import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { Grid } from "semantic-ui-react";
import { InternalAppSettings } from "@common/appSettings/model";
import { AppCommonState } from "@common/appCommonState";
import { drinkDriveOffenderSelector } from "@common/crud/drinkDriveOffenders/selectors";
import { CoreDetails } from "./details/CoreDetails";
import { AppState, DrinkDriveOffenderDetailModel } from "@common/crud/drinkDriveOffenders/model";

export interface CoreDetailProps {
    drinkDriveOffender: DrinkDriveOffenderDetailModel;
    internalAppSettings: InternalAppSettings;
}

export interface DispatchProps {
    push: (path: string) => void;
}

class DetailInternal extends React.Component<CoreDetailProps & DispatchProps, {}> {

    public render() {
        const { drinkDriveOffender } = this.props;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{drinkDriveOffender.forename} {drinkDriveOffender.surname}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CoreDetails {...this.props} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state: AppState & AppCommonState): CoreDetailProps {
    return {
        drinkDriveOffender: drinkDriveOffenderSelector(state),
        internalAppSettings: state.appSettings.internalAppSettings,
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
