import { TtcTrainerAdminRole } from "@common/auth/model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import * as React from "react";
import { Authorize } from "reauthorize";
import { Link } from "redux-little-router";
import { Checkbox, CheckboxProps, Grid, Message, Segment } from "semantic-ui-react";
import { ADTrainerUser } from "../model";

interface DuplicateUserMessageProps {
    duplicateUser: ADTrainerUser;
    basePath: string;
    removeADB2CConfirmed: boolean;
    onCheck: (_: any, { checked }: CheckboxProps) => void;
}

export const DuplicateUserMessage: React.FC<DuplicateUserMessageProps> = ({ duplicateUser, basePath, removeADB2CConfirmed, onCheck }) => (
    <>
        {duplicateUser.existsInAlaska ?
            <Message as={Segment} className="cancel-action">
                <p>A trainer with that email already exists:&nbsp;
                    <Link href={`${basePath}/${duplicateUser.id}`}>
                        {duplicateUser.fullName}
                    </Link>
                </p>
            </Message>
            :
            <Message as={Segment}>
                <Grid>
                    <Grid.Row>
                    Azure AD Trainer user: {duplicateUser.fullName} already exists for that email address,
                     the existing account will need to be removed from Azure AD B2C to continue.
                    </Grid.Row>
                    <Authorize authorize={TtcTrainerAdminRole}>
                        {duplicateUser.adB2CAccountCreated &&
                        <Grid.Row>
                            {`Account Created: ${duplicateUser.adB2CAccountCreated.format(DateFormat.LongWithTime)}`}
                        </Grid.Row>}
                        <Grid.Row>
                            <Checkbox label="Continue and remove existing account from Azure AD B2C" checked={removeADB2CConfirmed} onChange={onCheck} />
                        </Grid.Row>
                    </Authorize>
                </Grid>
            </Message>}
    </>
);
