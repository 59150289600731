import * as React from "react";
import { OrderGenesysApi } from "../orderGenesysApi";
import { GenesysObjectType } from "../model";

export function useResumeCallRecordingOnExit(genesysObjectType: GenesysObjectType, correlationId: string, ) {
    // Restart call recording if we leave the page
    React.useEffect(() => {
        function processUnLoadPage() {
            if (correlationId !== null) {
                const api = new OrderGenesysApi();
                api.resumeCallRecording(genesysObjectType, correlationId);
            }
        }

        // In order to trap the user navigating back to the admin app we need to listen for the the page being unloaded
        window.addEventListener("beforeunload", processUnLoadPage);

        return function cleanup() {
            window.removeEventListener("beforeunload", processUnLoadPage);
            processUnLoadPage();
        };
    }, [correlationId, genesysObjectType]);
}
