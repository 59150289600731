import * as React from "react";
import { Grid, Popup } from "semantic-ui-react";

interface CountryTitleProps{
abbreviation: string;
toolTip: string;
}
export const CountryTitle: React.FC<CountryTitleProps> = ({ abbreviation, toolTip }) => (
    <Grid.Column width={2} textAlign="center">
        <Popup content={toolTip} trigger={<p>{abbreviation}</p>} position='top center' />
    </Grid.Column>
);
