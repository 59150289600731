import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { toast } from "@common/toasts";
import * as actions from "./actiontypes";
import * as models from "./model";
import { DelegateApi } from "./delegateApi";
import { delegateFilterQuerySelector } from "./selectors";
import moment from "moment";
import { NoteAction } from "@common/notes/actions";

export type DelegateAction =
    ({ type: actions.CREATE_DELEGATE_SUCCESS } & Payload<models.DelegateDetailModel>)
    | ({ type: actions.LOAD_DELEGATES_SUCCESS } & Payload<models.DelegateListModel[]>)
    | ({ type: actions.LOAD_DELEGATE_DETAIL_SUCCESS } & Payload<models.DelegateDetailModel>)
    | ({ type: actions.SAVE_DELEGATE_SUCCESS } & Payload<models.DelegateDetailModel>)
    | ({ type: actions.SAVE_DELEGATE_DELETION_DATE_SUCCESS } & Payload<{ deletionDate: moment.Moment; id: string }>)
    | NoteAction;

export const createDelegateSuccess = (payload: models.DelegateDetailModel): DelegateAction => ({
    payload,
    type: actions.CREATE_DELEGATE_SUCCESS
});

export const loadDelegatesSuccess = (payload: models.DelegateListModel[]): DelegateAction => ({
    payload,
    type: actions.LOAD_DELEGATES_SUCCESS
});

export const loadDelegateDetailSuccess = (delegate: models.DelegateDetailModel): DelegateAction => ({
    payload: delegate,
    type: actions.LOAD_DELEGATE_DETAIL_SUCCESS
});

export const saveDelegateSuccess = (payload: models.DelegateDetailModel): DelegateAction => ({
    payload,
    type: actions.SAVE_DELEGATE_SUCCESS
});

export const saveDelegateDeletionDateSuccess = (payload: { deletionDate: moment.Moment; id: string }): DelegateAction => ({
    payload,
    type: actions.SAVE_DELEGATE_DELETION_DATE_SUCCESS
});

export function createDelegate(delegate: models.DelegateCreateEditModel, path: string, search: boolean = false) {
    return async (dispatch: Dispatch) => {
        const api = new DelegateApi();
        const result = await loadAndTrack(dispatch, actions.CREATE_DELEGATE, api.create(delegate));
        dispatch(createDelegateSuccess(result));
        if (search) {
            toast.success("Add Delegate Successful");
            dispatch(push(`/corporate-event-management/organisations/${result.organisationId}/delegates/${result.id}`));
        } else {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export function loadDelegatesFromQuery() {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const delegateFilterQuery = delegateFilterQuerySelector(getState());
        const api = new DelegateApi();
        const result = await loadAndTrack(dispatch, actions.LOAD_DELEGATES, api.getAll(delegateFilterQuery));
        dispatch(loadDelegatesSuccess(result));
    };
}

export function loadDelegateDetail({ organisationId, delegateId, attendeeId, eventInstanceId, overrideCheck, addAuditEntry }:
    { organisationId?: string; delegateId?: string; attendeeId?: string; eventInstanceId?: string; overrideCheck?: boolean; addAuditEntry: boolean }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const delegate = getState().delegates.find(c => c.id === delegateId);

        if (!delegate
            || !delegate.detailUpdated
            || delegate.detailUpdated.isBefore(delegate.listUpdated) || overrideCheck) {

            const action = !delegate ? createDelegateSuccess : loadDelegateDetailSuccess;

            const api = new DelegateApi();
            const result = await loadAndTrack(dispatch,
                actions.LOAD_DELEGATE_DETAIL,
                api.detail(organisationId, eventInstanceId, delegateId, attendeeId, addAuditEntry));

            dispatch(action(delegateId ? result : { ...result, attendeeWithoutDelegate: true }));
        }
    };
}

export function saveDelegate(delegate: models.DelegateCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new DelegateApi();
        const result = await loadAndTrack(dispatch, actions.SAVE_DELEGATE, api.save(delegate));

        if (result.errorUpdatingEmail) {
            toast.error("Could not update email as it is already in use!");
        }

        if (result.address?.isApproximate) {
            toast.warning("Unable to find exact address");
        }

        if (!result.errorUpdatingEmail) {
            dispatch(saveDelegateSuccess(result));
            toast.success("Delegate successfully edited");
        }

        if (path !== undefined && !result.errorUpdatingEmail) {
            dispatch(push(`${path}`));
        }
    };
}

export function handleDeletionDate(delegateId: string, organisationId: string, eventInstanceId: string, cancel: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new DelegateApi();

        const result = await loadAndTrack(dispatch, actions.SAVE_DELEGATE_DELETION_DATE_SUCCESS,
            api.handleDeletionDate(delegateId, organisationId, eventInstanceId, cancel));

        if (!cancel && result === null) {
            toast.warning("Failed to add deletion date due to delegate being on a future or recent course.");
            return;
        }

        dispatch(saveDelegateDeletionDateSuccess({ id: delegateId, deletionDate: result }));
        toast.success(cancel ? "Removed deletion date Successfully" :"Added deletion date Successfully");
    };
}
