import * as React from "react";
import { Button, Divider, Tab, Segment, TabProps } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { CoreDetailProps, DetailProps, DispatchProps } from "./Detail";
import { DetailsTab } from "./DetailsTab";
import { DelegateHistory } from "./DelegateHistory";
import { AddDeletionDateToDelegate } from "@common/crud/delegate/components/AddDeletionDateToDelegate";
import { Authorize } from "reauthorize";
import { DataDeleter } from "@common/auth/model";

export interface CoreDetailTabPropsState extends CoreDetailProps {
    onTabChange: (e: any, data: TabProps) => void;
    path: string;
}

enum TabNames {
    Details = "Details",
    History = "Delegate Journey",
}

enum TabPaths {
    Details = "/",
    History="/history"
}

export const CoreDetails: React.FC<CoreDetailTabPropsState> = ({ onTabChange, delegate, path, eventInstanceId, attendeeId }) => {
    const panes = [
        { menuItem: TabNames.Details, path: "", render: () => <DetailsTab delegate={delegate} eventInstanceId={eventInstanceId} attendeeId={attendeeId} /> },
        { menuItem: TabNames.History, path: "history", render: () => <DelegateHistory /> },
    ];

    const activeTab = path.split("/").pop().split("?")[0];
    const activeIndex = panes.findIndex(p => p?.path === activeTab);

    return (
        <Tab
            onTabChange={onTabChange}
            panes={panes}
            activeIndex={activeIndex >= 0 ? activeIndex : 0}
        />
    );
};

export interface CoreDetailsWithActionsState {
    showEdit: boolean;
}

export class CoreDetailsWithActions extends React.Component<DetailProps & DispatchProps, CoreDetailTabPropsState & CoreDetailsWithActionsState> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.state = {
            ...this.state,
            showEdit: this.props.tab !== TabPaths.History && !props.attendeeId
        };
    }

    public render() {
        const { delegate, path, eventInstanceId, attendeeId } = this.props;
        const basePath = path[path.length - 1] === "/" ? path.slice(0, -1) : path;

        return (
            <div>
                {!!delegate &&
                    <CoreDetails
                        eventInstanceId={eventInstanceId}
                        attendeeId={attendeeId}
                        delegate={delegate}
                        onTabChange={this.handleTabChange}
                        path={path}
                    />
                }

                <Divider />
                <Segment basic clearing vertical>
                    <div className="button-container right-align">
                        {this.state.showEdit &&
                            <Button
                                icon="pencil"
                                content="Edit"
                                as={Link}
                                href={`${basePath}/edit`}
                            />
                        }
                        <Authorize authorize={DataDeleter}>
                            <AddDeletionDateToDelegate delegate={delegate} />
                        </Authorize>
                    </div>
                </Segment>
            </div>
        );
    }

    private handleTabChange(_: any, tabData: TabProps) {
        this.setState({
            showEdit: tabData.panes[tabData.activeIndex].menuItem !== TabNames.History && !this.props.attendeeId
        });

        this.props.push(`${this.props.basePath}/${this.props.attendeeId ? this.props.attendeeId : this.props.delegate.id}`
            + `/${tabData.panes[tabData.activeIndex].path}`);
    }
}

export const coreDetailsTab = (props: DetailProps & DispatchProps) => ({
    path: "",
    menuItem: "Details",
    authorise: true,
    render: () => (
        <Tab.Pane>
            <CoreDetailsWithActions {...props} />
        </Tab.Pane>
    )
});
