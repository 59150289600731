import * as React from "react";
import { Button, SemanticICONS } from "semantic-ui-react";

export type FileAcceptTypes = "application/pdf" | "image/jpeg" | "image/png";

interface AddAttachmentButtonProps {
    label: string;
    disabled?: boolean;
    icon?: SemanticICONS;
    onChange: (files: FileList) => void;
}

export const AddAttachmentButton: React.FC<AddAttachmentButtonProps> = ({ label, disabled, icon, onChange }) => {
    const fileInputRef = React.useRef<HTMLInputElement>();

    function onFileUploadChange(e: React.FormEvent<HTMLInputElement>) {
        onChange(e.currentTarget.files);
    };

    function onUploadButtonClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <>
            <Button icon={icon ?? "upload"} content={label} disabled={disabled} onClick={onUploadButtonClick} />
            <input
                type="file"
                id="file-upload"
                ref={fileInputRef}
                className="invisible"
                onChange={onFileUploadChange}
                multiple
            />
        </>
    );
};
