import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { ApplicationState } from "../applicationState";
import { bookingIdSelector } from "../landing/selectors";
import { SeatApi } from "./seatApi";
import * as actions from "./actiontypes";
import { MultiPartSeatReservation, Seat, SeatReservation } from "./model";
import { push } from "redux-little-router";
import { eventInstanceIdSelector } from "@booking/eventInstance/selectors";

export type SeatAction =
    ({ type: actions.CREATE_RESERVATION_SUCCESS } & Payload<Seat[]>);

export const createReservationSuccess = (payload: Seat[]): SeatAction => ({
    payload,
    type: actions.CREATE_RESERVATION_SUCCESS
});

export function reserveSeat(seatReservation: SeatReservation, path?: string ) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        // bookingId will only exist for the Client Adviser route; this ensures the correct API is called.
        const bookingId = bookingIdSelector(getState());
        const api = new SeatApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "reserveSeat",
            api.reserveSeat(seatReservation)
        );

        const { isExpired, seats } = result;
        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=1`));
            return;
        }

        dispatch(createReservationSuccess(seats));

        if (path) {
            dispatch(push(`${path}/${seats[0].eventInstanceId}/booking-details`));
        }
    };
}

export function reserveMultiPartSeats(multiPartSeatReservation: MultiPartSeatReservation, path?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        // bookingId will only exist for the Client Adviser route; this ensures the correct API is called.
        const bookingId = bookingIdSelector(getState());
        const api = new SeatApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "reserveSeat",
            api.reserveMultiPartSeats(multiPartSeatReservation)
        );

        const { isExpired, seats } = result;
        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=1`));
            return;
        }

        dispatch(createReservationSuccess(seats));

        if (path) {
            dispatch(push(`${path}/${seats[0].eventInstanceId}/booking-details`));
        }
    };
}

export function getSeat(eventInstanceId: string, path?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        // bookingId will only exist for the Client Adviser route; this ensures the correct API is called.
        const bookingId = bookingIdSelector(getState());
        const api = new SeatApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "getSeat",
            api.getSeat(eventInstanceId)
        );

        const { isExpired, seats } = result;
        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=2`));
            return;
        }

        dispatch(createReservationSuccess(seats));
    };
}

export function loadSeat() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        // bookingId will only exist for the Client Adviser route; this ensures the correct API is called.
        const bookingId = bookingIdSelector(getState());
        const eventInstanceId = eventInstanceIdSelector(getState());
        const api = new SeatApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "getSeat",
            api.getSeat(eventInstanceId)
        );

        const { seats } = result;
        dispatch(createReservationSuccess(seats));
    };
}
