import axios from "axios";
import moment from "moment";
import { DigitalFees, DigitalFeesSaveModel, Fee } from "./model";

export class DigitalFeeApi {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/digitalFees";
    }

    public async getAll(): Promise<DigitalFees[]> {
        const response = await axios.get<DigitalFees[]>(`${this.apiUrl}`);

        return this.parseModels(response.data);
    }

    public async saveDigitalFees(model: DigitalFeesSaveModel) {
        const response = await axios.post<DigitalFees>(`${this.apiUrl}`, model);
        return this.parseModel(response.data);
    }

    public async toggleDifferentRates(forceId: number, enabled: boolean) {
        const response = await axios.post<string>(`${this.apiUrl}/toggle`, { forceId, enabled });
        return response.data;
    }

    public parseModels(models: DigitalFees[]): DigitalFees[] {
        return models.map(m => this.parseModel(m));
    }

    public parseModel(model: DigitalFees): DigitalFees {
        return {
            ...model,
            fees: model?.fees?.map(f => this.parseFeeModel(f))
        };
    }

    public parseFeeModel(model: Fee): Fee {
        return {
            ...model,
            effectiveDate: model.effectiveDate && moment(model.effectiveDate).utc().startOf("day")
        };
    }
}
