import * as React from "react";
import { Modal } from "@common/components";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Input, Button, Grid, Table, TableBody, TableRow, TableCell, InputOnChangeData } from "semantic-ui-react";
import { StandbyTrainerAvailabilityForDay, StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";
import moment from "moment";
import { Spinner } from "@common/global";
import { AssignStandbyTrainersRow } from "./AssignStandbyTrainersRow";
import { StandbyTrainersApi } from "../../StandbyTrainersApi";

interface AssignStandbyTrainersModalProps {
    date: moment.Moment;
    open: boolean;
    closeModal: () => void;
    currentAssignments: StandbyTrainersCalendarDay[];
    updateCurrentAssignments: (update: StandbyTrainersCalendarDay[]) => void;
    stopEditing: () => void;
    setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
    allAvailableTrainers: StandbyTrainerAvailabilityForDay[];
    changes: StandbyTrainersCalendarDay[];
    setChanges: React.Dispatch<React.SetStateAction<StandbyTrainersCalendarDay[]>>;
    nameSearch: string;
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

export const AssignStandbyTrainersModal: React.FC<AssignStandbyTrainersModalProps> =
({ date, open, currentAssignments, closeModal, updateCurrentAssignments, stopEditing, setDate, allAvailableTrainers, changes, setChanges,
    nameSearch, handleSearchChange }) => {
    const arrangementsForDay = currentAssignments?.find(d => d.date.date() === date.date());
    const [sessionOneStandbyIds, setSessionOneStandbyIds] = React.useState(arrangementsForDay?.sessionOneStandbyTrainerIds ?? []);
    const [sessionTwoStandbyIds, setSessionTwoStandbyIds] = React.useState(arrangementsForDay?.sessionTwoStandbyTrainerIds ?? []);
    const [sessionThreeStandbyIds, setSessionThreeStandbyIds] = React.useState(arrangementsForDay?.sessionThreeStandbyTrainerIds ?? []);
    const [sessionFourStandbyIds, setSessionFourStandbyIds] = React.useState(arrangementsForDay?.sessionFourStandbyTrainerIds ?? []);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        async function initDay() {
            const currentArrangementsForDay = currentAssignments?.find(d => d.date.date() === date.date());
            const newArrangementsForDay = changes?.find(d => d.date.date() === date.date());
            setSessionOneStandbyIds(newArrangementsForDay?.sessionOneStandbyTrainerIds ?? currentArrangementsForDay?.sessionOneStandbyTrainerIds ?? []);
            setSessionTwoStandbyIds(newArrangementsForDay?.sessionTwoStandbyTrainerIds ?? currentArrangementsForDay?.sessionTwoStandbyTrainerIds ?? []);
            setSessionThreeStandbyIds(newArrangementsForDay?.sessionThreeStandbyTrainerIds ?? currentArrangementsForDay?.sessionThreeStandbyTrainerIds ?? []);
            setSessionFourStandbyIds(newArrangementsForDay?.sessionFourStandbyTrainerIds ?? currentArrangementsForDay?.sessionFourStandbyTrainerIds ?? []);
        }
        initDay();
    }, [currentAssignments, changes, date]);

    function onPreviousDayClicked() {
        addChangesForDate();
        setDate(date.clone().subtract(1, "day"));
    }

    function onNextDayClicked() {
        addChangesForDate();
        setDate(date.clone().add(1, "day"));
    }

    const canGoBack = date.date() > 1;
    const canGoForward = date.date() < date.daysInMonth();
    const IdsAddedToCurrentDay = !sessionOneStandbyIds.every(id => arrangementsForDay?.sessionOneStandbyTrainerIds?.includes(id)) ||
        !sessionTwoStandbyIds.every(id => arrangementsForDay?.sessionTwoStandbyTrainerIds?.includes(id)) ||
        !sessionThreeStandbyIds.every(id => arrangementsForDay?.sessionThreeStandbyTrainerIds?.includes(id)) ||
        !sessionFourStandbyIds.every(id => arrangementsForDay?.sessionFourStandbyTrainerIds?.includes(id));
    const IdsRemovedFromCurrentDay = arrangementsForDay?.sessionOneStandbyTrainerIds.some(x => !sessionOneStandbyIds.includes(x)) ||
        arrangementsForDay?.sessionTwoStandbyTrainerIds.some(x => !sessionTwoStandbyIds.includes(x)) ||
        arrangementsForDay?.sessionThreeStandbyTrainerIds.some(x => !sessionThreeStandbyIds.includes(x)) ||
        arrangementsForDay?.sessionFourStandbyTrainerIds.some(x => !sessionFourStandbyIds.includes(x));

    function addChangesForDate() {
        // If something has changed we need to add it to the list of changes.
        if (IdsAddedToCurrentDay || IdsRemovedFromCurrentDay) {
            const newChange: StandbyTrainersCalendarDay =
                {
                    date,
                    sessionOneStandbyTrainerIds: sessionOneStandbyIds,
                    sessionTwoStandbyTrainerIds: sessionTwoStandbyIds,
                    sessionThreeStandbyTrainerIds: sessionThreeStandbyIds,
                    sessionFourStandbyTrainerIds: sessionFourStandbyIds
                };

            const indexOfCurrentDate = changes.map(x => x.date).indexOf(date);

            if (indexOfCurrentDate !== -1) {
                setChanges(changes.map((x, index) => index === indexOfCurrentDate? newChange : x ));
            }
            else
            {
                setChanges([...changes, newChange]);
            }
        }
    }

    async function saveChanges() {
        setLoading(true);
        let allChanges = changes;
        if (IdsAddedToCurrentDay || IdsRemovedFromCurrentDay) {
            const newChange: StandbyTrainersCalendarDay =
            {
                date,
                sessionOneStandbyTrainerIds: sessionOneStandbyIds,
                sessionTwoStandbyTrainerIds: sessionTwoStandbyIds,
                sessionThreeStandbyTrainerIds: sessionThreeStandbyIds,
                sessionFourStandbyTrainerIds: sessionFourStandbyIds
            };
            const indexOfCurrentDate = changes.map(x => x.date).indexOf(date);
            if (indexOfCurrentDate !== -1) {
                allChanges = changes.map((x, index) => index === indexOfCurrentDate? newChange : x );
            }
            else
            {
                allChanges = ([...allChanges, newChange]);
            }
        }
        if (allChanges.length < 1) {
            setLoading(false);
            closeModal();
            return;
        }
        const api = new StandbyTrainersApi();
        const result = await(api.saveChangesToCalendar(date, allChanges));
        updateCurrentAssignments(result);
        setLoading(false);
        stopEditing();
        closeModal();
    }

    const sortByBookedAndThenAvailable = (a: StandbyTrainerAvailabilityForDay, b: StandbyTrainerAvailabilityForDay) => {
        if (a.sessionOneIsBooked !== b.sessionOneIsBooked) {
            return a.sessionOneIsBooked ? 1 : -1;
        }

        if (a.sessionOneAvailable !== b.sessionOneAvailable) {
            return a.sessionOneAvailable ? -1 : 1;
        }

        if (a.sessionTwoIsBooked !== b.sessionTwoIsBooked) {
            return a.sessionTwoIsBooked ? 1 : -1;
        }

        if (a.sessionTwoAvailable !== b.sessionTwoAvailable) {
            return a.sessionTwoAvailable ? -1 : 1;
        }

        if (a.sessionThreeIsBooked !== b.sessionThreeIsBooked) {
            return a.sessionThreeIsBooked ? 1 : -1;
        }

        if (a.sessionThreeAvailable !== b.sessionThreeAvailable) {
            return a.sessionThreeAvailable ? -1 : 1;
        }

        if (a.sessionFourIsBooked !== b.sessionFourIsBooked) {
            return a.sessionFourIsBooked ? 1 : -1;
        }

        if (a.sessionFourAvailable !== b.sessionFourAvailable) {
            return a.sessionFourAvailable ? -1 : 1;
        }

        return 0;
    };

    const availableTrainers = allAvailableTrainers?.length > 0 ? allAvailableTrainers : [];

    const trainers = ((nameSearch !== "" && nameSearch !== null) ?
        availableTrainers.filter(t => t.fullName.toLowerCase().includes(nameSearch.trim().toLowerCase()))
        : availableTrainers).sort((a, b) => sortByBookedAndThenAvailable(a, b));

    return (
        <Modal open={open} size={"large"}>
            <Modal.Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>Select trainers</Grid.Column>
                        <Grid.Column width={7}>{date.format(DateFormat.Long)}</Grid.Column>
                        <Grid.Column width={3}><Button disabled={!canGoBack || loading} onClick={onPreviousDayClicked}>Previous Day</Button></Grid.Column>
                        <Grid.Column width={2}><Button disabled={!canGoForward || loading} onClick={onNextDayClicked}>Next Day</Button></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Header>
            <Modal.Actions>
                <Input placeholder="Enter a trainer name" className="floated-left" value={nameSearch} onChange={handleSearchChange} />
                <Button disabled={loading} content="Close" onClick={closeModal} className="cancel-action" />
                <Button disabled={loading} loading={loading} content="Continue" onClick={saveChanges} />
            </Modal.Actions>
            <Modal.Content>
                <Spinner active={loading}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Trainer</Table.HeaderCell>
                                <Table.HeaderCell>no. courses in same month</Table.HeaderCell>
                                <Table.HeaderCell>no. days in rolling 12 months</Table.HeaderCell>
                                <Table.HeaderCell colSpan={4}>
                                    <Table style={{ "table-layout": "fixed" }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4} textAlign="center">
                                                    Add to Session
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell textAlign="center" size={4}>Session One</TableCell>
                                                <TableCell textAlign="center" size={4}>Session Two</TableCell>
                                                <TableCell textAlign="center" size={4}>Session Three</TableCell>
                                                <TableCell textAlign="center" size={4}>Session Four</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <AssignStandbyTrainersRow
                                trainers={trainers}
                                sessionOneStandbyIds={sessionOneStandbyIds}
                                setSessionOneStandbyIds={setSessionOneStandbyIds}
                                sessionTwoStandbyIds={sessionTwoStandbyIds}
                                setSessionTwoStandbyIds={setSessionTwoStandbyIds}
                                sessionThreeStandbyIds={sessionThreeStandbyIds}
                                setSessionThreeStandbyIds={setSessionThreeStandbyIds}
                                sessionFourStandbyIds={sessionFourStandbyIds}
                                setSessionFourStandbyIds={setSessionFourStandbyIds}
                            />
                        </Table.Body>
                    </Table>
                </Spinner>
            </Modal.Content>
        </Modal>
    );
};
