import * as React from "react";
import { Button, Modal, Form, Loader } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { EventInstance, DigitalCourseCancellationReason, ClassCourseCancellationReason, OtherCancellationReason, OrgCancellationReason } from "../model";
import { useDispatch } from "react-redux";
import { cancelEventInstance } from "../actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { optionsFromText } from "@common/crud/common";
import { validators } from "not-valid";
import { BookingTypeEnum } from "@common/crud/eventType/model";
import { isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";
import { CurrencyInput } from "@common/global/CurrencyInput";

interface Props {
    eventInstance: EventInstance;
}

export const CancelEventInstanceButton: React.FC<Props> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>();
    const [reasonForCancelValid, setReasonForCancelValid] = React.useState<boolean>();
    const [reasonForCancel, setReasonForCancel] = React.useState<string>();
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [otherReason, setOtherReason] = React.useState<string>();
    const [cancellationCharge, setCancellationCharge] = React.useState<string>();
    const [miscellaneousOrganisationFee, setMiscellaneousOrganisationFee] = React.useState<number>(eventInstance?.miscellaneousOrganisationFee);
    const [miscellaneousOrganisationFeeValid, setMiscellaneousOrganisationFeeValid] = React.useState<boolean>(true);
    const [isContinueClicked, setIsContinueClicked] = React.useState<boolean>(false);
    const mounted = React.useRef<boolean>(false);

    const otherCancellationReasonChoosen = reasonForCancel === OtherCancellationReason;
    const orgCancellationReasonChoosen = reasonForCancel === OrgCancellationReason;

    React.useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false; };
    }, []);

    React.useEffect(() => {
        setMiscellaneousOrganisationFee(eventInstance?.miscellaneousOrganisationFee || 0);
        setMiscellaneousOrganisationFeeValid(true);
    }, [eventInstance?.miscellaneousOrganisationFee]);

    const cancellationReasonOptions = React.useMemo(() => {
        const options = DeliveryTypeEnum.Digital
            ? optionsFromText(DigitalCourseCancellationReason)
            : optionsFromText(ClassCourseCancellationReason);

        if (isEventInstanceWorkflowCorporate(eventInstance) && eventInstance?.bookingType === BookingTypeEnum.Closed) {
            options.push({ text: "Org cancellation", value: OrgCancellationReason });
        }

        return options;
    }, [eventInstance]);

    const cancellationChargeOptions = React.useMemo(() => {
        return Array.from(Array(5).keys()).map(i => ({ text: `${i * 25}%`, value: (i * 25).toString() }));
    }, []);

    const onCancelModal = React.useCallback(() => {
        setReasonForCancel(null);
        setOtherReason(null);
        setOtherReasonValid(true);
        setCancellationCharge(null);
        setIsModalOpen(false);
    }, []);

    const onReasonForCancelEventChanged = React.useCallback((value: string, valid: boolean) => {
        if (value) {
            setReasonForCancel(value);
            setReasonForCancelValid(valid);
        }
    }, []);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    const onCancelledChargeChanged = React.useCallback((value: string) => {
        setCancellationCharge(value);
    }, []);

    const onChangeMiscellaneousOrganisationFee = React.useCallback((value: number, valid: boolean) => {
        setMiscellaneousOrganisationFee(value);
        setMiscellaneousOrganisationFeeValid(valid);
    }, []);

    const onSubmit = React.useCallback(async () => {
        if (reasonForCancelValid && ((!otherCancellationReasonChoosen && !orgCancellationReasonChoosen) || otherReasonValid)
            && (!orgCancellationReasonChoosen || (cancellationCharge && miscellaneousOrganisationFeeValid))) {
            setIsContinueClicked(true);
            await dispatch(cancelEventInstance(
                eventInstance.id,
                reasonForCancel,
                otherReason,
                cancellationCharge
                    ? +cancellationCharge
                    : undefined,
                miscellaneousOrganisationFee)
            );
            if (mounted.current) {
                setIsContinueClicked(false);
                setIsModalOpen(false);
            }
        }
    }, [reasonForCancelValid, dispatch, eventInstance, reasonForCancel, otherReason, otherReasonValid, otherCancellationReasonChoosen,
        orgCancellationReasonChoosen, cancellationCharge, miscellaneousOrganisationFee, miscellaneousOrganisationFeeValid]);

    const onCancelClick = React.useCallback(() => setIsModalOpen(true), []);

    if (eventInstance.cancelled) {
        return null;
    }

    return (
        <>
            <Button className="irregular-positive" content="Cancel Course" onClick={onCancelClick} />
            <Modal open={isModalOpen}>
                <Modal.Content>
                    <Form onSubmit={onSubmit}>
                        <Input.Dropdown
                            options={cancellationReasonOptions}
                            dynamicOptions
                            value={reasonForCancel}
                            label="What is the reason for cancellation?"
                            onChange={onReasonForCancelEventChanged}
                        />
                        {(otherCancellationReasonChoosen || orgCancellationReasonChoosen) && (
                            <Input.Textarea
                                value={otherReason}
                                label={otherCancellationReasonChoosen
                                    ? "Other Reason"
                                    : "Org cancellation reason"}
                                validation={[validators.validLength({ min: 1, max: 500 })]}
                                onChange={onOtherReasonChanged}
                                required
                            />
                        )}
                        {orgCancellationReasonChoosen && (
                            <>
                                <Input.Dropdown
                                    options={cancellationChargeOptions}
                                    dynamicOptions
                                    value={cancellationCharge}
                                    label="What is the cancellation charge?"
                                    onChange={onCancelledChargeChanged}
                                    required
                                />
                                <CurrencyInput
                                    label={"Misc Org Fee"}
                                    value={miscellaneousOrganisationFee}
                                    onChange={onChangeMiscellaneousOrganisationFee}
                                />
                            </>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onCancelModal}>Cancel</Button>
                    <Button primary onClick={onSubmit}
                        disabled={!reasonForCancelValid
                            || ((otherCancellationReasonChoosen || orgCancellationReasonChoosen) && !otherReasonValid)
                            || (orgCancellationReasonChoosen && (!cancellationCharge || !miscellaneousOrganisationFeeValid))
                            || isContinueClicked}>
                        {isContinueClicked ? <Loader active inline size={"mini"} /> : "Continue"}</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
