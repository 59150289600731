import * as React from "react";
import { Button } from "semantic-ui-react";

export type FileAcceptTypes = "application/pdf" | "image/jpeg" | "image/png";

interface FileUploadInputProps {
    fileList: FileList;
    uploadLabel: string;
    multiple?: boolean;
    disabled?: boolean;
    accept?: FileAcceptTypes[];
    onChange: (files: FileList) => void;
}

export const FileUploadInput: React.FC<FileUploadInputProps> = ({ fileList, uploadLabel, multiple, disabled, accept, onChange }) => {

    const fileInputRef = React.useRef<HTMLInputElement>();

    function onClear() { onChange(undefined); }

    function onFileUploadChange(e: React.FormEvent<HTMLInputElement>) {
        onChange(e.currentTarget.files);
    };

    function onUploadButtonClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const acceptTypes = accept ? accept.join(",") : undefined;

    return fileList ? (
        <Button icon="delete" content="Clear " onClick={onClear} />
    ) : (
        <>
            <Button icon="upload" content={uploadLabel} disabled={disabled} onClick={onUploadButtonClick} />
            <input
                type="file"
                id="file-upload"
                ref={fileInputRef}
                className="invisible"
                multiple={multiple}
                accept={acceptTypes}
                onChange={onFileUploadChange} />
        </>
    );
};
