import React, { ReactNode, useRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import { mergeClasses } from "src/CorporateBooking.App/src/helpers";

export type ButtonVariant = "primary" | "alternate-primary" | "secondary" | "clear";
export type ButtonType = "button" | "submit" | "reset";

export interface ButtonProps extends Omit<AriaButtonProps<"button">, "type"> {
    type?: ButtonType;
    className?: string;
    children: ReactNode;
    isLoading?: boolean;
    loadingPlaceholder?: ReactNode;
    variant: ButtonVariant;
}

export interface BaseButtonProps extends ButtonProps {
    classes: {
        button: string;
        primary: string;
        alternatePrimary: string;
        secondary: string;
        loading: string;
        disabled: string;
        loader: string;
    };
}

export const BaseButton = (props: BaseButtonProps) => {
    const {
        type,
        className,
        children,
        isDisabled,
        isLoading = false,
        loadingPlaceholder = "Submitting...",
        variant,
        classes,
        ...ariaProps
    } = props;
    const ref = useRef<HTMLButtonElement>(null);
    const { buttonProps } = useButton({ ...ariaProps, isDisabled, type }, ref);

    const classNames = mergeClasses(
        classes.button,
        classes[variant],
        className,
        isLoading ? classes.loading : "",
        isDisabled ? classes.disabled : ""
    );

    return (
        <button {...buttonProps} className={classNames} ref={ref}>
            {isLoading && <div className={classes.loader}></div>}
            {isLoading ? loadingPlaceholder : children}
        </button>
    );
};
