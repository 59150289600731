import * as React from "react";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { Divider, Grid, Loader } from "semantic-ui-react";
import { ModuleTypeEnum } from "@common/crud/eventType/model";
import { eventTypeSelector } from "@common/crud/eventType/selectors";
import { Authorize } from "reauthorize";
import { TrainersEdit } from "@common/crud/eventInstance/components/details/TrainersEdit";
import { TtcClientAdvisorRole, TtcPlannerRole } from "@common/auth/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventInstance, EventTrainer, TrainerType } from "../../model";
import { EiSideBar } from "../EiSideBar";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { TrainerSmsModal } from "../trainers/TrainerSmsModal";
import { loadGroupEventInstancesDetails } from "@common/crud/eventInstance/components/trainers/helper";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { MultiDayTrainerDetails } from "./MultiDayTrainerDetails";

export const MultiDayTrainers: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const [groupEventInstances, setGroupEventInstances] = React.useState<EventInstance[]>();
    const eventType = useSelector(eventTypeSelector(eventInstance.eventTypeId));
    const isTheoryEvent = eventType?.moduleType === ModuleTypeEnum.Theory || eventType?.moduleType === ModuleTypeEnum.Both;
    const isPracticalEvent = eventType?.moduleType === ModuleTypeEnum.Practical || eventType?.moduleType === ModuleTypeEnum.Both;
    const isDigital = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    const [mustLoad, setMustLoad] = React.useState<boolean>(true);
    const [group, setGroup] = React.useState<EventInstanceGroupModel>();
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

        async function getData() {
            const api = new EventInstanceGroupApi();
            const eventInstanceGroup =  await api.getByEventInstanceId(eventInstance.id);
            setGroup(eventInstanceGroup);
            const loadGroupEventInstances = await loadGroupEventInstancesDetails(eventInstanceGroup);
            setGroupEventInstances(loadGroupEventInstances);
            setLoading(false);
        }

        if (!group && mustLoad) {
            setMustLoad(false);
            getData();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstance, mustLoad]);

    const getTrainerDetails = (trainer: EventTrainer, trainerType: TrainerType) =>
        (<MultiDayTrainerDetails updateGroupEventInstances={setGroupEventInstances} key={trainer.id} eventInstance={eventInstance} trainer={trainer}
            trainerType={trainerType} group={group} groupEventInstances={groupEventInstances} />);

    /* get the details of other trainers who are not on the currently selected EI so the current page
    will display a Trainer that is NOT on the currently displayed EI.
    Also, only display DISTINCT details so that Trainers do not appear more than once
    */

    const distinctTrainers = (trainerList: EventTrainer[]): EventTrainer[] => {
        const result = [];
        const map = new Map();
        if (trainerList?.length > 0) {
            for (const trainer of trainerList) {
                if (!map.has(trainer.id)) {
                    map.set(trainer.id, true);
                    result.push(trainer);
                }
            }
        }
        return result;
    };

    const buildTrainersDisplay = (trainerType: TrainerType) => {
        const tempDistinct: EventTrainer[] = [];
        switch (trainerType) {
            case TrainerType.TheoryTrainer:
                groupEventInstances?.forEach(ei => tempDistinct.push(...distinctTrainers(ei.trainers)));
                break;
            case TrainerType.PracticalTrainer:
                groupEventInstances?.forEach(ei => tempDistinct.push(...distinctTrainers(ei.practicalTrainers)));
                break;
            case TrainerType.MonitorTrainer:
                groupEventInstances?.forEach(ei => tempDistinct.push(...distinctTrainers(ei.monitorTrainers)));
                break;
            case TrainerType.OtherTrainer:
                groupEventInstances?.forEach(ei => tempDistinct.push(...distinctTrainers(ei.observerTrainers)));
                break;
            default:
            // nop
        }
        return distinctTrainers(tempDistinct).map(trainer => getTrainerDetails(trainer, trainerType));
    };

    const trainers = () => {
        return buildTrainersDisplay(TrainerType.TheoryTrainer);
    };

    function practicalTrainers() {
        return buildTrainersDisplay(TrainerType.PracticalTrainer);
    }
    function monitorTrainers() {
        return buildTrainersDisplay(TrainerType.MonitorTrainer);
    }
    function observerTrainers() {
        return buildTrainersDisplay(TrainerType.OtherTrainer);
    }

    const content = () => {
        return (<>
            <Divider hidden />
            <Grid>
                <h1 className="event-instance-title">Trainer details</h1>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <EventInstanceBanner eventInstance={eventInstance} />
                    </Grid.Column>
                </Grid.Row>
                <Authorize authorize={[TtcPlannerRole, TtcClientAdvisorRole]}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <TrainerSmsModal />
                        </Grid.Column>
                    </Grid.Row>
                </Authorize>
                {isTheoryEvent && (<>
                    <Grid.Row>
                        <Grid.Column width={4}><h3>Trainers</h3></Grid.Column>
                        <Grid.Column width={12} textAlign="right">
                            <Authorize authorize={TtcPlannerRole}>
                                <TrainersEdit
                                    eventInstance={eventInstance}
                                    eventType={eventType}
                                    isDigital={isDigital}
                                    trainerType={TrainerType.TheoryTrainer}
                                    groupEventInstances={groupEventInstances}
                                    group={group}
                                />

                            </Authorize>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} floated="right">
                            <Loader active={loading} />
                            {trainers()}
                        </Grid.Column>
                    </Grid.Row>
                </>)}
                {isPracticalEvent && (<>
                    <Grid.Row>
                        <Grid.Column width={4}><h3>Trainers</h3></Grid.Column>
                        <Grid.Column width={12} textAlign="right">
                            <Authorize authorize={TtcPlannerRole}>
                                <Grid.Row>
                                    <TrainersEdit
                                        eventInstance={eventInstance}
                                        eventType={eventType}
                                        isDigital={isDigital}
                                        trainerType={TrainerType.PracticalTrainer}
                                        groupEventInstances={groupEventInstances}
                                        group={group}
                                    />
                                </Grid.Row>
                            </Authorize>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} floated="right">
                            <Loader active={loading} />
                            {practicalTrainers()}
                        </Grid.Column>
                    </Grid.Row>
                </>)}
                {eventInstance.monitorTrainers && (<>
                    <Grid.Row>
                        <Grid.Column width={4}><h3>Monitor Trainers</h3></Grid.Column>
                        <Grid.Column width={12} textAlign="right">
                            <Authorize authorize={TtcPlannerRole}>
                                <Grid.Row>
                                    <TrainersEdit
                                        eventInstance={eventInstance}
                                        eventType={eventType}
                                        isDigital={isDigital}
                                        trainerType={TrainerType.MonitorTrainer}
                                        groupEventInstances={groupEventInstances}
                                        group={group}
                                    />
                                </Grid.Row>
                            </Authorize>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} floated="right">
                            <Loader active={loading} />
                            {monitorTrainers()}
                        </Grid.Column>
                    </Grid.Row>
                </>)}

                {eventInstance.observerTrainers &&  (<>
                    <Grid.Row>
                        <Grid.Column width={4}><h3>Other trainers</h3></Grid.Column>
                        <Grid.Column width={12} textAlign="right">
                            <Authorize authorize={TtcPlannerRole}>
                                <Grid.Row>
                                    <TrainersEdit
                                        eventInstance={eventInstance}
                                        eventType={eventType}
                                        isDigital={isDigital}
                                        trainerType={TrainerType.OtherTrainer}
                                        groupEventInstances={groupEventInstances}
                                        group={group}
                                    />
                                </Grid.Row>
                            </Authorize>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} floated="right">
                            <Loader active={loading} />
                            {observerTrainers()}
                        </Grid.Column>
                    </Grid.Row>
                </>)}
            </Grid>
        </>);
    };

    return (<EiSideBar children={content()} />);
};
