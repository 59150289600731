import * as React from "react";
import { BookingAppSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const BookingApp: ConfigurationAreaComponent<BookingAppSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="BookingApp"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ShowClassroomCoursesFirst", label: "Show classroom courses first" },
                { name: "SeatReservationTimeMinutes", label: "Seat reservation time (minutes)" },
                { name: "WelshCourseSelectionAlert", label: "Welsh course selection alert", inputType: InputType.MarkdownEditor },
                { name: "SpecialRequirementInfoEN", label: "Special Requirement Info EN", inputType: InputType.MarkdownEditor },
                { name: "SpecialRequirementInfoCY", label: "Special Requirement Info CY", inputType: InputType.MarkdownEditor },
                { name: "NoClassroomMessageEnglish", label: "No Classroom Message EN", inputType: InputType.TextArea },
                { name: "NoClassroomMessageCymraeg", label: "No Classroom Message CY", inputType: InputType.TextArea },
                { name: "WelshPoliceForceIds", label: "Welsh Police Force Ids (comma separated)", inputType: InputType.Input },
                { name: "StandardBookingExplanationEN", label: "Standard Booking Explanation EN", inputType: InputType.MarkdownEditor },
                { name: "StandardBookingExplanationCY", label: "Standard Booking Explanation CY", inputType: InputType.MarkdownEditor },
                { name: "FlexiPayExplanationEN", label: "Flexi-Booking Explanation EN", inputType: InputType.MarkdownEditor },
                { name: "FlexiPayExplanationCY", label: "Flexi-Booking Explanation CY", inputType: InputType.MarkdownEditor },
                { name: "EarlyBirdDaysSinceSentenceDate", label: "Early Bird Days Since Sentence Date" },
                { name: "HideClassroomCoursesFromAttendeeBeforeCourseInHours", label: "Hide classroom courses from attendee before course (hours)" },
                { name: "HideDigitalCoursesFromAttendeeBeforeCourseInHours", label: "Hide digital courses from attendee before course (hours)" },
                { name: "AlwaysShowRebookButton", label: "Always show rebook button (overrides the related fields below if on)", inputType: InputType.Toggle },
                { name: "HideRebookButtonFromDigitalAttendeeBeforeCourseInHours", label: "Hide rebook button from digital attendee before course (hours)" },
                { name: "HideRebookButtonFromClassroomAttendeeBeforeCourseInHours", label: "Hide rebook button from classroom attendee before course (hours)" },
            ]}
        />
    );
};
