export enum DeliveryTypeEnum {
    None = 0,
    Onsite = 1,
    Digital = 2
}

export const DeliveryType = {
    [DeliveryTypeEnum.None]: "None",
    [DeliveryTypeEnum.Onsite]: "Classroom",
    [DeliveryTypeEnum.Digital]: "Digital"
};
