/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_POLICE_USERS_SUCCESS = "LOAD_POLICE_USERS_SUCCESS";
export type LOAD_POLICE_USERS_SUCCESS = typeof LOAD_POLICE_USERS_SUCCESS;

export const CREATE_POLICE_USER_SUCCESS = "CREATE_POLICE_USER_SUCCESS";
export type CREATE_POLICE_USER_SUCCESS = typeof CREATE_POLICE_USER_SUCCESS;

export const SAVE_POLICE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export type SAVE_POLICE_USER_SUCCESS = typeof SAVE_POLICE_USER_SUCCESS;

export const LOAD_POLICE_USER_DETAIL_SUCCESS = "LOAD_POLICE_USER_DETAIL_SUCCESS";
export type LOAD_POLICE_USER_DETAIL_SUCCESS = typeof LOAD_POLICE_USER_DETAIL_SUCCESS;

export const POLICE_SIGNUP_INVITATION_SUCCESS = "POLICE_SIGNUP_INVITATION_SUCCESS";
export type POLICE_SIGNUP_INVITATION_SUCCESS = typeof POLICE_SIGNUP_INVITATION_SUCCESS;

export const SORT_POLICE_USERS = "SORT_POLICE_USERS";
export type SORT_POLICE_USERS = typeof SORT_POLICE_USERS;
