import * as React from "react";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Accordion, Icon } from "semantic-ui-react";

export const PrivacyPolicy: React.FC = () => {
    const [active, setActive] = useState<boolean>(false);

    const [t] = useTranslation("PrivacyPolicy");

    return (
        <Accordion className="privacy-policy">
            <Accordion.Title
                active={active}
                className="title"
                onClick={() => setActive(!active)}
            >
                {t("READ_PRIVACY_POLICY")}
                <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={active}>
                <div className="pre-wrap">
                    <Trans i18nKey="PrivacyPolicy:PRIVACY_POLICY">
                        At TTC Group we’re committed to ensure that your privacy is protected. We take your privacy seriously,
                        and will only user your personal information to administer your account,
                        and to provide the products and services that you have requested.

                        The information you have provided will not be used for marketing purposes and
                        you will only receive communications from us that is relative to your course.

                        Your information will only be shared with approved persons associated
                        with TTC who are assisting in the provision of the product or service that you have requested.

                        You have the right to ask for a copy of the information
                        TTC Group hold about you, you also have the right for your information to be erased or rectified.

                        If you have a query relating to your data please contact 0330 024 1805, alternatively, you can email
                        <a
                            className="primary-link"
                            target="_blank"
                            rel="noreferrer"
                            href="mailto:yourdata@ttc-uk.com"
                        >
                            yourdata@ttc-uk.com
                        </a>
                        or write to The Data Protection Officer, TTC Group, Hadley Park East, Telford TF1 6QJ.

                        For more information on TTC Group’s Privacy Policy please visit
                        <a
                            className="primary-link"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.ttc-uk.com/aboutus/#privacy"
                        >
                            https://www.ttc-uk.com/aboutus/#privacy
                        </a>
                    </Trans>
                </div>
            </Accordion.Content>
        </Accordion>
    );
};
