import { AppSettings } from "@common/appSettings/model";
import * as React from "react";
import { Authorize } from "reauthorize";
import { Grid } from "semantic-ui-react";
import { AppSetting, SettingsDictionary } from "../model";
import { AppSettingView } from "./AppSettingView";

export enum InputType {
    Input = 0,
    TextArea = 1,
    MarkdownEditor = 2,
    Toggle = 3,
    Number = 4,
    DropdownNumber = 5,
    DatePicker = 6
}

export interface SettingsGridItemProps<T> {
    label?: string;
    name: T;
    inputType?: InputType;
    isArchivable?: boolean;
    authorize?: string; // also hide settings on the back end.
    validate?: (value: string | string[] | { value: string; text: string }[]) => string[];
    withInfoModal?: boolean;
}

export interface SettingsGridProps<T extends string> {
    area: string;
    settings: SettingsDictionary<T>;
    items: SettingsGridItemProps<T>[];
    hierarchy: string[];
    currentAppSettings?: AppSettings;
}

export const SettingsGrid = <T extends string>(props: SettingsGridProps<T>) => {

    const { hierarchy, settings, area, currentAppSettings } = props;

    const renderItem = (item: SettingsGridItemProps<T>) => {
        const { name, label, inputType, isArchivable, validate, withInfoModal } = item;
        const setting = getSetting(name);

        if (item.authorize) {
            return (
                <Authorize authorize={item.authorize}>
                    <AppSettingView
                        key={name}
                        label={label || name}
                        setting={setting}
                        inputType={inputType ?? InputType.Input}
                        isArchivable={isArchivable}
                        hierarchyContext={hierarchy}
                        currentAppSettings={currentAppSettings}
                        validate={validate}
                        withInfoModal={withInfoModal}
                    />
                </Authorize>
            );
        }

        return (
            <AppSettingView
                key={name}
                label={label || name}
                setting={setting}
                inputType={inputType ?? InputType.Input}
                isArchivable={isArchivable}
                hierarchyContext={hierarchy}
                currentAppSettings={currentAppSettings}
                validate={validate}
                withInfoModal={withInfoModal}
            />);
    };

    const getSetting = (name: string): AppSetting => {
        return settings[name] || createEmptySetting(name);
    };

    const createEmptySetting = (name: string): AppSetting => {
        return {
            area,
            name,
            value: "",
            hierarchy: []
        };
    };

    return (
        <Grid>
            {props?.items?.map(renderItem)}
        </Grid>
    );
};
