import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import * as actions from "./actionTypes";
import { PoliceContract, PoliceContractOptions } from "./model";
import { Payload } from "@neworbit/redux-helpers";
import { PoliceContractsApi } from "./policeContractsApi";

export type PoliceContractAction =
    ({ type: actions.LOAD_POLICE_CONTRACTS_SUCCESS } & Payload<PoliceContract[]>);

export const loadPoliceContractsSuccess = (payload: PoliceContract[]): PoliceContractAction => ({
    payload,
    type: actions.LOAD_POLICE_CONTRACTS_SUCCESS
});

export function loadPoliceContracts(options: PoliceContractOptions) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceContractsApi();
        const fees = await loadAndTrack(dispatch, "loadPoliceContracts", api.getAll(options));
        dispatch(loadPoliceContractsSuccess(fees));
    };
}

