import * as React from "react";
import { EventTypeApi } from "@common/crud/eventType/eventTypeApi";
import { EventInstance } from "@common/crud/eventInstance";
import { languageSelector } from "@booking/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function useEventTypeBookingAppTexts(eventInstance: EventInstance, defaultTranslationNamespace: string, fetchTexts: boolean = false) {
    const [texts, setTexts] = React.useState<Dictionary<string>>({});
    const currentLanguage = useSelector(languageSelector);
    const [t] = useTranslation(defaultTranslationNamespace);

    const getTexts = async () => {
        const eventTypeApi = new EventTypeApi();
        eventTypeApi.getBookingAppTexts(eventInstance.eventTypeId).then(setTexts);
    };

    React.useEffect(() => {
        if (eventInstance?.eventTypeId && eventInstance.moduleType !== "Theory" && fetchTexts) {
            getTexts();
        }
    }, [eventInstance, fetchTexts]);

    const getTranslation = React.useCallback((key: string) => {
        let text;

        if (currentLanguage.current === "en") {
            text = texts[`EN_${key}`];
        } else {
            text = texts[`CY_${key}`];
        }

        return text?.length > 0 ? text : t(key);
    }, [texts, currentLanguage]);

    return getTranslation;
}
