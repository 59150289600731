import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { Grid } from "semantic-ui-react";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { ChangeEventType, HistoryRecord } from "@common/history/model";

const expectedFilters = [
    { type: ChangeEventType.DataModification, display: true },
];

export interface PurchaseOrderHistoryProps {
    history?: HistoryRecord[];
}

export const PurchaseOrderHistory: React.FC<PurchaseOrderHistoryProps> = ({ history }) => {
    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        expectedFilters
            .map(filter => filter.type));

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    return (
        <Grid className="no-margin-top">
            <Grid.Column width={3}>
                <Grid.Row>
                    Filters by group:
                </Grid.Row>
                {expectedFilters.map(eventType => eventType.display ?
                    <HistoryFilter
                        key={eventType.type}
                        eventType={eventType.type}
                        value={filters.includes(eventType.type)}
                        onChange={addOrRemove}
                    /> : null)}
                <Grid.Row>
                    <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                </Grid.Row>
            </Grid.Column>
            <Grid.Column width={13}>
                <HistoryContent showDetailsDefault={false} entries={history?.filter(
                    record => filters.includes(record.eventType))} />
            </Grid.Column>
        </Grid>
    );
};
