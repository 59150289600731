import * as React from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import "moment-duration-format";
import { Button, Grid, Segment } from "semantic-ui-react";
import { EventInstanceDetailModel } from "@common/crud/eventInstance/model";
import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import { AsyncDispatch } from "@common/redux-helpers";
import { push } from "redux-little-router";
import { BookingPaymentsInfo, ScheduledPaymentStatus } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import { bookingBasePathSelector, bookingIdSelector } from "../../landing/selectors";
import { ChosenCourse } from "../../global/ChosenCourse";
import { loadEventInstanceDetailsForBooking, loadPoliceOrganisationDetailsForBooking } from "../../eventInstance/actions";
import { View } from "../../global/ViewEnum";
import { ApplicationState } from "../../applicationState";
import { Booking } from "../../bookings/models";
import { BookingSummaryGridRows } from "../../bookings/components/BookingSummaryGridRows";
import { RebookButton } from "../../global/RebookButton";
import { ResendConfirmationButton } from "../../global/ResendConfirmationButton";
import { Media } from "@common/global/AppMedia";
import { useScrollToTop } from "@common/hooks/useScrollToTop";
import { BookingApi } from "@booking/bookings/bookingApi";
import { PrintableBookingConfirmation } from "@booking/bookings/components/PrintableBookingConfirmation";
import { DorsPortalEditInstruction } from "@booking/common/DorsPortalEditInstruction";
import { useCanRebook } from "@booking/global/hooks/useCanRebook";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface StateProps {
    eventInstance: EventInstanceDetailModel;
    booking: Booking;
    organisation?: OrganisationDetailModel;
    finishBookingTime?: moment.Moment;
    path: string;
}

interface DispatchProps {
    loadEventInstance: (eventInstanceId: string) => void;
    loadPoliceOrganisationDetails: (forceId: number) => void;
    redirect: (url: string) => void;
}

type OutstandingBalancePaymentConfirmationProps = StateProps & DispatchProps;

const OutstandingBalancePaymentConfirmationInternal: React.FC<OutstandingBalancePaymentConfirmationProps> = (props) => {
    const { eventInstance, organisation, booking, loadEventInstance, loadPoliceOrganisationDetails, path, finishBookingTime } = props;
    const eventTypeName = (eventInstance) ? eventInstance.eventTypeName : null;

    const courseAllowsRebooking = useCanRebook(eventInstance);
    const isFreshBooking = finishBookingTime !== null && moment().diff(finishBookingTime, "minutes") < 30;
    const bookingId = useSelector((state: ApplicationState) => bookingIdSelector(state));
    const [bookingPaymentsInfo, setBookingPaymentsInfo] = React.useState<BookingPaymentsInfo>(null);
    const [showPrint, setShowPrint] = React.useState(false);

    useScrollToTop();

    useEffect(() => {
        const currentEventInstance = booking.originalEventInstanceId ?? booking.reservedEventInstanceId;
        if (!eventInstance && currentEventInstance) {
            loadEventInstance(currentEventInstance);
        }

        if (!organisation && booking && booking.forceId) {
            loadPoliceOrganisationDetails(booking.forceId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking, eventInstance, organisation]);

    useEffect(() => {
        async function getPaymentInfo() {
            if (!bookingPaymentsInfo) {
                const paymentApi = new PaymentApi(bookingId);
                const response = await paymentApi.getBookingPaymentsBalance();
                setBookingPaymentsInfo(response);
            }
        }

        getPaymentInfo();
    }, [bookingId, bookingPaymentsInfo]);

    const [t] = useTranslation("OutstandingBalancePaymentConfirmation");

    const getActiveScheduledPaymentsPlanValue = () => {
        if (bookingPaymentsInfo?.scheduledPaymentPlans?.length === 0 ?? false) {
            return undefined;
        }

        return bookingPaymentsInfo?.scheduledPaymentPlans?.reduce((sum, curr) => {
            if (curr.status === ScheduledPaymentStatus.Awaits) {
                sum += (curr.amountInPence / 100);
            }

            return sum;
        }, 0);
    };

    const printConfirmation = React.useCallback(() => {
        const api = new BookingApi(bookingId);
        api.logprintbookingconfirmation();
        setShowPrint(true);
    }, [bookingId]);

    return (
        <>
            <Segment clearing className="welcome-title">
                <h3>{t("SUCCESSFUL_PAYMENT")}</h3>
                <p className="hide-on-mobile">
                    {eventTypeName && (
                        <Trans i18nKey="BookingConfirmation:WELCOME"
                            values={{
                                eventTypeName: eventInstance.eventTypeName,
                                eventTypeNameCy: eventInstance.eventTypeNameCy ?? eventInstance.eventTypeName
                            }}>
                            Below are the details of your <strong>{{ eventTypeName }}</strong> course booking.<br />
                            If you supplied an email address we will send you confirmation of your booking.
                        </Trans>)}
                </p>
            </Segment>
            <Media greaterThanOrEqual="tablet">
                <Grid className="two-section-layout">
                    <Grid.Column width={10} className="two-section-layout-left">
                        <div className="personal-details">
                            <Grid.Row>
                                <h4 className="underline not-mobile personal-details-title">{t("YOUR_PERSONAL_DETAILS")}</h4>
                            </Grid.Row>
                            <BookingSummaryGridRows booking={booking} showBookingReference showPersonalDetails />
                            <DorsPortalEditInstruction workflowType={eventInstance?.workflowType} />
                        </div>
                        {booking && booking.seatBooked && booking.email &&
                            <ResendConfirmationButton />
                        }
                        <Button className="print-button" onClick={printConfirmation}>{t("PRINT")}</Button>
                    </Grid.Column>
                    <Grid.Column width={6} className="two-section-layout-right">
                        <Grid.Row>
                            <ChosenCourse
                                eventInstance={eventInstance}
                                fullDetails={true}
                                showLinks={true}
                                waivedRebookingFee={booking.waivedRebookingFee}
                                freshConfirmation={isFreshBooking}
                                payAmount={bookingPaymentsInfo?.mostRecentPaymentValue}
                                currentView={View.OutstandingBalancePaymentConfirmation}
                                amountDue={bookingPaymentsInfo?.totalAmountDue}
                                scheduledPaymentAmount={getActiveScheduledPaymentsPlanValue()}
                                organisation={organisation}
                            />
                        </Grid.Row>
                        <RebookButton
                            courseAllowsRebooking={courseAllowsRebooking}
                            path={path}
                            booking={booking}
                            isFreshBooking={false}
                        />
                    </Grid.Column>
                </Grid>
            </Media>
            <Media lessThan="tablet">
                <Grid className="booking-details">
                    <Grid.Column width={16} className="no-side-padding">
                        <Grid.Row>
                            <ChosenCourse
                                eventInstance={eventInstance}
                                fullDetails={true}
                                showLinks={true}
                                freshConfirmation={isFreshBooking}
                                payAmount={bookingPaymentsInfo?.mostRecentPaymentValue}
                                currentView={View.OutstandingBalancePaymentConfirmation}
                                amountDue={bookingPaymentsInfo?.totalAmountDue}
                                scheduledPaymentAmount={getActiveScheduledPaymentsPlanValue()}
                                organisation={organisation}
                            />
                        </Grid.Row>
                        <RebookButton
                            courseAllowsRebooking={courseAllowsRebooking}
                            path={path}
                            booking={booking}
                            isFreshBooking={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={16} className="personal-details">
                        <Grid.Row>
                            <h4 className="underline not-mobile personal-details-title">{t("YOUR_PERSONAL_DETAILS")}</h4>
                        </Grid.Row>
                        <BookingSummaryGridRows booking={booking} showBookingReference showPersonalDetails />
                        <DorsPortalEditInstruction workflowType={eventInstance?.workflowType} />
                    </Grid.Column>
                    {booking && booking.seatBooked && booking.email &&
                        <ResendConfirmationButton />
                    }
                    <Button className="print-button" onClick={printConfirmation}>{t("PRINT")}</Button>
                </Grid>
            </Media>
            <PrintableBookingConfirmation showPrintView={showPrint} bookingId={booking.id} setShowPrint={setShowPrint} />
        </>
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    const { booking, eventInstances, organisations, router } = state;
    const currentEventInstance = booking.originalEventInstanceId ?? booking.reservedEventInstanceId;
    const eventInstance = eventInstances.find(e => e.id === currentEventInstance);
    const organisation = booking?.forceId ? organisations.find(e => e.dorsId === booking.forceId) : undefined;
    return {
        eventInstance,
        organisation,
        booking,
        finishBookingTime: router.query.finishDateTime !== null ? moment(router.query.finishDateTime) : undefined,
        path: bookingBasePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        loadEventInstance: (eventInstanceId) => dispatch(loadEventInstanceDetailsForBooking({ eventInstanceId })),
        loadPoliceOrganisationDetails: (forceId) => dispatch(loadPoliceOrganisationDetailsForBooking({ forceId })),
        redirect: (url) => dispatch(push(url))
    };
}

export const OutstandingBalancePaymentConfirmation = connect(mapStateToProps, mapDispatchToProps)(OutstandingBalancePaymentConfirmationInternal);
