/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { Button, Container, Grid, Icon, Message, Popup, Segment, Tab } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole, TtcFinanceAdministratorRole, TtcPlannerRole, TtcTester } from "@common/auth/model";
import { useDispatch, useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { CancelBookingModal } from "@common/crud/attendee/components/details/CancelBookingModal";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { push } from "redux-little-router";
import { ScheduledPaymentStatus } from "@common/payments/model";
import { SpecialRequirementsLabelWithEdit } from "@common/specialRequirements/SpecialRequirementsEditModal";
import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import {
    adjustAdditionalFees,
    createCertificateForAttendeeDetail,
    setEligibleForConcessions,
    updatePositiveBalanceExemptionStatus
} from "@common/crud/attendee/actions";
import { ScheduledPaymentDetails } from "@common/payments/components/scheduled-payments/ScheduledPaymentDetails";
import { cancelAttendeeScheduledPaymentPlan, updateAttendeeSpecialRequirements } from "../../actions";
import { CoreDetailProps } from "../Detail";
import { AttendeeApi } from "../..";
import { ContactDetailsModal } from "./ContactDetailsModal";
import { ActionType, ResendCommsModal } from "./ResendCommsModal";
import { AttendeeCarDetails } from "./AttendeeCarDetails";
import { AutoRemovalDateLabelWithEdit } from "./AutoRemovalDateLabelWithEdit";
import { RefundModal } from "./RefundModal";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { RebookButton } from "./RebookButton";
import { AdHocCommsModal } from "./AdHocComms/AdHocCommsModal";
import { AssistanceRequired, DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { GetCurrentAndNextDorsStatesResponse } from "@common/crud/common/LookupResponse";
import { SupportRequestsLabelWithEdit } from "@common/crud/dorsBooking/components/SupportRequestsLabelWithEdit";
import { setAssistanceRequiredForBooking } from "@common/crud/dorsBooking/actions";
import { AlaskaNudgeTaskEnum, TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { formatCurrency } from "@common/formating";
import { AdditionalFeesLabelWithEdit } from "@common/crud/attendee/components/details/AdditionalFeesLabelWithEdit ";
import { SpecialRequirementsAttribute } from "@common/crud/eventInstance/model";
import { UndoCancellationModal } from "./UndoCancellationModal";
import {
    AttendeeInfoMessage,
    AttendeeInfoMessages,
    AttendeeInfoMessagesModal
} from "@common/crud/eventInstance/components/details/AttendeeInfoMessagesModal";
import { taskTypeQuerySelector } from "../../selectors";
import {
    DrinkDriveOffenderCaseDetails
} from "@common/crud/drinkDriveOffenders/components/details/DrinkDriveOffenderCaseDetails";
import { DorsDetails } from "./DorsDetails";
import { Attendee, DocumentType, EventTypeCategory } from "@common/crud/attendee/model";
import { IvrPaymentActions } from "@common/crud/dorsBooking/components/details/IvrPaymentActions";
import { DdrsProviderTransferModal } from "@common/crud/drinkDriveOffenders/components/details/DdrsProviderTransferModal";
import { isEmptyObject } from "@common/helpers/is-empty-object";
import { FlexiPayStatusLabelWithEdit } from "./FlexiPayStatusLabelWithEdit";
import { FreeRebookingModal } from "./FreeRebookingModal";
import { FreeRebookingMessage } from "./FreeRebookingMessage";
import { DorsBooking, DorsBookingApi } from "@common/crud/dorsBooking";
import { ConcessionsButton } from "@common/crud/drinkDriveOffenders/components/details/ConcessionsButton";
import {
    SetDrinkDriveInitialBookingDate
} from "@common/crud/drinkDriveOffenders/components/SetDrinkDriveInitialBookingDate";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { CertificateActions } from "@common/crud/eventInstance/components/certificates/Ddrs/CertificateActions";
import { CountryEnum } from "@common/crud/organisation/model";
import { attendeeHasDocument, downloadLink, unsupportedCountry } from "@common/crud/eventInstance/components/certificates/Ddrs/Helpers";
import { GenderDisplay } from "@common/crud/drinkDriveOffenders/components/GenderDisplay";
import { GenderEnum } from "@common/crud/drinkDriveOffenders/model";
import { DdrsAttendeeLetterPrintView } from "@common/ddrsLetters/components/DdrsAttendeeLetterPrintView";
import { eventTypeSelector } from "@common/crud/eventType/selectors";
import { loadEventTypeDetail } from "@common/crud/eventType/actions";
import { unlockDrinkDriveOffenderAccount } from "@common/crud/drinkDriveOffenders/actions";
import { CourseEndLetterModal } from "./CourseEndLetterModal";
import { VatReceiptModal } from "./VatReceiptModal";
import { CancellationCommunicationsModal } from "./CancellationCommunicationsModal";
import { AddPaymentModal } from "./AddPaymentModal";

export const DetailsTab: React.FunctionComponent<CoreDetailProps> = ({ attendee, internalAppSettings, eventTypes }) => {
    const [expiryDate, setExpiryDate] = React.useState<moment.Moment>(attendee?.offerExpiry);
    const [attendeeAlreadyBookedAndCompleted, setAttendeeAlreadyBookedAndCompleted] = React.useState<boolean>();
    const [offerWithdrawn, setOfferWithdrawn] = React.useState<boolean>();
    const [dorsAttendanceStatus, setDorsAttendanceStatus] = React.useState<DorsAttendanceStatusesEnum>(DorsAttendanceStatusesEnum.NotSet);
    const [dorsResponse, setDorsResponse] = React.useState<GetCurrentAndNextDorsStatesResponse>(null);
    const [loadingNewAttendeeRecord, setLoadingNewAttendeeRecord] = React.useState(false);
    const [reloadNextDorsState, setReloadNextDorsState] = React.useState(true);
    const [refreshingRecord, setRefreshingRecord] = React.useState(false);
    const [booking, setBooking] = React.useState<DorsBooking>(null);
    const taskType = useSelector(taskTypeQuerySelector);
    const dispatch = useDispatch();
    const isTester = useSelector(currentUserIsInRoleSelector(TtcTester));
    const eventType = useSelector(eventTypeSelector(booking?.eventTypeId));
    const [mustLoad, setMustLoad] = React.useState<boolean>(true);

    React.useEffect(() => {
        setExpiryDate(attendee?.offerExpiry);
    }, [attendee]);

    React.useEffect(() => {
        if (refreshingRecord) {
            setRefreshingRecord(false);
        }
    }, [refreshingRecord]);

    React.useEffect(() => {
        const getBooking = async () => {
            const response = await new DorsBookingApi().detail(attendee.correlationId);
            setBooking(response);

            if (response?.eventTypeId) {
                dispatch(loadEventTypeDetail({ eventTypeId: response.eventTypeId }));
            }
            setMustLoad(false);
        };
        if (attendee?.correlationId && mustLoad) {
            getBooking();
        }
    }, [attendee, dispatch, mustLoad]);

    React.useEffect(() => {
        let isSubscribed = true;
        let pollingCall: NodeJS.Timeout;

        async function syncBookingDataWithDors(eventInstanceId: string, attendeeId: string) {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const response = await attendeeApi.getCurrentAndNextDorsStates(attendeeId);
            if (isSubscribed) {
                setExpiryDate(response.responseFromDors.expiryDate);
                setAttendeeAlreadyBookedAndCompleted(response.responseFromDors.dorsAttendanceStatus === DorsAttendanceStatusesEnum.AttendedAndCompleted);
                setOfferWithdrawn(response.responseFromDors.dorsAttendanceStatus === DorsAttendanceStatusesEnum.OfferWithdrawn);
                setDorsAttendanceStatus(response.responseFromDors.dorsAttendanceStatus);
                setDorsResponse(response);
            }

            if (!response.responseFromDors.statusUpdateInProgress) {
                setReloadNextDorsState(false);
                if (pollingCall) {
                    clearTimeout(pollingCall);
                }
                pollingCall = undefined;
            }
            else {
                pollingCall = setTimeout(() => {
                    if (attendee.externalAttendeeId) {
                        syncBookingDataWithDors(attendee.eventInstanceId, attendee.id);
                    }
                }, 3000);
            }
        }

        if (attendee.externalAttendeeId && reloadNextDorsState && booking?.eventTypeCategory === EventTypeCategory.Dors) {
            syncBookingDataWithDors(attendee.eventInstanceId, attendee.id);
        }

        return () => {
            isSubscribed = false;
            if (pollingCall) {
                clearInterval(pollingCall);
            }
        };
    }, [attendee.eventInstanceId, attendee.externalAttendeeId, attendee.id, reloadNextDorsState, booking?.eventTypeCategory]);

    const eventInstance = useSelector(eventInstanceSelector);
    const startTimeString = (eventInstance.startTime && eventInstance.startTime.format(DateFormat.Time, { trim: false })) || "";
    const endTimeString =
        (eventInstance.startTime && eventInstance.eventDuration && eventInstance.startTime.clone().add(eventInstance.eventDuration)
            .format(DateFormat.Time, { trim: false }));
    const startDateString = (eventInstance.startDate && eventInstance.startDate.format("dddd Do MMMM YYYY")) || "";
    const policeForceBookedWith = useSelector(policeOrganisationByDorsId(attendee.forceId));

    const attendeeDisplayAddress = <DisplayAddress address={attendee.address} />;
    const venueAddressDisplayAddress = <DisplayAddress address={eventInstance.venueAddress} />;

    function adjustAdditionalFeesAction(feeAdjustmentAmount: number) {
        dispatch(adjustAdditionalFees(attendee.id, attendee.eventInstanceId, feeAdjustmentAmount));
    }

    function updateSpecialRequirementsAction(requirements: SpecialRequirements, sendComms: boolean) {
        if (eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital) {
            requirements.venueAttributes = [];

            const classroomOnlyAttributes = [
                SpecialRequirementsAttribute.HearingLoop,
                SpecialRequirementsAttribute.Projector,
                SpecialRequirementsAttribute.TextToSpeechReporter,
                SpecialRequirementsAttribute.Translator
            ];
            requirements.eventAttributes = requirements.eventAttributes.filter(attr => !classroomOnlyAttributes.includes(attr));
        }
        dispatch(updateAttendeeSpecialRequirements(attendee.id, attendee.eventInstanceId, requirements, sendComms));
    }

    function updateSupportRequests(model: AssistanceRequired) {
        dispatch(setAssistanceRequiredForBooking(attendee.correlationId, model));
    }

    function manualPaymentOnClick() {
        dispatch(push(`${attendee.id}/manual-payment`));
    }

    function onCancelPaymentClick() {
        dispatch(cancelAttendeeScheduledPaymentPlan(attendee.id, attendee.eventInstanceId));
    }

    function onRemoveExemptionClick() {
        dispatch(updatePositiveBalanceExemptionStatus(attendee.id, attendee.eventInstanceId, !attendee.exemptedFromPositiveBalanceProcesses));
    }

    function onConcessionsClick() {
        dispatch(setEligibleForConcessions(attendee.id, attendee.eventInstanceId, attendee.correlationId, !concessions));
    }

    function createCertificate(attendeeId: string, docType: DocumentType) {
        dispatch(createCertificateForAttendeeDetail(attendeeId, eventInstance.id, docType));
    };

    const getDocType =(ddrsAttendee: Attendee) => {
        switch (ddrsAttendee?.referredCourtCountry) {
            case CountryEnum.England:
                return DocumentType.EnglishNonCompletionCertificate;
            case CountryEnum.Wales:
                return DocumentType.WelshNonCompletionCertificate;
            case CountryEnum.NIreland:
                return DocumentType.NIrelandNonCompletionCertificate;
            default:
                return DocumentType.Unknown;
        }
    };

    const [undoCancellationModalOpen, setUndoCancellationModalOpen] = React.useState(false);
    const [freeRebookingModalOpen, setFreeRebookingModalOpen] = React.useState(false);

    function openCancellationModal() { setUndoCancellationModalOpen(true); }
    function openFreeRebookingModal() { setFreeRebookingModalOpen(true); }

    // eslint-disable-next-line eqeqeq
    const eventStillAvailable = !eventInstance?.cancelled && (eventInstance?.reasonForHidingEvent == null
        || eventInstance?.reasonForHidingEvent?.length < 1) && eventInstance?.trainers?.length >= 1;

    const allowUndoCancellation = eventStillAvailable && loadingNewAttendeeRecord === false;
    const unCancellationLink = allowUndoCancellation
        ? <a onClick={openCancellationModal} className="cursor-pointer">Click here to un-do the cancellation.</a>
        : (
            <Popup
                content={"Unable to revert cancellation as previous course is no longer available"}
                trigger={<a className="greyed-out">Click here to un-do the cancellation.</a>}
                position="top center"
            />
        );

    const messageFilters: ((message: AttendeeInfoMessage) => boolean)[] = [
        m => Number(taskType) === AlaskaNudgeTaskEnum.RefundTasks ? m.header !== "Attendee did not complete" : true,
        m => attendee?.eventTypeCategory !== EventTypeCategory.Dors ? m.header !== "Attendee status is unknown" : true
    ];
    const attendeeInfoMessages = messageFilters.reduce((messages, filter) => messages = messages.filter(filter), AttendeeInfoMessages);

    const concessions = !!attendee.drinkDriveOffenderDetails?.eligibleForConcessions;

    const attendeeDOBMoment = moment(attendee?.drinkDriveOffenderDetails?.dateOfBirth);
    const attendeeDOBView = attendeeDOBMoment.isValid() ?
        attendeeDOBMoment.format(DateFormat.Short):"N/A";

    const unlockAccount = React.useCallback(async () => {
        await dispatch(unlockDrinkDriveOffenderAccount({ id: booking.id }));
        setMustLoad(true);
    }, [dispatch, booking?.id]);

    return (
        <Tab.Pane className="no-border">
            <Grid stackable>
                <UndoCancellationModal
                    eventInstance={eventInstance}
                    modalOpen={undoCancellationModalOpen}
                    setModalOpen={setUndoCancellationModalOpen}
                    attendee={attendee}
                    setLoadingNewAttendee={setLoadingNewAttendeeRecord}
                />
                <FreeRebookingModal
                    open={freeRebookingModalOpen}
                    eventInstance={eventInstance}
                    attendee={attendee}
                    setOpen={setFreeRebookingModalOpen}
                />
                {attendee.isBookingCanceled &&
                    <Grid.Column width={16}>
                        <Message as={Segment} error visible={loadingNewAttendeeRecord === false}>
                            <p>This attendee is cancelled. <Authorize authorize={[TtcClientAdvisorRole, TtcPlannerRole]}>
                                {unCancellationLink}
                            </Authorize>
                            </p>
                            {attendee.canUseFreeRebookingAddedByCa && <FreeRebookingMessage attendee={attendee} onLinkClick={openFreeRebookingModal} />}
                        </Message>
                    </Grid.Column>
                }
                {!attendee.isBookingCanceled && eventInstance?.reasonForHidingEvent &&
                    <Grid.Column width={16}>
                        <Message as={Segment} warning visible={loadingNewAttendeeRecord === false}>
                            <p>This attendee is on a hidden course. Reason for hiding: {eventInstance.reasonForHidingEvent}</p>
                            {attendee.canUseFreeRebookingAddedByCa && <FreeRebookingMessage attendee={attendee} onLinkClick={openFreeRebookingModal} />}
                        </Message>
                    </Grid.Column>
                }
                {attendee.isTransferred &&
                    <Grid.Column width={16}>
                        <Message as={Segment} warning>
                            <p>This attendee was transferred to {" "}
                                <strong>{attendee.drinkDriveOffenderDetails?.transferProvider}</strong> on {" "}
                                {attendee.drinkDriveOffenderDetails?.transferDate?.format(DateFormat.LongWithTime)}
                            </p>
                        </Message>
                    </Grid.Column>
                }
                {loadingNewAttendeeRecord === false &&
                    <>
                        <Grid.Column width={8}>
                            {attendee && !isEmptyObject(attendee) && eventInstance &&
                                <AttendeeInfoMessagesModal
                                    bookingId={attendee.correlationId}
                                    attendee={attendee}
                                    eventInstance={eventInstance}
                                    messages={attendeeInfoMessages}
                                    lookupResponse={dorsResponse?.responseFromDors}
                                />
                            }
                            <Container className="white-panel">
                                {booking?.accountIsLocked && <>
                                    <h5>Account is locked</h5>
                                    <span>The account has been locked due to excessive failed login attempts.
                                    If you are satisfied you are talking to the correct individual
                                    and you have carefully verified their identity, you may unlock the account by clicking the button </span>
                                    <Button onClick={unlockAccount} >Unlock account</Button>
                                </>
                                }
                                <h5>Attendee details</h5>
                                <LabelAndValue label="Name" value={attendee.fullName} />
                                {attendee.eventTypeCategory === EventTypeCategory.Ddrs &&
                                 <>
                                     <LabelAndValue label="Date Of Birth" value={attendeeDOBView} />
                                     <LabelAndValue label="Gender" value={
                                         <GenderDisplay gender={attendee?.drinkDriveOffenderDetails?.gender &&
                                        attendee.drinkDriveOffenderDetails.gender || GenderEnum.None} />} />
                                 </>}
                                <LabelAndValue label="Address" value={attendeeDisplayAddress} />
                                <LabelAndValue label="Telephone" value={attendee.telephone || "None"} />
                                <LabelAndValue label="Email" value={attendee.email || "None"} />
                                {attendee.mxEmailCheckResult && !attendee.mxEmailCheckResult.valid && (
                                    <Message as={Segment} className="cancel-action">
                                        <p><Icon className="validation-icon" name={"exclamation circle"} />{attendee.mxEmailCheckResult.error}</p>
                                    </Message>
                                )}
                                <Authorize authorize={TtcClientAdvisorRole}>
                                    <ContactDetailsModal
                                        attendee={attendee} />
                                </Authorize>
                                {attendee.eventTypeCategory === EventTypeCategory.Dors &&
                                    <DorsDetails
                                        attendee={attendee}
                                        booking={booking}
                                        liveDorsResponse={dorsResponse}
                                        liveExpiryDate={expiryDate}
                                        reloadNextDorsState={setReloadNextDorsState}
                                        eventTypes={eventTypes}
                                    />}
                            </Container>
                            {attendee.eventTypeCategory === EventTypeCategory.Ddrs &&
                                <DrinkDriveOffenderCaseDetails drinkDriveOffender={attendee.drinkDriveOffenderDetails}
                                    expiryDate={attendee.offerExpiry} externalAttendeeId={attendee.externalAttendeeId} />}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Container className="blue-panel">
                                <h5>Course details {<WelshLanguageIndicator language={eventInstance.language} />}</h5>
                                <LabelAndValue label="Scheme" value={eventInstance.eventTypeName} />
                                <LabelAndValue label="Date and time" value={`${startDateString}, ${startTimeString} - ${endTimeString}`} />
                                <LabelAndValue label="Venue" value={venueAddressDisplayAddress} />
                                <Authorize authorize={[TtcClientAdvisorRole]}>
                                    <SupportRequestsLabelWithEdit
                                        correlationId={attendee.correlationId}
                                        updateSupportRequests={updateSupportRequests}
                                        taskRelation={TaskRelationEnum.Attendee}
                                        booking={booking}
                                    />
                                </Authorize>
                                <SpecialRequirementsLabelWithEdit
                                    specialRequirements={attendee.specialRequirements}
                                    updateSpecialRequirements={updateSpecialRequirementsAction}
                                    isDigitalCourse={eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital} />
                                <AttendeeCarDetails attendee={attendee} editable />
                                {policeForceBookedWith?.name &&
                                    <LabelAndValue label="Force area" value={policeForceBookedWith && policeForceBookedWith.name} />}
                                <div className="attendee-actions">
                                    <Authorize authorize={[TtcClientAdvisorRole]}>
                                        <RebookButton
                                            attendee={attendee}
                                            internalAppSettings={internalAppSettings}
                                            attendeeAlreadyBookedAndCompleted={attendeeAlreadyBookedAndCompleted}
                                            offerWithdrawn={offerWithdrawn}
                                            dorsAttendanceStatus={dorsAttendanceStatus} />
                                        <IvrPaymentActions
                                            noRetryButton={true}
                                            bookingId={attendee?.correlationId}
                                            bookingAppEndpoint={internalAppSettings.bookingAppEndpoint}
                                        />
                                        {!attendee.isBookingCanceled &&
                                            <>
                                                <CancelBookingModal
                                                    correlationId={attendee.correlationId}
                                                    eventTypeCategory={attendee.eventTypeCategory}
                                                    attendeeId={attendee.id}
                                                    eventInstanceId={attendee.eventInstanceId}
                                                    setRefreshingRecord={setRefreshingRecord} />
                                                <ResendCommsModal recipient={attendee} actionType={ActionType.Confirmation} buttonText={"Send Confirmation"} />
                                                <ResendCommsModal recipient={attendee} actionType={ActionType.Reminder} buttonText={"Send Reminder"} />
                                            </>
                                        }
                                        <AdHocCommsModal
                                            correlationId={attendee.correlationId}
                                            attendeeId={attendee.id}
                                            eventInstanceId={attendee.eventInstanceId}
                                            attendeeEmail={attendee.email}
                                            attendeeNumber={attendee.telephone} />
                                        {attendee.isBookingCanceled &&
                                            <CancellationCommunicationsModal
                                                attendeeId={attendee.id}
                                                attendeeEmail={attendee.email}
                                                attendeeTelephone={attendee.telephone}
                                                eventInstanceId={attendee.eventInstanceId}
                                            />
                                        }
                                        {attendee.eventTypeCategory === EventTypeCategory.Ddrs &&
                                                <>
                                                    <DdrsProviderTransferModal attendee={attendee} />
                                                    {isTester && booking && <SetDrinkDriveInitialBookingDate externalAttendeeId={attendee.correlationId} />}
                                                    <br />
                                                    <LabelAndValue label="Certificates" value={
                                                        <CertificateActions attendeeHasDocument={attendeeHasDocument(attendee, getDocType(attendee) )}
                                                            createOnClick={function() {createCertificate(attendee.id, getDocType(attendee));}}
                                                            createDisabled={unsupportedCountry(attendee)}
                                                            downloadLink={downloadLink(attendee, getDocType(attendee), eventInstance.id)}
                                                            buttonText={"Create Non Completion Certficiate"}
                                                        />
                                                    }
                                                    />
                                                    <LabelAndValue label="Letters" value={
                                                        <>
                                                            <CourseEndLetterModal buttonText={"Print Completion Letter"}
                                                                disabled={!eventType?.completionLetterWordingEn}>
                                                                <DdrsAttendeeLetterPrintView attendee={attendee}
                                                                    openingWording={eventType?.completionLetterWordingEn} />
                                                            </CourseEndLetterModal>
                                                            <CourseEndLetterModal buttonText={"Print Non-Completion Letter"}
                                                                disabled={!eventType?.nonCompletionLetterWordingEn}>
                                                                <DdrsAttendeeLetterPrintView attendee={attendee}
                                                                    openingWording={eventType?.nonCompletionLetterWordingEn} />
                                                            </CourseEndLetterModal>
                                                        </>
                                                    }
                                                    />
                                                </>
                                        }
                                    </Authorize>
                                </div>
                            </Container>
                            <Container className="blue-panel">
                                <h5>Payment details</h5>
                                <LabelAndValue
                                    label="Course price"
                                    value={formatCurrency(attendee.eventInstancePriceInPence ?? eventInstance.priceInPence)} />
                                {attendee.eventTypeCategory === EventTypeCategory.Ddrs && ConcessionsButton(concessions, onConcessionsClick)}
                                <LabelAndValue label="Flexi-booking fee" value={attendee.flexiPayFees > 0 ? formatCurrency(attendee.flexiPayFees) : "N/A"} />
                                <FlexiPayStatusLabelWithEdit attendee={attendee} booking={booking} eventInstance={eventInstance} />
                                <AdditionalFeesLabelWithEdit
                                    additionalCourseFees={attendee.additionalCourseFees}
                                    adjustAdditionalFeesAmount={adjustAdditionalFeesAction} />
                                <LabelAndValue label="Total payments" value={formatCurrency(attendee.totalAmountPaid)} />
                                <LabelAndValue label="Remaining balance" value={formatCurrency(attendee.totalAmountDue)} />
                                <ScheduledPaymentDetails plans={attendee.scheduledPaymentPlans} />
                                <Authorize authorize={[TtcClientAdvisorRole]}>
                                    {!attendee.isBookingCanceled &&
                                        <AutoRemovalDateLabelWithEdit
                                            attendee={attendee}
                                            eventInstanceDate={eventInstance.startDate}
                                            disabled={eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital} />
                                    }
                                    <RefundModal
                                        correlationId={attendee.correlationId}
                                        eventInstanceId={attendee.eventInstanceId}
                                        attendeeId={attendee.id}
                                        totalAmountDue={attendee.totalAmountDue}
                                        totalAmountPaid={attendee.totalAmountPaid}
                                        workflowType={eventInstance.workflowType}
                                    />
                                    <Button onClick={manualPaymentOnClick}>Make a payment</Button>
                                    <Authorize authorize={[TtcFinanceAdministratorRole]}>
                                        <AddPaymentModal
                                            attendeeId={attendee.id}
                                            correlationId={attendee.correlationId}
                                            eventInstanceId={attendee.eventInstanceId}
                                        />
                                    </Authorize>
                                    <VatReceiptModal
                                        attendeeId={attendee.id}
                                        attendeeEmail={attendee.email}
                                        eventInstanceId={attendee.eventInstanceId}
                                    />
                                    {attendee?.scheduledPaymentPlans?.some(x => x.status === ScheduledPaymentStatus.Awaits) &&
                                        <Button onClick={onCancelPaymentClick}>Cancel Payments</Button>
                                    }
                                    {attendee?.exemptedFromPositiveBalanceProcesses &&
                                        <Button onClick={onRemoveExemptionClick}>Balance Verified</Button>
                                    }
                                    {attendee.refundError && <Message as={Segment} error><h5>{attendee.refundError}</h5></Message>}
                                </Authorize>
                            </Container>
                        </Grid.Column>
                    </>
                }
            </Grid>
        </Tab.Pane>
    );
};
