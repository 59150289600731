import * as React from "react";
import { TypedTable } from "@common/crud/common/TypedTable";
import { EventInstance } from "../..";
import { EventType } from "@common/crud/eventType";
import { useSelector } from "react-redux";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { GetEventMaxDigitalAttendees } from "@common/crud/eventType/model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { CountryEnum } from "@common/crud/organisation/model";

export interface PlannedEventsTableProps {
    plannedEventInstances: EventInstance[];
    eventTypeCategory: EventTypeCategory;
    title: string;
    countProtected?: boolean;
}

interface SchemeEventsSummary {
    id: string;
    schemeAbbreviation: string;
    courses: number;
    seats: number;
    englandCourses: number;
    walesCourses: number;
    scotlandCourses: number;
    northernIrelandCourses: number;
}

const createSchemeEventsSummaryForEventType =
(eventType: EventType, events: EventInstance[], isDdrs: boolean, countProtected: boolean): SchemeEventsSummary => {
    let seats = 0;
    let coursesOfEventType = 0;
    let englandCourses = 0;
    let walesCourses = 0;
    let scotlandCourses = 0;
    let northernIrelandCourses = 0;

    events.filter(e => e.protected !== true || countProtected).forEach(e => {
        if (e.eventTypeId === eventType.id && (!isDdrs || e.id === e.dayParts[0])) {
            seats += GetEventMaxDigitalAttendees(e.startDate, eventType);
            coursesOfEventType++;

            if (isDdrs) {
                // eslint-disable-next-line default-case
                switch (e.referredCourtCountry) {
                    case CountryEnum.England:
                        englandCourses++;
                        break;
                    case CountryEnum.Wales:
                        walesCourses++;
                        break;
                    case CountryEnum.Scotland:
                        scotlandCourses++;
                        break;
                    case CountryEnum.NIreland:
                        northernIrelandCourses++;
                        break;
                }
            }
        }
    });

    return {
        id: eventType.id,
        schemeAbbreviation: eventType.abbreviation,
        courses: coursesOfEventType,
        seats,
        englandCourses,
        walesCourses,
        scotlandCourses,
        northernIrelandCourses,
    };
};

const getSumOfValues = (key: keyof SchemeEventsSummary, summaries: SchemeEventsSummary[]) => {
    let value = 0;
    summaries.forEach(s => value += s[key] as number);
    return value;
};

export const PlannedEventsTable: React.FC<PlannedEventsTableProps> = ({ plannedEventInstances, eventTypeCategory, title, countProtected  }) => {

    const allEventTypes = useSelector(allEventTypesSelector);
    const isDdrs = eventTypeCategory === EventTypeCategory.Ddrs;
    const schemeEventsSummaries: SchemeEventsSummary[] =
        allEventTypes.map(e => createSchemeEventsSummaryForEventType(e, plannedEventInstances, isDdrs, countProtected));

    const tableStructureArray = [
        {
            header: "Scheme",
            value: (e: SchemeEventsSummary) => e.schemeAbbreviation
        },
        {
            header: "Courses",
            value: (e: SchemeEventsSummary) => e.courses,
            showTotal: true,
            getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("courses", v)}`,
        },
        {
            header: "Seats",
            value: (e: SchemeEventsSummary) => e.seats,
            showTotal: true,
            getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("seats", v)}`,
        },
    ];

    const ddrsCountryCourseValues = [{
        header: "England Courses",
        value: (e: SchemeEventsSummary) => e.englandCourses,
        showTotal: true,
        getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("englandCourses", v)}`,
    },{
        header: "Wales Courses",
        value: (e: SchemeEventsSummary) => e.walesCourses,
        showTotal: true,
        getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("walesCourses", v)}`,
    },
    {
        header: "Scotland Courses",
        value: (e: SchemeEventsSummary) => e.scotlandCourses,
        showTotal: true,
        getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("scotlandCourses", v)}`,
    },
    {
        header: "Northern Ireland Courses",
        value: (e: SchemeEventsSummary) => e.northernIrelandCourses,
        showTotal: true,
        getTotalValue: (v: SchemeEventsSummary[]) => `${getSumOfValues("northernIrelandCourses", v)}`,
    },];

    if (isDdrs) {
        tableStructureArray.splice(1,0, ...ddrsCountryCourseValues);
    }

    return (
        <>
            <h3 className="wide-header">{title}</h3>
            <TypedTable
                values={schemeEventsSummaries}
                emptyValuesArrayMessage="No courses created yet"
            >
                {
                    tableStructureArray
                }
            </TypedTable>
        </>
    );
};
