import * as React from "react";
import moment from "moment";
import { Tab } from "semantic-ui-react";
import { rangeValidator } from "@common/validation";
import { Input } from "@neworbit/simpleui-input";
import {
    ddrsDigitalPropName,
    DigitalEventTypeDetails,
    dorsPropName,
    EventTypePart,
    MultiDayType,
    ProductCategoryEnum,
    WorkflowTypeEnum,
    InitialiseEventTypePartsBasedOnDefaultNumberOfDeliveryDays
} from "../../model";
import { StartTimeForSessionsPicker } from "@common/crud/eventType/components/StartTimeForSessionsPicker";
import { EventTypePartPicker } from "@common/crud/eventType/components/EventTypePartPicker";
import { EditFieldWithEffectiveDate } from "../EditFieldWithEffectiveDate";
import { DateValueViewer,
    EventDurationPicker,
    MaxAttendeesNumberPicker,
    RegistrationDurationPicker
} from "../EventTypeFieldPickerHelpers";
import { DigitalCalendarSessionsTable } from "./DigitalCalendarSessionsTable.tsx";
import { optionsFromObject } from "@common/crud/common";
import { isWorkflowConstruction } from "@common/global/CommonHelpers";

export interface OwnProps {
    digitalEventTypeDetails: DigitalEventTypeDetails;
    workflowType: WorkflowTypeEnum;
    showErrors?: boolean;
    productCategory: ProductCategoryEnum;
    updateStartTimes: (propName: string, startTimes: Dictionary<moment.Duration[]>) => void;
    updateEventTypeParts: (propName: string, startTimes: Dictionary<EventTypePart>) => void;
    onChange(propName: string, termsAndConditionsProperty?: boolean): (value: any, valid: boolean) => void;
    showInOpenBookingApp: boolean;
}

export const DigitalDetails: React.FC<OwnProps> = ({
    digitalEventTypeDetails,
    workflowType,
    showErrors,
    productCategory,
    updateStartTimes,
    updateEventTypeParts,
    onChange,
    showInOpenBookingApp }) => {

    const { eventDurations, educationDurations, availableInDigitalPlanning, defaultNumberOfDigitalDeliveryDays, defaultMultiDayType,
        maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate,
        maxNumberOfDigitalAttendeesWithDuration, digitalEventTypeParts, digitalEventSuggestedStartTimesForSessions, digitalTermsAndConditionsLinkCy,
        digitalTermsAndConditionsLinkEn, spreadingHeadroomWeekday, spreadingHeadroomWeekend, digitalRegistrationDurations,
        digitalBreakEvenPoints
    } = digitalEventTypeDetails;

    const isDors = workflowType === WorkflowTypeEnum.Dors;
    const isDdrs = workflowType === WorkflowTypeEnum.DDRS;
    const hasEducationDuration = workflowType === WorkflowTypeEnum.CPC || productCategory === ProductCategoryEnum.OnRoadWithCpc;
    const isConstructionWorkflow = isWorkflowConstruction(workflowType);

    const useSingleDayControls = isDors || defaultNumberOfDigitalDeliveryDays === 1;

    function updateParts(value: Dictionary<EventTypePart>) {
        updateEventTypeParts(ddrsDigitalPropName, value);
    }

    function updateStarts(value: Dictionary<moment.Duration[]>) {
        updateStartTimes(dorsPropName, value);
    }

    const updateDefaultNumberOfDigitalDeliveryDays = React.useCallback((value: number, valid: boolean) => {
        const newEventTypeParts = InitialiseEventTypePartsBasedOnDefaultNumberOfDeliveryDays(digitalEventTypeParts , value);
        onChange("digitalEventTypeDetails.digitalEventTypeParts")(newEventTypeParts, true);

        onChange("digitalEventTypeDetails.defaultNumberOfDigitalDeliveryDays")(value > 10 ? defaultNumberOfDigitalDeliveryDays : value, valid);
    }, [defaultNumberOfDigitalDeliveryDays, digitalEventTypeParts, onChange]);

    return (
        <Tab.Pane key="digital-details">
            <h3>Course Details</h3>
            {!isDors && <Input.Number
                value={defaultNumberOfDigitalDeliveryDays}
                label={!isDdrs ? "Default Number of Digital Delivery Days" : "Default Number of Delivery Days"}
                validation={rangeValidator(1, 10)}
                showErrors={showErrors}
                onChange={updateDefaultNumberOfDigitalDeliveryDays}
            />}
            {defaultNumberOfDigitalDeliveryDays > 1 && <Input.DropdownNumber
                label="Default Multi Day Type"
                value={defaultMultiDayType}
                options={optionsFromObject(MultiDayType)}
                onChange={onChange("digitalEventTypeDetails.defaultMultiDayType")}
            />}
            {useSingleDayControls && (
                <EditFieldWithEffectiveDate allFields={eventDurations}
                    onChange={onChange("digitalEventTypeDetails.eventDurations")}
                    CreateFieldPicker={EventDurationPicker}
                    showErrors={showErrors}
                    horizontalDisplayTitle="Event Duration"
                    fieldName={"Event Duration"}
                    valueView={DateValueViewer}
                    fieldIsDuration
                />
            )}
            {(useSingleDayControls && hasEducationDuration) && <EditFieldWithEffectiveDate
                allFields={educationDurations}
                onChange={onChange("digitalEventTypeDetails.educationDurations")}
                CreateFieldPicker={EventDurationPicker}
                showErrors={showErrors}
                fieldName={"Education Duration"}
                valueView={DateValueViewer}
                fieldIsDuration
                horizontalDisplayTitle="Education Duration"
            />}
            {useSingleDayControls && <EditFieldWithEffectiveDate allFields={digitalRegistrationDurations}
                onChange={onChange("digitalEventTypeDetails.digitalRegistrationDurations")} CreateFieldPicker={RegistrationDurationPicker}
                showErrors={showErrors} fieldName={"Registration Duration"}  valueView={DateValueViewer} fieldIsDuration />}
            {!useSingleDayControls && <EventTypePartPicker
                eventTypeParts={digitalEventTypeParts}
                updateEventTypeParts={updateParts} defaultDays={defaultNumberOfDigitalDeliveryDays} />}
            {useSingleDayControls && <>
                <h3>Course Planning</h3>
                <StartTimeForSessionsPicker
                    startTimesForSessions={digitalEventSuggestedStartTimesForSessions ?? {}}
                    updateStartTimesForSessions={updateStarts}
                    showErrors={showErrors}
                />
                {isDors &&
                    <DigitalCalendarSessionsTable
                        digitalEventTypeDetails={digitalEventTypeDetails}
                        editable
                        onChange={onChange("digitalEventTypeDetails.sessionsIncludedInDigitalCalendarAutoAdd")} />}
            </>}
            <h3>Course Availability</h3>
            <EditFieldWithEffectiveDate allFields={maxNumberOfDigitalAttendeesWithDuration}
                onChange={onChange("digitalEventTypeDetails.maxNumberOfDigitalAttendeesWithDuration")} CreateFieldPicker={MaxAttendeesNumberPicker}
                showErrors={showErrors} fieldName={"Max number of attendees"} horizontalDisplayTitle={"Max number of digital attendees"} />

            <EditFieldWithEffectiveDate allFields={maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate}
                onChange={onChange("digitalEventTypeDetails.maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate")}
                CreateFieldPicker={MaxAttendeesNumberPicker}
                showErrors={showErrors} fieldName={"Max number of attendees"}
                horizontalDisplayTitle={"Max number of attendees per digital theory trainer"} />
            {isConstructionWorkflow && showInOpenBookingApp && (
                <>
                    <h3>Break Even Points</h3>
                    <EditFieldWithEffectiveDate
                        allFields={digitalBreakEvenPoints}
                        onChange={onChange("digitalEventTypeDetails.digitalBreakEvenPoints")}
                        CreateFieldPicker={MaxAttendeesNumberPicker}
                        showErrors={showErrors}
                        fieldName={"Digital break even points"}
                        horizontalDisplayTitle={"Digital break even points"} />
                </>
            )}
            <h3>Terms and Conditions</h3>
            <Input.Text
                value={digitalTermsAndConditionsLinkEn}
                label="Digital Terms and Conditions Link (English)"
                showErrors={showErrors}
                onChange={onChange("digitalEventTypeDetails.digitalTermsAndConditionsLinkEn", true)}
            />
            <Input.Text
                value={digitalTermsAndConditionsLinkCy}
                label="Digital Terms and Conditions Link (Welsh)"
                showErrors={showErrors}
                onChange={onChange("digitalEventTypeDetails.digitalTermsAndConditionsLinkCy", true)}
            />
            {(isDors || isDdrs) && (
                <>
                    <h3>Spreading</h3>
                    <Input.Number
                        value={spreadingHeadroomWeekday}
                        label="Spreading Headroom Weekday"
                        showErrors={showErrors}
                        onChange={onChange("digitalEventTypeDetails.spreadingHeadroomWeekday")}
                    />
                    <Input.Number
                        value={spreadingHeadroomWeekend}
                        label="Spreading Headroom Weekend"
                        showErrors={showErrors}
                        onChange={onChange("digitalEventTypeDetails.spreadingHeadroomWeekend")}
                    />
                </>
            )}
            <Input.Checkbox
                label="Available in digital planning"
                value={availableInDigitalPlanning}
                showErrors={showErrors}
                onChange={onChange("digitalEventTypeDetails.availableInDigitalPlanning")}
                toggle
            />
        </Tab.Pane>
    );
};
