/* eslint-disable max-lines */
import axios from "axios";
import moment from "moment";
import { toast } from "@common/toasts";
import { SpecialRequirements } from "../common/SpecialRequirements";
import { CarDetails } from "../common/CarDetails";
import { GetCurrentAndNextDorsStatesResponse, LookupResponse } from "../common/LookupResponse";
import {
    AttachedDocumentListModel,
    AttendeeDetailModel,
    AttendeeEditModel,
    AttendeeListModel,
    AttendeesForEventInstanceResponse,
    AttendeeSpecialRequirementsModel, DocumentType, DvsaUploadStatus,
    EditAutoRemovalDateModel,
    EditContactDetailsModel,
    RefundModel,
    RefundResponseModel,
    SendMessageOver,
    UpdateDelegateAttendeesResponse,
    UpdateDelegateAttendeesStatus,
    UpdateDorsStatusModel,
    UpdateFlexiPayModel,
    UpdateFlexiPayResponse,
    UpdateResponse,
    VerifyStripePaymentResult,
    VerifyStripePaymentStatus
} from "./model";
import { ResultResponse } from "@common/model";
import { Seat } from "@booking/seat";
import { DrinkDriveOffenderAttendeeScoresModel } from "../drinkDriveOffenders/model";
import { hasSortedOrderChanged, parseModel } from "./helpers";
import * as QueryString from "query-string";
import { OrderRefundTypeEnum } from "../order/model";

export class AttendeeApi {

    protected readonly attendeeApi: string;
    protected readonly paymentApi = "/api/payment";

    constructor(eventInstanceId: string) {
        this.attendeeApi = `/api/${eventInstanceId}/attendees`;
    }

    public async detail(attendeeId: string, addAuditEntry: boolean = true): Promise<AttendeeDetailModel> {
        const query = QueryString.stringify({ addAuditEntry });
        const response = await axios.get(`${this.attendeeApi}/${attendeeId}?${query}`);
        const model = response.data as AttendeeDetailModel;
        return parseModel(model);
    }

    public async getAttendeesForEventInstance(): Promise<AttendeesForEventInstanceResponse> {
        const response = await axios.get(this.attendeeApi);
        const data = response.data as AttendeesForEventInstanceResponse;
        return ({
            attendees: data.attendees.map(parseModel),
            attendeesFrom: moment(data.attendeesFrom)
        });
    }

    public async getAttendeesForOrderEventInstance(orderId: string): Promise<AttendeesForEventInstanceResponse> {
        const response = await axios.get(`${this.attendeeApi}/forOrder/${orderId}`);
        const data = response.data as AttendeesForEventInstanceResponse;
        return ({
            attendees: data.attendees.map(parseModel),
            attendeesFrom: moment(data.attendeesFrom)
        });
    }

    public async generateCertificate(attendeeIds: string[]): Promise<AttendeesForEventInstanceResponse> {
        const response = await axios.post(`${this.attendeeApi}/generateCertificate`, { attendeeIds });
        const data = response.data as AttendeesForEventInstanceResponse;
        return ({
            attendees: data.attendees.map(parseModel),
            attendeesFrom: moment(data.attendeesFrom)
        });
    }

    public async refund(refundModel: RefundModel): Promise<RefundResponseModel> {
        const response = await axios.post(`${this.paymentApi}`, refundModel);
        return response.data as RefundResponseModel;
    }

    public async cancelBooking(attendeeId: string, rebookReason: string, otherReason: string, freeRebookingEnabled: boolean) {
        const response = await axios.post(`${this.attendeeApi}/cancelbooking`, { attendeeId, rebookReason, otherReason, freeRebookingEnabled });
        const data = response.data as AttendeeListModel;
        return parseModel(data);
    }

    public async cancelCorporateBooking(attendeeId: string, cancellationReason: string, otherReason: string,
        isPaymentStillNeededForBnplOrder: OrderRefundTypeEnum) {
        const response = await axios.post(
            `${this.attendeeApi}/cancelcorporatebooking/${attendeeId}`,
            { cancellationReason, otherReason, isPaymentStillNeededForBnplOrder });
        const data = response.data as AttendeeListModel;
        return parseModel(data);
    }

    public async cancelScheduledPlan(attendeeId: string) {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/cancelscheduledplan`);
        const data = response.data as AttendeeDetailModel;

        return parseModel(data);
    }

    public async resendConfirmation(attendeeId: string, sendMessageOver: SendMessageOver): Promise<ResultResponse> {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/resendconfirmation`, { sendMessageOver });
        return response.data as ResultResponse;
    }

    public async resendReminder(attendeeId: string, sendMessageOver: SendMessageOver, messageType: number): Promise<ResultResponse> {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/resendreminder`, { sendMessageOver, messageType });
        return response.data as ResultResponse;
    }

    public updateContactDetails(attendeeId: string, contactDetailsModel: EditContactDetailsModel) {
        return axios.post(`${this.attendeeApi}/${attendeeId}/updatecontact`, contactDetailsModel);
    }

    public async updateOptionalCertificateRequirement(attendeeId: string, productCategory: number, required: boolean) {
        const response = await axios.patch(
            `${this.attendeeApi}/${attendeeId}/update-optional-certificate?productCategory=${productCategory}&required=${required}`);
        const data = response.data as AttendeeDetailModel;
        return parseModel(data);
    }

    public async setEligibleForConcessions(attendeeId: string, eventInstanceId: string, correlationId: string, eligible: boolean):
        Promise<AttendeeDetailModel> {
        const url = `${this.attendeeApi}/${attendeeId}/set-eligible-for-concessions`;
        const response = await axios.put(url, { eventInstanceId, correlationId, eligible });
        const data = response.data as AttendeeDetailModel;
        return parseModel(data);
    }

    public updateSpecialRequirements(attendeeId: string, specialRequirements: SpecialRequirements, sendComms: boolean) {
        return axios.post(`${this.attendeeApi}/${attendeeId}/updatespecialrequirements${sendComms ? "?sendComms=true" : ""}`, specialRequirements);
    }

    public async adjustAdditionalFees(attendeeId: string, feeAdjustmentAmount: number): Promise<AttendeeDetailModel> {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/adjustadditionalfees`, { feeAdjustmentAmount });
        const model = response.data as AttendeeDetailModel;
        return parseModel(model);
    }

    public async updateFlexiPay(attendeeId: string, model: UpdateFlexiPayModel): Promise<UpdateFlexiPayResponse> {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/updateflexipay`, model);
        return {
            ...response.data as UpdateFlexiPayResponse,
            attendeeDetailModel: response.data.attendeeDetailModel
                ? parseModel(response.data.attendeeDetailModel as AttendeeDetailModel)
                : undefined
        };
    }

    public async updateAttendanceId(attendeeId: string, attendanceId: number): Promise<UpdateResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/updateattendanceid`, { attendanceId });
        return result.data as UpdateResponse;
    }

    public async updateCarDetails(attendeeId: string, carDetails: CarDetails): Promise<UpdateResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/updatecardetails`, carDetails);
        return result.data as UpdateResponse;
    }

    public async updateAutoRemovalDate(attendeeId: string, model: EditAutoRemovalDateModel): Promise<UpdateResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/updateautoremovaldate`, model);
        return result.data as UpdateResponse;
    }

    public async requestDorsStatusUpdate(attendeeId: string, updateDorsStatusModel: UpdateDorsStatusModel): Promise<UpdateResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/updatedorsstatus`, updateDorsStatusModel);
        return result.data as UpdateResponse;
    }

    public async submitRegister(attendees: AttendeeListModel[], isStageTwo: boolean) {
        const response = await axios.post(`${this.attendeeApi}/register/${isStageTwo}`, attendees);
        if (response.data.errorMessage) {
            toast.error(response.data.errorMessage);
        }

        return {
            attendees: response.data.attendees.map(parseModel),
            attendeesFrom: moment(response.data.attendeesFrom)
        };
    }

    public async registerStage(isStageTwo: boolean) {
        await axios.post(`${this.attendeeApi}/registerstage/${isStageTwo}`);
    }

    public async getCurrentAndNextDorsStates(attendeeId: string): Promise<GetCurrentAndNextDorsStatesResponse> {
        const response = await axios.get(`${this.attendeeApi}/${attendeeId}/getCurrentAndNextDorsStates`);
        const model = response.data as GetCurrentAndNextDorsStatesResponse;
        return this.parseGetCurrentAndNextDorsStatesResponse(model);
    }

    public async updatePositiveBalanceExemptionStatus(attendeeId: string, exemptionStatus: boolean) {
        const response = await axios.put(`${this.attendeeApi}/${attendeeId}/updatepositivebalanceexemptionstatus`, { exemptionStatus });
        const data = response.data as AttendeeListModel;
        return parseModel(data);
    }

    public async sendAdHocEmail(attendeeId: string, templateName: string, templateId: number): Promise<ResultResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/sendadhocmail`, { templateName, templateId });
        return result.data as ResultResponse;
    }

    public async sendAdHocSms(attendeeId: string, templateName: string, template: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/sendadhocsms`, { templateName, template });
        return result.data as ResultResponse;
    }

    public async sendEmail(attendeeId: string, eventInstanceId: string, emailSubject: string, customEmailMessage: string) {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/sendcustomemail?eventInstanceId=${eventInstanceId}`,
            { emailSubject,customEmailMessage });
        return result.data as ResultResponse;
    }

    public async sendSms(attendeeId: string, eventInstanceId: string, message: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.attendeeApi}/${attendeeId}/sendsms?message=${message}&eventInstanceId=${eventInstanceId}`);
        return result.data as ResultResponse;
    }

    public async canBeRebooked(attendeeId: string): Promise<boolean> {
        const result = await axios.get(`${this.attendeeApi}/${attendeeId}/canberebooked`);
        return result.data as boolean;
    }

    public async undoCancellation(attendeeId: string, eventInstanceId: string) {
        const result = await axios.post<Seat>(`${this.attendeeApi}/${attendeeId}/undoCancellation`, { attendeeId, eventInstanceId });
        return result.data;
    }

    public async getNewestAttendeeId(seatId: string, eventInstanceId: string) {
        const result = await axios.get<string>(`${this.attendeeApi}/newestAttendeeId?eventInstanceId=${eventInstanceId}&seatId=${seatId}`);
        return result.data;
    }

    public async hasActiveNudges(attendeeId: string, taskType: number) {
        const response = await axios.get(`${this.attendeeApi}/${attendeeId}/active-nudges/${taskType}`);
        return response.data as boolean;
    }

    public async save(eventInstanceId: string, attendee: AttendeeEditModel): Promise<AttendeeDetailModel> {
        const response = await axios.put(`${this.attendeeApi}/${attendee.id}/save`, attendee);
        const model = response.data as AttendeeDetailModel;
        return parseModel(model);
    }

    public async updateFreeRebooking(attendeeId: string, freeRebookingEnabled: boolean): Promise<AttendeeDetailModel> {
        const response = await axios.post(`${this.attendeeApi}/${attendeeId}/updateFreeRebooking`, { freeRebookingEnabled });
        const model = response.data as AttendeeDetailModel;
        return parseModel(model);
    }

    public async bulkUpdateFreeRebooking(freeRebookingEnabled: boolean) {
        return axios.post(`${this.attendeeApi}/bulkUpdateFreeRebooking`, { freeRebookingEnabled });
    }

    public async sendVatReceipt(attendeeId: string, eventInstanceId: string, sendMessageOver: SendMessageOver): Promise<string> {
        try {
            const response = await axios.post(`${this.paymentApi}/send-vat-receipt`, { attendeeId, eventInstanceId, sendMessageOver });

            if (sendMessageOver === SendMessageOver.Email) {
                toast.info("Vat Receipt has been sent over to attendee");
            } else if (sendMessageOver === SendMessageOver.Letter) {
                toast.info("Vat Receipt has been prepared for printing");
            }
            return response.data;
        } catch {
            toast.error("Vat Receipt generation failed, please contact system administrator");
            return null;
        }
    }

    public async verifyPayment(attendeeId: string, correlationId: string, eventInstanceId: string,
        paymentAuthCode: string): Promise<VerifyStripePaymentResult> {
        try {
            const response = await axios.post<VerifyStripePaymentResult>(`${this.paymentApi}/verify-stripe-payment`,
                { attendeeId, correlationId, eventInstanceId, paymentAuthCode });
            return response.data;
        } catch {
            return {
                status: VerifyStripePaymentStatus.Error,
                message: "Payment verification failed, please contact system administrator"
            };
        }
    }

    public async savePayment(attendeeId: string, correlationId: string, eventInstanceId: string, paymentAuthCode: string) {
        const response = await axios.post<AttendeeDetailModel>(`${this.paymentApi}/save-stripe-payment`,
            { attendeeId, correlationId, eventInstanceId, paymentAuthCode });
        toast.success("Payment successfully added");
        return response?.data ? parseModel(response.data) : undefined;
    }

    public async sendCancellationCommunications(attendeeId: string, eventInstanceId: string, sendMessageOver: SendMessageOver): Promise<string> {
        try {
            const response = await axios.post(`${this.attendeeApi}/send-cancellation-communications`, { attendeeId, eventInstanceId, sendMessageOver });

            if (sendMessageOver === SendMessageOver.Email || sendMessageOver === SendMessageOver.SMS || sendMessageOver === SendMessageOver.Both) {
                toast.info("Cancellation communications has been sent over to attendee");
            } else if (sendMessageOver === SendMessageOver.Letter) {
                toast.info("Cancellation letter has been prepared for printing");
            }
            return response.data;
        } catch {
            toast.error("Cancellation communications failed, please contact system administrator");
            return null;
        }
    }

    public parseDorsLookupModel(model: LookupResponse): LookupResponse {
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
        };
    }

    public parseGetCurrentAndNextDorsStatesResponse(model: GetCurrentAndNextDorsStatesResponse): GetCurrentAndNextDorsStatesResponse {
        return {
            ...model,
            responseFromDors: this.parseDorsLookupModel(model.responseFromDors),
        };
    }

    public async logPrintBookingConfirmation(attendeeId: string) {
        return axios.post(`${this.attendeeApi}/${attendeeId}/logprintbookingconfirmation`);
    }

    public async updateDrinkDriveOffenderScores(eventInstanceId: string, model: DrinkDriveOffenderAttendeeScoresModel) {
        const response = await axios.post(`${this.attendeeApi}/update-scores`, model);
        if (response.data.errorMessage) {
            toast.error(response.data.errorMessage);
        }
        const data = response.data as AttendeeListModel[];
        return data.map(parseModel);
    }

    public async updateDelegateAttendees(delegateAttendees: AttendeeListModel[],
        delegateAttendeesFrom: moment.Moment,
        eventInstanceDate: moment.Moment): Promise<AttendeesForEventInstanceResponse> {
        const normalizedDelegateAttendees = delegateAttendees.map(da => ({
            ...da,
            forename: da.forename?.trim() || undefined,
            surname: da.surname?.trim() || undefined,
            drivingLicenceNumber: da.drivingLicenceNumber?.trim().toUpperCase() || undefined,
            drivingLicenceExactCountry: da.drivingLicenceExactCountry?.trim() || undefined,
            dqcReference: da.dqcReference?.trim().toUpperCase() || undefined,
            email: da.email?.trim() || undefined,
            telephone: da.telephone?.trim() || undefined,
        }));
        const response = await axios.post(`${this.attendeeApi}/updateDelegateAttendees`, {
            delegateAttendees: normalizedDelegateAttendees,
            delegateAttendeesFrom
        });
        const data = response.data as UpdateDelegateAttendeesResponse;

        switch (data.status) {
            case UpdateDelegateAttendeesStatus.Ok:
                hasSortedOrderChanged(delegateAttendees, "forename") && eventInstanceDate.isSameOrBefore(moment())
                    ? toast.warning("Delegate(s) update successfully completed. Please note - order of delegates has now changed")
                    : toast.info("Delegate(s) update successfully completed.");
                break;
            case UpdateDelegateAttendeesStatus.OldStateRefresh:
                toast.warning("The register has been amended by someone else and your changes have been discarded. Please redo your changes.");
                break;
            case UpdateDelegateAttendeesStatus.OverbookedDueToCorporateBookingApp:
                toast.warning("New delegate(s) could not be added due to active seat reservation(s). Existing delegate(s) update successfully completed.");
                break;
            case UpdateDelegateAttendeesStatus.SeatIsReserved:
                data.attendeesRemoved && toast.success("Delegate(s) removed successfully.");
                toast.error(`Could not add delegates as there are ${data.reservedOrBookedSeats} pending reservations.`);
                break;
            default:
                break;
        }

        return ({
            attendees: data.delegateAttendees.map(parseModel),
            attendeesFrom: moment(data.attendeesFrom)
        });
    }

    public async updateDelegateAttendeesSpecialRequirements(delegateAttendees: AttendeeSpecialRequirementsModel[]): Promise<AttendeeListModel[]> {
        const response = await axios.post(`${this.attendeeApi}/updateDelegateAttendeesSpecialRequirements`, delegateAttendees);
        const data = response.data as AttendeeListModel[];
        toast.info("Special requirements update successfully completed");
        return data.map(parseModel);
    }

    public async updateDvsaUploadStatus(attendeeId: string, dvsaUploadStatus: DvsaUploadStatus) {
        const result = await axios.patch(`${this.attendeeApi}/${attendeeId}/updateDvsaUploadStatus`, {
            dvsaUploadStatus
        });
        return result.data;
    }

    public async getAttachedDocuments(documentType: DocumentType = undefined): Promise<AttachedDocumentListModel[]> {
        const response = await axios.get<AttachedDocumentListModel[]>(`${this.attendeeApi}/attached-documents/${documentType}`);
        return response.data;
    }

    public async attachDocument(attendeeId: string, description: string, file: File) {
        const formData = new FormData();
        formData.append("description", description);
        formData.append("file", file);

        const response = await axios.post<AttachedDocumentListModel>(
            `${this.attendeeApi}/attach-document/${attendeeId}`, formData,
            {
                headers: { "Content-Type": "multipart/form-data" }
            });

        return response.data;
    }

    public async deleteAttachedDocument(documentId: string) {
        await axios.delete<AttachedDocumentListModel[]>(`${this.attendeeApi}/attached-documents/${documentId}`);
    }
}
