import { Dispatch, AnyAction } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { AppCommonState } from "@common/appCommonState";
import { Moment } from "moment";
import * as actions from "./actiontypes";
import { NotesApi } from "./notesApi";
import { completeAlaskaNudgeTask } from "@common/crud/alaskaNudgeTask/actions";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

export type NoteAction =
    ({ type: actions.ADD_NOTE_SUCCESS } & Payload<{ correlationId: string; id: string; note: string;
        addedBy: string; pendingDate?: Moment; nudgeTaskId?: string; isAlertNoteToTTC?: boolean; noteAttachmentFileName: string;
        noteAttachmentBlobPath: string; }>) |
    ({ type: actions.UPDATE_NOTE_SUCCESS } & Payload<{ correlationId: string; noteId: string; noteText: string; removeAttachment: boolean }>) |
    ({ type: actions.COMPLETED_PENDING_NOTE } & Payload<{ nudgeTaskId: string }>);

export const addNoteSuccess =
    (noteDetails: { correlationId: string; id: string; note: string;
        addedBy: string; pendingDate?: Moment; nudgeTaskId?: string; isAlertNoteToTTC?: boolean; noteAttachmentFileName: string;
        noteAttachmentBlobPath: string; }): NoteAction => ({
        payload: noteDetails,
        type: actions.ADD_NOTE_SUCCESS
    });

export const updateNoteSuccess =
    (noteDetails: { correlationId: string; noteId: string; noteText: string; removeAttachment: boolean }): NoteAction => ({
        payload: noteDetails,
        type: actions.UPDATE_NOTE_SUCCESS
    });

export const completedPendingNote = (nudgeTaskId: string): NoteAction => ({
    payload: { nudgeTaskId },
    type: actions.COMPLETED_PENDING_NOTE
});

export function addNoteToCorrelationId(correlationId: string, noteId: string, noteText: string, relatedTo: TaskRelationEnum, alertNoteToTTC: boolean,
    pendingDate?: Moment, file?: File, suffixId = "") {
    return async (dispatch: Dispatch, getState: () => AppCommonState) => {
        const currentUserEmail = getState().currentUser.email ?? getState().currentUser.fullName;
        const api = new NotesApi(correlationId);
        const { nudgeTaskId, noteAttachmentFileName, noteAttachmentBlobPath } = await loadAndTrack(
            dispatch,
            "addNote",
            api.addNote(noteId, noteText, relatedTo, alertNoteToTTC, pendingDate, file, suffixId)
        );
        dispatch(addNoteSuccess(
            { correlationId, id: noteId, note: noteText, addedBy: currentUserEmail, pendingDate, nudgeTaskId, isAlertNoteToTTC: alertNoteToTTC,
                noteAttachmentFileName, noteAttachmentBlobPath }));
    };
}

export function updateNoteToCorrelationId(correlationId: string, noteId: string, noteText: string, relatedTo: TaskRelationEnum, removeAttachment: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new NotesApi(correlationId);
        await loadAndTrack(dispatch, "updateNote", api.updateNote(noteText, noteId, relatedTo, removeAttachment));
        dispatch(updateNoteSuccess({ correlationId, noteId, noteText, removeAttachment }));
    };
}

export function completePendingNote(nudgeTaskId: string) {
    return async (dispatch: Dispatch) => {
        await dispatch(completeAlaskaNudgeTask({ nudgeTask: { id: nudgeTaskId, completionNotes: "" } }) as unknown as AnyAction);
        dispatch(completedPendingNote(nudgeTaskId));
    };
}
