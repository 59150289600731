import * as React from "react";
import { connect } from "react-redux";
import { LanguageState } from "@common/language/model";
import { Dispatch } from "redux";
import { setCurrentLanguage } from "@common/language/actions";
import { FlagIcon } from "@common/crud/common/FlagIcon";
import { LanguageCode } from "@common/crud/eventInstance/model";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface HeaderLanguageProps {
    languageCode: string;
    language: string;
    setCurrent: (language: string) => void;
}

export const HeaderLanguageInternal: React.FunctionComponent<HeaderLanguageProps> = (props) => {

    const getOnClick = (code: string) => () => props.setCurrent(code);

    const language = ObjectKeys(LanguageCode)
        .filter(lang =>  LanguageCode[lang] === props.languageCode)[0];

    return (
        <div className="header-language" onClick={getOnClick(props.languageCode)}>
            <FlagIcon language={language} />
            <span className="text uppercase not-mobile">{props.language}</span>
        </div>
    );
};

const mapStateToProps = (state: LanguageState) => state.language;

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrent: (language: string) => dispatch(setCurrentLanguage(language))
});

export const HeaderLanguage = connect(mapStateToProps, mapDispatchToProps)(HeaderLanguageInternal);
