import { Query } from "redux-little-router";
import { isNullOrUndefined } from "./global/CommonHelpers";
import { SemanticShorthandItem, TabPaneProps, TabProps } from "semantic-ui-react";

export interface Version {
    assemblyVersion: string;
    productVersion: string;
}

export enum EnvironmentTypeEnum {
    Prelive,
    Live
}

export const EnvironmentType = {
    [EnvironmentTypeEnum.Prelive]: "Prelive",
    [EnvironmentTypeEnum.Live]: "Live",
};

export const IsProductionEnvironment = (environment: string) => {
    const environmentUppercased = environment?.toUpperCase();
    return environment !== null &&
           (environmentUppercased === EnvironmentType[EnvironmentTypeEnum.Live].toUpperCase()
        || environmentUppercased === EnvironmentType[EnvironmentTypeEnum.Prelive].toUpperCase());
};

export enum Apps {
    Admin,
    Police,
    Customer,
    Driver,
    Booking,
    Trainer,
    CorporateBooking,
}

export interface StorageInfo {
    fileName: string;
    blobName: string;
}

export type SortDirection = "asc" | "desc";

export enum DayPeriodEnum {
    SessionOne = 1,
    SessionTwo = 2,
    SessionThree = 3,
    SessionFour = 4
}

export const DayPeriod = {
    [DayPeriodEnum.SessionOne]: "Session One",
    [DayPeriodEnum.SessionTwo]: "Session Two",
    [DayPeriodEnum.SessionThree]: "Session Three",
    [DayPeriodEnum.SessionFour]: "Session Four"
};

export enum DaySessionEnum {
    Unknown,
    One,
    Two,
    Three,
    Four
}

export const DaySession = {
    [DaySessionEnum.One]: "Session One",
    [DaySessionEnum.Two]: "Session Two",
    [DaySessionEnum.Three]: "Session Three",
    [DaySessionEnum.Four]: "Session Four",
};

export enum DayOfWeekEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export const DayOfWeek = {
    [DayOfWeekEnum.Monday]: "Monday",
    [DayOfWeekEnum.Tuesday]: "Tuesday",
    [DayOfWeekEnum.Wednesday]: "Wednesday",
    [DayOfWeekEnum.Thursday]: "Thursday",
    [DayOfWeekEnum.Friday]: "Friday",
    [DayOfWeekEnum.Saturday]: "Saturday",
    [DayOfWeekEnum.Sunday]: "Sunday",
};

export interface SortOptions<T> {
    property: keyof T;
    direction: SortDirection;
}

export type StringValues<T> = { [P in keyof T]: string };

export function removeEmptyValues<T>(obj: T): T {
    return (Object.keys(obj) as (keyof typeof obj)[]).reduce((prev, k) => {
        const currentValue = obj[k];
        if (isNullOrUndefined(currentValue)) {
            return prev;
        }

        return {
            ...prev,
            [k]: currentValue
        };

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as T);
}

export function toRouterQuery<T>(obj: T): Query {
    return (Object.keys(obj) as (keyof typeof obj)[]).reduce((prev, k) => {
        const currentValue = obj[k];
        const stringifiedValue = Array.isArray(currentValue)
            ? currentValue?.join(",")
            : currentValue?.toString();

        return {
            ...prev,
            [String(k)]: stringifiedValue ?? ""
        };

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as Query);
}

export interface ResultResponse {
    success: boolean;
    message?: string;
    result?: string;
}

interface CustomPane {
    pane?: SemanticShorthandItem<TabPaneProps>;
    menuItem?: any;
    render?: () => React.ReactNode;
    path?: string;
  }

export interface CustomTabProps extends Omit<TabProps , "panes"> {
    panes?: CustomPane[];
  }
