import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import {
    IQAReviewSectionId,
    IQAReviewSectionSeqNum
} from "@common/crud/eventInstance/components/monitoring/MonitoringReportingConstants";

export const IQAReviewSection: QuestionSection = {
    id: IQAReviewSectionId.toString(),
    title: "IQA Review",
    seqNum: IQAReviewSectionSeqNum,
    summaryCriterium: [{
        id: "12.1",
        text: "Provide written feedback here on the monitor’s report.  Feedback should be given" +
            " verbally in a confidential environment as well as in this written report",
        typeEnum: CriteriaEnum.text }]
};
