import * as React from "react";

import { GenesysSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const Genesys: ConfigurationAreaComponent<GenesysSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Genesys"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "UsePauseAndResume", label: "Enable Pause and Resume" },
                { name: "UseCliSearch", label: "Enable CLI Search" },
                { name: "GenesysAuthorisationEndpoint", label: "Genesys AuthorisationEndpoint" },
                { name: "ClientId", label: "Client Id" },
                { name: "DeploymentKey", label: "Web Chat Deployment Key" },
                { name: "OrgGuid", label: "Web Chat Org Guid" },
                { name: "UseGenesysIvr", label: "Enable Genesys IVR" },
                { name: "GenesysIvrFlowId", label: "Genesys IVR Flow Id" },
                { name: "AlaskaEnvironment", label: "Alaska Environment" },
            ]}
        />
    );
};
