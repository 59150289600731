import * as React from "react";
import { useSelector } from "react-redux";
import { AttendeeListModel } from "@common/crud/attendee";
import { Area, shouldAttendeesBeDisplayed } from "../../model";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "../../selectors";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { AttendanceRegister } from "./AttendanceRegister";
import { CompletionRegister } from "./CompletionRegister";
import { EiSideBar } from "../EiSideBar";
import { currentUserSelector } from "@common/auth";
import { getEventInstanceOptions } from "./helpers";
import { RegisterContext } from "./register-context";
import { GroupEventInstanceDayPartMenu } from "@common/crud/eventInstance/components/register/GroupEventInstanceDayPartMenu";

interface DrinkDriveRegisterProps {
    attendees: AttendeeListModel[];
    path: string;
    area: Area;
}

export const DrinkDriveRegister: React.FC<DrinkDriveRegisterProps> = ({ attendees, path, area }) => {
    const eventInstance = useSelector(eventInstanceSelector);
    const group = useSelector(eventInstanceGroupByIdSelector(eventInstance.groupId));
    const currentUser = useSelector(currentUserSelector);
    const attendeesDisplay = shouldAttendeesBeDisplayed(currentUser, eventInstance);
    if (attendeesDisplay.result === false) {
        return (
            <EiSideBar>
                <div>Cannot show attendees at the moment</div>
            </EiSideBar>
        );
    }

    const nonCancelledAttendees = attendees.filter(a => a.isBookingCanceled === false);
    const eventInstanceOptions = getEventInstanceOptions(eventInstance);
    const registerContextOptions = { path, attendees, eventInstance, nonCancelledAttendees, eventInstanceOptions };

    return (
        <EiSideBar>
            <div>
                <h1>Course register</h1>
                <EventInstanceBanner eventInstance={eventInstance} />
                <GroupEventInstanceDayPartMenu area={area} group={group} />
                <RegisterContext.Provider value={registerContextOptions}>
                    {eventInstance.isStageTwo ? <CompletionRegister /> : <AttendanceRegister eventInstance={eventInstance} />}
                </RegisterContext.Provider>
            </div>
        </EiSideBar>
    );
};
