import * as React from "react";
import { Grid } from "semantic-ui-react";
import { formatCurrency } from "@common/formating";

export interface BalanceInfoProps {
    paymentsSum: number;
    remainingBalance: number;
    courseFee?: number;
    additionalFees?: number;
}

export const BalanceInfo: React.FC<BalanceInfoProps> = (props) => {

    const row = (label: string, amount: number) => (
        <Grid.Row className="payment-info-row">
            <Grid.Column width={5}>
                <p className="payment-text">{label}</p>
            </Grid.Column>
            <Grid.Column width={11}>
                <p>{formatCurrency(amount)}</p>
            </Grid.Column>
        </Grid.Row>
    );

    return (
        <Grid className="balance-grid">
            {row("Total payments made", props.paymentsSum)}
            {row("Remaining balance", props.remainingBalance)}
            {props.courseFee !== undefined && row("Course fee", props.courseFee)}
            {props.additionalFees !== undefined && row("Additional fees", props.additionalFees)}
        </Grid>
    );
};
