import { validators, createValidator } from "not-valid";

import { onlyIf } from "./onlyIf";

const licenceErrorMessage = "Please enter a valid GB driving licence number, excluding the last two digits";

const drivingLicenceValidator = validators.validUKDrivingLicence({
    message: licenceErrorMessage,
    issueNumber: "prohibited",
    allowNI: false
});

const commonValidator = (dateOfBirth?: string) => {
    return [
        licenceNumberPatternValidator(),
        ... (dateOfBirth ? [licenceDateOfBirthValidator(dateOfBirth)] : []),
        drivingLicenceValidator,
    ];
};

export const drivingLicenceRequired = (isGBDrivingLicence: boolean, dateOfBirth?: string) => {
    return [
        validators.requiredString(),
        onlyIf(isGBDrivingLicence),
        ... (isGBDrivingLicence ? commonValidator(dateOfBirth) : []),
    ];
};

export const drivingLicenceRequiredIfGB = (isGBDrivingLicence: boolean, dateOfBirth?: string) => {
    return [
        onlyIf(isGBDrivingLicence),
        validators.requiredString(),
        ... (isGBDrivingLicence ? commonValidator(dateOfBirth) : []),
    ];
};

const licenceNumberPatternValidator = () => {
    // eslint-disable-next-line max-len
    const licenceRegEx = /^(?=.{16}$)[A-Za-z]{1,5}9{0,4}[0-9](?:[05][1-9]|[16][0-2])(?:[0][1-9]|[12][0-9]|3[01])[0-9](?:99|[A-Za-z][A-Za-z9])(?![IOQYZioqyz01_])\w[A-Za-z]{2}/;
    return createValidator<string>(
        v => licenceRegEx.test(v),
        licenceErrorMessage);
};

const licenceDateOfBirthValidator = (dateOfBirth: string) => {
    if (!dateOfBirth) {
        return createValidator<string>(() => {
            return true;
        }, licenceErrorMessage);
    }

    const dob = new Date(dateOfBirth);
    const dobDd = dob.getDate();
    const dobMm = dob.getMonth() + 1;
    const dobYy = dob.getFullYear().toString().substr(-2);

    return createValidator<string>(v => {
        const lnDd = Number(v.substr(8, 2));
        const lnMm = Number((v[6] === "5" ? "0" : v[6] === "6" ? "1" : v[6]) + v[7]);
        const lnYy = v[5] + v[10];

        return (dobDd === lnDd && dobMm === lnMm && dobYy === lnYy);
    }, `The date of birth we hold for you does not correspond with the driving licence number you have entered. 
    Please check the driving licence number you have entered and try again.`);
};
