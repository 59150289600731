import * as React from "react";
import { Checkbox } from "semantic-ui-react";

export interface FreeRebookingFormFieldProps {
    label: string;
    freeRebookingEnabled: boolean;
    setFreeRebookingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FreeRebookingFormField = (props: FreeRebookingFormFieldProps) => {
    const { label, freeRebookingEnabled, setFreeRebookingEnabled } = props;

    const freeRebookingDisabledMessage = "This means if the attendee rebooks, a rebook fee will be required unless the attendee " +
        "has 'flexi-booking' or is rebooking within 14 days of their original booking.";
    const freeRebookingEnabledMessage = "This means the attendee can rebook free of charge one time. This free of charge " +
        "rebooking will be used instead of any flexi-booking. However, if the attendee is rebooking within 14 days " +
        "of their original booking, their free of charge rebooking will not be used.";

    function onFreeRebookingToggleChange() {
        setFreeRebookingEnabled(prev => !prev);
    }

    return (
        <div className="field">
            <label>{label}</label>
            <Checkbox
                toggle
                checked={freeRebookingEnabled}
                onChange={onFreeRebookingToggleChange}
                label={freeRebookingEnabled ? "Yes" : "No"}
            />
            <br />
            <p>{freeRebookingEnabled ? freeRebookingEnabledMessage : freeRebookingDisabledMessage}</p>
        </div>
    );
};
