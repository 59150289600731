import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Media } from "@common/global/AppMedia";

export const BookingDetailsField: React.FunctionComponent = ({ children }) => (
    <>
        <Media greaterThanOrEqual="tablet">
            <Grid.Row>
                <Grid.Column width={16}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Media>
        <Media lessThan="tablet">{children}</Media>
    </>
);
