import * as React from "react";
import { ErrorMessage } from "formik";

export interface SemanticFormErrorProps {
    name: string;
}

export function SemanticFormError({
    name,
}: SemanticFormErrorProps) {

    return (
        <ErrorMessage
            name={name}
        >
            {(msg) => <div className="ui red pointing above basic label">{msg}</div>}
        </ErrorMessage>
    );
}
