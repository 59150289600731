import React from "react";

import { Address } from "./Address";

interface DisplayAddressProps {
    address?: Address;
}

export const DisplayAddress = (props: DisplayAddressProps) => {
    if (props.address) {
        let addressParts = [props.address.addressLine1 || "", props.address.addressLine2 || "", props.address.addressLine3 || "", props.address.city || ""];
        addressParts = addressParts.map(i => i.trim());
        const addressStr = addressParts.filter(i => i !== "").join(", ");
        return (
            <>
                {addressStr}
                <span className="no-break"> {props.address.postalCode}</span>
            </>
        );
    }
    return null;
};
