import { ClearTrainerAvailabilityEnum, MonitoringCandidates, TrainerType } from "@common/crud/eventInstance/model";
import { TrainerAllocationModel } from "@common/crud/trainer/model";
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { toast } from "@common/toasts";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { saveEventInstancesSuccess } from "@common/crud/eventInstance/actions";

export function allocateMultiDayTrainers(
    groupId: string,
    trainerType: TrainerType,
    trainers: TrainerAllocationModel[],
    monitoredTrainers:  MonitoringCandidates[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceGroupApi();
        const result = await loadAndTrack(
            dispatch, "allocateTrainers", api.allocateTrainers(groupId, trainerType, trainers, monitoredTrainers));
        toast.info("Allocated trainers updated");

        await dispatch(saveEventInstancesSuccess(result));
        window.location.reload();
    };
}

export function cancelGroupTrainer(groupId: string, trainerId: string, trainerType: TrainerType,
    reasonForCancellation: string, otherReason: string, dayParts: number[],
    clearTrainerAvailability: ClearTrainerAvailabilityEnum) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceGroupApi();
        const result = await loadAndTrack(
            dispatch, "cancelGroupTrainer", api.cancelGroupTrainer(groupId, trainerId, trainerType, reasonForCancellation, otherReason, dayParts,
                clearTrainerAvailability));

        if (result.some(r => r.errorMessageDuringUpdateTrainers)) {
            toast.error("At least one trainer cancellation failed, due to another trainer cancellation request");
            const failedResults = result.filter(r => r.errorMessageDuringUpdateTrainers);
            failedResults.forEach(failedResult => {
                toast.error(failedResult.errorMessageDuringUpdateTrainers);
            });
        } else {
            toast.info("Trainer cancelled from Group");
        }

        await dispatch(saveEventInstancesSuccess(result));
        window.location.reload();
    };
}
