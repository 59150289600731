import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { useSelector } from "react-redux";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "../../selectors";
import { EiSideBar } from "../EiSideBar";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { EventInstanceApi } from "@common/crud/eventInstance";
import { Grid } from "semantic-ui-react";
import { ChangeEventType } from "@common/history/model";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

const expectedFilters = [
    { type: ChangeEventType.Payment, display: true },
    { type: ChangeEventType.DataModification, display: true },
    { type: ChangeEventType.Booking, display: true },
    { type: ChangeEventType.Comms, display: true }
];

export const EventInstanceHistory: React.FC = () => {
    const [history, setHistory] = React.useState([]);
    const eventInstance = useSelector(eventInstanceSelector);
    const groupEventInstance = useSelector(eventInstanceGroupByIdSelector(eventInstance?.groupId));

    React.useEffect(() => {
        async function loadHistory() {
            const api = new EventInstanceApi();
            const result = await api.getEventInstanceHistory(eventInstance.id);
            setHistory(result);
        }
        if (eventInstance?.id) {
            loadHistory();
        }
    },[eventInstance?.id]);

    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        expectedFilters
            .map(filter => filter.type));

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    const content = () => {
        return eventInstance.corporateOrganisationId ? (
            <div>
                <h1>Course history</h1>
                <EventInstanceBanner eventInstance={eventInstance} />
                <Grid>
                    <Grid.Column width={3}>
                        <Grid.Row>
                            Filters by group:
                        </Grid.Row>
                        {expectedFilters.map(eventType => eventType.display ?
                            <HistoryFilter
                                key={eventType.type}
                                eventType={eventType.type}
                                value={filters.includes(eventType.type)}
                                onChange={addOrRemove}
                            /> : null)}
                        <Grid.Row>
                            <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <HistoryContent
                            showDetailsDefault={false}
                            entries={history?.filter(record => filters.includes(record.eventType))}
                            workflowType={eventInstance.workflowType}
                            groupEventInstance={groupEventInstance}
                            eventTypeCategory={EventTypeCategory.CorporateOrganisation}
                            taskRelation={TaskRelationEnum.EventInstance}
                        />
                    </Grid.Column>
                </Grid>
            </div>
        ) : (
            <div>
                <h1>Course history</h1>
                <EventInstanceBanner eventInstance={eventInstance} />
                <HistoryContent
                    showDetailsDefault={false}
                    entries={history}
                    workflowType={eventInstance.workflowType}
                    groupEventInstance={groupEventInstance}
                    taskRelation={TaskRelationEnum.EventInstance}
                />
            </div>
        );
    };

    return <EiSideBar children={content()} />;
};
