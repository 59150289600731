import * as React from "react";
import { useTranslation } from "react-i18next";
import { Booking } from "../bookings/models";
import { dateString, DateFormat } from "@common/crud/common/DateTimeFormats";

export interface AttendeeHeaderProps {
    booking: Booking;
}

const AttendeeHeader: React.FC<AttendeeHeaderProps> = ({ booking }) => {
    const [t] = useTranslation("AttendeeHeader");
    const completionDate = dateString(booking.expiryDate, DateFormat.Long);
    const eventTypeName = booking.eventTypeName;
    const eventTypeNameCy = booking.eventTypeNameCy ?? booking.eventTypeName;
    return (
        <>
            <h3>{t("WELCOME", { booking, completionDate, eventTypeName, eventTypeNameCy })}</h3>
            <p>{t("INTRODUCTION_LINE1")}<br />{t("INTRODUCTION_LINE2")}</p>
        </>
    );
};

export { AttendeeHeader };
