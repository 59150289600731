/* eslint-disable max-lines */
import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Segment, Container, Popup, Form, DropdownProps } from "semantic-ui-react";
import { cashRangeValidator } from "@common/validation/cashRangeValidator";
import { AppState as EventInstanceAppState } from "@common/crud/eventInstance/model";
import { AppState as AttendeeAppState } from "@common/crud/attendee/model";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { basePathSelector, attendeeSelector } from "@common/crud/attendee/selectors";
import { StartPaymentProcessModel } from "@common/payments";
import { loadPayment, addPayment, startAutoPaymentProcessFromAdminApp } from "@common/payments/actions";
import { AppState, ScheduledPaymentPlanModel, PaymentType } from "@common/payments/model";
import { ScheduledPaymentBase, ScheduledPaymentResponse } from "@common/payments/components/scheduled-payments/ScheduledPaymentBase";
import { BalanceInfo } from "@common/payments/components/BalanceInfo";
import { ScheduledPaymentDetails } from "@common/payments/components/scheduled-payments/ScheduledPaymentDetails";
import { ConfirmButton } from "@common/components";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { useSetBeforeGenesysAuthPage } from "@common/genesys/hooks/useSetBeforeGenesysAuthPage";
import { GenesysTokenProvider } from "@common/genesys/components/GenesysTokenProvider";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { PaymentApi } from "@common/payments/paymentApi";
import { Spinner } from "@common/global/Spinner";

export const ManualPayment: React.FC = () => {
    const [value, setValue] = useState<number>(0);
    const [isValid, setValid] = useState<boolean>(true);
    const [isPlanValid, setIsPlanValid] = useState<boolean>(true);
    const [scheduledPaymentPlan, setScheduledPaymentPlan] = useState<ScheduledPaymentPlanModel[]>(null);
    const [paymentType, setPaymentType] = useState(PaymentType.Card);
    const [showGenesysLogin, setShowGenesysLogin] = React.useState(false);
    const [genesysToken, setGenesysToken] = React.useState<string>(undefined);
    const [autoPaymentStarted, setAutoPaymentStarted] = React.useState(false);
    const [showScheduledPayments, setShowScheduledPayment] = React.useState<boolean>();
    const [autoPaymentLoading, setAutoPaymentLoading] = React.useState<boolean>(false);

    const stateProps = useSelector((state: AppState & EventInstanceAppState & AttendeeAppState) => {
        return {
            eventInstance: eventInstanceSelector(state),
            attendee: attendeeSelector(state),
            basePath: basePathSelector(state)
        };
    });

    const genesysSettings = useSelector(appSettingsSelector).genesysSettings;

    useSetBeforeGenesysAuthPage();

    const dispatch = useDispatch();
    const { eventInstance, attendee, basePath } = stateProps;

    React.useEffect(() => {
        setShowScheduledPayment(!attendee.isBookingCanceled);
    }, [attendee.isBookingCanceled]);

    React.useEffect(() => {
        async function loadAdminPayment() {
            const api = new PaymentApi();
            const response = await api.getIvrPaymentDetails(attendee.correlationId);
            const { isAdminPayment, isSeatReserved, paymentComplete } = response.paymentStatusModel;

            // If admin payment and the IVR payment failed within the seat reservation time,
            if (isSeatReserved && isAdminPayment && !paymentComplete) {
                setValue(response.amount);
                setScheduledPaymentPlan(response.scheduledPaymentPlans);
            }
        }

        if (attendee?.correlationId) {
            // Pre-populate payment state with previous IVR payment if it was failed
            loadAdminPayment();
        }

    }, [attendee?.correlationId]);

    const adminPaymentModel: StartPaymentProcessModel = {
        objectRelatedTo: attendee.id,
        eventInstanceId: eventInstance.id,
        partialPaymentModel: { amount: value },
        scheduledPaymentPlanModel: showScheduledPayments ? scheduledPaymentPlan : [],
        includeFullyBookedCourses: false,
        startPaymentProcessMultiPartModels: [{ eventInstanceId: eventInstance.id, relatedObjectId: attendee.id, dayPart: 0 }],
        groupId: eventInstance.groupId,
    };

    function onContinueButton() {
        if (paymentType === PaymentType.Card) {
            dispatch(
                loadPayment(
                    adminPaymentModel,
                    `${basePath}/${attendee.id}/${PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]}`,
                ));
        } else {
            dispatch(addPayment(
                eventInstance.id,
                attendee.id,
                paymentType,
                value * 100,
                `${basePath}/${attendee.id}`,
            ));
        }
    };

    function onContinueConfirmButton() {
        dispatch(addPayment(
            eventInstance.id,
            attendee.id,
            PaymentType.FakePayment,
            value * 100,
            `${basePath}/${attendee.id}`,
        ));
    }

    function onAutoPaymentButtonClick() {
        setAutoPaymentLoading(true);
        if (!genesysToken) {
            setShowGenesysLogin(true);
            setAutoPaymentStarted(true);
            return;
        }

        handleAutoPaymentClick(genesysToken);
    }

    function handleAutoPaymentClick(token: string) {
        dispatch(startAutoPaymentProcessFromAdminApp(token, attendee?.correlationId, eventInstance?.id, adminPaymentModel, clearAutoPaymentLoading));
    }

    function clearAutoPaymentLoading() {
        setAutoPaymentLoading(false);
    }

    function setPaymentPlan(planObject: ScheduledPaymentResponse) {
        setIsPlanValid(planObject.isPlanValid);
        setScheduledPaymentPlan(planObject.plan);
    };

    function onPaymentTypeOptionChange(_: any, option: DropdownProps) { setPaymentType(+option.value); }

    function onPaymentInputChange(v: number, valid: boolean) {
        setValue(v);
        setValid(valid);
    }

    function onTogglePlans(toggle: boolean) {
        setShowScheduledPayment(toggle);
    }

    function onTokenReceived(token: string) {
        setGenesysToken(token);

        if (autoPaymentStarted) {
            handleAutoPaymentClick(token);
        }
    }

    const paymentDisabled = !isValid || attendee.isBookingCanceled;
    const isSchedulePaymentDisabled = showScheduledPayments && !isPlanValid;
    const scheduledPaymentsVisible = paymentType === PaymentType.Card;

    const paymentTypeOptions = [
        { value: PaymentType.Card, text: "Card" },
        { value: PaymentType.ChequeOrPO, text: "Cheque" },
        { value: PaymentType.Cash, text: "Cash" },
        { value: PaymentType.FakePayment, text: "Balance Adjustment" },
    ];

    const paymentPlanSet = showScheduledPayments && scheduledPaymentPlan?.length > 0;

    PreventScrollingOnNumberInputsHook();
    return (
        <>
            {showGenesysLogin &&
                <GenesysTokenProvider onTokenReceived={onTokenReceived} />
            }
            <Segment>
                <h3>Payment options</h3>
            </Segment>
            <Grid className="two-section-layout">
                <Grid.Row>
                    <Grid.Column width={10} className="two-section-layout-left">
                        <Container className="indented">
                            <Grid.Row className="margin-bottom-rem">
                                <BalanceInfo
                                    paymentsSum={attendee.totalAmountPaid}
                                    remainingBalance={attendee.totalAmountDue}
                                    additionalFees={attendee.additionalCourseFees}
                                    courseFee={attendee.eventInstancePriceInPence ?? eventInstance.priceInPence}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <p>Payment type</p>
                                </Grid.Column>
                                <Grid.Column width={10} className="extra-padding">
                                    <Form.Dropdown
                                        selection
                                        placeholder="Any Department"
                                        value={paymentType}
                                        options={paymentTypeOptions}
                                        onChange={onPaymentTypeOptionChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <p>How much would you like to pay?</p>
                                </Grid.Column>
                                <Grid.Column width={10} className="extra-padding full-width-input">
                                    <Input.Number
                                        required
                                        validationOptions={{ sequential: true }}
                                        validation={cashRangeValidator(0, 300)}
                                        onChange={onPaymentInputChange}
                                        value={value}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {scheduledPaymentsVisible &&
                                <Grid.Row>
                                    <ScheduledPaymentBase
                                        disabledMessage="Scheduled payments can only be set when an attendee is booked"
                                        disabled={attendee.isBookingCanceled}
                                        sendPlan={setPaymentPlan}
                                        eventDate={eventInstance.startDate}
                                        maxValue={attendee.totalAmountDue && attendee.totalAmountDue / 100}
                                        initialPaymentValue={value}
                                        showPlans={showScheduledPayments}
                                        onShowPlansChange={onTogglePlans}
                                    />
                                </Grid.Row>
                            }
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={6} className="two-section-layout-right booking-details-column">
                        <Grid.Row>
                            <ScheduledPaymentDetails plans={attendee.scheduledPaymentPlans} />
                        </Grid.Row>

                        <Grid.Row>
                            <Popup
                                content="Stripe payment disabled due to attendee being cancelled"
                                disabled={!attendee.isBookingCanceled}
                                trigger={
                                    <span>
                                        {paymentType === PaymentType.Card && (
                                            <>
                                                {genesysSettings.useGenesysIvr && paymentPlanSet && (
                                                    <Popup
                                                        content={"Auto payment is not possible if scheduled payment plan set"}
                                                        trigger={
                                                            <div>
                                                                <Button
                                                                    disabled
                                                                    className="full-width payment-button"
                                                                    content="Start Auto Payment"
                                                                />
                                                            </div>
                                                        }
                                                    />
                                                )}
                                                {genesysSettings.useGenesysIvr && !paymentPlanSet && (
                                                    <Spinner active={autoPaymentLoading}>
                                                        <Button
                                                            className="full-width payment-button"
                                                            disabled={paymentDisabled || isSchedulePaymentDisabled || autoPaymentLoading}
                                                            onClick={onAutoPaymentButtonClick}
                                                        >
                                                            Start Auto Payment
                                                        </Button>
                                                    </Spinner>
                                                )}
                                                <Button
                                                    className="full-width payment-button"
                                                    disabled={paymentDisabled || isSchedulePaymentDisabled || autoPaymentLoading}
                                                    onClick={onContinueButton}
                                                >
                                                    Continue
                                                </Button>
                                            </>
                                        )}
                                        {paymentType === PaymentType.FakePayment && (
                                            <ConfirmButton
                                                className="full-width payment-button"
                                                content="Action will decrease attendee balance by given amount."
                                                header="Are you sure?"
                                                onConfirm={onContinueConfirmButton}
                                                disabled={autoPaymentLoading}
                                            >
                                                Continue
                                            </ConfirmButton>
                                        )}
                                        {paymentType !== PaymentType.Card && paymentType !== PaymentType.FakePayment && (
                                            <Button
                                                className="full-width payment-button"
                                                disabled={paymentDisabled || autoPaymentLoading}
                                                onClick={onContinueButton}
                                            >
                                                Continue
                                            </Button>
                                        )}
                                    </span>
                                }
                            />
                        </Grid.Row>

                        {attendee.scheduledPaymentPlans && attendee.scheduledPaymentPlans.length > 0 &&
                            <Grid.Row>
                                <span>
                                    This attendee has previously agreed to pay by installments.
                                    &nbsp;Please note that any active plan will be cancelled if a new installment plan
                                    &nbsp;is confirmed or if the attendee pays their balance in full.
                                </span>
                            </Grid.Row>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};
