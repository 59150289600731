import * as React from "react";
import { Message, Segment } from "semantic-ui-react";

export interface LabelWithValueAndActionProps {
    label: string;
    showAction: boolean;
    value: string | number | React.ReactNode;
    actionName: string;
    errorMessage?: string;
    action: () => void;
}

export const LabelWithValueAndAction: React.SFC<LabelWithValueAndActionProps> = ({ label, showAction, value, action, actionName, errorMessage }) => {
    return (
        <div className="label-and-value">
            <span className="label fixed-width">
                {label}
            </span>
            {showAction &&
                <a
                    className="show-button"
                    onClick={action}
                >{actionName}
                </a>
            }
            <div className="value">
                <span>{value}</span>
            </div>
            {errorMessage &&
                <Message as={Segment} error>
                    {errorMessage}
                </Message>
            }
        </div>
    );
};
