import * as React from "react";
import { last } from "lodash";
import { Detail } from "@neworbit/simpleui-detail";
import { AppSetting } from "@common/crud/configuration/model";

import { AppSettingEdit } from "./AppSettingEdit";
import { InputType } from "./SettingsGrid";

import { AppSettings } from "@common/appSettings/model";
import { DropdownItemProps } from "semantic-ui-react";

export interface AppSettingViewProps {
    label: string;
    setting: AppSetting;
    withInfoModal?: boolean;
    inputType: InputType;
    dropdownNumberOptions?: DropdownItemProps[];
    isArchivable?: boolean;
    hierarchyContext: string[];
    currentAppSettings?: AppSettings;
    validate?: (value: string | string[] | { value: string; text: string }[]) => string[];
}

export const AppSettingView: React.FC<AppSettingViewProps> = (props) => {

    const [editing, setEditing] = React.useState<boolean>(false);

    const { setting, withInfoModal, hierarchyContext, label, inputType, dropdownNumberOptions, isArchivable, currentAppSettings,
        validate } = props;

    const renderInput = (isCreate: boolean) => {
        return (
            <AppSettingEdit
                label={label}
                hierarchyContext={hierarchyContext}
                setting={setting}
                withInfoModal={withInfoModal}
                isArchivable={isArchivable}
                isCreate={isCreate}
                inputType={inputType}
                dropdownNumberOptions={dropdownNumberOptions}
                done={cancelEdit}
                currentAppSettings={currentAppSettings}
                validate={validate}
            />
        );
    };

    const renderDetail = () => {
        const value = setting && setting.value;
        const text = inputType !== InputType.DropdownNumber || value !== "0"
            ? (value || <span className="status-warning">Not set</span>)
            : <span className="status-warning">Not set</span>;
        return (
            <Detail
                label={label}
                value={<span onClick={enableEdit}>{text}</span>}
            />
        );
    };

    const enableEdit = () => setEditing(true);

    const cancelEdit = () => setEditing(false);

    const settingEntity = setting && last(setting.hierarchy);
    const contextEntity = last(hierarchyContext);
    const isSetForContext = settingEntity === contextEntity;

    if (isSetForContext && (inputType !== InputType.DropdownNumber || setting?.value !== "0")) {
        return renderInput(false);
    }
    if (editing) {
        return renderInput(true);
    }

    return renderDetail();
};
