export const TrainerAttributeDorsStatus = {
    0: "No Status",
    1: "Full",
    2: "Provisional",
    3: "Conditional",
    4: "Suspended",
    5: "Revoked",
    6: "Surrendered",
    7: "Expired"
};

export enum TrainerAttributeDorsStatusEnum {
    NoStatus = 0,
    Full = 1,
    Provisional = 2,
    Conditional = 3,
    Suspended = 4,
    Revoked = 5,
    Surrendered = 6,
    Expired = 7,
};
