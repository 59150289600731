import * as React from "react";
import { Checkbox, Table } from "semantic-ui-react";
import { EventInstanceWithOrdersListModel } from "../model";
import { orderBy } from "lodash";
import { AttendeeRowCheckable } from "./AttendeeRowCheckable";
import { AttendeeListModel } from "@common/crud/attendee";
import "./Checkable.scss";

interface OrderEventInstanceRowProps {
    eventInstance: EventInstanceWithOrdersListModel;
    selectedEventInstances: { id: string; selectedAttendees: string[]; attendeesNotExpanded?: boolean }[];
    attendees: AttendeeListModel[];
    toggleAttendeeSelected: (eventInstanceId: string, attendeeId: string) => void;
    fetchEventInstanceAttendees: (eventInstanceId: string, eventInstanceSelected: boolean) => void;
    toggleEventInstanceSelected: (eventInstanceId: string, eventInstanceExtended: boolean, attendees: string[]) => void;
}

export const AllAttendeesCheckable: React.FC<OrderEventInstanceRowProps> = ({
    eventInstance,
    selectedEventInstances,
    attendees,
    toggleAttendeeSelected,
    fetchEventInstanceAttendees,
    toggleEventInstanceSelected,
}) => {
    const selectableAttendeeIds = React.useMemo(() => attendees.map(a => a.id), [attendees]);

    const eventInstanceSelected = React.useMemo(() => {
        const eventInstanceSelectedData = selectedEventInstances.find(ei => ei.id === eventInstance.id);
        return eventInstanceSelectedData?.id && (eventInstanceSelectedData.attendeesNotExpanded
            || eventInstanceSelectedData.selectedAttendees.slice().sort().join() === selectableAttendeeIds.slice().sort().join());
    }, [eventInstance, selectableAttendeeIds, selectedEventInstances]);

    const eventInstancePartiallySelected = React.useMemo(() => {
        const eventInstanceSelectedData = selectedEventInstances.find(ei => ei.id === eventInstance.id);
        return eventInstanceSelectedData?.id && !eventInstanceSelectedData.attendeesNotExpanded && eventInstanceSelectedData.selectedAttendees.length > 0
            && eventInstanceSelectedData.selectedAttendees.slice().sort().join() !== selectableAttendeeIds.slice().sort().join();
    }, [eventInstance, selectableAttendeeIds, selectedEventInstances]);

    React.useEffect(() => {
        if (eventInstance?.id) {
            fetchEventInstanceAttendees(eventInstance.id, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstance?.id]);

    const toggleSelected = React.useCallback(() => {
        toggleEventInstanceSelected(eventInstance.id, true, selectableAttendeeIds);
    }, [selectableAttendeeIds, eventInstance, toggleEventInstanceSelected]);

    return (
        <Table>
            <Table.Header className="grey">
                <Table.Row>
                    <Table.HeaderCell>Attendee</Table.HeaderCell>
                    <Table.HeaderCell>Driving Licence</Table.HeaderCell>
                    <Table.HeaderCell>Booking Reference</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell >
                        <Checkbox
                            checked={!!eventInstanceSelected}
                            onChange={toggleSelected}
                            className={eventInstancePartiallySelected ? "partiallySelected" : undefined}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orderBy(attendees, attendee => attendee.fullName).map((attendee, i) => (
                    <AttendeeRowCheckable
                        key={`attendee_${i}`}
                        eventInstanceId={eventInstance.id}
                        attendee={attendee}
                        index={i}
                        selected={selectedEventInstances
                            .find(ei => ei.id === eventInstance.id)?.selectedAttendees
                            .includes(attendee.id) || false}
                        toggleAttendeeSelected={toggleAttendeeSelected}
                    />
                ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell key={0}>Total Attendees: {attendees.length}</Table.HeaderCell>
                    <Table.HeaderCell key={1} />
                    <Table.HeaderCell key={2}></Table.HeaderCell>
                    <Table.HeaderCell key={3}></Table.HeaderCell>
                    <Table.HeaderCell key={4}></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};
