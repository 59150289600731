import * as React from "react";

export interface DeleteButtonProps {
    id: string;
    deleteCourse: (id: string) => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ id, deleteCourse }) => {
    const onClick = React.useCallback(() => {
        deleteCourse(id);
    }, [deleteCourse, id]);

    return (
        <a className="cursor-pointer" onClick={onClick}>X</a>
    );
};
