import { EventType } from "@common/crud/eventType";
import * as React from "react";
import { Grid, Table } from "semantic-ui-react";

interface PlannedEventsOverviewTableHeaderProps {
    eventTypes: EventType[];
}

export const PlannedEventsOverviewTableHeader: React.FC<PlannedEventsOverviewTableHeaderProps> = ({ eventTypes }) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">Schemes:</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign="center">
                                <p>Name</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>
                {eventTypes.map(t => (<Table.HeaderCell key={t.id}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                {t.abbreviation}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign="center">
                                <p>Courses</p>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="center">
                                <p>Seats</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>))}
                <Table.HeaderCell>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                    Total
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign="center">
                                <p>Courses</p>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="center">
                                <p>Seats</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header >
    );
};
