import { EventInstance } from "@common/crud/eventInstance";

import * as actions from "./actionTypes";
import { RebookEventInstanceAction } from "./actions";

export function reducer(state: EventInstance = null, action: RebookEventInstanceAction): EventInstance {
    switch (action.type) {
        case actions.LOAD_REBOOK_EVENTINSTANCE_DETAIL_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
