import * as React from "react";
import { Link } from "redux-little-router";
import { Table } from "semantic-ui-react";
import { Order } from "../model";
import { formatPrice, prepareOrdersUrl } from "../helpers";
import { useSelector } from "react-redux";
import { universalEventManagementPathSelector } from "@common/redux-helpers";

interface EventInstanceOrderRowProps {
    order: Order;
    corporateOrganisationId?: string;
    corporateUserId?: string;
    index: number;
}

export const EventInstanceOrderRow: React.FC<EventInstanceOrderRowProps> = ({
    order,
    corporateOrganisationId,
    corporateUserId,
    index,
}) => {
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const orderLink = React.useMemo(() => prepareOrdersUrl(eventManagementPath, `orders/${order.id}`, corporateOrganisationId, corporateUserId),
        [corporateOrganisationId, corporateUserId, order.id, eventManagementPath]);

    return (
        <Table.Row key={`orderRow_${index}`} className="grey">
            <Table.Cell>
                <Link href={orderLink}>
                    {order.bookingReference}
                </Link>
            </Table.Cell>
            <Table.Cell>{order.bookingDate.format("LL")}</Table.Cell>
            {!corporateOrganisationId && (
                <Table.Cell>
                    <Link
                        href={`${eventManagementPath}/organisations/${order.organisationId}`}
                    >
                        {order.organisationName}
                    </Link>
                </Table.Cell>
            )}
            {!corporateUserId && (
                <Table.Cell>
                    <Link
                        href={`${eventManagementPath}/organisations/${order.organisationId}/users/${order.bookerId}`}
                    >
                        {order.bookerName}
                    </Link>
                </Table.Cell>
            )}
            <Table.Cell>{order.attendeeCount}</Table.Cell>
            <Table.Cell>{formatPrice(order.total ?? 0)}</Table.Cell>
        </Table.Row>
    );
};
