import { DurationPicker } from "@common/crud/common";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Input } from "@neworbit/simpleui-input";
import moment from "moment";
import { ValidationFunction } from "not-valid";
import * as React from "react";
import { EventInstanceCreateModel, EventInstanceEditModel } from "../model";

interface StartTimeDropDownWithCustomProps {
    duration: moment.Duration;
    showErrors: boolean;
    ddStartTime: number;
    availableSuggestedTimes: { key: number; text: string; value: number }[];
    eventTypeId: string;
    updateProperty: (prop: keyof EventInstanceEditModel | keyof EventInstanceCreateModel) => (value: any, valid: boolean) => void;
    startTime: moment.Duration;
    label: string;
    updateFieldsAffectedByStartTime: (time: moment.Duration) => void;
    isEventStartTime?: boolean;
    showOther: boolean;
    startTimeKeys: (keyof EventInstanceEditModel)[];
    durationKeys: (keyof EventInstanceEditModel)[];
    otherTimeValidation?: ValidationFunction<string>[];
}

export const StartTimeDropDownWithCustom: React.FC<StartTimeDropDownWithCustomProps> =
({ duration, showErrors, ddStartTime, availableSuggestedTimes, eventTypeId,
    updateProperty, startTime, label, updateFieldsAffectedByStartTime, isEventStartTime, showOther, startTimeKeys, durationKeys, otherTimeValidation }) => {

    const [other, setOther] = React.useState(showOther);
    const [dropDownStartTime, setDropDownStartTime] = React.useState<number>(ddStartTime);

    React.useEffect(() => {
        setDropDownStartTime(ddStartTime);
    }, [ddStartTime]);

    function updateNewStartTime(value: string, valid: boolean) {
        const parsedTime = moment.duration(value);
        startTimeKeys.forEach((startTimeKey: keyof EventInstanceEditModel) => updateProperty(startTimeKey)(parsedTime, valid));
        setDropDownStartTime(0);
        if (isEventStartTime) {
            updateFieldsAffectedByStartTime(parsedTime);
        }
    };

    function updateDuration(value: moment.Duration, valid: boolean) {
        durationKeys.forEach((durationKey: keyof EventInstanceEditModel) => updateProperty(durationKey)(value, valid));
    };

    function updateStartTime(value: number, valid: boolean) {
        if (eventTypeId === "") {
            const selected = moment.duration(ddStartTime);
            startTimeKeys.forEach((startTimeKey: keyof EventInstanceEditModel) => updateProperty(startTimeKey)(selected, valid));
            setOther(false);
            setDropDownStartTime(0);
            if (isEventStartTime) {
                updateFieldsAffectedByStartTime(selected);
            }
        } else {
            const selected = value === 0 ? moment.duration({ hours: 9 }) : moment.duration(value);
            startTimeKeys.forEach((startTimeKey: keyof EventInstanceEditModel) => updateProperty(startTimeKey)(selected, valid));
            if (isEventStartTime) {
                updateFieldsAffectedByStartTime(selected);
            }
            if (value === 0) {
                setOther(true);
                setDropDownStartTime(value);
            } else {
                startTimeKeys.forEach((startTimeKey: keyof EventInstanceEditModel) => updateProperty(startTimeKey)(selected, valid));
                setOther(false);
                setDropDownStartTime(selected.asMilliseconds());
            }
        }
    };

    return (
        <>
            <DurationPicker
                label={`${label} Duration`}
                eventDuration={duration}
                showErrors={showErrors}
                onChange={updateDuration}
            />
            <Input.DropdownNumber
                value={dropDownStartTime}
                label={`${label} Start Time`}
                placeholder={`Select a ${label} Start Time`}
                required
                options={availableSuggestedTimes}
                showErrors={showErrors}
                onChange={updateStartTime}
                dynamicOptions
            />
            {other &&
                <Input.Time
                    value={startTime?.format(DateFormat.Time, { trim: false })}
                    label={`Custom ${label} Start Time`}
                    showErrors={showErrors}
                    onChange={updateNewStartTime}
                    validation={otherTimeValidation}
                />
            }
        </>
    );
};
