import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Icon, SemanticICONS } from "semantic-ui-react";
import { cashRangeValidator } from "@common/validation/cashRangeValidator";
import { ScheduledPaymentResponse } from "@common/payments/components/scheduled-payments/ScheduledPaymentBase";
import { IvrPaymentDetail, ScheduledPaymentPlanViewModel } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import { ApplicationState } from "../../applicationState";
import { eventInstanceSelector } from "../../eventInstance/selectors";
import { bookingBasePathSelector } from "@booking/landing/selectors";
import { useSetBeforeGenesysAuthPage } from "@common/genesys/hooks/useSetBeforeGenesysAuthPage";
import { PaymentPlanModal } from "./PaymentPlanModal";
import { Booking } from "@booking/bookings/models";
import { EventInstance } from "@common/crud/eventInstance";
import { Seat } from "@booking/seat/model";
import { ManualPaymentModel } from "../models";
import "./InlineManualPayment.scss";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";
import { createValidator } from "not-valid";

interface OwnProps {
    onManualPaymentChange: (model: ManualPaymentModel) => void;
    eligibleForFlexiPay: boolean;
    flexiPayChecked: boolean;
    flexiPayFee: number;
    setFlexiPayDisabled: (disabled: boolean) => void;
    seatDiscount: number;
}

interface InlineManualPaymentStateProps {
    booking: Booking;
    eventInstance: EventInstance;
    path: string;
    seat: Seat;
    includeFullyBookedCourses: boolean;
}

const InlineManualPaymentInternal: React.FC<OwnProps & InlineManualPaymentStateProps> = (props) => {
    const { booking, eventInstance, onManualPaymentChange, eligibleForFlexiPay, flexiPayChecked, flexiPayFee, setFlexiPayDisabled, seatDiscount } = props;

    const [showInlinePayment, setShowInlinePayment] = React.useState(false);
    const [scheduledPaymentPlans, setScheduledPaymentPlans] = React.useState<ScheduledPaymentPlanViewModel[]>([]);
    const [manualPayment, setManualPayment] = React.useState<ManualPaymentModel>({
        skipPayment: false,
        waivePayment: false,
        isValid: true,
        isPlanValid: true,
        scheduledPaymentPlan: null,
        activePlans: [],
        outstandingAmount: undefined,
        paymentValue: 0
    });
    const [paymentAmountKey, setPaymentAmountKey] = React.useState("");
    const [bookingPayment, setBookingPayment] = React.useState<IvrPaymentDetail>(null);
    const [paymentValueManuallyChanged, setPaymentValueManuallyChanged] = React.useState(false);

    const {
        skipPayment,
        waivePayment,
        activePlans,
        outstandingAmount,
        paymentValue,
    } = manualPayment;

    const flexiPayFeeLoading = eligibleForFlexiPay && flexiPayFee === null;
    const eventPrice = Math.max(eventInstance?.priceInPence - (seatDiscount * 100 ?? 0)|| 0, 0);
    const rebookingFee = eventInstance?.additionalFeeInPence || 0;
    const amount = waivePayment ? eventPrice - rebookingFee : eventPrice + (flexiPayChecked ? flexiPayFee * 100 : 0);
    const minAmount = flexiPayChecked && !flexiPayFeeLoading ? flexiPayFee * 100 : 0;

    useSetBeforeGenesysAuthPage();

    useEffect(() => {
        async function getActiveScheduledPaymentPlans() {
            const paymentApi = new PaymentApi(booking.id);
            const response = await paymentApi.getScheduledPaymentPlans();
            setManualPayment(prev => ({
                ...prev,
                activePlans: response
            }));
        }

        if (booking?.id) {
            getActiveScheduledPaymentPlans();
        }
    }, [booking.id]);

    const isDefaultPlan = scheduledPaymentPlans.every(s => s.amount === 0 && s.chargeDate === null);
    const totalPlanAmount = scheduledPaymentPlans.reduce((p, c) => p += c.amount, 0);

    const validateRegularPayment = React.useCallback((value: number) => value >= minAmount / 100 && value <= amount / 100, [minAmount, amount]);
    const validatePaymentWithPlan = React.useCallback((value: number) => value + totalPlanAmount === amount / 100, [totalPlanAmount, amount]);
    const validatePayment = isDefaultPlan ? validateRegularPayment : validatePaymentWithPlan;

    useEffect(() => {
        let newKey = "";
        newKey += flexiPayChecked ? "flexipay" : "noflexipay";
        newKey += isDefaultPlan ? "-default" : "-manual";
        newKey += totalPlanAmount > 0 ? totalPlanAmount : "noplan";
        setPaymentAmountKey(newKey);
    }, [flexiPayChecked, isDefaultPlan, totalPlanAmount]);

    useEffect(() => {
        setManualPayment(prev => ({
            ...prev,
            outstandingAmount: amount / 100,
            paymentValue: isDefaultPlan ? amount / 100 : prev.paymentValue,
            isValid: isDefaultPlan ? validatePayment(amount / 100) : validatePayment(prev.paymentValue)
        }));

    }, [amount,
        isDefaultPlan,
        validatePayment
    ]);

    useEffect(() => {
        setFlexiPayDisabled(skipPayment);
    }, [skipPayment, setFlexiPayDisabled]);

    useEffect(() => {
        async function loadBookingPayment() {
            const api = new PaymentApi(booking?.id);
            const response = await api.getIvrPaymentDetails();
            setBookingPayment(response);
        }

        if (booking?.id && !bookingPayment) {
            loadBookingPayment();
        }
    }, [booking?.id, bookingPayment]);

    useEffect(() => {
        if (bookingPayment && !paymentValueManuallyChanged) {
            const { isSeatReserved, paymentComplete, flexiPayPurchased } = bookingPayment.paymentStatusModel;
            if (isSeatReserved && !paymentComplete) {
                let newPaymentValue = bookingPayment.amount;
                if (flexiPayPurchased && !flexiPayChecked) {
                    newPaymentValue -= flexiPayFee;
                }

                setManualPayment(prev => ({
                    ...prev,
                    paymentValue: newPaymentValue,
                    isValid: validatePayment(newPaymentValue)
                }));

                setShowInlinePayment(newPaymentValue < outstandingAmount);
            }
        }
    }, [bookingPayment, flexiPayChecked, flexiPayFee, outstandingAmount, validatePayment, paymentValueManuallyChanged]);

    useEffect(() => {
        if (!showInlinePayment && !manualPayment.isValid) {
            setShowInlinePayment(true);
        }
    }, [manualPayment.isValid, showInlinePayment]);

    useEffect(() => {
        onManualPaymentChange(manualPayment);
    }, [manualPayment, onManualPaymentChange]);

    function getPaymentPlan(planObject: ScheduledPaymentResponse) {
        setManualPayment({
            ...manualPayment,
            isPlanValid: planObject.isPlanValid,
            scheduledPaymentPlan: planObject.plan
        });
    }

    function onSkipPaymentChange(value: boolean) {
        setManualPayment({ ...manualPayment, skipPayment: value });
        if (value) {
            setScheduledPaymentPlans([]);
        }
    }

    function onWaivePaymentChange(value: boolean) {
        setManualPayment({ ...manualPayment, waivePayment: value });
    }

    function onPaymentValueChange(value: number, valid: boolean) {
        setPaymentValueManuallyChanged(true);
        setManualPayment({
            ...manualPayment,
            paymentValue: value,
            isValid: valid
        });
    }

    function onToggleInlineManualPayment() {
        setShowInlinePayment(!showInlinePayment);
    }

    const validators = isDefaultPlan ?
        cashRangeValidator(minAmount / 100, amount / 100) :
        [createValidator<number>(validatePaymentWithPlan, `Value and scheduled payments must add up to £${amount / 100}`)];

    PreventScrollingOnNumberInputsHook();
    return (
        <>
            <Button className="link-button blue" onClick={onToggleInlineManualPayment}>
                {showInlinePayment ? "Hide" : "Show"} alternative payment options
                &nbsp;&nbsp;&nbsp;
                <Icon name={`arrow ${(showInlinePayment ? "up" : "down")}` as SemanticICONS} />
            </Button>
            {showInlinePayment &&
                <Grid className="inline-payment">
                    <Grid.Row>
                        <Grid.Column width="10">Skip payment (pay later)?</Grid.Column>
                        <Grid.Column width="6" textAlign="right">
                            <Input.Checkbox disabled={eventPrice === 0 || flexiPayChecked} value={skipPayment} onChange={onSkipPaymentChange} />
                        </Grid.Column>
                    </Grid.Row>
                    {eventInstance.additionalFeeInPence > 0 &&
                        <Grid.Row>
                            <Grid.Column width="10">Waive fee (free of charge rebook)?</Grid.Column>
                            <Grid.Column width="6" textAlign="right">
                                <Input.Checkbox value={waivePayment} onChange={onWaivePaymentChange} />
                            </Grid.Column>
                        </Grid.Row>
                    }
                    <Grid.Row>
                        <Grid.Column width="10">Payment Amount</Grid.Column>
                        <Grid.Column width="6" textAlign="right">
                            {outstandingAmount > 0 &&
                                <Input.Number
                                    required
                                    disabled={skipPayment}
                                    validationOptions={{ sequential: true }}
                                    validation={validators}
                                    onChange={onPaymentValueChange}
                                    value={paymentValue}
                                    key={paymentAmountKey}
                                    showErrors={!skipPayment}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width="7">Scheduled Payments</Grid.Column>
                        <Grid.Column width="9" textAlign="right">
                            {activePlans.length === 0 && isDefaultPlan && <span>No Plan</span>}
                            {!isDefaultPlan && (
                                <>
                                    <p>Number of scheduled payments: {scheduledPaymentPlans.length}</p>
                                    <p>Amount of scheduled payment set up: £{totalPlanAmount.toFixed(2)}</p>
                                </>
                            )}
                            <br />
                            <PaymentPlanModal
                                skipPayment={skipPayment}
                                sendPlan={getPaymentPlan}
                                maxValue={outstandingAmount}
                                paymentValue={paymentValue}
                                eventDate={eventInstance && eventInstance.startDate}
                                scheduledPaymentPlans={scheduledPaymentPlans}
                                setScheduledPaymentPlans={setScheduledPaymentPlans}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {activePlans && activePlans.length > 0 &&
                <Grid.Row>
                    <span className="plans-note">
                        This attendee has previously agreed to pay by installments.
                        &nbsp;Please note that any active plan will be cancelled if a new installment plan
                        &nbsp;is confirmed or if the attendee pays their balance in full.
                    </span>
                </Grid.Row>
            }
        </>
    );
};

const mapStateToProps = (state: ApplicationState): InlineManualPaymentStateProps => ({
    booking: state.booking,
    eventInstance: eventInstanceSelector(state),
    path: bookingBasePathSelector(state),
    seat: state.seats[0],
    includeFullyBookedCourses: state.includeFullyBookedCourses,
});

export const InlineManualPayment = connect(mapStateToProps)(InlineManualPaymentInternal);
