import * as React from "react";
import { useState } from "react";
import { Accordion, AccordionTitleProps } from "semantic-ui-react";
import { Questionnaire } from "@common/crud/eventInstance/components/monitoring/Questionnaire";
import {
    Criterium,
    TrainerMonitoringReportCreateEditModel,
    TrainerMonitoringReportStatusEnum,
    TrainerMonitoringReportSubSection,
} from "@common/crud/trainer/trainerMonitoringModel";
import {
    FormValidationResult,
    QuestionSection,
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { TrainersCommentsSection } from "@common/crud/eventInstance/components/monitoring/questions/TrainersCommentsSection";
import { IQAReviewSection } from "@common/crud/eventInstance/components/monitoring/questions/IQAReviewSection";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { getQuestions } from "./helper";

export interface MonitoringReportFormProps {
    formType: string;
    isDigital: boolean;
    reportFormDataModel: TrainerMonitoringReportCreateEditModel;
    updateFormData: (model: TrainerMonitoringReportCreateEditModel) => void;
    lockedForChange: boolean;
    currentUserIsTrainerOnCourse: boolean;
    currentUserIsMonitorOrTrainerAdmin: boolean;
    isIqaReview: boolean;
    iqaCanSubmit: boolean;
    formValidationResult: FormValidationResult[];
    autoSaveProgress: () => void;
}
export const MonitoringReportForm: React.FC<MonitoringReportFormProps> = ({
    formType,
    isDigital,
    reportFormDataModel,
    updateFormData,
    lockedForChange,
    currentUserIsTrainerOnCourse,
    currentUserIsMonitorOrTrainerAdmin,
    isIqaReview,
    iqaCanSubmit,
    formValidationResult,
    autoSaveProgress
}) => {
    const [activeIndices, setActiveIndices] = useState([]);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const isIqaUpdateAllowed = useSelector(appSelector) === Apps.Admin && iqaCanSubmit;

    const trainerFeedbackNotAllowed =
        reportFormDataModel?.status !== TrainerMonitoringReportStatusEnum.Submitted;

    const trainerCanSeeIqa = currentUserIsTrainerOnCourse && reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.CompletedLocked;

    const handleActiveClick = React.useCallback((e: any, titleProps: AccordionTitleProps) => {
        const newIndex = titleProps.index as number;
        setActiveIndices(prev => {
            const previousIndex = prev.length > 0 ? prev[prev.length - 1] : -1;
            if (newIndex > previousIndex && previousIndex > -1) {
                return [...prev, newIndex];
            } else if (prev.some(a => a === newIndex)) {
                return prev.filter(a => a !== newIndex);
            } else {
                return [...prev.filter(a => a < newIndex), newIndex];
            }
        });

        if (isDirty) {
            autoSaveProgress();
        }
        setIsDirty(false);
    }, [isDirty, autoSaveProgress]);

    const updateValue = React.useCallback((value: Criterium) => {
        const sectionId = value.sectionId;
        const subSectionId = value.subSectionId;
        const subSection = reportFormDataModel.sections
            .find((s) => s.id === sectionId)
            .subSections?.find((ss) => ss.id === subSectionId);
        const oldCriteriumIndex = subSection?.criteria.findIndex((c) => c.id === value.id);
        subSection?.criteria.splice(oldCriteriumIndex, 1, value);
        updateFormData(reportFormDataModel);
        setIsDirty(true);
    }, [reportFormDataModel, updateFormData]);

    const updateSubSectionSatisfaction = React.useCallback(
        (subSection: TrainerMonitoringReportSubSection) => {
            const sectionId = subSection.sectionId;
            const subSectionId = subSection.id;
            const currentSection = reportFormDataModel.sections.find((s) => s.id === sectionId);
            const oldSubSectionIndex = currentSection?.subSections?.findIndex(
                (ss) => ss.id === subSectionId
            );
            currentSection?.subSections?.splice(oldSubSectionIndex, 1, subSection);
            updateFormData(reportFormDataModel);
            setIsDirty(true);
        }, [reportFormDataModel, updateFormData]);

    const trainerCommentsSection = (locked: boolean) => {
        return (
            <Questionnaire
                key={TrainersCommentsSection.id}
                active={activeIndices.includes(TrainersCommentsSection.seqNum)}
                section={TrainersCommentsSection}
                onHandleActiveClick={handleActiveClick}
                sectionData={reportFormDataModel?.sections?.find((x) => x.id === TrainersCommentsSection.id)}
                updateValue={updateValue}
                updateSubSectionSatisfaction={updateSubSectionSatisfaction}
                lockedForChange={locked}
                formValidationResult={formValidationResult}
            />
        );
    };

    const iqaReviewSection = () => {

        const renderIqaSection = (isLocked: boolean) => {
            return (
                <Questionnaire
                    key={IQAReviewSection.id}
                    active={activeIndices.includes(IQAReviewSection.seqNum)}
                    section={IQAReviewSection}
                    onHandleActiveClick={handleActiveClick}
                    sectionData={reportFormDataModel?.sections?.find((x) => x.id === IQAReviewSection.id)}
                    updateValue={updateValue}
                    updateSubSectionSatisfaction={updateSubSectionSatisfaction}
                    lockedForChange={isLocked}
                    formValidationResult={formValidationResult}
                />
            );
        };

        return (
            <>
                {trainerCanSeeIqa ?
                    renderIqaSection(true) :
                    currentUserIsMonitorOrTrainerAdmin ?
                        renderIqaSection(!isIqaUpdateAllowed) :
                        null}
            </>
        );
    };

    const questions = React.useMemo(() => getQuestions(formType, isDigital), [formType, isDigital]);

    return (
        <Accordion className="monitoring-acc" styled fluid>
            {questions !== null ? (
                <>
                    {questions.sections.map((s: QuestionSection) => (
                        <Questionnaire
                            key={s.id}
                            active={activeIndices.includes(s.seqNum)}
                            section={s}
                            onHandleActiveClick={handleActiveClick}
                            sectionData={reportFormDataModel?.sections?.find((x) => x.id === s.id)}
                            updateValue={updateValue}
                            updateSubSectionSatisfaction={updateSubSectionSatisfaction}
                            lockedForChange={lockedForChange || currentUserIsTrainerOnCourse}
                            formValidationResult={formValidationResult}
                        />
                    ))}
                    {currentUserIsTrainerOnCourse
                        ? trainerCommentsSection(trainerFeedbackNotAllowed)
                        : trainerCommentsSection(true)}
                    {isIqaReview && iqaReviewSection()}
                </>
            ) : <div>Scheme not recognised!</div>}
        </Accordion>
    );
};
