import * as React from "react";

import { DorsRestfulServiceSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const DorsRestfulService: ConfigurationAreaComponent<DorsRestfulServiceSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="DorsRestfulService"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "DorsServiceEndpoint", label: "Dors RESTful Service Url" },
                { name: "Username", label: "Username" },
                { name: "Password", label: "Password" },
            ]}
        />
    );
};
