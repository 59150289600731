/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_DRINKDRIVEOFFENDERS_SUCCESS = "LOAD_DRINKDRIVEOFFENDERS_SUCCESS";
export type LOAD_DRINKDRIVEOFFENDERS_SUCCESS = typeof LOAD_DRINKDRIVEOFFENDERS_SUCCESS;

export const LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS = "LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS";
export type LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS = typeof LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS;

export const LOAD_HISTORY_SUCCESS = "LOAD_HISTORY_SUCCESS";
export type LOAD_HISTORY_SUCCESS = typeof LOAD_HISTORY_SUCCESS;

export const CREATE_DRINKDRIVEOFFENDER_SUCCESS = "CREATE_DRINKDRIVEOFFENDER_SUCCESS";
export type CREATE_DRINKDRIVEOFFENDER_SUCCESS = typeof CREATE_DRINKDRIVEOFFENDER_SUCCESS;

export const SAVE_DRINKDRIVEOFFENDER_SUCCESS = "SAVE_DRINKDRIVEOFFENDER_SUCCESS";
export type SAVE_DRINKDRIVEOFFENDER_SUCCESS = typeof SAVE_DRINKDRIVEOFFENDER_SUCCESS;

export const UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS = "UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS";
export type UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS = typeof UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS;
