import * as React from "react";
import { Button, Modal, Form, DropdownItemProps, Popup } from "semantic-ui-react";
import { getYesNoValue, YesNoQuestion } from "@common/payments/components/YesNoQuestion";
import { Input } from "@neworbit/simpleui-input";
import { DdrsAttendeeApi } from "@common/crud/attendee/ddrsAttendeeApi";
import { AttendeeDetailModel } from "@common/crud/attendee/model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { ExternalDdrsProviders } from "../../model";
import { useDispatch } from "react-redux";
import { loadAttendeeDetailSuccess } from "@common/crud/attendee/actions";
import { DorsBooking } from "@common/crud/dorsBooking";
import { loadDrinkDriveOffenderDetailSuccess } from "../../actions";
import { DrinkDriveOffenderApi } from "../../drinkDriveOffenderApi";

export interface DdrsProviderTransferModalProps {
    attendee: AttendeeDetailModel & DorsBooking;
}

export const DdrsProviderTransferModal: React.FC<DdrsProviderTransferModalProps> = ({ attendee }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [isTransferring, setIsTransferring] = React.useState(false);
    const [selectedProvider, setSelectedProvider] = React.useState<string>();

    function openModal() { setOpen(!open); }

    function closeModal() {
        setIsTransferring(false);
        setSelectedProvider(undefined);
        setOpen(false);
    }

    async function onContinueClick() {
        setLoading(true);
        const providerName = attendee.isTransferred ? null : selectedProvider;

        if (attendee.eventInstanceId) {
            const attendeeApi = new DdrsAttendeeApi(attendee.eventInstanceId);
            const response = await attendeeApi.transferProvider(attendee.id, providerName);
            dispatch(loadAttendeeDetailSuccess(response));

        } else {
            const drinkDriveOffenderApi = new DrinkDriveOffenderApi();
            const ddrsOffender = await drinkDriveOffenderApi.transferProvider(attendee.id, providerName);
            dispatch(loadDrinkDriveOffenderDetailSuccess(ddrsOffender));
        }

        setLoading(false);
        closeModal();
    }

    function onIsTransferringChange(toggle: boolean) {
        setIsTransferring(toggle);
    }

    function onSelectedProviderChange(value: string) {
        setSelectedProvider(value);
    }

    const providerOptions = ExternalDdrsProviders.map<DropdownItemProps>(p => ({ text: p, value: p }));

    // Disable if attendee is still booked
    const transferButtonDisabled = attendee.eventInstanceId && !attendee.isBookingCanceled;
    const continueButtonDisabled = !attendee.isTransferred && (!isTransferring || !selectedProvider);

    return (
        <>
            <Popup
                disabled={!attendee.eventInstanceId || attendee.isBookingCanceled}
                content={"Attendee cannot be transferred if booked on a course"}
                trigger={
                    <Button onClick={openModal} disabled={transferButtonDisabled}>
                        {attendee.isTransferred ? "Undo Transfer" : "Transfer"}
                    </Button>
                }
            />
            <Modal open={open}>
                <Modal.Header>
                    {`${(attendee.isTransferred ? "Undo" : "")} Attendee Transfer`}
                </Modal.Header>
                <Modal.Content>
                    {!attendee.isTransferred &&
                        <Form>
                            <Form.Field>
                                <YesNoQuestion
                                    label="Is the attendee transferring to another DDRS provider?"
                                    value={isTransferring}
                                    onChange={getYesNoValue(onIsTransferringChange)}
                                />
                            </Form.Field>
                            {isTransferring &&
                                <Form.Field>
                                    <Input.Dropdown
                                        options={providerOptions}
                                        value={selectedProvider}
                                        onChange={onSelectedProviderChange}
                                        label="Which provider is this attendee transferring to?"
                                    />
                                </Form.Field>
                            }
                        </Form>
                    }
                    {attendee.isTransferred &&
                        <>
                            <p>This attendee was transferred to {" "}
                                <strong>{attendee.drinkDriveOffenderDetails?.transferProvider}</strong> on {" "}
                                {attendee.drinkDriveOffenderDetails?.transferDate?.format(DateFormat.LongWithTime)}
                            </p>
                            <p>Would you like to undo the transfer?</p>
                        </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={closeModal}
                        className="cancel-action"
                        disabled={loading}
                        content="Cancel"
                    />
                    <Button
                        onClick={onContinueClick}
                        loading={loading}
                        disabled={continueButtonDisabled}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content={attendee.isTransferred ? "Undo Transfer" : "Continue"}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
