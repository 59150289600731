/* eslint-disable max-lines */
import { Dispatch } from "redux";
import { push, State as RouterState } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { toast } from "@common/toasts";
import * as actions from "./actiontypes";
import { LOAD_ATTENDEES } from "@common/crud/attendee/actiontypes";
import * as models from "./model";
import {
    AppState,
    ClearTrainerAvailabilityEnum,
    EventInstance,
    OtherTrainerTypeCategoryEnum,
    RegisterState,
    SearchOptions,
    TrainerType,
    UpdateCertificateCommsDestinationModel,
    UpdateClosedCourseCommsDestinationsModel,
    SendClosedCourseReminderCommsModel,
    SendClosedCourseCreationCommsModel,
    BookNowPayLaterOrderAttendees,
    EnquiryEventInstanceData,
    EnquiryStatusUpdateModel
} from "./model";
import { EventInstanceApi } from "./eventInstanceApi";
import { EventInstanceDetailModel, EventInstanceListModel } from ".";
import { ApplicationState } from "src/Admin.App/src/applicationState";
import { routeTrainerIdSelector } from "../trainer/selectors";
import moment from "moment";
import { TrainerAllocationModel } from "../trainer/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { CorporateQuickAddEntityDictionary, QuickAddEntities, QuickAddEntityDictionary } from "../quickAdd/model";
import { v4 } from "uuid";
import { EventTypeCategory } from "../attendee/model";
import { EventInstanceGroupApi } from "../eventInstanceGroup/eventInstanceGroupApi";
import { AllWorkflowTypeArray, ConstructionWorkflowTypeArray, CorporateWorkflowTypeArray, ModuleTypeEnum, PoliceAndCourtWorkflowTypeArray,
    WorkflowTypeEnum } from "@common/crud/eventType/model";
import { RedirectTrainerToCoursesPageInTrainerApp, toastMissingNdorsLicenceTrainers } from "./helpers";
import { BusinessLineType } from "@common/redux-helpers";
import { loadAttendeesFromSuccess, loadAttendeesSuccess } from "../attendee/actions";
import { AttendeeApi } from "../attendee";
import { isCorporateSelector } from "../common/selectors";
import { FavouriteCreateEditModel } from "../../favourites/model";
import { UserFavouritesApi } from "../../favourites/UserFavouritesApi";

export type EventInstanceAction =
    ({ type: actions.LOAD_EVENTINSTANCES_SUCCESS } & Payload<models.EventInstanceListModel[]>)
    | ({ type: typeof actions.LOAD_EVENTINSTANCE_GROUP_SUCCESS } & Payload<EventInstanceGroupModel>)
    | ({ type: typeof actions.LOAD_EVENTINSTANCE_GROUPS_SUCCESS } & Payload<EventInstanceGroupModel[]>)
    | ({ type: actions.CREATE_EVENTINSTANCE_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: actions.CREATE_EVENTINSTANCES_SUCCESS } & Payload<models.EventInstanceDetailModel[]>)
    | ({ type: actions.LOAD_EVENTINSTANCE_DETAIL_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: actions.SAVE_EVENTINSTANCE_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: actions.SAVE_EVENTINSTANCES_SUCCESS } & Payload<models.EventInstanceDetailModel[]>)
    | ({ type: actions.SAVE_EVENTINSTANCE_GROUP_SUCCESS } & Payload<EventInstanceGroupModel>)
    | ({ type: typeof actions.UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: typeof actions.CANCEL_EVENT_INSTANCE_SUCCESS } & Payload<{ id: string; reasonForCancellation?: string;
        financeResponse?: models.CancelEventInstanceFinanceResponse; }>)
    | ({ type: typeof actions.CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS } & Payload<EventInstanceListModel[]>)
    | ({ type: typeof actions.BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS } & Payload<EventInstanceDetailModel[]>)
    | ({ type: typeof actions.BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS } & Payload<EventInstanceDetailModel[]>)
    | ({ type: typeof actions.PUBLISH_EVENT_INSTANCE_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: typeof actions.SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: typeof actions.REGISTRATION_SUBMIT_SUCCESS } & Payload<RegisterState>)
    | ({ type: typeof actions.ACCEPT_EVENT_INSTANCES_SUCCESS } & Payload<string[]>)
    | ({ type: typeof actions.BULK_REMOVE_TRAINERS_SUCCESS } & Payload<models.EventInstanceListModel[]>)
    | ({ type: typeof actions.BULK_REMOVE_TRAINER_SUCCESS } & Payload<models.EventInstanceListModel[]>)
    | ({ type: typeof actions.BULK_UPDATE_DORS_OPEN_PLACES_COUNT_SUCCESS } & Payload<models.EventInstanceListModel[]>)
    | ({ type: typeof actions.SAVE_NOTES_SUCCESS } & Payload<{ id: string; notes: models.EINotes }>)
    | ({ type: typeof actions.SAVE_HEALTH_AND_SAFETY_SUCCESS } & Payload<{ id: string; healthAndSafety: models.EventInstanceHealthAndSafetyModel}>)
    | ({ type: typeof actions.SAVE_FINANCE_DETAILS_SUCCESS } & Payload<models.EventInstanceDetailModel>)
    | ({ type: typeof actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS }
        & Payload<{ id: string; adminAvailableRoles: models.TrainerAvailabilityRoleTypeEnum[] }>)
    | ({ type: typeof actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS }
        & Payload<models.EventInstanceDetailModel>)
    | ({ type: typeof actions.ALLOCATE_TRAINERS_SUCCESS } & Payload<{ id: string }>)
    | ({ type: typeof actions.LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS } & Payload<models.DigitalPlanningSession[]>)
    | ({ type: typeof actions.SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS } & Payload<models.DigitalPlanningSession>)
    | ({ type: typeof actions.DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS } & Payload<string>)
    | ({ type: typeof actions.TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS } & Payload<number>)
    | ({ type: typeof actions.UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS } & Payload<{id: string; favourite: boolean}>)
    | ({ type: typeof actions.LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS } & Payload<BookNowPayLaterOrderAttendees[]>)
    | ({ type: typeof actions.LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS } & Payload<EnquiryEventInstanceData[]>)
    | ({ type: typeof actions.UPDATE_ENQUIRY_STATUS_SUCCESS } & Payload<EnquiryStatusUpdateModel>)
    | ({ type: actions.CONVERT_TO_BOOKABLE_SUCCESS } & Payload<models.EventInstanceDetailModel>);

export const loadEventInstancesSuccess = (payload: models.EventInstanceListModel[]): EventInstanceAction => ({
    payload,
    type: actions.LOAD_EVENTINSTANCES_SUCCESS
});

export const totalNumberOfEventInstancesSuccess = (payload: number): EventInstanceAction => ({
    payload,
    type: actions.TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS
});

export const createEventInstanceSuccess = (payload: models.EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.CREATE_EVENTINSTANCE_SUCCESS
});

export const createEventInstancesSuccess = (payload: models.EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.CREATE_EVENTINSTANCES_SUCCESS
});

export const saveEventInstanceSuccess = (payload: models.EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.SAVE_EVENTINSTANCE_SUCCESS
});

export const saveEventInstancesSuccess = (payload: models.EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.SAVE_EVENTINSTANCES_SUCCESS
});

export const saveEventInstanceGroupSuccess = (payload: EventInstanceGroupModel): EventInstanceAction => ({
    payload,
    type: actions.SAVE_EVENTINSTANCE_GROUP_SUCCESS
});

export const publishEventInstanceSuccess = (payload: models.EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.PUBLISH_EVENT_INSTANCE_SUCCESS
});

export const updateReasonForHidingEventSuccess = (payload: EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS
});

export const bulkUpdateReasonForHidingEventSuccess = (payload: EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS
});

export const bulkMakeAvailableToTrainersSuccess = (payload: EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS
});

export const bulkUpdateDorsOpenPlacesCountSuccess = (payload: EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.BULK_UPDATE_DORS_OPEN_PLACES_COUNT_SUCCESS
});

export const cancelEventInstanceSuccess = (id: string, reasonForCancellation?: string,
    financeResponse?: models.CancelEventInstanceFinanceResponse): EventInstanceAction => ({
    payload: { id, reasonForCancellation, financeResponse },
    type: actions.CANCEL_EVENT_INSTANCE_SUCCESS
});

export const cancelMultipleEventInstanceSuccess = (payload: EventInstanceListModel[]): EventInstanceAction => ({
    payload,
    type: actions.CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS
});

export const setConfirmationStatusSuccess = (payload: models.EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS
});

export const registrationSubmitSuccess = (registerState: RegisterState): EventInstanceAction => ({
    payload: registerState,
    type: actions.REGISTRATION_SUBMIT_SUCCESS
});

export const acceptMultipleEventInstancesSuccess = (eventInstanceIds: string[]): EventInstanceAction => ({
    payload: eventInstanceIds,
    type: actions.ACCEPT_EVENT_INSTANCES_SUCCESS
});

export const bulkRemoveTrainersSuccess = (payload: EventInstanceListModel[]): EventInstanceAction => ({
    payload,
    type: actions.BULK_REMOVE_TRAINERS_SUCCESS
});

export const saveNotesSuccess = (id: string, notes: models.EINotes): EventInstanceAction => ({
    payload: { id, notes },
    type: actions.SAVE_NOTES_SUCCESS
});

export const saveHealthAndSafetySuccess = (id: string, healthAndSafety: models.EventInstanceHealthAndSafetyModel): EventInstanceAction => ({
    payload: { id, healthAndSafety },
    type: actions.SAVE_HEALTH_AND_SAFETY_SUCCESS
});

export const saveFinanceDetailsSuccess = (payload: EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.SAVE_FINANCE_DETAILS_SUCCESS
});

export const allocateTrainersSuccess = (id: string): EventInstanceAction => ({
    payload: { id },
    type: actions.ALLOCATE_TRAINERS_SUCCESS
});

export const updateEventInstanceFavouriteStatus = (id: string, favourite: boolean): EventInstanceAction => ({
    payload: { id, favourite },
    type: actions.UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS
});

export const setEventInstanceAvailabilityForOtherTrainersSuccess =
    (
        id: string,
        adminAvailableRoles: models.TrainerAvailabilityRoleTypeEnum[]
    ): EventInstanceAction =>
        ({
            payload: { id, adminAvailableRoles },
            type: actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS
        });

export const setEventInstanceAvailabilityForOtherTrainersByTrainerSuccess = (payload: models.EventInstanceDetailModel):
    EventInstanceAction =>
    ({
        payload,
        type: actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS
    });

export const loadDigitalPlanningProgressSuccess = (payload: models.DigitalPlanningSession[]): EventInstanceAction => ({
    payload,
    type: actions.LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS
});

export const saveDigitalPlanningProgressSuccess = (payload: models.DigitalPlanningSession): EventInstanceAction => ({
    payload,
    type: actions.SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS
});

export const deleteDigitalPlanningProgressSuccess = (payload: string): EventInstanceAction => ({
    payload,
    type: actions.DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS
});

export const loadEIGroupSuccess = (eventInstanceGroup: EventInstanceGroupModel): EventInstanceAction =>
    ({
        payload: eventInstanceGroup,
        type: actions.LOAD_EVENTINSTANCE_GROUP_SUCCESS
    });

export const loadEventInstanceGroupsSuccess = (eventInstanceGroups: EventInstanceGroupModel[]): EventInstanceAction => ({
    payload: eventInstanceGroups,
    type: actions.LOAD_EVENTINSTANCE_GROUPS_SUCCESS
});

export const loadEventInstanceFinanceDataSuccess = (payload: BookNowPayLaterOrderAttendees[]): EventInstanceAction => ({
    payload,
    type: actions.LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS
});

export const loadEventInstanceEnquiriesDataSuccess = (payload: EnquiryEventInstanceData[]): EventInstanceAction => ({
    payload,
    type: actions.LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS
});

export const updateEnquiryStatusSuccess = (payload: EnquiryStatusUpdateModel): EventInstanceAction => ({
    payload,
    type: actions.UPDATE_ENQUIRY_STATUS_SUCCESS
});

export const convertToBookableSuccess = (payload: EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.CONVERT_TO_BOOKABLE_SUCCESS
});

export function createEventInstance(eventInstance: models.EventInstanceCreateModel, path: string, businessLineType: BusinessLineType,
    quickAddType?: QuickAddEntities) {
    return async (dispatch: Dispatch) => {
        const eiApi = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "createEventInstance", eiApi.create(eventInstance));
        if (result.eventInstanceCreatedWithErrors) {
            toast.warning(result.errorMessage);
        }

        if (!result.eventInstanceCreated) {
            toast.error(result.errorMessage);
        }

        if (result.eventInstanceCreated) {
            dispatch(createEventInstanceSuccess(result.eventInstance));

            if (quickAddType) {
                toast.success("Quick Add Event Instance Successful");
                dispatch(push(`${path}/quickAdd/${quickAddType === QuickAddEntities.Course
                    ? QuickAddEntityDictionary[QuickAddEntities.Course]
                    : CorporateQuickAddEntityDictionary[quickAddType]}/${v4()}`));
            }
            else {
                dispatch(push(businessLineType === BusinessLineType.PoliceAndCourt
                    ? `${path}/${result.eventInstance.id}`
                    : businessLineType === BusinessLineType.Corporate
                        ? `/corporate-event-management/eventInstances/${result.eventInstance.id}`
                        : `/construction-event-management/eventInstances/${result.eventInstance.id}`));
            }

        }
    };
}

export function createEventInstances(eventInstances: models.EventInstanceCreateModel[], useExistingTime?: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "createEventInstances", api.createEventInstances(eventInstances, useExistingTime));
        dispatch(createEventInstancesSuccess(result));
    };
}

export function saveEventInstanceTimes(eventInstanceId: string, startTime: string, endTime: string, breaks: { startTime: string; endTime: string }[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "saveEventInstanceTimes", api.saveEventInstanceTimes(eventInstanceId, startTime, endTime, breaks));
        dispatch(saveEventInstanceSuccess(result));
    };
}

export function saveEventInstance(eventInstance: models.EventInstanceEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "saveEventInstance", api.save(eventInstance));

        if (result.groupId) {
            const groupApi = new EventInstanceGroupApi();
            const eiGroupResult = await loadAndTrack(dispatch, "loadDrinkDriveGroup", groupApi.getByEventInstanceId(result.id));
            dispatch(loadEIGroupSuccess(eiGroupResult));
        }

        if (result.errorMessageDuringUpdatePrice) {
            toast.error(result.errorMessageDuringUpdatePrice);
            return;
        }
        if (result.errorMessageDuringUpdateStartDay) {
            toast.error(result.errorMessageDuringUpdateStartDay);
            return;
        }
        if (result.errorMessageDuringUpdateScheme) {
            toast.error(result.errorMessageDuringUpdateScheme);
            return;
        }
        if (result.errorMessageDuringUpdate) {
            toast.error(result.errorMessageDuringUpdate);
            return;
        }

        if (result.warningMessageDuringUpdate) {
            toast.warning(result.warningMessageDuringUpdate);
        }

        dispatch(saveEventInstanceSuccess(result));

        if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export function loadEventInstances({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const trainerId = getState().router.params.trainerId;

        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAll({ ...options, trainerId }));
        dispatch(loadEventInstancesSuccess(result.eventInstances));
        dispatch(totalNumberOfEventInstancesSuccess(result.totalNumberOfEventInstances ?? 0));
    };
}

export function loadEventInstancesAndGroups({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const trainerId = getState().router.params.trainerId;
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAllEventInstancesAndGroups({ ...options,  trainerId }));
        dispatch(loadEventInstancesSuccess(result.eventInstances));
        dispatch(loadEventInstanceGroupsSuccess(result.eventInstanceGroups));
    };
}

export function loadEventInstancesForVenue() {
    return async (dispatch: Dispatch, getState: () => AppState) => {

        const state = getState();
        const venueId = state.router.params.venueId;
        const isCorporate = isCorporateSelector(state);

        const options: SearchOptions = {
            venueId: [venueId],
            toDate: state.router.query.toDate ? moment(state.router.query.toDate) : undefined,
            fromDate: state.router.query.fromDate ? moment(state.router.query.fromDate) : moment().startOf("day"),
            workflowTypes: isCorporate? CorporateWorkflowTypeArray : PoliceAndCourtWorkflowTypeArray,
            maxPageSize: 200,
        };

        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAll({ ...options }));
        dispatch(loadEventInstancesSuccess(result.eventInstances));
        dispatch(totalNumberOfEventInstancesSuccess(result.totalNumberOfEventInstances ?? 0));
    };
}

export function loadCorporateEventInstances({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAll({ ...options, workflowTypes: CorporateWorkflowTypeArray }));
        dispatch(loadEventInstancesSuccess(result.eventInstances));
        dispatch(totalNumberOfEventInstancesSuccess(result.totalNumberOfEventInstances ?? 0));
    };
}

export function loadConstructionEventInstances({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAll({ ...options, workflowTypes: ConstructionWorkflowTypeArray }));
        dispatch(loadEventInstancesSuccess(result.eventInstances));
        dispatch(totalNumberOfEventInstancesSuccess(result.totalNumberOfEventInstances ?? 0));
    };
}

export function loadEventInstancesForAvailableCoursesPage({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const trainerId = getState().router.params.trainerId;

        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "loadEventInstances",
            api.getAll({ ...options, trainerId }));

        let eventInstancesToReturn = result.eventInstances;
        if (result.eventInstances.length > 0) {
            const lastEventInstanceOnPage = result.eventInstances[result.eventInstances.length - 1];
            const extraOptions: SearchOptions = {
                ...options,
                availableForOtherTrainers: models.YesNoAnyEnum.Yes,
                fromDate: lastEventInstanceOnPage.startDate,
                toDate: lastEventInstanceOnPage.startDate,
                workflowTypes: AllWorkflowTypeArray
            };
            const extraResult = await loadAndTrack(dispatch, "loadEventInstances", api.getAll(extraOptions));
            const extraEventInstancesForPage = extraResult.eventInstances.filter(ei => !result.eventInstances.some(x => x.id === ei.id));
            eventInstancesToReturn = result.eventInstances.concat(extraEventInstancesForPage);
        }

        dispatch(loadEventInstancesSuccess(eventInstancesToReturn));
        dispatch(totalNumberOfEventInstancesSuccess(result.totalNumberOfEventInstances ?? 0));
    };
}

export function loadSingleAndMultiDayEventInstances({ options }: { options?: SearchOptions } = {}) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const trainerId = getState().router.params.trainerId;

        const api = new EventInstanceApi();

        const multiDayOptions= { ...options, includeAlreadyStartedGroups: true, includeAllDayParts: true };

        const [multiDayResult, singleDayResult] = await Promise.all([
            loadAndTrack(
                dispatch,
                "loadDrinkDriveEventInstances",
                api.getAllEventInstancesAndGroups({
                    ...multiDayOptions,
                    trainerId,
                    workflowTypes: multiDayOptions.workflowTypes
                })),
            loadAndTrack(
                dispatch,
                "loadEventInstances",
                api.getAll({
                    ...options,
                    trainerId,
                    workflowTypes: multiDayOptions.workflowTypes
                }))
        ]);
        const multiDayEventInstances = multiDayResult.eventInstances.filter(ei => !singleDayResult.eventInstances.some(x => x.id === ei.id));

        dispatch(loadEventInstanceGroupsSuccess(multiDayResult.eventInstanceGroups));
        dispatch(loadEventInstancesSuccess([...multiDayEventInstances, ...singleDayResult.eventInstances]));
    };
}

export const loadEventInstanceDetailSuccess = (eventInstance: models.EventInstanceDetailModel) => ({
    payload: eventInstance,
    type: actions.LOAD_EVENTINSTANCE_DETAIL_SUCCESS
});

export function loadEventInstanceDetail({ eventInstanceId }: { eventInstanceId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        if (eventInstanceId === "create") {
            return;
        }

        const eventInstance = getState().eventInstances.filter(c => c.id === eventInstanceId)[0];

        const action = eventInstance === undefined ? createEventInstanceSuccess : loadEventInstanceDetailSuccess;

        const api = new EventInstanceApi();
        try {
            const result = await loadAndTrack(dispatch, "loadEventInstanceDetail", api.detail(eventInstanceId));
            dispatch(action(result));

            if (result.groupId) {
                const groupApi = new EventInstanceGroupApi();
                const eiGroupResult = await loadAndTrack(dispatch, "loadEventInstanceGroup", groupApi.getByEventInstanceId(result.id));
                dispatch(loadEIGroupSuccess(eiGroupResult));
            }
        } catch (e) {
            RedirectTrainerToCoursesPageInTrainerApp(e?.response?.status, getState()?.app, getState()?.router?.pathname, dispatch);
        }
    };
}

export function loadDdrsEventInstanceDetail({ eventInstanceId }: { eventInstanceId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const eventInstance = getState().eventInstances.filter(c => c.id === eventInstanceId)[0];

        const action = eventInstance === undefined ? createEventInstanceSuccess : loadEventInstanceDetailSuccess;

        const api = new EventInstanceApi();
        try {
            const result = await loadAndTrack(dispatch, "loadEventInstanceDetail", api.detail(eventInstanceId));
            dispatch(action(result));

            if (result.workflowType === WorkflowTypeEnum.DDRS) {
                const groupApi = new EventInstanceGroupApi();
                const eiGroupResult = await loadAndTrack(dispatch, "loadDrinkDriveGroup", groupApi.getByEventInstanceId(result.id));
                dispatch(loadEIGroupSuccess(eiGroupResult));
            }
        } catch (e) {
            RedirectTrainerToCoursesPageInTrainerApp(e?.response?.status, getState()?.app, getState()?.router?.pathname, dispatch);
        }
    };
}

export function updateReasonForHidingEvent(eventInstanceId: string, reasonForHidingEvent: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result =
            await loadAndTrack(dispatch, actions.UPDATE_REASON_FOR_HIDING_EVENT, api.updateReasonForHidingEvent(eventInstanceId, reasonForHidingEvent));
        dispatch(updateReasonForHidingEventSuccess(result));
    };
}

export function bulkUpdateReasonForHidingEvents(eventInstanceIds: string[], reasonForHidingEvent: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            actions.BULK_UPDATE_REASON_FOR_HIDING_EVENT,
            api.bulkUpdateReasonForHidingEvent(eventInstanceIds, reasonForHidingEvent));

        showSummaryInformation(result);
        dispatch(bulkUpdateReasonForHidingEventSuccess(result));
    };
}

export function bulkMakeAvailableToTrainers(eventInstanceIds: string[], isAvailable: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            actions.BULK_MAKE_AVAILABLE_TO_TRAINERS,
            api.bulkMakeAvailableToTrainers(eventInstanceIds, isAvailable));

        showSummaryInformation(result, "makeAvailable");
        dispatch(bulkMakeAvailableToTrainersSuccess(result));
    };
}

export function bulkUpdateDorsOpenPlacesCount(eventInstanceIds: string[], newOpenPlacesCount: number, deliveryType: DeliveryTypeEnum) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            actions.BULK_UPDATE_DORS_OPEN_PLACES_COUNT,
            api.bulkUpdateDorsOpenPlaces(eventInstanceIds, newOpenPlacesCount, deliveryType));

        if (result.every(e => e.openPlacesCount === newOpenPlacesCount)) {
            toast.success("Thank you. All courses have been successfully updated.");
        }

        showSummaryInformation(result, "dorsPlacesUpdate");
        dispatch(bulkUpdateDorsOpenPlacesCountSuccess(result));
    };
}

export function cancelEventInstance(eventInstanceId: string, reasonForCancel?: string, otherReason?: string, cancellationCharge?: number,
    miscellaneousOrganisationFee?: number) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            actions.CANCEL_EVENT_INSTANCE,
            api.cancelEventInstance(eventInstanceId, reasonForCancel, otherReason, cancellationCharge, miscellaneousOrganisationFee)
        );

        if (result.errors) {
            toast.error(`Error Cancelling: ${result.errors}`);
        }

        if (result.cancelled) {
            dispatch(cancelEventInstanceSuccess(eventInstanceId, result.reasonForCancellation, result.financeResponse));
            toast.success("Course successfully cancelled");
        }
    };
}

export function cancelMultipleEventInstances(eventInstanceIds: string[], reasonForCancel?: string, otherReason?: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, actions.CANCEL_MULTIPLE_EVENT_INSTANCES,
            api.cancelMultipleEventInstances(eventInstanceIds, reasonForCancel, otherReason));

        showSummaryInformation(result, "canceling");

        dispatch(cancelMultipleEventInstanceSuccess(result));
    };
}

export function cancelTrainer(
    eventInstanceId: string,
    trainerId: string,
    trainerType: TrainerType,
    reasonForCancellation: string,
    otherReason: string,
    clearTrainerAvailability: ClearTrainerAvailabilityEnum) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "cancelTrainer",
            api.cancelTrainer(eventInstanceId, trainerId, trainerType, reasonForCancellation, otherReason, clearTrainerAvailability));

        if (result.errorMessageDuringUpdateTrainers) {
            toast.error(result.errorMessageDuringUpdateTrainers);
        } else {
            toast.info("Trainers cancelled");
        }

        await dispatch(saveEventInstanceSuccess(result));
    };
}

export function publishEventInstance(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "publishEventInstance", api.publishEventInstance(eventInstanceId));

        dispatch(publishEventInstanceSuccess(result));
    };
}

export function publishMultipleEventInstances(eventInstanceIds: string[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const currentUrl = `${location.pathname}${location.search}`;
        await loadAndTrack(dispatch, "publishMultipleEventInstance", api.publishMultipleEventInstances(eventInstanceIds, currentUrl));
    };
}

export function resubmitCourseToDors(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "resubmitCourseToDors", api.resubmitCourseToDors(eventInstanceId));
    };
}

export function bulkSendEmails(templateId: number, templateName: string, instanceIds: string[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "bulkSendEmails", api.sendBulkEmails(templateId, templateName, instanceIds));

        toast.success("Thank you. Your email has been sent.");
    };
}

export function bulkSendSms(template: string, templateName: string, instanceIds: string[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "bulkSendSms", api.sendBulkSms(template, templateName, instanceIds));
        toast.success("Thank you. We are processing request of sending text messages");
    };
}

export function setConfirmationStatus(eventInstanceId: string, status: models.ConfirmationStatus) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "setConfirmationStatus", api.setConfirmationStatus(eventInstanceId, status));

        dispatch(setConfirmationStatusSuccess(result));
    };
}

export function sendReminderEmail(eventInstanceId: string, venueId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "sendReminderEmail", api.sendReminderMail(eventInstanceId, venueId));
        toast.info("Reminder mail has been sent");
    };
}

export function acceptEventInstances(eventInstanceIds: string[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "acceptEventInstances", api.acceptEventInstances(eventInstanceIds));
        toast.info("Courses accepted");

        dispatch(acceptMultipleEventInstancesSuccess(eventInstanceIds));
    };
}

export function bulkRemoveTrainers(eventInstanceIds: string[], clearTrainerAvailability: ClearTrainerAvailabilityEnum, reason?: string, otherReason?: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const eventInstances = await loadAndTrack(
            dispatch,
            actions.BULK_REMOVE_TRAINERS,
            api.bulkRemoveTrainers(eventInstanceIds, clearTrainerAvailability, reason, otherReason));

        showSummaryInformation(eventInstances, "updating");

        dispatch(bulkRemoveTrainersSuccess(eventInstances));
    };
}

export function bulkRemoveTrainer(eventInstanceIds: string[], clearTrainerAvailability: ClearTrainerAvailabilityEnum, reason?: string, otherReason?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const trainerId = routeTrainerIdSelector(getState());

        const api = new EventInstanceApi();
        const eventInstances = await loadAndTrack(
            dispatch,
            actions.BULK_REMOVE_TRAINER,
            api.bulkRemoveTrainer(eventInstanceIds, trainerId, clearTrainerAvailability, reason, otherReason));

        showSummaryInformation(eventInstances, "updating");

        dispatch(bulkRemoveTrainersSuccess(eventInstances));
    };
}

export function saveNotes(eventInstanceId: string, notes: models.EINotes) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "saveNotes", api.saveNotes(eventInstanceId, notes));
        dispatch(saveNotesSuccess(eventInstanceId, notes));
    };
}

export function loadDigitalPlaningProgressForDors() {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "loadDigitalPlanningProgress", api.getDigitalPlanningProgressByEventTypeCategory(EventTypeCategory.Dors));
        dispatch(loadDigitalPlanningProgressSuccess(result));
    };
}

export function loadDigitalPlaningProgressForDdrs() {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "loadDigitalPlanningProgress", api.getDigitalPlanningProgressByEventTypeCategory(EventTypeCategory.Ddrs));
        dispatch(loadDigitalPlanningProgressSuccess(result));
    };
}

export function loadAllDigitalPlaningProgress() {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "loadDigitalPlanningProgress", api.getAllDigitalPlanningProgress());
        dispatch(loadDigitalPlanningProgressSuccess(result));
    };
}

export function saveDigitalPlaningProgress(digitalPlanningSession: models.DigitalPlanningSession) {
    return async (dispatch: Dispatch) => {
        digitalPlanningSession.plannedDigitalEvents = digitalPlanningSession?.plannedDigitalEvents?.filter(e => e.protected !== true);
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "saveDigitalPlanningProgress", api.saveDigitalPlanningProgress(digitalPlanningSession));

        if (result === null) {
            return;
        }

        dispatch(saveDigitalPlanningProgressSuccess(result));
        toast.success("Progress Saved");
    };
}

export function startDigitalPlanningCourseCreation(months: moment.Moment[], eventTypeCategory: EventTypeCategory, forceIds: number[]) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        for (const month of months) {
            const result =
                await loadAndTrack(dispatch, "startDigitalPlanningCourseCreation", api.startDigitalPlanningCourseCreation(month, eventTypeCategory, forceIds));
            dispatch(saveDigitalPlanningProgressSuccess(result));
        }
    };
}

export function deleteDigitalPlanningSession(month: moment.Moment, id: string, eventTypeCategory: EventTypeCategory) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "startDigitalPlanningCourseCreation", api.deleteDigitalPlanningProgress(month, eventTypeCategory));
        dispatch(deleteDigitalPlanningProgressSuccess(id));
    };
}

export function allocateTrainers(
    eventInstanceId: string,
    trainerType: TrainerType,
    trainers: TrainerAllocationModel[],
    monitoredTrainersIds: string[],
    showToast: boolean = true,
    unrecognisedFee: boolean = false) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "allocateTrainers", api.allocateTrainers(eventInstanceId, trainerType, trainers, monitoredTrainersIds));

        const { errorMessageDuringUpdateTrainers } = result;

        if (showToast) {
            const successMessage = "Allocated trainers successfully updated";
            if (errorMessageDuringUpdateTrainers?.length > 0) {
                toast.warning(`${successMessage}\n\n${errorMessageDuringUpdateTrainers}`);
            } else {
                toast.success(successMessage);
            }

            if (unrecognisedFee)
            {
                toast.warning("Trainer's course fee does not match system known fees");
            }
        }
        dispatch(saveEventInstanceSuccess(result));
    };
}

export function syncTrainersWithDors(
    eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result =   await loadAndTrack(dispatch, "syncTrainersWithDors", api.syncTrainersWithDors(eventInstanceId));
        if (result.data.success) {
            toast.success("Received successful course trainer update response from DORS");
        }
        else {
            toast.warning(`Event instance trainer(s) not synced, DORS returned the following error message(s):${result.data.errorMessages}`);
        }

        const detailResult = await loadAndTrack(dispatch, "loadEventInstanceDetail", api.detail(eventInstanceId));

        toastMissingNdorsLicenceTrainers(detailResult, false);
        toastMissingNdorsLicenceTrainers(detailResult, true);

        dispatch(saveEventInstanceSuccess(detailResult));
    };
}

export function setTrainerCarTypeForEvent(
    eventInstanceId: string,
    trainerId: string,
    carType: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "setTrainerCarTypeForEvent", api.setTrainerCarTypeForEvent(eventInstanceId, trainerId, carType));
        dispatch(saveEventInstanceSuccess(result));
    };
}

export function addZoomAccount(
    eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "addZoomAccount", api.addZoomAccount(eventInstanceId));
        dispatch(saveEventInstanceSuccess(result));

        if (result.digitalCourseAccount) {
            toast.success("Zoom account added to Event Instance");
        } else {
            toast.warning("No free zoom accounts available for this Event Instance");
        }
    };
}

export function updateCertificateCommsDestination(
    eventInstanceId: string,
    updateCertificateDestinationModel: UpdateCertificateCommsDestinationModel) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateCertificicateCommsDestination",
            api.updateCertificateCommsDestination(eventInstanceId, updateCertificateDestinationModel));
        toast.info("Certificate communication destinations updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function updateClosedCourseCommsDestinations(
    eventInstanceId: string,
    updateClosedCourseCommsDestinationsModel: UpdateClosedCourseCommsDestinationsModel) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateClosedCourseCommsDestinations",
            api.updateClosedCourseCommsDestinations(eventInstanceId, updateClosedCourseCommsDestinationsModel));
        toast.info("Closed course communication destinations updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function sendClosedCourseReminderComms(
    eventInstanceId: string,
    sendClosedCourseReminderCommsModel: SendClosedCourseReminderCommsModel) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(
            dispatch,
            "sendClosedCourseReminderComms",
            api.sendClosedCourseReminderComms(eventInstanceId, sendClosedCourseReminderCommsModel));
        toast.info("Closed course reminder communications sent");
    };
}

export function sendClosedCourseCreationComms(
    eventInstanceId: string,
    sendClosedCourseCreationCommsModel: SendClosedCourseCreationCommsModel) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(
            dispatch,
            "sendClosedCourseCreationComms",
            api.sendClosedCourseCreationComms(eventInstanceId, sendClosedCourseCreationCommsModel));
        toast.info("Closed course creation communications sent");
    };
}

export function processCertificates(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "processCertificates",
            api.processCertificates(eventInstanceId));
        toast.info("Course closure documents have been created and sent");
        dispatch(saveEventInstanceSuccess(result));

        const attendeeApi = new AttendeeApi(eventInstanceId);
        const attendeeResult = await loadAndTrack(dispatch, LOAD_ATTENDEES, attendeeApi.getAttendeesForEventInstance());
        dispatch(loadAttendeesFromSuccess(attendeeResult.attendeesFrom));
        dispatch(loadAttendeesSuccess(attendeeResult.attendees));
    };
}

export function updateEventInstanceTrainerFee(
    eventInstanceId: string,
    eventInstanceTrainerId: string,
    trainerType: TrainerType,
    fee: number) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateEventInstanceTrainerFee",
            api.updateEventInstanceTrainerFee(eventInstanceId, eventInstanceTrainerId, trainerType, fee));
        toast.info("Trainer fee updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function updateEventInstanceTrainerSundries(
    eventInstanceId: string,
    eventInstanceTrainerId: string,
    trainerType: TrainerType,
    sundries: number) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateEventInstanceTrainerSundries",
            api.updateEventInstanceTrainerSundries(eventInstanceId, eventInstanceTrainerId, trainerType, sundries));
        toast.info("Trainer sundries updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function updateEventInstanceTrainerExternalAssessmentDue(
    eventInstanceId: string,
    eventInstanceTrainerId: string,
    trainerType: TrainerType,
    externalAssessmentDue: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateEventInstanceTrainerExternalAssessmentDue",
            api.updateEventInstanceTrainerExternalAssessmentDue(eventInstanceId, eventInstanceTrainerId, trainerType, externalAssessmentDue));
        toast.info("Trainer external assessment due updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function updateEventInstanceTrainerFeeNote(
    eventInstanceId: string,
    eventInstanceTrainerId: string,
    trainerType: TrainerType,
    feeNote: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(
            dispatch,
            "updateEventInstanceTrainerFeeNote",
            api.updateEventInstanceTrainerFeeNote(eventInstanceId, eventInstanceTrainerId, trainerType, feeNote));
        toast.info("Trainer Fee Note updated");

        dispatch(saveEventInstanceSuccess(result));
    };
}

export function setEventInstanceAvailabilityForOtherTrainers(
    eventInstanceId: string,
    trainersToAllocateNumber: number,
    role: models.TrainerAvailabilityRoleTypeEnum,
    basePath: string,
    deliveryType: DeliveryTypeEnum) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "setEventInstanceAvailabilityForOtherTrainers", api.setEventInstanceAvailabilityForOtherTrainers(
            eventInstanceId, trainersToAllocateNumber, role));
        if (result.errorMessage) {
            toast.error(result.errorMessage);
            dispatch(push(`${basePath}/${eventInstanceId}/trainers`));
            return;
        }

        if (deliveryType === DeliveryTypeEnum.Digital) {
            toast.success(`Course made ${trainersToAllocateNumber === 0 ? "un" : ""}available`);
        }
        dispatch(setEventInstanceAvailabilityForOtherTrainersSuccess(
            eventInstanceId,
            result.eventInstance.adminAvailableRoles));
    };
}

export function setEventInstanceAvailabilityForOtherTrainersByTrainer(
    eventInstanceId: string,
    trainerId: string,
    availableForOtherTrainers: boolean,
    moduleType: ModuleTypeEnum,
    handbackReason: models.HandbackReasonEnum,
    handbackReasonOther: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "setEventInstanceAvailabilityForOtherTrainersByTrainer",
            api.setEventInstanceAvailabilityForOtherTrainersByTrainer(
                eventInstanceId, availableForOtherTrainers, moduleType, handbackReason, handbackReasonOther));

        dispatch(setEventInstanceAvailabilityForOtherTrainersByTrainerSuccess(result));
    };
}

export function setOtherRoleForTrainer(
    eventInstanceId: string,
    trainerId: string,
    otherRole: OtherTrainerTypeCategoryEnum) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "setOtherRoleForTrainer", api.setOtherRoleForTrainer(eventInstanceId, trainerId, otherRole));
        dispatch(saveEventInstanceSuccess(result));
        if (result) {
            toast.info("Trainer Other Role Category updated");
        }
    };
}

export function saveHealthAndSafety(eventInstanceId: string, healthAndSafety: models.EventInstanceHealthAndSafetyModel, ) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "processHealthAndSafety", api.saveHealthAndSafety(eventInstanceId, healthAndSafety));

        if (healthAndSafety?.submittedOn) {
            toast.info("Health and Safety form has been successfully submitted");
        } else {
            toast.info("Health and Safety form has been successfully saved");
        }

        dispatch(saveHealthAndSafetySuccess(eventInstanceId, healthAndSafety));
    };
}

export function saveFinanceDetails(eventInstanceId: string, financeDetails: models.FinanceDetails, ) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "saveFinanceDetails", api.saveFinanceDetails(eventInstanceId, financeDetails));

        if (result.errorMessageDuringUpdate) {
            return Promise.resolve(false);
        }

        toast.info("Finance details have been successfully saved");
        dispatch(saveFinanceDetailsSuccess(result));
        return Promise.resolve(true);
    };
}

export function saveEiFavouriteStatus(favourite: FavouriteCreateEditModel) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "saveEiFavouriteStatus", new UserFavouritesApi().updateFavourites(favourite));
        dispatch(updateEventInstanceFavouriteStatus(favourite.targetId, favourite.markAsFavourite));
    };
}

export function loadEventInstanceFinanceData(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "getEventInstanceFinanceData", api.getEventInstanceFinanceData(eventInstanceId));
        dispatch(loadEventInstanceFinanceDataSuccess(result));
    };
}

export function loadEventInstanceEnquiriesData(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "getEventInstanceEnquiriesData", api.getEventInstanceEnquiriesData(eventInstanceId));
        dispatch(loadEventInstanceEnquiriesDataSuccess(result));
    };
}

export function forceRegisterProcessing(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "forceRegisterProcessing", api.forceRegisterProcesing(eventInstanceId));
        const result = await loadAndTrack(dispatch, "loadEventInstanceDetail", api.detail(eventInstanceId));
        dispatch(loadEventInstanceDetailSuccess(result));
    };
}

export function updateEnquiryStatus(updateModel: EnquiryStatusUpdateModel) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        await loadAndTrack(dispatch, "updateEnquiryStatus", api.updateEnquiryStatus(updateModel));
        dispatch(updateEnquiryStatusSuccess(updateModel));
    };
}

export function convertToBookable(eventInstanceId: string, venueId: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventInstanceApi();
        const result = await loadAndTrack(dispatch, "convertToBookable", api.convertToBookable(eventInstanceId, venueId));
        dispatch(convertToBookableSuccess(result));
    };
}

function showSummaryInformation(
    eventInstances: EventInstance[],
    action?: "updating" | "publishing" | "dorsPlacesUpdate" | "makeAvailable" | "canceling"): void {

    if (action === "dorsPlacesUpdate") {
        if (eventInstances.length === 0) {
            toast.warning("Some of the selected courses were not of the correct delivery type");
        } else if (eventInstances.some(x => (!!x.errorMessageDuringUpdateDorsOpenPlacesCount))) {
            toast.warning("Thank you. At least 1 course did not update successfully. Please now review the warning symbols.");
        }
        return;
    }

    if (action === "makeAvailable") {
        if (eventInstances.some(x => x.skippedForBulkMakeAvailableToTrainers)) {
            toast.warning("Thank you. At least 1 course did not update successfully.");
        } else {
            toast.success("Thank you. All courses have been successfully updated");
        }
        return;
    }

    if (action === "canceling") {
        if (eventInstances.some(ei => !!ei.errorMessageDuringPublish)) {
            toast.warning("Thank you. At least 1 course did not update successfully. Please now review the warning symbols.");
        } else {
            toast.success("Thank you. All courses have been successfully updated");
        }
        return;
    }

    if (eventInstances.some(x => (!!x.errorMessageDuringPublish && action === "publishing")
    || (!!x.errorMessageDuringUpdateTrainers && action === "updating"))) {
        toast.warning("Thank you. At least 1 course did not update successfully. Please now review the warning symbols.");
    } else {
        toast.success("Thank you. All courses have been successfully updated");
    }
}
