import { SchemeAbbreviation, SchemeEnum } from "@common/crud/eventType/model";
import { PreCourseSection } from "@common/crud/eventInstance/components/monitoring/questions/PreCourseSection";
import { TrainerAttributesSection } from "@common/crud/eventInstance/components/monitoring/questions/TrainerAttributesSection";
import { IntroductionSection } from "@common/crud/eventInstance/components/monitoring/questions/IntroductionSection";
import { OverallSummarySection } from "@common/crud/eventInstance/components/monitoring/questions/OverallSummarySection";
import { AvoidableThingsSection } from "@common/crud/eventInstance/components/monitoring/questions/AvoidableThingsSection";
import { KnowingTheRulesSection } from "@common/crud/eventInstance/components/monitoring/questions/KnowingTheRulesSection";
import { StickingToTheRulesSection } from "@common/crud/eventInstance/components/monitoring/questions/StickingToTheRulesSection";
import { StayingInControlSection } from "@common/crud/eventInstance/components/monitoring/questions/StayingInControlSection";
import { DrivingWithCareAndAttentionSection } from "@common/crud/eventInstance/components/monitoring/questions/DrivingWithCareAndAttentionSection";
import { CriteriaEnum, Questions } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SCDQuestions: Questions = {
    schemeId: SchemeAbbreviation[SchemeEnum.SCD],
    sections: [
        PreCourseSection,
        TrainerAttributesSection,
        IntroductionSection,
        {
            id: "4",
            title: "Module 1",
            seqNum: 3,
            subSections: [
                {
                    id: "4.1",
                    text: "How avoidable are collisions 10 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1",  text: "Encouraged clients to consider how much of our behaviour is avoidable" },
                        { id: "2",  text: "Describe scenario clearly." },
                        { id: "3",  text: "How much blame for each person? Record scores." },
                        { id: "4",  text: "Discuss what each driver could have done differently." },
                        { id: "5",  text: "Who could have avoided being involved in the collision? Record scores." },
                        { id: "6",  text: "Why didn’t they do these things?" },
                        { id: "7",  text: "Hold up and show to clients the blame and avoid scores. Draw out key messages." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "8", text: "", typeEnum: CriteriaEnum.text }]
        },
        AvoidableThingsSection,
        KnowingTheRulesSection,
        StickingToTheRulesSection,
        StayingInControlSection,
        DrivingWithCareAndAttentionSection,
        {
            id: "10",
            title: "Module 7",
            seqNum: 9,
            subSections: [{
                id: "10.1",
                text: "Action planning 20 min",
                seqNum: 1,
                criteria: [
                    { id: "1", text: "Have we answered your questions from the beginning?" },
                    { id: "2", text: "Your choice" },
                    { id: "3", text: "Non-driving action plan demonstrated" },
                    { id: "4", text: "Help clients create a plan to make lasting changes to their driving behaviour" },
                    { id: "5", text: "Q1, write down hold up to screen" },
                    { id: "6", text: "Client to client interaction during action plan." },
                    { id: "7", text: "** Assessor to record the trainers action plan." },
                    { id: "8", text: "Advise clients to start implementing their plan as soon as they next drive." },
                    { id: "9", text: "State providers advice about booking coaching session, promote as best solution" },
                    { id: "10", text: "Advise clients to keep written notes to help them on the practical coaching session." }

                ]
            }],
            summaryCriterium: [{ id: "11", text: "", typeEnum: CriteriaEnum.text }]
        },
        OverallSummarySection
    ]
};
