import * as React from "react";

import { GeocodingSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const Geocoding: ConfigurationAreaComponent<GeocodingSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="GeocodingConfig"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "GoogleAPIKey", label: "Google Api Key" },
                { name: "Url", label: "Google Api URL" }
            ]}
        />
    );
};
