import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import * as actions from "./actiontypes";
import * as models from "./model";
import { EventTypeApi } from "./eventTypeApi";
import { eventTypesByRouteSelector } from "./selectors";

export type EventTypeAction =
    ({ type: actions.LOAD_EVENTTYPES_SUCCESS } & Payload<models.EventTypeListModel[]>)
    | ({ type: actions.CREATE_EVENTTYPE_SUCCESS } & Payload<models.EventTypeDetailModel>)
    | ({ type: actions.LOAD_EVENTTYPE_DETAIL_SUCCESS } & Payload<models.EventTypeDetailModel>)
    | ({ type: actions.SAVE_EVENTTYPE_SUCCESS } & Payload<models.EventTypeDetailModel>);

export const loadEventTypesSuccess = (payload: models.EventTypeListModel[]): EventTypeAction => ({
    payload,
    type: actions.LOAD_EVENTTYPES_SUCCESS
});

export const createEventTypeSuccess = (payload: models.EventTypeDetailModel): EventTypeAction => ({
    payload,
    type: actions.CREATE_EVENTTYPE_SUCCESS
});

export const saveEventTypeSuccess = (payload: models.EventTypeDetailModel): EventTypeAction => ({
    payload,
    type: actions.SAVE_EVENTTYPE_SUCCESS
});

export function createEventType(eventType: models.EventTypeCreateEditModel, path: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventTypeApi();
        const result = await loadAndTrack(dispatch, actions.CREATE_EVENTTYPE, api.create(eventType));
        dispatch(createEventTypeSuccess(result));
        dispatch(push(`${path}/${result.id}`));
    };
}

export function saveEventType(eventType: models.EventTypeCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new EventTypeApi();
        const result = await loadAndTrack(dispatch, "saveEventType", api.save(eventType));

        dispatch(saveEventTypeSuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}`));
        }
    };
}

export function loadEventTypes() {
    return async (dispatch: Dispatch) => {
        const api = new EventTypeApi();
        const result = await loadAndTrack(dispatch, "loadEventTypes", api.getAll());
        dispatch(loadEventTypesSuccess(result));
    };
}

export function loadEventTypeForRouteWorkflowTypes(options: { name?: string; internalId?: string;
    shownInBookingApp?: boolean; workflowType?: models.WorkflowTypeEnum; }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const workflowTypes = eventTypesByRouteSelector(getState()).filter(wt =>
            wt === options.workflowType || !options.workflowType || options.workflowType === models.WorkflowTypeEnum.Any);
        const productCategoriesString = getState().router.query.productCategories;
        const productCategories = productCategoriesString
            ? productCategoriesString.split(",").map(Number)
            : [];

        const api = new EventTypeApi();
        const result = await loadAndTrack(
            dispatch, "loadEventTypes",
            api.getAllByWorkflowTypes({ ...options, workflowTypes, productCategories })
        );
        dispatch(loadEventTypesSuccess(result));
    };
}

export function loadDorsDigitalEventTypes() {
    return async (dispatch: Dispatch) => {
        const api = new EventTypeApi();
        const result = await loadAndTrack(dispatch, "loadEventTypes", api.getDorsDigitalEvents());
        dispatch(loadEventTypesSuccess(result));
    };
}

export function loadDdrsDigitalEventTypes() {
    return async (dispatch: Dispatch) => {
        const api = new EventTypeApi();
        const result = await loadAndTrack(dispatch, "loadEventTypes", api.getDdrsDigitalEvents());
        dispatch(loadEventTypesSuccess(result));
    };
}

export const loadEventTypeDetailSuccess = (eventType: models.EventTypeDetailModel) => ({
    payload: eventType,
    type: actions.LOAD_EVENTTYPE_DETAIL_SUCCESS
});

export function loadEventTypeDetail({ eventTypeId }: { eventTypeId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const eventType = getState().eventTypes.filter(c => c.id === eventTypeId)[0];

        if (eventType === undefined
            || eventType.detailUpdated === undefined
            || eventType.detailUpdated.isBefore(eventType.listUpdated)) {

            const action = eventType === undefined ? createEventTypeSuccess : loadEventTypeDetailSuccess;

            const api = new EventTypeApi();
            const result = await loadAndTrack(dispatch, "loadEventTypeDetail", api.detail(eventTypeId));
            dispatch(action(result));
        }
    };
}
