import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";
import moment, { Moment } from "moment";
import { AttendeeCarType, EventTypeCategory } from "@common/crud/attendee/model";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { ManualPaymentModel } from "@booking/global/models";

export interface Booking {
    id: string;
    attendanceId: number;
    schemeId?: number;
    forceId?: number;
    forename: string;
    surname: string;
    buildingNumber?: string;
    buildingName?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    townCity?: string;
    postcode?: string;
    telephone?: string;
    email?: string;
    driverNumber?: string;
    seatReservationId?: string;
    originalEventInstanceId?: string;
    originalSeatReservationId?: string;
    seatBooked: boolean;
    reservedEventInstanceId?: string;
    expiryDate: Moment;
    eventTypeName: string;
    eventTypeNameCy?: string;
    waivedRebookingFee?: boolean;
    isEmailAmendable?: boolean;
    isTelephoneAmendable?: boolean;
    dorsAttendanceStatus: DorsAttendanceStatusesEnum;
    isTechnicalAssistanceRequired?: boolean;
    hasPendingReservation?: boolean;
    bookingIsDuplicate?: boolean;
    isCancelled?: boolean;
    nextCancelledEventInstanceId?: string;
    isRebooking?: boolean;
    anonymized: boolean;
    noRebookingAllowed: boolean;
    flexiPayPurchased?: boolean;
    flexiPayExpiryDate?: moment.Moment;
    flexiPayUsed?: boolean;
    canUseFlexiPay?: boolean;
    eventTypeId?: string;
    carType?: string;
    specialCarDetails?: string;
    eventInstanceBookings: EventInstanceBooking[];
    eventTypeCategory: EventTypeCategory;
}

export interface EventInstanceBooking {
    eventInstanceId: string;
    seatId: string;
    startDate: Moment;
    cancelledStatus: boolean;
}

export interface MissingBookingProps {
    telephone?: string;
    specialRequirements?: SpecialRequirements;
    email?: string;
    carType?: AttendeeCarType;
    tAndCsConfirmed?: boolean;
    specialCarDetails?: string;
    includeFullyBookedCourses?: boolean;
    updateContactInformationOnly?: boolean;
    flexiPayPurchased?: boolean;
    flexiPayFee?: number;
}

export enum BookingCancellationReason {
    None = 0,
    Other = 1,
    RebookingMadeByCa = 16,
    RebookingMadeByAttendee = 17,
}

export interface BookingDetailsExtraInfoModel {
    nextPage?: string;
    isAutoPaymentProcess?: boolean;
    genesysToken?: string;
    manualPaymentModel?: ManualPaymentModel;
}

export type BookingDetailFormikModel = MissingBookingProps & BookingDetailsExtraInfoModel;

export interface AssistanceRequired {
    isTechnicalAssistanceRequired?: boolean;
}

export const AttendeeCarTypeOptions: { value: AttendeeCarType; text: string }[] = [
    { value: "Manual", text: "Manual" },
    { value: "Automatic", text: "Automatic" },
    { value: "Special", text: "I need to use my own specially adapted car" }
];

export function bookingAddress(booking: Booking): string {
    if (!booking.buildingNumber && !booking.buildingName && !booking.address1
        && !booking.address2 && !booking.address1 && !booking.townCity) {
        return "";
    } else {
        const houseNameAndNum = [booking.buildingName, booking.buildingNumber];
        let houseNameAndNumberFormatted = houseNameAndNum.filter(i => i).join(", ");
        if (!booking.buildingNumber && booking.buildingName) {
            houseNameAndNumberFormatted = booking.buildingName + ",";
        }
        const nameNumberAndAddress1 = `${houseNameAndNumberFormatted} ${booking.address1 || ""}`;
        let addressParts = [nameNumberAndAddress1,
            booking.address2 || "",
            booking.address3 || "",
            booking.townCity || ""];
        addressParts = addressParts.map(i => i.trim());
        return addressParts.filter(i => i !== "").join(", ");
    }
}
