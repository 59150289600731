import * as React from "react";
import { toast } from "@common/toasts";
import { Modal } from "@common/components";
import { Input } from "@neworbit/simpleui-input";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import { TrainerDetailModel } from "../../model";
import { useDispatch } from "react-redux";
import { updateTrainerNdorsLicenceNumber } from "@common/crud/trainer/actions";
import { TrainerApi } from "./../../trainerApi";

interface UpdateNdorsLicenceNumberModalProps {
    trainer: TrainerDetailModel;
}

const UpdateNdorsLicenceNumberModal: React.FC<UpdateNdorsLicenceNumberModalProps> = ({ trainer }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [ndorsLicenceNumber, setNdorsLicenceNumber] = React.useState<string>();
    const [ndorsLicenceNumberValid, setNdorsLicenceNumberValid] = React.useState<boolean>(false);
    const [verifying, setVerifying] = React.useState(false);
    const [verifiedId, setVerifiedId] = React.useState<string>();
    const dispatch = useDispatch();

    React.useEffect(() => {
        setNdorsLicenceNumber(trainer.ndorsLicenceNumber);
    }, [trainer.ndorsLicenceNumber]);

    function toggleModal() {
        setOpen(!open);
        setNdorsLicenceNumber(trainer.ndorsLicenceNumber);
    }

    function onSave() {
        dispatch(updateTrainerNdorsLicenceNumber(trainer.id, {
            ndorsLicenceNumber
        }));
        setOpen(false);
    }

    async function verifyNdorsLicenceNumber()
    {
        try {
            setNdorsLicenceNumberValid(false);
            setVerifying(true);
            const api = new TrainerApi();
            const valid = await api.checkTrainerNdorsLicenceValid(ndorsLicenceNumber, trainer.surname);
            if (valid)
            {
                setNdorsLicenceNumberValid(true);
                setVerifiedId(ndorsLicenceNumber);
                setVerifying(false);
                toast.success("NDORS Licence Number valid. Please click save.");
            }
            else
            {
                setNdorsLicenceNumberValid(false);
                setVerifying(false);
                toast.error("NDORS Licence Number invalid. Please try again.");
            }
        } catch {
            setNdorsLicenceNumberValid(false);
            setVerifying(false);
            toast.error("An error occurred. Please try again.");
        }
    }

    const isNotModified = trainer.ndorsLicenceNumber === ndorsLicenceNumber;
    const ndorsLicenceNumberVerified = verifiedId === ndorsLicenceNumber;
    return (
        <>
            <Grid.Row>
                <Grid.Column width={3}>
                    <Button className="link-button" onClick={toggleModal}>Update NDORS Licence Number</Button>
                </Grid.Column>
            </Grid.Row>

            <Modal open={open}>
                <Modal.Header>
                    Update NDORS Licence Number
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Input.Text
                            label="Type New NDORS Licence Number"
                            value={ndorsLicenceNumber}
                            onChange={setNdorsLicenceNumber}
                        />
                    </Form>
                    <Button onClick={verifyNdorsLicenceNumber} loading={verifying} disabled={verifying}>Verify</Button>
                    {trainer.ndorsLicenceNumber !== ndorsLicenceNumber &&
                        <Message info>
                            NDORS Licence Number modified, old NDORS Licence Number: {trainer.ndorsLicenceNumber}
                        </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={toggleModal} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        disabled={isNotModified || !ndorsLicenceNumberValid || !ndorsLicenceNumberVerified}
                        onClick={onSave}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { UpdateNdorsLicenceNumberModal };
