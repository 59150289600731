import * as React from "react";
import { StartPaymentProcessRequest } from "@common/payments/model";

export function useStickyState(startPaymentProcess: StartPaymentProcessRequest) {
    const key = "paymentTempKey";
    const [value] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : undefined;
    });

    React.useEffect(() => {
        if (startPaymentProcess?.id) {
            window.localStorage.setItem(key, JSON.stringify(startPaymentProcess));
        }

    }, [startPaymentProcess]);
    return value;
}
