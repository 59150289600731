import * as React from "react";
import { Divider } from "semantic-ui-react";
import { CacheSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const Caching: ConfigurationAreaComponent<CacheSettings> = ({ settings, hierarchy }) => (
    <>
        <h1>Cache Settings</h1>
        <Divider />
        <SettingsGrid
            area="CacheSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "AppSettingsCacheEnabled", label: "App Settings Cache Enabled", inputType: InputType.Toggle },
                { name: "CacheForSeconds", label: "Cache For Seconds", inputType: InputType.Number },
            ]}
        />
    </>
);
