import { Seat } from "@booking/seat";
import axios from "axios";
import moment from "moment";

import { Booking, BookingCancellationReason, MissingBookingProps } from "./models";

export class BookingApi {
    private apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/booking/${bookingId}` : "/api/booking";
    }

    public async getBooking(checkForDuplicate?: boolean, startNewSession?: boolean): Promise<Booking> {
        const duplicateCheck = checkForDuplicate ?? false;
        const newSession = startNewSession ?? false;
        const response = await axios.get(`${this.apiUrl}?checkForDuplicate=${duplicateCheck}&startNewSession=${newSession}`);
        const data = response.data as Booking;
        return this.parseModel(data);
    }

    public async updateMissingFields(payload: MissingBookingProps): Promise<{ booking: Booking; seats: Seat[]; isExpired: boolean }> {
        const response = await axios.put(this.apiUrl, payload);
        const data = response.data as { booking: Booking; seats: Seat[]; isExpired: boolean };
        const { seats, isExpired, booking } = data;

        return { booking: this.parseModel(booking), seats, isExpired };
    }

    public async updateContactInfo(email: string, telephone: string): Promise<void> {
        await axios.put(`${this.apiUrl}/updateContactDetails`, { email, telephone });
    }

    public async startRebookingProcess(rebookReason: BookingCancellationReason): Promise<Booking> {
        const url = `${this.apiUrl}/rebook`;
        const response = await axios.put(url, { rebookReason });
        const data = response.data as Booking;
        return this.parseModel(data);
    }

    public async cancelRebookingProcess(): Promise<Booking> {
        const url = `${this.apiUrl}/cancelRebook`;
        const response = await axios.put(url);
        const data = response.data as Booking;
        return this.parseModel(data);
    }

    public async resendConfirmationMail() {
        const response = await axios.post(`${this.apiUrl}/resendconfirmationmail`);
        return response;
    }

    public async getAttendeeIdForBooking(eventInstanceId: string): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/${eventInstanceId}`);
        return response.data as string;
    }

    public async getAttendeeId(bookingId: string, eventInstanceId: string, includeCancelled?: boolean) {
        const result = await axios.get<string>(`${this.apiUrl}/${eventInstanceId}?includeCancelled=${includeCancelled ?? false}`);
        return result.data;
    }

    public parseModel(model: Booking): Booking {
        return model && {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
            eventTypeName: model.eventTypeName || "Unknown",
            flexiPayExpiryDate: model.flexiPayExpiryDate && moment(model.flexiPayExpiryDate)
        };
    }

    public async logprintbookingconfirmation() {
        const response = await axios.post(`${this.apiUrl}/logprintbookingconfirmation`);
        return response;
    }
}
