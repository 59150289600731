import * as React from "react";
import { Fragment, Location } from "redux-little-router";
import { useSelector } from "react-redux";
import { AppCommonState } from "@common/appCommonState";

import { Base as AttendeeRoutes } from "../../landing";
import { ApplicationState } from "../../applicationState";

const noBookingId = (location: Location) => location.params.bookingId === undefined;

const Base: React.FC = () => {
    const currentUser = useSelector((state: AppCommonState) => state.currentUser);
    const booking = useSelector((state: ApplicationState) => state.booking);
    if (currentUser.loaded !== false && !currentUser.authenticated) {
        const redirectUrl = encodeURIComponent(location.pathname + location.search);
        const signInUrl = redirectUrl ? `/account/signin?redirectUrl=${redirectUrl}` : "/account/signin";
        location.href = signInUrl;
    }

    return (
        <div>
            <Fragment forRoute="/" withConditions={noBookingId}>
                <div>Please use the admin site to search for bookings.</div>
            </Fragment>
            <Fragment forRoute="/:bookingId">
                {
                    booking
                        ? <>
                            <AttendeeRoutes />
                        </>
                        : <h1>This Booking has now expired</h1>
                }
            </Fragment>
        </div>

    );
};

export { Base };
