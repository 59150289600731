import * as React from "react";
import { InvoiceDetailModel } from "../model";
import { Grid } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

interface InvoiceDetailHeaderProps {
    invoice: InvoiceDetailModel;
}

export const InvoiceDetailHeader: React.FC<InvoiceDetailHeaderProps> = ({ invoice }) => {

    return (
        <Grid container>

            <Grid.Row>
                <Grid.Column width={8} textAlign={"left"}>
                    <Grid.Row>TTC 2000 Limited</Grid.Row>
                    <Grid.Row>Hadley Park East</Grid.Row>
                    <Grid.Row>Telford</Grid.Row>
                    <Grid.Row>Shropshire</Grid.Row>
                    <Grid.Row>TF1 6QJ</Grid.Row>
                </Grid.Column>
                { invoice.vatPercentage &&
                    <Grid.Column width={8} textAlign={"right"}>
                        <Grid.Row>
                            <h5>{invoice.companyName}</h5>
                        </Grid.Row>
                        <Grid.Row>
                            <h5>{invoice.companyAddress?.addressLine1}</h5>
                        </Grid.Row>
                        <Grid.Row>
                            <h5>{invoice.companyAddress?.addressLine2}</h5>
                        </Grid.Row>
                        <Grid.Row>
                            <h5>{invoice.companyAddress?.addressLine3}</h5>
                        </Grid.Row>
                        <Grid.Row>
                            <h5>{invoice.companyAddress?.city}</h5>
                        </Grid.Row>
                        <Grid.Row>
                            <h5>{invoice.companyAddress?.postalCode}</h5>
                        </Grid.Row>
                    </Grid.Column>
                }
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16} textAlign={"right"}>
                    <Grid.Row>
                        <h5>Trainer: {invoice.trainerName}</h5>
                    </Grid.Row>
                    <Grid.Row>
                        <h5>Invoice Number: {invoice.invoiceNumber}</h5>
                    </Grid.Row>
                    <Grid.Row>
                        <h5>Invoice Date: {invoice.invoiceDate.format(DateFormat.Short)}</h5>
                    </Grid.Row>
                    { invoice.vatPercentage &&
                        <Grid.Row>
                            <h5>VAT Number: {invoice.vatNumber}</h5>
                        </Grid.Row>
                    }
                    <Grid.Row>
                        <h5>Description of service: Driver Awareness Training</h5>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
