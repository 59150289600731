import * as React from "react";
import { Modal } from "@common/components";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { Formik } from "formik";
import { AttendeeCarTypeOptions } from "src/Booking.App/src/bookings/models";
import { SemanticDropdown } from "@common/crud/formik/SemanticDropdown";
import { SemanticForm } from "@common/crud/formik/SemanticForm";
import { AttendeeDetailModel } from "../../model";
import { updateAttendeeCarDetails } from "../../actions";
import { SpecialCarDetailsField } from "./SpecialCarDetailsField";

export interface CarDetailsModalProps {
    attendee: AttendeeDetailModel;
}

export const AttendeeCarDetailsModal: React.FC<CarDetailsModalProps> = ({ attendee }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const openModal = React.useCallback(() => setOpen(true), []);
    const closeModal = React.useCallback(() => setOpen(false), []);
    const dispatch = useDispatch();

    const submit = React.useCallback(values => {
        dispatch(updateAttendeeCarDetails(attendee.id, attendee.eventInstanceId, values));
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <a className="show-button" onClick={openModal}>Update Car details</a>
            <Formik
                initialValues={{
                    carType: attendee.carType,
                    specialCarDetails: attendee.specialCarDetails || ""
                }}
                onSubmit={submit}
            >
                <Modal open={open}>
                    <Modal.Header>
                        Update Car details
                    </Modal.Header>
                    <Modal.Content>
                        <SemanticForm id="edit-car-type">
                            <SemanticDropdown
                                name="carType"
                                options={AttendeeCarTypeOptions}
                                label="Car Type"
                                placeholder="Car Type"
                            />
                            <SpecialCarDetailsField />
                        </SemanticForm>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeModal} className="cancel-action">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            form="edit-car-type"
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content="Yes"
                        />
                    </Modal.Actions>
                </Modal>
            </Formik>
        </>
    );
};
