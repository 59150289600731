import * as React from "react";
import { Button, Divider, Tab, Segment, TabProps } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { EventTypeAdmin } from "@common/auth/model";
import { CoreDetailProps, DetailProps, DispatchProps } from "../Detail";
import { DetailsTab } from "./DetailsTab";
import { ClassroomDetailsTab } from "./ClassroomDetailsTab";
import { DigitalDetailsTab } from "./DigitalDetailsTab";
import { TrainerFeesDetailsTab } from "./TrainerFeesDetailsTab";
import { CommunicationDetailsTab } from "./CommunicationDetailsTab";
import { CourseFeesDetailsTab } from "./CourseFeesDetailsTab";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { Link } from "redux-little-router";
import { DorsAndCpcCourseFeesDetailsTab } from "./DorsAndCpcCourseFeesDetailsTab";
import { showCourseFees } from "@common/crud/eventType/helpers";
import { FilesDetailsTab } from "./FilesDetailsTab";
import { CustomTabProps } from "@common/model";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { useSelector } from "react-redux";
import { DelegateDocumentsTab } from "./DelegateDocumentsTab";

interface CoreDetailTabPropsState extends CoreDetailProps {
    onTabChange: (e: any, data: TabProps) => void;
    path: string;
}

enum TabNames {
    Details = "Details",
    ClassroomDetails = "Classroom Details",
    DigitalDetails = "Digital Details",
    TrainerFees = "Trainer",
    Communications = "Communications",
    CourseFees = "Course Fees",
    Files = "Files",
    DelegateDocuments = "Delegate Documents"
}

export const CoreDetails: React.FC<CoreDetailTabPropsState> = ({ eventType, trainerAttributes, onTabChange, path }) => {
    const isDdrs = eventType.workflowType === WorkflowTypeEnum.DDRS;
    const businessLineType = useSelector(businessLineTypeSelector);

    let nonDdrsPanes = [
        { menuItem: TabNames.Details, path: "", render: () => (
            <DetailsTab
                eventType={eventType}
                trainerAttributes={trainerAttributes}
            />
        ) },
        { menuItem: TabNames.ClassroomDetails, path: "classroomDetails", render: () => <ClassroomDetailsTab eventType={eventType} /> },
        { menuItem: TabNames.DigitalDetails, path: "digitalDetails", render: () => <DigitalDetailsTab eventType={eventType} /> },
        { menuItem: TabNames.CourseFees, path: "courseFees", render: () => <DorsAndCpcCourseFeesDetailsTab eventType={eventType} /> },
        { menuItem: TabNames.TrainerFees, path: "trainer", render: () => (
            <TrainerFeesDetailsTab eventType={eventType} model={eventType} trainerAttributes={trainerAttributes} />) },
        { menuItem: TabNames.Communications, path: "communications", render: () => (<CommunicationDetailsTab eventType={eventType} />) },
        { menuItem: TabNames.Files, path: "files", render: () => (<FilesDetailsTab eventTypeId={eventType?.id} />) },
        { menuItem: TabNames.DelegateDocuments, path: "delegateDocuments", render: () => (<DelegateDocumentsTab eventTypeId={eventType?.id} />) }
    ];

    if (eventType.deliveryType !== SchemeDeliveryTypeEnum.ClassroomAndDigital) {
        if (eventType.deliveryType === SchemeDeliveryTypeEnum.Digital) {
            nonDdrsPanes = nonDdrsPanes.filter(p => p.menuItem !== TabNames.ClassroomDetails);
        }
        if (eventType.deliveryType === SchemeDeliveryTypeEnum.Classroom) {
            nonDdrsPanes = nonDdrsPanes.filter(p => p.menuItem !== TabNames.DigitalDetails);
        }
    }

    if (!showCourseFees(eventType)) {
        nonDdrsPanes = nonDdrsPanes.filter(p => p.menuItem !== TabNames.CourseFees);
    }

    if (eventType.workflowType === WorkflowTypeEnum.DDRS) {
        nonDdrsPanes = nonDdrsPanes.filter(p => p.menuItem !== TabNames.CourseFees);
    }

    if (businessLineType !== BusinessLineType.Construction) {
        nonDdrsPanes = nonDdrsPanes.filter(p => p.menuItem !== TabNames.DelegateDocuments);
    }

    const ddrsPanes = [...nonDdrsPanes,  { menuItem: TabNames.CourseFees, path: "courseFees",
        render: () => <CourseFeesDetailsTab ddrsCourseFees={eventType.ddrsCourseFees}  /> } ];

    const panes = !isDdrs ? nonDdrsPanes : ddrsPanes;
    const activeTab = path.split("/").pop().split("?")[0];
    const activeIndex = panes.findIndex(p => p?.path === activeTab) ;

    return (
        <Tab panes={panes} onTabChange={onTabChange} activeIndex={activeIndex >= 0 ? activeIndex : 0} />
    );
};

const CoreDetailsWithActions: React.FC<DetailProps & DispatchProps> = (props) => {
    function handleTabChange(event: any, tabData: CustomTabProps) {
        props.push(`${props.basePath}/${props.eventType.id}/${tabData.panes[+tabData.activeIndex].path}`);
    }

    const basePath = props.path[props.path.length -1] === "/" ? props.path.slice(0, -1) : props.path;
    const showEdit = !(basePath?.toLowerCase() || "").endsWith("files") && !(basePath?.toLowerCase() || "").endsWith("delegatedocuments");

    return (
        <div>
            <CoreDetails {...props} onTabChange={handleTabChange} />

            {showEdit && (
                <Authorize authorize={EventTypeAdmin}>
                    <Divider />
                    <Segment basic clearing vertical>
                        <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${basePath}/edit`} />
                    </Segment>
                </Authorize>
            )}
        </div>
    );
};

export const coreDetailsTab = (props: DetailProps & DispatchProps) => ({
    path: "",
    menuItem: "Details",
    authorise: true,
    render: () => (
        <Tab.Pane>
            <CoreDetailsWithActions {...props} />
        </Tab.Pane>
    )
});
