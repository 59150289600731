import { ApplicationState } from "@booking/applicationState";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export function useBookingStatus(bookingId: string) {
    const router = useSelector((state: ApplicationState) => state.router);
    const finishBookingTime = router.query.finishDateTime !== null ? moment(router.query.finishDateTime) : undefined;
    const isFreshBooking = finishBookingTime !== null && moment().diff(finishBookingTime, "minutes") < 30;
    const isRebooking = React.useMemo(() => !!finishBookingTime && sessionStorage.getItem(`rebooking-${bookingId}`) === "true",[bookingId, finishBookingTime]);
    const isCancelled = React.useMemo(() => !!finishBookingTime && sessionStorage.getItem(`cancelled-${bookingId}`) === "true",[bookingId, finishBookingTime]);
    return { isFreshBooking, isRebooking, isCancelled };
}
