import * as React from "react";
import { Modal } from "@common/components";
import { Button, DropdownItemProps, Form } from "semantic-ui-react";
import { StandbyTrainerCalendarSettings } from "../../standbyTrainersCalendarModel";
import { StandbyTrainersApi } from "../../StandbyTrainersApi";
import { Input } from "@neworbit/simpleui-input";
import { TrainerAttributeDefinitionApi, TrainerAttributeDefinitionListModel } from "@common/crud/trainerAttributeDefinition";
import { toast } from "react-toastify";

const StandbyTrainersCalendarSettingsModal = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [trainerAttributes, setTrainerAttributes] = React.useState<DropdownItemProps[]>([]);
    const [standbyTrainerCalendarSettings, setStandbyTrainerCalendarSettings] = React.useState<StandbyTrainerCalendarSettings>({});

    const parseTrainerAttributeDefinition = (trainerAttributeDefinition: TrainerAttributeDefinitionListModel): DropdownItemProps => {
        return { text: trainerAttributeDefinition.name, value: trainerAttributeDefinition.id, key: trainerAttributeDefinition.id };
    };

    React.useEffect(() => {
        async function loadData() {
            const trainerAttributeDefinitionApi = new TrainerAttributeDefinitionApi();
            const attributes = await trainerAttributeDefinitionApi.getAll();
            setTrainerAttributes(attributes?.map(parseTrainerAttributeDefinition) ?? []);

            const standbyTrainersApi = new StandbyTrainersApi();
            setStandbyTrainerCalendarSettings(await standbyTrainersApi.getStandbyTrainerCalendarSettings());
        }

        if (open) {
            loadData();
        }
    }, [open]);

    const onChangeStandbyTrainerCalendarSettingsOpened = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    const onCancel = React.useCallback(() => {
        toast.info("Setting have been canceled");
        onChangeStandbyTrainerCalendarSettingsOpened();
    }, [onChangeStandbyTrainerCalendarSettingsOpened]);

    const onChange = React.useCallback((property: keyof StandbyTrainerCalendarSettings) => (value: string | number) => {
        setStandbyTrainerCalendarSettings({ ...standbyTrainerCalendarSettings, [property]: value });
    }, [standbyTrainerCalendarSettings]);

    const onSave = React.useCallback(async () => {
        async function saveData() {
            const api = new StandbyTrainersApi();
            await api.saveStandbyTrainerCalendarSettings(standbyTrainerCalendarSettings);
            toast.info("Settings have been Saved");
        }

        saveData();
        onChangeStandbyTrainerCalendarSettingsOpened();
    }, [onChangeStandbyTrainerCalendarSettingsOpened, standbyTrainerCalendarSettings]);

    return (
        <>
            <Button type="button" icon="cog" onClick={onChangeStandbyTrainerCalendarSettingsOpened} />
            <Modal size="mini" open={open} onClose={undefined}>
                <Modal.Header>Standby Trainer Calendar settings</Modal.Header>
                <Modal.Content>
                    <Form className="extra-padding full-width-input">
                        <Input.Dropdown
                            label="Standby Trainer Attribute"
                            value={standbyTrainerCalendarSettings.standbyTrainerAttribute}
                            options={trainerAttributes}
                            onChange={onChange("standbyTrainerAttribute")}
                            dynamicOptions
                            search
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.weekdayStandbyTrainerFee}
                            label="Weekday Standby Trainer Fee"
                            onChange={onChange("weekdayStandbyTrainerFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.saturdayStandbyTrainerFee}
                            label="Saturday Standby Trainer Fee"
                            onChange={onChange("saturdayStandbyTrainerFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.sundayStandbyTrainerFee}
                            label="Sunday Standby Trainer Fee"
                            onChange={onChange("sundayStandbyTrainerFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.threeStandbySessionFee}
                            label="3 standby session fee"
                            onChange={onChange("threeStandbySessionFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.eveningStandbySessionFee}
                            label="Evening standby session fee"
                            onChange={onChange("eveningStandbySessionFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.standbySessionTierTwoFee}
                            label="Standby session tier 2 fee"
                            onChange={onChange("standbySessionTierTwoFee")}
                        />
                        <Input.Number
                            value={standbyTrainerCalendarSettings?.standbySessionTierThreeFee}
                            label="Standby session tier 3 fee"
                            onChange={onChange("standbySessionTierThreeFee")}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onCancel}>Cancel</Button>
                    <Button onClick={onSave}>Save</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { StandbyTrainersCalendarSettingsModal };
