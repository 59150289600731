import * as React from "react";
import { RebookingFees, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { Grid, GridRow, Segment } from "semantic-ui-react";
import { getCurrencyFormat } from "@common/formating";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

interface Props {
    title: string;
    rebookingFees?: RebookingFees[];
    defaultRebookingFee?: RebookingFees;
    workflowType: number;
}

export const RebookingFeesDetailsSection: React.FC<Props> = (
    {
        title,
        workflowType,
        rebookingFees,
        defaultRebookingFee,
    }) => {
    return (
        <>
            <h3>{title}</h3>
            <Segment secondary as={GridRow}>
                <Grid columns="three">
                    <Grid.Row>
                        <Grid.Column>More than {workflowType === WorkflowTypeEnum.Dors ? "7" : "21"} days before course Fee</Grid.Column>
                        <Grid.Column>Within {workflowType === WorkflowTypeEnum.Dors ? "7" : "21"} days/After course date Fee</Grid.Column>
                        <Grid.Column>Effective Date</Grid.Column>
                    </Grid.Row>

                    {defaultRebookingFee && (
                        <Grid.Row>
                            <Grid.Column>
                                {getCurrencyFormat(defaultRebookingFee.feeAfter14Days)}
                            </Grid.Column>
                            <Grid.Column>
                                {getCurrencyFormat(defaultRebookingFee.feeCloseToCourseDate)}
                            </Grid.Column>
                            <Grid.Column>
                                {defaultRebookingFee.effectiveDate?.format(DateFormat.Short)}
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {rebookingFees && rebookingFees.map((fee, index) => (
                        <Grid.Row key={index}>
                            <Grid.Column>
                                {getCurrencyFormat(fee.feeAfter14Days)}
                            </Grid.Column>
                            <Grid.Column>
                                {getCurrencyFormat(fee.feeCloseToCourseDate)}
                            </Grid.Column>
                            <Grid.Column>
                                {fee.effectiveDate?.format(DateFormat.Short)}
                            </Grid.Column>
                        </Grid.Row>
                    ))}
                </Grid>
            </Segment>
        </>
    );
};
