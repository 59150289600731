import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ConstructionAttributesTable } from "../ConstructionAttributesTable";

export const ConstructionAttributes = ({ trainer }: {trainer: TrainerDetailModel}) => {

    function GetAttributesByWorkflowAndDeliveryType(workflowFilter: (a: TrainerAttribute) => boolean) {
        const attributesForWorkflow = trainer?.trainerAttributes?.filter(workflowFilter);
        const classroomAttributes = attributesForWorkflow?.filter(a => !a.isDigital);
        const digitalAttributes = attributesForWorkflow?.filter(a => a.isDigital);
        return { classroomAttributes, digitalAttributes };
    };

    const { classroomAttributes: constructionClassroomAttributes, digitalAttributes: constructionDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isConstruction);
    const { classroomAttributes: citbClassroomAttributes, digitalAttributes: citbDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isCitb);
    const { classroomAttributes: apsClassroomAttributes, digitalAttributes: apsDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isAps);

    return (<>
        {constructionClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="Construction Classroom"
                    attributes={constructionClassroomAttributes}
                    digital={false}
                    title="Construction Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Construction Classroom Attributes"
                />
            </Grid.Row>
        }
        {constructionDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="Construction Digital"
                    attributes={constructionDigitalAttributes}
                    digital={true}
                    title="Construction Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Construction Digital Attributes"
                />
            </Grid.Row>
        }
        {citbClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="CITB Classroom"
                    attributes={citbClassroomAttributes}
                    digital={false}
                    title="CITB Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="CITB Classroom Attributes"
                />
            </Grid.Row>
        }
        {citbDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="CITB Digital"
                    attributes={citbDigitalAttributes}
                    digital={true}
                    title="CITB Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="CITB Digital Attributes"
                />
            </Grid.Row>
        }
        {apsClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="APS Classroom"
                    attributes={apsClassroomAttributes}
                    digital={false}
                    title="APS Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="APS Classroom Attributes"
                />
            </Grid.Row>
        }
        {apsDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <ConstructionAttributesTable
                    attributeCategory="APS Digital"
                    attributes={apsDigitalAttributes}
                    digital={true}
                    title="APS Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="APS Digital Attributes"
                />
            </Grid.Row>
        }
    </>);};
