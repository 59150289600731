import React from "react";
import { Input } from "@neworbit/simpleui-input";
import { Button, Divider, Grid, Icon } from "semantic-ui-react";
import { phoneNumberWithSpaces } from "@common/validation";

interface AttendeeManagerContactRowProps {
    managerContact: { name: string; telephone: string; email: string };
    index: number;
    amount: number;
    showErrors: boolean;
    editable: boolean;
    updateManagerValue: (index: number, field: string, value: string) => void;
    removeManager: (index: number) => void;
}

export const AttendeeManagerContactRow = (props: AttendeeManagerContactRowProps) => {
    const { managerContact, index, amount, showErrors, editable, updateManagerValue, removeManager } = props;

    const updateName = React.useCallback((value: string) => {
        updateManagerValue(index, "name", value);
    }, [index, updateManagerValue]);

    const updateEmail = React.useCallback((value: string) => {
        updateManagerValue(index, "email", value);
    }, [index, updateManagerValue]);

    const updateTelephone = React.useCallback((value: string) => {
        updateManagerValue(index, "telephone", value);
    }, [index, updateManagerValue]);

    const onRemoveClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        removeManager(index);
    }, [index, removeManager]);

    return (
        <>
            <Grid.Row>
                <Grid.Column>
                    {editable ? (
                        <Input.Text
                            value={managerContact.name}
                            placeholder="Name"
                            showErrors={showErrors}
                            onChange={updateName}
                        />
                    ) : managerContact.name && (
                        <span className="break-all manager-data-row">{managerContact.name}</span>
                    )}

                    {editable ? (
                        <Input.Text
                            value={managerContact.telephone}
                            placeholder="Telephone"
                            validation={phoneNumberWithSpaces()}
                            showErrors={showErrors}
                            onChange={updateTelephone}
                        />
                    ) : managerContact.telephone && (
                        <span className="break-all manager-data-row">{managerContact.telephone}</span>
                    )}

                    {editable ? (
                        <Input.Email
                            value={managerContact.email}
                            placeholder="Email"
                            showErrors={showErrors}
                            onChange={updateEmail}
                        />
                    ) : managerContact.email && (
                        <span className="break-all manager-data-row">{managerContact.email}</span>
                    )}
                </Grid.Column>
            </Grid.Row>
            {!editable && index !== amount - 1 && (
                <Divider hidden className="full-width" />
            )}
            {editable && (
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Button
                            type="button"
                            onClick={onRemoveClick}
                            content={<span><Icon name="minus circle" />Remove manager</span>}
                            className="link-button adjust-link-button-size" />
                    </Grid.Column>
                </Grid.Row>
            )}
        </>
    );
};
