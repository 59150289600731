import "moment-duration-format";
import * as React from "react";
import { Segment, Grid, SemanticWIDTHS, Rating } from "semantic-ui-react";
import { EventInstance } from "../model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Media } from "@common/global/AppMedia";
import { ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { useDispatch, useSelector } from "react-redux";
import moment, { Duration, Moment } from "moment";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { loadEventInstanceDetail, resubmitCourseToDors, saveEiFavouriteStatus } from "../actions";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { eventInstanceGroupByIdSelector } from "../selectors";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { Box } from "@material-ui/core";
import { WelshLanguageIndicator } from "./WelshLanguageIndicator";
import { DelegateConfirmTimesModal } from "./register/DelegateConfirmTimesModal";
import { FavouriteCreateEditModel } from "@common/favourites/model";
import { FavouriteCategoryEnum } from "@common/favourites/model";
import { eventTypeSelector } from "@common/crud/eventType/selectors";
import { loadEventTypeDetail } from "@common/crud/eventType/actions";

interface EventInstanceDetailsProps {
    eventInstance: EventInstance;
    showTimesConfirmation?: boolean;
    forceDelegateConfirmTimes?: boolean;
    confirmForcedDelegateTimeConfirmation?: () => void;
    cancelForcedDelegateTimeConfirmation?: () => void;
}

const EventInstanceBanner: React.FC<EventInstanceDetailsProps> = ({ eventInstance, showTimesConfirmation,
    forceDelegateConfirmTimes, confirmForcedDelegateTimeConfirmation, cancelForcedDelegateTimeConfirmation }) => {
    const groupDetail = useSelector(eventInstanceGroupByIdSelector(eventInstance.groupId)) as EventInstanceGroupModel;
    const app = useSelector(appSelector);
    const eventType = useSelector(eventTypeSelector(eventInstance.eventTypeId));
    const dispatch = useDispatch();
    const showCourseApprovalNumber = eventInstance?.workflowType === WorkflowTypeEnum.CPC
    || eventInstance?.productCategory === ProductCategoryEnum.OnRoadWithCpc;

    React.useEffect(() => {
        const getEventType = async () => {
            const eventTypeId = eventInstance.eventTypeId;
            dispatch(loadEventTypeDetail({ eventTypeId }));
        };

        if (eventInstance?.eventTypeId) {
            getEventType();
        }
    }, [dispatch, eventInstance.eventTypeId]);

    const buildDayPartDisplay = (startDate: Moment, startTime: Duration, eventDuration: Duration, groupEventInstanceId: string) => {
        const startDateString = startDate?.format("dddd Do MMMM YYYY") || "";
        const startTimeString = startTime?.format(DateFormat.Time, { trim: false }) || "";

        const endTime = (startTime && eventDuration) ? startTime.clone().add(eventDuration) : undefined;
        if (endTime?.asHours() >= 24) {
            const endDateString = startDate?.clone().add(1, "day").format("dddd Do MMMM YYYY") || "";
            const endTimeString = endTime.subtract(1, "day").format(DateFormat.Time, { trim: false }) || "";
            return eventInstance.id === groupEventInstanceId
                ? (<b>{startDateString + " - " + startTimeString + " - " + endDateString + " - " + endTimeString}</b>)
                : (startDateString + " - " + startTimeString + " - " + endDateString + " - " + endTimeString);
        } else {
            const endTimeString = endTime?.format(DateFormat.Time, { trim: false }) || "";
            return eventInstance.id === groupEventInstanceId
                ? (<b>{startDateString + " - " + startTimeString + " - " + endTimeString}</b>)
                : (startDateString + " - " + startTimeString + " - " + endTimeString);
        }
    };

    const renderMultiDayPart = (gi: EventInstanceGroupItemModel) => {
        return (
            <div key={gi.dayNumber}>
                {buildDayPartDisplay(gi.startDate, gi.startTime, gi.eventDuration, gi.eventInstanceId)}
            </div>
        );
    };

    const calculateDisplayTime = () => {
        if (!eventInstance.startDate) {
            return "";
        }

        if ((groupDetail?.eventInstanceGroupItems?.length || 1) > 1) {
            return groupDetail?.eventInstanceGroupItems?.map((gi: EventInstanceGroupItemModel) => renderMultiDayPart(gi));
        } else {
            return buildDayPartDisplay(eventInstance.startDate, eventInstance.startTime, eventInstance.eventDuration, eventInstance.id);
        }
    };

    const onResubmitCoursesToDors = async () => {
        await dispatch(resubmitCourseToDors(eventInstance.id));
        await dispatch(
            loadEventInstanceDetail({ eventInstanceId: eventInstance.id })
        );
    };

    const isReSubmitButtonVisible = eventInstance &&
        !eventInstance.cancelled &&
        eventInstance.workflowType === WorkflowTypeEnum.Dors &&
        !eventInstance.dorsId &&
        eventInstance.errorMessageDuringPublish;

    const renderDorsId = () => {
        if (eventInstance.workflowType === WorkflowTypeEnum.Dors) {
            return (
                <>
                    <br />
                    <b>DORS ID:</b> {isReSubmitButtonVisible ? (
                        <Authorize authorize={[TtcPlannerRole]}>
                            <a
                                className="primary-link cursor-pointer"
                                onClick={onResubmitCoursesToDors}
                            >
                                Resubmit to DORS
                            </a>
                        </Authorize>
                    ) : (
                        eventInstance.dorsId
                    )}
                </>
            );
        }
        return <></>;
    };

    const renderOrganisationName = () => {
        if (eventInstance.corporateOrganisationName) {
            return (
                <div className="flex-row-fullwidth">
                    <h3>
                        {eventInstance.corporateOrganisationName}
                    </h3>
                    {app === Apps.Admin && (
                        <Rating
                            icon="star"
                            rating={eventInstance.favourite ? 1 : 0}
                            maxRating={1}
                            onClick={toggleFavourite} />
                    )}
                </div>
            );
        }
        return <></>;
    };

    const eventInstanceEndTime = React.useMemo(() => moment.duration((eventInstance.startTime?.asSeconds() || 0)
        + (eventInstance.eventDuration?.asSeconds() || 0), "seconds"), [eventInstance]);

    function getDesktopColumnWidths(): SemanticWIDTHS[] {
        if (isReSubmitButtonVisible) {
            return ["11", "5"];
        }
        if (showCourseApprovalNumber) {
            return ["9", "7"];
        }
        return ["12", "4"];
    }

    function toggleFavourite() {
        const favourite: FavouriteCreateEditModel = {
            targetId: eventInstance.id,
            category: FavouriteCategoryEnum.EventInstance,
            name: `${eventInstance.eventTypeName} - ${eventInstance.venueName} ` +
                `${eventInstance.deliveryDateTime.format(DateFormat.ShortWithDayAndTime)}`,
            markAsFavourite: !eventInstance.favourite
        };
        dispatch(saveEiFavouriteStatus(favourite));
    };

    const [firstColumnDesktopWidth, secondColumnDesktopWidth] = getDesktopColumnWidths();

    return (
        <Segment className="course-section">
            {renderOrganisationName()}
            <h3>
                {eventInstance.eventTypeName}
                <WelshLanguageIndicator language={eventInstance.language} />
            </h3>
            <Media greaterThanOrEqual="tablet">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={firstColumnDesktopWidth}>
                            <>
                                <b>{eventInstance.venueName}</b>
                                <br />
                                {calculateDisplayTime()}
                                <DelegateConfirmTimesModal
                                    showTimesConfirmation={showTimesConfirmation}
                                    eventInstanceId={eventInstance.id}
                                    eventInstanceStartTime={eventInstance.startTime}
                                    eventInstanceEndTime={eventInstanceEndTime}
                                    eventInstanceBreaks={eventInstance.breaks}
                                    actualEventInstanceStartTime={eventInstance.actualStartTime}
                                    actualEventInstanceEndTime={eventInstance.actualEndTime}
                                    forceDelegateConfirmTimes={forceDelegateConfirmTimes}
                                    confirmForcedDelegateTimeConfirmation={confirmForcedDelegateTimeConfirmation}
                                    cancelForcedDelegateTimeConfirmation={cancelForcedDelegateTimeConfirmation}
                                />
                            </>
                        </Grid.Column>
                        <Grid.Column
                            width={secondColumnDesktopWidth}
                            textAlign={isReSubmitButtonVisible ? "left" : "right"}
                            verticalAlign="bottom"
                        >
                            <Box marginLeft="auto" width="fit-content">
                                <b>Course ID:</b> {eventInstance.courseId}
                                {app !== Apps.Trainer && renderDorsId()}
                                {showCourseApprovalNumber &&
                                    <div>
                                        <b>Course Approval Number: </b>{eventType?.courseApprovalNumber}
                                    </div>
                                }
                            </Box>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Media>
            <Media lessThan="tablet">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width="12">
                            <p>
                                <b>{eventInstance.venueName}</b>
                                <br />
                                {calculateDisplayTime()}
                                <DelegateConfirmTimesModal
                                    showTimesConfirmation={showTimesConfirmation}
                                    eventInstanceId={eventInstance.id}
                                    eventInstanceStartTime={eventInstance.startTime}
                                    eventInstanceEndTime={eventInstanceEndTime}
                                    eventInstanceBreaks={eventInstance.breaks}
                                    actualEventInstanceStartTime={eventInstance.actualStartTime}
                                    actualEventInstanceEndTime={eventInstance.actualEndTime}
                                    forceDelegateConfirmTimes={forceDelegateConfirmTimes}
                                    confirmForcedDelegateTimeConfirmation={confirmForcedDelegateTimeConfirmation}
                                    cancelForcedDelegateTimeConfirmation={cancelForcedDelegateTimeConfirmation}
                                />
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width="12">
                            <b>Course ID:</b> {eventInstance.courseId}
                            {app !== Apps.Trainer && renderDorsId()}
                        </Grid.Column>
                        {eventInstance?.workflowType === WorkflowTypeEnum.CPC &&
                            <Grid.Column width={16} textAlign="left">
                                <b>Course Approval Number: </b>{eventType?.courseApprovalNumber}
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </Media>
        </Segment>
    );
};

export { EventInstanceBanner };
