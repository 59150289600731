import moment from "moment";
import { AppState, Order, SearchOptions } from "./model";
import { createSelector } from "reselect";
import { OrdersState } from "./reducer";

export type SelectorState = AppState;

export const ordersSelector = (state: AppState) => state.ordersState;

export const routeIdSelector = (state: AppState) => {
    return state.router.params.orderId;
};

export const orderSelector = createSelector(
    ordersSelector,
    routeIdSelector,
    (ordersState: OrdersState, id: string): Order => {
        return ordersState?.orders?.filter(c => c.id === id)[0] || {} as any;
    }
);

export const eventInstancesWithOrdersSelector = (state: AppState) => state.eventInstancesWithOrdersState;

export const ordersSearchOptionsSelector = (state: AppState): SearchOptions => ({
    ...state.router.query,
    showOrdersBy: state.router.query.showOrdersBy ? +state.router.query.showOrdersBy : 0,
    maxPageSize: state.router.query.maxPageSize ? +state.router.query.maxPageSize : 100,
    page: state.router.query.page ? +state.router.query.page : 0,
    organisationId: state.router.query.organisationId ?? undefined,
    corporateOrganisationIds: state.router.query.corporateOrganisationIds?.split(",") ?? [],
    eventInstanceId: state.router.query.eventInstanceId ?? undefined,
    bookingReference: state.router.query.bookingReference ?? undefined,
    bookerName: state.router.query.bookerName ?? undefined,
    eventTypeIds: state.router.query.eventTypeIds?.split(",") ?? [],
    fromDate: state.router.query.fromDate ? moment(state.router.query.fromDate) : undefined,
    toDate: state.router.query.toDate ? moment(state.router.query.toDate) : undefined,
    showCancelled: state.router.query.showCancelled === "true",
    showCompleted: state.router.query.showCompleted === "true"
});
