import * as React from "react";
import { Authorize } from "reauthorize";
import { Segment, Message, Button } from "semantic-ui-react";
import { TtcPlannerRole, TrainerRole } from "@common/auth/model";
import { Link } from "redux-little-router";
import { ShowHideEventInstanceButton } from "../ShowHideEventInstanceButton";
import { EventInstance, ConfirmationStatus, EnquiryType } from "../../model";
import { PublishModal } from "./PublishModal";
import { useDispatch, useSelector } from "react-redux";
import { acceptEventInstances, setConfirmationStatus, sendReminderEmail } from "../../actions";
import { routerPathnameSelector } from "@common/crud/common/selectors";
import { CancelEventInstanceButton } from "../CancelEventInstanceButton";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DeleteEventInstanceButton } from "@common/crud/eventInstance/components/details/DeleteEventInstanceButton";
import { loadAttendeesForEventInstance } from "@common/crud/attendee/actions";
import { attendeesSelector } from "@common/crud/attendee/selectors";
import { AvailabilityModal } from "./AvailabilityModal";
import { BookingTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { isEventInstanceWorkflowConstruction, isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";
import { AddZoomAccountButton } from "../AddZoomAccountButton";
import { currentUserSelector } from "@common/auth";
import { TrainerCourseConfirmationSummary } from "../trainers/TrainerCourseConfirmationSummary";
import { ConfirmButton } from "@common/components";
import { ConvertButton } from "../ConvertButton";

interface Props {
    eventInstance: EventInstance;
}

export const EventInstanceActions: React.FC<Props> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const path = useSelector(routerPathnameSelector);
    const attendees = ((useSelector(attendeesSelector)) ?? []).filter(a => !a.isBookingCanceled);
    const isNotPublished = !!eventInstance && !eventInstance.publishDate;
    const isNotDigital = eventInstance.eventInstanceDeliveryType !== DeliveryTypeEnum.Digital;
    const hasAwaitsConfirmationStatus = eventInstance.workflowType !== WorkflowTypeEnum.BusinessDriver
        && eventInstance.confirmationStatus === ConfirmationStatus.Awaits;
    const hasNoAttendees = attendees.length === 0;
    const hasErrors = eventInstance.errorMessageDuringPublish || eventInstance.errorMessageDuringUpdateTrainers;
    const currentUser = useSelector(currentUserSelector);

    React.useEffect(() => {
        if (eventInstance?.id) {
            dispatch(loadAttendeesForEventInstance({ eventInstanceId: eventInstance.id }));
        }
    }, [dispatch, eventInstance.id]);

    const onAcceptEventInstance = React.useCallback(() => {
        dispatch(acceptEventInstances([eventInstance.id]));
    }, [dispatch, eventInstance.id]);

    const onConfirmClick = React.useCallback(() => {
        dispatch(setConfirmationStatus(eventInstance.id, ConfirmationStatus.Confirmed));
    }, [dispatch, eventInstance.id]);

    const onRejectClick = React.useCallback(() => {
        dispatch(setConfirmationStatus(eventInstance.id, ConfirmationStatus.Rejected));
    }, [dispatch, eventInstance]);

    const onResendButtonClick = React.useCallback(() => {
        dispatch(sendReminderEmail(eventInstance.id, eventInstance.venueId));
    }, [dispatch, eventInstance.id, eventInstance.venueId]);

    const CanShowErrorMessage = React.useCallback(() => {
        return eventInstance.reasonForHidingEvent === null || !hasNoAttendees;
    },[eventInstance.reasonForHidingEvent, hasNoAttendees]);

    if (eventInstance.cancelled) {
        return null;
    }

    const isCorporate = isEventInstanceWorkflowCorporate(eventInstance);
    const isCorporateAndClosed = isCorporate && eventInstance.bookingType === BookingTypeEnum.Closed;
    const isConstruction = isEventInstanceWorkflowConstruction(eventInstance);
    const isConstructionAndClosed = isConstruction && eventInstance.bookingType === BookingTypeEnum.Closed;

    return (
        <>
            <Authorize authorize={TrainerRole}>
                <>
                    <Segment basic clearing vertical>
                        <div className="button-container justify-start">
                            {!eventInstance.isAcceptedByTrainer &&
                                    <ConfirmButton
                                        header={"You are about to accept the following course:"}
                                        size={"large"}
                                        icon={"check"}
                                        content={

                                            <TrainerCourseConfirmationSummary
                                                eventInstances={[eventInstance]}
                                                trainerId={currentUser.id} />
                                        }
                                        onConfirm={onAcceptEventInstance}
                                        children={<span>Accept</span>}
                                    />
                            }
                            <AvailabilityModal
                                eventInstanceId={eventInstance.id}
                                moduleType={eventInstance.moduleType}
                                workflowType={eventInstance.workflowType}
                                trainers={eventInstance.trainers}
                                startDate={eventInstance.startDate}
                                practicalTrainers={eventInstance.practicalTrainers}
                                adminAvailableRoles={eventInstance.adminAvailableRoles}
                                eventInstanceDeliveryType={eventInstance.eventInstanceDeliveryType}
                            />
                        </div>
                    </Segment>
                </>
            </Authorize>
            <Authorize authorize={[TtcPlannerRole]}>
                <Segment basic clearing vertical>
                    <div className="button-container justify-start">
                        {
                            isNotPublished
                                ?
                                <>
                                    <PublishModal eventInstance={eventInstance} />
                                    <DeleteEventInstanceButton eventInstance={eventInstance} />
                                </>
                                : ((!isCorporateAndClosed && !isConstructionAndClosed) && <ShowHideEventInstanceButton eventInstance={eventInstance} />)
                        }
                        {(hasNoAttendees || isCorporate || isConstruction) && <CancelEventInstanceButton eventInstance={eventInstance} /> }
                        {
                            hasAwaitsConfirmationStatus && isNotDigital &&
                            <>
                                <Button content="Confirm" onClick={onConfirmClick} />
                                <Button content="Reject" onClick={onRejectClick} />
                            </>
                        }
                        <AddZoomAccountButton eventInstance={eventInstance} />
                        {isNotDigital && eventInstance.workflowType !== WorkflowTypeEnum.BusinessDriver &&
                            <Button content="Resend Reminder Email" onClick={onResendButtonClick} />}
                        {eventInstance.enquiryType === EnquiryType.Enquiry && <ConvertButton eventInstance={eventInstance} />}
                        <Button icon="pencil" content="Edit" as={Link} floated="right" href={`${path}/edit`} />
                    </div>
                    {hasErrors && <div className="margin-top">
                        {
                            eventInstance.errorMessageDuringPublish && CanShowErrorMessage() &&
                            <Message content={eventInstance.errorMessageDuringPublish} as={Segment} error />
                        }
                        {
                            eventInstance.errorMessageDuringUpdateTrainers &&
                            <Message content={eventInstance.errorMessageDuringUpdateTrainers} as={Segment} error />
                        }
                    </div>}
                </Segment>
            </Authorize>
        </>);
};
