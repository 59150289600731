import * as React from "react";
import { Button } from "semantic-ui-react";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import { IvrPaymentStatusModel } from "@common/crud/dorsBooking/model";
import { toast } from "react-toastify";

export interface BookButtonProps {
    bookingId: string;
    noRetryButton?: boolean;
    bookingAppEndpoint: string;
}

export const IvrPaymentActions: React.FC<BookButtonProps> = ({ bookingId, noRetryButton, bookingAppEndpoint }) => {

    const [paymentStatus, setPaymentStatus] = React.useState<IvrPaymentStatusModel>(Object);

    React.useEffect(() => {
        async function getData() {
            const bookingApi = new DorsBookingApi();
            const result = await bookingApi.getIvrPaymentStatus(bookingId);
            setPaymentStatus(result);
        }
        if (bookingId) {
            getData();
        }

    }, [bookingId]);

    async function onRejoinBooking(event: React.MouseEvent) {
        event.preventDefault();
        try {
            const bookingApi = new DorsBookingApi();
            await bookingApi.rejoinBooking(bookingId);
            const url = `${bookingAppEndpoint}bookings/${bookingId}/${paymentStatus?.eventInstanceId}/booking-details`;
            window.location.href = url;
        } catch {
            toast.error("An error occurred while joining the booking");
        }
    }

    function onRetryPayment(event: React.MouseEvent) {
        event.preventDefault();
        const url = `/police-and-court-event-management/eventInstances/${paymentStatus?.eventInstanceId}/attendees/${paymentStatus?.attendeeId}/manual-payment`;
        window.location.href = url;
    }

    if (!paymentStatus.isSeatReserved || paymentStatus.paymentComplete) {
        return null;
    }

    if (paymentStatus.isAdminPayment && !noRetryButton) {
        return <Button onClick={onRetryPayment} content="Retry Payment" />;
    }

    if (!paymentStatus.isAdminPayment) {
        return <Button onClick={onRejoinBooking} content="Rejoin Booking" />;
    }

    return null;
};
