import { Dispatch } from "redux";
import { Payload } from "@neworbit/redux-helpers";

import { loadAndTrack } from "redux-request-loading";

import { EventInstanceDetailModel } from "@common/crud/eventInstance";

import { bookingIdSelector } from "../landing/selectors";
import { ApplicationState } from "../applicationState";

import { BookingEventInstanceApi } from "../eventInstance/bookingEventInstanceApi";

import * as actions from "./actionTypes";
import { clearRebookVenue, loadRebookVenueDetail } from "@booking/rebookVenue/actions";
import { AsyncDispatch } from "@common/redux-helpers";

export type RebookEventInstanceAction =
    ({ type: typeof actions.LOAD_REBOOK_EVENTINSTANCE_DETAIL_SUCCESS } & Payload<EventInstanceDetailModel>);

export function loadRebookEventInstanceAndVenueDetail({ eventInstanceId }: { eventInstanceId?: string }) {
    return async (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
        const bookingId = bookingIdSelector(getState());

        if (eventInstanceId) {
            const api = new BookingEventInstanceApi(bookingId);
            const result = await loadAndTrack(
                dispatch,
                "loadRebookEventInstance",
                api.getEventInstanceDetailsForBooking({ id: eventInstanceId })
            );
            dispatch(loadRebookEventInstanceDetailSuccess(result));
            await dispatch(loadRebookVenueDetail({ venueId: result.venueId }));
        }
    };
}

export function clearRebookEventInstanceDetail() {
    return async (dispatch: Dispatch) => {
        dispatch(loadRebookEventInstanceDetailSuccess(null));
    };
}

export function clearRebookEventInstanceAndVenueDetail() {
    return async (dispatch: AsyncDispatch) => {
        await dispatch(clearRebookEventInstanceDetail());
        await dispatch(clearRebookVenue());
    };
}

export const loadRebookEventInstanceDetailSuccess = (payload: EventInstanceDetailModel): RebookEventInstanceAction => ({
    payload,
    type: actions.LOAD_REBOOK_EVENTINSTANCE_DETAIL_SUCCESS
});
