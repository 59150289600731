import * as React from "react";
import { useCallback } from "react";
import { Icon } from "semantic-ui-react";
import "./ChangeInBasketInput.scss";

type ChangeInBasketInputProps = {
    value?: number;
    maxValue?: number;
    seatsChangedForCourse?: (seats: number) => void;
    basketChangesInProgress?: boolean;
    wide?: boolean;
};

export const ChangeInBasketInput = ({ value, maxValue, seatsChangedForCourse, basketChangesInProgress, wide }: ChangeInBasketInputProps) => {
    const atMin = value === 1;
    const atMax = maxValue !== undefined && value === maxValue;

    const onMinus = useCallback(() => {
        if (!basketChangesInProgress && !atMin) {
            seatsChangedForCourse(- 1);
        }
    }, [basketChangesInProgress, atMin, seatsChangedForCourse]);

    const onPlus = useCallback(() => {
        if (!atMax && !basketChangesInProgress) {
            seatsChangedForCourse(1);
        }
    }, [atMax, basketChangesInProgress, seatsChangedForCourse]);

    return (
        <div className={`update-number-input${wide ? " update-number-input-wide" : " update-number-input-normal"}`}>
            <Icon name="minus" className="number-icon" onClick={onMinus} disabled={atMin} />
            <input type="text" value={value} readOnly />
            <Icon name="plus" className="number-icon" onClick={onPlus} disabled={atMax} />
        </div>
    );
};
