import * as React from "react";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { useSelector } from "react-redux";
import { Modal } from "@common/components";
import { Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { bookingSelector } from "../selectors";
import { EventTypeApi } from "@common/crud/eventType";
import { IntroMessagesResponse } from "@common/crud/eventType/model";
import { isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";

const SessionStorageKey = "AttendeeIntroMessageShown";

const IntroMessage: React.FC = () => {
    const [introMessages, setIntroMessages] = React.useState<IntroMessagesResponse>(null);
    const wasShownBefore = sessionStorage.getItem(SessionStorageKey) === "true";
    const [showModal, setShowModal] = React.useState<boolean>(!wasShownBefore);
    const [t, i18n] = useTranslation("IntroMessage");
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const booking = useSelector(bookingSelector);

    React.useEffect(() => {
        if (booking.schemeId && !isNullOrUndefinedOrEmptyString(booking.eventTypeId?.trim()) && !introMessages && !isClientAdvisor) {
            const eventTypeIntroMessages = new EventTypeApi();
            eventTypeIntroMessages.getIntroMessage(booking.schemeId, booking.eventTypeId).then(setIntroMessages);
        }
    }, [booking, introMessages, isClientAdvisor]);

    const close = React.useCallback(() => {
        setShowModal(false);
        sessionStorage.setItem(SessionStorageKey, "true");
    }, []);

    const messageEn = introMessages?.introMessageEnglish;
    const messageCy = introMessages?.introMessageWelsh;
    const isMessageConfigured = !!messageEn && messageEn.trim() !== "";
    const message = i18n.language === "en" ? messageEn : messageCy;

    if (!isMessageConfigured || isClientAdvisor === true) {
        return null;
    }

    return (
        <Modal
            open={showModal}
        >
            <Modal.Header>
                {t("MODAL_HEADER")}
            </Modal.Header>
            <Modal.Content>
                <HtmlRenderer value={message} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close}>
                    <Icon name='remove' /> {t("CONTINUE")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export { IntroMessage };
