import { loadAttendeeDetail, updateFlexiPay } from "@common/crud/attendee/actions";
import { UpdateFlexiPayModel } from "@common/crud/attendee/model";
import { basePathSelector, attendeeSelector } from "@common/crud/attendee/selectors";
import { eventInstanceIdSelector, eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { EventType, EventTypeApi } from "@common/crud/eventType";
import { formatCurrency } from "@common/formating";
import { Spinner } from "@common/global";
import { useFlexiPayFee } from "@common/hooks/useFlexiPayFee";
import { addPayment, loadPayment } from "@common/payments/actions";
import { AppState, PaymentPageNames, PaymentPageNamesEnum, PaymentType, StartPaymentProcessModel } from "@common/payments/model";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, DropdownProps, Form, Grid, Segment } from "semantic-ui-react";
import { AppState as EventInstanceAppState } from "@common/crud/eventInstance/model";
import { AppState as AttendeeAppState } from "@common/crud/attendee/model";
import { ConfirmButton } from "@common/components";
import { attendeeIdSelector } from "@common/payments/selectors";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { loadOrganisationDetailFromForceId } from "@common/crud/organisation/actions";

export const FlexiPayPayment = () => {
    const [paymentType, setPaymentType] = React.useState(PaymentType.Card);
    const [eventType, setEventType] = React.useState<EventType>(null);
    const { attendeeId, eventInstanceId, eventInstance, attendee, basePath } = useSelector((state: AppState & EventInstanceAppState & AttendeeAppState) => {
        return {
            attendeeId: attendeeIdSelector(state),
            eventInstanceId: eventInstanceIdSelector(state),
            eventInstance: eventInstanceSelector(state),
            attendee: attendeeSelector(state),
            basePath: basePathSelector(state)
        };
    });
    const referringOrganisation = useSelector(policeOrganisationByDorsId(attendee?.issuingForceId));
    const flexiPayFee = useFlexiPayFee(eventType, referringOrganisation);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const getEventType = async () => {
            const response = await new EventTypeApi().detail(eventInstance.eventTypeId);
            setEventType(response);
        };
        if (eventInstance?.eventTypeId) {
            getEventType();
        }
    }, [eventInstance]);

    React.useEffect(() => {
        dispatch(loadAttendeeDetail({ attendeeId, eventInstanceId, addAuditEntry: false }));
    },[attendeeId, dispatch, eventInstanceId]);

    React.useEffect(() => {
        dispatch(loadOrganisationDetailFromForceId(attendee?.issuingForceId));
    }, [attendee]);

    const paymentTypeOptions = [
        { value: PaymentType.Card, text: "Card" },
        { value: PaymentType.ChequeOrPO, text: "Cheque" },
        { value: PaymentType.Cash, text: "Cash" },
        { value: PaymentType.FakePayment, text: "Balance Adjustment" },
    ];

    function onPaymentTypeOptionChange(_: any, option: DropdownProps) {
        setPaymentType(+option.value);
    }

    function makePayment() {
        dispatch(addPayment(
            eventInstance.id,
            attendee.id,
            paymentType,
            flexiPayFee * 100,
            `${basePath}/${attendee.id}`,
            true
        ));

        const model: UpdateFlexiPayModel = {
            flexiPayFee: flexiPayFee * 100,
            flexiPayPurchased: true
        };
        dispatch(updateFlexiPay(eventInstance.id, attendee.id, model));
    }

    function onContinueClick() {
        if (paymentType === PaymentType.Card) {
            const adminPaymentModel: StartPaymentProcessModel = {
                objectRelatedTo: attendee.id,
                eventInstanceId: eventInstance.id,
                partialPaymentModel: { amount: flexiPayFee },
                scheduledPaymentPlanModel: [],
                includeFullyBookedCourses: false,
                flexiPayPurchasedAfterBooking: true,
                flexiPayFee,
                startPaymentProcessMultiPartModels: [{
                    relatedObjectId: attendee.id,
                    eventInstanceId: eventInstance.id,
                    dayPart: 0
                }]
            };

            dispatch(loadPayment(adminPaymentModel, `${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`));
            return;
        }

        makePayment();
    }

    return (
        <>
            <Segment>
                <h3>Payment options</h3>
            </Segment>
            <Spinner active={false}>
                <Grid className="two-section-layout">
                    <Grid.Row>
                        <Grid.Column width={10} className="two-section-layout-left">
                            <Container className="indented">
                                <Grid.Row className="margin-bottom-rem">
                                    <Grid className="balance-grid">
                                        <Grid.Row className="payment-info-row">
                                            <Grid.Column width={5}>
                                                <p className="payment-text">Flexi-booking fee</p>
                                            </Grid.Column>
                                            <Grid.Column width={11}>
                                                <p>{formatCurrency(flexiPayFee * 100)}</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={10}>
                                        <p>Payment type</p>
                                    </Grid.Column>
                                    <Grid.Column width={10} className="extra-padding">
                                        <Form.Dropdown
                                            selection
                                            placeholder="Any Department"
                                            value={paymentType}
                                            options={paymentTypeOptions}
                                            onChange={onPaymentTypeOptionChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={10}>
                                        <p>How much would you like to pay?</p>
                                    </Grid.Column>
                                    <Grid.Column width={10} className="extra-padding full-width-input">
                                        <Input.Text
                                            disabled
                                            value={flexiPayFee?.toFixed(2)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Container>
                        </Grid.Column>
                        <Grid.Column width={6} className="two-section-layout-right booking-details-column">
                            <Grid.Row>
                                {paymentType === PaymentType.FakePayment ?
                                    <ConfirmButton
                                        className="full-width"
                                        content="Action will decrease attendee balance by given amount."
                                        header="Are you sure?"
                                        onConfirm={makePayment}
                                    >
                                        Continue
                                    </ConfirmButton> :
                                    <Button
                                        className="full-width"
                                        onClick={onContinueClick}
                                        content="Continue"
                                    />
                                }
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Spinner>
        </>
    );
};
