/* eslint-disable max-lines */
/* eslint-disable max-classes-per-file */
import * as React from "react";
import { DropdownItemProps, Form, Label, InputOnChangeData, DropdownOnSearchChangeData, SemanticShorthandItem, HtmlLabelProps,
    LabelProps } from "semantic-ui-react";
import { validators, ValidationFunction, createValidator, ValidationOptions, validate } from "not-valid";
import { debounce, shallowEqual } from "@neworbit/simpleui-utils";

export type DropdownType = string | number | any[];

interface SharedProps<TValue> {
    value?: TValue;
    label?: SemanticShorthandItem<HtmlLabelProps>;
    inputLabel?: SemanticShorthandItem<LabelProps>;
    inputLabelPosition?: "left" | "right" | "left corner" | "right corner";
    validation?: ValidationFunction<TValue>[];
    validationOptions?: ValidationOptions;
    validationDebounce?: number;
    onChange?: (value: TValue, valid: boolean) => void;
    onBlur?: () => void;
    placeholder?: string;
    showErrors?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    disableClearable?: boolean;
    withoutLabel?: boolean;
    inline?: boolean;
}

interface BaseInputState<TValue> {
    value: TValue;
    rawValue?: string;
    errors: string[];
    dirty: boolean;
    touched: boolean;
}

export interface DropdownProps<TValue extends DropdownType> extends SharedProps<TValue> {
    options?: DropdownItemProps[];
    multiple?: boolean;
    search?: boolean | ((search: string) => Promise<DropdownItemProps[]>);
    debounce?: number;
    requiredValidator?: ValidationFunction<TValue>;
    children?: React.ReactChildren;
    dynamicOptions?: boolean;
}

export interface DropdownState<TValue extends DropdownType> extends BaseInputState<TValue> {
    options: DropdownItemProps[];
    prevOptions: DropdownItemProps[];
    loading: boolean;
}

interface DropdownConfigProps<TValue extends DropdownType> {
    hasChanged: (state: DropdownState<TValue>, value: TValue) => boolean;
}

class InputValidator<TValue> {
    private validators: ValidationFunction<TValue>[];
    private options: ValidationOptions;

    constructor(constructorValidators?: ValidationFunction<TValue>[], options?: ValidationOptions) {

        this.validators = (constructorValidators === null || constructorValidators === undefined)
            ? []
            : constructorValidators;

        this.options = options;
    }

    public validate(value: TValue): Promise<string[]> {
        return validate(this.validators, value, this.options);
    }
}

export abstract class DropdownBase<TValue extends DropdownType>
    extends React.Component<DropdownProps<TValue> & DropdownConfigProps<TValue>, DropdownState<TValue>> {

    protected static getDerivedStateFromProps(
        { hasChanged, value, options, dynamicOptions }: DropdownProps<any> & DropdownConfigProps<any>,
        state: DropdownState<any>
    ): DropdownState<any> {

        if (dynamicOptions && DropdownBase.optionsChanged(state.prevOptions, options)) {
            return {
                ...state,
                prevOptions: options,
                options
            };
        }

        if (hasChanged(state, value) === false) {
            return null;
        }

        return {
            ...state,
            value
        };
    }

    private static optionsChanged(prevOptions: DropdownItemProps[], options: DropdownItemProps[]) {

        if (prevOptions.length !== options.length) {
            return true;
        }

        for (let i = 0; i < prevOptions.length; i++) {
            if (prevOptions[i].label !== options[i].label) {
                return true;
            }

            if (prevOptions[i].value !== options[i].value) {
                return true;
            }
        }

        return false;
    }

    private searchChange: (event: any, data: DropdownOnSearchChangeData) => void;

    private validator: InputValidator<TValue>;

    constructor(props: DropdownProps<TValue> & DropdownConfigProps<TValue>) {
        super(props);
        this.state = {
            options: props.options || [],
            prevOptions: props.options || [],
            value: props.value,
            errors: [],
            dirty: props.value !== undefined,
            touched: false,
            loading: false
        };

        let validation = props.validation;

        if (props.required) {
            validation = props.validation === undefined
                ? [props.requiredValidator]
                : [props.requiredValidator, ...props.validation];
        }

        this.searchChange = this.getSearchChange();
        this.validator = new InputValidator(validation, props.validationOptions);
    }

    public shouldComponentUpdate(nextProps: DropdownProps<TValue>, nextState: DropdownState<TValue>) {
        const stateChanged = this.state !== nextState;
        const coreNextProps = this.getCoreProps(nextProps);
        const coreProps = this.getCoreProps(this.props);

        return stateChanged || (!shallowEqual(coreNextProps, coreProps));
    }

    public render() {

        const { disabled, label, multiple, placeholder, readOnly, search, value, required, disableClearable, withoutLabel } = this.props;

        let errors;

        if (this.state.errors) {
            errors = this.state.errors.map((e, i) => <p key={i}>{e}</p>);
        }

        const showErrors = errors.length > 0 && ((this.state.touched && this.state.dirty) || this.props.showErrors);

        return (
            <div className="field-wrapper">
                <Form.Dropdown
                    label={withoutLabel ? undefined : <label>{label}{(label && required) && <span className="required-asterisk"> *</span>}</label>}
                    placeholder={placeholder}
                    value={value}
                    search={typeof search === "function" ? true : search}
                    onSearchChange={this.searchChange}
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    fluid
                    selection
                    error={showErrors}
                    loading={this.state.loading}
                    options={this.state.options}
                    multiple={multiple}
                    disabled={disabled}
                    readOnly={readOnly}
                    clearable={!disableClearable && !required}
                />
                {showErrors && <Label basic color="red" pointing>
                    {errors}
                </Label>}
            </div>
        );
    }

    public async componentDidMount() {
        const errors = await this.validator.validate(this.state.value);
        this.setState(
            { errors },
            () => this.emitOnChange(this.state.value, errors));
    }

    public async componentDidUpdate() {
        const errors = await this.validator.validate(this.state.value);

        if (errors.length === this.state.errors.length) {
            return;
        }

        this.setState(
            { errors },
            () => this.emitOnChange(this.state.value, errors));
    }

    private getSearchChange(): (event: any, { searchQuery }: DropdownOnSearchChangeData) => void {

        if (typeof this.props.search !== "function") {
            return () => undefined;
        }

        const searchChange = async (event: any, { searchQuery }: DropdownOnSearchChangeData) => {
            if (typeof this.props.search !== "function") {
                return;
            }

            const options = await this.props.search(searchQuery);

            this.setState({
                options,
                loading: false
            });
        };

        if (this.props.debounce !== undefined) {
            const debounced = debounce((event: any, data: DropdownOnSearchChangeData) => searchChange(event, data), this.props.debounce);
            return (event: any, data: DropdownOnSearchChangeData) => {
                this.setState({ loading: true });
                return debounced(event, data);
            };
        }

        return (event: any, data: DropdownOnSearchChangeData) => {
            this.setState({ loading: true });
            return searchChange(event, data);
        };
    }

    private handleChange = async (event: any, data: InputOnChangeData) => {

        const value = data.value as TValue;

        this.setState({
            value,
            dirty: true
        }, () => this.emitOnChange(value, this.state.errors));

        const errors = await this.validator.validate(value);
        this.setState(
            { errors },
            () => this.emitOnChange(this.state.value, errors));
    };

    private emitOnChange(value: TValue, errors: string[]): void {
        if (this.props.onChange) {
            const valid = errors.length === 0;
            this.props.onChange(value, valid);
        }
    }

    private onBlur = () => this.setState({ touched: this.state.dirty });

    private getCoreProps(props: DropdownProps<TValue>) {
        const {
            value,
            validation,
            validationOptions,
            onChange,
            requiredValidator,
            ...coreProps
        } = props;

        return coreProps;
    }
}

export class ExtendedDropdown extends React.Component<DropdownProps<string>> {
    public render() {
        const props: DropdownProps<string> & DropdownConfigProps<string> = {
            ...this.props,
            requiredValidator: validators.requiredString(),
            hasChanged: (state, value) => value !== state.value
        };
        const StringDropdown = DropdownBase as unknown as new () => DropdownBase<string>;
        return <StringDropdown {...props} />;
    }
}

export class ExtendedDropdownNumber extends React.Component<DropdownProps<number>> {
    public render() {
        const props: DropdownProps<number> & DropdownConfigProps<number> = {
            ...this.props,
            requiredValidator: validators.requiredNumber(),
            hasChanged: (state, value) => value !== state.value
        };
        const NumberDropdown = DropdownBase as unknown as new () => DropdownBase<number>;
        return <NumberDropdown {...props} />;
    }
}

export class ExtendedDropdownMulti extends React.Component<DropdownProps<any[]>, {}> {

    public render() {
        const props: DropdownProps<any[]> & DropdownConfigProps<any[]> = {
            ...this.props,
            multiple: true,
            value: this.props.value || [],
            requiredValidator: createValidator<any[]>(v => v.length > 0, "This field is required"),
            hasChanged: (state, value) => value.length !== state.value.length
        };

        const MultiDropdown = DropdownBase as unknown as new () => DropdownBase<any[]>;

        return <MultiDropdown {...props} />;
    }
}
