import * as React from "react";
import { connect } from "react-redux";
import { Link } from "redux-little-router";
import { Grid, Menu } from "semantic-ui-react";

import { VenueDetailModel, AppState } from "../model";
import { venueSelector, basePathSelector } from "../selectors";
import { VenueBaseProps } from "./Base";

import { CoreDetailsWithActions } from "./details/CoreDetails";
import moment from "moment";

export interface CoreDetailProps {
    venue: VenueDetailModel;
    path: string;
    isPoliceOrCourt: boolean;
    isCorporate: boolean;
    isConstruction: boolean;
}

export interface DetailProps extends CoreDetailProps {
    basePath: string;
    tab?: string;
}

const DetailInternal: React.FC<DetailProps> = ({ venue, basePath, path, isPoliceOrCourt, isCorporate, isConstruction }) => {
    const date = new Date();
    const fromDate = moment(date).clone().startOf("month");
    const toDate = moment(date).clone().endOf("month");
    const query = React.useMemo(() => `fromDate=${fromDate.format("YYYY-MM-DD")}&toDate=${toDate.format("YYYY-MM-DD")}`, [fromDate, toDate]);

    return (
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <Grid.Column>
                    <h1>{venue.name}</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3} as={Grid} padded>
                    <Grid.Column vertical as={Menu}>
                        <Menu.Item name="Venues" as={Link} href={basePath}>
                        All Venues
                        </Menu.Item>
                        <Menu.Item name="Calendar" as={Link} href={`${basePath}/${venue.id}/calendar?${query}`}>
                        Calendar
                        </Menu.Item>
                        <Menu.Item name="History" as={Link} href={`${basePath}/${venue.id}/history`}>
                        History
                        </Menu.Item>
                    </Grid.Column>
                </Grid.Column>

                <Grid.Column width={13}>
                    <CoreDetailsWithActions
                        path={path}
                        venue={venue}
                        isPoliceOrCourt={isPoliceOrCourt}
                        isCorporate={isCorporate}
                        isConstruction={isConstruction}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );};

function mapStateToProps(state: AppState, ownProps: VenueBaseProps): DetailProps {
    return {
        venue: venueSelector(state),
        path: state.router.pathname,
        basePath: basePathSelector(state),
        isPoliceOrCourt: ownProps.isPoliceOrCourt,
        isCorporate: ownProps.isCorporate,
        isConstruction: ownProps.isConstruction
    };
}

export const Detail = connect(mapStateToProps)(DetailInternal);
