import { createSelector } from "reselect";
import { ApplicationState } from "../applicationState";

export const externalLinksSelector = (state: ApplicationState) => state.externalLinks;

export const bookingIdSelector = (state: ApplicationState) => state.router.params.bookingId;

export const bookingSelector = (state: ApplicationState) => state.booking;

export const pathSelector = (state: ApplicationState) => state.router.pathname;

export const searchSelector = (state: ApplicationState) => state.router.search;

export const bookingBasePathSelector = createSelector(
    bookingIdSelector,
    bookingId => bookingId ? `/bookings/${bookingId}` : "/landing"
);

export const deliveryTypeFilterSelector = (state: ApplicationState) => state.deliveryTypeFilters;
