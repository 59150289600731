/* eslint-disable max-lines */
import axios, { toFormData } from "axios";
import moment from "moment";

import {
    OrganisationListModel,
    OrganisationDetailModel,
    OrganisationCreateModel,
    OrganisationEditModel,
    SearchOptions,
    ReminderType,
    OrganisationSpecificFields,
    AdHocEmailCorporateOrganisation,
    BusinessDevelopmentManager,
    AttendeeField,
    AttendeeFieldResponse,
    Department,
    DepartmentResponse,
    PurchaseOrder,
    PurchaseOrderResponse,
} from "./model";
import * as QueryString from "query-string";
import { DsaAreaListItem } from "@common/dsa";
import { TrainerFee, ValueWithEffectiveDate } from "../eventType/model";
import { CommsDestination, LanguageEnum } from "../eventInstance/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";

const parseValueWithEffectiveDateObject = (model: ValueWithEffectiveDate<any>): ValueWithEffectiveDate<any> => ({
    ...model,
    effectiveDate: moment(model.effectiveDate)
});

class OrganisationApi {

    private readonly apiUrl = "/api/organisation";

    public async getAll(options?: SearchOptions): Promise<OrganisationListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as OrganisationListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<OrganisationDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async save(organisation: OrganisationEditModel): Promise<OrganisationDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${organisation.id}`, organisation);
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveSurveyLink(organisationId: string, language: LanguageEnum, surveyLink: string, surveyNumber: number): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveSurveyLink`, { language, surveyLink, surveyNumber });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveBookingInformationLink(organisationId: string, language: LanguageEnum, deliveryType: DeliveryTypeEnum,
        bookingInformationLink: string): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveBookingInformationLink`, { language, deliveryType, bookingInformationLink });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveTermsAndConditionsLink(organisationId: string, language: LanguageEnum, termsAndConditionsLink: string): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveTermsAndConditionsLink`, { language, termsAndConditionsLink });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveReminderType(organisationId: string, reminderType: ReminderType): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveReminderType`, { reminderType });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveCourseCreationCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string,
        value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveCourseCreationCommsDestination`, { commsDestination, email, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveCourseReminderCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string,
        value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveCourseReminderCommsDestination`, { commsDestination, email, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveBookingCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string,
        value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveBookingCommsDestination`, { commsDestination, email, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveCertificateCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string,
        value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveCertificateCommsDestination`, { commsDestination, email, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveCourseEditCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string,
        value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveCourseEditCommsDestination`, { commsDestination, email, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async saveOrgSpecific(organisationId: string, orgSpecificField: OrganisationSpecificFields, value?: boolean): Promise<OrganisationDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${organisationId}/saveOrgSpecific`, { orgSpecificField, value });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async create(organisation: OrganisationCreateModel): Promise<OrganisationDetailModel> {
        const response = await axios.post(this.apiUrl, organisation);
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public delete(id: string): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public async getDsaAreaList(): Promise<DsaAreaListItem[]> {
        const response = await axios.get(`${this.apiUrl}/referencedata/dsa-areas`);
        return response.data as DsaAreaListItem[];
    }

    public async detailFromForceId(forceId: number): Promise<OrganisationDetailModel> {
        const response = await axios.get(`${this.apiUrl}/forces/${forceId}`);
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async sendAdHocCorporationEmailToOrganisation(organisationId: string, model: AdHocEmailCorporateOrganisation): Promise<boolean> {
        const formData = toFormData(model);
        const response = await axios.post(
            `${this.apiUrl}/${organisationId}/send-ad-hoc-corporation-email-to-organisation`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    public async getAllBusinessDevelopmentManagers(): Promise<BusinessDevelopmentManager[]> {
        const response = await axios.get(`${this.apiUrl}/businessDevelopmentManagers`);
        return response.data;
    }

    public async getAttendeeFields(organisationId: string, onlyActive: boolean = true): Promise<AttendeeField[]> {
        const response = await axios.get<AttendeeField[]>(`${this.apiUrl}/${organisationId}/attendee-fields?onlyActive=${onlyActive}`);
        const parsedResponse = this.parseAttendeeField(response.data);
        return parsedResponse;
    }

    public async createAttendeeField(organisationId: string, attendeeField: AttendeeField): Promise<AttendeeFieldResponse> {
        const response = await axios.post<AttendeeFieldResponse>(`${this.apiUrl}/${organisationId}/attendee-fields`, attendeeField);
        const parsedResponse = this.parseAttendeeFieldResponse(response.data);
        return parsedResponse;
    }

    public async editAttendeeField(organisationId: string, attendeeField: AttendeeField): Promise<AttendeeFieldResponse> {
        const response = await axios.put<AttendeeFieldResponse>(`${this.apiUrl}/${organisationId}/attendee-fields`, attendeeField);
        const parsedResponse = this.parseAttendeeFieldResponse(response.data);
        return parsedResponse;
    }

    public async editFlexiPay(
        organisationId: string, chargesOwnFlexiPayFees: boolean, flexiPayFees: ValueWithEffectiveDate<number>[] ): Promise<OrganisationDetailModel> {
        const response = await axios.patch(`${this.apiUrl}/${organisationId}/flexi-pay`, { flexiPayFees, chargesOwnFlexiPayFees });
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public async getDepartments(organisationId: string, onlyActive: boolean = true): Promise<Department[]> {
        const response = await axios.get<Department[]>(`${this.apiUrl}/${organisationId}/departments?onlyActive=${onlyActive}`);
        const parsedResponse = this.parseDepartment(response.data);
        return parsedResponse;
    }

    public async createDepartment(organisationId: string, department: Department): Promise<DepartmentResponse> {
        const response = await axios.post<DepartmentResponse>(`${this.apiUrl}/${organisationId}/departments`, department);
        const parsedResponse = this.parseDepartmentResponse(response.data);
        return parsedResponse;
    }

    public async editDepartment(organisationId: string, department: Department): Promise<DepartmentResponse> {
        const response = await axios.put<DepartmentResponse>(`${this.apiUrl}/${organisationId}/departments`, department);
        const parsedResponse = this.parseDepartmentResponse(response.data);
        return parsedResponse;
    }

    public async getPurchaseOrder(organisationId: string, purchaseOrderId: string): Promise<PurchaseOrder> {
        const response = await axios.get<PurchaseOrder>(`${this.apiUrl}/${organisationId}/purchase-orders/${purchaseOrderId}`);
        const parsedResponse = this.parsePurchaseOrder(response.data, true);
        return parsedResponse;
    }

    public async getPurchaseOrders(organisationId: string): Promise<PurchaseOrder[]> {
        const response = await axios.get<PurchaseOrder[]>(`${this.apiUrl}/${organisationId}/purchase-orders`);
        const parsedResponse = this.parsePurchaseOrders(response.data);
        return parsedResponse;
    }

    public async createPurchaseOrder(organisationId: string, purchaseOrder: PurchaseOrder): Promise<PurchaseOrderResponse> {
        const response = await axios.post<PurchaseOrderResponse>(`${this.apiUrl}/${organisationId}/purchase-orders`, purchaseOrder);
        const parsedResponse = this.parsePurchaseOrderResponse(response.data);
        return parsedResponse;
    }

    public async editPurchaseOrder(organisationId: string, purchaseOrder: PurchaseOrder): Promise<PurchaseOrderResponse> {
        const response = await axios.put<PurchaseOrderResponse>(`${this.apiUrl}/${organisationId}/purchase-orders`, purchaseOrder);
        const parsedResponse = this.parsePurchaseOrderResponse(response.data);
        return parsedResponse;
    }

    public async validateSpecificUrlIdentifier(specificUrlIdentifier: string, organisationId: string): Promise<boolean> {
        const response = await axios.get<boolean>(`${this.apiUrl}/${organisationId}/validate-specific-url-identifier/${specificUrlIdentifier}`);
        return response.data;
    }

    public parseAttendeeFieldResponse(model: AttendeeFieldResponse): AttendeeFieldResponse {
        return {
            attendeeFields: this.parseAttendeeField(model.attendeeFields),
            error: model.error
        };
    }

    public parseAttendeeField(models: AttendeeField[]): AttendeeField[] {
        return models.map((model: AttendeeField) => ({
            ...model,
            dateInactive: model.dateInactive && moment(model.dateInactive),
        }));
    }

    public parseDepartmentResponse(model: DepartmentResponse): DepartmentResponse {
        return {
            departments: this.parseDepartment(model.departments),
            error: model.error
        };
    }

    public parseDepartment(models: Department[]): Department[] {
        return models.map((model: Department) => ({
            ...model,
            dateInactive: model.dateInactive && moment(model.dateInactive),
        }));
    }

    public parsePurchaseOrderResponse(model: PurchaseOrderResponse): PurchaseOrderResponse {
        return {
            purchaseOrders: this.parsePurchaseOrders(model.purchaseOrders),
            error: model.error
        };
    }

    public parsePurchaseOrder(model: PurchaseOrder, withHistory?: boolean): PurchaseOrder {
        return ({
            ...model,
            startDate: model.startDate && moment(model.startDate),
            endDate: model.endDate && moment(model.endDate),
            history: withHistory
                ? model.history?.map(h => ({
                    ...h,
                    dateCreated: moment(h.dateCreated),
                }))
                : [],
            courses: model.courses
        });
    }

    public parsePurchaseOrders(models: PurchaseOrder[]): PurchaseOrder[] {
        return models.map((model: PurchaseOrder) => (this.parsePurchaseOrder(model)));
    }

    public parseModel(model: OrganisationDetailModel): OrganisationDetailModel {
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
            classroomTrainerFees: model.classroomTrainerFees && OrganisationApi.parseTrainerFeesDictionary(model.classroomTrainerFees),
            practicalTrainerFees: model.practicalTrainerFees && OrganisationApi.parseTrainerFeesDictionary(model.practicalTrainerFees),
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated),
                pendingDate: h.pendingDate && moment(h.pendingDate),
                completionDate: h.completionDate && moment(h.completionDate)
            })),
            flexiPayFees: model.flexiPayFees && model.flexiPayFees.map(parseValueWithEffectiveDateObject),
            corporateOrganisationData: model.corporateOrganisationData ? {
                ...model.corporateOrganisationData,
                corporateOrganisationSpecificFeesData: model.corporateOrganisationData.corporateOrganisationSpecificFeesData ? {
                    /* eslint-disable max-len */
                    openDigitalEIFeeWithEffectiveDate: model.corporateOrganisationData.corporateOrganisationSpecificFeesData.openDigitalEIFeeWithEffectiveDate
                        ? model.corporateOrganisationData.corporateOrganisationSpecificFeesData.openDigitalEIFeeWithEffectiveDate.map(parseValueWithEffectiveDateObject)
                        : [],
                    openClassroomEIFeeWithEffectiveDate: model.corporateOrganisationData.corporateOrganisationSpecificFeesData.openClassroomEIFeeWithEffectiveDate
                        ? model.corporateOrganisationData.corporateOrganisationSpecificFeesData.openClassroomEIFeeWithEffectiveDate.map(parseValueWithEffectiveDateObject)
                        : [],
                    closedDigitalEIFeeWithEffectiveDate: model.corporateOrganisationData.corporateOrganisationSpecificFeesData.closedDigitalEIFeeWithEffectiveDate
                        ? model.corporateOrganisationData.corporateOrganisationSpecificFeesData.closedDigitalEIFeeWithEffectiveDate.map(parseValueWithEffectiveDateObject)
                        : [],
                    closedClassroomEIFeeWithEffectiveDate: model.corporateOrganisationData.corporateOrganisationSpecificFeesData.closedClassroomEIFeeWithEffectiveDate
                        ? model.corporateOrganisationData.corporateOrganisationSpecificFeesData.closedClassroomEIFeeWithEffectiveDate.map(parseValueWithEffectiveDateObject)
                        : [],
                    cpcUploadFee: model.corporateOrganisationData.corporateOrganisationSpecificFeesData.cpcUploadFee
                        ? model.corporateOrganisationData.corporateOrganisationSpecificFeesData.cpcUploadFee.map(parseValueWithEffectiveDateObject)
                        : []
                    /* eslint-enable max-len */
                } : undefined
            } : undefined
        };
    }

    public static parseTrainerFeesDictionary(trainerFeesDictionary: Record<string, TrainerFee[]>): Record<string, TrainerFee[]> {

        const entries = Object.entries(trainerFeesDictionary).map(x =>
            x.map((y, index) => index === 1 ? OrganisationApi.parseAllTrainerFees(y as TrainerFee[]): y ));

        return Object.fromEntries(entries);

    }

    public static parseAllTrainerFees(trainerFees: TrainerFee[]): TrainerFee[] {
        return trainerFees.map(OrganisationApi.parseTrainerFee);
    }

    public static parseValueWithEffectiveDateObject(model: ValueWithEffectiveDate<any>): ValueWithEffectiveDate<any> {
        return {
            ...model,
            effectiveDate: moment(model.effectiveDate)
        };
    }

    public static parseTrainerFee(trainerFee: TrainerFee) {
        return {
            ...trainerFee,
            effectiveDate: moment.utc(trainerFee.effectiveDate)
        };
    }
}

export { OrganisationApi };
