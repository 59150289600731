import * as React from "react";
import printJS from "print-js";
import { Modal } from "@common/components";
import { Button, CheckboxProps, Form, Radio } from "semantic-ui-react";
import { SendMessageOver } from "../../model";
import { AttendeeApi } from "../../attendeeApi";

export interface CancellationCommunicationsModalProps {
    attendeeId: string;
    attendeeEmail: string;
    attendeeTelephone: string;
    eventInstanceId: string;
}

export const CancellationCommunicationsModal: React.FC<CancellationCommunicationsModalProps> =
    ({ attendeeId, attendeeEmail, attendeeTelephone, eventInstanceId }) => {
        const [open, setOpen] = React.useState<boolean>(false);
        const [resendOption, setResendOption] = React.useState<SendMessageOver>(SendMessageOver.Both);

        React.useEffect(() => {
            setResendOption(attendeeEmail
                ? SendMessageOver.Email
                : attendeeTelephone
                    ? SendMessageOver.SMS
                    : SendMessageOver.Letter);
        }, [attendeeEmail, attendeeTelephone, open]);

        function onOpenClick() { setOpen(true); }
        function onCloseClick() { setOpen(false); }

        const setOption = React.useCallback((e: any, { value }: CheckboxProps) => setResendOption(value as number), []);

        function onContinueClick() {
            const sendCancellationCommunications = async () => {
                const attendeeApi = new AttendeeApi(eventInstanceId);
                const base64 = await attendeeApi.sendCancellationCommunications(attendeeId, eventInstanceId, resendOption);
                if (resendOption === SendMessageOver.Letter && base64 !== null) {
                    const blob = await fetch(base64).then(res => res.blob());
                    printJS(URL.createObjectURL(blob));
                }
            };
            sendCancellationCommunications();
            onCloseClick();
        }

        return (
            <>
                <Button className="button-margin" onClick={onOpenClick}>Send Cancellation</Button>

                <Modal open={open}>
                    <Modal.Header>
                        Send a booking cancelation comms
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                How are you sending the booking cancellation comms today?
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="Both Email and SMS"
                                    name="cancellationCommunicationsGroup"
                                    disabled={!attendeeEmail || !attendeeTelephone}
                                    value={SendMessageOver.Both}
                                    checked={resendOption === SendMessageOver.Both}
                                    onChange={setOption}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="Email"
                                    name="cancellationCommunicationsGroup"
                                    disabled={!attendeeEmail}
                                    value={SendMessageOver.Email}
                                    checked={resendOption === SendMessageOver.Email}
                                    onChange={setOption}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="SMS"
                                    name="cancellationCommunicationsGroup"
                                    disabled={!attendeeTelephone}
                                    value={SendMessageOver.SMS}
                                    checked={resendOption === SendMessageOver.SMS}
                                    onChange={setOption}
                                />
                            </Form.Field>
                            { /* Feature removed from R59, will be reinstated in future release so not deleting code.
                            <Form.Field>
                                <Radio
                                    label="Letter (manual print)"
                                    name="cancellationCommunicationsGroup"
                                    value={SendMessageOver.Letter}
                                    checked={resendOption === SendMessageOver.Letter}
                                    onChange={setOption}
                                />
                            </Form.Field> */}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={onCloseClick} negative>
                            Cancel
                        </Button>
                        <Button
                            onClick={onContinueClick}
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content="Continue"
                        />
                    </Modal.Actions>
                </Modal>
            </>
        );
    };
