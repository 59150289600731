export interface Questions {
    schemeId: string;
    formType?: string;
    sections: QuestionSection[];
}

export interface QuestionSection {
    id: string;
    title: string;
    seqNum: number;
    subSections?: QuestionSubSection[];
    summaryCriterium?: QuestionCriterium[];
    IsQuestionSection?: boolean;
}

export interface QuestionSubSection {
    id: string;
    text: string;
    seqNum: number;
    criteria?: QuestionCriterium[];
    summaryCriterium?: QuestionCriterium;
}

export interface QuestionCriterium {
    id: string;
    text: string;
    typeEnum?: CriteriaEnum;
    summarySubSection?: QuestionSummarySubSectionCriterium[];
}

export interface QuestionSummarySubSectionCriterium {
    id: string;
    text: string;
    typeEnum?: CriteriaEnum;
    idsToClear: string[];
}

export enum CriteriaEnum {
    none,
    check,
    text
}

export interface FormValidationResult {
    seqNum: number;
    validResult: boolean;
}
