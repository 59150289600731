import * as React from "react";
import { DdrsSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const DdrsSetting: ConfigurationAreaComponent<DdrsSettings> = ({ settings, hierarchy }) => (
    <SettingsGrid
        area="DdrsSettings"
        hierarchy={hierarchy}
        settings={settings}
        items={[
            { name: "UnbookedReminderCommsFrequencyDays", label: "Frequency for unbooked reminder comms (days)" },
        ]}
    />
);

