import * as React from "react";
import { Link } from "redux-little-router";
import { Table } from "semantic-ui-react";

import { AllProps } from "./All";

class AllItems extends React.Component<AllProps> {
    public render() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Offender Id</Table.HeaderCell>
                        <Table.HeaderCell>Court</Table.HeaderCell>
                        <Table.HeaderCell>Forename</Table.HeaderCell>
                        <Table.HeaderCell>Surname</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Telephone</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.drinkDriveOffenders?.map(drinkDriveOffender => (
                        <Table.Row key={drinkDriveOffender.id}>
                            <Table.Cell>
                                <Link href={`${this.props.path}/${drinkDriveOffender.id}`}>
                                    {drinkDriveOffender.attendanceId}
                                </Link>
                            </Table.Cell>

                            <Table.Cell>
                                {drinkDriveOffender.drinkDriveOffenderDetails?.courtName}
                            </Table.Cell>

                            <Table.Cell>
                                {drinkDriveOffender.forename}
                            </Table.Cell>

                            <Table.Cell>
                                {drinkDriveOffender.surname}
                            </Table.Cell>

                            <Table.Cell>
                                {drinkDriveOffender.email}
                            </Table.Cell>

                            <Table.Cell>
                                {drinkDriveOffender.telephone}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}

export { AllItems };
