/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import * as React from "react";
import { Button, Modal, Form, DropdownProps, Grid, Checkbox } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { cancelFromOrder } from "../actions";
import { AttendeeApi, AttendeeListModel } from "@common/crud/attendee";
import { Input } from "@neworbit/simpleui-input";
import { BusinessLineSupportsHalfRefund, EventInstanceDetails, GetRefundFullPeriodForBusinessLine, GetRefundHalfPeriodForBusinessLine, Order,
    OrderRefundTypeEnum, OtherCancellationReason } from "../model";
import { validators } from "not-valid";
import { CancelModalAttendeeRow } from "./CancelModalAttendeeRow";
import "./CancelModal.scss";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { toast } from "@common/toasts";
import { add } from "date-fns";
import { CancelModalEventInstancePaymentRow } from "./CancelModalEventInstancePaymentRow";
import { businessLineTypeSelector } from "@common/redux-helpers";

export interface CancelModalProps {
    orderId: string;
    eventInstance?: EventInstanceDetails;
    order?: Order;
    canCancel: boolean;
    validationAmount: number;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancelModal: React.FC<CancelModalProps> = ({ orderId, eventInstance, order, canCancel, validationAmount, setRefreshingRecord }) => {
    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineSupportsHalfRefunds = BusinessLineSupportsHalfRefund(businessLineType);
    const fullPeriodForBussinesLine = GetRefundFullPeriodForBusinessLine(businessLineType);
    const halfPeriodForBussinesLine = GetRefundHalfPeriodForBusinessLine(businessLineType);

    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [reason, setReason] = React.useState<any>();
    const [otherReason, setOtherReason] = React.useState<string>();
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [attendees, setAttendees] = React.useState<AttendeeListModel[]>([]);
    const [attendeesSelected, setAttendeesSelected] = React.useState<string[]>([]);
    const [eventInstancesSelected, setEventInstancesSelected] = React.useState<string[]>([]);
    const [paymentForCancelledPlacesRequired, setPaymentForCancelledPlacesRequired] = React.useState<OrderRefundTypeEnum>(
        (eventInstance?.eventInstanceDeliveryDateTime != null
            && eventInstance?.eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: fullPeriodForBussinesLine }))
            ? OrderRefundTypeEnum.Full
            : (businessLineSupportsHalfRefunds && eventInstance?.eventInstanceDeliveryDateTime != null
                && eventInstance?.eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: halfPeriodForBussinesLine }))
                ? OrderRefundTypeEnum.Half
                : OrderRefundTypeEnum.None
    );

    const otherCancellationReasonChoosen = reason === OtherCancellationReason;

    const dispatch = useDispatch();

    const togglePaymentForCancelledPlacesRequired = React.useCallback(() => {
        setPaymentForCancelledPlacesRequired(paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Full
            ? OrderRefundTypeEnum.None
            : OrderRefundTypeEnum.Full);
    }, [paymentForCancelledPlacesRequired]);

    const changePaymentForCancelledPlacesRequired = React.useCallback((_: any, d: DropdownProps) =>
        setPaymentForCancelledPlacesRequired(d.value as number), []);

    const openCloseAction = React.useCallback(() => {
        setPaymentForCancelledPlacesRequired(
            (eventInstance?.eventInstanceDeliveryDateTime != null
                && eventInstance?.eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: fullPeriodForBussinesLine }))
                ? OrderRefundTypeEnum.Full
                : (businessLineSupportsHalfRefunds && eventInstance?.eventInstanceDeliveryDateTime != null
                    && eventInstance?.eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: halfPeriodForBussinesLine }))
                    ? OrderRefundTypeEnum.Half
                    : OrderRefundTypeEnum.None);
        setReason(null);
        setOtherReason(null);
        setOtherReasonValid(true);
        setAttendees([]);
        setAttendeesSelected([]);
        setSubmitted(false);
        setOpen(!open);
    }, [businessLineSupportsHalfRefunds, eventInstance?.eventInstanceDeliveryDateTime, fullPeriodForBussinesLine, halfPeriodForBussinesLine, open]);

    React.useEffect(() => {
        if (order?.eventInstances) {
            const eventInstancesSelectedByDefault = order.eventInstances
                .filter(ei => ei.eventInstanceDeliveryDateTime != null
                    && ei.eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: fullPeriodForBussinesLine }));
            setEventInstancesSelected(eventInstancesSelectedByDefault.map(ei => ei.eventInstanceId).filter(id => !!id) as string[]);
        } else {
            setEventInstancesSelected([]);
        }
    }, [fullPeriodForBussinesLine, order]);

    React.useEffect(() => {
        const fetchData = async () => {
            const attendeeApi = new AttendeeApi(eventInstance.eventInstanceId);
            const result = await attendeeApi.getAttendeesForOrderEventInstance(orderId);

            if (result.attendees.length !== validationAmount) {
                toast.error("Previous cancellation not yet complete, please refresh and try again.");
                openCloseAction();
            }

            setAttendees(result.attendees);
            setAttendeesSelected([]);
        };

        if (open && eventInstance?.eventInstanceId) {
            fetchData();
        }
    }, [eventInstance, orderId, open, validationAmount, openCloseAction]);

    const onReasonChange = React.useCallback((_: any, d: DropdownProps) => setReason(d.value), []);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    const toggleAttendee = React.useCallback((attendeeId: string) => {
        if (attendeesSelected.includes(attendeeId)) {
            setAttendeesSelected(attendeesSelected.filter((id) => id !== attendeeId));
        } else {
            setAttendeesSelected([...attendeesSelected, attendeeId]);
        }
    }, [attendeesSelected]);

    const toggleEventInstance = React.useCallback((eventInstanceId: string) => {
        if (eventInstancesSelected.includes(eventInstanceId)) {
            setEventInstancesSelected(eventInstancesSelected.filter((id) => id !== eventInstanceId));
        } else {
            setEventInstancesSelected([...eventInstancesSelected, eventInstanceId]);
        }
    }, [eventInstancesSelected]);

    const allAttendeesSelected = React.useMemo(() => (attendeesSelected?.length ?? 0) === (attendees?.length ?? 0), [attendees, attendeesSelected]);

    const onAllAttendeesToggleTick = React.useCallback(() => {
        if (allAttendeesSelected) {
            setAttendeesSelected([]);
        } else {
            setAttendeesSelected(attendees.map((attendee) => attendee.id));
        }
    }, [allAttendeesSelected, attendees]);

    const allEventInstancesSelected = React.useMemo(
        () => (eventInstancesSelected?.length ?? 0) === (order?.eventInstances?.length ?? 0),
        [order?.eventInstances, eventInstancesSelected]
    );

    const onAllEventInstancesToggleTick = React.useCallback(() => {
        if (allEventInstancesSelected) {
            setEventInstancesSelected([]);
        } else if (order?.eventInstances) {
            setEventInstancesSelected(order.eventInstances.map((ei) => ei.eventInstanceId).filter((id) => !!id) as string[]);
        }
    }, [allEventInstancesSelected, order?.eventInstances]);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(
            cancelFromOrder({
                orderId,
                eventInstanceId: eventInstance?.eventInstanceId,
                reasonId: reason,
                otherReason,
                validationAmount,
                attendeesSelected,
                cancelAll: attendees.length === attendeesSelected.length,
                bnplEventInstancesWherePaymentIsStillRequired:
                    paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Full
                        ? eventInstance?.eventInstanceId
                            ? [eventInstance.eventInstanceId]
                            : order?.eventInstances
                                ?.map((ei) => ei.eventInstanceId ?? "")
                                .filter((ei) => !!ei) ?? []
                        : [],
                bnplEventInstancesWherePaymentIsStillHalfRequired:
                    paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Half
                        ? eventInstance?.eventInstanceId
                            ? [eventInstance.eventInstanceId]
                            : order?.eventInstances
                                ?.map((ei) => ei.eventInstanceId ?? "")
                                .filter((ei) => !!ei) ?? []
                        : [],
            })
        );
        setOpen(false);
        setReason(null);
        setOtherReason(null);
        setOtherReasonValid(true);
        setAttendees([]);
        setAttendeesSelected([]);
        setRefreshingRecord(true);
    }, [
        attendees.length,
        attendeesSelected,
        dispatch,
        eventInstance?.eventInstanceId,
        order?.eventInstances,
        orderId,
        otherReason,
        paymentForCancelledPlacesRequired,
        reason,
        setRefreshingRecord,
        validationAmount,
    ]);

    return (
        <>
            <Button disabled={!canCancel} onClick={openCloseAction}>{eventInstance?.eventInstanceId ? "CANCEL PLACES" : "CANCEL ORDER"}</Button>
            <Modal open={open}>
                <Modal.Header>
                    {eventInstance?.eventInstanceId ? "Cancel Course Seats" : "Cancel Order"}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        {!eventInstance?.eventInstanceId && (
                            <Form.Field>
                                Please note completing this action will cancel the entire order this booker has made.
                                If you want to only cancel from a certain course, please use the cancel course button instead.
                            </Form.Field>
                        )}
                        <Form.Select
                            options={[
                                { text: "Booker Decision", value: "BookerDecision" },
                                { text: "TTC Decision", value: "TtcDecision" },
                                { text: "Other", value: "Other" },
                            ]}
                            error={submitted && !reason}
                            value={reason}
                            label={"What is the reason for cancelling?"}
                            onChange={onReasonChange}
                            name="rebookingReason"
                        />
                        {otherCancellationReasonChoosen && (
                            <Input.Textarea
                                value={otherReason}
                                label="Other Reason"
                                validation={[validators.validLength({ min: 1, max: 500 })]}
                                onChange={onOtherReasonChanged}
                            />
                        )}
                        {attendees && attendees.length > 0 && (
                            <>
                                <Form.Field>
                                    {`Please select which delegates you would like to cancel from ${eventInstance.eventTypeName}, ` +
                                    `${eventInstance.eventInstanceStartDate.format(DateFormat.Long)} ` +
                                    `${eventInstance.eventInstanceStartTime.format(DateFormat.Time, { trim: false })}.`}
                                </Form.Field>
                                <Grid className="attendees-grid">
                                    <Grid.Row className="half-padding">
                                        <Grid.Column className="purple-header cancel-modal-purple-header" width={7}>
                                            Name
                                        </Grid.Column>
                                        <Grid.Column className="purple-header cancel-modal-purple-header" width={6}>
                                            Driving Licence Number
                                        </Grid.Column>
                                        <Grid.Column className="purple-header cancel-modal-purple-header" computer={3}>
                                            <Checkbox
                                                checked={allAttendeesSelected}
                                                onClick={onAllAttendeesToggleTick}
                                                label={allAttendeesSelected ? "Untick all" : "Tick all"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {attendees.map((attendee) => (
                                        <CancelModalAttendeeRow
                                            key={`attendee_${attendee.id}`}
                                            attendee={attendee}
                                            selected={attendeesSelected.includes(attendee.id)}
                                            toggleAttendee={toggleAttendee}
                                        />
                                    ))}
                                </Grid>
                                <div className={order?.bookNowPayLater ? "refund-and-summaries" : "refund-and-summaries-align-right"}>
                                    <>
                                        {(!businessLineSupportsHalfRefunds && order?.bookNowPayLater) && <Checkbox
                                            className="refund-checkbox"
                                            toggle
                                            label="Does the Booker/Organisation need to pay for these cancelled seats?"
                                            defaultChecked={paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Full}
                                            onChange={togglePaymentForCancelledPlacesRequired}
                                        />}
                                        {
                                            (businessLineSupportsHalfRefunds && order?.bookNowPayLater) && <Form.Select
                                                options={[
                                                    { text: "0%", value: OrderRefundTypeEnum.None },
                                                    { text: "50%", value: OrderRefundTypeEnum.Half },
                                                    { text: "100%", value: OrderRefundTypeEnum.Full },
                                                ]}
                                                value={paymentForCancelledPlacesRequired}
                                                label={"How much does the Booker/Organisation need to pay for these cancelled seats?"}
                                                onChange={changePaymentForCancelledPlacesRequired}
                                            />
                                        }
                                    </>
                                    <div className="summaries">
                                        <span>Number of delegates selected: {attendeesSelected.length}</span>
                                        <span>Number of delegates overall: {attendees.length}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {order?.bookNowPayLater && !eventInstance && <>
                            <Form.Field>
                                Please select event instaces for which payment is still needed
                            </Form.Field>
                            <Grid className="attendees-grid">
                                <Grid.Row className="half-padding">
                                    <Grid.Column className="purple-header cancel-modal-purple-header" width={7}>
                                        Product
                                    </Grid.Column>
                                    <Grid.Column className="purple-header cancel-modal-purple-header" width={6}>
                                        Date and time
                                    </Grid.Column>
                                    <Grid.Column className="purple-header cancel-modal-purple-header" computer={3}>
                                        <Checkbox
                                            checked={allEventInstancesSelected}
                                            onClick={onAllEventInstancesToggleTick}
                                            label={allEventInstancesSelected ? "Untick all" : "Tick all"}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {order?.eventInstances?.map((orderEventInstance) => (
                                    <CancelModalEventInstancePaymentRow
                                        key={`ei_${orderEventInstance.eventInstanceId}`}
                                        eventInstance={orderEventInstance}
                                        selected={eventInstancesSelected.includes(orderEventInstance.eventInstanceId ?? "")}
                                        toggleEventInstance={toggleEventInstance}
                                    />
                                ))}
                            </Grid>
                        </>}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        disabled={!reason || (otherCancellationReasonChoosen && (!otherReason || !otherReasonValid))
                            || (eventInstance?.eventInstanceId && attendeesSelected.length === 0)}
                        loading={submitted}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Confirm Cancellation"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
