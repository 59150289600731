import { applyMiddleware, createStore, combineReducers, StoreEnhancer } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { routerForBrowser } from "redux-little-router";
import { RootReducer } from "@common/redux-helpers";
import { middlewares } from "@common/startup/middleware";

export const configureStore = (reducers: RootReducer<any>, routes: any) => {
    const {
        reducer,
        middleware,
        enhancer
    } = routerForBrowser({
        routes
    });

    return createStore(
        combineReducers({
            ...reducers,
            router: reducer
        }),
        composeWithDevTools(
            enhancer,
            applyMiddleware(...middlewares, middleware) as StoreEnhancer
        )
    );
};
