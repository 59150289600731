import { BusinessLineType } from "@common/redux-helpers";
import axios from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import { toast } from "react-toastify";
import { NudgeTaskCompleteModel, NudgeTaskTypeModel, TaskStatusEnum } from "../common/NudgeTaskBaseModels";
import { AlaskaNudgeTaskDetailModel, AlaskaNudgeTaskSearchOptions,
    TaskRelationEnum, AlaskaNudgeTaskListResponse, AlaskaNudgeStatsCategoryOverviewModel, AlaskaNudgeTaskCategoryEnum } from "./model";

export class AlaskaNudgeTaskApi {

    private apiUrl: string;

    constructor() {
        this.apiUrl = "/api/alaskanudgetask";
    }

    public async getAll(options: AlaskaNudgeTaskSearchOptions): Promise<AlaskaNudgeTaskListResponse> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as AlaskaNudgeTaskListResponse;
        data.results = data.results.map(this.parseModel);
        return data;
    }

    public async getTrainersInterestedNumber(taskType: number): Promise<number> {
        const response = await axios.get(`${this.apiUrl}/trainers-interested-number/${taskType}`);
        const data = response.data as number;
        return data;
    }

    public async getUrgentNudgeCategories(businessLineType: BusinessLineType): Promise<AlaskaNudgeTaskCategoryEnum[]> {
        const response = await axios.get<AlaskaNudgeTaskCategoryEnum[]>(`${this.apiUrl}/urgent-nudge-categories?businessLineType=${businessLineType}`);
        return response.data;
    }

    public async getStatsByCategory(category: AlaskaNudgeTaskCategoryEnum, businessLineType: BusinessLineType,
        state?: TaskStatusEnum): Promise<AlaskaNudgeStatsCategoryOverviewModel[]> {
        const response =await axios.get<AlaskaNudgeStatsCategoryOverviewModel[]>(
            `${this.apiUrl}/stats-overview-by-category?category=${category}&state=${state ?? TaskStatusEnum.Any}&businessLineType=${businessLineType}`);
        return response.data;
    }

    public async resubmitStatusesToDors(options: AlaskaNudgeTaskSearchOptions): Promise<void> {
        await axios.post(`${this.apiUrl}/resubmitStatusesToDors`, null, { params: options });
    }

    public async resubmitCoursesToDors(currentUrl: string): Promise<void> {
        await axios.post(`${this.apiUrl}/resubmitCoursesToDors`, { currentUrl });
        toast.info("Course resubmissions will be processed in the background. You will receive a notification when completed.");
    }

    public async resubmitTrainersToDors(currentUrl: string): Promise<void> {
        await axios.post(`${this.apiUrl}/resubmitTrainersToDors`, { currentUrl });
        toast.info("Course resubmissions will be processed in the background. You will receive a notification when completed.");
    }

    public async addZoomAccounts(currentUrl: string, searchOptions: AlaskaNudgeTaskSearchOptions): Promise<void> {
        await axios.post(`${this.apiUrl}/addZoomAccounts`, { currentUrl, searchOptions });
        toast.info("Adding zoom accounts will be processed in the background. You will receive a notification when completed.");
    }

    public async detail(id: string): Promise<AlaskaNudgeTaskDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as AlaskaNudgeTaskDetailModel;
        return this.parseModel(model);
    }

    public async complete(nudgeTask: NudgeTaskCompleteModel) {
        await axios.put(this.apiUrl, nudgeTask);
    }

    public async markAsInvoiceProcessed(nudgeTaskIds: string[]) {
        await axios.put(`${this.apiUrl}/markAsInvoiceProcessed`, nudgeTaskIds);
    }

    public async markAsOrderInvoiceProcessed(nudgeTaskIds: string[]) {
        await axios.put(`${this.apiUrl}/markAsOrderInvoiceProcessed`, nudgeTaskIds);
    }

    public async getTaskTypes(businessLineType: BusinessLineType): Promise<NudgeTaskTypeModel[]> {
        const response = await axios.get(`${this.apiUrl}/taskTypes?businessLineType=${businessLineType}`);
        return response.data as NudgeTaskTypeModel[];
    }

    public async getAttendeeOrBookingLink(correlationId: string, taskType: number): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/get-attendee-url/${correlationId}?taskType=${taskType}`);
        return response.data as string;
    }

    public async changeAssignedCa(taskId: string, email: string) {
        await axios.post(`${this.apiUrl}/${taskId}/changeAssignedCA?email=${email}`);
    }

    public async changeAllocatedSupervisor(taskId: string, email: string) {
        await axios.post(`${this.apiUrl}/${taskId}/changeAllocatedSupervisor?email=${email}`);
    }

    public async getAllCaEmails(): Promise<string[]> {
        const response = await axios.get(`${this.apiUrl}/caEmails`);
        return response.data;
    }

    public async getAllSeniorCaEmails(): Promise<string[]> {
        const response = await axios.get(`${this.apiUrl}/seniorCaEmails`);
        return response.data;
    }

    public async getAllCorporateAdminEmails(): Promise<string[]> {
        const response = await axios.get(`${this.apiUrl}/corporateAdminEmails`);
        return response.data;
    }

    public parseModel(model: AlaskaNudgeTaskDetailModel): AlaskaNudgeTaskDetailModel {
        return {
            ...model,
            name: model.taskRelatedTo === TaskRelationEnum.EventInstance ? model.eventInstanceName : model.attendeeName,
            startDate: model.startDate && moment.utc(model.startDate),
            deadline: model.deadline && moment.utc(model.deadline),
            dropDeadDate: model.dropDeadDate && moment.utc(model.dropDeadDate),
            completedDate: model.completedDate && moment.utc(model.completedDate),
            eventInstanceDeliveryDate: model.eventInstanceDeliveryDate && moment.utc(model.eventInstanceDeliveryDate),
            attendeeCompletionDate: model.attendeeCompletionDate && moment.utc(model.attendeeCompletionDate)
        };
    }
}
