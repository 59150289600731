import { Location } from "redux-little-router";
import { Moment } from "moment";

import { Address } from "../common/Address";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { HistoryRecord } from "@common/history/model";
import { CountryEnum, VenueTypeEnum } from "../organisation/model";
import { AllowedWorkflowTypeEnum } from "../eventType/model";
import { createValidator } from "not-valid";

export interface VenueListModel {
    readonly id: string;
    readonly name?: string;
    readonly attributes?: VenueAttribute[];
    readonly listUpdated?: Moment;
    readonly expiryDate?: Moment;
    readonly address?: Address;
    readonly dorsId?: number;
    readonly distance?: number;
    readonly forceId?: number;
    readonly workflowTypes?: AllowedWorkflowTypeEnum[];
    readonly dsaAreaId?: number;
    readonly referredCourtCountry?: CountryEnum;
    venueType?: Exclude<VenueTypeEnum, VenueTypeEnum.None>;
}

export interface VenueDistanceModel extends VenueListModel {
    readonly distanceInMiles?: number;
}

export interface VenueDetailModel {
    readonly id: string;
    readonly name?: string;
    readonly address?: Address;
    readonly contactName?: string;
    readonly email?: string;
    readonly telephoneNumber?: string;
    readonly detailUpdated?: Moment;
    readonly attributes?: VenueAttribute[];
    readonly dorsId?: number;
    readonly expiryDate?: Moment;
    readonly forceId?: number;
    readonly organisationId?: string;
    readonly noteEn?: string;
    readonly noteCy?: string;
    readonly venueFee?: number;
    readonly feeNote?: string;
    readonly deliveryType?: DeliveryTypeEnum;
    readonly history?: HistoryRecord[];
    readonly organisationName?: string;
    readonly workflowTypes?: AllowedWorkflowTypeEnum[];
    readonly dsaAreaName?: string;
    readonly referredCourtCountry?: CountryEnum;
    readonly maxCapacity?: number;
    venueType?: Exclude<VenueTypeEnum, VenueTypeEnum.None>;
}

export type Venue = VenueListModel & VenueDetailModel & VenueDistanceModel;

export interface VenueCreateEditModel {
    id: string;
    name: string;
    address: Address;
    contactName: string;
    email: string;
    telephoneNumber: string;
    attributes: VenueAttribute[];
    dorsId?: number;
    expiryDate?: Moment;
    forceId?: number;
    organisationId?: string;
    noteEn?: string;
    noteCy?: string;
    venueFee?: number;
    feeNote?: string;
    deliveryType?: DeliveryTypeEnum;
    workflowTypes?: AllowedWorkflowTypeEnum[];
    dsaAreaId?: number;
    maxCapacity?: number;
    referredCourtCountry?: CountryEnum;
    venueType?: Exclude<VenueTypeEnum, VenueTypeEnum.None>;
}

export interface VenueState {
    readonly venues: Venue[];
}

export class VenueBuilder {
    private id: string;
    private name: string;
    private address: Address;
    private contactName: string;
    private email: string;
    private telephoneNumber: string;
    private attributes: VenueAttribute[];
    private dorsId: number;
    private expiryDate?: Moment;
    private forceId?: number;
    private organisationId?: string;
    private noteEn?: string;
    private noteCy?: string;
    private venueFee?: number;
    private dsaAreaId?: number;

    constructor() {
        this.name = "Test 1";
        this.address = {
            addressLine1: "Test 1",
            addressLine2: "Test 1",
            addressLine3: "Test 1",
            city: "Test 1",
            postalCode: "Test 1"
        };
        this.contactName = "Test 1";
        this.email = "Test 1";
        this.telephoneNumber = "Test 1";
        this.attributes = [0];
        this.dorsId = 0;
        this.organisationId = "Test 1";
        this.noteEn = "Test1";
        this.noteCy = "Test1";
        this.venueFee = 0;
    }

    public withId(id: string): VenueBuilder {
        this.id = id;
        return this;
    }

    public withName(name: string): VenueBuilder {
        this.name = name;
        return this;
    }

    public withaddress(address: Address): VenueBuilder {
        this.address = address;
        return this;
    }

    public withcontactName(contactName: string): VenueBuilder {
        this.contactName = contactName;
        return this;
    }

    public withemail(email: string): VenueBuilder {
        this.email = email;
        return this;
    }

    public withtelephoneNumber(telephoneNumber: string): VenueBuilder {
        this.telephoneNumber = telephoneNumber;
        return this;
    }

    public withAttributes(attributes: VenueAttribute[]): VenueBuilder {
        this.attributes = attributes;
        return this;
    }

    public withDorsId(dorsId: number): VenueBuilder {
        this.dorsId = dorsId;
        return this;
    }

    public withExpiryDate(expiryDate: Moment): VenueBuilder {
        this.expiryDate = expiryDate;
        return this;
    }

    public withForceId(forceId: number): VenueBuilder {
        this.forceId = forceId;
        return this;
    }

    public withOrganisationId(organisationId: string): VenueBuilder {
        this.organisationId = organisationId;
        return this;
    }

    public withNoteEn(noteEn: string): VenueBuilder {
        this.noteEn = noteEn;
        return this;
    }

    public withNoteCy(noteCy: string): VenueBuilder {
        this.noteEn = noteCy;
        return this;
    }

    public withVenueFee(fee: number): VenueBuilder {
        this.venueFee = fee;
        return this;
    }
    public withDsaAreaId(dsaAreaId: string): VenueBuilder {
        this.organisationId = dsaAreaId;
        return this;
    }
    public build(): VenueCreateEditModel {
        return {
            id: this.id,
            name: this.name,
            address: this.address,
            contactName: this.contactName,
            email: this.email,
            telephoneNumber: this.telephoneNumber,
            attributes: this.attributes,
            dorsId: this.dorsId,
            expiryDate: this.expiryDate,
            forceId: this.forceId,
            organisationId: this.organisationId,
            noteEn: this.noteEn,
            noteCy: this.noteCy,
            venueFee: this.venueFee,
            dsaAreaId: this.dsaAreaId
        };
    }

}

export enum VenueAttribute {
    None = 0,
    HearingLoop = 1,
    BreastfeedingRoom = 2,
    AreaAvailableForPrayer = 3,
    Projector = 4,
    CloseToPublicTransport = 5,
    DisabledAccess = 6
}

export const VenueAttributes = {
    [VenueAttribute.None]: "None",
    [VenueAttribute.HearingLoop]: "Hearing Loop",
    [VenueAttribute.BreastfeedingRoom]: "Breastfeeding Room",
    [VenueAttribute.AreaAvailableForPrayer]: "Area Available For Prayer",
    [VenueAttribute.Projector]: "Projector",
    [VenueAttribute.CloseToPublicTransport]: "Close To Public Transport",
    [VenueAttribute.DisabledAccess]: "Disabled Access"
};

export const VenueAttributePictures = {
    [VenueAttribute.None]: "",
    [VenueAttribute.HearingLoop]: "/assets/hearing-loop.png",
    [VenueAttribute.BreastfeedingRoom]: "/assets/breastfeeding-room.png",
    [VenueAttribute.AreaAvailableForPrayer]: "/assets/prayer-room.png",
    [VenueAttribute.Projector]: "/assets/projector.png",
    [VenueAttribute.CloseToPublicTransport]: "/assets/public-transport.png",
    [VenueAttribute.DisabledAccess]: "/assets/disabled-access.png"
};

export const workflowTypesValidator = () => {
    return createValidator<AllowedWorkflowTypeEnum[]>(v => Array.isArray(v) && v.length > 0, "A venue shold have at least one workflow type.");
};

export type AppState = VenueState & { router: Location };

export interface SearchOptions {
    name?: string;
    forceId?: number;
    deliveryType?: number;
    venueType?: number;
    organisationId?: string;
    dsaAreaId?: number;
    showExpired?: boolean;
    workflows?: number[];
    venueTypes?: VenueTypeEnum[];
}

export interface DorsSiteModel {
    readonly siteId: number;
    readonly siteName: string;
}

export const constructionVenueTypeOptions = [
    { text: "Any", value: undefined },
    { text: "Shared", value: VenueTypeEnum.ConstructionShared },
    { text: "Organisation Specific", value: VenueTypeEnum.ConstructionOrganisationSpecific },
    { text: "Delegate Home", value: VenueTypeEnum.DelegateHome },
    { text: "Enquiry", value: VenueTypeEnum.Enquiry }
];
