import { createSelector } from "reselect";

import { User, AppState, PoliceUserEditModel } from "./model";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.userId;
};

export type SelectorState = AppState;

export const usersSelector = (state: AppState) => state.policeUsers;

export const userSelector = createSelector(
    usersSelector,
    routeIdSelector,
    (users: User[], id: string) => {
        return users.filter(c => c.id === id)[0] || {} as any;
    }
);

export const userEditModelSelector = createSelector(
    userSelector,
    user => {
        return user as PoliceUserEditModel;
    }
);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("users") + "users".length);

export const userMapSelector = createSelector(
    usersSelector,
    (users: User[]) => {
        const idNamePairs = users.map(u => [u.id, u.fullName]) as [string, string][];
        return new Map(idNamePairs);
    }
);
