import * as React from "react";
import { useTranslation } from "react-i18next";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { EventInstance } from "@common/crud/eventInstance";
import { BookingPaymentsInfo } from "@common/payments/model";
import { Venue } from "@common/crud/venue";
import { EventType } from "@common/crud/eventType/model";
import { Language, LanguageCode, LanguageEnum } from "@common/crud/eventInstance/model";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DorsBooking } from "@common/crud/dorsBooking";
import { Booking } from "../models";
import { duration } from "moment";
import { OrganisationDetailModel } from "@common/crud/organisation/model";
import { TOptions } from "i18next";

interface PrintedBookingConfirmationBodyProps {
    booking: Booking | DorsBooking;
    bookingPaymentsInfo: BookingPaymentsInfo;
    eventInstance: EventInstance;
    eventInstanceGroup: EventInstanceGroupModel;
    eventType: EventType;
    language: LanguageEnum.English | LanguageEnum.Welsh;
    venue: Venue;
    force: OrganisationDetailModel;
    pageBreakAfter?: boolean;
}

export function PrintedBookingConfirmationBody({
    booking,
    bookingPaymentsInfo,
    language,
    eventInstance,
    eventInstanceGroup,
    eventType,
    venue,
    force,
    pageBreakAfter = false
}: PrintedBookingConfirmationBodyProps) {
    const languageCode = LanguageCode[language];
    const [t] = useTranslation("PrintedBookingConfirmation");
    const translate = (key: string, options?: TOptions) => t(key, { ...options, lng: languageCode });

    const address = venue?.address;

    const venueAddress = `${[
        address?.addressLine1,
        address?.addressLine2,
        address?.addressLine3,
        address?.city].filter(x => x).join(", ")} ${address?.postalCode}`;

    function displayPrice(priceInPence: number) {
        return `£${((priceInPence || 0) / 100).toFixed(2)}`;
    }

    function getLanguageProperty(obj: any, key: string) {
        const languageSuffix = languageCode.charAt(0).toUpperCase() + languageCode.slice(1);
        return obj?.[`${key}${languageSuffix}`];
    }
    const isDigital = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;

    let eventDetails;
    let whitespaceHeight;
    if (isDigital) {
        eventDetails  = eventType.digitalEventTypeDetails;
        whitespaceHeight= eventDetails.digitalWhitespaceHeight;
    }
    else {
        eventDetails  = eventType.classroomEventTypeDetails;
        whitespaceHeight= eventDetails.whitespaceHeight;
    }

    function getFormattedEiGroupItemTime(item: EventInstanceGroupItemModel) {
        const longDate = item.startDate?.format(DateFormat.Long);
        const startTime = item.startTime?.format(DateFormat.Time, { trim: false });
        const endTime = item.startTime?.clone()?.add(item.eventDuration)?.format(DateFormat.Time, { trim: false });

        return `${longDate}, ${startTime} - ${endTime}`;
    }

    function getFormattedEventInstanceTime() {
        const courseStartTime = eventInstance.startTime?.format(DateFormat.Time, { trim: false });
        const courseEndTime = eventInstance.startTime?.clone()?.add(eventInstance?.eventDuration || duration(0))?.format(DateFormat.Time, { trim: false });
        return `${courseStartTime} - ${courseEndTime}`;
    }

    function getTermsAndConditionsLink() {
        let link = getLanguageProperty(eventInstance, "termsAndConditionsLink");;
        if (force && force.organisationTermsAndConditions) {
            if (languageCode === "en" && force.termsAndConditionsLink) {
                link = force.termsAndConditionsLink;
            }

            if (languageCode === "cy" && force.termsAndConditionsLinkCy) {
                link = force.termsAndConditionsLinkCy;
            }
        }
        return (
            <a href={link}>
                {link}
            </a>
        );
    }

    return (
        <>
            <p>{translate("SALUTATION", { forename: booking.forename })}</p>
            <HtmlRenderer
                value={getLanguageProperty(eventDetails, isDigital ? "digitalBookingConfirmationLetterOpening" : "bookingConfirmationLetterOpening")}
            />
            <div className="course-details">
                <h3>{translate("COURSE_DETAILS")}</h3>
                <div className={`whitespace-${whitespaceHeight}`}>
                    <hr />
                    <LabelAndValue label={translate("BOOKING_REFERENCE")} value={booking.attendanceId} />
                    <LabelAndValue label={translate("COURSE_TYPE")} value={language === LanguageEnum.Welsh
                        ? (eventInstance.eventTypeNameCy ?? eventInstance.eventTypeName)
                        : eventInstance.eventTypeName} />

                    {eventInstanceGroup && eventInstanceGroup.eventInstanceGroupItems.sort((a, b) => a.dayNumber - b.dayNumber).map((item, i) => (
                        <LabelAndValue key={i} label={`Day ${item.dayNumber}`} value={getFormattedEiGroupItemTime(item)} />
                    ))}

                    {!eventInstanceGroup &&
                    <>
                        <LabelAndValue label={translate("COURSE_DATE")} value={eventInstance.startDate?.locale(languageCode).format(DateFormat.Long)} />
                        <LabelAndValue label={translate("COURSE_TIME")} value={getFormattedEventInstanceTime()} />
                    </>
                    }

                    {venue && eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
                    <LabelAndValue label={translate("COURSE_LOCATION")} value={venueAddress} />}
                    <LabelAndValue label={translate("COURSE_LANGUAGE")} value={translate(`${Language[eventInstance?.language]}`)} />
                    {bookingPaymentsInfo?.totalAmountDue > 0 &&
                    <LabelAndValue label={translate("OUTSTANDING_BALANCE")} value={displayPrice(bookingPaymentsInfo?.totalAmountDue)} />}
                    {getLanguageProperty(eventInstance, "note") &&
                    <LabelAndValue label={translate("VENUE_NOTE")} value={getLanguageProperty(eventInstance, "note")} />}
                    {getLanguageProperty(eventInstance, "bookingNote") &&
                    <LabelAndValue label={translate("BOOKING_NOTE")} value={getLanguageProperty(eventInstance, "bookingNote")} />}
                    <LabelAndValue label={translate("TERMS_AND_CONDITIONS")} value={getTermsAndConditionsLink()} />
                </div>
            </div>
            {isDigital &&
                <div className={`course-details whitespace-${whitespaceHeight}`}>
                    <h3>{translate("ZOOM_DETAILS")}</h3>
                    <hr />
                    <LabelAndValue label={translate("DIGITAL_COURSE_LINK")} value={eventInstance.digitalCourseLink} />
                    <LabelAndValue label={translate("DIGITAL_COURSE_MEETING_ID")} value={eventInstance.digitalCourseMeetingId} />
                    <LabelAndValue label={translate("DIGITAL_COURSE_PASSWORD_ATTENDEE")} value={eventInstance.digitalCoursePasswordAttendee} />
                </div>
            }
            {booking?.canUseFlexiPay &&
                <div className={`course-details whitespace-${whitespaceHeight}`}>
                    <h3>{translate("FLEXI_PAY")}</h3>
                    <hr />
                    <p>{getLanguageProperty(eventType, "flexiPayInstructions")}</p>
                </div>
            }
            <HtmlRenderer
                value={getLanguageProperty(eventDetails, isDigital ? "digitalBookingConfirmationLetterClosing" : "bookingConfirmationLetterClosing")}
            />
            <p>{translate("VALEDICTION")}</p>
            <p className={pageBreakAfter? "page-break-after": undefined}>TTC</p>
        </>
    );
}
