import { State as RouterState } from "redux-little-router";
import { createSelector } from "reselect";
import { BusinessLineType } from "./model";

export const sourcePathSelector = ({ router }: RouterState) => {
    if (router.previous) {
        return router.previous.pathname;
    }
    return router.pathname.substring(0, router.pathname.lastIndexOf("/"));
};

export const organisationIdSelector = ({ router }: RouterState): string => router.params.organisationId;

export const userIdSelector = ({ router }: RouterState): string => router.params.userId;

export const fleetIdSelector = ({ router }: RouterState): string => router.params.fleetId;

const createPathSegment = (entityPath: string, entityId: string) => entityId ? `/${entityPath}/${entityId}` : "";

export const organisationRootPathSelector = createSelector(
    organisationIdSelector,
    orgId => createPathSegment("organisations", orgId));

export const fleetRootPathSelector = createSelector(
    fleetIdSelector,
    organisationIdSelector,
    (fleetId, orgId) => createPathSegment("organisations", orgId) + createPathSegment("fleets", fleetId));

export const buildApiRootPath = ({ organisationId, userId }: { organisationId?: string; userId?: string }) => {
    const apiPath = "/api";
    const orgPath = createPathSegment("organisation", organisationId);
    const userPath = createPathSegment("user", userId);
    return apiPath + orgPath + userPath;
};

export const apiRootPathSelector = createSelector(
    organisationIdSelector,
    userIdSelector,
    (organisationId, userId) => buildApiRootPath({ organisationId, userId }));

export const createBasePathSelector = (pathSegment: string) =>
    ({ router }: RouterState) =>
        router.pathname.substring(0, router.pathname.indexOf(pathSegment) + pathSegment.length);

export const businessLineTypeSelector = ({ router }: RouterState): BusinessLineType => {
    if (router.pathname.indexOf("/corporate-event-management") !== -1) {
        return BusinessLineType.Corporate;
    }

    if (router.pathname.indexOf("/police-and-court-event-management") !== -1) {
        return BusinessLineType.PoliceAndCourt;
    }

    if (router.pathname.indexOf("/construction-event-management") !== -1) {
        return BusinessLineType.Construction;
    }

    return BusinessLineType.Unknown;
};

export const trainerScheduleBusinessLineType = ({ router }: RouterState): BusinessLineType => {
    if (router.pathname.indexOf("/schedule") !== -1 || router.pathname.indexOf("/schedule-drink-drive") !== -1) {
        return BusinessLineType.PoliceAndCourt;
    }

    if (router.pathname.indexOf("/schedule-corp") !== -1) {
        return BusinessLineType.Corporate;
    }

    if (router.pathname.indexOf("/schedule-construction") !== -1) {
        return BusinessLineType.Construction;
    }

    return BusinessLineType.Unknown;
};

export const universalEventManagementPathSelector = ({ router }: RouterState): string => {
    if (router.pathname.indexOf("corporate-event-management") !== -1) {
        return router.pathname.substring(0, router.pathname.indexOf("corporate-event-management") + "corporate-event-management".length);
    }

    if (router.pathname.indexOf("police-and-court-event-management") !== -1) {
        return router.pathname.substring(0, router.pathname.indexOf("police-and-court-event-management") + "police-and-court-event-management".length);
    }

    if (router.pathname.indexOf("construction-event-management") !== -1) {
        return router.pathname.substring(0, router.pathname.indexOf("construction-event-management") + "construction-event-management".length);
    }

    return undefined;
};
