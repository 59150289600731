import * as React from "react";
import { ExtendedBaseInput, ExtendedBaseInputSharedProps } from "./ExtendedBaseInput";
import { validators } from "not-valid";

export interface ExtendedEmailInputProps extends ExtendedBaseInputSharedProps<string> {
    validate?: boolean;
}

export class ExtendedEmailInput extends React.Component<ExtendedEmailInputProps> {

    public render() {
        const StringBaseInput = ExtendedBaseInput as unknown as new () => ExtendedBaseInput<string>;

        const props = { ...this.props };

        if (this.props.validate !== false) {
            props.validation = props.validation === undefined
                ? [validators.validEmail()]
                : [validators.validEmail(), ... props.validation];
        }

        return (
            <StringBaseInput
                defaultValue={""}
                getValueFromInput={this.getValueFromInput}
                type="email"
                autoComplete="email"
                disableAutocapitalize={true}
                disableAutocorrect={true}
                requiredValidator={validators.requiredString()}
                {...props}
            />
        );
    }

    private getValueFromInput = (value: string) => value;
}
