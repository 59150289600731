import * as React from "react";
import { Icon, Label, Menu, Tab } from "semantic-ui-react";
import { HistoryVersion } from "@common/history/model";
import { CoreDetailProps } from "../Detail";
import { DetailsTab } from "./DetailsTab";
import { HistoryTab } from "@common/history/components/HistoryTab";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "@common/crud/dorsBooking/actions";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { FileDetailsTab } from "./FileDetailsTab";
import { DrinkDriveOffenderApi } from "../../drinkDriveOffenderApi";

enum TabNames {
    Details = "Details",
    Files = "Files",
    History = "Client Journey",
}

export const CoreDetails: React.FC<CoreDetailProps> = ({ drinkDriveOffender, internalAppSettings }) => {
    const dispatch = useDispatch();
    const app = useSelector(appSelector);
    const showDetailsDefault = app === Apps.Admin;
    const [documentsCount, setDocumentsCount] = React.useState<number>(0);

    const hasActivePendingNotes = drinkDriveOffender?.historyRecords &&
        drinkDriveOffender?.historyRecords.some(ahr => ahr.isPendingNote === true && ahr.isPendingNoteCompleted === false);

    const loadAttachedDocuments = React.useCallback(async () => {
        const api = new DrinkDriveOffenderApi();
        const response = await api.getAttachedDocumentsCount(drinkDriveOffender.id);
        setDocumentsCount(response);
    }, [drinkDriveOffender?.id]);

    React.useEffect(() => {
        loadAttachedDocuments();
    }, [drinkDriveOffender.id, loadAttachedDocuments]);

    const getBookingHistory = React.useCallback(() => dispatch(getHistory(drinkDriveOffender?.id)), [drinkDriveOffender, dispatch]);

    const panes =
        [
            {
                menuItem: TabNames.Details,
                render: () => (
                    <>
                        <DetailsTab drinkDriveOffender={drinkDriveOffender} internalAppSettings={internalAppSettings} />
                    </>
                )
            },
            {
                menuItem: (
                    <Menu.Item
                        onClick={getBookingHistory}>
                        {TabNames.History} {hasActivePendingNotes && <Icon className="flag alternate vertical icon" color={"red"} />}
                    </Menu.Item>
                ),
                render: () => (
                    <HistoryTab
                        showDetailsDefault={showDetailsDefault}
                        historyRecords={drinkDriveOffender?.historyRecords}
                        correlationId={drinkDriveOffender.id}
                        historyVersion={HistoryVersion.V2}
                        relatedTo={TaskRelationEnum.DdrsBooking}
                        eventTypeCategory={EventTypeCategory.Ddrs} />
                )
            },
            {
                menuItem: (
                    <Menu.Item>
                        {TabNames.Files} <Label circular content={documentsCount} />
                    </Menu.Item>
                ),
                render: () => (
                    <FileDetailsTab
                        bookingId={drinkDriveOffender.id}
                        downloadUrl="/api/drinkDriveOffender/download"
                        openUrl="/api/drinkDriveOffender/open"
                        filesUpdated={() => loadAttachedDocuments()}
                    />
                )
            }
        ];

    return (
        <Tab panes={panes} />
    );
};
