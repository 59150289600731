import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const AvoidableThingsSection: QuestionSection = {
    id: "5",
    title: "Module 2",
    seqNum: 4,
    subSections: [{
        id: "5.1",
        text: "Why do avoidable things happen? 15 min",
        seqNum: 1,
        criteria: [
            { id: "1", text: "6 behaviours briefly explained." },
            { id: "2", text: "Hassles" },
            { id: "3", text: "Minor collision resulting from one of the 6 behaviours" },
            { id: "4", text: "Worst case scenario" },
            { id: "5", text: "Write down and hold up explanations previously heard" },
            { id: "6", text: "Course map as per manual description" },
            { id: "7", text: "What do you want to talk about? Write down and hold up to screen." },
            { id: "8", text: "Trainer to note down" }
        ]
    }],
    summaryCriterium: [{ id: "9", text: "Explanatory Comments (Mandatory)", typeEnum: CriteriaEnum.text }]
};
