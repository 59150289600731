import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { EventInstance } from "../model";
import { useDispatch, useSelector } from "react-redux";
import { updateReasonForHidingEvent } from "../actions";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { UPDATE_REASON_FOR_HIDING_EVENT } from "../actiontypes";
import { FreeRebookingFormField } from "@common/crud/common/FreeRebookingFormField";
import { AttendeeApi } from "@common/crud/attendee";
import { isEventInstanceWorkflowPoliceOrCourt } from "@common/global/CommonHelpers";

interface Props {
    eventInstance: EventInstance;
}

export const HideEventInstanceButton: React.FC<Props> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>();
    const [reasonForHidingEvent, setReasonForHidingEvent] = React.useState<string>();
    const [reasonForHidingEventValid, setReasonForHidingEventValid] = React.useState<boolean>();
    const isLoading = useSelector((state: LoadingState) => isRequestActive(state, UPDATE_REASON_FOR_HIDING_EVENT));
    const [freeRebookingEnabled, setFreeRebookingEnabled] = React.useState(false);
    const isPoliceOrCourt = React.useMemo(() => isEventInstanceWorkflowPoliceOrCourt(eventInstance), [eventInstance]);

    const onReasonForHidingEventChanged = React.useCallback((value: string, valid: boolean) => {
        setReasonForHidingEvent(value);
        setReasonForHidingEventValid(valid);
    }, []);

    const onCancelModal = React.useCallback(() => {
        setReasonForHidingEvent(null);
        setFreeRebookingEnabled(false);
        setIsModalOpen(false);
    }, []);

    const onSubmit = React.useCallback(async () => {
        if (reasonForHidingEventValid) {
            await dispatch(updateReasonForHidingEvent(eventInstance.id, reasonForHidingEvent));
            if (freeRebookingEnabled) {
                await new AttendeeApi(eventInstance.id).bulkUpdateFreeRebooking(true);
            }
            setIsModalOpen(false);
        }
    }, [reasonForHidingEventValid, reasonForHidingEvent, freeRebookingEnabled, eventInstance.id, dispatch]);

    const onHideEventInstanceClick = React.useCallback(() => setIsModalOpen(true), []);

    return (
        <>
            <Button className="irregular-positive" content="Hide Course" onClick={onHideEventInstanceClick} />
            <Modal open={isModalOpen}>
                <Modal.Content>
                    <Form onSubmit={onSubmit} loading={isLoading}>
                        <Input.Textarea
                            required
                            label="Reason for hiding Course"
                            value={reasonForHidingEvent}
                            onChange={onReasonForHidingEventChanged}
                        />
                        {(isPoliceOrCourt && reasonForHidingEventValid) &&
                            <FreeRebookingFormField
                                label="Add free rebooking to all attendees?"
                                freeRebookingEnabled={freeRebookingEnabled}
                                setFreeRebookingEnabled={setFreeRebookingEnabled}
                            />
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onCancelModal}>Cancel</Button>
                    <Button primary onClick={onSubmit} disabled={!reasonForHidingEventValid}>Hide Course</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
