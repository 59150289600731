import * as React from "react";
import { connect } from "react-redux";
import { LoadingState } from "redux-request-loading";
import { push } from "redux-little-router";
import { Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import { sourcePathSelector } from "@common/redux-helpers/selectors";
import { ConfigurationState } from "@common/crud/configuration/model";
import { hierarchySelector } from "@common/crud/configuration/selectors";
import { AppCommonState } from "@common/appCommonState";
import { selectors as organisationSelectors, OrganisationState } from "../../organisation";
import { PoliceUserEditModel, AppState } from "../model";
import { userEditModelSelector } from "../selectors";
import { saveUser } from "../actions";

import { EditModal } from "./EditModal";

export interface EditProps extends SharedEditProps<PoliceUserEditModel> {
    open: boolean;
    loading: boolean;
    hierarchy: string[];
}

export interface DispatchProps extends SaveDispatchProps<PoliceUserEditModel> {
    close: () => void;
}

export interface EditState extends FormState<PoliceUserEditModel> {
    enableLeavingDate: boolean;
}

export class EditForm extends EditComponent<PoliceUserEditModel, EditProps, EditState> {
    constructor(props: EditProps & DispatchProps) {
        super(props);
        this.updateProperty = this.updateProperty.bind(this);
        this.updateNestedProperty = this.updateNestedProperty.bind(this);
    }
    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>

                <Input.Text
                    value={this.state.values.forename}
                    label="Forename"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("forename", value, valid)}
                />
                <Input.Text
                    value={this.state.values.surname}
                    label="Surname"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("surname", value, valid)}
                />
                <Input.Email
                    value={this.state.values.email}
                    label="Email"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("email", value, valid)}
                />
            </Form>
        );
    }

    public submit(): void {
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    }
}

function mapStateToProps(state: AppState & AppCommonState & OrganisationState & LoadingState & ConfigurationState) {
    return {
        model: userEditModelSelector(state),
        open: state.router.pathname.endsWith("/edit"),
        sourcePath: sourcePathSelector(state),
        loading: state.loading.active,
        organisationId: organisationSelectors.routeIdSelector(state),
        hierarchy: hierarchySelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (user: PoliceUserEditModel, organisationId: string, path: string) => dispatch(saveUser({ user, organisationId, path })),
        dispatchClose: (sourcePath: string) => dispatch(push(sourcePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {
    const { model, open, sourcePath, loading, organisationId, hierarchy } = propsFromState;
    const { dispatchSave, dispatchClose } = propsFromDispatch;
    return {
        model,
        open,
        loading,
        save: user => dispatchSave(user, organisationId, sourcePath),
        close: () => dispatchClose(sourcePath),
        hierarchy
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
