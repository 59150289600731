import * as React from "react";
import { InternalAppSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const InternalApps: ConfigurationAreaComponent<InternalAppSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="InternalAppSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "TrainerAppEndpoint", label: "Trainer App URL" },
                { name: "BookingAppEndpoint", label: "Booking App URL" },
                { name: "DorsApiEndPoint", label: "DORS API Functions URL" },
                { name: "AdminAppEndpoint", label: "Admin App URL" },
                { name: "PoliceAppEndpoint", label: "Police App URL" },
                { name: "CorporateBookingAppEndpoint", label: "Commercial Booking App URL" },
                { name: "FunctionsAppUrl", label: "Functions App Url" }
            ]}
        />
    );
};
