import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "redux-little-router";
import { currentUserSelector } from "@common/auth";
import { bookingSelector } from "@booking/landing/selectors";
import { appSettingsSelector } from "@booking/bookings/selectors";
import { SaveBookingDetailsModal, SaveBookingDetailsModalRequired } from "@booking/bookings/components/SaveBookingDetailsModal";
import { EventTypeCategory } from "@common/crud/attendee/model";

interface AuthMenuProps {
    loginTitle: string;
    logoutTitle: string;
    homeTitle: string;
}

export const AuthMenu: React.FC<AuthMenuProps> = (props) => {

    const { logoutTitle, loginTitle, homeTitle } = props;
    const currentUser = useSelector(currentUserSelector);
    const booking = useSelector(bookingSelector);
    const appSettings = useSelector(appSettingsSelector);

    const [saveModalOpenHome, setSaveModalOpenHome] = React.useState(false);
    const [saveModalOpenSignout, setSaveModalOpenSignout] = React.useState(false);

    if (!currentUser) {
        return null;
    }

    const eventManagementPath = booking.eventTypeCategory === EventTypeCategory.CorporateOrganisation
        ? "corporate-event-management"
        : "police-and-court-event-management";
    const homeUrl = `${appSettings.internalAppSettings.adminAppEndpoint}${eventManagementPath}`;
    const signoutUrl = "/account/signout";

    function onHomeClicked() {
        const savePossible = SaveBookingDetailsModalRequired(booking);

        if (savePossible) {
            setSaveModalOpenHome(true);
            return;
        }

        window.location.href = homeUrl;
    };

    function onSignoutClicked() {
        const savePossible = SaveBookingDetailsModalRequired(booking);

        if (savePossible) {
            setSaveModalOpenSignout(true);
            return;
        }

        window.location.href = signoutUrl;
    };

    function closeModalHome() {
        setSaveModalOpenHome(false);
    }

    function closeModalSignout() {
        setSaveModalOpenSignout(false);
    }

    function getOnContinue(redirect: string) {
        return () => window.location.href = redirect;
    };

    return currentUser.authenticated
        // The sign out link is not a SPA route and must therefore be an a element to force the link to go to the server
        ?
        (
            <>
                <SaveBookingDetailsModal
                    open={saveModalOpenHome}
                    onContinue={getOnContinue(homeUrl)}
                    close={closeModalHome}
                />
                <SaveBookingDetailsModal
                    open={saveModalOpenSignout}
                    onContinue={getOnContinue(signoutUrl)}
                    close={closeModalSignout}
                />
                <a
                    className="heading-link" onClick={onHomeClicked}>{homeTitle}</a>
                <a className="heading-link with-separator" onClick={onSignoutClicked} >{logoutTitle}</a>
            </>
        )
        : <Link className="heading-link with-separator" href="/account/signin">{loginTitle}</Link>;
};

