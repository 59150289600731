import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { throttle } from "lodash";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import {  Button, Modal } from "semantic-ui-react";
import { OtherTrainerFee } from "../../model";
import { OtherTrainerPayTable } from "./OtherTrainerPayTable";

interface OtherTrainerPayEditModelProps {
currentOtherTrainerFees?: OtherTrainerFee[];
onChange: (value: any, valid: boolean) => void;
push: (path: string) => void;
}

export const OtherTrainerPayEditModal: React.FC<OtherTrainerPayEditModelProps> = ({ currentOtherTrainerFees, onChange, push }) => {

    const startOfCurrentMonth = moment().utc().startOf("month");
    const blankTrainerFee: OtherTrainerFee =
        {
            effectiveDate: startOfCurrentMonth,
            mentee: 0, mentor: 0, observer: 0, firstCourseSupport: 0,
            tieredBonuses: [{ amount: 0, tierNumber: 2 }, { amount: 0, tierNumber: 3 }]
        };
    const validationObject: { [key: string]: boolean} = setUpValidation(currentOtherTrainerFees, blankTrainerFee);

    const [open, setOpen] = React.useState(false);

    const [newTrainerFee, setNewTrainerFee] = React.useState<OtherTrainerFee>(null);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [formValidationObject, setFormValidationObject] = React.useState<{[key: string]: boolean}>(validationObject);
    const valid = formValidationObject? Object.values(formValidationObject).every(v => v): false;

    const throttledEffectiveDateWarning = React.useMemo(() => throttle(displayEffectiveDateWarning, 1000, { trailing: false }), []);

    function onClose() {
        push("trainer/edit");
        setOpen(false);
        setNewTrainerFee(null);
        setEditing(false);
        setFormValidationObject(validationObject);
    }

    function onSave() {
        onChange([...currentOtherTrainerFees, newTrainerFee], true);
        onClose();
    }

    function onOpen() {
        push("trainer/otherTrainerFeesOpen/edit");
        setOpen(true);
    }

    function onAddRowClick() {
        const startOfMonthFormatted = startOfCurrentMonth.format(DateFormat.DayMonthYear);
        const startOfMonthIsValid = !currentOtherTrainerFees.map(fees => fees.effectiveDate.format(DateFormat.DayMonthYear)).
            includes(startOfCurrentMonth.format(DateFormat.DayMonthYear));

        if (!startOfMonthIsValid) {
            throttledEffectiveDateWarning(startOfMonthFormatted);
        }
        setNewTrainerFee(blankTrainerFee);
        setEditing(true);
        setFormValidationObject({ ...formValidationObject, effectiveDate: startOfMonthIsValid });
    }

    function displayEffectiveDateWarning(date: string)  {
        toast.warning(`effective date ${date} already exists in the configuration`);
    }

    return (
        <Modal
            size={"fullscreen"}
            onClose={onClose}
            onOpen={onOpen}
            trigger={<Button content={"Edit"} />}
            open={open}
        >
            <Modal.Content>
                <OtherTrainerPayTable otherTrainerFees={currentOtherTrainerFees}
                    newOtherTrainerFee={newTrainerFee} setNewOtherTrainerFee={setNewTrainerFee}
                    push={push} onAddRowClick={onAddRowClick} editing={editing} formValidationObject={formValidationObject}
                    setFormValidationObject={setFormValidationObject} displayEffectiveDateWarning={throttledEffectiveDateWarning} editable />
                <Modal.Actions>
                    <Button
                        content="Close"
                        className="cancel-action"
                        onClick={onClose}
                    />
                    <Button
                        content="Save"
                        positive
                        onClick={onSave}
                        disabled={!editing || !valid}
                    />
                </Modal.Actions>
            </Modal.Content>
        </Modal>
    );};

function setUpValidation(currentTrainerFees: OtherTrainerFee[], blankTrainerFee: OtherTrainerFee) {
    let validationObject: { [key: string]: boolean } = null;
    if (currentTrainerFees) {

        const validationArray = Object.keys(blankTrainerFee).map(k => [k, true]);
        const validationArrayWithTieredBonuses = validationArray
            .slice(0, validationArray.length - 1)
            .concat(Object.keys(blankTrainerFee.tieredBonuses).map(k => [k, true]));
        validationObject = Object.fromEntries(validationArrayWithTieredBonuses);
    }
    return validationObject;
}

