import * as React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import "../UpdateDelegatesTable.scss";
import { AttendeeManagerContactRow } from "./AttendeeManagerContactRow";

interface ManagerDetailsProps {
    isEditable: boolean;
    fieldsForMarkup: string[];
    delegate: AttendeeListModel;
    onManagerValueChange: (index: number, field: string, value: string) => void;
    onRemoveManager: (index: number) => void;
    onAddManager: (event: React.SyntheticEvent) => void;
    submitted: boolean;
}

export function ManagerDetails({
    isEditable,
    fieldsForMarkup,
    delegate,
    onManagerValueChange,
    onRemoveManager,
    onAddManager,
    submitted
}: ManagerDetailsProps) {
    return (
        <Grid>
            <Grid.Column only="tablet mobile" width={12}>
                <Grid.Row className="purple-header">
                    Managers
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === "managers") ? "amber-field" : ""}>
                    {delegate.managers?.map((m, index) => (
                        <AttendeeManagerContactRow
                            key={`manager-contact-${index}`}
                            managerContact={m}
                            index={index}
                            amount={delegate.managers?.length || 0}
                            showErrors={submitted}
                            editable={isEditable}
                            updateManagerValue={onManagerValueChange}
                            removeManager={onRemoveManager}
                        />
                    ))}
                </Grid.Column>
            </Grid.Row>
            {isEditable && (
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="button"
                            onClick={onAddManager}
                            disabled={delegate.managers?.length >= 5}
                            content={<span><Icon name="plus circle" />Add manager</span>}
                            className="link-button adjust-link-button-size"
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};
