import { createSelector } from "reselect";
import { createBasePathSelector } from "@common/redux-helpers/selectors";
import { DorsBooking, AppState, DorsBookingDetailModel } from "./model";

export type SelectorState = AppState;

export const routeIdSelector = (state: AppState) => state.router.params.dorsBookingId;

export const dorsBookingsSelector = (state: AppState) => state.dorsBookings;

const empty = {};

export const dorsBookingSelector = createSelector(
    dorsBookingsSelector,
    routeIdSelector,
    (dorsBookings: DorsBooking[], id: string) => {
        return dorsBookings.filter(c => c.id === id)[0] || empty as any;
    }
);

export const dorsBookingSelectorFromAttendee = (id: string) => createSelector(
    dorsBookingsSelector,
    (dorsBookings: DorsBookingDetailModel[]) => {
        return dorsBookings.find(c => c.id === id) || empty as any;
    }
);

export const basePathSelector = createBasePathSelector("dorsBookings");
