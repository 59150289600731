import moment from "moment";

export interface TrainerMonitoringReportDetailModel {
    id: string;
    formType?: string;
    schemeAbbreviation: string;
    trainerId: string;
    trainerName: string;
    monitorId: string;
    monitorName: string;
    relatedEventInstanceId: string;
    status: TrainerMonitoringReportStatusEnum;
    summaryCriteria?: Criterium;
    sections: TrainerMonitoringReportSection[];
    completionDate?: moment.Moment;
    passFailResult: PassFailResultEnum;
    score?: string;
    viewAuthorised?: boolean;
}

export interface TrainerMonitoringReportCreateEditModel {
    id: string;
    formType?: string;
    schemeAbbreviation: string;
    trainerId: string;
    trainerName: string;
    monitorId: string;
    monitorName: string;
    relatedEventInstanceId: string;
    status: TrainerMonitoringReportStatusEnum;
    summaryCriteria?: Criterium;
    sections: TrainerMonitoringReportSection[];
    completionDate?: moment.Moment;
    passFailResult: PassFailResultEnum;
    score?: string;
}

export interface TrainerFeedbackCreateEditModel {
    id: string;
    trainerId: string;
    monitorId: string;
    relatedEventInstanceId: string;
    trainerCommentsSection: TrainerMonitoringReportSection;
}

export interface IqaTeamFeedbackCreateEditModel {
    id: string;
    trainerId: string;
    monitorId: string;
    relatedEventInstanceId: string;
    iqaTeamCommentsSection: TrainerMonitoringReportSection;
}

export interface TrainerMonitoringReportSection {
    id: string;
    seqNum: number;
    title: string;
    summaryCriteria?: Criterium[];
    subSections?: TrainerMonitoringReportSubSection[];
    IsQuestionSection: boolean;
}

export interface TrainerMonitoringReportSubSection {
    id: string;
    sectionId: string;
    seqNum: number;
    unsatisfactory?: boolean;
    criteria: Criterium[];
}

export interface Criterium {
    id: string;
    sectionId: string;
    subSectionId: string;
    unsatisfactory?: boolean;
    response?: string;
    summarySubSection?: SummarySubSectionCriterium[];
}

export interface SummarySubSectionCriterium {
    id: string;
    unsatisfactory?: boolean;
    response?: string;
}

export enum TrainerMonitoringReportStatusEnum {
    Draft,
    IqaReview,
    Submitted,
    CompletedLocked
}

export enum PassFailResultEnum {
    UnResolved = 0,
    Level1 = 1,
    Level2 = 2,
    Level3 = 3
}

export const PassFailResult = {
    [PassFailResultEnum.Level2]: "Level 2",
    [PassFailResultEnum.Level3]: "Level 3"
};

export const PassFailResultForDisplay = {
    [PassFailResultEnum.Level1]: "level 1",
    [PassFailResultEnum.Level2]: "level 2",
    [PassFailResultEnum.Level3]: "level 3"
};
