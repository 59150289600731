import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import {
    TrainersCommentsSectionId,
    TrainersCommentsSectionSeqNum
} from "@common/crud/eventInstance/components/monitoring/MonitoringReportingConstants";

export const TrainersCommentsSection: QuestionSection = {
    id: TrainersCommentsSectionId.toString(),
    title: "Trainer Comments",
    seqNum: TrainersCommentsSectionSeqNum,
    summaryCriterium: [
        {
            id: TrainersCommentsSectionId + ".1",
            text: "",
            typeEnum: CriteriaEnum.text
        }
    ],
    subSections: []
};
