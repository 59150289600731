import * as React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import { DorsBookingListModel } from "../model";
import { dorsBookingsSelector, SelectorState } from "../selectors";

import { AllItems } from "./AllItems";

export interface AllProps {
    dorsBookings: DorsBookingListModel[];
    path: string;
}

class AllInternal extends React.Component<AllProps> {
    public render() {
        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={14}>
                        <h1>Dors Bookings</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <AllItems dorsBookings={this.props.dorsBookings} path={this.props.path} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state: SelectorState): AllProps {
    return {
        path: state.router.pathname,
        dorsBookings: dorsBookingsSelector(state)
    };
}

export const All = connect(mapStateToProps)(AllInternal);
