import * as React from "react";
import { EventInstance } from "../..";
import { useSelector } from "react-redux";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { Table } from "semantic-ui-react";
import { PlannedEventsOverviewTableHeader } from "./PlannedEventsOverviewTableHeader";
import { PlannedEventsOverviewTableRow } from "./PlannedEventsOverviewTableRow";
import { Trainer } from "@common/crud/trainer";
import moment from "moment";

export interface PlannedEventsTableProps {
    plannedEventInstances: EventInstance[];
    trainers: Trainer[];
    month: moment.Moment;
}

export const PlannedEventsOverviewTable: React.FC<PlannedEventsTableProps> = ({ plannedEventInstances, trainers, month }) => {

    const allEventTypes = useSelector(allEventTypesSelector);

    return (
        <>
            <Table>
                <PlannedEventsOverviewTableHeader eventTypes={allEventTypes} />
                <Table.Body>
                    <PlannedEventsOverviewTableRow
                        name={"Total"}
                        eventTypes={allEventTypes}
                        events={plannedEventInstances}
                        month={month}
                    />
                    {trainers.map(t => (
                        <PlannedEventsOverviewTableRow
                            key={t.id}
                            name={t.fullName}
                            eventTypes={allEventTypes}
                            events={plannedEventInstances.filter(e => e.trainerId === t.id)}
                            trainer={t}
                            month={month}
                        />
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};
