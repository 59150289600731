import * as React from "react";
import { Link } from "redux-little-router";
import { Table } from "semantic-ui-react";

import { AllProps } from "./All";

class AllItems extends React.Component<AllProps> {
    public render() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Attendance Id</Table.HeaderCell>
                        <Table.HeaderCell>Scheme</Table.HeaderCell>
                        <Table.HeaderCell>Forename</Table.HeaderCell>
                        <Table.HeaderCell>Surname</Table.HeaderCell>
                        <Table.HeaderCell>Driver Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Telephone</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.dorsBookings.map(dorsBooking => (
                        <Table.Row key={dorsBooking.id}>
                            <Table.Cell>
                                <Link href={`${this.props.path}/${dorsBooking.id}`}>
                                    {dorsBooking.attendanceId}
                                </Link>
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.eventTypeName}
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.forename}
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.surname}
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.driverNumber}
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.email}
                            </Table.Cell>

                            <Table.Cell>
                                {dorsBooking.telephone}
                            </Table.Cell>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}

export { AllItems };
