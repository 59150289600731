import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { startRebookingProcess } from "../../landing/actions";
import { bookingIdSelector } from "@common/payments/selectors";
import { BookingCancellationReason } from "../models";

// This component does not render anything, it only kicks off the rebooking process
export const RebookStartPage: React.FC = () => {
    const dispatch = useDispatch();
    const bookingId = useSelector(bookingIdSelector);
    const isAttendee = bookingId ? false : true;

    React.useEffect(() => {
        if (!isAttendee) {
            dispatch(startRebookingProcess(BookingCancellationReason.RebookingMadeByCa));
        } else {
            dispatch(startRebookingProcess(BookingCancellationReason.RebookingMadeByAttendee));
        }
    }, [dispatch, isAttendee]);

    return null;
};
