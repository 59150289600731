import * as React from "react";
import { DdrsSmsSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid, InputType } from "./SettingsGrid";

export const DdrsSms: ConfigurationAreaComponent<DdrsSmsSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="DdrsSms"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "BookingConfirmationEnglish", label: "Classroom Booking Confirmation English", inputType: InputType.TextArea },
                { name: "DigitalBookingConfirmationEnglish", label: "Digital Booking Confirmation English", inputType: InputType.TextArea },
                { name: "RebookingConfirmationEnglish", label: "Classroom Rebooking Confirmation English", inputType: InputType.TextArea },
                { name: "DigitalRebookingConfirmationEnglish", label: "Digital Rebooking Confirmation English", inputType: InputType.TextArea },
                { name: "DdrsBookingReminderEnglish", label: "DDRS Booking Reminder English", inputType: InputType.TextArea },
                { name: "DdrsDigitalBookingReminderEnglish", label: "DDRS Digital Booking Reminder English", inputType: InputType.TextArea },
                { name: "DdrsDigitalBookingReminderForAllEnglish", label: "DDRS Digital Booking Reminder For All English", inputType: InputType.TextArea },
                { name: "DdrsDigitalReminderNoEmailEnglish", label: "DDRS Digital Booking Reminder No Email (English)", inputType: InputType.TextArea },
                { name: "DdrsFirstInvite", label: "Ddrs First Invite", inputType: InputType.TextArea },
                { name: "DdrsPendingBookingReminderSms", label: "DDRS course booking reminder", inputType: InputType.TextArea },
            ]}
        />
    );
};
