import * as React from "react";
import { SmsSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid, InputType } from "./SettingsGrid";

export const Sms: ConfigurationAreaComponent<SmsSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Sms"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "BookingReminderEnglish", label: "NDORS Booking Reminder English", inputType: InputType.TextArea },
                { name: "BookingReminderCymraeg", label: "Booking Reminder Cymraeg", inputType: InputType.TextArea },
                { name: "DigitalBookingReminderEnglish", label: "NDORS Digital Booking Reminder English", inputType: InputType.TextArea },
                { name: "DigitalBookingReminderCymraeg", label: "Digital Booking Reminder Cymraeg", inputType: InputType.TextArea },
                { name: "DigitalBookingReminderForAllEnglish", label: "NDORS Digital Booking Reminder For All English", inputType: InputType.TextArea },
                { name: "DigitalBookingReminderForAllCymraeg", label: "Digital Booking Reminder For All Cymraeg", inputType: InputType.TextArea },
                { name: "BookingConfirmationEnglish", label: "Classroom Booking Confirmation English", inputType: InputType.TextArea },
                { name: "BookingConfirmationCymraeg", label: "Classroom Booking Confirmation Cymraeg", inputType: InputType.TextArea },
                { name: "DigitalBookingConfirmationEnglish", label: "Digital Booking Confirmation English", inputType: InputType.TextArea },
                { name: "DigitalBookingConfirmationCymraeg", label: "Digital Booking Confirmation Cymraeg", inputType: InputType.TextArea },
                { name: "RebookingConfirmationEnglish", label: "Classroom Rebooking Confirmation English", inputType: InputType.TextArea },
                { name: "RebookingConfirmationCymraeg", label: "Classroom Rebooking Confirmation Cymraeg", inputType: InputType.TextArea },
                { name: "DigitalRebookingConfirmationEnglish", label: "Digital Rebooking Confirmation English", inputType: InputType.TextArea },
                { name: "DigitalRebookingConfirmationCymraeg", label: "Digital Rebooking Confirmation Cymraeg", inputType: InputType.TextArea },
                { name: "ConfirmationWithScheduledPaymentEnglish", label: "Confirmation With Scheduled Payment English", inputType: InputType.TextArea },
                { name: "ConfirmationWithScheduledPaymentCymraeg", label: "Confirmation With Scheduled Payment Cymraeg", inputType: InputType.TextArea },
                { name: "ConfirmationOfSuccessfulPaymentEnglish", label: "Confirmation Of Successful Payment English", inputType: InputType.TextArea },
                { name: "ConfirmationOfSuccessfulPaymentCymraeg", label: "Confirmation Of Successful Payment Cymraeg", inputType: InputType.TextArea },
                { name: "ConfirmationOfUnsuccessfulPaymentEnglish", label: "Confirmation Of Unsuccessful Payment English", inputType: InputType.TextArea },
                { name: "ConfirmationOfUnsuccessfulPaymentCymraeg", label: "Confirmation Of Unsuccessful Payment Cymraeg", inputType: InputType.TextArea },
                { name: "ReminderOfScheduledPaymentEnglish", label: "Reminder Of Scheduled Payment English", inputType: InputType.TextArea },
                { name: "ReminderOfScheduledPaymentCymraeg", label: "Reminder Of Scheduled Payment Cymraeg", inputType: InputType.TextArea },
                { name: "OutstandingAttendeeBalanceReminderCymraeg", label: "Outstanding Attendee Balance Reminder Cymraeg", inputType: InputType.TextArea },
                { name: "OutstandingAttendeeBalanceReminderEnglish", label: "Outstanding Attendee Balance Reminder English", inputType: InputType.TextArea },
                { name: "CancellationConfirmationUnpaidDebtsEnglish", label: "Cancellation Confirmation Unpaid Debts English", inputType: InputType.TextArea },
                { name: "CancellationConfirmationUnpaidDebtsCymraeg", label: "Cancellation Confirmation Unpaid Debts Cymraeg", inputType: InputType.TextArea },
                {
                    name: "CancellationAttendeeDidNotAttendCourseEnglish",
                    label: "Cancellation Confirmation Did Not Attend Course English",
                    inputType: InputType.TextArea
                },
                {
                    name: "CancellationAttendeeDidNotAttendCourseCymraeg",
                    label: "Cancellation Confirmation Did Not Attend Course Cymraeg",
                    inputType: InputType.TextArea
                },
                {
                    name: "CancellationConfirmationOtherReasonEnglish",
                    label: "Cancellation Confirmation - Other Reason (English)",
                    inputType: InputType.TextArea
                },
                {
                    name: "CancellationConfirmationOtherReasonCymraeg",
                    label: "Cancellation Confirmation - Other Reason Cymraeg",
                    inputType: InputType.TextArea
                },
                {
                    name: "DigitalCourseInWelshCymraeg",
                    label: "Welsh language digital course bookings",
                    inputType: InputType.TextArea
                },
                {
                    name: "CourseInWelshCymraeg",
                    label: "Welsh language course bookings",
                    inputType: InputType.TextArea
                },
                {
                    name: "DigitalReminderNoEmailEnglish",
                    label: "NDORS Digital Booking Reminder No Email (English)",
                    inputType: InputType.TextArea
                },
                {
                    name: "DigitalReminderNoEmailCymraeg",
                    label: "Digital Booking Reminder No Email (Cymraeg)",
                    inputType: InputType.TextArea
                },
                {
                    name: "CourseSpreadingNewBookingDetailsForAttendeeEnglish",
                    label: "Course reminder for attendee spread process (English)",
                    inputType: InputType.TextArea
                },
                {
                    name: "CourseSpreadingNewBookingDetailsForAttendeeCymraeg",
                    label: "Course reminder for attendee spread process (Cymraeg)",
                    inputType: InputType.TextArea
                },
                {
                    name: "AttendeeNotMovedInCourseSpreadEnglish",
                    label: "Attendee not moved in course spread (English)",
                    inputType: InputType.TextArea
                },
                {
                    name: "AttendeeNotMovedInCourseSpreadCymraeg",
                    label: "Attendee not moved in course spread (Cymraeg)",
                    inputType: InputType.TextArea
                },
                {
                    name: "CourseSpreadingTrainerAlert",
                    label: "Course spreading trainer alert",
                    inputType: InputType.TextArea
                },
                { name: "DigitalReminderForAttendeesWithNoEmailSms", label: "Digital reminder for attendees with no email", inputType: InputType.TextArea },
                { name: "TrainerInvoiceReminder", label: "Trainer Invoice Reminder", inputType: InputType.TextArea }
            ]}
        />
    );
};
