import { MobileLandscapeHeight, MobileMaxWidth, TabletLandscapeWidth, TabletMinWidth } from "@common/style/constants";
import * as React from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isTabletScreenSize = React.useMemo(() => windowDimensions.width >= TabletMinWidth && windowDimensions.width <= TabletLandscapeWidth,
        [windowDimensions.width]);

    const isMobileLandscapeHeight = React.useMemo(() => windowDimensions.height <= MobileLandscapeHeight,
        [windowDimensions.height]);

    const isMobileWidth = React.useMemo(() => windowDimensions.width <= MobileMaxWidth, [windowDimensions.width]);

    return { windowDimensions, isTabletScreenSize, isMobileLandscapeHeight, isMobileWidth };
}

export { useWindowDimensions };
