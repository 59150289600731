import axios from "axios";
import { Moment } from "moment";
import { PendingNoteAddedModel } from "@common/history/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import * as QueryString from "query-string";

export class NotesApi {
    private readonly noteApiUrl: string;

    constructor(bookingId?: string) {
        this.noteApiUrl = bookingId ? `/api/note/${bookingId}` : "/api/note";
    }

    public async addNote(id: string, noteText: string, taskRelation: TaskRelationEnum, alertNoteToTTC: boolean, pendingDate?: Moment,
        file?: File,suffixId?: string) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", id);
        formData.append("noteText", noteText);
        formData.append("pendingDate", pendingDate?.format("YYYY-MM-DD") ?? "");
        formData.append("taskRelation", taskRelation.toString());
        formData.append("alertNoteToTTC", alertNoteToTTC.toString());

        const query = suffixId? "?"+ QueryString.stringify({ suffixId }):"";
        const response = await axios.post<PendingNoteAddedModel>(
            `${this.noteApiUrl}${query}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        return response.data;
    }

    public async updateNote(noteText: string, id: string, taskRelation: TaskRelationEnum, removeAttachment: boolean) {
        const response = await axios.patch(this.noteApiUrl, { id, noteText, taskRelation, removeAttachment });
        return response.status;
    }

    public completePendingNote(correlationId: string, id: string) {
        return axios.put(this.noteApiUrl, { id });
    }
}
