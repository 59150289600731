import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";

import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actiontypes";
import * as models from "./model";
import * as reduxHelpersModels from "../../redux-helpers/model";
import { OrganisationApi } from "./organisationApi";
import { CommsDestination, LanguageEnum } from "../eventInstance/model";
import { NoteAction } from "@common/notes/actions";

import { toast } from "react-toastify";
import { CorporateQuickAddEntityDictionary, QuickAddEntities } from "../quickAdd/model";
import { v4 } from "uuid";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { UserFavouritesApi } from "../../favourites/UserFavouritesApi";
import { FavouriteCreateEditModel } from "../../favourites/model";
import { ValueWithEffectiveDate } from "../eventType/model";
import { CustomerStatusEnum } from "../alaskaNudgeTask/model";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";

export type OrganisationAction =
    ({ type: actions.LOAD_ORGANISATIONS_SUCCESS } & Payload<models.OrganisationListModel[]>)
    | ({ type: actions.LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS } & Payload<models.OrganisationListModel[]>)
    | ({ type: actions.CREATE_ORGANISATION_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.LOAD_ORGANISATION_DETAIL_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.SAVE_ORGANISATION_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.DELETE_ORGANISATION_SUCCESS } & Payload<string>)
    | ({ type: actions.SAVE_SURVEY_LINK_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.SAVE_REMINDER_TYPE_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.SAVE_ORGANISATION_SETTING_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.SAVE_BOOKING_INFORMATION_LINK_SUCCESS } & Payload<models.OrganisationDetailModel>)
    | ({ type: actions.LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS } & Payload<models.BusinessDevelopmentManager[]>)
    | ({ type: actions.UPDATE_ORGANISATION_FAVOURITE_STATUS } & Payload<{id: string; favourite: boolean}>)
    | NoteAction;

export const loadOrganisationsSuccess = (payload: models.OrganisationListModel[]): OrganisationAction => ({
    payload,
    type: actions.LOAD_ORGANISATIONS_SUCCESS
});

export const loadRelatedOrganisationOptionsSuccess = (payload: models.OrganisationListModel[]): OrganisationAction => ({
    payload,
    type: actions.LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS
});

export const createOrganisationSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.CREATE_ORGANISATION_SUCCESS
});

export const saveOrganisationSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_ORGANISATION_SUCCESS
});

export const saveSurveyLinkSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_SURVEY_LINK_SUCCESS
});

export const saveBookingInformationLinkSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_BOOKING_INFORMATION_LINK_SUCCESS
});

export const saveTermsAndConditionsLinkSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS
});

export const saveReminderTypeSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_REMINDER_TYPE_SUCCESS
});

export const saveOrganisationSettingSuccess = (payload: models.OrganisationDetailModel): OrganisationAction => ({
    payload,
    type: actions.SAVE_ORGANISATION_SETTING_SUCCESS
});

export const deleteOrganisationSuccess = (id: string): OrganisationAction => ({
    payload: id,
    type: actions.DELETE_ORGANISATION_SUCCESS
});

export const loadBusinessDevelopmentManagersSuccess = (payload: models.BusinessDevelopmentManager[]): OrganisationAction => ({
    payload,
    type: actions.LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS
});

export const updateOrganisationFavouriteStatus = (id: string, favourite: boolean): OrganisationAction => ({
    payload: { id, favourite },
    type: actions.UPDATE_ORGANISATION_FAVOURITE_STATUS
});

export function createOrganisation(organisation: models.OrganisationCreateModel, path: string, businessLineType?: models.BusinessLineType, quickAdd?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "createOrganisation", new OrganisationApi().create(organisation));
        dispatch(createOrganisationSuccess(result));

        if (quickAdd) {
            toast.success("Quick Add Organisation Successful");
            dispatch(push(getBusinessLineTypePath(+businessLineType)
                + `/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.Organisation]}/${v4()}`));
        }
        else {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export function saveOrganisation(organisation: models.OrganisationEditModel, path: string, part?: string) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveOrganisation", new OrganisationApi().save(organisation));

        dispatch(saveOrganisationSuccess(result));
        dispatch(push(`${path}/${result.id}${part ? "/" + part : ""}`));
    };
}

export function saveOrganisationDetails(organisation: models.OrganisationEditModel) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveOrganisation", new OrganisationApi().save(organisation));
        dispatch(saveOrganisationSuccess(result));
    };
}

export function saveSurveyLink(organisationId: string, language: LanguageEnum, surveyLink: string, surveyNumber: number) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveSurveyLink", new OrganisationApi().saveSurveyLink(organisationId, language, surveyLink, surveyNumber));
        dispatch(saveSurveyLinkSuccess(result));
    };
}

export function saveBookingInformationLink(organisationId: string, language: LanguageEnum, deliveryType: DeliveryTypeEnum, bookingInformationLink: string) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveBookingInformationLink",
            new OrganisationApi().saveBookingInformationLink(organisationId, language, deliveryType, bookingInformationLink));
        dispatch(saveBookingInformationLinkSuccess(result));
    };
}

export function saveTermsAndConditionsLink(organisationId: string, language: LanguageEnum, termsAndConditionsLink: string) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(
            dispatch,
            "saveTermsAndConditionsLink",
            new OrganisationApi().saveTermsAndConditionsLink(organisationId, language, termsAndConditionsLink)
        );
        dispatch(saveTermsAndConditionsLinkSuccess(result));
    };
}

export function saveReminderType(organisationId: string, reminderType: models.ReminderType) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveReminderType", new OrganisationApi().saveReminderType(organisationId, reminderType));
        dispatch(saveReminderTypeSuccess(result));
    };
}

export function saveCourseCreationCommsDestination(organisationId: string, courseCreationCommsDestination: CommsDestination, email?: string, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveCourseCreationCommsDestination",
            new OrganisationApi().saveCourseCreationCommsDestination(organisationId, courseCreationCommsDestination, email, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveCourseReminderCommsDestination(organisationId: string, courseCreationCommsDestination: CommsDestination, email?: string, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveCourseReminderCommsDestination",
            new OrganisationApi().saveCourseReminderCommsDestination(organisationId,courseCreationCommsDestination, email, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveBookingCommsDestination(organisationId: string, commsDestination: CommsDestination, email?: string, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveDelegateBookingCommsDestination",
            new OrganisationApi().saveBookingCommsDestination(organisationId, commsDestination, email, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveCertificateCommsDestination(organisationId: string, courseCreationCommsDestination: CommsDestination, email?: string, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveCertificateCommsDestination",
            new OrganisationApi().saveCertificateCommsDestination(organisationId,courseCreationCommsDestination, email, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveCourseEditCommsDestination(organisationId: string, courseEditCommsDestination: CommsDestination, email?: string, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveCourseEditCommsDestination",
            new OrganisationApi().saveCourseEditCommsDestination(organisationId, courseEditCommsDestination, email, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveOrgSpecific(organisationId: string, orgSpecificField: models.OrganisationSpecificFields, value?: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveOrgSpecific",
            new OrganisationApi().saveOrgSpecific(organisationId, orgSpecificField, value));
        dispatch(saveOrganisationSettingSuccess(result));
    };
}

export function saveOrgFavouriteStatus(favourite: FavouriteCreateEditModel) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "saveOrgUserFavouriteStatus",
            new UserFavouritesApi().updateFavourites(favourite));
        dispatch(updateOrganisationFavouriteStatus(favourite.targetId, favourite.markAsFavourite));
    };
}

export function deleteOrganisation(id: string, path: string) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "deleteOrganisation", new OrganisationApi().delete(id));
        dispatch(deleteOrganisationSuccess(id));
        dispatch(push(`${path}`));
    };
}

export function loadCourts( ) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadOrganisations", new OrganisationApi().getAll({ businessLineType: [models.BusinessLineType.Court] }));
        dispatch(loadOrganisationsSuccess(result));
    };
}

export function loadOrganisations({ options }: { options?: models.SearchOptions } = {}) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadOrganisations", new OrganisationApi().getAll(options));
        dispatch(loadOrganisationsSuccess(result));
    };
}

export function loadRelatedOrganisationOptions() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadOrganisations", new OrganisationApi().getAll({
            customerStatus: CustomerStatusEnum.Customer.toString(),
            showExpired: false,
            hasCorporateOrganisationSpecificCpcBookingUrl: true
        }));
        dispatch(loadRelatedOrganisationOptionsSuccess(result));
    };
}

export function loadAllOrganisations() {
    return loadOrganisations();
}

export function loadAllOrganisationsForBusineessLineType(businessLineType: reduxHelpersModels.BusinessLineType) {
    return loadOrganisations({ options: { businessLineType: [+businessLineType] } });
}

export function loadAllCorporateOrganisations() {
    return loadOrganisations({ options: { businessLineType: [ models.BusinessLineType.Corporate] } });
}

export function loadAllConstructionOrganisations() {
    return loadOrganisations({ options: { businessLineType: [models.BusinessLineType.Construction] } });
}

export function loadAllPoliceAndCourtOrganisations() {
    return loadOrganisations({ options: { businessLineType: [models.BusinessLineType.Police, models.BusinessLineType.Court] } });
}

export function loadAllPoliceAndNonTtcPoliceOrganisations() {
    return async (dispatch: Dispatch) => {
        const allPolice = await loadAndTrack(dispatch, "loadOrganisations",
            new OrganisationApi().getAll({ businessLineType: [models.BusinessLineType.Police] }));

        const nonTtcPolice: models.Organisation = {
            id: v4(),
            dorsId: 68193728,
            name: "Non-TTC Police Force",
            reminderType: models.ReminderType.None,
            businessLineType: models.BusinessLineType.Police,
            corporateBusinessLineType: models.CorporateBusinessLineType.NonCorporate,
            isExpired: false,
        };

        dispatch(loadOrganisationsSuccess([...allPolice, nonTtcPolice].sort((a, b) => a.name.localeCompare(b.name))));
    };
}

export const loadOrganisationDetailSuccess = (organisation: models.OrganisationDetailModel): OrganisationAction => ({
    payload: organisation,
    type: actions.LOAD_ORGANISATION_DETAIL_SUCCESS
});

export function loadOrganisationDetail({ organisationId }: { organisationId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const organisation = getState().organisations?.filter(c => c.id === organisationId)?.[0];

        if (organisation === undefined
            || organisation.detailUpdated === undefined
            || organisation.detailUpdated.isBefore(organisation.listUpdated)) {

            const result = await loadAndTrack(dispatch, "loadOrganisationDetail", new OrganisationApi().detail(organisationId));
            const action = getState().organisations.find(o => o.id === organisationId) === undefined
                ? createOrganisationSuccess : loadOrganisationDetailSuccess;
            dispatch(action(result));
        }
    };
}

export function loadOrganisationDetailFromForceId(forceId: number) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const organisation = getState().organisations?.filter(c => c.dorsId === forceId)?.[0];

        if (organisation === undefined
            || organisation.detailUpdated === undefined
            || organisation.detailUpdated.isBefore(organisation.listUpdated)) {

            const action = organisation === undefined ? createOrganisationSuccess : loadOrganisationDetailSuccess;

            const result = await loadAndTrack(dispatch, "loadOrganisationDetail", new OrganisationApi().detailFromForceId(forceId));
            dispatch(loadOrganisationDetailSuccess(result));
            dispatch(action(result));
        }
    };
}

export function loadBusinessDevelopmentManagers() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadBusinessDevelopmentManagers", new OrganisationApi().getAllBusinessDevelopmentManagers());
        dispatch(loadBusinessDevelopmentManagersSuccess(result));
    };
}

export function editFlexiPay(organisationId: string, chargesOwnFlexiPayFees: boolean, flexiPayFees: ValueWithEffectiveDate<number>[]) {
    return async (dispatch: Dispatch) => {
        const organisationDetailModel =
            await loadAndTrack(dispatch, "editFlexiPay", new OrganisationApi().editFlexiPay(organisationId, chargesOwnFlexiPayFees, flexiPayFees));
        dispatch(loadOrganisationDetailSuccess(organisationDetailModel));
    };
}
