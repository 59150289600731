import * as React from "react";
import { Accordion, Icon, Grid } from "semantic-ui-react";
import moment from "moment";
import { ChangeEventType, ChangeEventTypes } from "@common/history/model";

export interface HistoryTitleProps {
    isActive: boolean;
    itemIndex: string;
    dateModified: moment.Moment;
    modifiedBy: string;
    eventType: ChangeEventType;
    onClick: (e: any, d: any) => void;
}

export const HistoryTitle: React.SFC<HistoryTitleProps> = ({ isActive, itemIndex, onClick, dateModified, modifiedBy, eventType }) => {
    return (
        <>
            <Accordion.Title active={isActive} index={itemIndex} onClick={onClick}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Icon name="dropdown" />
                            {dateModified && dateModified.format("DD/MM/YYYY HH:mm")}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <span className="emphasized-property">
                                {ChangeEventTypes[eventType]}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <span className={"emphasized-property"}>
                                {modifiedBy}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Title>
        </>
    );
};
