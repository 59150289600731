import * as React from "react";

import { SpecialRequirements } from "../crud/common/SpecialRequirements";
import { SpecialRequirementsAttribute, SpecialRequirementsAttributePictures, SpecialRequirementsAttributes } from "../crud/eventInstance/model";
import { VenueAttributePictures, VenueAttributes } from "../crud/venue/model";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";

export interface SpecialRequirementsDisplayProps {
    showMessageIfNoSpecialRequirements: boolean;
    specialRequirements: SpecialRequirements;
    isDigitalCourse: boolean;
}

export const SpecialRequirementsDisplay: React.FC<SpecialRequirementsDisplayProps> = (
    {
        specialRequirements,
        showMessageIfNoSpecialRequirements,
        isDigitalCourse
    }
) => {

    const isCA = useSelector(isUserClientAdvisor);

    return (
        specialRequirements?.eventAttributes?.length === 0 && specialRequirements?.venueAttributes?.length === 0 &&
        !specialRequirements?.otherRequirements && !specialRequirements?.otherHearingRequirements && showMessageIfNoSpecialRequirements ?
            <span>None</span> :
            (
                <>
                    {
                        (isDigitalCourse && isCA) || !isDigitalCourse &&
                        <>
                            {
                                specialRequirements?.venueAttributes?.map((elem, idx) =>
                                    <img key={idx} src={VenueAttributePictures[elem]} title={VenueAttributes[elem]} />)
                            }
                        </>
                    }
                    {
                        specialRequirements?.eventAttributes?.map((elem, idx) =>
                            <img key={idx} src={SpecialRequirementsAttributePictures[elem]} title={SpecialRequirementsAttributes[elem]} />)
                    }
                    <div>
                        {specialRequirements?.otherRequirements &&
                            <span>Other Requirements: {specialRequirements?.otherRequirements}</span>}
                    </div>
                    <div>
                        {specialRequirements?.interpreterName &&
                            specialRequirements?.eventAttributes &&
                        (specialRequirements?.eventAttributes.includes(SpecialRequirementsAttribute.OwnInterpreter) ||
                            specialRequirements?.eventAttributes.includes(SpecialRequirementsAttribute.Translator)) &&
                            <span>{"Interpreter Name:"} {specialRequirements?.interpreterName}</span>}
                    </div>
                    <div>
                        {specialRequirements?.outsideUkCountryName &&
                        specialRequirements?.eventAttributes &&
                        (specialRequirements?.eventAttributes.includes(SpecialRequirementsAttribute.OutsideUkAttendee)) &&
                        <span>{"Other Country Name:"} {specialRequirements?.outsideUkCountryName}</span>}
                    </div>
                    <div>
                        {specialRequirements?.otherHearingRequirements &&
                            <span>Other Hearing Requirements: {specialRequirements?.otherHearingRequirements}</span>}
                    </div>
                </>
            )

    );
};
