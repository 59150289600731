import React from "react";
import { CorporateOrganisationContact, OrganisationContactDataModel } from "../model";
import { Input } from "@neworbit/simpleui-input";
import { Button, Grid } from "semantic-ui-react";
import { phoneNumberWithSpaces } from "@common/validation";

interface OrganisationContactRowProps {
    contactType: CorporateOrganisationContact;
    contact: OrganisationContactDataModel;
    index: number;
    showErrors: boolean;
    updateContactValue: (contact: CorporateOrganisationContact, index: number, field: string, value: string, valid: boolean) => void;
    removeContact: (index: number) => void;
}

export const OrganisationContactRow = (props: OrganisationContactRowProps) => {
    const { contactType, contact, index, showErrors, updateContactValue, removeContact } = props;

    const updateName = React.useCallback((value: string, valid: boolean) => {
        updateContactValue(contactType, index, "name", value, valid);
    }, [contactType, index, updateContactValue]);

    const updateEmail = React.useCallback((value: string, valid: boolean) => {
        updateContactValue(contactType, index, "email", value, valid);
    }, [contactType, index, updateContactValue]);

    const updateTelephone = React.useCallback((value: string, valid: boolean) => {
        updateContactValue(contactType, index, "telephone", value, valid);
    }, [contactType, index, updateContactValue]);

    const onRemoveClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        removeContact(index);
    }, [index, removeContact]);

    return (
        <Grid.Row>
            <Grid.Column width={12}>
                <Input.Text
                    value={contact.name}
                    label="Name"
                    showErrors={showErrors}
                    onChange={updateName}
                />
                <Input.Email
                    value={contact.email}
                    label="Email"
                    showErrors={showErrors}
                    onChange={updateEmail}
                />
                <Input.Text
                    value={contact.telephone}
                    label="Phone Number"
                    validation={phoneNumberWithSpaces()}
                    showErrors={showErrors}
                    onChange={updateTelephone}
                />
            </Grid.Column>
            <Grid.Column width={4}>
                <Button onClick={onRemoveClick} content="- Remove contact" className="link-button vertical-center" />
            </Grid.Column>
        </Grid.Row>
    );
};
