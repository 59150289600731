/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_BOOKING_SUCCESS = "LOAD_BOOKING_SUCCESS";
export type LOAD_BOOKING_SUCCESS = typeof LOAD_BOOKING_SUCCESS;

export const LOAD_EXTERNAL_LINKS_SUCCESS = "LOAD_EXTERNAL_LINKS_SUCCESS";
export type LOAD_EXTERNAL_LINKS_SUCCESS = typeof LOAD_EXTERNAL_LINKS_SUCCESS;

export const RESEND_CONFIRMATION_MAIL_SUCCESS = "RESEND_CONFIRMATION_MAIL_SUCCESS";
export type RESEND_CONFIRMATION_MAIL_SUCCESS = typeof RESEND_CONFIRMATION_MAIL_SUCCESS;

export const UPDATE_INCLUDE_FULLY_BOOKED_COURSES = "UPDATE_INCLUDE_FULLY_BOOKED_COURSES";
export type UPDATE_INCLUDE_FULLY_BOOKED_COURSES = typeof UPDATE_INCLUDE_FULLY_BOOKED_COURSES;

export const SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX = "SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX";
export type SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX = typeof SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX;

export const SET_DELIVERY_TYPE_FILTER_DISTANCE = "SET_DELIVERY_TYPE_FILTER_DISTANCE";
export type SET_DELIVERY_TYPE_FILTER_DISTANCE = typeof SET_DELIVERY_TYPE_FILTER_DISTANCE;

export const SET_DELIVERY_TYPE_FILTER_VENUE_ID = "SET_DELIVERY_TYPE_FILTER_VENUE_ID";
export type SET_DELIVERY_TYPE_FILTER_VENUE_ID = typeof SET_DELIVERY_TYPE_FILTER_VENUE_ID;

export const SET_DELIVERY_TYPE_BOOKING_ID = "SET_DELIVERY_TYPE_BOOKING_ID";
export type SET_DELIVERY_TYPE_BOOKING_ID = typeof SET_DELIVERY_TYPE_BOOKING_ID;
