import * as React from "react";
import { Modal } from "@common/components";
import { Button } from "semantic-ui-react";
import { Spinner } from "@common/global";

export interface SubmitFeedbackToMonitorModalProps {
    trainerOpen: boolean;
    isSubmitting: boolean;
    closeTrainerModal: () => void;
    saveTrainerFeedback: () => void;
}
export const SubmitFeedbackToMonitorModal: React.FC<SubmitFeedbackToMonitorModalProps> = (props) => {
    const {
        trainerOpen,
        isSubmitting,
        closeTrainerModal,
        saveTrainerFeedback
    } = props;

    return (
        <Modal open={trainerOpen}>
            <Modal.Header>Submit Feedback to Monitor</Modal.Header>
            <Modal.Content>
                <div>
                    Please check you input. Clicking 'Yes' will save your
                    feedback and lock the form for further changes
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Spinner active={isSubmitting}>
                    <Button onClick={closeTrainerModal} className="cancel-action">Cancel</Button>
                    <Button onClick={saveTrainerFeedback} positive>Yes</Button>
                </Spinner>
            </Modal.Actions>
        </Modal>
    );
};

