/* eslint-disable eqeqeq */
import * as React from "react";
import { Container, Grid, DropdownItemProps, Form, DropdownProps, Radio, RadioProps, Header, Divider } from "semantic-ui-react";
import { EventType } from "@common/crud/eventType";
import { WizardSettings } from "./BulkCreateBase";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { ModuleTypeEnum } from "@common/crud/eventType/model";

export interface CreationWizardProps {
    eventTypes: EventType[];
    eventTypesOptions: DropdownItemProps[];
    blockEventTypeChange: boolean;
    isDigitalVenue: boolean;
    setDemandedCourseSettings: (settings: WizardSettings) => void;
    eventTypeCategory: EventTypeCategory;
}

export const CreationWizard: React.FC<CreationWizardProps> = ({
    eventTypesOptions,
    eventTypes,
    setDemandedCourseSettings,
    blockEventTypeChange,
    isDigitalVenue,
    eventTypeCategory
}) => {
    const [selectedEventType, setSelectedEventType] = React.useState<EventType>(null);
    const [maxEventsPerDay, setMaxEventsPerDay] = React.useState<number[]>();
    const [selectedEventsPerDay, setSelectedEventsPerDay] = React.useState<number>();

    React.useEffect(() =>
        setDemandedCourseSettings({ eventsPerDay: selectedEventsPerDay, eventType: selectedEventType }), [selectedEventsPerDay, selectedEventType]);

    const getKeys = (dict: Dictionary<moment.Duration[]>) => dict != null ? Object.entries(dict).map(
        ([key]) => {
            return Number.parseInt(key, 10);
        }) : [0];

    const onEventTypeSelection = React.useCallback((_: any, { value }: DropdownProps) => {
        const chosenEventType = eventTypes.find(e => e.id === value);
        setSelectedEventType(chosenEventType);
        const maxEventAmountPerDay = isDigitalVenue ?
            (chosenEventType.digitalEventTypeDetails?.digitalEventSuggestedStartTimesForSessions == null ? null :
                getKeys(chosenEventType?.digitalEventTypeDetails?.digitalEventSuggestedStartTimesForSessions)) :
            chosenEventType.moduleType === ModuleTypeEnum.Both ?
                chosenEventType.classroomEventTypeDetails?.suggestedStartTimesForTheorySessions == null ? null :
                    getKeys(chosenEventType?.classroomEventTypeDetails?.suggestedStartTimesForTheorySessions) :
                (chosenEventType.classroomEventTypeDetails?.suggestedStartTimesForSessions == null ? null :
                    getKeys(chosenEventType?.classroomEventTypeDetails?.suggestedStartTimesForSessions));
        setMaxEventsPerDay(maxEventAmountPerDay);
        setSelectedEventsPerDay(maxEventAmountPerDay !== null && maxEventAmountPerDay.length >= 2 ? 2 : 0);
    }, [eventTypes, isDigitalVenue]);

    const onEventAmountSelection = React.useCallback((_: any, { value }: RadioProps) => {
        setSelectedEventsPerDay(value as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxEventsPerDay, selectedEventsPerDay]);

    const disabledSelection = (
        <>
            <Radio disabled={true} label="1 per day" />
            <Radio disabled={true} label="2 per day" />
        </>
    );

    const isDors = eventTypeCategory === EventTypeCategory.Dors;
    const isWizardComplete = selectedEventType != null && (!isDors || selectedEventsPerDay > 0);
    return (
        <Container>
            <Grid>
                <Grid.Column width={16}>
                    <Form.Dropdown
                        selection
                        floating
                        label="Scheme"
                        disabled={blockEventTypeChange}
                        placeholder="Choose what kind of scheme you want to create"
                        options={eventTypesOptions}
                        onChange={onEventTypeSelection}
                    />
                </Grid.Column>

                <Grid.Column width={16}>
                    <div className={`flex-cell ${selectedEventType == null ? "disabled" : ""}`}>
                        { isDors && <>
                            <Header size="medium">How many courses per day</Header>
                            {(maxEventsPerDay == null || maxEventsPerDay?.length === 0) && (
                                <>
                                    <span className="error-message">Scheme doesn't have configured suggested start times.
                                        Please update scheme or change to the one that's configured properly</span>
                                    <Divider />
                                    {disabledSelection}
                                </>)}
                            {maxEventsPerDay?.map(key =>
                                (<Radio
                                    className="event-radio-button"
                                    key={key}
                                    checked={selectedEventsPerDay === key}
                                    label={`${key} per day`}
                                    value={key}
                                    onChange={onEventAmountSelection}
                                />))}
                        </>}
                        {isWizardComplete &&
                            (
                                <>
                                    <Divider />
                                    <span>All set up correctly. Choose the dates on calendar.</span>
                                </>
                            )}
                    </div>
                </Grid.Column>
            </Grid>
        </Container >
    );
};
