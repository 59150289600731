import { Link } from "redux-little-router";
import { v4 } from "uuid";

export enum QuickAddEntities {
None,
DDRSOffender,
Course,
Trainer,
Venue,
Organisation,
ClosedCourse,
OpenCourse
}

export const QuickAddEntityNames = {
    [QuickAddEntities.None]: "None",
    [QuickAddEntities.Trainer]: "Trainer",
    [QuickAddEntities.Venue]: "Venue",
    [QuickAddEntities.ClosedCourse]: "Closed Course",
    [QuickAddEntities.OpenCourse]: "Open Course",
    [QuickAddEntities.Organisation]: "Organisation",
    [QuickAddEntities.DDRSOffender]: "DDRS Offender",
    [QuickAddEntities.Course]: "Course",
};

export const UniversalQuickAddEntityDictionary = {
    [QuickAddEntities.None]: "None",
    [QuickAddEntities.Trainer]: "Trainer",
    [QuickAddEntities.Venue]: "Venue",
};

export const CorporateQuickAddEntityDictionary = {
    [QuickAddEntities.ClosedCourse]: "ClosedCourse",
    [QuickAddEntities.OpenCourse]: "OpenCourse",
    [QuickAddEntities.Organisation]: "Organisation"
};

export const QuickAddEntityDictionary = {
    [QuickAddEntities.DDRSOffender]: "DDRSOffender",
    [QuickAddEntities.Course]: "Course",
};

export const corporateQuickAddDropdownOptions= [ { text: QuickAddEntityNames[QuickAddEntities.None],
    value: QuickAddEntities.None },
{ text: QuickAddEntityNames[QuickAddEntities.Trainer],
    value: QuickAddEntities.Trainer,
    href: `/corporate-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Trainer]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Venue],
    value: QuickAddEntities.Venue,
    href: `/corporate-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Venue]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Organisation],
    value: QuickAddEntities.Organisation,
    href: `/corporate-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.Organisation]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.ClosedCourse],
    value: QuickAddEntities.ClosedCourse, as: Link,
    href: `/corporate-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.ClosedCourse]}/${v4()}`  },
{ text: QuickAddEntityNames[QuickAddEntities.OpenCourse],
    value: QuickAddEntities.OpenCourse, as: Link,
    href: `/corporate-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.OpenCourse]}/${v4()}`  },
];

export const constructionQuickAddDropdownOptions= [ { text: QuickAddEntityNames[QuickAddEntities.None],
    value: QuickAddEntities.None },
{ text: QuickAddEntityNames[QuickAddEntities.Trainer],
    value: QuickAddEntities.Trainer,
    href: `/construction-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Trainer]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Venue],
    value: QuickAddEntities.Venue,
    href: `/construction-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Venue]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Organisation],
    value: QuickAddEntities.Organisation,
    href: `/construction-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.Organisation]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.ClosedCourse],
    value: QuickAddEntities.ClosedCourse, as: Link,
    href: `/construction-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.ClosedCourse]}/${v4()}`  },
{ text: QuickAddEntityNames[QuickAddEntities.OpenCourse],
    value: QuickAddEntities.OpenCourse, as: Link,
    href: `/construction-event-management/quickAdd/${CorporateQuickAddEntityDictionary[QuickAddEntities.OpenCourse]}/${v4()}`  },
];

export const quickAddDropdownOptions= [ { text: QuickAddEntityNames[QuickAddEntities.None],
    value: QuickAddEntities.None },
{ text: QuickAddEntityNames[QuickAddEntities.Trainer],
    value: QuickAddEntities.Trainer,
    href: `/police-and-court-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Trainer]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Venue],
    value: QuickAddEntities.Venue,
    href: `/police-and-court-event-management/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Venue]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.DDRSOffender],
    value: QuickAddEntities.DDRSOffender, as: Link,
    href: `/police-and-court-event-management/quickAdd/${QuickAddEntities[QuickAddEntities.DDRSOffender]}/${v4()}` },
{ text: QuickAddEntityNames[QuickAddEntities.Course],
    value: QuickAddEntities.Course, as: Link,
    href: `/police-and-court-event-management/quickAdd/${QuickAddEntityDictionary[QuickAddEntities.Course]}/${v4()}`  },
];

