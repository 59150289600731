import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { routes as policeUserRoutes } from "../police-user/routes";
import { organisationSelector } from "./selectors";
import { AppState, BusinessLineType } from "./model";
import { loadOrganisations, loadOrganisationDetail } from "./actions";
import { loadAllDigitalFees } from "@common/digitalFees";
import { loadEventTypes } from "../eventType";
import { loadDsaAreas } from "@common/dsa";
import { routes as trainerAvailabilitySearch } from "@common/crud/trainerAvailabilitySearch/routes";

export const OrganisationRoutes = {
    OrganisationDetails: "/:organisationId",
    Edit: "/edit",
    Properties: "/properties",
    SystemSettings: "/systemsettings",
    CourtOrganisations: "/courts",
    PoliceOrganisations: "/police",
    CorporateOrganisations: "/corporate",
    Create: "/create",
    Fees: "/fees",
    Contracts: "/contracts",
    AreaManagement: "/areaManagement",
    PoliceNotifications: "/policenotifications",
    Features: "/features",
    TrainerFees: "/trainer-fees",
    History: "/history",
    Communications: "/communications",
    Journey: "/journey",
};
const detailRoute = () => ({
    [OrganisationRoutes.OrganisationDetails]: {
        title: (state: AppState) => organisationSelector(state).name,
        resolve: [loadOrganisationDetail],
        [OrganisationRoutes.Edit]: {
            title: "Edit",
        },
        [OrganisationRoutes.Properties]: {
            title: "Properties"
        },
        [OrganisationRoutes.SystemSettings]: {
            title: "System Settings"
        },
        [OrganisationRoutes.Fees]: {
            title: "Fees",
            resolve: [loadAllDigitalFees, () => loadEventTypes()],
            [OrganisationRoutes.Edit]: {
                title: "Edit",
            }
        },
        [OrganisationRoutes.Contracts]: {
            title: "Contracts",
            resolve: [() => loadEventTypes()]
        },
        [OrganisationRoutes.AreaManagement]: {
            title: "Area Management",
        },
        ...policeUserRoutes,
        [OrganisationRoutes.Features]: {
            title: "Features"
        },
        [OrganisationRoutes.TrainerFees]: {
            title: "Trainer Fees",
            resolve: [() => loadEventTypes()]
        },
        [OrganisationRoutes.History]: {
            title: "Organisation History"
        },
        [OrganisationRoutes.Journey]: {
            title: "Organisation History"
        },
        [OrganisationRoutes.Communications]: {
            title: "Communications"
        },
        ...trainerAvailabilitySearch
    }
});

const courtsRoute = () => ({
    [OrganisationRoutes.CourtOrganisations]: {
        authorize: [PoliceAndDdrsAdminRole],
        resolve: [() => loadOrganisations({ options: { businessLineType: [BusinessLineType.Court] } }), loadDsaAreas],
        title: "Court Organisations",
        [OrganisationRoutes.Create]: {
            title: "Create Court Organisation"
        },
        [OrganisationRoutes.History]: {
            title: "Organisation History"
        },
        ignoreParentResolve: true,
        ...detailRoute()
    }
});

const policeRoute = () => ({
    [OrganisationRoutes.PoliceOrganisations]: {
        authorize: [PoliceAndDdrsAdminRole],
        resolve: () => loadOrganisations({ options: { businessLineType: [BusinessLineType.Police] } }),
        title: "Police Organisations",
        [OrganisationRoutes.Create]: {
            title: "Create Police Organisation"
        },
        [OrganisationRoutes.PoliceNotifications]: {
            title: "Notifications"
        },
        ...detailRoute()
    }
});

export const routes = {
    ...policeRoute(),
    ...courtsRoute()
};
