import * as React from "react";
import { Form, Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { dsaAreaOptionsSelectorWithAny, policeForceOptionsSelector } from "@common/crud/organisation/selectors";
import { venuesOptionsSelectorWithAnyForDsaAreaId, venuesOptionsSelectorWithAnyForForceId } from "@common/crud/venue/selectors";
import { EventTypeCategory } from "@common/crud/attendee/model";

export interface FilterProps {
    forceId: number;
    venueId: string;
    dsaAreaId: number;
}

export interface FiltersProps {
    disabled: boolean;
    onFilterChange: (filters: FilterProps) => void;
    eventTypeCategory: EventTypeCategory;
}

export const Filters: React.FC<FiltersProps> = ({ disabled, onFilterChange, eventTypeCategory }) => {
    const isDdrs = eventTypeCategory === EventTypeCategory.Ddrs;
    const isDors = eventTypeCategory === EventTypeCategory.Dors;

    const [forceId, setForceId] = React.useState<number>();
    const [venueId, setVenueId] = React.useState<string>();
    const [dsaAreaId, setDsaAreaId] = React.useState<number>();

    const policeForceOptions = useSelector(policeForceOptionsSelector);
    const venueOptions = useSelector(isDdrs ? venuesOptionsSelectorWithAnyForDsaAreaId(dsaAreaId) : venuesOptionsSelectorWithAnyForForceId(forceId));
    const dsaAreaOptions = useSelector(dsaAreaOptionsSelectorWithAny);

    const onForceChange = React.useCallback((_: any, { value }) => {
        setForceId(value);
        setVenueId("");
        onFilterChange({ forceId: value, venueId: "", dsaAreaId: null });
    }, [onFilterChange]);

    const onVenueChange = React.useCallback((_: any, { value }) => {
        setVenueId(value);

        const matchingVenues =  venueOptions.filter(v => v.value === value);
        const newForceId = (matchingVenues && matchingVenues.length > 0)
            ? matchingVenues[0].forceId
            : undefined;
        if (newForceId) {
            setForceId(newForceId);
        }

        onFilterChange({ forceId: newForceId ? newForceId : forceId, venueId: value, dsaAreaId });
    }, [dsaAreaId, forceId, onFilterChange, venueOptions]);

    const onDsaAreaChange = React.useCallback((_: any, { value }) => {
        setDsaAreaId(value);
        setVenueId("");
        onFilterChange({ dsaAreaId: value, venueId: "", forceId });
    }, [forceId, onFilterChange]);

    return (
        <Grid.Row>
            <Grid.Column width={8}>
                { isDors && <Form.Dropdown
                    selection
                    floating
                    disabled={disabled}
                    label="Police Force"
                    placeholder="Police Force"
                    value={forceId}
                    options={policeForceOptions}
                    onChange={onForceChange}
                    search
                />}
                { isDdrs && <Form.Dropdown
                    selection
                    floating
                    disabled={disabled}
                    label="DSA Area"
                    placeholder="DSA Area"
                    value={dsaAreaId}
                    options={dsaAreaOptions}
                    onChange={onDsaAreaChange}
                    search
                />}
            </Grid.Column>
            <Grid.Column width={8}>
                <Form.Dropdown
                    selection
                    floating
                    label="Venue"
                    disabled={disabled}
                    placeholder="Venue"
                    value={venueId}
                    options={venueOptions}
                    onChange={onVenueChange}
                    search
                />
            </Grid.Column>
        </Grid.Row>
    );
};
