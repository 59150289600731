import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SectionFiveFeedback: QuestionSection = {
    id: "5",
    title: "Feedback",
    seqNum: 4,
    summaryCriterium: [
        {
            id: "5.1",
            text: "Please record what feedback was given to the trainer:",
            typeEnum: CriteriaEnum.text
        },
        {
            id: "5.2",
            text: "General comments for improvement:",
            typeEnum: CriteriaEnum.text
        },
        {
            id: "5.3",
            text: "Areas of good practice:",
            typeEnum: CriteriaEnum.text
        },
        {
            id: "5.4",
            text: "Overall assessment outcome:",
            typeEnum: CriteriaEnum.none,
            summarySubSection: [
                {
                    id: "5.4.1",
                    text: "Meets the standard (Level 1)",
                    typeEnum: CriteriaEnum.check,
                    idsToClear: ["5.4.2", "5.4.3"]
                },
                {
                    id: "5.4.3",
                    text: "Meets the standard with minor improvements required (Level 2)",
                    typeEnum: CriteriaEnum.check,
                    idsToClear: ["5.4.1", "5.4.2"]
                },
                {
                    id: "5.4.2",
                    text: "Does not meet the standard (Level 3)",
                    typeEnum: CriteriaEnum.check,
                    idsToClear: ["5.4.1", "5.4.3"]
                }
            ]
        },
        {
            id: "5.5",
            text: "Justification:",
            typeEnum: CriteriaEnum.text
        },
        {
            id: "5.6",
            text: "Trainer’s comments:",
            typeEnum: CriteriaEnum.text
        }
    ],
    subSections: []
};
