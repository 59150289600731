import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { Button, Container, Form, Grid, InputOnChangeData } from "semantic-ui-react";
import { DigitalPlanningSessionsState, EventInstance } from "../../model";
import { digitalPlanningSessionsSelector } from "../../selectors";
import { loadDigitalTrainersForDdrs } from "@common/crud/trainer/actions";
import { trainersSelector } from "@common/crud/trainer/selectors";
import { ConfirmButton } from "@common/components";
import { DigitalPlanningEditMessage, DigitalPlanningMessageState } from "./DigitalPlanningEditMessage";
import { deleteDigitalPlanningSession, startDigitalPlanningCourseCreation } from "../../actions";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { MultiMonthDropDown } from "./MultiMonthDropDown";
import { DdrsPlannedEventsOverviewTable } from "./DdrsPlannedEventsOverviewTable";

export interface DdrsDigitalPlanningOverviewFilter {
    months?: moment.Moment[];
    trainerName?: string;
}

export const DdrsDigitalPlanningOverview: React.FC = () => {

    const selectedMonths = useSelector((state: any) => {
        const value = state.router.query.months as string;
        if (value) {
            return value.split(",")?.map(v => moment(v)) ?? [];
        }
        return [];
    });

    const trainerName = useSelector((state: any) => {
        const value = state.router.query.trainerName;
        if (value) {
            return value;
        }
        return "";
    });
    const [trainerNameSearch, setTrainerNameSearch] = React.useState<string>(trainerName);

    const planningSessions = useSelector(digitalPlanningSessionsSelector);
    const [digitallyPlannedEvents, setDigitallyPlannedEvents] = React.useState<EventInstance[]>([]);
    const [planningState, setPlanningState] = React.useState<DigitalPlanningMessageState>(
        {
            state: DigitalPlanningSessionsState.Unknown,
            courseCreationError: false,
            errorMessage: null
        });
    const [planningSessionId, setPlanningSessionId] = React.useState<string>(null);

    const dispatch = useDispatch();

    const onFilterChange = React.useCallback((filter: DdrsDigitalPlanningOverviewFilter) => {
        setDigitallyPlannedEvents([]);
        const combinedParameters: DdrsDigitalPlanningOverviewFilter = { months: selectedMonths, ...filter };
        const monthsValue = combinedParameters.months?.map(m => m.toISOString()).join(",");
        dispatch(
            push(`?months=${monthsValue}${trainerNameSearch ? `&trainerName=${trainerNameSearch}` :""}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedMonths.length, trainerNameSearch]);

    React.useEffect(() => {
        if (selectedMonths === null || selectedMonths?.length < 1) {
            return;
        }
        const params = { months: selectedMonths, trainerId: "" };
        dispatch(loadDigitalTrainersForDdrs(params));
        const selectedMonthMonths = selectedMonths?.map(m => m.month());
        const planning = planningSessions.filter(s => selectedMonthMonths.includes(s.month?.month()));
        if (planning?.length > 0) {
            const plannedEvents = planning.flatMap(p => p.plannedDigitalEvents);
            const state = planning?.findIndex(p => p.state === DigitalPlanningSessionsState.Creating) === -1 ? DigitalPlanningSessionsState.InProgress
                : DigitalPlanningSessionsState.Creating;
            const errorMessage = planning.flatMap(p => p.errorMessage).join(", ");
            const courseCreationError = planning.findIndex(p => p.courseCreationFailed) !== -1;
            setDigitallyPlannedEvents(plannedEvents);
            setPlanningState(
                {
                    state,
                    courseCreationError,
                    errorMessage
                });
            setPlanningSessionId(planning[0].id);
        } else {
            setDigitallyPlannedEvents([]);
            setPlanningSessionId(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, planningSessions, selectedMonths.length]);

    const onTrainerNameSearchChange = React.useCallback((_, data: InputOnChangeData) => setTrainerNameSearch(data.value), []);
    const trainers = useSelector(trainersSelector);
    const selectedTrainers = trainerName !== "" ? trainers.filter(t => t.fullName.includes(trainerName)) : trainers;
    const onTrainerNameSearch = React.useCallback(() => onFilterChange({ trainerName: trainerNameSearch }), [trainerNameSearch]);

    const createCourses = React.useCallback(() => {
        dispatch(startDigitalPlanningCourseCreation(selectedMonths, EventTypeCategory.Ddrs, null));
    }, [dispatch, selectedMonths]);

    const deletePlanningSession = React.useCallback(async () => {
        for (const monthToDelete of selectedMonths) {
            await dispatch(deleteDigitalPlanningSession(monthToDelete, planningSessionId, EventTypeCategory.Ddrs));
        }
        onFilterChange({ months: [], trainerName: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, planningSessionId, selectedMonths.length, onFilterChange]);

    const blockEditing =
        planningState.state > DigitalPlanningSessionsState.InProgress || selectedMonths === null || selectedMonths?.length < 1 || planningSessionId === null;
    return (
        <Container>
            <Grid className="ui form filter-font">
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h2><u>Digital Planning Summary</u></h2>
                    </Grid.Column>
                    <Grid.Column width={5} floated="right">
                        <MultiMonthDropDown hideCreateCoursesButton disabled={undefined} onFilterChange={onFilterChange} months={selectedMonths} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <DigitalPlanningEditMessage state={planningState} />
                    <Grid.Column width={5}>
                        <Form.Input
                            action={<Button icon="search" onClick={onTrainerNameSearch} />}
                            placeholder="Name"
                            value={trainerNameSearch}
                            onChange={onTrainerNameSearchChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6} floated="right" textAlign="right">
                        <ConfirmButton
                            header={"You are about to create courses"}
                            content={"Are you sure? Action cannot be undone and you will be unable to make any more changes to the current planning."}
                            onConfirm={createCourses}
                            disabled={blockEditing}>Create</ConfirmButton>
                        <ConfirmButton
                            header={"You are about to delete this planning"}
                            content={"Are you sure? Action cannot be undone."}
                            onConfirm={deletePlanningSession}
                            disabled={blockEditing}>Cancel</ConfirmButton>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ overflowX: "scroll" }}>
                    <Grid.Column width={16}>
                        <DdrsPlannedEventsOverviewTable
                            plannedEventInstances={digitallyPlannedEvents}
                            trainers={selectedMonths?.length > 0 ? selectedTrainers : []}
                            months={selectedMonths.sort((a, b) => a.diff(b)) ?? []}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
