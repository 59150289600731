import * as React from "react";
import { useSelector } from "react-redux";
import { FormikProps, Formik, FormikBag } from "formik";
import { EventInstance } from "@common/crud/eventInstance/model";
import { VenueDetailModel } from "@common/crud/venue/model";
import { MissingBookingProps, Booking, BookingDetailFormikModel } from "../models";
import { BookingDetailsDesktop } from "../../global/BookingDetailsDesktop";
import { BookingDetailsMobile } from "../../global/BookingDetailsMobile";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { useSetBeforeGenesysAuthPage } from "@common/genesys/hooks/useSetBeforeGenesysAuthPage";
import { appSettingsSelector, seatsSelector } from "../selectors";
import { eventInstanceHasSpecialRequirementsSelector } from "@booking/eventInstance/selectors";
import { Media } from "@common/global/AppMedia";
import { EventTypeApi } from "@common/crud/eventType";
import { OrganisationDetailModel } from "@common/crud/organisation/model";
import { getSpecialRequirementsSelected } from "@booking/landing/components/BookingSpecialRequirementsForm";

interface BookingDetailsFormProps {
    booking: Booking;
    eventInstance: EventInstance;
    venue: VenueDetailModel;
    isClientAdvisor: boolean;
    onSubmit: (missingProps: BookingDetailFormikModel) => void;
    seatDiscount: number;
    organisation?: OrganisationDetailModel;
}

export const BookingDetailsForm: React.FC<BookingDetailsFormProps> = (props: BookingDetailsFormProps) => {

    const hasSpecialRequirements = useSelector(eventInstanceHasSpecialRequirementsSelector);
    const genesysSettings = useSelector(appSettingsSelector).genesysSettings;
    const { booking, isClientAdvisor, eventInstance, organisation } = props;
    const seats = useSelector(seatsSelector);
    const seat = seats?.[0];

    useSetBeforeGenesysAuthPage();

    const [carFlag, setCarFlag] = React.useState<boolean>(null);

    const [flexiPayModalOpen, setFlexiPayModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (eventInstance?.eventTypeId) {
            const eventTypeApi = new EventTypeApi();
            eventTypeApi.getTypeCarFlag(eventInstance.eventTypeId).then(setCarFlag);
        }
    }, [eventInstance]);

    const requiresGenesysAuthorisation = genesysSettings.genesysSetupAndEnabled && isClientAdvisor;
    const paymentPage = requiresGenesysAuthorisation
        ? PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]
        : PaymentPageNames[PaymentPageNamesEnum.ReviewPayment];

    const specialIsOnlyOption = seat && !seat.allowAutomatic && !seat.allowManual;
    const carTypeValue = specialIsOnlyOption? "Special" : seat?.carType;

    async function onSubmit(values: BookingDetailFormikModel, formikBag: FormikBag<any, MissingBookingProps>) {
        formikBag.setSubmitting(true);
        await props.onSubmit(values);
    }

    const sessionTelephone = sessionStorage.getItem(`booking-telephone-${booking.id}`);
    const telephone = sessionTelephone && sessionTelephone !== "null" ? sessionTelephone : null;
    const sessionEmail = sessionStorage.getItem(`booking-email-${booking.id}`);
    const email = sessionEmail && sessionEmail !== "null" ? sessionEmail : null;

    return (
        <Formik
            initialValues={{
                telephone: telephone ?? booking.telephone,
                email: email ?? booking.email,
                specialRequirements: { ...seat?.specialRequirements,
                    hasSpecialRequirements: hasSpecialRequirements || getSpecialRequirementsSelected(seat?.specialRequirements) },
                carType: carTypeValue,
                specialCarDetails: seat?.specialCarDetails ?? booking.specialCarDetails,
                tAndCsConfirmed: isClientAdvisor || sessionStorage.getItem("tAndCsConfirmed") === "true",
                flexiPayPurchased: false,
                flexiPayFee: null
            }}
            onSubmit={onSubmit}
        >
            {(formikProps: FormikProps<MissingBookingProps>) => {

                async function handleContinueOnClick() {
                    formikProps.setFieldValue("nextPage", paymentPage);
                    await formikProps.submitForm();
                }

                const { errors, touched } = formikProps;
                const fields: (keyof MissingBookingProps)[] = ["email", "telephone", "tAndCsConfirmed", "specialRequirements", "carType", "specialCarDetails"];
                const allFieldsValid = fields.filter(x => (x !== "carType" && x !== "specialCarDetails")  || carFlag).every(field =>
                    !(errors[field] && touched[field]));

                return (
                    <>
                        <Media greaterThanOrEqual="tablet">
                            <BookingDetailsDesktop
                                {...props}
                                {...formikProps}
                                seats={seats}
                                handleContinueOnClick={handleContinueOnClick}
                                hasSpecialRequirements={hasSpecialRequirements}
                                isGenesysIvrEnabled={genesysSettings.useGenesysIvr}
                                flexiPayModalOpen={flexiPayModalOpen}
                                setFlexiPayModalOpen={setFlexiPayModalOpen}
                                specialIsOnlyOption={specialIsOnlyOption}
                                allFieldsValid={allFieldsValid}
                                carFlag={carFlag}
                                organisation={organisation}
                            />
                        </Media>
                        <Media lessThan="tablet">
                            <BookingDetailsMobile
                                {...props}
                                {...formikProps}
                                handleContinueOnClick={handleContinueOnClick}
                                hasSpecialRequirements={hasSpecialRequirements}
                                flexiPayModalOpen={flexiPayModalOpen}
                                setFlexiPayModalOpen={setFlexiPayModalOpen}
                                specialIsOnlyOption={specialIsOnlyOption}
                                carFlag={carFlag}
                                organisation={organisation}
                                allFieldsValid={allFieldsValid}
                            />
                        </Media>
                    </>
                );
            }}
        </Formik>
    );
};
