import * as React from "react";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { AttendeeDetailModel } from "@common/crud/attendee/model";
import { DorsStatusLabelWithEdit } from "@common/crud/attendee/components/details/DorsStatusLabelWithEdit";
import { AttendanceIdLabelWithEdit } from "@common/crud/attendee/components/details/AttendanceIdLabelWithEdit";
import { ReferredSchemeLabelWithEdit } from "@common/crud/attendee/components/details/ReferredSchemeLabelWithEdit";
import { useSelector } from "react-redux";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { GetCurrentAndNextDorsStatesResponse } from "@common/crud/common/LookupResponse";
import { DorsBooking } from "@common/crud/dorsBooking";
import { EventType } from "../../../eventType";

export interface OwnProps {
    attendee: AttendeeDetailModel;
    booking: DorsBooking;
    liveDorsResponse: GetCurrentAndNextDorsStatesResponse;
    liveExpiryDate: moment.Moment;
    reloadNextDorsState: (reload: boolean) => void;
    eventTypes: EventType[];
}

export const DorsDetails: React.FC<OwnProps> = ({ attendee, booking, liveDorsResponse, liveExpiryDate, reloadNextDorsState, eventTypes }) => {
    const chargingPoliceForce = useSelector(policeOrganisationByDorsId(attendee.issuingForceId));
    return (
        <>
            <LabelAndValue label="Driver number" value={attendee.drivingLicenceNumber} />
            <LabelAndValue label="Ticket Id" value={attendee.ticketId} />
            <ReferredSchemeLabelWithEdit
                booking={booking}
                eventTypeId={booking?.eventTypeId}
                eventTypes={eventTypes} />
            <LabelAndValue
                label="Completion Date"
                value={liveExpiryDate?.format(DateFormat.DayMonthYear)} />
            <LabelAndValue
                label="Referring Police Force"
                value={(chargingPoliceForce && chargingPoliceForce.name) || (attendee.forceName ?? "Non TTC Police Force")} />
            <DorsStatusLabelWithEdit
                attendee={attendee}
                updateDorsStatusPossibilities={liveDorsResponse}
                reloadNextDorsState={reloadNextDorsState} />
            <AttendanceIdLabelWithEdit
                attendanceId={attendee.externalAttendeeId}
                attendeeId={attendee.id}
                eventInstanceId={attendee.eventInstanceId}
                dorsBookingId={attendee.correlationId} />
            <LabelAndValue
                label="Deletion Date"
                value={attendee.deletionDate ? attendee.deletionDate.format(DateFormat.DayMonthYear) : "Not Set"} />
        </>

    );
};
