import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { SortDirection, SortOptions } from "@common/model";

import { Payload } from "@neworbit/redux-helpers";

import { toast } from "@common/toasts";

import * as actions from "./actiontypes";
import {
    PoliceUserListModel,
    PoliceUserDetailModel,
    PoliceUserCreateModel,
    PoliceUserEditModel,
    SearchOptions,
    AppState
} from "./model";
import { PoliceUserApi } from "./policeUserApi";

export type UserAction =
    ({ type: actions.LOAD_POLICE_USERS_SUCCESS } & Payload<PoliceUserListModel[]>)
    | ({ type: actions.CREATE_POLICE_USER_SUCCESS } & Payload<PoliceUserDetailModel>)
    | ({ type: actions.LOAD_POLICE_USER_DETAIL_SUCCESS } & Payload<PoliceUserDetailModel>)
    | ({ type: actions.SAVE_POLICE_USER_SUCCESS } & Payload<PoliceUserDetailModel>)
    | ({ type: actions.SORT_POLICE_USERS } & Payload<SortOptions<PoliceUserListModel>>)
    | ({ type: actions.POLICE_SIGNUP_INVITATION_SUCCESS } & Payload<string>);

export const loadUsersSuccess = (payload: PoliceUserListModel[]): UserAction => ({
    payload,
    type: actions.LOAD_POLICE_USERS_SUCCESS
});

export const createUserSuccess = (payload: PoliceUserDetailModel): UserAction => ({
    payload,
    type: actions.CREATE_POLICE_USER_SUCCESS
});

export const saveUserSuccess = (payload: PoliceUserDetailModel): UserAction => ({
    payload,
    type: actions.SAVE_POLICE_USER_SUCCESS
});

export const signupInvitationSuccess = (id: string): UserAction => ({
    payload: id,
    type: actions.POLICE_SIGNUP_INVITATION_SUCCESS
});

export function createUser({ user, path, organisationId }: { user: PoliceUserCreateModel; path: string; organisationId?: string }) {
    const api = new PoliceUserApi(organisationId);
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(
            dispatch,
            "createUser",
            api.create(user)
        );
        dispatch(createUserSuccess(result));
        dispatch(push(`${path}/${result.id}`));
    };
}

export function saveUser({ user, organisationId, path }: {user: PoliceUserEditModel; organisationId?: string; path?: string}) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceUserApi(organisationId);
        const result = await loadAndTrack(
            dispatch,
            "saveUser",
            api.save(user)
        );

        dispatch(saveUserSuccess(result));
        if (path !== undefined) {
            dispatch(push(path));
        }
    };
}

export function loadUsers({ options, organisationId }: { options?: SearchOptions; organisationId?: string } = {}) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceUserApi(organisationId);
        const result = await loadAndTrack(
            dispatch,
            "loadUsers",
            api.getAll(options)
        );
        dispatch(loadUsersSuccess(result));
    };
}

export const loadUserDetailSuccess = (user: PoliceUserDetailModel): UserAction => ({
    payload: user,
    type: actions.LOAD_POLICE_USER_DETAIL_SUCCESS
});

export function loadUserDetail({ userId, organisationId }: { userId: string; organisationId?: string }) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const user = getState().policeUsers.filter(c => c.id === userId)[0];

        if (
            user === undefined ||
            user.detailUpdated === undefined ||
            user.detailUpdated.isBefore(user.listUpdated)
        ) {
            const action =
                user === undefined ? createUserSuccess : loadUserDetailSuccess;

            const api = new PoliceUserApi(organisationId);
            const result = await loadAndTrack(
                dispatch,
                "loadUserDetail",
                api.detail(userId)
            );
            dispatch(action(result));
        }
    };
}

export function sendSignupInvitation(id: string, organisationId: string) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceUserApi(organisationId);
        await loadAndTrack(
            dispatch,
            "sendSignupInvitation",
            api.sendSignupInvitation(id)
        );
        toast.info("Signup email requested");
        dispatch(signupInvitationSuccess(id));
    };
}

export function updateAdb2cAccount(id: string, organisationId: string) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceUserApi(organisationId);
        const result = await loadAndTrack(
            dispatch,
            "updateAdb2cAccount",
            api.updateAdb2cAccount(id)
        );
        toast.info(`User account ${result.accountDisabled ? "disabled" : "enabled"}`);
        dispatch(saveUserSuccess(result));
    };
}

export function updateReceivesNotifications(id: string, organisationId: string, receivesNotifications: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new PoliceUserApi(organisationId);
        const result = await loadAndTrack(
            dispatch,
            "updateReceivesNotifications",
            api.updateReceivesNotifications(id, receivesNotifications)
        );
        dispatch(saveUserSuccess(result));
    };
}

export const sortUsers = (property: keyof PoliceUserListModel, direction: SortDirection = "asc"): UserAction => ({
    type: actions.SORT_POLICE_USERS,
    payload: { property, direction }
});
