import axios from "axios";

import { CurrentUser } from "./model";
import { BusinessLineType } from "@common/crud/organisation/model";

export class CurrentUserApi {

    private readonly apiUrl = "/api/currentuser";

    public async get(businessLine?: BusinessLineType): Promise<CurrentUser> {
        const url = businessLine
            ? `${this.apiUrl}?forbl=${businessLine}`
            : this.apiUrl;
        const response = await axios.get(url);
        return response.data as CurrentUser;
    }
}
