import axios from "axios";
import moment from "moment";
import { BankHoliday } from "./model";

export class BankHolidaysApi {
    private readonly apiUrl = "/api/bankHolidays";

    public async get(): Promise<BankHoliday[]> {
        const response = await axios.get(this.apiUrl);
        return response.data.map((h: BankHoliday) => ({ ...h, date: moment(h.date) }));
    }
}
