import { EventType } from "@common/crud/eventType";
import * as React from "react";
import moment from "moment";
import { Organisation } from "@common/crud/organisation";

export function useFlexiPayFee(eventType: EventType, organisation: Organisation) {
    const flexiPayFee = React.useMemo(() => {
        const now = moment();
        let currentFee;
        if (organisation?.chargesOwnFlexiPayFees) {
            currentFee = organisation.flexiPayFees.map(f => [f.value, f.effectiveDate.diff(now)]).reverse().find(f => f[1] <= 0);
        } else {
            currentFee = eventType?.flexiPayFees?.map(f => [f.fee, f.effectiveDate.diff(now)]).reverse().find(f => f[1] <= 0);
        }

        return currentFee?.length > 0 ? currentFee[0] : 0;
    }, [eventType, organisation]);

    return flexiPayFee;
}
