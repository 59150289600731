import * as React from "react";
import { Container, Dropdown, DropdownProps, Form, Header } from "semantic-ui-react";
import { ClearTrainerAvailabilityEnum, OtherCancellationReason, trainerCancellationReason } from "../../model";
import { SubActionModalProps } from "./SubActionModalProps";
import { optionsFromText } from "@common/crud/common";
import { bulkRemoveTrainer } from "../../actions";
import { useDispatch } from "react-redux";
import { RemoveTrainerAvailability } from "@common/crud/eventInstance/components/details/RemoveTrainerAvailability";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";

export const RemoveTrainerAction: React.FC<SubActionModalProps> = ({
    triggerDispatchAction,
    setActionComplete,
    selectedEventInstanceIds,
    children }) => {

    const dispatch = useDispatch();

    const [reasonForCancellation, setReasonForCancellation] = React.useState<string>(trainerCancellationReason[0]);
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [otherReason, setOtherReason] = React.useState<string>();
    const [clearTrainerAvailability, setClearTrainerAvailability] = React.useState<ClearTrainerAvailabilityEnum>(ClearTrainerAvailabilityEnum.No);
    const templates = optionsFromText(trainerCancellationReason);

    const otherCancellationReasonChoosen = reasonForCancellation === OtherCancellationReason;

    const onChange = React.useCallback((_: any, { value }: DropdownProps) => {
        setReasonForCancellation(value as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reasonForCancellation, templates, selectedEventInstanceIds]);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    React.useEffect(
        () => {
            async function remove() {
                await dispatch(bulkRemoveTrainer(selectedEventInstanceIds, clearTrainerAvailability, reasonForCancellation, otherReason));
                setActionComplete(true);
            }
            if ((!otherCancellationReasonChoosen || otherReasonValid) && triggerDispatchAction) {
                remove();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerDispatchAction, clearTrainerAvailability, otherCancellationReasonChoosen, otherReasonValid]);

    return (
        <Container>
            <Form>
                {children()}
                <Header size="small">Please confirm reason for removing trainer(s)</Header>
                <Dropdown
                    placeholder='Reason'
                    fluid
                    selection
                    value={reasonForCancellation}
                    options={templates}
                    onChange={onChange}
                />
                {otherCancellationReasonChoosen && (
                    <Input.Textarea
                        value={otherReason}
                        label="Other Reason"
                        validation={[validators.validLength({ max: 500 })]}
                        onChange={onOtherReasonChanged}
                    />
                )}
                <RemoveTrainerAvailability setClearTrainerAvailability={setClearTrainerAvailability} clearTrainerAvailability={clearTrainerAvailability} />
            </Form>
        </Container>
    );
};
