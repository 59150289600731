import * as React from "react";
import { Grid, Checkbox, Divider } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { OrderPaymentStatus } from "../model";

export interface CancelModalAttendeeRowProps {
    attendee: AttendeeListModel;
    selected: boolean;
    toggleAttendee: (attendeeId: string) => void;
}

export const CancelModalAttendeeRow: React.FC<CancelModalAttendeeRowProps> = ({
    attendee,
    selected,
    toggleAttendee,
}) => {
    const onAttendeeToggle = React.useCallback(() => {
        toggleAttendee(attendee.id);
    }, [attendee, toggleAttendee]);

    const blockAttendeeSelection =
        attendee.fromOrderWithBookNowPayLater &&
        attendee.bookNowPayLaterPaymentStatus === OrderPaymentStatus.Paid;

    return (
        <>
            <Grid.Row className="half-padding">
                <Grid.Column width={7}>{attendee.fullName}</Grid.Column>
                <Grid.Column width={6}>
                    {attendee.drivingLicenceNumber}
                </Grid.Column>
                {blockAttendeeSelection && (
                    <Grid.Column
                        width={3}
                        title="Cannot select attendee, the invoice is already paid"
                    >
                        <Checkbox checked={false} disabled />
                    </Grid.Column>
                )}
                {!blockAttendeeSelection && (
                    <Grid.Column width={3}>
                        <Checkbox
                            checked={selected}
                            onClick={onAttendeeToggle}
                        />
                    </Grid.Column>
                )}
            </Grid.Row>
            <Grid.Row className="without-padding">
                <Grid.Column>
                    <Divider />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};
