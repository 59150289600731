import * as React from "react";
import { Button, Divider, Grid, Segment, Tab } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Detail } from "@neworbit/simpleui-detail";
import { CoreDetailProps, DetailProps } from "../Detail";
import { SignupInvitationButton } from "./SignupInvitationButton";
import { Adb2cAccountButton } from "./Adb2cAccountButton";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export const CoreDetails: React.FC<CoreDetailProps> = ({ user }) => (
    <Grid>
        <Detail label="Forename" value={user.forename} labelWidth={5} valueWidth={11} />
        <Detail label="Surname" value={user.surname} labelWidth={5} valueWidth={11} />
        <Detail label="Email" value={user.email} labelWidth={5} valueWidth={11} />
        <Detail
            label="Last Login"
            value={user.lastLogin && user.lastLogin?.format(DateFormat.ShortWithTime)}
            labelWidth={5}
            valueWidth={11} />
    </Grid>
);

const CoreDetailsWithActions: React.FC<DetailProps> = (props) => {
    return (
        <div>
            <CoreDetails user={props.user} />
            <Divider />
            <Segment basic clearing vertical>
                <div className="button-container right-align">
                    <Button icon="pencil" content="Edit" as={Link} href={`${props.path}/edit`} />
                    <SignupInvitationButton user={props.user} />
                    <Adb2cAccountButton user={props.user} />
                </div>
            </Segment>
        </div>
    );
};

export const coreDetailsTab = (props: DetailProps) => ({
    path: "",
    menuItem: "Details",
    authorize: true,
    render: () => <Tab.Pane><CoreDetailsWithActions {...props} /></Tab.Pane>
});
