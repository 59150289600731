import * as React from "react";
import { Modal } from "@common/components";
import { Input } from "@neworbit/simpleui-input";
import { Button, Dimmer, Form, Loader } from "semantic-ui-react";
import { EventInstance } from "../../model";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { validators } from "not-valid";
import { BookingTypeEnum } from "@common/crud/eventType/model";
import { toast } from "@common/toasts";
import { useDispatch } from "react-redux";
import { saveFinanceDetails } from "../../actions";
import { PurchaseOrder } from "@common/crud/organisation/model";
import { PurchaseOrderDropdown } from "@common/crud/organisation/components/PurchaseOrderDropdown";
import { OrganisationApi } from "@common/crud/organisation";
import { filterAndSortPurchaseOrders } from "../../helpers";

interface FinanceDetailsModalProps {
    eventInstance: EventInstance;
}

const FinanceDetailsModal: React.FC<FinanceDetailsModalProps> = ({ eventInstance }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [formErrors, setFormErrors] = React.useState<Set<string>>(new Set<string>());

    const [purchaseOrders, setPurchaseOrders] = React.useState<PurchaseOrder[]>([]);
    const [existingPurchaseOrdersList, setExistingPurchaseOrders] = React.useState<PurchaseOrder[]>(eventInstance.existingPurchaseOrdersList);
    const [purchaseOrderNumbersList, setPurchaseOrderNumbers] = React.useState<string[]>(eventInstance.purchaseOrderNumbersList);
    const [price, setPrice] = React.useState<number>(eventInstance.price);
    const [closedCourseFee, setClosedCourseFee] = React.useState<number>(eventInstance.closedCourseFee);
    const [uploadFee, setUploadFee] = React.useState<number>(eventInstance.uploadFee);
    const [miscellaneousOrganisationFee, setMiscellaneousOrganisationFee] = React.useState<number>(eventInstance.miscellaneousOrganisationFee);
    const [financeDetailsNote, setFinanceDetailsNote] = React.useState<string>(eventInstance.financeDetailsNote);

    const totalValueRemaining = existingPurchaseOrdersList?.filter(po => po).reduce((total, po) => {
        let valueRemaining = po.valueRemaining;

        if (po.eventInstanceUses && po.eventInstanceUses[eventInstance.id]) {
            valueRemaining += po.eventInstanceUses[eventInstance.id];
        }

        return total + valueRemaining;
    }, 0);
    const totalCourseFees = miscellaneousOrganisationFee + closedCourseFee + (uploadFee * eventInstance.maxNumberOfAttendees);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const getPurchaseOrders = async () => {
            setIsLoading(true);
            const response = filterAndSortPurchaseOrders(
                await new OrganisationApi().getPurchaseOrders(eventInstance.corporateOrganisationId),
                eventInstance.id,
                eventInstance.startDate);
            setPurchaseOrders(response);
            setIsLoading(false);
        };

        if (eventInstance.corporateOrganisationId && open) {
            getPurchaseOrders();
        }
    }, [eventInstance.corporateOrganisationId, eventInstance.id, eventInstance.startDate, open]);

    const loadDefaultEventInstanceData = React.useCallback((ei: EventInstance) => {
        setFormErrors(new Set<string>());
        setExistingPurchaseOrders(ei.existingPurchaseOrdersList);
        setPurchaseOrderNumbers(ei.purchaseOrderNumbersList);
        setPrice(ei.price);
        setClosedCourseFee(ei.closedCourseFee);
        setUploadFee(ei.uploadFee);
        setMiscellaneousOrganisationFee(ei.miscellaneousOrganisationFee);
        setFinanceDetailsNote(ei.financeDetailsNote);
    }, []);

    React.useEffect(() => {
        loadDefaultEventInstanceData(eventInstance);
    }, [eventInstance, loadDefaultEventInstanceData]);

    const updateFormErrors = React.useCallback((formField: string, valid: boolean) => {
        const newFormErrors =  new Set(formErrors);
        if (newFormErrors.has(formField) && valid) {
            newFormErrors.delete(formField);
        } else if (!newFormErrors.has(formField) && !valid) {
            newFormErrors.add(formField);
        }
        setFormErrors(newFormErrors);
    }, [formErrors, setFormErrors]);

    const handleChangeExistingPurchaseOrders = (index: number) => (value: string, valid: boolean) => {
        onChangeExistingPurchaseOrders(index, value, valid);
    };

    const onChangeExistingPurchaseOrders = React.useCallback((index: number, value: string, valid: boolean) => {
        const selectedPurchaseOrder = purchaseOrders?.find(po => po.number === value);

        setPurchaseOrderNumbers(prevPurchaseOrderNumbers => {
            const newPurchaseOrderNumbers = [...(prevPurchaseOrderNumbers || [])];
            newPurchaseOrderNumbers[index] = selectedPurchaseOrder?.number !== "Not Set" ? selectedPurchaseOrder?.number : "";
            return newPurchaseOrderNumbers;
        });

        setExistingPurchaseOrders(prevExistingPurchaseOrders => {
            const newExistingPurchaseOrders = [...(prevExistingPurchaseOrders || [])];
            newExistingPurchaseOrders[index] = selectedPurchaseOrder;
            return newExistingPurchaseOrders;
        });

        updateFormErrors("existingPurchaseOrdersList", valid);
    }, [purchaseOrders, updateFormErrors]);

    const onAddPurchaseOrder = React.useCallback(() => {
        setExistingPurchaseOrders([...(existingPurchaseOrdersList || []), null]);
    }, [existingPurchaseOrdersList]);

    const onChangePurchaseOrderNumber = React.useCallback((value: string, valid: boolean) => {
        setPurchaseOrderNumbers([value]);
        updateFormErrors("purchaseOrderNumbersList", valid);
    }, [updateFormErrors]);

    const onChangePrice = React.useCallback((value: number, valid: boolean) => {
        setPrice(value);
        updateFormErrors("price", valid);
    }, [updateFormErrors]);

    const onChangeClosedCourseFee = React.useCallback((value: number, valid: boolean) => {
        setClosedCourseFee(value);
        updateFormErrors("closedCourseFee", valid);
    }, [updateFormErrors]);

    const onChangeUploadFee = React.useCallback((value: number, valid: boolean) => {
        setUploadFee(value);
        updateFormErrors("uploadFee", valid);
    }, [updateFormErrors]);

    const onChangeMiscellaneousOrganisationFee = React.useCallback((value: number, valid: boolean) => {
        setMiscellaneousOrganisationFee(value);
        updateFormErrors("miscellaneousOrganisationFee", valid);
    }, [updateFormErrors]);

    const onChangeFinanceDetailsNote = React.useCallback((value: string, valid: boolean) => {
        setFinanceDetailsNote(value);
        updateFormErrors("financeDetailsNote", valid);
    }, [updateFormErrors]);

    const openModal = React.useCallback(() => {
        setOpen(true);
    }, []);

    const closeModal = React.useCallback(() => {
        setOpen(false);
        loadDefaultEventInstanceData(eventInstance);
    }, [eventInstance, loadDefaultEventInstanceData]);

    const onSave = React.useCallback(async () => {
        if (formErrors.size !== 0) {
            toast.error("Please provide valid data");
            return;
        }

        const success = await dispatch(saveFinanceDetails(eventInstance.id, {
            existingPurchaseOrdersList: existingPurchaseOrdersList.filter(po => po),
            purchaseOrderNumbersList: purchaseOrderNumbersList.filter(num => num),
            price,
            closedCourseFee,
            uploadFee,
            miscellaneousOrganisationFee,
            financeDetailsNote
        }));

        if (success) {
            setOpen(false);
        }
    }, [closedCourseFee,
        dispatch,
        eventInstance.id,
        existingPurchaseOrdersList,
        financeDetailsNote,
        formErrors.size,
        miscellaneousOrganisationFee,
        price,
        purchaseOrderNumbersList,
        uploadFee]);

    const isOpen = eventInstance.bookingType === BookingTypeEnum.Open;
    const isClosed = eventInstance.bookingType === BookingTypeEnum.Closed;

    return (
        <>
            <Button className="link-button aligned-to-header" onClick={openModal}>Update Finance Details</Button>
            <Modal open={open}>
                <Modal.Header>
                    Update Finance Details
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <h3>Finance details</h3>
                        {isOpen && (
                            <CurrencyInput
                                label={"Open Course Delegate Fee"}
                                value={price}
                                onChange={onChangePrice}
                                required
                            />
                        )}
                        {isClosed && (
                            <>
                                {isLoading ? (
                                    <Dimmer active>
                                        <Loader />
                                    </Dimmer>) : purchaseOrders && (
                                    <>
                                        {existingPurchaseOrdersList?.map((purchaseOrder, index) => (
                                            <PurchaseOrderDropdown
                                                key={purchaseOrder?.id}
                                                existingPurchaseOrder={purchaseOrder}
                                                purchaseOrders={purchaseOrders.filter(po => !existingPurchaseOrdersList.includes(po) ||
                                                    po.number === existingPurchaseOrdersList[index]?.number)}
                                                onChange={handleChangeExistingPurchaseOrders(index)}
                                            />
                                        ))}
                                        <Button
                                            className="margin-bottom"
                                            content="Add Purchase Order"
                                            onClick={onAddPurchaseOrder}
                                            disabled={purchaseOrders?.length === existingPurchaseOrdersList?.length ||
                                                (existingPurchaseOrdersList && existingPurchaseOrdersList.some(po => !po || po.number === "Not Set")) ||
                                                (totalValueRemaining >= totalCourseFees)}
                                        />
                                    </>
                                )}
                                <Input.Text
                                    label="Purchase Order no"
                                    placeholder="Provide Purchase Order no"
                                    value={purchaseOrderNumbersList?.length > 0 ?
                                        purchaseOrderNumbersList.filter(num => num && num !== "Not Set").join(", ") : ""}
                                    onChange={onChangePurchaseOrderNumber}
                                    disabled={existingPurchaseOrdersList && existingPurchaseOrdersList.some(po => po !== undefined && po?.number !== "Not Set")}
                                />
                                <CurrencyInput
                                    label={"Closed Course Organisation Fee"}
                                    value={closedCourseFee}
                                    onChange={onChangeClosedCourseFee}
                                    required
                                />
                                {eventInstance.delegatesRequired !== false && (
                                    <CurrencyInput
                                        label={"Upload fee per delegate"}
                                        value={uploadFee}
                                        onChange={onChangeUploadFee}
                                    />
                                )}
                                <CurrencyInput
                                    label={"Misc Org Fee"}
                                    value={miscellaneousOrganisationFee}
                                    onChange={onChangeMiscellaneousOrganisationFee}
                                />
                            </>
                        )}
                        <Input.Textarea
                            label="Note"
                            validation={[validators.validLength({ max: 1000 })]}
                            value={financeDetailsNote}
                            onChange={onChangeFinanceDetailsNote}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSave}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { FinanceDetailsModal };
