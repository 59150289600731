import * as React from "react";
import { Button, ButtonProps, Grid, Icon } from "semantic-ui-react";

interface CertificateActionsProps{
attendeeHasDocument: boolean;
downloadLink: string;
createDisabled: boolean;
createOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => void;
buttonText: string;
}

export const CertificateActions: React.FC<CertificateActionsProps>=
({ attendeeHasDocument,  createOnClick, downloadLink, createDisabled, buttonText: onClickText }) => {

    return (!attendeeHasDocument
        ?<Button content={onClickText}
            onClick={createOnClick} disabled={createDisabled} />
        :<Grid width={6} columns={2}>
            <Grid.Column width={3}>
                <a href={downloadLink}>
                    <Icon name="download" link  />
                </a>
            </Grid.Column>
            <Grid.Column width={1}>
                <a href="#">
                    <Icon name="mail" link />
                </a>
            </Grid.Column>
        </Grid>);
};
