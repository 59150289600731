import { OrganisationDetailModel } from "@common/crud/organisation/model";
import axios from "axios";
import moment from "moment";

export class BookingOrganisationApi {

    private readonly apiUrl = "/api/organisation";

    public async getOrganisationByForceId(forceId: number): Promise<OrganisationDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${forceId}`);
        const model = response.data as OrganisationDetailModel;
        return this.parseModel(model);
    }

    public parseModel(model: OrganisationDetailModel): OrganisationDetailModel {
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
            flexiPayFees: model.flexiPayFees && model.flexiPayFees.map(f => ({
                ...f,
                effectiveDate: moment(f.effectiveDate)
            }))
        };
    }
}
