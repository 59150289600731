import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const StickingToTheRulesSection: QuestionSection = {
    id: "7",
    title: "Module 4",
    seqNum: 6,
    subSections: [{
        id: "7.1",
        text: "Sticking to the rules 25 min",
        seqNum: 1,
        criteria: [
            { id: "1", text: "Map re-capped" },
            { id: "2", text: "Encouraged clients to view their own choices from the perspective of other road " +
                    "users – and allow them to hear how their behaviour is seen by their peers" },
            { id: "3", text: "Rules, no rules, people who break rules, link to psychologists." },
            { id: "4", text: "Red light, two key messages" },
            { id: "5", text: "Cyclist, shared roads" },
            { id: "6", text: "Tailgaters, risk aspect" },
            { id: "7", text: "How do you want others to see you when you are driving? Write down, ask one " +
                    "client, then ask other clients what could be done to achieve that. All clients to write their own solutions." },
            { id: "8", text: "Overtaking, link to how good a driver do you think you are? Over-estimate our driving ability." },
            { id: "9", text: "Bias video, clients to be encouraged to make notes. Ask after what stood out for them." }
        ]
    }],
    summaryCriterium: [{ id: "10", text: "", typeEnum: CriteriaEnum.text }]
};
