import * as React from "react";
import moment, { Moment } from "moment";
import { SuggestedTimes } from "./SuggestedTimes";
import { sortStringDate } from "@common/crud/common/helpers";
import { Button, Grid } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export interface StartTimeForSessionsPickerProps {
    updateStartTimesForSessions: (startTimes: Dictionary<moment.Duration[]>) => void;
    startTimesForSessions: Dictionary<moment.Duration[]>;
    showErrors: boolean;
    sessionType?: string;
}

export const StartTimeForSessionsPicker: React.FC<StartTimeForSessionsPickerProps> = ({
    startTimesForSessions, updateStartTimesForSessions, showErrors, sessionType }) => {
    const addTime = React.useCallback((time: Moment, key: number, session: number) => {
        const parsedTime = time? moment.duration(time.format(DateFormat.Time)) : null;
        const newTimes = startTimesForSessions;
        newTimes[key][session] = parsedTime;

        if (newTimes[key].every(x => x && x.isValid())) {
            newTimes[key] = newTimes[key].sort(sortStringDate);
        }

        updateStartTimesForSessions(newTimes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTimesForSessions]);

    const getKeys = (dict: Dictionary<moment.Duration[]>) => Object.entries(dict).map(
        ([key]) => {
            return Number.parseInt(key, 10);
        });

    const addSession = React.useCallback((e: any) => {
        e.preventDefault();
        const keys = getKeys(startTimesForSessions);
        const biggestKey = keys.length > 0 ? (Math.max(...keys) + 1) : 1;
        const newTimes = startTimesForSessions;
        newTimes[biggestKey ?? 1] = [];

        updateStartTimesForSessions(newTimes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTimesForSessions]);

    const removeSession = React.useCallback((e: any) => {
        e.preventDefault();
        const keys = getKeys(startTimesForSessions);
        const biggestKey = Math.max(...keys);
        const newTimes = startTimesForSessions;
        delete newTimes[biggestKey];

        updateStartTimesForSessions(newTimes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTimesForSessions]);

    const sessionTypeLabel = sessionType ?? "";
    return (
        <>
            <Grid.Row>
                <Button onClick={addSession}>{`Add ${sessionTypeLabel} session`}</Button>
                <Button onClick={removeSession}>Remove latest session</Button>
            </Grid.Row>
            {
                Object.entries(startTimesForSessions).map(
                    ([key, value]) => {
                        const numberKey = Number.parseInt(key, 10);
                        const label = `${sessionTypeLabel} Start times for ${key} Session${numberKey > 1 ? "s":""} per day`;

                        return (<SuggestedTimes
                            key={key}
                            values={value}
                            requiredAmount={numberKey}
                            addTime={addTime}
                            showError={showErrors}
                            label={label}
                        />);
                    })}
        </>
    );
};
