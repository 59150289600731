import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Grid, Tab, TabProps } from "semantic-ui-react";
import { OrganisationState } from "@common/crud/organisation";
import { AppState, ConfigurationModel } from "../model";
import { basePathSelector, configurationSelector, hierarchySelector } from "../selectors";
import { PaneDefinition } from "./panes";

interface OwnProps {
    panes: PaneDefinition[];
    title?: string;
    verticalMenu?: boolean;
}

interface StateProps {
    configuration: ConfigurationModel;
    hierarchy: string[];
    basePath: string;
    tab?: string;
}

interface DispatchProps {
    push: (path: string) => void;
}

type ConfigurationProps = OwnProps & StateProps & DispatchProps;

class ConfigurationInternal extends React.Component<ConfigurationProps> {

    public render() {
        const { tab, title, verticalMenu } = this.props;
        const panes = this.getPanes();
        const activeIndex = panes.slice(1).findIndex(p => tab && tab.startsWith(p.path)) + 1;
        const menuProps = verticalMenu ? { vertical: true, fluid: true } : undefined;
        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{title || "Configuration"}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Tab panes={panes} menu={menuProps} onTabChange={this.handleTabChange} activeIndex={activeIndex} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private getPanes() {
        const { panes, configuration, hierarchy } = this.props;
        return panes.map(pane => ({
            path: pane.route,
            menuItem: pane.label,
            render: () => <Tab.Pane>{pane.render(configuration, hierarchy)}</Tab.Pane>
        }));
    }

    private handleTabChange = (event: any, { activeIndex }: TabProps) => {
        const { basePath } = this.props;
        const panes = this.getPanes();
        const pane = panes[activeIndex];
        this.props.push(`${basePath}${pane.path}`);
    };
}

const currentTab = (state: AppState) => state.router.pathname.substring(basePathSelector(state).length);

function mapStateToProps(state: AppState & OrganisationState): StateProps {
    const tab = currentTab(state);
    return {
        configuration: configurationSelector(state),
        hierarchy: hierarchySelector(state),
        basePath: basePathSelector(state),
        tab
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Configuration = connect(mapStateToProps, mapDispatchToProps)(ConfigurationInternal);
