import { Seat } from "@booking/seat";
import { adminAppEndpointSelector } from "@common/crud/common/selectors";
import { EventInstance } from "@common/crud/eventInstance";
import { EventInstanceCancellationUndoApi } from "@common/crud/eventInstance/eventInstanceCancellationUndoApi";
import { useInterval } from "@common/hooks/useInterval";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm, Message, Segment } from "semantic-ui-react";
import { Attendee, AttendeeApi } from "@common/crud/attendee";
import { UndoAttendeeCancellation } from "@common/crud/attendee/actions";
import { Apps } from "@common/model";
import { appSelector } from "@common/crud/common/selectors";

interface UndoCancellationModalProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    eventInstance: EventInstance;
    attendee: Attendee;
    setLoadingNewAttendee: (loadingNewAttendee: boolean) => void;
}

export const UndoCancellationModal: React.FC<UndoCancellationModalProps> = ({ modalOpen, setModalOpen, eventInstance, attendee, setLoadingNewAttendee }) => {
    const dispatch = useDispatch();
    const adminAppEndpoint = useSelector(adminAppEndpointSelector);
    const isAdminApp = useSelector(appSelector) === Apps.Admin;

    const [seatsStillAvailable, setSeatsStillAvailable] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newSeat, setNewSeat] = React.useState<Seat>();
    const [newAttendeeId, setNewAttendeeId] = React.useState<string>();
    const [displayWarning, setDisplayWarning] = React.useState<boolean>(false);

    const cancelButtonProps = { content: "Cancel", className: "cancel-action" };

    const confirmButtonProps = { content: `${seatsStillAvailable ? "Continue" : "Go back"}`, className: "green" };

    const seatsAvailableContent = displayWarning?
        // eslint-disable-next-line max-len
        "Warning - The attendee's place on this course is no longer available. Reverting this attendee's cancellation will result in the course being overbooked. Would you like to proceed?" :
        "This will add the attendee back to the course on Alaska and we will contact DORS to advise";

    const header = seatsStillAvailable ? "Are you sure you want to un-do this cancellation?" : "There are no available seats on this course";

    const content = seatsStillAvailable ? seatsAvailableContent
        : `This course currently has ${eventInstance.seatCount} attendees booked. Please remove another attendee from this course before reverting the
        cancellation of this attendee.`;

    const onCancel = React.useCallback(() => setModalOpen(false), [setModalOpen]);

    const onConfirm = React.useCallback(async () => {
        if (seatsStillAvailable) {
            const result = UndoAttendeeCancellation(attendee.id, eventInstance.id);
            result(dispatch).then(value => {
                setNewSeat(value);
                // eslint-disable-next-line eqeqeq
                if (value?.id != null) {
                    setLoading(true);
                    setLoadingNewAttendee(true);
                }
                return;
            });
        }
        setModalOpen(false);
    }
    , [attendee.id, dispatch, eventInstance.id, seatsStillAvailable, setLoadingNewAttendee, setModalOpen]);

    React.useEffect(() => {
        async function loadCanUndoCancellation() {
            const api = new EventInstanceCancellationUndoApi();
            const canUndoModel = await api.canUndoAttendeeCancellation(eventInstance.id);

            setDisplayWarning(canUndoModel.displayWarning);
            setSeatsStillAvailable(canUndoModel.canUndo);
        };
        if (eventInstance?.id && isAdminApp) {
            loadCanUndoCancellation();
        }
    }, [eventInstance?.id, isAdminApp]);

    useInterval(async () => {
        if (newSeat?.id) {
            const api = new AttendeeApi(newSeat.eventInstanceId);
            const newId = await api.getNewestAttendeeId(newSeat.id, newSeat.eventInstanceId);
            setNewAttendeeId(newId);
        }
    }, 3000);

    React.useEffect(() => {
        if (newAttendeeId) {
            location.href = `${adminAppEndpoint}police-and-court-event-management/eventInstances/${newSeat.eventInstanceId}/attendees/${newAttendeeId}`;
        }
    }, [adminAppEndpoint, newAttendeeId, newSeat?.eventInstanceId]);

    return (
        <>
            { loading &&
                <Message
                    as={Segment}
                    content={<h2>Please wait you are being redirected to the new attendee record. Do not refresh the page.</h2>}
                    visible
                    info />
            }
            <Confirm
                open={modalOpen}
                header={header}
                content={content}
                onConfirm={onConfirm}
                cancelButton={cancelButtonProps}
                confirmButton={confirmButtonProps}
                onCancel={onCancel}
            />
        </>
    );
};
