import { DeliveryTypeFilterSearch, LocalStateKeys, LocalStateKeysEnum } from "../model";

export function useGetDeliveryTypeFilterFromSessionStorage(bookingId: string): DeliveryTypeFilterSearch | null {
    const json = sessionStorage.getItem(`${LocalStateKeys[LocalStateKeysEnum.DeliveryTypeFilterSearch]}`);
    const deliveryTypeFilterObject = json !== null ? JSON.parse(json) as DeliveryTypeFilterSearch : null;
    if (deliveryTypeFilterObject && deliveryTypeFilterObject.bookingId === bookingId) {
        return deliveryTypeFilterObject;
    }
    return null;
}
