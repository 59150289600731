import moment from "moment";

export interface GenesysTokenCreateEditModel {
    token: string;
    validUntil: moment.Moment;
};

export interface GenesysOperationResponse {
    success: boolean;
    errorMessage: string;
}

export interface GenesysCliResponse {
    cliNumber: string;
}

export interface GenesysCallState {
    readonly allCallsPaused: boolean;
}

export enum LocalStateKeysEnum {
    TokenExpiryTime = 0,
    BeforeGenesysAuthPage = 1,
}

export const LocalStateKeys = {
    [LocalStateKeysEnum.TokenExpiryTime]: "TokenExpiryTime",
    [LocalStateKeysEnum.BeforeGenesysAuthPage]: "BeforeGenesysAuthPage",
};

export enum AdminRecordedActionType{
    none,
    attendeeRecordRead,
    dorsBookingRecordRead,
    startBookingJourney
}
