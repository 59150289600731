import * as React from "react";
import { useCallback, useState } from "react";
import { Button, DropdownItemProps, Modal } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { EventInstance } from "../model";
import { VenueApi } from "@common/crud/venue";
import { SearchOptions } from "@common/crud/venue/model";
import { VenueTypeEnum } from "@common/crud/organisation/model";
import { useDispatch } from "react-redux";
import { convertToBookable } from "../actions";

interface ConvertButtonProps {
    eventInstance: EventInstance;
}

export const ConvertButton = (props: ConvertButtonProps) => {
    const { eventInstance } = props;
    const dispatch = useDispatch();

    const [convertModalOpen, setConvertModalOpen] = useState(false);
    const [venueId, setVenueId] = useState<string | null>(null);
    const [venues, setVenues] = useState<DropdownItemProps[]>([]);

    const updateVenue = useCallback((value: string) => setVenueId(value), []);
    const closeModal = React.useCallback(() => setConvertModalOpen(false), []);

    const onConvertClick = useCallback(async () => {
        setConvertModalOpen(true);
        if (venues.length === 0) {
            const venueApi = new VenueApi();
            const searchOptions: SearchOptions = {
                workflows: [eventInstance.workflowType],
                deliveryType: eventInstance.eventInstanceDeliveryType,
                organisationId: eventInstance.corporateOrganisationId,
                venueTypes: [VenueTypeEnum.ConstructionOrganisationSpecific, VenueTypeEnum.ConstructionShared]
            };
            const serverVenues = await venueApi.getAll(searchOptions);
            setVenues(serverVenues
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(c => ({ key: c.id, text: c.name, value: c.id })));
        }
    }, [eventInstance, venues.length]);

    const onSubmit = React.useCallback(() => {
        dispatch(convertToBookable(eventInstance.id, venueId));
        closeModal();
    }, [closeModal, dispatch, eventInstance.id, venueId]);

    return (
        <>
            <Button className="irregular-positive" content="Convert" onClick={onConvertClick} />
            <Modal open={convertModalOpen}>
                <Modal.Header>Convert to bookable course</Modal.Header>
                <Modal.Content>
                    <p>
                        In order to convert this from an enquiry only course to a bookable course,
                        please select the correct venue using the dropdown below.
                    </p>
                    <Input.Dropdown
                        label="Venue"
                        placeholder="Select Venue"
                        value={venueId}
                        options={venues}
                        onChange={updateVenue}
                        dynamicOptions
                        search
                        required />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeModal}>Cancel</Button>
                    <Button primary onClick={onSubmit} disabled={!venueId}>Save</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
