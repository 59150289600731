/* eslint-disable max-lines */
import moment from "moment";
import { Location } from "redux-little-router";
import { Attendee } from "@common/crud/attendee";
import { SpecialRequirements } from "../common/SpecialRequirements";
import { HistoryRecord } from "@common/history/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { BookingsHistoryModel } from "@common/crud/bookingsHistory/model";
import { TrainerRole, CurrentUser } from "@common/auth/model";
import { Address } from "@common/crud/common";
import { Apps, DayOfWeekEnum, DayPeriodEnum } from "@common/model";
import { isAuthorized } from "reauthorize";
import { EventManagementAdminRoles } from "../../auth/model";
import { AllowedWorkflowTypeEnum, CorporateCertificateTypeEnum,
    DurationTypeEnum, EventTypePart, WorkflowTypeEnum, ProductCategoryEnum
} from "@common/crud/eventType/model";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { AttendeeFieldTypeEnum, CountryEnum, DelegatesContactModel, ManagersContactModel, OrganisationContactModel,
    PurchaseOrder } from "../organisation/model";
import { CompletionState, EventTypeCategory } from "../attendee/model";
import { isEventInstanceWorkflowPoliceOrCourt } from "@common/global/CommonHelpers";
import { OrderPaymentStatus } from "../order/model";

export interface EventTrainer {
    readonly id: string;
    readonly name?: string;
    readonly email?: string;
    readonly mobileNumber?: string;
    readonly homeNumber?: string;
    readonly ndorsLicenceNumber?: string;
    readonly fee?: number;
    readonly sundries?: number;
    readonly feeNote?: string;
    readonly availableForOtherTrainers?: boolean;
    readonly dateMadeAvailableForOtherTrainers?: moment.Moment;
    readonly confirmed?: boolean;
    readonly syncedWithDors?: boolean;
    readonly externalAssessmentDue?: boolean;
    readonly subcontractingProcess?: SubcontractingProcess;
    readonly otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum;
    readonly additionalFees?: AdditionalFee[];
    readonly additionalFeesTotal?: number;
    readonly isFeeNonStandard?: boolean;
}

export interface EventInstanceUpdateDelegateFormErrors {
    [delegateId: string]: Set<string>;
}

export interface FieldsForMarkup {
    [delegateId: string]: string[];
}

export interface AdditionalFee {
    feeType: FeeType;
    feeValue: number;
}

export interface getTrainerCarTypesForEventResponse {
    manual: boolean;
    automatic: boolean;
}

export interface UpdateTrainersResponse extends DorsResponse<string[]>{
}

interface DorsResponse<T>{
  success: boolean;
  errorMessages: string[];
response: T;
}

export interface PracticalEventTrainer extends EventTrainer {
    readonly carType: CarType;
}

export type CarType = "Manual" | "Automatic" | "Any";

export enum AutoSpreadStatusEnum {
    None,
    InProgress,
    Completed,
    Cancelled
}

export const AutoSpreadStatus = {
    [AutoSpreadStatusEnum.None]: "None",
    [AutoSpreadStatusEnum.InProgress]: "In Progress",
    [AutoSpreadStatusEnum.Completed]: "Completed",
    [AutoSpreadStatusEnum.Cancelled]: "Cancelled",
};

export interface EventInstanceBreak {
    startTime: moment.Duration;
    endTime: moment.Duration;
}

export interface EventInstanceListModel {
    readonly id: string;
    readonly venueName?: string;
    readonly delegateAddress?: boolean;
    readonly venueAddress?: Address;
    readonly eventTypeName?: string;
    readonly eventTypeAbbreviation?: string;
    readonly eventTypeId?: string;
    readonly startDate?: moment.Moment;
    readonly startDateIsBankHoliday?: boolean;
    startTime?: moment.Duration;
    actualStartTime?: moment.Duration;
    actualEndTime?: moment.Duration;
    breaks?: EventInstanceBreak[];
    readonly deliveryDateTime?: moment.Moment;
    readonly deliveryDateTimeEnd?: moment.Moment;
    readonly publishDate?: moment.Moment;
    readonly listUpdated?: moment.Moment;
    readonly moduleType?: string;
    readonly durationType?: DurationTypeEnum;
    readonly oneToOne?: boolean;
    readonly trainerAttributes?: string[];
    readonly language?: number;
    eventDuration?: moment.Duration;
    readonly reasonForHidingEvent?: string;
    readonly cancelled?: boolean;
    readonly numberOfTheoryTrainers?: number;
    readonly numberOfPracticalTrainers?: number;
    readonly openPlacesCount?: number;
    readonly placesBookedOnOpenBookingCount?: number;
    readonly seatCount?: number;
    readonly errorMessageDuringPublish?: string;
    readonly errorMessageDuringUpdateTrainers?: string;
    readonly errorMessageDuringUpdateDorsOpenPlacesCount?: string;
    readonly attendeesRegisterDate?: moment.Moment;
    readonly forceRegisterProcessing?: boolean;
    readonly stageOneSubmittedDate?: moment.Moment;
    readonly hasAttendeesWithSpecialRequirements?: boolean;
    readonly isAcceptedByTrainer?: boolean;
    readonly theoryTrainersNames?: string;
    readonly practicalTrainersNames?: string;
    readonly monitorsNames?: string;
    readonly observersNames?: string;
    readonly hasMonitor?: boolean;
    readonly hasObserver?: boolean;
    readonly updatesInProgress?: boolean;
    readonly groupId?: string;
    readonly theoryStartTime?: moment.Duration;
    readonly practicalStartTime?: moment.Duration;
    readonly theoryDuration?: moment.Duration;
    readonly practicalDuration?: moment.Duration;
    readonly theoryDeliveryDateTime?: moment.Moment;
    readonly theoryDeliveryDateTimeEnd?: moment.Moment;
    readonly practicalDeliveryDateTime?: moment.Moment;
    readonly practicalDeliveryDateTimeEnd?: moment.Moment;
    readonly workflowType?: WorkflowTypeEnum;
    readonly subcontractedTrainers?: EventTrainer[];
    readonly allTrainerIds?: string[];
    readonly practicalTrainersIds?: string[];
    readonly observerTrainersIds?: string[];
    readonly monitorTrainersIds?: string[];
    readonly trainerIds?: string[];
    readonly observerTrainers?: EventTrainer[];
    readonly practicalTrainers?: PracticalEventTrainer[];
    readonly selectedTrainerId?: string;
    readonly dayParts?: string[];
    readonly referredCourtCountry?: CountryEnum;
    readonly adminAvailableRoles?: TrainerAvailabilityRoleTypeEnum[];
    readonly trainerAvailableRoles?: TrainerAvailabilityRoleTypeEnum[];
    readonly availableRoles?: TrainerAvailabilityRoleTypeEnum[];
    role?: TrainerAvailabilityRoleTypeEnum;
    roleDeliveryDateTime?: moment.Moment;
    roleDeliveryDateTimeEnd?: moment.Moment;
    readonly adminRequestedTrainerCounts?: AdminRequestedTrainerCounts;
    readonly isDorsAccessibilityCategorySupported?: boolean;
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly hasUnconfirmedTrainers?: boolean;
    readonly autoSpreadStatus?: AutoSpreadStatusEnum;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly corporateOrganisationContact?: OrganisationContactModel;
    readonly productCategory?: number;
    readonly mandatoryCorpCertificate?: number;
    readonly optionalCorpCertificate?: number;
    readonly bookingType?: number;
    readonly purchaseOrderNumber?: string;
    readonly totalUploadFeeInPence?: number;
    readonly miscellaneousOrganisationFee?: number;
    readonly miscellaneousOrganisationFeeInPence?: number;
    readonly invoiceTotalInPence?: number;
    readonly attendeeFields?: Record<string, EventInstanceAttendeeField[]>;
    readonly doesNotUseTtcZoomAccounts?: boolean;
    readonly flexibleCertificatesRequired?: boolean;
    readonly certificateType?: number;
    readonly courseCategories?: number[];
    readonly resellerCourse?: boolean;
}

export interface EventInstanceListResponseModel {
    eventInstances: EventInstanceListModel[];
    totalNumberOfEventInstances?: number;
}

export interface EventInstanceDetailModel {
    readonly id: string;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly eventTypeNameCy?: string;
    readonly eventTypeAbbreviation?: string;
    readonly eventTypeCategory?: EventTypeCategory;
    readonly publishDate?: moment.Moment;
    startTime?: moment.Duration;
    actualStartTime?: moment.Duration;
    actualEndTime?: moment.Duration;
    breaks?: EventInstanceBreak[];
    readonly registrationEndTime?: moment.Duration;
    readonly maxNumberOfAttendees?: number;
    readonly maxNumberOfAttendeesPerTrainer?: number;
    readonly maxNumberOfAttendeesPerPracticalTrainer?: number;
    eventDuration?: moment.Duration;
    readonly venueId?: string;
    readonly venueName?: string;
    readonly delegateAddress?: boolean;
    readonly venueDorsId?: number;
    readonly venueAddress?: Address;
    readonly moduleType?: string;
    readonly durationType?: DurationTypeEnum;
    readonly oneToOne?: boolean;
    readonly trainerAttributes?: string[];
    readonly detailUpdated?: moment.Moment;
    readonly trainers?: EventTrainer[];
    readonly trainerIds?: string[];
    readonly allTrainerIds?: string[];
    readonly practicalTrainers?: PracticalEventTrainer[];
    readonly monitorTrainers?: EventTrainer[];
    readonly observerTrainers?: EventTrainer[];
    readonly language?: LanguageEnum;
    readonly dorsId?: number;
    readonly workflowType?: WorkflowTypeEnum;
    readonly startDate?: moment.Moment;
    readonly startDateIsBankHoliday?: boolean;
    readonly distance?: number;
    readonly schemeId?: number;
    readonly forceId?: number;
    readonly priceInPence?: number;
    readonly price?: number;
    readonly closedCourseFeeInPence?: number;
    readonly closedCourseFee?: number;
    readonly uploadFeeInPence?: number;
    readonly uploadFee?: number;
    readonly financeDetailsNote?: string;
    readonly errorMessageDuringPublish?: string;
    readonly errorMessageDuringUpdateTrainers?: string;
    readonly errorMessageDuringUpdateStartDay?: string;
    readonly errorMessageDuringUpdateScheme?: string;
    readonly errorMessageDuringUpdate?: string;
    readonly warningMessageDuringUpdate?: string;
    readonly errorMessageDuringUpdatePrice?: string;
    readonly reasonForHidingEvent?: string;
    readonly openPlacesCount?: number;
    readonly seatCount?: number;
    readonly cancelled?: boolean;
    readonly reasonForCancellation?: string;
    readonly additionalFee?: number;
    readonly additionalFeeInPence?: number;
    readonly attendeesRegisterDate?: moment.Moment;
    readonly forceRegisterProcessing?: boolean;
    readonly stageOneSubmittedDate?: moment.Moment;
    readonly confirmationStatus?: ConfirmationStatus;
    readonly officeNote?: string;
    readonly adminNote?: string;
    readonly trainerNote?: string;
    readonly creationNote?: string;
    readonly bookingNoteEn?: string;
    readonly bookingNoteCy?: string;
    readonly termsAndConditionsLinkEn?: string;
    readonly termsAndConditionsLinkCy?: string;
    readonly specialRequirementAttendees?: SpecialRequirementAttendee[];
    readonly automaticSeatCount?: number;
    readonly manualSeatCount?: number;
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly hasUnconfirmedTrainers?: boolean;
    readonly unconfirmedTrainerIds?: string[];
    readonly digitalCourseLink?: string;
    readonly isAcceptedByTrainer?: boolean;
    readonly digitalCourseAccount?: string;
    readonly digitalCoursePasswordTrainer?: string;
    readonly digitalCourseLinkTrainer?: string;
    readonly digitalCoursePasswordAttendee?: string;
    readonly digitalCourseMeetingId?: string;
    readonly digitalCourseWithoutRegistration?: boolean;
    readonly courseId?: string;
    readonly availableForOtherTrainers?: boolean;
    readonly isStageTwo?: boolean;
    readonly availableInBookingJourney?: number;
    readonly deliveryDateTime?: moment.Moment;
    readonly deliveryDateTimeEnd?: moment.Moment;
    history?: HistoryRecord[];
    bookingsHistory?: BookingsHistoryModel[];
    readonly hasMonitor?: boolean;
    readonly hasObserver?: boolean;
    readonly eventTypeParts?: Dictionary<EventTypePart>;
    readonly monitoredTrainersIds?: string[];
    readonly dsaArea?: number;
    readonly priceClearedDueToFlexiPay?: boolean;
    readonly theoryStartTime?: moment.Duration;
    readonly practicalStartTime?: moment.Duration;
    readonly theoryDuration?: moment.Duration;
    readonly practicalDuration?: moment.Duration;
    readonly theoryDeliveryDateTime?: moment.Moment;
    readonly theoryDeliveryDateTimeEnd?: moment.Moment;
    readonly practicalDeliveryDateTime?: moment.Moment;
    readonly practicalDeliveryDateTimeEnd?: moment.Moment;
    readonly skippedForBulkMakeAvailableToTrainers?: boolean;
    readonly subcontractedTrainers?: EventTrainer[];
    readonly groupId?: string;
    readonly subcontractingProcess?: SubcontractingProcess;
    readonly adminAvailableRoles?: TrainerAvailabilityRoleTypeEnum[];
    readonly trainerAvailableRoles?: TrainerAvailabilityRoleTypeEnum[];
    readonly adminRequestedTrainerCounts?: AdminRequestedTrainerCounts;
    readonly isDorsAccessibilityCategorySupported?: boolean;
    readonly availableRoles?: TrainerAvailabilityRoleTypeEnum[];
    readonly healthAndSafety?: EventInstanceHealthAndSafetyModel;
    readonly autoSpreadStatus?: AutoSpreadStatusEnum;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly productCategory?: ProductCategoryEnum;
    readonly mandatoryCorpCertificate?: number;
    readonly optionalCorpCertificate?: number;
    readonly bookingType?: number;
    readonly purchaseOrderFees?: number;
    readonly existingPurchaseOrdersList?: PurchaseOrder[];
    readonly purchaseOrderNumbersList?: string[];
    readonly totalUploadFeeInPence?: number;
    readonly miscellaneousOrganisationFee?: number;
    readonly miscellaneousOrganisationFeeInPence?: number;
    readonly invoiceTotalInPence?: number;
    readonly corporateOrganisationContact?: OrganisationContactModel;
    readonly corporateOrganisationCourseContact?: OrganisationContactModel;
    readonly corporateOrganisationFinanceContact?: OrganisationContactModel;
    readonly delegatesContact?: DelegatesContactModel;
    readonly managersContact?: ManagersContactModel;
    readonly registerProcessedDate?: moment.Moment;
    readonly certificatesProcessedDate?: moment.Moment;
    readonly educationDuration?: moment.Duration;
    readonly competencyReportRequired?: boolean;
    readonly attendeeFields?: Record<string, EventInstanceAttendeeField[]>;
    readonly favourite?: boolean;
    readonly doesNotUseTtcZoomAccounts?: boolean;
    readonly onRoadReportRequired?: boolean;
    readonly delegatesRequired?: boolean;
    readonly flexibleCertificatesRequired?: boolean;
    readonly certificateType?: number;
    readonly certificateRequired?: boolean;
    readonly contactsModel?: UpdateClosedCourseCommsDestinationsModel;
    readonly venueOrganisationName?: string;
    readonly locationDescription?: string;
    readonly enquiryType?: EnquiryType;
    readonly classroomBreakEvenPoint?: number;
    readonly digitalBreakEvenPoint?: number;
    readonly courseCategories?: number[];
    readonly resellerCourse?: boolean;
}

export interface CreateEventInstanceResponse {
    eventInstanceCreated?: boolean;
    eventInstanceCreatedWithErrors?: boolean;
    errorMessage?: string;
    eventInstance?: EventInstanceDetailModel;
}

export type EventInstance = EventInstanceListModel & EventInstanceDetailModel & EventInstanceDigitalPlanningModel;

export interface EventInstanceCreateModel {
    id: string;
    eventTypeId: string;
    eventTypeName: string;
    eventTypeAbbreviation: string;
    maxNumberOfAttendees: number;
    maxNumberOfAttendeesPerPracticalTrainer: number;
    eventDuration: moment.Duration;
    publishDate?: moment.Moment;
    startTime: moment.Duration;
    theoryStartTime?: moment.Duration;
    practicalStartTime?: moment.Duration;
    theoryDuration?: moment.Duration;
    practicalDuration?: moment.Duration;
    registrationEndTime?: moment.Duration;
    venueId: string;
    venueName: string;
    delegateHomeAddress?: Address;
    venueDorsId?: number;
    moduleType: string;
    durationType?: DurationTypeEnum;
    oneToOne: boolean;
    workflowType?: (AllowedWorkflowTypeEnum | WorkflowTypeEnum.None);
    trainerAttributes?: string[];
    language?: number;
    dorsId?: number;
    startDate: moment.Moment;
    schemeId?: number;
    forceId?: number;
    openPlacesCount?: number;
    officeNote?: string;
    adminNote?: string;
    bookingNoteEn?: string;
    bookingNoteCy?: string;
    eventInstanceDeliveryType?: number;
    digitalCourseLink?: string;
    digitalCourseAccount?: string;
    digitalCoursePasswordTrainer?: string;
    digitalCourseLinkTrainer?: string;
    digitalCoursePasswordAttendee?: string;
    digitalCourseMeetingId?: string;
    digitalCourseWithoutRegistration?: boolean;
    availableInBookingJourney: number;
    eventTypeParts?: Dictionary<EventTypePart>;
    groupItemStartDates?: Dictionary<moment.Moment>;
    dsaArea?: number;
    referredCourtCountry?: CountryEnum;
    dayParts?: string[];
    corporateOrganisationId?: string;
    productCategory?: ProductCategoryEnum;
    courseCategories?: number[];
    resellerCourse?: boolean;
    eventTypeCategory: EventTypeCategory;
    mandatoryCorpCertificate?: CorporateCertificateTypeEnum;
    optionalCorpCertificate?: CorporateCertificateTypeEnum;
    bookingType?: number;
    totalFeesInPence?: number;
    existingPurchaseOrdersList?: PurchaseOrder[];
    purchaseOrderNumbersList?: string[];
    miscellaneousOrganisationFee?: number;
    closedCourseFee?: number;
    uploadFee?: number;
    financeDetailsNote?: string;
    educationDuration?: moment.Duration;
    doesNotUseTtcZoomAccounts?: boolean;
    onRoadReportRequired?: boolean;
    delegatesRequired?: boolean;
    flexibleCertificatesRequired?: boolean;
    certificateType?: number;
    certificateRequired?: boolean;
    contactsModel?: UpdateClosedCourseCommsDestinationsModel;
    actualStartTime?: moment.Duration;
    actualEndTime?: moment.Duration;
    locationDescription?: string;
    enquiryType?: EnquiryType;
    classroomBreakEvenPoint?: number;
    digitalBreakEvenPoint?: number;
    numberOfDeliveryDays?: number;
}

export interface EventInstanceEditModel {
    id: string;
    eventTypeId: string;
    eventTypeName: string;
    eventTypeAbbreviation: string;
    maxNumberOfAttendees: number;
    maxNumberOfAttendeesPerTrainer: number;
    maxNumberOfAttendeesPerPracticalTrainer: number;
    eventDuration: moment.Duration;
    publishDate: moment.Moment;
    certificatesProcessedDate: moment.Moment;
    startTime: moment.Duration;
    theoryStartTime?: moment.Duration;
    practicalStartTime?: moment.Duration;
    theoryDuration?: moment.Duration;
    practicalDuration?: moment.Duration;
    registrationEndTime?: moment.Duration;
    venueId: string;
    delegateHomeAddress?: Address;
    venueName: string;
    venueDorsId?: number;
    moduleType: string;
    durationType?: DurationTypeEnum;
    oneToOne: boolean;
    trainerAttributes?: string[];
    trainers?: EventTrainer[];
    practicalTrainers?: PracticalEventTrainer[];
    observerTrainers?: EventTrainer[];
    language?: number;
    dorsId?: number;
    workflowType?: number;
    startDate: moment.Moment;
    distance?: number;
    schemeId?: number;
    forceId?: number;
    openPlacesCount?: number;
    seatCount?: number;
    officeNote?: string;
    adminNote?: string;
    eventInstanceDeliveryType?: number;
    digitalCourseLink?: string;
    bookingNoteEn?: string;
    bookingNoteCy?: string;
    digitalCourseAccount?: string;
    digitalCoursePasswordTrainer?: string;
    digitalCourseLinkTrainer?: string;
    digitalCoursePasswordAttendee?: string;
    digitalCourseMeetingId?: string;
    digitalCourseWithoutRegistration?: boolean;
    availableInBookingJourney?: number;
    maxNumberOfTheorySeatsWithExtra?: number;
    eventTypeParts?: Dictionary<EventTypePart>;
    groupItemStartDates?: Dictionary<moment.Moment>;
    dsaArea?: number;
    groupId?: string;
    reasonForHidingEvent?: string;
    isDorsAccessibilityCategorySupported?: boolean;
    corporateOrganisationId?: string;
    productCategory?: ProductCategoryEnum;
    mandatoryCorpCertificate?: number;
    optionalCorpCertificate?: number;
    bookingType?: number;
    purchaseOrderNumber?: string;
    miscellaneousOrganisationFee?: number;
    financeDetailsNote?: string;
    educationDuration?: moment.Duration;
    doesNotUseTtcZoomAccounts?: boolean;
    onRoadReportRequired?: boolean;
    delegatesRequired?: boolean;
    flexibleCertificatesRequired?: boolean;
    certificateType?: number;
    certificateRequired?: boolean;
    courseCategories?: number[];
    resellerCourse?: boolean;
    actualStartTime?: moment.Duration;
    actualEndTime?: moment.Duration;
    locationDescription?: string;
    enquiryType?: EnquiryType;
    classroomBreakEvenPoint?: number;
    digitalBreakEvenPoint?: number;
}

export interface EventInstanceAttendeeField {
    displayName: string;
    fieldId: string;
    type: AttendeeFieldTypeEnum;
}

export interface MultiDayEventInstanceListModel {
    eventInstances: EventInstanceListModel[];
    eventInstanceGroups: EventInstanceGroupModel[];
}

export interface EventInstanceDigitalPlanningModel {
    id?: string;
    trainerId?: string;
    eventTypeId?: string;
    eventTypeAbbreviation?: string;
    startDate?: moment.Moment;
    startTime?: moment.Duration;
    eventDuration?: moment.Duration;
    dayParts?: string[];
    protected?: boolean;
    referredCourtCountry?: CountryEnum;
}

export interface SubcontractedEventInstanceListModel extends EventInstanceListModel {
    trainerType?: string;
    courseFee?: number;
    courseSundries?: number;
    subcontractingPaymentConfirmed?: boolean;
}

export interface ExtendedEventInstance extends EventInstanceListModel {
    path?: string;
    onChange?: () => void;
    isSelected?: boolean;
    trainersName?: string;
    customKey?: string;
    isGroupedWithPrevious?: boolean;
    trainerId?: string;
    isEventInstanceGroup?: false;
}

export interface ExtendedEventInstanceGroup extends EventInstanceGroupModel {
    id: string;
    path?: string;
    onChange?: () => void;
    isSelected?: boolean;
    startDate: moment.Moment;
    publishDate?: moment.Moment;
    reasonForHidingEvent?: string;
    cancelled?: boolean;
    isEventInstanceGroup?: true;
}

export interface EventInstanceConfirmInterestModel {
    id: string;
    role: TrainerAvailabilityRoleTypeEnum;
    roleDeliveryDateTime: moment.Moment;
    roleDeliveryDateTimeEnd: moment.Moment;
    eventInstanceDeliveryType: DeliveryTypeEnum;
    eventTypeName: string;
    venueName: string;
    eventTypeId: string;
    eventDuration: moment.Duration;
    language: LanguageEnum;
}

export interface ConfirmInterestResponseModel {
    unavailableEventInstances: EventInstanceConfirmInterestModel[];
    eventInstancesOverlappingTrainerSchedule: EventInstanceConfirmInterestModel[];
}

export interface EventInstanceState {
    readonly eventInstances: EventInstance[];
    readonly groupedEventInstances: EventInstanceGroupModel[];
    readonly digitalPlanningSessions: DigitalPlanningSession[];
    readonly totalNumberOfEventInstances: number;
    readonly eventInstanceFinanceData: BookNowPayLaterOrderAttendees[];
    readonly eventInstanceEnquiriesData: EnquiryEventInstanceData[];
}

export interface SetEventInstanceAvailabilityForOtherTrainersResponseModel {
    readonly eventInstance: EventInstance;
    readonly errorMessage?: string;
}

export interface EventInstanceIdFromDetailsId {
    readonly eventInstanceId?: string;
    readonly workflowType?: WorkflowTypeEnum;
}

export interface CancelEventInstanceResponse {
    cancelled?: boolean;
    reasonForCancellation?: string;
    errors?: string;
    financeResponse?: CancelEventInstanceFinanceResponse;
}

export interface CancelEventInstanceFinanceResponse {
    closedCourseFee?: number;
    closedCourseFeeInPence?: number;
    invoiceTotalInPence?: number;
    miscellaneousOrganisationFee?: number;
    miscellaneousOrganisationFeeInPence?: number;
    uploadFee?: number;
    uploadFeeInPence?: number;
}

export interface AdminRequestedTrainerCounts {
    theory: number;
    manual: number;
    automatic: number;
    total: number;
}

export type AppState = EventInstanceState & { router: Location; app?: Apps };

export enum LanguageEnum {
    Any = 0,
    English = 1,
    Welsh = 2
}

export enum CommsDestination {
    Primary = 0,
    Course = 1,
    Finance = 2,
    Delegates = 3,
    Managers = 4,
}

export enum BookingAvailabilityTypeEnum {
    Both = 1,
    Online = 2,
    Office = 3
}

export enum ClearTrainerAvailabilityEnum {
    No = 0,
    Session = 1,
    FullDay = 2
}

export const BookingAvailabilityType = {
    [BookingAvailabilityTypeEnum.Both]: "Both",
    [BookingAvailabilityTypeEnum.Office]: "Office",
    [BookingAvailabilityTypeEnum.Online]: "Online"
};

export enum TrainerType {
    TheoryTrainer,
    PracticalTrainer,
    MonitorTrainer,
    OtherTrainer,
    RcMonitorTrainer,
    RcCoverTrainer,
    StandbyTrainer,
}

export const TrainerTypeName = {
    [TrainerType.TheoryTrainer]: "Theory",
    [TrainerType.PracticalTrainer]: "Practical",
    [TrainerType.MonitorTrainer]: "Monitor",
    [TrainerType.OtherTrainer]: "Other",
    [TrainerType.RcMonitorTrainer]: "RC Monitor",
    [TrainerType.RcCoverTrainer]: "RC Cover",
    [TrainerType.StandbyTrainer]: "Standby",
};

export const Language = {
    [LanguageEnum.Any]: "Any",
    [LanguageEnum.English]: "English",
    [LanguageEnum.Welsh]: "Welsh"
};

export const LanguageCode = {
    [LanguageEnum.English]: "en",
    [LanguageEnum.Welsh]: "cy"
};

export enum ConfirmationStatus {
    Awaits = 0,
    Confirmed = 1,
    Rejected = 2
}

export interface SearchOptions {
    eventType?: string[];
    fromDate?: moment.Moment;
    toDate?: moment.Moment;
    hasAvailableSeats?: boolean;
    requiresTrainers?: YesNoAnyEnum;
    oneToOneOnly?: boolean;
    forceId?: string[];
    venueId?: string[];
    getOnlyUnconfirmedByTrainer?: boolean;
    trainerId?: string;
    maxPageSize?: number;
    eventStatus?: string[];
    deliveryType?: string;
    availableForOtherTrainers?: YesNoAnyEnum;
    onlyIncludeCoursesMadeAvailableByTrainer?: boolean;
    language?: string;
    daysOfWeek?: DayOfWeekEnum[];
    dayPeriod?: DayPeriodEnum[];
    filtersCleared?: boolean;
    dsaAreaId?: string;
    includeAlreadyStartedGroups?: boolean;
    includeAllDayParts?: boolean;
    workflowTypes?: WorkflowTypeEnum[];
    stateWorkflowType?: WorkflowTypeEnum;
    page?: number;
    hasWarnings?: boolean;
    hasAutoSpreadStatus?: boolean;
    autoSpreadInProgress?: boolean;
    corporateOrganisationIds?: string[];
    bookingType?: string;
}

export interface SubcontractedSearchOptions {
    subcontractingType?: SubcontractingType;
    page?: number;
    pageSize?: number;
}

export interface EINotes {
    trainerNote?: string;
    officeNote?: string;
    adminNote?: string;
    bookingNoteEn?: string;
    bookingNoteCy?: string;
    creationNote?: string;
}

export interface DdrsEINotes extends EINotes {
    dayNumber: number;
}

export interface RegisterState {
    id: string;
    completion: boolean;
    isStageTwo: boolean;
}

export interface UpdateSubcontractingPaymentStatusModel {
    subcontractingType: SubcontractingType;
    confirmed: boolean;
    trainerType: TrainerType;
}

export enum SubcontractingType {
    To,
    From
}

export enum ClassroomAssignableAttribute {
    None = 0,
    HearingLoop = 1,
    Projector = 2,
    BslInterpreter = 3,
    TextToSpeechReporter = 4,
    Translator = 5
}

export enum DigitalSpecialRequirementsAttribute {
    None = 0,
    BslInterpreter = 3,
    NeedToBreastFeed = 6,
    NeedToPray = 7,
    NeedSubtitles = 8,
    OwnInterpreter = 9,
    OutsideUkAttendee = 11
}

export enum SpecialRequirementsAttribute {
    None = 0,
    HearingLoop = 1,
    Projector = 2,
    BslInterpreter = 3,
    TextToSpeechReporter = 4,
    Translator = 5,
    NeedToBreastFeed = 6,
    NeedToPray = 7,
    NeedSubtitles = 8,
    OwnInterpreter = 9,
    OutsideUkAttendee = 11,
    HearingImpairment = 12,
}

export enum DelegateClassroomAssignableAttribute {
    None = 0,
    HearingLoop = 1,
    BslInterpreter = 3,
    Translator = 5,
    NeedToBreastFeed = 6,
    NeedToPray = 7,
}

export const DelegateClassroomAssignableAttributes = {
    [DelegateClassroomAssignableAttribute.None]: "None",
    [DelegateClassroomAssignableAttribute.HearingLoop]: "Hearing Loop",
    [DelegateClassroomAssignableAttribute.BslInterpreter]: "BSL Interpreter",
    [DelegateClassroomAssignableAttribute.Translator]: "Own Translator",
    [DelegateClassroomAssignableAttribute.NeedToBreastFeed]: "Breastfeeding Room",
    [DelegateClassroomAssignableAttribute.NeedToPray]: "Prayer Room"
};

export enum DelegateDigitalAssignableAttribute {
    None = 0,
    BslInterpreter = 3,
    Translator = 5,
    NeedToBreastFeed = 6,
    NeedToPray = 7,
    NeedSubtitles = 8,
    OutsideUkAttendee = 11,
}

export const DelegateDigitalAssignableAttributes = {
    [DelegateDigitalAssignableAttribute.None]: "None",
    [DelegateDigitalAssignableAttribute.BslInterpreter]: "BSL Interpreter",
    [DelegateDigitalAssignableAttribute.Translator]: "Own Translator",
    [DelegateDigitalAssignableAttribute.NeedToBreastFeed]: "Delegate will need to breastfeed",
    [DelegateDigitalAssignableAttribute.NeedToPray]: "Delegate will need to pray",
    [DelegateDigitalAssignableAttribute.NeedSubtitles]: "Subtitles",
    [DelegateDigitalAssignableAttribute.OutsideUkAttendee]: "Joining outside of the UK"
};

export const hearingImpairmentAttributes = [
    ClassroomAssignableAttribute.BslInterpreter,
    ClassroomAssignableAttribute.TextToSpeechReporter,
    ClassroomAssignableAttribute.HearingLoop,
    SpecialRequirementsAttribute.NeedSubtitles
];

export const ClassroomAssignableAttributes = {
    [ClassroomAssignableAttribute.None]: "None",
    [ClassroomAssignableAttribute.HearingLoop]: "Hearing Loop",
    [ClassroomAssignableAttribute.Projector]: "Projector",
    [ClassroomAssignableAttribute.BslInterpreter]: "BSL Interpreter",
    [ClassroomAssignableAttribute.TextToSpeechReporter]: "Text To Speech Reporter",
    [ClassroomAssignableAttribute.Translator]: "Translator"
};

export const DigitalSpecialRequirementsAttributes = {
    [DigitalSpecialRequirementsAttribute.None]: "None",
    [DigitalSpecialRequirementsAttribute.NeedToBreastFeed]: "Need to Breastfeed",
    [DigitalSpecialRequirementsAttribute.NeedToPray]: "Need to Pray",
    [DigitalSpecialRequirementsAttribute.BslInterpreter]: "BSL Interpreter",
    [DigitalSpecialRequirementsAttribute.NeedSubtitles]: "Require Subtitles",
    [DigitalSpecialRequirementsAttribute.OwnInterpreter]: "Own interpreter",
    [DigitalSpecialRequirementsAttribute.OutsideUkAttendee]: "Other Country"
};

export const SpecialRequirementsAttributes = {
    [SpecialRequirementsAttribute.None]: "None",
    [SpecialRequirementsAttribute.HearingLoop]: "Hearing Loop",
    [SpecialRequirementsAttribute.Projector]: "Projector",
    [SpecialRequirementsAttribute.BslInterpreter]: "BSL Interpreter",
    [SpecialRequirementsAttribute.TextToSpeechReporter]: "Text To Speech Reporter",
    [SpecialRequirementsAttribute.Translator]: "Translator",
    [SpecialRequirementsAttribute.NeedToBreastFeed]: "Need to Breastfeed",
    [SpecialRequirementsAttribute.NeedToPray]: "Need to Pray",
    [SpecialRequirementsAttribute.NeedSubtitles]: "Require Subtitles",
    [SpecialRequirementsAttribute.OwnInterpreter]: "Own Interpreter",
    [DigitalSpecialRequirementsAttribute.OutsideUkAttendee]: "Other Country",
    [SpecialRequirementsAttribute.HearingImpairment]: "Hearing Impairment",
};

export const SpecialRequirementsAttributePictures = {
    [SpecialRequirementsAttribute.None]: "",
    [SpecialRequirementsAttribute.HearingLoop]: "/assets/hearing-loop.png",
    [SpecialRequirementsAttribute.Projector]: "/assets/projector.png",
    [SpecialRequirementsAttribute.BslInterpreter]: "/assets/bsl.png",
    [SpecialRequirementsAttribute.TextToSpeechReporter]: "/assets/speech-to-text.png",
    [SpecialRequirementsAttribute.Translator]: "/assets/translator.png",
    [SpecialRequirementsAttribute.NeedToBreastFeed]: "/assets/breastfeeding-room.png",
    [SpecialRequirementsAttribute.NeedToPray]: "/assets/prayer-room.png",
    [SpecialRequirementsAttribute.NeedSubtitles]: "/assets/subtitles.png",
    [SpecialRequirementsAttribute.OwnInterpreter]: "/assets/translator.png",
    [SpecialRequirementsAttribute.OutsideUkAttendee]: "/assets/from-afar.png",
    [SpecialRequirementsAttribute.HearingImpairment]: "",
};

export const WorkflowTypeToEventTypeCategory: Record<WorkflowTypeEnum, EventTypeCategory>={
    [WorkflowTypeEnum.Any]: null,
    [WorkflowTypeEnum.None]: null,
    [WorkflowTypeEnum.General]: null,
    [WorkflowTypeEnum.Dors]: EventTypeCategory.Dors,
    [WorkflowTypeEnum.DDRS]: EventTypeCategory.Ddrs,
    [WorkflowTypeEnum.CPC]: EventTypeCategory.CorporateOrganisation,
    [WorkflowTypeEnum.Workshop]: EventTypeCategory.CorporateOrganisation,
    [WorkflowTypeEnum.OnRoad]: EventTypeCategory.CorporateOrganisation,
    [WorkflowTypeEnum.BusinessDriver]: EventTypeCategory.CorporateOrganisation,
    [WorkflowTypeEnum.ConstructionStandard]: EventTypeCategory.ConstructionOrganisation,
    [WorkflowTypeEnum.CITB]: EventTypeCategory.ConstructionOrganisation,
    [WorkflowTypeEnum.APS]: EventTypeCategory.ConstructionOrganisation,
} as const;

export interface EventInstanceAvailabilityState {
    readonly eventInstanceAvailability: EventInstanceAvailability[];
}

export interface EventInstanceAvailability {
    id: string;
    availableSeats: number;
}

export enum TrainerAvailabilityRoleTypeEnum {
    None = 0,
    Theory = 1,
    Practical = 2,
    Manual = 3,
    Automatic = 4,
    AnyPractical = 5,
}

export const TrainerAvailabilityRoleType = {
    [TrainerAvailabilityRoleTypeEnum.None]: "",
    [TrainerAvailabilityRoleTypeEnum.Theory]: "Theory",
    [TrainerAvailabilityRoleTypeEnum.Practical]: "Practical",
    [TrainerAvailabilityRoleTypeEnum.Manual]: "Manual",
    [TrainerAvailabilityRoleTypeEnum.Automatic]: "Automatic",
    [TrainerAvailabilityRoleTypeEnum.AnyPractical]: "Practical"
};

export interface EventInstanceAttendeesState {
    readonly attendees: Attendee[];
}

export interface SpecialRequirementAttendee {
    id?: string;
    fullName?: string;
    specialRequirements?: SpecialRequirements;
}

export interface EventInstanceAttendeeSpreadResultModel {
    successCount: number;
    failCount: number;
    totalCount: number;
    duplicateRequest: boolean;
    failedAttendees: AttendeeSpreadModel[];
    spreadAttendees: AttendeeSpreadModel[];
    autoSpreadInProgress: boolean;
}

export interface AttendeeSpreadModel
{
    attendeeId: string;
    reservedSeatId: string;
    alternativeEventInstanceId: string;
    attendeeName: string;
    completionDate: moment.Moment;
    phoneNumber: string;
    hasEmailAddress: string;
    hasNoComms: boolean;
}

export interface SameDayEventInstanceModel {
    id: string;
    startTime: moment.Moment;
    trainerIds: string[];
    allowAllocateToNextTrainerIds: string[];
    clashingEventInstanceIds: string[];
}

export interface GroupEventInstancesModel {
    trainerIds: string[];
    dayNumber: number;
    trainerIdsAndOtherTrainerTypeCategories: TrainerIdAndOtherTrainerTypeCategory[];
}

export interface TrainerIdAndOtherTrainerTypeCategory {
    trainerId: string;
    otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum;
}

export interface HealthAndSafetyRowModel {
    result: boolean;
    comments?: string;
}

export interface EventInstanceHealthAndSafetyModel {
    evacuationPathClear: HealthAndSafetyRowModel;
    roomFreeOfTripSlipHazards: HealthAndSafetyRowModel;
    roomTemperatureSatisfactory: HealthAndSafetyRowModel;
    equipmentFunctional: HealthAndSafetyRowModel;
    equipmentHadValidPatTest: HealthAndSafetyRowModel;
    lightningSatisfactory: HealthAndSafetyRowModel;
    furnitureFitForPurposeAndStable: HealthAndSafetyRowModel;
    anyOtherComments?: string;
    submittedOn?: moment.Moment;
    unlockedForAmend: boolean;
}

export interface EventInstanceHealthAndSafetyValidationModel {
    evacuationPathClear?: string;
    roomFreeOfTripSlipHazards?: string;
    roomTemperatureSatisfactory?: string;
    equipmentFunctional?: string;
    equipmentHadValidPatTest?: string;
    lightningSatisfactory?: string;
    furnitureFitForPurposeAndStable?: string;
}

export interface UpdateCertificateCommsDestinationModel{
    primaryDict: { [email: string]: boolean };
    courseDict: { [email: string]: boolean };
    hasDelegatesContactDestination: boolean;
    hasManagersContactDestination: boolean;
}

export interface UpdateClosedCourseCommsDestinationsModel {
    creationPrimaryDict: { [email: string]: boolean };
    creationCourseDict: { [email: string]: boolean };
    creationFinanceDict: { [email: string]: boolean };
    reminderPrimaryDict: { [email: string]: boolean };
    reminderCourseDict: { [email: string]: boolean };
    reminderFinanceDict: { [email: string]: boolean };
    bookingPrimaryDict: { [email: string]: boolean };
    bookingCourseDict: { [email: string]: boolean };
    bookingFinanceDict: { [email: string]: boolean };
    courseClosurePrimaryDict: { [email: string]: boolean };
    courseClosureCourseDict: { [email: string]: boolean };
    courseEditPrimaryDict: { [email: string]: boolean };
    courseEditCourseDict: { [email: string]: boolean };
    courseEditFinanceDict: { [email: string]: boolean };
    courseReminderDelegatesContact: boolean;
    courseBookingDelegatesContact: boolean;
    courseClosureDelegatesContact: boolean;
    courseEditDelegatesContact: boolean;
    courseReminderManagersContact: boolean;
    courseBookingManagersContact: boolean;
    courseClosureManagersContact: boolean;
    courseEditManagersContact: boolean;
}

export interface SendClosedCourseReminderCommsModel {
    reminderPrimaryDict: { [email: string]: boolean };
    reminderCourseDict: { [email: string]: boolean };
    reminderFinanceDict: { [email: string]: boolean };
    reminderForDelegatesContact: boolean;
    reminderForManagersContact: boolean;
}

export interface SendClosedCourseCreationCommsModel {
    creationPrimaryDict: { [email: string]: boolean };
    creationCourseDict: { [email: string]: boolean };
    creationFinanceDict: { [email: string]: boolean };
}

export interface FinanceDetails {
    existingPurchaseOrdersList?: PurchaseOrder[];
    purchaseOrderNumbersList?: string[];
    price?: number;
    closedCourseFee?: number;
    uploadFee?: number;
    miscellaneousOrganisationFee?: number;
    financeDetailsNote?: string;
}

export const initialHealthAndSafetyModel: EventInstanceHealthAndSafetyModel = {
    evacuationPathClear: {
        result: false,
    },
    roomFreeOfTripSlipHazards: {
        result: false,
    },
    roomTemperatureSatisfactory: {
        result: false,
    },
    equipmentFunctional: {
        result: false,
    },
    equipmentHadValidPatTest: {
        result: false,
    },
    lightningSatisfactory: {
        result: false,
    },
    furnitureFitForPurposeAndStable: {
        result: false,
    },
    unlockedForAmend: false
};

export enum Area {
    None,
    AdminEventManagementEventInstance,
    AdminEventManagementTrainerEventInstance,
    AdminEventManagementTrainerCalendar,
    AdminEventManagementCalendar,
    AdminEventManagementVenueCalendar,
    AdminEventManagementCorporateOrganisation,
    TrainerAppAvailabilityCalendar,
    TrainerAppSchedule,
    TrainerAppAvailableCourses,
    PoliceEventInstancesList,
    RegionalCoordinatorCalendar,
    AdminEventManagementDigitalPlanning,
    AdminEventManagementSpreadingOverview
}

export enum EventStatusEnum {
    Any,
    Unpublished,
    Published,
    Cancelled,
    Hidden
}

export enum YesNoAnyEnum {
    Any,
    Yes,
    No
}

export const YesNoAnyOptions = {
    [YesNoAnyEnum.Any]: "Any",
    [YesNoAnyEnum.Yes]: "Yes",
    [YesNoAnyEnum.No]: "No"
};

export const EventStatus = {
    [EventStatusEnum.Any]: "Any status",
    [EventStatusEnum.Unpublished]: "Unpublished",
    [EventStatusEnum.Published]: "Published",
    [EventStatusEnum.Cancelled]: "Cancelled",
    [EventStatusEnum.Hidden]: "Hidden"
};

export enum PageSizesEnum {
    OneHundred = 100,
    TwoFifty = 250,
    FiveHundred = 500
}

export const PageSizes = {
    [PageSizesEnum.OneHundred]: "100",
    [PageSizesEnum.TwoFifty]: "250",
    [PageSizesEnum.FiveHundred]: "500"
};

export enum DigitalPlanningSessionsState {
    Unknown = 0,
    InProgress = 10,
    Creating = 20,
    Completed = 30
}

export interface DigitalPlanningSession {
    id: string;
    month?: moment.Moment;
    lastSelectedTrainerId?: string;
    state?: DigitalPlanningSessionsState;
    plannedDigitalEvents?: EventInstance[];
    errorMessage?: string;
    courseCreationFailed?: boolean;
    trainerOrder?: string[];
    eventTypeCategory?: EventTypeCategory;
}

export interface DigitalPlanningSessionResponse {
    digitalPlanningSession: DigitalPlanningSession;
    duplicateRequest: boolean;
    validRequest: boolean;
}

export interface AttachedDocumentListModel {
    readonly id: string;
    readonly fileName: string;
    readonly displayName?: string;
    readonly description: string;
    readonly blobPath: string;
    readonly dateCreated: moment.Moment;
    readonly addedByEmail: string;
    readonly adminViewOnly: boolean;
}

export enum AttendeesNotDisplayingReason {
    Unknown = 0,
    NotWithinOneHourWindowToEventStart = 1,
    Locked = 2
}
export interface ShouldAttendeesBeDisplayedResult {
    result: boolean;
    reason?: AttendeesNotDisplayingReason;
}

export interface SubcontractingProcess {
    isActive: boolean;
    originalTrainerId: string;
    newTrainerId: string;
    swapAgreedDate: moment.Moment;
}

export interface MonitoringCandidates {
    id: string;
    fee: number;
    sundries: number;
    name: string;
    otherTrainerType: boolean;
    otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum;
    deliveryDateTime?: moment.Moment;
}

export interface OtherTrainerBaseFee {
    mentor: number;
    mentee: number;
    observer: number;
    firstCourseSupport: number;
}

export enum OtherTrainerTypeCategoryEnum {
    Observer = 0,
    Mentor = 1,
    Mentee = 2,
    FirstCourseSupport = 3
}

export enum HandbackReasonEnum {
    None = 0,
    Illness = 1,
    OtherWorkCommitments = 2,
    Appointment = 3,
    Other = 4
}

export const HandbackReason = {
    [HandbackReasonEnum.None]: "None",
    [HandbackReasonEnum.Illness]: "Illness",
    [HandbackReasonEnum.OtherWorkCommitments]: "Other work commitments",
    [HandbackReasonEnum.Appointment]: "Appointment",
    [HandbackReasonEnum.Other]: "Other"
};

export const OtherTrainerTypeCategory = {
    [OtherTrainerTypeCategoryEnum.Observer]: "Observer",
    [OtherTrainerTypeCategoryEnum.Mentor]: "Mentor",
    [OtherTrainerTypeCategoryEnum.Mentee]: "Mentee",
    [OtherTrainerTypeCategoryEnum.FirstCourseSupport]: "1st Course Support",
};

export const OtherTrainerCategoryDropDownOptions = [
    {
        key: OtherTrainerTypeCategoryEnum.Observer,
        value: OtherTrainerTypeCategoryEnum.Observer,
        text: OtherTrainerTypeCategory[OtherTrainerTypeCategoryEnum.Observer]
    },
    {
        key: OtherTrainerTypeCategoryEnum.Mentor,
        value: OtherTrainerTypeCategoryEnum.Mentor,
        text: OtherTrainerTypeCategory[OtherTrainerTypeCategoryEnum.Mentor]
    },
    {
        key: OtherTrainerTypeCategoryEnum.Mentee,
        value: OtherTrainerTypeCategoryEnum.Mentee,
        text: OtherTrainerTypeCategory[OtherTrainerTypeCategoryEnum.Mentee]
    },
    {
        key: OtherTrainerTypeCategoryEnum.FirstCourseSupport,
        value: OtherTrainerTypeCategoryEnum.FirstCourseSupport,
        text: OtherTrainerTypeCategory[OtherTrainerTypeCategoryEnum.FirstCourseSupport]
    },
];

export enum FeeType {
    SingleTrainerFee
}

export type RegisterStatus = "TBC" | "Attended" | "Not arrived" | "Completed" | "Not completed";

export const OtherCancellationReason = "Other";
export const OrgCancellationReason = "Org cancellation";
export const DigitalCourseCancellationReason = ["Low numbers", "Trainer cancellation", OtherCancellationReason];
export const ClassCourseCancellationReason = ["Low numbers", "Trainer cancellation", "Venue cancelled", "Bad Weather", "Coronavirus", OtherCancellationReason];
export const trainerCancellationReason = ["TTC cancelled", "Swap", "Trainer cancellation", "Illness", OtherCancellationReason];

// The function eventOverlapsWithSession in src/App.Common/src/crud/eventInstance/helpers.ts may break if these change
export const SESSION_TWO_START_HOUR = 10;
export const SESSION_THREE_START_HOUR = 14;
export const SESSION_FOUR_START_HOUR = 18;

export const SUBCONTRACTING_WINDOW_IN_DAYS = 7;

export function isLocked(eventInstance: EventInstance): boolean {
    return isNowAfter24WindowEnd(eventInstance.attendeesRegisterDate);
}

export function isNowAfter24WindowEnd(date?: moment.Moment): boolean {
    const registerWindowEndDate = date && date.clone().add(1, "day");
    return date ? moment().isAfter(registerWindowEndDate) : false;
}

export function shouldAttendeesBeDisplayed(currentUser: CurrentUser, eventInstance: EventInstance): ShouldAttendeesBeDisplayedResult {
    const isTrainer = currentUser?.roles?.includes(TrainerRole);
    const hoursBefore = isEventInstanceWorkflowPoliceOrCourt(eventInstance) ? 1 : 24;
    const showAttendeesTime = eventInstance?.deliveryDateTime &&  eventInstance?.deliveryDateTime.clone().subtract(hoursBefore, "hours");
    const isEventInstanceLocked = isLocked(eventInstance);
    const isWithinWindowToEventStart = eventInstance?.deliveryDateTime && moment().utc().isAfter(showAttendeesTime);

    const result = !isTrainer ||
        !isEventInstanceLocked &&
        isWithinWindowToEventStart;

    const reason = !result ?
        isEventInstanceLocked ? AttendeesNotDisplayingReason.Locked : AttendeesNotDisplayingReason.NotWithinOneHourWindowToEventStart : undefined;
    return { result, reason  };
}

export function shouldAttendeeSpecialRequirementsBeAnonymous(currentUser: CurrentUser, eventInstance: EventInstance) {
    const isAlwaysAllowed = isAuthorized(currentUser, EventManagementAdminRoles) === "Authorized";
    if (isAlwaysAllowed) {
        return false;
    }
    const oneHourBeforeEiStartDate = eventInstance?.deliveryDateTime &&  eventInstance?.deliveryDateTime.clone().subtract(1, "hours");
    return eventInstance?.deliveryDateTime && moment().utc().isBefore(oneHourBeforeEiStartDate);
}

export class EventInstanceBuilder {
    private id: string;
    private name?: string;
    private eventTypeId?: string;
    private eventTypeName?: string;
    private eventTypeAbbreviation?: string;
    private startDate?: moment.Moment;
    private startTime?: moment.Duration;
    private moduleType?: string;
    private language?: number;
    private eventDuration?: moment.Duration;

    constructor() {
        this.name = "Test 1";
        this.eventDuration = moment.duration();
        this.eventTypeName = "";
        this.eventTypeName = "";
        this.eventTypeAbbreviation = "";
        this.startDate = moment();
        this.startTime = moment.duration();
        this.language = LanguageEnum.English;
    }

    public withId(id: string): EventInstanceBuilder {
        this.id = id;
        return this;
    }

    public withName(name: string): EventInstanceBuilder {
        this.name = name;
        return this;
    }

    public withEventTypeId(eventTypeId: string): EventInstanceBuilder {
        this.eventTypeId = eventTypeId;
        return this;
    }

    public withEventTypeName(eventTypeName: string): EventInstanceBuilder {
        this.eventTypeName = eventTypeName;
        return this;
    }

    public withEventTypeAbbreviation(eventTypeAbbreviation: string): EventInstanceBuilder {
        this.eventTypeAbbreviation = eventTypeAbbreviation;
        return this;
    }

    public withStartDate(startDate: moment.Moment): EventInstanceBuilder {
        this.startDate = startDate;
        return this;
    }

    public withStartTime(startTime: moment.Duration): EventInstanceBuilder {
        this.startTime = startTime;
        return this;
    }

    public withModuleType(moduleType: string): EventInstanceBuilder {
        this.moduleType = moduleType;
        return this;
    }

    public withLanguage(language: LanguageEnum): EventInstanceBuilder {
        this.language = language;
        return this;
    }

    public withEventDuration(eventDuration: moment.Duration): EventInstanceBuilder {
        this.eventDuration = eventDuration;
        return this;
    }

    public build(): EventInstanceCreateModel {
        return Object.assign({
            id: this.id,
            name: this.name,
            eventDuration: this.eventDuration,
            eventTypeAbbreviation: this.eventTypeAbbreviation,
            eventTypeId: this.eventTypeId,
            eventTypeName: this.eventTypeName,
            startDate: this.startDate,
            startTime: this.startTime,
            language: this.language,
            moduleType: this.moduleType
        });
    }
}

export interface BasicOrganisationOption {
    text: string;
    value: string;
    key: string;
}

export interface OrganisationOption {
    text: string;
    value: string;
    key: string;
    open: boolean;
    customerStatus?: number;
    companyType?: number[];
    hasCorporateOrganisationSpecificDigitalDetails: boolean;
    hasCorporateOrganisationSpecificPurchaseOrderDrawdowns: boolean;
    organisationContact?: OrganisationContactModel;
    organisationCourseContact?: OrganisationContactModel;
    organisationFinanceContact?: OrganisationContactModel;
    delegatesContact?: DelegatesContactModel;
    managersContact?: ManagersContactModel;
}

export interface BookNowPayLaterOrderAttendees {
    readonly orderNumber: string;
    readonly orderId: string;
    readonly bookerName: string;
    readonly bookerId: string;
    readonly organisationName: string;
    readonly organisationId: string;
    readonly poInvoiceReference: string;
    readonly numberOfSeats: number;
    readonly orderTotal: number;
    readonly attendees: BookNowPayLaterAttendee[];
}

export interface EnquiryEventInstanceData {
    readonly id: string;
    readonly name: string;
    readonly company: string;
    readonly email: string;
    readonly phone: string;
    readonly seats: number;
    readonly state: EventInstanceEnquiryState;
    readonly corporateUserId: string;
    readonly organisationId: string;
    readonly additionalComments?: string;
}

export enum EventInstanceEnquiryState {
    Unknown = 0,
    Active = 1,
    Cancelled = 2,
    Converted = 3
}

export interface BookNowPayLaterAttendee {
    readonly delegateName: string;
    readonly paymentStatus: OrderPaymentStatus;
    readonly isBookingCancelled: boolean;
    readonly completed: CompletionState;
    readonly attendeeId: string;
}

export enum SeatState
{
    None = 0,
    Reserved = 1,
    Booked = 2,
    Removed = 3
}

export enum EnquiryType
{
    NotEnquiry = 0,
    Enquiry = 1,
    ConvertedEnquiry = 2
}

export interface EnquiryStatusUpdateModel {
    eventInstanceId: string;
    enquiryId: string;
    state: EventInstanceEnquiryState;
}
