/* eslint-disable max-lines */
import moment from "moment";
import { validators } from "not-valid";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { DropdownItemProps, Form, Tab, TabProps, DropdownProps } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { optionsFromObject } from "@common/crud/common";
import { TrainerAttributeDefinition, TrainerAttributeDefinitionState, TrainerAttributeType } from "@common/crud/trainerAttributeDefinition";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { AsyncDispatch, BusinessLineType } from "@common/redux-helpers";
import { dorsId } from "@common/validation/dorsIdValidator";
import { positiveNumber } from "@common/validation/positiveNumber";
import {
    AppState,
    BookingAppTextKeys,
    BookingType,
    BookingTypeEnum,
    CorporateCertificateType,
    ClassroomEventTypeDetails,
    CourseFeesEventTypeDetails,
    DigitalEventTypeDetails,
    EventTypeCreateEditModel,
    EventTypePart,
    initialFeeDetail,
    InitialiseEventTypeParts,
    ModuleType,
    ModuleTypeEnum,
    PoliceAndCourtWorkflowType,
    ProductCategory,
    WorkflowTypeEnum,
    ProductCategoryEnum,
    CorporateCertificateTypeEnum,
    CorporateWorkflowTypes,
    ProductCategoryFilteredByWorkflow,
    DurationType,
    DurationTypeEnum,
    ConstructionWorkflowType,
    ProductWorkflowByCategory
} from "../model";
import { basePathSelector } from "../selectors";
import { createEventType } from "../actions";
import { CreateModal } from "./CreateModal";
import { ClassroomDetails } from "./details/ClassroomDetails";
import { DigitalDetails } from "./details/DigitalDetails";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CommunicationTab } from "./details/CommunicationTab";
import { FeeWithEffectiveDateList } from "./details/FeeWithEffectiveDateList";
import { toast } from "@common/toasts";
import { yesNoOptions } from "@common/crud/common/optionsMappers";
import { CourseFeesTab } from "./details/CourseFeesTab";
import { SchemeDeliveryType, SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { RebookingFeesForm } from "@common/crud/eventType/components/details/RebookingFeesForm";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { CourseFees } from "./details/CourseFees";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";
import { isBusinessDriverAdmin, isConstructionSelector, isCorporateSelector, isPoliceOrCourtSelector,
    isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { MuiDateField } from "@common/components/MuiDateField";
import { eventTypeModifyingIsMultiDay, isBusinessLineTypeCorporateOrConstruction, isNullOrUndefinedOrEmptyString, isWorkflowConstruction,
    isWorkflowCorporate, supportsOnRoadReport, workflowTypeSupportsCertificate } from "@common/global/CommonHelpers";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { CREATE_EVENTTYPE } from "@common/crud/eventType/actiontypes";
import { ProductCategoryMatchesWorkflowType } from "../helpers";
import { AppCommonState } from "@common/appCommonState";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { omit } from "lodash";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { Category } from "@common/appSettings/model";
import { EditCourseCategories } from "@common/components/EditCourseCategories";

export interface CreateProps {
    open: boolean;
    isCorporate: boolean;
    isConstruction: boolean;
    isPoliceOrCourt: boolean;
    trainerAttributes: DropdownItemProps[];
    complianceAttributes: DropdownItemProps[];
    isBusinessDriverAdmin: boolean;
    isTtcCorporateAdmin: boolean;
    push: (path: string) => void;
    isSaving: boolean;
    businessLineType: BusinessLineType;
    constructionCategories: Category[];
}

export interface DispatchProps extends SaveDispatchProps<EventTypeCreateEditModel> {
    close: () => void;
}

interface EditState extends FormState<EventTypeCreateEditModel> {
    activeIndex: string | number;
}

const defaultClassroomDetails: ClassroomEventTypeDetails = {
    defaultNumberOfClassroomDeliveryDays: 1,
    suggestedStartTimesForSessions: {},
    classroomEventTypeParts: InitialiseEventTypeParts(DeliveryTypeEnum.Onsite),
    trainerFees: []
};

const defaultDigitalDetails: any = {
    defaultNumberOfDigitalDeliveryDays: 1,
    digitalEventSuggestedStartTimesForSessions: {},
    digitalEventTypeParts: InitialiseEventTypeParts(DeliveryTypeEnum.Onsite),
    trainerFees: []
};

const defaultCourseFeesDetails: CourseFeesEventTypeDetails = {
    digitalEIFeeWithEffectiveDate: [],
    classroomEIFeeWithEffectiveDate: [],
    closedDigitalEIFeeWithEffectiveDate: [],
    closedClassroomEIFeeWithEffectiveDate: [],
    openDigitalEIFeeWithEffectiveDate: [],
    openClassroomEIFeeWithEffectiveDate: [],
    cpcUploadFeeRequired: false,
    cpcUploadFee: []
};

export class CreateForm extends FormBaseComponent<EventTypeCreateEditModel, CreateProps, EditState> {
    private isDors = () => this.state.values.workflowType === WorkflowTypeEnum.Dors;
    private isDdrs = () => this.state.values.workflowType === WorkflowTypeEnum.DDRS;
    private isCpc = () => this .state.values.workflowType === WorkflowTypeEnum.CPC;
    private isCorporateWorkflow = () => isWorkflowCorporate(this.state.values.workflowType);
    private isConstructionWorkflow = () => isWorkflowConstruction(this.state.values.workflowType);
    private showCertificateInfo = () => !this.state.values.flexibleCertificatesRequired
    && (this.state.values.productCategory === +(ProductCategoryEnum.CPC)
    || this.state.values.productCategory === +(ProductCategoryEnum.FORS));

    private isDataSelectedForCourseFees = () =>
        this.state.values.workflowType && this.state.values.deliveryType && this.state.values.bookingType;

    private deliveryType = () => this.state?.values.deliveryType;
    private requiresClassroomDetails = () => this.deliveryType() === SchemeDeliveryTypeEnum.Classroom
    || this.deliveryType() === SchemeDeliveryTypeEnum.ClassroomAndDigital;

    private requiresDigitalDetails = () => this.deliveryType() === SchemeDeliveryTypeEnum.Digital
    || this.deliveryType() === SchemeDeliveryTypeEnum.ClassroomAndDigital;

    private requiresCourseFees = () => (this.isDors() && this.deliveryType() === SchemeDeliveryTypeEnum.Digital
    || this.deliveryType() === SchemeDeliveryTypeEnum.ClassroomAndDigital) || ((this.isCorporateWorkflow() || this.isConstructionWorkflow())
        && this.isDataSelectedForCourseFees());

    private isMultiDay = () => eventTypeModifyingIsMultiDay(this.state.values.deliveryType, this.state.values.classroomEventTypeDetails,
        this.state.values.digitalEventTypeDetails);

    private panes = [
        {
            index: 0, menuItem: "Main Details", render: () => {
                const {
                    moduleType,
                    durationType,
                    deliveryType,
                    workflowType,
                    bookingType,
                    productCategory,
                    mandatoryCorpCertificate,
                    optionalCorpCertificate
                } = this.state.values;

                return (
                    <Tab.Pane key="main-details">
                        {(this.props.isCorporate || this.props.isConstruction) &&
                            <ExtendedTextInput
                                value={this.state.values.name}
                                label="Name"
                                showErrors={this.state.showErrors}
                                required
                                onChange={this.onChange("name")}
                            />
                        }
                        {this.props.isPoliceOrCourt &&
                        <>
                            <ExtendedTextInput
                                value={this.state.values.name}
                                label="Name (En)"
                                showErrors={this.state.showErrors}
                                required
                                onChange={this.onChange("name")}
                            />
                            <ExtendedTextInput
                                value={this.state.values.nameCy}
                                label="Name (Cy)"
                                showErrors={this.state.showErrors}
                                onChange={this.onChange("nameCy")}
                            />
                        </>
                        }
                        <ExtendedTextInput
                            value={this.state.values.abbreviation}
                            label="Abbreviation"
                            showErrors={this.state.showErrors}
                            required
                            onChange={this.onChange("abbreviation")}
                        />
                        <ExtendedDropdownNumber
                            value={workflowType}
                            label="Workflow Type"
                            required
                            validation={[validators.requiredNumber()]}
                            showErrors={this.state.showErrors}
                            options={optionsFromObject(this.props.isCorporate
                                ? CorporateWorkflowTypes(this.props.isBusinessDriverAdmin, this.props.isTtcCorporateAdmin)
                                : this.props.isConstruction
                                    ? ConstructionWorkflowType
                                    : PoliceAndCourtWorkflowType)}
                            onChange={this.updateWorkflowType}
                            dynamicOptions
                            search
                        />
                        {(this.isCpc() || this.isConstructionWorkflow) && (
                            <>
                                <MarkdownEditor
                                    value={this.state.values?.productIntro}
                                    label="Product Intro"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("productIntro")}
                                />
                                <MarkdownEditor
                                    value={this.state.values.productDescription}
                                    label="Product Description"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("productDescription")}
                                />
                            </>
                        )}
                        {(this.isCorporateWorkflow() || this.isConstructionWorkflow() || this.isDors()) && <ExtendedDropdownNumber
                            value={moduleType}
                            label="Course Type"
                            showErrors={this.state.showErrors}
                            required
                            options={ObjectKeys(ModuleType)
                                .filter(k => +k !== 0)
                                .filter(k => (this.state.values.deliveryType !== SchemeDeliveryTypeEnum.Digital
                                    && this.state.values.deliveryType !== SchemeDeliveryTypeEnum.ClassroomAndDigital)
                                    || +k === ModuleTypeEnum.Theory)
                                .filter(k => !this.isMultiDay() || +k !== ModuleTypeEnum.Both)
                                .map(k => ({ text: ModuleType[k], value: +k }))}
                            onChange={this.updateModuleType}
                            search
                            dynamicOptions
                        />}
                        {((this.isCorporateWorkflow() || this.isConstructionWorkflow()) && moduleType === ModuleTypeEnum.Both) && (
                            <ExtendedDropdownNumber
                                value={durationType}
                                label="Duration Type"
                                showErrors={this.state.showErrors}
                                required
                                options={ObjectKeys(DurationType).filter(k => +k !== 0).map(k => ({ text: DurationType[k], value: +k }))}
                                onChange={this.updateDurationType}
                                search
                            />
                        )}
                        {!this.props.isPoliceOrCourt && <>
                            <ExtendedDropdownNumber
                                value={bookingType}
                                label="Booking Type"
                                showErrors={this.state.showErrors}
                                required
                                options={ObjectKeys(BookingType).filter(k => +k !== 0).map(k => ({ text: BookingType[k], value: +k }))}
                                onChange={this.updateBookingType}
                                search
                            />
                            {(this.isCpc() || this.isConstructionWorkflow()) && <Input.Checkbox
                                value={this.state.values?.showInOpenBookingApp}
                                label="Show in Open Booking App"
                                showErrors={this.state.showErrors}
                                onChange={this.onChange("showInOpenBookingApp")}
                                toggle
                                disabled={!bookingType || bookingType === BookingTypeEnum.Closed}
                            />}
                            <Input.Checkbox
                                label="Delegates Required?"
                                value={Boolean(this.state.values.delegatesRequired)}
                                onChange={this.onChange("delegatesRequired")}
                                toggle
                                readOnly={bookingType === BookingTypeEnum.Open
                                    || workflowType === WorkflowTypeEnum.CPC}
                                disabled={bookingType === BookingTypeEnum.Open
                                    || workflowType === WorkflowTypeEnum.CPC}
                            />
                            {this.isCpc() && <Input.Checkbox
                                label={"Flexible Certificates Required?"}
                                value={Boolean(this.state.values.flexibleCertificatesRequired)}
                                onChange={this.onChange("flexibleCertificatesRequired")}
                                toggle
                            />}
                            {workflowTypeSupportsCertificate(this.state.values.workflowType) &&
                                <Input.Checkbox
                                    label="Certificate Required?"
                                    value={Boolean(this.state.values.certificateRequired)}
                                    onChange={this.onChange("certificateRequired")}
                                    toggle
                                />
                            }
                            {supportsOnRoadReport(workflowType, productCategory) &&
                                <Input.Checkbox
                                    label="On Road Report Required"
                                    value={Boolean(this.state.values.onRoadReportRequired)}
                                    onChange={this.onChange("onRoadReportRequired")}
                                    toggle
                                />
                            }
                            {this.props.isConstruction &&
                                <>
                                    <Input.Checkbox
                                        label="Reseller Course"
                                        value={Boolean(this.state.values.resellerCourse)}
                                        onChange={this.onChange("resellerCourse")}
                                        toggle
                                    />
                                    <EditCourseCategories
                                        label="Course Categories"
                                        categories={this.props.constructionCategories}
                                        values={this.state.values.courseCategories}
                                        onChange={this.onChange("courseCategories")} />
                                </>
                            }
                            <ExtendedDropdownNumber
                                value={productCategory}
                                label="Product Category"
                                showErrors={this.state.showErrors}
                                required
                                dynamicOptions
                                options={ObjectKeys(omit(ProductCategory, [ProductCategoryEnum.None]))
                                    .filter(k => this.props.isBusinessDriverAdmin ? true : ProductWorkflowByCategory[k] !== WorkflowTypeEnum.BusinessDriver)
                                    .filter(k => this.props.isTtcCorporateAdmin ? true : ProductCategoryFilteredByWorkflow(
                                        [WorkflowTypeEnum.CPC, WorkflowTypeEnum.Workshop, WorkflowTypeEnum.OnRoad]).includes(+k) === false)
                                    .filter(k => !workflowType || ProductCategoryMatchesWorkflowType(+k, workflowType))
                                    .map(k => ({ text: ProductCategory[k], value: +k }))}
                                onChange={this.updateProductCategory}
                                search
                            />
                            {this.showCertificateInfo() &&
                            <>
                                <ExtendedDropdownNumber
                                    value={mandatoryCorpCertificate}
                                    label="Mandatory Certificate"
                                    showErrors={this.state.showErrors}
                                    options={ObjectKeys(CorporateCertificateType)
                                        .filter(k => +k !== 0).map(k => ({ text: CorporateCertificateType[k], value: +k }))}
                                    required
                                    disabled
                                />
                                <ExtendedDropdownNumber
                                    value={optionalCorpCertificate}
                                    label="Optional Certificate"
                                    showErrors={this.state.showErrors}
                                    options={ObjectKeys(CorporateCertificateType)
                                        .map(k => ({ text: CorporateCertificateType[k], value: +k }))}
                                    required
                                    disabled
                                />
                            </>}
                        </>}
                        <ExtendedDropdownNumber
                            value={deliveryType}
                            label="Delivery Type"
                            showErrors={this.state.showErrors}
                            required
                            options={ObjectKeys(SchemeDeliveryType)
                                .filter(k => +k !== 0)
                                .filter(k => (this.state.values.moduleType !== ModuleTypeEnum.Practical && this.state.values.moduleType !== ModuleTypeEnum.Both)
                                    || +k === SchemeDeliveryTypeEnum.Classroom)
                                .map(k => ({ text: SchemeDeliveryType[k], value: +k }))}
                            onChange={this.updateDeliveryType}
                            dynamicOptions
                            search
                        />
                        {!this.props.isPoliceOrCourt && <>
                            {(isWorkflowCorporate(workflowType) || isWorkflowConstruction(workflowType)) &&
                                <>
                                    {(deliveryType === SchemeDeliveryTypeEnum.Classroom || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                                        <Input.Text
                                            value={this.state.values.internalId}
                                            label="Internal ID (Classroom)"
                                            onChange={this.onChange("internalId")}
                                        />
                                    }
                                    {(deliveryType === SchemeDeliveryTypeEnum.Digital || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                                        <Input.Text
                                            value={this.state.values.internalIdDigital}
                                            label="Internal ID (Digital)"
                                            onChange={this.onChange("internalIdDigital")}
                                        />
                                    }
                                </>
                            }
                            {(workflowType === WorkflowTypeEnum.CPC || productCategory === ProductCategoryEnum.OnRoadWithCpc)  &&
                                <Input.Text
                                    value={this.state.values.courseApprovalNumber}
                                    label="Course Approval Number"
                                    onChange={this.onChange("courseApprovalNumber")}
                                />
                            }
                        </> }
                        {(moduleType === ModuleTypeEnum.Practical ||
                            moduleType === ModuleTypeEnum.Both) &&
                            <Input.Number
                                value={this.state.values.maxNumberOfAttendeesPerPracticalTrainer}
                                label="Max Number Of Attendees Per Practical Trainer"
                                showErrors={this.state.showErrors}
                                validation={[validators.requiredNumber(), positiveNumber()]}
                                validationOptions={{ sequential: false }}
                                onChange={this.onChange("maxNumberOfAttendeesPerPracticalTrainer")}
                            />
                        }
                        {moduleType !== ModuleTypeEnum.Practical &&
                        (deliveryType === SchemeDeliveryTypeEnum.Classroom || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital ) &&
                            <ExtendedDropdown
                                value={this.state.values.theoryTrainerAttributeId}
                                label="Theory Trainer attribute"
                                showErrors={this.state.showErrors}
                                dynamicOptions
                                options={this.props.trainerAttributes}
                                required
                                onChange={this.onChange("theoryTrainerAttributeId")}
                                search
                            />}
                        {moduleType !== ModuleTypeEnum.Practical &&
                        (deliveryType === SchemeDeliveryTypeEnum.Digital || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)  &&
                            <ExtendedDropdown
                                value={this.state.values?.digitalEventTypeDetails?.digitalTrainerAttributeId}
                                label="Digital Trainer attribute"
                                showErrors={this.state.showErrors}
                                dynamicOptions
                                options={this.props.trainerAttributes}
                                required
                                onChange={this.onDetailsChange("digitalEventTypeDetails.digitalTrainerAttributeId")}
                                search
                            />}
                        {this.props.isPoliceOrCourt && (
                            <ExtendedDropdown
                                value={this.state.values?.welshTrainerAttributeId}
                                label="Welsh Trainer attribute"
                                showErrors={this.state.showErrors}
                                dynamicOptions
                                options={this.props.trainerAttributes}
                                required
                                onChange={this.onDetailsChange("welshTrainerAttributeId")}
                                search
                            />
                        )}
                        {((this.isCorporateWorkflow() || this.isConstructionWorkflow()) && (moduleType === ModuleTypeEnum.Practical ||
                            moduleType === ModuleTypeEnum.Both)) &&
                                <ExtendedDropdown
                                    value={this.state.values.practicalTrainerAttributeId}
                                    label="Practical Trainer attribute"
                                    showErrors={this.state.showErrors}
                                    dynamicOptions
                                    options={this.props.trainerAttributes}
                                    required
                                    onChange={this.onChange("practicalTrainerAttributeId")}
                                    search
                                />}
                        {((this.isDors()) && (moduleType === ModuleTypeEnum.Practical ||
                            moduleType === ModuleTypeEnum.Both)) &&
                            <>
                                <ExtendedDropdown
                                    value={this.state.values.manualCarPracticalTrainerAttributeId}
                                    label="Manual Car Practical Trainer attribute"
                                    showErrors={this.state.showErrors}
                                    dynamicOptions
                                    options={this.props.trainerAttributes}
                                    required
                                    onChange={this.onChange("manualCarPracticalTrainerAttributeId")}
                                    search
                                />
                                <ExtendedDropdown
                                    value={this.state.values.automaticCarPracticalTrainerAttributeId}
                                    label="Automatic Car Practical Trainer attribute"
                                    showErrors={this.state.showErrors}
                                    dynamicOptions
                                    options={this.props.trainerAttributes}
                                    required
                                    onChange={this.onChange("automaticCarPracticalTrainerAttributeId")}
                                    search
                                />
                            </>}
                        {this.state.values.workflowType && (this.isDors() || this.isDdrs()) &&
                        <ExtendedDropdown
                            value={this.state.values.monitorAttributeId}
                            label="Monitor Attribute"
                            showErrors={this.state.showErrors}
                            dynamicOptions
                            options={this.props.trainerAttributes}
                            onChange={this.onChange("monitorAttributeId")}
                            search
                        />}
                        {(moduleType === ModuleTypeEnum.Theory ||
                            moduleType === ModuleTypeEnum.Both) &&
                            <Form.Dropdown
                                floating
                                selection
                                multiple
                                value={this.state.values.theoryComplianceAttributeIds}
                                label="Theory Compliance attributes"
                                showErrors={this.state.showErrors}
                                dynamicOptions
                                options={this.props.complianceAttributes}
                                onChange={this.onDropdownPropertyChange("theoryComplianceAttributeIds")}
                                search
                            />}
                        {(moduleType === ModuleTypeEnum.Practical ||
                            moduleType === ModuleTypeEnum.Both) &&
                            <>
                                <Form.Dropdown
                                    floating
                                    selection
                                    multiple
                                    value={this.state.values.practicalComplianceAttributeIds}
                                    label="Practical Compliance attributes"
                                    showErrors={this.state.showErrors}
                                    dynamicOptions
                                    options={this.props.complianceAttributes}
                                    onChange={this.onDropdownPropertyChange("practicalComplianceAttributeIds")}
                                    search
                                />
                            </>}
                        {this.props.isPoliceOrCourt && <ExtendedDropdown
                            value={this.state.values.disableAutoAllocateIfTrainerNonCompliant?.toString() ?? "false"}
                            label="Disable auto allocate if trainer non compliant"
                            showErrors={this.state.showErrors}
                            dynamicOptions
                            options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                            required
                            onChange={this.updateDisableAutoAllocateIfTrainerNonCompliant}
                            search
                        />}
                        {this.isDors() && <Input.Number
                            value={this.state.values.dorsId}
                            label="DORS ID"
                            showErrors={this.state.showErrors}
                            validation={dorsId}
                            onChange={this.onChange("dorsId")}
                        />}
                        {this.props.isPoliceOrCourt && (
                            <>
                                <Input.Textarea
                                    value={this.state.values.noteEn}
                                    label={"Scheme Note (English)"}
                                    validation={[validators.validLength({ max: 1000 })]}
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("noteEn")}
                                />
                                <Input.Textarea
                                    value={this.state.values.noteCy}
                                    label={"Scheme Note (Welsh)"}
                                    validation={[validators.validLength({ max: 1000 })]}
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("noteCy")}
                                />
                            </>
                        )}
                        {!this.props.isPoliceOrCourt && (
                            <MarkdownEditor
                                value={this.state.values.productNote}
                                label="Product Note"
                                showErrors={this.state.showErrors}
                                onChange={this.onChange("productNote")}
                            />
                        )}
                        <Input.Textarea
                            value={this.state.values.introMessageEnglish}
                            label="Intro Message (English)"
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("introMessageEnglish")}
                        />
                        <Input.Textarea
                            value={this.state.values.introMessageWelsh}
                            label="Intro Message (Welsh)"
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("introMessageWelsh")}
                        />
                        {this.isDors() &&
                        <ExtendedDropdown
                            value={this.state.values.showCarType?.toString() ?? "false"}
                            label="Show car"
                            showErrors={this.state.showErrors}
                            dynamicOptions
                            options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                            required
                            onChange={this.updateShowCarType}
                            search
                        />}
                        {
                            this.state.values.showCarType &&
                            <>
                                <Input.Text
                                    value={this.state.values.amendableBookingAppTexts[BookingAppTextKeys.EnglishCarTypeNote] ?? ""}
                                    label="Car Type Note (En)"
                                    showErrors={this.state.showErrors}
                                    required
                                    onChange={this.onBookingAppTextChange(BookingAppTextKeys.EnglishCarTypeNote)}
                                />
                                <Input.Text
                                    value={this.state.values.amendableBookingAppTexts[BookingAppTextKeys.CymraegCarTypeNote] ?? ""}
                                    label="Car Type Note (Cy)"
                                    showErrors={this.state.showErrors}
                                    required
                                    onChange={this.onBookingAppTextChange(BookingAppTextKeys.CymraegCarTypeNote)}
                                />
                            </>
                        }
                        <Input.Text
                            value={this.state.values.surveyLink}
                            label="Survey 1 Link - English"
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("surveyLink")}
                        />
                        <Input.Text
                            value={this.state.values.surveyLinkCy}
                            label="Survey 1 Link - Welsh"
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("surveyLinkCy")}
                        />
                        <Input.Text
                            value={this.state.values.secondSurveyLink}
                            label="Survey 2 Link - English"
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("secondSurveyLink")}
                        />
                        <Input.Text
                            value={this.state.values.secondSurveyLinkCy}
                            label="Survey 2 Link - Welsh"
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("secondSurveyLinkCy")}
                        />
                        {(this.state.values.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                            this.state.values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) && (
                            <>
                                <Input.Text
                                    value={this.state.values.bookingInformationLinkClassroom}
                                    label="Classroom Booking Information Link - English"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("bookingInformationLinkClassroom")}
                                />
                                <Input.Text
                                    value={this.state.values.bookingInformationLinkClassroomCy}
                                    label="Classroom Booking Information Link - Welsh"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("bookingInformationLinkClassroomCy")}
                                />
                            </>
                        )}
                        {(this.state.values.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                            this.state.values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) && (
                            <>
                                <Input.Text
                                    value={this.state.values.bookingInformationLinkDigital}
                                    label="Digital Booking Information Link - English"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("bookingInformationLinkDigital")}
                                />
                                <Input.Text
                                    value={this.state.values.bookingInformationLinkDigitalCy}
                                    label="Digital Booking Information Link - Welsh"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("bookingInformationLinkDigitalCy")}
                                />
                            </>
                        )}
                        {this.props.isPoliceOrCourt &&
                        <ExtendedDropdown
                            value={this.state.values.flexiPayEnabled?.toString() ?? "false"}
                            label="Flexi-Booking Enabled"
                            showErrors={this.state.showErrors}
                            options={yesNoOptions()}
                            onChange={this.updateFlexiPayEnabled}
                            search
                        />}
                        {this.state.values.flexiPayEnabled &&
                            <>
                                <h3>Flexi-Booking Fees</h3>
                                <FeeWithEffectiveDateList
                                    fees={this.state.values.flexiPayFees}
                                    feesProperty="flexiPayFees"
                                    feeLabel="Flexi-Booking"
                                    onChange={this.onChange}
                                />
                            </>
                        }
                        {this.isDors() &&
                        <ExtendedDropdown
                            value={this.state.values.currentDorsScheme?.toString() ?? "false"}
                            label="Current DORS scheme"
                            showErrors={this.state.showErrors}
                            options={yesNoOptions()}
                            onChange={this.updateCurrentDorsScheme}
                            search
                        />}
                        <RebookingFeesForm form={this} />
                        <MuiDateField
                            value={this.state.values.expiryDate}
                            label="Expiry Date"
                            validation={[muiTodayOrfutureDateValidator]}
                            onChange={this.onChange("expiryDate")}
                        />
                    </Tab.Pane>
                );
            }
        },
        {
            index: 1, menuItem: "Classroom details", visible: this.requiresClassroomDetails, render: () => {
                return (
                    <ClassroomDetails
                        classroomEventTypeDetails={this.state.values.classroomEventTypeDetails}
                        workflowType={this.state.values.workflowType}
                        productCategory={this.state.values.productCategory}
                        updateStartTimes={this.updateStartTimes}
                        updateEventTypeParts={this.updateEventTypeParts}
                        showErrors={this.state.showErrors}
                        onChange={this.onDetailsChange}
                        theoryAndPractical={this.state.values.moduleType === ModuleTypeEnum.Both}
                        durationType={this.state.values.durationType}
                        showInOpenBookingApp={this.state.values.showInOpenBookingApp}
                    />
                );
            }
        },
        {
            index: 2, menuItem: "Digital details", visible: this.requiresDigitalDetails, render: () => {
                return (
                    <DigitalDetails
                        digitalEventTypeDetails={this.state.values.digitalEventTypeDetails}
                        workflowType={this.state.values.workflowType}
                        productCategory={this.state.values.productCategory}
                        updateStartTimes={this.updateStartTimes}
                        updateEventTypeParts={this.updateEventTypeParts}
                        showErrors={this.state.showErrors}
                        onChange={this.onDetailsChange}
                        showInOpenBookingApp={this.state.values.showInOpenBookingApp}
                    />
                );
            }
        },
        {
            index: 3, menuItem: "Course Fees", visible: this.requiresCourseFees, render: () => {
                return (
                    <CourseFees
                        courseFeesEventTypeDetails={this.state.values.courseFeesEventTypeDetails }
                        workflowType={this.state.values.workflowType}
                        deliveryType={this.state.values.deliveryType}
                        bookingType={this.state.values.bookingType}
                        showErrors={this.state.showErrors}
                        eventTypeName={this.state.values.name}
                        onChange={this.onDetailsChange}
                    />
                );
            }
        },
        {
            index: 4, menuItem: "Communications", render: () => {
                return (
                    <CommunicationTab
                        model={this.state.values}
                        showErrors={this.state.showErrors}
                        onPropertyChange={this.onChange}
                        onNestedPropertyChange={this.onDetailsChange}
                    />
                );
            }
        },
    ];

    private ddrsPanes = [...this.panes,
        {
            index: 5, menuItem: "Course Fees", render: () => {
                return (
                    <CourseFeesTab
                        ddrsCourseFees={this.state.values.ddrsCourseFees}
                        showErrors={this.state.showErrors}
                        onPropertyChange={this.onChange}
                        push={this.props.push}
                        create

                    />
                );
            }
        }
    ];

    constructor(props: CreateProps & SaveDispatchProps<EventTypeCreateEditModel>) {
        super(props);
        this.state = {
            values: Object.assign({
                id: "",
                name: "",
                durationType: isBusinessLineTypeCorporateOrConstruction(props.businessLineType)
                    ? undefined
                    : DurationTypeEnum.SeparateTheoryPractical,
                delegatesRequired: isBusinessLineTypeCorporateOrConstruction(props.businessLineType)
                    ? true
                    : undefined,
                certificateRequired: isBusinessLineTypeCorporateOrConstruction(props.businessLineType)
                    ? true
                    : undefined,
                classroomEventTypeDetails: defaultClassroomDetails,
                digitalEventTypeDetails: defaultDigitalDetails,
                courseFeesEventTypeDetails: defaultCourseFeesDetails,
                ddrsCourseFees: initialFeeDetail,
                amendableBookingAppTexts: {},
                flexiPayFees: [],
                rebookingFees: [],
                courseCategories: []
            }),
            valid: {
            },
            activeIndex: 0
        };
    }

    public render() {
        const panes = this.isDdrs() ? this.ddrsPanes: this.panes;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tab panes={panes?.filter(p => p?.visible ? p.visible() : true)} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
            </Form>
        );
    }

    public updateWorkflowType = (value: number) => {
        if (value === WorkflowTypeEnum.Dors) {
            this.updateNestedProperty("classroomEventTypeDetails.classroomEventTypeParts", null, true);
            this.updateNestedProperty("digitalEventTypeDetails.digitalEventTypeParts", null, true);
        } else {
            this.updateNestedProperty("classroomEventTypeDetails.classroomEventTypeParts", defaultClassroomDetails.classroomEventTypeParts, true);
            this.updateNestedProperty("digitalEventTypeDetails.digitalEventTypeParts", defaultDigitalDetails.digitalEventTypeParts, true);
        }

        if (value === WorkflowTypeEnum.DDRS) {
            this.updateProperty("moduleType", ModuleTypeEnum.Theory, true);
            this.updateProperty("dorsId", null, true);
        }

        if (supportsOnRoadReport(value, this.state.values.productCategory)) {
            this.updateProperty("onRoadReportRequired", true, true);
        }
        else {
            this.updateProperty("onRoadReportRequired", null, true);
        }

        if (value === WorkflowTypeEnum.CPC) {
            this.updateProperty("delegatesRequired", true, true);
        }

        if (value !== WorkflowTypeEnum.CPC) {
            this.updateProperty("flexibleCertificatesRequired", false, true);
        }

        this.updateProperty("certificateRequired", true, true);

        if (this.state.values.productCategory && !ProductCategoryMatchesWorkflowType(this.state.values.productCategory, value)) {
            this.updateProperty("productCategory", null, true);
        }
        this.updateProperty("workflowType", value, true);
    };

    public onBookingAppTextChange = (textKey: string) => (value: string, valid: boolean) => {
        const newValue = { ...this.state.values.amendableBookingAppTexts, [textKey]: value };
        this.updateProperty("amendableBookingAppTexts", newValue, valid);
    };

    public updateShowCarType = (value: string) => {
        this.updateBoolean(value, "showCarType");
    };

    public updateBoolean = (value: string, key: keyof EventTypeCreateEditModel) => {
        this.updateProperty(key, value === "true", true);
    };

    public updateDisableAutoAllocateIfTrainerNonCompliant = (value: string) => {
        this.updateBoolean(value, "disableAutoAllocateIfTrainerNonCompliant");
    };

    public updateFlexiPayEnabled = (value: string) => {
        const flexiPayEnabled = value === "true";
        if (!flexiPayEnabled) {
            this.updateProperty("flexiPayInstructionsEn", "", true);
            this.updateProperty("flexiPayInstructionsCy", "", true);
        }

        this.updateProperty("flexiPayEnabled", flexiPayEnabled, true);
    };

    public updateCurrentDorsScheme = (value: string) => {
        this.updateBoolean(value, "currentDorsScheme");
    };

    public updateModuleType = (value: number, valid: boolean) => {
        if (value !== ModuleTypeEnum.Both) {
            if (value !== ModuleTypeEnum.Theory) {
                this.updateNestedProperty("classroomEventTypeDetails.maxNumberOfAttendeesPerTrainer", null, true);
                this.updateNestedProperty("digitalEventTypeDetails.maxNumberOfAttendeesPerTrainer", null, true);
                this.updateProperty("theoryComplianceAttributeIds", [], true);
            }

            if (value !== ModuleTypeEnum.Practical) {
                this.updateProperty("maxNumberOfAttendeesPerPracticalTrainer", null, true);
                this.updateProperty("practicalTrainerAttributeId", null, true);
                this.updateProperty("manualCarPracticalTrainerAttributeId", null, true);
                this.updateProperty("automaticCarPracticalTrainerAttributeId", null, true);
                this.updateProperty("practicalComplianceAttributeIds", [], true);
            }

            if (this.state.values.durationType !== DurationTypeEnum.SeparateTheoryPractical) {
                this.updateDurationType(DurationTypeEnum.SeparateTheoryPractical, true);
            }
        }
        this.updateProperty("moduleType", value, valid);
    };

    public updateDurationType = (value: number, valid: boolean) => {
        this.updateNestedProperty("classroomEventTypeDetails.theoryEventDurations", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.suggestedStartTimesForTheorySessions", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.practicalEventDurations", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.suggestedStartTimesForPracticalSessions", null, true);
        this.updateProperty("durationType", value, valid);
    };

    public updateBookingType = (value: number, valid: boolean) => {
        if (!value || value === BookingTypeEnum.Closed) {
            this.updateProperty("showInOpenBookingApp", false, true);
        }

        if (value === BookingTypeEnum.Open) {
            this.updateProperty("delegatesRequired", true, true);
        }

        this.updateProperty("bookingType", value, valid);
    };

    public updateProductCategory = (value: number, valid: boolean) => {
        switch (value) {
            case ProductCategoryEnum.CPC:
                this.updateProperty("mandatoryCorpCertificate", CorporateCertificateTypeEnum.CPC, valid);
                this.updateProperty("optionalCorpCertificate", CorporateCertificateTypeEnum.None, valid);
                break;
            case ProductCategoryEnum.FORS:
                this.updateProperty("mandatoryCorpCertificate", CorporateCertificateTypeEnum.FORS, valid);
                this.updateProperty("optionalCorpCertificate", CorporateCertificateTypeEnum.CPC, valid);
                break;
            case ProductCategoryEnum.OpenreachStandard:
            case ProductCategoryEnum.OpenreachTrailerTowing:
            case ProductCategoryEnum.StandardOnRoad:
            case ProductCategoryEnum.MultiDropVehicle:
            case ProductCategoryEnum.OnRoadWithCpc:
            case ProductCategoryEnum.StandardBD:
            case ProductCategoryEnum.OnRoadTrailerTowing:
            case ProductCategoryEnum.LicenceAcquisition:
                this.updateProperty("mandatoryCorpCertificate", null, valid);
                this.updateProperty("optionalCorpCertificate", null, valid);
                break;
            default:
                this.updateProperty("mandatoryCorpCertificate", null, valid);
                this.updateProperty("optionalCorpCertificate", null, valid);
                break;
        }

        if (this.state.values.workflowType === WorkflowTypeEnum.BusinessDriver) {
            if (this.state.values.productCategory === ProductCategoryEnum.LicenceAcquisition && value !== ProductCategoryEnum.LicenceAcquisition) {
                this.updateProperty("onRoadReportRequired", true, true);
            }

            if (this.state.values.productCategory !== ProductCategoryEnum.LicenceAcquisition && value === ProductCategoryEnum.LicenceAcquisition) {
                this.updateProperty("onRoadReportRequired", null, true);
            }
        }

        this.updateProperty("productCategory", value, valid);
    };

    public updateDeliveryType = (value: number, valid: boolean) => {
        if (value !== SchemeDeliveryTypeEnum.ClassroomAndDigital) {
            if (value !== SchemeDeliveryTypeEnum.Classroom) {
                this.updateProperty("classroomEventTypeDetails", defaultClassroomDetails, true);
                this.updateProperty("theoryTrainerAttributeId", null, true);
                this.updateProperty("internalId", "", true);
            }

            if (value !== SchemeDeliveryTypeEnum.Digital) {
                this.updateProperty("digitalEventTypeDetails", defaultDigitalDetails, true);
                this.updateNestedProperty("digitalEventTypeDetails.digitalTrainerAttributeId", null, true);
                this.updateProperty("internalIdDigital", "", true);
            }
        }

        if (this.isDors() || !this.props.isPoliceOrCourt) {
            this.updateNestedProperty("classroomEventTypeDetails.classroomEventTypeParts", null, true);
            this.updateNestedProperty("digitalEventTypeDetails.digitalEventTypeParts", null, true);
        }

        this.updateProperty("deliveryType", value, valid);
    };

    public onDropdownPropertyChange = (propName: keyof (EventTypeCreateEditModel)) => (event: any, { value }: DropdownProps) =>
        this.onChange(propName)(value as any, true);

    public onChange = (propName: keyof EventTypeCreateEditModel) => (value: any, valid: boolean) => {
        this.updateProperty(propName, value, valid);
    };

    public onDetailsChange = (propName: string) => (value: any, valid: boolean) => {
        this.updateNestedProperty(propName, value, valid);
    };

    public submit = () => {
        if (this.state.values.hasRebookingFees && !this.state.values.rebookingFees.some(fee => fee.feeAfter14Days > 0 && fee.feeCloseToCourseDate > 0)) {
            toast.warning(!this.props.isPoliceOrCourt
                ? "Product cannot be saved as rebooking fees are required"
                : "Scheme cannot be saved as rebooking fees are required");
            return;
        }

        if (this.state.values.competencyReportRequired &&
            isNullOrUndefinedOrEmptyString(this.state.values.competencyReportLink))
        {
            toast.warning("A competency report link is required");
            return;
        }

        if (!this.state.values.flexiPayEnabled || this.flexiPayFeesValid()) {
            this.handleSubmit({ preventDefault: (): void => undefined } as any);
        }
    };

    private flexiPayFeesValid = () => {
        if (!this.state.values.flexiPayFees?.length) {
            toast.error("You must include at least one flexi-booking fee.");
            return false;
        }

        const uniqueFeeDates = new Set(this.state.values.flexiPayFees.map(f => f.effectiveDate.toISOString()));
        if (uniqueFeeDates.size < this.state.values.flexiPayFees.length) {
            toast.error("Two or more flexi-booking fees have the same effective date.");
            return false;
        }

        return true;
    };

    public updateStartTimes = (propName: keyof EventTypeCreateEditModel, startTimes: Dictionary<moment.Duration[]>) => {
        const startTimeValues = Object.values(startTimes);
        const valid = startTimeValues.flatMap(x => x).every(x => x && x.isValid()) &&
        startTimeValues.every(x => x.length === new Set(x.map(y => y?.asMilliseconds())).size);
        this.updateNestedProperty(propName, startTimes, valid);
    };

    public updateEventTypeParts = (propName: keyof ClassroomEventTypeDetails | keyof DigitalEventTypeDetails,
        eventTypeParts: Dictionary<EventTypePart>) => {
        this.updateNestedProperty(propName, eventTypeParts, true);
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));
}

function mapStateToProps(state: AppState & TrainerAttributeDefinitionState & LoadingState & AppCommonState) {
    let businessLineType: BusinessLineType;
    if (state.router.pathname.indexOf("/corporate-event-management") !== -1) {
        businessLineType = BusinessLineType.Corporate;
    } else if (state.router.pathname.indexOf("/construction-event-management") !== -1) {
        businessLineType = BusinessLineType.Construction;
    } else if (state.router.pathname.indexOf("/police-and-court-event-management") !== -1) {
        businessLineType = BusinessLineType.PoliceAndCourt;
    } else {
        businessLineType = BusinessLineType.Unknown;
    }

    return {
        trainerAttributes: trainerAttributeDefinitionsSelector(state) as TrainerAttributeDefinition[],
        open: state.router.pathname.endsWith("/create"),
        isCorporate: isCorporateSelector(state),
        isConstruction: isConstructionSelector(state),
        isPoliceOrCourt: isPoliceOrCourtSelector(state),
        basePath: basePathSelector(state),
        ddrsFeesOpen: state.router.pathname.endsWith("feesOpen/create"),
        isSaving: isRequestActive(state, CREATE_EVENTTYPE),
        isBusinessDriverAdmin: isBusinessDriverAdmin(state),
        isTtcCorporateAdmin: isTtcCorporateAdmin(state),
        businessLineType,
        constructionCategories: state.appSettings.constructionCategories
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (eventType: EventTypeCreateEditModel, basePath: string) => dispatch(createEventType(eventType, basePath)),
        dispatchPush: (basePath: string) => dispatch(push(basePath)),
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): CreateProps & DispatchProps {
    return {
        open: propsFromState.open,
        isCorporate: propsFromState.isCorporate,
        isConstruction: propsFromState.isConstruction,
        isPoliceOrCourt: propsFromState.isPoliceOrCourt,
        trainerAttributes: propsFromState.trainerAttributes.map((attr: TrainerAttributeDefinition) => ({ value: attr.id, text: attr.name })),
        complianceAttributes: propsFromState.trainerAttributes
            .filter((attr: TrainerAttributeDefinition) => attr.attributeType === TrainerAttributeType.Continuum)
            .map((attr: TrainerAttributeDefinition) => ({ value: attr.id, text: attr.name })),
        save: (eventType: EventTypeCreateEditModel) => {
            if (propsFromState.ddrsFeesOpen) {
                return;
            }
            return propsFromDispatch.dispatchSave(eventType, propsFromState.basePath);},
        close: () => propsFromDispatch.dispatchPush(propsFromState.basePath),
        push: (path) => propsFromDispatch.dispatchPush(`${propsFromState.basePath}/${path}`),
        isSaving: propsFromState.isSaving,
        isBusinessDriverAdmin: propsFromState.isBusinessDriverAdmin,
        isTtcCorporateAdmin: propsFromState.isTtcCorporateAdmin,
        businessLineType: propsFromState.businessLineType,
        constructionCategories: propsFromState.constructionCategories
    };
}
export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
