import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Grid } from "semantic-ui-react";
import { InternalAppSettings } from "@common/appSettings/model";
import { AppCommonState } from "@common/appCommonState";
import { DorsBookingDetailModel, AppState } from "../model";
import { dorsBookingSelector } from "../selectors";
import { CoreDetails } from "./details/CoreDetails";
import { EventType, EventTypeState } from "../../eventType";
import { RecordCaAction } from "@common/genesys/genesysActions";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { AsyncDispatch } from "@common/redux-helpers/async";
import { GenesysTokenProvider } from "@common/genesys/components/GenesysTokenProvider";
import { AdminRecordedActionType } from "@common/genesys/model";

export interface CoreDetailProps {
    dorsBooking: DorsBookingDetailModel;
    internalAppSettings: InternalAppSettings;
    eventTypes: EventType[];
}

export interface DispatchProps {
    push: (path: string) => void;
    recordCaAction: (genesysToken: string, correlationId: string) => Promise<void>;

}

class DetailInternal extends React.Component<CoreDetailProps & DispatchProps, {}> {
    private onTokenReceived = (genesysToken: string) => {
        this.props.recordCaAction(genesysToken, this.props.dorsBooking.id);
    };
    public render() {
        const { dorsBooking } = this.props;

        return (
            <>
                <Authorize authorize={TtcClientAdvisorRole}>
                    <GenesysTokenProvider onTokenReceived={this.onTokenReceived} showLogin={false} />
                </Authorize>
                <Grid container stackable className="nomargintop">
                    <Grid.Row>
                        <Grid.Column>
                            <h1>{dorsBooking.forename} {dorsBooking.surname}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <CoreDetails {...this.props} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state: AppState & AppCommonState & EventTypeState): CoreDetailProps {
    return {
        dorsBooking: dorsBookingSelector(state),
        internalAppSettings: state.appSettings.internalAppSettings,
        eventTypes: state.eventTypes || [],
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path)),
        recordCaAction: (genesysToken: string, correlationId: string) =>
            dispatch(RecordCaAction(genesysToken, correlationId, AdminRecordedActionType.dorsBookingRecordRead))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
