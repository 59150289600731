import * as React from "react";
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { phoneNumberRegex } from "@common/validation/phoneNumber";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { ApplicationState } from "../../applicationState";
import { MissingBookingProps } from "../models";
import { BookingDetailsField } from "./BookingDetailsField";

interface BookingDetailsTelephoneFieldProps {
    useSessionStorage?: boolean;
    bookingId: string;
}

export const BookingDetailsTelephoneField: React.FC<BookingDetailsTelephoneFieldProps> = ({ useSessionStorage, bookingId }) => {
    const [t] = useTranslation("BookingDetails");
    const { errors, touched } = useFormikContext<MissingBookingProps>();
    const isError = errors.telephone && touched.telephone;
    const isClientAdvisor = useSelector((state: ApplicationState) => isUserClientAdvisor(state));

    const validate = React.useCallback((value: string) => {
        if (useSessionStorage ?? true) {
            sessionStorage?.setItem(`booking-telephone-${bookingId}`, value);
        }

        if (phoneNumberRegex.test(value) || (isClientAdvisor && !value)) {
            return null;
        }

        return t("PHONE_NUMBER_REQUIRED");
    }, [bookingId, isClientAdvisor, t, useSessionStorage]);

    return (
        <BookingDetailsField>
            <Form error={isError}>
                <Field
                    name="telephone"
                    type="tel"
                    as={Form.Input}
                    label={<label>
                        {t("TELEPHONE")}
                    </label>}
                    className={`field-with-note${isClientAdvisor?"":" required"}`}
                    placeholder={t("TELEPHONE")}
                    error={isError}
                    validate={validate}
                />
                <p className="field-note">{t("TELEPHONE_NOTE")}</p>
                <ErrorMessage
                    name="telephone"
                    className="error-margin-bottom"
                >
                    {(msg) => <Message error header={msg} />}
                </ErrorMessage>
            </Form>
        </BookingDetailsField>
    );
};
