/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { Form, Tab, TabProps, Icon, Message, Segment } from "semantic-ui-react";
import { FormBaseComponent, SaveDispatchProps, FormState } from "@neworbit/simpleui-forms";
import { debounce } from "@neworbit/simpleui-utils";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, businessLineTypeSelector } from "@common/redux-helpers";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { AppState, CorporateUserDetailModel, CorporateUserCreateEditModel } from "../model";
import { createCorporateUser } from "../actions";
import { CREATE_CORPORATE_USER } from "../actiontypes";
import { OrganisationApi, OrganisationState } from "@common/crud/organisation";
import { toast } from "@common/toasts";
import { CreateModal } from "./CreateModal";
import { basePathSelector, organisationsPathSelector } from "../selectors";
import { AppCommonState } from "@common/appCommonState";
import { CorporateUserApi } from "../corporateUserApi";
import { phoneNumberWithSpaces } from "@common/validation";
import { Department } from "@common/crud/organisation/model";

export interface CreateProps {
    open: boolean;
    basePath: string;
    organisationsPath: string;
    findUser: (email: string) => Promise<CorporateUserDetailModel>;
    loadDepartments: (organisationId: string) => Promise<Department[]>;
    isSaving: boolean;
    organisationId: string;
    hasCorporateOrganisationSpecificDepartments: boolean;
}

export interface DispatchProps extends SaveDispatchProps<CorporateUserCreateEditModel> {
    close: () => void;
}

interface CreateState extends FormState<CorporateUserCreateEditModel> {
    duplicateUser: CorporateUserDetailModel;
    activeIndex: string | number;
    searchingEmail: boolean;
    departments: {text: string; value: string}[];
}

export class CreateForm extends FormBaseComponent<CorporateUserCreateEditModel, CreateProps, CreateState> {
    private onChange = (k: keyof CorporateUserCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private onChangeDepartments = (value: string[], valid: boolean) => {
        const departmentsSelected = this.state.departments?.filter(d => value.some(v => v === d.value))
            .sort((a, b) => a.text.localeCompare(b.text)).map(d => d.value) || value;
        this.onChange("departments")(departmentsSelected, valid);
    };

    private panes = [
        {
            menuItem: "Details", render: () => {
                const { values, duplicateUser, searchingEmail, showErrors } = this.state;
                const { organisationsPath } = this.props;

                return (<Tab.Pane key="mainPane">
                    <Input.Text
                        value={values.forename}
                        label="Forename"
                        showErrors={showErrors}
                        required
                        onChange={this.onChange("forename")}
                    />
                    <Input.Text
                        value={values.surname}
                        label="Surname"
                        showErrors={showErrors}
                        required
                        onChange={this.onChange("surname")}
                    />
                    <Input.Email
                        value={values.email}
                        label="Email"
                        showErrors={showErrors}
                        required
                        onChange={this.onEmailChanged}
                    />
                    {searchingEmail &&
                        <Message as={Segment} className="cancel-action">
                            <Icon loading name='spinner' />
                            Searching for existing users with that email...
                        </Message>
                    }
                    <Input.Text
                        value={values.telephone}
                        label="Telephone"
                        showErrors={showErrors}
                        onChange={this.onChange("telephone")}
                        validation={phoneNumberWithSpaces()}
                    />
                    {this.props.hasCorporateOrganisationSpecificDepartments && (
                        <Input.DropdownMulti
                            value={values.departments}
                            label="Departments"
                            showErrors={this.state.showErrors}
                            options={this.state.departments || []}
                            dynamicOptions
                            onChange={this.onChangeDepartments}
                            multiple
                            search
                        />
                    )}
                    <Input.Checkbox
                        value={values.closedCourseManager}
                        label="Closed course manager"
                        onChange={this.onChange("closedCourseManager")}
                    />
                    <Input.Checkbox
                        value={values.upcomingNewsAndCoursesNewsletter}
                        label="Upcoming news and courses newsletter"
                        onChange={this.onChange("upcomingNewsAndCoursesNewsletter")}
                    />
                    {duplicateUser &&
                        <Message as={Segment} className="cancel-action">
                            <p>User with that email already exists:&nbsp;
                                <Link href={`${organisationsPath}/${duplicateUser.organisationId}/users/${duplicateUser.id}`}>
                                    {duplicateUser.fullName}
                                </Link>
                            </p>
                        </Message>
                    }
                </Tab.Pane>);
            }
        },
    ];

    constructor(props: CreateProps & SaveDispatchProps<CorporateUserCreateEditModel>) {
        super(props);
        this.state = this.initialState;
    }

    public initialValueState: CorporateUserCreateEditModel = {
        id: "",
        forename: "",
        surname: "",
        organisationId: "",
        email: "",
    };

    public initialState: CreateState = {
        values: this.initialValueState,
        valid: {},
        duplicateUser: undefined,
        searchingEmail: false,
        activeIndex: 0,
        departments: []
    };

    public componentDidUpdate() {
        if (this.props.hasCorporateOrganisationSpecificDepartments && this.props.organisationId
            && this.state.values.organisationId !== this.props.organisationId) {
            this.loadDepartmentsIntoState(this.props.organisationId);
            this.setState({ values: { ...this.state.values, organisationId: this.props.organisationId } });
        }
    }

    private loadDepartmentsIntoState = async (organisationId: string) => {
        const departments = await this.props.loadDepartments(organisationId);
        this.setState({ departments: departments.map(d => ({ text: d.name, value: d.id })) });
    };

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tab panes={this.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
            </Form>
        );
    }

    public submit = () => {
        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            toast.error("Please correct any invalid fields");
            this.setState(prevState => ({ ...prevState, showErrors: true, activeIndex: 0 }));
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const duplicateUser = await this.props.findUser(value);
            this.setState({ searchingEmail: false, duplicateUser, valid: { ...this.state.valid, email: !duplicateUser } });
        } else {
            this.setState({ duplicateUser: null });
        }
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));
}

function mapStateToProps(state: AppState & LoadingState & OrganisationState & AppCommonState) {
    const pathname = state.router.pathname;
    const organisationId = state.router.params.organisationId;
    const open = pathname.endsWith("/create");
    const basePath = basePathSelector(state);
    const organisationsPath = organisationsPathSelector(state);
    const businessLineType = businessLineTypeSelector(state);

    const hasCorporateOrganisationSpecificDepartments = state.organisations && state.organisations.length > 0 && state.organisations
        .filter(c => c.id === organisationId)[0]?.corporateOrganisationData?.hasCorporateOrganisationSpecificDepartments;

    return {
        open,
        organisationId,
        basePath,
        organisationsPath,
        isSaving: isRequestActive(state, CREATE_CORPORATE_USER),
        hasCorporateOrganisationSpecificDepartments,
        businessLineType
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (corporateUser: CorporateUserCreateEditModel, basePath: string) => {
            return dispatch(createCorporateUser(corporateUser, basePath));
        },
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): CreateProps & DispatchProps {
    const organisationId = propsFromState.organisationId;
    const businessLineType = propsFromState.businessLineType;
    const api = new CorporateUserApi();
    const organisationApi = new OrganisationApi();
    return {
        organisationId,
        basePath: propsFromState.basePath,
        organisationsPath: propsFromState.organisationsPath,
        open: propsFromState.open,
        save: (delegate: CorporateUserCreateEditModel) => propsFromDispatch.dispatchSave({ ...delegate, organisationId }, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
        findUser: debounce((email: string) => api.findByEmail(email, businessLineType), 300),
        loadDepartments: (departmentOrganisationId: string) => organisationApi.getDepartments(departmentOrganisationId, true),
        isSaving: propsFromState.isSaving,
        hasCorporateOrganisationSpecificDepartments: propsFromState.hasCorporateOrganisationSpecificDepartments
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
