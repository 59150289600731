import moment from "moment";
import { EventTypeApi } from "@common/crud/eventType";

export const nonExpiredEventTypes = (expiryDate: moment.Moment) => !expiryDate || expiryDate > moment().utc();

export const expiryDateIsAfterOrBeforeExistingEiDateValidator = async (eventTypeId: string,
    selectedExpiryDate: moment.Moment) => {
    if (!selectedExpiryDate) {
        return undefined;
    }
    const api = new EventTypeApi();
    const date = await api.getFutureEventInstanceDateForEventType(eventTypeId, selectedExpiryDate);
    return date.length
        ? `Scheme has an existing course with date
        ${date}.
        Expiry date must be set after.`
        : undefined;
};
