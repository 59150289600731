import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Checkbox, Form, Grid } from "semantic-ui-react";
import {
    EditComponent,
    EditProps as SharedEditProps,
    SaveDispatchProps,
} from "@neworbit/simpleui-forms";
import { AsyncDispatch, BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { OrganisationEditModel, AppState } from "../model";
import { corporateOrConstructionBasePathSelector, organisationSelector } from "../selectors";
import { saveOrganisation } from "../actions";
import { NonPoliceFeesEditModal } from "./NonPoliceFeesEditModal";
import { EditFieldWithEffectiveDate } from "@common/crud/eventType/components/EditFieldWithEffectiveDate";
import { getCurrencyFormat } from "@common/formating";
import { CurrencyPicker } from "@common/crud/eventType/components/EventTypeFieldPickerHelpers";
import { OpenCourseFee, ValueWithEffectiveDate } from "../../eventType/model";
import { CorporateFeeTable } from "../../eventType/components/CorporateFeeTable";

export interface EditProps extends SharedEditProps<OrganisationEditModel> {
    businessLineType: BusinessLineType;
    open: boolean;
}

export interface DispatchProps
    extends SaveDispatchProps<OrganisationEditModel> {
    close: () => void;
}

export class NonPoliceFeesEditForm extends EditComponent<
    OrganisationEditModel,
    EditProps
> {
    public submitting = false;

    public shouldComponentUpdate() {
        return !this.submitting;
    }

    private openDigitalCoursesPropertyName = "corporateOrganisationData.corporateOrganisationSpecificFeesData.openDigitalEIFeeWithEffectiveDate";
    private openClassroomCoursesPropertyName = "corporateOrganisationData.corporateOrganisationSpecificFeesData.openClassroomEIFeeWithEffectiveDate";
    private closedDigitalCoursesPropertyName = "corporateOrganisationData.corporateOrganisationSpecificFeesData.closedDigitalEIFeeWithEffectiveDate";
    private closedClassroomCoursesPropertyName = "corporateOrganisationData.corporateOrganisationSpecificFeesData.closedClassroomEIFeeWithEffectiveDate";
    private cpcUploadPropertyName = "corporateOrganisationData.corporateOrganisationSpecificFeesData.cpcUploadFee";

    private updateOpenDigitalCoursesFees = (fees: ValueWithEffectiveDate<OpenCourseFee>[]) => {
        this.updateNestedProperty(
            this.openDigitalCoursesPropertyName,
            fees,
            true);
    };

    private updateOpenClassroomCoursesFees = (fees: ValueWithEffectiveDate<OpenCourseFee>[]) => {
        this.updateNestedProperty(
            this.openClassroomCoursesPropertyName,
            fees,
            true);
    };

    private updateClosedDigitalCoursesFees = (fees: ValueWithEffectiveDate<number>[]) => {
        this.updateNestedProperty(
            this.closedDigitalCoursesPropertyName,
            fees,
            true);
    };

    private updateClosedClassroomCoursesFees = (fees: ValueWithEffectiveDate<number>[]) => {
        this.updateNestedProperty(
            this.closedClassroomCoursesPropertyName,
            fees,
            true);
    };

    private updateCpcUploadFees = (fees: ValueWithEffectiveDate<number>[]) => {
        this.updateNestedProperty(
            this.cpcUploadPropertyName,
            fees,
            true);
    };

    private onBundleDiscountEnabledChange = (event: React.FormEvent<HTMLInputElement>, data: any) => {
        this.updateNestedProperty("corporateOrganisationData.isBundleDiscountEnabled", data.checked, true);
    };

    public render() {
        const { values, showErrors } = this.state;

        const openDigitalCoursesFees =
            values.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.openDigitalEIFeeWithEffectiveDate;
        const openClassroomCoursesFees =
            values.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.openClassroomEIFeeWithEffectiveDate;
        const closedDigitalCoursesFees =
            values.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.closedDigitalEIFeeWithEffectiveDate;
        const closedClassroomCoursesFees =
            values.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.closedClassroomEIFeeWithEffectiveDate;
        return (
            <Form onSubmit={this.handleSubmit}>
                <CorporateFeeTable
                    isOpen={true}
                    isDigital={true}
                    showEditButton={true}
                    fees={openDigitalCoursesFees ?? []}
                    onChange={this.updateOpenDigitalCoursesFees}
                    isWeekendSupplementRequired={true}
                />
                <CorporateFeeTable
                    isOpen={true}
                    isDigital={false}
                    showEditButton={true}
                    fees={openClassroomCoursesFees ?? []}
                    onChange={this.updateOpenClassroomCoursesFees}
                    isWeekendSupplementRequired={true}
                />
                <CorporateFeeTable
                    isOpen={false}
                    isDigital={true}
                    showEditButton={true}
                    fees={closedDigitalCoursesFees ?? []}
                    onChange={this.updateClosedDigitalCoursesFees}
                />
                <CorporateFeeTable
                    isOpen={false}
                    isDigital={false}
                    showEditButton={true}
                    fees={closedClassroomCoursesFees ?? []}
                    onChange={this.updateClosedClassroomCoursesFees}
                />
                {!this.props.model.corporateOrganisationData.openCourse && (
                    <EditFieldWithEffectiveDate
                        allFields={
                            values.corporateOrganisationData
                                ?.corporateOrganisationSpecificFeesData
                                ?.cpcUploadFee
                        }
                        onChange={this.updateCpcUploadFees}
                        CreateFieldPicker={CurrencyPicker("Upload Fee")}
                        showErrors={showErrors}
                        fieldName="Upload Fee"
                        valueView={getCurrencyFormat}
                        allowEmpty
                    />
                )}
                {this.props.businessLineType === BusinessLineType.Corporate && (
                    <Grid>
                        <h4>CPC bundle discount enabled?</h4>
                        <Checkbox
                            toggle
                            checked={values.corporateOrganisationData?.isBundleDiscountEnabled}
                            onChange={this.onBundleDiscountEnabledChange}
                        />
                    </Grid>
                )}
            </Form>
        );
    }

    public submit = () => {
        this.submitting = true;
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };
}

function mapStateToProps(state: AppState) {
    return {
        businessLineType: businessLineTypeSelector(state),
        model: organisationSelector(state) as OrganisationEditModel,
        open: state.router.pathname.endsWith("/edit"),
        basePath: corporateOrConstructionBasePathSelector(state),
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (organisation: OrganisationEditModel, basePath: string) =>
            dispatch(saveOrganisation(organisation, basePath, "fees")),
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
    };
}

function mergeProps(
    propsFromState: any,
    propsFromDispatch: any
): EditProps & DispatchProps {
    return {
        businessLineType: propsFromState.businessLineType,
        model: propsFromState.model,
        open: propsFromState.open,
        save: (organisation: OrganisationEditModel) =>
            propsFromDispatch.dispatchSave(
                organisation,
                propsFromState.basePath
            ),
        close: () =>
            propsFromDispatch.dispatchClose(
                `${propsFromState.basePath}/${propsFromState.model.id}/fees`
            ),
    };
}

export const NonPoliceFeesEdit = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(NonPoliceFeesEditModal);
