import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "@common/components";
import { Input } from "@neworbit/simpleui-input";
import { Button, Form } from "semantic-ui-react";
import { UpdateEventTypeIdAndNameRequest } from "../../../dorsBooking/model";
import { updateEventTypeIdAndName } from "@common/crud/attendee/actions";
import { Authorize } from "reauthorize";
import { TtcFinanceAdministratorRole } from "@common/auth/model";
import { DorsBooking } from "@common/crud/dorsBooking";
import { EventType } from "../../../eventType";

interface EditProps {
    booking: DorsBooking;
    eventTypeId: string;
    eventTypes: EventType[];
}

export const ReferredSchemeLabelWithEdit: React.FC<EditProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [eventTypeId, setEventTypeId] = React.useState(props.eventTypeId);
    const [eventTypeName, setEventType] = React.useState(null);
    const eventTypesOptions = props.eventTypes
        .filter(et => et.dorsId === props.booking?.schemeId)
        .map(et => ({ text: et.name, value: et.id }));
    const dispatch = useDispatch();

    useEffect(() => {
        setEventTypeId(props.eventTypeId);
    }, [props.eventTypeId]);

    useEffect(() => {
        setEventType(eventTypesOptions.find(et => et.value === eventTypeId)?.text);
    }, [eventTypeId, eventTypesOptions]);

    const closeModals = () => {
        setOpen(false);
    };

    const cancelUpdate = React.useCallback(() => {
        closeModals();
        setEventTypeId(props.eventTypeId);
    }, [props.eventTypeId]);

    async function sendUpdate()
    {
        const updateRequest: UpdateEventTypeIdAndNameRequest = {
            dorsBookingId: props.booking?.id,
            eventTypeId,
            eventTypeName,
        };

        await dispatch(updateEventTypeIdAndName(updateRequest, () => closeModals()));
    }

    function onEventTypeIdChange(value: string) {
        setEventTypeId(value);
    }

    function toggleDialog() { setOpen(!open); };

    return (
        <Authorize authorize={TtcFinanceAdministratorRole} >
            {
                eventTypesOptions.length > 1 &&
                <>
                    <div className="label-and-value">
                        <span className="label fixed-width">
                            Referred Scheme
                        </span>
                        <a
                            className="show-button"
                            onClick={toggleDialog}
                        >Update Scheme
                        </a>
                        <div className="value">
                            <span>{eventTypeName}</span>
                        </div>
                    </div>

                    <Modal open={open}>
                        <Modal.Header>
                            Update Scheme
                        </Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Input.Dropdown
                                    value={eventTypeId}
                                    label="Please choose a scheme from the dropdown below"
                                    options={eventTypesOptions}
                                    onChange={onEventTypeIdChange}
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={cancelUpdate}
                                className="cancel-action"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={sendUpdate}
                                icon="checkmark"
                                content="Save"
                            />
                        </Modal.Actions>
                    </Modal>
                </>
            }
        </Authorize>
    );
};
