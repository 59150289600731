import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, DropdownProps, Form, Message } from "semantic-ui-react";
import { Modal } from "@common/components";
import { cancelBooking, cancelGroupBooking } from "../../actions";
import { FreeRebookingFormField } from "@common/crud/common/FreeRebookingFormField";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { OtherCancellationReason } from "@common/crud/eventInstance/model";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";

export interface CancelBookingModalProps {
    attendeeId: string;
    eventInstanceId: string;
    eventTypeCategory: EventTypeCategory;
    correlationId: string;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancelBookingModal: React.SFC<CancelBookingModalProps> = (
    { attendeeId, eventInstanceId, setRefreshingRecord, eventTypeCategory, correlationId }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [rebookReason, setRebookReason] = React.useState<any>();
    const [otherReason, setOtherReason] = React.useState<string>();
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [freeRebookingEnabled, setFreeRebookingEnabled] = React.useState(false);
    const dispatch = useDispatch();

    const otherCancellationReasonChoosen = rebookReason === OtherCancellationReason;

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        if (eventTypeCategory === EventTypeCategory.Ddrs) {
            dispatch(cancelGroupBooking(attendeeId, correlationId, rebookReason, otherReason, freeRebookingEnabled));
        } else {
            dispatch(cancelBooking(eventInstanceId, attendeeId, rebookReason, otherReason, freeRebookingEnabled));
        }
        setOpen(false);
        setRefreshingRecord(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstanceId, attendeeId, rebookReason, dispatch, freeRebookingEnabled, otherReason]);

    const onOpenCancelModalClick = React.useCallback(() => setOpen(true), []);

    const onCancelClick = React.useCallback(() => {
        setFreeRebookingEnabled(false);
        setOpen(false);
        setOtherReason(null);
        setOtherReasonValid(true);
    }, []);

    const onReasonChange = React.useCallback((_: any, d: DropdownProps) => setRebookReason(d.value), []);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    return (
        <>
            <Button onClick={onOpenCancelModalClick}>Cancel</Button>
            <Modal open={open}>
                <Modal.Header>
                    Cancellation
                </Modal.Header>
                <Modal.Content>
                    <Form error={submitted && !rebookReason}>
                        <Form.Select
                            options={[
                                { text: "Other", value: OtherCancellationReason },
                                { text: "Adverse weather conditions", value: "AdverseWeather" },
                                { text: "Client decision no rebook", value: "ClientDecisionNoRebook" },
                                { text: "Offer withdrawn by police", value: "OfferWithdrawnByPolice" },
                                { text: "Company Decision", value: "CompanyDecision" },
                                { text: "Attendee not paid in full", value: "UnpaidDebts" },
                                { text: "Attendee did not attend course", value: "AttendeeDidNotAttendCourse" }
                            ]}
                            error={submitted && !rebookReason}
                            value={rebookReason}
                            label={"What is the reason for cancelling?"}
                            onChange={onReasonChange}
                            name="rebookingReason"
                        />
                        {otherCancellationReasonChoosen && (
                            <Input.Textarea
                                value={otherReason}
                                label="Other Reason"
                                validation={[validators.validLength({ max: 500 })]}
                                onChange={onOtherReasonChanged}
                            />
                        )}
                        {rebookReason &&
                            <FreeRebookingFormField
                                label="Add free rebooking to attendee?"
                                freeRebookingEnabled={freeRebookingEnabled}
                                setFreeRebookingEnabled={setFreeRebookingEnabled}
                            />
                        }
                        <Message
                            error
                            header="Please select a reason for re-booking"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelClick} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        disabled={!rebookReason || (otherCancellationReasonChoosen && !otherReasonValid)}
                        primary
                    >
                        Confirm Cancellation
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
