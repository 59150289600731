import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Segment, Button, Grid, Divider, Checkbox } from "semantic-ui-react";
import { RegisterAttendeeModel, CompletionState } from "@common/crud/attendee";
import { gotoStageOne, submitRegister } from "@common/crud/attendee/actions";
import { ReasonIssueEnum } from "@common/crud/attendee/model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRolesAndTrainers, PoliceAndDdrsAdminRole } from "@common/auth/model";
import { RegisterStatistics } from "./RegisterStatistics";
import { CompletionRegisterRow } from "./CompletionRegisterRow";
import { isNowAfter24WindowEnd } from "../../model";
import { block } from "redux-little-router";
import { EventInstanceApi } from "../..";
import { mapAttendanceRegisterAttendees, getFilterAttendeesData, getRegisterStats, mapCompletionRegisterAttendees } from "./helpers";
import { useRegisterContext } from "./register-context";
import { appSelector, isUserClientAdvisor } from "@common/crud/common/selectors";
import "./general.scss";
import { ConfirmButton } from "@common/components";
import { forceRegisterProcessing } from "../../actions";
import { Apps } from "@common/model";

export const CompletionRegister: React.FC = () => {

    const dispatch = useDispatch();
    const { attendees, eventInstanceOptions } = useRegisterContext();
    const isCa = useSelector(isUserClientAdvisor);
    const app = useSelector(appSelector);
    const isAdminApp = app === Apps.Admin;

    const
        {
            eventInstanceId,
            hasBeenSubmitted,
            attendeesRegisterDate,
            eventInstanceStartDateTime,
            updatesInProgress,
            eventInstanceFinished,
            registerClosed,
            forcedEventInstanceProcessing
        } = eventInstanceOptions;

    const [showError, setShowError] = React.useState<boolean>(false);
    const [needsResubmission, setNeedsResubmission] = React.useState(updatesInProgress);
    const setNeedsSubmission = React.useCallback(() => setNeedsResubmission(true), []);

    const [showRemovedAttendees, setShowRemovedAttendees] = React.useState<boolean>(false);
    function onShowRemovedAttendeesChange() {
        setShowRemovedAttendees(prevValue => !prevValue);
    }

    const {
        sortedAttendees,
        totalPlacesBooked,
        noCancelledAttendee
    } = getFilterAttendeesData(attendees, eventInstanceStartDateTime, showRemovedAttendees);

    const [attendeeRegister, setAttendeeRegister] = React.useState<RegisterAttendeeModel[]>([]);
    React.useEffect(() => {
        setAttendeeRegister(mapAttendanceRegisterAttendees(attendees));
    }, [attendees]);

    function getRegisterAttendee(attendeeId: string): RegisterAttendeeModel {
        return attendeeRegister.find(a => a.id === attendeeId) ?? Object();
    }

    async function onAttendeeRegisterUpdated(updatedAttendee: RegisterAttendeeModel, forceImmediateSave: boolean) {
        const restAttendees = attendeeRegister.filter(a => a.id !== updatedAttendee.id);
        const updatedAttendeeRegister = [...restAttendees, updatedAttendee];
        setAttendeeRegister(updatedAttendeeRegister);

        // Run validation on updates only when show error is already true
        if (showError && validateAttendeeRegister(updatedAttendeeRegister)) {
            setShowError(false);
        }

        if (forceImmediateSave) {
            const models = mapCompletionRegisterAttendees(attendees, updatedAttendeeRegister);
            // complete: false, as this only saves register
            dispatch(submitRegister(eventInstanceId, models, false));
        }

    }

    function validateAttendeeRegister(register: RegisterAttendeeModel[]) {
        const models = mapCompletionRegisterAttendees(attendees, register);
        if (models.some(a =>
            a.completed === CompletionState.Unknown ||
            (!isCa && a.completed === CompletionState.NotCompleted && a.reasonIssue === ReasonIssueEnum.None))) {
            return false;
        }

        return true;
    }

    function validate(): boolean {
        const isValid = validateAttendeeRegister(attendeeRegister);

        if (!isValid) {
            setShowError(true);
            return isValid;
        }

        setShowError(false);
        return isValid;
    }

    async function handleSubmit() {
        if (!validate()) {
            return;
        }
        setNeedsResubmission(false);

        const models = mapCompletionRegisterAttendees(attendees, attendeeRegister);

        // complete: true, marks stage 2 complete
        dispatch(submitRegister(eventInstanceId, models, true));
    }

    const getMessage = () => {
        if (attendeesRegisterDate === null) {
            return null;
        }

        if (needsResubmission) {
            return <p className="red-warning-text">Please resubmit the register to save your updates</p>;
        }

        const isCompleted = !isNowAfter24WindowEnd(attendeesRegisterDate);

        return isCompleted ? "The register has been completed." : "This register has been submitted";
    };

    function onRedirect() { dispatch(gotoStageOne(eventInstanceId)); }

    if (!location.pathname.endsWith("attendees") && window.location.pathname.endsWith("attendees") && needsResubmission) {
        dispatch(block(() => {
            return `Are you sure you want to leave the register?
            Any changes you made will not have been saved.
            This register will still require re submission before it can be processed.
            Alternatively you can discard your changes.`;
        }));
    }

    const onDiscard = React.useCallback(async () => {
        const api = new EventInstanceApi();
        await api.setUpdating(eventInstanceId, false);
        window.location.reload();
    }, [eventInstanceId]);

    const onForceRegisterProcessing = React.useCallback(async () => {
        dispatch(forceRegisterProcessing(eventInstanceId));
    }, [dispatch, eventInstanceId]);

    const hasBeenSubmittedAndNoNeedToResubmit = hasBeenSubmitted && !needsResubmission;
    const submitButtonText = hasBeenSubmittedAndNoNeedToResubmit ? "REGISTER SUBMITTED" : "SUBMIT REGISTER";

    const renderRegisterMessagePara = (
        <div className="register-message-paragraph">
            {!eventInstanceFinished && "Submit action will be enabled once the course has finished"}
            {getMessage()}
            {showError && <p className="error">In order to submit the register, please complete the actions in red.</p>}
        </div>
    );

    const showRemovedAttendeesLabel = `${showRemovedAttendees ? "Hide" : "Show"} removed attendees`;
    const removedAttendeeElement = (
        <Authorize authorize={PoliceAndDdrsAdminRole}>
            <Checkbox
                toggle
                disabled={noCancelledAttendee}
                label={showRemovedAttendeesLabel}
                checked={showRemovedAttendees}
                onChange={onShowRemovedAttendeesChange}
            />
        </Authorize>
    );

    const canForceProcessing = React.useMemo(() =>
        isAdminApp && hasBeenSubmitted && !registerClosed && !forcedEventInstanceProcessing,
    [isAdminApp, forcedEventInstanceProcessing, hasBeenSubmitted, registerClosed]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container padded className="register-table">
                <Grid.Row only="computer tablet">
                    <Grid.Column className="purple-header" computer={6} tablet={6}>
                        Attendee Name
                    </Grid.Column>
                    <Grid.Column className="purple-header" width={hasBeenSubmitted ? 4 : 5} only="computer">
                        Driving Licence
                    </Grid.Column>
                    <Grid.Column className="purple-header" computer={hasBeenSubmitted ? 3 : 5} textAlign="right" tablet={hasBeenSubmitted ? 7 : 10}>
                        Completed
                    </Grid.Column>
                    {hasBeenSubmitted &&
                        <Grid.Column width={3} />
                    }
                </Grid.Row>
                <Grid.Row only="mobile">
                    <Grid.Column width="11" className="purple-header">Attendee name</Grid.Column>
                    <Grid.Column width="5" className="purple-header">Completed</Grid.Column>
                </Grid.Row>
                <Divider />
                {sortedAttendees.map(attendee => (
                    <CompletionRegisterRow
                        key={attendee.id}
                        attendee={attendee}
                        registerAttendee={getRegisterAttendee(attendee.id)}
                        showError={showError}
                        setResubmissionRequired={setNeedsSubmission}
                        resubmissionRequired={needsResubmission}
                        onAttendeeRegisterUpdated={onAttendeeRegisterUpdated}
                        forcedEventInstanceProcessing={forcedEventInstanceProcessing}
                    />
                ))}

                <RegisterStatistics
                    showCompletion
                    registerStats={getRegisterStats(attendees, false, eventInstanceId, totalPlacesBooked, attendeeRegister)}
                    removedAttendeeElement={removedAttendeeElement}
                />
            </Grid>
            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <Segment className="button-segment mobile-only">
                    <div className="button-container right-align">
                        <Button type="submit" content={submitButtonText} disabled={!eventInstanceFinished || hasBeenSubmittedAndNoNeedToResubmit} />
                        {canForceProcessing && (
                            <ConfirmButton
                                icon="check"
                                content={"You are about to update this register as processed. If you complete this action, the relevant"
                                    + " register processing tasks will be initiated within 30 minutes. If you would like to continue,"
                                    + " please click the OK button. To cancel this process, click CANCEL"}
                                header="Mark register as processed"

                                onConfirm={onForceRegisterProcessing}
                            >
                                Process Register
                            </ConfirmButton>
                        )}
                        {renderRegisterMessagePara}
                        {needsResubmission && <Button icon="close" color="red" type="button" onClick={onDiscard} content="DISCARD CHANGES" />}
                        <Button icon="angle left" color="grey" type="button" onClick={onRedirect} disabled={registerClosed} content="UPDATE ARRIVALS" />
                    </div>
                </Segment>
                <Segment className="button-segment not-mobile">
                    <div className="button-container left-align">
                        <Button icon="angle left" color="grey" type="button" onClick={onRedirect} disabled={registerClosed} content="UPDATE ARRIVALS" />
                        {needsResubmission && <Button icon="close" color="red" type="button" onClick={onDiscard} content="DISCARD CHANGES" />}
                        <Button type="submit" content={submitButtonText} disabled={!eventInstanceFinished || hasBeenSubmittedAndNoNeedToResubmit} />
                        {canForceProcessing && (
                            <ConfirmButton
                                icon="check"
                                content={"You are about to update this register as processed. If you complete this action, the relevant"
                                    + " register processing tasks will be initiated within 30 minutes. If you would like to continue,"
                                    + " please click the OK button. To cancel this process, click CANCEL"}
                                header="Mark register as processed"

                                onConfirm={onForceRegisterProcessing}
                            >
                                Process Register
                            </ConfirmButton>
                        )}
                    </div>
                    {renderRegisterMessagePara}
                </Segment>
            </Authorize>
        </Form>
    );
};
