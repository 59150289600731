/* eslint-disable max-lines */
import * as React from "react";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { Authorize } from "reauthorize";
import { TrainerRole, TtcPlannerRole } from "@common/auth/model";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { basePathSelector } from "../selectors";
import { Area, AutoSpreadStatus, AutoSpreadStatusEnum, ExtendedEventInstance, TrainerAvailabilityRoleTypeEnum } from "../model";
import { EventInstance, EventInstanceListModel } from "../../eventInstance";
import { WelshLanguageIndicator } from "./WelshLanguageIndicator";
import { appSelector, routerPathnameSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { MonitorIcon } from "@common/crud/common/MonitorIcon";
import { ObserverIcon } from "@common/crud/common/ObserverIcon";
import { errorMessageDisplay } from "@common/crud/eventInstance/components/ErrorMessageDisplay";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { routeTrainerIdSelector } from "@common/crud/trainer/selectors";
import { TrainerRoleColumn } from "@common/crud/eventInstance/components/trainers/TrainerRoleColumn";
import { ModuleType, ModuleTypeEnum } from "@common/crud/eventType/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { baseEventManagementSelector, getPathString } from "../selectors";
import { isBusinessLineTypeCorporateOrConstruction } from "@common/global/CommonHelpers";
import { AddressDisplay } from "@common/crud/trainer/components/details/AddressDisplay";
import { GetEventInstanceDateDisplay } from "../helpers";

interface AllItemsProps {
    area: Area;
    eventInstances: EventInstanceListModel[];
    selectedEventInstances?: string[];
    registeredInterestEventInstanceModels?: EventInstanceListModel[];
    onEventInstanceSelect?: (eventInstanceId: string, role?: TrainerAvailabilityRoleTypeEnum) => void;
    selectAll?: () => void;
    isOnAvailableCoursesView?: boolean;
    trainerId?: string;
    groups?: EventInstanceGroupModel[];
    classroomGroups?: EventInstanceListModel[][];
    digitalGroups?: EventInstanceListModel[][];
}

export const AllItems: React.FC<AllItemsProps> = ({
    eventInstances, onEventInstanceSelect, area, selectedEventInstances, registeredInterestEventInstanceModels, selectAll, isOnAvailableCoursesView,
    trainerId, groups, classroomGroups, digitalGroups }) => {
    const app = useSelector(appSelector);
    const trainerIdFromRoute = useSelector(routeTrainerIdSelector);
    const [extendedEventInstances, setExtendedEventInstances] = React.useState<ExtendedEventInstance[]>([]);
    const basePath = useSelector(basePathSelector);
    const baseEventManagementPath = useSelector(baseEventManagementSelector);
    const businessLineType = useSelector(businessLineTypeSelector);
    const pathname = useSelector(routerPathnameSelector);

    React.useEffect(() => {
        const isGroupedWithPrevious = (index: number, curr: EventInstanceListModel, prev: EventInstanceListModel) => (
            index > 0 &&
            curr.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
            curr.eventTypeId === prev.eventTypeId &&
            curr.startDate.isSame(prev.startDate) &&
            curr.venueName === prev.venueName &&
            curr.role === prev.role
        );

        setExtendedEventInstances(eventInstances.map((e: EventInstanceListModel, i: number): ExtendedEventInstance => ({
            ...e,
            path: getPathString(e.workflowType, basePath, app, pathname, area),
            onChange: () => onEventInstanceSelect(e.id, e.role),
            isSelected: selectedEventInstances?.includes(e.id) ?? false,
            customKey: `${e.id}${e.role || ""}`,
            isGroupedWithPrevious: isOnAvailableCoursesView && isGroupedWithPrevious(i, e, eventInstances[i - 1]),
        })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstances, onEventInstanceSelect, selectedEventInstances, area, trainerId, basePath,
        isOnAvailableCoursesView, getPathString]);

    const adminView = area === Area.AdminEventManagementEventInstance ||
        area === Area.AdminEventManagementCalendar ||
        area === Area.AdminEventManagementVenueCalendar ||
        area === Area.AdminEventManagementTrainerEventInstance ||
        area === Area.AdminEventManagementSpreadingOverview ||
        area === Area.AdminEventManagementCorporateOrganisation;
    const trainerView = !isOnAvailableCoursesView && area === Area.TrainerAppSchedule;
    const isChecked = eventInstances.length === selectedEventInstances?.length;
    const availabilityView = area === Area.AdminEventManagementTrainerCalendar ||
        area === Area.TrainerAppAvailabilityCalendar;

    const selectColumn = {
        key: "tick-all",
        functionalHeader: () => <Checkbox onClick={selectAll} checked={isChecked} label="Tick All" />,
        value: (e: ExtendedEventInstance) => {
            return (
                <Authorize authorize={TtcPlannerRole}>
                    <Checkbox
                        checked={e.isSelected}
                        onChange={e.onChange}
                    />
                    {
                        e && (e.errorMessageDuringPublish || e.errorMessageDuringUpdateTrainers || e.errorMessageDuringUpdateDorsOpenPlacesCount) &&
                        <Popup
                            content={errorMessageDisplay(e)}
                            trigger={<Icon name={"exclamation triangle"} />}
                        />
                    }
                </Authorize>
            );
        }
    };

    const selectAllAdminColumn = (area !== Area.AdminEventManagementCalendar && area !== Area.AdminEventManagementVenueCalendar) ? [selectColumn] : [];
    const selectAllTrainerColumn = app === Apps.Admin ? [selectColumn] : [];

    const getColumns = () => {

        const getPath = (eventInstance: EventInstance) => {
            let pathId = eventInstance.id;
            if (availabilityView && eventInstance.groupId) {
                const group = groups?.find(g => g.id === eventInstance.groupId);
                const dayOneId = group?.eventInstanceGroupItems.find(gi => gi.dayNumber === 1).eventInstanceId;
                pathId = dayOneId;
            }
            return pathId;

        };
        if (adminView) {
            return [
                ...venueColumn(app, area, false, trainerId),
                ...(isBusinessLineTypeCorporateOrConstruction(businessLineType) && area === Area.AdminEventManagementEventInstance
                    ? organisationColumn(baseEventManagementPath)
                    : []),
                ...commonColumns(app, area, false, getPath, businessLineType),
                ...(area === Area.AdminEventManagementTrainerEventInstance ? adminTrainerScheduleColumns(trainerIdFromRoute) : []),
                ...adminColumns(getPath, area, businessLineType),
                ...(area === Area.AdminEventManagementSpreadingOverview ? [] : selectAllAdminColumn)
            ];
        }
        else if (trainerView) {
            return [
                ...venueColumn(app, area, false, trainerId),
                ...commonColumns(app, area, false, getPath, businessLineType),
                ...app === Apps.Admin ? trainerColumnsInAdmin : [],
                ...trainerColumns,
                ...selectAllTrainerColumn
            ];
        }
        else if (isOnAvailableCoursesView) {
            return [
                ...venueColumn(app, area, true, trainerId),
                ...commonColumns(app, area, true, getPath, businessLineType),
                ...getAvailableCoursesColumns(trainerId, registeredInterestEventInstanceModels, classroomGroups, digitalGroups)
            ];
        }

        return [
            ...venueColumn(app, area, false, trainerId),
            ...commonColumns(app, area, false, getPath, businessLineType),
            ...(area === Area.AdminEventManagementTrainerCalendar || area === Area.TrainerAppAvailabilityCalendar)
                ? adminTrainerScheduleColumns(trainerIdFromRoute ?? trainerId)
                : [],
        ];
    };

    const emptyArrayMessage = area === Area.AdminEventManagementSpreadingOverview
        ? "No courses found with automated spreading in progress"
        : "No courses on this date, range of dates or selection criteria";

    return (
        <TypedTable
            values={extendedEventInstances}
            tableClassName="event-instance-table"
            emptyValuesArrayMessage={emptyArrayMessage}
        >
            {getColumns()}
        </TypedTable>
    );
};

const sumProperty = (key: any, values: EventInstance[]) => values.reduce((a, b) => a + b[key], 0);

const sumPropertyTernary = (key: any, otherKey: any, values: EventInstance[]) => values.reduce((a, b) => {
    const max = Math.max(b[key] ?? 0, b[otherKey] ?? 0);
    return a + max;
}, 0);

const dynamicCellClassNameForAvailableCoursesView = (e: ExtendedEventInstance) => e.isGroupedWithPrevious ? "no-border" : "";

const venueColumn = (app: Apps, area: Area, isOnAvailableCoursesView: boolean, trainerId: string):
    TypedTableRowProps<ExtendedEventInstance>[] => {
    const isCourseForTrainer = (ei: ExtendedEventInstance) => ei.allTrainerIds.includes(trainerId);
    const isCourseForMultiDayTrainer = (ei: ExtendedEventInstance) => ei.groupId && isCourseForTrainer(ei);

    const staticDisplay = (e: ExtendedEventInstance) => isOnAvailableCoursesView || (e.groupId && !isCourseForTrainer(e));
    const getStaticDisplay = (e: ExtendedEventInstance) => (
        <span>
            {isOnAvailableCoursesView && e.eventInstanceDeliveryType === DeliveryTypeEnum.Digital ? "Online Venue" : e.venueName}
            <WelshLanguageIndicator language={e.language} />
        </span>
    );

    const venueDisplay = (e: ExtendedEventInstance) => staticDisplay(e) ? getStaticDisplay(e) :
        <Link className="event-name-link" href={`${e.path}/${e.id}`}>
            {e.corporateOrganisationName && area !== Area.AdminEventManagementEventInstance && area !== Area.AdminEventManagementCorporateOrganisation ?
                <>{e.venueName}<br />{e.corporateOrganisationName}</> : e.venueName}
            {e.hasAttendeesWithSpecialRequirements && <Icon name="speakap" />}
            <WelshLanguageIndicator language={e.language} />
            {e.oneToOne && <img className="one-to-one-icon" src={"/assets/one-to-one.png"} />}
        </Link>;

    const columns = [
        {
            header: "Venue",
            cellClassName: "break-word",
            value: (e: ExtendedEventInstance) => {
                return (
                    <>
                        {venueDisplay(e)}
                    </>
                );
            },
            dynamicCellClassName: (e: ExtendedEventInstance) => (isCourseForMultiDayTrainer(e) && isOnAvailableCoursesView)
                ? "course-for-multiday-trainer"
                : (isOnAvailableCoursesView ? dynamicCellClassNameForAvailableCoursesView(e) : undefined)
        },
    ];

    if ((app === Apps.Trainer && area === Area.TrainerAppAvailabilityCalendar)
        || (app === Apps.Admin && area === Area.AdminEventManagementTrainerCalendar)) {
        columns.push({
            header: "Address",
            cellClassName: "break-word",
            value: (e: ExtendedEventInstance) => {
                return (
                    <div>{e.eventInstanceDeliveryType === DeliveryTypeEnum.Digital
                        ? "Delivered by Zoom"
                        : e.venueAddress
                            ? AddressDisplay(e.venueAddress)
                            : "-"}</div>
                );
            },
            dynamicCellClassName: (e: ExtendedEventInstance) => (isCourseForMultiDayTrainer(e) && isOnAvailableCoursesView)
                ? "course-for-multiday-trainer"
                : (isOnAvailableCoursesView ? dynamicCellClassNameForAvailableCoursesView(e) : undefined)
        });
    }

    return columns;
};

const organisationColumn = (basePath: string): TypedTableRowProps<ExtendedEventInstance>[] => {
    return [
        {
            header: "Organisation",
            cellClassName: "break-word",
            value: (e) => {
                return (
                    e.corporateOrganisationId
                        ? (<Link className="event-name-link" href={`${basePath}/organisations/${e.corporateOrganisationId}`}>
                            {e.corporateOrganisationName}
                        </Link>)
                        : undefined
                );
            }
        },
    ];
};

const commonColumns = (app: Apps, area: Area, isOnAvailableCoursesView: boolean, getPath: (eventInstance: EventInstance)
    => string, businessLineType: BusinessLineType):
    TypedTableRowProps<ExtendedEventInstance>[] => {

    const dynamicCellClassName = isOnAvailableCoursesView ? dynamicCellClassNameForAvailableCoursesView : undefined;

    return [
        {
            header: (app === Apps.Trainer || area === Area.AdminEventManagementTrainerCalendar || area === Area.AdminEventManagementTrainerEventInstance)
                ? "Scheme/Product"
                : isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "Product" : "Scheme",
            dynamicCellClassName,
            value: (e) => e.eventTypeAbbreviation,
            showTotal: true,
            getTotalValue: (events: ExtendedEventInstance[]) => events.length,
        },
        {
            header: "Date",
            dynamicCellClassName,
            value: GetEventInstanceDateDisplay,
            headerClassName: area === Area.AdminEventManagementEventInstance ? "event-instance-table-200-px-cell" : "",
        },
    ];
};

const adminTrainerScheduleColumns = (trainerId: string): TypedTableRowProps<ExtendedEventInstance>[] => {
    return [
        {
            header: "Role",
            value: (e) => {
                return <TrainerRoleColumn eventInstance={e} trainerId={trainerId} />;
            }
        },
    ];
};

const adminColumns = (getPath: (eventInstance: EventInstance) => string, area: Area, businessLineType: BusinessLineType):
    TypedTableRowProps<ExtendedEventInstance>[] => [
    {
        header: area === Area.AdminEventManagementSpreadingOverview ? "AS Status" : "Status",
        value: (e) => {
            if (area === Area.AdminEventManagementSpreadingOverview) {
                return (
                    <span className={e.autoSpreadStatus === AutoSpreadStatusEnum.InProgress ? "bold-brand-green-dark" : ""}>
                        {AutoSpreadStatus[e.autoSpreadStatus]}
                    </span>
                );
            }

            const isPublished = !!e.publishDate;
            const isVisible = !e.reasonForHidingEvent;
            return e.cancelled ? "Cancelled" : isPublished && isVisible ? "Published" : !isPublished ? "Not Published" : "Hidden";
        }
    },

    {
        header: "Trainers",
        value: (e) => (<>
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Theory.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory]))
                && e.theoryTrainersNames &&
                <Popup
                    content={e.theoryTrainersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                        {e.numberOfTheoryTrainers} </Link>}
                    position='left center'
                />}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Theory.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory]))
                && !e.theoryTrainersNames &&
                <Link
                    className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    {e.numberOfTheoryTrainers} </Link>}
            {(e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both]) && "/"}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Practical.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Practical]))
                && e.practicalTrainersNames &&
                <Popup
                    content={e.practicalTrainersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                        {e.numberOfPracticalTrainers} </Link>}
                    position='left center'
                />}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Practical.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Practical]))
                && !e.practicalTrainersNames &&
                <Link
                    className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    {e.numberOfPracticalTrainers} </Link>}
            {e.monitorsNames && <Popup
                content={e.monitorsNames}
                trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    <MonitorIcon /> </Link>}
                position='left center'
            />}
            {e.observersNames && <Popup
                content={e.observersNames}
                trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    <ObserverIcon /></Link>}
                position='left center'
            />}
        </>),
        showTotal: true,
        getTotalValue: (events: ExtendedEventInstance[]) =>
            sumProperty("numberOfTheoryTrainers", events) + sumProperty("numberOfPracticalTrainers", events),
        cellClassName: "event-instance-table-110-px-cell"
    },
    {
        header: "Booked",
        value: (e) => (
            <Link
                href={businessLineType === BusinessLineType.PoliceAndCourt
                    ? `${e.path}/${getPath(e)}/attendees`
                    : `${e.path}/${getPath(e)}/delegates`}
                target={area === Area.AdminEventManagementSpreadingOverview ? "_blank" : ""}
            >
                {Math.max(e.placesBookedOnOpenBookingCount ?? 0, e.seatCount ?? 0)}/{e.openPlacesCount}
            </Link>
        ),
        showTotal: true,
        getTotalValue: (events: ExtendedEventInstance[]) =>
            `${sumPropertyTernary("placesBookedOnOpenBookingCount", "seatCount", events)}/${sumProperty("openPlacesCount", events)}`
    },
];

const trainerColumnsInAdmin: TypedTableRowProps<ExtendedEventInstance>[] = [
    {
        header: "Course Status",
        value: (e) => {
            const isPublished = !!e.publishDate;
            const isVisible = !e.reasonForHidingEvent;
            return e.cancelled ? "Cancelled" : isPublished && isVisible ? "Published" : !isPublished ? "Not Published" : "Hidden";
        }
    }
];

const trainerColumns: TypedTableRowProps<ExtendedEventInstance>[] = [
    {
        header: "Role",
        value: (e) => <TrainerRoleColumn eventInstance={e} trainerId={e?.selectedTrainerId} />
    },
    {
        header: "Trainers",
        value: (e) => (<>
            <Popup
                content={`${(e.theoryTrainersNames ?? "") +
                    (e.theoryTrainersNames && e.practicalTrainersNames && (e.moduleType.toString() === ModuleTypeEnum.Both.toString()
                        || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                        ? " / "
                        : "")
                    + (e.practicalTrainersNames ?? "")}`}
                trigger={<span className="monitor-link">
                    {(e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                        && `${e.numberOfTheoryTrainers}/${e.numberOfPracticalTrainers}`}
                    {(e.moduleType.toString() === ModuleTypeEnum.Theory.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory])
                        && e.numberOfTheoryTrainers}
                    {(e.moduleType.toString() === ModuleTypeEnum.Practical.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory])
                        && e.numberOfPracticalTrainers}
                </span>}
                disabled={!e.theoryTrainersNames && !e.practicalTrainersNames}
                position='left center'
            />
            <Popup
                content={e.monitorsNames}
                trigger={<span className="monitor-link">
                    {e.hasMonitor && <MonitorIcon />}
                </span>}
                disabled={!e.monitorsNames}
                position='left center'
            />
            <Popup
                content={e.observersNames}
                trigger={<span className="monitor-link">
                    {e.hasObserver && <ObserverIcon />}
                </span>}
                disabled={!e.observersNames}
                position='left center'
            />

        </>),
        cellClassName: "event-instance-table-110-px-cell"
    },
    { header: "Booked", value: (e) => `${e.seatCount}/${e.openPlacesCount}` },
    {
        header: "Status", value: (e) => {
            const isAcceptedText = e.isAcceptedByTrainer ? "Confirmed" : "Confirm";

            return (
                <>
                    <Checkbox
                        className={e.isAcceptedByTrainer ? "no-checkbox" : ""}
                        checked={e.isSelected}
                        onChange={e.onChange}
                        disabled={e.isAcceptedByTrainer}
                        label={isAcceptedText}
                    />
                </>
            );
        }
    }
];

const getAvailableCoursesColumns = (trainerId: string, selectedEventInstanceModels: EventInstanceListModel[],
    classroomGroups: EventInstanceListModel[][], digitalGroups: EventInstanceListModel[][]) => {

    const inSameGroup = (e1: EventInstanceListModel, e2: EventInstanceListModel) => {
        const e1Group = e1.eventInstanceDeliveryType === DeliveryTypeEnum.Digital ?
            digitalGroups.find(g => g[0].id === e1.id) :
            classroomGroups.find(g => g[0].id === e1.id);
        return e1Group?.some(ei => ei.id === e2.id);
    };

    const getCheckboxDisabledWithMessage = (e: EventInstanceListModel): [boolean, string] => {
        if (e?.role === TrainerAvailabilityRoleTypeEnum.Manual &&
            selectedEventInstanceModels.some(ei => ei.id === e.id && ei.role === TrainerAvailabilityRoleTypeEnum.Automatic) ||
            e?.role === TrainerAvailabilityRoleTypeEnum.Automatic &&
            selectedEventInstanceModels.some(ei => ei.id === e.id && ei.role === TrainerAvailabilityRoleTypeEnum.Manual)) {
            return [true, "You cannot register interest in both roles of a practical course"];
        }

        if (selectedEventInstanceModels?.some(sel => e?.id !== sel.id && !inSameGroup(e, sel) &&
            e.roleDeliveryDateTime.isSameOrBefore(sel.roleDeliveryDateTimeEnd) && e.roleDeliveryDateTimeEnd.isSameOrAfter(sel.roleDeliveryDateTime))) {
            return [true, "Course cannot be ticked as it runs during another course you have ticked"];
        }

        return [false, ""];
    };

    const Role = {
        [TrainerAvailabilityRoleTypeEnum.None]: "",
        [TrainerAvailabilityRoleTypeEnum.Theory]: "Theory",
        [TrainerAvailabilityRoleTypeEnum.Practical]: "Practical",
        [TrainerAvailabilityRoleTypeEnum.Manual]: "Practical - Manual",
        [TrainerAvailabilityRoleTypeEnum.Automatic]: "Practical - Automatic",
        [TrainerAvailabilityRoleTypeEnum.AnyPractical]: "Practical"
    };

    const availableCoursesColumns: TypedTableRowProps<ExtendedEventInstance>[] = [
        {
            header: "Role", dynamicCellClassName: dynamicCellClassNameForAvailableCoursesView, value: (e) => {
                return (
                    <div>{Role[e.role]}</div>
                );
            }
        },
        {
            header: "Request Courses", dynamicCellClassName: dynamicCellClassNameForAvailableCoursesView, value: (e) => {
                if (e.isGroupedWithPrevious) {
                    return null;
                }

                const [checkboxDisabled, disabledMessage] = getCheckboxDisabledWithMessage(e);

                return (
                    <Authorize authorize={TrainerRole}>
                        <Popup
                            trigger={
                                <Checkbox
                                    onChange={e.onChange}
                                    checked={selectedEventInstanceModels.some(ei => ei.id === e.id && ei.role === e.role)}
                                    disabled={checkboxDisabled}
                                />
                            }
                            content={disabledMessage}
                            disabled={!checkboxDisabled}
                        />
                    </Authorize>
                );
            }
        }
    ];
    return availableCoursesColumns;
};
