import React from "react";
import { Trainers } from "./details/Trainers";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../selectors";
import { MultiDayTrainers } from "./multiDay/MultiDayTrainers";
export const EventInstanceTrainerViewFactory =() => {

    const eventInstance = useSelector(eventInstanceSelector);

    if (eventInstance?.groupId) {
        return <MultiDayTrainers />;
    }

    return <Trainers />;
};
