import * as React from "react";
import { AttendeeListModel } from "@common/crud/attendee";
import { EventInstance } from "../../model";
import { EventInstanceOptions } from "./helpers";

export interface RegisterContextOptions {
    path?: string;
    eventInstance?: EventInstance;
    eventInstanceOptions?: EventInstanceOptions;
    attendees?: AttendeeListModel[];
    nonCancelledAttendees?: AttendeeListModel[];
    attendeesFrom?: moment.Moment;
}

export const RegisterContext = React.createContext<RegisterContextOptions>({});
export const useRegisterContext = () => React.useContext(RegisterContext);
