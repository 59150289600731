import * as React from "react";
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { validators } from "not-valid";
import { ValidationResultType } from "not-valid/bin/results";
import { MissingBookingProps } from "../models";
import { BookingDetailsField } from "./BookingDetailsField";

export const BookingDetailsSpecialCarTypeDetailsField: React.FunctionComponent = () => {
    const [t] = useTranslation("BookingDetails");
    const { errors, touched } = useFormikContext<MissingBookingProps>();
    const isError = errors.specialCarDetails && touched.specialCarDetails;

    const validate = React.useCallback((value: string) => {
        if (!value) {
            return t("SPECIAL_CAR_DETAILS_REQUIRED");
        }

        if (validators.validLength({ max: 150 })(value).type !== ValidationResultType.Pass) {
            return t("SPECIAL_CAR_DETAILS_MAX_LENGTH");
        }

        return null;
    }, [t]);

    return (
        <BookingDetailsField>
            <Form error={isError}>
                <Field
                    name="specialCarDetails"
                    type="text"
                    as={Form.TextArea}
                    label={<label>{t("SPECIAL_CAR_DETAILS")}</label>}
                    required
                    className="field-with-note"
                    placeholder={t("SPECIAL_CAR_DETAILS")}
                    error={isError}
                    validate={validate}
                />
                <p className="field-note">{t("SPECIAL_CAR_DETAILS_NOTE")}</p>
                <ErrorMessage
                    name="specialCarDetails"
                    className="error-margin-bottom"
                >
                    {(msg) => <Message error header={msg} />}
                </ErrorMessage>
            </Form>
        </BookingDetailsField>
    );
};
