import { EventInstance } from "@common/crud/eventInstance";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { Attendee } from "../..";
import { updateFreeRebooking } from "../../actions";

export interface FreeRebookingModalProps {
    open: boolean;
    eventInstance: EventInstance;
    attendee: Attendee;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FreeRebookingModal = ({ open, eventInstance, attendee, setOpen }: FreeRebookingModalProps) => {
    const dispatch = useDispatch();

    const messageForRemovingFreeRebooking = "This means if the attendee rebooks, a rebook fee will be required " +
        "unless the attendee has 'flexi-booking' or is rebooking within 14 days of their original booking.";
    const messageForAddingFreeRebooking = "This means the attendee can rebook free of charge one time. This " +
        "free of charge rebooking will be used instead of any flexi-booking. However, if the attendee is rebooking " +
        "within 14 days of their original booking, their free of charge rebooking will not be used.";

    function onCancelClick() {
        setOpen(false);
    }

    function onConfirmClick() {
        dispatch(updateFreeRebooking(eventInstance.id, attendee.id, !attendee.freeRebookingAddedByCa));
        setOpen(false);
    }

    return (
        <Modal open={open}>
            <Modal.Header>Are you sure?</Modal.Header>
            <Modal.Content>
                {attendee.freeRebookingAddedByCa ? messageForRemovingFreeRebooking : messageForAddingFreeRebooking}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancelClick} className="cancel-action">Cancel</Button>
                <Button onClick={onConfirmClick} primary>Confirm</Button>
            </Modal.Actions>
        </Modal>
    );
};
