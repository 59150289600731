import * as React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";
import { toast } from "@common/toasts";
import { translateField } from "@common/global/CommonHelpers";

import { CorporateCreateForm, CreateProps, DispatchProps } from "./CorporateCreate";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

export class CorporateCreateModal extends React.Component<CreateProps & DispatchProps, {}> {

    private createForm: CorporateCreateForm;

    private translations: Record<string, string> = {
        "name": "organisation name",
        "corporateOrganisationData.emailAddress": "organisation email address",
        "expiryDate": "expiry date",
        "organisationContact.name": "primary contact name",
        "organisationContact.email": "primary contact email",
        "organisationContact.telephone": "primary contact phone",
        "organisationCourseContact.name": "course contact name",
        "organisationCourseContact.email": "course contact email",
        "organisationCourseContact.telephone": "course contact phone",
        "organisationFinanceContact.name": "finance contact name",
        "organisationFinanceContact.email": "finance contact email",
        "organisationFinanceContact.telephone": "finance contact phone",
    };

    public render() {
        return (
            <Modal size="small" open={this.props.open} onClose={this.props.close}>
                <Modal.Header>Create Commercial Organisation</Modal.Header>
                <Modal.Content>
                    <CorporateCreateForm
                        open={this.props.open}
                        quickAddId={this.props.quickAddId}
                        businessDevelopmentManagers={this.props.businessDevelopmentManagers}
                        ttcCorporateAdmin={this.props.ttcCorporateAdmin}
                        businessDriverAdmin={this.props.businessDriverAdmin}
                        loadBusinessDevelopmentManagers={this.props.loadBusinessDevelopmentManagers}
                        save={this.props.save}
                        ref={instance => this.createForm = instance}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close}>Cancel</Button>
                    <Button onClick={this.submit}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private submit = () => {
        ObjectKeys(this.createForm.state.valid).forEach(key => {
            if (!this.createForm.state.valid[key])
            {
                const fieldTranslation = translateField(key, this.translations);
                if (fieldTranslation === undefined)
                {
                    toast.error("Please confirm all organisation details are valid");
                }
                else {
                    toast.error("Please confirm details are valid for " + fieldTranslation);
                }
            }
        });

        this.createForm.submit();
    };
}
