import * as React from "react";

import { PdfProcessingSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const PdfProcessingSetting: ConfigurationAreaComponent<PdfProcessingSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="PDFProcessing"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "LicenseKey", label: "License Key" },
            ]}
        />
    );
};
