import * as React from "react";
import { EventInstanceGroupItemModel } from "@common/crud/eventInstanceGroup/model";
import { GridDetail } from "@common/global/GridDetail";
import { RowDetail } from "@common/global/RowDetail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Media } from "@common/global/AppMedia";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";
import { TimingDetails } from "./TimingDetails";

interface MultiDayTimingDetailsProps{
    eiGroupItems: EventInstanceGroupItemModel[];
}

export const MultiDayTimingDetails: React.FC<MultiDayTimingDetailsProps> = ({ eiGroupItems }) => {

    const fieldIsValidAndNonZero = (field: moment.Duration) => field && field.asMilliseconds?.() > 0 ;
    const startDate = (gi: EventInstanceGroupItemModel) => gi.startDate && gi.startDate.format("LL");
    const startTime = (gi: EventInstanceGroupItemModel) => gi.startTime.format(DateFormat.Time, { trim: false });
    const eventDuration = (gi: EventInstanceGroupItemModel) => fieldIsValidAndNonZero(gi.eventDuration)
        ? gi.eventDuration.format(DateFormat.Time, { trim: false })
        : "Not Set";
    const registrationEndTime = (gi: EventInstanceGroupItemModel) => fieldIsValidAndNonZero(gi.registrationEndTime)
        ? gi.registrationEndTime.format?.(DateFormat.Time, { trim: false })
        : "Not Set";

    const headersArray =  ["Date", "Time", "Duration", "Registration End Time"];
    const columnWidths = [4,3,3,3] as SemanticWIDTHS[];
    const rowChildren = eiGroupItems?.map((gi) =>
        (<RowDetail key={gi.dayNumber} valueWidths={columnWidths}
            rowLabel={ `Day ${gi.dayNumber}`} values={[startDate(gi), startTime(gi), eventDuration(gi), registrationEndTime(gi)]} />));

    const mobileChildrenTimingDetails = eiGroupItems?.map((gi) => (
        <div key={gi.dayNumber}>
            <Grid.Row width={5} as="h4">
              Day {gi.dayNumber}
            </Grid.Row>
            <TimingDetails startDate={startDate(gi)} eventDuration={startTime(gi)}
                startTime={eventDuration(gi)} registrationEndTime={registrationEndTime(gi)} />
        </div>
    ));

    return (<>
        <Media greaterThanOrEqual="tablet">
            <GridDetail headerLabels={headersArray} labelWidths={columnWidths}>
                {rowChildren}
            </GridDetail>
        </Media>
        <Media lessThan="tablet">
            {mobileChildrenTimingDetails}
        </Media>
    </>);

};

