import axios from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import { DelegateDocument, DelegateDocumentResponse, SearchOptions } from "./model";

export class DelegateDocumentApi {
    private readonly apiUrl = "/api/delegateDocument";
    private readonly defaultOptions: SearchOptions = { showFuture: true, showExpired: false };

    public async getDelegateDocuments(options: SearchOptions = { showFuture: true, showExpired: false }): Promise<DelegateDocument[]> {
        const mergedOptions = { ...this.defaultOptions, ...options };
        const query = QueryString.stringify(mergedOptions);
        const response = await axios.get<DelegateDocument[]>(`${this.apiUrl}?${query}`);
        const parsedResponse = this.parseDocuments(response.data);
        return parsedResponse;
    }

    public async createDelegateDocument(
        document: DelegateDocument,
        options: SearchOptions = { showFuture: true, showExpired: false }): Promise<DelegateDocumentResponse> {
        const formData = this.getDelegateDocumentFormData(document);
        const mergedOptions = { ...this.defaultOptions, ...options };
        const query = QueryString.stringify(mergedOptions);

        const response = await axios.post<DelegateDocumentResponse>(`${this.apiUrl}?${query}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        const parsedResponse = this.parseDocumentsResponse(response.data);
        return parsedResponse;
    }

    public async editDelegateDocument(
        document: DelegateDocument,
        options: SearchOptions = { showFuture: true, showExpired: false }): Promise<DelegateDocumentResponse> {
        const formData = this.getDelegateDocumentFormData(document);
        const mergedOptions = { ...this.defaultOptions, ...options };
        const query = QueryString.stringify(mergedOptions);

        const response = await axios.put<DelegateDocumentResponse>(`${this.apiUrl}?${query}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        const parsedResponse = this.parseDocumentsResponse(response.data);
        return parsedResponse;
    }

    public async reorderDelegateDocuments(
        documents: DelegateDocument[],
        options: SearchOptions = { showFuture: true, showExpired: false }
    ): Promise<DelegateDocumentResponse> {
        const payload: { [id: string]: number } = {};
        documents.forEach((doc) => {
            payload[doc.id] = doc.priority;
        });
        const query = QueryString.stringify(options);

        const response = await axios.put<DelegateDocumentResponse>(`${this.apiUrl}/reorder?${query}`, payload, {
            headers: { "Content-Type": "application/json" }
        });
        const parsedResponse = this.parseDocumentsResponse(response.data);
        return parsedResponse;
    }

    private parseDocumentsResponse(model: DelegateDocumentResponse): DelegateDocumentResponse {
        return {
            documents: this.parseDocuments(model.documents),
            error: model.error
        };
    }

    private parseDocuments(models: DelegateDocument[]): DelegateDocument[] {
        return models.map((model: DelegateDocument) => ({
            ...model,
            startDate: moment(model.startDate),
            endDate: model.endDate && moment(model.endDate),
        }));
    }

    private getDelegateDocumentFormData(document: DelegateDocument): FormData {
        const formData = new FormData();
        formData.append("id", document.id);
        formData.append("documentName", document.documentName);
        formData.append("documentDescription", document.documentDescription);
        formData.append("uploadedDocument", document.uploadedDocument);
        formData.append("startDate", document.startDate.toISOString());
        formData.append("endDate", document.endDate?.toISOString());
        formData.append("priority", document.priority?.toString());

        const validEventTypes = document.eventTypes?.filter(eventTypeId => eventTypeId !== null);
        validEventTypes?.forEach(eventTypeId => {
            formData.append("eventTypes", eventTypeId);
        });

        const validTrainers = document.trainers?.filter(trainerId => trainerId !== null);
        validTrainers?.forEach(trainerId => {
            formData.append("trainers", trainerId);
        });

        return formData;
    }
}
