import * as React from "react";
import { Tab } from "semantic-ui-react";
import {
    BookingTypeEnum,
    CourseFeesEventTypeDetails,
    WorkflowTypeEnum
} from "../../model";
import { getCurrencyFormat } from "@common/formating";
import { EditFieldWithEffectiveDate } from "../EditFieldWithEffectiveDate";
import { CurrencyPicker } from "../EventTypeFieldPickerHelpers";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { Input } from "@neworbit/simpleui-input";
import { doesPriceIncludeVatForWorkflow, isWorkflowConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";
import { CorporateFeeTables } from "../CorporateFeeTables";

export interface OwnProps {
    courseFeesEventTypeDetails: CourseFeesEventTypeDetails;
    workflowType: WorkflowTypeEnum;
    deliveryType: SchemeDeliveryTypeEnum;
    bookingType: BookingTypeEnum;
    showErrors?: boolean;
    eventTypeName: string;
    onChange(propName: string, termsAndConditionsProperty?: boolean): (value: any, valid: boolean) => void;
}

export const CourseFees: React.FC<OwnProps> = ({
    courseFeesEventTypeDetails,
    workflowType,
    deliveryType,
    bookingType,
    showErrors,
    eventTypeName,
    onChange }) => {

    const { digitalEIFeeWithEffectiveDate, cpcUploadFeeRequired, cpcUploadFee } = courseFeesEventTypeDetails;

    const isDors = workflowType === WorkflowTypeEnum.Dors;
    const isCorporate = isWorkflowCorporate(workflowType);
    const isConstruction = isWorkflowConstruction(workflowType);
    const priceIncludesVat = doesPriceIncludeVatForWorkflow(workflowType);

    const isDigital = deliveryType === SchemeDeliveryTypeEnum.Digital || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital;

    const isClosed = bookingType === BookingTypeEnum.Closed || bookingType === BookingTypeEnum.Both;
    const digitalEiFeeText = (isCorporate || isConstruction) ? "Open course delegate fee (Digital)" : "Digital EI Fee";

    function updateCourseFees(feeField: keyof CourseFeesEventTypeDetails) {
        return onChange(`courseFeesEventTypeDetails.${feeField}`);
    }

    return (
        <Tab.Pane key="course-fees">
            <h3>Course Fees</h3>

            {isDigital && isDors && (
                <EditFieldWithEffectiveDate
                    allFields={digitalEIFeeWithEffectiveDate}
                    onChange={updateCourseFees("digitalEIFeeWithEffectiveDate")}
                    CreateFieldPicker={CurrencyPicker(digitalEiFeeText)}
                    showErrors={showErrors}
                    fieldName={"Digital EI Fee"}
                    valueView={getCurrencyFormat}
                />
            )}

            {(isCorporate || isConstruction) &&
                <CorporateFeeTables
                    showEditButtons
                    eventTypeName={eventTypeName}
                    bookingType={bookingType}
                    deliveryType={deliveryType}
                    courseFeesEventTypeDetails={courseFeesEventTypeDetails}
                    onChange={onChange}
                />
            }

            {((isCorporate || isConstruction) && isClosed) && (
                <div className="margin-top">
                    <Input.Checkbox
                        label="Upload Fee required?"
                        value={cpcUploadFeeRequired}
                        showErrors={showErrors}
                        onChange={updateCourseFees("cpcUploadFeeRequired")}
                        toggle
                    />

                    {cpcUploadFeeRequired && (
                        <EditFieldWithEffectiveDate
                            allFields={cpcUploadFee}
                            onChange={updateCourseFees("cpcUploadFee")}
                            CreateFieldPicker={CurrencyPicker("Upload Fee")}
                            showErrors={showErrors}
                            fieldName="Upload Fee"
                            valueView={getCurrencyFormat}
                        />
                    )}
                </div>
            )}

            <div className="right-aligned-text bold">{priceIncludesVat ? "Prices include VAT" : "Prices exclude VAT"}</div>
        </Tab.Pane>
    );
};
