/* eslint-disable no-nested-ternary */
import * as React from "react";
import { Segment } from "semantic-ui-react";
import { Basket, CorporateEventInstanceResultRow } from "../model";
import { orderBy } from "lodash";
import { numberFormatter } from "@common/helpers/number-format";
import { OrderApi } from "../orderApi";
import "./OrderChosenCourse.scss";
import moment from "moment";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { useSelector } from "react-redux";

interface OrderChosenCourseProps {
    basket: Basket;
}

interface RebookedItem {
    rebookedToEventInstanceId: string;
    rebookedEventTypeName: string;
    rebookedEventInstanceDeliveryDateTime: moment.Moment;
    rebookedEventInstanceDeliveryDateTimeEnd: moment.Moment;
    price: number;
}

interface GroupedRebookedItems {
    items: RebookedItem[];
    count: number;
    price: number;
}

export const OrderChosenCourse: React.FC<OrderChosenCourseProps> = (props: OrderChosenCourseProps) => {
    const {
        basket,
    } = props;

    const [coursesFromIds, setCoursesFromIds] = React.useState<CorporateEventInstanceResultRow[]>([]);

    const businessLineType = useSelector(businessLineTypeSelector);

    React.useEffect(() => {
        const courseIds = (basket && basket.items) ? (Object.values(basket.items).map(x => x.eventInstanceId) || []) : [];

        // fetch data only when some course from ids is missing basket item
        if (courseIds.length === 0 || courseIds.every(id => coursesFromIds.some(course => course.id === id))) {
            return;
        }

        const fetchData = async () => {
            const orderApi = new OrderApi();
            const newCoursesFromIds =
                await orderApi.getBookedCoursesFromIds(businessLineType, Object.values(basket.items).map(x => x.eventInstanceId), basket.relatedOrganisationId);
            setCoursesFromIds(newCoursesFromIds);
        };

        fetchData();
    }, [basket, coursesFromIds, businessLineType]);

    const memoizedOrderedBasketItemsWithCourses = React.useMemo(() => {
        const basketItems = (basket && basket.items) ? Object.values(basket.items) : [];
        const basketItemsWithCourse = basketItems.map(basketItem => ({ basketItem, course: coursesFromIds.find(c => c.id === basketItem.eventInstanceId) }));
        return orderBy(basketItemsWithCourse, ["course.startTime"], ["asc"]);
    }, [basket, coursesFromIds]);

    const memoizedRebookedItems = React.useMemo<Record<string, GroupedRebookedItems>>(() => {
        const flattenedRebookedItems = basket?.rebookedEventInstances?.map((ei) => ei.rebookedAttendees
            .filter(a => !a.ignored)
            .map((a) => {
                const oldPriceToBeRemoved = (a.feesHalfDue && !a.feesWaived)
                    ? a.oldPricePerSeatInPence / 2
                    : a.oldPricePerSeatInPence;

                return ({
                    rebookedToEventInstanceId: a.rebookedToEventInstanceId,
                    rebookedEventTypeName: a.rebookedEventTypeName,
                    rebookedEventInstanceDeliveryDateTime: a.rebookedEventInstanceDeliveryDateTime,
                    rebookedEventInstanceDeliveryDateTimeEnd: a.rebookedEventInstanceDeliveryDateTimeEnd,
                    price: a.fromOrderWithBookNowPayLater
                        ? 0
                        : (a.isBookingCanceled || (a.feesDue && !a.feesWaived && !a.feesZeroed))
                            ? a.newPricePerSeatInPence
                            : ((a.feesDue || a.feesHalfDue) && a.feesZeroed)
                                ? 0
                                : (a.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                                    ? 0
                                    : a.newPricePerSeatInPence - oldPriceToBeRemoved
                });
            }))?.flat() ?? [];

        const groupedByEventInstanceId = flattenedRebookedItems.reduce((acc: Record<string, GroupedRebookedItems>, item) => {
            if (!acc[item.rebookedToEventInstanceId]) {
                acc[item.rebookedToEventInstanceId] = { items: [], count: 0, price: 0 };
            }
            acc[item.rebookedToEventInstanceId].items.push(item);
            acc[item.rebookedToEventInstanceId].count++;
            acc[item.rebookedToEventInstanceId].price += item.price;
            return acc;
        }, {});

        return groupedByEventInstanceId;
    }, [basket?.rebookedEventInstances]);

    return (
        <Segment className="chosen-course">
            {(basket && basket.discount > 0) && (
                <div className="cost">Discount &pound;{numberFormatter((basket?.discount || 0) / 100)}</div>
            )}
            <div className="cost">Payment amount &pound;{numberFormatter((basket?.total || 0) / 100)}</div>
            <h4 className="underline">Your chosen bookings</h4>
            {memoizedOrderedBasketItemsWithCourses.map((basketItemWithCourse, index) => (
                <div key={`basket_item_${index}`} className="course">
                    <p className="bold bigger with-proper-margin break-word">
                        {basketItemWithCourse?.course?.eventTypeName}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Delivery</span> {basketItemWithCourse?.course?.startTimes[0]?.format("ddd, DD MMM HH:mm")}{" "}
                        - {basketItemWithCourse?.course?.endTimes[0]?.format("HH:mm")}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Places</span> {basketItemWithCourse?.basketItem?.numberOfSeats}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Cost</span> &pound;{numberFormatter(basketItemWithCourse?.basketItem?.totalPriceInPence / 100)}
                    </p>
                </div>
            ))}
            {Object.values(memoizedRebookedItems).map((rebookedGroup, index) => (
                <div key={`rebooked_group_${index}`} className="course">
                    <p className="bold bigger with-proper-margin">
                        {rebookedGroup.items[0].rebookedEventTypeName}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Delivery</span>{" "}
                        {rebookedGroup.items[0].rebookedEventInstanceDeliveryDateTime.format("ddd, DD MMM HH:mm")}{" "}
                        - {rebookedGroup.items[0].rebookedEventInstanceDeliveryDateTimeEnd.format("HH:mm")}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Places</span> {rebookedGroup.count}
                    </p>
                    <p className="course-paragraph">
                        <span className="course-value">Cost</span> &pound;{numberFormatter(rebookedGroup.price / 100)}
                    </p>
                </div>
            ))}
        </Segment>
    );
};
