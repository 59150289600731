import * as React from "react";
import { TrainerFee } from "../../model";
import { TrainerPayEditModal } from "./TrainerPayEditModal";
import { TrainerPayTable } from "./TrainerPayTable";

interface EditableTrainerPayTableProps {
currentTrainerFees: TrainerFee[];
noFeesText?: string;
editButtonText?: string;
onChange: (value: any, valid: boolean) => void;
push?: (path: string) => void;
onSubmit?: (trainerFees: TrainerFee[]) => void;
}

export const EditableTrainerPayTable: React.FC<EditableTrainerPayTableProps> =
({ currentTrainerFees, onChange, push, noFeesText, editButtonText, onSubmit }) => {

    const sortedCurrentTrainerFees = React.useMemo(() => currentTrainerFees.sort((a, b) => {
        if (a.effectiveDate.isBefore(b.effectiveDate)) {
            return -1;
        }
        if (a.effectiveDate.isAfter(b.effectiveDate)) {
            return 1;
        }
        return 0;
    }), [currentTrainerFees]);

    return (
        <>
            <TrainerPayTable trainerFees={sortedCurrentTrainerFees} noFeesText={noFeesText} />
            <TrainerPayEditModal noFeesText={noFeesText} currentTrainerFees={sortedCurrentTrainerFees}
                onChange={onChange} push={push} editButtonText={editButtonText} onSubmit={onSubmit} />
        </>
    );};
