import * as React from "react";
import { Table } from "semantic-ui-react";
import { orderBy } from "lodash";
import { EventInstanceWithOrdersListModel } from "../model";
import { OrderEventInstanceRowCheckable } from "./OrderEventInstanceRowCheckable";
import { AttendeeListModel } from "@common/crud/attendee";
import { OrderApi } from "../orderApi";
import { AllAttendeesCheckable } from "./AllAttendeesCheckable";

interface AllEventInstancesProps {
    corporateOrganisationId?: string;
    corporateUserId?: string;
    orderId?: string;
    eventInstanceId?: string;
    bookingReference?: string;
    bookerName?: string;
    eventInstances: EventInstanceWithOrdersListModel[];
    selectedEventInstances: { id: string; selectedAttendees: string[]; attendeesNotExpanded?: boolean }[];
    setSelectedEventInstances: (selectedEventInstances: { id: string; selectedAttendees: string[]; attendeesNotExpanded?: boolean }[]) => void;
}

export const AllEventInstancesCheckable: React.FC<AllEventInstancesProps> = ({
    corporateOrganisationId,
    corporateUserId,
    orderId,
    eventInstanceId,
    bookingReference,
    bookerName,
    eventInstances,
    selectedEventInstances,
    setSelectedEventInstances
}) => {
    const [eventInstancesAttendees, setEventInstancesAttendees] = React.useState<{ id: string; attendees: AttendeeListModel[]}[]>([]);

    React.useEffect(() => {
        const refreshAttendees = async () => {
            if (eventInstancesAttendees.length > 0) {
                const newEventInstancesAttendees = [];
                for (const eventInstancesData of eventInstancesAttendees) {
                    const api = new OrderApi();
                    const loadedAtendees = await api.getAllAttendees({ eventInstanceId: eventInstancesData.id, corporateOrganisationId, corporateUserId,
                        bookingReference, bookerName });
                    newEventInstancesAttendees.push({ id: eventInstancesData.id, attendees: loadedAtendees });
                }
                setEventInstancesAttendees(newEventInstancesAttendees);
            }
        };

        if (!eventInstanceId) {
            refreshAttendees();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingReference, bookerName]);

    const fetchEventInstanceAttendees = React.useCallback(async (eventInstanceSelectedId: string, eventInstanceSelected: boolean) => {
        const api = new OrderApi();
        const loadedAtendees = await api.getAllAttendees({
            orderId,
            eventInstanceId: eventInstanceSelectedId,
            corporateOrganisationId,
            corporateUserId,
            bookingReference,
            bookerName
        });
        setEventInstancesAttendees([...eventInstancesAttendees, { id: eventInstanceSelectedId, attendees: loadedAtendees }]);

        if (eventInstanceSelected) {
            const eventInstance = selectedEventInstances.find(e => e.id === eventInstanceSelectedId);

            if (eventInstance.attendeesNotExpanded) {
                setSelectedEventInstances(selectedEventInstances.map(e => {
                    if (e.id === eventInstanceSelectedId) {
                        return {
                            ...e,
                            selectedAttendees: loadedAtendees.map(a => a.id),
                            attendeesNotExpanded: false
                        };
                    }
                    return e;
                }));
            }
        }
    }, [bookerName, bookingReference, corporateOrganisationId, corporateUserId, eventInstancesAttendees, selectedEventInstances, setSelectedEventInstances]);

    const toggleAttendeeSelected = React.useCallback((eventInstanceSelectedId: string, attendeeId: string) => {
        const attendeeSelected = selectedEventInstances
            .find(e => e.id === eventInstanceSelectedId)?.selectedAttendees
            .includes(attendeeId);

        if (attendeeSelected) {
            const eventInstance = selectedEventInstances.find(e => e.id === eventInstanceSelectedId);

            const shouldRemoveEventInstance = eventInstance?.selectedAttendees.length === 1;

            setSelectedEventInstances(shouldRemoveEventInstance
                ? selectedEventInstances.filter(e => e.id !== eventInstanceSelectedId)
                : selectedEventInstances.map(e => {
                    if (e.id === eventInstanceSelectedId) {
                        return {
                            ...e,
                            selectedAttendees: e.selectedAttendees.filter(a => a !== attendeeId)
                        };
                    }
                    return e;
                }));
        } else {
            const shouldAddEventInstance = !selectedEventInstances.some(e => e.id === eventInstanceSelectedId);

            setSelectedEventInstances(shouldAddEventInstance
                ? [...selectedEventInstances, { id: eventInstanceSelectedId, selectedAttendees: [attendeeId] }]
                : selectedEventInstances.map(e => {
                    if (e.id === eventInstanceSelectedId) {
                        return {
                            ...e,
                            selectedAttendees: [...e.selectedAttendees, attendeeId]
                        };
                    }
                    return e;
                }));
        }
    }, [selectedEventInstances, setSelectedEventInstances]);

    const toggleEventInstanceSelected = React.useCallback((eventInstanceSelectedId: string, eventInstanceExtended: boolean, attendees: string[]) => {
        const eventInstanceSelected = selectedEventInstances
            .find(e => e.id === eventInstanceSelectedId && (e.attendeesNotExpanded
                || (e.selectedAttendees.slice().sort().join() === attendees.slice().sort().join())));
        const eventInstancePartiallySelected = selectedEventInstances
            .find(e => e.id === eventInstanceSelectedId && !e.attendeesNotExpanded
                && e.selectedAttendees.slice().sort().join() !== attendees.slice().sort().join());

        if (eventInstanceSelected) {
            setSelectedEventInstances(selectedEventInstances.filter(e => e.id !== eventInstanceSelectedId));
        } else if (eventInstancePartiallySelected) {
            setSelectedEventInstances(selectedEventInstances.map(e => {
                if (e.id === eventInstanceSelectedId) {
                    return {
                        ...e,
                        selectedAttendees: attendees,
                        attendeesNotExpanded: false
                    };
                }
                return e;
            }));
        } else {
            setSelectedEventInstances([...selectedEventInstances, {
                id: eventInstanceSelectedId,
                selectedAttendees: eventInstanceExtended ? attendees: [],
                attendeesNotExpanded: !eventInstanceExtended
            }]);
        }
    }, [selectedEventInstances, setSelectedEventInstances]);

    const singleEventInstance = React.useMemo(() => eventInstanceId
        ? eventInstances?.find(e => e.id === eventInstanceId)
        : undefined, [eventInstanceId, eventInstances]);

    return eventInstanceId ? (
        <AllAttendeesCheckable
            eventInstance={singleEventInstance}
            selectedEventInstances={selectedEventInstances || []}
            attendees={eventInstancesAttendees.find(eia => eia.id === eventInstanceId)?.attendees || []}
            toggleAttendeeSelected={toggleAttendeeSelected}
            toggleEventInstanceSelected={toggleEventInstanceSelected}
            fetchEventInstanceAttendees={fetchEventInstanceAttendees}
        />
    ) : (
        <Table>
            <Table.Header className="grey">
                <Table.Row>
                    <Table.HeaderCell>Venue</Table.HeaderCell>
                    <Table.HeaderCell>Product</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Attendees</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orderBy(eventInstances, ei => ei.eventInstanceDeliveryDateTime).map((eventInstance, i) => (
                    <OrderEventInstanceRowCheckable
                        key={`orderEventInstance_${i}`}
                        eventInstance={eventInstance}
                        index={i}
                        selectedEventInstances={selectedEventInstances || []}
                        attendees={eventInstancesAttendees.find(eia => eia.id === eventInstance.id)?.attendees || []}
                        toggleAttendeeSelected={toggleAttendeeSelected}
                        toggleEventInstanceSelected={toggleEventInstanceSelected}
                        fetchEventInstanceAttendees={fetchEventInstanceAttendees}
                    />
                ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell key={0}>Total Event Instances: {eventInstances.length}</Table.HeaderCell>
                    <Table.HeaderCell key={1} />
                    {!corporateOrganisationId && (
                        <Table.HeaderCell key={2} />
                    )}
                    {!corporateUserId && (
                        <Table.HeaderCell key={3} />
                    )}
                    <Table.HeaderCell key={4}></Table.HeaderCell>
                    <Table.HeaderCell key={5}></Table.HeaderCell>
                    <Table.HeaderCell key={6}></Table.HeaderCell>
                    <Table.HeaderCell key={7}></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};
