import * as React from "react";
import { Formik, Field, Form } from "formik";
import { Button, Grid, GridColumn, GridRow, Label } from "semantic-ui-react";
import axios from "axios";
import { toast } from "@common/toasts";
import { useCallback, useRef, useState } from "react";
import { ReCAPTCHA } from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { ApplicationState } from "@booking/applicationState";
import { debounce } from "lodash";
import { Spinner } from "@common/global";

export const DdrsLogin: React.FunctionComponent = () => {
    const recaptchaSiteKey = useSelector((state: ApplicationState) => state.appSettings.recaptchaSettings?.recaptchaSiteKey);

    const [recaptcha, setRecaptcha] = useState<string>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const useReCaptcha = useSelector((state: ApplicationState) => !!state.appSettings.featureSettings.useReCaptcha);
    const onRecaptcha = useCallback((value: any) => {
        setRecaptcha(value);
    }, []);

    const onExpired = useCallback(() => {
        setRecaptcha(undefined);
    }, []);

    const captchaRef = useRef(null);

    const submitDisabled = (useReCaptcha && recaptchaSiteKey && !recaptcha) || isSubmitting;

    const submit = async (values: any) => {
        setIsSubmitting(true);
        const apiUrl = "/api/ddrsauth/transferUrl";
        const query = useReCaptcha ?
            `?caseNumber=${values.caseNumber}&postCode=${values.postCode}&recaptchaToken=${recaptcha}` :
            `?caseNumber=${values.caseNumber}&postCode=${values.postCode}&recaptchaToken=`;

        try {
            const result = await axios.get(apiUrl + query);
            if (result.status === 200) {
                window.location.href = result.data.TransferRelativeUrl;
            } else {
                toast.error("Details not verified");
            }
        } catch (reason) {
            if (reason?.response?.status === 500) {
                toast.error("An error has occured or you have made too many failed attempts, please try again later");
            }
            else {
                toast.error("Details not verified");
            }
        }
        setIsSubmitting(false);
        if (useReCaptcha) {
            setRecaptcha(undefined);
            captchaRef.current.reset();
        }
    };

    return (
        <div>
            <Formik
                initialValues={{ caseNumber: "", postCode: "" }}
                onSubmit={debounce(async (values) => submit(values), 1000)}
            >
                <Form>
                    <Spinner active={isSubmitting} >
                        <Grid>
                            <GridRow>
                                <GridColumn width={4} >
                                    <Label basic >Case Number</Label>
                                </GridColumn>
                                <GridColumn width={4} >
                                    <Field name="caseNumber" type="text" />
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={4} >
                                    <Label basic >Post Code</Label>
                                </GridColumn>
                                <GridColumn width={4} >
                                    <Field name="postCode" type="text" />
                                </GridColumn>
                            </GridRow>
                            {useReCaptcha && <GridRow>
                                <GridColumn width={4} />
                                <GridColumn width={4}>
                                    {recaptchaSiteKey && <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onRecaptcha} onExpired={onExpired} ref={captchaRef} />}
                                </GridColumn>
                            </GridRow>}
                            <GridRow>
                                <GridColumn width={4} >
                                </GridColumn>
                                <GridColumn width={4} >
                                    <Button type="submit" disabled={submitDisabled} >Submit</Button>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Spinner>
                </Form>

            </Formik>
        </div>
    );
};
