import { createValidator } from "not-valid";

export const uinValidator = (alreadyUsedUins?: string[]) => {
    return createValidator<string>((v: string) => {
        const value = v || "";

        if (value && alreadyUsedUins.filter(n => n === value).length > 1) {
            return false;
        }

        if (value.length > 12 || (v && !v.match(/^[a-zA-Z0-9]+$/))) {
            return false;
        }

        return true;
    },
    "Please enter a valid unique UIN");
};
