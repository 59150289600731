import * as React from "react";
import { Modal } from "@common/components";
import { useDispatch } from "react-redux";
import { Input } from "@neworbit/simpleui-input";
import { Button, Form } from "semantic-ui-react";
import { phoneNumber } from "@common/validation";
import { updateAttendeeContactDetails } from "../../actions";
import { AttendeeDetailModel } from "../../model";
import { DorsBookingDetailModel } from "@common/crud/dorsBooking/model";
import { updateDorsBookingContactDetails } from "@common/crud/dorsBooking/actions";
import { validators } from "not-valid";

interface ContactDetailsModalProps {
    attendee?: AttendeeDetailModel;
    dorsBooking?: DorsBookingDetailModel;
}

const ContactDetailsModal: React.FC<ContactDetailsModalProps> = ({ attendee, dorsBooking }) => {
    const dataSource = attendee ? attendee : dorsBooking;

    const [open, setOpen] = React.useState<boolean>(false);
    const [telephone, setTelephone] = React.useState<string>(dataSource?.telephone);
    const [email, setEmail] = React.useState<string>(dataSource?.email);
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);
    const [isTelephoneValid, setIsTelephoneValid] = React.useState<boolean>(true);
    const [showError, setShowError] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTelephone(dataSource?.telephone);
        setEmail(dataSource?.email);
    }, [dataSource]);

    function openModal() {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    function onEmailChange(value: string, valid: boolean) {
        setEmail(value);
        setIsEmailValid(valid);
    }

    function onTelephoneChange(value: string, valid: boolean) {
        setTelephone(value);
        setIsTelephoneValid(valid);
    }

    function onAccept() {
        if (isTelephoneValid && isEmailValid) {
            const model = { telephone, email };
            if (attendee) {
                dispatch(updateAttendeeContactDetails(
                    attendee.id, attendee.eventInstanceId, attendee.correlationId, model
                ));
            } else {
                dispatch(updateDorsBookingContactDetails(dorsBooking.id, model));
            }

            setShowError(false);
            setOpen(false);
        } else {
            setShowError(true);
        }
    }

    return (
        <>
            <Button className="link-button" onClick={openModal}>Update contact details</Button>

            <Modal open={open}>
                <Modal.Header>
                    Update contact details
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Input.Email
                            label="Email"
                            value={email}
                            validation={[validators.validLength({ max: 50 })]}
                            showErrors={showError}
                            onChange={onEmailChange}
                        />
                        <Input.Text
                            label="Telephone"
                            value={telephone}
                            validation={phoneNumber()}
                            showErrors={showError}
                            onChange={onTelephoneChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onAccept}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Yes"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { ContactDetailsModal };
