import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { loadOrganisations } from "@common/crud/organisation";

const Routes = {
    ReviewPayment: `/${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`,
    ManualPayment: `/${PaymentPageNames[PaymentPageNamesEnum.ManualPayment]}`,
    BookingDetails: "/booking-details",
    GenesysAuthorisation: `/${PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]}`,
    Edit: "/edit",
    FlexiPayPayment: `/${PaymentPageNames[PaymentPageNamesEnum.FlexiPayPayment]}`,
    History: "/history",
};

export const detailRoutes = {
    [Routes.ReviewPayment]: {
        title: "Review Payment"
    },
    [Routes.ManualPayment]: {
        title: "Manual Payment"
    },
    [Routes.BookingDetails]: {
        title: "Booking Details"
    },
    [Routes.GenesysAuthorisation]: {
        title: "Genesys Authorisation"
    },
    [Routes.Edit]: {
        title: "Attendee Edit",
        resolve: [() => loadOrganisations()],
    },
    [Routes.FlexiPayPayment]: {
        title: "Flexi-pay Payment"
    },
};

export const corporateDetailRoutes = {
    [Routes.History]: {
        title: "Delegate Journey"
    },
};

