import { Field, Form, Formik } from "formik";
import * as React from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { AlaskaAndDorsData, DataToUseFromDorsForAttendeeModel, UpdateAttendeeFromDorsModel } from "../../model";

interface AttendeeDifferenceModalComponentProps {
    model: UpdateAttendeeFromDorsModel;
    handleSubmit: (submitModel: DataToUseFromDorsForAttendeeModel) => void;
    handleCancel: () => void;
    open: boolean;
}

export const AttendeeDifferenceModalComponent: React.FC<AttendeeDifferenceModalComponentProps> = ({ model, open, handleSubmit, handleCancel }) => {

    const generateGridRow = (name: string, data: AlaskaAndDorsData, fieldName?: string ) => (
        <Grid.Row>
            <Grid.Column width={3}>
                {name}
            </Grid.Column>
            <Grid.Column width={6}>
                {data.alaskaValue ?? ""}
            </Grid.Column>
            <Grid.Column width={6}>
                {data.dorsValue ?? ""}
            </Grid.Column>
            <Grid.Column width={1}>
                <Field type="checkbox" name={fieldName ?? name.toLowerCase()} />
            </Grid.Column>
        </Grid.Row>
    );

    if (!model) {
        return <></>;
    }

    return (
        <Formik<DataToUseFromDorsForAttendeeModel>
            initialValues={{
                address: false,
                driverNumber: false,
                email: false,
                forename: false,
                surname: false,
                telephone: false,
                ticketId: false
            }}
            onSubmit={handleSubmit}
        >
            <Modal open={open}>
                <Modal.Header>
                    Attendee Differences
                </Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <p>Based on this new attendance ID, we detect that there are differences between Alaska and DORS for the below fields.</p>
                            <p>Please tick the fields that you would like to be updated on Alaska using the information from DORS.</p>
                        </Grid.Row>
                    </Grid>
                    <Form id="attendee-differences-form">
                        <Grid>
                            <Grid.Row columns={16}>
                                <Grid.Column width={3}><strong>Field</strong></Grid.Column>
                                <Grid.Column width={6}><strong>Alaska</strong></Grid.Column>
                                <Grid.Column width={6}>
                                    <div>
                                        <strong>DORS</strong>
                                        <div>(ticking will add this data to Alaska)</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={1} />
                            </Grid.Row>
                            { model.address && generateGridRow("Address", model.address) }
                            { model.driverNumber && generateGridRow("Driver Number", model.driverNumber, "driverNumber") }
                            { model.email && generateGridRow("Email", model.email) }
                            { model.forename && generateGridRow("Forename", model.forename) }
                            { model.surname && generateGridRow("Surname", model.surname) }
                            { model.telephone && generateGridRow("Telephone", model.telephone) }
                            { model.ticketId && generateGridRow("TicketId", model.ticketId, "ticketId") }
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={handleCancel}
                        className="cancel-action"
                    >
                    Cancel
                    </Button>
                    <Button
                        type="submit"
                        form="attendee-differences-form"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </Formik>
    );
};
