import * as React from "react";
import { AccountLockSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const AccountLock: ConfigurationAreaComponent<AccountLockSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="AccountLockSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "FailedLoginAttemptsToLock", label: "Failed Login Attempts for Lock Out" },
                { name: "TimeToLockInMinutes", label: "Number of Minutes to lock the account" }
            ]}
        />
    );
};
