/* eslint-disable max-lines */
import * as React from "react";
import { ConstructionPostmarkTemplates } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid, SettingsGridItemProps } from "./SettingsGrid";
import { validatePostmarkTemplateId } from "../validation";
import { Form, Grid } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";
import { WorkflowTypeEnum, ConstructionWorkflowType } from "@common/crud/eventType/model";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ConstructionRecipientType, RecipientTypeEnum } from "@common/crud/common/RecipientTypeEnum";
import { PostmarkTemplateInfoApi } from "../postmarkTemplateInfoApi";
import { usePreventScroll } from "react-aria";

export const ConstructionPostmark: ConfigurationAreaComponent<
ConstructionPostmarkTemplates
> = ({ settings, hierarchy }) => {
    const [filtersVisible, setFiltersVisible] = React.useState<boolean>(true);
    const [workflowType, setWorkflowType] = React.useState<WorkflowTypeEnum>();
    const [deliveryType, setDeliveryType] = React.useState<DeliveryTypeEnum>();
    const [recipientType, setRecipientType] = React.useState<RecipientTypeEnum>();
    const [fieldsMatchingCriteria, setFieldsMatchingCriteria] = React.useState<string[]>();
    const [loadingData, setLoadingData] = React.useState<boolean>(true);

    usePreventScroll({ isDisabled: !loadingData });

    const items = React.useMemo(() => [
        {
            name: "SendFromEmailAlaska",
            label: "Send From Email Address - Alaska",
        },
        {
            name: "OneTimePasscodeEmail",
            label: "One Time Passcode",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionBookingAppBookerConfirmation",
            label: "Construction Booking App Booker Confirmation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "MissingDelegateInformation",
            label: "Missing Delegate Information",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
        },
        {
            name: "ConstructionOpenCourseReminderClassroomStandard",
            label: "Construction Open Course Reminder – Standard - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOpenCourseReminderDigitalStandard",
            label: "Construction Open Course Reminder – Standard - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOpenCourseReminderClassroomCITB",
            label: "Construction Open Course Reminder - CITB - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOpenCourseReminderDigitalCITB",
            label: "Construction Open Course Reminder - CITB - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOpenCourseReminderClassroomAPS",
            label: "Construction Open Course Reminder - APS - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOpenCourseReminderDigitalAPS",
            label: "Construction Open Course Reminder - APS - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEi",
            label: "Trainer allocated to EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEiTotal",
            label: "Trainer Allocated EI total",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerEiReminderForConstructionEi",
            label: "Trainer reminder for Construction EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerRemovedFromConstructionEi",
            label: "Trainer removed from Construction EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOrganisationCourseReminderClassroom",
            label: "Construction Organisation Course Reminder - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOrganisationCourseReminderDigital",
            label: "Construction Organisation Course Reminder - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBOrganisationCourseReminderClassroom",
            label: "CITB Organisation Course Reminder - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBOrganisationCourseReminderDigital",
            label: "CITB Organisation Course Reminder - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSOrganisationCourseReminderClassroom",
            label: "APS Organisation Course Reminder - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSOrganisationCourseReminderDigital",
            label: "APS Organisation Course Reminder - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionDelegateCourseReminderClassroom",
            label: "Construction Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionDelegateCourseReminderDigital",
            label: "Construction Delegate Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBDelegateCourseReminderClassroom",
            label: "CITB Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBDelegateCourseReminderDigital",
            label: "CITB Delegate Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSDelegateCourseReminderClassroom",
            label: "APS Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSDelegateCourseReminderDigital",
            label: "APS Delegate Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOrganisationCourseCreationClassroom",
            label: "Construction Organisation Course Creation - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConstructionOrganisationCourseCreationDigital",
            label: "Construction Organisation Course Creation - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBOrganisationCourseCreationClassroom",
            label: "CITB Organisation Course Creation - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CITBOrganisationCourseCreationDigital",
            label: "CITB Organisation Course Creation - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSOrganisationCourseCreationClassroom",
            label: "APS Organisation Course Creation - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "APSOrganisationCourseCreationDigital",
            label: "APS Organisation Course Creation - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EnquiryConfirmation",
            label: "Enquiry Confirmation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "GeneralConstructionEnquiryEmailAddress",
            label: "Email General Enquiries To"
        },
        {
            name: "GeneralConstructionEnquiry",
            label: "General Enquiry"
        }
    ] satisfies SettingsGridItemProps<keyof ConstructionPostmarkTemplates> [], []);

    React.useEffect(() => {
        const fetchFields = async () => {
            const api = new PostmarkTemplateInfoApi();
            const fields = await api.fieldsMatchingCriteria("ConstructionPostmarkTemplates", workflowType, deliveryType, recipientType);
            setFieldsMatchingCriteria(fields);
            setLoadingData(false);
        };

        setLoadingData(true);
        fetchFields();
    }, [workflowType, deliveryType, recipientType]);

    const filteredItems = React.useMemo(() => {
        if (workflowType || deliveryType || recipientType) {
            if (fieldsMatchingCriteria && fieldsMatchingCriteria.length > 0) {
                return items.filter((item) => fieldsMatchingCriteria.includes(item.name));
            } else {
                return [];
            }
        } else {
            return items;
        }
    }, [deliveryType, fieldsMatchingCriteria, items, recipientType, workflowType]);

    const onWorkflowTypeChange = React.useCallback((_: any, data: any) => {
        setWorkflowType(+data.value);
    }, []);

    const onDeliveryTypeChange = React.useCallback((_: any, data: any) => {
        setDeliveryType(+data.value);
    }, []);

    const onRecipientTypeChange = React.useCallback((_: any, data: any) => {
        setRecipientType(+data.value);
    }, []);

    const clearFilters = React.useCallback(() => {
        setWorkflowType(0);
        setDeliveryType(0);
        setRecipientType(0);
    }, []);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"float-left cursor-pointer"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filtersVisible &&
                <Grid stackable className="filter-grid" width={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Dropdown
                                label="Workflow"
                                placeholder="Any"
                                value={workflowType}
                                options={optionsFromObject(ConstructionWorkflowType, "Any", "")}
                                onChange={onWorkflowTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Delivery Type"
                                placeholder="Any"
                                value={deliveryType}
                                options={optionsFromObject(DeliveryType, "Any", "")}
                                onChange={onDeliveryTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Recipient Type"
                                placeholder="Any"
                                value={recipientType}
                                options={optionsFromObject(ConstructionRecipientType, "Any", "")}
                                onChange={onRecipientTypeChange}
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} />
                        <Grid.Column width={8}>
                            <Form.Button className="display-right" content="Clear Filters" onClick={clearFilters} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            <SettingsGrid
                area="ConstructionPostmarkTemplates"
                hierarchy={hierarchy}
                settings={settings}
                items={filteredItems}
            />
        </>
    );
};
