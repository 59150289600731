import * as React from "react";
import * as moment from "moment";
import { Button, Grid } from "semantic-ui-react";
import { MonthDropDown } from "./MonthDropDown";
import { DigitalPlanningFilter } from "./DigitalPlanningBase";
import { useDispatch } from "react-redux";
import { loadDigitalTrainersForDdrs, loadDigitalTrainersForDors } from "@common/crud/trainer/actions";
import { loadDigitalPlaningProgressForDdrs, loadDigitalPlaningProgressForDors } from "../../actions";
import { Filters } from "./Filters";
import { Area, DigitalPlanningSession } from "../../model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { Trainer } from "@common/crud/trainer";
import { DesktopLegend } from "@common/availabilityTrainer/components/DesktopLegend";

export interface DigitalPlanningHeaderProps {
    selectedMonth: moment.Moment;
    selectedTrainerId: any;
    selectedPlanningSession: DigitalPlanningSession;
    eventTypeCategory: EventTypeCategory;
    originalMonth: moment.Moment;
    currentTrainer: Trainer;
    onFilterChange: (filter: DigitalPlanningFilter) => Promise<void>;
}

export const DigitalPlanningHeader = (props: DigitalPlanningHeaderProps) => {
    const { selectedMonth, selectedTrainerId, selectedPlanningSession, eventTypeCategory, originalMonth, currentTrainer, onFilterChange } = props;

    const [recalculating, setRecalculating] = React.useState(false);
    const [showLegend, setShowLegend] = React.useState(false);

    const dispatch = useDispatch();

    const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;

    async function recalculate() {
        setRecalculating(true);
        const params =  { month: selectedMonth, trainerId: selectedTrainerId, recalculate: true };
        const ddrsParams = { months: [selectedMonth], trainerId: selectedTrainerId, recalculate: true };
        dispatch(ddrs ? loadDigitalTrainersForDdrs(ddrsParams) : loadDigitalTrainersForDors(params));
        dispatch(ddrs ? loadDigitalPlaningProgressForDdrs() : loadDigitalPlaningProgressForDors());
        setRecalculating(false);
    }

    function toggleLegend(e: any) {
        e.currentTarget.blur();
        setShowLegend(prev => !prev);
    }

    return (
        <>
            <Grid.Row>
                <Grid.Column width={6}>
                    <MonthDropDown disabled={undefined} onFilterChange={onFilterChange} month={selectedMonth} />
                </Grid.Column>
                {selectedMonth &&
                    <Grid.Column width={5} verticalAlign="middle" className="padded-top">
                        <Button disabled={recalculating} loading={recalculating} onClick={recalculate}>Recalculate</Button>
                        <Button onClick={toggleLegend}>{showLegend ? "Hide Legend" : "Show Legend"}</Button>
                    </Grid.Column>
                }
            </Grid.Row>
            {selectedMonth &&
                <>
                    <Grid.Row>
                        <Filters
                            key={`${selectedMonth.toString()}-trainers`}
                            disabled={undefined}
                            trainerId={selectedTrainerId}
                            onFilterChange={onFilterChange}
                            trainerOrder={selectedPlanningSession?.trainerOrder ?? []}
                            month={selectedMonth}
                            savedDigitallyPlannedEvents={selectedPlanningSession?.plannedDigitalEvents ?? []}
                            eventTypeCategory={eventTypeCategory}
                            originalMonth={originalMonth}
                            currentTrainer={currentTrainer}
                        />
                    </Grid.Row>
                    {showLegend &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Grid className="ui divided availability-legend no-margin-top">
                                    <Grid.Row>
                                        <Grid.Column><h3>Legend</h3></Grid.Column>
                                    </Grid.Row>
                                    <DesktopLegend area={Area.AdminEventManagementDigitalPlanning} />
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </>
            }
        </>
    );
};
