/* eslint-disable max-lines */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Segment, Tab, TabProps, Dropdown, Divider, Button, Icon } from "semantic-ui-react";
import { push } from "redux-little-router";
import { useTranslation } from "react-i18next";
import { bookingBasePathSelector, bookingSelector, deliveryTypeFilterSelector } from "../selectors";
import { BookingStages } from "../../global/BookingStages";
import { RebookInformation } from "../../bookings/components/RebookInformation";
import { WelcomeComponent } from "../../global/WelcomeComponent";
import { Filters } from "./Filters";
import { FilterOptions } from "../../eventInstance/model";
import { AllEventInstances } from "./AllEventInstances";
import { AllEventInstancesMobile } from "./AllEventInstancesMobile";
import { venueIdSelector, venuesSelector } from "@booking/venues/selectors";
import { findVenuesAndEventInstancesForBookingInRange } from "@booking/courseSearch/actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { Venue } from "@common/crud/venue";
import { IntroMessage } from "./IntroMessage";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { AllEventInstancesProps, CourseSearchResultRow, GroupCourseSearchResultRow } from "@booking/landing/model";
import { PendingEventInstanceReservation } from "@booking/landing/components/PendingEventInstanceReservation";
import { Media } from "@common/global/AppMedia";
import { languageSelector } from "@booking/selectors";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { LanguageEnum } from "@common/crud/eventInstance/model";
import { getFiltersTabPanes } from "./helper";
import { AppSettings } from "@common/appSettings/model";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { EventTypeApi } from "@common/crud/eventType";
import { useSetAdvancedFilter } from "../hooks/useSetAdvancedFilter";
import {
    setDeliveryTypeBookingId,
    setDeliveryTypeFilterColumnActiveIndex,
    setDeliveryTypeFilterDistance,
    setDeliveryTypeFilterVenueId
} from "@booking/landing/actions";
import { useGetDeliveryTypeFilterFromSessionStorage } from "@booking/landing/hooks/useGetDeliveryTypeFilterFromSessionStorage";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { GenesysTokenProvider } from "@common/genesys/components/GenesysTokenProvider";
import { RecordCaAction } from "@common/genesys/genesysActions";
import { AdminRecordedActionType } from "@common/genesys/model";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";

enum IndexNames {
    None = -1,
    Digital = 0,
    Classroom = 1,
}

const deliveryTypeToIndexNameMap = {
    [DeliveryTypeEnum.Digital]: IndexNames.Digital,
    [DeliveryTypeEnum.Onsite]: IndexNames.Classroom,
    [DeliveryTypeEnum.None]: IndexNames.Digital,
};

export const Landing: React.FC = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const [t] = useTranslation("Landing");
    const booking = useSelector(bookingSelector);
    const path = useSelector(bookingBasePathSelector);
    const deliveryTypeFilterStore = useSelector(deliveryTypeFilterSelector);
    const venues: Venue[] = useSelector(venuesSelector);
    const queryVenueId: string = useSelector(venueIdSelector);
    const [filtersChanged, setFiltersChanged] = React.useState(false);
    const [showNoCoursesMessage, setShowNoCoursesMessage] = React.useState(false);
    const appSettings: AppSettings = useSelector(appSettingsSelector);
    const welshPoliceForceIdsSetting = appSettings?.bookingAppSettings?.welshPoliceForceIds;
    const isAttendee = !useSelector(isUserClientAdvisor);
    // We redirect to the dashboard if an attendee has a booking
    // We don't redirect if they are cancelled or in the process of rebooking
    const redirectToDashboard = booking.seatBooked && !booking.isCancelled && isAttendee && !booking.isRebooking;
    const deliveryTypeFilter = useGetDeliveryTypeFilterFromSessionStorage(booking.id);
    const [activeIndex, setActiveIndex] = React.useState<IndexNames>(
        deliveryTypeFilter && deliveryTypeFilter?.deliveryTypeColumnActiveIndex !== null ?
            deliveryTypeFilter.deliveryTypeColumnActiveIndex : IndexNames.None);
    const [moreResults, setMoreResults] = React.useState<boolean>(false);
    const [venueId, setVenueId] = React.useState<string>(
        deliveryTypeFilter && deliveryTypeFilter?.venueId ?
            deliveryTypeFilter?.venueId : queryVenueId);
    const [distance, setDistance] = React.useState<number>(deliveryTypeFilter && deliveryTypeFilter?.distance !== null ? deliveryTypeFilter.distance : 25);
    const [searchOptions, setSearchOptions] = React.useState<FilterOptions>({
        distance: 25,
        limit: pageSize,
    });
    const [eventInstances, setEventInstances] = React.useState<CourseSearchResultRow[]>([]);
    const [eventInstanceGroups, setEventInstanceGroups] = React.useState<GroupCourseSearchResultRow[]>([]);
    const [currentLanguage, setCurrentLanguage] = React.useState<LanguageEnum>();
    const [loadingDeliveryTypes, setLoadingDeliveryTypes] = React.useState(true);
    const [showClassroomCourses, setShowClassroomCourses] = React.useState(
        deliveryTypeFilter && deliveryTypeFilter?.deliveryTypeColumnActiveIndex === IndexNames.Classroom);
    const [showDigitalCourses, setShowDigitalCourses] = React.useState(
        deliveryTypeFilter && deliveryTypeFilter?.deliveryTypeColumnActiveIndex === IndexNames.Digital);
    // value used to prevent another call to api after first load, when active index changes from None to default value
    // using mutable ref object instead of state because then it would need to be a dependency for useEffect
    // and it would trigger a refresh when changed from true to false
    const suppressNextLoad = React.useRef<boolean>(false);

    const language = useSelector(languageSelector);
    const bookingSettings = useSelector(appSettingsSelector)?.bookingAppSettings;
    const noClassroomCoursesMessage = language.current === "cy" ? bookingSettings.noClassroomMessageCymraeg : bookingSettings.noClassroomMessageEnglish;
    const onlyOneDeliveryType = !showClassroomCourses || !showDigitalCourses;
    const noCoursesMessageShown = !filtersChanged && queryVenueId !== undefined && eventInstances?.length === 0;

    const setDefaultTab = React.useCallback((deliveryType: DeliveryTypeEnum) => {
        if (activeIndex === IndexNames.None) {
            setActiveIndex(onlyOneDeliveryType ? 0 : deliveryTypeToIndexNameMap[deliveryType]);
            suppressNextLoad.current = true;
        } else {
            suppressNextLoad.current = false;
        }
    }, [activeIndex, onlyOneDeliveryType]);

    React.useEffect(() => {
        if (Object.keys(deliveryTypeFilterStore).length !== 0) {
            if (deliveryTypeFilterStore?.deliveryTypeColumnActiveIndex !== null && deliveryTypeFilterStore?.deliveryTypeColumnActiveIndex !== undefined) {
                setActiveIndex(deliveryTypeFilterStore.deliveryTypeColumnActiveIndex);
            }
            if (deliveryTypeFilterStore?.distance !== null && deliveryTypeFilterStore?.distance !== undefined) {
                setDistance(deliveryTypeFilterStore.distance);
            }

            if (deliveryTypeFilterStore?.venueId) {
                setVenueId(deliveryTypeFilterStore.venueId);
            }
            return;
        }

        if (booking.id) {
            dispatch(setDeliveryTypeBookingId(booking.id));
        }
    },[booking, deliveryTypeFilterStore]);

    React.useEffect(() => {
        async function setDeliveryTypes() {
            const api = new EventTypeApi();
            const deliveryType = await api.getDeliveryTypesForEventType(booking.eventTypeId);
            if (deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) {
                setShowDigitalCourses(true);
                setShowClassroomCourses(true);
                setLoadingDeliveryTypes(false);
                return;
            }
            setShowClassroomCourses(deliveryType === SchemeDeliveryTypeEnum.Classroom);
            setShowDigitalCourses(deliveryType === SchemeDeliveryTypeEnum.Digital);
            setLoadingDeliveryTypes(false);
        }
        if (booking?.eventTypeId) {
            setDeliveryTypes();
        }
    }, [booking?.eventTypeId, booking?.id, isAttendee]);

    React.useEffect(() => {
        if (!appSettings.loaded) {
            return;
        }

        // if booked, we immediately redirect to dashboard, so finding EIs/venues is unnecessary and was causing race condition
        if (!booking.id || redirectToDashboard) {
            return;
        }

        if (suppressNextLoad.current) {
            suppressNextLoad.current = false;
            return;
        }

        if (loadingDeliveryTypes) {
            return;
        }

        // If attendee is booking and org. is not one of the following welsh ones, then default language to English
        let languageToSet = searchOptions.language;
        const welshPoliceForceIds = welshPoliceForceIdsSetting
            && welshPoliceForceIdsSetting.length > 0
            && welshPoliceForceIdsSetting.split(",").filter(Boolean).map(Number);
        if (isAttendee && !welshPoliceForceIds.includes(booking.forceId) && searchOptions?.language === undefined) {
            languageToSet = LanguageEnum.English;
            setCurrentLanguage(LanguageEnum.English);
        }
        const updatedSearchOptions = { ...searchOptions, distance, language: languageToSet };
        const digitalCourses = onlyOneDeliveryType ? showDigitalCourses : activeIndex === IndexNames.None ? null :  activeIndex !== IndexNames.Classroom;
        dispatch(findVenuesAndEventInstancesForBookingInRange({
            ...updatedSearchOptions,
            venueId,
            distance: distance ? distance : undefined,
            preferredVenueId: !filtersChanged || activeIndex === IndexNames.None ? queryVenueId : undefined,
            digitalCourses,
        }, setEventInstances, setEventInstanceGroups, setMoreResults, setDefaultTab, setDistance));
    }, [
        searchOptions,
        queryVenueId,
        activeIndex,
        booking.id,
        venueId,
        redirectToDashboard,
        dispatch,
        setDefaultTab,
        booking.forceId,
        isAttendee,
        welshPoliceForceIdsSetting,
        filtersChanged,
        distance,
        loadingDeliveryTypes,
        appSettings.loaded,
        onlyOneDeliveryType,
        showDigitalCourses,
        setDistance]);

    React.useEffect(() => {
        setShowNoCoursesMessage(noCoursesMessageShown);
    }, [noCoursesMessageShown]);

    const onVenueFilterChange = React.useCallback((_: any, { value }) => {
        setVenueId(value);
        dispatch(setDeliveryTypeFilterVenueId(value));
    }, []);

    const handleTabChange = React.useCallback((_: any, d: TabProps) => {
        const newActiveIndex = d.activeIndex === IndexNames.Classroom ? IndexNames.Classroom : IndexNames.Digital;
        if (newActiveIndex !== activeIndex) {
            suppressNextLoad.current = false;
            setActiveIndex(newActiveIndex);
            dispatch(setDeliveryTypeFilterColumnActiveIndex(newActiveIndex));
            setSearchOptions({ ...searchOptions, limit: pageSize, venueId: "" });
            setVenueId(deliveryTypeFilterStore?.venueId || queryVenueId);
            dispatch(setDeliveryTypeFilterVenueId(
                deliveryTypeFilterStore && deliveryTypeFilterStore?.venueId !== null && deliveryTypeFilterStore?.venueId !== undefined
                    ? deliveryTypeFilterStore?.venueId : ""));
            setFiltersChanged(true);
        }
    }, [searchOptions, activeIndex, deliveryTypeFilterStore]);

    const onDistanceDropdownChange = React.useCallback((_: any, data: { value: number }) => {
        setDistance(data.value);
        dispatch(setDeliveryTypeFilterDistance(data.value));
        setVenueId("");
        dispatch(setDeliveryTypeFilterVenueId(""));
        setFiltersChanged(true);
        setSearchOptions({ ...searchOptions, limit: pageSize, venueId: "" });
    }, [dispatch, searchOptions]);

    const loadMore = React.useCallback(() => setSearchOptions(options => ({ ...options, limit: options.limit + pageSize })), []);

    const distanceOptions = React.useCallback((isMobile: boolean = false) => {
        const options = [25, 50, 75, 100].map((item) => {

            const dropdownText = isMobile ? `Within ${item.toString()} miles` : item.toString();
            return ({ text: dropdownText, value: item });
        });
        return isAttendee ? options : [{ text: "Any", value: -1 }, ...options];
    }, [isAttendee]);

    const updateFilters = React.useCallback((filters: FilterOptions) => {
        const newSearchOptions = { ...filters, venueId: "", limit: pageSize, preferredVenueId: "" };

        setVenueId("");
        setFiltersChanged(true);
        setSearchOptions(newSearchOptions);
        useSetAdvancedFilter(newSearchOptions, booking.id);
    }, [filtersChanged, queryVenueId, venues]);

    const disableClassroomFilters = onlyOneDeliveryType ? showDigitalCourses : activeIndex === IndexNames.Digital;

    if (!booking) {
        return <h2>{t("BOOKING_NOT_FOUND")}</h2>;
    }

    if (booking.bookingIsDuplicate || (booking.isCancelled && booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet)) {
        return (
            <div>
                <h2>{t("WELCOME_TO_PORTAL")}</h2>
                <div className="duplicate-booking-message">
                    <p>{t("UNABLE_TO_BOOK_ON_COURSE")}</p>
                    <p>{t("PLEASE_CALL_FOR_ASSISTANCE")}</p>
                </div>
            </div>);
    }

    if (booking.anonymized) {
        return (
            <div>
                <h2>{t("SESSION_EXPIRED_HEADING")}</h2>
                <div>
                    <p>{t("SESSION_EXPIRED_BODY")}</p>
                    <Button as="a" attached="bottom" fluid href="https://offer.ndors.org.uk/#/home" positive>{t("SESSION_EXPIRED_BUTTON_TEXT")}</Button>
                </div>
            </div>
        );
    }

    if (redirectToDashboard) {
        // If previous booking hasn't been completely processed then push them to the confirmation page instead of the dashboard
        const redirectUrl = booking.noRebookingAllowed ? `${path}/booking-confirmation` : `${path}/dashboard`;
        dispatch(push(redirectUrl));
        return null;
    }

    const venueOptions = [
        { key: "", text: t("ANY"), value: "" },
        ...venues.map(c => (
            { key: c.id, text: `${c.name} ${t("MILES_AWAY", { distanceInMiles: c.distanceInMiles })}`, value: c.id }
        ))
    ];

    const coursesMenuItemContent = (titleKey: string, explanationKey: string) => (
        <>
            <Icon className={titleKey.includes("CLASSROOM") ? "classroom" : "digital"} />
            <div>
                <h3>{t(titleKey)}</h3>
                {t(explanationKey)}
            </div>
        </>
    );

    const renderEventInstances = (isMobile: boolean, classRoomCourses: boolean) => {

        const noCoursesMessage = classRoomCourses ? noClassroomCoursesMessage : t("THERE_ARE_NO_DIGITAL_COURSES");
        const eventInstancesProps: AllEventInstancesProps = {
            eventInstances, eventInstanceGroups, moreResults, loadMore, pageSize,
            noCoursesMessage, showNoCoursesMessage: classRoomCourses ? showNoCoursesMessage : false };

        return isMobile
            ? () => <AllEventInstancesMobile {...eventInstancesProps} />
            : () => <AllEventInstances {...eventInstancesProps} />;
    };

    const classroomNonMobileTab = () => {
        const eventsComponent = renderEventInstances(false, true);
        return (
            <div className="classroom-section">
                <Grid>

                    <Grid.Row className="training-centre-filter">
                        <Grid.Column width={5}>
                            <span>{t("FILTER_BY_DISTANCE")}:</span>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Dropdown
                                fluid
                                disabled={disableClassroomFilters}
                                value={distance}
                                placeholder={t("FILTER_BY_DISTANCE_PLACEHOLDER")}
                                options={distanceOptions()}
                                onChange={onDistanceDropdownChange}
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="training-centre-filter">
                        <Grid.Column width={5}>
                            <span>{t("FILTER_BY_TRAINING_CENTRE")}:</span>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Dropdown
                                fluid
                                selection
                                options={venueOptions}
                                placeholder={t("FILTER_BY_VENUE")}
                                value={venueId}
                                onChange={onVenueFilterChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <>{eventsComponent()}</>
            </div>);
    };

    const classroomMobileTab = () => {
        const eventsComponent = renderEventInstances(true, true);
        return (
            <div className="mobile-event-instances">
                <Container className="training-centre-filter-mobile-container">
                    <Dropdown
                        fluid
                        disabled={disableClassroomFilters}
                        value={distance}
                        placeholder={t("FILTER_BY_DISTANCE_PLACEHOLDER")}
                        options={distanceOptions(true)}
                        onChange={onDistanceDropdownChange}
                        selection
                    />
                    <Dropdown
                        selection
                        options={venueOptions}
                        placeholder={t("FILTER_BY_VENUE")}
                        value={venueId}
                        onChange={onVenueFilterChange}
                        className="training-centre-filter-mobile"
                    />
                </Container>
                <>{eventsComponent()}</>
            </div>
        );
    };

    const getFilters = (defaultVisible: boolean) => (
        <Filters
            updateFilters={updateFilters}
            disableClassroomFilters={disableClassroomFilters}
            defaultVisible={defaultVisible}
            isAttendee={isAttendee}
            currentLanguage={currentLanguage}
        />
    );

    const deskTopTabPanes = getFiltersTabPanes(renderEventInstances(false, false),
        classroomNonMobileTab, coursesMenuItemContent, showClassroomCourses, showDigitalCourses);
    const mobileTabPanes = getFiltersTabPanes(renderEventInstances(true, false),
        classroomMobileTab, coursesMenuItemContent, showClassroomCourses, showDigitalCourses);

    const tableClassName = onlyOneDeliveryType ? "single-digital-tabs" : "digital-tabs";

    function onTokenReceived(token: string) {
        dispatch(RecordCaAction(token, booking.id, AdminRecordedActionType.startBookingJourney));
    };
    return (
        <>
            <IntroMessage />
            <RebookInformation />
            <Authorize authorize={TtcClientAdvisorRole}>
                <GenesysTokenProvider onTokenReceived={onTokenReceived} showLogin={false} />
            </Authorize>
            <Segment clearing className="welcome-title">
                <WelcomeComponent booking={booking} />
                {booking.hasPendingReservation && <PendingEventInstanceReservation booking={booking} />}
            </Segment>
            <Media greaterThanOrEqual="tablet">
                <BookingStages activeStage={1} paymentRequired />
                <Grid className="two-section-layout">
                    <Grid.Column width={10} className={`two-section-layout-left ${tableClassName}`}>
                        <Tab activeIndex={activeIndex} onTabChange={handleTabChange} panes={deskTopTabPanes} />
                    </Grid.Column>
                    <Grid.Column width={6} className="two-section-layout-right">
                        {getFilters(true)}
                    </Grid.Column>
                </Grid>
            </Media>
            <Media lessThan="tablet">
                <Container className={`mobile-container ${tableClassName}`}>
                    {getFilters(false)}
                    <Tab
                        activeIndex={activeIndex}
                        onTabChange={handleTabChange}
                        className="mobile-event-instances"
                        panes={mobileTabPanes} />
                </Container>
            </Media>
        </>
    );
};
