/* eslint-disable max-lines */
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { getCurrencyFormat } from "@common/formating/CurrencyFormatting";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { isInteger, nonNegativeNumber } from "@common/validation";
import { muiLessThanAYearAgoOrFutureValidator } from "@common/validation/lessThanAYearAgoOrFutureValidator";
import { Input } from "@neworbit/simpleui-input";
import { throttle } from "lodash";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import {  Button, Table } from "semantic-ui-react";
import { TrainerFeeBase, BonusBase, TrainerFee, TrainerTieredBonus } from "../../model";
import _ from "lodash";
import { TrainerFeeFormErrors } from "./TrainerPayEditModal";
import { v4 } from "uuid";
import { MuiDateField } from "@common/components/MuiDateField";
import { useSelector } from "react-redux";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

interface TrainerPayTableProps {
    trainerFees: TrainerFee[];
    newTrainerFees?: TrainerFee[];
    setNewTrainerFees?: React.Dispatch<React.SetStateAction<TrainerFee[]>>;
    onAddRowClick?: () => void;
    editable?: boolean;
    push?: (path: string) => void;
    trainerFeesBeingEdited?: Map<string, TrainerFee>;
    setTrainerFeesBeingEdited?: React.Dispatch<React.SetStateAction<Map<string, TrainerFee>>>;
    formErrors?: TrainerFeeFormErrors;
    setFormErrors?: React.Dispatch<React.SetStateAction<TrainerFeeFormErrors>>;
    noFeesText?: string;
}

export const TrainerPayTable: React.FC<TrainerPayTableProps> =
({ trainerFees, newTrainerFees, setNewTrainerFees, editable, trainerFeesBeingEdited, setTrainerFeesBeingEdited, formErrors,
    setFormErrors, noFeesText }) => {

    const businessLineType = useSelector(businessLineTypeSelector);

    function toastThresholdWarning(tier: number, previousTier: number) {
        toast.warning(`threshold for tier ${tier} must be higher than that of tier ${previousTier}`);
    }

    const startOfCurrentMonth = moment().utc().startOf("month");
    const blankTrainerFee: TrainerFee =
        { effectiveDate: startOfCurrentMonth, weekday: 0, saturday: 0, sunday: 0, evening: 0, welsh: 0, singleTrainer: 0, tieredBonuses:
        [{ tierNumber: 2, threshold: 0, amount: 0 }, { tierNumber: 3, threshold: 0, amount: 0 }] };

    const hasExistingTrainerFees = trainerFees && trainerFees.length > 0;
    const hasNewTrainerFees = newTrainerFees && newTrainerFees.length > 0;

    const throttledThresholdWarning = React.useMemo(() => throttle(toastThresholdWarning, 1000, { trailing: false }), []);
    const throttledEffectiveDateWarning = React.useMemo(() => throttle(displayEffectiveDateWarning, 1000, { trailing: false }), []);
    const activeTrainerFee = React.useMemo(() => _.findLast(trainerFees, f => f.effectiveDate.isSameOrBefore(moment())), [trainerFees]);
    const newlyAddedTrainerFee = React.useMemo(() => {
        const trainerFeeSet = new Set(trainerFees?.map(f => f.id));
        return newTrainerFees?.find(f => !trainerFeeSet.has(f.id));
    }, [trainerFees, newTrainerFees]);

    const defaultNumberOfTiers = 2;
    const maxNumberOfBonusTiers = React.useMemo(() => {
        return trainerFees
            ? Math.max(defaultNumberOfTiers, Math.max(...trainerFees.map(fees => fees.tieredBonuses?.length ?? 0)))
            : defaultNumberOfTiers;
    }, [trainerFees]);

    function displayEffectiveDateWarning(date: string)  {
        toast.warning(`effective date ${date} already exists in the configuration`);
    };

    function onAddRowClick() {
        const startOfMonthFormatted = startOfCurrentMonth.format(DateFormat.DayMonthYear);
        const startOfMonthIsValid = !newTrainerFees.some(fee => fee.effectiveDate?.isSame(startOfCurrentMonth, "month"));

        if (!startOfMonthIsValid) {
            throttledEffectiveDateWarning(startOfMonthFormatted);
        }

        const newTrainerFee = { ...blankTrainerFee, id: v4() };
        setNewTrainerFees(prev => [...prev, newTrainerFee]);
        setTrainerFeesBeingEdited(prev => prev.set(newTrainerFee.id, newTrainerFee));
        updateFormErrorsOnFieldChange(newTrainerFee, "effectiveDate", startOfMonthIsValid);
    }

    function updateTrainerFee(trainerFee: TrainerFee, updatedTrainerFee: TrainerFee) {
        setNewTrainerFees(prev => prev.map(f => f.id === trainerFee.id ? updatedTrainerFee : f));
    }

    function updateFormErrorsOnFieldChange(trainerFee: TrainerFee, formField: string, valid: boolean) {
        if (!(trainerFee.id in formErrors)) {
            if (!valid) {
                const set = new Set<string>();
                set.add(formField);
                setFormErrors(prev => ({ ...prev, [trainerFee.id]: set }));
            }
            return;
        }

        const newFormErrors = { ...formErrors };
        if (newFormErrors[trainerFee.id].has(formField) && valid) {
            newFormErrors[trainerFee.id].delete(formField);
        } else if (!newFormErrors[trainerFee.id].has(formField) && !valid) {
            newFormErrors[trainerFee.id].add(formField);
        }

        if (newFormErrors[trainerFee.id].size === 0) {
            delete newFormErrors[trainerFee.id];
        }

        setFormErrors(newFormErrors);
    }

    function onEffectiveDateChange(trainerFee: TrainerFee) {
        return function(value: moment.Moment, valid: boolean) {
            const firstOfMonth = value?.clone().utc().startOf("month");
            const dateIsAlreadyUsed = value ? newTrainerFees.some(fee => fee.effectiveDate?.isSame(value, "month") && fee.id !== trainerFee.id) : false;

            if (dateIsAlreadyUsed) {
                displayEffectiveDateWarning(value.format(DateFormat.DayMonthYear));
            }

            updateTrainerFee(trainerFee, { ...trainerFee, effectiveDate: firstOfMonth });
            updateFormErrorsOnFieldChange(trainerFee, "effectiveDate", !dateIsAlreadyUsed && valid);
        };
    }

    function onBaseFeeValueChange(trainerFee: TrainerFee, baseFeePart: keyof TrainerFeeBase, value: number) {
        updateTrainerFee(trainerFee, { ...trainerFee, [baseFeePart]: value });
    }

    function onBonusTierFeeValueChange(trainerFee: TrainerFee, tierIndex: number, bonusTierPart: keyof BonusBase, value: number) {
        const newTieredBonuses = trainerFee.tieredBonuses.map((tier, index) => {

            return tierIndex === index?   { ...tier, [bonusTierPart]: value }: tier;
        });

        if (newTieredBonuses) {
            newTieredBonuses.forEach(b => {
                const previousTier = newTieredBonuses.find(x => x.tierNumber === b.tierNumber - 1);
                const previousTierThreshold = b.tierNumber === 2? -1 :previousTier?.threshold;
                const isValid = previousTierThreshold < b.threshold;

                if (!isValid) {
                    throttledThresholdWarning(b.tierNumber, previousTier?.tierNumber);
                }

                if (previousTier) {
                    updateFormErrorsOnFieldChange(trainerFee, `threshold${previousTier?.tierNumber}`, isValid);
                }

                updateFormErrorsOnFieldChange(trainerFee, `threshold${b.tierNumber}`, isValid);
            });
        }

        updateTrainerFee(trainerFee, { ...trainerFee, tieredBonuses: newTieredBonuses });
    }

    function stopEditingTrainerFee(trainerFee: TrainerFee) {
        const newTrainerFeesBeingEdited = new Map(trainerFeesBeingEdited);
        newTrainerFeesBeingEdited.delete(trainerFee.id);
        setTrainerFeesBeingEdited(newTrainerFeesBeingEdited);
    }

    function startEditingTrainerFee(trainerFee: TrainerFee) {
        const newTrainerFeesBeingEdited = new Map(trainerFeesBeingEdited);
        newTrainerFeesBeingEdited.set(trainerFee.id, trainerFee);
        setTrainerFeesBeingEdited(newTrainerFeesBeingEdited);
    }

    function clearErrorsForTrainerFee(trainerFee: TrainerFee) {
        const newFormErrors = { ...formErrors };
        delete newFormErrors[trainerFee.id];
        setFormErrors(newFormErrors);
    }

    function onUndoClick(trainerFee: TrainerFee) {
        return function() {
            if (trainerFee.id === newlyAddedTrainerFee?.id) {
                setNewTrainerFees(prev => prev.filter(f => f.id !== trainerFee.id));
            } else {
                const trainerFeeBeforeChanges = trainerFeesBeingEdited.get(trainerFee.id);
                setNewTrainerFees(prev => prev.map(f => f.id === trainerFee.id ? trainerFeeBeforeChanges : f));
            }

            stopEditingTrainerFee(trainerFee);
            clearErrorsForTrainerFee(trainerFee);
        };
    }

    function onDeleteClick(trainerFee: TrainerFee) {
        return function() {
            setNewTrainerFees(newTrainerFees.filter(f => f.id !== trainerFee.id));
        };
    }

    function onEditClick(trainerFee: TrainerFee) {
        return function() {
            startEditingTrainerFee(trainerFee);
        };
    }

    const TrainerPayBaseRow =(trainerFee: TrainerFee, canBeWelsh: boolean, isEditable: boolean) => {
        const dateView = isEditable?
            (<MuiDateField
                value={trainerFee.effectiveDate}
                onChange={onEffectiveDateChange(trainerFee)}
                validation={[muiLessThanAYearAgoOrFutureValidator]}
                required
            />):trainerFee.effectiveDate?.format(DateFormat.DayMonthYear);

        const trainerFeeIsActive = trainerFee.id === activeTrainerFee?.id;

        const trainerFeeIsOverAYearOld = trainerFee?.effectiveDate?.isSameOrBefore(moment().add(-1, "y"));

        return (<React.Fragment key={isEditable? "edit": trainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            {editable && (hasExistingTrainerFees || hasNewTrainerFees) &&
                <Table.Cell singleLine className={!isEditable ? "" : "centered-text"}>
                    {!isEditable ?
                        <>
                            <Button content="Edit" onClick={onEditClick(trainerFee)} disabled={trainerFeeIsOverAYearOld} />
                            <Button content="Delete" secondary disabled={trainerFeeIsActive} onClick={onDeleteClick(trainerFee)} />
                        </> :
                        <Button content="Undo" secondary onClick={onUndoClick(trainerFee)} />}
                </Table.Cell>}
            <Table.Cell>{dateView}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(trainerFee, "weekday", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(trainerFee, "saturday", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(trainerFee, "sunday", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(trainerFee, "evening", isEditable)}</Table.Cell>
            {canBeWelsh && <Table.Cell>{baseNumFieldViewType(trainerFee, "welsh", isEditable)}</Table.Cell>}
        </React.Fragment>);
    };

    const BonusTierRow = (trainerFee: TrainerFee, bonusTier: TrainerTieredBonus, isEditable: boolean, tierIndex: number) => {

        const onChangeGeneric = (bonusTierPart: keyof BonusBase) => isEditable? (value: number) =>
            onBonusTierFeeValueChange(trainerFee, tierIndex, bonusTierPart, value): null;

        return (
            <React.Fragment key={tierIndex}>
                <Table.Cell>{bonusNumFieldViewType(trainerFee, bonusTier, "threshold",
                    isEditable, onChangeGeneric)}</Table.Cell>
                <Table.Cell>{bonusNumFieldViewType(trainerFee, bonusTier, "amount",
                    isEditable, onChangeGeneric)}</Table.Cell>
            </React.Fragment>
        );};

    const TrainerPayFullRow = (trainerFee: TrainerFee, isPoliceAndCourt: boolean, isEditable = false) => (
        (<Table.Row key={isEditable ? `edit-${trainerFee.id}` : trainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            {TrainerPayBaseRow(trainerFee, isPoliceAndCourt, isEditable)}
            {isPoliceAndCourt && (trainerFee.tieredBonuses
                ? trainerFee.tieredBonuses.map((bonus, index) => BonusTierRow(trainerFee, bonus, isEditable, index))
                : <><Table.Cell /><Table.Cell /><Table.Cell /><Table.Cell /></>
            )}
        </Table.Row>)
    );

    const TrainerPayHeader = (numberOfTiers: number, isPoliceAndCourt: boolean) => {
        const tierColumns = [];

        if (isPoliceAndCourt) {
            for (let i = 0; i < numberOfTiers; i++) {
                tierColumns.push({ rowOne: BonusHeaderRowOnePart(i + 2), rowTwo: BonusHeaderRowTwoPart(i+2) });
            }
        }

        return (<>
            <Table.Row>
                <Table.HeaderCell colSpan={editable && (hasNewTrainerFees || hasExistingTrainerFees) ? "2" : "1"} />
                <Table.HeaderCell colSpan='3'>{isPoliceAndCourt ? "Tier 1 / Standard" : "Standard"}</Table.HeaderCell>
                <Table.HeaderCell colSpan={isPoliceAndCourt ? "2" : "1"}>Add on</Table.HeaderCell>
                {isPoliceAndCourt && tierColumns.map(x => x.rowOne)}
            </Table.Row>
            <Table.Row>
                {editable && (hasNewTrainerFees || hasExistingTrainerFees) && <Table.HeaderCell>Actions</Table.HeaderCell>}
                <Table.HeaderCell>Date Effective (First of Month)</Table.HeaderCell>
                <Table.HeaderCell>Weekday</Table.HeaderCell>
                <Table.HeaderCell>Sat</Table.HeaderCell>
                <Table.HeaderCell>Sun</Table.HeaderCell>
                <Table.HeaderCell>Eve</Table.HeaderCell>
                {isPoliceAndCourt && <Table.HeaderCell>Welsh</Table.HeaderCell>}
                {tierColumns.map(x => x.rowTwo)}
            </Table.Row>
        </>);};

    const BonusHeaderRowOnePart =(tier: number) => (

        <Table.HeaderCell key={tier} colSpan='2'>{`Tier ${tier}`}</Table.HeaderCell>
    );

    const BonusHeaderRowTwoPart =(key: number) => (
        <React.Fragment key={key}>
            <Table.HeaderCell>Lowest no. courses</Table.HeaderCell>
            <Table.HeaderCell>Bonus</Table.HeaderCell>
        </React.Fragment>
    );

    const baseNumFieldViewType =(trainerFee: TrainerFee, baseFeePart: keyof TrainerFeeBase, isEditable: boolean) => {

        function onNumberChange(value: number, valid: boolean) {
            updateFormErrorsOnFieldChange(trainerFee, baseFeePart, valid);
            return onBaseFeeValueChange(trainerFee, baseFeePart, value);
        }

        const value = trainerFee[baseFeePart];

        return isEditable?
            <div className={"ui mini small-input"}><CurrencyInput
                value={value}
                onChange={onNumberChange}
                fitOnModal
            /></div> : getCurrencyFormat(value);};

    const bonusNumFieldViewType =(trainerFee: TrainerFee, bonusTier: TrainerTieredBonus, bonusTierPart: keyof BonusBase,
        isEditable: boolean, onChangeGeneric: (bonusTierPart: keyof BonusBase) => (value: number) => void) => {

        function onNumberChange(value: number, valid: boolean) {
            updateFormErrorsOnFieldChange(trainerFee, `${bonusTierPart}${bonusTier.tierNumber}`, valid);
            return isEditable? onChangeGeneric(bonusTierPart)(value): null;
        }

        const value = bonusTier[bonusTierPart];

        const numberInput = bonusTierPart === "amount" ?(<CurrencyInput
            value={value}
            onChange={onNumberChange}
            fitOnModal
        />):(<Input.Number value={value} onChange={onNumberChange}
            validation={[...isInteger(), nonNegativeNumber()]} />);

        const displayValue = bonusTierPart === "amount" ?  getCurrencyFormat(value): value;

        return isEditable? <div className={"ui mini small-input"}>{numberInput}</div> : displayValue;};

    return (
        <>
            <Table celled structured>
                <Table.Header>
                    {TrainerPayHeader(maxNumberOfBonusTiers, businessLineType === BusinessLineType.PoliceAndCourt)}
                </Table.Header>
                <Table.Body>
                    {trainerFeesBeingEdited?.size === 0 && !hasExistingTrainerFees &&
                        <Table.Row>
                            <Table.Cell textAlign="center" colSpan={5 + (2*defaultNumberOfTiers)}>{noFeesText ?? "No Fees Currently Saved"}</Table.Cell>
                        </Table.Row>}
                    {(hasExistingTrainerFees || hasNewTrainerFees) &&
                        (newTrainerFees || trainerFees)?.map(fee =>
                            TrainerPayFullRow(fee, businessLineType === BusinessLineType.PoliceAndCourt, trainerFeesBeingEdited?.has(fee.id)))}
                </Table.Body>
            </Table>
            {editable && !newlyAddedTrainerFee &&
                 <>
                     <Button content='Add new row' icon='add circle' labelPosition='left' onClick={onAddRowClick} />
                     <br /><br />
                 </>}
        </>
    );};

