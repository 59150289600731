import {
    SearchOptions,
} from "@common/crud/trainerAttributeDefinition/model";
import { Grid, Form, DropdownProps, Divider, InputOnChangeData } from "semantic-ui-react";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { eventTypeOptionsSelector } from "@common/crud/eventType/selectors";
import { attributeTypeSelector } from "../selectors";

interface FilterProps {
    searchOptions: SearchOptions;
    onFilterStateChange: (query: SearchOptions) => void;
}

interface Filter {
    filter: JSX.Element;
}

export const Filters: React.FC<FilterProps> = ({ searchOptions, onFilterStateChange: onFilterStateChange }) => {
    const [filterState, setFilterStateOptions] = useState<SearchOptions>(searchOptions);

    const [timerId, setTimerId] = React.useState<any>();
    const [searchString, setSearchString] = React.useState("");
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

    React.useEffect(() => {
        setSearchString(filterState.searchString ?? "");
    }, [filterState.searchString]);

    const eventTypeOptions = useSelector(eventTypeOptionsSelector);
    const attributeTypeOptions = useSelector(attributeTypeSelector);

    const onDropdownPropertyChanged = (prop: keyof (SearchOptions)) => (event: any, { value }: DropdownProps) => onPropertyChanged(prop)(value as any);
    const onPropertyChanged = (prop: keyof (SearchOptions)) => {
        return (value: any) => {
            const prevValue = filterState[prop];

            if (prevValue !== value) {
                const options = { ...filterState, [prop]: value };
                setFilterStateOptions(options);
                onFilterStateChange(options);
            }
        };
    };

    function onSearchChange(_: any, data: InputOnChangeData) {
        setSearchString(data.value);

        if (timerId) {
            clearTimeout(timerId);
        }
        const id = setTimeout(() => onPropertyChanged("searchString")(data.value), 300);
        setTimerId(id);
    }

    function onClearFilters() {
        const clearedSearchOptions = { ...Object.keys(searchOptions)
            .reduce((prevSearchOptions: SearchOptions, key: keyof typeof searchOptions) => {

                const defaultValue: string[] | string | number = Array.isArray(searchOptions[key]) ? [] : undefined;

                return {
                    ...prevSearchOptions,
                    [key]: defaultValue
                };
            }, {}) };

        setFilterStateOptions(clearedSearchOptions);
        onFilterStateChange(clearedSearchOptions);
    }

    function onToggleFiltersVisibility() {
        setFiltersVisible(prevValue => !prevValue);
    }

    const courseDetailsFilters: Filter[] = [{
        filter: (
            <>
                <Form.Dropdown
                    floating
                    selection
                    multiple
                    label="Course details"
                    placeholder="Scheme"
                    value={filterState.eventTypes}
                    options={eventTypeOptions}
                    className="event-type-dropdown"
                    onChange={onDropdownPropertyChanged("eventTypes")}
                />
            </>
        )
    }];

    const complianceFilters: Filter[] = [{
        filter: (
            <>
                <Form.Dropdown
                    floating
                    selection
                    key={filterState.attributeType}
                    label="Compliance"
                    placeholder="Attribute type"
                    value={filterState.attributeType}
                    options={attributeTypeOptions}
                    className="attribute-type-dropdown"
                    onChange={onDropdownPropertyChanged("attributeType")}
                />
            </>
        )
    }];

    function renderFilterRow(filters: Filter[]) {
        return (
            <>
                <Grid.Row>
                    {filters.map((x, i) => (
                        <Grid.Column
                            key={i}
                            width={16}
                            verticalAlign="bottom">
                            {x.filter}
                        </Grid.Column>
                    ))}
                </Grid.Row>

                <Divider />
            </>
        );
    }

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <Form.Input
                            action={{ icon: "search" }}
                            placeholder="Search"
                            value={searchString}
                            onChange={onSearchChange}
                        />
                        <a className={"issue-button float-left"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {filtersVisible &&
                <Grid className="filter-grid" stackable>
                    {renderFilterRow(courseDetailsFilters)}
                    {renderFilterRow(complianceFilters)}
                    <Grid.Row>
                        <Grid.Column width={14}>
                        </Grid.Column>
                        <Grid.Column width={2} verticalAlign="middle">
                            <a className={"issue-button float-right"} onClick={onClearFilters}>Clear filters</a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </>
    );
};
