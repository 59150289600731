/* eslint-disable max-lines */
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { ExtendedDropdown, ExtendedDropdownMulti } from "@common/components/ExtendedDropdown";
import { DelegateRaiseIssueModal } from "../../register/DelegateRaiseIssue";
import "../UpdateDelegatesTable.scss";
import { Media } from "@common/global/AppMedia";
import { EventInstanceAttendeeField } from "@common/crud/eventInstance/model";
import { AttendeeField } from "./AttendeeField";
import { OrganisationApi } from "@common/crud/organisation";
import { Department } from "@common/crud/organisation/model";
import { AttendeeTitle, AttendeeTitleEnum } from "@common/crud/attendee/model";
import { eventInstanceSelector } from "@booking/eventInstance/selectors";
import { ProductCategoryEnum } from "@common/crud/eventType/model";
import { useSelector } from "react-redux";
import { optionsFromObjectIncludingZero } from "@common/crud/common/optionsMappers";
import { MuiDateField } from "@common/components/MuiDateField";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";
import { baseEventManagementSelector } from "@common/crud/eventInstance/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

interface ConstructionDelegateBasicDetailsProps {
    isEventInstanceOpen: boolean;
    organisationId?: string;
    isEventInstanceMultiDay: boolean;
    isEditable: boolean;
    delegate: AttendeeListModel;
    organisationOptions: {
        text: string;
        value: string;
    }[];
    onOrganisationChange: (value: string, valid: boolean) => void;
    onTitleChange: (value: AttendeeTitleEnum) => void;
    onForenameChange: (value: string, valid: boolean) => void;
    isTrainerApp: boolean;
    eventInstanceId: string;
    onSurnameChange: (value: string, valid: boolean) => void;
    onDateOfBirthChange: (value: moment.Moment, valid: boolean) => void;
    onNationalInsuranceNumberChange: (value: string, valid: boolean) => void;
    isStageTwo: boolean;
    hasIssues: boolean;
    disableRow: boolean;
    attendeeFields?: Record<string, EventInstanceAttendeeField[]>;
    departments?: Record<string, Department[]>;
    submitted: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
    canRemoveDelegate: boolean;
    onRemoveClick: (event: React.SyntheticEvent) => void;
    onAttendeeRegisterUpdated: (updatedDelegate: AttendeeListModel) => void;
    onAttendeeFieldValueChange: (value: any, fieldConfiguration: EventInstanceAttendeeField) => void;
    addAttendeeFieldsToLoadedFields: (organisationId: string, fields: EventInstanceAttendeeField[]) => void;
    onDepartmentsChange: (value: string[], valid: boolean) => void;
    addDepartmentsToLoadedDepartments: (organisationId: string, departments: Department[]) => void;
    onDaysChange: (value: string[]) => void;
}

export function ConstructionDelegateBasicDetails({
    isEventInstanceOpen,
    organisationId,
    isEventInstanceMultiDay,
    isEditable,
    delegate,
    organisationOptions,
    onOrganisationChange,
    onTitleChange,
    onForenameChange,
    isTrainerApp,
    eventInstanceId,
    onSurnameChange,
    onDateOfBirthChange,
    onNationalInsuranceNumberChange,
    isStageTwo,
    hasIssues,
    disableRow,
    onAttendeeRegisterUpdated,
    attendeeFields,
    departments,
    submitted,
    canRemoveDelegate,
    onRemoveClick,
    eventInstanceGroupDays,
    onAttendeeFieldValueChange,
    addAttendeeFieldsToLoadedFields,
    onDepartmentsChange,
    addDepartmentsToLoadedDepartments,
    onDaysChange
}:
ConstructionDelegateBasicDetailsProps) {
    const [currentOrganisationAttendeeFields, setCurrentOrganisationAttendeeFields] = React.useState<EventInstanceAttendeeField[]>([]);
    const [currentOrganisationDepartments, setCurrentOrganisationDepartments] = React.useState<{text: string; value: string}[]>([]);

    const baseEventManagementPath = useSelector(baseEventManagementSelector);
    const eventInstance = useSelector(eventInstanceSelector);
    const isCitb = React.useMemo(() => eventInstance?.productCategory === ProductCategoryEnum.StandardCitb, [eventInstance]);
    const isDigital = React.useMemo(() => eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital, [eventInstance]);

    React.useEffect(() => {
        let active = true;

        const getAttendeeFields = async (usedOrganisationId: string) => {
            const organisationApi = new OrganisationApi();
            const fields = await organisationApi.getAttendeeFields(usedOrganisationId, true);
            if (!active) {
                return;
            }
            addAttendeeFieldsToLoadedFields(usedOrganisationId, fields);
            setCurrentOrganisationAttendeeFields(fields);
        };

        const usedOrganisationId = isEventInstanceOpen ? delegate.organisationId: organisationId;
        if (usedOrganisationId) {
            if (attendeeFields && attendeeFields[usedOrganisationId]) {
                setCurrentOrganisationAttendeeFields(attendeeFields[usedOrganisationId]);
            } else {
                getAttendeeFields(usedOrganisationId);
            }
        }
        return () => { active = false; };
    }, [addAttendeeFieldsToLoadedFields, attendeeFields, organisationId, delegate, isEventInstanceOpen]);

    React.useEffect(() => {
        let active = true;

        const getDepartments = async (usedOrganisationId: string) => {
            const organisationApi = new OrganisationApi();
            const organisationDepartments = await organisationApi.getDepartments(usedOrganisationId, true);
            if (!active) {
                return;
            }
            addDepartmentsToLoadedDepartments(usedOrganisationId, organisationDepartments);
            setCurrentOrganisationDepartments(organisationDepartments.map(d => ({ text: d.name, value: d.id })));
        };

        const usedOrganisationId = isEventInstanceOpen ? delegate.organisationId: organisationId;
        if (usedOrganisationId) {
            if (departments && departments[usedOrganisationId]) {
                setCurrentOrganisationDepartments(departments[usedOrganisationId].map(d => ({ text: d.name, value: d.id })));
            } else {
                getDepartments(usedOrganisationId);
            }
        }
        return () => { active = false; };
    }, [addDepartmentsToLoadedDepartments, departments, organisationId, delegate, isEventInstanceOpen]);

    const onComponentLevelDepartmentsChange = React.useCallback((value: string[], valid: boolean) => {
        const departmentsSelected = currentOrganisationDepartments?.filter(d => value.some(v => v === d.value))
            .sort((a, b) => a.text.localeCompare(b.text)).map(d => d.value) || value;
        onDepartmentsChange(departmentsSelected, valid);
    }, [currentOrganisationDepartments, onDepartmentsChange]);

    const dayOptions = React.useMemo(() =>
        eventInstanceGroupDays.map(x => ({ text: `Day ${x.dayNumber}`, value: x.eventInstanceId })),
    [eventInstanceGroupDays]);

    return (
        <Grid>
            {(isEventInstanceMultiDay && isEditable) && (<>
                <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                    <Grid.Row className="purple-header" >
                            Days
                    </Grid.Row>
                </Grid.Column>
                <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                    <Grid.Column>
                        <ExtendedDropdownMulti
                            value={delegate.eventInstanceIds}
                            options={dayOptions}
                            dynamicOptions
                            onChange={onDaysChange}
                            placeholder="Days"
                            multiple
                            required
                            search
                            disableClearable
                            showErrors={submitted}
                        />
                    </Grid.Column>
                </Grid.Row>
            </>)}
            {isEventInstanceOpen && (<>
                <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                    <Grid.Row className="purple-header" >
                            Organisation
                    </Grid.Row>
                </Grid.Column>
                <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                    <Grid.Column>
                        {(isEditable && delegate.notSaved && !delegate.delegateId)
                            ? (
                                <ExtendedDropdown
                                    value={delegate.organisationId}
                                    options={organisationOptions}
                                    onChange={onOrganisationChange}
                                    placeholder="Organisation"
                                    required
                                    search
                                    disableClearable
                                    showErrors={submitted}
                                />
                            ) : (
                                delegate.organisationName && <span className="break-all">{delegate.organisationName}</span>
                            )}
                    </Grid.Column>
                </Grid.Row>
            </>)}
            {(!isEventInstanceOpen && currentOrganisationDepartments && currentOrganisationDepartments.length > 0) && (
                <>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header" >
                            Departments
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column>
                            {isEditable
                                ? (
                                    <Input.DropdownMulti
                                        value={delegate.departments}
                                        options={currentOrganisationDepartments}
                                        dynamicOptions
                                        onChange={onComponentLevelDepartmentsChange}
                                        placeholder="Depts"
                                        multiple
                                        search
                                        showErrors={submitted}
                                    />
                                ) : (
                                    <span className="break-all">{delegate?.departments?.map(id => currentOrganisationDepartments
                                        .find(department => department.value === id)).filter(Boolean)
                                        .map(department => department.text).join(", ") ?? ""}</span>
                                )}
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
            {(isCitb && isDigital) && (
                <><Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                    <Grid.Row className="purple-header">
                        Title
                    </Grid.Row>
                </Grid.Column><Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                    <Grid.Column>
                        {isEditable
                            ? <Input.DropdownNumber
                                value={delegate.title}
                                onChange={(value, _) => onTitleChange(value)}
                                placeholder={"Title"}
                                options={optionsFromObjectIncludingZero(AttendeeTitle)}
                                disabled={isEventInstanceOpen && !delegate.organisationId}
                                showErrors={submitted} />
                            : (delegate.title !== null && delegate.title !== undefined)
                                ? <span className="break-all">{AttendeeTitle[delegate.title]}</span>
                                : <Icon className="validation-icon" name={"exclamation circle"} />}
                    </Grid.Column>
                </Grid.Row></>)}
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header" >
                    Forename
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column>
                    {isEditable
                        ? <Input.Text
                            value={delegate.forename ?? ""}
                            onChange={onForenameChange}
                            placeholder={"Forename"}
                            disabled={isEventInstanceOpen && !delegate.organisationId}
                            required
                            showErrors={submitted}
                        />
                        : delegate.forename
                            ? (delegate.anonymized || delegate.notSaved || isTrainerApp)
                                ? <span className="break-all">{delegate.forename}</span>
                                : <a target="_blank" rel="noreferrer" href={delegate.delegateId
                                    ? `/construction-event-management/organisations/${delegate.organisationId}`
                                + `/delegates/${delegate.delegateId}?attendeeId=${delegate.id}`
                                    : `/construction-event-management/eventInstances/${eventInstanceId}`
                                + `/delegates/${delegate.id}`}>
                                    <span className="break-all">{delegate.forename}</span>
                                </a>
                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                </Grid.Column>
            </Grid.Row>
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header" >
                    Surname
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column>
                    {isEditable
                        ? <Input.Text
                            value={delegate.surname ?? ""}
                            onChange={onSurnameChange}
                            placeholder={"Surname"}
                            disabled={isEventInstanceOpen && !delegate.organisationId}
                            required
                            showErrors={submitted}
                        />
                        : delegate.surname
                            ? (delegate.anonymized || delegate.notSaved || isTrainerApp)
                                ? <span className="break-all">{delegate.surname}</span>
                                : <a target="_blank" rel="noreferrer" href={delegate.delegateId
                                    ? `/construction-event-management/organisations/${delegate.organisationId}`
                                + `/delegates/${delegate.delegateId}?attendeeId=${delegate.id}`
                                    : `/construction-event-management/eventInstances/${eventInstanceId}`
                                + `/delegates/${delegate.id}`}>
                                    <span className="break-all">{delegate.surname}</span>
                                </a>
                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                </Grid.Column>
            </Grid.Row>
            {(isCitb && isDigital) && (
                <>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header">
                            Date of Birth
                        </Grid.Row>
                    </Grid.Column><Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column>
                            {isEditable
                                ? <MuiDateField
                                    value={delegate.dateOfBirth}
                                    placeholder="Date of Birth"
                                    onChange={onDateOfBirthChange}
                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                    showErrors={submitted} />
                                : delegate.dateOfBirth
                                    ? <span>
                                        {delegate.dateOfBirth ? delegate.dateOfBirth.format("DD/MM/YYYY") : ""}
                                    </span>
                                    : <Icon className="validation-icon" name={"exclamation circle"} />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header">
                            National Insurance Number
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column>
                            {isEditable
                                ? <Input.Text
                                    value={delegate.nationalInsuranceNumber ?? ""}
                                    onChange={onNationalInsuranceNumberChange}
                                    placeholder={"National Insurance Number"}
                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                    showErrors={submitted}
                                />
                                : delegate.nationalInsuranceNumber
                                    ? <span className="break-all">{delegate.nationalInsuranceNumber}</span>
                                    : <Icon className="validation-icon" name={"exclamation circle"} />}
                        </Grid.Column>
                    </Grid.Row>
                </>)}
            {!isTrainerApp && delegate.correlatedOrderId && (
                <>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header" >
                            Order ID
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column>
                            <a target="_blank" rel="noreferrer" href={`${baseEventManagementPath}/orders/${delegate.correlatedOrderId}`}>
                                {delegate.bookingReference}
                            </a>
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
            {!isTrainerApp && currentOrganisationAttendeeFields?.map(fieldConfiguration =>
                (<AttendeeField
                    key={fieldConfiguration.fieldId}
                    fieldConfiguration={fieldConfiguration}
                    fieldValues={delegate.attendeeFieldValues}
                    isEditable={isEditable}
                    fieldsForMarkup={[]}
                    onAttendeeFieldValueChange={onAttendeeFieldValueChange}
                />))}
            {canRemoveDelegate && (
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="button"
                            onClick={onRemoveClick}
                            content={<span><Icon name="minus circle" />Remove delegate</span>}
                            className="link-button adjust-link-button-size"
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            {isStageTwo && !isEditable && !delegate.isBookingCanceled && (
                <Media greaterThanOrEqual="computer">
                    <Grid.Row>
                        <Grid.Column>
                            <DelegateRaiseIssueModal
                                hasIssues={hasIssues}
                                attendee={delegate}
                                disabled={disableRow}
                                onAttendeeRegisterUpdated={onAttendeeRegisterUpdated} />
                        </Grid.Column>
                    </Grid.Row>
                </Media>
            )}
        </Grid>);
};
