import * as React from "react";
import { CarType } from "@common/crud/eventInstance/model";

interface DisplayTrainerCarTypeProps {
    carType: CarType;
    height?: number | string | undefined;
    width?: number | string | undefined;
}

export function DisplayTrainerCarType({ carType, height = undefined, width = undefined }: DisplayTrainerCarTypeProps) {
    return <img height={height} width={width} src={`/assets/${carType}.png`} alt={`Car Type: ${carType}`} />;
}
