import * as React from "react";
import moment from "moment";
import { Detail } from "@neworbit/simpleui-detail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { EventTypePart } from "@common/crud/eventType/model";
import { Grid } from "semantic-ui-react";

export interface DisplayDisplayEventTypePartsProps {
    dayParts: Dictionary<EventTypePart>;
}

export const DisplayEventTypeParts: React.FC<DisplayDisplayEventTypePartsProps> = ({ dayParts }) => {
    return (
        <>
            {dayParts ?
                Object.entries(dayParts).map(
                    ([key, value]) => {
                        const label = `Start time for Day ${key}`;
                        const startTime = moment.utc(value.suggestedStartTime.asMilliseconds()).format(DateFormat.TimeFormat12HoursFormat);
                        const duration = value.eventDuration.format(DateFormat.Time, { trim: false });
                        const registrationDuration = value.registrationDuration.format(DateFormat.Time, { trim: false });
                        return (
                            <Grid container stackable className="nomargintop" key={key}>
                                <Grid.Row>
                                    <Grid.Column width={6} >
                                        <Detail
                                            label={label}
                                            value={startTime}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Detail
                                            label="Duration"
                                            value={duration}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Detail
                                            label="Registration Duration"
                                            value={registrationDuration}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        );
                    }) :
                <Detail
                    label="Days Structures"
                    value="Not set"
                    labelWidth={5}
                    valueWidth={11}
                />
            }
        </>
    );
};
