import { Trainer } from "@common/crud/trainer";
import axios from "axios";
import moment from "moment";
import { MonitorTrainerAvailabilityForDay, RegionalCoordinatorCalendarDay, RegionalCoordinatorCalendar, RegionalCoordinatorCalendarSettings } from "./model";
import { parseEventInstanceDetailModel } from "@common/crud/eventInstance/helpers";

export class RegionalCoordinatorApi {

    private apiUrl = "/api/regionalCoordinator";

    public async getTrainerMonitoringForMonth(month: moment.Moment): Promise<RegionalCoordinatorCalendar> {
        const result = await axios.get<RegionalCoordinatorCalendar>(`${this.apiUrl}/${month.toISOString()}`);
        return RegionalCoordinatorApi.parseRegionalCoordinatorCalendar(result.data);
    }

    public async getAllMonitorTrainers(): Promise<Trainer[]> {
        const result = axios.get<Trainer[]>(`${this.apiUrl}/monitorTrainers`);
        return (await result).data;
    }

    public async getMonitorTrainersForDay(day: moment.Moment): Promise<MonitorTrainerAvailabilityForDay[]> {
        const result = await axios.get<MonitorTrainerAvailabilityForDay[]>(`${this.apiUrl}/monitorTrainers/${day.toISOString()}`);
        return result.data;
    }

    public async saveChangesToCalendar(month: moment.Moment, changes: RegionalCoordinatorCalendarDay[]): Promise<RegionalCoordinatorCalendarDay[]> {
        const result = await axios.patch<RegionalCoordinatorCalendarDay[]>(`${this.apiUrl}/${month.toISOString()}`, changes);
        return result.data.map(RegionalCoordinatorApi.parseRegionalCoordinatorCalendarDay);
    }

    public async getRcCoverTrainerIds(date: moment.Moment, trainerIds: string): Promise<string[]> {
        const result = axios.get<string[]>(`${this.apiUrl}/rc-cover-trainers?date=${date.toISOString()}&trainerIds=${trainerIds}`);
        return (await result).data;
    }

    public async getRcCoverTrainersBookedForEventInstance(date: moment.Moment, trainerIds: string[]): Promise<string[]> {
        const result = axios.get<string[]>(`${this.apiUrl}/rc-cover-trainers-event-instance?date=${date.toISOString()}&trainerIds=${trainerIds}`);
        return (await result).data;
    }

    public async getRegionalCoordinatorCalendarSettings(): Promise<RegionalCoordinatorCalendarSettings> {
        const response = await axios.get(`${this.apiUrl}/getRegionalCoordinatorCalendarSettings`);
        const data = response.data as RegionalCoordinatorCalendarSettings;
        return data;
    }
    public async saveRegionalCoordinatorCalendarSettings(regionalCoordinatorCalendarSettings: RegionalCoordinatorCalendarSettings): Promise<void> {
        await axios.post(`${this.apiUrl}/saveRegionalCoordinatorCalendarSettings`, regionalCoordinatorCalendarSettings);
    }

    public static parseRegionalCoordinatorCalendar(calendar: RegionalCoordinatorCalendar): RegionalCoordinatorCalendar {
        return {
            ...calendar,
            month: moment(calendar.month),
            days: calendar.days.map(RegionalCoordinatorApi.parseRegionalCoordinatorCalendarDay)
        };
    }

    public static parseRegionalCoordinatorCalendarDay(day: RegionalCoordinatorCalendarDay): RegionalCoordinatorCalendarDay {
        return {
            ...day,
            date: moment(day.date),
            monitoredEventsOnDay: day.monitoredEventsOnDay.map(parseEventInstanceDetailModel)
        };
    }
}
