/* eslint-disable max-lines */
import axios, { toFormData } from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import {
    TrainerListModel,
    TrainerDetailModel,
    TrainerCreateEditModel,
    TrainerDashboardModel,
    SearchOptions,
    TrainerLicencesMonitoringModel,
    TrainerAttributeUpdateModel,
    TrainerDetailUpdateModel,
    BulkFreeTypeCommsModel,
    BulkEmailTemplateCommsModel,
    TrainerJourneyNoteModel,
    TrainerWithAttributeModel,
    ADTrainerUser,
    OptSchemeTrainerModel,
    EditNdorsLicenceNumberModel,
    TrainerBankAccountCreateEditModel,
    EventInstanceCountForAttributeModel,
    EditNriLicenceNumberModel
} from "./model";
import { parseEventInstanceDetailModel } from "@common/crud/eventInstance/helpers";
import { EventTypeCategory } from "../attendee/model";
import { TrainerType } from "../eventInstance/model";

export class TrainerApi {

    private readonly apiUrl = "/api/trainer";

    public async getAll(options?: SearchOptions): Promise<TrainerListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as TrainerListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<TrainerDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public async save(trainer: TrainerCreateEditModel): Promise<TrainerDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${trainer.id}`, trainer);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public async saveBankAccount(trainerBankAccount: TrainerBankAccountCreateEditModel): Promise<TrainerDetailModel> {
        const response = await axios.put(`${this.apiUrl}/bank-account`, trainerBankAccount);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public async create(trainer: TrainerCreateEditModel): Promise<TrainerDetailModel> {

        const response = await axios.post(this.apiUrl, trainer);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public delete(id: string): Promise<unknown> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public async getProfileDetail(): Promise<TrainerDetailModel> {
        const response = await axios.get(`${this.apiUrl}`);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public async saveProfileDetail(trainer: TrainerCreateEditModel): Promise<TrainerDetailModel> {
        const response = await axios.put(`${this.apiUrl}`, trainer);
        const model = response.data as TrainerDetailModel;
        return this.parseModel(model);
    }

    public async findByEmail(email: string): Promise<ADTrainerUser> {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get(`${this.apiUrl}/findbyemail?email=${encodedEmail}`);
        const model = response.data as ADTrainerUser;
        return model && this.parseTrainerWithADB2CDateModel(model);
    }

    public async addNoteToTrainer(trainerId: string, note: string, recordAsComplaint: boolean = false, file?: File) {
        const formData = new FormData();
        formData.append("note", note);
        formData.append("recordAsComplaint", recordAsComplaint.toString());
        formData.append("file", file);
        const response = await axios.post<TrainerJourneyNoteModel>(
            `${this.apiUrl}/${trainerId}/add-note`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        return response.data;
    }

    public async getDigitalTrainers(
        month: moment.Moment, trainerId: string, recalculate: boolean, eventTypeCategory: EventTypeCategory, previousTrainerId: string = null):
        Promise<TrainerListModel[]> {
        const url = `${this.apiUrl}/digitalTrainers?month=${month.toISOString()}&trainerId=${trainerId}&recalculate=${recalculate}` +
            `&eventTypeCategory=${eventTypeCategory}&previousTrainerId=${previousTrainerId}`;
        const response = await axios.get(url);
        return response.data.map(this.parseModel);
    }

    public async getTrainersHaveAttributeForType(trainerIds: string[], eventTypeId: string, trainerType: TrainerType, isDigital: boolean):
        Promise<Record<string, boolean>> {
        const query = QueryString.stringify({ trainerIds, eventTypeId, trainerType, isDigital });
        const response = await axios.get(`${this.apiUrl}/hasAttributes?${query}`);
        return response.data;
    }

    public sendSignupInvitation(id: string) {
        return axios.post(`${this.apiUrl}/${id}/signupinvitation`);
    }

    public sendBulkFreeTypeComms(bulkFreeTypeCommsModel: BulkFreeTypeCommsModel) {
        const formData = toFormData(bulkFreeTypeCommsModel);
        return axios.post(`${this.apiUrl}/send-bulk-comms`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } });
    }

    public sendBulkEmailTemplateComms(bulkEmailTemplateCommsModel: BulkEmailTemplateCommsModel) {
        return axios.post(`${this.apiUrl}/send-template-comms`, bulkEmailTemplateCommsModel);
    }

    public async loadDashboardInformation(): Promise<TrainerDashboardModel> {
        const response = await axios.get(`${this.apiUrl}/loadDashboardInformation`);
        const data = response.data as TrainerDashboardModel;
        if (data.nextCourse) {
            data.nextCourse = parseEventInstanceDetailModel(data.nextCourse);
        }
        data.outstandingCourseRegisters = data.outstandingCourseRegisters.map(parseEventInstanceDetailModel);
        data.coursesThisWeek = data.coursesThisWeek.map(parseEventInstanceDetailModel);
        return response.data;
    }

    public async loadAverageDaysWorked(trainerId: string): Promise<number> {
        const response = await axios.get<number>(`${this.apiUrl}/${trainerId}/averageDaysWorked`);
        return response.data;
    }

    public async getTrainersWithAttribute(attributeDefinitionId: string) {
        const response = await axios.get(`${this.apiUrl}/trainers-with-attribute?id=${attributeDefinitionId}`);
        return response.data.map(this.parseTrainerWithAttributeModel, this);
    }

    public async saveOptSchemeTrainerAttributes(model: OptSchemeTrainerModel): Promise<TrainerDetailModel> {
        const response = await axios.put(`${this.apiUrl}/save-opt-scheme-trainer-attributes`, {
            trainerId: model.trainerId,
            isDigital: model.isDigital,
            optSchemeTrainerAttributes: model.optSchemeTrainerAttributes
        });
        return response === null ? null : this.parseModel(response.data as TrainerDetailModel);
    }

    public async getDefaultSenderAddress(): Promise<string> {
        const response = await axios.get<string>(`${this.apiUrl}/default-sender-address`);
        return response.data;
    }

    public async getEventInstanceCountForAttributes(trainerId: string, attributeIds: string[]): Promise<EventInstanceCountForAttributeModel[]> {
        const query = QueryString.stringify({ attributeIds });
        const url = `${this.apiUrl}/${trainerId}/event-instance-count-for-attributes?${query}`;
        const response = await axios.get(url);

        return response.data as EventInstanceCountForAttributeModel[];
    }

    public parseModel(model: TrainerDetailModel): TrainerDetailModel {
        return {
            ...model,
            lastLogin: model.lastLogin && moment(model.lastLogin),
            lastContinuumAttributesSyncDateTime: model.lastContinuumAttributesSyncDateTime && moment(model.lastContinuumAttributesSyncDateTime),
            expiryDate: model.expiryDate && moment(model.expiryDate),
            deletionDate: model.deletionDate && moment(model.deletionDate),
            history: model.history?.map(h => ({
                ...h, dateCreated: moment(h.dateCreated),
                pendingDate: h.pendingDate && moment(h.pendingDate),
                completionDate: h.completionDate && moment(h.completionDate),
                correlationId: model.id
            })),
            trainerAttributes: model.trainerAttributes && model.trainerAttributes.map(trainerAttr => ({
                ...trainerAttr,
                expiryDate: trainerAttr.expiryDate && moment(trainerAttr.expiryDate),
                lastMonitoredDate: trainerAttr.lastMonitoredDate && moment(trainerAttr.lastMonitoredDate),
                nextMonitoringDue: trainerAttr.nextMonitoringDue && moment(trainerAttr.nextMonitoringDue),
                nextMonitoringSessionPlanned: trainerAttr.nextMonitoringSessionPlanned && moment(trainerAttr.nextMonitoringSessionPlanned),
                lastDigitalMonitoredDate: trainerAttr.lastDigitalMonitoredDate && moment(trainerAttr.lastDigitalMonitoredDate),
                nextDigitalMonitoringDue: trainerAttr.nextDigitalMonitoringDue && moment(trainerAttr.nextDigitalMonitoringDue),
                nextDigitalMonitoringSessionPlanned: trainerAttr.nextDigitalMonitoringSessionPlanned && moment(trainerAttr.nextDigitalMonitoringSessionPlanned),
            }))
        };
    }

    public parseTrainerWithAttributeModel(model: TrainerWithAttributeModel): TrainerWithAttributeModel {
        return {
            ...model,
            trainer: this.parseModel(model.trainer),
            attributeHeldSince: model.attributeHeldSince,
        };
    }

    public async loadMonitorPlanner(options?: SearchOptions): Promise<TrainerLicencesMonitoringModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}/licence-monitoring?${query}`);
        const data = response.data as TrainerLicencesMonitoringModel[];
        return data.map(this.parsePlannerModel);
    }

    public parsePlannerModel(model: TrainerLicencesMonitoringModel): TrainerLicencesMonitoringModel {
        return {
            ...model,
            licenceMonitoringSchemeModels: model.licenceMonitoringSchemeModels && model.licenceMonitoringSchemeModels.map(trainerAttr => ({
                ...trainerAttr,
                expiryDate: trainerAttr.expiryDate && moment(trainerAttr.expiryDate),
                lastMonitored: trainerAttr.lastMonitored && moment(trainerAttr.lastMonitored),
                nextMonitoringDue: trainerAttr.nextMonitoringDue && moment(trainerAttr.nextMonitoringDue),
                nextMonitoringSession: trainerAttr.nextMonitoringSession && moment(trainerAttr.nextMonitoringSession),
                lastDigitalMonitored: trainerAttr.lastDigitalMonitored && moment(trainerAttr.lastDigitalMonitored),
                nextDigitalMonitoringDue: trainerAttr.nextDigitalMonitoringDue && moment(trainerAttr.nextDigitalMonitoringDue),
                nextDigitalMonitoringSession: trainerAttr.nextDigitalMonitoringSession && moment(trainerAttr.nextDigitalMonitoringSession),
                dateCreated: trainerAttr.dateCreated && moment(trainerAttr.dateCreated)
            }))
        };
    }

    public parseTrainerWithADB2CDateModel(model: ADTrainerUser): ADTrainerUser {
        return {
            ...model,
            adB2CAccountCreated: model.adB2CAccountCreated && moment(model.adB2CAccountCreated),
        };
    }

    public async saveTrainerAttributeMonitoringUpdate(model: TrainerAttributeUpdateModel): Promise<TrainerDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${model.id}/update-monitoring/`, model);
        const result = response.data as TrainerDetailModel;
        return this.parseModel(result);
    }

    public async updateTrainerNdorsLicenceNumber(trainerId: string, model: EditNdorsLicenceNumberModel): Promise<TrainerDetailUpdateModel> {
        const response = await axios.put(`${this.apiUrl}/${trainerId}/update-ndors-licence-number/`, model);
        const result = response.data as TrainerDetailUpdateModel;
        const parsedModel = this.parseModel(result.trainer);

        return {
            ...result,
            trainer: parsedModel
        };
    }

    public async updateTrainerNriLicenceNumber(trainerId: string, model: EditNriLicenceNumberModel): Promise<TrainerDetailUpdateModel> {
        const response = await axios.put(`${this.apiUrl}/${trainerId}/update-nri-licence-number/`, model);
        const result = response.data as TrainerDetailUpdateModel;
        const parsedModel = this.parseModel(result.trainer);

        return {
            ...result,
            trainer: parsedModel
        };
    }

    public async synchronizeTrainerDors(trainerId: string): Promise<TrainerDetailUpdateModel> {
        const response = await axios.get(`${this.apiUrl}/${trainerId}/synchronize-dors/`);
        const result = response.data as TrainerDetailUpdateModel;
        const parsedModel = this.parseModel(result.trainer);

        return {
            ...result,
            trainer: parsedModel
        };
    }

    public async checkIfTrainerIsAllocated(trainerId: string, contextEventInstanceId: string, deliveryDateTime: moment.Moment,
        deliveryDateTimeEnd: moment.Moment): Promise<boolean> {
        const model = { trainerId, contextEventInstanceId, deliveryDateTime: deliveryDateTime.toISOString(),
            deliveryDateTimeEnd: deliveryDateTimeEnd.toISOString() };
        const response = await axios.post(`${this.apiUrl}/is-trainer-allocated`, model);
        return response.data as boolean;
    }

    public async checkIfTrainerIsAllocatedOutsideGroup(
        groupId: string, trainerId: string, deliveryDateTime: moment.Moment, deliveryDateTimeEnd: moment.Moment): Promise<boolean> {
        const query =
            // eslint-disable-next-line max-len
            `groupId=${groupId}&trainerId=${trainerId}&deliveryDateTime=${deliveryDateTime.toISOString()}&deliveryDateTimeEnd=${deliveryDateTimeEnd.toISOString()}`;
        const response = await axios.get(`${this.apiUrl}/is-trainer-allocated-outside-group?${query}`);
        return response.data as boolean;
    }

    public async checkTrainerNdorsLicenceValid(ndorsLicenceNumber: string, surname: string) {
        const query = `ndorsLicenceNumber=${ndorsLicenceNumber}&surname=${surname}`;
        const response = await axios.get(`${this.apiUrl}/check-trainer-ndors-licence-valid?${query}`);
        return response.data as boolean;
    }

    public async updateTrainerNote(id: string, noteId: string, noteText: string, removeAttachment: boolean): Promise<void> {
        await axios.patch(`${this.apiUrl}/${id}/updateTrainerNote`, { noteId, noteText, removeAttachment });
    }
}
