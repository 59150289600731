import * as React from "react";
import { EmailApi } from "@common/email/emailApi";
import { Container, Modal, Button } from "semantic-ui-react";

export const EmailDetailsViewer: React.FC<{ emailMessageId: string; emailTemplateName: string; emailFrom?: string }>
    = ({ emailMessageId, emailTemplateName, emailFrom }) => {
        const [showDetails, setShowDetails] = React.useState<boolean>(false);
        const [emailUri, setEmailUri] = React.useState<string>();
        const [loading, setLoading] = React.useState<boolean>(false);

        React.useEffect(() => {
            async function getEmailUri() {

                if (emailMessageId && showDetails) {
                    setLoading(true);
                    const emailApi = new EmailApi();
                    const response = await emailApi.getEmailUri(emailMessageId);

                    if (response.success && response.result) {
                        setEmailUri(response.result);
                    }

                    setLoading(false);
                }
            }

            getEmailUri();
        }, [emailMessageId, showDetails]);

        const onShowDetailsClick = React.useCallback(() => {
            setShowDetails(!showDetails);
        }, [showDetails]);

        if (!emailMessageId) {
            return null;
        }

        return (
            <div>
                <a className="cursor-pointer" onClick={onShowDetailsClick}>Show Details</a>
                <Modal open={showDetails}>
                    <Modal.Header>Email details - {emailTemplateName}{emailFrom ? ` - sent from ${emailFrom}` : ""}</Modal.Header>
                    <Modal.Content>
                        <Container>
                            {emailUri && <iframe src={emailUri} title="Email Details" className="message-details" />}
                            {!emailUri && !loading && <span>Email isn't available yet. Please try again later.</span>}
                            {loading && <span>Loading email details. Please wait.</span>}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className="cancel-action" onClick={onShowDetailsClick}>Close</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    };
