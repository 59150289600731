import { AttendeeRole, TtcClientAdvisorRole } from "@common/auth/model";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { loadEventInstanceDetailsForBooking } from "../eventInstance/actions";
import { loadBooking, loadBookingForNewSession } from "./actions";

export const BookingRoutes = {
    Rebook: "/rebook",
    EventInstanceDetails: "/:eventInstanceId",
    ReviewPayment: `/${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`,
    ManualPayment: `/${PaymentPageNames[PaymentPageNamesEnum.ManualPayment]}`,
    BookingDetails: "/booking-details",
    BookingConfirmation: "/booking-confirmation",
    Dashboard: "/dashboard",
    CaRedirect: "/ca-redirect",
    PaymentConfirmation: "/payment-confirmation",
    Landing: "/landing",
    ReservationError: "/reservation-error",
    DorsUpdateError: "/dors-update-error",
    GenesysAuthorisation: `/${PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]}`,
};

const bookingRoutes = {
    [BookingRoutes.Rebook]: {
        title: "Rebook",
    },
    [BookingRoutes.EventInstanceDetails]: {
        resolve: [loadEventInstanceDetailsForBooking, loadBooking],
        title: "Course details",
        [BookingRoutes.ReviewPayment]: {
            title: "Review Payment"
        },
        [BookingRoutes.ManualPayment]: {
            title: "Manual Payment"
        },
        [BookingRoutes.BookingDetails]: {
            title: "Booking Details"
        },
        [BookingRoutes.GenesysAuthorisation]: {
            title: "Genesys Authorisation",
        }
    },
    [BookingRoutes.BookingConfirmation]: {
        resolve: [loadBooking],
        title: "Booking Confirmation"
    },
    [BookingRoutes.Dashboard]: {
        resolve: [loadBooking],
        title: "Dashboard"
    },
    [BookingRoutes.CaRedirect]: {
        resolve: [loadBooking],
        title: "Redirect Page"
    },
    [BookingRoutes.ReservationError]: {
        title: "Reservation Error"
    },
    [BookingRoutes.PaymentConfirmation]: {
        resolve: [loadBooking],
        title: "Payment Confirmation"
    },
    [BookingRoutes.DorsUpdateError]: {
        title: "DORS update error",
    },
};

const routes: any = {
    [BookingRoutes.Landing]: {
        title: "Welcome",
        authorize: [AttendeeRole, TtcClientAdvisorRole],
        resolve: [() => loadBookingForNewSession(true)],
        ignoreParentResolve: true,
        ...bookingRoutes
    }
};

export { routes, bookingRoutes };
