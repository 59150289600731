import * as React from "react";
import classes from "./RebookSignIn.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, FieldErrors, useFormContext } from "react-hook-form";
import { formSchema } from "./SignInValidation";
import { ErrorMessage } from "@common/components/ErrorMessage";
import { InputField } from "@booking/common/InputField";
import { Button } from "@booking/common/Button";
import { OtpField } from "@booking/common/OtpField";
import { AppCommonState } from "@common/appCommonState";
import { useSelector } from "react-redux";
import { RebookSignInApi } from "./rebookSignInApi";
import {
    RebookSignInCodeValidationResult,
    RebookSignInProgress,
    RebookSignInStatus,
    RebookingSignInTranslationKeys,
    messageForValidationResult } from "./model";
import { Trans, useTranslation } from "react-i18next";
import { ApplicationOwnState } from "@booking/applicationState";
import { i18n } from "i18next";
import { Icon, Popup } from "semantic-ui-react";

type FormType = {
    email: string;
    bookingReference: string;
    postcode: string;
    verificationStatus: RebookSignInStatus;
    code: string;
};

interface ActionSectionProps {
    status: RebookSignInStatus;
    errors: FieldErrors<FormType>;
    validationMessage: string;
    submitSignInDetails: (values: FormType) => void;
}

export const RebookSignIn = () => {
    const [validationState, setValidationState] = React.useState<RebookSignInProgress>(null);
    const currentLanguage = useSelector((state: ApplicationOwnState) => state.language.current);
    const defaultValues: FormType = React.useMemo(() => ({
        email: "",
        bookingReference: "",
        postcode: "",
        verificationStatus: "initial",
        code: ""
    }),[]);

    const translationConfig = useTranslation("RebookingSignIn");
    const [t]: [t: (key: RebookingSignInTranslationKeys) => string, i18n: i18n, ready: boolean] = translationConfig;

    const formControls = useForm({
        resolver: yupResolver(formSchema(translationConfig)),
        defaultValues,
    });

    const { handleSubmit, watch, reset, getValues, setValue, trigger, formState: { errors } } = formControls;

    const verificationStatus = watch("verificationStatus");
    const anyErrors = React.useMemo(() => Object.values(formControls.formState.errors).map(e => e?.message).some(e => e),[formControls.formState.errors]);

    const validationMessage = messageForValidationResult(validationState, translationConfig);

    React.useEffect(() => {
        // translate any errors when language changes
        if (anyErrors) {
            trigger();
        }
    },[currentLanguage, anyErrors, trigger]);

    //  React Hook form recommends to reset in useEffect rather than submit handler as execution order matters
    React.useEffect(function resetDirtyStateOnChangeFormStage() {
        if (formControls.formState.isSubmitSuccessful) {
            reset({}, { keepValues: true });
        };
    },[defaultValues, formControls.formState, getValues, reset, verificationStatus]);

    function additionalOnChange() {
        // if email or booking reference changes, reset the to first stage of form
        if (validationMessage || getValues("verificationStatus") !== "initial") {
            reset({ ...getValues(), verificationStatus: "initial", code: "" });
            setValidationState(null);
        }
    }

    async function submitSignInDetails(submittedValues: FormType) {
        if (getValues("verificationStatus") === "providingPassword") {
            setValue("code", "");
        }

        setValue("verificationStatus", "checkingDetails");
        const api = new RebookSignInApi();
        const response = await api.signInGenerateOtp(submittedValues.email, submittedValues.bookingReference, submittedValues.postcode);
        setValidationState(response);

        switch (response.validationResult) {
            case RebookSignInCodeValidationResult.Locked:
            case RebookSignInCodeValidationResult.AccountNotFound:
                setValue("verificationStatus", "initial");
                return;
            case RebookSignInCodeValidationResult.NotChecked:
                setValue("verificationStatus", "providingPassword");
                return;
            default:
                return;
        }
    }

    async function submitOneTimePassword(submittedValues: FormType) {
        setValue("verificationStatus", "verifying");
        const api = new RebookSignInApi();
        const response = await api.signInValidateOtp(submittedValues.email, submittedValues.code, submittedValues.bookingReference, submittedValues.postcode);
        setValidationState(response);

        switch (response.validationResult) {
            case RebookSignInCodeValidationResult.Locked:
                setValue("verificationStatus", "initial");
                return;
            case RebookSignInCodeValidationResult.Invalid:
            case RebookSignInCodeValidationResult.Superseded:
            case RebookSignInCodeValidationResult.NewEmailSent:
                setValue("verificationStatus", "providingPassword");
                return;
            case RebookSignInCodeValidationResult.SignedIn:
                setValue("verificationStatus", "verified");
                window.location.href = response.transferUrl;
                return;
            default:
                setValue("verificationStatus", "providingPassword");
                return;
        }
    }

    async function onSubmit(submittedValues: FormType) {
        switch (verificationStatus) {
            case "initial":
                return submitSignInDetails(submittedValues);
            case "providingPassword":
                return submitOneTimePassword(submittedValues);
            default:
                return;
        }
    }

    return  (
        <div>
            <div className={classes.formContainer}>
                <FormProvider {...formControls}>
                    <form className={classes.parentForm} onSubmit={handleSubmit(onSubmit)}>
                        <h1 className={classes.header}>
                            {t("HEADER_TEXT")}
                        </h1>
                        <div>
                            <p className={classes.asteriskLegend}>
                                <Trans i18nKey="RebookingSignIn:ASTERISK_LEGEND">
                                    (<span className={classes.asterisk}>*</span>) Indicates a required field.
                                </Trans>
                            </p>
                        </div>
                        <div className={classes.fieldsContainer}>
                            <InputField
                                name={"email"}
                                error={errors?.email}
                                className={classes.textInput}
                                placeholder={t("EMAIL_ADDRESS")}
                                additionalOnChange={additionalOnChange}
                                isRequired />
                            <InputField
                                name={"bookingReference"}
                                error={errors?.bookingReference}
                                className={classes.textInput}
                                placeholder={t("BOOKING_REFERENCE")}
                                additionalOnChange={additionalOnChange}
                                icon={<Popup content={t("BOOKING_REF_TOOLTIP")}
                                    trigger={
                                        <Icon name="info circle" color="blue" />
                                    }
                                    position='top left'
                                    offset={[-10, 0]}  />}
                                isRequired />
                            <InputField
                                name={"postcode"}
                                error={errors?.postcode}
                                className={classes.textInput}
                                placeholder={t("POSTCODE")}
                                additionalOnChange={additionalOnChange}
                                isRequired />
                        </div>
                        <ActionSection
                            status={verificationStatus}
                            errors={errors}
                            validationMessage={validationMessage}
                            submitSignInDetails={submitSignInDetails} />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

function ActionSection({  status, errors, validationMessage, submitSignInDetails }: ActionSectionProps)
{
    const externalResourcesSettings = useSelector((state: AppCommonState) => state.appSettings.externalResourcesSettings);
    const { getValues } = useFormContext<FormType>();

    const translationConfig = useTranslation("RebookingSignIn");
    const [t]: [t: (key: RebookingSignInTranslationKeys) => string, i18n: i18n, ready: boolean] = translationConfig;

    function onResend() {
        submitSignInDetails({ ...getValues() });
    }

    if (status === "verified") {
        return <p>{t("TRANSFERRING")}</p>;
    }

    if (status === "initial" || status === "checkingDetails")
    {
        return (<>
            <p>{t("OTP_INITIAL_TEXT")}</p>
            <Button
                isLoading={status === "checkingDetails"}
                type="submit"
                variant={"primary"}
                loadingPlaceholder={t("SUBMITTING")}
                className={classes.sendCodeButton}>
                {t("SEND_CODE")}
            </Button>
            <ErrorMessage error={validationMessage} />
            <a className={classes.newCustomerLink}
                target="_blank"
                rel="noreferrer"
                href={externalResourcesSettings.dorsPortalLink}>
                {t("NEW_CUSTOMER")}
            </a>
        </>);
    }

    return (<>
        <div className={classes.otp}>{t("OTP")}</div>
        <p>{t("RESEND_MESSAGE")}&nbsp;
            <a className={classes.resendLink} onClick={onResend}>{t("CLICK_HERE")}</a>
        </p>
        <div className={classes.otpForm}>
            <div className={classes.otpContainer}>
                <OtpField name="code" />
            </div>
            <Button
                className={classes.verifyButton}
                loadingPlaceholder={t("VERIFYING")}
                isLoading={status === "verifying"}
                type="submit"
                variant="primary">{t("VERIFY")}</Button>
        </div>
        <div>
            <ErrorMessage error={validationMessage} />
            <ErrorMessage error={errors?.code?.message} />
        </div>
        <div>{t("CHECK_SPAM_FOLDER")}</div>
    </>);
}
