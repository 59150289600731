import { drinkDriveOffenderSelector } from "@common/crud/drinkDriveOffenders/selectors";
import { detailRoutes } from "./detailRoutes";
import { AppState } from "@common/crud/drinkDriveOffenders/model";
import { loadDrinkDriveOffenderDetail, loadDrinkDriveOffenders } from "@common/crud/drinkDriveOffenders/actions";
import { loadCourts, loadOrganisations } from "../organisation/actions";
import { loadEventTypes } from "../eventType";

export const DrinkDriveOffenderRoutes = {
    DrinkDriveOffenderDetails: "/:drinkDriveOffenderId",
    Edit: "/edit",
    DrinkDriveOffenders: "/drinkDriveOffenders",
    Create: "/create"
};

const detailRoute = () => ({
    [DrinkDriveOffenderRoutes.DrinkDriveOffenderDetails]: {
        title: (state: AppState) => drinkDriveOffenderSelector(state).fullName,
        resolve: [loadDrinkDriveOffenderDetail],
        [DrinkDriveOffenderRoutes.Edit]: {
            resolve: [loadCourts],
            title: "Edit",
        },
        ...detailRoutes
    }
});

export const createDrinkDriveOffenderRouteObject = {
    resolve: [loadOrganisations, () => loadEventTypes()],
    title: "Create Drink Drive Offender"
};

export const routes = {
    [DrinkDriveOffenderRoutes.DrinkDriveOffenders]: {
        resolve: [loadDrinkDriveOffenders, loadCourts],
        title: "Drink Drive Offenders",
        ignoreParentResolve: true,
        ...detailRoute(),
        [DrinkDriveOffenderRoutes.Create]: createDrinkDriveOffenderRouteObject
    }
};
