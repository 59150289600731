import * as React from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import moment from "moment";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { TrainerCpdApi } from "@common/crud/trainer/trainerCpdApi";
import { Spinner } from "@common/global";
import { CreateEditTrainerCpdRecordModel, TrainerCpdRecordModel } from "@common/crud/trainer/model";
import { toast } from "@common/toasts";

interface FormValues {
    date: string;
    title: string;
    description: string;
}

export interface CpdRecordModalProps {
    open: boolean;
    record?: TrainerCpdRecordModel;
    trainerId?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CpdRecordModal = ({ open, record, trainerId, setOpen }: CpdRecordModalProps) => {
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = React.useState(false);
    const [deletingRecord, setDeletingRecord] = React.useState(false);

    const initialFormValues: FormValues = ({
        date: record?.recordDate.format("YYYY-MM-DD") || moment().startOf("day").format("YYYY-MM-DD"),
        title: record?.title || "",
        description: record?.description || ""
    });

    function validateFormValues({ date, title, description }: FormValues) {
        const errors: any = {};

        if (!moment(date).isValid()) {
            errors.date = "Date must be valid";
        }
        else if (moment(date).isBefore(moment().add(-1, "year")) || moment(date).isAfter(moment())) {
            errors.date = "Date can only be today or less than one year ago";
        }
        if (title.length < 1 || title.length > 100) {
            errors.title = "Title must be between 1 and 100 characters";
        }
        if (description.length < 1 || description.length > 500) {
            errors.description = "Description must be between 1 and 500 characters";
        }
        return errors;
    }

    async function onSubmit({ date, title, description }: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) {
        const model: CreateEditTrainerCpdRecordModel = { id: record?.id, recordDate: moment(date), title, description };

        if (record) {
            await new TrainerCpdApi(trainerId).updateTrainerCpdRecord(model);
        } else {
            await new TrainerCpdApi(trainerId).createTrainerCpdRecord(model);
        }

        setSubmitting(false);
        setOpen(false);
        resetForm({ values: initialFormValues });
    }

    function onDeleteClick() {
        setDeleteConfirmationModalOpen(true);
    }

    function closeDeleteConfirmationModal() {
        setDeleteConfirmationModalOpen(false);
    }

    async function deleteRecord() {
        setDeletingRecord(true);
        await new TrainerCpdApi(trainerId).deleteTrainerCpdRecord(record.id);
        setDeletingRecord(false);
        setDeleteConfirmationModalOpen(false);
        setOpen(false);
        toast.success("CPD record deleted");
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validate={validateFormValues}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ handleChange, handleBlur, submitForm, resetForm, values, errors, touched, isSubmitting, isValid }: FormikProps<FormValues>) => {
                function onCancelClick() {
                    setOpen(false);
                    resetForm({ values: initialFormValues });
                }

                return (
                    <>
                        <Modal size="tiny" open={deleteConfirmationModalOpen}>
                            <Modal.Header>Are you sure?</Modal.Header>
                            <Modal.Content>
                                <Spinner active={deletingRecord}>
                                    <p>Are you sure you want to delete this CPD record? Warning - this action cannot be undone.</p>
                                </Spinner>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button content="No" className="cancel-action" onClick={closeDeleteConfirmationModal} disabled={deletingRecord} />
                                <Button content="Yes" positive onClick={deleteRecord} disabled={deletingRecord} />
                            </Modal.Actions>
                        </Modal>
                        <Modal size="tiny" open={open}>
                            <Modal.Header>{record ? "CPD record" : "Add new CPD record"}</Modal.Header>
                            <Modal.Content>
                                <Spinner active={isSubmitting}>
                                    <Form>
                                        <Form.Input
                                            label="Date"
                                            type="date"
                                            id="date"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.date}
                                            error={errors.date && touched.date && { content: errors.date, pointing: "above" }}
                                        />
                                        <Form.Input
                                            placeholder="Title"
                                            id="title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                            error={errors.title && touched.title && { content: errors.title, pointing: "above" }}
                                        />
                                        <Form.TextArea
                                            placeholder="Description"
                                            id="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            error={
                                                errors.description && touched.description &&
                                                { content: errors.description, pointing: "above" }
                                            }
                                        />
                                    </Form>
                                </Spinner>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button content="Cancel" className="cancel-action" onClick={onCancelClick} disabled={isSubmitting} />
                                <Button content="Save" onClick={submitForm} disabled={isSubmitting || !isValid} />
                                {record && <Button content="Delete" onClick={onDeleteClick} disabled={isSubmitting} />}
                            </Modal.Actions>
                        </Modal>
                    </>
                );
            }}
        </Formik>
    );
};
