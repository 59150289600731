import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Header, Icon, Input } from "semantic-ui-react";

import { saveSetting } from "../actions";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { DomainBlacklistSettings } from "../model";

export const domainRegEx = new RegExp("^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$");

export const DomainBlacklist: ConfigurationAreaComponent<DomainBlacklistSettings> = ({ settings, hierarchy }) => {
    const dispatch = useDispatch();

    React.useEffect(() => setDomains(JSON.parse(settings?.Domains?.value?.toString() || "[]") as string[]), [settings]);

    const [domains, setDomains] = React.useState(JSON.parse(settings?.Domains?.value?.toString() || "[]") as string[]);
    const [newDomain, setNewDomain] = React.useState("");
    const [change, setChange] = React.useState(false);

    const onDeleteDomain = React.useCallback((event: MouseEvent, icon: Icon) => {
        const index = icon["data-id"];
        setChange(true);
        setDomains(domains.filter((_, i) => i !== index));
    }, [domains]);

    const onSetNewDomain = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNewDomain(event.target.value.toLowerCase());
    }, []);

    const onAddNewDomain = React.useCallback(() => {
        setDomains([ ...domains, newDomain ]);
        setNewDomain("");
        setChange(true);
    }, [domains, newDomain]);

    const onSaveDomains = React.useCallback(() => {
        const setting = {
            area: "DomainBlacklist",
            name: "Domains",
            hierarchy,
            value: JSON.stringify(domains)
        };
        dispatch(saveSetting({ setting }));
        setChange(false);
    }, [domains, hierarchy, dispatch]);

    const domainValid = domainRegEx.test(newDomain) && !((domains || []).some(d => d === newDomain));

    const domainGrid = domains.map((domain, index) => (
        <Grid.Row key={`domain_${index}`}>
            <Grid.Column width={12}>
                {domain}
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
                <Icon link data-id={index} name="trash" onClick={onDeleteDomain} />
            </Grid.Column>
        </Grid.Row>));

    return (
        <Grid className="row-select">
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header>Domain Blacklist</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right">
                    <Button content="Save" disabled={!change} onClick={onSaveDomains} />
                </Grid.Column>
            </Grid.Row>
            {domainGrid}
            <Grid.Row>
                <Grid.Column width={10}>
                    <Input className="full-width" value={newDomain} onChange={onSetNewDomain} />
                </Grid.Column>
                <Grid.Column width={6} textAlign="right">
                    <Button disabled={!domainValid} content="Add" onClick={onAddNewDomain} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={10} />
                <Grid.Column width={6} textAlign="right">
                    <Button content="Save" disabled={!change} onClick={onSaveDomains} />
                </Grid.Column>
            </Grid.Row>
        </Grid>);
};
