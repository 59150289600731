import { EventInstance } from "@common/crud/eventInstance";
import { Button, Modal } from "semantic-ui-react";
import * as React from "react";
import { toast } from "@common/toasts";
import { EventInstanceApi } from "@common/crud/eventInstance/eventInstanceApi";
import { Authorize } from "reauthorize";
import { TtcSeniorClientAdvisorRole } from "@common/auth/model";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { lineOfBusinessRouteSelector } from "@common/crud/common/selectors";

interface OwnProps {
    eventInstance: EventInstance;
}

export const DeleteEventInstanceButton: React.FC<OwnProps> = ({ eventInstance }) => {
    const hasTrainer = !!eventInstance?.allTrainerIds?.length;
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>();
    const dispatch = useDispatch();
    const redirectRoute = useSelector(lineOfBusinessRouteSelector);
    const disableButton = hasTrainer || !!eventInstance.publishDate;
    const closeModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const deleteCourse = async () => {
        try {
            await new EventInstanceApi().deleteEventInstance(eventInstance?.id);
            dispatch(push(`/${redirectRoute}/eventInstances`));
            toast.success("Course deleted successfully.");
        }
        catch (error) {
            // eslint-disable-next-line eqeqeq
            if (error?.response?.data?.errorMessage != null) {
                toast.error(error?.response?.data?.errorMessage);
            } else {
                toast.error("Something went wrong, please try again later.");
            }
        }
    };

    return (
        <Authorize authorize={[TtcSeniorClientAdvisorRole]}>
            <Button className="irregular-positive" content="Delete Course" onClick={() => setIsModalOpen(true)} disabled={disableButton}  />
            <Modal open={isModalOpen}>
                <Modal.Content>
                    Are you sure you wish to delete this course? This action cannot be undone.
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeModal}>No</Button>
                    <Button primary onClick={deleteCourse}>Yes</Button>
                </Modal.Actions>
            </Modal>
        </Authorize>
    );
};
