import * as React from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { Modal } from "@common/components";
import { toast } from "@common/toasts";
import { CreateForm, CreateProps, DispatchProps } from "./Create";
import { BusinessLineType } from "@common/redux-helpers";

export class CreateModal extends React.Component<CreateProps & DispatchProps, {}> {

    private createForm: CreateForm;

    public render() {
        return (
            <Modal size="small" open={this.props.open} onClose={this.props.close}>
                <Dimmer active={this.props.loading}>
                    <Loader active={this.props.loading} />
                </Dimmer>
                <Modal.Header>Create Course</Modal.Header>
                <Modal.Content>
                    <CreateForm
                        ref={this.setRef}
                        {...this.props}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close}>Cancel</Button>
                    <Button onClick={this.submit}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private setRef = (instance: CreateForm) => this.createForm = instance;

    private submit = () => {
        // eslint-disable-next-line eqeqeq
        if (this.createForm.state.values.maxNumberOfAttendees == null) {
            toast.error(this.props.businessLineType === BusinessLineType.Corporate
                ? "Product isn't configured correctly. You must set the maximum number of attendees for the course type."
                : "Scheme isn't configured correctly. You must set the maximum number of attendees for the course type.");

            return;
        }
        this.createForm.submit();
    };
}
