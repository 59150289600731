import { Area } from "@common/crud/eventInstance/model";
import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";

export const DesktopLegend=({ area }: {area: Area}) => {

    const digiPlanning = area === Area.AdminEventManagementDigitalPlanning;
    const courseText = digiPlanning ? "Provisionally planned course (same workflow)" : "Booked";
    const columnWidth = digiPlanning ? 5 : 3;
    return (
        <>
            <Grid.Row>
                <Grid.Column  textAlign="center" width={columnWidth}>
                    <div className="availability"></div>
                    <div>Availability</div >
                </Grid.Column>
                <Grid.Column width={columnWidth}><div className="unavailability"></div>
                    <div>Unavailability</div >
                </Grid.Column>
                <Grid.Column width={columnWidth}><div className="booked">COURSE</div>
                    <div>{courseText}</div>
                </Grid.Column>
                {digiPlanning && <>
                    <Grid.Column width={columnWidth}><div className="booked existing-course">COURSE</div>
                        <div>Existing Course</div ></Grid.Column>
                    <Grid.Column width={columnWidth}><div className="booked other-wf" >COURSE</div>
                        <div>Provisionally planned course (other workflow)</div>
                    </Grid.Column>
                </>}
                <Grid.Column width={columnWidth} textAlign="center" ><Icon name="home" />
                    <div>Classroom Course</div>
                </Grid.Column>
                {!digiPlanning && <Grid.Column width={columnWidth} textAlign="center"><Icon name="wifi" /><div>Digital Course</div></Grid.Column>}
            </Grid.Row>
        </>)
    ;
};
