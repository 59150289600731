import * as React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";

import { CreateForm, CreateProps, DispatchProps } from "./Create";

export class CreateModal extends React.Component<CreateProps & DispatchProps, {}> {
    private createForm: CreateForm;
    public render() {

        return (
            <Modal size="small" open={this.props.open} onClose={this.props.close}>
                <Modal.Header>{(this.props.isCorporate || this.props.isConstruction) ? "Create Product" : "Create Scheme"}</Modal.Header>
                <Modal.Content>
                    <CreateForm
                        open={this.props.open}
                        isCorporate={this.props.isCorporate}
                        isConstruction={this.props.isConstruction}
                        isPoliceOrCourt={this.props.isPoliceOrCourt}
                        save={this.props.save}
                        push={this.props.push}
                        trainerAttributes={this.props.trainerAttributes}
                        complianceAttributes={this.props.complianceAttributes}
                        isBusinessDriverAdmin={this.props.isBusinessDriverAdmin}
                        isTtcCorporateAdmin={this.props.isTtcCorporateAdmin}
                        ref={this.setRef}
                        isSaving={this.props.isSaving}
                        businessLineType={this.props.businessLineType}
                        constructionCategories={this.props.constructionCategories}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close}>Cancel</Button>
                    <Button disabled={this.props.isSaving} loading={this.props.isSaving} onClick={this.submit}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private setRef = (instance: CreateForm) => this.createForm = instance;

    private submit = () => this.createForm.submit();
}
