import { isBusinessDriverAdmin, isTtcCorporateAdmin } from "../../common/selectors";
import { OrganisationOption } from "../../eventInstance/model";
import { AppState, CompanyTypeEnum, NoneOrganisationOption } from "../model";
import { relatedOrganisationOptionsSelector } from "../selectors";
import { AppCommonState } from "@common/appCommonState";

export function getCorporateRelatedOrganisationOptions(
    state: AppState & AppCommonState,
    currentlyEditedOrganizationId?: string | undefined) {

    const ttcCorporateAdmin = isTtcCorporateAdmin(state);
    const businessDriverAdmin = isBusinessDriverAdmin(state);
    let organisationOptions = relatedOrganisationOptionsSelector(state);

    if (currentlyEditedOrganizationId) {
        organisationOptions = organisationOptions.filter(org => org.value !== currentlyEditedOrganizationId);
    }

    if (businessDriverAdmin === true && ttcCorporateAdmin === true) {
        organisationOptions = organisationOptions.filter(org => org.companyType?.includes(CompanyTypeEnum.BusinessDriver) ||
            org.companyType?.includes(CompanyTypeEnum.TTC) || org.companyType?.includes(CompanyTypeEnum.LicenceBureau));
    } else if (ttcCorporateAdmin === true && businessDriverAdmin === false) {
        organisationOptions = organisationOptions
            .filter(org => org.companyType?.includes(CompanyTypeEnum.TTC) || org.companyType?.includes(CompanyTypeEnum.LicenceBureau));
    } else if (businessDriverAdmin === true && ttcCorporateAdmin === false) {
        organisationOptions = organisationOptions.filter(org => org.companyType?.includes(CompanyTypeEnum.BusinessDriver));
    }

    const noneOrganisationOption: OrganisationOption = {
        value: NoneOrganisationOption,
        text: NoneOrganisationOption,
        key: NoneOrganisationOption,
        open: false,
        customerStatus: 1,
        companyType: undefined,
        hasCorporateOrganisationSpecificDigitalDetails: false,
        hasCorporateOrganisationSpecificPurchaseOrderDrawdowns: false
    };

    return [noneOrganisationOption, ...organisationOptions];
};

export function companyTypeSupportsDqcFields(companyTypes: number[]): boolean {
    return !companyTypes || companyTypes.includes(CompanyTypeEnum.TTC) || companyTypes.includes(CompanyTypeEnum.LicenceBureau);
}
