import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { push, State as RouterState } from "redux-little-router";
import {
    CreateStartPaymentProcessRequestResponseStatus,
    PaymentRequest,
    PaymentResponse,
    PaymentSuccessPayload,
    PaymentType,
    StartPaymentProcessRequest
} from "@common/payments/model";
import { AttendeeAction, loadAttendeeDetailSuccess } from "@common/crud/attendee/actions";
import { AttendeeApi } from "@common/crud/attendee";

import { attendeeIdSelector, bookingIdSelector } from "./selectors";
import { StartPaymentProcessModel } from "./model";
import { PaymentApi } from "./paymentApi";
import * as actions from "./actiontypes";
import { toast } from "@common/toasts";

export type PaymentAction =
    ({ type: actions.LOAD_PAYMENT_SUCCESS } & Payload<StartPaymentProcessRequest>)
    | ({ type: actions.MAKE_PAYMENT_SUCCESS } & Payload<PaymentSuccessPayload>)
    | ({ type: actions.MAKE_PAYMENT_FAILURE } & Payload<PaymentSuccessPayload>);

export const loadPaymentSuccess = (payment: StartPaymentProcessRequest) => ({
    payload: payment,
    type: actions.LOAD_PAYMENT_SUCCESS
});

export const makePaymentSuccess = (paymentResponse: PaymentResponse, amount: number, attendeeId: string): AttendeeAction => ({
    payload: {
        paymentResponse,
        amount,
        attendeeId,
    },
    type: actions.MAKE_PAYMENT_SUCCESS
});

export const makePaymentFailure = (paymentResponse: PaymentResponse, amount: number, attendeeId: string): AttendeeAction => ({
    payload: {
        paymentResponse,
        amount,
        attendeeId,
    },
    type: actions.MAKE_PAYMENT_FAILURE
});

export const addPaymentSuccess = (attendeeId: string, amount: number) => ({
    payload: { attendeeId, amount },
    type: actions.ADD_PAYMENT_SUCCESS
});

export function addPayment(
    eventInstanceId: string,
    attendeeId: string,
    paymentType: PaymentType,
    amount: number,
    path: string,
    flexiPayPurchasedAfterBooking?: boolean) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new PaymentApi(bookingId);
        await loadAndTrack(dispatch, "addPayment", api.addPayment(eventInstanceId, attendeeId, paymentType, amount, flexiPayPurchasedAfterBooking));

        const attendeeApi = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "laodAttendeeDetails", attendeeApi.detail(attendeeId));
        dispatch(loadAttendeeDetailSuccess(result));

        if (path) {
            dispatch(push(path));
        }
    };
}

export function loadPayment(startPaymentProcessModel: StartPaymentProcessModel, path: string) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new PaymentApi(bookingId);
        const payment = await loadAndTrack(dispatch, "loadPaymentRequest", api.createStartPaymentProcessRequest(startPaymentProcessModel));

        switch (payment.status) {
            case CreateStartPaymentProcessRequestResponseStatus.Ok: {
                dispatch(loadPaymentSuccess(payment.startPaymentProcessRequest));

                if (path) {
                    dispatch(push(path));
                }
                break;
            }

            case CreateStartPaymentProcessRequestResponseStatus.BookingSessionExpired: {
                dispatch(push("/landing"));
                break;
            }

            default: {
                dispatch(push(`/bookings/${bookingId}/dors-update-error?status=${payment.status}&delta=3`));
                break;
            }
        }
    };
}

export function payOutstandingBalance(path: string) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new PaymentApi(bookingId);
        const payment = await loadAndTrack(dispatch, "loadOutstandingBalancePaymentRequest", api.createOutstandingBalancePayment());
        dispatch(loadPaymentSuccess(payment));

        // Delay redirect to stop race condition where StartPaymentProcessRequest not set in state before
        // StripeWrapper component mounted.
        setTimeout(() => {
            if (path) {
                dispatch(push(path));
            }
        }, 500);
    };
}

export function makePayment(paymentRequest: PaymentRequest) {
    return async (dispatch: Dispatch, getState: () => RouterState) => {
        const bookingId = bookingIdSelector(getState());
        const attendeeId = attendeeIdSelector(getState());
        const api = new PaymentApi(bookingId);
        const paymentResponse = await loadAndTrack(dispatch, "makePayment", api.makePayment(paymentRequest));
        if (paymentResponse.validRequest) {
            dispatch(makePaymentSuccess(paymentResponse, paymentRequest.amount, attendeeId));
        }
        else {
            dispatch(makePaymentFailure(paymentResponse, 0, attendeeId));
        }
        return paymentResponse;
    };
}

export function startAutoPaymentProcessFromAdminApp(
    genesysToken: string,
    correlationId: string,
    eventInstanceId: string,
    paymentProcessModel: StartPaymentProcessModel,
    clearAutoPaymentLoading: () => void) {
    return async (dispatch: Dispatch) => {
        const paymentApi = new PaymentApi();
        const autoPaymentResult = await paymentApi.startAutoPaymentProcessFromAdminApp(correlationId, { genesysToken, paymentProcessModel });
        clearAutoPaymentLoading();

        if (autoPaymentResult.paymentProcessStatus !== CreateStartPaymentProcessRequestResponseStatus.Ok) {
            toast.error("Failed to start auto payment process");
            return;
        }

        if (autoPaymentResult.invalidCallInfo) {
            toast.error("Failed to retrieve Genesys call information");
            return;
        }

        if (!autoPaymentResult.ivrSessionCreated) {
            toast.error("IVR session was not created");
            return;
        }

        toast.success("Auto payment process successfully started");
        dispatch(push(`/police-and-court-event-management?bookingId=${correlationId}&eventInstanceId=${eventInstanceId}`));
    };
}
