import { createSelector } from "reselect";
import { EventType, AppState, WorkflowTypeEnum, CorporateWorkflowTypeArray, PoliceAndCourtWorkflowTypeArray,
    BookingTypeEnum, NonPoliceOrCourtWorkflowTypeForCompanyTypeOptions, ConstructionWorkflowTypeArray } from "./model";
import { DropdownItemProps } from "semantic-ui-react";
import {
    nonExpiredEventTypes,
} from "@common/helpers/expiredEventTypes";
import { isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate } from "@common/global/CommonHelpers";
import { Organisation } from "../organisation";
import { BusinessLineType } from "@common/redux-helpers";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.eventTypeId;
};

export type SelectorState = AppState;

export const allEventTypesSelector = (state: AppState) => state.eventTypes;

export const ddrsEventTypesSelector = (state: AppState) => state.eventTypes.filter(e => e.workflowType === WorkflowTypeEnum.DDRS);

export const dorsEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => !!et.dorsId && et.workflowType === WorkflowTypeEnum.Dors)
);

export const drinkDriveEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => et.workflowType === WorkflowTypeEnum.DDRS)
);

export const dorsOrDrinkDriveEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => (!!et.dorsId && et.workflowType === WorkflowTypeEnum.Dors) || et.workflowType === WorkflowTypeEnum.DDRS)
);

export const corporateEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => isEventTypeWorkflowCorporate(et))
);

export const constructionEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => isEventTypeWorkflowConstruction(et))
);

export const corporateAndConstructionEventTypesSelector = createSelector(
    allEventTypesSelector,
    eventTypes => eventTypes.filter(et => isEventTypeWorkflowCorporate(et) || isEventTypeWorkflowConstruction(et))
);

export const eventTypeMapSelector = createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]) => {
        const idNamePairs = eventTypes.map(d => [d.id, d.name]) as [string, string][];
        return new Map(idNamePairs);
    }
);

export const ddrsEventTypeOptionsSelector = createSelector(
    ddrsEventTypesSelector,
    (eventTypes: EventType[]) => [
        { text: "Select", value: undefined, key: undefined },
        ...eventTypes?.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.id, key: et.id })) || []
    ]
);

export const eventTypeByRouteSelector = createSelector(
    allEventTypesSelector,
    routeIdSelector,
    (eventTypes: EventType[], id: string): EventType => {
        return eventTypes.filter(c => c.id === id)[0] || {} as any;
    }
);

export const eventTypesByRouteSelector = (state: AppState) => {
    if (state.router.pathname.indexOf("police-and-court-event-management") !== -1) {
        return PoliceAndCourtWorkflowTypeArray;
    } else if (state.router.pathname.indexOf("corporate-event-management") !== -1) {
        return CorporateWorkflowTypeArray;
    } else {
        return ConstructionWorkflowTypeArray;
    }
};

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("eventTypes") + "eventTypes".length);

export const editPathSelector = (state: AppState) => {
    const eventTypeId = routeIdSelector(state);
    const startIndex = state.router.pathname.indexOf(eventTypeId) + eventTypeId.length;
    const endIndex = state.router.pathname.lastIndexOf("/edit");
    return state.router.pathname.substring(startIndex, endIndex);
};

export const eventTypeOptionSelector = createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]): DropdownItemProps[] => eventTypes.map(et => ({ text: et.name, value: et.id, key: et.id }))
);

export const eventTypeOptionsSelector = createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]) =>
        eventTypes.map(et => ({ text: et.name, value: et.id, key: et.id }))
);

export const eventTypeWithWorkflowOptionsSelector = (workflows: WorkflowTypeEnum[]) => createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]) =>
        [
            { text: "Any Scheme", value: "" },
            ...eventTypes
                .filter(et => nonExpiredEventTypes(et.expiryDate) && (!workflows || workflows.length === 0 || workflows.includes(et.workflowType)))
                .map(et => ({ text: et.name, value: et.id, key: et.id }))
        ]
);

export const eventTypeOptionsSelectorWithAny = createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]) => eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.id, key: et.id }))
);

export const nDorsEventTypeOptionsSelectorWithAny = createSelector(
    dorsEventTypesSelector,
    (eventTypes: EventType[]) => {
        return eventTypes
            .filter(et => nonExpiredEventTypes(et.expiryDate))
            .map(et => ({ text: et.name, value: et.id, key: et.id }));
    }
);

export const drinkDriveEventTypeOptionsSelectorWithAny = createSelector(
    drinkDriveEventTypesSelector,
    (eventTypes: EventType[]) => {
        return eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.id, key: et.id }));
    }
);

export const nDorsOrDrinkDriveEventTypeOptionsSelectorWithAny = createSelector(
    dorsOrDrinkDriveEventTypesSelector,
    (eventTypes: EventType[]) => {
        return eventTypes.map(et => ({ text: et.name, value: et.id, key: et.id }));
    }
);

export const corporateEventTypeOptionsSelectorWithAny = createSelector(
    corporateEventTypesSelector,
    (eventTypes: EventType[]) => {
        return [
            { text: "Any Product", value: "" },
            ...eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.id, key: et.id }))
        ];
    }
);

export const constructionEventTypeOptionsSelectorWithAny = createSelector(
    constructionEventTypesSelector,
    (eventTypes: EventType[]) => {
        return [
            { text: "Any Product", value: "" },
            ...eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.id, key: et.id }))
        ];
    }
);

export const constructionEventTypeOptionsForWorkflowSelector = (workflowType?: WorkflowTypeEnum) => createSelector(
    constructionEventTypesSelector,
    (eventTypes: EventType[]) => {
        return [
            { text: "Any Product", value: "" },
            ...eventTypes
                .filter(et => !workflowType || et.workflowType === workflowType)
                .filter(et => nonExpiredEventTypes(et.expiryDate))
                .map(et => ({ text: et.name, value: et.id, key: et.id }))
        ];
    }
);

export const corporateEventTypeOptionsForWorkflowSelector = (workflowType?: WorkflowTypeEnum) => createSelector(
    corporateEventTypesSelector,
    (eventTypes: EventType[]) => {
        return [
            { text: "Any Product", value: "" },
            ...eventTypes
                .filter(et => !workflowType || et.workflowType === workflowType)
                .filter(et => nonExpiredEventTypes(et.expiryDate))
                .map(et => ({ text: et.name, value: et.id, key: et.id }))
        ];
    }
);

export const eventTypeOptionsForWorkflowSelector = (workflowTypes: WorkflowTypeEnum[]) => createSelector(
    allEventTypesSelector,
    (eventTypes: EventType[]) => {
        return eventTypes
            .filter(et => workflowTypes.length === 0 || workflowTypes.some(wt => wt === et.workflowType))
            .filter(et => workflowTypes.length !== 1 || workflowTypes[0] !== WorkflowTypeEnum.Dors || !!et.dorsId)
            .filter(et => nonExpiredEventTypes(et.expiryDate))
            .map(et => ({ text: et.name, value: et.id, key: et.id }));
    }
);

export const nonPoliceOrCourtEventTypeOptionsForBulkCoursePlanningSelector = (businessLineType: BusinessLineType, organisation: Organisation,
    workflowType?: WorkflowTypeEnum) => createSelector(
    corporateAndConstructionEventTypesSelector,
    (eventTypes: EventType[]) => {
        const possibleWorkflowTypes =
            NonPoliceOrCourtWorkflowTypeForCompanyTypeOptions(
                businessLineType,
                organisation.companyType
            );
        eventTypes = eventTypes.filter((et) =>
            possibleWorkflowTypes.some(
                (possibleWorkflowType) =>
                    et.workflowType === possibleWorkflowType.value
            )
        );

        const eventTypeIsSuitableForOrganisation = (et: EventType) => {
            return organisation.corporateOrganisationData?.openCourse
                ? (et.bookingType === BookingTypeEnum.Open || et.bookingType === BookingTypeEnum.Both)
                : (et.bookingType === BookingTypeEnum.Closed || et.bookingType === BookingTypeEnum.Both);
        };

        const eventTypeHasCorrectWorkflow = (et: EventType) => !workflowType || et.workflowType === workflowType;

        return [
            ...eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)
                && eventTypeIsSuitableForOrganisation(et)
                && eventTypeHasCorrectWorkflow(et)).map(et => ({ text: et.name, value: et.id, key: et.id }))
        ];
    }
);

export const eventTypeDorsIdOptionsSelector = createSelector(
    dorsEventTypesSelector,
    (eventTypes: EventType[]) => eventTypes.filter(et => nonExpiredEventTypes(et.expiryDate)).map(et => ({ text: et.name, value: et.dorsId, key: et.id }))
);

export const eventTypeSelector = (eventTypeId: string) => createSelector(
    allEventTypesSelector,
    (eventTypes) => {
        return eventTypes.find(c => c.id === eventTypeId) || {} as any;
    }
);
