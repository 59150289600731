import { Spinner } from "@common/global";
import * as React from "react";
import { Icon, Table } from "semantic-ui-react";
import { DrinkDriveOffenderApi } from "../../drinkDriveOffenderApi";
import { AttachedDocumentListModel } from "../../model";

export const ReferralDocuments: React.FC = () => {

    const [loading, setLoading] = React.useState(true);
    const [documents, setDocuments] = React.useState<AttachedDocumentListModel[]>([]);

    React.useEffect(() => {
        async function loadDocuments() {
            const api = new DrinkDriveOffenderApi();
            const referralDocuments = await api.getAllDdrsOffenderDocuments();
            setDocuments(referralDocuments);
            setLoading(false);
        }

        loadDocuments();
    }, []);

    return (
        <Spinner active={loading}>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Document</Table.HeaderCell>
                        <Table.HeaderCell>Processed</Table.HeaderCell>
                        <Table.HeaderCell>Completed</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {documents.map(d => (
                        <Table.Row key={d.id}>
                            <Table.Cell>{d.description ?? d.fileName}</Table.Cell>
                            <Table.Cell>
                                {d.isProcessed
                                    ? <Icon name="check" color="green" />
                                    : <Icon name="wait" color="grey" />
                                }</Table.Cell>
                            <Table.Cell>
                                {d.isCompleted
                                    ? <Icon name="check circle" color="green" />
                                    : <Icon name="remove circle" color="red" />
                                }
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Spinner>
    );
};
