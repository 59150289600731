import * as React from "react";
import { Modal } from "@common/components";
import { Input } from "@neworbit/simpleui-input";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import { TrainerDetailModel } from "../../model";
import { useDispatch } from "react-redux";
import { updateTrainerNriLicenceNumber } from "@common/crud/trainer/actions";

interface UpdateNriLicenceNumberModalProps {
    trainer: TrainerDetailModel;
}

const UpdateNriLicenceNumberModal: React.FC<UpdateNriLicenceNumberModalProps> = ({ trainer }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [nriLicenceNumber, setNriLicenceNumber] = React.useState<string>();
    const dispatch = useDispatch();

    React.useEffect(() => {
        setNriLicenceNumber(trainer.nriLicenceNumber);
    }, [trainer.nriLicenceNumber]);

    function toggleModal() {
        setOpen(!open);
        setNriLicenceNumber(trainer.nriLicenceNumber);
    }

    function onSave() {
        dispatch(updateTrainerNriLicenceNumber(trainer.id, {
            nriLicenceNumber
        }));
        setOpen(false);
    }

    const isNotModified = trainer.nriLicenceNumber === nriLicenceNumber;
    return (
        <>
            <Grid.Row>
                <Grid.Column width={3}>
                    <Button className="link-button" onClick={toggleModal}>Update NRI Licence Number</Button>
                </Grid.Column>
            </Grid.Row>

            <Modal open={open}>
                <Modal.Header>
                    Update NRI Licence Number
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Input.Text
                            label="Type New NRI Licence Number"
                            value={nriLicenceNumber}
                            onChange={setNriLicenceNumber}
                        />
                    </Form>
                    {trainer.nriLicenceNumber !== nriLicenceNumber &&
                        <Message info>
                            NRI Licence Number modified, old NRI Licence Number: {trainer.nriLicenceNumber}
                        </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={toggleModal} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        disabled={isNotModified}
                        onClick={onSave}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { UpdateNriLicenceNumberModal };
