import * as React from "react";
import moment from "moment";
import { Button, Grid, Modal, Table } from "semantic-ui-react";
import { OpenCourseFee, ValueWithEffectiveDate } from "../model";
import { getCurrencyFormat } from "@common/formating";
import { MuiDateField } from "@common/components/MuiDateField";
import { CurrencyInput } from "@common/global/CurrencyInput";

export interface CorporateFeeTableProps {
    isOpen: boolean;
    isDigital: boolean;
    headerPrefix?: string;
    showEditButton?: boolean;
    fees: ValueWithEffectiveDate<number | OpenCourseFee>[];
    onChange?: (value: ValueWithEffectiveDate<any>[], valid: boolean) => void;
    isWeekendSupplementRequired?: boolean;
}

interface CourseFeeWithEffectiveDate {
    effectiveDate: moment.Moment;
    fee: number;
    weekendSupplement?: number;
}

export const CorporateFeeTable = ({
    isOpen,
    isDigital,
    headerPrefix,
    showEditButton,
    fees,
    onChange,
    isWeekendSupplementRequired = false,
}: CorporateFeeTableProps) => {
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [editModalHeader, setEditModalHeader] = React.useState("");
    const [newFee, setNewFee] =
        React.useState<CourseFeeWithEffectiveDate>(null);
    const [weekdayFeeValid, setWeekdayFeeValid] = React.useState(true);
    const [weekendSupplementValid, setWeekendSupplementValid] =
        React.useState(true);
    const [editedCourseFees, setEditedCourseFees] = React.useState<
        ValueWithEffectiveDate<number | OpenCourseFee>[]
    >(fees ? [...fees] : []);
    const [effectiveDateValid, setEffectiveDateValid] = React.useState(false);

    const sortedCourseFees = React.useMemo(
        () => fees?.sort((a, b) => a.effectiveDate.diff(b.effectiveDate)),
        [fees]
    );
    const sortedEditedCourseFees = React.useMemo(
        () =>
            editedCourseFees?.sort((a, b) =>
                a.effectiveDate.diff(b.effectiveDate)
            ),
        [editedCourseFees]
    );

    const header = `${
        isOpen ? "Open course delegate" : "Closed course organisation"
    } fee (${isDigital ? "Digital" : "Classroom"})`;

    function resetNewFeeRelatedState() {
        setNewFee(null);
        setWeekdayFeeValid(true);
        setWeekendSupplementValid(true);
        setEffectiveDateValid(false);
    }

    function onEditClick(e: any) {
        e.preventDefault();
        setEditModalOpen(true);
        setEditModalHeader(
            headerPrefix ? `${headerPrefix} - ${header}` : `${header}`
        );
    }

    function onCloseClick() {
        setEditModalOpen(false);
        setEditModalHeader("");
        setEditedCourseFees([...fees]);
        resetNewFeeRelatedState();
    }

    function onSaveClick() {
        if (newFee) {
            const newFeeWithCorrectType: ValueWithEffectiveDate<
                number | OpenCourseFee
            > = isOpen
                ? {
                    effectiveDate: newFee.effectiveDate,
                    value: {
                        fee: newFee.fee,
                        weekendSupplement: newFee.weekendSupplement,
                    }, }
                : { effectiveDate: newFee.effectiveDate, value: newFee.fee };
            if (onChange) {
                onChange(
                    [...sortedEditedCourseFees, newFeeWithCorrectType],
                    true
                );
            }
            setEditedCourseFees([
                ...sortedEditedCourseFees,
                newFeeWithCorrectType,
            ]);
        } else {
            if (onChange) {
                onChange(sortedEditedCourseFees, true);
            }
            setEditedCourseFees(sortedEditedCourseFees);
        }

        setEditModalOpen(false);
        setEditModalHeader("");
        resetNewFeeRelatedState();
    }

    function getCourseFeeValue(
        courseFee: ValueWithEffectiveDate<number | OpenCourseFee>
    ) {
        return isOpen
            ? (courseFee.value as OpenCourseFee).fee
            : (courseFee.value as number);
    }

    function onAddNewRowClick(e: any) {
        e.currentTarget.blur();
        setNewFee({ effectiveDate: null, fee: 0, weekendSupplement: 0 });
    }

    function onEffectiveDateChange(value: moment.Moment, valid: boolean) {
        setEffectiveDateValid(valid);
        if (valid) {
            setNewFee((prev) => ({ ...prev, effectiveDate: value }));
        }
    }

    function onWeekdayFeeChange(value: number, valid: boolean) {
        if (!valid) {
            setWeekdayFeeValid(false);
            return;
        }

        setWeekdayFeeValid(true);
        setNewFee((prev) => ({ ...prev, fee: value }));
    }

    function onWeekendSupplementChange(value: number, valid: boolean) {
        if (!valid) {
            setWeekendSupplementValid(false);
            return;
        }

        setWeekendSupplementValid(true);
        setNewFee((prev) => ({ ...prev, weekendSupplement: value }));
    }

    function effectiveDateAlreadyExists(effectiveDate: moment.Moment) {
        return sortedEditedCourseFees?.some((fee) =>
            fee.effectiveDate.isSame(effectiveDate, "day")
        );
    }

    function onRemoveClick(
        courseFee: ValueWithEffectiveDate<number | OpenCourseFee>
    ) {
        return () => {
            setEditedCourseFees([
                ...sortedEditedCourseFees.filter(
                    (fee) =>
                        !fee.effectiveDate.isSame(
                            courseFee.effectiveDate,
                            "day"
                        )
                ),
            ]);
        };
    }

    function savingEnabled() {
        const allFees = [...sortedEditedCourseFees, newFee];
        const atLeastOneFeeActive = allFees.some((fee) =>
            fee?.effectiveDate?.isSameOrBefore(moment().utc(), "day")
        );
        const weekdayFeeFieldValid = newFee?.fee && newFee.fee > 0;
        return (
            editModalOpen &&
            (newFee === null ||
                (weekdayFeeValid &&
                    atLeastOneFeeActive &&
                    weekdayFeeFieldValid &&
                    weekendSupplementValid &&
                    effectiveDateValid))
        );
    }

    const muiDateValidator = (value: moment.Moment) => {
        return value && effectiveDateAlreadyExists(value)
            ? "Please use unique effective dates for each fee."
            : undefined;
    };

    const table = (editable: boolean) => {
        const extraCellInFirstColumn =
            editable && sortedEditedCourseFees.length > 0;

        return (
            <>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {extraCellInFirstColumn && <Table.HeaderCell />}
                            <Table.HeaderCell>
                                Date effective from
                            </Table.HeaderCell>
                            <Table.HeaderCell>Weekday fee</Table.HeaderCell>
                            {isOpen && (
                                <Table.HeaderCell>
                                    Weekend supplement
                                </Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(editable
                            ? sortedEditedCourseFees
                            : sortedCourseFees
                        )?.map((courseFee) => (
                            <Table.Row key={courseFee.effectiveDate.toString()}>
                                {extraCellInFirstColumn && (
                                    <Table.Cell>
                                        <Button
                                            content="Remove"
                                            onClick={onRemoveClick(courseFee)}
                                        />
                                    </Table.Cell>
                                )}
                                <Table.Cell>
                                    {courseFee.effectiveDate.format(
                                        "DD/MM/YYYY"
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {getCurrencyFormat(
                                        getCourseFeeValue(courseFee)
                                    )}
                                </Table.Cell>
                                {isOpen && (
                                    <Table.Cell>
                                        {getCurrencyFormat(
                                            (courseFee.value as OpenCourseFee)
                                                .weekendSupplement
                                        )}
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        ))}
                        {editable && newFee && (
                            <Table.Row key={newFee.effectiveDate?.toString()}>
                                {extraCellInFirstColumn && <Table.Cell />}
                                <Table.Cell>
                                    <MuiDateField
                                        required
                                        validation={[muiDateValidator]}
                                        showErrors
                                        value={newFee.effectiveDate}
                                        onChange={onEffectiveDateChange}
                                        shouldDisableDate={
                                            effectiveDateAlreadyExists
                                        }
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <CurrencyInput
                                        required={true}
                                        value={newFee.fee}
                                        onChange={onWeekdayFeeChange}
                                    />
                                </Table.Cell>
                                {isOpen && (
                                    <Table.Cell>
                                        <CurrencyInput
                                            required={
                                                isOpen &&
                                                isWeekendSupplementRequired
                                            }
                                            value={newFee.weekendSupplement}
                                            onChange={onWeekendSupplementChange}
                                        />
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                {editable && !newFee && (
                    <Button content="Add new row" onClick={onAddNewRowClick} />
                )}
            </>
        );
    };

    return (
        <>
            <Modal open={editModalOpen}>
                <Modal.Header>{editModalHeader}</Modal.Header>
                <Modal.Content>{table(true)}</Modal.Content>
                <Modal.Actions>
                    <Button content="Close" negative onClick={onCloseClick} />
                    <Button
                        content="Save"
                        positive
                        onClick={onSaveClick}
                        disabled={!savingEnabled()}
                    />
                </Modal.Actions>
            </Modal>
            <Grid.Row>
                <Grid.Column width={16}>
                    <h4 className="bold">{header}</h4>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="no-padding-top">
                <Grid.Column width={16}>{table(false)}</Grid.Column>
            </Grid.Row>
            {showEditButton && (
                <Grid.Row className="margin-bottom">
                    <Grid.Column width={16}>
                        <Button content="Edit" onClick={onEditClick} />
                    </Grid.Column>
                </Grid.Row>
            )}
        </>
    );
};
