import * as React from "react";
export const ConcessionsButton = (eligibleForconcessions: boolean, updateConcessions: () => void) => {

    return (
        <div className="label-and-value">
            <span className="label fixed-width">
                Eligible For Concessions
            </span>
            <a
                className="show-button"
                onClick={updateConcessions}
            >{eligibleForconcessions?
                    "Remove Concessions Discount":"Add Concessions Discount"}
            </a>
            <div className="value">
                {eligibleForconcessions? "Yes": "No"}
            </div>
        </div>);};
