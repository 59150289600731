/* tslint:disable:no-consecutive-blank-lines */
/* tslint:disable:max-line-length */

import * as React from "react";
import { Button, Divider, Tab, Segment, TabProps } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Authorize } from "reauthorize";
import { TtcTrainerAdminRole } from "@common/auth/model";

import { CoreDetailProps, DetailProps, DispatchProps } from "../Detail";
import { DetailsTab } from "./DetailsTab";
import { TrainersTab } from "./TrainersTab";
import { CustomTabProps } from "@common/model";

interface CoreDetailTabPropsState extends CoreDetailProps {
    onTabChange: (e: any, data: TabProps) => void;
    path: string;
}

enum TabNames {
    Details = "Details",
    Trainers = "Trainers",
}

export const CoreDetails: React.SFC<CoreDetailTabPropsState> = (props) => {
    const panes = [
        { menuItem: TabNames.Details, path: "", render: () => <DetailsTab {...props} /> },
        { menuItem: TabNames.Trainers, path: "trainers", render: () => <TrainersTab trainerAttributeDefinition={props.trainerAttributeDefinition} /> },
    ];

    const activeTab = props.path.split("/").pop().split("?")[0];
    const activeIndex = panes.findIndex(p => p?.path === activeTab) ;

    return (
        <Tab panes={panes} onTabChange={props.onTabChange} activeIndex={activeIndex >= 0 ? activeIndex : 0} />
    );
};

const CoreDetailsWithActions: React.SFC<DetailProps & DispatchProps> = (props) => {
    function handleTabChange(event: any, tabData: CustomTabProps) {
        props.push(`${props.basePath}/${props.trainerAttributeDefinition.id}/${tabData.panes[+(tabData.activeIndex)].path}`);
    }

    const basePath = props.path[props.path.length -1] === "/" ? props.path.slice(0, -1) : props.path;

    return (
        <div>
            <CoreDetails {...props} onTabChange={handleTabChange} />

            <Divider />
            <Authorize authorize={TtcTrainerAdminRole}>
                <Segment basic clearing vertical>
                    <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${basePath}/edit`} />
                </Segment>
            </Authorize>
        </div>
    );
};

export const coreDetailsTab = (props: DetailProps & DispatchProps) => ({
    path: "",
    menuItem: "Details",
    authorise: true,
    render: () => <Tab.Pane><CoreDetailsWithActions {...props} /></Tab.Pane>
});
