import * as React from "react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { ConfirmButton, Modal } from "@common/components";
import { Spinner } from "@common/global";
import { Button, Message, Table } from "semantic-ui-react";
import { EventInstanceApi } from "../..";
import { AutoSpreadStatusEnum, EventInstanceAttendeeSpreadResultModel } from "../../model";
import { useDispatch } from "react-redux";
import { loadEventInstanceDetail } from "../../actions";
import { toast } from "@common/toasts";
import { Link } from "redux-little-router";
import { loadAttendeesForEventInstance } from "@common/crud/attendee/actions";

interface SpreadAttendeesModalProps {
    path: string;
    eventInstanceId: string;
    eventInstanceStarted: boolean;
    eventInstanceFinished: boolean;
    noAttendees: boolean;
    overAnHourIntoCourse: boolean;
    autoSpreadStatus: AutoSpreadStatusEnum;
}

interface ResultModalContentProps {
    path: string;
    resultModel: EventInstanceAttendeeSpreadResultModel;
}

const ResultModalContent: React.FC<ResultModalContentProps> = ({ resultModel }) => {

    const { successCount, failCount, totalCount, failedAttendees, spreadAttendees, autoSpreadInProgress } = resultModel;
    const attendeesWithoutComms = spreadAttendees.filter(a => a.hasNoComms);

    return (
        <>
            <p>
                The system has moved {successCount}/{totalCount} attendees to alternative courses.
            </p>
            {failCount > 0 &&
                <>
                    <p>Please see the details below of the attendees not moved</p>
                    <Table>
                        <Table.Header>
                            <Table.Row textAlign="center">
                                <Table.HeaderCell width={4}>Attendee</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Completion Date</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Phone Number</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Has email address?</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {failedAttendees.map(f => (
                                <Table.Row key={f.attendeeId} textAlign="center">
                                    <Table.Cell width={4}>{f.attendeeName}</Table.Cell>
                                    <Table.Cell width={4}>{f.completionDate.format(DateFormat.Short)}</Table.Cell>
                                    <Table.Cell width={4}>{f.phoneNumber}</Table.Cell>
                                    <Table.Cell width={4}>{f.hasEmailAddress}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            }
            {autoSpreadInProgress &&
                <p>Auto spreading will now commence. This means Alaska will try to move the remaining attendees when an applicable seat becomes available</p>
            }
            {successCount > 0 && attendeesWithoutComms.length > 0 &&
                <>
                    <Message warning>
                        WARNING - There is at least one attendee who has been successfully moved to another course,
                        who does not have an email address or mobile number. This means they will not receive their new digital reminder comms
                    </Message>
                    <Table>
                        <Table.Header>
                            <Table.Row><Table.Cell>Attendee</Table.Cell></Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {attendeesWithoutComms.map(f => (
                                <Table.Row key={f.attendeeId}>
                                    <Table.Cell>
                                        <Link
                                            className="attendee-name"
                                            href={"/police-and-court-event-management/eventInstances/" + f.alternativeEventInstanceId +
                                                  "/attendee-redirect/" + f.reservedSeatId}
                                            target="_blank"
                                        >
                                            {f.attendeeName}
                                        </Link>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            }
        </>
    );
};

export const SpreadAttendeesModal: React.FC<SpreadAttendeesModalProps> = ({
    path, eventInstanceId, eventInstanceStarted, eventInstanceFinished, noAttendees, overAnHourIntoCourse, autoSpreadStatus
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [resultData, setResultData] = React.useState<EventInstanceAttendeeSpreadResultModel>(null);
    const [cancellingAutoSpreading, setCancellingAutoSpreading] = React.useState(false);
    const dispatch = useDispatch();

    const onOpenButtonClick = React.useCallback(() => {
        setOpen(true);
    }, []);

    function resetData() {
        setResultData(null);
        setOpen(false);
    }

    const onCancelClick = React.useCallback(() => {
        resetData();
    }, []);

    const onContinueClick = React.useCallback(async () => {
        setIsSubmitting(true);
        const api = new EventInstanceApi();
        const result = await api.spreadAttendees(eventInstanceId);

        if (result.duplicateRequest) {
            toast.info("This course is already being spread.");
            resetData();
        }

        setTimeout(() => {
            dispatch(loadEventInstanceDetail({ eventInstanceId }));
            dispatch(loadAttendeesForEventInstance({ eventInstanceId }));
            setResultData(result);
            setIsSubmitting(false);
        }, 5000);
    }, [dispatch, eventInstanceId]);

    async function cancelAutoSpreading() {
        setCancellingAutoSpreading(true);
        await new EventInstanceApi().cancelAutoSpreading(eventInstanceId);
        setCancellingAutoSpreading(false);
        dispatch(loadEventInstanceDetail({ eventInstanceId }));
    }

    const courseInProgress = eventInstanceStarted && !eventInstanceFinished;
    const autoSpreadInProgress = autoSpreadStatus === AutoSpreadStatusEnum.InProgress;

    return (
        <>
            { courseInProgress && !autoSpreadInProgress &&
                <ConfirmButton
                    onConfirm={onOpenButtonClick}
                    header="Course in progress"
                    content={"This course may be in progress, click ok if you are sure you would like to complete the spreading process."}
                    disabled={noAttendees || overAnHourIntoCourse}
                >
                    Spread Attendees
                </ConfirmButton>
            }
            { !courseInProgress && !autoSpreadInProgress &&
                <Button onClick={onOpenButtonClick} disabled={noAttendees || eventInstanceFinished}>
                Spread Attendees
                </Button>
            }
            {autoSpreadInProgress &&
                <Button
                    content="Cancel Auto Spread"
                    onClick={cancelAutoSpreading}
                    disabled={cancellingAutoSpreading}
                    loading={cancellingAutoSpreading}
                />
            }

            <Modal open={open}>
                {resultData &&
                    <>
                        <Modal.Header>
                            Results
                        </Modal.Header>
                        <Modal.Content>
                            <ResultModalContent resultModel={resultData} path={path} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={onCancelClick} className="cancel-action">
                                Close
                            </Button>
                        </Modal.Actions>
                    </>
                }
                {!resultData &&
                    <>
                        <Modal.Header>
                            Spreading clients to other courses
                        </Modal.Header>
                        <Modal.Content>
                            Clicking continue will result in clients on this course being moved to other courses running at the same date and time.
                            Do you wish to continue?
                        </Modal.Content>
                        <Modal.Actions>
                            <Spinner active={isSubmitting}>
                                <Button onClick={onCancelClick} className="cancel-action">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={onContinueClick}
                                    positive
                                    labelPosition="right"
                                    icon="checkmark"
                                    content="Continue"
                                />
                            </Spinner>
                        </Modal.Actions>
                    </>
                }
            </Modal>
        </>
    );
};
