import React from "react";
import { Controller, FieldPath, FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form";
import { OneTimePassword } from "./OneTimePassword";

interface OtpFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>{
    name: TName;
    rules?: Omit<RegisterOptions<FieldValues, Path<FieldValues>>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
    useGenericOtp?: boolean;
}

export function OtpField<T extends FieldValues>({
    name,
    rules,
}: OtpFieldProps<T>) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={""}
            render={function({ field }) {
                const { onChange, value } = field;

                return (
                    <OneTimePassword onChange={onChange} value={value} />
                );
            }}
        />
    );
}
