import * as React from "react";
import printJS from "print-js";
import { Modal } from "@common/components";
import { Button, CheckboxProps, Form, Radio } from "semantic-ui-react";
import { SendMessageOver } from "../../model";
import { AttendeeApi } from "../../attendeeApi";

export interface VatReceiptModalProps {
    attendeeId: string;
    attendeeEmail: string;
    eventInstanceId: string;
}

export const VatReceiptModal: React.FC<VatReceiptModalProps> = ({ attendeeId, attendeeEmail, eventInstanceId }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [resendOption, setResendOption] = React.useState<SendMessageOver>(SendMessageOver.Both);

    React.useEffect(() => {
        setResendOption(attendeeEmail ? SendMessageOver.Email : SendMessageOver.Letter);
    }, [attendeeEmail, open]);

    function onOpenClick() { setOpen(true); }
    function onCloseClick() { setOpen(false); }

    const setOption = React.useCallback((e: any, { value }: CheckboxProps) => setResendOption(value as number), []);

    function onContinueClick() {
        const prepareVatReceipt = async () => {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const base64 = await attendeeApi.sendVatReceipt(attendeeId, eventInstanceId, resendOption);
            if (resendOption === SendMessageOver.Letter && base64 !== null) {
                const blob = await fetch(base64).then(res => res.blob());
                printJS(URL.createObjectURL(blob));
            }
        };
        prepareVatReceipt();
        onCloseClick();
    }

    return (
        <>
            <Button className="button-margin" onClick={onOpenClick}>Send VAT Receipt</Button>

            <Modal open={open}>
                <Modal.Header>
                    Send VAT receipt
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            How would you like to send the VAT receipt today?
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Email"
                                name="vatReceiptGroup"
                                disabled={!attendeeEmail}
                                value={SendMessageOver.Email}
                                checked={resendOption === SendMessageOver.Email}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Letter"
                                name="vatReceiptGroup"
                                value={SendMessageOver.Letter}
                                checked={resendOption === SendMessageOver.Letter}
                                onChange={setOption}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCloseClick} negative>
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinueClick}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
