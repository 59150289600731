import { Detail } from "@neworbit/simpleui-detail";
import * as React from "react";
import { Grid, Tab } from "semantic-ui-react";
import { TrainerAttributeType } from "../../model";
import { CoreDetailProps } from "../Detail";

export const DetailsTab = (props: CoreDetailProps) => {
    const getSchemesNames = () => {
        const eventTypes = props.eventTypes?.map(et => ({ name: et.name, id: et.id }));
        const schemeNames = props.trainerAttributeDefinition.schemes?.map(schemeId => eventTypes.find(et => et.id === schemeId)?.name);
        return schemeNames?.join(", ");
    };

    return (
        <Tab.Pane>
            <Grid>
                <Detail label="Name" value={props.trainerAttributeDefinition.name} labelWidth={5} valueWidth={11} />
                <Detail label="Attribute type" value={TrainerAttributeType[props.trainerAttributeDefinition.attributeType]} labelWidth={5} valueWidth={11} />
                <Detail label="Schemes" value={getSchemesNames()} labelWidth={5} valueWidth={11} />
            </Grid>
        </Tab.Pane>
    );
};
