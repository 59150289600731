import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { useDispatch, useSelector } from "react-redux";
import { trainerSelector } from "../../selectors";
import { Button, Form, Grid, Header, TextArea, TextAreaProps } from "semantic-ui-react";
import { TrainerApi } from "../../trainerApi";
import { loadTrainerDetail } from "../../actions";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { ChangeEventType } from "@common/history/model";
import { TrainerDetailModel } from "../../model";
import { Input } from "@neworbit/simpleui-input";
import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";

const expectedFilters = [
    { type: ChangeEventType.TrainerNote, display: true },
    { type: ChangeEventType.TrainerComplaints, display: true },
    { type: ChangeEventType.DetailsChanged, display: true },
    { type: ChangeEventType.Comms, display: true },
    { type: ChangeEventType.BillingAndInvoices, display: true },
    { type: ChangeEventType.Monitoring, display: true },
    { type: ChangeEventType.DataModification, display: true },
    { type: ChangeEventType.LicenceUpdated, display: true }
];

export function TrainerHistory() {
    const dispatch = useDispatch();
    const trainer = useSelector(trainerSelector) as TrainerDetailModel;
    const [files, setFiles] = React.useState<FileList>();
    const [noteText, setNoteText] = React.useState("");
    const [recordAsComplaint, setRecordAsComplaint] = React.useState(false);

    const onFileChange = React.useCallback((fileList: FileList) => {
        setFiles(fileList);
    }, []);

    function onTextAreaChange(e: any, d: TextAreaProps) {
        setNoteText(d.value.toString());
    }

    const handleOnClick = React.useCallback(async () => {
        const trainerApi = new TrainerApi();
        await trainerApi.addNoteToTrainer(trainer.id, noteText, recordAsComplaint, files && files.length > 0 ? files[0] : undefined);
        await dispatch(loadTrainerDetail({ trainerId: trainer.id, overrideCheck: true }));
        setNoteText("");
        setFiles(undefined);
    }, [dispatch, files, noteText, recordAsComplaint, trainer.id]);

    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        expectedFilters
            .map(filter => filter.type));

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    return (
        <>
            <div>
                <Grid className="note-container">
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form>
                                <TextArea placeholder={"Enter note"} rows={2} onChange={onTextAreaChange} value={noteText} />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Input.Checkbox value={recordAsComplaint} onChange={setRecordAsComplaint} label="Record as Complaint" />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button disabled={(noteText?.length || 0) === 0} className="wide" onClick={handleOnClick} floated="left" content="Add Note" />
                            <FileUploadInput
                                fileList={files}
                                uploadLabel="Add File"
                                onChange={onFileChange}
                            />
                            {(files && files.length > 0) && <span>{files[0].name}</span>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div>
                <Header size="large">Trainer Journey</Header>
                <Grid>
                    <Grid.Column width={3}>
                        <Grid.Row>
                            Filters by group:
                        </Grid.Row>
                        {expectedFilters.map(eventType => eventType.display ?
                            <HistoryFilter
                                key={eventType.type}
                                eventType={eventType.type}
                                value={filters.includes(eventType.type)}
                                onChange={addOrRemove}
                            /> : null)}
                        <Grid.Row>
                            <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <HistoryContent showDetailsDefault={false} entries={trainer?.history?.filter(
                            record => filters.includes(record.eventType))} />
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}
