import { MiddlewareAPI, Action } from "redux";

import { AxiosError } from "axios";
import { redirectToLogin, sessionTimedOut } from "@common/auth";
import { AsyncDispatch } from "@common/redux-helpers";

import * as toast from "./toasts";

const errorsToIgnore = ["Not authenticated"];

export const toastMiddleware = (api: MiddlewareAPI) => (next: AsyncDispatch) => async (action: Action) => {
    try {
        return await next(action);
    } catch (e) {
        const axiosError = e as AxiosError;
        if (axiosError.response && axiosError.response.status) {
            switch (axiosError.response.status) {
                case 400:
                    const errors = axiosError.response.data;
                    const keys = Object.keys(errors) as unknown as (keyof typeof errors)[];
                    if (keys.length > 0) {
                        keys.forEach(key => toast.error(errors[key]));
                    } else {
                        toast.error("There was a problem with your request, please try again later.");
                    }
                    throw axiosError;
                case 401:
                    const currentUser = api.getState().currentUser;
                    if (currentUser.authenticated) {
                        toast.info("You have been logged out due to inactivity. Please log in again.");
                        api.dispatch<any>(redirectToLogin);
                    }
                    return api.dispatch(sessionTimedOut());
                case 500:
                    toast.error("Something went wrong, please try again later.");
                    throw axiosError;
                default:
                    break;
            }
        }

        const message = typeof e === "string" ? e : e.message;
        if (errorsToIgnore.indexOf(message) === -1) {
            toast.error(message);
            throw e;
        } else {
            // Ignore the error by returning a dummy promise
            return new Promise<void>(resolve => resolve());
        }
    }
};
