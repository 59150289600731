import * as React from "react";
import { useState } from "react";
import { Accordion, AccordionTitleProps, Grid, Icon, List } from "semantic-ui-react";
import {
    CriteriaEnum, FormValidationResult, QuestionCriterium,
    QuestionSection,
    QuestionSubSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { CriteriaLabelAndValue } from "./CriteriaLabelAndValue";
import { SubSectionHeader } from "@common/crud/eventInstance/components/monitoring/SubSectionHeader";
import {
    Criterium,
    TrainerMonitoringReportSection,
    TrainerMonitoringReportSubSection
} from "@common/crud/trainer/trainerMonitoringModel";

export interface QuestionnaireProps {
    active: boolean;
    section: QuestionSection;
    sectionData: TrainerMonitoringReportSection;
    onHandleActiveClick: (e: any, titleProps: AccordionTitleProps) => void;
    updateValue: (value: Criterium) => void;
    updateSubSectionSatisfaction: (value: TrainerMonitoringReportSubSection) => void;
    lockedForChange: boolean;
    formValidationResult: FormValidationResult[];
}

export const Questionnaire: React.FC<QuestionnaireProps> = ((props) => {
    const {
        active, section, onHandleActiveClick, sectionData, updateValue, updateSubSectionSatisfaction, lockedForChange, formValidationResult
    } = props;
    const [unsatisfactorySubSections, setUnsatisfactorySubSections] = useState<string[]>([]);

    const satisfactionChanged = React.useCallback((id: string) => {
        setUnsatisfactorySubSections(
            unsatisfactorySubSections.find(u => u === id) === undefined
                ? unsatisfactorySubSections.concat(id)
                : unsatisfactorySubSections.filter(s => s !== id));

        const currentSubSection = sectionData?.subSections?.find(ss => ss.id === id);
        if (currentSubSection) {
            currentSubSection.unsatisfactory = !currentSubSection?.unsatisfactory;
            updateSubSectionSatisfaction(currentSubSection);
        }
    }, [updateSubSectionSatisfaction, sectionData, unsatisfactorySubSections]);

    const sectionSummary = section.summaryCriterium !== undefined;
    const isUnsatisfactory = sectionData?.subSections?.some(s => s.sectionId === section.id && s.unsatisfactory);
    const sectionTitle = () => {
        return (
            <>
                {isUnsatisfactory ?
                    <p>{section.title} - Unsatisfactory</p> :
                    <p>{section.title}</p>}
            </>
        );
    };

    const isValid = formValidationResult === undefined || !formValidationResult?.some(v => v.validResult === false && v.seqNum === section.seqNum);
    const getCriteriumData = (currentQuestionSubSection: QuestionSubSection, criteriaId: string): Criterium => {
        const currentSubSectionData = sectionData?.subSections?.find(ss => ss.id === currentQuestionSubSection.id);
        return currentSubSectionData?.criteria.find(c => c.id === criteriaId);
    };

    const updateCriteriaValue = React.useCallback((value: Criterium) => {
        updateValue(value);
    }, [updateValue]);

    return (
        <Accordion key={section.id} exclusive={false}>
            <Accordion.Title active={active} index={section.seqNum} onClick={onHandleActiveClick}>
                <div className="title-container">
                    <div>{sectionTitle()} {!isValid && <Icon name="warning" color="red" />}</div>
                    <span className="title-icon" />
                </div>
            </Accordion.Title>
            {section?.subSections?.map(ss => (
                <Accordion.Content
                    key={section.id + "-" + ss.id}
                    active={active}
                >
                    <Grid padded>
                        <SubSectionHeader
                            headerText={ss.text}
                            id={ss.id}
                            unsatisfactory={sectionData?.subSections?.find(ssd => ssd.id === ss.id).unsatisfactory}
                            satisfactionChanged={satisfactionChanged}
                            lockedForChange={lockedForChange} />
                        <Grid.Row>
                            <Grid>
                                {sectionData?.subSections?.find(ssd => ssd.id === ss.id).unsatisfactory &&
                                    sectionData?.subSections?.some(ssd => !!ssd.criteria.length) &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            Please select (check) only the items that are unsatisfactory, (leave satisfactory items unchecked)
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {!sectionData?.subSections?.find(ssd => ssd.id === ss.id).unsatisfactory &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <List as='ol'>
                                                {ss.criteria?.map((r) => <List.Item as='li' value='-' key={r.id} content={r.text} />)}
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {sectionData?.subSections?.find(ssd => ssd.id === ss.id).unsatisfactory &&
                                    <Grid.Row>
                                        <Grid.Column width={16} >
                                            <List>
                                                {ss.criteria?.filter(c => c.typeEnum === undefined || c.typeEnum === CriteriaEnum.check).map(c => (
                                                    <Grid.Row key={c.id}>
                                                        <Grid.Column width={16} >
                                                            <CriteriaLabelAndValue
                                                                questionCriterium={c}
                                                                criterium={getCriteriumData(ss, c.id)}
                                                                updateValue={updateCriteriaValue}
                                                                lockedForChange={lockedForChange}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))}
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>}
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <List>
                                            {ss.criteria?.filter(c => c.typeEnum === CriteriaEnum.text).map(c => (
                                                <Grid.Row key={c.id}>
                                                    <CriteriaLabelAndValue
                                                        questionCriterium={c}
                                                        criterium={getCriteriumData(ss, c.id)}
                                                        updateValue={updateCriteriaValue}
                                                        lockedForChange={lockedForChange}
                                                    />
                                                </Grid.Row>
                                            ))}
                                        </List>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Row>
                    </Grid>
                </Accordion.Content>
            ))}
            {sectionSummary && active && section.summaryCriterium.length > 0 &&
                <>
                    <h3>Comments for {section.title} (Mandatory)</h3>
                    {section.summaryCriterium.map((sc: QuestionCriterium, index) => (<CriteriaLabelAndValue
                        key={sc.id}
                        questionCriterium={sc}
                        criterium={sectionData?.summaryCriteria && sectionData?.summaryCriteria[index]}
                        updateValue={updateCriteriaValue}
                        lockedForChange={lockedForChange}
                    />)
                    )}
                </>
            }
        </Accordion>
    );
});
