import * as React from "react";
import { useFormikContext, Field } from "formik";

import { SemanticFormError } from "./SemanticFormError";

interface SemanticFormFieldProps<T> {
    name: Exclude<keyof T, number | symbol>;
    as?: React.ComponentType;
    label: string;
    note?: string;
    placeholder: string;
    validate?: (value: string) => string | null;
}

export function SemanticFormField<T extends object>({
    name,
    as,
    label,
    note,
    placeholder,
    validate
}: SemanticFormFieldProps<T>) {

    const { errors, touched } = useFormikContext<T>();
    const hasErrors = errors[name] && touched[name];

    const classes = [];

    if (hasErrors) {
        classes.push("error");
    }

    if (note) {
        classes.push("field-with-note");
    }

    return (
        <>
            <Field
                name={name}
                type="text"
                as={as}
                label={label}
                className={classes.join(" ")}
                placeholder={placeholder}
                validate={validate}
            />
            {note && <p className="field-note">{note}</p>}
            <SemanticFormError name={name} />
        </>
    );
}
