import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ObjectLiteral, push } from "redux-little-router";
import { SearchOptions, Area, Language, YesNoAnyEnum } from "@common/crud/eventInstance/model";
import {
    eventInstanceSearchOptionsSelector,
    groupedEventInstancesSelector,
    eventInstancesSelector
} from "../../selectors";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { StringValues } from "@common/model";
import { AuthorizeProps } from "reauthorize/dist/Authorize";
import { ActionModal } from "../bulkActions/ActionModal";
import { loadEventInstancesAndGroups } from "../../actions";
import { compare } from "@common/helpers/compare";
import { appSelector } from "@common/crud/common/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DrinkDriveFilters } from "@common/crud/eventInstance/components/drinkDrive/DrinkDriveFilters";
import { AllDrinkDriveItems } from "@common/crud/eventInstance/components/drinkDrive/AllDrinkDriveItems";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

const PAGE_SIZE = 100;

interface DrinkDriveEventInstanceListWithFiltersProps {
    area?: Area;
}

const parseOptions = (options: SearchOptions) => {
    const parsedOptions: StringValues<SearchOptions> = {
        fromDate: options.fromDate?.format("YYYY-MM-DD") || undefined,
        toDate: options.toDate?.format("YYYY-MM-DD") || undefined,
        hasAvailableSeats: options.hasAvailableSeats?.toString(),
        requiresTrainers: options.requiresTrainers ? YesNoAnyEnum[options.requiresTrainers] : undefined,
        oneToOneOnly: options.oneToOneOnly?.toString(),
        eventType: options.eventType?.join(",") || undefined,
        forceId: options.forceId?.join(",") || undefined,
        venueId: options.venueId?.join(",") || undefined,
        getOnlyUnconfirmedByTrainer: options.getOnlyUnconfirmedByTrainer?.toString(),
        trainerId: options.trainerId,
        eventStatus: options.eventStatus?.join(",") || undefined,
        deliveryType: options.deliveryType,
        availableForOtherTrainers: options.availableForOtherTrainers ? YesNoAnyEnum[options.availableForOtherTrainers] : undefined,
        maxPageSize: options.maxPageSize?.toString(),
        language: Language[options.language],
        daysOfWeek: options?.daysOfWeek?.join(",") || undefined,
        dayPeriod: options?.dayPeriod?.join(",") || undefined,
        filtersCleared: options?.filtersCleared?.toString(),
        dsaAreaId: options.dsaAreaId,
        includeAlreadyStartedGroups: options?.includeAlreadyStartedGroups?.toString()
    };

    return parsedOptions;
};

export const DrinkDriveEventInstanceListWithFilters: React.FC<DrinkDriveEventInstanceListWithFiltersProps> = ({ area }) => {
    const [groupedEventInstanceIds, setGroupedEventInstanceIds] = React.useState<string[]>([]);
    const [hasMonitors, setHasMonitors] = React.useState<boolean>(false);
    const [searchOptions, setSearchOptions] = React.useState<SearchOptions>({ includeAlreadyStartedGroups: true });
    const [initialLoad, setInitialLoad] = React.useState(true);
    const dispatch = useDispatch();
    const routerSearchOptions = useSelector(eventInstanceSearchOptionsSelector);
    const groupedEventInstances = useSelector(groupedEventInstancesSelector);
    const eventInstances = useSelector(eventInstancesSelector);
    const app = useSelector(appSelector);

    const setQuery = React.useCallback((query: ObjectLiteral<string>) => {
        dispatch(push({ pathname: undefined, query }));
    }, [dispatch]);

    React.useEffect(() => {
        // prevent dispatching the initial state so user can back track
        if (initialLoad) {
            setInitialLoad(false);
            return;
        }
        const query = parseOptions(routerSearchOptions);
        setQuery(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const loadDrinkDriveInstances = (options: SearchOptions) => dispatch(loadEventInstancesAndGroups({ options }));

        const truncatedOptions = { ...routerSearchOptions };
        Object.keys(truncatedOptions).forEach(key => truncatedOptions[key] === undefined ? delete truncatedOptions[key] : {});
        if (!compare(truncatedOptions, searchOptions)) {
            loadDrinkDriveInstances({ ...truncatedOptions, workflowTypes: [WorkflowTypeEnum.DDRS] });
            setSearchOptions(truncatedOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerSearchOptions]);

    const pageSize = searchOptions.maxPageSize ? searchOptions.maxPageSize : PAGE_SIZE;

    const onEventInstanceSelection = React.useCallback((eventInstanceId: string) => {
        if (groupedEventInstanceIds?.includes(eventInstanceId)) {
            const newSelectedIds = groupedEventInstanceIds?.filter(x => x !== eventInstanceId);
            setGroupedEventInstanceIds(newSelectedIds);
        } else {
            setGroupedEventInstanceIds([...groupedEventInstanceIds, eventInstanceId]);
        }

    }, [groupedEventInstanceIds]);

    const clearSelection = React.useCallback(() => {
        setGroupedEventInstanceIds([]);
    }, []);

    const onOptionsChanged = React.useCallback((options: SearchOptions) => {
        const query = parseOptions(options);
        clearSelection();
        setQuery(query);
    }, [clearSelection, setQuery]);

    const selectAll = React.useCallback(() => {
        if (groupedEventInstanceIds.length === groupedEventInstances.length) {
            setGroupedEventInstanceIds([]);
            setHasMonitors(false);
        } else {
            if (groupedEventInstances) {
                setGroupedEventInstanceIds(groupedEventInstances?.map(x => x.id));
                setHasMonitors(!!groupedEventInstances?.find(x => x.hasMonitor));
            }
        }
    }, [groupedEventInstanceIds, groupedEventInstances]);

    const isOnsiteCourse =
        groupedEventInstances?.some(x => groupedEventInstanceIds.find(c => c === x.eventInstanceGroupItems[0]?.eventInstanceId)?.length > 0 &&
            x.deliveryType === DeliveryTypeEnum.Onsite);

    return (
        <>
            <DrinkDriveFilters
                app={app}
                area={area}
                onOptionsChanged={onOptionsChanged}
                searchOptions={routerSearchOptions}
            />
            <Grid.Row>
                <Grid.Column width={16}>
                    {groupedEventInstances?.length > pageSize &&
                        <p>Showing first {pageSize} courses; use the search box to filter.</p>
                    }
                    <AllDrinkDriveItems
                        selectedEventInstanceGroups={groupedEventInstanceIds}
                        groupedEventInstances={groupedEventInstances?.slice(0, pageSize)}
                        eventInstances={eventInstances}
                        onEventInstanceGroupSelect={onEventInstanceSelection}
                        selectAll={selectAll}
                        area={area}
                    />
                </Grid.Column>
            </Grid.Row>
            {(area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementTrainerEventInstance) &&
                <AuthorizedGridColumn
                    authorize={TtcPlannerRole}
                    children={
                        <ActionModal
                            area={area}
                            selectedEventInstanceIds={groupedEventInstanceIds}
                            isOnsiteCourse={isOnsiteCourse}
                            clearSelection={clearSelection}
                            hasMonitor={hasMonitors}
                        />}
                />
            }
        </>
    );
};

const AuthorizedGridColumn: React.FC<AuthorizeProps> = ({ authorize, children }) => {
    return (
        <Authorize authorize={authorize}>
            <Grid.Row>
                <Grid.Column width={16}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Authorize>
    );
};
