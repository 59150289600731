import { Address } from "@common/crud/common";
import moment from "moment";
import * as React from "react";
import { FormEvent, useCallback } from "react";
import { CheckboxProps, Grid, Radio } from "semantic-ui-react";

export type FieldType = string | number | moment.Moment | number[] | Address | { name: string; telephone: string; email: string }[];

export const UpdateDelegatesMatchingDataField =
({ label,
    attendeeManualValue,
    attendeeManualValuePresentation,
    attendeeDelegateValue,
    attendeeDelegateValuePresentation,
    resultValue,
    attendeeFieldDisplayName,
    onUpdateChanged }:
    { label: string;
        attendeeManualValue: FieldType;
        attendeeManualValuePresentation?: string;
        attendeeDelegateValue: FieldType;
        attendeeDelegateValuePresentation?: string;
        resultValue?: FieldType;
        attendeeFieldDisplayName?: string;
        onUpdateChanged?: (value: FieldType, attendeeFieldDisplayName?: string) => void; }) => {

    const onRadioChange = useCallback((_event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        onUpdateChanged((data.checked && data.value === "manual") ? attendeeManualValue : attendeeDelegateValue, attendeeFieldDisplayName);
    }, [attendeeDelegateValue, attendeeManualValue, onUpdateChanged, attendeeFieldDisplayName]);

    return (
        <Grid.Row className={"mismatched-field"}>
            <Grid.Column width={4} as="h4">
                {label}
            </Grid.Column>
            <Grid.Column width={6}>
                <Radio
                    checked={resultValue === attendeeManualValue}
                    onChange={onRadioChange}
                    value="manual"
                    label={attendeeManualValuePresentation || attendeeManualValue}
                />
            </Grid.Column>
            <Grid.Column width={6}>
                <Radio
                    checked={resultValue === attendeeDelegateValue}
                    onChange={onRadioChange}
                    value="delegate"
                    label={attendeeDelegateValuePresentation || attendeeDelegateValue}
                />
            </Grid.Column>
        </Grid.Row>
    );};
