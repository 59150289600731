/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { Modal } from "@common/components";
import { Button, CheckboxProps, Container, Input } from "semantic-ui-react";
import { TrainersSelector } from "./TrainersSelector";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { MultiDayTrainerAvailabilitySelectModel, TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { useDispatch, useSelector } from "react-redux";
import { FeeAssign } from "./FeeAssign";
import { allocateTrainers } from "../../actions";
import {
    CarType,
    GroupEventInstancesModel,
    MonitoringCandidates,
    OtherTrainerTypeCategoryEnum,
    PracticalEventTrainer,
    SameDayEventInstanceModel,
    TrainerType
} from "../../model";
import {
    getAllocationsFromExistingTrainers,
    getFlattenedTrainers,
    getLimitMessage,
    getTrainerAllocations,
    getTrainerTypeAsString,
} from "./helper";
import { EventInstanceApi } from "../../eventInstanceApi";
import { allocateMultiDayTrainers as allocateMultiDayTrainers } from "@common/crud/eventInstanceGroup/actions";
import { getDrinkDriveTrainerAllocations as getMultiDayTrainerAllocations } from "@common/crud/eventInstance/components/trainers/helper";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { TrainerApi } from "@common/crud/trainer";
import { toast } from "react-toastify";
import { AvailabilityContext } from "./availability-context";
import { MultiDayTrainersSelector } from "@common/crud/eventInstance/components/trainers/MultiDayTrainersSelector";
import "./trainer-allocation-modal.scss";
import { DurationTypeEnum, ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { MonitorAssign } from "./MonitorAssign";
import { isWorkflowConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";
import { businessLineTypeSelector } from "@common/redux-helpers";

interface OwnProps {
    eventInstanceId: string;
    isDigital: boolean;
    eventTypeName: string;
    courseFee: number[];
    feeType: TrainerType;
    startDate: moment.Moment;
    startTime: moment.Duration;
    deliveryDateTime: moment.Moment;
    deliveryDateTimeEnd: moment.Moment;
    trainerType: TrainerType;
    theoryAndPractical: boolean;
    trainers: {
        id: string;
        fee: number;
        sundries: number;
        name: string;
        carType: CarType;
        availableForOtherTrainers?: boolean;
        dateMadeAvailableForOtherTrainers?: moment.Moment;
    }[];
    assignedMonitors: { id: string; fee: number; sundries: number; name: string }[];
    monitoringCandidates: MonitoringCandidates[];
    initialMonitoredTrainersIds: string[];
    isOneToOne: boolean;
    multiDayParts: GroupEventInstancesModel[];
    group?: EventInstanceGroupModel;
    moduleType: ModuleTypeEnum;
    durationType: DurationTypeEnum;
    practicalTrainers: PracticalEventTrainer[];
    publishedDate: moment.Moment;
    eventTypeId: string;
    workflowType: WorkflowTypeEnum;
};

export const TrainerAllocationModal: React.FC<OwnProps> = ({
    isDigital,
    eventTypeName,
    courseFee,
    feeType,
    startDate,
    startTime,
    deliveryDateTime,
    deliveryDateTimeEnd,
    trainers,
    eventInstanceId,
    trainerType,
    theoryAndPractical,
    assignedMonitors,
    monitoringCandidates,
    initialMonitoredTrainersIds,
    isOneToOne,
    multiDayParts,
    group,
    moduleType,
    durationType,
    practicalTrainers,
    publishedDate,
    eventTypeId,
    workflowType
}) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [modalStage, setModalStage] = React.useState<number>(1);
    const [allTrainers, setAllTrainers] = React.useState<Record<string, TrainerAvailabilitySelectModel[]>>({});
    const [filteredTrainers, setFilteredTrainers] = React.useState<Record<string, TrainerAvailabilitySelectModel[]>>();
    const [selectedTrainers, setSelectedTrainers] = React.useState<TrainerAvailabilitySelectModel[]>([]);
    const [savedSelectedTrainers, setSavedSelectedTrainers] = React.useState<TrainerAvailabilitySelectModel[]>([]);
    const [selectedEventInstances, setSelectedEventInstances] = React.useState<SameDayEventInstanceModel[]>([]);
    const [selectedMultiDayParts, setSelectedMultiDayParts] = React.useState<GroupEventInstancesModel[]>();
    const [selectedMonitoringCandidateIds, setSelectedMonitoringCandidateIds] = React.useState<string[]>([]);
    const [forbiddenOtherMonitoringCandidateIds, setForbiddenOtherMonitoringCandidateIds] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(false);
    const isCorporateOrConstruction = isWorkflowCorporate(workflowType) || isWorkflowConstruction(workflowType);
    const businessLineType = useSelector(businessLineTypeSelector);

    React.useEffect(() => {
        setSelectedMultiDayParts(multiDayParts);
        setSelectedMonitoringCandidateIds(initialMonitoredTrainersIds);
    }, [multiDayParts, initialMonitoredTrainersIds]);

    const isMultiDay = group?.eventInstanceGroupItems?.length > 0;
    const availabilityContextOptions = {
        eventInstanceGroup: group,
        trainerAvailabilities: allTrainers,
        distinctAvailability: [] as TrainerAvailabilitySelectModel[],
        isMultiDay
    };
    const dispatch = useDispatch();

    function getMonitoringCandidates(): MonitoringCandidates[]
    {
        if (trainerType === TrainerType.MonitorTrainer) {
            return monitoringCandidates;
        }

        const monitoringCandidatesOfDifferentTrainerType =
            monitoringCandidates.filter(x => (x.otherTrainerType !== (trainerType === TrainerType.OtherTrainer)));

        const mappedSelectedTrainers = selectedTrainers.filter(x => !monitoringCandidatesOfDifferentTrainerType.map(mc => mc.id).includes(x.id)).map(t =>
            ({
                name: t.fullName,
                id: t.id,
                otherTrainerType: trainerType === TrainerType.OtherTrainer,
                otherTrainerTypeCategory: t.otherTrainerTypeCategory
            } as MonitoringCandidates));

        return [...mappedSelectedTrainers, ...monitoringCandidatesOfDifferentTrainerType];
    }

    async function selectTrainer(trainer: TrainerAvailabilitySelectModel) {
        const fee = trainer.isRCCoverOnDate && trainerType !== TrainerType.PracticalTrainer ? 0 : courseFee[feeType];
        const hasOneCarType = (trainer.hasAutomaticAttribute && !trainer.hasManualAttribute) || (trainer.hasManualAttribute && !trainer.hasAutomaticAttribute);
        const carType = trainer.carType ?? isCorporateOrConstruction
            ? (trainer.hasPracticalAttribute ? "Any" : null)
            : hasOneCarType ? (trainer.hasAutomaticAttribute ? "Automatic" : "Manual") : null;
        const assignAsPracticalAndTheoryTrainer = theoryAndPractical &&
            (isCorporateOrConstruction
                ? trainer.hasPracticalAttribute
                : (trainer.hasAutomaticAttribute || trainer.hasManualAttribute))
            && !trainer.isPracticalBookedForBothModule;
        setSelectedTrainers([
            ...selectedTrainers,
            { ...trainer,
                fee,
                isBooked: trainer.isBooked,
                carType: theoryAndPractical ? carType : null,
                assignAsPracticalAndTheoryTrainer,
                availableForOtherTrainers: trainer.availableForOtherTrainers,
                dateMadeAvailableForOtherTrainers: trainer.dateMadeAvailableForOtherTrainers,
                otherTrainerTypeCategory: trainer.otherTrainerTypeCategory
            }
        ]);
    }

    function multiDaySelectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel) {
        const isRCCoverOnAnyDay = Object.values(trainer.dayAvailabilities).some(ta => ta.some(tad => tad.id === trainer.id && tad.isRCCoverOnDate));
        const fee = isRCCoverOnAnyDay && trainerType !== TrainerType.PracticalTrainer ? 0 : courseFee[feeType];
        const count = Array.from({ length: group.eventInstanceGroupItems.length }, (v, k) => k+1);
        const newTAObject = Object.values(trainer.dayAvailabilities).find(ta => ta.some(tad => tad.id === trainer.id))[0];
        // this button in multi day applies to ALL days so will only have one trainer selected
        setSelectedTrainers([
            // eslint-disable-next-line max-len
            { ...newTAObject, fee, isBooked: multiDayParts.some(dd => dd.trainerIds.some(t => t === trainer.id)), allocatedDayNumbers: count }
        ]);
        const newResult = [...selectedMultiDayParts].map((dayPart) => ({ ...dayPart, trainerIds: [trainer.id] }));

        setSelectedMultiDayParts(newResult);
        setLoading(false);
    }

    function deselectTrainer(trainer: TrainerAvailabilitySelectModel) {
        setSelectedTrainers([...selectedTrainers].filter(s => s.id !== trainer.id));
        setSelectedMonitoringCandidateIds([...selectedMonitoringCandidateIds].filter(id => id !== trainer.id));
    }

    function multiDayDeselectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel) {
        setSelectedTrainers([...selectedTrainers].filter(s => s.id !== trainer.id));
        const newResult = [...selectedMultiDayParts].map((dayPart) =>
            ({ ...dayPart,  trainerIds: dayPart.trainerIds.indexOf(trainer.id)===-1?[...dayPart.trainerIds] :[] }));

        setSelectedMultiDayParts(newResult);
    }

    async function onEditClick() {
        setLoading(true);
        const filtered = group?.eventInstanceGroupItems?.length > 0 ?
            await new TrainerAvailabilityApi().getTrainerAvailabilityForEventInstanceGroup(group.id, trainerType) :
            await new TrainerAvailabilityApi().getTrainerAvailabilityForEventInstance(eventInstanceId, trainerType);
        setFilteredTrainers(filtered);
        setAllTrainers(filtered);
        const observerMonitoringCandidateIds = monitoringCandidates.filter(x => x.otherTrainerType).map(x => x.id);

        if (observerMonitoringCandidateIds.length > 0) {
            const trainerApi = new TrainerApi();
            const observerMonitoringAllowedDictionary =
            await trainerApi.getTrainersHaveAttributeForType(observerMonitoringCandidateIds, eventTypeId, trainerType, isDigital);
            setForbiddenOtherMonitoringCandidateIds(Object.keys(observerMonitoringAllowedDictionary)
                .filter(key => !observerMonitoringAllowedDictionary[key]));
        }

        if (isMultiDay) {
            setSelectedMultiDayParts(multiDayParts);
            const trainerInit = currentTrainersInit(multiDayParts, filtered);
            setSelectedTrainers(trainerInit);
            setSavedSelectedTrainers(trainerInit);
        } else {
            const flattenTrainers = getFlattenedTrainers(trainers, filtered);
            setSelectedTrainers(flattenTrainers);
            setSavedSelectedTrainers(flattenTrainers);
        }

        setOpen(true);
        setLoading(false);
    }

    function onCloseModalClick() {
        setSelectedMultiDayParts([]);
        setSelectedTrainers([]);
        setModalStage(1);
        setOpen(false);
    }

    async function onContinueClick() {
        if (modalStage === 1) {
            const promises = [];

            if (workflowType === WorkflowTypeEnum.Dors) {
                promises.push(new EventInstanceApi().getSameDayEventInstances(eventInstanceId, selectedTrainers.map(t => t.id))
                    .then(value => setSelectedEventInstances(value)));
            }

            const observerMonitoringCandidateIds = (trainerType === TrainerType.OtherTrainer ?
                selectedTrainers : monitoringCandidates.filter(x => x.otherTrainerType)).map((x: TrainerAvailabilitySelectModel|MonitoringCandidates) => x.id);

            if (observerMonitoringCandidateIds.length > 0) {
                promises.push(new TrainerApi().getTrainersHaveAttributeForType(observerMonitoringCandidateIds, eventTypeId, trainerType, isDigital)
                    .then(value => setForbiddenOtherMonitoringCandidateIds(Object.keys(value).filter(key => !value[key]))));
            }

            if (promises.length > 0) {
                setLoading(true);
                await Promise.all(promises);
                setLoading(false);
            }
        }

        setModalStage(modalStage + 1);
    }

    function onBackClick() {
        setModalStage(modalStage - 1);
    }

    const getMonitoredTrainersIds = (): string[] => {
        const assigningMonitors = trainerType === TrainerType.MonitorTrainer;
        const assigningTheory = trainerType === TrainerType.TheoryTrainer;
        const assigningObservers = trainerType === TrainerType.OtherTrainer;

        // If there's only one trainer we can assume they're the one being monitored.
        if (assigningMonitors && monitoringCandidates.length === 1) {
            if (forbiddenOtherMonitoringCandidateIds.includes(monitoringCandidates[0].id)) {
                toast.warning("The 'other' trainer does not have the correct theory trainer attribute to be monitored");
                return [];
            }

            return [monitoringCandidates[0].id];
        }

        if ((assigningTheory || assigningObservers) && assignedMonitors.length === 1 && selectedTrainers.length === 1
            && !monitoringCandidates.length) {
            if (assigningObservers && forbiddenOtherMonitoringCandidateIds.includes(selectedTrainers[0].id)) {
                toast.warning("The 'other' trainer does not have the correct theory trainer attribute to be monitored");
                return [];
            }
            return [selectedTrainers[0].id];
        }

        if (isMultiDay) {

            if (selectedMonitoringCandidateIds.length) {
                return selectedMonitoringCandidateIds;
            }

            if (!monitoringCandidates.length && assignedMonitors.length > 0) {
                return selectedTrainers.map(st => st.id);
            }

            return monitoringCandidates
                .filter((value, index) => selectedTrainers.some(trainer => trainer.allocatedDayNumbers.includes(index + 1)))
                .map(trainer => trainer.id);
        }

        return selectedMonitoringCandidateIds;
    };

    const checkMultiDayAvailability = async () => {
        const trainerApi = new TrainerApi();
        const result: boolean[] = [];

        await Promise.all(selectedMultiDayParts.map(async (trainerSelect) => {
            if (trainerSelect.trainerIds.length > 0) {
                const gi = group.eventInstanceGroupItems.find((i: EventInstanceGroupItemModel) => i.dayNumber === trainerSelect.dayNumber);
                await Promise.all(trainerSelect.trainerIds.map(async ta => {
                    result.push(await trainerApi.checkIfTrainerIsAllocatedOutsideGroup(
                        group.id, ta, moment(gi.deliveryDateTime), moment(gi.deliveryDateTime).clone().add(moment.duration(gi.eventDuration))));
                }));
            }
        }));

        return result;
    };

    const extractTrainersFromMultiDayParts = (multiDayPartList: GroupEventInstancesModel[]): TrainerAvailabilitySelectModel[] => {
        const distinctTrainers = getDistinct(allTrainers);
        return selectDistinctTrainersList(multiDayPartList, distinctTrainers);
    };

    const monitorAllocationRequired = () => {
        if (isDigital && assignedMonitors.length === 0 && selectedTrainers.length + monitoringCandidates.length < 2) {
            return false;
        }

        if ((trainerType === TrainerType.TheoryTrainer || trainerType === TrainerType.OtherTrainer)
            && (selectedTrainers.length + monitoringCandidates.filter(x => !(x.otherTrainerType === (trainerType === TrainerType.OtherTrainer))).length) > 1
            && assignedMonitors.length > 0) {
            return true;
        }

        return trainerType === TrainerType.MonitorTrainer && selectedTrainers.length > 0 && monitoringCandidates.length > 1;
    };

    const validateAllocateTrainers = async (trainerApi: TrainerApi, newTrainersConfiguration: TrainerAvailabilitySelectModel[]): Promise<boolean> => {
        if (isMultiDay) {
            if (!selectedTrainers.some((t => t.allocatedDayNumbers))) {
                toast.warning("You must make an allocation change to continue");
                return false;
            }
        }

        const selectedTrainersWithoutSaved = isMultiDay ?
            newTrainersConfiguration.filter(sel => !savedSelectedTrainers.some(saved => saved.id === sel.id)) :
            selectedTrainers.filter(sel => !savedSelectedTrainers.some(saved => saved.id === sel.id));

        const selectedTrainersAllocationStatuses = isMultiDay ?
            await checkMultiDayAvailability() :
            await Promise.all(selectedTrainersWithoutSaved.map(t =>
                trainerApi.checkIfTrainerIsAllocated(t.id, durationType === DurationTypeEnum.Combined ? eventInstanceId : undefined, deliveryDateTime,
                    deliveryDateTimeEnd)));

        const newSelectedTrainers = selectedTrainersWithoutSaved.filter((t, i) => !selectedTrainersAllocationStatuses[i]);

        if (newSelectedTrainers.length < selectedTrainersWithoutSaved.length) {
            toast.error(getToastErrorMsg(selectedTrainersWithoutSaved, newSelectedTrainers));

            const flattenTrainers = getFlattenedTrainers(trainers, filteredTrainers);
            setSelectedTrainers(flattenTrainers);
            setSavedSelectedTrainers(flattenTrainers);

            setModalStage(1);
            return false;
        }

        return true;
    };

    const validateAllocateMonitored = (monitoredTrainersIds: string[]): boolean => {
        if (monitorAllocationRequired() && monitoredTrainersIds.length < 1) {
            toast.warning("You must select at least one trainer to be monitored");
            return false;
        }

        return true;
    };

    const allocateTrainersSingleDay = async (monitoredTrainersIds: string[]) => {
        const trainerAllocations = getTrainerAllocations(selectedTrainers, selectedEventInstances, isCorporateOrConstruction);
        const requiresAdditionalAllocation =
            trainerType === TrainerType.TheoryTrainer && selectedTrainers.some(t => t.assignAsPracticalAndTheoryTrainer === true);

        await dispatch(allocateTrainers(
            eventInstanceId,
            trainerType,
            trainerAllocations,
            trainerType === TrainerType.MonitorTrainer && selectedTrainers.length === 0 ? [] : monitoredTrainersIds,
            !requiresAdditionalAllocation,
            selectedTrainers.some(trainer => trainer.isFeeNonStandard)
        ));

        if (requiresAdditionalAllocation) {
            const practicalIds = selectedTrainers.filter(t => t.assignAsPracticalAndTheoryTrainer).map(t => t.id);
            const existingPracticalTrainers = getAllocationsFromExistingTrainers(practicalTrainers);
            const newPracticalTrainers = trainerAllocations.filter(t =>
                practicalIds.includes(t.id) && !existingPracticalTrainers.map(existingTrainer => existingTrainer.id).includes(t.id));
            const newPracticalTrainersWithFixedFee = newPracticalTrainers.map(t => (
                { ...t, sundries: 0,  fee: courseFee[TrainerType.PracticalTrainer] }
            ));
            const newAndExistingPracticalTrainers = [
                ...existingPracticalTrainers,
                ...newPracticalTrainersWithFixedFee.map(t => ({ ...t, revalidateCourseFeeNonStandard: true }))];

            await dispatch(allocateTrainers(
                eventInstanceId,
                TrainerType.PracticalTrainer,
                newAndExistingPracticalTrainers,
                monitoredTrainersIds
            ));
        }
    };

    async function onAllocateClick() {
        setLoading(true);

        const trainerApi = new TrainerApi();
        const newTrainersConfiguration = isMultiDay ? extractTrainersFromMultiDayParts(selectedMultiDayParts) : [];
        const allocationValid = await validateAllocateTrainers(trainerApi, newTrainersConfiguration);

        if (!allocationValid) {
            setLoading(false);
            return;
        }

        const monitoredTrainersIds = getMonitoredTrainersIds();
        const monitoredAllocationValid = validateAllocateMonitored(monitoredTrainersIds);

        if (!monitoredAllocationValid) {
            setLoading(false);
            return;
        }

        const monitoredTrainers = monitoringCandidates.length === 0 || !!selectedMonitoringCandidateIds.length ?
            getMonitoringCandidates().filter(candidate => monitoredTrainersIds.includes(candidate.id)) :
            monitoringCandidates.filter(candidate => monitoredTrainersIds.includes(candidate.id));

        if (isMultiDay) {
            const trainerAllocations = getMultiDayTrainerAllocations(group.id, newTrainersConfiguration, selectedEventInstances);
            await dispatch(allocateMultiDayTrainers(group.id, trainerType, trainerAllocations, monitoredTrainers));
        } else {
            await allocateTrainersSingleDay(monitoredTrainersIds);
        }

        setSelectedTrainers([]);
        setSelectedMultiDayParts([]);
        setModalStage(1);
        setOpen(false);
        setLoading(false);
    };

    function getToastErrorMsg(oldSelectedTrainers: TrainerAvailabilitySelectModel[], newSelectedTrainers: TrainerAvailabilitySelectModel[]) {
        let errorMsg: string;
        const unavailableTrainerCount = oldSelectedTrainers.length - newSelectedTrainers.length;

        if (oldSelectedTrainers.length > 1) {
            errorMsg = `${unavailableTrainerCount > 1 ? "All" : "Some"} selected trainers are no longer available.`;
        } else {
            errorMsg = "The selected trainer is no longer available.";
        }
        errorMsg += " Please try again.";

        return errorMsg;
    }

    function onAllocateToCourseChanged(trainerId: string, courseId: string) {
        return function innerFn(_: any, { checked }: CheckboxProps) {

            const updatedSelectedEventInstances = selectedEventInstances
                .map(e => e.id === courseId ? { ...e, trainerIds: checked? [...e.trainerIds, trainerId]:
                    [...e.trainerIds].filter(x => x !== trainerId) } : e);

            setSelectedEventInstances(updatedSelectedEventInstances);
        };
    }

    function getDistinct(displayList: Record<string, TrainerAvailabilitySelectModel[]>): TrainerAvailabilitySelectModel[] {
        const distinctAvailability = [] as TrainerAvailabilitySelectModel[];
        const unique = [] as string[];
        for (const trainerVals of Object.values(displayList)) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < trainerVals.length; i++) {
                const current = trainerVals[i].id;
                if (!unique.find(u => u === current)) {
                    distinctAvailability.push(trainerVals[i]);
                    unique.push(current);
                }
            }
        }
        return distinctAvailability;
    }

    function onAllocateToDayNumber(trainerId: string, dayNumber: number) {
        const dayParts: GroupEventInstancesModel[] = [];

        if (trainerType === TrainerType.OtherTrainer) {
            for (const e of [...selectedMultiDayParts]) {
                const eCopy = { ...e };
                if (!e.trainerIds) {
                    eCopy.trainerIds = [];
                }
                if (e.dayNumber === dayNumber) {
                    if (e.trainerIds.includes(trainerId)) {
                        eCopy.trainerIds = eCopy.trainerIds.filter(id => id !== trainerId);
                    } else {
                        eCopy.trainerIds = [...eCopy.trainerIds, trainerId];
                    }
                }
                dayParts.push(eCopy);
            }
        } else {
            if (selectedMultiDayParts.some(sei => sei.trainerIds.some((t => t !== trainerId)) && sei.dayNumber === dayNumber)) {
                toast.error("You cannot allocate two trainers to the same day");
                return;
            }
            for (const e of [...selectedMultiDayParts]) {
                const eCopy = { ...e };
                if (!e.trainerIds) {
                    eCopy.trainerIds = [];
                }
                if (e.dayNumber === dayNumber) {
                    if (e.trainerIds[0] !== trainerId) {
                        eCopy.trainerIds = [trainerId];
                    } else {
                        eCopy.trainerIds = [];
                    }
                }
                dayParts.push(eCopy);
            }
        }

        setSelectedMultiDayParts(dayParts);
        const newDistinctTrainers = extractTrainersFromMultiDayParts(dayParts);
        setSelectedTrainers(newDistinctTrainers);
    }

    function currentTrainersInit(
        dayParts: GroupEventInstancesModel[],
        trainersAvailabilityModels: Record<string, TrainerAvailabilitySelectModel[]>): TrainerAvailabilitySelectModel[] {
        const distinctTrainers = getDistinct(trainersAvailabilityModels);
        return selectDistinctTrainersList(dayParts, distinctTrainers);
    }

    function selectDistinctTrainersList(
        dayParts: GroupEventInstancesModel[],
        distinctTrainers: TrainerAvailabilitySelectModel[]): TrainerAvailabilitySelectModel[] {
        const newTrainers: TrainerAvailabilitySelectModel[] = [];

        distinctTrainers.forEach(dt => dt.allocatedDayNumbers = []);
        for (const dayPart of dayParts) {
            for (const distinctTrainer of distinctTrainers) {
                if (dayPart.trainerIds.some(tid => tid === distinctTrainer.id)) {
                    if (!newTrainers.some(nt => nt.id === distinctTrainer.id)) {
                        const otherTrainerTypeCategory = trainerType === TrainerType.OtherTrainer
                            ? selectedTrainers.length
                                ? selectedTrainers.find(t => distinctTrainer.id === t.id)?.otherTrainerTypeCategory
                                : geMultiDayOtherTrainerCategory(dayPart, distinctTrainer.id)
                            : null;
                        const fee = distinctTrainer.isRCCoverOnDate && trainerType !== TrainerType.PracticalTrainer ? 0 : courseFee[feeType];
                        newTrainers.push({ ...distinctTrainer, otherTrainerTypeCategory, fee });
                    }
                    const updatingTrainer = newTrainers.find(nt => nt.id === distinctTrainer.id);
                    if (!updatingTrainer.allocatedDayNumbers) {
                        updatingTrainer.allocatedDayNumbers = [];
                    }
                    if (!updatingTrainer.allocatedDayNumbers.some(dn => dn === dayPart.dayNumber)) {
                        updatingTrainer.allocatedDayNumbers.push(dayPart.dayNumber);
                    }
                }
            }
        }
        return newTrainers;
    }

    const geMultiDayOtherTrainerCategory = (day: GroupEventInstancesModel, trainerId: string): OtherTrainerTypeCategoryEnum => {
        if (day.trainerIdsAndOtherTrainerTypeCategories !== null && day.trainerIdsAndOtherTrainerTypeCategories.some(ti => ti.trainerId === trainerId)) {
            return day.trainerIdsAndOtherTrainerTypeCategories.filter(ti => ti.trainerId === trainerId)[0].otherTrainerTypeCategory;
        }

        return null;
    };

    const updateSelectedTrainer =
    (trainerId: string,
        newValue: { fee: number; isFeeNonStandard: boolean } | { sundries: number }
        | { assignAsPracticalAndTheoryTrainer: boolean } | { carType: CarType } | { otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum }
        | { otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum; fee: number; isFeeNonStandard: boolean }) => {
        setSelectedTrainers(selectedTrainers.map(selTrainer => {
            if (selTrainer.id === trainerId) {
                return { ...selTrainer, ...newValue };
            }
            return selTrainer;
        }));
    };

    function onFeeChanged(trainerId: string, fee: number, isFeeNonStandard: boolean) {
        updateSelectedTrainer(trainerId, { fee, isFeeNonStandard });
    }

    function onSundriesChanged(trainerId: string, sundries: number) {
        updateSelectedTrainer(trainerId, { sundries });
    }

    function onAssignToPracticalChanged(trainerId: string, assignAsPracticalAndTheoryTrainer: boolean) {
        updateSelectedTrainer(trainerId, { assignAsPracticalAndTheoryTrainer });
    }

    function onTrainerCarTypeChanged(trainerId: string, carType: CarType) {
        updateSelectedTrainer(trainerId, { carType });
    }

    function onOtherTrainerTypeCategoryChanged(trainerId: string, otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum, fee: number) {
        updateSelectedTrainer(trainerId, { otherTrainerTypeCategory, fee, isFeeNonStandard: false });
    }

    function carTypeSelectionRequired() {
        if (isCorporateOrConstruction) {
            return false;
        }

        if (!theoryAndPractical && trainerType !== TrainerType.PracticalTrainer) {
            return false;
        }

        if (trainerType === TrainerType.TheoryTrainer) {
            return selectedTrainers.some(t => t.assignAsPracticalAndTheoryTrainer && (
                getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === null ||
                getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === undefined));
        }

        if (trainerType === TrainerType.PracticalTrainer) {
            return selectedTrainers.some(t =>
                getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === null ||
                getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === undefined);
        }

        return false;
    }

    function getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(trainer: TrainerAvailabilitySelectModel) {
        return isAlreadyAssignedToPractical(trainer.id) ? getCarTypeForTrainerPreviouslyAssignedToPractical(trainer.id) : trainer.carType;
    }

    function isAlreadyAssignedToPractical(trainerId: string) {
        return practicalTrainers.map(t => t.id).includes(trainerId);
    }

    function getCarTypeForTrainerPreviouslyAssignedToPractical(trainerId: string) {
        return practicalTrainers?.find(t => t.id === trainerId)?.carType;
    }

    const renderModalContent = () => {
        if (!allTrainers || Object.values(allTrainers).length === 0) {
            return (<p>You have 0 trainers who have the correct criteria to be allocated to this course</p>);
        }

        if (modalStage === 3) {
            return (
                <MonitorAssign
                    trainers={getMonitoringCandidates()}
                    monitoredTrainersIds={selectedMonitoringCandidateIds}
                    setMonitoredTrainersIds={setSelectedMonitoringCandidateIds}
                    forbiddenOtherMonitoringCandidateIds={forbiddenOtherMonitoringCandidateIds}
                />
            );
        }

        if (modalStage === 2) {
            return (
                <FeeAssign
                    trainerType={trainerType}
                    trainers={selectedTrainers}
                    startDate={startDate}
                    startTime={startTime}
                    eventTypeName={eventTypeName}
                    onFeeChanged={onFeeChanged}
                    onSundriesChanged={onSundriesChanged}
                    sameDayCourses={selectedEventInstances}
                    selectedMultiDayEventInstances={selectedMultiDayParts}
                    onAllocateToSameDayCourse={onAllocateToCourseChanged}
                    onTrainerCarTypeChanged={onTrainerCarTypeChanged}
                    theoryAndPractical={theoryAndPractical}
                    onAssignToPracticalChanged={onAssignToPracticalChanged}
                    isAlreadyAssignedToPractical={isAlreadyAssignedToPractical}
                    moduleType={moduleType}
                    getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical={getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical}
                    onOtherTrainerTypeCategoryChanged={onOtherTrainerTypeCategoryChanged}
                    isCorporateOrConstruction={isCorporateOrConstruction}
                    courseFee={courseFee}
                    eventInstanceId={eventInstanceId}
                />
            );
        }

        const limitMessage = getLimitMessage(trainerType, isDigital, isOneToOne, businessLineType);
        return (
            <>
                {!isMultiDay &&
                    <TrainersSelector
                        isDigital={isDigital}
                        isCorporateOrConstruction={isCorporateOrConstruction}
                        trainers={allTrainers}
                        selectedTrainers={selectedTrainers}
                        limit={limitMessage}
                        onSelectTrainer={selectTrainer}
                        onDeselectTrainer={deselectTrainer}
                        savedSelectedTrainers={savedSelectedTrainers}
                        nameSearch={nameSearch}
                        theoryAndPractical={theoryAndPractical}
                        publishedDate={publishedDate}
                        startDate={startDate}
                        loading={loading}
                    />
                }
                {isMultiDay &&
                    <MultiDayTrainersSelector
                        isDigital={isDigital}
                        trainers={allTrainers}
                        selectedTrainers={selectedTrainers}
                        limit={limitMessage}
                        onSelectTrainer={multiDaySelectTrainer}
                        onDeselectTrainer={multiDayDeselectTrainer}
                        nameSearch={nameSearch}
                        daysInGroup={group.eventInstanceGroupItems?.length}
                        selectedMultiDayParts={selectedMultiDayParts}
                        onAllocateToDayNumber={onAllocateToDayNumber}
                        loading={loading}
                    />
                }
            </>
        );
    };

    const buttonText = `Edit ${getTrainerTypeAsString(trainerType)}`;

    const [nameSearch, setNameSearch] = React.useState("");
    function handleSearchChange(_: any, { value }: { value: string }) {
        setNameSearch(value);
    }

    function getModalTitle(stage: number) {
        switch (stage) {
            case 1:
                return "Select trainers";
            case 2:
                return "Allocate trainers";
            case 3:
                return "Assign monitoring";
            default:
                return "Unknown!";
        }
    }

    const otherTrainerCategoryNotSelected = trainerType === TrainerType.OtherTrainer &&
        selectedTrainers.some(t => t?.otherTrainerTypeCategory === null || t?.otherTrainerTypeCategory === undefined);
    const monitoringNeeded = monitorAllocationRequired() && modalStage === 3 && selectedMonitoringCandidateIds.length < 1;
    const carTypeNeeded = modalStage === 2 && carTypeSelectionRequired();
    const allocateDisabled = monitoringNeeded || carTypeNeeded || otherTrainerCategoryNotSelected;

    return (
        <AvailabilityContext.Provider value={availabilityContextOptions}>
            <Button content={buttonText} onClick={onEditClick} loading={loading} disabled={loading} />
            <Modal open={open} className={`trainerAllocationModal ${modalStage === 1 && "overflowOverlayTrainerAllocationModal"}`}>
                <Modal.Header>
                    {getModalTitle(modalStage)}
                </Modal.Header>
                <Modal.Actions>
                    {modalStage === 1 &&
                        <Input
                            placeholder="Enter a trainer name"
                            className="floated-left"
                            value={nameSearch}
                            onChange={handleSearchChange} />
                    }
                    <Button content="Close" onClick={onCloseModalClick} className="cancel-action" />
                    {((modalStage === 2 && !monitorAllocationRequired()) || modalStage === 3) ?  (
                        <>
                            <Button content="Back" onClick={onBackClick} />
                            <Button content="Allocate" onClick={onAllocateClick} disabled={allocateDisabled || loading} loading={loading} />
                        </>
                    ) : <Button content="Continue" onClick={onContinueClick} disabled={loading} />}
                </Modal.Actions>
                <Modal.Content>
                    <Container>
                        {renderModalContent()}
                    </Container>
                </Modal.Content>
            </Modal>
        </AvailabilityContext.Provider>
    );
};

