import * as React from "react";
import { Link } from "redux-little-router";
import { Button, Checkbox, Table } from "semantic-ui-react";
import { EventInstanceWithOrdersListModel } from "../model";
import { orderBy } from "lodash";
import { AttendeeRowCheckable } from "./AttendeeRowCheckable";
import { AttendeeListModel } from "@common/crud/attendee";
import "./Checkable.scss";
import { getBookingItemState } from "../../eventInstance/helpers";
import { formatPrice } from "../helpers";
import { universalEventManagementPathSelector } from "@common/redux-helpers";
import { useSelector } from "react-redux";

interface OrderEventInstanceRowProps {
    eventInstance: EventInstanceWithOrdersListModel;
    index: number;
    selectedEventInstances: { id: string; selectedAttendees: string[]; attendeesNotExpanded?: boolean }[];
    attendees: AttendeeListModel[];
    toggleAttendeeSelected: (eventInstanceId: string, attendeeId: string) => void;
    fetchEventInstanceAttendees: (eventInstanceId: string, eventInstanceSelected: boolean) => void;
    toggleEventInstanceSelected: (eventInstanceId: string, eventInstanceExtended: boolean, attendees: string[]) => void;
}

export const OrderEventInstanceRowCheckable: React.FC<OrderEventInstanceRowProps> = ({
    eventInstance,
    index,
    selectedEventInstances,
    attendees,
    toggleAttendeeSelected,
    fetchEventInstanceAttendees,
    toggleEventInstanceSelected,
}) => {
    const [fetched, setFetched] = React.useState<boolean>(false);
    const [extended, setExtended] = React.useState<boolean>(false);

    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const selectableAttendeeIds = React.useMemo(() => attendees.map(a => a.id), [attendees]);

    const eventInstanceSelected = React.useMemo(() => {
        const eventInstanceSelectedData = selectedEventInstances.find(ei => ei.id === eventInstance.id);
        return eventInstanceSelectedData?.id && (eventInstanceSelectedData.attendeesNotExpanded
            || eventInstanceSelectedData.selectedAttendees.slice().sort().join() === selectableAttendeeIds.slice().sort().join());
    }, [eventInstance, selectableAttendeeIds, selectedEventInstances]);

    const eventInstancePartiallySelected = React.useMemo(() => {
        const eventInstanceSelectedData = selectedEventInstances.find(ei => ei.id === eventInstance.id);
        return eventInstanceSelectedData?.id && !eventInstanceSelectedData.attendeesNotExpanded && eventInstanceSelectedData.selectedAttendees.length > 0
            && eventInstanceSelectedData.selectedAttendees.slice().sort().join() !== selectableAttendeeIds.slice().sort().join();
    }, [eventInstance, selectableAttendeeIds, selectedEventInstances]);

    const toggleAttendeesForOrder = React.useCallback(() => {
        if (!fetched) {
            fetchEventInstanceAttendees(eventInstance.id, eventInstanceSelected);
        }

        setExtended(!extended);
        setFetched(true);
    }, [eventInstance, eventInstanceSelected, extended, fetchEventInstanceAttendees, fetched]);

    const toggleSelected = React.useCallback(() => {
        toggleEventInstanceSelected(eventInstance.id, extended, selectableAttendeeIds);
    }, [selectableAttendeeIds, eventInstance, extended, toggleEventInstanceSelected]);

    const eventInstanceRow = React.useMemo(() => (
        <Table.Row key={`eventInstanceRow_${index}`} className="grey">
            <Table.Cell>
                <Link href={`${eventManagementPath}/eventInstances/${eventInstance.id}`}>
                    {eventInstance.venueName}
                </Link>
            </Table.Cell>
            <Table.Cell>{eventInstance.eventTypeName}</Table.Cell>
            <Table.Cell>
                {eventInstance.eventInstanceDeliveryDateTime.format(
                    "ddd DD/MM/YYYY HH:mm"
                )}
            </Table.Cell>
            <Table.Cell>
                {getBookingItemState(eventInstance.cancelled, eventInstance.completed)}
            </Table.Cell>
            <Table.Cell>{eventInstance.attendeeCount}</Table.Cell>
            <Table.Cell>{formatPrice(eventInstance.total)}</Table.Cell>
            <Table.Cell />
            <Table.Cell>
                <Button
                    id={extended ? "extended" : "not_extended"}
                    icon={extended ? "angle up" : "angle down"}
                    onClick={toggleAttendeesForOrder}
                />
            </Table.Cell>
        </Table.Row>
    ), [eventInstance, extended, index, toggleAttendeesForOrder]);

    return (
        <>
            {eventInstanceRow}
            {extended && attendees.length > 0 && (
                <Table.Cell colSpan={8}>
                    <Table>
                        <Table.Header className="grey">
                            <Table.Row>
                                <Table.HeaderCell>Attendee</Table.HeaderCell>
                                <Table.HeaderCell>Driving Licence</Table.HeaderCell>
                                <Table.HeaderCell>Booking Reference</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell >
                                    <Checkbox
                                        checked={!!eventInstanceSelected}
                                        onChange={toggleSelected}
                                        className={eventInstancePartiallySelected ? "partiallySelected" : undefined}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {orderBy(attendees, attendee => attendee.fullName).map((attendee, i) => (
                                <AttendeeRowCheckable
                                    key={`attendee_${i}`}
                                    eventInstanceId={eventInstance.id}
                                    attendee={attendee}
                                    index={i}
                                    selected={selectedEventInstances
                                        .find(ei => ei.id === eventInstance.id)?.selectedAttendees
                                        .includes(attendee.id) || false}
                                    toggleAttendeeSelected={toggleAttendeeSelected}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            )}
        </>
    );
};
