/* eslint-disable max-lines */
import { Location } from "redux-little-router";
import moment from "moment";
import { ScheduledPaymentPlanDetailModel } from "@common/payments/model";
import { HistoryRecord } from "@common/history/model";
import { Address } from "../common/Address";
import { SpecialRequirements } from "../common/SpecialRequirements";
import { CarType } from "../eventInstance/model";
import { DorsAttendanceStatusesEnum } from "../dorsBooking/model";
import { DdrsScore, DrinkDriveOffenderDetails } from "@common/crud/drinkDriveOffenders/model";
import { AttendeeFieldTypeEnum, CountryEnum } from "../organisation/model";
import { DelegateComparisonTypeEnum, DelegateState } from "../delegate/model";
import { MxEmailCheckResult } from "../email/model";
import { OnRoadReportStatus } from "../eventInstance/components/onRoadReports/model";
import { OrderPaymentStatus } from "../order/model";

export interface AttendeeListModel {
    readonly id: string;
    readonly delegateComparisonType?: DelegateComparisonTypeEnum;
    readonly delegateUniqueIdentifier?: string;
    readonly title?: AttendeeTitleEnum;
    readonly forename?: string;
    readonly surname?: string;
    readonly fullName?: string;
    readonly drivingLicenceNumber?: string;
    readonly nationalInsuranceNumber?: string;
    readonly citbRegistrationNumber?: string;
    readonly citbLevyNumber?: string;
    readonly eventInstanceId?: string;
    readonly eventInstanceIds?: string[];
    readonly contextAttendee?: boolean;
    readonly venueDorsId?: string;
    readonly email?: string;
    readonly telephone?: string;
    readonly didAttend?: boolean;
    readonly completed?: CompletionState;
    readonly specialRequirements?: SpecialRequirements;
    readonly issue?: string;
    readonly reasonIssue?: ReasonIssueEnum;
    readonly listUpdated?: moment.Moment;
    readonly forceId?: number;
    readonly issuingForceId?: number;
    readonly correlationId?: string;
    readonly bookingReference?: string;
    readonly dateModified?: moment.Moment;
    readonly isBookingCanceled?: boolean;
    readonly cancellationDate?: moment.Moment;
    readonly ddrsScores?: DdrsScore;
    readonly drinkDriveOffenderDetails?: DrinkDriveOffenderDetails;
    readonly freeRebookingAddedByCa?: boolean;
    readonly ddrsOffenderDocumentPaths?: DdrsOffenderDocumentPaths;
    readonly referredCourtCountry?: CountryEnum;
    readonly drivingLicenceCountry?: number;
    readonly vocationalLicenceCategories?: number[];
    readonly dqcReference?: string;
    readonly uin?: string;
    readonly dqcExpiry?: moment.Moment;
    readonly delegateId?: string;
    readonly requiresOptionalCertificate?: boolean;
    readonly requiresOptionalForsCertificate?: boolean;
    readonly delegateCertificateLinks?: Record<DocumentType, string>;
    readonly organisationId?: string;
    readonly organisationName?: string;
    readonly notSaved?: boolean;
    readonly drivingLicenceExactCountry?: string;
    readonly dateOfBirth?: moment.Moment;
    readonly address?: Address;
    readonly attendeeFieldValues?: AttendeeFieldValue[];
    readonly dvsaUploadStatus?: DvsaUploadStatus;
    readonly mxEmailCheckResult?: MxEmailCheckResult;
    readonly onRoadReportId?: string;
    readonly onRoadReportStatus?: OnRoadReportStatus;
    readonly departments?: string[];
    readonly anonymized?: boolean;
    readonly correlatedOrderId?: string;
    readonly managers?: { name: string; telephone: string; email: string }[];
    readonly bookNowPayLaterPaymentStatus?: OrderPaymentStatus;
    readonly fromOrderWithBookNowPayLater?: boolean;
    readonly score?: number;
    readonly idType?: AttendeeIdTypeEnum;
    readonly idLast4Digits?: string;
}

export interface AttendeeFieldValue {
    readonly name: string;
    readonly value: any;
    readonly displayName: string;
    readonly type: AttendeeFieldTypeEnum;
}

export interface AttendeesForEventInstanceResponse {
    readonly attendees: AttendeeListModel[];
    readonly attendeesFrom: moment.Moment;
}

export enum UpdateDelegateAttendeesStatus {
    Ok = 0,
    OldStateRefresh = 1,
    OverbookedDueToCorporateBookingApp = 2,
    SeatIsReserved = 3
}

export interface UpdateDelegateAttendeesResponse {
    readonly delegateAttendees: AttendeeListModel[];
    readonly status: UpdateDelegateAttendeesStatus;
    readonly attendeesFrom: moment.Moment;
    readonly reservedOrBookedSeats: number;
    readonly attendeesRemoved: boolean;
}

export interface AttendeeSpecialRequirementsModel {
    readonly id: string;
    readonly contextAttendee: boolean;
    readonly specialRequirements?: SpecialRequirements;
}

export const CpcComparableDelegateAttendeeFields: string[] = [
    "forename", "surname", "drivingLicenceCountry", "dqcReference", "email", "telephone", "drivingLicenceExactCountry"
];

export const WorkshopComparableDelegateAttendeeFields: string[] = [
    "forename", "surname", "drivingLicenceCountry", "email", "telephone"
];

export const OnRoadComparableDelegateAttendeeFields: string[] = [
    "forename", "surname", "drivingLicenceCountry", "email", "telephone"
];

export const BusinessDriverComparableDelegateAttendeeFields: string[] = [
    "forename", "surname", "drivingLicenceNumber", "drivingLicenceCountry", "email", "telephone"
];

export interface AttendeeDetailModel {
    readonly id: string;
    readonly delegateComparisonType?: DelegateComparisonTypeEnum;
    readonly delegateUniqueIdentifier?: string;
    readonly eventInstanceId?: string;
    readonly title?: AttendeeTitleEnum;
    readonly forename?: string;
    readonly surname?: string;
    readonly fullName?: string;
    readonly email?: string;
    readonly drivingLicenceNumber?: string;
    readonly nationalInsuranceNumber?: string;
    readonly citbRegistrationNumber?: string;
    readonly citbLevyNumber?: string;
    readonly externalAttendeeId?: number;
    readonly externalEventId?: number;
    readonly forceId?: number;
    readonly venueDorsId?: string;
    readonly telephone?: string;
    readonly didAttend?: boolean;
    readonly completed?: CompletionState;
    readonly address?: Address;
    readonly detailUpdated?: moment.Moment;
    readonly modifiedByEmail?: string;
    readonly dateModified?: moment.Moment;
    readonly attendeeHistoryRecords?: HistoryRecord[];
    readonly attendeeHistoryRecordsV2?: HistoryRecord[];
    readonly correlationId?: string;
    readonly specialRequirements?: SpecialRequirements;
    readonly isBookingCanceled?: boolean;
    readonly isRebookedAttendee?: boolean;
    readonly totalAmountPaid?: number;
    readonly totalAmountDue?: number;
    readonly balanceReduction?: number;
    readonly issuingForceId?: number;
    readonly forceName?: string;
    readonly ticketId?: string;
    readonly offerExpiry?: moment.Moment;
    readonly additionalCourseFees?: number;
    readonly scheduledPaymentPlans?: ScheduledPaymentPlanDetailModel[];
    readonly isTelephoneAmendable?: boolean;
    readonly isEmailAmendable?: boolean;
    readonly carType?: AttendeeCarType;
    readonly specialCarDetails?: string;
    readonly autoRemovalDate?: moment.Moment;
    readonly exemptedFromPositiveBalanceProcesses?: boolean;
    readonly refundError?: string;
    readonly eventInstancePriceInPence?: number;
    readonly deletionDate?: moment.Moment;
    readonly cancellationDate?: moment.Moment;
    readonly drinkDriveOffenderDetails?: DrinkDriveOffenderDetails;
    readonly eventTypeCategory?: EventTypeCategory;
    readonly ddrsScores?: DdrsScore;
    readonly isTransferred?: boolean;
    readonly flexiPayPurchased?: boolean;
    readonly flexiPayExpiryDate?: moment.Moment;
    readonly flexiPayUsed?: boolean;
    readonly flexiPayStatus?: FlexiPayStatusEnum;
    readonly flexiPayFees?: number;
    readonly canPurchaseFlexiPay?: boolean;
    readonly freeRebookingAddedByCa?: boolean;
    readonly canUseFreeRebookingAddedByCa?: boolean;
    readonly ddrsOffenderDocumentPaths?: DdrsOffenderDocumentPaths;
    readonly referredCourtCountry?: CountryEnum;
    readonly drivingLicenceCountry?: number;
    readonly vocationalLicenceCategories?: number[];
    readonly dqcReference?: string;
    readonly dqcExpiry?: moment.Moment;
    readonly delegateId?: string;
    readonly requiresOptionalCertificate?: boolean;
    readonly requiresOptionalForsCertificate?: boolean;
    readonly delegateCertificateLinks?: Record<DocumentType, string>;
    readonly drivingLicenceExactCountry?: string;
    readonly dateOfBirth?: moment.Moment;
    readonly dvsaUploadStatus?: DvsaUploadStatus;
    readonly mxEmailCheckResult?: MxEmailCheckResult;
    readonly onRoadReportId?: string;
    readonly onRoadReportStatus?: OnRoadReportStatus;
    readonly score?: number;
}

export type Attendee = AttendeeListModel & AttendeeDetailModel;

export interface AttendeeEditModel {
    id: string;
    eventInstanceId: string;
    forename: string;
    surname: string;
    email: string;
    drivingLicenceNumber: string;
    externalAttendeeId: number;
    externalEventId: number;
    forceId: number;
    venueDorsId: string;
    telephone: string;
    didAttend: boolean;
    address: Address;
    eventTypeCategory: EventTypeCategory;
    drinkDriveOffenderDetails?: DrinkDriveOffenderDetails;
    offerExpiry?: moment.Moment;
}

export interface RegisterAttendeeModel {
    id: string;
    didAttend: boolean;
    completed: CompletionState;
    registerSubmitted?: boolean;
    issue?: string;
    reasonIssue?: ReasonIssueEnum;
    dateModified?: moment.Moment;
}

export interface CanUndoCancellationModel {
canUndo: boolean;
displayWarning: boolean;
}

export interface IssueRaisedSuccess {
    attendeeId: string;
    issueText: string;
    reasonIssue: ReasonIssueEnum;
    register: { [id: string]: CompletionState };
}

export enum CompletionState {
    Unknown = 0,
    NotCompleted = 1,
    Completed = 2
}

export enum FlexiPayStatusEnum {
    NotActive = 0,
    Active = 1,
    Used = 2,
    Expired = 3
}

export const FlexiPayStatus = {
    [FlexiPayStatusEnum.NotActive]: "Not active",
    [FlexiPayStatusEnum.Active]: "Active",
    [FlexiPayStatusEnum.Used]: "Used",
    [FlexiPayStatusEnum.Expired]: "Expired"
};

export interface UpdateFlexiPayModel {
    flexiPayPurchased: boolean;
    flexiPayFee: number;
    skipPayment?: boolean;
}

export interface UpdateFlexiPayResponse {
    success: boolean;
    errors?: string;
    attendeeDetailModel?: AttendeeDetailModel;
}

export interface EditContactDetailsModel {
    telephone: string;
    email: string;
}

export interface EditAutoRemovalDateModel {
    autoRemovalDate: moment.Moment;
}

export type AttendeeCarType = CarType | "Special";

export interface RefundModel {
    readonly correlationId?: string;
    readonly eventInstanceId?: string;
    readonly attendeeId: string;
    readonly amount: number;
    readonly doNotRefundOnStripe?: boolean;
    readonly message?: string;
}

export interface DvsaUploadStatusModel {
    attendeeId: string;
    dvsaUploadStatus: DvsaUploadStatus;
}

export interface UpdateResponse {
    success: boolean;
    message: string;
}

export interface RefundResponseModel {
    readonly amount: number;
    readonly success: boolean;
    readonly message: string;
    readonly attendeeId: string;
}

export enum SendMessageOver {
    None = 0,
    Email = 1,
    SMS = 2,
    Both = 3,
    Letter = 4,
    LetterAutomatic = 5,
}

export enum ReasonIssueEnum {
    None = 0,
    DidNotAttend = 1,
    InsufficientId = 2,
    LateArrivalLateFromBreak = 3,
    OtherTermsAndConditionsBreach = 4,
    HQReviewRequired = 5,
    TechIssues = 6,
    MarkedAsNotCompleted = 7,
    NoShowNotAvailable = 8,
    DriverChange = 9,
    InStore = 10,
    UnableToDrive = 11,
    NotObserved = 12,
}

export const MultiDropVehicleSpecificReasons = [
    ReasonIssueEnum.NoShowNotAvailable,
    ReasonIssueEnum.DriverChange,
    ReasonIssueEnum.InStore,
    ReasonIssueEnum.UnableToDrive,
    ReasonIssueEnum.NotObserved
];

export enum MessageType {
    Reminder = 1,
    DigitalEventReminderForAll = 5,
    DigitalBookingReminderNoEmail = 8
}

export enum EventTypeCategory {
    Dors = 0,
    Ddrs = 1,
    CorporateOrganisation = 2,
    Unspecified = 3,
    ConstructionOrganisation = 4,
}

export type DdrsOffenderDocumentPaths = Record<DocumentType, BlobDetails>

export const ReasonIssue = {
    [ReasonIssueEnum.None]: "None",
    [ReasonIssueEnum.DidNotAttend]: "Did not attend",
    [ReasonIssueEnum.InsufficientId]: "Insufficient ID",
    [ReasonIssueEnum.LateArrivalLateFromBreak]: "Late arrival/Late from break",
    [ReasonIssueEnum.OtherTermsAndConditionsBreach]: "Other T&C breach",
    [ReasonIssueEnum.HQReviewRequired]: "HQ review required",
    [ReasonIssueEnum.TechIssues]: "Technical issues",
    [ReasonIssueEnum.MarkedAsNotCompleted]: "Marked as not completed",
    [ReasonIssueEnum.NoShowNotAvailable]: "No show/Not available",
    [ReasonIssueEnum.DriverChange]: "Driver change",
    [ReasonIssueEnum.InStore]: "In store",
    [ReasonIssueEnum.UnableToDrive]: "Unable to drive",
    [ReasonIssueEnum.NotObserved]: "Not observed"
};

export interface BlobDetails{
 blobPath: string;
 createdDate: moment.Moment;
}

export interface UpdateDorsStatusModel {
    currentStatus: DorsAttendanceStatusesEnum;
    requestedStatus: DorsAttendanceStatusesEnum;
}

export interface AttendeeState {
    readonly attendees: Attendee[];
    readonly attendeesFrom?: moment.Moment;
}

export enum AttendanceIdValidity {
    Available = 1,
    UsedByAttendee = 2,
    UsedByOfferWithSameDetails = 3,
    UsedByOfferWithDifferentDetails = 4,
    LowerThanLowestOneInDb = 5,
}

export interface AttendanceIdValidityModel {
    readonly validity: AttendanceIdValidity;
    readonly driverNumber: string;
    readonly differingFields: string[];
}

export enum DocumentType {
    Unknown = 0,
    WelshCompletionCertificate = 3,
    WelshNonCompletionCertificate = 4,
    EnglishCompletionCertificate = 5,
    EnglishNonCompletionCertificate = 6,
    NIrelandCompletionCertificate = 7,
    NIrelandNonCompletionCertificate = 8,
    CpcCompletionCertificate = 9,
    ForsCompletionCertificate =  10,
    WorkshopCompletionCertificate = 11,
    EventInstanceAttachment = 12,
    OnRoadCompletionCertificate = 13,
    OnRoadReportForm = 14,
    CompetencyReport = 15,
    BusinessDriverCompletionCertificate = 16,
}

export interface UpdateAttendeeFromDorsModel {
    readonly address: AlaskaAndDorsData;
    readonly driverNumber: AlaskaAndDorsData;
    readonly email: AlaskaAndDorsData;
    readonly forename: AlaskaAndDorsData;
    readonly surname: AlaskaAndDorsData;
    readonly telephone: AlaskaAndDorsData;
    readonly ticketId: AlaskaAndDorsData;
    readonly hasDifferences: AlaskaAndDorsData;
}

export interface DataToUseFromDorsForAttendeeModel {
    address: boolean;
    driverNumber: boolean;
    email: boolean;
    forename: boolean;
    surname: boolean;
    telephone: boolean;
    ticketId: boolean;
}

export interface AlaskaAndDorsData {
    alaskaValue: string;
    dorsValue: string;
}

export enum DvsaUploadStatus {
    Empty = 0,
    Valid= 1,
    Invalid = 2,
    Processing = 3,
    Used = 4,
    Expired = 5,
    Cancelled = 6,
}

export const DvsaUploadStatusName = {
    [DvsaUploadStatus.Empty]: "",
    [DvsaUploadStatus.Valid]: "Valid",
    [DvsaUploadStatus.Invalid]: "Invalid",
    [DvsaUploadStatus.Processing]: "Processing",
    [DvsaUploadStatus.Used]: "Used",
    [DvsaUploadStatus.Expired]: "Expired",
    [DvsaUploadStatus.Cancelled]: "Cancelled",
};

export enum VerifyStripePaymentStatus {
    Unknown = 0,
    Error = 1,
    Warning = 2,
    Success = 3
}

export interface VerifyStripePaymentResult {
    status: VerifyStripePaymentStatus;
    message: string;
}

export interface AttachedDocumentListModel {
    readonly id: string;
    readonly fileName: string;
    readonly description: string;
    readonly blobPath: string;
    readonly dateCreated: moment.Moment;
    readonly addedByEmail: string;
    readonly isProcessed: boolean;
    readonly isCompleted: boolean;
    readonly adminViewOnly: boolean;
}

export enum AttendeeTitleEnum {
    Mr = 0,
    Mrs = 1,
    Miss = 2,
    Ms = 3,
    PreferNotToSay = 4,
}

export const AttendeeTitle = {
    [AttendeeTitleEnum.Mr]: "Mr",
    [AttendeeTitleEnum.Mrs]: "Mrs",
    [AttendeeTitleEnum.Miss]: "Miss",
    [AttendeeTitleEnum.Ms]: "Ms",
    [AttendeeTitleEnum.PreferNotToSay]: "Prefer not to say",
};

export enum AttendeeIdTypeEnum {
    None = 0,
    DrivingLicence = 1,
    Passport = 2,
    Other = 3
}

export const AttendeeIdType = {
    [AttendeeIdTypeEnum.None]: "",
    [AttendeeIdTypeEnum.DrivingLicence]: "Driving Licence",
    [AttendeeIdTypeEnum.Passport]: "Passport",
    [AttendeeIdTypeEnum.Other]: "Other"
};

export type AppState = AttendeeState & DelegateState & { router: Location };
