import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Link, push } from "redux-little-router";
import { Card, Grid, Menu, Tab, TabProps } from "semantic-ui-react";

import { TrainerAttributeDefinitionDetailModel, AppState } from "../model";
import { trainerAttributeDefinitionSelector, basePathSelector, routeIdSelector } from "../selectors";

import * as details from "./details";
import { ContextItems } from "./details/ContextItems";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { EventType, EventTypeState } from "@common/crud/eventType/model";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

export interface CoreDetailProps {
    trainerAttributeDefinition: TrainerAttributeDefinitionDetailModel;
    eventTypes: EventType[];
}

export interface DetailProps extends CoreDetailProps {
    path: string;
    basePath: string;
    tab?: string;
}

export interface DispatchProps {
    push: (path: string) => void;
}

const tabs = ObjectKeys(details).map(k => details[k]);

class DetailInternal extends React.Component<DetailProps & DispatchProps, {}> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    public render() {
        const panes = tabs.map(t => t(this.props));
        const activeIndex = panes.slice(1).findIndex(p => this.props.tab && this.props.tab.startsWith(p.path)) + 1;

        const content = () => panes.length === 1
            ? <Card fluid>{panes[0].render()}</Card>
            : <Tab panes={panes} onTabChange={this.handleTabChange} activeIndex={activeIndex} />;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{this.props.trainerAttributeDefinition.name}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <Grid.Column vertical as={Menu}>
                            <Menu.Item name="Trainer Attributes" as={Link} href={this.props.basePath}>
                                All Trainer Attributes
                            </Menu.Item>
                            <ContextItems trainerAttributeDefinition={this.props.trainerAttributeDefinition} eventTypes={this.props.eventTypes} />
                        </Grid.Column>
                    </Grid.Column>

                    <Grid.Column width={13}>
                        {content()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private handleTabChange(event: any, { activeIndex }: TabProps) {
        this.props.push(`${this.props.basePath}/${this.props.trainerAttributeDefinition.id}${(tabs[+activeIndex])(null).path}`);
    }
}

const currentTab = (state: AppState) => state.router.pathname.substring(
    basePathSelector(state).length + 1 + routeIdSelector(state).toString().length);

function mapStateToProps(state: AppState & EventTypeState): DetailProps {
    return {
        trainerAttributeDefinition: trainerAttributeDefinitionSelector(state),
        path: state.router.pathname,
        basePath: basePathSelector(state),
        tab: currentTab(state),
        eventTypes: allEventTypesSelector(state)
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
