/* eslint-disable max-lines */
import * as React from "react";
import { Checkbox, Grid, Segment, Table, Button, ButtonContent, Icon, CheckboxProps } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { LanguageEnum, Language } from "@common/crud/eventInstance/model";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FilterOptions } from "../../eventInstance/model";
import { useSelector } from "react-redux";
import { bookingSelector } from "../selectors";
import { getDateInputFormat } from "@common/dateFormating";
import { WithIcon } from "./WithIcon";
import { DayOfWeekEnum } from "@common/model";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { daysOfWeekMap } from "./helper";
import { ukFullPostcodeOrOutwardCodeValidator } from "@common/validation";
import { useGetAdvancedFilter } from "../hooks/useGetAdvancedFilter";
import { MuiDateField } from "@common/components/MuiDateField";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface FiltersProps {
    disableClassroomFilters: boolean;
    updateFilters: (filters: FilterOptions) => void;
    defaultVisible: boolean;
    isAttendee: boolean;
    currentLanguage: LanguageEnum;
}

export const Filters: React.FC<FiltersProps> =
({ updateFilters, disableClassroomFilters, defaultVisible, isAttendee, currentLanguage }) => {
    const [t] = useTranslation("Filters");

    const booking = useSelector(bookingSelector);

    const [inTheMorning, setInTheMorning] = React.useState<boolean>();
    const [inTheAfternoon, setInTheAfternoon] = React.useState<boolean>();
    const [inTheEvening, setInTheEvening] = React.useState<boolean>();
    const [fromDate, setFromDate] = React.useState<moment.Moment>();
    const [toDate, setToDate] = React.useState<moment.Moment>();
    const [language, setLanguage] = React.useState<LanguageEnum>();
    const [includeFullyBooked, setIncludeFullyBooked] = React.useState<boolean>(false);
    const [includeEventsAfterExpiryDate, setIncludeEventsAfterExpiryDate] = React.useState<boolean>();
    const [visible, setVisible] = React.useState<boolean>(defaultVisible);
    const [postcode, setPostcode] = React.useState<string>("");
    const [daysOfWeek, setDaysOfWeek] = React.useState<DayOfWeekEnum[]>([]);
    const [ownInterpreter, setOwnInterpreter] = React.useState<boolean>(false);
    const [showTodaysCompletedCourses, setShowTodaysCompletedCourses] = React.useState<boolean>(false);

    React.useEffect(() => {
        const previousSearch = useGetAdvancedFilter(booking?.id);
        if (previousSearch?.toDate) {
            setToDate(previousSearch.toDate);
        }

        if (previousSearch?.fromDate) {
            setFromDate(previousSearch.fromDate);
        }

        if (previousSearch?.inTheMorning) {
            setInTheMorning(previousSearch.inTheMorning);
        }

        if (previousSearch?.inTheAfternoon) {
            setInTheAfternoon(previousSearch.inTheAfternoon);
        }

        if (previousSearch?.language) {
            setLanguage(previousSearch.language);
        }

        if (previousSearch?.inTheEvening) {
            setInTheEvening(previousSearch.inTheEvening);
        }

        if (previousSearch?.includeFullyBooked) {
            setIncludeFullyBooked(previousSearch.includeFullyBooked);
        }

        if (previousSearch?.includeEventsAfterExpiryDate) {
            setIncludeEventsAfterExpiryDate(previousSearch.includeEventsAfterExpiryDate);
        }

        if (previousSearch?.postcode) {
            setPostcode(previousSearch.postcode);
        } else {
            setPostcode(booking?.postcode ?? "");
        }

        if (previousSearch?.daysOfWeek) {
            setDaysOfWeek(previousSearch.daysOfWeek);
        }

        if (previousSearch?.ownInterpreter) {
            setOwnInterpreter(previousSearch.ownInterpreter);
        }

        if (previousSearch?.showTodaysCompletedCourses) {
            setShowTodaysCompletedCourses(previousSearch.showTodaysCompletedCourses);
        }

        if (previousSearch) {
            updateFilters(previousSearch);
        }

    }, [booking]);

    const [searchOptions, setSearchOptions] = React.useState<FilterOptions>({ limit: 10 });

    const toggleFiltersVisible = React.useCallback(() => {
        setVisible(!visible);
    }, [visible]);

    React.useEffect(() => {
        setSearchOptions({
            inTheMorning,
            inTheAfternoon,
            inTheEvening,
            toDate,
            fromDate,
            language,
            includeFullyBooked,
            includeEventsAfterExpiryDate,
            postcode,
            daysOfWeek,
            limit: 10,
            ownInterpreter,
            showTodaysCompletedCourses
        });
    }, [
        fromDate,
        inTheMorning,
        inTheAfternoon,
        inTheEvening,
        toDate,
        language,
        includeFullyBooked,
        includeEventsAfterExpiryDate,
        postcode,
        daysOfWeek,
        ownInterpreter,
        currentLanguage,
        showTodaysCompletedCourses
    ]);

    React.useEffect(() => {
        if (currentLanguage) {
            setLanguage(currentLanguage);
        }
    }, [currentLanguage]);

    const dateInputFormat = getDateInputFormat();

    const applyFilters = React.useCallback(() => {
        updateFilters(searchOptions);
    }, [searchOptions, updateFilters]);

    const onIncludeFullyBookedChange = React.useCallback((_: any, { checked }) => {
        setIncludeFullyBooked(checked);
    }, []);

    const onIncludeEventsAfterExpiryDateChange = React.useCallback((_: any, { checked }) => {
        setIncludeEventsAfterExpiryDate(checked);
    }, []);

    const onMorningChange = React.useCallback((_: any, { checked }) => {
        setInTheMorning(checked);
    }, []);

    const onAfternoonChange = React.useCallback((_: any, { checked }) => {
        setInTheAfternoon(checked);
    }, []);

    const onEveningChange = React.useCallback((_: any, { checked }) => {
        setInTheEvening(checked);
    }, []);

    const onOwnInterpreterChange = React.useCallback((_: any, { checked }) => {
        setOwnInterpreter(checked);
    }, []);

    const onShowTodaysCompletedCoursesChange = React.useCallback((_: any, { checked }) => {
        setShowTodaysCompletedCourses(checked);
    }, []);

    const toggleDayOfWeek = React.useCallback((dayOfWeek: DayOfWeekEnum) => (_: any, { checked }: CheckboxProps) => {
        if (checked && daysOfWeek.includes(dayOfWeek)) {
            return;
        }
        else if (checked) {
            setDaysOfWeek([...daysOfWeek, dayOfWeek]);
        }
        else {
            setDaysOfWeek(daysOfWeek.filter(x => x !== dayOfWeek));
        }
    }, [daysOfWeek]);

    const DayOfWeekField = daysOfWeekMap.map((d, i) => (
        <Grid.Column key={i}>
            <Checkbox
                className="booking-search-multiple-checkbox-option"
                checked={daysOfWeek.includes(d.dayOfWeekEnum)}
                onClick={toggleDayOfWeek(d.dayOfWeekEnum)}
                label={t(d.dayOfWeek)}
            />
        </Grid.Column>
    ));

    const languageOptions = ObjectKeys(Language)
        .map((k) => ({ text: t(`Languages.${k}`, { defaultValue: Language[k] }), value: +k }));

    return (
        <Segment className="search-filter-table">
            <Table unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell className="search-filters-cell">
                            <Button onClick={toggleFiltersVisible} className="toggle-filters-btn">
                                <ButtonContent>
                                    <Icon className="collapse-icon" size="large" name={(visible) ? "angle up" : "angle down"} />
                                    <img src="/assets/filters.svg" className="button-icon" />
                                    {t("ADVANCED_SEARCH")}
                                </ButtonContent>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row className={(visible) ? "" : "invisible"}>
                        <Table.Cell className="search-filters">
                            <Grid className="filter-rows">
                                <Authorize authorize={TtcClientAdvisorRole}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox
                                                checked={includeFullyBooked}
                                                onClick={onIncludeFullyBookedChange}
                                                label={"Include fully booked"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox
                                                checked={includeEventsAfterExpiryDate}
                                                onClick={onIncludeEventsAfterExpiryDateChange}
                                                label={"Show courses after attendee's completion date"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox
                                                checked={ownInterpreter}
                                                onClick={onOwnInterpreterChange}
                                                label={"Has own interpreter"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox
                                                checked={showTodaysCompletedCourses}
                                                onClick={onShowTodaysCompletedCoursesChange}
                                                label={"Show today's completed courses"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Authorize>
                                <Grid.Row>
                                    <Grid.Column>
                                        <WithIcon isAttendee={isAttendee} attendeeTextKey="FROM_DATE_ATTENDEE_TOOLTIP" caTextKey="FROM_DATE_CA_TOOLTIP">
                                            <MuiDateField
                                                placeholder={dateInputFormat}
                                                label={t("FROM_DATE")}
                                                value={fromDate}
                                                onChange={setFromDate}
                                                cancelLabel={t("CANCEL").toUpperCase()}
                                            />
                                        </WithIcon>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <WithIcon isAttendee={isAttendee} attendeeTextKey="TO_DATE_ATTENDEE_TOOLTIP" caTextKey="TO_DATE_CA_TOOLTIP">
                                            <MuiDateField
                                                placeholder={dateInputFormat}
                                                label={t("TO_DATE")}
                                                value={toDate}
                                                onChange={setToDate}
                                                cancelLabel={t("CANCEL").toUpperCase()}
                                            />
                                        </WithIcon>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={16}>
                                        <label className="booking-search-multiple-checkbox-option">{t("START_TIMES")}</label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            className="booking-search-multiple-checkbox-option"
                                            checked={inTheMorning}
                                            onClick={onMorningChange}
                                            label={t("MORNING")}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            className="booking-search-multiple-checkbox-option"
                                            checked={inTheAfternoon}
                                            onClick={onAfternoonChange}
                                            label={t("AFTERNOON")}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            className="booking-search-multiple-checkbox-option"
                                            checked={inTheEvening}
                                            onClick={onEveningChange}
                                            label={t("EVENING")}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={16}>
                                        <label className="booking-search-multiple-checkbox-option">{t("DAY_OF_THE_WEEK")}</label>
                                    </Grid.Column>
                                    {DayOfWeekField}
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <WithIcon isAttendee={isAttendee} attendeeTextKey="LANGUAGE_TOOLTIP" caTextKey="LANGUAGE_TOOLTIP">
                                            <Input.DropdownNumber
                                                value={language}
                                                label={t("LANGUAGE")}
                                                options={languageOptions}
                                                onChange={setLanguage}
                                                dynamicOptions
                                                key={`${languageOptions[0].text}`}
                                            />
                                        </WithIcon>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <WithIcon isAttendee={isAttendee} attendeeTextKey="POSTCODE_ATTENDEE_TOOLTIP" caTextKey="POSTCODE_CA_TOOLTIP">
                                            <Input.Text
                                                disabled={disableClassroomFilters}
                                                value={postcode}
                                                label={t("POSTCODE")}
                                                onChange={setPostcode}
                                                validation={ukFullPostcodeOrOutwardCodeValidator(t("POSTCODE_VALIDATION_ERROR"))}
                                            />
                                        </WithIcon>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row className={(visible) ? "" : "invisible"}>
                        <Table.Cell className="search-filters-cell">
                            <Button
                                onClick={applyFilters}
                                className="venue-filter-search-btn"
                            >
                                <ButtonContent>
                                    {t("APPLY")}
                                    <Icon name="angle right" />
                                </ButtonContent>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
            </Table>
        </Segment>
    );
};
