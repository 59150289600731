import * as React from "react";
import { connect } from "react-redux";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { OrganisationDetailModel, AppState, BusinessLineType } from "../model";
import { organisationSelector, routeIdSelector } from "../selectors";
import { ContextMenu } from "./ContextMenu";
import { DisplayValueWithEffectiveDate } from "@common/crud/eventType/components/details/DisplayValueWithEffectiveDate";
import { getCurrencyFormat } from "@common/formating";
import { CorporateFeeTable } from "../../eventType/components/CorporateFeeTable";
import { businessLineTypeSelector } from "@common/redux-helpers";

export interface ContactsDetailProps {
    path: string;
    organisationId: string;
    organisation: OrganisationDetailModel;
    businessLineType: BusinessLineType;
}

export class CorporateFeesDetailInternal extends React.Component<ContactsDetailProps> {
    public render() {
        const organisation = this.props.organisation;
        const openDigitalFees =
            organisation?.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.openDigitalEIFeeWithEffectiveDate ?? [];
        const openClassroomFees =
            organisation?.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.openClassroomEIFeeWithEffectiveDate ?? [];
        const closedDigitalFees =
            organisation?.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.closedDigitalEIFeeWithEffectiveDate ?? [];
        const closedClassroomFees =
            organisation?.corporateOrganisationData
                ?.corporateOrganisationSpecificFeesData
                ?.closedClassroomEIFeeWithEffectiveDate ?? [];

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <ContextMenu
                            businessLineType={this.props.businessLineType}
                        />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Grid container stackable className="nomargintop">
                            <h2>{organisation.name}'s Course Fees</h2>
                            <CorporateFeeTable
                                isOpen={true}
                                isDigital={true}
                                headerPrefix={
                                    "Open course delegate fee (Digital)"
                                }
                                showEditButton={false}
                                fees={openDigitalFees}
                            />
                            <CorporateFeeTable
                                isOpen={true}
                                isDigital={false}
                                headerPrefix={
                                    "Open course delegate fee (Classroom)"
                                }
                                showEditButton={false}
                                fees={openClassroomFees}
                            />
                            <CorporateFeeTable
                                isOpen={false}
                                isDigital={true}
                                headerPrefix={
                                    "Closed course delegate fee (Digital)"
                                }
                                showEditButton={false}
                                fees={closedDigitalFees}
                            />
                            <CorporateFeeTable
                                isOpen={false}
                                isDigital={false}
                                headerPrefix={
                                    "Closed course delegate fee (Classroom)"
                                }
                                showEditButton={false}
                                fees={closedClassroomFees}
                            />
                            {organisation.corporateOrganisationData
                                ?.openCourse === false && (
                                <>
                                    <DisplayValueWithEffectiveDate
                                        label="Upload Fee"
                                        values={
                                            organisation
                                                ?.corporateOrganisationData
                                                ?.corporateOrganisationSpecificFeesData
                                                ?.cpcUploadFee
                                        }
                                        valueFormat={getCurrencyFormat}
                                        customLabelWidth={8}
                                        customValueWidth={8}
                                    />
                                    {this.props.businessLineType === BusinessLineType.Corporate && (
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                <h4>CPC bundle discount enabled?</h4>
                                            </Grid.Column>
                                            {this.props.businessLineType === BusinessLineType.Corporate && (
                                                <Grid.Column width={10}>
                                                    {organisation?.corporateOrganisationData?.isBundleDiscountEnabled ? "Yes" : "No"}
                                                </Grid.Column>
                                            )}
                                        </Grid.Row>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const DetailWithActions: React.FC<ContactsDetailProps> = ({
    businessLineType,
    organisationId,
    organisation,
    path,
}) => {
    const hasCorporateOrganisationSpecificFeesData =
        organisation?.corporateOrganisationData
            ?.hasCorporateOrganisationSpecificFeesData;

    if (!hasCorporateOrganisationSpecificFeesData) {
        return <h2>Something went wrong, please try again later.</h2>;
    }
    return (
        <div>
            <CorporateFeesDetailInternal
                businessLineType={businessLineType}
                organisation={organisation}
                organisationId={organisationId}
                path={path}
            />
            <Divider />
            <Segment basic clearing vertical>
                <Button
                    icon="pencil"
                    content="Edit"
                    floated="right"
                    as={Link}
                    href={`${path}/edit`}
                />
            </Segment>
        </div>
    );
};

function mapStateToProps(state: AppState): ContactsDetailProps {
    return {
        businessLineType: +businessLineTypeSelector(state),
        organisation: organisationSelector(state),
        organisationId: routeIdSelector(state),
        path: state.router.pathname,
    };
}

export const NonPoliceFeesDetail = connect(mapStateToProps)(DetailWithActions);
