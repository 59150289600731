import * as React from "react";
import { Table } from "semantic-ui-react";
import "./TypedTable.scss";
import { Icon } from "../../components/Icon";

export interface TypedTableRowProps<T> {
    header?: string;
    key?: string;
    functionalHeader?: () => string | React.ReactNode;
    value: (obj: T) => string | React.ReactNode;
    cellClassName?: string;
    showTotal?: boolean; // You cannot have a total on the first column
    getTotalValue?: (values: T[]) => string | React.ReactNode;
    dynamicCellClassName?: (obj: T) => string;
    sortKey?: keyof T;
    headerClassName?: string;
}

export interface TypedTableProps<T> {
    children: TypedTableRowProps<T>[];
    emptyValuesArrayMessage: string;
    values: T[];
    tableClassName?: string;
    headerClassName?: string;
    showPrintableFooter?: boolean;
    footerClassName?: string;
    sortColumn?: keyof T;
    sortDirection?: SortDirection;
    onSort?: (column: keyof T) => void;
}

export const enum SortDirection {
    Ascending = "ascending",
    Descending = "descending"
}

const headerIcon = (sortKey: string | number | symbol, sortColumn: string | number | symbol, sortDirection: SortDirection) => {
    if (sortKey === sortColumn) {
        return <Icon color="dark" icon={sortDirection === SortDirection.Ascending ? "up-triangle" : "down-triangle"} />;
    }
    return null;
};

const printableFooter = (footerClassName: string) => (
    <tfoot>
        <tr>
            <td>
                <div className={footerClassName}>&nbsp;</div>
            </td>
        </tr>
    </tfoot>
);

export function TypedTable<T extends { id: string; path?: string; customKey?: string }>(props: TypedTableProps<T>) {
    const { children, values, tableClassName, headerClassName, emptyValuesArrayMessage,
        showPrintableFooter, footerClassName, sortColumn, sortDirection, onSort } = props;
    const showTotalsRow = children.find(c => c.showTotal) !== undefined;
    return (
        <Table className={tableClassName}>
            <Table.Header className={headerClassName}>
                <Table.Row>
                    {children.map((t, i) => (
                        t && (
                            <Table.HeaderCell
                                key={`${t.header || t.key || "header_row"}_${i}`}
                                sorted={t.sortKey && t.sortKey === sortColumn ? sortDirection : undefined}
                                onClick={t.sortKey ? function() { onSort(t.sortKey); } : undefined}
                                className={t.sortKey && t.sortKey === sortColumn ? `${t.headerClassName || ""} sorted-header` : t.headerClassName || ""}
                            >
                                {t.header ?? t.functionalHeader()}
                                {t.sortKey && headerIcon(t.sortKey, sortColumn, sortDirection)}
                            </Table.HeaderCell>
                        )
                    ))}
                </Table.Row>
            </Table.Header >
            <Table.Body>
                {values?.length > 0 ? values.map((v, idx) =>
                    (<Table.Row key={`${v.customKey || v.id || "body_row"}_${idx}`}>
                        {props.children.map((t, i) =>
                        {
                            if (!t) {
                                return null;
                            }

                            const classOne = t.cellClassName? t.cellClassName+" " : "";
                            const classTwo =(t.dynamicCellClassName &&  t.dynamicCellClassName(v))?? "";
                            const className = classOne+classTwo;

                            return (<Table.Cell key={`value_${i}`} className={className}>
                                {t.value(v)}
                            </Table.Cell>);})}
                    </Table.Row >)
                ) :
                    <Table.Row>
                        <Table.Cell textAlign="center" colSpan={children?.length}>{emptyValuesArrayMessage}</Table.Cell>
                    </Table.Row>}
            </Table.Body>
            {showTotalsRow &&
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell key={0} >Total</Table.HeaderCell>
                    {children?.map((col, index) => (
                        col && (
                            index > 0 ? (col.showTotal ?
                                <Table.HeaderCell key={`footer_${index}`} >{col.getTotalValue(values)}</Table.HeaderCell>
                                :  <Table.HeaderCell key={`footer_${index}`} />) : null
                        )))}
                </Table.Row>
            </Table.Footer>}
            {!showTotalsRow && showPrintableFooter ? (printableFooter(footerClassName)) : (<></>)}
        </Table>
    );
}
