import * as React from "react";
import { MonitorFee } from "../../model";
import { MonitorPayEditModal } from "./MonitorPayEditModal";
import { MonitorPayTable } from "./MonitorPayTable";

interface EditableMonitorPayTableProps {
currentTrainerFees: MonitorFee[];
onChange: (value: any, valid: boolean) => void;
push: (path: string) => void;
}

export const EditableMonitorPayTable: React.FC<EditableMonitorPayTableProps> = ({ currentTrainerFees, onChange, push }) => {

    return (
        <>
            <MonitorPayTable trainerFees={currentTrainerFees} />
            <MonitorPayEditModal currentTrainerFees={currentTrainerFees} onChange={onChange} push={push} />
        </>
    );};
