import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SectionOneIntroduction: QuestionSection = {
    id: "1",
    title: "Section 1 – Introduction",
    seqNum: 0,
    subSections: [
        {
            id: "1.1",
            text: "Was the trainer presentable?",
            seqNum: 1
        },
        {
            id: "1.2",
            text: "Did the trainer introduce themselves at the start of the course?",
            seqNum: 2
        },
        {
            id: "1.3",
            text: "Did the trainer present the introduction?",
            seqNum: 3
        }],
    summaryCriterium: [{ id: "1.4", text: "", typeEnum: CriteriaEnum.text }],
    IsQuestionSection: true
};
