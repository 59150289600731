import * as React from "react";
import { Form, Label, InputOnChangeData, LabelProps, SemanticShorthandItem, Input, HtmlLabelProps } from "semantic-ui-react";
import { ValidationFunction, ValidationOptions } from "not-valid";

import { debounce, shallowEqual, arraysEqual } from "@neworbit/simpleui-utils";
import { AutoComplete } from "@neworbit/simpleui-input/dist/auto-complete-type";
import { InputType } from "@neworbit/simpleui-input/dist/input-type";
import { InputValidator } from "@neworbit/simpleui-input/dist/input-validator";

export interface ExtendedBaseInputSharedProps<TValue> {
    value?: TValue;
    label?: SemanticShorthandItem<HtmlLabelProps>;
    inputLabel?: SemanticShorthandItem<LabelProps>;
    inputLabelPosition?: "left" | "right" | "left corner" | "right corner";
    validation?: ValidationFunction<TValue>[];
    validationOptions?: ValidationOptions;
    validationDebounce?: number;
    onChange?: (value: TValue, valid: boolean) => void;
    onBlur?: () => void;
    placeholder?: string;
    showErrors?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    inline?: boolean;
}

export interface ExtendedBaseInputProps<TValue> extends ExtendedBaseInputSharedProps<TValue> {
    disableAutocorrect?: boolean;
    disableAutocapitalize?: boolean;
    autoComplete?: AutoComplete;
    getValueFromInput: (value: string) => TValue;
    getValueForInput?: (value: TValue) => string;
    defaultValue: TValue;
    type: InputType;
    requiredValidator?: ValidationFunction<TValue>;
}

export interface ExtendedBaseInputState<TValue> {
    value: TValue;
    rawValue?: string;
    errors: string[];
    dirty: boolean;
    touched: boolean;
}

export class ExtendedBaseInput<TValue> extends React.Component<ExtendedBaseInputProps<TValue>, ExtendedBaseInputState<TValue>> {

    protected static getDerivedStateFromProps({ value, getValueForInput }: ExtendedBaseInputProps<any>,
        state: ExtendedBaseInputState<any>): ExtendedBaseInputState<any> {
        if (value === null || value === undefined || (state.value === value)) {
            return null;
        }

        return {
            ...state,
            value,
            rawValue: ExtendedBaseInput.getValueForInput(value, getValueForInput),
            dirty: state.dirty || value !== state.value
        };
    }

    private static getValueForInput(value: any, getValueForInput?: (value: any) => string) {
        return getValueForInput !== undefined ? getValueForInput(value) : value == null ? "" : value.toString();
    }

    private validator: InputValidator<TValue>;
    private validateDebounced: () => Promise<void>;
    private onBlurDebounced: () => void;
    private mounted: boolean = false;

    constructor(props: ExtendedBaseInputProps<TValue>) {
        super(props);

        const initialValue = this.getValueOrDefault(props.value);

        this.state = {
            value: props.value,
            rawValue: ExtendedBaseInput.getValueForInput(initialValue, props.getValueForInput),
            errors: [],
            dirty: props.value !== undefined,
            touched: false
        };

        let validation = props.validation;

        if (props.required && props.requiredValidator) {
            validation = props.validation === undefined
                ? [props.requiredValidator]
                : [props.requiredValidator, ...props.validation];
        }

        const validationDebounce = props.validationDebounce === undefined ? 250 : props.validationDebounce;

        this.validator = new InputValidator(validation, props.validationOptions);
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.validateDebounced = debounce(this.validate, validationDebounce).bind(this);
        this.onBlurDebounced = debounce(this.onBlur, validationDebounce).bind(this);
    }

    public shouldComponentUpdate(nextProps: ExtendedBaseInputProps<TValue>, nextState: ExtendedBaseInputState<TValue>) {
        const stateChanged = this.state !== nextState;
        const coreNextProps = this.getCoreProps(nextProps);
        const coreProps = this.getCoreProps(this.props);

        const shouldUpdate = stateChanged || (!shallowEqual(coreNextProps, coreProps));

        return shouldUpdate;
    }

    public render() {
        let errors;

        if (this.state.errors) {
            errors = this.state.errors.map((e, i) => <p key={i}>{e}</p>);
        }

        const showErrors = errors.length > 0 && ((this.state.touched && this.state.dirty) || this.props.showErrors);

        const className = "field-wrapper" + (this.props.inline ? " inline" : "");
        const pointing = this.props.inline ? "left" : "above";

        return (
            <div className={className}>
                <Form.Field
                    inline={this.props.inline}
                >
                    {
                        this.props.label
                        && <label>{this.props.label}{this.props.required && <span className="required-asterisk"> *</span>}</label>
                    }
                    <Input
                        value={this.state.rawValue}
                        label={this.props.inputLabel}
                        labelPosition={this.props.inputLabelPosition}
                        placeholder={this.props.placeholder}
                        onChange={this.handleChange}
                        type={this.props.type}
                        error={showErrors}
                        autoCorrect={this.props.disableAutocorrect ? "off" : "on"}
                        autoCapitalize={this.props.disableAutocapitalize ? "off" : "on"}
                        autoComplete={this.props.autoComplete || "on"}
                        onBlur={this.onBlurDebounced}
                        disabled={this.props.disabled}
                        readOnly={this.props.readOnly}
                    />
                </Form.Field>
                {
                    showErrors
                    && <Label basic color="red" pointing={pointing}>{errors}</Label>
                }
            </div>
        );
    }

    public componentDidMount() {
        this.validate();
    }

    public componentDidUpdate() {
        this.mounted = true;
        this.validateDebounced();
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    private onBlur() {
        if (this.mounted) {
            this.setState({ touched: this.state.dirty });
        }

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    private emitOnChange(value: TValue, errors: string[]): void {
        if (this.props.onChange) {
            const valid = errors.length === 0;
            this.props.onChange(value, valid);
        }
    }

    private getValueOrDefault(value: TValue): TValue {

        if (value !== null && value !== undefined) {
            return value;
        }

        return this.props.defaultValue;
    }

    private async validate() {
        const parsed = this.props.getValueFromInput(this.state.rawValue);
        const errors = await this.validator.validate(parsed);

        if (arraysEqual(errors, this.state.errors)) {
            return;
        }

        this.setState({ errors }, () => this.emitOnChange(parsed, errors));
    }

    private handleChange(event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {

        const parsed = this.props.getValueFromInput(data.value);

        this.setState({
            rawValue: data.value,
            dirty: true
        },
        () => this.emitOnChange(parsed, this.state.errors));
    }

    private getCoreProps(props: ExtendedBaseInputProps<TValue>) {
        const {
            value,
            validation,
            validationOptions,
            defaultValue,
            onChange,
            onBlur,
            getValueForInput,
            getValueFromInput,
            requiredValidator,
            type,
            ...coreProps
        } = props;

        return coreProps;
    }
}
