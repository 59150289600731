/* eslint-disable max-len */
import * as React from "react";
import { Fragment, useCallback } from "react";
import classes from "./Icon.module.scss";

export type IconType =
    "down-arrow" |
    "up-arrow" |
    "down-chevron" |
    "left-chevron" |
    "right-chevron" |
    "up-chevron" |
    "close" |
    "cog" |
    "edit" |
    "error" |
    "mail" |
    "minus" |
    "phone" |
    "plus" |
    "search" |
    "sort" |
    "tick" |
    "up-triangle" |
    "down-triangle" |
    "trash";

type IconProps = {
    icon: IconType;
    color?: "light" | "dark" | "green" | "red";
    size?: "small" | "medium" | "halvstor" | "large";
    onClick?: () => void;
    disabled?: boolean;
};

export const Icon = ({ icon, color = "light", size = "medium", onClick, disabled }: IconProps) => {
    const classNames = [classes.icon, classes[color], classes[size]];

    if (disabled === true) {
        classNames.push(classes.disabled);
    } else if (onClick !== undefined) {
        classNames.push(classes.clickable);
    }

    let svg = null;
    let short = false;

    switch (icon) {
        case "down-arrow":
            classNames.push(classes.rotate180);
            svg = (<Fragment>
                <polyline points="4 10.31 10 4.31 16 10.31" />
                <line x1="10" y1="5.53" x2="10" y2="15.72" />
            </Fragment>);
            break;
        case "up-arrow":
            svg = (<Fragment>
                <polyline points="4 10.31 10 4.31 16 10.31" />
                <line x1="10" y1="5.53" x2="10" y2="15.72" />
            </Fragment>);
            break;
        case "down-chevron":
            classNames.push(classes.rotate90);
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "left-chevron":
            classNames.push(classes.rotate180);
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "right-chevron":
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "up-chevron":
            classNames.push(classes.rotate270);
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "close":
            svg = (<Fragment>
                <line x1="14.49" y1="5.43" x2="5.16" y2="14.76" />
                <line x1="14.29" y1="14.56" x2="4.96" y2="5.23" />
            </Fragment>);
            break;
        case "cog":
            classNames.push(classes.thin);
            svg = (<Fragment>
                <circle cx="10.29" cy="10" r="3.38" />
                <path d="M13.73,4.16c-0.55-0.33-1.09-0.54-1.74-0.69l0,0c0-0.08-0.01-0.55-0.01-1.04s-0.43-0.89-0.97-0.89H9.56
                    c-0.53,0-0.97,0.41-0.97,0.91s0,0.96,0.01,1.02l0,0c-0.62,0.15-1.2,0.39-1.74,0.7l0,0c-0.05-0.06-0.38-0.4-0.73-0.74
                    C5.78,3.06,5.19,3.09,4.82,3.46L3.79,4.49C3.42,4.86,3.4,5.46,3.75,5.81C4.1,6.17,4.43,6.49,4.48,6.54l0,0
                    C4.17,7.09,3.93,7.68,3.77,8.31l0,0c-0.06,0-0.51,0-1.01,0S1.87,8.74,1.87,9.27v1.45c0,0.53,0.41,0.97,0.91,0.97s0.94,0,0.98,0.01
                    l0,0c0.15,0.64,0.36,1.24,0.68,1.79l0,0c-0.03,0.04-0.34,0.35-0.69,0.71s-0.33,0.95,0.04,1.32l1.03,1.03
                    c0.38,0.38,0.97,0.4,1.31,0.05c0.35-0.35,0.68-0.69,0.74-0.76l0,0c0.55,0.32,1.12,0.55,1.72,0.7l0,0c0,0.09,0,0.57,0,1.06
                    c0,0.49,0.43,0.89,0.97,0.89h1.45c0.53,0,0.97-0.41,0.97-0.91c0-0.5-0.01-0.98-0.01-1.07l0,0c0.66-0.17,1.19-0.36,1.75-0.7l0,0
                    c0.05,0.07,0.37,0.41,0.73,0.76s0.95,0.33,1.32-0.04l1.03-1.03c0.38-0.38,0.4-0.97,0.05-1.31c-0.35-0.35-0.69-0.7-0.76-0.78l0,0
                    c0.31-0.53,0.56-1.11,0.71-1.72l0,0c0.1,0,0.58,0,1.07,0s0.89-0.43,0.89-0.97V9.27c0-0.53-0.41-0.97-0.91-0.97
                    c-0.5,0-0.97,0-1.04,0l0,0c-0.16-0.67-0.34-1.22-0.68-1.79l0,0c0.05-0.04,0.37-0.36,0.72-0.71c0.35-0.35,0.32-0.94-0.05-1.31
                    l-1.03-1.03c-0.38-0.38-0.97-0.39-1.32-0.04S13.77,4.11,13.73,4.16L13.73,4.16z" />
            </Fragment>);
            break;
        case "edit":
            svg = (<Fragment>
                <line x1="8.21" y1="16.56" x2="3.39" y2="11.78" />
                <path d="m4.15,12.13L13.86,2.42c.62-.62,1.63-.62,2.25,0l1.44,1.44c.62.62.62,1.63,0,2.25L7.84,15.82" />
                <polyline points="3.44 12.79 1.57 18.36 7.2 16.52" />
                <line x1="12.94" y1="3.34" x2="16.62" y2="7.04" />
            </Fragment>);
            break;
        case "error":
            svg = (<Fragment>
                <circle cx="10.11" cy="9.94" r="8.62" />
                <line x1="10.11" y1="10.79" x2="10.11" y2="5.09" />
                <circle className={classes.fillOnly} cx="10.11" cy="13.74" r="1.35" />
            </Fragment>);
            break;
        case "mail":
            short = true;
            classNames.push(classes.short);
            svg = (<Fragment>
                <rect x="0.625" y="0.625" width="18.75" height="13.75" rx="1.375" />
                <path d="M1 1.47059L9.33854 8.44663C9.71861 8.76459 10.2742 8.75626 10.6446 8.42705L19 1" />
                <path d="M1 14L8 7" />
                <path d="M12 7L19 14" />
            </Fragment>);
            break;
        case "minus":
            svg = <line x1="15.1" y1="10" x2="4.9" y2="10" />;
            break;
        case "phone":
            classNames.push(classes.thin);
            svg = <path d="m12.19,17.92c1.94.74,3.38.31,3.38.31.56-.17,1.37-.65,1.78-1.06l.91-.91c.42-.42.42-1.1,0-1.51l-2.73-2.73c-.42-.42-1.1-.42-1.51,0l-.56.56c-.42.42-1.23.66-1.8.53,0,0-1.14-.25-2.86-1.93s-2.14-2.46-2.14-2.46c-.26-.53-.13-1.29.3-1.7l1.18-1.13c.42-.41.43-1.08.02-1.5l-2.73-2.73c-.42-.42-1.1-.42-1.51,0l-.56.56c-.42.42-1.01,1.17-1.32,1.66,0,0-.88,1.41-.28,3.2,0,0,1.59,4.42,4.54,7s5.91,3.83,5.91,3.83Z" />;
            break;
        case "plus":
            svg = <Fragment><line x1="10" y1="3.4" x2="10" y2="16.6" /><line x1="16.32" y1="10" x2="3.13" y2="10" /></Fragment>;
            break;
        case "search":
            svg = <Fragment><circle cx="8.36" cy="8.23" r="6.64" /><line x1="12.88" y1="13.13" x2="17.95" y2="18.39" /></Fragment>;
            break;
        case "sort":
            svg = (<Fragment>
                <line x1="5.04" y1="3.66" x2="5.04" y2="16.39" />
                <line x1="10.3" y1="4.23" x2="17.97" y2="4.23" />
                <line x1="10.3" y1="7.63" x2="15.08" y2="7.63" />
                <line x1="10.3" y1="10.99" x2="12.69" y2="10.99" />
                <line x1="2.13" y1="13.57" x2="5.04" y2="16.55" />
                <line x1="7.96" y1="13.57" x2="5.04" y2="16.55" />
            </Fragment>);
            break;
        case "tick":
            svg = <Fragment><line x1="16.92" y1="5.34" x2="7.59" y2="14.66" /><line x1="7.59" y1="14.66" x2="4.15" y2="11.17" /></Fragment>;
            break;
        case "up-triangle":
            classNames.push(classes.rotate270);
            classNames.push(classes.fill);
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "down-triangle":
            classNames.push(classes.rotate90);
            classNames.push(classes.fill);
            svg = <polyline points="7.64 4 13.64 10 7.64 16" />;
            break;
        case "trash":
            classNames.push(classes.fill);
            svg = (<path d="M 10.667969 2.667969 L 10.667969 0 L 5.332031 0 L 5.332031 2.667969 L 0.667969 2.667969 L 0.667969 4 L 2.667969 4 L 2.667969 15.332031 L 13.332031 15.332031 L 13.332031 4 L 15.332031 4 L 15.332031 2.667969 Z M 6.667969 12 L 5.332031 12 L 5.332031 6.667969 L 6.667969 6.667969 Z M 10.667969 12 L 9.332031 12 L 9.332031 6.667969 L 10.667969 6.667969 Z M 6.667969 1.332031 L 9.332031 1.332031 L 9.332031 2.667969 L 6.667969 2.667969 Z M 6.667969 1.332031 " />);
        default:
            break;
    }

    const clickHandler = useCallback((e: React.MouseEvent<SVGElement>) => {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    }, [onClick]);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 20 ${short ? 15 : 20}`} className={classNames.join(" ")} onClick={clickHandler}>
            {svg}
        </svg>
    );
};
