import { useSelector } from "react-redux";
import { ApplicationState } from "../applicationState";
import { BusinessLineType } from "@common/crud/organisation/model";
import { State as RouterState } from "redux-little-router";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

export const useBusinessLineType = () => {
    const router = useSelector<ApplicationState, RouterState | undefined>(state => state);
    const app = useSelector(appSelector);
    return app === Apps.CorporateBooking
        ? businessLineFromRouter(router)
        : BusinessLineType.None;
};

export const businessLineFromRouter = (state: RouterState): BusinessLineType => {
    const path = state.router?.pathname;
    if (path === "/") {
        return BusinessLineType.None;
    }

    return path.includes("/construction")
        ? BusinessLineType.Construction
        : BusinessLineType.Corporate;
};
