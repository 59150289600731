import { EventInstanceGroupItemModel } from "@common/crud/eventInstanceGroup/model";
import moment from "moment";

export const ParseEventInstanceGroupItem = (item: EventInstanceGroupItemModel): EventInstanceGroupItemModel => {
    return {
        ...item,
        startDate: item.startDate && moment(item.startDate),
        startTime: item.startTime && moment.duration(item.startTime),
        eventDuration: item.eventDuration && moment.duration(item.eventDuration),
        registrationDuration: item.registrationDuration && moment.duration(item.registrationDuration),
        deliveryDateTime: item.deliveryDateTime && moment(item.deliveryDateTime),
        registrationEndTime: item.registrationEndTime && moment.duration(item.registrationEndTime),
    };
};
