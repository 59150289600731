import * as React from "react";
import { useSelector } from "react-redux";
import { Modal } from "@common/components";
import { Button, Icon } from "semantic-ui-react";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { languageSelector } from "@booking/selectors";
import { AppSettings } from "@common/appSettings/model";
import { LanguageSettings } from "@common/language/model";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { useTranslation } from "react-i18next";

export const SpecialRequirementsInfoModal: React.FC = () => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [enableToolTip, setEnableToolTip] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>("");
    const currentLanguage: LanguageSettings = useSelector(languageSelector);
    const appSettings: AppSettings = useSelector(appSettingsSelector);
    const [t] = useTranslation("BookingDetails");

    React.useEffect(() => {
        if (appSettings.bookingAppSettings.specialRequirementInfoEN && appSettings.bookingAppSettings.specialRequirementInfoCY)
        {
            setEnableToolTip(true);
        }
    }, [appSettings.bookingAppSettings.specialRequirementInfoCY, appSettings.bookingAppSettings.specialRequirementInfoEN]);

    const openModal = React.useCallback(() => {
        if (currentLanguage.current === "en") {
            setText(appSettings.bookingAppSettings.specialRequirementInfoEN);
        }
        else {
            setText(appSettings.bookingAppSettings.specialRequirementInfoCY);
        }

        setShowModal(true);
    }, [appSettings, currentLanguage]);

    const closeModal = React.useCallback(() => {
        setShowModal(false);
    }, []);

    if (!enableToolTip) {
        return <></>;
    }

    return (
        <>
            <Icon link name="info circle" size="big" onClick={openModal} />
            <Modal open={showModal}>
                <Modal.Content>
                    <HtmlRenderer value={text} />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeModal}>
                        <Icon name='remove' /> {t("CLOSE")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
