import * as React from "react";
import { Modal } from "@common/components";
import { Button, Grid, Checkbox } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { HistoryRecord } from "../model";
import { completePendingNote } from "@common/notes/actions";
import { success } from "@common/toasts/toasts";

interface PendingNoteModalProps {
    open: boolean;
    entry: HistoryRecord;
    onClose: () => void;
}

const PendingNoteModal: React.FunctionComponent<PendingNoteModalProps> = ({ open, onClose, entry }) => {
    const dispatch = useDispatch();
    const [completeNote, setCompleteNote] = React.useState(false);

    const onSave = React.useCallback(async () => {
        if (completeNote) {
            await dispatch(completePendingNote(entry.nudgeTaskId));
            success("Successfully completed note.");
        }

        onClose();
    }, [dispatch, onClose, entry, completeNote]);

    const onCheck = React.useCallback((checked: boolean) => {
        setCompleteNote(checked);
    }, []);

    return (
        <Modal size="small" open={open} onClose={undefined}>
            <Modal.Header>Edit Scheme</Modal.Header>
            <Modal.Content>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Checkbox
                            checked={completeNote}
                            onClick={(e, d) => onCheck(d.checked)}
                            label="Complete pending note"
                        />
                    </Grid.Column>
                </Grid.Row>
            </Modal.Content>
            <Modal.Actions>
                <Button className="cancel-action" onClick={onClose}>Cancel</Button>
                <Button onClick={onSave}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export { PendingNoteModal };
