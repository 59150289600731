import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { EventInstance } from "@common/crud/eventInstance";
import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, List, Modal, Popup } from "semantic-ui-react";
import { Attendee, FlexiPayStatus, FlexiPayStatusEnum, UpdateFlexiPayModel } from  "../../model";
import { EventType, EventTypeApi } from "@common/crud/eventType";
import { isEmptyObject } from "@common/helpers/is-empty-object";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { updateFlexiPay } from "../../actions";
import { push } from "redux-little-router";
import { useFlexiPayFee } from "@common/hooks/useFlexiPayFee";
import { DorsBooking } from "@common/crud/dorsBooking";

export interface FlexiPayStatusLabelWithEditProps {
    attendee: Attendee;
    booking: DorsBooking;
    eventInstance: EventInstance;
}

export const FlexiPayStatusLabelWithEdit = ({ attendee, booking, eventInstance }: FlexiPayStatusLabelWithEditProps) => {
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const [eventType, setEventType] = React.useState<EventType>(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [editingEnabled, setEditingEnabled] = React.useState(true);
    const [reasonsForDisablingEditing, setReasonsForDisablingEditing] = React.useState<string[]>([]);
    const [skipPayment, setSkipPayment] = React.useState(false);
    const referringOrganisation = useSelector(policeOrganisationByDorsId(attendee?.issuingForceId));
    const flexiPayFee = useFlexiPayFee(eventType, referringOrganisation);
    const dispatch = useDispatch();

    const flexiPayActive = attendee.flexiPayStatus === FlexiPayStatusEnum.Active;

    React.useEffect(() => {
        const getEventType = async () => {
            const response = await new EventTypeApi().detail(eventInstance.eventTypeId);
            setEventType(response);
        };
        if (eventInstance?.eventTypeId) {
            getEventType();
        }
    }, [eventInstance]);

    React.useEffect(() => {
        const canInitialise = !!(
            attendee && !isEmptyObject(attendee) && eventInstance && !isEmptyObject(eventInstance) && eventType && flexiPayFee && booking
        );
        if (!canInitialise) {
            return;
        }

        const now = moment();
        const conditionsWithReasons: [boolean, string][] = [
            [attendee.flexiPayStatus === FlexiPayStatusEnum.Used, "Flexi-booking has already been used"],
            [attendee.flexiPayStatus === FlexiPayStatusEnum.Expired, "Flexi-booking was purchased but has expired"],
            [attendee.isBookingCanceled, "Attendee is marked as cancelled"],
            [attendee.isRebookedAttendee && attendee.additionalCourseFees > 0, "Attendee has already paid for a rebooking"],
            [referringOrganisation !== undefined && !referringOrganisation.flexiPayEnabled, "Referring organisation has flexi-booking disabled"],
            [!eventType.flexiPayEnabled, "Scheme has flexi-booking disabled"],
            [eventInstance.deliveryDateTime.isSameOrBefore(now), "Delivery date of course is today or in the past"],
            [!flexiPayFee, "Unable to find valid flexi-booking fee for scheme"],
            [eventInstance.deliveryDateTime.isSameOrBefore(booking.initialBookingDate.clone().add(14, "days")),
                "Delivery date of course is within 14 days of initial booking date"]
        ];

        const newReasonsForDisablingEditing = conditionsWithReasons.filter(c => c[0]).map(c => c[1]);
        setReasonsForDisablingEditing(newReasonsForDisablingEditing);
        setEditingEnabled(newReasonsForDisablingEditing.length === 0);
    }, [attendee, eventInstance, eventType, referringOrganisation, flexiPayFee, booking]);

    function onActionClick() {
        if (editingEnabled) {
            setConfirmationModalOpen(true);
        }
    }

    function closeConfirmationModal() {
        setConfirmationModalOpen(false);
        setSkipPayment(false);
    }

    function onContinueClick() {
        if (flexiPayActive || skipPayment) {
            const updateFlexiPayModel: UpdateFlexiPayModel = { flexiPayFee: flexiPayFee * 100, flexiPayPurchased: skipPayment, skipPayment };
            dispatch(updateFlexiPay(eventInstance.id, attendee.id, updateFlexiPayModel));
            closeConfirmationModal();
            return;
        }

        dispatch(push(`${attendee.id}/${PaymentPageNames[PaymentPageNamesEnum.FlexiPayPayment]}`));
    }

    function toggleSkipPayment() {
        setSkipPayment(prev => !prev);
    }

    const actionName = attendee.flexiPayStatus === FlexiPayStatusEnum.NotActive ? "Add flexi-booking" : "Remove flexi-booking";

    return (
        <>
            <div className="label-and-value">
                <span className="label fixed-width">
                    Flexi-booking status
                </span>
                <Popup
                    on="hover"
                    header={`Can't ${actionName.toLowerCase()}`}
                    disabled={editingEnabled}
                    content={reasonsForDisablingEditing.length > 1 ?
                        <List bulleted>
                            {reasonsForDisablingEditing.map(r => <List.Item key={r}>{r}</List.Item>)}
                        </List> :
                        reasonsForDisablingEditing[0]
                    }
                    trigger={isClientAdvisor &&
                        <a
                            className={`show-button ${!editingEnabled ? "disabled disabled-link" : ""}`}
                            onClick={onActionClick}
                        >
                            {actionName}
                        </a>
                    }
                />
                <div className="value">
                    {FlexiPayStatus[attendee.flexiPayStatus]}
                </div>
            </div>
            <Modal open={confirmationModalOpen}>
                <Modal.Header>
                    Are you sure?
                </Modal.Header>
                <Modal.Content>
                    <p>
                        {`Are you sure you want to ${flexiPayActive ? "remove flexi-booking from" : "add flexi-booking to"}
                        this attendee? This means they will ${flexiPayActive ? "not " : ""}be able to use flexi-booking for a future rebooking.`}
                    </p>
                    {!flexiPayActive && <Checkbox checked={skipPayment} label="Skip payment" onChange={toggleSkipPayment} />}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeConfirmationModal} className="cancel-action" content="Cancel" />
                    <Button onClick={onContinueClick} content={flexiPayActive || skipPayment ? "Continue" : "Continue to payment"} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
