import { Trainer } from "@common/crud/trainer";
import { Spinner } from "@common/global";
import moment from "moment";
import * as React from "react";
import { DayContentProps, DayPicker } from "react-day-picker";
import { Button, Container, Grid } from "semantic-ui-react";
import { StandbyTrainerAvailabilityForDay, StandbyTrainersCalendar, StandbyTrainersCalendarDay, TrainerNamesForDay } from "../../standbyTrainersCalendarModel";
import { StandbyTrainersApi } from "../../StandbyTrainersApi";
import { StandbyTrainers } from "./StandbyTrainers";
import { AssignStandbyTrainersModal } from "./AssignStandbyTrainersModal";
import "./StandbyTrainersCalendarStyle.scss";
import { DayPeriodEnum } from "@common/model";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { useWindowDimensions } from "@common/global/UseWindowDimensions";

interface StandbyTrainersCalendarProps {
    editing: boolean;
    stopEditing: () => void;
}

export const StandbyTrainersCalendarComponent: React.FC<StandbyTrainersCalendarProps> =
({ editing, stopEditing }) => {
    const [month, setMonth] = React.useState(moment().utc().startOf("M"));
    const [selectedDate, setSelectedDate] = React.useState(moment().utc().startOf("M"));
    const [standByTrainersCalendar, setStandByTrainersCalendar] = React.useState<StandbyTrainersCalendar>();
    const [allTrainers, setAllTrainers] = React.useState<Trainer[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [trainerNames, setTrainerNames] = React.useState<TrainerNamesForDay>(null);
    const [allAvailableTrainers, setAllAvailableTrainers] = React.useState<StandbyTrainerAvailabilityForDay[]>([]);
    const [changes, setChanges] = React.useState<StandbyTrainersCalendarDay[]>([]);
    const [nameSearch, setNameSearch] = React.useState("");
    const { isDayBankHoliday } = useBankHolidays();
    const { isMobileLandscapeHeight, isTabletScreenSize } = useWindowDimensions();

    const loadMonthData = React.useCallback(async () => {
        setLoading(true);
        const api = new StandbyTrainersApi();
        setStandByTrainersCalendar(await api.getStandbyTrainersForMonth(month));
        setLoading(false);
        setTrainerNames(null);
    }, [month]);

    React.useEffect(() => {
        loadMonthData();
    }, [loadMonthData, month]);

    React.useEffect(() => {
        async function loadData() {
            const api = new StandbyTrainersApi();
            setAllTrainers(await api.getAllStandbyTrainers());
        }
        loadData();
    }, []);

    React.useEffect(() => {
        async function loadData() {
            const api = new StandbyTrainersApi();
            const result = await api.getStandbyTrainersForDay(selectedDate);
            setAllAvailableTrainers(result);
        }
        if (editing) {
            loadData();
        }

    }, [editing, selectedDate]);

    function getTrainerName(trainerId: string) {
        const name = allTrainers?.find(t => t.id === trainerId)?.fullName ?? "Unknown!";

        if (name.length < 26) {
            return name;
        }

        return `${name.slice(0, 20)}...`;
    }

    function renderDay(props: DayContentProps) {
        const day = props.date;
        const currentDay = day.getDate();
        const dayMoment = moment.utc([day.getFullYear(), day.getMonth(), currentDay]).startOf("day");
        const arrangementsForDay = standByTrainersCalendar?.days.find(d => d.date.date() === dayMoment.date());
        const isBankHoliday = isDayBankHoliday(dayMoment);

        function onClick() {
            setSelectedDate(dayMoment);
            setTrainerNames({
                date: dayMoment,
                trainerNames: [],
                sessionOneTrainerNames: (arrangementsForDay?.sessionOneStandbyTrainerIds ?? []).map(id => getTrainerName(id)),
                sessionTwoTrainerNames: (arrangementsForDay?.sessionTwoStandbyTrainerIds ?? []).map(id => getTrainerName(id)),
                sessionThreeTrainerNames: (arrangementsForDay?.sessionThreeStandbyTrainerIds ?? []).map(id => getTrainerName(id)),
                sessionFourTrainerNames: (arrangementsForDay?.sessionFourStandbyTrainerIds ?? []).map(id => getTrainerName(id)),
                sessionSelected: ""
            });
            if (editing) {
                setModalOpen(true);
            }
        }

        function getStandbyTrainersClass(ids: string[]) {
            return (ids?.length > 0 ?? false)
                ? "standby-trainer-button"
                : "standby-trainer-button-no-assignees";
        }

        function displayTrainerNames(event: React.MouseEvent<HTMLButtonElement>, session: DayPeriodEnum, ids: string[]) {
            const trainerNamesForTrainerType = ids.map(id => getTrainerName(id));

            let sessionSelected = "";
            switch (session) {
                case DayPeriodEnum.SessionOne:
                    sessionSelected = "Session One";
                    break;
                case DayPeriodEnum.SessionTwo:
                    sessionSelected = "Session Two";
                    break;
                case DayPeriodEnum.SessionThree:
                    sessionSelected = "Session Three";
                    break;
                case DayPeriodEnum.SessionFour:
                    sessionSelected = "Session Four";
                    break;
                default:
                    break;
            }

            setTrainerNames({
                date: dayMoment,
                trainerNames: trainerNamesForTrainerType,
                sessionOneTrainerNames: [],
                sessionTwoTrainerNames: [],
                sessionThreeTrainerNames: [],
                sessionFourTrainerNames: [],
                sessionSelected
            });
            if (!editing) {
                event.stopPropagation();
            }
        }

        function getStandbyTrainersContent(ids: string[]) {
            const trainerNamesForTrainerType = (ids ?? []).map(id => getTrainerName(id));
            if (trainerNamesForTrainerType.length === 1) {
                return trainerNamesForTrainerType[0];
            } else if (trainerNamesForTrainerType.length > 1) {
                return `${trainerNamesForTrainerType.length} Trainers`;
            } else {
                return "";
            }
        }

        return (
            <div className="cell-style" onClick={onClick}>
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday &&
                        <span className={"bank-holiday-text"}>
                            {isTabletScreenSize || isMobileLandscapeHeight ? "BH" : "Bank holiday"}
                        </span>
                    }
                    <span>{currentDay}</span>
                </div>
                <Button
                    size="small"
                    className={getStandbyTrainersClass(arrangementsForDay?.sessionOneStandbyTrainerIds)}
                    onClick={(event) => displayTrainerNames(event, DayPeriodEnum.SessionOne, arrangementsForDay?.sessionOneStandbyTrainerIds)}>
                    {getStandbyTrainersContent(arrangementsForDay?.sessionOneStandbyTrainerIds)}
                </Button>
                <Button
                    size="small"
                    className={getStandbyTrainersClass(arrangementsForDay?.sessionTwoStandbyTrainerIds)}
                    onClick={(event) => displayTrainerNames(event, DayPeriodEnum.SessionTwo, arrangementsForDay?.sessionTwoStandbyTrainerIds)}>
                    {getStandbyTrainersContent(arrangementsForDay?.sessionTwoStandbyTrainerIds)}
                </Button>
                <Button
                    size="small"
                    className={getStandbyTrainersClass(arrangementsForDay?.sessionThreeStandbyTrainerIds)}
                    onClick={(event) => displayTrainerNames(event, DayPeriodEnum.SessionThree, arrangementsForDay?.sessionThreeStandbyTrainerIds)}>
                    {getStandbyTrainersContent(arrangementsForDay?.sessionThreeStandbyTrainerIds)}
                </Button>
                <Button
                    size="small"
                    className={getStandbyTrainersClass(arrangementsForDay?.sessionFourStandbyTrainerIds)}
                    onClick={(event) => displayTrainerNames(event, DayPeriodEnum.SessionFour, arrangementsForDay?.sessionFourStandbyTrainerIds)}>
                    {getStandbyTrainersContent(arrangementsForDay?.sessionFourStandbyTrainerIds)}
                </Button>
            </div>
        );
    }

    function onMonthChange(date: Date) {
        const calendarDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
        const startOfMonth = calendarDate.clone().startOf("month");
        setMonth(startOfMonth);
    }

    async function closeModal() {
        setChanges([]);
        setNameSearch("");
        setModalOpen(false);
        await loadMonthData();
    }

    function updateCurrentAssignments(update: StandbyTrainersCalendarDay[]) {
        setStandByTrainersCalendar({ ...standByTrainersCalendar, days: update });
    }

    function handleSearchChange(_: any, { value }: { value: string }) {
        setNameSearch(value);
    }

    return (
        <Container>
            <Spinner active={loading}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={14} mobile={10} tablet={12}>
                            <DayPicker
                                className="narrow standby-calendar"
                                selected={[]}
                                components={{ DayContent: renderDay }}
                                month={month.toDate()}
                                onMonthChange={onMonthChange}
                                disableNavigation={editing}
                                key={JSON.stringify(standByTrainersCalendar?.days)}
                                weekStartsOn={1}
                            />
                            <AssignStandbyTrainersModal
                                date={selectedDate}
                                open={modalOpen}
                                closeModal={closeModal}
                                currentAssignments={standByTrainersCalendar?.days}
                                key={selectedDate.toISOString()}
                                updateCurrentAssignments={updateCurrentAssignments}
                                stopEditing={stopEditing}
                                setDate={setSelectedDate}
                                allAvailableTrainers={allAvailableTrainers}
                                changes={changes}
                                setChanges={setChanges}
                                handleSearchChange={handleSearchChange}
                                nameSearch={nameSearch}
                            />
                        </Grid.Column>
                        <Grid.Column computer={2} mobile={6} tablet={4}>
                            <StandbyTrainers trainerNamesForDay={trainerNames} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Spinner>
        </Container>
    );
};
