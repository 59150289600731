import { EventType } from "@common/crud/eventType";
import { DorsSiteModel, Venue } from "../model";
import { DsaAreaListItem } from "@common/dsa";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { SchemeDeliveryTypeEnum, VenueTypeEnum } from "@common/crud/organisation/model";

export function getDorsSitesOptions(dorsSites?: DorsSiteModel[]) {
    // sort by siteId desc
    const sortedBySiteIdDesc = dorsSites?.sort((a, b) => b.siteId - a.siteId) || [];

    return sortedBySiteIdDesc.map(c => ({
        key: c.siteId,
        text: c.siteName,
        value: c.siteId
    }));
}

export function getDsaAreasOptions(dsaAreas?: DsaAreaListItem[]) {
    return dsaAreas ? dsaAreas.map(da => ({ text: da.description, value: da.id })) : [];
}

export const venueHasCorrectDeliveryType = (venue: Venue, eventType: EventType) => {
    if (!eventType) {
        return true;
    }

    if (eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) {
        return true;
    }

    if (eventType.deliveryType === SchemeDeliveryTypeEnum.Classroom) {
        return venue.deliveryType === DeliveryTypeEnum.Onsite;
    }

    if (eventType.deliveryType === SchemeDeliveryTypeEnum.Digital) {
        return venue.deliveryType === DeliveryTypeEnum.Digital;
    }

    return true;
};

export const isVenueTypeCorrectForCorporate = (v: Venue, organisationId: string) => {
    return v.venueType === VenueTypeEnum.DelegateHome ||
        v.venueType === VenueTypeEnum.CorporateShared ||
        (v.venueType === VenueTypeEnum.CorporateOrganisationSpecific && v.organisationId === organisationId);
};

export const isVenueTypeCorrectForConstruction = (v: Venue, organisationId: string) => {
    return v.venueType === VenueTypeEnum.DelegateHome ||
        v.venueType === VenueTypeEnum.Enquiry ||
        v.venueType === VenueTypeEnum.ConstructionShared ||
        (v.venueType === VenueTypeEnum.ConstructionOrganisationSpecific && v.organisationId === organisationId);
};

export const isVenueTypeCorrectForPoliceAndCourt = (v: Venue) => {
    return v.venueType === VenueTypeEnum.PoliceAndCourt;
};
