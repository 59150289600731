import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "redux-little-router";
import { Grid, Menu } from "semantic-ui-react";
import { BusinessLineType, CompanyTypeEnum } from "../model";
import { corporateOrConstructionBasePathSelector, courtsBasePathSelector, policeBasePathSelector, routeIdSelector, organisationSelector } from "../selectors";
import { isBusinessLineTypeCorporateOrConstruction } from "@common/global/CommonHelpers";
import { appSettingsSelector } from "@common/appSettings/selectors";

export interface ContextMenuProps {
    businessLineType?: BusinessLineType;
}

export const ContextMenu: React.FC<ContextMenuProps> = (props) => {
    const { businessLineType } = props;
    const policeBasePath = useSelector(policeBasePathSelector);
    const courtsBasePath = useSelector(courtsBasePathSelector);
    const corporateOrConstructionBasePath = useSelector(corporateOrConstructionBasePathSelector);
    const settings = useSelector(appSettingsSelector);
    const organisationPendingCourses = settings.featureSettings?.organisationPendingCourses;

    let basePath = "";
    switch (businessLineType) {
        case BusinessLineType.Corporate:
        case BusinessLineType.Construction:
            basePath = corporateOrConstructionBasePath;
            break;
        case BusinessLineType.Police:
            basePath = policeBasePath;
            break;
        case BusinessLineType.Court:
            basePath = courtsBasePath;
            break;
        default:
            break;
    }

    const organisationId = useSelector(routeIdSelector);
    const organisation = useSelector(organisationSelector);

    const organisationWithOrders = React.useMemo(() =>
        !(organisation?.companyType?.some(ct => ct === CompanyTypeEnum.BusinessDriver) || false),
    [organisation?.companyType]);

    const allOrgType = () => {
        let orgName = "";
        switch (businessLineType) {
            case BusinessLineType.Corporate:
            case BusinessLineType.Construction:
                orgName = "Organisations";
                break;
            case BusinessLineType.Police:
                orgName = "Police";
                break;
            case BusinessLineType.Court:
                orgName = "Courts";
                break;
            default:
                break;
        }
        return (
            <Menu.Item name={`All ${orgName}`} as={Link} href={basePath}>
                {`All ${orgName}`}
            </Menu.Item>
        );
    };

    return (
        <Grid.Column vertical as={Menu}>
            {allOrgType()}
            <Menu.Item name="Details" as={Link} href={`${basePath}/${organisationId}`}>
                Details
            </Menu.Item>
            {businessLineType && businessLineType === BusinessLineType.Police && <>
                <Menu.Item name="Users" as={Link} href={`${basePath}/${organisationId}/police-users`}>
                    Users
                </Menu.Item>
                <Menu.Item name="Fees" as={Link} href={`${basePath}/${organisationId}/fees`}>
                    Fees
                </Menu.Item>
                <Menu.Item name="Contracts" as={Link} href={`${basePath}/${organisationId}/contracts`}>
                    Contracts
                </Menu.Item>
                <Menu.Item name="Area Management" as={Link} href={`${basePath}/${organisationId}/areaManagement`}>
                    Area Management
                </Menu.Item>
                <Menu.Item name="Features" as={Link} href={`${basePath}/${organisationId}/features`}>
                    Features
                </Menu.Item>
                <Menu.Item name="Trainer Fees" as={Link} href={`${basePath}/${organisationId}/trainer-fees`}>
                    Trainer Fees
                </Menu.Item>
                <Menu.Item name="History" as={Link} href={`${basePath}/${organisationId}/history`}>
                    History
                </Menu.Item>
                <Menu.Item name="Communications" as={Link} href={`${basePath}/${organisationId}/communications`}>
                    Communications
                </Menu.Item>
            </>}
            {businessLineType && businessLineType === BusinessLineType.Court && <>
                <Menu.Item name="History" as={Link} href={`${basePath}/${organisationId}/history`}>
                    History
                </Menu.Item>
            </>}
            {(businessLineType && isBusinessLineTypeCorporateOrConstruction(+businessLineType)) && <>
                <Menu.Item name="Contacts" as={Link} href={`${basePath}/${organisationId}/contacts`}>
                    Contacts
                </Menu.Item>
                <Menu.Item name="History" as={Link} href={`${basePath}/${organisationId}/history`}>
                    History
                </Menu.Item>
                <Menu.Item name="Schedule" as={Link} href={`${basePath}/${organisationId}/schedule`}>
                    Schedule
                </Menu.Item>
                {organisationWithOrders && (
                    <Menu.Item name="Orders" as={Link} href={`${basePath}/${organisationId}/orders`}>
                        Orders
                    </Menu.Item>
                )}
                {(!organisation?.corporateOrganisationData?.openCourse && organisationPendingCourses) &&
                    <Menu.Item name="Pending Courses" as={Link} href={`${basePath}/${organisationId}/pending-courses`}>
                        Pending Courses
                    </Menu.Item>
                }
                <Menu.Item name="Calendar" as={Link} href={`${basePath}/${organisationId}/calendar`}>
                    Calendar
                </Menu.Item>
                <Menu.Item name="Venues" as={Link} href={`${basePath}/${organisationId}/venues`}>
                    Venues
                </Menu.Item>
                {(businessLineType && businessLineType === BusinessLineType.Corporate) &&
                <Menu.Item name="Delegates" as={Link} href={`${basePath}/${organisationId}/delegates`}>
                    Delegates
                </Menu.Item>}
                {organisationWithOrders && (
                    <Menu.Item name="Users" as={Link} href={`${basePath}/${organisationId}/users`}>
                        Users
                    </Menu.Item>
                )}
                {organisation?.corporateOrganisationData?.hasCorporateOrganisationSpecificDepartments && (
                    <Menu.Item name="Departments" as={Link} href={`${basePath}/${organisationId}/departments`}>
                        Departments
                    </Menu.Item>
                )}
                {!organisation?.corporateOrganisationData?.openCourse &&
                    <Menu.Item name="Config" as={Link} href={`${basePath}/${organisationId}/config`}>
                        Configuration
                    </Menu.Item>
                }
                {organisation?.corporateOrganisationData?.hasCorporateOrganisationSpecificDelegateFieldsData && (
                    <Menu.Item name="Fees" as={Link} href={`${basePath}/${organisationId}/attendee-fields`}>
                        Delegate Fields
                    </Menu.Item>
                )}
                {organisation?.corporateOrganisationData?.hasCorporateOrganisationSpecificFeesData && (
                    <Menu.Item name="Fees" as={Link} href={`${basePath}/${organisationId}/fees`}>
                        Fees
                    </Menu.Item>
                )}
                {organisation?.corporateOrganisationData?.hasCorporateOrganisationSpecificPurchaseOrderDrawdowns && (
                    <Menu.Item name="Purchase Orders" as={Link} href={`${basePath}/${organisationId}/purchase-orders`}>
                        Purchase Orders
                    </Menu.Item>
                )}
            </>}
        </Grid.Column>
    );
};
