/* eslint-disable max-lines */
import { Location } from "redux-little-router";
import moment, { Moment } from "moment";
import { Address } from "../common/Address";
import { DsaAreaListItem } from "@common/dsa";
import { OpenCourseFee, TrainerFee, ValueWithEffectiveDate } from "../eventType/model";
import { HistoryRecord } from "@common/history/model";
import { EventInstance, EventInstanceState } from "../eventInstance";
import { omit, pick } from "lodash";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { CustomerStatusEnum } from "../alaskaNudgeTask/model";
import { PendingEventInstanceState } from "../pendingCourses/model";

export interface OrganisationContactModel {
    readonly contacts: OrganisationContactDataModel[];
}

export interface OrganisationContactDataModel {
    readonly name: string;
    readonly email: string;
    readonly telephone: string;
    readonly courseCreationCommsDestination?: boolean;
    readonly courseReminderCommsDestination?: boolean;
    readonly certificateCommsDestination?: boolean;
    readonly bookingCommsDestination?: boolean;
    readonly courseEditCommsDestination?: boolean;
}

export const GetContactDescription = (contact: OrganisationContactDataModel) => {
    return contact.name
        ? `${contact.name} [${contact.email}]`
        : contact.email;
};

export interface DelegatesContactModel {
    readonly courseReminderCommsDestination?: boolean;
    readonly certificateCommsDestination?: boolean;
    readonly bookingCommsDestination?: boolean;
    readonly courseEditCommsDestination?: boolean;
}

export interface ManagersContactModel {
    readonly courseReminderCommsDestination?: boolean;
    readonly certificateCommsDestination?: boolean;
    readonly bookingCommsDestination?: boolean;
    readonly courseEditCommsDestination?: boolean;
}

export enum BusinessLineType {
    None = 0,
    Corporate = 1,
    Police = 2,
    Court = 3,
    Construction = 4,
}

export const BusinessLineTypeKeys = {
    [BusinessLineType.None]: "None",
    [BusinessLineType.Corporate]: "Corporate",
    [BusinessLineType.Police]: "Police",
    [BusinessLineType.Court]: "Court",
    [BusinessLineType.Construction]: "Construction"
};

export enum CorporateBusinessLineType {
    NonCorporate = 0,
    TtcOrLicenceBureau = 1,
    BusinessDriver = 2
};

export enum CountryEnum {
    None = 0,
    England = 1,
    Scotland = 2,
    Wales = 3,
    NIreland = 4
}

export const Country = {
    [CountryEnum.None]: "None",
    [CountryEnum.England]: "England",
    [CountryEnum.Scotland]: "Scotland",
    [CountryEnum.Wales]: "Wales",
    [CountryEnum.NIreland]: "Northern Ireland"
};

export const doesPriceIncludeVat = (businessLineType: BusinessLineType) => {
    switch (businessLineType) {
        case BusinessLineType.Corporate: return true;
        case BusinessLineType.Police: return true;
        case BusinessLineType.Court: return true;
        case BusinessLineType.Construction: return false;
        default: return true;
    };
};

export const CountryDropDownOptions = ObjectKeys(omit(Country, CountryEnum.None))
    .filter(k => typeof Country[k] === "string")
    .map(k => ({ text: Country[k] as string, value: +k }));

export const enum VenueTypeEnum {
    None = 0,
    PoliceAndCourt = 1,
    CorporateOrganisationSpecific = 2,
    CorporateShared = 3,
    ConstructionOrganisationSpecific = 4,
    ConstructionShared = 5,
    DelegateHome = 6,
    Enquiry = 7
}

export const venueTypeNeedsAddress = (venueType: VenueTypeEnum) =>
    venueType !== VenueTypeEnum.DelegateHome && venueType !== VenueTypeEnum.Enquiry;

export const PoliceAndCourtVenueType = {
    [VenueTypeEnum.PoliceAndCourt]: "Police and Court",
};

export const CorporateVenueType = {
    [VenueTypeEnum.CorporateOrganisationSpecific]: "Organisation Specific",
    [VenueTypeEnum.CorporateShared]: "Shared",
    [VenueTypeEnum.DelegateHome]: "Delegate Home"
};

export const ConstructionVenueType = {
    [VenueTypeEnum.ConstructionOrganisationSpecific]: "Organisation Specific",
    [VenueTypeEnum.ConstructionShared]: "Shared",
    [VenueTypeEnum.Enquiry]: "Enquiry"
};

export const VenueType = {
    ...PoliceAndCourtVenueType,
    ...CorporateVenueType,
    ...ConstructionVenueType
};

export enum SchemeDeliveryTypeEnum {
    Unknown = 0,
    Classroom = 1,
    Digital = 2,
    ClassroomAndDigital = 3
}

export const SchemeDeliveryType = {
    [SchemeDeliveryTypeEnum.Unknown]: "Unknown",
    [SchemeDeliveryTypeEnum.Classroom]: "Classroom",
    [SchemeDeliveryTypeEnum.Digital]: "Digital",
    [SchemeDeliveryTypeEnum.ClassroomAndDigital]: "Both"
};

export enum OrganisationSpecificFields {
    ProductFees = 0,
    DelegateFields = 1,
    DigitalDetails = 2,
    Departments = 3,
    PurchaseOrderDrawdowns = 4,
    CpcBookingUrl = 5,
    CpcOpenBookNowPayLater = 6
}

export interface SearchOptions {
    organisationName?: string;
    partnerName?: string;
    businessLineType?: BusinessLineType[];
    customerStatus?: string;
    showExpired?: boolean;
    hasCorporateOrganisationSpecificCpcBookingUrl?: boolean;
}

export interface CourtOrganisationData {
    readonly dsaArea: number;
    readonly address: Address;
    readonly country: CountryEnum;
}

export interface CorporateOrganisationSpecificFeesData {
    openDigitalEIFeeWithEffectiveDate?: ValueWithEffectiveDate<OpenCourseFee>[];
    openClassroomEIFeeWithEffectiveDate?: ValueWithEffectiveDate<OpenCourseFee>[];
    closedDigitalEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    closedClassroomEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    cpcUploadFee?: ValueWithEffectiveDate<number>[];
}

export interface CorporateOrganisationData {
    readonly openCourse?: boolean;
    readonly bdmId?: string;
    readonly bdmName?: string;
    readonly bdmEmail?: string;
    readonly address: Address;
    readonly invoiceAddress?: Address;
    readonly emailAddress: string;
    readonly invoiceEmailAddress?: string;
    readonly phoneNumber?: string;
    readonly invoicePhoneNumber?: string;
    readonly website?: string;
    readonly partner?: string;
    readonly accountManager?: string;
    readonly termsAndConditionsInfo?: string;
    readonly hasCorporateOrganisationSpecificFeesData?: boolean;
    readonly corporateOrganisationSpecificFeesData: CorporateOrganisationSpecificFeesData;
    readonly hasCorporateOrganisationSpecificDelegateFieldsData?: boolean;
    readonly hasCorporateOrganisationSpecificDigitalDetails?: boolean;
    readonly hasCorporateOrganisationSpecificDepartments?: boolean;
    readonly isBundleDiscountEnabled: boolean;
    readonly hasCorporateOrganisationSpecificPurchaseOrderDrawdowns?: boolean;
    readonly hasCorporateOrganisationSpecificCpcBookingUrl?: boolean;
    readonly hasCpcOpenBookNowPayLater?: boolean;
}

export interface BusinessDevelopmentManager {
    readonly id?: string;
    readonly name?: string;
    readonly email?: string;
}

export interface OrganisationListModel {
    readonly id: string;
    readonly name?: string;
    readonly isExpired?: boolean;
    readonly expiryDate?: Moment;
    readonly listUpdated?: Moment;
    readonly reminderType: number;
    readonly customerStatus?: CustomerStatusEnum;
    readonly companyType?: number[];
    readonly businessLineType: BusinessLineType;
    readonly corporateBusinessLineType: CorporateBusinessLineType;
    readonly corporateOrganisationData?: CorporateOrganisationData;
    readonly organisationContact?: OrganisationContactModel;
    readonly courtOrganisationData?: CourtOrganisationData;
    readonly regionalManagers?: ManagementContactDetails[];
    readonly regionalCoordinators?: ManagementContactDetails[];
    readonly flexiPayEnabled?: boolean;
    readonly chargesOwnFlexiPayFees?: boolean;
    readonly flexiPayFees?: ValueWithEffectiveDate<number>[];
    readonly managementInformationOnly?: boolean;
    readonly notificationsEnabled?: boolean;
    readonly organisationSurveys?: boolean;
    readonly surveyLink?: string;
    readonly surveyLinkCy?: string;
    readonly secondSurveyLink?: string;
    readonly secondSurveyLinkCy?: string;
    readonly organisationTermsAndConditions?: boolean;
    readonly termsAndConditionsLink?: string;
    readonly termsAndConditionsLinkCy?: string;
    readonly organisationBookingInformationLink?: boolean;
    readonly bookingInformationLinkClassroom?: string;
    readonly bookingInformationLinkClassroomCy?: string;
    readonly bookingInformationLinkDigital?: string;
    readonly bookingInformationLinkDigitalCy?: string;
    readonly relatedOrganisationId?: string;
    readonly specificUrlIdentifier?: string;
}

export interface ManagementContactDetails {
    readonly id: string;
    readonly name?: string;
    readonly email?: string;
    readonly number?: string;
}

export interface OrganisationDetailModel {
    readonly id: string;
    readonly name?: string;
    readonly expiryDate?: Moment;
    readonly detailUpdated?: Moment;
    readonly reminderType: number;
    readonly dorsId?: number;
    readonly organisationContact?: OrganisationContactModel;
    readonly organisationCourseContact?: OrganisationContactModel;
    readonly organisationFinanceContact?: OrganisationContactModel;
    readonly delegatesContact?: DelegatesContactModel;
    readonly managersContact?: ManagersContactModel;
    readonly businessLineType: BusinessLineType;
    readonly corporateBusinessLineType: CorporateBusinessLineType;
    readonly courtOrganisationData?: CourtOrganisationData;
    readonly corporateOrganisationData?: CorporateOrganisationData;
    readonly regionalManagers?: ManagementContactDetails[];
    readonly regionalCoordinators?: ManagementContactDetails[];
    readonly flexiPayEnabled?: boolean;
    readonly chargesOwnFlexiPayFees?: boolean;
    readonly flexiPayFees?: ValueWithEffectiveDate<number>[];
    readonly managementInformationOnly?: boolean;
    readonly notificationsEnabled?: boolean;
    readonly organisationSurveys?: boolean;
    readonly surveyLink?: string;
    readonly surveyLinkCy?: string;
    readonly secondSurveyLink?: string;
    readonly secondSurveyLinkCy?: string;
    readonly organisationTermsAndConditions?: boolean;
    readonly termsAndConditionsLink?: string;
    readonly termsAndConditionsLinkCy?: string;
    readonly organisationBookingInformationLink?: boolean;
    readonly bookingInformationLinkClassroom?: string;
    readonly bookingInformationLinkClassroomCy?: string;
    readonly bookingInformationLinkDigital?: string;
    readonly bookingInformationLinkDigitalCy?: string;
    readonly classroomTrainerFees?: Record<string, TrainerFee[]>;
    readonly practicalTrainerFees?: Record<string, TrainerFee[]>;
    readonly history?: HistoryRecord[];
    readonly customerStatus?: CustomerStatusEnum;
    readonly companyType?: CompanyTypeEnum[];
    readonly organisationNote?: string;
    readonly favourite?: boolean;
    readonly relatedOrganisationId?: string;
    readonly isBundleDiscountEnabled?: boolean;
    readonly specificUrlIdentifier?: string;
    readonly relatedOrganisationSpecificUrlIdentifier?: string;
}

export type Organisation = OrganisationListModel & OrganisationDetailModel;

export const NoneOrganisationOption = "None";

export interface OrganisationCreateModel {
    id: string;
    name: string;
    reminderType: number;
    dorsId?: number;
    expiryDate?: Moment;
    organisationContact?: OrganisationContactModel;
    organisationCourseContact?: OrganisationContactModel;
    organisationFinanceContact?: OrganisationContactModel;
    flexiPayEnabled?: boolean;
    managementInformationOnly?: boolean;
    notificationsEnabled?: boolean;
    organisationSurveys?: boolean;
    surveyLink?: string;
    surveyLinkCy?: string;
    secondSurveyLink?: string;
    secondSurveyLinkCy?: string;
    organisationTermsAndConditions?: boolean;
    termsAndConditionsLink?: string;
    termsAndConditionsLinkCy?: string;
    organisationBookingInformationLink?: boolean;
    bookingInformationLinkClassroom?: string;
    bookingInformationLinkClassroomCy?: string;
    bookingInformationLinkDigital?: string;
    bookingInformationLinkDigitalCy?: string;
    customerStatus?: number;
    companyType?: number[];
    organisationNote?: string;
    readonly businessLineType: BusinessLineType;
    readonly corporateOrganisationData?: CorporateOrganisationData;
    readonly courtOrganisationData?: CourtOrganisationData;
}

export interface OrganisationEditModel {
    id: string;
    name: string;
    requiresDeletion?: boolean;
    reminderType: number;
    dorsId?: number;
    expiryDate?: Moment;
    organisationContact?: OrganisationContactModel;
    organisationCourseContact?: OrganisationContactModel;
    organisationFinanceContact?: OrganisationContactModel;
    flexiPayEnabled?: boolean;
    managementInformationOnly?: boolean;
    notificationsEnabled?: boolean;
    organisationSurveys?: boolean;
    surveyLink?: string;
    surveyLinkCy?: string;
    secondSurveyLink?: string;
    secondSurveyLinkCy?: string;
    organisationTermsAndConditions?: boolean;
    termsAndConditionsLink?: string;
    termsAndConditionsLinkCy?: string;
    organisationBookingInformationLink?: boolean;
    bookingInformationLinkClassroom?: string;
    bookingInformationLinkClassroomCy?: string;
    bookingInformationLinkDigital?: string;
    bookingInformationLinkDigitalCy?: string;
    customerStatus: number;
    companyType: number[];
    organisationNote?: string;
    relatedOrganisationId?: string;
    specificUrlIdentifier?: string;
    readonly businessLineType: BusinessLineType;
    readonly dsaArea?: number;
    readonly courtOrganisationData?: CourtOrganisationData;
    readonly corporateOrganisationData?: CorporateOrganisationData;
    readonly regionalManagers?: ManagementContactDetails[];
    readonly regionalCoordinators?: ManagementContactDetails[];
    readonly classroomTrainerFees?: Record<string, TrainerFee[]>;
    readonly practicalTrainerFees?: Record<string, TrainerFee[]>;
}

export interface OrganisationState {
    readonly organisations: Organisation[];
    readonly relatedOrganisationsOptions: Organisation[];
    readonly businessLineType: BusinessLineType;
    readonly dsaAreas: DsaAreaListItem[];
    readonly businessDevelopmentManagers: BusinessDevelopmentManager[];
}

export interface AdHocEmailCorporateOrganisation {
    senderEmailAddress: string;
    templateName: string;
    templateId: number;
    customEmailSubject: string;
    customEmailBody: string;
    contacts: { id: number; emails: string[]}[];
    organisationName: string;
    attachments?: File[];
}

export enum ReminderType {
    None = 0,
    Email = 1,
    SMS = 2,
    Both = 3
}

export enum CorporateOrganisationContact {
    Primary = 0,
    Course = 1,
    Finance = 2
}

export const CorporateOrganisationContactName = {
    [CorporateOrganisationContact.Primary]: "Primary Contact",
    [CorporateOrganisationContact.Course]: "Course Contact",
    [CorporateOrganisationContact.Finance]: "Finance Contact",
};

export enum AttendeeFieldTypeEnum {
    Text = 1,
    Number = 2
}

export const AttendeeFieldType = {
    [AttendeeFieldTypeEnum.Text]: "Text",
    [AttendeeFieldTypeEnum.Number]: "Number",
};

export interface AttendeeField {
    id: string;
    displayName: string;
    fieldId: string;
    type: AttendeeFieldTypeEnum;
    dateInactive?: moment.Moment;
}

export interface AttendeeFieldResponse {
    attendeeFields: AttendeeField[];
    error: string;
}

export enum CompanyTypeEnum {
    TTC = 1,
    BusinessDriver = 2,
    LicenceBureau = 3,
    Construction = 4,
}

export const CompanyType = {
    [CompanyTypeEnum.TTC]: "TTC",
    [CompanyTypeEnum.BusinessDriver]: "Business Driver",
    [CompanyTypeEnum.LicenceBureau]: "TTC Commercial Services",
    [CompanyTypeEnum.Construction]: "Construction",
};

export interface Department {
    id: string;
    name: string;
    dateInactive?: moment.Moment;
}

export interface DepartmentResponse {
    departments: Department[];
    error: string;
}

export const CompanyTypeOptions = (isBusinessDriver: boolean, isTtcCorporateAdmin: boolean) => {
    const validCompanyTypes = [];

    if (isTtcCorporateAdmin) {
        validCompanyTypes.push(CompanyTypeEnum.TTC, CompanyTypeEnum.LicenceBureau);
    }

    if (isBusinessDriver) {
        validCompanyTypes.push(CompanyTypeEnum.BusinessDriver);
    }

    return pick(CompanyType, validCompanyTypes);
};

export interface PurchaseOrder {
    id: string;
    organisationId: string;
    number: string;
    startValue: number;
    valueRemaining: number;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    dateCreated?: moment.Moment;
    description?: string;
    history?: HistoryRecord[];
    courses?: EventInstance[];
    eventInstanceUses?: Record<string, number>;
}

export interface PurchaseOrderResponse {
    purchaseOrders: PurchaseOrder[];
    error: string;
}

export class OrganisationBuilder {
    private id: string;
    private name: string;
    private reminderType: ReminderType;
    private dorsId: number;
    private businessLineType: BusinessLineType;
    private corporateBusinessLineType: CorporateBusinessLineType;
    private customerStatus: number;

    constructor() {
        this.name = "Test 1";
        this.reminderType = ReminderType.Both;
    }

    public withId(id: string): OrganisationBuilder {
        this.id = id;
        return this;
    }

    public withName(name: string): OrganisationBuilder {
        this.name = name;
        return this;
    }

    public withReminderType(reminderType: ReminderType): OrganisationBuilder {
        this.reminderType = reminderType;
        return this;
    }

    public withDorsId(dorsId: number): OrganisationBuilder {
        this.dorsId = dorsId;
        return this;
    }

    public withBusinessLineType(businessLineType: BusinessLineType): OrganisationBuilder {
        this.businessLineType = businessLineType;
        return this;
    }

    public withCorporateBusinessLineType(corporateBusinessLineType: CorporateBusinessLineType): OrganisationBuilder {
        this.corporateBusinessLineType = corporateBusinessLineType;
        return this;
    }

    public withCustomerStatus(customerStatus: number): OrganisationBuilder {
        this.customerStatus = customerStatus;
        return this;
    }

    public build(): Organisation {
        return {
            id: this.id,
            name: this.name,
            reminderType: this.reminderType,
            dorsId: this.dorsId,
            businessLineType: this.businessLineType,
            corporateBusinessLineType: this.corporateBusinessLineType,
            customerStatus: this.customerStatus
        };
    }
}

export type CorporateAndConstructionAppState = EventInstanceState & OrganisationState & PendingEventInstanceState & { router: Location };

export type AppState = OrganisationState & { router: Location };
