import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PaymentApi } from "@common/payments/paymentApi";
import { BookingPaymentsInfo } from "@common/payments/model";
import { LanguageEnum } from "@common/crud/eventInstance/model";
import { PrintView } from "@common/print/PrintView";
import { EventType, EventTypeApi } from "@common/crud/eventType";
import { EventInstance } from "@common/crud/eventInstance";
import { Venue } from "@common/crud/venue";
import { isUserClientAdvisor, appSelector } from "@common/crud/common/selectors";
import { DorsBooking, DorsBookingApi } from "@common/crud/dorsBooking";
import { BookingApi } from "../bookingApi";
import { loadEventInstanceDetailsForBooking } from "../../eventInstance/actions";
import { ApplicationState } from "../../applicationState";
import { PrintedBookingConfirmationBody } from "./PrintedBookingConfirmationBody";
import { Apps } from "@common/model";
import { BookingVenueApi } from "@booking/venues/bookingVenueApi";
import { isEmpty } from "lodash";
import { Booking } from "../models";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { Grid } from "semantic-ui-react";
import moment from "moment";
import { CompanyInfoHeader } from "@common/print/CompanyInfoHeader";
import "./PrintableBookingConfirmation.scss";
import { LetterFooter } from "@common/print/LetterFooter";
import { OrganisationApi } from "@common/crud/organisation";
import { BookingOrganisationApi } from "@booking/organisation/bookingOrganisationApi";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface PrintableBookingConfirmationProps {
    bookingId: string;
    showPrintView: boolean;
    setShowPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PrintableBookingConfirmation({ bookingId, showPrintView, setShowPrint }: PrintableBookingConfirmationProps) {
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
    const [booking, setBooking] = React.useState<Booking | DorsBooking>(null);
    const [venue, setVenue] = React.useState<Venue>(null);
    const [eventType, setEventType] = React.useState<EventType>(null);
    const [bookingPaymentsInfo, setBookingPaymentsInfo] = React.useState<BookingPaymentsInfo>(null);
    const [eventInstance, setEventInstance] = React.useState<EventInstance>(null);
    const [eventInstanceGroup, setEventInstanceGroup] = React.useState<EventInstanceGroupModel>(null);
    const [eventInstanceId, setEventInstanceId] = React.useState<string>("");
    const [force, setForce] = React.useState<OrganisationDetailModel>(null);
    const eventInstances: EventInstance[] = useSelector((state: ApplicationState) => state.eventInstances);
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const app = useSelector(appSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const getBookingData = async () => {
            if (!bookingId) {
                return;
            }

            const paymentApi = new PaymentApi(!isClientAdvisor || app === Apps.Admin ? null : bookingId);
            let eiId: string = "";

            let bookingApiResult;
            if (app === Apps.Admin) {
                const dorsBookingApi = new DorsBookingApi();
                bookingApiResult = await dorsBookingApi.detail(bookingId);
                const adminPaymentBalance = await paymentApi.getAdminPaymentsBalance(bookingId);

                eiId = bookingApiResult.nextReservedEventInstanceId;

                setBookingPaymentsInfo(adminPaymentBalance);
                setBooking(bookingApiResult);
            }
            else {
                const bookingApi = new BookingApi(isClientAdvisor ? bookingId : undefined);
                bookingApiResult = await bookingApi.getBooking();
                const bookingPaymentBalance = await paymentApi.getBookingPaymentsBalance();

                eiId = bookingApiResult.reservedEventInstanceId ?? bookingApiResult.originalEventInstanceId;

                setBookingPaymentsInfo(bookingPaymentBalance);
                setBooking(bookingApiResult);
            }

            if (bookingApiResult?.forceId) {
                if (app === Apps.Admin) {
                    const organisationApi = new OrganisationApi();
                    const organisationApiResult: OrganisationDetailModel = await organisationApi.detailFromForceId(bookingApiResult.forceId);
                    setForce(organisationApiResult);
                } else {
                    const bookingOrganisationApi = new BookingOrganisationApi();
                    const bookingOrganisationApiResult: OrganisationDetailModel
                        = await bookingOrganisationApi.getOrganisationByForceId(bookingApiResult.forceId);
                    setForce(bookingOrganisationApiResult);
                }
            }

            if (eiId) {
                setEventInstanceId(eiId);
                dispatch(loadEventInstanceDetailsForBooking({ eventInstanceId: eiId }));
            }
        };

        if (isEmpty(booking)) {
            getBookingData();
        }
    }, [app, booking, bookingId, dispatch, isClientAdvisor, isDataLoaded]);

    React.useEffect(() => {
        const getEventInstance = async () => {
            const eventInstanceToUse = eventInstances.find(c => c.id === eventInstanceId);

            if (!isEmpty(eventInstanceToUse)) {
                const bookingVenueApi = new BookingVenueApi(app, booking.id, !isClientAdvisor);
                const eventTypeApi = new EventTypeApi();

                const bookingVenueData = await bookingVenueApi.detail(eventInstanceToUse.venueId);
                const eventTypeData = await eventTypeApi.detail(eventInstanceToUse.eventTypeId);

                setVenue(bookingVenueData);
                setEventType(eventTypeData);
                setEventInstance(eventInstanceToUse);
                setIsDataLoaded(true);
            }
        };

        if (isEmpty(eventInstance) && !isEmpty(eventInstanceId)) {
            getEventInstance();
        }
    }, [app, booking, dispatch, eventInstance, eventInstanceId, eventInstances, isClientAdvisor]);

    React.useEffect(() => {
        async function getEventInstanceGroup() {
            const eventInstanceGroupApi = new EventInstanceGroupApi();
            const result = await eventInstanceGroupApi.getByEventInstanceId(eventInstance.id);
            setEventInstanceGroup(result);
        }

        if (eventInstance?.workflowType === WorkflowTypeEnum.DDRS) {
            getEventInstanceGroup();
        }
    }, [eventInstance?.id, eventInstance?.workflowType]);

    const confirmationInfo = { booking, bookingPaymentsInfo, eventInstance, eventInstanceGroup, eventType, venue, force };

    React.useEffect(() => {
        if (isDataLoaded && showPrintView) {
            window.print();
            setShowPrint(false);
        }
    }, [isDataLoaded, showPrintView]);

    if (!isDataLoaded) {
        return <></>;
    }

    const address = {
        addressLine1: [booking.buildingName, booking.buildingNumber, booking.address1].filter(x => x).join(" "),
        addressLine2: booking.address2,
        addressLine3: booking.address3,
        city: booking.townCity,
        postalCode: booking.postcode
    };

    const header = () => (<div className="letter-header">
        <CompanyInfoHeader />
    </div>);

    // We need to check if a user is on iOs as the workaround we use for headers and footers will fail.
    const isUsingSafari = /Safari/.test(navigator.userAgent) && (/Chrome/.test(navigator.userAgent) === false);
    const userIsOnIosOrSafari = /iPad|iPhone|iPod/.test(navigator.userAgent) || isUsingSafari;

    return (
        <PrintView>
            <table>
                <thead><tr><td>
                    { userIsOnIosOrSafari ? (header()) : (<div className="table-header-row">&nbsp;</div>) }
                </td></tr></thead>

                <tbody><tr><td>
                    <div className="letter-body">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div>
                                        <div>{`${booking.forename} ${booking.surname}`}</div>
                                        <div>{address.addressLine1}</div>
                                        {address.addressLine2 && <div>{address.addressLine2}</div>}
                                        {address.addressLine3 && <div>{address.addressLine3}</div>}
                                        <div>{address.city}</div>
                                        <div>{address.postalCode}</div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="right">
                                    <span>{moment().format("DD/MMM/YYYY")}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {eventInstance.language === LanguageEnum.Welsh ?
                            <>
                                <PrintedBookingConfirmationBody pageBreakAfter={true} language={LanguageEnum.Welsh} {...confirmationInfo} />
                                <PrintedBookingConfirmationBody language={LanguageEnum.English} {...confirmationInfo} />
                            </> :
                            <PrintedBookingConfirmationBody language={LanguageEnum.English} {...confirmationInfo} />
                        }
                    </div>
                </td></tr></tbody>

                <tfoot><tr><td>
                    { userIsOnIosOrSafari ? (<LetterFooter />) : (<div className="table-footer-row">&nbsp;</div>) }
                </td></tr></tfoot>
            </table>

            { !userIsOnIosOrSafari && header()}

            { !userIsOnIosOrSafari && <LetterFooter />}
        </PrintView>
    );
}
