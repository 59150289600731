import { EventType } from "@common/crud/eventType";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { Trainer } from "@common/crud/trainer";
import * as React from "react";
import { useSelector } from "react-redux";
import { Grid, Message, Radio, RadioProps } from "semantic-ui-react";
import { nonExpiredEventTypes } from "@common/helpers/expiredEventTypes";

interface EventTypeInputProps {
    disabled: boolean;
    selectedEventType: EventType;
    setSelectedEventType: (eventType: EventType) => void;
    selectedTrainer: Trainer;
}

export const EventTypeInput: React.FC<EventTypeInputProps> = ({ disabled, selectedEventType, setSelectedEventType, selectedTrainer }) => {

    const events = useSelector(allEventTypesSelector);

    const canSelectEventType = React.useCallback((eventType: EventType) => {
        const trainerAttributes = selectedTrainer?.trainerAttributes?.map(a => a.attributeDefinitionId);
        return trainerAttributes?.includes(eventType?.digitalEventTypeDetails.digitalTrainerAttributeId);
    }, [selectedTrainer?.trainerAttributes]);

    const isSelected = (eventType: EventType) => {
        return selectedEventType?.id === eventType.id;
    };

    const onChange = React.useCallback((_: any, { value }: RadioProps) => {
        const type = events?.find(e => e.id === value);
        setSelectedEventType(type);
    }, [events, setSelectedEventType]);

    React.useEffect(() => {
        const selectedEvent = events.find(e => e.id === selectedEventType?.id);
        if (selectedEventType === null) {
            return;
        }
        if (canSelectEventType(selectedEvent) === false) {
            setSelectedEventType(null);
        }
    }, [selectedEventType?.id, canSelectEventType, setSelectedEventType, events, selectedEventType]);

    React.useEffect(() =>
    {
        const eventsThatCanBeSelected = events?.filter(e => canSelectEventType(e)) ?? [];
        // eslint-disable-next-line eqeqeq
        if (selectedEventType == null && eventsThatCanBeSelected.length > 0) {
            setSelectedEventType(eventsThatCanBeSelected[0]);
        }
    },
    [canSelectEventType, events, selectedEventType, setSelectedEventType]);

    return (
        <>
            <h3 className="wide-header">Choose a scheme</h3>
            <Grid className="no-margin-top" padded="horizontally">
                <Grid.Row className="no-padding-top">
                    {events.length < 1 &&
                        <Grid.Column width={16}>
                            <Message warning visible>
                                        No schemes were found, make sure all digital planning schemes have been marked as
                                available during digital planning in the scheme's digital settings.
                            </Message>
                        </Grid.Column>
                    }
                    {events.filter(e => nonExpiredEventTypes(e.expiryDate)).map((event, index) => (
                        <Grid.Column width={8} key={index}>
                            <Radio
                                label={event.abbreviation}
                                disabled={disabled || canSelectEventType(event) === false}
                                checked={isSelected(event)}
                                onChange={onChange}
                                value={event.id}
                                className={canSelectEventType(event) ? "bold" : ""}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid></>
    );
};
