import * as React from "react";
import ReactCurrencyInput from "react-currency-input-field";
import classes from "./CurrencyInput.module.scss";
import { ErrorMessage } from "./ErrorMessage";

interface CurrencyInputOwnProps {
    value: string | number;
    onChange: (value: number, valid: boolean) => void;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    showErrors?: boolean;
    fitOnModal?: boolean;
    hideNotRequired?: boolean;
    defaultValue?: string | number;
    maxLimit?: number;
    minLimit?: number;
    nonNegative?: boolean;
    prefix?: "£" | "$" | "€";
    isLoading?: boolean;
}

export const CurrencyInput: React.FC<CurrencyInputOwnProps> = ({
    value: parentValue,
    onChange,
    label,
    fitOnModal,
    maxLimit,
    minLimit,
    placeholder="£0.00",
    showErrors = true,
    defaultValue = null,
    required = false,
    disabled = false,
    nonNegative = true,
    prefix = "£",
    isLoading = false }) => {

    const [errorMessage, setErrorMessage] = React.useState("");
    const [className, setClassName] = React.useState("");
    const [initialised, setInitialised] = React.useState(false);
    const [localShowErrors, setLocalShowErrors] = React.useState(false);
    const [internalValue, setInternalValue] = React.useState(defaultValue);

    const combinedShowErrors = showErrors || localShowErrors;
    const validate = React.useCallback((value: string | number, isOnChange: boolean) => {
        let newErrorMessage = "";
        let newClassName = classes.valid;
        let valid = true;

        if (!isLoading && required && (value === undefined || value === null || value === "")) {
            newErrorMessage = "Field is required";
            newClassName = classes.invalid;
            valid = false;
        }

        if (!isLoading && Number.isNaN(Number(value))) {
            newErrorMessage = "Please enter a valid number";
            newClassName = classes.invalid;
            valid = false;
        }

        if (!isLoading && maxLimit && Number(value) > maxLimit) {
            newErrorMessage = `Max Value: ${prefix}${maxLimit}`;
            newClassName = classes.invalid;
            valid = false;
        }

        if (!isLoading && minLimit && Number(value) < minLimit) {
            newErrorMessage = `Min Value: ${prefix}${minLimit}`;
            newClassName = classes.invalid;
            valid = false;
        }

        if (!isLoading && nonNegative && Number(value) < 0) {
            newErrorMessage = "Value must be greater than or equal to 0";
            newClassName = classes.invalid;
            valid = false;
        }

        if (isOnChange) {
            setLocalShowErrors(true);
        }

        setClassName(newClassName);
        setErrorMessage(newErrorMessage);

        return valid;
    },[isLoading, maxLimit, minLimit, nonNegative, prefix, required]);

    const handleOnValueChange = React.useCallback((value: string | number) => {
        const isValid = validate(value, true);
        setInternalValue(value);
        onChange(+value, isValid);

    },[onChange, validate]);

    const initialiseValidState = React.useCallback((value: string | number) => {
        const newValue = value ?? defaultValue;
        const isValid = validate(newValue, false);
        setInternalValue(newValue);
        onChange(+newValue, isValid);

    },[defaultValue, onChange, validate]);

    // due to new orbit's simple ui forms...
    // we need useEffect hacks in order to show required errors on submit without any user input
    React.useEffect(() => {
        if (!initialised) {
            initialiseValidState(parentValue);
            setInitialised(true);
        }
    },[initialiseValidState, initialised, parentValue]);

    React.useEffect(() => {
        if (!initialised) {
            initialiseValidState(parentValue);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    React.useEffect(() => {
        if (!Number.isNaN(Number(parentValue))) {
            setInternalValue(parentValue);
        }
    }, [parentValue]);

    return (
        <div className={classes.reactCurrency}>
            <div className="field-wrapper">
                <div className="field">
                    {label && <label htmlFor={classes.rcinput} className={classes.label}>{label} ({prefix})</label>}
                    <div className="ui input">
                        <ReactCurrencyInput
                            id={classes.rcinput}
                            name="rc-input"
                            placeholder={placeholder}
                            disabled={disabled}
                            className={`${fitOnModal ? classes.fitOnModal : ""} ${combinedShowErrors? className : ""}`}
                            value={internalValue}
                            decimalsLimit={2}
                            decimalScale={2}
                            disableAbbreviations={true}
                            allowNegativeValue={!nonNegative}
                            onValueChange={handleOnValueChange}
                        />
                    </div>
                    {combinedShowErrors && <ErrorMessage error={errorMessage} />}
                </div>
            </div>
        </div>
    );
};
