import { toast } from "@common/toasts";

function error() {
    toast.error("Copying text to clipboard failed");
}

function success() {
    toast.success("Text was copied to clipboard");
}

function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand("copy");
        if (successful) {
            success();
        } else {
            error();
        }
    } catch (err) {
        error();
    } finally {
        document.body.removeChild(textArea);
    }
}

export async function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }

    try {
        await navigator.clipboard.writeText(text);
        success();
    } catch (err) {
        error();
    }
}
