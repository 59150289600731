import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Grid } from "semantic-ui-react";
import { AsyncDispatch } from "@common/redux-helpers";
import { InternalAppSettings } from "@common/appSettings/model";
import { AppCommonState } from "@common/appCommonState";
import { loadAllPoliceAndCourtOrganisations } from "@common/crud/organisation/actions";
import { attendeeSelector, basePathSelector, routeIdSelector } from "../selectors";
import { AttendeeDetailModel, AppState } from "../model";
import { CoreDetails } from "./details/CoreDetails";
import { EventType, EventTypeState } from "../../eventType";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { GenesysTokenProvider } from "@common/genesys/components/GenesysTokenProvider";
import { RecordCaAction } from "@common/genesys/genesysActions";
import { AdminRecordedActionType } from "@common/genesys/model";
import { loadAttendeeDetail } from "../actions";

export interface CoreDetailProps {
    attendee: AttendeeDetailModel;
    attendeeId?: string;
    eventInstanceId?: string;
    internalAppSettings: InternalAppSettings;
    eventTypes: EventType[];
}

export interface DetailStateProps extends CoreDetailProps {
    path: string;
    basePath: string;
    tab?: string;
}

export interface DispatchProps {
    push: (path: string) => void;
    loadAllPoliceAndCourtOrganisations: () => void;
    loadAttendee: (attendeeId: string, eventInstanceId: string) => void;
    recordCaAction: (genesysToken: string, correlationId: string) => Promise<void>;
}

class DetailInternal extends React.Component<DetailStateProps & DispatchProps, {}> {

    constructor(props: DetailStateProps & DispatchProps) {
        super(props);
    }

    public componentDidMount() {
        this.props.loadAttendee(this.props.attendeeId, this.props.eventInstanceId);
        this.props.loadAllPoliceAndCourtOrganisations();
    }

    private onTokenReceived = (genesysToken: string) => {
        const { recordCaAction, attendee } =  this.props;
        if (attendee.correlationId) {
            recordCaAction(genesysToken, attendee.correlationId);
        }
    };

    public render() {
        const { attendee, attendeeId, eventInstanceId, internalAppSettings, eventTypes } = this.props;

        return (<>
            <Authorize authorize={TtcClientAdvisorRole}>
                <GenesysTokenProvider onTokenReceived={this.onTokenReceived} showLogin={false} />
            </Authorize>
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{attendee.fullName}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CoreDetails
                            attendee={attendee}
                            attendeeId={attendeeId}
                            eventInstanceId={eventInstanceId}
                            internalAppSettings={internalAppSettings}
                            eventTypes={eventTypes}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
        );
    }
}

const currentTab = (state: AppState) => state.router.pathname.substring(
    basePathSelector(state).length + 1 + routeIdSelector(state).toString().length);

function mapStateToProps(state: AppState & AppCommonState & EventTypeState): DetailStateProps {
    const attendee = attendeeSelector(state);

    return {
        attendeeId: state.router.params.attendeeId,
        eventInstanceId: state.router.params.eventInstanceId,
        attendee,
        path: state.router.pathname,
        basePath: basePathSelector(state),
        tab: currentTab(state),
        internalAppSettings: state.appSettings.internalAppSettings,
        eventTypes: state.eventTypes || [],
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path)),
        loadAttendee: (attendeeId: string, eventInstanceId: string) =>
            dispatch(loadAttendeeDetail({ attendeeId, eventInstanceId, addAuditEntry: true })),
        loadAllPoliceAndCourtOrganisations: () => dispatch(loadAllPoliceAndCourtOrganisations()),
        recordCaAction: (genesysToken: string, correlationId: string) =>
            dispatch(RecordCaAction(genesysToken, correlationId, AdminRecordedActionType.attendeeRecordRead))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
