import * as React from "react";
import { EiSideBar } from "../EiSideBar";
import { Authorize } from "reauthorize";
import { EventManagementAdminRolesAndTrainers, EventManagementAdminRoles } from "@common/auth/model";
import { Detail } from "@neworbit/simpleui-detail";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { Grid, Divider, Segment, Container } from "semantic-ui-react";
import { isLocked } from "../../model";
import { EventInstanceNotesModal } from "./EventInstanceNotesModal";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DdrsEventInstanceNotesModal } from "@common/crud/eventInstance/components/details/DdrsEventInstanceNotesModal";
import { isEventInstanceWorkflowPoliceOrCourt } from "@common/global/CommonHelpers";

export const Communications: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);

    const isNotLocked = React.useMemo(() => !isLocked(eventInstance), [eventInstance]);
    const isDdrs = React.useMemo(() => eventInstance.workflowType === WorkflowTypeEnum.DDRS, [eventInstance]);
    const isPoliceOrCourt = React.useMemo(() => isEventInstanceWorkflowPoliceOrCourt(eventInstance), [eventInstance]);

    const notes = React.useMemo(() => ({
        officeNote: eventInstance.officeNote,
        trainerNote: eventInstance.trainerNote,
        adminNote: eventInstance.adminNote,
        creationNote: eventInstance.creationNote,
        bookingNoteEn: eventInstance.bookingNoteEn,
        bookingNoteCy: eventInstance.bookingNoteCy
    }), [eventInstance]);

    const notesModal = React.useMemo(() => isDdrs ?
        <DdrsEventInstanceNotesModal groupId={eventInstance.groupId} /> :
        <EventInstanceNotesModal eventInstance={eventInstance} notes={notes} />
    , [eventInstance, isDdrs, notes]);

    const content = React.useMemo(() => (
        <Container>
            <Grid className="event-instance-details">
                <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                    <h1 className="event-instance-title">Notes</h1>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EventInstanceBanner eventInstance={eventInstance} />
                        </Grid.Column>
                    </Grid.Row>
                    <Authorize authorize={EventManagementAdminRoles}>
                        <Detail label="Office Note" value={eventInstance.officeNote} labelWidth={5} valueWidth={11} />
                    </Authorize>
                    <Detail label="Admin note" value={eventInstance.adminNote} labelWidth={5} valueWidth={11} />
                    {eventInstance.creationNote && (
                        <Detail label="Creation note" value={eventInstance.creationNote} labelWidth={5} valueWidth={11} />)
                    }
                    {isPoliceOrCourt && (
                        <Authorize authorize={EventManagementAdminRoles}>
                            <Detail label="Booking note (EN)" value={eventInstance.bookingNoteEn} labelWidth={5} valueWidth={11} />
                            <Detail label="Booking note (CY)" value={eventInstance.bookingNoteCy} labelWidth={5} valueWidth={11} />
                        </Authorize>
                    )}
                    <Detail label="Trainer note" value={eventInstance.trainerNote} labelWidth={5} valueWidth={11} />
                </Authorize>
            </Grid>
            <Divider />
            <Segment basic clearing vertical>
                <div>
                    {isNotLocked && notesModal}
                </div>
            </Segment>
        </Container>
    ), [eventInstance, isPoliceOrCourt, isNotLocked, notesModal]);

    return (<EiSideBar children={content} />);
};
