/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, PaginationProps } from "semantic-ui-react";
import {
    ordersSearchOptionsSelector,
    ordersSelector,
    eventInstancesWithOrdersSelector,
} from "../selectors";
import { AllOrders } from "./AllOrders";
import { OrderBaseProps } from "./Base";
import { loadOrders, loadEventInstancesWithOrders } from "../actions";
import { OrderMenu } from "./OrderMenu";
import { SearchOptions, ShowOrdersBy } from "../model";
import { AllEventInstances } from "./AllEventInstances";
import { EventInstancesFilters } from "./EventInstancesFilters";
import { StringValues } from "@common/model";
import { Link, push } from "redux-little-router";
import { OrdersFilters } from "./OrdersFilters";
import { loadEventTypeForRouteWorkflowTypes } from "@common/crud/eventType/actions";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { compare } from "@common/helpers/compare";
import { TTCPagination } from "@common/components/TTCPageination";
import moment from "moment";
import { routerPathnameSelector } from "@common/crud/common/selectors";
import { OrderApi } from "../orderApi";
import "./All.scss";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

export const All = (props: OrderBaseProps) => {
    const { corporateOrganisationId, corporateUserId } = props;

    const [searchedSearchOptions, setSearchedSearchOptions] = React.useState<SearchOptions>({});
    const [rejoinAvailable, setRejoinAvailable] = React.useState<string>(null);

    const dispatch = useDispatch();

    const businessLineType = useSelector(businessLineTypeSelector);
    const path = useSelector(routerPathnameSelector);
    const ordersState = useSelector(ordersSelector);
    const eventInstancesWithOrdersState = useSelector(eventInstancesWithOrdersSelector);
    const searchOptions = useSelector(ordersSearchOptionsSelector);
    const hydratedSearchOptions = React.useMemo(() => (
        (corporateOrganisationId || corporateUserId)
            ? {
                ...searchOptions,
                businessLineType,
                organisationId: corporateOrganisationId,
                userId: corporateUserId,
            } : {
                ...searchOptions,
                businessLineType
            }), [businessLineType, corporateOrganisationId, corporateUserId, searchOptions]);

    React.useEffect(() => {
        const checkForExistingBasket = async () => {
            const api = new OrderApi();
            setRejoinAvailable(await api.checkForExistingBasket(businessLineType, corporateOrganisationId, corporateUserId));
        };

        checkForExistingBasket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: businessLineType === BusinessLineType.Corporate ? WorkflowTypeEnum.CPC : undefined }));
    }, [businessLineType, dispatch]);

    const createBasket = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const api = new OrderApi();
        await api.clearOldBaskets(businessLineType);
        dispatch(push(`${path}/create`));
    }, [dispatch, path, businessLineType]);

    const onOptionsChanged = React.useCallback(
        (options: SearchOptions) => {
            const query: StringValues<SearchOptions> = {
                showOrdersBy: options.showOrdersBy?.toString() || "0",
                maxPageSize: "100",
                page: options.page?.toString() || "0",
                organisationId: corporateOrganisationId,
                userId: corporateUserId,
                corporateOrganisationIds: options.corporateOrganisationIds?.join(",") || undefined,
                eventInstanceId: options.eventInstanceId || undefined,
                bookingReference: options.bookingReference || undefined,
                bookerName: options.bookerName || undefined,
                eventTypeIds: options.eventTypeIds?.join(",") || undefined,
                fromDate: options.fromDate?.format("YYYY-MM-DD") || undefined,
                toDate: options.toDate?.format("YYYY-MM-DD") || undefined,
                showCancelled: options.showCancelled ? "true" : "false",
                showCompleted: options.showCompleted ? "true" : "false",
            };
            dispatch(push({ pathname: undefined, query }));
        },
        [corporateOrganisationId, corporateUserId, dispatch]
    );

    const onShowOrdersByChanged = React.useCallback((showOrdersBy: ShowOrdersBy) => onOptionsChanged({
        showOrdersBy,
        maxPageSize: 100,
        page: 0,
        fromDate: showOrdersBy === ShowOrdersBy.EventInstance ? moment.utc() : undefined }
    ), [onOptionsChanged]);

    React.useEffect(() => {
        if (!compare(hydratedSearchOptions, searchedSearchOptions)) {
            dispatch(
                hydratedSearchOptions.showOrdersBy === ShowOrdersBy.Order
                    ? loadOrders({ options: hydratedSearchOptions })
                    : loadEventInstancesWithOrders({ options: hydratedSearchOptions })
            );
            setSearchedSearchOptions(hydratedSearchOptions);
        }
    }, [dispatch, hydratedSearchOptions, searchedSearchOptions]);

    const totalElements = React.useMemo(() => hydratedSearchOptions.showOrdersBy === ShowOrdersBy.Order
        ? ordersState.total
        : eventInstancesWithOrdersState.total, [eventInstancesWithOrdersState.total, ordersState.total, hydratedSearchOptions.showOrdersBy]);

    const totalPages = React.useMemo(() => {
        return totalElements
            ? Math.ceil(totalElements / searchedSearchOptions?.maxPageSize)
            : 1;
    }, [searchedSearchOptions?.maxPageSize, totalElements]);

    const onPageChange = React.useCallback((_: any, { activePage }: PaginationProps) => {
        onOptionsChanged({ ...searchedSearchOptions, page: +activePage - 1 });
    }, [onOptionsChanged, searchedSearchOptions]);

    const filters = React.useMemo(
        () =>
            hydratedSearchOptions.showOrdersBy === ShowOrdersBy.Order ? (
                <OrdersFilters
                    corporateOrganisationId={corporateOrganisationId}
                    corporateUserId={corporateUserId}
                    onOptionsChanged={onOptionsChanged}
                    searchOptions={hydratedSearchOptions}
                />
            ) : (
                <EventInstancesFilters
                    corporateOrganisationId={corporateOrganisationId}
                    onOptionsChanged={onOptionsChanged}
                    searchOptions={hydratedSearchOptions}
                />
            ),
        [onOptionsChanged, hydratedSearchOptions, corporateOrganisationId, corporateUserId]
    );

    const allItems = React.useMemo(
        () =>
            hydratedSearchOptions.showOrdersBy === ShowOrdersBy.Order ? (
                <AllOrders
                    corporateOrganisationId={corporateOrganisationId}
                    corporateUserId={corporateUserId}
                    orders={ordersState.orders}
                />
            ) : (
                <AllEventInstances
                    corporateOrganisationId={corporateOrganisationId}
                    corporateUserId={corporateUserId}
                    eventInstances={eventInstancesWithOrdersState.eventInstancesWithOrders}
                />
            ),
        [corporateOrganisationId, corporateUserId, eventInstancesWithOrdersState, ordersState, hydratedSearchOptions.showOrdersBy]
    );

    return (
        <>
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <div className="title-general">
                        <h1>Orders</h1>
                        <div className={`title-buttons ${!!rejoinAvailable && corporateUserId
                            ? "title-buttons-big"
                            : !!rejoinAvailable || corporateUserId
                                ? "title-buttons-medium"
                                : "title-buttons-small"}`}>
                            {corporateUserId &&
                                <Button icon="calendar outline" content="Rebook Seats" as={Link} href={`${path}/rebook`} />}
                            {!!rejoinAvailable && <Button icon="linkify" content="Rejoin Basket" as={Link} href={`${path}/${rejoinAvailable}`} />}
                            <Button icon="add" content="Create Basket" onClick={createBasket} as={Link} href={`${path}/create`} />
                        </div>
                    </div>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <OrderMenu
                            by={hydratedSearchOptions.showOrdersBy}
                            changeShowBy={onShowOrdersByChanged}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filters}
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16}>{allItems}</Grid.Column>
                </Grid.Row>
            </Grid>
            {totalPages > 1 && (
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <TTCPagination
                                activePage={hydratedSearchOptions?.page + 1 || 1}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    );
};
