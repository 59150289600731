import moment from "moment";
import { EventInstance, reducer as commonReducer } from "@common/crud/eventInstance";
import * as actions from "./actionTypes";
import { EventInstanceAction } from "./actions";

export function eventInstances(state: EventInstance[] = [], action: EventInstanceAction): EventInstance[] {
    switch (action.type) {
        case actions.LOAD_EVENT_INSTANCES_SUCCESS:
            return action.payload.map(c => ({ ...c }));
        case actions.LOAD_EVENTINSTANCE_DETAILS_FOR_BOOKING_SUCCESS:
            return state.map(c => eventInstance(c, action));
        default:
            return commonReducer(state, action);
    }
}

function eventInstance(state: EventInstance, action: EventInstanceAction): EventInstance {
    switch (action.type) {
        case actions.LOAD_EVENTINSTANCE_DETAILS_FOR_BOOKING_SUCCESS:
            if (action.payload.id === state.id) {
                return detailUpdated(action.payload, state);
            }
            return state;
        default:
            return state;
    }
}

function detailUpdated(value: EventInstance, state: EventInstance = {} as any): EventInstance {
    return { listUpdated: moment(), ...state, ...value, detailUpdated: moment() };
}
