import * as React from "react";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { AlcoholReading, DrCode, DrinkDriveOffenderDetails, HightRisk } from "@common/crud/drinkDriveOffenders/model";
import { Button, Container } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Link } from "redux-little-router";
import { SelectorState } from "@common/crud/drinkDriveOffenders/selectors";
import { useSelector } from "react-redux";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";

export interface OwnProps {
    drinkDriveOffender: DrinkDriveOffenderDetails;
    expiryDate: moment.Moment;
    externalAttendeeId: number;
}

export const DrinkDriveOffenderCaseDetails: React.FC<OwnProps> = ({ drinkDriveOffender, expiryDate, externalAttendeeId }) => {
    const path = useSelector((state: SelectorState) => state.router.pathname);
    const [showAllDetails,setShowAllDetails]=React.useState<boolean>(false);

    function  toggleDetails() {
        return setShowAllDetails(!showAllDetails);
    }
    return (
        <Container className="white-panel ddrs-offender">
            <h5>Case details</h5>
            <LabelAndValue label="Attendance Id" value={externalAttendeeId} />
            <LabelAndValue label="Referred Provider" value={drinkDriveOffender?.referredProvider || "N/A"} />
            <LabelAndValue label="Case Number" value={drinkDriveOffender?.caseNumber || "N/A"} />
            <LabelAndValue label="Court" value={drinkDriveOffender?.courtName} />
            <LabelAndValue
                label="Sentence Date"
                value={drinkDriveOffender?.sentenceDate &&
                    drinkDriveOffender?.sentenceDate.format(DateFormat.Short) || "N/A"} />
            <LabelAndValue label="Disqualified Period" value={drinkDriveOffender?.disqualifiedPeriod || "N/A"} />
            <LabelAndValue label="Interim Date" value={drinkDriveOffender?.interimDate &&
                drinkDriveOffender.interimDate.format(DateFormat.Short) || "N/A"} />
            <LabelAndValue label="Reduction" value={drinkDriveOffender?.reduction || "N/A"} />
            <LabelAndValue
                label="Completion Date"
                value={expiryDate && expiryDate.format(DateFormat.Short) || "N/A"} />

            <a className="show-button" onClick={toggleDetails}>{showAllDetails ?
                "Hide other case details" :"Show other case details" }</a>
            {showAllDetails &&<>
                <LabelAndValue label="Previous Convictions" value={drinkDriveOffender?.previousConvictions || "N/A"} />
                <LabelAndValue label="Supervising Court" value={drinkDriveOffender?.supervisingCourtName || "N/A"} />
                <LabelAndValue
                    label="Classic Certificate Number"
                    value={drinkDriveOffender?.classicCertificateNumber || "N/A"} />
                <LabelAndValue
                    label="DR Code"
                    value={drinkDriveOffender?.drCode ? DrCode[drinkDriveOffender?.drCode] : "N/A"} />
                <LabelAndValue
                    label="Alcohol Reading Type"
                    value={drinkDriveOffender?.alcoholReadingType ? AlcoholReading[drinkDriveOffender?.alcoholReadingType] : "N/A"} />
                <LabelAndValue
                    label="Alcohol Reading"
                    value={drinkDriveOffender?.alcoholReading ?? "N/A"} />
                <LabelAndValue
                    label="High Risk"
                    value={drinkDriveOffender?.highRisk ? HightRisk[drinkDriveOffender?.highRisk] : "N/A"} />
            </>}
            <Authorize authorize={TtcClientAdvisorRole}>
                <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${path}/edit`} />
            </Authorize>
        </Container>

    );
};
