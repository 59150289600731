import { TrainerAttributeDefinition, TrainerAttributeType } from "@common/crud/trainerAttributeDefinition";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import * as React from "react";
import { useSelector } from "react-redux";
import { Tab, Form, DropdownProps } from "semantic-ui-react";
import { EventTypeCreateEditModel, ModuleTypeEnum, WorkflowTypeEnum } from "../../model";
import { EditableTrainerPayTable } from "../TrainerPayTable/EditableTrainerPayTable";
import { FeeWithEffectiveDateList } from "./FeeWithEffectiveDateList";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { EditableMonitorPayTable } from "../MonitorObserverPayTable/EditableMonitorPayTable";
import {
    EditableOtherTrainerPayTable
} from "@common/crud/eventType/components/OtherTrainerPayTable/EditableOtherTrainerPayTable";
import { ExtendedDropdown } from "@common/components/ExtendedDropdown";
import { isWorkflowConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";

export interface Props {
    showErrors: boolean;
    values: EventTypeCreateEditModel;
    onChange(propName: keyof EventTypeCreateEditModel): (value: any, valid: boolean) => void;
    onDropdownPropertyChange(propName: keyof EventTypeCreateEditModel): (event: any, { value }: DropdownProps) => void;
    onDetailsChange(propName: string): (value: any, valid: boolean) => void;
    push: (path: string) => void;
    updateDisableAutoAllocateIfTrainerNonCompliant: (value: string) => void;
}

export const TrainerFeesEditTab: React.FC<Props> = ({ values, onChange, onDropdownPropertyChange, onDetailsChange, showErrors, push,
    updateDisableAutoAllocateIfTrainerNonCompliant }) => {
    const isCorporate = isWorkflowCorporate(values.workflowType);
    const isConstruction = isWorkflowConstruction(values.workflowType);
    const isWorkflowWithMonitoring = !isCorporate && !isConstruction;
    const trainerAttributes = useSelector(trainerAttributeDefinitionsSelector)?.map((
        attr: TrainerAttributeDefinition) => ({ value: attr.id, text: attr.name }));
    const complianceAttributes = useSelector(trainerAttributeDefinitionsSelector)?.filter((
        attr: TrainerAttributeDefinition) => attr.attributeType === TrainerAttributeType.Continuum)
        .map((attr: TrainerAttributeDefinition) => ({ value: attr.id, text: attr.name }));

    return (
        <Tab.Pane key="trainer-fees-details">
            <h3>Attribute</h3>
            {isWorkflowWithMonitoring &&
            <ExtendedDropdown
                value={values.monitorAttributeId}
                label="Monitor Attribute"
                showErrors={showErrors}
                dynamicOptions
                options={trainerAttributes}
                onChange={onChange("monitorAttributeId")}
                search
            />}
            {values.moduleType !== ModuleTypeEnum.Practical && (values.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                <ExtendedDropdown
                    value={values.theoryTrainerAttributeId}
                    label="Theory Trainer attribute"
                    showErrors={showErrors}
                    dynamicOptions
                    options={trainerAttributes}
                    required
                    onChange={onChange("theoryTrainerAttributeId")}
                    search
                />}
            {values.moduleType !== ModuleTypeEnum.Practical && (values.deliveryType === SchemeDeliveryTypeEnum.Digital ||
             values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)  &&
                <ExtendedDropdown
                    value={values?.digitalEventTypeDetails?.digitalTrainerAttributeId}
                    label="Digital Trainer attribute"
                    showErrors={showErrors}
                    dynamicOptions
                    options={trainerAttributes}
                    required
                    onChange={onDetailsChange("digitalEventTypeDetails.digitalTrainerAttributeId")}
                    search
                />
            }
            {!isCorporate && !isConstruction && (
                <ExtendedDropdown
                    value={values?.welshTrainerAttributeId}
                    label="Welsh Trainer attribute"
                    showErrors={showErrors}
                    dynamicOptions
                    options={trainerAttributes}
                    required
                    onChange={onDetailsChange("welshTrainerAttributeId")}
                    search
                />
            )}
            {(values.moduleType === ModuleTypeEnum.Theory ||
                values.moduleType === ModuleTypeEnum.Both) &&
                <Form.Dropdown
                    floating
                    selection
                    multiple
                    value={values.theoryComplianceAttributeIds}
                    label="Theory Compliance attributes"
                    showErrors={showErrors}
                    dynamicOptions
                    options={complianceAttributes}
                    onChange={onDropdownPropertyChange("theoryComplianceAttributeIds")}
                    search
                />}
            {(values.moduleType === ModuleTypeEnum.Practical ||
                values.moduleType === ModuleTypeEnum.Both) &&
                <Form.Dropdown
                    floating
                    selection
                    multiple
                    value={values.practicalComplianceAttributeIds}
                    label="Practical Compliance attributes"
                    showErrors={showErrors}
                    dynamicOptions
                    options={complianceAttributes}
                    onChange={onDropdownPropertyChange("practicalComplianceAttributeIds")}
                    search
                />}
            {!isCorporate && !isConstruction &&
            <ExtendedDropdown
                value={values.disableAutoAllocateIfTrainerNonCompliant?.toString() ?? "false"}
                label="Disable auto allocate if trainer non compliant"
                showErrors={showErrors}
                dynamicOptions
                options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                onChange={updateDisableAutoAllocateIfTrainerNonCompliant}
                search
            />}
            {(values.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
            <>
                <h3>Classroom Fees</h3>
                <EditableTrainerPayTable currentTrainerFees={values?.classroomEventTypeDetails?.trainerFees ?? []}
                    onChange={onDetailsChange("classroomEventTypeDetails.trainerFees")} push={push} />
            </>}

            {(values.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&<>
                <h3>Digital Fees</h3>
                <EditableTrainerPayTable currentTrainerFees={values?.digitalEventTypeDetails?.digitalTrainerFees ?? []}
                    onChange={onDetailsChange("digitalEventTypeDetails.digitalTrainerFees")} push={push} /></>}

            {(values.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&<>
                <h3>Other Trainer Fees - Digital</h3>
                <EditableOtherTrainerPayTable currentOtherTrainerFees={values?.digitalEventTypeDetails.digitalOtherTrainerFees ?? []}
                    onChange={onDetailsChange("digitalEventTypeDetails.digitalOtherTrainerFees")} push={push} /></>}

            {(values.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                values.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&<>
                <h3>Other Trainer Fees - Classroom</h3>
                <EditableOtherTrainerPayTable currentOtherTrainerFees={values?.classroomEventTypeDetails.classroomOtherTrainerFees ?? []}
                    onChange={onDetailsChange("classroomEventTypeDetails.classroomOtherTrainerFees")} push={push} /></>}

            {(values.moduleType === ModuleTypeEnum.Practical ||
                values.moduleType === ModuleTypeEnum.Both) && <><h3>Practical Fees</h3>
                <EditableTrainerPayTable currentTrainerFees={values?.classroomEventTypeDetails?.practicalTrainerFees ?? []}
                    onChange={onDetailsChange("classroomEventTypeDetails.practicalTrainerFees")} push={push} /></>}

            {values.workflowType !== WorkflowTypeEnum.DDRS &&
                <>
                    <h3>Uplift</h3>
                    <FeeWithEffectiveDateList
                        fees={values?.trainerUpliftFees}
                        feesProperty={"trainerUpliftFees"}
                        feeLabel="Trainer Uplift"
                        onChange={onChange}
                    />
                </>
            }
            {!isCorporate && !isConstruction &&
                <>
                    <h3>Monitor</h3>
                    <EditableMonitorPayTable currentTrainerFees={values?.monitorFees} onChange={onChange("monitorFees")} push={push} />
                </>
            }
        </Tab.Pane>
    );
};
