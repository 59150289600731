import axios from "axios";
import {
    IqaTeamFeedbackCreateEditModel,
    TrainerFeedbackCreateEditModel,
    TrainerMonitoringReportCreateEditModel,
    TrainerMonitoringReportDetailModel
} from "@common/crud/trainer/trainerMonitoringModel";
import moment from "moment";

export class TrainerMonitoringApi {
    private readonly apiUrl = "/api/trainer-monitoring";

    public async getTrainerMonitoringForm(trainerId: string, monitorId: string, relatedEventInstanceId: string): Promise<TrainerMonitoringReportDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${trainerId}/${monitorId}/${relatedEventInstanceId}`);
        if (response.data) {
            return this.parseModel(response.data);
        }
        return null;
    }

    public async getTrainerMonitoringFormWithNoMonitor(trainerId: string, relatedEventInstanceId: string): Promise<TrainerMonitoringReportDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${trainerId}/${relatedEventInstanceId}`);
        if (response.data) {
            return this.parseModel(response.data);
        }
        return null;
    }

    public async saveTrainerMonitoringFormProgress(model: TrainerMonitoringReportCreateEditModel): Promise<TrainerMonitoringReportCreateEditModel> {
        const response = await axios.post(`${this.apiUrl}/save-monitoring-report/`, model);
        return this.parseModel(response.data);
    }

    public async saveTrainerFeedback(model: TrainerFeedbackCreateEditModel): Promise<TrainerMonitoringReportCreateEditModel> {
        const response = await axios.post(`${this.apiUrl}/save-monitoring-trainer-feedback/`, model);
        if (response.data) {
            return this.parseModel(response.data);
        }
        return null;
    }

    public async saveIqaTeamFeedback(model: IqaTeamFeedbackCreateEditModel): Promise<TrainerMonitoringReportCreateEditModel> {
        const response = await axios.post(`${this.apiUrl}/save-monitoring-trainer-iqa-feedback/`, model);
        if (response.data) {
            return this.parseModel(response.data);
        }
        return null;
    }

    public parseModel(model: TrainerMonitoringReportDetailModel): TrainerMonitoringReportDetailModel {
        return {
            ...model,
            completionDate: model.completionDate && moment(model.completionDate)
        };
    }
}
