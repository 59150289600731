import * as React from "react";
import { Grid, Label, Menu } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { GetNumberOfNotes } from "../helpers";
import { appSelector } from "@common/crud/common/selectors";
import { useSelector } from "react-redux";
import { PendingEventInstance } from "../model";

export interface ContextItemsProps {
    basePath: string;
    pendingEventInstance: PendingEventInstance;
}

export const ContextItems: React.FC<ContextItemsProps> = ({ basePath, pendingEventInstance }) => {
    const app = useSelector(appSelector);
    const numberOfNotes = React.useMemo(() => GetNumberOfNotes(pendingEventInstance, app),[pendingEventInstance, app]);

    return (
        <>
            <Menu.Item name="Details" as={Link} href={`${basePath}/${pendingEventInstance.id}`}>Details</Menu.Item>
            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <Menu.Item name="Communications" as={Link} href={`${basePath}/${pendingEventInstance.id}/communications`}>
                    <Grid>
                        <Grid.Column width={10} verticalAlign="middle">Notes</Grid.Column>
                        <Grid.Column width={4}>
                            {numberOfNotes > 0 && <Label circular content={numberOfNotes} />}
                        </Grid.Column>
                    </Grid>
                </Menu.Item>
            </Authorize>
            <Menu.Item name="Files" as={Link} href={`${basePath}/${pendingEventInstance.id}/files`}>Files</Menu.Item>
            <Menu.Item name="History" as={Link} href={`${basePath}/${pendingEventInstance.id}/history`}>History</Menu.Item>
        </>
    );
};
