import * as React from "react";
import { OtherTrainerPayEditModal } from "./OtherTrainerPayEditModal";
import { OtherTrainerPayTable } from "./OtherTrainerPayTable";
import { OtherTrainerFee } from "@common/crud/eventType/model";

interface EditableOtherTrainerPayTableProps {
currentOtherTrainerFees: OtherTrainerFee[];
onChange: (value: any, valid: boolean) => void;
push: (path: string) => void;
}

export const EditableOtherTrainerPayTable: React.FC<EditableOtherTrainerPayTableProps> = ({ currentOtherTrainerFees, onChange, push }) => {
    return (
        <>
            <OtherTrainerPayTable otherTrainerFees={currentOtherTrainerFees} />
            <OtherTrainerPayEditModal currentOtherTrainerFees={currentOtherTrainerFees} onChange={onChange} push={push} />
        </>
    );
};
