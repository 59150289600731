/* eslint-disable max-lines */
/* eslint-disable max-len */
import { LanguageEnum } from "@common/crud/eventInstance/model";

// Please note: any fields included in a translation using t("KEY") should be preceeded with a dash. e.g. {{- booking.forename}}
// this prevents i18n from escaping the string; react will escape the string.
// DO NOT USE THE SAME APPROACH ON ANY TRANSLATION BEING PASSED TO TRANS

const Languages = {
    [0]: "Any",
    [LanguageEnum.English]: "English",
    [LanguageEnum.Welsh]: "Welsh",
};

export type TranslationModel = typeof en;
export const en = {
    Header: {
        LOGIN: "Login",
        LOGOUT: "Logout",
        HOME: "Home",
        LANGUAGE: "Cymraeg",
        LANGUAGE_CODE: "cy",
    },
    BookingStages: {
        STAGE_1: "Select your course",
        STAGE_2: "Confirm your details",
        STAGE_2_COMPLETE: "Confirm your details and complete booking",
        STAGE_3: "Payment",
    },
    AllVenues: {
        SEARCH: "Search",
        SEARCH_FOR_ANOTHER_VENUE: "Search for another venue",
        SELECT_VENUE: "Select venue",
        ADVANCED_SEARCH: "Advanced search",
        VENUE_INTRO: "Alternatively search with another postcode or filters",
        THERE_ARE_NO_EVENT_INSTANCES: "There are no courses matching the criteria",
        MILES: "miles",
        APPLY: "Apply",
        HAS_WELSH_LANGUAGE_COURSES: "Courses in the Welsh language available"
    },
    App: {
        PERSONAL_DETAILS: "Personal details",
    },
    BookingDetails: {
        MY_DETAILS: "My Details",
        ATTENDEE_DETAILS: "Attendee details",
        NAME: "Name",
        ADDRESS: "Address",
        TELEPHONE: "Telephone",
        EMAIL: "Email address",
        LICENCE_NUMBER: "Licence number",
        YOUR_CONTACT_DETAILS: "Your contact details",
        WELCOME: "Please confirm your details below before proceeding to payment",
        EDIT_INSTRUCTIONS: "To edit your details please visit the <1>NDORS Portal</1>",
        REQUIRED_FIELD: "This field is required",
        PHONE_NUMBER_REQUIRED: "Please enter a valid phone number",
        ACTION_REQUIRED: "Action required",
        YOU_MUST_TICK_THE_BOX: "You must tick the box to confirm you have read and agreed to our terms and conditions.",
        TELEPHONE_NOTE: "We will only use your number to contact you about important updates on your course.",
        EMAIL_REQUIRED: "Please enter a valid email address",
        EMAIL_NOTE: "We will only use your email address to send you email updates on your course",
        CONTINUE: "Continue",
        CLOSE: "Close",
        GO_TO_PAYMENT: "Go to payment",
        COMPLETE_BOOKING: "Complete booking",
        MANUAL_PAYMENT: "Manual payment",
        BOOKING_REFERENCE: "Booking Reference",
        CAR_TYPE: "Car Type",
        CAR_TYPE_REQUIRED: "Please select the car type that you require",
        CAR_TYPE_NOTE: "This course has a driving section, what type of car do you need?",
        SPECIAL_CAR_DETAILS_REQUIRED: "Please provide details of the special adaptations on the car",
        SPECIAL_CAR_DETAILS_MAX_LENGTH: "This must not exceed 150 characters",
        SPECIAL_CAR_DETAILS: "Special adaptations details",
        SPECIAL_CAR_DETAILS_NOTE: "Please add a brief description to explain the special adaptations of your car",
        CORRECT_CONTACT_DETAILS: "Please ensure we have the correct contact details below",
        PLEASE_COMPLETE_ALL_SECTIONS: "Please complete all sections of this page before proceeding",
        LANGUAGE_DEPENDENT_FIELD: "{{- english}}"
    },
    BookingConfirmation: {
        BOOKING_CONFIRMED: "Your booking is confirmed",
        BOOKING_REFERENCE: "TTC Booking Reference",
        YOUR_PERSONAL_DETAILS: "Your personal details",
        WELCOME: "Below are the details of your <1>{{- eventTypeName}}</1> course booking.",
        CHANGE_BOOKING: "Thank you for changing your course.",
        NEW_BOOKING_REFERENCE: "Your new booking is confirmed -",
        PRINT: "Print my booking details",
        CONFIRMATION: "Confirmation",
        ORDER_SUMMARY: "Order Summary",
        YOUR_COURSE: "Your course",
        RECOMMEND_ASSISTANCE: "<strong>You have booked a Digital course.  This will be delivered using Zoom.</strong><br /><br />We recommend clicking on the link in order to complete a practice of joining your Digital Driver Awareness course with TTC.",
        ZOOM_ASSISTANCE: "If you experience difficulties when completing your test Zoom meeting then please email us at <1>onlinecoursesupport@ttc-uk.com</1>. Please include your <strong>TTC booking reference</strong>, <strong>name</strong> and <strong>contact number</strong> in order for our online support team to get back in touch.",
        LANGUAGE_DEPENDENT_FIELD: "{{- english}}",
        TEST_MEETING: "Start test meeting",
        COURSE_DETAILS_TITLE: "Course details",
    },
    FinanceDetails: {
        AMOUNT_PAID: "Amount paid:",
        AMOUNT_DUE: "Amount due back to attendee:",
        OUTSTANDING_BALANCE: "Outstanding balance:",
        SCHEDULED_PAYMENT: "Amount of scheduled payment set up:",
        FLEXI_PAY_CONFIRMATION: "Flexi-booking fee:",
        PAYMENT_DETAILS_TITLE: "Payment details",
    },
    BookingAssistance: {
        RECOMMEND_ASSISTANCE: "<strong>You have booked a Digital course.  This will be delivered using Zoom.</strong><br />We recommend clicking on the following link in order to complete a practice of joining your Digital Driver Awareness course with TTC.",
        CHOOSE_ASSISTANCE: "If you experience difficulties when completing your test Zoom meeting then please email us at onlinecoursesupport@ttc-uk.com. Please include your reference number, name and contact number in order for our online support team to get back in touch.",
        TEST_MEETING: "Start practice meeting"
    },
    EventInstanceMobile: {
        TO: "to",
        END_TIME: "to {{- endTime}}",
        COURSE_LANGUAGE: "Language:",
        MILES: "miles",
        DAY_NUMBER: "Day ",
        AUTO_ONLY: "This course is suitable for automatic drivers only",
        MANUAL_ONLY: "This course is suitable for manual drivers only",
        MANUAL_AND_AUTO: "This course is suitable for manual and automatic drivers",
        Languages
    },
    AttendeeHeader: {
        WELCOME: "Welcome {{- booking.forename}}, you have been referred for {{- eventTypeName}} with a deadline date of {{- completionDate}}",
        INTRODUCTION_LINE1: "Below is a selection of courses we recommend based on your completion date.",
        INTRODUCTION_LINE2: "If these are not what you are looking for, you can use the advanced search to find the ideal course for you.",
    },
    ClientAdvisorHeader: {
        WELCOME: " - Referred for {{- eventTypeName}} with a deadline date of {{- completionDate}}",
        INTRODUCTION_LINE1: "Below is a selection of courses based on the client’s completion date.",
        INTRODUCTION_LINE2: "The advanced search can be used to refine the course results shown below.",
    },
    Landing: {
        ATTENDEE_LANDING_PAGE: "Welcome to Alaska",
        SELECT_DATE_TIME_AT_YOUR_CHOSEN_VENUE: "Please select a date and time at your chosen venue.",
        SEARCH: "Search for another venue",
        BOOKING_NOT_FOUND: "Sorry we could not find any details to match your request",
        CLASSROOM_COURSES: "Classroom",
        FILTER_BY_TRAINING_CENTRE: "Filter by training centre",
        FILTER_BY_VENUE: "Filter by venue",
        FILTER_BY_DISTANCE: "Filter by preferred travel distance (miles)",
        FILTER_BY_DISTANCE_PLACEHOLDER: "Filter by distance",
        DIGITAL_COURSES: "Digital",
        DIGITAL_COURSES_ONLY: "Digital Courses",
        DIGITAL_COURSES_ONLY_EXPLANATION: "Below is a list of courses that will be delivered online using Zoom",
        CLASSROOM_COURSES_ONLY: "Classroom Courses",
        CLASSROOM_COURSES_ONLY_EXPLANATION: "Below is a list of courses delivered at training locations",
        CLASSROOM_COURSES_EXPLANATION: "Click here to view courses delivered at training locations",
        DIGITAL_COURSES_EXPLANATION: "Click here to view courses that will be delivered online using Zoom",
        ANY: "Any",
        MILES_AWAY: "({{- distanceInMiles}} miles away)",
        THERE_ARE_NO_DIGITAL_COURSES: "Sorry there are no available courses based on your search criteria",
        THERE_ARE_NO_CLASSROOM_COURSES: "Due to the current situation with Covid-19, all classroom courses are cancelled. Please click on the digital course list on the left-hand side of this screen to find a course suitable for you.",
        SELECTED_COURSE: "Current course selection",
        BOOK_THIS_COURSE: "Continue to book this course",
        PENDING_BOOKING_INFORMATION: "You are reserving <1>{{- venueName}}</1> on <3>{{- date }}</3> at <5>{{- startTime }}</5>, click button to continue  with this selection or select a different course from the list below",
        WELCOME_TO_PORTAL: "Welcome to TTC's Online Booking Portal",
        UNABLE_TO_BOOK_ON_COURSE: "We are unable to book you on to a course. We apologise for any inconvenience caused",
        PLEASE_CALL_FOR_ASSISTANCE: "Please call 0330 024 1805 where one of our Advisors will be happy to help.",
        SESSION_EXPIRED_HEADING: "Sorry your booking session has expired",
        SESSION_EXPIRED_BODY: "To restart your booking with TTC please click the below button to transfer back to the NDORS Portal. Please note you will need to sign in to the NDORS Portal again before selecting TTC as your preferred provider.",
        SESSION_EXPIRED_BUTTON_TEXT: "NDORS Portal"
    },
    IntroMessage: {
        MODAL_HEADER: "Welcome to TTC's Booking Portal",
        CONTINUE: "Continue",
    },
    Payment: {
        FAIL_AND_RETRY: "Sorry, there was a problem processing your payment. Please try again.",
        SUCCEEDED: "Your payment has been processed.",
        TAKE_ME_TO_PAYMENT_VIEW: "Take me to Payment View",
    },
    PaymentReview: {
        PAY: "Pay Now",
        ENTER_CARD_DETAILS: "Please enter your card details below",
        CARD_NUMBER: "Card Number",
        EXPIRY_DATE: "Expiry Date",
        SECURITY_CODE: "Security Code",
        PAYMENT_FAILED: "Your payment has failed",
        PAYMENT_DETAILS: "Your payment details",
        NO_AVAILABILITY: "Sorry all places on that course have now been booked, please select another date.",
        RESERVATION_HAS_EXPIRED: "Your course reservation has expired, please use the back button and reselect the course.",
        PAYMENT_ALREADY_RECEIVED: "Your payment has already been received",
        SERVER_ERROR: "An error has occured during payment. Please call us."
    },
    TAndCsConfirmationComponent: {
        CONFIRM: "Please confirm you have read our <1>Terms & Conditions</1>.",
    },
    SpecialRequirementsCheckboxes: {
        HEARING_LOOP: "I require a hearing loop",
        BSL: "I require a British Sign Language (BSL) interpreter",
        BREASTFEEDING: "I require breastfeeding facilities",
        PRAYER_ROOM: "I require a prayer room",
        DISABLED_ACCESS: "I require disabled access",
        SPEECH_TO_TEXT: "I require speech to text reporter",
        OWN_TRANSLATOR: "I speak a different language and will bring my own translator",
        OWN_INTERPRETER: "You will need to bring your own interpreter, TTC will not provide this. Please enter the name of the interpreter below.",
        NEED_TO_BREASTFEED: "I will need to breastfeed during the course",
        NEED_PRAYER: "I will need to pray during the course",
        REQUIRE_SUBTITLES: "I need subtitles",
        OUTSIDE_UK: "I will be joining the course from outside of the UK.",
        OTHER_COUNTRY: "Please advise which country you will be joining the course from.",
        OTHER_DISABILITY: "Please confirm details of any other disabilities below",
        HEARING_IMPAIRMENT: "I have a hearing impairment",
        HEARING_REQUIREMENTS: "Please choose the options that best suit your requirement",
        OTHER_HEARING_REQUIREMENTS: "Please confirm details of any other hearing requirements below"
    },
    BookingSpecialRequirementsForm: {
        YOUR_REQUIREMENTS: "Your requirements",
        ANY_SPECIAL_REQUIREMENTS_QUESTION: "Do you have any special requirements when attending your course?",
        SELECT_SOME_REQUIREMENTS: "Please select some special requirements",
        SELECT_HEARING_REQUIREMENTS: "Please select some hearing impairment requirements",
        OTHER_REQUIREMENTS_LENGTH_EXCEEDED: "Other requirements must be 200 characters or less",
        CONFIRM_INTERPRETER_NAME: "Please confirm the name of the interpreter",
        CONFIRM_OTHER_COUNTRY: "Please confirm the name of the country",
        CURRENT_SPECIAL_REQUIREMENTS: "The following special requirements have previously been selected. If these are no longer required please deselect, or alternatively please select any that are now required",
        YES: "Yes",
        NO: "No",
        EI_SCHEDULED_WITHIN_72_HOURS: "Unfortunately, due to the course date you have selected, we will not have sufficient time to review your special requirements. Please go back to the previous page and select a later course, or alternatively continue to payment if you would like to still book your chosen course."
    },
    Filters: {
        SEARCH: "Search",
        SEARCH_FOR_ANOTHER_VENUE: "Search for another venue",
        SELECT_VENUE: "Select venue",
        ADVANCED_SEARCH: "Advanced search",
        VENUE_INTRO: "Alternatively search with another postcode or filters",
        POSTCODE: "Postcode",
        POSTCODE_VALIDATION_ERROR: "Please enter a valid UK postcode/outward code",
        THERE_ARE_NO_EVENT_INSTANCES: "There are no courses matching the criteria",
        MILES: "miles",
        APPLY: "Apply",
        HAS_WELSH_LANGUAGE_COURSES: "Courses in the Welsh language available",
        FROM_DATE: "From date",
        TO_DATE: "To date",
        SPECIAL_REQUIREMENTS: "Special requirements",
        LANGUAGE: "Language",
        LANGUAGE_ANY: "Any",
        DISTANCE: "Distance (Miles)",
        DAY_OF_THE_WEEK: "Day of the week",
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
        SUNDAY: "Sunday",
        FROM_DATE_ATTENDEE_TOOLTIP: "Enter the earliest date you can attend your course",
        FROM_DATE_CA_TOOLTIP: "Enter the earliest date the attendee can attend a course",
        TO_DATE_ATTENDEE_TOOLTIP: "Enter the latest date you can attend your course",
        TO_DATE_CA_TOOLTIP: "Enter the latest date the attendee can attend a course",
        LANGUAGE_TOOLTIP: "Our courses are provided in the English or Welsh languages",
        POSTCODE_ATTENDEE_TOOLTIP: "Enter the postcode where you wish to take your course",
        POSTCODE_CA_TOOLTIP: "Enter the postcode where the attendee wishes to take their course",
        DISTANCE_ATTENDEE_TOOLTIP: "Please select how far you would prefer to travel to your course",
        DISTANCE_CA_TOOLTIP: "Please select how far the attendee would prefer to travel to their course",
        START_TIMES: "Start times",
        MORNING: "Morning",
        AFTERNOON: "Afternoon",
        EVENING: "Evening",
        CANCEL: "Cancel",
        Languages
    },
    ManualPayment: {
        HOW_MUCH_QUESTION: "How much would you like to pay?",
        BOOK_WITHOUT_PAYING: "Book without paying",
        GO_TO_PAYMENT: "Go to payment",
        ENTER_CARD_DETAILS: "Please enter details below",
        PAYMENT_DETAILS: "Your payment options"
    },
    AllEventInstances: {
        TO: "to",
        BOOK_THIS_COURSE: "Book this course",
        BOOK: "Book",
        YOUR_CHOSEN_VENUE: "Your chosen venue",
        COURSE_LANGUAGE: "Language:",
        SEATS_AVAILABLE: "seats available",
        AUTO_ONLY: "This course is suitable for automatic drivers only",
        MANUAL_ONLY: "This course is suitable for manual drivers only",
        MANUAL_AND_AUTO: "This course is suitable for manual and automatic drivers",
        LOAD_MORE: "Load more...",
        MILES: "miles",
        FLEXI_PAY_USED_DIGITAL: "The fee to rearrange your course today is covered by 'Flexi-Booking'.",
        FLEXI_PAY_USED_CLASSROOM: "The fee to rearrange your course today is covered by 'Flexi-Booking'. " +
            "Please note you may be required to pay a small additional fee if you choose to attend a venue located in another Police Force area.",
        Languages,
        DAY_NUMBER: "Day "
    },
    ReservationError: {
        COURSE_NOT_AVAILABLE: "We're sorry this course is no longer available, please <1>click here</1> to view alternative courses"
    },
    ChosenCourse: {
        YOUR_CHOSEN_COURSE: "Your chosen course",
        COURSE_LANGUAGE: "Language:",
        VENUE_HEADER: "Venue:",
        DATE_HEADER: "Course date: ",
        VENUE_NOTES: "Additional information",
        VENUE_INSTRUCTIONS: "Please note the following for your chosen venue: ",
        TERMS_BUTTON: "Terms & conditions",
        REARRANGEMENT_FEE: "Price includes £<1>{{fee}}</1> rearrangement fee",
        AMOUNT_TO_PAY: "Amount to pay",
        EXPENSIVE_VENUE: "Price includes £<1>{{fee}}</1> extra to attend in this Police Force area",
        SCHEDULED_PAYMENT: "Amount of scheduled payment set up: ",
        AMOUNT_DUE: "Amount due back to attendee: ",
        OUTSTANDING_BALANCE: "Outstanding balance: ",
        REARRANGE_FEE: "Amount to pay today to rearrange your course",
        AMOUNT_PAID: "Amount paid",
        PAYMENT_AMOUNT: "Payment amount",
        DIGITAL_LINK: "Digital course link: ",
        DELIVERED_USING_ZOOM: "Delivered using Zoom",
        FLEXI_PAY_DESCRIPTION: "By paying a flexi-booking fee, attendees will be eligible for one rebooking free of charge (restrictions apply).",
        FLEXI_PAY_FEE: "Price includes £<1>{{fee}}</1> flexi-booking fee",
        FLEXI_PAY_CONFIRMATION: "Flexi-booking fee: ",
        PAYMENT: "Payment",
        Languages,
        LANGUAGE_DEPENDENT_FIELD: "{{- english}}"
    },
    ChosenVenue: {
        YOUR_CHOSEN_VENUE: "Your chosen venue",
        VENUE: "Another venue",
    },
    PrivacyPolicy: {
        READ_PRIVACY_POLICY: "Read our Privacy Information",
        PRIVACY_POLICY: "At TTC Group we’re committed to ensure that your privacy is protected. We take your privacy seriously, and will only use your personal information to administer your account, and to provide the products and services that you have requested.\n\nThe information you have provided will not be used for marketing purposes and you will only receive communications from us that is relative to your course.\n\nYour information will only be shared with approved persons associated with TTC who are assisting in the provision of the product or service that you have requested.\n\nYou have the right to ask for a copy of the information TTC Group hold about you, you also have the right for your information to be erased or rectified.\n\nIf you have a query relating to your data please contact 0330 024 1805, alternatively, you can email <1>yourdata@ttc-uk.com</1> or write to The Data Protection Officer, TTC Group, Hadley Park East, Telford TF1 6QJ.\n\nFor more information on TTC Group’s Privacy Policy please visit <3>https://www.ttc-uk.com/aboutus/#privacy</3>"
    },
    RebookInformation: {
        REBOOKING: "Rebooking",
        REBOOKING_INFORMATION_CLASSROOM: "Your original course booking is for <strong>{{- eventName}}</strong>, at <strong><3>{{- address}}</3></strong> on <strong>{{date}}</strong> at <strong>{{time}}</strong>.",
        REBOOKING_INFORMATION_DIGITAL_ATTENDEE: "Your original course booking was a Digital course for <strong>{{- eventName}}</strong> on <strong>{{date}}</strong> at <strong>{{time}}</strong>.",
        REBOOKING_INFORMATION_DIGITAL_CA: "Your original course booking was at <strong>{{- venueName}}</strong> for <strong>{{- eventName}}</strong> on <strong>{{date}}</strong> at <strong>{{time}}</strong>.",
        REBOOKING_FOOTNOTE: "Please select your new preferred date and time.",
        CANCEL_REBOOKING: "Cancel Rebooking",
    },
    OutstandingBalancePaymentConfirmation: {
        SUCCESSFUL_PAYMENT: "Your payment has been successful",
        BOOKING_REFERENCE: "Booking Reference",
        YOUR_PERSONAL_DETAILS: "Your personal details",
        WELCOME: "Below are the details of your <1>{{- eventTypeName}}</1> course booking.",
        CHANGE_BOOKING: "Thank you for changing your course.",
        NEW_BOOKING_REFERENCE: "Your new booking is confirmed - booking reference:",
        PRINT: "Print my booking details"
    },
    Dashboard: {
        WELCOME_HEADER: "Welcome {{attendeeName}}",
        BOOKING_REFERENCE: "TTC Booking Reference",
        WELCOME_TEXT: "You have been referred for {{eventTypeName}} with a deadline date of {{expiryDate}}",
        CHANGE_BOOKING: "Thank you for changing your course.",
        NEW_BOOKING_REFERENCE: "Your new booking is confirmed - booking reference:",
        PRINT: "Print my booking details",
        LANGUAGE_DEPENDENT_FIELD: "{{- english}}",
        YOUR_COURSE: "Your course",
        JOIN_COURSE: "Join course",
        TEST_MEETING: "Start test meeting",
        NEED_TO_REBOOK: "Need to rebook or change the date / venue?",
        PRICE: "Price",
        SCHEDULED_PAYMENT: "Scheduled payment amount",
        OUTSTANDING_BALANCE: "Outstanding balance",
        PAY_OUTSTANDING_BALANCE: "Pay outstanding balance",
        ZOOM_ASSISTANCE_HEADER: "Zoom meeting test not working?",
        ZOOM_ASSISTANCE: "If you experience difficulties when completing your test Zoom meeting then please email us at <1>onlinecoursesupport@ttc-uk.com</1>. Please include your <strong>TTC booking reference</strong>, <strong>name</strong> and <strong>contact number</strong> in order for our online support team to get back in touch.",
        RECOMMEND_ASSISTANCE: "<strong>You have booked a Digital course.  This will be delivered using Zoom.</strong><br />We recommend clicking on the link in order to complete a practice of joining your Digital Driver Awareness course with TTC.",
        UNABLE_TO_JOIN_ZOOM: "Unable to join course. Zoom call link not found.",
        COURSE_DETAILS_TITLE: "Course details",
        PAYMENT_DETAILS_TITLE: "Payment details",
        ADDITONAL_INFORMATION: "Additional information",
        ADDRESS: "Address",
    },
    CourseCoreDetails: {
        LANGUAGE: "Language",
        DELIVERED_ONLINE: "Delivered online",
        Languages
    },
    BookingActions: {
        RESEND_BOOKING_CONFIRMATION: "Resend booking confirmation",
        PRINT_BOOKING_DETAILS: "Print booking details",
        TERMS_AND_CONDITIONS: "Terms & conditions",
    },
    RebookButton: {
        CANNOT_REBOOK_INFO: "You are unable to make amendments to your course at this time. ",
        CALLUS: "Please call 0330 024 1805 where one of our Advisors will be happy to help.",
        REBOOK: "Rebook here",
        REBOOK_DASHBOARD: "Rebook",
        REBOOK_INTRO: "Need to change your course date or venue?",
        COURSE_ATTENDED_AND_COMPLETED: "Our records indicate you have completed this course"
    },
    OutstandingBalanceButton: {
        PAY_OUTSTANDING_BALANCE: "Pay outstanding balance",
        PAY_OUTSTANDING_BALANCE_QUESTION: "Do you want to pay your outstanding balance?"
    },
    ResendConfirmationButton: {
        RESEND_CONFIRMATION_EMAIL: "Resend Booking Confirmation Email",
    },
    PrintedBookingConfirmation: {
        SALUTATION: "Dear {{- forename}}",
        COURSE_DETAILS: "Course details",
        DIGITAL_COURSE_LINK: "Digital Course Link",
        DIGITAL_COURSE_MEETING_ID: "Digital Course Meeting ID",
        DIGITAL_COURSE_PASSWORD_ATTENDEE: "Digital Course Password Attendee",
        ZOOM_DETAILS: "ZOOM details",
        BOOKING_REFERENCE: "Booking reference",
        COURSE_TYPE: "Your chosen course",
        COURSE_DATE: "Course date",
        COURSE_TIME: "Course time",
        COURSE_LOCATION: "Venue",
        COURSE_LANGUAGE: "Course language",
        AMOUNT_PAID: "Amount paid",
        OUTSTANDING_BALANCE: "Outstanding balance",
        VENUE_NOTE: "Venue note",
        BOOKING_NOTE: "Booking note",
        TERMS_AND_CONDITIONS: "Terms & conditions",
        VALEDICTION: "Regards,",
        FLEXI_PAY: "Flexi-booking",
        Languages
    },
    RebookingSignIn: {
        NEW_CODE: "The code you provided was correct but has expired, a new code has been emailed to you.",
        SUPERSEDED: "The code you provided has been superseded by a newer email.",
        ACCOUNT_NOT_RECOGNISED: "The account information provided was not recognised.",
        CODE_NOT_RECOGNISED: "The entered code was not recognised.",
        ACCOUNT_ALREADY_LOCKED: "Your account is currently locked.",
        TRY_AGAIN: "Please try again at {{- accountLockedUntil}}.",
        EMAIL_REQUEST_LIMIT_REACHED: "Your account has been temporarily locked as you have requested too many one-time passcodes.",
        VALIDATION_ATTEMPTS_LIMIT_REACHED: "Your account has been temporarily locked as you have entered the wrong one-time passcode too many times.",
        EMAIL_INVALID_TEXT: "Please enter a valid email",
        EMAIL_REQUIRED_TEXT: "Email is required",
        BOOKING_REFERENCE_INVALID_TEXT: "Please enter a valid booking reference",
        BOOKING_REFERENCE_REQUIRED_TEXT: "Booking reference is required",
        POSTCODE_INVALID_TEXT: "Please enter a valid postcode",
        POSTCODE_REQUIRED_TEXT: "Postcode is required",
        CODE_INVALID_TEXT: "You must enter the 6 digit code from the email",
        EMAIL_ADDRESS: "Email address",
        BOOKING_REFERENCE: "TTC Booking Reference",
        POSTCODE: "Postcode",
        TRANSFERRING: "Transferring...",
        HEADER_TEXT: "Police and court referred courses sign in",
        ASTERISK_LEGEND: "(<1>*</1>) Indicates a required field.",
        OTP_INITIAL_TEXT: "To continue, you need to verify your e-mail address with a one-time passcode (OTP).",
        SEND_CODE: "Send one-time-code",
        NEW_CUSTOMER: "New Customer? Register here",
        OTP: "One-time password (OTP)",
        RESEND_MESSAGE: "Please enter the one-time password we have emailed to you. To resend,",
        CLICK_HERE: "click here.",
        SUBMITTING: "Submitting...",
        VERIFY: "Verify",
        VERIFYING: "Verifying...",
        CHECK_SPAM_FOLDER: "If you have not received the email, check your spam/junk folder.",
        BOOKING_REF_TOOLTIP: "To locate your TTC booking reference, please check the email that confirms your booking. It is an 8-digit code."
    },
    FlexiPayModal: {
        TERMS_AND_CONDITIONS_TEXT: "<0>See T&Cs</0> for more information",
        CHOOSE_BOOKING_TYPE: "Choose your booking type",
        CHOOSE_FLEXI: "Choose Flexi-Booking",
        CHOOSE_STANDARD: "Choose Standard Booking",
        FLEXI_BOOKING: "Flexi-Booking",
        STANDARD_BOOKING: "Standard Booking",
        FLEXI_FEE: "Flexi-booking fee",
        MOST_POPULAR: "Most Popular",
    }
};
