import * as React from "react";
import {  DorsBookingAccountLockSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const DorsBookingAccountLock: ConfigurationAreaComponent<DorsBookingAccountLockSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="DorsBookingAccountLockSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "FailedCodeAttemptsToLock", label: "Attempts to enter a code before the account is locked" },
                { name: "FailedEmailAttemptsToLock", label: "Number of emails the user can try before the account is locked" },
                { name: "TimeToLockInMinutes", label: "Minutes the account is locked out" },
                { name: "ValidationCodeLifespanInMinutes", label: "Validation code lifespan in minutes" },
            ]}
        />
    );
};
