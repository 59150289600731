/* eslint-disable max-lines */
import moment from "moment";
import { Merge, FieldError, FieldErrorsImpl } from "react-hook-form";
import { AccordionTitleProps } from "semantic-ui-react";
import { object, string, mixed, boolean, InferType, number } from "yup";
import { RiskRating, OpenreachStandardOnRoadReport } from "./Openreach/Standard/model";
import { MultiDropVehicleOnRoadReport } from "./MultiDropVehicle/model";
import {  OpenreachTrailerTowingOnRoadReport } from "./Openreach/TrailerTowing/model";
import { AttendeeListModel } from "@common/crud/attendee";
import { OnRoadWithTrailerTowingReport } from "./OnRoad/TrailerTowing/model";
import { ProductCategoryEnum } from "@common/crud/eventType/model";
export type GenericReportModel =
    OnRoadReport |
    MultiDropVehicleOnRoadReport |
    OpenreachStandardOnRoadReport |
    OpenreachTrailerTowingOnRoadReport |
    OnRoadWithTrailerTowingReport;

export type BaseOnRoadReportState = {
    delegate: AttendeeListModel;
    existingOnRoadReport: DelegateOnRoadReport | undefined;
    setOnRoadReports: React.Dispatch<React.SetStateAction<DelegateOnRoadReport[]>>;
    reportOpen: boolean;
    editing: boolean;
    setEditing: React.Dispatch<React.SetStateAction<boolean>>;
    onAttendeeReportClick: () => void;
};

export enum VehicleTypeEnum {
    None = 0,
    Car = 1,
    CarDerivedVan = 2,
    PickUp = 3,
    LightGoods = 4,
    RigidGoods = 5,
    ArticGoods = 6,
    Minibus = 7,
    Coach = 8,
}

const validVehicleTypeOptions = Object.values(VehicleTypeEnum).map(x => +x).filter(x => !isNaN(x) && x !== 0);

export const VehicleTypeEnumText: Record<VehicleTypeEnum, string> = {
    [VehicleTypeEnum.None]: "None",
    [VehicleTypeEnum.Car]: "Car",
    [VehicleTypeEnum.CarDerivedVan]: "Car derived van",
    [VehicleTypeEnum.PickUp]: "Pick up",
    [VehicleTypeEnum.LightGoods]: "Light goods < 3.5t",
    [VehicleTypeEnum.RigidGoods]: "Rigid goods > 3.5t",
    [VehicleTypeEnum.ArticGoods]: "Artic goods",
    [VehicleTypeEnum.Minibus]: "Minibus",
    [VehicleTypeEnum.Coach]: "Coach",
};

export enum FuelTypeEnum {
    None = 0,
    PetrolOrDiesel = 1,
    PlugInHybrid = 2,
    SelfChargingHybrid = 3,
    FullyElectric = 4,
    Hydrogen = 5,
}

const validFuelTypeOptions = Object.values(FuelTypeEnum).map(x => +x).filter(x => !isNaN(x) && x !== 0);
export const FuelTypeEnumText: Record<FuelTypeEnum, string> = {
    [FuelTypeEnum.None]: "None",
    [FuelTypeEnum.PetrolOrDiesel]: "Petrol or diesel",
    [FuelTypeEnum.PlugInHybrid]: "Plug-in hybrid",
    [FuelTypeEnum.SelfChargingHybrid]: "Self-charging hybrid",
    [FuelTypeEnum.FullyElectric]: "Fully electric",
    [FuelTypeEnum.Hydrogen]: "Hydrogen",
};

export const ElectricFuelVehicles = [FuelTypeEnum.PlugInHybrid, FuelTypeEnum.SelfChargingHybrid, FuelTypeEnum.FullyElectric] as const;
export const FullyEcoVehicles = [FuelTypeEnum.FullyElectric, FuelTypeEnum.Hydrogen] as const;

const vehicleDetailsSchema = object({
    lineManager: string().optional(),
    registrationNumber: string().optional()
        .matches(/^(?!\s)/, "No leading spaces allowed")
        .matches(/^(?!\s)[A-Za-z0-9 ]*$/, "Invalid characters used"),
    vehicleType: number().oneOf(validVehicleTypeOptions, "Vehicle type cannot be \"None\""),
    fuelType: number().oneOf(validFuelTypeOptions, "Fuel type cannot be \"None\"") });

const yesNoWithReasonSchema =(requireReasonWhen: boolean, errorText: string) => object({ completed: boolean(),
    comments: string()
        .when("completed", {
            is: requireReasonWhen,
            then: (schema) => schema.required(errorText)
        }) });

export const defaultYesNoWithReasonSchema = yesNoWithReasonSchema(false, "Additional information is required");

export const trainingObjectivesError = "All training objectives are required";
const preCourseCheckError = "Additional information is required";
const preCourseSchema = object({
    companyObjectives: object({
        objectiveOne: string().optional()
    }),
    trainingObjectives: object({
        objectiveOne: string().required(trainingObjectivesError),
        objectiveTwo: string().required(trainingObjectivesError),
        objectiveThree: string().required(trainingObjectivesError),
    }),

    eyeSightCheck: defaultYesNoWithReasonSchema,
    vehicleCheck: defaultYesNoWithReasonSchema,
    licenceCheck: defaultYesNoWithReasonSchema,
    healthIssues: yesNoWithReasonSchema(true, preCourseCheckError),
});

export const assessmentCriteriaOptions = [1, 2, 3, 4, 5] as const;
const assessmentCriteria = object({
    rating: mixed<typeof assessmentCriteriaOptions[number]>().required("You must select a number"),
    comments: string().required("Comments are required")
});

export type AssesmentCriteriaModel  = InferType<typeof assessmentCriteria>;
const concentrationSchema = object({
    commentaryDrive: assessmentCriteria,
    explainImpacts: assessmentCriteria,
});

const observationSchema = object({
    effectiveObservation: assessmentCriteria,
    useOfMirrors: assessmentCriteria,
    blindSpotVision: assessmentCriteria,
    dealingWithJunctions: assessmentCriteria,
});
const anticipationBaseSchema = object({
    forwardPlanning: assessmentCriteria,
    decisionMaking: assessmentCriteria,
    predictingRoadUsers: assessmentCriteria,
});

const anticipationSchemaElectric = object({
    regenerativeBraking: assessmentCriteria
});

const anticipationSchemaNonElectric = object({
    gearSelection: assessmentCriteria
});

const anticipationExtendedSchema = anticipationBaseSchema.concat(anticipationSchemaElectric).concat(anticipationSchemaNonElectric);

const anticipationSchema = anticipationBaseSchema
    .when("vehicleDetails.fuelType",
        {
            is: (value: `${FuelTypeEnum}`) =>
                ElectricFuelVehicles.includes(+value),
            then: (schema) => schema.concat(anticipationSchemaElectric),
            otherwise: (schema) => schema.concat(anticipationSchemaNonElectric)
        });

const spaceSchema = object({
    movingFollowingDistance: assessmentCriteria,
    queueingFollowingDistance: assessmentCriteria,
    positioning: assessmentCriteria,
    safetyMargins: assessmentCriteria,
});

const timeBaseSchema = object({
    appropriateSpeed: assessmentCriteria,
    speedCompliance: assessmentCriteria,
    accelerationSense: assessmentCriteria,
});

const timeSchemaHybridPetrolOrDiesel = object({
    ecoDriving: assessmentCriteria,
});

const timeSchemaElectric = object({
    ecoBattery: assessmentCriteria,
});

const timeSchema = timeBaseSchema
    .when("vehicleDetails.fuelType",
        {
            is: (value: `${FuelTypeEnum}`) => ElectricFuelVehicles.includes(+value),
            then: (schema) => schema.concat(timeSchemaElectric),
        })
    .when("vehicleDetails.fuelType",
        {
            is: (value: `${FuelTypeEnum}`) => !FullyEcoVehicles.includes(+value),
            then: (schema) => schema.concat(timeSchemaHybridPetrolOrDiesel),
        });

const timeSchemaExtended = timeBaseSchema.concat(timeSchemaHybridPetrolOrDiesel).concat(timeSchemaElectric);

const parkingAndManoeuvringBaseSchema = object({
    observations: assessmentCriteria,
    control: assessmentCriteria,
    accuracy: assessmentCriteria });

const parkingAndManoeuvringSchemaElectric = object({
    charging: assessmentCriteria,
});

const parkingAndManoeuvringExtendedSchema = parkingAndManoeuvringBaseSchema.concat(parkingAndManoeuvringSchemaElectric);

const parkingAndManoeuvringSchema = parkingAndManoeuvringBaseSchema
    .when("vehicleDetails.fuelType",
        {
            is: (value: `${FuelTypeEnum}`) => ElectricFuelVehicles.includes(+value),
            then: (schema) => schema.concat(parkingAndManoeuvringSchemaElectric)
        });

export const summarySchema = string().required("Summary is required");

export const onRoadSchemaObject = {
    vehicleDetails: vehicleDetailsSchema,
    preCourse: preCourseSchema,
    concentration: concentrationSchema,
    observation: observationSchema,
    anticipation: anticipationSchema,
    space: spaceSchema,
    time: timeSchema,
    parkingAndManoeuvring: parkingAndManoeuvringSchema,
    summaryText: summarySchema,
} as const;
export const OnRoadReportSchema = object(onRoadSchemaObject);
export type VehicleDetails = InferType<typeof vehicleDetailsSchema>;
export type PreCourse = InferType<typeof preCourseSchema>;
export type Concentration = InferType<typeof concentrationSchema>;
export type Observation = InferType<typeof observationSchema>;
export type Anticipation = InferType<typeof anticipationExtendedSchema>;
export type Space = InferType<typeof spaceSchema>;
export type Time = InferType<typeof timeSchemaExtended>;
export type ParkingAndManoeuvring = InferType<typeof parkingAndManoeuvringExtendedSchema>;
type SummarySchema = InferType<typeof summarySchema>;

export type OnRoadReport = InferType<typeof OnRoadReportSchema>;
export type OnRoadReportAll = {
    vehicleDetails: VehicleDetails;
    preCourse: PreCourse;
    concentration: Concentration;
    observation: Observation;
    anticipation: Anticipation;
    space: Space;
    time: Time;
    parkingAndManoeuvring: ParkingAndManoeuvring;
    summaryText: SummarySchema;
}

export const sectionTitles: Record<keyof OnRoadReport, string> = {
    vehicleDetails: "General details",
    preCourse: "Pre course",
    concentration: "Concentration",
    observation: "Observation",
    anticipation: "Anticipation",
    space: "Space",
    time: "Time",
    parkingAndManoeuvring: "Parking and manoeuvring",
    summaryText: "Additional comments / summary"
};
export type sectionKey = keyof typeof onRoadSchemaObject;
type NonAssessmentSections = "vehicleDetails" | "preCourse" | "summaryText";
export type AssessmentSectionKey = Exclude<sectionKey, NonAssessmentSections>;
export type OnRoadAssessment = Omit<OnRoadReportAll, NonAssessmentSections>

const sectionKeys = Object.keys(onRoadSchemaObject) as sectionKey[];
export const assessmentSectionKeys = sectionKeys.filter(section =>
    section !== "vehicleDetails" && section !== "preCourse" && section !== "summaryText") as AssessmentSectionKey[];

type OnRoadReportSectionKey = keyof OnRoadReport;
export type OnRoadReportSection = OnRoadReport[OnRoadReportSectionKey];
export type formSectionErrors<T  extends Omit<OnRoadReportSection, "summaryText">> = Merge<FieldError, FieldErrorsImpl<T>>

export type GenericFormSectionErrors <U, T  extends Omit<U, "summaryText">> = Merge<FieldError, FieldErrorsImpl<T>>

export enum OnRoadReportStatus {
    NotSubmitted = 0,
    InProgress = 1,
    Submitted = 2,
    Rejected = 3,
}

export const OnRoadReportStatusText: Record<OnRoadReportStatus, string> = {
    [OnRoadReportStatus.NotSubmitted]: "Not submitted",
    [OnRoadReportStatus.InProgress]: "In progress, last saved: ",
    [OnRoadReportStatus.Submitted]: "Submitted: ",
    [OnRoadReportStatus.Rejected]: "Rejected: ",
};

export enum OnRoadReportType {
    OnRoad = 0,
    MultiDropVehicle = 1,
    OpenreachStandard = 2,
    OpenreachTrailerTowing = 3,
    OnRoadTrailerTowing = 4
}

export type DelegateOnRoadReport = {
    id: string;
    eventInstanceId: string;
    attendeeId: string;
    report?: OnRoadReport;
    multiDropVehicleReport?: MultiDropVehicleOnRoadReport;
    openreachStandardReport?: OpenreachStandardOnRoadReport;
    openreachTrailerTowingReport?: OpenreachTrailerTowingOnRoadReport;
    onRoadWithTrailerTowingReport?: OnRoadWithTrailerTowingReport;
    status: OnRoadReportStatus;
    averageRating: number;
    competentToDrive?: boolean;
    openreachRiskRating?: RiskRating;
    dateSubmitted: moment.Moment | null;
    dateLastSaved: moment.Moment | null;
    dateRejected: moment.Moment | null;
    reportType: OnRoadReportType;
    rejectionReason: string;
}

interface GenericAccordionEditSectionWrapperProps<U, T extends Omit<U, "summaryText">> extends AccordionSectionPropsBase {
    showErrors: true;
    sectionErrors: GenericFormSectionErrors<U, T>;
}

interface AccordionDetailSectionWrapperProps extends AccordionSectionPropsBase {
    showErrors: false;
}

interface AccordionDetailSectionWrapperProps extends AccordionSectionPropsBase {
    showErrors: false;
}

export type GenericAccordionSectionWrapperProps<U, T extends Omit<U, "summaryText">> =
   GenericAccordionEditSectionWrapperProps<U, T> | AccordionDetailSectionWrapperProps;

interface AccordionSectionPropsBase {
    onReportSectionClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => void;
    open: boolean;
    title: string;
}

type RatingSuggestions = Record<typeof assessmentCriteriaOptions[number], string>;
type SectionSuggested<T extends keyof OnRoadAssessment> = Record<keyof OnRoadAssessment[T], RatingSuggestions>;
export type SuggestedComments<T extends keyof OnRoadAssessment> = Record<T, SectionSuggested<T>>;

/* eslint-disable max-len */
const commentaryDriveSuggestions: RatingSuggestions = {
    1: "You were able to identify and verbalise all of the appropriate hazards encountered, both actual and potential. This suggests that your concentration levels are high, leading to a planned and proactive approach to your driving.",
    2: "You managed to identify and verbalise the majority of hazards, however, in order to capture all of the relevant information you should pay particular attention to the potential and developing hazards. These tend to be less obvious to see at first, but are very important so as not to be taken by surprise.",
    3: "You managed to identify and verbalise some of the more obvious hazards, however, you need to pay particular attention to the detail surrounding the road ahead, the more detail you pick up on, the greater your concentration levels.",
    4: "You managed to identify and verbalise some hazards, however, you need to ask yourself 'what if', this would lead you to reading more into what might happen rather than just identifying the hazard as a visible object",
    5: "The number of hazards you were able to verbalise was minimal. This suggests you are not fully concentrating on the road environment and therefore not going to be able to adopt a proactive approach to identifying and dealing with hazards.",
};

const explainImpactSuggestions: RatingSuggestions = {
    1: "You are fully aware of the factors, both internal and external, that are linked to effective concentration and have a good strategy in place to maintain your attention to the road environment.",
    2: "You appear to be aware of the importance of maintaining concentration on the road environment and have a reasonable strategy to maintain focus. Utilising a commentary drive approach will enhance your concentration levels further.",
    3: "You have identified the more obvious elements that place demands on your driving concentration, however, do not allow non-related driving factors to divert your concentration away from the road environment.",
    4: "You recognise the need to concentrate on the driving task but do not fully appreciate all of factors that can affect concentration or have a strategy in place to maintain focus on the road environment. Reading the road ahead and being aware of your environment is key to safe driving.",
    5: "You have not considered the importance of concentrating on the driving task as being a priority against other factors. In order to drive safely, concentration needs to be diverted to the driving task so that a proactive approach can be adopted to your driving.",
};

const concentrationSuggestions: SectionSuggested<"concentration"> = {
    commentaryDrive: commentaryDriveSuggestions,
    explainImpacts: explainImpactSuggestions,
} as const;

const effectiveObservationSuggestions: RatingSuggestions = {
    1: "You have demonstrated very good observation and scanning skills by verbalising what you see in the far, middle and near distances as well as beside and behind you.",
    2: "You demonstrated a good level of observation skills and appreciate the importance of adopting a proactive approach to observing the road ahead, beside and behind your vehicle. Look for clues, under, through and over vehicles, reflections in windows for example.",
    3: "Your observations are inconsistent, while appearing to be good at times, you lapse into phases of adopting a fixed stare. It's important that you are aware of your surroundings at all times, this can be achieved by proactively looking for hazards ahead, beside and behind your vehicle.",
    4: "Your observations appear to focus on the road ahead and relatively close to the front of your vehicle. Open up your vision to take into account what's happening well ahead as well as beside and behind you.",
    5: "You appear to take a very passive approach to observation and are unaware of the detail in the road environment. It's critical that you develop an effective and proactive scanning technique, assessing the far, middle and near road environment, as well as what is going on beside and behind you.",
};

const useOfMirrorsSuggestions: RatingSuggestions = {
    1: "Your use of interior and side mirrors is highly effective, demonstrating that you are aware of your surroundings and consider these as key to making appropriate decisions in the direction and course of your vehicle.",
    2: "While your use of mirrors is good, consider checking them slightly sooner so that you have time to consider how your intended manoeuvre might affect other road users.",
    3: "Your use of the interior mirror is good, however, consider using door mirrors prior to making any change of direction, this will enable you to take account of other road users and how your manoeuvre might affect them.",
    4: "You appear to have an inconsistent approach to the use of mirrors. It's very important that you are aware at all times what going on around you, especially before you make any form of manoeuvre or change of direction.",
    5: "Your use of both interior and side mirrors is poor. In order to make a safe manoeuvre you must be aware of what is happening around you.",
};

const blindSpotVisionSuggestions: RatingSuggestions = {
    1: "You have demonstrated a good appreciation of the need to check blind spots prior to moving off or making a manoeuvre, including on the move, where other road users could be present.",
    2: "You have demonstrated a good appreciation of the need to check blind spots prior to moving off and reversing, however, consider making appropriate shoulder checks when on the move where your intended manoeuvre might affect another road user who is in your blind spot.",
    3: "Your checking of blind spots is inconsistent. You check them in some instances but not in others. In order to avoid coming into conflict with other road users its important to always check blind spots before moving off and in some instances, prior to making a manoeuvre while on the move.",
    4: "You tend to check blind spots prior to moving off but rarely when reversing, relying solely on mirrors. To avoid conflict with another road user it's very important to consider what might be in your blind spot areas prior to reversing.",
    5: "You rarely check your blind spots and when you do, it appears to be a cursory glance. In order to avoid a collision it's critical that you are aware and check blind spot areas before moving off and prior to making a manoeuvre where other road users could be present.",
};

const dealingWithJunctionsSuggestions: RatingSuggestions = {
    1: "Your technique for dealing with all types of junctions is of a consistently good standard. Your management of speed on approach allows time for effective observations, correct lane positioning and a planned approach throughout.",
    2: "Your techniques for dealing with all types of road junctions is generally good, however, reducing your speed earlier on approach will allow more time to assess the environment and anticipate the need to stop, or, adopt a slow-and-flow approach. This technique will allow more time for effective observations and better decision making.",
    3: "Your approach and techniques for typical junctions is generally good, however, your approach and lane discipline at roundabouts is inconsistent. It's important to approach at a speed that allows time to assess a gap, adopt the correct lane on approach and throughout the roundabout and avoid harsh braking/acceleration.",
    4: "Your approach and technique to junctions needs further development. Consider an earlier reduction in speed to give you more time to read the environment, understand what's going on around you and make appropriate decisions.",
    5: "Your approach and technique to junctions is poor. In order to avoid being taken by surprise it's important that you reduce your speed earlier, assess what's going on around you and make good decisions regarding lane discipline.",
};

const observationSuggestions: SectionSuggested<"observation"> = {
    effectiveObservation: effectiveObservationSuggestions,
    useOfMirrors: useOfMirrorsSuggestions,
    blindSpotVision: blindSpotVisionSuggestions,
    dealingWithJunctions: dealingWithJunctionsSuggestions,
};

const forwardPlanningSuggestions: RatingSuggestions = {
    1: "The smoothness of your drive demonstrates you have good forward planning skills, thus having time to anticipate the actions of others so as to avoid harsh braking and sudden changes in direction.",
    2: "Your forward planning skills are good but could be improved by looking further ahead which facilitates time to adopt a more proactive approach.",
    3: "Your forward planning skills in urban environments is inconsistent. You need to consider the actions of other road users and how their actions may require you to alter your speed and/or direction to deal with the situation safely.",
    4: "Your forward planning skills in rural environments is inconsistent. You tend to approach hazards, especially bends too fast, this leads to harsh braking while steering. This approach is destabilising on the vehicle and in certain situations could result in loss of control.",
    5: "Your driving style suggests you are not anticipating the road situations which leads to late and haphazard decision making. This style of driving places you and other road users at risk of being involved in a collision.",
};

const decisionMakingSuggestions: RatingSuggestions = {
    1: "You demonstrated a good standard of appropriate decision making throughout your drive.",
    2: "Your decision making is generally good, however, have confidence in knowing that you will have time to make the right decisions due to your approaching junctions and hazards at an appropriate speed.",
    3: "Your decision making is generally good, however, managing speed on approach to hazards and junctions will enable more time to plan and execute an appropriate course of action.",
    4: "Your decision making skills are inconsistent. In some instances you make calm and planned decisions, however, in other instances you rush the decision making process. This could lead to errors that place you and others at risk of a collision.",
    5: "Your decision making often demonstrated a lack of anticipation, leading to sudden stops, hesitancy or rushed decisions. Managing speed on approach to junctions and hazards will provide you with time to look well ahead so as to anticipate the appropriate course of action that is required.",
};

const predictingRoadUsersSuggestions: RatingSuggestions = {
    1: "You demonstrated good ability in anticipating the actions of other road users and were able to take appropriate and proactive action.",
    2: "Your ability to predict and take appropriate action based on other road users' actions is generally good, however, some of the less obvious hazards tended to take you by surprise. Always assume other road users will do the worst, ask yourself 'what if', you will then be less reactive in such situations.",
    3: "Your ability to predict and take appropriate action based on other road users' actions is good when in quiet situations, however, when in busy situations your driving is less structured and more reactive. Slowing down will give you more time to look and see, anticipate and take a more proactive approach.",
    4: "Your ability to predict and take appropriate action based on other road users' actions is inconsistent. You are able to anticipate the more obvious actions from others but do not tend to anticipate the potential actions, these tend to take you by surprise. Always assume other road users will do the worst, ask yourself 'what if', you will then be less reactive in such situations.",
    5: "Your ability to predict and take appropriate action based on other road users' actions is poor. In order to avoid becoming involved in a collision it is important that you observe and anticipate what other road users might do so that you can plan your drive accordingly. Always assume other road users will do the worst, ask yourself 'what if', you will then be less reactive in such situations.",
};

const gearSelectionSuggestions: RatingSuggestions = {
    1: "You demonstrated good use of gears throughout your drive, making changes in time, smoothly and with sympathy towards engine load. You used the automatic gearbox functions appropriately.",
    2: "While your use of gears is generally good, you tended to change down through the gears when slowing/stopping. Based on early observations, ease off the accelerator and allow the vehicle to lose speed gradually, selecting the appropriate gear once you know it is safe to proceed.",
    3: "Your use and selection of gears is generally good but you should avoid resting your hand on the gear lever as this limits steering control in certain situations.",
    4: "You demonstrated an inconsistent approach to the selection of gears. In some instances you were in the wrong gear which placed undue load on the engine and limited your ability to accelerate appropriately. Try and anticipate what gear would be appropriate to the road environment that will give you the best control, both in reducing or increasing your speed.",
    5: "Your use of gears is haphazard, making many unnecessary changes at a time when your attention should be on reading the road, positioning, and speed management. Based on early observations, ease off the accelerator and allow the vehicle to lose speed gradually, selecting the appropriate gear once you know it is safe to proceed.",
};

const regenerativeBrakingSuggestions: RatingSuggestions = {
    1: "You demonstrated excellent use of the regenerative braking feature of this vehicle throughout your drive, utilizing a mix of coasting and regenerative braking smoothly and with sympathy towards engine load when required. You also used the regenerative settings appropriately.",
    2: "You demonstrated a good use of the regenerative braking, think about how you could use it more efficiently through allocating the right setting or using coasting to achieve better results.",
    3: "While your use of regenerative braking is reasonably good, you tended not to use the settings or coasting feature to your best advantage when slowing/stopping. Based on early observations, think about the best way to lose speed gradually for the road and conditions you are in, rather than have the vehicle's regenerative braking settings set in one position for all roads.",
    4: "You demonstrated an inconsistent approach to the selection of regenerative braking. In some instances you could have either altered the setting or used coasting to achieve a better result. Try to anticipate what the traffic is doing ahead and which function is best suited to your driving style and the vehicle you are driving.",
    5: "Your use of the regenerative braking function of the vehicle is haphazard, with little consideration to not only how it works and what can be achieved by using it efficiently, but also to how it affects those around you. Think about how you could use the mix of regenerative braking vehicle settings and coasting to your best advantage, be that battery efficiency or controlling the traffic around you prior to a hazard.",
};

const anticipationSuggestions: SectionSuggested<"anticipation"> = {
    forwardPlanning: forwardPlanningSuggestions,
    decisionMaking: decisionMakingSuggestions,
    predictingRoadUsers: predictingRoadUsersSuggestions,
    gearSelection: gearSelectionSuggestions,
    regenerativeBraking: regenerativeBrakingSuggestions,
};

const movingFollowingDistanceSuggestions: RatingSuggestions = {
    1: "You demonstrated good judgment in being able to allow a safe following gap between you and vehicles in front.",
    2: "Most of the time you maintained a safe gap between you and the vehicle in front, however, when someone fills the gap in front of you, ease off the accelerator for a few seconds to re-establish the gap. Doing this maintains a safe following distance but will not adversely impact on the overall time of your journey.",
    3: "You tend to allow other drivers behind you to influence your speed and therefore the gap you maintain in front of you. Do not give in to pressure, when being tailgated, create more space between you and the vehicle in front, this builds in a safety margin should the vehicle in front brake suddenly.",
    4: "While you did leave a gap between you and the vehicle in front, you tended to not increase the space as speeds increased. Remember, the faster you go the more time it takes to stop. Maintain a safe gap by using the two-second rule, double in the wet and up to 10 times when on ice or snow.",
    5: "You have a tendency to drive too close to vehicles in front of you. This restricts your vision of the road ahead and places you in danger of a collision should the vehicle in front brake suddenly. Maintain a safe gap by using the two-second rule, double in the wet and up to 10 times when on ice or snow.",
};

const queueingFollowingDistanceSuggestions: RatingSuggestions = {
    1: "You demonstrated good judgment in maintaining a safe gap when queuing in moving traffic throughout your drive.",
    2: "You usually maintained a safe gap when following traffic in queues, however, when stopping, maintain a gap sufficient for you to be able to pull around the vehicle should it break down. Remember, 'tyres-&-tarmac'.",
    3: "You usually maintained a safe gap when following traffic in queues, however, as speeds increase so should your gap so as to avoid a rear-end shunt should the vehicle in front brake suddenly.",
    4: "Your maintenance of a safe following gap was inconsistent. Remember, if the vehicle in front brakes suddenly you might not be able to stop in time.",
    5: "You tended to get too close to vehicles in front when queuing in moving traffic. This could leave you susceptible to being pushed into the vehicle in front if you sustained a rear-end shunt. Remember, 'tyres-&-tarmac'.",
};

const positioningSuggestions: RatingSuggestions = {
    1: "You demonstrated good judgment in positioning your vehicle so you could see and be seen at junctions and roundabouts. You also correctly positioned your vehicle when negotiating bends in rural environments.",
    2: "You demonstrated good judgment in positioning your vehicle when dealing with junctions, however, consider increasing your field of view when approaching bends in rural environments. Where road space is available, position slightly left before approaching right-hand bends and towards the centre line when approaching left-hand bends, however, always be prepared to surrender that position if it places you too close to an oncoming vehicle.",
    3: "When dealing with junctions, consider the appropriate position prior to arriving at the junction and be prepared to creep forward to enable you to see and be seen by other road users.",
    4: "Your road positioning is inconsistent. At times you are positioned in another vehicle's blind spot or too close to large vehicles, thus restricting your view. Make sure you can see and can be seen by other road users by managing the space in front, beside, and behind you.",
    5: "You often positioned your vehicle in a place that limited your visibility and made you harder to be seen by others. Remember, creep slowly forward to increase your view when dealing with situations with limited view.",
};

const safetyMarginsSuggestions: RatingSuggestions = {
    1: "Throughout your drive you demonstrated good judgment in maintaining space, in front, beside, and behind your vehicle.",
    2: "Your management of space is generally very good, however, when traveling on dual carriageways and motorways avoid being alongside other vehicles when passing entry slip roads. Adjust your speed so that you have space beside you, an escape route, should you need it.",
    3: "In most instances you maintained a good safety margin around your vehicle, however, avoid driving alongside large vehicles when on dual carriageways and motorways. Remember, if you cannot see the driver in their mirrors, they cannot see you.",
    4: "You tended to compromise your safety margins at times, in particular when passing stationary vehicles or cyclists. Consider doors opening or cyclists wobbling off-course, and position your vehicle so that you will clear them adequately, if you cannot, hold back until it is safe to proceed.",
    5: "Your judgment of maintaining a safe space in front, beside, and behind you is poor. This places you in imminent danger of a collision. Think of your vehicle as having a protective bubble, do not allow anyone to enter that space, if this is likely, consider how you can adjust your speed or position to maintain your safety margin.",
};

const spaceSuggestions: SectionSuggested<"space"> = {
    movingFollowingDistance: movingFollowingDistanceSuggestions,
    queueingFollowingDistance: queueingFollowingDistanceSuggestions,
    positioning: positioningSuggestions,
    safetyMargins: safetyMarginsSuggestions,
};

const appropriateSpeedSuggestions: RatingSuggestions = {
    1: "Throughout your drive you demonstrated good judgment in selecting an appropriate speed based on the prevailing road, traffic, and weather conditions.",
    2: "Your judgment of selecting an appropriate speed is generally good, however, consider reducing speed when weather conditions are less favorable.",
    3: "Your judgment of speed was generally good, however, consider reducing speed when traveling in urban environments, remember, expect the unexpected and don't get taken by surprise.",
    4: "Your judgment of speed was generally good, however, when driving through residential environments consider the presence of vulnerable road users, they can be unpredictable, so anticipate the worst and slow down.",
    5: "You did not adjust your speed to reflect the hazards present or take into account the prevailing road, traffic, and weather conditions. Remember, you should drive at a speed that would enable you to stop safely should the unforeseen happen.",

};

const speedComplianceSuggestions: RatingSuggestions = {
    1: "Throughout your drive you demonstrated a good understanding of the different speed limits and generally complied with them.",
    2: "You demonstrated that you were aware of the speed limits but at times your compliance lapsed marginally. Maintain concentration and check your speed regularly.",
    3: "You demonstrated that you were aware of the speed limits but your compliance lapsed frequently. Maintain concentration and check your speed regularly.",
    4: "You tended to exceed the posted speed limit in some situations. This tended to be due to your lack of awareness of what the speed limit was. Concentrate, look out for where speed limits change and if you missed a sign, try and work out the speed limit based on other clues.",
    5: "Your knowledge and compliance of speed limits was poor. Make sure you look out for speed limit signs and check your speed regularly so that you do not exceed the posted limits.",
};

const accelerationSenseSuggestions: RatingSuggestions = {
    1: "Throughout your drive you demonstrated a good standard of acceleration sense, using the 'ease & squeeze' technique to good effect.",
    2: "You demonstrated some good examples of adopting the 'ease & squeeze' techniques, however, ease off the accelerator earlier to improve your comfort and safety margins.",
    3: "While you were aware of the benefits of having good acceleration sense, your lack of forward planning limited your scope to apply an 'ease & squeeze' approach. You will gain time to adopt this approach by looking well ahead and coming off the accelerator earlier, rather than maintaining power only to brake later.",
    4: "Your acceleration sense was rather inconsistent. The main areas to improve on are approaching roundabouts and junctions. Look ahead, anticipate the need to stop but be prepared to continue. Coming off the accelerator earlier will provide more time to glide into the junction viewing area without the need to brake or accelerate harshly.",
    5: "You demonstrated poor acceleration sense and adopted a harsh 'stop-go' theme. As well as being uncomfortable and uneconomical, it places you in danger of becoming involved in a collision.",
};

const ecoDrivingSuggestions: RatingSuggestions = {
    1: "During your drive you demonstrated sound knowledge and application of eco-safe driving principles.",
    2: "Your eco-safe driving techniques are generally good, however, driving at slightly slower speeds on certain roads will improve fuel economy with little impact on journey time.",
    3: "Your driving style is generally smooth and safe, however, fuel/energy consumption can be improved by reading the road ahead a little further so that you have more time to anticipate and plan, thus avoiding harsh braking followed by acceleration.",
    4: "Your eco-safe driving is inconsistent. Try and adopt all of the principles of COAST throughout your driving, this will make for a safer, smoother, and more fuel-efficient drive.",
    5: "Your driving style is unsympathetic towards your vehicle and fuel economy/energy. Such a driving style increases both stress levels and risk, as well as costing more in fuel and maintenance. Apply the principles of COAST and by default, you will be adopting an eco-safe approach.",
};

const ecoBatterySuggestions: RatingSuggestions = {
    1: "You show an excellent ability in getting the most out of your battery life.",
    2: "You show a good amount of ability in getting the most efficiency from your battery through your driving style. Maybe with a small amount more effort you could reach the maximum capable from your vehicle.",
    3: "Whilst overall your battery efficiency capabilities are ok, you need to work on getting the most efficiency out of your battery through a mix of acceleration / speed control, use of the regenerative braking functions of the vehicle and coasting.",
    4: "Your ability to get the most from your battery requires a lot more work in order to gain the maximum efficiency from the battery on your vehicle. Remember that efforts put in through your driving style will have a major impact on the life and efficiency of your vehicle's battery.",
    5: "Your ability to get the most out of your battery is poor and requires significant changes in your driving style in order that you gain maximum battery efficiency.",
};

const timeSuggestions: SectionSuggested<"time"> = {
    appropriateSpeed: appropriateSpeedSuggestions,
    speedCompliance: speedComplianceSuggestions,
    accelerationSense: accelerationSenseSuggestions,
    ecoDriving: ecoDrivingSuggestions,
    ecoBattery: ecoBatterySuggestions,
};

const observationsSuggestions: RatingSuggestions = {
    1: "You demonstrated good all-round observations before manoeuvring or parking.",
    2: "Your all-round observations are generally good, however, consider making more frequent sideways checks rather than relying on mirrors alone. This will provide better prior warning of an approaching road user who might not be visible in your mirrors.",
    3: "Your observations while manoeuvring are fairly good, however, take time to check all around your vehicle prior to beginning any reversing manoeuvre. This will provide better prior warning of an approaching road user.",
    4: "You will lessen the chance of coming into conflict with another road user by checking all around prior to and during any manoeuvre. While you did tend to check all around, you tended to do this after you had commenced the manoeuvre.",
    5: "Your observations prior to parking or manoeuvring are poor, leaving you at risk of coming into conflict with another road user. Always look all around prior to any manoeuvre and be especially aware of blind spots. Consider reversing into parking bays as this makes for a safer exit when you need to move off.",
};

const controlSuggestions: RatingSuggestions = {
    1: "Your vehicle control while manoeuvring and parking is smooth and controlled.",
    2: "Your vehicle control is good, however, consider slowing the pace down a little more so that you have more time to observe the environment around you.",
    3: "Your control is good, however, consider approaching road users prior to undertaking any manoeuvre and wait for them to pass, assume they will walk into the path of your vehicle.",
    4: "Your control when manoeuvring is inconsistent, tending to be rushed at times with little consideration towards the presence of other road users.",
    5: "Your parking and manoeuvring control lacks control. You need to control your vehicle smoothly, no faster than a slow walking pace. This will not only give you more time to observe, accuracy will improve too.",

};

const accuracySuggestions: RatingSuggestions = {
    1: "You demonstrated a good degree of accuracy when parking or manoeuvring.",
    2: "You demonstrated a good degree of accuracy when parking or manoeuvring, however, be prepared to alter the position of your vehicle or find an alternative space if the one chosen is not suitable.",
    3: "While your accuracy when manoeuvring is relatively good, don't compromise safety by selecting a location that might place you or others in danger.",
    4: "You were inconsistent in your approach to parking and manoeuvring. You will improve accuracy and confidence by practicing such manoeuvres more often.",
    5: "The accuracy of your manoeuvring and parking is poor. You will be able to control your vehicle more accurately by slowing down, assessing the environment prior to manoeuvring, and paying attention to detail, particularly with regard to your proximity to other vehicles.",
};

const chargingSuggestions: RatingSuggestions = {
    1: "You show an excellent knowledge of where, when, and how to charge your vehicle.",
    2: "You demonstrated a good knowledge of where, when, and how to charge your vehicle. Be aware that conditions such as temperature can affect this.",
    3: "Your knowledge of how, when, and where to charge requires further work. Consult your vehicle's handbook for advice on how to get the best out of your battery in terms of charging. Consult the internet to make sure you know where all the eligible charging points are on your journey if traveling in an area you are unfamiliar with.",
    4: "Your knowledge of where, when, and how to charge your vehicle requires more work. Consult your vehicle's handbook for advice on how to get the best out of your battery in terms of charging. Consult the internet to make sure you know where all the eligible charging points are on your journey if traveling in an area you are unfamiliar with.",
    5: "Your knowledge of where, when, and how to charge your vehicle requires significantly more work. Consult your vehicle's handbook for advice on how to get the best out of your battery in terms of charging. Consult the internet to make sure you know where all the eligible charging points are on your journey if traveling in an area you are unfamiliar with."
};

const parkingAndManoeuvringSuggestions: SectionSuggested<"parkingAndManoeuvring"> = {
    observations: observationsSuggestions,
    control: controlSuggestions,
    accuracy: accuracySuggestions,
    charging: chargingSuggestions,
};

export const suggestedComments = {
    concentration: concentrationSuggestions,
    observation: observationSuggestions,
    anticipation: anticipationSuggestions,
    space: spaceSuggestions,
    time: timeSuggestions,
    parkingAndManoeuvring: parkingAndManoeuvringSuggestions,
} satisfies SuggestedComments<keyof OnRoadAssessment>;

export const largeVehicleUseOfMirrorsSuggestions: RatingSuggestions = {
    1: "Your use of the external mirrors is highly effective, demonstrating that you are aware of your nearside hazards turning left and offside hazards when turning right and when negotiating roundabouts. This enables you to make appropriate decisions in the direction and course of your vehicle.",
    2: "While your use of external mirrors is good, consider checking them slightly sooner so that you have time to consider how your intended manoeuvre might affect other road users, especially prior to braking or turning.",
    3: "Your use of the external mirrors is generally good, however, consider using the left mirror more frequently when negotiating a left-hand bend to optimise the angle of view behind and likewise, the right mirror for right-hand bends. This will enable you to take account of other road users travelling immediately behind you.",
    4: "You appear to have an inconsistent approach to the use of the external mirrors. It's very important that you are aware at all times what is going on around you, especially before you make any form of manoeuvre, change of direction or change of speed, especially braking.",
    5: "Your use of both external mirrors is poor. In order to make a safe manoeuvre you must be aware of what is happening around you. Assess what is behind you to ensure you make an informed decision of when to slow down, change lanes or make a turning."
};

export const largeVehicleDealingWithJunctionsSuggestions: RatingSuggestions = {
    1: "Your technique for dealing with all types of junctions is of a consistently good standard. You position effectively to gain full vision to the sides, negating the risk of the vehicle panels blocking your view. Your management of speed on approach allows time for effective observations, correct lane positioning and a planned approach throughout.",
    2: "Your techniques for dealing with all types of road junctions is generally good, however, reducing your speed earlier on approach will allow more time to assess the environment and anticipate the need to stop, or, adopt a slow-and-flow approach. This technique will allow more time for effective observations and better decision making, especially when dealing with an angled junction whereby you need to position the vehicle in such a way to see both directions clearly.",
    3: "Your approach and techniques for typical junctions is generally good, however, your approach and lane discipline at roundabouts is inconsistent. It's important to approach at a speed that allows time to assess a gap, adopt the correct lane and position on approach and throughout the roundabout and avoid harsh braking/acceleration.",
    4: "Your approach to junctions is somewhat haphazard. Your speed on approach at times is too fast and your observations appear to be passive, sometimes rushed and with inconsistent consideration to position the vehicle to gain maximum visibility. Lane discipline entering and leaving junctions could be improved.",
    5: "Your approach to junctions is inconsistent insofar as management of speed on approach, leading to harsh braking, rushed observations and poor decision making and in some instances, incorrect lane positioning. No consideration to positioning the vehicle to afford maximum visibility in both directions."
};

export const largeVehicleObservationsSuggestions: RatingSuggestions = {
    1: "You demonstrated good all round observations before manoeuvring or parking and positioned well before the manoeuvre.",
    2: "Your all round observations are generally good, however, consider making more frequent external checks rather than relying on one mirror alone. This will provide better prior warning of an approaching road user who might not be visible to you.",
    3: "Your observations while manoeuvring are fairly good, however, take time to check all around your vehicle prior to beginning any reversing manoeuvre. This will provide better prior warning of an approaching road user.  If making deliveries, it would be beneficial to position the vehicle ready for the next delivery as opposed to reversing and changing direction after the delivery has been made.  This reduces the risk of being boxed in by other motorists resulting in a difficult manoeuvre. ",
    4: "You will lessen the chance of coming into conflict with another road user by checking all around prior to and during any manoeuvre. While you did tend to check all around, you tended to do this after you had commenced the manoeuvre, give yourself more time to effectively look.",
    5: "Your observations prior to parking or manoeuvring are poor, leaving you at risk of coming into conflict with another road user. Always look all around prior to any manoeuvre and be especially aware of blind spots. Consider reversing into parking bays as this makes for a safer exit when you need to move off.",
};

export const ProductCategoriesWithOnRoadReports = [ProductCategoryEnum.StandardOnRoad, ProductCategoryEnum.OnRoadWithCpc, ProductCategoryEnum.OnRoadTrailerTowing, ProductCategoryEnum.StandardBD, ProductCategoryEnum.OpenreachStandard, ProductCategoryEnum.OpenreachTrailerTowing, ProductCategoryEnum.MultiDropVehicle] as const;
export type ProductCategoryWithOnRoadReport = typeof ProductCategoriesWithOnRoadReports[number];
export const ProductCategoryToReportTypeMap: Record<ProductCategoryWithOnRoadReport, OnRoadReportType> = {
    [ProductCategoryEnum.MultiDropVehicle]: OnRoadReportType.MultiDropVehicle,
    [ProductCategoryEnum.StandardOnRoad]: OnRoadReportType.OnRoad,
    [ProductCategoryEnum.OnRoadWithCpc]: OnRoadReportType.OnRoad,
    [ProductCategoryEnum.OnRoadTrailerTowing]: OnRoadReportType.OnRoadTrailerTowing,
    [ProductCategoryEnum.OpenreachStandard]: OnRoadReportType.OpenreachStandard,
    [ProductCategoryEnum.OpenreachTrailerTowing]: OnRoadReportType.OpenreachTrailerTowing,
    [ProductCategoryEnum.StandardBD]: OnRoadReportType.OnRoad,
};

export const OnRoadReportNames: Record<OnRoadReportType, string> = {
    [OnRoadReportType.OnRoad]: "On Road Standard",
    [OnRoadReportType.OnRoadTrailerTowing]: "On Road With Trailer Towing",
    [OnRoadReportType.OpenreachStandard]: "Openreach Standard",
    [OnRoadReportType.OpenreachTrailerTowing]: "Openreach With Trailer Towing",
    [OnRoadReportType.MultiDropVehicle]: "Multi Drop Vehicle",
};
/* eslint-enable max-len */
