import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { Button, Grid, Message } from "semantic-ui-react";
import { ApplicationState } from "@booking/applicationState";
import { useDispatch, useSelector } from "react-redux";
import { pathSelector } from "@booking/landing/selectors";
import { reserveSeat } from "@booking/seat/actions";
import { Booking } from "@booking/bookings/models";
import { useState } from "react";
import { BookingEventInstanceApi } from "@booking/eventInstance/bookingEventInstanceApi";
import { EventInstance } from "@common/crud/eventInstance";
import { isUserClientAdvisor } from "@common/crud/common/selectors";

interface PendingEventInstanceReservationProps {
    booking: Booking;
}

export const PendingEventInstanceReservation: React.FC<PendingEventInstanceReservationProps> = ({ booking }) => {
    const [t] = useTranslation("Landing");
    const dispatch = useDispatch();
    const path = useSelector(pathSelector);
    const isCA = useSelector(isUserClientAdvisor);
    const includeFullyBookedCourses = useSelector((state: ApplicationState) => state.includeFullyBookedCourses);
    const [ eventInstance, setEventInstance] = useState<EventInstance>(undefined);
    React.useEffect(() => {
        async function retrieveEventInstanceDetail(id: string) {
            const api = new BookingEventInstanceApi(isCA ? booking.id : "");
            const result = await api.getEventInstanceDetailsForBooking({ id });
            setEventInstance(result);
        }
        if (!eventInstance) {
            retrieveEventInstanceDetail(booking.reservedEventInstanceId);
        }
    }, [booking.reservedEventInstanceId, eventInstance, booking.id, isCA]);

    const onReservationRequest = React.useCallback(() => {
        dispatch(reserveSeat({ eventInstanceId: booking.reservedEventInstanceId, includeFullyBookedCourses, dayPart: 0 }, path));
    }, [path, includeFullyBookedCourses, booking.reservedEventInstanceId, dispatch]);

    const venueName = eventInstance?.venueName;
    const date = dateString(eventInstance?.startDate, DateFormat.LongNoYear);
    const startTime = eventInstance && eventInstance?.startTime?.format(DateFormat.Time, { trim: false });

    return (
        <Message className="rebook-message">
            <Message.Header>{t("SELECTED_COURSE")}</Message.Header>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <p>
                            <Trans i18nKey="Landing:PENDING_BOOKING_INFORMATION" values={{ venueName, date, startTime }}>
                                You are reserving {{ venueName }} on {{ date }} at {{ startTime }}, click button to continue
                                with this selection or select a different course from the list below
                            </Trans>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={6} className="right-align">
                        <Button onClick={onReservationRequest}>
                            {t("BOOK_THIS_COURSE")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Message>
    );
};
