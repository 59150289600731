/* eslint-disable max-lines */
import { Modal } from "@common/components/Modal";
import * as React from "react";
import { Dropdown, DropdownItemProps, Button, DropdownProps, Container, Divider } from "semantic-ui-react";
import { HideEventsAction } from "./HideEventsAction";
import { SimpleAction } from "./SimpleAction";
import {
    publishMultipleEventInstances,
    bulkUpdateReasonForHidingEvents,
    bulkMakeAvailableToTrainers
} from "../../actions";
import { PublishConfirmationText } from "../PublishConfirmationText";
import { BulkEmailAction } from "./BulkEmailAction";
import { CancelMultipleEventInstances } from "./CancelMultipleEventInstances";
import { Area, SearchOptions } from "../../model";
import { RemoveTrainersAction } from "./RemoveTrainersAction";
import { RemoveTrainerAction } from "./RemoveTrainerAction";
import { TrainerRemovalWarningComponent } from "@common/crud/eventInstance/components/TrainerRemovalWarningComponent";
import { ConfirmButton } from "@common/components";
import { ChangeOpenPlacesAction } from "./ChangeOpenPlacesAction";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { BulkSwapTrainerAction } from "./BulkSwapTrainerAction";
import { BulkSmsAction } from "./BulkSmsAction";
import { useSelector } from "react-redux";
import { BusinessLineType, trainerScheduleBusinessLineType } from "@common/redux-helpers";
import { appSettingsSelector } from "@common/appSettings/selectors";

export interface ActionModalProps {
    area: Area;
    selectedEventInstanceIds: string[];
    clearSelection: () => void;
    isOnsiteCourse?: boolean;
    hasMonitor?: boolean;
    hasExternalAssessmentDue?: boolean;
    searchOptions?: SearchOptions;
}

export const enum Actions {
    Publish,
    HideEvents,
    UnhideEvents,
    SendEmail,
    RemoveTrainers,
    CancelEvents,
    RemoveTrainer,
    SwapTrainer,
    NoneSelected,
    ChangeNumberOfDigitalPlaces,
    ChangeNumberOfClassroomPlaces,
    MakeAvailableToTrainers,
    MakeUnavailableToTrainers,
    SendSms
}

enum ActionNames {
    "publish" = 0,
    "hide Courses" = 1,
    "un-hide Courses" = 2,
    "send email" = 3,
    "remove trainers" = 4,
    "cancel courses" = 5,
    "remove trainer" = 6,
    "quick swap" = 7,
    "unknown" = 8,
    "Change number of digital places" = 9,
    "change number of classroom places" = 10,
    "make available to trainers" = 11,
    "make unavailable to trainers" = 12,
    "send sms" = 13
}

const Options: { availableInArea: Area[]; option: DropdownItemProps }[] = [
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Publish Courses",
            value: Actions.Publish
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Hide Courses",
            value: Actions.HideEvents
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Un-hide Courses",
            value: Actions.UnhideEvents
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Cancel Courses",
            value: Actions.CancelEvents
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Send email",
            value: Actions.SendEmail
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Remove Trainers",
            value: Actions.RemoveTrainers
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Make Available to Trainers",
            value: Actions.MakeAvailableToTrainers
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Make Unavailable to Trainers",
            value: Actions.MakeUnavailableToTrainers
        }
    },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Please select", value: Actions.NoneSelected } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Publishing", value: Actions.Publish } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Remove trainer", value: Actions.RemoveTrainer } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Quick swap", value: Actions.SwapTrainer } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Hide Courses", value: Actions.HideEvents } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Un-hide Courses", value: Actions.UnhideEvents } },
    { availableInArea: [Area.AdminEventManagementTrainerEventInstance], option: { text: "Cancel Courses", value: Actions.CancelEvents } },
    {
        availableInArea: [Area.AdminEventManagementTrainerEventInstance],
        option: { text: "Make Available to Trainers", value: Actions.MakeAvailableToTrainers }
    },
    {
        availableInArea: [Area.AdminEventManagementTrainerEventInstance],
        option: { text: "Make Unavailable to Trainers", value: Actions.MakeUnavailableToTrainers }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Change number of digital places",
            value: Actions.ChangeNumberOfDigitalPlaces
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Change number of classroom places",
            value: Actions.ChangeNumberOfClassroomPlaces
        }
    },
    {
        availableInArea: [Area.AdminEventManagementEventInstance, Area.AdminEventManagementCorporateOrganisation],
        option: {
            text: "Send SMS",
            value: Actions.SendSms
        }
    },
];

export const ActionModal: React.FC<ActionModalProps> = ({
    selectedEventInstanceIds, clearSelection, area, isOnsiteCourse, hasMonitor, hasExternalAssessmentDue, searchOptions
}) => {
    const [chosenAction, setChosenAction] = React.useState<Actions>(
        ((area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementCorporateOrganisation)
            ? Actions.Publish
            : Actions.NoneSelected));
    const [openActionModal, setOpenActionModal] = React.useState<boolean>(false);
    const [triggerDispatchAction, setTriggerDispatchAction] = React.useState<boolean>(false);
    const [actionComplete, setActionComplete] = React.useState<boolean>(false);
    const [disableContinue, setDisableContinue] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);

    const config = useSelector(appSettingsSelector);
    const scheduleBusinessLineType = useSelector(trainerScheduleBusinessLineType);
    const allowSubcontracting = React.useMemo(() => {
        switch (scheduleBusinessLineType) {
            case BusinessLineType.PoliceAndCourt: return config.featureSettings.policeAndCourtSubcontracting;
            case BusinessLineType.Corporate: return config.featureSettings.commercialSubcontracting;
            case BusinessLineType.Construction: return config.featureSettings.constructionSubcontracting;
            default: return false;
        }
    }, [
        scheduleBusinessLineType,
        config.featureSettings.policeAndCourtSubcontracting,
        config.featureSettings.commercialSubcontracting,
        config.featureSettings.constructionSubcontracting]
    );

    const setAction = React.useCallback((e: any, data: DropdownProps) => {
        setChosenAction(data.value as Actions);
        setDisableContinue(false);
    }, []);

    const openCloseModal = React.useCallback(() => setOpenActionModal(!openActionModal), [openActionModal]);
    const triggerAction = React.useCallback(() => {
        setLoading(true);
        setTriggerDispatchAction(true);
    }, []);

    React.useEffect(() => {
        if (actionComplete === true) {
            setTriggerDispatchAction(false);
            setActionComplete(false);
            setChosenAction(((area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementCorporateOrganisation)
                ? Actions.Publish
                : Actions.NoneSelected));
            clearSelection();
            setLoading(false);
            openCloseModal();
        }
    }, [actionComplete, area, clearSelection, openCloseModal]);

    React.useEffect(() => {
        if (!triggerDispatchAction) {
            setLoading(false);
        }
    }, [triggerDispatchAction]);

    const unhideActionText = React.useCallback(() => <span>All selected courses will be unhidded. Are you sure?</span>, []);
    const makeAvailableToTrainersText = React.useCallback(() => <span>All selected courses will be available to trainers. Are you sure?</span>, []);
    const makeUnavailableToTrainersText = React.useCallback(() => <span>All selected courses will be unavailable to trainers. Are you sure?</span>, []);
    const warnForTrainerRemoval = React.useCallback(() => {
        return <TrainerRemovalWarningComponent hasMonitor={hasMonitor} hasExternalAssessmentDue={hasExternalAssessmentDue} />;
    }, [hasExternalAssessmentDue, hasMonitor]);

    return (
        <Container>
            <Button onClick={openCloseModal}>Action</Button>

            <Modal size="small" open={openActionModal}>
                <Modal.Header>What action would you like to take?</Modal.Header>
                <Modal.Content>
                    <Dropdown
                        selection
                        placeholder={"Action"}
                        options={Options.filter(x => x.availableInArea.includes(area)).map(x => x.option)}
                        onChange={setAction}
                        value={chosenAction}
                    />
                    <Divider />
                    {
                        chosenAction === Actions.Publish &&
                        <SimpleAction
                            triggerDispatchAction={triggerDispatchAction}
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            action={publishMultipleEventInstances(selectedEventInstanceIds)}
                            children={PublishConfirmationText}
                        />
                    }
                    {
                        chosenAction === Actions.HideEvents &&
                        <HideEventsAction
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            triggerDispatchAction={triggerDispatchAction}
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                        />
                    }
                    {
                        chosenAction === Actions.UnhideEvents &&
                        <SimpleAction
                            triggerDispatchAction={triggerDispatchAction}
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            action={bulkUpdateReasonForHidingEvents(selectedEventInstanceIds, "")}
                            children={unhideActionText}
                        />
                    }
                    {
                        chosenAction === Actions.RemoveTrainers &&
                        <RemoveTrainersAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                            children={warnForTrainerRemoval}
                        />
                    }
                    {
                        chosenAction === Actions.RemoveTrainer &&
                        <RemoveTrainerAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                            children={warnForTrainerRemoval}
                        />
                    }
                    {
                        chosenAction === Actions.CancelEvents &&
                        <CancelMultipleEventInstances
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            isOnsiteCourse={isOnsiteCourse}
                            setDisableContinue={setDisableContinue}
                        />
                    }
                    {
                        chosenAction === Actions.SendEmail &&
                        <BulkEmailAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                        />
                    }
                    {
                        chosenAction === Actions.SwapTrainer &&
                        <BulkSwapTrainerAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                            children={warnForTrainerRemoval}
                            setDisableContinue={setDisableContinue}
                            searchOptions={searchOptions}
                            allowSubcontracting={allowSubcontracting}
                        />
                    }
                    {
                        chosenAction === Actions.ChangeNumberOfClassroomPlaces &&
                        <ChangeOpenPlacesAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                            deliveryType={DeliveryTypeEnum.Onsite}
                            setDisableContinue={setDisableContinue}
                        />
                    }
                    {
                        chosenAction === Actions.ChangeNumberOfDigitalPlaces &&
                        <ChangeOpenPlacesAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                            deliveryType={DeliveryTypeEnum.Digital}
                            setDisableContinue={setDisableContinue}
                        />
                    }
                    {
                        chosenAction === Actions.MakeAvailableToTrainers &&
                        <SimpleAction
                            triggerDispatchAction={triggerDispatchAction}
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            action={bulkMakeAvailableToTrainers(selectedEventInstanceIds, true)}
                            children={makeAvailableToTrainersText}
                        />
                    }
                    {
                        chosenAction === Actions.MakeUnavailableToTrainers &&
                        <SimpleAction
                            triggerDispatchAction={triggerDispatchAction}
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            action={bulkMakeAvailableToTrainers(selectedEventInstanceIds, false)}
                            children={makeUnavailableToTrainersText}
                        />
                    }
                    {
                        chosenAction === Actions.SendSms &&
                        <BulkSmsAction
                            setActionComplete={setActionComplete}
                            actionComplete={actionComplete}
                            triggerDispatchAction={triggerDispatchAction}
                            selectedEventInstanceIds={selectedEventInstanceIds}
                            setTriggerDispatchAction={setTriggerDispatchAction}
                        />
                    }

                </Modal.Content>
                <Modal.Actions>
                    <Button loading={loading} disabled={loading} className="cancel-action" onClick={openCloseModal}>Cancel</Button>
                    <ConfirmButton
                        content={<Modal.Content><h3>{`You will perform the action ${ActionNames[chosenAction]} 
                        on ${selectedEventInstanceIds.length} course${selectedEventInstanceIds.length > 1 ? "s" : ""}`}</h3></Modal.Content>}
                        header="Confirm"
                        onConfirm={triggerAction}
                        disabled={disableContinue || chosenAction === Actions.NoneSelected || loading}
                        showToast={chosenAction !== Actions.SendSms && chosenAction !== Actions.SendEmail}
                        toastText="Bulk action started"
                        loading={loading}
                    >
                        Continue
                    </ConfirmButton>
                </Modal.Actions>

            </Modal>
        </Container>
    );
};
