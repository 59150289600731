import * as React from "react";
import { Modal } from "@common/components";
import { Button, Grid, TextArea } from "semantic-ui-react";
import { isNullOrUndefined } from "@common/global/CommonHelpers";

interface FeeNoteModalProps {
    feeNote: string;
    toggle: boolean;
    setToggle: React.Dispatch<boolean>;
    updateTrainerFeeNote: (feeNote: string) => void;
}

export const FeeNoteModal: React.FC<FeeNoteModalProps> = ({ feeNote, toggle, setToggle, updateTrainerFeeNote }) => {

    const [feeNoteText, setFeeNoteText] = React.useState("");

    React.useEffect(() => { setFeeNoteText(feeNote); }, [feeNote]);

    function onToggleModal() {
        setToggle(!toggle);
    }

    function onFeeNoteChange(_: any, data: any) {
        setFeeNoteText(data.value);
    }

    function onUpdateTrainerFeeNote() {
        updateTrainerFeeNote(feeNoteText);
    }

    const styles = {
        width: "100%"
    };

    const buttonLabel = isNullOrUndefined(feeNote) || feeNote.length === 0 ? "Add" : "Update";

    return (
        <Modal open={toggle} size="tiny">
            <Modal.Header>
                Add Fee Note
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width="16">
                        <TextArea
                            style={styles}
                            rows={4}
                            value={feeNoteText}
                            onChange={onFeeNoteChange}
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" className="cancel-action" onClick={onToggleModal} />
                <Button content={buttonLabel} onClick={onUpdateTrainerFeeNote} />
            </Modal.Actions>
        </Modal>
    );
};
