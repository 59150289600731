import { isUserClientAdvisor } from "@common/crud/common/selectors";
import * as React from "react";
import { useSelector } from "react-redux";
import { GenesysApi } from "../genesysApi";

export function useResumeCallRecordingOnExit(correlationId: string) {

    const isClientAdvisor = useSelector(isUserClientAdvisor);

    // Restart call recording if we leave the page
    React.useEffect(() => {
        function processUnLoadPage() {
            if (correlationId !== null && isClientAdvisor) {
                const api = new GenesysApi();
                api.resumeCallRecording(correlationId);
            }
        }

        // In order to trap the user navigating back to the admin app we need to listen for the the page being unloaded
        window.addEventListener("beforeunload", processUnLoadPage);

        return function cleanup() {
            window.removeEventListener("beforeunload", processUnLoadPage);
            processUnLoadPage();
        };
    }, [correlationId, isClientAdvisor]);
}
