import * as React from "react";
import { useSelector } from "react-redux";
import { EventInstanceMobileComponent } from "./EventInstanceMobile";
import { pathSelector } from "../selectors";
import { useTranslation } from "react-i18next";
import { AllEventInstancesProps, useScrollToNewElementRef } from "../model";
import { Message } from "semantic-ui-react";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { GroupEventInstanceMobileComponent } from "@booking/landing/components/GroupEventInstanceMobile";

export const AllEventInstancesMobile: React.FC<AllEventInstancesProps> = ({
    eventInstances,
    eventInstanceGroups,
    moreResults,
    loadMore,
    noCoursesMessage,
    pageSize }) => {
    const [t] = useTranslation("AllEventInstances");
    const path = useSelector(pathSelector);
    const firstNewElement = useScrollToNewElementRef([eventInstances?.length]);

    if (!eventInstances?.length && !eventInstanceGroups?.length) {
        return <p className="no-instances">{noCoursesMessage}</p>;
    }

    const indexWithAdditionalMargin = eventInstances.length - pageSize;

    const showFlexiPayMessage = eventInstances && eventInstances.length > 0 && eventInstances[0].priceClearedDueToFlexiPay;
    const coursesAreDigital = eventInstances && eventInstances.length > 0 && eventInstances[0].eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    const isDdrs = eventInstanceGroups?.length > 0;
    return (
        <>
            {showFlexiPayMessage &&
                <Message info size="tiny">
                    {coursesAreDigital ? t("FLEXI_PAY_USED_DIGITAL") : t("FLEXI_PAY_USED_CLASSROOM")}
                </Message>
            }
            {!isDdrs && eventInstances.map((eventInstance, index) => (
                <EventInstanceMobileComponent
                    eventInstance={eventInstance}
                    key={eventInstance.id}
                    path={path}
                    additionalMargin={index !== 0 && index === indexWithAdditionalMargin}
                    firstNewElement={index !== 0 && index === indexWithAdditionalMargin ? firstNewElement : undefined}
                />))
            }
            {isDdrs && eventInstanceGroups.map((groupEventInstance, index) => (
                <GroupEventInstanceMobileComponent
                    groupSearchResultRow={groupEventInstance}
                    key={groupEventInstance.id}
                    path={path}
                    additionalMargin={index !== 0 && index === indexWithAdditionalMargin}
                    firstNewElement={index !== 0 && index === indexWithAdditionalMargin ? firstNewElement : undefined}
                />))
            }
            {moreResults &&
                <button type="button" className="load-more-button" onClick={loadMore}>
                    {t("LOAD_MORE")}
                </button>
            }
        </>

    );
};
