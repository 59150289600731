import * as React from "react";
import { IMask, IMaskInput } from "react-imask";
import { Label } from "semantic-ui-react";

interface DateInputProps {
    label: string;
    value: string;
    onChange: (value: string, valid: boolean) => void;
    required?: boolean;
    showErrors?: boolean;
}

export const timeIsValid = (time: string) => {
    const hoursPart = time?.substring(0, 2);
    const minutesPart = time?.substring(3, 5);
    const hours = +hoursPart;
    const minutes = +minutesPart;
    return (
        time?.length === 5 &&
        !Number.isNaN(hours) &&
        hours >= 0 &&
        hours < 24 &&
        !Number.isNaN(minutes) &&
        minutes >= 0 &&
        minutes < 60
    );
};

export const TimeInput: React.FC<DateInputProps> = (props) => {
    const { label, value, onChange, showErrors } = props;

    const timeInputFormat = "HH:MM";

    const [touched, setTouched] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);

    const onTimeChange = React.useCallback(
        (maskedInputValue: any) => {
            setDirty(true);
            if (onChange) {
                onChange(maskedInputValue, timeIsValid(maskedInputValue));
            }
        },
        [onChange]
    );

    const onBlur = React.useCallback(() => {
        setTouched(dirty);
    }, [dirty]);

    const showError = React.useMemo(
        () => !timeIsValid(value) && ((touched && dirty) || showErrors),
        [dirty, showErrors, touched, value]
    );

    return (
        <div className="field-wrapper">
            <div className="field">
                <label>{label}</label>
                <div className="ui input">
                    <IMaskInput
                        type="tel"
                        value={props.value}
                        onAccept={onTimeChange}
                        placeholder={timeInputFormat}
                        overwrite={true}
                        lazy={false}
                        onBlur={onBlur}
                        mask={timeInputFormat}
                        blocks={{
                            HH: {
                                mask: IMask.MaskedRange,
                                from: 0,
                                to: 23,
                                maxLength: 2,
                                placeholderChar: "H",
                            },
                            MM: {
                                mask: IMask.MaskedRange,
                                from: 0,
                                to: 59,
                                maxLength: 2,
                                placeholderChar: "M",
                            },
                        }}
                    />
                </div>
            </div>
            {showError && (
                <Label basic color="red" pointing={"above"}>
                    {"Please enter a valid date"}
                </Label>
            )}
        </div>
    );
};
