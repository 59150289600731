import axios from "axios";

import { MultiPartSeatReservation, Seat, SeatReservation } from "./model";
import { SkipPaymentAndBookResult } from "@common/payments/model";

export class SeatApi {

    private readonly apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/booking/${bookingId}/seat` : "/api/seat";
    }

    public async reserveSeat(seatReservation: SeatReservation): Promise<{ seats: Seat[]; isExpired: boolean }> {

        const response = await axios.post(this.apiUrl, seatReservation, {});
        const data = response.data as { seats: Seat[]; isExpired: boolean };

        return data;
    }

    public async reserveMultiPartSeats(multiPartSeatReservation: MultiPartSeatReservation): Promise<{ seats: Seat[]; isExpired: boolean }> {
        const url = this.apiUrl + "/multi-day-part";
        const response = await axios.post(url , multiPartSeatReservation, {});
        const data = response.data as { seats: Seat[]; isExpired: boolean };

        return data;
    }

    public async getSeat(eventInstanceId: string): Promise<{ seats: Seat[]; isExpired: boolean }> {
        const response = await axios.get(`${this.apiUrl}/${eventInstanceId}`);
        const data = response.data as { seats: Seat[]; isExpired: boolean };

        return data;
    }

    public async skipPaymentAndBook(seats: Seat[], isWaivingRebookingFee?: boolean, includeFullyBookedCourses?: boolean
    ): Promise<SkipPaymentAndBookResult> {
        const response = await axios.post(`${this.apiUrl}/bookwithoutpayment`,{ isWaivingRebookingFee, includeFullyBookedCourses, seats });
        return response.data as SkipPaymentAndBookResult;
    }
}
