import { EventType } from "@common/crud/eventType";
import {
    AllowedProductCategories,
    EventTypeCreateEditModel,
    ProductWorkflowByCategory,
    WorkflowTypeEnum,
} from "@common/crud/eventType/model";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate } from "@common/global/CommonHelpers";

export const showCourseFees = (eventType: EventType | EventTypeCreateEditModel) => {
    const isDors = eventType.workflowType === WorkflowTypeEnum.Dors;
    const isDataSelectedForCourseFees = eventType.workflowType && eventType.deliveryType && eventType.bookingType;
    return (isDors && eventType.deliveryType === SchemeDeliveryTypeEnum.Digital ||
        eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) ||
        (isEventTypeWorkflowCorporate(eventType) || isEventTypeWorkflowConstruction(eventType) && isDataSelectedForCourseFees);
};

export const ProductCategoryMatchesWorkflowType = (productCategory: AllowedProductCategories, workflowType: WorkflowTypeEnum) =>
    ProductWorkflowByCategory[productCategory] === workflowType;

export function includes<T extends U, U>(coll: readonly T[], el: U): el is T {
    return coll.includes(el as T);
};
