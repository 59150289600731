import * as React from "react";
import { Button, Container } from "semantic-ui-react";
import { ShowOrdersBy } from "../model";

interface OrderMenuProps {
    by: ShowOrdersBy;
    changeShowBy: (by: ShowOrdersBy) => void;
}

export const OrderMenu = (props: OrderMenuProps) => {
    const { by, changeShowBy } = props;

    const showByOrders = React.useCallback(
        () => changeShowBy(ShowOrdersBy.Order),
        [changeShowBy]
    );
    const showByEventInstance = React.useCallback(
        () => changeShowBy(ShowOrdersBy.EventInstance),
        [changeShowBy]
    );

    return (
        <Container>
            <div className="button-search-menu">
                <Button
                    onClick={showByOrders}
                    content="Orders"
                    className={`button-search-menu-item${
                        by === ShowOrdersBy.Order ? " current" : ""
                    }`}
                />
                <Button
                    onClick={showByEventInstance}
                    content="Courses"
                    className={`button-search-menu-item${
                        by === ShowOrdersBy.EventInstance ? " current" : ""
                    }`}
                />
            </div>
        </Container>
    );
};
