/* eslint-disable max-lines */
import { EventType, WorkflowTypeEnum } from "@common/crud/eventType/model";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { Button, Container, Grid } from "semantic-ui-react";
import { DigitalPlanningCalendar } from "./DigitalPlanningCalendar";
import { loadTrainerAvailabilityById } from "@common/availabilityTrainer/actions";
import { loadDigitalTrainersForDdrs, loadDigitalTrainersForDors } from "@common/crud/trainer/actions";
import { DigitalPlanningSession, DigitalPlanningSessionsState, EventInstance,
    SESSION_TWO_START_HOUR, SESSION_THREE_START_HOUR, SESSION_FOUR_START_HOUR } from "../../model";
import { toast } from "@common/toasts";
import { digitalPlanningSessionsSelector, eventInstancesSelector } from "../../selectors";
import { loadAllDigitalPlaningProgress, loadEventInstances, saveDigitalPlaningProgress } from "../../actions";
import { v4 } from "uuid";
import { DigitalPlanningEditMessage, DigitalPlanningMessageState } from "./DigitalPlanningEditMessage";
import { ConfirmButton } from "@common/components";
import { AllAvailabilityCalendar } from "./AllAvailabilityCalendar";
import { loadDdrsDigitalEventTypes, loadDorsDigitalEventTypes } from "@common/crud/eventType/actions";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { GetDigitallyPlannedEventsForMonth, GetDigitalPlanningForMonth } from "./DigitalPlanningHelpers";
import { CountryEnum } from "@common/crud/organisation/model";
import { trainersSelector } from "@common/crud/trainer/selectors";
import { Trainer } from "@common/crud/trainer";
import { TrainerAvailabilityState } from "@common/availabilityTrainer/model";
import { EventInstanceApi } from "@common/crud/eventInstance";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";
import { DigitalPlanningHeader } from "./DigitalPlanningHeader";
import { DigitalPlanningSidebar } from "./DigitalPlanningSidebar";

export interface DigitalPlanningFilter {
    month?: moment.Moment;
    trainerId?: string;
    originalMonth?: moment.Moment;
}

interface DigitalPlanningBaseProps {
    eventTypeCategory: EventTypeCategory;
}

export const DigitalPlanningBase: React.FC<DigitalPlanningBaseProps> = ({ eventTypeCategory }) => {

    const selectedMonth = useSelector((state: any) => {
        const value = state.router.query.month;
        if (value) {
            return moment(value);
        }
        return null;
    });

    const originalMonth = useSelector((state: any) => {
        const value = state.router.query.originalMonth;
        if (value) {
            return moment(value);
        }
        return null;
    });

    const selectedTrainerId = useSelector((state: any) => {
        const value = state.router.query.trainerId;
        if (value) {
            return value;
        }
        return null;
    });

    const trainers = useSelector(trainersSelector);
    const selectedTrainer = trainers.find(t => t.id === selectedTrainerId);
    const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;

    const dispatch = useDispatch();

    const [selectedEventType, setSelectedEventType] = React.useState<EventType>();
    const [selectedEventsPerDay, setSelectedEventsPerDay] = React.useState<number>();
    const existingEvents = useSelector(eventInstancesSelector);
    const [digitallyPlannedEvents, setDigitallyPlannedEvents] = React.useState<EventInstance[]>([]);
    const [selectedPlanningSession, setSelectedPlanningSession] = React.useState<DigitalPlanningSession>(null);
    const [lastSelectedMonthString, setLastSelectedMonthString] = React.useState<string>(null);
    const [lastSelectedTrainer, setLastSelectedTrainer] = React.useState<string>(null);
    const [selectedDdrsCountry, setSelectedDdrsCountry] = React.useState<CountryEnum>(null);
    const [currentTrainer, setCurrentTrainer] = React.useState<Trainer>(null);
    const [existingAllocatedEventInstancesToSelectedTrainer, setExistingAllocatedEventInstancesToSelectedTrainer] = React.useState<EventInstance[]>(null);
    const [standbyCalendar, setStandbyCalendar] = React.useState<StandbyTrainersCalendarDay[]>([]);

    const selectedMonthString = selectedMonth?.toISOString() ?? null;

    React.useEffect(() => {
        dispatch(ddrs? loadDdrsDigitalEventTypes() : loadDorsDigitalEventTypes());
        dispatch(loadAllDigitalPlaningProgress());
    }, [ddrs, dispatch, selectedMonthString]);

    const allPlanningSessions = useSelector(digitalPlanningSessionsSelector);

    const planningSessionsForEventTypeCategory = React.useMemo(() => {
        return allPlanningSessions.filter(ps => ps.eventTypeCategory === eventTypeCategory);
    },[allPlanningSessions, eventTypeCategory]);

    const planningSessionsForOtherEventTypeCategory = React.useMemo(() => {
        return allPlanningSessions.filter(ps => ps.eventTypeCategory !== eventTypeCategory);
    },[allPlanningSessions, eventTypeCategory]);

    const digitallyPlannedEventsForOtherWorkflow = GetDigitalPlanningForMonth(planningSessionsForOtherEventTypeCategory, selectedMonth) ?
        GetDigitallyPlannedEventsForMonth(selectedMonth, planningSessionsForOtherEventTypeCategory, !ddrs):[];

    const defaultPlanningState: DigitalPlanningMessageState = {
        state: DigitalPlanningSessionsState.Unknown,
        courseCreationError: false,
        errorMessage: null
    };

    const [planningState, setPlanningState] = React.useState<DigitalPlanningMessageState>(defaultPlanningState);

    // Set the planning session from the selected month.
    React.useEffect(() => {
        if (selectedMonthString !== null) {
            const sessionForWorkflow = GetDigitalPlanningForMonth(planningSessionsForEventTypeCategory, selectedMonth);
            if (sessionForWorkflow) {
                setSelectedPlanningSession(sessionForWorkflow);
                const events = GetDigitallyPlannedEventsForMonth(selectedMonth, planningSessionsForEventTypeCategory, ddrs);
                setDigitallyPlannedEvents(events);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ddrs, planningSessionsForEventTypeCategory, selectedMonthString]);

    React.useEffect(() => {
        async function loadStandbyCalendar() {
            if (selectedMonthString !== lastSelectedMonthString || selectedTrainerId !== lastSelectedTrainer) {
                const api = new TrainerAvailabilityApi();
                const standby = await api.getTrainerStandbyWithIsoString(selectedMonthString, selectedTrainerId);
                setStandbyCalendar(standby.days);
            }
        };
        if (selectedMonthString) {
            loadStandbyCalendar();
        }
    }, [lastSelectedMonthString, lastSelectedTrainer, selectedMonthString, selectedTrainerId]);

    const saveProgress = React.useCallback(async (reloadTrainers: boolean, events?: EventInstance[]) => {
        if (selectedMonth === null || selectedTrainerId === null || digitallyPlannedEvents.length < 1) {
            return true;
        }

        const planningSession = planningSessionsForEventTypeCategory.find(s => s.month.month() === selectedMonth?.month())
        ?? { id: v4(), month: selectedMonth };
        planningSession.lastSelectedTrainerId = selectedTrainerId;
        planningSession.plannedDigitalEvents = events ?? digitallyPlannedEvents;
        planningSession.eventTypeCategory = eventTypeCategory;

        const selectedTrainerEvents = planningSession.plannedDigitalEvents.filter(e => e.trainerId === selectedTrainerId);
        let eventInstances: EventInstance[] = [];

        if (selectedTrainerEvents.length !== 0) {
            eventInstances = await new EventInstanceApi().getAllocatedTrainerEventInstances(selectedTrainerId, selectedTrainerEvents);
        }

        if (eventInstances.length !== 0) {
            toast.warning("Save could not be completed as the trainer has now been booked on to another course" +
                " at the same time as a course you have provisionally planned. " +
                "We have highlighted these courses in red, please review");
            setExistingAllocatedEventInstancesToSelectedTrainer(eventInstances);
            return false;
        }

        if (planningSession.state < DigitalPlanningSessionsState.Creating || !planningSession.state) {
            await dispatch(saveDigitalPlaningProgress(planningSession));
        }

        if (reloadTrainers) {
            const ddrsParams = { months: [selectedMonth], trainerId: selectedTrainerId, previousTrainerId: lastSelectedTrainer };
            const dorsParams = { month: selectedMonth, trainerId: selectedTrainerId, previousTrainerId: lastSelectedTrainer };
            await dispatch(ddrs ? loadDigitalTrainersForDdrs(ddrsParams) : loadDigitalTrainersForDors(dorsParams));
        }
        setExistingAllocatedEventInstancesToSelectedTrainer(null);
        return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [digitallyPlannedEvents, dispatch, selectedMonthString, selectedPlanningSession?.id, selectedTrainerId, ddrs,
        planningSessionsForEventTypeCategory, setExistingAllocatedEventInstancesToSelectedTrainer]);

    React.useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (selectedEventType && selectedEventType?.digitalEventTypeDetails?.digitalEventSuggestedStartTimesForSessions[selectedEventsPerDay] == null) {
            setSelectedEventsPerDay(null);
        }
    }, [selectedEventType?.digitalEventTypeDetails?.digitalEventSuggestedStartTimesForSessions, selectedEventsPerDay, selectedEventType]);

    const onFilterChange = React.useCallback(async (filter: DigitalPlanningFilter) => {
        // eslint-disable-next-line eqeqeq
        const isSaved = await saveProgress(filter.month == null);
        if (!isSaved) {
            return;
        }
        const trainerId = selectedMonth?.month() !== filter.month?.month() ? selectedPlanningSession?.lastSelectedTrainerId : selectedTrainerId;
        const combinedParameters: DigitalPlanningFilter = { month: selectedMonth, originalMonth, trainerId: trainerId ?? selectedTrainerId, ...filter };
        const trainerIdUrl = combinedParameters.trainerId ? `&trainerId=${combinedParameters.trainerId}` : "";
        const url = `?month=${combinedParameters.month?.toISOString()}&originalMonth=${combinedParameters.originalMonth?.toISOString()}${trainerIdUrl}`;
        dispatch(push(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveProgress, selectedPlanningSession?.lastSelectedTrainerId, selectedTrainerId, dispatch, selectedMonth,
        setExistingAllocatedEventInstancesToSelectedTrainer]);

    React.useEffect(() => {
        async function loadTrainers() {

            if (selectedMonthString !== lastSelectedMonthString || selectedTrainerId !== lastSelectedTrainer) {
                const ddrsParams = { months: [selectedMonth], trainerId: selectedTrainerId, previousTrainerId: lastSelectedTrainer };
                const dorsParams = { month: selectedMonth, trainerId: selectedTrainerId, previousTrainerId: lastSelectedTrainer };
                dispatch(ddrs ? loadDigitalTrainersForDdrs(ddrsParams) : loadDigitalTrainersForDors(dorsParams));
            }

            if (selectedMonthString !== lastSelectedMonthString) {
                const fromDate = ddrs ? selectedMonth?.clone().add(-1, "M") : selectedMonth?.clone();
                const toDate = ddrs ? selectedMonth?.clone().add(1, "M").endOf("month") : selectedMonth?.clone()?.endOf("month");
                dispatch(loadEventInstances({
                    options: {
                        fromDate,
                        toDate,
                        maxPageSize: -1,
                        workflowTypes: [WorkflowTypeEnum.Any]
                    }
                }));
                setLastSelectedMonthString(selectedMonthString);
            }

            if (selectedTrainerId !== lastSelectedTrainer) {
                dispatch(loadTrainerAvailabilityById({ trainerId: selectedTrainerId }));
                setLastSelectedTrainer(selectedTrainerId);
            }

        };
        if (selectedMonthString) {
            loadTrainers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthString, dispatch, selectedTrainerId, lastSelectedMonthString, ddrs]);

    const trainerAvailabilityModel =  useSelector((state: TrainerAvailabilityState) => state.trainerAvailability);
    const trainerAvailability = trainerAvailabilityModel.availableDates;
    const trainerExpiryDate = trainerAvailabilityModel.expiryDate;
    const showInputs = selectedMonth && selectedTrainerId;
    const showAllAvailabilityCalendar = selectedMonth && !showInputs;

    React.useEffect(() => {
        if (selectedMonth === null) {
            return;
        }

        const planningSession = GetDigitalPlanningForMonth(planningSessionsForEventTypeCategory, selectedMonth);
        const events = GetDigitallyPlannedEventsForMonth(selectedMonth, planningSessionsForEventTypeCategory, ddrs);
        if (planningSession) {
            setDigitallyPlannedEvents(events);
            setPlanningState(
                {
                    state: planningSession.state,
                    courseCreationError: planningSession.courseCreationFailed,
                    errorMessage: planningSession.errorMessage
                });
        } else {
            setPlanningState(defaultPlanningState);
            setDigitallyPlannedEvents(events ?? []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ddrs, planningSessionsForEventTypeCategory, selectedMonthString ]);

    React.useEffect(() => {
        if (selectedTrainer) {
            setCurrentTrainer(selectedTrainer);
        }
    },[selectedTrainer]);

    const onSaveClicked = React.useCallback(() => saveProgress(true), [saveProgress]);

    const onClearClicked = React.useCallback(async () => {
        await saveProgress(true, [...digitallyPlannedEvents.filter(e => e.trainerId !== selectedTrainerId)]);
    }, [digitallyPlannedEvents, saveProgress, selectedTrainerId]);

    const getStartTimeForSession = (session: number) => {
        if (ddrs) {
            return selectedEventType?.digitalEventTypeDetails?.digitalEventTypeParts[1].suggestedStartTime;
        }

        const times = selectedEventType.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions[selectedEventsPerDay]
            .sort(((a, b) => a.asMilliseconds > b.asMilliseconds ? 1 : -1));

        switch (session) {
            case 1:
                return times.find(t => t.hours() < SESSION_TWO_START_HOUR);
            case 2:
                return times.find(t => t.hours() >= SESSION_TWO_START_HOUR && t.hours() < SESSION_THREE_START_HOUR);
            case 3:
                return times.find(t => t.hours() >= SESSION_THREE_START_HOUR && t.hours() < SESSION_FOUR_START_HOUR);
            case 4:
                return times.find(t => t.hours() >= SESSION_FOUR_START_HOUR);
            default:
                return null;
        }
    };

    const disableEditing = planningState.state > DigitalPlanningSessionsState.InProgress;

    const noEventsOrEditingDisabled = digitallyPlannedEvents.length < 1 || disableEditing;
    return (
        <Container>
            <Grid className="ui form filter-font">
                <DigitalPlanningHeader
                    currentTrainer={currentTrainer}
                    eventTypeCategory={eventTypeCategory}
                    onFilterChange={onFilterChange}
                    originalMonth={originalMonth}
                    selectedMonth={selectedMonth}
                    selectedPlanningSession={selectedPlanningSession}
                    selectedTrainerId={selectedTrainerId}
                />
                <Grid.Row>
                    <Grid.Column width={12}>
                        <DigitalPlanningEditMessage state={planningState} />
                        { showAllAvailabilityCalendar &&
                            <AllAvailabilityCalendar month={selectedMonth} />
                        }
                        { showInputs &&
                        <DigitalPlanningCalendar
                            month={selectedMonth}
                            trainerAvailability={trainerAvailability}
                            trainerExpiryDate={trainerExpiryDate}
                            selectedTrainerId={selectedTrainerId}
                            existingEvents={existingEvents}
                            digitallyPlannedForOtherCategory={digitallyPlannedEventsForOtherWorkflow}
                            digitallyPlannedEvents={digitallyPlannedEvents}
                            selectedEventType={selectedEventType}
                            selectedCountry={selectedDdrsCountry}
                            setDigitallyPlannedEvents={setDigitallyPlannedEvents}
                            selectedEventsPerDay={selectedEventsPerDay}
                            disabled={disableEditing}
                            eventTypeCategory={eventTypeCategory}
                            onFilterChange={onFilterChange}
                            currentTrainer={currentTrainer}
                            getStartTimeForSession={getStartTimeForSession}
                            existingEventInstancesAllocatedToTrainer={existingAllocatedEventInstancesToSelectedTrainer}
                            standbyCalendar={standbyCalendar}
                        />
                        }
                    </Grid.Column>
                    {(showInputs || selectedTrainerId) &&
                        <DigitalPlanningSidebar
                            currentTrainer={currentTrainer}
                            digitallyPlannedEvents={digitallyPlannedEvents}
                            disableEditing={disableEditing}
                            eventTypeCategory={eventTypeCategory}
                            existingEvents={existingEvents}
                            getStartTimeForSession={getStartTimeForSession}
                            selectedDdrsCountry={selectedDdrsCountry}
                            selectedEventType={selectedEventType}
                            selectedEventsPerDay={selectedEventsPerDay}
                            selectedMonth={selectedMonth}
                            selectedTrainer={selectedTrainer}
                            selectedTrainerId={selectedTrainerId}
                            setDigitallyPlannedEvents={setDigitallyPlannedEvents}
                            setSelectedDdrsCountry={setSelectedDdrsCountry}
                            setSelectedEventType={setSelectedEventType}
                            setSelectedEventsPerDay={setSelectedEventsPerDay}
                            showInputs={showInputs}
                            standbyCalendar={standbyCalendar}
                            trainerAvailability={trainerAvailability}
                            trainerExpiryDate={trainerExpiryDate}
                        />
                    }
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={12}>
                        { showInputs &&
                        <>
                            <ConfirmButton
                                className="save-progress-button"
                                content="Are you sure you wish to clear this trainer's provisionally created courses?"
                                header="Clear courses"
                                onConfirm={onClearClicked}
                                floated={"left"}
                                disabled={noEventsOrEditingDisabled}
                            >
                                Clear
                            </ConfirmButton>
                            <Button
                                className="save-progress-button"
                                floated={"right"}
                                disabled={noEventsOrEditingDisabled}
                                onClick={onSaveClicked}>
                                save
                            </Button>
                        </>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
