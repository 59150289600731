import { createValidator, validators } from "not-valid";

import { positiveNumber } from "./positiveNumber";

const rangeValidator = () => {
    const min = 1;
    const max = Math.pow(2, 32) - 1;
    // eslint-disable-next-line eqeqeq
    return createValidator<number>(v => v == null || (v != null && v >= min && v <= max), `Value must be between ${min} and ${max}`);
};

export const dorsId = [validators.requiredNumber(), positiveNumber(), rangeValidator()];
export const dorsIdOptional = [positiveNumber(), rangeValidator()];
