import * as React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { CoreDetailProps } from "../Detail";
import { useSelector } from "react-redux";
import { NDorsAndCourtAttributes } from "./attributes/NdorsAndCourtAttributes";
import { CommercialAttributes } from "./attributes/CommercialAttributes";
import { ComplianceAttributes } from "./attributes/ComplianceAttributes";
import { ConstructionAttributes } from "./attributes/ConstructionAttributes";
import { AppState, AttributeRoute, AttributeRouteTitles, TrainerDetailModel } from "../../model";
import { Link } from "redux-little-router";
import { routerPathnameSelector } from "@common/crud/common/selectors";
import { BusinessLineType } from "@common/crud/organisation/model";

export const AttributesTab: React.FC<CoreDetailProps> = ({ trainer }) => {

    const routeSection = (useSelector((state: AppState) => state.router.query.section) ?? DefaultAttributesRoute(trainer?.businessLineTypes)) as AttributeRoute;
    const basePath = useSelector(routerPathnameSelector);
    return (
        <Tab.Pane>
            <Grid container={true}>
                {trainer?.businessLineTypes?.includes(BusinessLineType.Police) &&
                    <AttributesSectionMenuItem section={"ndors-and-court"} routeSection={routeSection} basePath={basePath} />
                }
                {trainer?.businessLineTypes?.includes(BusinessLineType.Corporate) &&
                    <AttributesSectionMenuItem section={"commercial"} routeSection={routeSection} basePath={basePath} />
                }
                {trainer?.businessLineTypes?.includes(BusinessLineType.Construction) &&
                    <AttributesSectionMenuItem section={"construction"} routeSection={routeSection} basePath={basePath} />
                }
                <AttributesSectionMenuItem section={"compliance"} routeSection={routeSection} basePath={basePath} />
                <AttributesTableForRoute trainer={trainer} section={routeSection} />
            </Grid>
        </Tab.Pane>
    );
};

export function AttributesSectionMenuItem({ section, routeSection, basePath }:
    {section: AttributeRoute; routeSection: AttributeRoute; basePath: string}) {

    return (
        <Link href={`${basePath}?section=${section}`}
            className={`button-search-menu-item ${section === routeSection? "current":""}`}>
            {AttributeRouteTitles[section]}
        </Link>);
}

const DefaultAttributesRoute = (businessLineTypes: BusinessLineType[]) =>
{
    if (!businessLineTypes || businessLineTypes.length === 0) {
        return "compliance";
    }

    switch (businessLineTypes[0]) {
        case BusinessLineType.Police:
            return "ndors-and-court";
        case BusinessLineType.Corporate:
            return "commercial";
        case BusinessLineType.Construction:
            return "construction";
        default:
            return "compliance";
    }
};

const AttributesTableForRoute = ({ trainer, section }: {trainer: TrainerDetailModel; section: AttributeRoute}) => {

    switch (section) {
        case "ndors-and-court":
            return <NDorsAndCourtAttributes trainer={trainer} />;
        case "commercial":
            return <CommercialAttributes trainer={trainer} />;
        case "compliance":
            return <ComplianceAttributes trainer={trainer} />;
        case "construction":
            return <ConstructionAttributes trainer={trainer} />;
        default:
            return <></>;
    }
};

