import { ExternalLinks } from "@common/ExternalLinks";
import { LandingAction } from "./actions";
import {
    LOAD_BOOKING_SUCCESS,
    LOAD_EXTERNAL_LINKS_SUCCESS,
    SET_DELIVERY_TYPE_BOOKING_ID,
    SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX,
    SET_DELIVERY_TYPE_FILTER_DISTANCE,
    SET_DELIVERY_TYPE_FILTER_VENUE_ID,
    UPDATE_INCLUDE_FULLY_BOOKED_COURSES,
} from "./actiontypes";
import { DeliveryTypeFilterSearch } from "@booking/landing/model";
import {
    useGetDeliveryTypeFilterFromSessionStorage
} from "@booking/landing/hooks/useGetDeliveryTypeFilterFromSessionStorage";
import {
    useClearDeliveryTypeFilterInSessionStorage
} from "@booking/landing/hooks/useClearDeliveryTypeFilterInSessionStorage";

export function landing(state = {} as any, action: LandingAction) {
    switch (action.type) {
        case LOAD_BOOKING_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function externalLinks(state = {} as any, action: LandingAction): ExternalLinks {
    switch (action.type) {
        case LOAD_EXTERNAL_LINKS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function includeFullyBookedCourses(state: boolean = false, action: LandingAction): boolean {
    switch (action.type) {
        case UPDATE_INCLUDE_FULLY_BOOKED_COURSES:
            return action.payload;
        default:
            return state;
    }
}

export function deliveryTypeFilters(state = {} as DeliveryTypeFilterSearch, action: LandingAction): DeliveryTypeFilterSearch {
    switch (action.type) {
        case SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX:
            return { ...state, deliveryTypeColumnActiveIndex: action.payload };
        case SET_DELIVERY_TYPE_FILTER_DISTANCE:
            return { ...state, distance: action.payload };
        case SET_DELIVERY_TYPE_FILTER_VENUE_ID:
            return { ...state, venueId: action.payload };
        case SET_DELIVERY_TYPE_BOOKING_ID:
            const deliveryTypeFilter = useGetDeliveryTypeFilterFromSessionStorage(action.payload);

            if (deliveryTypeFilter !== null) {
                return {
                    bookingId: action.payload,
                    venueId: deliveryTypeFilter?.venueId ?? "",
                    deliveryTypeColumnActiveIndex: deliveryTypeFilter.deliveryTypeColumnActiveIndex,
                    distance: deliveryTypeFilter.distance
                };
            } else {
                useClearDeliveryTypeFilterInSessionStorage();
            }

            return { ...state, bookingId: action.payload };
        default:
            return state;
    }
}
