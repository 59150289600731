import * as React from "react";
import { DorsBooking, SupportRequestAttribute, SupportRequestPictures, SupportRequestAttributes } from "@common/crud/dorsBooking/model";

export interface SupportRequestsDisplayProps {
    dorsBooking: DorsBooking;
    showMessageIfNoSupportRequests: boolean;
}

export const SupportRequestsDisplay: React.FC<SupportRequestsDisplayProps> = (
    {
        dorsBooking,
        showMessageIfNoSupportRequests
    }
) => {
    let imageStrings: number[] = [];
    if (dorsBooking?.isTechnicalAssistanceRequired) {
        imageStrings = imageStrings.concat(SupportRequestAttribute.TechnicalAssistanceRequired);
    }
    if (dorsBooking?.requiresSupervisorAssistance) {
        imageStrings = imageStrings.concat(SupportRequestAttribute.RequiresSupervisorAssistance);
    }
    if (dorsBooking?.shortNoticeCompletionRequiresSpace) {
        imageStrings = imageStrings.concat(SupportRequestAttribute.ShortNoticeCompletionRequiresSpace);
    }

    return (
        imageStrings.length === 0 && showMessageIfNoSupportRequests ?
            <span>None</span> :
            (
                <>
                    {imageStrings.map((elem, idx) => <img key={idx} src={SupportRequestPictures[elem]} title={SupportRequestAttributes[elem]} />)}
                </>
            )

    );
};
