import * as React from "react";
import { Button, Modal, Form, Radio, CheckboxProps } from "semantic-ui-react";
import { PrintableBookingConfirmation } from "src/Booking.App/src/bookings/components/PrintableBookingConfirmation";
import { Attendee, AttendeeApi } from "../..";
import { SendMessageOver, MessageType as AttendeeMessageType } from "../../model";
import { toast } from "@common/toasts";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { EventInstance } from "@common/crud/eventInstance";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ResultResponse } from "@common/model";
import { isValidMobileNumber } from "@common/validation/mobileNumber";
import {  DrinkDriveOffenderDetailModel, MessageType as DdrsMessageType } from "@common/crud/drinkDriveOffenders/model";
import { DrinkDriveOffenderApi } from "@common/crud/drinkDriveOffenders/drinkDriveOffenderApi";
import { EventTypeDetailModel } from "@common/crud/eventType/model";
import { DdrsOffenderLetterPrintView } from "@common/ddrsLetters/components/DdrsOffenderLetterPrintView";

export enum ActionType {
    Confirmation,
    Reminder
}

export interface ResendCommsModalProps {
    recipient: Attendee | DrinkDriveOffenderDetailModel;
    actionType: ActionType;
    buttonText: string;
    attendeeIsDrinkDriveOffender?: boolean;
    eventType?: EventTypeDetailModel;
}

export const ResendCommsModal: React.FC<ResendCommsModalProps> = ({ recipient, actionType, buttonText, eventType,attendeeIsDrinkDriveOffender=false }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [resendOption, setResendOption] = React.useState<SendMessageOver>(SendMessageOver.Both);
    const [loading, setLoading] = React.useState(false);
    const [showPrintView, setShowPrintView] = React.useState(false);
    const eventInstance: EventInstance = useSelector(eventInstanceSelector);

    const attendee = recipient as Attendee;
    const ddrsOffender = recipient as DrinkDriveOffenderDetailModel;

    React.useEffect(() => {
        setResendOption(recipient.email ? SendMessageOver.Email : recipient.telephone ? SendMessageOver.SMS : SendMessageOver.None);
    }, [recipient]);

    const openCloseAction = React.useCallback(() => {
        setShowPrintView(false);
        setOpen(!open);
    }, [open]);

    const setOption = React.useCallback((e: any, { value }: CheckboxProps) => setResendOption(value as number), []);

    async function onContinueClick() {
        setLoading(true);
        let result: ResultResponse;
        let action: string;
        if (actionType === ActionType.Confirmation) {
            if (resendOption === SendMessageOver.Letter) {
                new AttendeeApi(attendee.eventInstanceId).logPrintBookingConfirmation(attendee.id);
                setShowPrintView(true);
            } else {
                result = await new AttendeeApi(attendee.eventInstanceId).resendConfirmation(attendee.id, resendOption);
                action = "Confirmation message";
            }
        }

        else if (attendeeIsDrinkDriveOffender) {
            if (resendOption === SendMessageOver.Letter) {
                window.print();
            }

            else {
                await new DrinkDriveOffenderApi().sendComms(ddrsOffender.id, resendOption, DdrsMessageType.DrinkDriveOffenderBookingReminder);
                toast.info("Reminder message sent");
            }
        }

        else {
            const reminderType =
                eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital
                    ? attendee.email ? AttendeeMessageType.DigitalEventReminderForAll : AttendeeMessageType.DigitalBookingReminderNoEmail
                    : AttendeeMessageType.Reminder;
            result = await new AttendeeApi(attendee.eventInstanceId).resendReminder(attendee.id, resendOption, reminderType);
            action = "Reminder message";
        }

        if (result) {
            if (result.success) {
                toast.info(`${action} requested`);
            } else {
                toast.warning(result.message);
            }
        }
        setLoading(false);
        setOpen(false);
    };

    const hasValidMobileNumber = isValidMobileNumber(attendee?.telephone);

    return (
        <>
            <Button disabled={recipient.isTransferred} onClick={openCloseAction}>{buttonText}</Button>
            {resendOption === SendMessageOver.Letter && actionType === ActionType.Confirmation ?
                <PrintableBookingConfirmation bookingId={attendee.correlationId} showPrintView={showPrintView} setShowPrint={setShowPrintView} />:
                <DdrsOffenderLetterPrintView
                    openingWording={eventType?.reminderToBookLetterOpening}
                    closingWording={eventType?.reminderToBookLetterClosing} drinkDriveOffender={ddrsOffender} />
            }
            <Modal open={open}>
                <Modal.Header>
                    Resend Comms
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            {`How are you sending the booking ${ActionType[actionType].toLowerCase()} today?`}
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Both Email and SMS"
                                name="radioGroup"
                                disabled={!hasValidMobileNumber && !attendee?.email}
                                value={SendMessageOver.Both}
                                checked={resendOption === SendMessageOver.Both}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Email"
                                name="radioGroup"
                                disabled={!attendee?.email}
                                value={SendMessageOver.Email}
                                checked={resendOption === SendMessageOver.Email}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="SMS"
                                name="radioGroup"
                                disabled={!hasValidMobileNumber}
                                value={SendMessageOver.SMS}
                                checked={resendOption === SendMessageOver.SMS}
                                onChange={setOption}
                            />
                        </Form.Field>
                        {(actionType === ActionType.Confirmation || attendeeIsDrinkDriveOffender) && (
                            <Form.Field>
                                <Radio
                                    label="Letter (manual print)"
                                    name="radioGroup"
                                    value={SendMessageOver.Letter}
                                    checked={resendOption === SendMessageOver.Letter}
                                    onChange={setOption}
                                />
                            </Form.Field>
                        )}
                        {attendeeIsDrinkDriveOffender && (
                            <Form.Field>
                                <Radio
                                    label="Letter (auto print)"
                                    name="radioGroup"
                                    value={SendMessageOver.LetterAutomatic}
                                    checked={resendOption === SendMessageOver.LetterAutomatic}
                                    onChange={setOption}
                                />
                            </Form.Field>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinueClick}
                        loading={loading}
                        disabled={resendOption === SendMessageOver.None || loading}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
