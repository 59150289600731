import { Attendee } from "@common/crud/attendee";
import { CountryEnum } from "@common/crud/organisation/model";
import { DdrsOffenderDocumentPaths, DocumentType } from "@common/crud/attendee/model";

export const unsupportedCountry =(attendee: Attendee) => {
    switch (attendee?.referredCourtCountry) {
        case CountryEnum.England:
        case CountryEnum.Wales:
        case CountryEnum.NIreland:
            return false;
        default:
            return true;
    }
};

export const attendeeHasDocument =(attendee: Attendee, docType: DocumentType ) => {
    return !!attendee.ddrsOffenderDocumentPaths?.[docType];
};

export const downloadLink = (attendee: Attendee, docType: DocumentType, eventInstanceId: string) => {
    const path = (attendeeHasDocument(attendee, docType) &&
    attendee.ddrsOffenderDocumentPaths?.[docType as keyof DdrsOffenderDocumentPaths].blobPath) ?? "";
    return path? `/api/${eventInstanceId}/attendees/${attendee.id}/download?path=${path}&docType=${docType}`:"#";};
