import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const IntroductionSection: QuestionSection = {
    id: "3",
    title: "Introduction",
    seqNum: 2,
    subSections: [
        {
            id: "3.1",
            text: "10 min",
            seqNum: 1,
            criteria: [
                { id: "1", text: "Trainer and clients to introduce themselves." },
                { id: "2", text: "Use of digital platform." },
                { id: "3", text: "Explain what is going to happen and what is expected of clients.  " },
                { id: "4", text: "Set the right tone for the day. " },
                { id: "5", text: "Encourage clients to relax." }
            ]
        }
    ],
    summaryCriterium: [{ id: "6", text: "", typeEnum: CriteriaEnum.text }]
};
