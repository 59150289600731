import { Location } from "redux-little-router";
import moment from "moment";
import { TrainerVenuesInAvailabilityRangeModel } from "src/Trainer.App/src/workingRadius";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import {
    CarType,
    OtherTrainerTypeCategoryEnum,
    SESSION_FOUR_START_HOUR,
    SESSION_THREE_START_HOUR,
    SESSION_TWO_START_HOUR,
    TrainerType
} from "@common/crud/eventInstance/model";
import {
    TrainerPromiseState,
    PreferredNoDaysPerMonthInAGivenMonth,
    PreferredNoSessionsPerDayInAGivenMonth
} from "@common/trainerPromise/model";

export interface TrainerAvailabilityModel {
    id: string;
    trainerId: string;
    editMode?: boolean;
    availableDates?: AvailableDate[];
    availableVenues?: TrainerVenuesInAvailabilityRangeModel;
    preferredNoDaysPerMonthOverMonths?: PreferredNoDaysPerMonthInAGivenMonth[];
    preferredNoSessionsPerDayOverMonths?: PreferredNoSessionsPerDayInAGivenMonth[];
    expiryDate?: moment.Moment;
}

export interface TrainerAvailabilityDetails {
    trainerToSwapId: string;
    trainerToSwapType: TrainerType;
    interestedTrainers: TrainerAvailabilitySelectModel[];
}

export interface KeyValuePairTrainerAvailabilityDetails {
    key: string;
    value: TrainerAvailabilityDetails;
}

export interface TrainerAvailabilitySelectModel {
    id: string;
    fullName: string;
    distanceInMiles: number;
    allocatedCoursesInMonth: number;
    available: boolean;
    availabilityLastUpdated: moment.Moment;
    venueChosen: boolean;
    isBooked: boolean;
    isProvisionallyBooked: boolean;
    mobileNumber: string;
    homeNumber: string;
    isBookedOnOtherTimesOnTheSameDay: boolean;
    fee: number;
    sundries: number;
    averageDaysWorkedPerMonthInLastYear: number;
    isRCCoverOnDate: boolean;
    isRCMonitorOnDate: boolean;
    isStandbyTrainerDuringEventInstance: boolean;
    onlyAvailableForDigitalEvents: boolean;
    onlyAvailableForClassroomEvents: boolean;
    unverifiedTrainerAttributes: TrainerAttribute[];
    availabilityCategoryDayNumbers?: number[];
    allocatedDayNumbers?: number[];
    carType: CarType;
    hasPracticalAttribute: boolean;
    hasManualAttribute: boolean;
    hasAutomaticAttribute: boolean;
    assignAsPracticalAndTheoryTrainer: boolean;
    isFeeNonStandard?: boolean;
    availableForOtherTrainers?: boolean;
    dateMadeAvailableForOtherTrainers?: moment.Moment;
    otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum;
    isPracticalBookedForBothModule: boolean;
}

export interface MultiDayTrainerAvailabilitySelectModel {
    id: string;
    fullName: string;
    dayAvailabilities: Record<number, TrainerAvailabilitySelectModel[]>;
}

export interface SessionAvailability {
    session: number;
    type: AvailabilityType;
}

export interface AvailableDate {
    date: moment.Moment;
    sessionAvailabilities: SessionAvailability[];
    pendingUpdate?: boolean;
    dateUpdated?: moment.Moment;
}

export interface NumberDictionary<T> {
    [key: number]: T;
}

export type TrainerAvailability = TrainerAvailabilityModel;

export interface TrainerAvailabilityState {
    readonly trainerAvailability: TrainerAvailabilityModel;
}

export enum AvailabilityType {
    Unspecified = 0,
    Classroom = 10,
    Digital = 20,
    ClassroomAndDigital = 30
}

export const AvailabilityTypeOptions = {
    [AvailabilityType.Unspecified]: "Unspecified",
    [AvailabilityType.Classroom]: "Classroom",
    [AvailabilityType.Digital]: "Digital",
    [AvailabilityType.ClassroomAndDigital]: "Both"
};

export enum Sessions {
    SessionOne = 1,
    SessionTwo = 2,
    SessionThree = 3,
    SessionFour = 4,
    Unavailable = 5
}

export const SessionLabels = {
    [Sessions.SessionOne]: "Session One",
    [Sessions.SessionTwo]: "Session Two",
    [Sessions.SessionThree]: "Session Three",
    [Sessions.SessionFour]: "Session Four",
    [Sessions.Unavailable]: "Unavailable"
};

export type SessionNumber = 1 | 2 | 3 | 4;

export const sessionIntervals: { [session in SessionNumber]: readonly [number, number] } = {
    1: [0, SESSION_TWO_START_HOUR],
    2: [SESSION_TWO_START_HOUR, SESSION_THREE_START_HOUR],
    3: [SESSION_THREE_START_HOUR, SESSION_FOUR_START_HOUR],
    4: [SESSION_FOUR_START_HOUR, 24],
};

export enum Availability {
    Available = 1,
    Unavailable = 2
}
export const AvailableOptions = {
    [Availability.Available]: "Available",
    [Availability.Unavailable]: "Unavailable"
};

export type AppState = TrainerAvailabilityState & TrainerPromiseState & { router: Location };
