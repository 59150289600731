import { DayContentProps, DayPicker } from "react-day-picker";
import * as React from "react";
import moment from "moment";
import { EventInstance } from "../..";
import { Container, Icon } from "semantic-ui-react";
import { sortBy } from "lodash";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { toast } from "@common/toasts";
import { EventInstanceCreateModel } from "../../model";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export interface CalendarProps {
    eventInstances: EventInstance[];
    plannedEventInstances: EventInstanceCreateModel[];
    editMode: boolean;
    venueSelected: boolean;
    onMonthChange: (date: Date) => void;
    onViewModeDayClick: (date: Date) => void;
    addPlannedEvent: (day: moment.Moment) => void;
    removePlannedEvents: (day: number) => void;
}

interface ExtendedEventInstance extends EventInstanceCreateModel {
    isPlannedEvent: boolean;
    hasUnconfirmedTrainers: boolean;
}

export const Calendar: React.FC<CalendarProps> = ({
    eventInstances,
    onMonthChange,
    plannedEventInstances,
    addPlannedEvent,
    editMode,
    onViewModeDayClick,
    venueSelected,
    removePlannedEvents }) => {

    const { isDayBankHoliday } = useBankHolidays();

    const handleOnDayClick = React.useCallback((date: Date) => {
        const requestedDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).startOf("day");
        if (requestedDate.isBefore(moment(), "day")) {
            toast.warning("Cannot add course that's in past.");
            return;
        }

        if (eventInstances.some(e => e.startDate.isSame(requestedDate)) || plannedEventInstances.some(e => e.startDate.isSame(requestedDate))) {
            toast.warning("On that day there are existing courses. Adding new courses through calendar isn't permitted");
            return;
        }

        addPlannedEvent(requestedDate);
    }, [plannedEventInstances, addPlannedEvent, eventInstances]);

    const handleDayClickViewMode = React.useCallback((date: Date) => {
        onViewModeDayClick(date);
    }, [onViewModeDayClick]);

    function onTrashIconClick(currentDay: number) {
        return function(event: React.SyntheticEvent, data: object) {
            event.stopPropagation();
            removePlannedEvents(currentDay);
        };
    }

    const renderDay = React.useCallback((props: DayContentProps) => {
        const day = props.date;
        const date = moment.utc([day.getFullYear(), day.getMonth(), day.getDate()]).startOf("day");
        const sameDayEventInstances = eventInstances && eventInstances.filter(ei => ei.startDate.isSame(date, "day")
            && ei.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite);
        const sameDayPlannedEventInstances = plannedEventInstances
            && plannedEventInstances.filter(ei => ei.startDate.isSame(date, "day") && ei.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite);

        const mergedEvents = [...sameDayEventInstances, ...sameDayPlannedEventInstances.map(e => ({ ...e, isPlannedEvent: true }))];

        const currentDay = day.getDate();

        const isBankHoliday = isDayBankHoliday(date);
        const showTrashIcon = sameDayPlannedEventInstances && sameDayPlannedEventInstances.length > 0;

        return (
            <div className="cell-style">
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday && <span className={`bank-holiday-text ${showTrashIcon ? "reduced-line-height" : ""}`}>Bank holiday</span>}
                    {showTrashIcon &&
                        <Icon name="trash" className="cursor-pointer" onClick={onTrashIconClick(currentDay)} />}
                    {<span>{currentDay}</span>}
                </div>
                {sameDayEventInstances && drawEventInstances(mergedEvents as ExtendedEventInstance[])}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstances, plannedEventInstances]);

    const drawEventInstances = (sameDayEventInstances: ExtendedEventInstance[]) => {
        if (sameDayEventInstances.length) {
            if (venueSelected && sameDayEventInstances.length <= 3) {
                const sortedEvents = sortBy(sameDayEventInstances, x => x.startTime).slice(0, 3);

                return (<div className="flex-cell">
                    {sortedEvents.map(e => (
                        <div className={`event-details ${e.hasUnconfirmedTrainers ? "unconfirmed" : ""} ${e.isPlannedEvent ? "new-event" : ""}`} key={e.id} >
                            {e.eventTypeAbbreviation} {e.startTime.format(DateFormat.Time, { trim: false })}
                        </div>
                    ))
                    }
                </div >);
            } else {
                return (
                    <div className="flex-cell">
                        <div className={"event-details"}>
                            Course total: {sameDayEventInstances.length}
                        </div>
                    </div >
                );
            }
        }
        else {
            return null;
        }
    };

    return (
        <Container>
            <DayPicker
                className="narrow"
                selected={eventInstances && eventInstances.map(ei => ei.startDate.toDate())}
                onDayClick={editMode ? handleOnDayClick : handleDayClickViewMode}
                components={{ DayContent: renderDay }}
                onMonthChange={onMonthChange}
                weekStartsOn={1}
            />
        </Container>
    );
};
