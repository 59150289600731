import { loadCorporateUserDetail, loadCorporateUsersFromQuery } from "./actions";
import { AppState } from "./model";
import { corporateUserSelector } from "./selectors";
import { detailRoute as orderDetailRoute } from "../order/routes";

export const CorporateOrConstructionUserRoutes = {
    CorporateOrConstructionUserDetails: "/:corporateUserId",
    Edit: "/edit",
    Users: "/users",
    Create: "/create",
    CorporateOrConstructionUserOrders: "/orders",
    Rebook: "/rebook",
    SelectRebooking: "/selectRebooking",
    CreateBasket: "/create",
    DetailsAndPayment: "/detailsAndPayment",
    Payment: "/payment",
    GenesysAuth: "/genesysAuth",
    ReviewPayment: "/reviewPayment",
};

export const detailRoute = () => ({
    [CorporateOrConstructionUserRoutes.CorporateOrConstructionUserDetails]: {
        title: (state: AppState) => corporateUserSelector(state).fullName,
        resolve: [loadCorporateUserDetail],
        [CorporateOrConstructionUserRoutes.Edit]: {
            title: "Edit"
        },
        [CorporateOrConstructionUserRoutes.CorporateOrConstructionUserOrders]: {
            title: (state: AppState) => corporateUserSelector(state).fullName + "'s Orders",
            ...orderDetailRoute(),
            [CorporateOrConstructionUserRoutes.Rebook]: {
                title: "Rebook Attendees"
            },
            [CorporateOrConstructionUserRoutes.SelectRebooking]: {
                title: "Choose new courses"
            },
            [CorporateOrConstructionUserRoutes.CreateBasket]: {
                title: "Create Basket"
            },
            [CorporateOrConstructionUserRoutes.DetailsAndPayment]: {
                title: "Details and Payment"
            },
            [CorporateOrConstructionUserRoutes.Payment]: {
                title: "Payment"
            },
            [CorporateOrConstructionUserRoutes.GenesysAuth]: {
                title: "Genesys Authorisation"
            },
            [CorporateOrConstructionUserRoutes.ReviewPayment]: {
                title: "Review Payment"
            }
        }
    }
});

export const createCorporateOrConstructionUserRouteObject ={
    title: "Create User",
};

export const routes = {
    [CorporateOrConstructionUserRoutes.Users]: {
        resolve: [loadCorporateUsersFromQuery],
        title: "Users",
        ignoreParentResolve: true,
        ...detailRoute(),
        [CorporateOrConstructionUserRoutes.Create]: createCorporateOrConstructionUserRouteObject
    }
};
