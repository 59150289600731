import * as actions from "./actionTypes";
import { AppSettingsAction } from "./actions";
import { AppSettings, initialAppSettings } from "./model";

export function appSettings(state: AppSettings = initialAppSettings, action: AppSettingsAction): AppSettings {
    switch (action.type) {
        case actions.LOAD_APP_SETTINGS_SUCCESS:
            return { ...action.payload, loaded: true };
        default:
            return state;
    }
}
