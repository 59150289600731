import * as React from "react";
import { Table } from "semantic-ui-react";
import { sum } from "lodash";
import { Order } from "../model";
import { OrderRow } from "./OrderRow";
import { formatPrice } from "../helpers";

interface AllOrdersProps {
    corporateOrganisationId?: string;
    corporateUserId?: string;
    orders: Order[];
}

export const AllOrders: React.FC<AllOrdersProps> = ({
    corporateOrganisationId,
    corporateUserId,
    orders
}) => {
    const [contextOrderId, setContextOrderId] = React.useState<string>(undefined);

    const setContextOrder = React.useCallback((orderId: string) => {
        if (contextOrderId !== orderId) {
            setContextOrderId(orderId);
        } else {
            setContextOrderId(undefined);
        }
    }, [contextOrderId]);

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Booking ID</Table.HeaderCell>
                    <Table.HeaderCell>Booking Date</Table.HeaderCell>
                    {!corporateOrganisationId && (
                        <Table.HeaderCell>Organisation</Table.HeaderCell>
                    )}
                    {!corporateUserId && (
                        <Table.HeaderCell>Booker</Table.HeaderCell>
                    )}
                    <Table.HeaderCell>Number of seats</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orders.map((order) => (
                    <OrderRow
                        key={`order_${order.id}`}
                        order={order}
                        extended={order.id === contextOrderId}
                        corporateOrganisationId={corporateOrganisationId}
                        corporateUserId={corporateUserId}
                        showEventInstances={setContextOrder}
                    />
                ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell key={0}>Total Orders: {orders.length}</Table.HeaderCell>
                    <Table.HeaderCell key={1} />
                    {!corporateOrganisationId && (
                        <Table.HeaderCell key={2} />
                    )}
                    {!corporateUserId && (
                        <Table.HeaderCell key={3} />
                    )}
                    <Table.HeaderCell key={4}>{sum(orders.map(o => o.attendeeCount))}</Table.HeaderCell>
                    <Table.HeaderCell key={5}>{formatPrice(sum(orders.map(o => o.total)))}</Table.HeaderCell>
                    <Table.HeaderCell key={6}></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};
