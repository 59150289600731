import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AttendeeListModel } from "@common/crud/attendee";
import { Area, AutoSpreadStatusEnum, shouldAttendeesBeDisplayed } from "../../model";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "../../selectors";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { AttendanceRegister } from "./AttendanceRegister";
import { CompletionRegister } from "./CompletionRegister";
import { EiSideBar } from "../EiSideBar";
import { currentUserSelector } from "@common/auth";
import { getEventInstanceOptions } from "./helpers";
import { RegisterContext } from "./register-context";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { BookingTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { SpreadAttendeesModal } from "../details/SpreadAttendeesModal";
import { Message } from "semantic-ui-react";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { ConfirmButton } from "@common/components";
import { EventInstanceApi } from "../../eventInstanceApi";
import { DelegateAttendanceRegister } from "./DelegateAttendanceRegister";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { DelegateCompletionRegister } from "./DelegateCompletionRegister";
import { loadAllOrganisationsForBusineessLineType } from "@common/crud/organisation/actions";
import { isCurrentDateTimeBeforeEventInstanceDateTime } from "./helpers";
import { attendeesFromSelector } from "@common/crud/attendee/selectors";
import { isEventInstanceWorkflowConstruction, isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";
import { ConstructionDelegateAttendanceRegister } from "./ConstructionDelegateAttendanceRegister";
import { ConstructionDelegateCompletionRegister } from "./ConstructionDelegateCompletionRegister";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { GroupEventInstanceDayPartMenu } from "./GroupEventInstanceDayPartMenu";

interface RegisterProps {
    attendees: AttendeeListModel[];
    path: string;
    area: Area;
}

export const Register: React.FC<RegisterProps> = ({ attendees, path, area }) => {
    const dispatch = useDispatch();

    const [sendingComms, setSendingComms] = React.useState(false);
    const [delegateConfirmTimes, setDelegateConfirmTimes] = React.useState(false);
    const [triggerSubmit, setTriggerSubmit] = React.useState(false);

    const attendeesFrom = useSelector(attendeesFromSelector);
    const eventInstance = useSelector(eventInstanceSelector);
    const eventInstanceGroup = useSelector(eventInstanceGroupByIdSelector(eventInstance.groupId)) as EventInstanceGroupModel;
    const eventInstanceGroupDays = React.useMemo(() => eventInstanceGroup?.eventInstanceGroupItems?.map(eigi => ({
        dayNumber: eigi.dayNumber,
        deliveryDateTime: eigi.deliveryDateTime,
        eventInstanceId: eigi.eventInstanceId
    })).sort((a, b) => a.dayNumber - b.dayNumber) ?? [], [eventInstanceGroup?.eventInstanceGroupItems]);

    const currentUser = useSelector(currentUserSelector);
    const businessLineType = useSelector(businessLineTypeSelector);
    const app = useSelector(appSelector);
    const settings = useSelector(appSettingsSelector);
    const allowCommercialOpenBookingManualDelegateAdjustments = settings.featureSettings?.allowCommercialOpenBookingManualDelegateAdjustments;
    const allowConstructionOpenBookingManualDelegateAdjustments = settings.featureSettings?.allowConstructionOpenBookingManualDelegateAdjustments;

    const isAdminApp = app === Apps.Admin;
    const isCorporate = isEventInstanceWorkflowCorporate(eventInstance);
    const isConstruction = isEventInstanceWorkflowConstruction(eventInstance);

    const isBeforeEventInstanceDateTime = React.useMemo(() => isCurrentDateTimeBeforeEventInstanceDateTime(eventInstance), [eventInstance]);
    const showTimesConfirmation = React.useMemo(() => (isCorporate || isConstruction) && !isBeforeEventInstanceDateTime,
        [isCorporate, isConstruction, isBeforeEventInstanceDateTime]);

    React.useEffect(() => {
        if (eventInstance && eventInstance.bookingType === BookingTypeEnum.Open) {
            dispatch(loadAllOrganisationsForBusineessLineType(businessLineType));
        }
    }, [businessLineType, dispatch, eventInstance]);

    const forceDelegateConfirmTimes = React.useCallback(() => {
        setDelegateConfirmTimes(true);
    }, []);

    const confirmForcedDelegateTimeConfirmation = React.useCallback(() => {
        setDelegateConfirmTimes(false);
        setTriggerSubmit(true);
    }, []);

    React.useEffect(() => {
        if (triggerSubmit) {
            setTriggerSubmit(false);
        }
    }, [triggerSubmit]);

    const cancelForcedDelegateTimeConfirmation = React.useCallback(() => {
        setDelegateConfirmTimes(false);
    }, []);

    const attendeesDisplay = shouldAttendeesBeDisplayed(currentUser, eventInstance);

    const nonCancelledAttendees = attendees.filter(a => a.isBookingCanceled === false);
    const eventInstanceOptions = getEventInstanceOptions(eventInstance);
    const registerContextOptions = { path, attendees, eventInstance, nonCancelledAttendees, eventInstanceOptions, attendeesFrom };

    const showSpreadButton = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital && eventInstance.workflowType === WorkflowTypeEnum.Dors;
    const showSendCommsButton =
        (eventInstance.autoSpreadStatus === AutoSpreadStatusEnum.Completed || eventInstance.autoSpreadStatus === AutoSpreadStatusEnum.Cancelled) &&
        nonCancelledAttendees?.length > 0;

    const areDelegatesEditable = (isCorporate || isConstruction) && !!eventInstance.publishDate;
    const canChangeDelegateAmount = areDelegatesEditable &&
        (eventInstance.bookingType === BookingTypeEnum.Closed
            || (isAdminApp && ((isCorporate && allowCommercialOpenBookingManualDelegateAdjustments)
            || (isConstruction && allowConstructionOpenBookingManualDelegateAdjustments))));
    const eventInstanceFinished = eventInstanceOptions.eventInstanceFinished ?? false;
    const eventInstanceStarted = eventInstance?.deliveryDateTime?.clone()?.isBefore(moment().utc());
    const hasNoAttendees = attendees && !attendees.length;
    const overAnHourIntoCourse = (eventInstanceStarted && !eventInstanceFinished) && (moment.utc().diff(eventInstance.deliveryDateTime, "minute") > 60);

    async function onSendCommsClick() {
        setSendingComms(true);
        const api = new EventInstanceApi();
        const attendeeIdsToNotify = nonCancelledAttendees.map(f => f.id);
        await api.notifyAttendeesFailedToMove(eventInstance.id, attendeeIdsToNotify);
        setSendingComms(false);
    }

    if (attendeesDisplay.result === false) {
        const attendeeName = businessLineType === BusinessLineType.PoliceAndCourt ? "attendees" : "delegates";
        return (
            <EiSideBar>
                <div>{`Cannot show ${attendeeName} at the moment`}</div>
            </EiSideBar>
        );
    }

    return (
        <EiSideBar>
            <div>
                {eventInstance.autoSpreadStatus === AutoSpreadStatusEnum.InProgress && isAdminApp &&
                    <Message info>
                        Auto Spreading is in progress for this course
                    </Message>
                }
                <h1>Course register</h1>
                <EventInstanceBanner
                    eventInstance={eventInstance}
                    showTimesConfirmation={showTimesConfirmation}
                    forceDelegateConfirmTimes={delegateConfirmTimes}
                    cancelForcedDelegateTimeConfirmation={cancelForcedDelegateTimeConfirmation}
                    confirmForcedDelegateTimeConfirmation={confirmForcedDelegateTimeConfirmation}
                />
                {isAdminApp &&
                    <div className="margin-bottom">
                        {showSpreadButton &&
                            <SpreadAttendeesModal
                                path={path}
                                eventInstanceId={eventInstance.id}
                                eventInstanceFinished={eventInstanceFinished}
                                eventInstanceStarted={eventInstanceStarted}
                                noAttendees={hasNoAttendees}
                                overAnHourIntoCourse={overAnHourIntoCourse}
                                autoSpreadStatus={eventInstance.autoSpreadStatus}
                            />
                        }
                        {showSendCommsButton &&
                            <ConfirmButton
                                header="Are you sure?"
                                content="Are you sure? Clicking continue will send communications to each attendee."
                                onConfirm={onSendCommsClick}
                                disabled={sendingComms}
                                loading={sendingComms}
                            >
                                Send Failed Spread Comms
                            </ConfirmButton>
                        }
                    </div>
                }

                {eventInstanceGroup?.id && (
                    <GroupEventInstanceDayPartMenu area={area} group={eventInstanceGroup} />
                )}

                {isCorporate ? (
                    <RegisterContext.Provider value={registerContextOptions}>
                        {eventInstance.isStageTwo
                            ? <DelegateCompletionRegister
                                eventInstanceDate={eventInstance.startDate}
                                areDelegatesEditable={areDelegatesEditable}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                forceDelegateConfirmTimes={forceDelegateConfirmTimes}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                                triggerSubmit={triggerSubmit}
                            />
                            : <DelegateAttendanceRegister
                                eventInstance={eventInstance}
                                areDelegatesEditable={areDelegatesEditable}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                            />}
                    </RegisterContext.Provider>
                ) : isConstruction ? (
                    <RegisterContext.Provider value={registerContextOptions}>
                        {eventInstance.isStageTwo
                            ? <ConstructionDelegateCompletionRegister
                                eventInstanceDate={eventInstance.startDate}
                                areDelegatesEditable={areDelegatesEditable}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                forceDelegateConfirmTimes={forceDelegateConfirmTimes}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                                triggerSubmit={triggerSubmit}
                            />
                            : <ConstructionDelegateAttendanceRegister
                                eventInstance={eventInstance}
                                areDelegatesEditable={areDelegatesEditable}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                            />}
                    </RegisterContext.Provider>
                ) : (
                    <RegisterContext.Provider value={registerContextOptions}>
                        {eventInstance.isStageTwo ? <CompletionRegister /> : <AttendanceRegister eventInstance={eventInstance} />}
                    </RegisterContext.Provider>
                )}
            </div>
        </EiSideBar>
    );
};
