import * as React from "react";
import { AddAttachmentButton } from "./AddAttachmentButton";
import { AttachmentItem } from "./AttachmentItem";

import classes from "./EmailAttachments.module.scss";

interface EmailAttachmentsProps {
    onAttachmentsChange: (files: File[]) => void;
}

export const EmailAttachments: React.FC<EmailAttachmentsProps> = ({ onAttachmentsChange }) => {
    const [files, setFiles] = React.useState<File[]>([]);

    const onAdd = React.useCallback((fileList: FileList) => {
        const newFileList = files.concat(Array.from(fileList));
        setFiles(newFileList);
        onAttachmentsChange(newFileList);
    }, [files, onAttachmentsChange]);

    const onRemove = React.useCallback((file: File) => {
        const newFileList = files.filter(f => f !== file);
        setFiles(newFileList);
        onAttachmentsChange(newFileList);
    }, [files, onAttachmentsChange]);

    return (
        <>
            {files &&
                <div className={classes.attachmentItems}>
                    {Array.from(files).map((file, i) => <AttachmentItem key={i} file={file} onRemove={onRemove} />)}
                </div>
            }
            <AddAttachmentButton
                label="Add Attachment"
                icon="attach"
                onChange={onAdd}
            />
        </>
    );
};
