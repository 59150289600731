import * as React from "react";

import { GraphSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const Graph: ConfigurationAreaComponent<GraphSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="GraphAccessConfig"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "TrainerClientId", label: "Azure Client Id (Trainer)" },
                { name: "TrainerClientSecret", label: "Azure Client Secret (Trainer)" },
                { name: "TrainerTenant", label: "Azure Tenant (Trainer)" },
                { name: "TrainerIssuer", label: "Azure Issuer (Trainer)" },
                { name: "TrainerAadInstance", label: "Azure AD Instance (Trainer)" },
                { name: "PoliceClientId", label: "Azure Client Id (Police)" },
                { name: "PoliceClientSecret", label: "Azure Client Secret (Police)" },
                { name: "PoliceTenant", label: "Azure Tenant (Police)" },
                { name: "PoliceAadInstance", label: "Azure AD Instance (Police)" },
                { name: "AdminTenant", label: "Azure Tenant (Admin B2B)" },
                { name: "AdminClientId", label: "Admin Client Id (Admin B2B)" },
                { name: "AdminClientSecret", label: "Azure Client Secret (Admin B2B)" },
                { name: "AdminAadInstance", label: "Azure AD Instance (Admin B2B)" },
                { name: "AdminAppIdUri", label: "Admin Application ID URI (Admin B2B)" },
                { name: "CaRoleId", label: "Client Advisor Role ID" },
                { name: "SeniorCaRoleId", label: "Senior Client Advisor Role ID" },
                { name: "ServicePrincipal", label: "Alaska B2B App Object Id" },
                { name: "AdminTenantUsesGroups", label: "Alaska B2B Uses Groups" },
                { name: "CorporateAdminRoleId", label: "Commercial Admin Role ID" },
                { name: "BusinessDevelopmentManagerRoleId", label: "Business Development Manager Role ID" }
            ]}
        />
    );
};
