import { CountryDropDownOptions, CountryEnum } from "@common/crud/organisation/model";
import { Grid, Message, Radio, RadioProps } from "semantic-ui-react";
import * as React from "react";
import { Trainer } from "@common/crud/trainer";

interface DdrsCountryProps{
    selectedDdrsCountry: CountryEnum;
    setSelectedDdrsCountry: React.Dispatch<React.SetStateAction<CountryEnum>>;
    selectedTrainer: Trainer;
}
export const DdrsCountryInput: React.FC<DdrsCountryProps>=({ selectedDdrsCountry, setSelectedDdrsCountry, selectedTrainer }) => {

    const selectedTrainerCountries = selectedTrainer?.ddrsCountries;
    const onlyOneCountryOption = selectedTrainerCountries?.length === 1 ?? false;
    const canSelectCountry = (country: CountryEnum) => selectedTrainerCountries?.includes(country) ?? false;

    function onRadioChange(_: any, { value }: RadioProps) {
        setSelectedDdrsCountry(+value);
    };

    React.useEffect(() => {
        if (onlyOneCountryOption) {
            setSelectedDdrsCountry(selectedTrainerCountries[0]);
        }
    },[onlyOneCountryOption, selectedTrainerCountries, setSelectedDdrsCountry]);

    return (
        <>
            <h3 className="wide-header">Choose a country</h3>
            <Grid className="no-margin-top" padded="horizontally">
                <Grid.Row className="no-padding-top">
                    {(!selectedTrainerCountries || selectedTrainerCountries.length < 1) &&
                        <Grid.Column width={16}>
                            <Message warning visible>No countries were found for this trainer.</Message>
                        </Grid.Column>
                    }
                    {CountryDropDownOptions.map((country, index) => (
                        <Grid.Column width={8} key={index}>
                            <Radio
                                label={country.text}
                                disabled={onlyOneCountryOption || canSelectCountry(country.value) === false}
                                checked={country.value === selectedDdrsCountry}
                                onChange={onRadioChange}
                                value={country.value}
                                className={canSelectCountry(country.value) ? "bold" : ""}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid>
        </>
    );
};
