import * as React from "react";
import { Modal } from "@common/components";
import { Button, Confirm, Form, Header } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { saveNotes } from "../../actions";
import { validators } from "not-valid";
import { DdrsEINotes, EINotes, EventInstance } from "../../model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRoles, TrainerRole } from "@common/auth/model";
import { ApplicationState } from "src/Admin.App/src/applicationState";
import { eventInstanceGroupByIdSelector } from "@common/crud/eventInstance/selectors";
import { EventInstanceNotesComponent } from "@common/crud/eventInstance/components/details/EventInstanceNotesComponent";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { EventInstancesWithGroupModel } from "@common/crud/eventInstanceGroup/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { currentUserSelector } from "@common/auth";
import { toast } from "@common/toasts";
import { isNullOrUndefined } from "@common/global/CommonHelpers";

export interface DdrsEventInstanceNotesModalProps {
    groupId: string;
}

export const DdrsEventInstanceNotesModal: React.FC<DdrsEventInstanceNotesModalProps> = (props: DdrsEventInstanceNotesModalProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [dayPartNotes, setDayPartNotes] = React.useState<DdrsEINotes[]>(undefined);
    const [unchangedDayPartNotes, setUnchangedDayPartNotes] = React.useState<DdrsEINotes[]>(undefined);
    const group = useSelector(eventInstanceGroupByIdSelector(props.groupId));
    const [groupEventInstances, setGroupEventInstances] = React.useState<EventInstancesWithGroupModel>();
    const [mustLoad, setMustLoad] = React.useState<boolean>(true);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [trainerDays, setTrainerDays] = React.useState<number[]>([]);
    const isTrainerApp = useSelector(appSelector) === Apps.Trainer;
    const currentUser = useSelector(currentUserSelector);
    const [modified, setModified] = React.useState<boolean>(false);
    const [noteCleared, setNoteCleared] = React.useState<boolean>(false);

    const loadNotesData = React.useCallback( async () => {

        function getNotesForDayParts(eventInstance: EventInstance): DdrsEINotes {
            const dayPart =  group.eventInstanceGroupItems.find(gei => gei.eventInstanceId === eventInstance.id);
            return {
                dayNumber: dayPart.dayNumber,
                officeNote: eventInstance.officeNote,
                trainerNote: eventInstance.trainerNote,
                adminNote: eventInstance.adminNote,
                bookingNoteEn: eventInstance.bookingNoteEn,
                bookingNoteCy: eventInstance.bookingNoteCy
            };
        }

        function getTrainerDays(eventInstance: EventInstance): number {
            return group.eventInstanceGroupItems.filter(f => f.eventInstanceId === eventInstance.id)[0].dayNumber;
        }

        const api = new EventInstanceGroupApi();
        const eventInstanceGroup =  await api.getEventInstancesForNotesByGroupId(group.id);
        setGroupEventInstances(eventInstanceGroup);

        const loadedNotes = eventInstanceGroup.eventInstances.map(gei => getNotesForDayParts(gei));
        const isTrainerDay = eventInstanceGroup.eventInstances.some(ei => ei.allTrainerIds.some(t => t === currentUser.id));
        const daysForTrainer = isTrainerApp && isTrainerDay && eventInstanceGroup.eventInstances ?
            eventInstanceGroup.eventInstances.map(gei => getTrainerDays(gei)) :
            [];
        setTrainerDays(daysForTrainer);

        setUnchangedDayPartNotes(loadedNotes ? loadedNotes.map(n => Object.assign({}, { ...n })): []);
        setDayPartNotes(loadedNotes ? loadedNotes.map(n => Object.assign({}, { ...n })): []);
    }, [currentUser.id, group.eventInstanceGroupItems, group.id, isTrainerApp]);

    React.useEffect(() => {

        if (group.id && mustLoad) {
            loadNotesData();
            setMustLoad(false);
            setModified(false);
        }
    }, [dayPartNotes, group, groupEventInstances, loadNotesData, mustLoad]);

    const hasNotChanged = React.useCallback((n: DdrsEINotes) => {
        const key = n.dayNumber - 1;
        return n.bookingNoteCy === unchangedDayPartNotes[key].bookingNoteCy
            && n.bookingNoteEn === unchangedDayPartNotes[key].bookingNoteEn
            && n.adminNote === unchangedDayPartNotes[key].adminNote
            && n.trainerNote === unchangedDayPartNotes[key].trainerNote;
    }, [unchangedDayPartNotes]);

    const onNotesChange = React.useCallback((newNotes: DdrsEINotes[]) => {
        const notBeenModified =  newNotes.map(n => hasNotChanged(n));
        setModified(notBeenModified.some((value: boolean) => value === false));
    }, [hasNotChanged]);

    const dispatch = useDispatch();

    const onOpenButtonClick = React.useCallback(() => setOpen(true), []);

    const onTrainerNoteChange = React.useCallback((dayNumber: number, value: string) => {
        const currentNotes = [...dayPartNotes];
        currentNotes[dayNumber - 1].trainerNote = value;
        setDayPartNotes(currentNotes);
        onNotesChange(currentNotes);
    }, [dayPartNotes, onNotesChange]);

    const onNoteChange = React.useCallback((noteKey: keyof EINotes) => {
        return (dayNumber: number, value: string) => {
            const currentNotes = dayPartNotes.map((dpn: DdrsEINotes) => ({ ...dpn, [noteKey]: value }));
            setDayPartNotes(currentNotes);
            onNotesChange(currentNotes);
        };},[dayPartNotes, onNotesChange]);

    const onCancelClick = React.useCallback(async () => {
        setSaving(true);
        setModified(false);
        setOpen(false);
        setNoteCleared(false);
        setSaving(false);
        setMustLoad(true);
    }, []);

    const onSaveConfirm = React.useCallback(async () => {
        async function sendIfChanged(dayNotes: DdrsEINotes) {
            const dayNumber = dayNotes.dayNumber;
            const shouldUpdate = hasNotChanged(dayNotes) === false;

            if (shouldUpdate) {
                const eventInstanceId = group.eventInstanceGroupItems.filter(gei => gei.dayNumber === dayNumber)[0].eventInstanceId;
                await dispatch(saveNotes(eventInstanceId, dayNotes));
            }
        }

        if (modified) {
            setSaving(true);
            setNoteCleared(false);
            await dayPartNotes.forEach(dpn => ((!isTrainerApp || trainerDays.some(td => td === dpn.dayNumber)) && sendIfChanged(dpn)));
            toast.info("Notes saved");
            setOpen(false);
            setSaving(false);
            setModified(false);
            setMustLoad(true);
        }
    }, [dayPartNotes, dispatch, group.eventInstanceGroupItems, hasNotChanged, isTrainerApp, modified, trainerDays]);

    const onClearCancelClick = React.useCallback(() => {
        setNoteCleared(false);
    }, []);

    const onSaveClick = React.useCallback(async () => {

        if (dayPartNotes.some(dpn => hasNotChanged(dpn) === false)) {
            const test = dayPartNotes.filter(dpn => (dpn.trainerNote === undefined && !isNullOrUndefined(unchangedDayPartNotes[dpn.dayNumber-1].trainerNote)) ||
                (dpn.adminNote === undefined && !isNullOrUndefined(unchangedDayPartNotes[dpn.dayNumber-1].adminNote)) ||
                (dpn.bookingNoteCy === undefined && !isNullOrUndefined(unchangedDayPartNotes[dpn.dayNumber-1].bookingNoteCy)) ||
                (dpn.bookingNoteEn === undefined && !isNullOrUndefined(unchangedDayPartNotes[dpn.dayNumber-1].bookingNoteEn)));

            if (test.length > 0) {
                setNoteCleared(true);
                return;
            }
            await onSaveConfirm();
        }
    }, [dayPartNotes, hasNotChanged, onSaveConfirm, unchangedDayPartNotes]);

    const userRoles = useSelector((state: ApplicationState) => state.currentUser.roles);
    const isTrainer = userRoles.includes(TrainerRole);

    const trainerLabel = () => {
        if (isTrainer) {
            return <span><i>(Add a note for Admin to review)</i></span>;
        }
        else {
            return <span><i>(These boxes will contain any notes from the trainer)</i></span>;
        }
    };

    return (
        <>
            <Button onClick={onOpenButtonClick}>
                {isTrainer ? "Add Trainer Note" : "Manage Course Notes"}
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    What notes are you adding today?
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Authorize authorize={EventManagementAdminRoles}>
                            <Header size="large">Admin Notes</Header>
                            <EventInstanceNotesComponent
                                label={<span><b>Office Note</b> <i>(Add a note for Admin App users to review)</i></span>}
                                value={dayPartNotes && dayPartNotes[0]?.officeNote}
                                validation={[validators.validLength({ max: 1000 })]}
                                canChange={!isTrainerApp}
                                onNoteChange={onNoteChange("officeNote")} />
                            <EventInstanceNotesComponent
                                label={<span><b>Admin Note</b> <i>(Add a note for the trainer to review)</i></span>}
                                value={dayPartNotes && dayPartNotes[0]?.adminNote}
                                validation={[validators.validLength({ max: 1000 })]}
                                canChange={!isTrainerApp}
                                onNoteChange={onNoteChange("adminNote")} />
                            <Header size="large">Booking Notes</Header>
                            <EventInstanceNotesComponent
                                label={<span><b>Booking Note (EN)</b> <i>(Add a note for English clients to see in the Booking App)</i></span>}
                                value={dayPartNotes && dayPartNotes[0]?.bookingNoteEn}
                                validation={[validators.validLength({ max: 1000 })]}
                                canChange={!isTrainerApp}
                                onNoteChange={onNoteChange("bookingNoteEn")} />
                            <EventInstanceNotesComponent
                                label={<span><b>Booking Note (CY)</b> <i>(Add a note for Welsh clients to see in the Booking App)</i></span>}
                                value={dayPartNotes && dayPartNotes[0]?.bookingNoteCy}
                                validation={[validators.validLength({ max: 1000 })]}
                                canChange={!isTrainerApp}
                                onNoteChange={onNoteChange("bookingNoteCy")} />
                        </Authorize>
                        <Header size="large">Trainer Notes</Header>
                        {trainerLabel()}
                        {dayPartNotes?.map(part => (
                            <React.Fragment key={part.dayNumber}>
                                <EventInstanceNotesComponent
                                    dayNumber={part.dayNumber}
                                    label={<span><b>Day: {part.dayNumber} Trainer Note</b></span>}
                                    value={part.trainerNote}
                                    validation={[validators.validLength({ max: 1000 })]}
                                    canChange={!isTrainerApp || trainerDays.some(td => td === part.dayNumber)}
                                    onNoteChange={onTrainerNoteChange} />
                            </React.Fragment>
                        )
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelClick} className="cancel-action" disabled={saving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onSaveClick}
                        disabled={!modified || saving}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                    <Confirm
                        open={noteCleared}
                        header={"Are you sure?"}
                        content={"One or more notes will be cleared if you continue, are you sure?"}
                        onCancel={onClearCancelClick}
                        onConfirm={onSaveConfirm}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
