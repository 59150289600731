import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import { TimeInput } from "@common/global/TimeInput";
import "./DelegateConfirmTimesModalBreak.scss";
import { Media } from "@common/global/AppMedia";

export interface DelegateConfirmTimesModalBreakProps {
    eventInstanceBreak: { id: string; startTime: string; endTime: string };
    updateEventInstanceBreak: (eventInstanceBreak: { id: string; startTime: string; endTime: string }) => void;
    removeEventInstanceBreak: (eventInstanceBreak: { id: string; startTime: string; endTime: string }) => void;
}

export const DelegateConfirmTimesModalBreak: React.FC<DelegateConfirmTimesModalBreakProps> =
    ({ eventInstanceBreak, updateEventInstanceBreak, removeEventInstanceBreak }) => {
        const updateBreakStartTime = React.useCallback((value: string) => updateEventInstanceBreak({ ...eventInstanceBreak, startTime: value }),
            [eventInstanceBreak, updateEventInstanceBreak]);
        const updateBreakEndTime = React.useCallback((value: string) => updateEventInstanceBreak({ ...eventInstanceBreak, endTime: value }),
            [eventInstanceBreak, updateEventInstanceBreak]);
        const onRemoveClick = React.useCallback(() => {
            removeEventInstanceBreak(eventInstanceBreak);
        }, [eventInstanceBreak, removeEventInstanceBreak]);

        return (
            <>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <TimeInput
                            label="Start Time"
                            value={eventInstanceBreak.startTime}
                            onChange={updateBreakStartTime}
                            required
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <TimeInput
                            label="End Time"
                            value={eventInstanceBreak.endTime}
                            onChange={updateBreakEndTime}
                            required
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Media greaterThanOrEqual="computer">
                            <Button content='Remove' icon='remove circle' labelPosition='left' className="break-button-margin" onClick={onRemoveClick} />
                        </Media>
                        <Media lessThan="computer">
                            <Button onClick={onRemoveClick} content="- Remove" className="link-button break-remove-mobile-button" />
                        </Media>
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };
