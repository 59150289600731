import * as React from "react";
import { Accordion, AccordionTitleProps } from "semantic-ui-react";

import moment from "moment";
import { HistoryRecord, ChangeEventType } from "@common/history/model";

import { HistoryRow } from "./HistoryRow";

export interface OldHistoryProps {
    attendeeHistoryRecords: HistoryRecord[];
}

export const OldHistoryBaseComponent: React.FC<OldHistoryProps> = (props) => {

    const [activeIndexes, setActiveIndexes] =
        React.useState<string[]>(props.attendeeHistoryRecords.filter(i => i.eventType !== ChangeEventType.DataModification).map(i => i.id));

    const handleClick = React.useCallback((e: any, titleProps: AccordionTitleProps) => {
        const stringIndex = titleProps.index as string;

        if (activeIndexes.includes(stringIndex)) {
            removeActiveIndex(stringIndex);
        } else {
            addActiveIndex(stringIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndexes]);

    const removeActiveIndex = (index: string) => {
        setActiveIndexes(activeIndexes.filter(x => x !== index));
    };

    const addActiveIndex = (index: string) => {
        setActiveIndexes([...activeIndexes, index]);
    };

    let { attendeeHistoryRecords } = props;
    attendeeHistoryRecords = attendeeHistoryRecords.sort((a, b) => {
        const aDate = a.dateCreated || moment(1);
        const bDate = b.dateCreated || moment(1);
        return aDate.isAfter(bDate) ? -1 : 1;
    });

    return (
        <Accordion styled fluid>
            {attendeeHistoryRecords?.map((record) => {
                return (
                    <HistoryRow
                        onClick={handleClick}
                        key={record.id}
                        itemIndex={record.id}
                        isActive={activeIndexes.includes(record.id)}
                        currentHistoryRow={record}
                    />
                );
            })}
        </Accordion>
    );
};
