import { AppCommonState } from "@common/appCommonState";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pauseCallRecording } from "../actions";
import { GenesysObjectType } from "../model";

export function usePauseCallRecordingOnEntry(genesysObjectType: GenesysObjectType, correlationId: string) {

    const dispatch = useDispatch();

    const { genesysSettings, settingsLoaded } = useSelector((state: AppCommonState) => {
        return {
            genesysSettings: state.appSettings.genesysSettings,
            settingsLoaded: state.appSettings.loaded
        };
    });

    // Pause call recording for CA when we enter the page
    React.useEffect(() => {
        if (settingsLoaded && genesysSettings.genesysSetupAndEnabled)
        {
            // If we aren't getting a new token but we are a client advisor then pause the call recording
            dispatch(pauseCallRecording(genesysObjectType, correlationId));
        }
    }, [correlationId, dispatch, genesysObjectType, genesysSettings.genesysSetupAndEnabled, settingsLoaded]);
}
