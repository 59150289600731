/* eslint-disable max-lines */
import {
    TtcVenueAdminRole,
    TrainerRole,
    EventManagementAdminRolesAndTrainers,
    PoliceRole,
    CorporateHomeRole,
    EventManagementAdminRoles,
    TtcPlannerRole,
    ConstructionHomeRole,
} from "@common/auth/model";
import { routes as attendeeRoutes, corporateRoutes as corporateAttendeeRoutes,
    constructionRoutes as constructionAttendeeRoutes } from "@common/crud/attendee/routes";
import { loadVenues } from "../venue/actions";
import { loadEventTypes } from "../eventType/actions";
import { loadDdrsEventInstanceDetail, loadDigitalPlaningProgressForDors, loadDigitalPlaningProgressForDdrs, loadEventInstanceDetail } from "./actions";
import { AppState } from "./model";
import { eventInstanceBreadcrumbSelector } from "./selectors";
import { loadAttendeesForEventInstance } from "@common/crud/attendee/actions";
import { loadPoliceContracts } from "@common/policeContracts";
import { loadDsaAreas } from "@common/dsa";
import { loadDdrsDigitalEventTypes, loadDorsDigitalEventTypes } from "../eventType/actions";
import { loadAllOrganisations, loadOrganisations } from "../organisation/actions";
import { AppCommonState } from "@common/appCommonState";
import { Apps } from "@common/model";

export const EventInstanceRoutes = {
    EventInstanceDetails: "/:eventInstanceId",
    Edit: "/edit",
    History: "/history",
    BookingsHistory: "/bookings-history",
    Trainers: "/trainers",
    EventInstances: "/eventInstances",
    DdrsEventInstances: "/drink-drive",
    Create: "/create",
    BulkCreate: "/bulk-create",
    DdrsBulkCreate: "/ddrs-bulk-create",
    Communications: "/communications",
    SpecialRequirements: "/special-requirements",
    Monitoring: "/monitoring",
    DigitalPlanning: "/digital-planning",
    DdrsDigitalPlanning: "/ddrs-digital-planning",
    CreateCourses: "/create-courses",
    DdrsCreateCourses: "/ddrs-create-courses",
    Scores: "/scores",
    Certificates: "/certificates",
    CourseClosure: "/course-closure",
    TrainerSupportCalendar: "/team-support-calendar",
    HealthAndSafety: "/health-and-safety",
    StandbyTrainerCalendar: "/standby-trainer-calendar",
    SpreadingOverview: "/spreading-overview",
    Contacts: "/contacts",
    ClosedContacts: "/closed-contacts",
    Files: "/files",
    Finance: "/finance",
    Enquiries: "/enquiries",
    DelegateDocuments: "/delegateDocuments",
};

const attendeeRedirectRoutes = {
    "/attendee-redirect": {
        "/:reservedSeatId": {
            title: "Attendee Redirect"
        }
    }
};

const commonDetailRoutes = {
    ...attendeeRoutes,
    ...attendeeRedirectRoutes,
    ...corporateAttendeeRoutes,
    [EventInstanceRoutes.History]: {
        title: "Course history",
        authorize: EventManagementAdminRoles
    },
    [EventInstanceRoutes.Communications]: {
        title: "Notes",
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
    [EventInstanceRoutes.SpecialRequirements]: {
        title: "Special Requirements",
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
    [EventInstanceRoutes.BookingsHistory]: {
        title: "Bookings history",
        authorize: EventManagementAdminRoles
    },
    [EventInstanceRoutes.Monitoring]: {
        title: "Monitoring",
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
    [EventInstanceRoutes.HealthAndSafety]: {
        title: "Health and Safety",
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
    [EventInstanceRoutes.Files]: {
        title: "Files",
        authorize: [...EventManagementAdminRoles, TrainerRole]
    }
};

const constructionDetailRoutes = {
    ...constructionAttendeeRoutes,
    [EventInstanceRoutes.History]: {
        title: "Course history"
    },
    [EventInstanceRoutes.Communications]: {
        title: "Notes"
    },
    [EventInstanceRoutes.SpecialRequirements]: {
        title: "Special Requirements"
    },
    [EventInstanceRoutes.CourseClosure]: {
        title: "Course Closure"
    },
    [EventInstanceRoutes.BookingsHistory]: {
        title: "Bookings history",
    },
    [EventInstanceRoutes.Monitoring]: {
        title: "Monitoring"
    },
    [EventInstanceRoutes.Contacts]: {
        title: "Contacts"
    },
    [EventInstanceRoutes.ClosedContacts]: {
        title: "Closed Contacts"
    },
    [EventInstanceRoutes.Files]: {
        title: "Files"
    },
    [EventInstanceRoutes.Finance]: {
        title: "Finance"
    },
    [EventInstanceRoutes.Enquiries]: {
        title: "Enquiries"
    },
    [EventInstanceRoutes.DelegateDocuments]: {
        title: "Delegate Documents"
    }
};

const corporateDetailRoutes = {
    ...corporateAttendeeRoutes,
    [EventInstanceRoutes.History]: {
        title: "Course history"
    },
    [EventInstanceRoutes.Communications]: {
        title: "Notes"
    },
    [EventInstanceRoutes.SpecialRequirements]: {
        title: "Special Requirements"
    },
    [EventInstanceRoutes.CourseClosure]: {
        title: "Course Closure"
    },
    [EventInstanceRoutes.BookingsHistory]: {
        title: "Bookings history",
    },
    [EventInstanceRoutes.Monitoring]: {
        title: "Monitoring"
    },
    [EventInstanceRoutes.Contacts]: {
        title: "Contacts"
    },
    [EventInstanceRoutes.ClosedContacts]: {
        title: "Closed Contacts"
    },
    [EventInstanceRoutes.Files]: {
        title: "Files"
    },
    [EventInstanceRoutes.Finance]: {
        title: "Finance"
    },
    [EventInstanceRoutes.Enquiries]: {
        title: "Enquiries"
    }
};

export const ddrsDetailRoute = () => ({
    [EventInstanceRoutes.EventInstanceDetails]: {
        title: (state: AppState) => eventInstanceBreadcrumbSelector(state),
        // resolve loadDdrsEventInstanceDetail function is undefined in trainer app unless we wrap it this way
        resolve: [({ eventInstanceId }: {eventInstanceId: string}) => loadDdrsEventInstanceDetail({ eventInstanceId })],

        authorize: [TtcVenueAdminRole, TrainerRole, PoliceRole],
        [EventInstanceRoutes.Edit]: {
            resolve: [loadEventTypes, loadVenues, loadDsaAreas],
            title: "Edit",
        },
        [EventInstanceRoutes.Scores]: {
            resolve: [loadAttendeesForEventInstance],
            title: "DDRS Attendee Scores"
        },
        [EventInstanceRoutes.Certificates]: {
            resolve: [loadAttendeesForEventInstance],
            title: "DDRS Attendee Certificates"
        },
        [EventInstanceRoutes.Trainers]: {
            resolve: [loadEventTypes, loadDdrsEventInstanceDetail],
            title: "Trainers"
        },
        ...commonDetailRoutes
    }
});

export const detailRoute = () => ({
    [EventInstanceRoutes.EventInstanceDetails]: {
        title: (state: AppState) => eventInstanceBreadcrumbSelector(state),
        // resolve loadEventInstanceDetail function is undefined in trainer app unless we wrap it this way
        resolve: [({ eventInstanceId }: {eventInstanceId: string}) => loadEventInstanceDetail({ eventInstanceId })],
        authorize: [TtcVenueAdminRole, TrainerRole, PoliceRole],
        [EventInstanceRoutes.Edit]: {
            resolve: [loadEventTypes, loadVenues, loadDsaAreas],
            title: "Edit",
        },
        [EventInstanceRoutes.Trainers]: {
            resolve: [loadEventTypes],
            title: "Trainers"
        },
        ...commonDetailRoutes
    }
});

export const createEventInstanceRouteObject = {
    resolve: [loadVenues, loadEventTypes, loadPoliceContracts],
    title: "Create Course"
};

export const routes = {
    [EventInstanceRoutes.DdrsEventInstances]: {
        authorize: [...EventManagementAdminRolesAndTrainers],
        title: "DDRS Courses",
        ignoreParentResolve: true,
        persistQuery: true,
        ...ddrsDetailRoute(),
        resolve: [loadDsaAreas],
        [EventInstanceRoutes.Create]: {
            resolve: [loadVenues, loadEventTypes, loadDsaAreas],
            title: "Create DDRS Course"
        },
        [EventInstanceRoutes.DdrsDigitalPlanning]: {
            title: "DDRS Digital Planning"
        },
        [EventInstanceRoutes.DdrsCreateCourses]: {
            title: "DDRS Create Courses",
            resolve: [loadDdrsDigitalEventTypes, loadDigitalPlaningProgressForDdrs]
        },
        [EventInstanceRoutes.DdrsBulkCreate]: {
            title: "DDRS Create Courses",
            resolve: [loadDsaAreas]
        },
    },
    [EventInstanceRoutes.EventInstances]: {
        authorize: [...EventManagementAdminRolesAndTrainers, PoliceRole],
        title: (state: AppCommonState) => state.app === Apps.Trainer ? "Schedule":"Courses",
        ignoreParentResolve: true,
        persistQuery: true,
        ...detailRoute(),
        [EventInstanceRoutes.Create]: createEventInstanceRouteObject,
        [EventInstanceRoutes.BulkCreate]: {
            title: "Create Courses",
        },
        [EventInstanceRoutes.DigitalPlanning]: {
            title: "Digital Planning"
        },
        [EventInstanceRoutes.TrainerSupportCalendar]: {
            title: "Planning"
        },
        [EventInstanceRoutes.StandbyTrainerCalendar]: {
            title: "Planning"
        },
        [EventInstanceRoutes.CreateCourses]: {
            title: "Create Courses",
            resolve: [loadDorsDigitalEventTypes, loadDigitalPlaningProgressForDors, loadAllOrganisations]
        },
        [EventInstanceRoutes.SpreadingOverview]: {
            title: "Spreading Overview",
            authorize: [TtcPlannerRole]
        }
    }
};

export const constructionDetailRoute = () => ({
    [EventInstanceRoutes.EventInstanceDetails]: {
        title: (state: AppState) => eventInstanceBreadcrumbSelector(state),
        resolve: [({ eventInstanceId }: {eventInstanceId: string}) => loadEventInstanceDetail({ eventInstanceId })],
        authorize: [TtcVenueAdminRole, TrainerRole],
        [EventInstanceRoutes.Edit]: {
            resolve: [loadVenues, loadEventTypes, loadOrganisations],
            title: "Edit",
        },
        [EventInstanceRoutes.Trainers]: {
            resolve: [loadEventTypes],
            title: "Trainers"
        },
        ...constructionDetailRoutes
    }
});

export const corporateDetailRoute = () => ({
    [EventInstanceRoutes.EventInstanceDetails]: {
        title: (state: AppState) => eventInstanceBreadcrumbSelector(state),
        resolve: [({ eventInstanceId }: {eventInstanceId: string}) => loadEventInstanceDetail({ eventInstanceId })],
        authorize: [TtcVenueAdminRole, TrainerRole],
        [EventInstanceRoutes.Edit]: {
            resolve: [loadVenues, loadEventTypes, loadOrganisations],
            title: "Edit",
        },
        [EventInstanceRoutes.Trainers]: {
            resolve: [loadEventTypes],
            title: "Trainers"
        },
        ...corporateDetailRoutes
    }
});

export const createCorporateAndConstructionEventInstanceRouteObject = {
    resolve: [loadVenues, loadEventTypes, loadOrganisations],
    title: "Create Course"
};

export const constructionRoutes = {
    [EventInstanceRoutes.EventInstances]: {
        authorize: [ConstructionHomeRole],
        title: "Courses",
        ignoreParentResolve: true,
        persistQuery: true,
        ...constructionDetailRoute(),
        [EventInstanceRoutes.Create]: createCorporateAndConstructionEventInstanceRouteObject,
    }
};

export const corporateRoutes = {
    [EventInstanceRoutes.EventInstances]: {
        authorize: [CorporateHomeRole],
        title: "Courses",
        ignoreParentResolve: true,
        persistQuery: true,
        ...corporateDetailRoute(),
        [EventInstanceRoutes.Create]: createCorporateAndConstructionEventInstanceRouteObject,
    }
};
