import * as React from "react";

import { FunctionAppAuthSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const FunctionAuth: ConfigurationAreaComponent<FunctionAppAuthSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="FunctionAppAuthSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ClientId", label: "Azure Client Id" },
                { name: "ClientSecret", label: "Azure Client Secret" },
                { name: "TenantId", label: "Azure Tenant Id" },
                { name: "Instance", label: "Azure AD Instance" },
                { name: "DurableTaskSystemKey", label: "Durable Task System Key" },
                { name: "AuthKey", label: "Authentication Key" }
            ]}
        />
    );
};
