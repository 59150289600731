import * as React from "react";
import { useAvailabilityContext } from "@common/crud/eventInstance/components/trainers/availability-context";
import { Table, Label, Icon, Button, SemanticCOLORS, ButtonProps } from "semantic-ui-react";
import { MultiDayTrainerAvailabilitySelectModel, TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { GroupEventInstancesModel } from "@common/crud/eventInstance/model";

export interface OwnProps {
    trainer: MultiDayTrainerAvailabilitySelectModel;
    selectedMultiDayParts: GroupEventInstancesModel[];
    onAllocateToDayNumber: (trainerId: string, dayNumber: number) => void;
}

export const AvailabilityColumn: React.FC<OwnProps> = ({ trainer, selectedMultiDayParts, onAllocateToDayNumber }) => {

    const { eventInstanceGroup } = useAvailabilityContext();
    const trainerDayEntry = (dayNumber: number) => {
        return trainer.dayAvailabilities[dayNumber][0];
    };

    const onAllocateClick = React.useCallback((e: any, d: ButtonProps) => {
        const canBeBooked = (dayNumber: number) =>
            !trainer.dayAvailabilities[dayNumber][0].isBooked && !trainer.dayAvailabilities[dayNumber][0].isProvisionallyBooked;

        if (canBeBooked(d.data)) {
            onAllocateToDayNumber(trainer.id, d.data);
        }
    }, [onAllocateToDayNumber, trainer.dayAvailabilities, trainer.id]);

    const getIcon = (available: boolean, dayNumber: number) => {
        const colour = getAvailabilityStatusColour(dayNumber, available);
        if (available) {
            return (<div>
                <Icon name={"checkmark"} color={colour} />{showAvailabilityTypeSymbol(trainer.dayAvailabilities[dayNumber][0])}
            </div>);
        } else {
            return (<div>
                <Icon name={"cancel"} color={colour} />{showAvailabilityTypeSymbol(trainer.dayAvailabilities[dayNumber][0])}
            </div>);
        }
    };

    const getAvailabilityStatusColour = (dayNumber: number, available: boolean) => {
        if (available && !trainer.dayAvailabilities[dayNumber][0].isBooked) {
            if (trainer.dayAvailabilities[dayNumber][0].isBookedOnOtherTimesOnTheSameDay) {
                return "orange";
            }
            return "green";
        }

        if (!available && !trainer.dayAvailabilities[dayNumber][0].isBooked && trainer.dayAvailabilities[dayNumber][0].isBookedOnOtherTimesOnTheSameDay) {
            return "orange";
        }

        return "red";
    };

    const getStatusColour = (dayNumber?: number) => {
        if (trainer.dayAvailabilities[dayNumber].some(c => c.isBooked ||
            trainer.dayAvailabilities[dayNumber].some(p => p.isProvisionallyBooked))) {
            return "red";
        } else if (trainer.dayAvailabilities[dayNumber].some(c => c.isBookedOnOtherTimesOnTheSameDay) ||
            trainer.dayAvailabilities[dayNumber].some(c => c.isRCMonitorOnDate) ||
            trainer.dayAvailabilities[dayNumber].some(c => c.isRCCoverOnDate) ||
            trainer.dayAvailabilities[dayNumber].some(c => c.isStandbyTrainerDuringEventInstance)) {
            return "orange";
        }
        return "green";
    };

    const getTextStatus = (dayNumber: number, displayText: string) => {
        return (
            <>
                <Label color={getStatusColour(dayNumber)} basic size={"mini"} >{displayText}</Label>
                {showAvailabilityTypeSymbol(trainer.dayAvailabilities[dayNumber][0])}
            </>);
    };

    const getRendering = (dayNumber: number) => {
        if (trainer.dayAvailabilities[dayNumber] === undefined) {
            return <Label color="red" basic size="mini">Booked</Label>;
        }
        if (trainer.dayAvailabilities[dayNumber][0].isRCCoverOnDate) {
            return getTextStatus(dayNumber, "RC Cover");
        }
        if (trainer.dayAvailabilities[dayNumber][0].isRCMonitorOnDate) {
            return getTextStatus(dayNumber, "RC Monitor");
        }
        if (trainer.dayAvailabilities[dayNumber][0].isProvisionallyBooked) {
            return getTextStatus(dayNumber, "Provisionally Booked");
        }
        if (trainer.dayAvailabilities[dayNumber][0].isBooked) {
            return getTextStatus(dayNumber, "Booked");
        }
        if (trainer.dayAvailabilities[dayNumber][0].isStandbyTrainerDuringEventInstance) {
            return getTextStatus(dayNumber, "Standby Trainer");
        }
        return (
            <Button as={"button"} size={"huge"} compact basic color={areaColor(dayNumber)} className={borderClass(dayNumber)}
                data={dayNumber}
                onClick={onAllocateClick}
                disabled={!trainerDayEntry(dayNumber)} >
                {getIcon(trainer.dayAvailabilities[dayNumber][0].available, dayNumber)}
            </Button>);
    };

    const showAvailabilityTypeSymbol = (t: TrainerAvailabilitySelectModel) => {
        if (!t.available) {
            return <></>;
        }

        if (t.onlyAvailableForDigitalEvents) {
            return (<>
                <br />
                <Icon name="wifi" size="small" />
            </>
            );
        }

        if (t.onlyAvailableForClassroomEvents) {
            return (<>
                <br />
                <Icon name="home" size="small" />
            </>
            );
        }

        return <></>;
    };

    const areaColor = (dayNumber: number): SemanticCOLORS => {
        return selected(dayNumber) ? "green" : undefined;
    };

    const borderClass =(dayNumber: number) => selected(dayNumber) ? "selected-bold" : "";

    const selected = (dayNumber: number) => selectedMultiDayParts[dayNumber - 1]?.trainerIds?.some(tid => trainer.id === tid);

    const dataCells = () => {
        const colWidth = 16 / eventInstanceGroup?.eventInstanceGroupItems?.length;
        return (
            <Table style={{ "table-layout": "fixed" }} stackable>
                <Table.Body>
                    <Table.Row>
                        {eventInstanceGroup.eventInstanceGroupItems?.map((a, index) =>
                            (
                                <Table.Cell key={ `${a.dayNumber}-B${index}` } textAlign="center" size={colWidth}
                                    verticalAlign="middle" style={{ minWidth: "101px" } }>
                                    {getRendering(a.dayNumber)}
                                </Table.Cell>
                            ))
                        }
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    };

    return (
        <>
            {dataCells()}
        </>

    );
};
