import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { NoteAction } from "@common/notes/actions";
import * as actions from "./actiontypes";
import * as models from "./model";
import { toast } from "@common/toasts";
import { AssistanceRequired } from "@common/crud/dorsBooking/model";
import { HistoryRecord } from "@common/history/model";
import { HistoryApi } from "@common/history/historyApi";
import { UPDATE_ATTENDANCE_ID_SUCCESS, UPDATE_CONTACT_DETAILS_SUCCESS } from "@common/crud/attendee/actiontypes";
import { EditContactDetailsModel } from "@common/crud/attendee/model";
import { updateContactDetailsSuccess } from "@common/crud/attendee/actions";
import { push } from "redux-little-router";
import { DrinkDriveOffenderApi } from "./drinkDriveOffenderApi";
import { DrinkDriveOffenderCreateModel, DrinkDriveOffenderUnlockAccountModel, SearchOptions } from "./model";
import { QuickAddEntities } from "../quickAdd/model";
import { v4 } from "uuid";
import { UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS } from "./actiontypes";

const api = new DrinkDriveOffenderApi();

export type DrinkDriveOffenderAction =
    ({ type: actions.LOAD_DRINKDRIVEOFFENDERS_SUCCESS } & Payload<models.DrinkDriveOffenderListModel[]>)
    | ({ type: actions.CREATE_DRINKDRIVEOFFENDER_SUCCESS } & Payload<models.DrinkDriveOffenderDetailModel>)
    | ({ type: actions.SAVE_DRINKDRIVEOFFENDER_SUCCESS } & Payload<models.DrinkDriveOffenderDetailModel>)
    | ({ type: actions.LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS } & Payload<models.DrinkDriveOffenderDetailModel>)
    | ({ type: actions.LOAD_HISTORY_SUCCESS } & Payload<{ id: string; historyRecords: HistoryRecord[] }>)
    | ({ type: UPDATE_ATTENDANCE_ID_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; attendanceId: number }>)
    | ({ type: UPDATE_CONTACT_DETAILS_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; model: EditContactDetailsModel }>)
    | ({ type: UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS } & Payload<models.DrinkDriveOffenderDetailModel>)
    | NoteAction;

export const getHistorySuccess = (model: { id: string; historyRecords: HistoryRecord[] }): DrinkDriveOffenderAction => ({
    payload: model,
    type: actions.LOAD_HISTORY_SUCCESS
});

export const loadDrinkDriveOffendersSuccess = (payload: models.DrinkDriveOffenderListModel[]): DrinkDriveOffenderAction => ({
    payload,
    type: actions.LOAD_DRINKDRIVEOFFENDERS_SUCCESS
});

export function loadDrinkDriveOffenders() {
    return async (dispatch: Dispatch) => {
        const options: SearchOptions = { name: "" };
        const result = await loadAndTrack(dispatch, "loadDrinkDriveOffenders", api.getAll(options));
        dispatch(loadDrinkDriveOffendersSuccess(result));
    };
}

export const loadDrinkDriveOffenderDetailSuccess = (drinkDriveOffender: models.DrinkDriveOffenderDetailModel) => ({
    payload: drinkDriveOffender,
    type: actions.LOAD_DRINKDRIVEOFFENDER_DETAIL_SUCCESS
});

export const unlockAccountSuccess = (drinkDriveOffender: models.DrinkDriveOffenderDetailModel) => ({
    payload: drinkDriveOffender,
    type: actions.UNLOCK_DRINKDRIVEOFFENDER_ACCOUNT_SUCCESS
});

export function loadDrinkDriveOffenderDetail({ drinkDriveOffenderId }: { drinkDriveOffenderId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const drinkDriveOffender = getState().drinkDriveOffenders.filter(c => c.id === drinkDriveOffenderId)[0];

        if (drinkDriveOffender === undefined
            || drinkDriveOffender.detailUpdated === undefined
            || drinkDriveOffender.detailUpdated.isBefore(drinkDriveOffender.listUpdated)) {

            const action = loadDrinkDriveOffenderDetailSuccess;

            const result = await loadAndTrack(dispatch, "loadDrinkDriveOffenderDetail", api.detail(drinkDriveOffenderId));
            dispatch(action(result));
        }
    };
}

export function createDrinkDriveOffender(model: DrinkDriveOffenderCreateModel, path: string, quickAdd: boolean = false) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "createDrinkDriveOffender", api.create(model));
        dispatch(createDrinkDriveOffenderSuccess(result));
        if (quickAdd) {
            toast.success("Quick Add Drink Drive Offender Successful");
            dispatch(push(`/police-and-court-event-management/quickAdd/${QuickAddEntities[QuickAddEntities.DDRSOffender]}/${v4()}`));
        }
        else if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export const createDrinkDriveOffenderSuccess = (drinkDriveOffender: models.DrinkDriveOffenderDetailModel): DrinkDriveOffenderAction => ({
    payload: drinkDriveOffender,
    type: actions.CREATE_DRINKDRIVEOFFENDER_SUCCESS
});

export function saveDrinkDriveOffender(drinkDriveOffender: models.DrinkDriveOffenderEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "saveDrinkDriveOffender", api.save(drinkDriveOffender));

        dispatch(saveDrinkDriveOffenderSuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export const saveDrinkDriveOffenderSuccess = (payload: models.DrinkDriveOffenderDetailModel): DrinkDriveOffenderAction => ({
    payload,
    type: actions.SAVE_DRINKDRIVEOFFENDER_SUCCESS
});

export function getHistory(correlationId: string) {
    return async (dispatch: Dispatch) => {
        const historyApi = new HistoryApi(correlationId);
        const result = await loadAndTrack(dispatch, "getHistory", historyApi.getHistory());

        dispatch(getHistorySuccess({ id: correlationId, historyRecords: result }));
    };
}

export function setAssistanceRequiredForBooking(correlationId: string, assistanceRequired: AssistanceRequired) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "setAssistanceRequiredForBooking", api.setAssistanceRequired(correlationId, assistanceRequired));

        dispatch(loadDrinkDriveOffenderDetailSuccess(result));
    };
}

export function setEligibleForConcessions(correlationId: string, eligible: boolean) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "setEligibleForConcessions", api.setEligibleForConcessions(correlationId, eligible));

        dispatch(loadDrinkDriveOffenderDetailSuccess(result));
    };
}

export function updateDrinkDriveOffenderContactDetails(correlationId: string, model: EditContactDetailsModel) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "updateDrinkDriveOffenderContactDetails", api.updateContactDetails(correlationId, model));
        dispatch(updateContactDetailsSuccess({ correlationId, attendeeId: null, model }));
    };
}

export function unlockDrinkDriveOffenderAccount(unlockAccountModel: DrinkDriveOffenderUnlockAccountModel) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "unlockAccount", api.unlockAccount(unlockAccountModel));
        dispatch(unlockAccountSuccess(result));
        toast.success("Account unlocked");
    };
}
