import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { Card, Grid, Menu, Tab, TabProps } from "semantic-ui-react";
import { isAuthorized } from "reauthorize";
import { CurrentUser, AuthState } from "@common/auth";
import { organisationRootPathSelector } from "@common/redux-helpers/selectors";
import { PoliceUserDetailModel, AppState } from "../model";
import { userSelector, basePathSelector, routeIdSelector } from "../selectors";

import * as details from "./details";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

export interface CoreDetailProps {
    user: PoliceUserDetailModel;
}

export interface DetailProps extends CoreDetailProps {
    path: string;
    orgPath: string;
    basePath: string;
    tab?: string;
    currentUser: CurrentUser;
}

export interface DispatchProps {
    push: (path: string) => void;
}

const tabs = ObjectKeys(details).map(k => details[k]);

export class DetailInternal extends React.Component<DetailProps & DispatchProps, {}> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    public render() {
        const { tab, user, basePath, currentUser } = this.props;
        const panes = tabs.map(t => t(this.props)).filter(p => isAuthorized(currentUser, p.authorize) === "Authorized");
        const activeIndex = panes.slice(1).findIndex(p => tab && tab.startsWith(p.path)) + 1;
        const content = () => panes.length === 1
            ? <Card fluid>{panes[0].render()}</Card>
            : <Tab panes={panes} onTabChange={this.handleTabChange} activeIndex={activeIndex} />;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{user.fullName}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <Grid.Column vertical as={Menu}>
                            <Menu.Item name="Users" as={Link} href={basePath}>
                                All Users
                            </Menu.Item>
                        </Grid.Column>
                    </Grid.Column>

                    <Grid.Column width={13}>
                        {content()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private handleTabChange(event: any, { activeIndex }: TabProps) {
        this.props.push(`${this.props.basePath}/${this.props.user.id}${tabs[+activeIndex](null).path}`);
    }
}

const currentTab = (state: AppState) => state.router.pathname.substring(
    basePathSelector(state).length + 1 + routeIdSelector(state).toString().length);

function mapStateToProps(state: AppState & AuthState): DetailProps {
    return {
        user: userSelector(state),
        path: state.router.pathname,
        basePath: basePathSelector(state),
        orgPath: organisationRootPathSelector(state),
        tab: currentTab(state),
        currentUser: state.currentUser
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
