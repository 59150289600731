import { DropdownItemProps } from "semantic-ui-react";

export function optionsFromObject(obj: { [key: number]: string }, emptyOption?: string, emptyValue?: any): DropdownItemProps[] {
    const options = Object.keys(obj).filter(k => +k !== 0).map(k => ({ key: +k, text: obj[k], value: +k }));
    if (!emptyOption) {
        return options;
    }

    return [{ text: emptyOption, value: emptyValue ?? "" }].concat(options);
}

export function optionsFromObjectWithString(obj: { [key: number]: string }, emptyOption?: string, emptyValue?: any): DropdownItemProps[] {
    const options = Object.keys(obj).filter(k => +k !== 0).map(k => ({ key: (+k).toString(), text: obj[k], value: (+k).toString() }));
    if (!emptyOption) {
        return options;
    }

    return [{ text: emptyOption, value: emptyValue ?? "" }].concat(options);
}

export function optionsFromObjectIncludingZero(obj: { [key: number]: string }, emptyOption?: string, emptyValue?: any): DropdownItemProps[] {
    const options = Object.keys(obj).map(k => ({ key: +k, text: obj[k], value: +k }));
    if (!emptyOption) {
        return options;
    }

    return [{ text: emptyOption, value: emptyValue ?? "" }].concat(options);
}

export function rotateDropdownRight(options: DropdownItemProps[]): DropdownItemProps[]
{
    if (options.length > 0)
    {
        const leftItem = options.shift();
        options.push(leftItem);
    }

    return options;
}

export function optionsFromText(items: string[]) {
    return items.map(text => ({ text, value: text }));
}

export function yesNoOptions() {
    return [{ value: "false", text: "No" }, { value: "true", text: "Yes" }];
}
