import * as React from "react";

import { NotifierSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const Notifier: ConfigurationAreaComponent<NotifierSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Notifier"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "DelayInMilliseconds", label: "Delay in Milliseconds" },
                { name: "SendSurveyLinkEmailAfterHours", label: "Send survey 2 link after delivery (hours)" },
                { name: "SendDigitalEmailReminderBeforeHours", label: "Send digital reminder email before course (hours)" },
                { name: "SendDigitalSmsReminderBeforeHours", label: "Send digital reminder SMS before course (hours)" },
                { name: "PendingBookingReminderFrequency", label: "Frequency of NDORS reminder to book comms (hours)" },
                { name: "SendExtraSmsToAttendeesWithNoEmail", label: "Send extra SMS to attendees with no email" },
                { name: "SendExtraSmsToAttendeesWithNoEmailHours", label: "Frequency of extra sms (hours)" },
                { name: "MaxCoursesInTrainerCoverNotifications", label: "Maximum number of courses included in trainer cover notifications" },
                { name: "SendCorpCourseReminderEmailBeforeCourseHours", label: "Send Delegate Closed course reminder email before course (hours)" },
                {
                    name: "SendOrganisationClosedCourseReminderEmailBeforeCourseHours",
                    label: "Send Organisation Closed course reminder email before course (hours)",
                    inputType: InputType.Number,
                },
                {
                    name: "SendManagerClosedCourseReminderEmailBeforeCourseHours",
                    label: "Send Manager Closed course reminder email before course (hours)",
                    inputType: InputType.Number,
                },
                { name: "SendDelegateCourseReminderSmsBeforeCourseHours", label: "Send Closed course delegate reminder SMS before course (hours)" },
                {
                    name: "BeforeFirstCorporateOpenCourseReminderHours",
                    label: "Send First Open course reminder before course (hours)",
                    inputType: InputType.Number
                },
                {
                    name: "BeforeSecondCorporateOpenCourseReminderHours",
                    label: "Send Second Open course reminder before course (hours)",
                    inputType: InputType.Number
                },
                {
                    name: "BeforeThirdCorporateOpenCourseReminderHours",
                    label: "Send Third Open course reminder before course (hours)",
                    inputType: InputType.Number
                },
                {
                    name: "BeforeSmsCorporateOpenCourseReminderHours",
                    label: "Send SMS Open course reminder SMS before course (hours)",
                    inputType: InputType.Number
                },
                {
                    name: "SendWorkshopSurveyLinkAfterHours",
                    label: "Send workshop survey link after register processing (hours)",
                    inputType: InputType.Number
                },
                {
                    name: "BeforeConstructionOpenCourseReminderHours",
                    label: "Send Construction Open course reminder before course (hours)",
                    inputType: InputType.Number
                }
            ]}
        />
    );
};
