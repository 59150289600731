import { resendConfirmationMail } from "@booking/landing/actions";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Confirm } from "semantic-ui-react";
import { BookingApi } from "../bookingApi";
import { Booking } from "../models";
import { PrintableBookingConfirmation } from "./PrintableBookingConfirmation";
import classes from "./BookingActions.module.scss";
import { Button } from "@booking/common/Button";
import { Authorize } from "reauthorize";
import { AttendeeRole } from "@common/auth/model";

export function BookingActions({ booking, termsAndConditionsLink }: { booking: Booking; termsAndConditionsLink: string }) {

    const [resendModalOpen, setResendModalOpen] = React.useState(false);
    const [showPrint, setShowPrint] = React.useState(false);
    const [t] = useTranslation("BookingActions");
    const dispatch = useDispatch();

    function showResendModal() {
        setResendModalOpen(true);
    }

    function hideResendModal() {
        setResendModalOpen(false);
    }
    function onConfirmClick() {
        dispatch(resendConfirmationMail());
        hideResendModal();
    }

    function printConfirmation() {
        const api = new BookingApi(booking.id);
        api.logprintbookingconfirmation();
        setShowPrint(true);
    };

    return (<>
        <div className={classes.actions}>
            <Authorize authorize={AttendeeRole}>
                {booking?.seatBooked && booking.email &&
                <Button onPress={showResendModal} variant="primary">{t("RESEND_BOOKING_CONFIRMATION")}</Button>
                }
                <Confirm open={resendModalOpen} header={t("RESEND_BOOKING_CONFIRMATION")} cancelButton={{
                    content: "Cancel",
                    className: "cancel-action"
                }} onConfirm={onConfirmClick} onCancel={hideResendModal} />
            </Authorize>
            <Button onPress={printConfirmation} variant="primary">{t("PRINT_BOOKING_DETAILS")}</Button>
            {termsAndConditionsLink &&
                <Button variant="primary"><a href={termsAndConditionsLink} target="_blank" rel="noreferrer">{t("TERMS_AND_CONDITIONS")}</a></Button>
            }

        </div>
        <PrintableBookingConfirmation showPrintView={showPrint} bookingId={booking.id} setShowPrint={setShowPrint} />
    </>);
}

