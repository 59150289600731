import { AppCommonState } from "@common/appCommonState";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import * as React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

interface DorsPortalEditInstructionProps {
    inline?: boolean;
    workflowType: WorkflowTypeEnum;
}

export const DorsPortalEditInstruction: React.FC<DorsPortalEditInstructionProps> = ({ inline, workflowType }) => {
    const externalResourcesSettings = useSelector((state: AppCommonState) => state.appSettings.externalResourcesSettings);

    return workflowType === WorkflowTypeEnum.Dors && (
        inline ? (
            <Trans i18nKey="BookingDetails:EDIT_INSTRUCTIONS">
            To edit your details please visit the
                <a className="primary-link" target="_blank" rel="noreferrer" href={externalResourcesSettings.dorsPortalLink}>NDORS Portal</a>.
            </Trans>
        ) : (
            <p className="sub-heading">
                <Trans i18nKey="BookingDetails:EDIT_INSTRUCTIONS">
                To edit your details please visit the
                    <a className="primary-link" target="_blank" rel="noreferrer" href={externalResourcesSettings.dorsPortalLink}>NDORS Portal</a>.
                </Trans>
            </p>
        )
    );};
