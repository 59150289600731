/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { Form, Tab, TabProps, Segment, Message, Icon, Grid, Button } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";
import { phoneNumberWithSpaces } from "@common/validation";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { DelegateDetailModel, DelegateCreateEditModel, AppState, DrivingLicenceCountry, filteredVocationalLicenceCatogories,
    DrivingLicenceCountryEnum, DelegateComparisonTypeEnum } from "../model";
import { saveDelegate } from "../actions";
import { delegateSelector, pathWithoutLastPartSelector, editPathSelector, basePathSelector } from "../selectors";
import { EditModal } from "./EditModal";
import { Organisation, OrganisationApi, OrganisationState } from "@common/crud/organisation";
import { AuthState } from "@common/auth/model";
import { toast } from "@common/toasts";
import { Spinner } from "@common/global";
import { debounce } from "@neworbit/simpleui-utils";
import { DelegateApi } from "../delegateApi";
import { MuiDateField } from "@common/components/MuiDateField";
import { licenceValidator } from "@common/validation/drivingLicenceNumber";
import { ValidationResultType } from "not-valid/bin/results";
import { AttendeeField, CompanyTypeEnum } from "@common/crud/organisation/model";
import { DelegateAttendeeField } from "./DelegateAttendeeField";
import { EventInstanceAttendeeField } from "@common/crud/eventInstance/model";
import { MxEmailCheckResult } from "@common/crud/email/model";
import { EmailApi } from "@common/email/emailApi";
import { organisationSelector } from "@common/crud/organisation/selectors";
import { companyTypeSupportsDqcFields } from "@common/crud/organisation/utils/organisationsHelper";
import { validators } from "not-valid";
import { ManagerContactRow } from "@common/crud/common/ManagerContactRow";

export interface EditProps extends SharedEditProps<DelegateCreateEditModel> {
    open: boolean;
    editPath: string;
    delegatesBasePath: string;
    findUserByEmail: (organisationId: string, email: string) => Promise<DelegateDetailModel>;
    findUserByDrivingLicence: (organisationId: string, drivingLicence: string) => Promise<DelegateDetailModel>;
    findUserByUin: (organisationId: string, uin: string) => Promise<DelegateDetailModel>;
    checkEmail: (email: string) => Promise<MxEmailCheckResult>;
    loadAttendeeFields: (organisationId: string) => Promise<AttendeeField[]>;
    organisationCompanyTypes?: number[];
}

export interface DispatchProps extends SaveDispatchProps<DelegateCreateEditModel> {
    close: () => void;
}

interface EditState extends FormState<DelegateCreateEditModel> {
    activeIndex: string | number;
    duplicateEmailUser: DelegateDetailModel;
    duplicateLicenceNumberUser: DelegateDetailModel;
    duplicateUinUser: DelegateDetailModel;
    searchingEmail: boolean;
    searchingLicenceNumber: boolean;
    searchingUin: boolean;
    licenceNumberTouched: boolean;
    uinTouched: boolean;
    managersValid: Set<string>;
    attendeeFields: AttendeeField[];
}

export class EditForm extends EditComponent<DelegateCreateEditModel, EditProps & DispatchProps, EditState> {

    constructor(props: EditProps & DispatchProps & SaveDispatchProps<DelegateCreateEditModel>) {
        super(props);
        this.updateManagerValue = this.updateManagerValue.bind(this);
        this.fixContactsInvalid = this.fixContactsInvalid.bind(this);
        this.addManager = this.addManager.bind(this);
        this.removeManager = this.removeManager.bind(this);
    }

    public async componentDidMount() {
        if (!this.state.attendeeFields) {
            this.loadAttendeeFieldsIntoState(this.state.values.organisationId);
        }
    }

    public componentWillReceiveProps(props: Readonly<EditProps & DispatchProps & SaveDispatchProps<DelegateCreateEditModel>>) {
        super.componentWillReceiveProps(props);

        const defaultPane = this.panes.find(pane => pane.editPath === props.editPath);

        this.setState({
            activeIndex: defaultPane ? defaultPane.index : 0,
            loading: false,
        });
    }

    private loadAttendeeFieldsIntoState = async (organisationId: string) => {
        const attendeeFields = await this.props.loadAttendeeFields(organisationId);
        this.setState({ attendeeFields });
    };

    private onChange = (k: keyof DelegateCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const duplicateEmailUser = await this.props.findUserByEmail(this.state.values.organisationId, value);
            const mxEmailCheckResult = await this.props.checkEmail(value);

            this.updateProperty("mxEmailCheckResult", mxEmailCheckResult, true);
            if (duplicateEmailUser?.id !== this.state.values.id) {
                this.setState({ searchingEmail: false, duplicateEmailUser, valid: { ...this.state.valid, email: !duplicateEmailUser } });
            } else {
                this.setState({ searchingEmail: false, duplicateEmailUser: null, valid: { ...this.state.valid, email: true } });
            }
        } else {
            this.updateProperty("mxEmailCheckResult", null, true);
            this.setState({ duplicateEmailUser: null });
        }
    };

    private onUinTouched = () => {
        this.setState({ uinTouched: true });
    };

    private onLicenceNumberTouched = () => {
        this.setState({ licenceNumberTouched: true });
    };

    private onUinChanged = async (value: string, valid: boolean) => {
        this.updateProperty("uin", value, valid);
        if (value && valid) {
            this.setState({ searchingUin: true, valid: { ...this.state.valid, uin: false } });
            const duplicateUinUser = await this.props.findUserByUin(this.state.values.organisationId, value);

            if (duplicateUinUser?.id !== this.state.values.id) {
                this.setState({
                    searchingLicenceNumber: false,
                    duplicateUinUser,
                    valid: { ...this.state.valid, uin: !duplicateUinUser }
                });
            } else {
                this.setState({ searchingUin: false, duplicateUinUser: null, valid: { ...this.state.valid, uin: true } });
            }
        } else {
            this.setState({ duplicateUinUser: null });
        }
    };

    private onLiceneNumberChanged = async (value: string, valid: boolean) => {
        this.updateProperty("licenceNumber", value, valid);

        const delegateComparisonType = this.props.organisationCompanyTypes && this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver)
            ? DelegateComparisonTypeEnum.Uin
            : DelegateComparisonTypeEnum.DrivingLicenceNumber;

        if (delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber && value && valid) {
            this.setState({ searchingLicenceNumber: true, valid: { ...this.state.valid, licenceNumber: false } });
            const duplicateLicenceNumberUser = await this.props.findUserByDrivingLicence(this.state.values.organisationId, value);

            if (duplicateLicenceNumberUser?.id !== this.state.values.id) {
                this.setState({
                    searchingLicenceNumber: false,
                    duplicateLicenceNumberUser,
                    valid: { ...this.state.valid, licenceNumber: !duplicateLicenceNumberUser }
                });
            } else {
                this.setState({ searchingLicenceNumber: false, duplicateLicenceNumberUser: null, valid: { ...this.state.valid, licenceNumber: true } });
            }
        } else {
            this.setState({ duplicateLicenceNumberUser: null });
        }
    };

    private onSurnameChanged = async (value: string, valid: boolean) => {
        this.updateProperty("surname", value, valid);
        const drivingLicenceNumberValidator = licenceValidator(this.state.values?.drivingLicenceCountry, value, []);
        const licenceValid = drivingLicenceNumberValidator(this.state.values.licenceNumber);
        this.setState({ valid: { ...this.state.valid, licenceNumber: licenceValid.type === ValidationResultType.Pass } });
    };

    private onDrivingLicenceCountryChanged = async (value: number, valid: boolean) => {
        this.updateProperty("drivingLicenceCountry", value, valid);
        const drivingLicenceNumberValidator = licenceValidator(value, this.state.values?.surname, []);
        const licenceValid = drivingLicenceNumberValidator(this.state.values.licenceNumber);
        this.setState({ valid: { ...this.state.valid, licenceNumber: licenceValid.type === ValidationResultType.Pass } });

        if (value !== DrivingLicenceCountryEnum.NonGBNI) {
            this.updateProperty("drivingLicenceExactCountry", undefined, true);
            this.updateProperty("dateOfBirth", undefined, true);
            this.setState({ valid: { ...this.state.valid, drivingLicenceExactCountry: true, dateOfBirth: true } });
        }
    };

    private onAttendeeFieldValueChange = (value: any, fieldConfiguration: EventInstanceAttendeeField) => {
        this.updateProperty("attendeeFieldValues",
            [ ...(this.state.values.attendeeFieldValues || []).filter(afv => afv.name !== fieldConfiguration.fieldId),
                { name: fieldConfiguration.fieldId, value, displayName: fieldConfiguration.displayName, type: fieldConfiguration.type } ],
            true);
    };

    private panes = [
        {
            index: 0, menuItem: "Details", editPath: "", render: () => {
                const { values, showErrors, duplicateEmailUser, duplicateLicenceNumberUser, duplicateUinUser, licenceNumberTouched,
                    uinTouched, searchingEmail } = this.state;
                const { delegatesBasePath } = this.props;

                const delegateComparisonType = this.props.organisationCompanyTypes
                    && this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver)
                    ? DelegateComparisonTypeEnum.Uin
                    : DelegateComparisonTypeEnum.DrivingLicenceNumber;

                return (
                    <Tab.Pane key="mainPane">
                        {delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                            <Input.Text
                                value={values.uin}
                                label="UIN"
                                showErrors={showErrors || uinTouched}
                                onChange={this.onUinChanged}
                                onBlur={this.onUinTouched}
                                required
                                validation={[validators.validLength({ max: 12 })]}
                            />
                        )}
                        {duplicateUinUser &&
                            <Message as={Segment} className="cancel-action">
                                <p>A delegate with that UIN already exists:&nbsp;
                                    <Link href={`${delegatesBasePath}/${duplicateUinUser.id}`}>
                                        {duplicateUinUser.fullName}
                                    </Link>
                                </p>
                            </Message>
                        }
                        <Input.Text
                            value={values.forename}
                            label="Forename"
                            showErrors={showErrors}
                            required
                            onChange={this.onChange("forename")}
                        />
                        <Input.Text
                            value={values.surname}
                            label="Surname"
                            showErrors={showErrors}
                            required
                            onChange={this.onSurnameChanged}
                        />
                        {this.props.organisationCompanyTypes
                            && !this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver) && (
                            <AddressLookup
                                address={values.address}
                                showErrors={showErrors}
                                onChange={this.onChange("address")}
                                required={false}
                            />
                        )}
                        <h2>Contact Details</h2>
                        <Input.Email
                            value={values.email}
                            label="Email"
                            showErrors={showErrors}
                            onChange={this.onEmailChanged}
                        />
                        {searchingEmail &&
                            <>
                                <Message as={Segment} className="cancel-action">
                                    <Icon loading name='spinner' />
                                    Searching for existing delegates with that email...
                                </Message>
                                <Message as={Segment} className="cancel-action">
                                    <Icon loading name='spinner' />
                                    Validating email...
                                </Message>
                            </>
                        }
                        {duplicateEmailUser &&
                            <Message as={Segment} className="cancel-action">
                                <p>A delegate with that email already exists:&nbsp;
                                    <Link href={`${delegatesBasePath}/${duplicateEmailUser.id}`}>
                                        {duplicateEmailUser.fullName}
                                    </Link>
                                </p>
                            </Message>
                        }
                        {values.mxEmailCheckResult && !values.mxEmailCheckResult.valid &&
                            <Message as={Segment} className="cancel-action">
                                <p><Icon className="validation-icon" name={"exclamation circle"} />{values.mxEmailCheckResult.error}</p>
                            </Message>
                        }
                        <Input.Text
                            value={values.mobileNumber}
                            label="Mobile Number"
                            showErrors={showErrors}
                            validation={phoneNumberWithSpaces()}
                            onChange={this.onChange("mobileNumber")}
                        />
                        <h2>Licence Details</h2>
                        <Input.DropdownNumber
                            value={values.drivingLicenceCountry}
                            label="Driving Licence Country"
                            showErrors={this.state.showErrors}
                            required={delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber}
                            options={Object.keys(DrivingLicenceCountry).filter(k => +k !== 0).map(k => ({ text: DrivingLicenceCountry[k], value: +k }))}
                            onChange={this.onDrivingLicenceCountryChanged}
                            search
                        />
                        <Input.Text
                            value={values.licenceNumber}
                            label="Licence Number"
                            showErrors={showErrors || licenceNumberTouched}
                            onChange={this.onLiceneNumberChanged}
                            onBlur={this.onLicenceNumberTouched}
                            disabled={!values.drivingLicenceCountry}
                            required={delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber}
                            validation={[licenceValidator(values?.drivingLicenceCountry, values?.surname, [])]}
                            key={`${values?.drivingLicenceCountry || "country"}-${values?.surname || "surname"}`}
                        />
                        {duplicateLicenceNumberUser &&
                            <Message as={Segment} className="cancel-action">
                                <p>A delegate with that driving licence number already exists:&nbsp;
                                    <Link href={`${delegatesBasePath}/${duplicateLicenceNumberUser.id}`}>
                                        {duplicateLicenceNumberUser.fullName}
                                    </Link>
                                </p>
                            </Message>
                        }
                        {values.drivingLicenceCountry === DrivingLicenceCountryEnum.NonGBNI && (
                            <>
                                <Input.Text
                                    value={values.drivingLicenceExactCountry}
                                    label="Issuing Country"
                                    showErrors={showErrors}
                                    onChange={this.onChange("drivingLicenceExactCountry")}
                                />
                                <MuiDateField
                                    value={values.dateOfBirth}
                                    label="Date of Birth"
                                    showErrors={showErrors}
                                    onChange={this.onDateOfBirthChanged}
                                />
                            </>
                        )}
                        <Input.DropdownMulti
                            value={values.vocationalLicenceCategories}
                            label="Vocational Licence Categories"
                            showErrors={this.state.showErrors}
                            options={filteredVocationalLicenceCatogories(values.vocationalLicenceCategories)}
                            dynamicOptions
                            onChange={this.onChange("vocationalLicenceCategories")}
                            multiple
                            search />
                        {companyTypeSupportsDqcFields(this.props.organisationCompanyTypes) && (
                            <>
                                <h2>DQC Details</h2>
                                <Input.Text
                                    value={values.dqcReference}
                                    label="DQC Reference"
                                    showErrors={showErrors}
                                    onChange={this.onChange("dqcReference")} />
                                <MuiDateField
                                    value={values.dqcExpiry}
                                    label="DQC Expiry"
                                    showErrors={showErrors}
                                    onChange={this.onDqcExpiryChanged} />
                            </>
                        )}
                        {delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={12}>
                                        <h2>Managers</h2>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Button
                                            onClick={this.addManager}
                                            disabled={values.managers?.length >= 5}
                                            content="Add manager"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {values.managers?.map((m, index) => (
                                    <ManagerContactRow
                                        key={`manager-contact-${index}`}
                                        managerContact={m}
                                        index={index}
                                        showErrors={showErrors}
                                        updateManagerValue={this.updateManagerValue}
                                        removeManager={this.removeManager}
                                    />
                                ))}
                            </Grid>
                        )}
                        {this.state.attendeeFields && this.state.attendeeFields.length > 0 && (
                            <h2>Delegate Fields</h2>
                        )}
                        {this.state.attendeeFields?.map(af =>
                            (<DelegateAttendeeField
                                key={af.fieldId}
                                fieldConfiguration={af}
                                fieldValues={this.state.values.attendeeFieldValues}
                                onAttendeeFieldValueChange={this.onAttendeeFieldValueChange}
                            />)
                        )}
                    </Tab.Pane>
                );
            }
        }
    ];

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Spinner active={this.state.loading}>
                    <Tab panes={this.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                </Spinner>
            </Form>
        );
    }

    public submit = async () => {
        this.setState({ loading: true, showErrors: true });

        if (this.state.managersValid && this.state.managersValid.size !== 0) {
            this.setState(prevState => ({ ...prevState, showErrors: true, activeIndex: 0, loading: false }));
            toast.warning("Delegate cannot be edited as some managers have duplicate email addresses");
            return;
        }

        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            this.setState(prevState => ({ ...prevState, showErrors: true, activeIndex: 0, loading: false }));
            toast.error("Please complete required fields");
            return;
        }

        await this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private updateManagerValue = (index: number, field: string, value: string, valid: boolean) => {
        const validField = `contact.${index}.${field}`;
        const uniqueValidField = "contact.unique";
        const newContactValid = new Set(this.state.managersValid);

        if (valid && newContactValid.has(validField)) {
            newContactValid.delete(validField);
        }

        if (!valid && !newContactValid.has(validField)) {
            newContactValid.add(validField);
        }

        const newManagers = this.state.values.managers.map((existingManager, managerIndex) => managerIndex === index
            ? { ...existingManager, [field]: value }
            : existingManager);
        const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
        const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length ;

        if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
            newContactValid.delete(uniqueValidField);
        }

        if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
            newContactValid.add(uniqueValidField);
        }

        this.setState({ values: { ...this.state.values, managers: newManagers } });

        this.setState({ managersValid: newContactValid });
    };

    public fixContactsInvalid(indexRemoved: number, newManagers: { name: string; telephone: string; email: string }[]) {
        const newManagersValid = new Set<string>();
        this.state.managersValid.forEach(invalidField => {
            const parts = invalidField.split(".");

            if (parts[0] === "contact") {
                if (parts[1] !== "unique") {
                    if (+parts[1] < indexRemoved) {
                        newManagersValid.add(invalidField);
                    }

                    if (+parts[1] > indexRemoved) {
                        const newInvalidField = `${parts[0]}.${+parts[1] - 1}.${parts[2]}`;
                        newManagersValid.add(newInvalidField);
                    }
                } else {
                    const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
                    const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length;

                    if (hasDuplicates) {
                        newManagersValid.add(invalidField);
                    }
                }
            } else {
                newManagersValid.add(invalidField);
            }
        });
        this.setState({ managersValid: newManagersValid });
    }

    public removeManager(index: number) {
        const newManagers = this.state.values.managers.filter((_, managerIndex: number) => managerIndex !== index);
        this.updateProperty("managers", newManagers, true);
        this.fixContactsInvalid(index, newManagers);
    }

    public addManager(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateProperty(
            "managers",
            [ ...this.state.values.managers || [], { name: "", email: "" } ],
            true
        );
    }

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));

    private onDqcExpiryChanged = (value: moment.Moment, valid: boolean) => {
        if (this.state.values.dqcExpiry !== value) {
            this.updateProperty("dqcExpiry", value, valid);
        }
    };

    private onDateOfBirthChanged = (value: moment.Moment, valid: boolean) => {
        if (this.state.values.dateOfBirth !== value) {
            this.updateProperty("dateOfBirth", value, valid);
        }
    };
}

function mapStateToProps(state: AppState & OrganisationState & AuthState) {
    const open = state.router.pathname.endsWith("/edit");
    const organisation = organisationSelector(state) as Organisation;

    let model = delegateSelector(state) as DelegateCreateEditModel;
    if (model?.id && (model.managers?.length || 0) === 0) {
        model = {
            ...model,
            managers: [{ name: "", telephone: "", email: "" }]
        };
    }

    return {
        model,
        open,
        basePath: pathWithoutLastPartSelector(state),
        delegatesBasePath: basePathSelector(state),
        editPath: open ? editPathSelector(state) : null,
        organisationCompanyTypes: organisation.companyType
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (delegate: DelegateCreateEditModel, basePath: string) => dispatch(saveDelegate(delegate, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {
    const { model, open, basePath, delegatesBasePath, editPath } = propsFromState;
    const { dispatchSave, dispatchClose } = propsFromDispatch;
    const delegateApi = new DelegateApi();
    const emailApi = new EmailApi();
    const organisationApi = new OrganisationApi();
    return {
        model,
        open,
        editPath,
        delegatesBasePath,
        save: (delegate: DelegateCreateEditModel) => dispatchSave(delegate, basePath),
        close: () => dispatchClose(`${basePath}`),
        findUserByEmail: debounce((organisationId: string, email: string) => delegateApi.findByEmail(organisationId, email), 300),
        findUserByDrivingLicence: debounce((organisationId: string, drivingLicence: string) =>
            delegateApi.findByDrivingLicence(organisationId, drivingLicence), 300),
        findUserByUin: debounce((organisationId: string, uin: string) =>
            delegateApi.findByUin(organisationId, uin), 300),
        checkEmail: debounce((email: string) => emailApi.checkEmail(email), 300),
        loadAttendeeFields: (organisationId) => organisationApi.getAttendeeFields(organisationId, true),
        organisationCompanyTypes: propsFromState.organisationCompanyTypes
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
