import * as React from "react";
import { flushSync } from "react-dom";
import { Button } from "semantic-ui-react";

interface CourseEndLetterModalProps {
    children: React.ReactChild;
    buttonText?: string;
    disabled?: boolean;
}

export const CourseEndLetterModal: React.FC<CourseEndLetterModalProps> = ({ children, buttonText, disabled }) => {

    const [open, setOpen] = React.useState<boolean>(false);

    function openPrint() {
        window.onafterprint = closePrint;

        flushSync(() => {
            setOpen(true);
        });

        window.print();
    }

    function closePrint() {
        setOpen(false);
        removeEventListener("afterprint", closePrint);
    }

    return (
        <>
            {open && children}
            <Button content={buttonText ?? ""} onClick={openPrint} disabled={disabled} />
        </>
    );
};
