import { DateFormat } from "@common/crud/common/DateTimeFormats";
import moment from "moment";
import { EventInstance } from "../..";
import { DigitalPlanningSession } from "../../model";
import { AvailableDate } from "@common/availabilityTrainer/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export const CheckOverlappingEvents = (eventSeries: EventInstance[], existingEvents: EventInstance[], existingPlannedEvents: EventInstance[]) => {
    const errors = eventSeries.map(e => {
        return CheckForOverlappingEventsForEventInstance(e, existingEvents, existingPlannedEvents) ? `${e.startDate?.format(DateFormat.Short)}` : "";
    }).filter(m => m !== "");

    return errors.length > 0;
};

export const CheckForOverlappingEventsForEventInstance =
(eventInstance: EventInstance, existingEvents: EventInstance[], existingPlannedEvents: EventInstance[]): boolean => {
    const trainerId = eventInstance.trainerId;
    const trainerEvents =
        [...existingEvents.filter(e => hasAssignedTrainer(e, trainerId)), ...existingPlannedEvents.filter(e => hasAssignedTrainer(e, trainerId))];
    const practicalTrainerEvents = existingEvents.filter(e => hasAssignedPracticalTrainer(e, trainerId));
    return trainerEvents.some(t => theoryEventsOverlap(t, eventInstance)) || practicalTrainerEvents.some(t => practicalEventsOverlap(t, eventInstance));
};

export const hasAssignedTrainer = (eventInstance: EventInstance, trainerId: string) =>
    eventInstance?.trainerId === trainerId || eventInstance.trainerIds?.includes(trainerId);

export const hasAssignedPracticalTrainer = (eventInstance: EventInstance, trainerId: string) => eventInstance.practicalTrainersIds?.includes(trainerId);

const theoryEventsOverlap = (eventOne: EventInstance, eventTwo: EventInstance) => {
    const eventOneStart = eventOne?.startDate?.clone()?.add((eventOne?.theoryStartTime || eventOne?.startTime)?.clone());
    const eventOneEnd = eventOneStart?.clone()?.add((eventOne?.theoryDuration || eventOne?.eventDuration)?.clone());

    const eventTwoStart = eventTwo?.startDate?.clone()?.add(eventTwo?.startTime?.clone());
    const eventTwoEnd = eventTwoStart?.clone()?.add(eventTwo?.eventDuration?.clone());

    return eventOneStart < eventTwoEnd && eventTwoStart < eventOneEnd;
};

const practicalEventsOverlap = (eventOne: EventInstance, eventTwo: EventInstance) => {
    const eventOneStart = eventOne?.startDate?.clone()?.add((eventOne?.practicalStartTime || eventOne.startTime)?.clone());
    const eventOneEnd = eventOneStart?.clone()?.add((eventOne?.practicalDuration || eventOne?.eventDuration)?.clone());

    const eventTwoStart = eventTwo?.startDate?.clone()?.add(eventTwo?.startTime?.clone());
    const eventTwoEnd = eventTwoStart?.clone()?.add(eventTwo?.eventDuration?.clone());

    return eventOneStart < eventTwoEnd && eventTwoStart < eventOneEnd;
};

export const SetProtectedEventInstances = (normalEvents: EventInstance[], eventsToProtect: EventInstance[]): EventInstance[] => {
    eventsToProtect.forEach(e => e.protected = true);
    return [...(normalEvents ?? []), ...(eventsToProtect ?? [])];
};

export const GetDigitalPlanningForMonth = (sessions: DigitalPlanningSession[], month: moment.Moment) => {
    return sessions.find(s => s.month.month() === month?.month() && s.month.year() === month?.year());
};

export const GetDigitallyPlannedEventsForMonth = (month: moment.Moment, planningSessions: DigitalPlanningSession[], ddrs: boolean) => {
    const session = GetDigitalPlanningForMonth(planningSessions, month);
    const monthBefore = GetDigitalPlanningForMonth(planningSessions, month?.clone().add(-1, "month"));
    const monthAfter = GetDigitalPlanningForMonth(planningSessions, month?.clone().add(1, "month"));

    return ddrs ?
        SetProtectedEventInstances(session?.plannedDigitalEvents, [...(monthBefore?.plannedDigitalEvents ?? []), ...(monthAfter?.plannedDigitalEvents ?? [])])
        : session?.plannedDigitalEvents;
};

export const GetAllTrainerIdsForEventInstance = (eventInstanceId: string, events: EventInstance[]) => {
    const eventInstance = events?.find(e => e.id === eventInstanceId);
    if (eventInstance?.allTrainerIds?.length > 0) {
        return eventInstance.allTrainerIds;
    }
    const groupId = eventInstance?.groupId;
    if (groupId) {
        const groupMemberWithTrainerId = events.find(e => e.groupId === groupId && e.allTrainerIds?.length > 0);
        return groupMemberWithTrainerId?.allTrainerIds ?? [];
    }
    return [];
};

export const IsTrainerAllocatedToClassroomEiOnDay = (dayAvailability: AvailableDate, existingEventsInSelectedMonthForSelectedTrainer: EventInstance[]) =>
    existingEventsInSelectedMonthForSelectedTrainer.some(ei =>
        ei.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
        ei.startDate.isSame(dayAvailability.date));
