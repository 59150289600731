/* eslint-disable max-lines */
import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import * as actions from "./actiontypes";
import * as models from "./model";
import { OrderApi } from "./orderApi";
import { getBusinessLineTypePath, isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";
import { toast } from "@common/toasts";
import { PaymentResponse } from "@common/payments/model";
import { OrderGenesysApi } from "./orderGenesysApi";
import { OrderPaymentApi } from "./orderPaymentApi";
import { HistoryRecord } from "@common/history/model";
import { prepareOrdersUrl } from "./helpers";
import { CancelFromOrderModel } from "./model";
import { BusinessLineType } from "@common/redux-helpers";
import { Address } from "../common";

export type OrderAction =
    ({ type: actions.LOAD_ORDERS_SUCCESS } & Payload<models.OrderListResponse>) |
    ({ type: actions.LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS } & Payload<models.EventInstanceWithOrdersResponse>) |
    ({ type: actions.LOAD_ORDER_DETAIL_SUCCESS } & Payload<models.OrderDetailModel>) |
    ({ type: actions.LOAD_ORDER_DETAIL_SUCCESS } & Payload<models.OrderDetailModel>) |
    ({ type: actions.LOAD_ORDER_PAYMENT_SUCCESS } & Payload<models.StartOrganisationPaymentProcessRequest>) |
    ({ type: actions.MAKE_PAYMENT_SUCCESS } & Payload<PaymentResponse>) |
    ({ type: actions.MAKE_PAYMENT_FAILURE } & Payload<PaymentResponse>) |
    ({ type: actions.ORDER_REFUND_SUCCESS } & Payload<{ orderId: string; order: models.Order }>) |
    ({ type: actions.ORDER_REFUND_FAILURE } & Payload<{ orderId: string; requestedAmount: number; resultAmount: number; message: string;
        order: models.Order; }>) |
    ({ type: actions.LOAD_HISTORY_SUCCESS } & Payload<{ orderId: string; history: HistoryRecord[] }>) |
    ({ type: actions.FIX_EVENT_INSTANCES_WITH_ORDERS } & Payload<{ eventInstanceId: string; newCount: number }[]>);

export const loadOrdersSuccess = (payload: models.OrderListResponse): OrderAction => ({
    payload,
    type: actions.LOAD_ORDERS_SUCCESS
});

export const loadEventInstancesWithOrdersSuccess = (payload: models.EventInstanceWithOrdersResponse): OrderAction => ({
    payload,
    type: actions.LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS
});

export const fixEventInstancesWithOrders = (payload?: { eventInstanceId: string; newCount: number }[]): OrderAction => ({
    payload,
    type: actions.FIX_EVENT_INSTANCES_WITH_ORDERS
});

export const loadOrderDetailSuccess = (payload: models.OrderDetailModel) => ({
    payload,
    type: actions.LOAD_ORDER_DETAIL_SUCCESS
});

export const loadOrderPaymentSuccess = (payload: models.StartOrganisationPaymentProcessRequest) => ({
    payload,
    type: actions.LOAD_ORDER_PAYMENT_SUCCESS
});

export const makePaymentSuccess = (payload: PaymentResponse): OrderAction => ({
    payload,
    type: actions.MAKE_PAYMENT_SUCCESS
});

export const makePaymentFailure = (payload: PaymentResponse): OrderAction => ({
    payload,
    type: actions.MAKE_PAYMENT_FAILURE
});

export const refundSuccess = (payload: { orderId: string; order: models.Order }): OrderAction => ({
    payload,
    type: actions.ORDER_REFUND_SUCCESS
});

export const refundFailure = (payload: { orderId: string; requestedAmount: number; resultAmount: number; message: string;
    order: models.Order; }): OrderAction => ({
    payload,
    type: actions.ORDER_REFUND_FAILURE
});

export const loadHistorySuccess = (payload: { orderId: string; history: HistoryRecord[] }): OrderAction => ({
    payload,
    type: actions.LOAD_HISTORY_SUCCESS
});

export function loadOrders({ options }: { options?: models.SearchOptions }) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "loadOrders", api.getAllOrders(options));
        dispatch(loadOrdersSuccess(result));
    };
}

export function loadEventInstancesWithOrders({ options }: { options?: models.SearchOptions }) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "loadEventInstancesWithOrders", api.getAllEventInstancesWithOrders(options));
        dispatch(loadEventInstancesWithOrdersSuccess(result));
    };
}

export function clearReservations(orderId: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        await loadAndTrack(dispatch, "clearReservations", api.clearReservations(orderId));
    };
}

export function markInvoiceAsPaid(orderId: string, eventInstanceId: string, invoiceReference: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "markInvoiceAsPaid", api.markInvoiceAsPaid({ orderId, eventInstanceId, invoiceReference }));
        dispatch(loadOrderDetailSuccess(result));
    };
}

export function loadOrderDetail({ orderId, force }: { orderId: string; force?: boolean }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        if (isNullOrUndefinedOrEmptyString(orderId)) {
            return;
        }

        const order = getState().ordersState?.orders?.filter(c => c.id === orderId)[0];

        if (force
            || order === undefined
            || order.detailUpdated === undefined
            || (order.listUpdated && order.detailUpdated.isBefore(order.listUpdated))) {

            const api = new OrderApi();
            const result = await loadAndTrack(dispatch, "loadOrder", api.detail(orderId));
            dispatch(loadOrderDetailSuccess(result));
        }
    };
}

export function updateOrder(id: string, order: models.OrderEditModel) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "updateOrder", api.update(id, order));
        toast.success("Order updated successfully");
        dispatch(loadOrderDetailSuccess(result));
    };
}

export function updateInvoiceReference(id: string, reference: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "updateInvoiceReference", api.updateInvoiceReference(id, reference));
        toast.success("PO reference updated successfully");
        dispatch(loadOrderDetailSuccess(result));
    };
}

export function changeBooker(id: string, bookerId: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "updateOrder", api.changeBooker(id, bookerId));
        toast.success("Order updated successfully");
        dispatch(loadOrderDetailSuccess(result));
    };
}

export function sendConfirmation(orderCommsModel: { orderId: string; eventInstanceId?: string}) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        await loadAndTrack(dispatch, "sendConfirmation", api.sendConfirmation(orderCommsModel));
        toast.success("Confirmation sent successfully");
    };
}

export function sendReminders(orderCommsModel: { orderId: string; eventInstanceId: string; reminderType: models.ReminderType;
    recipientTypes: models.RecipientType[]; businessLineType: BusinessLineType; }) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        await loadAndTrack(dispatch, "sendReminders", api.sendReminders(orderCommsModel));
        toast.success("Reminders sent successfully");
    };
}

export function loadPayment(businessLineType: BusinessLineType, basketId: string, corporateOrganisationId?: string, corporateUserId?: string, query?: string,
    enquiryEventInstanceId?: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderPaymentApi();
        const payment = await loadAndTrack(dispatch, "loadPaymentRequest", api.startPaymentProcess(businessLineType, basketId));
        const eventManagementPath = getBusinessLineTypePath(businessLineType);
        switch (payment.status) {
            case "Ok": {
                dispatch(loadOrderPaymentSuccess(payment.startOrganisationPaymentProcessRequest));
                dispatch(
                    push(
                        prepareOrdersUrl(
                            eventManagementPath,
                            "orders/genesysAuth",
                            corporateOrganisationId,
                            corporateUserId,
                            query,
                            enquiryEventInstanceId
                        )
                    )
                );
                break;
            }

            case "NoLongerValid": {
                toast.error("Seats have been taken by another user.");
                dispatch(
                    push(
                        prepareOrdersUrl(
                            eventManagementPath,
                            "orders/createBasket",
                            corporateOrganisationId,
                            corporateUserId,
                            query,
                            enquiryEventInstanceId
                        )
                    )
                );
                break;
            }

            case "BasketGone":
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                break;

            default: {
                dispatch(
                    push(
                        prepareOrdersUrl(
                            eventManagementPath,
                            "orders/createBasket",
                            corporateOrganisationId,
                            corporateUserId,
                            query,
                            enquiryEventInstanceId
                        )
                    )
                );
                break;
            }
        }
    };
}

export function completeProcess(paymentRequest: models.OrganisationPaymentRequest, corporateOrganisationId?: string, corporateUserId?: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderPaymentApi();
        const paymentResponse = await loadAndTrack(dispatch, "makePayment", api.makePayment(paymentRequest));
        const eventManagementPath = getBusinessLineTypePath(paymentRequest.businessLineType);
        if (paymentResponse.validRequest) {
            if (paymentResponse.createdOrderId) {
                const orderApi = new OrderApi();
                const result = await loadAndTrack(dispatch, "loadOrder", orderApi.detail(paymentResponse.createdOrderId));
                dispatch(loadOrderDetailSuccess(result));
                dispatch(push(prepareOrdersUrl(eventManagementPath, `orders/${paymentResponse.createdOrderId}`, corporateOrganisationId, corporateUserId)));
            } else {
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
            }
        } else {
            toast.error("Payment failed");
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
        }

        return paymentResponse;
    };
}

export function bookNowPayLater(
    businessLineType: BusinessLineType,
    basketId: string,
    amount: number,
    invoiceReference?: string,
    invoiceEmail?: string,
    invoicePhoneNo?: string,
    invoiceAddress?: Address,
    corporateOrganisationId?: string,
    corporateUserId?: string,
    queryAddition?: string,
    enquiryEventInstanceId?: string) {
    return async (dispatch: Dispatch) => {
        const orderPaymentApi = new OrderPaymentApi();
        const paymentResponse = await loadAndTrack(dispatch, "bookNowPayLater", orderPaymentApi.bookNowPayLater(businessLineType, basketId, amount,
            invoiceReference, invoiceEmail, invoicePhoneNo, invoiceAddress));
        const eventManagementPath = getBusinessLineTypePath(businessLineType);
        if (paymentResponse.validRequest) {
            if (paymentResponse.createdOrderId)  {
                const orderApi = new OrderApi();
                const result = await loadAndTrack(dispatch, "loadOrder", orderApi.detail(paymentResponse.createdOrderId));
                dispatch(loadOrderDetailSuccess(result));
                const url = prepareOrdersUrl(
                    eventManagementPath,
                    `orders/${paymentResponse.createdOrderId}`,
                    corporateOrganisationId,
                    corporateUserId,
                    queryAddition,
                    enquiryEventInstanceId
                );

                if (enquiryEventInstanceId) {
                    window.open(url, "_blank");
                }

                dispatch(push(enquiryEventInstanceId
                    ? `${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`
                    : url
                ));
            } else {
                dispatch(push(enquiryEventInstanceId
                    ? `${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`
                    : prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId, queryAddition, enquiryEventInstanceId)));
            }
        } else {
            toast.error("Order failed");
            dispatch(push(enquiryEventInstanceId
                ? `${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`
                : prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
        }

        return paymentResponse;
    };
}

export function makePayment(paymentRequest: models.OrganisationPaymentRequest) {
    return async (dispatch: Dispatch) => {
        const api = new OrderPaymentApi();
        const paymentResponse = await loadAndTrack(dispatch, "makePayment", api.makePayment(paymentRequest));

        if (paymentResponse.validRequest) {
            dispatch(makePaymentSuccess(paymentResponse));
        }
        else {
            dispatch(makePaymentFailure(paymentResponse));
        }

        return paymentResponse;
    };
}

export function auditOrderAuthorisationBypass(genesysObjectType: models.GenesysObjectType, correlatedId: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderGenesysApi();
        await loadAndTrack(dispatch, "auditAuthorisationBypass", api.auditOrderAuthorisationBypass(genesysObjectType, correlatedId));
    };
}

export function pauseCallRecording(genesysObjectType: models.GenesysObjectType, correlatedId: string) {
    return async (dispatch: Dispatch) => {
        const api = new OrderGenesysApi();
        await loadAndTrack(dispatch, "pauseCallRecording", api.pauseCallRecording(genesysObjectType, correlatedId));
    };
}

export function cancelFromOrder(cancelFromOrderModel: CancelFromOrderModel) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "cancelFromOrder", api.cancelFromOrder(cancelFromOrderModel));
        dispatch(loadOrderDetailSuccess(result));
    };
}

export function refundFromOrder({ orderId, eventInstanceId, refundAmount, doNotRefundOnStripe }:
    { orderId: string; eventInstanceId?: string; refundAmount: number; doNotRefundOnStripe: boolean }) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const refundResponse = await loadAndTrack(dispatch, "refund", api.refundFromOrder({ orderId, eventInstanceId, refundAmount, doNotRefundOnStripe }));

        if (refundResponse.success) {
            toast.success("Refund successful");
            dispatch(refundSuccess({
                orderId,
                order: refundResponse.order
            }));

            const result = await loadAndTrack(dispatch, "loadOrder", api.detail(orderId));
            dispatch(loadOrderDetailSuccess(result));
        }
        else {
            toast.error(refundResponse.message);
            dispatch(refundFailure({
                orderId,
                requestedAmount: refundAmount,
                resultAmount: refundResponse.amount,
                message: refundResponse.message,
                order: refundResponse.order
            }));
        }

        dispatch(loadHistorySuccess({ orderId, history: refundResponse.order.history }));
    };
}

export function addNoteToOrder(orderId: string, noteText: string, file?: File) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "addNote", api.addNoteToOrder(orderId, noteText, file));
        dispatch(loadHistorySuccess({ orderId, history: result }));
    };
}

export function updateOrderNote(orderId: string, noteId: string, noteText: string, removeAttachment: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new OrderApi();
        const result = await loadAndTrack(dispatch, "addNote", api.updateOrderNote(orderId, noteId, noteText, removeAttachment));
        dispatch(loadHistorySuccess({ orderId, history: result }));
    };
}
