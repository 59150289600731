import * as React from "react";
import { Form, Header, Radio } from "semantic-ui-react";
import { ClearTrainerAvailabilityEnum } from "@common/crud/eventInstance/model";

interface Props {
    setClearTrainerAvailability: React.Dispatch<React.SetStateAction<ClearTrainerAvailabilityEnum>>;
    clearTrainerAvailability: ClearTrainerAvailabilityEnum;
    multiple?: boolean;
}

export const RemoveTrainerAvailability: React.FC<Props> = ({ clearTrainerAvailability, setClearTrainerAvailability, multiple }) => {
    return (
        <>
            <Header size="small">
                {multiple
                    ? "Would you like to clear the trainer's availability for the courses selected?"
                    : "Would you like to clear the trainer's availability?"
                }
            </Header>
            <Form>
                <Form.Field>
                    <Radio
                        label='No'
                        value={ClearTrainerAvailabilityEnum.No}
                        checked={clearTrainerAvailability === ClearTrainerAvailabilityEnum.No}
                        onChange={() => setClearTrainerAvailability(ClearTrainerAvailabilityEnum.No)}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={multiple ? "Yes, just for the sessions that were selected" : "Yes, just for this session"}
                        value={ClearTrainerAvailabilityEnum.Session}
                        checked={clearTrainerAvailability === ClearTrainerAvailabilityEnum.Session}
                        onChange={() => setClearTrainerAvailability(ClearTrainerAvailabilityEnum.Session)}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={multiple ? "Yes, for the full days that were selected" : "Yes, for the full day"}
                        value={ClearTrainerAvailabilityEnum.FullDay}
                        checked={clearTrainerAvailability === ClearTrainerAvailabilityEnum.FullDay}
                        onChange={() => setClearTrainerAvailability(ClearTrainerAvailabilityEnum.FullDay)}
                    />
                </Form.Field>
            </Form>
        </>
    );
};
