import * as React from "react";
import { useSelector } from "react-redux";
import { BookingWrapper } from "./global/BookingWrapper";
import { ApplicationState } from "./applicationState";
import { RouteFragments } from "./RouteFragments";
import { MediaContextProvider, mediaStyles } from "@common/global/AppMedia";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Hotjar from "@hotjar/browser";

export const App = () => {
    const isLoading = useSelector((state: ApplicationState) => state.loading.active);
    const appSettings = useSelector((state: ApplicationState) => state.appSettings);
    const language = useSelector((state: ApplicationState) => state.language);

    React.useEffect(() => {
        const { policeBookingAppSiteId: siteId, hotjarVersion } = appSettings.hotjarSettings;

        if (siteId && hotjarVersion) {
            Hotjar.init(siteId, hotjarVersion);
        }

    },[appSettings.hotjarSettings]);

    return (
        <>
            <style>{mediaStyles}</style>
            <MediaContextProvider>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={language.current}>
                    <BookingWrapper
                        loading={isLoading}
                        appSettings={appSettings}
                        routeFragments={RouteFragments}
                    />
                </MuiPickersUtilsProvider>
            </MediaContextProvider>
        </>
    );
};
