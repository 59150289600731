import * as React from "react";
import { Grid } from "semantic-ui-react";
import { calculateVAT, calculateGrossAmount } from "@common/trainerInvoices";
import { numberFormatter } from "@common/helpers/number-format";

interface InvoiceTotalsFooterProps {
    invoiceTotal: number;
    vatPercentage?: number;
}

export const InvoiceTotalsFooter: React.FC<InvoiceTotalsFooterProps> = ({ invoiceTotal, vatPercentage }) => {

    const vatAmount = calculateVAT(invoiceTotal, vatPercentage);
    const grossAmount = calculateGrossAmount(invoiceTotal, vatPercentage);

    if (!vatPercentage) {
        return (
            <Grid celled divided>
                <Grid.Row>
                    <Grid.Column className="original" width={12} textAlign={"right"}>
                        <p>Total</p>
                    </Grid.Column>
                    <Grid.Column className="original" width={4} textAlign={"center"}>
                        <p>£{numberFormatter(invoiceTotal)}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    return (
        <Grid celled divided>
            <Grid.Row>
                <Grid.Column className="original" width={12} textAlign={"right"}>
                    <p>Net</p>
                    <p>VAT (20%)</p>
                    <p>Gross</p>
                </Grid.Column>
                <Grid.Column className="original" width={4} textAlign={"center"}>
                    <p>£{numberFormatter(invoiceTotal)}</p>
                    <p>£{numberFormatter(vatAmount)}</p>
                    <p>£{numberFormatter(grossAmount)}</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
