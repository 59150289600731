import * as React from "react";
import moment from "moment";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { v4 } from "uuid";

interface MuiDateFieldProps {
    value?: moment.Moment;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    showErrors?: boolean;
    format?: string;
    onChange: (value: moment.Moment, valid: boolean) => void;
    validation?: ((value: moment.Moment) => string | Promise<string>)[];
    smallButton?: boolean;
    shouldDisableDate?: (date: moment.Moment) => boolean;
    cancelLabel?: string;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
}

const MuiDateField = (props: MuiDateFieldProps) => {
    const { value, label, placeholder, required, disabled, showErrors, onChange, validation, format, smallButton, shouldDisableDate, cancelLabel,
        minDate, maxDate } = props;
    const [stateValue, setStateValue] = React.useState<moment.Moment>(undefined);
    const [settingUnblurredValue, setSettingUnblurredValue] = React.useState<boolean>(false);
    const [unblurredValue, setUnblurredValue] = React.useState<moment.Moment>(undefined);
    const [touched, setTouched] = React.useState<boolean>(false);
    const [dirty, setDirty] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [dragging, setDragging] = React.useState<boolean>(false);
    const [mouseDown, setMouseDown] = React.useState<boolean>(false);
    const [allSelected, setAllSelected] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const idRef = React.useRef(v4());

    function selectOnClick() {
        inputRef.current.focus();
        const text = inputRef.current.value;
        let cursorPosition = inputRef.current.selectionStart;
        let startIndex;
        let endIndex;

        if (text.charAt(cursorPosition) === "/") {
            cursorPosition --;
        }

        for (let i = cursorPosition; i >= 0; i--) {
            if (i === 0 || text.charAt(i - 1)==="/") {
                startIndex = i;
                break;
            }
        }

        for (let i = cursorPosition; i <= text.length; i++) {
            if (i + 1 >= text.length || text.charAt(i + 1)==="/") {
                endIndex = i;
                break;
            }
        }

        inputRef.current.setSelectionRange(startIndex, endIndex + 1);
    }

    function onMouseDown() {
        setMouseDown(true);
    }

    function onMouseUp() {
        if (!dragging && !allSelected) {
            selectOnClick();
        }

        endDrag();
    }

    function endDrag() {
        setMouseDown(false);
        setDragging(false);
        setAllSelected(inputRef.current.selectionStart === 0 && inputRef.current.selectionEnd === inputRef.current.value.length);
    }

    function onMouseMove() {
        if (mouseDown) {
            setDragging(true);
        }
    }

    function onDoubleClick() {
        inputRef.current.focus();
        inputRef.current.select();
        setAllSelected(true);
    }

    React.useEffect(() => {
        const innerInput = document.getElementById(idRef.current) as HTMLInputElement;
        inputRef.current = innerInput;
    },[]);

    const usedFormat = React.useMemo(() => format || "DD/MM/YYYY", [format]);

    React.useEffect(() => {
        if (value !== stateValue) {
            setStateValue(value);
            setDirty(true);
        }
    }, [stateValue, value]);

    const labelFunc = React.useCallback(() => value ? moment(value).format(usedFormat) : placeholder || usedFormat, [placeholder, value, usedFormat]);

    const validateValue = React.useCallback( (validatedValue: moment.Moment): string | Promise<string> => {
        if (required && !validatedValue) {
            return "This field is required";
        }

        if (validatedValue && !validatedValue.isValid()) {
            return "This date is invalid";
        }

        if (validation && validation.length > 0) {
            const validationResults = validation.map(v => v(validatedValue)).filter(vr => vr);
            if (validationResults.length > 0) {
                return validationResults[0];
            }
        }

        return undefined;
    }, [required, validation]);

    const onAcceptComponentLevel = React.useCallback( async (newValue: moment.Moment) => {
        const valueWithRemovedTime = newValue
            ? moment(newValue.startOf("day")).utc(true)
            : undefined;
        const validationError = await validateValue(valueWithRemovedTime);
        setError(validationError);
        onChange(valueWithRemovedTime, !validationError);
        setSettingUnblurredValue(false);
        setUnblurredValue(undefined);
    }, [onChange, validateValue]);

    const onChangeComponentLevel = React.useCallback( async (newValue: moment.Moment) => {
        const valueWithRemovedTime = newValue
            ? moment(newValue.startOf("day")).utc(true)
            : undefined;
        const validationError = await validateValue(valueWithRemovedTime);
        setError(validationError);
        setSettingUnblurredValue(true);
        setUnblurredValue(valueWithRemovedTime);
    }, [validateValue]);

    const onBlurred = React.useCallback(() => {
        setTouched(true);
        if (settingUnblurredValue) {
            onChange(unblurredValue, !error);
            setSettingUnblurredValue(false);
            setUnblurredValue(undefined);
        }
    }, [error, onChange, settingUnblurredValue, unblurredValue]);

    const onInteract = React.useCallback(() => {
        setTouched(true);
    }, []);

    return (
        <div className="field">
            {label && (
                <label>{label}</label>
            )}
            <KeyboardDatePicker
                shouldDisableDate={shouldDisableDate}
                value={value}
                labelFunc={labelFunc}
                format={usedFormat}
                disabled={disabled}
                onAccept={onAcceptComponentLevel}
                onChange={onChangeComponentLevel}
                onBlur={onBlurred}
                onOpen={onInteract}
                error={!!error}
                okLabel=""
                clearLabel=""
                cancelLabel={cancelLabel}
                InputProps={{ disableUnderline: true, className: "ui labeled input", id: idRef.current,
                    onDoubleClick, onMouseDown, onMouseUp, onMouseMove, onMouseLeave: endDrag }}
                className={`mui-field${smallButton ? " small-mui-button" : ""}${(value || unblurredValue) ? "": " placeholder no-value-mui-field"}`}
                autoOk
                minDate={minDate}
                maxDate={maxDate}
            />
            { ((showErrors || (dirty && touched)) && error) && <div className="ui red pointing above basic label"><p>{error}</p></div> }
        </div>
    );
};

export { MuiDateField };
