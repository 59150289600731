import * as React from "react";
import { ManagementContactDetails } from "../model";
import { ManagementContactDetailItem } from "./ManagementContactDetailItem";

export interface ManagementContactDetailsProps {
    regionalManagers: ManagementContactDetails[];
    regionalCoordinators: ManagementContactDetails[];
    allowEditing?: boolean;
    addRegionalManager?: (manager: ManagementContactDetails) => void;
    removeRegionalManager?: (id: string) => () => void;
    addRegionalCoordinator?: (manager: ManagementContactDetails) => void;
    removeRegionalCoordinator?: (id: string) => () => void;
}

export const ManagementContactDetailsList: React.FC<ManagementContactDetailsProps> =
({ regionalCoordinators, regionalManagers, allowEditing, addRegionalCoordinator, addRegionalManager, removeRegionalCoordinator, removeRegionalManager }) => {

    return (
        <>
            <ManagementContactDetailItem
                title={"Regional Management"}
                managerType={"Regional Manager"}
                managers={regionalManagers}
                addManager={addRegionalManager}
                removeManager={removeRegionalManager}
                allowEditing={allowEditing}
            />

            <ManagementContactDetailItem
                title={"Regional Coordinators"}
                managerType={"Regional Coordinator"}
                managers={regionalCoordinators}
                addManager={addRegionalCoordinator}
                removeManager={removeRegionalCoordinator}
                allowEditing={allowEditing}
            />
        </>
    );
};
