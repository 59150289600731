import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form, Message } from "semantic-ui-react";
import { AttendeeApi } from "../../attendeeApi";
import { VerifyStripePaymentResult, VerifyStripePaymentStatus } from "../../model";
import { useDispatch } from "react-redux";
import { loadAttendeeDetailSuccess } from "../../actions";

export interface AddPaymentModalProps {
    attendeeId: string;
    correlationId: string;
    eventInstanceId: string;
}

export const AddPaymentModal: React.FC<AddPaymentModalProps> = ({ attendeeId, correlationId, eventInstanceId }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [paymentAuthCode, setPaymentAuthCode] = React.useState<string>("");
    const [verificationResult, setVerificationResult] = React.useState<VerifyStripePaymentResult>({ status: VerifyStripePaymentStatus.Unknown, message: "" });
    const [callInProgress, setCallInProgress] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (open) {
            setPaymentAuthCode("");
            setVerificationResult({ status: VerifyStripePaymentStatus.Unknown, message: "" });
        }
    }, [open]);

    const onOpenClick = React.useCallback(() => setOpen(true), []);
    const onCloseClick = React.useCallback(() => setOpen(false), []);

    const onPaymentAuthCodeChange = React.useCallback((_: any, { value }) => {
        setPaymentAuthCode(value);
        if (verificationResult.status !== VerifyStripePaymentStatus.Unknown) {
            setVerificationResult({ status: VerifyStripePaymentStatus.Unknown, message: "" });
        }
    }, [verificationResult]);

    const onVerifyClick = React.useCallback(async () => {
        setCallInProgress(true);
        try {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const result = await attendeeApi.verifyPayment(attendeeId, correlationId, eventInstanceId, paymentAuthCode);
            setVerificationResult(result);
        } finally {
            setCallInProgress(false);
        }
    }, [attendeeId, correlationId, eventInstanceId, paymentAuthCode]);

    const onSaveClick = React.useCallback(async () => {
        setCallInProgress(true);
        try {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const attendee = await attendeeApi.savePayment(attendeeId, correlationId, eventInstanceId, paymentAuthCode);
            dispatch(loadAttendeeDetailSuccess(attendee));
            setOpen(false);
        } finally {
            setCallInProgress(false);
        }
    }, [attendeeId, correlationId, dispatch, eventInstanceId, paymentAuthCode]);

    const messageColor = React.useMemo(() => {
        switch (verificationResult.status) {
            case VerifyStripePaymentStatus.Error: return "red";
            case VerifyStripePaymentStatus.Warning: return "yellow";
            case VerifyStripePaymentStatus.Success: return "green";
            default: return "red";
        }
    }, [verificationResult]);

    return (
        <>
            <Button className="button-margin" onClick={onOpenClick}>Add Payment</Button>

            <Modal open={open}>
                <Modal.Header>
                    Add Payment
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            Please enter the CH reference of the payment you are adding to this attendee
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label="CH reference"
                                value={paymentAuthCode || ""}
                                onChange={onPaymentAuthCodeChange}
                            />
                        </Form.Field>
                        {verificationResult.status !== VerifyStripePaymentStatus.Unknown && (
                            <Form.Field>
                                <Message color={messageColor}>{verificationResult.message}</Message>
                            </Form.Field>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <div className="modal-margin-bottom">
                        <Button onClick={onCloseClick} negative floated="left">
                            Cancel
                        </Button>
                        <Button
                            onClick={onSaveClick}
                            positive
                            floated="right"
                            disabled={verificationResult.status === VerifyStripePaymentStatus.Unknown
                                || verificationResult.status === VerifyStripePaymentStatus.Error
                                || callInProgress}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onVerifyClick}
                            positive
                            floated="right"
                            disabled={callInProgress}
                        >
                            Verify
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
};
