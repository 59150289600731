/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { PushAction, push } from "redux-little-router";
import { Form, Tab, TabProps, DropdownItemProps, Checkbox, CheckboxProps, Segment, Message, Icon } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, BusinessLineType } from "@common/redux-helpers";
import { optionsFromObject } from "@common/crud/common";
import { phoneNumberWithSpaces, vatNumber, sortCode, bankAccountNumber } from "@common/validation";
import { TrainerAttributesPicker } from "@common/crud/common/TrainerAttributesPicker";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { TrainerAttributeDefinitionState, TrainerAttributeDefinition } from "@common/crud/trainerAttributeDefinition";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import {
    CompanyType,
    CompanyTypeEnum,
    TrainerCreateEditModel,
    AppState,
    ADTrainerUser,
    TrainerEmploymentType,
    TrainerDetailModel,
    AttributeRoutes,
    AttributeRoute
} from "../model";
import { saveTrainer } from "../actions";
import { trainerSelector, pathWithoutLastPartSelector, editPathSelector, basePathSelector } from "../selectors";
import { EditModal } from "./EditModal";
import { policeOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { OrganisationState } from "@common/crud/organisation";
import { AuthState, TtcFinanceAdministratorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { toast } from "@common/toasts";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { Spinner } from "@common/global";
import { CountryDropDownOptions } from "@common/crud/organisation/model";
import { debounce } from "@neworbit/simpleui-utils";
import { TrainerApi } from "../trainerApi";
import { DuplicateUserMessage } from "./DuplicateUserMessage";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { routerQuerySelector } from "@common/crud/common/selectors";
import { TrainerRouterQuery } from "./Detail";
import * as QueryString from "query-string";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { MultiSelectCheckbox } from "@common/components";

interface EditProps extends SharedEditProps<TrainerCreateEditModel> {
    open: boolean;
    isFinanceAdministrator: boolean;
    trainerAttributeDefinitions: TrainerAttributeDefinition[];
    organisationOptions: DropdownItemProps[];
    editPath: string;
    query: TrainerRouterQuery;
    trainersBasePath: string;
    findUser: (email: string) => Promise<ADTrainerUser>;
    updateTabRoute: (section: AttributeRoute) => PushAction;
    isTrainerAnonymized: boolean;
    modalHeader: string;
}

interface DispatchProps extends SaveDispatchProps<TrainerCreateEditModel> {
    close: () => void;
}

export type AllEditProps = EditProps & DispatchProps;

interface EditState extends FormState<TrainerCreateEditModel> {
    activeIndex: string | number;
    isVatRegistered: boolean;
    hasNdorsLicence: boolean;
    duplicateUser: ADTrainerUser;
    searchingEmail: boolean;
    removeADB2CConfirmed: boolean;
    isTrainerAnonymized: boolean;
    businessLineTypes: number[];
}

export class EditForm extends EditComponent<TrainerCreateEditModel, AllEditProps, EditState> {

    private filteredAttributeRoutes = AttributeRoutes;

    public componentWillReceiveProps(nextProps: Readonly<AllEditProps>) {
        // If we don't do this new orbit EditComponent will overwrite state changes with the original model when we change tabs.
        // referential equality check is sufficient
        if (nextProps.model !== this.props.model) {
            super.componentWillReceiveProps(nextProps);
        }

        this.setState({
            isVatRegistered: nextProps.model.vatNumber !== "",
            activeIndex: nextProps.query?.section? this.filteredAttributeRoutes.indexOf(nextProps.query.section) : 0,
            loading: false,
            hasNdorsLicence: !!nextProps.model.ndorsLicenceNumber ?? false,
            isTrainerAnonymized: nextProps.isTrainerAnonymized,
        });
    }

    private getDorsCourtAndAdhocAttributes = (attributes:  TrainerAttributeDefinition[]) =>
        attributes?.filter((a) => a.isDorsRelated || a.isDdrs ||
        (!a.isCompliance && !a.isCpc && !a.isWorkshop && !a.isOnRoad && !a.isBusinessDriver &&
        !a.isConstruction && !a.isCitb && !a.isAps
        ));

    private getCommercialAttributes = (attributes: TrainerAttributeDefinition[]) =>
        attributes?.filter((a) => a.isCpc || a.isWorkshop || a.isOnRoad || a.isBusinessDriver);

    private getComplianceAttributes = (attributes: TrainerAttributeDefinition[]) => attributes?.filter((a) =>
        a.isCompliance);

    private getConstructionAttributes = (attributes: TrainerAttributeDefinition[]) => attributes?.filter((a) =>
        a.isConstruction || a.isCitb || a.isAps);

    private onChange = (k: keyof TrainerCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private onNestedPropertyChange = (k: string) => (value: any, valid: boolean) => {
        this.updateNestedProperty(k, value, valid);
    };

    private updateDateProperty = (k: keyof TrainerCreateEditModel) => {
        return (value: any, valid: boolean) => this.updateProperty(k, value, valid);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const duplicateUser = await this.props.findUser(value);
            this.setState({ searchingEmail: false, duplicateUser, removeADB2CConfirmed: false, valid: { ...this.state.valid, email: !duplicateUser } });
        } else {
            this.setState({ duplicateUser: null, removeADB2CConfirmed: false });
        }
    };

    private onIsVatRegisteredChange = (_: any, { checked }: CheckboxProps) => this.setState(prevState => ({
        ...prevState,
        isVatRegistered: checked,
        values: {
            ...prevState.values,
            vatNumber: "",
        }
    }));

    private onRemoveADB2CConfirm = (_: any, { checked }: CheckboxProps) =>
        this.setState((prevState) =>
            ({ ...this.state,
                values: { ...this.state.values,
                    adB2CAccountForRemovalId: checked ? prevState.duplicateUser.adB2CId : null },
                removeADB2CConfirmed: checked,
                valid: { ...this.state.valid, email: checked }
            }));

    private setBusinessLineTypes = (update: (arr: number[]) => number[]) =>
    {
        const businessLineTypes = update(this.state.values.businessLineTypes);
        this.setState(() =>
            ({ ...this.state,
                values: { ...this.state.values, businessLineTypes },
                valid: { ...this.state.valid,
                    businessLineTypes: businessLineTypes.length > 0 }
            }));
    };

    private buildPanes = () =>
    {
        const panes = [];
        this.filteredAttributeRoutes = [];
        panes.push({
            index: 0, menuItem: "Details", editPath: "", render: () => {
                const { values, showErrors, duplicateUser, searchingEmail } = this.state;
                const { trainersBasePath } = this.props;
                const limitedCompanySelected = values.companyType === CompanyTypeEnum.LimitedCompany;

                return (
                    <Tab.Pane key="mainPane">
                        <Input.Text
                            value={values.title}
                            label="Title"
                            showErrors={showErrors}
                            onChange={this.onChange("title")}
                        />
                        <Input.Text
                            value={values.forename}
                            label="Forename"
                            showErrors={showErrors}
                            required
                            onChange={this.onChange("forename")}
                        />
                        <Input.Text
                            value={values.surname}
                            label="Surname"
                            showErrors={showErrors}
                            required
                            onChange={this.onChange("surname")}
                        />
                        <Input.DropdownNumber
                            label="Employment Type"
                            showErrors={showErrors}
                            value={values.employmentType}
                            options={optionsFromObject(TrainerEmploymentType)}
                            onChange={this.onChange("employmentType")}
                        />
                        <>
                            <span>Line of Business</span> <span className="required-asterisk">*</span>
                            <MultiSelectCheckbox
                                label="Police and Courts"
                                value={BusinessLineType.PoliceAndCourt}
                                selectedValues={this.state.values.businessLineTypes}
                                onValuesChanged={this.setBusinessLineTypes}
                            />
                            <MultiSelectCheckbox
                                label="Commercial"
                                value={BusinessLineType.Corporate}
                                selectedValues={this.state.values.businessLineTypes}
                                onValuesChanged={this.setBusinessLineTypes}
                            />
                            <MultiSelectCheckbox
                                label="Construction"
                                value={BusinessLineType.Construction}
                                selectedValues={this.state.values.businessLineTypes}
                                onValuesChanged={this.setBusinessLineTypes}
                            />
                            {showErrors && this.state?.valid?.businessLineTypes === false &&
                                <div className="ui red pointing above basic label"><p>This field is required</p></div>
                            }
                        </>
                        <MuiDateField
                            value={values.expiryDate}
                            label="Expiry Date"
                            disabled={this.state.isTrainerAnonymized}
                            showErrors={showErrors}
                            validation={[muiTodayOrfutureDateValidator]}
                            onChange={this.updateDateProperty("expiryDate")}
                        />
                        <AddressLookup
                            address={values.address}
                            showErrors={showErrors}
                            onChange={this.onChange("address")}
                        />
                        <Authorize authorize={TtcFinanceAdministratorRole}>
                            <h2>Company Information</h2>
                            <Checkbox label="VAT Registered?" onClick={this.onIsVatRegisteredChange} checked={this.state.isVatRegistered} />
                            {this.state.isVatRegistered && (
                                <Input.Text
                                    value={values.vatNumber}
                                    label="Vat Number"
                                    showErrors={showErrors}
                                    validation={vatNumber()}
                                    onChange={this.onChange("vatNumber")}
                                />
                            )}
                            <Input.DropdownNumber
                                value={values.companyType}
                                label="Company Type"
                                showErrors={showErrors}
                                options={optionsFromObject(CompanyType)}
                                onChange={this.onChange("companyType")}
                                required
                            />
                            {limitedCompanySelected && (
                                <>
                                    <Input.Text
                                        value={values.companyName}
                                        label="Company Name"
                                        showErrors={showErrors}
                                        onChange={this.onChange("companyName")}
                                    />
                                    <Input.Text
                                        value={values.companyRegistrationNumber}
                                        label="Company Registration Number"
                                        showErrors={this.state.showErrors}
                                        onChange={this.onChange("companyRegistrationNumber")}
                                    />
                                    <AddressLookup
                                        address={values.companyAddress}
                                        onChange={this.onChange("companyAddress")}
                                        showErrors={showErrors}
                                        title={"Company Address"}
                                        required={false}
                                    />
                                </>
                            )}
                            <h2>Bank Account</h2>
                            <Input.Text
                                value={values.bankAccount && values.bankAccount.accountName}
                                label="Account Name"
                                showErrors={showErrors}
                                onChange={this.onNestedPropertyChange("bankAccount.accountName")}
                            />
                            <Input.Text
                                value={values.bankAccount && values.bankAccount.sortCode}
                                label="Sort Code"
                                showErrors={showErrors}
                                validation={sortCode()}
                                onChange={this.onNestedPropertyChange("bankAccount.sortCode")}
                            />
                            <Input.Text
                                value={values.bankAccount && values.bankAccount.accountNumber}
                                label="Account Number"
                                showErrors={showErrors}
                                validation={bankAccountNumber()}
                                onChange={this.onNestedPropertyChange("bankAccount.accountNumber")}
                            />
                        </Authorize>
                        <Input.Email
                            value={values.email}
                            label="Email"
                            showErrors={showErrors}
                            required
                            onChange={this.onEmailChanged}
                        />
                        {searchingEmail &&
                        <Message as={Segment} className="cancel-action">
                            <Icon loading name='spinner' />
                            Searching for existing users with that email...
                        </Message>
                        }
                        {duplicateUser &&
                            <DuplicateUserMessage
                                duplicateUser={duplicateUser}
                                basePath={trainersBasePath}
                                removeADB2CConfirmed={this.state.removeADB2CConfirmed}
                                onCheck={this.onRemoveADB2CConfirm} />
                        }
                        <Input.Text
                            value={values.mobileNumber}
                            label="Mobile Number"
                            showErrors={showErrors}
                            validation={phoneNumberWithSpaces()}
                            onChange={this.onChange("mobileNumber")}
                        />
                        <Input.Text
                            value={values.homeNumber}
                            label="Home Number"
                            showErrors={showErrors}
                            validation={phoneNumberWithSpaces()}
                            onChange={this.onChange("homeNumber")}
                        />
                        <h2>Emergency Contact</h2>
                        <Input.Text
                            value={values.emergencyContact && values.emergencyContact.name}
                            label="Name"
                            showErrors={showErrors}
                            onChange={this.onNestedPropertyChange("emergencyContact.name")}
                        />
                        <Input.Text
                            value={values.emergencyContact && values.emergencyContact.phoneNumber}
                            label="Phone Number"
                            showErrors={showErrors}
                            validation={phoneNumberWithSpaces()}
                            onChange={this.onNestedPropertyChange("emergencyContact.phoneNumber")}
                        />
                    </Tab.Pane>
                );
            }
        });

        const trainerBusinessLineTypes = this.state.values.businessLineTypes;

        if (trainerBusinessLineTypes?.includes(BusinessLineType.PoliceAndCourt) || trainerBusinessLineTypes?.length === 0) {
            panes.push({
                index: 1, menuItem: "Ndors, Court & Adhoc Attributes", editPath: "/attributes", render: () => (
                    <Tab.Pane key="nDorsAndCourtAttributesPane">
                        <TrainerAttributesPicker
                            trainerAttributeDefinitions={this.getDorsCourtAndAdhocAttributes(this.props.trainerAttributeDefinitions)}
                            trainerAttributes={this.state.values.trainerAttributes}
                            showErrors={false}
                            onChange={this.onChange("trainerAttributes")}
                        />
                    </Tab.Pane>
                )
            });

            this.filteredAttributeRoutes.push(AttributeRoutes[0]);
        }

        if (trainerBusinessLineTypes?.includes(BusinessLineType.Corporate)) {
            panes.push({
                index: 2, menuItem: "Commercial Attributes", editPath: "/attributes", render: () => (
                    <Tab.Pane key="commericalAttributesPane">
                        <TrainerAttributesPicker
                            trainerAttributeDefinitions={this.getCommercialAttributes(this.props.trainerAttributeDefinitions)}
                            trainerAttributes={this.state.values.trainerAttributes}
                            showErrors={false}
                            onChange={this.onChange("trainerAttributes")}
                        />
                    </Tab.Pane>
                )
            });

            this.filteredAttributeRoutes.push(AttributeRoutes[1]);
        }

        if (trainerBusinessLineTypes?.includes(BusinessLineType.Construction)) {
            panes.push({
                index: 3, menuItem: "Construction Attributes", editPath: "/attributes", render: () => (
                    <Tab.Pane key="constructionAttributesPane">
                        <TrainerAttributesPicker
                            trainerAttributeDefinitions={this.getConstructionAttributes(this.props.trainerAttributeDefinitions)}
                            trainerAttributes={this.state.values.trainerAttributes}
                            showErrors={false}
                            onChange={this.onChange("trainerAttributes")}
                        />
                    </Tab.Pane>
                )
            });

            this.filteredAttributeRoutes.push(AttributeRoutes[2]);
        }

        panes.push({
            index: 4, menuItem: "Compliance Attributes", editPath: "/attributes", render: () => (
                <Tab.Pane key="complianceAttributesPane">
                    <TrainerAttributesPicker
                        trainerAttributeDefinitions={this.getComplianceAttributes(this.props.trainerAttributeDefinitions)}
                        trainerAttributes={this.state.values.trainerAttributes}
                        showErrors={false}
                        onChange={this.onChange("trainerAttributes")}
                    />
                </Tab.Pane>
            )
        });

        this.filteredAttributeRoutes.push(AttributeRoutes[3]);

        panes.push({
            index: 5, menuItem: "Areas", editPath: "/areas", render: () => (
                <Tab.Pane key="areasPane">
                    <Input.DropdownMulti
                        value={this.state.values.areaIds}
                        label="NDORS Police Areas"
                        options={this.props.organisationOptions}
                        onChange={this.onChange("areaIds")}
                    />
                    <Input.DropdownMulti
                        value={this.state.values.ddrsCountries}
                        label="DDRS Countries"
                        options={CountryDropDownOptions}
                        onChange={this.onChange("ddrsCountries")}
                    />
                </Tab.Pane>
            )
        });

        panes.push({
            index: 6, menuItem: "Management", editPath: "/management", render: () => (
                <Tab.Pane key="managementPane">
                    <Input.Dropdown
                        value={this.state.values.homeArea}
                        label="Home Area"
                        options={this.props.organisationOptions}
                        onChange={this.onChange("homeArea")}
                    />
                </Tab.Pane>
            )
        });

        return panes;
    };

    private panes = this.buildPanes();

    public render() {
        // if we've come from base detail (empty) path or the company info tab return the detail tab (empty path)
        // otherwise return the tab(s) with equivalent path to the detail page we've come from
        return (
            <Form onSubmit={this.handleSubmit}>
                <Spinner active={this.state.loading}>
                    <Tab panes={this.panes.filter(x => !this.props.editPath ||
                        this.props.editPath ==="/companyInformation"? !x.editPath : x.editPath.includes(this.props.editPath))}
                    activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                </Spinner>
            </Form>
        );
    }

    public submit = async () => {
        this.setState({ loading: true });
        if (this.props.isFinanceAdministrator) {
            if ((this.state.isVatRegistered && !this.state.values.vatNumber) ||
                this.state.values.companyType === CompanyTypeEnum.Unknown ||
                (this.state.values.companyType === CompanyTypeEnum.LimitedCompany && (!this.state.values.companyName || !this.state.values.companyAddress))
            ) {
                this.setState(prevState => ({ ...prevState, activeIndex: 0, loading: false, showErrors: true }));
                toast.error("Please complete the Company Information");
                return;
            }
        }

        if (ObjectKeys(this.state.valid).some(k => !this.state.valid[k])) {
            this.setState(prevState => ({ ...prevState, activeIndex: 0, loading: false, showErrors: true }));
            toast.error("Please complete required fields");
            return;
        }

        this.setState(prevState => ({ ...prevState, showErrors: false }));
        await this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => {
        this.setState(prevState => ({ ...prevState, activeIndex }));
        const newSection = this.filteredAttributeRoutes[activeIndex as number];
        this.props.updateTabRoute(newSection);
    };
}

function mapStateToProps(state: AppState & TrainerAttributeDefinitionState & OrganisationState & AuthState) {
    const open = state.router.pathname.endsWith("/edit");

    return {
        model: trainerSelector(state) as TrainerCreateEditModel,
        open,
        basePath: pathWithoutLastPartSelector(state),
        trainersBasePath: basePathSelector(state),
        editPath: open ? editPathSelector(state) : null,
        query: routerQuerySelector(state) as TrainerRouterQuery,
        isFinanceAdministrator: currentUserIsInRoleSelector(TtcFinanceAdministratorRole)(state),
        trainerAttributeDefinitions: trainerAttributeDefinitionsSelector(state),
        organisationOptions: policeOrganisationOptionsSelector(state),
        isTrainerAnonymized: (trainerSelector(state) as TrainerDetailModel).isAnonymized
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (trainer: TrainerCreateEditModel, basePath: string, query: string) =>
            dispatch(saveTrainer(trainer, basePath + query)),
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
        dispatchTabChange: (basePath: string, section: AttributeRoute) => dispatch(push(basePath+"/edit"+ `?${QueryString.stringify({ section })}`))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): AllEditProps {
    const { model, open, basePath, trainersBasePath, editPath, trainerAttributeDefinitions, isFinanceAdministrator, organisationOptions,
        isTrainerAnonymized, query } = propsFromState;
    const { dispatchSave, dispatchClose, dispatchTabChange } = propsFromDispatch;
    const api = new TrainerApi();

    const sectionQuery = query?.section? `?${QueryString.stringify(query)}`:"";
    const editSegment = editPath || "/Details";
    const modalHeader = `Edit Trainer ${editSegment.slice(1).charAt(0).toUpperCase() + editSegment.slice(2)}`;
    return {
        model,
        open,
        editPath,
        trainersBasePath,
        query,
        trainerAttributeDefinitions,
        organisationOptions,
        isFinanceAdministrator,
        isTrainerAnonymized,
        modalHeader,
        save: (trainer: TrainerCreateEditModel) => dispatchSave(trainer, basePath, sectionQuery),
        close: () => dispatchClose(`${basePath}` + sectionQuery),
        findUser: debounce((email: string) => api.findByEmail(email), 300),
        updateTabRoute: (section: AttributeRoute) => dispatchTabChange(basePath, section)
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
