import * as React from "react";
import { Tab, Grid, Popup, Icon } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { CoreDetailProps } from "../Detail";
import { AddressDisplay } from "./AddressDisplay";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { TtcTrainerAdminRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { TrainerEmploymentType } from "../../model";
import { MultiSelectCheckbox } from "@common/components";
import { BusinessLineType } from "@common/redux-helpers";

export const DetailsTab: React.FC<CoreDetailProps> = ({ trainer }) => {
    const app = useSelector(appSelector);

    const getAddressLabel = () => (
        <Popup
            trigger={<span>{trainer?.address?.isApproximate && <Icon name="warning sign" />} Address</span>}
            disabled={!trainer?.address?.isApproximate}
            content="The Lat/Long position for this trainer is approximate"
        />
    );

    return (
        <Tab.Pane>
            <Grid>
                <Detail label="Title" value={trainer.title} labelWidth={5} valueWidth={11} />
                <Detail label="Forename" value={trainer.forename} labelWidth={5} valueWidth={11} />
                <Detail label="Surname" value={trainer.surname} labelWidth={5} valueWidth={11} />
                <Detail label="Employment Type" value={TrainerEmploymentType[trainer.employmentType]} labelWidth={5} valueWidth={11} />
                <Detail label={getAddressLabel()} value={AddressDisplay(trainer.address)} labelWidth={5} valueWidth={11} />
                <Detail label="Email" value={<div className="text-wrap">{trainer.email}</div>} labelWidth={5} valueWidth={11} />
                <Detail label="Mobile Number" value={trainer.mobileNumber} labelWidth={5} valueWidth={11} />
                <Detail label="Home Number" value={trainer.homeNumber} labelWidth={5} valueWidth={11} />
                {trainer.emergencyContact &&
                    <>
                        <Detail label="Emergency contact name" value={trainer.emergencyContact.name} labelWidth={5} valueWidth={11} />
                        <Detail label="Emergency contact number" value={trainer.emergencyContact.phoneNumber} labelWidth={5} valueWidth={11} />
                    </>}
                <Authorize authorize={TtcTrainerAdminRole}>
                    <Detail
                        label="Last Login"
                        value={trainer.lastLogin && trainer.lastLogin.format(DateFormat.ShortWithTime)}
                        labelWidth={5}
                        valueWidth={11}
                    />

                    <Detail
                        label="Last Continuum Attributes Sync Date"
                        value={trainer.lastContinuumAttributesSyncDateTime && trainer.lastContinuumAttributesSyncDateTime.format(DateFormat.ShortWithTime)}
                        labelWidth={5}
                        valueWidth={11}
                    />
                </Authorize>
                <>
                    <span>Line of Business</span>
                    <MultiSelectCheckbox
                        label="Police and Courts"
                        value={BusinessLineType.PoliceAndCourt}
                        selectedValues={trainer.businessLineTypes}
                        onValuesChanged={null}
                        isDisabled={true}
                    />
                    <MultiSelectCheckbox
                        label="Commercial"
                        value={BusinessLineType.Corporate}
                        selectedValues={trainer.businessLineTypes}
                        onValuesChanged={null}
                        isDisabled={true}
                    />
                    <MultiSelectCheckbox
                        label="Construction"
                        value={BusinessLineType.Construction}
                        selectedValues={trainer.businessLineTypes}
                        onValuesChanged={null}
                        isDisabled={true}
                    />
                </>
                {app !== Apps.Trainer && <Detail
                    label="Expiry Date"
                    value={trainer.expiryDate && trainer.expiryDate.format(DateFormat.Short)}
                    labelWidth={5}
                    valueWidth={11}
                />}

                {
                    app !== Apps.Trainer &&
                    !!trainer.deletionDate &&
                    <Detail
                        label="Deletion Date"
                        value={trainer.deletionDate && trainer.deletionDate.format(DateFormat.Short)}
                        labelWidth={5}
                        valueWidth={11}
                    />
                }
            </Grid>
        </Tab.Pane>
    );
};
