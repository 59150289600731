import * as React from "react";
import { Authorize } from "reauthorize";
import {  Grid, Divider } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { TtcTrainerAdminRole } from "@common/auth/model";
import { AttributesTable } from "../AttributesTable";
import { DorsAttributesTable } from "../DorsAttributesTable";
import { UpdateNdorsLicenceNumberModal } from "../UpdateNdorsLicenceNumberModal";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import { SynchronizeDorsModal } from "../SynchronizeDorsModal";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";

export const NDorsAndCourtAttributes = ({ trainer }: {trainer: TrainerDetailModel}) => {
    const dorsAttributes = trainer?.trainerAttributes?.filter((a) => a.isDors);
    const classroomAttributes = dorsAttributes?.filter((a) => !a.isDigital);
    const digitalAttributes = trainer?.trainerAttributes?.filter(
        (a) => a.isDigital && a.isDors
    );
    const ddrsAttributes = trainer?.trainerAttributes?.filter((a) => a.isDdrs);

    const adHocAttributes = trainer?.trainerAttributes?.filter(
        (a) => !a.isDors && !a.isDdrs && !a.isCompliance && !a.isCpc && !a.isWorkshop
    );

    return (<>
        <h3 className="row">DORS Licence</h3>
        <Detail
            label="NDORS Licence Number"
            value={trainer.ndorsLicenceNumber}
            labelWidth={5}
            valueWidth={11}
        />
        <Authorize authorize={TtcTrainerAdminRole}>
            <Grid.Row>
                <Grid.Column width={4}>
                    <SynchronizeDorsModal trainer={trainer} />
                </Grid.Column>
                <Grid.Column width={4}>
                    <UpdateNdorsLicenceNumberModal trainer={trainer} />
                </Grid.Column>
            </Grid.Row>
        </Authorize>
        <Divider />
        {classroomAttributes?.length > 0 &&
    <Grid.Row>
        <DorsAttributesTable
            title="DORS Classroom Attributes"
            trainerId={trainer.id}
            attributes={classroomAttributes}
            attributeCategory="DORS"
            digital={false}
        />
    </Grid.Row>
        }
        {digitalAttributes?.length > 0 &&
    <Grid.Row>
        <DorsAttributesTable
            title="DORS Digital Attributes"
            attributes={digitalAttributes}
            attributeCategory="Digital DORS"
            digital={true}
            trainerId={trainer.id}
        />
    </Grid.Row>
        }
        {ddrsAttributes?.length > 0 &&
    <Grid.Row>
        <AttributesTable
            attributeModalHeader="DDRS Attributes"
            title="DDRS Attributes"
            attributes={ddrsAttributes}
            attributeCategory="DDRS"
            digital={false}
            trainerId={trainer.id}
        />
    </Grid.Row>
        }
        <Divider />
        <h3 className="row">Ad-Hoc Attributes</h3>
        {adHocAttributes?.map((trainerAttribute, index) => (
            <Attribute
                trainerAttribute={trainerAttribute}
                showDivider={
                    trainer.trainerAttributes.length - 1 !== index
                }
                key={trainerAttribute.attributeDefinitionId}
            />
        ))}
    </>);};

const Attribute = (props: {
    trainerAttribute: TrainerAttribute;
    showDivider: boolean;
}) => (
    <>
        <Detail
            value={props.trainerAttribute.name}
            label={"Attribute Name"}
            labelWidth={5}
            valueWidth={11}
        />
        {props.trainerAttribute.expiryDate && (
            <Detail
                label="Expiry Date"
                value={props.trainerAttribute.expiryDate.format("DD/MM/YYYY")}
                labelWidth={5}
                valueWidth={11}
            />
        )}
        {props.showDivider && <Divider />}
    </>
);
