/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import "moment-duration-format";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import {
    Checkbox,
    CheckboxProps,
    Confirm,
    Divider,
    Form,
    Input as SemanticInput,
    InputOnChangeData,
    Label
} from "semantic-ui-react";
import { EditComponent, EditProps as SharedEditProps, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { BookingType,
    BookingTypeEnum, CorporateCertificateType, EventTypePart,
    GetEventClassroomRegistrationDuration, GetEventDigitalRegistrationDuration, GetEducationDuration, GetEventDuration,
    GetEventMaxAttendeesPerClassroomTrainer, GetEventMaxAttendeesPerDigitalTrainer,
    GetEventMaxClassroomAttendees, GetEventMaxDigitalAttendees, GetEventRegistrationEndTime,
    GetTheoryAndPracticalEventDurations, ModuleType, ModuleTypeEnum, ProductCategory, ProductCategoryEnum, WorkflowType,
    WorkflowTypeEnum, DurationTypeEnum, CertificateType, GetProductCategories, BusinessDriverProductCategories,
    GetEventClassroomBreakEvenPoints,
    GetEventDigitalBreakEvenPoints,
} from "@common/crud/eventType/model";
import { createValidator, validators } from "not-valid";
import { Address, DurationPicker, optionsFromObject } from "@common/crud/common";
import { Authorize } from "reauthorize";
import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { EventType, EventTypeState } from "../../eventType";
import { Trainer, TrainerState } from "../../trainer";
import { selectors as venueSelectors, Venue, VenueState } from "../../venue";
import { saveEventInstance } from "../actions";
import { basePathSelector, ddrsBasePathSelector, eventInstanceSelector,
    eventInstanceGroupByIdSelector } from "../selectors";
import {
    AppState,
    BookingAvailabilityType,
    BookingAvailabilityTypeEnum,
    EnquiryType,
    EventInstanceEditModel,
    Language
} from "../model";
import { CoreDetails } from "./details/CoreDetails";
import { EditModal } from "./EditModal";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DigitalCourseEdit } from "./DigitalCourseEdit";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { MultiDayEventTypePartEdit } from "./MultiDayEventTypePartEdit";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { mapEIGroupItemsToEventTypeParts, totalDurationEqualsSchemeTotal } from "../helpers";
import { CompanyTypeEnum, OrganisationState, SchemeDeliveryTypeEnum, VenueTypeEnum } from "@common/crud/organisation/model";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { constructionOrganisationOptionsSelector, corporateOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { eventTypeIsMultiDay, isBusinessLineTypeCorporateOrConstruction, isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate,
    isEventTypeWorkflowPoliceOrCourt, isVenueConstruction, isVenueCorporate, isVenuePoliceAndCourt, isWorkflowConstruction, isWorkflowCorporate,
    supportsOnRoadReport, workflowTypeSupportsCertificate } from "@common/global/CommonHelpers";
import { BankHoliday } from "@common/bankHolidays/model";
import { BankHolidaysApi } from "@common/bankHolidays/bankHolidaysApi";
import { CustomerStatusEnum } from "@common/crud/alaskaNudgeTask/model";
import { ClosedCorporateEdit } from "./ClosedCorporateEdit";
import { StartDateEdit } from "./StartDateEdit";
import { isBusinessDriverAdmin, isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { AppCommonState } from "@common/appCommonState";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { isVenueTypeCorrectForConstruction, isVenueTypeCorrectForCorporate,
    isVenueTypeCorrectForPoliceAndCourt, venueHasCorrectDeliveryType } from "@common/crud/venue/components/helpers";
import { EventInstanceApi } from "../eventInstanceApi";
import { rangeValidator } from "@common/validation";
import { Category } from "@common/appSettings/model";
import { EditCourseCategories } from "@common/components/EditCourseCategories";

interface OrganisationOption {
    text: string;
    value: string;
    key: string;
    open: boolean;
    customerStatus?: number;
};

export interface EditProps extends SharedEditProps<EventInstanceEditModel> {
    open: boolean;
    eventInstanceClosed: boolean;
    venues: Venue[];
    trainers: Trainer[];
    eventTypes: EventType[];
    group: EventInstanceGroupModel;
    isSaving: boolean;
    businessLineType: BusinessLineType;
    organisationOptions: OrganisationOption[];
    reducedProductCategories: { text: string; value: number }[];
    constructionCategories: Category[];
}

export interface DispatchProps extends SaveDispatchProps<EventInstanceEditModel> {
    close: () => void;
}

interface EditFormState extends FormState<EventInstanceEditModel> {
    endTimeDisplay: string;
    showDurationError: boolean;
}

export class EditForm extends EditComponent<EventInstanceEditModel, EditProps, EditFormState> {
    public bankHolidays: BankHoliday[];

    public async componentDidMount() {
        if (!this.bankHolidays) {
            this.bankHolidays = await new BankHolidaysApi().get();
        }
    }

    public componentDidUpdate(prevProps: EditProps, prevState: EditFormState) {
        if (this.state.values.openPlacesCount !== prevState.values.openPlacesCount) {
            this.validateOpenPlacesCount(this.state.values.openPlacesCount);
        }
    }

    public GetEventType = (currentEventTypeId?: string) =>  this.props.eventTypes.find(et => et.id === (currentEventTypeId || this.state.values.eventTypeId));

    public SingleModuleTypeTimingPicker = () => {
        const { values, showErrors } = this.state;
        return (
            <>
                <DurationPicker
                    label="Duration"
                    eventDuration={values.eventDuration}
                    showErrors={showErrors}
                    onChange={this.updateDuration}
                />
                <Input.Time
                    value={values.startTime ?
                        values.startTime.format(DateFormat.Time, { trim: false }) :
                        moment.duration().format(DateFormat.Time, { trim: false })}
                    label="Start Time"
                    required
                    showErrors={showErrors}
                    onChange={this.updateStartTime}
                />
                <Input.Time
                    value={values.registrationEndTime ?
                        values.registrationEndTime.format(DateFormat.Time, { trim: false }) :
                        moment.duration().format(DateFormat.Time, { trim: false })}
                    label="Registration End Time"
                    required
                    showErrors={showErrors}
                    onChange={this.updateRegistrationEndTime}
                    disabled
                />
                {this.hasEducationDuration(this.GetEventType())  &&
                     <DurationPicker
                         label="Education Duration"
                         eventDuration={values.educationDuration}
                         showErrors={showErrors}
                         onChange={this.updateEducationDuration}
                     />}
            </>);
    };

    public BothModuleTypeTimingPicker = () => {
        const { values, showErrors } = this.state;
        const showCombined = values.durationType === DurationTypeEnum.Combined;

        return (<>
            {showCombined ? (
                <>
                    <DurationPicker
                        label="Classroom Duration"
                        eventDuration={values.theoryDuration}
                        showErrors={showErrors}
                        onChange={this.updateClassroomDuration}
                    />
                    <Input.Time
                        value={values.theoryStartTime ?
                            values.startTime.format(DateFormat.Time, { trim: false }) :
                            moment.duration().format(DateFormat.Time, { trim: false })}
                        label="Classroom Start Time"
                        required
                        showErrors={showErrors}
                        onChange={this.updateClassroomStartTime}
                    />
                </>
            ) : (
                <>
                    <DurationPicker
                        label="Theory Duration"
                        eventDuration={values.theoryDuration}
                        showErrors={showErrors}
                        onChange={this.updateTheoryDuration}
                    />
                    <Input.Time
                        value={values.theoryStartTime ?
                            values.startTime.format(DateFormat.Time, { trim: false }) :
                            moment.duration().format(DateFormat.Time, { trim: false })}
                        label="Theory Start Time"
                        required
                        showErrors={showErrors}
                        onChange={this.updateTheoryStartTime}
                    />
                </>
            )}
            <Input.Time
                value={values.registrationEndTime ?
                    values.registrationEndTime.format(DateFormat.Time, { trim: false }) :
                    moment.duration().format(DateFormat.Time, { trim: false })}
                label="Registration End Time"
                required
                showErrors={showErrors}
                onChange={this.updateRegistrationEndTime}
                disabled
            />

            {!showCombined && (
                <>
                    <DurationPicker
                        label="Practical Duration"
                        eventDuration={values.practicalDuration}
                        showErrors={showErrors}
                        onChange={this.updatePracticalDuration}
                    />
                    <Input.Time
                        value={values.practicalStartTime ?
                            values.practicalStartTime.format(DateFormat.Time, { trim: false }) :
                            moment.duration().format(DateFormat.Time, { trim: false })}
                        label="Practical Start Time"
                        required
                        showErrors={showErrors}
                        onChange={this.updatePracticalStartTime}
                        validation={this.PracticalStartTimeValidator()}
                    />
                </>
            )}
            {this.hasEducationDuration(this.GetEventType())  &&
                     <DurationPicker
                         label="Education Duration"
                         eventDuration={values.educationDuration}
                         showErrors={showErrors}
                         onChange={this.updateEducationDuration}
                     />}</>);
    };

    public TimingDetailsEdit = () => this.state.values.moduleType === ModuleType[ModuleTypeEnum.Both]?
        this.BothModuleTypeTimingPicker()
        : this.SingleModuleTypeTimingPicker();

    public render() {
        const { values, showErrors } = this.state;
        const availableOnBookingJourney = values.availableInBookingJourney ? values.availableInBookingJourney : BookingAvailabilityTypeEnum.Both;
        const updateOpenPlacesAllowed = this.isPublished() && values.trainers && values.trainers.length > 0
        && !this.state.values.oneToOne && !this.state.values.reasonForHidingEvent;

        const eventType = this.GetEventType();

        const openPlacesLabel = values.workflowType === WorkflowTypeEnum.Dors
            ? "Open Places On DORS"
            : values.workflowType === WorkflowTypeEnum.DDRS
                ? "DDRS Open Place Count"
                : "CPC Open Place Count";
        const cancelProps = { content: "Cancel", className: "cancel-action" };

        return (
            <Form onSubmit={this.handleSubmit}>
                {this.isPublished() ? <CoreDetails onEditModal eventInstance={values} group={this.props.group} /> : this.fullDetails()}
                {(this.hasNoSeats() && !isWorkflowCorporate(values.workflowType) && !isWorkflowConstruction(values.workflowType)) &&
                <Input.DropdownNumber
                    value={values.language}
                    label="Delivery Language"
                    showErrors={showErrors}
                    required
                    validation={[validators.requiredNumber()]}
                    options={optionsFromObject(Language)}
                    onChange={this.onUpdate("language")}
                />}
                {!isWorkflowCorporate(values.workflowType) && !isWorkflowConstruction(values.workflowType) && (
                    <Input.DropdownNumber
                        label="Booking Journey Type"
                        showErrors={showErrors}
                        value={availableOnBookingJourney}
                        validation={[validators.requiredNumber()]}
                        disabled={this.state.values.oneToOne}
                        required
                        options={optionsFromObject(BookingAvailabilityType)}
                        onChange={this.onUpdate("availableInBookingJourney")}
                    />
                )}
                {this.state.values.doesNotUseTtcZoomAccounts !== null && (
                    <Input.Dropdown
                        value={this.state.values.doesNotUseTtcZoomAccounts?.toString() ?? "false"}
                        label="Organisation specific digital details required"
                        showErrors={this.state.showErrors}
                        dynamicOptions
                        options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                        onChange={this.updateDoesNotUseTtcZoomAccounts}
                        disabled
                        search
                    />
                )}
                {this.isPublished() && values.workflowType === WorkflowTypeEnum.CPC && values.flexibleCertificatesRequired && (
                    <Input.DropdownNumber
                        label="Certificate Type"
                        placeholder="Select Certificate Type"
                        showErrors={showErrors}
                        value={values.certificateType}
                        options={
                            Object.keys(CertificateType)
                                .filter(k => +k !== 0)
                                .map(
                                    k => ({ text: CertificateType[k], value: +k })
                                )}
                        onChange={this.updateCertificateType}
                        required
                    />
                )}
                {this.isPublished() && (isWorkflowCorporate(values.workflowType) || isWorkflowConstruction(values.workflowType))
                    && values.bookingType === BookingTypeEnum.Closed && (
                    <ClosedCorporateEdit
                        showErrors={showErrors}
                        values={values}
                        venueOptions={this.getVenueOptions()}
                        endTimeDisplay={this.state.endTimeDisplay}
                        selectedDayIsBankHoliday={this.bankHolidays?.some(h => h.date.isSame(values.startDate, "day"))}
                        updateVenue={this.updateVenue}
                        updateCertificateRequired={this.updateCertificateRequired}
                        updateOnRoadReportRequired={this.updateOnRoadReportRequired}
                        updateStartDate={this.updateStartDate}
                        eventInstanceClosed={this.props.eventInstanceClosed}
                        eventType={eventType}
                        bankHolidays={this.bankHolidays}
                        updateEventTypeParts={this.updateEventTypeParts}
                        updateStartDates={this.updateAllDDRSGroupStartDates}
                        updateSpecificStartDate={this.updateSpecificGroupStartDate}
                    >
                        {this.TimingDetailsEdit()}
                    </ClosedCorporateEdit>
                )}
                <DigitalCourseEdit
                    eventInstance={values}
                    updateProperty={this.onUpdate}
                    updateDigitalCourseWithoutRegistration={this.updateDigitalCourseWithoutRegistration}
                />
                <Divider hidden />
                {(updateOpenPlacesAllowed) &&
                    <Authorize authorize={PoliceAndDdrsAdminRole}>
                        <Form.Field
                            control={SemanticInput}
                            type="number"
                            value={values.openPlacesCount}
                            onChange={this.onOpenPlaceCountChange}
                            error={this.isOpenPlacesCountInvalid(values.openPlacesCount) && showErrors && {
                                content: `Value must be between ${this.minPlacesCount()} and ${this.configuredMaxSeatCount()}`,
                                pointing: "above"
                            }}
                            label={openPlacesLabel}
                            placeholder={openPlacesLabel}
                        />
                    </Authorize>}
                <Input.Textarea
                    value={values.adminNote}
                    label="Admin Note"
                    autoHeight={false}
                    validation={[validators.validLength({ max: 1000 })]}
                    showErrors={showErrors}
                    onChange={this.onUpdate("adminNote")}
                />

                {!isWorkflowCorporate(values.workflowType) && !isWorkflowConstruction(values.workflowType) && (
                    <>
                        <Input.Textarea
                            value={values.bookingNoteEn}
                            label="Booking Note (EN)"
                            autoHeight={false}
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={showErrors}
                            onChange={this.onUpdate("bookingNoteEn")}
                        />
                        <Input.Textarea
                            value={values.bookingNoteCy}
                            label="Booking Note (CY)"
                            autoHeight={false}
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={showErrors}
                            onChange={this.onUpdate("bookingNoteCy")}
                        />
                    </>
                )}
                <Confirm
                    open={this.state.showDurationError}
                    header={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType)
                        ? "Total duration differs from product"
                        : "Total duration differs from scheme"}
                    content={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType)
                        ? "The total duration of courses differs from the total duration defined on the product. Are you sure you wish to continue?"
                        : "The total duration of drink drive courses differs from the total duration defined on the scheme. Are you sure you wish to continue?"}
                    cancelButton={cancelProps}
                    onConfirm={this.submitIgnoringDurationWarning}
                    onCancel={this.closeDurationWarningModal}
                />
            </Form>
        );
    }

    public submit = async () => {
        const { values } = this.state;
        const eventType = this.GetEventType();

        if (eventType.moduleType === ModuleTypeEnum.Both) {
            if (!this.validateDuration(values.theoryDuration) )
            {
                toast.warning("Theory event duration has not been set");
            }
            if (!this.validateDuration(values.practicalDuration) )
            {
                toast.warning("Practical event duration has not been set");
            }
        }
        else if (!eventTypeIsMultiDay(eventType) && !this.validateDuration(values.eventDuration)) {
            toast.warning("Event duration has not been set");
        }

        if (!eventTypeIsMultiDay(eventType) && this.hasEducationDuration(eventType) &&
            !this.validateDuration(values.educationDuration)) {
            toast.warning("Education duration has not been set");
        }

        if (!values.maxNumberOfAttendees || values.maxNumberOfAttendees === null) {
            toast.error(isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType)
                ? "Product isn't configured correctly. Please set the maximum number of attendees for the course type on the product."
                : "Scheme isn't configured correctly. Please set the maximum number of attendees for the course type on the scheme.");

            return;
        }

        if (!this.valid()) {
            toast.warning("Course cannot be created as some mandatory fields have not been completed");
        }

        if (eventTypeIsMultiDay(eventType) &&
            !totalDurationEqualsSchemeTotal(values.eventInstanceDeliveryType, values.eventTypeId, values.eventTypeParts, this.props.eventTypes) ) {
            this.openDurationWarningModal();
            return;
        }

        const venue = this.props.venues.find(v => v.id === values.venueId);
        if (venue?.venueType === VenueTypeEnum.Enquiry && venue?.deliveryType === DeliveryTypeEnum.Onsite) {
            const api = new EventInstanceApi();
            if (await api.validateLocationDescription(values.locationDescription) === false) {
                toast.warning("Location description is not valid");
                this.setState(prevState => ({ ...prevState, showErrors: true }));
                return;
            }
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private isPublished = () => !!this.state.values.publishDate;
    private isAfterCourseClosure = () => !!this.state.values.certificatesProcessedDate;
    private hasNoSeats = () => this.state.values.seatCount === 0;

    private isCpcEventType(eventType: EventType): boolean {
        return eventType?.workflowType === WorkflowTypeEnum.CPC;
    }

    private hasEducationDuration(eventType: EventType): boolean {
        return this.isCpcEventType(eventType) || eventType?.productCategory === ProductCategoryEnum.OnRoadWithCpc;
    }

    private getVenueOptions() {
        const { venues } = this.props;
        const { values } = this.state;

        // When changing venues, can only change to ones with the same deliveryType as the current venue's deliveryType
        const eventType = this.GetEventType();
        let currentVenueDeliveryType = venues.find(v => v.id === values.venueId)?.deliveryType || eventType?.deliveryType;
        if (+currentVenueDeliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) {
            currentVenueDeliveryType = undefined;
        }

        const isPoliceAndCourt = isEventTypeWorkflowPoliceOrCourt(eventType);
        const isCorporate = isEventTypeWorkflowCorporate(eventType);
        const isConstruction = isEventTypeWorkflowConstruction(eventType);

        const filteredVenues = venues.filter(v => ((currentVenueDeliveryType === undefined
            || eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
            || (v?.deliveryType === currentVenueDeliveryType && venueHasCorrectDeliveryType(v, eventType)))
            && (!eventType?.workflowType
                || (v.workflowTypes.some(wft => wft === eventType.workflowType)
                    && (!isPoliceAndCourt || isVenueTypeCorrectForPoliceAndCourt(v))
                    && (!isCorporate || isVenueTypeCorrectForCorporate(v, values.corporateOrganisationId))
                    && (!isConstruction || isVenueTypeCorrectForConstruction(v, values.corporateOrganisationId))))));

        return filteredVenues.map(c => ({ key: `venue_option_${c.id}`, text: c.name, value: c.id }));
    }

    public PracticalStartTimeValidator = () => {
        return [createValidator<string>(
            v => {
                if (v !== undefined) {
                    const parsedTime: moment.Duration = moment.duration(v);
                    const minPossibleStartTime = this.state.values.theoryStartTime.clone().add(this.state.values.theoryDuration);
                    return parsedTime > minPossibleStartTime;
                }

                return true;
            },
            "Please enter time after the theory session")];
    };

    private fullDetails() {
        const { values, showErrors, endTimeDisplay } = this.state;

        const eventType = this.GetEventType();
        const businessLineType = this.props.businessLineType;
        const allowedSchemes = this.props.eventTypes.filter(e => !values.workflowType || values.workflowType === e.workflowType);
        const corporateOrganisation = this.props.organisationOptions.find(org => org.value === values.corporateOrganisationId);
        const mappedOrganisationOptions = this.props.organisationOptions
            .filter(o => o.customerStatus === CustomerStatusEnum.Customer)
            .map(o => ({ key: `organisation_option_${o.value}`, text: o.text, value: o.value }));
        const venue = this.props.venues.find(v => v.id === values.venueId);

        const venueOptions = this.getVenueOptions();

        return (
            <>
                {isBusinessLineTypeCorporateOrConstruction(businessLineType) && (
                    <>
                        <Input.Dropdown
                            value={values.corporateOrganisationId}
                            label="Organisation"
                            showErrors={showErrors}
                            required
                            options={mappedOrganisationOptions}
                            onChange={this.updateOrganisation}
                            disabled
                            dynamicOptions
                            search
                        />
                        <Input.DropdownNumber
                            label="Product Category"
                            placeholder="Select Product Category"
                            showErrors={showErrors}
                            value={values.productCategory}
                            dynamicOptions
                            options={this.props.reducedProductCategories}
                            onChange={this.updateProductCategory}
                            search
                            required
                        />
                    </>
                )}
                <Input.Dropdown
                    label={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType) ? "Product" : "Scheme"}
                    placeholder={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType) ? "Select Product" : "Select Scheme"}
                    showErrors={showErrors}
                    value={values.eventTypeId}
                    disabled={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType) && !corporateOrganisation}
                    options={allowedSchemes
                        .filter(
                            c => (!c.expiryDate || c.expiryDate > moment.utc()) &&
                                (!c.productCategory || c.productCategory === values.productCategory) &&
                                (!corporateOrganisation ||
                                    (corporateOrganisation.open && c.bookingType !== BookingTypeEnum.Closed) ||
                                    (!corporateOrganisation.open && c.bookingType !== BookingTypeEnum.Open)) &&
                                (!corporateOrganisation || !corporateOrganisation.open || corporateOrganisation.open)
                        )
                        .map(
                            c => ({ key: `event_type_option_${c.id}`, text: c.name, value: c.id })
                        )}
                    onChange={this.updateEventType}
                    dynamicOptions
                    search
                    required
                />
                {businessLineType === BusinessLineType.Construction && (
                    <>
                        {venue?.venueType === VenueTypeEnum.Enquiry && (
                            <Input.Checkbox
                                label="Reseller Course"
                                value={Boolean(this.state.values.resellerCourse)}
                                onChange={this.onUpdate("resellerCourse")}
                                toggle
                            />
                        )}
                        <EditCourseCategories
                            label="Course Categories"
                            categories={this.props.constructionCategories}
                            values={this.state.values.courseCategories}
                            onChange={this.updateCourseCategories} />
                    </>
                )}
                {(!!values.eventTypeId && (isWorkflowCorporate(values.workflowType) || isWorkflowConstruction(values.workflowType))) &&
                    <>
                        {(eventType?.deliveryType === SchemeDeliveryTypeEnum.Classroom
                            || (eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
                                && values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite)) &&
                            <div className="margin-bottom">
                                Internal Product ID (Classroom): {eventType?.internalId}
                            </div>
                        }
                        {(eventType?.deliveryType === SchemeDeliveryTypeEnum.Digital
                            || (eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
                                && values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital)) &&
                            <div className="margin-bottom">
                                Internal Product ID (Digital): {eventType?.internalIdDigital}
                            </div>
                        }
                    </>
                }
                {(!!values.eventTypeId && (values.workflowType === WorkflowTypeEnum.CPC || values.productCategory === ProductCategoryEnum.OnRoadWithCpc)) &&
                    <div className="margin-bottom">
                        Course Approval Number: {eventType?.courseApprovalNumber}
                    </div>
                }
                {workflowTypeSupportsCertificate(values.workflowType) &&
                    <Input.Checkbox
                        label="Certificate Required?"
                        value={(values.certificateRequired === undefined
                            || values.certificateRequired === null)
                            ? true
                            : values.certificateRequired}
                        onChange={this.onUpdate("certificateRequired")}
                        toggle
                    />
                }
                {supportsOnRoadReport(eventType?.workflowType, eventType?.productCategory) &&
                    <Input.Checkbox
                        label="On Road Report Required"
                        value={Boolean(this.state.values.onRoadReportRequired)}
                        onChange={this.onUpdate("onRoadReportRequired")}
                        toggle
                    />
                }
                {isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType) && (
                    <>
                        <Input.DropdownNumber
                            label="Booking Type"
                            placeholder="Select Booking Type"
                            showErrors={showErrors}
                            value={values.bookingType}
                            options={
                                Object.keys(BookingType).filter(k => (corporateOrganisation?.open ?
                                    +k === BookingTypeEnum.Open : +k === BookingTypeEnum.Closed))
                                    .map(
                                        k => ({ text: BookingType[k], value: +k })
                                    )}
                            disabled
                            required
                        />
                        <Input.Checkbox
                            label="Delegates Required?"
                            value={(values.delegatesRequired === undefined
                                    || values.delegatesRequired === null)
                                ? true
                                : values.delegatesRequired}
                            onChange={this.onUpdate("delegatesRequired")}
                            toggle
                            readOnly={values.bookingType === BookingTypeEnum.Open
                                || values.workflowType === WorkflowTypeEnum.CPC}
                            disabled={values.bookingType === BookingTypeEnum.Open
                                || values.workflowType === WorkflowTypeEnum.CPC}
                        />
                        {values.flexibleCertificatesRequired && (
                            <Input.DropdownNumber
                                label="Certificate Type"
                                placeholder="Select Certificate Type"
                                showErrors={showErrors}
                                value={values.certificateType}
                                options={
                                    Object.keys(CertificateType)
                                        .filter(k => +k !== 0)
                                        .map(
                                            k => ({ text: CertificateType[k], value: +k })
                                        )}
                                onChange={this.updateCertificateType}
                                required
                            />
                        )}
                    </>
                )}
                <Checkbox
                    label="Course is 1:1?"
                    onChange={this.onOneToOneChange}
                    checked={this.state.values.oneToOne}
                />
                <Input.Dropdown
                    label="Venue"
                    placeholder="Select Venue"
                    showErrors={showErrors}
                    value={values.venueId}
                    options={venueOptions}
                    onChange={this.updateVenue}
                    dynamicOptions
                    search
                    required
                />
                {venue?.venueType === VenueTypeEnum.Enquiry && venue?.deliveryType === DeliveryTypeEnum.Onsite && (
                    <Input.Text
                        label=" Location description"
                        value={values.locationDescription}
                        onChange={this.updateLocationDescription}
                        required
                        showErrors={showErrors}
                    />
                )}
                {venue?.venueType === VenueTypeEnum.DelegateHome && (
                    <AddressLookup
                        address={values.delegateHomeAddress || {} as Address}
                        showErrors={showErrors}
                        onChange={this.updateDelegateHomeAddress}
                        required
                        autofill
                    />
                )}
                {businessLineType === BusinessLineType.Construction
                    && values.bookingType === BookingTypeEnum.Open && venue?.venueType === VenueTypeEnum.Enquiry && (
                    <>
                        {values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite && (
                            <Input.Number
                                value={this.state.values.classroomBreakEvenPoint}
                                label="Classroom Break Even Point"
                                showErrors={this.state.showErrors}
                                required
                                validation={this.state.values.maxNumberOfAttendees > 0 ? rangeValidator(0, this.state.values.maxNumberOfAttendees) : undefined}
                                onChange={this.onUpdate("classroomBreakEvenPoint")}
                            />
                        )}
                        {values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital  && (
                            <Input.Number
                                value={this.state.values.digitalBreakEvenPoint}
                                label="Digital Break Even Point"
                                showErrors={this.state.showErrors}
                                required
                                validation={this.state.values.maxNumberOfAttendees > 0 ? rangeValidator(0, this.state.values.maxNumberOfAttendees) : undefined}
                                onChange={this.onUpdate("digitalBreakEvenPoint")}
                            />
                        )}
                    </>
                )}
                <Divider hidden />
                {!eventTypeIsMultiDay(eventType) &&
                    <StartDateEdit
                        startDate={values.startDate}
                        showErrors={showErrors}
                        updateStartDate={this.updateStartDate}
                        selectedDayIsBankHoliday={this.bankHolidays?.some(h => h.date.isSame(values.startDate, "day"))}
                    />}
                <Input.Number
                    value={values.maxNumberOfAttendees}
                    label="Max Attendees Permitted"
                    disabled
                />
                {this.isOnSiteDeliveryType() && !!values.maxNumberOfAttendeesPerTrainer && <Input.Number
                    value={values.maxNumberOfAttendeesPerTrainer}
                    label="Max Attendees Permitted Per Theory Trainer"
                    disabled
                />}
                {this.isOnSiteDeliveryType() && !!values.maxNumberOfAttendeesPerPracticalTrainer && <Input.Number
                    value={values.maxNumberOfAttendeesPerPracticalTrainer}
                    label="Max Attendees Permitted Per Practical Trainer"
                    disabled
                />}
                <Input.Text
                    value={values.moduleType}
                    label="Course Type"
                    disabled
                />
                <Input.Text
                    value={WorkflowType[values.workflowType]}
                    label="Workflow Type"
                    disabled
                />
                <Input.Text
                    value={values.trainerAttributes ? values.trainerAttributes.join(", ") : ""}
                    label="Trainer Attributes"
                    disabled
                />
                {!values.flexibleCertificatesRequired && (
                    <>
                        {!!values.mandatoryCorpCertificate &&
                            <Input.Text value={CorporateCertificateType[values.mandatoryCorpCertificate]} label="Mandatory Certificate" disabled />
                        }
                        {!!values.optionalCorpCertificate &&
                            <Input.Text value={CorporateCertificateType[values.optionalCorpCertificate]} label="Optional Certificate" disabled />
                        }
                    </>
                )}

                {eventTypeIsMultiDay(eventType) &&
                <div className="day-part-edit">
                    <MultiDayEventTypePartEdit
                        groupItemStartDates={values.groupItemStartDates}
                        bankHolidays={this.bankHolidays}
                        firstStartDate={this.state.values.startDate}
                        deliveryType={values.eventInstanceDeliveryType}
                        updateEventTypeParts={this.updateEventTypeParts}
                        eventTypeParts={values.eventTypeParts}
                        updateStartDates={this.updateAllDDRSGroupStartDates}
                        updateSpecificStartDate={this.updateSpecificGroupStartDate}
                        eventType={eventType}
                    />
                </div>
                }
                {!eventTypeIsMultiDay(eventType) &&
                    this.TimingDetailsEdit()
                }
                {<Label id="endtime">
                    Event Ends at: {endTimeDisplay}
                </Label>}
            </>
        );
    }

    private updateDigitalCourseWithoutRegistration = (value: string) => {
        this.updateProperty("digitalCourseWithoutRegistration", value === "No", true);
    };

    private updateEducationDuration = (value: moment.Duration) => {
        this.updateProperty("educationDuration", value, this.validateDuration(value));
    };

    private onUpdate = (prop: keyof EventInstanceEditModel) => (value: any, valid: boolean) => {
        this.updateProperty(prop, value, valid);
    };

    private updateVenue = (value: string, valid: boolean) => {
        const { venues } = this.props;
        const venue = venues.find(v => v.id === value);
        const eventType = this.GetEventType();

        if (venue) {
            this.updateProperty("venueId", value, valid);
            this.updateProperty("venueName", venue.name, valid);
            this.updateProperty("forceId", venue.forceId, valid);
            this.updateProperty("venueDorsId", venue.dorsId, valid);
            this.updateProperty("eventInstanceDeliveryType", venue.deliveryType, valid);
            this.updateProperty("enquiryType", venue.venueType === VenueTypeEnum.Enquiry ? EnquiryType.Enquiry : EnquiryType.NotEnquiry, valid);

            if (eventType) {
                if (venue.venueType === VenueTypeEnum.Enquiry) {
                    this.updateProperty("resellerCourse", eventType.resellerCourse, true);
                } else {
                    this.updateProperty("resellerCourse", false, true);
                }
            } else {
                this.updateProperty("resellerCourse", false, true);
            }

            this.deliveryTypeChanged(venue.deliveryType);
        } else {
            this.updateProperty("venueId", "", valid);
            this.updateProperty("venueName", "", valid);
            this.updateProperty("forceId", "", valid);
            this.updateProperty("venueDorsId", "", valid);
            this.updateProperty("enquiryType", EnquiryType.NotEnquiry, valid);
            this.updateProperty("eventInstanceDeliveryType", undefined, valid);
            this.updateProperty("resellerCourse", false, true);
            this.deliveryTypeChanged(undefined);
        }

        if (venue?.venueType !== VenueTypeEnum.Enquiry || venue?.deliveryType !== DeliveryTypeEnum.Onsite) {
            this.updateProperty("locationDescription", undefined, true);
        }

        if (venue?.venueType && venue.venueType !== VenueTypeEnum.DelegateHome) {
            this.updateProperty("delegateHomeAddress", {}, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine1", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine2", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine3", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.city", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.postalCode", undefined, true);
        }
    };

    private updateDelegateHomeAddress = (address: Address, valid: boolean) => {
        this.updateProperty("delegateHomeAddress", address, valid);
    };

    private updateLocationDescription = (value: string, valid: boolean) => {
        this.updateProperty("locationDescription", value, valid);
    };

    private updateCertificateRequired = (value: boolean, valid: boolean) => {
        if (this.isAfterCourseClosure() && this.props.model.certificateRequired && !value) {
            toast.warning("Certificate required cannot be toggled off on a published course");
            return;
        }
        this.updateProperty("certificateRequired", value, valid);
    };

    private updateOnRoadReportRequired = (value: boolean, valid: boolean) => {
        this.updateProperty("onRoadReportRequired", value, valid);
    };

    private updateDoesNotUseTtcZoomAccounts = (value: string) => {
        this.updateProperty("doesNotUseTtcZoomAccounts", value, true);
    };

    private updateOrganisation = (value: string, valid: boolean) => {
        this.updateProperty("corporateOrganisationId", value, valid);
        if (value) {
            const corporateOrganisation = this.props.organisationOptions.find(org => org.value === value);
            const eventType = this.GetEventType();
            if (corporateOrganisation?.open && this.state.values.bookingType === BookingTypeEnum.Closed) {
                this.updateProperty("bookingType", null, true);
            }
            if (corporateOrganisation?.open && eventType?.bookingType === BookingTypeEnum.Closed) {
                this.updateEventType(null, true);
            }
        }
    };

    private updateEventType = (value: string, valid: boolean) => {
        const eventType = this.props.eventTypes.find(et => et.id === value);
        const start = this.state.values.startDate;
        const venueId = this.state.values.venueId;
        const venue = this.props.venues.find(v => v.id === venueId);
        const startTime = this.state.values.startTime;

        if (value !== "" && value !== this.state.values.eventTypeId) {
            const moduleType = ModuleType[eventType.moduleType];

            if (eventType) {
                const isCorporate = isEventTypeWorkflowCorporate(eventType);
                const isConstruction = isEventTypeWorkflowConstruction(eventType);
                const isCpc = this.isCpcEventType(eventType);
                const hasEducationDuration = this.hasEducationDuration(eventType);
                this.updateProperty("eventTypeId", value, valid);
                this.updateProperty("eventTypeName", eventType.name, valid);
                this.updateProperty("maxNumberOfAttendeesPerPracticalTrainer", eventType.maxNumberOfAttendeesPerPracticalTrainer, valid);
                this.updateProperty("moduleType", moduleType, valid);
                this.updateProperty("durationType", eventType.durationType, valid);
                this.updateProperty("workflowType", eventType.workflowType, valid);
                this.updateProperty("schemeId", eventType.dorsId, valid);
                this.updateProperty("eventTypeAbbreviation", eventType.abbreviation, valid);
                this.updateProperty("delegatesRequired", eventType.delegatesRequired, valid);
                this.updateProperty("certificateRequired", eventType.certificateRequired, valid);
                this.updateProperty("courseCategories", eventType.courseCategories, valid);

                if (venue?.venueType === VenueTypeEnum.Enquiry) {
                    this.updateProperty("resellerCourse", eventType.resellerCourse, valid);
                }

                if (isCorporate || isConstruction) {
                    this.updateProperty("mandatoryCorpCertificate", eventType.mandatoryCorpCertificate, valid);

                    if (isCpc) {
                        this.updateProperty("digitalCourseWithoutRegistration", false, true);
                        this.updateProperty("optionalCorpCertificate", eventType.optionalCorpCertificate, valid);
                    }

                    if (eventType.workflowType === WorkflowTypeEnum.Workshop || eventType.workflowType === WorkflowTypeEnum.OnRoad) {
                        this.updateProperty("digitalCourseWithoutRegistration", true, true);
                    }

                    if (supportsOnRoadReport(eventType.workflowType, eventType.productCategory)) {
                        this.updateProperty("onRoadReportRequired", eventType.onRoadReportRequired, true);
                    }
                    else {
                        this.updateProperty("onRoadReportRequired", null, true);
                    }
                }

                if (isConstruction && (eventType.bookingType === BookingTypeEnum.Open || eventType.bookingType === BookingTypeEnum.Both)) {
                    if (eventType.classroomEventTypeDetails.classroomBreakEvenPoints) {
                        this.updateProperty("classroomBreakEvenPoint", GetEventClassroomBreakEvenPoints(this.state.values.startDate, eventType), valid);
                    }

                    if (eventType.digitalEventTypeDetails.digitalBreakEvenPoints) {
                        this.updateProperty("digitalBreakEvenPoint", GetEventDigitalBreakEvenPoints(this.state.values.startDate, eventType), valid);
                    }
                }

                let maxNumberOfAttendees;
                let registrationEnd;

                if (this.isOnSiteDeliveryType()) {
                    const duration = GetEventDuration(start, DeliveryTypeEnum.Onsite, eventType);
                    const educationDuration = hasEducationDuration ? GetEducationDuration(start, DeliveryTypeEnum.Onsite, eventType) : null;
                    this.updateDurations(duration, educationDuration, start, eventType);
                    this.updateProperty("educationDuration", educationDuration, hasEducationDuration ? this.validateDuration(educationDuration) : true);
                    registrationEnd = GetEventRegistrationEndTime(startTime, GetEventClassroomRegistrationDuration(start, eventType));
                    this.updateProperty("maxNumberOfAttendeesPerTrainer", GetEventMaxAttendeesPerClassroomTrainer(start, eventType), valid);
                    maxNumberOfAttendees = GetEventMaxClassroomAttendees(start, eventType);
                } else {
                    const duration = GetEventDuration(start, DeliveryTypeEnum.Digital, eventType);
                    const educationDuration = hasEducationDuration ? GetEducationDuration(start, DeliveryTypeEnum.Digital, eventType) : null;
                    this.updateDurations(duration, educationDuration, start, eventType);
                    this.updateProperty("educationDuration", educationDuration, hasEducationDuration ? this.validateDuration(educationDuration) : true);
                    this.updateProperty("maxNumberOfAttendeesPerTrainer", GetEventMaxAttendeesPerDigitalTrainer(start, eventType), valid);
                    maxNumberOfAttendees = GetEventMaxDigitalAttendees(start, eventType);
                    registrationEnd = GetEventRegistrationEndTime(startTime, GetEventDigitalRegistrationDuration(start, eventType));
                }

                if (venue && venueHasCorrectDeliveryType(venue, eventType) === false) {
                    this.updateVenue(null, false);
                }

                this.updateProperty("maxNumberOfAttendees", maxNumberOfAttendees, valid);
                this.updateProperty("openPlacesCount", maxNumberOfAttendees, valid);
                this.updateProperty("registrationEndTime", registrationEnd, true);

                if (eventType.bookingType !== BookingTypeEnum.Both && eventType.bookingType !== this.state.values.bookingType) {
                    this.updateProperty("bookingType", null, true);
                }
            }
        }
    };

    private updateCertificateType = (value: number, valid: boolean) => {
        this.updateProperty("certificateType", value, valid);
    };

    private updateProductCategory = (value: number, valid: boolean) => {
        this.updateProperty("productCategory", value, valid);
        const eventType = this.GetEventType();

        if (eventType && eventType.productCategory !== value) {
            this.updateProperty("eventTypeId", null, false);
            this.updateProperty("workflowType", null, false);
            this.updateProperty("onRoadReportRequired", null, true);
        }

    };

    private updateDurations(duration: moment.Duration, educationDuration: moment.Duration | null,  start: moment.Moment, eventType: EventType) {
        if (eventType.moduleType === ModuleTypeEnum.Both) {
            const { theoryDurations, practicalDurations } = GetTheoryAndPracticalEventDurations(start, eventType);
            this.updateTheoryDuration(theoryDurations);
            this.updatePracticalDuration(practicalDurations);
            this.updateProperty("eventDuration", null, true);
        }
        else {
            this.updateProperty("eventDuration", duration, this.validateDuration(duration));
            this.updateProperty("theoryDuration", null, true);
            this.updateProperty("practicalDuration", null, true);
        }

        if (educationDuration) {
            this.updateProperty("educationDuration", educationDuration, this.validateDuration(educationDuration));
        }
    }

    private onOneToOneChange = (_: any, { checked }: CheckboxProps) => {

        const eventType = this.GetEventType();

        const start = this.state.values.startDate;

        const maxNumberOfAttendees=checked?
            1:this.isOnSiteDeliveryType()?
                GetEventMaxClassroomAttendees(start, eventType):GetEventMaxDigitalAttendees(start, eventType);

        const maxNumberOfAttendeesPerTrainer=checked?
            1:  this.isOnSiteDeliveryType()?
                GetEventMaxAttendeesPerClassroomTrainer(start, eventType):GetEventMaxAttendeesPerDigitalTrainer(start, eventType);

        const maxNumberOfAttendeesPerPracticalTrainer=checked?
            1 : eventType.maxNumberOfAttendeesPerPracticalTrainer;

        return this.setState(prevState => {
            return ({
                ...prevState,

                values: {
                    ...prevState.values,
                    maxNumberOfAttendees,
                    maxNumberOfAttendeesPerTrainer,
                    maxNumberOfAttendeesPerPracticalTrainer,
                    oneToOne: checked,
                    availableInBookingJourney: checked? BookingAvailabilityTypeEnum.Office : prevState.values.availableInBookingJourney
                }
            });
        });
    };

    private updateDuration = (value: moment.Duration) => {
        this.updateProperty("eventDuration", value, this.validateDuration(value));

        let endTime = this.state.values.startTime.clone().add(value);
        if (endTime?.asHours() >= 24) {
            endTime = endTime.subtract(1, "day");
        }

        this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
    };

    private updateClassroomDuration = (value: moment.Duration) => {
        this.updateProperty("theoryDuration", value, this.validateDuration(value));
        this.updateProperty("practicalDuration", value, this.validateDuration(value));

        let endTime = this.state.values.theoryStartTime?.clone().add(value);
        if (endTime?.asHours() >= 24) {
            endTime = endTime.subtract(1, "day");
        }

        this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
    };

    private updateTheoryDuration = (value: moment.Duration) => {
        this.updateProperty("theoryDuration", value, this.validateDuration(value));
    };

    private updatePracticalDuration = (value: moment.Duration) => {
        this.updateProperty("practicalDuration", value, this.validateDuration(value));

        let endTime = this.state.values.practicalStartTime?.clone().add(value);
        if (endTime?.asHours() >= 24) {
            endTime = endTime.subtract(1, "day");
        }

        this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
    };

    private updateAllDDRSGroupStartDates = ( value: Dictionary<moment.Moment>, valid: boolean) =>  {
        const datesArray = Object.values(value);

        const correctOrder = datesArray.every((_e,index) => index + 1 < datesArray.length? value[index + 1].isBefore(value[index + 2]) : true);

        if (!correctOrder) {
            valid = false;
            toast.warning("Day parts must be in chronological order");
        }

        return this.updateProperty("groupItemStartDates", value, valid);};

    private updateSpecificGroupStartDate = (day: string, value: moment.Moment, valid: boolean) =>
        this.updateAllDDRSGroupStartDates({ ...this.state.values.groupItemStartDates, [day]: value }, valid );

    private isOpenPlacesCountInvalid = (value: number) =>
        value < this.minPlacesCount() || (value > this.configuredMaxSeatCount() && this.eventHasTrainers());

    private onOpenPlaceCountChange = (_: any, props: InputOnChangeData) => this.validateOpenPlacesCount(props.value);

    private validateOpenPlacesCount = (value: string | number) => {
        let newValue: number;

        if (value === typeof (String)) {
            newValue = Number.parseInt(value, 10);
        } else {
            newValue = value as number;
        }

        if (this.isOpenPlacesCountInvalid(newValue)) {
            this.updateProperty("openPlacesCount", newValue, false);
        } else {
            this.updateProperty("openPlacesCount", newValue, true);
        }
    };

    private submitIgnoringWarning = (errorField: keyof EditFormState) => {
        this.setState({ ...this.state,
            [errorField]: false
        });

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private submitIgnoringDurationWarning=() =>
        this.submitIgnoringWarning("showDurationError");

    private closeDurationWarningModal = () => this.setState({ showDurationError: false });

    private openDurationWarningModal = () => this.setState({ showDurationError: true });

    private minPlacesCount = () => this.state.values.seatCount === 0 ? 1 : this.state.values.seatCount;

    private configuredMaxSeatCount = () => this.state.values.maxNumberOfTheorySeatsWithExtra;

    private eventHasTrainers = () => this.state.values.trainers?.length > 0;

    private updateStartDate = (value: moment.Moment, valid: boolean) => {
        const eventType = this.GetEventType();
        const startTime = this.state.values.startTime;
        this.updateProperty("startDate", value, valid);
        this.updateProperty("classroomBreakEvenPoint", GetEventClassroomBreakEvenPoints(value, eventType), valid);
        this.updateProperty("digitalBreakEvenPoint", GetEventDigitalBreakEvenPoints(value, eventType), valid);
        const duration = GetEventDuration(value, this.state.values.eventInstanceDeliveryType, eventType);
        const educationDuration = this.hasEducationDuration(eventType)
            ? GetEducationDuration(value, this.state.values.eventInstanceDeliveryType, eventType) : null;
        this.updateDurations(duration, educationDuration, value, eventType);

        let registrationEnd;
        if (this.state.values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital) {
            this.updateProperty("maxNumberOfAttendees", GetEventMaxDigitalAttendees(value, eventType), true);
            this.updateProperty("maxNumberOfAttendeesPerTrainer", GetEventMaxAttendeesPerDigitalTrainer(value, eventType), true);
            registrationEnd = GetEventRegistrationEndTime(startTime, GetEventDigitalRegistrationDuration(value, eventType));
        } else {
            this.updateProperty("maxNumberOfAttendees", GetEventMaxClassroomAttendees(value, eventType), true);
            this.updateProperty("maxNumberOfAttendeesPerTrainer", GetEventMaxAttendeesPerClassroomTrainer(value, eventType), true);
            registrationEnd = GetEventRegistrationEndTime(startTime, GetEventClassroomRegistrationDuration(value, eventType));
        }
        this.updateProperty("registrationEndTime", registrationEnd, true);
    };

    private validateDuration = (value: moment.Duration) => {
        return (value?.minutes() > 0 || value?.hours() > 0);
    };

    private updateStartTime = (time: string) => {
        if (time !== undefined) {
            const eventType = this.GetEventType();
            const parsedTime: moment.Duration = moment.duration(time);
            const start = this.state.values.startDate;
            this.updateProperty("startTime", parsedTime, true);

            let endTime = parsedTime.clone().add(this.state.values.eventDuration);
            if (endTime?.asHours() >= 24) {
                endTime = endTime.subtract(1, "day");
            }

            this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
            const registrationDuration = this.isOnSiteDeliveryType() ? GetEventClassroomRegistrationDuration(start, eventType)
                : GetEventDigitalRegistrationDuration(start, eventType);
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(parsedTime, registrationDuration), true);
        }
    };

    private updateClassroomStartTime = (time: string) => {
        if (time !== undefined) {
            const eventType = this.GetEventType();
            const parsedTime: moment.Duration = moment.duration(time);
            const start = this.state.values.startDate;
            this.updateProperty("theoryStartTime", parsedTime, true);
            this.updateProperty("practicalStartTime", parsedTime, true);
            const registrationDuration = this.isOnSiteDeliveryType() ? GetEventClassroomRegistrationDuration(start, eventType)
                : GetEventDigitalRegistrationDuration(start, eventType);
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(parsedTime, registrationDuration), true);

            let endTime = parsedTime.clone().add(this.state.values.theoryDuration);
            if (endTime?.asHours() >= 24) {
                endTime = endTime.subtract(1, "day");
            }

            this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
        }
    };

    private updateTheoryStartTime = (time: string) => {
        if (time !== undefined) {
            const eventType = this.GetEventType();
            const parsedTime: moment.Duration = moment.duration(time);
            const start = this.state.values.startDate;
            this.updateProperty("theoryStartTime", parsedTime, true);
            const registrationDuration = this.isOnSiteDeliveryType() ? GetEventClassroomRegistrationDuration(start, eventType)
                : GetEventDigitalRegistrationDuration(start, eventType);
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(parsedTime, registrationDuration), true);
        }
    };

    private updatePracticalStartTime = (time: string) => {
        if (time !== undefined) {
            const parsedTime: moment.Duration = moment.duration(time);
            this.updateProperty("practicalStartTime", parsedTime, true);

            let endTime = parsedTime.clone().add(this.state.values.practicalDuration);
            if (endTime?.asHours() >= 24) {
                endTime = endTime.subtract(1, "day");
            }

            this.setState({ endTimeDisplay: endTime?.format(DateFormat.Time, { trim: false }) });
        }
    };

    private updateRegistrationEndTime = (time: string) => {
        if (time !== undefined) {
            const parsedTime: moment.Duration = moment.duration(time);
            this.updateProperty("registrationEndTime", parsedTime, true);
        }
    };

    public updateEventTypeParts = (eventTypeParts: Dictionary<EventTypePart>): any => {
        this.updateProperty("eventTypeParts", eventTypeParts, true);
    };

    private deliveryTypeChanged = (eventInstanceType: number) => {
        if (eventInstanceType !== DeliveryTypeEnum.Digital) {
            this.updateProperty("digitalCourseLink", null, true);
            this.updateProperty("digitalCourseAccount", null, true);
            this.updateProperty("digitalCoursePasswordTrainer", null, true);
            this.updateProperty("digitalCourseLinkTrainer", null, true);
            this.updateProperty("digitalCoursePasswordAttendee", null, true);
            this.updateProperty("digitalCourseMeetingId", null, true);
        }
    };

    private updateCourseCategories = (value: number[], valid: boolean) => {
        this.updateProperty("courseCategories", value, valid);
    };

    private isOnSiteDeliveryType(): boolean {
        return this.state.values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite;
    }
}

function mapStateToProps(state: AppState & VenueState & TrainerState & EventTypeState & LoadingState & OrganisationState & AppCommonState) {

    const pathname = state.router.pathname;
    const isDdrsEventInstance = new RegExp("/drink-drive/.*/edit").test(pathname);
    const detailsModel = eventInstanceSelector(state);
    const model = detailsModel as EventInstanceEditModel;
    const eventInstanceClosed = !!detailsModel?.certificatesProcessedDate;
    const group = eventInstanceGroupByIdSelector(model?.groupId)(state) as EventInstanceGroupModel;

    if (group && !isEmpty(group) ) {
        model.eventTypeParts= mapEIGroupItemsToEventTypeParts(group);
        const groupItemStartDates = Object.fromEntries(group.eventInstanceGroupItems.map(gi => [gi.dayNumber,gi.startDate]));
        model.groupItemStartDates = groupItemStartDates;
    }

    const businessLineType = businessLineTypeSelector(state);
    let organisationOptions = businessLineType === BusinessLineType.Corporate
        ? corporateOrganisationOptionsSelector(state)
        : constructionOrganisationOptionsSelector(state);
    const isBusinessDriverAdminRole = isBusinessDriverAdmin(state);
    const isTtcCorporateAdminRole = isTtcCorporateAdmin(state);

    if (businessLineType === BusinessLineType.Corporate) {
        if (isBusinessDriverAdminRole === true && isTtcCorporateAdminRole === true) {
            organisationOptions = organisationOptions.filter(org => org.companyType.includes(CompanyTypeEnum.BusinessDriver) ||
                org.companyType.includes(CompanyTypeEnum.TTC) || org.companyType.includes(CompanyTypeEnum.LicenceBureau));
        }
        else if (isTtcCorporateAdminRole === true && isBusinessDriverAdminRole === false) {
            organisationOptions = organisationOptions
                .filter(org => org.companyType.includes(CompanyTypeEnum.TTC) || org.companyType.includes(CompanyTypeEnum.LicenceBureau));
        } else if (isBusinessDriverAdminRole === true && isTtcCorporateAdminRole === false) {
            organisationOptions = organisationOptions.filter(org => org.companyType.includes(CompanyTypeEnum.BusinessDriver));
        } else {
            organisationOptions = [];
        }
    }

    const orgCompanyTypes = organisationOptions.find(org => org.value === model.corporateOrganisationId)?.companyType;
    const isBusinessDriverOnly = orgCompanyTypes && orgCompanyTypes.length === 1 && orgCompanyTypes.includes(CompanyTypeEnum.BusinessDriver);
    const hasBusinessDriver = orgCompanyTypes && orgCompanyTypes.includes(CompanyTypeEnum.BusinessDriver);
    const reducedProductCategories = GetProductCategories(businessLineType, isBusinessDriverAdminRole, isTtcCorporateAdminRole)
        .filter(k => hasBusinessDriver || !BusinessDriverProductCategories.includes(k))
        .filter(k => !isBusinessDriverOnly || BusinessDriverProductCategories.includes(k))
        .filter(k => +k !== 0)
        .map(k => ({ text: ProductCategory[k], value: +k }));

    let venues = venueSelectors.unexpiredVenuesSelector(state) || [];

    if (businessLineType === BusinessLineType.Corporate) {
        venues = venues.filter(v => isVenueCorporate(v));
    } else if (businessLineType === BusinessLineType.Construction) {
        venues = venues.filter(v => isVenueConstruction(v));
    } else if (businessLineType === BusinessLineType.PoliceAndCourt) {
        venues = venues.filter(v => isVenuePoliceAndCourt(v));
    }

    return {
        venues,
        trainers: state.trainers || [],
        eventTypes: isWorkflowCorporate(model?.workflowType)
            ? (state.eventTypes || []).filter(et => isEventTypeWorkflowCorporate(et))
            : isWorkflowConstruction(model?.workflowType)
                ? (state.eventTypes || []).filter(et => isEventTypeWorkflowConstruction(et))
                : (state.eventTypes || []).filter(et => isEventTypeWorkflowPoliceOrCourt(et)),
        model,
        eventInstanceClosed,
        open: pathname.endsWith("/edit"),
        basePath: isDdrsEventInstance ? ddrsBasePathSelector(state) : basePathSelector(state),
        group,
        isSaving: isRequestActive(state, "saveEventInstance"),
        businessLineType,
        organisationOptions,
        reducedProductCategories,
        constructionCategories: state.appSettings.constructionCategories
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (eventInstance: EventInstanceEditModel, basePath: string) => dispatch(saveEventInstance(eventInstance, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {

    const { venues, trainers,eventTypes, model, eventInstanceClosed, open, basePath, group, businessLineType, organisationOptions,
        reducedProductCategories, constructionCategories } = propsFromState;
    const { dispatchSave, dispatchClose } = propsFromDispatch;

    return {
        group,
        venues,
        trainers,
        eventTypes,
        model,
        eventInstanceClosed,
        open,
        save: (eventInstance: EventInstanceEditModel) => dispatchSave(eventInstance, basePath),
        close: () => dispatchClose(`${basePath}/${model.id}`),
        isSaving: propsFromState.isSaving,
        businessLineType,
        organisationOptions,
        reducedProductCategories,
        constructionCategories
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
