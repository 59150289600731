/* eslint-disable react/jsx-no-bind */
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import "../UpdateDelegatesTable.scss";
import { EventInstanceAttendeeField } from "@common/crud/eventInstance/model";
import { AttendeeFieldValue } from "@common/crud/attendee/model";
import { AttendeeFieldTypeEnum } from "@common/crud/organisation/model";

interface AttendeeFieldProps {
    fieldConfiguration: EventInstanceAttendeeField;
    fieldValues: AttendeeFieldValue[];
    isEditable: boolean;
    fieldsForMarkup: string[];
    onAttendeeFieldValueChange: (value: any, fieldConfiguration: EventInstanceAttendeeField) => void;
}

const FieldComponent = (fieldConfiguration: EventInstanceAttendeeField, value: any,
    onAttendeeFieldValueChange: (value: any, fieldConfiguration: EventInstanceAttendeeField) => void) => {
    switch (fieldConfiguration.type) {
        case AttendeeFieldTypeEnum.Text:
            const onStringValueChange = (stringValue: string) => onAttendeeFieldValueChange(stringValue, fieldConfiguration);
            return (<Input.Text
                value={value}
                onChange={onStringValueChange}
                placeholder={fieldConfiguration.displayName}
            />);
        case AttendeeFieldTypeEnum.Number:
            const onIntegerValueChange = (numberValue: number) => onAttendeeFieldValueChange(numberValue, fieldConfiguration);
            return (<Input.Number
                value={value}
                onChange={onIntegerValueChange}
                placeholder={fieldConfiguration.displayName}
            />);
        default:
            return undefined;
    }
};

export function AttendeeField({
    fieldConfiguration,
    fieldValues,
    isEditable,
    fieldsForMarkup,
    onAttendeeFieldValueChange
}: AttendeeFieldProps) {
    const value = fieldValues?.find(v => v.name === fieldConfiguration.fieldId)?.value ?? undefined;

    return (
        <>
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header" >
                    {fieldConfiguration.displayName}
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === fieldConfiguration.fieldId) ? "amber-field" : ""}>
                    {isEditable
                        ? FieldComponent(fieldConfiguration, value, onAttendeeFieldValueChange)
                        : (value || value === 0)
                            ? <span className="break-all">{value}</span>
                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

