import * as React from "react";
import "moment-duration-format";
import { Detail } from "@neworbit/simpleui-detail";
import { Authorize } from "reauthorize";
import { EventManagementAdminRoles } from "@common/auth/model";
import { EventInstance } from "../..";
import { AutomaticAttendeeAvailability } from "./AutomaticAttendeeAvailability";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { BookingTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { useSelector } from "react-redux";
import { isConstructionSelector } from "@common/crud/common/selectors";
import { VenueDetailModel } from "@common/crud/venue/model";
import { VenueTypeEnum } from "@common/crud/organisation/model";

interface AttendeeDetailsProps {
    eventInstance: EventInstance;
    venueDetail: VenueDetailModel;
}

export const AttendeeDetails: React.FC<AttendeeDetailsProps> = (props) => {

    const { eventInstance, venueDetail } = props;

    const isNotPublished = !!eventInstance && !eventInstance.publishDate;

    const isConstruction = useSelector(isConstructionSelector);

    return (
        <Authorize authorize={EventManagementAdminRoles}>
            <h3 className="event-instance-title">Attendee details</h3>
            <Detail
                label="Max attendees permitted"
                value={`${eventInstance.maxNumberOfAttendees}`}
                labelWidth={5}
                valueWidth={11}
            />
            {eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite && <>
                {!!eventInstance.maxNumberOfAttendeesPerTrainer &&

                <Detail
                    label="Max attendees permitted per theory trainer"
                    value={`${eventInstance.maxNumberOfAttendeesPerTrainer}`}
                    labelWidth={5}
                    valueWidth={11}
                />}

                <Detail
                    label="Max capacity of the course venue"
                    value={`${venueDetail?.maxCapacity ? venueDetail.maxCapacity: "" }`}
                    labelWidth={5}
                    valueWidth={11}
                />
            </>
            }

            {!!eventInstance.maxNumberOfAttendeesPerPracticalTrainer && <Detail
                label="Max attendees permitted per practical trainer"
                value={`${eventInstance.maxNumberOfAttendeesPerPracticalTrainer}`}
                labelWidth={5}
                valueWidth={11}
            />}
            <Detail
                label={`${WorkflowTypeEnum[eventInstance.workflowType]?.toLocaleUpperCase()} open places count`}
                value={`${isNotPublished ? 0 : eventInstance.openPlacesCount}`}
                labelWidth={5}
                valueWidth={11}
            />

            <Detail
                label="Places booked out of total"
                value={`${eventInstance.seatCount}/${eventInstance.openPlacesCount}`}
                labelWidth={5}
                valueWidth={11}
            />
            {(isConstruction && venueDetail?.venueType === VenueTypeEnum.Enquiry && eventInstance.bookingType === BookingTypeEnum.Open) && (
                <>
                    {eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite && (
                        <Detail
                            label="Classroom Break Even Point"
                            value={`${eventInstance.classroomBreakEvenPoint}`}
                            labelWidth={5}
                            valueWidth={11} />
                    )}
                    {eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital && (
                        <Detail
                            label="Digital Break Even Point"
                            value={`${eventInstance.digitalBreakEvenPoint}`}
                            labelWidth={5}
                            valueWidth={11} />
                    )}
                </>
            )}
            <AutomaticAttendeeAvailability eventInstance={eventInstance} />
        </Authorize>
    );
};
