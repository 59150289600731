import { en } from "@booking/i18n/resources";
import { i18n } from "i18next";
import { UseTranslationResponse } from "react-i18next";
import { DateFormat } from "./../../../App.Common/src/crud/common/DateTimeFormats";

enum LockedReason {
    None = 0,
    ExistingLock = 1,
    EmailRequestLimitReached = 2,
    ValidationAttemptsLimitReached = 3,
}
export interface RebookSignInProgress
{
    email?: string;
    validationResult: RebookSignInCodeValidationResult;
    dorsBookingId?: string;
    transferUrl?: string;
    accountLockedUntil?: moment.Moment;
    lockedReason?: LockedReason;
};

export type RebookSignInStatus = "initial" | "checkingDetails" | "providingPassword" | "verifying" | "verified";

export enum RebookSignInCodeValidationResult
{
    NotChecked = 0,
    NewEmailSent = 1,
    Superseded = 2,
    AccountNotFound = 3,
    Invalid = 4,
    SignedIn = 5,
    Locked = 6,
};

export const initialSignInProgressState: RebookSignInProgress = {
    validationResult: RebookSignInCodeValidationResult.NotChecked,
};

export type RebookingSignInTranslationKeys = keyof typeof en["RebookingSignIn"];

export const messageForValidationResult = (result: RebookSignInProgress, translationConfig: UseTranslationResponse<string, undefined>) => {

    const [t]: [t: (key: RebookingSignInTranslationKeys) => string, i18n: i18n, ready: boolean] = translationConfig;

    switch (result?.validationResult)
    {
        case RebookSignInCodeValidationResult.NewEmailSent:
            return t("NEW_CODE");
        case RebookSignInCodeValidationResult.Superseded:
            return t("SUPERSEDED");
        case RebookSignInCodeValidationResult.AccountNotFound:
            return t("ACCOUNT_NOT_RECOGNISED");
        case RebookSignInCodeValidationResult.Invalid:
            return t("CODE_NOT_RECOGNISED");
        case RebookSignInCodeValidationResult.Locked:
            return getLockedReasonFullMessage(result.accountLockedUntil, result.lockedReason, translationConfig);
        default:
            return null;
    }
};

const getLockedReasonMessage = (reason: LockedReason, translationConfig: UseTranslationResponse<string, undefined>) => {
    const [t]: [t: (key: RebookingSignInTranslationKeys) => string, i18n: i18n, ready: boolean] = translationConfig;
    switch (reason)
    {
        case LockedReason.EmailRequestLimitReached:
            return t("EMAIL_REQUEST_LIMIT_REACHED");
        case LockedReason.ValidationAttemptsLimitReached:
            return t("VALIDATION_ATTEMPTS_LIMIT_REACHED");
        case LockedReason.ExistingLock:
            return t("ACCOUNT_ALREADY_LOCKED");
        default:
            return null;
    }
};

const getLockedReasonFullMessage = (
    accountLockedUntilMoment: moment.Moment,
    lockedReason: LockedReason,
    translationConfig: UseTranslationResponse<string, undefined>
) => {
    const accountLockedUntil = accountLockedUntilMoment.format(DateFormat.ShortWithTime);
    const reasonText = getLockedReasonMessage(lockedReason, translationConfig);
    const tryAgainWithTimingText = translationConfig.t("TRY_AGAIN", { accountLockedUntil });

    if (!reasonText) {
        return "";
    }

    return `${reasonText} ${tryAgainWithTimingText}`;
};

