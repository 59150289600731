import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "moment-duration-format";
import { Accordion, AccordionContent, Popup } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import { BookingPaymentsInfo, PaymentPageNames, PaymentPageNamesEnum, ScheduledPaymentStatus } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import { loadEventInstanceDetailsForBooking, loadPoliceOrganisationDetailsForBooking } from "../../eventInstance/actions";
import { bookingBasePathSelector, bookingIdSelector } from "../../landing/selectors";
import { ApplicationState } from "../../applicationState";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { Authorize } from "reauthorize";
import { AttendeeRole } from "@common/auth/model";
import { useCanRebook } from "@booking/global/hooks/useCanRebook";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import classes from "./Dashboard.module.scss";
import { Icon as SemanticUiIcon } from "semantic-ui-react";
import { Button } from "@booking/common/Button";
import { DashboardRebookButton } from "@booking/global/DashboardRebookButton";
import { getExternalLinksForGivenLanguage } from "./TermsAndFaqLinks";
import { payOutstandingBalance } from "@common/payments/actions";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { AttendeeDetails } from "./AttendeeDetails";
import { CourseCoreDetails } from "./CourseCoreDetails";
import { BookingActions } from "./BookingActions";

export const Dashboard = () => {
    const { booking, eventInstances, organisations } = useSelector((state: ApplicationState) => state);
    const currentEventInstanceId = booking.originalEventInstanceId ?? booking.reservedEventInstanceId;
    const eventInstance = eventInstances.find(e => e.id === currentEventInstanceId);
    const organisation = booking?.forceId ? organisations.find(e => e.dorsId === booking.forceId) : undefined;
    const path = useSelector(bookingBasePathSelector);
    const eventTypeName = (eventInstance) ? eventInstance.eventTypeName : null;
    const bookingId = useSelector((state: ApplicationState) => bookingIdSelector(state));
    const dispatch = useDispatch();
    const [bookingPaymentsInfo, setBookingPaymentsInfo] = React.useState<BookingPaymentsInfo>(null);
    const [zoomHelpOpen, setZoomHelpOpen] = React.useState(false);
    const isDigitalCourse = eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    const courseAllowsRebooking = useCanRebook(eventInstance);

    useEffect(() => {
        const currentEventInstance = booking.originalEventInstanceId ?? booking.reservedEventInstanceId;
        if (!eventInstance && currentEventInstance) {
            dispatch(loadEventInstanceDetailsForBooking({ eventInstanceId: currentEventInstance }));
        }
        if (!organisation && booking && booking.forceId) {
            dispatch(loadPoliceOrganisationDetailsForBooking({ forceId: booking.forceId }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking, eventInstance, organisation]);

    useEffect(() => {
        async function getPaymentInfo() {
            if (!bookingPaymentsInfo) {
                const paymentApi = new PaymentApi(bookingId);
                const response = await paymentApi.getBookingPaymentsBalance();
                setBookingPaymentsInfo(response);
            }
        }

        getPaymentInfo();
    }, [bookingId, bookingPaymentsInfo]);

    const [t] = useTranslation("Dashboard");

    const getActiveScheduledPaymentsPlanValue = () => {
        if (bookingPaymentsInfo?.scheduledPaymentPlans?.length === 0 ?? false) {
            return undefined;
        }

        return bookingPaymentsInfo?.scheduledPaymentPlans?.reduce((sum, curr) => {
            if (curr.status === ScheduledPaymentStatus.Awaits) {
                sum += (curr.amountInPence / 100);
            }

            return sum;
        }, 0);
    };

    const welcomeHeaderText = t("WELCOME_HEADER", { attendeeName: booking.forename ?? "" });
    const welcomeText = t("WELCOME_TEXT", { eventTypeName, expiryDate: booking.expiryDate?.format(DateFormat.Long) ?? "" });
    const links = eventInstance && getExternalLinksForGivenLanguage(eventInstance, organisation);
    const amountDue = bookingPaymentsInfo?.totalAmountDue;
    const showAmountDue = amountDue >= 0;
    const price = bookingPaymentsInfo?.seatPrice;
    const showPrice = price > 0;
    const amount = amountDue !== 0 ? (amountDue / 100).toFixed(2) : amountDue.toFixed(2);
    const attendeeEventPrice = price !== 0 ? (price / 100).toFixed(2) : price.toFixed(2);;
    const scheduledPaymentAmount = getActiveScheduledPaymentsPlanValue();

    const payBalance = React.useCallback(() => {
        dispatch(payOutstandingBalance(`${path}/${eventInstance?.id}/${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`));
    }, [eventInstance?.id, path, dispatch]);

    return (
        <Authorize authorize={AttendeeRole}>
            <div className={classes.container}>
                <div className={classes.welcome}>
                    <h2>{welcomeHeaderText}</h2>
                    <p>{welcomeText}</p>
                </div>
                <div className={classes.courseDetails}>
                    <h3 className={classes.detailsHeader}>{t("YOUR_COURSE")}</h3>
                    <div className={classes.desktopDivider} />
                    <div className={classes.divider} />
                    <div className={classes.detailsGrid}>
                        <div className={classes.courseReference}>
                            <div className={classes.sectionTitle}>
                                {t("COURSE_DETAILS_TITLE")}
                            </div>
                            <div className={classes.bookingReference}>
                                <div className={classes.bookingRefText}>{t("BOOKING_REFERENCE")}:&nbsp;</div>
                                <div>{booking.attendanceId}</div>
                            </div>
                        </div>
                        <div className={classes.horizontalGridDivider} />
                        <div className={classes.twoColumnContainer}>
                            <div className={classes.coreDetailsContainer}>
                                <CourseCoreDetails eventInstance={eventInstance} />
                            </div>
                            <div className={classes.verticalGridDivider} />
                            <div className={classes.location}>
                                {isDigitalCourse?
                                    <>
                                        <Popup content={t("UNABLE_TO_JOIN_ZOOM")}
                                            trigger={
                                                <div className={classes.joinCourseButtonWrapper}>
                                                    <Button isDisabled={!eventInstance?.digitalCourseLink}
                                                        className={classes.joinCourseButton}
                                                        variant={"secondary"}>
                                                        <a href={eventInstance.digitalCourseLink} target="_blank" rel="noreferrer">
                                                            {t("JOIN_COURSE")}
                                                        </a>
                                                    </Button>
                                                </div>
                                            }
                                            disabled={Boolean(eventInstance?.digitalCourseLink)}
                                            position='top right'
                                        />

                                        <div className={classes.testMeetingLink}>
                                            <a href="https://zoom.us/test" target="_blank" rel="noreferrer">
                                                {t("TEST_MEETING")}
                                            </a>
                                            <Popup content={
                                                <Trans i18nKey={"Dashboard:RECOMMEND_ASSISTANCE"}>
                                                    {/* eslint-disable-next-line max-len */}
                                                    <strong>You have booked a Digital course.  This will be delivered using Zoom.</strong><br />We recommend clicking on the link in order to complete a practice of joining your Digital Driver Awareness course with TTC.
                                                </Trans>}
                                            trigger={
                                                <SemanticUiIcon name="info circle" />
                                            }
                                            position='top right'
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className={classes.addressSection}>
                                        <div className={classes.detailsFieldName}>{t("ADDRESS")}:&nbsp;</div>
                                        <div>{DisplayAddress({ address: eventInstance?.venueAddress })}</div>
                                    </div>}
                            </div>
                        </div>
                        <div className={classes.horizontalGridDivider} />
                        <AttendeeDetails booking={booking} />
                        <div className={classes.horizontalGridDivider} />
                        <div className={classes.financialDetail}>
                            <div className={classes.sectionTitle}>
                                {t("PAYMENT_DETAILS_TITLE")}
                            </div>
                            {showPrice && <div className={classes.detailsField}>
                                <div className={classes.detailsFieldName}>{t("PRICE")}:&nbsp;</div>
                                <div>£{attendeeEventPrice}</div>
                            </div>}
                            {showAmountDue && <div className={classes.detailsField}>
                                <div className={classes.detailsFieldName}>{t("OUTSTANDING_BALANCE")}:&nbsp;</div>
                                <div>£{amount}</div>
                            </div>}
                            {!booking.waivedRebookingFee && scheduledPaymentAmount > 0 &&
                                <div className={classes.detailsField}>
                                    <div className={classes.detailsFieldName}>{t("SCHEDULED_PAYMENT")}:&nbsp;</div>
                                    <div>£{scheduledPaymentAmount.toFixed(2)}</div>
                                </div> }
                        </div>
                        <div className={classes.horizontalGridDivider} />
                        <div className={classes.actionSection}>
                            <div className={classes.sectionTitle}>
                                {t("ADDITONAL_INFORMATION")}
                            </div>
                            <BookingActions booking={booking} termsAndConditionsLink={links?.termsAndConditionsLink} />
                        </div>
                        {bookingPaymentsInfo && bookingPaymentsInfo.totalAmountDue > 0 &&
                            <Button
                                className={classes.payBalanceButton}
                                variant="secondary"
                                onPress={payBalance}
                            >
                                {t("PAY_OUTSTANDING_BALANCE")}
                            </Button>
                        }
                    </div>
                    <div className={classes.rebookSection}>
                        <DashboardRebookButton
                            courseAllowsRebooking={courseAllowsRebooking}
                            path={path}
                            booking={booking}
                            isFreshBooking={false}
                        />
                    </div>
                    {isDigitalCourse &&
                    <div className={classes.zoomHelp}>
                        <Accordion>
                            <Accordion.Title active={zoomHelpOpen} onClick={() => setZoomHelpOpen((prev) => !prev)}>
                                <div className={classes.accordionTitle}>
                                    <div className={classes.accordionTitleRow}>
                                        <div>
                                            {t("ZOOM_ASSISTANCE_HEADER")}
                                        </div>
                                        <SemanticUiIcon name='angle down' />
                                    </div>
                                    <div className={classes.divider} />
                                </div>
                            </Accordion.Title>
                            <AccordionContent active={zoomHelpOpen}>
                                <Trans i18nKey="Dashboard:ZOOM_ASSISTANCE" >
                                    {/* eslint-disable-next-line max-len*/}
                                If you experience difficulties when completing your test Zoom meeting then please email us at <a href={"mailto:onlinecoursesupport@ttc-uk.com"}>onlinecoursesupport@ttc-uk.com</a>. Please include your <strong>reference number</strong>, <strong>name</strong> and <strong>contact number</strong> in order for our online support team to get back in touch.
                                </Trans>
                            </AccordionContent>
                        </Accordion>
                    </div>}
                </div>
            </div>
        </Authorize>);
};
