import moment from "moment";
import { BusinessLineType } from "@common/redux-helpers";
import { ClassroomEventTypeDetails, ConstructionWorkflowTypeArray, CorporateWorkflowTypeArray, DigitalEventTypeDetails, EventType,
    ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventInstance } from "@common/crud/eventInstance";
import { Venue } from "@common/crud/venue";
import { SchemeDeliveryTypeEnum, VenueTypeEnum } from "@common/crud/organisation/model";
import { includes } from "lodash";
import { DelegateComparisonTypeEnum } from "@common/crud/delegate/model";

export function isNullOrUndefined(value: any) {
    return value === undefined || value === null;
}

export function isNullOrUndefinedOrEmptyString(value: any) {
    return value === undefined || value === null || value === "";
}

export function isArrayNullOrEmpty<T>(array?: T[]) {
    return isNullOrUndefined(array) || array.length === 0;
}

export function getBusinessLineTypePath(businessLineType: BusinessLineType) {
    return businessLineType === BusinessLineType.Corporate
        ? "/corporate-event-management"
        : businessLineType === BusinessLineType.Construction
            ? "/construction-event-management"
            : "/police-and-court-event-management";
};

export function capitalize(str: string) {
    return str?.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()) ?? "";
};

export function defaultDateIfInvalid(date: moment.Moment, defaultDate: moment.Moment = undefined): moment.Moment | undefined {
    if (date < moment.utc("1900-01-01"))
    {
        return defaultDate;
    }

    return date;
}

export function translateField(fieldName: string, translationDictionary: Record<string, string>): string {
    return translationDictionary[fieldName];
}

export function isBusinessLineTypePoliceOrCourt(businessLineType: BusinessLineType) {
    return businessLineType === BusinessLineType.PoliceAndCourt || businessLineType === BusinessLineType.PoliceAndCourt2;
}

export function isBusinessLineTypeCorporateOrConstruction(businessLineType: BusinessLineType) {
    return businessLineType === BusinessLineType.Corporate || businessLineType === BusinessLineType.Construction;
}

export function isWorkflowPoliceOrCourt(workflowType: WorkflowTypeEnum | number) {
    return workflowType === WorkflowTypeEnum.Dors || workflowType === WorkflowTypeEnum.DDRS;
}

export function isWorkflowCorporate(workflowType: WorkflowTypeEnum | number) {
    return includes(CorporateWorkflowTypeArray, workflowType);
}

export function isWorkflowConstruction(workflowType: WorkflowTypeEnum | number) {
    return includes(ConstructionWorkflowTypeArray, workflowType);
}

export function doesPriceIncludeVatForWorkflow(workflowType: WorkflowTypeEnum | number) {
    return !isWorkflowConstruction(workflowType);
}

export function isEventTypeWorkflowPoliceOrCourt(eventType: EventType) {
    return isWorkflowPoliceOrCourt(eventType?.workflowType);
}

export function isEventTypeWorkflowCorporate(eventType: EventType) {
    return includes(CorporateWorkflowTypeArray, eventType?.workflowType);
}

export function isEventTypeWorkflowConstruction(eventType: EventType) {
    return includes(ConstructionWorkflowTypeArray, eventType?.workflowType);
}

export function isEventInstanceWorkflowPoliceOrCourt(eventInstance: EventInstance) {
    return isWorkflowPoliceOrCourt(eventInstance?.workflowType);
}

export function isEventInstanceWorkflowCorporate(eventInstance: EventInstance) {
    return includes(CorporateWorkflowTypeArray, eventInstance?.workflowType);
}

export function isEventInstanceWorkflowConstruction(eventInstance: EventInstance) {
    return includes(ConstructionWorkflowTypeArray, eventInstance?.workflowType);
}

export function getEventInstanceWorkflowTypeName(eventInstance: EventInstance) {
    switch (eventInstance?.workflowType) {
        case WorkflowTypeEnum.Dors:
            return "DORS";
        case WorkflowTypeEnum.DDRS:
            return "DDRS";
        case WorkflowTypeEnum.CPC:
            return "CPC";
        case WorkflowTypeEnum.Workshop:
            return "Workshop";
        case WorkflowTypeEnum.OnRoad:
            return "On Road";
        case WorkflowTypeEnum.BusinessDriver:
            return "Business Driver";
        default:
            return "";
    }
}

export function getSearchWorkflowTypeName(stateWorkflowType: string) {
    switch (stateWorkflowType) {
        case WorkflowTypeEnum.Dors.toString():
            return "DORS";
        case WorkflowTypeEnum.DDRS.toString():
            return "DDRS";
        case WorkflowTypeEnum.CPC.toString():
            return "CPC";
        case WorkflowTypeEnum.Workshop.toString():
            return "Workshop";
        case WorkflowTypeEnum.OnRoad.toString():
            return "On Road";
        case WorkflowTypeEnum.BusinessDriver.toString():
            return "Business Driver";
        default:
            return "";
    }
}

export function CalculateDelegateComparisonType(workflowType: WorkflowTypeEnum) {
    return workflowType === WorkflowTypeEnum.BusinessDriver
        ? DelegateComparisonTypeEnum.Uin
        : DelegateComparisonTypeEnum.DrivingLicenceNumber;
}

export function isHomePath(path: string) {
    return path ==="/police-and-court-event-management" || path === "/corporate-event-management" || path === "/construction-event-management";
}

export function workflowTypeSupportsCertificate(workflowType: WorkflowTypeEnum | number) {
    return workflowType === WorkflowTypeEnum.Workshop || workflowType === WorkflowTypeEnum.OnRoad || workflowType === WorkflowTypeEnum.BusinessDriver;
}

export function isVenueCorporate(venue: Venue) {
    return venue?.venueType === VenueTypeEnum.CorporateShared
    || venue?.venueType === VenueTypeEnum.CorporateOrganisationSpecific
    || venue?.venueType === VenueTypeEnum.DelegateHome;
}

export function isVenueConstruction(venue: Venue) {
    return venue?.venueType === VenueTypeEnum.ConstructionShared
    || venue?.venueType === VenueTypeEnum.Enquiry
    || venue?.venueType === VenueTypeEnum.ConstructionOrganisationSpecific;
}

export function isVenuePoliceAndCourt(venue: Venue) {
    return venue?.venueType === VenueTypeEnum.PoliceAndCourt;
}

export function supportsOnRoadReport(workflowType?: WorkflowTypeEnum, productCategory?: ProductCategoryEnum) {
    return workflowType === WorkflowTypeEnum.OnRoad
        || (workflowType === WorkflowTypeEnum.BusinessDriver && productCategory !== ProductCategoryEnum.LicenceAcquisition);
}

export function eventTypeIsMultiDay(eventType: EventType) {
    return eventType?.workflowType !== WorkflowTypeEnum.Dors
        && (((eventType?.deliveryType === SchemeDeliveryTypeEnum.Classroom || eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)
            && eventType?.classroomEventTypeDetails?.defaultNumberOfClassroomDeliveryDays > 1)
            || ((eventType?.deliveryType === SchemeDeliveryTypeEnum.Digital || eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)
            && eventType?.digitalEventTypeDetails?.defaultNumberOfDigitalDeliveryDays > 1));
}

export function eventTypeModifyingIsMultiDay(deliveryType: SchemeDeliveryTypeEnum, classroomEventTypeDetails?: ClassroomEventTypeDetails,
    digitalEventTypeDetails?: DigitalEventTypeDetails) {
    return ((deliveryType === SchemeDeliveryTypeEnum.Classroom ||
            deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
            classroomEventTypeDetails?.defaultNumberOfClassroomDeliveryDays > 1) ||
        ((deliveryType === SchemeDeliveryTypeEnum.Digital ||
            deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
            digitalEventTypeDetails?.defaultNumberOfDigitalDeliveryDays > 1);
}
