import * as React from "react";
import { Modal } from "@common/components";
import { Button, Grid, Message, Progress } from "semantic-ui-react";
import { TrainerDetailModel } from "../../model";
import { useDispatch } from "react-redux";
import { handleTrainerDorsSynchronizationResult } from "@common/crud/trainer/actions";
import { TrainerApi } from "../../trainerApi";
import { loadAndTrack } from "redux-request-loading";

interface SynchronizeDorsModalProps {
    trainer: TrainerDetailModel;
}

const SynchronizeDorsModal: React.FC<SynchronizeDorsModalProps> = ({
    trainer,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [finished, setFinished] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function dispatchSynronizeTrainerDors() {
            const api = new TrainerApi();
            const result = await loadAndTrack(dispatch, "synchronizeTrainerDors", api.synchronizeTrainerDors(trainer.id));
            await dispatch(handleTrainerDorsSynchronizationResult(result));
            setError(!!result.errorMessage);
            setFinished(true);
        }

        if (open) {
            setFinished(false);
            setError(false);
            dispatchSynronizeTrainerDors();
        }
    }, [dispatch, open, trainer?.id]);

    function toggleModal() {
        setOpen(!open);
    }

    return (
        <>
            <Grid.Row>
                <Grid.Column width={3}>
                    <Button
                        className="link-button"
                        onClick={toggleModal}
                        disabled={!trainer?.ndorsLicenceNumber}
                    >
                        Sync with DORS
                    </Button>
                </Grid.Column>
            </Grid.Row>

            <Modal open={open}>
                <Modal.Header>Sync with DORS</Modal.Header>
                <Modal.Content>
                    {finished ? (
                        error ? (
                            <Message error>
                                DORS synchronization failed for the trainer
                            </Message>
                        ) : (
                            <Message info>
                                DORS synchronization finished for the trainer
                            </Message>
                        )
                    ) : (
                        <Progress percent={100} active>
                            DORS synchronization running for the trainer
                        </Progress>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={toggleModal}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { SynchronizeDorsModal };
