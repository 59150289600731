import * as React from "react";
import { Grid, Item } from "semantic-ui-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Booking, bookingAddress } from "../models";
import { EventTypeCategory } from "@common/crud/attendee/model";

interface Props extends WithTranslation {
    booking: Booking;
    showBookingReference?: boolean;
    showPersonalDetails: boolean;
}

const BookingSummaryGridRowsInternal: React.FC<Props> = ({ booking, t, showBookingReference, showPersonalDetails }: Props) => {

    const addressAndPostcode = () => {
        const addr = bookingAddress(booking);
        return ((booking.postcode || "") === "")
            ? <span>{addr}</span>
            : <>{addr} <span className="no-break">{booking.postcode}</span></>;
    };
    const isDors = booking?.eventTypeCategory === EventTypeCategory.Dors;

    const items = booking ? [
        showPersonalDetails && { key: 1, fieldName: t("NAME"), value: `${booking.forename || ""} ${booking.surname || ""}` },
        showPersonalDetails && { key: 2, fieldName: t("ADDRESS"), value: addressAndPostcode() },
        showPersonalDetails && !isDors && { key: 5, fieldName: t("LICENCE_NUMBER"), value: `${booking.driverNumber || ""}` }
    ].filter(i => i !== undefined && i.value !== undefined) : [];

    return (
        <>
            {showBookingReference && <Grid.Row>
                <Item>
                    <Item.Content>
                        <Item.Header className="summary-title">{t("BOOKING_REFERENCE")}</Item.Header>
                        <Item.Description className="summary-value">{`${booking.attendanceId || ""}`}</Item.Description>
                    </Item.Content>
                </Item>
            </Grid.Row>
            }
            {items.map(elem =>
                (<Grid.Row key={elem.key}>
                    <Item>
                        <Item.Content>
                            <Item.Header className="summary-title">{elem.fieldName}</Item.Header>
                            <Item.Description className="summary-value">{elem.value}</Item.Description>
                        </Item.Content>
                    </Item>
                </Grid.Row>)
            )}
        </>);
};

export const BookingSummaryGridRows = withTranslation("BookingDetails")(BookingSummaryGridRowsInternal);
