/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { Form, Tab, TabProps, DropdownItemProps, Icon, Message, Segment, Grid, Button } from "semantic-ui-react";
import { FormBaseComponent, SaveDispatchProps, FormState } from "@neworbit/simpleui-forms";
import { debounce } from "@neworbit/simpleui-utils";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { DelegateCreateEditModel, AppState, DelegateDetailModel, CorporateEventManagementSearchPath, DrivingLicenceCountry,
    BasicCorporateEventManagementSearchPath, filteredVocationalLicenceCatogories, DrivingLicenceCountryEnum,
    BasicConstructionEventManagementSearchPath, ConstructionEventManagementSearchPath, DelegateComparisonTypeEnum } from "../model";
import { createDelegate } from "../actions";
import { CREATE_DELEGATE } from "../actiontypes";
import { corporateOrganisationOptionsSelector, organisationSelector } from "@common/crud/organisation/selectors";
import { Organisation, OrganisationApi, OrganisationState } from "@common/crud/organisation";
import { phoneNumberWithSpaces } from "@common/validation";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { toast } from "@common/toasts";
import { DelegateApi } from "../delegateApi";
import { CreateModal } from "./CreateModal";
import { basePathSelector } from "../selectors";
import { DisplayTextIfNotInProduction } from "@common/helpers/environment";
import { AppCommonState } from "@common/appCommonState";
import { IsProductionEnvironment } from "@common/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { licenceValidator } from "@common/validation/drivingLicenceNumber";
import { ValidationResultType } from "not-valid/bin/results";
import { AttendeeField, CompanyTypeEnum } from "@common/crud/organisation/model";
import { DelegateAttendeeField } from "./DelegateAttendeeField";
import { EventInstanceAttendeeField } from "@common/crud/eventInstance/model";
import { MxEmailCheckResult } from "@common/crud/email/model";
import { EmailApi } from "@common/email/emailApi";
import { companyTypeSupportsDqcFields } from "@common/crud/organisation/utils/organisationsHelper";
import { validators } from "not-valid";
import { ManagerContactRow } from "@common/crud/common/ManagerContactRow";

export interface CreateProps {
    open: boolean;
    basePath: string;
    findUserByEmail: (organisationId: string, email: string) => Promise<DelegateDetailModel>;
    findUserByDrivingLicence: (organisationId: string, drivingLicence: string) => Promise<DelegateDetailModel>;
    findUserByUin: (organisationId: string, uin: string) => Promise<DelegateDetailModel>;
    checkEmail: (email: string) => Promise<MxEmailCheckResult>;
    loadAttendeeFields: (organisationId: string) => Promise<AttendeeField[]>;
    isSaving: boolean;
    organisationOptions: DropdownItemProps[];
    organisationId: string;
    environment?: string;
    currentUserEmail?: string;
    organisationCompanyTypes?: number[];
}

export interface DispatchProps extends SaveDispatchProps<DelegateCreateEditModel> {
    close: () => void;
}

interface CreateState extends FormState<DelegateCreateEditModel> {
    duplicateEmailUser: DelegateDetailModel;
    duplicateLicenceNumberUser: DelegateDetailModel;
    duplicateUinUser: DelegateDetailModel;
    activeIndex: string | number;
    searchingEmail: boolean;
    searchingLicenceNumber: boolean;
    searchingUin: boolean;
    licenceNumberTouched: boolean;
    uinTouched: boolean;
    managersValid: Set<string>;
    attendeeFields: AttendeeField[];
}

export class CreateForm extends FormBaseComponent<DelegateCreateEditModel, CreateProps, CreateState> {
    private onChange = (k: keyof DelegateCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private panes = [
        {
            menuItem: "Details", render: () => {
                const { values, duplicateEmailUser, duplicateLicenceNumberUser, duplicateUinUser, licenceNumberTouched, uinTouched,
                    searchingEmail, showErrors } = this.state;
                const { basePath } = this.props;

                const delegateComparisonType = this.props.organisationCompanyTypes
                    && this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver)
                    ? DelegateComparisonTypeEnum.Uin
                    : DelegateComparisonTypeEnum.DrivingLicenceNumber;

                return (<Tab.Pane key="mainPane">
                    {delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                        <Input.Text
                            value={values.uin}
                            label="UIN"
                            showErrors={showErrors || uinTouched}
                            onChange={this.onUinChanged}
                            onBlur={this.onUinTouched}
                            required
                            validation={[validators.validLength({ max: 12 }), validators.validAlphanumeric()]}
                        />
                    )}
                    {duplicateUinUser &&
                        <Message as={Segment} className="cancel-action">
                            <p>A delegate with that UIN already exists:&nbsp;
                                <Link href={`${basePath}/${duplicateUinUser.id}`}>
                                    {duplicateUinUser.fullName}
                                </Link>
                            </p>
                        </Message>
                    }
                    <Input.Text
                        value={values.forename}
                        label="Forename"
                        showErrors={showErrors}
                        required
                        onChange={this.onChange("forename")}
                    />
                    <Input.Text
                        value={values.surname}
                        label="Surname"
                        showErrors={showErrors}
                        required
                        onChange={this.onSurnameChanged}
                    />
                    <Input.Dropdown
                        value={values.organisationId}
                        label="Organisation"
                        showErrors={showErrors}
                        required
                        options={this.props.organisationOptions}
                        onChange={this.onOrganisationChange}
                        dynamicOptions
                        search
                        disabled={values.organisationId === this.props.organisationId}
                    />
                    {this.props.organisationCompanyTypes
                        && !this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver) && (
                        <AddressLookup
                            address={values.address}
                            showErrors={showErrors}
                            required={false}
                            onChange={this.onChange("address")}
                            autofill
                        />
                    )}
                    <h2>Contact Details</h2>
                    <Input.Email
                        value={values.email}
                        label="Email"
                        showErrors={showErrors}
                        onChange={this.onEmailChanged}
                    />
                    {searchingEmail &&
                        <>
                            <Message as={Segment} className="cancel-action">
                                <Icon loading name='spinner' />
                                Searching for existing delegates with that email...
                            </Message>
                            <Message as={Segment} className="cancel-action">
                                <Icon loading name='spinner' />
                                Validating email...
                            </Message>
                        </>
                    }
                    {duplicateEmailUser &&
                        <Message as={Segment} className="cancel-action">
                            <p>A delegate with that email already exists:&nbsp;
                                <Link href={`${basePath}/${duplicateEmailUser.id}`}>
                                    {duplicateEmailUser.fullName}
                                </Link>
                            </p>
                        </Message>
                    }
                    {values.mxEmailCheckResult && !values.mxEmailCheckResult.valid &&
                        <Message as={Segment} className="cancel-action">
                            <p><Icon className="validation-icon" name={"exclamation circle"} />{values.mxEmailCheckResult.error}</p>
                        </Message>
                    }
                    <Input.Text
                        value={values.mobileNumber}
                        label="Mobile Number"
                        showErrors={showErrors}
                        validation={phoneNumberWithSpaces()}
                        onChange={this.onChange("mobileNumber")}
                    />
                    <h2>Licence Details</h2>
                    <Input.DropdownNumber
                        value={values.drivingLicenceCountry}
                        label="Driving Licence Country"
                        showErrors={this.state.showErrors}
                        required={delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber}
                        options={Object.keys(DrivingLicenceCountry).filter(k => +k !== 0).map(k => ({ text: DrivingLicenceCountry[k], value: +k }))}
                        onChange={this.onDrivingLicenceCountryChanged}
                        search
                    />
                    <Input.Text
                        value={values.licenceNumber}
                        label="Licence Number"
                        showErrors={showErrors || licenceNumberTouched}
                        onChange={this.onLiceneNumberChanged}
                        onBlur={this.onLicenceNumberTouched}
                        disabled={!values.drivingLicenceCountry}
                        required={delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber}
                        validation={[licenceValidator(values?.drivingLicenceCountry, values?.surname, [])]}
                        key={`${values?.drivingLicenceCountry || "country"}-${values?.surname || "surname"}`}
                    />
                    {duplicateLicenceNumberUser &&
                        <Message as={Segment} className="cancel-action">
                            <p>A delegate with that driving licence number already exists:&nbsp;
                                <Link href={`${basePath}/${duplicateLicenceNumberUser.id}`}>
                                    {duplicateLicenceNumberUser.fullName}
                                </Link>
                            </p>
                        </Message>
                    }
                    {values.drivingLicenceCountry === DrivingLicenceCountryEnum.NonGBNI && (
                        <>
                            <Input.Text
                                value={values.drivingLicenceExactCountry}
                                label="Issuing Country"
                                showErrors={showErrors}
                                onChange={this.onChange("drivingLicenceExactCountry")}
                            />
                            <MuiDateField
                                value={values.dateOfBirth}
                                label="Date Of Birth"
                                showErrors={showErrors}
                                onChange={this.onDateOfBirthChanged}
                            />
                        </>
                    )}
                    <Input.DropdownMulti
                        value={values.vocationalLicenceCategories}
                        label="Vocational Licence Categories"
                        showErrors={this.state.showErrors}
                        options={filteredVocationalLicenceCatogories(values.vocationalLicenceCategories)}
                        dynamicOptions
                        onChange={this.onChange("vocationalLicenceCategories")}
                        multiple
                        search />
                    {companyTypeSupportsDqcFields(this.props.organisationCompanyTypes) && (
                        <>
                            <h2>DQC Details</h2>
                            <Input.Text
                                value={values.dqcReference}
                                label="DQC Reference"
                                showErrors={showErrors}
                                onChange={this.onChange("dqcReference")} />
                            <MuiDateField
                                value={values.dqcExpiry}
                                label="DQC Expiry"
                                showErrors={showErrors}
                                onChange={this.onDqcExpiryChanged} />
                        </>
                    )}

                    {delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <h2>Managers</h2>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Button
                                        onClick={this.addManager}
                                        disabled={values.managers?.length >= 5}
                                        content="Add manager"
                                        className="vertical-center"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {values.managers?.map((m, index) => (
                                <ManagerContactRow
                                    key={`manager-contact-${index}`}
                                    managerContact={m}
                                    index={index}
                                    showErrors={showErrors}
                                    updateManagerValue={this.updateManagerValue}
                                    removeManager={this.removeManager}
                                />
                            ))}
                        </Grid>
                    )}

                    {this.state.attendeeFields && this.state.attendeeFields.length > 0 && (
                        <h2>Delegate Fields</h2>
                    )}
                    {this.state.attendeeFields?.map(af =>
                        (<DelegateAttendeeField
                            key={af.fieldId}
                            fieldConfiguration={af}
                            fieldValues={this.state.values.attendeeFieldValues}
                            onAttendeeFieldValueChange={this.onAttendeeFieldValueChange}
                        />)
                    )}
                </Tab.Pane>);
            }
        },
    ];

    constructor(props: CreateProps & SaveDispatchProps<DelegateCreateEditModel>) {
        super(props);
        this.state = this.initialState;
        this.updateManagerValue = this.updateManagerValue.bind(this);
        this.fixContactsInvalid = this.fixContactsInvalid.bind(this);
        this.addManager = this.addManager.bind(this);
        this.removeManager = this.removeManager.bind(this);
    }

    public prepareEmailWithAlias(email: string) {
        if (!email || email.indexOf("@") === -1) {
            return "";
        }

        const emailParts = email.split("@");
        const alias = "+" + Array.from(Array(16), () => Math.floor(Math.random() * 32).toString(32)).join("") + "@";

        return emailParts[0] + alias + emailParts[1];
    }

    public initialValueState: DelegateCreateEditModel = {
        id: "",
        forename: DisplayTextIfNotInProduction("Test", this.props.environment),
        surname: DisplayTextIfNotInProduction("Delegate", this.props.environment),
        organisationId: "",
        address: {
            postalCode: "",
            city: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: ""
        },
        email: DisplayTextIfNotInProduction(this.prepareEmailWithAlias(this.props.currentUserEmail), this.props.environment),
        mobileNumber: "",
        licenceNumber: "",
        managers: [
            { name: "", telephone: "", email: "" }
        ]
    };

    public initialState: CreateState = {
        values: this.initialValueState,
        valid: {},
        duplicateEmailUser: undefined,
        searchingEmail: false,
        duplicateLicenceNumberUser: undefined,
        duplicateUinUser: undefined,
        searchingLicenceNumber: false,
        searchingUin: false,
        licenceNumberTouched: false,
        uinTouched: false,
        activeIndex: 0,
        managersValid: new Set<string>(),
        attendeeFields: []
    };

    public componentDidUpdate() {
        if (this.props.organisationId && this.state.values.organisationId !== this.props.organisationId) {
            this.loadAttendeeFieldsIntoState(this.props.organisationId);
            this.setState({ values: { ...this.state.values, organisationId: this.props.organisationId, attendeeFieldValues: [] } });
        }
    }

    private loadAttendeeFieldsIntoState = async (organisationId: string) => {
        const attendeeFields = await this.props.loadAttendeeFields(organisationId);
        this.setState({ attendeeFields });
    };

    private onOrganisationChange = (value: string) => {
        if (this.state.values.organisationId !== value) {
            this.onChange("organisationId")(value, !!value);
            this.onChange("attendeeFieldValues")([], true);
            if (!this.props.organisationId && value) {
                this.loadAttendeeFieldsIntoState(value);
            }
        }
    };

    private onAttendeeFieldValueChange = (value: any, fieldConfiguration: EventInstanceAttendeeField) => {
        this.updateProperty("attendeeFieldValues",
            [ ...(this.state.values.attendeeFieldValues || []).filter(afv => afv.name !== fieldConfiguration.fieldId),
                { name: fieldConfiguration.fieldId, value, displayName: fieldConfiguration.displayName, type: fieldConfiguration.type } ],
            true);
    };

    public componentWillReceiveProps(nextProps: Readonly<CreateProps & SaveDispatchProps<DelegateCreateEditModel>>) {
        if (!IsProductionEnvironment(nextProps.environment) && this.props.currentUserEmail !== nextProps.currentUserEmail) {
            this.setState({ values: { ...this.state.values, email: this.prepareEmailWithAlias(nextProps.currentUserEmail) } });
        }
    }

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tab panes={this.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
            </Form>
        );
    }

    public submit = () => {
        this.setState({ showErrors: true });

        if (this.state.managersValid && this.state.managersValid.size !== 0) {
            toast.warning("Delegate cannot be created as some managers have duplicate email addresses");
            return;
        }

        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            toast.error("Please correct any invalid fields");
            this.setState(prevState => ({ ...prevState, showErrors: true, activeIndex: 0 }));
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const organisationId = this.state.values.organisationId || this.props.organisationId;
            const duplicateEmailUser = await this.props.findUserByEmail(organisationId, value);
            const mxEmailCheckResult = await this.props.checkEmail(value);
            this.updateProperty("mxEmailCheckResult", mxEmailCheckResult, true);
            this.setState({ searchingEmail: false, duplicateEmailUser, valid: { ...this.state.valid, email: !duplicateEmailUser } });
        } else {
            this.updateProperty("mxEmailCheckResult", null, true);
            this.setState({ duplicateEmailUser: null });
        }
    };

    private onUinTouched = () => {
        this.setState({ uinTouched: true });
    };

    private onLicenceNumberTouched = () => {
        this.setState({ licenceNumberTouched: true });
    };

    private onUinChanged = async (value: string, valid: boolean) => {
        this.updateProperty("uin", value, valid);
        if (value && valid) {
            this.setState({ searchingUin: true, valid: { ...this.state.valid, uin: false } });
            const duplicateUinUser = await this.props.findUserByUin(this.state.values.organisationId, value);

            if (duplicateUinUser?.id !== this.state.values.id) {
                this.setState({
                    searchingLicenceNumber: false,
                    duplicateUinUser,
                    valid: { ...this.state.valid, uin: !duplicateUinUser }
                });
            } else {
                this.setState({ searchingUin: false, duplicateUinUser: null, valid: { ...this.state.valid, uin: true } });
            }
        } else {
            this.setState({ duplicateUinUser: null });
        }
    };

    private onLiceneNumberChanged = async (value: string, valid: boolean) => {
        this.updateProperty("licenceNumber", value, valid);

        const delegateComparisonType = this.props.organisationCompanyTypes && this.props.organisationCompanyTypes.includes(CompanyTypeEnum.BusinessDriver)
            ? DelegateComparisonTypeEnum.Uin
            : DelegateComparisonTypeEnum.DrivingLicenceNumber;

        if (delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber && value && valid) {
            this.setState({ searchingLicenceNumber: true, valid: { ...this.state.valid, licenceNumber: false } });
            const organisationId = this.state.values.organisationId || this.props.organisationId;
            const duplicateLicenceNumberUser = await this.props.findUserByDrivingLicence(organisationId, value);
            this.setState({
                searchingLicenceNumber: false,
                duplicateLicenceNumberUser,
                valid: { ...this.state.valid, licenceNumber: !duplicateLicenceNumberUser }
            });
        } else {
            this.setState({ duplicateLicenceNumberUser: null });
        }
    };

    private onSurnameChanged = async (value: string, valid: boolean) => {
        this.updateProperty("surname", value, valid);
        const drivingLicenceNumberValidator = licenceValidator(this.state.values?.drivingLicenceCountry, value, []);
        const licenceValid = drivingLicenceNumberValidator(this.state.values.licenceNumber);
        this.setState({ valid: { ...this.state.valid, licenceNumber: licenceValid.type === ValidationResultType.Pass } });
    };

    private onDrivingLicenceCountryChanged = async (value: number, valid: boolean) => {
        this.updateProperty("drivingLicenceCountry", value, valid);
        const drivingLicenceNumberValidator = licenceValidator(value, this.state.values?.surname, []);
        const licenceValid = drivingLicenceNumberValidator(this.state.values.licenceNumber);
        this.setState({ valid: { ...this.state.valid, licenceNumber: licenceValid.type === ValidationResultType.Pass } });

        if (value !== DrivingLicenceCountryEnum.NonGBNI) {
            this.updateProperty("drivingLicenceExactCountry", undefined, true);
            this.updateProperty("dateOfBirth", undefined, true);
            this.setState({ valid: { ...this.state.valid, drivingLicenceExactCountry: true, dateOfBirth: true } });
        }
    };

    private onDqcExpiryChanged = (value: moment.Moment, valid: boolean) => {
        if (this.state.values.dqcExpiry !== value) {
            this.updateProperty("dqcExpiry", value, valid);
        }
    };

    private onDateOfBirthChanged = (value: moment.Moment, valid: boolean) => {
        if (this.state.values.dateOfBirth !== value) {
            this.updateProperty("dateOfBirth", value, valid);
        }
    };

    private updateManagerValue = (index: number, field: string, value: string, valid: boolean) => {
        const validField = `contact.${index}.${field}`;
        const uniqueValidField = "contact.unique";
        const newContactValid = new Set(this.state.managersValid);

        if (valid && newContactValid.has(validField)) {
            newContactValid.delete(validField);
        }

        if (!valid && !newContactValid.has(validField)) {
            newContactValid.add(validField);
        }

        const newManagers = this.state.values.managers.map((existingManager, managerIndex) => managerIndex === index
            ? { ...existingManager, [field]: value }
            : existingManager);
        const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
        const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length ;

        if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
            newContactValid.delete(uniqueValidField);
        }

        if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
            newContactValid.add(uniqueValidField);
        }

        this.setState({ values: { ...this.state.values, managers: newManagers } });

        this.setState({ managersValid: newContactValid });
    };

    public fixContactsInvalid(indexRemoved: number, newManagers: { name: string; telephone: string; email: string }[]) {
        const newManagersValid = new Set<string>();
        this.state.managersValid.forEach(invalidField => {
            const parts = invalidField.split(".");

            if (parts[0] === "contact") {
                if (parts[1] !== "unique") {
                    if (+parts[1] < indexRemoved) {
                        newManagersValid.add(invalidField);
                    }

                    if (+parts[1] > indexRemoved) {
                        const newInvalidField = `${parts[0]}.${+parts[1] - 1}.${parts[2]}`;
                        newManagersValid.add(newInvalidField);
                    }
                } else {
                    const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
                    const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length;

                    if (hasDuplicates) {
                        newManagersValid.add(invalidField);
                    }
                }
            } else {
                newManagersValid.add(invalidField);
            }
        });
        this.setState({ managersValid: newManagersValid });
    }

    public removeManager(index: number) {
        const newManagers = this.state.values.managers.filter((_, managerIndex: number) => managerIndex !== index);
        this.updateProperty("managers", newManagers, true);
        this.fixContactsInvalid(index, newManagers);
    }

    public addManager(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateProperty(
            "managers",
            [ ...this.state.values.managers || [], { name: "", email: "" } ],
            true
        );
    }

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));
}

function mapStateToProps(state: AppState & LoadingState & OrganisationState & AppCommonState) {
    const pathname = state.router.pathname;
    const organisationId = state.router.params.organisationId;
    const openWithBusinessDriver = pathname.endsWith("/createBusinessDriver");
    const openWithTtc = pathname.endsWith("/createTTC");
    const open = pathname.endsWith("/create") || openWithBusinessDriver || openWithTtc;
    const basePath = basePathSelector(state);
    const organisation = organisationSelector(state) as Organisation;

    const organisationCompanyTypes = organisation?.companyType ??
        (openWithBusinessDriver
            ? [CompanyTypeEnum.BusinessDriver]
            : openWithTtc
                ? [CompanyTypeEnum.TTC]
                : []);

    let organisationOptions = corporateOrganisationOptionsSelector(state);

    if (openWithBusinessDriver) {
        organisationOptions = organisationOptions.filter(o => o.companyType.some(ct => ct === CompanyTypeEnum.BusinessDriver));
    }

    if (openWithTtc) {
        organisationOptions = organisationOptions.filter(o => o.companyType.some(ct => ct === CompanyTypeEnum.TTC || ct === CompanyTypeEnum.LicenceBureau));
    }

    return {
        open,
        organisationId,
        basePath,
        isSaving: isRequestActive(state, CREATE_DELEGATE),
        organisationOptions,
        environment: state.appSettings?.environment,
        currentUserEmail: state.currentUser?.email,
        organisationCompanyTypes
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (delegate: DelegateCreateEditModel, basePath: string) => {
            const search = basePath === CorporateEventManagementSearchPath || basePath === BasicCorporateEventManagementSearchPath
                || basePath === ConstructionEventManagementSearchPath || basePath === BasicConstructionEventManagementSearchPath;
            return dispatch(createDelegate(delegate, basePath, search));
        },
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): CreateProps & DispatchProps {
    const delegateApi = new DelegateApi();
    const emailApi = new EmailApi();
    const organisationApi = new OrganisationApi();
    return {
        organisationId: propsFromState.organisationId,
        basePath: propsFromState.basePath,
        open: propsFromState.open,
        save: (delegate: DelegateCreateEditModel) => propsFromDispatch.dispatchSave(delegate, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
        findUserByEmail: debounce((organisationId: string, email: string) => delegateApi.findByEmail(organisationId, email), 300),
        findUserByDrivingLicence: debounce((organisationId: string, drivingLicence: string) =>
            delegateApi.findByDrivingLicence(organisationId, drivingLicence), 300),
        findUserByUin: debounce((organisationId: string, uin: string) =>
            delegateApi.findByUin(organisationId, uin), 300),
        checkEmail: debounce((email: string) => emailApi.checkEmail(email), 300),
        loadAttendeeFields: (organisationId) => organisationApi.getAttendeeFields(organisationId, true),
        isSaving: propsFromState.isSaving,
        organisationOptions: propsFromState.organisationOptions,
        environment: propsFromState.environment,
        currentUserEmail: propsFromState.currentUserEmail,
        organisationCompanyTypes: propsFromState.organisationCompanyTypes
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
