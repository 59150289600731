import { createValidator } from "not-valid";

const range = (min: number, max: number) => {
    // eslint-disable-next-line eqeqeq
    return createValidator<number>(v => v != null && v >= min && v <= max, `Value must be between ${min} and ${max}`);
};

export const rangeValidator = (min: number, max: number) => {
    return [ range(min, max) ];
};
