import * as React from "react";
import { Modal } from "@common/components";
import { Spinner } from "@common/global";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { UpdateSpecialRequirementsTable } from "./UpdateSpecialRequirementsTable";
import { AttendeeApi, AttendeeListModel, loadAttendeesSuccess } from "@common/crud/attendee";
import { loadEventInstanceDetail } from "../../actions";

interface UpdateSpecialRequirementsModalProps {
    eventInstanceId: string;
    eventInstanceFinished: boolean;
    delegateAttendees: AttendeeListModel[];
    isDigitalCourse: boolean;
}

export const UpdateSpecialRequirementsModal: React.FC<UpdateSpecialRequirementsModalProps> = ({
    eventInstanceId, eventInstanceFinished, delegateAttendees, isDigitalCourse
}) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [newDelegates, setNewDelegates] = React.useState<AttendeeListModel[]>(delegateAttendees);

    React.useEffect(() => {
        setNewDelegates(delegateAttendees);
    }, [delegateAttendees]);

    const onOpenButtonClick = React.useCallback(() => {
        setOpen(true);
    }, []);

    const onResetData = React.useCallback(() => {
        setNewDelegates(delegateAttendees);
        setOpen(false);
    }, [delegateAttendees]);

    const onCancelClick = React.useCallback(() => {
        onResetData();
    }, [onResetData]);

    const onContinueClick = React.useCallback(async () => {
        setIsSubmitting(true);
        const attendeeApi = new AttendeeApi(eventInstanceId);
        const result = await attendeeApi.updateDelegateAttendeesSpecialRequirements(
            newDelegates.map(d => ({ id: d.id, contextAttendee: d.contextAttendee, specialRequirements: d.specialRequirements })));
        dispatch(loadAttendeesSuccess(result));
        dispatch(loadEventInstanceDetail({ eventInstanceId }));
        setIsSubmitting(false);
        setOpen(false);
    }, [dispatch, eventInstanceId, newDelegates]);

    return (
        <>
            <Button onClick={onOpenButtonClick} disabled={eventInstanceFinished}>
                Update Special Requirements
            </Button>

            <Modal size="large" open={open}>
                <Modal.Header>
                    Update Special Requirements
                </Modal.Header>
                <Modal.Content>
                    <h3>
                        Please confirm any special requirements for the delegates on this register.
                    </h3>
                    <UpdateSpecialRequirementsTable
                        delegates={delegateAttendees}
                        newDelegates={newDelegates}
                        setNewDelegates={setNewDelegates}
                        isDigitalCourse={isDigitalCourse}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Spinner active={isSubmitting}>
                        <Button onClick={onCancelClick} className="cancel-action">
                            Cancel
                        </Button>
                        <Button
                            onClick={onContinueClick}
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content="Continue"
                        />
                    </Spinner>
                </Modal.Actions>
            </Modal>
        </>
    );
};
