import { Spinner } from "@common/global";
import { StringValues } from "@common/model";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { Button, Checkbox, Grid, Pagination, PaginationProps } from "semantic-ui-react";
import { EventInstanceApi } from "../../eventInstanceApi";
import { Area, EventInstance, SearchOptions } from "../../model";
import { eventInstanceSearchOptionsSelector } from "../../selectors";
import { AllItems } from "../AllItems";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

const PAGE_SIZE = 50;

const parseOptions = (options: SearchOptions) => {
    const parsedOptions: StringValues<SearchOptions> = {
        hasAutoSpreadStatus: "true",
        autoSpreadInProgress: options.autoSpreadInProgress?.toString() === "false" ? "false" : "true",
        page: options.page?.toString() ?? "0",
        maxPageSize: PAGE_SIZE.toString()
    };

    return parsedOptions;
};

export const SpreadingOverview = () => {
    const routerSearchOptions = useSelector(eventInstanceSearchOptionsSelector);
    const [eventInstances, setEventInstances] = React.useState<EventInstance[]>([]);
    const [onlyShowInProgress, setOnlyShowInProgress] = React.useState(routerSearchOptions.autoSpreadInProgress?.toString() === "false" ? false : true);
    const [page, setPage] = React.useState(+routerSearchOptions.page || 1);
    const [totalNumberOfEventInstances, setTotalNumberOfEventInstances] = React.useState(0);
    const [refreshing, setRefreshing] = React.useState(true);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const getEventInstances = async () => {
            const response = await new EventInstanceApi().getAll({
                hasAutoSpreadStatus: true,
                autoSpreadInProgress: onlyShowInProgress,
                workflowTypes: [WorkflowTypeEnum.Dors],
                maxPageSize: PAGE_SIZE,
                page: page - 1
            });
            setEventInstances(response.eventInstances.reverse());
            setTotalNumberOfEventInstances(response.totalNumberOfEventInstances);
        };

        if (refreshing) {
            getEventInstances().then(() => setRefreshing(false));
            if (!initialLoad) {
                const query = parseOptions({ hasAutoSpreadStatus: true, autoSpreadInProgress: onlyShowInProgress, page, maxPageSize: PAGE_SIZE });
                dispatch(push({ pathname: undefined, query }));
            } else {
                setInitialLoad(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshing]);

    function onToggleChange() {
        setOnlyShowInProgress(prev => !prev);
        setPage(1);
        setRefreshing(true);
    }

    function onPageChange(_: any, { activePage }: PaginationProps) {
        setPage(activePage as number);
        setRefreshing(true);
    }

    function onRefreshClick(e: any) {
        e.currentTarget.blur();
        setRefreshing(true);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <h1>Spreading Overview</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                    <Checkbox
                        checked={onlyShowInProgress}
                        onChange={onToggleChange}
                        toggle
                        label={onlyShowInProgress ? "Only In Progress" : "All"}
                    />
                </Grid.Column>
                <Grid.Column width={8} className="no-margin-left no-margin-right" textAlign="right">
                    <Button content="Refresh" onClick={onRefreshClick} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Spinner active={refreshing}>
                    <Grid.Column width={16}>
                        <AllItems
                            eventInstances={eventInstances}
                            area={Area.AdminEventManagementSpreadingOverview}
                        />
                    </Grid.Column>
                </Spinner>
            </Grid.Row>
            {totalNumberOfEventInstances > PAGE_SIZE &&
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Pagination
                            activePage={page}
                            totalPages={Math.ceil(totalNumberOfEventInstances / PAGE_SIZE)}
                            onPageChange={onPageChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
    );
};
