import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Button, Form, Grid } from "semantic-ui-react";
import {
    EditComponent,
    EditProps as SharedEditProps,
    SaveDispatchProps,
    FormState
} from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import {
    OrganisationEditModel,
    AppState,
    OrganisationContactDataModel,
    CorporateOrganisationContact
} from "../model";
import { corporateOrConstructionBasePathSelector, organisationSelector } from "../selectors";
import { saveOrganisation } from "../actions";
import { NonPoliceContactsEditModal } from "./NonPoliceContactsEditModal";
import { OrganisationContactRow } from "./OrganisationContactRow";
import { toast } from "react-toastify";

export interface EditProps extends SharedEditProps<OrganisationEditModel> {
    open: boolean;
}

export interface DispatchProps extends SaveDispatchProps<OrganisationEditModel> {
    close: () => void;
}

interface EditState extends FormState<OrganisationEditModel> {
    contactsValid: Set<string>;
}

export class CorporateContactsEditForm extends EditComponent<OrganisationEditModel, EditProps, EditState> {

    constructor(props: EditProps & SaveDispatchProps<OrganisationEditModel>) {
        super(props);

        this.addPrimaryContact = this.addPrimaryContact.bind(this);
        this.removePrimaryContact = this.removePrimaryContact.bind(this);
        this.addCourseContact = this.addCourseContact.bind(this);
        this.removeCourseContact = this.removeCourseContact.bind(this);
        this.addFinanceContact = this.addFinanceContact.bind(this);
        this.removeFinanceContact = this.removeFinanceContact.bind(this);
        this.updateContactValue = this.updateContactValue.bind(this);
        this.fixContactsInvalid = this.fixContactsInvalid.bind(this);
        this.submit = this.submit.bind(this);
    }

    public submitting = false;

    public shouldComponentUpdate() {
        return !this.submitting;
    }

    public updateContactValue(contact: CorporateOrganisationContact, index: number, field: string, value: string, valid: boolean) {
        const validField = `${contact}.${index}.${field}`;
        const uniqueValidField = `${contact}.unique`;
        const newContactValid = new Set(this.state.contactsValid);

        if (valid && newContactValid.has(validField)) {
            newContactValid.delete(validField);
        }

        if (!valid && !newContactValid.has(validField)) {
            newContactValid.add(validField);
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Primary) {
            const newContacts = this.state.values.organisationContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates = (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({ values: { ...this.state.values, organisationContact: { ...this.state.values.organisationContact, contacts: newContacts } } });
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Course) {
            const newContacts = this.state.values.organisationCourseContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates = (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({
                values: { ...this.state.values, organisationCourseContact: { ...this.state.values.organisationCourseContact, contacts: newContacts } } });
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Finance) {
            const newContacts = this.state.values.organisationFinanceContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates =  (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({
                values: { ...this.state.values, organisationFinanceContact: { ...this.state.values.organisationFinanceContact, contacts: newContacts } } });
        }

        this.setState({ contactsValid: newContactValid });
    }

    public fixContactsInvalid(contact: CorporateOrganisationContact, indexRemoved: number, newContacts: OrganisationContactDataModel[]) {
        const newContactValid = new Set<string>();
        this.state.contactsValid.forEach(invalidField => {
            const parts = invalidField.split(".");

            if (+parts[0] === contact) {
                if (parts[1] !== "unique") {
                    if (+parts[1] < indexRemoved) {
                        newContactValid.add(invalidField);
                    }

                    if (+parts[1] > indexRemoved) {
                        const newInvalidField = `${parts[0]}.${+parts[1] - 1}.${parts[2]}`;
                        newContactValid.add(newInvalidField);
                    }
                } else {
                    const emailContactRepresentations = newContacts.map(c => c.email);
                    const hasDuplicates = (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length;

                    if (hasDuplicates) {
                        newContactValid.add(invalidField);
                    }
                }
            } else {
                newContactValid.add(invalidField);
            }
        });
        this.setState({ contactsValid: newContactValid });
    }

    public removePrimaryContact(index: number) {
        const newContacts = this.state.values.organisationContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Primary, index, newContacts);
    }

    public addPrimaryContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationContact.contacts",
            [ ...this.state.values.organisationContact?.contacts || [], { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public removeCourseContact(index: number) {
        const newContacts = this.state.values.organisationCourseContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationCourseContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Course, index, newContacts);
    }

    public addCourseContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationCourseContact.contacts",
            [ ...this.state.values.organisationCourseContact?.contacts || [], { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public removeFinanceContact(index: number) {
        const newContacts = this.state.values.organisationFinanceContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationFinanceContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Finance, index, newContacts);
    }

    public addFinanceContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationFinanceContact.contacts",
            [ ...this.state.values.organisationFinanceContact?.contacts || [], { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public render() {
        const { values, showErrors } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Primary Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addPrimaryContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationContact && values.organisationContact.contacts && values.organisationContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                contactType={CorporateOrganisationContact.Primary}
                                key={`primaryContact_${index}`}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removePrimaryContact}
                            />
                        )
                    )}
                </Grid>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Course Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addCourseContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationCourseContact && values.organisationCourseContact.contacts && values.organisationCourseContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                contactType={CorporateOrganisationContact.Course}
                                key={`courseContact_${index}`}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removeCourseContact}
                            />
                        )
                    )}
                </Grid>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Finance Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addFinanceContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationFinanceContact && values.organisationFinanceContact.contacts && values.organisationFinanceContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                contactType={CorporateOrganisationContact.Finance}
                                key={`financeContact_${index}`}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removeFinanceContact}
                            />
                        )
                    )}
                </Grid>
            </Form>
        );
    }

    public submit = () => {
        if (this.state.contactsValid && this.state.contactsValid.size !== 0) {
            toast.warning("Organisation cannot be edited as some contacts have duplicate email addresses");
            return;
        }

        this.submitting = true;
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };
}

function mapStateToProps(state: AppState) {
    return {
        model: organisationSelector(state) as OrganisationEditModel,
        open: state.router.pathname.endsWith("/edit"),
        basePath: corporateOrConstructionBasePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (organisation: OrganisationEditModel, basePath: string) => dispatch(saveOrganisation(organisation, basePath, "contacts")),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        model: propsFromState.model,
        open: propsFromState.open,
        save: (organisation: OrganisationEditModel) => propsFromDispatch.dispatchSave(organisation, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}/contacts`),
    };
}

export const NonPoliceContactsEdit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(NonPoliceContactsEditModal);
