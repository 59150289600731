import { TrainerWithAttributeModel } from "@common/crud/trainer/model";
import { TrainerApi } from "@common/crud/trainer/trainerApi";
import { Spinner } from "@common/global";
import * as React from "react";
import { Grid, Tab, Table } from "semantic-ui-react";
import { TrainerAttributeDefinitionDetailModel } from "../../model";

export interface TrainersTabProps {
    trainerAttributeDefinition: TrainerAttributeDefinitionDetailModel;
}

export const TrainersTab = ({ trainerAttributeDefinition }: TrainersTabProps) => {
    const [trainersWithAttribute, setTrainersWithAttribute] = React.useState<TrainerWithAttributeModel[]>(null);

    React.useEffect(() => {
        async function initialiseTrainersWithAttribute() {
            setTrainersWithAttribute(await new TrainerApi().getTrainersWithAttribute(trainerAttributeDefinition.id));
        }

        if (!trainersWithAttribute && trainerAttributeDefinition.id) {
            initialiseTrainersWithAttribute();
        }
    }, [trainerAttributeDefinition.id, trainersWithAttribute]);

    return (
        <Tab.Pane>
            <Grid>
                <Spinner active={!trainersWithAttribute}>
                    {trainersWithAttribute && !trainersWithAttribute.length ?
                        <p><br />No trainers currently have this attribute</p> :
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Full Name</Table.HeaderCell>
                                    <Table.HeaderCell>Attribute Held Since</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {trainersWithAttribute?.map(t => (
                                    <Table.Row key={t.trainer.id}>
                                        <Table.Cell>{t.trainer.fullName}</Table.Cell>
                                        <Table.Cell>{t.attributeHeldSince}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </Spinner>
            </Grid>
        </Tab.Pane>
    );
};
