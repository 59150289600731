import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import moment from "moment";
import { useState } from "react";
import { Label } from "semantic-ui-react";
import { EventInstanceCreateModel, EventInstanceEditModel } from "../model";
import { StartTimeDropDownWithCustom } from "./StartTimeDropDownWithCustom";
import {  createValidator } from "not-valid";
import { DurationPicker } from "@common/crud/common";

export interface SingleDayPropsForCreateProps {
    eventTypeId: string;
    eventDuration: moment.Duration;
    educationDuration: moment.Duration;
    theoryDuration: moment.Duration;
    practicalDuration: moment.Duration;
    ddStartTime: number;
    ddPracticalTime: number;
    startTime: moment.Duration;
    theoryStartTime: moment.Duration;
    practicalStartTime: moment.Duration;
    updateFieldsAffectedByStartTime: (time: moment.Duration) => void;
    registrationEndTime: moment.Duration;
    showOther: boolean;
    availableSuggestedTimes: { key: number; text: string; value: number }[];
    theoryAvailableSuggestedTimes: { key: number; text: string; value: number }[];
    practicalAvailableSuggestedTimes: { key: number; text: string; value: number }[];
    showErrors: boolean;
    updateProperty: (prop: keyof EventInstanceEditModel | keyof EventInstanceCreateModel) => (value: any, valid: boolean) => void;
    showTheoryAndPractical: boolean;
    showCombined: boolean;
    validationPredicate?: (v: string) => boolean;
    hasEducationDuration: boolean;
}

export const SingleDayPropsForCreate: React.FC<SingleDayPropsForCreateProps> = ({
    eventTypeId, eventDuration, educationDuration, ddStartTime, startTime, showTheoryAndPractical, showCombined, theoryDuration, theoryAvailableSuggestedTimes,
    theoryStartTime, practicalDuration, practicalAvailableSuggestedTimes, practicalStartTime,
    showOther, availableSuggestedTimes, showErrors, updateProperty, registrationEndTime, updateFieldsAffectedByStartTime,
    ddPracticalTime, validationPredicate, hasEducationDuration }) => {

    const [endDisplay, setEndDisplay] = useState<string>();
    const otherValidation = [createValidator<string>(validationPredicate, "Please enter time after the theory session")];

    React.useEffect(() => {
        if (eventTypeId === "") {
            setEndDisplay("");
        }
        else if (eventDuration && startTime && eventDuration.asMilliseconds() > 0) {
            let endTime = startTime.clone().add(eventDuration);
            if (endTime?.asHours() >= 24) {
                endTime = endTime.subtract(1, "day");
            }

            setEndDisplay(endTime.format(DateFormat.Time, { trim: false }));
        }
    }, [eventDuration, startTime, eventTypeId]);

    function updateRegistrationEndTime(time: string, valid: boolean) {
        if (time !== undefined) {
            const parsedTime: moment.Duration = moment.duration(time);
            updateProperty("registrationEndTime")(parsedTime, valid);
        }
    };

    function updateStartTimeProp(prop: keyof EventInstanceEditModel | keyof EventInstanceCreateModel) {
        const isValid = practicalStartTime?.isValid() && validationPredicate(practicalStartTime.toISOString());
        updateProperty("practicalStartTime")(practicalStartTime, isValid);
        return updateProperty (prop);
    }

    function updateEducationDuration(value: moment.Duration, valid: boolean) {
        updateProperty("educationDuration")(value, valid);
    }

    return (
        <>
            { !showTheoryAndPractical &&
                <StartTimeDropDownWithCustom
                    isEventStartTime
                    duration={eventDuration}
                    showErrors={showErrors}
                    updateFieldsAffectedByStartTime={updateFieldsAffectedByStartTime}
                    updateProperty={updateProperty}
                    label=""
                    availableSuggestedTimes={availableSuggestedTimes}
                    ddStartTime={ddStartTime}
                    startTime={startTime}
                    eventTypeId={eventTypeId}
                    showOther={showOther}
                    startTimeKeys={["startTime"]}
                    durationKeys={["eventDuration"]}
                />
            }
            { showTheoryAndPractical &&
                <>
                    {showCombined && <StartTimeDropDownWithCustom
                        isEventStartTime
                        duration={theoryDuration}
                        showErrors={showErrors}
                        updateFieldsAffectedByStartTime={updateFieldsAffectedByStartTime}
                        updateProperty={updateProperty}
                        label="Classroom"
                        availableSuggestedTimes={theoryAvailableSuggestedTimes}
                        ddStartTime={ddStartTime}
                        startTime={theoryStartTime}
                        eventTypeId={eventTypeId}
                        showOther={showOther}
                        startTimeKeys={["theoryStartTime", "practicalStartTime"]}
                        durationKeys={["theoryDuration", "practicalDuration"]}
                    />}
                    {!showCombined && (
                        <>
                            <StartTimeDropDownWithCustom
                                isEventStartTime
                                duration={theoryDuration}
                                showErrors={showErrors}
                                updateFieldsAffectedByStartTime={updateFieldsAffectedByStartTime}
                                updateProperty={updateStartTimeProp}
                                label="Theory"
                                availableSuggestedTimes={theoryAvailableSuggestedTimes}
                                ddStartTime={ddStartTime}
                                startTime={theoryStartTime}
                                eventTypeId={eventTypeId}
                                showOther={showOther}
                                startTimeKeys={["theoryStartTime"]}
                                durationKeys={["theoryDuration"]}
                            />
                            <StartTimeDropDownWithCustom
                                duration={practicalDuration}
                                showErrors={showErrors}
                                updateFieldsAffectedByStartTime={updateFieldsAffectedByStartTime}
                                updateProperty={updateProperty}
                                label="Practical"
                                availableSuggestedTimes={practicalAvailableSuggestedTimes}
                                startTime={practicalStartTime}
                                eventTypeId={eventTypeId}
                                showOther={showOther}
                                startTimeKeys={["practicalStartTime"]}
                                durationKeys={["practicalDuration"]}
                                ddStartTime={ddPracticalTime}
                                otherTimeValidation={otherValidation}
                            />
                        </>
                    )}
                </>
            }
            {hasEducationDuration && <DurationPicker
                label={"Education Duration"}
                eventDuration={educationDuration}
                showErrors={showErrors}
                onChange={updateEducationDuration}
            />}

            <Input.Time
                value={registrationEndTime ?
                    registrationEndTime.format(DateFormat.Time, { trim: false }) :
                    moment.duration().format(DateFormat.Time, { trim: false })}
                label="Registration End Time"
                required
                showErrors={showErrors}
                onChange={updateRegistrationEndTime}
                disabled
            />

            {endDisplay &&
                <Label id="endtime">
                    Event Ends at: {endDisplay}
                </Label>
            }
        </>
    );
};
