import { Grid, Form, DropdownProps, Button } from "semantic-ui-react";
import * as React from "react";
import { useState } from "react";
import { SearchOptions, ShowOrdersBy } from "../model";
import { useDispatch, useSelector } from "react-redux";
import { corporateEventTypeOptionsForWorkflowSelector } from "@common/crud/eventType/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { getDateInputFormat } from "@common/dateFormating";
import moment from "moment";
import { nameof } from "@common/helpers/nameof";
import { loadOrganisations } from "@common/crud/organisation";
import { corporateOrganisationOptionsSelectorWithAny } from "@common/crud/organisation/selectors";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface RebookEventInstancesFiltersProps {
    corporateOrganisationId?: string;
    searchOptions: SearchOptions;
    onOptionsChanged: (query: SearchOptions) => void;
}

export const RebookEventInstancesFilters: React.FC<RebookEventInstancesFiltersProps> = ({ corporateOrganisationId, searchOptions, onOptionsChanged }) => {
    const [stateOptions, setStateOptions] = useState<SearchOptions>(searchOptions);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!corporateOrganisationId) {
            dispatch(loadOrganisations());
        }
    }, [corporateOrganisationId, dispatch]);

    const corporateOrganisationOptions = useSelector(corporateOrganisationOptionsSelectorWithAny);
    const eventTypeOptions = useSelector(corporateEventTypeOptionsForWorkflowSelector(WorkflowTypeEnum.CPC));

    const dateInputFormat = React.useMemo(() => getDateInputFormat(), []);

    const onInputChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            if (moment.isMoment(value) && !value.isValid) {
                return;
            }

            const prevValue = stateOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
                onOptionsChanged(options);
            }
        };
    }, [onOptionsChanged, stateOptions]);

    const onMultiDropdownChange = React.useCallback((prop: keyof SearchOptions) => (_: any, { value }: DropdownProps) => {
        const options = { ...stateOptions, [prop]: value };
        // deselect all eventTypes if "Any Product" is selected
        if (prop === nameof<SearchOptions>("eventTypeIds") && Array.isArray(value) && value.includes("")) {
            options.eventTypeIds = [];
        }
        if (prop === nameof<SearchOptions>("corporateOrganisationIds") && Array.isArray(value) && value.includes("")) {
            options.corporateOrganisationIds = [];
        }
        setStateOptions(options);
        onOptionsChanged(options);
    }, [onOptionsChanged, stateOptions]);

    const filtersClearable = React.useMemo(() => searchOptions?.eventTypeIds?.length > 0 || searchOptions?.toDate || searchOptions?.fromDate,
        [searchOptions?.eventTypeIds?.length, searchOptions?.fromDate, searchOptions?.toDate]);

    const onClearFilters = React.useCallback(() => {
        const clearedSearchOptions = {
            ...ObjectKeys(searchOptions)
                .reduce((prevSearchOptions: SearchOptions, key: keyof SearchOptions) => {
                    const defaultValue: string[] | string | boolean =
                        Array.isArray(searchOptions[key]) ? [] : undefined;

                    return {
                        ...prevSearchOptions,
                        [key]: defaultValue
                    };
                }, {}), filtersCleared: true
        };
        clearedSearchOptions.showOrdersBy = ShowOrdersBy.EventInstance;

        setStateOptions(clearedSearchOptions);
        onOptionsChanged(clearedSearchOptions);
    }, [onOptionsChanged, searchOptions]);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <div className="filters-actions">
                <Button className="link-button filter-button" content={showFiltersDisplay} onClick={onToggleFiltersVisibility} />
                <Button className="link-button filter-button" content="Clear Filters" onClick={onClearFilters} disabled={!filtersClearable} />
            </div>

            {filtersVisible &&
                <Grid className="course-filter-grid full-width" stackable>
                    <Grid.Row>
                        {!corporateOrganisationId && (
                            <Grid.Column width={8}>
                                <div className="filters">
                                    <Form.Dropdown
                                        label="Organisations"
                                        floating
                                        multiple
                                        selection
                                        placeholder="Organisations"
                                        value={stateOptions.corporateOrganisationIds ?? []}
                                        options={corporateOrganisationOptions}
                                        onChange={onMultiDropdownChange("corporateOrganisationIds")}
                                        search
                                    />
                                </div>
                            </Grid.Column>
                        )}
                        <Grid.Column width={corporateOrganisationId ? 16 : 8}>
                            <div className="filters">
                                <Form.Dropdown
                                    label="Product"
                                    floating
                                    multiple
                                    selection
                                    placeholder="Product"
                                    value={stateOptions.eventTypeIds ?? []}
                                    options={eventTypeOptions}
                                    onChange={onMultiDropdownChange("eventTypeIds")}
                                    search
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <div className="filters">
                                <MuiDateField
                                    label="Date from"
                                    placeholder={dateInputFormat}
                                    value={stateOptions?.fromDate}
                                    onChange={onInputChanged("fromDate")}
                                    key={"fromDate-" + stateOptions?.fromDate?.toString()}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="filters">
                                <MuiDateField
                                    label="Date to"
                                    placeholder={dateInputFormat}
                                    value={stateOptions?.toDate}
                                    onChange={onInputChanged("toDate")}
                                    key={"toDate-" + stateOptions?.toDate?.toString()}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </>
    );
};
