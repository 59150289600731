import * as React from "react";
import { connect } from "react-redux";
import { AuthState, User, AuthorizationSetting, isAuthorized } from "reauthorize";

export interface IfNotAuthorizedStateProps {
    currentUser: User;
}

export interface IfNotAuthorizedProps {
    authorize: AuthorizationSetting;
    children?: React.ReactNode;
}

export const IfNotAuthorizedInternal: React.SFC<IfNotAuthorizedStateProps & IfNotAuthorizedProps> = ({ authorize, currentUser, children }) =>
    isAuthorized(currentUser, authorize) === "Unauthorized"
    && <>{children}</>;

const mapStateToProps = ({ currentUser }: AuthState): IfNotAuthorizedStateProps => ({ currentUser });

export const IfNotAuthorized = connect(mapStateToProps)(IfNotAuthorizedInternal);
