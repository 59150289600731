import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { push } from "redux-little-router";

interface RedirectProps {
    href: string;
}

interface DispatchProps {
    push: (href: string) => void;
}

class RedirectInternal extends React.Component<RedirectProps & DispatchProps> {
    public componentDidMount() {
        this.props.push(this.props.href);
    }

    public render(): React.ReactNode {
        return null;
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: href => dispatch(push(href))
    };
}

export const Redirect = connect(null, mapDispatchToProps)(RedirectInternal);
