import * as React from "react";
import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";

import { Modal, Button, Confirm } from "semantic-ui-react";

import { useEffect } from "react";
import { Authorize } from "reauthorize";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";

import { SpecialRequirementsCheckboxes } from "./SpecialRequirementsCheckboxes";
import { SpecialRequirementsDisplay } from "./SpecialRequirementsDisplay";
import {
    ClassroomAssignableAttribute,
    hearingImpairmentAttributes,
    SpecialRequirementsAttribute
} from "@common/crud/eventInstance/model";
import { VenueAttribute } from "@common/crud/venue/model";

export interface SpecialRequirementsLabelWithEditProps {
    specialRequirements: SpecialRequirements;
    updateSpecialRequirements: (requirements: SpecialRequirements, sendComms: boolean) => void;
    isDigitalCourse: boolean;
}

export const SpecialRequirementsLabelWithEdit: React.FC<SpecialRequirementsLabelWithEditProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [specialRequirements, setSpecialRequirements] = React.useState(props.specialRequirements);
    const { isDigitalCourse } = props;
    const hearingImpairmentSubAttributes = [
        ClassroomAssignableAttribute.BslInterpreter,
        ClassroomAssignableAttribute.TextToSpeechReporter,
        ClassroomAssignableAttribute.HearingLoop,
        SpecialRequirementsAttribute.NeedSubtitles
    ];

    const [sendCommsModalOpen, setSendCommsModalOpen] = React.useState(false);

    useEffect(() => {
        setSpecialRequirements(props.specialRequirements);
    }, [props.specialRequirements]);

    const isSubOptionsSelectedIfHearingImpairmentSelected = React.useCallback(() => {
        return !specialRequirements?.hasHearingImpairment ||
            (specialRequirements?.hasHearingImpairment &&
                (specialRequirements.eventAttributes.some(attribute => hearingImpairmentSubAttributes.includes(attribute)) ||
                    !!specialRequirements?.otherHearingRequirements?.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[specialRequirements]);

    const onUpdateSpecialRequirements = React.useCallback((requirements: SpecialRequirements) => {
        setSpecialRequirements(requirements);
    }, []);

    const onOpenClick = React.useCallback(() => setOpen(!open), [open]);

    const resetSpecialRequirements = React.useCallback(() => {
        setSpecialRequirements(props.specialRequirements);
        setOpen(false);
    }, [props.specialRequirements]);

    const sendUpdateRequirements = React.useCallback((sendComms: boolean) => {
        if (
            specialRequirements.hasHearingImpairment &&
            !specialRequirements.eventAttributes.some(a => hearingImpairmentAttributes.includes(a)) &&
            !specialRequirements.otherHearingRequirements
        ) {
            props.updateSpecialRequirements({ ...specialRequirements, hasHearingImpairment: false }, sendComms);
        } else {
            props.updateSpecialRequirements(specialRequirements, sendComms);
        }
        setSendCommsModalOpen(false);
        setOpen(false);
    }, [props, specialRequirements]);

    const onUpdateSpecialRequirementsClicked = React.useCallback(() => {
        if (specialRequirements.eventAttributes.includes(SpecialRequirementsAttribute.OwnInterpreter)
        || specialRequirements.eventAttributes.includes(SpecialRequirementsAttribute.NeedToBreastFeed)
        || specialRequirements.venueAttributes.includes(VenueAttribute.BreastfeedingRoom)
        || specialRequirements.eventAttributes.includes(SpecialRequirementsAttribute.Translator)
        || specialRequirements.eventAttributes.includes(SpecialRequirementsAttribute.NeedToPray)) {
            setSendCommsModalOpen(true);
            return;
        }
        sendUpdateRequirements(false);
    }, [specialRequirements, sendUpdateRequirements]);

    const cancelButtonProps = { content: "No", className: "cancel-action" };
    const confirmButtonProps = { content: "Yes", className: "green" };

    const sendWithoutComms = React.useCallback(() => sendUpdateRequirements(false), [sendUpdateRequirements]);
    const sendWithComms = React.useCallback(() => sendUpdateRequirements(true), [sendUpdateRequirements]);

    return (
        <div className="label-and-value">
            <span className="label fixed-width">
                Special Requirements
            </span>
            <Authorize authorize={[...EventManagementAdminRolesAndTrainers]}>
                <a
                    className="show-button"
                    onClick={onOpenClick}
                >Update requirements
                </a>
            </Authorize>
            <div className="value">
                <SpecialRequirementsDisplay
                    isDigitalCourse={isDigitalCourse}
                    specialRequirements={props.specialRequirements}
                    showMessageIfNoSpecialRequirements={true}
                />
            </div>

            <Modal open={open}>
                <Modal.Header>
                    Update Special Requirements
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <p>Please select attendee special requirements?</p>
                        <SpecialRequirementsCheckboxes
                            isDigitalCourse={isDigitalCourse}
                            includeOther={true}
                            onUpdate={onUpdateSpecialRequirements}
                            specialRequirements={specialRequirements}
                            translated={false}
                            hasSpecialRequirements={true}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={resetSpecialRequirements}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onUpdateSpecialRequirementsClicked}
                        icon="checkmark"
                        content="Save"
                        disabled={!isSubOptionsSelectedIfHearingImpairmentSelected()}
                    />
                </Modal.Actions>
            </Modal>

            <Confirm
                open={sendCommsModalOpen}
                header={"Send special requirements email"}
                content={"Do you want to automatically send the special requirements guidance email for the requirements you have selected?"}
                onConfirm={sendWithComms}
                cancelButton={cancelButtonProps}
                confirmButton={confirmButtonProps}
                onCancel={sendWithoutComms}
            />
        </div>
    );
};
