import { appSettingsSelector } from "@common/appSettings/selectors";
import { Modal } from "@common/components";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { AppSettings } from "@common/appSettings/model";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import "./WelshWarningModal.scss";

interface WelshWarningModalProps {
    open: boolean;
    dismissModal: () => void;
    goBack: () => void;
    abortModal: () => void;
}

export const WelshWarningModal: React.FC<WelshWarningModalProps> = ({ open, dismissModal, goBack, abortModal }) => {
    const appSettings: AppSettings = useSelector(appSettingsSelector);
    const text = appSettings?.bookingAppSettings?.welshCourseSelectionAlert;
    if (text === null || text === "") {
        abortModal();
    }
    return (
        <Modal
            open={open}
            size="small"
        >
            <Modal.Header>Gwybodaeth bwysig am eich cwrs/Important information about your course</Modal.Header>
            <Modal.Content>
                <HtmlRenderer value={text} />
            </Modal.Content>
            <Modal.Actions>
                <Grid columns={16}>
                    <Grid.Column textAlign="left" width={10}>
                        <Button onClick={goBack}>Choose Another Course/Dewis Cwrs Arall</Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={6}>
                        <Button onClick={dismissModal}>Parhewch</Button>
                    </Grid.Column>
                </Grid>
            </Modal.Actions>
        </Modal>);
};
