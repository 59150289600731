import * as React from "react";
import { AttendeeListModel } from "@common/crud/attendee";
import { Checkbox, Grid } from "semantic-ui-react";

import { CurrentUser, PoliceRole } from "@common/auth/model";
import { CurrentUserContext } from "@common/current-user-context";

import { RegisterAttendee } from "./RegisterAttendee";
import { SpecialRequirementsDisplay } from "@common/specialRequirements/SpecialRequirementsDisplay";
import { AttendeeCarDetails } from "@common/crud/attendee/components/details/AttendeeCarDetails";
import { useRegisterContext } from "./register-context";

interface RegisterAttendeeProps {
    attendee: AttendeeListModel;
    didAttend: boolean;
    onAttendanceUpdated: (id: string, value: boolean) => void;
}

const AttendanceRegisterRow: React.FC<RegisterAttendeeProps> = ({ attendee, didAttend, onAttendanceUpdated }) => {
    const currentUser: CurrentUser = React.useContext(CurrentUserContext);
    const { eventInstanceOptions: { isDigitalEventInstance } } = useRegisterContext();

    function handleAttendedChange(_: any, data: any) {
        onAttendanceUpdated(attendee.id, data.checked);
    }

    return (
        <>
            <Grid.Row>
                <RegisterAttendee attendee={attendee} blueName />
                <Grid.Column width={5} textAlign="right">
                    <Checkbox
                        className="right-aligned-center"
                        checked={didAttend}
                        disabled={currentUser?.roles.includes(PoliceRole)}
                        onChange={handleAttendedChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="no-padding">
                <Grid.Column width="16" className="attendee-requirements">
                    <SpecialRequirementsDisplay
                        isDigitalCourse={isDigitalEventInstance}
                        specialRequirements={attendee.specialRequirements || {} as any}
                        showMessageIfNoSpecialRequirements={false}
                    />
                    <AttendeeCarDetails attendee={attendee} />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

export { AttendanceRegisterRow };
