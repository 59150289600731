import moment from "moment";

export enum PoliceNotificationType {
    None,
    MiscNotes,
    Compliments,
    Complaints,
    VenueIncidents,
    ESG
};

export const PoliceNotificationTypes = {
    [PoliceNotificationType.None]: "None",
    [PoliceNotificationType.MiscNotes]: "Misc. Notes",
    [PoliceNotificationType.Compliments]: "Compliments",
    [PoliceNotificationType.Complaints]: "Complaints",
    [PoliceNotificationType.VenueIncidents]: "Venue Incidents",
    [PoliceNotificationType.ESG]: "ESG"
};

export interface PoliceNotificationListModel {
    readonly id: string;
    readonly organisationId: string;
    readonly createdByEmail: string;
    readonly notificationType: PoliceNotificationType;
    readonly description: string;
    readonly organisationName: string;
    readonly dateCreated: moment.Moment;
}

export interface PoliceNotificationCreateEditModel {
    id?: string;
    organisationId: string;
    notificationType: PoliceNotificationType;
    description: string;
    organisationName: string;
}

export interface PoliceNotificationSearchOptions {
    organisationId?: string;
}
