import * as React from "react";
import { Form, Message, DropdownProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { EventInstance } from "@common/crud/eventInstance";
import { ErrorMessage, Field, useFormikContext, FieldProps } from "formik";
import { AttendeeCarType } from "@common/crud/attendee/model";
import { MissingBookingProps, AttendeeCarTypeOptions } from "../models";
import { BookingDetailsField } from "./BookingDetailsField";
import { BookingDetailsSpecialCarTypeDetailsField } from "./BookingDetailsSpecialCarTypeDetailsField";
import { useEventTypeBookingAppTexts } from "@common/hooks/useEventTypeBookingAppTexts";
import { useSelector } from "react-redux";
import { seatSelector } from "../selectors";

interface CarTypeFieldsProps {
    eventInstance: EventInstance;
    specialIsOnlyOption: boolean;
    carFlag: boolean;
 }

export const BookingDetailsCarTypeFields: React.FunctionComponent<CarTypeFieldsProps> = ({ eventInstance, specialIsOnlyOption, carFlag }) => {
    const [t] = useTranslation("BookingDetails");
    const { errors, touched, values, setFieldValue } = useFormikContext<MissingBookingProps>();
    const isError = errors.carType && touched.carType;
    const seat = useSelector(seatSelector);
    const filteredCarTypeOptions = AttendeeCarTypeOptions.filter(carType => carType.value ==="Special" ||
        (carType.value === "Automatic" && seat?.allowAutomatic) || (carType.value === "Manual" && seat?.allowManual));

    const getCustomizableTextOrDefaultForKey = useEventTypeBookingAppTexts(eventInstance, "BookingDetails", carFlag);
    const carTypeNote = getCustomizableTextOrDefaultForKey("CAR_TYPE_NOTE");
    const validate = React.useCallback((value: string) => {
        if (value) {
            return null;
        }

        return t("CAR_TYPE_REQUIRED");
    }, []);

    React.useEffect(() => {
        if (carFlag === false && values.carType) {
            setFieldValue("carType", null);
        }

    },[carFlag, setFieldValue]);

    if (!carFlag) {
        return null;
    }

    return (
        <>
            <BookingDetailsField>
                <Form error={isError}>
                    <Field name="carType" validate={validate}>
                        {({
                            field: { onChange, onBlur, ...fieldProps }
                        }: FieldProps<AttendeeCarType>) => {
                            const wrappedOnChange = React.useCallback((event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                onChange({ target: { name: "carType", value: data.value } });
                            }, [onChange]);

                            const wrappedOnBlur = React.useCallback(() => {
                                onBlur({ target: { name: "carType" } });
                            }, [onBlur]);

                            return (
                                <Form.Select
                                    {...fieldProps}
                                    onChange={wrappedOnChange}
                                    onBlur={wrappedOnBlur}
                                    disabled={specialIsOnlyOption}
                                    type="text"
                                    value={values.carType}
                                    options={filteredCarTypeOptions}
                                    required
                                    label={<label>{t("CAR_TYPE")}</label>}
                                    className="field-with-note"
                                    placeholder={t("CAR_TYPE")}
                                    error={isError}
                                />
                            );
                        }}
                    </Field>
                    <p className="field-note">{carTypeNote}</p>
                    <ErrorMessage
                        name="carType"
                        className="error-margin-bottom"
                    >
                        {(msg) => <Message error header={msg} />}
                    </ErrorMessage>
                </Form>
                {
                    values.carType === "Special" &&
                    <BookingDetailsSpecialCarTypeDetailsField />
                }
            </BookingDetailsField>
        </>
    );
};
