import * as React from "react";
import { Grid, Form, Button, InputOnChangeData } from "semantic-ui-react";

import { SearchOptions } from "../model";

interface FilterProps {
    searchOptions: SearchOptions;
    onOptionsChanged: (options: SearchOptions) => void;
}

interface FilterState {
    name: string;
}

export class Filters extends React.Component<FilterProps, FilterState> {
    public state = { name: this.props.searchOptions.name || "" };

    public render() {
        return (
            <Grid.Row>
                <Grid.Column className="ui form">
                    <Form onSubmit={this.onSubmit}>
                        <Form.Group widths="equal">
                            <Form.Input
                                action={<Button icon="search" />}
                                placeholder="Name"
                                value={this.state.name}
                                onChange={this.onNameInputChanged}
                            />
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    }

    private onPropertyChanged<P extends keyof SearchOptions>(prop: P) {
        return (value: SearchOptions[P]) => {
            const { searchOptions } = this.props;
            const prevValue = searchOptions[prop];

            if (prevValue !== value) {
                const options = { ...searchOptions, [prop]: value };
                this.props.onOptionsChanged(options);
            }
        };
    }

    private onNameInputChanged = (event: any, data: InputOnChangeData) => this.setState({ name: data.value });

    private onSubmit = () => this.onPropertyChanged("name")(this.state.name);
}
