import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { FieldAndDate } from "@common/crud/common/FieldAndDate";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { toast } from "@common/toasts";
import moment from "moment";
import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import { CreateFieldPickerProps, ValueWithEffectiveDate } from "../model";
import { NumberValueAndDateInput } from "./details/ValueAndDateInput";

interface EditFieldProps {
    allFields: ValueWithEffectiveDate<any>[];
    onChange: (value: ValueWithEffectiveDate<any>[], valid: boolean) => void;
    CreateFieldPicker: (props: CreateFieldPickerProps<any>) => JSX.Element;
    valueView?: (value: any) => (string | JSX.Element);
    showErrors: boolean;
    fieldName: string;
    fieldIsDuration?: boolean;
    horizontalDisplayTitle?: string;
    allowEmpty?: boolean;
}

export const EditFieldWithEffectiveDate: React.FC<EditFieldProps> =
({ allFields, onChange, showErrors, fieldName, CreateFieldPicker, fieldIsDuration, horizontalDisplayTitle, valueView, allowEmpty }) => {
    const [fields, setFields] = React.useState<ValueWithEffectiveDate<any>[]>(allFields ?? []);

    function addField(field: any, date: moment.Moment, valid: boolean) {
        if (fields.find(d => d.effectiveDate.isSame(date, "day"))) {
            toast.warning(`You cannot have two instances of ${fieldName} with the same effective date, please remove the existing one to continue.`);
            return;
        }

        if (!allowEmpty && isNullOrUndefined(field)) {
            toast.warning(`${fieldName} cannot be empty.`);
            return;
        }

        if (!fieldIsDuration && (isNaN(field) || field <= 0)) {
            toast.warning(`${fieldName} must be a number greater than 0.`);
            return;
        }

        if (!valid)
        {
            toast.warning(`${fieldName} must contain a valid value.`);
            return;
        }

        setFields([...fields, { value: field || 0, effectiveDate: date }]);
    }

    function removeFields(date: moment.Moment) {
        return () => {
            event.preventDefault();
            setFields([...fields.filter(d => d.effectiveDate !== date)]);
        };
    }

    const validateEditionFields = React.useMemo(() => (fields?.length ?? 0) === 0, [fields?.length]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => onChange(fields, true), [fields]);
    const defaultFormat = (value: any) => value.toString();
    const formatValue = valueView ?? defaultFormat;

    return (
        <>
            <Grid>
                {horizontalDisplayTitle && <Grid.Row><Grid.Column><h4>{horizontalDisplayTitle}</h4></Grid.Column></Grid.Row>}
                <Grid.Row>
                    <Grid.Column>
                        {horizontalDisplayTitle?
                            <NumberValueAndDateInput
                                onChange={addField}
                                showErrors={validateEditionFields && showErrors}
                                CreateFieldPicker={CreateFieldPicker}
                            />:<FieldAndDate
                                showErrors={validateEditionFields && showErrors}
                                onChange={addField}
                                fieldName={fieldName}
                                fieldIsDuration={fieldIsDuration}
                                CreateFieldPicker={CreateFieldPicker}
                            /> }
                    </Grid.Column>
                </Grid.Row>
                {fields?.sort((a, b) => a.effectiveDate.diff(b.effectiveDate)).map((d, i) => (
                    <Grid.Row key={i}>
                        <Grid.Column width={6}>{fieldName} from {d.effectiveDate.format(DateFormat.DayMonthYear)}</Grid.Column>
                        <Grid.Column width={6}>{formatValue(d.value)}</Grid.Column>
                        <Grid.Column width={4}><Button onClick={removeFields(d.effectiveDate)} >Remove</Button></Grid.Column>
                    </Grid.Row>
                ))}
                { (showErrors && validateEditionFields)
                    && <div className="ui red pointing above basic label"><p>At least one element is required</p></div> }
            </Grid>
        </>
    );
};
