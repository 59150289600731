import React, { RefObject, forwardRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import classes from "./IconButton.module.scss";
import { mergeClasses } from "src/CorporateBooking.App/src/helpers";
import { IconColor, IconType } from "@common/components/BaseIcon";
import { Icon } from "./Icon";

export interface IconButtonProps extends AriaButtonProps<"button"> {
    icon: IconType;
    color?: IconColor;
    width?: number;
    height?: number;
    className?: string;
}

export const IconButton = forwardRef<SVGSVGElement, IconButtonProps>((props, ref) => {
    const { icon, color = "primary", width, height, className, isDisabled } = props;

    const { buttonProps } = useButton({
        ...props,
        elementType: "svg"
    }, ref as RefObject<SVGSVGElement>);

    const classNames = mergeClasses(classes.iconButton, classes[color], className, isDisabled? classes.disabled : "");

    return <Icon {...buttonProps} type={icon} color={color} width={width} height={height} className={classNames} ref={ref} />;
});
