import { createSelector } from "reselect";
import { createBasePathSelector } from "@common/redux-helpers";
import { AppState, BusinessLineType, CompanyTypeEnum, Organisation, OrganisationState } from "./model";
import { DsaAreaListItem } from "@common/dsa";
import { AlaskaNudgeTask } from "../alaskaNudgeTask";
import { WorkflowTypeEnum } from "../eventType/model";
import { CustomerStatusEnum } from "../alaskaNudgeTask/model";
import { BasicOrganisationOption, OrganisationOption } from "../eventInstance/model";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.organisationId;
};

export type SelectorState = AppState;

export const organisationsSelector = (state: AppState) => state.organisations ?? [];
export const relatedOrganisationOptionsSelector = (state: AppState) => {
    return state.relatedOrganisationsOptions?.map(mapToOrganisationOption) ?? [];
};

export const relatedOrganisationOptionsSelectorWithNone = (state: AppState) => {
    return [ { text: "None", value: "" }, ...state.relatedOrganisationsOptions?.map(mapToOrganisationOption) ?? [] ];
};

export const basicRelatedOrganisationOptionsSelectorWithNone = (state: AppState) => {
    return [ { text: "None", value: "" }, ...state.relatedOrganisationsOptions?.map(mapToBasicOrganisationOption) ?? [] ];
};

export const dsaAreasSelector = (state: AppState) => state.dsaAreas;

export const policeOrganisationsSelector = createSelector(
    organisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.filter(c => c.businessLineType === BusinessLineType.Police) || [];
    }
);

export const courtOrganisationsSelector = createSelector(
    organisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.filter(c => c.businessLineType === BusinessLineType.Court) || [];
    }
);

export const corporateOrganisationsSelector = createSelector(
    organisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.filter(c => c.businessLineType === BusinessLineType.Corporate) || [];
    }
);

export const constructionOrganisationsSelector = createSelector(
    organisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.filter(c => c.businessLineType === BusinessLineType.Construction) || [];
    }
);

export const corporateOrganisationsCompanyTypeFilteredSelector = (isBusinessDriverAdmin: boolean, isTtcCorporateAdmin: boolean) => {
    return createSelector(
        corporateOrganisationsSelector,
        (organisations: Organisation[]) => {
            const companyTypesToInclude: CompanyTypeEnum[] = [];
            if (isBusinessDriverAdmin) {
                companyTypesToInclude.push(CompanyTypeEnum.BusinessDriver);
            }
            if (isTtcCorporateAdmin) {
                companyTypesToInclude.push(CompanyTypeEnum.TTC, CompanyTypeEnum.LicenceBureau);
            }
            return organisations
                .filter(o => o.companyType.some(ct => companyTypesToInclude.includes(ct)));
        }
    );
};

export const courtOrganisationSelector = createSelector(
    courtOrganisationsSelector,
    routeIdSelector,
    (organisations: Organisation[], id: string) => {
        return organisations.filter(c => c.id === id)[0] || {} as any;
    }
);

export const courtOrganisationOptionsSelector = createSelector(
    courtOrganisationsSelector,
    (organisations: Organisation[]) => [
        { text: "Select", value: undefined, key: undefined },
        ...organisations?.map(org => ({ text: org.name, value: org.id, key: org.id })) || []
    ]
);

export const unexpiredOrganisationsSelector = createSelector(
    policeOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(v => v.isExpired === false) ?? []
);

export const unexpiredPoliceDorsOrganisationsSelector = createSelector(
    policeOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(v => v.isExpired === false && !v.managementInformationOnly) ?? []
);

export const unexpiredCorporateOrganisationsSelector = createSelector(
    corporateOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(v => v.isExpired === false) ?? []
);

export const unexpiredConstructionOrganisationsSelector = createSelector(
    constructionOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(v => v.isExpired === false) ?? []
);

export const policeOrganisationByDorsId = (dorsId: number) => {
    return createSelector(
        policeOrganisationsSelector,
        (organisations: Organisation[]) => {
            return organisations?.find(org => dorsId && org.dorsId === dorsId);
        }
    );
};

const emptyOrganisation: Organisation = {
    id: undefined,
    reminderType: undefined,
    businessLineType: undefined,
    corporateBusinessLineType: undefined
};

export const organisationSelector = createSelector(
    organisationsSelector,
    routeIdSelector,
    (organisations: Organisation[], id: string) => {
        return organisations.filter(c => c.id === id)[0] || emptyOrganisation;
    }
);

export const policeBasePathSelector = createBasePathSelector("police-and-court-event-management/police");
export const courtsBasePathSelector = createBasePathSelector("police-and-court-event-management/courts");
export const corporateOrConstructionBasePathSelector = createBasePathSelector("organisations");

export const policeDorsOrganisationOptionsSelector = createSelector(
    policeOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(org => !org.managementInformationOnly)
        .map(org => ({ text: org.name, value: org.dorsId || "", key: org.id }))
);

export const unexpiredPoliceDorsOrganisationOptionsSelector = createSelector(
    unexpiredOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(org => !org.managementInformationOnly)
        .map(org => ({ text: org.name, value: org.dorsId || "", key: org.id }))
);

export const policeDorsOrganisationOptionsSelectorWithAny = createSelector(
    policeOrganisationsSelector,
    (organisations: Organisation[]) =>
        [ { text: "Any Police Force", value: "" },
            ...(organisations || []).filter(org => !org.managementInformationOnly).map(org => ({ text: org.name, value: org.dorsId || "", key: org.id })) ]
);

export const policeOrganisationOptionsSelector = createSelector(
    unexpiredOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.map(org => ({ text: org.name, value: org.id || "", key: org.id }))
);

export const corporateOrganisationOptionsSelector = createSelector(
    unexpiredCorporateOrganisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.map(mapToOrganisationOption);
    }
);

export const constructionOrganisationOptionsSelector = createSelector(
    unexpiredConstructionOrganisationsSelector,
    (organisations: Organisation[]) => {
        return organisations?.map(mapToOrganisationOption);
    }
);

export const corporateOrganisationOptionsSelectorWithAny = createSelector(
    unexpiredCorporateOrganisationsSelector,
    (organisations: Organisation[]) => [ { text: "Any Organisation", value: "", open: false },
        ...(organisations || []).map(org => ({ text: org.name, value: org.id || "", key: org.id, open: org.corporateOrganisationData?.openCourse ?? false })) ]
);

export const constructionOrganisationOptionsSelectorWithAny = createSelector(
    unexpiredConstructionOrganisationsSelector,
    (organisations: Organisation[]) => [ { text: "Any Organisation", value: "", open: false },
        ...(organisations || []).map(org => ({ text: org.name, value: org.id || "", key: org.id, open: org.corporateOrganisationData?.openCourse ?? false })) ]
);

export const corporateOrganisationOptionsSelectorForWorkflow = (workflow?: WorkflowTypeEnum) => createSelector(
    unexpiredCorporateOrganisationsSelector,
    (organisations: Organisation[]) => {
        organisations = workflow === WorkflowTypeEnum.BusinessDriver
            ? organisations.filter(org => org.companyType?.includes(CompanyTypeEnum.BusinessDriver) || false)
            : organisations = organisations.filter(o => (o.companyType?.includes(CompanyTypeEnum.LicenceBureau) || false)
                || (o.companyType?.includes(CompanyTypeEnum.TTC) || false)) || [];
        return (organisations.filter(o => o.customerStatus === CustomerStatusEnum.Customer) || [])
            .map(org => ({ text: org.name, value: org.id || "", key: org.id, open: org.corporateOrganisationData?.openCourse ?? false }));
    }
);

export const corporateOrganisationWithPendingNoteOptionsSelector = (orgsWithPendingNotesTasks: AlaskaNudgeTask[]) => createSelector(
    unexpiredCorporateOrganisationsSelector,
    (organisations: Organisation[]) => [ { text: "Any Organisation", value: "" }, ...organisations
        .filter(o => orgsWithPendingNotesTasks.some(t => t.corporateOrganisationId === o.id))
        .sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" }))
        .map(org => ({ text: org.name, value: org.id || "", key: org.id, open: org.corporateOrganisationData?.openCourse ?? false }))]
);

export const nonManagementInfoPoliceOrganisationOptionsSelector = createSelector(
    unexpiredOrganisationsSelector,
    (organisations: Organisation[]) => organisations?.filter(org => !org.managementInformationOnly)
        ?.map(org => ({ text: org.name, value: org.id || "", key: org.id }))
);

export const nonManagementInfoPoliceOrganisationOptionsSelectorWithAny = (state: OrganisationState & AppState) => {
    const options = nonManagementInfoPoliceOrganisationOptionsSelector(state) || [];

    return [
        { text: "Any Police Force", value: "" },
        ...options
    ];
};

export const policeForceOptionsSelector = (state: OrganisationState & AppState) => policeDorsOrganisationOptionsSelector(state) || [];

export const policeForceOptionsSelectorWithAny = (state: OrganisationState & AppState) => policeDorsOrganisationOptionsSelectorWithAny(state) || [];

export const dsaAreaOptionsSelectorWithAny = createSelector(
    dsaAreasSelector,
    (dsaAreaListItems: DsaAreaListItem[]) => {
        return [
            { text: "Any Area", value: "" },
            ...(dsaAreaListItems ?? []).map(et => ({ text: et.description, value: et.id, key: et.id }))
        ];
    }
);

function mapToOrganisationOption(org: Organisation): OrganisationOption {
    return {
        ...org,
        text: org.name ?? "",
        value: org.id || "",
        key: org.id,
        open: org.corporateOrganisationData?.openCourse ?? false,
        customerStatus: org.customerStatus,
        companyType: org.companyType,
        hasCorporateOrganisationSpecificDigitalDetails: org.corporateOrganisationData?.hasCorporateOrganisationSpecificDigitalDetails || false,
        hasCorporateOrganisationSpecificPurchaseOrderDrawdowns: org.corporateOrganisationData?.hasCorporateOrganisationSpecificPurchaseOrderDrawdowns || false
    };
}

function mapToBasicOrganisationOption(org: Organisation): BasicOrganisationOption {
    return {
        text: org.name ?? "",
        value: org.id || "",
        key: org.id,
    };
}

