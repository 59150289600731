import React from "react";
import { Input } from "@neworbit/simpleui-input";
import { DropdownItemProps } from "semantic-ui-react";
import { PurchaseOrder } from "../model";
import moment from "moment";
import { numberFormatter } from "@common/helpers/number-format";

export interface PurchaseOrderDropdownProps {
    existingPurchaseOrder?: PurchaseOrder;
    purchaseOrders?: PurchaseOrder[];
    onChange: (value: string, valid: boolean) => void;
}

export const PurchaseOrderDropdown: React.FC<PurchaseOrderDropdownProps> = ({ existingPurchaseOrder, purchaseOrders, onChange }) => {
    const getPurchaseOrderOptions = (): DropdownItemProps[] => {
        return [
            { key: "not-set", text: "Not Set", value: "Not Set" },
            ...purchaseOrders.map((po) => {
                const dropdownOption = {
                    key: po.id,
                    text: (
                        <div>
                            No. {po.number} - added {moment(po.dateCreated).format("DD/MM/YYYY")} - £{numberFormatter(po.valueRemaining)}
                            <span className="purchase-order-tooltip">
                                {po.description || "No description available"}
                            </span>
                        </div>
                    ),
                    value: po.number
                };
                return dropdownOption;
            })
        ];
    };

    return (
        <Input.Dropdown
            label="Select Purchase Order"
            showErrors={true}
            value={existingPurchaseOrder?.number || "Not Set"}
            options={getPurchaseOrderOptions()}
            onChange={onChange}
            dynamicOptions
            search
        />
    );
};
