import * as React from "react";

interface ErrorBoundaryState {
    hasError: boolean;
    errorInfo: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            errorInfo: undefined,
        };
    }

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            hasError: true,
            errorInfo: info
        });
        // eslint-disable-next-line no-console
        console.error(error, info);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <h2>Something went wrong, please try again later.</h2>
            );
        }
        return this.props.children;
    }
}
