/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { Form, Tab, TabProps, Segment, Message, Icon } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, businessLineTypeSelector } from "@common/redux-helpers";
import { AppState, CorporateUserDetailModel, CorporateUserCreateEditModel } from "../model";
import { saveCorporateUser } from "../actions";
import { pathWithoutLastPartSelector, editPathSelector, corporateUserSelector, organisationsPathSelector } from "../selectors";
import { EditModal } from "./EditModal";
import { OrganisationApi, OrganisationState } from "@common/crud/organisation";
import { AuthState } from "@common/auth/model";
import { toast } from "@common/toasts";
import { Spinner } from "@common/global";
import { debounce } from "@neworbit/simpleui-utils";
import { MuiDateField } from "@common/components/MuiDateField";
import { CorporateUserApi } from "../corporateUserApi";
import { phoneNumberWithSpaces } from "@common/validation";
import { Department } from "@common/crud/organisation/model";

export interface EditProps extends SharedEditProps<CorporateUserCreateEditModel> {
    organisationId: string;
    open: boolean;
    editPath: string;
    organisationsPath: string;
    loadDepartments: (organisationId: string) => Promise<Department[]>;
    findUser: (email: string) => Promise<CorporateUserDetailModel>;
    hasCorporateOrganisationSpecificDepartments: boolean;
}

export interface DispatchProps extends SaveDispatchProps<CorporateUserCreateEditModel> {
    close: () => void;
}

interface EditState extends FormState<CorporateUserCreateEditModel> {
    activeIndex: string | number;
    duplicateUser: CorporateUserDetailModel;
    searchingEmail: boolean;
    departments: {text: string; value: string}[];
}

export class EditForm extends EditComponent<CorporateUserCreateEditModel, EditProps & DispatchProps, EditState> {

    public componentWillReceiveProps(props: Readonly<EditProps & DispatchProps & SaveDispatchProps<CorporateUserCreateEditModel>>) {
        super.componentWillReceiveProps(props);

        const defaultPane = this.panes.find(pane => pane.editPath === props.editPath);

        this.setState({
            activeIndex: defaultPane ? defaultPane.index : 0,
            loading: false,
            departments: [],
        });
    }

    public async componentDidMount() {
        if (this.props.hasCorporateOrganisationSpecificDepartments && !this.state.departments) {
            this.loadDepartmentsIntoState(this.state.values.organisationId);
        }
    }

    private loadDepartmentsIntoState = async (organisationId: string) => {
        const departments = await this.props.loadDepartments(organisationId);
        this.setState({ departments: departments.map(d => ({ text: d.name, value: d.id })) });
    };

    private onChange = (k: keyof CorporateUserCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private onChangeDepartments = (value: string[], valid: boolean) => {
        const departmentsSelected = this.state.departments?.filter(d => value.some(v => v === d.value))
            .sort((a, b) => a.text.localeCompare(b.text)).map(d => d.value) || value;
        this.onChange("departments")(departmentsSelected, valid);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const duplicateUser = await this.props.findUser(value);
            this.setState({ searchingEmail: false, duplicateUser, valid: { ...this.state.valid, email: !duplicateUser } });
        } else {
            this.setState({ duplicateUser: null });
        }
    };

    private panes = [
        {
            index: 0, menuItem: "Details", editPath: "", render: () => {
                const { values, showErrors, duplicateUser, searchingEmail } = this.state;
                const { organisationsPath } = this.props;

                return (
                    <Tab.Pane key="mainPane">
                        <Input.Text
                            value={values.forename}
                            label="Forename"
                            showErrors={showErrors}
                            required
                            onChange={this.onChange("forename")}
                        />
                        <Input.Text
                            value={values.surname}
                            label="Surname"
                            showErrors={showErrors}
                            required
                            onChange={this.onChange("surname")}
                        />
                        <Input.Email
                            value={values.email}
                            label="Email"
                            showErrors={showErrors}
                            required
                            onChange={this.onEmailChanged}
                        />
                        {searchingEmail &&
                            <Message as={Segment} className="cancel-action">
                                <Icon loading name='spinner' />
                                Searching for existing users with that email...
                            </Message>
                        }
                        {duplicateUser &&
                            <Message as={Segment} className="cancel-action">
                                <p>User with that email already exists:&nbsp;
                                    <Link href={`${organisationsPath}/${duplicateUser.organisationId}/users/${duplicateUser.id}`}>
                                        {duplicateUser.fullName}
                                    </Link>
                                </p>
                            </Message>
                        }
                        <Input.Text
                            value={values.telephone}
                            label="Telephone"
                            showErrors={showErrors}
                            onChange={this.onChange("telephone")}
                            validation={phoneNumberWithSpaces()}
                        />
                        {this.props.hasCorporateOrganisationSpecificDepartments && (
                            <Input.DropdownMulti
                                value={values.departments}
                                label="Departments"
                                showErrors={this.state.showErrors}
                                options={this.state.departments || []}
                                dynamicOptions
                                onChange={this.onChangeDepartments}
                                multiple
                                search
                            />
                        )}
                        <Input.Checkbox
                            value={values.closedCourseManager}
                            label="Closed course manager"
                            onChange={this.onChange("closedCourseManager")}
                        />
                        <Input.Checkbox
                            value={values.upcomingNewsAndCoursesNewsletter}
                            label="Upcoming news and courses newsletter"
                            onChange={this.onChange("upcomingNewsAndCoursesNewsletter")}
                        />
                        <MuiDateField
                            value={values.expiry}
                            label="Expiry Date"
                            showErrors={showErrors}
                            onChange={this.onExpiryChanged}
                        />
                    </Tab.Pane>
                );
            }
        }
    ];

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Spinner active={this.state.loading}>
                    <Tab panes={this.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                </Spinner>
            </Form>
        );
    }

    public submit = async () => {
        this.setState({ loading: true });

        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            this.setState(prevState => ({ ...prevState, showErrors: true, activeIndex: 0, loading: false }));
            toast.error("Please correct any invalid fields");
            return;
        }

        await this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));

    private onExpiryChanged = (value: moment.Moment, valid: boolean) => {
        if (this.state.values.expiry !== value) {
            this.updateProperty("expiry", value, valid);
        }
    };
}

function mapStateToProps(state: AppState & OrganisationState & AuthState) {
    const open = state.router.pathname.endsWith("/edit");
    const organisationId = state.router.params.organisationId;
    const hasCorporateOrganisationSpecificDepartments = state.organisations && state.organisations.length > 0 && state.organisations
        .filter(c => c.id === organisationId)[0]?.corporateOrganisationData?.hasCorporateOrganisationSpecificDepartments;
    const businessLineType = businessLineTypeSelector(state);

    return {
        organisationId,
        model: corporateUserSelector(state) as CorporateUserCreateEditModel,
        open,
        basePath: pathWithoutLastPartSelector(state),
        organisationsPath: organisationsPathSelector(state),
        editPath: open ? editPathSelector(state) : null,
        hasCorporateOrganisationSpecificDepartments,
        businessLineType
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (corporateUser: CorporateUserCreateEditModel, basePath: string) => dispatch(saveCorporateUser(corporateUser, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {
    const { model, open, basePath, organisationsPath, editPath, organisationId } = propsFromState;
    const { dispatchSave, dispatchClose } = propsFromDispatch;
    const api = new CorporateUserApi();
    const organisationApi = new OrganisationApi();
    const businessLineType = propsFromState.businessLineType;
    return {
        organisationId,
        model,
        open,
        editPath,
        organisationsPath,
        save: (corporateUser: CorporateUserCreateEditModel) => dispatchSave(corporateUser, basePath),
        close: () => dispatchClose(`${basePath}`),
        findUser: debounce((email: string) => api.findByEmail(email, businessLineType), 300),
        loadDepartments: (departmentOrganisationId: string) => organisationApi.getDepartments(departmentOrganisationId, true),
        hasCorporateOrganisationSpecificDepartments: propsFromState.hasCorporateOrganisationSpecificDepartments
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
