import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Link, State as RouterState } from "redux-little-router";
import { organisationRootPathSelector } from "@common/redux-helpers";
import { appSelector } from "./selectors";
import { Apps } from "@common/model";

interface InternalLinkProps {
    href: string;
    organisationRootPath: string;
    className?: string;
    children?: React.ReactNode;
}

const InternalLinkInternal: React.SFC<InternalLinkProps> = ({ href, organisationRootPath, className, children }) => {
    const isAdminApp = useSelector(appSelector) === Apps.Admin;
    const root = isAdminApp ? "/police-and-court-event-management" : "";
    return (<Link href={`${root}${organisationRootPath}/${href}`} className={className}>{children}</Link>);
};

function mapStateToProps(state: RouterState) {
    return {
        organisationRootPath: organisationRootPathSelector(state)
    };
}

export const InternalLink = connect(mapStateToProps)(InternalLinkInternal);
