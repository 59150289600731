/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_AVAILABILITY_TRAINER_SUCCESS = "LOAD_AVAILABILITY_TRAINER_SUCCESS";
export type LOAD_AVAILABILITY_TRAINER_SUCCESS = typeof LOAD_AVAILABILITY_TRAINER_SUCCESS;

export const LOAD_VENUES_IN_AVAILABILITY_TRAINER_SUCCESS = "LOAD_VENUES_IN_AVAILABILITY_TRAINER_SUCCESS";
export type LOAD_VENUES_IN_AVAILABILITY_TRAINER_SUCCESS = typeof LOAD_VENUES_IN_AVAILABILITY_TRAINER_SUCCESS;

export const SAVE_AVAILABILITY_TRAINER_SUCCESS = "SAVE_AVAILABILITY_TRAINER_SUCCESS";
export type SAVE_AVAILABILITY_TRAINER_SUCCESS = typeof SAVE_AVAILABILITY_TRAINER_SUCCESS;

export const SET_AVAILABILITY_EDIT_MODE_SUCCESS = "SET_AVAILABILITY_EDIT_MODE_SUCCESS";
export type SET_AVAILABILITY_EDIT_MODE_SUCCESS = typeof SET_AVAILABILITY_EDIT_MODE_SUCCESS;
