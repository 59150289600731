import * as React from "react";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { EventInstance, EventTrainer, OtherTrainerTypeCategory, PracticalEventTrainer, TrainerType } from "../../model";
import { Button, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { getCurrencyFormat } from "@common/formating";
import { Link } from "redux-little-router";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { FeeNoteModal } from "@common/crud/eventInstance/components/details/FeeNoteModal";
import { CancelTrainer } from "@common/crud/eventInstance/components/details/CancelTrainer";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { toast } from "@common/toasts";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { OtherRoleEdit } from "@common/crud/eventInstance/components/details/OtherRoleEdit";
import { universalEventManagementPathSelector } from "@common/redux-helpers";

interface MultiDayTrainerDetailsProps {
    eventInstance: EventInstance;
    trainerType: TrainerType;
    trainer: EventTrainer | PracticalEventTrainer;
    group: EventInstanceGroupModel;
    groupEventInstances: EventInstance[];
    updateGroupEventInstances: (eventInstances: EventInstance[]) => void;
}

export const MultiDayTrainerDetails: React.FC<MultiDayTrainerDetailsProps> = ({
    eventInstance,
    trainerType,
    trainer,
    group,
    groupEventInstances,
    updateGroupEventInstances
}) => {

    const [isInFeeEditMode, setIsInFeeEditMode] = React.useState<boolean>(false);
    const [fee, setFee] = React.useState<number>(trainer.fee);
    const [feeNote, setFeeNote] = React.useState<string>(trainer.feeNote);

    const [isInSundriesEditMode, setIsInSundriesEditMode] = React.useState<boolean>(false);
    const [sundries, setSundries] = React.useState<number>(trainer.sundries);

    const [toggleFeeNoteModal, setToggleFeeNoteModal] = React.useState(false);

    const dispatch = useDispatch();
    const path = useSelector(universalEventManagementPathSelector);
    const isAdminApp = useSelector(appSelector) === Apps.Admin;

    const dayNumbers = React.useCallback((): number[] => {
        const dayNumbersAllocated: number[] = [];
        const daysWithTrainer: EventInstance[] = [];

        switch (trainerType) {
            case TrainerType.TheoryTrainer:
                daysWithTrainer.push(...groupEventInstances.filter(ei => ei.trainers?.some(eit => eit.id === trainer.id)));
                break;
            case TrainerType.PracticalTrainer:
                daysWithTrainer.push(...groupEventInstances.filter(ei => ei.practicalTrainers?.some(eit => eit.id === trainer.id)));
                break;
            case TrainerType.MonitorTrainer:
                daysWithTrainer.push(...groupEventInstances.filter(ei => ei.monitorTrainers?.some(eit => eit.id === trainer.id)));
                break;
            case TrainerType.OtherTrainer:
                daysWithTrainer.push(...groupEventInstances.filter(ei => ei.observerTrainers?.some(eit => eit.id === trainer.id)));
                break;
            default:
            // nop
        }

        daysWithTrainer.map((dayEventInstance) => {
            const groupItem = group?.eventInstanceGroupItems?.find((item: EventInstanceGroupItemModel) =>
                item.eventInstanceId === dayEventInstance.id);
            const displayDay =   groupItem?.dayNumber ?? undefined;

            if (displayDay) {
                dayNumbersAllocated.push(displayDay);
            }
        });
        return dayNumbersAllocated;
    }, [group?.eventInstanceGroupItems, groupEventInstances, trainer.id, trainerType]);

    React.useEffect(() => {
        setFee(trainer.fee);
        setSundries(trainer.sundries);
    }, [trainer]);

    const daysDisplay = () => {
        const numbersArray = dayNumbers();
        return numbersArray?.length === 0 ? "None" : numbersArray?.length === groupEventInstances.length ? "All" : numbersArray.join();
    };

    const getEditButtonIcon = (isInEditMode: boolean) => {
        return isInEditMode ? "check" : "pencil";
    };

    const additionalFeesRow = () => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing">
                    <b>Additional Fees:</b>
                </Grid.Column>
                <Grid.Column width={10}>
                    {getCurrencyFormat(trainer.additionalFeesTotal)}
                </Grid.Column>
            </Grid.Row>
        );
    };

    const hrefColumn = (name: string, value: string, href: string) => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing">
                    <b>{name}</b>
                </Grid.Column>
                <Grid.Column width={10}>
                    <a className="text-wrap" href={href}>{value}</a>
                </Grid.Column>
            </Grid.Row>
        );
    };

    const column = (name: string, value: string) => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing">
                    <b>{name}</b>
                </Grid.Column>
                <Grid.Column width={10}>
                    {value}
                </Grid.Column>
            </Grid.Row>
        );
    };

    const feeColumn = () => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing">
                    <b>Fee:</b>
                </Grid.Column>
                <Grid.Column width={10}>
                    {isInFeeEditMode ?
                        <CurrencyInput
                            value={fee}
                            onChange={setFee}
                        /> : getCurrencyFormat(fee)}
                    <Button icon={getEditButtonIcon(isInFeeEditMode)} size="mini" onClick={updateTrainerFee} />
                    {isInFeeEditMode && <Button icon="delete" color="red" size="mini" onClick={cancelFeeEdit} />}
                </Grid.Column>
            </Grid.Row>
        );
    };

    const sundriesColumn = () => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing"><b>Sundries:</b></Grid.Column>
                <Grid.Column width={10}>
                    {isInSundriesEditMode ?
                        <CurrencyInput
                            value={sundries}
                            onChange={setSundries}
                        /> : getCurrencyFormat(sundries)}
                    <Button icon={getEditButtonIcon(isInSundriesEditMode)} size="mini" onClick={updateTrainerSundries} />
                    {isInSundriesEditMode && <Button icon="delete" color="red" size="mini" onClick={cancelSundriesEdit} />}
                </Grid.Column>
            </Grid.Row>
        );
    };

    function onToggleFeeNoteModal() {
        setToggleFeeNoteModal(!toggleFeeNoteModal);
    }

    async function updateTrainerFeeNote(updatedFeeNote: string) {
        if (toggleFeeNoteModal) {
            const api = new EventInstanceGroupApi();
            try {
                await api.updateGroupEventInstanceTrainerFeeNote(eventInstance.groupId, trainer.id, trainerType, updatedFeeNote);
                setFeeNote(updatedFeeNote);
                toast.info("Trainer fee note updated");
            }
            catch (_) {
                toast.error("Something went wrong");
            }
        }
        setToggleFeeNoteModal(!toggleFeeNoteModal);
    }

    const feeNoteColumn = () => {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing"><b>Fee Note:</b></Grid.Column>
                <Grid.Column width={10}>
                    <span>{feeNote}</span>&nbsp;
                    <FeeNoteModal
                        toggle={toggleFeeNoteModal}
                        setToggle={setToggleFeeNoteModal}
                        feeNote={feeNote}
                        updateTrainerFeeNote={updateTrainerFeeNote}
                    />
                    <Button icon={getEditButtonIcon(toggleFeeNoteModal)} size="mini" onClick={onToggleFeeNoteModal} />
                </Grid.Column>
            </Grid.Row>
        );
    };

    const updateTrainerFee = React.useCallback(async () => {
        if (isInFeeEditMode) {
            const api = new EventInstanceGroupApi();
            try {
                await api.updateGroupEventInstanceTrainerFee(eventInstance.groupId, trainer.id, trainerType, fee);
                toast.info("Trainer fee updated");
            }
            catch (_) {
                setFee(trainer.fee);
                toast.error("Something went wrong");
            }
        }
        setIsInFeeEditMode(!isInFeeEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInFeeEditMode, eventInstance.groupId, trainer.id, trainerType, fee]);

    const cancelFeeEdit = React.useCallback(() => {
        setIsInFeeEditMode(false);
    }, []);

    const updateTrainerSundries = React.useCallback(async () => {
        if (isInSundriesEditMode) {
            const api = new EventInstanceGroupApi();
            try {
                await api.updateGroupEventInstanceTrainerSundries(eventInstance.groupId, trainer.id, trainerType, sundries);
                toast.info("Trainer sundries updated");
            }
            catch (_) {
                setSundries(trainer.sundries);
                toast.error("Something went wrong");
            }
        }
        setIsInSundriesEditMode(!isInSundriesEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInSundriesEditMode, dispatch, eventInstance.groupId, trainer.id, trainerType, sundries]);

    const cancelSundriesEdit = React.useCallback(() => {
        setIsInSundriesEditMode(false);
    }, []);

    return (
        <Grid className="trainer-details">
            <Grid.Row className="trainer-details-heading">
                <Grid.Column width={6} className="horizontal-spacing">
                    {isAdminApp ? <Link href={`${path}/trainers/${trainer.id}`}>{trainer.name} </Link> : trainer.name}
                </Grid.Column>
                {
                    trainerType === TrainerType.OtherTrainer && trainer?.otherTrainerTypeCategory !== null &&
                    <Grid.Column width={6} textAlign="right">
                        {OtherTrainerTypeCategory[trainer?.otherTrainerTypeCategory]}
                    </Grid.Column>
                }
            </Grid.Row>
            <>
                {column("Days:", daysDisplay())}
                {column("Confirmed:", trainer.confirmed ? "Yes" : "No")}
                {
                    trainer.mobileNumber && hrefColumn("Mobile:", trainer.mobileNumber, `tel:${trainer.mobileNumber}`)
                }
                {
                    trainer.homeNumber && hrefColumn("Home Telephone:", trainer.homeNumber, `tel:${trainer.homeNumber}`)

                }
                <Authorize authorize={TtcClientAdvisorRole}>
                    {
                        trainer.email && hrefColumn("Email:", trainer.email, `mailto:${trainer.email}`)
                    }
                    {feeColumn()}
                    {additionalFeesRow()}
                    {sundriesColumn()}
                </Authorize>
                {feeNoteColumn()}
                {
                    trainerType === TrainerType.OtherTrainer && trainer?.otherTrainerTypeCategory !== null &&
                    <OtherRoleEdit
                        trainerId={trainer?.id}
                        otherTrainerTypeCategory={trainer?.otherTrainerTypeCategory}
                        getEditButtonIcon={getEditButtonIcon}
                        groupId={eventInstance?.groupId}
                        dayNumbers={dayNumbers()}
                        updateGroupEventInstances={updateGroupEventInstances}
                    />
                }
            </>
            {isAdminApp && <>
                <Grid.Column width={6} textAlign="left" className="column-spacing">
                    <CancelTrainer eventInstanceId={eventInstance.id} trainerId={trainer.id}
                        trainerType={trainerType} dayParts={dayNumbers()} />
                </Grid.Column>
            </>}
        </Grid>
    );
};
