import * as React from "react";
import { Table } from "semantic-ui-react";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { TrainerRow } from "./TrainerRow";
import moment from "moment";
import { Spinner } from "@common/global";

interface OwnProps {
    isDigital: boolean;
    isCorporateOrConstruction: boolean;
    selectedTrainers: TrainerAvailabilitySelectModel[];
    trainers: Record<string, TrainerAvailabilitySelectModel[]>;
    limit?: { value: number; message: string };
    onSelectTrainer(trainer: TrainerAvailabilitySelectModel): void;
    onDeselectTrainer(trainer: TrainerAvailabilitySelectModel): void;
    savedSelectedTrainers: TrainerAvailabilitySelectModel[];
    nameSearch?: string;
    theoryAndPractical: boolean;
    publishedDate: moment.Moment;
    startDate: moment.Moment;
    loading: boolean;
}

export const TrainersSelector: React.FunctionComponent<OwnProps> = ({
    isDigital,
    isCorporateOrConstruction: isCorporate,
    selectedTrainers,
    trainers,
    limit,
    onSelectTrainer,
    onDeselectTrainer,
    savedSelectedTrainers,
    nameSearch,
    theoryAndPractical,
    publishedDate,
    startDate,
    loading
}) => {

    const renderEmptyRow = () => {
        return (
            <Table.Row active>
                <Table.Cell />
                <Table.Cell />
                {!isDigital && <Table.Cell />}
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                {!isDigital && <Table.Cell />}
                <Table.Cell />
                {(!isCorporate && theoryAndPractical) && <Table.Cell />}
                <Table.Cell />
            </Table.Row>
        );
    };

    const sortedTrainers = (trainerVals: TrainerAvailabilitySelectModel[], firstRow: boolean) => {

        const values = (nameSearch !== "" && nameSearch !== null) ? trainerVals.filter(t => t.fullName.toLowerCase().includes(nameSearch.trim().toLowerCase()))
            : trainerVals;

        if (firstRow) {
            const trainerNotSelected = values.filter(item => savedSelectedTrainers.find(z => z.id === item.id) === undefined);
            const displayList = savedSelectedTrainers.concat(trainerNotSelected);
            return displayList;
        }
        else {
            const trainerNotSelected = values.filter(item => savedSelectedTrainers.find(z => z.id === item.id) === undefined);
            return trainerNotSelected;
        }
    };

    return (<>
        <Spinner active={loading}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Trainer</Table.HeaderCell>
                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                        {!isDigital && <Table.HeaderCell>Distance from Venue (miles)</Table.HeaderCell>}
                        <Table.HeaderCell>no. courses in same month</Table.HeaderCell>
                        <Table.HeaderCell>no. days in rolling 12 months</Table.HeaderCell>
                        <Table.HeaderCell>Available</Table.HeaderCell>
                        {!isDigital && <Table.HeaderCell>Venue chosen</Table.HeaderCell>}
                        <Table.HeaderCell>Current status</Table.HeaderCell>
                        {(!isCorporate && theoryAndPractical) && <Table.HeaderCell>Car Type</Table.HeaderCell>}
                        <Table.HeaderCell>Add to course</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {Object.entries(trainers).map(([, trainerVals], index) => (
                        <React.Fragment key={index}>
                            {index > 0 && renderEmptyRow()}
                            {sortedTrainers(trainerVals, index === 0).map(t => (
                                <TrainerRow
                                    key={t.id}
                                    isDigital={isDigital}
                                    isCorporate={isCorporate}
                                    trainer={t}
                                    limit={limit}
                                    selectedTrainers={selectedTrainers}
                                    onSelectTrainer={onSelectTrainer}
                                    onDeselectTrainer={onDeselectTrainer}
                                    theoryAndPractical={theoryAndPractical}
                                    publishedDate={publishedDate}
                                    startDate={startDate}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </Table.Body>
            </Table>
        </Spinner>
    </>);
};
