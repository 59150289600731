import * as React from "react";
import { SmsServiceSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const SmsService: ConfigurationAreaComponent<SmsServiceSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="SmsService"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ClientBillingReference", label: "Text Anywhere Client Billing Reference" },
                { name: "Username", label: "Text Anywhere Username" },
                { name: "Password", label: "Text Anywhere Password" },
                { name: "NotificationEndpoint", label: "Sms Notification Endpoint" },
                { name: "IsLiveEnvironment", label: "Use Live Service" },
                { name: "SmsEndpoint", label: "Text Anywhere Sms Endpoint" },
                { name: "UseGenesysForSms", label: "Use Genesys for SMS" },
                { name: "OutboundSmsNumber", label: "Genesys Outbound SMS Number" },
                { name: "ClientId", label: "Genesys Client Id" },
                { name: "ClientSecret", label: "Genesys Client Secret" },
            ]}
        />
    );
};
