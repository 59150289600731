import * as React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { organisationIdSelector } from "@common/redux-helpers";

export const CorporateAndConstructionAdminEventManagementActions: React.FC<{ eventManagementPath: string; path: string }> = ({ eventManagementPath, path }) => {
    const routeOrganisationId = useSelector(organisationIdSelector);
    return (
        <>
            <Button content="Trainer availability search" as={Link} href={routeOrganisationId
                ? `/${eventManagementPath}/organisations/${routeOrganisationId}/schedule/trainer-availability-search`
                : `/${eventManagementPath}/trainer-availability-search`} />
            <Button content="Create" as={Link} href={`${path}/create`} />
        </>
    );
};
