/* eslint-disable eqeqeq */
/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { DayContentProps, DayPicker } from "react-day-picker";
import { Container, Button, Grid, Table, Message, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "reauthorize";
import { TrainerRole } from "@common/auth/model";
import {
    EventInstanceListModel, Area, SearchOptions,
    SESSION_TWO_START_HOUR, SESSION_THREE_START_HOUR, SESSION_FOUR_START_HOUR
} from "@common/crud/eventInstance/model";
import { TrainerAvailability, AppState, Sessions, AvailableDate, SessionAvailability, AvailabilityType } from "../model";
import { SettingsBar, SettingsBarConfiguration } from "./SettingsBar";
import { EventInstance } from "@common/crud/eventInstance";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { AllItems } from "@common/crud/eventInstance/components/AllItems";
import { eventInstancesSelector, groupedEventInstancesSelector } from "@common/crud/eventInstance/selectors";
import { saveTrainerAvailability, setAvailabilityEditMode } from "../actions";
import { toast } from "@common/toasts";
import { TrainerAvailabilityApi } from "../trainerAvailabilityApi";
import { ResponsiveLegend } from "./ResponsiveLegend";
import { RegionalCoordinatorCalendarDay } from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/model";
import { routeTrainerIdSelector, trainerSelector } from "@common/crud/trainer/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { loadSingleAndMultiDayEventInstances } from "@common/crud/eventInstance/actions";
import { currentUserSelector } from "@common/auth";
import { setDeliveryTimesForTrainer } from "@common/crud/eventInstance/helpers";
import { DayPickerHeader } from "@common/availabilityTrainer/components/DayPickerHeader";
import { durationMap, previousCourseSessionInProgress } from "../helpers";
import { StandbyTrainersCalendarDay } from "@common/crud/eventInstance/standbyTrainersCalendarModel";
import { MobileMaxWidth } from "@common/style/constants";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { useWindowDimensions } from "@common/global/UseWindowDimensions";
import { PreferredNoDaysPerMonthInAGivenMonth, PreferredNoSessionsPerDayInAGivenMonth } from "@common/trainerPromise/model";
import {
    setUpdatedPreferredNoDaysPerMonthOverMonths,
    setUpdatedPreferredNoSessionsPerDayOverMonths,
    setCurrentPreferredNoDaysPerMonthOverMonths,
    setCurrentPreferredNoSessionsPerDayOverMonths
} from "../../trainerPromise/actions";

interface AvailabilityTrainerProps {
    area: Area;
}

const getInitialDate = () => {
    const searchParams = new URLSearchParams(location.search);
    const d = searchParams.get("date");
    return d ? moment.utc(d, "YYYY/MM/DD") : moment().utc();
};

export const AvailabilityTrainerComponent: React.FC<AvailabilityTrainerProps> = ({ area }) => {
    const dispatch = useDispatch();
    const { windowDimensions, isMobileLandscapeHeight, isTabletScreenSize } = useWindowDimensions();
    const selTrainerAvailability = useSelector((state: AppState) => state.trainerAvailability);
    const selTrainerPromise = useSelector((state: AppState) => state.trainerPromise);
    const eventInstances = useSelector(eventInstancesSelector);
    const eventInstanceGroups = useSelector(groupedEventInstancesSelector);
    const currentUser = useSelector(currentUserSelector);
    const trainerIdFromRoute = useSelector(routeTrainerIdSelector);
    const trainerId = area === Area.TrainerAppAvailabilityCalendar ? currentUser?.id : trainerIdFromRoute;

    const [settings, setSettings] = React.useState<SettingsBarConfiguration[]>([
        { value: false, enumValue: Sessions.SessionOne },
        { value: false, enumValue: Sessions.SessionTwo },
        { value: false, enumValue: Sessions.SessionThree },
        { value: false, enumValue: Sessions.SessionFour },
        { value: false, enumValue: Sessions.Unavailable }
    ]);

    const [scheduledEvents, setScheduledEvents] = React.useState<EventInstanceListModel[]>([]);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [calendarDate, setCalendarDate] = React.useState<moment.Moment>(getInitialDate());
    const [planningStarted, setPlanningStarted] = React.useState<moment.Moment[]>([]);
    const [planningInProgress, setPlanningInProgress] = React.useState(false);
    const [trainerAvailability, setTrainerAvailability] =
        React.useState<TrainerAvailability>({ ...selTrainerAvailability, availableDates: selTrainerAvailability.availableDates || [] });
    const [availabilityType, setAvailabilityType] = React.useState(AvailabilityType.Unspecified);
    const { availableDates } = trainerAvailability;
    const [rcCalendar, setRcCalendar] = React.useState<RegionalCoordinatorCalendarDay[]>([]);
    const [standbyCalendar, setStandbyCalendar] = React.useState<StandbyTrainersCalendarDay[]>([]);
    const trainer = useSelector(trainerSelector);
    const { isDayBankHoliday } = useBankHolidays();

    React.useEffect(() => {
        if (selTrainerAvailability) {
            setTrainerAvailability({ ...selTrainerAvailability, availableDates: selTrainerAvailability.availableDates || [] });
        }
    }, [selTrainerAvailability]);

    React.useEffect(() => {
        async function initialise() {
            const today = getInitialDate();
            const startOfCurrentMonth = today.clone().startOf("month");
            const middleOfMonth = startOfCurrentMonth.clone().add(15, "days");
            const endOfCurrentMonth = today.clone().endOf("month");
            const api = new TrainerAvailabilityApi();
            const rc = await api.getTrainerRCMonitoring(middleOfMonth, trainer?.id);
            const standby = await api.getTrainerStandby(middleOfMonth, trainer?.id);
            const searchOptions: { options: SearchOptions } = {
                options: {
                    fromDate: startOfCurrentMonth,
                    toDate: endOfCurrentMonth,
                    workflowTypes: [WorkflowTypeEnum.Any]
                }
            };
            setRcCalendar(rc.days);
            setStandbyCalendar(standby.days);
            dispatch(loadSingleAndMultiDayEventInstances(searchOptions));

            const result = await api.checkIfPlanningStartedForMonth();
            if (result) {
                setPlanningStarted(result);
                setPlanningInProgress(result.findIndex(p => p.startOf("month").dayOfYear() === startOfCurrentMonth.startOf("month").dayOfYear()) > -1);
            }
        }
        initialise();
    }, [trainer?.id, dispatch]);

    const currentWindowWidth = React.useMemo(() => windowDimensions.width,
        [windowDimensions.width]);

    const setEditModeAndShowMessage = React.useCallback((editModeValue: boolean, showMessageValue: boolean) => {
        setEditMode(editModeValue);
        dispatch(setAvailabilityEditMode(editModeValue));
        setShowMessage(showMessageValue);
    }, [dispatch]);

    const enterEditMode = React.useCallback(() => {
        setEditModeAndShowMessage(true, false);
        // discard updated states to avoid preserving validation errors when re-entering edit mode after cancel
        dispatch(setUpdatedPreferredNoDaysPerMonthOverMonths(null));
        dispatch(setUpdatedPreferredNoSessionsPerDayOverMonths(null));
    }, [dispatch, setEditModeAndShowMessage]);

    const cancel = React.useCallback(() => {
        setEditModeAndShowMessage(false, false);
        setTrainerAvailability({
            ...trainerAvailability,
            availableDates: [...trainerAvailability.availableDates]
        });
    }, [trainerAvailability, setEditModeAndShowMessage]);

    const save = React.useCallback(() => {
        let preferredNoDaysPerMonthOverMonths: PreferredNoDaysPerMonthInAGivenMonth[] = [];
        if (selTrainerPromise.updatedPreferredNoDaysPerMonthOverMonths)
        {
            for (const updatedPreferredNoDays of selTrainerPromise.updatedPreferredNoDaysPerMonthOverMonths)
            {
                if (updatedPreferredNoDays.valid)
                {
                    preferredNoDaysPerMonthOverMonths = preferredNoDaysPerMonthOverMonths.concat(updatedPreferredNoDays);
                }
                else if (selTrainerPromise.currentPreferredNoDaysPerMonthOverMonths?.find(c => c.month.isSame(updatedPreferredNoDays.month)))
                {
                    preferredNoDaysPerMonthOverMonths = preferredNoDaysPerMonthOverMonths.concat(
                        selTrainerPromise.currentPreferredNoDaysPerMonthOverMonths?.find( c => c.month.isSame(updatedPreferredNoDays.month)));
                }
            }
        }
        else if (selTrainerPromise.currentPreferredNoDaysPerMonthOverMonths)
        {
            preferredNoDaysPerMonthOverMonths = preferredNoDaysPerMonthOverMonths
                .concat(selTrainerPromise.currentPreferredNoDaysPerMonthOverMonths);
        }

        let preferredNoSessionsPerDayOverMonths: PreferredNoSessionsPerDayInAGivenMonth[] = [];
        if (selTrainerPromise.updatedPreferredNoSessionsPerDayOverMonths)
        {
            for (const updatedPreferredNoSessions of selTrainerPromise.updatedPreferredNoSessionsPerDayOverMonths)
            {
                if (updatedPreferredNoSessions.valid)
                {
                    preferredNoSessionsPerDayOverMonths = preferredNoSessionsPerDayOverMonths.concat(updatedPreferredNoSessions);
                }
                else if (selTrainerPromise.currentPreferredNoSessionsPerDayOverMonths?.find(c => c.month.isSame(updatedPreferredNoSessions.month)))
                {
                    preferredNoSessionsPerDayOverMonths = preferredNoSessionsPerDayOverMonths.concat(
                        selTrainerPromise.currentPreferredNoSessionsPerDayOverMonths?.find(c => c.month.isSame(updatedPreferredNoSessions.month)));
                }
            }
        }
        else if (selTrainerPromise.currentPreferredNoDaysPerMonthOverMonths)
        {
            preferredNoSessionsPerDayOverMonths = preferredNoSessionsPerDayOverMonths
                .concat(selTrainerPromise.currentPreferredNoSessionsPerDayOverMonths);
        }

        const now = moment.utc();
        const currTrainerAvailability: TrainerAvailability = {
            ...trainerAvailability,
            availableDates: [...trainerAvailability.availableDates].map(existingDate => {
                // remove the pending flag and add the timestamp
                const { pendingUpdate, ...newlyUpdatedDate } = { ...existingDate, dateUpdated: now };
                return existingDate.pendingUpdate? newlyUpdatedDate :existingDate;
            } ),
            preferredNoDaysPerMonthOverMonths,
            preferredNoSessionsPerDayOverMonths,
        };

        dispatch(setCurrentPreferredNoDaysPerMonthOverMonths(preferredNoDaysPerMonthOverMonths));
        dispatch(setCurrentPreferredNoSessionsPerDayOverMonths(preferredNoSessionsPerDayOverMonths));
        dispatch(setUpdatedPreferredNoDaysPerMonthOverMonths(null));
        dispatch(setUpdatedPreferredNoSessionsPerDayOverMonths(null));
        dispatch(saveTrainerAvailability(currTrainerAvailability));
        setEditModeAndShowMessage(false, false);
    }, [trainerAvailability, selTrainerPromise, dispatch, setEditModeAndShowMessage]);

    const renderDay = React.useCallback((props: DayContentProps) => {
        const day = props.date;
        const currentDay = day.getDate();
        const dayMoment = moment.utc([day.getFullYear(), day.getMonth(), currentDay]).startOf("day");
        const sameDayEventInstances = eventInstances?.filter(ei =>
            ei.allTrainerIds.includes(trainerId) && ei.startDate.startOf("day").isSame(dayMoment)) ?? [];

        const availability: AvailableDate = availableDates?.find(x => x.date.isSame(dayMoment))
            ?? Object.assign({ date: dayMoment, sessionAvailabilities: [] });

        const isBankHoliday = isDayBankHoliday(dayMoment);
        return (
            <div className="cell-style">
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday &&
                        <span className={`bank-holiday-text ${isMobileLandscapeHeight ?
                            "word-padding bank-holiday-text-padding-left-mobile-landscape" :
                            "bank-holiday-text-padding-left-normal"}`}>{isTabletScreenSize || isMobileLandscapeHeight ? "BH" : "Bank holiday"}
                        </span>}
                    <div>
                        {currentWindowWidth <= MobileMaxWidth && <span className="word-padding">{day.toString().split(" ")[0]}</span>}
                        {<span className={isBankHoliday ? "current-day-style" : undefined}>{currentDay}</span>}
                    </div>
                </div>
                {drawAvailability(availability.sessionAvailabilities, sameDayEventInstances?.map(e => setDeliveryTimesForTrainer(e, trainerId)), moment(day))}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstances, availableDates, trainerId, isMobileLandscapeHeight, isTabletScreenSize, currentWindowWidth]);

    const onMonthChange = React.useCallback(async (month: Date) => {
        const d = moment.utc([month.getFullYear(), month.getMonth(), month.getDate()]);
        const startOfMonth = d.clone().startOf("month");
        const middleOfMonth = startOfMonth.clone().add(15, "days");
        const endOfMonth = d.clone().endOf("month");
        const api = new TrainerAvailabilityApi();
        const rc = await api.getTrainerRCMonitoring(middleOfMonth, trainer?.id);
        const standby = await api.getTrainerStandby(middleOfMonth, trainer?.id);
        const searchOptions: { options: SearchOptions } = {
            options: {
                fromDate: startOfMonth,
                toDate: endOfMonth,
                workflowTypes: [WorkflowTypeEnum.Any]
            }
        };

        const searchParams = new URLSearchParams(window.location.search);
        const dateString = startOfMonth.format("YYYY-MM-DD");
        if (dateString !== searchParams.get("date")) {
            searchParams.set("date", dateString);
            window.history.pushState({}, "", `${window.location.pathname}?${searchParams}`);
        }

        setRcCalendar(rc.days);
        setCalendarDate(d);
        setStandbyCalendar(standby.days);
        dispatch(loadSingleAndMultiDayEventInstances(searchOptions));
        setPlanningInProgress(planningStarted.findIndex(p => p.startOf("month").dayOfYear() === startOfMonth.dayOfYear()) > -1);
    }, [dispatch, trainer?.id, planningStarted]);

    React.useEffect(() => {
        const monthFromQuery = getInitialDate();
        setCalendarDate(moment(monthFromQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const printRow = (cssClass: string, sessionCourse: EventInstance, type: AvailabilityType, lengthOfCont: number, hiddenByContCourse: boolean) => {
        const showHomeIcon = type && type === AvailabilityType.Classroom || type === AvailabilityType.ClassroomAndDigital;
        const showWifiIcon = type && type === AvailabilityType.Digital || type === AvailabilityType.ClassroomAndDigital;

        if (hiddenByContCourse || (sessionCourse && lengthOfCont === 0)) {
            return <></>;
        }

        return (<Table.Row rowSpan={sessionCourse ? lengthOfCont : 1} className={`period-row ${lengthOfCont >= 2 ? `rows-${lengthOfCont}` : ""}
            ${sessionCourse?.unconfirmedTrainerIds?.includes(trainerId) ? "unconfirmed" : ""}`}>
            <td className={
                `separated-row DayPeriod ${cssClass} ${sessionCourse != null &&
                 (isTabletScreenSize || isMobileLandscapeHeight) ? "tablet-size-row" : "day-period-padding"}
                 flex-row space-between flex-wrap
                 ${sessionCourse?.unconfirmedTrainerIds?.includes(trainerId) ? "unconfirmed" : ""}`
            }>
                {sessionCourse && (
                    <div className="course-name-text">
                        <div className={`${lengthOfCont > 1 ? `text-wrap-ellipsis-${lengthOfCont}` : "text-ellipsis"}`}>
                            {`${sessionCourse.startTime.format(DateFormat.Time, { trim: false })} ${sessionCourse.eventTypeAbbreviation}`}
                        </div>
                    </div>
                )}
                {sessionCourse == null && (showWifiIcon || showHomeIcon) &&
                    <span className="day-period-icons">
                        {showWifiIcon && <Icon name="wifi" />}
                        {showHomeIcon && <Icon name="home" />}
                    </span>
                }
            </td>
        </Table.Row>);
    };

    const printRCRow = (cssClass: string, rcType: string, type: AvailabilityType) => {
        const showHomeIcon = type && type === AvailabilityType.Classroom || type === AvailabilityType.ClassroomAndDigital;
        const showWifiIcon = type && type === AvailabilityType.Digital || type === AvailabilityType.ClassroomAndDigital;
        return (<Table.Row className="period-row">
            <td
                className={
                    `separated-row DayPeriod ${cssClass}
                    ${rcType != null && (isTabletScreenSize || isMobileLandscapeHeight) ? "tablet-size-row" : "day-period-padding"}`
                }>
                {`${rcType}`}
                <span>
                    {showHomeIcon && <Icon className="floated-right" name="home" />}
                    {showWifiIcon && <Icon className="floated-right" floated="right" name="wifi" />}
                </span>
            </td>
        </Table.Row>);
    };

    const printStandbyRow = (cssClass: string) => {
        return (<Table.Row className="period-row">
            <td className={`separated-row DayPeriod day-period-padding ${cssClass} `}>
                {isTabletScreenSize || isMobileLandscapeHeight ? "ST" : "Standby Trainer"}
            </td>
        </Table.Row>);
    };

    const RCTabletScreen = (isRCCover: boolean) => {
        if (isRCCover) {
            return isTabletScreenSize || isMobileLandscapeHeight ? "RCC" : "RC Cover";
        }
        return isTabletScreenSize || isMobileLandscapeHeight ? "RCM" : "RC Monitor";
    };

    const GetSessionTextForCoverOrMonitor = (day: moment.Moment, session: 1 | 2) => {
        const arrangementsForDay = rcCalendar?.find(d => d.date.date() === day.date());

        if (arrangementsForDay == undefined) {
            return null;
        }
        const monitoringSessionsOnDay = session === 1 ? arrangementsForDay?.monitorSessionsOnDaySessionOne
            : arrangementsForDay?.monitorSessionsOnDaySessionTwo;
        return arrangementsForDay.coverTrainerIds?.length > 0 ? RCTabletScreen(true) : `${RCTabletScreen(false)} ${monitoringSessionsOnDay}`;
    };

    const drawAvailability = (availability: SessionAvailability[], trainerEvents: EventInstance[], date: moment.Moment) => {

        const sessionOneCourse = trainerEvents.find(x => x.startTime?.hours() < SESSION_TWO_START_HOUR);
        const sessionTwoCourses = trainerEvents
            .filter(x =>
                (x.startTime?.hours() >= SESSION_TWO_START_HOUR &&
                    x.startTime?.hours() < SESSION_THREE_START_HOUR) ||
                (x.startTime?.hours() < SESSION_THREE_START_HOUR &&
                    x.startTime?.clone().add(x.eventDuration).hours() > SESSION_TWO_START_HOUR));
        const sessionThreeCourses = trainerEvents
            .filter(x =>
                (x.startTime?.hours() >= SESSION_THREE_START_HOUR &&
                    x.startTime?.hours() < SESSION_FOUR_START_HOUR) ||
                (x.startTime?.hours() < SESSION_FOUR_START_HOUR &&
                    x.startTime?.clone().add(x.eventDuration).hours() > SESSION_THREE_START_HOUR));
        const sessionFourCourses = trainerEvents.filter(x => (x.startTime?.hours() > SESSION_FOUR_START_HOUR) ||
            (x.startTime?.clone().add(x.eventDuration).hours() > SESSION_FOUR_START_HOUR));

        const sessionOne = availability.find(a => a.session === 1);
        const sessionTwo = availability.find(a => a.session === 2);
        const sessionThree = availability.find(a => a.session === 3);
        const sessionFour = availability.find(a => a.session === 4);

        const getSessionOneText = GetSessionTextForCoverOrMonitor(date, 1);
        const getSesionTwoText = GetSessionTextForCoverOrMonitor(date, 2);
        const isCoverOrMonitor = !!(getSessionOneText || getSesionTwoText);

        const standbyArrangementsForDay = standbyCalendar?.find(d => d.date.date() === date.date());
        const sessionOneStandby = standbyArrangementsForDay?.sessionOneStandbyTrainerIds?.length > 0 ?? false;
        const sessionTwoStandby = standbyArrangementsForDay?.sessionTwoStandbyTrainerIds?.length > 0 ?? false;
        const sessionThreeStandby = standbyArrangementsForDay?.sessionThreeStandbyTrainerIds?.length > 0 ?? false;
        const sessionFourStandby = standbyArrangementsForDay?.sessionFourStandbyTrainerIds?.length > 0 ?? false;

        const sessionTwoCourse = sessionTwoCourses.find(x => x !== sessionOneCourse) ?? sessionTwoCourses.find(x => x);
        const sessionThreeCourse = sessionThreeCourses.find(x => x !== sessionTwoCourse) ?? sessionThreeCourses.find(x => x);
        const sessionFourCourse = sessionFourCourses.find(x => x !== sessionThreeCourse) ?? sessionFourCourses.find(x => x);

        const sessionCourses = [
            sessionOneCourse,
            sessionTwoCourse,
            sessionThreeCourse,
            sessionFourCourse,
        ];

        return (
            <div className="font-style">
                <Table className="unstackable">
                    <Table.Body className="table-body">
                        {
                            !sessionOneCourse && isCoverOrMonitor
                                ? printRCRow("top period-color booked", getSessionOneText, sessionOne?.type)
                                : sessionOneStandby ? printStandbyRow("top period-color booked")
                                    : printRow(`top ${sessionOne ? "period-color" : ""} ${sessionOneCourse ? "booked" : ""}`,
                                        sessionOneCourse, sessionOne?.type, durationMap(sessionCourses)[0], false)
                        }
                        {
                            !sessionTwoCourse && isCoverOrMonitor
                                ? printRCRow("middle-top period-color booked", getSesionTwoText, sessionTwo?.type)
                                : sessionTwoStandby ? printStandbyRow("middle-top period-color booked")
                                    : printRow(`middle-top ${sessionTwo ? "period-color" : ""} ${sessionTwoCourse ? "booked" : ""}`,
                                        sessionTwoCourse, sessionTwo?.type, durationMap(sessionCourses)[1], previousCourseSessionInProgress(2, sessionCourses))
                        }

                        {sessionThreeStandby
                            ? printStandbyRow("middle-bottom period-color booked")
                            : printRow(
                                `middle-bottom ${sessionThree ? "period-color" : ""} ${sessionThreeCourse ? "booked" : ""}`,
                                sessionThreeCourse, sessionThree?.type, durationMap(sessionCourses)[2], previousCourseSessionInProgress(3, sessionCourses))}
                        {sessionFourStandby
                            ? printStandbyRow("bottom period-color booked")
                            : printRow(
                                `bottom ${sessionFour ? "period-color" : ""} ${sessionFourCourse ? "booked" : ""}`,
                                sessionFourCourse, sessionFour?.type, durationMap(sessionCourses)[3], previousCourseSessionInProgress(4, sessionCourses))}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    const handleOnSettingsChange = React.useCallback((currSettings: SettingsBarConfiguration[]) => setSettings(currSettings), []);

    const handleDayClick = (day: Date) => {
        if (planningInProgress) {
            toast.warning("Calendar cannot be edited whilst Admin are planning courses.");
            return;
        }

        const momentDate = moment.utc([day.getFullYear(), day.getMonth(), day.getDate()]).startOf("day");
        const periods = getAvailablePeriods();
        const periodsAreUnavailable = periods.includes(Sessions.Unavailable);

        if (periods.filter(p => p <= 4).length === 0) {
            setShowMessage(true);
            toast.error("You need to select the number of session to change availability");
            return;
        }

        if (availabilityType <= 0 && !periodsAreUnavailable) {
            toast.error("You need to select a delivery type to change availability");
            return;
        }

        if (availableDates.some(availability => availability.date.isSame(momentDate))) {
            handleExistingAvailability(momentDate, periods, periodsAreUnavailable);
        } else if (!periodsAreUnavailable) {
            addAvailability(momentDate, periods);
        }
    };

    const getAllMultiDaysFromEI = (eventInstance: EventInstance) => {
        return eventInstances.filter(e => e.groupId && e.groupId === eventInstance.groupId);
    };

    const handleDayClickInViewMode = (day: Date) => {
        const momentDate = moment.utc([day.getFullYear(), day.getMonth(), day.getDate()]).startOf("day");
        const requestedEvents = eventInstances.filter(x => x.startDate.startOf("day").isSame(momentDate));
        const key = "id";
        const requestedEventsWithAllMultiDays = requestedEvents.flatMap((ei) => ei.groupId ?
            getAllMultiDaysFromEI(ei) :
            ei);

        setScheduledEvents(requestedEventsWithAllMultiDays.length > 1 ?
            [...Array.from(new Map(requestedEventsWithAllMultiDays.map(item =>
                [item[key], item])).values())]
            :
            requestedEventsWithAllMultiDays
        );
    };

    const handleExistingAvailability = (date: moment.Moment, periods: number[], unavailable: boolean) => {
        const availability = { ...trainerAvailability.availableDates?.find(x => x.date.isSame(date)) };
        const currentAvailableSessionsPeriods = availability.sessionAvailabilities;
        let newSessionAvailabilities = availability.sessionAvailabilities;
        for (const a of periods) {
            if (unavailable && currentAvailableSessionsPeriods.map(s => s.session).includes(a)) {
                newSessionAvailabilities = newSessionAvailabilities.filter(x => x.session !== a);
            } else if (!unavailable && !currentAvailableSessionsPeriods.find(s => s.session === a && s.type === availabilityType)) {
                newSessionAvailabilities = newSessionAvailabilities.filter(x => x.session !== a);
                newSessionAvailabilities.push({ session: a, type: availabilityType });
            }
        }
        availability.sessionAvailabilities = newSessionAvailabilities;
        availability.pendingUpdate = true;

        setShowMessage(false);
        setTrainerAvailability({
            ...trainerAvailability,
            availableDates: [...trainerAvailability.availableDates.filter(a => !a.date.isSame(date)), availability]
        });
    };

    const addAvailability = (date: moment.Moment, periods: number[]) => {
        setShowMessage(false);
        const newAvailability: TrainerAvailability = {
            ...trainerAvailability,
            availableDates: [
                ...trainerAvailability.availableDates,
                {
                    date,
                    sessionAvailabilities: periods.map(p => ({ session: p, type: availabilityType })),
                    pendingUpdate: true
                }]
        };

        setTrainerAvailability(newAvailability);
    };

    const getAvailablePeriods = () => {
        return settings.filter(x => x.value === true).map(elem => elem.enumValue);
    };

    return (
        <Container textAlign={"left"}>
            <Grid>
                {showMessage && <span className="error">You need to tick at least one of the checkboxes below.</span>}
                {editMode &&
                    <Grid.Row>
                        <SettingsBar
                            planningStarted={planningInProgress}
                            cancel={cancel} save={save}
                            settings={settings}
                            onChange={handleOnSettingsChange}
                            onAvailabilityTypeChange={setAvailabilityType}
                            availabilityType={availabilityType}
                        />
                    </Grid.Row>
                }
                <Authorize authorize={[TrainerRole]}>
                    <Grid.Row className="button-row">
                        <Grid.Column>
                            {editMode && planningInProgress && <Message
                                info
                                header="Please note - changes can not currently be made to your availability for this month
                                            as Admin are currently planning courses"
                                visible
                            />
                            }

                            {!editMode &&
                                <>
                                    <div>
                                        <h1>Calendar</h1>
                                        <h5>Welcome to your availability calendar. Please use this functionality
                                            to update us on when you are available or unavailable to work.
                                        </h5>
                                    </div>
                                    <br />
                                    <br />
                                    <Button onClick={enterEditMode}>EDIT YOUR CALENDAR</Button>
                                </>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Authorize>
                <Grid.Row>
                    <Grid.Column only="tablet mobile" ><ResponsiveLegend mobile={true} area={area} /></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column only="computer" ><ResponsiveLegend mobile={false} area={area} /></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={16} mobile={16}>
                        <DayPicker
                            selected={availableDates && availableDates.map(availability => availability.date.toDate())}
                            onDayClick={editMode ? handleDayClick : handleDayClickInViewMode}
                            onMonthChange={onMonthChange}
                            components={{ DayContent: renderDay, Caption: DayPickerHeader }}
                            month={calendarDate?.toDate()}
                            weekStartsOn={1}
                        />
                    </Grid.Column>
                </Grid.Row>
                {
                    !editMode &&
                    <AllItems
                        area={area}
                        eventInstances={scheduledEvents}
                        groups={eventInstanceGroups}
                        trainerId={trainer?.id ?? currentUser?.id}
                    />
                }
            </Grid>
        </Container>
    );
};
