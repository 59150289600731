import * as React from "react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Container, Dropdown, Header, DropdownProps, Divider } from "semantic-ui-react";
import { bulkSendEmails } from "../../actions";
import { toast } from "@common/toasts";
import { BulkEmailConfirmationModal } from "./BulkEmailConfirmationModal";
import { useDispatch } from "react-redux";
import { useAdHocTemplates, AdhocTemplateType } from "@common/hooks/useAdHocTemplates";
import { EmailApi } from "@common/email/emailApi";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";

export const BulkEmailAction: React.FC<SubActionModalProps> = ({
    triggerDispatchAction,
    setActionComplete,
    selectedEventInstanceIds,
    setTriggerDispatchAction
}) => {
    const templates = useAdHocTemplates(AdhocTemplateType.Email);
    const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>();
    const [selectedTemplateName, setSelectedTemplateName] = React.useState<string>();
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [templateBody, setTemplateBody] = React.useState<string>();
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                if (!selectedTemplateId) {
                    toast.warning("You need to chose the template");
                    return setTriggerDispatchAction && setTriggerDispatchAction(false);
                }

                setSelectedTemplateName(templates.find(x => x.value === selectedTemplateId)?.text);
                setOpenConfirmation(true);
            }
        }, [triggerDispatchAction, selectedTemplateId, templates, setTriggerDispatchAction]);

    React.useEffect(
        () => {
            async function getTemplate() {
                if (selectedTemplateId) {
                    setTemplateBody("");
                    const emailApi = new EmailApi();

                    const response = await emailApi.getEmailTemplate(selectedTemplateId);
                    setTemplateBody(response);
                }
            }

            getTemplate();
        },
        [selectedTemplateId]
    );

    const onAccept = React.useCallback(() => {
        if (selectedTemplateId === undefined || selectedTemplateName === undefined || selectedEventInstanceIds === undefined) {
            throw new Error(`Can't send emails without valid template ${selectedTemplateName} ${selectedTemplateId} ${selectedEventInstanceIds}`);
        }

        dispatch(bulkSendEmails(selectedTemplateId, selectedTemplateName, selectedEventInstanceIds));
        setActionComplete(true);
    }, [selectedEventInstanceIds, selectedTemplateId, selectedTemplateName, dispatch, setActionComplete]);

    const onCancel = React.useCallback(() => {
        if (setTriggerDispatchAction !== undefined) {
            setTriggerDispatchAction(false);
        }

        setOpenConfirmation(!openConfirmation);
    }, [openConfirmation, setTriggerDispatchAction]);

    const onChange = React.useCallback((_: any, { value }: DropdownProps) => {
        setSelectedTemplateId(value as number);
    }, []);

    return (
        <Container>
            <Header size="small">Please choose the template that you'd like to send.</Header>
            <Dropdown
                placeholder='Select email template'
                fluid
                selection
                options={templates}
                onChange={onChange}
            />
            <Container>
                <Divider />
                {!selectedTemplateId && !templateBody && <span>In order to see preview please select template.</span>}
                {selectedTemplateId && !templateBody && <span>Loading template.</span>}
                {selectedTemplateId && templateBody && <HtmlRenderer value={templateBody} />}
                <Divider />
            </Container>
            <BulkEmailConfirmationModal
                eventInstanceIds={selectedEventInstanceIds}
                onCancel={onCancel}
                open={openConfirmation}
                onConfirm={onAccept}
                templateName={selectedTemplateName}
            />
        </Container >
    );
};
