/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, PaginationProps } from "semantic-ui-react";
import { eventInstancesWithOrdersSelector, ordersSearchOptionsSelector } from "../selectors";
import { OrderBaseProps } from "./Base";
import { loadEventInstancesWithOrders } from "../actions";
import { SearchOptions } from "../model";
import { StringValues } from "@common/model";
import { push } from "redux-little-router";
import { loadEventTypeForRouteWorkflowTypes } from "@common/crud/eventType/actions";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { compare } from "@common/helpers/compare";
import { TTCPagination } from "@common/components/TTCPageination";
import { AllEventInstancesCheckable } from "./AllEventInstancesCheckable";
import { OrdersAndEventInstancesFilters } from "./OrdersAndEventInstancesFilters";
import "./All.scss";
import "./Rebook.scss";
import { OrderApi } from "../orderApi";
import { prepareOrdersUrl } from "../helpers";
import { businessLineTypeSelector, universalEventManagementPathSelector } from "@common/redux-helpers";

export interface RebookSelectProps extends OrderBaseProps {
    orderId?: string;
    eventInstanceId?: string;
}

export const Rebook = (props: RebookSelectProps) => {
    const [selectedEventInstances, setSelectedEventInstances]
        = React.useState<{ id: string; selectedAttendees: string[]; attendeesNotExpanded?: boolean }[]>([]);

    const { corporateOrganisationId, corporateUserId, orderId, eventInstanceId } = props;

    const [searchedSearchOptions, setSearchedSearchOptions] = React.useState<SearchOptions>({});

    const [rebookingInProgress, setRebookingInProgress] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);
    const eventInstancesWithOrdersState = useSelector(eventInstancesWithOrdersSelector);
    const searchOptions = useSelector(ordersSearchOptionsSelector);
    const hydratedSearchOptions = React.useMemo(() => (
        (corporateOrganisationId || corporateUserId)
            ? {
                ...searchOptions,
                businessLineType,
                organisationId: corporateOrganisationId,
                userId: corporateUserId,
                justForOrderId: orderId,
                justForEventInstanceId: eventInstanceId,
            } : searchOptions), [businessLineType, corporateOrganisationId, corporateUserId, eventInstanceId, orderId, searchOptions]);

    React.useEffect(() => {
        dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: WorkflowTypeEnum.CPC }));
    }, [dispatch]);

    const onOptionsChanged = React.useCallback(
        (options: SearchOptions) => {
            const query: StringValues<SearchOptions> = {
                showOrdersBy: "0",
                maxPageSize: "100",
                page: options.page?.toString() || "0",
                organisationId: corporateOrganisationId,
                userId: corporateUserId,
                corporateOrganisationIds: options.corporateOrganisationIds?.join(",") || undefined,
                eventInstanceId: options.eventInstanceId || undefined,
                bookingReference: options.bookingReference || undefined,
                bookerName: options.bookerName || undefined,
                eventTypeIds: options.eventTypeIds?.join(",") || undefined,
                fromDate: options.fromDate?.format("YYYY-MM-DD") || undefined,
                toDate: options.toDate?.format("YYYY-MM-DD") || undefined,
                showCancelled: options.showCancelled ? "true" : "false",
                showCompleted: options.showCompleted ? "true" : "false",
            };
            dispatch(push({ pathname: undefined, query }));
        },
        [corporateOrganisationId, corporateUserId, dispatch]
    );

    React.useEffect(() => {
        if (!compare(hydratedSearchOptions, searchedSearchOptions)) {
            dispatch(loadEventInstancesWithOrders({ options: hydratedSearchOptions }));
            setSearchedSearchOptions(hydratedSearchOptions);
        }
    }, [dispatch, hydratedSearchOptions, searchedSearchOptions]);

    const totalElements = React.useMemo(() => eventInstancesWithOrdersState.total, [eventInstancesWithOrdersState.total]);

    const totalPages = React.useMemo(() => {
        return totalElements
            ? Math.ceil(totalElements / searchedSearchOptions?.maxPageSize)
            : 1;
    }, [searchedSearchOptions?.maxPageSize, totalElements]);

    const onPageChange = React.useCallback((_: any, { activePage }: PaginationProps) => {
        onOptionsChanged({ ...searchedSearchOptions, page: +activePage - 1 });
    }, [onOptionsChanged, searchedSearchOptions]);

    const onContinue = React.useCallback(async () => {
        setRebookingInProgress(true);
        const api = new OrderApi();
        await api.startRebooking(businessLineType, selectedEventInstances, corporateOrganisationId, corporateUserId);
        dispatch(push(prepareOrdersUrl(eventManagementPath, "orders/selectRebooking", corporateOrganisationId, corporateUserId)));
        setRebookingInProgress(false);
    }, [corporateOrganisationId, corporateUserId, dispatch, selectedEventInstances, businessLineType, eventManagementPath]);

    return (
        <>
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <div className="title-general">
                        <h1>Rebook Attendees</h1>
                    </div>
                </Grid.Row>
            </Grid>
            {!orderId && (
                <OrdersAndEventInstancesFilters
                    corporateUserId={corporateUserId}
                    onOptionsChanged={onOptionsChanged}
                    searchOptions={hydratedSearchOptions}
                />
            )}
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <AllEventInstancesCheckable
                            corporateOrganisationId={corporateOrganisationId}
                            corporateUserId={corporateUserId}
                            orderId={orderId}
                            eventInstanceId={eventInstanceId}
                            bookingReference={searchOptions.bookingReference}
                            bookerName={searchOptions.bookerName}
                            eventInstances={eventInstancesWithOrdersState.eventInstancesWithOrders}
                            selectedEventInstances={selectedEventInstances}
                            setSelectedEventInstances={setSelectedEventInstances}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {totalPages > 1 && (
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <TTCPagination
                                activePage={hydratedSearchOptions?.page + 1 || 1}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
            <div className="rebook-button-panel">
                <Button content="Choose new courses" onClick={onContinue} disabled={selectedEventInstances.length === 0 || rebookingInProgress} />
            </div>
        </>
    );
};
