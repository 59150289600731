import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { AppCommonState } from "@common/appCommonState";
import { bookingIdSelector, pathSelector, currentAppSelector } from "../selectors";
import { goBack, push } from "redux-little-router";
import { GenesysSkipAuthorisationButton } from "./GenesysSkipAuthoristionbutton";
import { PaymentPageNames, PaymentPageNamesEnum, PaymentState } from "@common/payments/model";
import { GenesysApi } from "../genesysApi";
import { Apps } from "@common/model";
import { attendeeSelector } from "@common/crud/attendee/selectors";
import { LocalStateKeys, LocalStateKeysEnum } from "../model";
import { toQueryString } from "@common/global/ToQueryString";

export const GenesysAuthorisation: React.FC = () => {

    const isClientAdvisor = useSelector(isUserClientAdvisor);

    const { internalAppSettings, genesysSettings, settingsLoaded } = useSelector((state: AppCommonState) => {
        return {
            internalAppSettings: state.appSettings.internalAppSettings,
            genesysSettings: state.appSettings.genesysSettings,
            settingsLoaded: state.appSettings.loaded
        };
    });

    const dispatch = useDispatch();

    const path = useSelector(pathSelector);

    const bookingId = useSelector(bookingIdSelector);

    const attendee = useSelector(attendeeSelector);

    const correlationId = bookingId ?? attendee.correlationId;

    const currentApp = useSelector(currentAppSelector);

    const startPaymentProcess = useSelector((state: PaymentState) => state.startPaymentProcess);

    // We assume the payment page is on the same base url as the current page
    const paymentPage = `${path.substring(0, path.lastIndexOf("/") + 1)}${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`;

    // We hook up to the browser window messages so that the iFrame can tell us when its operation is complete
    React.useEffect(() => {
        const handler = async (event: any) => {
            try {
                const message = JSON.parse(event.data);
                if (message.token && !message.error) {
                    // Send token to the server
                    const genesysApi = new GenesysApi();
                    await genesysApi.updateToken({ token: message.token, validUntil: message.validUntil });

                    // We have a token so proceed to payment page
                    dispatch(push(paymentPage));
                }
            } catch (error) {
                // do nothing
            }
        };

        window.addEventListener("message", handler);

        // Unregister from event handler when component exits
        return () => window.removeEventListener("message", handler);
    }, [dispatch, paymentPage, correlationId]);

    // If Either token is still valid or not a CA or Genesys config is not correct we just redirect to payment page
    const justRedirectToPayment = !isClientAdvisor || (settingsLoaded && !genesysSettings.genesysSetupAndEnabled);

    const fromBookingDetails = window.localStorage.getItem(LocalStateKeys[LocalStateKeysEnum.BeforeGenesysAuthPage]);
    const backButtonUsed = !fromBookingDetails;

    // If we don't have the response from startPaymentProcess in state then a browser refresh has occurred
    const pageRefreshed = !startPaymentProcess?.correlationId;

    // We use this effect to navigate away from this page if we don't actually need to authenticate.
    React.useEffect(() => {
        if (backButtonUsed) {
            // If they have come to this page and we haven't come from the payment details page we assume user has used the browser back button
            // from the payment page. We do an 2nd back to skip the showing the genesys auth page which stops us just redirecting to the payment page once more.
            dispatch(goBack());
        }
        else if (justRedirectToPayment) {
            dispatch(push(paymentPage));
        }
    }, [dispatch, justRedirectToPayment, paymentPage, backButtonUsed]);

    if (backButtonUsed || justRedirectToPayment || !settingsLoaded) {
        // We don't need to go to authorise on Genesys so break out of component.
        return null;
    }

    // This is where we tell Genesys go to when authorisation is complete
    const redirectBase = currentApp === Apps.Admin ? internalAppSettings.adminAppEndpoint : internalAppSettings.bookingAppEndpoint;

    const redirectUrl = `${redirectBase}${PaymentPageNames[PaymentPageNamesEnum.GenesysRedirect]}`;

    const queryParameters = toQueryString({ response_type: "token", client_id: genesysSettings.clientId, redirect_uri: redirectUrl });

    // Host an iframe containing the Genesys auth page. It will redirect back to us once complete
    // The advantage of using an iFrame is that we can allow the agent to carry on regardless if Genesys is down (or they can't login)
    return (
        <>
            {!pageRefreshed
                ? <>
                    <iframe src={`${genesysSettings.genesysAuthorisationEndpoint}?${queryParameters}`} className="genesys-login" />
                    <GenesysSkipAuthorisationButton paymentPage={paymentPage} />
                </>
                : <>
                    <h2>Page refreshed, please use the back button until you return to the
                        {currentApp === Apps.Admin ? " Manual Payment" : " Booking Details"} page</h2>
                </>
            }
        </>
    );
};
