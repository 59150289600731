import * as React from "react";
import { Container, Form } from "semantic-ui-react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Input } from "@neworbit/simpleui-input";
import { rangeValidator } from "@common/validation";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { useDispatch } from "react-redux";
import { bulkUpdateDorsOpenPlacesCount } from "../../actions";

interface ChangeOpenPlacesActionProps {
    deliveryType: DeliveryTypeEnum;
    setDisableContinue: (value: boolean) => void;
}

export const ChangeOpenPlacesAction: React.FC<SubActionModalProps & ChangeOpenPlacesActionProps> =
({ triggerDispatchAction, selectedEventInstanceIds, setActionComplete, deliveryType, setDisableContinue }) => {
    const [newPlacesCount, setNewPlacesCount] = React.useState(deliveryType === DeliveryTypeEnum.Digital ? 9 : 24);
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                dispatch(bulkUpdateDorsOpenPlacesCount(selectedEventInstanceIds, newPlacesCount, deliveryType));
                setActionComplete(true);
            }
        },
        [deliveryType, dispatch, newPlacesCount, selectedEventInstanceIds, setActionComplete, triggerDispatchAction]);

    React.useEffect(() => setDisableContinue(!(newPlacesCount >= 0 && newPlacesCount <= 99)), [newPlacesCount, setDisableContinue]);

    return (
        <Container>
            <Form>
                <p>How many spaces would you like to update these courses to?</p>
                <Input.Number validation={rangeValidator(0, 99)} value={newPlacesCount} onChange={setNewPlacesCount} label="Open Places" />
            </Form>
        </Container >
    );
};
