/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_CONFIGURATION_SUCCESS = "LOAD_CONFIGURATION_SUCCESS";
export type LOAD_CONFIGURATION_SUCCESS = typeof LOAD_CONFIGURATION_SUCCESS;

export const SAVE_SETTING = "SAVE_SETTING";
export type SAVE_SETTING = typeof SAVE_SETTING;

export const SAVE_SETTING_SUCCESS = "SAVE_SETTING_SUCCESS";
export type SAVE_SETTING_SUCCESS = typeof SAVE_SETTING_SUCCESS;

export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export type DELETE_SETTING_SUCCESS = typeof DELETE_SETTING_SUCCESS;

export const LOAD_ORG_LOGO_SUCCESS = "LOAD_ORG_LOGO_SUCCESS";
export type LOAD_ORG_LOGO_SUCCESS = typeof LOAD_ORG_LOGO_SUCCESS;
