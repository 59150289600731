import * as React from "react";
import { TestSettings as TestSettingsDictionary } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const TestSettings: ConfigurationAreaComponent<TestSettingsDictionary> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="TestSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "TrainerHandbackExpirationInHours", label: "Trainer handback expiration (hours)" },
                { name: "FakeDors", label: "Use Fake DORS", inputType: InputType.Toggle },
                { name: "FakeDeletionDateToAnonymizeTrainer", label: "Add days from expiry date to anonymize trainer", inputType: InputType.Number },
                {
                    name: "IgnoreSchemesWithInvalidIdsWhenSyncingTrainer", label: "Ignore schemes with invalid IDs when syncing trainer",
                    inputType: InputType.Toggle
                },
                {
                    name: "TrainerExpiryWarningDaysThreshold",
                    label: "Days without logging in for Trainer to get expiry warning notification",
                    inputType: InputType.Number
                },
                { name: "TrainerExpiryDaysThreshold", label: "Days without logging in for Trainer to get expired", inputType: InputType.Number },
                {
                    name: "AttendanceRegisterCompleteTimerMinutes",
                    label: "Police and Court Register Processing duration (minutes)",
                    inputType: InputType.Number
                },
                {
                    name: "CorporateAttendanceRegisterCompleteTimerMinutes",
                    label: "Commercial Register Processing duration (minutes)",
                    inputType: InputType.Number },
                {
                    name: "AttendanceRegisterResubmitTimerMinutes",
                    label: "Commercial Resubmitted Register Processing duration (minutes)",
                    inputType: InputType.Number
                },
                {
                    name: "AttendanceRegisterWaitTimerMinutes",
                    label: "Register next check wait time (minutes)",
                    inputType: InputType.Number
                },
                {
                    name: "DaysToWaitBeforeDeletingDetachedDelegates",
                    label: "Days to wait before deleting Detached Delegates",
                    inputType: InputType.Number
                },
                {
                    name: "RequireTrainerPromiseInvoiceReminder",
                    label: "Require Trainer Promise Invoice Reminder",
                    inputType: InputType.Toggle
                },
                {
                    name: "DisableZoomAccountAllocationValidation",
                    label: "Disable Zoom Account Allocation Validation",
                    inputType: InputType.Toggle
                },
                {
                    name: "AllowUnknownStatusDorsBookingToRebook",
                    label: "Allow unknown status DORS booking to rebook",
                    inputType: InputType.Toggle
                }
            ]}
        />
    );
};
