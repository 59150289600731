import * as React from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Grid, Tab } from "semantic-ui-react";
import { DurationTypeEnum, EventType, ModuleTypeEnum, MultiDayType, ProductCategoryEnum, WorkflowTypeEnum } from "../../model";
import { DisplayEventTypePartAndStartTimesPicker } from "@common/crud/eventType/components/DisplayEventTypePartAndStartTimesPicker";
import { DisplayValueWithEffectiveDate } from "./DisplayValueWithEffectiveDate";
import { isWorkflowConstruction } from "@common/global/CommonHelpers";

export interface OwnProps {
    eventType: EventType;
}

export const ClassroomDetailsTab: React.FC<OwnProps> = ({ eventType }) => {
    const classroomDetails = eventType.classroomEventTypeDetails ?? {};
    const hasEducationDuration = eventType.workflowType === WorkflowTypeEnum.CPC || eventType.productCategory === ProductCategoryEnum.OnRoadWithCpc;
    const canBeMultiDay = eventType.workflowType !== WorkflowTypeEnum.Dors && eventType.moduleType !== ModuleTypeEnum.Both;
    const useSingleDayControls = eventType.workflowType === WorkflowTypeEnum.Dors || classroomDetails.defaultNumberOfClassroomDeliveryDays === 1;
    const isConstruction = isWorkflowConstruction(eventType.workflowType);

    function formatDuration(value: moment.Duration) {
        return value.format(DateFormat.Time, { trim: false });
    }

    const showTheoryAndPracticalDetails = eventType.moduleType === ModuleTypeEnum.Both;

    return (
        <Tab.Pane>
            <Grid>
                <h3>Course Details</h3>
                {canBeMultiDay && <Detail label="Default Number of Classroom Delivery Days"
                    value={classroomDetails.defaultNumberOfClassroomDeliveryDays?.toString()} labelWidth={5} valueWidth={11} />}
                {canBeMultiDay && classroomDetails.defaultNumberOfClassroomDeliveryDays > 1 && <Detail label="Default Multi Day Type"
                    value={MultiDayType[classroomDetails.defaultMultiDayType]} labelWidth={5} valueWidth={11} />}
                { (useSingleDayControls && !showTheoryAndPracticalDetails) &&
                    <DisplayValueWithEffectiveDate
                        label="Event Duration"
                        values={classroomDetails?.eventDurations}
                        valueFormat={formatDuration}
                    />
                }
                { (useSingleDayControls && showTheoryAndPracticalDetails) &&
                <>
                    {eventType.durationType === DurationTypeEnum.Combined && (
                        <DisplayValueWithEffectiveDate
                            label="Event Duration"
                            values={classroomDetails?.theoryEventDurations}
                            valueFormat={formatDuration}
                        />
                    )}
                    {eventType.durationType !== DurationTypeEnum.Combined && (
                        <>
                            <DisplayValueWithEffectiveDate
                                label="Theory Event Duration"
                                values={classroomDetails?.theoryEventDurations}
                                valueFormat={formatDuration}
                            />
                            <DisplayValueWithEffectiveDate
                                label="Practical Event Duration"
                                values={classroomDetails?.practicalEventDurations}
                                valueFormat={formatDuration}
                            />
                        </>
                    )}
                </>
                }
                {(useSingleDayControls && hasEducationDuration) &&
                    <DisplayValueWithEffectiveDate
                        label="Education Duration"
                        values={classroomDetails?.educationDurations}
                        valueFormat={formatDuration}
                    />
                }
                {useSingleDayControls && (
                    <DisplayValueWithEffectiveDate
                        label="Registration Duration"
                        values={classroomDetails?.classroomRegistrationDurations}
                        valueFormat={formatDuration}
                    />)}
                <h3>Course Planning</h3>
                {!showTheoryAndPracticalDetails &&
                    <DisplayEventTypePartAndStartTimesPicker
                        eventTypeParts={classroomDetails.classroomEventTypeParts}
                        workflowType={eventType.workflowType}
                        defaultNumberOfDeliveryDays={classroomDetails.defaultNumberOfClassroomDeliveryDays}
                        suggestedStartTimesForSessions={classroomDetails.suggestedStartTimesForSessions}
                    />
                }
                {(useSingleDayControls && showTheoryAndPracticalDetails) &&
                <>
                    {eventType.durationType === DurationTypeEnum.Combined && (
                        <DisplayEventTypePartAndStartTimesPicker
                            eventTypeParts={classroomDetails.classroomEventTypeParts}
                            workflowType={eventType.workflowType}
                            suggestedStartTimesForSessions={classroomDetails.suggestedStartTimesForTheorySessions}
                            deliveryTypeDescription="Classroom"
                        />
                    )}
                    {eventType.durationType !== DurationTypeEnum.Combined && (
                        <>
                            <DisplayEventTypePartAndStartTimesPicker
                                eventTypeParts={classroomDetails.classroomEventTypeParts}
                                workflowType={eventType.workflowType}
                                suggestedStartTimesForSessions={classroomDetails.suggestedStartTimesForTheorySessions}
                                deliveryTypeDescription="Theory"
                            />
                            <DisplayEventTypePartAndStartTimesPicker
                                eventTypeParts={classroomDetails.classroomEventTypeParts}
                                workflowType={eventType.workflowType}
                                suggestedStartTimesForSessions={classroomDetails.suggestedStartTimesForPracticalSessions}
                                deliveryTypeDescription="Practical"
                            />
                        </>
                    )}
                </>
                }
                <h3>Course Availability</h3>
                <DisplayValueWithEffectiveDate
                    label="Max number of classroom attendees"
                    values={classroomDetails.maxNumberOfAttendeesWithEffectiveDate}
                />
                <DisplayValueWithEffectiveDate
                    label="Max Number Of Attendees Per Classroom Theory Trainer"
                    values={classroomDetails.maxNumberOfAttendeesPerTrainerWithEffectiveDate}
                />
                {(isConstruction && eventType.showInOpenBookingApp) && (
                    <>
                        <h3>Break Even Points</h3>
                        <DisplayValueWithEffectiveDate
                            label="Classroom break even points"
                            values={classroomDetails?.classroomBreakEvenPoints} />
                    </>
                )}
                <h3>Terms and Conditions</h3>
                <Detail label="Terms and Conditions Link (En)" value={classroomDetails.termsAndConditionsLinkEn} labelWidth={5} valueWidth={11} />
                <Detail label="Terms and Conditions Link (Cy)" value={classroomDetails.termsAndConditionsLinkCy} labelWidth={5} valueWidth={11} />
            </Grid>
        </Tab.Pane>
    );
};
