import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { Modal } from "@common/components";
import { Button, Form, Message } from "semantic-ui-react";
import { LabelWithValueAndAction } from "@common/global/LabelWithValueAndAction";
import { AttendanceIdValidity, AttendanceIdValidityModel, DataToUseFromDorsForAttendeeModel, UpdateAttendeeFromDorsModel } from "../../model";
import { updateAttendanceId } from "@common/crud/attendee/actions";
import { DorsAttendanceStatusesEnum, DorsAttendanceStatusesKeys, UpdateAttendanceIdResponse } from "@common/crud/dorsBooking/model";
import { validateAttendanceIdUpdate } from "../../actions";
import { AttendeeDifferenceModalComponent } from "./AttendeeDifferenceComponent";
import { UpdateAttendanceIdRequest, UpdateAttendanceIdWithDifferencesRequest } from "../../../dorsBooking/model";
import { toast } from "@common/toasts";
import { Input } from "@neworbit/simpleui-input";
import { createValidator } from "not-valid";
import { DorsBookingApi } from "@common/crud/dorsBooking";

const MaxAttendanceId = 2147483647; // int.MaxValue

interface EditProps {
    attendanceId: number;
    eventInstanceId?: string;
    attendeeId?: string;
    dorsBookingId?: string;
}

function getAttendanceValidityMessage(validityModel: AttendanceIdValidityModel) {
    if (!validityModel) {
        return "";
    }

    switch (validityModel.validity) {
        case AttendanceIdValidity.UsedByAttendee:
            return <p>Attendee has been found with the same Attendance ID</p>;
        case AttendanceIdValidity.UsedByOfferWithDifferentDetails:
            return (
                <>
                    <p>Offer has been found with the same Attendance ID and the following differences:</p>
                    <ul>{validityModel.differingFields.map((field, index) => <li key={index}>{field.replace(/([a-z])([A-Z])/g, "$1 $2")}</li>)}</ul>
                </>
            );
        case AttendanceIdValidity.UsedByOfferWithSameDetails:
            return <p>Offer has been found with the same Attendance ID and the same Ticket ID</p>;
        case AttendanceIdValidity.LowerThanLowestOneInDb:
            return <p>Given Attendance Id is not valid</p>;
        default:
            return "";
    }
}

function attendanceValidityIdReusable(validityModel: AttendanceIdValidityModel) {
    const validity = validityModel?.validity;
    return validity === AttendanceIdValidity.UsedByOfferWithDifferentDetails || validity === AttendanceIdValidity.UsedByOfferWithSameDetails;
}

export const AttendanceIdLabelWithEdit: React.FC<EditProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [differencesOpen, setDifferencesOpen] = React.useState<boolean>(false);
    const [newDorsBookingId, setNewDorsBookingId] = React.useState<string>("");
    const [updateAttendeeFromDorsModel, setUpdateAttendeeFromDorsModel] = React.useState<UpdateAttendeeFromDorsModel>(null);
    const [attendanceId, setAttendanceId] = React.useState(props.attendanceId);
    const [attendanceIdValid, setAttendanceIdValid] = React.useState<boolean>(true);
    const [attendanceValidity, setAttendanceValidity] = React.useState<AttendanceIdValidityModel>(null);
    const [verifying, setVerifying] = React.useState(false);
    const [verifiedId, setVerifiedId] = React.useState<number>();
    const [dorsStatus, setDorsStatus] = React.useState<DorsAttendanceStatusesEnum>();
    const [olderBookingExists, setOlderBookingExists] = React.useState<boolean>(null);

    const dispatch = useDispatch();

    const isClientAdvisor = useSelector(isUserClientAdvisor);

    useEffect(() => {
        setAttendanceId(props.attendanceId);
    }, [props.attendanceId]);

    const closeModals = (refreshPage: boolean = false) => {
        setOpen(false);
        setConfirmOpen(false);
        setDifferencesOpen(false);

        if (refreshPage) {
            window.location.reload();
        }
    };

    const cancelUpdate = React.useCallback(() => {
        closeModals();
        setAttendanceId(props.attendanceId);
    }, [props.attendanceId]);

    const validateUpdateResponseReceived = (response: UpdateAttendanceIdResponse) => {

        if (response.noMatchingBookingFound) {
            toast.warning("Attendance ID cannot be found. Please ensure the DORS record has been registered and shared");
            return;
        }

        if (response.success) {
            closeModals();
            sendUpdate(null);
        } else {
            if (!response.attendanceIdChanged) {
                toast.warning("You must enter a different attendance ID to update it!");
            }

            if (response.attendanceIdValidityModel) {
                setAttendanceValidity(response.attendanceIdValidityModel);
                setConfirmOpen(true);
            } else {
                setUpdateAttendeeFromDorsModel(response.updateAttendeeFromDorsModel);
                setNewDorsBookingId(response.newDorsBookingId);
                setDifferencesOpen(true);
            }
        }
    };

    const validateUpdate = async (allowReuse: boolean) => {
        setUpdating(true);

        const updateRequest: UpdateAttendanceIdRequest = {
            eventInstanceId: props.eventInstanceId,
            attendeeId: props.attendeeId,
            correlationId: props.dorsBookingId,
            attendanceId,
            allowReuse
        };

        await dispatch(validateAttendanceIdUpdate(updateRequest, validateUpdateResponseReceived));
        setUpdating(false);
    };

    const sendUpdate = async (submitModel: DataToUseFromDorsForAttendeeModel) => {
        const updateRequest: UpdateAttendanceIdWithDifferencesRequest = {
            eventInstanceId: props.eventInstanceId,
            attendeeId: props.attendeeId,
            correlationId: props.dorsBookingId,
            attendanceId,
            newDorsBookingId,
            submitModel
        };

        const refreshPage = !!submitModel ?? false;

        await dispatch(updateAttendanceId(updateRequest, () => closeModals(refreshPage)));
    };

    function sendUpdateWithoutReuse() { validateUpdate(false); }
    function sendUpdateWithReuse() { validateUpdate(true); }
    function sendUpdateWithDifferences(submitModel: DataToUseFromDorsForAttendeeModel) { sendUpdate(submitModel); }
    function onAttendanceIdChange(value: number, valid: boolean) {
        setAttendanceId(value);
        setAttendanceIdValid(valid);
    }
    function toggleDialog() { setOpen(!open); };

    function maxAttendanceIdValidator() {
        return createValidator<number>(v => v < MaxAttendanceId, `Maximum allowed attendance id is ${MaxAttendanceId}`);
    };

    async function verifyAttendanceId() {
        setVerifying(true);
        const api = new DorsBookingApi();
        const verified = await api.checkAttendanceIdDorsStatus(attendanceId);
        setDorsStatus(verified);
        setVerifiedId(attendanceId);
        setVerifying(false);
    }

    const attendanceIdVerified = verifiedId === attendanceId;

    React.useEffect(() => {
        async function checkForOldBookings() {
            const api = new DorsBookingApi();
            const olderBooking = await api.checkForOlderBookingsWithMatchingDetails(props.attendanceId);
            setOlderBookingExists(olderBooking);
        }
        if (props.attendanceId && isClientAdvisor) {
            checkForOldBookings();
        }
    }, [props.attendanceId, isClientAdvisor]);

    const newIdHasDifferentScheme = attendanceValidity?.differingFields.includes("SchemeId");

    return (
        <>
            <LabelWithValueAndAction
                action={toggleDialog}
                actionName="Update Attendance ID"
                label="Attendance Id"
                showAction={isClientAdvisor}
                value={props.attendanceId}
            />

            <Modal open={open}>
                <Modal.Header>
                    Update Attendance Id
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Input.Number
                            label="Type new attendance id"
                            value={attendanceId}
                            showErrors={!attendanceIdValid}
                            validation={[maxAttendanceIdValidator()]}
                            onChange={onAttendanceIdChange}
                        />
                    </Form>
                    <Button onClick={verifyAttendanceId} loading={verifying} disabled={verifying}>Verify</Button>
                    {verifiedId &&
                        <Message information>
                            The current dors status for attendance Id <strong>{verifiedId}</strong> is <strong>{DorsAttendanceStatusesKeys[dorsStatus]}</strong>
                        </Message>
                    }
                    {olderBookingExists === true &&
                        <Message color="yellow">
                            Please be aware that there is an older record in the system that is using the same Ticket Id or driving licence.
                        </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={cancelUpdate}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={sendUpdateWithoutReuse}
                        icon="checkmark"
                        content="Save"
                        loading={updating}
                        disabled={updating || !attendanceIdValid || !attendanceIdVerified || olderBookingExists === null}
                    />
                </Modal.Actions>
                <Modal open={confirmOpen}>
                    <Modal.Header>
                        Attendance Id is in use
                    </Modal.Header>
                    <Modal.Content>
                        {newIdHasDifferentScheme ?
                            <p>Attendance ID cannot be changed as new ID has a different scheme</p> :
                            <>
                                {getAttendanceValidityMessage(attendanceValidity)}
                                {attendanceValidity?.driverNumber !== null &&
                                    <p>The matching record's Driver Number: <strong>{attendanceValidity?.driverNumber}</strong></p>}
                                {attendanceValidityIdReusable(attendanceValidity) && <p>Are you sure you want to reuse this Attendance Id?</p>}
                            </>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={cancelUpdate}
                            className="cancel-action"
                        >
                            Cancel
                        </Button>
                        {attendanceValidityIdReusable(attendanceValidity) && !newIdHasDifferentScheme && <Button
                            onClick={sendUpdateWithReuse}
                            icon="checkmark"
                            content="I'm sure"
                            loading={updating}
                            disabled={updating || !attendanceIdValid}
                        />}
                    </Modal.Actions>
                </Modal>
                {updateAttendeeFromDorsModel &&
                    <AttendeeDifferenceModalComponent
                        model={updateAttendeeFromDorsModel}
                        open={differencesOpen}
                        handleSubmit={sendUpdateWithDifferences}
                        handleCancel={cancelUpdate}
                    />
                }
            </Modal>
        </>
    );
};
