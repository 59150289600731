import axios from "axios";
import { AttendeeApi } from "./attendeeApi";
import { parseModel } from "./helpers";
import { AttendeeDetailModel, DocumentType } from "./model";
import { AttendeeListModel } from "@common/crud/attendee";

export class DdrsAttendeeApi extends AttendeeApi {

    public async transferProvider(attendeeId: string, providerName: string) {
        const response = await axios.post<AttendeeDetailModel>(
            `${this.attendeeApi}/${attendeeId}/transfer-provider`, { providerName });

        return parseModel(response.data);
    }

    public async createCertificateForAttendeeInList(attendeeId: string, eventInstanceId: string, documentType: DocumentType ) {
        const response = await axios.post(
            `${this.attendeeApi}/${attendeeId}/create-certificate/list?docType=${documentType}&eventInstanceId=${eventInstanceId}`);

        const data = response.data as AttendeeListModel[];
        return data.map(parseModel);
    }

    public async createCertificateForAttendeeDetail(attendeeId: string, eventInstanceId: string, documentType: DocumentType ) {
        const response = await axios.post<AttendeeDetailModel>(
            `${this.attendeeApi}/${attendeeId}/create-certificate/detail?docType=${documentType}&eventInstanceId=${eventInstanceId}`);

        return parseModel(response.data);
    }
}
