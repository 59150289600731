import axios from "axios";
import { GenesysCallState, GenesysOperationResponse } from "@common/genesys/model";
import { GenesysObjectType } from "./model";

export class OrderGenesysApi {

    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/orderGenesys";
    }

    public async auditOrderAuthorisationBypass(genesysObjectType: GenesysObjectType, correlatedId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${genesysObjectType}/${correlatedId}/auditOrderAuthorisationBypass`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }

    public async pauseCallRecording(genesysObjectType: GenesysObjectType, correlatedId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${genesysObjectType}/${correlatedId}/pauseRecording`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }

    public async getCallState(genesysObjectType: GenesysObjectType, correlatedId: string): Promise<GenesysCallState> {
        const response = await axios.get(`${this.apiUrl}/${genesysObjectType}/${correlatedId}/callstate`);
        return response.data as GenesysCallState;
    }

    public async resumeCallRecording(genesysObjectType: GenesysObjectType, correlatedId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${genesysObjectType}/${correlatedId}/resumeRecording`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }
}
