import * as React from "react";
import { Container } from "semantic-ui-react";

export interface TrainerRemovalWarningComponentProps {
    onTrainerSwapModal?: boolean;
    hasMonitor?: boolean;
    hasExternalAssessmentDue?: boolean;
}

export const TrainerRemovalWarningComponent = ({ onTrainerSwapModal, hasMonitor, hasExternalAssessmentDue }: TrainerRemovalWarningComponentProps) => {
    if (!hasMonitor && !hasExternalAssessmentDue) {
        return null;
    }

    return (
        <Container>
            {hasMonitor && <h3 className="status-attention">IMPORTANT – there is a monitor allocated to this course.</h3>}
            {hasExternalAssessmentDue && <h3 className="status-attention">IMPORTANT – a trainer is due to be externally assessed.</h3>}
            <p>Are you sure you want to {onTrainerSwapModal ? "swap" : "remove"} the trainer?</p>
            <p>If you do, click {onTrainerSwapModal ? "complete swap" : "continue"} to complete this action, or alternatively press cancel.</p>
            <p>You may wish to reschedule the monitoring session if you complete the action to {onTrainerSwapModal ? "swap" : "remove"} the trainer. </p>
            {!onTrainerSwapModal && <br />}
        </Container>
    );
};
