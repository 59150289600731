import { Media } from "@common/global/AppMedia";
import * as React from "react";
import { PaginationProps, Pagination } from "semantic-ui-react";

export const TTCPagination = (props: PaginationProps) => (
    <div className="pagination-container">
        <Media lessThan="pageination">
            <Pagination
                size="mini"
                siblingRange={1}
                boundaryRange={0}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                {...props}
            />
        </Media>
        <Media greaterThanOrEqual="pageination">
            <Pagination
                size="mini"
                siblingRange={2}
                boundaryRange={0}
                ellipsisItem={undefined}
                firstItem={undefined}
                lastItem={undefined}
                {...props}
            />
        </Media>
    </div>
);
