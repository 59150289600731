import { Apps } from "@common/model";
import { DurationTypeEnum, ModuleTypeEnum, ProductCategoryEnum,  WorkflowTypeEnum } from "../eventType/model";
import { HistoryRecord } from "@common/history/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import moment from "moment";
import { Location } from "redux-little-router";

export type PendingEventInstance = PendingEventInstanceListModel & PendingEventInstanceDetailModel;

export interface PendingEventInstanceCreateEditModel {
    id: string;
    corporateOrganisationId?: string;
    corporateOrganisationName?: string;
    productCategory?: ProductCategoryEnum;
    eventTypeId?: string;
    eventTypeName?: string;
    eventTypeAbbreviation?: string;
    venueId?: string;
    venueName?: string;
    deliveryType?: DeliveryTypeEnum;
    startDate?: moment.Moment;
    startTime?: moment.Duration;
    creationNote?: string;
}

export interface CreatePendingEventInstanceResponse {
    pendingEventInstance?: PendingEventInstanceDetailModel;
    pendingEventInstanceCreated?: boolean;
    errorMessage?: string;
}

export interface PendingEventInstanceListModel {
    readonly id: string;
    readonly eventInstanceId?: string;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly productCategory?: ProductCategoryEnum;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly eventTypeAbbreviation?: string;
    readonly venueId?: string;
    readonly venueName?: string;
    readonly dateCreated: moment.Moment;
    readonly startDate?: moment.Moment;
    startTime?: moment.Duration;
    readonly workflowType?: WorkflowTypeEnum;
    readonly moduleType?: ModuleTypeEnum;
    readonly durationType?: DurationTypeEnum;
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly adminNote?: string;
    readonly listUpdated?: moment.Moment;
    readonly status?: PendingEventInstanceStatusEnum;
}

export interface PendingEventInstanceListResponseModel {
    pendingEventInstances: PendingEventInstanceListModel[];
    totalNumberOfPendingEventInstances?: number;
}

export interface PendingEventInstanceDetailModel {
    readonly id: string;
    readonly eventInstanceId?: string;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly productCategory?: ProductCategoryEnum;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly eventTypeAbbreviation?: string;
    readonly eventDuration?: moment.Duration;
    readonly venueId?: string;
    readonly venueName?: string;
    readonly dateCreated: moment.Moment;
    readonly startDate?: moment.Moment;
    startTime?: moment.Duration;
    readonly workflowType?: WorkflowTypeEnum;
    readonly moduleType?: ModuleTypeEnum;
    readonly durationType?: DurationTypeEnum;
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly officeNote?: string;
    readonly adminNote?: string;
    readonly trainerNote?: string;
    readonly creationNote?: string;
    readonly detailUpdated?: moment.Moment;
    readonly status?: PendingEventInstanceStatusEnum;
    readonly cancellationReason?: string;
    history?: HistoryRecord[];
}

export enum PendingEventInstanceStatusEnum {
    Pending = 0,
    Cancelled = 1,
    Converted = 2,
}

export const PendingEventInstanceStatus = {
    [PendingEventInstanceStatusEnum.Pending]: "Pending",
    [PendingEventInstanceStatusEnum.Cancelled]: "Cancelled",
    [PendingEventInstanceStatusEnum.Converted]: "Converted",
};

export interface CancelPendingEventInstanceResponse {
    cancelled?: boolean;
    cancellationReason?: string;
}

export interface ExtendedPendingEventInstance extends PendingEventInstanceListModel {
    path?: string;
    customKey?: string;
}

export enum PendingEventInstanceActionEnum {
    Create = 0,
    Edit = 1,
    Convert = 2,
}

export const PendingEventInstanceAction = {
    [PendingEventInstanceActionEnum.Create]: "Create",
    [PendingEventInstanceActionEnum.Edit]: "Edit",
    [PendingEventInstanceActionEnum.Convert]: "Convert"
};
export interface SearchOptions {
    corporateOrganisationId?: string;
    eventType?: string[];
    deliveryType?: string;
    fromDate?: moment.Moment;
    toDate?: moment.Moment;
    venueId?: string[];
    filtersCleared?: boolean;
    maxPageSize?: number;
    page?: number;
}

export enum PageSizesEnum {
    OneHundred = 100,
    TwoFifty = 250,
    FiveHundred = 500
}

export const PageSizes = {
    [PageSizesEnum.OneHundred]: "100",
    [PageSizesEnum.TwoFifty]: "250",
    [PageSizesEnum.FiveHundred]: "500"
};

export interface PendingEventInstanceState {
    readonly pendingEventInstances: PendingEventInstance[];
    readonly totalNumberOfPendingEventInstances: number;
}

export type AppState = PendingEventInstanceState & { router: Location; app?: Apps };
