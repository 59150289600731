import { useFormikContext } from "formik";
import * as React from "react";
import { CarDetails } from "@common/crud/common/CarDetails";
import { Form } from "semantic-ui-react";
import { SemanticFormField } from "@common/crud/formik/SemanticFormField";

export function SpecialCarDetailsField() {

    const validateSpecialDetails = React.useCallback((value: string) => {
        if (!value) {
            return "Special adaptation details required.";
        }

        if (value.length > 150) {
            return "Special adaptation details must be less than 150 characters.";
        }

        return null;
    }, []);

    const { values } = useFormikContext<CarDetails>();

    if (values.carType !== "Special") {
        return null;
    }

    return (
        <SemanticFormField
            name="specialCarDetails"
            as={Form.TextArea}
            label="Special adaptation details"
            placeholder="Special adaptation details"
            validate={validateSpecialDetails}
        />
    );
}
