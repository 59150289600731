import React, { useMemo } from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { Category } from "@common/appSettings/model";
import { Table } from "semantic-ui-react";

interface CourseCatagoriesProps {
    categories: Category[];
    values: number[];
}

export const CourseCategories = ({ categories, values }: CourseCatagoriesProps) => {
    const value = useMemo(() => {
        const getCategoryInfo = (v: number) => {
            const category = categories.find((c) => c.id === v || c.subCategories.some((sc) => sc.id === v));
            const subCategory = category?.subCategories.find((sc) => sc.id === v);
            return { category, subCategory };
        };

        if (values.length === 0) {
            return <>None</>;
        }

        const info = values.map(getCategoryInfo);
        const tidiedInfo = info.filter(a =>
            a.category !== undefined &&
            (a.subCategory !== undefined ||
            info.filter(b => a.category === b.category && b.subCategory !== undefined).length === 0))
            .sort((a, b) => a.category.title !== b.category.title
                ? a.category.title.localeCompare(b.category.title)
                : a.subCategory.title.localeCompare(b.subCategory.title));

        return (<Table compact>
            {tidiedInfo.map((i, index) => {
                return (
                    <Table.Row key={`cc_${index}`}>
                        <Table.Cell width={7}>{i.category.title}</Table.Cell>
                        {i.subCategory
                            ? <Table.Cell width={1}>&gt;</Table.Cell>
                            : <Table.Cell width={1}>&nbsp;</Table.Cell>
                        }
                        {i.subCategory
                            ? <Table.Cell width={8}>{i.subCategory.title}</Table.Cell>
                            : <Table.Cell width={8}>&nbsp;</Table.Cell>}
                    </Table.Row>);
            })}
        </Table>);
    }, [categories, values]);

    return (<Detail
        label="Course Categories"
        value={value}
        labelWidth={5}
        valueWidth={11}
    />);
};
