import * as React from "react";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { AppCommonState } from "@common/appCommonState";
import "./GenesysChatButtonStyle.scss";

declare global {
    let CXBus: any;
}

export const GenesysChatButton: React.FC = () => {

    const genesysSettings = useSelector((state: AppCommonState) => state.appSettings.genesysSettings);
    const externalResourcesSettings = useSelector((state: AppCommonState) => state.appSettings.externalResourcesSettings);

    CXBus?.configure({
        debug: false,
        // Had to leave this script link in place as couldn't download it from the 3rd party to self-host it
        pluginsPath: "https://apps.euw2.pure.cloud/widgets/9.0/plugins/"
    });

    CXBus?.loadPlugin("widgets-core");

    // Webchat
    (window as any)._genesys = {
        widgets: {
            webchat: {
                transport: {
                    type: "purecloud-v2-sockets",
                    dataURL: "https://api.euw2.pure.cloud",
                    deploymentKey: genesysSettings.deploymentKey,
                    orgGuid: genesysSettings.orgGuid,
                    interactionData: {
                        routing: {
                            targetType: "QUEUE",
                            targetAddress: "Webchat Queue Course Enquiry",
                            priority: 2
                        }
                    }
                },
                userData: {
                    addressStreet: "",
                    addressCity: "",
                    addressPostalCode: "",
                    addressState: "",
                    phoneNumber: "",
                    customField1Label: "",
                    customField1: "",
                    customField2Label: "",
                    customField2: "",
                    customField3Label: "",
                    customField3: ""
                },
                form: {
                    autoSubmit: false,
                    firstname: "",
                    lastname: "",
                    email: "",
                    addressPostalCode: "",
                    reference_number: "",
                    subject: "",
                    wrapper: "<table></table>",
                    inputs: [
                        {
                            id: "cx_webchat_form_firstname",
                            name: "firstname",
                            maxlength: "100",
                            placeholder: "Required",
                            label: "First name",
                            validateWhileTyping: true,
                            validate: (event: any,form: any,input: any,label: any,$: any,CXBus: any,Common: any) =>
                                input
                                    ? (input.val().replace(/\s/g,"") != "")
                                    : true
                        },
                        {
                            id: "cx_webchat_form_lastname",
                            name: "lastname",
                            maxlength: "100",
                            placeholder: "Required",
                            label: "Last name",
                            validateWhileTyping: true,
                            validate: (event: any,form: any,input: any,label: any,$: any,CXBus: any,Common: any) =>
                                input
                                    ? (input.val().replace(/\s/g,"") != "")
                                    : true
                        },
                        {
                            id: "cx_webchat_form_email",
                            name: "email",
                            maxlength: "100",
                            placeholder: "Optional",
                            label: "Email",
                            validateWhileTyping: false,
                            validate: (event: any,form: any,input: any,label: any,$: any,CXBus: any,Common: any) => {
                                if (input) {
                                    const email = input.val();
                                    const regex = /^\S+@@\S+\.\S+$/;
                                    return email == "" || regex.test(email);
                                }
                                return true;
                            }
                        },
                        {
                            id: "cx_webchat_form_addressPostalCode",
                            name: "addressPostalCode",
                            maxlength: "20",
                            placeholder: "Required",
                            label: "Postcode",
                            validateWhileTyping: true,
                            validate: (event: any,form: any,input: any,label: any,$: any,CXBus: any,Common: any) =>
                                input
                                    ? (input.val().replace(/\s/g,"") != "")
                                    : true
                        },
                        {
                            id: "cx_webchat_form_reference_number",
                            name: "reference_number",
                            maxlength: "20",
                            placeholder: "Optional",
                            label: "Reference number",
                            title: `Your reference number may be:\n• 16 digits,which can be found on your course offer letter 
                            from the Police,or\n• 8 digits,which can be found on your TTC confirmation email or Begin N,\n   
                            followed by 7 digits,which can be found on your course offer documentation from TTC`
                        },
                        {
                            id: "cx_webchat_form_subject",
                            name: "subject",
                            label: "Subject",
                            placeholder: "Optional",
                            type: "select",
                            options: [
                                {
                                    text: "", value: ""
                                },
                                {
                                    text: "I need to book or amend a course", value: "Book or amend a course"
                                },
                                {
                                    text: "I need technical support to help me join a course", value: "Technical support"
                                },
                                {
                                    text: "I need further information relating to my course offer", value: "Further information"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    };

    // Channel Selector
    (window as any)._genesys.widgets.channelselector = {
        ewtRefreshInterval: 10,
        channels: [
            {
                enable: true,
                clickCommand: "CallUs.open",
                displayName: "Call Us",
                i18n: "CallUs",
                icon: "call-outgoing",
                html: "",
                ewt: {
                    display: true,
                    queue: "New Booking English",
                    availabilityThresholdMin: 300,
                    availabilityThresholdMax: 480,
                    hideChannelWhenThresholdMax: false
                }
            },
            {
                enable: true,
                clickCommand: "WebChat.open",
                displayName: "Web Chat",
                i18n: "WebChat",
                icon: "chat",
                html: "",
                ewt: {
                    display: true,
                    queue: "New Booking English",
                    availabilityThresholdMin: 300,
                    availabilityThresholdMax: 480,
                    hideChannelWhenThresholdMax: false
                }
            },
            {
                enable: false,
                clickCommand: "SendMessage.open",
                displayName: "Send Message",
                i18n: "SendMessage",
                icon: "email",
                html: ""
            },
            {
                enable: false,
                clickCommand: "Callback.open",
                displayName: "Receive a Call",
                i18n: "Callback",
                icon: "call-incoming",
                html: "",
                ewt: {
                    display: true,
                    queue: "New Booking English",
                    availabilityThresholdMin: 300,
                    availabilityThresholdMax: 480,
                    hideChannelWhenThresholdMax: false
                }
            },
            {
                enable: false,
                name: "CoBrowse",
                clickCommand: "CoBrowse.open",
                displayName: "Co-browse",
                i18n: "Co-browse",
                icon: "cobrowse",
                html: ""
            }
        ]
    };

    // Call us
    (window as any)._genesys.widgets.callus = {
        contacts: [
            {
                displayName: "Call us on",
                i18n: "Number001",
                number: "0330 024 1805"
            }
        ],
        hours: [
            ""
        ]
    };

    // Main
    (window as any)._genesys.widgets.main = {
        i18n: {
            en: {
                callus: {
                    SubTitle: "You can reach us on the following number...",
                    CoBrowseText: `For our up to-date opening hours,please visit our 
                        <a href='${externalResourcesSettings.contactPageLink}'>contact page</a>`
                }
            }
        }
    };

    const customPlugin = CXBus?.registerPlugin("Custom");
    const onOpen = React.useCallback(() => customPlugin?.command("ChannelSelector.open"),[]);

    return (
        <>
            <div className="not-mobile header-contact">
                <Button className="genesys-chat-button" floated="right" onClick={onOpen}>Contact Us</Button>
            </div>
            <div className="mobile-only header-contact">
                <Button className="genesys-chat-button" floated="right" onClick={onOpen}>
                    <img className="contact-icon" src="/assets/phone.svg" />
                </Button>
            </div>
        </>);
};
