import { CorporateHomeRole, TtcTrainerAdminRole } from "@common/auth/model";

import { loadTrainerAttributeDefinitionsFromQuery, loadTrainerAttributeDefinitionDetail } from "./actions";
import { AppState } from "./model";
import { trainerAttributeDefinitionSelector } from "./selectors";
import { detailRoutes } from "./detailRoutes";
import { loadEventTypes } from "@common/crud/eventType/actions";

export const TrainerAttributeDefinitionRoutes = {
    TrainerAttributeDetails: "/:trainerAttributeDefinitionId",
    Edit: "/edit",
    TrainerAttributeDefinitions: "/trainerAttributeDefinitions",
    Create: "/create",
    Trainers: "/trainers",
};

const detailRoute = () => ({
    [TrainerAttributeDefinitionRoutes.TrainerAttributeDetails]: {
        title: (state: AppState) => trainerAttributeDefinitionSelector(state).name,
        resolve: [loadTrainerAttributeDefinitionDetail, loadEventTypes],
        [TrainerAttributeDefinitionRoutes.Edit]: {
            title: "Edit",
            authorize: TtcTrainerAdminRole,
            resolve: [loadEventTypes]
        },
        [TrainerAttributeDefinitionRoutes.Trainers]: {
            title: "Trainers",
            resolve: loadTrainerAttributeDefinitionDetail,
            [TrainerAttributeDefinitionRoutes.Edit]: {
                title: "Edit"
            }
        },
        ...detailRoutes
    }
});

export const routes = {
    [TrainerAttributeDefinitionRoutes.TrainerAttributeDefinitions]: {
        resolve: [loadTrainerAttributeDefinitionsFromQuery, loadEventTypes],
        title: "Trainer Attribute Definitions",
        ignoreParentResolve: true,
        ...detailRoute(),
        [TrainerAttributeDefinitionRoutes.Create]: {
            title: "Create Trainer Attribute Definition",
            authorize: [TtcTrainerAdminRole, CorporateHomeRole]
        }
    }
};
