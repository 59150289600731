import * as React from "react";
import { Grid, TextArea, TextAreaProps, Button, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { toast } from "@common/toasts";
import { addNoteToOrder } from "../actions";
import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";

export interface NewOrderNoteProps {
    orderId: string;
}

export const NewOrderNote: React.FC<NewOrderNoteProps> = ({ orderId }) => {
    const [files, setFiles] = React.useState<FileList>();
    const [noteText, setNoteText] = React.useState<string>("");
    const dispatch = useDispatch();

    const onFileChange = React.useCallback((fileList: FileList) => {
        setFiles(fileList);
    }, []);

    const onTextAreaChange = React.useCallback((e: any, d: TextAreaProps) => {
        setNoteText(d.value.toString());
    }, []);

    const handleOnClick = React.useCallback(async () => {
        if (noteText) {
            await dispatch(addNoteToOrder(orderId, noteText, files && files.length > 0 ? files[0] : undefined));
            setNoteText("");
            setFiles(undefined);
            toast.success("Note added");
        }
    }, [noteText, dispatch, orderId, files]);

    return (
        <Grid className="note-container">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form>
                        <TextArea placeholder={"Enter note"} rows={2} onChange={onTextAreaChange} value={noteText} />
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button className="wide" onClick={handleOnClick} disabled={(noteText?.length || 0) === 0} floated="left" content="Add Note" />
                    <FileUploadInput
                        fileList={files}
                        uploadLabel="Add File"
                        onChange={onFileChange}
                    />
                    {(files && files.length > 0) && <span>{files[0].name}</span>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
