import * as React from "react";
import moment, { Moment } from "moment";
import { Label, Segment } from "semantic-ui-react";
import { StartTimePicker } from "./StartTimePicker";

export interface SuggestedTimesProps {
    values: moment.Duration[];
    requiredAmount: number;
    showError: boolean;
    label?: string;
    addTime: (time: Moment, key: number, session: number) => void;
}

export const SuggestedTimes: React.FC<SuggestedTimesProps> = ({ addTime, values, showError, requiredAmount, label }) => {
    const onAdd = React.useCallback((session: number) => (time: Moment) => {

        addTime(time, requiredAmount, session);
    }, [requiredAmount, addTime]);

    const duplicateTime =  values.length !== new Set(values.map(y => y?.asMilliseconds())).size;

    return (
        <>
            <Segment>
                <h3>{label}</h3>
                {[...Array(requiredAmount)].map((e, index) => {
                    const sessionTime = values[index];

                    const timeAsMoment = sessionTime? moment().startOf("day").add(sessionTime) : null;

                    return (<StartTimePicker
                        time={timeAsMoment}
                        key={`session${index}-${values[index]?.asMilliseconds()}`}
                        handleClick={onAdd(index)}
                        label={`Session ${index + 1}`} />);
                })
                }
            </Segment>
            {
                showError &&
                <>{
                    values.filter(x => x && x.isValid()).length < requiredAmount &&
                    <Label basic color="red" pointing>
                    Please complete all times for these sessions.
                    </Label>}
                {duplicateTime && <Label basic color="red">
                    Start times must be different.
                </Label>}
                </>

            }
        </>
    );
};
