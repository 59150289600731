import { Dispatch } from "redux";
import { replace } from "redux-little-router";

import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actionTypes";
import { CurrentUser } from "./model";

export type CurrentUserAction =
    ({ type: actions.GET_CURRENT_USER_SUCCESS } & Payload<CurrentUser>)
    | { type: actions.SESSION_TIMED_OUT };

export const getCurrentUserSuccess = (payload: CurrentUser): CurrentUserAction => ({
    payload,
    type: actions.GET_CURRENT_USER_SUCCESS
});

export const sessionTimedOut = (): CurrentUserAction => ({
    type: actions.SESSION_TIMED_OUT
});

export const redirectToLogin = (dispatch: Dispatch) => {
    const redirectUrl = encodeURIComponent(location.pathname + location.search);
    const loginUrl = `/login?redirectUrl=${redirectUrl}`;
    return dispatch(replace(loginUrl));
};
