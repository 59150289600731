/* eslint-disable max-lines */
import {
    Area,
    EventStatus,
    PageSizes,
    Language,
    LanguageEnum,
    SearchOptions,
    AppState,
    YesNoAnyOptions,
    YesNoAnyEnum
} from "@common/crud/eventInstance/model";
import { Grid, Form, DropdownProps, CheckboxProps, Button, SemanticWIDTHS, Divider } from "semantic-ui-react";
import * as React from "react";
import moment from "moment";
import { getDateInputFormat } from "@common/dateFormating";
import { Apps, DayOfWeekEnum, DayOfWeek } from "@common/model";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadOrganisations } from "@common/crud/organisation";
import { dsaAreaOptionsSelectorWithAny } from "@common/crud/organisation/selectors";
import { loadVenues } from "@common/crud/venue";
import { sortedDrinkDriveVenueOptionsWithAnySelector } from "@common/crud/venue/selectors";
import { GoToEventInstanceById } from "../GoToEventInstanceById";
import { drinkDriveEventTypeOptionsSelectorWithAny } from "@common/crud/eventType/selectors";
import { optionsFromObject, optionsFromText } from "@common/crud/common";
import { DeliveryType } from "@common/crud/common/DeliveryTypeEnum";
import { AdminEventManagementActions } from "@common/crud/eventInstance/components/AdminEventManagementActions";
import { optionsFromObjectWithString } from "@common/crud/common/optionsMappers";
import { MuiDateField } from "@common/components/MuiDateField";
import { loadEventTypeForRouteWorkflowTypes } from "@common/crud/eventType/actions";
import { BusinessLineType } from "@common/crud/organisation/model";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

interface DrinkDriveFilterProps {
    app: Apps;
    area: Area;
    searchOptions: SearchOptions;
    onOptionsChanged: (query: SearchOptions) => void;
}

interface Filter {
    showInArea: Area[];
    filter: JSX.Element;
}

export const DrinkDriveFilters: React.FC<DrinkDriveFilterProps> = ({ app, area, searchOptions, onOptionsChanged }) => {
    const [stateOptions, setStateOptions] = useState<SearchOptions>({ ...searchOptions });
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);
    const started = "Started";
    const notStarted = "Not Started";
    const dispatch = useDispatch();
    const path = useSelector((state: AppState) => state.router.pathname);

    React.useEffect(() => {
        dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: WorkflowTypeEnum.DDRS }));
        dispatch(loadVenues({ options: { workflows: [WorkflowTypeEnum.DDRS] } }));
    }, [dispatch]);

    React.useEffect(() => {
        if (app === Apps.Admin) {
            dispatch(loadOrganisations({ options: { businessLineType: [BusinessLineType.Police, BusinessLineType.Court] } }));
        }
    }, [app, dispatch]);

    const getProgress = searchOptions.includeAlreadyStartedGroups === true ? started : notStarted;
    const eventTypeOptions = useSelector(drinkDriveEventTypeOptionsSelectorWithAny);
    const dsaAreaOptions = useSelector(dsaAreaOptionsSelectorWithAny);
    const venueOptions = useSelector(sortedDrinkDriveVenueOptionsWithAnySelector);
    const dateInputFormat = getDateInputFormat();

    const availableSeatLabel = searchOptions?.hasAvailableSeats ? "available" : "all";
    const oneToOneOnlyLabel = searchOptions?.oneToOneOnly ? "yes" : "no";

    const onSubmit = React.useCallback(() => {
        const options = { ...searchOptions, ...stateOptions };
        setStateOptions(options);
        onOptionsChanged(options);
    }, [searchOptions, stateOptions, onOptionsChanged]);

    const onInputChanged = (prop: keyof (SearchOptions)) => {
        return (value: any) => {
            if (moment.isMoment(value) && !value.isValid) {
                return;
            }

            const prevValue = searchOptions[prop];

            if (prevValue !== value) {
                setStateOptions({ ...stateOptions, [prop]: value, filtersCleared: false });
            }
        };
    };
    const onCheckboxChanged = (prop: keyof (SearchOptions)) => (event: any, { checked }: CheckboxProps) => onPropertyChanged(prop)(checked as any);
    const onDropdownPropertyChanged = (prop: keyof (SearchOptions)) => (event: any, { value }: DropdownProps) => onPropertyChanged(prop)(value as any);
    const onCourseProgressChanged = (prop: keyof (SearchOptions)) => (event: any, { value }: DropdownProps) => {
        onPropertyChanged(prop)(value === started);
    };

    const onPropertyChanged = (prop: keyof (SearchOptions)) => {
        return (value: any) => {
            const prevValue = stateOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
                onOptionsChanged(options);
            }
        };
    };

    const onMultiDropdownChange = React.useCallback((prop: keyof SearchOptions) => (_: any, { value }: DropdownProps) => {
        const options = { ...stateOptions, [prop]: value };
        setStateOptions(options);
        onOptionsChanged(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateOptions]);

    function onClearFilters() {
        const clearedSearchOptions = {
            ...Object.keys(searchOptions)
                .reduce((prevSearchOptions: SearchOptions, key: string) => {

                    const defaultValue: string[] | string = Array.isArray(searchOptions[key]) ? [] : undefined;

                    return {
                        ...prevSearchOptions,
                        [key]: defaultValue
                    };
                }, {}), filtersCleared: true
        };

        setStateOptions(clearedSearchOptions);
        onOptionsChanged(clearedSearchOptions);
    }

    function onToggleFiltersVisibility() {
        setFiltersVisible(prevValue => !prevValue);
    }

    const isTrainerOrTrainerAdmin = (app === Apps.Trainer || area === Area.AdminEventManagementTrainerEventInstance);

    const courseDetailFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                floating
                multiple
                selection
                label="Course details"
                placeholder="Scheme"
                value={stateOptions.eventType ?? []}
                options={eventTypeOptions}
                className="event-type-dropdown"
                onChange={onMultiDropdownChange("eventType")}
                search
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                key={searchOptions.language}
                placeholder="Language"
                value={searchOptions.language}
                options={optionsFromObject(Language, "Any", LanguageEnum.Any)}
                onChange={onDropdownPropertyChanged("language")}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                multiple
                placeholder="Course Status"
                value={stateOptions?.eventStatus}
                options={optionsFromObjectWithString(EventStatus, "Any Status")}
                onChange={onMultiDropdownChange("eventStatus")}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                key={searchOptions.deliveryType}
                placeholder="Delivery Type"
                options={optionsFromObjectWithString(DeliveryType, "Any")}
                value={searchOptions.deliveryType}
                onChange={onDropdownPropertyChanged("deliveryType")}
            />
        )
    }];

    const dateTimeFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Field>
                <MuiDateField
                    label="Date from"
                    placeholder={dateInputFormat}
                    value={searchOptions?.fromDate}
                    onChange={onInputChanged("fromDate")}
                />
            </Form.Field>
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Field>
                <MuiDateField
                    label="Date to"
                    placeholder={dateInputFormat}
                    value={searchOptions?.toDate}
                    onChange={onInputChanged("toDate")}
                />
            </Form.Field>
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                placeholder="Course Progress"
                value={getProgress}
                options={optionsFromText([started, notStarted])}
                onChange={onCourseProgressChanged("includeAlreadyStartedGroups")}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance
        ],
        filter: (
            <Form.Dropdown
                multiple
                selection
                width={4}
                placeholder="Days Of Week"
                value={stateOptions?.daysOfWeek ?? []}
                options={optionsFromObject(DayOfWeek, "Sunday", DayOfWeekEnum.Sunday)}
                onChange={onMultiDropdownChange("daysOfWeek")}
            />
        )
    }];

    const venueFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                multiple
                placeholder="Venue"
                value={stateOptions?.venueId ?? []}
                options={venueOptions}
                onChange={onMultiDropdownChange("venueId")}
                search
            />
        )
    };

    const allocationFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                label="Requires Trainers"
                key={searchOptions?.requiresTrainers}
                placeholder="Requires Trainers"
                value={searchOptions.requiresTrainers}
                options={optionsFromObject(YesNoAnyOptions, "Any", YesNoAnyEnum.Any)}
                onChange={onDropdownPropertyChanged("requiresTrainers")}
                search
            />
        )
    }, {
        showInArea: [
            Area.AdminEventManagementEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                label="Available to Trainers"
                key={searchOptions.availableForOtherTrainers}
                placeholder="Available to Trainers"
                value={searchOptions.availableForOtherTrainers}
                options={optionsFromObject(YesNoAnyOptions, "Any", YesNoAnyEnum.Any)}
                onChange={onDropdownPropertyChanged("availableForOtherTrainers")}
                search
            />
        )
    }];

    let areaFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                label="DSA Area"
                key={searchOptions.dsaAreaId}
                placeholder="DSA Area"
                value={searchOptions.dsaAreaId}
                options={dsaAreaOptions}
                onChange={onDropdownPropertyChanged("dsaAreaId")}
                search
            />
        )
    }];

    areaFilters.push(venueFilter);
    areaFilters = areaFilters.concat(allocationFilters);

    const numberOfResultsFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <Form.Dropdown
                selection
                placeholder="Number of Results"
                value={searchOptions?.maxPageSize}
                options={optionsFromObject(PageSizes)}
                onChange={onDropdownPropertyChanged("maxPageSize")}
            />
        )
    };

    const trainerUnconfirmedFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <>
                <label>Unconfirmed?</label>
                <Form.Checkbox
                    toggle
                    checked={searchOptions?.getOnlyUnconfirmedByTrainer}
                    onChange={onCheckboxChanged("getOnlyUnconfirmedByTrainer")}
                />
            </>
        )
    };

    const seatAvailabilityFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        filter: (
            <>
                <label>Seat Availability</label>
                <Form.Checkbox
                    toggle
                    label={availableSeatLabel}
                    checked={searchOptions.hasAvailableSeats}
                    onChange={onCheckboxChanged("hasAvailableSeats")}
                />
            </>
        )
    };

    const oneToOneOnlyFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance
        ],
        filter: (
            <>
                <label>1:1 only</label>
                <Form.Checkbox
                    toggle
                    label={oneToOneOnlyLabel}
                    checked={searchOptions.oneToOneOnly}
                    onChange={onCheckboxChanged("oneToOneOnly")}
                />
            </>
        )
    };

    function renderFilterRow(filters: Filter[], withDivider?: boolean) {
        const visibleFilters = filters.filter(f => f.showInArea.includes(area));

        if (visibleFilters.length === 0) {
            return null;
        }

        const width = Math.floor(16 / visibleFilters.length);
        const totalWidth = width * visibleFilters.length;
        const lastColumnWidth = totalWidth === 16 ? width : (16 - width * visibleFilters.length) + width;
        return (
            <>
                <Grid.Row>
                    {visibleFilters.map((x, i) => (
                        <Grid.Column
                            key={i}
                            width={(i === visibleFilters.length - 1 ? lastColumnWidth : width) as SemanticWIDTHS}
                            verticalAlign="bottom"
                            className={(withDivider && i === visibleFilters.length / 2 ? "border-left" : "")}>
                            {x.filter}
                        </Grid.Column>
                    ))}
                </Grid.Row>

                <Divider />
            </>
        );
    }

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";
    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8} verticalAlign="bottom">
                        {(isTrainerOrTrainerAdmin || app === Apps.Admin) && <GoToEventInstanceById />}
                        <a className={"issue-button float-left"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right" verticalAlign="top">
                        {app === Apps.Admin && area === Area.AdminEventManagementEventInstance &&
                            <AdminEventManagementActions ddrs={true} path={path} />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {filtersVisible &&
                <Grid className="filter-grid" stackable>
                    {renderFilterRow(courseDetailFilters)}

                    <Grid.Row className="date-row">
                        <Grid.Column width={16}>
                            <label> Dates / times</label>
                        </Grid.Column>
                    </Grid.Row>
                    {renderFilterRow(dateTimeFilters)}

                    {renderFilterRow(areaFilters, true)}

                    <Grid.Row>
                        {seatAvailabilityFilter.showInArea.includes(area) && (
                            <Grid.Column width={3}>
                                {seatAvailabilityFilter.filter}
                            </Grid.Column>
                        )}
                        {trainerUnconfirmedFilter.showInArea.includes(area) && (
                            <Grid.Column width={(area === Area.AdminEventManagementTrainerEventInstance ? 3 : 12)}>
                                {trainerUnconfirmedFilter.filter}
                            </Grid.Column>
                        )}

                        {oneToOneOnlyFilter.showInArea.includes(area) && (
                            <Grid.Column width={3}>
                                {oneToOneOnlyFilter.filter}
                            </Grid.Column>
                        )}

                        <div className="display-right">
                            <Grid.Column width={2}>
                                <a className={"issue-button filters"} onClick={onClearFilters}>Clear filters</a>
                            </Grid.Column>
                            <Grid.Column width={2} textAlign="right">
                                <Button content="APPLY" onClick={onSubmit} />
                            </Grid.Column>
                        </div>
                    </Grid.Row>
                </Grid>
            }

            {numberOfResultsFilter.showInArea.includes(area) &&
                <Grid>
                    <Grid.Row textAlign="right">
                        <Grid.Column width={16}>
                            {numberOfResultsFilter.filter}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </>
    );
};
