import * as React from "react";
import { EditForm } from "@common/crud/eventType/components/Edit";
import { Button, Icon, Item } from "semantic-ui-react";
import { CreateForm } from "@common/crud/eventType/components/Create";
import { CurrencyInput } from "@common/global/CurrencyInput";
import moment from "moment";
import { RebookingFees, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { Input } from "@neworbit/simpleui-input";

interface Props {
    form: EditForm | CreateForm;
}

export const RebookingFeesForm: React.FC<Props> = ({ form }) => {
    const hasRebookingFees = form.state.values.hasRebookingFees;
    const rebookingFees = form.state.values.rebookingFees;
    const onChange = form.onChange;
    const labelForInputFeeAfter14Days = `More than ${WorkflowTypeEnum.Dors === form.state.values.workflowType ? "7" : "21"} days before course Fee`;
    const labelForInputFeeCloseToCourseDate = `Within ${WorkflowTypeEnum.Dors === form.state.values.workflowType ? "7" : "21"} days/After course date Fee`;

    function addFee(e: any) {
        e.preventDefault();
        const newRebookingFees = [...rebookingFees, { effectiveDate: moment.utc().startOf("day") }];
        onChange("rebookingFees")(newRebookingFees, true);
    }

    const handleRebookFeesChange = React.useCallback((value: boolean) => {
        if (!value) {
            onChange("rebookingFees")([], true);
        }
        onChange("hasRebookingFees")(value, true);
    }, [onChange]);

    const onEffectiveDateChanged = React.useCallback((fee: RebookingFees) => {
        return (value: moment.Moment, valid: boolean) => {
            const newRebookingFees = rebookingFees.map(rebookingFee =>
                (rebookingFee.feeAfter14Days === fee.feeAfter14Days
                && rebookingFee.feeCloseToCourseDate === fee.feeCloseToCourseDate
                    ? { ...rebookingFee, effectiveDate: value } : rebookingFee));
            return onChange("rebookingFees")(newRebookingFees, valid);
        };
    }, [onChange, rebookingFees]);

    const onFeeAfter14Days = React.useCallback((fee: RebookingFees) => {
        return (value: number, valid: boolean) => {
            const newRebookingFees = rebookingFees.map(rebookingFee => (rebookingFee.effectiveDate === fee.effectiveDate
                ? { ...rebookingFee, feeAfter14Days: value }
                : rebookingFee));
            return onChange("rebookingFees")(newRebookingFees, valid);
        };
    }, [onChange, rebookingFees]);

    const onFeeCloseToCourseDate = React.useCallback((fee: RebookingFees) => {
        return (value: number, valid: boolean) => {
            const newRebookingFees = rebookingFees.map(rebookingFee => (rebookingFee.effectiveDate === fee.effectiveDate
                ? { ...rebookingFee, feeCloseToCourseDate: value }
                : rebookingFee));
            return onChange("rebookingFees")(newRebookingFees, valid);
        };
    }, [onChange, rebookingFees]);

    function onFeeRemoved(fee: RebookingFees, e: any) {
        e.preventDefault();
        const newRebookingFees = rebookingFees.filter(r => r.effectiveDate !== fee.effectiveDate);
        return onChange("rebookingFees")(newRebookingFees, true);
    }

    if (rebookingFees === null) { return null; }

    return (
        <Item>
            <Item.Header as="h3" content="Rebooking Fees" />
            <Input.Checkbox
                label="Has rebooking fees"
                onChange={handleRebookFeesChange}
                value={hasRebookingFees}
            />
            {hasRebookingFees && (
                <>
                    <Button as="div" labelPosition='left' size="big">
                        <Button icon onClick={addFee}>
                            <Icon name="add" />
                        </Button>
                    </Button>
                    {rebookingFees && rebookingFees.map((fee,index) => (
                        <Item.Group key={index}>
                            <Item.Content>
                                <MuiDateField
                                    value={fee.effectiveDate}
                                    label="Effective Date"
                                    showErrors={form.state.showErrors}
                                    required
                                    onChange={onEffectiveDateChanged(fee)}
                                />
                                <CurrencyInput
                                    value={fee.feeAfter14Days}
                                    label={labelForInputFeeAfter14Days}
                                    showErrors={form.state.showErrors}
                                    required
                                    onChange={onFeeAfter14Days(fee)}
                                />
                                <CurrencyInput
                                    value={fee.feeCloseToCourseDate}
                                    label={labelForInputFeeCloseToCourseDate}
                                    showErrors={form.state.showErrors}
                                    required
                                    onChange={onFeeCloseToCourseDate(fee)}
                                />
                                <Button className="cancel-action" attached="bottom" content="Remove Rebooking Fee" onClick={(e) => onFeeRemoved(fee,e)} />
                            </Item.Content>
                        </Item.Group>
                    ))}
                </>
            )}
        </Item>
    );
};
