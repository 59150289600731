import * as React from "react";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { LabelWithValueAndAction } from "@common/global/LabelWithValueAndAction";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { DorsBookingAttendanceStatus } from "@common/crud/dorsBooking/components/DorsBookingAttendanceStatus";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";
import { toast } from "@common/toasts";
import { Attendee, AttendeeApi } from "../..";
import { GetCurrentAndNextDorsStatesResponse } from "@common/crud/common/LookupResponse";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";

export interface EditProps {
    attendee: Attendee;
    updateDorsStatusPossibilities: GetCurrentAndNextDorsStatesResponse;
    reloadNextDorsState: (reload: boolean) => void;
}

export const DorsStatusLabelWithEdit: React.FC<EditProps> = ({ attendee, updateDorsStatusPossibilities, reloadNextDorsState }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [currentDorsStatus, setCurrentDorsStatus] = React.useState<DorsAttendanceStatusesEnum>(DorsAttendanceStatusesEnum.NotSet);

    const nextPossibleDorsStatus = updateDorsStatusPossibilities?.nextDorsAttendanceStatus ?? null;

    React.useEffect(() => {
        const dorsStatus = updateDorsStatusPossibilities?.responseFromDors.dorsAttendanceStatus ?? DorsAttendanceStatusesEnum.NotSet;
        setCurrentDorsStatus(dorsStatus);
    }, [updateDorsStatusPossibilities]);

    const isClientAdvisor = useSelector(isUserClientAdvisor);

    const updateToBooked = nextPossibleDorsStatus === DorsAttendanceStatusesEnum.Booked;
    const updateToCompleted = nextPossibleDorsStatus === DorsAttendanceStatusesEnum.AttendedAndCompleted;
    const updateToPending = nextPossibleDorsStatus === DorsAttendanceStatusesEnum.BookingPending;

    const getActionName = () => {
        if (updateToBooked) {
            return "Update status to booked";
        } else if (updateToCompleted) {
            return "Update status to attended and completed";
        } else if (updateToPending) {
            return "Update status to booking pending";
        }

        return "";
    };

    const updateStatus = React.useCallback(async (requestedStatus: DorsAttendanceStatusesEnum) => {
        setErrorMessage("");
        const attendeeApi = new AttendeeApi(attendee.eventInstanceId);
        const status = await attendeeApi.requestDorsStatusUpdate(attendee.id, { currentStatus: currentDorsStatus, requestedStatus });
        if (status.success) {
            toast.success("DORS status updated successfully");
            setCurrentDorsStatus(requestedStatus);
            reloadNextDorsState(true);
        } else {
            setErrorMessage(status.message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendee.eventInstanceId, attendee.id, currentDorsStatus]);

    const action = React.useCallback(() => {
        if (updateToCompleted) {
            setOpen(true);
        }
        else {
            updateStatus(nextPossibleDorsStatus);
        }
    }, [nextPossibleDorsStatus, updateToCompleted, updateStatus]);

    const closeModal = React.useCallback(() => {
        setOpen(false);
    }, []);

    const save = React.useCallback(() => {
        updateStatus(DorsAttendanceStatusesEnum.AttendedAndCompleted);
        closeModal();
    }, [closeModal, updateStatus]);

    const isActionAvailable = () =>
        (updateToBooked || updateToCompleted || updateToPending)
        && isClientAdvisor
        && currentDorsStatus !== DorsAttendanceStatusesEnum.NotSet;

    return (
        <>
            {updateDorsStatusPossibilities?.responseFromDors.statusUpdateInProgress ? (
                <LabelAndValue label="DORS Status" value="DORS status update in progress" />
            ) : (
                <LabelWithValueAndAction
                    action={action}
                    actionName={getActionName()}
                    label="DORS status"
                    showAction={isActionAvailable()}
                    value={<DorsBookingAttendanceStatus status={currentDorsStatus} />}
                    errorMessage={errorMessage}
                />
            )}
            <Modal open={open}>
                <Modal.Header>
                    Are you sure?
                </Modal.Header>
                <Modal.Content>
                    <p>Updating attendance status to "Attended and Completed" cannot be undone. Are you sure?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={closeModal}
                        className="cancel-action"
                        content="Cancel"
                    />
                    <Button
                        onClick={save}
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
