import { Dispatch } from "redux";
import { Payload } from "@neworbit/redux-helpers";

import { loadAndTrack } from "redux-request-loading";

import { bookingIdSelector } from "../landing/selectors";
import { ApplicationState } from "../applicationState";

import * as actions from "./actionTypes";
import { appSelector, isUserClientAdvisor } from "@common/crud/common/selectors";
import { BookingVenueApi } from "@booking/venues/bookingVenueApi";
import { VenueDetailModel } from "@common/crud/venue/model";

export type RebookVenueAction =
    ({ type: actions.LOAD_REBOOK_VENUE_DETAIL_SUCCESS } & Payload<VenueDetailModel>);

export function loadRebookVenueDetail({ venueId }: { venueId: string }) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        if (!venueId) {
            return;
        }

        const state = getState();
        const bookingId = bookingIdSelector(state);
        const isClientAdvisor = isUserClientAdvisor(state);
        const app = appSelector(state);
        const api = new BookingVenueApi(app, bookingId, !isClientAdvisor);
        const result = await loadAndTrack(dispatch, "loadVenueDetail", api.detail(venueId));
        dispatch(loadRebookVenueDetailSuccess(result));
    };
}

export function clearRebookVenue() {
    return async (dispatch: Dispatch) => {
        dispatch(loadRebookVenueDetailSuccess(null));
    };
}

export const loadRebookVenueDetailSuccess = (venue: VenueDetailModel): RebookVenueAction => ({
    payload: venue,
    type: actions.LOAD_REBOOK_VENUE_DETAIL_SUCCESS
});
