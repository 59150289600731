import * as React from "react";
import { Button, Modal, Form, Checkbox, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { refundFromOrder } from "../actions";
import { EventInstanceDetails, Order } from "../model";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { formatPrice } from "../helpers";

export interface RefundModalProps {
    order: Order;
    selectedEventInstance?: EventInstanceDetails;
    selectedEventInstanceTotal?: number;
    selectedEventInstanceTotalPayments?: number;
    selectedEventInstanceTotalPendingRefunds?: number;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RefundModal: React.FC<RefundModalProps> = ({ order, selectedEventInstance, selectedEventInstanceTotal, selectedEventInstanceTotalPayments,
    selectedEventInstanceTotalPendingRefunds, setRefreshingRecord }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [doNotRefundOnStripe, setDoNotRefundOnStripe] = React.useState<boolean>(false);

    const onlyOneEventInstanceAvailable = React.useMemo(() => order?.eventInstances?.length === 1, [order?.eventInstances?.length]);

    const dispatch = useDispatch();

    const onRefundCheckboxClick = React.useCallback(() => setDoNotRefundOnStripe(!doNotRefundOnStripe), [doNotRefundOnStripe]);

    const openCloseAction = React.useCallback(() => {
        setDoNotRefundOnStripe(false);
        setSubmitted(false);
        setOpen(!open);
    }, [open]);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(refundFromOrder({
            orderId: order.id,
            eventInstanceId: (!onlyOneEventInstanceAvailable && selectedEventInstance) ? selectedEventInstance?.eventInstanceId : undefined,
            refundAmount: (!onlyOneEventInstanceAvailable && selectedEventInstance) ? selectedEventInstanceTotalPendingRefunds : order.totalPendingRefunds,
            doNotRefundOnStripe
        }));
        setOpen(false);
        setRefreshingRecord(true);
    }, [dispatch, doNotRefundOnStripe, order, onlyOneEventInstanceAvailable, selectedEventInstance, selectedEventInstanceTotalPendingRefunds,
        setRefreshingRecord]);

    return (
        <>
            <Button onClick={openCloseAction}>
                {(onlyOneEventInstanceAvailable || selectedEventInstance?.eventInstanceId) ? "REFUND COURSE" : "REFUND ORDER"}
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    Refunds
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <LabelAndValue
                                label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? "Course Total"
                                    : "Order Total"}
                                value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? formatPrice(selectedEventInstanceTotal ?? 0)
                                    : formatPrice(order.total ?? 0)} />
                        </Form.Field>
                        <LabelAndValue
                            label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                ? "Course Total Payments"
                                : "Order Total Payments"}
                            value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                ? formatPrice(selectedEventInstanceTotalPayments ?? 0)
                                : formatPrice(order.totalPayments ?? 0)} />
                        <LabelAndValue
                            label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                ? "Course Remaining Balance"
                                : "Order Remaining Balance"}
                            value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                ? `£-${((selectedEventInstanceTotalPendingRefunds ?? 0) / 100).toFixed(2)}`
                                : `£-${(order.totalPendingRefunds / 100).toFixed(2)}`}
                        />
                        <Divider />
                        <LabelAndValue
                            label={(onlyOneEventInstanceAvailable || selectedEventInstance) ? "Course Refund" : "Order Refund"}
                            value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                ? formatPrice(selectedEventInstanceTotalPendingRefunds ?? 0)
                                : formatPrice(order.totalPendingRefunds ?? 0)}
                        />
                        <Checkbox checked={doNotRefundOnStripe} onClick={onRefundCheckboxClick} label="Do not refund automatically in Stripe" />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        loading={submitted}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
