import { Version } from "@common/model";

export interface AppSettings {
    version: Version;
    internalAppSettings: InternalAppSettings;
    organisationId?: string;
    loaded?: boolean;
    bookingAppSettings: BookingAppSettings;
    genesysSettings: GenesysSettings;
    trainerInvoicesSettings: TrainerInvoicesSettings;
    externalResourcesSettings: ExternalResourcesSettings;
    zoomSettings: ZoomSettings;
    featureSettings: FeatureSettings;
    addressLookupSettings: AddressLookupSettings;
    recaptchaSettings: RecaptchaSettings;
    environment: string;
    claritySettings: ClaritySettings;
    googleTagManagerSettings: GoogleTagManagerSettings;
    testSettings: TestSettings;
    cpcBookingAppSettings: CpcBookingAppSettings;
    hotjarSettings: HotjarSettings;
    categories: Category[];
    constructionCategories: Category[];
}

export interface InternalAppSettings {
    bookingAppEndpoint: string;
    trainerAppEndpoint: string;
    dorsApiEndPoint: string;
    adminAppEndpoint: string;
    corporateBookingAppEndpoint: string;
}

interface BookingAppSettings {
    introMessageEnglish: string;
    introMessageWelsh: string;
    showClassroomCoursesFirst: boolean;
    welshCourseSelectionAlert: string;
    specialRequirementInfoEN: string;
    specialRequirementInfoCY: string;
    noClassroomMessageEnglish: string;
    noClassroomMessageCymraeg: string;
    welshPoliceForceIds: string;
    standardBookingExplanationEN: string;
    standardBookingExplanationCY: string;
    flexiPayExplanationEN: string;
    flexiPayExplanationCY: string;
    alwaysShowRebookButton: boolean;
    hideRebookButtonFromDigitalAttendeeBeforeCourseInHours: number;
    hideRebookButtonFromClassroomAttendeeBeforeCourseInHours: number;
}

interface GenesysSettings {
    usePauseAndResume: boolean;
    genesysAuthorisationEndpoint: string;
    clientId: string;
    genesysSetupAndEnabled: boolean;
    deploymentKey: string;
    orgGuid: string;
    useCliSearch: boolean;
    genesysCliSearchEnabled: boolean;
    useGenesysIvr: boolean;
}

interface ExternalResourcesSettings {
    contactPageLink: string;
    trainerAppResourcesLink: string;
    dorsPortalLink: string;
    privacyPolicyLink: string;
    constructionPrivacyPolicyLink: string;
    cpcCourseEligibilityLink: string;
    cpcTermsAndConditionsLink: string;
    constructionTermsAndConditionsLink: string;
}

interface TrainerInvoicesSettings {
    earliestInvoiceDate: Date;
    earliestInvoiceDateVersionTwo: Date;
    enabled: boolean;
    tier2ThresholdDefault: number;
    tier3ThresholdDefault: number;
}

interface ZoomSettings {
    passwordResetEnabled: boolean;
    passwordResetFrequencyDays: number;
}

interface FeatureSettings {
    policeAndCourtSubcontracting: boolean;
    commercialSubcontracting: boolean;
    constructionSubcontracting: boolean;
    useReCaptcha: boolean;
    trainerPromise: boolean;
    trainerPromiseInvoiceReminder: boolean;
    autoSpreading: boolean;
    corporateBookingContactButtons: boolean;
    bookingContactButtons: boolean;
    walletPayments: boolean;
    allowCommercialOpenBookingManualDelegateAdjustments: boolean;
    allowConstructionOpenBookingManualDelegateAdjustments: boolean;
    offlineBookNowPayLaterEnabled: boolean;
    onlineBookNowPayLaterEnabled: boolean;
    organisationPendingCourses: boolean;
}

interface AddressLookupSettings {
    apiKey: string;
}

interface RecaptchaSettings {
    recaptchaSiteKey: string;
}

interface ClaritySettings {
    projectId: string;
}

interface GoogleTagManagerSettings {
    gtmId: string;
}

interface TestSettings {
    requireTrainerPromiseInvoiceReminder: boolean;
    allowUnknownStatusDorsBookingToRebook: boolean;
}

interface CpcBookingAppSettings {
    cpcBundleEnabled?: boolean;
    cpcBundleBanner?: string;
}

interface HotjarSettings {
    cpcSiteId: number;
    policeBookingAppSiteId: number;
    hotjarVersion: number;
}

export interface Category {
    id: number;
    title: string;
    icon: number;
    subCategories: SubCategory[];
}

export interface SubCategory {
    id: number;
    title: string;
}

export const initialAppSettings: AppSettings = {
    version: { assemblyVersion: "", productVersion: "" },
    environment: "",
    internalAppSettings: {
        bookingAppEndpoint: "",
        trainerAppEndpoint: "",
        dorsApiEndPoint: "",
        adminAppEndpoint: "",
        corporateBookingAppEndpoint: ""
    },
    bookingAppSettings: {
        introMessageEnglish: "",
        introMessageWelsh: "",
        showClassroomCoursesFirst: false,
        welshCourseSelectionAlert: "",
        specialRequirementInfoEN: "",
        specialRequirementInfoCY: "",
        noClassroomMessageEnglish: "",
        noClassroomMessageCymraeg: "",
        welshPoliceForceIds: "",
        standardBookingExplanationEN: "",
        standardBookingExplanationCY: "",
        flexiPayExplanationEN: "",
        flexiPayExplanationCY: "",
        alwaysShowRebookButton: false,
        hideRebookButtonFromClassroomAttendeeBeforeCourseInHours: 0,
        hideRebookButtonFromDigitalAttendeeBeforeCourseInHours: 0
    },
    genesysSettings: {
        usePauseAndResume: false,
        genesysAuthorisationEndpoint: "",
        clientId: "",
        genesysSetupAndEnabled: false,
        deploymentKey: "",
        orgGuid: "",
        useCliSearch: false,
        genesysCliSearchEnabled: false,
        useGenesysIvr: false
    },
    trainerInvoicesSettings: {
        earliestInvoiceDate: null,
        earliestInvoiceDateVersionTwo: null,
        enabled: false,
        tier2ThresholdDefault: 0,
        tier3ThresholdDefault: 0
    },
    externalResourcesSettings: {
        contactPageLink: "",
        trainerAppResourcesLink: "",
        dorsPortalLink: "",
        privacyPolicyLink: "",
        constructionPrivacyPolicyLink: "",
        cpcCourseEligibilityLink: "",
        cpcTermsAndConditionsLink: "",
        constructionTermsAndConditionsLink: ""
    },
    zoomSettings: {
        passwordResetEnabled: false,
        passwordResetFrequencyDays: 0
    },
    featureSettings: {
        policeAndCourtSubcontracting: false,
        commercialSubcontracting: false,
        constructionSubcontracting: false,
        useReCaptcha: false,
        trainerPromise: false,
        trainerPromiseInvoiceReminder: false,
        autoSpreading: false,
        corporateBookingContactButtons: false,
        bookingContactButtons: false,
        walletPayments: false,
        allowCommercialOpenBookingManualDelegateAdjustments: false,
        allowConstructionOpenBookingManualDelegateAdjustments: false,
        offlineBookNowPayLaterEnabled: false,
        onlineBookNowPayLaterEnabled: false,
        organisationPendingCourses: false
    },
    addressLookupSettings: {
        apiKey: ""
    },
    recaptchaSettings: {
        recaptchaSiteKey: "",
    },
    claritySettings: {
        projectId: ""
    },
    googleTagManagerSettings: {
        gtmId: ""
    },
    hotjarSettings: {
        cpcSiteId: null,
        hotjarVersion: null,
        policeBookingAppSiteId: null
    },
    testSettings: {
        requireTrainerPromiseInvoiceReminder: false,
        allowUnknownStatusDorsBookingToRebook: false,
    },
    cpcBookingAppSettings: {
        cpcBundleEnabled: undefined,
        cpcBundleBanner: undefined
    },
    categories: [],
    constructionCategories: []
};
