import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";
import * as React from "react";
import { DelegateDetailModel } from "@common/crud/delegate/model";
import { useDispatch, useSelector } from "react-redux";
import { handleDeletionDate } from "@common/crud/delegate/actions";
import { pathWithoutLastPartSelector } from "@common/crud/delegate/selectors";

interface Props {
    delegate: DelegateDetailModel;
}

export const AddDeletionDateToDelegate: React.FC<Props> = ({ delegate }) => {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const path = useSelector(pathWithoutLastPartSelector);

    const eventInstanceIdFromPath = React.useMemo(() => {
        if (path.includes("eventInstances")) {
            const splitPath = path.split("/");
            const indexOfEventInstanceId = splitPath.indexOf("eventInstances") + 1;
            return splitPath[indexOfEventInstanceId];
        }
        return "";
    },[path]);

    const onModalOpened = React.useCallback(() => setOpenModal(true), []);
    const onModalClosed = React.useCallback(() => setOpenModal(false), []);

    const oHandleDeletionDate = React.useCallback(() => {
        dispatch(handleDeletionDate(delegate.id, delegate.organisationId, eventInstanceIdFromPath, !!delegate.deletionDate));
        setOpenModal(false);
        return;
    },[delegate, dispatch, eventInstanceIdFromPath]);

    const label = React.useMemo(() => delegate?.deletionDate
        ? "Cancel Right To Be Forgotten"
        : "Right To Be Forgotten", [delegate]);

    const description = React.useMemo(() => delegate?.deletionDate
        ? "This will remove deletion date from this delegate."
        : "This will set a deletion date for today to this delegate.", [delegate]);

    if (delegate?.anonymized || delegate?.corporateBookingHidden) {
        return null;
    }

    return (
        <>
            <Button onClick={onModalOpened}>{label}</Button>
            <Modal open={openModal}>
                <Modal.Header>
                    {label}
                </Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to continue?</p>
                    <p>{description}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onModalClosed} className="cancel-action">
                        Cancel
                    </Button>
                    <Button onClick={oHandleDeletionDate} primary>
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
