import React, { forwardRef } from "react";
import classes from "./Input.module.scss";
import { BaseInput, InputProps } from "@common/components/BaseInput";

// reuses the input component from the cpc redesign but with reset styles
export const Input = forwardRef<HTMLInputElement, InputProps>((
    props, ref) => {

    return (<BaseInput {...props} classNames={classes} ref={ref} />);
});
