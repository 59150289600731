import * as React from "react";

import { GenderEnum, Gender } from "../model";

interface Props {
    gender?: GenderEnum;
}

export const GenderDisplay: React.FC<Props> = ({ gender }: Props) => {
    return <p>{Gender[gender] || "N/A"}</p>;
};
