/* eslint-disable quote-props */
import { routes as landing } from "./landing";
import { routes as bookings } from "./bookings";
const routes: any = {
    "/": {
        title: "Rebooking Sign In",
        authorize: false,
        "/login": {
            title: "Login",
            authorize: false
        },
        "/ddrsLogin": {
            title: "DDRS Login",
            authorize: false
        },
        ...landing,
        ...bookings
    }
};

export { routes };
