import * as React from "react";
import { Button } from "semantic-ui-react";
import { copyTextToClipboard } from "@common/copyToClipboard";

const CopyButton: React.FC<{ text: string }> = ({ text }) => {
    const copy = React.useCallback(() => copyTextToClipboard(text), [text]);

    return (
        <Button onClick={copy} disabled={!text}>Copy</Button>
    );
};

export { CopyButton };
