import { Checkbox, CheckboxProps, Form, TextArea } from "semantic-ui-react";
import * as React from "react";

export interface AssistanceRequiredComponentProps {
    comment: string;
    flag?: boolean;
    onChange: (value: string) => void;
    onFlagClick: (value: boolean) => void;
    label: string;
}

export const AssistanceRequiredComponent: React.FC<AssistanceRequiredComponentProps> = (props: AssistanceRequiredComponentProps) => {
    const { comment, flag, onChange, onFlagClick, label } = props;

    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const updateClick = React.useCallback((_: any, checked: CheckboxProps) => {
        onFlagClick(checked.checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateComment = React.useCallback((value: string): boolean => {
        if (flag) {
            if (!value || value.length < 1) {
                setErrorMessage("You must enter a comment");
                return true;
            }
            if (value.length > 999) {
                setErrorMessage("Comment must be less than 1000 characters");
                return true;
            }
        }
        return false;
    },[flag]);

    const updateComment = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
        setIsError(validateComment(event.currentTarget.value));
        if (!isError) {
            onChange(event.currentTarget.value);
        }
    }, [isError, onChange, validateComment]);

    return (
        <Form error={isError}>
            <Checkbox
                onClick={updateClick}
                checked={flag}
                label={label} />
            {flag &&
            <>
                <br />
                <div className="support-requests">
                    <TextArea
                        className="support-requests-input"
                        placeholder="Enter your comments (required)"
                        value={comment}
                        onChange={updateComment}
                        rows={4}
                        onBlur={updateComment}
                    />
                </div>
                {isError && <div><p>{errorMessage}</p></div>}
            </>}
        </Form>
    );
};

