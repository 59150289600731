import * as React from "react";
import { Fragment } from "redux-little-router";
import { InvoicePreview } from "./invoicePreview";
import { InvoiceDetail } from "./invoiceDetail";
import { Invoices } from "@common/trainerInvoices/components/Invoices";

export const Base: React.FC = () => {
    return (
        <div>
            <Fragment forRoute="/">
                <Invoices />
            </Fragment>
            <Fragment forRoute="/preview">
                <InvoicePreview />
            </Fragment>
            <Fragment forRoute="/:id">
                <InvoiceDetail />
            </Fragment>
        </div>
    );
};
