import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import React from "react";
import { Icon } from "semantic-ui-react";
interface StartDateEditProps {
    startDate: moment.Moment;
    showErrors: boolean;
    updateStartDate: (value: moment.Moment, valid: boolean) => void;
    selectedDayIsBankHoliday: boolean;
}
export const StartDateEdit = ({ startDate, showErrors, updateStartDate, selectedDayIsBankHoliday }: StartDateEditProps) => {
    return (
        <>
            <MuiDateField
                value={startDate}
                label="Date"
                required
                validation={[muiTodayOrfutureDateValidator]}
                showErrors={showErrors}
                onChange={updateStartDate}
            />
            {selectedDayIsBankHoliday &&
        <div className="ui yellow message">
            <Icon name="warning sign" size="large" />Warning - date selected is a Bank Holiday
        </div>}
        </>);
};
