import moment from "moment";
import { Attendee } from "@common/crud/attendee";
import { DorsBooking } from "@common/crud/dorsBooking";

export interface HistoryRecord {
    id: string;
    correlationId: string;
    orderId: string;
    eventType: ChangeEventType;
    eventMessage: string;
    dateCreated: moment.Moment;
    recordModifiedByEmail: string;
    comparisonResult: ObjectDifference[];
    historyLinkItems?: HistoryLinkItem[];
    pendingDate?: moment.Moment;
    completionDate?: moment.Moment;
    isPendingNote: boolean;
    isPendingNoteCompleted: boolean;
    isAlertNoteToTTC?: boolean;
    nudgeTaskId?: string;
    properties?: HistoryPropertyBag;
    eventInstanceDay?: number;
    noteAttachmentFileName?: string;
    noteAttachmentBlobPath?: string;
}

export interface PendingNoteAddedModel {
    nudgeTaskId?: string;
    noteAttachmentFileName?: string;
    noteAttachmentBlobPath?: string;
}

export enum ChangeEventType {
    Event = 0,
    DataModification = 1,
    Payment = 2,
    Refund = 3,
    Booking = 4,
    Comms = 5,
    Note = 6,
    DORS = 7,
    ExpiryDate = 8,
    DetailsChanged = 9,
    Nudge = 10,
    RegisterUpdates = 11,
    Audit = 12,
    TrainerNote = 13,
    BillingAndInvoices = 14,
    TrainerComplaints = 15,
    Monitoring = 16,
    ReferralAdded = 17,
    AlwaysShown = 18,
    FlexiPayExpiryDate = 19,
    LicenceUpdated = 20,
    Genesys = 21,
    CourseCreated = 22,
    Certificates = 23,
}

export const ChangeEventTypes = {
    [ChangeEventType.Event]: "Events",
    [ChangeEventType.DataModification]: "Data Modifications",
    [ChangeEventType.Payment]: "Payments",
    [ChangeEventType.Refund]: "Refunds",
    [ChangeEventType.Booking]: "Bookings",
    [ChangeEventType.Comms]: "Comms",
    [ChangeEventType.Note]: "Notes",
    [ChangeEventType.DORS]: "DORS",
    [ChangeEventType.ExpiryDate]: "Completion Date",
    [ChangeEventType.Nudge]: "Nudges",
    [ChangeEventType.DetailsChanged]: "Details Changed",
    [ChangeEventType.RegisterUpdates]: "Register Updates",
    [ChangeEventType.Audit]: "Audit",
    [ChangeEventType.TrainerNote]: "Notes",
    [ChangeEventType.BillingAndInvoices]: "Billing and Invoices",
    [ChangeEventType.TrainerComplaints]: "Complaints",
    [ChangeEventType.Monitoring]: "Monitoring",
    [ChangeEventType.ReferralAdded]: "Referral Added",
    [ChangeEventType.AlwaysShown]: "Always Shown",
    [ChangeEventType.FlexiPayExpiryDate]: "Flexi-Booking Expiry Date",
    [ChangeEventType.LicenceUpdated]: "Licence Updated",
    [ChangeEventType.Genesys]: "Genesys call action",
    [ChangeEventType.CourseCreated]: "Course Created",
    [ChangeEventType.Certificates]: "Certificates"
};

export interface ObjectDifference {
    propertyName: keyof Attendee | DorsBooking;
    changedPropertyName: string;
    oldValue?: string;
    newValue: string;
}

export enum LinkedResource {
    OldEventInstance = 0,
    NewEventInstance = 1,
    OldAttendee = 2,
    EventInstance = 3,
    Payment = 4,
    NewAttendee = 5,
    Organisation = 6,
    CorporateUser = 7,
    CorporateEventInstance = 8,
}

export interface HistoryLinkItem {
    mainObjectId: string;
    mainObjectName: string;
    secondaryObjectId: string;
    linkedResource: LinkedResource;
}

export enum HistoryVersion {
    V1 = 0,
    V2 = 1
}

export class HistoryRecordBuilder {
    private id: string;
    private correlationId: string;
    private orderId: string;
    private eventType: ChangeEventType;
    private eventMessage: string;
    private dateCreated: moment.Moment;
    private recordModifiedByEmail: string;
    private comparisonResult: ObjectDifference[];
    private historyLinkItems?: HistoryLinkItem[];
    private pendingDate?: moment.Moment;
    private completionDate?: moment.Moment;
    private nudgeTaskId?: string;

    constructor(eventType: ChangeEventType) {
        this.id = "test1";
        this.correlationId = "test1";
        this.eventType = eventType;
        this.eventMessage = "test";
        this.dateCreated = moment();
        this.recordModifiedByEmail = "test@test.test";
        this.comparisonResult = [];
        this.historyLinkItems = [];
        this.pendingDate = null;
        this.completionDate = null;
        this.nudgeTaskId = null;
    }

    public withId(id: string): HistoryRecordBuilder {
        return this.with({ id });
    }

    public withEventMessage(eventMessage: string): HistoryRecordBuilder {
        return this.with({ eventMessage });
    }

    public withDateCreated(dateCreated: moment.Moment): HistoryRecordBuilder {
        return this.with({ dateCreated });
    }

    public withRecordModifiedByEmail(recordModifiedByEmail: string): HistoryRecordBuilder {
        return this.with({ recordModifiedByEmail });
    }

    public withComparisonResult(comparisonResult: ObjectDifference[]): HistoryRecordBuilder {
        return this.with({ comparisonResult });
    }

    public withHistoryLinkItems(historyLinkItems: HistoryLinkItem[]): HistoryRecordBuilder {
        return this.with({ historyLinkItems });
    }

    public withPendingDate(pendingDate?: moment.Moment): HistoryRecordBuilder {
        return this.with({ pendingDate });
    }

    public withCompletionDate(completionDate?: moment.Moment): HistoryRecordBuilder {
        return this.with({ completionDate });
    }

    public withNudgeTaskId(nudgeTaskId?: string): HistoryRecordBuilder {
        return this.with({ nudgeTaskId });
    }

    public build(): HistoryRecord {
        return {
            id: this.id,
            correlationId: this.correlationId,
            orderId: this.orderId,
            eventType: this.eventType,
            eventMessage: this.eventMessage,
            dateCreated: this.dateCreated,
            recordModifiedByEmail: this.recordModifiedByEmail,
            comparisonResult: this.comparisonResult,
            historyLinkItems: this.historyLinkItems,
            pendingDate: this.pendingDate,
            isPendingNote: this.pendingDate !== null,
            completionDate: this.completionDate,
            isPendingNoteCompleted: this.completionDate !== null,
            nudgeTaskId: this.nudgeTaskId,
        };
    }

    private with(newProperties: object) {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), { ...this, ...newProperties });
    }
}

type PropertyName =
    "EmailMessageId" |
    "EmailTemplateName" |
    "EmailFrom" |
    "EmailTo" |
    "SmsBody" |
    "SmsTemplateName" |
    "TransferredToProviderName" |
    "ProductName" |
    "CourseVenue" |
    "CourseDateTime" |
    "CertificateUrl" |
    "MessageSentDateTime" |
    "SeatsBooked" |
    "CorporateEventInstanceIdRedirect" |
    "CorporateBookingCorporateUserId" |
    "CorporateBookingCorporateUserName" |
    "CorporateBookingOrganisationId" |
    "CorporateBookingOrganisationName" |
    "CorporateBookingAmountPaid" |
    "CorporateBookingPaymentRef";

export type HistoryPropertyBag = Record<PropertyName, string>;
