import * as React from "react";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { AttendeeDetailModel } from "../../model";
import { DisplayAttendeeCarType } from "./DisplayAttendeeCarType";
import { AttendeeCarDetailsModal } from "./AttendeeCarDetailsModal";

export interface AttendeeCarDetailsProps {
    attendee: AttendeeDetailModel;
    editable?: boolean;
}

export const AttendeeCarDetails: React.SFC<AttendeeCarDetailsProps> = ({ attendee, editable }) => {

    const label = editable ? (
        <>
            <span className="fixed-width">Car Type</span>
            <Authorize authorize={[...EventManagementAdminRolesAndTrainers]}>
                <AttendeeCarDetailsModal attendee={attendee} />
            </Authorize>
        </>) : "Car Type";

    return (
        <>
            {attendee.carType && <LabelAndValue label={label} value={<DisplayAttendeeCarType carType={attendee.carType} />} />}
            {attendee.specialCarDetails && <LabelAndValue label="Special adaptation details" value={attendee.specialCarDetails} />}
        </>
    );
};
