export const calculateVAT = (netAmount: number, vatPercentage: number) => {
    if (!vatPercentage) {
        return 0;
    }

    return (netAmount * (vatPercentage / 100));
};

export const calculateGrossAmount = (netAmount: number, vatPercentage: number) => {
    if (!vatPercentage) {
        return netAmount;
    }

    return netAmount + calculateVAT(netAmount, vatPercentage);
};
