import * as React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { CoreDetailProps } from "./Detail";
import { useSelector } from "react-redux";
import { organisationSelector } from "@common/crud/organisation/selectors";
import { OrganisationApi } from "@common/crud/organisation";
import { Department } from "@common/crud/organisation/model";

export const DetailsTab: React.FC<CoreDetailProps> = ({ corporateUser }) => {
    const [departments, setDepartments] = React.useState<Department[]>([]);

    const organisation = useSelector(organisationSelector);
    const hasCorporateOrganisationSpecificDepartments = organisation?.corporateOrganisationData?.hasCorporateOrganisationSpecificDepartments;

    React.useEffect(() => {
        const getDepartments = async () => {
            const response = await new OrganisationApi().getDepartments(organisation.id);
            setDepartments(response);
        };

        if (hasCorporateOrganisationSpecificDepartments && organisation?.id) {
            getDepartments();
        }
    }, [organisation?.id, hasCorporateOrganisationSpecificDepartments]);

    const departmentNames = React.useMemo(() => {
        if (!hasCorporateOrganisationSpecificDepartments)
        {
            return "";
        }

        return corporateUser?.departments?.map(id => departments.find(department => department.id === id))
            .filter(Boolean).map(department => department.name).join(", ") ?? "";
    }, [corporateUser?.departments, departments, hasCorporateOrganisationSpecificDepartments]);

    return (
        <Tab.Pane>
            <Grid>
                <Detail label="Forename" value={corporateUser.forename} labelWidth={5} valueWidth={11} />
                <Detail label="Surname" value={corporateUser.surname} labelWidth={5} valueWidth={11} />
                <Detail label="Email" value={corporateUser.email} labelWidth={5} valueWidth={11} />
                <Detail label="Telephone" value={corporateUser.telephone} labelWidth={5} valueWidth={11} />
                <Detail
                    label="Expiry"
                    value={corporateUser.expiry?.format("LL") ?? ""}
                    labelWidth={5}
                    valueWidth={11} />
                {hasCorporateOrganisationSpecificDepartments && (
                    <Detail
                        label="Departments"
                        value={departmentNames}
                        labelWidth={5}
                        valueWidth={11}
                    />
                )}
                <Detail
                    label="Closed course manager" value={corporateUser.closedCourseManager
                        ? "Yes"
                        : "No"}
                    labelWidth={5}
                    valueWidth={11} />
                <Detail
                    label="Opted in to marketing" value={corporateUser.upcomingNewsAndCoursesNewsletter
                        ? "Yes"
                        : "No"}
                    labelWidth={5}
                    valueWidth={11} />
            </Grid>
        </Tab.Pane>
    );
};
