import { BusinessLineType } from "../crud/organisation/model";

export interface FavouriteDetailModel {
    readonly id: string;
    readonly targetId: string;
    readonly category: FavouriteCategoryEnum;
    readonly name: string;
    readonly favouritedDateTime: moment.Moment;
    readonly businessLineType: BusinessLineType;
}

export interface FavouriteCreateEditModel {
    readonly id?: string;
    readonly targetId: string;
    readonly category: FavouriteCategoryEnum;
    readonly name?: string;
    readonly markAsFavourite?: boolean;
}

export enum FavouriteCategoryEnum {
    Unknown = 0,
    Organisation = 1,
    EventInstance = 2
}
