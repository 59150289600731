import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { AsyncDispatch } from "@common/redux-helpers";

import * as actions from "./actiontypes";
import { ConfigurationApi } from "./configurationApi";
import { ConfigurationModel, AppSetting } from "./model";
import { toast } from "react-toastify";

export type ConfigurationAction =
    ({ type: actions.LOAD_CONFIGURATION_SUCCESS } & Payload<ConfigurationModel>)
    | ({ type: actions.SAVE_SETTING } & Payload<AppSetting>)
    | ({ type: actions.SAVE_SETTING_SUCCESS })
    | ({ type: actions.DELETE_SETTING_SUCCESS })
    | ({ type: actions.LOAD_ORG_LOGO_SUCCESS } & Payload<any>);

export const loadConfigurationSuccess = (payload: ConfigurationModel): ConfigurationAction => ({
    payload,
    type: actions.LOAD_CONFIGURATION_SUCCESS
});

export const saveSettingSuccess = (): ConfigurationAction => ({
    type: actions.SAVE_SETTING_SUCCESS
});

export const deleteSettingSuccess = (): ConfigurationAction => ({
    type: actions.DELETE_SETTING_SUCCESS
});

export function loadConfiguration({ organisationId }: { organisationId?: string } = {}) {
    return async (dispatch: Dispatch) => {
        const api = new ConfigurationApi(organisationId);
        const result = await loadAndTrack(
            dispatch,
            "loadConfiguration",
            api.get()
        );
        dispatch(loadConfigurationSuccess(result));
    };
}

export function saveSetting({ organisationId, setting }: { organisationId?: string; setting: AppSetting }) {
    return async (dispatch: AsyncDispatch) => {
        const api = new ConfigurationApi(organisationId);
        await loadAndTrack(
            dispatch,
            "saveSetting",
            api.post(setting)
        );
        dispatch(saveSettingSuccess());
        toast.success("System configuration successfully updated");
        dispatch(loadConfiguration({ organisationId }));
    };
}

export function deleteSetting({ organisationId, id }: { organisationId?: string; id: string }) {
    return async (dispatch: AsyncDispatch) => {
        const api = new ConfigurationApi(organisationId);
        await loadAndTrack(
            dispatch,
            "deleteSetting",
            api.delete(id)
        );
        dispatch(deleteSettingSuccess());
        toast.success("Configuration item successfully deleted");
        dispatch(loadConfiguration({ organisationId }));
    };
}
