export const validatePostmarkTemplateId = (val: string): string[] => {
    const errors: string[] = [];

    if ((val?.length || 0) > 10) {
        errors.push("Postmark template id is too long");
    }
    return errors;
};

export const validateCpcBundleDiscount = (val: string): string[] => {
    const errors: string[] = [];
    const numberValue = Number(val);

    if (numberValue < 1 || numberValue > 49) {
        errors.push("Expected discount in the range 1-49");
    }

    return errors;
};

