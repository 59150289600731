import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SectionTwoDeliverySkills: QuestionSection = {
    id: "2",
    title: "Section 2 – Delivery Skills",
    seqNum: 1,
    subSections: [
        {
            id: "2.1",
            text: "Did the trainer relate well to the clients?",
            seqNum: 1,
        },
        {
            id: "2.2",
            text: "Did the trainer work efficiently with the co-trainer?",
            seqNum: 2,
        },
        {
            id: "2.3",
            text: "Did the trainer fully engage all clients and get everyone involved at relevant times?",
            seqNum: 3,
        },
        {
            id: "2.4",
            text: "Did the trainer challenge attitudes if necessary?",
            seqNum: 4,
        },
        {
            id: "2.5",
            text: "If you answered yes to the above questions, was this done appropriately?",
            seqNum: 5,
        },
        {
            id: "2.6",
            text: "Did the trainer address all clients’ questions/challenges appropriately?",
            seqNum: 6,
        },
        {
            id: "2.7",
            text: "Did the trainer use teaching techniques appropriately?",
            seqNum: 7,
        },
        {
            id: "2.8",
            text: "Did the trainer deliver the elements of the course at an appropriate pace for the clients?",
            seqNum: 8,
        }],
    summaryCriterium: [{ id: "2.9", text: "", typeEnum: CriteriaEnum.text }],
    IsQuestionSection: true
};
