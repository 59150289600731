import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RebookInformation } from "../../bookings/components/RebookInformation";
import { PaymentReview } from "./PaymentReview";
import { startPaymentProcessSelector } from "@booking/payments/selectors";
import { useStickyState } from "@common/payments/useStickyState";

const StripeWrapper: React.FC = () => {
    let startPaymentProcess = useSelector(startPaymentProcessSelector);

    const localState = useStickyState(startPaymentProcess);

    if (!startPaymentProcess || startPaymentProcess.stripePublishKey === null) {
        startPaymentProcess = localState;
    }

    if (!startPaymentProcess || startPaymentProcess.stripePublishKey === null) {
        return null;
    }

    const stripePromise = loadStripe(startPaymentProcess.stripePublishKey);
    return (
        <>
            <RebookInformation />
            <Elements stripe={stripePromise}>
                <PaymentReview startPaymentProcess={startPaymentProcess} />
            </Elements>
        </>
    );
};

export { StripeWrapper };
