import { HistoryRecord } from "@common/history/model";
import moment from "moment";
import { DrinkDriveOffenderDetails } from "../drinkDriveOffenders/model";
import { AttendeeDetailModel, BlobDetails, DdrsOffenderDocumentPaths, DocumentType } from "./model";

export const parseModel = (model: AttendeeDetailModel): AttendeeDetailModel => {
    return {
        ...model,
        offerExpiry: model.offerExpiry && moment(model.offerExpiry),
        dateModified: model.dateModified && moment(model.dateModified),
        autoRemovalDate: model.autoRemovalDate && moment(model.autoRemovalDate),
        attendeeHistoryRecordsV2: model?.attendeeHistoryRecordsV2?.map(parseHistory),
        attendeeHistoryRecords: model?.attendeeHistoryRecords?.map(parseHistory),
        scheduledPaymentPlans: model?.scheduledPaymentPlans?.map(plan => (
            {
                ...plan,
                chargeDate: plan.chargeDate && moment(plan.chargeDate)
            }
        )),
        deletionDate: model.deletionDate && moment(model.deletionDate),
        specialRequirements: {
            ...model?.specialRequirements,
            venueAttributes: model?.specialRequirements?.venueAttributes ?? [],
            eventAttributes: model?.specialRequirements?.eventAttributes ?? []
        },
        cancellationDate: model.cancellationDate && moment(model.cancellationDate),
        ddrsOffenderDocumentPaths: model.ddrsOffenderDocumentPaths && parseDdrsOffenderDocumentPaths(model.ddrsOffenderDocumentPaths),
        drinkDriveOffenderDetails: model.drinkDriveOffenderDetails && parseDrinkDriverOffenderDetails(model.drinkDriveOffenderDetails),
        delegateCertificateLinks: model.delegateCertificateLinks && parseDelegateCertificateLinks(model.delegateCertificateLinks),
        flexiPayExpiryDate: model.flexiPayExpiryDate && moment(model.flexiPayExpiryDate),
        dqcExpiry: model.dqcExpiry && moment(model.dqcExpiry),
        dateOfBirth: model.dateOfBirth && moment(model.dateOfBirth),
    };
};

const parseHistory = (historyRecord: HistoryRecord): HistoryRecord => {
    return {
        ...historyRecord,
        dateCreated: historyRecord.dateCreated && moment(historyRecord.dateCreated),
        pendingDate: historyRecord.pendingDate && moment(historyRecord.pendingDate),
        completionDate: historyRecord.completionDate && moment(historyRecord.completionDate),
    };
};

const parseDrinkDriverOffenderDetails = (model: DrinkDriveOffenderDetails): DrinkDriveOffenderDetails => {
    return {
        ...model,
        sentenceDate: model.sentenceDate && moment(model.sentenceDate),
        interimDate: model.interimDate && moment(model.interimDate),
        transferDate: model.transferDate && moment(model.transferDate),
        dateOfBirth: model.dateOfBirth && moment(model.dateOfBirth)
    };
};

const parseDdrsOffenderDocumentPaths = (model: DdrsOffenderDocumentPaths): DdrsOffenderDocumentPaths => {

    return Object.fromEntries(Object.keys(model).map(key => [DocumentType[key as keyof DocumentType], parseBlobDetails(model[key])]));
};

const parseDelegateCertificateLinks = (model: Record<string, string>): Record<DocumentType, string> => {

    return Object.fromEntries(Object.keys(model).map(key => [DocumentType[key as keyof DocumentType], model[key]]));
};

const parseBlobDetails = (model: BlobDetails): BlobDetails => {

    return {
        ...model,
        createdDate: model.createdDate && moment(model.createdDate)
    };
};

export const hasSortedOrderChanged = <T> (array: T[], sortedByPropertyName: string): boolean => {
    const sortedArray = array.map(da => da[sortedByPropertyName]).sort((a: string, b: string) => a.localeCompare(b));
    return sortedArray.toString() !== array.map(da => da[sortedByPropertyName]).toString();
};

