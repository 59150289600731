import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";
import * as React from "react";
import {
    Checkbox,
    CheckboxProps,
    DropdownItemProps,
    DropdownProps,
    Form,
    Input,
    InputOnChangeData,
    InputProps,
    TextArea,
    TextAreaProps,
} from "semantic-ui-react";
import { InputType } from "./SettingsGrid";

interface AppSettingInputProps {
    inputType: InputType;
    value: string;
    dropdownNumberOptions?: DropdownItemProps[];
    onChange?: (event: any, { value }: InputProps | TextAreaProps) => void;
    onMarkdownChange?: (value: string) => void;
    onToggleChange?: (_: any, { checked }: CheckboxProps) => void;
    onNumberChange?: (_: any, { value }: InputOnChangeData) => void;
    onDropdownNumberChange?: (_: any, { value }: DropdownProps) => void;
}

export const AppSettingInput = (props: AppSettingInputProps) => {
    const { inputType, value, dropdownNumberOptions, onChange, onMarkdownChange, onToggleChange, onNumberChange,
        onDropdownNumberChange } = props;

    switch (inputType) {
        case InputType.Input:
            return (<Input fluid autoFocus type="text" value={value} onChange={onChange} />);
        case InputType.TextArea:
            return (<>
                <TextArea className={"setting-text-area"} autoFocus type="text" value={value} onChange={onChange} />
                <span className={"float-right"}>Character count:{value.length}</span>
            </>);
        case InputType.MarkdownEditor:
            return (<>
                <MarkdownEditor
                    value={value}
                    onChange={onMarkdownChange}
                    showErrors
                />
                <span className={"float-right"}>Character count:{value.length}</span>
            </>);
        case InputType.Toggle:
            return (<Checkbox toggle checked={value.toLowerCase() === "true"} onChange={onToggleChange} />);
        case InputType.Number:
            return (<Form.Input
                fluid
                autoFocus
                type="text"
                value={value}
                onChange={onNumberChange}
            />);
        case InputType.DropdownNumber:
            return ( <div className="field-wrapper">
                <Form.Dropdown
                    value={value ? +value : 0}
                    showErrors
                    fluid
                    selection
                    options={dropdownNumberOptions}
                    onChange={onDropdownNumberChange}
                />
            </div>);
        default:
            return <></>;
    }
};
