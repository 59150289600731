import { Dispatch } from "redux";
import { clarity } from "react-microsoft-clarity";
import { Payload, beginAjaxCall } from "@neworbit/redux-helpers";

import { AppCommonState } from "../appCommonState";

import { LOAD_APP_SETTINGS_SUCCESS } from "./actionTypes";
import { AppSettingsApi } from "./appSettingsApi";
import { AppSettings } from "./model";

export type AppSettingsAction =
    ({ type: LOAD_APP_SETTINGS_SUCCESS } & Payload<AppSettings>);

export function loadAppSettingsSuccess(payload: AppSettings): AppSettingsAction {
    return {
        payload,
        type: LOAD_APP_SETTINGS_SUCCESS
    };
}

export function loadAppSettings(useClarity: boolean = false, relatedOrganisationId: string = "") {
    const api = new AppSettingsApi();
    return async (dispatch: Dispatch, getState: () => AppCommonState) => {
        const state = getState().appSettings;
        if (!state.loaded) {
            dispatch(beginAjaxCall("loadAppSettings"));
            const appSettings = await api.load(relatedOrganisationId);
            if (useClarity && !clarity.hasStarted() && appSettings.claritySettings?.projectId) {
                clarity.init(appSettings.claritySettings.projectId);
            }
            dispatch(loadAppSettingsSuccess(appSettings));
        }
    };
}
