import * as React from "react";
import { InvoiceDetailModel, InvoiceFormat } from "../model";
import { InvoiceLineItemsTable } from "./InvoiceLineItemsTable";
import { InvoiceTotalsFooter } from "./invoiceTotalsFooter";
import { InvoiceDetailHeader } from "./invoiceDetailHeader";
import { InvoiceBonusLineItemsTable } from "./InvoiceBonusLineItemsTable";
import { PrintView } from "@common/print/PrintView";
import { LetterFooter } from "@common/print/LetterFooter";
import "./PrintableInvoiceDetails.scss";

interface PrintableInvoiceDetailProps {
    invoice: InvoiceDetailModel;
    invoiceFormat: InvoiceFormat;
    showPrintView: boolean;
    setShowPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PrintableInvoiceDetail: React.FC<PrintableInvoiceDetailProps> = ({ invoice, invoiceFormat, showPrintView, setShowPrint }) => {
    React.useEffect(() => {
        if (showPrintView) {
            window.print();
            setShowPrint(false);
        }
    }, [showPrintView, setShowPrint]);

    if (!invoice) {
        return <></>;
    }

    return (
        <PrintView>
            <div className="letter-body">
                <InvoiceDetailHeader invoice={invoice} />
                <InvoiceLineItemsTable lineItems={invoice.invoiceLineItems} vatPercentage={invoice.vatPercentage} invoiceFormat={invoiceFormat} />
                {invoiceFormat === InvoiceFormat.NewCombinedBonuses &&
                <>
                    <InvoiceLineItemsTable lineItems={invoice.invoiceSubcontractedToLineItems} vatPercentage={invoice.vatPercentage}
                        subcontractedType="to" invoiceFormat={invoiceFormat} />
                    <InvoiceBonusLineItemsTable bonusLineItems={invoice.invoiceBonusLineItems} />
                </>}
                <div className="course-details">
                    <InvoiceTotalsFooter invoiceTotal={invoice.invoiceTotal} vatPercentage={invoice.vatPercentage} />
                </div>
                {invoiceFormat === InvoiceFormat.NewCombinedBonuses &&
                <>
                    <InvoiceLineItemsTable lineItems={invoice.invoiceSubcontractedFromLineItems} vatPercentage={invoice.vatPercentage}
                        subcontractedType="from" invoiceFormat={invoiceFormat} />
                </>}
            </div>
            <LetterFooter />
        </PrintView>
    );
};

