import * as React from "react";
import { AddressLookupSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const AddressLookup: ConfigurationAreaComponent<AddressLookupSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="AddressLookup"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ApiKey", label: "Experian Api Key" },
            ]}
        />
    );
};
