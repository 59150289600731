import * as React from "react";

import { CoreDetailProps } from "../Detail";

export const ContextItems: React.FC<CoreDetailProps> = () => (
    <div />
    /*
    Replace the empty div with something like this to add a context link:
    <>
        <Menu.Item as={Link} href={`${this.props.basePath}/${this.props.trainerAttribute.id}/something`}>
            Something
        </Menu.Item>
    </>
    */
);
