import { Trainer } from "@common/crud/trainer";
import axios from "axios";
import moment from "moment";
import { StandbyTrainersCalendarDay, StandbyTrainersCalendar, StandbyTrainerAvailabilityForDay,
    StandbyTrainerCalendarSettings } from "./standbyTrainersCalendarModel";

export class StandbyTrainersApi {

    private apiUrl = "/api/standByTrainers";

    public async getStandbyTrainersForMonth(month: moment.Moment): Promise<StandbyTrainersCalendar> {
        const result = await axios.get<StandbyTrainersCalendar>(`${this.apiUrl}/${month.toISOString()}`);
        return StandbyTrainersApi.parseStandbyTrainersCalendar(result.data);
    }

    public async getAllStandbyTrainers(): Promise<Trainer[]> {
        const result = axios.get<Trainer[]>(`${this.apiUrl}/standbyTrainers`);
        return (await result).data;
    }

    public async getStandbyTrainersForDay(day: moment.Moment): Promise<StandbyTrainerAvailabilityForDay[]> {
        const result = await axios.get<StandbyTrainerAvailabilityForDay[]>(`${this.apiUrl}/standbyTrainers/${day.toISOString()}`);
        return result.data;
    }

    public async saveChangesToCalendar(month: moment.Moment, changes: StandbyTrainersCalendarDay[]): Promise<StandbyTrainersCalendarDay[]> {
        const result = await axios.patch<StandbyTrainersCalendarDay[]>(`${this.apiUrl}/${month.toISOString()}`, changes);
        return result.data.map(StandbyTrainersApi.parseStandbyTrainersCalendarDay);
    }

    public async getStandbyTrainerCalendarSettings(): Promise<StandbyTrainerCalendarSettings> {
        const response = await axios.get(`${this.apiUrl}/getStandbyTrainerCalendarSettings`);
        const data = response.data as StandbyTrainerCalendarSettings;
        return data;
    }

    public async saveStandbyTrainerCalendarSettings(standbyTrainerCalendarSettings: StandbyTrainerCalendarSettings): Promise<void> {
        await axios.post(`${this.apiUrl}/saveStandbyTrainerCalendarSettings`, standbyTrainerCalendarSettings);
    }

    public static parseStandbyTrainersCalendar(calendar: StandbyTrainersCalendar): StandbyTrainersCalendar {
        return {
            ...calendar,
            month: moment(calendar.month),
            days: calendar.days.map(StandbyTrainersApi.parseStandbyTrainersCalendarDay)
        };
    }

    public static parseStandbyTrainersCalendarDay(day: StandbyTrainersCalendarDay): StandbyTrainersCalendarDay {
        return {
            ...day,
            date: moment(day.date),
        };
    }
}
