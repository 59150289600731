import axios from "axios";
import { CreateEditTrainerCpdRecordModel, TrainerCpdRecordModel } from "./model";
import moment from "moment";

export class TrainerCpdApi {
    private readonly apiUrl: string;

    constructor(trainerId?: string) {
        this.apiUrl = trainerId ? `/api/trainerCpd/${trainerId}` : "/api/trainerCpd";
    }

    public async getTrainerCpdRecords(): Promise<TrainerCpdRecordModel[]> {
        const response = await axios.get(this.apiUrl);
        const records = response.data as TrainerCpdRecordModel[];
        return records.map(this.parseModel);
    }

    public async createTrainerCpdRecord(model: CreateEditTrainerCpdRecordModel) {
        return axios.post(this.apiUrl, model);
    }

    public async updateTrainerCpdRecord(model: CreateEditTrainerCpdRecordModel) {
        return axios.put(this.apiUrl, model);
    }

    public async deleteTrainerCpdRecord(id: string) {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public parseModel(model: TrainerCpdRecordModel): TrainerCpdRecordModel {
        return {
            ...model,
            recordDate: model.recordDate && moment(model.recordDate)
        };
    }
}
