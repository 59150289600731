import { TtcPlannerRole } from "@common/auth/model";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "reauthorize";
import { Button, Modal } from "semantic-ui-react";
import { setEventInstanceAvailabilityForOtherTrainers } from "../../actions";
import { dropdownItemSelected } from "@common/validation/dropdownItemValidator";
import { TrainerAvailabilityRoleType, TrainerAvailabilityRoleTypeEnum } from "../../model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { basePathSelector, eventInstanceSelector } from "../../selectors";
import { isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";

interface Props {
    trainerAvailabilityRoleType: TrainerAvailabilityRoleTypeEnum;
    roleAvailability: TrainerAvailabilityRoleTypeEnum[];
    eventInstanceId: string;
    canHaveMoreThanOneTrainer?: boolean;
    eventInstanceDeliveryType: DeliveryTypeEnum;
}

const MAX_NUMBER_OF_TRAINERS_NEEDED = 12;
const numberOfTrainersDropdownOptions =
    Array(MAX_NUMBER_OF_TRAINERS_NEEDED + 1).fill(null).map((_, index) => ({ value: index, text: index.toString() }));

export const ModuleAvailabilityModalForPlanner = (props: Props) => {
    const {
        trainerAvailabilityRoleType,
        roleAvailability,
        eventInstanceId,
        canHaveMoreThanOneTrainer,
        eventInstanceDeliveryType
    } = props;

    const [open, setOpen] = React.useState(false);
    const [numberOfTrainersToAllocate, setNumberOfTrainersToAllocate] = React.useState<number>(1);
    const eventInstance = useSelector(eventInstanceSelector);
    const basePath = useSelector(basePathSelector);

    const dispatch = useDispatch();

    const availableForOtherTrainers = !!roleAvailability?.length && roleAvailability.includes(trainerAvailabilityRoleType);
    const trainerTypeText = TrainerAvailabilityRoleType[trainerAvailabilityRoleType].toLowerCase();

    function openModal() {
        setOpen(true);
    }

    function onContinueClick() {
        dispatch(setEventInstanceAvailabilityForOtherTrainers(
            eventInstanceId, availableForOtherTrainers ? 0 : numberOfTrainersToAllocate, trainerAvailabilityRoleType, basePath, eventInstanceDeliveryType));
        reset();
    }

    function reset() {
        setOpen(false);
        setNumberOfTrainersToAllocate(1);
    }

    function changeNumberOfTrainersToAllocate(numberToAllocate: number) {
        setNumberOfTrainersToAllocate(numberToAllocate);
    }

    function onMakeAvailableClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.currentTarget.blur();
        if (eventInstanceDeliveryType === DeliveryTypeEnum.Digital) {
            onContinueClick();
        } else {
            openModal();
        }
    }

    function buttonDisabled() {
        if (trainerAvailabilityRoleType === TrainerAvailabilityRoleTypeEnum.Theory) {
            const maxTrainers = (isEventInstanceWorkflowCorporate(eventInstance) || eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital)
                ? 1
                : 2;
            return eventInstance.trainers?.length >= maxTrainers;
        }

        return eventInstance.practicalTrainers?.length >= MAX_NUMBER_OF_TRAINERS_NEEDED;
    }

    return (
        <>
            <Button onClick={onMakeAvailableClick} className={availableForOtherTrainers? "undo-action" : ""} disabled={buttonDisabled()}>
                {`Make ${availableForOtherTrainers ? "unavailable" : "available"} - ${trainerTypeText}`}
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    {availableForOtherTrainers ?
                        `Would you like to stop this course showing as available for ${trainerTypeText} trainers?` :
                        `Would you like to make this course available for ${trainerTypeText} trainers?`
                    }
                </Modal.Header>
                <Modal.Content>
                    <Authorize authorize={[TtcPlannerRole]}>
                        {!availableForOtherTrainers && canHaveMoreThanOneTrainer &&
                            <Input.DropdownNumber
                                label={`How many ${trainerTypeText} trainers do you need?`}
                                validation={[dropdownItemSelected()]}
                                value={numberOfTrainersToAllocate}
                                options={numberOfTrainersDropdownOptions}
                                onChange={changeNumberOfTrainersToAllocate}
                            />}
                    </Authorize>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={reset} className="cancel-action">
                        Cancel
                    </Button>
                    <Authorize authorize={[TtcPlannerRole]}>
                        <Button
                            onClick={onContinueClick}
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content="Continue"
                        />
                    </Authorize>
                </Modal.Actions>
            </Modal>
        </>
    );

};
