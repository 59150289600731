import { User } from "reauthorize";
import { Moment } from "moment";
import { CustomerStatusEnum } from "@common/crud/alaskaNudgeTask/model";
import { BusinessLineType } from "@common/crud/organisation/model";

// This isn't ideal, but see \Ttc.Models\Auth\Role.cs
export const TrainerRole = "Trainer";
export const TtcPlannerRole = "TtcPlanner";
export const TtcSystemConfiguratorRole = "TtcSystemConfigurator";
export const PoliceAndDdrsAdminRole = "TtcEventAdmin";
export const EventTypeAdmin = "TtcEventTypeAdmin";
export const TtcAttributeManager = "TtcAttributeManager";
export const TtcVenueAdminRole = "TtcVenueAdmin";
export const TtcTrainerAdminRole = "TtcTrainerAdmin";
export const TtcClientAdvisorRole = "TtcClientAdvisor";
export const TtcSeniorClientAdvisorRole = "TtcSeniorClientAdvisor";
export const TtcFinanceAdministratorRole = "TtcFinanceAdministrator";
export const TtcPaymentHandlerRole = "TtcPaymentHandler";
export const TtcCorporateEventAdmin = "AlaskaTTCCorporateEventAdmin";
export const BusinessDriverEventAdmin = "AlaskaBusinessDriverEventAdmin";
export const DdrsAdmin = "DdrsAdmin";
export const CorporateHomeRole = "CorpAdmin";
export const ConstructionHomeRole = "AlaskaConstructionAdmin";
export const AttendeeRole = "Attendee";
export const PoliceRole = "Police";
export const TtcRoles = [TtcSystemConfiguratorRole, PoliceAndDdrsAdminRole, CorporateHomeRole];
export const EventManagementAdminRoles = [PoliceAndDdrsAdminRole, TtcVenueAdminRole, TtcTrainerAdminRole, TtcClientAdvisorRole, TtcPlannerRole];
export const EventManagementAdminRolesAndTrainers = [...EventManagementAdminRoles, TrainerRole];
export const MonitorRole = "Monitor";
export const EventManagementAdminRolesAndMonitors = [...EventManagementAdminRoles, MonitorRole];
export const IqaTeamRole = "IqaTeam";
export const TtcTester = "TtcTester";
export const AnonCorporateBookingUser = "AnonCorporateBookingUser";
export const CorporateBookingUser = "CorporateBookingUser";
export const AnonConstructionBookingUser = "AnonConstructionBookingUser";
export const ConstructionBookingUser = "ConstructionBookingUser";
export const DataDeleter = "DataDeleter";

export interface CurrentUser extends User {
    fullName?: string;
    loaded: boolean;
    adB2CId?: string;
    id: string;
    email?: string;
    forename?: string;
    surname?: string;
    created?: Moment;
    forceId?: number;
    telephone?: string;
    upcomingNewsAndCoursesNewsletter?: boolean;
    organisationName?: string;
    customerStatus?: CustomerStatusEnum;
    organisationSettings?: {
        isBookNowPayLaterActive: boolean;
    };
}

export interface UpdatedCurrentUser extends CurrentUser {
    errorUpdatingEmail?: boolean;
    errorUpdatingOrganisation?: boolean;
}

export interface AuthState {
    currentUser: CurrentUser;
}

export const initialState: CurrentUser = {
    authenticated: false,
    loaded: false,
    adB2CId: null,
    id: null,
    email: null,
    forename: null,
    surname: null,
    created: null,
    roles: [],
    forceId: null
};

export const businessLineTypeForUser = (user: User): BusinessLineType => {
    if (user.roles.includes(CorporateBookingUser) || user.roles.includes(AnonCorporateBookingUser)) {
        return BusinessLineType.Corporate;
    }

    if (user.roles.includes(ConstructionBookingUser) || user.roles.includes(AnonConstructionBookingUser)) {
        return BusinessLineType.Construction;
    }

    return BusinessLineType.None;
};
