import { Country, CountryEnum } from "@common/crud/organisation/model";
import * as React from "react";
import { Button, Checkbox, Icon, Modal, Popup, Table } from "semantic-ui-react";
import { DdrsAllCountriesFeeConfig, DiscountTypeEnum, DiscountTypes, initialFeeEditable, nullFeesObj } from "../../model";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface CommonFeeTableProps {
    ddrsSchemeFeeConfig: DdrsAllCountriesFeeConfig;
    push?: (path: string) => void;
    create?: boolean;
}

type ConditionalFeeTableProps =
    { editable?: false; showErrors?: never; onChange?: never  }
    | { editable: true; showErrors: boolean; onChange: React.Dispatch<React.SetStateAction<DdrsAllCountriesFeeConfig>> }

type DdrsFeesTableProps = CommonFeeTableProps & ConditionalFeeTableProps;
type AllowedCountryValues = Exclude<CountryEnum, CountryEnum.None>;
enum DDRSDiscountHeaders{
    DiscountType,
    Weekday,
    Concessions,
    EarlyBird,
    ConcessionsAndEarlyBird,
    WeekendSupplement
}

export const DdrsFeesTable: React.FC<DdrsFeesTableProps> =
({ ddrsSchemeFeeConfig, showErrors, onChange, editable, push, create }) =>

{
    const initialEnabledState = {
        [CountryEnum.England]: false,
        [CountryEnum.Wales]: false,
        [CountryEnum.Scotland]: false,
        [CountryEnum.NIreland]: false };
    const [open, setOpen] = React.useState(false);
    const [countriesEnabled, setCountriesEnabled] = React.useState<Dictionary<boolean>>(initialEnabledState);

    const crudSuffix = create? "create":"edit";
    const feeConfig = ddrsSchemeFeeConfig ?? initialFeeEditable;
    const noCountriesEnabled = Object.values(countriesEnabled).every(e => !e);

    function onFeeChange(country: AllowedCountryValues, feeType: string) {

        return function(field: DiscountTypeEnum) {
            onChange({ ...feeConfig,

                [country]: [{ ...feeConfig[country][0], value: { ...feeConfig[country][0].value, [feeType]: field } }]

            });
        };
    }

    function onDiscountTypeChange(country: AllowedCountryValues) {

        return function(field: DiscountTypeEnum) {

            const feeKeys = ObjectKeys(feeConfig[country][0].value);

            const newEntries= feeKeys.map((key, index) => {

                const value = key==="discountType"?field:
                    required(field, index)|| weekendSupplementEnabled(index)? feeConfig[country][0].value[key]:null;
                return [key,value];});

            onChange({ ...feeConfig,

                [country]: [{ ...feeConfig[country][0], value: Object.fromEntries(newEntries) }]

            });
        };

    }

    const defaultEnabled = (index: DDRSDiscountHeaders) => weekdayEnabled(index)||weekendSupplementEnabled(index);
    const weekdayEnabled=(index: DDRSDiscountHeaders) => index=== DDRSDiscountHeaders.Weekday;
    const weekendSupplementEnabled= (index: DDRSDiscountHeaders) => index=== DDRSDiscountHeaders.Weekday ||
        index=== DDRSDiscountHeaders.WeekendSupplement;

    const concessionEnabled = (index: DDRSDiscountHeaders) => index === DDRSDiscountHeaders.Concessions;
    const earlyBirdEnabled = (index: DDRSDiscountHeaders) =>
        index === DDRSDiscountHeaders.EarlyBird;

    const bothEnabled = (index: DDRSDiscountHeaders) =>
        index ===DDRSDiscountHeaders.EarlyBird ||
        index === DDRSDiscountHeaders.Concessions || index === DDRSDiscountHeaders.ConcessionsAndEarlyBird;

    const required = (discountType: DiscountTypeEnum, index: DDRSDiscountHeaders) => {

        switch (discountType) {

            case DiscountTypeEnum.Both:
                return bothEnabled(index) || weekdayEnabled(index);
            case DiscountTypeEnum.Concession:
                return concessionEnabled(index) || weekdayEnabled(index);
            case DiscountTypeEnum.EarlyBird:
                return earlyBirdEnabled(index) || weekdayEnabled(index);
            case DiscountTypeEnum.None:
            default:
                return  weekdayEnabled(index);
        }

    };

    const requiredCompleted = ObjectKeys(feeConfig).filter(country => countriesEnabled[+country]).map(enabledCountry =>
    { const discountObj= feeConfig[enabledCountry][0].value;
        return ObjectKeys(discountObj).filter((_key, index) =>
            required(discountObj.discountType, index)).every(feeKey =>
            discountObj[feeKey] !== null && discountObj[feeKey] >= 0);}).every(e => e);

    function onCountryCheckbox(country: CountryEnum) {

        return () => {
            onChange({ ...feeConfig,
                [country]: [nullFeesObj] });

            setCountriesEnabled({ ...countriesEnabled, [country]: !countriesEnabled[country] });;
        };

    }

    function onOpen() {

        const allFieldsNull = Object.values(feeConfig).map(v => Object.values(v[0].value)
            .every(o =>  !o)).every(e => e);

        setOpen(true);
        if (push && !isNullOrUndefined(create) ) {push(`courseFees/feesOpen/${crudSuffix}`);};

        if (allFieldsNull && !noCountriesEnabled) {

            setCountriesEnabled(initialEnabledState);

        }

    }

    function onClose() {
        setOpen(false);
        setCountriesEnabled(initialEnabledState);
        if (editable) {
            onChange(null);
        }

        if (push && !isNullOrUndefined(create)) {push(`courseFees/${crudSuffix}`);};
    }

    function onSave() {
        setOpen(false);
        if (push && !isNullOrUndefined(create)) {push(crudSuffix);};
    }
    const feesTableBody = (<Table.Body>{feeConfig && ObjectKeys(feeConfig).map(
        c => {

            const cellValueObject = feeConfig[c][0].value;
            return (<Table.Row key={c}>
                <Table.Cell>
                    {Country[c]}
                </Table.Cell>

                {editable &&
                <Table.Cell width={1} textAlign={"center"} >
                    <Checkbox checked={countriesEnabled[c]} onChange={onCountryCheckbox(+c as CountryEnum)} />
                </Table.Cell>}

                {ObjectKeys(cellValueObject).map((feeType, index) => {

                    const cellValue = cellValueObject[feeType];
                    const detailCellValue = feeType === "discountType" ? DiscountTypes[cellValueObject.discountType] : cellValue;

                    const currencyInputEnabled = defaultEnabled(index) ||
                     (cellValueObject.discountType === DiscountTypeEnum.Concession && concessionEnabled(index))
                     ||  (cellValueObject.discountType === DiscountTypeEnum.EarlyBird && earlyBirdEnabled(index)) ||
                     (cellValueObject.discountType === DiscountTypeEnum.Both && bothEnabled(index));

                    const createEditCellValue = editable && (feeType ==="discountType" ?(
                        <ExtendedDropdownNumber
                            value={cellValue}
                            disabled={!countriesEnabled[c]}
                            showErrors={showErrors}
                            options={ObjectKeys(DiscountTypes).map(k => ({ text: DiscountTypes[k], value: +k }))}
                            onChange={onDiscountTypeChange(+c)}
                            search
                        />) :(
                        <CurrencyInput
                            disabled={!currencyInputEnabled || !countriesEnabled[c]}
                            value={cellValue ?? ""}
                            required
                            showErrors={showErrors}
                            onChange={onFeeChange(+c, feeType)}
                            nonNegative
                        />));

                    const formattedCellValue =   editable? createEditCellValue :
                        feeType !== "discountType"?
                            detailCellValue ?
                                `£${detailCellValue}`: "N/A" : detailCellValue;

                    return (
                        <Table.Cell key={feeType}>{formattedCellValue}</Table.Cell>
                    );})}
            </Table.Row>);}
    )}</Table.Body>);

    return  (<>

        <Modal
            size={"fullscreen"}
            onClose={onClose}
            onOpen={onOpen}
            open={open}
            trigger={<Button>{editable?
                ddrsSchemeFeeConfig?"Edit":"Create New":"View"}</Button>}
        >
            <Modal.Content>
                <Table definition celled columns={editable? 8:7} size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            {editable && <Table.HeaderCell width={1} />}
                            <Table.HeaderCell>Discount Type</Table.HeaderCell>
                            <Table.HeaderCell>Weekday</Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup trigger={<span>Concessions <Icon circular name="info" /></span>}
                                    content="Amount to be deducted from course fee" basic />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup trigger={<span>Early Bird <Icon circular name="info" /></span>} content="Amount to be deducted from course fee" basic />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup trigger={<span>Concessions &amp; Early Bird <Icon circular name="info" /></span>}
                                    content="Amount to be deducted from course fee" basic />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup trigger={<span>Weekend Supplement <Icon circular name="info" /></span>}
                                    content="Amount to be added to course fee" basic />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {feesTableBody}
                </Table>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={onClose}
                        className="cancel-action"
                    />
                    {editable && <Button
                        content="Save"
                        disabled={!requiredCompleted || noCountriesEnabled}
                        onClick={onSave}
                        positive
                    />}

                </Modal.Actions>
            </Modal.Content>
        </Modal>
    </>);}
;
