/* eslint-disable max-lines */
import { Location } from "redux-little-router";
import moment from "moment";
import { TrainerAttribute } from "../common/TrainerAttribute";
import { Address } from "../common/Address";
import { EventInstanceListModel } from "../eventInstance";
import { HistoryRecord } from "@common/history/model";
import { AuthState } from "@common/auth";
import { AppCommonState } from "@common/appCommonState";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { PassFailResultEnum } from "@common/crud/trainer/trainerMonitoringModel";
import { OtherTrainerTypeCategoryEnum, SubcontractingProcess, TrainerType } from "../eventInstance/model";
import { BusinessLineType, CountryEnum } from "@common/crud/organisation/model";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { WorkflowTypeEnum } from "../eventType/model";

export interface TrainerListModel {
    readonly id: string;
    readonly fullName?: string;
    readonly listUpdated?: moment.Moment;
    readonly email?: string;
    readonly mobileNumber?: string;
    readonly nextMonitoringDue?: moment.Moment;
    readonly expiryDate?: moment.Moment;
    readonly deletionDate?: moment.Moment;
    readonly unassignedAvailableSessionsCount?: number;
    readonly preferredNoDaysPerMonth?: number;
    readonly preferredNoSessionsPerDay?: number;
    readonly unverifiedTrainerAttributes?: TrainerAttribute[];
    readonly isAnonymized?: boolean;
    readonly businessLineTypes?: BusinessLineType[];
}

export interface TrainerDetailModel {
    readonly id: string;
    readonly adB2CId?: string;
    readonly title?: string;
    readonly forename?: string;
    readonly surname?: string;
    readonly employmentType?: TrainerEmploymentTypeEnum;
    readonly fullName?: string;
    readonly address?: Address;
    readonly vatNumber?: string;
    readonly companyName?: string;
    readonly companyType?: CompanyTypeEnum;
    readonly companyRegistrationNumber?: string;
    readonly companyAddress?: Address;
    readonly bankAccount?: {
        sortCode?: string;
        accountName?: string;
        accountNumber?: string;
    };
    readonly email?: string;
    readonly mobileNumber?: string;
    readonly homeNumber?: string;
    readonly emergencyContact?: {
        name?: string;
        phoneNumber?: string;
    };
    readonly trainerAttributes?: TrainerAttribute[];
    readonly ndorsLicenceNumber?: string;
    readonly nriLicenceNumber?: string;
    readonly detailUpdated?: moment.Moment;
    readonly availabilityDistance?: number;
    readonly availableVenueIds?: string[];
    readonly history?: HistoryRecord[];
    readonly areaIds?: string[];
    readonly ddrsCountries?: CountryEnum[];
    readonly expiryDate?: moment.Moment;
    readonly deletionDate?: moment.Moment;
    readonly lastLogin?: moment.Moment;
    readonly lastContinuumAttributesSyncDateTime?: moment.Moment;
    readonly homeArea?: string;
    readonly errorUpdatingEmail?: boolean;
    readonly bankAccountDetailsAreHidden?: boolean;
    readonly isAnonymized?: boolean;
    readonly businessLineTypes?: BusinessLineType[];
}

export type Trainer = TrainerListModel & TrainerDetailModel;

export interface TrainerCreateEditModel {
    id: string;
    title: string;
    forename: string;
    surname: string;
    employmentType: number;
    name: string;
    address: Address;
    vatNumber: string;
    companyName: string;
    companyType: number;
    companyRegistrationNumber: string;
    companyAddress: Address;
    bankAccount: {
        sortCode?: string;
        accountNumber?: string;
        accountName?: string;
    };
    email: string;
    mobileNumber: string;
    homeNumber: string;
    emergencyContact: {
        name?: string;
        phoneNumber?: string;
    };
    trainerAttributes?: TrainerAttribute[];
    ndorsLicenceNumber?: string;
    areaIds?: string[];
    ddrsCountries?: CountryEnum[];
    expiryDate?: moment.Moment;
    homeArea?: string;
    adB2CAccountForRemovalId?: string;
    businessLineTypes: number[];
}

export interface TrainerBankAccountCreateEditModel {
    sortCode?: string;
    accountNumber?: string;
    accountName?: string;
}

export interface TrainerState {
    readonly trainers: Trainer[];
    readonly trainerMonitoringPlanner: TrainerLicencesMonitoringModel[];
}

export enum CompanyTypeEnum {
    Unknown = 0,
    SoleTrader = 1,
    LimitedCompany = 2
}

export const CompanyType = {
    [CompanyTypeEnum.Unknown]: "Unknown",
    [CompanyTypeEnum.SoleTrader]: "Sole Trader",
    [CompanyTypeEnum.LimitedCompany]: "Limted Company"
};

export enum LicenseStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}

export const LicenseStatus = {
    [LicenseStatusEnum.All]: "All",
    [LicenseStatusEnum.Active]: "Active",
    [LicenseStatusEnum.Inactive]: "Inactive"
};

export enum MonitoringDueEnum {
    All = 0,
    Overdue = 1,
    OneMonth = 2,
    ThreeMonths = 3
}

export const MonitoringDue = {
    [MonitoringDueEnum.All]: "All",
    [MonitoringDueEnum.Overdue]: "Overdue",
    [MonitoringDueEnum.OneMonth]: "One Month",
    [MonitoringDueEnum.ThreeMonths]: "Three Months"
};

export enum MonitoringDeliveryTypeEnum {
    All = 0,
    Classroom = 1,
    Digital = 2
}

export const MonitoringDeliveryType = {
    [MonitoringDeliveryTypeEnum.All]: "All",
    [MonitoringDeliveryTypeEnum.Classroom]: "Classroom",
    [MonitoringDeliveryTypeEnum.Digital]: "Digital",
};

export enum TrainerEmploymentTypeEnum {
    SelfEmployed = 1,
    Employed = 2
}

export const TrainerEmploymentType = {
    [TrainerEmploymentTypeEnum.SelfEmployed]: "Self-employed",
    [TrainerEmploymentTypeEnum.Employed]: "Employed",
};

export type AppState = TrainerState & { router: Location };

export interface TrainerDashboardModel {
    forename: string;
    nextCourse?: EventInstanceListModel;
    coursesThisWeek: EventInstanceListModel[];
    outstandingCourseRegisters: EventInstanceListModel[];
    businessLineTypes: BusinessLineType[];
}

export interface AvailableDeliveryTypes {
    classroom: boolean;
    digital: boolean;
}

export interface TrainerToSwapAndAllocateToDetails {
    trainerToSwapId: string;
    trainerToSwapType: TrainerType;
    startSubcontractingProcess: boolean;
    swapAgreedDate: moment.Moment;
    trainerAllocation:  TrainerAllocationModel;
}

export interface TrainerAllocationModel {
    id: string;
    fee: number;
    sundries: number;
    allocateToCourses?: string[];
    dayNumbers?: number[];
    subcontractingProcess?: SubcontractingProcess;
    carType?: string;
    availableForOtherTrainers?: boolean;
    dateMadeAvailableForOtherTrainers?: moment.Moment;
    otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum;
    revalidateCourseFeeNonStandard?: boolean;
}

export interface SearchOptions {
    searchString?: string;
    monitoringDue?: MonitoringDueEnum;
    eventTypes?: string[];
    deliveryType?: DeliveryTypeEnum;
    homeArea?: string[];
    optedInVenue?: string;
    ddrsAttributes?: boolean;
    showExpired?: boolean;
    workflows?: WorkflowTypeEnum[];
}

export interface TrainerLicencesMonitoringModel {
    id: string;
    trainerName: string;
    licenceMonitoringSchemeModels: LicenceMonitoringSchemeModel[];
}

export interface LicenceMonitoringSchemeModel {
    abbreviation: string;
    scheme: number;
    expiryDate?: moment.Moment;
    lastMonitored?: moment.Moment;
    nextMonitoringDue?: moment.Moment;
    nextMonitoringSession?: moment.Moment;
    lastDigitalMonitored?: moment.Moment;
    nextDigitalMonitoringDue?: moment.Moment;
    nextDigitalMonitoringSession?: moment.Moment;
    isDigital?: boolean;
    dorsStatus?: number;
    dateCreated?: moment.Moment;
}

export interface TrainerAttributeUpdateModel {
    id: string;
    eventInstanceId: string;
    trainerAttribute: TrainerAttribute;
}

export interface EditZoomAccountModel {
    zoomAccount: string;
    zoomPassword: string;
}

export interface EditNdorsLicenceNumberModel {
    ndorsLicenceNumber: string;
}

export interface EditNriLicenceNumberModel {
    nriLicenceNumber: string;
}

export interface TrainerDetailUpdateModel {
    trainer: TrainerDetailModel;
    success: boolean;
    errorMessage: string;
}

export interface BulkFreeTypeCommsModel {
    isEmail: boolean;
    emailSubject: string;
    freeTypeMessage: string;
    trainerIds: string[];
    recordComms: boolean;
    from: string;
    attachments?: File[];
}

export interface MonitoringReportResponse {
    reports: MonitoringReportHistory[];
    pageCount: number;
}

export interface MonitoringReportHistory {
    id: string;
    venueName: string;
    schemeAbbreviation: string;
    courseDate: moment.Moment;
    monitorName: string;
    deliveryType: DeliveryTypeEnum;
    passFailResult: Exclude<PassFailResultEnum, PassFailResultEnum.UnResolved>;
    relatedEventInstanceId: string;
    score?: string;
    path?: string;
    trainerName: string;
}

export interface BulkEmailTemplateCommsModel {
    trainerIds: string[];
    templateId: number;
    templateName: string;
    recordComms: boolean;
    from: string;
}

export interface TrainerCpdRecordModel {
    id: string;
    trainerId: string;
    recordDate: moment.Moment;
    title: string;
    description: string;
}

export interface CreateEditTrainerCpdRecordModel {
    id?: string;
    recordDate: moment.Moment;
    title: string;
    description: string;
}

export interface TrainerJourneyNoteModel {
    note: string;
    complaint: boolean;
}

export interface TrainerWithAttributeModel {
    trainer: TrainerDetailModel;
    attributeDefinitionId: string;
    attributeHeldSince: string;
}

export interface ADTrainerUser {
    id: string;
    adB2CId: string;
    fullName: string;
    adB2CAccountCreated: moment.Moment;
    existsInAlaska: boolean;
}

export interface OptSchemeTrainerModel {
    trainerId: string;
    isDigital: boolean;
    optSchemeTrainerAttributes: OptSchemeTrainerAttributeModel[];
}

export interface OptSchemeTrainerAttributeModel {
    attributeDefinitionId: string;
    isOptedOut: boolean;
    lastMonitored: moment.Moment;
    nextMonitoringDue: moment.Moment;
    nextMonitoringSession: moment.Moment;
}

export interface EventInstanceCountForAttributeModel {
    attributeDefinitionId: string;
    eventInstanceCount: number;
}

export enum TabNames {
    Details = "Details",
    CompanyInfo = "Billing Information",
    Attributes = "Attributes",
    Availability = "Availability",
    WorkingRadius = "Working Radius",
    Invoices = "Invoices",
    Schedule = "Schedule",
    History = "Trainer Journey",
    Areas = "Areas",
    Management = "Management",
    Cpd = "CPD",
}

export enum TabPaths {
    Details = "/",
    CompanyInfo = "/companyInformation",
    Attributes = "/attributes",
    Availability = "/availability",
    WorkingRadius = "/workingRadius",
    Invoices = "/invoices",
    Schedule = "/schedule",
    History = "/history",
    Areas = "/areas",
    Management = "/management",
    Cpd = "/cpd",
}

export const AttributeRouteTitles = {
    "ndors-and-court": "NDORS/Court",
    "commercial": "Commercial",
    "construction": "Construction",
    "compliance": "Compliance"
} as const;

export const AttributeRoutes = ObjectKeys(AttributeRouteTitles);

export type AttributeRoute = keyof typeof AttributeRouteTitles;

export type DetailState = AppState & AuthState & AppCommonState;
