import * as React from "react";
import moment from "moment";

import { TrainerAttributeDefinition, TrainerAttributeType } from "../trainerAttributeDefinition";

import { TrainerAttribute } from "./TrainerAttribute";
import { useSelector } from "react-redux";
import { isUserAttributeManager } from "./selectors";
import { Dropdown, DropdownProps } from "semantic-ui-react";

interface TrainerAttributesPickerProps {
    trainerAttributeDefinitions: TrainerAttributeDefinition[];
    trainerAttributes: TrainerAttribute[];
    showErrors: boolean;
    onChange: (value: TrainerAttribute[], valid: boolean) => void;
}

export const TrainerAttributesPicker: React.FC<TrainerAttributesPickerProps> = (props) => {
    const isAttributeManager = useSelector(isUserAttributeManager);

    const handleAddDelete = React.useCallback((_, data: DropdownProps) => {
        const values = data.value as string[];
        let trainerAttributes = props?.trainerAttributes ? [...props.trainerAttributes] : [];

        if (trainerAttributes.length > values.length) {
            trainerAttributes = trainerAttributes.filter(trainerAttribute => values.indexOf(trainerAttribute.attributeDefinitionId) > -1);
        }

        if (trainerAttributes.length < values.length) {
            const addedId = values.find(elem => !trainerAttributes.some(attr => attr.attributeDefinitionId === elem));
            const attributeDefinition = props.trainerAttributeDefinitions.find(x => x.id === addedId);
            trainerAttributes.push({
                attributeDefinitionId: addedId,
                name: attributeDefinition.name,
                expiryDate: undefined,
                lastMonitoredDate: attributeDefinition.lastMonitoredDate ? moment() : undefined,
                nextMonitoringDue: attributeDefinition.nextMonitoringDue ? moment() : undefined,
                nextMonitoringSessionPlanned: attributeDefinition.nextMonitoringSessionPlanned ? moment() : undefined,
                complianceStatus: attributeDefinition.attributeType === TrainerAttributeType.Continuum ? 1 : 0,
                dorsStatus: 0
            });
        }

        props.onChange(trainerAttributes, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.trainerAttributes, props.trainerAttributeDefinitions, props.onChange]);

    const { trainerAttributes, trainerAttributeDefinitions } = props;

    const trainerAttributeIds = trainerAttributes && trainerAttributes.length > 0 ?
        trainerAttributes.map(attr => attr.attributeDefinitionId) : [];
    const trainerAttributeDefinitionsDropdown = trainerAttributeDefinitions && trainerAttributeDefinitions.length > 0 ?
        trainerAttributeDefinitions.map(elem => ({ value: elem.id, text: elem.name })) : [];

    return (
        <>
            <Dropdown
                fluid
                multiple
                search
                onChange={handleAddDelete}
                selection
                value={trainerAttributeIds}
                disabled={!isAttributeManager}
                options={trainerAttributeDefinitionsDropdown}
            />
        </>
    );
};
