import { TrainerCpdRecordModel } from "@common/crud/trainer/model";
import { routeTrainerIdSelector } from "@common/crud/trainer/selectors";
import { TrainerCpdApi } from "@common/crud/trainer/trainerCpdApi";
import { Spinner } from "@common/global";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Table } from "semantic-ui-react";
import { CpdRecordModal } from "./CpdRecordModal";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export const CpdRecords = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [records, setRecords] = React.useState<TrainerCpdRecordModel[]>(null);
    const [loading, setLoading] = React.useState(false);
    const [openRecord, setOpenRecord] = React.useState<TrainerCpdRecordModel>(null);
    const trainerId = useSelector(routeTrainerIdSelector);

    React.useEffect(() => {
        const getRecords = async () => {
            setLoading(true);
            const data = await new TrainerCpdApi(trainerId).getTrainerCpdRecords();
            setRecords(data);
            setLoading(false);
        };
        if (!modalOpen) {
            getRecords();
            setOpenRecord(null);
        }
    }, [modalOpen, trainerId]);

    function onAddNewCpdRecordClick() {
        setModalOpen(true);
    }

    function onViewEditClick(record: TrainerCpdRecordModel) {
        return () => {
            setOpenRecord(record);
            setModalOpen(true);
        };
    }

    return (
        <>
            <CpdRecordModal open={modalOpen} record={openRecord} trainerId={trainerId} setOpen={setModalOpen} />
            <Spinner active={loading}>
                <Grid stackable container className="no-left-margin">
                    <Grid.Row className="margin-top-bottom">
                        <Grid.Column width={10} verticalAlign="middle">
                            <h3>{!trainerId && "My "}CPD records</h3>
                        </Grid.Column>
                        <Grid.Column width={6} stretched className="detail-with-copy">
                            <Button content="Add new CPD record" onClick={onAddNewCpdRecordClick} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Table celled className="fixed-table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={4}>CPD completion date</Table.HeaderCell>
                                        <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Edit</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {records?.map(r => (
                                        <Table.Row key={r.id}>
                                            <Table.Cell>{r.recordDate.format(DateFormat.Short)}</Table.Cell>
                                            <Table.Cell>{r.description}</Table.Cell>
                                            <Table.Cell><a onClick={onViewEditClick(r)} className="cursor-pointer">Edit</a></Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Spinner>
        </>
    );
};
