import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { EventInstanceDetails, Order } from "../model";
import { OrderApi } from "../orderApi";
import { Rebook } from "./Rebook";
import { useSelector } from "react-redux";
import { businessLineTypeSelector } from "@common/redux-helpers";

export interface RefundModalProps {
    order: Order;
    selectedEventInstance?: EventInstanceDetails;
    canRebook: boolean;
}

export const OrderRebookSelectModal: React.FC<RefundModalProps> = ({ order, selectedEventInstance, canRebook }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const businessLineType = useSelector(businessLineTypeSelector);

    const onlyOneEventInstanceAvailable = React.useMemo(() => order?.eventInstances?.length === 1, [order?.eventInstances?.length]);

    const openCloseAction = React.useCallback(() => {
        const clearOldBaskets = async () => {
            const api = new OrderApi();
            await api.clearOldBaskets(businessLineType);
        };

        if (!open) {
            clearOldBaskets();
        }
        setOpen(!open);
    }, [open, businessLineType]);

    return (
        <>
            <Button onClick={openCloseAction} disabled={!canRebook}>
                REBOOK
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    {(!onlyOneEventInstanceAvailable && selectedEventInstance?.eventInstanceId) ? "Rebook Course" : "Rebook Order"}
                </Modal.Header>
                <Modal.Content>
                    <Rebook
                        corporateOrganisationId={order.organisationId}
                        corporateUserId={order.bookerId}
                        orderId={order.id}
                        eventInstanceId={selectedEventInstance?.eventInstanceId}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
