import * as React from "react";

import { HistoryRecord } from "@common/history/model";

import { HistoryTitle } from "./HistoryTitle";
import { HistoryContent } from "./HistoryContent";

export interface HistoryRowProps {
    isActive: boolean;
    currentHistoryRow: HistoryRecord;
    itemIndex: string;
    onClick: (e: any, d: any) => void;
}

export const HistoryRow: React.FC<HistoryRowProps> = ({ isActive, currentHistoryRow, itemIndex, onClick }) => {
    return (
        <>
            <HistoryTitle
                isActive={isActive}
                itemIndex={itemIndex}
                onClick={onClick}
                dateModified={currentHistoryRow.dateCreated}
                modifiedBy={currentHistoryRow.recordModifiedByEmail}
                eventType={currentHistoryRow.eventType}
            />
            <HistoryContent
                itemIndex={itemIndex}
                isActive={isActive}
                historyRecord={currentHistoryRow}
            />
        </>
    );
};
