import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { toast } from "@common/toasts";
import * as actions from "./actiontypes";
import * as models from "./model";
import { TrainerApi } from "./trainerApi";
import moment from "moment";
import { OptSchemeTrainerModel, EditNdorsLicenceNumberModel } from "./model";
import { QuickAddEntities, UniversalQuickAddEntityDictionary } from "../quickAdd/model";
import { v4 } from "uuid";
import { EventTypeCategory } from "../attendee/model";
import { BusinessLineType } from "@common/redux-helpers";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";

export type TrainerAction =
    ({ type: actions.LOAD_TRAINERS_SUCCESS } & Payload<models.TrainerListModel[]>)
    | ({ type: actions.LOAD_TRAINER_MONITORING_PLANS } & Payload<models.TrainerLicencesMonitoringModel[]>)
    | ({ type: actions.CREATE_TRAINER_SUCCESS } & Payload<models.TrainerDetailModel>)
    | ({ type: actions.LOAD_TRAINER_DETAIL_SUCCESS } & Payload<models.TrainerDetailModel>)
    | ({ type: actions.SAVE_TRAINER_SUCCESS } & Payload<models.TrainerDetailModel>)
    | ({ type: actions.SIGNUP_INVITATION_SUCCESS } & Payload<string>)
    | ({ type: actions.LOAD_TRAINERS_DASHBOARD_SUCCESS } & Payload<models.TrainerDashboardModel>)
    | ({ type: actions.SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES} & Payload<models.OptSchemeTrainerModel>)
    | ({ type: actions.UPDATE_TRAINER_NOTE} & Payload<{ trainerId: string; noteId: string; noteText: string; removeAttachment: boolean }>);

export const loadTrainersSuccess = (payload: models.TrainerListModel[]): TrainerAction => ({
    payload,
    type: actions.LOAD_TRAINERS_SUCCESS
});

export const createTrainerSuccess = (payload: models.TrainerDetailModel): TrainerAction => ({
    payload,
    type: actions.CREATE_TRAINER_SUCCESS
});

export const saveTrainerSuccess = (payload: models.TrainerDetailModel): TrainerAction => ({
    payload,
    type: actions.SAVE_TRAINER_SUCCESS
});

export const signupInvitationSuccess = (id: string): TrainerAction => ({
    payload: id,
    type: actions.SIGNUP_INVITATION_SUCCESS
});

export const loadTrainerDashboardSuccess = (payload: models.TrainerDashboardModel): TrainerAction => ({
    payload,
    type: actions.LOAD_TRAINERS_DASHBOARD_SUCCESS
});

export const loadTrainerMonitoringPLanningSuccess = (payload: models.TrainerLicencesMonitoringModel[]): TrainerAction => ({
    payload,
    type: actions.LOAD_TRAINER_MONITORING_PLANS
});

export const saveOptSchemeTrainerAttributeSuccess = (payload: models.OptSchemeTrainerModel): TrainerAction => ({
    payload,
    type: actions.SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES
});

export const updateTrainerNoteFileSuccess = (payload: { trainerId: string; noteId: string; noteText: string; removeAttachment: boolean }): TrainerAction => ({
    payload,
    type: actions.UPDATE_TRAINER_NOTE
});

export function createTrainer(trainer: models.TrainerCreateEditModel, path: string, businessLineType: BusinessLineType, quickAdd: boolean = false) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, actions.CREATE_TRAINER, api.create(trainer));
        dispatch(createTrainerSuccess(result));
        if (quickAdd) {
            toast.success("Quick Add Trainer Successful");
            dispatch(push(getBusinessLineTypePath(+businessLineType) + `/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Trainer]}/${v4()}`));
        }
        else {
            dispatch(push(`${path}/${result.id}`));
        }

    };
}

export function saveTrainerBankAccount(trainerBankAccount: models.TrainerBankAccountCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "saveTrainer", api.saveBankAccount(trainerBankAccount));

        dispatch(saveTrainerSuccess({ ...result, bankAccount: trainerBankAccount }));
        toast.success("Bank account details updated");

        if (path !== undefined) {
            dispatch(push(`${path}`));
        }
    };
}

export function saveTrainer(trainer: models.TrainerCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "saveTrainer", path.includes("/trainers") ? api.save(trainer) : api.saveProfileDetail(trainer));
        if (result.errorUpdatingEmail) {
            toast.error("Could not update email as it is already in use!");
        }

        if (result.address.isApproximate) {
            toast.warning("Unable to find exact address");
        }

        if (!result.errorUpdatingEmail) {
            dispatch(saveTrainerSuccess(result));
            toast.success("Trainer successfully edited");
        }

        if (path !== undefined && !result.errorUpdatingEmail) {
            dispatch(push(`${path}`));
        }
    };
}

export function loadTrainers({ options }: { options?: models.SearchOptions } = {}) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "loadTrainers", api.getAll(options));
        dispatch(loadTrainersSuccess(result));
    };
}

export function loadDigitalTrainersForDors(
    { month, trainerId, recalculate, previousTrainerId }: { month: moment.Moment; trainerId: string; recalculate?: boolean; previousTrainerId?: string }) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(
            dispatch, "loadTrainers", api.getDigitalTrainers(month, trainerId, recalculate ?? false, EventTypeCategory.Dors, previousTrainerId));
        dispatch(loadTrainersSuccess(result));
    };
}

export function loadDigitalTrainersForDdrs(
    { months, trainerId, recalculate, previousTrainerId }: { months: moment.Moment[]; trainerId: string; recalculate?: boolean; previousTrainerId?: string }) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        let result: models.TrainerListModel[] = [];
        for (const month of months) {
            const trainers =
                await loadAndTrack(
                    dispatch, "loadTrainers", api.getDigitalTrainers(month, trainerId, recalculate ?? false, EventTypeCategory.Ddrs, previousTrainerId));
            const currentTrainerIds = result.map(t => t.id);
            result = [...result, ...trainers.filter(t => currentTrainerIds.includes(t.id) === false)];
        }
        dispatch(loadTrainersSuccess(result));
    };
}

export const loadTrainerDetailSuccess = (trainer: models.TrainerDetailModel) => ({
    payload: trainer,
    type: actions.LOAD_TRAINER_DETAIL_SUCCESS
});

export function loadTrainerDetail({ trainerId, overrideCheck }: { trainerId?: string; overrideCheck?: boolean }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const trainer = getState().trainers.find(c => c.id === trainerId);

        if (!trainer
            || !trainer.detailUpdated
            || trainer.detailUpdated.isBefore(trainer.listUpdated) || overrideCheck) {

            const action = !trainer ? createTrainerSuccess : loadTrainerDetailSuccess;

            const api = new TrainerApi();
            const result = await loadAndTrack(dispatch, "loadTrainerDetail", trainerId ? api.detail(trainerId) : api.getProfileDetail());
            dispatch(action(result));
        }
    };
}

export function sendSignupInvitation(id: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        await loadAndTrack(
            dispatch,
            "sendSignupInvitation",
            api.sendSignupInvitation(id)
        );
        toast.info("Signup email requested");
        dispatch(signupInvitationSuccess(id));
    };
}

export function loadDashboardInformation() {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "loadTrainerDashboardInformation", api.loadDashboardInformation());
        dispatch(loadTrainerDashboardSuccess(result));
    };
}

export function loadTrainerMonitoringPlans({ options }: { options?: models.SearchOptions }) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "loadTrainerMonitoringPlans", api.loadMonitorPlanner(options));
        dispatch(loadTrainerMonitoringPLanningSuccess(result));
    };
}

export function saveOptSchemeTrainerAttributes(optSchemeTrainerDetail: OptSchemeTrainerModel) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        try {
            const result = await loadAndTrack(dispatch, "saveOptSchemeTrainerAttributes", api.saveOptSchemeTrainerAttributes(optSchemeTrainerDetail));
            dispatch(saveOptSchemeTrainerAttributeSuccess(optSchemeTrainerDetail));

            if (result !== null) {
                dispatch(saveTrainerSuccess(result));
            }
            toast.success("Opting scheme changes Saved.");
        }
        catch (error) {
            toast.error("An error occurred while saving changes.");
        }
    };
}

export function updateTrainerNdorsLicenceNumber(trainerId: string, model: EditNdorsLicenceNumberModel) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "updateTrainerNdorsLicenceNumber", api.updateTrainerNdorsLicenceNumber(trainerId, model));

        if (result.success) {
            toast.info("NDORS licence number updated");
        } else {
            toast.warning(`NDORS licence number update failed: ${result.errorMessage}`);
        }

        dispatch(saveTrainerSuccess(result.trainer));
    };
}

export function updateTrainerNriLicenceNumber(trainerId: string, model: models.EditNriLicenceNumberModel) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        const result = await loadAndTrack(dispatch, "updateTrainerNriLicenceNumber", api.updateTrainerNriLicenceNumber(trainerId, model));

        if (result.success) {
            toast.info("NRI licence number updated");
        } else {
            toast.warning(`NRI licence number update failed: ${result.errorMessage}`);
        }

        dispatch(saveTrainerSuccess(result.trainer));
    };
}

export function handleTrainerDorsSynchronizationResult(result: models.TrainerDetailUpdateModel) {
    return async (dispatch: Dispatch) => {

        if (result.success) {
            toast.info("DORS synchronized");
        } else {
            toast.warning(`DORS synchronization failed: ${result.errorMessage}`);
        }

        dispatch(saveTrainerSuccess(result.trainer));
    };
}

export function updateTrainerNote(correlationId: string, noteId: string, noteText: string, removeAttachment: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerApi();
        await loadAndTrack(dispatch, "addNote", api.updateTrainerNote(correlationId, noteId, noteText, removeAttachment));
        dispatch(updateTrainerNoteFileSuccess({ trainerId: correlationId, noteId, noteText, removeAttachment }));
    };
}
