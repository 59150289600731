import * as React from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Grid, Tab } from "semantic-ui-react";
import { EventType, ModuleTypeEnum, MultiDayType, ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DisplayEventTypePartAndStartTimesPicker } from "@common/crud/eventType/components/DisplayEventTypePartAndStartTimesPicker";
import { DisplayValueWithEffectiveDate } from "./DisplayValueWithEffectiveDate";
import { DigitalCalendarSessionsTable } from "./DigitalCalendarSessionsTable.tsx";
import { isWorkflowConstruction } from "@common/global/CommonHelpers";

export interface OwnProps {
    eventType: EventType;
}

export const DigitalDetailsTab: React.FC<OwnProps> = ({ eventType }) => {
    const digitalDetails = eventType.digitalEventTypeDetails ?? {};
    const isDors = eventType.workflowType === WorkflowTypeEnum.Dors;
    const isDdrs = eventType.workflowType === WorkflowTypeEnum.DDRS;
    const hasEducationDuration = eventType.workflowType === WorkflowTypeEnum.CPC || eventType.productCategory === ProductCategoryEnum.OnRoadWithCpc;
    const canBeMultiDay = eventType.workflowType !== WorkflowTypeEnum.Dors && eventType.moduleType !== ModuleTypeEnum.Both;
    const useSingleDayControls = eventType.workflowType === WorkflowTypeEnum.Dors || digitalDetails.defaultNumberOfDigitalDeliveryDays === 1;
    const isConstruction = isWorkflowConstruction(eventType.workflowType);

    function formatDuration(value: moment.Duration) {
        return value.format(DateFormat.Time, { trim: false });
    }

    return (
        <Tab.Pane>
            <Grid>
                <h3>Course Details</h3>
                {canBeMultiDay && <Detail label="Default Number of Digital Delivery Days"
                    value={digitalDetails.defaultNumberOfDigitalDeliveryDays?.toString()} labelWidth={5} valueWidth={11} />}
                {canBeMultiDay && digitalDetails.defaultNumberOfDigitalDeliveryDays > 1 && <Detail label="Default Multi Day Type"
                    value={MultiDayType[digitalDetails.defaultMultiDayType]} labelWidth={5} valueWidth={11} />}
                {useSingleDayControls &&
                    <DisplayValueWithEffectiveDate label="Event Duration" values={digitalDetails.eventDurations} valueFormat={formatDuration} />
                }

                {(useSingleDayControls && hasEducationDuration) &&
                    <DisplayValueWithEffectiveDate
                        label="Education Duration"
                        values={digitalDetails?.educationDurations}
                        valueFormat={formatDuration}
                    />
                }
                {useSingleDayControls && <DisplayValueWithEffectiveDate label="Registration Duration"
                    values={digitalDetails.digitalRegistrationDurations} valueFormat={formatDuration} />}
                <h3>Course Planning</h3>
                <DisplayEventTypePartAndStartTimesPicker
                    eventTypeParts={digitalDetails.digitalEventTypeParts}
                    workflowType={eventType.workflowType}
                    defaultNumberOfDeliveryDays={digitalDetails.defaultNumberOfDigitalDeliveryDays}
                    suggestedStartTimesForSessions={digitalDetails.digitalEventSuggestedStartTimesForSessions} />
                {isDors && (
                    <DigitalCalendarSessionsTable digitalEventTypeDetails={digitalDetails} />
                )}
                <h3>Course Availability</h3>
                <DisplayValueWithEffectiveDate label="Max number of digital attendees" values={digitalDetails.maxNumberOfDigitalAttendeesWithDuration} />
                <DisplayValueWithEffectiveDate
                    label="Max number of attendees per digital theory trainer"
                    values={digitalDetails.maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate}
                />
                {(isConstruction && eventType.showInOpenBookingApp) && (
                    <>
                        <h3>Break Even Points</h3>
                        <DisplayValueWithEffectiveDate
                            label="Digital break even points"
                            values={digitalDetails.digitalBreakEvenPoints} />
                    </>
                )}
                <h3>Terms and Conditions</h3>
                <Detail label="Digital Terms and Conditions Link (En)" value={digitalDetails.digitalTermsAndConditionsLinkEn} labelWidth={5} valueWidth={11} />
                <Detail label="Digital Terms and Conditions Link (Cy)" value={digitalDetails.digitalTermsAndConditionsLinkCy} labelWidth={5} valueWidth={11} />
                {(isDors || isDdrs) && (
                    <>
                        <h3>Spreading</h3>
                        <Detail label="Spreading Headroom Weekday" value={digitalDetails.spreadingHeadroomWeekday?.toString()} labelWidth={5} valueWidth={11} />
                        <Detail label="Spreading Headroom Weekend" value={digitalDetails.spreadingHeadroomWeekend?.toString()} labelWidth={5} valueWidth={11} />
                    </>
                )}
            </Grid>
        </Tab.Pane>
    );
};
