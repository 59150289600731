import * as React from "react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { TrainerAttributeDorsStatus } from "@common/crud/common/TrainerAttributeDorsStatus";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { OptTrainerModal } from "@common/crud/trainer/components/details/OptTrainerModal";

export interface DorsAttributesTableProps {
    attributes: TrainerAttribute[];
    attributeCategory: string;
    digital: boolean;
    trainerId: string;
    title: string;
}

interface ExtendedTrainerAttribute extends TrainerAttribute {
    id: string;
}

export const DorsAttributesTable: React.FC<DorsAttributesTableProps> = ({ attributes, digital, attributeCategory, trainerId, title }) => {
    const [ extendedTrainerAttributes, setExtendedTrainerAttributes] = React.useState<ExtendedTrainerAttribute[]>([]);
    React.useEffect(() => {
        setExtendedTrainerAttributes(attributes?.map((e: TrainerAttribute): ExtendedTrainerAttribute => ({
            id: e.attributeDefinitionId,
            ...e
        })));
    }, [attributes]);

    const columns: TypedTableRowProps<ExtendedTrainerAttribute>[] = [
        {
            header: "Name",
            value: (e) => e.name
        },
        {
            header: "Status",
            value: (e) => e.dorsStatus > 0
                ? `${TrainerAttributeDorsStatus[e.dorsStatus]} ${e.isOptedOut ? "(Opted Out)" : ""}`
                : `${TrainerAttributeDorsStatus[0]} ${e.isOptedOut ? "(Opted Out)" : ""}`
        },
        {
            header: "Expiry Date",
            value: (e) => e.expiryDate?.format(DateFormat.Short) ?? "No date"
        },
        {
            header: "Last Monitored Date",
            value: (e) => (digital ? e.lastDigitalMonitoredDate : e.lastMonitoredDate )?.format(DateFormat.Short) ?? "No date"
        },
        {
            header: "Next Monitoring Due",
            value: (e) => (digital ? e.nextDigitalMonitoringDue : e.nextMonitoringDue)?.format(DateFormat.Short) ?? "No date"
        },
        {
            header: "Next Monitoring Session",
            value: (e) => (digital ? e.nextDigitalMonitoringSessionPlanned : e.nextMonitoringSessionPlanned)?.format(DateFormat.Short) ?? "No date"
        }
    ];

    return (
        <>
            <h3>{title}</h3>
            <TypedTable values={extendedTrainerAttributes} emptyValuesArrayMessage={`No ${attributeCategory} attributes`} >
                {columns}
            </TypedTable>
            <OptTrainerModal modalHeader={title} trainerAttributes={attributes} trainerId={trainerId} isDigital={digital} />
        </>
    );
};
