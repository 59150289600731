import * as React from "react";
import moment from "moment";
import { v4 } from "uuid";
import { Modal } from "@common/components";
import { Button } from "semantic-ui-react";
import {
    ScheduledPaymentResponse
} from "@common/payments/components/scheduled-payments/ScheduledPaymentBase";
import { ScheduledPaymentPlan } from "@common/payments/components/scheduled-payments/ScheduledPaymentPlan";
import { ScheduledPaymentPlanViewModel } from "@common/payments/model";

const defaultPaymentPlan: ScheduledPaymentPlanViewModel = ({
    id: v4(),
    amount: 0,
    chargeDate: null,
    isAmountDirty: false,
    isDateDirty: false,
    index: 0
});

interface PaymentPlanModalProps {
    skipPayment: boolean;
    sendPlan: (planObject: ScheduledPaymentResponse) => void;
    maxValue: number;
    paymentValue: number;
    eventDate: moment.Moment;
    scheduledPaymentPlans: ScheduledPaymentPlanViewModel[];
    setScheduledPaymentPlans: React.Dispatch<React.SetStateAction<ScheduledPaymentPlanViewModel[]>>;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
    skipPayment, maxValue, paymentValue, eventDate, sendPlan, scheduledPaymentPlans, setScheduledPaymentPlans
}) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [isPlanValid, setIsPlanValid] = React.useState<boolean>();

    function openModal() {
        setOpen(true);

        if (scheduledPaymentPlans.length === 0) {
            setScheduledPaymentPlans([defaultPaymentPlan]);
        }

    }

    function closeModal() {
        setOpen(false);
        sendPlan({ isPlanValid: true, plan: [] });
        setScheduledPaymentPlans([]);
    }

    function onSave() { setOpen(false); }

    function onSendPlan(response: ScheduledPaymentResponse) {
        setIsPlanValid(response.isPlanValid);
        sendPlan(response);
    }

    const buttonText = scheduledPaymentPlans.length > 0 ? "Edit plan" : "+ Set up plan";
    const disable = skipPayment || (paymentValue === maxValue && scheduledPaymentPlans?.length === 0);

    return (
        <>
            <Button disabled={disable} className="link-button blue" onClick={openModal}>{buttonText}</Button>

            <Modal open={open}>
                <Modal.Header>
                    Set up scheduled payment
                </Modal.Header>
                <Modal.Content>
                    <ScheduledPaymentPlan
                        maxValue={maxValue}
                        sendPlan={onSendPlan}
                        initialPaymentValue={paymentValue}
                        eventDate={eventDate}
                        scheduledPaymentPlans={scheduledPaymentPlans}
                        setScheduledPaymentPlans={setScheduledPaymentPlans}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeModal} content="Cancel" />
                    <Button onClick={onSave} content="Save" disabled={!isPlanValid} />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { PaymentPlanModal };
