import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

export interface RowDetailProps {
    rowLabel: string | JSX.Element;
    values: (string | JSX.Element | Element)[];
    valueWidths?: SemanticWIDTHS[];
    valueClassName?: string;
}

export const RowDetail: React.SFC<RowDetailProps> = ({ rowLabel, values, valueWidths, valueClassName }) => {
    return (
        <Grid.Row>
            <Grid.Column width={3} as="h4">
                {rowLabel}
            </Grid.Column>
            {values.map((value, index) =>

            { const columnWidth = valueWidths.length > 0 ? valueWidths[index] :Math.floor(12 / values.length) as SemanticWIDTHS;

                return (<Grid.Column key={index} width={columnWidth} className={valueClassName}>
                    {value}
                </Grid.Column>);}
            )}
        </Grid.Row>
    );
};
