/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import {
    Checkbox,
    CheckboxProps,
    Divider,
    Dropdown,
    DropdownProps,
    Grid,
    Label,
    Radio,
    SemanticWIDTHS,
    SemanticWIDTHSNUMBER
} from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { CurrencyInput } from "@common/global/CurrencyInput";
import {
    GroupEventInstancesModel,
    TrainerType,
    CarType,
    OtherTrainerCategoryDropDownOptions,
    OtherTrainerTypeCategoryEnum,
    SameDayEventInstanceModel, OtherTrainerBaseFee
} from "../../model";
import { useAvailabilityContext } from "@common/crud/eventInstance/components/trainers/availability-context";
import { ModuleTypeEnum } from "@common/crud/eventType/model";
import { EventInstanceApi } from "@common/crud/eventInstance";

interface OwnProps {
    trainerType: TrainerType;
    eventTypeName: string;
    startDate: moment.Moment;
    startTime: moment.Duration;
    trainers: TrainerAvailabilitySelectModel[];
    onFeeChanged(trainerId: string, fee: number, isFeeNonStandard: boolean): void;
    onSundriesChanged(trainerId: string, sundries: number): void;
    sameDayCourses: SameDayEventInstanceModel[];
    selectedMultiDayEventInstances: GroupEventInstancesModel[];
    onAllocateToSameDayCourse(trainerId: string, eiId: string): (e: any, selected: CheckboxProps) => void;
    onAssignToPracticalChanged?: (trainerId: string, assignAsPracticalAndTheoryTrainer: boolean) => void;
    onTrainerCarTypeChanged?: (trainerId: string, carType: CarType) => void;
    theoryAndPractical?: boolean;
    isAlreadyAssignedToPractical?: (trainerId: string) => boolean;
    getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical?: (trainer: TrainerAvailabilitySelectModel) => string;
    onOtherTrainerTypeCategoryChanged?: (trainerId: string, otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum, fee: number) => void;
    moduleType: ModuleTypeEnum;
    isCorporateOrConstruction: boolean;
    courseFee: number[];
    eventInstanceId?: string;
}

export const FeeAssign: React.FC<OwnProps> = ({
    trainerType,
    eventTypeName,
    startDate,
    startTime,
    trainers,
    onFeeChanged,
    onSundriesChanged,
    sameDayCourses,
    selectedMultiDayEventInstances,
    onAllocateToSameDayCourse,
    onTrainerCarTypeChanged,
    theoryAndPractical,
    onAssignToPracticalChanged,
    isAlreadyAssignedToPractical,
    getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical,
    moduleType,
    onOtherTrainerTypeCategoryChanged,
    isCorporateOrConstruction: isCorporate,
    courseFee,
    eventInstanceId
}) => {
    const practicalTrainers = trainerType === TrainerType.PracticalTrainer;
    const theoryTrainers = trainerType === TrainerType.TheoryTrainer;

    const { eventInstanceGroup, isMultiDay } = useAvailabilityContext();
    const [otherTrainerBaseFee, setOtherTrainerBaseFee] = React.useState<OtherTrainerBaseFee>();

    React.useEffect(() => {
        async function GetOtherTrainerFees() {
            const eventInstanceApi = new EventInstanceApi();
            const response = await eventInstanceApi.getOtherTrainerFeeFromEventType(eventInstanceId);
            setOtherTrainerBaseFee(response);
        }

        if (trainerType === TrainerType.OtherTrainer) {
            GetOtherTrainerFees();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[trainerType]);

    const isOtherTrainer = trainerType === TrainerType.OtherTrainer;

    const getOtherTrainerFee = React.useCallback((otherTrainerCategory: OtherTrainerTypeCategoryEnum) => {
        switch (otherTrainerCategory) {
            case OtherTrainerTypeCategoryEnum.Mentee:
                return otherTrainerBaseFee?.mentee ?? 0;
            case OtherTrainerTypeCategoryEnum.Mentor:
                return otherTrainerBaseFee?.mentor ?? 0;
            case OtherTrainerTypeCategoryEnum.Observer:
                return otherTrainerBaseFee?.observer ?? 0;
            case OtherTrainerTypeCategoryEnum.FirstCourseSupport:
                return otherTrainerBaseFee?.firstCourseSupport ?? 0;
            default:
                return 0;
        }
    }, [otherTrainerBaseFee]);

    const isFeeNonStandard = React.useCallback((trainer: TrainerAvailabilitySelectModel) => {
        const standardFee = isOtherTrainer
            ? getOtherTrainerFee(trainer.otherTrainerTypeCategory)
            : practicalTrainers && courseFee.length > 1 ? courseFee[1] : courseFee[0];
        return trainer.fee !== standardFee;
    }, [courseFee, getOtherTrainerFee, isOtherTrainer, practicalTrainers]);

    const onInputFeeChanged = React.useCallback((trainerId: string) => {
        return (fee: number) =>
        {
            const trainer = trainers.find(t => t.id === trainerId);
            const isTrainerFeeNonStandard = isFeeNonStandard(trainer);
            onFeeChanged(trainerId, fee, isTrainerFeeNonStandard);
        };
    }, [isFeeNonStandard, onFeeChanged, trainers]);

    const onInputSundriesChanged = React.useCallback((trainerId: string) => {
        return (sundries: number) => onSundriesChanged(trainerId, sundries);
    }, [onSundriesChanged]);

    const onInputAssignToPracticalChanged = React.useCallback((trainerId: string) => {
        return (_: any, { checked }: CheckboxProps) => onAssignToPracticalChanged(trainerId, checked);
    }, [onAssignToPracticalChanged]);

    const onCarTypeChanged =(trainerId: string, carType: CarType) => () => onTrainerCarTypeChanged(trainerId, carType);

    const renderHeader = (text: string, width: SemanticWIDTHSNUMBER) => {
        return (<Grid.Column width={width}><ins><strong>{text}</strong></ins></Grid.Column>);
    };

    const disableDueToClash = (trainer: TrainerAvailabilitySelectModel, eventInstance: SameDayEventInstanceModel) => {
        return sameDayCourses.filter(ei => ei.trainerIds.includes(trainer.id) && eventInstance.clashingEventInstanceIds.includes(ei.id)).some(ei => ei);
    };

    const renderDayStartDateAndTime = () => {
        return isMultiDay ?
            (
                <>
                    the following days:<br />
                    {eventInstanceGroup.eventInstanceGroupItems.map(gi =>
                        (<>
                            <p key={gi.dayNumber}>{gi. startDate.format(DateFormat.Long)} at {gi.startTime?.format(DateFormat.Time, { trim: false }) }</p>
                        </>)) }
                </>
            )
            :
            startDate.format(DateFormat.Long) + " at " + startTime?.format(DateFormat.Time, { trim: false });
    };

    function onOtherTrainerCategoryChange(trainerId: string, dropdownProps: DropdownProps) {
        const otherTrainerCategoryValue = dropdownProps.value as OtherTrainerTypeCategoryEnum;
        const otherTrainerFee = getOtherTrainerFee(otherTrainerCategoryValue);
        onOtherTrainerTypeCategoryChanged(trainerId, otherTrainerCategoryValue, otherTrainerFee);
    }

    const dayColumns = () => (eventInstanceGroup && eventInstanceGroup.eventInstanceGroupItems?.length) as SemanticWIDTHS;

    const monitorOrObserverTrainers = trainerType === TrainerType.MonitorTrainer || trainerType === TrainerType.OtherTrainer;
    const showAssignToPracticalAswell = theoryAndPractical && theoryTrainers;
    const showCarDetails = !isCorporate && (practicalTrainers || showAssignToPracticalAswell);
    const canBeAssignedToPractical = (trainer: TrainerAvailabilitySelectModel) => !trainer.isPracticalBookedForBothModule
        && (isCorporate
            ? trainer.hasPracticalAttribute
            : (trainer.hasAutomaticAttribute || trainer.hasManualAttribute));
    const carOptionsDisabled = (trainer: TrainerAvailabilitySelectModel) =>
        moduleType !== ModuleTypeEnum.Practical && !trainer.assignAsPracticalAndTheoryTrainer;
    const manualOptionDisabled =
        (t: TrainerAvailabilitySelectModel) => isAlreadyAssignedToPractical(t.id) || t.hasManualAttribute === false || carOptionsDisabled(t);
    const automaticOptionDisabled =
        (t: TrainerAvailabilitySelectModel) => isAlreadyAssignedToPractical(t.id) || t.hasAutomaticAttribute === false || carOptionsDisabled(t);

    const gridSettings = React.useMemo(() => {
        let columns = 3;
        let widthUsed = 0;
        if (!isMultiDay && showAssignToPracticalAswell && theoryTrainers) {
            columns += 1;
            widthUsed += 2;
        }
        if (!isMultiDay && showCarDetails) {
            columns += 1;
            widthUsed += 3;
        }
        if (!isMultiDay && !isCorporate && !showCarDetails && !monitorOrObserverTrainers) {
            columns += 1;
            widthUsed += 3;
        }
        if (isMultiDay) {
            columns += 1;
            widthUsed += 4;
        }
        if (isOtherTrainer) {
            columns += 1;
            widthUsed += 3;
        }

        const baseWidth = Math.floor((16 - widthUsed) / 3);
        const nameWidth = 16 - widthUsed - (2 * baseWidth);

        return {
            nameWidth: nameWidth as SemanticWIDTHSNUMBER,
            baseWidth: baseWidth as SemanticWIDTHSNUMBER,
            columnCount: columns as SemanticWIDTHSNUMBER
        };
    }, [isCorporate, isMultiDay, isOtherTrainer, monitorOrObserverTrainers, showAssignToPracticalAswell, showCarDetails, theoryTrainers]);

    return (<>
        <p>You are allocating to {eventTypeName} on {renderDayStartDateAndTime()}</p>
        <p>Please review the details below and click allocate to complete the allocation process</p>
        <Divider hidden />
        <Grid columns={gridSettings.columnCount} divided>
            <Grid.Row>
                {renderHeader("Trainer Name", gridSettings.nameWidth)}
                {renderHeader("Course fee", gridSettings.baseWidth)}
                {renderHeader("Sundries", gridSettings.baseWidth)}
                {!isMultiDay && showAssignToPracticalAswell && theoryTrainers && renderHeader("Add to practical?", 2)}
                {!isMultiDay && showCarDetails && renderHeader("Choose Car Type", 3)}
                {!isMultiDay && !isCorporate && !showCarDetails && !monitorOrObserverTrainers && renderHeader("Allocate to next course on same day?", 3)}
                {isMultiDay && renderHeader("Allocated days", 4)}
                {isOtherTrainer && renderHeader("Trainer Type", 3)}
            </Grid.Row>
            {!isMultiDay && trainers.map(t => (
                <Grid.Row key={t.id}>
                    <Grid.Column width={gridSettings.nameWidth}>{t.fullName}</Grid.Column>
                    <Grid.Column width={gridSettings.baseWidth}>
                        <div>
                            <CurrencyInput
                                value={t.fee}
                                onChange={onInputFeeChanged(t.id)}
                                fitOnModal
                            />
                        </div>
                        {isFeeNonStandard(t) &&
                            <div className="ui orange pointing above basic label">
                                <p>
                                    Trainer's course fee does not match system known fees
                                </p>
                            </div>
                        }
                    </Grid.Column>
                    <Grid.Column width={gridSettings.baseWidth}>
                        <CurrencyInput
                            value={t.sundries}
                            onChange={onInputSundriesChanged(t.id)}
                            fitOnModal
                        />
                    </Grid.Column>
                    { (!isMultiDay && showAssignToPracticalAswell && theoryTrainers) &&
                        <Grid.Column width={2}>
                            <Checkbox
                                checked={isAlreadyAssignedToPractical(t.id) || t.assignAsPracticalAndTheoryTrainer}
                                onChange={onInputAssignToPracticalChanged(t.id)}
                                disabled={isAlreadyAssignedToPractical(t.id) || !canBeAssignedToPractical(t)}
                            />
                        </Grid.Column>
                    }
                    { (!isMultiDay && showCarDetails) &&
                        <>
                            <Grid.Column width={3}>
                                <Radio
                                    label="Automatic"
                                    name={`${t.id}-carType`}
                                    disabled={automaticOptionDisabled(t)}
                                    value={"Automatic"}
                                    checked={getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === "Automatic"}
                                    onChange={onCarTypeChanged(t.id, "Automatic")}
                                />
                                <Radio
                                    label="Manual"
                                    name={`${t.id}-carType`}
                                    disabled={manualOptionDisabled(t)}
                                    value={"Manual"}
                                    checked={getCarTypeFromTrainerOrFromTrainerPreviouslyAssignedToPractical(t) === "Manual"}
                                    onChange={onCarTypeChanged(t.id, "Manual")}
                                />
                            </Grid.Column>
                        </>
                    }
                    { (!isMultiDay && !isCorporate && !showCarDetails && !monitorOrObserverTrainers) && <Grid.Column width={3}>
                        {theoryTrainers && (
                            <Grid columns="3">
                                {sameDayCourses.map(e => (
                                    <Grid.Column key={e.id}>
                                        <label>{e.startTime.utc().format("h:mm A")}</label>
                                        <Checkbox
                                            checked={e.trainerIds.includes(t.id)}
                                            indeterminate={!e.trainerIds.includes(t.id) &&
                                                 !(e.allowAllocateToNextTrainerIds.includes(t.id)) || disableDueToClash(t, e)}
                                            disabled={!(e.allowAllocateToNextTrainerIds.includes(t.id)) || disableDueToClash(t, e)
                                                || t.isPracticalBookedForBothModule}
                                            onChange={onAllocateToSameDayCourse(t.id, e.id)}
                                        />
                                    </Grid.Column>
                                ))}
                                {sameDayCourses.length === 0 &&
                                                            <p>No courses on the same day</p>
                                }
                            </Grid>
                        )}
                    </Grid.Column>
                    }
                    {
                        isOtherTrainer &&
                        <Grid.Column width={3}>
                            <Dropdown
                                selection
                                value={t.otherTrainerTypeCategory}
                                onChange={(e, d) => onOtherTrainerCategoryChange(t.id, d)}
                                options={OtherTrainerCategoryDropDownOptions.map(otc => ({ ...otc, key: `${otc.value}-${t.id}` }))}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>))
            }
            {isMultiDay && trainers.map(t => (
                <Grid.Row key={t.id}>
                    <Grid.Column width={gridSettings.nameWidth}>{t.fullName}</Grid.Column>
                    <Grid.Column width={gridSettings.baseWidth} className={isOtherTrainer ? "medium-input" : ""}>
                        <CurrencyInput
                            value={isOtherTrainer ? getOtherTrainerFee(t.otherTrainerTypeCategory) : t.fee}
                            onChange={onInputFeeChanged(t.id)}
                            fitOnModal
                        />
                    </Grid.Column>
                    <Grid.Column width={gridSettings.baseWidth} className={isOtherTrainer ? "medium-input" : ""}>
                        <CurrencyInput
                            value={t.sundries}
                            onChange={onInputSundriesChanged(t.id)}
                            fitOnModal
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid columns={dayColumns()}>
                            {eventInstanceGroup.eventInstanceGroupItems?.map(e => (
                                <Grid.Column key={e.eventInstanceId} textAlign={"center"}>
                                    <Label>{e.dayNumber}</Label>
                                    <br />
                                    <Checkbox
                                        disabled
                                        checked={selectedMultiDayEventInstances[e.dayNumber-1]?.trainerIds?.some(tid => t.id === tid)} />
                                </Grid.Column>
                            ))
                            }
                        </Grid>
                    </Grid.Column>
                    {
                        isOtherTrainer &&
                        <Grid.Column width={3}>
                            <Dropdown
                                selection
                                value={t.otherTrainerTypeCategory}
                                onChange={(e, d) => onOtherTrainerCategoryChange(t.id, d)}
                                options={OtherTrainerCategoryDropDownOptions.map(otc => ({ ...otc, key: `${otc.value}-${t.id}` }))}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>))
            }
        </Grid>
    </>);
};
