import * as React from "react";
import { Button, Divider, Tab, Segment, TabProps } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { CoreDetailProps, DetailProps, DispatchProps } from "./Detail";
import { DetailsTab } from "./DetailsTab";
import { Base as OrdersTab } from "@common/crud/order/components/TabBase";
import { CorporateOrConstructionUserRoutes } from "../routes";

export interface CoreDetailTabPropsState extends CoreDetailProps {
    onTabChange: (e: any, data: TabProps) => void;
    path: string;
}

enum TabNames {
    Details = "Details",
    Orders = "Orders",
}

const NotTabbedOrderViews = [
    CorporateOrConstructionUserRoutes.Rebook,
    CorporateOrConstructionUserRoutes.SelectRebooking,
    CorporateOrConstructionUserRoutes.Create,
    CorporateOrConstructionUserRoutes.DetailsAndPayment,
    CorporateOrConstructionUserRoutes.Payment,
    CorporateOrConstructionUserRoutes.GenesysAuth,
    CorporateOrConstructionUserRoutes.ReviewPayment
];

export const CoreDetails: React.FC<CoreDetailTabPropsState> = ({ onTabChange, corporateUser, path }) => {
    let panes = [
        { menuItem: TabNames.Details, path: "", render: () => <DetailsTab corporateUser={corporateUser} /> },
        { menuItem: TabNames.Orders, path: "orders", render: () => <OrdersTab /> }
    ];

    if (NotTabbedOrderViews.some(v => path.toLowerCase().includes(v.toLowerCase()))) {
        panes = panes.filter(p => p?.menuItem === TabNames.Orders);
    }

    const activeTab = path.split("/").pop().split("?")[0];
    const activeIndex = path.toLowerCase().includes(TabNames.Orders.toLowerCase())
        ? panes.findIndex(p => p?.path === TabNames.Orders.toLowerCase())
        : panes.findIndex(p => p?.path === activeTab);

    return panes.length === 1
        ? (
            panes[0].render()
        ) : (
            <Tab
                onTabChange={onTabChange}
                panes={panes}
                activeIndex={activeIndex >= 0 ? activeIndex : 0}
            />
        );
};

export interface CoreDetailsWithActionsState {
    showEdit: boolean;
}

export class CoreDetailsWithActions extends React.Component<DetailProps & DispatchProps, CoreDetailTabPropsState & CoreDetailsWithActionsState> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.state = {
            ...this.state,
            showEdit: !this.props.path.toLowerCase().includes(TabNames.Orders.toLowerCase())
        };
    }

    public render() {
        const { corporateUser, path } = this.props;
        const basePath = path[path.length - 1] === "/" ? path.slice(0, -1) : path;
        const onOrders = path.toLowerCase().includes(TabNames.Orders.toLowerCase());

        return (
            <div>
                {!!corporateUser && <CoreDetails corporateUser={corporateUser} onTabChange={this.handleTabChange} path={path} />}

                {!onOrders &&
                    <>
                        <Divider />
                        <Segment basic clearing vertical>
                            <div className="button-container right-align">
                                {this.state.showEdit && (
                                    <Button
                                        icon="pencil"
                                        content="Edit"
                                        as={Link}
                                        href={`${basePath}/edit`}
                                    />
                                )}
                            </div>
                        </Segment>
                    </>
                }
            </div>
        );
    }

    private handleTabChange(_: any, tabData: TabProps) {
        this.setState({
            showEdit: tabData.activeIndex === 0
        });

        this.props.push(`${this.props.basePath}/${this.props.corporateUser.id}/${tabData.panes[tabData.activeIndex].path}`);
    }
}

export const coreDetailsTab = (props: DetailProps & DispatchProps) => ({
    path: "",
    menuItem: "Details",
    authorise: true,
    render: () => (
        <Tab.Pane>
            <CoreDetailsWithActions {...props} />
        </Tab.Pane>
    )
});
