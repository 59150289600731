import axios, { AxiosError } from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import { Order, StartOrganisationPaymentProcessResult, OrganisationPaymentRequest } from "./model";
import { PaymentResponse } from "@common/payments/model";
import { toast } from "@common/toasts";
import { BusinessLineType } from "@common/redux-helpers";
import { Address } from "../common";

export class OrderPaymentApi {

    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/orderPayment";
    }

    public async paymentTotal(businessLineType: BusinessLineType): Promise<number> {
        const query = QueryString.stringify({
            businessLineType
        });
        const response = await axios.get(`${this.apiUrl}/paymentTotal?${query}`);
        return response.data as number;
    }

    public async startPaymentProcess(businessLineType: BusinessLineType, basketId: string): Promise<StartOrganisationPaymentProcessResult> {
        const response = await axios.post(`${this.apiUrl}/startPaymentProcess`, { businessLineType, basketId });
        return response.data as StartOrganisationPaymentProcessResult;
    }

    public async bookNowPayLater(
        businessLineType: BusinessLineType,
        basketId: string,
        amount: number,
        invoiceReference?: string,
        invoiceEmail?: string,
        invoicePhoneNo?: string,
        invoiceAddress?: Address,
    ): Promise<PaymentResponse> {
        try {
            const response = await axios.post(
                `${this.apiUrl}/bookNowPayLater`,
                {
                    businessLineType,
                    basketId,
                    amount,
                    orderInvoiceData: {
                        reference: invoiceReference,
                        email: invoiceEmail,
                        telephone: invoicePhoneNo,
                        address: invoiceAddress
                    }
                });
            const model = response.data as PaymentResponse;
            if (!model.validRequest && !model.duplicateRequest && !model.requiresAction) {
                toast.error("Your order has failed");
            }
            return model;
        } catch (e) {
            const axiosError = e as AxiosError;
            if (axiosError && axiosError.response && axiosError.response.status && axiosError.response.status === 500) {
                toast.error("An error has occured during payment. Please call us.");
                return undefined;
            }
            throw e;
        }
    }

    public async makePayment(paymentRequest?: OrganisationPaymentRequest): Promise<PaymentResponse> {
        try {
            const response = await axios.post(`${this.apiUrl}/charge-stripe`, paymentRequest);
            const model = response.data as PaymentResponse;
            if (!model.validRequest && !model.duplicateRequest && !model.requiresAction) {
                toast.error(model.paymentError
                    ? model.paymentError
                    : "Your payment has failed");
            }
            return model;
        } catch (e) {
            const axiosError = e as AxiosError;
            // If the server returned 500 error, for example if an exception was thrown, then we don't know the
            // state of the payment and so need to tell user to call us
            if (axiosError && axiosError.response && axiosError.response.status && axiosError.response.status === 500) {
                const response: PaymentResponse = {
                    validRequest: false,
                    paymentError: null,
                    paymentAuthorised: false,
                    requiresAction: null,
                    paymentIntentClientSecret: null,
                    duplicateRequest: false,
                    noAvailability: false,
                    paymentAlreadyReceived: false,
                    reservationHasExpired: false,
                    serverError: true
                };
                toast.error("An error has occured during payment. Please call us.");
                return response;
            }
            throw e;
        }
    }

    public parseOrderModel<T extends Order>(model: T): T {
        return {
            ...model,
            bookingDate: model.bookingDate
                ? moment(model.bookingDate)
                : undefined,
            cancellationDate: model.cancellationDate
                ? moment(model.cancellationDate)
                : undefined,
            eventInstances: model.eventInstances
                ? model.eventInstances.map((eventInstance) => ({
                    ...eventInstance,
                    eventInstanceDeliveryDateTime: eventInstance.eventInstanceDeliveryDateTime
                        ? moment(eventInstance.eventInstanceDeliveryDateTime)
                        : undefined,
                    cancellationDate: eventInstance.cancellationDate
                        ? moment(eventInstance.cancellationDate)
                        : undefined,
                }))
                : [],
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated)
            })),
        };
    }
}
