/* eslint-disable max-lines */
import * as React from "react";
import { SystemConfigurationModel } from "../model";
import { Postmark } from "./Postmark";
import { InternalApps } from "./InternalApps";
import { Graph } from "./Graph";
import { Emailer } from "./Emailer";
import { Geocoding } from "./Geocoding";
import { Stripe } from "./Stripe";
import { BookingApi } from "./BookingApi";
import { AzureSearch } from "./AzureSearch";
import { DorsRestfulService } from "./DorsRestfulService";
import { DorsProxy } from "./DorsProxy";
import { ExternalResources } from "./ExternalResources";
import { Notifier } from "./Notifier";
import { Sms } from "./Sms";
import { SmsService } from "./SmsService";
import { AdHocTemplates } from "./AdHocTemplates/AdHocTemplates";
import { BookingApp } from "./BookingApp";
import { ZoomService } from "./ZoomService";
import { InputType } from "./SettingsGrid";
import { Genesys } from "./Genesys";
import { TrainerInvoices } from "./TrainerInvoices";
import { AlertEmails } from "@common/crud/configuration/components/AlertEmails";
import { DdrsSetting } from "./DdrsService";
import { PdfProcessingSetting } from "./PdfProcessingSetting";
import { ContinuumApi } from "./ContinuumApi";
import { Features } from "./Features";
import { AddressLookup } from "./AddressLookup";
import { Recaptcha } from "@common/crud/configuration/components/Recaptcha";
import { DdrsPostmark } from "./DdrsPostmark";
import { FunctionAuth } from "./FunctionAuth";
import { DdrsSms } from "./DdrsSms";
import { AccountLock } from "@common/crud/configuration/components/AccountLockSettings";
import { TestSettings } from "./TestSettings";
import { CorporatePostmark } from "./CorporatePostmark";
import { CorporateAccountLock } from "./CorporateAccountLockSettings";
import { CorporateSms } from "./CorporateSms";
import { ClaritySettings } from "./ClaritySettings";
import { GoogleTagManagerSettings } from "./GoogleTagManagerSettings";
import { Caching } from "./Caching";
import { DomainBlacklist } from "./DomainBlacklist";
import { CpcBookingApp } from "./CpcBookingApp";
import { DorsBookingAccountLock } from "./DorsBookingAccountLock";
import { HotjarSettings } from "./HotjarSettings";
import { ConstructionPostmark } from "./ConstructionPostmark";

export interface PaneDefinition<T = any> {
    route: string;
    label: string;
    render: (model: T, hierarchy: string[]) => JSX.Element;
}

// These define the tab panes on the configuration pages.

export const systemConfigurationPanes: PaneDefinition<SystemConfigurationModel>[] = [
    {
        route: "/internalapps",
        label: "Internal Endpoints",
        render: (model, hierarchy) => <InternalApps settings={model.internalAppSettings} hierarchy={hierarchy} />
    },
    {
        route: "/postmark",
        label: "Postmark Templates - NDORS",
        render: (model, hierarchy) => <Postmark settings={model.postmarkTemplates} hierarchy={hierarchy} />
    },
    {
        route: "/ddrs-postmark",
        label: "Postmark Templates - DDRS",
        render: (model, hierarchy) => <DdrsPostmark settings={model.ddrsPostmarkTemplates} hierarchy={hierarchy} />
    },
    {
        route: "/corporate-postmark",
        label: "Postmark Templates - Corp",
        render: (model, hierarchy) => <CorporatePostmark settings={model.corporatePostmarkTemplates} hierarchy={hierarchy} />
    },
    {
        route: "/construction-postmark",
        label: "Postmark Templates - Construction",
        render: (model, hierarchy) => <ConstructionPostmark settings={model.constructionPostmarkTemplates} hierarchy={hierarchy} />
    },
    {
        route: "/ad-hoc-postmark",
        label: "Ad-Hoc Attendee Email Templates",
        render: (model, hierarchy) => (<AdHocTemplates
            settings={model.adHocPostmarkTemplates}
            hierarchy={hierarchy}
            area={"AdHocPostmarkTemplates"}
            inputType={InputType.Number}
        />)
    },    {
        route: "/ad-hoc-trainer-postmark",
        label: "Ad-Hoc Trainer Email Templates",
        render: (model, hierarchy) => (<AdHocTemplates
            settings={model.adHocTrainerPostmarkTemplates}
            hierarchy={hierarchy}
            area={"AdHocTrainerPostmarkTemplates"}
            inputType={InputType.Number}
        />)
    },
    {
        route: "/ad-hoc-sms",
        label: "Ad-Hoc SMS Templates",
        render: (model, hierarchy) => (<AdHocTemplates
            settings={model.adHocSmsTemplates}
            hierarchy={hierarchy}
            area={"AdHocSmsTemplates"}
            inputType={InputType.TextArea}
        />)
    },
    {
        route: "/ad-hoc-corp-email-templates",
        label: "Ad-Hoc Email Templates - Corp",
        render: (model, hierarchy) => (<AdHocTemplates
            settings={model.adHocCorpEmailTemplates}
            hierarchy={hierarchy}
            area={"AdHocCorpEmailTemplates"}
            inputType={InputType.Number}
        />)
    },
    {
        route: "/graph",
        label: "Graph Access",
        render: (model, hierarchy) => <Graph settings={model.graphSettings} hierarchy={hierarchy} />
    },
    {
        route: "/emailer",
        label: "Emailer",
        render: (model, hierarchy) => <Emailer settings={model.emailerSettings} hierarchy={hierarchy} />
    },
    {
        route: "/geocoding",
        label: "Geocoding",
        render: (model, hierarchy) => <Geocoding settings={model.geocodingSettings} hierarchy={hierarchy} />
    },
    {
        route: "/stripe",
        label: "Stripe",
        render: (model, hierarchy) => <Stripe settings={model.stripeSettings} hierarchy={hierarchy} />
    },
    {
        route: "/bookingApi",
        label: "Booking API",
        render: (model, hierarchy) => <BookingApi settings={model.bookingApi} hierarchy={hierarchy} />
    },
    {
        route: "/azuresearch",
        label: "Azure Search",
        render: (model, hierarchy) => <AzureSearch settings={model.azureSearchSettings} hierarchy={hierarchy} />
    },
    {
        route: "/dorsRestfulService",
        label: "DORS RESTful Service",
        render: (model, hierarchy) => <DorsRestfulService settings={model.dorsRestfulServiceSettings} hierarchy={hierarchy} />
    },
    {
        route: "/dorsProxy",
        label: "DORS Proxy",
        render: (model, hierarchy) => <DorsProxy settings={model.dorsProxySettings} hierarchy={hierarchy} />
    },
    {
        route: "/externalresources",
        label: "External Resources",
        render: (model, hierarchy) => <ExternalResources settings={model.externalResourcesSettings} hierarchy={hierarchy} />
    },
    {
        route: "/notifier",
        label: "Notifier",
        render: (model, hierarchy) => <Notifier settings={model.notifierSettings} hierarchy={hierarchy} />
    },
    {
        route: "/smsService",
        label: "SMS Service",
        render: (model, hierarchy) => <SmsService settings={model.smsServiceSettings} hierarchy={hierarchy} />
    },
    {
        route: "/sms",
        label: "SMS Templates - NDORS",
        render: (model, hierarchy) => <Sms settings={model.smsSettings} hierarchy={hierarchy} />
    },
    {
        route: "/ddrs-sms",
        label: "SMS templates - DDRS",
        render: (model, hierarchy) => <DdrsSms settings={model.ddrsSmsSettings} hierarchy={hierarchy} />
    },
    {
        route: "/corporate-sms",
        label: "SMS Templates - Corp",
        render: (model, hierarchy) => <CorporateSms settings={model.corporateSmsSettings} hierarchy={hierarchy} />
    },
    {
        route: "/bookingApp",
        label: "NDORS/DDRS booking app",
        render: (model, hierarchy) => <BookingApp settings={model.bookingAppSettings} hierarchy={hierarchy} />
    },
    {
        route: "/cpcBookingApp",
        label: "CPC booking app",
        render: (model, hierarchy) => <CpcBookingApp settings={model.cpcBookingAppSettings} hierarchy={hierarchy} />
    },
    {
        route: "/zoomService",
        label: "Zoom Service",
        render: (model, hierarchy) => <ZoomService settings={model.zoomSettings} hierarchy={hierarchy} />
    },
    {
        route: "/genesys",
        label: "Genesys",
        render: (model, hierarchy) => <Genesys settings={model.genesysSettings} hierarchy={hierarchy} />
    },
    {
        route: "/trainerInvoices",
        label: "Trainer Invoices",
        render: (model, hierarchy) => <TrainerInvoices settings={model.trainerInvoicesSettings} hierarchy={hierarchy} />
    },
    {
        route: "/alertEmails",
        label: "Alert Emails",
        render: (model, hierarchy) => <AlertEmails settings={model.alertEmailsSettings} hierarchy={hierarchy} />
    },
    {
        route: "/ddrsSettings",
        label: "DDRS",
        render: (model, hierarchy) => <DdrsSetting settings={model.ddrsSettings} hierarchy={hierarchy} />
    },
    {
        route: "/pdfProcessing",
        label: "PDF Processing",
        render: (model, hierarchy) => <PdfProcessingSetting settings={model.pdfProcessingSettings} hierarchy={hierarchy} />
    },
    {
        route: "/continuumApi",
        label: "Continuum API",
        render: (model, hierarchy) => <ContinuumApi settings={model.continuumApiSettings} hierarchy={hierarchy} />
    },
    {
        route: "/features",
        label: "Features",
        render: (model, hierarchy) => <Features settings={model.featureSettings} hierarchy={hierarchy} />
    },
    {
        route: "/cacheSettings",
        label: "Cache Settings",
        render: (model, hierarchy) => <Caching settings={model.cacheSettings} hierarchy={hierarchy} />
    },
    {
        route: "/addressLookup",
        label: "Address Lookup",
        render: (model, hierarchy) => <AddressLookup settings={model.addressLookupSettings} hierarchy={hierarchy} />
    },
    {
        route: "/recaptcha",
        label: "ReCaptcha Settings",
        render: (model, hierarchy) => <Recaptcha settings={model.recaptchaSettings} hierarchy={hierarchy} />
    },
    {
        route: "/functionAppAuth",
        label: "Function App Auth",
        render: (model, hierarchy) => <FunctionAuth settings={model.functionAppAuthSettings} hierarchy={hierarchy} />
    },
    {
        route: "/accountLockSettings",
        label: "Account Lock Settings",
        render: (model, hierarchy) => <AccountLock settings={model.accountLockSettings} hierarchy={hierarchy} />
    },
    {
        route: "/testSettings",
        label: "Test Settings",
        render: (model, hierarchy) => <TestSettings settings={model.testSettings} hierarchy={hierarchy} />
    },
    {
        route: "/dorsBookingAccountLockSettings",
        label: "Dors Booking Account Lock Settings",
        render: (model, hierarchy) => <DorsBookingAccountLock settings={model.dorsBookingAccountLockSettings} hierarchy={hierarchy} />
    },
    {
        route: "/corporateAccountLockSettings",
        label: "Commercial Account Lock Settings",
        render: (model, hierarchy) => <CorporateAccountLock settings={model.corporateAccountLockSettings} hierarchy={hierarchy} />
    },
    {
        route: "/clarity",
        label: "Microsoft Clarity",
        render: (model, hierarchy) => <ClaritySettings settings={model.claritySettings} hierarchy={hierarchy} />
    },
    {
        route: "/gtm",
        label: "Google Tag Manager",
        render: (model, hierarchy) => <GoogleTagManagerSettings settings={model.googleTagManagerSettings} hierarchy={hierarchy} />
    },
    {
        route: "/domainBlacklist",
        label: "Domain Blacklist",
        render: (model, hierarchy) => <DomainBlacklist settings={model.domainBlacklistSettings} hierarchy={hierarchy} />
    },
    {
        route: "/hotjar",
        label: "Hotjar",
        render: (model, hierarchy) => <HotjarSettings settings={model.hotjarSettings} hierarchy={hierarchy} />
    }
];

// Turn the above definitions into route configuration objects for redux-little-router.
export const systemConfigurationRoutes = getRoutesFromPanes(systemConfigurationPanes);

export function getRoutesFromPanes(panes: PaneDefinition[]) {
    return panes.reduce(
        (routes, pane) => ({
            ...routes,
            [pane.route]: {
                title: pane.label
            }
        }),
        {});
}
