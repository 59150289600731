import * as React from "react";
import { dateRange, datesConsecutive, EventInstanceWithOrdersListModel } from "../model";
import { Icon } from "semantic-ui-react";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import "./offeredCourseDetails.scss";
import { SelectCourseButton } from "./SelectCourseButton";
import { useSelector } from "react-redux";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { doesPriceIncludeVat } from "@common/crud/organisation/model";

export interface OfferedRebookedCourseDetailsProps {
    offeredCourse: EventInstanceWithOrdersListModel;
    selectedEventInstanceId?: string;
    setSelectedEventInstance: (eventInstanceId: string) => void;
}

export const OfferedRebookedCourseDetails = (props: OfferedRebookedCourseDetailsProps) => {
    const { offeredCourse, selectedEventInstanceId, setSelectedEventInstance } = props;

    const businessLineType = useSelector(businessLineTypeSelector);

    const [showMore, setShowMore] = React.useState(false);

    const onShowMoreToggle = React.useCallback(() => {
        setShowMore(!showMore);
    }, [showMore]);

    const formattedPrice = doesPriceIncludeVat(+businessLineType)
        ? `£${(offeredCourse.priceInPence / 100).toFixed(2)}`
        : `£${(offeredCourse.priceInPence / 100).toFixed(2)} + VAT`;

    return (
        <div className="offered-course-details">
            <div className="date-part">
                <div className="center-text"><Icon name="calendar" /></div>
                {offeredCourse.startTimes.length > 1 ?
                    <>
                        <div className="center-text">{`${offeredCourse.startTimes.length} DAYS`}</div>
                        <div className="center-text">{dateRange(offeredCourse.startTimes)}</div>
                        <div className="center-text">{datesConsecutive(offeredCourse.startTimes) ? "consecutive" : "non-consecutive"}</div>
                    </> : <>
                        <div className="center-text">{offeredCourse.eventInstanceDeliveryDateTime?.format("ddd")?.toUpperCase()}</div>
                        <div className="center-text bigger-font">{offeredCourse.eventInstanceDeliveryDateTime?.format("DD")}</div>
                        <div className="center-text">{offeredCourse.eventInstanceDeliveryDateTime?.format("MMM")?.toUpperCase()}</div>
                    </>
                }
            </div>
            <div className="details-part">
                <div className="product-name">{offeredCourse.eventTypeName}</div>
                {offeredCourse.startTimes.length > 1 ?
                    <>
                        <div className="dates-part">
                            <div>
                                <Icon name="time" />{`Day 1: ${offeredCourse.startTimes[0]?.format("DD MMM HH:mm")}`
                                    + ` - ${offeredCourse.endTimes[0]?.format("HH:mm")}`}
                                <Icon name={showMore ? "chevron up" : "chevron down"} className="clickable-chevron" onClick={onShowMoreToggle} />
                            </div>
                            {showMore && offeredCourse.startTimes.slice(1).map((startTime, index) => (
                                <div key={`${offeredCourse.id}-date-${index}`}>
                                    <Icon name="time" />{`Day ${index + 2}: ${startTime?.format("DD MMM HH:mm")}`
                                    + `- ${offeredCourse.endTimes[index + 1]?.format("HH:mm")}`}
                                </div>
                            ))}
                        </div>
                    </> : <>
                        <div><Icon name="time" />
                            {offeredCourse.eventInstanceDeliveryDateTime?.format("HH:mm")} - {offeredCourse.eventInstanceDeliveryDateTimeEnd?.format("HH:mm")}
                        </div>
                    </>
                }
                <div><Icon name="map pin" />
                    {offeredCourse.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite ? `Delivered in ${offeredCourse.venueName}` : "Delivered Online"}
                </div>
                <div><Icon name="user" />{offeredCourse.openPlacesCount - offeredCourse.seatCount} places available</div>
            </div>
            <div className="actions-part">
                <div className="price end-text">{formattedPrice}</div>
                <SelectCourseButton
                    offeredCourseId={offeredCourse.id}
                    selectedEventInstanceId={selectedEventInstanceId}
                    setSelectedEventInstance={setSelectedEventInstance}
                />
            </div>
        </div>
    );
};
