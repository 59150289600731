import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { throttle } from "lodash";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import {  Button, Modal } from "semantic-ui-react";
import { MonitorFee } from "../../model";
import { MonitorPayTable } from "./MonitorPayTable";

interface MonitorPayEditModalProps {
currentTrainerFees?: MonitorFee[];
onChange: (value: any, valid: boolean) => void;
push: (path: string) => void;
}

export const MonitorPayEditModal: React.FC<MonitorPayEditModalProps> = ({ currentTrainerFees, onChange, push }) => {

    const startOfCurrentMonth = moment().utc().startOf("month");
    const blankTrainerFee: MonitorFee ={ effectiveDate: startOfCurrentMonth, monitor: 0 };
    const validationObject: { [key: string]: boolean} = setUpValidation(currentTrainerFees, blankTrainerFee);

    const [open, setOpen] = React.useState(false);
    const [newTrainerFee, setNewTrainerFee] = React.useState<MonitorFee>(null);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [formValidationObject, setFormValidationObject] = React.useState<{[key: string]: boolean}>(validationObject);
    const valid = formValidationObject? Object.values(formValidationObject).every(v => v): false;

    const throttledEffectiveDateWarning = React.useMemo(() => throttle(displayEffectiveDateWarning, 1000, { trailing: false }), []);

    function onClose() {
        push("trainer/edit");
        setOpen(false);
        setNewTrainerFee(null);
        setEditing(false);
        setFormValidationObject(validationObject);
    }

    function onSave() {
        onChange([...currentTrainerFees, newTrainerFee], true);
        onClose();
    }

    function onOpen() {
        push("trainer/monitorObserverFeesOpen/edit");
        setOpen(true);
    }

    function onAddRowClick() {
        const startOfMonthFormatted = startOfCurrentMonth.format(DateFormat.DayMonthYear);
        const startOfMonthIsValid = !currentTrainerFees.map(fees => fees.effectiveDate.format(DateFormat.DayMonthYear)).
            includes(startOfCurrentMonth.format(DateFormat.DayMonthYear));

        if (!startOfMonthIsValid) {
            throttledEffectiveDateWarning(startOfMonthFormatted);
        }
        setNewTrainerFee(blankTrainerFee);
        setEditing(true);
        setFormValidationObject({ ...formValidationObject, effectiveDate: startOfMonthIsValid });
    }

    function displayEffectiveDateWarning(date: string)  {
        toast.warning(`effective date ${date} already exists in the configuration`);};

    return (
        <Modal
            size={"fullscreen"}
            onClose={onClose}
            onOpen={onOpen}
            trigger={<Button content={"Edit"} />}
            open={open}
        >
            <Modal.Content>
                <MonitorPayTable trainerFees={currentTrainerFees}
                    newTrainerFee={newTrainerFee} setNewTrainerFee={setNewTrainerFee}
                    push={push} onAddRowClick={onAddRowClick} editing={editing} formValidationObject={formValidationObject}
                    setFormValidationObject={setFormValidationObject} displayEffectiveDateWarning={throttledEffectiveDateWarning} editable />
                <Modal.Actions>
                    <Button
                        content="Close"
                        className="cancel-action"
                        onClick={onClose}
                    />
                    <Button
                        content="Save"
                        positive
                        onClick={onSave}
                        disabled={!editing || !valid}
                    />
                </Modal.Actions>
            </Modal.Content>
        </Modal>
    );};

function setUpValidation(currentTrainerFees: MonitorFee[], blankTrainerFee: MonitorFee) {
    let validationObject: { [key: string]: boolean } = null;
    if (currentTrainerFees) {

        const validationArray = Object.keys(blankTrainerFee).map(k => [k, true]);
        validationObject = Object.fromEntries(validationArray);
    }
    return validationObject;
}

