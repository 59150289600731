import * as React from "react";
import { Tab } from "semantic-ui-react";

import { Trainer } from "../..";

import { WorkingRadius } from "./WorkingRadius";

export const WorkingRadiusTab = ({ trainer }: { trainer: Trainer }) => (
    <Tab.Pane>
        <br />
        <WorkingRadius trainer={trainer} />
    </Tab.Pane>
);
