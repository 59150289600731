/* eslint-disable max-lines */
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { toast } from "@common/toasts";
import { NoteAction } from "@common/notes/actions";
import moment from "moment";
import { MAKE_PAYMENT_SUCCESS, MAKE_PAYMENT_FAILURE } from "@common/payments/actiontypes";
import { PaymentSuccessPayload } from "@common/payments/model";
import { loadEventInstanceDetailSuccess, registrationSubmitSuccess } from "../eventInstance/actions";
import { CarDetails } from "../common/CarDetails";
import { SpecialRequirements } from "../common/SpecialRequirements";

import {
    RefundModel,
    AttendeeListModel,
    AttendeeDetailModel,
    AppState,
    EditContactDetailsModel,
    RegisterAttendeeModel,
    EditAutoRemovalDateModel, AttendeeEditModel, UpdateFlexiPayModel, DvsaUploadStatus, DvsaUploadStatusModel
} from "./model";
import { AttendeeApi } from "./attendeeApi";
import * as actions from "./actiontypes";
import { HistoryRecord } from "@common/history/model";
import { HistoryApi } from "@common/history/historyApi";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import {
    UpdateAttendanceIdRequest,
    UpdateAttendanceIdResponse,
    UpdateAttendanceIdWithDifferencesRequest,
    UpdateEventTypeIdAndNameRequest
} from "@common/crud/dorsBooking/model";
import { push } from "redux-little-router";
import { DrinkDriveOffenderAttendeeScoresModel } from "@common/crud/drinkDriveOffenders/model";
import { UPDATE_DRINKDRIVEOFFENDER_SUCCESS } from "./actiontypes";
import { DdrsAttendeeApi } from "./ddrsAttendeeApi";
import { DocumentType } from "@common/crud/attendee/model";
import { AttendeeGroupApi } from "@common/crud/attendee/attendeeGroupApi";
import { loadDelegateDetailSuccess } from "../delegate/actions";
import { DelegateApi } from "../delegate/delegateApi";
import * as delegateActions from "../delegate/actiontypes";
import { EventInstanceApi } from "../eventInstance";
import { OrderRefundTypeEnum } from "../order/model";

export type AttendeeAction =
    ({ type: actions.LOAD_ATTENDEES_SUCCESS } & Payload<AttendeeListModel[]>)
    | ({ type: actions.LOAD_ATTENDEE_DETAIL_SUCCESS } & Payload<AttendeeDetailModel>)
    | ({ type: actions.REFUND_ATTENDEE_SUCCESS } & Payload<RefundModel>)
    | ({ type: actions.SAVE_ATTENDEE_SUCCESS } & Payload<AttendeeDetailModel>)
    | ({ type: actions.REFUND_ATTENDEE_FAILURE } & Payload<RefundModel>)
    | ({ type: actions.UPDATE_CONTACT_DETAILS_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; model: EditContactDetailsModel }>)
    | ({ type: actions.UPDATE_SPECIAL_REQUIREMENTS_SUCCESS } & Payload<{ attendeeId: string; model: SpecialRequirements }>)
    | ({ type: actions.UPDATE_CAR_DETAILS_SUCCESS } & Payload<{ attendeeId: string; model: CarDetails }>)
    | ({ type: actions.UPDATE_ATTENDANCE_ID_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; attendanceId: number }>)
    | ({ type: actions.UPDATE_AUTO_REMOVAL_DATE_SUCCESS } & Payload<{ attendeeId: string; removalDate: moment.Moment }>)
    | ({ type: MAKE_PAYMENT_SUCCESS } & Payload<PaymentSuccessPayload>)
    | ({ type: MAKE_PAYMENT_FAILURE } & Payload<PaymentSuccessPayload>)
    | ({ type: actions.LOAD_ATTENDEE_HISTORY_SUCCESS } & Payload<{ id: string; historyRecords: HistoryRecord[] }>)
    | ({ type: UPDATE_DRINKDRIVEOFFENDER_SUCCESS } & Payload<AttendeeListModel[]>)
    | ({ type: actions.UPDATE_FLEXIPAY_SUCCESS } & Payload<{ attendeeId: string; flexiPayPurchased: boolean; flexiPayExpiryDate: moment.Moment }>)
    | ({ type: actions.UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS} & Payload<{ dorsBookingId: string; eventTypeId: string; eventTypeName: string }>)
    | ({ type: actions.LOAD_ATTENDEES_FROM_SUCCESS} & Payload<moment.Moment>)
    | NoteAction
    | ({ type: actions.UPDATE_DVSA_UPLOAD_STATUS_SUCCESS } & Payload<DvsaUploadStatusModel>);

export const loadAttendeesSuccess = (payload: AttendeeListModel[]): AttendeeAction => ({
    payload,
    type: actions.LOAD_ATTENDEES_SUCCESS
});

export const loadAttendeesFromSuccess = (payload: moment.Moment): AttendeeAction => ({
    payload,
    type: actions.LOAD_ATTENDEES_FROM_SUCCESS
});

export const refundSuccess = (payload: RefundModel): AttendeeAction => ({
    payload,
    type: actions.REFUND_ATTENDEE_SUCCESS
});

export const refundFailure = (payload: RefundModel): AttendeeAction => ({
    payload,
    type: actions.REFUND_ATTENDEE_FAILURE
});

export const loadAttendeeDetailSuccess = (attendee: AttendeeDetailModel) => ({
    payload: attendee,
    type: actions.LOAD_ATTENDEE_DETAIL_SUCCESS
});

export const updateContactDetailsSuccess = (model: { correlationId: string; attendeeId: string; model: EditContactDetailsModel }) => ({
    payload: model,
    type: actions.UPDATE_CONTACT_DETAILS_SUCCESS
});

export const updateSpecialRequirementsSuccess = (model: { attendeeId: string; model: SpecialRequirements }) => ({
    payload: model,
    type: actions.UPDATE_SPECIAL_REQUIREMENTS_SUCCESS
});

export const updateAttendanceIdSuccess = (model: { attendeeId: string; attendanceId: number; correlationId: string }) => ({
    payload: model,
    type: actions.UPDATE_ATTENDANCE_ID_SUCCESS
});

export const updateEventTypeIdAndNameSuccess = (model: { dorsBookingId: string; eventTypeId: string; eventTypeName: string }) => ({
    payload: model,
    type: actions.UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS
});

export const getHistorySuccess = (model: { id: string; historyRecords: HistoryRecord[] }): AttendeeAction => ({
    payload: model,
    type: actions.LOAD_ATTENDEE_HISTORY_SUCCESS
});

export const updateAttendeeCarDetailsSuccess = (model: { attendeeId: string; model: CarDetails }) => ({
    payload: model,
    type: actions.UPDATE_CAR_DETAILS_SUCCESS
});

export const updateAutoRemovalDateSuccess = (attendeeId: string, removalDate: moment.Moment) => ({
    payload: { attendeeId, removalDate },
    type: actions.UPDATE_AUTO_REMOVAL_DATE_SUCCESS
});

export const updateFlexiPaySuccess = (attendeeId: string, flexiPayPurchased: boolean, flexiPayExpiryDate: moment.Moment) => ({
    payload: { attendeeId, flexiPayPurchased, flexiPayExpiryDate },
    type: actions.UPDATE_FLEXIPAY_SUCCESS
});

export const updateDvsaUploadStatusSuccess = (attendeeId: string, dvsaUploadStatus: DvsaUploadStatus) => ({
    payload: { attendeeId, dvsaUploadStatus },
    type: actions.UPDATE_DVSA_UPLOAD_STATUS_SUCCESS
});

export function loadAttendeeDetail({ attendeeId, eventInstanceId, addAuditEntry }: { attendeeId: string; eventInstanceId: string; addAuditEntry: boolean }) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const attendee = getState().attendees.filter(c => c.id === attendeeId)[0];

        if (attendee === undefined
            || attendee.detailUpdated === undefined
            || attendee.detailUpdated.isBefore(attendee.listUpdated)) {

            const api = new AttendeeApi(eventInstanceId);
            const result = await loadAndTrack(dispatch, "loadAttendeeDetail", api.detail(attendeeId, addAuditEntry));
            dispatch(loadAttendeeDetailSuccess(result));
        }
    };
}

export function loadAttendeesForEventInstance({ eventInstanceId }: { eventInstanceId: string }) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, actions.LOAD_ATTENDEES, api.getAttendeesForEventInstance());
        dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
        dispatch(loadAttendeesSuccess(result.attendees));
    };
}

export function generateCertificateForAttendees(attendeeIds: string[], eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "certificate", api.generateCertificate(attendeeIds));
        dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
        dispatch(loadAttendeesSuccess(result.attendees));
    };
}

export function createCertificateForAttendeeInList( attendeeId: string, eventInstanceId: string, documentType: DocumentType ) {
    return async (dispatch: Dispatch) => {
        const api = new DdrsAttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "certificate", api.createCertificateForAttendeeInList(attendeeId, eventInstanceId, documentType));
        dispatch(loadAttendeesSuccess(result));
    };
}

export function createCertificateForAttendeeDetail( attendeeId: string, eventInstanceId: string, documentType: DocumentType ) {
    return async (dispatch: Dispatch) => {
        const api = new DdrsAttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "certificate", api.createCertificateForAttendeeDetail(attendeeId, eventInstanceId, documentType));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function refund(refundModel: RefundModel) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(refundModel.eventInstanceId);
        const refundResponse = await loadAndTrack(dispatch, "refund", api.refund(refundModel));

        if (refundResponse.success) {
            toast.success("Refund successful");
            const newBalance: RefundModel = {
                attendeeId: refundModel.attendeeId,
                amount: refundModel.amount,
                message: ""
            };

            dispatch(refundSuccess(newBalance));
        }
        else {
            toast.error(refundResponse.message);
            const errorInRefund: RefundModel = {
                attendeeId: refundModel.attendeeId,
                amount: 0,
                message: refundResponse.message
            };

            dispatch(refundFailure(errorInRefund));
        }
    };
}

export function cancelBooking(eventInstanceId: string, attendeeId: string, reasonId: string, otherReason: string, freeRebookingEnabled: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "cancelBooking", api.cancelBooking(attendeeId, reasonId, otherReason, freeRebookingEnabled));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function cancelCorporateBooking(eventInstanceId: string, attendeeId: string, delegateId: string, organisationId: string, reasonId: string,
    otherReason: string, isPaymentStillNeededForBnplOrder: OrderRefundTypeEnum) {
    return async (dispatch: Dispatch) => {
        const attendeeApi = new AttendeeApi(eventInstanceId);
        await loadAndTrack(
            dispatch,
            "cancelCorporateBooking",
            attendeeApi.cancelCorporateBooking(attendeeId, reasonId, otherReason, isPaymentStillNeededForBnplOrder));

        const isDelegate = delegateId !== attendeeId;
        const delegateApi = new DelegateApi();
        const result = await loadAndTrack(dispatch, delegateActions.LOAD_DELEGATE_DETAIL,
            delegateApi.detail(
                isDelegate ? organisationId : undefined,
                isDelegate ? undefined : eventInstanceId,
                isDelegate ? delegateId : undefined,
                isDelegate ? undefined : attendeeId
            ));

        dispatch(loadDelegateDetailSuccess(delegateId ? result : { ...result, attendeeWithoutDelegate: true }));

    };
}

export function cancelGroupBooking(attendeeId: string,correlationId: string, reasonId: string, otherReason: string, freeRebookingEnabled: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeGroupApi();
        const result = await loadAndTrack(dispatch, "cancelGroupBooking", api.cancelGroupBooking(attendeeId, correlationId, reasonId, otherReason,
            freeRebookingEnabled));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function cancelAttendeeScheduledPaymentPlan(attendeeId: string, eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "cancelAttendeeScheduledPaymentPlan", api.cancelScheduledPlan(attendeeId));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function updateAttendeeContactDetails(attendeeId: string, eventInstanceId: string, correlationId: string, model: EditContactDetailsModel) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        await loadAndTrack(dispatch, "updateAttendeeContactDetails", api.updateContactDetails(attendeeId, model));
        dispatch(updateContactDetailsSuccess({ attendeeId, model, correlationId }));
    };
}

export function updateAttendeeOptionalCertificateRequirement(attendeeId: string, eventInstanceId: string, productCategory: number, required: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateAttendeeOptionalCertificateRequirement",
            api.updateOptionalCertificateRequirement(attendeeId, productCategory, required));
        dispatch(saveAttendeeSuccess(result));
    };
}

export function updateAttendeeSpecialRequirements(attendeeId: string, eventInstanceId: string, model: SpecialRequirements, sendComms?: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        await loadAndTrack(dispatch, "updateAttendeeSpecialRequirements", api.updateSpecialRequirements(attendeeId, model, sendComms));
        dispatch(updateSpecialRequirementsSuccess({ attendeeId, model }));
    };
}

export function updateAutoRemovalDate(attendeeId: string, eventInstanceId: string, model: EditAutoRemovalDateModel) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateAutoRemovalDate", api.updateAutoRemovalDate(attendeeId, model));
        if (result.success) {
            dispatch(updateAutoRemovalDateSuccess(attendeeId, model.autoRemovalDate));
        } else {
            toast.error(result.message);
        }
    };
}

export function updateAttendeeCarDetails(attendeeId: string, eventInstanceId: string, model: CarDetails) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        await loadAndTrack(dispatch, "updateAttendeeCarDetails", api.updateCarDetails(attendeeId, model));
        dispatch(updateAttendeeCarDetailsSuccess({ attendeeId, model }));
    };
}

export function adjustAdditionalFees(attendeeId: string, eventInstanceId: string, feeAdjustmentAmount: number) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "adjustAdditionalFees", api.adjustAdditionalFees(attendeeId, feeAdjustmentAmount));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function updateAttendanceId(request: UpdateAttendanceIdWithDifferencesRequest, resultCallback: () => void) {
    return async (dispatch: Dispatch) => {
        const api = new DorsBookingApi();
        const result = await loadAndTrack(dispatch, "updateAttendanceId", api.updateAttendanceId(request));
        if (result) {
            dispatch(updateAttendanceIdSuccess(request));
            resultCallback();
            toast.success("Record updated successfully");
        }
    };
}

export function updateEventTypeIdAndName(request: UpdateEventTypeIdAndNameRequest, resultCallback: () => void) {
    return async (dispatch: Dispatch) => {
        const api = new DorsBookingApi();
        const result = await loadAndTrack(dispatch, "updateEventTypeIdAndName", api.updateEventTypeIdAndName(request));
        if (result) {
            dispatch(updateEventTypeIdAndNameSuccess(request));
            resultCallback();
            toast.success("Record updated successfully");
        }
    };
}

export function saveAttendee(eventInstanceId: string, model: AttendeeEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "saveAttendee", api.save(eventInstanceId, model));

        dispatch(saveAttendeeSuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export const saveAttendeeSuccess = (payload: AttendeeDetailModel): AttendeeAction => ({
    payload,
    type: actions.SAVE_ATTENDEE_SUCCESS
});

export function validateAttendanceIdUpdate(request: UpdateAttendanceIdRequest, resultCallback: (result: UpdateAttendanceIdResponse) => void) {
    return async (dispatch: Dispatch) => {
        const api = new DorsBookingApi();
        const result: UpdateAttendanceIdResponse = await loadAndTrack(dispatch, "validateAttendanceIdUpdate", api.validateAttendanceIdUpdate(request));

        resultCallback(result);
    };
}

export function getAttendeeHistory(attendeeId: string, correlationId: string) {
    return async (dispatch: Dispatch) => {
        const api = new HistoryApi(correlationId);
        const result = await loadAndTrack(dispatch, "getAttendeeHistory", api.getHistory());

        dispatch(getHistorySuccess({ id: attendeeId, historyRecords: result }));
    };
}

export function submitAttendanceRegister(eventInstanceId: string, attendees: RegisterAttendeeModel[], isStageTwo: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);

        const result = await loadAndTrack(dispatch, "submitRegister", api.submitRegister(attendees, isStageTwo));
        dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
        dispatch(loadAttendeesSuccess(result.attendees));

        await loadAndTrack(dispatch, "registerStage", api.registerStage(isStageTwo));

        // Stage 1: completion is always false as register can be completed from stage 2 only
        dispatch(registrationSubmitSuccess({ id: eventInstanceId, completion: false, isStageTwo }));
    };
}

export function submitDelegateAttendanceRegister(eventInstanceId: string, attendees: AttendeeListModel[], isStageTwo: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);

        const result = await loadAndTrack(dispatch, "submitRegister", api.submitRegister(attendees, isStageTwo));
        dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
        dispatch(loadAttendeesSuccess(result.attendees));

        await loadAndTrack(dispatch, "registerStage", api.registerStage(isStageTwo));

        // Stage 1: completion is always false as register can be completed from stage 2 only
        dispatch(registrationSubmitSuccess({ id: eventInstanceId, completion: false, isStageTwo }));
    };
}

export function submitRegister(eventInstanceId: string, attendees: AttendeeListModel[], completion: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);

        const result = await loadAndTrack(dispatch, "submitRegister", api.submitRegister(
            attendees.map(a => ({ ...a, registerSubmitted: completion }))
            ,true));
        dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
        dispatch(loadAttendeesSuccess(result.attendees));

        await loadAndTrack(dispatch, "registerStage", api.registerStage(true));

        const eventInstanceApi = new EventInstanceApi();
        const eventInstanceResult = await loadAndTrack(dispatch, "loadEventInstanceDetail", eventInstanceApi.detail(eventInstanceId));
        dispatch(loadEventInstanceDetailSuccess(eventInstanceResult));
    };
}

export function gotoStageOne(eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        await loadAndTrack(dispatch, "registerStage", api.registerStage(false));
        dispatch(registrationSubmitSuccess({ id: eventInstanceId, completion: false, isStageTwo: false }));
    };
}

export function updatePositiveBalanceExemptionStatus(eventInstanceId: string, attendeeId: string, exemptionStatus: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updatePositiveBalanceExemptionStatus",
            api.updatePositiveBalanceExemptionStatus(attendeeId, exemptionStatus));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function UndoAttendeeCancellation(attendeeId: string, eventInstanceId: string) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "undoAttendeeCancellation", api.undoCancellation(attendeeId, eventInstanceId));
        // eslint-disable-next-line eqeqeq
        if (result?.id != null) {
            toast.success("Cancellation undone successfully, please wait to be redirected to the new attendee.");
        } else {
            toast.error("There was an error attempting to undo the cancellation.");
        }
        return result;
    };
}

export function updateFreeRebooking(eventInstanceId: string, attendeeId: string, freeRebookingEnabled: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateFreeRebooking", api.updateFreeRebooking(attendeeId, freeRebookingEnabled));
        dispatch(loadAttendeeDetailSuccess(result));
    };
}

export function setEligibleForConcessions(attendeeId: string, eventInstanceId: string, correlationId: string, eligible: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "setEligibleForConcessions",
            api.setEligibleForConcessions(attendeeId, eventInstanceId, correlationId, eligible));

        dispatch(loadAttendeeDetailSuccess(result));
    };
};

export const updateDrinkDriveOffenderScoresSuccess = (payload: AttendeeListModel[]): AttendeeAction => ({
    payload,
    type: actions.UPDATE_DRINKDRIVEOFFENDER_SUCCESS
});

export function updateDrinkDriveOffenderScores(eventInstanceId: string, model: DrinkDriveOffenderAttendeeScoresModel) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateDrinkDriveOffenderScores", api.updateDrinkDriveOffenderScores(eventInstanceId, model));
        dispatch(updateDrinkDriveOffenderScoresSuccess(result));
    };
}

export function updateFlexiPay(eventInstanceId: string, attendeeId: string, model: UpdateFlexiPayModel) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateFlexiPay", api.updateFlexiPay(attendeeId, model));

        if (result.success) {
            dispatch(loadAttendeeDetailSuccess(result.attendeeDetailModel));
        } else {
            toast.error(result.errors);
        }
    };
}

export function updateDvsaUploadStatus(eventInstanceId: string, attendeeId: string, dvsaUploadStatus: DvsaUploadStatus) {
    return async (dispatch: Dispatch) => {
        const api = new AttendeeApi(eventInstanceId);
        const result = await loadAndTrack(dispatch, "updateDvsaUploadStatus", api.updateDvsaUploadStatus(attendeeId, dvsaUploadStatus));
        dispatch(updateDvsaUploadStatusSuccess(attendeeId, result));
    };
}
