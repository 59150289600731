import { Location } from "redux-little-router";
import { HistoryRecord } from "@common/history/model";
import { Address } from "../common";
import { AuthState } from "reauthorize";
import { AppCommonState } from "@common/appCommonState";
import { CorporateOrConstructionOrganisationIndexFilterEnum } from "../search/model";
import * as moment from "moment";
import { AttendeeFieldValue, AttendeeIdTypeEnum, AttendeeTitleEnum } from "../attendee/model";
import { MxEmailCheckResult } from "../email/model";
import { ProductCategoryEnum } from "../eventType/model";
import { OrderPaymentStatus } from "../order/model";

export enum DrivingLicenceCountryEnum {
    None = 0,
    TBC = 1,
    GB = 2,
    NI = 3,
    NonGBNI = 4
}

export const DrivingLicenceCountry = {
    [DrivingLicenceCountryEnum.None]: "None",
    [DrivingLicenceCountryEnum.TBC]: "To Be Confirmed",
    [DrivingLicenceCountryEnum.GB]: "Great Britain",
    [DrivingLicenceCountryEnum.NI]: "Northern Ireland",
    [DrivingLicenceCountryEnum.NonGBNI]: "Non Great Britain / Northern Ireland"
};

export enum VocationalLicenceCategoryEnum {
    None = 1,
    TBC = 2,
    C1 = 3,
    C1E = 4,
    C = 5,
    CE = 6,
    D1 = 7,
    D1E = 8,
    DE = 9,
    D = 10,
    B = 11,
}

export const VocationalLicenceCategory = {
    [VocationalLicenceCategoryEnum.None]: "None",
    [VocationalLicenceCategoryEnum.TBC]: "To Be Confirmed",
    [VocationalLicenceCategoryEnum.C1]: "C1",
    [VocationalLicenceCategoryEnum.C1E]: "C1E",
    [VocationalLicenceCategoryEnum.C]: "C",
    [VocationalLicenceCategoryEnum.CE]: "CE",
    [VocationalLicenceCategoryEnum.D1]: "D1",
    [VocationalLicenceCategoryEnum.D1E]: "D1E",
    [VocationalLicenceCategoryEnum.DE]: "DE",
    [VocationalLicenceCategoryEnum.D]: "D",
    [VocationalLicenceCategoryEnum.B]: "B",
};

export const filteredVocationalLicenceCatogories = (currentCategories: number[], eventInstanceProductCategory?: ProductCategoryEnum) => {
    let categories = [
        VocationalLicenceCategoryEnum.None,
        VocationalLicenceCategoryEnum.TBC,
        VocationalLicenceCategoryEnum.B,
        VocationalLicenceCategoryEnum.C,
        VocationalLicenceCategoryEnum.C1,
        VocationalLicenceCategoryEnum.C1E,
        VocationalLicenceCategoryEnum.CE,
        VocationalLicenceCategoryEnum.D,
        VocationalLicenceCategoryEnum.D1,
        VocationalLicenceCategoryEnum.D1E,
        VocationalLicenceCategoryEnum.DE
    ];
    if (eventInstanceProductCategory && (
        eventInstanceProductCategory !== ProductCategoryEnum.AFV &&
        eventInstanceProductCategory !== ProductCategoryEnum.OnRoadWithCpc)
    ) {
        categories = categories.filter(k => k !== VocationalLicenceCategoryEnum.B);
    }
    return categories
        .filter(k => !(currentCategories || []).some(c => c === VocationalLicenceCategoryEnum.None)
            || k === VocationalLicenceCategoryEnum.None)
        .filter(k => !(currentCategories || []).some(c => c === VocationalLicenceCategoryEnum.TBC)
            || k === VocationalLicenceCategoryEnum.TBC)
        .filter(k => !(currentCategories || []).some(c => c !== VocationalLicenceCategoryEnum.None
            && c !== VocationalLicenceCategoryEnum.TBC) || (k !== VocationalLicenceCategoryEnum.None
            && +k !== VocationalLicenceCategoryEnum.TBC))
        .map(k => ({ text: VocationalLicenceCategory[k], value: k }));
};

export enum DelegateComparisonTypeEnum {
    None = 0,
    DrivingLicenceNumber = 1,
    Uin = 2
}

export const DelegateComparisonType = {
    [DelegateComparisonTypeEnum.None]: "None",
    [DelegateComparisonTypeEnum.DrivingLicenceNumber]: "Driving Licence Number",
    [DelegateComparisonTypeEnum.Uin]: "UIN",
};

export interface DelegateListModel {
    readonly id: string;
    readonly delegateComparisonType?: DelegateComparisonTypeEnum;
    readonly delegateUniqueIdentifier?: string;
    readonly title?: AttendeeTitleEnum;
    readonly fullName?: string;
    readonly email?: string;
    readonly mobileNumber?: string;
    readonly uin?: string;
    readonly licenceNumber?: string;
    readonly listUpdated?: moment.Moment;
}

export interface DelegateDetailModel {
    readonly id: string;
    readonly delegateComparisonType?: DelegateComparisonTypeEnum;
    readonly delegateUniqueIdentifier?: string;
    readonly uin?: string;
    readonly title?: AttendeeTitleEnum;
    readonly forename?: string;
    readonly surname?: string;
    readonly organisationId?: string;
    readonly organisationName?: string;
    readonly fullName?: string;
    readonly address?: Address;
    readonly email?: string;
    readonly mobileNumber?: string;
    readonly licenceNumber?: string;
    readonly nationalInsuranceNumber?: string;
    readonly citbRegistrationNumber?: string;
    readonly citbLevyNumber?: string;
    readonly drivingLicenceCountry?: number;
    readonly vocationalLicenceCategories?: number[];
    readonly dqcReference?: string;
    readonly dqcExpiry?: moment.Moment;
    readonly history?: HistoryRecord[];
    readonly detailUpdated?: moment.Moment;
    readonly errorUpdatingEmail?: boolean;
    readonly delegateAttendees?: DelegateAttendeeModel[];
    readonly drivingLicenceExactCountry?: string;
    readonly dateOfBirth?: moment.Moment;
    readonly attendeeFieldValues?: AttendeeFieldValue[];
    readonly deletionDate?: moment.Moment;
    readonly mxEmailCheckResult?: MxEmailCheckResult;
    readonly anonymized?: boolean;
    readonly attendeeWithoutDelegate?: boolean;
    readonly correlationId?: string;
    readonly corporateBookingHidden?: boolean;
    readonly managers?: { name: string; telephone: string; email: string }[];
    readonly score?: number;
    readonly idType?: AttendeeIdTypeEnum;
    readonly idLast4Digits?: string;
}

export interface DelegateAttendeeModel {
    eventInstanceId: string;
    attendeeId: string;
    seatId: string;
    correlationId: string;
    eventTypeName: string;
    venueName: string;
    deliveryDate: moment.Moment;
    addedOn: moment.Moment;
    cancelled: boolean;
    completed: boolean;
    deletionDate?: moment.Moment;
    fromOrderWithBookNowPayLater: boolean;
    bookNowPayLaterPaymentStatus: OrderPaymentStatus;
}

export type Delegate = DelegateListModel & DelegateDetailModel;

export interface DelegateCreateEditModel {
    id?: string;
    uin?: string;
    forename?: string;
    surname?: string;
    organisationId?: string;
    address?: Address;
    email?: string;
    mobileNumber?: string;
    licenceNumber?: string;
    drivingLicenceCountry?: number;
    vocationalLicenceCategories?: number[];
    dqcReference?: string;
    dqcExpiry?: moment.Moment;
    drivingLicenceExactCountry?: string;
    dateOfBirth?: moment.Moment;
    attendeeFieldValues?: AttendeeFieldValue[];
    deletionDate?: moment.Moment;
    delegateAttendees?: DelegateAttendeeModel[];
    mxEmailCheckResult?: MxEmailCheckResult;
    managers?: { name: string; telephone: string; email: string }[];
}

export interface SearchOptions {
    searchString?: string;
}

export interface DelegateState {
    readonly delegates: Delegate[];
}

export const BasicCorporateEventManagementSearchPath = "/corporate-event-management/search";

export const BasicConstructionEventManagementSearchPath = "/construction-event-management/search";

export const CorporateEventManagementSearchPath =
    `/corporate-event-management/search?filter=${CorporateOrConstructionOrganisationIndexFilterEnum.Delegate}`;

export const ConstructionEventManagementSearchPath =
    `/construction-event-management/search?filter=${CorporateOrConstructionOrganisationIndexFilterEnum.Delegate}`;

export type AppState = DelegateState & { router: Location };

export type DetailState = AppState & AuthState & AppCommonState;
