import * as React from "react";
import { useCallback, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";

import { configurationSelector } from "../configuration/selectors";

export type RichTextEditorProps = {
    onChange?: (htmlBody: string) => void;
}

export function RichTextEditor({ onChange }: RichTextEditorProps) {
    const ref = useRef(null);
    const apiKey = useApiKey();

    const editorInitialized = useCallback((event, editor) => {
        ref.current = editor;
    }, [ref]);

    const editorChanged = useCallback(() => {
        onChange(ref.current.getContent({ format: "html" }));
    }, [onChange]);

    return (
        <div>
            <Editor
                apiKey={apiKey}
                onInit={editorInitialized}
                onChange={editorChanged}
                init={{
                    plugins: "preview",
                    newline_behavior: "invert",
                    menubar: false,
                    statusbar: false,
                    toolbar: [
                        "preview",
                        "undo redo",
                        "bold italic forecolor",
                        "alignleft aligncenter alignright alignjustify",
                        "bullist numlist outdent indent"
                    ].join("|"),
                    // These plugins are only available for paid TinyMCE subscriptions.
                    // https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-spellchecker/
                    // https://www.tiny.cloud/docs/tinymce/6/autocorrect/
                    // at writing (Nov 2022) that's from $90 pcm.
                    // plugins: ["tinymcespellchecker", "autocorrect"]
                }}
            />
        </div>
    );
}

/**
 * I'm giving it a default because it's not a secret and it'll make configuring
 * and deploying the app to different environments easier and less likely to be
 * a breaking change.
 * Moved to a hook to it's easier to test.
 * @returns {string} The API key for TinyMCE.
 */
export function useApiKey(): string {
    const defaultApiKey = "yzy178tpc7m4esdtctjhi6lkio3gco4upamgpgq4n9azfcci";
    const apiKeyFromConfig = useSelector(configurationSelector).emailerSettings.RichTextEditorApiKey?.value;
    return String(apiKeyFromConfig ?? defaultApiKey);
}
