import * as React from "react";
import { Fragment } from "redux-little-router";
import { Login } from "@common/global";
import { DdrsLogin } from "./DdrsLogin";
import { Base as Landing } from "./landing";
import { Base as Bookings } from "./bookings";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { GenesysAuthorisationRedirectReturn } from "@common/genesys/components/GenesysAuthorisationRedirectReturn";
import { RebookSignIn } from "./rebookSignIn/RebookSignIn";

export const RouteFragments: React.FC = () => {
    return (
        <Fragment forRoute="/">
            <div>
                <Fragment forRoute="/">
                    <RebookSignIn />
                </Fragment>
                <Fragment forRoute="/login">
                    <Login />
                </Fragment>
                <Fragment forRoute="/landing">
                    <Landing />
                </Fragment>
                <Fragment forRoute="/bookings">
                    <Bookings />
                </Fragment>
                <Fragment forRoute={"/ddrsLogin"}>
                    <DdrsLogin />
                </Fragment>
                <Fragment forRoute={`/${PaymentPageNames[PaymentPageNamesEnum.GenesysRedirect]}`}>
                    <GenesysAuthorisationRedirectReturn />
                </Fragment>
            </div>
        </Fragment>
    );
};
