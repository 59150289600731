import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { RegionalCoordinatorCalendarSettings } from "./model";
import { RegionalCoordinatorApi } from "./RegionalCoordinatorApi";
import { toast } from "react-toastify";

const RegionalCoordinatorCalendarSettingsModal = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [regionalCoordinatorCalendarSettings, setRegionalCoordinatorCalendarSettings] = React.useState<RegionalCoordinatorCalendarSettings>({});

    React.useEffect(() => {
        async function loadData() {
            const api = new RegionalCoordinatorApi();
            setRegionalCoordinatorCalendarSettings(await api.getRegionalCoordinatorCalendarSettings());
        }

        if (open) {
            loadData();
        }
    }, [open]);

    const onChangeRegionalCoordinatorCalendarSettingsOpened = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    const onCancel = React.useCallback(() => {
        toast.info("Setting have been canceled");
        onChangeRegionalCoordinatorCalendarSettingsOpened();
    }, [onChangeRegionalCoordinatorCalendarSettingsOpened]);

    const onChange = React.useCallback((property: keyof RegionalCoordinatorCalendarSettings) => (value: string | number) => {
        setRegionalCoordinatorCalendarSettings({ ...regionalCoordinatorCalendarSettings, [property]: value });
    }, [regionalCoordinatorCalendarSettings]);

    const onSave = React.useCallback(async () => {
        async function saveData() {
            const api = new RegionalCoordinatorApi();
            await api.saveRegionalCoordinatorCalendarSettings(regionalCoordinatorCalendarSettings);
            toast.info("Settings have been Saved");
        }

        saveData();
        onChangeRegionalCoordinatorCalendarSettingsOpened();
    }, [onChangeRegionalCoordinatorCalendarSettingsOpened, regionalCoordinatorCalendarSettings]);

    return (
        <>
            <Button type="button" icon="cog" onClick={onChangeRegionalCoordinatorCalendarSettingsOpened} />
            <Modal size="mini" open={open} onClose={undefined}>
                <Modal.Header>Trainer Support Calendar settings</Modal.Header>
                <Modal.Content>
                    <Form className="extra-padding full-width-input">
                        <Input.Number
                            value={regionalCoordinatorCalendarSettings?.weekdayRcCoverFee}
                            label="Weekday RC Cover Fee"
                            onChange={onChange("weekdayRcCoverFee")}
                        />
                        <Input.Number
                            value={regionalCoordinatorCalendarSettings?.saturdayRcCoverFee}
                            label="Saturday RC Cover Fee"
                            onChange={onChange("saturdayRcCoverFee")}
                        />
                        <Input.Number
                            value={regionalCoordinatorCalendarSettings?.sundayRcCoverFee}
                            label="Sunday RC Cover Fee"
                            onChange={onChange("sundayRcCoverFee")}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onCancel}>Cancel</Button>
                    <Button onClick={onSave}>Save</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export { RegionalCoordinatorCalendarSettingsModal };
