/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_ALASKA_TASKS_SUCCESS = "LOAD_ALASKA_TASKS_SUCCESS";
export type LOAD_ALASKA_TASKS_SUCCESS = typeof LOAD_ALASKA_TASKS_SUCCESS;

export const LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS = "LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS";
export type LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS = typeof LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS;

export const COMPLETE_ALASKA_NUDGETASK_SUCCESS = "COMPLETE_ALASKA_NUDGETASK_SUCCESS";
export type COMPLETE_ALASKA_NUDGETASK_SUCCESS = typeof COMPLETE_ALASKA_NUDGETASK_SUCCESS;

export const CREATE_ALASKA_NUDGETASK_SUCCESS = "CREATE_ALASKA_NUDGETASK_SUCCESS";
export type CREATE_ALASKA_NUDGETASK_SUCCESS = typeof CREATE_ALASKA_NUDGETASK_SUCCESS;

export const LOAD_ALASKA_TASK_TYPES_SUCCESS = "LOAD_ALASKA_TASK_TYPES_SUCCESS";
export type LOAD_ALASKA_TASK_TYPES_SUCCESS = typeof LOAD_ALASKA_TASK_TYPES_SUCCESS;

export const LOAD_ALASKA_TASK_USERS_SUCCESS = "LOAD_ALASKA_TASK_USERS_SUCCESS";
export type LOAD_ALASKA_TASK_USERS_SUCCESS = typeof LOAD_ALASKA_TASK_USERS_SUCCESS;

export const LOAD_ALASKA_TASK_TRAINERS_SUCCESS = "LOAD_ALASKA_TASK_TRAINERS_SUCCESS";
export type LOAD_ALASKA_TASK_TRAINERS_SUCCESS = typeof LOAD_ALASKA_TASK_TRAINERS_SUCCESS;

export const LOAD_CA_EMAILS_SUCCESS = "LOAD_CA_EMAILS_SUCCESS";
export type LOAD_CA_EMAILS_SUCCESS = typeof LOAD_CA_EMAILS_SUCCESS;

export const LOAD_SENIOR_CA_EMAILS_SUCCESS = "LOAD_SENIOR_CA_EMAILS_SUCCESS";
export type LOAD_SENIOR_CA_EMAILS_SUCCESS = typeof LOAD_SENIOR_CA_EMAILS_SUCCESS;

export const LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS = "LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS";
export type LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS = typeof LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS;

export const LOAD_ALASKA_TASK_COUNT_SUCCESS = "LOAD_ALASKA_TASK_COUNT_SUCCESS";
export type LOAD_ALASKA_TASK_COUNT_SUCCESS = typeof LOAD_ALASKA_TASK_COUNT_SUCCESS;
