import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { useSelector } from "react-redux";
import { TrainerAvailabilityState } from "@common/availabilityTrainer/model";
import { Trainer } from "../..";
import { buildVenueCheckboxes } from "../venue/venue-checkbox";
import "./WorkingRadius.scss";

export function WorkingRadius({ trainer }: { trainer: Trainer }) {
    const availableVenues = useSelector((state: TrainerAvailabilityState) => {
        return state.trainerAvailability?.availableVenues;
    });

    return (
        <Grid>
            <Detail label="Working Radius" value={`${trainer.availabilityDistance} miles`} labelWidth={5} valueWidth={11} />
            {(availableVenues && trainer.availableVenueIds) && <Detail
                label="Venues"
                value={buildVenueCheckboxes(availableVenues.inRange.concat(availableVenues.nearRange), trainer.availableVenueIds)}
                labelWidth={5}
                valueWidth={11}
            />}
        </Grid>
    );
}
