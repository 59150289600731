import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form } from "semantic-ui-react";
import {
    EditComponent,
    EditProps as SharedEditProps,
    SaveDispatchProps
} from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import { phoneNumber } from "@common/validation";
import {
    OrganisationEditModel,
    AppState,
    BusinessDevelopmentManager,
    NoneOrganisationOption,
} from "../model";
import { corporateOrConstructionBasePathSelector, organisationSelector } from "../selectors";
import { loadBusinessDevelopmentManagers, saveOrganisation } from "../actions";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { optionsFromObject } from "@common/crud/common/optionsMappers";
import { NonDriverCustomerStatus } from "@common/crud/alaskaNudgeTask/model";
import { AppCommonState } from "@common/appCommonState";
import { toast } from "@common/toasts";
import { OrganisationOption } from "../../eventInstance/model";
import { getCorporateRelatedOrganisationOptions } from "../utils/organisationsHelper";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { ExtendedEmailInput } from "@common/components/ExtendedEmailInput";
import { ConstructionEditModal } from "./ConstructionEditModal";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";

export interface EditProps extends SharedEditProps<OrganisationEditModel> {
    open: boolean;
    businessDevelopmentManagers: BusinessDevelopmentManager[];
    organisationOptions: OrganisationOption[];
    loadBusinessDevelopmentManagers: () => void;
}

export interface DispatchProps extends SaveDispatchProps<OrganisationEditModel> {
    close: () => void;
}

export class ConstructionEditForm extends EditComponent<OrganisationEditModel, EditProps> {

    constructor(props: EditProps & SaveDispatchProps<OrganisationEditModel>) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onBdmChange = this.onBdmChange.bind(this);
    }

    public submitting = false;

    public componentDidMount() {
        this.props.loadBusinessDevelopmentManagers();
    }

    public shouldComponentUpdate() {
        return !this.submitting;
    }

    public onBdmChange(bdm: string) {
        this.updateNestedProperty("corporateOrganisationData.bdmId", bdm, true);
        const matchingBdm = this.props.businessDevelopmentManagers.find(m => m.id === bdm);
        this.updateNestedProperty("corporateOrganisationData.bdmName", matchingBdm?.name, true);
        this.updateNestedProperty("corporateOrganisationData.bdmEmail", matchingBdm?.email, true);
    }

    private updateRelatedOrganisation = (value: string, valid: boolean) => {
        this.updateProperty("relatedOrganisationId", value, valid);
    };

    public render() {
        const { values, showErrors } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <h2>Account Details</h2>
                <ExtendedTextInput
                    value={values.name}
                    label="Name"
                    required
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("name", value, valid)}
                />
                <ExtendedDropdown
                    value={values.corporateOrganisationData?.bdmId}
                    label="BDM"
                    showErrors={showErrors}
                    options={(values.corporateOrganisationData?.bdmId &&
                        !this.props.businessDevelopmentManagers.some(bdm => bdm.id === values.corporateOrganisationData.bdmId)
                        ? [...this.props.businessDevelopmentManagers,
                            {
                                id: values.corporateOrganisationData.bdmId,
                                name: values.corporateOrganisationData.bdmName,
                                email: values.corporateOrganisationData.bdmEmail
                            }
                        ] : this.props.businessDevelopmentManagers).map(bdm => ({ text: `${bdm.name} [${bdm.email}]`, value: bdm.id }))}
                    onChange={this.onBdmChange}
                    dynamicOptions
                    search
                />
                <ExtendedDropdownNumber
                    value={values.customerStatus}
                    label="Customer Status"
                    required
                    showErrors={showErrors}
                    options={optionsFromObject(NonDriverCustomerStatus)}
                    onChange={(value, valid) => this.updateProperty("customerStatus", value, valid)}
                />
                <ExtendedDropdown
                    value={values.relatedOrganisationId}
                    label="Related Organisation"
                    showErrors={showErrors}
                    options={this.props.organisationOptions}
                    onChange={this.updateRelatedOrganisation}
                    dynamicOptions
                    search
                />
                {!values.corporateOrganisationData?.openCourse && (
                    <>
                        <AddressLookup
                            required
                            showErrors={showErrors}
                            address={values.corporateOrganisationData?.address}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.address", value, valid)}
                        />
                        <ExtendedEmailInput
                            value={values.corporateOrganisationData?.emailAddress}
                            label="Email Address"
                            required
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.emailAddress", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.phoneNumber}
                            label="Phone Number"
                            validation={phoneNumber()}
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.phoneNumber", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.website}
                            label="Website"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.website", value, valid)}
                        />
                        <AddressLookup
                            title="Invoice Address"
                            showErrors={showErrors}
                            address={values.corporateOrganisationData.invoiceAddress}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoiceAddress", value, valid)}
                            autofill
                            required={false}
                        />
                        <ExtendedEmailInput
                            value={values.corporateOrganisationData?.invoiceEmailAddress}
                            label="Accounts Payable Email Address"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoiceEmailAddress", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.invoicePhoneNumber}
                            label="Accounts Payable Phone Number"
                            validation={phoneNumber()}
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoicePhoneNumber", value, valid)}
                        />
                    </>
                )}
                <MuiDateField
                    value={values.expiryDate}
                    label="Expiry Date"
                    showErrors={showErrors}
                    validation={[muiTodayOrfutureDateValidator]}
                    onChange={(value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid)}
                />
                <MarkdownEditor
                    value={values.organisationNote}
                    label="Organisation Note"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("organisationNote", value, valid)}
                />
                {!values.corporateOrganisationData?.openCourse && (
                    <>
                        <h2>Partner Details</h2>
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.partner}
                            label="Partner"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.partner", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.accountManager}
                            label="Account Manager"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.accountManager", value, valid)}
                        />
                    </>
                )}
            </Form>
        );
    }

    public submit = () => {
        if (!this.valid()) {
            this.setState({ showErrors: true });
            toast.warning("Organisation cannot be created as some mandatory fields have not been completed");
            return;
        }

        this.submitting = true;
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };
}

function mapStateToProps(state: AppState & AppCommonState) {
    const businessDevelopmentManagers = state.businessDevelopmentManagers;
    const model = organisationSelector(state) as OrganisationEditModel;

    if (!model.relatedOrganisationId) {
        model.relatedOrganisationId = NoneOrganisationOption;
    }

    return {
        model,
        open: state.router.pathname.endsWith("/edit"),
        basePath: corporateOrConstructionBasePathSelector(state),
        businessDevelopmentManagers,
        organisationOptions: getCorporateRelatedOrganisationOptions(state, model.id)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchLoadBusinessDevelopmentManagers: () => dispatch(loadBusinessDevelopmentManagers()),
        dispatchSave: (organisation: OrganisationEditModel, basePath: string) => dispatch(saveOrganisation(organisation, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        organisationOptions: propsFromState.organisationOptions,
        model: propsFromState.model,
        open: propsFromState.open,
        businessDevelopmentManagers: propsFromState.businessDevelopmentManagers,
        save: (organisation: OrganisationEditModel) => propsFromDispatch.dispatchSave(organisation, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`),
        loadBusinessDevelopmentManagers: () => propsFromDispatch.dispatchLoadBusinessDevelopmentManagers(),
    };
}

export const ConstructionEdit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConstructionEditModal);
