/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form, Label } from "semantic-ui-react";
import {
    EditComponent,
    EditProps as SharedEditProps,
    SaveDispatchProps,
    FormState
} from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import { phoneNumber } from "@common/validation";
import {
    OrganisationEditModel,
    AppState,
    BusinessDevelopmentManager,
    CompanyTypeEnum,
    NoneOrganisationOption,
} from "../model";
import { corporateOrConstructionBasePathSelector, organisationSelector } from "../selectors";
import { loadBusinessDevelopmentManagers, saveOrganisation } from "../actions";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { CorporateEditModal } from "./CorporateEditModal";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { optionsFromObject } from "@common/crud/common/optionsMappers";
import { CustomerStatus } from "@common/crud/alaskaNudgeTask/model";
import { isBusinessDriverAdmin, isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { AppCommonState } from "@common/appCommonState";
import { toast } from "@common/toasts";
import { OrganisationOption } from "../../eventInstance/model";
import { getCorporateRelatedOrganisationOptions } from "../utils/organisationsHelper";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { ExtendedEmailInput } from "@common/components/ExtendedEmailInput";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";
import { OrganisationApi } from "../organisationApi";
import { debounce } from "lodash";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";

export interface EditProps extends SharedEditProps<OrganisationEditModel> {
    open: boolean;
    businessDevelopmentManagers: BusinessDevelopmentManager[];
    ttcCorporateAdmin: boolean;
    businessDriverAdmin: boolean;
    organisationOptions: OrganisationOption[];
    loadBusinessDevelopmentManagers: () => void;
}

export interface DispatchProps extends SaveDispatchProps<OrganisationEditModel> {
    close: () => void;
}

interface CorporateEditState extends FormState<OrganisationEditModel> {
    validating: boolean;
}

export class CorporateEditForm extends EditComponent<OrganisationEditModel, EditProps, CorporateEditState> {
    private validateDebounced: (specificUrlIdentifier: string) => void;

    constructor(props: EditProps & SaveDispatchProps<OrganisationEditModel>) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onBdmChange = this.onBdmChange.bind(this);
        this.onToggleCompanyType = this.onToggleCompanyType.bind(this);
        this.updateSpecificUrlIdentifier = this.updateSpecificUrlIdentifier.bind(this);
        this.validateSpecificUrlIdentifier = this.validateSpecificUrlIdentifier.bind(this);
        this.validateDebounced = debounce(this.validateSpecificUrlIdentifier, 1000);

        this.setState({ validating: false });
    }

    public submitting = false;

    public componentDidMount() {
        this.props.loadBusinessDevelopmentManagers();
    }

    public shouldComponentUpdate() {
        return !this.submitting;
    }

    public onBdmChange(bdm: string) {
        this.updateNestedProperty("corporateOrganisationData.bdmId", bdm, true);
        const matchingBdm = this.props.businessDevelopmentManagers.find(m => m.id === bdm);
        this.updateNestedProperty("corporateOrganisationData.bdmName", matchingBdm?.name, true);
        this.updateNestedProperty("corporateOrganisationData.bdmEmail", matchingBdm?.email, true);
    }

    public onToggleCompanyType(_: any, value: string) {
        if (!isNullOrUndefinedOrEmptyString(value)) {
            const enumValue = parseInt(value, 10);
            this.updateProperty("companyType", [enumValue], true);
        }
    }

    private updateRelatedOrganisation = (value: string, valid: boolean) => {
        this.updateProperty("relatedOrganisationId", value, valid);
    };

    private validateSpecificUrlIdentifier = async (value: string) => {
        if (value) {
            this.setState({ validating: true }, async () => {
                const api = new OrganisationApi();
                const valid = await api.validateSpecificUrlIdentifier(value, this.state.values.id);
                this.updateProperty("specificUrlIdentifier", value, valid);
                this.setState({ validating: false });

                if (!valid) {
                    toast.warning("Specific URL Identifier is invalid, please choose another.");
                }
            });
        }
    };

    private updateSpecificUrlIdentifier = (value: string) => {
        this.updateProperty("specificUrlIdentifier", value, !value);
        this.validateDebounced(value);
    };

    public render() {
        const { values, showErrors } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <h2>Account Details</h2>
                <ExtendedTextInput
                    value={values.name}
                    label="Name"
                    required
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("name", value, valid)}
                />
                <ExtendedDropdown
                    value={values.corporateOrganisationData?.bdmId}
                    label="BDM"
                    showErrors={showErrors}
                    options={(values.corporateOrganisationData?.bdmId &&
                        !this.props.businessDevelopmentManagers.some(bdm => bdm.id === values.corporateOrganisationData.bdmId)
                        ? [...this.props.businessDevelopmentManagers,
                            {
                                id: values.corporateOrganisationData.bdmId,
                                name: values.corporateOrganisationData.bdmName,
                                email: values.corporateOrganisationData.bdmEmail
                            }
                        ] : this.props.businessDevelopmentManagers).map(bdm => ({ text: `${bdm.name} [${bdm.email}]`, value: bdm.id }))}
                    onChange={this.onBdmChange}
                    dynamicOptions
                    search
                />
                <ExtendedDropdownNumber
                    value={values.customerStatus}
                    label="Customer Status"
                    required
                    showErrors={showErrors}
                    options={optionsFromObject(CustomerStatus)}
                    onChange={(value, valid) => this.updateProperty("customerStatus", value, valid)}
                />
                <div className="field-wrapper">
                    <div className="field">
                        <label>Company</label>
                    </div>
                </div>
                <Form.Field>
                    <RadioGroup
                        value={values.companyType?.values().next().value}
                        onChange={this.onToggleCompanyType}>
                        {this.props.ttcCorporateAdmin && (<FormControlLabel control={<Radio color={"primary"} />} label="Is TTC Company"
                            checked={values.companyType?.some(ct => ct === CompanyTypeEnum.TTC)} value={CompanyTypeEnum.TTC} />)}
                        {this.props.businessDriverAdmin && (<FormControlLabel control={<Radio color={"primary"} />} label="Is Business Driver Company"
                            checked={values.companyType?.some(ct => ct === CompanyTypeEnum.BusinessDriver)} value={CompanyTypeEnum.BusinessDriver} />)}
                        {this.props.ttcCorporateAdmin && (<FormControlLabel control={<Radio color={"primary"} />} label="Is TTC Commercial Services Company"
                            checked={values.companyType?.some(ct => ct === CompanyTypeEnum.LicenceBureau)} value={CompanyTypeEnum.LicenceBureau} />)}
                    </RadioGroup>
                </Form.Field>
                {(showErrors && (!values.companyType || values.companyType.length === 0)) &&
                    <Label basic color="red" pointing={"above"}>Company Type is required</Label>}
                <ExtendedDropdown
                    value={values.relatedOrganisationId}
                    label="Related Organisation"
                    showErrors={showErrors}
                    options={this.props.organisationOptions}
                    onChange={this.updateRelatedOrganisation}
                    dynamicOptions
                    search
                />
                {values.corporateOrganisationData.hasCorporateOrganisationSpecificCpcBookingUrl && (
                    <ExtendedTextInput
                        value={values.specificUrlIdentifier}
                        label="Specific URL Identifier"
                        showErrors={showErrors}
                        onChange={this.updateSpecificUrlIdentifier}
                        disabled={this.state.validating}
                    />
                )}
                {!values.corporateOrganisationData?.openCourse && (
                    <>
                        <AddressLookup
                            required
                            showErrors={showErrors}
                            address={values.corporateOrganisationData?.address}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.address", value, valid)}
                        />
                        <ExtendedEmailInput
                            value={values.corporateOrganisationData?.emailAddress}
                            label="Email Address"
                            required
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.emailAddress", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.phoneNumber}
                            label="Phone Number"
                            validation={phoneNumber()}
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.phoneNumber", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.website}
                            label="Website"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.website", value, valid)}
                        />
                    </>
                )}
                <MuiDateField
                    value={values.expiryDate}
                    label="Expiry Date"
                    showErrors={showErrors}
                    validation={[muiTodayOrfutureDateValidator]}
                    onChange={(value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid)}
                />
                <MarkdownEditor
                    value={values.organisationNote}
                    label="Organisation Note"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("organisationNote", value, valid)}
                />
                <Input.Textarea
                    value={values.corporateOrganisationData.termsAndConditionsInfo}
                    label={"Terms and Conditions info"}
                    validation={[validators.validLength({ max: 500 })]}
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.termsAndConditionsInfo", value, valid)}
                />
                {!values.corporateOrganisationData?.openCourse && (
                    <>
                        <h2>Partner Details</h2>
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.partner}
                            label="Partner"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.partner", value, valid)}
                        />
                        <ExtendedTextInput
                            value={values.corporateOrganisationData?.accountManager}
                            label="Account Manager"
                            showErrors={showErrors}
                            onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.accountManager", value, valid)}
                        />
                    </>
                )}
            </Form>
        );
    }

    public submit = () => {
        if (!this.valid() || this.state.validating && !this.state.values.companyType || this.state.values.companyType.length === 0) {
            this.setState({ showErrors: true });
            toast.warning("Organisation cannot be created as some mandatory fields have not been completed");
            return;
        }

        this.submitting = true;
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };
}

function mapStateToProps(state: AppState & AppCommonState) {
    const businessDevelopmentManagers = state.businessDevelopmentManagers;
    const model = organisationSelector(state) as OrganisationEditModel;
    const ttcCorporateAdmin = isTtcCorporateAdmin(state);
    const businessDriverAdmin = isBusinessDriverAdmin(state);

    if (!model.relatedOrganisationId) {
        model.relatedOrganisationId = NoneOrganisationOption;
    }

    return {
        model,
        open: state.router.pathname.endsWith("/edit"),
        basePath: corporateOrConstructionBasePathSelector(state),
        businessDevelopmentManagers,
        ttcCorporateAdmin,
        businessDriverAdmin,
        organisationOptions: getCorporateRelatedOrganisationOptions(state, model.id)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchLoadBusinessDevelopmentManagers: () => dispatch(loadBusinessDevelopmentManagers()),
        dispatchSave: (organisation: OrganisationEditModel, basePath: string) => dispatch(saveOrganisation(organisation, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        organisationOptions: propsFromState.organisationOptions,
        model: propsFromState.model,
        open: propsFromState.open,
        businessDevelopmentManagers: propsFromState.businessDevelopmentManagers,
        ttcCorporateAdmin: propsFromState.ttcCorporateAdmin,
        businessDriverAdmin: propsFromState.businessDriverAdmin,
        save: (organisation: OrganisationEditModel) => propsFromDispatch.dispatchSave(organisation, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`),
        loadBusinessDevelopmentManagers: () => propsFromDispatch.dispatchLoadBusinessDevelopmentManagers(),
    };
}

export const CorporateEdit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CorporateEditModal);
