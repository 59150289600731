import * as React from "react";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";

interface DropdownWithoutOnChangeOnRenderProps {
    value: boolean | number | string | (boolean | number | string)[];
    label: string;
    options: DropdownItemProps[];
    onChange: (value: boolean | number | string | (boolean | number | string)[], valid: boolean) => void;
};

export const DropdownWithoutOnChangeOnRender: React.FC<DropdownWithoutOnChangeOnRenderProps> = ({ value, label, options, onChange }) => {
    const onDropdownChange = React.useCallback((_: any, data: DropdownProps) => onChange(data.value, true), [onChange]);

    return (
        <div className="field-wrapper">
            <div className="field simple-ui-like">
                <label>{label}</label>
                <Dropdown
                    value={value}
                    options={options}
                    onChange={onDropdownChange}
                    className="fluid selection"
                />
            </div>
        </div>
    );
};
