import * as React from "react";
import { AlertEmailsSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const AlertEmails: ConfigurationAreaComponent<AlertEmailsSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="alertEmails"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "MemberEmails", label: "IQA Team Member Emails (comma-delimited list)" },
                { name: "TrainerPanelChangesEmails", label: "Trainer Panel Changes Emails (comma-delimited list)" }
            ]}
        />
    );
};
