import { Trainer, TrainerApi } from "@common/crud/trainer";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { trainersSelector } from "@common/crud/trainer/selectors";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, DropdownItemProps, Form, Grid, Table, TableBody, TableCell, TableHeader, TableHeaderCell } from "semantic-ui-react";
import { EventInstance } from "@common/crud/eventInstance";
import { DigitalPlanningFilter } from "./DigitalPlanningBase";
import { EventTypeCategory } from "@common/crud/attendee/model";

export interface FiltersProps {
    disabled: boolean;
    trainerId: string;
    trainerOrder: string[];
    onFilterChange: (filters: DigitalPlanningFilter) => void;
    savedDigitallyPlannedEvents: EventInstance[];
    month: moment.Moment;
    eventTypeCategory: EventTypeCategory;
    originalMonth: moment.Moment;
    currentTrainer: Trainer;
}

export const Filters: React.FC<FiltersProps> =
    ({ disabled, onFilterChange, trainerId, trainerOrder, savedDigitallyPlannedEvents, month, eventTypeCategory, originalMonth, currentTrainer }) => {
        const [selectedTrainer, setSelectedTrainer] = React.useState(null);
        const [averageDaysWorked, setAverageDaysWorked] = React.useState(0);
        const [orderedTrainers, setOrderedTrainers] = React.useState([]);
        const [nextTrainerId, setNextTrainerId] = React.useState(null);
        const [previousTrainerId, setPreviousTrainerId] = React.useState(null);
        const [trainerOptions, setTrainerOptions] = React.useState<DropdownItemProps[]>([]);

        const trainers = useSelector(trainersSelector);
        const config = useSelector(appSettingsSelector);
        const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;

        const trainerHasSavedDigitallyPlannedSessions = React.useCallback((id: string) => {
            return savedDigitallyPlannedEvents.find(e => e.trainerId === id) !== undefined;
        },[savedDigitallyPlannedEvents]);

        const trainerText = React.useCallback((trainer: Trainer, hasAvailablityThisMonth: boolean = true) =>
            `${trainer.fullName} - ${hasAvailablityThisMonth? trainer.unassignedAvailableSessionsCount:"0"}
         session${trainer.unassignedAvailableSessionsCount === 1 && hasAvailablityThisMonth? "" : "s"}
    available ${trainerHasSavedDigitallyPlannedSessions(trainer.id) ? "✅" : ""}`,[trainerHasSavedDigitallyPlannedSessions]);

        React.useEffect(() => {
            function sortByPositionAscending(a: Trainer, b: Trainer) {
                const aPos = trainerOrder.indexOf(a.id);
                const bPos = trainerOrder.indexOf(b.id);
                return aPos - bPos;
            }
            setOrderedTrainers(trainers.sort(sortByPositionAscending));
        }, [trainerOrder, trainers]);

        React.useEffect(() => {

            setTrainerOptions(orderedTrainers.map(t =>
                ({
                    text: trainerText(t),
                    value: t.id,
                    icon: `${t.unverifiedTrainerAttributes?.length > 0 ? "exclamation triangle" : ""}`
                })));
        }, [orderedTrainers, savedDigitallyPlannedEvents, selectedTrainer, trainerText]);

        const onTrainerIdChange = React.useCallback((_: any, { value }) => {
            if (ddrs && originalMonth?.isValid()) {
                onFilterChange({ trainerId: value, month: originalMonth });
            } else {
                onFilterChange({ trainerId: value, month });
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ddrs, onFilterChange, originalMonth]);

        React.useEffect(() => {
            setSelectedTrainer(orderedTrainers.find(t => t.id === trainerId));
        }, [trainerId, orderedTrainers]);

        React.useEffect(() => {
            async function loadAverageDaysWorked() {
                const trainerApi = new TrainerApi();
                const result = await trainerApi.loadAverageDaysWorked(trainerId);
                setAverageDaysWorked(result);
            }
            if (trainerId) {
                loadAverageDaysWorked();
            }
        }, [trainerId]);

        React.useEffect(() => {
            const selectedTrainerIndex = trainerOptions.findIndex(t => t.value === trainerId);
            if (selectedTrainerIndex > 0) {
                setPreviousTrainerId(trainerOptions[selectedTrainerIndex -1].value);
            } else {
                setPreviousTrainerId(null);
            }
            if (selectedTrainerIndex < trainerOptions.length - 1) {
                setNextTrainerId(trainerOptions[selectedTrainerIndex + 1].value);
            } else {
                setNextTrainerId(null);
            }
        }, [trainerId, trainerOptions]);

        const goToNextTrainer = React.useCallback(() => onTrainerIdChange(null, { value: nextTrainerId }), [nextTrainerId, onTrainerIdChange]);
        const goToPreviousTrainer = React.useCallback(() => onTrainerIdChange(null, { value: previousTrainerId }), [onTrainerIdChange, previousTrainerId]);

        const trainerProgress = () => {
            if (!orderedTrainers.length && (selectedTrainer === null || selectedTrainer === undefined )) {
                return <></>;
            }
            return (
                <span>
                    Trainer {orderedTrainers?.findIndex(t => t.id === selectedTrainer?.id) + 1} out of {orderedTrainers?.length} trainers
                </span>
            );
        };

        const preferredDaysAndSessionsText =
            `${selectedTrainer?.preferredNoDaysPerMonth ?? "Not Set"}/${selectedTrainer?.preferredNoSessionsPerDay ?? "Not Set"}`;

        return (
            <Grid.Column width={16}>
                <Grid>
                    <Grid.Row verticalAlign="top">
                        <Grid.Column width={7}>
                            <Grid>
                                <Grid.Row className="no-padding-bottom">
                                    <Grid.Column width={16}>
                                        <Form.Dropdown
                                            selection
                                            floating
                                            disabled={disabled}
                                            placeholder={trainerId && currentTrainer ? trainerText(currentTrainer, !!selectedTrainer) : "Trainer"}
                                            value={trainerId}
                                            options={trainerOptions}
                                            onChange={onTrainerIdChange}
                                            search
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="no-padding-top">
                                    <Grid.Column width={16} className="flex-row space-between center-aligned">
                                        <Button disabled={previousTrainerId === null} onClick={goToPreviousTrainer}>Previous</Button>
                                        {trainerProgress()}
                                        <Button disabled={nextTrainerId === null} onClick={goToNextTrainer}>Next</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        {selectedTrainer &&
                            <Grid.Column width={9}>
                                <Table collapsing className="digital-planning-trainer-stats-table">
                                    <TableHeader>
                                        <Table.Row>
                                            <TableHeaderCell>Non-Compliant Attributes</TableHeaderCell>
                                            <TableHeaderCell>Average Days</TableHeaderCell>
                                            {config.featureSettings.trainerPromise && <TableHeaderCell>Preferred Days/Sessions</TableHeaderCell>}
                                        </Table.Row>
                                    </TableHeader>
                                    <TableBody>
                                        <Table.Row>
                                            <TableCell>{selectedTrainer?.unverifiedTrainerAttributes?.length ?? 0}</TableCell>
                                            <TableCell>{trainerId ? averageDaysWorked : 0}</TableCell>
                                            {config.featureSettings.trainerPromise && <TableCell>{preferredDaysAndSessionsText}</TableCell>}
                                        </Table.Row>
                                    </TableBody>
                                </Table>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        );
    };
