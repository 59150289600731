import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ScheduledPaymentPlanDetailModel, ScheduledPaymentStatus } from "@common/payments/model";

export interface ScheduledPaymentDetailsRowProps {
    plan: ScheduledPaymentPlanDetailModel;
}

export const ScheduledPaymentDetailsRow: React.SFC<ScheduledPaymentDetailsRowProps> = (props) => {
    const getStatus = (status: ScheduledPaymentStatus) => {
        switch (status) {
            case ScheduledPaymentStatus.FinishedWithError:
                return "Failed";
            case ScheduledPaymentStatus.FinishedWithSuccess:
                return "Paid";
            case ScheduledPaymentStatus.Cancelled:
                return "Cancelled";
            default:
                return "";
        }
    };

    return (
        <Grid.Row className="row scheduled-payment-details-row">
            <Grid.Column width={4}>
                £{(props.plan?.amountInPence / 100).toFixed(2) ?? 0.00}
            </Grid.Column>
            <Grid.Column width={8}>
                {props.plan?.chargeDate.format("DD/MM/YYYY") ?? ""}
            </Grid.Column>
            <Grid.Column width={4} className="scheduled-label">
                {getStatus(props.plan.status)}
            </Grid.Column>
        </Grid.Row>
    );
};
