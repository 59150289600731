/* eslint-disable max-lines */
import axios from "axios";
import moment from "moment";
import {
    EventTypeListModel,
    EventTypeDetailModel,
    EventTypeCreateEditModel,
    EventType,
    IntroMessagesResponse,
    ClassroomEventTypeDetails,
    DigitalEventTypeDetails,
    EventTypePart,
    TrainerFee,
    ValueWithEffectiveDate,
    DdrsAllCountriesFeeConfig,
    RebookingFees,
    WorkflowTypeEnum,
    CourseFeesEventTypeDetails,
    EventTypeFile,
    EventTypeFileResponse,
    ProductCategoryEnum,
} from "./model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { EventTypeCategory } from "../attendee/model";
import { CountryEnum, SchemeDeliveryTypeEnum } from "../organisation/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";

export class EventTypeApi {
    private readonly apiUrl = "/api/eventtype";

    public async getAll(eventType?: EventTypeCategory): Promise<EventTypeListModel[]> {
        const url = eventType ? `${this.apiUrl}?eventType=${eventType}` : this.apiUrl;
        const response = await axios.get(url);
        const data = response.data as EventTypeListModel[];
        return data.map(this.parseModel);
    }

    public async getAllByWorkflowTypes(options: { name?: string; internalId?: string;
        shownInBookingApp?: boolean; workflowTypes?: WorkflowTypeEnum[]; productCategories?: ProductCategoryEnum[]; }): Promise<EventTypeListModel[]> {
        const response = await axios.post(`${this.apiUrl}/byWorkflowTypes`, options);
        const data = response.data as EventTypeListModel[];
        return data.map(this.parseModel);
    }

    public async getDorsDigitalEvents(): Promise<EventTypeListModel[]> {
        const response = await axios.get(`${this.apiUrl}/digitalEvents`);
        const data = response.data as EventTypeListModel[];
        return data.map(this.parseModel);
    }

    public async getDdrsDigitalEvents(): Promise<EventTypeListModel[]> {
        const response = await axios.get(`${this.apiUrl}/ddrsDigitalEvents`);
        const data = response.data as EventTypeListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<EventTypeDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as EventTypeDetailModel;
        return this.parseModel(model);
    }

    public async getIntroMessage(id: number, eventTypeId: string): Promise<IntroMessagesResponse> {
        const response = await axios.get(`${this.apiUrl}/${id}/intro-message/${eventTypeId}`);
        return response.data as IntroMessagesResponse;
    }

    public async save(eventType: EventTypeCreateEditModel): Promise<EventTypeDetailModel> {
        const parsedEventType = this.parseObject(eventType);
        const response = await axios.put(
            `${this.apiUrl}/${eventType.id}`,
            parsedEventType
        );
        const model = response.data as EventTypeDetailModel;
        return this.parseModel(model);
    }

    public async create(eventType: EventTypeCreateEditModel): Promise<EventTypeDetailModel> {
        const parsedEventType = this.parseObject(eventType);
        const response = await axios.post(
            this.apiUrl,
            parsedEventType
        );

        const model = response.data as EventTypeDetailModel;
        return this.parseModel(model);
    }

    public async getBookingAppTexts(eventTypeId: string): Promise<Dictionary<string>> {
        const response = await axios.get(`${this.apiUrl}/${eventTypeId}/get-amendable-booking-app-texts`);
        return response.data as Dictionary<string>;
    }

    public async getTypeCarFlag(eventTypeId: string): Promise<boolean> {
        const response = await axios.get(`${this.apiUrl}/${eventTypeId}/get-show-car-type-flag`);
        return response.data as boolean;
    }

    public async getDeliveryTypesForEventType(eventTypeId: string): Promise<SchemeDeliveryTypeEnum> {
        const response = await axios.get(`${this.apiUrl}/deliveryTypes?eventTypeId=${eventTypeId}`);
        const model = response.data as SchemeDeliveryTypeEnum;
        return model;
    }

    public async getFutureEventInstanceDateForEventType(eventTypeId: string, date: moment.Moment): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/${eventTypeId}/futureEventInstanceDate?date=${date.toISOString()}`);
        return response.data as string;
    }

    public async getFiles(eventTypeId: string, showExpired: boolean = true): Promise<EventTypeFile[]> {
        const response = await axios.get<EventTypeFile[]>(`${this.apiUrl}/${eventTypeId}/files?showExpired=${showExpired}`);
        const parsedResponse = this.parseFiles(response.data);
        return parsedResponse;
    }

    public async createFile(eventTypeId: string, file: EventTypeFile, onlyActive: boolean): Promise<EventTypeFileResponse> {
        const formData = new FormData();
        formData.append("id", file.id);
        formData.append("documentName", file.documentName);
        formData.append("documentDescription", file.documentDescription);
        formData.append("uploadedFile", file.uploadedFile);
        formData.append("startDate", file.startDate.toISOString());
        formData.append("endDate", file.endDate?.toISOString());

        const response = await axios.post<EventTypeFileResponse>(`${this.apiUrl}/${eventTypeId}/files?onlyActive=${onlyActive}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        const parsedResponse = this.parseFilesResponse(response.data);
        return parsedResponse;
    }

    public async editFile(eventTypeId: string, file: EventTypeFile, onlyActive: boolean): Promise<EventTypeFileResponse> {
        const formData = new FormData();
        formData.append("id", file.id);
        formData.append("documentName", file.documentName);
        formData.append("documentDescription", file.documentDescription);
        formData.append("uploadedFile", file.uploadedFile);
        formData.append("startDate", file.startDate.toISOString());
        formData.append("endDate", file.endDate?.toISOString());

        const response = await axios.put<EventTypeFileResponse>(`${this.apiUrl}/${eventTypeId}/files?onlyActive=${onlyActive}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        const parsedResponse = this.parseFilesResponse(response.data);
        return parsedResponse;
    }

    public async getDurationForEventType(eventTypeId: string, eventDate: moment.Moment, deliveryType: DeliveryTypeEnum): Promise<moment.Duration> {
        const response = await axios.get(`${this.apiUrl}/${eventTypeId}/duration?eventDate=
            ${eventDate?.format(DateFormat.ISODateFormat)}&deliveryType=${deliveryType}`);
        return response.data as moment.Duration;
    }

    public parseFilesResponse(model: EventTypeFileResponse): EventTypeFileResponse {
        return {
            files: this.parseFiles(model.files),
            error: model.error
        };
    }

    public parseFiles(models: EventTypeFile[]): EventTypeFile[] {
        return models.map((model: EventTypeFile) => ({
            ...model,
            startDate: moment(model.startDate),
            endDate: model.endDate && moment(model.endDate),
        }));
    }

    public parseModel(model: EventTypeDetailModel): EventTypeDetailModel {
        return {
            ...model,
            ddrsCourseFees: EventTypeApi.parseDDRSFeesObject(model.ddrsCourseFees),
            classroomEventTypeDetails: EventTypeApi.parseClassroomDetailsObject(model.classroomEventTypeDetails),
            digitalEventTypeDetails: EventTypeApi.parseDigitalDetailsObject(model.digitalEventTypeDetails),
            courseFeesEventTypeDetails: EventTypeApi.parseCourseFeesDetailsObject(model.courseFeesEventTypeDetails),
            trainerUpliftFees: model.trainerUpliftFees && model.trainerUpliftFees.map(fee => ({ ...fee, effectiveDate: moment(fee.effectiveDate) })),
            flexiPayFees: EventTypeApi.parseFeesWithEffectiveDate(model.flexiPayFees),
            rebookingFees: EventTypeApi.parseRebookingFeesWithEffectiveDate(model.rebookingFees),
            defaultRebookingFee: EventTypeApi.parseDefaultRebookingFeeWithEffectiveDate(model.defaultRebookingFee),
            monitorFees: EventTypeApi.parseFeesWithEffectiveDate(model.monitorFees),
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated),
                pendingDate: h.pendingDate && moment(h.pendingDate),
                completionDate: h.completionDate && moment(h.completionDate)
            })),
            expiryDate: model.expiryDate && moment(model.expiryDate)
        };
    }

    private static parseRebookingFeesWithEffectiveDate(rebookingFees: RebookingFees[]): RebookingFees[] {
        return rebookingFees && rebookingFees
            .map(rebookingFee => ({ ...rebookingFee, effectiveDate: moment(rebookingFee.effectiveDate) }))
            .sort((a, b) => a.effectiveDate.diff(b.effectiveDate));
    }

    private static parseDefaultRebookingFeeWithEffectiveDate(defaultRebookingFee: RebookingFees): RebookingFees {
        return defaultRebookingFee && { ...defaultRebookingFee, effectiveDate: moment(defaultRebookingFee.effectiveDate) };
    }

    public static parseFeesWithEffectiveDate(fees: any[] ) {
        return fees && fees.map(fee => ({ ...fee, effectiveDate: moment(fee.effectiveDate) })).sort((a, b) => a.effectiveDate.diff(b.effectiveDate));
    }

    public static parseValueWithEffectiveDateDuration(model: ValueWithEffectiveDate<moment.Duration>) {
        return {
            ...model,
            effectiveDate: model.effectiveDate.toISOString(),
            value: model.value && moment.utc(model.value.asMilliseconds()).format(DateFormat.TimeWithSeconds)
        };
    }

    public static parseValueWithEffectiveDate(model: ValueWithEffectiveDate<any>) {
        return {
            ...model,
            effectiveDate: model.effectiveDate.toISOString(),
        };
    }

    public static parseValueWithEffectiveDateDurationObject(model: ValueWithEffectiveDate<moment.Duration>): ValueWithEffectiveDate<moment.Duration> {
        return {
            ...model,
            effectiveDate: moment(model.effectiveDate),
            value: moment.duration(model.value)
        };
    }

    public static parseValueWithEffectiveDateObject(model: ValueWithEffectiveDate<any>): ValueWithEffectiveDate<any> {
        return {
            ...model,
            effectiveDate: moment(model.effectiveDate)
        };
    }

    public static parseDDRSFeesObject(model: DdrsAllCountriesFeeConfig): DdrsAllCountriesFeeConfig {

        return model && !EventTypeApi.isEmpty(model)? Object.fromEntries(Object.keys(EventTypeApi.parseCountryEnum(model)).map(key =>
            [key, model[CountryEnum[key]].map(this.parseValueWithEffectiveDateObject)]
        )):null;
    }

    public static parseDDRSFees(model: DdrsAllCountriesFeeConfig): DdrsAllCountriesFeeConfig {

        return model && !EventTypeApi.isEmpty(model)? Object.fromEntries(Object.keys(model).map(key =>
            [key,model[key].map(this.parseValueWithEffectiveDate)])):null;
    }

    public static isEmpty(obj: any) {
        return Object.keys(obj).length === 0;
    }

    public static parseClassroomDetailsObject(model: ClassroomEventTypeDetails): ClassroomEventTypeDetails {
        return {
            ...model,
            trainerFees: model.trainerFees && model.trainerFees.map(EventTypeApi.parseTrainerFee),
            practicalTrainerFees: model.practicalTrainerFees && model.practicalTrainerFees.map(EventTypeApi.parseTrainerFee),
            suggestedStartTimesForSessions: EventTypeApi.parseDictionaryToDuration(model.suggestedStartTimesForSessions),
            suggestedStartTimesForTheorySessions: EventTypeApi.parseDictionaryToDuration(model.suggestedStartTimesForTheorySessions),
            suggestedStartTimesForPracticalSessions: EventTypeApi.parseDictionaryToDuration(model.suggestedStartTimesForPracticalSessions),
            classroomEventTypeParts: model.classroomEventTypeParts &&
                EventTypeApi.parseDictionaryEventTypePartsToDuration(model.classroomEventTypeParts),
            eventDurations: model.eventDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            educationDurations: model.educationDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            practicalEventDurations: model.practicalEventDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            theoryEventDurations: model.theoryEventDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            maxNumberOfAttendeesPerTrainerWithEffectiveDate: model.maxNumberOfAttendeesPerTrainerWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            maxNumberOfAttendeesWithEffectiveDate: model.maxNumberOfAttendeesWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            classroomRegistrationDurations: model.classroomRegistrationDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            classroomOtherTrainerFees: EventTypeApi.parseFeesWithEffectiveDate(model.classroomOtherTrainerFees),
            classroomBreakEvenPoints: model.classroomBreakEvenPoints?.map(this.parseValueWithEffectiveDateObject)
        };
    }

    public static parseCountryEnum(model: DdrsAllCountriesFeeConfig): DdrsAllCountriesFeeConfig {
        return Object.fromEntries(Object.keys(model).map(key => [CountryEnum[key as keyof CountryEnum], model[key]]));
    }

    public static parseDigitalDetailsObject(model: DigitalEventTypeDetails): DigitalEventTypeDetails {
        return {
            ...model,
            digitalEventSuggestedStartTimesForSessions: EventTypeApi.parseDictionaryToDuration(model.digitalEventSuggestedStartTimesForSessions),
            digitalEventTypeParts: model.digitalEventTypeParts &&
                EventTypeApi.parseDictionaryEventTypePartsToDuration(model.digitalEventTypeParts),
            digitalTrainerFees: model.digitalTrainerFees?.map(EventTypeApi.parseTrainerFee),
            eventDurations: model.eventDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            educationDurations: model.educationDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            maxNumberOfDigitalAttendeesWithDuration: model.maxNumberOfDigitalAttendeesWithDuration?.map(this.parseValueWithEffectiveDateObject),
            maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate:
                model.maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            digitalRegistrationDurations: model.digitalRegistrationDurations?.map(this.parseValueWithEffectiveDateDurationObject),
            digitalOtherTrainerFees: EventTypeApi.parseFeesWithEffectiveDate(model.digitalOtherTrainerFees),
            digitalBreakEvenPoints: model.digitalBreakEvenPoints?.map(this.parseValueWithEffectiveDateObject)
        };
    }

    public static parseClassroomDetails(model: ClassroomEventTypeDetails): any {
        return {
            ...model,
            trainerFees: model.trainerFees && model.trainerFees.map(EventTypeApi.parseTrainerFee),
            practicalTrainerFees: model.practicalTrainerFees && model.practicalTrainerFees.map(EventTypeApi.parseTrainerFee),
            suggestedStartTimesForSessions: model.suggestedStartTimesForSessions &&
                EventTypeApi.parseDictionary(model.suggestedStartTimesForSessions),
            suggestedStartTimesForTheorySessions: model.suggestedStartTimesForTheorySessions &&
                EventTypeApi.parseDictionary(model.suggestedStartTimesForTheorySessions),
            suggestedStartTimesForPracticalSessions: model.suggestedStartTimesForPracticalSessions &&
                EventTypeApi.parseDictionary(model.suggestedStartTimesForPracticalSessions),
            classroomEventTypeParts: model.classroomEventTypeParts &&
                EventTypeApi.parseDictionaryOfEventTypeParts(model.classroomEventTypeParts),
            eventDurations: model.eventDurations?.map(this.parseValueWithEffectiveDateDuration),
            educationDurations: model.educationDurations?.map(this.parseValueWithEffectiveDateDuration),
            theoryEventDurations: model.theoryEventDurations?.map(this.parseValueWithEffectiveDateDuration),
            practicalEventDurations: model.practicalEventDurations?.map(this.parseValueWithEffectiveDateDuration),
            classroomRegistrationDurations: model?.classroomRegistrationDurations?.map(this.parseValueWithEffectiveDateDuration),
        };
    }

    public static parseCourseFeesDetailsObject(model: CourseFeesEventTypeDetails): any {
        return {
            ...model,
            digitalEIFeeWithEffectiveDate: model.digitalEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            classroomEIFeeWithEffectiveDate: model.classroomEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            closedDigitalEIFeeWithEffectiveDate: model.closedDigitalEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            closedClassroomEIFeeWithEffectiveDate: model.closedClassroomEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            openDigitalEIFeeWithEffectiveDate: model.openDigitalEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            openClassroomEIFeeWithEffectiveDate: model.openClassroomEIFeeWithEffectiveDate?.map(this.parseValueWithEffectiveDateObject),
            cpcUploadFee: model.cpcUploadFee?.map(this.parseValueWithEffectiveDateObject),
        };
    }

    public static parseTrainerFee(trainerFee: TrainerFee) {
        return {
            ...trainerFee,
            effectiveDate: moment.utc(trainerFee.effectiveDate)
        };
    }

    public static parseDigitalDetails(model: DigitalEventTypeDetails): any {
        return {
            ...model,
            trainerFees: model.digitalTrainerFees && model.digitalTrainerFees.map(EventTypeApi.parseTrainerFee),
            digitalEventSuggestedStartTimesForSessions: model.digitalEventSuggestedStartTimesForSessions &&
                EventTypeApi.parseDictionary(model.digitalEventSuggestedStartTimesForSessions),
            digitalEventTypeParts: model.digitalEventTypeParts &&
                EventTypeApi.parseDictionaryOfEventTypeParts(model.digitalEventTypeParts),
            eventDurations: model.eventDurations?.map(this.parseValueWithEffectiveDateDuration),
            educationDurations: model.educationDurations?.map(this.parseValueWithEffectiveDateDuration),
            maxNumberOfDigitalAttendeesWithDuration: model.maxNumberOfDigitalAttendeesWithDuration?.map(this.parseValueWithEffectiveDate),
            maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate:
                model.maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate?.map(this.parseValueWithEffectiveDate),
            digitalRegistrationDurations: model.digitalRegistrationDurations?.map(this.parseValueWithEffectiveDateDuration)
        };
    }

    public parseObject(eventType: EventType): any {
        return {
            ...eventType,
            classroomEventTypeDetails: EventTypeApi.parseClassroomDetails(eventType.classroomEventTypeDetails),
            digitalEventTypeDetails: EventTypeApi.parseDigitalDetails(eventType.digitalEventTypeDetails),
            courseFeesEventTypeDetails: EventTypeApi.parseCourseFeesDetailsObject(eventType.courseFeesEventTypeDetails),
            ddrsCourseFees: EventTypeApi.parseDDRSFees(eventType.ddrsCourseFees),
            amendableBookingAppTexts: eventType.showCarType ? eventType.amendableBookingAppTexts : {}
        };
    }

    public static parseDictionary(dict: Dictionary<moment.Duration[]>) {

        if (isNullOrUndefined(dict)) {
            return {};
        }

        const parsed: Dictionary<string[]> = {};
        Object.keys(dict).forEach(key => {
            if (dict[key]) {
                parsed[key] = dict[key].map(time => moment.utc(time.asMilliseconds()).format(DateFormat.TimeWithSeconds));
            }
        });
        return parsed;
    }

    public static parseDictionaryOfEventTypeParts(dict: Dictionary<EventTypePart>) {

        if (isNullOrUndefined(dict)) {
            return {};
        }

        const parsed: Dictionary<{ suggestedStartTime: string; eventDuration: string; registrationDuration: string }> = {};
        Object.keys(dict).forEach(key => {
            parsed[key] = {
                suggestedStartTime: dict[key].suggestedStartTime
                    ? moment.utc(dict[key].suggestedStartTime.asMilliseconds()).format(DateFormat.TimeWithSeconds)
                    : undefined,
                eventDuration: dict[key].suggestedStartTime
                    ? moment.utc(dict[key].eventDuration.asMilliseconds()).format(DateFormat.TimeWithSeconds)
                    : undefined,
                registrationDuration: dict[key].suggestedStartTime
                    ? moment.utc(dict[key].registrationDuration.asMilliseconds()).format(DateFormat.TimeWithSeconds)
                    : undefined
            };
        });
        return parsed;
    }

    public static parseDictionaryToDuration(dict: Dictionary<moment.Duration[]>) {

        if (isNullOrUndefined(dict)) {
            return {};
        }

        const parsed: Dictionary<moment.Duration[]> = {};
        Object.keys(dict).forEach(key => {
            parsed[key] = dict[key].map(time => moment.duration(time));
        });
        return parsed;
    }

    public static parseDictionaryEventTypePartsToDuration(dict: Dictionary<EventTypePart>) {

        if (isNullOrUndefined(dict)) {
            return {};
        }

        const parsed: Dictionary<EventTypePart> = {};
        Object.keys(dict).forEach(key => {
            parsed[key] = {
                eventDuration: moment.duration(dict[key].eventDuration),
                suggestedStartTime: moment.duration(dict[key].suggestedStartTime),
                registrationDuration: moment.duration(dict[key].registrationDuration)
            };
        });

        return parsed;
    }
}
