import { EventType } from "@common/crud/eventType";
import { GetEventMaxDigitalAttendees } from "@common/crud/eventType/model";
import { Trainer } from "@common/crud/trainer";
import { errorMessageDisplay } from "@common/crud/trainer/components/ErrorMessageDisplay";
import moment from "moment";
import * as React from "react";
import { Link } from "redux-little-router";
import { Grid, Table, Icon, Popup } from "semantic-ui-react";
import { EventInstance } from "../..";

interface PlannedEventsOverviewTableRowProps {
    name: string;
    eventTypes: EventType[];
    events: EventInstance[];
    trainer?: Trainer;
    month: moment.Moment;
}

interface SchemeEventsSummary {
    id?: string;
    schemeAbbreviation?: string;
    courses: number;
    seats: number;
}

const createSchemeEventsSummaryForEventType = (eventType: EventType, events: EventInstance[]): SchemeEventsSummary => {
    let coursesOfEventType = 0;
    let seats = 0;
    events.forEach(e => {
        if (e.eventTypeId === eventType.id) {
            coursesOfEventType++;
            seats += GetEventMaxDigitalAttendees(e.startDate, eventType);
        }
    });

    return {
        id: eventType.id,
        schemeAbbreviation: eventType.abbreviation,
        courses: coursesOfEventType,
        seats
    };
};

const getTotalCoursesAndSeats = (eventTypes: EventType[], events: EventInstance[]): SchemeEventsSummary => {
    let coursesCount = 0;
    let seatCount = 0;
    eventTypes.forEach(e => {
        const summary = createSchemeEventsSummaryForEventType(e, events);
        coursesCount += summary.courses;
        seatCount += summary.seats;
    });
    return {
        courses: coursesCount,
        seats: seatCount
    };
};

const trainerCanRunEventType = (eventType: EventType, trainer?: Trainer) => {
    if (trainer) {
        return trainer.trainerAttributes?.map(a => a.attributeDefinitionId).includes(eventType.digitalEventTypeDetails.digitalTrainerAttributeId);
    }
    return true;
};

export const PlannedEventsOverviewTableRow: React.FC<PlannedEventsOverviewTableRowProps> =
({ eventTypes, events, name, trainer, month }) => {
    const schemeEventsSummaries: SchemeEventsSummary[] = eventTypes.map(e => createSchemeEventsSummaryForEventType(e, events));

    const totals = getTotalCoursesAndSeats(eventTypes, events);
    const trainerLink = `/police-and-court-event-management/eventInstances/digital-planning?month=${month?.toISOString()}&trainerId=${trainer?.id}`;

    return (
        <Table.Row>
            <Table.Cell>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10} textAlign="left">
                            { trainer ? <Link href={trainerLink}>{name}</Link> : name}
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="left">
                            {
                                trainer?.unverifiedTrainerAttributes?.length > 0 &&
                                <Popup
                                    content={errorMessageDisplay(trainer.unverifiedTrainerAttributes)}
                                    trigger={<Icon name={"exclamation triangle"} />}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
            {schemeEventsSummaries.map(s => (<Table.Cell key={s.id}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} textAlign="center">
                            <p>{trainerCanRunEventType(eventTypes.find(e => e.id === s.id), trainer) ? s.courses : "N/A"}</p>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="center">
                            <p>{trainerCanRunEventType(eventTypes.find(e => e.id === s.id), trainer) ? s.seats  : "N/A"}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>))}
            <Table.Cell>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} textAlign="center">
                            <p>{totals.courses}</p>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="center">
                            <p>{totals.seats}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </Table.Row>
    );
};
