import moment from "moment";

import * as actions from "./actiontypes";
import { OrganisationAction } from "./actions";
import { BusinessDevelopmentManager, Organisation } from "./model";
import { ChangeEventType } from "@common/history/model";
import { completePendingNoteInHistory } from "../alaskaNudgeTask/pendingNoteReducerCommon";
import { COMPLETED_PENDING_NOTE } from "@common/notes/actiontypes";

export function organisations(state: Organisation[] = [], action: OrganisationAction): Organisation[] {
    switch (action.type) {
        case actions.LOAD_ORGANISATIONS_SUCCESS:
            return detailAwareOrganisations(state, action);
        case actions.SAVE_ORGANISATION_SUCCESS:
        case actions.SAVE_SURVEY_LINK_SUCCESS:
        case actions.SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS:
        case actions.SAVE_REMINDER_TYPE_SUCCESS:
        case actions.SAVE_ORGANISATION_SETTING_SUCCESS:
        case actions.LOAD_ORGANISATION_DETAIL_SUCCESS:
        case actions.ADD_NOTE_SUCCESS:
        case actions.UPDATE_NOTE_SUCCESS:
        case actions.SAVE_BOOKING_INFORMATION_LINK_SUCCESS:
        case actions.UPDATE_ORGANISATION_FAVOURITE_STATUS:
        case COMPLETED_PENDING_NOTE:
            return state.map(c => organisation(c, action));
        case actions.CREATE_ORGANISATION_SUCCESS:
            return [...state, detailUpdated(action.payload)];
        case actions.DELETE_ORGANISATION_SUCCESS:
            return [...state.filter(c => c.id !== action.payload)];
        default:
            return state;
    }
}

// Due to loading data with resolves on routes, we're not sure whether list load or details load will be run first.
// During testing sometimes details were shown, sometime not - race condition on list and details calls.
// Hence need for old status aware reducer.
function detailAwareOrganisations(state: Organisation[], action: OrganisationAction): Organisation[] {
    switch (action.type) {
        case actions.LOAD_ORGANISATIONS_SUCCESS:
            const actionState = action.payload.map(c => ({ ...c, listUpdated: moment() }));
            const newDetailsAwareState = actionState.map(newElement => {
                const oldElementFound = state?.find(oldElement => oldElement.id === newElement.id);
                return oldElementFound?.id
                    ? {
                        ...newElement,
                        reminderType: oldElementFound.reminderType,
                        classroomTrainerFees: oldElementFound.classroomTrainerFees,
                        practicalTrainerFees: oldElementFound.practicalTrainerFees,
                        history: oldElementFound.history,
                        customerStatus: oldElementFound.customerStatus,
                        companyType: oldElementFound.companyType,
                        favourite: oldElementFound.favourite,
                        relatedOrganisationSpecificUrlIdentifier: oldElementFound.relatedOrganisationSpecificUrlIdentifier,
                    } : newElement;
            });
            return newDetailsAwareState;
        default:
            return state;
    }
}

function organisation(state: Organisation, action: OrganisationAction): Organisation {
    switch (action.type) {
        case actions.SAVE_ORGANISATION_SUCCESS:
        case actions.SAVE_SURVEY_LINK_SUCCESS:
        case actions.SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS:
        case actions.SAVE_REMINDER_TYPE_SUCCESS:
        case actions.SAVE_ORGANISATION_SETTING_SUCCESS:
        case actions.LOAD_ORGANISATION_DETAIL_SUCCESS:
        case actions.SAVE_BOOKING_INFORMATION_LINK_SUCCESS:
            if (action.payload.id === state.id) {
                return detailUpdated(action.payload, state);
            }
            return state;
        case actions.ADD_NOTE_SUCCESS:
            if (action.payload.correlationId === state.id) {
                return {
                    listUpdated: moment(),
                    ...state,
                    detailUpdated: moment(),
                    history: [
                        ...state.history,
                        Object.assign({
                            id: action.payload.id,
                            organisationId: action.payload.correlationId,
                            eventType: ChangeEventType.Note,
                            eventMessage: action.payload.note,
                            dateCreated: moment(),
                            nudgeTaskId: action.payload.nudgeTaskId,
                            recordModifiedByEmail: action.payload.addedBy,
                            isPendingNote: !!action.payload.pendingDate,
                            isPendingNoteCompleted: false,
                            pendingDate: action.payload.pendingDate,
                            noteAttachmentFileName: action.payload.noteAttachmentFileName,
                            noteAttachmentBlobPath: action.payload.noteAttachmentBlobPath
                        })
                    ]
                };
            }
            return state;
        case actions.UPDATE_NOTE_SUCCESS: {
            if (action.payload.correlationId === state.id) {
                return {
                    listUpdated: moment(),
                    ...state,
                    detailUpdated: moment(),
                    history: state.history.map(history => {
                        if (history.id === action.payload.noteId) {
                            history.eventMessage = action.payload.noteText;

                            if (action.payload.removeAttachment) {
                                history.noteAttachmentFileName = null;
                                history.noteAttachmentBlobPath = null;
                            }
                        }
                        return history;
                    })
                };
            }
            return state;
        }
        case COMPLETED_PENDING_NOTE: {
            if (state.history && Array.isArray(state.history) &&
                state.history.some(note => note.nudgeTaskId === action.payload.nudgeTaskId)) {
                const history = completePendingNoteInHistory(state.history, action.payload.nudgeTaskId);
                if (history !== state.history) {
                    return {
                        listUpdated: moment(),
                        ...state,
                        detailUpdated: moment(),
                        history
                    };
                }
            }

            return state;
        }
        case actions.UPDATE_ORGANISATION_FAVOURITE_STATUS:
            if (action.payload.id === state.id) {
                return favouriteStatusUpdated(action.payload.favourite, state);
            }
            return state;
        default:
            return state;
    }
}

function detailUpdated(value: Organisation, state: Organisation = {} as Organisation): Organisation {
    return { listUpdated: moment(), ...state, ...value, detailUpdated: moment() };
}

function favouriteStatusUpdated(value: boolean, state: Organisation = {} as Organisation): Organisation {
    return { listUpdated: moment(), ...state, favourite: value };
}

export function businessDevelopmentManagers(state: BusinessDevelopmentManager[] = [], action: OrganisationAction): BusinessDevelopmentManager[] {
    switch (action.type) {
        case actions.LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function relatedOrganisationsOptions(state: Organisation[] = [], action: OrganisationAction): Organisation[] {
    switch (action.type) {
        case actions.LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
