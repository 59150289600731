/* eslint-disable max-lines */
import { Payload } from "@neworbit/redux-helpers";
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { push } from "redux-little-router";
import { toast } from "@common/toasts";
import { createReservationSuccess } from "../seat/actions";
import { Booking, BookingCancellationReason, MissingBookingProps } from "../bookings/models";
import { BookingApi } from "../bookings/bookingApi";
import { ApplicationState } from "../applicationState";
import { SeatApi } from "../seat/seatApi";
import { bookingBasePathSelector, bookingIdSelector } from "./selectors";
import { SkipAndBookModel } from "./model";
import { ExternalLinks } from "@common/ExternalLinks";
import * as actions from "./actiontypes";
import {
    CreateStartPaymentProcessRequestResponseStatus,
    StartPaymentProcessMultiPartModel
} from "@common/payments/model";
import { loadPayment } from "@common/payments/actions";
import { EventInstance } from "@common/crud/eventInstance";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import { ManualPaymentModel } from "@booking/global/models";

export type LandingAction =
    ({ type: actions.LOAD_BOOKING_SUCCESS } & Payload<Booking>) |
    ({ type: actions.LOAD_EXTERNAL_LINKS_SUCCESS } & Payload<ExternalLinks>) |
    ({ type: actions.RESEND_CONFIRMATION_MAIL_SUCCESS } & Payload<string>) |
    ({ type: typeof actions.UPDATE_INCLUDE_FULLY_BOOKED_COURSES } & Payload<boolean>) |
    ({ type: actions.SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX } & Payload<number>) |
    ({ type: actions.SET_DELIVERY_TYPE_FILTER_DISTANCE } & Payload<number>) |
    ({ type: actions.SET_DELIVERY_TYPE_FILTER_VENUE_ID } & Payload<string>) |
    ({ type: actions.SET_DELIVERY_TYPE_BOOKING_ID } & Payload<string>);

export const loadBookingSuccess = (payload: Booking): LandingAction => ({
    payload,
    type: actions.LOAD_BOOKING_SUCCESS
});

export const loadExternalLinksSuccess = (payload: ExternalLinks): LandingAction => ({
    payload,
    type: actions.LOAD_EXTERNAL_LINKS_SUCCESS
});

export const resendConfirmationMailSuccess = (id: string) => ({
    payload: id,
    type: actions.RESEND_CONFIRMATION_MAIL_SUCCESS
});

export const includeFullyBookedCoursesSuccess = (includeFullyBookedCourses: boolean) => ({
    payload: includeFullyBookedCourses,
    type: actions.UPDATE_INCLUDE_FULLY_BOOKED_COURSES
});

export const setDeliveryTypeFilterColumnActiveIndex = (activeIndex: number) => ({
    payload: activeIndex,
    type: actions.SET_DELIVERY_TYPE_FILTER_COLUMN_ACTIVE_INDEX
});

export const setDeliveryTypeFilterDistance = (distance: number) => ({
    payload: distance,
    type: actions.SET_DELIVERY_TYPE_FILTER_DISTANCE
});

export const setDeliveryTypeFilterVenueId = (venueId: string) => ({
    payload: venueId,
    type: actions.SET_DELIVERY_TYPE_FILTER_VENUE_ID
});

export const setDeliveryTypeBookingId = (bookingId: string) => ({
    payload: bookingId,
    type: actions.SET_DELIVERY_TYPE_BOOKING_ID
});

export function loadBooking() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = getState().router.params.bookingId;

        const api = new BookingApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "loadBooking",
            api.getBooking(false, false)
        );
        dispatch(loadBookingSuccess(result));
    };
}

// Function called at the start of a booking journey that initialises the booking session
export function loadBookingForNewSession(checkForDuplicate: boolean) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = getState().router.params.bookingId;

        const api = new BookingApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            "loadBooking",
            api.getBooking(checkForDuplicate, true)
        );
        dispatch(loadBookingSuccess(result));
    };
}

export function updateMissingFieldsAndGoToPayment(
    missingProps: MissingBookingProps,
    manualPaymentModel: ManualPaymentModel,
    eventInstance: EventInstance,
    nextPage: string,
    isClientAdvisor: boolean,
    startPaymentProcessMultiPartModels: StartPaymentProcessMultiPartModel[],
    bookingId?: string,
    path?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const api = new BookingApi(bookingId);

        const includeFullyBookedCourses = getState().includeFullyBookedCourses;
        missingProps = { ...missingProps, includeFullyBookedCourses };
        const result = await loadAndTrack(dispatch, "updateMissingFields", api.updateMissingFields(missingProps));

        const { isExpired, seats, booking } = result;
        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=2`));
            return;
        }

        dispatch(loadBookingSuccess(booking));
        dispatch(createReservationSuccess(seats));

        if (nextPage === PaymentPageNames[PaymentPageNamesEnum.ReviewPayment] || nextPage === PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]) {
            if (eventInstance.priceInPence === 0) {
                const skipAndBookModel: SkipAndBookModel = {
                    bookingId,
                    seats
                };
                const url = isClientAdvisor
                    ? `/bookings/${booking.id}/ca-redirect?outstandingBalancePayment=${false}`
                    : `/landing/booking-confirmation?finishDateTime=${new Date().toISOString()}`;

                // It needs to be casted as any because there's problem with types
                // when dispatching redux-thunk actions
                dispatch(skipPaymentAndBook(skipAndBookModel, url) as any);
                return;
            } else {

                const manualPaymentModelProps = manualPaymentModel ? {
                    partialPaymentModel: {
                        amount: manualPaymentModel.paymentValue,
                        isWaivingRebookingFee: manualPaymentModel.waivePayment
                    },
                    scheduledPaymentPlanModel: manualPaymentModel.scheduledPaymentPlan,
                } : {};

                const startPaymentProcessModel = {
                    bookingId: booking.id,
                    eventInstanceId: booking.reservedEventInstanceId,
                    objectRelatedTo: booking.seatReservationId,
                    ...manualPaymentModelProps,
                    includeFullyBookedCourses,
                    flexiPayPurchased: missingProps.flexiPayPurchased,
                    flexiPayFee: missingProps.flexiPayPurchased ? missingProps.flexiPayFee : 0,
                    startPaymentProcessMultiPartModels
                };
                dispatch(loadPayment(startPaymentProcessModel, null) as any);
            }
        }

        // We now redirect to whatever EI and Seat come back
        if (isClientAdvisor) {
            dispatch(push(`/bookings/${booking.id}/${booking.reservedEventInstanceId}/${nextPage}`));
        } else {
            dispatch(push(`/landing/${booking.reservedEventInstanceId}/${nextPage}`));
        }

    };
}

export function updateMissingFieldsAndGoToAutoPayment(
    missingProps: MissingBookingProps,
    manualPaymentModel: ManualPaymentModel,
    genesysToken: string,
    clearAutoPaymentLoading: () => void,
    flexiPayPurchased: boolean,
    flexiPayFee: number,
    bookingId?: string,
    path?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {

        const api = new BookingApi(bookingId);
        const includeFullyBookedCourses = getState().includeFullyBookedCourses;
        const model = { ...missingProps, includeFullyBookedCourses, flexiPayPurchased, flexiPayFee };
        const { isExpired, booking } = await loadAndTrack(dispatch, "updateMissingFields", api.updateMissingFields(model));

        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=2`));
            return;
        }

        const paymentApi = new PaymentApi(bookingId);
        const startPaymentProcessMultiPartModels = booking.eventInstanceBookings.map(
            (b, index) => ({ eventInstanceId: b.eventInstanceId, relatedObjectId: b.seatId, dayPart: index+1 }));

        const autoPaymentResult = await paymentApi.startAutoPaymentProcessFromBookingApp({
            genesysToken,
            paymentProcessModel: {
                bookingId: booking.id,
                eventInstanceId: booking.reservedEventInstanceId,
                objectRelatedTo: booking.seatReservationId,
                includeFullyBookedCourses,
                partialPaymentModel: {
                    amount: manualPaymentModel.paymentValue,
                    isWaivingRebookingFee: manualPaymentModel.waivePayment
                },
                scheduledPaymentPlanModel: manualPaymentModel.scheduledPaymentPlan,
                flexiPayPurchased,
                flexiPayFee: flexiPayPurchased ? flexiPayFee : 0,
                startPaymentProcessMultiPartModels
            }
        });

        clearAutoPaymentLoading();

        if (autoPaymentResult.paymentProcessStatus !== CreateStartPaymentProcessRequestResponseStatus.Ok) {
            toast.error("Failed to start auto payment process");
            return;
        }

        if (autoPaymentResult.invalidCallInfo) {
            toast.error("Failed to retrieve Genesys call information");
            return;
        }

        if (!autoPaymentResult.ivrSessionCreated) {
            toast.error("IVR session was not created");
            return;
        }

        toast.success("Auto payment process successfully started");
        const adminAppEndpoint = getState().appSettings.internalAppSettings.adminAppEndpoint;
        const queryParams = `bookingId=${booking.id}&eventInstanceId=${booking.reservedEventInstanceId}`;
        window.location.assign(`${adminAppEndpoint}police-and-court-event-management?${queryParams}`);
    };
}

export function updateMissingFieldsAndSkipAndBook(
    missingProps: MissingBookingProps,
    model: SkipAndBookModel,
    bookingId: string,
    path: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const api = new BookingApi(bookingId);

        const includeFullyBookedCourses = getState().includeFullyBookedCourses;
        missingProps = { ...missingProps, includeFullyBookedCourses };
        const result = await loadAndTrack(dispatch, "updateMissingFields", api.updateMissingFields(missingProps));

        const { isExpired, seats, booking } = result;
        if (isExpired) {
            dispatch(push(`${path}/reservation-error?delta=2`));
            return;
        }

        dispatch(loadBookingSuccess(booking));
        dispatch(createReservationSuccess(seats));
        dispatch(skipPaymentAndBook(model, `${path}/ca-redirect`) as any);
    };
}

export function skipPaymentAndBook(skipAndBookModel: SkipAndBookModel, path: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const { bookingId, isWaivingRebookingFee, seats } = skipAndBookModel;
        const includeFullyBookedCourses = getState().includeFullyBookedCourses;
        const api = new SeatApi(bookingId);
        const apiCall = api.skipPaymentAndBook(seats, isWaivingRebookingFee, includeFullyBookedCourses);
        const result = await loadAndTrack(dispatch, "skipPaymentAndBook", apiCall);

        switch (result.status) {
            case CreateStartPaymentProcessRequestResponseStatus.Ok: {
                dispatch(createReservationSuccess([result.seat]));

                if (path) {
                    dispatch(push(path));
                }
                break;
            }

            case CreateStartPaymentProcessRequestResponseStatus.BookingSessionExpired: {
                dispatch(push("/landing"));
                break;
            }

            default: {
                dispatch(push(`/bookings/${bookingId}/dors-update-error?status=${result.status}&delta=3`));
                break;
            }
        }
    };
}

export function startRebookingProcess(rebookReason: BookingCancellationReason) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new BookingApi(bookingId);
        const result = await loadAndTrack(dispatch, "startRebookingProcess", api.startRebookingProcess(rebookReason));

        dispatch(loadBookingSuccess(result));
        const path = bookingBasePathSelector(getState());
        dispatch(push(path));
    };
}

export function cancelRebookingProcess(redirectUrl?: string) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new BookingApi(bookingId);
        const result = await loadAndTrack(dispatch, "cancelRebookingProcess", api.cancelRebookingProcess());

        dispatch(loadBookingSuccess(result));

        if (redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            dispatch(push(""));
        }
    };
}

export function resendConfirmationMail() {
    return async (dispatch: Dispatch) => {
        const api = new BookingApi();
        await loadAndTrack(dispatch, "resendConfirmationMail", api.resendConfirmationMail());
        toast.info("Confirmation email requested");
    };
}
