import * as React from "react";
import { Grid, Divider, Checkbox, CheckboxProps, Popup } from "semantic-ui-react";
import { OtherTrainerTypeCategory, OtherTrainerTypeCategoryEnum } from "../../model";

interface MonitorAssignProps {
    trainers: { id: string; name: string; otherTrainerType: boolean; otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum }[];
    monitoredTrainersIds: string[];
    setMonitoredTrainersIds: (ids: string[]) => void;
    forbiddenOtherMonitoringCandidateIds: string[];
}

export const MonitorAssign: React.FC<MonitorAssignProps> = ({
    trainers,
    monitoredTrainersIds,
    setMonitoredTrainersIds,
    forbiddenOtherMonitoringCandidateIds
}) => {

    function monitored(trainerId: string, toBeMonitored: boolean) {
        if (toBeMonitored) {
            setMonitoredTrainersIds([...monitoredTrainersIds, trainerId]);
        } else {
            setMonitoredTrainersIds([...monitoredTrainersIds.filter(t => t !== trainerId)]);
        }
    }

    function renderHeader(text: string) {
        return (<Grid.Column><ins><strong>{text}</strong></ins></Grid.Column>);
    };

    function onMonitoredChanged(trainerId: string) {
        return (_: any, { checked }: CheckboxProps) => monitored(trainerId, checked);
    }

    function trainerIsBeingMonitored(trainerId: string) {
        return monitoredTrainersIds.includes(trainerId);
    }

    const tooFewTrainersSelected = monitoredTrainersIds.length < 1;
    const otherTrainersAssigned = trainers.some(x => x.otherTrainerType);

    return (<>
        <p>Please select the trainers you wish to monitor</p>
        {tooFewTrainersSelected && <p color="red">You must select at least one trainer to be monitored</p>}
        <Divider hidden />
        <Grid columns={otherTrainersAssigned? 3 : 2} divided>
            <Grid.Row>
                {renderHeader("Trainer Name")}
                {otherTrainersAssigned && renderHeader("Other Trainer Type")}
                {renderHeader("Monitored")}
            </Grid.Row>
            {trainers.map(t => {
                const observersMissingAttributes = forbiddenOtherMonitoringCandidateIds.includes(t.id);
                return (
                    <Grid.Row key={t.id}>
                        <Grid.Column>
                            {t.name}
                        </Grid.Column>
                        {otherTrainersAssigned &&
                    <Grid.Column>
                        {t.otherTrainerType && OtherTrainerTypeCategory[t.otherTrainerTypeCategory]}
                    </Grid.Column>}
                        <Grid.Column>
                            <Popup
                                disabled={!observersMissingAttributes}
                                content={"This trainer is missing the required attribute(s) to be monitored"} trigger={<Checkbox
                                    checked={trainerIsBeingMonitored(t.id)}
                                    onChange={onMonitoredChanged(t.id)}
                                    disabled={observersMissingAttributes}
                                />} />

                        </Grid.Column>
                    </Grid.Row>);})}
        </Grid>
    </>);
};
