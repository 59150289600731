import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { NoteAction } from "@common/notes/actions";
import * as actions from "./actiontypes";
import * as models from "./model";
import { DorsBookingApi } from "./dorsBookingApi";
import { AssistanceRequired } from "./model";
import { HistoryRecord } from "@common/history/model";
import { HistoryApi } from "@common/history/historyApi";
import { UPDATE_ATTENDANCE_ID_SUCCESS, UPDATE_CONTACT_DETAILS_SUCCESS } from "@common/crud/attendee/actiontypes";
import { EditContactDetailsModel } from "@common/crud/attendee/model";
import { updateContactDetailsSuccess } from "@common/crud/attendee/actions";

const api = new DorsBookingApi();

export type DorsBookingAction =
    ({ type: actions.LOAD_DORSBOOKINGS_SUCCESS } & Payload<models.DorsBookingListModel[]>)
    | ({ type: actions.LOAD_DORSBOOKING_DETAIL_SUCCESS } & Payload<models.DorsBookingDetailModel>)
    | ({ type: actions.LOAD_HISTORY_SUCCESS } & Payload<{ id: string; historyRecords: HistoryRecord[] }>)
    | ({ type: UPDATE_ATTENDANCE_ID_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; attendanceId: number }>)
    | ({ type: UPDATE_CONTACT_DETAILS_SUCCESS } & Payload<{ correlationId: string; attendeeId: string; model: EditContactDetailsModel }>)
    | NoteAction;

export const loadDorsBookingsSuccess = (payload: models.DorsBookingListModel[]): DorsBookingAction => ({
    payload,
    type: actions.LOAD_DORSBOOKINGS_SUCCESS
});

export const getHistorySuccess = (model: { id: string; historyRecords: HistoryRecord[] }): DorsBookingAction => ({
    payload: model,
    type: actions.LOAD_HISTORY_SUCCESS
});

export function loadDorsBookings() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadDorsBookings", api.getAll());
        dispatch(loadDorsBookingsSuccess(result));
    };
}

export const loadDorsBookingDetailSuccess = (dorsBooking: models.DorsBookingDetailModel) => ({
    payload: dorsBooking,
    type: actions.LOAD_DORSBOOKING_DETAIL_SUCCESS
});

export function loadDorsBookingDetail({ dorsBookingId }: { dorsBookingId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const dorsBooking = getState().dorsBookings.filter(c => c.id === dorsBookingId)[0];

        if (dorsBooking === undefined
            || dorsBooking.detailUpdated === undefined
            || dorsBooking.detailUpdated.isBefore(dorsBooking.listUpdated)) {

            const action = loadDorsBookingDetailSuccess;

            const result = await loadAndTrack(dispatch, "loadDorsBookingDetail", api.detail(dorsBookingId));
            dispatch(action(result));
        }
    };
}

export function loadDorsBookingDetailWithAudit({ dorsBookingId }: { dorsBookingId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const dorsBooking = getState().dorsBookings.filter(c => c.id === dorsBookingId)[0];

        if (dorsBooking === undefined
            || dorsBooking.detailUpdated === undefined
            || dorsBooking.detailUpdated.isBefore(dorsBooking.listUpdated)) {

            const action = loadDorsBookingDetailSuccess;

            const result = await loadAndTrack(dispatch, "loadDorsBookingDetailWithAudit", api.detailWithAudit(dorsBookingId));
            dispatch(action(result));
        }
    };
}

export function getHistory(correlationId: string) {
    return async (dispatch: Dispatch) => {
        const historyApi = new HistoryApi(correlationId);
        const result = await loadAndTrack(dispatch, "getHistory", historyApi.getHistory());

        dispatch(getHistorySuccess({ id: correlationId, historyRecords: result }));
    };
}

export function setAssistanceRequiredForBooking(correlationId: string, assistanceRequired: AssistanceRequired) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "setAssistanceRequiredForBooking", api.setAssistanceRequired(correlationId, assistanceRequired));

        dispatch(loadDorsBookingDetailSuccess(result));
    };
}

export function updateDorsBookingContactDetails(correlationId: string, model: EditContactDetailsModel) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "updateDorsBookingContactDetails", api.updateContactDetails(correlationId, model));
        dispatch(updateContactDetailsSuccess({ correlationId, attendeeId: null, model }));
    };
}
