import { appSettingsSelector } from "@common/appSettings/selectors";
import * as React from "react";
import { CaptionProps, useNavigation } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { Button, DropdownItemProps, Form, Grid, Message } from "semantic-ui-react";
import moment from "moment";
import { TrainerAvailability, AppState } from "../model";
import { setUpdatedPreferredNoDaysPerMonthOverMonths, setUpdatedPreferredNoSessionsPerDayOverMonths } from "../../trainerPromise/actions";
import { GetDropDownItemFromDate, GetMomentFromDropDownItem } from "../../crud/eventInstance/components/digitalPlanning/GetNextTwelveMonthsDropDownOptions";
import { Input } from "@neworbit/simpleui-input";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { isValidNumericString } from "@common/validation";

const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

export const DayPickerHeader = (props: CaptionProps) => {
    const dispatch = useDispatch();
    const selTrainerAvailability = useSelector((state: AppState) => state.trainerAvailability);
    const [trainerAvailability, setTrainerAvailability] =
        React.useState<TrainerAvailability>({ ...selTrainerAvailability,
            availableDates: selTrainerAvailability.availableDates || [],
            preferredNoDaysPerMonthOverMonths: selTrainerAvailability.preferredNoDaysPerMonthOverMonths || [],
            preferredNoSessionsPerDayOverMonths: selTrainerAvailability.preferredNoSessionsPerDayOverMonths || [],
            editMode: selTrainerAvailability.editMode || false
        });
    const [preferredNoDaysPerMonth, setPreferredNoDaysPerMonth] = React.useState<number>(undefined);
    const [preferredNoSessionsPerDay, setPreferredNoSessionsPerDay] = React.useState<number>(undefined);
    const [preferredNoDaysPerMonthInvalid, setPreferredNoDaysPerMonthInvalid] = React.useState<boolean>(false);
    const [preferredNoSessionsPerDayInvalid, setPreferredNoSessionsPerDayInvalid] = React.useState<boolean>(false);

    const config = useSelector(appSettingsSelector);

    React.useEffect(() => {
        if (selTrainerAvailability) {
            setTrainerAvailability({ ...selTrainerAvailability,
                availableDates: selTrainerAvailability.availableDates || [],
                preferredNoDaysPerMonthOverMonths: selTrainerAvailability.preferredNoDaysPerMonthOverMonths || [],
                preferredNoSessionsPerDayOverMonths: selTrainerAvailability.preferredNoSessionsPerDayOverMonths || [],
                editMode: selTrainerAvailability.editMode || false
            });
            setPreferredNoDaysPerMonthInvalid(false);
            setPreferredNoSessionsPerDayInvalid(false);
        }
    }, [selTrainerAvailability]);

    const updateTrainerPromiseFields = React.useCallback((calendarDate: moment.Moment) => {
        setPreferredNoDaysPerMonth(
            trainerAvailability.preferredNoDaysPerMonthOverMonths?.find(p => p.month.isSame(calendarDate))?.preferredNoDaysPerMonth
        );
        setPreferredNoSessionsPerDay(
            trainerAvailability.preferredNoSessionsPerDayOverMonths?.find(p => p.month.isSame(calendarDate))?.preferredNoSessionsPerDay
        );
    }, [trainerAvailability.preferredNoDaysPerMonthOverMonths, trainerAvailability.preferredNoSessionsPerDayOverMonths]);

    React.useEffect(() => {
        if (trainerAvailability) {
            const calendarDate = props.displayMonth && GetMomentFromDropDownItem(GetDropDownItemFromDate(props.displayMonth));
            updateTrainerPromiseFields(calendarDate);
        }
    }, [trainerAvailability, props.displayMonth, updateTrainerPromiseFields]);

    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
    // but by using 0 as the day it will give us the last day of the prior
    // month. So passing in 1 as the month number will return the last day
    // of January, not February - therefore we do month + 1 here
    function daysInMonth(month: number, year: number) {
        return new Date(year, month + 1, 0).getDate();
    }

    const isValidNumberOfDaysInAMonth = React.useCallback((value: string, date: moment.Moment) => {
        const daysInCurrentMonth = daysInMonth(date.month(), date.year());
        const currentDate = Number.parseInt(value, 10);
        return (isValidNumericString(value) && (!value || (currentDate > 0 && currentDate <= daysInCurrentMonth)));
    }, []);

    function isValidNumberOfSessions(value: string) {
        return (isValidNumericString(value) && (!value || (Number.parseInt(value, 10) > 0 && Number.parseInt(value, 10) <= 4)));
    }

    const updateTrainerPromiseFieldsAndValidationState = React.useCallback((calendarDate: moment.Moment) => {
        const newPreferredNoDaysPerMonth =
            trainerAvailability.preferredNoDaysPerMonthOverMonths?.find(p => p.month.isSame(calendarDate))?.preferredNoDaysPerMonth;
        const newPreferredNoSessionsPerDayOverMonths =
            trainerAvailability.preferredNoSessionsPerDayOverMonths?.find(p => p.month.isSame(calendarDate))?.preferredNoSessionsPerDay;
        setPreferredNoDaysPerMonth(newPreferredNoDaysPerMonth);
        setPreferredNoSessionsPerDay(newPreferredNoSessionsPerDayOverMonths);
        setPreferredNoDaysPerMonthInvalid(!isValidNumberOfDaysInAMonth(newPreferredNoDaysPerMonth?.toString(), calendarDate));
        setPreferredNoSessionsPerDayInvalid(!isValidNumberOfSessions(newPreferredNoSessionsPerDayOverMonths?.toString()));
    }, [isValidNumberOfDaysInAMonth, trainerAvailability.preferredNoDaysPerMonthOverMonths, trainerAvailability.preferredNoSessionsPerDayOverMonths]);

    const changeMonthAndUpdateTrainerPromiseFieldsAndValidationState = React.useCallback(async (date: Date) => {
        goToMonth(date);
        updateTrainerPromiseFieldsAndValidationState(moment(date));
    }, [goToMonth, updateTrainerPromiseFieldsAndValidationState]);

    const onMonthChange = React.useCallback(async (_: any, { value }) => {
        changeMonthAndUpdateTrainerPromiseFieldsAndValidationState(GetMomentFromDropDownItem(value).toDate());
    }, [changeMonthAndUpdateTrainerPromiseFieldsAndValidationState]);

    const GetMonthsForDropDownOptions = React.useCallback((): DropdownItemProps[] => {
        const date = moment.utc([props.displayMonth.getFullYear(), props.displayMonth.getMonth(), props.displayMonth.getDate()]);
        const threeMonthsBack = date.subtract(3, "months");
        let threeMonthsBackFromDisplayMonth = threeMonthsBack.month();
        let year = date.year();
        const options: DropdownItemProps[] = [];
        while (options.length < 15) {
            if (threeMonthsBackFromDisplayMonth > 11) {
                threeMonthsBackFromDisplayMonth = 0;
                year++;
            }
            const month = `${months[threeMonthsBackFromDisplayMonth]} ${year}`;
            options.push({ text: <strong>{month}</strong>, value: month });
            threeMonthsBackFromDisplayMonth++;
        }

        return options;
    },[props.displayMonth]);

    const updateUpdatedPreferredNoDaysPerMonth = React.useCallback((value: string) => {
        const calendarDate = props.displayMonth && GetMomentFromDropDownItem(GetDropDownItemFromDate(props.displayMonth));
        const valid = isValidNumberOfDaysInAMonth(value, calendarDate);
        setPreferredNoDaysPerMonthInvalid(!valid);
        const updatedPreferredNoDaysPerMonthOverMonths = [
            ...trainerAvailability.preferredNoDaysPerMonthOverMonths.filter(p => !p.month.isSame(calendarDate)),
            {
                month: calendarDate,
                preferredNoDaysPerMonth: value ? Number.parseInt(value, 10) : undefined,
                valid
            }];
        setTrainerAvailability({
            ...trainerAvailability,
            preferredNoDaysPerMonthOverMonths: updatedPreferredNoDaysPerMonthOverMonths
        });
        dispatch(setUpdatedPreferredNoDaysPerMonthOverMonths(updatedPreferredNoDaysPerMonthOverMonths));
    }, [props.displayMonth, isValidNumberOfDaysInAMonth, trainerAvailability, dispatch]);

    const updateUpdatedPreferredNoSessionsPerDay = React.useCallback((value: string) => {
        const valid = isValidNumberOfSessions(value);
        setPreferredNoSessionsPerDayInvalid(!valid);
        const calendarDate = props.displayMonth && GetMomentFromDropDownItem(GetDropDownItemFromDate(props.displayMonth));
        const updatedPreferredNoSessionsPerDayOverMonths = [
            ...trainerAvailability.preferredNoSessionsPerDayOverMonths.filter(p => !p.month.isSame(calendarDate)),
            {
                month: calendarDate,
                preferredNoSessionsPerDay: value ? Number.parseInt(value, 10) : undefined,
                valid
            }];
        setTrainerAvailability({
            ...trainerAvailability,
            preferredNoSessionsPerDayOverMonths: updatedPreferredNoSessionsPerDayOverMonths
        });
        dispatch(setUpdatedPreferredNoSessionsPerDayOverMonths(updatedPreferredNoSessionsPerDayOverMonths));
    }, [props.displayMonth, trainerAvailability, setTrainerAvailability, dispatch]);

    const renderTrainerPromiseFields = () => {
        if (config.featureSettings.trainerPromise && !trainerAvailability.editMode)
        {
            return (
                <Grid.Row>
                    <Grid.Column computer={16} mobile={16}>
                        <div className={"rdp-trainer-promise"}>
                            <div className={"rdp-trainer-promise-field"}><b>Preferred no. days per month: </b>
                                {isNullOrUndefined(preferredNoDaysPerMonth) ? "Not Set" : preferredNoDaysPerMonth}</div>
                            <div className={"rdp-trainer-promise-field"}><b>Preferred no. sessions per day: </b>
                                {isNullOrUndefined(preferredNoSessionsPerDay) ? "Not Set" : preferredNoSessionsPerDay}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            );
        }
        else if (config.featureSettings.trainerPromise && trainerAvailability.editMode)
        {
            return (
                <Grid.Row>
                    <Grid.Column computer={16} mobile={16}>
                        <div className={"rdp-trainer-promise"}>
                            <div className={"rdp-trainer-promise-field"}>
                                <Input.Text
                                    value={isNullOrUndefined(preferredNoDaysPerMonth) ? "" : preferredNoDaysPerMonth.toString()}
                                    label="Preferred no. days per month: "
                                    onChange={updateUpdatedPreferredNoDaysPerMonth}
                                />
                                {preferredNoDaysPerMonthInvalid &&
                                    <Message><strong>Please enter a valid whole number of days in a month</strong></Message>
                                }
                            </div>
                            <div className={"rdp-trainer-promise-field"}>
                                <Input.Text
                                    value={isNullOrUndefined(preferredNoSessionsPerDay) ? "": preferredNoSessionsPerDay.toString()}
                                    label="Preferred no. sessions per day: "
                                    onChange={updateUpdatedPreferredNoSessionsPerDay}
                                />
                                {preferredNoSessionsPerDayInvalid &&
                                    <Message><strong>Please enter a valid number of sessions (between 1 and 4)</strong></Message>
                                }
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            );
        }
        else
        {
            return "";
        }
    };

    return (
        <>
            <Grid.Row>
                <Grid.Column computer={16} mobile={16}>
                    <div className={"rdp-caption"}>
                        <Form.Dropdown
                            className="rdp-caption-label rdp-dropdown-month"
                            selection
                            floating
                            placeholder="Month"
                            value={props.displayMonth && GetDropDownItemFromDate(props.displayMonth)}
                            options={GetMonthsForDropDownOptions()}
                            onChange={onMonthChange}
                            search
                        />
                        <div className={"rdp-nav"}>
                            <Button onClick={() => changeMonthAndUpdateTrainerPromiseFieldsAndValidationState(moment().toDate())}>Current Month</Button>
                            <button name="previous-month" aria-label="Go to previous month"
                                className={"rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_previous"}
                                onClick={() => previousMonth && changeMonthAndUpdateTrainerPromiseFieldsAndValidationState(previousMonth)} type="button">
                                <svg width="16px" height="16px" viewBox="0 0 120 120" className={"rdp-nav_icon"}>
                                    <path d="M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786
                                        83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146
                                        C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576
                                        72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321
                                        15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458
                                        L69.490332,3.34314575 Z" fill="currentColor" fillRule="nonzero">
                                    </path>
                                </svg>
                            </button>
                            <button name="next-month" aria-label="Go to next month" className={"rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_next"}
                                onClick={() => nextMonth && changeMonthAndUpdateTrainerPromiseFieldsAndValidationState(nextMonth)} type="button">
                                <svg width="16px" height="16px" viewBox="0 0 120 120" className={"rdp-nav_icon"}>
                                    <path d="M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786
                                        35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146
                                        C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576
                                        46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321
                                        103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z" fill="currentColor">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            {renderTrainerPromiseFields()}
        </>
    );
};
