import * as React from "react";
import { Checkbox, Grid, Tab } from "semantic-ui-react";
import { Spinner } from "@common/global";
import "./FilesDetailsTab.scss";
import moment from "moment";
import { orderBy } from "lodash";
import { DelegateDocument } from "@common/crud/delegateDocument/model";
import { DelegateDocumentApi } from "@common/crud/delegateDocument/delegateDocumentApi";
import { constructionTrainersSelector } from "@common/crud/trainer/selectors";
import { useDispatch, useSelector } from "react-redux";
import { loadTrainers } from "@common/crud/trainer/actions";
import { ConstructionWorkflowTypeArray } from "../../model";
import { DragDropTypedTable, DragDropTypedTableRowProps } from "@common/crud/common/DragDropTypedTable";

interface DelegateDocumentsTabProps {
    eventTypeId: string;
}

export const DelegateDocumentsTab: React.FC<DelegateDocumentsTabProps> = ({ eventTypeId }) => {
    const dispatch = useDispatch();
    const trainers = useSelector(constructionTrainersSelector);

    React.useEffect(() => {
        dispatch(loadTrainers({ options: { workflows: ConstructionWorkflowTypeArray } }));
    }, [dispatch]);

    const [delegateDocuments, setDelegateDocuments] = React.useState<DelegateDocument[]>([]);
    const [showExpired, setShowExpired] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(true);

    React.useEffect(() => {
        const loadDelegateDocuments = async () => {
            const response = await new DelegateDocumentApi().getDelegateDocuments({ showExpired, eventTypes: [eventTypeId] });
            setDelegateDocuments(response);
            setRefreshing(false);
        };

        loadDelegateDocuments();
    }, [eventTypeId, showExpired]);

    const getTrainerNames = (trainerIds: string[]) => {
        return trainerIds.map(id => {
            const trainer = trainers.find(t => t.id === id);
            return trainer ? trainer.fullName : id;
        }).join(", ");
    };

    const baseColumns = (): DragDropTypedTableRowProps<DelegateDocument>[] => {
        return [
            {
                header: "Document Name",
                value: (f) => f.documentName || "",
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "Document Description",
                value: (f) => f.documentDescription || "",
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "URL",
                value: (f) => f.url ? <a href={f.url} target="_blank" rel="noreferrer" className="file-url">{f.url}</a> : "",
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "Start Date",
                value: (f) => f.startDate ? f.startDate.format("LL") : "",
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "Trainers",
                value: (f) => f.trainers ? getTrainerNames(f.trainers) : "",
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            }
        ];
    };

    const endDateColumn = (): DragDropTypedTableRowProps<DelegateDocument>[] => {
        return [
            {
                header: "End Date",
                value: (f) => f.endDate?.format("LL"),
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
        ];
    };

    const columns = (): DragDropTypedTableRowProps<DelegateDocument>[] => {
        return showExpired
            ? [
                ...baseColumns(),
                ...endDateColumn(),
            ] : [
                ...baseColumns(),
            ];
    };

    const updateDocumentPriorities = async (newOrder: DelegateDocument[]) => {
        const updatedDocuments = newOrder.map((doc, index) => ({
            ...doc,
            priority: index + 1,
        }));

        const result = await new DelegateDocumentApi().reorderDelegateDocuments(updatedDocuments, { showExpired, eventTypes: [eventTypeId] });
        setDelegateDocuments(result.documents);
    };

    const toggleShowExpired = React.useCallback(() => setShowExpired(!showExpired), [showExpired]);

    const activeDocuments = delegateDocuments.filter(f => !f.endDate || f.endDate >= moment.utc());
    const expiredDocuments = delegateDocuments.filter(f => f.endDate && f.endDate < moment.utc());

    const sortedActiveDocuments = orderBy(activeDocuments, "priority", "asc");
    const sortedExpiredDocuments = orderBy(expiredDocuments, "priority", "asc");

    const sortedDocuments = [...sortedActiveDocuments, ...sortedExpiredDocuments];

    return (
        <Tab.Pane>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h1>Delegate Documents</h1>
                        <h4>Below is a table containing links to documents that need to be provided to delegates within their reminder emails</h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row width={16}>
                    <Grid.Column width={8}>
                        <Checkbox
                            label={"Show Expired"}
                            checked={showExpired}
                            onChange={toggleShowExpired}
                            toggle
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid stackable className="full-width no-padding-right">
                    <Grid.Row>
                        <Grid.Column width={16} className="no-padding-right">
                            <Spinner active={refreshing}>
                                <DragDropTypedTable
                                    values={sortedDocuments}
                                    onSaveChanges={updateDocumentPriorities}
                                    tableClassName="file-table"
                                    emptyValuesArrayMessage={"No delegate documents added"}
                                    reorderButtonText={"Reorder Documents"}
                                >
                                    {columns()}
                                </DragDropTypedTable>
                            </Spinner>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid>
        </Tab.Pane>
    );
};
