import * as React from "react";
import { getTrainerBackgroundBasedOnAverageDaysWorked } from "@common/crud/eventInstance/helpers";
import { Table, Button, Icon } from "semantic-ui-react";
import { MultiDayTrainerAvailabilitySelectModel, TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { toast } from "react-toastify";
import { AvailabilityColumn } from "@common/crud/eventInstance/components/trainers/AvailabilityColumn";
import { GroupEventInstancesModel } from "@common/crud/eventInstance/model";

interface OwnProps {
    isDigital: boolean;
    trainer: MultiDayTrainerAvailabilitySelectModel;
    selectedTrainers: TrainerAvailabilitySelectModel[];
    limit?: { value: number; message: string };
    onSelectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel): void;
    onDeselectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel): void;
    daysInGroup?: number;
    selectedMultiDayParts?: GroupEventInstancesModel[];
    onAllocateToDayNumber: (trainerId: string, dayNumber: number) => void;
}

export const MultiDayTrainerRow: React.FC<OwnProps> = ({ isDigital, trainer, selectedTrainers,
    limit, onSelectTrainer, onDeselectTrainer,
    daysInGroup, selectedMultiDayParts , onAllocateToDayNumber }) => {

    const trainerDayAvailabilities = trainer && trainer.dayAvailabilities && Object.values(trainer.dayAvailabilities);
    const currentTrainerDetails = trainerDayAvailabilities.length > 1 ? trainerDayAvailabilities[1][0] : trainerDayAvailabilities[0][0];
    const onSelectClicked = React.useCallback(() => {
        const selectionLimitExceeded = () => {
            return limit && selectedTrainers?.length > 0 && selectedTrainers.length >= limit.value;
        };

        if (selectionLimitExceeded()) {
            toast.info(limit.message);
        } else {
            onSelectTrainer(trainer);
        }
    }, [limit, onSelectTrainer, selectedTrainers.length, trainer]);

    const onDeselectClicked = React.useCallback(() => {
        onDeselectTrainer(trainer);
    }, [onDeselectTrainer, trainer]);

    const getBooleanIcon = (value: boolean) => {
        return (<Icon name={value ? "checkmark" : "cancel"} color={value ? "green" : "red"} />);
    };

    const isSelected = () => {
        return selectedMultiDayParts?.some(sei => sei.trainerIds.some(s => s === trainer.id) );
    };

    const getSelectionButton = () => {
        if (isSelected()) {
            return (<Button
                content={"Deselect"}
                onClick={onDeselectClicked}
                color="green"
                basic={true}
                className={"selected-bold"}
            />);
        } else if (!(Object.values(trainer.dayAvailabilities).some(a => a.some(avb => avb.isBooked) ||
            Object.values(trainer.dayAvailabilities).some(p => p.some(avp => avp.isProvisionallyBooked))))) {
            return (<Button
                content={"Select All"}
                onClick={onSelectClicked}
                color="black"
                basic
            />);
        }
        return (<></>);
    };

    return (
        <>
            {currentTrainerDetails && <Table.Row key={trainer.id}>
                <Table.Cell content={trainer.fullName} />
                <Table.Cell>
                    {currentTrainerDetails.mobileNumber}
                    {currentTrainerDetails.mobileNumber && currentTrainerDetails.homeNumber && <br />}
                    {currentTrainerDetails.homeNumber}
                </Table.Cell>
                {!isDigital && <Table.Cell content={Math.round(currentTrainerDetails.distanceInMiles)} />}
                <Table.Cell width={2} content={currentTrainerDetails.allocatedCoursesInMonth} />
                <Table.Cell width={2} className={getTrainerBackgroundBasedOnAverageDaysWorked(currentTrainerDetails.averageDaysWorkedPerMonthInLastYear)}
                    content={currentTrainerDetails.averageDaysWorkedPerMonthInLastYear} />
                {!isDigital && <Table.Cell textAlign="center">{getBooleanIcon(currentTrainerDetails.venueChosen)}</Table.Cell>}
                <Table.Cell colSpan={daysInGroup}>
                    <AvailabilityColumn
                        trainer={trainer}
                        selectedMultiDayParts={selectedMultiDayParts}
                        onAllocateToDayNumber={onAllocateToDayNumber} />
                </Table.Cell>
                <Table.Cell>
                    {getSelectionButton()}
                </Table.Cell>
            </Table.Row>}
        </>
    );
};
