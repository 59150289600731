import axios from "axios";
import moment from "moment";
import * as QueryString from "query-string";

import { FavouriteCreateEditModel, FavouriteDetailModel, FavouriteCategoryEnum } from "./model";
import { BusinessLineType } from "@common/redux-helpers";

export class UserFavouritesApi {
    private readonly apiUrl = "/api/userFavourites";

    public async updateFavourites(favourite: FavouriteCreateEditModel): Promise<boolean>
    {
        const response = await axios.put<boolean>(`${this.apiUrl}/updateFavourites`, favourite);
        return response.data;
    }

    public async getFavouritesByCategory(category: FavouriteCategoryEnum, businessLineType: BusinessLineType): Promise<FavouriteDetailModel[]>
    {
        const response = await axios.get<FavouriteDetailModel[]>(`${this.apiUrl}?${QueryString.stringify({ category, businessLineType })}`);
        return response.data.map(this.parseModel);
    }

    private parseModel(model: FavouriteDetailModel): FavouriteDetailModel {
        return {
            ...model,
            favouritedDateTime: moment(model.favouritedDateTime)
        };
    }
}
