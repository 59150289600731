import * as React from "react";
import {  Divider, Grid } from "semantic-ui-react";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import { CorporateAttributesTable } from "../CorporateAttributesTable";
import { Detail } from "@neworbit/simpleui-detail";
import { Authorize } from "reauthorize";
import { TtcTrainerAdminRole } from "@common/auth/model";
import { UpdateNriLicenceNumberModal } from "../UpdateNriLicenceNumberModal";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";

export const CommercialAttributes = ({ trainer }: {trainer: TrainerDetailModel}) => {

    function GetAttributesByWorkflowAndDeliveryType(workflowFilter: (a: TrainerAttribute) => boolean) {
        const attributesForWorkflow = trainer?.trainerAttributes?.filter(workflowFilter);
        const classroomAttributes = attributesForWorkflow?.filter(a => !a.isDigital);
        const digitalAttributes = attributesForWorkflow?.filter(a => a.isDigital);
        return { classroomAttributes, digitalAttributes };
    };

    const { classroomAttributes: cpcClassroomAttributes, digitalAttributes: cpcDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isCpc);
    const { classroomAttributes: workshopClassroomAttributes, digitalAttributes: workshopDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isWorkshop);
    const { classroomAttributes: onRoadClassroomAttributes, digitalAttributes: onRoadDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isOnRoad);
    const { classroomAttributes: businessDriverClassroomAttributes, digitalAttributes: businessDriverDigitalAttributes } =
        GetAttributesByWorkflowAndDeliveryType(a => a.isBusinessDriver);

    return (<>
        <h3 className="row">NRI Licence</h3>
        <Detail
            label="NRI Licence Number"
            value={trainer.nriLicenceNumber}
            labelWidth={5}
            valueWidth={11}
        />
        <Authorize authorize={TtcTrainerAdminRole}>
            <Grid.Row>
                <Grid.Column width={4}>
                    <UpdateNriLicenceNumberModal trainer={trainer} />
                </Grid.Column>
            </Grid.Row>
        </Authorize>
        <Divider />
        {cpcClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="CPC Classroom"
                    attributes={cpcClassroomAttributes}
                    digital={false}
                    title="CPC Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="CPC Classroom Attributes"
                />
            </Grid.Row>
        }
        {cpcDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="CPC Digital"
                    attributes={cpcDigitalAttributes}
                    digital
                    title="CPC Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="CPC Digital Attributes"
                />
            </Grid.Row>
        }
        {workshopClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="Workshop Classroom"
                    attributes={workshopClassroomAttributes}
                    digital={false}
                    title="Workshop Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Workshop Classroom Attributes"
                />
            </Grid.Row>
        }
        {workshopDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="Workshop Digital"
                    attributes={workshopDigitalAttributes}
                    digital
                    title="Workshop Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Workshop Digital Attributes"
                />
            </Grid.Row>
        }
        {onRoadClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="On Road Classroom"
                    attributes={onRoadClassroomAttributes}
                    digital={false}
                    title="On Road Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="On Road Classroom Attributes"
                />
            </Grid.Row>
        }
        {onRoadDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="On Road Digital"
                    attributes={onRoadDigitalAttributes}
                    digital
                    title="On Road Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="On Road Digital Attributes"
                />
            </Grid.Row>
        }
        {businessDriverClassroomAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="Business Driver Classroom"
                    attributes={businessDriverClassroomAttributes}
                    digital={false}
                    title="Business Driver Classroom Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Business Driver Classroom Attributes"
                />
            </Grid.Row>
        }
        {businessDriverDigitalAttributes?.length > 0 &&
            <Grid.Row>
                <CorporateAttributesTable
                    attributeCategory="Business Driver Digital"
                    attributes={businessDriverDigitalAttributes}
                    digital
                    title="Business Driver Digital Attributes"
                    trainerId={trainer.id}
                    attributeModalHeader="Business Driver Digital Attributes"
                />
            </Grid.Row>
        }
    </>);};
