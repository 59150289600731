import { reducer as loading } from "redux-request-loading";
import { RootReducer } from "@common/redux-helpers";
import { reducer as appSettings } from "@common/appSettings";
import { reducer as currentUser } from "@common/auth";
import { reducer as language } from "@common/language";
import { reducer as stripePaymentResponse, startPaymentProcess } from "@common/payments";
import { Apps } from "@common/model";
import { ApplicationOwnState } from "./applicationState";
import { reducer as booking } from "./landing";
import { reducer as eventInstances } from "./eventInstance";
import { venues } from "./venues/reducer";
import { externalLinks, includeFullyBookedCourses, deliveryTypeFilters } from "./landing/reducer";
import { seats } from "./seat/reducer";
import { reducer as rebookEventInstance } from "./rebookEventInstance";
import { reducer as rebookVenue } from "./rebookVenue";
import { reducer as organisations } from "@common/crud/organisation";

const rootReducer: RootReducer<ApplicationOwnState> = {
    loading,
    appSettings,
    currentUser,
    language,
    booking,
    eventInstances,
    seats,
    startPaymentProcess,
    stripePaymentResponse,
    venues,
    externalLinks,
    includeFullyBookedCourses,
    rebookEventInstance,
    rebookVenue,
    deliveryTypeFilters,
    organisations,
    app: () => Apps.Booking
};

export { rootReducer };
