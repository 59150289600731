import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const PreCourseSection: QuestionSection = {
    id: "1",
    title: "Pre-Course",
    seqNum: 0,
    subSections: [
        {
            id: "1.1",
            text: "Trainer Appearance",
            seqNum: 1,
            criteria: [
                { id: "1", text: "Smart business" },
                { id: "2", text: "Visible on the screen" },
                { id: "3", text: "Wearing ID badge" },
                { id: "4", text: "Maintained eye contact with screen throughout" }
            ]
        },
        {
            id: "1.2",
            text: "Digital Classroom preparation",
            seqNum: 2,
            criteria: [
                { id: "1", text: "Digital platform open" },
                { id: "2", text: "PowerPoint ready" },
                { id: "3", text: "Desktop - no other application / programme/ documents visible" },
                { id: "4", text: "Suitable room - free from disruption/ adequately lit Meeting Started" }
            ]
        },
        {
            id: "1.3",
            text: "Resources and Visual Aids",
            seqNum: 3,
            criteria: [
                { id: "1", text: "Pen and Paper" },
                { id: "2", text: "Visual Prompts Available, digital platform, 4 circles, national limit image" },
                { id: "3", text: "Trainer Manual" }
            ]
        },
        {
            id: "1.4",
            text: "Pre-Course Registers",
            seqNum: 4,
            criteria: [
                { id: "1", text: "Registration completed in allocated time" },
                { id: "2", text: "Confidentiality of registers maintained" },
                { id: "3", text: "Confidentiality of clients maintained" }

            ]
        }],
    summaryCriterium: [{ id: "1.5", text: "", typeEnum: CriteriaEnum.text }]
};
