import * as React from "react";
import { connect } from "react-redux";
import { Confirm, Grid, Icon, Form, InputProps, TextAreaProps, Checkbox, CheckboxProps, InputOnChangeData,
    DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { AsyncDispatch } from "@common/redux-helpers";

import { selectors as organisationSelectors, OrganisationState } from "../../organisation";
import { AppSetting, AppState, ConfigurationModel } from "../model";
import { saveSetting, deleteSetting } from "../actions";
import { configurationSelector } from "../selectors";

import { InputType } from "./SettingsGrid";
import { TierThresholdLabels } from "./TrainerInvoices";
import { AppSettings } from "@common/appSettings/model";
import { AppSettingInput } from "./AppSettingInput";
import { camelCase } from "lodash";
import { InfoModal } from "./InfoModal";

interface OwnProps {
    label: string;
    setting: AppSetting;
    withInfoModal?: boolean;
    hierarchyContext: string[];
    isCreate: boolean;
    inputType: InputType;
    isArchivable?: boolean;
    dropdownNumberOptions?: DropdownItemProps[];
    done: () => void;
    currentAppSettings?: AppSettings;
    validate?: (value: string | string[] | { value: string; text: string }[]) => string[];
}

interface StateProps {
    organisationId?: string;
    configuration: ConfigurationModel;
}

interface DispatchProps {
    saveSetting: (setting: AppSetting) => void;
    deleteSetting: (id: string) => void;
}

type AppSettingEditProps = OwnProps & StateProps & DispatchProps;

interface AppSettingEditState {
    value: string;
    withInfoModal: boolean;
    showInfoModal: boolean;
    archived: boolean;
    dirty: boolean;
    deleting: boolean;
    saving: boolean;
    valid: boolean;
    errors: string[];
}

const cancelButtonProps = { content: "Cancel", className: "cancel-action" };

export class AppSettingEditInternal extends React.Component<AppSettingEditProps, AppSettingEditState> {

    public static getDerivedStateFromProps(props: AppSettingEditProps, state: AppSettingEditState): AppSettingEditState {
        if (!state.dirty) {
            const sconf = props.configuration[camelCase(props.setting.area)];
            const sdict = sconf && sconf[props.setting.name];
            if (sdict && sdict.id === props.setting.id) {
                return {
                    ...state,
                    value: sdict.value,
                };
            }

            // eslint-disable-next-line guard-for-in
            for (const section in props.configuration) {
                const oconf = props.configuration[section];
                const odict = oconf && oconf[props.setting.name];
                if (odict && odict.id === props.setting.id) {
                    return {
                        ...state,
                        value: odict.value,
                    };
                }
            }
        }

        return null;
    }

    public componentDidUpdate(prevProps: AppSettingEditProps, prevState: AppSettingEditState) {
        const { label, configuration } = this.props;
        if ((label === TierThresholdLabels.Tier2 || label === TierThresholdLabels.Tier3) &&
            (configuration !== prevProps.configuration || this.state.value !== prevState.value)) {
            this.validateNewTierThreshold(this.state.value);
        }
    }

    constructor(props: AppSettingEditProps) {
        super(props);
        this.state = {
            value: props?.setting?.value ? String(props.setting.value) : "",
            withInfoModal: !!props?.withInfoModal,
            showInfoModal: false,
            archived: props?.setting?.archived ?? false,
            dirty: false,
            deleting: false,
            saving: false,
            valid: true,
            errors: []
        };
    }

    public render() {
        const { setting, inputType, isCreate, isArchivable, label, dropdownNumberOptions } = this.props;
        const { value, saving, deleting, dirty, archived, valid, errors, withInfoModal, showInfoModal } = this.state;
        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <h4>{label}</h4>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Form onSubmit={this.save}>
                        <AppSettingInput
                            inputType={inputType}
                            value={value}
                            dropdownNumberOptions={dropdownNumberOptions}
                            onChange={this.onChange}
                            onMarkdownChange={this.onMarkdownChange}
                            onToggleChange={this.onToggleChange}
                            onNumberChange={this.onNumberChange}
                            onDropdownNumberChange={this.onDropdownNumberChange}
                        />
                        {errors && errors.length > 0 && <div className="ui red pointing above basic label">{errors.join(", ")}</div>}
                    </Form>
                </Grid.Column>
                <Grid.Column width={2}>
                    {withInfoModal && <Icon link name="info circle" onClick={this.showInfoModal} />}
                    {dirty && valid && <Icon link name="check" onClick={this.validatePassword} />}
                    {inputType !== InputType.Toggle && (isCreate
                        ? <Icon link name="cancel" onClick={this.cancel} />
                        : <Icon link name="trash" onClick={this.delete} />)}
                    {isArchivable && <Checkbox label="Archive" checked={archived} onChange={this.onArchivedChange} />}
                </Grid.Column>
                <Confirm
                    open={deleting}
                    header={`Delete ${label}`}
                    cancelButton={cancelButtonProps}
                    onConfirm={this.confirmDelete}
                    onCancel={this.cancelAction}
                />
                <Confirm
                    open={saving}
                    header={`Save ${label}`}
                    cancelButton={cancelButtonProps}
                    onConfirm={this.save}
                    content={"Are you sure that you want to save a password that contains trailing asterisk characters?"}
                    onCancel={this.cancelAction}
                />
                <InfoModal
                    postmarkTemplates={setting?.area}
                    settingName={setting?.name}
                    settingLabel={label}
                    open={showInfoModal}
                    onClose={this.closeInfoModal}
                />
            </Grid.Row>
        );
    }

    private onChange = (event: any, { value }: InputProps | TextAreaProps) => this.setState({ value, dirty: true, valid: !!value });

    private onToggleChange = (_: any, { checked }: CheckboxProps) => this.setState({ value: checked?.toString() ?? "", dirty: true });

    private onNumberChange = (_: any, { value }: InputOnChangeData) => {
        if (value !== "" && !/^(0|[1-9]\d*)$/.test(value)) {
            return;
        }

        this.setState({ value, dirty: true });
    };

    private onMarkdownChange = (value: string) => this.setState({ value, dirty: true });

    private onDropdownNumberChange = (_: any, { value }: DropdownProps) => this.setState({ value: value.toString(), dirty: true });

    private onArchivedChange = (_: any, { checked }: CheckboxProps) => this.setState(prevState => ({ ...prevState, archived: checked, dirty: true }));

    private validatePassword = () => {
        const { value } = this.state;
        if (value.endsWith("**********")) {
            this.setState({ saving: true });
        } else {
            this.save();
        }
    };

    private validateNewTierThreshold = (value: string) => {
        const { label, currentAppSettings, configuration } = this.props;

        const otherTierNumber = label === TierThresholdLabels.Tier2 ? 3 : 2;
        const otherTierThresholdFromConfig = +(configuration.trainerInvoicesSettings?.[`Tier${otherTierNumber}Threshold`]?.value);
        // currentAppSettings has default values for tier thresholds
        const otherTierThresholdDefault = currentAppSettings.trainerInvoicesSettings?.[`tier${otherTierNumber}ThresholdDefault`];
        const otherTierThreshold = otherTierThresholdFromConfig || otherTierThresholdDefault;
        const newThresholdForThisTier = +value;

        const [valid, greaterOrLessThanText] = otherTierNumber === 3 ?
            [newThresholdForThisTier < otherTierThreshold, "less than"] : [newThresholdForThisTier > otherTierThreshold, "greater than"];
        const activeOrDefaultText = otherTierThresholdFromConfig ? "currently active" : "default";
        const errors =
            valid ? [] : [`${label} must be ${greaterOrLessThanText} the ${activeOrDefaultText} Tier ${otherTierNumber} Threshold (${otherTierThreshold}).`];

        this.setState({ valid, errors });
    };

    private save = () => {
        if (this.props.validate) {
            const errors = this.props.validate(this.state.value);
            if (errors && errors.length > 0) {
                this.setState({ ...this.state, errors, saving: false });
                return;
            }
        }

        const { area, name } = this.props.setting;
        const setting = {
            area,
            name,
            archived: this.state.archived,
            hierarchy: this.props.hierarchyContext,
            value: this.state.value
        };
        this.props.saveSetting(setting);
        this.setState({ dirty: false, saving: false, errors: [] });
        this.props.done();
    };

    private cancel = () => this.props.done();

    private delete = () => this.setState({ deleting: true });

    private showInfoModal = () => this.setState({ showInfoModal: true });

    private closeInfoModal = () => this.setState({ showInfoModal: false });

    private confirmDelete = () => {
        this.props.deleteSetting(this.props.setting.id);
        this.setState({ deleting: false });
    };

    private cancelAction = () => this.setState({ deleting: false, saving: false });
}

function mapStateToProps(state: AppState & OrganisationState): StateProps {
    return {
        organisationId: organisationSelectors.routeIdSelector(state),
        configuration: configurationSelector(state),
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        saveSetting: (setting: AppSetting, organisationId: string) => dispatch(saveSetting({ setting, organisationId })),
        deleteSetting: (id: string, organisationId: string) => dispatch(deleteSetting({ id, organisationId })),
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch, ownProps: OwnProps): AppSettingEditProps {
    const { organisationId } = propsFromState;
    return {
        ...propsFromState,
        ...ownProps,
        saveSetting: (setting: AppSetting) => propsFromDispatch.saveSetting(setting, organisationId),
        deleteSetting: (id: string) => propsFromDispatch.deleteSetting(id, organisationId)
    };
}

export const AppSettingEdit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AppSettingEditInternal);
