import * as React from "react";
import { connect } from "react-redux";
import { Link, push } from "redux-little-router";
import { LoadingState } from "redux-request-loading";
import { Form, Message, Segment } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import { AppCommonState } from "@common/appCommonState";
import { hierarchySelector } from "@common/crud/configuration/selectors";
import { ConfigurationState } from "@common/crud/configuration/model";
import { AppState, PoliceUserCreateModel, PoliceUserListModel } from "../model";
import { basePathSelector } from "../selectors";
import { createUser } from "../actions";
import { OrganisationState, selectors as organisationSelectors } from "../../organisation";
import { CreateModal } from "./CreateModal";

export interface CreateProps {
    open: boolean;
    loading: boolean;
    hierarchy: string[];
    organisationId: string;
}

export interface DispatchProps extends SaveDispatchProps<PoliceUserCreateModel> {
    close: () => void;
}

interface CreateState extends FormState<PoliceUserCreateModel> {
    duplicateUser: PoliceUserListModel;
}

export class CreateForm extends FormBaseComponent<PoliceUserCreateModel, CreateProps, CreateState> {

    constructor(props: CreateProps & SaveDispatchProps<PoliceUserCreateModel>) {
        super(props);
        this.state = {
            values: {
                id: "",
                forename: "",
                surname: "",
                fullName: "",
                email: ""
            },
            valid: {},
            duplicateUser: undefined
        };
        this.updateProperty = this.updateProperty.bind(this);
        this.updateNestedProperty = this.updateNestedProperty.bind(this);
    }

    public render() {
        const organisationId = this.props.organisationId;
        const duplicateUserUrl =
            organisationId ? `/organisations/${organisationId}/users/${this.state.duplicateUser?.id}` : `/users/${this.state.duplicateUser?.id}`;
        return (
            // `error` prop is needed to make the duplicate message box visible, due to Semantic UI CSS rules
            <Form onSubmit={this.handleSubmit} error={!!this.state.duplicateUser}>

                <Input.Text
                    value={this.state.values.forename}
                    label="Forename"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("forename", value, valid)}
                />
                <Input.Text
                    value={this.state.values.surname}
                    label="Surname"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("surname", value, valid)}
                />

                <Input.Email
                    value={this.state.values.email}
                    label="Email"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("email", value, valid)}
                />
                {this.state.duplicateUser &&
                    <Message as={Segment} error>
                        A user with that email already exists:
                        <Link href={duplicateUserUrl}>
                            {this.state.duplicateUser.fullName}
                        </Link>
                    </Message>
                }
            </Form>
        );
    }

    public submit(): void {
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    }
}

function mapStateToProps(state: AppState & AppCommonState & OrganisationState & LoadingState & ConfigurationState & CreateState) {
    return {
        open: state.router.pathname.endsWith("/create"),
        basePath: basePathSelector(state),
        loading: state.loading.active,
        organisationId: organisationSelectors.routeIdSelector(state),
        hierarchy: hierarchySelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (user: PoliceUserCreateModel, organisationId: string, path: string) => dispatch(createUser({ user, organisationId, path })),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): CreateProps & DispatchProps {
    const { open, basePath, loading, organisationId, hierarchy } = propsFromState;
    const { dispatchSave, dispatchClose } = propsFromDispatch;
    return {
        open,
        save: (user: PoliceUserCreateModel) => dispatchSave(user, organisationId, basePath),
        close: () => dispatchClose(basePath),
        loading,
        hierarchy,
        organisationId
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
