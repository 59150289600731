import * as React from "react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Container, Header, Dropdown, DropdownProps, Popup, Message, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { TypedTable } from "@common/crud/common/TypedTable";
import { selectedEventInstancesSelector } from "../../selectors";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { routeTrainerIdSelector } from "@common/crud/trainer/selectors";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { loadEventTypes } from "@common/crud/eventType";
import { loading, loadSuccess } from "redux-request-loading";
import { EventInstance, EventInstanceApi } from "../..";
import { TrainerFeeCalculator } from "../../TrainerFeeCalculator";
import { TrainerAllocationModel } from "@common/crud/trainer/model";
import { loadEventInstancesSuccess } from "../../actions";
import { isLoadingSelector } from "@common/crud/common/selectors";
import { Spinner } from "@common/global";
import { toast } from "@common/toasts";
import moment from "moment";
import { muiPresentOrPastDateValidator } from "@common/validation/pastDateValidator";
import { muiLessThanAYearAgoOrFutureValidator } from "@common/validation/lessThanAYearAgoOrFutureValidator";
import { configureSubcontractingOnNewTrainer } from "../../helpers";
import { MuiDateField } from "@common/components/MuiDateField";
import { TrainerType } from "../../model";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { Organisation, OrganisationApi } from "@common/crud/organisation";

export const BulkSwapTrainerAction: React.FC<SubActionModalProps> = ({
    triggerDispatchAction,
    setActionComplete,
    selectedEventInstanceIds,
    children,
    setDisableContinue,
    searchOptions,
    allowSubcontracting }) => {

    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingSelector);
    const eventInstances = useSelector(selectedEventInstancesSelector(selectedEventInstanceIds));
    const trainerId = useSelector(routeTrainerIdSelector);
    const eventTypes = useSelector(allEventTypesSelector);
    const [trainerAvailability, setTrainerAvailability] = React.useState<Record<string, TrainerAvailabilitySelectModel[]>>({});
    const [selectedTrainers, setSelectedTrainers] = React.useState<Record<string, TrainerAllocationModel>>({});
    const [swapAgreedDates, setSwapAgreedDates] = React.useState<Record<string, { date: moment.Moment; valid: boolean }>>({});

    React.useEffect(() => {
        if (eventTypes.length === 0) {
            dispatch(loadEventTypes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTrainerChange = React.useCallback((eventInstanceId: string) => async (_: any, { value }: DropdownProps) => {
        const trainers = { ...selectedTrainers };

        const eventInstance = eventInstances.find(x => x.id === eventInstanceId);
        const eventType = eventTypes.find(x => x.id === eventInstance.eventTypeId);
        const orgApi = new OrganisationApi();
        let organisation: Organisation;

        if (eventInstance.workflowType === WorkflowTypeEnum.Dors) {
            organisation = await orgApi.detailFromForceId(eventInstance?.forceId);
        }

        const fee = TrainerFeeCalculator.getCourseFee(eventInstance, eventType, organisation, null, false);

        trainers[eventInstanceId] = { id: value as string, allocateToCourses: [], fee, sundries: 0 };
        if (allowSubcontracting) {
            configureSubcontractingOnNewTrainer(trainers[eventInstanceId], trainerId, eventInstance, moment());
        }
        setSelectedTrainers(trainers);

        setSwapAgreedDates({ ...swapAgreedDates, [eventInstanceId]: { date: moment(), valid: true } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstances, eventTypes, selectedTrainers, trainerId, swapAgreedDates]);

    const onSwapAgreedDateChange = (eventInstanceId: string) => (value: moment.Moment, valid: boolean) => {
        if (!value) {
            return;
        }

        const newSwapAgreedDates = { ...swapAgreedDates };
        newSwapAgreedDates[eventInstanceId] = { date: value, valid };
        setSwapAgreedDates(newSwapAgreedDates);

        const eventInstance = eventInstances.find(ei => ei.id === eventInstanceId);
        const newTrainer = selectedTrainers[eventInstanceId];
        if (allowSubcontracting) {
            configureSubcontractingOnNewTrainer(newTrainer, trainerId, eventInstance, value);
        }
        setSelectedTrainers({ ...selectedTrainers, [eventInstanceId]: newTrainer });
    };

    React.useEffect(() => {
        const allSwapAgreedDatesValid = Object.values(swapAgreedDates).every(e => e.valid);
        const hasSelectedTrainerForEachTheoryCourse = eventInstances.filter(ei => !ei.practicalStartTime).length === Object.keys(selectedTrainers).length;
        setDisableContinue(!allSwapAgreedDatesValid || !hasSelectedTrainerForEachTheoryCourse);
    }, [swapAgreedDates, selectedTrainers, setDisableContinue, eventInstances]);

    React.useEffect(() => {
        if (selectedEventInstanceIds.length > 0) {
            dispatch(loading("loadingSimpleAvailability"));
            const availabilityApi = new TrainerAvailabilityApi();
            availabilityApi
                .getSimpleTrainersAvailabilityForEventInstances(selectedEventInstanceIds, trainerId)
                .then(v => {
                    setTrainerAvailability(v);
                    dispatch(loadSuccess("loadingSimpleAvailability"));
                });
        }
    }, [selectedEventInstanceIds, trainerId, dispatch]);

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                const eventInstanceApi = new EventInstanceApi();
                dispatch(loading("swappingTrainers"));
                eventInstanceApi
                    .swapTrainer({ trainerId, trainers: selectedTrainers, trainerType: TrainerType.TheoryTrainer, searchOptions })
                    .then((result) => {
                        dispatch(loadEventInstancesSuccess(result));
                        dispatch(loadSuccess("swappingTrainers"));
                        toast.success("Trainers have been swapped");
                        setActionComplete(true);
                    });
            }
        },
        [triggerDispatchAction, selectedTrainers, dispatch, selectedEventInstanceIds, setActionComplete, trainerId, searchOptions]);

    const getBookedDisplayText = (trainerAvailabilitySelectModel: TrainerAvailabilitySelectModel) => {
        if (!trainerAvailabilitySelectModel) {
            return "";
        }
        if (trainerAvailabilitySelectModel.isBooked) {
            return "(Trainer already booked)";
        }
        if (trainerAvailabilitySelectModel.isProvisionallyBooked) {
            return "(Trainer provisionally booked)";
        }
        if (trainerAvailabilitySelectModel.isRCCoverOnDate) {
            return "(Trainer booked as RC cover)";
        }
        if (trainerAvailabilitySelectModel.isRCMonitorOnDate) {
            return "(Trainer booked as RC monitor)";
        }
        if (trainerAvailabilitySelectModel.isStandbyTrainerDuringEventInstance) {
            return "(Trainer booked as standby trainer)";
        }
        return "";
    };

    const subcontractingWillStart = Object.values(selectedTrainers).some(t => t.subcontractingProcess?.isActive === true);

    const dynamicCellClassName =
        (eventInstance: EventInstance) => selectedTrainers[eventInstance.id]?.subcontractingProcess?.isActive ? "ui warning message" : "";

    return (
        <Container>
            {children()}
            <Header size="small">Please choose new trainers for the course(s)</Header>
            {subcontractingWillStart &&
                <Message warning>
                    <Icon name="warning sign" />
                    The current trainer will subcontract the highlighted courses to the new trainers due to the swap date being within 14 days of the course
                    start date.
                </Message>
            }
            <Spinner active={isLoading}>
                <TypedTable
                    values={eventInstances}
                    emptyValuesArrayMessage="Select course">
                    {
                        [
                            {
                                header: "Course name",
                                value: (e) => `${e.venueName} ${e.startDate.startOf("day").add(e.startTime).format(DateFormat.ShortWithTime)}`,
                                dynamicCellClassName
                            },
                            {
                                header: "Swap with",
                                value: (e) => (
                                    trainerAvailability[e.id] && trainerAvailability[e.id]?.filter(x => !x.isBooked).length > 0 ?
                                        <Popup
                                            content={e.practicalStartTime ?
                                                "Quick swap is unavailable for courses with a practical component" :
                                                "Swap cannot be completed as subcontracting is already taking place"
                                            }
                                            on="hover"
                                            disabled={e.subcontractedTrainers.length === 0 && !e.practicalStartTime}
                                            trigger={
                                                <span>
                                                    <Dropdown
                                                        disabled={e.subcontractedTrainers.length > 0 || !!e.practicalStartTime}
                                                        onChange={onTrainerChange(e.id)}
                                                        placeholder="Select trainer"
                                                        search
                                                        options={
                                                            trainerAvailability[e.id].map((t, i) => ({
                                                                key: i,
                                                                text: `${t.fullName} ${getBookedDisplayText(t)}`,
                                                                disabled: t.isBooked || t.isProvisionallyBooked || t.isRCCoverOnDate || t.isRCMonitorOnDate ||
                                                                    t.isStandbyTrainerDuringEventInstance,
                                                                value: t.id
                                                            }))}
                                                    />
                                                </span>
                                            }
                                        /> : "No trainers available"
                                ),
                                dynamicCellClassName
                            },
                            {
                                header: "Swap agreed date",
                                value: (e) => (
                                    <MuiDateField
                                        disabled={selectedTrainers[e.id] === undefined}
                                        value={swapAgreedDates[e.id]?.date ?? moment()}
                                        onChange={onSwapAgreedDateChange(e.id)}
                                        showErrors
                                        validation={[muiPresentOrPastDateValidator, muiLessThanAYearAgoOrFutureValidator]}
                                    />
                                ),
                                dynamicCellClassName
                            }
                        ]
                    }
                </TypedTable>
            </Spinner>
        </Container >
    );
};
