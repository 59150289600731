import { createValidator } from "not-valid";

/*
Zoom Password requirements:

    1. Must be at least 8 characters
    2. Cannot be longer than 32 characters
    3. Have at least 1 letter (a, b, c...)
    4. Have at least 1 number (1, 2, 3...)
    5. Include both uppercase and lower case letters
    6. Cannot contain only one character (i.e., "111111" or "aaaaaa")
    7. Cannot contain consecutive characters (i.e., "123456" or "abcdef")
    8. Cannot contain spaces (i.e., "Go Zoom")

Available at: https://support.zoom.us/hc/en-us/articles/115005166483-Managing-your-password
*/

// No more than 3 repeating chars.
const NoRepeatingCharRegEx = /^(?!.*(\w)\1{3,}).+$/;

/*
The following regex enforces these rules:
    1. No whitespace character like space, (?!.*[\s])
    2. At least one upper case letter, (?=.*?[A-Z])
    3. At least one lower case letter, (?=.*?[a-z])
    4. At least one digit, (?=.*?[0-9])
    5. At least one special character, (?=.*?[#?!@$%^&*-/])
    6. Minimum eight and maximum 32 characters in length .{8,32}

    Most of the above is taken from:
    https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
*/
const MainPasswordRegex = /(?!.*[\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@]).*$/;

export const validZoomPassword = createValidator<string>(value => {
    if (!value) {
        return false;
    }

    if (value.length !== 10) {
        return false;
    }

    return MainPasswordRegex.test(value)
        && NoRepeatingCharRegEx.test(value);

}, "Please enter a valid Zoom password");

export const zoomPasswordValidator = () => {
    return [validZoomPassword];
};
