import * as React from "react";
import { Divider, Grid, List } from "semantic-ui-react";
import "./RegionalCoordinatorTrainers.scss";
import {
    RCTrainerType,
    TrainerNamesForDay
} from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/model";

interface Props {
    trainerNamesForDay: TrainerNamesForDay;
}

export const RegionalCoordinatorTrainers = ({ trainerNamesForDay }: Props) => {
    return (
        trainerNamesForDay &&
        <Grid columns={1} className="grid-container">
            <Grid.Row>
                <Grid.Column>
                    <h3>{trainerNamesForDay.rcTrainerType === RCTrainerType.Cover ? "Cover Trainers" : "Monitor Trainers"}</h3>
                    <div>{trainerNamesForDay.date.toDate().toDateString()}</div>
                    <Divider className={trainerNamesForDay.rcTrainerType === RCTrainerType.Cover ? "cover-divider" : "monitor-divider"} />
                    <List>
                        {
                            trainerNamesForDay.trainerNames.map((name, index) => (
                                <List.Item key={`${index}-${name}`}>
                                    {name}
                                </List.Item>
                            ))
                        }
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
