import * as React from "react";
import { useTranslation } from "react-i18next";

interface BookingStagesProps {
    activeStage: number;
    paymentRequired: boolean;
}

export const BookingStages: React.FC<BookingStagesProps> = ({ activeStage, paymentRequired }) => {
    const [t] = useTranslation("BookingStages");
    const getItemClass = (stage: number): string => {

        if (stage > activeStage) {
            return "stage-item faded-50";
        }
        if (stage === activeStage) {
            return "stage-item";
        }
        return "stage-item-complete";
    };

    const stageTwoText = paymentRequired ? "STAGE_2" : "STAGE_2_COMPLETE";

    return (
        <ol className="stage-list">
            <li className={getItemClass(1)}>{t("STAGE_1")}</li>
            <li className={getItemClass(2)}>{t(stageTwoText)}</li>
            { paymentRequired &&
                <li className={getItemClass(3)}>{t("STAGE_3")}</li>
            }
        </ol>
    );
};
