import * as React from "react";
import { Table } from "semantic-ui-react";
import { EventInstanceWithOrdersListModel } from "../model";
import { EventInstanceRow } from "./EventInstanceRow";

interface AllEventInstancesProps {
    eventInstances: EventInstanceWithOrdersListModel[];
    corporateOrganisationId?: string;
    corporateUserId?: string;
}

export const AllEventInstances: React.FC<AllEventInstancesProps> = ({
    eventInstances,
    corporateOrganisationId,
    corporateUserId
}) => {
    const [contextEventInstanceId, setContextEventInstanceId] = React.useState<string>(undefined);

    const setContexEventInstance = React.useCallback((eventInstanceId: string) => {
        if (contextEventInstanceId !== eventInstanceId) {
            setContextEventInstanceId(eventInstanceId);
        } else {
            setContextEventInstanceId(undefined);
        }
    }, [contextEventInstanceId]);

    const rows = React.useMemo(() => (eventInstances.map((eventInstance) => (
        <EventInstanceRow
            key={`eventInstance_${eventInstance.id}`}
            eventInstance={eventInstance}
            extended={eventInstance.id === contextEventInstanceId}
            corporateOrganisationId={corporateOrganisationId}
            corporateUserId={corporateUserId}
            showOrders={setContexEventInstance}
        />
    ))), [contextEventInstanceId, eventInstances, corporateOrganisationId, corporateUserId, setContexEventInstance]);

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Venue</Table.HeaderCell>
                    <Table.HeaderCell>Product</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Attendees</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    );
};
