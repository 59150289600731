import { Middleware } from "redux";
import thunk from "redux-thunk";
import { LOCATION_CHANGED, Location } from "redux-little-router";
import { configureDispatchActions } from "@neworbit/redux-helpers";
import { configureAuthMiddleware, AuthState, AuthPayload } from "reauthorize";
import { toastMiddleware } from "@common/toasts";
import { redirectToLogin } from "@common/auth";

const dispatchActions = configureDispatchActions(
    LOCATION_CHANGED,
    action => (action.payload || {}).result,
    state => state.router.params,
    (parent, state) => {
        return state.router.result.resolveParent !== false
            && (state.router.previous === undefined || (state.router.previous.pathname !== parent.route)
            );
    }
);

const authMiddleware = configureAuthMiddleware<AuthState, { payload: Location }>({
    actionType: LOCATION_CHANGED,
    getAuthPayload: action => {
        const { result } = action.payload;
        if (!result) {
            throw new Error("Invalid URL");
        }
        if (result.docTitle) {
            document.title = result.docTitle;
        }
        return result as AuthPayload;
    },
    getUser: state => state.currentUser,
    unauthenticatedAction: redirectToLogin,
    unauthorizedAction: redirectToLogin
});

export const middlewares: Middleware[] = [
    toastMiddleware as Middleware,
    authMiddleware,
    dispatchActions as Middleware,
    thunk,
];
