/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_EVENTTYPES_SUCCESS = "LOAD_EVENTTYPES_SUCCESS";
export type LOAD_EVENTTYPES_SUCCESS = typeof LOAD_EVENTTYPES_SUCCESS;

export const CREATE_EVENTTYPE_SUCCESS = "CREATE_EVENTTYPE_SUCCESS";
export type CREATE_EVENTTYPE_SUCCESS = typeof CREATE_EVENTTYPE_SUCCESS;

export const SAVE_EVENTTYPE_SUCCESS = "SAVE_EVENTTYPE_SUCCESS";
export type SAVE_EVENTTYPE_SUCCESS = typeof SAVE_EVENTTYPE_SUCCESS;

export const LOAD_EVENTTYPE_DETAIL_SUCCESS = "LOAD_EVENTTYPE_DETAIL_SUCCESS";
export type LOAD_EVENTTYPE_DETAIL_SUCCESS = typeof LOAD_EVENTTYPE_DETAIL_SUCCESS;

export const CREATE_EVENTTYPE = "CREATE_EVENTTYPE";
