import * as React from "react";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Input } from "@neworbit/simpleui-input";

export interface StartTimeInputProps {
    id: string;
    startTime: moment.Duration;
    onStartTimeChange: (id: string, startTime: moment.Duration) => void;
}

export const StartTimeInput: React.FC<StartTimeInputProps> = ({ id, startTime, onStartTimeChange }) => {
    const onChange = React.useCallback((value) => {
        const parsedTime = moment.duration(value);
        if (parsedTime.isValid()) {
            onStartTimeChange(id, parsedTime);
        }
    }, [id, onStartTimeChange]);

    return (
        <Input.Time
            value={startTime.format(DateFormat.Time, { trim: false })}
            onChange={onChange}
        />
    );
};
