import { createSelector } from "reselect";
import { Trainer, AppState } from "./model";
import { BusinessLineType } from "../organisation/model";

export const routeTrainerIdSelector = (state: AppState) => state.router.params.trainerId;

export const trainersSelector = (state: AppState) => state.trainers;

export const constructionTrainersSelector = createSelector(
    trainersSelector,
    (trainers: Trainer[]) => {
        return trainers.filter(c => c.businessLineTypes?.includes(BusinessLineType.Construction));
    }
);

export const trainerSelector = createSelector(
    trainersSelector,
    routeTrainerIdSelector,
    (trainers: Trainer[], id: string) => {
        return id ? trainers.find(c => c.id === id) || {} as any : trainers[0] || {} as any;
    }
);

export const trainerBankAccountSelector = createSelector(
    trainersSelector,
    routeTrainerIdSelector,
    (trainers: Trainer[], id: string) => {
        return id ? (trainers.find(c => c.id === id)?.bankAccount ?? {} as any) || {} as any : (trainers[0]?.bankAccount ?? {} as any) || {} as any;
    }
);

export const trainerIdSelector = (trainerId: string) => createSelector(
    trainersSelector,
    (trainers: Trainer[]) => {
        if (trainers) {
            return trainers.filter(c => c.id === trainerId)[0] || null;
        } else {
            return null;
        }
    }
);

export const basePathSelector = (state: AppState) => {
    if (state.router.pathname.indexOf("profile") !== -1) {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("profile") + "profile".length);
    } else {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("trainers") + "trainers".length);
    }
};

export const pathWithoutLastPartSelector = (state: AppState) =>
    state.router.pathname.substr(0, state.router.pathname.lastIndexOf("/")); // e.g http://localhost:33788/profile => http://localhost:33788/

export const editPathSelector = (state: AppState) => {
    const trainerId = routeTrainerIdSelector(state);
    const startIndex = state.router.pathname.indexOf(trainerId) + trainerId.length;
    const endIndex = state.router.pathname.lastIndexOf("/edit");
    return state.router.pathname.substring(startIndex, endIndex);
};

export const trainerMonitoringPlannerSelector = (state: AppState) => state.trainerMonitoringPlanner;
