import * as React from "react";
import { useSelector } from "react-redux";
import { AppCommonState } from "@common/appCommonState";
import { currentAppSelector } from "../selectors";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { Apps } from "@common/model";
import { toQueryString } from "@common/global/ToQueryString";

interface GenesysTokenProviderProps {
    onTokenReceived: (token: string) => void;
    showLogin?: boolean;
}

export const GenesysTokenProvider: React.FC<GenesysTokenProviderProps> = ({ onTokenReceived, showLogin = true }) => {

    const [iframeClassName, setIframeClassName] = React.useState("genesys-login-nodisplay");

    const { internalAppSettings, genesysSettings, settingsLoaded } = useSelector((state: AppCommonState) => {
        return {
            internalAppSettings: state.appSettings.internalAppSettings,
            genesysSettings: state.appSettings.genesysSettings,
            settingsLoaded: state.appSettings.loaded
        };
    });

    const currentApp = useSelector(currentAppSelector);

    // We hook up to the browser window messages so that the iFrame can tell us when its operation is complete
    React.useEffect(() => {
        const handler = async (event: any) => {
            try {
                const message = JSON.parse(event.data);
                if (message.token && !message.error) {
                    // Got a token passed from the iFrame, so hide it and ensure it stays hidden
                    if (timerId.current !== null) {
                        clearTimeout(timerId.current);
                        timerId.current = null;
                        setIframeClassName("genesys-login-nodisplay");
                    }

                    onTokenReceived(message.token);
                }
            } catch (error) {
                // do nothing, likely message from the iFrame that isn't JSON and so not something we are interested in
            }
        };

        window.addEventListener("message", handler);

        // Unregister from event handler when component exits
        return () => window.removeEventListener("message", handler);
    }, [onTokenReceived]);

    const timerId = React.useRef(null);

    // We initially hide the iframe window but show it after 2 seconds as this should give us time to
    // get the token. If we haven't got the token after 2 seconds it is likely that the user is
    // not logged into Genesys and will need to login in the Genesys login window in the iFrame to continue.
    React.useEffect(() => {
        timerId.current = setTimeout(() => {
            if (showLogin) {
                setIframeClassName("genesys-login");
            }
        }, 2000);

        return function cleanup() {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!settingsLoaded) {
        // Display nothing if we can't use the cli search
        return null;
    }

    // This is where Genesys will redirect back to once authorisation is complete. This page will send us a message
    // containing the token

    const redirectAppUrl = currentApp === Apps.Admin
        ? internalAppSettings.adminAppEndpoint
        : internalAppSettings.bookingAppEndpoint;

    const redirectUrl = `${redirectAppUrl}${PaymentPageNames[PaymentPageNamesEnum.GenesysRedirect]}`;

    // Formulate authorisation request to Genesys API
    const queryParameters = toQueryString({ response_type: "token", client_id: genesysSettings.clientId, redirect_uri: redirectUrl });

    // Host an iframe containing the Genesys auth page. Genesys will redirect back to us with a token if they are already logged in
    // or after they login if they weren't logged in
    // The advantage of using an iFrame is that any login/authorisation happens without moving away from our current page.
    // The iframe is initially hidden by giving it no height/width
    return (
        <>
            <iframe className={iframeClassName} src={`${genesysSettings.genesysAuthorisationEndpoint}?${queryParameters}`} />
        </>
    );
};
