import * as React from "react";
import { PrintView } from "@common/print/PrintView";
import "../DdrsLetterPrintView.scss";
import { AttendeeDetailModel } from "@common/crud/attendee/model";
import { Letter } from "./Letter";

interface DdrsAttendeeLetterPrintViewProps {
    attendee: AttendeeDetailModel;
    openingWording: string;
    closingWording?: string;
}

export const DdrsAttendeeLetterPrintView: React.FC<DdrsAttendeeLetterPrintViewProps> = (
    {
        attendee,
        openingWording,
        closingWording
    }) => {

    return (
        <PrintView>
            <Letter
                forename={attendee.forename}
                surname={attendee.surname}
                address={attendee.address}
                letterOpening={openingWording}
                letterClosing={closingWording ?? ""} />
        </PrintView>
    );
};
