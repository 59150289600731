import * as React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { CoreDetailProps } from "../Detail";
import { policeOrganisationsSelector } from "@common/crud/organisation/selectors";
import { useSelector } from "react-redux";
import { CountryEnum } from "@common/crud/organisation/model";
import { AreaList } from "./AreaList";

export const AreasTab: React.FC<CoreDetailProps> = ({ trainer }) => {

    const organisationOptions = useSelector(policeOrganisationsSelector);

    const createDescription = (fieldName: string, classroomOnly: boolean) =>
        `The below is a list of ${fieldName} that this trainer is able to deliver ${classroomOnly?"classroom ":""}courses in:`;
    const createPlaceholder = (fieldName: string) => `This trainer has no associated ${fieldName}.`;

    const policeAreaList = trainer.areaIds ? trainer.areaIds.map(areaId => organisationOptions.find(o => o.id === areaId)?.name) : [];
    const countryList = trainer.ddrsCountries? trainer.ddrsCountries.map(country => CountryEnum[country]) : [];

    return (
        <Tab.Pane>
            <Grid>
                <AreaList title={"NDORS Police Areas"} description={createDescription("Police Areas", true)}
                    subtitle={"Areas"} list={policeAreaList} placeholder={createPlaceholder("Police Areas")} />
                <AreaList title={"DDRS Countries"} description={createDescription("Countries", false)}
                    subtitle={"Countries"} list={countryList} placeholder={createPlaceholder("Countries")} />
            </Grid>
        </Tab.Pane>);
};
