import * as React from "react";
import { Divider } from "semantic-ui-react";
import { FeatureSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const Features: ConfigurationAreaComponent<FeatureSettings> = ({ settings, hierarchy }) => (
    <>
        <h1>Features</h1>
        <Divider />
        <SettingsGrid
            area="Features"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "PoliceAndCourtSubcontracting", label: "Police And Court Subcontracting", inputType: InputType.Toggle },
                { name: "CommercialSubcontracting", label: "Commercial Subcontracting", inputType: InputType.Toggle },
                { name: "ConstructionSubcontracting", label: "Construction Subcontracting", inputType: InputType.Toggle },
                { name: "UseReCaptcha", label: "Use ReCaptcha", inputType: InputType.Toggle },
                { name: "UseAccountLocking", label: "Use Account Locking", inputType: InputType.Toggle },
                { name: "TrainerPromise", label: "Trainer Promise", inputType: InputType.Toggle },
                { name: "TrainerPromiseInvoiceReminder", label: "Trainer Promise Invoice Reminder", inputType: InputType.Toggle },
                { name: "AutoSpreading", label: "Auto Spreading", inputType: InputType.Toggle },
                { name: "CorporateBookingContactButtons", label: "Commercial Booking Contact Buttons", inputType: InputType.Toggle },
                { name: "BookingContactButtons", label: "Booking Contact Buttons", inputType: InputType.Toggle },
                { name: "WalletPayments", label: "Apple / Google Pay", inputType: InputType.Toggle },
                { name: "OnlineBookNowPayLaterEnabled", label: "Online book now pay later", inputType: InputType.Toggle },
                { name: "OfflineBookNowPayLaterEnabled", label: "Offline book now pay later", inputType: InputType.Toggle },
                { name: "AllowCommercialOpenBookingManualDelegateAdjustments",
                    label: "Allow Commercial Open Booking Manual Delegate Adjustments", inputType: InputType.Toggle },
                { name: "AllowConstructionOpenBookingManualDelegateAdjustments",
                    label: "Allow Construction Open Booking Manual Delegate Adjustments", inputType: InputType.Toggle },
                { name: "OrganisationPendingCourses", label: "Organisation Pending Courses", inputType: InputType.Toggle },
            ]}
        />
    </>
);
