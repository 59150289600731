import * as React from "react";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { HistoryRecord } from "../model";
import { HistoryEntry } from "./HistoryEntry";
import { PendingNoteModal } from "./PendingNoteModal";
import _ from "lodash";
import { DdrsHistoryEntryTitle } from "@common/history/components/DdrsHistoryEntryTitle";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

export interface HistoryDateEntriesProps {
    dateEntries: { key: string; values: HistoryRecord[] };
    showAllDetails: boolean;
    workflowType: number;
    daySelected?: number;
    eventTypeCategory?: EventTypeCategory;
    taskRelation: TaskRelationEnum;
}

export const HistoryDateEntries: React.SFC<HistoryDateEntriesProps> =
({ dateEntries, showAllDetails, workflowType, daySelected, eventTypeCategory, taskRelation }) => {
    const [showEditPendingNote, setShowEditPendingNote] = React.useState<boolean>(false);
    const [editedEntry, setEditedEntry] = React.useState<HistoryRecord>(null);

    const editPendingNote = React.useCallback((entry: HistoryRecord) => {
        setEditedEntry(entry);
        setShowEditPendingNote(true);
    }, []);

    const close = React.useCallback(() => setShowEditPendingNote(false), []);

    const getGroupedByTitleTimeEntries = () => {
        let dataValues = dateEntries.values;

        if (daySelected !== null && daySelected !== 0) {
            dataValues = dataValues.filter((h: HistoryRecord) => h.eventInstanceDay === daySelected);
        }

        const filteredByData = dataValues.filter((v) => v.eventMessage !== "" || !!v.comparisonResult.length);

        return _(filteredByData)
            .groupBy(h => `${h.dateCreated.format(DateFormat.Time)}/${h.eventType}/${h.recordModifiedByEmail}`)
            .map((value, key) => ({ key, values: value }))
            .value();
    };

    if (workflowType === WorkflowTypeEnum.DDRS && dateEntries.values.every(v => !v.comparisonResult.length && v.eventMessage === "")) {
        return <></>;
    }

    return (
        <div className="container">
            <div className="date">
                <span className="bold">
                    {moment(dateEntries.key, DateFormat.Short).format(DateFormat.DayMonthYear)}
                </span>
            </div>
            <div className="timeline">
                <div className="indicator">
                    <div className="join" />
                    <div className="tip" />
                </div>
            </div>
            <div className="details">
                {
                    eventTypeCategory !== EventTypeCategory.Ddrs
                        ? dateEntries
                            .values
                            .map(entry => (<HistoryEntry
                                key={entry.id}
                                entry={entry}
                                showAllDetails={showAllDetails}
                                onEditPendingNote={editPendingNote}
                                eventTypeCategory={eventTypeCategory}
                                taskRelation={taskRelation} /> ))
                        : getGroupedByTitleTimeEntries()
                            .map(keyValue => (<DdrsHistoryEntryTitle
                                key={keyValue.key}
                                entries={keyValue.values}
                                showAllDetails={showAllDetails}
                                entryKey={keyValue.key}
                                onEditPendingNote={editPendingNote} />))

                }
            </div>
            <PendingNoteModal open={showEditPendingNote} entry={editedEntry} onClose={close} />
        </div>);
};
