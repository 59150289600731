import { TtcVenueAdminRole } from "@common/auth/model";
import { loadVenueDetail } from "./actions";
import { AppState } from "./model";
import { venueSelector } from "./selectors";
import { detailRoutes } from "./detailRoutes";
import { loadAllConstructionOrganisations, loadAllCorporateOrganisations, loadAllPoliceAndCourtOrganisations,  } from "../organisation/actions";
import { loadDsaAreas } from "@common/dsa";

export const VenueRoutes = {
    VenueDetails: "/:venueId",
    Edit: "/edit",
    Venues: "/venues",
    Create: "/create"
};

export const detailRoute = () => ({
    [VenueRoutes.VenueDetails]: {
        title: (state: AppState) => venueSelector(state).name,
        resolve: [loadVenueDetail],
        authorize: TtcVenueAdminRole,
        [VenueRoutes.Edit]: {
            title: "Edit",
            resolve: [loadAllPoliceAndCourtOrganisations, loadDsaAreas],
        },
        ...detailRoutes
    }
});

export const corporateDetailRoute = () => ({
    [VenueRoutes.VenueDetails]: {
        title: (state: AppState) => venueSelector(state).name,
        resolve: [loadVenueDetail],
        authorize: TtcVenueAdminRole,
        [VenueRoutes.Edit]: {
            title: "Edit",
            resolve: [loadAllCorporateOrganisations],
        },
        ...detailRoutes
    }
});

export const constructionDetailRoute = () => ({
    [VenueRoutes.VenueDetails]: {
        title: (state: AppState) => venueSelector(state).name,
        resolve: [loadVenueDetail],
        authorize: TtcVenueAdminRole,
        [VenueRoutes.Edit]: {
            title: "Edit",
            resolve: [loadAllConstructionOrganisations],
        },
        ...detailRoutes
    }
});

export const createVenueRouteObject = {
    title: "Create Venue",
    resolve: [loadAllPoliceAndCourtOrganisations, loadDsaAreas],
};

export const corporateCreateVenueRouteObject = {
    title: "Create Venue",
    resolve: [loadAllCorporateOrganisations],
};

export const constructionCreateVenueRouteObject = {
    title: "Create Venue",
    resolve: [loadAllConstructionOrganisations],
};

export const routes = {
    [VenueRoutes.Venues]: {
        resolve: [loadAllPoliceAndCourtOrganisations, loadDsaAreas],
        title: "Venues",
        ignoreParentResolve: true,
        authorize: TtcVenueAdminRole,
        ...detailRoute(),
        [VenueRoutes.Create]: createVenueRouteObject
    }
};

export const corporateRoutes = {
    [VenueRoutes.Venues]: {
        title: "Venues",
        ignoreParentResolve: true,
        authorize: TtcVenueAdminRole,
        ...corporateDetailRoute(),
        [VenueRoutes.Create]: corporateCreateVenueRouteObject
    }
};

export const constructionRoutes = {
    [VenueRoutes.Venues]: {
        title: "Venues",
        ignoreParentResolve: true,
        authorize: TtcVenueAdminRole,
        ...constructionDetailRoute(),
        [VenueRoutes.Create]: constructionCreateVenueRouteObject
    }
};
