import { LetterFooter } from "@common/print/LetterFooter";
import { LetterHeader } from "@common/print/LetterHeader";
import * as React from "react";
import { Address } from "@common/crud/common";

interface LetterProps {
    forename: string;
    surname: string;
    address: Address;
    letterOpening: string;
    letterClosing?: string;
}

export const Letter = ({ letterOpening, letterClosing, address, forename, surname }: LetterProps) => {
    return (
        <>
            <LetterFooter />
            <table>
                <tbody>
                    <tr>
                        <td>
                            <LetterHeader
                                addressee={`${forename} ${surname}`}
                                address={address} />
                            <div className="invite-letter-body">
                                <div className="invite-letter-content" dangerouslySetInnerHTML={{ __html: letterOpening + letterClosing ?? "" }} />
                                <p>Kind Regards</p>
                                <p>TTC</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};
