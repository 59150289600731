import { HistoryRecord } from "@common/history/model";
import moment from "moment";

function completePendingNoteInHistory(records: HistoryRecord[], nudgeTaskId: string): HistoryRecord[] {
    if (!records.some(note => note.nudgeTaskId === nudgeTaskId)) {
        return records;
    }

    const newRecords = records.slice();
    const index = newRecords.findIndex(x => x.nudgeTaskId === nudgeTaskId);
    const note = { ...newRecords[index] };
    note.completionDate = moment();
    note.isPendingNoteCompleted = true;
    newRecords[index] = note;
    return newRecords;
}

export { completePendingNoteInHistory };
