import { isString, flatten } from "lodash";
import { ReactNode } from "react";

const replaceString = (str: string, match: string, fn: (m: any, i: number) => ReactNode | string) => {
    if (str === "") {
        return str;
    } else if (!str) {
        throw new TypeError("Parameter needs to have value");
    }

    const re = new RegExp(`(${match})`, "gi");

    const result: ReactNode[] = str.split(re);

    for (let i = 1, length = result.length; i < length; i += 2) {
        result[i] = fn(result[i], i);
    }

    return result;
};

export const reactStringReplace =
    (source: string | React.ReactNode[], match: string, fn?: (m: any, i: number) => ReactNode | string) => {
        if (!Array.isArray(source)) {
            source = [source];
        }

        const flatArray = flatten(source.map((x) => isString(x) ? replaceString(x, match, fn) : x));
        return flatArray.filter(x => x !== "");
    };
