import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const DrivingWithCareAndAttentionSection: QuestionSection = {
    id: "9",
    title: "Module 6",
    seqNum: 8,
    subSections: [{
        id: "9.1",
        text: "Driving with care and attention 15 min",
        seqNum: 1,
        criteria: [
            { id: "1", text: "Map re-capped" },
            { id: "2", text: "Help clients understand the role played by COAST and considerate driving." },
            { id: "3", text: "Tunnel and funnel vision" },
            { id: "4", text: "Say what you see introduced" },
            { id: "5", text: "What might happen next?" },
            { id: "6", text: "Safety bubble" },
            { id: "7", text: "2 sec rule" },
            { id: "8", text: "COAST." }
        ]
    }],
    summaryCriterium: [{ id: "9", text: "", typeEnum: CriteriaEnum.text }]
};
