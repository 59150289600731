/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_VENUES_SUCCESS = "LOAD_VENUES_SUCCESS";
export type LOAD_VENUES_SUCCESS = typeof LOAD_VENUES_SUCCESS;

export const CREATE_VENUE_SUCCESS = "CREATE_VENUE_SUCCESS";
export type CREATE_VENUE_SUCCESS = typeof CREATE_VENUE_SUCCESS;

export const SAVE_VENUE_SUCCESS = "SAVE_VENUE_SUCCESS";
export type SAVE_VENUE_SUCCESS = typeof SAVE_VENUE_SUCCESS;

export const DELETE_VENUE_SUCCESS = "DELETE_VENUE_SUCCESS";
export type DELETE_VENUE_SUCCESS = typeof DELETE_VENUE_SUCCESS;

export const LOAD_VENUE_DETAIL_SUCCESS = "LOAD_VENUE_DETAIL_SUCCESS";
export type LOAD_VENUE_DETAIL_SUCCESS = typeof LOAD_VENUE_DETAIL_SUCCESS;
