/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Button, Form, Grid } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { AsyncDispatch, businessLineTypeSelector, createBasePathSelector } from "@common/redux-helpers";
import { phoneNumber } from "@common/validation";

import {
    AppState,
    BusinessDevelopmentManager,
    BusinessLineType,
    CorporateOrganisationContact,
    OrganisationContactDataModel,
    OrganisationCreateModel,
    ReminderType
} from "../model";
import { corporateOrConstructionBasePathSelector } from "../selectors";
import { createOrganisation, loadBusinessDevelopmentManagers } from "../actions";

import { ConstructionCreateModal } from "./ConstructionCreateModal";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { toast } from "react-toastify";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { OrganisationContactRow } from "./OrganisationContactRow";
import { NonDriverCustomerStatus, CustomerStatusEnum } from "@common/crud/alaskaNudgeTask/model";
import { optionsFromObject } from "@common/crud/common";
import { AppCommonState } from "@common/appCommonState";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { ExtendedEmailInput } from "@common/components/ExtendedEmailInput";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";

export interface CreateProps {
    open: boolean;
    quickAddId: string;
    businessDevelopmentManagers: BusinessDevelopmentManager[];
    loadBusinessDevelopmentManagers: () => void;
}

export interface DispatchProps extends SaveDispatchProps<OrganisationCreateModel> {
    close: () => void;
}

interface CreateState extends FormState<OrganisationCreateModel> {
    quickAddId: string;
    contactsValid: Set<string>;
}

export class ConstructionCreateForm extends FormBaseComponent<OrganisationCreateModel, CreateProps, CreateState> {

    constructor(props: CreateProps & SaveDispatchProps<OrganisationCreateModel>) {
        super(props);
        this.state = {
            values: {
                id: "",
                name: "",
                reminderType: ReminderType.None,
                dorsId: undefined,
                customerStatus: CustomerStatusEnum.Customer,
                organisationContact: {
                    contacts: []
                },
                organisationCourseContact: {
                    contacts: []
                },
                organisationFinanceContact: {
                    contacts: []
                },
                businessLineType: BusinessLineType.Construction,
                corporateOrganisationData: {
                    bdmId: "",
                    bdmName: "",
                    bdmEmail: "",
                    address: undefined,
                    emailAddress: "",
                    phoneNumber: "",
                    website: "",
                    partner: "",
                    accountManager: "",
                    isBundleDiscountEnabled: false,
                    corporateOrganisationSpecificFeesData: {
                        openDigitalEIFeeWithEffectiveDate: [],
                        openClassroomEIFeeWithEffectiveDate: [],
                        closedDigitalEIFeeWithEffectiveDate: [],
                        closedClassroomEIFeeWithEffectiveDate: [],
                        cpcUploadFee: []
                    }
                },
            },
            valid: {},
            contactsValid: new Set<string>(),
            quickAddId: "",
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onBdmChange = this.onBdmChange.bind(this);
        this.addPrimaryContact = this.addPrimaryContact.bind(this);
        this.removePrimaryContact = this.removePrimaryContact.bind(this);
        this.addCourseContact = this.addCourseContact.bind(this);
        this.removeCourseContact = this.removeCourseContact.bind(this);
        this.addFinanceContact = this.addFinanceContact.bind(this);
        this.removeFinanceContact = this.removeFinanceContact.bind(this);
        this.updateContactValue = this.updateContactValue.bind(this);
        this.fixContactsInvalid = this.fixContactsInvalid.bind(this);
        this.submit = this.submit.bind(this);
    }

    public initialValueState: OrganisationCreateModel = {
        id: "",
        name: "",
        reminderType: ReminderType.None,
        dorsId: undefined,
        customerStatus: CustomerStatusEnum.Customer,
        companyType: [],
        organisationContact: {
            contacts: []
        },
        organisationCourseContact: {
            contacts: []
        },
        organisationFinanceContact: {
            contacts: []
        },
        businessLineType: BusinessLineType.Construction,
        corporateOrganisationData: {
            isBundleDiscountEnabled: false,
            bdmId: "",
            bdmName: "",
            bdmEmail: "",
            address: undefined,
            emailAddress: "",
            phoneNumber: "",
            website: "",
            partner: "",
            accountManager: "",
            corporateOrganisationSpecificFeesData: {
                openDigitalEIFeeWithEffectiveDate: [],
                openClassroomEIFeeWithEffectiveDate: [],
                closedDigitalEIFeeWithEffectiveDate: [],
                closedClassroomEIFeeWithEffectiveDate: [],
                cpcUploadFee: []
            }
        }
    };

    public initialState: CreateState = {
        values: this.initialValueState,
        valid: {},
        contactsValid: new Set<string>(),
        quickAddId: "",
    };

    public componentDidMount() {
        this.props.loadBusinessDevelopmentManagers();
    }

    public componentDidUpdate() {
        if (this.props.quickAddId !== this.state.quickAddId) {
            this.setState({ ...this.initialState, quickAddId: this.props.quickAddId,  });
        }
    }

    public onBdmChange(bdm: string) {
        this.updateNestedProperty("corporateOrganisationData.bdmId", bdm, true);

        const matchingBdm = this.props.businessDevelopmentManagers.find(m => m.id === bdm);

        this.updateNestedProperty("corporateOrganisationData.bdmName", matchingBdm?.name, true);
        this.updateNestedProperty("corporateOrganisationData.bdmEmail", matchingBdm?.email, true);
    }

    public updateContactValue(contact: CorporateOrganisationContact, index: number, field: string, value: string, valid: boolean) {
        const validField = `${contact}.${index}.${field}`;
        const uniqueValidField = `${contact}.unique`;
        const newContactValid = new Set(this.state.contactsValid);

        if (valid && newContactValid.has(validField)) {
            newContactValid.delete(validField);
        }

        if (!valid && !newContactValid.has(validField)) {
            newContactValid.add(validField);
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Primary) {
            const newContacts = this.state.values.organisationContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const nameContactRepresentations = newContacts.map(c => c.name);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
                || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({ values: { ...this.state.values, organisationContact: { ...this.state.values.organisationContact, contacts: newContacts } } });
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Course) {
            const newContacts = this.state.values.organisationCourseContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const nameContactRepresentations = newContacts.map(c => c.name);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
                || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({
                values: { ...this.state.values, organisationCourseContact: { ...this.state.values.organisationCourseContact, contacts: newContacts } } });
        }

        if ((contact as CorporateOrganisationContact)  === CorporateOrganisationContact.Finance) {
            const newContacts = this.state.values.organisationFinanceContact.contacts.map((existingContact, contactIndex) => contactIndex === index
                ? { ...existingContact, [field]: value }
                : existingContact);
            const nameContactRepresentations = newContacts.map(c => c.name);
            const emailContactRepresentations = newContacts.map(c => c.email);
            const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
                || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

            if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
                newContactValid.delete(uniqueValidField);
            }

            if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
                newContactValid.add(uniqueValidField);
            }

            this.setState({
                values: { ...this.state.values, organisationFinanceContact: { ...this.state.values.organisationFinanceContact, contacts: newContacts } } });
        }

        this.setState({ contactsValid: newContactValid });
    }

    public fixContactsInvalid(contact: CorporateOrganisationContact, indexRemoved: number, newContacts: OrganisationContactDataModel[]) {
        const newContactValid = new Set<string>();
        this.state.contactsValid.forEach(invalidField => {
            const parts = invalidField.split(".");

            if (+parts[0] === contact) {
                if (parts[1] !== "unique") {
                    if (+parts[1] < indexRemoved) {
                        newContactValid.add(invalidField);
                    }

                    if (+parts[1] > indexRemoved) {
                        const newInvalidField = `${parts[0]}.${+parts[1] - 1}.${parts[2]}`;
                        newContactValid.add(newInvalidField);
                    }
                } else {
                    const nameContactRepresentations = newContacts.map(c => c.name);
                    const emailContactRepresentations = newContacts.map(c => c.email);
                    const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
                        || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

                    if (hasDuplicates) {
                        newContactValid.add(invalidField);
                    }
                }
            } else {
                newContactValid.add(invalidField);
            }
        });
        this.setState({ contactsValid: newContactValid });
    }

    public removePrimaryContact(index: number) {
        const newContacts = this.state.values.organisationContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Primary, index, newContacts);
    }

    public addPrimaryContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationContact.contacts",
            [ ...this.state.values.organisationContact.contacts, { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public removeCourseContact(index: number) {
        const newContacts = this.state.values.organisationCourseContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationCourseContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Course, index, newContacts);
    }

    public addCourseContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationCourseContact.contacts",
            [ ...this.state.values.organisationCourseContact.contacts, { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public removeFinanceContact(index: number) {
        const newContacts = this.state.values.organisationFinanceContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationFinanceContact.contacts", newContacts, true);
        this.fixContactsInvalid(CorporateOrganisationContact.Finance, index, newContacts);
    }

    public addFinanceContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationFinanceContact.contacts",
            [ ...this.state.values.organisationFinanceContact.contacts, { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public render() {
        const { values, showErrors } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <h2>Account Details</h2>
                <ExtendedTextInput
                    value={values.name}
                    label="Name"
                    required
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("name", value, valid)}
                />
                <ExtendedDropdown
                    value={values.corporateOrganisationData.bdmId}
                    label="BDM"
                    showErrors={showErrors}
                    options={(values.corporateOrganisationData.bdmId &&
                        !this.props.businessDevelopmentManagers.some(bdm => bdm.id === values.corporateOrganisationData.bdmId)
                        ? [...this.props.businessDevelopmentManagers,
                            {
                                id: values.corporateOrganisationData.bdmId,
                                name: values.corporateOrganisationData.bdmName,
                                email: values.corporateOrganisationData.bdmEmail
                            }
                        ] : this.props.businessDevelopmentManagers).map(bdm => ({ text: `${bdm.name} [${bdm.email}]`, value: bdm.id }))}
                    onChange={this.onBdmChange}
                    dynamicOptions
                    search
                />
                <ExtendedDropdownNumber
                    value={values.customerStatus}
                    label="Customer Status"
                    required
                    showErrors={showErrors}
                    options={optionsFromObject(NonDriverCustomerStatus)}
                    onChange={(value, valid) => this.updateProperty("customerStatus", value, valid)}
                />
                <AddressLookup
                    required
                    showErrors={showErrors}
                    address={values.corporateOrganisationData.address}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.address", value, valid)}
                    autofill
                />
                <ExtendedEmailInput
                    value={values.corporateOrganisationData.emailAddress}
                    label="Email Address"
                    required
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.emailAddress", value, valid)}
                />
                <ExtendedTextInput
                    value={values.corporateOrganisationData.phoneNumber}
                    label="Phone Number"
                    validation={phoneNumber()}
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.phoneNumber", value, valid)}
                />
                <ExtendedTextInput
                    value={values.corporateOrganisationData.website}
                    label="Website"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.website", value, valid)}
                />
                <AddressLookup
                    title="Invoice Address"
                    showErrors={showErrors}
                    address={values.corporateOrganisationData.invoiceAddress}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoiceAddress", value, valid)}
                    autofill
                    required={false}
                />
                <ExtendedEmailInput
                    value={values.corporateOrganisationData.invoiceEmailAddress}
                    label="Accounts Payable Email Address"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoiceEmailAddress", value, valid)}
                />
                <ExtendedTextInput
                    value={values.corporateOrganisationData.invoicePhoneNumber}
                    label="Accounts Payable Phone Number"
                    validation={phoneNumber()}
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.invoicePhoneNumber", value, valid)}
                />
                <MuiDateField
                    value={values.expiryDate}
                    label="Expiry Date"
                    showErrors={showErrors}
                    validation={[muiTodayOrfutureDateValidator]}
                    onChange={(value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid)}
                />
                <MarkdownEditor
                    value={values.organisationNote}
                    label="Organisation Note"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("organisationNote", value, valid)}
                />
                <h2>Partner Details</h2>
                <ExtendedTextInput
                    value={values.corporateOrganisationData.partner}
                    label="Partner"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.partner", value, valid)}
                />
                <ExtendedTextInput
                    value={values.corporateOrganisationData.accountManager}
                    label="Account Manager"
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("corporateOrganisationData.accountManager", value, valid)}
                />

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Primary Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addPrimaryContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationContact && values.organisationContact.contacts && values.organisationContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                key={`primaryContact_${index}`}
                                contactType={CorporateOrganisationContact.Primary}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removePrimaryContact}
                            />
                        )
                    )}
                </Grid>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Course Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addCourseContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationCourseContact && values.organisationCourseContact.contacts && values.organisationCourseContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                key={`courseContact_${index}`}
                                contactType={CorporateOrganisationContact.Course}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removeCourseContact}
                            />
                        )
                    )}
                </Grid>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Finance Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addFinanceContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationFinanceContact && values.organisationFinanceContact.contacts && values.organisationFinanceContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                key={`financeContact_${index}`}
                                contactType={CorporateOrganisationContact.Finance}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removeFinanceContact}
                            />
                        )
                    )}
                </Grid>
            </Form>
        );
    }

    public submit(): void {
        if (!this.valid() || (this.state.contactsValid && this.state.contactsValid.size !== 0)) {
            this.setState({ showErrors: true });
            toast.warning("Organisation cannot be created as some mandatory fields have not been completed");
            return;
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    }
}

function mapStateToProps(state: AppState & AppCommonState) {
    const pathname = state.router.pathname;
    const quickAdd = pathname.includes("quickAdd");
    const createForm = pathname.endsWith("/create");

    const open = quickAdd || createForm;

    const businessLineType = businessLineTypeSelector(state);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);
    const basePath = quickAdd ? createBasePathSelector(businessLineTypePath)(state) : corporateOrConstructionBasePathSelector(state);
    const quickAddId = quickAdd ? pathname.substring(pathname.indexOf("quickAdd")) : "";
    const businessDevelopmentManagers = state.businessDevelopmentManagers;

    return {
        open,
        basePath,
        quickAddId,
        businessLineType,
        businessDevelopmentManagers
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchLoadBusinessDevelopmentManagers: () => dispatch(loadBusinessDevelopmentManagers()),
        dispatchSave: (organisation: OrganisationCreateModel, basePath: string, businessLineType: BusinessLineType) => {
            const quickAdd = basePath === "/construction-event-management";
            return dispatch(createOrganisation(organisation, basePath, businessLineType, quickAdd));
        },
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): CreateProps & DispatchProps {
    return {
        open: propsFromState.open,
        quickAddId: propsFromState.quickAddId,
        businessDevelopmentManagers: propsFromState.businessDevelopmentManagers,
        save: (organisation: OrganisationCreateModel) => propsFromDispatch.dispatchSave(organisation, propsFromState.basePath, propsFromState.businessLineType),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
        loadBusinessDevelopmentManagers: () => propsFromDispatch.dispatchLoadBusinessDevelopmentManagers(),
    };
}

export const ConstructionCreate = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConstructionCreateModal);
