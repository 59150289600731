/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_TRAINERS_SUCCESS = "LOAD_TRAINERS_SUCCESS";
export type LOAD_TRAINERS_SUCCESS = typeof LOAD_TRAINERS_SUCCESS;

export const LOAD_TRAINER_MONITORING_PLANS = "LOAD_TRAINER_MONITORING_PLANS";
export type LOAD_TRAINER_MONITORING_PLANS = typeof LOAD_TRAINER_MONITORING_PLANS;

export const CREATE_TRAINER_SUCCESS = "CREATE_TRAINER_SUCCESS";
export type CREATE_TRAINER_SUCCESS = typeof CREATE_TRAINER_SUCCESS;

export const SAVE_TRAINER_SUCCESS = "SAVE_TRAINER_SUCCESS";
export type SAVE_TRAINER_SUCCESS = typeof SAVE_TRAINER_SUCCESS;

export const LOAD_TRAINER_DETAIL_SUCCESS = "LOAD_TRAINER_DETAIL_SUCCESS";
export type LOAD_TRAINER_DETAIL_SUCCESS = typeof LOAD_TRAINER_DETAIL_SUCCESS;

export const SIGNUP_INVITATION_SUCCESS = "SIGNUP_INVITATION_SUCCESS";
export type SIGNUP_INVITATION_SUCCESS = typeof SIGNUP_INVITATION_SUCCESS;

export const LOAD_TRAINERS_DASHBOARD_SUCCESS = "LOAD_TRAINERS_DASHBOARD_SUCCESS";
export type LOAD_TRAINERS_DASHBOARD_SUCCESS = typeof LOAD_TRAINERS_DASHBOARD_SUCCESS;

export const CREATE_TRAINER = "CREATE_TRAINER";

export const SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES = "SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES";
export type SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES = typeof SAVE_OPT_SCHEME_TRAINER_ATTRIBUTES;

export const UPDATE_TRAINER_NOTE = "UPDATE_TRAINER_NOTE";
export type UPDATE_TRAINER_NOTE = typeof UPDATE_TRAINER_NOTE;
