import { SchemeAbbreviation, SchemeEnum } from "@common/crud/eventType/model";
import { PreCourseSection } from "@common/crud/eventInstance/components/monitoring/questions/PreCourseSection";
import { TrainerAttributesSection } from "@common/crud/eventInstance/components/monitoring/questions/TrainerAttributesSection";
import { IntroductionSection } from "@common/crud/eventInstance/components/monitoring/questions/IntroductionSection";
import { OverallSummarySection } from "@common/crud/eventInstance/components/monitoring/questions/OverallSummarySection";
import { CriteriaEnum, Questions } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const NMACQuestions: Questions = {

    schemeId: SchemeAbbreviation[SchemeEnum.NMAC],
    sections: [
        PreCourseSection,
        TrainerAttributesSection,
        IntroductionSection,
        {
            id: "4",
            title: "Module 1",
            seqNum: 3,
            subSections: [
                {
                    id: "4.1",
                    text: "Facts about smart motorways 30 min",
                    seqNum: 4,
                    criteria: [
                        { id: "1", text: "What do you like about smart motorways?" },
                        { id: "2", text: "Write down what you don’t like, hold up to screen." },
                        { id: "3", text: "Traffic volume and impacts" },
                        { id: "4", text: "What are smart motorways?" },
                        { id: "5", text: "Why have them?" },
                        { id: "6", text: "How do they work?" },
                        { id: "7", text: "Smart motorways are smart. Ask why might people ignore the speed restriction or lane closure?" },
                        { id: "8", text: "Write down and hold up to the screen the common reasons of why lanes are closed." },
                        { id: "9", text: "Why restrict speeds?" },
                        { id: "10", text: "Traffic queues." },
                        { id: "11", text: "Traffic flow." },
                        { id: "12", text: "Phantom jams." },
                        { id: "13", text: "Activity 1 ask 2 or 3 client’s consequences of ignoring " +
                                "speed restrictions, then open to all. Also closed lane." },
                        { id: "14", text: "Summarise benefits" }
                    ]
                }
            ],
            summaryCriterium: [{ id: "15", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "5",
            title: "Module 2",
            seqNum: 4,
            subSections: [
                {
                    id: "5.1",
                    text: "Using smart motorways 40 min plus 10 min break",
                    seqNum: 4,
                    criteria: [
                        { id: "1", text: "Using smart motorways" },
                        { id: "2", text: "How to tell you are joining, variable speed limit sign, slip road signs" },
                        { id: "3", text: "Red circles signs on gantries, what is a hard shoulder? If one sign blank what does it mean?" },
                        { id: "4", text: "All lanes running, MS4 signs." },
                        { id: "5", text: "Red x signs, taxi in lane, is it ok for you to do that?" },
                        { id: "6", text: "Can you remember why lanes are closed? What if there is a red x in the left lane approaching a junction?" },
                        { id: "7", text: "If you are on a motorway with a hard shoulder and the sign above it is blank, " +
                                "can you drive on the hard shoulder? No." },
                        { id: "8", text: "Restrictions end upon seeing national speed limit displayed." },
                        { id: "9", text: "How to know you are leaving smart motorway, “variable speed limit ends” sign." },
                        { id: "10", text: "Summarise signs, include signs that will be seen on traditional motorways." },
                        { id: "11", text: "Emergency areas. Ask what is an emergency area and how do you know when the next one is?" },
                        { id: "12", text: "Write down and hold up to the screen what is not an emergency. Reveal what is." },
                        { id: "13", text: "What should you do in an emergency?" },
                        { id: "14", text: "Ask why are emergency areas being painted orange?" },
                        { id: "15", text: "Ask what to do when you wish to re-join the carriageway?" },
                    ]
                }
            ],
            summaryCriterium: [{ id: "16", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "6",
            title: "Module 3",
            seqNum: 5,
            subSections: [
                {
                    id: "6.1",
                    text: "Why it matters 20 min",
                    seqNum: 6,
                    criteria: [
                        { id: "1", text: "Activity 2, consequences of being involved in a collision. Each topic can be" +
                                " given to 4 sets of different clients to discuss." },
                        { id: "2", text: "All clients asked what are the consequences to you the driver. " },
                        { id: "3", text: "Why do collisions happen? Why do you think motorways are the safest road type?" },
                        { id: "4", text: "Does speed matter? If instead of 50 mph restricted speed you chose to drive " +
                                "at 60mph or 70 mph the impact speeds would be?" },
                        { id: "5", text: "Will they live or die? " }
                    ]
                }
            ],
            summaryCriterium: [{ id: "6", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "7",
            title: "Module 4",
            seqNum: 6,
            subSections: [
                {
                    id: "7.1",
                    text: "Action planning 20 min",
                    seqNum: 7,
                    criteria: [
                        { id: "1", text: "Putting things into practice. Good intentions easier said than done." },
                        { id: "2", text: "Your choice, consequences, good intentions." },
                        { id: "3", text: "Non-driving example given." },
                        { id: "4", text: "When/where do other people break the rules on motorways?" },
                        { id: "5", text: "Q1, When and where are you most at risk? Write down and hold up to the screen." },
                        { id: "6", text: "Why do other people break the rules?" },
                        { id: "7", text: "Q2, when do you break the rules?" },
                        { id: "8", text: "Ask someone to share Q1 &2, and what they are going to do. Encourage client to client engagement." },
                        { id: "9", text: "Q3, Encourage clients to write down their answers." },
                        { id: "10", text: "We have good intentions, but it is hard to change. Why do our plans sometimes fail?" },
                        { id: "11", text: "Q4, why might the plan go wrong?" },
                        { id: "12", text: "Q5, how will you get over those barriers?" },
                        { id: "13", text: "Ask if a client wishes to share their whole plan." },
                        { id: "14", text: "Recap" },
                        { id: "15", text: "Any questions from the beginning that we have not addressed?" },
                        { id: "16", text: "Internal Monitor to record the trainers action plan." },
                        { id: "17", text: "Encourage clients to end the course." },
                    ]
                }
            ],
            summaryCriterium: [{ id: "18", text: "", typeEnum: CriteriaEnum.text }]
        },
        OverallSummarySection
    ]
};
