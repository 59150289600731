import * as React from "react";
import moment from "moment";
import { Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { MultiDayTrainerAvailabilitySelectModel, TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { useAvailabilityContext } from "@common/crud/eventInstance/components/trainers/availability-context";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { GroupEventInstancesModel } from "@common/crud/eventInstance/model";
import { MultiDayTrainerRow } from "@common/crud/eventInstance/components/trainers/MultiDayTrainerRow";
import { Spinner } from "@common/global";

interface OwnProps {
    isDigital: boolean;
    selectedTrainers: TrainerAvailabilitySelectModel[];
    trainers: Record<string, TrainerAvailabilitySelectModel[]>;
    limit?: { value: number; message: string };
    onSelectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel): void;
    onDeselectTrainer(trainer: MultiDayTrainerAvailabilitySelectModel): void;
    nameSearch?: string;
    daysInGroup?: number;
    selectedMultiDayParts: GroupEventInstancesModel[];
    onAllocateToDayNumber: (trainerId: string, dayNumber: number) => void;
    loading: boolean;
}

export const MultiDayTrainersSelector: React.FunctionComponent<OwnProps> = ({
    isDigital,
    selectedTrainers,
    trainers,
    limit,
    onSelectTrainer,
    onDeselectTrainer,
    nameSearch,
    daysInGroup,
    selectedMultiDayParts,
    onAllocateToDayNumber,
    loading,
}) => {

    const { eventInstanceGroup } = useAvailabilityContext();

    function addToDisplay(
        displayResult: MultiDayTrainerAvailabilitySelectModel[],
        dayNumber: number,
        val: TrainerAvailabilitySelectModel,
        trainerValues: TrainerAvailabilitySelectModel[]) {

        const { id, fullName } = val;
        if (!displayResult.find(dr => dr.id === id)) {
            displayResult.push({ id, fullName, dayAvailabilities: [] });
        }
        const current = displayResult.find(dr => dr.id === id);
        const index = displayResult.indexOf(current, 0);
        current.dayAvailabilities[dayNumber] = trainerValues;
        displayResult[index] = current;
    }

    function getFlattenedTrainersByDays(): MultiDayTrainerAvailabilitySelectModel[] {
        const displayResult: MultiDayTrainerAvailabilitySelectModel[] = [];

        for (const trainerVals of Object.values(trainers)) {
            for (const val of Object.values(trainerVals)) {
                const {  fullName, availabilityCategoryDayNumbers } = val;
                if (nameSearch !== "" && nameSearch !== null) {
                    if (fullName.toLowerCase().includes(nameSearch.trim().toLowerCase())) {
                        for (const dayNumber of availabilityCategoryDayNumbers) {
                            addToDisplay(
                                displayResult, dayNumber, val, [trainerVals.find(tv => tv.id === val.id)] );
                        }
                    }
                } else {
                    for (const dayNumber of availabilityCategoryDayNumbers) {
                        addToDisplay(
                            displayResult, dayNumber, val, [trainerVals.find(tv => tv.id === val.id)] );
                    }
                }
            }
        }

        return displayResult;
    }

    const dayColumns = eventInstanceGroup?.eventInstanceGroupItems?.length || 1;

    const addToCourseHeaderCells = () => {
        const colWidth = 16 / dayColumns;
        return (
            <Table.HeaderCell colSpan={dayColumns}>
                <Table style={{ "table-layout": "fixed" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={dayColumns} textAlign="center">
                                Add to Course
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {eventInstanceGroup.eventInstanceGroupItems?.map(gi =>
                                <TableCell textAlign="center" size={colWidth} key={gi.dayNumber}>{moment(gi.startDate).format(DateFormat.Short)}</TableCell>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>

            </Table.HeaderCell>
        );
    };

    return (<>
        <Spinner active={loading}>
            <Table celled style={{ "table-layout": "fixed" }} >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Trainer</Table.HeaderCell>
                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                        {!isDigital && <Table.HeaderCell>Distance from Venue (miles)</Table.HeaderCell>}
                        <Table.HeaderCell>no. courses in same month</Table.HeaderCell>
                        <Table.HeaderCell>no. days in rolling 12 months</Table.HeaderCell>
                        {!isDigital && <Table.HeaderCell>Venue chosen</Table.HeaderCell>}
                        {addToCourseHeaderCells()}
                        <Table.HeaderCell>All days</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getFlattenedTrainersByDays().map((trainerValues, index) => (
                        <React.Fragment key={index}>
                            <MultiDayTrainerRow
                                key={trainerValues.id}
                                isDigital={isDigital}
                                trainer={trainerValues}
                                limit={limit}
                                selectedTrainers={selectedTrainers}
                                onSelectTrainer={onSelectTrainer}
                                onDeselectTrainer={onDeselectTrainer}
                                daysInGroup={daysInGroup}
                                selectedMultiDayParts={selectedMultiDayParts}
                                onAllocateToDayNumber={onAllocateToDayNumber}
                            />
                        </React.Fragment>
                    ))}
                </Table.Body>
            </Table>
        </Spinner>
    </>);
};
