/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_ORGANISATIONS_SUCCESS = "LOAD_ORGANISATIONS_SUCCESS";
export type LOAD_ORGANISATIONS_SUCCESS = typeof LOAD_ORGANISATIONS_SUCCESS;

export const LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS = "LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS";
export type LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS = typeof LOAD_RELATED_ORGANISATION_OPTIONS_SUCCESS;

export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export type CREATE_ORGANISATION_SUCCESS = typeof CREATE_ORGANISATION_SUCCESS;

export const SAVE_ORGANISATION_SUCCESS = "SAVE_ORGANISATION_SUCCESS";
export type SAVE_ORGANISATION_SUCCESS = typeof SAVE_ORGANISATION_SUCCESS;

export const SAVE_SURVEY_LINK_SUCCESS = "SAVE_SURVEY_LINK_SUCCESS";
export type SAVE_SURVEY_LINK_SUCCESS = typeof SAVE_SURVEY_LINK_SUCCESS;

export const SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS = "SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS";
export type SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS = typeof SAVE_TERMS_AND_CONDITIONS_LINK_SUCCESS;

export const SAVE_REMINDER_TYPE_SUCCESS = "SAVE_REMINDER_TYPE_SUCCESS";
export type SAVE_REMINDER_TYPE_SUCCESS = typeof SAVE_REMINDER_TYPE_SUCCESS;

export const SAVE_ORGANISATION_SETTING_SUCCESS = "SAVE_ORGANISATION_SETTING_SUCCESS";
export type SAVE_ORGANISATION_SETTING_SUCCESS = typeof SAVE_ORGANISATION_SETTING_SUCCESS;

export const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS";
export type DELETE_ORGANISATION_SUCCESS = typeof DELETE_ORGANISATION_SUCCESS;

export const LOAD_ORGANISATION_DETAIL_SUCCESS = "LOAD_ORGANISATION_DETAIL_SUCCESS";
export type LOAD_ORGANISATION_DETAIL_SUCCESS = typeof LOAD_ORGANISATION_DETAIL_SUCCESS;

export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export type ADD_NOTE_SUCCESS = typeof ADD_NOTE_SUCCESS;

export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export type UPDATE_NOTE_SUCCESS = typeof UPDATE_NOTE_SUCCESS;

export const SAVE_BOOKING_INFORMATION_LINK_SUCCESS = "SAVE_BOOKING_INFORMATION_LINK_SUCCESS";
export type SAVE_BOOKING_INFORMATION_LINK_SUCCESS = typeof SAVE_BOOKING_INFORMATION_LINK_SUCCESS;

export const LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS = "LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS";
export type LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS = typeof LOAD_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS;

export const UPDATE_ORGANISATION_FAVOURITE_STATUS = "UPDATE_ORGANISATION_FAVOURITE_STATUS";
export type UPDATE_ORGANISATION_FAVOURITE_STATUS = typeof UPDATE_ORGANISATION_FAVOURITE_STATUS;
