import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { toast } from "@common/toasts";
import { Payload } from "@neworbit/redux-helpers";
import * as actions from "./actiontypes";
import * as models from "./model";
import { VenueApi } from "./venueApi";
import { QuickAddEntities, UniversalQuickAddEntityDictionary } from "../quickAdd/model";
import { v4 } from "uuid";
import { BusinessLineType } from "@common/redux-helpers";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";

export type VenueAction =
    ({ type: actions.LOAD_VENUES_SUCCESS } & Payload<models.VenueListModel[]>)
    | ({ type: actions.CREATE_VENUE_SUCCESS } & Payload<models.VenueDetailModel>)
    | ({ type: actions.LOAD_VENUE_DETAIL_SUCCESS } & Payload<models.VenueDetailModel>)
    | ({ type: actions.SAVE_VENUE_SUCCESS } & Payload<models.VenueDetailModel>)
    | ({ type: actions.DELETE_VENUE_SUCCESS } & Payload<string>);

export const loadVenuesSuccess = (payload: models.VenueListModel[]): VenueAction => ({
    payload,
    type: actions.LOAD_VENUES_SUCCESS
});

export const createVenueSuccess = (payload: models.VenueDetailModel): VenueAction => ({
    payload,
    type: actions.CREATE_VENUE_SUCCESS
});

export const saveVenueSuccess = (payload: models.VenueDetailModel): VenueAction => ({
    payload,
    type: actions.SAVE_VENUE_SUCCESS
});

export const deleteVenueSuccess = (id: string): VenueAction => ({
    payload: id,
    type: actions.DELETE_VENUE_SUCCESS
});

export function createVenue(venue: models.VenueCreateEditModel, path: string, businessLineType: BusinessLineType, quickAdd: boolean = false) {
    return async (dispatch: Dispatch) => {
        const api = new VenueApi();
        const result = await loadAndTrack(dispatch, "createVenue", api.create(venue));
        dispatch(createVenueSuccess(result));
        if (quickAdd) {
            toast.success("Quick Add Venue Successful");
            dispatch(push(getBusinessLineTypePath(+businessLineType) + `/quickAdd/${UniversalQuickAddEntityDictionary[QuickAddEntities.Venue]}/${v4()}`));
        }
        else {
            dispatch(push(`${path}/${result.id}`));
        }

    };
}

export function saveVenue(venue: models.VenueCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new VenueApi();
        const result = await loadAndTrack(dispatch, "saveVenue", api.save(venue));

        dispatch(saveVenueSuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export function deleteVenue(id: string, path: string) {
    return async (dispatch: Dispatch) => {
        const api = new VenueApi();
        await loadAndTrack(dispatch, "deleteVenue", api.delete(id));
        dispatch(deleteVenueSuccess(id));
        dispatch(push(`${path}`));
    };
}

export function loadVenues({ options }: { options?: models.SearchOptions }) {
    return async (dispatch: Dispatch) => {
        const api = new VenueApi();
        const result = await loadAndTrack(dispatch, "loadVenues", api.getAll(options));
        dispatch(loadVenuesSuccess(result));
    };
}

export const loadVenueDetailSuccess = (venue: models.VenueDetailModel) => ({
    payload: venue,
    type: actions.LOAD_VENUE_DETAIL_SUCCESS
});

export function loadVenueDetail({ venueId }: { venueId: string }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        // eslint-disable-next-line eqeqeq
        if (venueId == null) {
            return;
        }

        const venue = getState().venues.filter(c => c.id === venueId)[0];

        if (venue === undefined
            || venue.detailUpdated === undefined
            || venue.detailUpdated.isBefore(venue.listUpdated)) {

            const action = venue === undefined ? createVenueSuccess : loadVenueDetailSuccess;

            const api = new VenueApi();
            const result = await loadAndTrack(dispatch, "loadVenueDetail", api.detail(venueId));
            dispatch(action(result));
        }
    };
}
