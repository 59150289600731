import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";
import * as React from "react";
import { Authorize } from "reauthorize";
import { Button, Checkbox, Grid, Icon, Input, InputOnChangeData, Popup } from "semantic-ui-react";
import { EventInstanceApi } from "../../eventInstanceApi";
import { AttachedDocumentListModel } from "../../model";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { EiSideBar } from "../EiSideBar";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { Spinner } from "@common/global";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { EventTypeFile } from "@common/crud/eventType/model";
import "./Files.scss";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { isBusinessLineTypeCorporateOrConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";
import { CorporateHomeRole } from "@common/auth/model";
import { ConfirmButton } from "@common/components";
import { toast } from "@common/toasts";

export const Files = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const businessLineType = useSelector(businessLineTypeSelector);
    const isAdminApp = useSelector(appSelector) === Apps.Admin;

    const [eventTypeFiles, setEventTypeFiles] = React.useState<EventTypeFile[]>([]);
    const [refreshing, setRefreshing] = React.useState(true);

    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [adminViewOnly, setAdminViewOnly] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<FileList>();
    const [loading, setLoading] = React.useState(false);
    const [attachedDocuments, setAttachedDocuments] = React.useState<AttachedDocumentListModel[]>([]);

    const isCorporate = React.useMemo(() => isWorkflowCorporate(eventInstance?.workflowType), [eventInstance]);

    React.useEffect(() => {
        const loadAttachedDocuments = async () => {
            const eventTypeResponse = await new EventInstanceApi().getFiles(eventInstance.id);
            setEventTypeFiles(eventTypeResponse || []);
            const eventInstanceResponse = await new EventInstanceApi().getAttachedDocuments(eventInstance.id);
            setAttachedDocuments(eventInstanceResponse);
            setRefreshing(false);
        };

        if (eventInstance?.id) {
            loadAttachedDocuments();
        }
    }, [eventInstance?.id]);

    const eventTypeColumns = (): TypedTableRowProps<EventTypeFile>[] => {
        return [
            {
                header: "Document Name",
                value: (f) => f.documentName,
            },
            {
                header: "Document Description",
                value: (f) => f.documentDescription,
            },
            {
                header: "Actions",
                value: (f) => (
                    <Grid>
                        <Grid.Row className="file-action-row">
                            <Button content="Preview" onClick={() => onEventTypePreviewClick(f)} className="link-button file-action" />
                        </Grid.Row>
                        <Grid.Row className="file-action-row">
                            <Button content="Download" onClick={() => onEventTypeDownloadClick(f)} className="link-button file-action" />
                        </Grid.Row>
                    </Grid>
                ),
            },
        ];
    };

    const eventInstanceColumns = (): TypedTableRowProps<AttachedDocumentListModel>[] => {
        return [
            {
                header: "Document Name",
                value: (f) => f.adminViewOnly
                    ? <>
                        {f.displayName ?? f.description}
                        {" "}
                        <Popup trigger={<Icon name="eye" size="large" />}>
                            <Popup.Content>
                                Admin View Only
                            </Popup.Content>
                        </Popup>
                    </>
                    : f.displayName ?? f.description
            },
            {
                header: "Document Description",
                value: (f) => f.description
            },
            {
                header: "Date Added",
                value: (f) => f.dateCreated?.format("DD/MM/YYYY"),
            },
            {
                header: "Actions",
                value: (f) => (
                    <Grid>
                        <Grid.Row className="file-action-row">
                            <Button content="Preview" onClick={() => onEventInstancePreviewClick(f)} className="link-button file-action" />
                        </Grid.Row>
                        <Grid.Row className="file-action-row">
                            <Button content="Download" onClick={() => onEventInstanceDownloadClick(f)} className="link-button file-action" />
                        </Grid.Row>
                        <Authorize authorize={[CorporateHomeRole]}>
                            <Grid.Row className="file-action-row">
                                <ConfirmButton
                                    className="link-button file-action"
                                    header={`Delete '${f.displayName ?? f.description}'`}
                                    content="Are you sure you want to delete this file?"
                                    onConfirm={() => onEventInstanceDeleteClick(f)}
                                >
                                    Delete
                                </ConfirmButton>
                            </Grid.Row>
                        </Authorize>
                    </Grid>
                ),
            },
        ];
    };

    const onEventTypePreviewClick = React.useCallback((file: EventTypeFile) =>
        window.open(`/api/eventtype/${eventInstance.eventTypeId}/files/${file.id}?viewOnly=true`),
    [eventInstance?.eventTypeId]);

    const onEventTypeDownloadClick = React.useCallback((file: EventTypeFile) =>
        window.open(`/api/eventtype/${eventInstance.eventTypeId}/files/${file.id}?viewOnly=false`),
    [eventInstance?.eventTypeId]);

    const onEventInstancePreviewClick = React.useCallback((file: AttachedDocumentListModel) =>
        window.open(`/api/eventInstance/${eventInstance?.id}/attached-documents/open/${file.id}`),
    [eventInstance?.id]);

    const onEventInstanceDownloadClick = React.useCallback((file: AttachedDocumentListModel) =>
        window.open(`/api/eventInstance/${eventInstance?.id}/attached-documents/download/${file.id}`),
    [eventInstance?.id]);

    const onEventInstanceDeleteClick = React.useCallback(async (file: AttachedDocumentListModel) => {
        try {
            await new EventInstanceApi().deleteAttachedDocument(eventInstance?.id, file.id);
            toast.success(`Successfully deleted '${file.displayName ?? file.description}'`);
            setAttachedDocuments(prev => prev.filter(f => f.id !== file.id));
        } catch (error) {
            toast.error(`Failed to delete '${file.displayName ?? file.description}'`);
        }
    }, [eventInstance?.id]);

    const sortedEventTypeFiles = React.useMemo(() => orderBy(eventTypeFiles, "startDate", "asc"), [eventTypeFiles]);
    const sortedEventInstanceDocuments = React.useMemo(() => orderBy(attachedDocuments, "dateCreated", "desc"), [attachedDocuments]);

    const onNameChange = React.useCallback((e: any, { value }: InputOnChangeData) => {
        setName(value);
    }, []);

    const onDescriptionChange = React.useCallback((e: any, { value }: InputOnChangeData) => {
        setDescription(value);
    }, []);

    const toggleAdminViewOnly = React.useCallback(() => setAdminViewOnly(!adminViewOnly), [adminViewOnly]);

    const onFileChange = React.useCallback((fileList: FileList) => {
        setFiles(fileList);
    }, []);

    const onUploadFile = React.useCallback(async () => {
        setLoading(true);
        const newDocument = await new EventInstanceApi().attachDocument(eventInstance.id, name, description, adminViewOnly, files[0]);
        setAttachedDocuments(prev => [...prev, newDocument]);
        setFiles(undefined);
        setName("");
        setDescription("");
        setAdminViewOnly(false);
        setLoading(false);
    }, [adminViewOnly, name, description, eventInstance.id, files]);

    const isStateValid = React.useMemo(() => files && name.length > 1 && description.length > 1, [name.length, description.length, files]);

    const content = () => {
        return (
            <div>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <h1>{`${isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "Product" : "Scheme"} Files`}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid stackable className="full-width no-padding-right">
                        <Grid.Row>
                            <Grid.Column width={16} className="no-padding-right">
                                <Spinner active={refreshing}>
                                    <TypedTable
                                        values={sortedEventTypeFiles}
                                        tableClassName="file-table"
                                        emptyValuesArrayMessage={"No files added"}
                                    >
                                        {eventTypeColumns()}
                                    </TypedTable>
                                </Spinner>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid>
                <h1>Course Files</h1>
                <Grid>
                    {(isAdminApp || isCorporate) && (
                        <>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input type="text" value={name} placeholder="Name" onChange={onNameChange} fluid />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input type="text" value={description} placeholder="Description" onChange={onDescriptionChange} fluid />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {isAdminApp && <Checkbox label="Admin view only" onClick={toggleAdminViewOnly} checked={adminViewOnly} />}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <FileUploadInput
                                        fileList={files}
                                        uploadLabel="Add File"
                                        disabled={description.trim().length === 0}
                                        onChange={onFileChange}
                                    />
                                    <Button loading={loading} content="Upload" disabled={!isStateValid} onClick={onUploadFile} />
                                </Grid.Column>
                                <Grid.Column width={13}>
                                    {(files && files.length > 0) &&
                                        <span className="file-name">
                                            {`File to be uploaded: ${files[0].name}`}
                                        </span>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                    <Grid.Row>
                        <Grid.Column width={16} className="no-padding-right">
                            <Spinner active={refreshing}>
                                <TypedTable
                                    values={sortedEventInstanceDocuments}
                                    tableClassName="file-table"
                                    emptyValuesArrayMessage={"No files added"}
                                >
                                    {eventInstanceColumns()}
                                </TypedTable>
                            </Spinner>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    };

    return <EiSideBar children={content()} />;
};
