import * as React from "react";

interface LabelAndValueProps {
    label: JSX.Element | string;
    labelClass?: string;
    value?: React.ReactNode;
    valueClass?: string;
}

export const LabelAndValue = (props: LabelAndValueProps) => {
    const labelClassNames = ["label"];
    const valueClassNames = ["value"];

    if (props.labelClass) {
        labelClassNames.push(props.labelClass);
    }

    if (props.valueClass) {
        valueClassNames.push(props.valueClass);
    }

    return  (
        <div className="label-and-value">
            <p className={labelClassNames.join(" ")}>{props.label}</p>
            <div className={valueClassNames.join(" ")}>{props.value || ""}</div>
        </div>
    );
};
