import axios from "axios";
import { WorkflowTypeEnum } from "../eventType/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { RecipientTypeEnum } from "../common/RecipientTypeEnum";

class PostmarkTemplateInfoApi {

    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/postmarkTemplateInfo";
    }

    public async fieldsMatchingCriteria(postmarkTemplates: string, workflowType?: WorkflowTypeEnum, deliveryType?: DeliveryTypeEnum,
        recipientType?: RecipientTypeEnum): Promise<string[]> {
        const response = await axios.post(`${this.apiUrl}/${postmarkTemplates}`, { workflowType, deliveryType, recipientType });
        const data = response.data as string[];
        return data;
    }

    public async info(postmarkTemplates: string, settingName: string): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/${postmarkTemplates}/${settingName}`);
        const data = response.data as string;
        return data;
    }
}

export { PostmarkTemplateInfoApi };
