import * as React from "react";
import { EventTrainer, PracticalEventTrainer, TrainerAvailabilityRoleTypeEnum } from "../../model";
import moment from "moment";
import { ModuleType, ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { useSelector } from "react-redux";
import { currentUserSelector } from "@common/auth";
import { TrainerRole, TtcPlannerRole } from "@common/auth/model";
import { ModuleAvailabilityModalForPlanner } from "./ModuleAvailabilityModalForPlanner";
import { ModuleAvailabilityModalForTrainer } from "./ModuleAvailabilityModalForTrainer";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { isWorkflowCorporate } from "@common/global/CommonHelpers";

export interface AvailabilityModalProps {
    eventInstanceId: string;
    startDate: moment.Moment;
    trainers?: EventTrainer[];
    practicalTrainers?: PracticalEventTrainer[];
    monitorTrainers?: EventTrainer[];
    canHaveMoreThanOneTrainer?: boolean;
    moduleType: string;
    adminAvailableRoles: TrainerAvailabilityRoleTypeEnum[];
    eventInstanceDeliveryType: DeliveryTypeEnum;
    workflowType: WorkflowTypeEnum;
}

export const AvailabilityModal: React.FC<AvailabilityModalProps> = (
    {
        eventInstanceId,
        startDate,
        canHaveMoreThanOneTrainer,
        moduleType,
        trainers,
        practicalTrainers,
        monitorTrainers,
        adminAvailableRoles,
        eventInstanceDeliveryType,
        workflowType
    }) => {

    const currentUser = useSelector(currentUserSelector);
    const isPlanner = currentUser && currentUser.roles?.includes(TtcPlannerRole);
    const isTrainer = currentUser && currentUser.roles?.includes(TrainerRole);

    const isCurrentUserTheoryTrainer = trainers?.some(trainer => trainer.id === currentUser?.id);

    return (
        <>
            {isPlanner && (moduleType === ModuleType[ModuleTypeEnum.Theory] ||
                moduleType === ModuleType[ModuleTypeEnum.Both]) &&
                <ModuleAvailabilityModalForPlanner
                    eventInstanceId={eventInstanceId}
                    trainerAvailabilityRoleType={TrainerAvailabilityRoleTypeEnum.Theory}
                    roleAvailability={adminAvailableRoles}
                    canHaveMoreThanOneTrainer={canHaveMoreThanOneTrainer}
                    eventInstanceDeliveryType={eventInstanceDeliveryType}
                />
            }
            {(isWorkflowCorporate(workflowType) && (isPlanner && (moduleType === ModuleType[ModuleTypeEnum.Practical] ||
                moduleType === ModuleType[ModuleTypeEnum.Both]))) &&
                <ModuleAvailabilityModalForPlanner
                    eventInstanceId={eventInstanceId}
                    trainerAvailabilityRoleType={TrainerAvailabilityRoleTypeEnum.AnyPractical}
                    roleAvailability={adminAvailableRoles}
                    canHaveMoreThanOneTrainer={canHaveMoreThanOneTrainer}
                    eventInstanceDeliveryType={eventInstanceDeliveryType}
                />
            }
            {(!isWorkflowCorporate(workflowType) && (isPlanner && (moduleType === ModuleType[ModuleTypeEnum.Practical] ||
                moduleType === ModuleType[ModuleTypeEnum.Both]))) &&
                <ModuleAvailabilityModalForPlanner
                    eventInstanceId={eventInstanceId}
                    trainerAvailabilityRoleType={TrainerAvailabilityRoleTypeEnum.Manual}
                    roleAvailability={adminAvailableRoles}
                    canHaveMoreThanOneTrainer={canHaveMoreThanOneTrainer}
                    eventInstanceDeliveryType={eventInstanceDeliveryType}
                />
            }
            {(!isWorkflowCorporate(workflowType) && (isPlanner && (moduleType === ModuleType[ModuleTypeEnum.Practical] ||
                moduleType === ModuleType[ModuleTypeEnum.Both]))) &&
                <ModuleAvailabilityModalForPlanner
                    eventInstanceId={eventInstanceId}
                    trainerAvailabilityRoleType={TrainerAvailabilityRoleTypeEnum.Automatic}
                    roleAvailability={adminAvailableRoles}
                    canHaveMoreThanOneTrainer={canHaveMoreThanOneTrainer}
                    eventInstanceDeliveryType={eventInstanceDeliveryType}
                />
            }
            {(isTrainer && isCurrentUserTheoryTrainer && !isWorkflowCorporate(workflowType) &&
                moduleType === ModuleType[ModuleTypeEnum.Theory]) &&
                <ModuleAvailabilityModalForTrainer
                    eventInstanceId={eventInstanceId}
                    moduleType={ModuleTypeEnum.Theory}
                    currentUser={currentUser}
                    startDate={startDate}
                    monitorTrainers={monitorTrainers}
                    practicalTrainers={practicalTrainers}
                    trainers={trainers}
                />
            }
        </>
    );
};
