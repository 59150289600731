/* eslint-disable max-lines */
import * as React from "react";
import { Authorize } from "reauthorize";
import { TrainerRole, TtcClientAdvisorRole, } from "@common/auth/model";
import {
    EventTrainer,
    OtherTrainerTypeCategory,
    PracticalEventTrainer,
    TrainerType
} from "../../model";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import {
    updateEventInstanceTrainerExternalAssessmentDue,
    updateEventInstanceTrainerFee,
    updateEventInstanceTrainerFeeNote,
    updateEventInstanceTrainerSundries,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { getCurrencyFormat } from "@common/formating";
import { Link } from "redux-little-router";
import { baseEventManagementSelector, eventInstanceSelector } from "../../selectors";
import { CancelTrainer } from "./CancelTrainer";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { FeeNoteModal } from "./FeeNoteModal";
import { CarTypeEdit } from "./CarTypeEdit";
import { SwapTrainer } from "./SwapTrainer";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import { TrainerApi } from "@common/crud/trainer";
import { RevertTrainerSwapModal } from "./RevertTrainerSwapModal";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { TrainerDetailsContactNumbers } from "./TrainerDetailsContactNumbers";
import { OtherRoleEdit } from "@common/crud/eventInstance/components/details/OtherRoleEdit";
import { TrainerRoleFeeView } from "./TrainerRoleFeeView";
import { currentUserSelector } from "@common/auth";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventTypeCategory } from "@common/crud/attendee/model";

export interface TrainerDetailsProps {
    eventInstanceId: string;
    trainerType: TrainerType;
    trainer: EventTrainer | PracticalEventTrainer;
    bookedRcCoverTrainerIds?: string[];
    isPoliceOrCourtWorkflow: boolean;
    eventTypeCategory: EventTypeCategory;
}

const isNullUndefinedOrEmpty = (value: string) => value === undefined || value === null || value === "";

export const hrefRow = (name: string, value: string, href: string) => {
    if (isNullUndefinedOrEmpty(value)) {
        return null;
    }
    return (
        <tr>
            <th>{name}</th>
            <td>
                <a className="text-wrap" href={href}>{value}</a>
            </td>
        </tr>);
};

export const row = (name: string, value: string) => {
    if (isNullUndefinedOrEmpty(value)) {
        return null;
    }
    return (
        <tr>
            <th>{name}</th>
            <td>
                {value}
            </td>
        </tr>);
};

export const TrainerDetails: React.FC<TrainerDetailsProps> = ({
    eventInstanceId,
    trainerType,
    trainer,
    isPoliceOrCourtWorkflow,
    eventTypeCategory,
    bookedRcCoverTrainerIds = [] }) => {
    const mayHaveCarType = trainerType === TrainerType.PracticalTrainer;
    const carType = mayHaveCarType ? (trainer as PracticalEventTrainer).carType : "";
    const [isInFeeEditMode, setIsInFeeEditMode] = React.useState<boolean>(false);
    const [fee, setFee] = React.useState<number>(trainer.fee);
    const [feeError, setFeeError] = React.useState<boolean>(false);

    const [isInSundriesEditMode, setIsInSundriesEditMode] = React.useState<boolean>(false);
    const [sundries, setSundries] = React.useState<number>(trainer.sundries);
    const [sundriesError, setSundriesError] = React.useState<boolean>(false);

    const [externalAssessmentDue, setExternalAssessmentDue] = React.useState<boolean>(trainer.externalAssessmentDue);
    const [isInExternalAssessmentDueEditMode, setIsInExternalAssessmentDueEditMode] = React.useState<boolean>(false);

    const [toggleFeeNoteModal, setToggleFeeNoteModal] = React.useState(false);

    const [subcontractedByTrainer, setSubcontractedByTrainer] = React.useState<TrainerDetailModel>(null);
    const [isRevertSwapModalOpen, setIsRevertSwapModalOpen] = React.useState(false);

    const dispatch = useDispatch();
    const path = useSelector(baseEventManagementSelector);
    const isAdminApp = useSelector(appSelector) === Apps.Admin;
    const config = useSelector(appSettingsSelector);
    const currentUser = useSelector(currentUserSelector);
    const trainerIsUser = currentUser?.id === trainer.id;

    const eventInstance = useSelector(eventInstanceSelector);
    const canTrainerEditSundries = React.useMemo(() => eventInstance?.workflowType === WorkflowTypeEnum.BusinessDriver
        && !eventInstance?.cancelled && !eventInstance?.certificatesProcessedDate, [eventInstance]);

    React.useEffect(() => {
        const getSubcontractedByTrainer = async () => {
            setSubcontractedByTrainer(await new TrainerApi().detail(trainer.subcontractingProcess.originalTrainerId));
        };

        if (trainer?.subcontractingProcess?.isActive) {
            getSubcontractedByTrainer();
        }
    }, [trainer]);

    const getEditButtonIcon = React.useCallback((isInEditMode: boolean) => isInEditMode ? "check" : "pencil", []);

    const additionalFeesRow = () => {
        return (
            <tr>
                <th>Additional Fees:</th>
                <td>
                    {getCurrencyFormat(trainer.additionalFeesTotal)}
                </td>
            </tr>);
    };

    const handleFeeChange = React.useCallback((value: number, valid: boolean) => {
        setFee(value);
        setFeeError(!valid);
    }, []);

    const handleSundriesChange = React.useCallback((value: number, valid: boolean) => {
        setSundries(value);
        setSundriesError(!valid);
    }, []);

    const handleExternalAssessmentDueChange = React.useCallback((_: any, { value }: { value: boolean }) => {
        setExternalAssessmentDue(value);
    }, []);

    const feeRow = () => {
        return (
            <tr>
                <th>Base Fee:</th>
                <td>
                    {isInFeeEditMode ?
                        <CurrencyInput
                            value={fee}
                            onChange={handleFeeChange}
                        /> : getCurrencyFormat(fee)}
                    <Button
                        icon={getEditButtonIcon(isInFeeEditMode)}
                        size="mini"
                        disabled={isInFeeEditMode && feeError}
                        onClick={updateTrainerFee}
                    />
                    {isInFeeEditMode && <Button icon="delete" color="red" size="mini" onClick={cancelFeeEdit} />}
                </td>
            </tr>);
    };

    const sundriesRow = () => {
        return (
            <tr>
                <th>Sundries:</th>
                <td>
                    {isInSundriesEditMode ?
                        <CurrencyInput
                            value={sundries}
                            onChange={handleSundriesChange}
                        /> : getCurrencyFormat(sundries)}
                    <Button
                        icon={getEditButtonIcon(isInSundriesEditMode)}
                        size="mini"
                        disabled={isInSundriesEditMode && sundriesError}
                        onClick={updateTrainerSundries}
                    />
                    {isInSundriesEditMode && <Button icon="delete" color="red" size="mini" onClick={cancelSundriesEdit} />}
                </td>
            </tr>);
    };

    function onToggleFeeNoteModal() {
        setToggleFeeNoteModal(!toggleFeeNoteModal);
    }

    function updateTrainerFeeNote(feeNote: string) {
        if (toggleFeeNoteModal) {
            dispatch(updateEventInstanceTrainerFeeNote(eventInstanceId, trainer.id, trainerType, feeNote));
        }
        setToggleFeeNoteModal(!toggleFeeNoteModal);
    }

    function openRevertSwapModal() {
        setIsRevertSwapModalOpen(true);
    }

    const feeNoteRow = () => {
        return (
            <tr>
                <th>Fee Note:</th>
                <td>
                    <span>{trainer.feeNote}</span>&nbsp;
                    <FeeNoteModal
                        toggle={toggleFeeNoteModal}
                        setToggle={setToggleFeeNoteModal}
                        feeNote={trainer.feeNote}
                        updateTrainerFeeNote={updateTrainerFeeNote}
                    />
                    <Button icon={getEditButtonIcon(toggleFeeNoteModal)} size="mini" onClick={onToggleFeeNoteModal} />
                </td>
            </tr>);
    };

    const syncedWithDorsStatusRow = () => {
        return (<tr>
            <th>DORS Status:</th>
            {trainer?.syncedWithDors?<td>Synced</td> :<td>Not Synced</td>}
        </tr>
        );
    };

    const externalAssessmentDueRow = () => {
        return (
            <tr>
                <th>External Assessment Due:</th>
                <td>
                    {isInExternalAssessmentDueEditMode ?
                        <Dropdown
                            value={externalAssessmentDue}
                            options={[{ value: true, text: "Yes" }, { value: false, text: "No" }]}
                            onChange={handleExternalAssessmentDueChange}
                            selection
                            compact
                        />
                        : trainer.externalAssessmentDue ? "Yes" : "No"}
                    {" "}
                    <Button
                        icon={getEditButtonIcon(isInExternalAssessmentDueEditMode)}
                        size="mini"
                        onClick={updateExternalAssessmentDue}
                    />
                    {isInExternalAssessmentDueEditMode &&
                        <Button icon="delete" color="red" size="mini" onClick={cancelExternalAssessmentDueEdit} />}
                </td>
            </tr>);
    };

    const subcontractedByTrainerRow = () => (
        <tr>
            <th>Subcontracted By:</th>
            <td>
                <a href={`${path}/trainers/${subcontractedByTrainer?.id}`}>{subcontractedByTrainer?.fullName}</a>
                &nbsp;
                <Icon name="undo" link title="Revert the subcontracting swap" onClick={openRevertSwapModal} />
            </td>
        </tr>
    );

    const updateTrainerFee = React.useCallback(() => {
        if (feeError) {
            return;
        }

        if (isInFeeEditMode) {
            dispatch(updateEventInstanceTrainerFee(eventInstanceId, trainer.id, trainerType, fee));
        }
        setIsInFeeEditMode(!isInFeeEditMode);
    }, [isInFeeEditMode, feeError, dispatch, eventInstanceId, trainer.id, trainerType, fee]);

    const cancelFeeEdit = React.useCallback(() => {
        setIsInFeeEditMode(false);
        setFee(trainer.fee);
        setFeeError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateTrainerSundries = React.useCallback(() => {
        if (sundriesError) {
            return;
        }

        if (isInSundriesEditMode) {
            dispatch(updateEventInstanceTrainerSundries(eventInstanceId, trainer.id, trainerType, sundries));
        }
        setIsInSundriesEditMode(!isInSundriesEditMode);
    }, [isInSundriesEditMode, sundriesError, dispatch, eventInstanceId, trainer.id, trainerType, sundries]);

    const updateExternalAssessmentDue = React.useCallback(() => {
        if (isInExternalAssessmentDueEditMode) {
            dispatch(updateEventInstanceTrainerExternalAssessmentDue(eventInstanceId, trainer.id, trainerType, externalAssessmentDue));
        }
        setIsInExternalAssessmentDueEditMode(!isInExternalAssessmentDueEditMode);
    }, [dispatch, eventInstanceId, externalAssessmentDue, isInExternalAssessmentDueEditMode, trainer.id, trainerType]);

    const cancelSundriesEdit = React.useCallback(() => {
        setIsInSundriesEditMode(false);
        setSundries(trainer.sundries);
        setSundriesError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cancelExternalAssessmentDueEdit = React.useCallback(() => {
        setIsInExternalAssessmentDueEditMode(false);
        setExternalAssessmentDue(trainer.externalAssessmentDue);
    }, [trainer.externalAssessmentDue]);

    const isMonitorOrObserver = trainerType === TrainerType.MonitorTrainer || trainerType === TrainerType.OtherTrainer;

    const allowSubcontracting = () => {
        switch (eventTypeCategory) {
            case EventTypeCategory.ConstructionOrganisation:
                return config.featureSettings.constructionSubcontracting;
            case EventTypeCategory.CorporateOrganisation:
                return config.featureSettings.commercialSubcontracting;
            case EventTypeCategory.Dors:
            case EventTypeCategory.Ddrs:
                return config.featureSettings.policeAndCourtSubcontracting;
            default:
                return false;
        }
    };

    const showSwapTrainer = () => {
        if (!isMonitorOrObserver) {
            return allowSubcontracting();
        }
        else {
            return false;
        }
    };

    return (
        <>
            <RevertTrainerSwapModal
                isOpen={isRevertSwapModalOpen}
                setIsOpen={setIsRevertSwapModalOpen}
                eventInstanceId={eventInstanceId}
                trainer={trainer}
                fee={fee}
                trainerType={trainerType}
            />
            <div className="trainer-details">
                <div className="trainer-details-heading">
                    {isAdminApp ?
                        <div className="trainer-details-heading-trainer-name-rc-cover">
                            <Link href={`${path}/trainers/${trainer.id}`}>{trainer.name}</Link>
                            {trainerType === TrainerType.TheoryTrainer && bookedRcCoverTrainerIds.includes(trainer.id) && <div>RC Cover</div>}
                        </div>
                        : <div className="trainer-details-heading-trainer-name-rc-cover">{trainer.name}</div>}
                    {(carType || trainer?.otherTrainerTypeCategory !== null) && (
                        <div className="trainer-details-secondary-heading-container">
                            {(carType && carType !== "Any") && <span className="trainer-details-secondary-heading">{carType}</span>}
                            {
                                trainer?.otherTrainerTypeCategory !== null &&
                                <span className="trainer-details-secondary-heading">
                                    {OtherTrainerTypeCategory[trainer?.otherTrainerTypeCategory]}
                                </span>
                            }
                        </div>
                    )}
                </div>
                <div>
                    <table>
                        <tbody>
                            <TrainerDetailsContactNumbers trainer={trainer} />
                            <Authorize authorize={TrainerRole}>
                                {trainerIsUser &&
                                <TrainerRoleFeeView
                                    fee={trainer.fee}
                                    additionalFeesTotal={trainer.additionalFeesTotal}
                                    sundries={trainer.sundries}
                                    canTrainerEditSundries={canTrainerEditSundries}
                                    isInSundriesEditMode={isInSundriesEditMode}
                                    sundriesError={sundriesError}
                                    handleSundriesChange={handleSundriesChange}
                                    updateTrainerSundries={updateTrainerSundries}
                                    cancelSundriesEdit={cancelSundriesEdit}
                                />}
                            </Authorize>
                            <Authorize authorize={TtcClientAdvisorRole}>
                                {hrefRow("Email:", trainer.email, `mailto:${trainer.email}`)}
                                {feeRow()}
                                {additionalFeesRow()}
                                {sundriesRow()}
                                {feeNoteRow()}
                                {isPoliceOrCourtWorkflow && row("NDORS License Number:", trainer.ndorsLicenceNumber)}
                                {isPoliceOrCourtWorkflow && trainer?.syncedWithDors !== null && syncedWithDorsStatusRow()}
                                {isPoliceOrCourtWorkflow &&
                                    (trainerType === TrainerType.PracticalTrainer || trainerType === TrainerType.TheoryTrainer) && externalAssessmentDueRow()}
                                {trainer?.subcontractingProcess?.isActive && allowSubcontracting() &&
                                    !isMonitorOrObserver && subcontractedByTrainerRow()}
                                <CarTypeEdit
                                    trainerId={trainer?.id}
                                    eventInstanceId={eventInstanceId}
                                    carType={carType}
                                    isTrainerSubcontracted={trainer?.subcontractingProcess?.isActive}
                                />
                                {
                                    trainerType === TrainerType.OtherTrainer && trainer?.otherTrainerTypeCategory !== null &&
                                    <OtherRoleEdit
                                        trainerId={trainer?.id}
                                        eventInstanceId={eventInstanceId}
                                        otherTrainerTypeCategory={trainer?.otherTrainerTypeCategory}
                                        isFeeNonStandard={trainer?.isFeeNonStandard}
                                        getEditButtonIcon={getEditButtonIcon}
                                    />
                                }
                            </Authorize>
                            {isAdminApp &&
                                <tr>
                                    <td>
                                        <CancelTrainer
                                            eventInstanceId={eventInstanceId}
                                            trainerId={trainer.id}
                                            trainerType={trainerType}
                                            exteralAssessmentDue={trainer.externalAssessmentDue}
                                        />
                                        {showSwapTrainer() &&
                                            <SwapTrainer trainer={trainer} trainerType={trainerType} />}
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
