import { HistoryRecord } from "@common/history/model";
import React from "react";

interface Props {
    historyRecord: HistoryRecord;
}

export const OrganisationCommsHistoryNote = ({ historyRecord }: Props) => {
    return (
        <div>
            <div>
                Message sent: {historyRecord.properties.MessageSentDateTime}
            </div>
            <div>
                Sent by: {historyRecord.properties.EmailFrom}
            </div>
            {historyRecord.properties.EmailTo && (
                <div>
                    Sent to: {historyRecord.properties.EmailTo}
                </div>
            )}
            <div>
                Template name: {historyRecord.properties.EmailTemplateName}
            </div>
        </div>
    );
};
