import React from "react";
import { Input } from "@neworbit/simpleui-input";
import { Button, Grid } from "semantic-ui-react";
import { phoneNumberWithSpaces } from "@common/validation";

interface ManagerContactRowProps {
    managerContact: { name: string; telephone: string; email: string };
    index: number;
    showErrors: boolean;
    updateManagerValue: (index: number, field: string, value: string, valid: boolean) => void;
    removeManager: (index: number) => void;
}

export const ManagerContactRow = (props: ManagerContactRowProps) => {
    const { managerContact, index, showErrors, updateManagerValue, removeManager } = props;

    const updateName = React.useCallback((value: string, valid: boolean) => {
        updateManagerValue(index, "name", value, valid);
    }, [index, updateManagerValue]);

    const updateEmail = React.useCallback((value: string, valid: boolean) => {
        updateManagerValue(index, "email", value, valid);
    }, [index, updateManagerValue]);

    const updateTelephone = React.useCallback((value: string, valid: boolean) => {
        updateManagerValue(index, "telephone", value, valid);
    }, [index, updateManagerValue]);

    const onRemoveClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        removeManager(index);
    }, [index, removeManager]);

    return (
        <>
            <Grid.Row>
                <Grid.Column>
                    <Input.Text
                        value={managerContact.name}
                        label="Name"
                        showErrors={showErrors}
                        onChange={updateName}
                    />
                    <Input.Text
                        value={managerContact.telephone}
                        label="Telephone"
                        validation={phoneNumberWithSpaces()}
                        showErrors={showErrors}
                        onChange={updateTelephone}
                    />
                    <Input.Email
                        value={managerContact.email}
                        label="Email"
                        showErrors={showErrors}
                        onChange={updateEmail}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Button onClick={onRemoveClick} content="- Remove manager" className="link-button vertical-center" />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};
