import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { Button, Checkbox, CheckboxProps, Grid, Popup } from "semantic-ui-react";
import { Sessions, SessionLabels, Availability, AvailableOptions, AvailabilityTypeOptions, AvailabilityType } from "../model";

export interface SettingsBarConfiguration {
    value: boolean;
    enumValue: number;
}

export interface SettingsBarProps {
    settings: SettingsBarConfiguration[];
    onChange: (value: SettingsBarConfiguration[]) => void;
    planningStarted: boolean;
    cancel: () => void;
    save: () => void;
    availabilityType: AvailabilityType;
    onAvailabilityTypeChange: React.Dispatch<React.SetStateAction<AvailabilityType>>;
}

export const SettingsBar: React.FC<SettingsBarProps> = (props) => {
    const [allDayChecked, setAllDayChecked] = React.useState<boolean>(false);
    const [disable, setDisable] = React.useState<boolean>();
    const [available, setAvailable] = React.useState<boolean>(true);

    const handleOnChange = (enumValue?: Sessions) => (value: boolean) => {
        const settings = props.settings.filter(x => x.enumValue !== enumValue);
        props.onChange([...settings, { enumValue, value }]);
    };

    const handleAllDayChange = React.useCallback((value: boolean) => {
        const existingUnavailableValue = props.settings.find(x => x.enumValue === Sessions.Unavailable).value;
        const settings =
        [
            ...props.settings
                .filter(s => s.enumValue !== Sessions.Unavailable)
                .map(s => ({ ...s, value })),
            { enumValue: Sessions.Unavailable, value: existingUnavailableValue }
        ];

        props.onChange(settings);
        setAllDayChecked(value);
        setDisable(value);
    },[props]);

    const handleOnAvailabilityCheck = (availability: Availability) => (value: boolean) => {
        if (availability === Availability.Unavailable) {
            handleUnavailable(value);
            setAvailable(!value);
        } else {
            handleUnavailable(!value);
            setAvailable(value);
        }
    };

    const handleUnavailable = (value: boolean) => {
        const settings = props.settings.filter(x => x.enumValue !== Sessions.Unavailable);
        props.onChange([...settings, { enumValue: Sessions.Unavailable, value }]);
    };

    const { onAvailabilityTypeChange, availabilityType } = props;

    function handleAvailabilityChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
        return onAvailabilityTypeChange(data.value as AvailabilityType);
    }

    return (
        <Grid className="setting-row">
            <Grid.Row>
                <h3>Update your availability</h3>
            </Grid.Row>
            <Grid.Row>
                <h4><b>Sessions</b></h4>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={SessionLabels[Sessions.SessionOne]}
                        onChange={handleOnChange(Sessions.SessionOne)}
                        value={props.settings.find(x => x.enumValue === Sessions.SessionOne).value}
                        disabled={disable}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={SessionLabels[Sessions.SessionTwo]}
                        onChange={handleOnChange(Sessions.SessionTwo)}
                        value={props.settings.find(x => x.enumValue === Sessions.SessionTwo).value}
                        disabled={disable}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={SessionLabels[Sessions.SessionThree]}
                        onChange={handleOnChange(Sessions.SessionThree)}
                        value={props.settings.find(x => x.enumValue === Sessions.SessionThree).value}
                        disabled={disable}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={SessionLabels[Sessions.SessionFour]}
                        onChange={handleOnChange(Sessions.SessionFour)}
                        value={props.settings.find(x => x.enumValue === Sessions.SessionFour).value}
                        disabled={disable}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={"All Day"}
                        onChange={handleAllDayChange}
                        value={allDayChecked}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <h4><b>Delivery Type</b></h4>
            </Grid.Row>
            <Grid.Row className="remove-top-padding">
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Checkbox
                        label={AvailabilityTypeOptions[AvailabilityType.Classroom]}
                        name="availability"
                        value={AvailabilityType.Classroom}
                        onChange={handleAvailabilityChange}
                        checked={available && availabilityType === AvailabilityType.Classroom}
                        disabled={!available}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Checkbox
                        label={AvailabilityTypeOptions[AvailabilityType.Digital]}
                        name="availability"
                        value={AvailabilityType.Digital}
                        onChange={handleAvailabilityChange}
                        checked={available && availabilityType === AvailabilityType.Digital}
                        disabled={!available}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Checkbox
                        label={AvailabilityTypeOptions[AvailabilityType.ClassroomAndDigital]}
                        name="availability"
                        value={AvailabilityType.ClassroomAndDigital}
                        checked={available && availabilityType === AvailabilityType.ClassroomAndDigital}
                        onChange={handleAvailabilityChange}
                        disabled={!available}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <h4><b>Availability</b></h4>
            </Grid.Row>
            <Grid.Row className="remove-top-padding">
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={AvailableOptions[Availability.Available]}
                        value={available}
                        onChange={handleOnAvailabilityCheck(Availability.Available)}
                    />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                    <Input.Checkbox
                        label={AvailableOptions[Availability.Unavailable]}
                        onChange={handleOnAvailabilityCheck(Availability.Unavailable)}
                        value={!available}
                    />
                </Grid.Column>
                <Grid.Column computer={2} tablet={8} mobile={8}>
                    <Button onClick={props.cancel}>Cancel</Button>
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={8}>
                    { props.planningStarted ? <Popup
                        content="Calendar cannot be edited whilst Admin are planning courses."
                        trigger={<Button color="grey">Save</Button>}
                    /> : <Button onClick={props.save}>Save</Button>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
