import * as React from "react";
import { NewNote } from "@common/notes/components/NewNote";
import { Grid, Header } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { ChangeEventType, HistoryRecord } from "../model";
import { HistoryFilter } from "./HistoryFilter";
import { HistoryContent } from "./HistoryContent";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { EventTypeCategory } from "@common/crud/attendee/model";

export interface NewHistoryBaseProps {
    correlationId: string;
    historyRecords: HistoryRecord[];
    relatedTo: TaskRelationEnum;
    showDetailsDefault: boolean;
    eventTypeCategory?: EventTypeCategory;
}

const dorsExpectedFilters = [
    { type: ChangeEventType.Note, display: true },
    { type: ChangeEventType.Payment, display: true },
    { type: ChangeEventType.Booking, display: true },
    { type: ChangeEventType.Comms, display: true },
    { type: ChangeEventType.DORS, display: true },
    { type: ChangeEventType.ExpiryDate, display: false },
    { type: ChangeEventType.RegisterUpdates, display: true },
    { type: ChangeEventType.DetailsChanged, display: true },
    { type: ChangeEventType.Nudge, display: true },
    { type: ChangeEventType.Genesys, display: true },
    { type: ChangeEventType.Audit, display: true },
    { type: ChangeEventType.AlwaysShown, display: false },
    { type: ChangeEventType.FlexiPayExpiryDate, display: false },
];

const drinkDriveExpectedFilters = [
    { type: ChangeEventType.Note, display: true },
    { type: ChangeEventType.Payment, display: true },
    { type: ChangeEventType.Booking, display: true },
    { type: ChangeEventType.Comms, display: true },
    { type: ChangeEventType.ReferralAdded, display: false },
    { type: ChangeEventType.RegisterUpdates, display: true },
    { type: ChangeEventType.DetailsChanged, display: true },
    { type: ChangeEventType.Nudge, display: true },
    { type: ChangeEventType.Audit, display: true },
    { type: ChangeEventType.AlwaysShown, display: false },
    { type: ChangeEventType.FlexiPayExpiryDate, display: false },
];

const corporateOrganisationExpectedFilters = [
    { type: ChangeEventType.Note, display: true },
    { type: ChangeEventType.CourseCreated, display: true },
    { type: ChangeEventType.Comms, display: true }
];

export const NewHistoryBaseComponent: React.FC<NewHistoryBaseProps> = ({
    correlationId, historyRecords, relatedTo, showDetailsDefault, eventTypeCategory
}) => {
    const expectedFilters = React.useMemo(() => {
        switch (eventTypeCategory) {
            case EventTypeCategory.Dors:
                return dorsExpectedFilters;
            case EventTypeCategory.Ddrs:
                return drinkDriveExpectedFilters;
            case EventTypeCategory.CorporateOrganisation:
                return corporateOrganisationExpectedFilters;
            default:
                return [];
        }
    }, [eventTypeCategory]);

    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        eventTypeCategory === EventTypeCategory.CorporateOrganisation
            ? [ ...expectedFilters.map(filter => filter.type)]
            : expectedFilters
                .map(filter => filter.type)
                .filter(filter =>
                    filter === ChangeEventType.ExpiryDate ||
                    filter === ChangeEventType.ReferralAdded ||
                    filter === ChangeEventType.Note ||
                    filter === ChangeEventType.Payment ||
                    filter === ChangeEventType.AlwaysShown ||
                    filter === ChangeEventType.FlexiPayExpiryDate)
    );

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(changeSelectAllByEventTypeCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters?.length]);

    const changeSelectAllByEventTypeCategory = (): string => {
        switch (eventTypeCategory) {
            case EventTypeCategory.CorporateOrganisation:
                return filters.length > 2 ? "Deselect All" : "Select All";
            default:
                return filters.length > 4 ? "Deselect All" : "Select All";
        }
    };

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const getFiltersByEventTypeCategory = (): ChangeEventType[] => {
        switch (eventTypeCategory) {
            case EventTypeCategory.CorporateOrganisation:
                return filters.length > 2 ? [] : [...expectedFilters.map(filter => filter.type)];
            default:
                return filters.length > 4
                    ? [ChangeEventType.ExpiryDate, ChangeEventType.ReferralAdded, ChangeEventType.AlwaysShown, ChangeEventType.FlexiPayExpiryDate]
                    : [...expectedFilters.map(filter => filter.type)];
        }
    };

    const selectFilters = React.useCallback(() => {
        const newFilters = getFiltersByEventTypeCategory();
        setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expectedFilters, filters?.length]);

    return (
        <div>
            <div>
                <NewNote correlationId={correlationId} relatedTo={relatedTo} />
            </div>
            <div>
                <Header size="large">Case history</Header>
                <Grid>
                    <Grid.Column width={3}>
                        <Grid.Row>
                            Filters by group:
                        </Grid.Row>
                        {expectedFilters.map(eventType => eventType.display ?
                            <HistoryFilter
                                key={eventType.type}
                                eventType={eventType.type}
                                value={filters.includes(eventType.type)}
                                onChange={addOrRemove}
                            /> : null)}
                        <Authorize authorize={PoliceAndDdrsAdminRole}>
                            <HistoryFilter
                                key={ChangeEventType.DataModification}
                                eventType={ChangeEventType.DataModification}
                                value={filters.includes(ChangeEventType.DataModification)}
                                onChange={addOrRemove}
                                classNames={eventTypeCategory === EventTypeCategory.CorporateOrganisation ? ["no-padding-left", "no-padding-right"] : []}
                            />
                        </Authorize>
                        <Grid.Row>
                            <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <HistoryContent showDetailsDefault={showDetailsDefault} entries={historyRecords?.filter(
                            record => filters.includes(record.eventType))} eventTypeCategory={eventTypeCategory} taskRelation={relatedTo} />
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    );
};
