import * as React from "react";
import { Container, Form } from "semantic-ui-react";
import { SubActionModalProps } from "./SubActionModalProps";
import { useDispatch } from "react-redux";
import { bulkUpdateReasonForHidingEvents } from "../../actions";

export const HideEventsAction: React.FC<SubActionModalProps> = ({ triggerDispatchAction, selectedEventInstanceIds, setActionComplete }) => {
    const [reason, setReason] = React.useState<string>("");
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                dispatch(bulkUpdateReasonForHidingEvents(selectedEventInstanceIds, reason));
                setActionComplete(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerDispatchAction]);

    const onReasonChange = React.useCallback((_: any, { value }) => {
        setReason(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reason]);

    return (
        <Container>
            <Form>
                <Form.TextArea label="What's the reason?" value={reason} onChange={onReasonChange} rows={3} />
            </Form>
        </Container >
    );
};
