import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CountryEnum } from "@common/crud/organisation/model";
import { toast } from "@common/toasts";
import * as React from "react";
import { Icon, Table } from "semantic-ui-react";
import { EventInstance } from "../..";

interface DigitalCalendarSessionRowProps {
    sessionCourse: EventInstance;
    cssClass: string;
    onClick: () => void;
    numberOfSeats?: number;
    canChange?: boolean;
    fromOtherWorkflowPlanning?: boolean;
    classroomAvailabilityOnly?: boolean;
    trainerExpired: boolean;
    lengthOfCont: number;
    hiddenByContCourse: boolean;
    standby: boolean;
}

export const DigitalCalendarSessionRow: React.FC<DigitalCalendarSessionRowProps> =
({ sessionCourse, cssClass, onClick, numberOfSeats, canChange, classroomAvailabilityOnly, trainerExpired, fromOtherWorkflowPlanning, lengthOfCont,
    hiddenByContCourse, standby }) => {
    const className = `separated-row DayPeriod ${cssClass}`;
    const onRowClicked = React.useCallback(() => {
        if (canChange) {
            onClick();
        }
        else if (standby) {
            toast.warning("Trainer has been assigned as standby trainer for this session and cannot be edited in digital planning");
        }
        else if (fromOtherWorkflowPlanning) {
            toast.warning("This session has been provisionally planned elsewhere and cannot be edited here");
        }
        else {
            toast.warning("This session has already been created and cannot be edited in digital planning");
        }
    }, [canChange, fromOtherWorkflowPlanning, onClick, standby]);

    const countryAbbrevMap = new Map([
        [CountryEnum.England, "E"],
        [CountryEnum.Scotland, "S"],
        [CountryEnum.Wales, "W"],
        [CountryEnum.NIreland, "NI"],
        [CountryEnum.None, ""]
    ]);

    const countryAbbrev = (country?: CountryEnum) => countryAbbrevMap.get(country ?? CountryEnum.None);

    const getSessionText = () => {
        const countryAbbreviation = sessionCourse ? `${countryAbbrev(sessionCourse.referredCourtCountry)}` : "";
        return  (
            <div className="text-ellipsis">
                {
                    sessionCourse ?
                        `${sessionCourse.startTime?.format(DateFormat.Time, { trim: false })} ${sessionCourse.eventTypeAbbreviation} ${countryAbbreviation}`
                        : ""
                }
            </div>
        );
    };

    if (standby) {
        return (<Table.Row className="period-row">
            <td
                colSpan={2}
                onClick={onRowClicked}
                className={`${className} no-padding-right`}>
                Standby Trainer
            </td>
        </Table.Row>);
    }

    if (trainerExpired) {
        return (<Table.Row className="period-row">
            <td
                colSpan={2}
                className={`${className} no-padding-right`}>
                Expired
            </td>
        </Table.Row>);
    }

    if (hiddenByContCourse || (sessionCourse && lengthOfCont===0)) {
        return <></>;
    }

    return (<Table.Row rowSpan={sessionCourse? lengthOfCont : 1} className={`period-row ${lengthOfCont >= 2 ? `rows-${lengthOfCont}`:""}`}>
        <td
            onClick={onRowClicked}
            className={`${className} no-padding-right day-period-cell`}>
            {getSessionText()}
            <span onClick={onClick}>
                {!canChange ?
                    <>{sessionCourse.eventInstanceDeliveryType === DeliveryTypeEnum.Digital ? numberOfSeats : <Icon name="home" />}</> :
                    <>{classroomAvailabilityOnly && <Icon name="home" />} {numberOfSeats}</>
                }
            </span>
        </td>
    </Table.Row>);
};
