import * as React from "react";
import {
    Modal,
    Button,
    Radio,
    CheckboxProps,
    Divider,
    Form,
    Dropdown,
} from "semantic-ui-react";
import { useAdHocTemplates, AdhocTemplateType } from "@common/hooks/useAdHocTemplates";
import { toast } from "@common/toasts";
import { AttendeeApi } from "@common/crud/attendee";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import { isValidMobileNumber } from "@common/validation/mobileNumber";
import { Input } from "@neworbit/simpleui-input";
import { CustomSmsMessage } from "@common/crud/common";
import { CustomEmailMessage } from "@common/crud/common/CustomEmailMessage";

export interface AdHocCommsProps {
    correlationId: string;
    attendeeId?: string;
    eventInstanceId?: string;
    attendeeEmail: string;
    attendeeNumber: string;
}

export const AdHocCommsModal: React.FC<AdHocCommsProps> = ({ correlationId, attendeeEmail, attendeeNumber, attendeeId, eventInstanceId }) => {
    const [chosenMedium, setChosenMedium] = React.useState<AdhocTemplateType>(attendeeEmail ? AdhocTemplateType.Email : AdhocTemplateType.SMS);
    const [chosenTemplate, setChosenTemplate] = React.useState<number | string | null>(null);
    const [openAdHocModal, setOpenAdHocModal] = React.useState<boolean>(false);
    const [ongoingAction, setOngoingAction] = React.useState<boolean>(false);
    const [customSmsToggle, setCustomSmsToggle] = React.useState(false);
    const [customEmailToggle, setCustomEmailToggle] = React.useState(false);
    const [customEmailMessage, setCustomEmailMessage] = React.useState("");
    const [customSmsMessage, setCustomSmsMessage] = React.useState("");
    const [emailSubject, setEmailSubject]=React.useState("");
    const [showErrors, setShowErrors] = React.useState(false);
    const [isValid, setIsValidChange] = React.useState(false);

    const templates = useAdHocTemplates(chosenMedium);

    React.useEffect(() => {
        const updatedMedium=attendeeEmail ? AdhocTemplateType.Email : AdhocTemplateType.SMS;
        setChosenMedium(updatedMedium);

    },[attendeeEmail]);

    function onSmsTemplateChange(_: any, { value }: any) {
        setChosenTemplate(value);
    }

    const clearFields=() => {
        setCustomSmsToggle(false);
        setCustomEmailToggle(false);
        setCustomEmailMessage("");
        setCustomSmsMessage("");
        setEmailSubject("");
        setShowErrors(false);
    };

    const openCloseModal = React.useCallback(() => {
        if (openAdHocModal) {
            clearFields();
        }
        setOpenAdHocModal(!openAdHocModal);}, [openAdHocModal]);

    const sendComm = React.useCallback(async () => {
        if (chosenMedium === AdhocTemplateType.Email && !isValid) {
            setShowErrors(true);
            setOngoingAction(false);
            return;
        }

        if (!attendeeEmail && chosenMedium === AdhocTemplateType.Email ||
            !attendeeNumber && chosenMedium === AdhocTemplateType.SMS) {

            toast.warning(`
                Cannot send ${chosenMedium === AdhocTemplateType.Email ? "email" : "sms"}
                as Attendee hasn't provided an ${chosenMedium === AdhocTemplateType.Email ? "email address." : "telephone number."}`);
        }
        else {
            const templateName = customSmsToggle || customEmailToggle ? "" : templates.find(x => x.value === chosenTemplate)?.text;
            if (templateName === undefined) {
                throw new Error(`Can't find template ${chosenTemplate}`);
            }

            const dorsBookingApi = new DorsBookingApi();

            setOngoingAction(true);

            const sendEmail = () => {
                if (attendeeId) {
                    if (eventInstanceId === undefined) {
                        throw new Error(`Can't use Attendee API for ${attendeeId} without an eventInstanceId.`);
                    }

                    const attendeeApi = new AttendeeApi(eventInstanceId);

                    return customEmailToggle ?
                        attendeeApi.sendEmail(attendeeId, eventInstanceId, emailSubject, customEmailMessage)
                        : attendeeApi.sendAdHocEmail(attendeeId, templateName, chosenTemplate as number);
                }

                return customEmailToggle
                    ? dorsBookingApi.sendEmail(correlationId, emailSubject, customEmailMessage)
                    : dorsBookingApi.sendAdHocEmail(correlationId, templateName, chosenTemplate as number);};

            const sendSms = () => {
                if (attendeeId) {
                    if (eventInstanceId === undefined) {
                        throw new Error(`Can't use Attendee API for ${attendeeId} without an eventInstanceId.`);
                    }

                    const attendeeApi = new AttendeeApi(eventInstanceId);

                    return customSmsToggle ?
                        attendeeApi.sendSms(attendeeId, eventInstanceId, customSmsMessage)
                        : attendeeApi.sendAdHocSms(attendeeId, templateName, chosenTemplate as string);
                }

                return customSmsToggle ?
                    dorsBookingApi.sendSms(correlationId, customSmsMessage)
                    : dorsBookingApi.sendAdHocSms(correlationId, templateName, chosenTemplate as string);
            };

            try {
                const response = chosenMedium === AdhocTemplateType.Email
                    ? await sendEmail()
                    : await sendSms();
                if (response.success) {
                    const commType= customSmsToggle||customEmailToggle ? "Free Type":templateName;

                    toast.info(`Thank you. Your ${commType} ${chosenMedium === AdhocTemplateType.Email ? "email" : "sms"} has been sent.`);
                } else {
                    toast.warning(response?.message ?? "An unexpected error occurred");
                }
            } finally {
                setOngoingAction(false);
            }

            setChosenTemplate(null);
            openCloseModal();
        }
    }, [attendeeEmail, chosenMedium, attendeeNumber, customSmsToggle, templates,
        eventInstanceId, openCloseModal, chosenTemplate, customEmailToggle, attendeeId, correlationId, emailSubject, customEmailMessage, customSmsMessage,
        isValid]);

    const setMedium = React.useCallback((_: any, { value }: CheckboxProps) => {
        setChosenMedium(value as AdhocTemplateType);
        setCustomSmsToggle(false);
        setCustomEmailToggle(false);
    }, []);

    const hasValidMobileNumber = isValidMobileNumber(attendeeNumber);
    const typeIsSms = chosenMedium === AdhocTemplateType.SMS;

    const messageContent= typeIsSms ?
        (
            <>
                <Input.Checkbox
                    label="Send a custom message"
                    value={customSmsToggle}
                    onChange={setCustomSmsToggle}
                />

                {customSmsToggle ?
                    <CustomSmsMessage required={customSmsToggle} message={customSmsMessage} setMessage={setCustomSmsMessage} />
                    :
                    <Dropdown
                        placeholder='Select template'
                        fluid
                        selection
                        options={templates}
                        onChange={onSmsTemplateChange}
                    />
                }
            </>
        ) :
        (
            <CustomEmailMessage
                onShowErrorsChange={setShowErrors}
                onIsValidChange={setIsValidChange}
                isCustomEmail={customEmailToggle}
                onCustomEmailChange={setCustomEmailToggle}
                onSubjectChange={setEmailSubject}
                onBodyChange={setCustomEmailMessage}
                templateDropdownOptions={templates}
                onTemplateIdChange={setChosenTemplate}
                showErrors={showErrors} />
        );

    return (
        <>
            <Button onClick={openCloseModal}>Send other comms</Button>

            <Modal size="small" open={openAdHocModal}>
                <Modal.Header>What comms would you like to send?</Modal.Header>
                <Modal.Content>
                    <div>
                        <Radio
                            label="Email"
                            value={AdhocTemplateType.Email}
                            checked={chosenMedium === AdhocTemplateType.Email}
                            onChange={setMedium}
                        />
                        <Radio
                            className="padding-left-20"
                            label="SMS"
                            disabled={!hasValidMobileNumber}
                            value={AdhocTemplateType.SMS}
                            checked={chosenMedium === AdhocTemplateType.SMS}
                            onChange={setMedium}

                        />
                    </div>
                    <Divider />
                    <Form>
                        {messageContent}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={openCloseModal}>Cancel</Button>
                    <Button disabled={ongoingAction} onClick={sendComm}>Continue</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
