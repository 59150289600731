/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { push } from "redux-little-router";
import { DropdownItemProps, DropdownMenuProps, Form, Icon, Popup } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps, ValidationObject } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, createBasePathSelector } from "@common/redux-helpers";
import { AppCommonState } from "@common/appCommonState";
import { AlcoholReading, AlcoholReadingEnum, AllDdrsProviders, DrCode, DrCodeEnum, Gender, HighRiskEnum, HightRisk } from "../model";
import { basePathSelector as drinkDriveOffendersBasePathSelector } from "../selectors";
import { OrganisationState, selectors as organisationSelectors } from "../../organisation";
import { CreateModal, CreatePropsModal } from "./CreateModal";
import { AppState, DrinkDriveOffenderCreateModel } from "@common/crud/drinkDriveOffenders/model";
import { Address, optionsFromObject } from "@common/crud/common";
import { phoneNumber } from "@common/validation";
import { muiPastDateValidator } from "@common/validation/pastDateValidator";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { createDrinkDriveOffender } from "@common/crud/drinkDriveOffenders/actions";
import { DrinkDriveOffenderDetails } from "./../model";
import { EventTypeState } from "@common/crud/eventType";
import { ddrsEventTypeOptionsSelector } from "@common/crud/eventType/selectors";
import { DisplayTextIfNotInProduction } from "@common/helpers/environment";
import { MuiDateField } from "@common/components/MuiDateField";
import { ChangeToTitleCase } from "@common/crud/eventInstance/helpers";

export interface CreateProps {
    open: boolean;
    ddrsEventTypes: DropdownMenuProps[];
    courts: DropdownMenuProps[];
    genders: DropdownMenuProps[];
    quickAddId: string;
    environment?: string;
}

export interface DispatchProps extends SaveDispatchProps<DrinkDriveOffenderCreateModel> {
    close: () => void;
}

interface CreateFormState extends FormState<DrinkDriveOffenderCreateModel>{
    values: DrinkDriveOffenderCreateModel;
    quickAddId: string;
    valid: ValidationObject<DrinkDriveOffenderCreateModel>;
}

export class CreateForm extends FormBaseComponent<DrinkDriveOffenderCreateModel, CreateProps, CreateFormState> {

    constructor(props: CreateProps & SaveDispatchProps<DrinkDriveOffenderCreateModel>) {
        super(props);
        this.state = this.initialState;
    }

    public initialDDRSDetails: DrinkDriveOffenderDetails =  {
        gender: undefined,
        dateOfBirth: moment().subtract(30, "year"),
        courtId: "",
        sentenceDate: moment(),
        disqualifiedPeriod: 0,
        interimDate: moment(),
        reduction: 0,
        previousConvictions: 0,
        supervisingCourtId: "",
        classicCertificateNumber: "",
        drCode: undefined,
        alcoholReadingType: undefined,
        alcoholReading: undefined,
        highRisk: HighRiskEnum.NotHighRisk,
        referredProvider: "TTC",
        caseNumber: "",
        eventType: undefined,
        eligibleForConcessions: false
    };

    public initialValueState: DrinkDriveOffenderCreateModel = {
        id: "",
        forename: DisplayTextIfNotInProduction("DDRS", this.props.environment),
        surname: DisplayTextIfNotInProduction("Attendee", this.props.environment),
        email: "",
        telephone: "",
        address1: "",
        address2: "",
        address3: "",
        townCity: "",
        postcode: "",
        eventTypeId: "",
        expiryDate: moment(),
        drinkDriveOffenderDetails: this.initialDDRSDetails };

    public initialState: CreateFormState = {
        values: { ...this.initialValueState, drinkDriveOffenderDetails: { ...this.initialValueState.drinkDriveOffenderDetails,
            drCode: null,
            alcoholReadingType: null,
            alcoholReading: null
        }, },
        valid: {},
        quickAddId: "" };

    public componentDidUpdate() {

        if (this.props.quickAddId!== this.state.quickAddId) {
            this.setState({ ...this.initialState, quickAddId: this.props.quickAddId });
        }
    }

    public render() {
        const address: Address = {
            addressLine1: this.state.values.address1,
            addressLine2: this.state.values.address2,
            addressLine3: this.state.values.address3,
            postalCode: this.state.values.postcode,
            city: this.state.values.townCity
        };

        const providerOptions = AllDdrsProviders.map<DropdownItemProps>(p => ({ text: p, value: p }));
        const genderInfo ="A gender of Male or Female must be selected as these are the options as required by your referred court";

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={this.state.values.forename}
                    label="Forename"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onChange("forename")}
                />
                <Input.Text
                    value={this.state.values.surname}
                    label="Surname"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onChange("surname")}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails.dateOfBirth}
                    label="Date Of Birth"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.dateOfBirth")}
                />
                <Input.Email
                    value={this.state.values.email}
                    label="Email"
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("email")}
                />
                <Input.Text
                    value={this.state.values.telephone}
                    label="Telephone"
                    validation={phoneNumber()}
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("telephone")}
                />
                <Input.Dropdown
                    label="Scheme"
                    placeholder="Scheme"
                    showErrors={this.state.showErrors}
                    value={this.state.values.eventTypeId}
                    options={this.props.ddrsEventTypes}
                    onChange={this.onChange("eventTypeId")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Dropdown
                    label="Court"
                    placeholder="Court"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.courtId}
                    options={this.props.courts}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.courtId")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Dropdown
                    label="Referred Provider"
                    placeholder="Referred Provider"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.referredProvider}
                    options={providerOptions}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.referredProvider")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Text
                    required
                    label="Case Number"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.caseNumber}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.caseNumber")}
                />
                <Input.DropdownNumber
                    label={<label>Gender <Popup content={genderInfo} wide="very" trigger={<a><Icon name="info circle" /></a>} /></label>}
                    placeholder="Gender"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.gender}
                    options={this.props.genders}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.gender")}
                    dynamicOptions
                    search
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails.sentenceDate}
                    label="Sentence Date"
                    showErrors={this.state.showErrors}
                    required
                    validation={[muiPastDateValidator]}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.sentenceDate")}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails.disqualifiedPeriod}
                    label="Disqualified Period"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.disqualifiedPeriod")}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails.interimDate}
                    label="Interim Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.interimDate")}
                />
                <AddressLookup
                    showErrors={this.state.showErrors}
                    address={address}
                    onChange={this.updateAddress}
                    autofill
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails.reduction}
                    label="Reduction"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.reduction")}
                />
                <MuiDateField
                    value={this.state.values.expiryDate}
                    label="Completion Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("expiryDate")}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails.previousConvictions}
                    label="Previous Convictions"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.previousConvictions")}
                />
                <Input.Dropdown
                    label="Supervising Court"
                    placeholder="Supervising Court"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.supervisingCourtId}
                    options={this.props.courts}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.supervisingCourtId")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Text
                    value={this.state.values.drinkDriveOffenderDetails.classicCertificateNumber}
                    required
                    label="Classic Certificate Number"
                    showErrors={this.state.showErrors}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.classicCertificateNumber")}
                />
                <Input.DropdownNumber
                    label="DR Code"
                    placeholder="DR Code"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.drCode}
                    options={optionsFromObject(DrCode, "Select")}
                    onChange={this.onDrCodeChange}
                    dynamicOptions
                    search
                    required
                />
                <Input.DropdownNumber
                    label="Alcohol Reading Type"
                    placeholder="Alcohol Reading Type"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.alcoholReadingType}
                    options={optionsFromObject(AlcoholReading, "Select")}
                    onChange={this.onAlcoholReadingTypeChange}
                    dynamicOptions
                    search
                    required
                />

                {this.state.values.drinkDriveOffenderDetails.alcoholReadingType > AlcoholReadingEnum.None && (
                    <Input.Number
                        value={this.state.values.drinkDriveOffenderDetails.alcoholReading}
                        label="Alcohol Reading"
                        showErrors={this.state.showErrors}
                        onChange={this.onAlcoholReadingChange}
                    />
                )}
                <Input.DropdownNumber
                    label="High Risk"
                    placeholder="High Risk"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails.highRisk}
                    options={optionsFromObject(HightRisk, "Select")}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.highRisk")}
                    dynamicOptions
                    search
                    required
                />
            </Form>
        );
    }

    public onChange = (propName: keyof DrinkDriveOffenderCreateModel) => (value: any, valid: boolean) => {
        if ((propName === "forename" || propName === "surname")) {
            value = ChangeToTitleCase(value);
        }
        this.updateProperty(propName, value, valid);
    };
    public onDetailsChange = (propName: string) => (value: any, valid: boolean) => {
        this.updateNestedProperty(propName, value, valid);
    };

    private updateAddress = (address: Address, valid: boolean) => {
        this.updateProperty("address1", address.addressLine1, valid);
        this.updateProperty("address2", address.addressLine2, valid);
        this.updateProperty("address3", address.addressLine3, valid);
        this.updateProperty("townCity", address.city, valid);
        this.updateProperty("postcode", address.postalCode, valid);
    };

    private onDrCodeChange = (value: any, valid: boolean) => {
        this.updateNestedProperty("drinkDriveOffenderDetails.drCode", value, valid);
        if (value === DrCodeEnum.DR30 || value === DrCodeEnum.DR31 || value === DrCodeEnum.DR60 || value === DrCodeEnum.DR61 || value === DrCodeEnum.DR70 ) {
            this.updateNestedProperty("drinkDriveOffenderDetails.highRisk", 1, valid);
        }
    };

    private onAlcoholReadingTypeChange = (value: any, valid: boolean) => {
        this.updateNestedProperty("drinkDriveOffenderDetails.alcoholReadingType", value, valid);
        if (this.state.values.drinkDriveOffenderDetails.alcoholReading &&
            value && valid && this.shouldSetHighRisk(value, this.state.values.drinkDriveOffenderDetails.alcoholReading)) {
            this.updateNestedProperty("drinkDriveOffenderDetails.highRisk", 2, valid);
        }
    };

    private onAlcoholReadingChange = (value: any, valid: boolean) => {
        this.updateNestedProperty("drinkDriveOffenderDetails.alcoholReading", value, valid);
        if (this.state.values.drinkDriveOffenderDetails.alcoholReadingType &&
            value && valid && this.shouldSetHighRisk(this.state.values.drinkDriveOffenderDetails.alcoholReadingType, value)) {
            this.updateNestedProperty("drinkDriveOffenderDetails.highRisk", 2, valid);
        }
        else
        {
            this.updateNestedProperty("drinkDriveOffenderDetails.highRisk", 4, valid);
        }
    };

    private shouldSetHighRisk = (alcoholReadingType: AlcoholReadingEnum, value?: number) => {
        return (alcoholReadingType && value) && ((alcoholReadingType === AlcoholReadingEnum.Blood && value >= 200) ||
                (alcoholReadingType === AlcoholReadingEnum.Breath && value >= 87.5) ||
                (alcoholReadingType === AlcoholReadingEnum.Urine && value >= 267.5));
    };

    public submit = () => this.handleSubmit();
}

function mapStateToProps(state: AppState & OrganisationState & EventTypeState & AppCommonState ) {
    const pathname = state.router.pathname;
    const quickAdd = pathname.includes("quickAdd");
    const createForm =pathname.endsWith("/create");
    const open = quickAdd || createForm;
    const basePath = quickAdd?  createBasePathSelector("/police-and-court-event-management")(state):drinkDriveOffendersBasePathSelector(state);
    const quickAddId = quickAdd?  pathname.substring( pathname.indexOf("quickAdd")):"";

    return {
        ddrsEventTypes: ddrsEventTypeOptionsSelector(state),
        courts: organisationSelectors.courtOrganisationOptionsSelector(state),
        genders: optionsFromObject(Gender, "Select"),
        open,
        basePath,
        quickAddId,
        loading: state.loading,
        environment: state.appSettings.environment
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (dorsBooking: DrinkDriveOffenderCreateModel, basePath: string) =>
        {
            const quickAdd = basePath ==="/police-and-court-event-management";
            return dispatch(createDrinkDriveOffender(dorsBooking, basePath, quickAdd));},
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): CreatePropsModal & DispatchProps {
    return {
        ddrsEventTypes: propsFromState.ddrsEventTypes,
        courts: propsFromState.courts,
        genders: propsFromState.genders,
        open: propsFromState.open,
        save: (dorsBooking: DrinkDriveOffenderCreateModel) => propsFromDispatch.dispatchSave(dorsBooking, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
        quickAddId: propsFromState.quickAddId,
        loading: propsFromState.loading.active,
        environment: propsFromState.environment
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
