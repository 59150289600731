import * as React from "react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Container, Dropdown, DropdownProps, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { TypedTable } from "@common/crud/common/TypedTable";
import { selectedEventInstancesSelector } from "../../selectors";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { loadEventTypes } from "@common/crud/eventType";
import { loading, loadSuccess } from "redux-request-loading";
import { EventInstanceApi } from "../..";
import { TrainerFeeCalculator } from "../../TrainerFeeCalculator";
import { TrainerAllocationModel } from "@common/crud/trainer/model";
import { loadEventInstanceDetail } from "../../actions";
import { isLoadingSelector } from "@common/crud/common/selectors";
import { Spinner } from "@common/global";
import { toast } from "@common/toasts";
import { EventTrainer, PracticalEventTrainer, TrainerType } from "../../model";
import moment from "moment";
import { OrganisationApi } from "@common/crud/organisation/organisationApi";
import { Organisation } from "@common/crud/organisation";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

interface OwnProps {
    trainer?: EventTrainer | PracticalEventTrainer;
    setIsNewTrainerSelected?: React.Dispatch<React.SetStateAction<boolean>>;
    swapAgreedDate?: moment.Moment;
    startSubcontractingProcess?: boolean;
    trainerType?: TrainerType;
}

type SwapTrainerActionProps = SubActionModalProps & OwnProps;

export const SwapTrainerAction: React.FC<SwapTrainerActionProps> = ({
    triggerDispatchAction,
    setActionComplete,
    selectedEventInstanceIds,
    children,
    trainer,
    setIsNewTrainerSelected,
    swapAgreedDate,
    startSubcontractingProcess,
    trainerType }) => {

    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingSelector);
    const eventInstances = useSelector(selectedEventInstancesSelector(selectedEventInstanceIds));
    const eventTypes = useSelector(allEventTypesSelector);
    const [trainerAvailability, setTrainerAvailability] = React.useState<Record<string, TrainerAvailabilitySelectModel[]>>({});
    const [selectedTrainers, setSelectedTrainers] = React.useState<Record<string, TrainerAllocationModel>>({});

    const trainerId = trainer?.id;
    const isNewTrainerSelected = Object.keys(selectedTrainers).length > 0;
    const isPracticalTrainer = trainerType === TrainerType.PracticalTrainer;
    const carType = isPracticalTrainer ? (trainer as PracticalEventTrainer)?.carType : null;

    React.useEffect(() => {
        if (eventTypes.length === 0) {
            dispatch(loadEventTypes());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (setIsNewTrainerSelected) {
            setIsNewTrainerSelected(isNewTrainerSelected);
        }
    }, [setIsNewTrainerSelected, isNewTrainerSelected]);

    React.useEffect(() => {
        if (!isNewTrainerSelected) {
            return;
        }

        const newTrainer = selectedTrainers[selectedEventInstanceIds[0]];

        if (startSubcontractingProcess && !newTrainer.subcontractingProcess) {
            newTrainer.subcontractingProcess = {
                isActive: true,
                originalTrainerId: trainer?.id,
                newTrainerId: newTrainer.id,
                swapAgreedDate
            };
        } else if (startSubcontractingProcess && !newTrainer.subcontractingProcess?.swapAgreedDate.isSame(swapAgreedDate)) {
            newTrainer.subcontractingProcess.swapAgreedDate = swapAgreedDate;
        } else if (!startSubcontractingProcess && newTrainer.subcontractingProcess) {
            newTrainer.subcontractingProcess = null;
        } else {
            return;
        }

        setSelectedTrainers({ [selectedEventInstanceIds[0]]: newTrainer });
    }, [isNewTrainerSelected, selectedEventInstanceIds, selectedTrainers,
        startSubcontractingProcess, swapAgreedDate, trainer?.id]);

    const onTrainerChange = React.useCallback((eventInstanceId: string) => async (_: any, { value }: DropdownProps) => {
        const trainers = { ...selectedTrainers };
        const eventInstance = eventInstances.find(x => x.id === eventInstanceId);
        const orgApi = new OrganisationApi();
        let organisation: Organisation;
        if (eventInstance.workflowType === WorkflowTypeEnum.Dors) {
            organisation = await orgApi.detailFromForceId(eventInstance?.forceId);
        }
        const eventType = eventTypes.find(x => x.id === eventInstance.eventTypeId);
        const fee = TrainerFeeCalculator.getCourseFee(eventInstance, eventType,  organisation, undefined, isPracticalTrainer,);

        trainers[eventInstanceId] = { id: value as string, allocateToCourses: [], fee, sundries: 0, carType };
        setSelectedTrainers(trainers);
    }, [eventInstances, eventTypes, selectedTrainers, carType, isPracticalTrainer]);

    React.useEffect(() => {
        if (selectedEventInstanceIds.length > 0) {
            dispatch(loading("loadingSimpleAvailability"));
            const availabilityApi = new TrainerAvailabilityApi();
            availabilityApi
                .getSimpleTrainersAvailabilityForEventInstances(selectedEventInstanceIds, trainerId, trainerType)
                .then(v => {
                    setTrainerAvailability(v);
                    dispatch(loadSuccess("loadingSimpleAvailability"));
                });
        }
    }, [dispatch, selectedEventInstanceIds, trainerId, trainerType]);

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                const eventInstanceApi = new EventInstanceApi();
                dispatch(loading("swappingTrainers"));
                eventInstanceApi
                    .swapTrainer({ trainerId, trainers: selectedTrainers, trainerType })
                    .then((result) => {
                        dispatch(loadEventInstanceDetail({ eventInstanceId: selectedEventInstanceIds[0] }));
                        dispatch(loadSuccess("swappingTrainers"));
                        toast.success("Trainers have been swapped");
                        setActionComplete(true);
                    });
            }
        },
        [triggerDispatchAction, selectedTrainers, dispatch, selectedEventInstanceIds, setActionComplete, trainerId, trainerType]);

    const getBookedDisplayText = (trainerAvailabilitySelectModel: TrainerAvailabilitySelectModel) => {
        if (!trainerAvailabilitySelectModel) {
            return "";
        }
        if (trainerAvailabilitySelectModel.isBooked) {
            return "(Trainer already booked)";
        }
        if (trainerAvailabilitySelectModel.isProvisionallyBooked) {
            return "(Trainer provisionally booked)";
        }
        if (trainerAvailabilitySelectModel.isRCCoverOnDate) {
            return "(Trainer booked as RC cover)";
        }
        if (trainerAvailabilitySelectModel.isRCMonitorOnDate) {
            return "(Trainer booked as RC monitor)";
        }
        if (trainerAvailabilitySelectModel.isStandbyTrainerDuringEventInstance) {
            return "(Trainer booked as standby trainer)";
        }
        return "";
    };

    function newTrainerHasSameCarType(newTrainer: TrainerAvailabilitySelectModel) {
        switch (carType) {
            case "Any":
                return newTrainer.hasPracticalAttribute;
            case "Automatic":
                return newTrainer.hasAutomaticAttribute;
            case "Manual":
                return newTrainer.hasManualAttribute;
            default:
                return true;
        }
    }

    function getNewTrainerAvailabilityText(newTrainer: TrainerAvailabilitySelectModel) {
        let disabledReason = getBookedDisplayText(newTrainer);

        if (!newTrainerHasSameCarType(newTrainer)) {
            disabledReason += `${disabledReason && "; "}Incorrect car type`;
        }

        disabledReason = disabledReason && `(${disabledReason})`;

        return `${newTrainer.fullName} ${disabledReason}`;
    }

    return (
        <Container>
            {children()}
            <Spinner active={isLoading}>
                <TypedTable
                    values={eventInstances}
                    emptyValuesArrayMessage="Select course">
                    {
                        [
                            {
                                header: "Course name",
                                value: (e) => `${e.venueName} ${e.startDate.startOf("day").add(e.startTime).format(DateFormat.ShortWithTime)}`
                            },
                            {
                                header: "Swap with",
                                value: (e) => (
                                    trainerAvailability[e.id] && trainerAvailability[e.id]?.filter(x => !x.isBooked).length > 0 ?
                                        <Popup
                                            content="Swap cannot be completed as subcontracting is already taking place"
                                            on="hover"
                                            disabled={e.subcontractedTrainers.length === 0}
                                            trigger={
                                                <span>
                                                    <Dropdown
                                                        disabled={e.subcontractedTrainers.length > 0}
                                                        onChange={onTrainerChange(e.id)}
                                                        placeholder="Select trainer"
                                                        search
                                                        options={
                                                            trainerAvailability[e.id].map((t, i) => ({
                                                                key: i,
                                                                text: getNewTrainerAvailabilityText(t),
                                                                disabled: t.isBooked || t.isProvisionallyBooked || t.isRCCoverOnDate ||
                                                                          t.isRCMonitorOnDate || !newTrainerHasSameCarType(t) ||
                                                                          t.isStandbyTrainerDuringEventInstance,
                                                                value: t.id
                                                            }))}
                                                    />
                                                </span>
                                            }
                                        /> : "No trainers available"
                                )
                            }
                        ]
                    }
                </TypedTable>
            </Spinner>
        </Container >
    );
};
