import * as React from "react";
import { Table, TableCell, TableHeader, TableHeaderCell } from "semantic-ui-react";
import "./RegionalCoordinatorLegend.scss";

export const RegionCoordinatorLegend = () => (
    <Table className="rc-legend">
        <TableHeader><TableHeaderCell><h3>Legend</h3></TableHeaderCell><TableHeaderCell /></TableHeader>
        <Table.Body>
            <Table.Row>
                <TableCell><div className="coordinator"></div></TableCell>
                <TableCell textAlign="center">RC Cover</TableCell>
            </Table.Row>
            <Table.Row>
                <TableCell><div className="monitor"></div></TableCell>
                <TableCell textAlign="center">RC Monitor</TableCell>
            </Table.Row>
        </Table.Body>
    </Table>
);
