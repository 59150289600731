import moment from "moment";

export function compare(a: any, b: any): boolean {
    const type = typeof (a);

    if (type === "boolean" || typeof (b) === "boolean") {
        return (a || false) === (b || false);
    }

    if (type !== typeof (b)) {
        return false;
    }

    if (type === "number" || type === "string") {
        return a === b;
    }

    if (type === "undefined") {
        return true;
    }

    if (type === "object") {
        if (moment.isMoment(a)) {
            return (a?.unix() || undefined) === (b?.unix() || undefined);
        }
        if (Array.isArray(a)) {
            if (a.length !== b.length) {
                return false;
            }
            const arrayA = a.sort();
            const arrayB = b.sort();
            for (let x = 0; x < arrayA.length; x++) {
                if (!compare(arrayA[x], arrayB[x])) {
                    return false;
                }
            }
        } else {
            for (const property in a) {
                // eslint-disable-next-line no-prototype-builtins
                if (!a.hasOwnProperty(property) || !compare(a[property], b[property])) {
                    return false;
                }
            }

            for (const property in b) {
                // eslint-disable-next-line no-prototype-builtins
                if (!b.hasOwnProperty(property) || !compare(b[property], a[property])) {
                    return false;
                }
            }
        }
    }
    return true;
}
