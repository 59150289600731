import * as React from "react";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AllEventInstancesProps } from "../model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { Media } from "@common/global/AppMedia";
import { EventInstanceList } from "./EventInstanceList";

export const AllEventInstances: React.FC<AllEventInstancesProps> =
    ({
        eventInstances,
        eventInstanceGroups,
        moreResults,
        loadMore,
        noCoursesMessage,
        pageSize,
        showNoCoursesMessage
    }) => {
        const [t] = useTranslation("AllEventInstances");

        if ((!eventInstances?.length && !eventInstanceGroups?.length) || showNoCoursesMessage) {
            return <p className="no-instances">{noCoursesMessage}</p>;
        }

        const showFlexiPayMessage = eventInstances && eventInstances.length > 0 && eventInstances[0].priceClearedDueToFlexiPay;
        const coursesAreDigital = eventInstances && eventInstances.length > 0 && eventInstances[0].eventInstanceDeliveryType === DeliveryTypeEnum.Digital;

        return (
            <>
                {showFlexiPayMessage &&
                    <Message info size="tiny">
                        {coursesAreDigital ? t("FLEXI_PAY_USED_DIGITAL") : t("FLEXI_PAY_USED_CLASSROOM")}
                    </Message>
                }
                <Media greaterThanOrEqual="computer">
                    <EventInstanceList
                        isMobile={false}
                        eventInstances={eventInstances}
                        eventInstanceGroups={eventInstanceGroups}
                        pageSize={pageSize} />
                </Media>
                <Media lessThan="computer">
                    <EventInstanceList isMobile={true}
                        eventInstances={eventInstances}
                        eventInstanceGroups={eventInstanceGroups}
                        pageSize={pageSize} />
                </Media>
                {moreResults &&
                    <button type="button" className="load-more-button" onClick={loadMore}>
                        {t("LOAD_MORE")}
                    </button>
                }
            </>
        );
    };
