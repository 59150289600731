import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { Button, Container, Dropdown, DropdownProps, Form, Grid, InputOnChangeData } from "semantic-ui-react";
import { MonthDropDown } from "./MonthDropDown";
import { DigitalPlanningSessionsState, EventInstance } from "../../model";
import { digitalPlanningSessionsSelector } from "../../selectors";
import { loadDigitalTrainersForDors } from "@common/crud/trainer/actions";
import { PlannedEventsOverviewTable } from "./PlannedEventsOverviewTable";
import { trainersSelector } from "@common/crud/trainer/selectors";
import { ConfirmButton } from "@common/components";
import { DigitalPlanningEditMessage, DigitalPlanningMessageState } from "./DigitalPlanningEditMessage";
import { deleteDigitalPlanningSession, startDigitalPlanningCourseCreation } from "../../actions";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { policeForceOptionsSelector } from "@common/crud/organisation/selectors";

export interface DigitalPlanningOverviewFilter {
    month?: moment.Moment;
    trainerName?: string;
}

export const DigitalPlanningOverview: React.FC = () => {

    const selectedMonth = useSelector((state: any) => {
        const value = state.router.query.month;
        if (value) {
            return moment(value);
        }
        return null;
    });

    const trainerName = useSelector((state: any) => {
        const value = state.router.query.trainerName;
        if (value) {
            return value;
        }
        return "";
    });

    const planningSessions = useSelector(digitalPlanningSessionsSelector);
    const [digitallyPlannedEvents, setDigitallyPlannedEvents] = React.useState<EventInstance[]>([]);
    const [planningState, setPlanningState] = React.useState<DigitalPlanningMessageState>(
        {
            state: DigitalPlanningSessionsState.Unknown,
            courseCreationError: false,
            errorMessage: null
        });
    const [planningSessionId, setPlanningSessionId] = React.useState<string>(null);

    const dispatch = useDispatch();

    const onFilterChange = React.useCallback((filter: DigitalPlanningOverviewFilter) => {
        setDigitallyPlannedEvents([]);
        const combinedParameters: DigitalPlanningOverviewFilter = { month: selectedMonth, ...filter };
        dispatch(
            push(`?month=${combinedParameters.month?.toISOString()}${combinedParameters.trainerName ? `&trainerName=${combinedParameters.trainerName}` :""}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trainerName, selectedMonth]);

    React.useEffect(() => {
        if (selectedMonth === null) {
            return;
        }
        const params = { month: selectedMonth, trainerId: "" };
        dispatch(loadDigitalTrainersForDors(params));
        const planningSession = planningSessions.find(s => s.month.month() === selectedMonth.month());
        if (planningSession) {
            setDigitallyPlannedEvents(planningSession.plannedDigitalEvents);
            setPlanningState(
                {
                    state: planningSession.state,
                    courseCreationError: planningSession.courseCreationFailed,
                    errorMessage: planningSession.errorMessage
                });
            setPlanningSessionId(planningSession.id);
        } else {
            setDigitallyPlannedEvents([]);
            setPlanningSessionId(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, planningSessions, selectedMonth?.month]);

    const [trainerNameSearch, setTrainerNameSearch] = React.useState<string>(trainerName);
    const onTrainerNameSearchChange = React.useCallback((_, data: InputOnChangeData) => setTrainerNameSearch(data.value), []);
    const trainers = useSelector(trainersSelector);
    const selectedTrainers = trainerName !== "" ? trainers.filter(t => t.fullName.includes(trainerName)) : trainers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onTrainerNameSearch = React.useCallback(() => onFilterChange({ trainerName: trainerNameSearch }), [trainerNameSearch]);
    const [selectedPoliceForceIds, setSelectedPoliceForceIds] = React.useState<number[]>(null);
    const policeForceOptions = useSelector(policeForceOptionsSelector).filter(o => o.value !== "");

    function onSelectedPoliceForcesChange(_: any, { value }: DropdownProps) {
        const newSelectedPoliceForceIds = value as number[];
        setSelectedPoliceForceIds(newSelectedPoliceForceIds.length > 0 ? newSelectedPoliceForceIds : null);
    }

    const createCourses = React.useCallback(() => {
        dispatch(startDigitalPlanningCourseCreation([selectedMonth], EventTypeCategory.Dors, selectedPoliceForceIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedMonth]);
    const deletePlanningSession = React.useCallback(() => {
        dispatch(deleteDigitalPlanningSession(selectedMonth, planningSessionId, EventTypeCategory.Dors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedMonth]);

    const blockEditing = planningState.state > DigitalPlanningSessionsState.InProgress || selectedMonth === null || planningSessionId === null;
    return (
        <Container>
            <Grid className="ui form filter-font">
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h2><u>Digital Planning Summary</u></h2>
                    </Grid.Column>
                    <Grid.Column width={5} floated="right">
                        <MonthDropDown hideCreateCoursesButton disabled={undefined} onFilterChange={onFilterChange} month={selectedMonth} />
                    </Grid.Column>
                </Grid.Row>
                {!blockEditing &&
                    <Grid.Row textAlign="right" className="no-padding">
                        <Grid.Column>
                            <Dropdown multiple selection placeholder="Any Police Force" options={policeForceOptions} onChange={onSelectedPoliceForcesChange} />
                        </Grid.Column>
                    </Grid.Row>
                }
                <Grid.Row>
                    <DigitalPlanningEditMessage state={planningState} />
                    <Grid.Column width={6}>
                        <Form.Input
                            action={<Button icon="search" onClick={onTrainerNameSearch} />}
                            placeholder="Name"
                            value={trainerNameSearch}
                            onChange={onTrainerNameSearchChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6} floated="right" textAlign="right">
                        <ConfirmButton
                            header={"You are about to create courses"}
                            content={"Are you sure? Action cannot be undone and you will be unable to make any more changes to the current planning."}
                            onConfirm={createCourses}
                            disabled={blockEditing}>Create</ConfirmButton>
                        <ConfirmButton
                            header={"You are about to delete this planning"}
                            content={"Are you sure? Action cannot be undone."}
                            onConfirm={deletePlanningSession}
                            disabled={blockEditing}>Cancel</ConfirmButton>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <PlannedEventsOverviewTable
                            plannedEventInstances={digitallyPlannedEvents}
                            trainers={selectedMonth ? selectedTrainers : []}
                            month={selectedMonth}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
