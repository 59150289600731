import * as React from "react";
import { getTrainerBackgroundBasedOnAverageDaysWorked } from "@common/crud/eventInstance/helpers";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import { StandbyTrainerAvailabilityForDay } from "../../standbyTrainersCalendarModel";
import { DayPeriodEnum } from "@common/model";
import "./AssignStandbyTrainersRowStyle.scss";

interface AssignTrainersRowProps {
    trainers: StandbyTrainerAvailabilityForDay[];
    sessionOneStandbyIds: string[];
    setSessionOneStandbyIds: (ids: string[]) => void;
    sessionTwoStandbyIds: string[];
    setSessionTwoStandbyIds: (ids: string[]) => void;
    sessionThreeStandbyIds: string[];
    setSessionThreeStandbyIds: (ids: string[]) => void;
    sessionFourStandbyIds: string[];
    setSessionFourStandbyIds: (ids: string[]) => void;
}

export const AssignStandbyTrainersRow: React.FC<AssignTrainersRowProps> =
({ trainers, sessionOneStandbyIds, setSessionOneStandbyIds, sessionTwoStandbyIds, setSessionTwoStandbyIds, sessionThreeStandbyIds, setSessionThreeStandbyIds,
    sessionFourStandbyIds, setSessionFourStandbyIds }) => {
    const onDeselectClickedStandby = (trainerId: string, period: DayPeriodEnum) => () => {
        switch (period) {
            case DayPeriodEnum.SessionOne:
                if (!sessionOneStandbyIds.includes(trainerId)) {
                    return;
                }

                setSessionOneStandbyIds(sessionOneStandbyIds.filter(id => id !== trainerId));
                break;
            case DayPeriodEnum.SessionTwo:
                if (!sessionTwoStandbyIds.includes(trainerId)) {
                    return;
                }

                setSessionTwoStandbyIds(sessionTwoStandbyIds.filter(id => id !== trainerId));
                break;
            case DayPeriodEnum.SessionThree:
                if (!sessionThreeStandbyIds.includes(trainerId)) {
                    return;
                }

                setSessionThreeStandbyIds(sessionThreeStandbyIds.filter(id => id !== trainerId));
                break;
            case DayPeriodEnum.SessionFour:
                if (!sessionFourStandbyIds.includes(trainerId)) {
                    return;
                }

                setSessionFourStandbyIds(sessionFourStandbyIds.filter(id => id !== trainerId));
                break;
            default:
                break;
        }
    };

    const onSelectClickedStandby = (trainerId: string, period: DayPeriodEnum) => () => {
        switch (period) {
            case DayPeriodEnum.SessionOne:
                setSessionOneStandbyIds([...sessionOneStandbyIds, trainerId]);
                break;
            case DayPeriodEnum.SessionTwo:
                setSessionTwoStandbyIds([...sessionTwoStandbyIds, trainerId]);
                break;
            case DayPeriodEnum.SessionThree:
                setSessionThreeStandbyIds([...sessionThreeStandbyIds, trainerId]);
                break;
            case DayPeriodEnum.SessionFour:
                setSessionFourStandbyIds([...sessionFourStandbyIds, trainerId]);
                break;
            default:
                break;
        }
    };

    const getCellContent = (trainer: StandbyTrainerAvailabilityForDay, period: DayPeriodEnum) => {
        let standbyIds: string[] = [];
        let available: boolean = false;
        let isBooked: boolean = false;
        let isProvisionallyBooked: boolean = false;
        let isBookedAtRc: boolean = false;
        switch (period) {
            case DayPeriodEnum.SessionOne:
                standbyIds = sessionOneStandbyIds;
                available = trainer.sessionOneAvailable;
                isBooked = trainer.sessionOneIsBooked;
                isProvisionallyBooked = trainer.sessionOneIsProvisionallyBooked;
                isBookedAtRc = trainer.bookedAsRegionalCoordinatorCover|| trainer.BookedAsRegionalCoordinatorMonitor;
                break;
            case DayPeriodEnum.SessionTwo:
                standbyIds = sessionTwoStandbyIds;
                available = trainer.sessionTwoAvailable;
                isBooked = trainer.sessionTwoIsBooked;
                isProvisionallyBooked = trainer.sessionTwoIsProvisionallyBooked;
                isBookedAtRc = trainer.bookedAsRegionalCoordinatorCover|| trainer.BookedAsRegionalCoordinatorMonitor;
                break;
            case DayPeriodEnum.SessionThree:
                standbyIds = sessionThreeStandbyIds;
                available = trainer.sessionThreeAvailable;
                isBooked = trainer.sessionThreeIsBooked;
                isProvisionallyBooked = trainer.sessionThreeIsProvisionallyBooked;
                isBookedAtRc = trainer.bookedAsRegionalCoordinatorCover|| trainer.BookedAsRegionalCoordinatorMonitor;
                break;
            case DayPeriodEnum.SessionFour:
                standbyIds = sessionFourStandbyIds;
                available = trainer.sessionFourAvailable;
                isBooked = trainer.sessionFourIsBooked;
                isProvisionallyBooked = trainer.sessionFourIsProvisionallyBooked;
                isBookedAtRc = false;
                break;
            default:
                break;
        }
        if (standbyIds.includes(trainer.id)) {
            return (<Button as={"button"} size={"huge"} compact basic className="selected-bold" onClick={onDeselectClickedStandby(trainer.id, period)}>
                <Icon name={available ? "checkmark" : "cancel"} color={available ? "green" : "red"} className="availability-icon" />
            </Button>);
        } else if (!(isBooked || isProvisionallyBooked || isBookedAtRc)) {
            return (<Button as={"button"} size={"huge"} compact basic onClick={onSelectClickedStandby(trainer.id, period)}>
                <Icon name={available ? "checkmark" : "cancel"} color={available ? "green" : "red"} className="availability-icon" />
            </Button>);
        } else if (isBooked) {
            return <Label color="red" content="Booked" />;
        } else if (isProvisionallyBooked) {
            return <Label color="red" content="Provisionally Booked" />;
        } else if (isBookedAtRc) {
            if (trainer.bookedAsRegionalCoordinatorCover) {
                return <Label color="red" content="Booked as RC Cover" />;
            } else {
                return <Label color="red" content="Booked as RC Cover" />;
            }
        }
        return (<></>);
    };

    return (
        <>
            { trainers.map(trainer => (
                <Table.Row key={trainer.id}>
                    <Table.Cell width={3} content={trainer.fullName} className="assign-standby-trainers-column" />
                    <Table.Cell width={2} content={trainer.numberOfCoursesInSameMonth} className="assign-standby-trainers-column" />
                    <Table.Cell width={2} className={getTrainerBackgroundBasedOnAverageDaysWorked(trainer.averageDaysWorked)}
                        content={trainer.averageDaysWorked} />
                    <Table.Cell width={9} className="assign-standby-trainers-column">
                        <Table style={{ "table-layout": "fixed" }} stackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell
                                        textAlign="center"
                                        size={4}
                                        verticalAlign="middle"
                                        style={{ minWidth: "101px" } }
                                        className="assign-standby-trainers-column"
                                    >
                                        {getCellContent(trainer, DayPeriodEnum.SessionOne)}
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign="center"
                                        size={4}
                                        verticalAlign="middle"
                                        style={{ minWidth: "101px" } }
                                        className="assign-standby-trainers-column"
                                    >
                                        {getCellContent(trainer, DayPeriodEnum.SessionTwo)}
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign="center"
                                        size={4}
                                        verticalAlign="middle"
                                        style={{ minWidth: "101px" } }
                                        className="assign-standby-trainers-column"
                                    >
                                        {getCellContent(trainer, DayPeriodEnum.SessionThree)}
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign="center"
                                        size={4}
                                        verticalAlign="middle"
                                        style={{ minWidth: "101px" } }
                                        className="assign-standby-trainers-column"
                                    >
                                        {getCellContent(trainer, DayPeriodEnum.SessionFour)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Table.Cell>
                </Table.Row>
            ))}
        </>
    );
};
