import { ApplicationState } from "@booking/applicationState";
import { bookingIdSelector } from "@common/payments/selectors";
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { GenesysApi } from "./genesysApi";
import { AdminRecordedActionType } from "./model";

export function auditAuthorisationBypass() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new GenesysApi();
        await loadAndTrack(dispatch, "auditAuthorisationBypass", api.auditAuthorisationBypass(bookingId));
    };
}

export function pauseCallRecording(correlationId: string) {
    return async (dispatch: Dispatch) => {
        const api = new GenesysApi();
        await loadAndTrack(dispatch, "pauseCallRecording", api.pauseCallRecording(correlationId));
    };
}

export function RecordCaAction(token: string, dorsBookingId: string, actionType: AdminRecordedActionType) {
    return async (dispatch: Dispatch) => {
        const api = new GenesysApi();
        await loadAndTrack(dispatch,
            "recordCaAction",
            api.RecordCaAction(token, dorsBookingId, actionType));
    };
}
