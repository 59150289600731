import * as React from "react";
import { Container } from "semantic-ui-react";
import { HeaderLogo } from "@common/global/HeaderLogo";
import { HeaderLanguage } from "@common/global/HeaderLanguage";
import { useTranslation } from "react-i18next";
import { AttendeeRole, TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { AuthMenu } from "./AuthMenu";
import { GenesysChatButton } from "./GenesysChatButton";
import { AppCommonState } from "@common/appCommonState";
import { useSelector } from "react-redux";
import { appSettingsSelector } from "@booking/bookings/selectors";

export const BookingHeader: React.SFC = () => {

    const [t] = useTranslation("Header");

    const genesysSettings = useSelector((state: AppCommonState) => state.appSettings.genesysSettings);
    const externalResourcesSettings = useSelector((state: AppCommonState) => state.appSettings.externalResourcesSettings);
    const hasAllChatSettings = externalResourcesSettings?.contactPageLink && genesysSettings?.deploymentKey && genesysSettings?.orgGuid;
    const config = useSelector(appSettingsSelector);

    return (
        <div className="booking-header">
            <Container className="booking-header-wrapper">
                <Authorize authorize={TtcClientAdvisorRole}>
                    <div className="top-bar">
                        <AuthMenu loginTitle={t("LOGIN")} logoutTitle={t("LOGOUT")} homeTitle={t("HOME")} />
                    </div>
                </Authorize>
                <div className="header-main-bar">
                    <HeaderLogo />
                    <HeaderLanguage language={t("LANGUAGE")} languageCode={t("LANGUAGE_CODE")} />
                    {config.featureSettings.bookingContactButtons && (
                        <Authorize authorize={AttendeeRole}>{ hasAllChatSettings && <GenesysChatButton /> }</Authorize>
                    )}
                </div>
            </Container>
        </div>
    );
};
