import * as React from "react";
import { EventTypePart, WorkflowTypeEnum } from "@common/crud/eventType/model";
import moment from "moment";
import { DisplaySuggestedStartTimes } from "@common/crud/eventType/components/details/DisplaySuggestedStartTimes";
import { DisplayEventTypeParts } from "@common/crud/eventType/components/details/DisplayEventTypeParts";

export interface DisplayEventTypePartAndStartTimesPickerProps {
    workflowType: WorkflowTypeEnum;
    defaultNumberOfDeliveryDays?: number;
    suggestedStartTimesForSessions?:  Dictionary<moment.Duration[]>;
    eventTypeParts: Dictionary<EventTypePart>;
    deliveryTypeDescription?: string;
}

export const DisplayEventTypePartAndStartTimesPicker: React.FC<DisplayEventTypePartAndStartTimesPickerProps> = ({
    workflowType, suggestedStartTimesForSessions,
    eventTypeParts, deliveryTypeDescription, defaultNumberOfDeliveryDays }) => {

    const useSingleDayControls = workflowType === WorkflowTypeEnum.Dors || defaultNumberOfDeliveryDays === 1;

    return (
        <>
            {useSingleDayControls && <DisplaySuggestedStartTimes
                startTimes={suggestedStartTimesForSessions} deliveryTypeDescription={deliveryTypeDescription} />}
            {!useSingleDayControls && <DisplayEventTypeParts dayParts={eventTypeParts} />}
        </>
    );
};
