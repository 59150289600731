import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { saveNotes } from "../../actions";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { EINotes, EventInstance } from "../../model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRoles, TrainerRole } from "@common/auth/model";
import { useSelector } from "react-redux";
import { ApplicationState } from "src/Admin.App/src/applicationState";
import { toast } from "@common/toasts";
import { isEventInstanceWorkflowPoliceOrCourt } from "@common/global/CommonHelpers";

export interface EventInstanceNotesModalProps {
    notes: EINotes;
    eventInstance: EventInstance;
}

export const EventInstanceNotesModal: React.FC<EventInstanceNotesModalProps> = (props: EventInstanceNotesModalProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [notes, setNotes] = React.useState(props.notes);
    const [notModified, setNotModified] = React.useState(true);

    React.useEffect(() => setNotes(props.notes), [props.notes]);

    const isPoliceOrCourt = React.useMemo(() => isEventInstanceWorkflowPoliceOrCourt(props.eventInstance), [props.eventInstance]);

    const onNotesChange = React.useCallback(() => {
        setNotModified(
            notes.officeNote === props.notes.officeNote &&
            notes.adminNote === props.notes.adminNote &&
            notes.bookingNoteEn === props.notes.bookingNoteEn &&
            notes.bookingNoteCy === props.notes.bookingNoteCy &&
            notes.trainerNote === props.notes.trainerNote
        );
    }, [notes, props.notes]);

    const dispatch = useDispatch();

    const onOpenButtonClick = React.useCallback(() => setOpen(true), []);

    const onOfficeNoteChange = React.useCallback((value: string) => {
        setNotes({ ...notes, officeNote: value });
        onNotesChange();
    }, [notes, onNotesChange]);

    const onTrainerNoteChange = React.useCallback((value: string) => {
        setNotes({ ...notes, trainerNote: value });
        onNotesChange();
    }, [notes, onNotesChange]);

    const onAdminNoteChange = React.useCallback((value: string) => {
        setNotes({ ...notes, adminNote: value });
        onNotesChange();
    }, [notes, onNotesChange]);

    const onBookingEnNoteChange = React.useCallback((value: string) => {
        setNotes({ ...notes, bookingNoteEn: value });
        onNotesChange();
    }, [notes, onNotesChange]);

    const onBookingCyNoteChange = React.useCallback((value: string) => {
        setNotes({ ...notes, bookingNoteCy: value });
        onNotesChange();
    }, [notes, onNotesChange]);

    const onCancelClick = React.useCallback(() => {
        setNotes(props.notes);
        setOpen(false);
    }, [props.notes]);

    const onSaveClick = React.useCallback(() => {
        dispatch(saveNotes(props.eventInstance.id, notes));
        toast.info("Notes saved");
        setNotModified(true);
        setOpen(false);
    }, [dispatch, notes, props.eventInstance]);

    const userRoles = useSelector((state: ApplicationState) => state.currentUser.roles);
    const isTrainer = userRoles.includes(TrainerRole);

    const trainerLabel = React.useMemo(() => isTrainer
        ? (<span><b>Trainer Note</b> <i>(Add a note for Admin to review)</i></span>)
        : (<span><b>Trainer Note</b> <i>(This box will contain any notes from the trainer)</i></span>)
    , [isTrainer]);

    return (
        <>
            <Button onClick={onOpenButtonClick}>
                {isTrainer ? "Add Trainer Note" : "Manage Course Notes"}
            </Button>

            <Modal open={open}>
                <Modal.Header>
                    What notes are you adding today?
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Authorize authorize={EventManagementAdminRoles}>
                            <Input.Textarea
                                value={notes.officeNote}
                                label={<span><b>Office Note</b> <i>(Add a note for Admin App users to review)</i></span>}
                                validation={[validators.validLength({ max: 1000 })]}
                                onChange={onOfficeNoteChange}
                            />
                        </Authorize>
                        <Input.Textarea
                            label={trainerLabel}
                            value={notes.trainerNote}
                            onChange={onTrainerNoteChange}
                        />
                        <Authorize authorize={EventManagementAdminRoles}>
                            <Input.Textarea
                                value={notes.adminNote}
                                label={<span><b>Admin Note</b> <i>(Add a note for the trainer to review)</i></span>}
                                validation={[validators.validLength({ max: 1000 })]}
                                onChange={onAdminNoteChange}
                            />
                            {isPoliceOrCourt && (
                                <>
                                    <Input.Textarea
                                        value={notes.bookingNoteEn}
                                        label={<span><b>Booking Note (EN)</b> <i>(Add a note for English clients to see in the Booking App)</i></span>}
                                        validation={[validators.validLength({ max: 1000 })]}
                                        onChange={onBookingEnNoteChange}
                                    />
                                    <Input.Textarea
                                        value={notes.bookingNoteCy}
                                        label={<span><b>Booking Note (CY)</b> <i>(Add a note for Welsh clients to see in the Booking App)</i></span>}
                                        validation={[validators.validLength({ max: 1000 })]}
                                        onChange={onBookingCyNoteChange}
                                    />
                                </>
                            )}
                        </Authorize>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelClick} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSaveClick}
                        disabled={notModified}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
