import * as React from "react";
import { ConfirmButton } from "@common/components";
import { useDispatch } from "react-redux";

import { EventInstance } from "../..";
import { publishEventInstance } from "../../actions";
import { PublishConfirmationText } from "../PublishConfirmationText";
import { Modal } from "semantic-ui-react";
import { ConstructionWorkflowTypeArray, CorporateWorkflowTypeArray, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { toast } from "@common/toasts";
import { BookingTypeEnum } from "../../../eventType/model";
import { includes } from "lodash";

interface OwnProps {
    eventInstance: EventInstance;
}

export const PublishModal: React.FC<OwnProps> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const publish = () => dispatch(publishEventInstance(eventInstance.id));
    const internalPublish = React.useCallback(() => {
        publish();
        const hasAnyReminderContactSet = eventInstance.corporateOrganisationContact?.contacts?.find(c => c.courseReminderCommsDestination)
            || eventInstance.corporateOrganisationCourseContact?.contacts?.find(c => c.courseReminderCommsDestination)
            || eventInstance.delegatesContact?.courseReminderCommsDestination
            || eventInstance.managersContact?.courseReminderCommsDestination;

        const isClosedCorporateEvent = eventInstance.bookingType === BookingTypeEnum.Closed &&
        includes(CorporateWorkflowTypeArray, eventInstance.workflowType);

        if (!hasAnyReminderContactSet && isClosedCorporateEvent) {
            toast.warning("There is no contact to send the course reminder to. If you want to set one up, please go to the contacts tab.");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstance]);
    const header = "Publish course";
    const disablePublishButton = React.useMemo(() => {
        switch (eventInstance.workflowType) {
            case WorkflowTypeEnum.Dors:
                return !eventInstance.schemeId || !eventInstance.forceId;
            case WorkflowTypeEnum.DDRS:
                return !eventInstance.dsaArea;
            case WorkflowTypeEnum.CPC:
            case WorkflowTypeEnum.Workshop:
            case WorkflowTypeEnum.OnRoad:
            case WorkflowTypeEnum.BusinessDriver:
                return !CorporateWorkflowTypeArray.includes(eventInstance.workflowType)
                    || (eventInstance.flexibleCertificatesRequired && !eventInstance.certificateType);
            case WorkflowTypeEnum.ConstructionStandard:
            case WorkflowTypeEnum.CITB:
            case WorkflowTypeEnum.APS:
                return !ConstructionWorkflowTypeArray.includes(eventInstance.workflowType);
            default:
                return true;
        }
    }, [eventInstance]);

    return (
        <ConfirmButton
            header={header}
            content={<Modal.Content><PublishConfirmationText /></Modal.Content>}
            onConfirm={internalPublish}
            disabled={disablePublishButton}
        >
            Publish
        </ConfirmButton>
    );
};
