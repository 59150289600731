import * as React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";
import { push } from "redux-little-router";
import { auditOrderAuthorisationBypass } from "../actions";
import { GenesysObjectType, StartOrganisationPaymentProcessRequest } from "../model";

export interface GenesysSkipAuthorisationButtonProps {
    paymentPage: string;
    startPaymentProcess: StartOrganisationPaymentProcessRequest;
}

export const GenesysSkipAuthorisationButton: React.FC<GenesysSkipAuthorisationButtonProps> = ({ paymentPage, startPaymentProcess }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const dispatch = useDispatch();

    const onContinue = React.useCallback(() => {
        setOpen(false);
        dispatch(auditOrderAuthorisationBypass(
            startPaymentProcess.basketId
                ? GenesysObjectType.Basket
                : GenesysObjectType.Order,
            startPaymentProcess.basketId
        ));
        dispatch(push(paymentPage));
    }, [dispatch, startPaymentProcess?.basketId, paymentPage]);

    const onOpenCancelModalClick = React.useCallback(() => setOpen(true), []);

    const onCancelClick = React.useCallback(() => setOpen(false), []);

    const timerid = React.useRef(null);

    // Button initially disable and only enabled later to make it unlikely that it is used if Genesys is just slow
    React.useEffect(() => {
        timerid.current = setTimeout(() => {
            setDisabled(false);
        }, 2000);
    }, []);

    React.useEffect(() => {
        return function cleanup() {
            if (timerid.current !== null)
            {
                clearTimeout(timerid.current);
            }
        };
    }, [timerid]);

    return (
        <>
            <Button onClick={onOpenCancelModalClick} disabled={disabled}>Proceed to Payment</Button>

            <Modal open={open}>
                <Modal.Header>
                    Proceed to Payment
                </Modal.Header>
                <Modal.Content>
                    <h3>Proceed to payment without logging into Genesys. The call recording will not be stopped.
                        Only do this if there is a problem logging into Genesys.
                        Note that using this option will be audited.
                    </h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelClick} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        primary
                    >
                        Continue
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
