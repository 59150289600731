import * as React from "react";
import { LanguageEnum, Language } from "../../model";
import { Dropdown } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";

export interface LanguageDropdownProps {
    id: string;
    language: LanguageEnum;
    onLanguageChange: (id: string, language: LanguageEnum) => void;
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ id, onLanguageChange, language }) => {
    const onChange = React.useCallback((_: any, { value }) => {
        onLanguageChange(id, value);
    }, [id, onLanguageChange]);

    return (
        <Dropdown value={language} options={optionsFromObject(Language)} onChange={onChange} />
    );
};
