/* eslint-disable max-lines */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Segment, Button, Grid, Divider, Icon } from "semantic-ui-react";
import { AttendeeApi, AttendeeListModel } from "@common/crud/attendee";
import { loadAttendeesForEventInstance, loadAttendeesFromSuccess, loadAttendeesSuccess, submitDelegateAttendanceRegister } from "@common/crud/attendee/actions";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { RegisterStatistics } from "./RegisterStatistics";
import {
    getRegisterStats,
    isCurrentDateTimeBeforeEventInstanceDateTime
} from "./helpers";
import { useRegisterContext } from "./register-context";
import { EventInstance } from "@common/crud/eventInstance";
import { UpdateDelegatesTableRow } from "../details/UpdateDelegatesTableRow";
import { EventInstanceUpdateDelegateFormErrors, FieldsForMarkup } from "../../model";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { licenceValidator } from "@common/validation/drivingLicenceNumber";
import { ValidationResultType } from "not-valid/bin/results";
import { BookingTypeEnum, ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { CalculateDelegateComparisonType, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";
import { nameof } from "@common/helpers/nameof";
import "./DelegateRegister.scss";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { Media } from "@common/global/AppMedia";
import moment from "moment";
import { forEach, isEqual, isEqualWith, isObject, sortBy } from "lodash";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { uinValidator } from "@common/validation/uin";
import { DelegateComparisonTypeEnum } from "@common/crud/delegate/model";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";

interface DelegateAttendanceRegisterProps {
    eventInstance: EventInstance;
    areDelegatesEditable: boolean;
    canChangeDelegateAmount: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
}

interface UndoStackItem {
    id: string;
    notSaved: boolean;
    property: string[];
    value: AttendeeListModel;
}

export const DelegateAttendanceRegister: React.FC<DelegateAttendanceRegisterProps> = ({ eventInstance, areDelegatesEditable, canChangeDelegateAmount,
    eventInstanceGroupDays }) => {
    const dispatch = useDispatch();

    const {
        nonCancelledAttendees: attendees,
        eventInstanceOptions: { eventInstanceId, registerClosed },
        attendeesFrom
    } = useRegisterContext();

    const isEventInstanceOpen = eventInstance.bookingType === BookingTypeEnum.Open;
    const isEventInstanceMultiDay = !!eventInstance.groupId;
    const isBeforeEventInstanceDateTime = isCurrentDateTimeBeforeEventInstanceDateTime(eventInstance);

    const app = useSelector(appSelector);
    const settings = useSelector(appSettingsSelector);
    const allowCommercialOpenBookingManualDelegateAdjustments = settings.featureSettings?.allowCommercialOpenBookingManualDelegateAdjustments;
    const isAdminApp = app === Apps.Admin;
    const isTrainerApp = app === Apps.Trainer;
    const hasCpc = eventInstance?.workflowType === WorkflowTypeEnum.CPC || (eventInstance?.productCategory === ProductCategoryEnum.OnRoadWithCpc);
    const hasUin = eventInstance?.workflowType === WorkflowTypeEnum.BusinessDriver;
    const isAfv = eventInstance?.workflowType === WorkflowTypeEnum.Workshop && eventInstance.productCategory === ProductCategoryEnum.AFV;

    const [attendeeRegister, setAttendeeRegister] = React.useState<AttendeeListModel[]>(attendees?.sort((a, b) => a.forename?.localeCompare(b.forename)) || []);
    const [attendeesRegisterFrom, setAttendeesRegisterFrom] = React.useState<moment.Moment>(attendeesFrom);
    const anyAttendeesAdded = attendeeRegister.length > 0;

    const [changedDataSubmitting, setChangedDataSubmitting] = React.useState<boolean>(false);
    const [backendValidationInProgress, setBackendValidationInProgress] = React.useState<boolean>(false);
    const [registerEditable, setRegisterEditable] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [formErrors, setFormErrors] = React.useState<EventInstanceUpdateDelegateFormErrors>({});
    const [fieldsForMarkup, setFieldsForMarkup] = React.useState<FieldsForMarkup>({});
    const [delegateDataFetchingInProgress, setDelegateDataFetchingInProgress] = React.useState<string[]>([]);

    const dataFetchingInProgress = React.useMemo(() => delegateDataFetchingInProgress.length > 0, [delegateDataFetchingInProgress]);
    const [undoStack, setUndoStack] = React.useState<UndoStackItem[]>([]);
    const cleanUpRemovedDelegate = React.useCallback((delegate: AttendeeListModel) => {
        if (delegate.id in formErrors) {
            const newFormErrors = { ...formErrors };
            delete newFormErrors[delegate.id];
            setFormErrors(newFormErrors);
        }
        if (delegate.id in fieldsForMarkup) {
            const newFieldsForMarkup = { ...fieldsForMarkup };
            delete newFieldsForMarkup[delegate.id];
            setFieldsForMarkup(newFieldsForMarkup);
        }
    }, [fieldsForMarkup, formErrors]);

    const onUndoChanges = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (undoStack.length > 0) {
            const newundoStack = [...undoStack];
            const lastState = newundoStack.pop();
            setUndoStack(newundoStack);
            if (lastState) {
                setAttendeeRegister((items) => {
                    const attendeeItem = items.find(item => item.id === lastState.id);
                    if (!attendeeItem) {
                        const newItems = [...items, lastState.value];
                        if (!lastState.notSaved) {
                            // item gets inserted in the correct place
                            newItems.sort((a, b) => {
                                if (a.notSaved && !b.notSaved) {
                                    return 1;
                                }
                                if (!a.notSaved && b.notSaved) {
                                    return -1;
                                }
                                return a.forename?.localeCompare(b.forename);
                            });
                        }
                        return newItems;
                    }
                    if (attendeeItem.notSaved === true) {
                        const undoStackContainsMoreItemsForId = newundoStack.some(item => item.id === lastState.id);
                        if (!undoStackContainsMoreItemsForId) {
                            // remove this item as it was the result of user clicking "add delegate"
                            cleanUpRemovedDelegate(attendeeItem);
                            return items.filter(item => item.id !== lastState.id);
                        }
                    }
                    // replace existing item with previous state
                    return items.map(item => item.id === lastState.id ? lastState.value : item);
                });
            }
        }
    }, [cleanUpRemovedDelegate, undoStack]);

    React.useEffect(() => {
        dispatch(loadAttendeesForEventInstance({ eventInstanceId }));
    }, [dispatch, eventInstanceId]);

    React.useEffect(() => {
        if (attendees && !registerEditable) {
            setAttendeeRegister(attendees);
            setAttendeesRegisterFrom(attendeesFrom);
        }
    }, [attendees, attendeesFrom, registerEditable]);

    function handleFormSubmit() {
        onSubmitRegister(attendeeRegister, true);
    }

    const onSubmitRegister = React.useCallback((models: AttendeeListModel[], isStageTwo: boolean) => {
        dispatch(submitDelegateAttendanceRegister(eventInstanceId, models, isStageTwo));
    }, [dispatch, eventInstanceId]);

    const attendeesToShow = React.useMemo(() => {
        let filteredAttendees = attendeeRegister ? [...attendeeRegister] : [];

        filteredAttendees = filteredAttendees.filter(a => !a.isBookingCanceled);

        if (isEventInstanceMultiDay && !registerEditable) {
            filteredAttendees = filteredAttendees.filter(a => a.eventInstanceIds?.includes(eventInstanceId) || a.eventInstanceId === eventInstanceId);
        }

        return filteredAttendees;
    }, [attendeeRegister, eventInstanceId, isEventInstanceMultiDay, registerEditable]);

    const canAddMoreDelegates = React.useMemo(() =>
        (eventInstance.bookingType === BookingTypeEnum.Closed || (isAdminApp && allowCommercialOpenBookingManualDelegateAdjustments))
            && (attendeeRegister?.filter(a => !a.isBookingCanceled) || []).length < (eventInstance.openPlacesCount || 0),
    [attendeeRegister, eventInstance, isAdminApp, allowCommercialOpenBookingManualDelegateAdjustments]);

    const onEditRegister = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRegisterEditable(true);
    }, []);

    const onCancelEdit = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAttendeeRegister(attendees);
        setUndoStack([]);
        setFormErrors({});
        setFieldsForMarkup({});
        setRegisterEditable(false);
        setSubmitted(false);
    }, [attendees]);

    const onSaveChanges = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setSubmitted(true);

        const emptyNotSavedAttendeeIds = attendeeRegister.filter(a => a.notSaved && !a.forename && !a.surname && !a.drivingLicenceCountry
            && !a.drivingLicenceNumber && !a.uin && (!a.vocationalLicenceCategories || a.vocationalLicenceCategories.length === 0)
            && !a.dqcReference && (!a.dqcExpiry || !a.dqcExpiry.isValid() && !a.email && !a.telephone)).map(a => a.id);

        const formErrorsForCheck = { ...formErrors };

        if (emptyNotSavedAttendeeIds.length > 0) {
            setFormErrors(formErrorsForCheck);
            toast.error("Found empty unsaved delegate(s). Please provide valid data");
            return;
        }

        if (Object.keys(formErrorsForCheck).length !== 0) {
            toast.error("Please provide valid data");
            return;
        }

        setChangedDataSubmitting(true);

        try {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const result = await attendeeApi.updateDelegateAttendees(attendeeRegister, attendeesRegisterFrom, eventInstance.startDate);
            dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
            dispatch(loadAttendeesSuccess(result.attendees));
            setFormErrors({});
            setFieldsForMarkup({});
            setUndoStack([]);
            setRegisterEditable(false);
            setSubmitted(false);
        } finally {
            setChangedDataSubmitting(false);
        }
    }, [attendeeRegister, attendeesRegisterFrom, dispatch, eventInstance.startDate, eventInstanceId, formErrors]);

    const alreadyUsedDrivingLicenceNumbers = React.useMemo(() => {
        return attendeeRegister?.filter(attendee => !attendee.isBookingCanceled)
            .map(attendee => ({ id: attendee.id, drivingLicenceNumber: attendee.drivingLicenceNumber }));
    }, [attendeeRegister]);

    const alreadyUsedUins = React.useMemo(() => {
        return attendeeRegister?.filter(attendee => !attendee.isBookingCanceled)
            .map(attendee => ({ id: attendee.id, uin: attendee.uin }));
    }, [attendeeRegister]);

    const updateDelegate = React.useCallback((delegate: AttendeeListModel, updatedDelegate: AttendeeListModel, forceImmediateSave?: boolean) => {
        const updatedAttendeeRegister = attendeeRegister.map(d => d.id === delegate.id ? updatedDelegate : d);
        if (!isDelegateEqual(delegate, updatedDelegate)) {
            const changedProperties = getChangedProperties(delegate, updatedDelegate);
            if (changedProperties) {
                const lastItemOnStack = undoStack[undoStack.length - 1];
                if (!(lastItemOnStack && lastItemOnStack.id === delegate.id && isArrayEqual(lastItemOnStack.property, changedProperties))) {
                    setUndoStack(prevUndoStack => [...prevUndoStack, {
                        id: delegate.id,
                        notSaved: delegate.notSaved,
                        property: changedProperties,
                        value: delegate
                    }]);
                }
            }
        }
        setAttendeeRegister(updatedAttendeeRegister);

        if (forceImmediateSave) {
            onSubmitRegister(updatedAttendeeRegister, false);
        }
    }, [attendeeRegister, onSubmitRegister, undoStack]);

    const updateFieldsForMarkup = React.useCallback((delegate: AttendeeListModel, updatedFieldsForMarkup: string[]) => {
        if (!(delegate.id in fieldsForMarkup)) {
            const newFieldsForMarkup = { ...fieldsForMarkup, [delegate.id]: updatedFieldsForMarkup };
            setFieldsForMarkup(newFieldsForMarkup);
            return;
        }
    }, [fieldsForMarkup]);

    const updateFormErrorsOnFieldChange = React.useCallback((delegate: AttendeeListModel, formField: keyof AttendeeListModel, valid: boolean,
        awareFormErrors?: EventInstanceUpdateDelegateFormErrors) => {
        const checkedFormErrors = awareFormErrors || formErrors;

        if (!(delegate.id in checkedFormErrors)) {
            if (!valid) {
                const set = new Set<string>();
                set.add(formField);
                const newFormErrors = { ...checkedFormErrors, [delegate.id]: set };
                setFormErrors(newFormErrors);
                return newFormErrors;
            }
            return checkedFormErrors;
        }

        const newFormErrors = { ...checkedFormErrors };
        if (newFormErrors[delegate.id].has(formField) && valid) {
            newFormErrors[delegate.id].delete(formField);
        } else if (!newFormErrors[delegate.id].has(formField) && !valid) {
            newFormErrors[delegate.id].add(formField);
        }

        if (newFormErrors[delegate.id].size === 0) {
            delete newFormErrors[delegate.id];
        }

        setFormErrors(newFormErrors);
        return newFormErrors;
    }, [formErrors, setFormErrors]);

    const onAddRowClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const delegateComparisonType = CalculateDelegateComparisonType(eventInstance.workflowType);

        const newDelegate: AttendeeListModel = {
            id: v4(),
            delegateComparisonType,
            eventInstanceIds: isEventInstanceMultiDay ? eventInstanceGroupDays.map(eigd => eigd.eventInstanceId) : [],
            managers: delegateComparisonType === DelegateComparisonTypeEnum.Uin
                ? [{ name: "", telephone: "", email: "" }]
                : [],
            notSaved: true
        };

        setUndoStack([...undoStack, {
            id: newDelegate.id,
            notSaved: newDelegate.notSaved,
            property: null,
            value: newDelegate
        }]);
        setAttendeeRegister(prev => [...prev, newDelegate]);
    }, [eventInstance, eventInstanceGroupDays, isEventInstanceMultiDay, undoStack]);

    const removeDelegate = React.useCallback((delegate: AttendeeListModel) => {
        setAttendeeRegister(attendeeRegister.filter(d => d.id !== delegate.id));
        setUndoStack([...undoStack, {
            id: delegate.id,
            notSaved: delegate.notSaved,
            property: null,
            value: delegate
        }]);
        cleanUpRemovedDelegate(delegate);
    }, [attendeeRegister, cleanUpRemovedDelegate, undoStack]);

    const forceDrivingLicenceNumberValidation = React.useCallback((id: string, drivingLicenceNumber: string) => {
        const drivingLicenceNumbers
            = [ ...alreadyUsedDrivingLicenceNumbers.filter(dln => dln.id !== id).map(attendee => (attendee.drivingLicenceNumber)), drivingLicenceNumber];
        let checkedFormErrors = formErrors;
        for (const attendee of attendeeRegister) {
            const drivingLicenceNumberValidator = licenceValidator(attendee?.drivingLicenceCountry, attendee.surname, drivingLicenceNumbers);
            const licenceValid = drivingLicenceNumberValidator(attendee.id === id ? drivingLicenceNumber : attendee.drivingLicenceNumber);
            checkedFormErrors = updateFormErrorsOnFieldChange(attendee, "drivingLicenceNumber", licenceValid.type === ValidationResultType.Pass,
                checkedFormErrors);
        }
    }, [attendeeRegister, updateFormErrorsOnFieldChange, alreadyUsedDrivingLicenceNumbers, formErrors]);

    const forceUinValidation = React.useCallback((id: string, uin: string) => {
        const uins
            = [ ...alreadyUsedUins.filter(auuin => auuin.id !== id).map(attendee => (attendee.uin)), uin];
        let checkedFormErrors = formErrors;
        for (const attendee of attendeeRegister) {
            const validator = uinValidator(uins);
            const uinValid = validator(attendee.id === id ? uin : attendee.uin);
            checkedFormErrors = updateFormErrorsOnFieldChange(attendee, "uin", uinValid.type === ValidationResultType.Pass,
                checkedFormErrors);
        }
    }, [attendeeRegister, updateFormErrorsOnFieldChange, alreadyUsedUins, formErrors]);

    const updateBackendValidationInProgress = React.useCallback((validationInProgress: boolean) => setBackendValidationInProgress(validationInProgress), []);

    const fetchingRowDataInProgress = React.useCallback((delegateId: string) => {
        if (!delegateDataFetchingInProgress.some(af => af === delegateId)) {
            setDelegateDataFetchingInProgress([ ...delegateDataFetchingInProgress, delegateId ]);
        }
    }, [delegateDataFetchingInProgress]);

    const fetchingRowDataFinished = React.useCallback((delegateId: string) => {
        setDelegateDataFetchingInProgress(delegateDataFetchingInProgress.filter(af => af !== delegateId));
    }, [delegateDataFetchingInProgress]);

    const updateDelegatesElement = (
        (!registerEditable && areDelegatesEditable) ?
            (<Button
                type="button"
                onClick={onEditRegister}
                content="UPDATE DELEGATES"
                floated="left"
                disabled={!isAdminApp && registerClosed}
                className="update-delegates-attendance-button"
            />)
            : (registerEditable && canChangeDelegateAmount) ?
                (<Button
                    type="button"
                    onClick={onAddRowClick}
                    content='Add new delegate'
                    floated="left"
                    labelPosition='left'
                    className="update-delegates-attendance-button"
                    icon='add circle'
                    disabled={!canAddMoreDelegates}
                />)
                : null
    );

    const updateDelegatesElementMobile = (
        (!registerEditable && areDelegatesEditable) ?
            (<Button
                type="button"
                onClick={onEditRegister}
                content="UPDATE"
                disabled={!isAdminApp && registerClosed}
            />)
            : (registerEditable && canChangeDelegateAmount) ?
                (<Button
                    type="button"
                    onClick={onAddRowClick}
                    content='Add'
                    disabled={!canAddMoreDelegates}
                />)
                : null
    );

    const undoButton = (
        <Button
            type="button"
            className="cancel-action"
            disabled={undoStack.length === 0}
            onClick={onUndoChanges}
            labelPosition="left"
            floated="right"
            icon
        >
            <Icon name="undo" />
            Undo
        </Button>
    );

    const blockAddingDelegatesElement = ((eventInstance.bookingType === BookingTypeEnum.Closed
        || (isAdminApp && allowCommercialOpenBookingManualDelegateAdjustments))
     && registerEditable && !canAddMoreDelegates)
        ? (<span className="update-delegates-attendance-span">Cannot add delegates over open places count</span>)
        : null;

    const attendeeColumnWidth = React.useMemo(() =>
        (hasCpc || hasUin)
            ? isTrainerApp
                ? 6
                : 3
            : (isTrainerApp && !isAfv)
                ? 7
                : 4
    , [hasCpc, hasUin, isTrainerApp, isAfv]);
    const drivingLicenceColumnWidth = React.useMemo(() =>
        (hasCpc || hasUin)
            ? registerEditable
                ? isTrainerApp
                    ? 6
                    : 5
                : isTrainerApp
                    ? 4
                    : 3
            : registerEditable
                ? (isTrainerApp && !isAfv)
                    ? 9
                    : 7
                : (isTrainerApp && !isAfv)
                    ? 6
                    : 4
    ,[hasCpc, hasUin, registerEditable, isTrainerApp, isAfv]);
    const dqcOrUinColumnWidth = React.useMemo(() =>
        registerEditable
            ? isTrainerApp
                ? 4
                : hasUin
                    ? 4
                    : 3
            : isTrainerApp
                ? 3
                : hasUin
                    ? 3
                    : 2
    ,[registerEditable, isTrainerApp, hasUin]);
    const contactColumnWidth = React.useMemo(() =>
        (hasUin && !isTrainerApp)
            ? 4
            : 5
    , [hasUin, isTrainerApp]);

    return (
        <>
            <Form onSubmit={handleFormSubmit} >
                <Grid container padded className="attendance-register detail-list-container">
                    <Grid.Row only="computer">
                        <Grid.Column className="purple-header" computer={attendeeColumnWidth}>
                            {isEventInstanceOpen
                                ? "Org & Attendee"
                                : hasUin
                                    ? "Attendee Details"
                                    : "Attendee Name"}
                        </Grid.Column>
                        <Grid.Column className="purple-header" width={drivingLicenceColumnWidth} only="computer">
                            Driving Licence
                        </Grid.Column>
                        {hasCpc && (
                            <Grid.Column className="purple-header" width={dqcOrUinColumnWidth} only="computer">
                                DQC
                            </Grid.Column>
                        )}
                        {(!isTrainerApp || isAfv) && (
                            <Grid.Column className="purple-header" width={contactColumnWidth} only={!isAfv ? "computer" : undefined}>
                                Contact
                            </Grid.Column>
                        )}
                        {hasUin && (
                            <Grid.Column className="purple-header" width={dqcOrUinColumnWidth} only="computer">
                                Managers
                            </Grid.Column>
                        )}
                        {!registerEditable && (
                            <Grid.Column className="purple-header" computer={3} textAlign="right">
                                Attended
                            </Grid.Column>
                        )}
                    </Grid.Row>
                    <Divider className="full-width" />
                    {attendeesToShow.map(attendee => (
                        <React.Fragment key={attendee.id}>
                            <UpdateDelegatesTableRow
                                delegate={attendee}
                                isEditable={registerEditable}
                                eventInstanceId={eventInstanceId}
                                alreadyUsedDrivingLicenceNumbers={alreadyUsedDrivingLicenceNumbers}
                                alreadyUsedUins={alreadyUsedUins}
                                updateFormErrorsOnFieldChange={updateFormErrorsOnFieldChange}
                                updateDelegate={updateDelegate}
                                isStageTwo={false}
                                removeDelegate={removeDelegate}
                                forceDrivingLicenceNumberValidation={forceDrivingLicenceNumberValidation}
                                forceUinValidation={forceUinValidation}
                                isEventInstanceOpen={isEventInstanceOpen}
                                isEventInstanceMultiDay={isEventInstanceMultiDay}
                                eventInstanceWorkflowType={eventInstance?.workflowType}
                                eventInstanceProductCategory={eventInstance?.productCategory}
                                isEventInstanceAfv={isAfv}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                updateFieldsForMarkup={updateFieldsForMarkup}
                                fieldsForMarkup={fieldsForMarkup[attendee.id] || []}
                                updateBackendValidationInProgress={updateBackendValidationInProgress}
                                fetchingRowDataInProgress={fetchingRowDataInProgress}
                                fetchingRowDataFinished={fetchingRowDataFinished}
                                submitted={submitted}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                            />
                            <Divider className="full-width"  />
                        </React.Fragment>
                    ))}
                    <Media lessThan="computer" className="detail-list-container">
                        <br />
                        <Grid.Column className="container">
                            <Grid.Row className="container">
                                {undoButton}
                            </Grid.Row>
                            <Grid.Row className="container">
                                {updateDelegatesElementMobile}
                            </Grid.Row>
                            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                                <Grid.Row className="container">
                                    {registerEditable &&
                                        <Button
                                            type="submit"
                                            onClick={onSaveChanges}
                                            content="SAVE"
                                            disabled={changedDataSubmitting || backendValidationInProgress || dataFetchingInProgress} />}
                                </Grid.Row>
                                <Grid.Row className="container">
                                    {registerEditable && <Button type="button" onClick={onCancelEdit} content="CANCEL" className="cancel-action" />}
                                </Grid.Row>
                                <Grid.Row className="container">
                                    {!registerEditable &&
                                        <Button type="button" disabled={isBeforeEventInstanceDateTime || !anyAttendeesAdded} content="CONFIRM" />}
                                </Grid.Row>
                            </Authorize>
                        </Grid.Column>
                    </Media>
                    <RegisterStatistics
                        registerStats={getRegisterStats(attendees, isEventInstanceMultiDay, eventInstanceId)}
                        updateDelegatesElement={updateDelegatesElement}
                        blockAddingDelegatesElement={blockAddingDelegatesElement}
                    />
                </Grid>
                <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                    <Media greaterThanOrEqual="computer">
                        <Segment className="button-segment no-margin-top">
                            <div className="button-container right-align right-align-last-element">
                                {registerEditable &&
                                <Button
                                    type="submit"
                                    onClick={onSaveChanges}
                                    disabled={changedDataSubmitting || backendValidationInProgress || dataFetchingInProgress}
                                    content="SAVE CHANGES"
                                    floated="right"
                                />
                                }
                                {registerEditable &&
                                    <>
                                        <Button type="button" onClick={onCancelEdit} content="CANCEL" className="cancel-action" floated="right" />
                                        {undoButton}
                                    </>
                                }
                                {!registerEditable &&
                                    <Button
                                        type="button"
                                        onClick={handleFormSubmit}
                                        disabled={isBeforeEventInstanceDateTime || !anyAttendeesAdded}
                                        content="CONFIRM DELEGATES"
                                        floated="right" />}
                            </div>
                        </Segment>
                    </Media>
                </Authorize>
            </Form>
        </>
    );
};

const isArrayEqual = (first: string[], second: string[]) => {
    return isEqual(sortBy(first), sortBy(second));
};

const isDelegateEqual = (first: AttendeeListModel, second: AttendeeListModel) => {
    const allKeys = Object.keys(first).concat(Object.keys(second))
        .filter((value, index, self) => self.indexOf(value) === index);

    for (const key of allKeys) {
        const firstValue = first[key];
        const secondValue = second[key];

        if (isNullOrUndefinedOrEmptyString(firstValue) && isNullOrUndefinedOrEmptyString(secondValue)) {
            continue; // consider these equal
        }

        // check if firstValue is null or undefined and secondvalue is empty array
        if ((isNullOrUndefined(firstValue) || firstValue.length === 0) && Array.isArray(secondValue) && secondValue.length === 0) {
            continue; // consider these equal
        }

        if (!isEqualWith(firstValue, secondValue)) {
            return false;
        }
    }

    return true;
};

const getChangedProperties = (first: AttendeeListModel, second: AttendeeListModel) => {
    const changes: string[] = [];
    const ignore: string[] = [nameof<AttendeeListModel>("dateModified"), nameof<AttendeeListModel>("listUpdated")];

    const checkChanges = (a: any, b: any, prefix = "") => {
        forEach(a, (value, key) => {
            const currentKey = prefix ? `${prefix}.${key}` : key;

            if (ignore.includes(currentKey)) {
                return;
            }

            if (value === null || value === undefined) {
                if (b[key] !== null && b[key] !== undefined) {
                    changes.push(currentKey);
                }
            } else if (!Array.isArray(value) && isObject(value) && isObject(b[key])) {
                checkChanges(value, b[key], currentKey);
            } else if (!isEqual(value, b[key])) {
                changes.push(currentKey);
            }
        });

        // check for properties that exist on second object but not on first
        forEach(b, (_value, key) => {
            const currentKey = prefix ? `${prefix}.${key}` : key;

            if (!Object.prototype.hasOwnProperty.call(a, key) && !ignore.includes(currentKey)) {
                changes.push(currentKey);
            }
        });
    };

    checkChanges(first, second);
    return changes;
};
