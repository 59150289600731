/* eslint-disable max-lines */
import * as React from "react";
import { Link, push } from "redux-little-router";
import { Tab, Grid, Container, Dimmer, Loader, Divider, Message, Segment, Button, Input } from "semantic-ui-react";
import { orderBy } from "lodash";
import { BookingSource, EventInstanceDetails, Order, OrderPaymentStatus } from "../model";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { ExtendedDropdown } from "@common/components/ExtendedDropdown";
import { EditModal } from "./EditModal";

import "./DetailsTab.scss";
import { SendBookerConfirmationModal } from "./SendBookerConfirmationModal";
import { SendOpenBookingReminderModal } from "./SendOpenBookingReminderModal";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { useDispatch, useSelector } from "react-redux";
import { CancelModal } from "./CancelModal";
import { RefundModal } from "./RefundModal";
import { ChangeBookerModal } from "./ChangeBookerModal";
import { parse } from "querystring";
import { formatPrice, getPaymentStatusName, prepareOrdersUrl } from "../helpers";
import { organisationIdSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { routeCorporateUserIdSelector } from "@common/crud/corporateUser/selectors";
import { OrderRebookSelectModal } from "./OrderRebookSelectModal";
import { updateInvoiceReference } from "../actions";
import { getStateOfBookingWithCancellationDate } from "../../eventInstance/helpers";
import { ProcessInvoiceModal } from "./ProcessInvoiceModal";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

interface DetailsTabProps {
    order: Order;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ order }) => {
    const [queryRedirected, setQueryRedirected] = React.useState(false);
    const [refreshingRecord, setRefreshingRecord] = React.useState(false);
    const [selectedEventInstance, setSelectedEventInstance] = React.useState<EventInstanceDetails>(undefined);
    const [isReferenceInEditMode, setIsReferenceInEditMode] = React.useState(false);
    const [invoiceReference, setInvoiceReference] = React.useState<string>();
    const [initialInvoiceReference, setInitialInvoiceReference] =  React.useState<string | undefined>();
    const toggleIsReferenceInEditMode = React.useCallback(() => setIsReferenceInEditMode(!isReferenceInEditMode), [isReferenceInEditMode]);
    // hiding BNPL cancel order as a workaround for now
    // to fixed in release 65.1
    // https://thettcgroup.tpondemand.com/entity/86253-order-cancellation-not-honouring-that-payment
    const showCancelModal: boolean = !order.bookNowPayLater;

    const eventManagementPath = useSelector(universalEventManagementPathSelector);
    const corporateOrganisationId = useSelector(organisationIdSelector);
    const corporateUserId = useSelector(routeCorporateUserIdSelector);
    const businessLineType = useSelector(businessLineTypeSelector);

    const dispatch = useDispatch();

    const onlyOneEventInstanceAvailable = React.useMemo(() => order?.eventInstances?.length === 1, [order?.eventInstances?.length]);
    const bookNowPayLaterOrder = React.useMemo(() => order?.bookNowPayLater, [order?.bookNowPayLater]);

    const orderPaymentStatusName = React.useMemo(
        () => order?.paymentStatus !== undefined ? getPaymentStatusName(order.paymentStatus) : "",
        [order.paymentStatus]);

    React.useEffect(() => {
        let locationEventInstanceId: string | string[];
        if (location.search) {
            const locationSearch = parse(location.search.substring(1));
            locationEventInstanceId = locationSearch.eventInstanceId;
        }

        if (!queryRedirected && !!locationEventInstanceId && order?.eventInstances?.length > 0) {
            setSelectedEventInstance(order.eventInstances.find(ei => ei.eventInstanceId === locationEventInstanceId));
            setQueryRedirected(true);
        } else if (order?.eventInstances?.length === 1) {
            setSelectedEventInstance(order.eventInstances[0]);
        } else if (order?.eventInstances?.length > 1 && selectedEventInstance?.eventInstanceId) {
            setSelectedEventInstance(order.eventInstances.find(ei => ei.eventInstanceId === selectedEventInstance.eventInstanceId));
        }
    }, [order?.eventInstances, selectedEventInstance, refreshingRecord, queryRedirected]);

    React.useEffect(() => {
        if (!initialInvoiceReference) {
            setInitialInvoiceReference(order?.invoiceReference);
        }
        setInvoiceReference(order?.invoiceReference);
    }, [initialInvoiceReference, order?.invoiceReference]);

    const selectedEventInstanceTotal = selectedEventInstance?.eventInstanceId
        ? order?.eventInstanceTotals
            ? order?.eventInstanceTotals[selectedEventInstance?.eventInstanceId] ?? undefined
            : undefined
        : undefined;

    const selectedEventInstanceTotalIncludingNonRefundedCancellations = selectedEventInstance?.eventInstanceId
        ? order?.eventInstanceTotalsIncludingNonRefundedCancellations
            ? order?.eventInstanceTotalsIncludingNonRefundedCancellations[selectedEventInstance?.eventInstanceId] ?? undefined
            : undefined
        : undefined;

    const selectedEventInstanceTotalPayments = selectedEventInstance?.eventInstanceId
        ? order?.eventInstanceTotalPayments
            ? order?.eventInstanceTotalPayments[selectedEventInstance?.eventInstanceId] ?? undefined
            : undefined
        : undefined;

    const selectedEventInstancePaymentStatus = selectedEventInstance?.eventInstanceId
        ? order?.eventInstancePaymentStatuses
            ? order?.eventInstancePaymentStatuses[selectedEventInstance?.eventInstanceId] ?? undefined
            : undefined
        : undefined;

    const selectedEventInstancePaymentStatusName = selectedEventInstancePaymentStatus || selectedEventInstancePaymentStatus === 0
        ? getPaymentStatusName(selectedEventInstancePaymentStatus)
        : undefined;

    const selectedEventInstanceTotalPendingRefunds = selectedEventInstance?.eventInstanceId
        ? order?.eventInstanceTotalPendingRefunds
            ? order?.eventInstanceTotalPendingRefunds[selectedEventInstance?.eventInstanceId] ?? undefined
            : undefined
        : undefined;

    const isAnyInvoicePaid = Object.values(order?.eventInstancePaymentStatuses ?? []).some(status => status === OrderPaymentStatus.Paid);

    React.useEffect(() => {
        if (refreshingRecord) {
            setRefreshingRecord(false);
        }
    }, [refreshingRecord]);

    const onInvoiceReferenceUpdate = React.useCallback(() => {
        if (order?.id && invoiceReference) {
            dispatch(updateInvoiceReference(order.id, invoiceReference));
            setInitialInvoiceReference(invoiceReference);
        }
        toggleIsReferenceInEditMode();
    }, [dispatch, invoiceReference, order.id, toggleIsReferenceInEditMode]);

    const onInputReferenceChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceReference(e.target.value);
    }, []);

    const cancelInvoiceReferenceUpdate = React.useCallback(() => {
        setInvoiceReference(initialInvoiceReference);
        toggleIsReferenceInEditMode();
    }, [initialInvoiceReference, toggleIsReferenceInEditMode]);

    const eventInstanceOptions = React.useMemo(() => {
        return orderBy(order?.eventInstances, ei => ei.eventInstanceStartDate).map(course => {
            const status = getStateOfBookingWithCancellationDate(
                !!course.cancellationDate,
                course.cancelled,
                course.completed);
            return ({ text: `${course.eventTypeName} - ${course.eventInstanceDeliveryDateTime.format("LL")} - ${status}`, value: course.eventInstanceId });
        }) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order?.eventInstances, refreshingRecord]);

    const coursesInOrder = React.useMemo(() => {
        return order?.eventInstances?.filter(course => !course.cancelled).length || 0;
    }, [order?.eventInstances]);

    const attendeesInOrder = React.useMemo(() => {
        return order?.eventInstances?.filter(course => !course.cancelled).reduce((acc, course) => acc + course.attendeeCount, 0) || 0;
    }, [order?.eventInstances]);

    const handleVisibleCourseChange = React.useCallback((value: string) => {
        setQueryRedirected(true);
        dispatch(push(prepareOrdersUrl(
            eventManagementPath,
            value
                ? `orders/${order.id}?eventInstanceId=${value}`
                : `orders/${order.id}`,
            corporateOrganisationId,
            corporateUserId
        )));
        setSelectedEventInstance(order.eventInstances.filter(a => a.eventInstanceId === value)[0]);
    }, [corporateOrganisationId, corporateUserId, dispatch, order.eventInstances, order.id, eventManagementPath]);

    const buildDayPartDisplay = React.useCallback((startDate: moment.Moment, startTime: moment.Duration, eventDuration: moment.Duration) => {
        const startTimeString = (startTime && startTime.format(DateFormat.Time, { trim: false })) || "";
        const endTimeString = (startTime &&
            eventDuration &&
            startTime.clone().add(eventDuration).format(DateFormat.Time, { trim: false })) || "";
        const startDateString = (startDate && startDate.format("dddd Do MMMM YYYY")) || "";
        return startDateString + " - " + startTimeString + " - " + endTimeString;
    }, []);

    const dayToBold = React.useMemo(() => {
        if (selectedEventInstance?.otherStartTimes?.length > 0) {
            const compareTo = moment();
            if (selectedEventInstance.eventInstanceDeliveryDateTime.isAfter(compareTo)) {
                return 1;
            } else {
                for (let i = 0; i < selectedEventInstance.otherStartTimes.length; i++) {
                    if (selectedEventInstance.otherStartTimes[i].eventInstanceDeliveryDateTime.isAfter(compareTo)) {
                        return i + 2;
                    }
                }
            }
        }

        return undefined;
    }, [selectedEventInstance]);

    if (!order?.id) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    return (
        <Tab.Pane>
            <Grid stackable>
                {order.cancellationDate && (
                    <Grid.Row>
                        <Grid.Column>
                            <Container className="white-panel">
                                <Message as={Segment} error>
                                    <p>This order booking is cancelled.</p>
                                    <p>Reason: {order.otherReason}</p>
                                </Message>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {order.refundError && <Message as={Segment} error><h5>{order.refundError}</h5></Message>}
                <Grid.Row className="remove-bottom-padding">
                    <Grid.Column width={8}>
                        <Container className="white-panel">
                            <h5>Organisation details</h5>
                            <LabelAndValue label="Organisation Name" value={
                                <Link href={`${eventManagementPath}/organisations/${order.organisationId}`}>
                                    {order.organisationName}
                                </Link>}
                            />
                            <LabelAndValue label="BDM" value={order.organisationBdmId
                                ? `${order.organisationBdmName} [${order.organisationBdmEmail}]`
                                : "None"} />
                        </Container>
                        <Container className="white-panel">
                            <h5>Billing details</h5>
                            <LabelAndValue label="Name on Tax Receipt" value={order.taxReceiptInformation?.nameOnTaxReceipt} />
                            {order.organisationAddress?.addressLine1 && (
                                <LabelAndValue label="Address Line 1" value={order.organisationAddress.addressLine1} />
                            )}
                            {order.organisationAddress?.addressLine2 && (
                                <LabelAndValue label="Address Line 2" value={order.organisationAddress.addressLine2} />
                            )}
                            {order.organisationAddress?.addressLine3 && (
                                <LabelAndValue label="Address Line 3" value={order.organisationAddress.addressLine3} />
                            )}
                            {order.organisationAddress?.city && (
                                <LabelAndValue label="Town" value={order.organisationAddress.city} />
                            )}
                            {order.organisationAddress?.postalCode && (
                                <LabelAndValue label="Postcode" value={order.organisationAddress.postalCode} />
                            )}
                        </Container>
                        {order.bookNowPayLater && (
                            <Container className="white-panel">
                                <h5>Invoice details</h5>
                                {order.invoiceEmail && (
                                    <LabelAndValue label="Accounts payable email" value={order.invoiceEmail} />
                                )}
                                {order.invoicePhoneNumber && (
                                    <LabelAndValue label="Accounts payable contact no." value={order.invoicePhoneNumber} />
                                )}
                                {(order.invoiceAddress?.addressLine1 && order.invoiceAddress?.addressLine1 !== order.organisationAddress?.addressLine1 &&
                                    order.invoiceAddress?.postalCode && order.invoiceAddress?.postalCode !== order.organisationAddress?.postalCode &&
                                    order.invoiceAddress?.city && order.invoiceAddress?.city !== order.organisationAddress?.city) && (
                                    <>
                                        {order.invoiceAddress?.addressLine1 && (
                                            <LabelAndValue label="Address Line 1" value={order.invoiceAddress.addressLine1} />
                                        )}
                                        {order.invoiceAddress?.addressLine2 && (
                                            <LabelAndValue label="Address Line 2" value={order.invoiceAddress.addressLine2} />
                                        )}
                                        {order.invoiceAddress?.addressLine3 && (
                                            <LabelAndValue label="Address Line 3" value={order.invoiceAddress.addressLine3} />
                                        )}
                                        {order.invoiceAddress?.city && (
                                            <LabelAndValue label="Town" value={order.invoiceAddress.city} />
                                        )}
                                        {order.invoiceAddress?.postalCode && (
                                            <LabelAndValue label="Postcode" value={order.invoiceAddress.postalCode} />
                                        )}
                                    </>
                                )}
                            </Container>
                        )}
                        <Container className="white-panel">
                            <h5>Booker details</h5>
                            <div className="detail-with-action">
                                <LabelAndValue label="Booker Name" value={
                                    <Link href={`${eventManagementPath}/organisations/${order.organisationId}/users/${order.bookerId}`}>
                                        {order.bookerName}
                                    </Link>}
                                />
                                <ChangeBookerModal
                                    order={order}
                                    setRefreshingRecord={setRefreshingRecord}
                                />
                            </div>
                            <LabelAndValue label="Booker Email" value={order.bookerEmail} />
                            <LabelAndValue label="Booker Phone Number" value={order.bookerPhoneNumber || "None"} />
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Container className="blue-panel">
                            <h5>Order details</h5>
                            <LabelAndValue label="Booking source" value={BookingSource[order.bookingSource]} />
                            <LabelAndValue label="Courses in order" value={coursesInOrder || "0"} />
                            <LabelAndValue label="Seats in order" value={attendeesInOrder || "0"} />
                            <div className="order-actions">
                                {(!order.bookNowPayLater || !isAnyInvoicePaid) &&
                                <>
                                    {
                                        showCancelModal && <CancelModal
                                            orderId={order.id}
                                            canCancel={!order.cancellationDate && (order.eventInstances?.some(ei => !ei.completed
                                            && !ei.cancelled && !ei.cancellationDate) || false)}
                                            order={order}
                                            validationAmount={order.attendeeCount}
                                            setRefreshingRecord={setRefreshingRecord}
                                        />}
                                    <OrderRebookSelectModal
                                        order={order}
                                        canRebook={(order.eventInstances?.some(ei => !ei.completed) || false)}
                                    />
                                </>}
                                {order.basketId && (
                                    <SendBookerConfirmationModal
                                        orderId={order.id}
                                        canSend={!order.cancellationDate}
                                    />
                                )}
                            </div>
                        </Container>
                        {eventInstanceOptions.length > 1 && (
                            <Container className="blue-panel">
                                <h5>Course selection</h5>
                                {!selectedEventInstance && <span>More than one course available. Please select one from dropdown.</span>}
                                <div className="field-wrapper">
                                    <ExtendedDropdown
                                        key={selectedEventInstance?.eventInstanceId}
                                        value={selectedEventInstance?.eventInstanceId}
                                        dynamicOptions
                                        options={eventInstanceOptions}
                                        onChange={handleVisibleCourseChange}
                                        search
                                    />
                                </div>
                                <Divider />
                            </Container>
                        )}
                        {selectedEventInstance && (
                            <Container className="blue-panel">
                                <h5>Course details</h5>
                                {selectedEventInstance.cancellationDate && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Container className="blue-panel">
                                                <Message as={Segment} error>
                                                    <p>This course booking is cancelled.</p>
                                                    <p>Reason: {selectedEventInstance.otherReason}</p>
                                                </Message>
                                            </Container>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                <LabelAndValue label="Venue" value={
                                    <Link href={`${eventManagementPath}/eventInstances/${selectedEventInstance.eventInstanceId}`}>
                                        {selectedEventInstance.venueName}
                                    </Link>}
                                />
                                <LabelAndValue label="Product" value={
                                    <Link href={`${eventManagementPath}/eventTypes/${selectedEventInstance.eventTypeId}`}>
                                        {selectedEventInstance.eventTypeName}
                                    </Link>}
                                />
                                <LabelAndValue
                                    label={selectedEventInstance.otherStartTimes?.length > 0 ? "Day 1" : "Date And Time"}
                                    value={buildDayPartDisplay(
                                        selectedEventInstance.eventInstanceStartDate,
                                        selectedEventInstance.eventInstanceStartTime,
                                        selectedEventInstance.eventInstanceDuration
                                    )}
                                    valueClass={dayToBold === 1 ? "bold-day" : ""}
                                />
                                {selectedEventInstance.otherStartTimes?.length > 0 && selectedEventInstance.otherStartTimes.map((startTime, index) => (
                                    <LabelAndValue
                                        key={index}
                                        label={`Day ${index + 2}`}
                                        value={buildDayPartDisplay(
                                            startTime.eventInstanceStartDate,
                                            startTime.eventInstanceStartTime,
                                            startTime.eventInstanceDuration
                                        )}
                                        valueClass={dayToBold === index + 2 ? "bold-day" : ""}
                                    />
                                ))}
                                <LabelAndValue label="Number of seats" value={
                                    <span>
                                        {selectedEventInstance.attendeeCount || "0"}{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${eventManagementPath}/eventInstances/${selectedEventInstance.eventInstanceId}/delegates`}
                                        >
                                            (go to delegates)
                                        </a>
                                    </span>} />
                                <LabelAndValue
                                    label="Status"
                                    value={getStateOfBookingWithCancellationDate(
                                        !!selectedEventInstance.cancellationDate,
                                        selectedEventInstance.cancelled,
                                        selectedEventInstance.completed)} />
                                <div className="order-actions">
                                    {(!order.bookNowPayLater || (selectedEventInstance?.eventInstanceId
                                        && order?.eventInstancePaymentStatuses
                                        && order.eventInstancePaymentStatuses[selectedEventInstance.eventInstanceId] !== OrderPaymentStatus.Paid))
                                        && <>
                                            <CancelModal
                                                orderId={order.id}
                                                eventInstance={selectedEventInstance}
                                                order={order}
                                                canCancel={!selectedEventInstance.cancellationDate && !selectedEventInstance.cancelled
                                                    && !selectedEventInstance.completed}
                                                validationAmount={selectedEventInstance.attendeeCount}
                                                setRefreshingRecord={setRefreshingRecord}
                                            />
                                            <OrderRebookSelectModal
                                                order={order}
                                                selectedEventInstance={selectedEventInstance}
                                                canRebook={!selectedEventInstance.completed}
                                            />
                                        </>}
                                    {order.basketId && (
                                        <>
                                            <SendBookerConfirmationModal
                                                orderId={order.id}
                                                canSend={!order.cancellationDate && !selectedEventInstance.cancellationDate
                                                    && !selectedEventInstance.cancelled && !selectedEventInstance.completed}
                                                eventInstanceId={selectedEventInstance.eventInstanceId}
                                            />
                                            <SendOpenBookingReminderModal
                                                orderId={order.id}
                                                canSend={!selectedEventInstance.cancellationDate && !selectedEventInstance.cancelled
                                                    && !selectedEventInstance.completed}
                                                eventInstanceId={selectedEventInstance.eventInstanceId}
                                            />
                                        </>
                                    )}
                                </div>
                            </Container>
                        )}
                        <Container className="blue-panel">
                            <h5>Payment details</h5>
                            <LabelAndValue
                                label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? "Course Total"
                                    : "Order Total"}
                                value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? formatPrice(selectedEventInstanceTotal ?? 0)
                                    : formatPrice(order.total ?? 0)} />
                            {businessLineType !== BusinessLineType.Construction && <LabelAndValue label="Bundle discount" value={order.bundleDiscountApplied
                                ? order.bundleDiscountPercentageConfigured
                                    ? `${order.bundleDiscountPercentageConfigured}%`
                                    : "Yes"
                                : "N/A"} />}
                            <LabelAndValue label="Payment method" value={order.bookNowPayLater
                                ? "Purchase Order"
                                : "Card"} />
                            {order.bookNowPayLater && <>
                                {!isReferenceInEditMode && <div className="invoice-reference-row">
                                    <LabelAndValue label="PO Reference" value={invoiceReference} />
                                    <Button
                                        icon="pencil"
                                        size="mini"
                                        onClick={toggleIsReferenceInEditMode}
                                    /></div>}
                                {isReferenceInEditMode && <>
                                    <LabelAndValue label="PO Reference" value="" />
                                    <div className="invoice-reference-row">
                                        <Input
                                            value={invoiceReference}
                                            type="text"
                                            onChange={onInputReferenceChange}
                                        />
                                        <div>
                                            <Button
                                                icon="check"
                                                size="mini"
                                                disabled={!invoiceReference}
                                                onClick={onInvoiceReferenceUpdate}
                                            />
                                            <Button icon="delete" color="red" size="mini" onClick={cancelInvoiceReferenceUpdate} />
                                        </div>
                                    </div></>}
                            </>}
                            {order.bookNowPayLater &&
                            <LabelAndValue
                                label="Payment status"
                                value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? selectedEventInstancePaymentStatusName
                                    : orderPaymentStatusName} />}
                            <LabelAndValue
                                label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? "Course Total Payments"
                                    : "Order Total Payments"}
                                value={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? formatPrice(selectedEventInstanceTotalPayments ?? 0)
                                    : formatPrice(order.totalPayments ?? 0)}
                            />
                            <LabelAndValue
                                label={(onlyOneEventInstanceAvailable || selectedEventInstance)
                                    ? "Course Remaining Balance"
                                    : "Order Remaining Balance"}
                                value={bookNowPayLaterOrder
                                    ? ((onlyOneEventInstanceAvailable || selectedEventInstance)
                                        ? selectedEventInstancePaymentStatus === OrderPaymentStatus.Outstanding
                                        : order?.paymentStatus === OrderPaymentStatus.Outstanding)
                                        ? (onlyOneEventInstanceAvailable || selectedEventInstance)
                                            ? formatPrice((selectedEventInstanceTotalIncludingNonRefundedCancellations ?? 0)
                                                - (selectedEventInstanceTotalPayments ?? 0))
                                            : formatPrice((order.totalIncludingNonRefundedCancellations ?? 0)
                                                - (order.totalPayments ?? 0))
                                        : formatPrice(0)
                                    : (onlyOneEventInstanceAvailable || selectedEventInstance)
                                        ? formatPrice(-selectedEventInstanceTotalPendingRefunds)
                                        : formatPrice(-order.totalPendingRefunds)}
                            />
                            <div className="order-actions">
                                {(!bookNowPayLaterOrder && ((!onlyOneEventInstanceAvailable && selectedEventInstance)
                                    ? (selectedEventInstanceTotalPendingRefunds ?? 0)
                                    : order.totalPendingRefunds) > 0) && (
                                    <RefundModal
                                        order={order}
                                        selectedEventInstance={selectedEventInstance}
                                        selectedEventInstanceTotal={selectedEventInstanceTotal}
                                        selectedEventInstanceTotalPayments={selectedEventInstanceTotalPayments}
                                        selectedEventInstanceTotalPendingRefunds={selectedEventInstanceTotalPendingRefunds}
                                        setRefreshingRecord={setRefreshingRecord} />
                                )}
                                {
                                    (selectedEventInstance || onlyOneEventInstanceAvailable) &&
                                    selectedEventInstance?.eventInstanceId &&
                                    order.id &&
                                    invoiceReference &&
                                    (selectedEventInstancePaymentStatus === OrderPaymentStatus.Outstanding
                                        || selectedEventInstancePaymentStatus === OrderPaymentStatus.Unknown) &&
                                    <ProcessInvoiceModal
                                        eventInstanceId={selectedEventInstance.eventInstanceId}
                                        orderId={order.id}
                                        selectedEventInstanceTotalIncludingNonRefundedCancellations={
                                            selectedEventInstanceTotalIncludingNonRefundedCancellations
                                        }
                                        eventInstanceTotalPayments={selectedEventInstanceTotalPayments}
                                        invoiceReference={invoiceReference}
                                        setRefreshingRecord={setRefreshingRecord} />
                                }
                            </div>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="remove-top-padding">
                    <Grid.Column>
                        <div className="order-actions">
                            <EditModal
                                order={order}
                                setRefreshingRecord={setRefreshingRecord}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};
