/* eslint-disable @typescript-eslint/no-redeclare */
export const CREATE_DELEGATE_SUCCESS = "CREATE_DELEGATE_SUCCESS";
export type CREATE_DELEGATE_SUCCESS = typeof CREATE_DELEGATE_SUCCESS;

export const CREATE_DELEGATE = "CREATE_DELEGATE";

export const LOAD_DELEGATES_SUCCESS = "LOAD_DELEGATES_SUCCESS";
export type LOAD_DELEGATES_SUCCESS = typeof LOAD_DELEGATES_SUCCESS;

export const LOAD_DELEGATES = "LOAD_DELEGATES";

export const LOAD_DELEGATE_DETAIL_SUCCESS = "LOAD_DELEGATE_DETAIL_SUCCESS";
export type LOAD_DELEGATE_DETAIL_SUCCESS = typeof LOAD_DELEGATE_DETAIL_SUCCESS;

export const LOAD_DELEGATE_DETAIL = "LOAD_DELEGATE_DETAIL";

export const SAVE_DELEGATE_SUCCESS = "SAVE_DELEGATE_SUCCESS";
export type SAVE_DELEGATE_SUCCESS = typeof SAVE_DELEGATE_SUCCESS;

export const SAVE_DELEGATE = "SAVE_DELEGATE";

export const SAVE_DELEGATE_DELETION_DATE_SUCCESS = "SAVE_DELEGATE_DELETION_DATE_SUCCESS";
export type SAVE_DELEGATE_DELETION_DATE_SUCCESS = typeof SAVE_DELEGATE_DELETION_DATE_SUCCESS;
