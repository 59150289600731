import axios from "axios";
import { AttendeeListModel } from "@common/crud/attendee/model";
import { parseModel } from "@common/crud/attendee/helpers";

export class AttendeeGroupApi {

    protected readonly attendeeApi: string;

    constructor() {
        this.attendeeApi = "/api/attendee-group";
    }

    public async cancelGroupBooking(attendeeId: string, correlationId: string, rebookReason: string, otherReason: string, freeRebookingEnabled: boolean) {
        const response = await axios.post(`${this.attendeeApi}/cancelGroupBooking`, { attendeeId, correlationId, rebookReason, otherReason,
            freeRebookingEnabled });
        const data = response.data as AttendeeListModel;
        return parseModel(data);
    }
}
