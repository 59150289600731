import { createSelector } from "reselect";
import { Attendee } from "@common/crud/attendee";
import { attendeesSelector } from "@common/crud/attendee/selectors";
import { ApplicationsOwnState } from "@common/appCommonState";
import { TrainerRole } from "@common/auth/model";
import moment from "moment";
import { createBasePathSelector } from "@common/redux-helpers";
import { EventInstance, AppState, SearchOptions, Area, LanguageEnum, YesNoAnyEnum } from "./model";
import { DateFormat } from "../common/DateTimeFormats";
import { Apps } from "@common/model";
import { appSelector, routerPathnameSelector, lineOfBusinessRouteSelector } from "../common/selectors";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { WorkflowTypeEnum, GetBusinessHomeNameForWorkflow, BusinessHomeRouteName } from "@common/crud/eventType/model";

export const registerSelector = (state: AppState) => state.router.query.register;
export const eventInstancesSelector = (state: AppState) => state.eventInstances;
export const groupedEventInstancesSelector = (state: AppState) => state.groupedEventInstances;
export const eventInstanceIdSelector = (state: AppState) => state.router.params.eventInstanceId;
export const digitalPlanningSessionsSelector = (state: AppState) => state.digitalPlanningSessions;
export const reservedIdSelector = (state: AppState) => state.router.params.reservedSeatId;
export const totalNumberOfEventInstancesSelector = (state: AppState) => state.totalNumberOfEventInstances;
export const eventInstanceFinanceDataSelector = (state: AppState) => state.eventInstanceFinanceData;
export const eventInstanceEnquiriesDataSelector = (state: AppState) => state.eventInstanceEnquiriesData;

export const eventInstanceSelector = createSelector(
    eventInstancesSelector,
    eventInstanceIdSelector,
    (eventInstances: EventInstance[], id: string): EventInstance => {
        return eventInstances.find(c => c.id === id) || {} as any;
    }
);

export const eventInstanceGroupByIdSelector = (id: string) => createSelector(
    groupedEventInstancesSelector,
    (groups: EventInstanceGroupModel[]) => {
        return groups?.find(g => g.id === id) || { } as EventInstanceGroupModel;
    }
);

export const eventInstanceBreadcrumbSelector = createSelector(
    eventInstancesSelector,
    eventInstanceIdSelector,
    (eventInstances: EventInstance[], id: string): string => {
        const eventInstance = eventInstances.find(c => c.id === id);

        return eventInstance ?
            `${eventInstance.venueName} ${eventInstance.startDate?.format(DateFormat.Short) ?? ""}` :
            "Course Details";
    }
);

export const trainerScheduleSelector = createSelector(
    routerPathnameSelector,
    (pathName: string): string => pathName.substring(0, pathName.indexOf("/schedule")));

export const baseEventManagementSelector = createSelector(
    routerPathnameSelector,
    lineOfBusinessRouteSelector,
    (pathname: string, businessLine: BusinessHomeRouteName): string => {
        if (pathname.indexOf(businessLine) !== -1) {
            return `${pathname.substring(0, pathname.indexOf(businessLine) + businessLine.length)}`;
        }
        return "";
    }
);

export const selectedEventInstanceListPath = (area: Area, workflowType: WorkflowTypeEnum = WorkflowTypeEnum.Dors) => createSelector(
    appSelector,
    routerPathnameSelector,
    basePathSelector,
    (app: Apps, pathname: string, basePath: string): string => {

        return getPathString(workflowType, basePath, app, pathname, area);
    }
);

export function getPathString(workflowType: WorkflowTypeEnum, basePath: string, app: Apps, pathname: string, area: Area): string {
    if (area === Area.AdminEventManagementSpreadingOverview) {
        return basePath;
    }
    if (app === Apps.Police) {
        return "/eventInstances";
    }
    const workflowPath = workflowType === WorkflowTypeEnum.DDRS ? "drink-drive" : "eventInstances";
    if (app === Apps.Trainer) {
        return workflowPath;
    } else {
        const part = GetBusinessHomeNameForWorkflow(workflowType);
        return area === Area.AdminEventManagementEventInstance
            ? pathname
            : `/${part}/${workflowPath}`;
    }
};

export const dynamicSelectedEventInstanceListPath = (area: Area) => createSelector(
    appSelector,
    routerPathnameSelector,
    basePathSelector,
    (app: Apps, pathname: string, basePath: string): (workflowType: WorkflowTypeEnum) => string  => {

        return (workflowType: WorkflowTypeEnum) => getPathString(workflowType, basePath, app, pathname, area);
    }
);

export const selectedEventInstancesSelector = (eventInstanceIds?: string[]) => createSelector(
    eventInstancesSelector,
    (instances: EventInstance[]) => {
        return instances.filter(i => eventInstanceIds?.includes(i.id));
    }
);

export const attendeesForEventInstanceSelector = (eventInstanceId: string) => createSelector(
    attendeesSelector,
    eventInstanceIdSelector,
    (attendees: Attendee[]) => {
        if (attendees) {
            return attendees.filter(c => c.eventInstanceId === eventInstanceId) || [];
        } else {
            return [];
        }
    }
);

export const isUserTrainer = (state: ApplicationsOwnState) => state.currentUser && state.currentUser.roles?.indexOf(TrainerRole) !== -1;

export const eventInstanceSearchOptionsSelector = (state: AppState, initialStateWorkflowType?: WorkflowTypeEnum): SearchOptions => ({
    ...state.router.query,
    hasAvailableSeats: state.router.query.hasAvailableSeats === "true",
    eventType: state.router.query.eventType?.split(",") ?? [],
    eventStatus: state.router.query.eventStatus?.split(",") ?? [],
    forceId: state.router.query.forceId?.split(",") ?? [],
    venueId: state.router.query.venueId?.split(",") ?? [],
    oneToOneOnly: state.router.query.oneToOneOnly === "true",
    getOnlyUnconfirmedByTrainer: state.router.query.getOnlyUnconfirmedByTrainer === "true",
    toDate: state.router.query.toDate ? moment(state.router.query.toDate) : undefined,
    fromDate: state.router.query.fromDate ? moment(state.router.query.fromDate) : moment().startOf("day"),
    maxPageSize: state.router.query.maxPageSize ? parseInt(state.router.query.maxPageSize, 10) : 100,
    language: LanguageEnum[state.router.query.language],
    daysOfWeek: state.router.query?.daysOfWeek?.split(",").map(x => Number.parseInt(x, 10)) ?? [],
    dayPeriod: state.router.query?.dayPeriod?.split(",").map(x => Number.parseInt(x, 10)) ?? [],
    includeAlreadyStartedGroups: state.router.query.includeAlreadyStartedGroups
        ? state.router.query.includeAlreadyStartedGroups  === "true" : true,
    requiresTrainers: state.router.query.requiresTrainers ? YesNoAnyEnum[state.router.query.requiresTrainers] : YesNoAnyEnum.Any,
    availableForOtherTrainers: state.router.query.availableForOtherTrainers ? YesNoAnyEnum[state.router.query.availableForOtherTrainers] : YesNoAnyEnum.Any,
    hasWarnings: state.router.query.hasWarnings === "true",
    stateWorkflowType: state.router.query.stateWorkflowType ? +state.router.query.stateWorkflowType : initialStateWorkflowType,
    corporateOrganisationIds: state.router.query.corporateOrganisationIds?.split(",") ?? []
});

export const basePathSelector = (state: AppState) => {
    if (state.router.pathname.indexOf("eventInstances") !== -1) {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("eventInstances") + "eventInstances".length);
    } else if (state.router.pathname.indexOf("schedule") !== -1) {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("schedule") + "schedule".length);
    }

    return state.router.pathname.substring(0, state.router.pathname.indexOf("pending-courses") + "pending-courses".length);
};

export const ddrsBasePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("drink-drive") + "drink-drive".length);

export const variableBasePathSelector = (state: AppState) => {
    const pathName = state.router.pathname;
    const drinkDrive = "drink-drive";
    if (pathName.indexOf(drinkDrive) > 0) {
        return state.router.pathname.substring(0, state.router.pathname.indexOf(drinkDrive) + drinkDrive.length);
    } else {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("eventInstances") + "eventInstances".length);
    }
};

export const eventManagementPathSelector = createBasePathSelector("police-and-court-event-management");
