import * as React from "react";
import { Button, ModalContentProps, SemanticICONS, Modal, Header } from "semantic-ui-react";
import { toast } from "@common/toasts";

interface ConditionalConfirmButtonProps {
    label?: string;
    icon?: SemanticICONS;
    basic?: boolean;
    onConfirm: () => void;
    confirmButtonDisabled: boolean;
    header?: string;
    content?: React.ReactNode | ModalContentProps;
    disabled?: boolean;
    buttonClassName: string;
}

interface ConditionalConfirmButtonState {
    open: boolean;
}

export class ConditionalConfirmButton extends React.Component<ConditionalConfirmButtonProps, ConditionalConfirmButtonState> {
    constructor(props: ConditionalConfirmButtonProps) {
        super(props);
        this.state = { open: false };
    }

    public render() {
        const { children, label, icon, basic, header, content, disabled, buttonClassName, confirmButtonDisabled, onConfirm } = this.props;
        const { open } = this.state;
        return (
            <div>
                <Button
                    className={buttonClassName}
                    label={label}
                    icon={icon}
                    basic={basic}
                    content={children}
                    onClick={this.show}
                    disabled={disabled}
                />
                <Modal
                    open={open}
                    onClose={this.hideAndToastError}
                    size="small"
                >
                    <Header content={header} />
                    <Modal.Content>
                        {content}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.hideAndToastError} className="cancel-action" content="Cancel" />
                        <Button disabled={confirmButtonDisabled} onClick={onConfirm} floated="right" content="OK" />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

    private show = () => this.setState({ open: true });

    private hideAndToastError = () => {
        this.setState({ open: false });
        toast.error("Invoice submit process cancelled");
    };
}
