import * as React from "react";
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { validators } from "not-valid";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { ValidationResultType } from "not-valid/bin/results";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { ApplicationState } from "../../applicationState";
import { MissingBookingProps } from "../models";
import { BookingDetailsField } from "./BookingDetailsField";

interface BookingDetailsEmailFieldProps {
    useSessionStorage?: boolean;
    bookingId: string;
}

export const BookingDetailsEmailField: React.FC<BookingDetailsEmailFieldProps> = ({ useSessionStorage, bookingId }) => {
    const [t] = useTranslation("BookingDetails");
    const { errors, touched } = useFormikContext<MissingBookingProps>();
    const isError = errors.email && touched.email;
    const isClientAdvisor = useSelector((state: ApplicationState) => isUserClientAdvisor(state));

    const validate = React.useCallback((value: string) => {
        if (useSessionStorage ?? true) {
            sessionStorage.setItem(`booking-email-${bookingId}`, value);
        }
        const emailValidators = [validators.validEmail(), validators.validLength({ max: 50 })];
        if (value && emailValidators.every(fn => fn(value.toString()).type === ValidationResultType.Pass) || (isClientAdvisor && !value)) {
            return null;
        }

        return value?.length > 50 ? "Please enter no more than 50 characters" : t("EMAIL_REQUIRED");
    }, [bookingId, isClientAdvisor, t, useSessionStorage]);

    return (
        <BookingDetailsField>
            <Form error={isError}>
                <Field
                    name="email"
                    type="text"
                    as={Form.Input}
                    label={<label>{t("EMAIL")}</label>}
                    className={`field-with-note${isClientAdvisor?"":" required"}`}
                    placeholder={t("EMAIL")}
                    error={isError}
                    validate={validate}
                />
                <p className="field-note">{t("EMAIL_NOTE")}</p>
                <ErrorMessage
                    name="email"
                    className="error-margin-bottom"
                >
                    {(msg) => <Message error header={msg} />}
                </ErrorMessage>
            </Form>
        </BookingDetailsField>
    );
};
