import { FormBaseComponent, SaveDispatchProps, FormState } from "@neworbit/simpleui-forms";

export interface EditProps<TModel> {
    model: TModel;
}

export abstract class ExtendedEditComponent<TModel, TProps extends EditProps<TModel> = EditProps<TModel>, TState extends FormState<TModel> = FormState<TModel>>
    extends FormBaseComponent<TModel, TProps, TState> {

    constructor(props: TProps & SaveDispatchProps<TModel>) {
        super(props);

        this.state = {
            values: Object.assign({}, props.model),
            valid: {},
            showErrors: false
        } as TState;

        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    }

    public componentWillReceiveProps(props: Readonly<TProps & SaveDispatchProps<TModel>>) {
        if (this.props.model !== props.model) {
            this.setState({
                values: Object.assign({}, props.model) as TModel
            });
        }
    }
}
