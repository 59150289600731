import * as React from "react";
import { toast } from "@common/toasts";
import { toast as orgToast } from "react-toastify";
import { useInterval } from "../../hooks/useInterval";
import { GenesysApi } from "@common/genesys/genesysApi";

export function useReportCallRecordingStatus(isUserClientAdvisor: boolean, correlationId: string, pauseRecordingStatusChecking: boolean = false) {
    const [allCallsPaused, setAllCallsPaused] = React.useState<boolean>(null);
    const toastId = React.useRef(null);

    const getStatus = async () => {
        if (isUserClientAdvisor && correlationId !== null && !pauseRecordingStatusChecking) {
            const genesysApi = new GenesysApi();
            const status = await genesysApi.getCallState(correlationId);
            setAllCallsPaused(status.allCallsPaused);
        }
    };

    useInterval(getStatus, 2000);

    React.useEffect(() => {
        if (allCallsPaused === null) {
            return;
        }

        if (toastId !== null) {
            // allCallsPaused has changed so remove the current toast
            orgToast.dismiss(toastId.current);
        }

        if (!allCallsPaused) {
            toastId.current = toast.errorPersistent("Important! The call has not been paused. Please ensure this call is deleted or manually paused");
        } else {
            toastId.current = toast.successPersistent("The call has been successful paused");
        }
    }, [allCallsPaused]);

    React.useEffect(() => {
        return function cleanup() {
            if (toastId !== null) {
                orgToast.dismiss(toastId.current);
            }
        };
    }, [toastId]);
}
