/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_ATTENDEES = "LOAD_ATTENDEES";
export const LOAD_ATTENDEES_SUCCESS = "LOAD_ATTENDEES_SUCCESS";
export type LOAD_ATTENDEES_SUCCESS = typeof LOAD_ATTENDEES_SUCCESS;

export const LOAD_EVENTINSTANCEATTENDEES_SUCCESS = "LOAD_EVENTINSTANCEATTENDEES_SUCCESS";
export type LOAD_EVENTINSTANCEATTENDEES_SUCCESS = typeof LOAD_EVENTINSTANCEATTENDEES_SUCCESS;

export const LOAD_ATTENDEE_DETAIL_SUCCESS = "LOAD_ATTENDEE_DETAIL_SUCCESS";
export type LOAD_ATTENDEE_DETAIL_SUCCESS = typeof LOAD_ATTENDEE_DETAIL_SUCCESS;

export const ADD_NOTE_TO_ATTENDEE_SUCCESS = "ADD_NOTE_TO_ATTENDEE_SUCCESS";
export type ADD_NOTE_TO_ATTENDEE_SUCCESS = typeof ADD_NOTE_TO_ATTENDEE_SUCCESS;

export const REFUND_ATTENDEE_SUCCESS = "REFUND_ATTENDEE_SUCCESS";
export type REFUND_ATTENDEE_SUCCESS = typeof REFUND_ATTENDEE_SUCCESS;

export const REFUND_ATTENDEE_FAILURE = "REFUND_ATTENDEE_FAILURE";
export type REFUND_ATTENDEE_FAILURE = typeof REFUND_ATTENDEE_FAILURE;

export const UPDATE_CONTACT_DETAILS_SUCCESS = "UPDATE_CONTACT_DETAILS_SUCCESS";
export type UPDATE_CONTACT_DETAILS_SUCCESS = typeof UPDATE_CONTACT_DETAILS_SUCCESS;

export const UPDATE_SPECIAL_REQUIREMENTS_SUCCESS = "UPDATE_SPECIAL_REQUIREMENTS_SUCCESS";
export type UPDATE_SPECIAL_REQUIREMENTS_SUCCESS = typeof UPDATE_SPECIAL_REQUIREMENTS_SUCCESS;

export const UPDATE_ADDITIONAL_FEES_SUCCESS = "UPDATE_ADDITIONAL_FEES_SUCCESS";
export type UPDATE_ADDITIONAL_FEES_SUCCESS = typeof UPDATE_ADDITIONAL_FEES_SUCCESS;

export const UPDATE_ATTENDANCE_ID_SUCCESS = "UPDATE_ATTENDANCE_ID_SUCCESS";
export type UPDATE_ATTENDANCE_ID_SUCCESS = typeof UPDATE_ATTENDANCE_ID_SUCCESS;

export const UPDATE_CAR_DETAILS_SUCCESS = "UPDATE_CAR_DETAILS_SUCCESS";
export type UPDATE_CAR_DETAILS_SUCCESS = typeof UPDATE_CAR_DETAILS_SUCCESS;

export const UPDATE_AUTO_REMOVAL_DATE_SUCCESS = "UPDATE_AUTO_REMOVAL_DATE_SUCCESS";
export type UPDATE_AUTO_REMOVAL_DATE_SUCCESS = typeof UPDATE_AUTO_REMOVAL_DATE_SUCCESS;

export const LOAD_ATTENDEE_HISTORY_SUCCESS = "LOAD_ATTENDEE_HISTORY_SUCCESS";
export type LOAD_ATTENDEE_HISTORY_SUCCESS = typeof LOAD_ATTENDEE_HISTORY_SUCCESS;

export const SAVE_ATTENDEE_SUCCESS = "SAVE_ATTENDEE_SUCCESS";
export type SAVE_ATTENDEE_SUCCESS = "SAVE_ATTENDEE_SUCCESS";

export const UPDATE_DRINKDRIVEOFFENDER_SUCCESS = "UPDATE_DRINKDRIVEOFFENDER_SUCCESS";
export type UPDATE_DRINKDRIVEOFFENDER_SUCCESS = typeof UPDATE_DRINKDRIVEOFFENDER_SUCCESS;

export const UPDATE_FLEXIPAY_SUCCESS = "UPDATE_FLEXIPAY_SUCCESS";
export type UPDATE_FLEXIPAY_SUCCESS = typeof UPDATE_FLEXIPAY_SUCCESS;

export const UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS = "UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS";
export type UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS = typeof UPDATE_EVENT_TYPE_ID_AND_NAME_SUCCESS;

export const LOAD_ATTENDEES_FROM_SUCCESS = "LOAD_ATTENDEES_FROM_SUCCESS";
export type LOAD_ATTENDEES_FROM_SUCCESS = typeof LOAD_ATTENDEES_FROM_SUCCESS;

export const UPDATE_DVSA_UPLOAD_STATUS_SUCCESS = "UPDATE_DVSA_UPLOAD_STATUS_SUCCESS";
export type UPDATE_DVSA_UPLOAD_STATUS_SUCCESS = typeof UPDATE_DVSA_UPLOAD_STATUS_SUCCESS;
