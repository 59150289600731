import * as React from "react";
import { Accordion, Grid } from "semantic-ui-react";
import { HistoryRecord, ChangeEventType } from "@common/history/model";

import { HistoryObjectModificationComponent } from "../HistoryObjectModificationComponent";

export interface HistoryContentProps {
    isActive: boolean;
    itemIndex: string;
    historyRecord: HistoryRecord;
}

export const HistoryContent: React.SFC<HistoryContentProps> = ({ isActive, itemIndex, historyRecord }) => {
    return (
        <>
            <Accordion.Content key={`c_${itemIndex}`} active={isActive}>
                <Grid>
                    {historyRecord.eventType !== ChangeEventType.DataModification && <HistoryEventComponent eventMessage={historyRecord.eventMessage} />}

                    {historyRecord.eventType === ChangeEventType.DataModification &&
                        <HistoryObjectModificationComponent historyRecord={historyRecord} key={itemIndex} />}
                </Grid>
            </Accordion.Content>
        </>
    );
};

const HistoryEventComponent: React.SFC<{ eventMessage: string }> = ({ eventMessage }) => (
    <Grid.Row>
        <Grid.Column width={16}>
            <span>{eventMessage}</span>
        </Grid.Column>
    </Grid.Row>
);
