import * as React from "react";
import { EventTypeDetailModel } from "@common/crud/eventType/model";
import { RebookingFeesDetailsSection } from "@common/crud/eventType/components/details/RebookingFeesDetailsSection";

interface Props {
    eventType: EventTypeDetailModel;
}

export const RebookingFeesDetails: React.FC<Props> = ({ eventType }) => {
    return (
        <>
            {
                eventType?.defaultRebookingFee && (
                    <RebookingFeesDetailsSection
                        title="Default Rebooking Fees"
                        workflowType={eventType.workflowType}
                        defaultRebookingFee={eventType.defaultRebookingFee}
                    />
                )
            }
            {
                eventType?.rebookingFees && !!eventType?.rebookingFees.length && (
                    <RebookingFeesDetailsSection
                        title="Rebooking Fees"
                        workflowType={eventType.workflowType}
                        rebookingFees={eventType.rebookingFees}
                    />
                )
            }
        </>
    );
};
