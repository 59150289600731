import { createValidator } from "not-valid";

const cashRange = (min: number, max: number) => {
    // eslint-disable-next-line eqeqeq
    return createValidator<number>(v => v != null && v >= min && v <= max, `Value must be between £${min.toFixed(2)} and £${max.toFixed(2)}`);
};

export const cashRangeValidator = (min: number, max: number) => {
    return [cashRange(min, max)];
};
