/* eslint-disable max-lines */
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import "moment-duration-format";
import * as QueryString from "query-string";
import {
    AttendeeSpreadModel,
    DigitalPlanningSessionsState,
    CancelEventInstanceResponse,
    ClearTrainerAvailabilityEnum,
    ConfirmationStatus,
    ConfirmInterestResponseModel,
    CreateEventInstanceResponse,
    DigitalPlanningSession,
    MultiDayEventInstanceListModel,
    EINotes,
    EventInstance,
    EventInstanceAttendeeSpreadResultModel,
    EventInstanceConfirmInterestModel,
    EventInstanceCreateModel,
    EventInstanceDetailModel,
    EventInstanceEditModel,
    EventInstanceIdFromDetailsId,
    EventInstanceListModel,
    EventInstanceListResponseModel,
    getTrainerCarTypesForEventResponse,
    UpdateSubcontractingPaymentStatusModel,
    SubcontractedSearchOptions,
    SameDayEventInstanceModel,
    SearchOptions,
    TrainerAvailabilityRoleTypeEnum,
    TrainerType,
    UpdateTrainersResponse,
    EventInstanceDigitalPlanningModel,
    OtherTrainerTypeCategoryEnum,
    EventInstanceHealthAndSafetyModel,
    HandbackReasonEnum,
    DigitalPlanningSessionResponse,
    SetEventInstanceAvailabilityForOtherTrainersResponseModel,
    UpdateCertificateCommsDestinationModel,
    FinanceDetails,
    UpdateClosedCourseCommsDestinationsModel,
    AttachedDocumentListModel,
    OtherTrainerBaseFee,
    SendClosedCourseReminderCommsModel,
    SendClosedCourseCreationCommsModel,
    EnquiryEventInstanceData,
    EnquiryStatusUpdateModel,
} from "./model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { TrainerAllocationModel, TrainerToSwapAndAllocateToDetails } from "@common/crud/trainer/model";
import { toast } from "@common/toasts";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { EventTypeApi } from "@common/crud/eventType";
import { EventTypeFile, ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { parseEventInstanceDetailModel } from "@common/crud/eventInstance/helpers";
import { EventTypeCategory } from "../attendee/model";
import { HistoryRecord } from "@common/history/model";
import { BookingsHistoryModel } from "@common/crud/bookingsHistory/model";
import { EventInstanceOrganisationContacts } from "./components/contacts/model";
import { Serialized } from "@common/helpers/type-helpers";
import { BookNowPayLaterOrderAttendees } from "./model";
import { EventInstanceEnquiry } from "../order/model";

export class EventInstanceApi {
    private readonly apiUrl = "/api/eventinstance";

    private readonly digitalPlanningApiUrl = "/api/digitalPlanning";

    public async getAll(options: SearchOptions): Promise<EventInstanceListResponseModel> {
        const parsedOptions = {
            ...options,
            toDate: options.toDate ? options.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: options.fromDate ? options.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };

        const query = QueryString.stringify(parsedOptions);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as EventInstanceListResponseModel;
        const result = {
            eventInstances: data.eventInstances.map(parseEventInstanceDetailModel),
            totalNumberOfEventInstances: data.totalNumberOfEventInstances
        };
        return result;
    }

    public async getAllEventInstancesAndGroups(options: SearchOptions): Promise<MultiDayEventInstanceListModel> {
        const parsedOptions = {
            ...options,
            toDate: options.toDate ? options.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: options.fromDate ? options.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };

        const query = QueryString.stringify(parsedOptions);
        const response = await axios.get(`${this.apiUrl}/multiDay?${query}`);
        const data = response.data as MultiDayEventInstanceListModel;
        const result = {
            eventInstanceGroups: data.eventInstanceGroups?.map(this.parseMultiDayListModel),
            eventInstances: data.eventInstances?.map(parseEventInstanceDetailModel)
        };
        return result;
    }

    public async loadEventInstancesDetailsForGroup(groupId: string): Promise<EventInstanceDetailModel[]> {
        const response = await axios.get(`${this.apiUrl}/event-instances-by-group-id/${groupId}`);
        const data = response.data as EventInstanceDetailModel[];
        const result = [...data.map(parseEventInstanceDetailModel)];
        return result;
    }

    public async getAllSubcontracted(options: SubcontractedSearchOptions):
        Promise<EventInstanceListResponseModel> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}/subcontracting?${query}`);
        const data = response.data as EventInstanceListResponseModel;
        return {
            eventInstances: data.eventInstances.map(parseEventInstanceDetailModel),
            totalNumberOfEventInstances: data.totalNumberOfEventInstances
        };
    }

    public async detail(id: string): Promise<EventInstanceDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async save(eventInstance: EventInstanceEditModel): Promise<EventInstanceDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${eventInstance.id}`, this.serializeObject(eventInstance));
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async saveEventInstanceTimes(eventInstanceId: string, startTime: string, endTime: string, breaks: { startTime: string; endTime: string }[]):
        Promise<EventInstanceDetailModel> {
        const response = await axios.put(`${this.apiUrl}/times/${eventInstanceId}`, { startTime, endTime, breaks });
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async setUpdating(eventInstanceId: string, updating: boolean) {
        return axios.post(`${this.apiUrl}/updating/${eventInstanceId}?updating=${updating}`);
    }

    public async create(eventInstance: EventInstanceCreateModel): Promise<CreateEventInstanceResponse> {
        const parsedEventInstance = {
            ...eventInstance,
            existingPurchaseOrdersList: eventInstance.existingPurchaseOrdersList && [...eventInstance.existingPurchaseOrdersList.filter(po => po)]
        };

        const response = await axios.post<CreateEventInstanceResponse>(this.apiUrl, this.serializeObject(parsedEventInstance));
        const model = response.data;
        model.eventInstance = parseEventInstanceDetailModel(model.eventInstance);
        return model;
    }

    public async createEventInstances(eventInstances: EventInstanceCreateModel[], useExistingTime?: boolean): Promise<EventInstanceDetailModel[]> {
        const parsedEventInstances = eventInstances.map(e => this.serializeObject(e, useExistingTime));

        const response = await axios.post(`${this.apiUrl}/create-courses`, parsedEventInstances);
        const model = response.data as EventInstanceDetailModel[];
        return model.map(parseEventInstanceDetailModel);
    }

    public async updateReasonForHidingEvent(eventInstanceId: string, reasonForHidingEvent: string): Promise<EventInstanceDetailModel> {
        const result = await axios.post(`${this.apiUrl}/${eventInstanceId}/reasonForHidingEvent`, { reasonForHidingEvent });
        return parseEventInstanceDetailModel(result.data);
    }

    public async bulkUpdateReasonForHidingEvent(eventInstanceIds: string[], reasonForHidingEvent: string): Promise<EventInstanceDetailModel[]> {
        const result = await axios.post(`${this.apiUrl}/reasonForHidingEvent`, { reasonForHidingEvent, eventInstanceIds });
        return result.data.map(parseEventInstanceDetailModel);
    }

    public async bulkMakeAvailableToTrainers(eventInstanceIds: string[], isAvailable: boolean): Promise<EventInstanceDetailModel[]> {
        const result = await axios.post(`${this.apiUrl}/bulkAvailableForOtherTrainers`, { eventInstanceIds, isAvailable });
        return result.data.map(parseEventInstanceDetailModel);
    }

    public async cancelTrainer(
        eventInstanceId: string,
        trainerId: string,
        trainerType: TrainerType,
        reasonForCancellation: string,
        otherReason: string,
        clearTrainerAvailability: ClearTrainerAvailabilityEnum) {
        const result = (await axios.post(`${this.apiUrl}/${eventInstanceId}/cancel-trainer`,
            { trainerId, trainerType, reasonForCancellation, otherReason, clearTrainerAvailability }))
            .data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async cancelEventInstance(eventInstanceId: string, reasonForCancel?: string, otherReason?: string,
        cancellationCharge?: number, miscellaneousOrganisationFee?: number): Promise<CancelEventInstanceResponse> {
        const response = await axios.post<CancelEventInstanceResponse>(`${this.apiUrl}/${eventInstanceId}/cancel`, { reasonForCancel, otherReason,
            cancellationCharge, miscellaneousOrganisationFee });
        return response.data;
    }

    public async cancelMultipleEventInstances(eventInstanceIds: string[], reasonForCancel?: string, otherReason?: string): Promise<EventInstanceListModel[]> {
        const result = await axios.post(`${this.apiUrl}/cancel-multiple-events`, { eventInstanceIds, reasonForCancel, otherReason });
        return result.data.map(parseEventInstanceDetailModel);
    }

    public async publishEventInstance(eventInstanceId: string): Promise<EventInstanceDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${eventInstanceId}/publish`);
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async publishMultipleEventInstances(eventInstanceIds: string[], currentUrl: string) {
        await axios.post(`${this.apiUrl}/publishmultiple`, { eventInstanceIds, currentUrl });
    }

    public async resubmitCourseToDors(eventInstanceId: string): Promise<void> {
        const response = await axios.post(`${this.apiUrl}/${eventInstanceId}/resubmitCourseToDors`);
        if (response.data) {
            toast.success("Course added to DORS successfully");
        } else {
            toast.error("Course not added to DORS");
        }
    }

    public async setConfirmationStatus(eventInstanceId: string, status: ConfirmationStatus): Promise<EventInstanceDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${eventInstanceId}/set-event-instance-status`, { confirmationStatus: status });
        const data = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(data);
    }

    public async sendReminderMail(eventInstanceId: string, venueId: string) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/resend-reminder-email`, { venueId });
    }

    public async acceptEventInstances(eventInstanceIds: string[]) {
        await axios.post(`${this.apiUrl}/accept-event-instances`, { eventInstanceIds });
    }

    public async getEventInstanceIdByCourseId(courseId: number): Promise<EventInstanceIdFromDetailsId> {
        const response = await axios.get(`${this.apiUrl}/event-instance-id-by-course-id/${courseId}`);
        return response.data as EventInstanceIdFromDetailsId;
    }

    public async bulkRemoveTrainers(
        eventInstanceIds: string[],
        clearTrainerAvailability: ClearTrainerAvailabilityEnum,
        reason?: string,
        otherReason?: string): Promise<EventInstanceListModel[]> {
        const response = await axios.post(`${this.apiUrl}/remove-trainers`, { eventInstanceIds, clearTrainerAvailability, reason, otherReason });
        const data = response.data as EventInstanceListModel[];
        return data.map(parseEventInstanceDetailModel);
    }

    public async bulkRemoveTrainer(
        eventInstanceIds: string[],
        trainerId: string,
        clearTrainerAvailability: ClearTrainerAvailabilityEnum,
        reason?: string,
        otherReason?: string): Promise<EventInstanceListModel[]> {
        const response = await axios.post(
            `${this.apiUrl}/remove-trainers/${trainerId}`,
            { eventInstanceIds, clearTrainerAvailability, reasonForCancel: reason, otherReason });
        const data = response.data as EventInstanceListModel[];
        return data.map(parseEventInstanceDetailModel);
    }

    public async bulkUpdateDorsOpenPlaces(eventInstanceIds: string[], newOpenPlacesCount: number, deliveryType: DeliveryTypeEnum):
        Promise<EventInstanceListModel[]> {
        const response = await axios.post(`${this.apiUrl}/update-dors-open-places-count`, { eventInstanceIds, newOpenPlacesCount, deliveryType });
        const data = response.data as EventInstanceListModel[];
        return data.map(parseEventInstanceDetailModel);
    }

    public async saveNotes(eventInstanceId: string, notes: EINotes) {
        await axios.patch(`${this.apiUrl}/${eventInstanceId}/notes`, notes);
    }

    public async spreadAttendees(eventInstanceId: string): Promise<EventInstanceAttendeeSpreadResultModel> {
        const response = await axios.post(`${this.apiUrl}/${eventInstanceId}/spreadAttendees`);
        const data = response.data as EventInstanceAttendeeSpreadResultModel;
        data.failedAttendees = data.failedAttendees.map(this.parseFailedAttendees);

        return data;
    }

    public async cancelAutoSpreading(eventInstanceId: string) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/cancelAutoSpreading`);
    }

    public async checkIfAutoSpreadingInProgress(): Promise<boolean> {
        const response = await axios.get(`${this.apiUrl}/autoSpreadingInProgress`);
        return response.data;
    }

    public async notifyAttendeesFailedToMove(eventInstanceId: string, attendeesToNotify: string[]) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/notifyNotMovedAttendees`, { attendeesToNotify });
        toast.success("Attendees have been notified");
    }

    public async setEventInstanceAvailabilityForOtherTrainers(
        eventInstanceId: string,
        trainersToAllocateNumber: number,
        role: TrainerAvailabilityRoleTypeEnum): Promise<SetEventInstanceAvailabilityForOtherTrainersResponseModel> {
        const response = await axios.patch(
            `${this.apiUrl}/${eventInstanceId}/availableForOtherTrainers`,
            { trainersToAllocateNumber, role });
        return response.data;
    }

    public async setEventInstanceAvailabilityForOtherTrainersByTrainer(
        eventInstanceId: string,
        availableForOtherTrainers: boolean,
        moduleType: ModuleTypeEnum,
        handbackReason: HandbackReasonEnum,
        handbackReasonOther: string) {
        const response = await axios.patch(
            `${this.apiUrl}/${eventInstanceId}/trainers/availableForOtherTrainers`,
            { availableForOtherTrainers, moduleType, handbackReason, handbackReasonOther });
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async updateSubcontractingPaymentStatus(
        eventInstanceId: string,
        model: UpdateSubcontractingPaymentStatusModel) {
        await axios.patch(`${this.apiUrl}/${eventInstanceId}/subcontractingPayment`, model);
    }

    public async confirmInterest(eventInstances: EventInstanceConfirmInterestModel[]): Promise<ConfirmInterestResponseModel> {
        const parsedEventInstances = eventInstances.map(ei => ({  ...ei, eventDuration: this.formatDuration(ei.eventDuration) }));
        const response = await axios.post(`${this.apiUrl}/confirm-interest`, parsedEventInstances);
        return {
            unavailableEventInstances: response.data.unavailableEventInstances.map(this.parseConfirmInterestModel),
            eventInstancesOverlappingTrainerSchedule: response.data.eventInstancesOverlappingTrainerSchedule.map(this.parseConfirmInterestModel)
        };
    }

    public async sendBulkEmails(templateId: number, templateName: string, eventInstanceIds: string[]) {
        axios.post(`${this.apiUrl}/send-bulk-emails`, { templateId, templateName, eventInstanceIds });
    }

    public async sendBulkSms(template: string, templateName: string, eventInstanceIds: string[]) {
        axios.post(`${this.apiUrl}/send-bulk-sms`, { template, templateName, eventInstanceIds });
    }

    public async sendTrainerSms(eventInstanceId: string, message: string, trainerIds: string[]) {
        axios.post(`${this.apiUrl}/sendTrainerSms`, { eventInstanceId, message, trainerIds });
    }

    public async allocateTrainers(
        eventInstanceId: string,
        trainerType: TrainerType,
        trainers: TrainerAllocationModel[],
        monitoredTrainersIds: string[]) {
        const result = (await axios.post(`${this.apiUrl}/${eventInstanceId}/trainers`, {
            eventInstanceId,
            trainerType,
            trainers,
            monitoredTrainersIds
        })).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async syncTrainersWithDors(
        eventInstanceId: string): Promise<AxiosResponse<UpdateTrainersResponse>> {
        const result: AxiosResponse<UpdateTrainersResponse> = await axios.post(`${this.apiUrl}/${eventInstanceId}/synctrainers`);
        return result;
    }

    public async getTrainerCarTypesForEvent(
        eventInstanceId: string,
        trainerId: string): Promise<getTrainerCarTypesForEventResponse> {
        const result: AxiosResponse<getTrainerCarTypesForEventResponse> = await axios.get(`${this.apiUrl}/${eventInstanceId}/carTypes/${trainerId}`);
        return result.data;
    }

    public async setTrainerCarTypeForEvent(
        eventInstanceId: string,
        trainerId: string,
        carType: string): Promise<EventInstanceDetailModel> {
        const result: AxiosResponse<EventInstanceDetailModel> = await axios.post(`${this.apiUrl}/${eventInstanceId}/carType/${trainerId}?carType=${carType}`);
        return parseEventInstanceDetailModel(result.data);
    }

    public async addZoomAccount(eventInstanceId: string): Promise<EventInstanceDetailModel> {
        const result: AxiosResponse<EventInstanceDetailModel> = await axios.get(`${this.apiUrl}/${eventInstanceId}/addZoomAccount`);
        return parseEventInstanceDetailModel(result.data);
    }

    public async getSameDayEventInstances(eventInstanceId: string, trainerIds: string[]): Promise<SameDayEventInstanceModel[]> {
        const query = QueryString.stringify({ trainerIds });
        const response: AxiosResponse<SameDayEventInstanceModel[]> = await axios.get(`${this.apiUrl}/${eventInstanceId}/same-day?${query}`);

        return response.data.map(d => ({
            ...d,
            startTime: moment(d.startTime)
        }));
    }

    public async swapTrainer({ trainerId, trainers, searchOptions, trainerType }: {
            trainerId: string;
            trainers: Dictionary<TrainerAllocationModel>;
            searchOptions?: SearchOptions;
            trainerType: TrainerType;
        }): Promise<EventInstanceListModel[]> {
        const result = await axios.post(`${this.apiUrl}/swap-trainer`, {
            trainerId,
            trainers,
            searchOptions,
            trainerType
        });

        return result.data.map(parseEventInstanceDetailModel);
    }

    public async swapTrainers(trainers: Record<string, TrainerToSwapAndAllocateToDetails>) {
        await axios.post(`${this.apiUrl}/swap-trainers`, trainers);
    }

    public async updateEventInstanceTrainerFee(
        eventInstanceId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        fee: number) {
        const trainersPath = this.getPath(trainerType);
        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/${trainersPath}/${eventInstanceTrainerId}/fee`, {
            fee
        })).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async updateCertificateCommsDestination(
        eventInstanceId: string,
        model: UpdateCertificateCommsDestinationModel) {
        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/updateCertificateCommsDestination`, model)).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async updateClosedCourseCommsDestinations(eventInstanceId: string, model: UpdateClosedCourseCommsDestinationsModel) {
        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/updateClosedCourseCommsDestinations`, model)).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async sendClosedCourseReminderComms(eventInstanceId: string, model: SendClosedCourseReminderCommsModel) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/sendClosedCourseReminderComms`, model);
    }

    public async sendClosedCourseCreationComms(eventInstanceId: string, model: SendClosedCourseCreationCommsModel) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/sendClosedCourseCreationComms`, model);
    }

    public async processCertificates(eventInstanceId: string) {
        const result = (await axios.post(`${this.apiUrl}/${eventInstanceId}/processCertificates`)).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async resendCertificateEmails(eventInstanceId: string, delegateIds: string[]) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/resendCertificateEmails`, delegateIds);
    }

    public async getEventInstanceContacts(eventInstanceId: string) {
        const result = (await axios.get<EventInstanceOrganisationContacts[]>(`${this.apiUrl}/${eventInstanceId}/contacts`)).data;
        return result;
    }

    public async getEventInstanceFinanceData(eventInstanceId: string) {
        const result = (await axios.get<BookNowPayLaterOrderAttendees[]>(`${this.apiUrl}/${eventInstanceId}/finance`)).data;
        return result;
    }

    public async getEventInstanceEnquiriesData(eventInstanceId: string) {
        const result = (await axios.get<EnquiryEventInstanceData[]>(`${this.apiUrl}/${eventInstanceId}/enquiries`)).data;
        return result;
    }

    public async updateEnquiryStatus(model: EnquiryStatusUpdateModel) {
        await axios.put(`${this.apiUrl}/updateEnquiryStatus`, model);
    }

    public async setEventInstanceContacts(eventInstanceId: string, contacts: EventInstanceOrganisationContacts[]) {
        const result = (await axios.post<EventInstanceOrganisationContacts[]>(`${this.apiUrl}/${eventInstanceId}/contacts`, contacts)).data;
        return result;
    }

    public async updateEventInstanceTrainerSundries(
        eventInstanceId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        sundries: number) {

        const trainersPath = this.getPath(trainerType);
        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/${trainersPath}/${eventInstanceTrainerId}/sundries`, {
            sundries
        })).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async updateEventInstanceTrainerExternalAssessmentDue(
        eventInstanceId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        externalAssessmentDue: boolean) {

        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/${eventInstanceTrainerId}/${trainerType}/externalAssessmentDue`, {
            externalAssessmentDue
        }
        )).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async updateEventInstanceTrainerFeeNote(
        eventInstanceId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        feeNote: string) {
        const result = (await axios.patch(`${this.apiUrl}/${eventInstanceId}/${eventInstanceTrainerId}/${trainerType}/feenote`, {
            feeNote
        })).data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(result);
    }

    public async getDigitalPlanningProgressByEventTypeCategory(eventTypeCategory: EventTypeCategory) {
        const response = await axios.get<DigitalPlanningSession[]>(
            `${this.digitalPlanningApiUrl}/digitalPlanningProgress/byCategory?eventTypeCategory=${eventTypeCategory}`);
        const data = response.data;
        return data.map(d => this.parseDigitalPlanningSessionModel(d));
    }

    public async getAllDigitalPlanningProgress() {
        const response = await axios.get<DigitalPlanningSession[]>(
            `${this.digitalPlanningApiUrl}/digitalPlanningProgress`);
        const data = response.data;
        return data.map(d => this.parseDigitalPlanningSessionModel(d));
    }

    public async saveDigitalPlanningProgress(model: DigitalPlanningSession) {
        const mappedModel = { ...model, plannedDigitalEvents: model.plannedDigitalEvents?.map((e: EventInstanceEditModel) => this.serializeObject(e, true)) };
        const response = await axios.post<DigitalPlanningSessionResponse>(`${this.digitalPlanningApiUrl}/digitalPlanningProgress`, mappedModel);
        const data = response.data as DigitalPlanningSessionResponse;

        if (!data.validRequest && data.duplicateRequest) {
            toast.error("Failed to save progress because duplicate session exists, please refresh browser and try again after making your changes.");
            return null;
        }

        if (data.digitalPlanningSession?.state > DigitalPlanningSessionsState.InProgress) {
            toast.warning("This planning session can no longer be edited, any changes you have made will not have been saved.");
        }
        return this.parseDigitalPlanningSessionModel(data.digitalPlanningSession);
    }

    public async startDigitalPlanningCourseCreation(month: moment.Moment, eventTypeCategory: EventTypeCategory, forceIds: number[]) {
        const response =
        await axios.post<DigitalPlanningSession>(
            `${this.digitalPlanningApiUrl}/digitalPlanning/create`, { month, eventTypeCategory, forceIds });
        const data = response.data;
        return this.parseDigitalPlanningSessionModel(data);
    }

    public async deleteDigitalPlanningProgress(month: moment.Moment, eventTypeCategory: EventTypeCategory) {
        await axios.post<DigitalPlanningSession>(
            `${this.digitalPlanningApiUrl}/digitalPlanning/delete?month=${month.toISOString()}&eventTypeCategory=${eventTypeCategory}`);
    }

    public async deleteEventInstance(eventInstanceId: string) {
        return axios.delete(`${this.apiUrl}/${eventInstanceId}/`);
    }

    public async getAllocatedTrainerEventInstances(trainerId: string, eventInstanceDigitalPlanningModels: EventInstanceDigitalPlanningModel[]) {
        const eventInstances = eventInstanceDigitalPlanningModels.map(e => this.parseMomentDuration(e));
        const response = await axios.post<EventInstance[]>(`${this.apiUrl}/allocatedTrainerEventInstances`, { trainerId, eventInstances });
        return response.data.map(eventInstance => parseEventInstanceDetailModel(eventInstance));
    }

    public async setOtherRoleForTrainer(
        eventInstanceId: string,
        trainerId: string,
        otherRole: OtherTrainerTypeCategoryEnum): Promise<EventInstanceDetailModel> {
        const result: AxiosResponse = await axios.post(`${this.apiUrl}/${eventInstanceId}/trainer/${trainerId}?otherRole=${otherRole}`);
        return parseEventInstanceDetailModel(result.data as EventInstanceDetailModel);
    }

    public async saveHealthAndSafety(eventInstanceId: string, healthAndSafety: EventInstanceHealthAndSafetyModel) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/healthAndSafety`, healthAndSafety);
    }

    public async saveFinanceDetails(eventInstanceId: string, financeDetails: FinanceDetails): Promise<EventInstanceDetailModel> {
        const result = await axios.post(`${this.apiUrl}/${eventInstanceId}/financeDetails`, financeDetails);
        return parseEventInstanceDetailModel(result.data as EventInstanceDetailModel);
    }

    public async getEventInstanceHistory(eventInstanceId: string): Promise<HistoryRecord[]> {
        const response = await axios.get(`${this.apiUrl}/${eventInstanceId}/history`);
        const data = response.data as HistoryRecord[];
        return data.map(d => this.parseEventInstanceHistory(d));
    }

    public async getEventInstanceBookingHistory(eventInstanceId: string): Promise<BookingsHistoryModel[]> {
        const response = await axios.get(`${this.apiUrl}/${eventInstanceId}/bookingHistory`);
        const data = response.data as BookingsHistoryModel[];
        return data.map(d => this.parseEventInstanceBookingHistory(d));
    }

    public async getAttachedDocuments(eventInstanceId: string): Promise<AttachedDocumentListModel[]> {
        const response = await axios.get<AttachedDocumentListModel[]>(`${this.apiUrl}/${eventInstanceId}/attached-documents`);
        return response.data?.map(d => ({ ...d, dateCreated: moment(d.dateCreated) }));
    }

    public async attachDocument(eventInstanceId: string, name: string, description: string, adminViewOnly: boolean, file: File) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("file", file);

        const response = await axios.post<AttachedDocumentListModel>(
            adminViewOnly
                ? `${this.apiUrl}/${eventInstanceId}/attach-document?adminViewOnly=true`
                : `${this.apiUrl}/${eventInstanceId}/attach-document`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" }
            });

        return {
            ...response.data,
            dateCreated: moment(response.data.dateCreated)
        };
    }

    public async deleteAttachedDocument(eventInstanceId: string, documentId: string) {
        await axios.delete<AttachedDocumentListModel[]>(`${this.apiUrl}/${eventInstanceId}/attached-documents/${documentId}`);
    }

    public async getOtherTrainerFeeFromEventType(eventInstanceId: string) {
        const response = await axios.get(`${this.apiUrl}/${eventInstanceId}/otherTrainerFeesForEventInstance`);
        return response.data as OtherTrainerBaseFee;
    }

    public async createCorporateCoursesForBulkPlanning(eventInstances: EventInstance[]) {
        const parsedEventInstances = eventInstances.map(e => ({
            bookingType: e.bookingType,
            corporateOrganisationId: e.corporateOrganisationId,
            eventTypeAbbreviation: e.eventTypeAbbreviation,
            eventTypeId: e.eventTypeId,
            eventTypeName: e.eventTypeName,
            id: e.id,
            startDate: e.startDate.clone().startOf("day").utc(true),
            startTime: this.formatDuration(e.startTime),
            venueId: e.venueId,
            workflowType: e.workflowType
        }));
        const response = await axios.post(`${this.apiUrl}/corporateBulkPlanning`, parsedEventInstances);
        return response.data as EventInstanceDetailModel[];
    }

    public async saveEiFavouriteStatus(eventInstanceId: string, markAsFavourite: boolean) {
        const response = await axios.put(`${this.apiUrl}/${eventInstanceId}/saveEiFavouriteStatus?${QueryString.stringify({ markAsFavourite })}`);
        const model = response.data as EventInstanceDetailModel;
        return parseEventInstanceDetailModel(model);
    }

    public async getFiles(eventInstanceId: string): Promise<EventTypeFile[]> {
        const response = await axios.get<EventTypeFile[]>(`${this.apiUrl}/${eventInstanceId}/eventTypeFiles`);
        const parsedResponse = this.parseFiles(response.data);
        return parsedResponse;
    }

    public async seatsAvailable(eventInstanceId: string): Promise<number> {
        const response = await axios.get<number>(`${this.apiUrl}/${eventInstanceId}/seatsAvailable`);
        return response.data;
    }

    public async getCourseFees(
        corporateOrganisationId: string,
        eventInstanceTypeId: string,
        startDate: moment.Moment,
        deliveryType: DeliveryTypeEnum): Promise<FinanceDetails> {
        const params = {
            organisationId: corporateOrganisationId,
            eventTypeId: eventInstanceTypeId,
            startDate: startDate.format("MM/DD/YYYY"),
            deliveryType: DeliveryTypeEnum[deliveryType],
        };
        const response = await axios.get(`${this.apiUrl}/courseFees`, { params });
        return response.data;
    }

    public async convertToBookable(eventInstanceId: string, venueId: string): Promise<EventInstanceDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${eventInstanceId}/convertToBookable`, { venueId });
        return parseEventInstanceDetailModel(response.data as EventInstanceDetailModel);
    }

    public async forceRegisterProcesing(eventInstanceId: string) {
        await axios.post(`${this.apiUrl}/${eventInstanceId}/forceRegisterProcessing`);
    }

    public async validateLocationDescription(locationDescription: string) {
        const response = await axios.get(`${this.apiUrl}/validateLocationDescription?city=${locationDescription}`);
        return !!response.data;
    }

    public async addEnquiry(eventInstanceId: string, enquiry: EventInstanceEnquiry) {
        await axios.post(`${this.apiUrl}/addEnquiry/${eventInstanceId}`, enquiry);
    }

    public parseFiles(models: EventTypeFile[]): EventTypeFile[] {
        return models.map((model: EventTypeFile) => ({
            ...model,
            startDate: moment(model.startDate),
            endDate: model.endDate && moment(model.endDate),
        }));
    }

    public parseEventInstanceBookingHistory(model: BookingsHistoryModel) {
        return {
            ...model,
            dateCreated: moment(model.dateCreated),
            offerExpiry: moment(model.offerExpiry),
        };
    }

    public parseEventInstanceHistory(model: HistoryRecord) {
        return {
            ...model,
            dateCreated: moment(model.dateCreated),
            pendingDate: moment(model.pendingDate),
            completionDate: moment(model.completionDate)
        };
    }

    public parseMomentDuration(eventInstance: EventInstanceDigitalPlanningModel): Serialized<EventInstanceDigitalPlanningModel, moment.Duration> {
        return {
            ...eventInstance,
            startTime: this.formatDuration(eventInstance.startTime),
            eventDuration: this.formatDuration(eventInstance.eventDuration)
        };
    }

    public formatDuration(duration: moment.Duration) {
        return duration?.format(DateFormat.TimeWithSeconds, { trim: false });
    }

    public parseDigitalPlanningSessionModel(model: DigitalPlanningSession): DigitalPlanningSession {
        return {
            ...model,
            month: model.month && moment(model.month).utc().startOf("day"),
            plannedDigitalEvents: model.plannedDigitalEvents.map(e => parseEventInstanceDetailModel(e))
        };
    }

    public parseMultiDayListModel(model: EventInstanceGroupModel): EventInstanceGroupModel {
        return {
            ...model,
            startDate: model.eventInstanceGroupItems[0] && moment(model.eventInstanceGroupItems[0].startDate),
            startTime: model.eventInstanceGroupItems[0] && moment.duration(model.eventInstanceGroupItems[0].startTime),
            eventInstanceGroupItems: model.eventInstanceGroupItems.map(i => ({
                ...i,
                startDate: moment(i.startDate),
                eventDuration: moment.duration(i.eventDuration),
                startTime: moment.duration(i.startTime),
            }))
        };
    }

    public serializeObject(eventInstance: EventInstanceCreateModel | EventInstanceEditModel, useExistingTime: boolean = false):
     Serialized<EventInstanceCreateModel | EventInstanceEditModel, moment.Duration> {
        const typeParts = eventInstance.eventTypeParts;
        const parsed = EventTypeApi.parseDictionaryOfEventTypeParts(typeParts);
        const theoryAndPractical = !!eventInstance.theoryStartTime;
        const startTime = theoryAndPractical ? eventInstance.theoryStartTime?.clone()
            : (useExistingTime || eventInstance.workflowType !== WorkflowTypeEnum.DDRS) ?
                eventInstance.startTime :
                typeParts && typeParts[1].suggestedStartTime;
        const endTime = theoryAndPractical ? eventInstance.practicalStartTime?.clone().add(eventInstance.practicalDuration?.clone()) : moment.duration();
        const startDateTime = eventInstance.startDate.clone().add(startTime?.clone());
        const endDateTime = eventInstance.startDate.clone().add(endTime?.clone());
        const eventDuration = theoryAndPractical ? moment.duration(moment(endDateTime).diff(startDateTime))
            : (useExistingTime || eventInstance.workflowType !== WorkflowTypeEnum.DDRS) ? eventInstance.eventDuration : typeParts && typeParts[1].eventDuration;

        const result = {
            ...eventInstance,

            // If the EI is unpublished it is legitimate for the openPlacesCount to be 0
            openPlacesCount: eventInstance.openPlacesCount > 0 || isNullOrUndefined(eventInstance.publishDate)
                ? eventInstance.openPlacesCount
                : eventInstance.maxNumberOfAttendees,
            eventTypeParts: parsed,
            startTime: this.formatDuration(startTime),
            eventDuration: this.formatDuration(eventDuration),
            registrationEndTime: this.formatDuration(eventInstance?.registrationEndTime),
            theoryStartTime: this.formatDuration(eventInstance.theoryStartTime),
            theoryDuration: this.formatDuration(eventInstance.theoryDuration),
            practicalStartTime: this.formatDuration(eventInstance.practicalStartTime),
            practicalDuration: this.formatDuration(eventInstance.practicalDuration),
            educationDuration: this.formatDuration(eventInstance.educationDuration),
            actualEndTime: this.formatDuration(eventInstance.actualEndTime),
            actualStartTime: this.formatDuration(eventInstance.actualStartTime),
        };
        return result as Serialized<EventInstanceEditModel | EventInstanceCreateModel, moment.Duration>;
    }

    public parseConfirmInterestModel(model: EventInstanceConfirmInterestModel) {
        return {
            ...model,
            roleDeliveryDateTime: moment(model.roleDeliveryDateTime),
            roleDeliveryDateTimeEnd: moment(model.roleDeliveryDateTimeEnd),
            eventDuration: moment.duration(model.eventDuration)
        };
    }

    private getPath(trainerType: TrainerType) {
        switch (trainerType) {
            case TrainerType.PracticalTrainer:
                return "practicalTrainers";
            case TrainerType.TheoryTrainer:
                return "trainers";
            case TrainerType.MonitorTrainer:
                return "monitorTrainers";
            case TrainerType.OtherTrainer:
                return "otherTrainers";
            default:
                return "#";
        }
    }

    private parseFailedAttendees(model: AttendeeSpreadModel): AttendeeSpreadModel {
        return {
            ...model,
            completionDate: moment(model.completionDate)
        };
    }
}
