/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form, DropdownItemProps, DropdownMenuProps, Icon, Popup } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";
import { OrganisationState, selectors as organisationsSelectors } from "@common/crud/organisation";
import { EditModal } from "./EditModal";
import { basePathSelector, drinkDriveOffenderSelector } from "@common/crud/drinkDriveOffenders/selectors";
import { Address, optionsFromObject } from "@common/crud/common";
import {
    AlcoholReading,
    AlcoholReadingEnum,
    AllDdrsProviders,
    AppState,
    DrCode,
    DrinkDriveOffenderEditModel,
    Gender,
    HightRisk
} from "@common/crud/drinkDriveOffenders/model";
import { phoneNumber } from "@common/validation";
import { saveDrinkDriveOffender } from "@common/crud/drinkDriveOffenders/actions";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { ddrsEventTypeOptionsSelector } from "@common/crud/eventType/selectors";
import { EventTypeState } from "@common/crud/eventType";
import { MuiDateField } from "@common/components/MuiDateField";

export interface EditProps extends SharedEditProps<DrinkDriveOffenderEditModel> {
    open: boolean;
    courts: DropdownItemProps[];
    genders: DropdownItemProps[];
    ddrsEventTypes: DropdownMenuProps[];
}

export interface DispatchProps extends SaveDispatchProps<DrinkDriveOffenderEditModel> {
    close: () => void;
}

export class EditForm extends EditComponent<DrinkDriveOffenderEditModel, EditProps> {

    public render() {
        const address: Address = {
            addressLine1: this.state.values.address1,
            addressLine2: this.state.values.address2,
            addressLine3: this.state.values.address3,
            postalCode: this.state.values.postcode,
            city: this.state.values.townCity
        };

        const providerOptions = AllDdrsProviders.map<DropdownItemProps>(p => ({ text: p, value: p }));
        const genderInfo ="A gender of Male or Female must be selected as these are the options as required by your referred court";

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={this.state.values.forename}
                    label="Forename"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onChange("forename")}
                />
                <Input.Text
                    value={this.state.values.surname}
                    label="Surname"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onChange("surname")}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails.dateOfBirth}
                    label="Date Of Birth"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.dateOfBirth")}
                />
                <Input.Email
                    value={this.state.values.email}
                    label="Email"
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("email")}
                />
                <Input.Text
                    value={this.state.values.telephone}
                    label="Telephone"
                    validation={phoneNumber()}
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("telephone")}
                />
                <AddressLookup
                    showErrors={this.state.showErrors}
                    address={address}
                    onChange={this.updateAddress}
                />
                <Input.Dropdown
                    label="Scheme"
                    placeholder="Scheme"
                    showErrors={this.state.showErrors}
                    value={this.state.values.eventTypeId}
                    options={this.props.ddrsEventTypes}
                    onChange={this.onChange("eventTypeId")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Dropdown
                    label="Court"
                    placeholder="Court"
                    showErrors={this.state.showErrors}
                    required
                    value={this.state.values.drinkDriveOffenderDetails?.courtId}
                    options={this.props.courts}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.courtId")}
                    dynamicOptions
                    search
                />
                <Input.Dropdown
                    label="Referred Provider"
                    placeholder="Referred Provider"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.referredProvider}
                    options={providerOptions}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.referredProvider")}
                    dynamicOptions
                    search
                    required
                />
                <Input.Text
                    required
                    label="Case Number"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.caseNumber}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.caseNumber")}
                />
                <Input.DropdownNumber
                    label={<label>Gender <Popup content={genderInfo} wide="very" trigger={<a><Icon name="info circle" /></a>} /></label>}
                    placeholder="Gender"
                    showErrors={this.state.showErrors}
                    required
                    value={this.state.values.drinkDriveOffenderDetails?.gender}
                    options={this.props.genders}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.gender")}
                    dynamicOptions
                    search
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails?.sentenceDate}
                    label="Sentence Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.sentenceDate")}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.disqualifiedPeriod}
                    label="Disqualified Period"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.disqualifiedPeriod")}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails?.interimDate}
                    label="Interim Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.interimDate")}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.reduction}
                    label="Reduction"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.reduction")}
                />
                <MuiDateField
                    value={this.state.values.expiryDate}
                    label="Completion Date"
                    required
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("expiryDate")}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.previousConvictions}
                    label="Previous Convictions"
                    required
                    showErrors={this.state.showErrors}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.previousConvictions")}
                />
                <Input.Dropdown
                    label="Supervising Court"
                    placeholder="Supervising Court"
                    required
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.supervisingCourtId}
                    options={this.props.courts}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.supervisingCourtId")}
                    dynamicOptions
                    search
                />
                <Input.Text
                    value={this.state.values.drinkDriveOffenderDetails?.classicCertificateNumber}
                    required
                    label="Classic Certificate Number"
                    showErrors={this.state.showErrors}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.classicCertificateNumber")}
                />
                <Input.DropdownNumber
                    label="DR Code"
                    placeholder="DR Code"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.drCode}
                    options={optionsFromObject(DrCode, "Select")}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.drCode")}
                    dynamicOptions
                    search
                    required
                />
                <Input.DropdownNumber
                    label="Alcohol Reading Type"
                    placeholder="Alcohol Reading Type"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.alcoholReadingType}
                    options={optionsFromObject(AlcoholReading, "Select")}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.alcoholReadingType")}
                    dynamicOptions
                    search
                    required
                />

                {this.state.values.drinkDriveOffenderDetails?.alcoholReadingType > AlcoholReadingEnum.None && (
                    <Input.Number
                        value={this.state.values.drinkDriveOffenderDetails?.alcoholReading}
                        label="Alcohol Reading"
                        showErrors={this.state.showErrors}
                        onChange={this.onDetailsChange("drinkDriveOffenderDetails.alcoholReading")}
                    />
                )}
                <Input.DropdownNumber
                    label="High Risk"
                    placeholder="High Risk"
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.highRisk}
                    options={optionsFromObject(HightRisk, "Select")}
                    onChange={this.onDetailsChange("drinkDriveOffenderDetails.highRisk")}
                    dynamicOptions
                    search
                    required
                />
            </Form>
        );
    }

    public onChange = (propName: keyof DrinkDriveOffenderEditModel) => (value: any, valid: boolean) => {
        this.updateProperty(propName, value, valid);
    };
    public onDetailsChange = (propName: string) => (value: any, valid: boolean) => {
        this.updateNestedProperty(propName, value, valid);
    };

    private updateAddress = (address: Address, valid: boolean) => {
        this.updateProperty("address1", address.addressLine1, valid);
        this.updateProperty("address2", address.addressLine2, valid);
        this.updateProperty("address3", address.addressLine3, valid);
        this.updateProperty("townCity", address.city, valid);
        this.updateProperty("postcode", address.postalCode, valid);
    };

    public submit = () => this.handleSubmit({ preventDefault: (): void => undefined } as any);
}

function mapStateToProps(state: AppState & OrganisationState & EventTypeState) {
    return {
        ddrsEventTypes: ddrsEventTypeOptionsSelector(state),
        courts: organisationsSelectors.courtOrganisationOptionsSelector(state),
        genders: optionsFromObject(Gender),
        model: drinkDriveOffenderSelector(state) as DrinkDriveOffenderEditModel,
        open: state.router.pathname.endsWith("/edit"),
        basePath: basePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (drinkDriveOffender: DrinkDriveOffenderEditModel, basePath: string) => dispatch(saveDrinkDriveOffender(drinkDriveOffender, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {
    return {
        ddrsEventTypes: propsFromState.ddrsEventTypes,
        model: propsFromState.model,
        open: propsFromState.open,
        courts: propsFromState.courts,
        genders: propsFromState.genders,
        save: (drinkDriveOffender: DrinkDriveOffenderEditModel) => propsFromDispatch.dispatchSave(drinkDriveOffender, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`)
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
