import * as React from "react";
import { useSelector } from "react-redux";
import { useInterval } from "@common/hooks/useInterval";
import { AttendeeApi } from "@booking/attendee/attendeeApi";
import { adminAppEndpointSelector } from "@common/crud/common/selectors";
import { bookingSelector, bookingBasePathSelector } from "@booking/landing/selectors";
import { Container } from "semantic-ui-react";
import { State as RouterState, Link } from "redux-little-router";
import { Spinner } from "@common/global";
import { toast } from "@common/toasts";

export const ClientAdvisorRedirectComponent: React.FC = () => {
    const [id, setId] = React.useState<string>("");
    const booking = useSelector(bookingSelector);
    const adminAppEndpoint = useSelector(adminAppEndpointSelector);
    const bookingBasePath = useSelector(bookingBasePathSelector);
    const isOutstandingBalancePayment = useSelector((state: RouterState) => state.router.query.outstandingBalancePayment === "true");

    useInterval(async () => {
        const attendeeApi = new AttendeeApi(booking.id);
        const attendeeId = await attendeeApi.getAttendeeId(booking.seatReservationId, booking.reservedEventInstanceId);

        setId(attendeeId);
    }, 2000);

    React.useEffect(() => window.onpopstate = () => { toast.warning("You can't go back from a booking"); history.forward(); }, []);

    React.useEffect(() => {
        if (id) {
            location.href = `${adminAppEndpoint}police-and-court-event-management/eventInstances/${booking.reservedEventInstanceId}/attendees/${id}`;
        }
    }, [adminAppEndpoint, booking?.reservedEventInstanceId, id]);

    const path = isOutstandingBalancePayment ?
        `${bookingBasePath}/payment-confirmation?finishDateTime=${new Date().toISOString()}` :
        `${bookingBasePath}/booking-confirmation?finishDateTime=${new Date().toISOString()}`;

    return (
        <Container>
            <div>
                <span>You are being redirected to the attendee record <strong>DO NOT</strong> click back into the booking app,<Spinner active={true} /></span>
            </div>

            <Link href={path}>Click here to be redirected to the confirmation page.</Link>
        </Container>
    );
};
