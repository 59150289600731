import { DrinkDriveOffenderDetailModel } from "./model";

export const ddrsOffenderAddress = (ddrsOffender: DrinkDriveOffenderDetailModel) => {

    const address = {
        addressLine1: [ddrsOffender.buildingName, ddrsOffender.buildingNumber, ddrsOffender.address1].filter(x => x).join(" "),
        addressLine2: ddrsOffender.address2,
        addressLine3: ddrsOffender.address3,
        city: ddrsOffender.townCity,
        postalCode: ddrsOffender.postcode
    };
    return address;
};
