import * as React from "react";
import { Tab } from "semantic-ui-react";
import { DdrsAllCountriesFeeConfig,  EventTypeCreateEditModel } from "../../model";
import { DDRSFeesEditAndView } from "../DDRSFeesEditAndView";
import { DDRSFeesTablePicker, DDRSFeeViewer } from "../EventTypeFieldPickerHelpers";

interface CourseFeesTabProps {
    ddrsCourseFees: DdrsAllCountriesFeeConfig;
    showErrors?: boolean;
    onPropertyChange(propName: keyof EventTypeCreateEditModel): (value: any, valid: boolean) => void;
    push: (path: string) => void;
    create: boolean;
}

export const CourseFeesTab: React.FC<CourseFeesTabProps> =({
    ddrsCourseFees,
    showErrors,
    onPropertyChange,
    push, create }) => {

    return (
        <Tab.Pane key="course-fees">
            <h3>Course Fees</h3>
            <DDRSFeesEditAndView ddrsCourseFees={ddrsCourseFees}
                onChange={onPropertyChange("ddrsCourseFees")} CreateFieldPicker={DDRSFeesTablePicker(push, create)}
                showErrors={showErrors} fieldName={"DDRS Course Fees"}
                valueView={DDRSFeeViewer(push, create, null)} />
        </Tab.Pane>
    );
};
