import * as React from "react";
import { getCurrencyFormat } from "@common/formating";
import { Button } from "semantic-ui-react";
import { CurrencyInput } from "@common/global/CurrencyInput";

export const TrainerRoleFeeView = (
    {
        fee,
        additionalFeesTotal,
        sundries,
        canTrainerEditSundries,
        isInSundriesEditMode,
        sundriesError,
        handleSundriesChange,
        updateTrainerSundries,
        cancelSundriesEdit
    }:
    {
        fee: number;
        additionalFeesTotal: number;
        sundries: number;
        canTrainerEditSundries: boolean;
        isInSundriesEditMode: boolean;
        sundriesError: boolean;
        handleSundriesChange: (value: number, valid: boolean) => void;
        updateTrainerSundries: () => void;
        cancelSundriesEdit: () => void;
    }) =>
{
    const getEditButtonIcon = React.useCallback((isInEditMode: boolean) => isInEditMode ? "check" : "pencil", []);

    return (<>
        <tr>
            <th>Base Fee:</th>
            <td>
                {getCurrencyFormat(fee)}
            </td>
        </tr>
        {additionalFeesTotal > 0 && <tr>
            <th>Additional Fees:</th>
            <td>
                {getCurrencyFormat(additionalFeesTotal)}
            </td>
        </tr>}
        <tr>
            <th>Sundries:</th>
            {canTrainerEditSundries ? (
                <td>
                    {isInSundriesEditMode ?
                        <CurrencyInput
                            value={sundries}
                            onChange={handleSundriesChange}
                        /> : getCurrencyFormat(sundries)}
                    <Button
                        icon={getEditButtonIcon(isInSundriesEditMode)}
                        size="mini"
                        disabled={isInSundriesEditMode && sundriesError}
                        onClick={updateTrainerSundries}
                    />
                    {isInSundriesEditMode && <Button icon="delete" color="red" size="mini" onClick={cancelSundriesEdit} />}
                </td>
            ) : (
                <td>
                    {getCurrencyFormat(sundries)}
                </td>
            )}
        </tr>
    </>);
};
