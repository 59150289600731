import * as React from "react";

import { ContinuumApiSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const ContinuumApi: ConfigurationAreaComponent<ContinuumApiSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="ContinuumApi"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "Endpoint", label: "Endpoint" },
                { name: "ApiKey", label: "API Key" },
                { name: "EnableContinuumCourseRequestGeneration", label: "Enable Continuum Course Request Generation", inputType: InputType.Toggle },
            ]}
        />
    );
};
