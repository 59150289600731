import React from "react";
import classes from "./AttendeeDetails.module.scss";
import { Booking, bookingAddress } from "../models";
import { AppCommonState } from "@common/appCommonState";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useTypeSafeTranslation } from "@booking/i18n/resources/useTypeSafeTranslation";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { mergeClasses } from "src/CorporateBooking.App/src/helpers";

const AddressAndPostcode = ({ booking }: {booking: Booking}) => {
    const addr = bookingAddress(booking);
    return ((booking.postcode || "") === "")
        ? <span>{addr}</span>
        : <>{addr} <span className="no-break">{booking.postcode}</span></>;
};

export function AttendeeDetails({ booking, twoColumnLayout = false }: {booking: Booking; twoColumnLayout?: boolean }) {
    const externalResourcesSettings = useSelector((state: AppCommonState) => state.appSettings.externalResourcesSettings);
    const t = useTypeSafeTranslation("BookingDetails");
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const containerClasses = mergeClasses(classes.details, twoColumnLayout? classes.twoColumnLayout: undefined);

    return (<div className={containerClasses}>
        <div className={classes.myDetails}>
            <div className={classes.myDetailsTitle}>{isClientAdvisor? t("ATTENDEE_DETAILS"): t("MY_DETAILS")}</div>
            <div className={classes.detailsField}>
                <div className={classes.detailsFieldName}>{t("NAME")}:&nbsp;</div>
                <div>{booking.forename} {booking.surname}</div>
            </div>
            <div className={classes.detailsField}>
                <div className={classes.detailsFieldName}>{t("ADDRESS")}:&nbsp;</div>
                <div>
                    <AddressAndPostcode booking={booking} />
                </div>
            </div>
        </div>
        {!isClientAdvisor &&
        <div className={classes.editDetails}>
            <Trans i18nKey="BookingDetails:EDIT_INSTRUCTIONS">
                To edit your details please visit the
                <a className="primary-link"
                    target="_blank"
                    rel="noreferrer"
                    href={externalResourcesSettings.dorsPortalLink}>NDORS Portal</a>.
            </Trans>
        </div>}
    </div>);
}
