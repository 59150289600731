import * as React from "react";
import { Link } from "redux-little-router";
import { TypedTable } from "@common/crud/common/TypedTable";
import { InvoiceFormat, TrainerInvoiceLineItem, TrainerInvoiceSubcontractedLineItem } from "../model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { useSelector } from "react-redux";
import { isUserTrainer } from "@common/crud/eventInstance/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import {
    OtherTrainerTypeCategory,
    OtherTrainerTypeCategoryEnum,
    TrainerType,
    TrainerTypeName
} from "@common/crud/eventInstance/model";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";

interface InvoiceLineItemsTableProps {
    lineItems: TrainerInvoiceLineItem[];
    vatPercentage?: number;
    subcontractedType?: "to" | "from";
    invoiceFormat: InvoiceFormat;
    showPrintableFooter?: boolean;
}

export const InvoiceLineItemsTable: React.FC<InvoiceLineItemsTableProps> = ({ lineItems, vatPercentage, subcontractedType, invoiceFormat,
    showPrintableFooter = true }) => {

    const isTrainer = useSelector(isUserTrainer);

    const trainerTypeName = (trainerTypes: TrainerType[], otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum): string => {

        if (trainerTypes.includes(TrainerType.OtherTrainer) && otherTrainerTypeCategory !== null) {
            return OtherTrainerTypeCategory[otherTrainerTypeCategory];
        }

        if (trainerTypes.length === 2) {
            return `${TrainerTypeName[trainerTypes[0]]} and ${TrainerTypeName[trainerTypes[1]]}`;
        }

        return TrainerTypeName[trainerTypes[0]];
    };

    const eventInstanceName = (v: TrainerInvoiceLineItem) => {

        if (v.trainerTypes.some(trainerType =>
            trainerType === TrainerType.RcCoverTrainer ||
            trainerType === TrainerType.RcMonitorTrainer ||
            trainerType === TrainerType.StandbyTrainer)) {
            return "N/A";
        }

        return (
            isTrainer
                ? <>{v.courseVenue} <WelshLanguageIndicator language={v.courseLanguage} /></>
                : <Link href={`${getEventInstanceLink(v)}`}>{v.courseVenue} <WelshLanguageIndicator language={v.courseLanguage} /></Link>
        );
    };

    const getEventInstanceLink = (v: TrainerInvoiceLineItem) => {
        return v.workflowType === WorkflowTypeEnum.DDRS
            ? `/police-and-court-event-management/drink-drive/${v.eventInstanceId}/trainers`
            : `/police-and-court-event-management/eventInstances/${v.eventInstanceId}/trainers`;
    };

    const getCourseTime = (v: TrainerInvoiceLineItem) => {
        if (v.trainerTypes.includes(TrainerType.RcCoverTrainer)) {
            return "";
        }

        return  v.courseTime.format(DateFormat.Time, { trim: false });
    };

    const getCorseDate = (v: TrainerInvoiceLineItem) => {
        const courseDate = v.courseDate.format(DateFormat.Short);
        if (v.workflowType === WorkflowTypeEnum.DDRS) {
            return (
                <>
                    {courseDate}
                    <br />
                    {`Day (${v.dayPartInTheGroup.toString()})`}
                </> );
        }

        return courseDate;
    };

    const baseLineFormatArray = [
        {
            header: "Course Date",
            value: (v: TrainerInvoiceSubcontractedLineItem) => getCorseDate(v),
        },
        {
            header: "Course Time",
            value: (v: TrainerInvoiceSubcontractedLineItem) => getCourseTime(v),
        },
        {
            header: "Scheme",
            value: (v: TrainerInvoiceSubcontractedLineItem) => v.eventTypeName,
        },
        {
            header: "Course Venue",
            value: (v: TrainerInvoiceSubcontractedLineItem) => eventInstanceName(v),
        },
        {
            header: "Role",
            value: (v: TrainerInvoiceSubcontractedLineItem) => trainerTypeName(v.trainerTypes, v.otherTrainerTypeCategory),
        },
        {
            header: vatPercentage ? "Tax Rate" : "",
            value: () => vatPercentage ? "Standard" : "",
        },
        {
            header: "Course Fee",
            value: (v: TrainerInvoiceSubcontractedLineItem) => `£${v.courseFee.toFixed(2)}`,
        },
        {
            header: "Course Sundries",
            value: (v: TrainerInvoiceSubcontractedLineItem) => `£${v.courseSundries.toFixed(2)}`,
        }];

    const courseUpliftFormat =
    (<TypedTable emptyValuesArrayMessage="No courses found for last month" values={lineItems} headerClassName="table-header-row"
        showPrintableFooter={showPrintableFooter} footerClassName="table-footer-row">
        {
            [
                ...baseLineFormatArray,
                {
                    header: "Course Uplift Fee",
                    value: v => `£${v.courseUpliftFee.toFixed(2)}`,
                }
            ]
        }
    </TypedTable>);

    const subcontractedTrainerFormat = (
        <>
            <h3>{subcontractedType ? `Courses subcontracted ${subcontractedType} another Trainer` : "Standard Courses"}</h3>
            <TypedTable emptyValuesArrayMessage="No courses found" values={lineItems as TrainerInvoiceSubcontractedLineItem[]}
                headerClassName="table-header-row" showPrintableFooter={showPrintableFooter} footerClassName="table-footer-row">
                {
                    [
                        ...baseLineFormatArray,
                        {
                            header: subcontractedType ? `Subcontracted ${subcontractedType}` : "",
                            value: v => subcontractedType ? v.subcontractedTrainerName : "",
                        }
                    ]
                }
            </TypedTable>
        </>
    );

    return (
        <>
            {invoiceFormat === InvoiceFormat.OldUpliftFees? courseUpliftFormat: subcontractedTrainerFormat}
        </>
    );
};
