import React from "react";
import classes from "./CourseCoreDetails.module.scss";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { EventInstance } from "@common/crud/eventInstance";
import { Trans, useTranslation } from "react-i18next";
import { Icon } from "@booking/common/Icon";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";

export function CourseCoreDetails({ eventInstance, showLocation = false }: {eventInstance: EventInstance; showLocation?: boolean}) {
    const [t] = useTranslation("CourseCoreDetails");
    const eventTypeName = (eventInstance) ? eventInstance.eventTypeName : null;

    return (<div className={classes.dateAndTime}>
        <div className={classes.scheme}>
            <Icon type={"driver"} />
            <Trans
                i18nKey="ChosenCourse:LANGUAGE_DEPENDENT_FIELD"
                values={{ english: eventTypeName, welsh: eventInstance?.eventTypeNameCy ?? eventTypeName }}>
                {eventTypeName}
            </Trans>
        </div>
        <div className={classes.date}>
            <Icon type={"calendar"} />
            {eventInstance?.startDate && eventInstance.startDate.format(DateFormat.DayMonthYear)}
        </div>
        <div className={classes.time}>
            <Icon type={"time"} />
            {(eventInstance?.startTime && eventInstance?.eventDuration)
                && (eventInstance.startTime.format(DateFormat.Time) +
                    " - " + eventInstance.startTime.clone().add(eventInstance.eventDuration).format(DateFormat.Time))}
        </div>
        {showLocation &&
                <div className={classes.location}>
                    <Icon type={"location"} />
                    <LocationDisplay eventInstance={eventInstance} />
                </div>}
        <div className={classes.detailsField}>
            <div className={classes.detailsFieldName}>{t("LANGUAGE")}:&nbsp;</div>
            {eventInstance?.language && <div>{t(`Languages.${eventInstance.language}`)}</div>}
        </div>
    </div>);
}

function LocationDisplay({ eventInstance }: {eventInstance: EventInstance}) {
    const [t] = useTranslation("CourseCoreDetails");
    if (eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital) {
        return <div>{t("DELIVERED_ONLINE")}</div>;
    }

    return <div>{DisplayAddress({ address: eventInstance?.venueAddress })}</div>;

}

