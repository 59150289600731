import axios from "axios";
import "moment-duration-format";
import { CanUndoCancellationModel } from "../attendee/model";

// Having this in eventInstanceApi.ts breaks the Police App - you get an invalid route when you try and view the register or an attendee
// Removing the `new EventInstanceApi()` call fixes it but it is probably something in the build as the code it fails even if the
// `new EventInstanceApi()` is in a function that is not called in the code.
export class EventInstanceCancellationUndoApi {

    private readonly apiUrl = "/api/eventinstance";

    public async canUndoAttendeeCancellation(id: string): Promise<CanUndoCancellationModel> {
        const response = await axios.post(`${this.apiUrl}/${id}/canUndoAttendeeCancellation`);
        const data = response.data;
        return data;
    }
}
