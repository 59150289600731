import { FormTypeEnum, FormTypeName } from "@common/crud/eventType/model";
import { SectionOneIntroduction } from "@common/crud/eventInstance/components/monitoring/questions/SectionOneIntroduction";
import { Questions } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import {
    SectionTwoDeliverySkills
} from "@common/crud/eventInstance/components/monitoring/questions/SectionTwoDeliverySkills";
import {
    SectionThreeKeyMessages
} from "@common/crud/eventInstance/components/monitoring/questions/SectionThreeKeyMessages";
import {
    SectionFourDiversityAndInclusion
} from "@common/crud/eventInstance/components/monitoring/questions/SectionFourDiversityAndInclusion";
import { SectionFiveFeedback } from "@common/crud/eventInstance/components/monitoring/questions/SectionFiveFeedback";

export const AllSchemeMonitoringForm: Questions = {
    schemeId: null,
    formType: FormTypeName[FormTypeEnum.AllScheme],
    sections: [
        SectionOneIntroduction,
        SectionTwoDeliverySkills,
        SectionThreeKeyMessages,
        SectionFourDiversityAndInclusion,
        SectionFiveFeedback
    ]
};
