import * as React from "react";
import { validators } from "not-valid";

import { AutoComplete } from "@neworbit/simpleui-input/dist/auto-complete-type";
import { ExtendedBaseInput, ExtendedBaseInputSharedProps } from "./ExtendedBaseInput";

export interface ExtendedTextInputProps extends ExtendedBaseInputSharedProps<string> {
    disableAutocorrect?: boolean;
    disableAutocapitalize?: boolean;
    autoComplete?: AutoComplete;
}

export class ExtendedTextInput extends React.Component<ExtendedTextInputProps, {}> {
    public render() {
        const StringBaseInput = ExtendedBaseInput as unknown as new () => ExtendedBaseInput<string>;

        return (
            <StringBaseInput
                defaultValue={""}
                getValueFromInput={this.getValueFromInput}
                type="text"
                requiredValidator={validators.requiredString()}
                {...this.props}
            />
        );
    }

    private getValueFromInput = (value: string) => value;
}
