import * as React from "react";
import { Grid, Button } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { AttendeeRole } from "@common/auth/model";
import { useTranslation } from "react-i18next";

import { Booking } from "../bookings/models";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { useSelector } from "react-redux";
import { appSettingsSelector } from "@booking/bookings/selectors";

export interface RebookButtonProps {
    booking: Booking;
    courseAllowsRebooking: boolean;
    isFreshBooking: boolean;
    path: string;
}

export const RebookButton: React.FC<RebookButtonProps> =
    ({ courseAllowsRebooking, path, isFreshBooking, booking }) => {
        const [t] = useTranslation("RebookButton");
        const settings = useSelector(appSettingsSelector);
        const expiredBooking = !(booking && booking.expiryDate && booking.expiryDate.isAfter(Date.now()));
        const bookingStatusAllowsRebooking = booking && (booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.BookingPending
        || booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.Booked ||
        (settings.testSettings?.allowUnknownStatusDorsBookingToRebook && booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet));

        const attendedAndCompleted = booking?.dorsAttendanceStatus === DorsAttendanceStatusesEnum.AttendedAndCompleted;
        const canRebook = courseAllowsRebooking && bookingStatusAllowsRebooking;

        let noRebookMessage = "";
        if (!expiredBooking) {
            if (attendedAndCompleted) {
                noRebookMessage = t("COURSE_ATTENDED_AND_COMPLETED");
            } else if (!canRebook) {
                noRebookMessage = t("CANNOT_REBOOK_INFO") + t("CALLUS");
            }
        }

        return (
            <Authorize authorize={AttendeeRole}>
                {!expiredBooking && !isFreshBooking &&
                    <Grid.Row className="rebook-section">
                        {!attendedAndCompleted && <p>{t("REBOOK_INTRO")}</p>}
                        {canRebook &&
                            <Button className="rebook-button" href={`${path}/rebook`}>
                                {t("REBOOK")}
                            </Button>
                        }
                        {noRebookMessage &&
                            <div>
                                {noRebookMessage}
                            </div>
                        }
                    </Grid.Row>
                }
            </Authorize>
        );
    };
