import { loadDorsBookings, loadDorsBookingDetailWithAudit } from "./actions";
import { AppState } from "./model";
import { dorsBookingSelector } from "./selectors";
import { detailRoutes } from "./detailRoutes";
import { loadEventTypes } from "../eventType/actions";
import { loadAllPoliceAndCourtOrganisations } from "../organisation/actions";

const detailRoute = () => ({
    "/:dorsBookingId": {
        title: (state: AppState) => dorsBookingSelector(state).attendanceId,
        resolve: [loadDorsBookingDetailWithAudit, () => loadAllPoliceAndCourtOrganisations(), loadEventTypes],
        ...detailRoutes
    }
});

export const routes = {
    "/dorsBookings": {
        resolve: () => loadDorsBookings(),
        title: "DorsBookings",
        ignoreParentResolve: true,
        ...detailRoute(),
    }
};
