import * as React from "react";
import { useDispatch } from "react-redux";
import { SubActionModalProps } from "./SubActionModalProps";
import { Container } from "semantic-ui-react";

export const SimpleAction: React.FC<SubActionModalProps> = ({
    triggerDispatchAction,
    setActionComplete,
    action,
    children,
    actionComplete
}) => {
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            if (triggerDispatchAction && !actionComplete) {
                setActionComplete(true);
                dispatch(action);
            }
        },
        [actionComplete, action, dispatch, setActionComplete, triggerDispatchAction]);

    return (
        <Container>
            {children()}
        </Container>
    );
};
