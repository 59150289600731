import * as React from "react";

import { ExternalResourcesSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const ExternalResources: ConfigurationAreaComponent<ExternalResourcesSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="ExternalResourcesSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ContactPageLink", label: "Contact Page Link" },
                { name: "TrainerAppResourcesLink", label: "Trainer App Resources Link" },
                { name: "DorsPortalLink", label: "NDORS Portal Link" },
                { name: "PrivacyPolicyLink", label: "Privacy Policy Link" },
                { name: "CpcCourseEligibilityLink", label: "CPC Course Eligibility Link" },
                { name: "CpcTermsAndConditionsLink", label: "CPC T&C Link" },
                { name: "ConstructionPrivacyPolicyLink", label: "Construction Privacy Policy Link" },
                { name: "ConstructionTermsAndConditionsLink", label: "Construction T&C Link" },
            ]}
        />
    );
};
