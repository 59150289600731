import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { TrainerAttributeComplianceStatus } from "@common/crud/common/TrainerAttributeComplianceStatus";
import * as React from "react";

export const errorMessageDisplay = (unverifiedTrainerAttributes: TrainerAttribute[]) => {
    return (
        <>
            {unverifiedTrainerAttributes?.length > 0 &&
            <>
                {unverifiedTrainerAttributes.map(attribute => (
                    <span key={attribute.attributeDefinitionId}>{attribute.name} ({TrainerAttributeComplianceStatus[attribute.complianceStatus]}); </span>
                ))}
            </>}
        </>
    );
};
