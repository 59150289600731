import {
    Button,
    Checkbox, CheckboxProps,
    Divider,
    Dropdown,
    DropdownProps,
    Form,
    Header, InputOnChangeData,
    Label,
    Modal
} from "semantic-ui-react";
import * as React from "react";
import {
    AdHocEmailCorporateOrganisation,
    CorporateOrganisationContact,
    CorporateOrganisationContactName,
    OrganisationDetailModel
} from "@common/crud/organisation/model";
import { ChangeEvent, SyntheticEvent } from "react";
import { Input } from "@neworbit/simpleui-input";
import { RichTextEditor } from "@common/crud/common/RichTextEditor";
import { EmailTemplate } from "@common/appSettings/appSettingsApi";
import { AppSettingsApi } from "@common/appSettings/appSettingsApi";
import { ValidationResultType } from "not-valid/bin/results";
import { createValidator, validators } from "not-valid";
import { OrganisationApi } from "@common/crud/organisation";
import { toast } from "@common/toasts";
import { currentUserSelector } from "@common/auth";
import { useSelector } from "react-redux";
import { EmailAttachments } from "@common/crud/email/attachments/EmailAttachments";

interface ContactCheckBoxProps {
    id: number;
    presentation: string;
    emails: string[];
}

interface Props {
    organisation: OrganisationDetailModel;
}

const validSenderEmail = createValidator<string>(value => {
    if (!value) {
        return false;
    }

    return value.toUpperCase().endsWith("@TTC-UK.COM");
}, "Please enter email ending with @ttc-uk.com");

export const AdHocEmailCorporateOrganisationModal = ({ organisation }: Props) => {
    const userSelector = useSelector(currentUserSelector);
    const [toggleModal, setToggleModal] = React.useState<boolean>(false);
    const [corporateOrganisationContacts, setCorporateOrganisationContacts] =
        React.useState<ContactCheckBoxProps[]>((organisation?.organisationContact?.contacts?.length || 0) === 0
            ? []
            : [{
                id: CorporateOrganisationContact.Primary,
                presentation: organisation.organisationContact.contacts.map(contact => contact.email).join(", "),
                emails: organisation.organisationContact.contacts.map(contact => contact.email)
            }]);
    const [sendCustomEmail, setSendCustomEmail] = React.useState<boolean>(false);
    const [customEmailBody, setCustomEmailBody] = React.useState<string>("");
    const [customEmailSubject, setCustomEmailSubject] = React.useState<string>("");
    const [customEmailBodyError, setCustomEmailBodyError] = React.useState(sendCustomEmail);
    const [customEmailSubjectError, setCustomEmailSubjectError] = React.useState(sendCustomEmail);
    const [files, setFiles] = React.useState<File[]>();
    const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[] | null>(null);
    const [emailTemplateDropdownError, setEmailTemplateDropdownError] = React.useState(true);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState<EmailTemplate>(null);
    const emailValidators = [validators.validEmail(), validators.validLength({ max: 50 })];
    const [senderAddress, setSenderAddress] = React.useState<string>(userSelector?.email ?? "");
    const [senderAddressError, setSenderAddressError] = React.useState<string>(
        userSelector?.email && userSelector?.email.length > 0 && emailValidators.every(fn => fn(userSelector?.email).type === ValidationResultType.Pass)
            ? validSenderEmail(userSelector?.email).type === ValidationResultType.Pass
                ? undefined
                : "Please enter email ending with @ttc-uk.com"
            : "Please enter a valid email address");
    const contactEmailAddresses = [
        {
            id: CorporateOrganisationContact.Primary,
            label: `${CorporateOrganisationContactName[CorporateOrganisationContact.Primary]}: ` +
                `${ organisation.organisationContact?.contacts?.map(contact => contact.email)?.join(", ") || ""}`,
            emails: organisation.organisationContact?.contacts?.map(contact => contact.email) || []
        },
        {
            id: CorporateOrganisationContact.Course,
            label: `${CorporateOrganisationContactName[CorporateOrganisationContact.Course]}: ` +
                `${ organisation.organisationCourseContact?.contacts?.map(contact => contact.email)?.join(", ") || ""}`,
            emails: organisation.organisationCourseContact?.contacts?.map(contact => contact.email) || []
        },
        {
            id: CorporateOrganisationContact.Finance,
            label: `${CorporateOrganisationContactName[CorporateOrganisationContact.Finance]}: ` +
                `${ organisation.organisationFinanceContact?.contacts?.map(contact => contact.email)?.join(", ") || ""}`,
            emails: organisation.organisationFinanceContact?.contacts?.map(contact => contact.email) || []
        }];

    React.useEffect(() => {
        async function getAdHocCorpEmailTemplates() {
            const api = new AppSettingsApi();
            const templates = await api.getAdHocCorpEmailTemplates();
            setEmailTemplates(templates);
        }
        if (toggleModal) {
            getAdHocCorpEmailTemplates();
        }
    },[toggleModal]);

    const emailTemplateDropdownOptions = React.useCallback(() => {
        return emailTemplates ? Object.values(emailTemplates).map(t => ({ text: t.text, key: t.text, value: t.text })) : [];
    },[emailTemplates]);

    const openToggleModal = () => {
        setToggleModal(true);
    };

    const closeToggleModal = () => {
        clearModal();
        setToggleModal(false);
    };

    const clearModal = () => {
        setCorporateOrganisationContacts((organisation?.organisationContact?.contacts?.length || 0) === 0
            ? []
            : [{
                id: CorporateOrganisationContact.Primary,
                presentation: organisation.organisationContact.contacts.map(contact => contact.email).join(", "),
                emails: organisation.organisationContact.contacts.map(contact => contact.email)
            }]);
        setSendCustomEmail(false);
        setCustomEmailBodyError(false);
        setCustomEmailSubjectError(false);
        setFiles(undefined);
        setSenderAddress(userSelector?.email ?? "");
        setSenderAddressError(userSelector?.email && userSelector?.email.length > 0 &&
            emailValidators.every(fn => fn(userSelector?.email).type === ValidationResultType.Pass)
            ? validSenderEmail(userSelector?.email).type === ValidationResultType.Pass
                ? undefined
                : "Please enter email ending with @ttc-uk.com"
            : "Please enter a valid email address");
        setEmailTemplates(null);
        setCustomEmailBody("");
        setCustomEmailSubject("");
        setEmailTemplateDropdownError(true);
    };

    const onContactSelection = (event: SyntheticEvent, { checked, }: CheckboxProps, contactId: number) => {
        const selectedContactEmailAddresses = contactEmailAddresses.find(a => a.id === contactId);
        if (checked) {
            setCorporateOrganisationContacts(prevState => [...prevState,
                {
                    id: contactId,
                    presentation: selectedContactEmailAddresses.label,
                    emails: selectedContactEmailAddresses.emails,
                }
            ]);
        } else {
            setCorporateOrganisationContacts(prevState => prevState.filter(contact => contact.id !== contactId));
        }
    };

    const onSendCustomEmail = (value: boolean, valid: boolean) => {
        setCustomEmailSubjectError(value);
        setCustomEmailBodyError(value);
        setCustomEmailSubject(!value && "");
        setCustomEmailBody(!value && "");
        setSelectedEmailTemplate(value && null);
        setEmailTemplateDropdownError(!value);
        setSendCustomEmail(value);
    };

    const onCustomEmailBodyChange = (value: string) => {
        setCustomEmailBodyError(sendCustomEmail && value.length === 0);
        setCustomEmailBody(value);
    };

    const onCustomEmailSubjectChange = (event: ChangeEvent, { value }: any) => {
        setCustomEmailSubjectError(sendCustomEmail && value.length === 0);
        setCustomEmailSubject(value);
    };

    const onEmailTemplateDropdownChange = (event: SyntheticEvent, data: DropdownProps) => {
        const emailTemplate = emailTemplates.find(et => et.text === data.value);
        setSelectedEmailTemplate({ text: emailTemplate.text, value: emailTemplate.value });
        setEmailTemplateDropdownError(!sendCustomEmail && (data.value === null || data.value === undefined));
    };

    const onSenderAddressChanged = (event: ChangeEvent, { value }: InputOnChangeData) => {
        setSenderAddress(value);
        const senderError = value && value.length > 0 && emailValidators.every(fn => fn(value).type === ValidationResultType.Pass)
            ? validSenderEmail(value).type === ValidationResultType.Pass
                ? undefined
                : "Please enter email ending with @ttc-uk.com"
            : "Please enter a valid email address";
        setSenderAddressError(senderError);
    };

    const sendEmailDisabled = React.useCallback(() => {
        return senderAddressError?.length > 0
            || customEmailSubjectError
            || customEmailBodyError
            || emailTemplateDropdownError
            || !corporateOrganisationContacts.length;
    },[senderAddressError, customEmailBodyError, customEmailSubjectError, emailTemplateDropdownError, corporateOrganisationContacts]);

    const sendEmail = async () => {
        const model: AdHocEmailCorporateOrganisation =  {
            senderEmailAddress: senderAddress,
            contacts: corporateOrganisationContacts,
            customEmailSubject,
            customEmailBody,
            templateName: selectedEmailTemplate?.text,
            templateId: selectedEmailTemplate?.value,
            organisationName: organisation.name,
            attachments: files ? Array.from(files) : undefined,
        };

        const organisationApi = new OrganisationApi();
        try {
            const emailSent = await organisationApi.sendAdHocCorporationEmailToOrganisation(organisation.id, model);
            if (emailSent) {
                toast.success("Email has been sent");
                closeToggleModal();
            } else {
                toast.error("Failed to send email");
            }
        } catch (error) {
            toast.error("Failed to send email");
        }
    };

    const onFileChange = React.useCallback((fileList: File[]) => {
        setFiles(fileList);
    }, []);

    return (
        <>
            <Button onClick={openToggleModal}>Send ad-hoc email</Button>
            <Modal size="small" open={toggleModal}>
                <Modal.Header>Send ad-hoc email to Org contact(s)</Modal.Header>
                <Modal.Content>
                    <Header size="tiny">Which contact would you like to email</Header>
                    {
                        contactEmailAddresses.map(contact => (
                            <div key={contact.label}>
                                <Checkbox
                                    disabled={!contact.emails.length}
                                    onChange={(event, data) => onContactSelection(event, data, contact.id)}
                                    value={contact.label}
                                    defaultChecked={contact.id === CorporateOrganisationContact.Primary}
                                    label={contact.label} />
                            </div>
                        ))
                    }
                    {
                        !corporateOrganisationContacts.length && <Label basic color="red" content={"This field is required"} pointing="above" />
                    }
                    <Divider />
                    <Header size="tiny">Choose your message</Header>
                    <Input.Checkbox
                        label="Send a custom email"
                        value={sendCustomEmail}
                        onChange={onSendCustomEmail}
                    />
                    {sendCustomEmail ? (
                        <Form style={{ marginTop: "10px" }}>
                            <Form.Input
                                required
                                label="Subject"
                                spellCheck="true"
                                onChange={onCustomEmailSubjectChange}
                                error={customEmailSubjectError}
                            />
                            {customEmailSubjectError && <Label basic color="red" content={"This field is required"} pointing="above" />}
                            <RichTextEditor onChange={onCustomEmailBodyChange} />
                            {customEmailBodyError && <Label basic color="red" content={"This field is required"} pointing="above" />}
                            <Divider />
                            <EmailAttachments onAttachmentsChange={onFileChange} />
                        </Form>
                    )
                        : (
                            <Form style={{ marginTop: "10px" }}>
                                <Dropdown
                                    placeholder="Select template"
                                    fluid
                                    selection
                                    options={emailTemplateDropdownOptions()}
                                    onChange={onEmailTemplateDropdownChange}
                                />
                                {(emailTemplateDropdownError) && <Label basic color="red" content="This field is required" pointing="above" />}
                            </Form>
                        )
                    }
                    <Divider />
                    <Header size="tiny">Choose Sender Address</Header>
                    <Form>
                        <Form.Input
                            required
                            label="Send address"
                            value={senderAddress}
                            spellCheck="true"
                            onChange={onSenderAddressChanged}
                            error={senderAddressError}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeToggleModal}>Cancel</Button>
                    <Button onClick={sendEmail} disabled={sendEmailDisabled()}>Send</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
