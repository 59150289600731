import * as React from "react";
import { Message, TextArea, TextAreaProps } from "semantic-ui-react";

interface CustomSmsMessageProps {
    required: boolean;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomSmsMessage: React.FC<CustomSmsMessageProps> = ({ required, message, setMessage }) => {

    function setSmsMessage(_: any, { value }: TextAreaProps) {
        const val = value as string;
        const trimmedMessage = val.length > 306 ? val.substr(0, 306) : val;
        setMessage(trimmedMessage);
    }

    return (
        <>
            <span className={"float-right"}>Character count: {message.length}/306</span>
            <TextArea
                value={message}
                label="Sms message"
                onChange={setSmsMessage}
                required={required}
                spellCheck="true"
                autoComplete="on"
            />
            {message.length >= 160 &&
                <Message color="yellow">
                    Warning - more than 160 characters will increase the cost of sending this message.
                    Please reduce the number of characters if possible
                </Message>
            }
        </>
    );
};
