import axios from "axios";

import { ConfigurationModel, AppSetting } from "./model";

class ConfigurationApi {

    private readonly apiUrl: string;

    constructor(orgId?: string) {
        this.apiUrl = orgId ? `/api/organisation/${orgId}/configuration` : "/api/configuration";
    }

    public async info(settingName: string): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/info/${settingName}`);
        const data = response.data as string;
        return data;
    }

    public async get(): Promise<ConfigurationModel> {
        const response = await axios.get(this.apiUrl);
        const data = response.data as ConfigurationModel;
        return data;
    }

    public async post(setting: AppSetting): Promise<{}> {
        return axios.post(this.apiUrl, setting);
    }

    public delete(id: string): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }
}

export { ConfigurationApi };
