import * as React from "react";
import { Button, Checkbox, CheckboxProps, Form, Grid, Icon, Popup } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { CompletionState } from "@common/crud/attendee/model";
import "../UpdateDelegatesTable.scss";
import { Media } from "@common/global/AppMedia";
import { useRegisterContext } from "../../register/register-context";
import { DelegateRaiseIssueModal } from "../../register/DelegateRaiseIssue";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

interface DelegateStateToggleProps {
    isStageTwo: boolean;
    showCompletionError: boolean;
    completionState: CompletionState;
    delegate: AttendeeListModel;
    completionDisabled: boolean;
    handleCompletedChange: (_: any, data: CheckboxProps) => void;
    hasBeenSubmitted: boolean;
    updatingCompletion: boolean;
    onUpdateCompletion: () => Promise<void>;
    handleAttendedChange: (_: any, data: any) => void;
    isEditable: boolean;
    hasIssues: boolean;
    disableRow: boolean;
    onAttendeeRegisterUpdated: (updatedDelegate: AttendeeListModel) => void;
    isEventInstanceAfv: boolean;
    forcedEventInstanceProcessing?: boolean;
}

export const DelegateStateToggle = (
    { isStageTwo,
        showCompletionError,
        completionState,
        delegate,
        completionDisabled,
        handleCompletedChange,
        hasBeenSubmitted,
        updatingCompletion,
        onUpdateCompletion,
        handleAttendedChange,
        isEditable,
        hasIssues,
        disableRow,
        onAttendeeRegisterUpdated,
        isEventInstanceAfv,
        forcedEventInstanceProcessing }:
    DelegateStateToggleProps) => {
    const { eventInstanceOptions } = useRegisterContext();
    const { registerClosed } = eventInstanceOptions;

    const app = useSelector(appSelector);
    const isAdminApp = app === Apps.Admin;

    const stateChangeDisabledDueToAfv = React.useMemo(() => isEventInstanceAfv && (!delegate.address?.addressLine1 || !delegate.address?.postalCode)
        , [delegate, isEventInstanceAfv]);

    return (<>
        {isStageTwo && !isEditable && !delegate.isBookingCanceled && (
            <Media lessThan="computer">
                <Grid.Row>
                    <Grid.Column>
                        <DelegateRaiseIssueModal
                            hasIssues={hasIssues}
                            attendee={delegate}
                            disabled={disableRow}
                            onAttendeeRegisterUpdated={onAttendeeRegisterUpdated} />
                    </Grid.Column>
                </Grid.Row>
            </Media>
        )}
        <Grid.Column width={3} textAlign="right" only="computer">
            {isStageTwo
                ? (<>
                    <CompletionStateRadioWithToolTip showCompletionError={showCompletionError}
                        completionState={completionState}
                        delegate={delegate}
                        completionDisabled={completionDisabled || stateChangeDisabledDueToAfv}
                        handleCompletedChange={handleCompletedChange} />
                    <UpdateToggle
                        hasBeenSubmitted={hasBeenSubmitted}
                        updatingCompletion={updatingCompletion}
                        delegate={delegate}
                        isAdminApp={isAdminApp}
                        registerClosed={registerClosed}
                        forcedEventInstanceProcessing={forcedEventInstanceProcessing}
                        onUpdateCompletion={onUpdateCompletion} />
                </>)
                : (
                    <Checkbox
                        className="right-aligned-center"
                        checked={delegate.didAttend}
                        onChange={handleAttendedChange}
                        disabled={stateChangeDisabledDueToAfv}
                    />
                )}
            {stateChangeDisabledDueToAfv && (
                <Popup trigger={<Icon name="warning" color="red" size="large" className="delegate-state-warning" />}>
                    <Popup.Content>
                        <span>Please enter the delegate's address before completing register update</span>
                    </Popup.Content>
                </Popup>
            )}
        </Grid.Column>
        <Media lessThan="computer" className="container">
            {isStageTwo
                ? (<Grid.Row className="purple-header checkbox-text">
                        Completed
                    <CompletionStateRadioWithToolTip
                        showCompletionError={showCompletionError}
                        completionState={completionState}
                        delegate={delegate}
                        completionDisabled={completionDisabled || stateChangeDisabledDueToAfv}
                        handleCompletedChange={handleCompletedChange} />
                    <UpdateToggle
                        hasBeenSubmitted={hasBeenSubmitted}
                        updatingCompletion={updatingCompletion}
                        delegate={delegate}
                        isAdminApp={isAdminApp}
                        registerClosed={registerClosed}
                        onUpdateCompletion={onUpdateCompletion} />
                </Grid.Row>
                )
                : (<>
                    <Grid.Row className="purple-header checkbox-text">
                        <div>Attended</div>
                        <Checkbox
                            checked={delegate.didAttend}
                            onChange={handleAttendedChange}
                            disabled={stateChangeDisabledDueToAfv}
                        />
                    </Grid.Row>
                </>)}
            {stateChangeDisabledDueToAfv && (
                <Popup trigger={<Icon name="warning" color="red" size="large" className="delegate-state-warning" />}>
                    <Popup.Content>
                        <span>Please enter the delegate's address before completing register update</span>
                    </Popup.Content>
                </Popup>
            )}
        </Media>
    </>);
};

const UpdateToggle = ({ hasBeenSubmitted,
    updatingCompletion,
    delegate,
    isAdminApp,
    registerClosed,
    forcedEventInstanceProcessing,
    onUpdateCompletion }:
    {
        hasBeenSubmitted: boolean;
        updatingCompletion: boolean;
        delegate: AttendeeListModel;
        isAdminApp: boolean;
        registerClosed: boolean;
        forcedEventInstanceProcessing?: boolean;
        onUpdateCompletion: () => Promise<void>;}) => {
    return hasBeenSubmitted && (
        updatingCompletion
            ? <p className="red-warning-text">Please resubmit</p>
            : forcedEventInstanceProcessing
                ? <p className="red-warning-text">Forced processing in progress</p>
                : (
                    <Button
                        as={"a"}
                        disabled={delegate.isBookingCanceled || (!isAdminApp && registerClosed)}
                        className={"issue-button float-right"}
                        onClick={onUpdateCompletion}
                    >
                        <Icon name="angle left" />Update
                    </Button>
                )
    );
};

const CompletionStateRadioWithToolTip = (
    { showCompletionError, completionState, delegate, completionDisabled, handleCompletedChange }:
    {showCompletionError: boolean;
        completionState: CompletionState;
        delegate: AttendeeListModel;
        completionDisabled: boolean;
        handleCompletedChange: (_: any, data: CheckboxProps) => void;}) => {
    return (<Popup
        trigger={<Form.Group
            className={`completed-radio reg-radio ${showCompletionError && completionState === CompletionState.Unknown ? "error" : ""}`}
        >
            <Form.Radio
                label="Yes"
                name={`${delegate.id}_completed`}
                value={CompletionState.Completed}
                checked={completionState === CompletionState.Completed}
                disabled={completionDisabled}
                onChange={handleCompletedChange} />
            <Form.Radio
                label="No"
                name={`${delegate.id}_completed`}
                value={CompletionState.NotCompleted}
                checked={completionState === CompletionState.NotCompleted}
                disabled={completionDisabled}
                onChange={handleCompletedChange} />
        </Form.Group>}
        content={"Can't change completion state, delegate didn't attend the course"}
        on="hover"
        hoverable={true}
        disabled={delegate.didAttend}
        position="top left" />);
};

