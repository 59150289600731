import * as React from "react";
import { DdrsPostmarkTemplates } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";
import { validatePostmarkTemplateId } from "../validation";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DdrsRecipientType, RecipientTypeEnum } from "@common/crud/common/RecipientTypeEnum";
import { PostmarkTemplateInfoApi } from "../postmarkTemplateInfoApi";
import { Form, Grid } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";
import { usePreventScroll } from "react-aria";

export const DdrsPostmark: ConfigurationAreaComponent<
    DdrsPostmarkTemplates
> = ({ settings, hierarchy }) => {
    const [filtersVisible, setFiltersVisible] = React.useState<boolean>(true);
    const [deliveryType, setDeliveryType] = React.useState<DeliveryTypeEnum>();
    const [recipientType, setRecipientType] = React.useState<RecipientTypeEnum>();
    const [fieldsMatchingCriteria, setFieldsMatchingCriteria] = React.useState<string[]>();
    const [loadingData, setLoadingData] = React.useState<boolean>(true);

    usePreventScroll({ isDisabled: !loadingData });

    const items = React.useMemo(() => [
        {
            name: "BookingConfirmationEnglish",
            label: "Attendee Booking Confirmation (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalCourseBookingConfirmationNonRegEnglish",
            label: "Digital Course Booking Confirmation Non-Reg (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingReminderEnglish",
            label: "DDRS Attendee Booking Reminder (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalBookingReminderForAllAttendeesEnglish",
            label: "DDRS Digital Booking Reminder All Attendees (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DdrsFirstInvite",
            label: "Ddrs First Invite",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ReminderToBookDdrsCourse",
            label: "Reminder to book DDRS course",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
    ], []);

    React.useEffect(() => {
        const fetchFields = async () => {
            const api = new PostmarkTemplateInfoApi();
            const fields = await api.fieldsMatchingCriteria("DdrsPostmarkTemplates", undefined, deliveryType, recipientType);
            setFieldsMatchingCriteria(fields);
            setLoadingData(false);
        };

        setLoadingData(true);
        fetchFields();
    }, [deliveryType, recipientType]);

    const filteredItems = React.useMemo(() => {

        if (deliveryType || recipientType) {
            if (fieldsMatchingCriteria && fieldsMatchingCriteria.length > 0) {
                return items.filter((item) => fieldsMatchingCriteria.includes(item.name));
            } else {
                return [];
            }
        } else {
            return items;
        }
    }, [deliveryType, fieldsMatchingCriteria, items, recipientType]);

    const onDeliveryTypeChange = React.useCallback((_: any, data: any) => {
        setDeliveryType(+data.value);
    }, []);

    const onRecipientTypeChange = React.useCallback((_: any, data: any) => {
        setRecipientType(+data.value);
    }, []);

    const clearFilters = React.useCallback(() => {
        setDeliveryType(0);
        setRecipientType(0);
    }, []);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"float-left cursor-pointer"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filtersVisible &&
                <Grid stackable className="filter-grid" width={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Dropdown
                                label="Delivery Type"
                                placeholder="Any"
                                value={deliveryType}
                                options={optionsFromObject(DeliveryType, "Any", "")}
                                onChange={onDeliveryTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Recipient Type"
                                placeholder="Any"
                                value={recipientType}
                                options={optionsFromObject(DdrsRecipientType, "Any", "")}
                                onChange={onRecipientTypeChange}
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} />
                        <Grid.Column width={8}>
                            <Form.Button className="display-right" content="Clear Filters" onClick={clearFilters} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            <SettingsGrid
                area="DdrsPostmarkTemplates"
                hierarchy={hierarchy}
                settings={settings}
                items={filteredItems}
            />
        </>
    );
};
