import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const TrainerAttributesSection: QuestionSection = {
    id: "2",
    title: "Trainer Attributes",
    seqNum: 1,
    subSections: [
        {
            id: "2.1",
            text: "Pre-Course Rapport / Registration",
            seqNum: 1,
            criteria: [
                { id: "1", text: "Trainer welcoming to clients" },
                { id: "2", text: "Trainer helped the clients with any IT issues" },
                { id: "3", text: "Trainer removed the client’s surname if possible" },
                { id: "4", text: "Introduced assessor / EQA and their role" }
            ],
            summaryCriterium: { id: "5", text: "Summary", typeEnum: CriteriaEnum.text },
        },
        {
            id: "2.2",
            text: "Client Management",
            seqNum: 2,
            criteria: [
                { id: "1", text: "Deal with client issues and communicate this to the office" },
                { id: "2", text: "Accommodate individual clients’ additional needs" },
                { id: "3", text: "Employ a client-centred approach" }
            ]
        },
        {
            id: "2.3",
            text: "Group Management",
            seqNum: 3,
            criteria: [
                { id: "1", text: "Remain in control throughout the session " },
                { id: "2", text: "Effective use of advising clients to mute/unmute if feedback experienced" },
                { id: "3", text: "Dealing with difficult clients" },
                { id: "4", text: "Maintain clients’ interest" },
                { id: "5", text: "Adapt facilitation / teaching techniques to include all learning styles" },
                { id: "6", text: "Equality & Diversity managed / compliant " },
                { id: "7", text: "Locked the room " }
            ]
        },
        {
            id: "2.4",
            text: "Attitude & Enthusiasm",
            seqNum: 4,
            criteria: [
                { id: "1", text: "Positive attitude" },
                { id: "2", text: "Challenges clients’ attitudes constructively" },
                { id: "3", text: "Balanced level of humour" },
                { id: "4", text: "Credible source of road safety information" },
                { id: "5", text: "Not overly authoritarian" },
                { id: "6", text: "Does not have a superior attitude" },
                { id: "7", text: "Creates a fun course for the clients" }
            ]
        },
        {
            id: "2.5",
            text: "Q&A Technique",
            seqNum: 5,
            criteria: [
                { id: "1", text: "Coaching techniques used appropriately" },
                { id: "2", text: "Kept the course client centred" },
                { id: "3", text: "Did not introduce anecdotes from clients or self " },
                { id: "4", text: "Used wording appropriate to the clients comprehension / understanding" },
                { id: "5", text: "Effective use of thumbs up and thumbs down" },
                { id: "6", text: "Spread the questions equally across all the clients" },
                { id: "7", text: "Allows clients time to respond" }
            ]
        },
        {
            id: "2.6",
            text: "Adherence to Course",
            seqNum: 6,
            criteria: [
                { id: "1", text: "Did not contaminate with material from other courses" },
                { id: "2", text: "Adhered to the suggested delivery method / content" },
                { id: "3", text: "Delivered all key messages" },
                { id: "4", text: "Adhere to service provider requirements" }
            ]
        },
        {
            id: "2.7",
            text: "Time Management",
            seqNum: 7,
            criteria: [
                { id: "1", text: "Maintained course timings" },
                { id: "2", text: "Allowed enough time for activities" }
            ]
        },
        {
            id: "2.8",
            text: "Digital Platform",
            seqNum: 8,
            criteria: [
                { id: "1", text: "Clients able to access the course without major issue" },
                { id: "2", text: "Trainer could see all clients" },
                { id: "3", text: "GDPR principles followed correctly when checking ID and completing registration process?" },
                { id: "4", text: "Were any technical difficulties encountered by trainer / " +
                        "observer / client? E.g. loss of internet connection, difficulties in" +
                        " hearing others due to mic or internet" },
                { id: "5", text: "Was the trainer’s audio and video clear / uninterrupted / comprehensible? " }
            ]
        }
    ],
    summaryCriterium: [{ id: "16", text: "", typeEnum: CriteriaEnum.text }]
};
