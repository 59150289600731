import * as React from "react";
import { EventTypeFile, EventTypeFileResponse } from "../../model";
import { Button, Checkbox, Grid, Tab } from "semantic-ui-react";
import { Spinner } from "@common/global";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { EventTypeApi } from "../../eventTypeApi";

import "./FilesDetailsTab.scss";
import { toast } from "@common/toasts";
import { FileModal } from "./FileModal";
import moment from "moment";
import { orderBy } from "lodash";

interface FilesDetailsTabProps {
    eventTypeId: string;
}

export const FilesDetailsTab: React.FC<FilesDetailsTabProps> = ({ eventTypeId }) => {
    const [files, setFiles] = React.useState<EventTypeFile[]>([]);
    const [changingFile, setChangingFile] = React.useState<boolean>(false);
    const [contextFile, setContextFile] = React.useState<EventTypeFile>(undefined);
    const [refreshing, setRefreshing] = React.useState(true);
    const [showExpired, setShowExpired] = React.useState(false);

    React.useEffect(() => {
        const getFiles = async () => {
            const response = await new EventTypeApi().getFiles(eventTypeId, showExpired);
            setFiles(response);
            setRefreshing(false);
        };

        if (eventTypeId) {
            getFiles();
        }
    }, [eventTypeId, showExpired]);

    const baseColumns = (): TypedTableRowProps<EventTypeFile>[] => {
        return [
            {
                header: "Document Name",
                value: (f) => <Button content={f.documentName} onClick={() => onEditClick(f)} className="link-button file-action break-word" />,
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "Document Description",
                value: (f) => f.documentDescription,
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
            {
                header: "Start Date",
                value: (f) => f.startDate.format("LL"),
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
        ];
    };

    const endDateColumns = (): TypedTableRowProps<EventTypeFile>[] => {
        return [
            {
                header: "End Date",
                value: (f) => f.endDate?.format("LL"),
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
        ];
    };

    const actionsColumns = (): TypedTableRowProps<EventTypeFile>[] => {
        return [
            {
                header: "Actions",
                value: (f) => (
                    <Grid>
                        <Grid.Row className="file-action-row">
                            <Button content="Preview" onClick={() => onPreviewClick(f)} className="link-button file-action" />
                        </Grid.Row>
                        <Grid.Row className="file-action-row">
                            <Button content="Download" onClick={() => onDownloadClick(f)} className="link-button file-action" />
                        </Grid.Row>
                    </Grid>
                ),
                dynamicCellClassName: (f) => (!f.endDate || f.endDate >= moment.utc()) ? undefined : "grey"
            },
        ];
    };

    const columns = (): TypedTableRowProps<EventTypeFile>[] => {
        return showExpired
            ? [
                ...baseColumns(),
                ...endDateColumns(),
                ...actionsColumns(),
            ] : [
                ...baseColumns(),
                ...actionsColumns(),
            ];
    };

    const onAddClick = React.useCallback(() => {
        setContextFile(undefined);
        setChangingFile(true);
    }, []);

    const onEditClick = React.useCallback((file: EventTypeFile) => {
        setContextFile(file);
        setChangingFile(true);
    }, []);

    const onPreviewClick = React.useCallback((file: EventTypeFile) => window.open(`/api/eventtype/${eventTypeId}/files/${file.id}?viewOnly=true`),
        [eventTypeId]);

    const onDownloadClick = React.useCallback((file: EventTypeFile) => window.open(`/api/eventtype/${eventTypeId}/files/${file.id}?viewOnly=false`),
        [eventTypeId]);

    const onCloseCreateModal = React.useCallback(() => {
        setContextFile(undefined);
        setChangingFile(false);
    }, []);

    const onSaveCreateModal = React.useCallback(async (file: EventTypeFile) => {
        setRefreshing(true);
        let response: EventTypeFileResponse;
        try {
            response = contextFile
                ? await new EventTypeApi().editFile(eventTypeId, file, showExpired)
                : await new EventTypeApi().createFile(eventTypeId, file, showExpired);
            if (response.error) {
                toast.error(response.error);
            }
            setFiles(response.files);
        } catch {
            toast.error("Unexepcted error, please contact administrator");
            const refreshedFiles = await new EventTypeApi().getFiles(eventTypeId);
            setFiles(refreshedFiles);
        } finally {
            setRefreshing(false);
            setContextFile(undefined);
            setChangingFile(false);
        }
    }, [contextFile, eventTypeId, showExpired]);

    const toggleShowExpired = React.useCallback(() => setShowExpired(!showExpired), [showExpired]);

    const activeFiles = files.filter(f => !f.endDate || f.endDate >= moment.utc());
    const expiredFiles = files.filter(f => f.endDate && f.endDate < moment.utc());

    const sortedActiveFiles = orderBy(activeFiles, "startDate", "asc");
    const sortedExpiredFiles = orderBy(expiredFiles, "startDate", "asc");

    const sortedFiles = [...sortedActiveFiles, ...sortedExpiredFiles];

    return (
        <Tab.Pane>
            <FileModal
                open={changingFile}
                file={contextFile}
                close={onCloseCreateModal}
                save={onSaveCreateModal}
            />
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h1>Files</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row width={16}>
                    <Grid.Column width={8}>
                        <Checkbox
                            label={"Show Expired"}
                            checked={showExpired}
                            onChange={toggleShowExpired}
                            toggle
                        />
                    </Grid.Column>
                    <Grid.Column width={8} className="no-margin-left no-margin-right gridded-padding" textAlign="right">
                        <Button content="Add" onClick={onAddClick} />
                    </Grid.Column>
                </Grid.Row>
                <Grid stackable className="full-width no-padding-right">
                    <Grid.Row>
                        <Grid.Column width={16} className="no-padding-right">
                            <Spinner active={refreshing}>
                                <TypedTable
                                    values={sortedFiles}
                                    tableClassName="file-table"
                                    emptyValuesArrayMessage={"No files added"}
                                >
                                    {columns()}
                                </TypedTable>
                            </Spinner>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid>
        </Tab.Pane>
    );
};
