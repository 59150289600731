import axios from "axios";
import moment from "moment";
import { TrainerVenuesInAvailabilityRangeModel } from "src/Trainer.App/src/workingRadius";
import * as QueryString from "query-string";
import {
    NumberDictionary,
    TrainerAvailabilityModel,
    TrainerAvailabilitySelectModel
} from "./model";
import { LanguageEnum, TrainerType } from "@common/crud/eventInstance/model";
import { RegionalCoordinatorCalendar } from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/model";
import { RegionalCoordinatorApi } from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/RegionalCoordinatorApi";
import { StandbyTrainersApi } from "@common/crud/eventInstance/StandbyTrainersApi";
import { StandbyTrainersCalendar } from "@common/crud/eventInstance/standbyTrainersCalendarModel";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ObjectEntries } from "@common/helpers/typedObjectMethods";

export class TrainerAvailabilityApi {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/traineravailability";
    }

    public async getTrainerAvailability(): Promise<TrainerAvailabilityModel> {
        const response = await axios.get(`${this.apiUrl}`);
        const data = response.data as TrainerAvailabilityModel;
        return this.parseModel(data);
    }

    public async getTrainerAvailabilityForEventInstance(
        eventInstanceId: string,
        trainerType: TrainerType): Promise<Record<string, TrainerAvailabilitySelectModel[]>> {
        const query = QueryString.stringify({ eventInstanceId, trainerType });
        const response = await axios.get(`${this.apiUrl}/categories?${query}`);
        return this.parseSelectDictionaryModel(response.data as Record<string, TrainerAvailabilitySelectModel[]>);
    }

    public async getTrainerAvailabilityForSearchCriteria(
        eventTypeId: string,
        deliveryType: DeliveryTypeEnum,
        venueId: string,
        postCode: string,
        language: LanguageEnum,
        getTheoryTrainers: boolean,
        getPracticalTrainers: boolean,
        proposedDeliveryDate: moment.Moment,
        proposedDeliveryTime: moment.Moment): Promise<Record<string, TrainerAvailabilitySelectModel[]>> {
        const response = await axios.post(`${this.apiUrl}/preliminary-categories`, {
            eventTypeId,
            deliveryType,
            venueId: venueId ? venueId : "",
            postCode: postCode ? postCode : "",
            language: language ? language : 0,
            getTheoryTrainers,
            getPracticalTrainers,
            proposedDeliveryDate: proposedDeliveryDate?.isValid() ? proposedDeliveryDate.toISOString() : "",
            proposedDeliveryTime: proposedDeliveryTime?.isValid() ? proposedDeliveryTime.toISOString() : "" });
        return this.parseSelectDictionaryModel(response.data as Record<string, TrainerAvailabilitySelectModel[]>);
    }

    public async getTrainerAvailabilityForEventInstanceGroup(
        eventInstanceGroupId: string,
        trainerType: TrainerType): Promise<Record<string, TrainerAvailabilitySelectModel[]>> {
        const query = QueryString.stringify({ eventInstanceGroupId, trainerType });
        const response = await axios.get(`${this.apiUrl}/categories-by-group?${query}`);
        return response.data as Record<string, TrainerAvailabilitySelectModel[]>;
    }

    public async getSimpleTrainersAvailabilityForEventInstances(
        eventInstanceIds: string[],
        trainerId: string,
        trainerType: TrainerType = TrainerType.TheoryTrainer): Promise<Dictionary<TrainerAvailabilitySelectModel[]>> {
        const query = QueryString.stringify({ eventInstanceIds, trainerId, trainerType });
        const response = await axios.get(`${this.apiUrl}/simple-availability?${query}`);
        return this.parseSelectDictionaryModel(response.data as Dictionary<TrainerAvailabilitySelectModel[]>);
    }

    public async getTrainerAvailabilityByTrainerId(trainerId: string): Promise<TrainerAvailabilityModel> {
        const response = await axios.get(`${this.apiUrl}/${trainerId}`);
        const data = response.data as TrainerAvailabilityModel;
        return this.parseModel(data);
    }

    public async getTrainerVenuesInAvailabilityRangeByTrainerId(trainerId: string): Promise<TrainerVenuesInAvailabilityRangeModel> {
        const response = await axios.get(`${this.apiUrl}/${trainerId}/getvenuesinavailabilityrange`);
        return response.data as TrainerVenuesInAvailabilityRangeModel;
    }

    public async checkIfPlanningStartedForMonth(): Promise<moment.Moment[]> {
        const response = await axios.get<moment.Moment[]>(`${this.apiUrl}/planningInProgress`);
        return response.data ? response.data.map(d => moment(d)) : undefined;
    }

    public async saveTrainerAvailability(availability: TrainerAvailabilityModel): Promise<TrainerAvailabilityModel> {
        let response;
        if (!availability.id) {
            response = await axios.post(`${this.apiUrl}`, availability);
        } else {
            response = await axios.put(`${this.apiUrl}/${availability.id}`, availability);
        }

        const model = response.data as TrainerAvailabilityModel;
        return this.parseModel(model);
    }

    public async getTrainerRCMonitoring(month: moment.Moment, trainerId?: string): Promise<RegionalCoordinatorCalendar> {
        const params = trainerId ? `?trainerId=${trainerId}` : "";
        const response = await axios.get(`${this.apiUrl}/rcCalendar/${month.toISOString()}${params}`);
        return RegionalCoordinatorApi.parseRegionalCoordinatorCalendar(response.data);
    }

    public async getTrainerStandby(month: moment.Moment, trainerId?: string): Promise<StandbyTrainersCalendar> {
        const params = trainerId ? `?trainerId=${trainerId}` : "";
        const response = await axios.get(`${this.apiUrl}/standby/${month.toISOString()}${params}`);
        return StandbyTrainersApi.parseStandbyTrainersCalendar(response.data);
    }

    public async getTrainerStandbyWithIsoString(month: string, trainerId?: string): Promise<StandbyTrainersCalendar> {
        const params = trainerId ? `?trainerId=${trainerId}` : "";
        const response = await axios.get(`${this.apiUrl}/standby/${month}${params}`);
        return StandbyTrainersApi.parseStandbyTrainersCalendar(response.data);
    }

    public async getAllDigitalAvailabilityForMonth(month: moment.Moment, trainerIds: string[]):
        Promise<NumberDictionary<NumberDictionary<number>>> {
        const response = await axios.post(`${this.apiUrl}/allDigitalAvailability`, { month, trainerIds });
        return response.data;
    }

    public parseModel(model: TrainerAvailabilityModel): TrainerAvailabilityModel {
        return {
            ...model,
            availableDates: model.availableDates && model.availableDates.map(availableDate => {
                return {
                    ...availableDate,
                    date: moment(availableDate.date),
                    dateUpdated: moment(availableDate.dateUpdated)
                };
            }),
            preferredNoDaysPerMonthOverMonths: model.preferredNoDaysPerMonthOverMonths && model.preferredNoDaysPerMonthOverMonths.map(noDays => {
                return {
                    ...noDays,
                    month: moment(noDays.month)
                };
            }),
            preferredNoSessionsPerDayOverMonths: model.preferredNoSessionsPerDayOverMonths && model.preferredNoSessionsPerDayOverMonths.map(noSessions => {
                return {
                    ...noSessions,
                    month: moment(noSessions.month)
                };
            }),
            expiryDate: model.expiryDate ? moment(model.expiryDate) : undefined
        };
    }

    public parseSelectModel(model: TrainerAvailabilitySelectModel): TrainerAvailabilitySelectModel {
        return {
            ...model,
            availabilityLastUpdated: model.availabilityLastUpdated? moment(model.availabilityLastUpdated): undefined
        };
    }

    public parseSelectDictionaryModel(model: Record<string, TrainerAvailabilitySelectModel[]>): Record<string, TrainerAvailabilitySelectModel[]> {
        return Object.fromEntries(ObjectEntries(model).map(e => [e[0], e[1].map(selectModel => this.parseSelectModel(selectModel))]));
    }
}
