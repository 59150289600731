import { createSelector } from "reselect";
import { AppState, CorporateUser } from "./model";
import { FilterState } from "./components/corporate-user-list-filters";

export const routeCorporateUserIdSelector = (state: AppState) => state.router.params.corporateUserId;

export const corporateUsersSelector = (state: AppState) => state.corporateUsers;

export const queryAdditionSelector = (state: AppState) => state.router.query?.addition;

export const queryEnquiryEventInstanceIdSelector = (state: AppState) => state.router.query?.enquiryEventInstanceId;

export const corporateUserSelector = createSelector(
    corporateUsersSelector,
    routeCorporateUserIdSelector,
    (corporateUsers: CorporateUser[], id: string) => {
        return id ? corporateUsers.find(c => c.id === id) || {} as any : corporateUsers[0] || {} as any;
    }
);

export const basePathSelector = (state: AppState) => {
    return state.router.pathname.substring(0, state.router.pathname.indexOf("users") + "users".length);
};

export const organisationsPathSelector = (state: AppState) => {
    return state.router.pathname.substring(0, state.router.pathname.indexOf("organisations") + "organisations".length);
};

export const pathWithoutLastPartSelector = (state: AppState) =>
    state.router.pathname.substr(0, state.router.pathname.lastIndexOf("/"));

export const editPathSelector = (state: AppState) => {
    const corporateUserId = routeCorporateUserIdSelector(state);
    const startIndex = state.router.pathname.indexOf(corporateUserId) + corporateUserId.length;
    const endIndex = state.router.pathname.lastIndexOf("/edit");
    return state.router.pathname.substring(startIndex, endIndex);
};

export const corporateUserFilterQuerySelector = (state: AppState): FilterState => {
    const organisationId = state.router.params.organisationId;
    const query = state.router.query;

    const { searchString, showExpired } = query;
    return ({
        organisationId,
        searchString: searchString ?? undefined,
        showExpired: showExpired === "true",
    });
};
