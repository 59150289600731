import axios from "axios";
import * as QueryString from "query-string";

import {
    PoliceUserListModel,
    PoliceUserDetailModel,
    PoliceUserCreateModel,
    PoliceUserEditModel,
    SearchOptions
} from "./model";
import { parseModel } from "./parseModel";

export class PoliceUserApi {
    private readonly apiUrl: string;

    constructor(orgId?: string) {
        this.apiUrl = orgId ? `/api/organisation/${orgId}/police-user` : "/api/user";
    }

    public async getAll(options: SearchOptions): Promise<PoliceUserListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as PoliceUserListModel[];
        return data.map(parseModel);
    }

    public async detail(id: string): Promise<PoliceUserDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as PoliceUserDetailModel;
        return parseModel(model);
    }

    public async save(user: PoliceUserEditModel): Promise<PoliceUserDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${user.id}`, user);
        const model = response.data as PoliceUserDetailModel;
        return parseModel(model);
    }

    public async create(user: PoliceUserCreateModel): Promise<PoliceUserDetailModel> {
        const response = await axios.post(this.apiUrl, user);
        const model = response.data as PoliceUserDetailModel;
        return parseModel(model);
    }

    public sendSignupInvitation(id: string) {
        return axios.post(`${this.apiUrl}/${id}/signupinvitation`);
    }

    public async updateAdb2cAccount(id: string) {
        const response = await axios.put(`${this.apiUrl}/${id}/update-adb2c-account`);
        const model = response.data as PoliceUserDetailModel;
        return parseModel(model);
    }

    public async updateReceivesNotifications(id: string, receivesNotifications: boolean) {
        const response =
            await axios.patch(`${this.apiUrl}/${id}/update-receives-notifications`, { receivesNotifications });
        const model = response.data as PoliceUserDetailModel;
        return parseModel(model);
    }
}
