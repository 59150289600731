/* eslint-disable max-lines */
import * as React from "react";
import { PostmarkTemplates } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";
import { validatePostmarkTemplateId } from "../validation";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DorsRecipientType, RecipientTypeEnum } from "@common/crud/common/RecipientTypeEnum";
import { PostmarkTemplateInfoApi } from "../postmarkTemplateInfoApi";
import { Form, Grid } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";
import { usePreventScroll } from "react-aria";

export const Postmark: ConfigurationAreaComponent<PostmarkTemplates> = ({
    settings,
    hierarchy,
}) => {
    const [filtersVisible, setFiltersVisible] = React.useState<boolean>(true);
    const [deliveryType, setDeliveryType] = React.useState<DeliveryTypeEnum>();
    const [recipientType, setRecipientType] = React.useState<RecipientTypeEnum>();
    const [fieldsMatchingCriteria, setFieldsMatchingCriteria] = React.useState<string[]>();
    const [loadingData, setLoadingData] = React.useState<boolean>(true);

    usePreventScroll({ isDisabled: !loadingData });

    const items = React.useMemo(() => [
        {
            name: "SendFromEmailAlaska",
            label: "Send From Email Address - Alaska",
        },
        {
            name: "TrainerSignupInvitation",
            label: "Trainers Signup Invitation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "PoliceSignupInvitation",
            label: "Police Signup Invitation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "RebookingOneTimePasscodeEmail",
            label: "Rebooking One Time Passcode Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingConfirmationEnglish",
            label: "Attendee Booking Confirmation (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingConfirmationCymraeg",
            label: "Attendee Booking Confirmation (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalCourseBookingConfirmationEnglish",
            label: "Digital Course Booking Confirmation Reg (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalCourseBookingConfirmationCymraeg",
            label: "Digital Course Booking Confirmation Reg (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalCourseBookingConfirmationNonRegEnglish",
            label: "Digital Course Booking Confirmation Non-Reg (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalCourseBookingConfirmationNonRegCymraeg",
            label: "Digital Course Booking Confirmation Non-Reg (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "RebookingConfirmationOtherReasonEnglish",
            label: "Attendee Rebooking to Classroom Confirmation (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "RebookingConfirmationOtherReasonCymraeg",
            label: "Attendee Rebooking to Classroom Confirmation (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationAdverseWeatherEnglish",
            label: "Attendee Cancellation Confirmation - Adverse Weather (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationOtherReasonEnglish",
            label: "Attendee Cancellation Confirmation - Other Reason (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationClientDecisionNoRebookEnglish",
            label: "Attendee Cancellation Confirmation - Client Decision No Rebook (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationClientDecisionOfferWithdrawnEnglish",
            label: "Attendee Cancellation Confirmation - Offer Withdrawn (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationCompanyDecisionEnglish",
            label: "Attendee Cancellation Confirmation - Company Decision (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationUnpaidDebtsEnglish",
            label: "Attendee Cancellation Confirmation - Unpaid Debts (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationAttendeeDidNotAttendCourseEnglish",
            label: "Attendee Cancellation Confirmation - Did Not Attend Course (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingReminderEnglish",
            label: "NDORS Attendee Booking Reminder (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalBookingReminderEnglish",
            label: "Digital Booking Reminder Unregistered Attendees (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalBookingReminderForAllAttendeesEnglish",
            label: "NDORS Digital Booking Reminder All Attendees (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingCancellationEnglish",
            label: "Attendee Booking Cancellation (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationAdverseWeatherCymraeg",
            label: "Attendee Cancellation Confirmation - Adverse Weather (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationOtherReasonCymraeg",
            label: "Attendee Cancellation Confirmation - Other Reason (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationClientDecisionNoRebookCymraeg",
            label: "Attendee Cancellation Confirmation - Client Decision No Rebook (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationClientDecisionOfferWithdrawnCymraeg",
            label: "Attendee Cancellation Confirmation - Offer Withdrawn (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationCompanyDecisionCymraeg",
            label: "Attendee Cancellation Confirmation - Company Decision (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationConfirmationUnpaidDebtsCymraeg",
            label: "Attendee Cancellation Confirmation - Unpaid Debts (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CancellationAttendeeDidNotAttendCourseCymraeg",
            label: "Attendee Cancellation Confirmation - Did Not Attend Course (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },

        {
            name: "BookingReminderCymraeg",
            label: "Attendee Booking Reminder (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalBookingReminderCymraeg",
            label: "Digital Booking Reminder Unregistered Attendees (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalBookingReminderForAllAttendeesCymraeg",
            label: "Digital Booking Reminder All Attendees (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BookingCancellationCymraeg",
            label: "Attendee Booking Cancellation (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationWithScheduledPaymentEnglish",
            label: "Confirmation With Scheduled Payment (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationWithScheduledPaymentCymraeg",
            label: "Confirmation With Scheduled Payment (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationOfSuccessfulPaymentEnglish",
            label: "Confirmation Of Successful Payment (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationOfSuccessfulPaymentCymraeg",
            label: "Confirmation Of Successful Payment (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationOfUnsuccessfulPaymentEnglish",
            label: "Confirmation Of Unsuccessful Payment (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ConfirmationOfUnsuccessfulPaymentCymraeg",
            label: "Confirmation Of Unsuccessful Payment (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ReminderOfScheduledPaymentEnglish",
            label: "Reminder Of Scheduled Payment (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ReminderOfScheduledPaymentCymraeg",
            label: "Reminder Of Scheduled Payment (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstanceVenueReminderEmail",
            label: "Course Venue Reminder Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstancesVenueReminderEmail",
            label: "Bulk Course Venue Reminder Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstanceVenueConfirmationEmail",
            label: "Course Venue Confirmation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OutstandingAttendeeBalanceReminderEnglish",
            label: "Outstanding Attendee Balance Reminder (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OutstandingAttendeeBalanceReminderCymraeg",
            label: "Outstanding Attendee Balance Reminder (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalRebookingConfirmationEnglish",
            label: "Digital Rebooking Confirmation (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DigitalRebookingConfirmationCymraeg",
            label: "Digital Rebooking Confirmation (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEi",
            label: "Trainer allocated to EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEiTotal",
            label: "Trainer Allocated EI total",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerCoverRequired",
            label: "Trainer Cover Required",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEiReminder",
            label: "Trainer allocated to EI reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerEiReminder",
            label: "Trainer EI reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerInvoiceReminder",
            label: "Trainer Invoice Reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerInactivityWarning",
            label: "Trainer Inactivity Warning",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerUnSubmittedRegisterReminder",
            label: "Trainer Un-Submitted Register Reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeRebookingFromClassroomEnglish",
            label: "Attendee rebooking from classroom (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeRebookingFromClassroomCymraeg",
            label: "Attendee rebooking from classroom (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeCompletionEnglish",
            label: "Attendee Completion (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeCompletionCymraeg",
            label: "Attendee Completion (Welsh)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SurveyEmailEnglish",
            label: "Survey email 1 (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SurveyEmailCymraeg",
            label: "Survey email 1 (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SecondSurveyEmailEnglish",
            label: "Survey email 2 (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SecondSurveyEmailCymraeg",
            label: "Survey email 2 (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BreastFeedingGuidanceEnglish",
            label: "Breast Feeding Guidance email (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BreastFeedingGuidanceCymraeg",
            label: "Breast Feeding Guidance email (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OwnInterpreterGuidanceEnglish",
            label: "Own Interpreter Guidance email (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OwnInterpreterGuidanceCymraeg",
            label: "Own Interpreter Guidance email (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "MonitorAllocatedToCourse",
            label: "Monitor allocated to course",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AlertTrainerCourseWillBeMonitored",
            label: "Alert course will be monitored",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CourseSpreadingNewBookingDetailsForAttendeeEnglish",
            label: "Course reminder for attendee spread process (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CourseSpreadingNewBookingDetailsForAttendeeCymraeg",
            label: "Course reminder for attendee spread process (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeNotMovedInCourseSpreadEnglish",
            label: "Attendee not moved in course spread (English)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeNotMovedInCourseSpreadCymraeg",
            label: "Attendee not moved in course spread (Cymraeg)",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CourseSpreadingTrainerAlert",
            label: "Course spreading trainer alert",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AlertTrainerMonitoringHasBeenSubmitted",
            label: "Alert Trainer monitoring has been submitted",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AlertTrainerFeedbackToMonitoringHasBeenSubmitted",
            label: "Alert Trainer Feedback to Monitoring has been submitted",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerRemovedFromCourses",
            label: "Trainer removed from courses",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerRemovedFromCancelledCourses",
            label: "Trainer removed from cancelled courses",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "Level3AlertForIqaTeam",
            label: "Level 3 Alert For IQA Team",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AlertTrainerIqaCommentsSubmitted",
            label: "Alert Trainer Iqa Comments Submitted",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SupervisorAssistAllocationAlert",
            label: "Supervisor Allocation Alert",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "AttendeeFreeTypeTemplate",
            label: "Attendee free type template",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerFreeTypeTemplate",
            label: "Trainer free type template",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "RcSupportAllocation",
            label: "RC Support Allocation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "RcMonitorAllocation",
            label: "RC Monitor Allocation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "PoliceNotification",
            label: "Police Notification",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "NeedToPrayGuidanceEnglish",
            label: "Need to pray guidance English",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "NeedToPrayGuidanceWelsh",
            label: "Need to pray guidance Welsh",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerRemovedFromTrainerSupportCalendar",
            label: "Trainer removed from Trainer Support Calendar",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "VenueHealthAndSafetyFormReminder",
            label: "Venue H&S Form reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAddedToTrainerStandbyCalendar",
            label: "Trainer added to trainer standby calendar",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerPanelChanges",
            label: "Trainer Panel Changes",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "VatReceiptEnglish",
            label: "VAT Receipt - EN",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "VatReceiptCymraeg",
            label: "VAT Receipt - CY",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
    ], []);

    React.useEffect(() => {
        const fetchFields = async () => {
            const api = new PostmarkTemplateInfoApi();
            const fields = await api.fieldsMatchingCriteria("PostmarkTemplates", undefined, deliveryType, recipientType);
            setFieldsMatchingCriteria(fields);
            setLoadingData(false);
        };

        setLoadingData(true);
        fetchFields();
    }, [deliveryType, recipientType]);

    const filteredItems = React.useMemo(() => {
        if (deliveryType || recipientType) {
            if (fieldsMatchingCriteria && fieldsMatchingCriteria.length > 0) {
                return items.filter((item) => fieldsMatchingCriteria.includes(item.name));
            } else {
                return [];
            }
        } else {
            return items;
        }
    }, [deliveryType, fieldsMatchingCriteria, items, recipientType]);

    const onDeliveryTypeChange = React.useCallback((_: any, data: any) => {
        setDeliveryType(+data.value);
    }, []);

    const onRecipientTypeChange = React.useCallback((_: any, data: any) => {
        setRecipientType(+data.value);
    }, []);

    const clearFilters = React.useCallback(() => {
        setDeliveryType(0);
        setRecipientType(0);
    }, []);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"float-left cursor-pointer"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filtersVisible &&
                <Grid stackable className="filter-grid" width={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Dropdown
                                label="Delivery Type"
                                placeholder="Any"
                                value={deliveryType}
                                options={optionsFromObject(DeliveryType, "Any", "")}
                                onChange={onDeliveryTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Recipient Type"
                                placeholder="Any"
                                value={recipientType}
                                options={optionsFromObject(DorsRecipientType, "Any", "")}
                                onChange={onRecipientTypeChange}
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} />
                        <Grid.Column width={8}>
                            <Form.Button className="display-right" content="Clear Filters" onClick={clearFilters} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            <SettingsGrid
                area="PostmarkTemplates"
                hierarchy={hierarchy}
                settings={settings}
                items={filteredItems}
            />
        </>
    );
};
