import * as React from "react";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { ApplicationState } from "../applicationState";
import { Booking } from "../bookings/models";
import { ClientAdvisorHeader } from "./ClientAdvisorHeader";
import { View } from "./ViewEnum";
import { AttendeeHeader } from "./AttendeeHeader";

export interface WelcomeComponentProps {
    booking: Booking;
}

export const WelcomeComponent: React.FC<WelcomeComponentProps> = ({ booking }) => {
    const isClientAdvisor = useSelector((state: ApplicationState) => isUserClientAdvisor(state));

    return (
        <>
            {isClientAdvisor &&
                <ClientAdvisorHeader showDetailedInfo={true} currentView={View.VenueSelection} />
            }
            {!isClientAdvisor &&
                <AttendeeHeader booking={booking} />
            }
        </>
    );
};
