/* eslint-disable max-lines */
/* tslint:disable:no-consecutive-blank-lines */
import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { DropdownItemProps, Form } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";

import { phoneNumberWithSpaces } from "@common/validation";
import { muiFutureDateValidator } from "@common/validation/futureDateValidator";
import { Address, optionsFromObject } from "@common/crud/common";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import {
    OrganisationState,
    selectors as organisationSelectors,
    Organisation
} from "@common/crud/organisation";
import { validators } from "not-valid";
import { saveVenue } from "../actions";
import { venueSelector, basePathSelector } from "../selectors";
import { VenueCreateEditModel, AppState, VenueAttributes, DorsSiteModel, workflowTypesValidator } from "../model";
import { EditModal } from "./EditModal";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { WorkflowTypeEnum, WorkflowTypeOptions } from "@common/crud/eventType/model";
import { VenueApi } from "../venueApi";
import { getDorsSitesOptions, getDsaAreasOptions } from "./helpers";
import { DsaAreaListItem } from "@common/dsa";
import { EventInstanceApi, EventInstanceListModel } from "@common/crud/eventInstance";
import { omit } from "lodash";
import { ConstructionVenueType, CorporateVenueType, Country, PoliceAndCourtVenueType,
    VenueTypeEnum, venueTypeNeedsAddress } from "@common/crud/organisation/model";
import { LoadingState } from "redux-request-loading";
import { Spinner } from "@common/global";
import { toast } from "react-toastify";
import { MuiDateField } from "@common/components/MuiDateField";
import { VenueBaseProps } from "./Base";
import { constructionOrganisationOptionsSelector, corporateOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { AuthState, BusinessDriverEventAdmin, TtcCorporateEventAdmin } from "@common/auth/model";
import { MarkdownEditor } from "./../../common/MarkdownEditor";

export interface EditProps extends SharedEditProps<VenueCreateEditModel> {
    open: boolean;
    isPoliceOrCourt: boolean;
    isCorporate: boolean;
    isConstruction: boolean;
    isBusinessDriverAdmin: boolean;
    isCorporateEventAdmin: boolean;
    organisationId?: string;
    organisationOptions: DropdownItemProps[];
    organisations: Organisation[];
    loading: boolean;
    dsaAreas: DsaAreaListItem[];
}

export interface DispatchProps extends SaveDispatchProps<VenueCreateEditModel> {
    close: () => void;
}

interface EditFormState extends FormState<VenueCreateEditModel> {
    dorsSites: DorsSiteModel[];
    courses: EventInstanceListModel[];
    hideCoursesModalOpen: boolean;
    submitting: boolean;
}

export class EditForm extends EditComponent<VenueCreateEditModel, EditProps, EditFormState> {

    public async componentDidMount() {
        const api = new VenueApi();
        const sites = await api.getAllDorsSites();
        const response = await new EventInstanceApi().getAll({
            venueId: [ this.state.values.id ],
            fromDate: moment(),
            workflowTypes: [WorkflowTypeEnum.Any]
        });
        const courses = response.eventInstances;
        this.setState({
            ...this.state,
            dorsSites: sites,
            courses,
            hideCoursesModalOpen: false,
            submitting: false
        });
    }

    private setHideCoursesModalOpen(open: boolean) {
        this.setState({ ...this.state, hideCoursesModalOpen: open });
    }

    private updateModelProp(property: keyof VenueCreateEditModel) {
        return (value: any, valid: boolean) => this.updateProperty(property, value, valid);
    }

    private updateVenueType() {
        return (value: any, valid: boolean) => {
            this.updateProperty("venueType", value, valid);

            if (venueTypeNeedsAddress(+value)) {
                this.updateProperty("deliveryType", DeliveryTypeEnum.Onsite, true);
                this.updateProperty("address", {}, true);
                this.updateNestedProperty("address.addressLine1", undefined, true);
                this.updateNestedProperty("address.addressLine2", undefined, true);
                this.updateNestedProperty("address.addressLine3", undefined, true);
                this.updateNestedProperty("address.city", undefined, true);
                this.updateNestedProperty("address.postalCode", undefined, true);
            }
        };
    }

    private updateExpiryDate() {
        return (value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid);
    }

    private updateOrganisationProp() {
        return (value: string, valid: boolean) => this.updateOrganisation(value, valid);
    }

    private updateDsaAreaProp() {
        return (value: number, valid: boolean) => this.updateDsaArea(value, valid);
    }

    private containsWorkflow(workflowValues: WorkflowTypeEnum[],  workflow: WorkflowTypeEnum) {
        return  workflowValues?.some(w => w === workflow);
    }

    private containsDors() {
        return this.containsWorkflow(this.state.values.workflowTypes, WorkflowTypeEnum.Dors);
    }

    private containsDdrs() {
        return this.containsWorkflow(this.state.values.workflowTypes, WorkflowTypeEnum.DDRS);
    }

    private updateWorkflowProp() {

        return (value: WorkflowTypeEnum[], valid: boolean) => {

            if (this.containsWorkflow(value, WorkflowTypeEnum.DDRS) && !this.containsWorkflow(value,WorkflowTypeEnum.Dors)) {
                this.updateOrganisation(null,valid);
                this.updateProperty("dorsId", null, valid);
            }

            if (this.containsWorkflow(value,WorkflowTypeEnum.Dors) && !this.containsWorkflow(value,WorkflowTypeEnum.DDRS)) {
                this.updateDsaArea(null, valid);
            }

            const validDeliveryType = !this.policeOrCourtDigitalWithMultipleWorkflows(this.state.values.deliveryType, value);

            this.updateProperty("deliveryType", this.state.values.deliveryType, valid && validDeliveryType);

            return this.updateProperty("workflowTypes", value, valid && validDeliveryType);};
    }

    private updateOrganisation = (value: string, valid: boolean) => {
        const organisation = this.props.organisations.find(v => v.id === value);

        if (organisation) {
            this.updateProperty("organisationId", value, valid);
            this.updateProperty("forceId", organisation.dorsId, valid);
        }
        else {
            this.updateProperty("organisationId", null, !this.containsDors());
            this.updateProperty("forceId", null, !this.containsDors());
        }
    };

    private updateDsaArea = (value: number, valid: boolean) => {
        const dsaArea = this.props.dsaAreas?.find(v => v.id === value);
        const country = dsaArea?.country;

        if (dsaArea) {
            this.updateProperty("dsaAreaId", value, valid);
            this.updateProperty("referredCourtCountry", country, valid);
        }
        else {
            this.updateProperty("dsaAreaId", null, !this.containsDdrs());
            this.updateProperty("referredCourtCountry", null, !this.containsDdrs());
        }
    };

    private updateDeliveryType = (value: number, valid: boolean) => {
        const validWorkflows = !this.policeOrCourtDigitalWithMultipleWorkflows(value, this.state.values.workflowTypes);
        this.updateProperty("deliveryType", value, valid && validWorkflows);
        this.updateProperty("workflowTypes", this.state.values.workflowTypes, valid && validWorkflows);
    };

    private policeOrCourtDigitalWithMultipleWorkflows(deliveryType: DeliveryTypeEnum, workflowTypes: WorkflowTypeEnum[]) {
        return this.props.isPoliceOrCourt && (deliveryType === DeliveryTypeEnum.Digital) && (workflowTypes.length > 1);
    }

    public render() {
        const { values, showErrors, dorsSites, hideCoursesModalOpen } = this.state;
        const { organisations, isPoliceOrCourt, isCorporate, isConstruction, organisationId, organisationOptions, isBusinessDriverAdmin,
            isCorporateEventAdmin } = this.props;

        const dorsSiteOptions = getDorsSitesOptions(dorsSites);
        const countryOptions = optionsFromObject(omit(Country, 0));
        const dsaAreaOptions = getDsaAreasOptions(this.props.dsaAreas);
        const venueTypeOptions = isCorporate
            ? optionsFromObject(CorporateVenueType)
            : isConstruction
                ? optionsFromObject(ConstructionVenueType)
                : optionsFromObject(PoliceAndCourtVenueType);
        const workflowTypeOptions = WorkflowTypeOptions(
            isCorporate,
            isBusinessDriverAdmin,
            isCorporateEventAdmin,
            isConstruction,
            false
        );
        const deliveryTypeOptions = values.venueType === VenueTypeEnum.DelegateHome
            ? optionsFromObject(omit(DeliveryType,[DeliveryTypeEnum.Digital]))
            : optionsFromObject(DeliveryType);

        const classroom = this.state.values.deliveryType === DeliveryTypeEnum.Onsite;
        return (<Spinner active={this.props.loading || this.state.submitting}>
            {hideCoursesModalOpen ?
                <h3>

                This venue has courses that are due to start on or after the current expiration date.
                Any published courses will be hidden if you continue.
                </h3> :
                <Form onSubmit={this.handleSubmit}>

                    <Input.Text
                        value={values.name}
                        label="Name"
                        showErrors={showErrors}
                        required
                        onChange={this.updateModelProp("name")}
                    />
                    {!isPoliceOrCourt && (
                        <>
                            <Input.DropdownNumber
                                value={values.venueType}
                                label="Venue Type"
                                showErrors={showErrors}
                                validation={[validators.requiredNumber()]}
                                options={venueTypeOptions}
                                onChange={this.updateVenueType()}
                                disabled={!!organisationId}
                            />
                            {(values.venueType === VenueTypeEnum.CorporateOrganisationSpecific ||
                                values.venueType === VenueTypeEnum.ConstructionOrganisationSpecific) && (
                                <Input.Dropdown
                                    value={values.organisationId}
                                    label="Organisation"
                                    showErrors={showErrors}
                                    required
                                    options={organisationOptions}
                                    onChange={this.updateModelProp("organisationId")}
                                    dynamicOptions
                                    search
                                    disabled={!!organisationId}
                                />
                            )}
                        </>
                    )}
                    <Input.Text
                        value={values.contactName}
                        label="Contact Name"
                        showErrors={showErrors}
                        onChange={this.updateModelProp("contactName")}
                    />
                    <Input.Email
                        value={values.email}
                        label="Email"
                        showErrors={showErrors}
                        onChange={this.updateModelProp("email")}
                    />
                    <Input.Text
                        value={values.telephoneNumber}
                        label="Telephone Number"
                        showErrors={showErrors}
                        validation={phoneNumberWithSpaces()}
                        onChange={this.updateModelProp("telephoneNumber")}
                    />
                    {venueTypeNeedsAddress(values.venueType) && (
                        <AddressLookup
                            address={values.address || {} as Address}
                            showErrors={showErrors}
                            onChange={this.updateModelProp("address")}
                        />
                    )}
                    <Input.DropdownMulti
                        value={values.attributes}
                        label="Attributes"
                        options={optionsFromObject(VenueAttributes)}
                        dynamicOptions
                        showErrors={showErrors}
                        onChange={this.updateModelProp("attributes")}
                    />
                    <Input.DropdownNumber
                        value={values.deliveryType}
                        label="Delivery type"
                        showErrors={showErrors}
                        validation={[validators.requiredNumber()]}
                        options={deliveryTypeOptions}
                        onChange={this.updateDeliveryType}
                        dynamicOptions
                        required
                    />
                    <Input.DropdownMulti
                        value={values.workflowTypes}
                        label="Workflows"
                        options={workflowTypeOptions}
                        dynamicOptions
                        required
                        showErrors={showErrors}
                        validation={[workflowTypesValidator()]}
                        onChange={this.updateWorkflowProp()}
                    />
                    {this.containsDors() &&
                   <><Input.Dropdown
                       label="Police Force"
                       placeholder="Select Police Force"
                       showErrors={showErrors}
                       value={values.organisationId}
                       options={organisations.map(o => ({ key: o.id, text: o.name, value: o.id }))}
                       onChange={this.updateOrganisationProp()}
                       required
                       dynamicOptions
                       search
                   />
                   <Input.DropdownNumber
                       label="Dors Site"
                       placeholder="Select Dors Site"
                       showErrors={showErrors}
                       value={values.dorsId}
                       options={dorsSiteOptions}
                       onChange={this.updateModelProp("dorsId")}
                       required
                       dynamicOptions
                       search
                   />
                   </>
                    }
                    {this.containsDdrs() &&
                    <>
                        <Input.DropdownNumber
                            label="DSA Area"
                            placeholder="Select DSA Area"
                            showErrors={showErrors}
                            value={values.dsaAreaId}
                            options={dsaAreaOptions}
                            onChange={this.updateDsaAreaProp()}
                            required
                            dynamicOptions
                            search
                        />
                        <Input.DropdownNumber
                            label="Country"
                            showErrors={showErrors}
                            options={countryOptions}
                            value={values.referredCourtCountry}
                            disabled
                            required
                        />
                    </>
                    }
                    <MuiDateField
                        value={values.expiryDate}
                        label="Expiry Date"
                        showErrors={showErrors}
                        validation={[muiFutureDateValidator]}
                        onChange={this.updateExpiryDate()}
                    />
                    <CurrencyInput
                        label="Venue Fee (net)"
                        value={values.venueFee}
                        showErrors={showErrors}
                        onChange={this.updateModelProp("venueFee")}
                    />
                    <Input.Textarea
                        value={values.feeNote}
                        label="Venue Fee Note"
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={this.updateModelProp("feeNote")}
                    />
                    {isPoliceOrCourt ?
                        <Input.Textarea
                            value={values.noteEn}
                            label={"Booking App Note (English)"}
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={showErrors}
                            onChange={this.updateModelProp("noteEn")}
                        />:
                        <MarkdownEditor
                            value={values.noteEn}
                            label="Booking Note"
                            showErrors={showErrors}
                            onChange={this.updateModelProp("noteEn")}
                        />}
                    {isPoliceOrCourt && (
                        <Input.Textarea
                            value={values.noteCy}
                            label="Booking App Note (Welsh)"
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={showErrors}
                            onChange={this.updateModelProp("noteCy")}
                        />
                    )}
                    {classroom && (
                        <Input.Number
                            value={values.maxCapacity}
                            label="Max Capacity"
                            showErrors={showErrors}
                            onChange={this.updateModelProp("maxCapacity")}
                        />
                    )}
                </Form>}</Spinner>
        );
    }

    public componentDidUpdate(prevProps: EditProps) {
        if (prevProps.organisations?.length !== this.props.organisations?.length && this.props.organisations.length !== 0) {
            this.updateOrganisation(this.state.values.organisationId, true);
        }
    }

    public submit = async () => {

        const { deliveryType, workflowTypes } =  this.state.values;
        const expiryDate = moment(this.state.values.expiryDate).add(1, "days");
        const coursesAfterExpiryDate = this.state.courses.filter(c => c.deliveryDateTime.isSameOrAfter(expiryDate));

        if (!Array.isArray(workflowTypes) || workflowTypes.length === 0) {
            toast.warning("A venue shold have at least one workflow type.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (!deliveryType || +deliveryType === DeliveryTypeEnum.None) {
            toast.warning("A venue shold have delivery type.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (this.policeOrCourtDigitalWithMultipleWorkflows(deliveryType, workflowTypes)) {
            toast.warning("A digital venue should not have multiple workflow types.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            toast.error("Please correct any invalid fields");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (!coursesAfterExpiryDate.length || !this.state.values.expiryDate) {
            this.handleSubmit({ preventDefault: (): void => undefined } as any);
            return;
        }

        if (!this.state.hideCoursesModalOpen) {
            this.setHideCoursesModalOpen(true);
            return;
        }

        this.setState({ submitting: true });
        await new EventInstanceApi().bulkUpdateReasonForHidingEvent(coursesAfterExpiryDate.map(c => c.id), "Venue expires before start date.");
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
        this.setState({ submitting: false });
        this.setHideCoursesModalOpen(false);
    };
}

function mapStateToProps(state: AppState & OrganisationState & LoadingState & AuthState, ownProps: VenueBaseProps) {
    const isPoliceOrCourt = ownProps.isPoliceOrCourt;
    const isCorporate = ownProps.isCorporate;
    const isConstruction = ownProps.isConstruction;
    const isBusinessDriverAdmin = state.currentUser?.roles?.includes(BusinessDriverEventAdmin) || false;
    const isCorporateEventAdmin = state.currentUser?.roles?.includes(TtcCorporateEventAdmin) || false;

    const organisationOptions = isCorporate
        ? corporateOrganisationOptionsSelector(state)
        : isConstruction
            ? constructionOrganisationOptionsSelector(state)
            : [];

    return {
        model: venueSelector(state),
        open: state.router.pathname.endsWith("/edit"),
        isPoliceOrCourt,
        isCorporate,
        isConstruction,
        isBusinessDriverAdmin,
        isCorporateEventAdmin,
        organisationId: ownProps.organisationId,
        basePath: basePathSelector(state),
        organisations: organisationSelectors.unexpiredOrganisationsSelector(state),
        loading: state.loading.active,
        dsaAreas: organisationSelectors.dsaAreasSelector(state),
        organisationOptions
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (venue: VenueCreateEditModel, basePath: string) => dispatch(saveVenue(venue, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        model: propsFromState.model,
        open: propsFromState.open,
        organisations: propsFromState.organisations,
        save: (venue: VenueCreateEditModel) => propsFromDispatch.dispatchSave(venue, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`),
        loading: propsFromState.loading,
        dsaAreas: propsFromState.dsaAreas,
        isPoliceOrCourt: propsFromState.isPoliceOrCourt,
        isCorporate: propsFromState.isCorporate,
        isConstruction: propsFromState.isConstruction,
        isBusinessDriverAdmin: propsFromState.isBusinessDriverAdmin,
        isCorporateEventAdmin: propsFromState.isCorporateEventAdmin,
        organisationId: propsFromState.organisationId,
        organisationOptions: propsFromState.organisationOptions,
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
