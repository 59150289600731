import * as React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";
import { Provider } from "react-redux";
import { initialiseLocation, configureStore } from "@common/startup";
import "react-toastify/dist/ReactToastify.css";
import "./style/index.scss";
import { registerServiceWorker } from "@common/index";
import { setCurrentLanguage } from "@common/language";
import i18next from "i18next";
import { loadAppSettings } from "@common/appSettings";
import { configureI18n } from "./i18n/i18n";
import { loadExternalLinks } from "@common/ExternalLinks";
import { App } from "./app";
import { routes } from "./routes";
import { rootReducer as reducers } from "./reducers";

moment.updateLocale("en", {
    week: {
        dow: 1,
    },
});

const store = configureStore(reducers, routes);

store.dispatch<any>(initialiseLocation());
store.dispatch<any>(loadExternalLinks());
store.dispatch<any>(loadAppSettings());

configureI18n().then(() => {
    store.dispatch<any>(setCurrentLanguage(i18next.language));

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("approot")
    );
});

registerServiceWorker();
