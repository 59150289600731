/* eslint-disable max-lines */
import * as React from "react";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { OrderApi } from "../orderApi";
import { CorporateEventInstanceResultRow, CorporateEventTypeResultRow, Basket, DayOfWeek, DayOfWeekEnum, BasketComparisonResult,
    EventInstanceEnquiry } from "../model";
import { OfferedCourseDetails } from "./OfferedCourseDetails";
import { ExtendedDropdown, ExtendedDropdownMulti } from "@common/components/ExtendedDropdown";
import { BasketModal } from "./BasketModal";
import "./CreateBasket.scss";
import { toast } from "@common/toasts";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { MuiDateField } from "@common/components/MuiDateField";
import { getDateInputFormat } from "@common/dateFormating";
import { optionsFromObjectIncludingZero } from "@common/crud/common";
import { routeCorporateUserIdSelector } from "@common/crud/corporateUser/selectors";
import { BusinessLineType, businessLineTypeSelector, organisationIdSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { compareBaskets, prepareOrdersUrl } from "../helpers";
import { muiAnyDateValidator } from "@common/validation/futureDateValidator";
import { relatedOrganisationOptionsSelectorWithNone } from "@common/crud/organisation/selectors";
import { loadRelatedOrganisationOptions } from "@common/crud/organisation/actions";
import { RelatedOrganisationChangedModal } from "./RelatedOrganisationChangedModal";

export const CreateBasket = () => {
    const [basket, setBasket] = React.useState<Basket>(undefined);
    const [basketComparisonResult, setBasketComparisonResult] = React.useState<BasketComparisonResult>(undefined);
    const [basketChangesInProgress, setBasketChangesInProgress] = React.useState<boolean>(false);

    const [showRelatedOrganisation, setShowRelatedOrganisation] = React.useState<boolean>(false);
    const [relatedOrganisationId, setRelatedOrganisationId] = React.useState<string>(undefined);

    const [showFilters, setShowFilters] = React.useState<boolean>(false);
    const [offeredCourseTypes, setOfferedCourseTypes] = React.useState<CorporateEventTypeResultRow[]>([]);
    const [choosenCourseTypes, setChoosenCourseTypes] = React.useState<CorporateEventTypeResultRow[]>();
    const [fromDate, setFromDate] = React.useState<moment.Moment>(undefined);
    const [toDate, setToDate] = React.useState<moment.Moment>(undefined);
    const [daysOfWeek, setDaysOfWeek] = React.useState<number[]>([]);

    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [offeredCourses, setOfferedCourses] = React.useState<CorporateEventInstanceResultRow[]>([]);
    const [offeredEverything, setOfferedEverything] = React.useState<boolean>(false);

    const [enquiryData, setEnquiryData] = React.useState<EventInstanceEnquiry>(undefined);

    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);
    const organisationOptions = useSelector(relatedOrganisationOptionsSelectorWithNone);
    const corporateOrganisationId = useSelector(organisationIdSelector);
    const corporateUserId = useSelector(routeCorporateUserIdSelector);

    const dispatch = useDispatch();

    const memoizedChoosenCourseTypeIds = React.useMemo(() => choosenCourseTypes?.map(x => x.id) || [], [choosenCourseTypes]);

    React.useEffect(() => {
        if (businessLineType === BusinessLineType.Corporate && organisationOptions.length === 1) {
            dispatch(loadRelatedOrganisationOptions());
        }
    }, [businessLineType, dispatch, organisationOptions.length]);

    React.useEffect(() => {
        const fetchData = async () => {
            const api = new OrderApi();

            if (offeredCourseTypes.length === 0) {
                const courseTypes = await api.getOfferedCourseTypes(businessLineType);
                setOfferedCourseTypes(courseTypes);
            }

            const courses = await api.getOfferedCourses(businessLineType, currentPage, memoizedChoosenCourseTypeIds, fromDate, toDate, daysOfWeek,
                relatedOrganisationId);
            setOfferedCourses(currentPage === 0
                ? courses
                : [...offeredCourses, ...courses]);

            setOfferedEverything(courses.length === 0 || courses.length % 20 !== 0);
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedChoosenCourseTypeIds, businessLineType, fromDate, toDate, daysOfWeek, currentPage, relatedOrganisationId]);

    React.useEffect(() => {
        const fetchEnquiryData = async () => {
            const api = new OrderApi();
            const preparedEnquiryData = await api.prepareEnquiryData(corporateOrganisationId, corporateUserId);
            setEnquiryData(preparedEnquiryData);
        };

        fetchEnquiryData();
    }, [corporateOrganisationId, corporateUserId]);

    const handleRelatedOrganisationChange = React.useCallback(async (value: string) => {
        if (basket?.id && relatedOrganisationId !== value) {
            setBasketChangesInProgress(true);
            try {
                const api = new OrderApi();
                const updatedBasket = await api.changeBasketRelatedOrganisation(businessLineType, basket.id, value);

                if (!updatedBasket?.id) {
                    toast.error("Basket no longer exists");
                    dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                    return;
                }

                const comparisonResult = compareBaskets(basket, updatedBasket);
                setBasket(updatedBasket);
                setBasketComparisonResult(comparisonResult);
                setCurrentPage(0);
                setRelatedOrganisationId(updatedBasket?.relatedOrganisationId);
            } finally {
                setBasketChangesInProgress(false);
            }
        }
    }, [basket, corporateOrganisationId, corporateUserId, dispatch, relatedOrganisationId, eventManagementPath, businessLineType]);

    React.useEffect(() => {
        const fetchData = async () => {
            setBasketChangesInProgress(true);
            try {
                const api = new OrderApi();

                const existingBasket = await api.getBasket(businessLineType, corporateOrganisationId, corporateUserId);
                setBasket(existingBasket);
                setRelatedOrganisationId(existingBasket?.relatedOrganisationId);

                if (corporateOrganisationId && existingBasket?.relatedOrganisationId !== corporateOrganisationId) {
                    handleRelatedOrganisationChange(corporateOrganisationId);
                }
            } finally {
                setBasketChangesInProgress(false);
            }
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corporateOrganisationId, corporateUserId]);

    const handleChoosenCourseTypeChange = React.useCallback((value: string[]) => {
        setCurrentPage(0);

        if (value) {
            const courseTypes = offeredCourseTypes.filter(x => value.some(y => y === x.id));
            setChoosenCourseTypes(courseTypes);
        } else {
            setChoosenCourseTypes(undefined);
        }
    }, [offeredCourseTypes]);

    const handleDayOfWeekChange = React.useCallback((value: number[]) => {
        setCurrentPage(0);
        setDaysOfWeek(value);
    }, []);

    const handleFromDateChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        if (valid) {
            setCurrentPage(0);
            setFromDate(value);
        }
    }, []);

    const handleToDateChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        if (valid) {
            setCurrentPage(0);
            setToDate(value);
        }
    }, []);

    const clearFilters = React.useCallback(() => {
        setCurrentPage(0);
        setChoosenCourseTypes(undefined);
        setFromDate(undefined);
        setToDate(undefined);
        setDaysOfWeek([]);
    }, []);

    const handleSeatsChangeForCourse = React.useCallback(async (offeredCourseId: string, numberOfSeats: number, add: boolean, seatsDiff?: number) => {
        setBasketChangesInProgress(true);
        try {
            const seatsForOfferedCourse = basket?.items
                ? Object.values(basket.items).find(x => x.eventInstanceId === offeredCourseId)?.numberOfSeats ?? 0
                : 0;
            const orderApi = new OrderApi();
            const changedBasket = await orderApi.updateBasketItem(businessLineType, basket.id, offeredCourseId, numberOfSeats, add);

            if (!changedBasket?.id) {
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                return;
            }

            setBasket(changedBasket);
            const seatsForChangedBasketOfferedCourse = Object.values(changedBasket.items).find(x => x.eventInstanceId === offeredCourseId)?.numberOfSeats ?? 0;

            if (add
                ? seatsForChangedBasketOfferedCourse === seatsForOfferedCourse + numberOfSeats
                : !Object.values(changedBasket.items).some(x => x.eventInstanceId === offeredCourseId)) {
                setOfferedCourses(offeredCourses.map(x => x.id === offeredCourseId ? {
                    ...x,
                    seatsAvailable: add
                        ? x.seatsAvailable - numberOfSeats
                        : x.seatsAvailable + seatsDiff
                } : x));

                const eventInstanceAlreadyInBasket = basket && basket.items && Object.values(basket?.items).some(x => x.eventInstanceId === offeredCourseId);

                toast.success(add
                    ? numberOfSeats > 0
                        ? `${numberOfSeats} place added to basket`
                        : `${Math.abs(numberOfSeats)} place removed from basket`
                    : numberOfSeats === 0
                        ? "Course removed from basket"
                        : eventInstanceAlreadyInBasket
                            ? `Course in basket updated to ${numberOfSeats} places`
                            : `Course added to basket with ${numberOfSeats} places`);
            } else {
                toast.error("Not possible to make that reservation, please reload offered courses");
            }
        } finally {
            setBasketChangesInProgress(false);
        }
    }, [basket, corporateOrganisationId, corporateUserId, dispatch, offeredCourses, eventManagementPath, businessLineType]);

    const handleLoadMoreCourses = React.useCallback(() => {
        setCurrentPage(currentPage + 1);
    }, [currentPage]);

    const confirmBasket = React.useCallback(async () => {
        setBasketChangesInProgress(true);
        try {
            const orderApi = new OrderApi();
            const changeBasketStatusResult = await orderApi.confirmBasketCourses(businessLineType, basket.id);

            if (!changeBasketStatusResult?.corporateBasket?.id) {
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                return;
            }

            if (changeBasketStatusResult.error) {
                toast.error(changeBasketStatusResult.error);
            }

            setBasket(changeBasketStatusResult.corporateBasket);
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders/detailsAndPayment", corporateOrganisationId, corporateUserId)));
        } finally {
            setBasketChangesInProgress(false);
        }
    }, [basket, corporateOrganisationId, corporateUserId, dispatch, businessLineType, eventManagementPath]);

    const dateInputFormat = React.useMemo(() => getDateInputFormat(), []);

    const courseOptions = React.useMemo(() => offeredCourseTypes.map(x => ({ text: x.name, value: x.id })), [offeredCourseTypes]);
    const daysOfWeekOptions = React.useMemo(() => {
        const options = optionsFromObjectIncludingZero(DayOfWeek);
        const sundayIndex = options.findIndex(option => option.key === DayOfWeekEnum.Sunday);
        const sundayOption = options.splice(sundayIndex, 1)[0];
        options.push(sundayOption);
        return options;
    }, []);

    const toggleFilters = React.useCallback(() => setShowFilters(!showFilters), [showFilters]);

    const choosenCourseTypeIds = React.useMemo(() => choosenCourseTypes?.map(x => x.id) ?? [], [choosenCourseTypes]);

    const filtersClearable = React.useMemo(() => choosenCourseTypeIds?.length > 0 || fromDate || toDate || daysOfWeek?.length > 0,
        [choosenCourseTypeIds?.length, daysOfWeek?.length, fromDate, toDate]);

    const showFiltersContent = React.useMemo(() => showFilters ? "Hide Filters" : "Show Filters", [showFilters]);

    const toggleRelatedOrganisation = React.useCallback(() => setShowRelatedOrganisation(!showRelatedOrganisation), [showRelatedOrganisation]);

    const showRelatedOrganisationContent = React.useMemo(() =>
        showRelatedOrganisation ? "Hide Related Organisation" : "Show Related Organisation", [showRelatedOrganisation]);

    const closeRelatedOrganisationChangedModal = React.useCallback(() => setBasketComparisonResult(undefined), []);

    const pageTitle = React.useMemo(() => businessLineType === BusinessLineType.Corporate
        ? "Driver CPC Periodic Training Courses"
        : "Construction Open Courses"
    , [businessLineType]);

    return (
        <>
            <Dimmer active={basketChangesInProgress} inverted >
                <Loader active={basketChangesInProgress} inline />
            </Dimmer>
            <div className="create-basket-page">
                <RelatedOrganisationChangedModal
                    basketComparisonResult={basketComparisonResult}
                    close={closeRelatedOrganisationChangedModal}
                />
                <h2 className="courses-title">{pageTitle}</h2>
                <div className="filters-actions">
                    {businessLineType === BusinessLineType.Corporate && (
                        <Button className="link-button filter-button" content={showRelatedOrganisationContent} onClick={toggleRelatedOrganisation} />
                    )}
                    <Button className="link-button filter-button" content={showFiltersContent} onClick={toggleFilters} />
                    <Button className="link-button filter-button" content="Clear Filters" onClick={clearFilters} disabled={!filtersClearable} />
                    <BasketModal
                        basket={basket}
                        seatsChangedForCourse={handleSeatsChangeForCourse}
                        basketChangesInProgress={basketChangesInProgress}
                        confirmBasket={confirmBasket}
                    />
                </div>
                {showRelatedOrganisation && (
                    <Grid stackable className="course-filter-grid full-width">
                        <Grid.Row>
                            <Grid.Column>
                                <div className="filters-and-basket">
                                    <ExtendedDropdown
                                        value={relatedOrganisationId}
                                        label="Related Organisation"
                                        options={organisationOptions}
                                        onChange={handleRelatedOrganisationChange}
                                        dynamicOptions
                                        search
                                        disabled={!!corporateOrganisationId}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                {showFilters && (
                    <Grid stackable className="course-filter-grid full-width">
                        <Grid.Row>
                            <Grid.Column>
                                <div className="filters-and-basket">
                                    <ExtendedDropdownMulti
                                        value={choosenCourseTypeIds}
                                        dynamicOptions
                                        options={courseOptions}
                                        onChange={handleChoosenCourseTypeChange}
                                        search
                                        placeholder="Filter by product"
                                        multiple
                                        label="Product"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="filters-and-basket">
                                    <ExtendedDropdownMulti
                                        value={daysOfWeek}
                                        dynamicOptions
                                        options={daysOfWeekOptions}
                                        onChange={handleDayOfWeekChange}
                                        search
                                        placeholder="Days of week"
                                        multiple
                                        label="Days of week"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="filters-and-basket-dates">
                                    <MuiDateField
                                        label="Date from"
                                        placeholder={dateInputFormat}
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        key={"fromDate-" + fromDate?.toString()}
                                        validation={[muiAnyDateValidator]}
                                        showErrors
                                    />
                                    <MuiDateField
                                        label="Date to"
                                        placeholder={dateInputFormat}
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        key={"toDate-" + toDate?.toString()}
                                        validation={[muiAnyDateValidator]}
                                        showErrors
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                <div className="courses-list">
                    {offeredCourses.length === 0 && <div>No courses found</div>}
                    {offeredCourses.map(oc => (
                        <OfferedCourseDetails
                            key={oc.id}
                            offeredCourse={oc}
                            seatsAddedForCourse={handleSeatsChangeForCourse}
                            basketChangesInProgress={basketChangesInProgress}
                            enquiryData={enquiryData}
                        />
                    ))}
                    {(offeredCourses.length > 0 && !offeredEverything) &&
                        <Button onClick={handleLoadMoreCourses} className="link-button adjusted-to-offered-course">Load more</Button>}
                </div>
            </div>
        </>
    );
};
