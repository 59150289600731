import * as React from "react";
import moment from "moment";
import { Input } from "@neworbit/simpleui-input";
import { DurationPicker } from "@common/crud/common";
import { CreateFieldPickerProps, DdrsAllCountriesFeeConfig } from "../model";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { DdrsFeesTable } from "./details/DdrsFeesTable";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { CountryEnum } from "@common/crud/organisation/model";
import { positiveNumber } from "@common/validation/positiveNumber";

export function EventDurationPicker(props: CreateFieldPickerProps<moment.Duration>)
{return  (<DurationPicker
    onChange={props.setField}
    eventDuration={props.field}
    showErrors={props.showErrors}
/>);}

export  function RegistrationDurationPicker(props: CreateFieldPickerProps<moment.Duration>)
{return (<DurationPicker
    label={"Registration Duration"}
    onChange={props.setField}
    eventDuration={props.field}
    showErrors={props.showErrors}
    showHours={false}
    freeEntry
/>);
}

export function MaxAttendeesNumberPicker(props: CreateFieldPickerProps<number>)
{
    const validation = React.useMemo(() => {
        const baseValidation = props.showErrors ? [positiveNumber()] : [];
        return props.validation ? baseValidation.concat(props.validation) : baseValidation;
    }, [props.showErrors, props.validation]);

    return (
        <Input.Number
            label={"Max number of attendees"}
            onChange={props.setField}
            value={props.field}
            showErrors={props.showErrors}
            validation={validation}
            validationOptions={{ sequential: false }}
            required
        />
    );
}

export function CurrencyPicker(label: string) {
    return function(props: CreateFieldPickerProps<number>)
    {return (<CurrencyInput
        value={props.field}
        label={label}
        showErrors={props.showErrors}
        onChange={props.setField}
    />);
    };
}

export function DDRSFeesTablePicker(push: (path: string) => void, create: boolean) {
    return function DDRSFeesTablePick(props: CreateFieldPickerProps<DdrsAllCountriesFeeConfig>) {
        {
            return (<DdrsFeesTable ddrsSchemeFeeConfig={props.field}
                push={push}
                create={create}
                showErrors={props.showErrors}
                onChange={props.setField as React.Dispatch<React.SetStateAction<DdrsAllCountriesFeeConfig>>}
                editable />);

        }

    };
}

export function DDRSFeeViewer(push: (path: string) => void, create: boolean, country: CountryEnum) {
    return function DDRSFeeTableView(value: DdrsAllCountriesFeeConfig) {
        const feeValue = country? { [country]: [{ value }] }:value;
        return <DdrsFeesTable ddrsSchemeFeeConfig={feeValue} push={push} create={create} />;

    };

}

export function DateValueViewer(value: moment.Duration) {
    return value.format(DateFormat.Time, { trim: false });
}
