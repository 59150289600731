import * as React from "react";
import moment from "moment";
import { Tab } from "semantic-ui-react";
import { rangeValidator } from "@common/validation";
import { Input } from "@neworbit/simpleui-input";
import { ClassroomEventTypeDetails, ddrsClassroomPropName, dorsPracticalPropName, dorsPropName, dorsTheoryPropName, DurationTypeEnum, EventTypePart,
    InitialiseEventTypePartsBasedOnDefaultNumberOfDeliveryDays, MultiDayType, ProductCategoryEnum, WorkflowTypeEnum } from "../../model";
import { StartTimeForSessionsPicker } from "@common/crud/eventType/components/StartTimeForSessionsPicker";
import { EventTypePartPicker } from "@common/crud/eventType/components/EventTypePartPicker";
import { EditFieldWithEffectiveDate } from "../EditFieldWithEffectiveDate";
import { DateValueViewer, EventDurationPicker, MaxAttendeesNumberPicker,
    RegistrationDurationPicker } from "../EventTypeFieldPickerHelpers";
import { optionsFromObject } from "@common/crud/common";
import { isWorkflowConstruction } from "@common/global/CommonHelpers";
export interface OwnProps {
    classroomEventTypeDetails: ClassroomEventTypeDetails;
    workflowType: WorkflowTypeEnum;
    showErrors?: boolean;
    productCategory: ProductCategoryEnum;
    updateStartTimes: (propName: string, startTimes: Dictionary<moment.Duration[]>) => void;
    updateEventTypeParts: (propName: string, startTimes: Dictionary<EventTypePart>) => void;
    onChange(propName: string, termsAndConditionsProperty?: boolean): (value: any, valid: boolean) => void;
    theoryAndPractical: boolean;
    durationType: DurationTypeEnum;
    showInOpenBookingApp: boolean;
}

export const ClassroomDetails: React.FC<OwnProps> = ({
    classroomEventTypeDetails,
    workflowType,
    showErrors,
    productCategory,
    onChange,
    updateStartTimes,
    updateEventTypeParts,
    theoryAndPractical,
    durationType,
    showInOpenBookingApp }) => {

    const { defaultNumberOfClassroomDeliveryDays, defaultMultiDayType, maxNumberOfAttendeesPerTrainerWithEffectiveDate, maxNumberOfAttendeesWithEffectiveDate,
        termsAndConditionsLinkEn, termsAndConditionsLinkCy, classroomEventTypeParts, suggestedStartTimesForSessions, suggestedStartTimesForPracticalSessions,
        suggestedStartTimesForTheorySessions, eventDurations, educationDurations, classroomRegistrationDurations, theoryEventDurations, practicalEventDurations,
        classroomBreakEvenPoints
    } = classroomEventTypeDetails;

    const isDors = workflowType === WorkflowTypeEnum.Dors;
    const isDdrs = workflowType === WorkflowTypeEnum.DDRS;
    const hasEducationDuration = workflowType === WorkflowTypeEnum.CPC || productCategory === ProductCategoryEnum.OnRoadWithCpc;
    const isConstructionWorkflow = isWorkflowConstruction(workflowType);

    function updateStarts(value: Dictionary<moment.Duration[]>) {
        updateStartTimes(dorsPropName, value);
    }

    function updateTheoryStarts(value: Dictionary<moment.Duration[]>) {
        updateStartTimes(dorsTheoryPropName, value);
    }

    function updatePracticalStarts(value: Dictionary<moment.Duration[]>) {
        updateStartTimes(dorsPracticalPropName, value);
    }

    function updateClassromStarts(value: Dictionary<moment.Duration[]>) {
        updateTheoryStarts(value);
        updatePracticalStarts(value);
    }

    function updateParts(value: Dictionary<EventTypePart>) {
        updateEventTypeParts(ddrsClassroomPropName, value);
    }

    function onChangeCombinedEventDurations(value: any, valid: boolean) {
        onChange("classroomEventTypeDetails.theoryEventDurations")(value, valid);
        onChange("classroomEventTypeDetails.practicalEventDurations")(value, valid);
    }

    const updateDefaultNumberOfClassroomDeliveryDays = React.useCallback((value: number, valid: boolean) => {
        const newEventTypeParts = InitialiseEventTypePartsBasedOnDefaultNumberOfDeliveryDays(classroomEventTypeParts , value);
        onChange("classroomEventTypeDetails.classroomEventTypeParts")(newEventTypeParts, true);

        onChange("classroomEventTypeDetails.defaultNumberOfClassroomDeliveryDays")(value > 10 ? defaultNumberOfClassroomDeliveryDays : value, valid);
    }, [classroomEventTypeParts, defaultNumberOfClassroomDeliveryDays, onChange]);

    const useSingleDayControls = isDors || defaultNumberOfClassroomDeliveryDays === 1;
    const theoryAndPracticalDetails = useSingleDayControls && theoryAndPractical;

    return (
        <Tab.Pane key="classroom-details">
            <h3>Course Details</h3>
            {(!isDors && !theoryAndPractical) && <Input.Number
                value={defaultNumberOfClassroomDeliveryDays}
                label={isDdrs ? "Default Number of Classroom Delivery Days" : "Default Number of Delivery Days"}
                showErrors={showErrors}
                validation={rangeValidator(1, 10)}
                onChange={updateDefaultNumberOfClassroomDeliveryDays}
            />}
            {defaultNumberOfClassroomDeliveryDays > 1 && <Input.DropdownNumber
                label="Default Multi Day Type"
                value={defaultMultiDayType}
                options={optionsFromObject(MultiDayType)}
                onChange={onChange("classroomEventTypeDetails.defaultMultiDayType")}
            />}
            {(useSingleDayControls && !theoryAndPractical) && <EditFieldWithEffectiveDate
                allFields={eventDurations}
                onChange={onChange("classroomEventTypeDetails.eventDurations")}
                CreateFieldPicker={EventDurationPicker}
                showErrors={showErrors}
                fieldName={"Event Duration"}
                valueView={DateValueViewer}
                fieldIsDuration
                horizontalDisplayTitle="Event Duration"
            />}
            {(theoryAndPracticalDetails) &&
                <>
                    {durationType === DurationTypeEnum.Combined && (
                        <EditFieldWithEffectiveDate
                            allFields={theoryEventDurations}
                            onChange={onChangeCombinedEventDurations}
                            CreateFieldPicker={EventDurationPicker}
                            showErrors={showErrors}
                            fieldName={"Event Duration"}
                            valueView={DateValueViewer}
                            fieldIsDuration
                            horizontalDisplayTitle="Event Duration"
                        />
                    )}
                    {durationType !== DurationTypeEnum.Combined && (
                        <>
                            <EditFieldWithEffectiveDate
                                allFields={theoryEventDurations}
                                onChange={onChange("classroomEventTypeDetails.theoryEventDurations")}
                                CreateFieldPicker={EventDurationPicker}
                                showErrors={showErrors}
                                fieldName={"Theory Event Duration"}
                                valueView={DateValueViewer}
                                fieldIsDuration
                                horizontalDisplayTitle="Theory Event Duration"
                            />
                            <EditFieldWithEffectiveDate allFields={practicalEventDurations}
                                onChange={onChange("classroomEventTypeDetails.practicalEventDurations")}
                                CreateFieldPicker={EventDurationPicker}
                                showErrors={showErrors}
                                fieldName={"Practical Event Duration"}
                                valueView={DateValueViewer}
                                fieldIsDuration
                                horizontalDisplayTitle="Practical Event Duration"
                            />
                        </>
                    )}
                </>
            }
            {(useSingleDayControls && hasEducationDuration) && <EditFieldWithEffectiveDate
                allFields={educationDurations}
                onChange={onChange("classroomEventTypeDetails.educationDurations")}
                CreateFieldPicker={EventDurationPicker}
                showErrors={showErrors}
                fieldName={"Education Duration"}
                valueView={DateValueViewer}
                fieldIsDuration
                horizontalDisplayTitle="Education Duration"
            />}
            {useSingleDayControls && <EditFieldWithEffectiveDate allFields={classroomRegistrationDurations}
                onChange={onChange("classroomEventTypeDetails.classroomRegistrationDurations")} CreateFieldPicker={RegistrationDurationPicker}
                showErrors={showErrors} fieldName={"Registration Duration"} valueView={DateValueViewer} fieldIsDuration />}
            {!useSingleDayControls && <EventTypePartPicker
                eventTypeParts={classroomEventTypeParts}
                updateEventTypeParts={updateParts} defaultDays={defaultNumberOfClassroomDeliveryDays} />}
            {useSingleDayControls && <>
                <h3>Course Planning</h3>
                {!theoryAndPracticalDetails && <StartTimeForSessionsPicker
                    startTimesForSessions={suggestedStartTimesForSessions ?? {}}
                    updateStartTimesForSessions={updateStarts}
                    showErrors={showErrors}
                />}
                {theoryAndPracticalDetails &&
                <>
                    {durationType === DurationTypeEnum.Combined && (
                        <StartTimeForSessionsPicker
                            startTimesForSessions={suggestedStartTimesForTheorySessions ?? {}}
                            updateStartTimesForSessions={updateClassromStarts}
                            showErrors={showErrors}
                            sessionType="Classroom"
                        />
                    )}
                    {durationType !== DurationTypeEnum.Combined && (
                        <>
                            <StartTimeForSessionsPicker
                                startTimesForSessions={suggestedStartTimesForTheorySessions ?? {}}
                                updateStartTimesForSessions={updateTheoryStarts}
                                showErrors={showErrors}
                                sessionType="Theory"
                            />
                            <StartTimeForSessionsPicker
                                startTimesForSessions={suggestedStartTimesForPracticalSessions ?? {}}
                                updateStartTimesForSessions={updatePracticalStarts}
                                showErrors={showErrors}
                                sessionType="Practical"
                            />
                        </>
                    )}
                </>}
            </>}
            <h3>Course Availability</h3>
            <EditFieldWithEffectiveDate allFields={maxNumberOfAttendeesWithEffectiveDate}
                onChange={onChange("classroomEventTypeDetails.maxNumberOfAttendeesWithEffectiveDate")} CreateFieldPicker={MaxAttendeesNumberPicker}
                showErrors={showErrors} fieldName={"Max number of attendees"} horizontalDisplayTitle={"Max number of classroom attendees"}  />
            <EditFieldWithEffectiveDate allFields={maxNumberOfAttendeesPerTrainerWithEffectiveDate}
                onChange={onChange("classroomEventTypeDetails.maxNumberOfAttendeesPerTrainerWithEffectiveDate")}
                CreateFieldPicker={MaxAttendeesNumberPicker}
                showErrors={showErrors} fieldName={"Max number of attendees"}
                horizontalDisplayTitle={"Max number of attendees per classroom theory trainer"} />
            {isConstructionWorkflow && showInOpenBookingApp && (
                <>
                    <h3>Break Even Points</h3>
                    <EditFieldWithEffectiveDate
                        allFields={classroomBreakEvenPoints}
                        onChange={onChange("classroomEventTypeDetails.classroomBreakEvenPoints")}
                        CreateFieldPicker={MaxAttendeesNumberPicker}
                        showErrors={showErrors}
                        fieldName={"Classroom break even points"}
                        horizontalDisplayTitle={"Classroom break even points"} />
                </>
            )}
            <h3>Terms and Conditions</h3>
            <Input.Text
                value={termsAndConditionsLinkEn}
                label="Terms and Conditions Link (English)"
                showErrors={showErrors}
                onChange={onChange("classroomEventTypeDetails.termsAndConditionsLinkEn", true)} />
            <Input.Text
                value={termsAndConditionsLinkCy}
                label="Terms and Conditions Link (Welsh)"
                showErrors={showErrors}
                onChange={onChange("classroomEventTypeDetails.termsAndConditionsLinkCy", true)} />
        </Tab.Pane>
    );
};
