import * as React from "react";
import moment from "moment";
import { Button, Container, Grid, Message, Segment, Tab } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole, TtcTester } from "@common/auth/model";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { CoreDetailProps } from "../Detail";
import { ContactDetailsModal } from "@common/crud/attendee/components/details/ContactDetailsModal";
import { BookButton } from "@common/crud/search/components/BookButton";
import { GenderDisplay } from "@common/crud/drinkDriveOffenders/components/GenderDisplay";
import { GenderEnum } from "@common/crud/drinkDriveOffenders/model";
import { AdHocCommsModal } from "@common/crud/attendee/components/details/AdHocComms/AdHocCommsModal";
import { SupportRequestsLabelWithEdit } from "@common/crud/dorsBooking/components/SupportRequestsLabelWithEdit";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { useDispatch, useSelector } from "react-redux";
import {
    setEligibleForConcessions,
    setAssistanceRequiredForBooking,
    unlockDrinkDriveOffenderAccount
} from "@common/crud/drinkDriveOffenders/actions";
import { AssistanceRequired } from "@common/crud/dorsBooking/model";
import { DrinkDriveOffenderCaseDetails } from "@common/crud/drinkDriveOffenders/components/details/DrinkDriveOffenderCaseDetails";
import { FirstInviteLetterModal } from "./FirstInviteLetterModal";
import { DdrsProviderTransferModal } from "./DdrsProviderTransferModal";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { ActionType, ResendCommsModal } from "@common/crud/attendee/components/details/ResendCommsModal";
import { EventTypeApi } from "@common/crud/eventType";
import { EventTypeDetailModel } from "@common/crud/eventType/model";
import { ConcessionsButton } from "./ConcessionsButton";
import {
    SetDrinkDriveInitialBookingDate
} from "@common/crud/drinkDriveOffenders/components/SetDrinkDriveInitialBookingDate";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";

export const DetailsTab: React.FC<CoreDetailProps> = ({ drinkDriveOffender, internalAppSettings }) => {
    const [eventType, setEventType] = React.useState<EventTypeDetailModel>(null);
    const [mustLoad, setMustLoad] = React.useState<boolean>(true);
    const dispatch = useDispatch();
    const isTester = useSelector(currentUserIsInRoleSelector(TtcTester));
    const concessions = !!drinkDriveOffender.drinkDriveOffenderDetails?.eligibleForConcessions;
    const updateConcessionsEligibility = React.useCallback(() => {
        dispatch(setEligibleForConcessions(drinkDriveOffender.id, !concessions));
    }, [concessions, dispatch, drinkDriveOffender.id]);

    React.useEffect(() => {
        if (mustLoad && drinkDriveOffender && drinkDriveOffender.eventTypeId !== undefined && !eventType) {
            setMustLoad(false);
            const eventTypeApi = new EventTypeApi();
            eventTypeApi.detail(drinkDriveOffender.eventTypeId).then(setEventType);
        }
    }, [drinkDriveOffender, eventType, mustLoad]);

    const updateSupportRequests = React.useCallback((model: AssistanceRequired) => {
        dispatch(setAssistanceRequiredForBooking(drinkDriveOffender.id, model));
    }, [dispatch, drinkDriveOffender.id]);

    const unlockAccount = React.useCallback(async () => {
        dispatch(unlockDrinkDriveOffenderAccount({ id: drinkDriveOffender.id }));
    }, [dispatch, drinkDriveOffender.id]);

    const normalizedAddress = {
        addressLine1: [drinkDriveOffender.buildingName, drinkDriveOffender.buildingNumber, drinkDriveOffender.address1].filter(x => x).join(" "),
        addressLine2: drinkDriveOffender.address2,
        addressLine3: drinkDriveOffender.address3,
        city: [drinkDriveOffender.townCity, drinkDriveOffender.postcode].filter(x => x).join(", "),
        postalCode: drinkDriveOffender.country
    };

    return (
        <Tab.Pane className="no-border">
            {drinkDriveOffender &&
                <Grid stackable>
                    <Grid.Column width={8}>
                        <Container className="white-panel ddrs-offender-details-attendee">
                            {drinkDriveOffender.isTransferred &&
                                <Grid.Column width={16}>
                                    <Message as={Segment} warning>
                                        <p>This attendee was transferred to {" "}
                                            <strong>{drinkDriveOffender?.drinkDriveOffenderDetails?.transferProvider}</strong> on {" "}
                                            {drinkDriveOffender?.drinkDriveOffenderDetails?.transferDate?.format(DateFormat.LongWithTime)}
                                        </p>
                                    </Message>
                                </Grid.Column>
                            }
                            {drinkDriveOffender.accountIsLocked && <>
                                <h5>Account is locked</h5>
                                <span>The account has been locked due to excessive failed login attempts.
                                    If you are satisfied you are talking to the correct individual
                                    and you have carefully verified their identity, you may unlock the account by clicking the button </span>
                                <Button onClick={unlockAccount} >Unlock account</Button>
                            </>
                            }
                            <h5>Attendee details</h5>
                            <LabelAndValue label="Name" value={`${drinkDriveOffender.forename} ${drinkDriveOffender.surname}`} />
                            <LabelAndValue label="Date Of Birth" value={moment.isMoment(drinkDriveOffender?.drinkDriveOffenderDetails?.dateOfBirth) &&
                                    drinkDriveOffender.drinkDriveOffenderDetails.dateOfBirth?.format(DateFormat.Short) || "N/A"} />
                            <LabelAndValue label="Gender" value={<GenderDisplay
                                gender={drinkDriveOffender?.drinkDriveOffenderDetails?.gender &&
                                    drinkDriveOffender.drinkDriveOffenderDetails.gender || GenderEnum.None} />} />
                            <LabelAndValue label="Address" value={<DisplayAddress address={normalizedAddress} />} />
                            <LabelAndValue label="Telephone" value={drinkDriveOffender.telephone || "None"} />
                            <LabelAndValue label="Email" value={drinkDriveOffender.email || "None"} />
                            <Authorize authorize={TtcClientAdvisorRole}>
                                <ContactDetailsModal
                                    dorsBooking={drinkDriveOffender}
                                />
                            </Authorize>
                        </Container>
                        <DrinkDriveOffenderCaseDetails
                            drinkDriveOffender={drinkDriveOffender.drinkDriveOffenderDetails} expiryDate={drinkDriveOffender.expiryDate}
                            externalAttendeeId={drinkDriveOffender.attendanceId} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Container className="blue-panel">
                            <h5>Course details</h5>
                            <LabelAndValue label="Scheme" value={drinkDriveOffender.eventTypeName} />
                            <LabelAndValue label="Date and Time" value="No course booked" />
                            <Authorize authorize={[TtcClientAdvisorRole]}>
                                <SupportRequestsLabelWithEdit
                                    correlationId={drinkDriveOffender.id}
                                    updateSupportRequests={updateSupportRequests}
                                    taskRelation={TaskRelationEnum.DdrsBooking}
                                    booking={drinkDriveOffender} />
                                <div className="attendee-actions">
                                    <BookButton
                                        disable={drinkDriveOffender.isTransferred}
                                        bookingId={drinkDriveOffender.id}
                                        bookingAppEndpoint={internalAppSettings.bookingAppEndpoint} />
                                    {drinkDriveOffender &&
                                        <ResendCommsModal eventType={eventType} recipient={drinkDriveOffender} actionType={ActionType.Reminder}
                                            buttonText={"Send Reminder To Book"} attendeeIsDrinkDriveOffender={true} />}

                                    <AdHocCommsModal
                                        correlationId={drinkDriveOffender.id}
                                        attendeeEmail={drinkDriveOffender.email}
                                        attendeeNumber={drinkDriveOffender.telephone}
                                    />
                                    {drinkDriveOffender && <FirstInviteLetterModal eventType={eventType} drinkDriveOffender={drinkDriveOffender} />}
                                    {drinkDriveOffender && <DdrsProviderTransferModal attendee={drinkDriveOffender} />}
                                    {isTester && <SetDrinkDriveInitialBookingDate externalAttendeeId={drinkDriveOffender.id} />}
                                </div>
                            </Authorize>
                        </Container>
                        <Container className="blue-panel">
                            <h5>Payment details</h5>
                            <LabelAndValue
                                label="Course price"
                                value={"N/A"} />
                            {ConcessionsButton(concessions, updateConcessionsEligibility)}
                        </Container>
                    </Grid.Column>
                </Grid>
            }
        </Tab.Pane>
    );
};
