import * as React from "react";
import { HistoryRecord, ChangeEventTypes, ChangeEventType } from "../model";
import { DdrsHistoryEntryValues } from "@common/history/components/DdrsHistoryEntryValues";

export interface HistoryEntryProps {
    entries: HistoryRecord[];
    showAllDetails: boolean;
    entryKey: string;
    onEditPendingNote: (entry: HistoryRecord) => void;
}

export const DdrsHistoryEntryTitle: React.FC<HistoryEntryProps> = ({ entries, showAllDetails, entryKey, onEditPendingNote }) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(showAllDetails);

    React.useEffect(() => {
        setShowDetails(showAllDetails);
    }, [showAllDetails]);

    const onShowDetails = React.useCallback(() => setShowDetails(!showDetails), [showDetails]);

    const splitKey = entryKey?.split("/");

    const time = splitKey[0];
    const eventType = parseInt(splitKey[1], 10);
    const recordModifiedByEmail = splitKey[2];

    return (
        <div className="entry">
            <span className="bold cursor-pointer" onClick={onShowDetails}>
                <span className="entry-type">
                    {ChangeEventTypes[eventType as ChangeEventType]}
                </span>
                {
                    eventType !== ChangeEventType.ExpiryDate && eventType !== ChangeEventType.FlexiPayExpiryDate
                    && <span className="creation-details">{time} by {recordModifiedByEmail}</span>
                }
            </span>
            {
                entries.map(entry => (
                    <DdrsHistoryEntryValues key={entry.id} entry={entry} onEditPendingNote={onEditPendingNote} showDetails={showDetails} />
                ))
            }
        </div>
    );
};
