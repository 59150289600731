import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const OverallSummarySection: QuestionSection = {
    id: "11",
    title: "Overall Summary",
    seqNum: 10,
    summaryCriterium: [{
        id: "11.1",
        text: "Provide written feedback here on the trainers’ performance overall, best " +
            "practice would utilise a recognised feedback model.  Feedback should be given" +
            " verbally in a confidential environment as well as in this written report",
        typeEnum: CriteriaEnum.text
    }]
};
