/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Icon, Popup, Segment } from "semantic-ui-react";
import { EventInstance } from "@common/crud/eventInstance";
import { LanguageEnum } from "@common/crud/eventInstance/model";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { useDispatch, useSelector } from "react-redux";
import { AttendeeRole, TtcPaymentHandlerRole } from "@common/auth/model";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import { ApplicationState } from "../applicationState";
import { getExternalLinksForGivenLanguage } from "../bookings/components/TermsAndFaqLinks";
import { View } from "./ViewEnum";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { venuesSelector } from "@booking/venues/selectors";
import { loadVenueDetail } from "@booking/venues/actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { InlineManualPayment } from "./chosenCourse/InlineManualPayment";
import { ManualPaymentModel } from "./models";
import { Authorize } from "reauthorize";
import { Media } from "@common/global/AppMedia";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface ChosenCourseProps {
    eventInstance: EventInstance;
    fullDetails: boolean;
    showLinks: boolean;
    payAmount?: number;
    waivedRebookingFee?: boolean;
    currentView: View;
    freshConfirmation?: boolean;
    scheduledPaymentAmount?: number;
    amountDue?: number;
    eligibleForFlexiPay?: boolean;
    flexiPayChecked?: boolean;
    flexiPayFee?: number;
    setFlexiPayChecked?: (checked: boolean) => void;
    onManualPaymentChange?: (model: ManualPaymentModel) => void;
    seatDiscount?: number;
    organisation?: OrganisationDetailModel;
}

export const ChosenCourse: React.FC<ChosenCourseProps> = (props: ChosenCourseProps) => {

    const [t] = useTranslation("ChosenCourse");

    const {
        eventInstance,
        fullDetails,
        showLinks,
        payAmount,
        currentView,
        scheduledPaymentAmount,
        freshConfirmation,
        amountDue,
        waivedRebookingFee,
        eligibleForFlexiPay,
        flexiPayChecked,
        flexiPayFee,
        setFlexiPayChecked,
        onManualPaymentChange,
        seatDiscount,
        organisation
    } = props;

    const rebookEventInstance = useSelector((state: ApplicationState) => state.rebookEventInstance);
    const rebooking = !!rebookEventInstance;

    let extraAmount: number = null;
    if (eventInstance?.priceInPence - rebookEventInstance?.priceInPence > 0) {
        extraAmount = (eventInstance.priceInPence - rebookEventInstance.priceInPence) / 100;
    }

    const user = useSelector((state: ApplicationState) => state.currentUser);
    const isAttendee = user.roles.includes(AttendeeRole);

    const courseDate = eventInstance && dateString(eventInstance.startDate, DateFormat.LongNoYear);
    const startTime = courseDate && eventInstance.startTime.format(DateFormat.Time, { trim: false });
    const endTime = courseDate && eventInstance.startTime.clone().add(eventInstance.eventDuration).format(DateFormat.Time, { trim: false });
    const links = eventInstance && getExternalLinksForGivenLanguage(eventInstance, organisation);
    const venue = useSelector(venuesSelector).find(v => v.id === eventInstance?.venueId);
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const dispatch = useDispatch();

    const [flexiPayDisabled, setFlexiPayDisabled] = React.useState(false);

    React.useEffect(() => {
        if (eventInstance) {
            dispatch(loadVenueDetail({ venueId: eventInstance.venueId }));
        }
    }, [dispatch, eventInstance, venue]);

    const eiStartsWithin14Days = moment(eventInstance?.deliveryDateTime).isSameOrBefore(moment().add(14, "days"));

    React.useEffect(() => {
        if (flexiPayChecked && currentView === View.BookingDetails && eiStartsWithin14Days) {
            setFlexiPayChecked(false);
        }
    }, [eiStartsWithin14Days, setFlexiPayChecked, currentView, flexiPayChecked]);

    const onFlexiPayChange = React.useCallback((_: any, { checked }: CheckboxProps) => setFlexiPayChecked(checked), [setFlexiPayChecked]);

    const displayEiPriceWithTranslation = (translation: string) => {

        const eventPrice = Math.max((payAmount ?? eventInstance?.priceInPence - ((seatDiscount ?? 0) * 100)) || 0, 0);
        const rebookingFee = eventInstance.additionalFeeInPence || 0;
        const flexiPayFeeInPence = flexiPayChecked ? flexiPayFee * 100 : 0;

        const paymentAmount = currentView === View.BookingDetails && waivedRebookingFee
            ? eventPrice - rebookingFee
            : eventPrice + flexiPayFeeInPence;

        return (
            <div className="cost">{translation} £{(paymentAmount / 100).toFixed(2)}</div>
        );
    };

    const displayFlexiPayCheckbox = () => (
        <>
            <Popup
                disabled={!eiStartsWithin14Days}
                trigger={
                    <Checkbox
                        label="Add flexi-booking"
                        checked={!eiStartsWithin14Days && flexiPayChecked}
                        onChange={onFlexiPayChange}
                        disabled={eiStartsWithin14Days || flexiPayDisabled}
                    />
                }
                content={"Flexi-booking cannot be added because the chosen course starts within 14 days."}
            />
            <Popup trigger={<Icon name="question circle" />} content={t("FLEXI_PAY_DESCRIPTION")} />
        </>
    );

    const displayPaymentInformation = () => (
        <>
            <h4 className="underline">{t("PAYMENT")}</h4>
            {eligibleForFlexiPay && isClientAdvisor && displayFlexiPayCheckbox()}
            {displayEiPriceWithTranslation(t("AMOUNT_TO_PAY"))}
            {eventInstance.additionalFee !== 0 && !waivedRebookingFee &&
                <Trans i18nKey="ChosenCourse:REARRANGEMENT_FEE" values={{ fee: eventInstance.additionalFee.toFixed(2) }}>
                    Price includes <span>fee</span> rearrangement fee
                </Trans>}
            {rebooking && extraAmount &&
                <Trans i18nKey="ChosenCourse:EXPENSIVE_VENUE" values={{ fee: extraAmount.toFixed(2) }}>
                    Price includes £<span>fee</span> extra to attend in this Police Force area
                </Trans>
            }
            {flexiPayFee && flexiPayChecked &&
                <Trans i18nKey="ChosenCourse:FLEXI_PAY_FEE" values={{ fee: flexiPayFee.toFixed(2) }}>
                    Price includes £<span>fee</span> flexi-booking fee
                </Trans>
            }
            <Media greaterThanOrEqual="tablet">
                <Authorize authorize={TtcPaymentHandlerRole}>
                    <InlineManualPayment
                        onManualPaymentChange={onManualPaymentChange}
                        flexiPayChecked={flexiPayChecked}
                        flexiPayFee={flexiPayFee}
                        eligibleForFlexiPay={eligibleForFlexiPay}
                        setFlexiPayDisabled={setFlexiPayDisabled}
                        seatDiscount={seatDiscount}
                    />
                </Authorize>
            </Media>
        </>
    );

    const stripePaymentInformation = () => (
        <>
            {displayEiPriceWithTranslation(t("PAYMENT_AMOUNT"))}
            {scheduledPaymentAmount && payAmount &&
                <div>{t("SCHEDULED_PAYMENT")} £{scheduledPaymentAmount.toFixed(2)}</div>}
        </>
    );

    const bookingConfirmationScreenInformation = () => {
        const showAmountDue = amountDue >= 0;
        const amount = amountDue !== 0 ? (amountDue / 100).toFixed(2) : amountDue.toFixed(2);

        return (
            <>
                {eventInstance.digitalCourseLink &&
                    <p>
                        <span className="chosen-course-label">{t("DIGITAL_LINK")}</span>
                        <a href={eventInstance.digitalCourseLink}>{eventInstance.digitalCourseLink}</a>
                    </p>
                }
                {freshConfirmation && <div className="cost">{t("AMOUNT_PAID")} £{payAmount && (payAmount / 100).toFixed(2)}</div>}
                {!isAttendee && amountDue < 0 &&
                    <div><span className="chosen-course-label">{t("AMOUNT_DUE")}</span>
                        £{Math.abs(amountDue / 100).toFixed(2)} </div>
                }
                {showAmountDue &&
                    <div><span className="chosen-course-label">{t("OUTSTANDING_BALANCE")}</span>
                        £{amount} </div>
                }
                {!waivedRebookingFee && scheduledPaymentAmount > 0 &&
                    <div><span className="chosen-course-label">{t("SCHEDULED_PAYMENT")}</span>
                        £{scheduledPaymentAmount.toFixed(2)}</div>
                }
                {flexiPayChecked && flexiPayFee && !rebooking &&
                    <div><span className="chosen-course-label">{t("FLEXI_PAY_CONFIRMATION")}</span>
                        £{flexiPayFee.toFixed(2)}</div>
                }
            </>
        );
    };

    const getVenueNoteForLanguage = () => {
        if (!venue || !eventInstance) {
            return null;
        }

        const { noteEn, noteCy } = venue;
        const note = eventInstance.language === LanguageEnum.English ? noteEn : noteCy || noteEn;

        return !note
            ? null
            : <div className="venue-notes">
                <h4 className="underline">{t("VENUE_NOTES")}</h4>
                {note}<br />
            </div>;
    };

    const getEventInstanceNoteForLanguage = () => {
        if (!venue || !eventInstance) {
            return null;
        }

        const { bookingNoteCy, bookingNoteEn } = eventInstance;
        const note = eventInstance.language === LanguageEnum.English ? bookingNoteEn : bookingNoteCy || bookingNoteEn;

        return !note
            ? null
            : <div className="event-instance-notes">
                {note}<br />
            </div>;
    };

    const isClassroom = venue && venue.deliveryType !== DeliveryTypeEnum.Digital;
    const isDigital = venue && venue.deliveryType === DeliveryTypeEnum.Digital;

    return (
        <Segment className="chosen-course">
            <h4 className="underline">{t("YOUR_CHOSEN_COURSE")}</h4>
            {venue && eventInstance &&
                <>
                    <p className="bold bigger">
                        <Trans
                            i18nKey="ChosenCourse:LANGUAGE_DEPENDENT_FIELD"
                            values={{ english: eventInstance.eventTypeName, welsh: eventInstance.eventTypeNameCy ?? eventInstance.eventTypeName }}>
                            {eventInstance.eventTypeName}
                        </Trans>
                    </p>
                    <div className="venue-details">
                        {(!isAttendee || isClassroom) &&
                            <p className="venue-name"><span className="chosen-course-label">{t("VENUE_HEADER")}</span> {venue.name}</p>
                        }
                        {isClassroom &&
                            <p className="venue-address"><DisplayAddress address={venue.address} /></p>
                        }
                        {isDigital &&
                            <p>{t("DELIVERED_USING_ZOOM")}</p>
                        }
                    </div>
                    <p>
                        <span className="chosen-course-label">{t("DATE_HEADER")}</span>
                        {courseDate}, <span className="no-break">{startTime} - {endTime}</span></p>
                    <p className="course-language">
                        <span className="chosen-course-label">{t("COURSE_LANGUAGE")}</span> {t(`Languages.${eventInstance.language}`)}
                        <WelshLanguageIndicator language={eventInstance.language} />
                    </p>

                    {currentView === View.BookingDetails && displayPaymentInformation()}

                    {currentView === View.PaymentReview && stripePaymentInformation()}

                    {(currentView === View.BookingConfirmation
                        || currentView === View.Dashboard
                        || currentView === View.OutstandingBalancePaymentConfirmation)
                        && bookingConfirmationScreenInformation()}

                    {getVenueNoteForLanguage()}
                    {getEventInstanceNoteForLanguage()}
                    {fullDetails &&
                        <div className="buttons">
                            {links && showLinks && (
                                <Button className="continue-button" as="a" href={links.termsAndConditionsLink} target="_blank">
                                    {t("TERMS_BUTTON")}
                                </Button>
                            )}
                        </div>
                    }
                </>
            }
        </Segment>
    );
};
