import { createValidator } from "not-valid";
import moment from "moment";

export const pastDateValidator = createValidator<moment.Moment>(
    v => v && v.isValid() ? moment().isAfter(v) : true,
    "Please enter a date before today");

export const muiPastDateValidator = (value: moment.Moment) => {
    return (value && !value.isBefore(moment(), "day"))
        ? "Please enter a date before today"
        : undefined;
};

export const presentOrPastDateValidator = createValidator<moment.Moment>(
    v => v && v.isValid() ? moment().isSameOrAfter(v) : true,
    "Date cannot be in the future");

export const muiPresentOrPastDateValidator = (value: moment.Moment) => {
    return (value && !value.isSameOrBefore(moment(), "day"))
        ? "Date cannot be in the future"
        : undefined;
};
