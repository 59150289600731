export enum RecipientTypeEnum {
    None = 0,
    Organisations = 1,
    Attendees = 2,
    Trainers = 3,
    Venues = 4,
    IQA = 5,
    CA = 6,
    Managers = 7
}

export const CorporateRecipientType = {
    [RecipientTypeEnum.Organisations]: "Organisations",
    [RecipientTypeEnum.Attendees]: "Delegates",
    [RecipientTypeEnum.Trainers]: "Trainers",
    [RecipientTypeEnum.Venues]: "Venues",
    [RecipientTypeEnum.Managers]: "Managers"
};

export const ConstructionRecipientType = {
    [RecipientTypeEnum.Organisations]: "Organisations",
    [RecipientTypeEnum.Attendees]: "Delegates",
    [RecipientTypeEnum.Trainers]: "Trainers",
    [RecipientTypeEnum.Venues]: "Venues",
    [RecipientTypeEnum.Managers]: "Managers"
};

export const DdrsRecipientType = {
    [RecipientTypeEnum.Attendees]: "Attendees"
};

export const DorsRecipientType = {
    [RecipientTypeEnum.Organisations]: "Police Forces",
    [RecipientTypeEnum.Attendees]: "Attendees",
    [RecipientTypeEnum.Trainers]: "Trainers",
    [RecipientTypeEnum.Venues]: "Venues",
    [RecipientTypeEnum.IQA]: "Internal Quality Assurance",
    [RecipientTypeEnum.CA]: "Client Advisor"
};
