import * as React from "react";

import { EmailerSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const Emailer: ConfigurationAreaComponent<EmailerSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Emailer"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ApplicationId", label: "Application Id" },
                { name: "AuthKey", label: "Authentication Key" },
                { name: "BaseUri", label: "Base URI" },
                { name: "PostmarkApiUrl", label: "Postmark Api Url" },
                { name: "PostmarkApiKey", label: "Postmark Api Key" },
                { name: "RichTextEditorApiKey", label: "Rich Text Editor Api Key" },
            ]}
        />
    );
};
