import * as React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "moment-duration-format";
import { Trans } from "react-i18next";
import { Link, push } from "redux-little-router";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { loadEventInstanceDetailsForBooking, loadPoliceOrganisationDetailsForBooking } from "../../eventInstance/actions";
import { bookingBasePathSelector, bookingIdSelector } from "../../landing/selectors";
import { ApplicationState } from "../../applicationState";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { loadBooking } from "@booking/landing";
import { addNoteToCorrelationId } from "@common/notes/actions";
import { v4 } from "uuid";
import { useScrollToTop } from "@common/hooks/useScrollToTop";
import classes from "./BookingConfirmation.module.scss";
import { CourseCoreDetails } from "./CourseCoreDetails";
import { AttendeeDetails } from "./AttendeeDetails";
import { useTypeSafeTranslation } from "@booking/i18n/resources/useTypeSafeTranslation";
import { Button } from "@booking/common/Button";
import { BookingActions } from "./BookingActions";
import { getExternalLinksForGivenLanguage } from "./TermsAndFaqLinks";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { FinanceDetails } from "./FinanceDetails";
import { useBookingStatus } from "../hooks/useBookingStatus";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { NewNote } from "@common/notes/components/NewNote";

export function BookingConfirmation() {
    const [isBookingLoading, setIsBookingLoading] = React.useState<boolean>(true);
    const { eventInstances, booking, organisations } = useSelector((state: ApplicationState) => state);
    const organisation = booking?.forceId ? organisations.find(e => e.dorsId === booking.forceId) : undefined;
    const eventInstance = eventInstances.find(e => e.id === booking.reservedEventInstanceId);
    const eventTypeName = (eventInstance) ? eventInstance.eventTypeName : null;
    const dispatch = useDispatch();
    const t = useTypeSafeTranslation("BookingConfirmation");
    const links = eventInstance && getExternalLinksForGivenLanguage(eventInstance, organisation);
    const bookingId = useSelector((state: ApplicationState) => bookingIdSelector(state));
    const path = useSelector(bookingBasePathSelector);
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    useScrollToTop();

    useEffect(() => {
        if (!organisation && booking?.forceId) {
            dispatch(loadPoliceOrganisationDetailsForBooking({ forceId: booking.forceId }));
        }

    }, [booking.forceId, dispatch, organisation]);

    useEffect(() => {
        async function getBooking() {
            await dispatch(loadBooking());
            setIsBookingLoading(false);
        }

        getBooking();
    }, [dispatch]);

    useEffect(() => {
        if (!eventInstance && booking.reservedEventInstanceId) {
            dispatch(loadEventInstanceDetailsForBooking({ eventInstanceId: booking.reservedEventInstanceId }));
        }

    }, [booking.reservedEventInstanceId, dispatch, eventInstance]);

    if (isBookingLoading === false && booking && booking.seatBooked === false) {
        dispatch(addNoteToCorrelationId(bookingId, v4(), "Confirmation page accessed on unbooked attendee", TaskRelationEnum.None, null));
        dispatch(push(path));
    }

    const showYourCourseSection = eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital && !isClientAdvisor;

    return (
        <div className={classes.confirmationContainer}>
            <h1 className={classes.header}>
                {t("CONFIRMATION")}
            </h1>
            <div className={classes.divider} />
            <div>{isClientAdvisor? <ClientAdvisorConfirmationHeader />: <AttendeeConfirmationHeader />}</div>
            <EventTypeWelcome eventTypeName={eventTypeName} eventTypeNameCy={eventInstance?.eventTypeNameCy} />
            <div className={classes.twoSectionLayout}>
                <div className={classes.orderSummary}>
                    <div className={classes.gridHeaderSection}>
                        <h3>{t("ORDER_SUMMARY")}</h3>
                    </div>
                    <div className={classes.detailsGrid}>
                        <div className={classes.eventTypeTitle}>
                            {t("COURSE_DETAILS_TITLE")}
                        </div>
                        <div className={classes.horizontalGridDivider} />
                        <CourseCoreDetails eventInstance={eventInstance} showLocation />
                        <div className={classes.horizontalGridDivider} />
                        <AttendeeDetails booking={booking} twoColumnLayout={showYourCourseSection} />
                        {isClientAdvisor && <Authorize authorize={TtcClientAdvisorRole}>
                            <div className={classes.horizontalGridDivider} />
                            <NewNote correlationId={booking.id} relatedTo={TaskRelationEnum.Attendee} showConfirmationToast />
                        </Authorize>}
                        <div className={classes.horizontalGridDivider} />
                        <div className={classes.financeDetailsContainer}>
                            <FinanceDetails />
                        </div>
                    </div>
                </div>
                {showYourCourseSection &&
                <div className={classes.yourCourse}>
                    <div className={classes.gridHeaderSection}>
                        <h3>{t("YOUR_COURSE")}</h3>
                    </div>
                    <div className={classes.detailsGrid}>
                        <p><Trans i18nKey="BookingConfirmation:RECOMMEND_ASSISTANCE" /></p>
                        <Button variant={"primary"}>
                            <a href="https://zoom.us/test" target="_blank" rel="noreferrer">
                                {t("TEST_MEETING")}
                            </a>
                        </Button>
                        <p><Trans i18nKey="BookingConfirmation:ZOOM_ASSISTANCE" >
                            {/* eslint-disable-next-line max-len*/}
                                If you experience difficulties when completing your test Zoom meeting then please email us at <a href={"mailto:onlinecoursesupport@ttc-uk.com"}>onlinecoursesupport@ttc-uk.com</a>. Please include your <strong>reference number</strong>, <strong>name</strong> and <strong>contact number</strong> in order for our online support team to get back in touch.
                        </Trans></p>
                    </div>
                </div>}
            </div>
            <div className={classes.bookingActionsContainer}>
                <BookingActions booking={booking} termsAndConditionsLink={links?.termsAndConditionsLink} />
            </div>
        </div>);
}

function EventTypeWelcome({ eventTypeName, eventTypeNameCy }: {eventTypeName: string; eventTypeNameCy: string}) {
    return (<p className={classes.eventTypeWelcome}>
        {eventTypeName && (
            <Trans i18nKey="BookingConfirmation:WELCOME"
                values={{
                    eventTypeName,
                    eventTypeNameCy: eventTypeNameCy ?? eventTypeName
                }}>
            Below are the details of your <strong>{{ eventTypeName }}</strong> course booking.<br />
            If you supplied an email address we will send you confirmation of your booking.
            </Trans>)}
    </p>);
}

function ClientAdvisorConfirmationHeader() {
    const t = useTypeSafeTranslation("BookingConfirmation");
    const booking = useSelector((state: ApplicationState) => {
        return state.booking;
    });
    const { isRebooking } = useBookingStatus(booking.id);
    const path = useSelector(bookingBasePathSelector);
    const attendeeName = `${booking.forename} ${booking.surname}`;

    if (isRebooking) {
        return (
            <>
                <p>
                    <span>{t("NEW_BOOKING_REFERENCE")}</span>
                    <br />
                    <span className={classes.detailsFieldName}>
                        {t("BOOKING_REFERENCE")}:&nbsp;
                    </span><strong>{booking.attendanceId}</strong>
                </p>
            </>);
    }
    return (<>
        <h3>
            You have booked <Link href={`${path}/ca-redirect`}>{attendeeName}</Link>
        </h3>
        <div><span className={classes.detailsFieldName}>{t("BOOKING_REFERENCE")}:</span> {booking.attendanceId}</div>
    </>);
}

function AttendeeConfirmationHeader() {
    const booking = useSelector((state: ApplicationState) => {
        return state.booking;
    });
    const t = useTypeSafeTranslation("BookingConfirmation");
    const { isRebooking } = useBookingStatus(booking.id);

    if (isRebooking) {
        return (
            <>
                <h3>{t("CHANGE_BOOKING")}</h3>
                <p>
                    <span>{t("NEW_BOOKING_REFERENCE")}</span>
                    <br />
                    <span className={classes.detailsFieldName}>
                        {t("BOOKING_REFERENCE")}:&nbsp;
                    </span><strong>{booking.attendanceId}</strong>
                </p>
            </>);
    }

    return (
        <>
            <h3>{t("BOOKING_CONFIRMED")}</h3>
            <div><span className={classes.detailsFieldName}>{t("BOOKING_REFERENCE")}:</span> {booking.attendanceId}</div>
        </>);
}
