import * as React from "react";
import moment from "moment";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { selectedEventInstanceListPath } from "../../selectors";
import { Area, ExtendedEventInstanceGroup } from "../../model";
import { EventInstanceListModel } from "../../../eventInstance";
import { WelshLanguageIndicator } from "../WelshLanguageIndicator";
import { MonitorIcon } from "@common/crud/common/MonitorIcon";
import { ObserverIcon } from "@common/crud/common/ObserverIcon";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { errorMessageDisplay } from "@common/crud/eventInstance/components/ErrorMessageDisplay";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

interface AllDrinkDriveItemsProps {
    area: Area;
    groupedEventInstances: EventInstanceGroupModel[];
    eventInstances: EventInstanceListModel[];
    selectedEventInstanceGroups?: string[];
    onEventInstanceGroupSelect?: (eventInstanceGroupId: string) => void;
    selectAll?: () => void;
}

export const AllDrinkDriveItems: React.FC<AllDrinkDriveItemsProps> = ({
    groupedEventInstances, eventInstances, onEventInstanceGroupSelect, area, selectedEventInstanceGroups, selectAll
}) => {
    const path = useSelector(selectedEventInstanceListPath(area, WorkflowTypeEnum.DDRS));
    const [extendedGroupedEventInstances, setExtendedGroupedEventInstances] = React.useState<ExtendedEventInstanceGroup[]>([]);

    React.useEffect(() => {

        function buildGroupedEventInstance(e: EventInstanceGroupModel, eventInstance: EventInstanceListModel) {
            return ({
                path,
                startDate: eventInstance?.startDate,
                publishDate: eventInstance?.publishDate,
                reasonForHidingEvent: eventInstance?.reasonForHidingEvent,
                cancelled: eventInstance?.cancelled,
                onChange: () => onEventInstanceGroupSelect(e.id),
                isSelected: selectedEventInstanceGroups?.includes(e?.id) ?? false
            });
        }

        if (groupedEventInstances?.length > 0 && eventInstances?.length > 0) {
            setExtendedGroupedEventInstances(groupedEventInstances.map<ExtendedEventInstanceGroup>(e => {
                const eventInstanceItemIds = e.eventInstanceGroupItems?.map(g => g.eventInstanceId) ?? [];
                const linkedEventInstances = eventInstances
                    .filter(ei => eventInstanceItemIds.includes(ei.id))
                    .sort((a, b) => a.deliveryDateTime.diff(b.deliveryDateTime));
                const firstEventInstance = linkedEventInstances?.length > 0 && linkedEventInstances[0];
                const extendedProps = buildGroupedEventInstance(e, firstEventInstance);

                return ({ ...e, ...extendedProps });
            }));
        }
        else {
            setExtendedGroupedEventInstances([]);
        }
    }, [eventInstances, groupedEventInstances, onEventInstanceGroupSelect, path, selectedEventInstanceGroups]);

    const adminView = area === Area.AdminEventManagementEventInstance ||
        area === Area.AdminEventManagementCalendar || area === Area.AdminEventManagementTrainerEventInstance;
    const isChecked = groupedEventInstances?.length === selectedEventInstanceGroups?.length;

    const selectColumn = {
        key: "tick-all",
        functionalHeader: () => <Checkbox onClick={selectAll} checked={isChecked} label="Tick All" />,
        value: (e: ExtendedEventInstanceGroup) => {
            return (
                <Authorize authorize={TtcPlannerRole}>
                    <Checkbox
                        checked={e.isSelected}
                        onChange={e.onChange}
                    />
                    {
                        e && (e.errorMessageDuringPublish) &&
                        <Popup
                            content={errorMessageDisplay(e)}
                            trigger={<Icon name={"exclamation triangle"} />}
                        />
                    }
                </Authorize>
            );
        }
    };

    const selectAllAdminColumn = area !== Area.AdminEventManagementCalendar ? [selectColumn] : [];
    const getColumns = () => {
        if (adminView) {
            return [
                ...commonColumns(false, eventInstances),
                ...adminColumns,
                ...selectAllAdminColumn
            ];
        }

        return [
            ...commonColumns(false, eventInstances)
        ];
    };

    return (
        <TypedTable
            values={extendedGroupedEventInstances}
            tableClassName="event-instance-table"
            emptyValuesArrayMessage="No courses on this date, range of dates or selection criteria"
        >
            {getColumns()}
        </TypedTable>
    );
};

const sumProperty = (key: any, values: EventInstanceGroupModel[]) => values.reduce((a, b) => a + b.eventInstanceGroupItems[0][key], 0);

const sumTrainersProperty = (key: any, values: EventInstanceGroupModel[]) => values.reduce((a, b) => a + b[key], 0);

const commonColumns = (isOnAvailableCoursesView: boolean, eventInstances: EventInstanceListModel[]): TypedTableRowProps<ExtendedEventInstanceGroup>[] => {
    return [
        {
            header: "Venue",
            value: (e) => {
                return (
                    <>
                        {isOnAvailableCoursesView && e.venueName}
                        {!isOnAvailableCoursesView && <Link className="event-name-link" href={`${e.path}/${e.eventInstanceGroupItems[0].eventInstanceId}`}>
                            {e.venueName} {e.hasAttendeesWithSpecialRequirements && <Icon name="speakap" />}
                            <WelshLanguageIndicator language={e.language} />
                        </Link>}</>
                );
            }
        },
        {
            header: "Scheme",
            value: (e) => eventInstances.find(ei => e.eventInstanceGroupItems.map(gi => gi.eventInstanceId).includes(ei.id))?.eventTypeAbbreviation,
            showTotal: true,
            getTotalValue: (events: ExtendedEventInstanceGroup[]) => events.length
        },
        {
            header: "Date",
            value: (e) => {
                return (
                    <div>
                        {e.eventInstanceGroupItems.map((gi, index) =>
                            <div key={index}>{getDisplayDay(gi).format("ddd DD/MM/YYYY HH:mm")}</div>
                        )}
                    </div>
                );
            }
        }
    ];
};

function getDisplayDay(groupItem: EventInstanceGroupItemModel): moment.Moment {
    const firstStartDate = groupItem.startDate.startOf("day").clone();
    return firstStartDate.add(groupItem.startTime);
}

const adminColumns: TypedTableRowProps<ExtendedEventInstanceGroup>[] = [
    {
        header: "Status",
        value: (e) => {
            const isPublished = !!e.publishDate;
            const isVisible = !e.reasonForHidingEvent;
            return e.cancelled ? "Cancelled" : isPublished && isVisible ? "Published" : !isPublished ? "Not Published" : "Hidden";
        }
    },
    {
        header: "Trainers",
        value: (e) => {

            const dayOneId = e.eventInstanceGroupItems?.find(gi => gi.dayNumber === 1)?.eventInstanceId;

            return (<>
                {!e.trainersNames && <Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                    {e.numberOfTheoryTrainers} </Link>}
                {(e.trainersNames) && <Popup
                    content={e.trainersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                        {e.numberOfTheoryTrainers} </Link>}
                    position='left center'
                />}
                {e.monitorsNames && <Popup
                    content={e.monitorsNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                        <MonitorIcon /> </Link>}
                    position='left center'
                />}
                {e.observersNames && <Popup
                    content={e.observersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                        <ObserverIcon /></Link>}
                    position='left center'
                />}
            </>);},
        showTotal: true,
        getTotalValue: (events: ExtendedEventInstanceGroup[]) => sumTrainersProperty("numberOfTrainers", events)
    },
    {
        header: "Booked",
        value: (e) => {
            return (
                <Link href={`${e.path}/${e.eventInstanceGroupItems[0].eventInstanceId}/attendees`}>
                    {`${e.seatCount}/${e.openPlacesCount} `} {e.hasVariableOccupancyCounts && <Icon name="warning" />}
                </Link>);
        },
        showTotal: true,
        getTotalValue: (events: ExtendedEventInstanceGroup[]) => `${sumProperty("seatCount", events)}/${sumProperty("openPlacesCount", events)}`
    }
];
