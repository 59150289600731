import * as React from "react";
import { Modal } from "@common/components";
import { Header, Button } from "semantic-ui-react";
import { selectedEventInstancesSelector } from "../../selectors";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { useSelector, useDispatch } from "react-redux";
import { EventInstanceListModel } from "../..";
import { Language, LanguageEnum } from "../../model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export interface BulkEmailConfirmationModalProps {
    open: boolean;
    eventInstanceIds?: string[];
    templateName?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const BulkEmailConfirmationModal: React.FC<BulkEmailConfirmationModalProps> = ({
    open,
    templateName,
    eventInstanceIds,
    onCancel,
    onConfirm
}) => {
    const instances = useSelector(selectedEventInstancesSelector(eventInstanceIds));
    const dispatch = useDispatch();

    const onAccept = React.useCallback(() => {
        dispatch(onConfirm);
    }, [onConfirm, dispatch]);

    return (
        <Modal open={open}>
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Content>
                <Header size="small">{`You will send ‘${templateName}’ to attendees on the following courses`}</Header>
                <TypedTable
                    values={instances}
                    emptyValuesArrayMessage="No courses selected"
                >
                    {commonColumns}
                </TypedTable>
            </Modal.Content>
            <Modal.Actions>
                <Button className="cancel-action" onClick={onCancel}>Cancel</Button>
                <Button primary onClick={onAccept}>Confirm</Button>
            </Modal.Actions>
        </Modal>
    );
};

const commonColumns: TypedTableRowProps<EventInstanceListModel>[] = [
    {
        header: "Course date",
        value: (e) => e?.startDate?.format(DateFormat.Short)
    },
    {
        header: "Number of attendees",
        value: (e) => e.seatCount
    },
    {
        header: "Language",
        value: (e) => Language[e?.language ?? LanguageEnum.Any]
    },
];
