import * as React from "react";
import { Modal } from "@common/components";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { EditForm, EditProps, DispatchProps } from "./Edit";

// Using {} is OK for React type intersections.
// eslint-disable-next-line @typescript-eslint/ban-types
export class EditModal extends React.Component<EditProps & DispatchProps, {}> {

    private editForm: EditForm;

    private Spinner(isSaving: boolean) {
        return (<>
            <Modal.Header>Saving, please wait...</Modal.Header>
            <Modal.Content>
                <Dimmer active={isSaving} >
                    <Loader active={isSaving} />
                </Dimmer>
            </Modal.Content></>
        );
    }

    private EditableContent() {
        return (<>
            <Modal.Header>Edit Course</Modal.Header>
            <Modal.Content>
                <EditForm
                    ref={this.setRef}
                    {...this.props}
                />
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={this.props.close} className="cancel-action">Cancel</Button>
                <Button onClick={this.submit}>Save</Button>
            </Modal.Actions>
        </>
        );
    }

    public render() {
        const { isSaving } = this.props;
        const content = isSaving ? this.Spinner(isSaving) : this.EditableContent();

        return (<Modal size="small" open={this.props.open} onClose={this.props.close}>
            {content}
        </Modal>);
    }

    private setRef = (instance: EditForm) => this.editForm = instance;

    private submit = () => {
        this.editForm.submit();
    };
}
