import * as React from "react";
import { AttendeeListModel, CompletionState } from "@common/crud/attendee";
import { CheckboxProps, Form, Popup, Grid, Icon, Button } from "semantic-ui-react";
import { CurrentUser, PoliceRole, TrainerRole } from "@common/auth/model";
import { CurrentUserContext } from "@common/current-user-context";
import { SpecialRequirementsDisplay } from "@common/specialRequirements/SpecialRequirementsDisplay";
import { AttendeeCarDetails } from "@common/crud/attendee/components/details/AttendeeCarDetails";
import { EventInstanceApi } from "../..";
import { Media } from "@common/global/AppMedia";
import { ReasonIssue, ReasonIssueEnum, RegisterAttendeeModel } from "@common/crud/attendee/model";
import { useRegisterContext } from "./register-context";
import { Authorize } from "reauthorize";
import { RaiseIssueModal } from "./RaiseIssue";
import { RegisterAttendee } from "./RegisterAttendee";

interface CompletionRegisterRowProps {
    attendee: AttendeeListModel;
    registerAttendee: RegisterAttendeeModel;
    showError: boolean;
    resubmissionRequired: boolean;
    forcedEventInstanceProcessing?: boolean;
    setResubmissionRequired: () => void;
    onAttendeeRegisterUpdated: (registerAttendee: RegisterAttendeeModel, forceImmediateSave: boolean) => void;
}

const CompletionRegisterRow: React.FC<CompletionRegisterRowProps> = (props) => {

    const { eventInstanceOptions } = useRegisterContext();
    const { hasBeenSubmitted, isDigitalEventInstance, registerClosed } = eventInstanceOptions;

    const { attendee, showError, registerAttendee, onAttendeeRegisterUpdated, setResubmissionRequired, resubmissionRequired,
        forcedEventInstanceProcessing } = props;

    const name = `${attendee.id}_completed`;

    function onChange(e: any, data: CheckboxProps) {
        const completed: CompletionState = +data.value;
        onAttendeeRegisterUpdated({
            ...registerAttendee,
            completed,
            reasonIssue: completed === CompletionState.NotCompleted
                ? !registerAttendee.reasonIssue
                    ? ReasonIssueEnum.MarkedAsNotCompleted
                    : registerAttendee.reasonIssue
                : registerAttendee.reasonIssue === ReasonIssueEnum.MarkedAsNotCompleted
                    ? ReasonIssueEnum.None
                    : registerAttendee.reasonIssue
        }, !updating);
    }

    function onRaiseIssueChange(registerAttendeeModel: RegisterAttendeeModel) {
        onAttendeeRegisterUpdated({
            ...registerAttendeeModel
        }, !updating);
    }

    const currentUser: CurrentUser = React.useContext(CurrentUserContext);
    const [updating, setUpdating] = React.useState(false);
    const disableRow = hasBeenSubmitted && !updating;
    const canComplete = (attendee.didAttend === false || disableRow) || currentUser?.roles.includes(PoliceRole);

    React.useEffect(() => {
        if (!resubmissionRequired) {
            setUpdating(false);
        }
    }, [resubmissionRequired]);

    async function onUpdate() {
        const api = new EventInstanceApi();
        await api.setUpdating(attendee.eventInstanceId, true);
        setUpdating(true);
        setResubmissionRequired();
    };

    const renderUpdateButtonOrText = updating
        ? <p className="red-warning-text">Please resubmit</p>
        : forcedEventInstanceProcessing
            ? <p className="red-warning-text">Forced processing in progress</p>
            : (
                <Button as={"a"} disabled={attendee.isBookingCanceled  || registerClosed} className={"issue-button float-right"} onClick={onUpdate}>
                    <Icon name="angle left" />Update
                </Button>
            );

    const hasIssues = showError &&
        (registerAttendee.completed === CompletionState.NotCompleted || registerAttendee.didAttend === false) &&
        registerAttendee.reasonIssue === ReasonIssueEnum.None;

    // Since register filter cancelled attendees out, get the data from attendee if cancelled
    const reasonIssue = attendee.isBookingCanceled ? attendee.reasonIssue : registerAttendee.reasonIssue;
    const issueText = attendee.isBookingCanceled ? attendee.issue : registerAttendee.issue;

    const raiseIssueModal = (
        <>
            <Authorize authorize={[TrainerRole]}>
                <Grid.Row>
                    <RaiseIssueModal
                        hasIssues={hasIssues}
                        attendee={attendee}
                        registerAttendee={registerAttendee}
                        disabled={disableRow}
                        onAttendeeRegisterUpdated={onRaiseIssueChange}
                    />
                </Grid.Row>
            </Authorize>
            {reasonIssue !== ReasonIssueEnum.None &&
                <Grid.Row className="no-padding">
                    <Grid.Column width="16">
                        <span className="raised-issue-detail"><b>Reason:</b> {ReasonIssue[reasonIssue]}</span>
                    </Grid.Column>
                </Grid.Row>
            }
            {issueText &&
                <Grid.Row className="no-padding">
                    <Grid.Column width="16">
                        <span className="raised-issue-detail">{issueText}</span>
                    </Grid.Column>
                </Grid.Row>
            }
        </>
    );

    // Since register filter cancelled attendees out, get the data from attendee if cancelled
    const completionState = attendee.didAttend
        ? attendee.isBookingCanceled ? attendee.completed : registerAttendee?.completed
        : CompletionState.NotCompleted;

    return (
        <>
            <Grid.Row className={attendee.isBookingCanceled ? "light-grey" : ""}>
                <RegisterAttendee blueName attendee={attendee} raiseIssueModal={raiseIssueModal} />
                <Grid.Column computer={hasBeenSubmitted ? 3 : 5} tablet={5} mobile={5}>
                    <Popup
                        trigger={
                            <Form.Group className={`completed-radio reg-radio ${showError && completionState === CompletionState.Unknown ? "error" : ""}`}>
                                <Form.Radio
                                    label="Yes"
                                    name={name}
                                    value={CompletionState.Completed}
                                    checked={completionState === CompletionState.Completed}
                                    disabled={canComplete}
                                    onChange={onChange}
                                />
                                <Form.Radio
                                    label="No"
                                    name={name}
                                    value={CompletionState.NotCompleted}
                                    checked={completionState === CompletionState.NotCompleted}
                                    disabled={canComplete}
                                    onChange={onChange}
                                />
                            </Form.Group>}
                        content={"Can't change completion state, attendee didn't attend the course"}
                        on="hover"
                        hoverable={true}
                        disabled={attendee.didAttend}
                        position="top left"
                    />
                    {hasBeenSubmitted &&
                        <Media lessThan="computer">
                            <div>
                                {renderUpdateButtonOrText}
                            </div>
                        </Media>
                    }
                </Grid.Column>

                {hasBeenSubmitted &&
                    <Grid.Column width={2} only="computer" textAlign="right">
                        {renderUpdateButtonOrText}
                    </Grid.Column>
                }
            </Grid.Row>
            <Grid.Row className="no-padding">
                <Grid.Column width="16" className="attendee-requirements">
                    <SpecialRequirementsDisplay
                        isDigitalCourse={isDigitalEventInstance}
                        specialRequirements={attendee.specialRequirements || {} as any}
                        showMessageIfNoSpecialRequirements={false}
                    />
                    <AttendeeCarDetails attendee={attendee} />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

export { CompletionRegisterRow };
