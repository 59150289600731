import React from "react";
import OtpInput from "react-otp-input";

export const BaseOneTimePassword = ({ value, onChange, classes }: {value: string; onChange: (otp: string) => void; classes: {otpInput: string}}) => {
    return (<OtpInput
        value={value}
        onChange={onChange}
        inputType="tel"
        inputStyle={classes.otpInput}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
    />);
};
