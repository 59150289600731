import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { getCurrencyFormat } from "@common/formating/CurrencyFormatting";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { muiLessThanAYearAgoOrFutureValidator } from "@common/validation/lessThanAYearAgoOrFutureValidator";
import { DebouncedFunc } from "lodash";
import moment from "moment";
import * as React from "react";
import { Button, Table } from "semantic-ui-react";
import { OtherTrainerFee, OtherTrainerFeeBase } from "@common/crud/eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { useSelector } from "react-redux";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

interface OtherTrainerPayTableProps {
    otherTrainerFees: OtherTrainerFee[];
    newOtherTrainerFee?: OtherTrainerFee;
    setNewOtherTrainerFee?: React.Dispatch<React.SetStateAction<OtherTrainerFee>>;
    onAddRowClick?: () => void;
    editable?: boolean;
    editing?: boolean;
    push?: (path: string) => void;
    formValidationObject?: {
        [key: string]: boolean;
    };
    setFormValidationObject?: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>;
    displayEffectiveDateWarning?: DebouncedFunc<(date: string) => void>;
}

export const OtherTrainerPayTable: React.FC<OtherTrainerPayTableProps> =
({ otherTrainerFees,  newOtherTrainerFee,  setNewOtherTrainerFee, editable,
    onAddRowClick, editing, formValidationObject, setFormValidationObject, displayEffectiveDateWarning }) => {

    const businessLineType = useSelector(businessLineTypeSelector);
    const isPoliceAndCourt = React.useMemo(() => businessLineType === BusinessLineType.PoliceAndCourt, [businessLineType]);

    function onEffectiveDateChange(value: moment.Moment, valid: boolean) {

        const firstOfMonth = value?.clone().utc().startOf("month");
        const dateIsAlreadyUsed = value ? otherTrainerFees.some(fee => fee.effectiveDate?.isSame(value, "month")) : false;

        if (dateIsAlreadyUsed) {
            displayEffectiveDateWarning(value.format(DateFormat.DayMonthYear));
        }

        setFormValidationObject({ ...formValidationObject, effectiveDate: !dateIsAlreadyUsed && valid });
        setNewOtherTrainerFee({ ...newOtherTrainerFee, effectiveDate: firstOfMonth });
    }

    function onBaseFeeValueChange(baseFeePart: keyof OtherTrainerFeeBase, value: number) {

        setNewOtherTrainerFee({ ...newOtherTrainerFee, [baseFeePart]: value });
    }

    const baseNumFieldViewType =(otherTrainerFee: OtherTrainerFee, baseFeePart: keyof OtherTrainerFeeBase, isEditable: boolean) => {

        function onNumberChange(value: number, valid: boolean) {
            setFormValidationObject({ ...formValidationObject, [baseFeePart]: valid });
            return onBaseFeeValueChange(baseFeePart, value);
        }

        const value = otherTrainerFee[baseFeePart];

        return isEditable?
            <div className={"ui mini small-input"}><CurrencyInput
                value={value}
                onChange={onNumberChange}
                fitOnModal
            /></div> : getCurrencyFormat(value);
    };

    const bonusNumFieldViewType =(otherTrainerFee: OtherTrainerFee, isEditable: boolean, tierIndex: number) => {

        function onNumberChange(value: number, valid: boolean) {
            setFormValidationObject({ ...formValidationObject, [tierIndex]: valid });
            return onBonusTierFeeValueChange(otherTrainerFee, tierIndex, value);
        }

        const value = otherTrainerFee?.tieredBonuses[tierIndex]?.amount;

        return isEditable?
            <div className={"ui mini small-input"}><CurrencyInput
                value={value}
                onChange={onNumberChange}
                fitOnModal
            /></div> : getCurrencyFormat(value);
    };

    function onBonusTierFeeValueChange(otherTrainerFee: OtherTrainerFee, tierIndex: number, value: number) {
        const newTieredBonuses = otherTrainerFee.tieredBonuses.map((tier, index) => {
            return tierIndex === index?   { ...tier, amount: value }: tier;
        });
        setNewOtherTrainerFee({ ...newOtherTrainerFee, tieredBonuses: newTieredBonuses });
    }

    const TrainerPayBaseRow =(otherTrainerFee: OtherTrainerFee, isEditable: boolean) => {
        const dateView = isEditable?
            (<MuiDateField
                value={otherTrainerFee.effectiveDate}
                onChange={onEffectiveDateChange}
                validation={[muiLessThanAYearAgoOrFutureValidator]}
                required
            />):otherTrainerFee.effectiveDate?.format(DateFormat.DayMonthYear);

        return (<React.Fragment key={isEditable? "edit": otherTrainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            <Table.Cell>{dateView}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(otherTrainerFee, "mentee", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(otherTrainerFee, "observer", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(otherTrainerFee, "mentor", isEditable)}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(otherTrainerFee, "firstCourseSupport", isEditable)}</Table.Cell>
            {
                isPoliceAndCourt && otherTrainerFee.tieredBonuses.map((_,index) =>
                    <Table.Cell key={index}>{bonusNumFieldViewType(otherTrainerFee, isEditable, index)}</Table.Cell>
                )
            }
        </React.Fragment>);
    };

    const TrainerPayFullRow = (otherTrainerFee: OtherTrainerFee, isEditable = false) => (

        (<Table.Row key={isEditable?"edit":otherTrainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            {TrainerPayBaseRow(otherTrainerFee, isEditable)}
        </Table.Row>)
    );

    const TrainerPayHeader = () => {
        return (<>
            <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan='2'>{isPoliceAndCourt ? "Tier 1 Standard" : "Standard"}</Table.HeaderCell>
                <Table.HeaderCell colSpan='2'>Add on</Table.HeaderCell>
                {isPoliceAndCourt &&
                    <>
                        <Table.HeaderCell colSpan='1'>Tier 2</Table.HeaderCell>
                        <Table.HeaderCell colSpan='1'>Tier 3</Table.HeaderCell>
                    </>
                }
            </Table.Row>
            <Table.Row>
                <Table.HeaderCell>Date Effective (First of Month)</Table.HeaderCell>
                <Table.HeaderCell>Mentee</Table.HeaderCell>
                <Table.HeaderCell>Observer</Table.HeaderCell>
                <Table.HeaderCell>Mentor</Table.HeaderCell>
                <Table.HeaderCell>1st Course Support</Table.HeaderCell>
                {isPoliceAndCourt &&
                    <>
                        <Table.HeaderCell>Bonus Amount</Table.HeaderCell>
                        <Table.HeaderCell>Bonus Amount</Table.HeaderCell>
                    </>
                }
            </Table.Row>
        </>);
    };

    return (
        <>
            <Table celled structured>
                <Table.Header>
                    {TrainerPayHeader()}
                </Table.Header>
                <Table.Body>
                    {otherTrainerFees && otherTrainerFees.length > 0
                        ? otherTrainerFees.map(fee => TrainerPayFullRow(fee))
                        : !editing &&
                            <Table.Row>
                                <Table.Cell textAlign="center" colSpan={7}>No Fees Currently Saved</Table.Cell>
                            </Table.Row>
                    }
                    {editing &&  TrainerPayFullRow(newOtherTrainerFee, true)}
                </Table.Body>
            </Table>
            {editable && !editing &&
                 <>
                     <Button content='Add new row' icon='add circle' labelPosition='left' onClick={onAddRowClick} />
                     <br /><br />
                 </>}
        </>
    );};

