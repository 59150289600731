import * as React from "react";
import { PrintView } from "@common/print/PrintView";
import "../DdrsLetterPrintView.scss";
import { ddrsOffenderAddress } from "@common/crud/drinkDriveOffenders/helpers";
import { DrinkDriveOffenderDetailModel } from "@common/crud/drinkDriveOffenders/model";
import { Letter } from "./Letter";

export interface DdrsOffenderLetterPrintViewProps {
    drinkDriveOffender: DrinkDriveOffenderDetailModel;
    openingWording: string;
    closingWording?: string;
}

export const DdrsOffenderLetterPrintView: React.FC<DdrsOffenderLetterPrintViewProps> = (
    {
        drinkDriveOffender,
        openingWording,
        closingWording
    }) => {

    return (
        <PrintView>
            <Letter
                forename={drinkDriveOffender.forename}
                surname={drinkDriveOffender.surname}
                address={ddrsOffenderAddress(drinkDriveOffender)}
                letterOpening={openingWording}
                letterClosing={closingWording ?? ""} />
        </PrintView>
    );
};
