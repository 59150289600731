import * as React from "react";
import { Container, Modal, Button } from "semantic-ui-react";

export const SmsDetailsViewer: React.FC<{ smsBody: string; smsTemplateName: string }> = ({ smsBody, smsTemplateName }) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);

    const onShowDetailsClick = React.useCallback(() => {
        setShowDetails(!showDetails);
    }, [showDetails]);

    if (!smsBody) {
        return null;
    }

    return (
        <div>
            <a className="cursor-pointer" onClick={onShowDetailsClick}>Show Details</a>
            <Modal open={showDetails}>
                <Modal.Header>Sms details - {smsTemplateName}</Modal.Header>
                <Modal.Content>
                    <Container>
                        <div>
                            {smsBody}
                        </div>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onShowDetailsClick}>Close</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
