import { Location } from "redux-little-router";
import moment from "moment";

export interface SearchParameters {
    includeTotalResultCount?: boolean;
    facets?: string[];
    filter?: string;
    highlightFields?: string[];
    highlightPreTag?: string;
    highlightPostTag?: string;
    minimumCoverage?: number;
    orderBy?: string[];
    scoringParameters?: ScoringParameter[];
    scoringProfile?: string;
    searchFields?: string[];
    searchMode?: SearchMode;
    select?: string[];
    skip?: number;
    top?: number;
    queryType?: QueryType;
}

export enum SearchType {
    Dashboard,
    Delegate
}

export interface SearchFilterFields {
    eventTypeId: string;
    bookingStatus: BookingStatusEnum;
}

export type searchOptions = SearchFilterFields & { searchText: string };
export type corporateSearchOptions = CorporateSearchFilterFields & { searchText: string};
export type constructionSearchOptions = ConstructionSearchFilterFields & { searchText: string};

export interface CorporateSearchFilterFields {
    filter: CorporateOrConstructionOrganisationIndexFilterEnum;
    organisationId?: string;
}

export interface ConstructionSearchFilterFields {
    filter: CorporateOrConstructionOrganisationIndexFilterEnum;
    organisationId?: string;
}

export interface ScoringParameter {
    name: string;
    values: string[];
}

export enum BookingStatusEnum {
    None,
    Attendee,
    Offer,
    Cancelled
}

export enum CorporateOrConstructionOrganisationIndexFilterEnum {
    None,
    Organisation,
    Delegate
}

export const BookingStatus = {
    [BookingStatusEnum.None]: "None",
    [BookingStatusEnum.Attendee]: "Attendee",
    [BookingStatusEnum.Offer]: "Offer",
    [BookingStatusEnum.Cancelled]: "Cancelled"
};

export enum SearchMode {
    Any = 0,
    All = 1
}

export enum QueryType {
    Simple = 0,
    Full = 1
}

export interface SearchResultWrapper {
    readonly tooManyResults: boolean;
    readonly results: any;
}

export interface ConstructionSearchResultListModel {
    id: string;
    organisationId: string;
    organisationName: string;
    delegateOrganisationName: string;
    bookingReference: string;
    bookerOrganisationId: string;
    bookerOrganisationName: string;
    bookerId: string;
    bookerName: string;
    delegateName: string;
    eventInstanceId: string;
    attendeeName: string;
    partnerName: string;
    primaryContactName: string;
    primaryContactEmailAddress: string;
    primaryContactPhoneNumber: string;
    emailAddress: string;
    phoneNumber: string;
}

export interface CorporateSearchResultListModel {
    id: string;
    organisationId: string;
    organisationName: string;
    delegateOrganisationName: string;
    bookingReference: string;
    bookerOrganisationId: string;
    bookerOrganisationName: string;
    bookerId: string;
    bookerName: string;
    delegateName: string;
    eventInstanceId: string;
    attendeeName: string;
    partnerName: string;
    uin: string;
    driverNumber: string;
    primaryContactName: string;
    primaryContactEmailAddress: string;
    primaryContactPhoneNumber: string;
    emailAddress: string;
    phoneNumber: string;
}

export interface SearchResultListModel {
    readonly id: string;
    readonly correlationId?: string;
    readonly postcode?: string;
    readonly driverNumber?: string;
    readonly forename?: string;
    readonly surname?: string;
    readonly listUpdated?: moment.Moment;
    readonly eventInstanceId?: string;
    readonly eventType?: string;
    readonly isBookingCanceled?: boolean;
    readonly startDate?: moment.Moment;
    readonly attendanceId: string;
    readonly paymentId?: string;
    readonly ivrBookingUntil?: moment.Moment;
    readonly isTransferred?: boolean;
}

export interface SearchResultDetailModel {
    readonly id: string;
    readonly correlationId?: string;
    readonly attendanceId?: string;
    readonly ticketId?: string;
    readonly postcode?: string;
    readonly telephone?: string;
    readonly email?: string;
    readonly driverNumber?: string;
    readonly address1?: string;
    readonly address2?: string;
    readonly address3?: string;
    readonly town?: string;
    readonly forename?: string;
    readonly surname?: string;
    readonly eventInstanceId?: string;
    readonly isBookingCanceled?: boolean;
    readonly eventType?: string;
    readonly startDate?: moment.Moment;
    readonly paymentId?: string;
    readonly ivrBookingUntil?: moment.Moment;
}

export type ConstructionSearchResult = ConstructionSearchResultListModel;

export type CorporateSearchResult = CorporateSearchResultListModel;

export type SearchResult = SearchResultListModel & SearchResultDetailModel;

export interface SearchState {
    readonly searchResults: SearchResult[];
    readonly corporateSearchResults: CorporateSearchResult[];
    readonly constructionSearchResults: ConstructionSearchResult[];
}

export type AppState = SearchState & { router: Location };
