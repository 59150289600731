import * as React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";

interface Props {
    label: string;
    selectedValues: any[];
    value: any;
    isDisabled?: boolean;
    onValuesChanged: (update: <T>(oldValue: T[]) => T[]) => void;
}

export const MultiSelectCheckbox = ({ label, selectedValues, value, onValuesChanged, isDisabled = false }: Props) => {
    const onChange = React.useCallback((e: any, { checked }: CheckboxProps) => onValuesChanged(oldState => checked
        ? [...oldState.filter(v => v !== value), value]
        : oldState.filter(v => v !== value)), [value, onValuesChanged]);
    return (
        <div className="field-wrapper select-list-item">
            <Checkbox
                label={label}
                checked={selectedValues && selectedValues.indexOf(value) > -1}
                onChange={onChange}
                disabled={isDisabled}
            />
        </div>);
};
