import * as React from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Divider, Grid, Segment, Tab } from "semantic-ui-react";
import { getCurrencyFormat } from "@common/formating";
import { EventTypeDetailModel, ModuleTypeEnum, WorkflowTypeEnum } from "../../model";
import { TrainerAttributeDefinition } from "@common/crud/trainerAttributeDefinition";
import { EventType } from "../..";
import { TrainerPayTable } from "../TrainerPayTable/TrainerPayTable";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { MonitorPayTable } from "../MonitorObserverPayTable/MonitorPayTable";
import {
    OtherTrainerPayTable
} from "@common/crud/eventType/components/OtherTrainerPayTable/OtherTrainerPayTable";
import { isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate } from "@common/global/CommonHelpers";

interface TrainerFeesTabProps {
    model: EventTypeDetailModel;
    trainerAttributes: TrainerAttributeDefinition[];
    eventType: EventType;
}

export const TrainerFeesDetailsTab: React.FC<TrainerFeesTabProps> = ({ model, trainerAttributes, eventType }) => {
    const monitorAttribute = eventType.monitorAttributeId && (trainerAttributes.find(attr => attr.id === eventType.monitorAttributeId)?.name ?? "");
    const theoryTrainerAttribute = eventType.theoryTrainerAttributeId && trainerAttributes.find(attr => attr.id === eventType.theoryTrainerAttributeId);
    const trainerFeesClassroom = model?.classroomEventTypeDetails?.trainerFees;
    const digitalTrainerAttribute = model?.digitalEventTypeDetails?.digitalTrainerAttributeId &&
        trainerAttributes.find(attr => attr.id === model?.digitalEventTypeDetails?.digitalTrainerAttributeId);
    const digitalTrainerFees = model?.digitalEventTypeDetails?.digitalTrainerFees;
    const practicalFees = model?.classroomEventTypeDetails?.practicalTrainerFees;
    const theoryComplianceAttributes = model?.theoryComplianceAttributeIds?.map(a => trainerAttributes.find(attr => attr.id === a)?.name)?.join(", ");
    const practicalComplianceAttributes = model?.practicalComplianceAttributeIds?.map(a => trainerAttributes.find(attr => attr.id === a)?.name)?.join(", ");
    const welshTrainerAttribute = model?.welshTrainerAttributeId && trainerAttributes.find(attr => attr.id === model?.welshTrainerAttributeId)?.name;
    const isCorporate = isEventTypeWorkflowCorporate(eventType);
    const isConstruction = isEventTypeWorkflowConstruction(eventType);
    const isWorkflowWithMonitoring = !isCorporate && !isConstruction;

    return (
        <Tab.Pane>
            <Grid>
                <h3>Attribute</h3>
                {
                    isWorkflowWithMonitoring && <Detail label="Monitor Attribute" value={monitorAttribute} labelWidth={5} valueWidth={11} />
                }

                {model.moduleType !== ModuleTypeEnum.Practical && (model.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                    <Detail label="Theory Trainer attribute" value={theoryTrainerAttribute?.name} labelWidth={5} valueWidth={11} />}
                {model.moduleType !== ModuleTypeEnum.Practical && (model.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                    <Detail label="Digital Trainer attribute" value={digitalTrainerAttribute?.name} labelWidth={5} valueWidth={11} />}
                {!isCorporate && !isConstruction && (
                    <Detail label="Welsh Trainer attribute" value={welshTrainerAttribute} labelWidth={5} valueWidth={11} />
                )}
                {(model.moduleType === ModuleTypeEnum.Theory ||
                model.moduleType === ModuleTypeEnum.Both) &&
                    <Detail label="Theory Compliance attributes" value={theoryComplianceAttributes} labelWidth={5} valueWidth={11} />
                }
                {(model.moduleType === ModuleTypeEnum.Practical ||
                model.moduleType === ModuleTypeEnum.Both) &&
                    <Detail label="Practical Compliance attributes" value={practicalComplianceAttributes} labelWidth={5} valueWidth={11} />
                }
                {!isCorporate && !isConstruction &&
                <Detail
                    label="Disable auto allocate if trainer non compliant"
                    value={model?.disableAutoAllocateIfTrainerNonCompliant ? "Yes" : "No"}
                    labelWidth={5}
                    valueWidth={11}
                />}
            </Grid>

            {(model.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&<>
                <h3>Classroom Fees</h3>
                <TrainerPayTable trainerFees={trainerFeesClassroom}
                /></>}

            {(model.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&<>
                <h3>Digital Fees</h3>
                <TrainerPayTable trainerFees={digitalTrainerFees}
                /></>}

            {(model.moduleType === ModuleTypeEnum.Practical ||
                model.moduleType === ModuleTypeEnum.Both) &&
                <>
                    <h3>Practical Fees</h3>
                    <TrainerPayTable trainerFees={practicalFees}
                    />
                </>}

            {
                model.trainerUpliftFees &&
                <>
                    <h3>Trainer Uplift Fees</h3>
                    <Segment secondary>
                        <Grid>
                            {model.trainerUpliftFees.map((trainerFee, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Detail
                                            label="Fee Effective Date"
                                            value={trainerFee.effectiveDate?.format(DateFormat.Short)}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                        <Detail
                                            label="Fee"
                                            value={getCurrencyFormat(trainerFee.fee)}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                        {
                                            index !== model.trainerUpliftFees?.length - 1 &&
                                            <Divider />
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Segment>
                </>
            }
            { (eventType.deliveryType === SchemeDeliveryTypeEnum.Digital || eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                <>
                    <h3>Other Trainer Fees - Digital</h3>
                    <OtherTrainerPayTable otherTrainerFees={eventType.digitalEventTypeDetails.digitalOtherTrainerFees} />
                </>
            }
            { (eventType.deliveryType === SchemeDeliveryTypeEnum.Classroom || eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&
                <>
                    <h3>Other Trainer Fees - Classroom</h3>
                    <OtherTrainerPayTable otherTrainerFees={eventType.classroomEventTypeDetails.classroomOtherTrainerFees} />
                </>
            }
            { (eventType.workflowType === WorkflowTypeEnum.Dors || eventType.workflowType === WorkflowTypeEnum.DDRS) &&
                <>
                    <h3>Monitor</h3>
                    <MonitorPayTable trainerFees={model?.monitorFees} />
                </>
            }
        </Tab.Pane>
    );
};
