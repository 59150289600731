import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { push } from "redux-little-router";
import { NudgeTaskCompleteModel, NudgeTaskTypeModel } from "../common/NudgeTaskBaseModels";
import * as actions from "./actiontypes";
import { AppState, AlaskaNudgeTaskListModel, AlaskaNudgeTaskDetailModel, AlaskaNudgeTaskSearchOptions } from "./model";
import { AlaskaNudgeTaskApi } from "./alaskaNudgeTaskApi";
import { BusinessLineType } from "@common/redux-helpers";

const api = new AlaskaNudgeTaskApi();

export type AlaskaNudgeTaskAction =
    ({ type: actions.LOAD_ALASKA_TASKS_SUCCESS } & Payload<AlaskaNudgeTaskListModel[]>)
    | ({ type: actions.LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS } & Payload<AlaskaNudgeTaskDetailModel>)
    | ({ type: actions.COMPLETE_ALASKA_NUDGETASK_SUCCESS } & Payload<NudgeTaskCompleteModel>)
    | ({ type: actions.CREATE_ALASKA_NUDGETASK_SUCCESS } & Payload<AlaskaNudgeTaskDetailModel>)
    | ({ type: actions.LOAD_ALASKA_TASK_TYPES_SUCCESS } & Payload<NudgeTaskTypeModel[]>)
    | ({ type: actions.LOAD_ALASKA_TASK_USERS_SUCCESS } & Payload<string[]>)
    | ({ type: actions.LOAD_ALASKA_TASK_TRAINERS_SUCCESS } & Payload<string[]>)
    | ({ type: actions.LOAD_CA_EMAILS_SUCCESS } & Payload<string[]>)
    | ({ type: actions.LOAD_SENIOR_CA_EMAILS_SUCCESS } & Payload<string[]>)
    | ({ type: actions.LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS } & Payload<string[]>)
    | ({ type: actions.LOAD_ALASKA_TASK_COUNT_SUCCESS } & Payload<number>);

export const loadAlaskaTasksSuccess = (payload: AlaskaNudgeTaskListModel[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_ALASKA_TASKS_SUCCESS
});

export const createAlaskaTaskSuccess = (payload: AlaskaNudgeTaskDetailModel): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.CREATE_ALASKA_NUDGETASK_SUCCESS
});

export const loadAlaskaNudgeTaskTypesSuccess = (payload: NudgeTaskTypeModel[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_ALASKA_TASK_TYPES_SUCCESS
});

export const loadAlaskaNudgeTaskUsersSuccess = (payload: string[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_ALASKA_TASK_USERS_SUCCESS
});

export const loadAlaskaNudgeTaskTrainersSuccess = (payload: string[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_ALASKA_TASK_TRAINERS_SUCCESS
});

export const completeAlaskaNudgeTaskSuccess = (payload: NudgeTaskCompleteModel): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.COMPLETE_ALASKA_NUDGETASK_SUCCESS
});

export const loadAllCAEmailsSuccess = (payload: string[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_CA_EMAILS_SUCCESS
});

export const loadAllSeniorCAEmailsSuccess = (payload: string[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_SENIOR_CA_EMAILS_SUCCESS
});

export const loadAllCorporateAdminEmailsSuccess = (payload: string[]): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_CORPORATE_ADMIN_EMAILS_SUCCESS
});

export const loadAlaskaNudgeTaskCountSuccess = (payload: number): AlaskaNudgeTaskAction => ({
    payload,
    type: actions.LOAD_ALASKA_TASK_COUNT_SUCCESS
});

export function loadAlaskaTasks({ options }: { options?: AlaskaNudgeTaskSearchOptions } = {}) {
    return async (dispatch: Dispatch) => {
        const response = await loadAndTrack(dispatch, "loadAlaskaTasks", api.getAll(options));
        dispatch(loadAlaskaTasksSuccess(response.results));
        dispatch(loadAlaskaNudgeTaskCountSuccess(response.resultCount));
        dispatch(loadAlaskaNudgeTaskUsersSuccess(response.emailOfCaWhoCreatedNoteOptions));
        dispatch(loadAlaskaNudgeTaskTrainersSuccess(response.trainersWhoHaveBeenAssignedOptions));
    };
}

export function resubmitStatusesToDors(options: AlaskaNudgeTaskSearchOptions) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "resubmitStatusesToDors", api.resubmitStatusesToDors(options));
    };
}

export function resubmitCoursesToDors() {
    return async (dispatch: Dispatch) => {
        const currentUrl = `${location.pathname}${location.search}`;
        await loadAndTrack(dispatch, "resubmitCoursessToDors", api.resubmitCoursesToDors(currentUrl));
    };
}

export function resubmitTrainersToDors() {
    return async (dispatch: Dispatch) => {
        const currentUrl = `${location.pathname}${location.search}`;
        await loadAndTrack(dispatch, "resubmitTrainersToDors", api.resubmitTrainersToDors(currentUrl));
    };
}

export function addZoomAccounts(searchOptions: AlaskaNudgeTaskSearchOptions) {
    return async (dispatch: Dispatch) => {
        const currentUrl = `${location.pathname}${location.search}`;
        await loadAndTrack(dispatch, "addZoomAccounts", api.addZoomAccounts(currentUrl, searchOptions));
    };
}

export const loadAlaskaNudgeTaskDetailSuccess = (alaskaNudgeTask: AlaskaNudgeTaskDetailModel) => ({
    payload: alaskaNudgeTask,
    type: actions.LOAD_ALASKA_NUDGETASK_DETAIL_SUCCESS
});

export function loadAlaskaNudgeTaskDetail({ alaskaNudgeTaskId }: { alaskaNudgeTaskId: string }) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const nudgeTask = getState().alaskaTasks.filter(c => c.id === alaskaNudgeTaskId)[0];

        if (nudgeTask === undefined
            || nudgeTask.detailUpdated === undefined
            || nudgeTask.detailUpdated.isBefore(nudgeTask.listUpdated)) {

            const action = nudgeTask === undefined ? createAlaskaTaskSuccess : loadAlaskaNudgeTaskDetailSuccess;

            const result = await loadAndTrack(dispatch, "loadAlaskaNudgeTaskDetail", api.detail(alaskaNudgeTaskId));
            dispatch(action(result));
        }
    };
}

export function completeAlaskaNudgeTask({ nudgeTask, path }: { nudgeTask: NudgeTaskCompleteModel; path?: string }) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "completeAlaskaNudgeTask", api.complete(nudgeTask));

        dispatch(completeAlaskaNudgeTaskSuccess(nudgeTask));
        if (path !== undefined) {
            dispatch(push(path));
        }
    };
}

export function loadAlaskaNudgeTaskTypes(businessLineType: BusinessLineType) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadAlaskaNudgeTaskTypes", api.getTaskTypes(businessLineType));
        dispatch(loadAlaskaNudgeTaskTypesSuccess(result));
    };
}

export function goToAttendeeOrBookingByCorrelationId(correlationId: string, taskType?: number) {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "goToAttendeeOrBookingByCorrelationId", api.getAttendeeOrBookingLink(correlationId, taskType));
        dispatch(push(result));
    };
}

export function loadAllCAEmails() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadAllCAEmails", api.getAllCaEmails());
        dispatch(loadAllCAEmailsSuccess(result));
    };
}

export function loadAllSeniorCAEmails() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadAllSeniorCAEmails", api.getAllSeniorCaEmails());
        dispatch(loadAllSeniorCAEmailsSuccess(result));
    };
}

export function loadAllCorporateAdminEmails() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadAllCorporateAdminEmails", api.getAllCorporateAdminEmails());
        dispatch(loadAllCorporateAdminEmailsSuccess(result));
    };
}

export function markAsInvoiceProcessed({ selectedTaskIds, options }:
    { selectedTaskIds: string[]; options?: AlaskaNudgeTaskSearchOptions }) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "markAsInvoiceProcessed", api.markAsInvoiceProcessed(selectedTaskIds));
        const response = await loadAndTrack(dispatch, "loadAlaskaTasks", api.getAll(options));
        dispatch(loadAlaskaTasksSuccess(response.results));
        dispatch(loadAlaskaNudgeTaskCountSuccess(response.resultCount));
        dispatch(loadAlaskaNudgeTaskUsersSuccess(response.emailOfCaWhoCreatedNoteOptions));
        dispatch(loadAlaskaNudgeTaskTrainersSuccess(response.trainersWhoHaveBeenAssignedOptions));
    };
}

export function markAsOrderInvoiceProcessed({ selectedTaskIds, options }:
    { selectedTaskIds: string[]; options?: AlaskaNudgeTaskSearchOptions }) {
    return async (dispatch: Dispatch) => {
        await loadAndTrack(dispatch, "markAsOrderInvoiceProcessed", api.markAsOrderInvoiceProcessed(selectedTaskIds));
        const response = await loadAndTrack(dispatch, "loadAlaskaTasks", api.getAll(options));
        dispatch(loadAlaskaTasksSuccess(response.results));
        dispatch(loadAlaskaNudgeTaskCountSuccess(response.resultCount));
        dispatch(loadAlaskaNudgeTaskUsersSuccess(response.emailOfCaWhoCreatedNoteOptions));
        dispatch(loadAlaskaNudgeTaskTrainersSuccess(response.trainersWhoHaveBeenAssignedOptions));
    };
}
