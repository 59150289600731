import * as React from "react";
import { Link } from "redux-little-router";
import { Checkbox, Table } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { getBookingItemStateByCompletionState } from "../../eventInstance/helpers";
import { useSelector } from "react-redux";
import { universalEventManagementPathSelector } from "@common/redux-helpers";
import { OrderPaymentStatus } from "../model";

interface AttendeeRowProps {
    eventInstanceId: string;
    attendee: AttendeeListModel;
    index: number;
    selected: boolean;
    toggleAttendeeSelected: (eventInstanceId: string, attendeeId: string) => void;
}

export const AttendeeRowCheckable: React.FC<AttendeeRowProps> = ({
    eventInstanceId,
    attendee,
    index,
    selected,
    toggleAttendeeSelected
}) => {
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const toggleSelected = React.useCallback(() => {
        toggleAttendeeSelected(eventInstanceId, attendee.id);
    }, [attendee.id, eventInstanceId, toggleAttendeeSelected]);

    const blockAttendeeSelection = attendee.fromOrderWithBookNowPayLater &&
        attendee.bookNowPayLaterPaymentStatus ===
            OrderPaymentStatus.Paid;

    return (
        <Table.Row key={`eventInstanceRow_${index}`} className="grey">
            <Table.Cell>
                <Link href={`${eventManagementPath}/eventInstances/${eventInstanceId}/delegates/${attendee.id}`}>
                    {attendee.fullName}
                </Link>
            </Table.Cell>
            <Table.Cell>{attendee.drivingLicenceNumber}</Table.Cell>
            <Table.Cell>{attendee.bookingReference}</Table.Cell>
            <Table.Cell>
                {getBookingItemStateByCompletionState(attendee.isBookingCanceled, attendee.completed)}
            </Table.Cell>
            {blockAttendeeSelection && <Table.Cell title="Cannot select attendee, the invoice is already paid"><Checkbox
                checked={false}
                disabled
            /></Table.Cell>}
            {!blockAttendeeSelection && <Table.Cell><Checkbox
                checked={selected}
                onChange={toggleSelected}
            /></Table.Cell>}
        </Table.Row>
    );
};
