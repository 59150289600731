import * as React from "react";
import moment from "moment";
import { HeaderLogo } from "@common/global/HeaderLogo";
import { Address } from "@common/crud/common";
import "./LetterHeader.scss";
import { Grid } from "semantic-ui-react";

interface LetterHeaderProps {
    address: Address;
    addressee: string;
}

export function LetterHeader({ address, addressee }: LetterHeaderProps) {
    return (
        <Grid className="print-header">
            <Grid.Row className="header-row">
                <Grid.Column width={10} floated="left">
                    <HeaderLogo logo="logo-print.png" />
                </Grid.Column>
                <Grid.Column width={6} verticalAlign="bottom">
                    <div className="company-info">
                        <p className="para bold">TTC Group</p>
                        <p className="para">Hadley Park East</p>
                        <p className="para">Telford, TF1 6QJ</p>
                        <p className="para">T: 0330 024 1805</p>
                        <p className="para bold">www.thettcgroup.com</p>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <div className="address">
                        <div>{addressee}</div>
                        <div>{address.addressLine1}</div>
                        {address.addressLine2 && <div>{address.addressLine2}</div>}
                        {address.addressLine3 && <div>{address.addressLine3}</div>}
                        <div>{address.city}</div>
                        <div>{address.postalCode}</div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="date">
                <Grid.Column width={16} textAlign="right">
                    <span>{moment().format("DD/MMM/YYYY")}</span>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
