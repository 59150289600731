import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form } from "semantic-ui-react";
import { AppSettingInput } from "./AppSettingInput";
import { InputType } from "./SettingsGrid";
import { noop } from "lodash";
import { PostmarkTemplateInfoApi } from "../postmarkTemplateInfoApi";

export interface InfoModalProps {
    postmarkTemplates: string;
    settingName: string;
    settingLabel: string;
    open: boolean;
    onClose: () => void;
}

export const InfoModal: React.FC<InfoModalProps> = ({ open, postmarkTemplates, settingName, settingLabel, onClose }) => {
    const [value, setValue] = React.useState<string>("");

    React.useEffect(() => {
        const fetchInfo = async () => {
            const api = new PostmarkTemplateInfoApi();
            const infoValue = await api.info(postmarkTemplates, settingName);
            setValue(infoValue);
        };

        if (open && settingName) {
            fetchInfo();
        }
    }, [open, postmarkTemplates, settingName]);

    return (
        <Modal open={open}>
            <Modal.Header>
                Info - {settingLabel}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <AppSettingInput
                        inputType={InputType.TextArea}
                        value={value}
                        onChange={noop}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} className="cancel-action">
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
