import * as React from "react";
import { DropdownItemProps } from "semantic-ui-react";
import moment from "moment";
import { DigitalPlanningSession } from "../../model";

const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

export const GetNextTwelveMonthsDropDownOptions = (sessionsInProgress: DigitalPlanningSession[]): DropdownItemProps[] => {

    let currentMonth = moment().month();
    let year = moment().year();
    const options: DropdownItemProps[] = [];
    while (options.length < 12) {
        if (currentMonth > 11) {
            currentMonth = 0;
            year++;
        }
        // eslint-disable-next-line eqeqeq
        const inProgressSession = sessionsInProgress?.find(p => p.month.month() === currentMonth) != null;
        const month = `${months[currentMonth]} ${year}`;
        options.push({ text: <strong>{month}{inProgressSession ? "- IN PROGRESS" : ""}</strong>, value: month });
        currentMonth++;
    }
    return options;
};

export const GetMomentFromDropDownItem = (date: string): moment.Moment => {
    const parts = date.replace("- IN PROGRESS", "").split(" ");
    const month = months.indexOf(parts[0]) + 1;
    return moment(`01-${month}-${parts[1]}`, "DD-MM-YYYY").utc(true);
};

export const GetDropDownItemFromMoment = (date: moment.Moment): string => {
    const month = months[date.month()];
    return `${month} ${date.year()}`;
};

export const GetDropDownItemFromDate = (date: Date): string => {
    const month = months[date.getMonth()];
    return `${month} ${date.getFullYear()}`;
};
