import * as React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";

import { EditForm, EditProps, DispatchProps } from "./Edit";

export const EditModal = (props: EditProps & DispatchProps) => {
    const { open, save, model, dsaAreas, close } = props;

    const editForm = React.useRef(null);

    const setEditFormRef = React.useCallback((instance: EditForm) => {
        editForm.current = instance;
    }, []);

    const submit = React.useCallback(() => {
        editForm.current.submit();
    }, []);

    return (
        <Modal size="small" open={open} onClose={close}>
            <Modal.Header>Edit Organisation</Modal.Header>
            <Modal.Content>
                <EditForm
                    open={open}
                    save={save}
                    model={model}
                    dsaAreas={dsaAreas}
                    ref={setEditFormRef}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button className="cancel-action" onClick={close}>Cancel</Button>
                <Button onClick={submit}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
};
