import * as React from "react";
import { Table } from "semantic-ui-react";
import { SortDirection } from "@common/model";

export interface SortableHeaderProps<P extends string = string> {
    propertyName: P;
    title: string;
}

export interface SortableHeadersProps<P extends string = string> {
    headers: SortableHeaderProps<P>[];
    onSort: (property: P, direction: SortDirection) => void;
    initialSortProperty?: P;
    initialSortDirection?: SortDirection;
}

interface SortableHeadersState<P extends string> {
    sortProperty: P;
    sortDirection: SortDirection;
}

const reverseDirection = (direction: SortDirection): SortDirection => direction === "asc" ? "desc" : "asc";

export class SortableHeaders<P extends string = string> extends React.Component<SortableHeadersProps<P>, SortableHeadersState<P>> {
    constructor(props: SortableHeadersProps<P>) {
        super(props);
        this.state = {
            sortProperty: props.initialSortProperty ?? null,
            sortDirection: props.initialSortDirection ?? "asc"
        };
    }

    public render() {
        return (
            <Table.Row>
                {this.props.headers.map(this.renderHeader)}
            </Table.Row>
        );
    }

    private renderHeader = (header: SortableHeaderProps<P>) => {
        const { propertyName, title } = header;
        return (
            <Table.HeaderCell
                key={propertyName}
                onClick={this.sort(propertyName)}
                sorted={this.getSorted(propertyName)}
            >
                {title}
            </Table.HeaderCell>
        );
    };

    private getSorted(property: string) {
        const { sortProperty, sortDirection } = this.state;
        if (sortProperty !== property) {
            return null;
        }
        return sortDirection === "desc" ? "descending" : "ascending";
    }

    private sort(property: P) {
        return () => {
            const { sortProperty, sortDirection } = this.state;
            const newDirection = property === sortProperty ? reverseDirection(sortDirection) : sortDirection;
            this.setState({ sortProperty: property, sortDirection: newDirection });
            this.props.onSort(property, newDirection);
        };
    }
}
