import * as React from "react";
import { useSelector } from "react-redux";
import { State } from "redux-little-router";
import { CreateStartPaymentProcessRequestResponseStatus } from "@common/payments/model";
import { toast } from "@common/toasts";

const DorsUpdateError: React.FC = () => {
    const status = +useSelector((state: State) => state.router.query.status);

    const historyGo = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        // should go back to the landing page.
        window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf("/"));
    }, []);

    const clickHere = (
        <a className="primary-link" href="#" onClick={historyGo}>
            click here
        </a>
    );

    React.useEffect(() => window.onpopstate = () => {
        toast.warning("Sorry this course is no longer available, please use link below to return to the Search page"); history.forward(); },
    []);

    switch (status) {
        case CreateStartPaymentProcessRequestResponseStatus.BookedAtTheoreticalMaximum:
            return (
                <>
                    We're sorry this course is no longer available as the extra seat has been taken, please {clickHere} to view alternative courses
                </>
            );
        case CreateStartPaymentProcessRequestResponseStatus.FullAndIncludeFullyBookedNotSelected:
            return (
                <>
                    We're sorry this course is no longer available, please {clickHere} to view alternative courses
                </>
            );
        case CreateStartPaymentProcessRequestResponseStatus.UnableToNotifyDors:
            return (
                <>
                    We're sorry but DORS is not accepting any more bookings on to this course, please {clickHere} to view alternative courses
                </>
            );
        default:
            return (
                <>
                    Unknown error, please {clickHere} to view alternative courses
                </>
            );
    }
};

export { DorsUpdateError };
