import { Detail } from "@neworbit/simpleui-detail";
import * as React from "react";

interface TimingDetailsProps {
    startDate: string;
    eventDuration?: string;
    educationDuration?: string;
    startTime: string;
    registrationEndTime?: string;
    theoryStartTime?: string;
    practicalStartTime?: string;
    practicalDuration?: string;
    theoryDuration?: string;
    theoryAndPractical?: boolean;
    showCombined?: boolean;
    hasEducationDuration?: boolean;
}
export const TimingDetails: React.FC<TimingDetailsProps> = ({
    startDate,
    eventDuration,
    educationDuration,
    startTime,
    registrationEndTime,
    theoryAndPractical,
    showCombined,
    practicalDuration,
    practicalStartTime,
    theoryDuration,
    theoryStartTime,
    hasEducationDuration,
}) => {
    return (
        <>
            <Detail
                label="Date"
                value={startDate}
                labelWidth={5}
                valueWidth={11}
            />
            {showCombined && (
                <Detail
                    label={"Classroom Start time"}
                    value={theoryStartTime}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {!showCombined && (
                <Detail
                    label={
                        theoryAndPractical ? "Theory Start time" : "Start time"
                    }
                    value={theoryAndPractical ? theoryStartTime : startTime}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {registrationEndTime && (
                <Detail
                    label="Registration end time"
                    value={registrationEndTime}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {showCombined && theoryDuration && (
                <Detail
                    label={"Classroom duration"}
                    value={theoryDuration}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {!showCombined && theoryDuration && (
                <Detail
                    label="Theory duration"
                    value={theoryDuration}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {!showCombined && practicalStartTime && (
                <Detail
                    label="Practical Start time"
                    value={practicalStartTime}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {!showCombined && practicalDuration && (
                <Detail
                    label="Practical duration"
                    value={practicalDuration}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {eventDuration && (
                <Detail
                    label="Course duration"
                    value={eventDuration}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {hasEducationDuration && educationDuration && (
                <Detail
                    label="Education duration"
                    value={educationDuration}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
        </>
    );
};
