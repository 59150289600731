import { createValidator } from "not-valid";

const  ukFullPostcodeOrOutwardCode = (errorMessage: string) => {
    return createValidator<string>(
        v => new RegExp("^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2}|[A-Z][A-HJ-Y]?[0-9][A-Z0-9]?)$").test(v.toUpperCase()),
        errorMessage);
};

export const ukFullPostcodeOrOutwardCodeValidator = (errorMessage: string) => {
    return [ukFullPostcodeOrOutwardCode(errorMessage)];
};
