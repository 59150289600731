import * as React from "react";
import { Form } from "semantic-ui-react";
import { EventInstance } from "@common/crud/eventInstance/model";
import { TermsAndFaqLinks } from "./TermsAndFaqLinks";
import { OrganisationDetailModel } from "@common/crud/organisation/model";
import { MissingBookingProps } from "../models";
import { Field, useFormikContext } from "formik";
import { ErrorMessage } from "@common/components/ErrorMessage";
import { ErrorMessage as ErrorMessageWrapper } from "formik";
import { useTypeSafeTranslation } from "@booking/i18n/resources/useTypeSafeTranslation";

interface TAndCsConfirmationComponentProps {
    eventInstance: EventInstance;
    organisation?: OrganisationDetailModel;
}
export const TAndCsConfirmationComponent: React.SFC<TAndCsConfirmationComponentProps> =
    ({  eventInstance, organisation }) => {
        const {  touched, errors, values }  = useFormikContext<MissingBookingProps>();
        const isError = errors.tAndCsConfirmed && touched.tAndCsConfirmed;
        const t = useTypeSafeTranslation("BookingDetails");

        const validate = React.useCallback((value: boolean) => {

            if (value) {
                return null;
            }

            return t("YOU_MUST_TICK_THE_BOX");
        }, [t]);

        React.useEffect(() => {
            sessionStorage.setItem("tAndCsConfirmed", values.tAndCsConfirmed.toString());
        },[values.tAndCsConfirmed]);

        return (
            <Form error={isError}>
                <div className="tacs-container mobile-keyline">
                    <Field
                        id={"checkbox-field"}
                        name="tAndCsConfirmed"
                        type="checkbox"
                        error={isError}
                        className="tacs-checkbox"
                        validate={validate}
                    />
                    <label htmlFor="checkbox-field" />
                    <TermsAndFaqLinks eventInstance={eventInstance} organisation={organisation} />
                </div>
                <ErrorMessageWrapper
                    name="tAndCsConfirmed"
                    className="error-margin-bottom"
                >
                    {(msg) => <div className={"error-message-wrapper"}><ErrorMessage error={msg} /></div>}
                </ErrorMessageWrapper>
            </Form>
        );
    };
