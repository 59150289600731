import * as React from "react";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { AppCommonState } from "@common/appCommonState";
import { Apps } from "@common/model";
import { EventInstanceLineOfBusinessMenu } from "@common/crud/eventInstance/components/EventInstanceLineOfBusinessMenu";
import { Area } from "../model";

export const All: React.FC<{ children: JSX.Element; extraSpacing?: boolean; area: Area; withoutLineOfBusinessMenu?: boolean }>
    = ({ children, extraSpacing, area, withoutLineOfBusinessMenu }) => {
        const app = useSelector((state: AppCommonState) => state.app);
        return (
            <>
                <Grid container stackable className="nomargintop filter-table">
                    <Grid.Row className={extraSpacing ? "extra-spacing" : ""}>
                        <Grid.Column width={16}>
                            <h1>{app === Apps.Admin && area === Area.AdminEventManagementEventInstance? "Courses" : "Schedule"}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    {!withoutLineOfBusinessMenu && app === Apps.Admin &&
                        (area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementTrainerEventInstance) &&
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <EventInstanceLineOfBusinessMenu area={area} />
                        </Grid.Column>
                    </Grid.Row>}
                </Grid>
                {children}
            </>
        );
    };
