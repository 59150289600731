import { createValidator } from "not-valid";
import moment from "moment";

export const consentDateValidator = createValidator<moment.Moment>(
    v => {
        if (v && v.isValid()) {
            return v.utc().startOf("day").diff(moment(Date.now()).utc().subtract(3, "years").startOf("day")) > 0 &&
                v.utc().startOf("day").diff(moment(Date.now()).utc().startOf("day")) < 1;
        }
        else {
            return false;
        }
    },
    "Please enter a date between today and three years ago.");
