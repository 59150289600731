import * as React from "react";
import { Button, Table } from "semantic-ui-react";
import "./TypedTable.scss";
import { toast } from "react-toastify";

export interface DragDropTypedTableRowProps<T> {
    header?: string;
    key?: string;
    functionalHeader?: () => string | React.ReactNode;
    value: (obj: T) => string | React.ReactNode;
    cellClassName?: string;
    showTotal?: boolean;
    getTotalValue?: (values: T[]) => string | React.ReactNode;
    dynamicCellClassName?: (obj: T) => string;
}

export interface DragDropTypedTableProps<T> {
    children: DragDropTypedTableRowProps<T>[];
    emptyValuesArrayMessage: string;
    values: T[];
    tableClassName?: string;
    headerClassName?: string;
    showPrintableFooter?: boolean;
    footerClassName?: string;
    onSaveChanges: (values: T[]) => void;
    reorderButtonText?: string;
}

const printableFooter = (footerClassName: string) => (
    <tfoot>
        <tr>
            <td>
                <div className={footerClassName}>&nbsp;</div>
            </td>
        </tr>
    </tfoot>
);

export function DragDropTypedTable<T extends { id: string; path?: string; customKey?: string }>(props: DragDropTypedTableProps<T>) {
    const { children, values, tableClassName, headerClassName, emptyValuesArrayMessage,
        showPrintableFooter, footerClassName, onSaveChanges, reorderButtonText } = props;
    const showTotalsRow = children.find(c => c.showTotal) !== undefined;

    const [draggingIndex, setDraggingIndex] = React.useState<number | null>(null);
    const [draggedIndex, setDraggedIndex] = React.useState<number | null>(null);
    const [orderedValues, setOrderedValues] = React.useState(values);
    const [isDragAndDropEnabled, setIsDragAndDropEnabled] = React.useState(false);

    React.useEffect(() => {
        return () => {
            toast.dismiss("info-toast");
        };
    }, []);

    React.useEffect(() => {
        setOrderedValues(values);
    }, [values]);

    const toggleDragAndDrop = () => {
        if (isDragAndDropEnabled) {
            onSaveChanges(orderedValues);
            toast.dismiss();
            toast.success("Changes saved successfully");
        } else {
            setOrderedValues(values);
            toast.dismiss();
            toast.info("Drag and drop to reorder", { autoClose: false, toastId: "info-toast" });
        }
        setIsDragAndDropEnabled(!isDragAndDropEnabled);
    };

    const handleDragStart = (index: number) => {
        setDraggingIndex(index);
        setDraggedIndex(index);
    };

    const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
        event.preventDefault();
    };

    const handleDrop = (index: number) => {
        if (draggedIndex === null) {
            return;
        }
        const newValues = [...orderedValues];
        const [removed] = newValues.splice(draggedIndex!, 1);
        newValues.splice(index, 0, removed);
        setOrderedValues(newValues);
        setDraggedIndex(null);
        setDraggingIndex(null);
    };

    const handleCancelChanges = () => {
        toast.dismiss();
        toast.info("Changes cancelled");
        setOrderedValues(values);
        setIsDragAndDropEnabled(false);
    };

    return (
        <>
            <Button
                disabled={orderedValues.length < 2}
                content={isDragAndDropEnabled ? "Save Changes" : reorderButtonText}
                onClick={toggleDragAndDrop}
            />
            {isDragAndDropEnabled && (
                <Button
                    icon="cancel"
                    content="Cancel Changes"
                    onClick={handleCancelChanges}
                />
            )}
            <Table className={tableClassName}>
                <Table.Header className={headerClassName}>
                    <Table.Row>
                        {children.map((t, i) => (
                            t && (
                                <Table.HeaderCell key={`${t.header || t.key || "header_row"}_${i}`}>
                                    {t.header ?? t.functionalHeader()}
                                </Table.HeaderCell>
                            )
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {orderedValues?.length > 0 ? orderedValues.map((v, idx) => (
                        <Table.Row
                            key={`${v.customKey || v.id || "body_row"}_${idx}`}
                            draggable={isDragAndDropEnabled}
                            onDragStart={isDragAndDropEnabled ? () => handleDragStart(idx) : undefined}
                            onDragOver={isDragAndDropEnabled ? handleDragOver : undefined}
                            onDrop={isDragAndDropEnabled ? () => handleDrop(idx) : undefined}
                            className={`
                                ${isDragAndDropEnabled ? "move-cursor" : "default-cursor"}
                                ${isDragAndDropEnabled && draggingIndex === idx ? "dragging" : ""}
                            `}
                        >
                            {props.children.map((t, i) => {
                                if (!t) {
                                    return null;
                                }

                                const classOne = t.cellClassName ? t.cellClassName + " " : "";
                                const classTwo = (t.dynamicCellClassName && t.dynamicCellClassName(v)) ?? "";
                                return (
                                    <Table.Cell key={`${t.key || "value"}_${i}`} className={classOne + classTwo}>
                                        {t.value(v)}
                                    </Table.Cell>
                                );
                            })}
                        </Table.Row>
                    )) : (
                        <Table.Row>
                            <Table.Cell colSpan={children.length}>{emptyValuesArrayMessage}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
                {showTotalsRow &&
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell key={0}>Total</Table.HeaderCell>
                            {children?.map((col, index) => (
                                col && (
                                    index > 0 ? (col.showTotal ?
                                        <Table.HeaderCell key={`footer_${index}`}>{col.getTotalValue(values)}</Table.HeaderCell>
                                        : <Table.HeaderCell key={`footer_${index}`} />) : null
                                )))}
                        </Table.Row>
                    </Table.Footer>}
                {!showTotalsRow && showPrintableFooter ? (printableFooter(footerClassName)) : (<></>)}
            </Table>
        </>
    );
}
