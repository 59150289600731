import { createBasePathSelector } from "@common/redux-helpers/selectors";
import { createSelector } from "reselect";
import { DrinkDriveOffender } from "@common/crud/drinkDriveOffenders/model";

import { AppState } from "./model";

export type SelectorState = AppState;

export const routeIdSelector = (state: AppState) => state.router.params.drinkDriveOffenderId;

export const drinkDriveOffendersSelector = (state: AppState) => state.drinkDriveOffenders;

const empty = {};

export const drinkDriveOffenderSelector = createSelector(
    drinkDriveOffendersSelector,
    routeIdSelector,
    (drinkDriveOffenders: DrinkDriveOffender[], id: string) => {
        return drinkDriveOffenders.filter(c => c.id === id)[0] || empty as any;
    }
);

export const basePathSelector = createBasePathSelector("drinkDriveOffenders");
