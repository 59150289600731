import * as React from "react";
import moment from "moment";
import { EventTypeCreateEditModel, FeeWithEffectiveDate } from "../../model";
import { Button, Icon, Label, Segment } from "semantic-ui-react";
import { FeeWithEffectiveDateEdit } from "./FeeWithEffectiveDateEdit";

export interface Props {
    fees: FeeWithEffectiveDate[];
    showErrors?: boolean;
    feesProperty: keyof EventTypeCreateEditModel;
    feeLabel: string;
    onChange(propName: keyof EventTypeCreateEditModel): (value: any, valid: boolean) => void;
}

export const FeeWithEffectiveDateList: React.FC<Props> = ({
    fees,
    showErrors,
    feesProperty,
    feeLabel: feeLabel,
    onChange }) => {

    const onFeeChanged = React.useCallback((fee: FeeWithEffectiveDate) => {
        return (value: number, valid: boolean) => {
            const newFee = Object.assign({}, { ...fee });
            newFee.fee = value;
            const newFees = fees.map(t => {
                if (t === fee) {
                    return newFee;
                }
                return t;
            });
            onChange(feesProperty)(newFees, valid);
        };
    }, [feesProperty, onChange, fees]);

    const onEffectiveDateChanged = React.useCallback((fee: FeeWithEffectiveDate) => {
        return (value: moment.Moment, valid: boolean) => {
            const newFee = Object.assign({}, { ...fee }, { effectiveDate: value });
            const newFees = fees.map(t => {
                if (t === fee) {
                    return newFee;
                }
                return t;
            });
            onChange(feesProperty)(newFees, valid);
        };
    }, [feesProperty, onChange, fees]);

    const onFeeRemoved = React.useCallback((fee: FeeWithEffectiveDate) => {
        return (e: any) => {
            e.preventDefault();
            const newFees = fees.filter(tf => tf !== fee);
            onChange(feesProperty)(newFees, true);
        };
    }, [feesProperty, onChange, fees]);

    const addFee = React.useCallback((e: any) => {
        e.preventDefault();
        const newFees = [...fees, { effectiveDate: moment.utc().startOf("day") }];
        onChange(feesProperty)(newFees, true);
    }, [feesProperty, onChange, fees]);

    return (
        <>
            <Button as="div" labelPosition='left' size="big">
                <Label basic>{feeLabel} Fees</Label>
                <Button icon onClick={addFee}>
                    <Icon name="add" />
                </Button>
            </Button>

            {
                fees && fees.map((fee, index) => (
                    <Segment secondary key={index}>
                        <FeeWithEffectiveDateEdit
                            feeWithEffectiveDate={fee}
                            showErrors={showErrors}
                            onEffectiveDateChange={onEffectiveDateChanged}
                            onFeeChange={onFeeChanged}
                        />
                        <Button className="cancel-action" attached="bottom" content={`Remove ${feeLabel} Fee`} onClick={onFeeRemoved(fee)} />
                    </Segment>
                ))
            }
        </>
    );
};
