import { TrainerAllocationModel } from "@common/crud/trainer/model";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Loader, Modal } from "semantic-ui-react";
import { loadEventInstanceDetail } from "../../actions";
import { EventInstanceApi } from "../../eventInstanceApi";
import { EventTrainer, PracticalEventTrainer, TrainerType } from "../../model";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";

export interface RevertTrainerSwapModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    eventInstanceId: string;
    trainer: EventTrainer | PracticalEventTrainer;
    fee: number;
    trainerType: TrainerType;
}

export const RevertTrainerSwapModal = (props: RevertTrainerSwapModalProps) => {
    const { isOpen, setIsOpen, eventInstanceId, trainer, fee, trainerType } = props;
    const [isChecking, setIsChecking] = React.useState(true);
    const [canRevert, setCanRevert] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const checkIfRevertSwapPossible = async () => {
            setIsChecking(true);
            const availabilityApi = new TrainerAvailabilityApi();
            const simpleTrainerAvailibilities: Dictionary<TrainerAvailabilitySelectModel[]>
                = await availabilityApi.getSimpleTrainersAvailabilityForEventInstances([eventInstanceId], trainer.id, trainerType);
            setCanRevert(simpleTrainerAvailibilities[eventInstanceId].some(t => t.id === trainer.subcontractingProcess.originalTrainerId
                && !t.isBooked && !t.isProvisionallyBooked && !t.isRCCoverOnDate && !t.isRCMonitorOnDate && !t.isStandbyTrainerDuringEventInstance));
            setIsChecking(false);
        };

        if (isOpen && trainer?.id) {
            checkIfRevertSwapPossible();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, trainer?.id]);

    const closeModal = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const revertSwap = React.useCallback(async () => {
        setIsLoading(true);

        const newTrainer: Record<string, TrainerAllocationModel> = {
            [eventInstanceId]: {
                id: trainer?.subcontractingProcess.originalTrainerId,
                allocateToCourses: [],
                fee,
                sundries: 0,
                subcontractingProcess: {
                    ...trainer?.subcontractingProcess,
                    isActive: false
                }
            }
        };

        await new EventInstanceApi().swapTrainer({ trainerId: trainer?.id, trainers: newTrainer, trainerType });
        dispatch(loadEventInstanceDetail({ eventInstanceId }));

        setIsLoading(false);
    }, [dispatch, eventInstanceId, fee, trainer?.id, trainer?.subcontractingProcess, trainerType]);

    return (
        <Modal open={isOpen}>
            {isChecking ? (
                <>
                    <Modal.Header>Checking if reverting swap is possible</Modal.Header>
                    <Loader />
                </>
            ) : (
                canRevert ? (
                    <>
                        <Modal.Header>Are you sure?</Modal.Header>
                        <Modal.Content>Are you sure you wish to revert this swap? This means the subcontracting process will be cancelled</Modal.Content>
                        <Modal.Actions>
                            <Button className="cancel-action" content="Cancel" onClick={closeModal} loading={isLoading} disabled={isLoading} />
                            <Button positive content="Complete" onClick={revertSwap} loading={isLoading} disabled={isLoading} />
                        </Modal.Actions>
                    </>
                ) : (
                    <>
                        <Modal.Header>Reverting swap is impossible</Modal.Header>
                        <Modal.Content>Original trainer is already assigned to other activity at given time</Modal.Content>
                        <Modal.Actions>
                            <Button className="cancel-action" content="Close" onClick={closeModal} />
                        </Modal.Actions>
                    </>
                )
            )}
        </Modal>
    );
};
