import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tab } from "semantic-ui-react";
import { orderSelector, routeIdSelector } from "../selectors";
import { DetailsTab } from "./DetailsTab";
import { clearReservations, loadOrderDetail } from "../actions";
import { State, push } from "redux-little-router";
import { OrderHistory } from "./OrderHistory";
import { parse } from "query-string";
import { CustomTabProps } from "@common/model";

export const Detail = () => {
    const [orderIdLoaded, setOrderIdLoaded] = React.useState<string>(undefined);

    const dispatch = useDispatch();

    const path = useSelector((state: State) => state.router.pathname);
    const orderId = useSelector(routeIdSelector);
    const order = useSelector(orderSelector);

    const basePath = React.useMemo(() => path[path.length - 1] === "/" ? path.slice(0, -1) : path, [path]);

    React.useEffect(() => {
        if (orderId && orderIdLoaded !== orderId) {
            dispatch(clearReservations(orderId));
            dispatch(loadOrderDetail({ orderId }));
            setOrderIdLoaded(orderId);
        }
    }, [dispatch, orderId, orderIdLoaded]);

    const panes = React.useMemo(() => [
        { menuItem: "Details", path: "", render: () => <DetailsTab order={order} /> },
        { menuItem: "Booking Journey", path: "history", render: () => <OrderHistory /> },
    ], [order]);

    const activeTab = React.useMemo(() => basePath.split("/").pop().split("?")[0], [basePath]);
    const activeIndex = React.useMemo(() => panes.findIndex(p => p?.path === activeTab), [activeTab, panes]);

    const handleTabChange = React.useCallback((_: any, tabData: CustomTabProps) => {
        let newPath;
        if (tabData.panes[+tabData.activeIndex].path === "" && basePath.indexOf("history") !== -1) {
            newPath = basePath.substring(0, path.indexOf("history") - 1);
        } else if (tabData.panes[+tabData.activeIndex].path === "history" && basePath.indexOf("history") === -1) {
            newPath = `${basePath}/history`;
        }

        if (location.search) {
            const locationSearch = parse(location.search.substring(1));
            if (locationSearch.eventInstanceId) {
                newPath = `${newPath}?eventInstanceId=${locationSearch.eventInstanceId}`;
            }
        }

        if (newPath) {
            dispatch(push(newPath));
        }
    }, [basePath, dispatch, path]);

    return (
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <Grid.Column>
                    <h1>{order?.bookingReference}</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Tab
                        onTabChange={handleTabChange}
                        panes={panes}
                        activeIndex={activeIndex >= 0 ? activeIndex : 0}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
