import * as React from "react";

import { DorsProxySettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const DorsProxy: ConfigurationAreaComponent<DorsProxySettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="DorsProxy"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "AlaskaSupportedSchemes", label: "Alaska Supported Schemes" },
                { name: "SuspendedSchemes", label: "Suspended schemes" },
                { name: "EnableDorsApiEndpoints", label: "Enable DORS API Endpoints", inputType: InputType.Toggle }
            ]}
        />
    );
};
