/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_EVENTINSTANCES_SUCCESS = "LOAD_EVENTINSTANCES_SUCCESS";
export type LOAD_EVENTINSTANCES_SUCCESS = typeof LOAD_EVENTINSTANCES_SUCCESS;

export const TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS = "TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS";
export type TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS = typeof TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS;

export const LOAD_EVENTINSTANCEATTENDEES_SUCCESS = "LOAD_EVENTINSTANCEATTENDEES_SUCCESS";
export type LOAD_EVENTINSTANCEATTENDEES_SUCCESS = typeof LOAD_EVENTINSTANCEATTENDEES_SUCCESS;

export const CREATE_EVENTINSTANCE_SUCCESS = "CREATE_EVENTINSTANCE_SUCCESS";
export type CREATE_EVENTINSTANCE_SUCCESS = typeof CREATE_EVENTINSTANCE_SUCCESS;

export const CREATE_EVENTINSTANCES_SUCCESS = "CREATE_EVENTINSTANCES_SUCCESS";
export type CREATE_EVENTINSTANCES_SUCCESS = typeof CREATE_EVENTINSTANCES_SUCCESS;

export const SAVE_EVENTINSTANCE_SUCCESS = "SAVE_EVENTINSTANCE_SUCCESS";
export type SAVE_EVENTINSTANCE_SUCCESS = typeof SAVE_EVENTINSTANCE_SUCCESS;

export const SAVE_EVENTINSTANCE_GROUP_SUCCESS = "SAVE_EVENTINSTANCE_GROUP_SUCCESS";
export type SAVE_EVENTINSTANCE_GROUP_SUCCESS = typeof SAVE_EVENTINSTANCE_GROUP_SUCCESS;

export const SAVE_EVENTINSTANCES_SUCCESS = "SAVE_EVENTINSTANCES_SUCCESS";
export type SAVE_EVENTINSTANCES_SUCCESS = typeof SAVE_EVENTINSTANCES_SUCCESS;

export const LOAD_EVENTINSTANCE_DETAIL_SUCCESS = "LOAD_EVENTINSTANCE_DETAIL_SUCCESS";
export type LOAD_EVENTINSTANCE_DETAIL_SUCCESS = typeof LOAD_EVENTINSTANCE_DETAIL_SUCCESS;

export const GET_SEAT_AVAILABILITY_SUCCESS = "GET_SEAT_AVAILABILITY_SUCCESS";
export type GET_SEAT_AVAILABILITY_SUCCESS = typeof GET_SEAT_AVAILABILITY_SUCCESS;

export const UPDATE_REASON_FOR_HIDING_EVENT = "UPDATE_REASON_FOR_HIDING_EVENT";
export const UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS = "UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS";

export const BULK_UPDATE_REASON_FOR_HIDING_EVENT = "BULK_UPDATE_REASON_FOR_HIDING_EVENT";
export const BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS = "BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS";

export const BULK_UPDATE_DORS_OPEN_PLACES_COUNT = "BULK_UPDATE_DORS_OPEN_PLACES_COUNT";
export const BULK_UPDATE_DORS_OPEN_PLACES_COUNT_SUCCESS = "BULK_UPDATE_DORS_OPEN_PLACES_COUNT_SUCCESS";

export const BULK_REMOVE_TRAINERS = "BULK_REMOVE_TRAINERS";
export const BULK_REMOVE_TRAINERS_SUCCESS = "BULK_REMOVE_TRAINERS_SUCCESS";

export const BULK_REMOVE_TRAINER = "BULK_REMOVE_TRAINER";
export const BULK_REMOVE_TRAINER_SUCCESS = "BULK_REMOVE_TRAINER_SUCCESS";

export const BULK_SEND_EMAILS = "BULK_SEND_EMAILS";

export const CANCEL_EVENT_INSTANCE = "CANCEL_EVENT_INSTANCE";
export const CANCEL_EVENT_INSTANCE_SUCCESS = "CANCEL_EVENT_INSTANCE_SUCCESS";

export const CANCEL_MULTIPLE_EVENT_INSTANCES = "CANCEL_MULTIPLE_EVENT_INSTANCES";
export const CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS = "CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS";

export const BULK_MAKE_AVAILABLE_TO_TRAINERS = "BULK_MAKE_AVAILABLE_TO_TRAINERS";
export const BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS = "BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS";

export const PUBLISH_EVENT_INSTANCE_SUCCESS = "PUBLISH_EVENT_INSTANCE_SUCCESS";
export type PUBLISH_EVENT_INSTANCE_SUCCESS = typeof PUBLISH_EVENT_INSTANCE_SUCCESS;

export const SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS = "SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS";
export type SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS = typeof SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS;

export const REGISTRATION_SUBMIT_SUCCESS = "REGISTRATION_SUBMIT_SUCCESS";
export type REGISTRATION_SUBMIT_SUCCESS = typeof REGISTRATION_SUBMIT_SUCCESS;

export const ACCEPT_EVENT_INSTANCES_SUCCESS = "ACCEPT_EVENT_INSTANCES_SUCCESS";
export type ACCEPT_EVENT_INSTANCES_SUCCESS = typeof ACCEPT_EVENT_INSTANCES_SUCCESS;

export const SAVE_NOTES_SUCCESS = "SAVE_NOTES_SUCCESS";
export type SAVE_NOTES_SUCCESS = typeof SAVE_NOTES_SUCCESS;

export const SAVE_HEALTH_AND_SAFETY_SUCCESS = "SAVE_HEALTH_AND_SAFETY_SUCCESS";
export type SAVE_HEALTH_AND_SAFETY_SUCCESS = typeof SAVE_HEALTH_AND_SAFETY_SUCCESS;

export const SAVE_FINANCE_DETAILS_SUCCESS = "SAVE_FINANCE_DETAILS_SUCCESS";
export type SAVE_FINANCE_DETAILS_SUCCESS = typeof SAVE_HEALTH_AND_SAFETY_SUCCESS;

export const SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS = "SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS";
export type SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS = typeof SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS;

export const SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS = "SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS";
export type SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS =
    typeof SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS;

export const ALLOCATE_TRAINERS_SUCCESS = "ALLOCATE_TRAINERS_SUCCESS";
export type ALLOCATE_TRAINERS_SUCCESS = typeof ALLOCATE_TRAINERS_SUCCESS;

export const LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS = "LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS";
export type LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS = typeof LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS;

export const SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS = "SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS";
export type SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS = typeof SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS;

export const DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS = "DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS";
export type DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS = typeof DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS;

export const LOAD_EVENTINSTANCE_GROUP_SUCCESS = "LOAD_EVENTINSTANCE_GROUP_SUCCESS";
export type LOAD_EVENTINSTANCE_GROUP_SUCCESS = typeof LOAD_EVENTINSTANCE_GROUP_SUCCESS;

export const LOAD_EVENTINSTANCE_GROUPS_SUCCESS = "LOAD_EVENTINSTANCE_GROUPS_SUCCESS";
export type LOAD_EVENTINSTANCE_GROUPS_SUCCESS = typeof LOAD_EVENTINSTANCE_GROUPS_SUCCESS;

export const UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS = "UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS";
export type UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS = typeof UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS;

export const LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS = "LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS";
export type LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS = typeof LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS;

export const LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS = "LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS";
export type LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS = typeof LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS;

export const UPDATE_ENQUIRY_STATUS_SUCCESS = "UPDATE_ENQUIRY_STATUS_SUCCESS";
export type UPDATE_ENQUIRY_STATUS_SUCCESS = typeof UPDATE_ENQUIRY_STATUS_SUCCESS;

export const CONVERT_TO_BOOKABLE_SUCCESS = "CONVERT_TO_BOOKABLE_SUCCESS";
export type CONVERT_TO_BOOKABLE_SUCCESS = typeof CONVERT_TO_BOOKABLE_SUCCESS;
