import axios from "axios";
import moment from "moment";
import { PoliceContract, PoliceContractOptions } from "./model";
import * as QueryString from "query-string";

export class PoliceContractsApi {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/policeContracts";
    }

    public async getAll(options: PoliceContractOptions): Promise<PoliceContract[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get<PoliceContract[]>(`${this.apiUrl}?${query}`);
        return this.parseModels(response.data);
    }

    public parseModels(models: PoliceContract[]): PoliceContract[] {
        return models.map(m => this.parseModel(m));
    }

    public parseModel(model: PoliceContract): PoliceContract {
        return {
            ...model,
            startDate: model.startDate && moment(model.startDate).utc().startOf("day"),
            endDate: model.endDate && moment(model.endDate).utc().startOf("day")
        };
    }
}
