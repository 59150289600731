import { Attendee } from "@common/crud/attendee";
import { attendeesSelector } from "@common/crud/attendee/selectors";
import { LookupResponse } from "@common/crud/common/LookupResponse";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import { DorsAttendanceStatusesEnum, LinkedBookingResponse } from "@common/crud/dorsBooking/model";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Confirm } from "semantic-ui-react";
import { EventInstance } from "../..";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

type Headers = "Attendee is currently booked" | "Attendee did not complete" | "Attendee offer is withdrawn" | "Attendee status is unknown";

export interface AttendeeInfoMessage {
    header: Headers;
    content: string | React.ReactNode;
    trigger: (eventInstance: EventInstance, attendee: Attendee, booking: LinkedBookingResponse) => boolean;
}

export const AttendeeInfoMessages: AttendeeInfoMessage[] = [
    {
        header: "Attendee is currently booked",
        content: <p className="modal-content">Please note this attendee is currently booked on a course that is either in progress or
            that has not been updated by the trainer. Please ensure you review the attendee/register accordingly before proceeding</p>,
        trigger: (event: EventInstance, attendee: Attendee) => {
            return event?.startDate?.clone().add(event?.startTime?.clone()) <= moment()
                && event?.attendeesRegisterDate === null && !attendee?.isBookingCanceled;
        }
    },
    {
        header: "Attendee did not complete",
        content: (<p className="modal-content">Please note this attendee has been marked as <strong>not completed</strong> on the register.
            Please ensure you review the attendee/register before proceeding.</p>),
        trigger: (event: EventInstance, attendee: Attendee) => {
            return event?.attendeesRegisterDate && attendee?.didAttend === false;
        }
    },
    {
        header: "Attendee offer is withdrawn",
        content: (<p className="modal-content">Please note this attendee's DORS status is offer withdrawn.</p>),
        trigger: (event: EventInstance, attendee: Attendee, booking: LinkedBookingResponse) => {
            return booking?.isOfferWithdrawn;
        }
    },
    {
        header: "Attendee status is unknown",
        content: (<p className="modal-content">Please note this attendee's DORS status is unknown.</p>),
        trigger: (event: EventInstance, attendee: Attendee, booking: LinkedBookingResponse) => {
            return booking?.dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet;
        }
    }
];

interface AttendeeInfoMessagesModalProps {
    attendee: Attendee;
    eventInstance: EventInstance;
    messages: AttendeeInfoMessage[];
    bookingId: string;
    lookupResponse: LookupResponse;
}

export const ShowAttendeeMessagesAgain = () => sessionStorage.setItem("seen-attendee-messages", null);

export const AttendeeInfoMessagesModal: React.FC<AttendeeInfoMessagesModalProps> = ({ attendee, eventInstance, messages, bookingId, lookupResponse }) => {

    const [messagesToShow, setMessagesToShow] = React.useState<AttendeeInfoMessage[]>([]);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [currentMessage, setCurrentMessage] = React.useState(0);
    const [booking, setBooking] = React.useState<LinkedBookingResponse>();
    const app = useSelector(appSelector);
    const isPoliceApp = app === Apps.Police;

    const messagesAlreadySeen = sessionStorage.getItem("seen-attendee-messages") === bookingId;

    React.useEffect(() => {
        if (messagesAlreadySeen) {
            return;
        }
        let triggeredMessages: AttendeeInfoMessage[] = [];
        messages.forEach(m => {
            if (m.trigger(eventInstance, attendee, booking)) {
                triggeredMessages = [...triggeredMessages, m];
            }
        });
        setMessagesToShow(triggeredMessages);
        setModalOpen(triggeredMessages.length > 0);
    }, [attendee, booking, eventInstance, messages, messagesAlreadySeen]);

    React.useEffect(() => {
        async function loadBooking() {
            const bookingApi = new DorsBookingApi();
            const result = await bookingApi.getLinkedBookings(bookingId);
            setBooking(result);
        };
        if (bookingId && lookupResponse && !isPoliceApp) {
            loadBooking();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId, lookupResponse]);

    const currentMessageDetails = messagesToShow[currentMessage];
    const header = messagesToShow.length > 0 ? `Message ${currentMessage + 1}/${messagesToShow.length}` : "";
    const content = messagesToShow.length > 0 ? <><h3 className="modal-content">{currentMessageDetails.header}</h3><p>{currentMessageDetails.content}</p></>
        : "";

    const cancelButtonProps = { content: `${currentMessage !== 0 ? "Previous" : ""}`, className: `${currentMessage === 0 ? "hidden" : ""}` };
    const confirmButtonProps = { content: `${currentMessage !== messagesToShow.length - 1 ? "Next" : "Continue"}` };

    function onConfirm() {
        if (currentMessage === messagesToShow.length - 1) {
            if (messagesToShow?.length > 0) {
                sessionStorage.setItem("seen-attendee-messages", bookingId);
            }
            setModalOpen(false);
        } else {
            setCurrentMessage(currentMessage + 1);
        }
    }

    function onCancel() {
        if (currentMessage !== 0) {
            setCurrentMessage(currentMessage - 1);
        }
    }

    // Checks for refreshes
    const attendees = useSelector(attendeesSelector);
    if (attendees?.length === 0) {
        ShowAttendeeMessagesAgain();
    }

    return (
        <Confirm
            open={modalOpen}
            header={header}
            content={content}
            cancelButton={cancelButtonProps}
            confirmButton={confirmButtonProps}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    );
};
