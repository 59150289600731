import { Payload } from "@neworbit/redux-helpers";
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";

import * as actions from "./actionTypes";
import { SettingsApi } from "./settingsApi";
import { ExternalLinks } from "./model";

export type Action =
    ({ type: actions.LOAD_EXTERNAL_LINKS_SUCCESS } & Payload<ExternalLinks>);

export const loadExternalLinksSuccess = (payload: ExternalLinks): Action => ({
    payload,
    type: actions.LOAD_EXTERNAL_LINKS_SUCCESS
});

export function loadExternalLinks() {
    return async (dispatch: Dispatch) => {
        const api = new SettingsApi();
        const result = await loadAndTrack(dispatch, "loadExternalLinks", api.getExternalLinks());
        dispatch(loadExternalLinksSuccess(result));
    };
}
