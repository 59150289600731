import * as React from "react";
import moment from "moment";
import { MuiDateField } from "@common/components/MuiDateField";

export interface StartDateInputProps {
    id: string;
    startDate: moment.Moment;
    onStartDateChange: (id: string, startDate: moment.Moment) => void;
}

export const StartDateInput: React.FC<StartDateInputProps> = ({ id, startDate, onStartDateChange }) => {
    const onChange = React.useCallback((value) => {
        const parsedDate = moment(value);
        if (parsedDate.isValid()) {
            onStartDateChange(id, parsedDate);
        }
    }, [id, onStartDateChange]);

    return (
        <MuiDateField
            value={startDate}
            onChange={onChange}
        />
    );
};
