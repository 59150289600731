/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Grid, Message, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { EventInstance } from "@common/crud/eventInstance/model";
import { FormikProps, useFormikContext } from "formik";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole, TtcPaymentHandlerRole } from "@common/auth/model";
import { BookingSummaryGridRows } from "../bookings/components/BookingSummaryGridRows";
import { Booking, BookingDetailFormikModel, MissingBookingProps } from "../bookings/models";
import { TAndCsConfirmationComponent } from "../bookings/components/TAndCsConfirmationComponent";
import { BookingDetailsTelephoneField } from "../bookings/components/BookingDetailsTelephoneField";
import { BookingDetailsEmailField } from "../bookings/components/BookingDetailsEmailField";
import { BookingDetailsCarTypeFields } from "../bookings/components/BookingDetailsCarTypeFields";
import { BookingSpecialRequirementsForm } from "../landing/components/BookingSpecialRequirementsForm";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { ChosenCourse } from "./ChosenCourse";
import { View } from "./ViewEnum";
import { BookingStages } from "./BookingStages";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { GenesysTokenProvider } from "@common/genesys/components/GenesysTokenProvider";
import { ManualPaymentModel } from "./models";
import { updateMissingFieldsAndGoToAutoPayment, updateMissingFieldsAndSkipAndBook } from "@booking/landing/actions";
import { bookingBasePathSelector } from "@booking/landing/selectors";
import { SkipAndBookModel } from "@booking/landing/model";
import { isEmptyObject } from "@common/helpers/is-empty-object";
import { useFlexiPay } from "./hooks/useFlexiPay";
import { Spinner } from "@common/global";
import { FlexiPayModal } from "./FlexiPayModal";
import { Seat } from "@booking/seat";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface BookingDetailsDesktopProps extends FormikProps<MissingBookingProps> {
    booking: Booking;
    hasSpecialRequirements: boolean;
    eventInstance: EventInstance;
    isClientAdvisor: boolean;
    isGenesysIvrEnabled: boolean;
    flexiPayModalOpen: boolean;
    handleContinueOnClick: () => void;
    setFlexiPayModalOpen: (open: boolean) => void;
    seatDiscount: number;
    specialIsOnlyOption: boolean;
    carFlag: boolean;
    seats: Seat[];
    organisation?: OrganisationDetailModel;
    allFieldsValid: boolean;
}

export const BookingDetailsDesktop: React.FC<BookingDetailsDesktopProps> = (props: BookingDetailsDesktopProps) => {
    const [t] = useTranslation("BookingDetails");
    const [showGenesysLogin, setShowGenesysLogin] = React.useState(false);
    const [genesysToken, setGenesysToken] = React.useState<string>(undefined);
    const [autoPaymentStarted, setAutoPaymentStarted] = React.useState(false);
    const [manualPaymentModel, setManualPaymentModel] = React.useState<ManualPaymentModel>(Object);
    const [autoPaymentLoading, setAutoPaymentLoading] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const path = useSelector(bookingBasePathSelector);
    const formikProps = useFormikContext<BookingDetailFormikModel>();

    const {
        booking,
        hasSpecialRequirements,
        eventInstance,
        handleContinueOnClick,
        isClientAdvisor,
        isGenesysIvrEnabled,
        flexiPayModalOpen,
        setFlexiPayModalOpen,
        seatDiscount,
        specialIsOnlyOption,
        carFlag,
        seats,
        organisation,
        allFieldsValid,
    } = props;

    function onTokenReceived(token: string) {
        setGenesysToken(token);

        if (autoPaymentStarted) {
            handleAutoPaymentClick(token);
        }
    }

    async function validateForm() {
        const errors = await formikProps.validateForm();
        formikProps.setFieldTouched("specialRequirements", true);
        formikProps.setFieldTouched("telephone", true);
        formikProps.setFieldTouched("email", true);
        formikProps.setFieldTouched("tAndCsConfirmed", true);
        if (carFlag) {
            formikProps.setFieldTouched("carType", true);
        }
        return errors;
    }

    async function onAutoPaymentButtonClick() {
        const errors = await validateForm();

        if (Object.values(errors ?? {}).map(v => Boolean(v)).length > 0) {
            return;
        }

        setAutoPaymentLoading(true);
        if (!genesysToken) {
            setShowGenesysLogin(true);
            setAutoPaymentStarted(true);
            return;
        }
        handleAutoPaymentClick(genesysToken);
    }

    function onManualPaymentChange(model: ManualPaymentModel) {
        setManualPaymentModel(model);
    }

    const bookingId = isClientAdvisor ? booking.id : null;
    const { nextPage, ...missingProps } = formikProps.values;

    async function handleAutoPaymentClick(token: string) {
        dispatch(updateMissingFieldsAndGoToAutoPayment(
            missingProps,
            manualPaymentModel,
            token,
            clearAutoPaymentLoading,
            flexiPayChecked,
            flexiPayFee,
            bookingId)
        );
    }

    function clearAutoPaymentLoading() {
        setAutoPaymentLoading(false);
    }

    async function handleBookingWithoutPayment() {
        const errors = await validateForm();

        if (Object.values(errors ?? {}).map(v => Boolean(v)).length > 0) {
            return;
        }

        const model: SkipAndBookModel = {
            bookingId: booking.id,
            seats,
            isWaivingRebookingFee: manualPaymentModel.waivePayment
        };

        dispatch(updateMissingFieldsAndSkipAndBook(missingProps, model, bookingId, path));
    }

    const eiStartsWithin14Days = moment(eventInstance?.deliveryDateTime).isSameOrBefore(moment().add(14, "days"));

    async function handleContinueButtonClick() {
        if (!eiStartsWithin14Days && eligibleForFlexiPay && !flexiPayModalOpen && !isClientAdvisor) {
            setFlexiPayModalOpen(true);
            await validateForm();
            if (carFlag) {
                formikProps.setFieldTouched("carType", true);
            }
            return;
        }

        continueToPayment(flexiPayChecked);
    }

    function continueToPayment(flexiPayPurchased: boolean) {
        setFlexiPayModalOpen(false);
        formikProps.setFieldValue("manualPaymentModel", manualPaymentModel);
        formikProps.setFieldValue("flexiPayPurchased", flexiPayPurchased);
        formikProps.setFieldValue("flexiPayFee", flexiPayFee);
        handleContinueOnClick();
    }

    function closeFlexiPayModal() {
        setFlexiPayModalOpen(false);
    }

    const { skipPayment, paymentValue, isValid, isPlanValid } = manualPaymentModel;
    const bookWithoutPayment = skipPayment || paymentValue === 0;

    const isDefaultObject = isEmptyObject(manualPaymentModel);
    const manualPaymentInvalid = !isDefaultObject && isClientAdvisor && !skipPayment && (!isValid || !isPlanValid);
    const paymentPlanSet = manualPaymentModel?.scheduledPaymentPlan?.length > 0;

    const paymentRequired = eventInstance.priceInPence > 0;
    const gotoPaymentMessage = isClientAdvisor ? "Go to manual payment" : t("GO_TO_PAYMENT");
    const continueButtonMessage = paymentRequired ? gotoPaymentMessage : t("COMPLETE_BOOKING");

    const {
        eligibleForFlexiPay,
        flexiPayFee,
        flexiPayChecked,
        setFlexiPayChecked
    } = useFlexiPay(booking.id, eventInstance.eventTypeId, organisation, booking.forceId, booking.isRebooking, booking.isCancelled, isClientAdvisor);

    React.useEffect(() => {
        if (flexiPayModalOpen && !allFieldsValid) {
            setFlexiPayModalOpen(false);
        }

    }, [allFieldsValid, flexiPayModalOpen, setFlexiPayModalOpen]);

    return (
        <>
            <FlexiPayModal
                open={flexiPayModalOpen && allFieldsValid}
                continueToPayment={continueToPayment}
                eventInstance={eventInstance}
                flexiPayFee={flexiPayFee}
                onClose={closeFlexiPayModal}
            />
            {showGenesysLogin &&
                <GenesysTokenProvider onTokenReceived={onTokenReceived} />
            }
            <BookingStages activeStage={2} paymentRequired={paymentRequired} />
            <Grid className="two-section-layout">
                <Grid.Column width={10} className="two-section-layout-left border-top">
                    <Container className="indented">
                        <BookingSummaryGridRows booking={booking} showPersonalDetails={false} />
                        <Grid.Row>
                            <h3 className="sub-heading">{t("CORRECT_CONTACT_DETAILS")}</h3>
                        </Grid.Row>
                        <BookingDetailsTelephoneField bookingId={booking.id} />
                        <BookingDetailsEmailField bookingId={booking.id} />
                        <BookingDetailsCarTypeFields eventInstance={eventInstance} specialIsOnlyOption={specialIsOnlyOption} carFlag={carFlag} />
                        <BookingSpecialRequirementsForm
                            hasUserFilterBySpecialRequirements={hasSpecialRequirements}
                            isDigitalCourse={eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital}
                            eventInstance={eventInstance}
                        />
                    </Container>
                </Grid.Column>
                <Grid.Column width={6} className="two-section-layout-right booking-details-column">
                    <Grid.Row>
                        <ChosenCourse
                            eventInstance={eventInstance}
                            fullDetails={false}
                            showLinks={true}
                            currentView={View.BookingDetails}
                            waivedRebookingFee={manualPaymentModel.waivePayment}
                            eligibleForFlexiPay={eligibleForFlexiPay}
                            flexiPayChecked={flexiPayChecked}
                            flexiPayFee={flexiPayFee}
                            onManualPaymentChange={onManualPaymentChange}
                            setFlexiPayChecked={setFlexiPayChecked}
                            seatDiscount={seatDiscount}
                            organisation={organisation}
                        />
                    </Grid.Row>
                    <Grid.Row className="tacs-row">
                        {!isClientAdvisor &&
                            <TAndCsConfirmationComponent
                                eventInstance={eventInstance}
                                organisation={organisation}
                            />
                        }

                        {allFieldsValid === false &&
                            <Message warning visible content={t("PLEASE_COMPLETE_ALL_SECTIONS")} />
                        }

                        {bookWithoutPayment === false && (
                            <>
                                <Authorize authorize={TtcClientAdvisorRole}>
                                    {isGenesysIvrEnabled && paymentPlanSet && (
                                        <Popup
                                            content={"Auto payment is not possible if scheduled payment plan set"}
                                            trigger={
                                                <div>
                                                    <Button disabled className="continue-button" content="Start Auto Payment" />
                                                </div>
                                            }
                                        />
                                    )}
                                    {isGenesysIvrEnabled && !paymentPlanSet && (
                                        <Spinner active={autoPaymentLoading}>
                                            <Button
                                                className={"continue-button"}
                                                content={"Start Auto Payment"}
                                                onClick={onAutoPaymentButtonClick}
                                                disabled={manualPaymentInvalid || !allFieldsValid || autoPaymentLoading || carFlag === null
                                                    || formikProps.isSubmitting}
                                            />
                                        </Spinner>
                                    )}
                                </Authorize>
                                <Button
                                    className={"continue-button"}
                                    content={continueButtonMessage}
                                    onClick={handleContinueButtonClick}
                                    disabled={manualPaymentInvalid || !allFieldsValid || autoPaymentLoading
                                        || carFlag === null || formikProps.isSubmitting}
                                />
                            </>
                        )}
                        {bookWithoutPayment &&
                            <Authorize authorize={TtcPaymentHandlerRole}>
                                <Button
                                    className={"manual-payment-button"}
                                    content={t("COMPLETE_BOOKING")}
                                    onClick={handleBookingWithoutPayment}
                                    disabled={manualPaymentInvalid || !allFieldsValid || autoPaymentLoading || carFlag === null || formikProps.isSubmitting}
                                />
                            </Authorize>
                        }
                    </Grid.Row>
                </Grid.Column>
            </Grid>
            <PrivacyPolicy />
        </>
    );
};
