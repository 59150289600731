import {
    Criterium, PassFailResultEnum,
    TrainerMonitoringReportCreateEditModel,
    TrainerMonitoringReportSection,
    TrainerMonitoringReportStatusEnum
} from "@common/crud/trainer/trainerMonitoringModel";
import {
    IQAReviewSectionSeqNum,
    TrainersCommentsSectionSeqNum
} from "@common/crud/eventInstance/components/monitoring/MonitoringReportingConstants";
import { FormValidationResult } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { AllSchemeMonitoringForm } from "./questions/AllSchemeMonitoringForm";
import { NSACQuestions } from "@common/crud/eventInstance/components/monitoring/questions/NSACQuestions";
import { FormTypeEnum, FormTypeName } from "@common/crud/eventType/model";
import { WDUQuestions } from "@common/crud/eventInstance/components/monitoring/questions/WDUQuestions";
import { NMACQuestions } from "@common/crud/eventInstance/components/monitoring/questions/NMACQuestions";
import { SCDQuestions } from "@common/crud/eventInstance/components/monitoring/questions/SCDQuestions";
import {
    PreCourseSectionClassroom
} from "@common/crud/eventInstance/components/monitoring/questions/PreCourseSectionClassroom";
import {
    TrainerAttributesSectionClassroom
} from "@common/crud/eventInstance/components/monitoring/questions/TrainerAttributesSectionClassroom";
import {
    IntroductionSectionClassroom
} from "@common/crud/eventInstance/components/monitoring/questions/IntroductionSectionClassroom";

export function validateMonitoringForm(model: TrainerMonitoringReportCreateEditModel): FormValidationResult[] {
    const validateSummary = (criterium: Criterium[]): boolean => {
        const testResult = criterium.find(ss => (ss.response === undefined || ss.response === "") && !ss.summarySubSection?.length);
        return testResult === undefined;
    };
    const validationResult: FormValidationResult[] = [];
    if (model.status === TrainerMonitoringReportStatusEnum.Draft) {
        const contextSections = model.sections.filter(s => s.seqNum < IQAReviewSectionSeqNum);
        contextSections.forEach(data => {
            validationResult.push({ seqNum: data.seqNum, validResult: validateSummary(data.summaryCriteria) });
        });
    } else if (model.status === TrainerMonitoringReportStatusEnum.IqaReview) {
        const contextSections = model.sections.filter(s => s.seqNum === IQAReviewSectionSeqNum);
        contextSections.forEach(data => {
            validationResult.push({ seqNum: data.seqNum, validResult: validateSummary(data.summaryCriteria) });
        });
    } else if (model.status === TrainerMonitoringReportStatusEnum.Submitted) {
        const contextSections = model.sections.filter(s => s.seqNum === TrainersCommentsSectionSeqNum);
        contextSections.forEach(data => {
            validationResult.push({ seqNum: data.seqNum, validResult: validateSummary(data.summaryCriteria) });
        });
    }

    return validationResult;
}

export function getFailedSections(reportFormDataModel: TrainerMonitoringReportCreateEditModel) {
    const isSectionUnsatisfactory = (section: TrainerMonitoringReportSection): TrainerMonitoringReportSection => {
        if (section.subSections?.filter(ss => ss.unsatisfactory === true).length > 0) {
            return section;
        }
        return undefined;
    };

    const sections = reportFormDataModel?.sections;
    const fails: TrainerMonitoringReportSection[] = [];
    sections.forEach(s => {
        if (isSectionUnsatisfactory(s)) {
            fails.push(s);
        }
    });

    return fails;
}

export function getPassFailResult(fails: TrainerMonitoringReportSection[]): PassFailResultEnum {
    return fails?.length === 0 ?
        PassFailResultEnum.Level1 :
        fails?.length === 1 ?
            PassFailResultEnum.Level2 :
            PassFailResultEnum.Level3;
}

export function getQuestions(formType: string, isDigital: boolean) {

    function getQuestionForType() {
        switch (formType) {
            case FormTypeName[FormTypeEnum.NSAC]:
                return NSACQuestions;

            case FormTypeName[FormTypeEnum.SCD]:
                return SCDQuestions;

            case FormTypeName[FormTypeEnum.NMAC]:
                return NMACQuestions;

            case FormTypeName[FormTypeEnum.WDU]:
                return WDUQuestions;

            case FormTypeName[FormTypeEnum.AllScheme]:
                return AllSchemeMonitoringForm;

            default:
                return null;
        }
    }

    const questions = getQuestionForType();

    // If classroom course, replace the first 3 sections with classroom sections
    // The first 3 sections are the same in all types of Questions
    if (questions && !isDigital && formType !== FormTypeName[FormTypeEnum.AllScheme]) {
        questions.sections.splice(0, 3,
            PreCourseSectionClassroom,
            TrainerAttributesSectionClassroom,
            IntroductionSectionClassroom
        );
    }

    return questions;

}
