import axios from "axios";
import * as QueryString from "query-string";

import {
    TrainerAttributeDefinitionListModel,
    TrainerAttributeDefinitionDetailModel,
    TrainerAttributeDefinitionCreateModel,
    TrainerAttributeDefinitionEditModel,
    SearchOptions
} from "./model";

export class TrainerAttributeDefinitionApi {

    private readonly apiUrl = "/api/trainerattributedefinition";

    public async getAll(options?: SearchOptions): Promise<TrainerAttributeDefinitionListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as TrainerAttributeDefinitionListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<TrainerAttributeDefinitionDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as TrainerAttributeDefinitionDetailModel;
        return this.parseModel(model);
    }

    public async save(trainerAttribute: TrainerAttributeDefinitionEditModel): Promise<TrainerAttributeDefinitionDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${trainerAttribute.id}`, trainerAttribute);
        const model = response.data as TrainerAttributeDefinitionDetailModel;
        return this.parseModel(model);
    }

    public async create(trainerAttribute: TrainerAttributeDefinitionCreateModel): Promise<TrainerAttributeDefinitionDetailModel> {

        const response = await axios.post(this.apiUrl, trainerAttribute);
        const model = response.data as TrainerAttributeDefinitionDetailModel;
        return this.parseModel(model);
    }

    public delete(id: string): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public parseModel(model: TrainerAttributeDefinitionDetailModel): TrainerAttributeDefinitionDetailModel {
        return {
            ...model
        };
    }
}
