import * as React from "react";
import { Button, Checkbox, CheckboxProps, Form, Modal, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { sendReminders } from "../actions";
import { RecipientType, ReminderType } from "../model";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

export interface SendOpenBookingReminderModalProps {
    orderId: string;
    eventInstanceId: string;
    canSend: boolean;
}

export const SendOpenBookingReminderModal: React.FC<SendOpenBookingReminderModalProps> = ({ orderId, eventInstanceId, canSend }) => {
    const businessLineType = useSelector(businessLineTypeSelector);

    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [reminderType, setReminderType] = React.useState<ReminderType>(undefined);
    const [recipientTypes, setRecipientTypes] = React.useState<RecipientType[]>([]);

    const dispatch = useDispatch();

    const setOption = React.useCallback((e: any, { value }: CheckboxProps) => setReminderType(value as ReminderType), []);

    const onToggleRecipientBooker = React.useCallback((e, { checked }) => {
        if (checked) {
            setRecipientTypes([...recipientTypes, RecipientType.Booker]);
        } else {
            setRecipientTypes(recipientTypes.filter(x => x !== RecipientType.Booker));
        }
    }, [recipientTypes]);

    const onToggleRecipientDelegates = React.useCallback((e, { checked }) => {
        if (checked) {
            setRecipientTypes([...recipientTypes, RecipientType.Delegates]);
        } else {
            setRecipientTypes(recipientTypes.filter(x => x !== RecipientType.Delegates));
        }
    }, [recipientTypes]);

    const openCloseAction = React.useCallback(() => {
        setReminderType(undefined);
        setRecipientTypes([]);
        setSubmitted(false);
        setOpen(!open);
    }, [open]);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(sendReminders({
            orderId,
            eventInstanceId,
            reminderType,
            recipientTypes,
            businessLineType
        }));
        setOpen(false);
    }, [dispatch, eventInstanceId, orderId, recipientTypes, reminderType, businessLineType]);

    return (
        <>
            <Button disabled={!canSend} onClick={openCloseAction}>SEND REMINDER EMAIL</Button>
            <Modal open={open}>
                <Modal.Header>
                    Send Reminder
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        {businessLineType === BusinessLineType.Corporate && (
                            <>
                                <Form.Field>
                                    Please confirm which reminder emails you wish to send
                                </Form.Field>
                                {!recipientTypes.some(x => x === RecipientType.Delegates) && (
                                    <Form.Field>
                                        <Radio
                                            label="First Reminder"
                                            name="reminderType"
                                            value={ReminderType.One}
                                            checked={reminderType === ReminderType.One}
                                            onChange={setOption}
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <Radio
                                        label="Second Reminder"
                                        name="reminderType"
                                        value={ReminderType.Two}
                                        checked={reminderType === ReminderType.Two}
                                        onChange={setOption}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label="Third Reminder"
                                        name="reminderType"
                                        value={ReminderType.Three}
                                        checked={reminderType === ReminderType.Three}
                                        onChange={setOption}
                                    />
                                </Form.Field>
                            </>
                        )}
                        <Form.Field>
                            Please confirm who should receive the reminders
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label="Booker"
                                type="checkbox"
                                checked={recipientTypes.some(x => x === RecipientType.Booker)}
                                onChange={onToggleRecipientBooker}
                            />
                        </Form.Field>
                        {(reminderType !== ReminderType.One) && (
                            <Form.Field>
                                <Checkbox
                                    label="Delegates"
                                    type="checkbox"
                                    checked={recipientTypes.some(x => x === RecipientType.Delegates)}
                                    onChange={onToggleRecipientDelegates}
                                />
                            </Form.Field>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        loading={submitted}
                        disabled={(businessLineType === BusinessLineType.Corporate && reminderType === undefined) || recipientTypes.length === 0}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
