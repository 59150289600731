import { Venue } from "@common/crud/venue";

import * as actions from "./actionTypes";
import { RebookVenueAction } from "./actions";

export function reducer(state: Venue = null, action: RebookVenueAction): Venue {
    switch (action.type) {
        case actions.LOAD_REBOOK_VENUE_DETAIL_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
