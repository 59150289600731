import * as React from "react";
import { Area } from "../model";
import { CommonBase } from "@common/crud/eventInstance/components/CommonBase";
import { Fragment } from "redux-little-router";
import {
    RegionalCoordinatorCalendarBase
} from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/RegionalCoordinatorCalendarBase";
import { StandbyTrainersCalendarBase } from "./standbyTrainersCalendar/StandbyTrainersCalendarBase";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

export const Base: React.FC<{ area: Area; workflowType?: WorkflowTypeEnum }> = ({ area, workflowType }) => {
    return (
        <>
            <Fragment forRoute="/team-support-calendar">
                <RegionalCoordinatorCalendarBase />
            </Fragment>
            <Fragment forRoute="/standby-trainer-calendar">
                <StandbyTrainersCalendarBase />
            </Fragment>
            <Fragment forRoute="/schedule-drink-drive">
                <CommonBase area={area} fragmentFor={"ddrs"}  />
            </Fragment>
            <Fragment forRoute="/schedule-corp">
                <CommonBase area={area} fragmentFor={"corp"}  />
            </Fragment>
            <Fragment forRoute="/schedule-construction">
                <CommonBase area={area} fragmentFor={"construction"}  />
            </Fragment>
            <CommonBase area={area} fragmentFor={"dors"} workflowType={workflowType}  />
        </>
    );
};
