import * as React from "react";
import { Modal } from "@common/components";
import { Button, Grid, Input, InputOnChangeData } from "semantic-ui-react";
import { v4 } from "uuid";
import { EventTypeFile } from "../../model";
import moment from "moment";
import { MuiDateField } from "@common/components/MuiDateField";
import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";

import "./FileModal.scss";

export interface FileModalProps {
    open: boolean;
    file?: EventTypeFile;
    close: () => void;
    save: (file: EventTypeFile) => void;
}

export const FileModal: React.FC<FileModalProps> = ({ open, file, close, save }) => {
    const [documentName, setDocumentName] = React.useState<string>("");
    const [documentDescription, setDocumentDescription] = React.useState<string>("");
    const [uploadedFiles, setUploadedFiles] = React.useState<FileList>(undefined);
    const [startDate, setStartDate] = React.useState<moment.Moment>(undefined);
    const [startDateValid, setStartDateValid] = React.useState<boolean>(true);
    const [endDate, setEndDate] = React.useState<moment.Moment>(undefined);
    const [endDateValid, setEndDateValid] = React.useState<boolean>(true);
    const [saving, setSaving] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (open) {
            setDocumentName(file?.documentName || "");
            setDocumentDescription(file?.documentDescription || "");
            setUploadedFiles(undefined);
            setStartDate(file?.startDate || undefined);
            setStartDateValid(true);
            setEndDate(file?.endDate || undefined);
            setEndDateValid(true);
        }
    }, [open, file]);

    const onDocumentNameChange = React.useCallback((_, data: InputOnChangeData) => {
        setDocumentName(data.value);
    }, []);

    const onDocumentDescriptionChange = React.useCallback((_, data: InputOnChangeData) => {
        setDocumentDescription(data.value);
    }, []);

    const onFileChange = React.useCallback((uploadedFile: FileList) => {
        setUploadedFiles(uploadedFile);
    }, []);

    const onStartDateChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        setStartDate(value);
        setStartDateValid(valid);
    }, []);

    const onEndDateChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        setEndDate(value);
        setEndDateValid(valid);
    }, []);

    const canBeSaved = React.useMemo(() => documentName && documentDescription && startDate && startDateValid && endDateValid
        && (file?.blobName || (uploadedFiles?.length > 0 ?? false)),
    [documentName, documentDescription, startDate, startDateValid, endDateValid, uploadedFiles, file]);

    const onSaveClick = React.useCallback(async () => {
        if (canBeSaved) {
            setSaving(true);
            try {
                await save({
                    id: file?.id || v4(),
                    blobName: file?.blobName,
                    uploadedFile: uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles[0] : undefined,
                    documentName,
                    documentDescription,
                    startDate,
                    endDate
                });
            } finally {
                setSaving(false);
            }
        }
    }, [canBeSaved, save, file, documentName, documentDescription, startDate, endDate, uploadedFiles]);

    const uploadButton = (<FileUploadInput
        fileList={uploadedFiles}
        uploadLabel={file ? "CHANGE FILE" : "CHOOSE FILE"}
        multiple={false}
        onChange={onFileChange}
    />);

    return (
        <Modal open={open}>
            <Modal.Header>
                {file ? "Edit File" : "Add File"}
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16} className="full-width-input">
                            <Input
                                type="text"
                                placeholder="Document Name"
                                value={documentName}
                                required
                                onChange={onDocumentNameChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} className="full-width-input">
                            <Input
                                type="text"
                                placeholder="Document Description"
                                value={documentDescription}
                                required
                                onChange={onDocumentDescriptionChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {file?.fileName && (
                        <Grid.Row>
                            <Grid.Column width={16} className="full-width-input">
                                <span>
                                    {`File that's currently uploaded: ${file.fileName}`}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Column width={16} className="full-width-input">
                            {(uploadedFiles && uploadedFiles.length > 0) ? <Grid className="file-upload-grid">
                                <Grid.Row>
                                    <span>
                                        {`File to be uploaded: ${uploadedFiles[0].name}`}
                                    </span>
                                </Grid.Row>
                                <Grid.Row>
                                    {uploadButton}
                                </Grid.Row>
                            </Grid> : uploadButton}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} className="full-width-input">
                            <MuiDateField
                                placeholder="Start Date"
                                value={startDate}
                                required
                                onChange={onStartDateChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} className="full-width-input">
                            <MuiDateField
                                placeholder="End Date"
                                value={endDate}
                                onChange={onEndDateChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} className="cancel-action">
                    Cancel
                </Button>
                <Button
                    onClick={onSaveClick}
                    disabled={!canBeSaved || saving}
                    positive
                    content="Save"
                />
            </Modal.Actions>
        </Modal>
    );
};
