import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

export interface GridDetailProps {
 headerLabels: string[];
    children: JSX.Element[];
    labelWidths?: SemanticWIDTHS[];
}

export const GridDetail: React.SFC<GridDetailProps> = ({ headerLabels, children, labelWidths }) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3} as="h4" />
                {headerLabels.map((header,index) =>
                {
                    const headerWidth =labelWidths.length > 0 ? labelWidths[index] : Math.floor(12 / headerLabels.length) as SemanticWIDTHS;
                    return (<Grid.Column key={index} width={headerWidth} as="h4">
                        {header}
                    </Grid.Column>);}
                )}
            </Grid.Row>
            <>{children}</>
        </Grid>
    );
};
