import { ApplicationState } from "@booking/applicationState";
import { useTypeSafeTranslation } from "@booking/i18n/resources/useTypeSafeTranslation";
import { BookingPaymentsInfo, ScheduledPaymentStatus } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useBookingStatus } from "../hooks/useBookingStatus";
import { bookingIdSelector } from "@booking/landing/selectors";
import classes from "./FinanceDetails.module.scss";
import { isUserClientAdvisor } from "@common/crud/common/selectors";

export function FinanceDetails() {
    const booking = useSelector((state: ApplicationState) => state.booking);
    const showAmountAttendeeIsDue = useSelector(isUserClientAdvisor);
    const t = useTypeSafeTranslation("FinanceDetails");
    const { isFreshBooking, isRebooking, isCancelled } = useBookingStatus(booking.id);
    const { startPaymentProcess } = useSelector((state: ApplicationState) => state);
    const bookingId = useSelector((state: ApplicationState) => bookingIdSelector(state));

    const getActiveScheduledPaymentsPlanValue = () => {
        if (bookingPaymentsInfo?.scheduledPaymentPlans?.length === 0 ?? false) {
            return undefined;
        }

        return bookingPaymentsInfo?.scheduledPaymentPlans?.reduce((sum, curr) => {
            if (curr.status === ScheduledPaymentStatus.Awaits) {
                sum += (curr.amountInPence / 100);
            }

            return sum;
        }, 0);
    };

    const [bookingPaymentsInfo, setBookingPaymentsInfo] = React.useState<BookingPaymentsInfo>(null);
    const payAmount = bookingPaymentsInfo?.mostRecentPaymentValue;
    const amountDue = bookingPaymentsInfo?.totalAmountDue;
    const scheduledPaymentAmount= getActiveScheduledPaymentsPlanValue();
    const flexiPayPurchased = !isRebooking && !isCancelled && bookingPaymentsInfo && bookingPaymentsInfo.flexiPayFees > 0;
    const flexiPayFee = flexiPayPurchased ? bookingPaymentsInfo.flexiPayFees / 100 : null;
    const showAmountDue = amountDue >= 0;
    const amount = amountDue !== 0 ? (amountDue / 100).toFixed(2) : amountDue.toFixed(2);

    useEffect(() => {
        async function setPaymentInfo() {
            if (startPaymentProcess.correlationId !== null) {
                setBookingPaymentsInfo({
                    amountPaid: startPaymentProcess.amount,
                    fees: startPaymentProcess.fee,
                    mostRecentPaymentValue: startPaymentProcess.amount,
                    scheduledPaymentPlans: startPaymentProcess.plan?.map((plan, idx) =>
                        ({ chargeDate: plan.chargeDate, amountInPence: plan.amount * 100, id: idx.toString(), status: ScheduledPaymentStatus.Awaits })),
                    totalAmountDue: startPaymentProcess.fullEventInstancePrice - startPaymentProcess.amount,
                    flexiPayFees: !isRebooking && !isCancelled && startPaymentProcess.fee > 0 ? startPaymentProcess.fee : 0,
                    startPaymentProcessMultiPartModels: startPaymentProcess.startPaymentProcessMultiPartModels,
                    seatPrice: null
                });
            } else if (!bookingPaymentsInfo) {
                const paymentApi = new PaymentApi(bookingId);
                const response = await paymentApi.getBookingPaymentsBalance();
                setBookingPaymentsInfo(response);
            }
        }

        setPaymentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
        <div className={classes.financeDetailsTitle}>{t("PAYMENT_DETAILS_TITLE")}</div>
        {isFreshBooking &&
            <div className={classes.detailsField}>
                <span className={classes.detailsFieldName}>{t("AMOUNT_PAID")}&nbsp;</span>£{payAmount && (payAmount / 100).toFixed(2)}
            </div>}
        {showAmountAttendeeIsDue && amountDue < 0 &&
            <div className={classes.detailsField}>
                <span className={classes.detailsFieldName}>{t("AMOUNT_DUE")}&nbsp;</span>
                £{Math.abs(amountDue / 100).toFixed(2)}
            </div>}
        {showAmountDue &&
            <div className={classes.detailsField}>
                <span className={classes.detailsFieldName}>{t("OUTSTANDING_BALANCE")}&nbsp;</span>
                £{amount}
            </div>}
        {!booking.waivedRebookingFee && scheduledPaymentAmount > 0 &&
            <div className={classes.detailsField}>
                <span className={classes.detailsFieldName}>{t("SCHEDULED_PAYMENT")}&nbsp;</span>
                £{scheduledPaymentAmount.toFixed(2)}
            </div>}
        {flexiPayPurchased && flexiPayFee && !isRebooking &&
            <div className={classes.detailsField}>
                <span className={classes.detailsFieldName}>{t("FLEXI_PAY_CONFIRMATION")}&nbsp;</span>
                £{flexiPayFee.toFixed(2)}
            </div>}
    </>);
}
