import moment from "moment";
import { Address } from "@common/crud/common";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { LanguageEnum, OtherTrainerTypeCategoryEnum, TrainerType } from "@common/crud/eventInstance/model";

export interface InvoiceListModel {
    id: string;
    invoiceNumber: number;
    invoiceDate: moment.Moment;
    invoiceTotal: number;
    dateProcessed: moment.Moment;
    dateSubmitted: moment.Moment;
    vatPercentage?: number;
    trainerId: string;
    trainerName: string;
    path?: string;
    isCancelled?: boolean;
}

export interface InvoiceDetailModel {
    id: string;
    invoiceNumber: string;
    trainerName: string;
    invoiceDate: moment.Moment;
    invoiceTotal: number;
    dateProcessed: moment.Moment;
    invoiceLineItems: TrainerInvoiceLineItem[];
    vatPercentage?: number;
    vatNumber: string;
    companyName: string;
    companyAddress: Address;
    isCancelled: boolean;
    invoiceBonusLineItems: TrainerInvoiceBonusLineItem[];
    invoiceSubcontractedToLineItems: TrainerInvoiceSubcontractedLineItem[];
    invoiceSubcontractedFromLineItems: TrainerInvoiceSubcontractedLineItem[];
}

export interface InvoicePreviewModel {
    invoiceDate: moment.Moment;
    invoiceTotal: number;
    invoiceLineItems: TrainerInvoiceLineItem[];
    vatPercentage?: number;
    invoiceBonusLineItems: TrainerInvoiceBonusLineItem[];
    invoiceSubcontractedToLineItems: TrainerInvoiceSubcontractedLineItem[];
    invoiceSubcontractedFromLineItems: TrainerInvoiceSubcontractedLineItem[];
}

export interface TrainerInvoiceLineItem {
    id: string;
    path?: string;
    courseDate: moment.Moment;
    courseTime: moment.Duration;
    courseVenue: string;
    courseLanguage: LanguageEnum;
    trainerTypes: TrainerType[];
    courseFee: number;
    courseSundries: number;
    courseUpliftFee: number;
    eventInstanceId: string;
    eventTypeName: string;
    dayPartInTheGroup: number;
    workflowType: WorkflowTypeEnum;
    otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum;
}

export enum TrainerInvoiceBonusTypeEnum {
    None,
    Uplift,
    TieredBonus,
}

export enum InvoiceFormat{
    OldUpliftFees,
    NewCombinedBonuses
}

export const TrainerInvoiceBonusType = {
    [TrainerInvoiceBonusTypeEnum.None]: "Error",
    [TrainerInvoiceBonusTypeEnum.Uplift]: "Course Uplift",
    [TrainerInvoiceBonusTypeEnum.TieredBonus]: "Tier Bonus",
};

export interface TrainerInvoiceBonusLineItem {
    id: string;
    fee: number;
    date: moment.Moment;
    type: TrainerInvoiceBonusTypeEnum;
    totalCourses: number;
    tierNumber: number;
}

export interface TrainerInvoiceSubcontractedLineItem extends TrainerInvoiceLineItem {
    subcontractedTrainerName: string;
}
