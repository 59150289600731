import { MxEmailCheckResult } from "@common/crud/email/model";
import axios from "axios";

export class EmailApi {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/email";
    }

    public async getEmailUri(messageId: string): Promise<{ success: boolean; message: string; result: string }> {
        const response = await axios.get(`${this.apiUrl}/${messageId}`);

        return response.data;
    }

    public async getEmailTemplate(templateId: number): Promise<string> {
        const response = await axios.get(`${this.apiUrl}/template/${templateId}`);

        return response.data;
    }

    public async checkEmail(email: string): Promise<MxEmailCheckResult> {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get<MxEmailCheckResult>(`${this.apiUrl}/checkemail?email=${encodedEmail}`);
        return response.data;
    }
}
