import { mixed, object, string } from "yup";
import { RebookSignInStatus, RebookingSignInTranslationKeys } from "./model";
import { UseTranslationResponse } from "react-i18next";
import { i18n } from "i18next";

const digitsOnly = (value: string) => /^\d+$/.test(value);

export const formSchema =(translationConfig: UseTranslationResponse<string, undefined>) => {

    const [t]: [t: (key: RebookingSignInTranslationKeys) => string, i18n: i18n, ready: boolean] = translationConfig;

    return object({
        email: string().email(t("EMAIL_INVALID_TEXT")).required(t("EMAIL_REQUIRED_TEXT")),
        bookingReference: string().required(t("BOOKING_REFERENCE_REQUIRED_TEXT"))
            .test("Digits only", t("BOOKING_REFERENCE_INVALID_TEXT"), digitsOnly),
        postcode: string().required(t("POSTCODE_INVALID_TEXT")).matches(/^(?!\s)[A-Za-z0-9 ]*$/, t("POSTCODE_INVALID_TEXT")),
        verificationStatus: mixed<RebookSignInStatus>().oneOf(["initial" , "providingPassword"]),
        code: string().when("verificationStatus",
            {
                is: (val: RebookSignInStatus) => val !== "initial",
                then: (schema) => schema
                    .matches(/\b(?:\d[ ]*?){6}\b/, t("CODE_INVALID_TEXT"))
                    .required(t("CODE_INVALID_TEXT")),
            })
    }).required();};

