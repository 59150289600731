import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { DropdownItemProps, Label } from "semantic-ui-react";
import { EventInstanceEditModel } from "../model";
import moment from "moment";
import { StartDateEdit } from "./StartDateEdit";
import { supportsOnRoadReport, workflowTypeSupportsCertificate, eventTypeIsMultiDay } from "@common/global/CommonHelpers";
import { EventType } from "@common/crud/eventType";
import { MultiDayEventTypePartEdit } from "./MultiDayEventTypePartEdit";
import { BankHoliday } from "@common/bankHolidays/model";
import { EventTypePart } from "@common/crud/eventType/model";

export interface ClosedCorporateEditProps {
    showErrors: boolean;
    values: EventInstanceEditModel;
    venueOptions: DropdownItemProps[];
    endTimeDisplay: string;
    selectedDayIsBankHoliday: boolean;
    eventInstanceClosed: boolean;
    eventType: EventType;
    bankHolidays: BankHoliday[];
    updateVenue: (value: string, valid: boolean) => void;
    updateStartDate: (value: moment.Moment, valid: boolean) => void;
    updateCertificateRequired: (value: boolean, valid: boolean) => void;
    updateOnRoadReportRequired: (value: boolean, valid: boolean) => void;
    updateEventTypeParts: (eventTypeParts: Dictionary<EventTypePart>) => void;
    updateStartDates?:  ( value: Dictionary<moment.Moment>, valid: boolean) => void;
    updateSpecificStartDate?: (day: string, value: moment.Moment, valid: boolean) => void;
}

export const ClosedCorporateEdit: React.FC<React.PropsWithChildren<ClosedCorporateEditProps>> = ({
    showErrors,
    values,
    venueOptions,
    endTimeDisplay,
    selectedDayIsBankHoliday,
    eventInstanceClosed,
    eventType,
    bankHolidays,
    updateVenue,
    updateStartDate,
    updateCertificateRequired,
    updateOnRoadReportRequired,
    updateEventTypeParts,
    updateStartDates,
    updateSpecificStartDate,
    children,
}) => {

    return (
        <>
            {workflowTypeSupportsCertificate(values.workflowType) &&
                <Input.Checkbox
                    label="Certificate Required?"
                    value={Boolean(values.certificateRequired)}
                    onChange={updateCertificateRequired}
                    toggle
                />
            }
            {(!eventInstanceClosed && supportsOnRoadReport(values.workflowType, values.productCategory)) &&
                <Input.Checkbox
                    label="On Road Report Required?"
                    value={Boolean(values.onRoadReportRequired)}
                    onChange={updateOnRoadReportRequired}
                    toggle
                />
            }
            <Input.Dropdown
                label="Venue"
                placeholder="Select Venue"
                showErrors={showErrors}
                value={values.venueId}
                options={venueOptions}
                onChange={updateVenue}
                dynamicOptions
                search
                required
            />
            {!eventTypeIsMultiDay(eventType) && (
                <>
                    <StartDateEdit
                        startDate={values.startDate}
                        showErrors={showErrors}
                        updateStartDate={updateStartDate}
                        selectedDayIsBankHoliday={selectedDayIsBankHoliday}
                    />
                    {children}
                    <Label id="endtime">
                            Event Ends at: {endTimeDisplay}
                    </Label>
                </>
            )}
            {eventTypeIsMultiDay(eventType) && (
                <div className="day-part-edit">
                    <MultiDayEventTypePartEdit
                        groupItemStartDates={values.groupItemStartDates}
                        bankHolidays={bankHolidays}
                        firstStartDate={values.startDate}
                        deliveryType={values.eventInstanceDeliveryType}
                        updateEventTypeParts={updateEventTypeParts}
                        eventTypeParts={values.eventTypeParts}
                        updateStartDates={updateStartDates}
                        updateSpecificStartDate={updateSpecificStartDate}
                        eventType={eventType}
                    />
                </div>
            )}
        </>
    );
};
