import * as React from "react";
import { Tab, Grid, Popup, Icon, Container, Dropdown, InputOnChangeData, Message, Segment, Divider } from "semantic-ui-react";
import { CoreDetailProps } from "./Detail";
import { AddressDisplay } from "./AddressDisplay";
import { DelegateAttendeeModel, DelegateComparisonTypeEnum, DrivingLicenceCountry, DrivingLicenceCountryEnum, VocationalLicenceCategory } from "../model";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import moment from "moment";
import { CancelCorporateBookingModal } from "@common/crud/attendee/components/details/CancelCorporateBookingModal";
import { parse } from "query-string";
import { Link } from "redux-little-router";
import { getBookingItemState } from "../../eventInstance/helpers";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { ProductCategoryEnum } from "@common/crud/eventType/model";
import { AttendeeIdType, AttendeeTitle } from "@common/crud/attendee/model";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export const DetailsTab: React.FC<CoreDetailProps> = ({ delegate, attendeeId }) => {
    const [visibleAttendeeId, setVisibleAttendeeId] = React.useState<string>();
    const [visibleCourse, setVisibleCourse] = React.useState<DelegateAttendeeModel>();
    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);

    const eventInstance = useSelector(eventInstanceSelector);
    const isAps = React.useMemo(() => eventInstance?.productCategory === ProductCategoryEnum.StandardAps, [eventInstance?.productCategory]);
    const isCitb = React.useMemo(() => eventInstance?.productCategory === ProductCategoryEnum.StandardCitb, [eventInstance?.productCategory]);
    const isDigital = React.useMemo(() => eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital, [eventInstance?.eventInstanceDeliveryType]);

    const locationAttendeeId = React.useMemo(() => {
        const locationSearch = parse(location.search);
        return locationSearch.attendeeId;
    }, []);

    const filteredCoursesInOrder = React.useMemo(() => {
        // Show only latest attendee for each event instance
        const filteredCoursesLookup =
            (delegate.delegateAttendees || []).reduce((lookup: Record<string, DelegateAttendeeModel>, item) => {
                if (!lookup[item.eventInstanceId] || item.addedOn.isAfter(lookup[item.eventInstanceId].addedOn)) {
                    lookup[item.eventInstanceId] = item;
                }
                return lookup;
            }, {});

        // Still if url directs to specific older version, include it in list (alongside current version if it exists)
        const courseFromAttendee = (locationAttendeeId || attendeeId)
            ? (delegate?.delegateAttendees?.filter(a => a.attendeeId === (locationAttendeeId || attendeeId))[0])
            : undefined;

        let filteredCourses: DelegateAttendeeModel[] = [];
        if (courseFromAttendee) {
            if (!filteredCoursesLookup[courseFromAttendee.eventInstanceId]) {
                filteredCoursesLookup[courseFromAttendee.eventInstanceId] = courseFromAttendee;
                filteredCourses = Object.values(filteredCoursesLookup);
            } else {
                filteredCourses = Object.values(filteredCoursesLookup);
                if (filteredCoursesLookup[courseFromAttendee.eventInstanceId].attendeeId !== courseFromAttendee.attendeeId) {
                    filteredCourses = [...filteredCourses, courseFromAttendee];
                }
            }
        } else {
            filteredCourses = Object.values(filteredCoursesLookup);
        }

        // Order them by delivery date
        const futureBookedCourses = filteredCourses.filter(a => !a.cancelled && a.deliveryDate.isSameOrAfter(moment(), "day")) || [];
        const sortedFutureBookedCourses = futureBookedCourses.sort((a, b) => a.deliveryDate.valueOf() - b.deliveryDate.valueOf()) || [];

        const futureCancelledCourses = filteredCourses.filter(a => a.cancelled && a.deliveryDate.isSameOrAfter(moment(), "day")) || [];
        const sortedFutureCancelledCourses = futureCancelledCourses.sort((a, b) => a.deliveryDate.valueOf() - b.deliveryDate.valueOf()) || [];

        const pastCourses = filteredCourses.filter(a => a.deliveryDate.isBefore(moment(), "day")) || [];
        const sortedPastCourses = pastCourses.sort((a, b) => b.deliveryDate.valueOf() - a.deliveryDate.valueOf()) || [];

        return sortedFutureBookedCourses.concat(sortedFutureCancelledCourses).concat(sortedPastCourses);
    }, [attendeeId, delegate, locationAttendeeId]);

    const courseOptions = React.useMemo(() => {
        return filteredCoursesInOrder.map(course => {
            const status = getBookingItemState(course.cancelled, course.completed);
            return ({ text: `${course.eventTypeName} - ${course.deliveryDate.format("LL")} - ${status}`, value: course.attendeeId });
        });
    }, [filteredCoursesInOrder]);

    React.useEffect(() => {
        const course = (locationAttendeeId || attendeeId)
            ? (delegate?.delegateAttendees?.filter(a => a.attendeeId === (locationAttendeeId || attendeeId))[0])
            : (filteredCoursesInOrder.length > 0
                ? filteredCoursesInOrder[0]
                : undefined);

        setVisibleAttendeeId(course?.attendeeId);
        setVisibleCourse(course);
    }, [filteredCoursesInOrder, delegate, attendeeId, locationAttendeeId]);

    const handleVisibleCourseChange = React.useCallback((_: any, data: InputOnChangeData) => {
        const value = data.value as string;
        setVisibleAttendeeId(value);
        setVisibleCourse(delegate?.delegateAttendees?.filter(a => a.attendeeId === value)[0]);
    }, [delegate]);

    const getAddressLabel = React.useCallback(() => {
        return (<Popup
            trigger={<span>{delegate?.address?.isApproximate && <Icon name="warning sign" />} Address</span>}
            disabled={!delegate?.address?.isApproximate}
            content="The Lat/Long position for this delegate is approximate"
        />);
    }, [delegate]);

    const isPastCourse = React.useMemo(() => {
        return visibleCourse && visibleCourse.deliveryDate.isBefore(moment(), "day");
    }, [visibleCourse]);

    return (
        <Tab.Pane>
            <Grid stackable>
                <Grid.Column width={visibleCourse ? 8 : 16}>
                    <Container className="white-panel">
                        <h5>Delegate details</h5>
                        {delegate.delegateComparisonType === DelegateComparisonTypeEnum.Uin && delegate.uin && (
                            <LabelAndValue label="UIN" value={delegate.uin} />
                        )}
                        {(isCitb && isDigital) && (
                            <LabelAndValue label="Title" value={AttendeeTitle[delegate.title]} />
                        )}
                        <LabelAndValue label="Forename" value={delegate.forename} />
                        <LabelAndValue label="Surname" value={delegate.surname} />
                        {(isCitb && isDigital) && (
                            <>
                                <LabelAndValue label="Date of Birth" value={delegate.dateOfBirth?.format("LL") ?? ""} />
                                <LabelAndValue label="National Insurance Number" value={delegate.nationalInsuranceNumber} />
                            </>
                        )}
                        {(delegate.delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber || (isCitb && isDigital)) && (
                            <LabelAndValue label={getAddressLabel()} value={AddressDisplay(delegate.address)} />
                        )}
                        {(isCitb && isDigital) && (
                            <>
                                <LabelAndValue label="CITB Registration Number" value={delegate.citbRegistrationNumber} />
                                <LabelAndValue label="Company Levy Number" value={delegate.citbLevyNumber} />
                            </>
                        )}
                        {isCitb && (
                            <>
                                <LabelAndValue label="ID Type" value={AttendeeIdType[delegate.idType]} />
                                <LabelAndValue label="ID Last 4 Digits" value={delegate.idLast4Digits} />
                            </>
                        )}
                        <LabelAndValue label="Email" value={delegate.email} />
                        {delegate.mxEmailCheckResult && !delegate.mxEmailCheckResult.valid && (
                            <Message as={Segment} className="cancel-action">
                                <p><Icon className="validation-icon" name={"exclamation circle"} />{delegate.mxEmailCheckResult.error}</p>
                            </Message>
                        )}
                        <LabelAndValue label="Mobile Number" value={delegate.mobileNumber} />
                        {isAps && (
                            <LabelAndValue label="Score" value={delegate.score} />
                        )}
                        {businessLineType !== BusinessLineType.Construction && (
                            <LabelAndValue label="Licence Number" value={delegate.licenceNumber} />
                        )}
                        <LabelAndValue label="Organisation" value={delegate.organisationName} />
                        {businessLineType !== BusinessLineType.Construction && (
                            <LabelAndValue label="Driving Licence Country" value={DrivingLicenceCountry[delegate.drivingLicenceCountry]} />
                        )}
                        {delegate.delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber && (
                            <>
                                {delegate.drivingLicenceCountry === DrivingLicenceCountryEnum.NonGBNI && (
                                    <>
                                        <LabelAndValue label="Issuing Country" value={delegate.drivingLicenceExactCountry} />
                                        <LabelAndValue
                                            label="Date of Birth"
                                            value={delegate.dateOfBirth?.format("LL") ?? ""}
                                        />
                                    </>
                                )}
                                <LabelAndValue
                                    label="Vocational Licence Categories"
                                    value={delegate.vocationalLicenceCategories?.map(vlc => VocationalLicenceCategory[vlc]).join(", ") ?? ""}
                                />
                                <LabelAndValue
                                    label="DQC Reference"
                                    value={delegate.dqcReference}
                                />
                                <LabelAndValue
                                    label="DQC Expiry"
                                    value={delegate.dqcExpiry?.format("LL") ?? ""}
                                />
                            </>
                        )}
                        <LabelAndValue
                            label="Deletion Date"
                            value={delegate.deletionDate?.format("LL") ?? ""}
                        />
                        {delegate.delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                            <>
                                <Divider hidden className="full-width" />
                                <h2>Managers</h2>
                                {delegate.managers?.map((manager, index) => (
                                    <>
                                        <LabelAndValue
                                            label="Name"
                                            value={manager.name || ""}
                                        />
                                        <LabelAndValue
                                            label="Telephone"
                                            value={manager?.telephone || ""}
                                        />
                                        <LabelAndValue
                                            label="Email"
                                            value={manager?.email || ""}
                                        />
                                        {index !== delegate.managers.length - 1 && (
                                            <Divider hidden className="full-width" />
                                        )}
                                    </>
                                ))}
                            </>
                        )}
                        {delegate.attendeeFieldValues && delegate.attendeeFieldValues.length > 0 && delegate.attendeeFieldValues.map(afv =>
                            <LabelAndValue key={afv.name} label={afv.displayName} value={afv.value} />)}
                    </Container>
                </Grid.Column>
                {visibleCourse && (
                    <Grid.Column width={8}>
                        <Container className={isPastCourse ? "dark-blue-panel" : "blue-panel"}>
                            {(!attendeeId && filteredCoursesInOrder && filteredCoursesInOrder.length > 0) && (
                                <>
                                    <div className="field-wrapper">
                                        <Dropdown
                                            value={visibleAttendeeId}
                                            dynamicOptions
                                            options={courseOptions}
                                            onChange={handleVisibleCourseChange}
                                            search
                                        />
                                    </div>
                                </>
                            )}
                            <Link href={`${businessLineTypePath}/eventInstances/${visibleCourse.eventInstanceId}`}>
                                Go to course
                            </Link>
                            <h5>Course details</h5>
                            {visibleCourse && (
                                <>
                                    <LabelAndValue label="Product" value={visibleCourse.eventTypeName} />
                                    <LabelAndValue label="Date and Time" value={visibleCourse.deliveryDate?.format("LLLL") ?? ""} />
                                    <LabelAndValue label="Venue" value={visibleCourse.venueName} />
                                    <LabelAndValue label="Status" value={getBookingItemState(visibleCourse.cancelled, visibleCourse.completed)} />
                                    {(!visibleCourse.cancelled && !visibleCourse.completed) &&
                                        <CancelCorporateBookingModal
                                            attendeeId={visibleCourse.attendeeId}
                                            eventInstanceId={visibleCourse.eventInstanceId}
                                            delegateId={delegate?.id}
                                            organisationId={delegate?.organisationId}
                                            isBookNowPayLaterOrder={visibleCourse.fromOrderWithBookNowPayLater}
                                            eventInstanceDeliveryDateTime={visibleCourse.deliveryDate}
                                        />
                                    }
                                </>
                            )}
                        </Container>
                    </Grid.Column>
                )}
            </Grid>
        </Tab.Pane>
    );
};
