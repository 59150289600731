import * as React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { ManagementContactDetailsList } from "@common/crud/organisation/components/ManagementContactDetailsList";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import { useSelector } from "react-redux";
import { policeOrganisationsSelector } from "@common/crud/organisation/selectors";

interface Props {
    trainer: TrainerDetailModel;
}

export const ManagementTab: React.FC<Props> = ({ trainer }) => {
    const organisations = useSelector(policeOrganisationsSelector);
    const organisation = organisations.find(org => org.id === trainer.homeArea);

    return (
        <Tab.Pane>
            <Grid>
                <Detail
                    key={"trainer.homeArea"}
                    label="Home Area"
                    value={organisation?.name ?? "No home area set"}
                    labelWidth={5}
                    valueWidth={11}
                />
                {trainer?.homeArea && (organisation?.regionalManagers || organisation?.regionalCoordinators) &&
                    <ManagementContactDetailsList
                        regionalManagers={organisation?.regionalManagers}
                        regionalCoordinators={organisation?.regionalCoordinators}
                    />
                }
            </Grid>
        </Tab.Pane>
    );
};
