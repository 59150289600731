import axios, { AxiosResponse } from "axios";
import "moment-duration-format";
import { EventInstancesWithGroupModel, EventInstanceGroupModel } from "./model";
import {
    ClearTrainerAvailabilityEnum,
    EventInstanceDetailModel,
    MonitoringCandidates, OtherTrainerTypeCategoryEnum,
    TrainerType
} from "@common/crud/eventInstance/model";
import { TrainerAllocationModel } from "@common/crud/trainer/model";
import { parseEventInstanceDetailModel } from "../eventInstance/helpers";
import { ParseEventInstanceGroupItem } from "@common/crud/eventInstanceGroup/helpers";

export class EventInstanceGroupApi {
    private readonly apiUrl = "/api/event-instance-group";

    public async getByEventInstanceId(id: string): Promise<EventInstanceGroupModel> {
        const response = await axios.get<EventInstanceGroupModel>(`${this.apiUrl}/event-instance/${id}`);
        return this.parseModel(response.data);
    }

    public async getStatusByEventInstanceId(id: string): Promise<string> {
        const response = await axios.get<string>(`${this.apiUrl}/status/${id}`);
        return response.data;
    }

    public async getEventInstancesByGroupId(id: string): Promise<EventInstancesWithGroupModel> {
        const response = await axios.get<EventInstancesWithGroupModel>(`${this.apiUrl}/${id}/event-instances`);
        return { ...response.data, eventInstances: response.data.eventInstances.map(ei => parseEventInstanceDetailModel(ei)) };
    }

    public async getEventInstancesForNotesByGroupId(id: string): Promise<EventInstancesWithGroupModel> {
        const response = await axios.get<EventInstancesWithGroupModel>(`${this.apiUrl}/${id}/event-instances-notes`);
        return { ...response.data, eventInstances: response.data.eventInstances.map(ei => parseEventInstanceDetailModel(ei)) };
    }

    public async allocateTrainers(
        groupId: string,
        trainerType: TrainerType,
        trainers: TrainerAllocationModel[],
        monitoredTrainers: MonitoringCandidates[]) {
        const result = (await axios.post(`${this.apiUrl}/${groupId}/trainers`, {
            groupId,
            trainerType,
            trainers,
            monitoredTrainers
        })).data as EventInstanceDetailModel[];
        return result.map(r => parseEventInstanceDetailModel(r));
    }

    public async cancelGroupTrainer(groupId: string, trainerId: string, trainerType: TrainerType, reasonForCancellation: string, otherReason: string,
        dayParts: number[], clearTrainerAvailability: ClearTrainerAvailabilityEnum): Promise<EventInstanceDetailModel[]> {
        const result = (await axios.post(`${this.apiUrl}/${groupId}/cancel-group-trainer`,
            { trainerId, trainerType, reasonForCancellation, otherReason, dayParts, clearTrainerAvailability }))
            .data as EventInstanceDetailModel[];
        return result.map(r => parseEventInstanceDetailModel(r));
    }

    public async updateGroupEventInstanceTrainerFeeNote(
        groupId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        feeNote: string) {
        await axios.patch(`${this.apiUrl}/${groupId}/${eventInstanceTrainerId}/${trainerType}/feenote`, {
            feeNote
        });
    }

    public async updateGroupEventInstanceTrainerFee(
        groupId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        fee: number) {
        await axios.patch(`${this.apiUrl}/${groupId}/${eventInstanceTrainerId}/${trainerType}/fee`, {
            fee
        });
    }

    public async updateGroupEventInstanceTrainerSundries(
        groupId: string,
        eventInstanceTrainerId: string,
        trainerType: TrainerType,
        sundries: number) {
        await axios.patch(`${this.apiUrl}/${groupId}/${eventInstanceTrainerId}/${trainerType}/sundries`, {
            sundries
        });
    }

    public async setOtherRoleForGroupTrainer(
        groupId: string,
        dayNumbers: number[],
        trainerId: string,
        otherRole: OtherTrainerTypeCategoryEnum): Promise<EventInstanceDetailModel[]> {
        const result: AxiosResponse = await axios.put(`${this.apiUrl}/${groupId}/trainer/${trainerId}`, {
            otherRole, dayNumbers
        });
        const data = result.data as EventInstanceDetailModel[];
        return data.map(r => parseEventInstanceDetailModel(r));
    }

    public parseModel(model: EventInstanceGroupModel): EventInstanceGroupModel {
        return {
            ...model,
            eventInstanceGroupItems: model.eventInstanceGroupItems?.map(ParseEventInstanceGroupItem) || []
        };
    }
}
