import * as React from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import { RCCalendar } from "./RegionalCoordinatorCalendar";
import { RegionalCoordinatorCalendarSettingsModal } from "./RegionalCoordinatorCalendarSettingsModal";

export const RegionalCoordinatorCalendarBase: React.FC = () => {
    const [editing, setEditing] = React.useState(false);

    function onUpdate() {
        setEditing(true);
    }

    function onCancel() {
        setEditing(false);
    }

    return (
        <Container>
            <h1>Trainer Support Planning</h1>
            <Grid className="ui form filter-font">
                <Grid.Row>
                    <Grid.Column width={8} textAlign="left">
                        {editing ? <Button onClick={onCancel} className="cancel-action" >Cancel</Button> : <Button onClick={onUpdate}>Update</Button>}
                        <RegionalCoordinatorCalendarSettingsModal />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <RCCalendar
                            editing={editing}
                            stopEditing={onCancel}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
