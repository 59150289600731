import * as React from "react";
import { Grid } from "semantic-ui-react";
import { CopyButton } from "@common/components/CopyButton";

interface Props {
    label: string;
    value: string;
}

const DetailWithCopy: React.FC<Props> = ({ label, value }) => (
    <Grid.Row>
        <Grid.Column width={5} as="h4">
            {label}
        </Grid.Column>
        <Grid.Column className="detail-with-copy text-wrap" largeScreen={4} computer={6} tablet={8} mobile={11}>
            {value || "Not set"}
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={3} mobile={16}>
            <CopyButton text={value} />
        </Grid.Column>
    </Grid.Row>
);

export { DetailWithCopy };
