export interface ApiRootPathProps {
    apiRootPath: string;
}

export enum BusinessLineType {
    Unknown,
    Corporate,
    PoliceAndCourt,
    PoliceAndCourt2,
    Construction,
};
