import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SectionThreeKeyMessages: QuestionSection = {
    id: "3",
    title: "Section 3 – Key Messages",
    seqNum: 2,
    subSections: [
        {
            id: "3.1",
            text: "Were the key messages delivered as per the trainer manual?",
            seqNum: 1
        },
        {
            id: "3.2",
            text: "Were module timings adhered to as per the trainer manual?",
            seqNum: 2,
        },
        {
            id: "3.3",
            text: "Were trainer change overs done at the correct time?",
            seqNum: 3,
        }],
    summaryCriterium: [{ id: "3.4", text: "", typeEnum: CriteriaEnum.text }],
    IsQuestionSection: true
};
