import {
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { TrainerAttributesSection } from "./TrainerAttributesSection";

/*
Trainer Attributes section, removed bullet points:
    Trainer helped the clients with any IT issues
    Effective use of advising clients to mute/unmute if feedback experienced
    Locked the room
    Effective use of thumbs up and thumbs down
    Clients able to access the course without major issue
    Trainer could see all clients
    GDPR principles followed correctly when checking ID and completing registration process?
    Were any technical difficulties encountered by trainer / observer / client?
        E.g. loss of internet connection, difficulties in hearing others due to mic or internet
    Was the trainer’s audio and video clear / uninterrupted / comprehensible?
*/
export const TrainerAttributesSectionClassroom: QuestionSection = {
    ...TrainerAttributesSection,
    subSections: [
        {
            ...TrainerAttributesSection.subSections[0],
            criteria: [
                { id: "1", text: "Trainer welcoming to clients" },
                { id: "2", text: "Trainer removed the client’s surname if possible" },
                { id: "3", text: "Introduced assessor / EQA and their role" }
            ],
        },
        TrainerAttributesSection.subSections[1],
        {
            ...TrainerAttributesSection.subSections[2],
            criteria: [
                { id: "1", text: "Remain in control throughout the session " },
                { id: "2", text: "Dealing with difficult clients" },
                { id: "3", text: "Maintain clients’ interest" },
                { id: "4", text: "Adapt facilitation / teaching techniques to include all learning styles" },
                { id: "5", text: "Equality & Diversity managed / compliant " },
            ]
        },
        TrainerAttributesSection.subSections[3],
        {
            ...TrainerAttributesSection.subSections[4],
            criteria: [
                { id: "1", text: "Coaching techniques used appropriately" },
                { id: "2", text: "Kept the course client centred" },
                { id: "3", text: "Did not introduce anecdotes from clients or self " },
                { id: "4", text: "Used wording appropriate to the clients comprehension / understanding" },
                { id: "5", text: "Spread the questions equally across all the clients" },
                { id: "6", text: "Allows clients time to respond" }
            ]
        },
        TrainerAttributesSection.subSections[5],
        TrainerAttributesSection.subSections[6],
    ],
};
