import * as React from "react";
import { Button, Grid, Input, InputOnChangeData, Tab } from "semantic-ui-react";
import { AttachedDocumentListModel } from "../../model";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { toast } from "@common/toasts";
import { FileUploadInput } from "./FileUploadInput";
import { DrinkDriveOffenderApi } from "../..";
import { FileEntry } from "./FileEntry";
import _ from "lodash";

export interface FileDetailsTabProps {
    bookingId: string;
    downloadUrl: string;
    openUrl: string;
    filesUpdated?: () => void;
}

export const FileDetailsTab: React.FC<FileDetailsTabProps> = ({ bookingId, downloadUrl, openUrl, filesUpdated }) => {

    const [description, setDescription] = React.useState<string>("");
    const [files, setFiles] = React.useState<FileList>();
    const [loading, setLoading] = React.useState(false);
    const [showAllDetails, setShowAllDetails] = React.useState<boolean>(false);
    const [attachedDocuments, setAttachedDocuments] = React.useState<AttachedDocumentListModel[]>([]);

    React.useEffect(() => {
        async function loadAttachedDocuments() {
            const api = new DrinkDriveOffenderApi();
            const documents = await api.getAttachedDocuments(bookingId);
            setAttachedDocuments(documents);
        }

        loadAttachedDocuments();
    }, [bookingId]);

    function onDescriptionChange(e: any, { value }: InputOnChangeData) {
        setDescription(value);
    }

    function onFileChange(fileList: FileList) { setFiles(fileList); }

    async function onUploadFile() {
        setLoading(true);
        const api = new DrinkDriveOffenderApi();
        const newDocument = await api.uploadAttachedDocument(bookingId, description, files[0]);
        setAttachedDocuments(prev => [...prev, newDocument]);
        setFiles(undefined);
        filesUpdated?.();
        setDescription("");
        setLoading(false);
    }

    const isStateValid = files && description.length > 1;

    const groupedDocuments = _(attachedDocuments).orderBy((a) => a.dateCreated, "desc")
        .groupBy(entry => moment(entry.dateCreated).format(DateFormat.Short))
        .map((value, key) => ({ key, values: value }))
        .value();

    const showHideText = showAllDetails ? "Hide all" : "Show all";
    const setShowAllDetailsClick = React.useCallback(() => setShowAllDetails(!showAllDetails), [showAllDetails]);

    const deleteFileClicked = async (file: AttachedDocumentListModel) => {
        try {
            const api = new DrinkDriveOffenderApi();
            await api.deleteAttachedDocument(bookingId, file.id);
            toast.success(`Successfully deleted '${file.description}'`);
            setAttachedDocuments(prev => prev.filter(f => f.id !== file.id));
            filesUpdated?.();
        } catch (error) {
            toast.error(`Failed to delete '${file.description}'`);
        }
    };

    return (
        <Tab.Pane className="no-border">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10} verticalAlign="middle">
                        <Input type="text" value={description} placeholder="Description" onChange={onDescriptionChange} fluid />
                    </Grid.Column>
                    <Grid.Column width={6} verticalAlign="middle">
                        <FileUploadInput fileList={files} uploadLabel="Add File" disabled={description.trim().length === 0} onChange={onFileChange} />
                        <Button loading={loading} content="Upload" disabled={!isStateValid} onClick={onUploadFile} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <a className="show-button with-padding" onClick={setShowAllDetailsClick}>{`${showHideText} download links`}</a>
                </Grid.Row>
                {groupedDocuments.map(({ key, values }) => (
                    <Grid.Row key={key}>
                        <div className="container">
                            <div className="date">
                                <span className="bold">
                                    {moment(key, DateFormat.Short).format(DateFormat.DayMonthYear)}
                                </span>
                            </div>
                            <div className="timeline">
                                <div className="indicator">
                                    <div className="join" />
                                    <div className="tip" />
                                </div>
                            </div>
                            <div className="details">
                                {values.map(a => (
                                    <FileEntry
                                        key={a.id}
                                        entry={a}
                                        downloadUrl={downloadUrl}
                                        showAllDetails={showAllDetails}
                                        openUrl={openUrl}
                                        onDeleteFileClick={(entry) => deleteFileClicked(entry)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Grid.Row>
                ))}
            </Grid>
        </Tab.Pane >
    );
};
