import * as React from "react";
import { useCallback } from "react";
import { Button, Icon } from "semantic-ui-react";
import "./AddToBasketButton.scss";

type AddToBasketButtonProps = { maxValue?: number; seatsAddedForCourse?: (seats: number) => void; basketChangesInProgress: boolean };

export const AddToBasketButton = ({ maxValue, seatsAddedForCourse, basketChangesInProgress }: AddToBasketButtonProps) => {
    const [value, setValue] = React.useState(1);

    const atMin = value === 1;
    const atMax = maxValue !== undefined && value === maxValue;

    const onMinus = useCallback(() => {
        if (!basketChangesInProgress && !atMin) {
            setValue(value - 1);
        }
    }, [atMin, value, basketChangesInProgress]);

    const onPlus = useCallback(() => {
        if (!atMax && !basketChangesInProgress) {
            setValue(value + 1);
        }
    }, [atMax, value, basketChangesInProgress]);

    const handleBasketAdd = useCallback(() => {
        seatsAddedForCourse(value);
        setValue(1);
    }, [seatsAddedForCourse, value]);

    return (
        <div className="add-to-basket-button">
            <div className="add-number-input">
                <Icon name="minus" className="number-icon-left" onClick={onMinus} disabled={atMin} />
                <input type="text" className="number-input" value={value} readOnly />
                <Icon name="plus" className="number-icon-right" onClick={onPlus} disabled={atMax} />
            </div>
            <Button onClick={handleBasketAdd} disabled={basketChangesInProgress} className="link-button add-link-button">Add to basket</Button>
        </div>
    );
};
