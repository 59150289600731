import { EventType, TrainerFee, WorkflowTypeEnum } from "../eventType/model";
import { EventInstance } from ".";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { EventInstanceGroupModel } from "../eventInstanceGroup/model";
import { TrainerType } from "./model";
import { Organisation } from "../organisation";
import { LanguageEnum } from "@common/crud/eventInstance/model";

const EveningStartHour: number = 18;

export class TrainerFeeCalculator {

    private static getDailyFee = (eventInstance: EventInstance, fee: TrainerFee) => {
        if (eventInstance.startDate) {
            const dayOfWeek = eventInstance.startDate.isoWeekday();
            if (dayOfWeek === 7 || eventInstance.startDateIsBankHoliday) {
                return fee.sunday;
            }
            if (dayOfWeek === 6) {
                return fee.saturday;
            }
        }
        return fee.weekday;
    };

    private static getHourlyFee = (eventInstance: EventInstance, fee: TrainerFee) => {

        const isEvening = eventInstance.startTime && eventInstance.startTime.asHours() >= EveningStartHour;
        if (isEvening) {
            return fee.evening ?? 0;
        }
        return 0;
    };

    private static getLanguageFee = (eventInstance: EventInstance, fee: TrainerFee) => {
        if (eventInstance.language === LanguageEnum.Welsh) {
            return fee.welsh;
        }
        return 0;
    };

    private static getIsDigital = (eventInstance: EventInstance) => {
        return eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    };

    public static getCourseFee(eventInstance: EventInstance, eventType: EventType, organisation: Organisation, eventInstanceGroup?: EventInstanceGroupModel,
        practical?: boolean): number {
        if (isNullOrUndefined(eventInstance) || isNullOrUndefined(eventType)) {
            return 0;
        }

        const isDigital = TrainerFeeCalculator.getIsDigital(eventInstance);
        let trainerFees: TrainerFee[];
        if (isDigital) {
            trainerFees = eventType?.digitalEventTypeDetails?.digitalTrainerFees;
        } else if (practical) {
            trainerFees = eventInstance.workflowType === WorkflowTypeEnum.Dors?
                organisation?.practicalTrainerFees?.[eventType?.id] ?? eventType?.classroomEventTypeDetails?.practicalTrainerFees :
                eventType?.classroomEventTypeDetails?.practicalTrainerFees;
        } else {
            trainerFees = eventInstance.workflowType === WorkflowTypeEnum.Dors?
                organisation?.classroomTrainerFees?.[eventType?.id] ?? eventType?.classroomEventTypeDetails?.trainerFees :
                eventType?.classroomEventTypeDetails?.trainerFees;
        }

        if (isNullOrUndefined(trainerFees)) {
            return 0;
        }

        const effectiveFees = trainerFees
            .filter(f => f.effectiveDate.startOf("day").isSameOrBefore(eventInstance.startDate.startOf("day")))
            .sort(((a, b) => b.effectiveDate.diff(a.effectiveDate)));

        if (isNullOrUndefined(effectiveFees) || effectiveFees.length < 1) {
            return 0;
        }

        const trainerFee = effectiveFees[0];

        if (eventInstance.workflowType === WorkflowTypeEnum.DDRS) {
            return isNullOrUndefined(eventInstanceGroup)? 0 : TrainerFeeCalculator.getDdrsCourseFee(eventInstance, trainerFee, eventInstanceGroup);
        }

        return TrainerFeeCalculator.getDorsCourseFee(eventInstance, trainerFee);
    }

    public static getMonitorCourseFee(eventInstance: EventInstance, eventType: EventType, trainerType: TrainerType): number {
        if (isNullOrUndefined(eventInstance) || isNullOrUndefined(trainerType) || isNullOrUndefined(eventType) ||
            isNullOrUndefined(eventType.monitorFees)) {
            return 0;
        }

        if (trainerType !== TrainerType.MonitorTrainer && trainerType !== TrainerType.OtherTrainer) {
            return 0;
        }

        const effectiveFees = eventType.monitorFees
            .filter(f => f.effectiveDate.startOf("day").isSameOrBefore(eventInstance.startDate.startOf("day")))
            .sort(((a, b) => b.effectiveDate.diff(a.effectiveDate)));

        if (isNullOrUndefined(effectiveFees) || effectiveFees.length < 1) {
            return 0;
        }

        const trainerFee = effectiveFees[0];

        return trainerFee.monitor;
    }

    private static getDorsCourseFee(eventInstance: EventInstance, trainerFee: TrainerFee): number {
        const dailyFee = TrainerFeeCalculator.getDailyFee(eventInstance, trainerFee);
        const hourlyFee = TrainerFeeCalculator.getHourlyFee(eventInstance, trainerFee);
        const languageFee = TrainerFeeCalculator.getLanguageFee(eventInstance, trainerFee);
        return (dailyFee ?? 0) + hourlyFee + languageFee;
    }

    // For DDRS the trainer fee on EventType is for the full course
    private static getDdrsCourseFee(eventInstance: EventInstance, trainerFee: TrainerFee, eventInstanceGroup: EventInstanceGroupModel): number {
        // Each event instance occurs on a separate day, so this is the total number of days
        const numberOfDays = eventInstanceGroup.eventInstanceGroupItems.length;

        const courseFee = getDayRate(eventInstance, trainerFee) / numberOfDays;

        return courseFee;
    }
}

function getDayRate({ startDate }: EventInstance, trainerFee: TrainerFee) {
    if (startDate === undefined) {
        return trainerFee.weekday;
    }

    const dayOfWeek = startDate?.isoWeekday();
    switch (dayOfWeek) {
        case 7: // Sun
            return trainerFee.sunday;
        case 6: // Sat
            return trainerFee.saturday;
        default:
            return trainerFee.weekday;
    }
}
