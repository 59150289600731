import * as React from "react";
import { CorporateSmsSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid, InputType } from "./SettingsGrid";

export const CorporateSms: ConfigurationAreaComponent<CorporateSmsSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="CorporateSms"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "DelegateCourseReminderClassroom", label: "Delegate Course Reminder - Classroom", inputType: InputType.TextArea },
                { name: "DelegateCourseReminderDigital", label: "Delegate Course Reminder - Digital", inputType: InputType.TextArea },
                {
                    name: "BusinessDriverDelegateCourseReminderClassroom",
                    label: "Business Driver Delegate Course Reminder - Classroom",
                    inputType: InputType.TextArea
                },
                {
                    name: "BusinessDriverDelegateCourseReminderDigital",
                    label: "Business Driver Delegate Course Reminder - Digital",
                    inputType: InputType.TextArea
                },
                { name: "WorkshopSurvey", label: "Workshop Survey", inputType: InputType.TextArea },
                { name: "BusinessDriverTrainerCourseReminder", label: "Business Driver Trainer Course Reminder", inputType: InputType.TextArea },
            ]}
        />
    );
};
