import * as React from "react";
import { ZoomServiceSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const ZoomService: ConfigurationAreaComponent<ZoomServiceSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Zoom"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ZoomApiEndpoint", label: "Zoom Api Endpoint" },
                { name: "ZoomAuthorisationEndpoint", label: "Zoom Authorisation Endpoint" },
                { name: "ZoomAccountId", label: "Zoom Account ID" },
                { name: "ZoomClientId", label: "Zoom Client ID" },
                { name: "ZoomClientSecret", label: "Zoom Client Secret" },
                { name: "UseLiveService", label: "Use Live Service" },
                { name: "PasswordResetEnabled", label: "Password reset process enabled" },
                { name: "PasswordResetFrequencyDays", label: "Password Reset Frequency (days)" }
            ]}
        />
    );
};
