import * as React from "react";
import { Button, Checkbox, Table } from "semantic-ui-react";
import { DigitalEventTypeDetails, SessionsIncludedInDigitalCalendarAutoAdd } from "../../model";

export interface DigitalCalendarSessionsTableProps {
    digitalEventTypeDetails: DigitalEventTypeDetails;
    editable?: boolean;
    onChange?: (value: SessionsIncludedInDigitalCalendarAutoAdd, valid: boolean) => void;
}

export const DigitalCalendarSessionsTable = ({ digitalEventTypeDetails, editable, onChange }: DigitalCalendarSessionsTableProps) => {
    const defaultState: SessionsIncludedInDigitalCalendarAutoAdd = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: []
    };

    const initialState = digitalEventTypeDetails?.sessionsIncludedInDigitalCalendarAutoAdd ?? defaultState;

    const [editing, setEditing] = React.useState(false);
    const [newSessionsIncludedInDigitalCalendarAutoAdd, setNewSessionsIncludedInDigitalCalendarAutoAdd] =
        React.useState<SessionsIncludedInDigitalCalendarAutoAdd>(initialState);

    const daysOfWeek: (keyof SessionsIncludedInDigitalCalendarAutoAdd)[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const sessions = [1, 2, 3, 4];
    const convertToTitleCase = (str: string) => str[0].toLocaleUpperCase() + str.slice(1);

    function onEditClick() {
        setEditing(true);
    }

    function onCancelClick() {
        setEditing(false);
        setNewSessionsIncludedInDigitalCalendarAutoAdd(initialState);
    }

    function onSaveClick() {
        onChange(newSessionsIncludedInDigitalCalendarAutoAdd, true);
        setEditing(false);
    }

    function onCheckboxChange(day: keyof SessionsIncludedInDigitalCalendarAutoAdd, session: number) {
        return function() {
            if (newSessionsIncludedInDigitalCalendarAutoAdd[day].includes(session)) {
                setNewSessionsIncludedInDigitalCalendarAutoAdd(prev => ({
                    ...prev,
                    [day]: prev[day].filter(x => x !== session)
                }));
                return;
            }

            setNewSessionsIncludedInDigitalCalendarAutoAdd(prev => ({
                ...prev,
                [day]: [...prev[day], session]
            }));
        };
    }

    return (
        <>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="5">Sessions included in digital calendar auto add</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Day</Table.HeaderCell>
                        <Table.HeaderCell>Session 1</Table.HeaderCell>
                        <Table.HeaderCell>Session 2</Table.HeaderCell>
                        <Table.HeaderCell>Session 3</Table.HeaderCell>
                        <Table.HeaderCell>Session 4</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {daysOfWeek.map(day => (
                        <Table.Row key={day}>
                            <Table.Cell>{convertToTitleCase(day)}</Table.Cell>
                            {sessions.map(session => (
                                <Table.Cell textAlign="center" key={session}>
                                    <Checkbox
                                        checked={newSessionsIncludedInDigitalCalendarAutoAdd[day].includes(session)}
                                        disabled={!editing}
                                        onChange={onCheckboxChange(day, session)}
                                    />
                                </Table.Cell>))
                            }
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {editable && !editing && <Button content="Edit" onClick={onEditClick} />}
            {editable && editing &&
                <>
                    <Button content="Cancel" className="cancel-action" onClick={onCancelClick} />
                    <Button content="Save" positive onClick={onSaveClick} />
                </>
            }
        </>
    );
};
