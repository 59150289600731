import * as React from "react";
import { Grid, Form, Radio, CheckboxProps, Message } from "semantic-ui-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";
import { useFormikContext, FieldProps, Field, ErrorMessage } from "formik";
import { MissingBookingProps } from "../../bookings/models";
import { hearingImpairmentAttributes, LanguageCode, LanguageEnum, SpecialRequirementsAttribute } from "@common/crud/eventInstance/model";
import { SpecialRequirementsCheckboxes } from "@common/specialRequirements/SpecialRequirementsCheckboxes";
import { WelshWarningModal } from "./WelshWarningModal";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import { bookingBasePathSelector } from "../selectors";
import { eventInstanceSelector } from "@booking/eventInstance/selectors";
import { ApplicationState } from "@booking/applicationState";
import { AttendeeRole } from "@common/auth/model";
import { setCurrentLanguage } from "@common/language";
import { seatSelector } from "@booking/bookings/selectors";
import { Seat } from "@booking/seat";
import { VenueAttribute } from "@common/crud/venue/model";
import { EventInstance } from "@common/crud/eventInstance";
import { SpecialRequirementsInfoModal } from "./SpecialRequirementsInfoModal";
import { IsEiScheduledToRunWithin72Hours } from "@booking/landing/components/helper";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface Props {
    hasUserFilterBySpecialRequirements: boolean;
    isDigitalCourse: boolean;
    eventInstance: EventInstance;
}

type BookingSpecialRequirementsFormProps = Props & WithTranslation;

export function getSpecialRequirementsSelected(requirements: SpecialRequirements) {
    return requirements && (requirements.eventAttributes?.length > 0
        || requirements.venueAttributes?.length > 0
        || requirements.hasHearingImpairment
        || !!requirements.interpreterName
        || !!requirements.otherRequirements
        || !!requirements.outsideUkCountryName
        || !!requirements.otherHearingRequirements);
}

const BookingSpecialRequirementsFormInternal: React.FC<BookingSpecialRequirementsFormProps> = (props: BookingSpecialRequirementsFormProps) => {
    const { t, hasUserFilterBySpecialRequirements, isDigitalCourse } = props;
    const { errors, values, setFieldValue } = useFormikContext<MissingBookingProps>();
    const specialRequirements = React.useMemo(() => values.specialRequirements || { venueAttributes: [],
        eventAttributes: [],
        hasSpecialRequirements: hasUserFilterBySpecialRequirements || getSpecialRequirementsSelected(values.specialRequirements) },
    [hasUserFilterBySpecialRequirements, values.specialRequirements]);

    const [specialRequirementsChanged, setSpecialRequirementsChanged] = React.useState(false);

    const dispatch = useDispatch();
    const seat: Seat = useSelector(seatSelector);
    const hasSpecialRequirements = values.specialRequirements?.hasSpecialRequirements;
    const compareKeys = (keys: (keyof SpecialRequirements)[],
        currentSpecialRequirements: SpecialRequirements, existingSpecialRequirements: SpecialRequirements) => {
        let result = true;
        keys.forEach(key => {
            result = result && JSON.stringify(currentSpecialRequirements[key]) === JSON.stringify(existingSpecialRequirements[key]);
        });
        return result;
    };

    const specialRequirementsAreEqual = React.useCallback(
        (currentSpecialRequirements: SpecialRequirements, existingSpecialRequirements: SpecialRequirements) => {
            return compareKeys(ObjectKeys(currentSpecialRequirements) as (keyof SpecialRequirements)[],
                currentSpecialRequirements,
                existingSpecialRequirements);
        }, []);

    const onUpdateHasSpecialRequirements = React.useCallback((e: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
        if (value === "true") {
            setFieldValue("specialRequirements", { ...specialRequirements, hasSpecialRequirements: true });
        } else {
            setFieldValue("specialRequirements", { venueAttributes: [], eventAttributes: [], hasSpecialRequirements: false });
        }
        setSpecialRequirementsChanged(true);
    }, [specialRequirements, setFieldValue]);

    const validate = React.useCallback((selectedRequirements: SpecialRequirements) => {
        if (!(selectedRequirements.hasSpecialRequirements)) {
            return null;
        }

        if (specialRequirementsChanged === false) {
            const changed = specialRequirementsAreEqual(seat?.specialRequirements ?? {}, selectedRequirements) === false;
            setSpecialRequirementsChanged(changed);
        }

        const { venueAttributes, eventAttributes, otherRequirements, interpreterName, outsideUkCountryName,
            otherHearingRequirements, hasHearingImpairment } = selectedRequirements;

        if (otherRequirements?.length > 200) {
            return t("OTHER_REQUIREMENTS_LENGTH_EXCEEDED");
        }

        if ((eventAttributes?.includes(SpecialRequirementsAttribute.OwnInterpreter)
             || eventAttributes?.includes(SpecialRequirementsAttribute.Translator))
             && !interpreterName) {
            return t("CONFIRM_INTERPRETER_NAME");
        }

        if (eventAttributes?.includes(SpecialRequirementsAttribute.OutsideUkAttendee)
        && !outsideUkCountryName) {
            return t("CONFIRM_OTHER_COUNTRY");
        };

        if (hasHearingImpairment && !eventAttributes.some(a => hearingImpairmentAttributes.includes(a)) && !otherHearingRequirements) {
            return t("SELECT_HEARING_REQUIREMENTS");
        }

        if (venueAttributes?.length || eventAttributes?.length || otherRequirements?.length || interpreterName?.length || otherHearingRequirements) {
            return null;
        }

        return t("SELECT_SOME_REQUIREMENTS");
    }, [seat?.specialRequirements, specialRequirementsAreEqual, specialRequirementsChanged, t]);

    const hasErrors = errors.specialRequirements !== undefined;

    const existingSpecialRequirements = (): boolean => {
        if (values.specialRequirements) {
            const { venueAttributes, eventAttributes, otherRequirements, interpreterName } = values.specialRequirements;
            return venueAttributes?.length > 0 || eventAttributes?.length > 0 || otherRequirements?.length > 0 || interpreterName?.length > 0;
        }
        return false;
    };

    // true if booking has at least one of the special requirements
    const hasAnyAttribute = (attributes: number[], allAttributes: number[]) => attributes.map(a => allAttributes.includes(a)).includes(true);

    const existingSpecialRequirementsStillRelevant = (): boolean => {

        // If they need to breastfeed or pray.
        if (hasAnyAttribute([SpecialRequirementsAttribute.NeedToPray, SpecialRequirementsAttribute.NeedToBreastFeed], specialRequirements.eventAttributes)
        || hasAnyAttribute([VenueAttribute.BreastfeedingRoom], specialRequirements.venueAttributes)) {
            return true;
        }

        // If it is a classroom course and they have disabled access, text to speech or hearing loop
        if (isDigitalCourse === false &&
            (hasAnyAttribute([SpecialRequirementsAttribute.TextToSpeechReporter, SpecialRequirementsAttribute.HearingLoop], specialRequirements.eventAttributes)
            || hasAnyAttribute([VenueAttribute.DisabledAccess, VenueAttribute.HearingLoop], specialRequirements.venueAttributes))) {
            return true;
        }

        // If it is a digital course and they are hearing impaired or will be joining from outside the uk.
        if (isDigitalCourse &&
            hasAnyAttribute([SpecialRequirementsAttribute.NeedSubtitles, SpecialRequirementsAttribute.OutsideUkAttendee], specialRequirements.eventAttributes))
        {
            return true;
        }

        return false;
    };

    const [hasExistingSpecialRequirements] = React.useState(existingSpecialRequirements());
    const showRequirementsMessage = hasExistingSpecialRequirements && specialRequirementsChanged === false && existingSpecialRequirementsStillRelevant();

    const [welshWarningModalOpen, setWelshWarningModalOpen] = React.useState(false);
    const dismissWelshWarning = React.useCallback(() => {
        dispatch(setCurrentLanguage(LanguageCode[LanguageEnum.Welsh]));
        setWelshWarningModalOpen(false);
    }, [dispatch]);

    const abortWelshWarning = React.useCallback(() => {
        // If no modal text defined then this callback is use instead of dismissWelshWarning above as
        // the dispatch causes an error in that scenario
        setWelshWarningModalOpen(false);
    }, []);

    const path = useSelector(bookingBasePathSelector);
    const goBack = React.useCallback(() => {
        dispatch(setCurrentLanguage(LanguageCode[LanguageEnum.English]));
        dispatch(push(path));
    }, [path, dispatch]);

    const eventInstance: EventInstance = useSelector(eventInstanceSelector);
    const user = useSelector((state: ApplicationState) => state.currentUser);
    const isAttendee = user.roles.includes(AttendeeRole);

    React.useEffect(() => {
        if (eventInstance.language === LanguageEnum.Welsh && isAttendee) {
            setWelshWarningModalOpen(true);
        }},
    [eventInstance, isAttendee]);

    const isEiScheduledToRunWithin72Hours = eventInstance && IsEiScheduledToRunWithin72Hours(eventInstance.deliveryDateTime);
    const isClientAdvisor = useSelector(isUserClientAdvisor);

    const isSpecialRequirementsEditable = isClientAdvisor || !isEiScheduledToRunWithin72Hours;

    return (
        <>
            <Grid.Row>
                <Grid.Column as={Form} error={hasErrors}>
                    <div className="mobile-keyline">
                        <h3 className="your-requirements">{t("YOUR_REQUIREMENTS")}</h3>
                        <SpecialRequirementsInfoModal />
                    </div>
                    <Grid>
                        {
                            (isSpecialRequirementsEditable && showRequirementsMessage) && (
                                <Grid.Column width={16}>
                                    <Message className="rebook-message" ><strong>{t("CURRENT_SPECIAL_REQUIREMENTS")}</strong></Message>
                                </Grid.Column>
                            )
                        }
                        <Grid.Column width={10}>
                            <span>{t("ANY_SPECIAL_REQUIREMENTS_QUESTION")}</span>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Group inline>
                                <Form.Field
                                    control={Radio}
                                    label={t("YES")}
                                    value="true"
                                    checked={hasSpecialRequirements}
                                    onChange={onUpdateHasSpecialRequirements}
                                />
                                <Form.Field
                                    control={Radio}
                                    label={t("NO")}
                                    value="false"
                                    checked={!hasSpecialRequirements}
                                    onChange={onUpdateHasSpecialRequirements}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid>
                    {
                        hasErrors &&
                        <ErrorMessage
                            name="specialRequirements"
                            className="error-margin-bottom"
                        >
                            {(msg) => <Message error header={msg} />}
                        </ErrorMessage>
                    }
                    {
                        (values?.specialRequirements?.hasSpecialRequirements && !isSpecialRequirementsEditable) && (
                            <Grid.Column className="ei_scheduled_within_72_hours_message_container" width={16}>
                                <Message className="your-requirements" ><strong>{t("EI_SCHEDULED_WITHIN_72_HOURS")}</strong></Message>
                            </Grid.Column>
                        )
                    }
                    {
                        (isSpecialRequirementsEditable) && (
                            <Field name="specialRequirements" validate={validate}>
                                {({ field: { value, onChange } }: FieldProps<SpecialRequirements>) => {
                                    function onUpdateSpecialRequirements(newValue: SpecialRequirements) {
                                        onChange({ target:
                                            { name: "specialRequirements", value: { ...newValue, hasSpecialRequirements: value.hasSpecialRequirements } } });
                                    }

                                    return (
                                        <SpecialRequirementsCheckboxes
                                            specialRequirements={value}
                                            onUpdate={onUpdateSpecialRequirements}
                                            includeOther={true}
                                            hasSpecialRequirements={value.hasSpecialRequirements}
                                            isDigitalCourse={isDigitalCourse}
                                        />
                                    );
                                }}
                            </Field>
                        )
                    }
                </Grid.Column>
            </Grid.Row>
            <WelshWarningModal open={welshWarningModalOpen} dismissModal={dismissWelshWarning} goBack={goBack} abortModal={abortWelshWarning} />
        </>
    );
};

export const BookingSpecialRequirementsForm = withTranslation("BookingSpecialRequirementsForm")(BookingSpecialRequirementsFormInternal);
