import * as React from "react";
import { Grid, Button } from "semantic-ui-react";
import { getDateInputFormat } from "@common/dateFormating";
import moment from "moment";

import { ScheduledPaymentPlanViewModel } from "../../model";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { MuiDateField } from "@common/components/MuiDateField";

export interface ScheduledPaymentRowProps {
    scheduledPaymentPlan: ScheduledPaymentPlanViewModel;
    isFirstRow: boolean;
    isLastRow: boolean;
    isAddingEnabled: boolean;
    removePaymentPlan: (planId: string) => void;
    addPaymentPlan: () => void;
    updatePaymentPlan: (plan: ScheduledPaymentPlanViewModel) => void;
}

export const ScheduledPaymentRow: React.FC<ScheduledPaymentRowProps> = ({
    scheduledPaymentPlan,
    isFirstRow,
    isLastRow,
    addPaymentPlan,
    updatePaymentPlan,
    removePaymentPlan,
    isAddingEnabled
}) => {
    const dateInputFormat = getDateInputFormat();

    function onRemovePlanClick() {
        removePaymentPlan(scheduledPaymentPlan.id);
    }

    function onAmountChanged(value: number) {
        updatePaymentPlan({
            ...scheduledPaymentPlan,
            isAmountDirty: true,
            amount: value
        });
    }

    function onDateChange(value: moment.Moment) {
        updatePaymentPlan({
            ...scheduledPaymentPlan,
            isDateDirty: true,
            chargeDate: value
        });
    }

    return (
        <>
            <Grid.Row>
                <Grid.Column width={7} className="full-width-input fixed-height">
                    <CurrencyInput
                        required
                        label={"Enter payment amount"}
                        value={scheduledPaymentPlan.amount}
                        onChange={onAmountChanged}
                    />
                </Grid.Column>
                <Grid.Column width={7} className="full-width-input fixed-height">
                    <MuiDateField
                        placeholder={dateInputFormat}
                        label="Payment date"
                        value={scheduledPaymentPlan.chargeDate}
                        onChange={onDateChange}
                    />
                </Grid.Column>
                <Grid.Column width={2} className="padding-0">
                    <div className="control-button-container">
                        <Button
                            className="control-button"
                            disabled={isFirstRow}
                            onClick={onRemovePlanClick}
                        >
                            -
                        </Button>
                        {
                            isLastRow &&
                            <Button
                                className="control-button"
                                disabled={
                                    scheduledPaymentPlan.amount === 0 ||
                                    isNullOrUndefined(scheduledPaymentPlan.chargeDate) ||
                                    !isAddingEnabled
                                }
                                onClick={addPaymentPlan}
                            >
                                +
                            </Button>
                        }
                    </div>

                </Grid.Column>

            </Grid.Row>
            {
                scheduledPaymentPlan?.validationMessage !== "" &&
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>
                            {scheduledPaymentPlan?.validationMessage}
                        </p>
                    </Grid.Column>
                </Grid.Row>
            }
        </>
    );
};
