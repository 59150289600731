/* tslint:disable:object-literal-key-quotes */
import { loadAlaskaNudgeTaskDetail, loadAllCAEmails } from "./actions";
import { AppState } from "./model";
import { alaskaNudgeTaskSelector } from "./selectors";
import { detailRoutes } from "./detailRoutes";

const detailRoute = () => ({
    "/:alaskaNudgeTaskId": {
        title: (state: AppState) => alaskaNudgeTaskSelector(state).title,
        resolve: [loadAlaskaNudgeTaskDetail, loadAllCAEmails],
        ...detailRoutes
    }
});

export const routes = {
    "/alaskatasks": {
        title: "Tasks",
        ignoreParentResolve: true,
        ...detailRoute()
    }
};
