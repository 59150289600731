import { Dropdown, Grid } from "semantic-ui-react";
import * as React from "react";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";

interface Props {
    groupEventInstance: EventInstanceGroupModel;
    setDay: React.Dispatch<React.SetStateAction<number>>;
    day: number;
}

export const EventInstanceDayDropdown: React.FC<Props> = ({ groupEventInstance, setDay, day })  => {
    const days = groupEventInstance?.eventInstanceGroupItems?.map((ei) => ({ key: ei.id, value: ei.dayNumber, text: `Day ${ei.dayNumber}` }));
    const daysIncludingAll = days ? [...days, { key: "all", value: null as number, text: "All" }] : [];

    function changeEventInstance(eventInstanceDay: number) {
        setDay(eventInstanceDay);
    }

    return (<Grid.Column width={3}>
        <Dropdown
            placeholder='Select Day'
            selection
            value={day}
            onChange={(e,d) => changeEventInstance(d.value as number)}
            options={daysIncludingAll}
        />
    </Grid.Column>);
};
