import * as React from "react";
import { Form, InputOnChangeData } from "semantic-ui-react";
import { EventInstanceApi } from "../eventInstanceApi";
import { toast } from "@common/toasts";
import { basePathSelector, getPathString } from "@common/crud/eventInstance/selectors";
import { useSelector } from "react-redux";
import { Area } from "../model";
import { appSelector, routerPathnameSelector } from "@common/crud/common/selectors";

export const GoToEventInstanceById = () => {
    const [courseId, setCourseId] = React.useState<number>(null);

    const app = useSelector(appSelector);
    const pathname = useSelector(routerPathnameSelector);
    const basePath = useSelector(basePathSelector);

    const onSubmit = React.useCallback(async () => {
        if (courseId === null) {
            return;
        }

        const api = new EventInstanceApi();
        const response = await api.getEventInstanceIdByCourseId(courseId);

        if (response.eventInstanceId !== null) {
            const path = getPathString(response.workflowType, basePath, app, pathname, Area.AdminEventManagementTrainerEventInstance);
            location.href = `${path}/${response.eventInstanceId}`;
        } else {
            toast.info("No results found");
        }
    }, [app, basePath, courseId, pathname]);

    const onChange = React.useCallback((_: any, data: InputOnChangeData) => {
        setCourseId(data.value !== null ? +data.value : null);
    }, []);

    return (
        <Form onSubmit={onSubmit} vertical="true">
            <Form.Input
                className="search-field"
                action={{ icon: "search" }}
                value={courseId || ""}
                onChange={onChange}
                type="number"
                placeholder="Course ID"
            />
        </Form>
    );
};
