/* eslint-disable @typescript-eslint/no-redeclare */
export const CREATE_CORPORATE_USER_SUCCESS = "CREATE_CORPORATE_USER_SUCCESS";
export type CREATE_CORPORATE_USER_SUCCESS = typeof CREATE_CORPORATE_USER_SUCCESS;

export const CREATE_CORPORATE_USER = "CREATE_CORPORATE_USER";

export const LOAD_CORPORATE_USERS_SUCCESS = "LOAD_CORPORATE_USERS_SUCCESS";
export type LOAD_CORPORATE_USERS_SUCCESS = typeof LOAD_CORPORATE_USERS_SUCCESS;

export const LOAD_CORPORATE_USERS = "LOAD_CORPORATE_USERS";

export const LOAD_CORPORATE_USER_DETAIL_SUCCESS = "LOAD_CORPORATE_USER_DETAIL_SUCCESS";
export type LOAD_CORPORATE_USER_DETAIL_SUCCESS = typeof LOAD_CORPORATE_USER_DETAIL_SUCCESS;

export const LOAD_CORPORATE_USER_DETAIL = "LOAD_CORPORATE_USER_DETAIL";

export const SAVE_CORPORATE_USER_SUCCESS = "SAVE_CORPORATE_USER_SUCCESS";
export type SAVE_CORPORATE_USER_SUCCESS = typeof SAVE_CORPORATE_USER_SUCCESS;

export const SAVE_CORPORATE_USER = "SAVE_CORPORATE_USER";
