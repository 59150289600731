import { Dispatch } from "redux";
import { initializeCurrentLocation } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { getCurrentUserSuccess, CurrentUserApi } from "@common/auth";
import { State as RouterState } from "redux-little-router";
import { businessLineFromRouter } from "src/CorporateBooking.App/src/hooks/useBusinessLineType";
import { AppCommonState } from "@common/appCommonState";
import { Apps } from "@common/model";

const initialise = async (dispatch: Dispatch, getState: () => RouterState & AppCommonState) => {

    const state = getState();
    const businessLineType = state.app === Apps.CorporateBooking ?  businessLineFromRouter(state) : null;
    const currentUser = await loadAndTrack(dispatch, "getCurrentUser", new CurrentUserApi().get(businessLineType));
    currentUser.loaded = true;
    await dispatch(getCurrentUserSuccess(currentUser));

    const initialLocation = state.router;

    if (initialLocation) {
        dispatch(initializeCurrentLocation(initialLocation));
    }
};

export function initialiseLocation() {
    return async (dispatch: Dispatch, getState: () => RouterState & AppCommonState) => {
        await initialise(dispatch, getState);
    };
}
