import * as React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "semantic-ui-react";

import { AllItems } from "./AllItems";
import { drinkDriveOffendersSelector, SelectorState } from "@common/crud/drinkDriveOffenders/selectors";
import { Link } from "redux-little-router";
import { DrinkDriveOffenderListModel } from "@common/crud/drinkDriveOffenders";
import { FileUpload } from "./FileUpload";
import { ReferralDocuments } from "./details/ReferralDocuments";

export interface AllProps {
    drinkDriveOffenders: DrinkDriveOffenderListModel[];
    path: string;
}

const AllInternal: React.FC<AllProps> = ({ path, drinkDriveOffenders }) => {

    return (
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <h1>Referral Documents</h1>
            </Grid.Row>
            <Grid.Row>
                <ReferralDocuments />
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h1>Drink Drive Offenders</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Button icon="add" content="Create" as={Link} href={`${path}/create`} />
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    <FileUpload
                        uploadLabel="Upload PDFs"
                        uploadApiPath="/api/document/ddrs-documents"
                        accept={["application/pdf"]}
                        multiple
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <AllItems drinkDriveOffenders={drinkDriveOffenders} path={path} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

function mapStateToProps(state: SelectorState): AllProps {
    return {
        path: state.router.pathname,
        drinkDriveOffenders: drinkDriveOffendersSelector(state)
    };
}

export const All = connect(mapStateToProps)(AllInternal);
