import { TypedTable } from "@common/crud/common/TypedTable";
import React from "react";
import { EventInstance } from "../..";
import { EventTrainer, Language, PracticalEventTrainer } from "../../model";
import { GetEventInstanceDateDisplay } from "../../helpers";
import { TrainerRoleColumn } from "./TrainerRoleColumn";
import classes from "./TrainerCourseConfirmationSummary.module.scss";

export function TrainerCourseConfirmationSummary({ eventInstances, trainerId }: {eventInstances: EventInstance[]; trainerId: string}) {

    return (
        <div className={classes.tableContainer}>
            <TypedTable
                values={eventInstances}
                emptyValuesArrayMessage=""
            >
                {
                    [
                        {
                            header: "Venue",
                            value: (e) => e.venueName
                        },
                        {
                            header: "Scheme/Product",
                            value: (e) => e.eventTypeName
                        },
                        {
                            header: "Language",
                            value: (e) => Language[e.language]
                        },
                        {
                            header: "Date/time",
                            value: GetEventInstanceDateDisplay
                        },
                        {
                            header: "Role",
                            value: (e) => <TrainerRoleColumn eventInstance={e} trainerId={trainerId} />
                        },
                        {
                            header: "Base fee (£)",
                            value: (e) => GetTotalFeeForTrainer(e, trainerId, "fee").toFixed(2)
                        },
                        {
                            header: "Sundries (£)",
                            value: (e) => GetTotalFeeForTrainer(e, trainerId, "sundries").toFixed(2)
                        },
                    ]
                }
            </TypedTable>
            <div className={classes.modalText}>Are you sure?</div>
        </div>);
}

function GetFeeFromTrainersByType(trainers: EventTrainer[] | PracticalEventTrainer[], trainerId: string, feeType: "fee" | "sundries") {
    return trainers?.find(t => t.id === trainerId)?.[feeType] ?? 0;
}

function GetTotalFeeForTrainer(eventInstance: EventInstance, trainerId: string, feeType: "fee" | "sundries") {
    return GetFeeFromTrainersByType(eventInstance.trainers, trainerId, feeType)
    + GetFeeFromTrainersByType(eventInstance.practicalTrainers, trainerId, feeType)
    + GetFeeFromTrainersByType(eventInstance.monitorTrainers, trainerId, feeType)
    + GetFeeFromTrainersByType(eventInstance.observerTrainers, trainerId, feeType);
}
