import * as React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AsyncDispatch, businessLineTypeSelector, organisationIdSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { PaymentResponse } from "@common/payments/model";
import { push } from "redux-little-router";
import { Button, Container, Grid, Segment } from "semantic-ui-react";
import { debounce } from "@neworbit/simpleui-utils";
import { useClearBeforeGenesysAuthPage } from "@common/genesys";
import { usePauseCallRecordingOnEntry } from "./usePauseCallRecordingOnEntry";
import { useReportCallRecordingStatus } from "./useReportCallRecordingStatus";
import { useResumeCallRecordingOnExit } from "./useResumeCallRecordingOnExit";
import { Basket, GenesysObjectType, OrderState, OrganisationPaymentRequest, StartOrganisationPaymentProcessRequest } from "../model";
import { loadOrderDetail, makePayment } from "../actions";
import { OrderApi } from "../orderApi";
import { OrderChosenCourse } from "./OrderChosenCourse";
import { queryEnquiryEventInstanceIdSelector, routeCorporateUserIdSelector } from "@common/crud/corporateUser/selectors";
import { prepareOrdersUrl } from "../helpers";
import "./OrderPaymentReview.scss";

interface StateProps {
    stripePaymentResponse: PaymentResponse;
}

interface DispatchProps {
    proceedWithPayment: (paymentRequest: OrganisationPaymentRequest) => Promise<PaymentResponse>;
    redirect: (url: string) => void;
}

interface OwnProps {
    startPaymentProcess?: StartOrganisationPaymentProcessRequest;
}

type OrderPaymentReviewProps = StateProps & DispatchProps & OwnProps;

const OrderPaymentReviewInternal: React.FC<OrderPaymentReviewProps> = (props) => {
    const [basket, setBasket] = React.useState<Basket>(undefined);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [pauseCallRecordingCheck, setPauseCallRecordingCheck] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);
    const corporateOrganisationId = useSelector(organisationIdSelector);
    const corporateUserId = useSelector(routeCorporateUserIdSelector);
    const enquiryEventInstanceId = useSelector(queryEnquiryEventInstanceIdSelector);

    React.useEffect(() => {
        const fetchData = async () => {
            const api = new OrderApi();

            const existingBasket = await api.getBasket(businessLineType, corporateOrganisationId, corporateUserId);
            setBasket(existingBasket);
        };

        fetchData();
    }, [businessLineType, corporateOrganisationId, corporateUserId]);

    const dispatch = useDispatch();

    useClearBeforeGenesysAuthPage();
    usePauseCallRecordingOnEntry(
        props.startPaymentProcess.basketId
            ? GenesysObjectType.Basket
            : GenesysObjectType.Order,
        props.startPaymentProcess.basketId
    );
    useReportCallRecordingStatus(
        props.startPaymentProcess.basketId
            ? GenesysObjectType.Basket
            : GenesysObjectType.Order,
        props.startPaymentProcess.basketId,
        pauseCallRecordingCheck
    );
    useResumeCallRecordingOnExit(
        props.startPaymentProcess.basketId
            ? GenesysObjectType.Basket
            : GenesysObjectType.Order,
        props.startPaymentProcess.basketId
    );

    const submit = React.useCallback(async (ev: any) => {
        ev.preventDefault();
        setDisabled(true);
        setErrorMessage(null);

        const card = elements.getElement(CardNumberElement);
        const paymentMethodData = await stripe.createPaymentMethod({ type: "card", card });

        const { startPaymentProcess } = props;

        if (paymentMethodData.paymentMethod) {
            const paymentRequest: OrganisationPaymentRequest = {
                businessLineType,
                basketId: startPaymentProcess.basketId,
                paymentMethodId: paymentMethodData.paymentMethod.id,
                paymentIntentId: "",
                amount: startPaymentProcess.amount,
                orderId: startPaymentProcess.orderId,
            };

            let paymentResponse: PaymentResponse;
            try {
                setPauseCallRecordingCheck(true);
                paymentResponse = await props.proceedWithPayment(paymentRequest);
            }
            finally {
                setPauseCallRecordingCheck(false);
            }

            if (paymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                handleAction(paymentResponse);
            } else if (paymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else {
                if (paymentResponse.createdOrderId)  {
                    dispatch(loadOrderDetail({ orderId: paymentResponse.createdOrderId, force: true }));

                    const url = prepareOrdersUrl(eventManagementPath, `orders/${paymentResponse.createdOrderId}`, corporateOrganisationId, corporateUserId);

                    if (enquiryEventInstanceId) {
                        window.open(url, "_blank");
                    }

                    props.redirect(enquiryEventInstanceId
                        ? `${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`
                        : url
                    );
                } else {
                    props.redirect(enquiryEventInstanceId
                        ? `${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`
                        : prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId));
                }
            }
        }
        if (paymentMethodData.error) {
            showErrorAndEnable(paymentMethodData.error.message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, props.startPaymentProcess, props.proceedWithPayment, businessLineType, eventManagementPath, enquiryEventInstanceId]);

    const handleAction = async (response: PaymentResponse) => {
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
            response.paymentIntentClientSecret
        );

        // eslint-disable-next-line eqeqeq
        if (errorAction != null) {
            setShowError(true);
        } else {
            const { startPaymentProcess } = props;
            const paymentRequest: OrganisationPaymentRequest = {
                businessLineType,
                basketId: basket.id,
                paymentMethodId: "",
                paymentIntentId: paymentIntent.id,
                amount: startPaymentProcess.amount,
                orderId: startPaymentProcess.orderId,
            };

            const paymentResponse = await props.proceedWithPayment(paymentRequest);

            if (paymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else if (paymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                await handleAction(paymentResponse);
            } else {
                if (paymentResponse.createdOrderId)  {
                    dispatch(loadOrderDetail({ orderId: paymentResponse.createdOrderId, force: true }));
                    props.redirect(`${eventManagementPath}/orders/${paymentResponse.createdOrderId}`);
                } else {
                    props.redirect(`${eventManagementPath}/orders`);
                }
            }
        }
    };

    const getErrorDiv = (message: string) => {
        return (
            <div className="payment-error">
                <p>{message}</p>
            </div>);
    };

    const getErrorSection = () => {
        if (!showError) {
            return (<></>);
        }

        if (props.stripePaymentResponse?.validRequest === false) {
            const message = props.stripePaymentResponse.paymentError
                ? props.stripePaymentResponse.paymentError
                : "Your payment has failed";

            return getErrorDiv(message);
        }
        if (errorMessage) {
            return getErrorDiv(errorMessage);
        }

        return (<></>);
    };

    const getStripeContainer = () => {
        return (
            <>
                <Grid.Row className="margin-top-half">
                    <Grid.Column width={16}>
                        <p>Card Number</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardNumberElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Expiry Date</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardExpiryElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Security Code</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardCvcElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {getErrorSection()}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const showErrorAndEnable = (currErrorMessage?: string) => {
        setShowError(true);
        setDisabled(false);

        setErrorMessage(currErrorMessage);
    };

    return (
        <>
            <Segment>
                <h3>Payment details</h3>
                <p>Please enter card details below</p>
            </Segment>
            <Grid className="two-section-layout">
                <Grid.Row>
                    <Grid.Column width={10} className="two-section-layout-left border-top">
                        <Grid.Row>
                            <Container className="indented">
                                {getStripeContainer()}
                                <span>
                                    <img src="/assets/stripe-logo.png" className="stripe-logo" alt="Powered by Stripe" />
                                </span>
                                <span className="float-right accepted-cards">
                                    <img src="/assets/2_Card_color_horizontal.png" alt="Mastercard Visa" />
                                </span>
                            </Container>
                        </Grid.Row>
                        <Grid.Row>
                            <Button className="order-payment-button" disabled={disabled} onClick={submit}>Pay</Button>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={6} className="two-section-layout-right booking-details-column no-padding-left">
                        <Grid.Row>
                            <OrderChosenCourse basket={basket} />
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

function mapStateToProps(state: OrderState): StateProps {
    return {
        stripePaymentResponse: state.orderPaymentsState.stripePaymentResponse,
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        proceedWithPayment: debounce((paymentRequest: OrganisationPaymentRequest) => dispatch(makePayment(paymentRequest)), 500),
        redirect: (url) => dispatch(push(url))
    };
}

export const OrderPaymentReview = connect(mapStateToProps, mapDispatchToProps)(OrderPaymentReviewInternal);
