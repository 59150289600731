// If we are using an Object literal then we are safe to cast
// https://www.mattstobbs.com/object-keys-typescript/

export function ObjectKeys<T extends object>(obj: T) {
    return Object.keys(obj) as (keyof typeof obj)[];
}

export function ObjectEntries<T extends object>(obj: T) {
    return Object.entries(obj) as [keyof typeof obj, T[keyof T]][];
}
