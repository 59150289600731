import { Location } from "redux-little-router";
import moment from "moment";
import { Seat } from "@booking/seat";
import { IvrPaymentStatusModel } from "@common/crud/dorsBooking/model";
import { EventTypeCategory } from "@common/crud/attendee/model";

export enum CreateStartPaymentProcessRequestResponseStatus {
    Ok = 0,
    BookedAtTheoreticalMaximum = 1,
    FullAndIncludeFullyBookedNotSelected = 2,
    UnableToNotifyDors = 3,
    BookingSessionExpired = 4
}

export interface CreateStartPaymentProcessRequestResponse {
    startPaymentProcessRequest?: StartPaymentProcessRequest;
    status: CreateStartPaymentProcessRequestResponseStatus;
}

export interface StartPaymentProcessRequest {
    id: string;
    orderId: string;
    amount: number;
    fee?: number;
    fullEventInstancePrice?: number;
    correlationId: string;
    relatedObjectId: string;
    startPaymentProcessMultiPartModels: StartPaymentProcessMultiPartModel[];
    eventInstanceId: string;
    stripePublishKey: string;
    plan?: ScheduledPaymentPlanModel[];
    waivedRebookingFee?: boolean;
    isNewBooking?: boolean;
    isOutstandingBalancePayment?: boolean;
    flexiPayPurchased?: boolean;
    flexiPayPurchasedAfterBooking?: boolean;
    flexiPayFee?: number;
    eventTypeCategory?: EventTypeCategory;
}

export interface SkipPaymentAndBookResult {
    status: CreateStartPaymentProcessRequestResponseStatus;
    seat?: Seat;
}

export interface StartPaymentProcessModel {
    objectRelatedTo: string;
    eventInstanceId: string;
    bookingId?: string;
    partialPaymentModel?: PartialPaymentModel;
    scheduledPaymentPlanModel?: ScheduledPaymentPlanModel[];
    includeFullyBookedCourses: boolean;
    flexiPayPurchased?: boolean;
    flexiPayPurchasedAfterBooking?: boolean;
    flexiPayFee?: number;
    startPaymentProcessMultiPartModels: StartPaymentProcessMultiPartModel[];
    groupId?: string;
}

export interface StartPaymentProcessMultiPartModel {
    eventInstanceId: string;
    relatedObjectId: string;
    dayPart: number;
}

export interface ScheduledPaymentPlanModel {
    chargeDate?: moment.Moment;
    amount?: number;
}

export interface ScheduledPaymentPlanViewModel extends ScheduledPaymentPlanModel {
    id: string;
    index: number;
    validationMessage?: string;
    isAmountDirty: boolean;
    isDateDirty: boolean;
}

export interface PartialPaymentModel {
    amount: number;
    isWaivingRebookingFee?: boolean;
}

export interface PaymentRequest {
    paymentMethodId: string;
    paymentIntentId: string;
    amount: number;
    startPaymentProcessMultiPartModels: StartPaymentProcessMultiPartModel[];
    readonly eventInstanceId: string;
    orderId: string;
    correlationId: string;
    plan: ScheduledPaymentPlanModel[];
    waivedRebookingFee: boolean;
    isNewBooking: boolean;
    eventTypeCategory?: EventTypeCategory;
}

export interface PaymentResponse {
    validRequest: boolean;
    paymentError?: string;
    paymentAuthorised: boolean;
    requiresAction: string;
    paymentIntentClientSecret: string;
    noAvailability: boolean;
    reservationHasExpired: boolean;
    paymentAlreadyReceived: boolean;
    duplicateRequest: boolean;
    serverError?: boolean;
    amount?: number;
    createdOrderId?: string;
}

export interface PaymentSuccessPayload {
    paymentResponse: PaymentResponse;
    amount: number;
    attendeeId: string;
}

export interface BookingPaymentsInfo {
    amountPaid: number;
    totalAmountDue: number;
    fees: number;
    scheduledPaymentPlans: ScheduledPaymentPlanDetailModel[];
    mostRecentPaymentValue: number;
    flexiPayFees: number;
    startPaymentProcessMultiPartModels: StartPaymentProcessMultiPartModel[];
    seatPrice: number;
}

export interface IvrPaymentDetail {
    amount: number;
    paymentStatusModel: IvrPaymentStatusModel;
    scheduledPaymentPlans: ScheduledPaymentPlanDetailModel[];
}

export interface PaymentDetailsForTransfer {
    amount: number;
    isSuitableForTransfer: boolean;
    paymentId: string;
    transferIssue: string;
    paymentDate: moment.Moment;
}

export interface PaymentState {
    readonly startPaymentProcess: StartPaymentProcessRequest;
    readonly stripePaymentResponse: PaymentResponse;
}

export interface ScheduledPaymentPlanDetailModel {
    id: string;
    chargeDate: moment.Moment;
    amountInPence: number;
    status: ScheduledPaymentStatus;
}

export enum ScheduledPaymentStatus {
    None = 0,
    Awaits = 1,
    Processing = 2,
    FinishedWithSuccess = 3,
    FinishedWithError = 4,
    Cancelled = 5
}

export enum PaymentType {
    None = 0,
    Card = 1,
    ChequeOrPO = 2,
    Cash = 3,
    Proof = 4,
    FakePayment = 5,
}

export type AppState = PaymentState & { router: Location };

export enum PaymentPageNamesEnum {
    ReviewPayment = 0,
    ManualPayment = 1,
    GenesysAuthorisation = 2,
    GenesysRedirect = 3,
    FlexiPayPayment = 4,
}

export const PaymentPageNames = {
    [PaymentPageNamesEnum.ReviewPayment]: "review-payment",
    [PaymentPageNamesEnum.ManualPayment]: "manual-payment",
    [PaymentPageNamesEnum.GenesysAuthorisation]: "genesys-auth",
    [PaymentPageNamesEnum.GenesysRedirect]: "genesysRedirect",
    [PaymentPageNamesEnum.FlexiPayPayment]: "flexipay-payment",
};

export interface AutoPaymentProcessModel {
    genesysToken: string;
    paymentProcessModel: StartPaymentProcessModel;
}

export interface AutoPaymentProcessResult {
    paymentProcessStatus:  CreateStartPaymentProcessRequestResponseStatus;
    ivrSessionCreated: boolean;
    invalidCallInfo: boolean;
}
