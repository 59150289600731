import * as React from "react";
import { Tab } from "semantic-ui-react";
import { HistoryRecord, HistoryVersion } from "@common/history/model";
import { NewHistoryBaseComponent } from "@common/history/components/NewHistoryBaseComponent";
import { OldHistoryBaseComponent } from "./oldHistory/OldHistoryBaseComponent";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { EventTypeCategory } from "@common/crud/attendee/model";

export interface HistoryTabProps {
    correlationId: string;
    historyVersion: HistoryVersion;
    historyRecords: HistoryRecord[];
    relatedTo: TaskRelationEnum;
    showDetailsDefault: boolean;
    eventTypeCategory: EventTypeCategory;
}

export const HistoryTab: React.FC<HistoryTabProps> = ({ historyRecords, historyVersion, correlationId, relatedTo, showDetailsDefault, eventTypeCategory }) => {
    return (
        <Tab.Pane className="no-border">
            {historyVersion === HistoryVersion.V1 ?
                <OldHistoryBaseComponent
                    attendeeHistoryRecords={historyRecords}
                /> :
                <NewHistoryBaseComponent
                    showDetailsDefault={showDetailsDefault}
                    correlationId={correlationId}
                    historyRecords={historyRecords}
                    relatedTo={relatedTo}
                    eventTypeCategory={eventTypeCategory}
                />
            }
        </Tab.Pane>
    );
};
