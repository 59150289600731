import i18next from "i18next";

import { Payload } from "@neworbit/redux-helpers";
import moment from "moment";

import { SET_CURRENT_LANGUAGE } from "./actionTypes";

export type LanguageAction = ({ type: typeof SET_CURRENT_LANGUAGE } & Payload<string>);

export const setCurrentLanguage = (language: string): LanguageAction => {
    i18next.changeLanguage(language);
    moment.locale(language);
    return {
        type: SET_CURRENT_LANGUAGE,
        payload: language
    };
};
