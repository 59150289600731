import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { toast } from "@common/toasts";
import * as actions from "./actiontypes";
import * as models from "./model";
import { corporateUserFilterQuerySelector } from "./selectors";
import { CorporateUserApi } from "./corporateUserApi";

export type CorporateUserAction =
    ({ type: actions.CREATE_CORPORATE_USER_SUCCESS } & Payload<models.CorporateUserDetailModel>)
    | ({ type: actions.LOAD_CORPORATE_USERS_SUCCESS } & Payload<models.CorporateUserListModel[]>)
    | ({ type: actions.LOAD_CORPORATE_USER_DETAIL_SUCCESS } & Payload<models.CorporateUserDetailModel>)
    | ({ type: actions.SAVE_CORPORATE_USER_SUCCESS } & Payload<models.CorporateUserDetailModel>);

export const createCorporateUserSuccess = (payload: models.CorporateUserDetailModel): CorporateUserAction => ({
    payload,
    type: actions.CREATE_CORPORATE_USER_SUCCESS
});

export const loadCorporateUsersSuccess = (payload: models.CorporateUserListModel[]): CorporateUserAction => ({
    payload,
    type: actions.LOAD_CORPORATE_USERS_SUCCESS
});

export const loadCorporateUserDetailSuccess = (corporateUser: models.CorporateUserDetailModel): CorporateUserAction => ({
    payload: corporateUser,
    type: actions.LOAD_CORPORATE_USER_DETAIL_SUCCESS
});

export const saveCorporateUserSuccess = (payload: models.CorporateUserDetailModel): CorporateUserAction => ({
    payload,
    type: actions.SAVE_CORPORATE_USER_SUCCESS
});

export function createCorporateUser(corporateUser: models.CorporateUserCreateEditModel, path: string) {
    return async (dispatch: Dispatch) => {
        const api = new CorporateUserApi();
        const result = await loadAndTrack(dispatch, actions.CREATE_CORPORATE_USER, api.create(corporateUser));
        dispatch(createCorporateUserSuccess(result));
        dispatch(push(`${path}/${result.id}`));
    };
}

export function loadCorporateUsersFromQuery() {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const corporateUserFilterQuery = corporateUserFilterQuerySelector(getState());
        const api = new CorporateUserApi();
        const result = await loadAndTrack(dispatch, actions.LOAD_CORPORATE_USERS, api.getAll(corporateUserFilterQuery));
        dispatch(loadCorporateUsersSuccess(result));
    };
}

export function loadCorporateUserDetail({ corporateUserId, overrideCheck }:
    { organisationId: string; corporateUserId?: string; overrideCheck?: boolean }) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const corporateUser = getState().corporateUsers.find(c => c.id === corporateUserId);

        if (!corporateUser
            || !corporateUser.detailUpdated
            || corporateUser.detailUpdated.isBefore(corporateUser.listUpdated) || overrideCheck) {

            const action = !corporateUser ? createCorporateUserSuccess : loadCorporateUserDetailSuccess;

            const api = new CorporateUserApi();
            const result = await loadAndTrack(dispatch, actions.LOAD_CORPORATE_USER_DETAIL, api.detail(corporateUserId));
            dispatch(action(result));
        }
    };
}

export function saveCorporateUser(corporateUser: models.CorporateUserCreateEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new CorporateUserApi();
        const result = await loadAndTrack(dispatch, actions.SAVE_CORPORATE_USER, api.save(corporateUser));

        if (result.errorUpdatingEmail) {
            toast.error("Could not update email as it is already in use!");
        }

        if (!result.errorUpdatingEmail) {
            dispatch(saveCorporateUserSuccess(result));
            toast.success("User successfully edited");
        }

        if (path !== undefined && !result.errorUpdatingEmail) {
            dispatch(push(`${path}`));
        }
    };
}
