import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import moment from "moment";
import { CreateFieldPickerProps } from "../../model";
import { MuiDateField } from "@common/components/MuiDateField";
import { toast } from "react-toastify";

interface ValueAndDateInputProps {
    showErrors: boolean;
    onChange: (value: any, date: moment.Moment, valid: boolean) => void;
    CreateFieldPicker?: (props: CreateFieldPickerProps<any>) => JSX.Element;

}

export const NumberValueAndDateInput: React.FC<ValueAndDateInputProps> = ({ onChange, showErrors,
    CreateFieldPicker }) => {

    const [value, setValue] = React.useState<any>();
    const [date, setDate] = React.useState<moment.Moment>();
    const [valid, setValid] = React.useState<boolean>();

    function onAddValue() {
        event.preventDefault();
        if ((value.hours && value.minutes && value.hours() === 0 && value.minutes() === 0) ||
            !date || !date.isValid() || !valid) {
            toast.warning("Please provide valid data");
            return;
        }

        onChange(value, date, true);
        setValue(0);
    }

    function onValueChange(newValue: any, isValid: boolean) {
        setValue(newValue);
        setValid(isValid);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={6}>
                    <MuiDateField
                        value={date}
                        onChange={setDate}
                        label={"Effective Date"}
                        showErrors={showErrors}
                        required
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    { CreateFieldPicker({ field: value, setField: onValueChange, showErrors })}
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button style={{ marginTop: "9%" }} onClick={onAddValue}>Add</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
