/* eslint-disable eqeqeq */
import * as React from "react";
import { Modal, Button, Checkbox, Form, Popup } from "semantic-ui-react";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateAutoRemovalDate } from "../../actions";
import { Attendee } from "../..";
import { MuiDateField } from "@common/components/MuiDateField";

export interface AutoRemovalDateLabelWithEditProps {
    attendee: Attendee;
    eventInstanceDate: moment.Moment;
    disabled: boolean;
}

export const AutoRemovalDateLabelWithEdit: React.FC<AutoRemovalDateLabelWithEditProps> = ({ attendee, eventInstanceDate, disabled }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [autoRemovalDate, setAutoRemovalDate] = React.useState<moment.Moment>(attendee?.autoRemovalDate?.clone() ?? null);
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [excludeAttendeeFromAutoremovalProcess, setExcludeAttendeeFromAutoremovalProcess] = React.useState<boolean>(!!attendee?.autoRemovalDate);
    const dispatch = useDispatch();

    useEffect(() => {
        setAutoRemovalDate(attendee?.autoRemovalDate?.clone() ?? null);
        setExcludeAttendeeFromAutoremovalProcess(!attendee?.autoRemovalDate);
    }, [attendee.autoRemovalDate]);

    useEffect(() => {
        const eventInstanceDateMinusOne = eventInstanceDate?.clone().subtract(1, "day");
        const eventInstanceDateMinusSeven = eventInstanceDate?.clone().subtract(7, "day");

        setIsValid(autoRemovalDate == null ||
            (autoRemovalDate != null && autoRemovalDate.isBetween(eventInstanceDateMinusSeven, eventInstanceDateMinusOne, "day", "[]")));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRemovalDate, excludeAttendeeFromAutoremovalProcess]);

    const onAutoRemovalDateUpdate = React.useCallback((date: moment.Moment) => {
        setAutoRemovalDate(date);
    }, []);

    const setExcludedFromAutoRemovalProcess = (value: boolean) => {
        setExcludeAttendeeFromAutoremovalProcess(value);

        if (value) {
            setAutoRemovalDate(null);
        } else {
            setAutoRemovalDate(moment());
        }
    };

    const submit = React.useCallback(() => {
        dispatch(updateAutoRemovalDate(attendee.id, attendee.eventInstanceId, { autoRemovalDate }));
        setOpen(false);
    }, [dispatch, attendee.id, attendee.eventInstanceId, autoRemovalDate]);

    const onPopupClicked = React.useCallback(() => {
        if (!disabled) {
            setOpen(!open);
        }
    }, [disabled, open]);

    const onCancelClicked = React.useCallback(() => {
        setAutoRemovalDate(attendee.autoRemovalDate);
        setOpen(false);
    }, [attendee.autoRemovalDate]);

    const onExcludeClicked = React.useCallback((_e, { checked }) => setExcludedFromAutoRemovalProcess(checked), []);

    return (
        <>
            <div className="label-and-value">
                <span className="label fixed-width">
                    Auto Removal Date
                </span>
                <Popup
                    disabled={!disabled}
                    content="Auto removal process is disabled for digital courses."
                    on="click"
                    pinned
                    trigger={<a
                        className={`show-button ${disabled ? "disabled" : ""}`}
                        onClick={onPopupClicked}
                    >
                        Update Auto Removal Date
                    </a>}
                />
                <div className="value">
                    {autoRemovalDate != null && autoRemovalDate.format(DateFormat.Short) || "Attendee excluded from auto removal process"}
                </div>
            </div>
            <Modal open={open}>
                <Modal.Header>
                    Update Auto Removal Date
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Please select new attendee auto removal date</label>
                            <MuiDateField
                                value={autoRemovalDate}
                                onChange={onAutoRemovalDateUpdate}
                                disabled={excludeAttendeeFromAutoremovalProcess}
                            />
                            {!isValid && <span>Date needs to be between 7 and 1 day(s) before the EI.</span>}
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label="Exclude attendee from auto removal process"
                                type="checkbox"
                                checked={excludeAttendeeFromAutoremovalProcess}
                                onChange={onExcludeClicked}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onCancelClicked}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid}
                        onClick={submit}
                        icon="checkmark"
                        content="Yes"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
