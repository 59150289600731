import * as React from "react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Container, Dropdown, Header, DropdownProps, Divider } from "semantic-ui-react";
import { bulkSendSms } from "../../actions";
import { toast } from "@common/toasts";
import { BulkEmailConfirmationModal } from "./BulkEmailConfirmationModal";
import { useDispatch } from "react-redux";
import { useAdHocTemplates, AdhocTemplateType } from "@common/hooks/useAdHocTemplates";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";

export const BulkSmsAction: React.FC<SubActionModalProps> = ({
    triggerDispatchAction,
    setActionComplete,
    selectedEventInstanceIds,
    setTriggerDispatchAction }) => {
    const templates = useAdHocTemplates(AdhocTemplateType.SMS);
    const [selectedTemplateName, setSelectedTemplateName] = React.useState<string>();
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [templateBody, setTemplateBody] = React.useState<string>();
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            if (triggerDispatchAction) {
                if (!templateBody) {
                    toast.warning("You need to chose the template");
                    setTriggerDispatchAction(false);
                    return;
                }

                setSelectedTemplateName(templates.find(x => x.value === templateBody).text as string);
                setOpenConfirmation(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [triggerDispatchAction]);

    const onAccept = React.useCallback(() => {
        dispatch(bulkSendSms(templateBody, selectedTemplateName, selectedEventInstanceIds));
        setActionComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEventInstanceIds, selectedTemplateName, templateBody]);

    const onCancel = React.useCallback(() => {
        setTriggerDispatchAction(false);
        setOpenConfirmation(!openConfirmation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openConfirmation]);

    const onChange = React.useCallback((_: any, { value }: DropdownProps) => {
        setTemplateBody(value as string);
        if (value) {
            setTriggerDispatchAction(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateBody, templates, selectedEventInstanceIds, openConfirmation]);

    return (
        <Container>
            <Header size="small">Please choose the template that you'd like to send.</Header>
            <Dropdown
                placeholder='Select sms template'
                fluid
                selection
                options={templates}
                onChange={onChange}
            />
            <Container>
                <Divider />
                {!templateBody && <span>In order to see preview please select template.</span>}
                {templateBody && <HtmlRenderer value={templateBody} />}
                <Divider />
            </Container>
            <BulkEmailConfirmationModal
                eventInstanceIds={selectedEventInstanceIds}
                onCancel={onCancel}
                open={openConfirmation}
                onConfirm={onAccept}
                templateName={selectedTemplateName}
            />
        </Container >
    );
};
