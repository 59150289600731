import * as React from "react";
import { useSelector } from "react-redux";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "../selectors";
import { EiSideBar } from "./EiSideBar";
import { CoreDetails } from "@common/crud/eventInstance/components/details/CoreDetails";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";

export const Detail: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const group = useSelector(eventInstanceGroupByIdSelector(eventInstance.groupId)) as EventInstanceGroupModel;

    const content = (
        <CoreDetails eventInstance={eventInstance} group={group} />
    );

    return (
        <EiSideBar children={content} />
    );
};
