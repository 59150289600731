import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const SectionFourDiversityAndInclusion: QuestionSection = {
    id: "4",
    title: "Section 4 – Diversity and Inclusion",
    seqNum: 3,
    subSections: [
        {
            id: "4.1",
            text: "Did the trainer interact with clients in a way that respects beliefs, cultures, values and preferences?",
            seqNum: 1
        },
        {
            id: "4.2",
            text: "Did the trainer demonstrate and promote a positive image of the scheme? (i.e. explaining the ‘respect’ element and setting ground rules)",
            seqNum: 2,
        },
        {
            id: "4.3",
            text: "Did the trainer challenge discrimination in a way that promotes Diversity and Inclusion?",
            seqNum: 3,
        },
        {
            id: "4.4",
            text: "Had the trainer been notified of any clients with additional requirements?",
            seqNum: 4,
        },
        {
            id: "4.5",
            text: "If you answered yes to the above question, were the client’s additional requirements catered for?",
            seqNum: 5,
        }],
    summaryCriterium: [{ id: "4.5", text: "", typeEnum: CriteriaEnum.text }],
    IsQuestionSection: true
};
