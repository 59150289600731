import moment from "moment";

export interface NudgeTaskBaseListModel {
    readonly id: string;
    readonly taskType?: number;
    readonly title?: string;
    readonly status?: number;
    readonly startDate?: moment.Moment;
    readonly deadline?: moment.Moment;
    readonly completedDate?: moment.Moment;
    readonly listUpdated?: moment.Moment;
}

export interface NudgeTaskBaseDetailModel {
    readonly id: string;
    readonly completionType?: CompletionTypeEnum;
    readonly title?: string;
    readonly description?: string;
    readonly status?: TaskStatusEnum;
    readonly ownerType?: OwnerTypeEnum;
    readonly properties?: NudgeTaskPropertyBag;
    readonly startDate?: moment.Moment;
    readonly deadline?: moment.Moment;
    readonly dropDeadDate?: moment.Moment;
    readonly completedDate?: moment.Moment;
    readonly completedBy?: string;
    readonly completionNotes?: string;
    readonly detailUpdated?: moment.Moment;
    readonly eventInstanceDeliveryDate?: moment.Moment;
    readonly pendingEventInstanceDeliveryDate?: moment.Moment;
}

export interface NudgeTaskTypeModel {
    readonly id: number;
    readonly title: string;
}

export interface NudgeTaskCompleteModel {
    id: string;
    completionNotes: string;
}

export type NudgeTaskType = NudgeTaskTypeModel;

export interface NudgeTaskTypeState {
    readonly taskTypes: NudgeTaskTypeModel[];
}

export interface AlaskaNudgeTaskTypeState {
    readonly alaskaTaskTypes: NudgeTaskTypeModel[];
}

export interface PendingNoteCreatorsState {
    readonly pendingNoteCreators: string[];
}

export interface TrainersWhoHaveBeenAssignedState {
    readonly trainersAssignedOnNudge: string[];
}

export interface CaEmailsState {
    readonly caEmails: string[];
    readonly seniorCaEmails: string[];
}

export interface CorporateAdminEmailsState {
    readonly corporateAdminEmails: string[];
};

export enum TaskStatusEnum {
    Any = 1,
    Pending = 10,
    Overdue = 20,
    Urgent = 23,
    Open = 25,
    CompletedOnTime = 30,
    CompletedLate = 40,
    Completed = 45,
    ClosedUnfinished = 50
}

export enum OwnerTypeEnum {
    Driver = 1,
    Department = 2,
    ClientAdvisor = 3,
    SeniorClientAdvisor = 4,
    IqaTeam = 5
}

export const TaskStatus = {
    0: "Unknown",
    1: "Any",
    10: "Pending",
    20: "Overdue",
    23: "Urgent",
    25: "Open",
    30: "Completed On Time",
    40: "Completed Late",
    45: "Completed",
    50: "Closed Unfinished"
};

export const SearchPeriod = {
    0: "Unknown",
    1: "Last Week",
    2: "Last Month",
    3: "Last 6 Months",
    4: "Last Year"
};

export enum SearchPeriodEnum {
    "Unknown" = 0,
    "LastWeek" = 1,
    "LastMonth" = 2,
    "Last 6 Months" = 3,
    "Last Year" = 4
}

export enum CompletionTypeEnum {
    Automatic = 1,
    Manual = 2
}

export type PropertyName =
    "CourseTitle" |
    "DriverName" |
    "DriverId" |
    "VenueAttributes" |
    "EventAttributes" |
    "OtherRequirements" |
    "InterpreterName" |
    "Issue" |
    "ExpiryDate" |
    "Reason" |
    "ChangeDirection" |
    "ReasonIssue" |
    "TrainerName" |
    "TrainerId" |
    "Trainers" |
    "TrainerNote" |
    "AdhocTemplateName" |
    "AdhocTemplateId" |
    "PendingNoteContent" |
    "EmailOfCaWhoCreatedNote" |
    "TrainersRequired" |
    "TrainersInterestedNumber" |
    "CompletionStatus" |
    "Scheme" |
    "TrainersAllocated" |
    "OutsideUkCountryName" |
    "EmailOfUserWhoCreatedNudge" |
    "MonitoredTrainerName" |
    "MonitorName" |
    "AllocatedSupervisorEmail" |
    "ErrorMessage" |
    "MissingProperties" |
    "CarType" |
    "SpecialCarDetails" |
    "ReferringPoliceForce" |
    "EmailOfPoliceUserWhoCreatedNote" |
    "ExpiredBy" |
    "ExpiredByEmail" |
    "SubmittedBy" |
    "EventInstanceTrainerIds" |
    "CourseId" |
    "OrganisationName" |
    "BaseFee" |
    "InvoiceTotal" |
    "InternalProductId" |
    "PracticalTrainersAllocated" |
    "PracticalTrainersRequired" |
    "BookerId" |
    "BookerName" |
    "EventInstancesAffected" |
    "PurchaseOrderNumbers" |
    "CourseCreatedDate" |
    "Company" |
    "AttendeesWithRejectedReports" |
    "BreakEvenPoint" |
    "ActiveEnquiryCount";

export type NudgeTaskPropertyBag = Record<PropertyName, string>;
