import * as React from "react";
import { Modal } from "@common/components";
import { Button, Table } from "semantic-ui-react";
import { BasketComparisonResult, CorporateEventInstanceResultRow } from "../model";
import { OrderApi } from "../orderApi";
import { orderBy } from "lodash";
import { BasketDifferenceItem } from "./BasketDifferenceItem";
import { numberFormatter } from "@common/helpers/number-format";
import "./RelatedOrganisationChangedModal.scss";
import { useSelector } from "react-redux";
import { businessLineTypeSelector } from "@common/redux-helpers";

export interface RelatedOrganisationChangedModalProps {
    basketComparisonResult: BasketComparisonResult;
    close: () => void;
}

export const RelatedOrganisationChangedModal: React.FC<RelatedOrganisationChangedModalProps> = ({ basketComparisonResult, close }) => {
    const [coursesFromIds, setCoursesFromIds] = React.useState<CorporateEventInstanceResultRow[]>([]);

    const businessLineType = useSelector(businessLineTypeSelector);

    React.useEffect(() => {
        const courseIds = basketComparisonResult?.differences?.map(x => x.eventInstanceId) || [];

        // fetch data only when some course from ids is missing basket item
        if (courseIds.length === 0 || courseIds.every(id => coursesFromIds.some(course => course.id === id))) {
            return;
        }

        const fetchData = async () => {
            const orderApi = new OrderApi();
            const newCoursesFromIds = await orderApi.getBookedCoursesFromIds(businessLineType, courseIds);
            setCoursesFromIds(newCoursesFromIds);
        };

        fetchData();
    }, [basketComparisonResult, coursesFromIds, businessLineType]);

    const memoizedOrderedBasketDifferencesWithCourses = React.useMemo(() => {
        const basketDifferences = basketComparisonResult?.differences || [];
        const basketDifferencesWithCourse = basketDifferences.map(basketDifference => ({
            basketDifference,
            course: coursesFromIds.find(c => c.id === basketDifference.eventInstanceId)
        }));
        return orderBy(basketDifferencesWithCourse, ["course.startTime"], ["asc"]);
    }, [basketComparisonResult?.differences, coursesFromIds]);

    const showBasket = React.useMemo(() => basketComparisonResult?.differences?.length > 0, [basketComparisonResult?.differences?.length]);

    return (
        <Modal open={showBasket}>
            <Modal.Header>
                Basket has been modified due to related organisation change
            </Modal.Header>
            <Modal.Content>
                {showBasket && (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={3}>Course</Table.HeaderCell>
                                <Table.HeaderCell colSpan={6}>Price per Seat</Table.HeaderCell>
                                <Table.HeaderCell colSpan={1}>Places</Table.HeaderCell>
                                <Table.HeaderCell colSpan={6}>Cost</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {memoizedOrderedBasketDifferencesWithCourses.map((basketDifferenceWithCourse, index) => {
                                return (
                                    <BasketDifferenceItem
                                        key={`basketDifferenceItem_${index}`}
                                        basketDifference={basketDifferenceWithCourse.basketDifference}
                                        course={basketDifferenceWithCourse.course}
                                    />
                                );
                            })}
                        </Table.Body>
                        <Table.Footer fullWidth>
                            {basketComparisonResult.discountChanged && (
                                <>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={16} className="discount-padding" />
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={10} className="discount-box-left">
                                            <div className="cell-with-rows">
                                                <span className="bolden-summary">Discount</span>
                                                <span>Course bundle discount</span>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell colSpan={6} className="discount-box-right">
                                            <span className="old-value">
                                                {basketComparisonResult.oldDiscount > 0 ? "-" : ""}
                                                &pound;{numberFormatter(basketComparisonResult.oldDiscount / 100)}
                                            </span>
                                            <span> =&gt; </span>
                                            <span className="new-value">
                                                {basketComparisonResult.newDiscount > 0 ? "-" : ""}
                                                &pound;{numberFormatter(basketComparisonResult.newDiscount / 100)}
                                            </span>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={16} className="discount-padding-without-border" />
                                    </Table.Row>
                                </>
                            )}
                            <Table.Row>
                                <Table.HeaderCell colSpan={10} className="bolden-summary">Total</Table.HeaderCell>
                                <Table.HeaderCell colSpan={6}>
                                    <span className="old-value">&pound;{numberFormatter(basketComparisonResult.oldTotal / 100)}</span>
                                    <span> =&gt; </span>
                                    <span className="new-value">&pound;{numberFormatter(basketComparisonResult.newTotal / 100)}</span>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close}>
                    Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
