import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Message } from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { bookingBasePathSelector } from "../../landing/selectors";
import { cancelRebookingProcess } from "../../landing/actions";
import { ApplicationState } from "../../applicationState";
import { loadRebookEventInstanceAndVenueDetail, clearRebookEventInstanceAndVenueDetail } from "../../rebookEventInstance/actions";
import { AttendeeApi } from "@booking/attendee/attendeeApi";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { isUserClientAdvisor } from "@common/crud/common/selectors";

export const RebookInformation: React.FC = () => {

    const booking = useSelector((state: ApplicationState) => state.booking);
    const internalAppSettings = useSelector((state: ApplicationState) => state.appSettings.internalAppSettings);
    const bookingBasePath = useSelector((state: ApplicationState) => bookingBasePathSelector(state));
    const [t] = useTranslation("RebookInformation");
    const dispatch = useDispatch();

    const rebookEventInstance = useSelector((state: ApplicationState) => state.rebookEventInstance);
    const rebookVenue = useSelector((state: ApplicationState) => state.rebookVenue);

    const [attendeeId, setAttendeeId] = useState<string>(null);

    const isRebooking = booking.isRebooking ?? false;
    const isClientAdvisor = useSelector(isUserClientAdvisor);

    React.useEffect(() => {
        if (isRebooking && isClientAdvisor) {
            const attendeeApi = new AttendeeApi(booking.id);
            attendeeApi.getAttendeeId(booking.originalSeatReservationId, booking.originalEventInstanceId)
                .then(setAttendeeId);
        }
    }, [isClientAdvisor, isRebooking, booking.originalSeatReservationId, booking.originalEventInstanceId, booking.id]);

    const cancelRebooking = React.useCallback(() => {
        const redirectUrl = isClientAdvisor
            ? internalAppSettings.adminAppEndpoint + "police-and-court-event-management/eventInstances/" + booking.originalEventInstanceId
                + "/attendees/" + attendeeId
            : `${bookingBasePath}/dashboard`;
        dispatch(cancelRebookingProcess(redirectUrl));
    }, [bookingBasePath, booking, internalAppSettings, attendeeId, isClientAdvisor, dispatch]);

    const eventInstanceId = booking.originalEventInstanceId ? booking.originalEventInstanceId : booking.reservedEventInstanceId;

    useEffect(() => {
        if (isRebooking) {
            dispatch(loadRebookEventInstanceAndVenueDetail({ eventInstanceId }));
        } else {
            dispatch(clearRebookEventInstanceAndVenueDetail());
        }
    }, [isRebooking, eventInstanceId, dispatch]);

    if (!rebookVenue || !rebookEventInstance) {
        return null;
    }

    const eventName = rebookEventInstance.eventTypeName;
    const eventNameCy = rebookEventInstance.eventTypeNameCy ?? rebookEventInstance.eventTypeName;
    const venueName = rebookVenue.name;
    const date = dateString(rebookEventInstance.startDate, DateFormat.LongNoYear);
    const startTime = rebookEventInstance.startTime.format(DateFormat.Time, { trim: false });
    const endTime = rebookEventInstance.startTime.clone().add(rebookEventInstance.eventDuration).format(DateFormat.Time, { trim: false });
    const time = startTime + " - " + endTime;

    const originalEventDigital = rebookEventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;

    const classroomInformation = () => (
        <Trans i18nKey="RebookInformation:REBOOKING_INFORMATION_CLASSROOM"
            values={{ eventName, eventNameCy, date, time }}>
            Your original course booking is for {{ eventName }},
            at <DisplayAddress address={rebookVenue.address} />
            on {{ date }}
            at {{ time }}
        </Trans>
    );

    const digitalAttendee = () => (
        <Trans i18nKey="RebookInformation:REBOOKING_INFORMATION_DIGITAL_ATTENDEE"
            values={{ eventName, eventNameCy, date, time }}>
            Your original course booking was a Digital course for {{ eventName }}
            on {{ date }}
            at {{ time }}
        </Trans>
    );

    const digitalCa = () => (
        <Trans i18nKey="RebookInformation:REBOOKING_INFORMATION_DIGITAL_CA"
            values={{ eventName, eventNameCy, date, time }}>
            Your original course booking was at {{ venueName }} for {{ eventName }}
            on {{ date }}
            at {{ time }}
        </Trans>
    );

    return (
        <Message className="rebook-message">
            <Message.Header>{t("REBOOKING")}</Message.Header>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <p>
                            {!originalEventDigital && classroomInformation()}
                            {originalEventDigital && !isClientAdvisor && digitalAttendee()}
                            {originalEventDigital && isClientAdvisor && digitalCa()}
                        </p>
                        <p>{t("REBOOKING_FOOTNOTE")}</p>
                    </Grid.Column>
                    <Grid.Column width={6} className="right-align">
                        <Button onClick={cancelRebooking}>
                            {t("CANCEL_REBOOKING")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Message>
    );
};
