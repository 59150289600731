import * as React from "react";
import { Card, Icon } from "semantic-ui-react";
import { BasketItem, CorporateEventInstanceResultRow, dateRange } from "../model";
import { numberFormatter } from "@common/helpers/number-format";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ChangeInBasketInput } from "./ChangeInBasketInput";

interface DetailsAndBillingCourseSummaryProps {
    course: CorporateEventInstanceResultRow;
    basketItem: BasketItem;
    index: number;
    handleSeatsChangeForCourse: (offeredCourseId: string, seats: number, add: boolean, seatsDiff?: number) => void;
    basketChangesInProgress: boolean;
    priceIncludesVat: boolean;
    allowRemovingBasketItem: boolean;
}

export const DetailsAndBillingCourseSummary = (props: DetailsAndBillingCourseSummaryProps) => {
    const { course, basketItem, index, handleSeatsChangeForCourse, basketChangesInProgress, priceIncludesVat, allowRemovingBasketItem } = props;

    const onRemove = React.useCallback(() => {
        if (!basketChangesInProgress) {
            handleSeatsChangeForCourse(basketItem.eventInstanceId, 0, false);
        }
    }, [basketChangesInProgress, basketItem.eventInstanceId, handleSeatsChangeForCourse]);

    const onChange = React.useCallback((seats: number) => {
        if (!basketChangesInProgress) {
            handleSeatsChangeForCourse(basketItem.eventInstanceId, seats, true);
        }
    }, [basketChangesInProgress, basketItem.eventInstanceId, handleSeatsChangeForCourse]);

    return (
        <Card fluid key={`course_${index}`}>
            <Card.Content header={course?.eventTypeName} className="break-word" />
            <Card.Content>
                {course.startTimes.length > 1 &&
                    <div>{dateRange(course.startTimes)}</div>
                }
                {course.startTimes.length === 1 &&
                    <>
                        <div>{course?.startTimes[0]?.format("ddd, DD MMM yyyy")}</div>
                        <div>{course?.startTimes[0]?.format("HH:mm")} - {course?.endTimes[0]?.format("HH:mm")}</div>
                    </>
                }
                <div className="important">
                    &pound;{numberFormatter((priceIncludesVat ? basketItem.pricePerSeatInPence : basketItem.pricePerSeatExcludingVatInPence) / 100)}
                </div>
                <div className="totals">
                    <div>{
                        course?.deliveryType === DeliveryTypeEnum.Onsite
                            ? `Delivered in ${course.venueName}`
                            : "Delivered Online"
                    }</div>
                    {course?.deliveryType === DeliveryTypeEnum.Onsite && (
                        <div>
                            {course.venueTown}
                        </div>
                    )}
                    <div>
                        <span>Reserved Places {allowRemovingBasketItem && <Icon name="trash" className="delete-icon" onClick={onRemove} />}</span>
                        <div className="seats">
                            <ChangeInBasketInput
                                value={basketItem.numberOfSeats}
                                maxValue={course?.seatsAvailable}
                                seatsChangedForCourse={onChange}
                                basketChangesInProgress={basketChangesInProgress}
                                wide
                            />
                        </div>
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
};
