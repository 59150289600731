import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { loadConfiguration } from "@common/crud/configuration";
import { loadAppSettings } from "@common/appSettings";
import { loadUserDetail } from "./actions";
import { AppState } from "./model";
import { userSelector } from "./selectors";
import { detailRoutes } from "./detailRoutes";

export const PoliceUserRoutes = {
    PoliceUserDetails: "/:userId",
    Edit: "/edit",
    PoliceUsers: "/police-users",
    Create: "/create"
};

const detailRoute = () => ({
    [PoliceUserRoutes.PoliceUserDetails]: {
        title: (state: AppState) => userSelector(state).fullName,
        resolve: loadUserDetail,
        [PoliceUserRoutes.Edit]: {
            title: "Edit",
        },
        ...detailRoutes
    }
});

export const routes = {
    [PoliceUserRoutes.PoliceUsers]: {
        title: "Users",
        ignoreParentResolve: true,
        resolve: [() => loadConfiguration(), loadAppSettings],
        ...detailRoute(),
        [PoliceUserRoutes.Create]: {
            title: "Create User",
            authorize: PoliceAndDdrsAdminRole,
        }
    }
};
