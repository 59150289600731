/* eslint-disable max-lines */
import * as React from "react";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { basePathSelector, dynamicSelectedEventInstanceListPath } from "../selectors";
import { Area, ExtendedEventInstance, ExtendedEventInstanceGroup, TrainerAvailabilityRoleTypeEnum } from "../model";
import { EventInstanceListModel } from "../../eventInstance";
import { WelshLanguageIndicator } from "./WelshLanguageIndicator";
import { appSelector, routerPathnameSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { MonitorIcon } from "@common/crud/common/MonitorIcon";
import { ObserverIcon } from "@common/crud/common/ObserverIcon";
import { ModuleType, ModuleTypeEnum } from "@common/crud/eventType/model";
import { baseEventManagementSelector, getPathString } from "../selectors";
import { GetEventInstanceDateDisplay } from "../helpers";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { errorMessageDisplay } from "./ErrorMessageDisplay";
import { EventInstanceGroupItemModel, EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";

interface AllCorporateItemsProps {
    area: Area;
    eventInstances: EventInstanceListModel[];
    eventInstanceGroups: EventInstanceGroupModel[];
    selectedEventInstances?: string[];
    selectedEventInstanceGroups?: string[];
    registeredInterestEventInstanceModels?: EventInstanceListModel[];
    onEventInstanceSelect?: (eventInstanceId: string, role?: TrainerAvailabilityRoleTypeEnum) => void;
    onEventInstanceGroupSelect?: (eventInstanceGroupId: string) => void;
    selectAll?: () => void;
    pageSize: number;
}

export const AllCorporateItems: React.FC<AllCorporateItemsProps> =
({ eventInstances,
    eventInstanceGroups,
    onEventInstanceSelect,
    onEventInstanceGroupSelect,
    area,
    selectedEventInstances,
    selectedEventInstanceGroups,
    selectAll,
    pageSize }) => {

    const getPath = useSelector(dynamicSelectedEventInstanceListPath(area));
    const app = useSelector(appSelector) as Apps.Admin;
    const basePath = useSelector(basePathSelector);
    const baseEventManagementPath = useSelector(baseEventManagementSelector);
    const pathname = useSelector(routerPathnameSelector);
    const extendedEventInstances = eventInstances.map((e: EventInstanceListModel): ExtendedEventInstance => ({
        ...e,
        path: getPathString(e.workflowType, basePath, app, pathname, area),
        onChange: () => onEventInstanceSelect(e.id, e.role),
        isSelected: selectedEventInstances?.includes(e.id) ?? false,
        customKey: `${e.id}${e.role || ""}`,
        isEventInstanceGroup: false,
    }));

    function buildGroupedEventInstance(e: EventInstanceGroupModel, eventInstance: EventInstanceListModel) {
        return ({
            path: getPath(eventInstance.workflowType),
            startDate: eventInstance?.startDate,
            publishDate: eventInstance?.publishDate,
            reasonForHidingEvent: eventInstance?.reasonForHidingEvent,
            cancelled: eventInstance?.cancelled,
            onChange: () => onEventInstanceGroupSelect(e.id),
            isSelected: selectedEventInstanceGroups?.includes(e?.id) ?? false,
        });
    }

    const extendedEventInstanceGroups = eventInstanceGroups.map<ExtendedEventInstanceGroup>(e => {
        const eventInstanceItemIds = e.eventInstanceGroupItems?.map(g => g.eventInstanceId) ?? [];
        const linkedEventInstances = eventInstances
            .filter(ei => eventInstanceItemIds.includes(ei.id))
            .sort((a, b) => a.deliveryDateTime.diff(b.deliveryDateTime));
        const firstEventInstance = linkedEventInstances?.length > 0 && linkedEventInstances[0];
        const extendedProps = buildGroupedEventInstance(e, firstEventInstance);

        return ({ ...e, isEventInstanceGroup: true, ...extendedProps });
    });
    const extendedEventInstancesAndGroups =
        extendedEventInstances.filter(ei => !ei.groupId || extendedEventInstanceGroups.map(g => g.id).includes(ei.groupId))
            .reduce<(ExtendedEventInstance | ExtendedEventInstanceGroup)[]>((extendedEventInstancesAndGroupsArray, currentEventInstance) => {
                if (!currentEventInstance.groupId) {
                    return [...extendedEventInstancesAndGroupsArray, currentEventInstance];
                }

                if (!extendedEventInstancesAndGroupsArray.map(e => e.id).includes(currentEventInstance.groupId)) {
                    const group = extendedEventInstanceGroups.find(g => g.id === currentEventInstance.groupId);
                    return [...extendedEventInstancesAndGroupsArray, group];
                }

                return extendedEventInstancesAndGroupsArray;

            },[]);

    const isChecked = eventInstances.length === selectedEventInstances?.length;

    const selectColumn = {
        key: "tick-all",
        functionalHeader: () => <Checkbox onClick={selectAll} checked={isChecked} label="Tick All" />,
        value: (e: (ExtendedEventInstance | ExtendedEventInstanceGroup)) => {

            const errorMessageDuringUpdateTrainers = getValueFromEiOrFirstDay("errorMessageDuringUpdateTrainers", e, extendedEventInstances);
            const errorMessageDuringUpdateDorsOpenPlacesCount =
                getValueFromEiOrFirstDay("errorMessageDuringUpdateDorsOpenPlacesCount", e, extendedEventInstances);

            return (
                <Authorize authorize={TtcPlannerRole}>
                    <Checkbox
                        checked={e.isSelected}
                        onChange={e.onChange}
                    />
                    {
                        e && (e.errorMessageDuringPublish || errorMessageDuringUpdateTrainers || errorMessageDuringUpdateDorsOpenPlacesCount) &&
                        <Popup
                            content={errorMessageDisplay(e)}
                            trigger={<Icon name={"exclamation triangle"} />}
                        />
                    }
                </Authorize>
            );
        }
    };

    const selectAllAdminColumn = [selectColumn];

    const getColumns = () => {
        return [
            ...venueColumn(extendedEventInstances),
            ...(area === Area.AdminEventManagementEventInstance
                ? organisationColumn(baseEventManagementPath, extendedEventInstances)
                : []),
            ...commonColumns(area, extendedEventInstances),
            ...adminColumns(),
            ...selectAllAdminColumn
        ];

    };

    const emptyArrayMessage = "No courses on this date, range of dates or selection criteria";

    return (
        <TypedTable
            values={extendedEventInstancesAndGroups.slice(0, pageSize)}
            tableClassName="event-instance-table"
            emptyValuesArrayMessage={emptyArrayMessage}
        >
            {getColumns()}
        </TypedTable>
    );
};

const sumProperty = (key: "numberOfTheoryTrainers" | "numberOfPracticalTrainers" | "openPlacesCount",
    values: (ExtendedEventInstance | ExtendedEventInstanceGroup)[]) => values.reduce((a, b) => a + b[key], 0);

const sumPropertyTernary = (
    values:  (ExtendedEventInstance | ExtendedEventInstanceGroup)[]) => values.reduce((a, b) => {
    const max = isExtendedEventInstance(b)? Math.max(b.placesBookedOnOpenBookingCount ?? 0, b.seatCount ?? 0) : b.seatCount ?? 0;
    return a + max;
}, 0);

const venueColumn = (extendedEventInstances: ExtendedEventInstance[]):
    TypedTableRowProps<(ExtendedEventInstance | ExtendedEventInstanceGroup)>[] => {
    const columns = [
        {
            header: "Venue",
            cellClassName: "break-word",
            value: (e: (ExtendedEventInstance | ExtendedEventInstanceGroup)) => {

                const oneToOne = getValueFromEiOrFirstDay("oneToOne", e, extendedEventInstances);
                const eventInstanceId = isExtendedEventInstance(e) ? e.id : e.eventInstanceGroupItems[0].eventInstanceId;
                return (
                    <>
                        <Link className="event-name-link" href={`${e.path}/${eventInstanceId}`}>
                            {e.venueName}
                            {e.hasAttendeesWithSpecialRequirements && <Icon name="speakap" />}
                            <WelshLanguageIndicator language={e.language} />
                            {oneToOne && <img className="one-to-one-icon" src={"/assets/one-to-one.png"} />}
                        </Link>
                    </>
                );
            }
        },
    ];
    return columns;
};

const organisationColumn = (basePath: string, extendedEventInstances: ExtendedEventInstance[]):
TypedTableRowProps<(ExtendedEventInstance | ExtendedEventInstanceGroup)>[] => {

    return [
        {
            header: "Organisation",
            cellClassName: "break-word",
            value: (e) => {
                const orgId = getValueFromEiOrFirstDay("corporateOrganisationId", e, extendedEventInstances);
                const orgName = getValueFromEiOrFirstDay("corporateOrganisationName", e, extendedEventInstances);
                return (
                    orgId
                        ? (<Link className="event-name-link" href={`${basePath}/organisations/${orgId}`}>
                            {orgName}
                        </Link>)
                        : undefined
                );
            }
        },
    ];
};

const commonColumns = (area: Area, extendedEventInstances: ExtendedEventInstance[]):
    TypedTableRowProps<(ExtendedEventInstance | ExtendedEventInstanceGroup)>[] => {
    return [
        {
            header: "Product",
            value: (e) => {
                return getValueFromEiOrFirstDay("eventTypeAbbreviation", e, extendedEventInstances);
            },
            showTotal: true,
            getTotalValue: (events: (ExtendedEventInstance | ExtendedEventInstanceGroup)[]) => events.length,
        },
        {
            header: "Date",
            value: (e) => isExtendedEventInstance(e)? GetEventInstanceDateDisplay(e) : multiDayDateColumn(e),
            headerClassName: area === Area.AdminEventManagementEventInstance ? "event-instance-table-200-px-cell" : "",
        },
    ];
};

const adminColumns = ():
    TypedTableRowProps<ExtendedEventInstance | ExtendedEventInstanceGroup>[] => [
    {
        header: "Status",
        value: (e) => {
            const isPublished = !!e.publishDate;
            const isVisible = !e.reasonForHidingEvent;
            return e.cancelled ? "Cancelled" : isPublished && isVisible ? "Published" : !isPublished ? "Not Published" : "Hidden";
        }
    },

    {
        header: "Trainers",
        value: (e) => isExtendedEventInstance(e)? (<>
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Theory.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory]))
                && e.theoryTrainersNames &&
                <Popup
                    content={e.theoryTrainersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                        {e.numberOfTheoryTrainers} </Link>}
                    position='left center'
                />}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Theory.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Theory]))
                && !e.theoryTrainersNames &&
                <Link
                    className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    {e.numberOfTheoryTrainers} </Link>}
            {(e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both]) && "/"}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Practical.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Practical]))
                && e.practicalTrainersNames &&
                <Popup
                    content={e.practicalTrainersNames}
                    trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                        {e.numberOfPracticalTrainers} </Link>}
                    position='left center'
                />}
            {((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
                || (e.moduleType.toString() === ModuleTypeEnum.Practical.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Practical]))
                && !e.practicalTrainersNames &&
                <Link
                    className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    {e.numberOfPracticalTrainers} </Link>}
            {e.monitorsNames && <Popup
                content={e.monitorsNames}
                trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    <MonitorIcon /> </Link>}
                position='left center'
            />}
            {e.observersNames && <Popup
                content={e.observersNames}
                trigger={<Link className="monitor-link" href={`${e.path}/${e.id}/trainers`}>
                    <ObserverIcon /></Link>}
                position='left center'
            />}
        </>): getMultiDayTrainerDisplay(e),
        showTotal: true,
        getTotalValue: (events: (ExtendedEventInstance | ExtendedEventInstanceGroup)[]) =>
            sumProperty("numberOfTheoryTrainers", events) + sumProperty("numberOfPracticalTrainers", events),
        cellClassName: "event-instance-table-110-px-cell"
    },
    {
        header: "Booked",
        value: (e) => isExtendedEventInstance(e)? (
            <Link
                href={`${e.path}/${e.id}/delegates`}
                target={""}
            >
                {Math.max(e.placesBookedOnOpenBookingCount ?? 0, e.seatCount ?? 0)}/{e.openPlacesCount}
            </Link>
        ): getMultiDayBookedColumn(e),
        showTotal: true,
        getTotalValue: (events: (ExtendedEventInstance | ExtendedEventInstanceGroup)[]) =>
            `${sumPropertyTernary(events)}/${sumProperty("openPlacesCount", events)}`
    },
];

function getMultiDayTrainerDisplay(e: ExtendedEventInstanceGroup) {

    const dayOneId = e.eventInstanceGroupItems?.find(gi => gi.dayNumber === 1)?.eventInstanceId;

    return (<>
        {!e.trainersNames && <Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
            {e.numberOfTheoryTrainers} </Link>}
        {(e.trainersNames) && <Popup
            content={e.trainersNames}
            trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                {e.numberOfTheoryTrainers} </Link>}
            position='left center'
        />}
        {e.monitorsNames && <Popup
            content={e.monitorsNames}
            trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                <MonitorIcon /> </Link>}
            position='left center'
        />}
        {e.observersNames && <Popup
            content={e.observersNames}
            trigger={<Link className="monitor-link" href={`${e.path}/${dayOneId}/trainers`}>
                <ObserverIcon /></Link>}
            position='left center'
        />}
    </>);}

function getMultiDayBookedColumn(e: ExtendedEventInstanceGroup) {
    return (
        <Link href={`${e.path}/${e.eventInstanceGroupItems[0].eventInstanceId}/delegates`}>
            {`${e.seatCount}/${e.openPlacesCount} `} {e.hasVariableOccupancyCounts && <Icon name="warning" />}
        </Link>);
}

function multiDayDateColumn(e: ExtendedEventInstanceGroup) {
    return (
        <div>
            {e.eventInstanceGroupItems.map((gi, index) =>
                <div key={index}>{getDisplayDay(gi).format("ddd DD/MM/YYYY HH:mm")}</div>
            )}
        </div>
    );
}

function getDetailFromEventInstanceDay<K extends keyof ExtendedEventInstance>(
    key: K,
    eventInstanceGroup: ExtendedEventInstanceGroup,
    extendedEventInstances: ExtendedEventInstance[])
{
    const eventInstance = extendedEventInstances.find(e => e.groupId === eventInstanceGroup.id);
    return eventInstance[key];
}

function isExtendedEventInstance(e: ExtendedEventInstance | ExtendedEventInstanceGroup): e is ExtendedEventInstance {
    return !e.isEventInstanceGroup;
}

function getValueFromEiOrFirstDay<K extends keyof ExtendedEventInstance>(
    key: K,
    e: ExtendedEventInstance | ExtendedEventInstanceGroup,
    extendedEventInstances: ExtendedEventInstance[]): ExtendedEventInstance[typeof key]
{
    let valueFromEiOrFirstDay: ExtendedEventInstance[K];

    if (isExtendedEventInstance(e)) {
        valueFromEiOrFirstDay = e[key];
    }
    else {
        valueFromEiOrFirstDay = getDetailFromEventInstanceDay(key, e, extendedEventInstances);
    }

    return valueFromEiOrFirstDay;
}

function getDisplayDay(groupItem: EventInstanceGroupItemModel): moment.Moment {
    const firstStartDate = groupItem.startDate.startOf("day").clone();
    return firstStartDate.add(groupItem.startTime);
}

