import * as React from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { EventInstanceDetailModel } from "../..";
import { ModuleType, ModuleTypeEnum } from "@common/crud/eventType/model";

export interface AutomaticAttendeeAvailabilityProps {
    eventInstance: EventInstanceDetailModel;
}

export function AutomaticAttendeeAvailability({ eventInstance }: AutomaticAttendeeAvailabilityProps) {

    const automaticTrainers = eventInstance.practicalTrainers?.filter(t => t.carType === "Automatic");
    if (eventInstance.moduleType === ModuleType[ModuleTypeEnum.Theory] || !automaticTrainers) {
        return null;
    }

    const availableAutomaticSeats = automaticTrainers.length * (eventInstance.maxNumberOfAttendeesPerPracticalTrainer || 0);
    const remainingAutomaticSeats = Math.max(availableAutomaticSeats - eventInstance.automaticSeatCount || 0, 0);

    return (
        <Detail
            label="Automatic Attendee Availability"
            value={`${remainingAutomaticSeats}/${availableAutomaticSeats}`}
            labelWidth={5}
            valueWidth={11}
        />
    );
}
