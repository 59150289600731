import * as React from "react";
import { LocalStateKeys, LocalStateKeysEnum } from "../model";

export function useSetBeforeGenesysAuthPage() {

    React.useEffect(() => {
        // We set a marker so that we know that we know we are prior to Genesys Auth page.
        // We use this to support back and forward in the browser
        window.localStorage.setItem(LocalStateKeys[LocalStateKeysEnum.BeforeGenesysAuthPage], "true");
    }, []);

}
