import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { ChangeEventType } from "@common/history/model";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { PendingEventInstanceBanner } from "./PendingEventInstanceBanner";
import { SideBar } from "./SideBar";
import { pendingEventInstanceSelector } from "../selectors";
import { PendingCoursesApi } from "../pendingCoursesApi";

const expectedFilters = [
    { type: ChangeEventType.DataModification, display: true },
];

export const History: React.FC = () => {
    const [history, setHistory] = React.useState([]);
    const pendingEventInstance = useSelector(pendingEventInstanceSelector);

    React.useEffect(() => {
        async function loadHistory() {
            const api = new PendingCoursesApi();
            const result = await api.getHistory(pendingEventInstance.id);
            setHistory(result);
        }
        if (pendingEventInstance?.id) {
            loadHistory();
        }
    },[pendingEventInstance?.id]);

    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        expectedFilters
            .map(filter => filter.type));

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    const content = () => {
        return (<div>
            <h1>Course history</h1>
            <PendingEventInstanceBanner pendingEventInstance={pendingEventInstance} />
            <Grid>
                <Grid.Column width={3}>
                    <Grid.Row>
                            Filters by group:
                    </Grid.Row>
                    {expectedFilters.map(eventType => eventType.display ?
                        <HistoryFilter
                            key={eventType.type}
                            eventType={eventType.type}
                            value={filters.includes(eventType.type)}
                            onChange={addOrRemove}
                        /> : null)}
                    <Grid.Row>
                        <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={13}>
                    <HistoryContent
                        showDetailsDefault={false}
                        entries={history?.filter(record => filters.includes(record.eventType))}
                        workflowType={pendingEventInstance.workflowType}
                        eventTypeCategory={EventTypeCategory.CorporateOrganisation}
                        taskRelation={TaskRelationEnum.PendingEventInstance}
                    />
                </Grid.Column>
            </Grid>
        </div>);
    };

    return <SideBar children={content()} />;
};
