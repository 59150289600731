import * as React from "react";
import { Modal } from "@common/components";
import { Button, Table } from "semantic-ui-react";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";
import { CorporateEventInstanceResultRow, Basket } from "../model";
import { numberFormatter } from "@common/helpers/number-format";
import { OrderApi } from "../orderApi";
import { BasketModalItem } from "./BasketModalItem";
import "./basketModal.scss";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { doesPriceIncludeVat } from "@common/crud/organisation/model";

export interface BasketModalProps {
    basket: Basket;
    seatsChangedForCourse: (offeredCourseId: string, seats: number, add: boolean, seatsDiff?: number) => void;
    basketChangesInProgress: boolean;
    confirmBasket: () => void;
}

export const BasketModal: React.FC<BasketModalProps> = ({ basket, seatsChangedForCourse, basketChangesInProgress, confirmBasket }) => {
    const [coursesFromIds, setCoursesFromIds] = React.useState<CorporateEventInstanceResultRow[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);

    const businessLineType = useSelector(businessLineTypeSelector);

    React.useEffect(() => {
        const courseIds = (basket && basket.items) ? (Object.values(basket.items).map(x => x.eventInstanceId) || []) : [];

        // fetch data only when some course from ids is missing basket item
        if (courseIds.length === 0 || courseIds.every(id => coursesFromIds.some(course => course.id === id))) {
            return;
        }

        const fetchData = async () => {
            const orderApi = new OrderApi();
            const newCoursesFromIds =
                await orderApi.getBookedCoursesFromIds(businessLineType, Object.values(basket.items).map(x => x.eventInstanceId), basket.relatedOrganisationId);
            setCoursesFromIds(newCoursesFromIds);
        };

        fetchData();
    }, [basket, coursesFromIds, businessLineType]);

    const onOpenToggle = React.useCallback(() => setOpen(!open), [open]);

    const onRemoveBasketItem = React.useCallback((eventInstanceId: string, seatsDiff: number) => {
        seatsChangedForCourse(eventInstanceId, 0, false, seatsDiff);
    }, [seatsChangedForCourse]);

    const onChangeBasketItem = React.useCallback((eventInstanceId: string, seats: number) => {
        seatsChangedForCourse(eventInstanceId, seats, true);
    }, [seatsChangedForCourse]);

    const onContinueClick = React.useCallback(() => {
        confirmBasket();
        onOpenToggle();
    }, [confirmBasket, onOpenToggle]);

    const memoizedOrderedBasketItemsWithCourses = React.useMemo(() => {
        const basketItems = (basket && basket.items) ? Object.values(basket.items) : [];
        const basketItemsWithCourse = basketItems.map(basketItem => ({ basketItem, course: coursesFromIds.find(c => c.id === basketItem.eventInstanceId) }));
        return orderBy(basketItemsWithCourse, ["course.startTime"], ["asc"]);
    }, [basket, coursesFromIds]);

    const priceIncludesVat = React.useMemo(() => doesPriceIncludeVat(+businessLineType), [businessLineType]);

    const vatDifference = React.useMemo(() => (basket?.total || 0) - (basket?.totalExcludingVat || 0), [basket]);

    PreventScrollingOnNumberInputsHook();
    return (
        <>
            <Button
                icon="shopping basket"
                className="button-margin basket basket-button"
                onClick={onOpenToggle}
                content={(basket && basket.items && Object.entries(basket.items).length > 0)
                    ? `(${Object.entries(basket.items).map(item => item[1].numberOfSeats).reduce((a, b) => a + b, 0)})`
                    : undefined}
            />
            <Modal open={open}>
                <Modal.Header>
                    My basket
                </Modal.Header>
                <Modal.Content>
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={5} className="title-column-width">Course</Table.HeaderCell>
                                    <Table.HeaderCell className="fixed-column-width" colSpan={5}>Places</Table.HeaderCell>
                                    <Table.HeaderCell colSpan={1} className="action-column-width" />
                                    <Table.HeaderCell className="fixed-column-width" colSpan={5}>Cost</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {memoizedOrderedBasketItemsWithCourses.map((basketItemWithCourse, index) => {
                                    return (
                                        <BasketModalItem
                                            key={`basketItem_${index}`}
                                            basketItem={basketItemWithCourse.basketItem}
                                            course={basketItemWithCourse.course}
                                            removeBasketItem={onRemoveBasketItem}
                                            onChangeBasketItem={onChangeBasketItem}
                                            basketChangesInProgress={basketChangesInProgress}
                                            priceIncludesVat={priceIncludesVat}
                                        />
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer fullWidth>
                                {(basket && basket.discount > 0) && (
                                    <>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={16} className="discount-padding" />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={11} className="discount-box-left">
                                                <div className="cell-with-rows">
                                                    <span className="bolden-summary">Discount</span>
                                                    <span>Course bundle discount</span>
                                                </div>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={5} className="discount-box-right">
                                                -&pound;{numberFormatter(basket.discount / 100)}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={16} className="discount-padding-without-border" />
                                        </Table.Row>
                                    </>
                                )}
                                {!priceIncludesVat && vatDifference > 0 && (
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={11} className="bolden-summary">VAT</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={5}> &pound;{numberFormatter(vatDifference / 100)}</Table.HeaderCell>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.HeaderCell colSpan={11} className="bolden-summary">Total</Table.HeaderCell>
                                    <Table.HeaderCell colSpan={5}> &pound;{numberFormatter((basket?.total || 0) / 100)}</Table.HeaderCell>
                                </Table.Row>
                                {businessLineType === BusinessLineType.Corporate && (
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={16} className="bolden-summary">
                                            Price includes VAT and DVSA registration fees
                                        </Table.HeaderCell>
                                    </Table.Row>
                                )}
                            </Table.Footer>
                        </Table>
                    </>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onOpenToggle} className="cancel-action">
                        Continue shopping
                    </Button>
                    <Button disabled={!basket || !basket.items || Object.values(basket.items).length === 0} onClick={onContinueClick}>
                        Checkout
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
