import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useStickyState } from "./useStickyState";
import { OrderPaymentReview } from "./OrderPaymentReview";
import { OrderState } from "../model";

const OrderStripeWrapper: React.FC = () => {
    let startPaymentProcess = useSelector((state: OrderState) => state.orderPaymentsState.startPaymentProcess);
    const localState = useStickyState(startPaymentProcess);

    if (!startPaymentProcess || startPaymentProcess.stripePublishKey === null) {
        startPaymentProcess = localState;
    }

    const stripePromise = loadStripe(startPaymentProcess.stripePublishKey);

    return (
        <Elements stripe={stripePromise}>
            <OrderPaymentReview startPaymentProcess={startPaymentProcess} />
        </Elements>
    );
};

export { OrderStripeWrapper };
