/* eslint-disable max-lines */
import * as React from "react";
import { useState } from "react";
import { Button, Grid, Message, Popup } from "semantic-ui-react";
import { EventInstance, EventTrainer } from "@common/crud/eventInstance/model";
import { Trainer, TrainerApi } from "@common/crud/trainer";
import { EventType, EventTypeApi } from "@common/crud/eventType";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import moment from "moment";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { toast } from "@common/toasts";
import { useSelector } from "react-redux";
import { CurrentUser, EventManagementAdminRoles, MonitorRole, TtcTrainerAdminRole } from "@common/auth/model";
import { MonitoringReportForm } from "@common/crud/eventInstance/components/monitoring/MonitoringReportForm";
import {
    IqaTeamFeedbackCreateEditModel,
    PassFailResultEnum,
    PassFailResultForDisplay,
    TrainerFeedbackCreateEditModel,
    TrainerMonitoringReportCreateEditModel,
    TrainerMonitoringReportSection,
    TrainerMonitoringReportStatusEnum
} from "@common/crud/trainer/trainerMonitoringModel";
import { TrainerMonitoringReportBuilder } from "@common/crud/trainer/trainerMonitoringReportBuilder";
import { TrainerMonitoringApi } from "@common/crud/trainer/trainerMonitoringApi";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { currentUserSelector } from "@common/auth";
import { Spinner } from "@common/global";
import { isIqaTeam } from "@common/crud/common/selectors";
import { SubmitMonitoringModal } from "@common/crud/eventInstance/components/monitoring/SubmitMonitoringModal";
import { SubmitIqaTeamFeedbackModal } from "@common/crud/eventInstance/components/monitoring/SubmitIqaTeamFeedbackModal";
import {
    SubmitFeedbackToMonitorModal
} from "@common/crud/eventInstance/components/monitoring/SubmitFeedbackToMonitorModal";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import {
    IQAReviewSectionId,
    PassFailResultLevel2,
    TrainersCommentsSectionId
} from "@common/crud/eventInstance/components/monitoring/MonitoringReportingConstants";
import { EventTypeDetailModel, FormTypeEnum, FormTypeName } from "@common/crud/eventType/model";
import { FormValidationResult } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { getFailedSections, getPassFailResult, validateMonitoringForm } from "./helper";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export interface TrainerMonitoringDisplayProps {
    eventTrainer: EventTrainer;
    eventMonitor: EventTrainer;
    eventInstance: EventInstance;
}

export const TrainerMonitoringDisplay: React.FC<TrainerMonitoringDisplayProps> = ({
    eventTrainer, eventMonitor, eventInstance
}) => {
    const [trainer, setTrainer] = useState<Trainer>(null);
    const [monitor, setMonitor] = useState<Trainer>(null);
    const [eventType, setEventType] = useState<EventType>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [trainerOpen, setTrainerOpen] = React.useState<boolean>(false);
    const [iqaOpen, setIqaOpen] = React.useState<boolean>(false);
    const [currentAttribute, setCurrentAttribute] = useState<TrainerAttribute>();
    const [isViewingSelf, setIsViewingSelf] = useState<boolean>();
    const [reportFormDataModel, setReportFormDataModel] = useState<TrainerMonitoringReportCreateEditModel>();
    const [passFailResult, setPassFailResult] = useState<PassFailResultEnum>(PassFailResultEnum.UnResolved);
    const [failedSections, setFailedSections] = useState<TrainerMonitoringReportSection[]>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isAuthorised, setIsAuthorised] = useState<boolean>(null);
    const [isIqaReview, setIsIqaReview] = useState<boolean>();
    const [formValidationResult, setFormValidationResult] = useState<FormValidationResult[]>();
    const currentUser: CurrentUser = useSelector(currentUserSelector);
    const currentUserIsIqaTeam = useSelector(isIqaTeam);

    const currentUserIsTrainerOnCourse = !!eventInstance?.trainers?.find(t => t.id === currentUser.id) &&
        currentUser.id === reportFormDataModel?.trainerId &&
        reportFormDataModel?.status >= TrainerMonitoringReportStatusEnum.Submitted;
    const currentUserIsMonitorOnCourse = !!eventInstance?.monitorTrainers?.find(t => t.id === currentUser.id);
    const currentUserIsMonitorOrTrainerAdmin = !!currentUser.roles.find(r => r === TtcTrainerAdminRole) || currentUserIsMonitorOnCourse;
    const currentUserIsMonitor = !!currentUser.roles.find(r => r === MonitorRole);

    const courseHasStarted = eventInstance && eventInstance.deliveryDateTime < moment().utc();
    const lockedForChange = reportFormDataModel?.status === undefined ||
        reportFormDataModel?.status > TrainerMonitoringReportStatusEnum.Draft || !courseHasStarted;

    const isCompletedAndLocked = reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.CompletedLocked;
    const isSubmittedByMonitor = reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.Submitted ||
        reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.IqaReview;
    const monitorCanSubmit = (!currentAttribute || !isViewingSelf) && !lockedForChange && courseHasStarted && !isSubmittedByMonitor;

    const trainerCanSubmit = currentUserIsTrainerOnCourse &&
        reportFormDataModel.trainerId === currentUser.id &&
        reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.Submitted;

    const iqaCanSubmit = currentUserIsIqaTeam && isIqaReview &&
        reportFormDataModel?.status === TrainerMonitoringReportStatusEnum.IqaReview;

    const showForm = !!currentUser.roles.find(r => r === TtcTrainerAdminRole) ||
        currentUserIsMonitorOnCourse || currentUserIsTrainerOnCourse || currentUserIsMonitor;

    const popupContent = courseHasStarted ?
        "Report has been submitted, no further changes permitted" :
        "Form cannot be submitted until the course has started";

    const defaultDate = eventInstance.startDate.clone().add({ year: 2 });

    function validateForm(model: TrainerMonitoringReportCreateEditModel): boolean {
        const validationResult = validateMonitoringForm(model);
        setFormValidationResult(validationResult);
        return validationResult.filter(r => r.validResult === false).length === 0;
    }

    const isDigitalEventInstanceDeliveryType = React.useCallback(() => {
        return eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    }, [eventInstance]);

    const openModal = React.useCallback(() => {
        if (!monitorCanSubmit) {
            return;
        }

        const fails = getFailedSections(reportFormDataModel);
        const newPassFailResult = getPassFailResult(fails);

        if (isDigitalEventInstanceDeliveryType()) {
            setCurrentAttribute({ ...currentAttribute, nextDigitalMonitoringDue: defaultDate });
        } else {
            setCurrentAttribute({ ...currentAttribute, nextMonitoringDue: defaultDate });
        }

        setFailedSections(fails);
        setPassFailResult(newPassFailResult);
        setIsIqaReview(newPassFailResult > PassFailResultLevel2);
        setOpen(true);
    }, [currentAttribute, defaultDate, monitorCanSubmit, reportFormDataModel, isDigitalEventInstanceDeliveryType]);

    const closeModal = React.useCallback(() => { setOpen(false); }, []);
    const openTrainerModal = React.useCallback(() => { setTrainerOpen(true); }, []);
    const closeTrainerModal = React.useCallback(() => { setTrainerOpen(false); }, []);
    const openIqaModal = React.useCallback(() => { setIqaOpen(true); }, []);
    const closeIqaModal = React.useCallback(() => { setIqaOpen(false); }, []);

    const onNextMonitoringDateUpdate = React.useCallback(
        (value: moment.Moment, valid: boolean) => {
            if (value && valid) {
                const updatedAttribute = { ...currentAttribute, nextMonitoringDue: value };
                setCurrentAttribute(updatedAttribute);
            }
        },
        [currentAttribute]
    );

    const onNextDigitalMonitoringDateUpdate = React.useCallback(
        (value: moment.Moment, valid: boolean) => {
            if (value && valid) {
                const updatedAttribute = { ...currentAttribute, nextDigitalMonitoringDue: value };
                setCurrentAttribute(updatedAttribute);
            }
        },
        [currentAttribute]
    );

    const updateFormData = React.useCallback(
        (model: TrainerMonitoringReportCreateEditModel) => {
            setReportFormDataModel({ ...model });
            setIsIqaReview(model.passFailResult > PassFailResultLevel2);
        }, []);

    const findTrainerAttributeToUpdate = React.useCallback((currentTrainer: Trainer, currentEventType: EventType) => {
        if (isDigitalEventInstanceDeliveryType()) {
            return currentTrainer?.trainerAttributes?.find((ta: any) =>
                ta.attributeDefinitionId === currentEventType?.digitalEventTypeDetails?.digitalTrainerAttributeId
            );
        } else {
            return currentTrainer?.trainerAttributes?.find((ta: any) =>
                ta.attributeDefinitionId === currentEventType?.theoryTrainerAttributeId
            );
        }
    }, [isDigitalEventInstanceDeliveryType]);

    const updateTrainerAttribute = React.useCallback((currentTrainer: TrainerDetailModel, currentEventType: EventTypeDetailModel) => {
        const attribute = findTrainerAttributeToUpdate(currentTrainer, currentEventType);

        if (!attribute) {
            toast.error(
                "The required attribute has not been found on the trainer!"
            );
        }

        if (attribute) {
            setCurrentAttribute(attribute);
            setIsViewingSelf(currentUser.id === currentTrainer.id);
        }
    }, [currentUser, findTrainerAttributeToUpdate]);

    async function saveProgress() {
        if (!monitorCanSubmit) {
            return;
        }

        setIsSaving(true);

        const trainerMonitoringApi = new TrainerMonitoringApi();
        reportFormDataModel.status = reportFormDataModel.status ?? TrainerMonitoringReportStatusEnum.Draft;
        const response = await trainerMonitoringApi.saveTrainerMonitoringFormProgress(reportFormDataModel);

        toast.success("Your progress has been saved");
        setReportFormDataModel(response);
        setIsSaving(false);
    }

    async function autoSaveProgress() {
        if (!monitorCanSubmit) {
            return;
        }

        const trainerMonitoringApi = new TrainerMonitoringApi();
        reportFormDataModel.status = reportFormDataModel.status ?? TrainerMonitoringReportStatusEnum.Draft;
        const response = await trainerMonitoringApi.saveTrainerMonitoringFormProgress(reportFormDataModel);

        setReportFormDataModel(response);
    }

    async function submitForm(model: TrainerMonitoringReportCreateEditModel) {
        const trainerMonitoringApi = new TrainerMonitoringApi();
        const response = await trainerMonitoringApi.saveTrainerMonitoringFormProgress(model);
        toast.success("Your feedback has been submitted, no further changes are permitted");
        setReportFormDataModel(response);
    }

    async function submitIqaForm(model: IqaTeamFeedbackCreateEditModel) {
        const trainerMonitoringApi = new TrainerMonitoringApi();
        const response = await trainerMonitoringApi.saveIqaTeamFeedback(model);
        toast.success("Your feedback has been submitted, no further changes are permitted");
        setReportFormDataModel(response);
    };

    async function submitTrainerFeedbackForm(model: TrainerFeedbackCreateEditModel) {
        const trainerMonitoringApi = new TrainerMonitoringApi();
        const response = await trainerMonitoringApi.saveTrainerFeedback(model);
        toast.success("Your feedback has been submitted, no further changes are permitted");
        setReportFormDataModel(response);
    };

    async function onMonitorSubmit() {
        if ((!currentAttribute?.nextDigitalMonitoringDue || !currentAttribute?.nextDigitalMonitoringDue?.isValid()) &&
            (!currentAttribute?.nextMonitoringDue || !currentAttribute?.nextMonitoringDue?.isValid())) {
            return;
        }

        setIsSubmitting(true);
        if (currentUser.roles.find((r) =>
            r === MonitorRole || EventManagementAdminRoles.find(() => r))
        ) {
            currentAttribute.lastMonitoredDate = eventInstance.startDate;
        }

        const payload = {
            id: trainer.id,
            eventInstanceId: eventInstance.id,
            trainerAttribute: currentAttribute,
        };

        if (validateForm(reportFormDataModel)) {
            const trainerApi = new TrainerApi();
            const response = await trainerApi.saveTrainerAttributeMonitoringUpdate(payload);
            setTrainer(response);
            updateTrainerAttribute(response, eventType);

            const submitFormData = {
                ...reportFormDataModel,
                status: TrainerMonitoringReportStatusEnum.Submitted,
                passFailResult
            };

            await submitForm(submitFormData);
        } else {
            toast.error(
                "Please complete all the required comments"
            );
        }
        setIsSubmitting(false);
        setOpen(false);
    }

    async function saveIqaFeedback() {
        setIsSubmitting(true);

        if (validateForm(reportFormDataModel)) {
            const payload = {
                id: reportFormDataModel.trainerId,
                eventInstanceId: reportFormDataModel.relatedEventInstanceId,
                trainerAttribute: currentAttribute,
            };
            const trainerApi = new TrainerApi();
            await trainerApi.saveTrainerAttributeMonitoringUpdate(payload);

            const iqaComments = reportFormDataModel.sections.find(s => s.id === IQAReviewSectionId.toString());
            const feedBack = {
                id: reportFormDataModel.id,
                monitorId: reportFormDataModel.monitorId,
                trainerId: reportFormDataModel.trainerId,
                relatedEventInstanceId: reportFormDataModel.relatedEventInstanceId,
                iqaTeamCommentsSection: iqaComments
            };
            await submitIqaForm(feedBack);
        } else {
            toast.error(
                "Please complete all the required comments"
            );
        }
        setIsSubmitting(false);
        closeIqaModal();
    }

    async function saveTrainerFeedback() {
        setIsSubmitting(true);

        const trainerComments = reportFormDataModel.sections.find(s => s.id === TrainersCommentsSectionId.toString());
        const feedBack = {
            id: reportFormDataModel.id, monitorId: reportFormDataModel.monitorId, trainerId: reportFormDataModel.trainerId,
            relatedEventInstanceId: reportFormDataModel.relatedEventInstanceId, trainerCommentsSection: trainerComments
        };

        if (validateForm(reportFormDataModel)) {
            await submitTrainerFeedbackForm(feedBack);
        } else {
            toast.error(
                "Please complete all the required comments"
            );
        }
        setIsSubmitting(false);
        closeTrainerModal();
    }

    React.useEffect(() => {
        const setTrainerMonitoringReportModel = (model: TrainerMonitoringReportCreateEditModel) => {
            setReportFormDataModel(model);
            setIsIqaReview(model.passFailResult > PassFailResultLevel2);
            setPassFailResult(model.passFailResult);
        };

        const getInitialData = async () => {
            const trainerMonitoringApi = new TrainerMonitoringApi();
            const eventTypeApi = new EventTypeApi();
            const trainerApi = new TrainerApi();

            const eventTypeResponse = await eventTypeApi.detail(eventInstance.eventTypeId);
            const trainerResponse = await trainerApi.detail(eventTrainer.id);
            const monitorResponse = eventMonitor? await trainerApi.detail(eventMonitor.id): null;
            const existingFormData = monitorResponse
                ? await trainerMonitoringApi.getTrainerMonitoringForm(trainerResponse.id, monitorResponse.id, eventInstance.id)
                : await trainerMonitoringApi.getTrainerMonitoringFormWithNoMonitor(trainerResponse.id, eventInstance.id);

            const newFormData = new TrainerMonitoringReportBuilder(
                FormTypeName[FormTypeEnum.AllScheme],
                eventTypeResponse.abbreviation,
                trainerResponse.id,
                monitorResponse?.id,
                eventInstance.id).build();

            if (!existingFormData) {
                setIsAuthorised(true);
                setTrainerMonitoringReportModel(newFormData);
            }
            else {
                setIsAuthorised(existingFormData.viewAuthorised);
                setTrainerMonitoringReportModel(existingFormData.viewAuthorised? existingFormData : newFormData);
            }

            setDataLoaded(true);
            setEventType(eventTypeResponse);
            setTrainer(trainerResponse);
            setMonitor(monitorResponse);
            updateTrainerAttribute(trainerResponse, eventTypeResponse);
        };

        if (!dataLoaded) {
            getInitialData();
        }

    }, [dataLoaded, eventInstance, eventMonitor, eventTrainer, updateTrainerAttribute]);

    const lastMonitoredDate = currentAttribute?.isDigital ?
        currentAttribute?.lastDigitalMonitoredDate?.format(DateFormat.Short) :
        currentAttribute?.lastMonitoredDate?.format(DateFormat.Short);
    return (
        <>
            <Spinner active={!dataLoaded}>
                <Grid.Column width={16}>
                    <Grid.Column width={16}>
                        <LabelAndValue label="Monitor" value={monitor?.fullName} />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <LabelAndValue label="Monitoring" value={trainer?.fullName} />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <LabelAndValue label="Last Monitored Date" value={lastMonitoredDate} />
                    </Grid.Column>
                    <br />
                    {!isAuthorised && dataLoaded &&
                    <Message>You are not currently authorised to view this monitoring form at this time</Message>
                    }
                    {isAuthorised && <>
                        {showForm &&
                        <>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="bold">{eventType && eventType?.name} Monitoring form</span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <MonitoringReportForm
                                    isDigital={isDigitalEventInstanceDeliveryType()}
                                    formType={reportFormDataModel?.formType !== null ? reportFormDataModel?.formType : eventType?.abbreviation}
                                    reportFormDataModel={reportFormDataModel}
                                    updateFormData={updateFormData}
                                    lockedForChange={lockedForChange}
                                    currentUserIsTrainerOnCourse={currentUserIsTrainerOnCourse}
                                    currentUserIsMonitorOrTrainerAdmin={currentUserIsMonitorOrTrainerAdmin}
                                    isIqaReview={isIqaReview}
                                    iqaCanSubmit={iqaCanSubmit}
                                    formValidationResult={formValidationResult}
                                    autoSaveProgress={autoSaveProgress} />
                            </Grid.Row>
                        </>
                        }
                        <Grid.Row>
                            {!currentUserIsTrainerOnCourse && !isCompletedAndLocked && showForm && (
                                <>
                                    <Grid.Column width={16}>
                                        <Spinner active={isSaving}>
                                            <Popup
                                                disabled={monitorCanSubmit}
                                                content={popupContent}
                                                trigger={
                                                    <Button
                                                        color={monitorCanSubmit ? undefined : "grey"}
                                                        content="Save Progress"
                                                        onClick={saveProgress}
                                                    />
                                                }
                                            />
                                            <Popup
                                                disabled={monitorCanSubmit}
                                                content={popupContent}
                                                trigger={
                                                    <Button
                                                        color={monitorCanSubmit ? undefined : "grey"}
                                                        content="Submit Monitoring"
                                                        onClick={openModal}
                                                        className="float-right"
                                                    />
                                                }
                                            />
                                        </Spinner>
                                    </Grid.Column>
                                    <br />
                                </>
                            )}
                            {currentUserIsTrainerOnCourse && !isCompletedAndLocked && isSubmittedByMonitor && (
                                <Grid.Column width={16}>
                                    <Button
                                        content="Submit Feedback"
                                        disabled={!trainerCanSubmit}
                                        onClick={openTrainerModal}
                                        className="float-right"
                                    />
                                    <br />
                                </Grid.Column>
                            )}
                            {currentUserIsIqaTeam && isIqaReview && isSubmittedByMonitor && (
                                <Grid.Column width={16}>
                                    <Button
                                        content="Submit IQA Feedback"
                                        disabled={!iqaCanSubmit}
                                        onClick={openIqaModal}
                                        className="float-right"
                                    />
                                    <br />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        {reportFormDataModel?.status > TrainerMonitoringReportStatusEnum.Draft &&
                        <>
                            <br />
                            <Grid.Column width={16}>
                                <LabelAndValue
                                    label="Result"
                                    value={PassFailResultForDisplay[reportFormDataModel.passFailResult]}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <LabelAndValue
                                    label="Score"
                                    value={reportFormDataModel.score}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {(isDigitalEventInstanceDeliveryType()) &&
                                    <LabelAndValue
                                        label="Recommended Next Monitoring Due"
                                        value={currentAttribute?.nextDigitalMonitoringDue?.format(
                                            DateFormat.Short
                                        )}
                                    />
                                }
                                {(!isDigitalEventInstanceDeliveryType()) &&
                                    <LabelAndValue
                                        label="Recommended Next Monitoring Due"
                                        value={currentAttribute?.nextMonitoringDue?.format(
                                            DateFormat.Short
                                        )}
                                    />
                                }
                            </Grid.Column>
                        </>}</>}
                </Grid.Column>
            </Spinner>

            <SubmitMonitoringModal open={open}
                currentAttribute={currentAttribute} passFailResult={passFailResult}
                numFailedSections={failedSections?.length} isSubmitting={isSubmitting}
                onNextMonitoringDateUpdate={onNextMonitoringDateUpdate}
                onNextDigitalMonitoringDateUpdate={onNextDigitalMonitoringDateUpdate} onSubmit={onMonitorSubmit}
                setPassFailResult={setPassFailResult} closeModal={closeModal} setIsIqaReview={setIsIqaReview}
                isDigitalEventInstanceDeliveryType={isDigitalEventInstanceDeliveryType()} />

            <SubmitIqaTeamFeedbackModal closeIqaModal={closeIqaModal}
                iqaOpen={iqaOpen} isSubmitting={isSubmitting} currentAttribute={currentAttribute}
                onNextMonitoringDateUpdate={onNextMonitoringDateUpdate}
                onNextDigitalMonitoringDateUpdate={onNextDigitalMonitoringDateUpdate}
                setReportFormDataModel={setReportFormDataModel} saveIqaFeedback={saveIqaFeedback}
                isDigitalEventInstanceDeliveryType={isDigitalEventInstanceDeliveryType()} />

            <SubmitFeedbackToMonitorModal
                closeTrainerModal={closeTrainerModal}
                isSubmitting={isSubmitting}
                trainerOpen={trainerOpen}
                saveTrainerFeedback={saveTrainerFeedback}
            />
        </>
    );
};
