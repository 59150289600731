import * as React from "react";
import { Grid, Tab } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { CoreDetailProps, DetailProps } from "../Detail";

export const SupportInfo: React.SFC<CoreDetailProps> = ({ user }) => {

    return (
        <>
            <Grid>
                <Detail label="User ID" value={user.id} labelWidth={5} valueWidth={11} />
                <Detail label="AD B2C ID" value={user.adB2CId} labelWidth={5} valueWidth={11} />
            </Grid>
        </>
    );
};

export const supportInfoTab = (props: DetailProps) => ({
    path: "/support",
    menuItem: "Support Info",
    authorize: PoliceAndDdrsAdminRole,
    render: () => <Tab.Pane><SupportInfo user={props.user} /></Tab.Pane>
});
