import * as React from "react";
import { Grid, TextArea, TextAreaProps, Button, Form } from "semantic-ui-react";
import { addNoteToCorrelationId } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "@common/toasts";
import { getDateInputFormat } from "@common/dateFormating";
import { Moment } from "moment";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { v4 } from "uuid";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";
import classes from "./NewNote.module.scss";

export interface NewNoteProps {
    correlationId: string;
    showConfirmationToast?: boolean;
    relatedTo: TaskRelationEnum;
}

export const NewNote: React.FC<NewNoteProps> = ({ correlationId, showConfirmationToast, relatedTo }) => {
    const [files, setFiles] = React.useState<FileList>();
    const [noteText, setNoteText] = React.useState<string>("");
    const [pendingDate, setPendingDate] = React.useState<Moment>(null);
    const [dateFieldKey, setDateFieldKey] = React.useState<number>(1);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [alertNoteToTTC, setAlertNoteToTTC] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const app = useSelector(appSelector);

    const isPoliceApp = React.useMemo(() => app === Apps.Police, [app]);
    const isAdminApp = React.useMemo(() => app === Apps.Admin, [app]);
    const isBookingApp = React.useMemo(() => app === Apps.Booking, [app]);

    const onFileChange = React.useCallback((fileList: FileList) => {
        setFiles(fileList);
    }, []);

    const onTextAreaChange = React.useCallback((e: any, d: TextAreaProps) => {
        setNoteText(d.value.toString());
    }, []);

    React.useEffect(() => { validateNoteDate(pendingDate); }, [pendingDate]);
    const validateNoteDate = async (noteDate: moment.Moment) => {
        const errors = muiTodayOrfutureDateValidator(noteDate);
        setErrorMessage(!errors ? "" : errors);
    };

    const handleOnClick = React.useCallback(async () => {
        if (noteText !== "" && errorMessage === "") {
            const isAlertNoteToTTC = alertNoteToTTC;
            await dispatch(addNoteToCorrelationId(
                correlationId,
                v4(),
                noteText,
                relatedTo,
                isAlertNoteToTTC,
                pendingDate,
                files && files.length > 0 ? files[0] : undefined
            ));
            setNoteText("");
            setFiles(undefined);
            setPendingDate(null);
            setDateFieldKey(dateFieldKey + 1);
            setAlertNoteToTTC(false);
            if (showConfirmationToast) {
                toast.success("Note added");
            }
            else if (isPoliceApp && isAlertNoteToTTC) {
                toast.success("Thank you. An alert has been sent to TTC");
            }
        }
    }, [noteText, errorMessage, alertNoteToTTC, dispatch, correlationId, relatedTo, pendingDate, files, dateFieldKey, showConfirmationToast, isPoliceApp]);

    const onToggleChange = React.useCallback(() => setAlertNoteToTTC(state => !state), []);

    return (
        <Grid className="note-container">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form>
                        <TextArea placeholder={"Enter note"} rows={2} onChange={onTextAreaChange} value={noteText} />
                    </Form>
                </Grid.Column>
            </Grid.Row>
            {
                (relatedTo !== TaskRelationEnum.Organisation && isPoliceApp) &&
                <Grid.Row>
                    <Grid.Column width={16}>
                        <label className="bold">Alert note to TTC</label>
                        <Form.Checkbox
                            toggle
                            label={alertNoteToTTC ? "Yes" : "No"}
                            checked={alertNoteToTTC}
                            onChange={onToggleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            }
            {<Authorize authorize={TtcClientAdvisorRole}>
                <Grid.Row className="no-padding">
                    <Grid.Column>
                        <Form>
                            <Form.Field width={isBookingApp? null : 4} className={isBookingApp? classes.confirmationNote : null}>
                                <MuiDateField
                                    key={dateFieldKey}
                                    placeholder={getDateInputFormat()}
                                    label="Pending date"
                                    value={pendingDate}
                                    onChange={setPendingDate}
                                />

                                {errorMessage !== "" && <p className="status-attention"><b>{errorMessage}</b></p>}
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Authorize>}
            <Grid.Row>
                <Grid.Column>
                    <Button className="wide" disabled={(noteText?.length || 0) === 0} onClick={handleOnClick} floated="left" content="Add Note" />
                    {isAdminApp && <>
                        <FileUploadInput
                            fileList={files}
                            uploadLabel="Add File"
                            onChange={onFileChange}
                        />
                        {(files && files.length > 0) && <span>{files[0].name}</span>}
                    </>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
