/* eslint-disable eqeqeq */
import * as React from "react";
import { Button, Form, Header, Modal } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { ClearTrainerAvailabilityEnum, OtherCancellationReason, trainerCancellationReason, TrainerType } from "../../model";
import { optionsFromText } from "@common/crud/common";
import { cancelTrainer } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { TrainerRemovalWarningComponent } from "@common/crud/eventInstance/components/TrainerRemovalWarningComponent";
import { cancelGroupTrainer } from "@common/crud/eventInstanceGroup/actions";
import { RemoveTrainerAvailability } from "@common/crud/eventInstance/components/details/RemoveTrainerAvailability";
import { validators } from "not-valid";

interface Props {
    eventInstanceId: string;
    trainerId: string;
    trainerType: TrainerType;
    dayParts?: number[];
    exteralAssessmentDue?: boolean;
}

export const CancelTrainer: React.FC<Props> = ({
    eventInstanceId,
    trainerId,
    trainerType,
    dayParts,
    exteralAssessmentDue,
}) => {

    const [reasonForCancellation, setReasonForCancellation] = React.useState<string>(trainerCancellationReason[0]);
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [otherReason, setOtherReason] = React.useState<string>();
    const [confirm, setConfirm] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>();
    const [clearTrainerAvailability, setClearTrainerAvailability] = React.useState<ClearTrainerAvailabilityEnum>(ClearTrainerAvailabilityEnum.No);
    const dispatch = useDispatch();
    const eventInstance = useSelector(eventInstanceSelector);
    const hasMonitor = eventInstance?.monitorTrainers?.length > 0;
    const showMonitorWarning = hasMonitor && trainerType !== TrainerType.MonitorTrainer;
    const [loading, setLoading] = React.useState(false);
    const isDdrs = eventInstance.groupId?.length > 0;

    const otherCancellationReasonChoosen = reasonForCancellation === OtherCancellationReason;

    const onReasonForCancelChanged = React.useCallback((value: string) => {
        if (value) {
            setReasonForCancellation(value);
            setConfirm(true);
        }
    }, []);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    const onCancelTrainer = React.useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const onClearAndClose = React.useCallback(() => {
        setIsModalOpen(false);
        setReasonForCancellation(null);
        setOtherReason(null);
        setOtherReasonValid(true);
        setConfirm(false);
        setLoading(false);
    }, []);

    const onSubmit = React.useCallback(async () => {
        try {
            if (confirm) {
                setLoading(true);
                if (isDdrs) {
                    await dispatch(cancelGroupTrainer(eventInstance.groupId, trainerId, trainerType, reasonForCancellation, otherReason, dayParts,
                        clearTrainerAvailability));
                } else {
                    await dispatch(cancelTrainer(eventInstanceId, trainerId, trainerType, reasonForCancellation, otherReason, clearTrainerAvailability));
                }
            }
        } finally {
            onClearAndClose();
        }
    }, [confirm, dayParts, dispatch, eventInstance.groupId, eventInstanceId, isDdrs, reasonForCancellation, trainerId, trainerType, clearTrainerAvailability,
        otherReason, onClearAndClose]);

    return (
        <>
            <Button secondary content="Cancel Trainer" onClick={onCancelTrainer} loading={loading} />
            <Modal open={isModalOpen}>
                <Modal.Content>
                    <TrainerRemovalWarningComponent hasMonitor={showMonitorWarning} hasExternalAssessmentDue={exteralAssessmentDue} />
                    <Form>
                        <Header size="small">Please confirm reason for removing trainer</Header>
                        <Input.Dropdown
                            value={reasonForCancellation}
                            options={optionsFromText(trainerCancellationReason)}
                            onChange={onReasonForCancelChanged}
                        />
                        {otherCancellationReasonChoosen && (
                            <Input.Textarea
                                value={otherReason}
                                label="Other Reason"
                                validation={[validators.validLength({ max: 500 })]}
                                onChange={onOtherReasonChanged}
                            />
                        )}
                    </Form>
                    {eventInstance.groupId == null &&
                        <RemoveTrainerAvailability
                            clearTrainerAvailability={clearTrainerAvailability}
                            setClearTrainerAvailability={setClearTrainerAvailability} />
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={onClearAndClose} loading={loading} disabled={loading}>Cancel</Button>
                    <Button
                        primary
                        disabled={(otherCancellationReasonChoosen && !otherReasonValid) || loading}
                        onClick={onSubmit}
                        loading={loading}
                    >
                        Continue
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
