import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { Table } from "semantic-ui-react";
import * as React from "react";
import { OptTrainerRow } from "@common/crud/trainer/components/details/OptTrainerRow";
import { OptSchemeTrainerAttributeModel } from "@common/crud/trainer/model";
import { Apps } from "@common/model";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";

interface Props {
    trainerAttributes: TrainerAttribute[];
    setOptTrainerAttributes: React.Dispatch<React.SetStateAction<OptSchemeTrainerAttributeModel[]>>;
    optTrainerAttributes: OptSchemeTrainerAttributeModel[];
}

export const OptTrainerTable: React.FC<Props> = ({ trainerAttributes, setOptTrainerAttributes, optTrainerAttributes }) => {
    const app = useSelector(appSelector);
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Opt in/out</Table.HeaderCell>
                    {
                        app === Apps.Admin &&
                        <>
                            <Table.HeaderCell>Last Monitored Date</Table.HeaderCell>
                            <Table.HeaderCell>Next Monitoring Due</Table.HeaderCell>
                            <Table.HeaderCell>Next Monitoring Session</Table.HeaderCell>
                        </>
                    }
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {trainerAttributes.map(trainerAttribute => (
                    <OptTrainerRow
                        app={app}
                        key={trainerAttribute.attributeDefinitionId}
                        trainerAttribute={trainerAttribute}
                        setOptTrainerAttributes={setOptTrainerAttributes}
                        optTrainerAttributes={optTrainerAttributes} />
                ))}
            </Table.Body>
        </Table>
    );
};
