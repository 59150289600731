import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";

export const MobileLegend=() => {

    return (
        <>
            <Grid.Row>
                <Grid.Column width={5}><div className="availability"></div></Grid.Column>
                <Grid.Column width={5}><div className="unavailability"></div></Grid.Column>
                <Grid.Column width={5}><div className="booked">COURSE</div></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5} textAlign="center">Availability</Grid.Column>
                <Grid.Column width={5} textAlign="center">Unavailability</Grid.Column>
                <Grid.Column width={5} textAlign="center">Booked</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8} textAlign="center" ><Icon name="home" /></Grid.Column>
                <Grid.Column width={8} textAlign="center"><Icon name="wifi" /></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8} textAlign="center">Classroom Course</Grid.Column>
                <Grid.Column width={8} textAlign="center">Digital Course</Grid.Column>
            </Grid.Row>
        </>
    );
};
