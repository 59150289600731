/* eslint-disable max-len */
import { EventTypeCategory } from "@common/crud/attendee/model";
import * as React from "react";
import { useSelector } from "react-redux";
import { EventInstance } from "../..";
import { digitalPlanningSessionsSelector } from "../../selectors";
import { PlannedEventsTable } from "./PlannedEventsTable";

interface TotalPlannedEventsTableProps {
    month: moment.Moment;
    plannedEventInstances: EventInstance[];
};

export const TotalPlannedEventsTable: React.FC<TotalPlannedEventsTableProps> = ({ month, plannedEventInstances }) => {
    const planningSessions = useSelector(digitalPlanningSessionsSelector);
    const eventsFromPlanningSessions = planningSessions.flatMap(p => p.plannedDigitalEvents);
    const eventsFromPlanningSessionsIds = eventsFromPlanningSessions.map(p => p.id);
    const plannedEventIds = plannedEventInstances.map(p => p.id);
    const newEvents = plannedEventInstances.filter(p => eventsFromPlanningSessionsIds.findIndex(i => i === p.id) === -1);
    const existingEventsThatHaveNotBeenDeleted = planningSessions.flatMap(p => p.plannedDigitalEvents).filter(p => p.startDate.month() !== month.month() || plannedEventIds.findIndex(i => i === p.id) !== -1);
    const allEvents = [...newEvents, ...existingEventsThatHaveNotBeenDeleted];
    return (
        <PlannedEventsTable
            plannedEventInstances={allEvents}
            eventTypeCategory={EventTypeCategory.Ddrs}
            title="Total Summary"
            countProtected
        />
    );
};
