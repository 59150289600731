import * as React from "react";

import { Detail } from "@neworbit/simpleui-detail";

import { CoreDetailProps } from "../Detail";
import { WorkflowType,
    ModuleTypeEnum,
    ModuleType,
    WorkflowTypeEnum,
    BookingAppTextKeys,
    BookingType,
    ProductCategory,
    CorporateCertificateType,
    ProductCategoryEnum,
    DurationType } from "../../model";
import { Grid, GridRow, Segment, Tab } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { getCurrencyFormat } from "@common/formating";
import { SchemeDeliveryType, SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { RebookingFeesDetails } from "@common/crud/eventType/components/details/RebookingFeesDetails";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate, isWorkflowConstruction,
    supportsOnRoadReport,
    workflowTypeSupportsCertificate } from "@common/global/CommonHelpers";
import { CourseCategories } from "@common/components/CourseCategories";
import { ApplicationState } from "src/Admin.App/src/applicationState";
import { useSelector } from "react-redux";

export const DetailsTab: React.FC<CoreDetailProps> = ({ eventType, trainerAttributes }) => {
    const practicalTrainerAttribute =
        eventType.practicalTrainerAttributeId && trainerAttributes.find(attr => attr.id === eventType.practicalTrainerAttributeId);
    const manualCarPracticalTrainerAttribute =
        eventType.manualCarPracticalTrainerAttributeId && trainerAttributes.find(attr => attr.id === eventType.manualCarPracticalTrainerAttributeId);
    const automaticCarPracticalTrainerAttribute =
        eventType.automaticCarPracticalTrainerAttributeId &&
        trainerAttributes.find(attr => attr.id === eventType.automaticCarPracticalTrainerAttributeId);

    const isPracticalEvent = eventType.moduleType === ModuleTypeEnum.Practical || eventType.moduleType === ModuleTypeEnum.Both;
    const isDors = eventType.workflowType === WorkflowTypeEnum.Dors;
    const isCpc = eventType.workflowType === WorkflowTypeEnum.CPC;
    const isCorporate = isEventTypeWorkflowCorporate(eventType);
    const isConstruction = isEventTypeWorkflowConstruction(eventType);
    const showCertificateInfo = !eventType.flexibleCertificatesRequired && (eventType.productCategory === +(ProductCategoryEnum.CPC)
        || eventType.productCategory === +(ProductCategoryEnum.FORS));
    const yesNoFromBool = (value: boolean) => value ? "Yes" : "No";

    const constructionCategories = useSelector((state: ApplicationState) => state.appSettings.constructionCategories);

    return (
        <Tab.Pane>
            <Grid>
                <h3>{(isCorporate || isConstruction) ? "Product Details" : "Scheme Details"}</h3>
                {(isCorporate || isConstruction) &&
                    <Detail label="Name" value={eventType.name} labelWidth={5} valueWidth={11} />
                }
                {!isCorporate && !isConstruction && <>
                    <Detail label="Name (En)" value={eventType.name} labelWidth={5} valueWidth={11} />
                    <Detail label="Name (Cy)" value={eventType.nameCy} labelWidth={5} valueWidth={11} />
                </>}
                <Detail label="Abbreviation" value={eventType.abbreviation} labelWidth={5} valueWidth={11} />
                {(isCpc || isConstruction) && (
                    <>
                        <Detail
                            label="Product Intro"
                            value={<HtmlRenderer value={eventType?.productIntro} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Product Description"
                            value={<HtmlRenderer value={eventType?.productDescription} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                )}
                <Detail
                    label="Workflow Type"
                    value={eventType.workflowType ? WorkflowType[eventType.workflowType] : WorkflowType[0]}
                    labelWidth={5}
                    valueWidth={11}
                />
                <Detail
                    label="Delivery Type"
                    value={eventType.deliveryType ? SchemeDeliveryType[eventType.deliveryType] : SchemeDeliveryType[0]}
                    labelWidth={5}
                    valueWidth={11}
                />
                {(isCorporate || isConstruction || isDors) &&
                    <Detail label="Course Type" value={ModuleType[eventType.moduleType]} labelWidth={5} valueWidth={11} />}
                {((isCorporate || isConstruction) && eventType.moduleType === ModuleTypeEnum.Both)
                    && <Detail label="Duration Type" value={DurationType[eventType.durationType]} labelWidth={5} valueWidth={11} />}
                {(isCorporate || isConstruction) &&
                <>
                    <Detail label="Booking Type" value={BookingType[eventType.bookingType]} labelWidth={5} valueWidth={11} />
                    {(eventType?.workflowType === WorkflowTypeEnum.CPC || isWorkflowConstruction(eventType?.workflowType)) &&
                    <Detail label="Show in Open Booking App" value={yesNoFromBool(eventType?.showInOpenBookingApp)} labelWidth={5} valueWidth={11} />
                    }
                    <Detail label="Delegates Required?" value={yesNoFromBool(eventType?.delegatesRequired)} labelWidth={5} valueWidth={11} />
                    {isCpc && (
                        <Detail
                            label="Flexible Certificates Required?"
                            value={yesNoFromBool(eventType?.flexibleCertificatesRequired)}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    )}
                    {workflowTypeSupportsCertificate(eventType.workflowType) && (
                        <Detail label="Certificate Required?" value={yesNoFromBool(eventType?.certificateRequired)} labelWidth={5} valueWidth={11} />
                    )}
                    {
                        supportsOnRoadReport(eventType?.workflowType, eventType?.productCategory) &&
                        <Detail
                            label="On Road Report Required?"
                            value={yesNoFromBool(eventType.onRoadReportRequired)}
                            labelWidth={5}
                            valueWidth={11} />
                    }
                    {isConstruction &&
                        <>
                            <Detail label="Reseller Course" value={yesNoFromBool(eventType.resellerCourse)} labelWidth={5} valueWidth={11} />
                            <CourseCategories categories={constructionCategories} values={eventType.courseCategories} />
                        </>
                    }
                    <Detail label="Product Category" value={ProductCategory[eventType.productCategory]} labelWidth={5} valueWidth={11} />
                    {showCertificateInfo &&
                        <>
                            <Detail
                                label="Mandatory Certificate"
                                value={CorporateCertificateType[eventType.mandatoryCorpCertificate]}
                                labelWidth={5}
                                valueWidth={11} />
                            <Detail
                                label="Optional Certificate"
                                value={CorporateCertificateType[eventType.optionalCorpCertificate]}
                                labelWidth={5}
                                valueWidth={11} />
                        </>}
                    {(isCorporate || isConstruction) && <>
                        {eventType.deliveryType !== SchemeDeliveryTypeEnum.Digital &&
                            <Detail
                                label="Internal Product ID (Classroom)"
                                value={eventType.internalId}
                                labelWidth={5}
                                valueWidth={11} />
                        }
                        {eventType.deliveryType !== SchemeDeliveryTypeEnum.Classroom &&
                            <Detail
                                label="Internal Product ID (Digital)"
                                value={eventType.internalIdDigital}
                                labelWidth={5}
                                valueWidth={11} />
                        }
                    </>}
                    {(eventType?.workflowType === WorkflowTypeEnum.CPC || eventType?.productCategory === ProductCategoryEnum.OnRoadWithCpc) &&
                        <Detail
                            label="Course Approval Number"
                            value={eventType.courseApprovalNumber}
                            labelWidth={5}
                            valueWidth={11} />
                    }
                </>}
                {isDors && <Detail label="DORS Id"
                    value={eventType.dorsId ? `${eventType.dorsId}` : ""} labelWidth={5} valueWidth={11} />}
                {isPracticalEvent &&
                <>
                    <Detail
                        label="Max Number Of Attendees Per Practical Trainer"
                        value={`${eventType.maxNumberOfAttendeesPerPracticalTrainer}`}
                        labelWidth={5}
                        valueWidth={11}
                    />
                    {!isCorporate && !isConstruction && (
                        <Detail
                            label="Show Car"
                            value={eventType.showCarType?.toString() ?? "false"}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    )}
                    {isCorporate && isConstruction && (
                        <Detail
                            label="Practical Trainer attribute"
                            value={practicalTrainerAttribute?.name}
                            labelWidth={5} valueWidth={11}
                        />
                    )}
                    {!isCorporate && !isConstruction && (
                        <>
                            <Detail
                                label="Manual Car Practical Trainer attribute"
                                value={manualCarPracticalTrainerAttribute?.name}
                                labelWidth={5} valueWidth={11}
                            />
                            <Detail
                                label="Automatic Car Practical Trainer attribute"
                                value={automaticCarPracticalTrainerAttribute?.name}
                                labelWidth={5}
                                valueWidth={11}
                            />
                        </>
                    )}
                    <Detail
                        label="Car Type Note (En)"
                        value={eventType.amendableBookingAppTexts[BookingAppTextKeys.EnglishCarTypeNote] ?? ""}
                        labelWidth={5}
                        valueWidth={11}
                    />
                    <Detail
                        label="Car Type Note (Cy)"
                        value={eventType.amendableBookingAppTexts[BookingAppTextKeys.CymraegCarTypeNote] ?? ""}
                        labelWidth={5}
                        valueWidth={11}
                    />
                </>}
                {!isCorporate && !isConstruction &&
                    <Detail label="Flexi-Booking Enabled" value={yesNoFromBool(eventType.flexiPayEnabled)} labelWidth={5} valueWidth={11} />
                }
                {eventType.flexiPayEnabled && eventType.flexiPayFees?.length > 0 &&
                    <>
                        <h3>Flexi-Booking Fees</h3>
                        <Segment secondary as={GridRow}>
                            <Grid columns="two">
                                <Grid.Row>
                                    <Grid.Column>Fee</Grid.Column>
                                    <Grid.Column>Effective Date</Grid.Column>
                                </Grid.Row>
                                {eventType.flexiPayFees.map((fee, index) => (
                                    <Grid.Row key={index}>
                                        <Grid.Column>
                                            {getCurrencyFormat(fee.fee)}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {fee.effectiveDate?.format(DateFormat.Short)}
                                        </Grid.Column>
                                    </Grid.Row>
                                ))}
                            </Grid>
                        </Segment>
                    </>
                }
                {eventType?.rebookingFees && <RebookingFeesDetails eventType={eventType} />}
                {isDors &&<Detail label="Current DORS scheme" value={yesNoFromBool(eventType.currentDorsScheme)} labelWidth={5} valueWidth={11} />}
                <Detail
                    label="Expiry Date"
                    value={eventType.expiryDate && eventType.expiryDate.format(DateFormat.Short)}
                    labelWidth={5}
                    valueWidth={11}
                />
            </Grid >
        </Tab.Pane>
    );
};
