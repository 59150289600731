import { Input } from "@neworbit/simpleui-input";
import moment from "moment";
import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { MuiDateField } from "@common/components/MuiDateField";
import { AttendeeListModel } from "@common/crud/attendee";
import "../UpdateDelegatesTable.scss";

interface DqcDetailsProps {
    isEditable: boolean;
    fieldsForMarkup: string[];
    delegate: AttendeeListModel;
    onDqcReferenceChange: (value: string, valid: boolean) => void;
    onDqcExpiryChange: (value: moment.Moment, valid: boolean) => void;
    isEventInstanceOpen: boolean;
    submitted: boolean;
}

export function DqcDetails({
    isEditable,
    fieldsForMarkup,
    delegate,
    onDqcReferenceChange,
    onDqcExpiryChange,
    isEventInstanceOpen,
    submitted
}: DqcDetailsProps) {
    return (
        <Grid className="register-field-grid">
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header">
                    DQC Reference
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === "dqcReference") ? "amber-field" : ""}>
                    {isEditable ? (
                        <Input.Text
                            value={delegate.dqcReference ?? ""}
                            onChange={onDqcReferenceChange}
                            placeholder="Ref"
                            disabled={isEventInstanceOpen && !delegate.organisationId}
                            showErrors={submitted}
                        />
                    ) : delegate.dqcReference ? (
                        <span className="break-all">{delegate.dqcReference}</span>
                    ) : (
                        <Icon className="validation-icon" name="exclamation circle" />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header">
                    DQC Expiry
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === "dqcExpiry") ? "amber-field" : ""}>
                    {isEditable ? (
                        <MuiDateField
                            value={delegate.dqcExpiry}
                            placeholder="Expiry"
                            onChange={onDqcExpiryChange}
                            disabled={isEventInstanceOpen && !delegate.organisationId}
                            format="DD/MM/YY"
                            smallButton
                            showErrors={submitted}
                        />
                    ) : delegate.dqcExpiry ? (
                        <span className="break-all">{delegate.dqcExpiry ? delegate.dqcExpiry.format("DD/MM/YY") : ""}</span>
                    ) : (
                        <Icon className="validation-icon" name="exclamation circle" />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
