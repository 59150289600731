import * as React from "react";
import { Form, useFormikContext } from "formik";

export function SemanticForm(props: React.FormHTMLAttributes<HTMLFormElement>) {

    const classes = [ "ui", "form" ];

    const { errors } = useFormikContext();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
        classes.push("error");
    }

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <Form
            {...props}
            className={classes.join(" ")}
        >
            {props.children}
        </Form>
    );
}
