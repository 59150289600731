import * as React from "react";
import { EventInstance } from "../..";
import { useSelector } from "react-redux";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { Table } from "semantic-ui-react";
import { Trainer } from "@common/crud/trainer";
import moment from "moment";
import { DdrsPlannedEventsOverviewTableHeader } from "./DdrsPlannedEventsOverviewTableHeader";
import { DdrsPlannedEventsOverviewTableRow } from "./DdrsPlannedEventsOverviewTableRow";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

export interface DdrsPlannedEventsTableProps {
    plannedEventInstances: EventInstance[];
    trainers: Trainer[];
    months: moment.Moment[];
}

export const DdrsPlannedEventsOverviewTable: React.FC<DdrsPlannedEventsTableProps> = ({ plannedEventInstances, trainers, months }) => {

    const allEventTypes = useSelector(allEventTypesSelector);

    // We can assume that there is only one event type for DDRS.
    const ddrsEventType = allEventTypes?.find(e => e.workflowType === WorkflowTypeEnum.DDRS);

    // eslint-disable-next-line eqeqeq
    if (ddrsEventType == null) {
        return <></>;
    }

    return (
        <>
            <Table>
                <DdrsPlannedEventsOverviewTableHeader months={months} />
                <Table.Body>
                    <DdrsPlannedEventsOverviewTableRow
                        name={"Total"}
                        eventType={ddrsEventType}
                        events={plannedEventInstances}
                        months={months}
                    />
                    {trainers.map(t => (
                        <DdrsPlannedEventsOverviewTableRow
                            key={t.id}
                            name={t.fullName}
                            eventType={ddrsEventType}
                            events={plannedEventInstances.filter(e => e.trainerId === t.id)}
                            trainer={t}
                            months={months}
                        />
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};
