import * as React from "react";

import { Button, Grid } from "semantic-ui-react";

import { ChangeEventType, ChangeEventTypes } from "../model";

export interface HistoryFilterProps {
    eventType: ChangeEventType;
    value: boolean;
    classNames?: string[];
    onChange: (value: ChangeEventType) => void;
}

export const HistoryFilter: React.FC<HistoryFilterProps> = ({ onChange, value, eventType, classNames }) => {
    const handleClick = React.useCallback(() => {
        onChange(eventType);
    }, [onChange, eventType]);

    return (
        <Grid.Row>
            <Button
                fluid
                className={`filter ${value ? "filter-ticked" : "filter-unticked"} ${classNames?.join(" ") ?? ""}`}
                onClick={handleClick}
            >
                {ChangeEventTypes[eventType]}
            </Button>
        </Grid.Row>
    );
};
