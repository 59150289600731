import { BusinessDriverEventAdmin, IqaTeamRole, TtcAttributeManager, TtcClientAdvisorRole, TtcCorporateEventAdmin } from "@common/auth/model";
import { AppCommonState } from "@common/appCommonState";
import { State as RouterState } from "redux-little-router";
import { BusinessHomeRouteName } from "../eventType/model";

export const isUserClientAdvisor = (state: AppCommonState) => state.currentUser && state.currentUser.roles.includes(TtcClientAdvisorRole);

export const isUserAttributeManager = (state: AppCommonState) => state.currentUser && state.currentUser.roles.includes(TtcAttributeManager);

export const isIqaTeam = (state: AppCommonState) => state.currentUser && state.currentUser.roles.includes(IqaTeamRole);

export const isBusinessDriverAdmin = (state: AppCommonState) => state.currentUser && state.currentUser.roles.includes(BusinessDriverEventAdmin);

export const isTtcCorporateAdmin = (state: AppCommonState) => state.currentUser && state.currentUser.roles.includes(TtcCorporateEventAdmin);

export const routerPathnameSelector = (state: RouterState) => state.router.pathname;

export const routerQuerySelector = (state: RouterState) => state.router.query;

export const isLoadingSelector = (state: AppCommonState) => state.loading.active;

export const isCorporateSelector = (state: RouterState) => state.router.pathname.indexOf("corporate-event-management") !== -1;

export const isConstructionSelector = (state: RouterState) => state.router.pathname.indexOf("construction-event-management") !== -1;

export const isPoliceOrCourtSelector = (state: RouterState) => state.router.pathname.indexOf("police-and-court-event-management") !== -1;

export const lineOfBusinessRouteSelector = (state: RouterState): BusinessHomeRouteName => isCorporateSelector(state)
    ? "corporate-event-management"
    : isConstructionSelector(state)
        ? "construction-event-management"
        : "police-and-court-event-management";

export const adminAppEndpointSelector = (state: AppCommonState) => {
    return state.appSettings?.internalAppSettings?.adminAppEndpoint;
};

export const corpBookingAppEndpointSelector = (state: AppCommonState) => {
    return state.appSettings?.internalAppSettings?.corporateBookingAppEndpoint;
};

export const appSelector = (state: AppCommonState) => state.app;

export const isPendingCoursesSelector = (state: RouterState) => state.router.pathname.indexOf("pending-courses") !== -1;
