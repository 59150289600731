import * as React from "react";
import { Grid, Radio, CheckboxProps, Container } from "semantic-ui-react";

export interface YesNoQuestionProps {
    disabled?: boolean;
    label: React.ReactNode;
    value: boolean;
    onChange: (e: any, data: CheckboxProps) => void;
}

export const getYesNoValue = (setState: (value: boolean) => void) => (_: any, data: CheckboxProps) => {
    setState(data.value === "true");
};

export const YesNoQuestion = (props: YesNoQuestionProps) => {
    return (
        <Container className="margin-bottom">
            <Grid>
                <Grid.Row width={16}>
                    <Grid.Column width={12}>
                        <p>{props.label}</p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Radio
                            className="question-checkbox"
                            disabled={props.disabled}
                            label="Yes"
                            value="true"
                            checked={props.value}
                            onChange={props.onChange}
                        />
                        <Radio
                            className="question-checkbox"
                            disabled={props.disabled}
                            label="No"
                            value="false"
                            checked={props.value === false}
                            onChange={props.onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
