import * as React from "react";
import { useCallback } from "react";
import { Button } from "semantic-ui-react";
import "./SelectCourseButton.scss";

export interface SelectCourseButtonProps {
    offeredCourseId: string;
    selectedEventInstanceId?: string;
    setSelectedEventInstance: (eventInstanceId: string) => void;
}

export const SelectCourseButton = ({ offeredCourseId, selectedEventInstanceId, setSelectedEventInstance }: SelectCourseButtonProps) => {
    const handleCourseSelected = useCallback(() => {
        setSelectedEventInstance(offeredCourseId);
    }, [offeredCourseId, setSelectedEventInstance]);

    const label = selectedEventInstanceId === offeredCourseId ? "Selected" : "Select";

    return (
        <div onClick={handleCourseSelected} className={selectedEventInstanceId === offeredCourseId ? "selected-course-button" : "select-course-button"}>
            <Button className="link-button select-link-button">{label}</Button>
        </div>
    );
};
