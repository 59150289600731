import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { TrainerDetails } from "./TrainerDetails";
import { Detail } from "@neworbit/simpleui-detail";
import { Button, Divider, Grid, Message, Popup, Segment } from "semantic-ui-react";
import { ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { eventTypeSelector } from "@common/crud/eventType/selectors";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole, TtcPlannerRole } from "@common/auth/model";
import { TrainersEdit } from "./TrainersEdit";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventInstance, EventTrainer, TrainerType, TrainerTypeName } from "../../model";
import { EiSideBar } from "../EiSideBar";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { TrainerSmsModal } from "../trainers/TrainerSmsModal";
import {
    RegionalCoordinatorApi
} from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/RegionalCoordinatorApi";
import { loadOrganisationDetailFromForceId } from "@common/crud/organisation/actions";
import { syncTrainersWithDors } from "../../actions";
import { AvailabilityModal } from "./AvailabilityModal";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { isEventInstanceWorkflowPoliceOrCourt } from "@common/global/CommonHelpers";

export const Trainers: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const eventType = useSelector(eventTypeSelector(eventInstance.eventTypeId));
    const isTheoryEvent = eventType?.moduleType === ModuleTypeEnum.Theory || eventType?.moduleType === ModuleTypeEnum.Both;
    const isPracticalEvent = eventType?.moduleType === ModuleTypeEnum.Practical || eventType?.moduleType === ModuleTypeEnum.Both;
    const isDigital = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    const allTrainers = (eventInstance.trainers ?? []).concat(eventInstance.practicalTrainers ?? []);
    const hasUnsyncedTrainers = allTrainers.map(t => !t?.syncedWithDors).some(x => x);
    const userIsPlanner = useSelector(currentUserIsInRoleSelector(TtcPlannerRole));
    const isAdminApp = useSelector(appSelector) === Apps.Admin;
    const [bookedRcCoverTrainerIds, setBookedRcCoverTrainerIds] = React.useState<string[]>([]);
    const dispatch = useDispatch();
    const hasGetRcCoverTrainerBeenCalledOnce = React.useRef(false);
    const isDors = eventInstance.workflowType === WorkflowTypeEnum.Dors;
    const canHaveMoreThanOneTrainer = !isDigital && isDors;
    const isPoliceOrCourtWorkflow = isEventInstanceWorkflowPoliceOrCourt(eventInstance);

    React.useEffect(() => {
        const getRcCoverTrainer = async () => {
            const trainerIds = eventInstance?.trainers?.map(trainer => trainer.id).join(",");
            setBookedRcCoverTrainerIds(await new RegionalCoordinatorApi()
                .getRcCoverTrainerIds(eventInstance.startDate, trainerIds));
        };

        if (userIsPlanner && eventInstance !== null && isDigital && !!eventInstance.trainers?.length && !hasGetRcCoverTrainerBeenCalledOnce.current) {
            hasGetRcCoverTrainerBeenCalledOnce.current = true;
            getRcCoverTrainer();
        }
    }, [eventInstance, isDigital, userIsPlanner]);

    React.useEffect(() => {
        if (isAdminApp && eventInstance?.forceId) {
            dispatch(loadOrganisationDetailFromForceId(eventInstance.forceId));
        }
    }, [dispatch, isAdminApp, eventInstance?.forceId]);

    async function onResubmitToDors() {
        dispatch(syncTrainersWithDors(eventInstance.id));
    }

    const { trainers, practicalTrainers, monitorTrainers, observerTrainers } = eventInstance;

    const content = () => {
        return (<>
            <Divider hidden />
            <Grid>
                <h1 className="event-instance-title">Trainer details</h1>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <EventInstanceBanner eventInstance={eventInstance} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className="button-container justify-end">
                            <Authorize authorize={[TtcPlannerRole]}>
                                <AvailabilityModal
                                    eventInstanceId={eventInstance.id}
                                    moduleType={eventInstance.moduleType}
                                    workflowType={eventInstance.workflowType}
                                    startDate={eventInstance.startDate}
                                    canHaveMoreThanOneTrainer={canHaveMoreThanOneTrainer}
                                    adminAvailableRoles={eventInstance.adminAvailableRoles}
                                    eventInstanceDeliveryType={eventInstance.eventInstanceDeliveryType}
                                />

                                {isPoliceOrCourtWorkflow &&
                                    (
                                        <Popup
                                            disabled={hasUnsyncedTrainers}
                                            // Mocking disabled prop on button and overwriting pointer-events: none to get popup only on disabled button
                                            content={allTrainers.some(x => x)? "All event instance trainers are currently synced with DORS":
                                                "No practical or theory trainers to sync"}
                                            trigger={
                                                <Button
                                                    className={!hasUnsyncedTrainers?"disabled disabled-tooltip":""}
                                                    content="Sync Trainers"
                                                    onClick={!hasUnsyncedTrainers? null : onResubmitToDors}                                        />
                                            }
                                        />
                                    )
                                }
                            </Authorize>
                            <Authorize authorize={[TtcPlannerRole, TtcClientAdvisorRole]}>
                                <TrainerSmsModal />
                            </Authorize>
                        </div>
                    </Grid.Column>

                </Grid.Row>
                {
                    isTheoryEvent &&
                    getTrainersByType(
                        eventInstance,
                        eventType,
                        isDigital,
                        TrainerType.TheoryTrainer,
                        trainers,
                        bookedRcCoverTrainerIds,
                        isPoliceOrCourtWorkflow,
                    )
                }
                {
                    isPracticalEvent &&
                    getTrainersByType(
                        eventInstance,
                        eventType,
                        isDigital,
                        TrainerType.PracticalTrainer,
                        practicalTrainers,
                        bookedRcCoverTrainerIds,
                        isPoliceOrCourtWorkflow,
                    )
                }
                {
                    monitorTrainers && isPoliceOrCourtWorkflow &&
                    getTrainersByType(
                        eventInstance,
                        eventType,
                        isDigital,
                        TrainerType.MonitorTrainer,
                        monitorTrainers,
                        bookedRcCoverTrainerIds,
                        isPoliceOrCourtWorkflow,
                    )
                }
                {
                    observerTrainers &&
                    getTrainersByType(
                        eventInstance,
                        eventType,
                        isDigital,
                        TrainerType.OtherTrainer,
                        observerTrainers,
                        bookedRcCoverTrainerIds,
                        isPoliceOrCourtWorkflow,
                    )
                }
                <Grid.Row>
                    {
                        isAdminApp && eventInstance.errorMessageDuringUpdateTrainers &&
                        <Message content={eventInstance.errorMessageDuringUpdateTrainers} as={Segment} error />
                    }
                </Grid.Row>
            </Grid>
        </>);
    };

    return (<EiSideBar children={content()} />);
};

function getLabelForTrainerType(typeName: string, trainers: EventTrainer[]) {
    const name = `${typeName} Trainers`;
    return trainers.length !== 0 ? name : `There are no ${name.toLocaleLowerCase()} assigned to this course`;
}

function getTrainerDetails(
    eventInstance: EventInstance,
    trainer: EventTrainer,
    trainerType: TrainerType,
    bookedRcCoverTrainerIds: string[],
    isPoliceOrCourtWorkflow: boolean
) {
    return (
        <TrainerDetails
            key={trainer.id + eventInstance.detailUpdated}
            eventInstanceId={eventInstance.id}
            trainer={trainer}
            trainerType={trainerType}
            bookedRcCoverTrainerIds={bookedRcCoverTrainerIds}
            isPoliceOrCourtWorkflow={isPoliceOrCourtWorkflow}
            eventTypeCategory={eventInstance.eventTypeCategory}
        />
    );
}

function getTrainersByType(
    eventInstance: EventInstance,
    eventType: any,
    isDigital: boolean,
    trainerType: TrainerType,
    trainers: EventTrainer[],
    bookedRcCoverTrainerIds: string[],
    isPoliceOrCourtWorkflow: boolean
) {
    const trainersEditProps = { eventInstance, eventType, isDigital, trainerType };

    const trainerTypeName = TrainerTypeName[trainerType];
    const label = getLabelForTrainerType(trainerTypeName, trainers);
    const trainerDetails = (<>{
        trainers.map(trainer => getTrainerDetails(eventInstance, trainer, trainerType, bookedRcCoverTrainerIds, isPoliceOrCourtWorkflow))
    }</>);
    const labelWidth = trainers.length === 0 ? 15 : 5;
    const valueWidth = trainers.length === 0 ? 1 : 11;

    return (<>
        <Authorize authorize={TtcPlannerRole}>
            <Grid.Row>
                <TrainersEdit {...trainersEditProps} />
            </Grid.Row>
        </Authorize>
        <Detail
            label={label}
            value={<>{trainerDetails}</>}
            labelWidth={labelWidth}
            valueWidth={valueWidth}
        />
    </>);
}
