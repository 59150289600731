import axios from "axios";
import moment from "moment";
import { Venue, VenueDetailModel } from "@common/crud/venue/model";
import { Apps } from "@common/model";

export class BookingVenueApi {

    private readonly apiUrl: string;

    constructor(app: Apps, bookingId?: string, isAttendee?: boolean) {

        if (app === Apps.Booking) {
            this.apiUrl = !isAttendee ? `/api/booking/${bookingId}/venue` : "/api/venue";
        } else {
            this.apiUrl = `/api/dorsBooking/${bookingId}/venue`;
        }
    }

    public async detail(id: string): Promise<VenueDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as VenueDetailModel;
        return this.parseModel(model);
    }

    public parseModel<T extends Venue>(model: T): T {
        return {
            ...model,
            expiryDate: model.expiryDate
                ? moment(model.expiryDate)
                : undefined
        };
    }
}
