import * as React from "react";
import "./LetterFooter.scss";

export const LetterFooter = () => (
    <div className="letter-footer">
        <p className="brand-text">
            The TTC Group consists of TTC Group (UK) Limited (registered number 06214074) and its subsidiaries:
            TTC 2000 Limited (registered number 08446911), Cycle Experience Ltd (registered number 03036579),
            TTC Commercial Services Limited (registered number 04819897).
            Balanceability is a trading name of Cycle Experience Ltd. All companies are registered in England and Wales.
            <br />
            TTC Group registered office: Hadley Park East, Telford, TF1 6QJ.
        </p>
    </div>
);
