import { Location } from "redux-little-router";
import { AuthState } from "@common/auth";
import moment from "moment";
import {
    AlaskaNudgeTaskTypeState,
    NudgeTaskBaseDetailModel,
    NudgeTaskBaseListModel,
    NudgeTaskPropertyBag,
    PendingNoteCreatorsState,
    TrainersWhoHaveBeenAssignedState,
    TaskStatusEnum,
    CaEmailsState,
    CorporateAdminEmailsState
} from "../common/NudgeTaskBaseModels";
import { DayPeriodEnum } from "@common/model";
import { TrainerAvailabilityRoleTypeEnum } from "../eventInstance/model";

export interface AlaskaNudgeTaskListResponse {
    results: AlaskaNudgeTaskListModel[];
    resultCount: number;
    emailOfCaWhoCreatedNoteOptions: string[];
    trainersWhoHaveBeenAssignedOptions: string[];
}

export interface AlaskaNudgeTaskListModel extends NudgeTaskBaseListModel {
    readonly name?: string;
    readonly correlationId?: string;
    readonly eventType?: string;
    readonly eventTypeId?: string;
    readonly attendeeName?: string;
    readonly taskRelatedTo?: TaskRelationEnum;
    readonly trainerAllocatedAs: TrainerAllocatedAsEnum;
    readonly nonCompliantAttributes: { [name: string]: [value: string] };
    readonly eventInstanceId?: string;
    readonly eventInstanceName?: string;
    readonly orderId?: string;
    readonly orderBookingReference?: string;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly zoomAccountId?: string;
    readonly zoomAccountName?: string;
    readonly attendeeId?: string;
    readonly attendeeTelephone?: string;
    readonly attendeeEmail?: string;
    readonly taskType?: AlaskaNudgeTaskEnum;
    readonly emailOfCaWhoCreatedNote?: string;
    readonly properties?: NudgeTaskPropertyBag;
    readonly eventInstanceDeliveryDate?: moment.Moment;
    readonly category?: AlaskaNudgeTaskCategoryEnum;
    readonly attendeeCompletionDate?: moment.Moment;
    readonly trainerAvailabilityRole?: TrainerAvailabilityRoleTypeEnum;
    readonly orderInvoiceReference?: string;
    readonly pendingEventInstanceDeliveryDate?: moment.Moment;

}

export interface AlaskaNudgeTaskDetailModel extends NudgeTaskBaseDetailModel {
    readonly name?: string;
    readonly attendeeId?: string;
    readonly attendeeName?: string;
    readonly attendeeTelephone?: string;
    readonly attendeeEmail?: string;
    readonly attendeeEventInstanceId?: string;
    readonly eventInstanceId?: string;
    readonly orderId?: string;
    readonly orderBookingReference?: string;
    readonly corporateOrganisationId?: string;
    readonly corporateOrganisationName?: string;
    readonly zoomAcountId?: string;
    readonly zoomAccountName?: string;
    readonly eventType?: string;
    readonly taskRelatedTo?: TaskRelationEnum;
    readonly trainerAllocatedAs: TrainerAllocatedAsEnum;
    readonly nonCompliantAttributes: { [name: string]: [value: string] };
    readonly eventInstanceName?: string;
    readonly eventInstanceDateAndTime?: string;
    readonly eventInstanceVenueName?: string;
    readonly eventInstanceVenueId?: string;
    readonly numberOfAttendeesOnEi?: number;
    readonly trainerId?: string;
    readonly trainerName?: string;
    readonly taskType?: AlaskaNudgeTaskEnum;
    readonly correlationId?: string;
    readonly category?: AlaskaNudgeTaskCategoryEnum;
    readonly attendeeCompletionDate?: moment.Moment;
    readonly trainerAvailabilityRole?: TrainerAvailabilityRoleTypeEnum;
}

export type AlaskaNudgeTask = AlaskaNudgeTaskListModel & AlaskaNudgeTaskDetailModel;

export interface AlaskaNudgeTasksState {
    alaskaTasks: AlaskaNudgeTask[];
    alaskaTasksCount: number;
}

export interface AlaskaNudgeTaskSearchOptions {
    attendeeName?: string;
    organisationName?: string;
    bookerName?: string;
    orderReferenceNumber?: string;
    status?: TaskStatusEnum;
    taskType?: string;
    eventTypeId?: string;
    organisationId?: string;
    forceId?: number;
    emailOfCaWhoCreatedNote?: string;
    trainerName?: string;
    specialRequirement?: number;
    page?: string;
    sortBy?: string;
    sortDirection?: string;
    deliveryType?: string;
    carType?: string;
    dayPeriod?: DayPeriodEnum[];
    corporate?: boolean;
    construction?: boolean;
    workflowType?: string;
    bookingType?: string;
}

export interface EventInstanceAffected {
    Id: string;
    Name: string;
    EventTypeId: string;
    EventTypeName: string;
    VenueId: string;
    VenueName: string;
    PendingRefund: number;
}

export enum TaskRelationEnum {
    None = 0,
    Attendee = 1,
    EventInstance = 2,
    Trainer = 3,
    DorsBooking = 4,
    Monitoring = 5,
    DdrsBooking = 6,
    Organisation = 7,
    DelegateAttendee = 8,
    ZoomAccount = 9,
    DelegateRecord = 10,
    Order = 11,
    PendingEventInstance = 12
}

export enum TrainerAllocatedAsEnum {
    None = 0,
    TheoryTrainer = 1,
    PracticalTrainer = 2,
    Monitor = 3,
    Observer = 4,
    Mentor = 5,
    Mentee = 6,
    FirstCourseSupport = 7
}

export const TrainerAllocatedAs = {
    0: "None",
    1: "Theory Trainer",
    2: "Practical Trainer",
    3: "Monitor",
    4: "Observer",
    5: "Mentor",
    6: "Mentee",
    7: "First Course Support"
};

export enum AlaskaNudgeTaskEnum {
    Any = 0,
    RefundTasks = 24,
    SpecialRequirementsTasks = 25,
    AttendeeOfferHasBeenWithdrawn = 26,
    IssueRaisedAgainstAttendee = 27,
    AttendeeEventInstanceIsAfterBookingExpiryDate = 28,
    DorsRejectedBookingStatusChange = 29,
    EiHiddenActionRequired = 30,
    EventInstanceRejectedByVenue = 31,
    CarTypeReview = 38,
    NoSeatsForCarType = 39,
    AttendeeNotRegisteredForDigitalCourse = 40,
    AttendeePaymentRequired = 41,
    IncompleteCourseRegister = 42,
    CourseNotConfirmedByTrainer = 43,
    TrainerNote = 44,
    AttendeeWithoutEmail = 45,
    AttendeesWithOutStandingPendingNote = 46,
    CoursesMadeAvailableByAdmin = 47,
    CoursesMadeAvailableByTrainers = 48,
    AttendeesNotCompletedDueToTechnicalIssues = 49,
    AttendeeRequiresTechnicalAssistance = 50,
    AttendeeRequiresAssistanceFromASupervisor = 51,
    AttendeeWithShortCompletions = 52,
    FailureCancellingCourseOnDors = 53,
    AttendeesMarkedAsNotCompleted = 54,
    CoursesThatNeedATrainer = 55,
    CoursesWithInsufficientAttendeeBookings = 56,
    MonitoringLevel3SubmittedForUrgentReview = 57,
    MonitoringLevel2SubmittedForReview = 58,
    DorsRejectedTrainer = 60,
    DorsRejectedCourseCreation = 61,
    TrainerComplaints = 62,
    CancelCoursesRejectedByZoom = 63,
    CompletedAttendeesNeedUpdatingOnDors = 64,
    AttendeesMarkedAsNotArrived = 65,
    IncompleteDdrsAttendeeCreation = 66,
    CoursesWithNonCompliantTrainers = 67,
    PoliceUserRequiresTTCAssistance = 68,
    AdminAlertExpiredTrainers = 69,
    HealthAndSafetyIssues = 70,
    HealthAndSafetyFormNotSubmitted = 71,
    AttendeesWhoNeedACancellationLetter = 72,
    OrganisationsWithOutstandingCourseInvoices = 73,
    OrganisationsWithOutstandingPendingNotes = 74,
    DelegateWithoutEmailNeedsCpcCert = 75,
    CourseCertificatesOutstanding= 76,
    DigitalCourseCreatedWithoutZoomAccount = 77,
    ZoomAccountPasswordDueToExpire = 78,
    TrainerCourseFeeNeedsReview = 79,
    BookerIsDueRefund = 80,
    OrdersWithOutstandingInvoice = 81,
    OrganisationsWithPendingCourse = 82,
    EnquiryCoursesRequiringReview = 83
}

export enum AlaskaNudgeTaskCategoryEnum {
    Unknown = 0,
    All = 5,
    PlanningCourses = 10,
    PlanningAttendees = 20,
    PlanningTrainers = 30,
    OnlineSupport = 40,
    General = 50,
    Organisations = 60
}

export interface AlaskaNudgeStatsCategoryOverviewModel {
    taskType: number;
    urgent: number;
    overdue: number;
    open: number;
}

export const PlanningCoursesTasks = [
    AlaskaNudgeTaskEnum.CoursesThatNeedATrainer,
    AlaskaNudgeTaskEnum.CoursesWithInsufficientAttendeeBookings,
    AlaskaNudgeTaskEnum.EiHiddenActionRequired,
    AlaskaNudgeTaskEnum.EventInstanceRejectedByVenue,
    AlaskaNudgeTaskEnum.CarTypeReview,
    AlaskaNudgeTaskEnum.NoSeatsForCarType,
    AlaskaNudgeTaskEnum.CoursesMadeAvailableByAdmin,
    AlaskaNudgeTaskEnum.CoursesMadeAvailableByTrainers,
    AlaskaNudgeTaskEnum.DorsRejectedTrainer,
    AlaskaNudgeTaskEnum.DorsRejectedCourseCreation
];

export const PlanningAttendeeTasks = [
    AlaskaNudgeTaskEnum.SpecialRequirementsTasks,
    AlaskaNudgeTaskEnum.AttendeeOfferHasBeenWithdrawn,
    AlaskaNudgeTaskEnum.IssueRaisedAgainstAttendee,
    AlaskaNudgeTaskEnum.AttendeeRequiresAssistanceFromASupervisor,
    AlaskaNudgeTaskEnum.AttendeeWithShortCompletions,
    AlaskaNudgeTaskEnum.AttendeeEventInstanceIsAfterBookingExpiryDate,
    AlaskaNudgeTaskEnum.DorsRejectedBookingStatusChange,
    AlaskaNudgeTaskEnum.AttendeePaymentRequired,
    AlaskaNudgeTaskEnum.AttendeesWithOutStandingPendingNote,
    AlaskaNudgeTaskEnum.AttendeeWithoutEmail,
    AlaskaNudgeTaskEnum.IncompleteDdrsAttendeeCreation,
    AlaskaNudgeTaskEnum.AttendeesWhoNeedACancellationLetter,
    AlaskaNudgeTaskEnum.CompletedAttendeesNeedUpdatingOnDors,
];

export enum CustomerStatusEnum {
    None = 0,
    Customer = 1,
    Prospect = 2,
    IndividualDriver = 3,
}

export const CustomerStatus = {
    [CustomerStatusEnum.None]: "",
    [CustomerStatusEnum.Customer]: "Customer",
    [CustomerStatusEnum.Prospect]: "Prospect",
    [CustomerStatusEnum.IndividualDriver]: "Individual Driver",
};

export const NonDriverCustomerStatus = {
    [CustomerStatusEnum.None]: "",
    [CustomerStatusEnum.Customer]: "Customer",
    [CustomerStatusEnum.Prospect]: "Prospect",
    [CustomerStatusEnum.IndividualDriver]: "Individual",
};

export type AppState = AlaskaNudgeTasksState & AlaskaNudgeTaskTypeState & AuthState &
    PendingNoteCreatorsState & TrainersWhoHaveBeenAssignedState & CaEmailsState & CorporateAdminEmailsState & { router: Location };
