import * as React from "react";
import { Grid } from "semantic-ui-react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "@booking/applicationState";

interface StripeContainerProps {
    t: TFunction;
    showError: boolean;
    errorMessage: string;
}

export const StripeContainer: React.FC<StripeContainerProps> = ({ t, showError, errorMessage }) => {
    const stripePaymentResponse = useSelector((state: ApplicationState) => state.stripePaymentResponse);

    const getErrorDiv = (errorCode: string, paymentError?: string) => {
        return (
            <div className="payment-error">
                {paymentError
                    ? <p>{paymentError}</p>
                    : <p>{t(errorCode)}</p>
                }
            </div>);
    };

    const getErrorSection = () => {
        if (!showError) {
            return (<></>);
        }

        if (stripePaymentResponse.validRequest === false &&
            !stripePaymentResponse.requiresAction &&
            stripePaymentResponse.duplicateRequest === false) {
            if (stripePaymentResponse.noAvailability === false &&
                stripePaymentResponse.reservationHasExpired === false &&
                stripePaymentResponse.paymentAlreadyReceived === false &&
                !stripePaymentResponse.serverError) {
                return getErrorDiv("PAYMENT_FAILED", stripePaymentResponse.paymentError);
            }
            if (stripePaymentResponse.noAvailability === true) {
                return getErrorDiv("NO_AVAILABILITY");
            }
            if (stripePaymentResponse.reservationHasExpired === true) {
                return getErrorDiv("RESERVATION_HAS_EXPIRED");
            }
            if (stripePaymentResponse.paymentAlreadyReceived === true) {
                return getErrorDiv("PAYMENT_ALREADY_RECEIVED");
            }
            if (stripePaymentResponse.serverError === true) {
                return getErrorDiv("SERVER_ERROR");
            }
        }
        if (errorMessage) {
            return (<div className="payment-error">
                <p>{errorMessage}</p>
            </div>);
        }

        return (<></>);
    };

    return (
        <>
            <Grid.Row>
                <Grid.Column width={16}>
                    <p>{t("CARD_NUMBER")}</p>
                </Grid.Column>
                <Grid.Column width={16}>
                    <CardNumberElement className="payment-input" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <p>{t("EXPIRY_DATE")}</p>
                </Grid.Column>
                <Grid.Column width={16}>
                    <CardExpiryElement className="payment-input" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <p>{t("SECURITY_CODE")}</p>
                </Grid.Column>
                <Grid.Column width={16}>
                    <CardCvcElement className="payment-input" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    {getErrorSection()}
                </Grid.Column>
            </Grid.Row>
        </>
    );
};
