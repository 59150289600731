import { TtcClientAdvisorRole } from "@common/auth/model";
import { bookingRoutes } from "../landing/routes";
import { loadBookingForNewSession } from "../landing";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";

export const BookingsRoutes = {
    Bookings: "/bookings",
    BookingSearch: "/:bookingId",
    Rebook: "/rebook",
    GenesysRedirect: `/${PaymentPageNames[PaymentPageNamesEnum.GenesysRedirect]}`,
};

const routes: any = {
    [BookingsRoutes.Bookings]: {
        title: "Bookings",
        authorize: TtcClientAdvisorRole,
        [BookingsRoutes.BookingSearch]: {
            title: "Booking",
            resolve: [() => loadBookingForNewSession(false)],

            // We only want loadBookingForNewSession called here and not on the other pages in the journey. As they are
            // child routes we use ignoreParentResolve to suppress.
            ignoreParentResolve: true,
            [BookingsRoutes.Rebook]: {
                title: "Rebook Booking",
            },
            ...bookingRoutes
        }
    },
    [BookingsRoutes.GenesysRedirect]: {
        title: "Genesys Redirect",
    },
};

export { routes };
