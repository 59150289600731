import { AvailableDate } from "@common/availabilityTrainer/model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventType } from "@common/crud/eventType";
import { GetEventDuration, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { CountryEnum } from "@common/crud/organisation/model";
import { Trainer } from "@common/crud/trainer";
import { trainersSelector } from "@common/crud/trainer/selectors";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { toast } from "@common/toasts";
import moment from "moment";
import * as React from "react";
import { DayContentProps, DayPicker } from "react-day-picker";
import { useSelector } from "react-redux";
import { Container, Icon } from "semantic-ui-react";
import { v4 } from "uuid";
import { EventInstance } from "../..";
import { StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";
import { DigitalPlanningFilter } from "./DigitalPlanningBase";
import { CheckOverlappingEvents, hasAssignedTrainer } from "./DigitalPlanningHelpers";
import { DrawAvailability } from "./DrawAvailability";
import { useWindowDimensions } from "@common/global/UseWindowDimensions";

interface DigitalPlanningCalendarProps {
    month: moment.Moment;
    trainerAvailability: AvailableDate[];
    trainerExpiryDate?: moment.Moment;
    selectedTrainerId: string;
    selectedEventType: EventType;
    selectedCountry?: CountryEnum;
    existingEvents: EventInstance[];
    digitallyPlannedForOtherCategory: EventInstance[];
    digitallyPlannedEvents: EventInstance[];
    setDigitallyPlannedEvents: (events: EventInstance[]) => void;
    selectedEventsPerDay: number;
    disabled: boolean;
    eventTypeCategory: EventTypeCategory;
    onFilterChange: (filters: DigitalPlanningFilter) => void;
    currentTrainer: Trainer;
    getStartTimeForSession: (session: number) => moment.Duration;
    existingEventInstancesAllocatedToTrainer: EventInstance[];
    standbyCalendar: StandbyTrainersCalendarDay[];
}

export const DigitalPlanningCalendar: React.FC<DigitalPlanningCalendarProps> =
({ month, trainerAvailability, trainerExpiryDate, selectedTrainerId, digitallyPlannedEvents, existingEvents, selectedEventType, setDigitallyPlannedEvents,
    selectedEventsPerDay, disabled, eventTypeCategory, onFilterChange, selectedCountry, currentTrainer, digitallyPlannedForOtherCategory,
    getStartTimeForSession, existingEventInstancesAllocatedToTrainer, standbyCalendar }) => {

    const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;
    const trainers = useSelector(trainersSelector);
    const { isDayBankHoliday } = useBankHolidays();
    const { isTabletScreenSize, isMobileLandscapeHeight } = useWindowDimensions();

    const deleteDigitallyPlannedEvent = React.useCallback((eventId: string) => {
        const eventToDelete = digitallyPlannedEvents.find(e => e.id === eventId);

        if (eventToDelete?.protected === true) {
            toast.warning(`Cannot delete this event instance as it is part of a course that has started in a previous month.
             To delete this and the rest of the series please go to the previous month.`);
            return;
        }

        setDigitallyPlannedEvents(digitallyPlannedEvents.filter(e => e.id !== eventId && eventToDelete.dayParts?.includes(e.id) === false));
    }, [digitallyPlannedEvents, setDigitallyPlannedEvents]);

    const addDigitallyPlannedEvent = React.useCallback((session: number, date: moment.Moment, oldEventId: string) => {
        if (!ddrs && (selectedEventsPerDay === undefined || selectedEventsPerDay === null)) {
            toast.warning("You need to select the number of sessions per day");
            return;
        }

        if (ddrs && !selectedCountry) {
            toast.warning("You need to select a country");
            return;
        }

        const start = getStartTimeForSession(session);

        if (start === undefined || start === null) {
            toast.warning("No suggested digital start time found for session");
            return;
        }

        if (currentTrainer === undefined || currentTrainer === null) {
            toast.error("Error getting trainer");
            return;
        }

        const newEvents: EventInstance[] = ddrs ? getAllEventsForDdrs(date) :  [{
            id: v4(),
            eventTypeId: selectedEventType.id,
            startDate: date,
            startTime: start,
            eventTypeAbbreviation: selectedEventType.abbreviation,
            trainerId: selectedTrainerId,
            eventDuration: GetEventDuration(date, DeliveryTypeEnum.Digital, selectedEventType),
            workflowType: ddrs ? WorkflowTypeEnum.DDRS : WorkflowTypeEnum.Dors,
            dayParts: []
        }];

        const errors = CheckOverlappingEvents(newEvents, existingEvents, [...digitallyPlannedEvents, ...digitallyPlannedForOtherCategory]);
        if (errors) {
            toast.warning("Could not create event as it would overlap existing events");
            return;
        }

        setDigitallyPlannedEvents([...digitallyPlannedEvents.filter(e => e.id !== oldEventId), ...newEvents]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEventsPerDay, selectedEventType?.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions, selectedEventType?.id,
        selectedEventType?.abbreviation, trainers, setDigitallyPlannedEvents, digitallyPlannedEvents, selectedTrainerId, getStartTimeForSession,
        ddrs, existingEvents, selectedCountry, digitallyPlannedForOtherCategory]);

    const getAllEventsForDdrs = (date: moment.Moment): EventInstance[] => {
        const dayPartsDictionary = selectedEventType?.digitalEventTypeDetails?.digitalEventTypeParts;
        const dayParts = Object.keys(dayPartsDictionary);
        const ids = dayParts.map(() => v4());
        return dayParts.map((s, index) =>
        {
            const event = ({
                id: ids[index],
                eventTypeId: selectedEventType.id,
                startDate: date.clone().add((index * 7), "days"),
                startTime: dayPartsDictionary[s].suggestedStartTime,
                eventTypeAbbreviation: selectedEventType.abbreviation,
                trainerId: selectedTrainerId,
                eventDuration: dayPartsDictionary[s].eventDuration,
                workflowType: WorkflowTypeEnum.DDRS,
                referredCourtCountry: selectedCountry,
                dayParts: ids
            });
            return event;
        }
        );
    };

    function onTrashIconClick(day: number) {
        return function() {
            const newDigitallyPlannedEvents = digitallyPlannedEvents.filter(e => e.startDate.date() !== day || !hasAssignedTrainer(e, selectedTrainerId));
            setDigitallyPlannedEvents(newDigitallyPlannedEvents);
        };
    }

    const renderDay = React.useCallback((props: DayContentProps) => {
        const day = props.date;
        const dayMoment = moment.utc([day.getFullYear(), day.getMonth(), day.getDate()]).startOf("day");
        const currentDay = dayMoment.date();
        const existingAllocationsOnDay = existingEventInstancesAllocatedToTrainer?.filter(e => e.startDate.toDate().getDate() === day.getDate());
        const availability = trainerAvailability?.find(x => x.date.isSame(dayMoment))?.sessionAvailabilities ?? [];
        const dayHasPlannedEvents = digitallyPlannedEvents.some(e => e.startDate.date() === currentDay);
        const isBankHoliday = isDayBankHoliday(dayMoment);
        return (
            <div className="cell-style">
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday &&
                        <span className={`bank-holiday-text ${dayHasPlannedEvents ? "reduced-line-height" : ""}`}>
                            {isTabletScreenSize || isMobileLandscapeHeight ? "BH" : "Bank holiday"}
                        </span>
                    }
                    {dayHasPlannedEvents && <Icon name="trash" className="cursor-pointer" onClick={onTrashIconClick(currentDay)} />}
                    {<span>{currentDay}</span>}
                </div>
                <DrawAvailability
                    existingEvents={existingEvents}
                    digitallyPlannedEvents={digitallyPlannedEvents}
                    digitallyPlannedForOtherCategory={digitallyPlannedForOtherCategory}
                    availability={availability}
                    trainerExpiryDate={trainerExpiryDate}
                    addDigitallyPlannedEvent={addDigitallyPlannedEvent}
                    deleteDigitallyPlannedEvent={deleteDigitallyPlannedEvent}
                    selectedTrainerId={selectedTrainerId}
                    day={dayMoment.toDate()}
                    selectedEventType={selectedEventType}
                    disabled={disabled}
                    eventTypeCategory={eventTypeCategory}
                    existingAllocationsOnDay={existingAllocationsOnDay}
                    standbyCalendar={standbyCalendar}
                />
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainerAvailability, existingEvents, digitallyPlannedEvents, addDigitallyPlannedEvent, trainerExpiryDate,
        deleteDigitallyPlannedEvent, selectedTrainerId, selectedEventType, existingEventInstancesAllocatedToTrainer, isTabletScreenSize,
        isMobileLandscapeHeight]);

    const onMonthChange = React.useCallback((newMonth: Date) => {
        onFilterChange({ month: moment(newMonth) });
    }, [onFilterChange]);

    return (
        <Container>
            <DayPicker
                className="narrow"
                selected={[]}
                components={{ DayContent: renderDay }}
                month={month.toDate()}
                disableNavigation={!ddrs}
                onMonthChange={onMonthChange}
                weekStartsOn={1}
            />
        </Container>
    );
};
