import * as React from "react";
import { connect } from "react-redux";
import { AsyncDispatch } from "@common/redux-helpers";
import { OrganisationState, selectors as organisationSelectors } from "@common/crud/organisation";
import { ConfirmButton } from "@common/components";
import { User, AppState } from "../../model";
import { sendSignupInvitation } from "../../actions";

interface OwnProps {
    user: User;
}

interface DispatchProps {
    sendInvitation: () => void;
}

const SignupInvitationButtonInternal: React.SFC<OwnProps & DispatchProps> = ({ user, sendInvitation }) => {
    const notSignedUp = user.adB2CId === null; // As opposed to undefined, which just means the user hasn't been loaded yet.
    if (!notSignedUp) {
        return null;
    }

    return (
        <ConfirmButton icon="mail" onConfirm={sendInvitation} header="Resend Signup Email">
            Resend Signup Email
        </ConfirmButton>
    );
};

const mapStateToProps = (state: AppState & OrganisationState) => {
    return {
        organisationId: organisationSelectors.routeIdSelector(state),
    };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => {
    return {
        sendInvitation: (id: string, organisationId: string) => dispatch(sendSignupInvitation(id, organisationId))
    };
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch, ownProps: OwnProps): OwnProps & DispatchProps => {
    const { organisationId } = propsFromState;
    const { sendInvitation } = propsFromDispatch;
    const { user } = ownProps;
    return {
        user,
        sendInvitation: () => sendInvitation(user.id, organisationId)
    };
};

export const SignupInvitationButton = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SignupInvitationButtonInternal);
