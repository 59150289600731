import * as React from "react";
import { Checkbox } from "semantic-ui-react";
import { VenueDistanceModel } from "@common/crud/venue";

interface VenueCheckboxProps<T> {
    label: string;
    collection: T[];
    setCollection?: React.Dispatch<React.SetStateAction<T[]>>;
    value: T;
}

function VenueCheckbox<T>({ label, collection, value, setCollection }: VenueCheckboxProps<T>) {
    const updateCollection = React.useCallback((_, data) => {
        if (data.checked) {
            setCollection(values => [...values, value]);
        } else {
            setCollection(values => {
                values.splice(values.indexOf(value), 1);
                return [...values];
            });
        }
    }, [value, setCollection]);

    return (
        <Checkbox
            disabled={!setCollection}
            label={label}
            checked={collection.includes(value)}
            onChange={updateCollection}
        />
    );
}

export function buildVenueCheckboxes(venueList: VenueDistanceModel[],
    selectedVenues: string[],
    setSelectedVenues?: React.Dispatch<React.SetStateAction<string[]>>) {
    return (
        <ul className="venues-checkboxes">
            {venueList?.map((venue, index) => (
                <li key={index}>
                    <VenueCheckbox
                        label={`${venue.name} (${venue.distanceInMiles} miles)`}
                        value={venue.id}
                        collection={selectedVenues}
                        setCollection={setSelectedVenues}
                    />
                </li>
            ))}
        </ul>
    );
}
