import * as React from "react";
import moment from "moment";
import { DayContentProps, DayPicker } from "react-day-picker";
import { Container, Table } from "semantic-ui-react";
import { NumberDictionary } from "@common/availabilityTrainer/model";
import { TrainerAvailabilityApi } from "@common/availabilityTrainer/trainerAvailabilityApi";
import { useSelector } from "react-redux";
import { trainersSelector } from "@common/crud/trainer/selectors";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { useWindowDimensions } from "@common/global/UseWindowDimensions";

interface AllAvailabilityCalendarProps {
    month: moment.Moment;
}

export const AllAvailabilityCalendar: React.FC<AllAvailabilityCalendarProps> = ({ month }) => {

    const [allAvailability, setAllAvailability] = React.useState<NumberDictionary<NumberDictionary<number>>>();

    const trainers = useSelector(trainersSelector);
    const trainerIds = React.useMemo(() => trainers.map(t => t.id), [trainers]);

    const { isDayBankHoliday } = useBankHolidays();
    const { isMobileLandscapeHeight, isTabletScreenSize } = useWindowDimensions();

    const monthString = month.toISOString();

    React.useEffect(() => {
        async function loadData() {
            const api = new TrainerAvailabilityApi();
            const result = await api.getAllDigitalAvailabilityForMonth(month, trainerIds);
            setAllAvailability(result);;
        }
        if (monthString && trainerIds?.length > 0) {
            loadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthString, trainerIds]);

    function availabilityRow(cssClass: string, availability: number) {
        const className = `separated-row DayPeriod ${cssClass}`;
        return (
            <Table.Row className="period-row">
                <td
                    className={`${className} no-padding-right`}>
                </td>
                <td
                    className={`${className} right-row`}>
                    {availability}
                </td>
            </Table.Row>
        );
    }

    const getCssClassName = (positionClass: string) => `${positionClass} period-color`;

    function renderDay(props: DayContentProps) {
        const day = props.date;
        const availability = allAvailability && allAvailability[day.getDate()];
        const dayMoment = moment.utc([day.getFullYear(), day.getMonth(), day.getDate()]).startOf("day");
        const isBankHoliday = isDayBankHoliday(dayMoment);

        return (
            <div className="cell-style">
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday && <span className="bank-holiday-text">{isTabletScreenSize || isMobileLandscapeHeight ? "BH" : "Bank holiday"}</span>}
                    <span>{day.getDate()}</span>
                </div>
                <div className="font-style">
                    <Table className="unstackable">
                        <Table.Body className="table-body">
                            {availabilityRow(getCssClassName("top"), availability ? availability[1] : 0)}
                            {availabilityRow(getCssClassName("middle-top"), availability ? availability[2] : 0)}
                            {availabilityRow(getCssClassName("middle-bottom"), availability ? availability[3] : 0)}
                            {availabilityRow(getCssClassName("bottom"),  availability ? availability[4] : 0)}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }

    return (
        <Container>
            <DayPicker
                className="narrow"
                selected={[]}
                components={{ DayContent: renderDay }}
                month={month.toDate()}
                disableNavigation={true}
                weekStartsOn={1}
            />
        </Container>
    );
};
