import * as React from "react";
import { Loader, Segment } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { useTranslation } from "react-i18next";
import { setCurrentLanguage } from "@common/language";
import { BookingDetailFormikModel } from "../../bookings/models";
import { bookingSelector, bookingBasePathSelector } from "../../landing/selectors";
import { updateMissingFieldsAndGoToPayment } from "../../landing/actions";
import { ClientAdvisorHeader } from "../../global/ClientAdvisorHeader";
import { View } from "../../global/ViewEnum";
import { RebookInformation } from "./RebookInformation";
import { BookingDetailsForm } from "./BookingDetailsForm";
import { LanguageEnum } from "@common/crud/eventInstance/model";
import { loadVenueDetail } from "../../venues/actions";
import { venuesSelector } from "@booking/venues/selectors";
import { useScrollToTop } from "@common/hooks/useScrollToTop";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { ChosenCourseBanner } from "./ChosenCourseBanner";
import { DorsPortalEditInstruction } from "@booking/common/DorsPortalEditInstruction";
import { StartPaymentProcessMultiPartModel } from "@common/payments/model";
import { seatsSelector } from "@booking/bookings/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { useState } from "react";
import { loadSeat } from "@booking/seat/actions";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { loadPoliceOrganisationDetailsForBooking } from "@booking/eventInstance/actions";

export const BookingDetails: React.FC = () => {
    const dispatch = useDispatch();

    const booking = useSelector(bookingSelector);
    const eventInstance = useSelector(eventInstanceSelector);

    const seats = useSelector(seatsSelector);
    const venue = useSelector(venuesSelector)?.find(i => i.id === eventInstance.venueId) ?? null;
    const organisation = useSelector(policeOrganisationByDorsId(booking.forceId));
    const isClientAdvisor = useSelector(currentUserIsInRoleSelector(TtcClientAdvisorRole));
    const path = useSelector(bookingBasePathSelector);
    const [group, setGroup] = useState<EventInstanceGroupModel>();
    const [mustLoad, setMustLoad] = React.useState<boolean>(true);
    const firstDaySeat = seats?.[0];
    const seatDiscount = firstDaySeat?.discountAmountApplied;

    useScrollToTop();

    React.useEffect(() => {

        async function loadGroup() {
            const groupApi = new EventInstanceGroupApi();
            const eventInstanceGroup = await groupApi.getByEventInstanceId(eventInstance.id);
            setGroup(eventInstanceGroup);
        }

        if (!isClientAdvisor && eventInstance.language === LanguageEnum.Welsh) {
            dispatch(setCurrentLanguage(("cy")));
        }
        if (eventInstance?.venueId && !venue) {
            dispatch(loadVenueDetail({ venueId: eventInstance.venueId }));
        }
        if (eventInstance.groupId && !group && eventInstance.workflowType===WorkflowTypeEnum.DDRS && mustLoad) {
            setMustLoad(false);
            loadGroup();
        }
        if (eventInstance && (!seats || !Array.isArray(seats))) {
            dispatch(loadSeat());
        }
    }, [dispatch, eventInstance, group, isClientAdvisor, venue, mustLoad, seats]);

    React.useEffect(() => {
        sessionStorage.setItem(`rebooking-${booking.id}`, booking.isRebooking?.toString());
        sessionStorage.setItem(`cancelled-${booking.id}`, booking.isCancelled?.toString());
    }, [booking]);

    React.useEffect(() => {
        if (!organisation && booking && booking.forceId) {
            dispatch(loadPoliceOrganisationDetailsForBooking({ forceId: booking.forceId }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking]);

    function onSubmit(values: BookingDetailFormikModel) {
        const bookingId = isClientAdvisor ? booking.id : null;
        const { nextPage, manualPaymentModel, ...missingProps } = values;
        const multiPart: StartPaymentProcessMultiPartModel[] =
            seats.map(seat => ({ eventInstanceId: seat.eventInstanceId, relatedObjectId: seat.id, dayPart: group ? group.eventInstanceGroupItems.find(
                groupEI => groupEI.eventInstanceId === seat.eventInstanceId).dayNumber : 0 }));

        dispatch(updateMissingFieldsAndGoToPayment(
            missingProps,
            manualPaymentModel,
            eventInstance,
            nextPage,
            isClientAdvisor,
            multiPart,
            bookingId,
            path));
    }

    const [t] = useTranslation("BookingDetails");

    if (!booking.id) {
        return <Loader active />;
    }

    return (
        <>
            <RebookInformation />
            {isClientAdvisor && (
                <>
                    <ClientAdvisorHeader showDetailedInfo={true} currentView={View.BookingDetails} noMarginOnLinkButton />
                    <ChosenCourseBanner booking={booking} eventInstance={eventInstance} />
                </>
            )}
            {!isClientAdvisor && (
                <Segment clearing className="welcome-title">
                    <h3>{t("YOUR_CONTACT_DETAILS")}</h3>
                    <p>
                        <span className="not-mobile">{t("WELCOME")}<br /></span>
                        <DorsPortalEditInstruction inline={true} workflowType={eventInstance?.workflowType} />
                    </p>
                </Segment>
            )}
            <BookingDetailsForm
                booking={booking}
                eventInstance={eventInstance}
                venue={venue}
                isClientAdvisor={isClientAdvisor}
                onSubmit={onSubmit}
                seatDiscount={seatDiscount}
                organisation={organisation}
            />
        </>
    );
};
