import { PoliceAndDdrsAdminRole } from "@common/auth/model";

export const PoliceUseDetailsRoutes = {
    Support: "/support"
};

export const detailRoutes = {
    [PoliceUseDetailsRoutes.Support]: {
        title: "Support Info",
        authorize: PoliceAndDdrsAdminRole
    }
};
