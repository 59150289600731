import * as React from "react";
import { connect } from "react-redux";
import { Button, Divider, Grid, Rating, Segment } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { Link } from "redux-little-router";
import { AppCommonState } from "../../../appCommonState";
import {
    OrganisationDetailModel,
    AppState,
    BusinessLineType,
    NoneOrganisationOption,
} from "../model";
import { organisationSelector, relatedOrganisationOptionsSelector, routeIdSelector } from "../selectors";
import { ContextMenu } from "./ContextMenu";
import {
    AdHocEmailCorporateOrganisationModal
} from "@common/crud/organisation/components/AdHocEmailCorporateOrganisationModal";
import { NonDriverCustomerStatus } from "@common/crud/alaskaNudgeTask/model";
import { FavouriteCategoryEnum } from "@common/favourites/model";
import { saveOrgFavouriteStatus } from "../actions";
import { AsyncDispatch } from "@common/redux-helpers";
import { FavouriteCreateEditModel } from "@common/favourites/model";
import { corpBookingAppEndpointSelector } from "../../common/selectors";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";

export interface DetailProps {
    path: string;
    organisationId: string;
    organisation: OrganisationDetailModel;
    relatedOrganisationName?: string;
    relatedOrganisationUrl?: string;
    copyRelatedOrganisationUrlToClipboard?: () => void;
}

export interface DispatchProps {
    saveFavourite: (favourite: FavouriteCreateEditModel) => void;
}

export class DetailInternal extends React.Component<DetailProps & DispatchProps> {
    public render() {
        const organisation = this.props.organisation;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h1>{organisation.name}&nbsp;
                            <Rating
                                icon="star"
                                rating={this.props.organisation?.favourite ? 1 : 0}
                                maxRating={1}
                                onClick={this.toggleFavourite} />
                        </h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <ContextMenu businessLineType={BusinessLineType.Construction} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Grid container stackable className="nomargintop">
                            <h2>Account Details</h2>
                            <Detail
                                label="Name"
                                value={organisation?.name || ""}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <Detail
                                label="Open Course Organisation"
                                value={organisation?.corporateOrganisationData?.openCourse ? "Yes" : "No"}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <Detail
                                label="BDM"
                                value={`${organisation?.corporateOrganisationData?.bdmId
                                    ? `${organisation.corporateOrganisationData.bdmName} [${organisation.corporateOrganisationData.bdmEmail}]`
                                    : ""}`}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <Detail
                                label="Customer Status"
                                value={NonDriverCustomerStatus[organisation.customerStatus]}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            {!organisation?.corporateOrganisationData?.openCourse && (
                                <>
                                    <h3>Address</h3>
                                    <Detail
                                        label="Address Line 1"
                                        value={organisation?.corporateOrganisationData?.address?.addressLine1 || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Address Line 2"
                                        value={organisation?.corporateOrganisationData?.address?.addressLine2 || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Address Line 3"
                                        value={organisation?.corporateOrganisationData?.address?.addressLine3 || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Town"
                                        value={organisation?.corporateOrganisationData?.address?.city || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Postcode"
                                        value={organisation?.corporateOrganisationData?.address?.postalCode || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Email Address"
                                        value={organisation?.corporateOrganisationData?.emailAddress || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Phone Number"
                                        value={organisation?.corporateOrganisationData?.phoneNumber || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Website"
                                        value={organisation?.corporateOrganisationData?.website || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    {organisation?.corporateOrganisationData?.invoiceAddress?.addressLine1 && (
                                        <>
                                            <h3>Invoice address</h3>
                                            <Detail
                                                label="Address Line 1"
                                                value={organisation?.corporateOrganisationData?.invoiceAddress?.addressLine1 || ""}
                                                labelWidth={5}
                                                valueWidth={11}
                                            />
                                            <Detail
                                                label="Address Line 2"
                                                value={organisation?.corporateOrganisationData?.invoiceAddress?.addressLine2 || ""}
                                                labelWidth={5}
                                                valueWidth={11}
                                            />
                                            <Detail
                                                label="Address Line 3"
                                                value={organisation?.corporateOrganisationData?.invoiceAddress?.addressLine3 || ""}
                                                labelWidth={5}
                                                valueWidth={11}
                                            />
                                            <Detail
                                                label="Town"
                                                value={organisation?.corporateOrganisationData?.invoiceAddress?.city || ""}
                                                labelWidth={5}
                                                valueWidth={11}
                                            />
                                            <Detail
                                                label="Postcode"
                                                value={organisation?.corporateOrganisationData?.invoiceAddress?.postalCode || ""}
                                                labelWidth={5}
                                                valueWidth={11}
                                            />
                                        </>
                                    )}
                                    {organisation?.corporateOrganisationData?.invoiceEmailAddress && (
                                        <Detail
                                            label="Accounts Payable Email Address"
                                            value={organisation.corporateOrganisationData.invoiceEmailAddress}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                    )}
                                    {organisation?.corporateOrganisationData?.invoicePhoneNumber && (
                                        <Detail
                                            label="Accounts Payable Phone Number"
                                            value={organisation.corporateOrganisationData.invoicePhoneNumber}
                                            labelWidth={5}
                                            valueWidth={11}
                                        />
                                    )}
                                    <Divider />
                                </>
                            )}
                            <Detail
                                label="Expiry Date"
                                value={organisation?.expiryDate?.isValid() && organisation?.expiryDate?.format("DD/MM/YYYY") || ""}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <Detail
                                label={"Organisation Note"}
                                value={<HtmlRenderer value={organisation?.organisationNote ?? ""} /> }
                                labelWidth={5}
                                valueWidth={11}
                            />
                            {this.props.relatedOrganisationName && (
                                <Detail
                                    label="Related Organisation Name"
                                    value={this.props.relatedOrganisationName || ""}
                                    labelWidth={5}
                                    valueWidth={11}
                                />
                            )}
                            {!organisation?.corporateOrganisationData?.openCourse && (
                                <>
                                    <h2>Partner Details</h2>
                                    <Detail
                                        label="Partner"
                                        value={organisation?.corporateOrganisationData?.partner || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Account Manager"
                                        value={organisation?.corporateOrganisationData?.accountManager || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private toggleFavourite = () => {
        const favourite: FavouriteCreateEditModel = {
            targetId: this.props.organisation?.id,
            category: FavouriteCategoryEnum.Organisation,
            name: `${this.props.organisation?.name}`,
            markAsFavourite: !this.props.organisation?.favourite
        };
        this.props.saveFavourite(favourite);
    };
}

const DetailWithActions: React.FC<DetailProps & DispatchProps> = ({
    organisationId,
    organisation,
    path,
    saveFavourite,
    relatedOrganisationName,
    relatedOrganisationUrl }) => {

    const organisationExists = React.useCallback(() => {
        return organisation?.id?.length > 0;
    },[organisation?.id]);

    const copyRelatedOrganisationUrlToClipboard = React.useCallback(() => {
        if (relatedOrganisationUrl) {
            navigator.clipboard.writeText(relatedOrganisationUrl);
        }
    },[relatedOrganisationUrl]);

    return (
        <div>
            <DetailInternal
                relatedOrganisationName={relatedOrganisationName}
                relatedOrganisationUrl={relatedOrganisationUrl}
                organisation={organisation}
                organisationId={organisationId}
                path={path}
                saveFavourite={saveFavourite}
                copyRelatedOrganisationUrlToClipboard={copyRelatedOrganisationUrlToClipboard} />
            <Divider />
            <Segment basic clearing vertical>
                {
                    organisationExists() && <AdHocEmailCorporateOrganisationModal organisation={organisation} />
                }
                <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${path}/edit`} />
            </Segment>
        </div>
    );
};

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchFavourite: (favourite: FavouriteCreateEditModel) => dispatch(saveOrgFavouriteStatus(favourite)),
    };
}

function mapStateToProps(state: AppState & AppCommonState): DetailProps {
    const organisation = organisationSelector(state);
    const relatedOrganisationName = relatedOrganisationOptionsSelector(state)
        .find(org => org.value === organisation?.relatedOrganisationId)?.text;
    const corporateBookingAppUrl = corpBookingAppEndpointSelector(state);

    let relatedOrganisationUrl = "";
    if (corporateBookingAppUrl && organisation?.id) {
        relatedOrganisationUrl = `${corporateBookingAppUrl}cpc/${organisation?.relatedOrganisationId &&
            organisation?.relatedOrganisationId !== NoneOrganisationOption ? organisation?.relatedOrganisationId : organisation.id}`;
    }
    return {
        organisation: organisationSelector(state),
        organisationId: routeIdSelector(state),
        relatedOrganisationName,
        relatedOrganisationUrl,
        path: state.router.pathname
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): DetailProps & DispatchProps {
    return {
        organisation: propsFromState.organisation,
        organisationId: propsFromState.organisationId,
        relatedOrganisationName: propsFromState.relatedOrganisationName,
        path: propsFromState.path,
        relatedOrganisationUrl: propsFromState.relatedOrganisationUrl,
        saveFavourite: (favourite: FavouriteCreateEditModel) =>  propsFromDispatch.dispatchFavourite(favourite),
    };
}

export const ConstructionDetail = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DetailWithActions);
