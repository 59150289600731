import { createSelector } from "reselect";

import { createBasePathSelector } from "@common/redux-helpers/selectors";

import { routeIdSelector as orgIdSelector } from "../organisation/selectors";

import { ConfigurationState } from "./model";

export const configurationSelector = (state: ConfigurationState) => state.configuration;

export const basePathSelector = createBasePathSelector("configuration");

export const hierarchySelector = createSelector(
    orgIdSelector,
    orgId => orgId ? ["SYSTEM", orgId] : ["SYSTEM"]
);
