import * as React from "react";
import { Grid, Tab } from "semantic-ui-react";
import { getCurrencyFormat } from "@common/formating";
import { BookingTypeEnum, EventType, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DisplayValueWithEffectiveDate } from "./DisplayValueWithEffectiveDate";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { doesPriceIncludeVatForWorkflow, isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate } from "@common/global/CommonHelpers";
import { CorporateFeeTables } from "../CorporateFeeTables";

export interface OwnProps {
    eventType: EventType;
}

export const DorsAndCpcCourseFeesDetailsTab: React.FC<OwnProps> = ({ eventType }) => {
    const courseFeesDetails = eventType.courseFeesEventTypeDetails ?? {};

    const isDors = eventType.workflowType === WorkflowTypeEnum.Dors;
    const isCorporate = isEventTypeWorkflowCorporate(eventType);
    const isConstruction = isEventTypeWorkflowConstruction(eventType);
    const priceIncludesVat = doesPriceIncludeVatForWorkflow(eventType.workflowType);

    const isDigital = eventType.deliveryType === SchemeDeliveryTypeEnum.Digital || eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital;
    const isClosed = eventType.bookingType === BookingTypeEnum.Closed || eventType.bookingType === BookingTypeEnum.Both;

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column>
                    <h3>Course Fees</h3>
                    {isDors && isDigital &&
                        <DisplayValueWithEffectiveDate
                            label={"Digital EI Fee"}
                            values={courseFeesDetails.digitalEIFeeWithEffectiveDate}
                            valueFormat={getCurrencyFormat}
                        />
                    }
                    {(isCorporate || isConstruction) &&
                        <CorporateFeeTables
                            eventTypeName={eventType.name}
                            bookingType={eventType.bookingType}
                            deliveryType={eventType.deliveryType}
                            courseFeesEventTypeDetails={courseFeesDetails}
                        />
                    }
                    {(isCorporate || isConstruction) && isClosed && courseFeesDetails.cpcUploadFeeRequired &&
                        <DisplayValueWithEffectiveDate
                            label="Upload Fee"
                            values={courseFeesDetails.cpcUploadFee}
                            valueFormat={getCurrencyFormat}
                        />
                    }

                    <div className="floated-right bold">{priceIncludesVat ? "Prices include VAT" : "Prices exclude VAT"}</div>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
