import * as React from "react";
import { AppSettingsApi, EmailTemplate, SmsTemplate, AdHocCommsTemplate } from "@common/appSettings/appSettingsApi";

export enum AdhocTemplateType {
    Email = 1,
    SMS = 2
}

export function useAdHocTemplates(templateType: AdhocTemplateType): AdHocCommsTemplate[] {
    const [smsTemplates, setSmsTemplates] = React.useState<SmsTemplate[] | null>(null);
    const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[] | null>(null);

    React.useEffect(() => {
        async function getTemplates() {
            const api = new AppSettingsApi();

            if (templateType === AdhocTemplateType.Email && emailTemplates === null) {
                setEmailTemplates(await api.getAdHocPostmarkTemplates());
            } else if (templateType === AdhocTemplateType.SMS && smsTemplates === null) {
                setSmsTemplates(await api.getAdHocSmsTemplates());
            }
        }

        getTemplates();
    }, [templateType, emailTemplates, smsTemplates]);

    return templateType === AdhocTemplateType.Email ? emailTemplates ?? [] : smsTemplates ?? [];
}
