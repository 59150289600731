import { Dispatch } from "redux";
import { EventInstanceAction as CommonEventInstanceAction, EventInstanceDetailModel } from "@common/crud/eventInstance";
import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { createEventInstanceSuccess } from "@common/crud/eventInstance/actions";
import { ApplicationState } from "../applicationState";
import { bookingIdSelector } from "../landing/selectors";
import { BookingEventInstanceApi } from "./bookingEventInstanceApi";
import * as actions from "./actionTypes";
import { FilterOptions } from "./model";
import { createOrganisationSuccess } from "@common/crud/organisation/actions";
import { BookingOrganisationApi } from "@booking/organisation/bookingOrganisationApi";

export type EventInstanceAction = CommonEventInstanceAction |
    ({ type: typeof actions.LOAD_EVENT_INSTANCES_SUCCESS } & Payload<EventInstanceDetailModel[]>) |
    ({ type: typeof actions.LOAD_EVENTINSTANCE_DETAILS_FOR_BOOKING_SUCCESS } & Payload<EventInstanceDetailModel>);

export function loadEventInstances(filterOptions: FilterOptions) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const state = getState();
        const bookingId = state.router.params.bookingId;

        const api = new BookingEventInstanceApi(bookingId);
        const result = await loadAndTrack(
            dispatch,
            actions.LOAD_EVENT_INSTANCES_SUCCESS,
            api.getVenueEventInstances(filterOptions)
        );
        dispatch(loadEventInstancesSuccess(result));
    };
}

export function loadEventInstanceDetailsForBooking({ eventInstanceId }: { eventInstanceId?: string }) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {

        const bookingId = bookingIdSelector(getState());
        const eventInstance = getState().eventInstances.filter(c => c.id === eventInstanceId)[0];
        const action = eventInstance === undefined ? createEventInstanceSuccess : loadEventInstanceDetailsForBookingSuccess;

        if (eventInstanceId) {
            const api = new BookingEventInstanceApi(bookingId);
            const result = await loadAndTrack(
                dispatch,
                actions.LOAD_EVENTINSTANCE_DETAILS_FOR_BOOKING_SUCCESS,
                api.getEventInstanceDetailsForBooking({ id: eventInstanceId })
            );
            dispatch(action(result));
        }
    };
}

export function loadPoliceOrganisationDetailsForBooking({ forceId }: { forceId?: number }) {
    return async (dispatch: Dispatch) => {
        if (forceId) {
            const api = new BookingOrganisationApi();
            const result = await loadAndTrack(
                dispatch,
                "LOAD_ORGANISATION_DETAIL",
                api.getOrganisationByForceId(forceId)
            );
            dispatch(createOrganisationSuccess(result));
        }
    };
}

export const loadEventInstancesSuccess = (payload: EventInstanceDetailModel[]): EventInstanceAction => ({
    payload,
    type: actions.LOAD_EVENT_INSTANCES_SUCCESS
});

export const loadEventInstanceDetailsForBookingSuccess = (payload: EventInstanceDetailModel): EventInstanceAction => ({
    payload,
    type: actions.LOAD_EVENTINSTANCE_DETAILS_FOR_BOOKING_SUCCESS
});
