import * as React from "react";
import { Popup } from "semantic-ui-react";
import moment from "moment";
import { ScheduledPaymentPlanViewModel } from "../../model";
import { YesNoQuestion, getYesNoValue } from "../YesNoQuestion";
import { defaultPaymentPlan, ScheduledPaymentPlan } from "./ScheduledPaymentPlan";

export interface ScheduledPaymentBaseProps {
    maxValue: number;
    initialPaymentValue: number;
    eventDate: moment.Moment;
    disabled: boolean;
    disabledMessage: string;
    showPlans: boolean;
    onShowPlansChange: (toggle: boolean) => void;
    sendPlan: (planObject: ScheduledPaymentResponse) => void;
}

export interface ScheduledPaymentResponse {
    plan: ScheduledPaymentPlanViewModel[];
    isPlanValid: boolean;
}

export const ScheduledPaymentBase: React.FC<ScheduledPaymentBaseProps> = (props) => {
    const [scheduledPaymentPlans, setScheduledPaymentPlans] = React.useState<ScheduledPaymentPlanViewModel[]>([]);

    React.useEffect(() => {
        if (props.showPlans) {
            setScheduledPaymentPlans([defaultPaymentPlan()]);
        } else {
            setScheduledPaymentPlans([]);
        }
    }, [props.showPlans]);

    return (
        <>
            <Popup
                content={props.disabledMessage}
                disabled={!props.disabled}
                trigger={
                    <span>
                        <YesNoQuestion
                            disabled={props.disabled}
                            label="Would you like to set up installments?"
                            value={props.showPlans}
                            onChange={getYesNoValue(props.onShowPlansChange)}
                        />
                    </span>
                }
            />

            {props.showPlans && (
                <ScheduledPaymentPlan
                    maxValue={props.maxValue}
                    sendPlan={props.sendPlan}
                    initialPaymentValue={props.initialPaymentValue}
                    eventDate={props.eventDate}
                    scheduledPaymentPlans={scheduledPaymentPlans}
                    setScheduledPaymentPlans={setScheduledPaymentPlans}
                />
            )}
        </>
    );
};
