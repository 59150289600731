import * as React from "react";
import { sanitize } from "dompurify";
import "./HtmlRenderer.scss";

interface HtmlRendererProps {
    value: string;
}

export function HtmlRenderer({ value }: HtmlRendererProps) {

    return (
        <div
            className="html-renderer"
            dangerouslySetInnerHTML={{
                __html: sanitize(value || "")
            }}
        />
    );
}
