import { ApplicationState } from "@booking/applicationState";
import { bookingSelector } from "@booking/landing/selectors";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { Spinner } from "@common/global";
import { phoneNumberRegex } from "@common/validation/phoneNumber";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { ValidationResultType } from "not-valid/bin/results";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { BookingApi } from "../bookingApi";
import { Booking } from "../models";

interface SaveBookingDetailsModalProps {
    open: boolean;
    onContinue: () => void;
    close: () => void;
}

const hasValue = (value: string) => value !== "" && value !== undefined && value !== null;

export const SaveBookingDetailsModalRequired = (booking: Booking) => {
    const sessionTelephone = sessionStorage?.getItem(`booking-telephone-${booking.id}`);
    const sessionEmail = sessionStorage?.getItem(`booking-email-${booking.id}`);
    const telephoneChanged = hasValue(sessionTelephone) && sessionTelephone !== booking.telephone;
    const emailChanged = hasValue(sessionEmail) && sessionEmail !== booking.email;
    return telephoneChanged || emailChanged;
};

export const SaveBookingDetailsModal: React.FC<SaveBookingDetailsModalProps> = ({ open, onContinue, close }) => {
    const booking = useSelector(bookingSelector);

    const telephoneKey = `booking-telephone-${booking.id}`;
    const emailKey = `booking-email-${booking.id}`;

    const sessionTelephone = sessionStorage?.getItem(telephoneKey);
    const sessionEmail = sessionStorage?.getItem(emailKey);

    const telephoneChanged = hasValue(sessionTelephone) && sessionTelephone !== booking.telephone;
    const emailChanged = hasValue(sessionEmail) && sessionEmail !== booking.email;

    const emailValidators = [validators.validEmail(), validators.validLength({ max: 50 })];
    const emailValid = emailValidators.every(fn => fn(sessionEmail).type === ValidationResultType.Pass) || sessionEmail?.length === 0;

    const telephoneValid = phoneNumberRegex.test(sessionTelephone) || sessionTelephone?.length === 0;

    const atLeastOneValidChange = (emailChanged && emailValid) || (telephoneChanged && telephoneValid);

    const [saving, setSaving] = React.useState(false);
    const [saveDetails, setSaveDetails] = React.useState(true);

    const isClientAdvisor = useSelector((state: ApplicationState) => isUserClientAdvisor(state));

    async function saveAndContinue() {
        setSaving(true);
        if (saveDetails && atLeastOneValidChange) {
            const telephone = telephoneValid ? sessionStorage?.getItem(telephoneKey) : booking.telephone;
            const email = emailValid ? sessionStorage?.getItem(emailKey) : booking.email;
            const api = new BookingApi(booking.id);
            await api.updateContactInfo(email, telephone);
        }
        sessionStorage?.removeItem(telephoneKey);
        sessionStorage?.removeItem(emailKey);
        onContinue();
        setSaving(false);
    }

    if (!isClientAdvisor) {
        return <></>;
    }

    return (
        <Modal open={open}>
            <>
                <Modal.Header>
                    Save changes?
                </Modal.Header>
                <Modal.Content>
                    { atLeastOneValidChange && <p>Do you wish to save the changes you made to this booking's contact details?</p> }
                    { emailChanged &&
                    <>
                        {emailValid && <p>Email changed from <strong>{booking.email ?? "none"}</strong> to <strong>{sessionEmail ?? "none"}</strong></p>}
                        {!emailValid && <p>Email change will not be saved as it is invalid</p> }
                    </>
                    }
                    {telephoneChanged &&
                        <>
                            { telephoneValid && <p>Telephone changed from <strong>{booking.telephone ?? "none"}</strong>
                             to <strong>{sessionTelephone ?? "none"}</strong></p> }
                            {!telephoneValid && <p>Telephone change will not be saved as it is invalid</p>}
                        </>
                    }
                    <Input.Checkbox
                        value={saveDetails && atLeastOneValidChange}
                        label={saveDetails ? "Yes" : "No"}
                        onChange={setSaveDetails}
                        disabled={saving || !atLeastOneValidChange}
                        toggle
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Spinner active={saving}>
                        <Button
                            onClick={close}
                            labelPosition="right"
                            content="Continue with booking"
                            className="cancel-action"
                            icon="close"
                        />
                        <Button
                            onClick={saveAndContinue}
                            positive
                            labelPosition="right"
                            icon="arrow right"
                            content="Leave booking app"
                        />
                    </Spinner>
                </Modal.Actions>
            </>
        </Modal>);
};
