import * as React from "react";
import { Checkbox, CheckboxProps, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { SortableHeaders, SortableHeaderProps } from "@common/components";
import { SortDirection } from "@common/model";
import { AppState, PoliceUserListModel } from "../model";
import { sortUsers, updateReceivesNotifications } from "../actions";
import { Link } from "redux-little-router";
import { AsyncDispatch } from "@common/redux-helpers/async";
import { OrganisationState, selectors as organisationSelectors } from "@common/crud/organisation";

interface OwnProps {
    users: PoliceUserListModel[];
}

interface StateProps {
    organisationId: string;
}

interface DispatchProps {
    sortUsers: (property: keyof PoliceUserListModel, direction: SortDirection) => void;
    updateReceivesNotifications: (id: string, organisationId: string, receivesNotifications: boolean) => void;
}

export type AllItemsProps = OwnProps & StateProps & DispatchProps;

const headers: SortableHeaderProps<keyof PoliceUserListModel>[] = [
    { propertyName: "fullName", title: "Name" },
    { propertyName: "email", title: "Email" },
    { propertyName: "receivesNotifications", title: "Receives Notifications" }
];

class AllItemsInternal extends React.Component<AllItemsProps> {
    private onReceivesNotificationsChange(userId: string) {
        return (_: any, { checked }: CheckboxProps) => {
            this.props.updateReceivesNotifications(userId, this.props.organisationId, checked);
        };
    }

    public render() {
        return (
            <Table sortable compact>
                <Table.Header>
                    <SortableHeaders
                        key={location.search} // This resets the component state (i.e. sort property/direction) on loading new data
                        headers={headers}
                        onSort={this.props.sortUsers}
                    />
                </Table.Header>
                <Table.Body>
                    {this.props.users.map(user => (
                        <Table.Row key={user.id}>
                            <Table.Cell>
                                <Link href={`police-users/${user.id}`}>
                                    {user.fullName}
                                </Link>
                            </Table.Cell>

                            <Table.Cell>
                                {user.email}
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    checked={user.receivesNotifications}
                                    onChange={this.onReceivesNotificationsChange(user.id)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}

const mapStateToProps = (state: AppState & OrganisationState) => {
    return {
        organisationId: organisationSelectors.routeIdSelector(state),
    };
};

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        sortUsers: (property, direction) => dispatch(sortUsers(property, direction)),
        updateReceivesNotifications: (id: string, organisationId: string, receivesNotifications: boolean) =>
            dispatch(updateReceivesNotifications(id, organisationId, receivesNotifications))
    };
}

export const AllItems = connect(mapStateToProps, mapDispatchToProps)(AllItemsInternal);
