import * as React from "react";
import { Button, Divider, Tab, Segment, TabProps } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Authorize } from "reauthorize";
import { TrainerRole, TtcFinanceAdministratorRole, TtcTrainerAdminRole } from "@common/auth/model";
import { connect, useSelector } from "react-redux";
import { appSelector, routerQuerySelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { CoreDetailProps, DetailProps, DispatchProps, TrainerRouterQuery } from "../Detail";
import { SignupInvitationButton } from "./SignUpInvitationButton";
import { DetailsTab } from "./DetailsTab";
import { AttributesTab } from "./AttributesTab";
import { CompanyInformation } from "./CompanyInformation";
import { AvailabilityTab } from "./AvailabilityTab";
import { TrainerHistory } from "./TrainerHistory";
import { ScheduleTab } from "./ScheduleTab";
import { AreasTab } from "./AreasTab";
import { currentUserSelector } from "@common/auth";
import { WorkingRadiusTab } from "./WorkingRadiusTab";
import { TrainerInvoicesTab } from "./TrainerInvoicesTab";
import { ManagementTab } from "./ManagementTab";
import { CpdTab } from "./CpdTab";
import { AppState, TabNames, TabPaths } from "../../model";
import * as QueryString from "query-string";
import { BusinessLineType } from "@common/crud/organisation/model";

export interface CoreDetailTabPropsState extends CoreDetailProps {
    onTabChange: (e: any, data: TabProps) => void;
    path: string;
}

const LobScheduleMap = new Map<BusinessLineType, string>([
    [BusinessLineType.Police, "schedule"],
    [BusinessLineType.Court, "schedule-drink-drive"],
    [BusinessLineType.Corporate, "schedule-corp"],
    [BusinessLineType.Construction, "schedule-construction"],
]);

export const CoreDetails: React.FC<CoreDetailTabPropsState> = ({ onTabChange, trainer, path }) => {
    const app = useSelector(appSelector);
    const user = useSelector(currentUserSelector);
    const activeTab = path.split("/").pop().split("?")[0];

    let adminPanes: any = [];
    if (app === Apps.Admin) {

        const filteredKeys = Array.from(LobScheduleMap.keys()).filter(k => trainer?.businessLineTypes?.includes(k));

        // we derive the schedule path from the url or if we're on a different tab, we derive the initial view from the trainer's business line types
        const schedulePath = Array.from(LobScheduleMap.values()).includes(activeTab) ?
            activeTab : LobScheduleMap.get(filteredKeys[0]);
        adminPanes = [
            { menuItem: TabNames.Availability, path: "availability", render: () => <AvailabilityTab /> },
            { menuItem: TabNames.WorkingRadius, path: "workingRadius", render: () => <WorkingRadiusTab trainer={trainer} /> },
            { menuItem: TabNames.Invoices, path: "invoices", render: () => <TrainerInvoicesTab /> },
            { menuItem: TabNames.Schedule, path: schedulePath, render: () => <ScheduleTab /> },
            { menuItem: TabNames.History, path: "history", render: () => <TrainerHistory /> }
        ];

        if (trainer.businessLineTypes?.indexOf(BusinessLineType.Police) > -1) {
            adminPanes = adminPanes.concat([
                { menuItem: TabNames.Areas, path: "areas", render: () => <AreasTab trainer={trainer} /> }
            ]);
        }

        adminPanes = adminPanes.concat([
            { menuItem: TabNames.Cpd, path: "cpd", render: () => <CpdTab /> }
        ]);
    }

    const panes = [
        { menuItem: TabNames.Details, path: "", render: () => <DetailsTab trainer={trainer} /> },
        (user.roles.includes(TtcFinanceAdministratorRole) || app === Apps.Trainer)
            ? { menuItem: TabNames.CompanyInfo, path: "companyInformation", render: () => <CompanyInformation trainer={trainer} /> }
            : null,
        app !== Apps.Trainer ? { menuItem: TabNames.Attributes, path: "attributes", render: () => <AttributesTab trainer={trainer} /> } : null,
        ...adminPanes,
        trainer.businessLineTypes?.indexOf(BusinessLineType.Police) > -1
            ? { menuItem: TabNames.Management, path: "management", render: () => <ManagementTab trainer={trainer} /> }
            : null
    ];

    const activeIndex = panes.findIndex(p => p?.path === activeTab);

    return (<>
        {app === Apps.Trainer? <Tab
            onTabChange={onTabChange}
            panes={panes}
        /> :<Tab
            onTabChange={onTabChange}
            panes={panes}
            activeIndex={activeIndex >= 0 ? activeIndex : 0}
        />}</>
    );
};

export interface CorDetailsWithActionsState {
    showEdit: boolean;
    showCompanyInfoEdit: boolean;
}

class CoreDetailsWithActionsInternal extends React.Component<DetailProps & DispatchProps, CoreDetailTabPropsState & CorDetailsWithActionsState> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.state = {
            ...this.state,
            showEdit:
                this.props.tab !== TabPaths.Availability &&
                this.props.tab !== TabPaths.Invoices &&
                this.props.tab !== TabPaths.WorkingRadius &&
                this.props.tab !== TabPaths.Schedule &&
                this.props.tab !== TabPaths.History &&
                this.props.tab !== TabPaths.Cpd,
            showCompanyInfoEdit:
                this.props.tab === TabPaths.CompanyInfo,
        };
    }

    public componentDidMount() {
        const basePath = this.props.path[this.props.path.length - 1] === "/" ? this.props.path.slice(0, -1) : this.props.path;
        const pathParts = basePath.split("/");
        const lastPathPart = `/${pathParts[pathParts.length - 1]}`;
        this.setState({
            showEdit:
                lastPathPart !== TabPaths.Availability &&
                lastPathPart !== TabPaths.Invoices &&
                lastPathPart !== TabPaths.WorkingRadius &&
                lastPathPart !== TabPaths.Schedule &&
                lastPathPart !== TabPaths.History &&
                lastPathPart !== TabPaths.Cpd,
            showCompanyInfoEdit:
                lastPathPart === TabPaths.CompanyInfo
        });
    }

    public render() {
        const { trainer, path, query } = this.props;
        const basePath = path[path.length - 1] === "/" ? path.slice(0, -1) : path;
        const fullQuery = query?.section? `?${QueryString.stringify(query)}`:"";
        const editPath = `${basePath}/edit${fullQuery}`;
        return (
            <div>
                {!!trainer && <CoreDetails trainer={trainer} onTabChange={this.handleTabChange} path={path} />}

                <Divider />
                <Segment basic clearing vertical>
                    <div className="button-container right-align">
                        {this.state.showEdit &&
                            <Authorize authorize={TtcTrainerAdminRole}>
                                <Button
                                    icon="pencil"
                                    content="Edit"
                                    as={Link}
                                    href={editPath}
                                />
                            </Authorize>
                        }
                        {this.state.showCompanyInfoEdit &&
                            <Authorize authorize={TrainerRole}>
                                <Button
                                    icon="pencil"
                                    content="Update Bank Account Information"
                                    as={Link}
                                    href={editPath}
                                />
                            </Authorize>
                        }
                        <SignupInvitationButton trainer={trainer} />
                    </div>
                </Segment>
            </div>
        );
    }

    private handleTabChange(_: any, tabData: TabProps) {
        this.setState({
            showEdit:
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.Availability &&
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.Invoices &&
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.WorkingRadius &&
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.Schedule &&
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.History &&
                tabData.panes[+tabData.activeIndex].menuItem !== TabNames.Cpd,
            showCompanyInfoEdit:
                tabData.panes[+tabData.activeIndex].menuItem === TabNames.CompanyInfo
        });

        if (!this.props.isTrainerApp) {
            this.props.push(`${this.props.basePath}/${this.props.trainer.id}/${(tabData.panes[+tabData.activeIndex] as any).path}`);
        }

    }
}

function mapStateToProps(state: AppState) {

    return {
        query: routerQuerySelector(state) as TrainerRouterQuery,
    };
}

export const CoreDetailsWithActions = connect(mapStateToProps)(CoreDetailsWithActionsInternal);

export const coreDetailsTab = (props: DetailProps & DispatchProps) => ({
    path: "",
    menuItem: "Details",
    authorise: true,
    render: () => (
        <Tab.Pane>
            <CoreDetailsWithActions {...props} />
        </Tab.Pane>
    )
});
