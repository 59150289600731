import { Location } from "redux-little-router";
import moment from "moment";

export interface TrainerAttributeDefinitionListModel {
    readonly id: string;
    readonly name?: string;
    schemes?: string[];
    readonly attributeType?: number;
    readonly listUpdated?: moment.Moment;
}

export interface TrainerAttributeDefinitionDetailModel {
    readonly id: string;
    readonly name?: string;
    schemes?: string[];
    readonly attributeType?: number;
    readonly detailUpdated?: moment.Moment;
    readonly lastMonitoredDate?: moment.Moment;
    readonly nextMonitoringDue?: moment.Moment;
    readonly nextMonitoringSessionPlanned?: moment.Moment;
    readonly isDorsRelated?: boolean;
    readonly isDdrs?: boolean;
    readonly isCompliance?: boolean;
    readonly isCpc?: boolean;
    readonly isDigital?: boolean;
    readonly isWorkshop?: boolean;
    readonly isOnRoad?: boolean;
    readonly isBusinessDriver?: boolean;
    readonly isConstruction?: boolean;
    readonly isCitb?: boolean;
    readonly isAps?: boolean;
}

export enum TrainerAttributeType {
    Alaska = 0,
    Continuum = 1
};

export type TrainerAttributeDefinition = TrainerAttributeDefinitionListModel & TrainerAttributeDefinitionDetailModel;

export interface TrainerAttributeDefinitionCreateModel {
    id: string;
    name: string;
    readonly attributeType?: number;
}

export interface TrainerAttributeDefinitionEditModel {
    id: string;
    name: string;
    schemes?: string[];
    readonly attributeType?: number;
}

export interface TrainerAttributeDefinitionState {
    readonly trainerAttributeDefinitions: TrainerAttributeDefinition[];
}

export class TrainerAttributeDefinitionBuilder {
    private id: string;
    private name: string;

    constructor() {
        this.name = "Test 1";
    }

    public withId(id: string): TrainerAttributeDefinitionBuilder {
        this.id = id;
        return this;
    }

    public withname(name: string): TrainerAttributeDefinitionBuilder {
        this.name = name;
        return this;
    }

    public build(): TrainerAttributeDefinitionEditModel {
        return {
            id: this.id,
            name: this.name
        };
    }

}

export type AppState = TrainerAttributeDefinitionState & { router: Location };

export interface SearchOptions {
    searchString?: string;
    eventTypes?: string[];
    attributeType?: number;
    includeCategories?: boolean;
}
