import { Modal } from "@common/components";
import { Button, Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import {
    PassFailResultEnum,
    PassFailResultForDisplay
} from "@common/crud/trainer/trainerMonitoringModel";
import { optionsFromObject } from "@common/crud/common";
import { Spinner } from "@common/global";
import * as React from "react";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import moment from "moment";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiFutureDateValidator } from "@common/validation/futureDateValidator";

export interface SubmitMonitoringModalProps {
    open: boolean;
    currentAttribute: TrainerAttribute;
    passFailResult: PassFailResultEnum;
    numFailedSections: number;
    isSubmitting: boolean;
    onNextMonitoringDateUpdate: (value: moment.Moment, valid: boolean) => void;
    onNextDigitalMonitoringDateUpdate: (value: moment.Moment, valid: boolean) => void;
    setPassFailResult: (result: PassFailResultEnum) => void;
    onSubmit: () => void;
    closeModal: () => void;
    setIsIqaReview: (value: boolean) => void;
    isDigitalEventInstanceDeliveryType: boolean;
}
export const SubmitMonitoringModal: React.FC<SubmitMonitoringModalProps> = (props) => {
    const {
        open,
        onNextMonitoringDateUpdate,
        onNextDigitalMonitoringDateUpdate,
        onSubmit,
        currentAttribute,
        passFailResult,
        numFailedSections,
        isSubmitting,
        closeModal,
        setIsIqaReview,
        setPassFailResult,
        isDigitalEventInstanceDeliveryType
    } = props;
    const isIqaReview = passFailResult > PassFailResultEnum.Level2;

    const onSetPassFailResult = React.useCallback((value: number) => {

        const updateFlags = (result: PassFailResultEnum, iqaReview: boolean) => {
            setPassFailResult(result);
            setIsIqaReview(iqaReview);
        };

        switch (value) {
            case PassFailResultEnum.Level1:
                updateFlags(PassFailResultEnum.Level1, false);
                break;
            case PassFailResultEnum.Level2:
                updateFlags(PassFailResultEnum.Level2, false);
                break;
            case PassFailResultEnum.Level3:
                updateFlags(PassFailResultEnum.Level3, numFailedSections >= 2);
            default:
                break;
        }
    }, [numFailedSections, setIsIqaReview, setPassFailResult]);

    const level3WarningText = () => {
        return (
            <>
                <div><b>The trainer has achieved a {PassFailResultForDisplay[passFailResult]}. Click continue to submit this result, alternatively
                    you may choose an alternative result from the list below</b></div>
                <br />
                <div><b>Note - this will send an alert to the IQA Team</b></div>
                <br />
            </>
        );
    };

    const level2InstructionText = () => {
        return (
            <>
                <div><b>The trainer has achieved a {PassFailResultForDisplay[passFailResult]}. Click continue to submit this result, alternatively
                    you may choose an alternative result from the list below</b></div>
                <br />
            </>
        );
    };

    const level1InstructionText = () => {
        return (
            <>
                <div><b>The trainer has achieved a {PassFailResultForDisplay[passFailResult]}. Click continue to submit this result, alternatively
                    you may choose an alternative result from the list below</b></div>
                <br />
            </>
        );
    };

    const filteredOptions = () => {
        const all = optionsFromObject(PassFailResultForDisplay);

        if (numFailedSections === 0) {
            return all.filter(o => +o.value < 3);
        }

        return numFailedSections === 1 ? all : all.filter(o => +o.value > 1);
    };

    return (
        <Modal open={open}>
            <Modal.Header>Submit Monitoring</Modal.Header>
            <Modal.Content>
                <Form onSubmit={onSubmit}>
                    {isIqaReview === false && isDigitalEventInstanceDeliveryType && <MuiDateField
                        label="Please confirm the recommended date for next monitoring"
                        value={currentAttribute?.nextDigitalMonitoringDue}
                        validation={[muiFutureDateValidator]}
                        onChange={onNextDigitalMonitoringDateUpdate}
                    />}
                    {isIqaReview === false && !isDigitalEventInstanceDeliveryType && <MuiDateField
                        label="Please confirm the recommended date for next monitoring"
                        value={currentAttribute?.nextMonitoringDue}
                        validation={[muiFutureDateValidator]}
                        onChange={onNextMonitoringDateUpdate}
                    />}
                    <br />
                    {passFailResult === PassFailResultEnum.Level3 && level3WarningText()}
                    {passFailResult === PassFailResultEnum.Level2 && level2InstructionText()}
                    {passFailResult === PassFailResultEnum.Level1 && level1InstructionText()}

                    <Input.DropdownNumber
                        value={passFailResult}
                        onChange={onSetPassFailResult}
                        options={filteredOptions()}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Spinner active={isSubmitting}>
                    <Button onClick={closeModal} className="cancel-action">Cancel</Button>
                    <Button onClick={onSubmit} positive>Continue</Button>
                </Spinner>
            </Modal.Actions>
        </Modal>
    );
};
