import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Register } from "@common/crud/eventInstance/components/register/Register";
import { attendeesSelector, SelectorState } from "../selectors";
import { useInterval } from "@common/hooks/useInterval";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { AttendeeApi } from "../attendeeApi";
import { AttendeeListModel, loadAttendeesSuccess } from "..";
import { toast } from "@common/toasts";
import moment from "moment";
import { currentAppSelector } from "@common/genesys/selectors";
import { Apps } from "@common/model";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DrinkDriveRegister } from "@common/crud/eventInstance/components/register/DrinkDriveRegister";
import { Area } from "@common/crud/eventInstance/model";
import { loadAttendeesFromSuccess } from "../actions";

export interface AllProps{
    area: Area;
}

export const All: React.FC<AllProps> = ({ area }) => {

    const path = useSelector((state: SelectorState) => state.router.pathname);
    const attendees = useSelector(attendeesSelector);
    const eventInstance = useSelector(eventInstanceSelector);
    const eventInstanceId = eventInstance.id;
    const [knownAttendees, setKnownAttendees] = React.useState([]);
    const isTrainerApp = useSelector(currentAppSelector) === Apps.Trainer;
    const isDdrs = eventInstance.workflowType === WorkflowTypeEnum.DDRS;

    const dispatch = useDispatch();

    const matchingAttendees = React.useMemo(() =>
        attendees?.filter(a => !a.isBookingCanceled && a.eventInstanceId === eventInstanceId) || [], [attendees, eventInstanceId]);

    React.useEffect(() => {
        if (knownAttendees.length === 0 && matchingAttendees.length > 0) {
            setKnownAttendees(matchingAttendees);
        }
    }, [knownAttendees?.length, matchingAttendees]);

    function getNewAttendees(latest: AttendeeListModel[]) {
        const newAttendees: AttendeeListModel[] = [];
        latest.filter(a => a.eventInstanceId === eventInstanceId).forEach(attendee => {
            const matchingOldAttendee = knownAttendees.find(a => a.id === attendee.id);
            if (matchingOldAttendee === undefined && attendee.isBookingCanceled === false) {
                newAttendees.push(attendee);
            }
        });

        setKnownAttendees([...newAttendees, ...knownAttendees.filter(a => a.eventInstanceId === eventInstanceId)]);
        return newAttendees;
    }

    function triggerToastsForNewAttendees(newAttendees: AttendeeListModel[]) {
        // triggerTrainerNotificationSound();
        newAttendees.forEach(attendee => {
            toast.importantAlertPersistent(`Please be aware ${attendee.fullName} has just been added to your course register.`);
        });
    }

    // In future we could also limit the polling if the registration end time has passed.
    const eventHasFinished = eventInstance.deliveryDateTimeEnd < moment();
    const checkForNewAttendees = async () => {
        if (isTrainerApp && !eventHasFinished) {
            const api = new AttendeeApi(eventInstanceId);
            const result = await api.getAttendeesForEventInstance();
            const newAttendees = getNewAttendees(result.attendees);
            if (newAttendees.length > 0) {
                triggerToastsForNewAttendees(newAttendees);
                dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
                dispatch(loadAttendeesSuccess([...knownAttendees, ...newAttendees]));
            }
        }
    };

    useInterval(async () => {
        checkForNewAttendees();
    }, 30000);

    return (
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <Grid.Column width={16}>
                    {isDdrs ?
                        <DrinkDriveRegister attendees={attendees} path={path} area={area} /> :
                        <Register attendees={attendees} path={path} area={area} />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
