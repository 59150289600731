import * as React from "react";
import moment from "moment";
import { FeeWithEffectiveDate } from "../../model";
import { CurrencyInput } from "../../../../global/CurrencyInput";
import { MuiDateField } from "@common/components/MuiDateField";

export interface Props {
    feeWithEffectiveDate: FeeWithEffectiveDate;
    showErrors?: boolean;
    onEffectiveDateChange(fee: FeeWithEffectiveDate): (value: moment.Moment, valid: boolean) => void;
    onFeeChange(fee: FeeWithEffectiveDate): (value: number, valid: boolean) => void;
}

export const FeeWithEffectiveDateEdit: React.FC<Props> = ({
    feeWithEffectiveDate,
    showErrors,
    onEffectiveDateChange,
    onFeeChange }) => {

    return (
        <>
            <MuiDateField
                value={feeWithEffectiveDate.effectiveDate}
                label="Effective Date"
                showErrors={showErrors}
                onChange={onEffectiveDateChange(feeWithEffectiveDate)}
            />
            <CurrencyInput
                value={feeWithEffectiveDate.fee}
                label="Fee"
                showErrors={showErrors}
                required
                onChange={onFeeChange(feeWithEffectiveDate)}
            />

        </>
    );
};
