import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { Button, Container } from "semantic-ui-react";
import * as React from "react";
import { Modal } from "@common/components";
import { OptTrainerTable } from "@common/crud/trainer/components/details/OptTrainerTable";
import { EventInstanceCountForAttributeModel, OptSchemeTrainerAttributeModel, OptSchemeTrainerModel } from "@common/crud/trainer/model";
import { toast } from "@common/toasts";
import { saveOptSchemeTrainerAttributes } from "@common/crud/trainer/actions";
import { useDispatch } from "react-redux";
import { TrainerApi } from "../../trainerApi";

interface Props {
    modalHeader: string;
    trainerAttributes: TrainerAttribute[];
    trainerId: string;
    isDigital: boolean;
}

export const OptTrainerModal: React.FC<Props> = ({ modalHeader, trainerAttributes, trainerId, isDigital }) => {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [modalStage, setModalStage] = React.useState<number>(1);
    const [optTrainerAttributes, setOptTrainerAttributes] = React.useState<OptSchemeTrainerAttributeModel[]>([]);
    const [eventInstanceCounts, setEventInstanceCounts] = React.useState<EventInstanceCountForAttributeModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleResetAndCloseModal = () => {
        setOptTrainerAttributes([]);
        setEventInstanceCounts([]);
        setModalStage(1);
        setOpenModal(false);
    };

    const handleGoBack = () => {
        setOptTrainerAttributes([]);
        setEventInstanceCounts([]);
        setModalStage(modalStage - 1);
    };

    function handleModal() {
        setOpenModal(true);
    };

    function onCancel() {
        if (modalStage === 2) {
            handleGoBack();
        }
        else {
            handleResetAndCloseModal();
        }
    };

    async function onSave() {
        setLoading(true);

        if (optTrainerAttributes.length) {
            const newOptTrainerAttributes = optTrainerAttributes.map(a => ({ ...a, isOptedOut: a.isOptedOut !== true }));

            if (modalStage === 1) {
                const newOptOutTrainerAttributes = newOptTrainerAttributes.filter(x => x.isOptedOut);

                if (newOptOutTrainerAttributes.length > 0) {
                    const counts =
                        (await new TrainerApi().getEventInstanceCountForAttributes(trainerId, newOptOutTrainerAttributes.map(x => x.attributeDefinitionId)))
                            .filter(x => x.eventInstanceCount > 0);

                    if (counts.length > 0) {
                        setEventInstanceCounts(counts);
                        setModalStage(modalStage + 1);
                        setLoading(false);
                        return;
                    }
                }
            }

            await saveTrainerAttributes(newOptTrainerAttributes);
        }
        else {
            toast.error("Cannot save because no changes have been made.");
        }

        setLoading(false);
    };

    const saveTrainerAttributes = async (newOptTrainerAttributes: OptSchemeTrainerAttributeModel[]) => {

        const optSchemeTrainerDetail: OptSchemeTrainerModel = {
            trainerId,
            isDigital,
            optSchemeTrainerAttributes: newOptTrainerAttributes
        };

        await dispatch(saveOptSchemeTrainerAttributes(optSchemeTrainerDetail));
        handleResetAndCloseModal();
    };

    const renderModalContent = () => {
        if (modalStage === 2) {
            return (
                <>
                    {eventInstanceCounts.map(c => {
                        const attribute = trainerAttributes.find(a => a.attributeDefinitionId === c.attributeDefinitionId);

                        const message =  `You have ${c.eventInstanceCount} future course${c.eventInstanceCount > 1 ? "s" : ""} ` +
                            `booked for that need${c.eventInstanceCount > 1 ? "" : "s"} ${attribute.name}`;

                        return (
                            <p key={c.attributeDefinitionId}>
                                {message}
                            </p>
                        );
                    })}
                    <p>Are you sure you wish to continue?</p>
                </>
            );
        }

        return (
            <>
                <p>Use the toggles provided to opt in or out of each scheme.</p>
                <OptTrainerTable
                    trainerAttributes={trainerAttributes}
                    setOptTrainerAttributes={setOptTrainerAttributes}
                    optTrainerAttributes={optTrainerAttributes} />
            </>
        );
    };

    return (
        <>
            <Button
                disabled={!trainerAttributes?.length}
                content="Update Preferences"
                onClick={handleModal}
            />
            <Modal open={openModal}>
                <Modal.Header>
                    Update {modalHeader}
                </Modal.Header>
                <Modal.Content>
                    <Container>
                        {renderModalContent()}
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancel} negative content={modalStage === 2 ? "Back" : "Cancel"} />
                    <Button onClick={onSave} positive content={modalStage === 2 ? "Continue" : "Save"} disabled={loading} loading={loading} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
