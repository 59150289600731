import * as React from "react";
import { Trans } from "react-i18next";
import { EventInstance, LanguageEnum } from "@common/crud/eventInstance/model";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface Props {
    eventInstance: EventInstance;
    organisation?: OrganisationDetailModel;
}

export const TermsAndFaqLinks: React.FC<Props> = (props: Props) => {
    const links = getExternalLinksForGivenLanguage(props.eventInstance, props.organisation);
    return (
        <div className="tacs-faqs">
            <Trans i18nKey="TAndCsConfirmationComponent:CONFIRM">
                Please confirm you have read our
                <a className="primary-link" href={links.termsAndConditionsLink} target="_blank" rel="noreferrer">Terms &amp; Conditions</a>.
            </Trans>
        </div>
    );
};

export const getExternalLinksForGivenLanguage = (eventInstance: EventInstance, organisation?: OrganisationDetailModel) => {
    if (eventInstance.language === LanguageEnum.English) {
        return {
            termsAndConditionsLink: organisation?.organisationTermsAndConditions
                ? organisation?.termsAndConditionsLink || eventInstance.termsAndConditionsLinkEn || "#"
                : eventInstance.termsAndConditionsLinkEn || "#"
        };
    } else {
        return {
            termsAndConditionsLink: organisation?.organisationTermsAndConditions
                ? organisation?.termsAndConditionsLinkCy || eventInstance.termsAndConditionsLinkCy || "#"
                : eventInstance.termsAndConditionsLinkCy || "#"
        };
    }
};
