import moment from "moment";
import * as actions from "./actiontypes";
import { EventInstanceAction } from "./actions";
import {
    AdminRequestedTrainerCounts,
    BookNowPayLaterOrderAttendees,
    DigitalPlanningSession,
    EINotes,
    EnquiryEventInstanceData,
    EventInstance,
    TrainerAvailabilityRoleTypeEnum } from "./model";
import { orderBy } from "lodash";
import { EventInstanceDetailModel, EventInstanceListModel } from ".";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";

export function eventInstances(state: EventInstance[] = [], action: EventInstanceAction): EventInstance[] {
    switch (action.type) {
        case actions.LOAD_EVENTINSTANCES_SUCCESS:
            return orderBy(action.payload.map(c => ({ ...c, listUpdated: moment() })), ["startDate", "startTime"], "asc");
        case actions.CREATE_EVENTINSTANCES_SUCCESS:
            return [...state, ...action.payload];
        case actions.SAVE_EVENTINSTANCES_SUCCESS:
        case actions.BULK_UPDATE_DORS_OPEN_PLACES_COUNT_SUCCESS:
        case actions.BULK_REMOVE_TRAINERS_SUCCESS:
            return addOrReplace(state, action.payload);
        case actions.SAVE_EVENTINSTANCE_SUCCESS:
        case actions.PUBLISH_EVENT_INSTANCE_SUCCESS:
        case actions.LOAD_EVENTINSTANCE_DETAIL_SUCCESS:
        case actions.SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS:
        case actions.UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS:
        case actions.BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS:
        case actions.BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS:
        case actions.CANCEL_EVENT_INSTANCE_SUCCESS:
        case actions.REGISTRATION_SUBMIT_SUCCESS:
        case actions.CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS:
        case actions.ACCEPT_EVENT_INSTANCES_SUCCESS:
        case actions.SAVE_NOTES_SUCCESS:
        case actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS:
        case actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS:
        case actions.SAVE_HEALTH_AND_SAFETY_SUCCESS:
        case actions.SAVE_FINANCE_DETAILS_SUCCESS:
        case actions.UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS:
        case actions.CONVERT_TO_BOOKABLE_SUCCESS:
            return state.map(c => eventInstance(c, action));
        case actions.CREATE_EVENTINSTANCE_SUCCESS:
            return [...state, detailUpdated(action.payload)];
        default:
            return state;
    }
}

export function totalNumberOfEventInstances(state: number = 0, action: EventInstanceAction): number {
    switch (action.type) {
        case actions.TOTAL_NUMBER_OF_EVENTINSTANCES_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function groupedEventInstances(state: EventInstanceGroupModel[] = [], action: EventInstanceAction): EventInstanceGroupModel[] {
    switch (action.type) {
        case actions.LOAD_EVENTINSTANCE_GROUPS_SUCCESS:
            return orderBy(action.payload.map(c => ({ ...c, listUpdated: moment() })), ["startDate", "startTime"], "asc");
        case actions.LOAD_EVENTINSTANCE_GROUP_SUCCESS:
            return [...state.filter(e => e.id !== action.payload.id), action.payload];
        case actions.SAVE_EVENTINSTANCE_GROUP_SUCCESS:
            return state.map(c => groupedEventInstance(c, action));
        default:
            return state;
    }
}

function groupedEventInstance(state: EventInstanceGroupModel, action: EventInstanceAction): EventInstanceGroupModel {
    switch (action.type) {
        case actions.SAVE_EVENTINSTANCE_GROUP_SUCCESS:
            if (action.payload.id === state.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}

export function digitalPlaning(state: DigitalPlanningSession[] = [], action: EventInstanceAction): DigitalPlanningSession[] {
    switch (action.type) {
        case actions.LOAD_DIGITAL_PLANNING_SESSIONS_SUCCESS:
            return action.payload;
        case actions.SAVE_DIGITAL_PLANNING_SESSIONS_SUCCESS:
            return [...state.filter(e => e.id !== action.payload.id), action.payload];
        case actions.DELETE_DIGITAL_PLANNING_SESSIONS_SUCCESS:
            return [...state.filter(e => e.id !== action.payload)];
        default:
            return state;
    }
}

function eventInstance(state: EventInstance, action: EventInstanceAction): EventInstance {
    switch (action.type) {
        case actions.SAVE_EVENTINSTANCE_SUCCESS:
        case actions.PUBLISH_EVENT_INSTANCE_SUCCESS:
        case actions.LOAD_EVENTINSTANCE_DETAIL_SUCCESS:
        case actions.SET_EVENT_INSTANCE_CONFIRMATION_STATUS_SUCCESS:
        case actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_BY_TRAINER_SUCCESS:
        case actions.UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS:
        case actions.SAVE_FINANCE_DETAILS_SUCCESS:
        case actions.CONVERT_TO_BOOKABLE_SUCCESS:
            if (action.payload.id === state.id) {
                return detailUpdated(action.payload, state);
            }
            return state;
        case actions.CANCEL_EVENT_INSTANCE_SUCCESS:
            if (action.payload.id === state.id) {
                return detailUpdated({
                    id: action.payload.id,
                    cancelled: true,
                    reasonForCancellation: action.payload.reasonForCancellation,
                    digitalCourseAccount: null,
                    digitalCoursePasswordTrainer: null,
                    closedCourseFee: action.payload.financeResponse
                        ? action.payload.financeResponse.closedCourseFee
                        : state.closedCourseFee,
                    closedCourseFeeInPence: action.payload.financeResponse
                        ? action.payload.financeResponse.closedCourseFeeInPence
                        : state.closedCourseFeeInPence,
                    invoiceTotalInPence: action.payload.financeResponse
                        ? action.payload.financeResponse.invoiceTotalInPence
                        : state.invoiceTotalInPence,
                    miscellaneousOrganisationFee: action.payload.financeResponse
                        ? action.payload.financeResponse.miscellaneousOrganisationFee
                        : state.miscellaneousOrganisationFee,
                    miscellaneousOrganisationFeeInPence: action.payload.financeResponse
                        ? action.payload.financeResponse.miscellaneousOrganisationFeeInPence
                        : state.miscellaneousOrganisationFeeInPence,
                    uploadFee: action.payload.financeResponse
                        ? action.payload.financeResponse.uploadFee
                        : state.uploadFee,
                    uploadFeeInPence: action.payload.financeResponse
                        ? action.payload.financeResponse.uploadFeeInPence
                        : state.uploadFeeInPence,
                }, state);
            }
            return state;
        case actions.REGISTRATION_SUBMIT_SUCCESS: {
            if (action.payload.id === state.id) {
                if (action.payload.completion) {
                    return detailUpdated({ id: action.payload.id, attendeesRegisterDate: moment(), isStageTwo: action.payload.isStageTwo, }, state);
                }
                else {
                    return detailUpdated({ id: action.payload.id, isStageTwo: action.payload.isStageTwo, }, state);
                }
            }
            return state;
        }
        case actions.ACCEPT_EVENT_INSTANCES_SUCCESS: {
            if (action.payload?.includes(state.id)) {
                return detailUpdated({ id: state.id, isAcceptedByTrainer: true }, state);
            }
            return state;
        }
        case actions.BULK_UPDATE_REASON_FOR_HIDING_EVENT_SUCCESS: {
            const ei = (action.payload as EventInstanceDetailModel[]).find(x => x.id === state.id);
            if (ei) {
                return detailUpdated(ei, state);
            }
            return state;
        }
        case actions.BULK_MAKE_AVAILABLE_TO_TRAINERS_SUCCESS: {
            const ei = (action.payload as EventInstanceDetailModel[]).find(x => x.id === state.id);
            if (ei) {
                return detailUpdated(ei, state);
            }
            return state;
        }
        case actions.SAVE_NOTES_SUCCESS: {
            const payload = action.payload as { id: string; notes: EINotes };
            if (payload && payload.id === state.id) {
                return detailUpdated({
                    id: state.id,
                    officeNote: payload.notes.officeNote,
                    trainerNote: payload.notes.trainerNote,
                    adminNote: payload.notes.adminNote,
                    bookingNoteEn: payload.notes.bookingNoteEn,
                    bookingNoteCy: payload.notes.bookingNoteCy
                }, state);
            }
            return state;
        }
        case actions.SET_EVENT_INSTANCE_AVAILABILITY_FOR_OTHER_TRAINERS_SUCCESS: {
            const payload = action.payload as
            { id: string; adminRequestedTrainerCounts: AdminRequestedTrainerCounts; adminAvailableRoles: TrainerAvailabilityRoleTypeEnum[] };
            if (payload && payload.id === state.id) {
                return detailUpdated({
                    id: state.id,
                    adminAvailableRoles: payload.adminAvailableRoles,
                    availableForOtherTrainers: payload.adminAvailableRoles?.length > 0
                }, state);
            }
            return state;
        }
        case actions.CANCEL_MULTIPLE_EVENT_INSTANCES_SUCCESS:
            const payload = action.payload as EventInstanceListModel[];
            const event = payload?.find(x => state.id === x.id);
            return detailUpdated(event, state);
        case actions.SAVE_HEALTH_AND_SAFETY_SUCCESS:
            if (action.payload && action.payload.id === state.id) {
                return detailUpdated({
                    id: state.id,
                    healthAndSafety: action.payload.healthAndSafety
                }, state);
            }
            return state;
        case actions.UPDATE_EVENT_INSTANCE_FAVOURITE_STATUS:
            if (action.payload.id === state.id) {
                return favouriteStatusUpdated(action.payload.favourite, state);
            }
            return state;
        default:
            return state;
    }
}

export function eventInstanceFinanceData(state: BookNowPayLaterOrderAttendees[] = [], action: EventInstanceAction): BookNowPayLaterOrderAttendees[] {
    switch (action.type) {
        case actions.LOAD_EVENT_INSTANCE_FINANCE_DATA_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function eventInstanceEnquiriesData(state: EnquiryEventInstanceData[] = [], action: EventInstanceAction): EnquiryEventInstanceData[] {
    switch (action.type) {
        case actions.LOAD_EVENT_INSTANCE_ENQUIRIES_DATA_SUCCESS:
            return action.payload;
        case actions.UPDATE_ENQUIRY_STATUS_SUCCESS:
            return state.map(e => {
                if (e.id === action.payload.enquiryId) {
                    return { ...e, state: action.payload.state };
                }
                return e;
            });
        default:
            return state;
    }
}

function detailUpdated(value: EventInstance, state: EventInstance = {} as any): EventInstance {
    return { listUpdated: moment(), ...state, ...value, detailUpdated: moment() };
}

function favouriteStatusUpdated(value: boolean, state: EventInstance = {} as any): EventInstance {
    return { listUpdated: moment(), ...state, favourite: value };
}

function addOrReplace(state: EventInstance[], payload: EventInstance[]): EventInstance[] {
    const newState = state.filter(x => payload.findIndex(newEvent => newEvent.id === x.id) === -1);
    const events = [...newState, ...payload];
    return orderBy(events, ["startDate", "startTime"], "asc");
}
