import * as React from "react";
import { createPortal } from "react-dom";
import "./PrintView.scss";

export function PrintView({ children }: React.Props<void>) {
    const printRoot = document.getElementById("printroot");

    const oldPrintElementChild = document.getElementById("print-child");

    if (oldPrintElementChild) {
        printRoot.removeChild(oldPrintElementChild);
    }

    const newPrintElementChild = document.createElement("div");
    newPrintElementChild.setAttribute("id","print-child");
    printRoot.appendChild(newPrintElementChild);

    return createPortal(
        <section className="print-only">{children}</section>,
        newPrintElementChild
    );
}
