import { createSelector } from "reselect";
import { NudgeTaskTypeModel } from "../common/NudgeTaskBaseModels";
import { AlaskaNudgeTask, AppState } from "./model";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.alaskaNudgeTaskId;
};

export const tasksSelector = (state: AppState) => state.alaskaTasks;

export const tasksCountSelector = (state: AppState) => state.alaskaTasksCount;

export const alaskaNudgeTaskSelector = createSelector(
    tasksSelector,
    routeIdSelector,
    (tasks: AlaskaNudgeTask[], id: string) => {
        return tasks.filter(c => c.id === id)[0] || {} as any;
    }
);

export const eventManagementPathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("police-and-court-event-management") + "police-and-court-event-management".length);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("alaskatasks") + "alaskatasks".length);

export const taskTypesSelector = (state: AppState) => state.alaskaTaskTypes;

export const alaskaTaskTypesOptionsSelector = createSelector(
    taskTypesSelector,
    (alaskaNudgeTypes: NudgeTaskTypeModel[]) => alaskaNudgeTypes.map(t => {
        return { text: t.title, value: t.id.toString(), key: t.id.toString() };
    })
);

export const pendingNoteCreatorsSelector = (state: AppState) => state.pendingNoteCreators;

export const caEmailsSelector = (state: AppState) => state.caEmails;

export const seniorCaEmailsSelector = (state: AppState) => state.seniorCaEmails;

export const corporateAdminEmailsSelector = (state: AppState) => state.corporateAdminEmails;

export const pendingNoteCreatorsOptionsSelector = createSelector(
    pendingNoteCreatorsSelector,
    (pendingNoteCreators: string[]) => {
        const options = pendingNoteCreators.sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" })).map(t => ({ text: t, value: t, key: t }));
        return [{ text: "Any User", value: "" }, ...options];
    }
);

export const trainersWhoHaveBeenAssignedSelector = (state: AppState) => state.trainersAssignedOnNudge;

export const trainersWhoHaveBeenAssignedOptionsSelector = createSelector(
    trainersWhoHaveBeenAssignedSelector,
    (trainersAssignedOnNudge: string[]) => {
        const individualTrainers = trainersAssignedOnNudge?.reduce((n, trainers) => n = [...n, ...trainers.split(",")], []) ?? [];
        const uniqueTrainers =  Array.from(new Set(individualTrainers));
        const options = uniqueTrainers?.map(t => ({ text: t, value: t, key: t })) ?? [];

        return [{ text: "Any Trainer", value: "" }, ...options];
    }
);

