import { createSelector } from "reselect";
import moment from "moment";
import { Venue, AppState, SearchOptions as VenueListFilterState } from "./model";
import { CurrentUser, currentUserSelector } from "@common/auth";
import { SearchOptions } from "../eventInstance/model";
import { eventInstanceSearchOptionsSelector } from "../eventInstance/selectors";
import { DropdownItemProps } from "semantic-ui-react";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { NonPoliceOrCourtWorkflowTypeForCompanyTypeOptions, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { isWorkflowConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";
import { Organisation, SchemeDeliveryTypeEnum, VenueTypeEnum } from "../organisation/model";
import { BusinessLineType } from "@common/redux-helpers";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.venueId;
};

export type SelectorState = AppState;

export const venuesSelector = (state: AppState) => state.venues;

export const venueSelector = createSelector(
    venuesSelector,
    routeIdSelector,
    (venues: Venue[], id: string): Venue => {
        return venues.filter(c => c.id === id)[0] || {} as any;
    }
);

export const onsiteVenuesSelector = createSelector(
    venuesSelector,
    venues => {
        return venues.filter(v => v.deliveryType === DeliveryTypeEnum.Onsite && v.venueType !== VenueTypeEnum.DelegateHome);
    }
);

export const unexpiredVenuesSelector = createSelector(
    venuesSelector,
    venues => {
        const now = moment();
        return venues.filter(v =>
            v.expiryDate === undefined ||
            v.expiryDate === null ||
            (v.expiryDate && (!v.expiryDate.isValid() || v.expiryDate.isAfter(now))));
    }
);

export const dorsVenuesSelector = createSelector(
    unexpiredVenuesSelector,
    venues => venues.filter(v => v.dorsId)
);

export const dorsVenueOptionsSelector = createSelector(
    dorsVenuesSelector,
    venues => venues.map(v => ({ text: v.name, value: v.dorsId || "", key: v.id }))
);

export const unexpiredClassroomVenueOptionsSelector = createSelector(
    unexpiredVenuesSelector,
    (venues: Venue[]) => {
        const venueOptions = venues
            .filter(v => v.deliveryType === DeliveryTypeEnum.Onsite)
            .map(v => ({ text: v.name, value: v.id || "", key: v.id }));

        return [
            { text: "Any", value: "" },
            ...venueOptions.sort((optionA, optionB) => {
                return optionA.text.localeCompare(optionB.text);
            })
        ];
    }
);

export const forceIdFilteredVenueSelector = createSelector(
    eventInstanceSearchOptionsSelector,
    currentUserSelector,
    venuesSelector,
    (searchOptions: SearchOptions, user: CurrentUser, allVenues: Venue[]) => {
        const forceId = +(user.forceId ?? searchOptions.forceId);
        return forceId ?
            allVenues.filter(venue => venue.forceId === forceId) :
            allVenues.filter(venue => venue.workflowTypes.some(wf => wf === WorkflowTypeEnum.Dors));
    }
);

export const forceIdBySearchOptionsFilteredVenueSelector = createSelector(
    eventInstanceSearchOptionsSelector,
    currentUserSelector,
    venuesSelector,
    (searchOptions: SearchOptions, user: CurrentUser, allVenues: Venue[]) => {
        const forceIds = user.forceId ? [user.forceId.toString()] : searchOptions.forceId;
        return forceIds.length ?
            allVenues.filter(venue => forceIds.some(forceId => parseInt(forceId, 10) === venue.forceId)) :
            allVenues.filter(venue => venue.workflowTypes.some(wf => wf === WorkflowTypeEnum.Dors));
    }
);

export const constructionFilteredVenueSelector = createSelector(
    venuesSelector,
    (allVenues: Venue[]) => {
        return  allVenues.filter(venue => venue.workflowTypes.some(wf => isWorkflowConstruction(wf)));
    }
);

export const corporateFilteredVenueSelector = createSelector(
    venuesSelector,
    (allVenues: Venue[]) => {
        return  allVenues.filter(venue => venue.workflowTypes.some(wf => isWorkflowCorporate(wf)));
    }
);

export const corporateAndConstructionFilteredVenueSelector = createSelector(
    venuesSelector,
    (allVenues: Venue[]) => {
        return  allVenues.filter(venue => venue.workflowTypes.some(wf => isWorkflowCorporate(wf) || isWorkflowConstruction(wf)));
    }
);

export const venueOptionsForWorkflowSelector = (workflowTypes: WorkflowTypeEnum[]) => createSelector(
    venuesSelector,
    (allVenues: Venue[]) => {
        return allVenues
            .filter(venue => workflowTypes.length === 0 || venue.workflowTypes.some(wf => workflowTypes.some(wt => wt === wf)))
            .filter(venue => !venue.expiryDate?.isValid() || venue.expiryDate?.isAfter(moment()))
            .sort((v1, v2) => v1.name.localeCompare(v2.name))
            .map(venue => ({ text: venue.name, value: venue.id || "", key: venue.id }));
    }
);

export const corporateOnsiteFilteredVenueSelector = createSelector(
    venuesSelector,
    (allVenues: Venue[]) => {
        return  allVenues.filter(venue => venue.workflowTypes.some(wf => isWorkflowCorporate(wf) && venue.deliveryType === DeliveryTypeEnum.Onsite));
    }
);

export const dsaAreaIdFilteredVenueSelector = createSelector(
    eventInstanceSearchOptionsSelector,
    currentUserSelector,
    venuesSelector,
    (searchOptions: SearchOptions, user: CurrentUser, allVenues: Venue[]) => {
        const dsaAreaId = +searchOptions.dsaAreaId;
        return dsaAreaId ?
            allVenues.filter(venue => venue.dsaAreaId === dsaAreaId) :
            allVenues.filter(venue => venue.workflowTypes.some(wf => wf === WorkflowTypeEnum.DDRS));
    }
);

export const venuesOptionsSelectorWithAny = createSelector(
    forceIdFilteredVenueSelector,
    (venues: Venue[]) => {
        const venueOptions = venues.map(v => ({ text: v.name, value: v.id || "", key: v.id }));

        return [
            { text: "Any Venue", value: "" },
            ...venueOptions.sort((optionA, optionB) => {
                return optionA.text.localeCompare(optionB.text);
            })
        ];
    }
);

export const venueOptionsForNonPoliceOrCourtBulkPlanSelector =
    (businessLineType: BusinessLineType, organisation: Organisation, deliveryType?: SchemeDeliveryTypeEnum, workflowType?: WorkflowTypeEnum) => createSelector(
        unexpiredVenuesSelector,
        (venues: Venue[]) => {
            const possibleWorkflowTypes =
                NonPoliceOrCourtWorkflowTypeForCompanyTypeOptions(
                    businessLineType,
                    organisation.companyType
                );
            venues = venues.filter((venue) =>
                possibleWorkflowTypes.some(
                    (possibleWorkflowType) =>
                        venue.workflowTypes?.some(
                            (venueWorkflowType) =>
                                venueWorkflowType === possibleWorkflowType.value
                        ) || false
                )
            );

            const hasCorrectWorkflow = (venue: Venue) => !workflowType || venue.workflowTypes.some(wf => wf === workflowType);
            const hasCorrectDeliveryType = (venue: Venue) => (
                !deliveryType
                || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
                || (deliveryType === SchemeDeliveryTypeEnum.Classroom && venue.deliveryType === DeliveryTypeEnum.Onsite)
                || (deliveryType === SchemeDeliveryTypeEnum.Digital && venue.deliveryType === DeliveryTypeEnum.Digital)
            );
            const hasCorrectVenueType = (venue: Venue) => (
                (businessLineType === BusinessLineType.Corporate && (venue.venueType === VenueTypeEnum.CorporateShared
                || (venue.venueType === VenueTypeEnum.CorporateOrganisationSpecific && venue.organisationId === organisation.id))) ||
                (businessLineType === BusinessLineType.Construction && (venue.venueType === VenueTypeEnum.ConstructionShared
                    || (venue.venueType === VenueTypeEnum.ConstructionOrganisationSpecific && venue.organisationId === organisation.id)))
            );

            return venues
                .filter(v => hasCorrectWorkflow(v) && hasCorrectDeliveryType(v) && hasCorrectVenueType(v))
                .map(v => ({ text: v.name, value: v.id || "", key: v.id }));
        });

export const sortedVenueOptionsWithAnySelector = createSelector(
    forceIdBySearchOptionsFilteredVenueSelector,
    (venues: Venue[]) => filterAndSortActiveVenues(venues)
);

export const constructionSortedVenueOptionsWithAnySelector = createSelector(
    constructionFilteredVenueSelector,
    (venues: Venue[]) => filterAndSortActiveVenues(venues)
);

export const corporateSortedVenueOptionsWithAnySelector = createSelector(
    corporateFilteredVenueSelector,
    (venues: Venue[]) => filterAndSortActiveVenues(venues)
);

export const corporateOnsiteSortedVenueOptionsWithAnySelector = createSelector(
    corporateOnsiteFilteredVenueSelector,
    (venues: Venue[]) => filterAndSortActiveVenues(venues)
);

export const sortedDrinkDriveVenueOptionsWithAnySelector = createSelector(
    dsaAreaIdFilteredVenueSelector,
    (venues: Venue[]) => filterAndSortActiveVenues(venues)
);

const filterAndSortActiveVenues = (venues: Venue[]) => {
    const filterAndSort = (filter: (venue: Venue) => boolean) =>
        venues.filter(filter).sort((v1, v2) => v1.name.localeCompare(v2.name));

    const activeVenues = filterAndSort(v => !v.expiryDate?.isValid() || v.expiryDate?.isAfter(moment()));
    const expiredVenues = filterAndSort(v => v.expiryDate?.isValid() && v.expiryDate.isBefore(moment()));

    const activeVenueOptions = activeVenues.map<DropdownItemProps>(v => ({
        text: v.name, value: v.id || "", key: v.id
    }));

    const expiredVenueOptions = expiredVenues.map<DropdownItemProps>(v => ({
        text: v.name, value: v.id || "", key: v.id, className: "grey"
    }));

    return [
        ...activeVenueOptions,
        ...expiredVenueOptions
    ];
};

export const venuesOptionsSelectorWithAnyForForceId = (forceId?: number) => createSelector(
    unexpiredVenuesSelector,
    (allVenues: Venue[]) => {
        const dorsVenuesWithForceId = allVenues.filter(venue => venue.forceId && venue.workflowTypes
            && venue.workflowTypes.some(wt => wt === WorkflowTypeEnum.Dors));
        const venues = forceId ? dorsVenuesWithForceId.filter(venue => venue.forceId === forceId) : dorsVenuesWithForceId;

        const venueOptions = venues.map(v => ({ text: v.name, value: v.id || "", key: v.id, forceId: v.forceId }));

        return [
            { text: "Any Venue", value: "", forceId: undefined as number },
            ...venueOptions.sort((optionA, optionB) => {
                return optionA.text.localeCompare(optionB.text);
            })
        ];
    }
);

export const venuesOptionsSelectorWithAnyForDsaAreaId = (dsaAreaId?: number) => createSelector(
    unexpiredVenuesSelector,
    (allVenues: Venue[]) => {

        const venues = dsaAreaId ? allVenues.filter(venue => venue.dsaAreaId === dsaAreaId && venue.workflowTypes?.includes(WorkflowTypeEnum.DDRS)) : allVenues;

        const venueOptions = venues.map(v => ({ text: v.name, value: v.id || "", key: v.id, forceId: v.forceId }));

        return [
            { text: "Any Venue", value: "", forceId: undefined as number },
            ...venueOptions.sort((optionA, optionB) => {
                return optionA.text.localeCompare(optionB.text);
            })
        ];
    }
);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("venues") + "venues".length);

export const venueFilterQuerySelector = (state: AppState): VenueListFilterState => {
    const { forceId, name, deliveryType, dsaAreaId, showExpired, workflows, venueType } = state.router.query;

    return {
        forceId: forceId ? Number(forceId) : undefined,
        name: name ?? undefined,
        deliveryType: deliveryType ? Number(deliveryType) : undefined,
        dsaAreaId: dsaAreaId ? Number(dsaAreaId) : undefined,
        showExpired: showExpired === "true",
        workflows: workflows?.split(",").filter(Boolean).map(x => +x) ?? [],
        venueType: venueType ? Number(venueType) : undefined
    };
};
