import { Booking } from "../bookings/models";
import { ExternalLinks } from "@common/ExternalLinks";
import { DependencyList, LegacyRef, MutableRefObject, useEffect, useRef } from "react";
import moment from "moment";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventInstanceGroupItemModel } from "@common/crud/eventInstanceGroup/model";
import { Seat } from "@booking/seat";

export interface LandingState {
    booking: Booking;
    externalLinks: ExternalLinks;
}

export interface SkipAndBookModel {
    bookingId: string;
    isWaivingRebookingFee?: boolean;
    seats: Seat[];
}

export interface AllEventInstancesProps {
    eventInstances: CourseSearchResultRow[];
    eventInstanceGroups: GroupCourseSearchResultRow[];
    moreResults: boolean;
    loadMore: () => void;
    noCoursesMessage: string;
    pageSize: number;
    showNoCoursesMessage?: boolean;
}

export interface EventInstanceProps {
    eventInstance: CourseSearchResultRow;
    path: string;
    additionalMargin: boolean;
    firstNewElement: LegacyRef<HTMLDivElement>;
    isMobile?: boolean;
}

export interface GroupEventInstanceProps {
    groupSearchResultRow: GroupCourseSearchResultRow;
    path: string;
    additionalMargin: boolean;
    firstNewElement: LegacyRef<HTMLDivElement>;
}

export interface CourseSearchResultRow {
    readonly id: string;
    readonly startDate?: moment.Moment;
    readonly startTime?: moment.Duration;
    readonly endTime?: moment.Duration;
    readonly distanceInMiles?: number;
    readonly priceInPence?: number;
    readonly language?: number;
    readonly venueName?: string;
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly seatsAvailable?: number;
    readonly automaticSeatsData?: EventInstanceSessionCarTypeData;
    readonly manualSeatsData?: EventInstanceSessionCarTypeData;
    readonly oneToOne?: boolean;
    readonly priceClearedDueToFlexiPay?: boolean;
}

export interface GroupCourseSearchResultRow extends CourseSearchResultRow {
    groupId: string;
    dayParts: EventInstanceGroupItemModel[];
}

export interface EventInstanceSessionCarTypeData {
    readonly availableSeats: number;
    readonly remainingSeats: number;
}

export enum IndexNames {
    None = -1,
    Digital = 0,
    Classroom = 1,
}

export const deliveryTypeToIndexNameMap = {
    [DeliveryTypeEnum.Digital]: IndexNames.Digital,
    [DeliveryTypeEnum.Onsite]: IndexNames.Classroom,
    [DeliveryTypeEnum.None]: IndexNames.Digital,
};

export interface DeliveryTypeFilterSearch {
    deliveryTypeColumnActiveIndex: number;
    distance: number;
    venueId: string;
    bookingId: string;
}

export enum LocalStateKeysEnum {
    AdvancedFilterSearch = 0,
    DeliveryTypeFilterSearch = 1,
}

export const LocalStateKeys = {
    [LocalStateKeysEnum.AdvancedFilterSearch]: "advancedFilterSearch-",
    [LocalStateKeysEnum.DeliveryTypeFilterSearch]: "deliveryTypeFilterSearch",
};

function cumulativeOffsetTop(element: HTMLElement) {
    let top = 0;
    let current: any = element;
    do {
        top += current.offsetTop || 0;
        current = current.offsetParent;
    } while (current);

    return top;
}

export function useScrollToNewElementRef(deps: DependencyList): MutableRefObject<HTMLDivElement> {
    const firstNewElement = useRef<HTMLDivElement>();

    useEffect(() => {
        if (firstNewElement.current) {
            window.scrollTo({
                behavior: "smooth",
                top: cumulativeOffsetTop(firstNewElement.current) - 100
            });
        }
    }, [deps]);

    return firstNewElement;
}
