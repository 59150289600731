import * as React from "react";
import { Button, DropdownDivider, Form } from "semantic-ui-react";
import moment from "moment";
import { GetDropDownItemFromMoment, GetMomentFromDropDownItem, GetNextTwelveMonthsDropDownOptions } from "./GetNextTwelveMonthsDropDownOptions";
import { DigitalPlanningFilter } from "./DigitalPlanningBase";
import { useSelector } from "react-redux";
import { digitalPlanningSessionsSelector } from "../../selectors";

export interface FiltersProps {
    disabled: boolean;
    month: moment.Moment;
    onFilterChange: (filters: DigitalPlanningFilter) => void;
    hideCreateCoursesButton?: boolean;
}

export const MonthDropDown: React.FC<FiltersProps> = ({ disabled, onFilterChange, month, hideCreateCoursesButton }) => {
    const [showMonthDropDown, setShowMonthDropDown] = React.useState(hideCreateCoursesButton ?? (month !== null && month !== undefined));

    const onStartCreatingClicked = React.useCallback(() => setShowMonthDropDown(true), []);

    const onMonthChange = React.useCallback((_: any, { value }) => {
        onFilterChange({ month: GetMomentFromDropDownItem(value), originalMonth: GetMomentFromDropDownItem(value),  trainerId: null });
    }, [onFilterChange]);

    const planningSessions = useSelector(digitalPlanningSessionsSelector);

    const monthOptions = GetNextTwelveMonthsDropDownOptions(planningSessions);
    return (
        <>
            { showMonthDropDown === false &&
                <Button onClick={onStartCreatingClicked} content={"Create Courses"} />
            }
            {   showMonthDropDown &&
                <Form.Dropdown
                    selection
                    label={"Choose a Month"}
                    floating
                    disabled={disabled}
                    placeholder="Month"
                    value={month && GetDropDownItemFromMoment(month)}
                    options={monthOptions}
                    onChange={onMonthChange}
                    search
                    selectOnBlur={false}
                />
            }
            <DropdownDivider />
        </>
    );
};
