import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { Address } from "@common/crud/common/Address";
import { DropdownItemProps } from "semantic-ui-react";
import { debounce } from "lodash";
import { AddressLookupApi } from "../addressLookupApi";
import { AppState } from "@common/crud/police-user/model";
import { AppCommonState } from "@common/appCommonState";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { connect } from "react-redux";
import { IsProductionEnvironment } from "@common/model";
import { v4 } from "uuid";
import { ExperianAddressResponse } from "../experianAddressModel";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";

interface AddressLookupProps {
    showErrors: boolean;
    address: Address;
    onChange: (value: Address, valid: boolean) => void;
    title?: string;
    required?: boolean;
    apiKey?: string;
    environment?: string;
    autofill?: boolean;
    disabled?: boolean;
    compact?: boolean;
    withoutTitle?: boolean;
    fetchingDataInProgress?: (id: string) => void;
    fetchingDataFinished?: (id: string) => void;
}

interface AddressLookupState {
    addressId: string;
    lastCallId: string;
    options: DropdownItemProps[];
}

class AddressLookupInternal extends React.Component<AddressLookupProps, AddressLookupState> {
    private notifyDebounced: (address: Address, valid: boolean) => void;
    private environment;

    constructor(props: AddressLookupProps) {
        super(props);

        this.notifyDebounced = debounce(this.notifyParent, 300);
        this.state = {
            addressId: props.address?.addressLine1,
            lastCallId: undefined,
            options: props.address?.addressLine1
                ? [{ text: props.address?.addressLine1, value: props.address?.addressLine1, key: props.address?.addressLine1 }]
                : []
        };
        this.environment = this.props.environment;
    }

    public async componentDidMount() {
        const { autofill } = this.props;
        if (autofill && !IsProductionEnvironment(this.environment)) {
            const addresses = await this.searchAddresses("TF1 6QJ");
            const addressToAutoPopulate = addresses.find(address => address.text === "T T C Group (Uk) Ltd, Hadley, Telford, TF1 6QJ");
            await this.getAddressById(addressToAutoPopulate.value.toString(), true);
        }
    }

    public render() {
        const { showErrors, disabled, compact, withoutTitle } = this.props;
        const required = this.props.required ?? true;
        const address = this.props.address || {} as any;
        const documentMode = "documentMode";
        // Internet Explorer 6-11
        const isIE = /* @cc_on!@ */ false || !!document[documentMode];

        return (
            <>
                {(!compact && !withoutTitle) && <h2>{this.props.title || "Address"}</h2>}
                {!isIE && <Input.Dropdown
                    value={this.state.addressId}
                    search={this.searchAddresses}
                    label={compact ? undefined : "Search"}
                    debounce={300}
                    placeholder="Type to search for an address"
                    showErrors={showErrors}
                    options={this.state.options}
                    dynamicOptions
                    onChange={this.getAddressById}
                    disabled={disabled}
                />}
                <ExtendedTextInput
                    value={address.addressLine1 ?? ""}
                    label={compact ? undefined : "Address Line 1"}
                    placeholder={compact ? "Address Line 1" : undefined}
                    showErrors={showErrors}
                    required={required}
                    onChange={this.handleOnChange("addressLine1")}
                    disabled={disabled}
                />
                <ExtendedTextInput
                    value={address.addressLine2 ?? ""}
                    label={compact ? undefined : "Address Line 2"}
                    placeholder={compact ? "Address Line 2" : undefined}
                    showErrors={showErrors}
                    onChange={this.handleOnChange("addressLine2")}
                    disabled={disabled}
                />
                <ExtendedTextInput
                    value={address.addressLine3 ?? ""}
                    label={compact ? undefined : "Address Line 3"}
                    placeholder={compact ? "Address Line 3" : undefined}
                    showErrors={showErrors}
                    onChange={this.handleOnChange("addressLine3")}
                    disabled={disabled}
                />
                <ExtendedTextInput
                    value={address.city ?? ""}
                    label={compact ? undefined : "Town"}
                    placeholder={compact ? "Town" : undefined}
                    showErrors={showErrors}
                    required={required}
                    onChange={this.handleOnChange("city")}
                    disabled={disabled}
                />
                <ExtendedTextInput
                    value={address.postalCode ?? ""}
                    label={compact ? undefined : "Postcode"}
                    placeholder={compact ? "Postcode" : undefined}
                    showErrors={showErrors}
                    required={required}
                    onChange={this.handleOnChange("postalCode")}
                    disabled={disabled}
                />
            </>
        );
    }

    private searchAddresses = async (postcode: string) => {
        const response = postcode && (await AddressLookupApi.searchForAddress(postcode));
        this.setState({
            options: response ?
                response.results.map(elem =>
                    ({ text: elem.suggestion, value: elem.format, key: elem.format }))
                    .concat((postcode ? [{ text: postcode, value: postcode, key: postcode }] : [])) :
                (postcode ? [{ text: postcode, value: postcode, key: postcode }] : [])
        });

        return this.state.options;
    };

    private getAddressById = async (value: string, valid: boolean) => {
        const isActualValue = !!value;
        const hasTheSamePreviousValue = value && this.props.address && this.state.addressId && this.state.addressId === value;

        if (isActualValue && !hasTheSamePreviousValue) {
            const callId = v4();

            if (this.props.fetchingDataInProgress) {
                this.props.fetchingDataInProgress(callId);
            }

            try {
                this.setState({ lastCallId: callId });
                const address = value && (await AddressLookupApi.getAddressById(value));
                this.propagateData(callId, value, valid, address);
            } finally {
                if (this.props.fetchingDataFinished) {
                    this.props.fetchingDataFinished(callId);
                }
            }
        }
    };

    private propagateData(callId: string, value: string, valid: boolean, address: ExperianAddressResponse) {
        if (address && this.state.lastCallId === callId) {
            this.setState({ addressId: value });
            this.notifyParent({
                addressLine1: address.address.addressLine1,
                addressLine2: address.address.addressLine2,
                addressLine3: address.address.addressLine3,
                city: address.address.locality,
                postalCode: address.address.postalCode
            }, valid);
        }
    }

    private handleOnChange(prop: keyof Address) {
        return (value: string, valid: boolean) => {
            const address: Address = {
                ...this.props.address,
                [prop]: value
            };
            this.notifyDebounced(address, valid);
        };
    }

    private notifyParent(address: Address, valid: boolean) {
        this.props.onChange(address, valid);
    }
}

function mapStateToProps(state: AppState & AppCommonState) {
    return {
        environment: appSettingsSelector(state).environment
    };
}

export const AddressLookup = connect(mapStateToProps)(AddressLookupInternal);
