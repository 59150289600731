import * as React from "react";
import { useSelector } from "react-redux";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { Modal } from "@common/components";
import { Input, Button } from "semantic-ui-react";
import { LabelWithValueAndAction } from "@common/global/LabelWithValueAndAction";
import { formatCurrency } from "@common/formating";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";

export interface EditProps {
    additionalCourseFees: number;
    adjustAdditionalFeesAmount: (newFeesAmount: number) => void;
}

export const AdditionalFeesLabelWithEdit: React.SFC<EditProps> = ({ additionalCourseFees, adjustAdditionalFeesAmount }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [feeAdjustment, setFeeAdjustment] = React.useState<number>(0);
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    const [isValid, setIsValid] = React.useState<boolean>(true);

    const closeModals = React.useCallback(() => {
        setOpen(false);
        setConfirmOpen(false);
    }, []);

    const cancelUpdate = React.useCallback(() => closeModals(), []);

    const sendUpdate = React.useCallback(() => {
        adjustAdditionalFeesAmount(feeAdjustment);
        closeModals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feeAdjustment]);

    const onAdjustedFeesChange = React.useCallback((e: any, { value }) => {
        setFeeAdjustment(Number.parseInt(value, 10));
    }, []);

    const toggleDialog = React.useCallback(() => setOpen(!open), [open]);

    const checkUpdate = React.useCallback(() => {

        if (feeAdjustment > 0 && feeAdjustment * 100 <= additionalCourseFees) {
            setIsValid(true);
            setConfirmOpen(true);
        } else {
            setIsValid(false);
        }
    }, [additionalCourseFees, feeAdjustment]);

    PreventScrollingOnNumberInputsHook();

    return (
        <>
            <LabelWithValueAndAction
                action={toggleDialog}
                actionName="Adjust Fees"
                label="Additional course fees"
                showAction={isClientAdvisor}
                value={formatCurrency(additionalCourseFees)}
            />

            <Modal open={open}>
                <Modal.Header>
                    Adjust Additional Course Fees
                </Modal.Header>
                <Modal.Content>
                    <p>How much are you adjusting today</p>
                    <Input
                        fluid
                        type="number"
                        value={feeAdjustment}
                        onChange={onAdjustedFeesChange}
                    />
                    {!isValid && <span>Adjustment must be a value greater than 0 and less than or equal to the current additional course fees</span>}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={cancelUpdate}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={checkUpdate}
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
                <Modal open={confirmOpen}>
                    <Modal.Header>
                        Are you sure?
                    </Modal.Header>
                    <Modal.Content>
                        <p>Action will decrease the additional course fees by : <strong>£{feeAdjustment}</strong></p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={cancelUpdate}
                            className="cancel-action"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={sendUpdate}
                            disabled={!isValid}
                            icon="checkmark"
                            content="I'm sure"
                        />
                    </Modal.Actions>
                </Modal>
            </Modal>
        </>
    );
};
