import { DayOfWeekEnum } from "@common/model";
import moment from "moment";

export const daysOfWeekMap: { dayOfWeekEnum: DayOfWeekEnum; dayOfWeek: string }[] = [
    { dayOfWeekEnum: DayOfWeekEnum.Monday, dayOfWeek: "MONDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Tuesday, dayOfWeek: "TUESDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Wednesday, dayOfWeek: "WEDNESDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Thursday, dayOfWeek: "THURSDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Friday, dayOfWeek: "FRIDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Saturday, dayOfWeek: "SATURDAY" },
    { dayOfWeekEnum: DayOfWeekEnum.Sunday, dayOfWeek: "SUNDAY" },
];

export function getFiltersTabPanes(
    digitalCoursesTab: () => JSX.Element,
    classRoomCoursesTab: () => JSX.Element,
    coursesMenuItemContent: (titleKey: string, explanationKey: string) => JSX.Element,
    showClassroom: boolean,
    showDigital: boolean) {

    const onlyOneTab = !showClassroom || !showDigital;
    const digitalOnlyTab = coursesMenuItemContent("DIGITAL_COURSES_ONLY", "DIGITAL_COURSES_ONLY_EXPLANATION");
    const digitalTab = coursesMenuItemContent("DIGITAL_COURSES", "DIGITAL_COURSES_EXPLANATION");

    const digital = {
        menuItem: {
            key: "digital",
            content: onlyOneTab ? digitalOnlyTab : digitalTab,
        },
        render: digitalCoursesTab,
    };

    const classroomOnlyTab = coursesMenuItemContent("CLASSROOM_COURSES_ONLY", "CLASSROOM_COURSES_ONLY_EXPLANATION");
    const classroomTab = coursesMenuItemContent("CLASSROOM_COURSES", "CLASSROOM_COURSES_EXPLANATION");

    const classroom = {
        menuItem: {
            key: "classroom",
            content: onlyOneTab ? classroomOnlyTab : classroomTab,
        },
        render: classRoomCoursesTab,
    };

    if (showClassroom && showDigital) {
        return [digital, classroom];
    }

    if (showClassroom) {
        return [classroom];
    }

    if (showDigital) {
        return [digital];
    }

    return [];
}

export function IsEiScheduledToRunWithin72Hours(deliveryDateTime: moment.Moment) {
    const now = moment().utc();
    const seventyTwoHoursFromNow = now.add(72, "hours");
    return deliveryDateTime <= seventyTwoHoursFromNow;
}

