import { createSelector } from "reselect";
import { FilterState } from "./components/delegate-list-filters";
import { AppState, Delegate } from "./model";

export const routeDelegateIdSelector = (state: AppState) => state.router.params.delegateId;
export const routeAttendeeIdSelector = (state: AppState) => state.router.params.attendeeId;

export const delegatesSelector = (state: AppState) => state.delegates;

export const delegateSelector = createSelector(
    delegatesSelector,
    routeDelegateIdSelector,
    routeAttendeeIdSelector,
    (delegates: Delegate[], delegateId: string, attendeeId: string) => {
        return delegateId
            ? delegates.find(c => c.id === delegateId) || {} as any
            : attendeeId
                ? delegates.find(c => c.delegateAttendees.some(a => a.attendeeId === attendeeId))
                : delegates[0] || {} as any;
    }
);

export const basePathSelector = (state: AppState) => {
    if (state.router.pathname.indexOf("profile") !== -1) {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("profile") + "profile".length);
    } else if (state.router.pathname.indexOf("delegates") !== -1)  {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("delegates") + "delegates".length);
    } else {
        return state.router.pathname.substring(0, state.router.pathname.indexOf("search") + "search".length);
    }
};

export const pathWithoutLastPartSelector = (state: AppState) =>
    state.router.pathname.substr(0, state.router.pathname.lastIndexOf("/"));

export const editPathSelector = (state: AppState) => {
    const delegateId = routeDelegateIdSelector(state);
    const startIndex = state.router.pathname.indexOf(delegateId) + delegateId.length;
    const endIndex = state.router.pathname.lastIndexOf("/edit");
    return state.router.pathname.substring(startIndex, endIndex);
};

export const delegateFilterQuerySelector = (state: AppState): FilterState => {
    const organisationId = state.router.params.organisationId;
    const query = state.router.query;

    const { searchString } = query;
    return ({
        organisationId,
        searchString: searchString ?? undefined,
    });
};
