import { EventType, EventTypePart, ModuleType, ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import {
    EventInstance,
    EventInstanceDetailModel,
    EventInstanceEditModel,
    SUBCONTRACTING_WINDOW_IN_DAYS,
    TrainerAvailabilityRoleTypeEnum
} from "@common/crud/eventInstance/model";
import moment from "moment";
import { EventInstanceGroupModel } from "../eventInstanceGroup/model";
import { toast } from "react-toastify";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { TrainerAllocationModel } from "../trainer/model";
import { sessionIntervals, SessionNumber } from "@common/availabilityTrainer/model";
import { defaultDateIfInvalid, isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";
import { Apps } from "@common/model";
import { push } from "redux-little-router";
import { Dispatch } from "redux";
import { PurchaseOrder } from "../organisation/model";
import { CompletionState } from "@common/crud/attendee/model";

export const isDrinkDriveCourse = (eventInstance: EventInstance): boolean => eventInstance.workflowType === WorkflowTypeEnum.DDRS;

export const parseEventInstanceDetailModel = (model: EventInstanceDetailModel): EventInstanceDetailModel => {
    return {
        ...model,
        startDate: model.startDate && moment(model.startDate).utc().startOf("day"),
        eventDuration: model.eventDuration && moment.duration(model.eventDuration),
        educationDuration: model.educationDuration && moment.duration(model.educationDuration),
        startTime: model.startTime && moment.duration(model.startTime),
        actualStartTime: model.actualStartTime && moment.duration(model.actualStartTime),
        actualEndTime: model.actualEndTime && moment.duration(model.actualEndTime),
        theoryDuration: model.theoryDuration && moment.duration(model.theoryDuration),
        theoryStartTime: model.theoryStartTime && moment.duration(model.theoryStartTime),
        practicalDuration: model.practicalDuration && moment.duration(model.practicalDuration),
        practicalStartTime: model.practicalStartTime && moment.duration(model.practicalStartTime),
        registrationEndTime: model.registrationEndTime && moment.duration(model.registrationEndTime),
        publishDate: model.publishDate && moment(model.publishDate),
        deliveryDateTime: model.deliveryDateTime && moment(model.deliveryDateTime).utc(),
        deliveryDateTimeEnd: model.deliveryDateTimeEnd && moment(model.deliveryDateTimeEnd).utc(),
        theoryDeliveryDateTime: model.theoryDeliveryDateTime && moment(model.theoryDeliveryDateTime).utc(),
        theoryDeliveryDateTimeEnd: model.theoryDeliveryDateTimeEnd && moment(model.theoryDeliveryDateTimeEnd).utc(),
        practicalDeliveryDateTime: model.practicalDeliveryDateTime && moment(model.practicalDeliveryDateTime).utc(),
        practicalDeliveryDateTimeEnd: model.practicalDeliveryDateTimeEnd && moment(model.practicalDeliveryDateTimeEnd).utc(),
        attendeesRegisterDate: model.attendeesRegisterDate && moment(model.attendeesRegisterDate),
        stageOneSubmittedDate: model.stageOneSubmittedDate && moment(model.stageOneSubmittedDate),
        breaks: model.breaks?.map(b => ({
            startTime: moment.duration(b.startTime),
            endTime: moment.duration(b.endTime),
        })),
        registerProcessedDate: model.registerProcessedDate && defaultDateIfInvalid(moment(model.registerProcessedDate).utc()),
        certificatesProcessedDate: model.certificatesProcessedDate && defaultDateIfInvalid(moment(model.certificatesProcessedDate).utc()),
        subcontractedTrainers: model.subcontractedTrainers?.map(t => ({
            ...t,
            subcontractingProcess: {
                ...t.subcontractingProcess,
                swapAgreedDate: t.subcontractingProcess?.swapAgreedDate && moment(t.subcontractingProcess.swapAgreedDate)
            }
        })),
        history: model.history?.map(h => ({
            ...h, dateCreated: moment(h.dateCreated),
            pendingDate: h.pendingDate && moment(h.pendingDate),
            completionDate: h.completionDate && moment(h.completionDate)
        })),
        bookingsHistory: model.bookingsHistory?.map(h => ({
            ...h, dateCreated: moment(h.dateCreated), offerExpiry: moment(h.offerExpiry)
        })
        ),
        trainers: model.trainers?.map(t => ({
            ...t,
            dateMadeAvailableForOtherTrainers: t.dateMadeAvailableForOtherTrainers && moment(t.dateMadeAvailableForOtherTrainers)
        })),
        practicalTrainers: model.practicalTrainers?.map(t => ({
            ...t,
            dateMadeAvailableForOtherTrainers: t.dateMadeAvailableForOtherTrainers && moment(t.dateMadeAvailableForOtherTrainers)
        })),
        healthAndSafety: model.healthAndSafety ? {
            ...model.healthAndSafety,
            submittedOn: model.healthAndSafety.submittedOn ? moment(model.healthAndSafety.submittedOn) : undefined
        } : undefined,
    };
};

export const toastMissingNdorsLicenceTrainers=(eventInstance: EventInstance, practical: boolean) => {
    const trainerList = practical? eventInstance.practicalTrainers :eventInstance.trainers;
    const missingLicenseNames =  trainerList.filter(t => !t.ndorsLicenceNumber).map(tr => `${tr.name}`).join(", ");
    if (missingLicenseNames) {
        toast.info(`The following ${practical? "practical": "theory"} trainers do not have licences and cannot be synced:
        ${missingLicenseNames}`);
    }
};

export const getTrainerBackgroundBasedOnAverageDaysWorked = (averageDaysWorked: number) => {
    if (averageDaysWorked >= 10 && averageDaysWorked < 13) {
        return "assign-standby-trainers-column background-pale-amber";
    } else if (averageDaysWorked >= 13) {
        return "assign-standby-trainers-column background-pale-red";
    }
    return "assign-standby-trainers-column background-pale-green";
};

export const mapEIGroupItemsToEventTypeParts = (EiGroup: EventInstanceGroupModel): Dictionary<EventTypePart> => {
    return Object.fromEntries(EiGroup.eventInstanceGroupItems.map((gi) =>
        [
            gi.dayNumber,
            {
                suggestedStartTime: gi.startTime,
                eventDuration: gi.eventDuration,
                registrationDuration: gi.registrationDuration
            }
        ]
    ));
};

export const UpdateEIGroupWithEventTypeParts = (EventTypeParts: Dictionary<EventTypePart>,EIGroup: EventInstanceGroupModel ): EventInstanceGroupModel => {

    const updatedGroupItems = Object.keys(EventTypeParts).map(day => {

        const groupItem = EIGroup.eventInstanceGroupItems.find(gi => gi.dayNumber===+day);
        return {
            ...groupItem,
            startTime: EventTypeParts[day].suggestedStartTime,
            eventDuration: EventTypeParts[day].eventDuration,
            registrationDuration: EventTypeParts[day].registrationDuration
        };
    });

    return { ...EIGroup, eventInstanceGroupItems: updatedGroupItems };

};

export const getUpdatedEventInstancesAndGroup =(eventInstance: EventInstanceEditModel, linkedEIs: EventInstanceEditModel[],
    group: EventInstanceGroupModel) => {
    const updatedGroup = UpdateEIGroupWithEventTypeParts(eventInstance.eventTypeParts, group);
    const updatedEventInstances: EventInstanceEditModel[] = updatedGroup.eventInstanceGroupItems.map((gi) =>

        ({ ...[...linkedEIs][linkedEIs.findIndex(linkedEI => linkedEI.id===gi.eventInstanceId)],
            startDate: gi.startDate, startTime: gi.startTime, registrationEndTime: gi.registrationEndTime, eventDuration: gi.eventDuration,
            registrationDuration: gi.registrationDuration }));

    return { updatedGroup, updatedEventInstances };

};

export const totalDurationEqualsSchemeTotal = (eventInstanceDeliveryType: DeliveryTypeEnum, eventTypeId: string,
    eventTypeParts: Dictionary<EventTypePart>, eventTypes: EventType[]) => {
    const scheme = eventTypes.find(et => et.id === eventTypeId);
    const schemeEventTypeParts = eventInstanceDeliveryType === DeliveryTypeEnum.Onsite?
        scheme.classroomEventTypeDetails.classroomEventTypeParts : scheme.digitalEventTypeDetails.digitalEventTypeParts;
    const getTotalDDRSDuration = (ddrsEventTypeParts: Dictionary<EventTypePart>) =>
        Object.keys(ddrsEventTypeParts).reduce((prev, current) => prev + ddrsEventTypeParts[current].eventDuration.asMinutes(), 0);

    return getTotalDDRSDuration(schemeEventTypeParts) === getTotalDDRSDuration(eventTypeParts);
};

export const GetNumberOfAttendeesWithSpecialRequirements = (eventInstance: EventInstance): number => (
    eventInstance?.specialRequirementAttendees
        ?.map(attendee => attendee.specialRequirements)
        .filter(sr => sr?.eventAttributes?.length || sr?.venueAttributes?.length || sr?.otherHearingRequirements || sr?.otherRequirements)
        .length ?? 0
);

export const GetNumberOfNotes = (eventInstance: EventInstance, app: Apps): number => {
    let numberOfNotes = 0;

    if (eventInstance && eventInstance?.adminNote) {
        numberOfNotes += 1;
    }

    if (eventInstance && eventInstance?.trainerNote) {
        numberOfNotes += 1;
    }

    if (app === Apps.Trainer) {
        return numberOfNotes;
    }

    if (eventInstance && eventInstance?.officeNote) {
        numberOfNotes += 1;
    }

    if (eventInstance && eventInstance?.bookingNoteEn) {
        numberOfNotes += 1;
    }

    if (eventInstance && eventInstance?.bookingNoteCy) {
        numberOfNotes += 1;
    }

    return numberOfNotes;
};

export function configureSubcontractingOnNewTrainer(
    newTrainer: TrainerAllocationModel,
    originalTrainerId: string,
    eventInstance: EventInstance,
    swapAgreedDate: moment.Moment) {
    const startSubcontractingProcess = swapAgreedDate.isSameOrAfter(moment(eventInstance.startDate).subtract(SUBCONTRACTING_WINDOW_IN_DAYS, "days"));

    if (startSubcontractingProcess && !newTrainer.subcontractingProcess) {
        newTrainer.subcontractingProcess = {
            isActive: true,
            originalTrainerId,
            newTrainerId: newTrainer.id,
            swapAgreedDate
        };
    } else if (startSubcontractingProcess && !newTrainer.subcontractingProcess?.swapAgreedDate.isSame(swapAgreedDate)) {
        newTrainer.subcontractingProcess.swapAgreedDate = swapAgreedDate;
    } else if (!startSubcontractingProcess && newTrainer.subcontractingProcess) {
        newTrainer.subcontractingProcess = null;
    }
};

export const setDeliveryTimesForTrainer = (eventInstance: EventInstance, trainerId: string) => {
    if (eventInstance.moduleType?.toString() !== ModuleTypeEnum.Both.toString()) {
        return eventInstance;
    }
    const trainerIsTheory = eventInstance.trainerIds.includes(trainerId);
    const trainerIsPractical = eventInstance.practicalTrainers?.findIndex(t => t.id === trainerId) !== -1;
    if (trainerIsTheory && trainerIsPractical) {
        return eventInstance;
    }

    if (trainerIsTheory) {
        eventInstance.startTime = eventInstance.theoryStartTime;
        eventInstance.eventDuration = eventInstance.theoryDuration;
    }

    if (trainerIsPractical) {
        eventInstance.startTime = eventInstance.practicalStartTime;
        eventInstance.eventDuration = eventInstance.practicalDuration;
    }

    return eventInstance;
};

export const eventOverlapsWithSession = (event: EventInstance, sessionNumber: SessionNumber) => {
    const [sessionStart, sessionEnd] = sessionIntervals[sessionNumber];
    const eventStart = Math.floor(event.startTime.asHours());
    const eventEnd = Math.floor(event.startTime?.clone().add(event.eventDuration).asHours());

    return eventStart < sessionEnd && eventEnd > sessionStart;
};

export const RedirectTrainerToCoursesPageInTrainerApp = (status: number, app: Apps, pathName: string, dispatch: Dispatch) => {
    if (status === 404 && app === Apps.Trainer) {
        toast.error("Course not found.");
        const pathParts = pathName.split("/");
        dispatch(push(`${(pathParts.length > 1 ? (!isNullOrUndefinedOrEmptyString(pathParts[0]) ? `/${pathParts[0]}` : `/${pathParts[1]}`) : "/" ) }`));
    }
};

export const ChangeToTitleCase = (value: string) => value.replace(/\w+/g, (v) => v.charAt(0).toUpperCase() + v.slice(1));

export const filterAndSortPurchaseOrders = (purchaseOrders: PurchaseOrder[], eventInstanceId: string, eventInstanceStartDate: moment.Moment) => {
    return purchaseOrders
        .filter(po =>
            (po.valueRemaining > 0 || po.eventInstanceUses?.[eventInstanceId]) &&
            po.startDate <= eventInstanceStartDate &&
            (!po.endDate || po.endDate >= eventInstanceStartDate))
        .sort((a, b) => moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf());
};

export function GetEventInstanceDateDisplay(e: EventInstance) {
    const startDate = e.startDate.startOf("day").clone();

    if ((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
        && e?.role === TrainerAvailabilityRoleTypeEnum.Practical) {
        const practicalStartDateTime = startDate.add(e.practicalStartTime);
        return practicalStartDateTime.format("ddd DD/MM/YYYY HH:mm");
    }

    if ((e.moduleType.toString() === ModuleTypeEnum.Both.toString() || e.moduleType.toString() === ModuleType[ModuleTypeEnum.Both])
        && e?.role === TrainerAvailabilityRoleTypeEnum.Theory) {
        const theoryStartDateTime = startDate.add(e.theoryStartTime);
        return theoryStartDateTime.format("ddd DD/MM/YYYY HH:mm");
    }

    const startDateTime = startDate.add(e.startTime);

    return startDateTime.format("ddd DD/MM/YYYY HH:mm");
}

export function getBookingItemStateByCompletionState(isBookingCancelled?: boolean, completed?: CompletionState) {
    return getBookingItemState(isBookingCancelled, completed === CompletionState.Completed);
}

export function getBookingItemState(isBookingCancelled?: boolean, completed?: boolean) {
    return isBookingCancelled ? "CANCELLED"
        : completed
            ? "COMPLETED"
            : "BOOKED";
}

export function getStateOfBookingWithCancellationDate(isBookingCancelled?: boolean, isCourseCancelled?: boolean, completed?: boolean) {
    return isBookingCancelled
        ? "BOOKING CANCELLED"
        : getBookingItemState(isCourseCancelled, completed);
};
