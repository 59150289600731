/* eslint-disable max-lines */
import { Location } from "redux-little-router";
import moment from "moment";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { HistoryRecord } from "@common/history/model";
import { CompanyTypeEnum, CountryEnum, SchemeDeliveryTypeEnum } from "../organisation/model";
import { KeysMatching } from "@common/helpers/type-helpers";
import { includes } from "../eventType/helpers";
import { optionsFromObject } from "../common";
import { omit, pick } from "lodash";
import { BusinessLineType } from "@common/redux-helpers";
import { ObjectEntries, ObjectKeys } from "@common/helpers/typedObjectMethods";
import { ValidationResult } from "not-valid";

export interface EventTypePart {
    suggestedStartTime: moment.Duration;
    eventDuration: moment.Duration;
    registrationDuration: moment.Duration;
}

export interface ValueWithEffectiveDate<T> {
    value: T;
    effectiveDate: moment.Moment;
}

export enum MultiDayTypeEnum {
    None = 0,
    Consecutive = 1,
    Weekly = 2,
}

export const MultiDayType = {
    [MultiDayTypeEnum.None]: "None",
    [MultiDayTypeEnum.Consecutive]: "Consecutive",
    [MultiDayTypeEnum.Weekly]: "Weekly",
};

export interface ClassroomEventTypeDetails {
    maxNumberOfAttendeesWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    maxNumberOfAttendeesPerTrainerWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    termsAndConditionsLinkEn?: string;
    termsAndConditionsLinkCy?: string;
    defaultNumberOfClassroomDeliveryDays?: number;
    defaultMultiDayType?: MultiDayTypeEnum;
    classroomEventTypeParts?: Dictionary<EventTypePart>;
    suggestedStartTimesForSessions?:  Dictionary<moment.Duration[]>;
    suggestedStartTimesForTheorySessions?:  Dictionary<moment.Duration[]>;
    suggestedStartTimesForPracticalSessions?:  Dictionary<moment.Duration[]>;
    trainerFees?: TrainerFee[];
    whitespaceHeight?: number;
    bookingConfirmationLetterOpeningEn?: string;
    bookingConfirmationLetterOpeningCy?: string;
    bookingConfirmationLetterClosingEn?: string;
    bookingConfirmationLetterClosingCy?: string;
    eventDurations?: ValueWithEffectiveDate<moment.Duration>[];
    educationDurations?: ValueWithEffectiveDate<moment.Duration>[];
    classroomRegistrationDurations?: ValueWithEffectiveDate<moment.Duration>[];
    practicalEventDurations?: ValueWithEffectiveDate<moment.Duration>[];
    theoryEventDurations?: ValueWithEffectiveDate<moment.Duration>[];
    practicalTrainerFees?: TrainerFee[];
    classroomOtherTrainerFees?: OtherTrainerFee[];
    classroomBreakEvenPoints?: ValueWithEffectiveDate<number>[];
}

export interface DigitalEventTypeDetails {
    digitalTrainerAttributeId?: string;
    maxNumberOfDigitalAttendeesWithDuration?: ValueWithEffectiveDate<number>[];
    maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    digitalTermsAndConditionsLinkEn?: string;
    digitalTermsAndConditionsLinkCy?: string;
    defaultNumberOfDigitalDeliveryDays?: number;
    defaultMultiDayType?: MultiDayTypeEnum;
    digitalEventTypeParts?: Dictionary<EventTypePart>;
    digitalEventSuggestedStartTimesForSessions?: Dictionary<moment.Duration[]>;
    digitalTrainerFees?: TrainerFee[];
    digitalWhitespaceHeight?: number;
    digitalBookingConfirmationLetterOpeningEn?: string;
    digitalBookingConfirmationLetterOpeningCy?: string;
    digitalBookingConfirmationLetterClosingEn?: string;
    digitalBookingConfirmationLetterClosingCy?: string;
    availableInDigitalPlanning?: boolean;
    spreadingHeadroomWeekend?: number;
    spreadingHeadroomWeekday?: number;
    eventDurations?: ValueWithEffectiveDate<moment.Duration>[];
    educationDurations?: ValueWithEffectiveDate<moment.Duration>[];
    digitalRegistrationDurations?: ValueWithEffectiveDate<moment.Duration>[];
    sessionsIncludedInDigitalCalendarAutoAdd?: SessionsIncludedInDigitalCalendarAutoAdd;
    digitalOtherTrainerFees?: OtherTrainerFee[];
    digitalBreakEvenPoints?: ValueWithEffectiveDate<number>[];
}

export interface OpenCourseFee {
    fee: number;
    weekendSupplement: number;
}

export interface CourseFeesEventTypeDetails {
    digitalEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    classroomEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    closedDigitalEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    closedClassroomEIFeeWithEffectiveDate?: ValueWithEffectiveDate<number>[];
    openDigitalEIFeeWithEffectiveDate?: ValueWithEffectiveDate<OpenCourseFee>[];
    openClassroomEIFeeWithEffectiveDate?: ValueWithEffectiveDate<OpenCourseFee>[];
    cpcUploadFeeRequired?: boolean;
    cpcUploadFee?: ValueWithEffectiveDate<number>[];
}

export interface EventTypeListModel {
    readonly id: string;
    readonly name?: string;
    readonly nameCy?: string;
    readonly abbreviation?: string;
    readonly maxNumberOfAttendees?: number;
    readonly moduleType?: number;
    readonly durationType?: number;
    readonly listUpdated?: moment.Moment;
    readonly workflowType?: AllowedWorkflowTypeEnum;
    readonly bookingType?: number;
    readonly productCategory?: number;
    readonly dorsId?: number;
    readonly trainerUpliftFees?: FeeWithEffectiveDate[];
    readonly monitorAttributeId?: string;
    readonly introMessageEnglish?: string;
    readonly introMessageWelsh?: string;
    readonly flexiPayEnabled?: boolean;
    readonly flexiPayFees?: FeeWithEffectiveDate[];
    readonly ddrsCourseFees?: DdrsAllCountriesFeeConfig;
    readonly currentDorsScheme?: boolean;
    readonly rebookingFees?: RebookingFees[];
    readonly defaultRebookingFee?: RebookingFees;
    readonly monitorFees?: MonitorFee[];
    readonly mandatoryCorpCertificate?: number;
    readonly optionalCorpCertificate?: number;
    readonly expiryDate?: moment.Moment;
    readonly internalId?: string;
    readonly internalIdDigital?: string;
    readonly courseApprovalNumber?: string;
    readonly courseCategories?: number[];
    readonly resellerCourse?: boolean;
}

export interface EventTypeDetailModel {
    readonly id: string;
    readonly name?: string;
    readonly nameCy?: string;
    readonly abbreviation?: string;
    readonly dorsId?: number;
    readonly maxNumberOfAttendeesPerPracticalTrainer?: number;
    readonly moduleType?: ModuleTypeEnum;
    readonly durationType?: DurationTypeEnum;
    readonly workflowType?: AllowedWorkflowTypeEnum;
    readonly bookingType?: Exclude<BookingTypeEnum, BookingTypeEnum.Any>;
    readonly productCategory?: Exclude<ProductCategoryEnum, ProductCategoryEnum.Any>;
    readonly mandatoryCorpCertificate?: CorporateCertificateTypeEnum;
    readonly optionalCorpCertificate?: CorporateCertificateTypeEnum;
    readonly detailUpdated?: moment.Moment;
    readonly theoryTrainerAttributeId?: string;
    readonly practicalTrainerAttributeId?: string;
    readonly manualCarPracticalTrainerAttributeId?: string;
    readonly automaticCarPracticalTrainerAttributeId?: string;
    readonly noteCy?: string;
    readonly noteEn?: string;
    readonly productNote?: string;
    readonly theoryComplianceAttributeIds?: string[];
    readonly practicalComplianceAttributeIds?: string[];
    readonly disableAutoAllocateIfTrainerNonCompliant?: boolean;
    readonly trainerUpliftFees?: FeeWithEffectiveDate[];
    readonly monitorAttributeId?: string;
    readonly showCarType?: boolean;
    readonly amendableBookingAppTexts?: Dictionary<string>;
    readonly introMessageEnglish?: string;
    readonly introMessageWelsh?: string;
    readonly surveyLink?: string;
    readonly surveyLinkCy?: string;
    readonly secondSurveyLink?: string;
    readonly secondSurveyLinkCy?: string;
    readonly bookingInformationLinkClassroom?: string;
    readonly bookingInformationLinkClassroomCy?: string;
    readonly bookingInformationLinkDigital?: string;
    readonly bookingInformationLinkDigitalCy?: string;
    readonly firstInviteLetterOpening?: string;
    readonly firstInviteLetterClosing?: string;
    readonly reminderToBookLetterOpening?: string;
    readonly reminderToBookLetterClosing?: string;
    readonly classroomEventTypeDetails?: ClassroomEventTypeDetails;
    readonly digitalEventTypeDetails?: DigitalEventTypeDetails;
    readonly courseFeesEventTypeDetails?: CourseFeesEventTypeDetails;
    readonly history?: HistoryRecord[];
    readonly flexiPayEnabled?: boolean;
    readonly flexiPayFees?: FeeWithEffectiveDate[];
    readonly ddrsCourseFees?: DdrsAllCountriesFeeConfig;
    readonly flexiPayInstructionsEn?: string;
    readonly flexiPayInstructionsCy?: string;
    readonly deliveryType?: SchemeDeliveryTypeEnum;
    readonly currentDorsScheme?: boolean;
    readonly rebookingFees?: RebookingFees[];
    readonly defaultRebookingFee?: RebookingFees;
    readonly completionLetterWordingEn?: string;
    readonly nonCompletionLetterWordingEn?: string;
    readonly monitorFees?: MonitorFee[];
    readonly welshTrainerAttributeId?: string;
    readonly productIntro?: string;
    readonly productDescription?: string;
    readonly showInOpenBookingApp?: boolean;
    readonly expiryDate?: moment.Moment;
    readonly internalId?: string;
    readonly internalIdDigital?: string;
    readonly courseApprovalNumber?: string;
    readonly competencyReportRequired?: boolean;
    readonly competencyReportLink?: string;
    readonly competencyReportEnabledDate?: moment.Moment;
    readonly onRoadReportRequired?: boolean;
    readonly delegatesRequired?: boolean;
    readonly flexibleCertificatesRequired?: boolean;
    readonly certificateRequired?: boolean;
    readonly courseCategories?: number[];
    readonly resellerCourse?: boolean;
}

export type EventType = EventTypeListModel & EventTypeDetailModel;

export interface CreateFieldPickerProps<T> {
    field: T;
    setField: React.Dispatch<React.SetStateAction<T>> | ((newValue: T, isValid: boolean) => void);
    showErrors: boolean;
    label?: string;
    validation?: ((value: T) => ValidationResult)[];
}

export interface EventTypeCreateEditModel {
    id: string;
    name: string;
    nameCy?: string;
    abbreviation: string;
    maxNumberOfAttendeesPerPracticalTrainer: number;
    dorsId?: number;
    moduleType: ModuleTypeEnum;
    durationType: DurationTypeEnum;
    workflowType: number;
    bookingType?: number;
    productCategory?: number;
    mandatoryCorpCertificate?: number;
    optionalCorpCertificate?: number;
    theoryTrainerAttributeId: string;
    practicalTrainerAttributeId: string;
    manualCarPracticalTrainerAttributeId: string;
    automaticCarPracticalTrainerAttributeId: string;
    theoryComplianceAttributeIds?: string[];
    practicalComplianceAttributeIds?: string[];
    disableAutoAllocateIfTrainerNonCompliant?: boolean;
    noteCy: string;
    noteEn: string;
    productNote?: string;
    trainerUpliftFees?: FeeWithEffectiveDate[];
    monitorAttributeId?: string;
    showCarType?: boolean;
    amendableBookingAppTexts?: Dictionary<string>;
    introMessageEnglish?: string;
    introMessageWelsh?: string;
    surveyLink?: string;
    surveyLinkCy?: string;
    secondSurveyLink?: string;
    secondSurveyLinkCy?: string;
    bookingInformationLinkClassroom?: string;
    bookingInformationLinkClassroomCy?: string;
    bookingInformationLinkDigital?: string;
    bookingInformationLinkDigitalCy?: string;
    firstInviteLetterOpening?: string;
    firstInviteLetterClosing?: string;
    reminderToBookLetterOpening?: string;
    reminderToBookLetterClosing?: string;
    flexiPayEnabled?: boolean;
    flexiPayFees?: FeeWithEffectiveDate[];
    ddrsCourseFees?: DdrsAllCountriesFeeConfig;
    flexiPayInstructionsEn?: string;
    flexiPayInstructionsCy?: string;
    readonly classroomEventTypeDetails?: ClassroomEventTypeDetails;
    readonly digitalEventTypeDetails?: DigitalEventTypeDetails;
    readonly courseFeesEventTypeDetails?: CourseFeesEventTypeDetails;
    deliveryType?: SchemeDeliveryTypeEnum;
    readonly currentDorsScheme?: boolean;
    hasRebookingFees?: boolean;
    rebookingFees?: RebookingFees[];
    completionLetterWordingEn?: string;
    nonCompletionLetterWordingEn?: string;
    monitorFees?: MonitorFee[];
    welshTrainerAttributeId?: string;
    productIntro?: string;
    productDescription?: string;
    showInOpenBookingApp?: boolean;
    expiryDate?: moment.Moment;
    internalId?: string;
    internalIdDigital?: string;
    courseApprovalNumber?: string;
    competencyReportRequired?: boolean;
    competencyReportLink?: string;
    onRoadReportRequired?: boolean;
    delegatesRequired?: boolean;
    flexibleCertificatesRequired?: boolean;
    certificateRequired?: boolean;
    courseCategories?: number[];
    resellerCourse?: boolean;
}

export interface EventTypeFile {
    id: string;
    documentName: string;
    documentDescription: string;
    uploadedFile?: File;
    startDate: moment.Moment;
    endDate?: moment.Moment;
    blobName?: string;
    fileName?: string;
}

export interface EventTypeFileResponse {
    files: EventTypeFile[];
    error: string;
}

export interface RebookingFees {
    feeAfter14Days: number;
    feeCloseToCourseDate: number;
    effectiveDate: moment.Moment;
}

export const GetDurationWithEffectiveDateFromEventType = (startDate: moment.Moment,
    deliveryType: DeliveryTypeEnum, eventType: EventType,
    durationKey:
    KeysMatching<DigitalEventTypeDetails, ValueWithEffectiveDate<moment.Duration>[]> &
    KeysMatching<ClassroomEventTypeDetails, ValueWithEffectiveDate<moment.Duration>[]>) => {
    const durations: ValueWithEffectiveDate<moment.Duration>[] = deliveryType === DeliveryTypeEnum.Digital ? eventType?.digitalEventTypeDetails?.[durationKey]
        : eventType?.classroomEventTypeDetails?.[durationKey];

    return GetValueFromValueWithEffectiveDate(durations, startDate);
};

export const GetEventDuration = (startDate: moment.Moment, deliveryType: DeliveryTypeEnum, eventType: EventType) => {
    return GetDurationWithEffectiveDateFromEventType(startDate, deliveryType, eventType, "eventDurations");
};

export const GetEducationDuration = (startDate: moment.Moment, deliveryType: DeliveryTypeEnum, eventType: EventType) => {
    return GetDurationWithEffectiveDateFromEventType(startDate, deliveryType, eventType, "educationDurations");
};

export const GetTheoryAndPracticalEventDurations = (startDate: moment.Moment, eventType: EventType) => {

    const theoryDurations = GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails?.theoryEventDurations, startDate);
    const practicalDurations = GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails?.practicalEventDurations, startDate);

    return { theoryDurations, practicalDurations };
};

const GetValueFromValueWithEffectiveDate = <T>(values: ValueWithEffectiveDate<T>[], startDate: moment.Moment): T => {
    const order = values?.filter(d => d.effectiveDate <= startDate)
        .sort((a, b) => b.effectiveDate.diff(a.effectiveDate));
    if (order?.length > 0) {
        return order[0].value;
    }
    return null;
};

export const GetEventMaxDigitalAttendees = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.digitalEventTypeDetails.maxNumberOfDigitalAttendeesWithDuration ?? [], startDate);
};

export const GetEventMaxAttendeesPerDigitalTrainer = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.digitalEventTypeDetails.maxNumberOfAttendeesPerDigitalTheoryTrainerWithEffectiveDate ?? [], startDate);
};

export const GetEventMaxClassroomAttendees = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.maxNumberOfAttendeesWithEffectiveDate ?? [], startDate);
};

export const GetEventMaxAttendeesPerClassroomTrainer = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.maxNumberOfAttendeesPerTrainerWithEffectiveDate ?? [], startDate);
};

export const GetEventClassroomRegistrationDuration = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.classroomRegistrationDurations, startDate);
};

export const GetEventDigitalRegistrationDuration = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.digitalEventTypeDetails.digitalRegistrationDurations, startDate);
};

export const GetEventTheoryDuration = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.theoryEventDurations, startDate);
};

export const GetEventPracticalDuration = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.practicalEventDurations, startDate);
};

export const GetEventClassroomBreakEvenPoints = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.classroomEventTypeDetails.classroomBreakEvenPoints ?? [], startDate);
};

export const GetEventDigitalBreakEvenPoints = (startDate: moment.Moment, eventType: EventType) => {
    return GetValueFromValueWithEffectiveDate(eventType?.digitalEventTypeDetails.digitalBreakEvenPoints ?? [], startDate);
};

export const GetEventRegistrationEndTime = (startTime: moment.Duration, registrationDuration: moment.Duration) => {
    const start = startTime?.clone();
    const duration = registrationDuration?.clone();
    let registrationEndTime = start?.add(duration);

    if (registrationEndTime?.asHours() > 24) {
        registrationEndTime = registrationEndTime.subtract(1, "day");
    }

    return registrationEndTime;
};

export interface TrainerFeeBase {
    weekday: number;
    saturday: number;
    sunday: number;
    evening: number;
    welsh: number;
}

export interface TrainerFee extends TrainerFeeBase {
    id?: string;
    singleTrainer: number;
    tieredBonuses?: TrainerTieredBonus[];
    effectiveDate: moment.Moment;
}

export interface OtherTrainerFeeBase {
    mentor: number;
    mentee: number;
    firstCourseSupport: number;
    observer: number;
}

export interface OtherTrainerFee extends OtherTrainerFeeBase {
    id?: string;
    tieredBonuses?: OtherTrainerTieredBonus[];
    effectiveDate: moment.Moment;
}

export interface OtherTrainerTieredBonus {
    tierNumber: number;
    amount: number;
}

export interface MonitorFeeBase {
    monitor: number;
}

export interface MonitorFee extends MonitorFeeBase {
    effectiveDate: moment.Moment;
}

export interface BonusBase {
    threshold: number;
    amount: number;
}
export interface TrainerTieredBonus extends BonusBase {
    tierNumber: number;
}

export interface DdrsFeeConfig {
discountType: DiscountTypeEnum;
weekday: number;
concessions: number|null;
earlyBird: number|null;
concessionsAndEarlyBird: number|null;
weekendSupplement: number|null;
}

export interface DdrsAllCountriesFeeConfig {
    [CountryEnum.England]?: ValueWithEffectiveDate<DdrsFeeConfig>[];
    [CountryEnum.Wales]?: ValueWithEffectiveDate<DdrsFeeConfig>[];
    [CountryEnum.Scotland]?: ValueWithEffectiveDate<DdrsFeeConfig>[];
    [CountryEnum.NIreland]?: ValueWithEffectiveDate<DdrsFeeConfig>[];
}

export type ddrsFeeValueAndDateArray = ValueWithEffectiveDate<DdrsFeeConfig>[]

export interface FeeWithEffectiveDate {
    fee: number;
    effectiveDate: moment.Moment;
}

export type TrainerFeeWithoutEffectiveDate = Omit<TrainerFee, "effectiveDate">

export interface EventTypeState {
    readonly eventTypes: EventType[];
}

export interface SessionsIncludedInDigitalCalendarAutoAdd {
    readonly monday: number[];
    readonly tuesday: number[];
    readonly wednesday: number[];
    readonly thursday: number[];
    readonly friday: number[];
    readonly saturday: number[];
    readonly sunday: number[];
}

export const InitialiseEventTypeParts = (deliveryType: DeliveryTypeEnum): Dictionary<EventTypePart> => {
    const classroomEventTypeParts: Dictionary<EventTypePart> = {};
    classroomEventTypeParts[1] = {
        suggestedStartTime: moment.duration("12:30:00"),
        eventDuration: moment.duration("04:30:00"),
        registrationDuration: moment.duration("00:15:00")
    };
    classroomEventTypeParts[2] = {
        suggestedStartTime: moment.duration("09:45:00"),
        eventDuration: moment.duration("07:15:00"),
        registrationDuration: moment.duration("00:15:00")
    };
    classroomEventTypeParts[3] = {
        suggestedStartTime: moment.duration("09:45:00"),
        eventDuration: moment.duration("07:15:00"),
        registrationDuration: moment.duration("00:15:00")
    };

    const digitalEventTypeParts: Dictionary<EventTypePart> = {};
    digitalEventTypeParts[1] = {
        suggestedStartTime: moment.duration("08:30:00"),
        eventDuration: moment.duration("04:45:00"),
        registrationDuration: moment.duration("00:15:00")
    };
    digitalEventTypeParts[2] = {
        suggestedStartTime: moment.duration("08:45:00"),
        eventDuration: moment.duration("04:30:00"),
        registrationDuration: moment.duration("00:15:00")
    };
    digitalEventTypeParts[3] = {
        suggestedStartTime: moment.duration("08:45:00"),
        eventDuration: moment.duration("04:30:00"),
        registrationDuration: moment.duration("00:15:00")
    };
    digitalEventTypeParts[4] = {
        suggestedStartTime: moment.duration("08:45:00"),
        eventDuration: moment.duration("04:30:00"),
        registrationDuration: moment.duration("00:15:00")
    };

    return deliveryType === DeliveryTypeEnum.Onsite ?
        classroomEventTypeParts :
        digitalEventTypeParts;
};

export const InitialiseEventTypePartsBasedOnDefaultNumberOfDeliveryDays = (existingEventTypeParts: Dictionary<EventTypePart>,
    defaultNumberOfDeliveryDays: number): Dictionary<EventTypePart> => {
    return Array.from({ length: defaultNumberOfDeliveryDays }, (_, i) => i + 1)
        .reduce((acc: Dictionary<EventTypePart>, key: number) => {
            acc[key] = existingEventTypeParts?.[key] ?? {
                suggestedStartTime: moment.duration("08:00:00"),
                eventDuration: moment.duration("08:00:00"),
                registrationDuration: moment.duration("00:15:00")
            };
            return acc;
        }, {});
};

export const InitialiseGroupItemStartDates = (start: moment.Moment, multiDayType: MultiDayTypeEnum,
    eventTypeParts: Dictionary<EventTypePart>): Dictionary<moment.Moment> => {
    return Object.keys(eventTypeParts).reduce((acc, key, index) => {
        acc[key] = start.clone().add(index, multiDayType === MultiDayTypeEnum.Consecutive ? "days" : "weeks");
        return acc;
    }, {});
};

export const dorsPropName = "classroomEventTypeDetails.suggestedStartTimesForSessions";
export const dorsTheoryPropName = "classroomEventTypeDetails.suggestedStartTimesForTheorySessions";
export const dorsPracticalPropName = "classroomEventTypeDetails.suggestedStartTimesForPracticalSessions";
export const ddrsClassroomPropName = "classroomEventTypeDetails.classroomEventTypeParts";
export const ddrsDigitalPropName = "digitalEventTypeDetails.digitalEventTypeParts";

export class EventTypeBuilder {
    private id: string;
    private name: string;
    private nameCy: string;
    private abbreviation: string;
    private maxNumberOfAttendeesPerPracticalTrainer: number;
    private dorsId?: number;
    private moduleType: ModuleTypeEnum;
    private durationType: DurationTypeEnum;
    private workflowType: number;
    private theoryTrainerAttributeId: string = "";
    private practicalTrainerAttributeId: string = "";
    private manualCarPracticalTrainerAttributeId: string = "";
    private automaticCarPracticalTrainerAttributeId: string = "";
    private noteCy: string = "";
    private noteEn: string = "";
    private theoryComplianceAttributeIds: string[] = [];
    private practicalComplianceAttributeIds: string[] = [];
    private amendableBookingAppTexts?: Dictionary<string> = null;
    private showCarType?: boolean;
    private classroomEventTypeDetails: ClassroomEventTypeDetails;
    private digitalEventTypeDetails: DigitalEventTypeDetails;
    private rebookingFees: RebookingFees[];

    constructor() {
        this.name = "Test 1";
        this.nameCy = "Test 1 (Cy)";
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.classroomEventTypeDetails = { } as ClassroomEventTypeDetails;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.digitalEventTypeDetails = { } as DigitalEventTypeDetails;
        this.dorsId = undefined;
        this.moduleType = 1;
        this.workflowType = 0;
        this.classroomEventTypeDetails.suggestedStartTimesForSessions = {
            1: [moment.duration("3:00")],
            2: [moment.duration("3:00"), moment.duration("6:00")],
            3: [moment.duration("3:00"), moment.duration("6:00"), moment.duration("9:00")],
        };

        this.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions = {
            1: [moment.duration("4:00")],
            2: [moment.duration("4:00"), moment.duration("7:00")],
        };
    }

    public withId(id: string): EventTypeBuilder {
        this.id = id;
        return this;
    }

    public withname(name: string): EventTypeBuilder {
        this.name = name;
        return this;
    }

    public withnameCy(nameCy: string): EventTypeBuilder {
        this.nameCy = nameCy;
        return this;
    }

    public withmaxNumberOfAttendeesPerPracticalTrainer(maxNumberOfAttendeesPerPracticalTrainer: number): EventTypeBuilder {
        this.maxNumberOfAttendeesPerPracticalTrainer = maxNumberOfAttendeesPerPracticalTrainer;
        return this;
    }

    public withModuleType(moduleType: ModuleTypeEnum): EventTypeBuilder {
        this.moduleType = moduleType;
        return this;
    }

    public withDorsId(dorsId: number): EventTypeBuilder {
        this.dorsId = dorsId;
        return this;
    }

    public withWorkflowType(workflowType: number): EventTypeBuilder {
        this.workflowType = workflowType;
        return this;
    }

    public withTheoryTrainerAttributeId(theoryTrainerAttributeId: string) {
        this.theoryTrainerAttributeId = theoryTrainerAttributeId;
        return this;
    }

    public withPracticalTrainerAttributeId(practicalTrainerAttributeId: string) {
        this.practicalTrainerAttributeId = practicalTrainerAttributeId;
        return this;
    }

    public withManualCarPracticalTrainerAttributeId(manualCarPracticalTrainerAttributeId: string) {
        this.manualCarPracticalTrainerAttributeId = manualCarPracticalTrainerAttributeId;
        return this;
    }

    public withAutomaticCarPracticalTrainerAttributeId(automaticCarPracticalTrainerAttributeId: string) {
        this.automaticCarPracticalTrainerAttributeId = automaticCarPracticalTrainerAttributeId;
        return this;
    }

    public withDigitalTrainerAttributeId(digitalTrainerAttributeId: string) {
        this.digitalEventTypeDetails.digitalTrainerAttributeId = digitalTrainerAttributeId;
        return this;
    }

    public withAbbreviation(abbreviation: string) {
        this.abbreviation = abbreviation;
        return this;
    }

    public withAmendableBookingAppTexts(texts: Dictionary<string>) {
        this.amendableBookingAppTexts = texts;
        return this;
    }

    public withShowCarType(showCarType: boolean) {
        this.showCarType = showCarType;
        return this;
    }

    public build(): EventTypeCreateEditModel {
        return {
            id: this.id,
            name: this.name,
            nameCy: this.nameCy,
            classroomEventTypeDetails: this.classroomEventTypeDetails,
            digitalEventTypeDetails: this.digitalEventTypeDetails,
            dorsId: this.dorsId,
            moduleType: this.moduleType,
            durationType: this.durationType,
            workflowType: this.workflowType,
            theoryTrainerAttributeId: this.theoryTrainerAttributeId,
            practicalTrainerAttributeId: this.practicalTrainerAttributeId,
            manualCarPracticalTrainerAttributeId: this.manualCarPracticalTrainerAttributeId,
            automaticCarPracticalTrainerAttributeId: this.automaticCarPracticalTrainerAttributeId,
            theoryComplianceAttributeIds: this.theoryComplianceAttributeIds,
            practicalComplianceAttributeIds: this.practicalComplianceAttributeIds,
            maxNumberOfAttendeesPerPracticalTrainer: this.maxNumberOfAttendeesPerPracticalTrainer,
            noteCy: this.noteCy,
            noteEn: this.noteEn,
            abbreviation: this.abbreviation,
            trainerUpliftFees: [],
            amendableBookingAppTexts: this.amendableBookingAppTexts,
            showCarType: this.showCarType,
            deliveryType: SchemeDeliveryTypeEnum.ClassroomAndDigital,
            rebookingFees: this.rebookingFees
        };
    }
}

export enum ModuleTypeEnum {
    None = 0,
    Theory = 1,
    Practical = 2,
    Both = 3
}

export const ModuleType = {
    [ModuleTypeEnum.None]: "None",
    [ModuleTypeEnum.Theory]: "Theory",
    [ModuleTypeEnum.Practical]: "Practical",
    [ModuleTypeEnum.Both]: "Both"
};

export enum DurationTypeEnum {
    None = 0,
    SeparateTheoryPractical = 1,
    Combined = 2
}

export const DurationType = {
    [DurationTypeEnum.None]: "None",
    [DurationTypeEnum.SeparateTheoryPractical]: "Separate Theory/Practical",
    [DurationTypeEnum.Combined]: "Combined",
};

export enum BookingTypeEnum {
    Any = -1,
    None = 0,
    Open = 1,
    Closed = 2,
    Both = 3,
}

export const EventInstanceBookingType = {
    [BookingTypeEnum.Open]: "Open",
    [BookingTypeEnum.Closed]: "Closed",
};

export const BookingType = {
    [BookingTypeEnum.None]: "None",
    [BookingTypeEnum.Open]: "Open",
    [BookingTypeEnum.Closed]: "Closed",
    [BookingTypeEnum.Both]: "Both"
};

export enum CertificateTypeEnum {
    None = 0,
    MandatoryCpcWithoutOptionalFors = 1,
    MandatoryForsWithOptionalCpc = 2,
    MandatoryCpcWithOptionalFors = 3,
    MandatoryForsWithoutOptionalCpc = 4,
    OrganisationOrDelegateChoice = 5,
}

export const CertificateType = {
    [CertificateTypeEnum.None]: "None",
    [CertificateTypeEnum.MandatoryCpcWithoutOptionalFors]: "Mandatory CPC without optional FORS",
    [CertificateTypeEnum.MandatoryForsWithOptionalCpc]: "Mandatory FORS with optional CPC",
    [CertificateTypeEnum.MandatoryCpcWithOptionalFors]: "Mandatory CPC with optional FORS",
    [CertificateTypeEnum.MandatoryForsWithoutOptionalCpc]: "Mandatory FORS without optional CPC",
    [CertificateTypeEnum.OrganisationOrDelegateChoice]: "Organisation or delegate choice",
};

export const BookingAppTextKeys = {
    EnglishCarTypeNote: "EN_CAR_TYPE_NOTE",
    CymraegCarTypeNote: "CY_CAR_TYPE_NOTE",
};

export enum WorkflowTypeEnum {
    Any = -1,
    None = 0,
    General = 1,
    Dors = 2,
    DDRS = 4,
    CPC = 5,
    Workshop = 6,
    OnRoad = 7,
    BusinessDriver = 8,
    ConstructionStandard = 9,
    CITB = 10,
    APS = 11,
}

export type BusinessHomeRouteName = "corporate-event-management" | "construction-event-management" | "police-and-court-event-management"

const unclassifiedWorkflowTypes = [WorkflowTypeEnum.Any, WorkflowTypeEnum.General] as const;
type UnclassifiedWorkflowType = typeof unclassifiedWorkflowTypes[number];
export type AllowedWorkflowTypeEnum = Exclude<WorkflowTypeEnum, UnclassifiedWorkflowType | WorkflowTypeEnum.None>;
export const WorkflowType: Record<Exclude<WorkflowTypeEnum, UnclassifiedWorkflowType>, string>= {
    0: "None",
    2: "DORS+",
    4: "DDRS",
    5: "CPC",
    6: "Workshop",
    7: "On Road",
    8: "Business Driver",
    9: "Standard",
    10: "CITB Standard",
    11: "APS Standard",
};

export const PoliceAndCourtWorkflowTypeArray =
    [WorkflowTypeEnum.Dors, WorkflowTypeEnum.DDRS] satisfies AllowedWorkflowTypeEnum[];
export const BusinessDriverWorkflowTypeArray =
    [WorkflowTypeEnum.BusinessDriver] satisfies AllowedWorkflowTypeEnum[];
export const CorporateAdminWorkflowTypeArray =
    [WorkflowTypeEnum.CPC, WorkflowTypeEnum.Workshop, WorkflowTypeEnum.OnRoad] satisfies AllowedWorkflowTypeEnum[];
export const CorporateWorkflowTypeArray =
    [...BusinessDriverWorkflowTypeArray, ...CorporateAdminWorkflowTypeArray] satisfies AllowedWorkflowTypeEnum[];
export const ConstructionWorkflowTypeArray =
    [WorkflowTypeEnum.ConstructionStandard, WorkflowTypeEnum.CITB, WorkflowTypeEnum.APS] satisfies AllowedWorkflowTypeEnum[];
export const AllWorkflowTypeArray =
    [...PoliceAndCourtWorkflowTypeArray, ...CorporateWorkflowTypeArray, ...ConstructionWorkflowTypeArray] satisfies AllowedWorkflowTypeEnum[];

export const WorkflowTypesForCompanyType = (companyType: CompanyTypeEnum[]) => {
    if (!companyType) {
        return [];
    }

    const validWorkflowTypes = [];
    if (companyType.includes(CompanyTypeEnum.TTC) || companyType.includes(CompanyTypeEnum.LicenceBureau)) {
        validWorkflowTypes.push(WorkflowTypeEnum.CPC, WorkflowTypeEnum.Workshop, WorkflowTypeEnum.OnRoad);
    }
    if (companyType.includes(CompanyTypeEnum.BusinessDriver)) {
        validWorkflowTypes.push(WorkflowTypeEnum.BusinessDriver);
    }

    return validWorkflowTypes;
};

export const WorkflowTypesForBusinessLineType = (businessLineType: BusinessLineType) => {
    if (businessLineType === BusinessLineType.Corporate) {
        return CorporateWorkflowTypeArray;
    } else if (businessLineType === BusinessLineType.Construction) {
        return ConstructionWorkflowTypeArray;
    } else if (businessLineType === BusinessLineType.PoliceAndCourt
        || businessLineType === BusinessLineType.PoliceAndCourt2) {
        return PoliceAndCourtWorkflowTypeArray;
    } else {
        return AllWorkflowTypeArray;
    }
};

export const WorkflowTypeOptions = (isCorporate: boolean, isBusinessDriverAdmin: boolean, isCorporateEventAdmin: boolean,
    isConstruction: boolean, includeAny: boolean) => {
    let valuesToReturn: WorkflowTypeEnum[] = [];
    if (isCorporate) {
        if (isBusinessDriverAdmin && isCorporateEventAdmin) {
            valuesToReturn = [...CorporateWorkflowTypeArray];
        } else if (isBusinessDriverAdmin) {
            valuesToReturn = [...BusinessDriverWorkflowTypeArray];
        } else if (isCorporateEventAdmin) {
            valuesToReturn = [...CorporateAdminWorkflowTypeArray];
        }
    } else if (isConstruction) {
        valuesToReturn = [...ConstructionWorkflowTypeArray];
    } else {
        valuesToReturn = [...PoliceAndCourtWorkflowTypeArray];
    }

    if (includeAny) {
        valuesToReturn = [...valuesToReturn, WorkflowTypeEnum.Any];
    }

    return optionsFromObject(pick(WorkflowType, valuesToReturn));
};

export function GetBusinessHomeNameForWorkflow(workflowType: WorkflowTypeEnum): BusinessHomeRouteName {
    if (includes(CorporateWorkflowTypeArray, workflowType)) {
        return "corporate-event-management";
    }
    else if (includes(PoliceAndCourtWorkflowTypeArray, workflowType)) {
        return "police-and-court-event-management";
    }
    else if (includes(ConstructionWorkflowTypeArray, workflowType)) {
        return "construction-event-management";
    }
    else {
        return null;
    }
};

export const CorporateWorkflowTypes = (isBusinessDriverAdmin: boolean, isTtcCorporateAdmin: boolean) => {
    const valuesToOmit: WorkflowTypeEnum[] = [];
    if (isBusinessDriverAdmin === false) {
        valuesToOmit.push(WorkflowTypeEnum.BusinessDriver);
    }
    if (isTtcCorporateAdmin === false) {
        valuesToOmit.push(WorkflowTypeEnum.CPC, WorkflowTypeEnum.Workshop, WorkflowTypeEnum.OnRoad);
    }

    return omit(CorporateWorkflowType, valuesToOmit);
};

export const CorporateProductCategories = (isBusinessDriverAdmin: boolean, isTtcCorporateAdmin: boolean) => {
    const workflowTypes = CorporateWorkflowTypes(isBusinessDriverAdmin, isTtcCorporateAdmin);
    const filteredProductCategories = ProductCategoryFilteredByWorkflow(ObjectKeys(workflowTypes).map(Number));
    const productCategoryEntries = ObjectEntries(ProductCategoryExceptNone);
    const filteredEntries = productCategoryEntries
        .filter(([key, _]) => filteredProductCategories.includes(key));
    return Object.fromEntries(filteredEntries) as typeof ProductCategoryExceptNone;
};

export const CorporateProductCategoriesForWorkflowType = (isBusinessDriverAdmin: boolean, isTtcCorporateAdmin: boolean, workflowType?: WorkflowTypeEnum) => {
    const productCategories = CorporateProductCategories(isBusinessDriverAdmin, isTtcCorporateAdmin);
    if (!workflowType || workflowType === WorkflowTypeEnum.Any) {
        return productCategories;
    }

    const productCategoriesForWorkflowType = ProductCategoryFilteredByWorkflow([workflowType]);
    const filteredProductCategories = ObjectEntries(productCategories)
        .filter(([key, _]) => productCategoriesForWorkflowType.includes(key));
    return Object.fromEntries(filteredProductCategories) as typeof ProductCategoryExceptNone;
};

export const ConstructionProductCategories = () => {
    const filteredProductCategories = ProductCategoryFilteredByWorkflow(ConstructionWorkflowTypeArray);
    const productCategoryEntries = ObjectEntries(ProductCategoryExceptNone);
    const filteredEntries = productCategoryEntries.filter(([key, _]) =>
        filteredProductCategories.includes(key));
    return Object.fromEntries(filteredEntries) as typeof ProductCategoryExceptNone;
};

export const ConstructionProductCategoriesForWorkflowType = (workflowType?: WorkflowTypeEnum) => {
    const productCategories = ConstructionProductCategories();
    if (!workflowType || workflowType === WorkflowTypeEnum.Any) {
        return productCategories;
    }

    const productCategoriesForWorkflowType = ProductCategoryFilteredByWorkflow([workflowType]);
    const filteredProductCategories = ObjectEntries(ProductCategoryExceptNone).filter(([key, _]) =>
        productCategoriesForWorkflowType.includes(key));
    return Object.fromEntries(filteredProductCategories) as typeof ProductCategoryExceptNone;
};

export const NonPoliceOrCourtWorkflowTypeForCompanyTypeOptions = (businessLineType: BusinessLineType, companyType: CompanyTypeEnum[]) => {
    const validWorkflowTypes = businessLineType === BusinessLineType.Corporate
        ? WorkflowTypesForCompanyType(companyType)
        : ConstructionWorkflowTypeArray;
    return optionsFromObject(pick(WorkflowType, validWorkflowTypes));
};

export const PoliceAndCourtWorkflowType = {
    0: "None",
    2: "DORS+",
    4: "DDRS",
};

export const CorporateWorkflowType = {
    0: "None",
    5: "CPC",
    6: "Workshop",
    7: "On Road",
    8: "Business Driver",
};

export const ConstructionWorkflowType = {
    9: "Construction",
    10: "CITB",
    11: "APS",
};

export const WorkflowTypes: Record<AllowedWorkflowTypeEnum, string> = {
    [WorkflowTypeEnum.Dors]: "DORS+",
    [WorkflowTypeEnum.DDRS]: "DDRS",
    [WorkflowTypeEnum.CPC]: "CPC",
    [WorkflowTypeEnum.Workshop]: "Workshop",
    [WorkflowTypeEnum.OnRoad]: "On Road",
    [WorkflowTypeEnum.BusinessDriver]: "Business Driver",
    [WorkflowTypeEnum.ConstructionStandard]: "Construction",
    [WorkflowTypeEnum.CITB]: "CITB",
    [WorkflowTypeEnum.APS]: "APS",
};

export enum ProductCategoryEnum {
    Any = -1,
    None = 0,
    CPC = 1,
    FORS = 2,
    StandardWorkshop = 3,
    AFV = 4,
    StandardOnRoad = 5,
    OpenreachStandard = 6,
    OnRoadWithCpc = 7,
    MultiDropVehicle = 8,
    OneSchoolGlobal = 9,
    StandardConstruction = 10,
    OpenreachTrailerTowing = 11,
    StandardCitb = 12,
    StandardAps = 13,
    StandardBD = 14,
    OnRoadTrailerTowing = 15,
    LicenceAcquisition = 16,
};

export type AllowedProductCategories = Exclude<ProductCategoryEnum, ProductCategoryEnum.Any | ProductCategoryEnum.None>

export const ProductCategory = {
    [ProductCategoryEnum.None]: "None",
    [ProductCategoryEnum.CPC]: "CPC",
    [ProductCategoryEnum.FORS]: "FORS",
    [ProductCategoryEnum.StandardWorkshop]: "Standard Workshop",
    [ProductCategoryEnum.AFV]: "AFV",
    [ProductCategoryEnum.StandardOnRoad]: "Standard On Road",
    [ProductCategoryEnum.OpenreachStandard]: "Standard Openreach",
    [ProductCategoryEnum.OnRoadWithCpc]: "On Road with CPC",
    [ProductCategoryEnum.MultiDropVehicle]: "Multi Drop Vehicle",
    [ProductCategoryEnum.OneSchoolGlobal]: "One School Global",
    [ProductCategoryEnum.StandardConstruction]: "Standard",
    [ProductCategoryEnum.OpenreachTrailerTowing]: "Openreach with Trailer Towing",
    [ProductCategoryEnum.StandardCitb]: "Standard CITB",
    [ProductCategoryEnum.StandardAps]: "Standard APS",
    [ProductCategoryEnum.StandardBD]: "Standard Business Driver",
    [ProductCategoryEnum.OnRoadTrailerTowing]: "On Road with Trailer Towing",
    [ProductCategoryEnum.LicenceAcquisition]: "Licence Acquisition",
};

export const ProductWorkflowByCategory = {
    [ProductCategoryEnum.CPC]: WorkflowTypeEnum.CPC,
    [ProductCategoryEnum.FORS]: WorkflowTypeEnum.CPC,
    [ProductCategoryEnum.StandardWorkshop]: WorkflowTypeEnum.Workshop,
    [ProductCategoryEnum.AFV]: WorkflowTypeEnum.Workshop,
    [ProductCategoryEnum.StandardOnRoad]: WorkflowTypeEnum.OnRoad,
    [ProductCategoryEnum.OpenreachStandard]: WorkflowTypeEnum.BusinessDriver,
    [ProductCategoryEnum.OnRoadWithCpc]: WorkflowTypeEnum.OnRoad,
    [ProductCategoryEnum.MultiDropVehicle]: WorkflowTypeEnum.OnRoad,
    [ProductCategoryEnum.OneSchoolGlobal]: WorkflowTypeEnum.Workshop,
    [ProductCategoryEnum.StandardConstruction]: WorkflowTypeEnum.ConstructionStandard,
    [ProductCategoryEnum.OpenreachTrailerTowing]: WorkflowTypeEnum.BusinessDriver,
    [ProductCategoryEnum.StandardCitb]: WorkflowTypeEnum.CITB,
    [ProductCategoryEnum.StandardAps]: WorkflowTypeEnum.APS,
    [ProductCategoryEnum.StandardBD]: WorkflowTypeEnum.BusinessDriver,
    [ProductCategoryEnum.OnRoadTrailerTowing]: WorkflowTypeEnum.OnRoad,
    [ProductCategoryEnum.LicenceAcquisition]: WorkflowTypeEnum.BusinessDriver,
};

const ProductCategoryExceptNone = omit(ProductCategory, ProductCategoryEnum.None);

const typedProductWorkflowByCategoryKeys = ObjectKeys(ProductWorkflowByCategory);
export const ProductCategoryFilteredByWorkflow = (workflowTypes: WorkflowTypeEnum[]) => {
    return typedProductWorkflowByCategoryKeys
        .filter((key) => workflowTypes.includes(ProductWorkflowByCategory[key]));
};

export const BusinessDriverProductCategories = typedProductWorkflowByCategoryKeys
    .filter(key =>  ProductWorkflowByCategory[key] === WorkflowTypeEnum.BusinessDriver);

export const GetProductCategoriesForCompany = (company: CompanyTypeEnum[]) => {
    if (!company || company.length === 0) {
        return [];
    }

    const workflowTypes = [];
    if (company.includes(CompanyTypeEnum.BusinessDriver)) {
        workflowTypes.push(WorkflowTypeEnum.BusinessDriver);
    }
    if (company.includes(CompanyTypeEnum.TTC) || company.includes(CompanyTypeEnum.LicenceBureau)) {
        workflowTypes.push(WorkflowTypeEnum.CPC, WorkflowTypeEnum.Workshop, WorkflowTypeEnum.OnRoad);
    }

    return ProductCategoryFilteredByWorkflow(workflowTypes);
};

export const GetProductCategories = (businessLineType: BusinessLineType, isBusinessDriverAdmin: boolean, isTtcCorporateAdmin: boolean) => {
    const workflows = businessLineType === BusinessLineType.Corporate
        ? CorporateWorkflowTypes(isBusinessDriverAdmin, isTtcCorporateAdmin)
        : ConstructionWorkflowType;
    const categories = ProductCategoryFilteredByWorkflow(ObjectKeys(workflows).map(Number));

    return categories;
};

export enum CorporateCertificateTypeEnum {
    None = 0,
    CPC = 1,
    FORS = 2
};

export const CorporateCertificateType = {
    [CorporateCertificateTypeEnum.None]: "None",
    [CorporateCertificateTypeEnum.CPC]: "CPC",
    [CorporateCertificateTypeEnum.FORS]: "FORS",
};

export interface IntroMessagesResponse {
    introMessageEnglish: string;
    introMessageWelsh: string;
}
export enum FormTypeEnum {
    None = 0,
    NSAC,
    NMAC,
    WDU,
    SCD,
    AllScheme
}

export const FormTypeName = {
    [FormTypeEnum.NSAC]: "NSAC",
    [FormTypeEnum.NMAC]: "NMAC",
    [FormTypeEnum.WDU]: "WDU",
    [FormTypeEnum.SCD]: "SCD",
    [FormTypeEnum.AllScheme]: "AllScheme"
};

export enum SchemeEnum {
    None = 0,
    NSAC,
    NMAC,
    WDU,
    SCD
}

export const SchemeAbbreviation = {
    [SchemeEnum.NSAC]: "NSAC",
    [SchemeEnum.NMAC]: "NMAC",
    [SchemeEnum.WDU]: "WDU",
    [SchemeEnum.SCD]: "SCD"
};

export enum DiscountTypeEnum {
    None,
    Both,
    Concession,
    EarlyBird
    }

export const DiscountTypes = {
    [DiscountTypeEnum.None]: "None",
    [DiscountTypeEnum.Both]: "Both",
    [DiscountTypeEnum.Concession]: "Concession",
    [DiscountTypeEnum.EarlyBird]: "Early Bird",
};

export const nullFeesObj: ValueWithEffectiveDate<DdrsFeeConfig> ={ value: {
    discountType: DiscountTypeEnum.None,
    weekday: null,
    concessions: null,
    earlyBird: null,
    concessionsAndEarlyBird: null,
    weekendSupplement: null
}, effectiveDate: null };

export const initialFeeEditable: DdrsAllCountriesFeeConfig = {
    [CountryEnum.England]: [nullFeesObj],
    [CountryEnum.Wales]: [nullFeesObj],
    [CountryEnum.Scotland]: [nullFeesObj],
    [CountryEnum.NIreland]: [nullFeesObj]
};

export const initialFeeDetail: DdrsAllCountriesFeeConfig = {
    [CountryEnum.England]: [],
    [CountryEnum.Wales]: [],
    [CountryEnum.Scotland]: [],
    [CountryEnum.NIreland]: []
};

export type AppState = EventTypeState & { router: Location };

export enum CategoryIconEnum {
    AllCourses = 0,
    BuildingSafetyAct = 1,
    Cdm2015 = 2,
    TemporaryWorks = 3,
    Nec3AndNec4 = 4,
    InfrastructureAndConstruction = 5,
    Cad = 6,
    LawAndContracts = 7,
    ItTraining = 8,
    VirtualLiveCoursesAndELearning = 9,
    BusinessSkills = 10,
    FunctionalSafetyAndHazop = 11,
    ItilAndInformationSecurity = 12,
    OilAndGas = 13,
    ProjectManagement = 14,
    RenewablesAndEnvironmental = 15,
    SupplyChainManagement = 16,
    SqaModernApprenticeships = 17,
    Consultancy = 18
}

export interface CategoryStructure {
    id: number;
    titlte: string;
    icon: CategoryIconEnum;
    subCategories: SubCategoryStructure[];
}

export interface SubCategoryStructure {
    id: number;
    title: string;
}
