import * as React from "react";
import { Button, Modal, Table, Checkbox, Popup, Icon } from "semantic-ui-react";
import { CorporateUsersByEmail, DetailsAndBillingModel } from "../model";

export interface DetailsAndBillingUserSelectModalProps {
    open: boolean;
    corporateUsersByEmail: CorporateUsersByEmail;
    corporateOrganisationId?: string;
    selectCorporateUser: (model: DetailsAndBillingModel) => void;
}

export const DetailsAndBillingUserSelectModal: React.FC<DetailsAndBillingUserSelectModalProps> = ({ open, corporateUsersByEmail, corporateOrganisationId,
    selectCorporateUser }) => {
    const [selectedUser, setSelectedUser] = React.useState<string>(undefined);

    React.useEffect(() => {
        if (open) {
            setSelectedUser(undefined);
        }
    }, [open]);

    const containsNotSelectableUsers = React.useMemo(() => {
        return corporateOrganisationId && corporateUsersByEmail?.users?.some(u => corporateOrganisationId !== u.organisationId);
    }, [corporateOrganisationId, corporateUsersByEmail?.users]);

    const onChecboxChanged = React.useCallback((userId: string) => {
        setSelectedUser(userId);
    }, []);

    const onSelectUser = React.useCallback(() => {
        if (selectedUser !== "none") {
            const selectedUserData = corporateUsersByEmail?.users?.find(u => u.userId === selectedUser);
            selectCorporateUser(selectedUserData);
        } else {
            selectCorporateUser(undefined);
        }
    }, [corporateUsersByEmail?.users, selectCorporateUser, selectedUser]);

    return (
        <Modal open={open}>
            <Modal.Header>
                Select Booker
            </Modal.Header>
            <Modal.Content>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Organisation</Table.HeaderCell>
                            {containsNotSelectableUsers && <Table.HeaderCell>Warning</Table.HeaderCell>}
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            corporateUsersByEmail?.users?.map((u, i) =>
                                (<Table.Row key={`user_${i}`}>
                                    <Table.Cell>
                                        {`${u.forename} ${u.surname}`}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {`${u.organisation}`}
                                    </Table.Cell>
                                    {containsNotSelectableUsers && <Table.Cell>
                                        {corporateOrganisationId !== u.organisationId && (
                                            <Popup content="User with that email belongs to another organisation" trigger={
                                                <Icon className="email-warning" name="warning circle" />
                                            } />
                                        )}
                                    </Table.Cell>}
                                    <Table.Cell>
                                        <Checkbox
                                            checked={selectedUser === u.userId}
                                            disabled={corporateOrganisationId && corporateOrganisationId !== u.organisationId}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onChange={() => onChecboxChanged(u.userId)}
                                        />
                                    </Table.Cell>
                                </Table.Row>))
                        }
                        <Table.Row>
                            <Table.Cell colSpan={containsNotSelectableUsers ? 3 : 2}>
                                None of above
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    checked={selectedUser === "none"}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onChange={() => onChecboxChanged("none")}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={!selectedUser}
                    onClick={onSelectUser}
                    positive
                    labelPosition="right"
                    icon="checkmark"
                    content="Continue"
                />
            </Modal.Actions>
        </Modal>
    );
};
