import * as React from "react";
import { Icon, Grid } from "semantic-ui-react";
import { ScheduledPaymentStatus, ScheduledPaymentPlanDetailModel } from "@common/payments/model";
import { ScheduledPaymentDetailsRow } from "./ScheduledPaymentDetailsRow";

export interface ScheduledPaymentDetailsProps {
    plans: ScheduledPaymentPlanDetailModel[];
}

export const ScheduledPaymentDetails: React.SFC<ScheduledPaymentDetailsProps> = ({ plans }) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);

    const onShowDetailsClick = React.useCallback(() => setShowDetails(!showDetails), [showDetails]);

    const GetValueText = () => {

        // eslint-disable-next-line eqeqeq
        if (plans == null || plans.length === 0) {
            return "No plan";
        } else if (plans.some(x => x.status === ScheduledPaymentStatus.Cancelled)) {
            return "Plan cancelled";
        } else if (plans.some(x => x.status === ScheduledPaymentStatus.FinishedWithError)) {
            return "Plan failed";
        } else {
            const completedPaymentsCount = plans.filter(x => x.status === ScheduledPaymentStatus.FinishedWithSuccess).length;
            const totalPlans = plans.length;

            return `${completedPaymentsCount} of ${totalPlans} payments made`;
        }
    };

    return (
        <div className="label-and-value">
            <span className="label fixed-width">
                Scheduled payments
            </span>
            {plans && plans.length > 0 &&
                <>
                    <a
                        className="show-button"
                        onClick={onShowDetailsClick}
                    >{showDetails ? "Hide all" : "Show all"}
                    </a>
                    <Icon className={showDetails ? "angle down" : "angle right"} />
                </>
            }
            <div className="value">
                {GetValueText()}
            </div>
            {showDetails && plans &&
                <Grid className="grid scheduled-payment-details-grid">
                    {plans.map(plan => (<ScheduledPaymentDetailsRow key={plan.id} plan={plan} />))}
                </Grid>
            }
        </div>
    );
};
