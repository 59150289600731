import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Header, TextArea, TextAreaProps } from "semantic-ui-react";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { ChangeEventType } from "@common/history/model";
import { delegateSelector } from "../selectors";
import { DelegateDetailModel } from "../model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { addNoteToCorrelationId } from "@common/notes/actions";
import { v4 } from "uuid";
import { FileUploadInput } from "@common/crud/drinkDriveOffenders/components/details/FileUploadInput";

const expectedFilters = [
    { type: ChangeEventType.DataModification, display: true },
    { type: ChangeEventType.Note, display: true },
    { type: ChangeEventType.Booking, display: true },
    { type: ChangeEventType.Comms, display: true },
    { type: ChangeEventType.Certificates, display: true },
    { type: ChangeEventType.ExpiryDate, display: false },
    { type: ChangeEventType.RegisterUpdates, display: true },
    { type: ChangeEventType.DetailsChanged, display: true },
    { type: ChangeEventType.Nudge, display: true },
    { type: ChangeEventType.Audit, display: true },
    { type: ChangeEventType.AlwaysShown, display: false },
    { type: ChangeEventType.FlexiPayExpiryDate, display: false },
];

export function DelegateHistory() {
    const [files, setFiles] = React.useState<FileList>();

    const onFileChange = React.useCallback((fileList: FileList) => {
        setFiles(fileList);
    }, []);

    const delegate = useSelector(delegateSelector) as DelegateDetailModel;
    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState<ChangeEventType[]>(
        expectedFilters
            .map(filter => filter.type));

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");
    const [noteText, setNoteText] = React.useState("");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    function onTextAreaChange(e: any, d: TextAreaProps) {
        setNoteText(d.value.toString());
    }

    const { correlationId, taskRelation } = delegate.attendeeWithoutDelegate ?
        { correlationId: delegate.delegateAttendees[0].correlationId, taskRelation: TaskRelationEnum.DelegateAttendee } :
        { correlationId: delegate.id, taskRelation: TaskRelationEnum.DelegateRecord };

    const handleOnClick = React.useCallback(async () => {
        dispatch(addNoteToCorrelationId(
            correlationId,
            v4(),
            noteText,
            taskRelation,
            false,
            null,
            files && files.length > 0 ? files[0] : undefined,
            delegate.organisationId
        ));
        setNoteText("");
        setFiles(undefined);
    }, [correlationId, delegate.organisationId, dispatch, noteText, taskRelation, files]);

    return (
        <div>
            <div>
                <Grid className="note-container">
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form>
                                <TextArea placeholder={"Enter note"} rows={2} onChange={onTextAreaChange} value={noteText} />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button disabled={(noteText?.length || 0) === 0} className="wide" onClick={handleOnClick} floated="left" content="Add Note" />
                            <FileUploadInput
                                fileList={files}
                                uploadLabel="Add File"
                                onChange={onFileChange}
                            />
                            {(files && files.length > 0) && <span>{files[0].name}</span>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <Header size="large">Case history</Header>
            <Grid>
                <Grid.Column width={3}>
                    <Grid.Row>
                        Filters by group:
                    </Grid.Row>
                    {expectedFilters.map(eventType => eventType.display ?
                        <HistoryFilter
                            key={eventType.type}
                            eventType={eventType.type}
                            value={filters.includes(eventType.type)}
                            onChange={addOrRemove}
                        /> : null)}
                    <Grid.Row>
                        <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={13}>
                    <HistoryContent showDetailsDefault={false} entries={delegate?.history?.filter(
                        record => filters.includes(record.eventType))}
                    taskRelation={taskRelation} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
