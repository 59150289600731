import * as React from "react";
import { Version } from "../model";
import { VersionInfo } from "./VersionInfo";

interface FooterProps {
    version: Version;
    text?: string;
    tncLink?: string;
}

export class Footer extends React.Component<FooterProps> {
    public render() {
        const year = new Date().getFullYear();
        const { version, text, tncLink } = this.props;

        return (
            <footer className="global-footer">
                <div className="ui container wrapper">
                    {text &&
                        <div className="footer-message">
                            {text}
                        </div>
                    }
                    {tncLink &&
                        <div className="privacy">
                            <a href={tncLink} target="_blank" rel="noreferrer"> Privacy Policy</a>
                        </div>
                    }
                    <div className="copyright">
                        &copy; TTC Group {year}. <VersionInfo {...version} /> All rights reserved.
                    </div>
                </div>
            </footer>
        );
    }
}
