import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Detail } from "@neworbit/simpleui-detail/dist/Detail";
import * as React from "react";
import { ValueWithEffectiveDate } from "../../model";
import { SemanticWIDTHS } from "semantic-ui-react";

interface DisplayValueWithEffectiveDateProps {
    values: ValueWithEffectiveDate<any>[];
    label: string;
    valueFormat?: (value: any) => (string|JSX.Element);
    customLabelWidth?: SemanticWIDTHS;
    customValueWidth?: SemanticWIDTHS;
};

export const DisplayValueWithEffectiveDate: React.FC<DisplayValueWithEffectiveDateProps> = ({ values, label, valueFormat,
    customLabelWidth, customValueWidth }) => {
    const sortedValues = (values ?? []).sort((a, b) => a.effectiveDate.diff(b.effectiveDate));
    const defaultFormat = (value: any) => value.toString();
    const displayValue = valueFormat ?? defaultFormat;
    return (
        <>
            { sortedValues.length < 1 &&
                <Detail
                    label={`${label}`}
                    value={"None set"}
                    labelWidth={customLabelWidth ?? 5}
                    valueWidth={customValueWidth ?? 11}
                />
            }
            {sortedValues.map((d, i) => (
                <Detail
                    key={i}
                    label={`${label} from ${d.effectiveDate.format?.(DateFormat.DayMonthYear)}`}
                    value={displayValue(d.value)}
                    labelWidth={customLabelWidth ?? 5}
                    valueWidth={customValueWidth ?? 11}
                />
            ))}
        </>
    );
};
