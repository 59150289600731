/* eslint-disable max-lines */
import { DelegatesContactModel, GetContactDescription, ManagersContactModel, OrganisationContactModel } from "@common/crud/organisation/model";
import * as React from "react";
import { Checkbox, Grid } from "semantic-ui-react";
import { UpdateClosedCourseCommsDestinationsModel } from "../../model";

interface ClosedContactsFormProps {
    primaryContact: OrganisationContactModel;
    courseContact: OrganisationContactModel;
    financeContact: OrganisationContactModel;
    delegatesContact: DelegatesContactModel;
    managersContact: ManagersContactModel;
    isBusinessDriver: boolean;
    onChange(model: UpdateClosedCourseCommsDestinationsModel): void;
}

export const ClosedContactsForm = ({ primaryContact, courseContact, financeContact, delegatesContact, managersContact, isBusinessDriver,
    onChange }: ClosedContactsFormProps) => {

    const mapPropsToUpdateClosedCourseCommsDestinationsModel =
        React.useCallback(() => {
            return {
                creationPrimaryDict:
                    primaryContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseCreationCommsDestination || false;
                        return dict;
                    }, {}) || {},
                creationCourseDict:
                    courseContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseCreationCommsDestination || false;
                        return dict;
                    }, {}) || {},
                creationFinanceDict:
                    financeContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseCreationCommsDestination || false;
                        return dict;
                    }, {}) || {},
                reminderPrimaryDict:
                    primaryContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseReminderCommsDestination || false;
                        return dict;
                    }, {}) || {},
                reminderCourseDict:
                    courseContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseReminderCommsDestination || false;
                        return dict;
                    }, {}) || {},
                reminderFinanceDict:
                    financeContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseReminderCommsDestination || false;
                        return dict;
                    }, {}) || {},
                bookingPrimaryDict:
                    primaryContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.bookingCommsDestination || false;
                        return dict;
                    }, {}) || {},
                bookingCourseDict:
                    courseContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.bookingCommsDestination || false;
                        return dict;
                    }, {}) || {},
                bookingFinanceDict:
                    financeContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.bookingCommsDestination || false;
                        return dict;
                    }, {}) || {},
                courseClosurePrimaryDict:
                    primaryContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.certificateCommsDestination || false;
                        return dict;
                    }, {}) || {},
                courseClosureCourseDict:
                    courseContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.certificateCommsDestination || false;
                        return dict;
                    }, {}) || {},
                courseEditPrimaryDict:
                    primaryContact?.contacts?.reduce((dict, contact) => {
                        dict[contact.email] =
                            contact.courseEditCommsDestination  || false;
                        return dict;
                    }, {}) || {},
                courseEditCourseDict: courseContact?.contacts?.reduce((dict, contact) => {
                    dict[contact.email] = contact.courseEditCommsDestination  || false;
                    return dict;
                }, {}) || {},
                courseEditFinanceDict: financeContact?.contacts?.reduce((dict, contact) => {
                    dict[contact.email] = contact.courseEditCommsDestination  || false;
                    return dict;
                }, {}) || {},
                courseReminderDelegatesContact:
                    delegatesContact?.courseReminderCommsDestination ?? false,
                courseBookingDelegatesContact:
                    delegatesContact?.bookingCommsDestination ?? false,
                courseClosureDelegatesContact:
                    delegatesContact?.certificateCommsDestination ?? false,
                courseEditDelegatesContact:
                    delegatesContact?.courseEditCommsDestination ?? false,
                courseReminderManagersContact:
                    managersContact?.courseReminderCommsDestination ?? false,
                courseBookingManagersContact:
                    managersContact?.bookingCommsDestination ?? false,
                courseClosureManagersContact:
                    managersContact?.certificateCommsDestination ?? false,
                courseEditManagersContact:
                    managersContact?.courseEditCommsDestination ?? false,
            };
        }, [
            courseContact?.contacts,
            financeContact?.contacts,
            primaryContact?.contacts,
            delegatesContact?.bookingCommsDestination,
            delegatesContact?.certificateCommsDestination,
            delegatesContact?.courseReminderCommsDestination,
            delegatesContact?.courseEditCommsDestination,
            managersContact?.bookingCommsDestination,
            managersContact?.certificateCommsDestination,
            managersContact?.courseReminderCommsDestination,
            managersContact?.courseEditCommsDestination
        ]);

    const [model, setModel] = React.useState<UpdateClosedCourseCommsDestinationsModel>(mapPropsToUpdateClosedCourseCommsDestinationsModel());

    React.useEffect(() => {
        onChange(model);
    }, [model, onChange]);

    React.useEffect(() => {
        setModel(mapPropsToUpdateClosedCourseCommsDestinationsModel());
    }, [primaryContact,
        courseContact,
        financeContact,
        delegatesContact,
        mapPropsToUpdateClosedCourseCommsDestinationsModel]);

    const setCreationPrimaryDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            creationPrimaryDict: {
                ...model.creationPrimaryDict,
                [email]: !model.creationPrimaryDict[email]
            }
        });
    }, [model]);

    const setCourseClosurePrimaryDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            courseClosurePrimaryDict: {
                ...model.courseClosurePrimaryDict,
                [email]: !model.courseClosurePrimaryDict[email]
            }
        });
    }, [model]);

    const setCreationCourseDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            creationCourseDict: {
                ...model.creationCourseDict,
                [email]: !model.creationCourseDict[email]
            }
        });
    }, [model]);

    const setCourseClosureCourseDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            courseClosureCourseDict: {
                ...model.courseClosureCourseDict,
                [email]: !model.courseClosureCourseDict[email]
            }
        });
    }, [model]);

    const setCreationFinanceDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            creationFinanceDict: {
                ...model.creationFinanceDict,
                [email]: !model.creationFinanceDict[email]
            }
        });
    }, [model]);

    const setCourseReminderDelegatesContact = React.useCallback(() => {
        setModel({
            ...model,
            courseReminderDelegatesContact: !model.courseReminderDelegatesContact
        });
    }, [model]);

    const setCourseBookingDelegatesContact = React.useCallback(() => {
        setModel({
            ...model,
            courseBookingDelegatesContact: !model.courseBookingDelegatesContact
        });
    }, [model]);

    const setCourseClosureDelegatesContact = React.useCallback(() => {
        setModel({
            ...model,
            courseClosureDelegatesContact: !model.courseClosureDelegatesContact
        });
    }, [model]);

    const setCourseEditDelegatesContact = React.useCallback(() => {
        setModel({
            ...model,
            courseEditDelegatesContact: !model.courseEditDelegatesContact
        });
    }, [model]);

    const setCourseReminderManagersContact = React.useCallback(() => {
        setModel({
            ...model,
            courseReminderManagersContact: !model.courseReminderManagersContact
        });
    }, [model]);

    const setCourseBookingManagersContact = React.useCallback(() => {
        setModel({
            ...model,
            courseBookingManagersContact: !model.courseBookingManagersContact
        });
    }, [model]);

    const setCourseClosureManagersContact = React.useCallback(() => {
        setModel({
            ...model,
            courseClosureManagersContact: !model.courseClosureManagersContact
        });
    }, [model]);

    const setCourseEditManagersContact = React.useCallback(() => {
        setModel({
            ...model,
            courseEditManagersContact: !model.courseEditManagersContact
        });
    }, [model]);

    const setReminderPrimaryDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            reminderPrimaryDict: {
                ...model.reminderPrimaryDict,
                [email]: !model.reminderPrimaryDict[email]
            }
        });
    }, [model]);

    const setReminderCourseDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            reminderCourseDict: {
                ...model.reminderCourseDict,
                [email]: !model.reminderCourseDict[email]
            }
        });
    }, [model]);

    const setReminderFinanceDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            reminderFinanceDict: {
                ...model.reminderFinanceDict,
                [email]: !model.reminderFinanceDict[email]
            }
        });
    }, [model]);

    const setBookingPrimaryDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            bookingPrimaryDict: {
                ...model.bookingPrimaryDict,
                [email]: !model.bookingPrimaryDict[email]
            }
        });
    }, [model]);

    const setBookingCourseDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            bookingCourseDict: {
                ...model.bookingCourseDict,
                [email]: !model.bookingCourseDict[email]
            }
        });
    }, [model]);

    const setBookingFinanceDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            bookingFinanceDict: {
                ...model.bookingFinanceDict,
                [email]: !model.bookingFinanceDict[email]
            }
        });
    }, [model]);

    const setCourseEditPrimaryDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            courseEditPrimaryDict: {
                ...model.courseEditPrimaryDict,
                [email]: !model.courseEditPrimaryDict[email]
            }
        });
    }, [model]);

    const setCourseEditCourseDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            courseEditCourseDict: {
                ...model.courseEditCourseDict,
                [email]: !model.courseEditCourseDict[email]
            }
        });
    }, [model]);

    const setCourseEditFinanceDict = React.useCallback((email: string) => {
        setModel({
            ...model,
            courseEditFinanceDict: {
                ...model.courseEditFinanceDict,
                [email]: !model.courseEditFinanceDict[email]
            }
        });
    }, [model]);

    const showCourseCreationCommsDestination = React.useMemo(() => {
        const primaryContacts = primaryContact?.contacts?.filter(contact => contact.email);
        const courseContacts = courseContact?.contacts?.filter(contact => contact.email);
        const financeContacts = financeContact?.contacts?.filter(contact => contact.email);
        return primaryContacts?.length || courseContacts?.length || financeContacts?.length;
    }, [courseContact?.contacts, financeContact?.contacts, primaryContact?.contacts]);

    const labelWithDictToggleRow = (
        label: string,
        email: string,
        state: { [email: string]: boolean },
        labelWithDictToggleRowChange: (email: string) => void) => {
        const checked = state[email];
        const updateValue = () => {
            labelWithDictToggleRowChange(email);
        };

        return (
            <Grid.Row>
                <Grid.Column width={6}>
                    {label}
                </Grid.Column>
                <Grid.Column width={2}>
                    <Checkbox toggle checked={checked} onChange={updateValue} />
                </Grid.Column>
            </Grid.Row>
        );
    };

    const labelWithToggleRow = (label: string, checked: boolean, onLabelWithToggleRowChange: () => void) => {
        return (
            <Grid.Row>
                <Grid.Column width={6}>
                    {label}
                </Grid.Column>
                <Grid.Column width={2}>
                    <Checkbox toggle checked={checked} onChange={onLabelWithToggleRowChange} />
                </Grid.Column>
            </Grid.Row>
        );
    };

    return (
        <Grid>
            {(!!showCourseCreationCommsDestination) &&
                <>
                    <h3>Course creation/cancellation comms destination</h3>
                    {primaryContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Primary Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.creationPrimaryDict,
                            setCreationPrimaryDict
                        )
                    )}
                    {courseContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Course Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.creationCourseDict,
                            setCreationCourseDict
                        )
                    )}
                    {financeContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Finance Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.creationFinanceDict,
                            setCreationFinanceDict
                        )
                    )}
                </>}
            <>
                <h3>Course reminder comms destination</h3>
                {primaryContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                    labelWithDictToggleRow(
                        `Primary Contact (${GetContactDescription(contact)})`,
                        contact.email,
                        model.reminderPrimaryDict,
                        setReminderPrimaryDict
                    )
                )}
                {courseContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                    labelWithDictToggleRow(
                        `Course Contact (${GetContactDescription(contact)})`,
                        contact.email,
                        model.reminderCourseDict,
                        setReminderCourseDict
                    )
                )}
                {financeContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                    labelWithDictToggleRow(
                        `Finance Contact (${GetContactDescription(contact)})`,
                        contact.email,
                        model.reminderFinanceDict,
                        setReminderFinanceDict
                    )
                )}
                {labelWithToggleRow("Delegates", model.courseReminderDelegatesContact, setCourseReminderDelegatesContact)}
                {isBusinessDriver && labelWithToggleRow("Managers", model.courseReminderManagersContact, setCourseReminderManagersContact)}
            </>
            <>
                <h3>Course booking comms destination</h3>
                {isBusinessDriver &&
                <>
                    {primaryContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Primary Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.bookingPrimaryDict,
                            setBookingPrimaryDict
                        )
                    )}
                    {courseContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Course Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.bookingCourseDict,
                            setBookingCourseDict
                        )
                    )}
                    {financeContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                        labelWithDictToggleRow(
                            `Finance Contact (${GetContactDescription(contact)})`,
                            contact.email,
                            model.bookingFinanceDict,
                            setBookingFinanceDict
                        )
                    )}
                </>
                }
                {labelWithToggleRow("Delegates", model.courseBookingDelegatesContact, setCourseBookingDelegatesContact)}
                {isBusinessDriver && labelWithToggleRow("Managers", model.courseBookingManagersContact, setCourseBookingManagersContact)}
            </>
            <>
                <h3>Course closure comms destination</h3>
                {primaryContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                    labelWithDictToggleRow(
                        `Primary Contact (${GetContactDescription(contact)})`,
                        contact.email,
                        model.courseClosurePrimaryDict,
                        setCourseClosurePrimaryDict
                    )
                )}
                {courseContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                    labelWithDictToggleRow(
                        `Course Contact (${GetContactDescription(contact)})`,
                        contact.email,
                        model.courseClosureCourseDict,
                        setCourseClosureCourseDict
                    )
                )}
                {labelWithToggleRow("Delegates", model.courseClosureDelegatesContact, setCourseClosureDelegatesContact)}
                {isBusinessDriver && labelWithToggleRow("Managers", model.courseClosureManagersContact, setCourseClosureManagersContact)}
            </>
            {isBusinessDriver &&
             <>
                 <h3>Course Edit Comms Destination</h3>
                 {primaryContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                     labelWithDictToggleRow(
                         `Primary Contact (${GetContactDescription(contact)})`,
                         contact.email,
                         model.courseEditPrimaryDict,
                         setCourseEditPrimaryDict
                     )
                 )}
                 {courseContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                     labelWithDictToggleRow(
                         `Course Contact (${GetContactDescription(contact)})`,
                         contact.email,
                         model.courseEditCourseDict,
                         setCourseEditCourseDict
                     )
                 )}
                 {financeContact?.contacts?.filter(contact => contact.email)?.map(contact =>
                     labelWithDictToggleRow(
                         `Finance Contact (${GetContactDescription(contact)})`,
                         contact.email,
                         model.courseEditFinanceDict,
                         setCourseEditFinanceDict
                     )
                 )}
                 {labelWithToggleRow("Delegates", model.courseEditDelegatesContact, setCourseEditDelegatesContact)}
                 {labelWithToggleRow("Managers", model.courseEditManagersContact, setCourseEditManagersContact)}
             </>}
        </Grid>
    );
};
