import * as React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AsyncDispatch } from "@common/redux-helpers";
import { connect } from "react-redux";
import { AppState, PaymentRequest, PaymentResponse, StartPaymentProcessRequest } from "@common/payments/model";
import { push } from "redux-little-router";
import { Button, Container, Grid, Segment } from "semantic-ui-react";
import { basePathSelector } from "@common/crud/attendee/selectors";
import { makePayment } from "@common/payments/actions";
import { debounce } from "@neworbit/simpleui-utils";
import {
    useClearBeforeGenesysAuthPage,
    useReportCallRecordingStatus,
    useResumeCallRecordingOnExit
} from "@common/genesys";
import { usePauseCallRecordingOnEntry } from "@common/genesys/hooks/usePauseCallRecordingOnEntry";
import { updateFlexiPay } from "@common/crud/attendee/actions";
import { UpdateFlexiPayModel } from "@common/crud/attendee/model";

interface StateProps {
    stripePaymentResponse: PaymentResponse;
    path: string;
}

interface DispatchProps {
    proceedWithPayment: (paymentRequest: PaymentRequest) => Promise<PaymentResponse>;
    redirect: (url: string) => void;
    updateFlexiPay: (eventInstanceId: string, attendeeId: string, model: UpdateFlexiPayModel) => void;
}

interface OwnProps {
    startPaymentProcess?: StartPaymentProcessRequest;
}

type PaymentReviewProps = StateProps & DispatchProps & OwnProps;

const PaymentReviewInternal: React.FC<PaymentReviewProps> = (props) => {

    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [pauseCallRecordingCheck, setPauseCallRecordingCheck] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();

    useClearBeforeGenesysAuthPage();
    usePauseCallRecordingOnEntry(props.startPaymentProcess.correlationId);
    useReportCallRecordingStatus(true, props.startPaymentProcess.correlationId, pauseCallRecordingCheck);
    useResumeCallRecordingOnExit(props.startPaymentProcess.correlationId);

    const submit = React.useCallback(async (ev: any) => {
        ev.preventDefault();
        setDisabled(true);
        setErrorMessage(null);

        const card = elements.getElement(CardNumberElement);
        const paymentMethodData = await stripe.createPaymentMethod({ type: "card", card });
        const { startPaymentProcess } = props;

        if (paymentMethodData.paymentMethod) {
            const paymentRequest: PaymentRequest = {
                paymentMethodId: paymentMethodData.paymentMethod.id,
                paymentIntentId: "",
                amount: startPaymentProcess.amount,
                eventInstanceId: startPaymentProcess.eventInstanceId,
                startPaymentProcessMultiPartModels: startPaymentProcess.startPaymentProcessMultiPartModels,
                orderId: startPaymentProcess.orderId,
                correlationId: startPaymentProcess.correlationId,
                plan: startPaymentProcess.plan,
                waivedRebookingFee: startPaymentProcess.waivedRebookingFee,
                isNewBooking: startPaymentProcess.isNewBooking,
                eventTypeCategory: startPaymentProcess.eventTypeCategory,
            };

            let paymentResponse: PaymentResponse;
            try {
                setPauseCallRecordingCheck(true);
                paymentResponse = await props.proceedWithPayment(paymentRequest);
            }
            finally {
                setPauseCallRecordingCheck(false);
            }

            if (paymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                handleAction(paymentResponse);
            } else if (paymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else {
                if (props.startPaymentProcess.flexiPayPurchasedAfterBooking) {
                    const model: UpdateFlexiPayModel = {
                        flexiPayFee: startPaymentProcess.amount,
                        flexiPayPurchased: startPaymentProcess.flexiPayPurchasedAfterBooking
                    };
                    props.updateFlexiPay(paymentRequest.eventInstanceId, paymentRequest.startPaymentProcessMultiPartModels[0].relatedObjectId, model);
                }
                handleRedirect();
            }
        }
        if (paymentMethodData.error) {
            showErrorAndEnable(paymentMethodData.error.message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, props.startPaymentProcess, props.proceedWithPayment]);

    const handleAction = async (response: PaymentResponse) => {
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
            response.paymentIntentClientSecret
        );

        // eslint-disable-next-line eqeqeq
        if (errorAction != null) {
            setShowError(true);
        } else {
            const { startPaymentProcess } = props;
            const paymentRequest: PaymentRequest = {
                paymentMethodId: "",
                paymentIntentId: paymentIntent.id,
                amount: startPaymentProcess.amount,
                eventInstanceId: startPaymentProcess.eventInstanceId,
                startPaymentProcessMultiPartModels: startPaymentProcess.startPaymentProcessMultiPartModels,
                orderId: startPaymentProcess.orderId,
                correlationId: startPaymentProcess.correlationId,
                plan: startPaymentProcess.plan,
                waivedRebookingFee: startPaymentProcess.waivedRebookingFee,
                isNewBooking: startPaymentProcess.isNewBooking,
                eventTypeCategory: startPaymentProcess.eventTypeCategory,
            };

            const paymentResponse = await props.proceedWithPayment(paymentRequest);

            if (paymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else if (paymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                await handleAction(paymentResponse);
            } else {
                handleRedirect();
            }
        }
    };

    const getErrorDiv = (message: string) => {
        return (
            <div className="payment-error">
                <p>{message}</p>
            </div>);
    };

    const getErrorSection = () => {
        if (!showError) {
            return (<></>);
        }

        if (props.stripePaymentResponse.validRequest === false) {
            const message = props.stripePaymentResponse.paymentError
                ? props.stripePaymentResponse.paymentError
                : "Your payment has failed";

            return getErrorDiv(message);
        }
        if (errorMessage) {
            return getErrorDiv(errorMessage);
        }

        return (<></>);
    };

    const getStripeContainer = () => {
        return (
            <>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Card Number</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardNumberElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Expiry Date</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardExpiryElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Security Code</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <CardCvcElement className="payment-input" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {getErrorSection()}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const handleRedirect = () => {
        props.redirect(`${props.path}/${props.startPaymentProcess.relatedObjectId}`);
    };

    const showErrorAndEnable = (currErrorMessage?: string) => {
        setShowError(true);
        setDisabled(false);

        setErrorMessage(currErrorMessage);
    };

    return (
        <>
            <Segment>
                <h3>Payment details</h3>
                <p>Please enter card details below</p>
            </Segment>
            <Grid className="two-section-layout">
                <Grid.Row>
                    <Grid.Column width={10} className="two-section-layout-left border-top">
                        <Container className="indented">
                            {getStripeContainer()}
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={6} className="two-section-layout-right booking-details-column">
                        <Grid.Row>
                            You are about to pay £{(props.startPaymentProcess.amount / 100).toFixed(2)}
                        </Grid.Row>
                        <Grid.Row>
                            <Button className="full-width payment-button" disabled={disabled} onClick={submit}>Pay</Button>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

function mapStateToProps(state: AppState): StateProps {
    return {
        stripePaymentResponse: state.stripePaymentResponse,
        path: basePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        proceedWithPayment: debounce((paymentRequest: PaymentRequest) => dispatch(makePayment(paymentRequest)), 500),
        redirect: (url) => dispatch(push(url)),
        updateFlexiPay: (eventInstanceId: string, attendeeId: string, model: UpdateFlexiPayModel) => dispatch(
            updateFlexiPay(eventInstanceId, attendeeId, model)
        )
    };
}

export const PaymentReview = connect(mapStateToProps, mapDispatchToProps)(PaymentReviewInternal);
