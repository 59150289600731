import * as React from "react";
import { Checkbox, Grid, Table, TableBody, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { BookingsHistoryModel } from "@common/crud/bookingsHistory/model";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { isBusinessLineTypeCorporateOrConstruction, isBusinessLineTypePoliceOrCourt, isWorkflowConstruction } from "@common/global/CommonHelpers";
import { BookingsHistoryAttendeeContent } from "./BookingsHistoryAttendeeContent";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";

export interface BookingsHistoryContentProps {
    entries: BookingsHistoryModel[];
    isEventInstanceMultiDay: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
    workflowType?: WorkflowTypeEnum;
}

export const BookingsHistoryContent: React.FC<BookingsHistoryContentProps> = ({ entries, isEventInstanceMultiDay, eventInstanceGroupDays, workflowType }) => {
    const businessLineType = useSelector(businessLineTypeSelector);
    const [showCurrentAttendes, setShowCurrentAttendes] = React.useState(false);
    const isConstruction = React.useMemo(() => isWorkflowConstruction(workflowType), [workflowType]);
    const attendees = React.useMemo(() => showCurrentAttendes
        ? entries && entries.filter(entry => entry.status === "BOOKED" || entry.status === "COMPLETED")
        : entries,
    [entries, showCurrentAttendes]);
    const totalCurrentAttendes = React.useMemo(() => entries
        ? entries.filter(entry => entry.status === "BOOKED" || entry.status === "COMPLETED").length
        : 0,
    [entries]);
    const toggleCurrentAttendees = React.useCallback(() => setShowCurrentAttendes(state => !state), []);
    const currentAttendeesLabel = React.useMemo(() => `${showCurrentAttendes ? "Show all" : "Show current"} attendees`, [showCurrentAttendes]);

    const [sortedAttendees, setSortedAttendees] = React.useState(attendees);
    const [sortOrder, setSortOrder] = React.useState<"ascending" | "descending" | undefined>(undefined);
    const [sortedProperty, setSortedProperty] = React.useState<keyof BookingsHistoryModel>();

    const identifierLabel = React.useMemo(() => {
        switch (workflowType) {
            case WorkflowTypeEnum.DDRS:
                return "Case Number";
            case WorkflowTypeEnum.BusinessDriver:
                return "UIN";
            default:
                return "Driving Licence";
        }
    }, [workflowType]);

    const sortBy = React.useCallback((property: keyof BookingsHistoryModel) => () => {
        const nextSortOrder = sortOrder === "ascending" ? "desc" : "asc";
        const sorted = orderBy(attendees, property, nextSortOrder);
        setSortedAttendees(sorted);
        const fixedNextSortOrder = nextSortOrder === "asc" ? "ascending" : "descending";
        setSortOrder(fixedNextSortOrder);
        setSortedProperty(property);
    }, [attendees, sortOrder]);

    React.useEffect(() => {
        setSortedAttendees(attendees);
    }, [attendees]);

    return (
        <Table sortable>
            <TableHeader className="table-header">
                <TableRow>
                    <TableHeaderCell className="not-sortable-column">{isBusinessLineTypeCorporateOrConstruction(businessLineType) ?
                        "Delegate Name" : "Attendee Name"}</TableHeaderCell>
                    {!isConstruction && (<TableHeaderCell className="not-sortable-column">{identifierLabel}</TableHeaderCell>)}
                    <TableHeaderCell className="not-sortable-column">Booked By</TableHeaderCell>
                    <TableHeaderCell onClick={sortBy("dateCreated")} sorted={sortedProperty === "dateCreated" ? sortOrder : undefined}>
                        Booked At
                    </TableHeaderCell>
                    {isEventInstanceMultiDay && <TableHeaderCell className="not-sortable-column">Days</TableHeaderCell>}
                    <TableHeaderCell className="not-sortable-column">Status</TableHeaderCell>
                    {
                        isBusinessLineTypePoliceOrCourt(businessLineType) &&
                        <TableHeaderCell onClick={sortBy("offerExpiry")} sorted={sortedProperty === "offerExpiry" ? sortOrder : undefined}>
                            Completion Date
                        </TableHeaderCell>
                    }
                </TableRow>
            </TableHeader>
            <TableBody>
                {sortedAttendees && sortedAttendees.map((booking, index) =>
                    (<BookingsHistoryAttendeeContent
                        key={`booking-${index}`}
                        booking={booking}
                        isEventInstanceMultiDay={isEventInstanceMultiDay}
                        eventInstanceGroupDays={eventInstanceGroupDays}
                        workflowType={workflowType}
                    />))}
            </TableBody>
            {sortedAttendees &&
                <Table.Footer>
                    <TableRow>
                        <TableHeaderCell colSpan="6">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8} textAlign="left">
                                        <Checkbox
                                            toggle
                                            label={currentAttendeesLabel}
                                            checked={showCurrentAttendes}
                                            onChange={toggleCurrentAttendees}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="no-padding-top">
                                    <Grid.Column width={14} className="bold" textAlign="right">Total attendees in history
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="right" className="purple-header">
                                        <strong>{entries.length}</strong>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="no-padding-top">
                                    <Grid.Column width={14} className="bold" textAlign="right">Total current attendees
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="right" className="purple-header">
                                        <strong>{totalCurrentAttendes}</strong>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </TableHeaderCell>
                    </TableRow>
                </Table.Footer>}
        </Table>
    );
};
