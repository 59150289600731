import * as React from "react";
import { EiSideBar } from "../EiSideBar";
import { useDispatch, useSelector } from "react-redux";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "../../selectors";
import { SpecialRequirementsDisplay } from "@common/specialRequirements/SpecialRequirementsDisplay";
import { Grid } from "semantic-ui-react";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { shouldAttendeeSpecialRequirementsBeAnonymous } from "../../model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { filterEmptySpecialRequirements, hasNoSpecialRequirements } from "./special-requirements";
import { UpdateSpecialRequirementsModal } from "./UpdateSpecialRequirementsModal";
import moment from "moment";
import { attendeesSelector } from "@common/crud/attendee/selectors";
import { loadAttendeesForEventInstance } from "@common/crud/attendee/actions";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { isEventInstanceWorkflowConstruction, isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";

export const SpecialRequirements: React.FC = () => {
    const dispatch = useDispatch();

    const app = useSelector(appSelector);
    const isTrainerApp = app === Apps.Trainer;

    const attendees = useSelector(attendeesSelector);
    const eventInstance = useSelector(eventInstanceSelector);
    const { specialRequirementAttendees } = eventInstance;

    const showUpdateButton = !isTrainerApp && (isEventInstanceWorkflowCorporate(eventInstance) || isEventInstanceWorkflowConstruction(eventInstance));
    const isEventInstanceMultiDay = React.useMemo(() => !!eventInstance?.groupId, [eventInstance?.groupId]);
    const eventInstanceGroup = useSelector(eventInstanceGroupByIdSelector(eventInstance?.groupId));

    const eventInstanceFinished = React.useMemo(() => {
        if (isEventInstanceMultiDay) {
            const length = eventInstanceGroup.eventInstanceGroupItems?.length;
            if (length) {
                const lastDayEi = eventInstanceGroup.eventInstanceGroupItems[length - 1];
                return lastDayEi?.deliveryDateTime?.clone().add(lastDayEi?.eventDuration)?.isBefore(moment().utc());
            }
        }
        return eventInstance?.deliveryDateTime?.clone().add(eventInstance?.eventDuration)?.isBefore(moment().utc());
    }, [eventInstance, isEventInstanceMultiDay, eventInstanceGroup]);

    React.useEffect(() => {
        if (showUpdateButton && eventInstance?.id && attendees?.length === 0) {
            dispatch(loadAttendeesForEventInstance({ eventInstanceId: eventInstance.id }));
        }
    }, [showUpdateButton, attendees?.length, eventInstance?.id, dispatch]);

    const isDigitalCourse = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;

    const currentUser = useSelector((state: any) => state.currentUser);
    const anonymous = shouldAttendeeSpecialRequirementsBeAnonymous(currentUser, eventInstance);

    const hasNoSpecialRequirementAttendees = hasNoSpecialRequirements(specialRequirementAttendees);
    const filteredSpecialRequirementAttendees = filterEmptySpecialRequirements(specialRequirementAttendees);

    const content = (
        <Grid className="event-instance-details">
            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <>
                    <h1 className="event-instance-title">Special requirements</h1>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EventInstanceBanner eventInstance={eventInstance} />
                        </Grid.Column>
                    </Grid.Row>
                    {showUpdateButton &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <UpdateSpecialRequirementsModal
                                    eventInstanceId={eventInstance.id}
                                    eventInstanceFinished={eventInstanceFinished}
                                    delegateAttendees={attendees}
                                    isDigitalCourse={isDigitalCourse}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {hasNoSpecialRequirementAttendees &&
                        <p>There are no special requirements for this course.</p>
                    }
                    {filteredSpecialRequirementAttendees.map((attendee, index) => (
                        <div key={`r-${attendee.id}`} className="event-instance-attendee-row">
                            <p key={`n-${attendee.id}`} className="event-instance-attendee-name">
                                <strong>{anonymous ? `Attendee ${index + 1}` : attendee.fullName}</strong>
                            </p>
                            <SpecialRequirementsDisplay
                                key={`sr-${attendee.id}`}
                                isDigitalCourse={isDigitalCourse}
                                specialRequirements={attendee.specialRequirements}
                                showMessageIfNoSpecialRequirements={false}
                            />
                        </div>

                    ))
                    }
                </>
            </Authorize>
        </Grid>
    );

    return <EiSideBar children={content} />;
};
