import {
    Criterium, PassFailResultEnum, SummarySubSectionCriterium,
    TrainerMonitoringReportCreateEditModel,
    TrainerMonitoringReportSection,
    TrainerMonitoringReportStatusEnum, TrainerMonitoringReportSubSection
} from "@common/crud/trainer/trainerMonitoringModel";
import { AllSchemeMonitoringForm } from "@common/crud/eventInstance/components/monitoring/questions/AllSchemeMonitoringForm";
import {
    QuestionCriterium, Questions, QuestionSection,
    QuestionSubSection, QuestionSummarySubSectionCriterium
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { TrainersCommentsSection } from "@common/crud/eventInstance/components/monitoring/questions/TrainersCommentsSection";
import { IQAReviewSection } from "@common/crud/eventInstance/components/monitoring/questions/IQAReviewSection";
import {
    IQAReviewSectionId,
    IQAReviewSectionSeqNum, TrainersCommentsSectionId,
    TrainersCommentsSectionSeqNum
} from "@common/crud/eventInstance/components/monitoring/MonitoringReportingConstants";

export class TrainerMonitoringReportBuilder {
    private readonly id: string;
    private readonly formType: string;
    private readonly schemeAbbreviation: string;
    private readonly trainerId: string;
    private trainerName: string;
    private readonly monitorId: string;
    private monitorName: string;
    private readonly relatedEventInstanceId: string;
    private readonly trainerMonitoringReportStatus: TrainerMonitoringReportStatusEnum;
    private summaryCriteria?: Criterium;
    private readonly sections: TrainerMonitoringReportSection[];

    constructor(formType: string, schemeAbbreviation: string, trainerId: string, monitorId: string, relatedEventInstanceId: string) {
        this.id = "";
        this.formType = formType;
        this.schemeAbbreviation = schemeAbbreviation;
        this.trainerId = trainerId;
        this.monitorId = monitorId;
        this.trainerMonitoringReportStatus = TrainerMonitoringReportStatusEnum.Draft;
        this.relatedEventInstanceId = relatedEventInstanceId;
        this.sections = this.buildSections();
    }

    public build(): TrainerMonitoringReportCreateEditModel {
        return {
            id: this.id,
            formType: this.formType,
            schemeAbbreviation: this.schemeAbbreviation,
            trainerId: this.trainerId,
            trainerName: this.trainerName,
            monitorId: this.monitorId,
            monitorName: this.monitorName,
            relatedEventInstanceId: this.relatedEventInstanceId,
            status: this.trainerMonitoringReportStatus,
            summaryCriteria: this.summaryCriteria,
            sections: this.sections,
            passFailResult: PassFailResultEnum.UnResolved
        };
    }

    private static buildCriterium(sectionId: string, subSectionId: string, questionCriterium: QuestionCriterium, index: number): Criterium {
        return {
            id: questionCriterium.id,
            sectionId,
            subSectionId,
            unsatisfactory: false,
            response: "",
            summarySubSection: TrainerMonitoringReportBuilder.buildSummarySubSectionCriterium(questionCriterium.summarySubSection)
        };
    }

    private static buildSummarySubSectionCriterium(summarySubSection: QuestionSummarySubSectionCriterium[]): SummarySubSectionCriterium[] {
        if (summarySubSection !== undefined) {
            return summarySubSection.map((s): SummarySubSectionCriterium => {
                return {
                    id: s.id,
                    unsatisfactory: false,
                    response: ""
                };
            });
        }
        return [];
    }

    private buildCriteria(sectionId: string, subSectionId: string, questionCriteria: QuestionCriterium[]): Criterium[] {
        if (questionCriteria !== undefined) {
            return questionCriteria.map((c: QuestionCriterium, index): Criterium =>
                TrainerMonitoringReportBuilder.buildCriterium(sectionId, subSectionId, c, index)
            );
        }
        return [];
    }

    private buildSection(questionSection: QuestionSection, sectionIndex: number): TrainerMonitoringReportSection {
        const seqNum = sectionIndex === IQAReviewSectionId ? IQAReviewSectionSeqNum :
            sectionIndex === TrainersCommentsSectionId ? TrainersCommentsSectionSeqNum :
                sectionIndex;

        const subSections = questionSection?.subSections?.map((ss: QuestionSubSection, index): TrainerMonitoringReportSubSection => {
            return {
                id: ss.id,
                sectionId: questionSection.id,
                seqNum,
                unsatisfactory: false,
                criteria: this.buildCriteria(questionSection.id, ss.id, ss.criteria)
            };
        });

        const reportSection: TrainerMonitoringReportSection = {
            seqNum,
            id: questionSection.id,
            title: questionSection.title,
            summaryCriteria: questionSection.summaryCriterium?.length > 0 ?
                questionSection.summaryCriterium.map((sc: QuestionCriterium, index) => TrainerMonitoringReportBuilder.buildCriterium(
                    questionSection.id, "", sc, index)) :
                undefined,
            subSections,
            IsQuestionSection: questionSection.IsQuestionSection
        };

        return reportSection;
    }

    private buildSchemeSections(scheme: Questions): TrainerMonitoringReportSection[] {
        const form = scheme.sections.map((e: any, index): TrainerMonitoringReportSection => {
            return this.buildSection(e as QuestionSection, index);
        });
        return form
            .concat(this.buildSection(TrainersCommentsSection, TrainersCommentsSectionId))
            .concat(this.buildSection(IQAReviewSection, IQAReviewSectionId));
    }

    private buildSections(): TrainerMonitoringReportSection[] {
        return this.buildSchemeSections(AllSchemeMonitoringForm);
    }
}
