/* eslint-disable max-lines */
import axios from "axios";
import * as QueryString from "query-string";
import moment from "moment";
import { SearchOptions, Order, EventInstanceWithOrdersListModel, OrderListResponse, EventInstanceWithOrdersResponse, OrderDetailModel, OrderEditModel,
    ReminderType, RecipientType, OrderRefundResponseModel, CorporateEventInstanceResultRow, CorporateEventTypeResultRow, Basket,
    CorporateUsersByEmail, ChangeBasketStatusResult, OrderCorporateUser, OrderCorporateOrganisation, TaxReceiptInformation,
    AttendeeSearchOptions, RefreshBasketBeforePaymentResult, OrganisationAutocompleteOption,
    MarkInvoiceAsPaidModel, CancelFromOrderModel, EventInstanceEnquiry } from "./model";
import { DateFormat } from "../common/DateTimeFormats";
import { HistoryRecord } from "@common/history/model";
import { AttendeeListModel } from "../attendee";
import { BusinessLineType } from "@common/redux-helpers";

export class OrderApi {

    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = "/api/order";
    }

    public async getAllOrders(options: SearchOptions): Promise<OrderListResponse> {
        const parsedOptions = {
            ...options,
            toDate: options.toDate ? options.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: options.fromDate ? options.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };
        const query = QueryString.stringify(parsedOptions);
        const response = await axios.get(`${this.apiUrl}/orders?${query}`);
        const data = response.data as OrderListResponse;
        return {
            ...data,
            orders: data.orders.map(this.parseOrderModel)
        };
    }

    public async getAllEventInstancesWithOrders(options: SearchOptions): Promise<EventInstanceWithOrdersResponse> {
        const parsedOptions = {
            ...options,
            toDate: options.toDate ? options.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: options.fromDate ? options.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };
        const query = QueryString.stringify(parsedOptions);
        const response = await axios.get(`${this.apiUrl}/eventInstancesWithOrders?${query}`);
        const data = response.data as EventInstanceWithOrdersResponse;
        return {
            ...data,
            eventInstancesWithOrders: data.eventInstancesWithOrders.map(this.parseEventInstanceWithOrdersModel)
        };
    }

    public async getAllAttendees(options: AttendeeSearchOptions): Promise<AttendeeListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}/attendees?${query}`);
        return response.data as AttendeeListModel[];
    }

    public async clearReservations(orderId: string): Promise<void> {
        await axios.post(`${this.apiUrl}/clearReservations/${orderId}`);
    }

    public async markInvoiceAsPaid(request: MarkInvoiceAsPaidModel): Promise<OrderDetailModel> {
        const response = await axios.post(`${this.apiUrl}/markInvoiceAsPaid`, request);
        const data = response.data as OrderDetailModel;
        return this.parseOrderModel(data);
    }

    public async detail(id: string): Promise<OrderDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as OrderDetailModel;
        return this.parseOrderModel(model);
    }

    public async addMorePlaces(orderId: string, eventInstanceId: string, adjustedSeats: number): Promise<boolean> {
        const response = await axios.post(`${this.apiUrl}/${orderId}/addMorePlaces`, {
            eventInstanceId,
            adjustedSeats,
        });
        return response.data as boolean;
    }

    public async cancelFromOrder(cancelFromOrderModel: CancelFromOrderModel): Promise<OrderDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${cancelFromOrderModel.orderId}/cancelFromOrder`, cancelFromOrderModel);
        const data = response.data as OrderDetailModel;
        return this.parseOrderModel(data);
    }

    public async refundFromOrder(refundFromOrderModel: { orderId: string; eventInstanceId?: string; refundAmount: number; doNotRefundOnStripe: boolean }):
        Promise<OrderRefundResponseModel> {
        const response = await axios.post(`${this.apiUrl}/${refundFromOrderModel.orderId}/refundFromOrder`, refundFromOrderModel);
        const data = response.data as OrderRefundResponseModel;
        const parsedData = this.parseOrderModel(data.order);
        return {
            ...data,
            order: {
                ...parsedData,
                history: parsedData.history.map(h => ({
                    ...h,
                    dateCreated: moment(h.dateCreated)
                }))
            }
        };
    }

    public async update(id: string, model: OrderEditModel): Promise<OrderDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${id}`, model);
        const data = response.data as OrderDetailModel;
        return this.parseOrderModel(data);
    }

    public async updateInvoiceReference(id: string, newReference: string): Promise<OrderDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${id}/invoiceReference`, { newReference });
        const data = response.data as OrderDetailModel;
        return this.parseOrderModel(data);
    }

    public async addNoteToOrder(id: string, note: string, file?: File): Promise<HistoryRecord[]> {
        const formData = new FormData();
        formData.append("note", note);
        formData.append("file", file);
        const response = await axios.post(
            `${this.apiUrl}/${id}/note`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        const data = response.data as HistoryRecord[];
        return data.map(h => ({
            ...h,
            dateCreated: moment(h.dateCreated)
        }));
    }

    public async updateOrderNote(id: string, noteId: string, note: string, removeAttachment: boolean): Promise<HistoryRecord[]> {
        const response = await axios.patch(`${this.apiUrl}/${id}/note`, { noteId, note, removeAttachment });
        const data = response.data as HistoryRecord[];
        return data.map(h => ({
            ...h,
            dateCreated: moment(h.dateCreated)
        }));
    }

    public async changeBooker(id: string, bookerId: string): Promise<OrderDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${id}/changeBooker`, { bookerId });
        const data = response.data as OrderDetailModel;
        return this.parseOrderModel(data);
    }

    public async getOfferedCourseTypes(businessLineType: BusinessLineType): Promise<CorporateEventTypeResultRow[]> {
        const query = QueryString.stringify({
            businessLineType
        });
        const response = await axios.get(`${this.apiUrl}/offeredCourseTypes?${query}`);
        return response.data as CorporateEventTypeResultRow[];
    }

    public async getOfferedCourses(businessLineType: BusinessLineType, page: number, eventTypeIds?: string[], dateFrom?: moment.Moment, dateTo?: moment.Moment,
        daysOfWeek?: number[], relatedOrganisationId?: string): Promise<CorporateEventInstanceResultRow[]> {
        const query = QueryString.stringify({
            businessLineType,
            eventTypeIds,
            page,
            dateFrom: dateFrom?.format(DateFormat.ISODateFormat),
            dateTo: dateTo?.format(DateFormat.ISODateFormat),
            daysOfWeek,
            relatedOrganisationId
        });
        const response = await axios.get(`${this.apiUrl}/offeredCourses?${query}`);
        const data = response.data as CorporateEventInstanceResultRow[];
        return data.map(model => ({
            ...model,
            startTimes: model.startTimes && model.startTimes.map(t => moment(t)),
            endTimes: model.endTimes && model.endTimes.map(t => moment(t)),
        }));
    }

    public async checkForExistingBasket(businessLineType: BusinessLineType, corporateOrganisationId?: string, corporateUserId?: string): Promise<string> {
        const query = QueryString.stringify({ corporateOrganisationId, corporateUserId, businessLineType });
        const response = await axios.get(`${this.apiUrl}/checkForExistingBasket?${query}`);
        return response.data as string;
    }

    public async clearOldBaskets(businessLineType: BusinessLineType): Promise<void> {
        await axios.post(`${this.apiUrl}/clearOldBaskets`, { businessLineType });
    }

    public async prepareEnquiryBasket(businessLineType: BusinessLineType, eventInstanceId: string, enquiryId: string): Promise<void> {
        await axios.post(`${this.apiUrl}/prepareEnquiryBasket`, { businessLineType, eventInstanceId, enquiryId });
    }

    public async getBasket(businessLineType: BusinessLineType, corporateOrganisationId?: string, corporateUserId?: string): Promise<Basket> {
        const query = QueryString.stringify({
            corporateOrganisationId,
            corporateUserId,
            businessLineType
        });
        const response = await axios.get(`${this.apiUrl}/basket?${query}`);
        return this.parseBasket(response.data as Basket);
    }

    public async updateBasketItem(businessLineType: BusinessLineType, basketId: string, eventInstanceId: string, numberOfSeats: number,
        add: boolean): Promise<Basket> {
        const response = await axios.post(`${this.apiUrl}/basket`, { businessLineType, basketId, eventInstanceId, numberOfSeats, add });
        return this.parseBasket(response.data as Basket);
    }

    public async confirmBasketCourses(businessLineType: BusinessLineType, basketId: string): Promise<ChangeBasketStatusResult> {
        const response = await axios.post(`${this.apiUrl}/basket/confirmCourses`, { businessLineType, basketId });
        return response.data as ChangeBasketStatusResult;
    }
    public async refreshBasketBeforePayment(businessLineType: BusinessLineType, basketId: string, orderCorporateUser?: OrderCorporateUser,
        orderCorporateOrganisation?: OrderCorporateOrganisation,
        orderTaxReceiptInformation?: TaxReceiptInformation): Promise<RefreshBasketBeforePaymentResult> {
        const response = await axios.post(`${this.apiUrl}/basket/refreshBasketBeforePayment`, {
            businessLineType, basketId, orderCorporateUser, orderCorporateOrganisation, orderTaxReceiptInformation
        });
        return response.data as RefreshBasketBeforePaymentResult;
    }

    public async changeBasketRelatedOrganisation(businessLineType: BusinessLineType, basketId: string, relatedOrganisationId: string): Promise<Basket> {
        const response = await axios.post(`${this.apiUrl}/basket/changeRelatedOrganisation`, { businessLineType, basketId, relatedOrganisationId });
        return this.parseBasket(response.data as Basket);
    }

    public async saveDetailsAndBilling(businessLineType: BusinessLineType, basketId: string, orderCorporateUser?: OrderCorporateUser,
        orderCorporateOrganisation?: OrderCorporateOrganisation, orderTaxReceiptInformation?: TaxReceiptInformation): Promise<Basket> {
        const response = await axios.post(`${this.apiUrl}/basket/saveDetailsAndBilling`, { businessLineType, basketId, orderCorporateUser,
            orderCorporateOrganisation, orderTaxReceiptInformation });
        return response.data as Basket;
    }

    public async goBackToCourses(businessLineType: BusinessLineType, basketId: string, orderCorporateUser?: OrderCorporateUser,
        orderCorporateOrganisation?: OrderCorporateOrganisation, orderTaxReceiptInformation?: TaxReceiptInformation): Promise<Basket> {
        const response = await axios.post(`${this.apiUrl}/basket/goBackToCourses`, { businessLineType, basketId, orderCorporateUser, orderCorporateOrganisation,
            orderTaxReceiptInformation });
        return this.parseBasket(response.data as Basket);
    }

    public async getBookedCoursesFromIds(businessLineType: BusinessLineType, eventInstanceIds: string[],
        relatedOrganisationId?: string): Promise<CorporateEventInstanceResultRow[]> {
        const response = await axios.post<CorporateEventInstanceResultRow[]>(`${this.apiUrl}/bookedCoursesFromIds`, {
            businessLineType,
            eventInstanceIds,
            relatedOrganisationId
        });
        const data = response.data as CorporateEventInstanceResultRow[];
        return data.map(model => ({
            ...model,
            startTimes: model.startTimes && model.startTimes.map(t => moment(t)),
            endTimes: model.endTimes && model.endTimes.map(t => moment(t)),
        }));
    }

    public async startRebooking(businessLineType: BusinessLineType, selectedEventInstances: { id: string; selectedAttendees: string[];
        attendeesNotExpanded?: boolean; }[], corporateOrganisationId?: string, corporateUserId?: string): Promise<Basket> {
        const query = QueryString.stringify({
            businessLineType,
            corporateOrganisationId,
            corporateUserId
        });
        const response = await axios.post(`${this.apiUrl}/startRebooking?${query}`, { selectedEventInstances });
        return this.parseBasket(response.data as Basket);
    }

    public async rebookSeat(businessLineType: BusinessLineType, basketId: string, originalEventInstanceId: string, originalAttendeeId: string,
        rebookedEventInstanceId: string, waiveFees?: boolean, zeroFees?: boolean): Promise<Basket> {
        const response = await axios.post(`${this.apiUrl}/rebookSeat`, { businessLineType, basketId, originalEventInstanceId, originalAttendeeId,
            rebookedEventInstanceId,  waiveFees, zeroFees });
        return this.parseBasket(response.data as Basket);
    }

    public async getOrganisationUserEmails(organisationId: string): Promise<string[]> {
        const response = await axios.get<string[]>(`${this.apiUrl}/organisationUserEmails/${organisationId}`);
        return response.data;
    }

    public async getOrganisationsAutocompleteOptions(businessLineType: BusinessLineType): Promise<OrganisationAutocompleteOption[]>  {
        const response = await axios.get<OrganisationAutocompleteOption[]>(`${this.apiUrl}/organisationsAutocompleteOptions/${businessLineType}`);
        return response.data;
    }

    public parseBasket<T extends Basket>(model: T): T {
        return ({
            ...model,
            rebookedEventInstances: model.rebookedEventInstances?.map((eventInstance) => ({
                ...eventInstance,
                eventInstanceDeliveryDateTime: eventInstance.eventInstanceDeliveryDateTime
                    ? moment(eventInstance.eventInstanceDeliveryDateTime)
                    : undefined,
                eventInstanceDeliveryDateTimeEnd: eventInstance.eventInstanceDeliveryDateTimeEnd
                    ? moment(eventInstance.eventInstanceDeliveryDateTimeEnd)
                    : undefined,
                rebookedAttendees: eventInstance.rebookedAttendees?.map((attendee) => ({
                    ...attendee,
                    rebookedEventInstanceDeliveryDateTime: attendee.rebookedEventInstanceDeliveryDateTime
                        ? moment(attendee.rebookedEventInstanceDeliveryDateTime)
                        : undefined,
                    rebookedEventInstanceDeliveryDateTimeEnd: attendee.rebookedEventInstanceDeliveryDateTimeEnd
                        ? moment(attendee.rebookedEventInstanceDeliveryDateTimeEnd)
                        : undefined
                }))
            }))
        });
    }

    public parseOrderModel<T extends Order>(model: T): T {
        return {
            ...model,
            bookingDate: model.bookingDate
                ? moment(model.bookingDate)
                : undefined,
            cancellationDate: model.cancellationDate
                ? moment(model.cancellationDate)
                : undefined,
            eventInstances: model.eventInstances
                ? model.eventInstances.map((eventInstance) => ({
                    ...eventInstance,
                    eventInstanceDeliveryDateTime: eventInstance.eventInstanceDeliveryDateTime
                        ? moment(eventInstance.eventInstanceDeliveryDateTime)
                        : undefined,
                    cancellationDate: eventInstance.cancellationDate
                        ? moment(eventInstance.cancellationDate)
                        : undefined,
                    eventInstanceStartDate: eventInstance.eventInstanceStartDate && moment(eventInstance.eventInstanceStartDate).utc().startOf("day"),
                    eventInstanceDuration: eventInstance.eventInstanceDuration && moment.duration(eventInstance.eventInstanceDuration),
                    eventInstanceStartTime: eventInstance.eventInstanceStartTime && moment.duration(eventInstance.eventInstanceStartTime),
                    otherStartTimes: eventInstance.otherStartTimes ? eventInstance.otherStartTimes.map((otherStartTime) => ({
                        eventInstanceDeliveryDateTime: otherStartTime.eventInstanceDeliveryDateTime
                            ? moment(otherStartTime.eventInstanceDeliveryDateTime)
                            : undefined,
                        eventInstanceStartDate: otherStartTime.eventInstanceStartDate && moment(otherStartTime.eventInstanceStartDate).utc().startOf("day"),
                        eventInstanceDuration: otherStartTime.eventInstanceDuration && moment.duration(otherStartTime.eventInstanceDuration),
                        eventInstanceStartTime: otherStartTime.eventInstanceStartTime && moment.duration(otherStartTime.eventInstanceStartTime),
                    })) : [],
                }))
                : [],
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated)
            }))
        };
    }

    public parseEventInstanceWithOrdersModel<T extends EventInstanceWithOrdersListModel>(model: T): T {
        return {
            ...model,
            eventInstanceDeliveryDateTime: model.eventInstanceDeliveryDateTime
                ? moment(model.eventInstanceDeliveryDateTime)
                : undefined,
            eventInstanceDeliveryDateTimeEnd: model.eventInstanceDeliveryDateTimeEnd
                ? moment(model.eventInstanceDeliveryDateTimeEnd)
                : undefined,
            cancellationDate: model.cancellationDate
                ? moment(model.cancellationDate)
                : undefined,
            startTimes: model.startTimes
                ? model.startTimes.map(t => moment(t))
                : [],
            endTimes: model.endTimes
                ? model.endTimes.map(t => moment(t))
                : [],
        };
    }

    public async sendConfirmation(orderCommsModel: { orderId: string; eventInstanceId?: string}): Promise<void> {
        await axios.post(`${this.apiUrl}/${orderCommsModel.orderId}/sendConfirmation`, orderCommsModel);
    }

    public async sendReminders(orderCommsModel: { orderId: string; eventInstanceId: string; reminderType: ReminderType;
        recipientTypes: RecipientType[]; }): Promise<void> {
        await axios.post(`${this.apiUrl}/${orderCommsModel.orderId}/sendReminders`, orderCommsModel);
    }

    public async checkEmail(email: string, businessLineType: BusinessLineType): Promise<CorporateUsersByEmail> {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get<CorporateUsersByEmail>(`${this.apiUrl}/checkemail?businessLineType=${businessLineType}&email=${encodedEmail}`);
        return response.data;
    }

    public async prepareEnquiryData(corporateOrganisationId?: string, corporateUserId?: string): Promise<EventInstanceEnquiry> {
        const query = QueryString.stringify({
            corporateOrganisationId,
            corporateUserId
        });
        const response = await axios.get<EventInstanceEnquiry>(`${this.apiUrl}/prepareEnquiryData?${query}`);
        return response.data;
    }
}
