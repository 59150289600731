/* eslint-disable max-lines */
import { AvailabilityType, AvailableDate, SessionNumber } from "@common/availabilityTrainer/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventType, GetEventDuration, SessionsIncludedInDigitalCalendarAutoAdd, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { toast } from "@common/toasts";
import moment from "moment";
import * as React from "react";
import { Button, Checkbox, CheckboxProps, Grid, Popup } from "semantic-ui-react";
import { v4 } from "uuid";
import { EventInstance, SESSION_FOUR_START_HOUR, SESSION_THREE_START_HOUR, SESSION_TWO_START_HOUR } from "../../model";
import { StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";
import {
    CheckForOverlappingEventsForEventInstance,
    IsTrainerAllocatedToClassroomEiOnDay
} from "./DigitalPlanningHelpers";

export interface QuickPlanProps {
    trainerAvailability: AvailableDate[];
    trainerExpiryDate?: moment.Moment;
    selectedMonth: moment.Moment;
    selectedEventType: EventType;
    selectedTrainerId: string;
    selectedEventsPerDay: number;
    existingEvents: EventInstance[];
    digitallyPlannedEvents: EventInstance[];
    setDigitallyPlannedEvents: React.Dispatch<React.SetStateAction<EventInstance[]>>;
    standbyCalendar: StandbyTrainersCalendarDay[];
    getStartTimeForSession: (session: number) => moment.Duration;
}

export const QuickPlan = (props: QuickPlanProps) => {
    const {
        trainerAvailability,
        trainerExpiryDate,
        selectedMonth,
        selectedEventType,
        selectedTrainerId,
        selectedEventsPerDay,
        existingEvents,
        digitallyPlannedEvents,
        setDigitallyPlannedEvents,
        getStartTimeForSession,
        standbyCalendar
    } = props;

    const [selectedSessions, setSelectedSessions] = React.useState<Set<SessionNumber>>(new Set());
    const [initialisedSelectedSessions, setInitialisedSelectedSessions] = React.useState(false);
    const [digitalTrainerAvailabilityForSelectedMonth, setDigitalTrainerAvailabilityForSelectedMonth] = React.useState<AvailableDate[]>([]);
    const existingClassroomEventsInSelectedMonthForSelectedTrainer = React.useMemo(() => (
        existingEvents?.filter(e => e.startDate.month() === selectedMonth.month() && e.trainerIds.includes(selectedTrainerId))
    ), [existingEvents, selectedMonth, selectedTrainerId]);
    const existingEventsInSelectedMonthForSelectedTrainer = React.useMemo(() => (
        existingEvents?.filter(e => e.startDate.month() === selectedMonth.month() && e.allTrainerIds.includes(selectedTrainerId))
    ), [existingEvents, selectedMonth, selectedTrainerId]);
    const [overrideEnabled, setOverrideEnabled] = React.useState(false);
    const [autoAddEnabled, setAutoAddEnabled] = React.useState(false);
    const [autoAddPopupMessage, setAutoAddPopupMessage] = React.useState("");
    const { isDayBankHoliday } = useBankHolidays();

    const getDigitalSessionAvailabilities = (availability: AvailableDate) =>
        availability.sessionAvailabilities.filter(a => a.type === AvailabilityType.Digital || a.type === AvailabilityType.ClassroomAndDigital);

    const selectedEventTypeRef = React.useRef<EventType>();
    React.useEffect(() => {
        if (!selectedEventType) {
            return;
        }

        let popupMessage = "";
        let enabled = true;

        if (!selectedEventType.digitalEventTypeDetails.sessionsIncludedInDigitalCalendarAutoAdd) {
            popupMessage = "Sessions per day has not been set on the scheme";
            enabled = false;
            if (selectedEventTypeRef.current !== selectedEventType) {
                toast.warning("Auto Add disabled as sessions per day has not been set on the scheme");
            }
        } else if (!selectedEventsPerDay) {
            popupMessage = "You need to select a course number model";
            enabled = false;
        } else if (selectedEventsPerDay < Math.max(...Array.from(selectedSessions))) {
            popupMessage = "The current course number model is incompatible with the selected sessions";
            enabled = false;
        }

        selectedEventTypeRef.current = selectedEventType;
        setAutoAddEnabled(enabled);
        setAutoAddPopupMessage(popupMessage);
    }, [selectedEventType, selectedEventsPerDay, selectedSessions]);

    const trainerAvailabilityRef = React.useRef<AvailableDate[]>();
    React.useEffect(() => {
        if (!trainerAvailability) {
            deselectAllSessions();
            setDigitalTrainerAvailabilityForSelectedMonth([]);
        } else if (!initialisedSelectedSessions || trainerAvailabilityRef.current !== trainerAvailability) {
            trainerAvailabilityRef.current = trainerAvailability;
            const newDigitalTrainerAvailabilityForSelectedMonth: AvailableDate[] = [];
            const newSelectedSessions = new Set<SessionNumber>();

            for (const availability of trainerAvailability) {

                const digitalSessionAvailabilities = getDigitalSessionAvailabilities(availability);

                if (availability.date.year() === selectedMonth.year() &&
                    availability.date.month() === selectedMonth.month() &&
                    digitalSessionAvailabilities.length > 0) {
                    newDigitalTrainerAvailabilityForSelectedMonth.push(availability);
                    digitalSessionAvailabilities.forEach(a => newSelectedSessions.add(a.session as SessionNumber));
                }
            }

            setSelectedSessions(newSelectedSessions);
            setDigitalTrainerAvailabilityForSelectedMonth(newDigitalTrainerAvailabilityForSelectedMonth);
            setInitialisedSelectedSessions(true);
        }

    }, [trainerAvailability, selectedMonth, initialisedSelectedSessions]);

    function deselectAllSessions() {
        setSelectedSessions(new Set());
    }

    function onSessionCheckboxChange(session: SessionNumber) {
        return function(_: any, { checked }: CheckboxProps) {
            const newSelectedSessions = new Set(selectedSessions);
            if (checked) {
                newSelectedSessions.add(session);
            } else {
                newSelectedSessions.delete(session);
            }
            setSelectedSessions(newSelectedSessions);
        };
    }

    function onAutoAddClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.currentTarget.blur();

        if (digitalTrainerAvailabilityForSelectedMonth.length === 0) {
            return;
        }

        if (overrideEnabled) {
            setDigitallyPlannedEvents(createEvents());
        } else {
            setDigitallyPlannedEvents(prev => [...prev, ...createEvents()]);
        }
    }

    function getDayOfWeekStringFromDate(date: moment.Moment): keyof SessionsIncludedInDigitalCalendarAutoAdd {
        const map = {
            0: "sunday",
            1: "monday",
            2: "tuesday",
            3: "wednesday",
            4: "thursday",
            5: "friday",
            6: "saturday"
        };
        return map[date.day()];
    }

    function eventTypeAllowsSessionOnDate(session: SessionNumber, date: moment.Moment) {
        const dayOfWeek = getDayOfWeekStringFromDate(date);
        return selectedEventType.digitalEventTypeDetails.sessionsIncludedInDigitalCalendarAutoAdd?.[dayOfWeek]?.includes(session);
    }

    function createEvents() {
        const newDigitallyPlannedEvents: EventInstance[] = [];

        for (const dayAvailability of digitalTrainerAvailabilityForSelectedMonth) {
            if (isDayBankHoliday(dayAvailability.date)) {
                continue;
            }

            if (trainerExpiryDate && trainerExpiryDate <= dayAvailability.date) {
                continue;
            }

            const eventDuration = GetEventDuration(dayAvailability.date, DeliveryTypeEnum.Digital, selectedEventType);
            const digitalSessionAvailabilities = getDigitalSessionAvailabilities(dayAvailability);

            if (IsTrainerAllocatedToClassroomEiOnDay(dayAvailability, existingClassroomEventsInSelectedMonthForSelectedTrainer)) {
                continue;
            }

            const standbyArrangementsForDay = standbyCalendar?.find(d => d.date.date() === dayAvailability.date.date());
            const sessionOneStandby = standbyArrangementsForDay?.sessionOneStandbyTrainerIds?.length > 0 ?? false;
            const sessionTwoStandby = standbyArrangementsForDay?.sessionTwoStandbyTrainerIds?.length > 0 ?? false;
            const sessionThreeStandby = standbyArrangementsForDay?.sessionThreeStandbyTrainerIds?.length > 0 ?? false;
            const sessionFourStandby = standbyArrangementsForDay?.sessionFourStandbyTrainerIds?.length > 0 ?? false;

            for (const sessionAvailability of digitalSessionAvailabilities) {
                if (!selectedSessions.has(sessionAvailability.session as SessionNumber) ||
                    !eventTypeAllowsSessionOnDate(sessionAvailability.session as SessionNumber, dayAvailability.date)) {
                    continue;
                }

                if (sessionAvailability.session === 1 && sessionOneStandby ||
                    sessionAvailability.session === 2 && sessionTwoStandby ||
                    sessionAvailability.session === 3 && sessionThreeStandby ||
                    sessionAvailability.session === 4 && sessionFourStandby) {
                    continue;
                }

                const plannedEvent: EventInstance = {
                    id: v4(),
                    eventTypeId: selectedEventType.id,
                    startDate: dayAvailability.date,
                    startTime: getStartTimeForSession(sessionAvailability.session),
                    eventTypeAbbreviation: selectedEventType.abbreviation,
                    trainerId: selectedTrainerId,
                    eventDuration,
                    workflowType: WorkflowTypeEnum.Dors,
                    dayParts: []
                };

                const existingPlannedEvents = overrideEnabled ? [] : digitallyPlannedEvents;
                if (CheckForOverlappingEventsForEventInstance(plannedEvent, existingEventsInSelectedMonthForSelectedTrainer, existingPlannedEvents)) {
                    continue;
                }

                newDigitallyPlannedEvents.push(plannedEvent);
            }
        }

        return newDigitallyPlannedEvents;
    }

    function getSessionFromStartTime(startTime: moment.Duration) {
        const hour = startTime.hours();
        if (hour < SESSION_TWO_START_HOUR) {
            return 1;
        } else if (hour < SESSION_THREE_START_HOUR) {
            return 2;
        } else if (hour < SESSION_FOUR_START_HOUR) {
            return 3;
        }
        return 4;
    }

    function clearPlannedEventsForSelectedSessions() {
        setDigitallyPlannedEvents(prev => prev.filter(e => (
            e.trainerId !== selectedTrainerId ||
            e.startDate.month() !== selectedMonth.month() ||
            e.startDate.year() !== selectedMonth.year() ||
            !selectedSessions.has(getSessionFromStartTime(e.startTime))
        )));
    }

    function onClearClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.currentTarget.blur();
        clearPlannedEventsForSelectedSessions();
    }

    function onOverrideToggleChanged() {
        setOverrideEnabled(prev => !prev);
    }

    const sessions: SessionNumber[] = [1, 2, 3, 4];

    return (
        <>
            <h3>Sessions</h3>
            <Grid padded="horizontally">
                <Grid.Row>
                    {sessions.map(s => (
                        <Grid.Column width={4} key={s}>
                            <Checkbox
                                label={s}
                                checked={selectedSessions.has(s)}
                                onChange={onSessionCheckboxChange(s)}
                                className="quick-plan-checkbox"
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
                <Popup
                    content={autoAddPopupMessage}
                    disabled={autoAddEnabled}
                    trigger={
                        <Grid.Row>
                            <Grid.Column width={16} className="flex-row space-around">
                                <Button content="Auto Add" onClick={onAutoAddClick} disabled={!autoAddEnabled} />
                                <Button content="Clear" onClick={onClearClick} disabled={!autoAddEnabled} />
                            </Grid.Column>
                        </Grid.Row>
                    }
                />
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Checkbox
                            toggle
                            label="Override"
                            checked={overrideEnabled}
                            disabled={!autoAddEnabled}
                            onChange={onOverrideToggleChanged}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};
