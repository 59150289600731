import { Location } from "redux-little-router";
import moment from "moment";
import { HistoryRecord } from "@common/history/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { AttendanceIdValidityModel, EventTypeCategory } from "@common/crud/attendee/model";
import { DataToUseFromDorsForAttendeeModel, UpdateAttendeeFromDorsModel } from "../attendee/model";

export interface DorsBookingListModel {
    readonly id: string;
    readonly attendanceId?: number;
    readonly eventTypeName?: string;
    readonly eventTypeId?: string;
    readonly forename?: string;
    readonly surname?: string;
    readonly driverNumber?: string;
    readonly email?: string;
    readonly telephone?: string;
    readonly listUpdated?: moment.Moment;
    readonly isTechnicalAssistanceRequired?: boolean;
    readonly requiresSupervisorAssistance?: boolean;
    readonly shortNoticeCompletionRequiresSpace?: boolean;
}

export interface DorsBookingDetailModel {
    readonly id: string;
    readonly attendanceId?: number;
    readonly ticketId?: string;
    readonly offenceId?: number;
    readonly expiryDate?: moment.Moment;
    readonly forceId?: number;
    readonly forceName?: string;
    readonly driverNumber?: string;
    readonly forename?: string;
    readonly surname?: string;
    readonly buildingNumber?: string;
    readonly buildingName?: string;
    readonly address1?: string;
    readonly address2?: string;
    readonly address3?: string;
    readonly townCity?: string;
    readonly postcode?: string;
    readonly country?: string;
    readonly email?: string;
    readonly telephone?: string;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly schemeId?: number;
    readonly detailUpdated?: moment.Moment;
    readonly dorsAttendanceStatus?: DorsAttendanceStatusesEnum;
    readonly historyRecords?: HistoryRecord[];
    readonly nextReservedEventInstanceId?: string;
    readonly isTechnicalAssistanceRequired?: boolean;
    readonly requiresSupervisorAssistance?: boolean;
    readonly shortNoticeCompletionRequiresSpace?: boolean;
    readonly technicalAssistanceComment?: string;
    readonly supervisorAssistanceComment?: string;
    readonly shortNoticeRequiresSpaceComment?: string;
    readonly initialEventInstance?: string;
    readonly isTransferred?: boolean;
    readonly flexiPayPurchased?: boolean;
    readonly canUseFlexiPay?: boolean;
    readonly initialBookingDate?: moment.Moment;
    readonly eventTypeCategory?: EventTypeCategory;
    readonly accountIsLocked?: boolean;
}

export interface LinkedBookingResponse {
    hasDifferentBookings: boolean;
    attendanceId: string;
    name: string;
    issuedBy: string;
    hasBookedAttendee: string;
    isOfferWithdrawn: boolean;
    hasCancelledAttendee: boolean;
    dorsAttendanceStatus: DorsAttendanceStatusesEnum;
    disableBooking: boolean;
}

export interface IvrPaymentStatusModel {
    isAdminPayment: boolean;
    isSeatReserved: boolean;
    paymentComplete: boolean;
    eventInstanceId: string;
    attendeeId: string;
    flexiPayPurchased: boolean;
}

export interface UpdateAttendanceIdRequest {
    correlationId: string;
    eventInstanceId: string;
    attendeeId: string;
    attendanceId: number;
    allowReuse: boolean;
}

export interface UpdateAttendanceIdWithDifferencesRequest {
    correlationId: string;
    eventInstanceId: string;
    attendeeId: string;
    attendanceId: number;
    newDorsBookingId: string;
    submitModel: DataToUseFromDorsForAttendeeModel;
}

export interface UpdateAttendanceIdResponse {
    success: boolean;
    noMatchingBookingFound: boolean;
    attendanceIdValidityModel: AttendanceIdValidityModel;
    updateAttendeeFromDorsModel: UpdateAttendeeFromDorsModel;
    newDorsBookingId: string;
    attendanceIdChanged: boolean;
}

export interface UpdateEventTypeIdAndNameRequest {
    dorsBookingId: string;
    eventTypeId: string;
    eventTypeName: string;
}

export type DorsBooking = DorsBookingListModel & DorsBookingDetailModel;

export enum DorsAttendanceStatusesEnum {
    NotSet = 0,
    BookingPending = 1,
    Booked = 2,
    BookedAndPaid = 3,
    AttendedAndCompleted = 4,
    AttendedAndNotCompleted = 5,
    DidNotAttend = 6,
    OfferWithdrawn = 7,
    WorkbookSent = 9,
    AssessmentAttempted = 10,
    CourseAccessed = 11,
    BookedOnWrongCourse = 100
}

export const DorsAttendanceStatusesKeys = {
    [DorsAttendanceStatusesEnum.NotSet]: "Unknown",
    [DorsAttendanceStatusesEnum.BookingPending]: "Booking Pending",
    [DorsAttendanceStatusesEnum.Booked]: "Booked",
    [DorsAttendanceStatusesEnum.BookedAndPaid]: "Booked And Paid",
    [DorsAttendanceStatusesEnum.AttendedAndCompleted]: "Attended And Completed",
    [DorsAttendanceStatusesEnum.AttendedAndNotCompleted]: "Attended And Not Completed",
    [DorsAttendanceStatusesEnum.DidNotAttend]: "Did Not Attend",
    [DorsAttendanceStatusesEnum.OfferWithdrawn]: "Offer Withdrawn",
    [DorsAttendanceStatusesEnum.WorkbookSent]: "Workbook Sent",
    [DorsAttendanceStatusesEnum.AssessmentAttempted]: "Assessment Attempted",
    [DorsAttendanceStatusesEnum.CourseAccessed]: "Course Accessed",
    [DorsAttendanceStatusesEnum.BookedOnWrongCourse]: "Booked On Wrong Course"
};

export enum DorsAttendanceStatuses {
    NotSet = "Not Set",
    BookingPending = "Booking Pending",
    Booked = "Booked",
    BookedAndPaid = "Booked And Paid",
    AttendedAndCompleted = "Attended And Completed",
    AttendedAndNotCompleted = "Attended And Not Completed",
    DidNotAttend = "Did Not Attend",
    OfferWithdrawn = "Offer Withdrawn",
    WorkbookSent = "Workbook Sent",
    AssessmentAttempted = "Assessment Attempted",
    CourseAccessed = "Course Accessed"
}

export interface AssistanceRequired {
    isTechnicalAssistanceRequired?: boolean;
    requiresSupervisorAssistance?: boolean;
    shortNoticeCompletionRequiresSpace?: boolean;
    technicalAssistanceComment?: string;
    supervisorAssistanceComment?: string;
    shortNoticeRequiresSpaceComment?: string;
    taskRelation?: TaskRelationEnum;
    isIvrBooking?: boolean;
}

export enum SupportRequestAttribute {
    None = 0,
    TechnicalAssistanceRequired = 1,
    RequiresSupervisorAssistance = 2,
    ShortNoticeCompletionRequiresSpace = 3
}

export const SupportRequestAttributes = {
    [SupportRequestAttribute.None]: "None",
    [SupportRequestAttribute.TechnicalAssistanceRequired]: "Technical Assistance Required",
    [SupportRequestAttribute.RequiresSupervisorAssistance]: "Requires Supervisor Assistance",
    [SupportRequestAttribute.ShortNoticeCompletionRequiresSpace]: "Short Notice Completion, Requires Last Minute Space"
};

export const SupportRequestPictures = {
    [SupportRequestAttribute.None]: "",
    [SupportRequestAttribute.TechnicalAssistanceRequired]: "/assets/technical-assistance.png",
    [SupportRequestAttribute.RequiresSupervisorAssistance]: "/assets/supervisor.png",
    [SupportRequestAttribute.ShortNoticeCompletionRequiresSpace]: "/assets/last-minute.png"
};

export interface DorsBookingState {
    readonly dorsBookings: DorsBooking[];
}

export const getFlexiPaySessionStorageKey = (bookingId: string) => `booking-flexiPayChecked-${bookingId}`;

export type AppState = DorsBookingState  & { router: Location };
