import * as React from "react";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventTypePartEdit } from "@common/crud/eventType/components/EventTypePartEdit";
import moment from "moment";
import { EventType, EventTypePart } from "@common/crud/eventType/model";
import { BankHoliday } from "@common/bankHolidays/model";

export interface MultiDayEventTypePartEditProps {
    deliveryType: DeliveryTypeEnum;
    updateEventTypeParts: (eventTypeParts: Dictionary<EventTypePart>) => void;
    eventTypeParts: Dictionary<EventTypePart>;
    groupItemStartDates?: Dictionary<moment.Moment>;
    updateStartDates?:  ( value: Dictionary<moment.Moment>, valid: boolean) => void;
    updateSpecificStartDate?: (day: string, value: moment.Moment, valid: boolean) => void;
    firstStartDate: moment.Moment;
    bankHolidays: BankHoliday[];
    eventType: EventType;
}

export const MultiDayEventTypePartEdit: React.FC<MultiDayEventTypePartEditProps> = ({
    deliveryType, updateEventTypeParts, eventTypeParts, groupItemStartDates, updateStartDates, updateSpecificStartDate, firstStartDate, bankHolidays,
    eventType }) => {
    const updateEventTypePart = React.useCallback((key: string, value: EventTypePart) => {
        if (key) {
            const newEventTypeParts = { ...eventTypeParts };
            newEventTypeParts[key] = value;
            updateEventTypeParts(newEventTypeParts);
        }
    }, [eventTypeParts, updateEventTypeParts]);

    const updateSuggestedTime = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, suggestedStartTime: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    const updateSuggestedDuration = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, eventDuration: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    const updateSuggestedRegistrationDuration = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, registrationDuration: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    const numberOfDayParts = React.useMemo(() => groupItemStartDates
        ? Object.keys(groupItemStartDates).length
        : null
    , [groupItemStartDates]);

    const multiDayType = React.useMemo(() => deliveryType === DeliveryTypeEnum.Onsite
        ? eventType.classroomEventTypeDetails.defaultMultiDayType
        : eventType.digitalEventTypeDetails.defaultMultiDayType
    , [deliveryType, eventType]);

    return (
        <>
            {
                eventTypeParts && Object.entries(eventTypeParts).map(
                    ([key, value]) => {
                        const label = `Start time for Day ${key}`;
                        const groupItemStartDate = groupItemStartDates?.[key];
                        return (
                            <EventTypePartEdit
                                bankHolidays={bankHolidays}
                                firstStartDate={firstStartDate}
                                key={key}
                                itemKey={key}
                                label={label}
                                eventTypePart={value}
                                updateSuggestedDuration={updateSuggestedDuration}
                                updateSuggestedRegistrationDuration={updateSuggestedRegistrationDuration}
                                updateSuggestedTime={updateSuggestedTime}
                                groupItemStartDate={groupItemStartDate}
                                updateStartDates={updateStartDates}
                                updateSpecificStartDate={updateSpecificStartDate}
                                numberOfDayParts={numberOfDayParts}
                                multiDayType={multiDayType}
                            />);
                    })}
        </>
    );
};
