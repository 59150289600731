import * as React from "react";
import { Grid } from "semantic-ui-react";
import { RegisterStats } from "./helpers";
import { Media } from "@common/global/AppMedia";

export interface RegisterStatisticsProps {
    showCompletion?: boolean;
    registerStats: RegisterStats;
    updateDelegatesElement?: JSX.Element;
    removedAttendeeElement?: JSX.Element;
    blockAddingDelegatesElement?: JSX.Element;
}

export const RegisterStatistics: React.FC<RegisterStatisticsProps> = ({ registerStats, removedAttendeeElement, showCompletion, updateDelegatesElement,
    blockAddingDelegatesElement }) => (
    <>
        <Grid.Row className="no-padding-top no-padding-bottom">
            <Grid.Column width={8} textAlign="left">
                {removedAttendeeElement}
                <Media greaterThanOrEqual="computer">
                    {updateDelegatesElement}
                </Media>
                {blockAddingDelegatesElement}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-top">
            <Grid.Column width={14} className="bold mobile-align" textAlign="right">
                Total places booked
            </Grid.Column>
            <Grid.Column width={2} textAlign="right" className="purple-header">
                <strong>{registerStats.totalPlacesBooked}</strong>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-top">
            <Grid.Column width={14} className="bold mobile-align" textAlign="right">
                Total attendees arrived
            </Grid.Column>
            <Grid.Column width={2} textAlign="right" className="purple-header">
                <strong>{registerStats.totalArrived}</strong>
            </Grid.Column>
        </Grid.Row>
        {showCompletion &&
            <Grid.Row className="no-padding-top">
                <Grid.Column width={14} className="bold mobile-align" textAlign="right">
                    Total completed
                </Grid.Column>
                <Grid.Column width={2} textAlign="right" className="purple-header">
                    <strong>{registerStats.totalCompleted}</strong>
                </Grid.Column>
            </Grid.Row>
        }
    </>
);
