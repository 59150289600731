import * as React from "react";
import { Button } from "semantic-ui-react";
import { EventInstance } from "../model";
import { useDispatch } from "react-redux";
import { addZoomAccount } from "../actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

interface Props {
    eventInstance: EventInstance;
}

export const AddZoomAccountButton: React.FC<Props> = ({ eventInstance }) => {
    const dispatch = useDispatch();

    const onAddZoomAccountClick = React.useCallback(async () => {
        dispatch(addZoomAccount(eventInstance.id));
    }, [dispatch, eventInstance]);

    if (eventInstance.cancelled || eventInstance.eventInstanceDeliveryType !== DeliveryTypeEnum.Digital || !!eventInstance.digitalCourseAccount
        || eventInstance.doesNotUseTtcZoomAccounts) {
        return null;
    }

    return (
        <Button className="irregular-positive" content="Add zoom account" onClick={onAddZoomAccountClick} />
    );
};
