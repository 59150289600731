/* eslint-disable @typescript-eslint/no-redeclare */
export const SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS = "SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS";
export type SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS = typeof SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS;

export const SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS = "SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS";
export type SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS = typeof SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS;

export const SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS = "SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS";
export type SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS = typeof SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS;

export const SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS = "SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS";
export type SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS = typeof SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS;
