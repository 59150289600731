import { MuiDateField } from "@common/components/MuiDateField";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { getCurrencyFormat } from "@common/formating/CurrencyFormatting";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { muiLessThanAYearAgoOrFutureValidator } from "@common/validation/lessThanAYearAgoOrFutureValidator";
import { DebouncedFunc } from "lodash";
import moment from "moment";
import * as React from "react";
import {  Button, Table } from "semantic-ui-react";
import { MonitorFee, MonitorFeeBase } from "../../model";

interface MonitorPayTableProps {
    trainerFees: MonitorFee[];
    newTrainerFee?: MonitorFee;
    setNewTrainerFee?: React.Dispatch<React.SetStateAction<MonitorFee>>;
    onAddRowClick?: () => void;
    editable?: boolean;
    editing?: boolean;
    push?: (path: string) => void;
    formValidationObject?: {
        [key: string]: boolean;
    };
    setFormValidationObject?: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>;
    displayEffectiveDateWarning?: DebouncedFunc<(date: string) => void>;
}

export const MonitorPayTable: React.FC<MonitorPayTableProps> =
({ trainerFees,  newTrainerFee,  setNewTrainerFee, editable,
    onAddRowClick, editing, formValidationObject, setFormValidationObject, displayEffectiveDateWarning }) => {

    function onEffectiveDateChange(value: moment.Moment, valid: boolean) {

        const firstOfMonth = value?.clone().utc().startOf("month");
        const dateIsAlreadyUsed = value?
            trainerFees.map(fee => fee.effectiveDate.format(DateFormat.DayMonthYear)).includes(value.format(DateFormat.DayMonthYear)):false;

        if (dateIsAlreadyUsed) {
            displayEffectiveDateWarning(value.format(DateFormat.DayMonthYear));
        }

        setFormValidationObject({ ...formValidationObject, effectiveDate: !dateIsAlreadyUsed && valid });
        setNewTrainerFee({ ...newTrainerFee, effectiveDate: firstOfMonth });
    }

    function onBaseFeeValueChange(baseFeePart: keyof MonitorFeeBase, value: number) {

        setNewTrainerFee({ ...newTrainerFee, [baseFeePart]: value });
    }

    const TrainerPayBaseRow =(trainerFee: MonitorFee, isEditable: boolean) => {

        const dateView = isEditable?
            (<MuiDateField
                value={trainerFee.effectiveDate}
                onChange={onEffectiveDateChange}
                validation={[muiLessThanAYearAgoOrFutureValidator]}
                required
            />):trainerFee.effectiveDate?.format(DateFormat.DayMonthYear);

        return (<React.Fragment key={isEditable? "edit": trainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            <Table.Cell>{dateView}</Table.Cell>
            <Table.Cell>{baseNumFieldViewType(trainerFee, "monitor", isEditable)}</Table.Cell>
        </React.Fragment>);
    };

    const TrainerPayFullRow = (trainerFee: MonitorFee, isEditable = false) => (

        (<Table.Row key={isEditable?"edit":trainerFee.effectiveDate.format(DateFormat.DayMonthYear)}>
            {TrainerPayBaseRow(trainerFee, isEditable)}
        </Table.Row>)
    );

    const TrainerPayHeader = () => {
        return (<>
            <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan='3'>Standard</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
                <Table.HeaderCell>Date Effective (First of Month)</Table.HeaderCell>
                <Table.HeaderCell>Monitor</Table.HeaderCell>
            </Table.Row>
        </>);};

    const baseNumFieldViewType =(trainerFee: MonitorFee, baseFeePart: keyof MonitorFeeBase, isEditable: boolean) => {

        function onNumberChange(value: number, valid: boolean) {
            setFormValidationObject({ ...formValidationObject, [baseFeePart]: valid });
            return onBaseFeeValueChange(baseFeePart, value);
        }

        const value = trainerFee[baseFeePart];

        return isEditable?
            <div className={"ui mini small-input"}><CurrencyInput
                value={value}
                onChange={onNumberChange}
                fitOnModal
            /></div> : getCurrencyFormat(value);};

    return (
        <>
            <Table celled structured>
                <Table.Header>
                    {TrainerPayHeader()}
                </Table.Header>
                <Table.Body>
                    {trainerFees && trainerFees.length > 0? trainerFees.map(fee => TrainerPayFullRow(fee)): !editing &&
                        <Table.Row>
                            <Table.Cell textAlign="center" colSpan={3}>No Fees Currently Saved</Table.Cell>
                        </Table.Row>}
                    {editing &&  TrainerPayFullRow(newTrainerFee, true)}

                </Table.Body>
            </Table>
            {editable && !editing &&
                 <>
                     <Button content='Add new row' icon='add circle' labelPosition='left' onClick={onAddRowClick} />
                     <br /><br />
                 </>}
        </>
    );};

