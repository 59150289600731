import * as React from "react";
import { Grid } from "semantic-ui-react";

interface AreaListProps{
    title: string;
    description: string;
    subtitle: string;
    list: any[];
    placeholder: string;
}

export const AreaList = ({ title,  description, subtitle, list, placeholder }: AreaListProps) => (
    <>
        <Grid.Row><h3>{title}</h3></Grid.Row>

        {list.length > 0?
            <>
                <Grid.Row><p>{description}</p></Grid.Row>
                <Grid.Row><h5>{subtitle}</h5></Grid.Row>
                <Grid.Row><p>{list.join(", ")}</p></Grid.Row>
            </>:
            <p>{placeholder}</p>}
    </>
);
