import * as React from "react";
import { Button, Modal, Form } from "semantic-ui-react";

export interface CreateFieldModalProps {
    onSave: (newField: string) => void;
}

export const CreateFieldModal: React.FC<CreateFieldModalProps> = ({ onSave }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [newField, setNewField] = React.useState<string>();

    const onChange = React.useCallback((_: any, { value }) => {
        setNewField(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newField]);

    const openCloseModal = React.useCallback(() => {
        setNewField("");
        setOpen(!open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, newField]);

    const onFieldSave = React.useCallback(() => {
        onSave(newField);
        setNewField("");
        setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newField]);

    return (
        <>
            <Button
                onClick={openCloseModal}
                floated="right"
            >
                Create AdHoc Template
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    Create AdHoc Template
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Form.Input
                                label="Enter new field name"
                                value={newField || ""}
                                onChange={onChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseModal}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        positive
                        onClick={onFieldSave}
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
