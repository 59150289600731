/* eslint-disable max-lines */
import * as React from "react";
import "moment-duration-format";
import { Grid, Segment, Message, Divider } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { AutoSpreadStatusEnum, BookingAvailabilityTypeEnum, Language } from "@common/crud/eventInstance/model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRolesAndTrainers, EventManagementAdminRoles, MonitorRole,
    EventManagementAdminRolesAndMonitors } from "@common/auth/model";
import { WelshLanguageIndicator } from "../WelshLanguageIndicator";
import { EventInstanceActions } from "./EventInstanceActions";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventInstance } from "../..";
import { VenueDetails } from "./VenueDetails";
import { AttendeeDetails } from "./AttendeeDetails";
import { EventInstanceAvailabilityMessage } from "./EventInstanceAvailabilityMessage";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { CopyButton } from "@common/components/CopyButton";
import { DetailWithCopy } from "@common/crud/eventInstance/components/details/DetailWithCopy";
import { connect, useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "@common/auth";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { VenueDetailModel } from "@common/crud/venue/model";
import { VenueApi } from "@common/crud/venue/venueApi";
import { BookingType, BookingTypeEnum, CertificateType, DurationType, DurationTypeEnum,
    ModuleType, ModuleTypeEnum, ProductCategory, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DateAndTimeDetails } from "./DateAndTimeDetails";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { baseEventManagementSelector } from "../../selectors";
import { FinanceDetails } from "./FinanceDetails";
import { isWorkflowCorporate, supportsOnRoadReport, workflowTypeSupportsCertificate, isEventInstanceWorkflowPoliceOrCourt,
    isEventInstanceWorkflowConstruction } from "@common/global/CommonHelpers";
import { CustomDetail } from "@common/components/CustomDetail";
import { loadEventTypeDetail } from "@common/crud/eventType/actions";
import { eventTypeSelector } from "@common/crud/eventType/selectors";
import { OnRoadReportNames, ProductCategoryToReportTypeMap } from "../onRoadReports/model";
import { ProductCategoryHasOnRoadReport } from "../onRoadReports/helpers";
import { State as RouterState } from "redux-little-router";
import { CourseCategories } from "@common/components/CourseCategories";
import { ApplicationState } from "src/Admin.App/src/applicationState";

interface InternalCoreDetailProps {
    eventInstance: EventInstance;
    group: EventInstanceGroupModel;
    onEditModal?: boolean;
}

interface CoreDetailProps extends InternalCoreDetailProps {
    basePath: string;
}

export const InternalCoreDetails: React.FC<CoreDetailProps> = (props) => {
    const { eventInstance, group, onEditModal, basePath } = props;
    const user = useSelector(currentUserSelector);
    const [venueDetail, setVenueDetail] = React.useState<VenueDetailModel>(null);
    const parentEventType = useSelector(eventTypeSelector(eventInstance.eventTypeId));
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function initVenue() {
            const api = new VenueApi();
            const venue = await api.detail(eventInstance.venueId);

            setVenueDetail(venue);
        }

        if (eventInstance.venueId) {
            initVenue();
        }

    }, [eventInstance.venueId]);

    React.useEffect(() => {
        const eventTypeId = eventInstance.eventTypeId;
        async function initParentEventType() {
            dispatch(loadEventTypeDetail({ eventTypeId }));
        }

        if (eventTypeId) {
            initParentEventType();
        }
    }, [dispatch, eventInstance.eventTypeId]);

    const showCourseLinkToMonitor = eventInstance?.monitorTrainers?.find(mt => mt.id === user.id) && user.roles.includes(MonitorRole);
    const showCourseLinkToObserver = eventInstance?.observerTrainers?.find(mt => mt.id === user.id);
    const showDigitalCoursePassword = EventManagementAdminRolesAndMonitors.some(r => user.roles.indexOf(r) > -1) ||
        showCourseLinkToMonitor || showCourseLinkToObserver;

    const availableOnBookingJourney = eventInstance.availableInBookingJourney ? eventInstance.availableInBookingJourney : BookingAvailabilityTypeEnum.Both;
    const hideEditableCloseCourseFields = onEditModal
        && isWorkflowCorporate(eventInstance.workflowType)
        && eventInstance.bookingType === BookingTypeEnum.Closed;
    const isAdminApp = useSelector(appSelector) === Apps.Admin;
    const isPoliceOrCourt = isEventInstanceWorkflowPoliceOrCourt(eventInstance);
    const isConstruction = isEventInstanceWorkflowConstruction(eventInstance);
    const yesNoFromBool = (value: boolean) => value ? "Yes" : "No";

    const constructionCategories = useSelector((state: ApplicationState) => state.appSettings.constructionCategories);

    const renderGrid = () => (<Grid className="event-instance-details">
        {eventInstance.autoSpreadStatus === AutoSpreadStatusEnum.InProgress && isAdminApp &&
            <Grid.Column width={16}>
                <Message info>
                    Auto Spreading is in progress for this course
                </Message>
            </Grid.Column>
        }
        {eventInstance.cancelled
            && <Grid.Column width={16}>
                <Message as={Segment} error>
                    This Course is cancelled.
                    {eventInstance.reasonForCancellation && <><br />Reason: {eventInstance.reasonForCancellation}.</>}
                </Message>
            </Grid.Column>}

        <h1>Course details</h1>
        <Grid.Row>
            <Grid.Column width={16}>
                <EventInstanceBanner eventInstance={eventInstance} />
            </Grid.Column>
        </Grid.Row>
        {!onEditModal &&
            <Grid.Row>
                <Grid.Column width={16}>
                    <EventInstanceActions
                        eventInstance={eventInstance}
                    />
                </Grid.Column>
            </Grid.Row>}
        {!isPoliceOrCourt && (
            <>
                <h3>Organisation details</h3>
                <Detail
                    label="Organisation"
                    value={isAdminApp
                        ? (<a
                            className="primary-link"
                            target="_blank"
                            rel="noreferrer"
                            href={`${basePath}/organisations/${eventInstance.corporateOrganisationId}`}
                        >
                            {eventInstance.corporateOrganisationName}
                        </a>)
                        : (eventInstance.corporateOrganisationName)
                    }
                    labelWidth={5}
                    valueWidth={11}
                />
                {isAdminApp && eventInstance?.corporateOrganisationContact?.contacts && eventInstance.corporateOrganisationContact.contacts.length > 0 && (
                    <>
                        <h3 className="event-instance-title">Primary Contact</h3>
                        {eventInstance.corporateOrganisationContact.contacts.map((contact, index) => (
                            <React.Fragment key={contact.name}>
                                <Detail
                                    label="Name"
                                    value={contact.name || ""}
                                    labelWidth={5}
                                    valueWidth={11}
                                />
                                <Detail
                                    label="Email"
                                    value={contact.email
                                        ? <a
                                            className="primary-link"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={"mailto:" + contact.email}
                                        >
                                            {contact.email}
                                        </a>
                                        : ""}
                                    labelWidth={5}
                                    valueWidth={11}
                                />
                                <Detail
                                    label="Telephone"
                                    value={contact?.telephone || ""}
                                    labelWidth={5}
                                    valueWidth={11}
                                />
                                {index !== eventInstance.corporateOrganisationContact.contacts.length - 1 && (
                                    <Divider hidden className="full-width" />
                                )}
                            </React.Fragment>
                        ))}
                    </>
                )}
            </>
        )}
        <h3 className="event-instance-title">Main details</h3>
        <Detail
            label={!isPoliceOrCourt ? "Product" : "Scheme"}
            value={eventInstance.eventTypeName}
            labelWidth={5}
            valueWidth={11}
        />
        {isConstruction && (
            <>
                {eventInstance.resellerCourse &&
                    <Detail label="Reseller Course" value={yesNoFromBool(eventInstance.resellerCourse)} labelWidth={5} valueWidth={11} />
                }
                <CourseCategories categories={constructionCategories} values={eventInstance.courseCategories} />
            </>
        )}
        {!isPoliceOrCourt && (
            <>
                <Detail
                    label="Product Category"
                    value={ProductCategory[eventInstance.productCategory]}
                    labelWidth={5}
                    valueWidth={11}
                />
                {eventInstance.workflowType === WorkflowTypeEnum.CPC && (
                    <>
                        <Detail
                            label="Flexible Certificates Required?"
                            value={!eventInstance.flexibleCertificatesRequired ? "No" : "Yes"}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        {eventInstance.flexibleCertificatesRequired && (
                            <Detail
                                label="Certificate Type"
                                value={CertificateType[eventInstance.certificateType]}
                                labelWidth={5}
                                valueWidth={11}
                            />
                        )}
                    </>
                )}
                <Detail
                    label="Booking Type"
                    value={BookingType[eventInstance.bookingType]}
                    labelWidth={5}
                    valueWidth={11}
                />
                <Detail
                    label="Delegates Required"
                    value={eventInstance.delegatesRequired === false ? "No" : "Yes"}
                    labelWidth={5}
                    valueWidth={11}
                />
                {(!onEditModal && workflowTypeSupportsCertificate(eventInstance.workflowType)) && (
                    <Detail
                        label="Certificate Required"
                        value={eventInstance.certificateRequired === false ? "No" : "Yes"}
                        labelWidth={5}
                        valueWidth={11}
                    />
                )}
                {supportsOnRoadReport(eventInstance.workflowType, eventInstance.productCategory) &&
                    <Detail
                        label="On Road Report Required"
                        value={`${yesNoFromBool(eventInstance.onRoadReportRequired)}${GetReportTypeName(eventInstance)}`}
                        labelWidth={5}
                        valueWidth={11} />
                }
            </>
        )}
        <Authorize authorize={EventManagementAdminRolesAndTrainers}>
            {eventInstance.reasonForHidingEvent
                && <Detail label="Reason for hiding" value={eventInstance.reasonForHidingEvent} labelWidth={5} valueWidth={11} />}
        </Authorize>
        <Detail
            label="Course Language"
            value={<span>{Language[eventInstance.language]} <WelshLanguageIndicator language={eventInstance.language} /></span>}
            labelWidth={5}
            valueWidth={11}
        />
        <Detail
            label="Delivery Type"
            value={DeliveryType[eventInstance.eventInstanceDeliveryType]}
            labelWidth={5}
            valueWidth={11}
        />
        {(isAdminApp && isPoliceOrCourt) &&
            <Detail
                label="Booking Journey Type"
                value={<span>{BookingAvailabilityTypeEnum[availableOnBookingJourney]}</span>}
                labelWidth={5}
                valueWidth={11}
            />
        }
        <Detail
            label="1:1"
            value={yesNoFromBool(eventInstance.oneToOne)}
            labelWidth={5}
            valueWidth={11}
        />
        <Authorize authorize={EventManagementAdminRoles}>
            <Detail
                label="Course Type"
                value={`${eventInstance.moduleType || ""}`}
                labelWidth={5}
                valueWidth={11}
            />
            {!isPoliceOrCourt && eventInstance.moduleType === ModuleType[ModuleTypeEnum.Both] && <Detail
                label="Duration Type"
                value={`${eventInstance.durationType ? DurationType[eventInstance.durationType] : ""}`}
                labelWidth={5}
                valueWidth={11}
            />}
        </Authorize>
        {eventInstance.competencyReportRequired
            && parentEventType?.competencyReportLink
            && <CustomDetail
                label="Competency Report Link"
                value={<a href={parentEventType?.competencyReportLink} target="_blank" rel="noreferrer">{parentEventType?.competencyReportLink}</a>}
                labelWidth={5}
                valueWidth={11}
                valueClassName="break-all"
            />}
        {eventInstance.workflowType === WorkflowTypeEnum.Dors && <Authorize authorize={EventManagementAdminRoles}>
            <Detail
                label="Price"
                value={eventInstance.priceInPence ? `${(eventInstance.priceInPence / 100).toFixed(2)}` : ""}
                labelWidth={5}
                valueWidth={11}
            />
        </Authorize>}
        {!isPoliceOrCourt && (
            <>
                {eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
                    <Detail
                        label="Internal Product ID (Classroom)"
                        value={parentEventType.internalId}
                        labelWidth={5}
                        valueWidth={11}
                    />
                }
                {eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital &&
                    <Detail
                        label="Internal Product ID (Digital)"
                        value={parentEventType.internalIdDigital}
                        labelWidth={5}
                        valueWidth={11}
                    />
                }
            </>
        )}
        {!hideEditableCloseCourseFields &&
        <DateAndTimeDetails
            groupDetail={group}
            workflowType={eventInstance.workflowType}
            productCategory={eventInstance.productCategory}
            startDate={eventInstance.startDate}
            eventDuration={eventInstance.eventDuration}
            educationDuration={eventInstance.educationDuration}
            startTime={eventInstance.startTime}
            registrationEndTime={eventInstance.registrationEndTime}
            theoryAndPractical={eventInstance.moduleType === ModuleType[ModuleTypeEnum.Both]}
            showCombined={eventInstance.durationType === DurationTypeEnum.Combined}
            theoryDuration={eventInstance.theoryDuration}
            theoryStartTime={eventInstance.theoryStartTime}
            practicalDuration={eventInstance.practicalDuration}
            practicalStartTime={eventInstance.practicalStartTime}
        />}
        {
            eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital &&
            <>
                <h3 className="event-instance-title">Digital details</h3>
                <Detail
                    label="Registration Required"
                    value={yesNoFromBool(!eventInstance.digitalCourseWithoutRegistration)}
                    labelWidth={5}
                    valueWidth={11}
                />
                <Authorize authorize={EventManagementAdminRoles}>
                    <Grid.Row>
                        <Grid.Column width={5} as="h4">
                            Digital Course Link
                        </Grid.Column>
                        <Grid.Column largeScreen={4} computer={6} tablet={8} mobile={11} className="truncate">
                            <a
                                className="primary-link"
                                href={eventInstance.digitalCourseLink || "#"}>
                                {eventInstance.digitalCourseLink || "Not set"}
                            </a>
                        </Grid.Column>
                        <Grid.Column largeScreen={5} computer={5} tablet={3} mobile={16}>
                            <CopyButton text={eventInstance.digitalCourseLink} />
                        </Grid.Column>
                    </Grid.Row>
                </Authorize>
                <DetailWithCopy label="Digital Course Account" value={eventInstance.digitalCourseAccount} />
                <DetailWithCopy label="Digital Course Password (Trainer)" value={eventInstance.digitalCoursePasswordTrainer} />
                {(showCourseLinkToMonitor || showCourseLinkToObserver) &&
                <Grid.Row>
                    <Grid.Column width={5} as="h4">
                        Digital Course Link
                    </Grid.Column>
                    <Grid.Column largeScreen={4} computer={6} tablet={8} mobile={11} className="truncate detail-with-copy">
                        <a
                            className="primary-link"
                            href={eventInstance.digitalCourseLink || "#"}>
                            {eventInstance.digitalCourseLink || "Not set"}
                        </a>
                    </Grid.Column>
                    <Grid.Column largeScreen={5} computer={5} tablet={3} mobile={16}>
                        <CopyButton text={eventInstance.digitalCourseLink} />
                    </Grid.Column>
                </Grid.Row>
                }
                {showDigitalCoursePassword &&
                    <Detail
                        label="Digital Course Password (Attendee)"
                        value={eventInstance.digitalCoursePasswordAttendee || "Not set"}
                        labelWidth={5}
                        valueWidth={11}
                    />
                }
                {
                    <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                        <Detail
                            label="Digital Course Meeting ID"
                            value={eventInstance.digitalCourseMeetingId || "Not set"}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </Authorize>
                }
            </>
        }

        <VenueDetails eventInstance={eventInstance} />
        {eventInstance.doesNotUseTtcZoomAccounts !== null && (
            <Detail
                label="Organisation specific digital details required"
                value={yesNoFromBool(eventInstance.doesNotUseTtcZoomAccounts)}
                labelWidth={5}
                valueWidth={11}
            />
        )}
        {(!isPoliceOrCourt && isAdminApp && !onEditModal) && <FinanceDetails eventInstance={eventInstance} />}
        <Authorize authorize={EventManagementAdminRolesAndTrainers}>
            <AttendeeDetails eventInstance={eventInstance} venueDetail={venueDetail} />
        </Authorize>
    </Grid>);

    return (
        <>
            <EventInstanceAvailabilityMessage eventInstance={eventInstance} />
            {renderGrid()}
        </>
    );
};

function GetReportTypeName(eventInstance: EventInstance) {
    if (!eventInstance.onRoadReportRequired || !ProductCategoryHasOnRoadReport(eventInstance.productCategory)) {
        return "";
    }

    return ` (${OnRoadReportNames[ProductCategoryToReportTypeMap[eventInstance.productCategory]]})`;
}

function mapStateToProps(state: RouterState, ownProps: InternalCoreDetailProps) {
    return {
        basePath: baseEventManagementSelector(state),
        ...ownProps
    };
}

export const CoreDetails = connect(mapStateToProps)(InternalCoreDetails);
