import * as React from "react";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { BookingTypeEnum, CourseFeesEventTypeDetails } from "../model";
import { CorporateFeeTable } from "./CorporateFeeTable";

export interface CorporateFeeTablesProps {
    bookingType: BookingTypeEnum;
    deliveryType: SchemeDeliveryTypeEnum;
    eventTypeName: string;
    showEditButtons?: boolean;
    courseFeesEventTypeDetails: CourseFeesEventTypeDetails;
    onChange?: (propName: string, termsAndConditionsProperty?: boolean) => (value: any, valid: boolean) => void;
}

export const CorporateFeeTables = ({ bookingType, deliveryType, eventTypeName, showEditButtons, courseFeesEventTypeDetails,
    onChange }: CorporateFeeTablesProps) => {
    const isDigital = deliveryType === SchemeDeliveryTypeEnum.Digital || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital;
    const isClassroom = deliveryType === SchemeDeliveryTypeEnum.Classroom || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital;

    const isOpen = bookingType === BookingTypeEnum.Open || bookingType === BookingTypeEnum.Both;
    const isClosed = bookingType === BookingTypeEnum.Closed || bookingType === BookingTypeEnum.Both;

    function updateCourseFees(feeField: keyof CourseFeesEventTypeDetails) {
        return onChange?.(`courseFeesEventTypeDetails.${feeField}`);
    }

    return (
        <>
            {isOpen && isDigital &&
                <CorporateFeeTable
                    headerPrefix={eventTypeName}
                    isOpen
                    isDigital
                    showEditButton={showEditButtons}
                    fees={courseFeesEventTypeDetails.openDigitalEIFeeWithEffectiveDate ?? []}
                    onChange={updateCourseFees("openDigitalEIFeeWithEffectiveDate")}
                />
            }
            {isOpen && isClassroom &&
                <CorporateFeeTable
                    headerPrefix={eventTypeName}
                    isOpen
                    isDigital={false}
                    showEditButton={showEditButtons}
                    fees={courseFeesEventTypeDetails.openClassroomEIFeeWithEffectiveDate ?? []}
                    onChange={updateCourseFees("openClassroomEIFeeWithEffectiveDate")}
                />
            }
            {isClosed && isDigital &&
                <CorporateFeeTable
                    headerPrefix={eventTypeName}
                    isOpen={false}
                    isDigital
                    showEditButton={showEditButtons}
                    fees={courseFeesEventTypeDetails.closedDigitalEIFeeWithEffectiveDate ?? []}
                    onChange={updateCourseFees("closedDigitalEIFeeWithEffectiveDate")}
                />
            }
            {isClosed && isClassroom &&
                <CorporateFeeTable
                    headerPrefix={eventTypeName}
                    isOpen={false}
                    isDigital={false}
                    showEditButton={showEditButtons}
                    fees={courseFeesEventTypeDetails.closedClassroomEIFeeWithEffectiveDate ?? []}
                    onChange={updateCourseFees("closedClassroomEIFeeWithEffectiveDate")}
                />
            }
        </>
    );
};
