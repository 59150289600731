/* eslint-disable eqeqeq */
import * as React from "react";
import "moment-duration-format";
import { Detail } from "@neworbit/simpleui-detail";
import { ConfirmationStatus, EnquiryType } from "@common/crud/eventInstance/model";
import { Authorize } from "reauthorize";
import { EventManagementAdminRoles } from "@common/auth/model";
import { Address } from "@common/crud/common";
import { EventInstance } from "../..";
import { appSelector } from "@common/crud/common/selectors";
import { useSelector } from "react-redux";
import { Apps } from "@common/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

interface VenueDetailsProps {
    eventInstance: EventInstance;
}

const VenueDetails: React.FC<VenueDetailsProps> = (props) => {
    const { eventInstance } = props;
    const app = useSelector(appSelector);
    const businessLineType = useSelector(businessLineTypeSelector);

    const venueName = React.useMemo(() => {
        if (app !== Apps.Admin) {
            return <>{eventInstance.venueName}</>;
        }

        switch (businessLineType) {
            case BusinessLineType.Corporate:
                return <a href={`/corporate-event-management/venues/${eventInstance?.venueId}`}>{eventInstance?.venueName}</a>;
            case BusinessLineType.Construction:
                return <a href={`/construction-event-management/venues/${eventInstance?.venueId}`}>{eventInstance?.venueName}</a>;
            case BusinessLineType.PoliceAndCourt:
            case BusinessLineType.PoliceAndCourt2:
                return <a href={`/police-and-court-event-management/venues/${eventInstance?.venueId}`}>{eventInstance?.venueName}</a>;
            default:
                return <>{eventInstance.venueName}</>;
        }
    }, [app, businessLineType, eventInstance?.venueId, eventInstance?.venueName]);

    const AddressDisplay = (address: Address) => address
        &&
        <div>
            {address.addressLine1}<br />{address.addressLine2}<br />{address.addressLine3}<br />{address.city}<br />{address.postalCode}
        </div>;

    const showAddress = eventInstance.eventInstanceDeliveryType !== DeliveryTypeEnum.Digital && eventInstance.enquiryType != EnquiryType.Enquiry;
    const showVenueConfirmation =
        !(eventInstance.workflowType === WorkflowTypeEnum.DDRS && eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital)
        && eventInstance.workflowType !== WorkflowTypeEnum.BusinessDriver && !eventInstance.delegateAddress;
    const showPoliceOrgName =
        eventInstance.workflowType === WorkflowTypeEnum.Dors &&
        eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite;

    return (
        <>
            <h3 className="event-instance-title">Venue details</h3>
            <Detail
                label="Venue"
                value={venueName}
                labelWidth={5}
                valueWidth={11}
            />

            {eventInstance.enquiryType === EnquiryType.Enquiry && eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
                <Detail
                    label="Location description"
                    value={eventInstance.locationDescription}
                    labelWidth={5}
                    valueWidth={11}
                />
            }

            {showAddress &&
                <Detail
                    label={eventInstance.delegateAddress ? "Delegate Address" : "Venue address"}
                    value={AddressDisplay(eventInstance.venueAddress)} labelWidth={5} valueWidth={11}
                />
            }
            <Authorize authorize={EventManagementAdminRoles}>
                {eventInstance.confirmationStatus != null && showVenueConfirmation
                    && eventInstance.eventInstanceDeliveryType !== DeliveryTypeEnum.Digital &&
                    <Detail
                        label="Venue confirmation status"
                        value={ConfirmationStatus[eventInstance.confirmationStatus]}
                        labelWidth={5}
                        valueWidth={11}
                    />}
            </Authorize>
            {showPoliceOrgName &&
                <Detail
                    label="Police Organisation"
                    value={eventInstance.venueOrganisationName}
                    labelWidth={5}
                    valueWidth={11}
                />
            }
        </>
    );
};

export { VenueDetails };
