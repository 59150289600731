import { Checkbox, Grid } from "semantic-ui-react";
import * as React from "react";

export interface SubSectionHeaderProps {
    id: string;
    headerText: string;
    unsatisfactory?: boolean;
    satisfactionChanged: (id: string) => void;
    lockedForChange: boolean;
}

export const SubSectionHeader: React.FC<SubSectionHeaderProps> = ((props) => {
    const { lockedForChange, satisfactionChanged, id } = props;

    function onChangeSatisfaction() {
        satisfactionChanged(id);
    };

    const toggleText = props.unsatisfactory ? "Unsatisfactory" : "Satisfactory";

    return (
        <Grid.Row>
            <Grid.Column width={12}><h4>{props.headerText}</h4></Grid.Column>
            <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right" >
                <Checkbox toggle label={toggleText} checked={props.unsatisfactory} onChange={onChangeSatisfaction} disabled={lockedForChange} />
            </Grid.Column>
        </Grid.Row>
    );
});
