import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { CaAssistanceRequiredComponent } from "@common/crud/dorsBooking/components/CaAssistanceRequired";
import { SupportRequestsDisplay } from "@common/crud/dorsBooking/components/SupportRequestsDisplay";
import { AssistanceRequired, DorsBooking, DorsBookingDetailModel } from "@common/crud/dorsBooking/model";
import { dorsBookingSelectorFromAttendee } from "@common/crud/dorsBooking/selectors";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

interface SupportRequestsLabelWithEditProps {
    correlationId: string;
    updateSupportRequests: (supportRequests: AssistanceRequired) => void;
    taskRelation: TaskRelationEnum;
    booking: DorsBooking;
}

export const SupportRequestsLabelWithEdit: React.FC<SupportRequestsLabelWithEditProps> = (props) => {
    const { correlationId, taskRelation, booking } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState(false);
    const errorMessage = "You must enter comments for all required support request. Comments must be less than 1000 characters in length";
    const [assistanceRequired, setAssistanceRequired] = React.useState<AssistanceRequired>();
    const dorsBookingFromCurrentState = useSelector(dorsBookingSelectorFromAttendee(correlationId)) as DorsBookingDetailModel;
    const dorsBooking = dorsBookingFromCurrentState?.id === undefined ? booking : dorsBookingFromCurrentState;

    React.useEffect(() => {
        if (dorsBooking) {
            setAssistanceRequired({
                isTechnicalAssistanceRequired: dorsBooking.isTechnicalAssistanceRequired,
                requiresSupervisorAssistance: dorsBooking.requiresSupervisorAssistance,
                shortNoticeCompletionRequiresSpace: dorsBooking.shortNoticeCompletionRequiresSpace,
                technicalAssistanceComment: dorsBooking.technicalAssistanceComment,
                supervisorAssistanceComment: dorsBooking.supervisorAssistanceComment,
                shortNoticeRequiresSpaceComment: dorsBooking.shortNoticeRequiresSpaceComment,
                taskRelation
            });
        }
    }, [dorsBooking, taskRelation]);

    const cancel = React.useCallback(() => setOpen(false), []);

    const openModal = React.useCallback(() => setOpen(true), []);

    const onRaiseSupportRequests = React.useCallback((supportRequests: AssistanceRequired) => {
        supportRequests.taskRelation = taskRelation;
        setAssistanceRequired(supportRequests);
    }, [taskRelation]);

    const validateComment = (value: string): boolean => {
        if (!value || value.length < 1) {
            return false;
        }
        return value.length <= 999;
    };

    const valid = (supportRequests: AssistanceRequired): boolean => {
        if (supportRequests.isTechnicalAssistanceRequired && !validateComment(supportRequests.technicalAssistanceComment)) {
            return false;
        }

        if (supportRequests.requiresSupervisorAssistance && !validateComment(supportRequests.supervisorAssistanceComment)) {
            return false;
        }

        return !(supportRequests.shortNoticeCompletionRequiresSpace && !validateComment(supportRequests.shortNoticeRequiresSpaceComment));
    };

    const onRaiseSupportRequestsClicked = React.useCallback(() => {
        if (valid(assistanceRequired)) {
            setIsError(false);
            props.updateSupportRequests(assistanceRequired);
            setOpen(false);
        } else {
            setIsError(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.updateSupportRequests, assistanceRequired]);

    return (
        <div className="label-and-value">
            <span className="label fixed-width">
                Support Requests
            </span>

            <a
                className="show-button"
                onClick={openModal}
            >Raise support requests
            </a>

            <div className="value">
                <SupportRequestsDisplay dorsBooking={dorsBooking} showMessageIfNoSupportRequests={true} />
            </div>

            <Modal open={open}>
                <Modal.Header>
                    Raise support requests
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <p>What support request are you raising today?</p>
                        <CaAssistanceRequiredComponent
                            isOfferBooking
                            dorsBooking={dorsBooking}
                            onUpdate={onRaiseSupportRequests} />
                    </div>
                    {isError && <div className="error" ><p>{errorMessage}</p></div>}
                </Modal.Content>

                <Modal.Actions>
                    <Button
                        onClick={cancel}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onRaiseSupportRequestsClicked}
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
