import axios from "axios";
import moment from "moment";
import "moment-duration-format";
import * as QueryString from "query-string";
import { EventInstanceDetailModel } from "@common/crud/eventInstance/model";
import { FilterOptions } from "./model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export class BookingEventInstanceApi {
    private apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/booking/${bookingId}/eventinstance` : "/api/eventinstance";
    }

    public async getVenueEventInstances(query: FilterOptions): Promise<EventInstanceDetailModel[]> {
        const parsedQuery = {
            ...query,
            toDate: query.toDate ? query.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: query.fromDate ? query.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };

        const response = await axios.get(`${this.apiUrl}?${QueryString.stringify(parsedQuery)}`);
        const data = response.data as EventInstanceDetailModel[];
        return data.map(this.parseModel);
    }

    public async getEventInstanceDetailsForBooking({ id }: { id: string }): Promise<EventInstanceDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as EventInstanceDetailModel;
        return this.parseModel(model);
    }

    public parseModel(model: EventInstanceDetailModel): EventInstanceDetailModel {
        return {
            ...model,
            startDate: model.startDate && moment(model.startDate).utc().startOf("day"),
            eventDuration: model.eventDuration && moment.duration(model.eventDuration),
            startTime: model.startTime && moment.duration(model.startTime),
            publishDate: model.publishDate && moment(model.publishDate),
            priceInPence: model.priceInPence < 0 ? 0 : model.priceInPence,
            deliveryDateTime: model.deliveryDateTime && moment(model.deliveryDateTime).utc(),
            deliveryDateTimeEnd: model.deliveryDateTimeEnd && moment(model.deliveryDateTimeEnd).utc(),
        };
    }
}
