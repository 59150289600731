import * as React from "react";
import { Grid, Message } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { ConfirmButton } from "@common/components";
import { ConditionalConfirmButton } from "@common/components/ConditionalConfirmButton";
import { InvoiceFormat, InvoicePreviewModel } from "../model";
import { TrainerInvoiceApi } from "../trainerInvoiceApi";
import { InvoiceLineItemsTable } from "./InvoiceLineItemsTable";
import { InvoiceTotalsFooter } from "./invoiceTotalsFooter";
import { useSelector } from "react-redux";
import { trainerSelector } from "@common/crud/trainer/selectors";
import { Trainer } from "@common/crud/trainer";
import { Spinner } from "@common/global";
import { InvoiceBonusLineItemsTable } from "./InvoiceBonusLineItemsTable";
import moment from "moment";
import { appSettingsSelector } from "@common/appSettings/selectors";

interface InvoicePreviewComponentProps {
    submitInvoice: () => void;
    trainerId?: string;
    modalContent?: React.ReactNode;
    submitLoading: boolean;
    myAvailability?: boolean;
    myChosenVenues?: boolean;
    myPreferredSchemes?: boolean;
}

export const InvoicePreviewComponent: React.FC<InvoicePreviewComponentProps> = ({
    submitInvoice, trainerId, modalContent, submitLoading, myAvailability, myChosenVenues, myPreferredSchemes
}) => {
    const [invoicePreview, setInvoicePreview] = React.useState<InvoicePreviewModel>(null);
    const [canSubmitInvoice, setCanSubmitInvoice] = React.useState<boolean>(null);

    const trainer = useSelector(trainerSelector) as Trainer;

    const config = useSelector(appSettingsSelector);
    const trainerInvoiceOldFormatDate = moment(config.trainerInvoicesSettings.earliestInvoiceDateVersionTwo);

    const invoiceFormat = (trainerInvoiceOldFormatDate && invoicePreview?.invoiceDate < trainerInvoiceOldFormatDate)?
        InvoiceFormat.OldUpliftFees: InvoiceFormat.NewCombinedBonuses;

    React.useEffect(() => {
        async function getTrainerInvoiceData() {
            const api = new TrainerInvoiceApi(trainerId);
            setCanSubmitInvoice(await api.checkCanSubmitInvoice());
            setInvoicePreview(await api.getInvoicePreview());
        };

        getTrainerInvoiceData();
    }, [trainerId]);

    if (canSubmitInvoice === false) {
        return <h3>Preview unavailable. Invoice has already been submitted</h3>;
    }

    const hasBankAccountDetails = !!(trainer.bankAccount?.accountNumber && trainer.bankAccount?.sortCode) || trainer.bankAccountDetailsAreHidden;

    return (
        <Spinner active={invoicePreview === null || submitLoading}>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h1>Preview for {invoicePreview && invoicePreview.invoiceDate.clone().add(-1, "M").format("MMMM")}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={"right"}>
                        <h5>Invoice Date: {invoicePreview && invoicePreview.invoiceDate.format(DateFormat.Short)}</h5>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {!hasBankAccountDetails &&
                            <Message className="centered-text" warning>
                                Invoices cannot be submitted because the trainer is missing bank account information.
                            </Message>
                        }
                        { config.featureSettings.trainerPromiseInvoiceReminder &&
                            canSubmitInvoice && invoicePreview && invoicePreview.invoiceTotal > 0 && modalContent &&
                                <ConditionalConfirmButton
                                    buttonClassName="full-width "
                                    content={modalContent}
                                    header={"Submit for processing?"}
                                    confirmButtonDisabled={!myAvailability || !myChosenVenues || !myPreferredSchemes}
                                    onConfirm={submitInvoice}
                                    disabled={!hasBankAccountDetails}
                                >
                                    Submit For Processing
                                </ConditionalConfirmButton>
                        }
                        {config.featureSettings.trainerPromiseInvoiceReminder &&
                            canSubmitInvoice && invoicePreview && invoicePreview.invoiceTotal > 0 && !modalContent &&
                                <ConfirmButton
                                    className="full-width "
                                    content="Once the invoice is submitted for processing it cannot be amended."
                                    header="Submit for processing?"
                                    onConfirm={submitInvoice}
                                    disabled={!hasBankAccountDetails}
                                >
                                    Submit For Processing
                                </ConfirmButton>
                        }
                        { !config.featureSettings.trainerPromiseInvoiceReminder &&
                            canSubmitInvoice && invoicePreview && invoicePreview.invoiceTotal > 0 &&
                                <ConfirmButton
                                    className="full-width "
                                    content={modalContent ?? "Once the invoice is submitted for processing it cannot be amended."}
                                    header="Submit for processing?"
                                    onConfirm={submitInvoice}
                                    disabled={!hasBankAccountDetails}
                                >
                                    Submit For Processing
                                </ConfirmButton>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {invoicePreview &&
                <>
                    <InvoiceLineItemsTable
                        lineItems={invoicePreview.invoiceLineItems}
                        vatPercentage={invoicePreview.vatPercentage}
                        invoiceFormat={invoiceFormat}
                        showPrintableFooter={false} />
                    {invoiceFormat ===InvoiceFormat.NewCombinedBonuses &&
                    <>
                        <InvoiceLineItemsTable
                            lineItems={invoicePreview.invoiceSubcontractedToLineItems}
                            vatPercentage={invoicePreview.vatPercentage}
                            subcontractedType="to"
                            invoiceFormat={invoiceFormat}
                            showPrintableFooter={false} />
                        <InvoiceBonusLineItemsTable
                            bonusLineItems={invoicePreview.invoiceBonusLineItems} showPrintableFooter={false} />
                    </>}

                    <InvoiceTotalsFooter
                        invoiceTotal={invoicePreview.invoiceTotal}
                        vatPercentage={invoicePreview.vatPercentage} />
                    {invoiceFormat === InvoiceFormat.NewCombinedBonuses &&
                    <>
                        <InvoiceLineItemsTable
                            lineItems={invoicePreview.invoiceSubcontractedFromLineItems}
                            subcontractedType="from"
                            invoiceFormat={invoiceFormat} showPrintableFooter={false} />
                    </>}
                </>
            }
        </Spinner>
    );
};
