import * as React from "react";
import { Container } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Area } from "../model";
import { useSelector } from "react-redux";
import { baseEventManagementSelector, trainerScheduleSelector } from "../selectors";
import { ConstructionHomeRole, CorporateHomeRole, DdrsAdmin } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { BusinessLineType } from "@common/crud/organisation/model";
import { trainerSelector } from "@common/crud/trainer/selectors";

export interface LineOfBusinessMenuItemProps {
    children: string;
    href: string;
}

export interface LineOfBusinessMenuProps {
    area: Area;
}

function LineOfBusinessMenuItem({ href, children }: LineOfBusinessMenuItemProps) {
    return <Link href={href} className="button-search-menu-item" activeProps={{ className: "button-search-menu-item current" }}>{children}</Link>;
}

export const EventInstanceLineOfBusinessMenu: React.FC<LineOfBusinessMenuProps> = ({ area }) => {
    const trainer = useSelector(trainerSelector);
    const basePath = useSelector(area === Area.AdminEventManagementTrainerEventInstance ? trainerScheduleSelector : baseEventManagementSelector);

    const dorsPath = area === Area.AdminEventManagementTrainerEventInstance ?
        `${basePath}/schedule` : `${basePath}/eventInstances`;

    const ddrsPath = area === Area.AdminEventManagementTrainerEventInstance ?
        `${basePath}/schedule-drink-drive` : `${basePath}/drink-drive`;

    const corpPath = `${basePath}/schedule-corp`;
    const constructionPath = `${basePath}/schedule-construction`;

    function ShowLineOfBusinessMenuItem(lob: BusinessLineType) {
        return !(area === Area.AdminEventManagementTrainerEventInstance) || trainer?.businessLineTypes?.includes(lob);
    }

    return (<Container>
        <div className="button-search-menu">
            {ShowLineOfBusinessMenuItem(BusinessLineType.Police) && <>
                <LineOfBusinessMenuItem href={dorsPath}>NDORS</LineOfBusinessMenuItem>
                {/* DDRS comes under police here as Ddrs and police are linked when it comes to trainer line of business */}
                <Authorize authorize={DdrsAdmin}>
                    <LineOfBusinessMenuItem href={ddrsPath}>DDRS</LineOfBusinessMenuItem>
                </Authorize></>}
            {area === Area.AdminEventManagementTrainerEventInstance && (
                <>
                    {ShowLineOfBusinessMenuItem(BusinessLineType.Corporate) && <Authorize authorize={CorporateHomeRole}>
                        <LineOfBusinessMenuItem href={corpPath}>COMMERCIAL</LineOfBusinessMenuItem>
                    </Authorize>}
                    {ShowLineOfBusinessMenuItem(BusinessLineType.Construction) && <Authorize authorize={ConstructionHomeRole}>
                        <LineOfBusinessMenuItem href={constructionPath}>CONSTRUCTION</LineOfBusinessMenuItem>
                    </Authorize>}
                </>
            )}
        </div>
    </Container>);

};

