import * as React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from "@common/components";

import { NonPoliceFeesEditForm, DispatchProps, EditProps } from "./NonPoliceFeesEdit";

export class NonPoliceFeesEditModal extends React.Component<EditProps & DispatchProps, {}> {

    private editForm: NonPoliceFeesEditForm;

    public render() {
        const hasCorporateOrganisationSpecificFeesData = this.props.model?.corporateOrganisationData?.hasCorporateOrganisationSpecificFeesData;

        if (!hasCorporateOrganisationSpecificFeesData) {
            return <div />;
        }

        return (
            <Modal size="small" open={this.props.open} onClose={this.props.close}>
                <Modal.Header>Edit {this.props.model?.name}'s Course Fees</Modal.Header>
                <Modal.Content>
                    <NonPoliceFeesEditForm
                        open={this.props.open}
                        save={this.props.save}
                        model={this.props.model}
                        businessLineType={this.props.businessLineType}
                        ref={instance => this.editForm = instance}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close}>Cancel</Button>
                    <Button onClick={this.submit}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private submit = () => this.editForm.submit();
}
