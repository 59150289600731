import * as React from "react";
import { Link } from "redux-little-router";
import { Table } from "semantic-ui-react";

import { AllProps } from "./All";
import { TrainerAttributeDefinitionListModel } from "../model";
import { TrainerAttributeType } from "@common/crud/trainerAttributeDefinition";
import { nonExpiredEventTypes } from "@common/helpers/expiredEventTypes";

class AllItems extends React.Component<AllProps> {
    public render() {
        const getSchemesNames = (trainerAttribute: TrainerAttributeDefinitionListModel) => {
            const eventTypes = this.props.eventTypes?.filter(e => nonExpiredEventTypes(e.expiryDate)).map(et => ({ name: et.name, id: et.id }));
            const schemeNames = trainerAttribute.schemes?.filter(schemeId => eventTypes.some(e => e.id === schemeId))
                .map(schemeId => eventTypes.find(et => et.id === schemeId)?.name);
            return schemeNames?.join(", ");
        };

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Attribute type</Table.HeaderCell>
                        <Table.HeaderCell>Schemes</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.trainerAttributes.map(trainerAttribute => (
                        <Table.Row key={trainerAttribute.id}>
                            <Table.Cell>
                                <Link href={`${this.props.basePath}/${trainerAttribute.id}`}>
                                    {trainerAttribute.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>
                                {TrainerAttributeType[trainerAttribute.attributeType]}
                            </Table.Cell>
                            <Table.Cell>
                                {getSchemesNames(trainerAttribute)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}

export { AllItems };
