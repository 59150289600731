import * as React from "react";
import { useSelector } from "react-redux";
import { Area } from "@common/crud/eventInstance/model";
import { Link } from "redux-little-router";
import { BusinessLineType, businessLineTypeSelector, universalEventManagementPathSelector } from "@common/redux-helpers";

export interface AdminMultiDayPartMenuItemProps {
    area: Area;
    eventInstanceId: string;
    children: string;
}

export const AdminMultiDayPartMenuItem: React.FC<AdminMultiDayPartMenuItemProps> = ({ area, eventInstanceId, children }) => {
    const businessLineType = useSelector(businessLineTypeSelector);
    const basePath = useSelector(universalEventManagementPathSelector);
    const href = businessLineType === BusinessLineType.PoliceAndCourt
        ? area === Area.AdminEventManagementTrainerEventInstance
            ? `${basePath}/schedule-drink-drive/${eventInstanceId}/attendees`
            : `${basePath}/drink-drive/${eventInstanceId}/attendees`
        : area === Area.AdminEventManagementTrainerEventInstance
            ? `${basePath}/schedule/${eventInstanceId}/delegates`
            : `${basePath}/eventInstances/${eventInstanceId}/delegates`;

    return <Link href={href} className="button-search-menu-item" activeProps={{ className: "button-search-menu-item current" }}>Day {children}</Link>;

};
