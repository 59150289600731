import * as React from "react";
import { CpcBookingAppSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";
import { validateCpcBundleDiscount } from "../validation";

export const CpcBookingApp: ConfigurationAreaComponent<CpcBookingAppSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="CpcBookingApp"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "CpcBundleEnabled", label: "CPC bundle enabled?", inputType: InputType.Toggle },
                { name: "CpcBundleDiscountPercentage", label: "CPC bundle discount (%)", inputType: InputType.Number, validate: validateCpcBundleDiscount },
                { name: "CpcBundleAmount", label: "CPC bundle amount", inputType: InputType.Number },
                { name: "CpcBundleBanner", label: "CPC bundle banner", inputType: InputType.TextArea },
            ]}
        />
    );
};
