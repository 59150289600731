import * as React from "react";
import { EventTypePart } from "@common/crud/eventType/model";
import { EventTypePartEdit } from "@common/crud/eventType/components/EventTypePartEdit";
import moment from "moment";

export interface EventTypePartPickerProps {
    eventTypeParts: Dictionary<EventTypePart>;
    updateEventTypeParts: (value: Dictionary<EventTypePart>) => void;
    defaultDays: number;
}

export const EventTypePartPicker: React.FC<EventTypePartPickerProps> = ({ eventTypeParts, updateEventTypeParts, defaultDays }) => {
    const templatePart =  React.useMemo(() => ({
        suggestedStartTime: moment.duration("08:00:00"),
        eventDuration: moment.duration("08:00:00"),
        registrationDuration: moment.duration("00:15:00")
    }), []);

    React.useEffect(() => {
        let modifiedEventTypeParts = Object.assign({}, eventTypeParts);

        if (defaultDays) {
            if (!eventTypeParts) {
                let counter = 1;
                while (counter <= defaultDays) {
                    modifiedEventTypeParts = addEventTypePart(counter, modifiedEventTypeParts);
                    counter++;
                }
            }
            modifiedEventTypeParts = modifiedEventTypeParts || { 1: templatePart };

            const keys = getKeys(modifiedEventTypeParts);
            let biggestKey = keys?.length > 0 ? (Math.max(...keys)) : 1;
            if (!isNaN(biggestKey) && defaultDays !== biggestKey) {
                while (biggestKey !== defaultDays && biggestKey !== 0) {
                    if (biggestKey > defaultDays) {
                        modifiedEventTypeParts = removeEventTypePart(biggestKey, modifiedEventTypeParts);
                        biggestKey--;
                    } else if (biggestKey <= defaultDays) {
                        modifiedEventTypeParts = addEventTypePart(biggestKey + 1, modifiedEventTypeParts);
                        biggestKey++;
                    }
                }
            }

            updateEventTypeParts(modifiedEventTypeParts);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDays]);

    const getKeys = React.useCallback((dict: Dictionary<EventTypePart>) => dict && Object.entries(dict)?.map(
        ([key]) => {
            return Number.parseInt(key, 10);
        }), []);

    const addEventTypePart = React.useCallback((biggestKey: number, modifiedEventTypeParts: Dictionary<EventTypePart>): Dictionary<EventTypePart> => {
        const newEventTypeParts = modifiedEventTypeParts || { 1: templatePart };
        newEventTypeParts[biggestKey ?? 1] = templatePart;
        return newEventTypeParts;
    }, [templatePart]);

    const removeEventTypePart = React.useCallback((biggestKey: number, modifiedEventTypeParts: Dictionary<EventTypePart>): Dictionary<EventTypePart> => {
        const newEventTypeParts = modifiedEventTypeParts;
        delete newEventTypeParts[biggestKey];
        return newEventTypeParts;
    }, []);

    const updateEventTypePart = React.useCallback((key: string, value: EventTypePart)  => {
        if (key) {
            eventTypeParts[key] = value;
            updateEventTypeParts(eventTypeParts);
        }
    }, [eventTypeParts, updateEventTypeParts]);

    const updateSuggestedTime = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, suggestedStartTime: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    const updateSuggestedDuration = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, eventDuration: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    const updateSuggestedRegistrationDuration = React.useCallback((key: string, value: string) => {
        const oldData = eventTypeParts[key];
        const newPart = { ...oldData, registrationDuration: moment.duration(value) };
        updateEventTypePart(key, newPart);
    }, [eventTypeParts, updateEventTypePart]);

    return (
        <>
            {
                eventTypeParts && Object.entries(eventTypeParts).map(
                    ([key, value]) => {
                        const label = `Start time for Day ${key}`;

                        return (<EventTypePartEdit key={key} itemKey={key} label={label} eventTypePart={value}
                            updateSuggestedDuration={updateSuggestedDuration}
                            updateSuggestedRegistrationDuration={updateSuggestedRegistrationDuration}
                            updateSuggestedTime={updateSuggestedTime} />);
                    })}
        </>
    );
};
