import { loadTrainerDetail } from "@common/crud/trainer/actions";
import { loadAppSettings } from "@common/appSettings";

export const TrainerInvoiceRoutes = {
    Details: "/:id",
    Preview: "/preview",
};

export const routes = {
    [TrainerInvoiceRoutes.Details]: {
        title: "Invoice Detail",
        resolve: [loadAppSettings]
    },
    [TrainerInvoiceRoutes.Preview]: {
        title: "Preview Invoice",
        resolve: [loadTrainerDetail, loadAppSettings]
    }
};
