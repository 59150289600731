import * as React from "react";
import { Tab } from "semantic-ui-react";
import { AvailabilityTrainerComponent } from "@common/availabilityTrainer/components/AvailabilityTrainer";
import { Area } from "@common/crud/eventInstance/model";

export const AvailabilityTab = () => (
    <Tab.Pane>
        <AvailabilityTrainerComponent area={Area.AdminEventManagementTrainerCalendar} />
    </Tab.Pane>
);
