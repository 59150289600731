import * as React from "react";
import { Label, Button } from "semantic-ui-react";

import classes from "./EmailAttachments.module.scss";

interface AttachmentItemProps {
    file: File;
    onRemove: (file: File) => void;
}

export const AttachmentItem: React.FC<AttachmentItemProps> = ({ file, onRemove }) => {
    const onRemoveClick = React.useCallback(() => {
        onRemove(file);
    }, [file, onRemove]);

    return (
        <div className={classes.attachmentItem}>
            <Label>
                <i aria-hidden="true" className="attach icon"></i>
                {file.name}
            </Label>
            <Button
                className={classes.removeAttachmentButton}
                title={`Remove ${file.name}`}
                onClick={onRemoveClick}
                color="red"
                size="mini"
                icon="trash alternate outline"
            />
        </div>
    );
};
