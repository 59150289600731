import * as React from "react";
import { Icon } from "semantic-ui-react";
import { HistoryRecord } from "../model";

export interface PendingNoteDetailsProps {
    entry: HistoryRecord;
    onEditPendingNote: (entry: HistoryRecord) => void;
}

function formatDate(entry: HistoryRecord) {
    if (entry.isPendingNoteCompleted) {
        return "Completed " + entry.completionDate.format("DD/MM/YYYY");
    }
    else {
        return entry.pendingDate.format("DD/MM/YYYY");
    }
}

const PendingNoteDetails: React.SFC<PendingNoteDetailsProps> = ({ entry, onEditPendingNote }) => {
    const buttonClass = "pending-note-button " + (entry.isPendingNoteCompleted ? "completed" : "pending");
    const onEdit = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onEditPendingNote(entry);
    }, [entry, onEditPendingNote]);

    if (entry.isPendingNote) {
        return (
            <>
                <button className={buttonClass} onClick={onEdit} disabled={entry.isPendingNoteCompleted}>
                    <Icon className="flag alternate vertical icon" color={entry.isPendingNoteCompleted ? "green" : "red"} />
                </button>
                <span className="pending-date">{formatDate(entry)}</span>
            </>
        );
    }

    return null;
};

export { PendingNoteDetails };
