import { SchemeAbbreviation, SchemeEnum } from "@common/crud/eventType/model";
import { PreCourseSection } from "@common/crud/eventInstance/components/monitoring/questions/PreCourseSection";
import { TrainerAttributesSection } from "@common/crud/eventInstance/components/monitoring/questions/TrainerAttributesSection";
import { IntroductionSection } from "@common/crud/eventInstance/components/monitoring/questions/IntroductionSection";
import { OverallSummarySection } from "@common/crud/eventInstance/components/monitoring/questions/OverallSummarySection";
import { CriteriaEnum, Questions } from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const NSACQuestions: Questions = {

    schemeId: SchemeAbbreviation[SchemeEnum.NSAC],
    sections: [
        PreCourseSection,
        TrainerAttributesSection,
        IntroductionSection,
        {
            id: "4",
            title: "Module 1",
            seqNum: 3,
            subSections: [
                {
                    id: "4.1",
                    text: "Knowing the limit 30 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "Guess the limit, write down and hold up to screen." },
                        { id: "2", text: "Provides clients with information on speed limits." },
                        { id: "3", text: "Gives them skills in identifying speed limits." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "4", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "5",
            title: "Module 2",
            seqNum: 4,
            subSections: [
                {
                    id: "5.1",
                    text: "Knowing why it matters 25 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "To increase clients’ understanding of the negative consequences of " +
                                "speeding and the risks of speeding even slightly over the limit." },
                        { id: "2", text: "Ensure film can be seen and heard by clients. Thumbs up/down." },
                        { id: "3", text: "To increase their awareness of the benefits of complying with speed limits." },
                        { id: "4", text: "Key message write down and hold up to screen." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "5", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "6",
            title: "Module 3",
            seqNum: 5,
            subSections: [
                {
                    id: "6.1",
                    text: "Staying in Control 20 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "To increase clients’ skills in controlling their vehicle’s speed and in" +
                                " recognising and managing stress and pressure to speed." },
                        { id: "2", text: "Speed creep, discussed, solutions. " },
                        { id: "3", text: "When do you feel stressed, write down and hold up to screen." },
                        { id: "4", text: "Managing stress and pressure." },
                        { id: "5", text: "Key messages discussed." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "6", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "7",
            title: "Module 4",
            seqNum: 6,
            subSections: [
                {
                    id: "7.1",
                    text: "Selecting a safe speed 15 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "Increases clients’ awareness of the importance of concentration, " +
                                "observation, anticipation, and allowing adequate space and time." },
                        { id: "2", text: "What speed is it safe to do?" },
                        { id: "3", text: "Rule 126, drive at a speed etc." },
                        { id: "4", text: "C,O & A detailed." },
                        { id: "5", text: "Safety bubble and 2 sec rule detailed." },
                        { id: "6", text: "Key messages discussed." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "7", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "8",
            title: "Module 5",
            seqNum: 7,
            subSections: [
                {
                    id: "8.1",
                    text: "Action Planning 20 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "Increases clients’ intentions and reduces their barriers to drive or ride at a safe speed." },
                        { id: "2", text: "The most important part of the course." },
                        { id: "3", text: "Your choice, consequences, good intentions." },
                        { id: "4", text: "Non-driving example given of an action plan." },
                        { id: "5", text: "Q1, write down and hold up to screen." },
                        { id: "6", text: "Client to client interaction during action plan." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "7", text: "", typeEnum: CriteriaEnum.text }]
        },
        {
            id: "9",
            title: "Module 6",
            seqNum: 8,
            subSections: [
                {
                    id: "9.1",
                    text: "Closing Thoughts 10 min",
                    seqNum: 1,
                    criteria: [
                        { id: "1", text: "Reinforces the main messages and remind clients of the actions they will take." },
                        { id: "2", text: "What stood out for you? Write down and hold up to screen." },
                        { id: "3", text: "Any questions?" },
                        { id: "4", text: "Encourages clients to “end” the course themselves." }
                    ]
                }
            ],
            summaryCriterium: [{ id: "5", text: "", typeEnum: CriteriaEnum.text }]
        },
        OverallSummarySection
    ]
};
