import * as React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

interface SpinnerProps {
    active: boolean;
    className?: string;
}

const Spinner: React.SFC<SpinnerProps> = ({ active, className, children }) => (
    <Dimmer.Dimmable className={className} dimmed={active}>
        {children}
        <Dimmer active={active} inverted className="dimmer">
            <Loader active={active} />
        </Dimmer>
    </Dimmer.Dimmable>
);

export { Spinner };
