import { cy, en } from "@booking/i18n/resources";
import { useTranslation } from "react-i18next";

type TranslationKeys = keyof typeof en & keyof typeof cy;

export function useTypeSafeTranslation<T extends TranslationKeys>(key: T) {
    const [t] = useTranslation(key);
    const typeSafeTranslation = (subKey: keyof typeof en[T]) => t(subKey as string);
    return typeSafeTranslation;
}
