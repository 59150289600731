import { createValidator } from "not-valid";
import moment from "moment";

export const lessThanAYearAgoOrFutureValidator = createValidator<moment.Moment>(
    v => v && v.isValid() ? v.isSameOrAfter(moment().subtract(1, "years")) : true,
    "Date cannot be longer than a year ago");

export const muiLessThanAYearAgoOrFutureValidator = (value: moment.Moment) => {
    return (value && value.isValid() && !value.isSameOrAfter(moment().subtract(1, "years"), "day"))
        ? "Date cannot be longer than a year ago"
        : undefined;
};
