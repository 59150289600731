import * as React from "react";

import { Version } from "../model";

class VersionInfo extends React.Component<Version> {
    public render() {
        const assemblyVersion = this.props.assemblyVersion ? `v${this.props.assemblyVersion}` : "";
        const productVersion = `Build ${this.props.productVersion}`;

        return (
            <span title={productVersion}>{assemblyVersion}.</span>
        );
    }
}

export { VersionInfo };
