import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { Card, Grid, Tab } from "semantic-ui-react";
import { TrainerDetailModel, DetailState, AttributeRoute } from "../model";
import { trainerSelector, basePathSelector } from "../selectors";
import * as details from "./details";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

export interface CoreDetailProps {
    trainer: TrainerDetailModel;
}

export type TrainerRouterQuery = {section: AttributeRoute};
export interface DetailProps extends CoreDetailProps {
    path: string;
    basePath: string;
    tab?: string;
    isTrainerApp: boolean;
    query?: TrainerRouterQuery;
}

export interface DispatchProps {
    push: (path: string) => void;
}

const tabs = Object.values(details);

class DetailInternal extends React.Component<DetailProps & DispatchProps, {}> {

    constructor(props: DetailProps & DispatchProps) {
        super(props);
    }

    public render() {
        const panes = tabs.map(t => t(this.props));

        const content = () => panes.length === 1
            ? <Card fluid>{panes[0].render()}</Card>
            : <Tab panes={panes} />;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{this.props.isTrainerApp ? "Personal Details" : this.props.trainer.fullName}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {content()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state: DetailState): DetailProps {
    return {
        trainer: trainerSelector(state),
        path: state.router.pathname,
        basePath: basePathSelector(state),
        isTrainerApp: appSelector(state) === Apps.Trainer
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
