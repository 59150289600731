import { FilterOptions } from "@booking/eventInstance/model";
import { ApplicationState } from "@booking/applicationState";
import { bookingIdSelector } from "@booking/landing/selectors";
import { includeFullyBookedCoursesSuccess, setDeliveryTypeFilterDistance } from "@booking/landing/actions";
import { CourseSearchApi } from "./courseSearchApi";
import { loadAndTrack } from "redux-request-loading";
import { Dispatch } from "redux";
import { loadVenuesSuccess } from "@booking/venues/actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CourseSearchResultRow, GroupCourseSearchResultRow } from "@booking/landing/model";

export function findVenuesAndEventInstancesForBookingInRange(
    searchOptions: FilterOptions,
    eventInstancesCallback: (eventInstances: CourseSearchResultRow[]) => void,
    eventInstanceGroupsCallback: (eventInstanceGroups: GroupCourseSearchResultRow[]) => void,
    hasMoreResultsCallback: (hasMoreResults: boolean) => void,
    preferredDeliveryCallback: (deliveryMethod: DeliveryTypeEnum,) => void,
    resultDistanceCallback: (resultDistance: number) => void) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        const bookingId = bookingIdSelector(getState());
        const api = new CourseSearchApi(bookingId);
        const result = await loadAndTrack(dispatch, "findVenuesAndEventInstancesForBookingInRange", api.getAllForBooking(searchOptions));
        dispatch(loadVenuesSuccess(result.venues));
        eventInstancesCallback(result.eventInstances);
        eventInstanceGroupsCallback(result.eventInstanceGroupItems);
        hasMoreResultsCallback(result.moreResults);
        preferredDeliveryCallback(result.preferredVenueDeliveryType);
        resultDistanceCallback(result.resultDistance);
        dispatch(setDeliveryTypeFilterDistance(result.resultDistance));
        dispatch(includeFullyBookedCoursesSuccess(searchOptions.includeFullyBooked ?? false));
    };
}
