/* eslint-disable max-lines */
import * as React from "react";
import moment from "moment";
import { validators } from "not-valid";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form, DropdownItemProps, Tab, TabProps, DropdownProps } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps, FormState } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { optionsFromObject } from "@common/crud/common";
import { TrainerAttributeDefinitionState, TrainerAttributeDefinition } from "@common/crud/trainerAttributeDefinition";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { AsyncDispatch } from "@common/redux-helpers";
import { dorsId } from "@common/validation/dorsIdValidator";
import { positiveNumber } from "@common/validation/positiveNumber";
import {
    EventTypeCreateEditModel,
    AppState,
    ModuleTypeEnum,
    ModuleType,
    BookingAppTextKeys,
    ClassroomEventTypeDetails,
    DigitalEventTypeDetails,
    WorkflowTypeEnum,
    EventTypePart,
    EventTypeDetailModel,
    PoliceAndCourtWorkflowType,
    BookingType,
    ProductCategory,
    BookingTypeEnum,
    CorporateCertificateType,
    ProductCategoryEnum,
    CorporateCertificateTypeEnum,
    CorporateWorkflowTypes,
    DurationType,
    DurationTypeEnum,
    ConstructionWorkflowType,
} from "../model";
import { eventTypeByRouteSelector, basePathSelector, editPathSelector } from "../selectors";
import { saveEventType } from "../actions";
import { EditModal } from "./EditModal";
import { ClassroomDetails } from "./details/ClassroomDetails";
import { DigitalDetails } from "./details/DigitalDetails";
import { TrainerFeesEditTab } from "./details/TrainerFeesEditTab";
import { CommunicationTab } from "./details/CommunicationTab";
import { FeeWithEffectiveDateList } from "./details/FeeWithEffectiveDateList";
import { toast } from "@common/toasts";
import { yesNoOptions } from "@common/crud/common/optionsMappers";
import { CourseFeesTab } from "./details/CourseFeesTab";
import { SchemeDeliveryType, SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { RebookingFeesForm } from "@common/crud/eventType/components/details/RebookingFeesForm";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { CourseFees } from "./details/CourseFees";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";
import { isBusinessDriverAdmin, isConstructionSelector, isCorporateSelector, isPoliceOrCourtSelector,
    isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { MuiDateField } from "@common/components/MuiDateField";
import {
    expiryDateIsAfterOrBeforeExistingEiDateValidator
} from "@common/helpers/expiredEventTypes";
import { ProductCategoryMatchesWorkflowType, showCourseFees } from "@common/crud/eventType/helpers";
import { eventTypeModifyingIsMultiDay, isNullOrUndefinedOrEmptyString, isWorkflowConstruction, isWorkflowCorporate, supportsOnRoadReport,
    workflowTypeSupportsCertificate } from "@common/global/CommonHelpers";
import { AppCommonState } from "@common/appCommonState";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { omit } from "lodash";
import { Category } from "@common/appSettings/model";
import { EditCourseCategories } from "@common/components/EditCourseCategories";

export interface EditProps extends SharedEditProps<EventTypeCreateEditModel> {
    open: boolean;
    isCorporate: boolean;
    isConstruction: boolean;
    isPoliceOrCourt: boolean;
    trainerAttributes: DropdownItemProps[];
    isBusinessDriverAdmin: boolean;
    isTtcCorporateAdmin: boolean;
    termsAndConditionsTouched: () => void;
    editPath: string;
    push: (path: string) => void;
    constructionCategories: Category[];
}

export interface DispatchProps extends SaveDispatchProps<EventTypeCreateEditModel> {
    close: () => void;
}

interface EditState extends FormState<EventTypeCreateEditModel> {
    activeIndex: string | number;
    panes: ({index: number; menuItem: string; editPath: string; render: () => JSX.Element; visible?: undefined} |
        {index: number; menuItem: string; editPath: string; visible: boolean; render: () => JSX.Element} | {})[];
}

export class EditForm extends EditComponent<EventTypeCreateEditModel, EditProps & DispatchProps, EditState> {
    public componentDidMount() {
        const panes = this.isDdrs() ? this.panesWithCourseFees : this.panes;
        const panesToShow = panes.filter(p => p?.visible !== null && p?.visible !== undefined ? p?.visible : true);
        const index = panesToShow.findIndex(pane => pane.editPath === this.props.editPath);
        this.setState({
            activeIndex: index ? index : 0,
            panes: panesToShow
        });
    }

    public componentWillReceiveProps(nextProps: Readonly<EditProps & DispatchProps & SaveDispatchProps<EventTypeCreateEditModel>>) {
        if (nextProps.model !== this.props.model) {
            super.componentWillReceiveProps(nextProps);
        }
    }

    private isDors = () => this.state.values.workflowType === WorkflowTypeEnum.Dors;
    private isDdrs = () => this.state.values.workflowType === WorkflowTypeEnum.DDRS;
    private isCpc = () => this .state.values.workflowType === WorkflowTypeEnum.CPC;
    private isCorporateWorkflow = () => isWorkflowCorporate(this.state.values.workflowType);
    private isConstructionWorkflow = () => isWorkflowConstruction(this.state.values.workflowType);
    private showCertificateInfo = () => !this.state.values.flexibleCertificatesRequired
    && (this.state.values.productCategory === +(ProductCategoryEnum.CPC)
    || this.state.values.productCategory === +(ProductCategoryEnum.FORS));

    public updateDeliveryType = (value: number, valid: boolean) => {
        if (value !== SchemeDeliveryTypeEnum.ClassroomAndDigital) {
            if (value !== SchemeDeliveryTypeEnum.Classroom) {
                this.updateProperty("classroomEventTypeDetails", this.state.values.classroomEventTypeDetails, true);
                this.updateProperty("internalId", "", true);
            }

            if (value !== SchemeDeliveryTypeEnum.Digital) {
                this.updateProperty("digitalEventTypeDetails", this.state.values.digitalEventTypeDetails, true);
                this.updateProperty("internalIdDigital", "", true);
            }
        }
        this.updateProperty("deliveryType", value, valid);
    };

    private deliveryType = () => this.state?.values.deliveryType;
    private requiresClassroomDetails = () => this.deliveryType() === SchemeDeliveryTypeEnum.Classroom
        || this.deliveryType() === SchemeDeliveryTypeEnum.ClassroomAndDigital;
    private requiresDigitalDetails = () => this.deliveryType() === SchemeDeliveryTypeEnum.Digital
        || this.deliveryType() === SchemeDeliveryTypeEnum.ClassroomAndDigital;
    private requiresCourseFees = () => showCourseFees(this.state.values);

    private isMultiDay = () => eventTypeModifyingIsMultiDay(this.state.values.deliveryType, this.state.values.classroomEventTypeDetails,
        this.state.values.digitalEventTypeDetails);

    private panes = [
        {
            index: 0, menuItem: "Main Details", editPath: "", render: () => {
                const {  mandatoryCorpCertificate, optionalCorpCertificate, workflowType, deliveryType, productCategory } = this.state.values;

                return (
                    <Tab.Pane key="main-details">
                        <h3>{!this.props.isPoliceOrCourt ? "Product Details" : "Scheme Details"}</h3>
                        {(this.isCorporateWorkflow() || this.isConstructionWorkflow()) &&
                            <Input.Text
                                value={this.state.values.name}
                                label="Name"
                                showErrors={this.state.showErrors}
                                required
                                onChange={this.onChange("name")}
                            />
                        }
                        {(this.isDors() || this.isDdrs()) && <>
                            <Input.Text
                                value={this.state.values.name}
                                label="Name (En)"
                                showErrors={this.state.showErrors}
                                required
                                onChange={this.onChange("name")}
                            />
                            <Input.Text
                                value={this.state.values.nameCy}
                                label="Name (Cy)"
                                showErrors={this.state.showErrors}
                                onChange={this.onChange("nameCy")}
                            />
                        </>}
                        <Input.Text
                            value={this.state.values.abbreviation}
                            label="Abbreviation"
                            showErrors={this.state.showErrors}
                            required
                            onChange={this.onChange("abbreviation")}
                        />
                        <ExtendedDropdownNumber
                            value={this.state.values.workflowType}
                            label="Workflow Type"
                            validation={[validators.requiredNumber()]}
                            showErrors={this.state.showErrors}
                            options={optionsFromObject(this.props.isCorporate
                                ? CorporateWorkflowTypes(this.props.isBusinessDriverAdmin, this.props.isTtcCorporateAdmin)
                                : this.props.isConstruction
                                    ? ConstructionWorkflowType
                                    : PoliceAndCourtWorkflowType)}
                            onChange={this.updateWorkflowType}
                            dynamicOptions
                            search
                        />
                        {(this.isCpc() || this.isConstructionWorkflow()) && (
                            <>
                                <MarkdownEditor
                                    value={this.state.values?.productIntro}
                                    label="Product Intro"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("productIntro")}
                                />
                                <MarkdownEditor
                                    value={this.state.values.productDescription}
                                    label="Product Description"
                                    showErrors={this.state.showErrors}
                                    onChange={this.onChange("productDescription")}
                                />
                            </>
                        )}
                        {(!this.props.isPoliceOrCourt || this.isDors()) &&
                            <ExtendedDropdownNumber
                                value={this.state.values.moduleType}
                                label="Course Type"
                                showErrors={this.state.showErrors}
                                required
                                options={ObjectKeys(omit(ModuleType, ModuleTypeEnum.None))
                                    .filter(k => (this.state.values.deliveryType !== SchemeDeliveryTypeEnum.Digital
                                        && this.state.values.deliveryType !== SchemeDeliveryTypeEnum.ClassroomAndDigital)
                                        || +k === ModuleTypeEnum.Theory)
                                    .filter(k => !this.isMultiDay() || +k !== ModuleTypeEnum.Both)
                                    .map(k => ({ text: ModuleType[k], value: +k }))}
                                onChange={this.updateModuleType}
                                search
                                dynamicOptions
                            />}
                        {((this.isCorporateWorkflow() || this.isConstructionWorkflow()) && this.state.values.moduleType === ModuleTypeEnum.Both) && (
                            <ExtendedDropdownNumber
                                value={this.state.values.durationType}
                                label="Duration Type"
                                showErrors={this.state.showErrors}
                                required
                                options={ObjectKeys(omit(DurationType, DurationTypeEnum.None)).map(k => ({ text: DurationType[k], value: +k }))}
                                onChange={this.updateDurationType}
                                search
                                disabled
                            />
                        )}
                        {!this.props.isPoliceOrCourt && <>
                            <ExtendedDropdownNumber
                                value={this.state.values.bookingType}
                                label="Booking Type"
                                showErrors={this.state.showErrors}
                                required
                                options={ObjectKeys(omit(BookingType, BookingTypeEnum.None)).map(k => ({ text: BookingType[k], value: +k }))}
                                onChange={this.updateBookingType}
                                search
                            />
                            {(this.isCpc() || this.isConstructionWorkflow()) && <Input.Checkbox
                                value={this.state.values?.showInOpenBookingApp}
                                label="Show in Open Booking App"
                                showErrors={this.state.showErrors}
                                onChange={this.onChange("showInOpenBookingApp")}
                                toggle
                                disabled={!this.state.values.bookingType || this.state.values.bookingType === BookingTypeEnum.Closed}
                            />}
                            <Input.Checkbox
                                label="Delegates Required?"
                                value={Boolean(this.state.values.delegatesRequired)}
                                onChange={this.onChange("delegatesRequired")}
                                toggle
                                readOnly={this.state.values.bookingType === BookingTypeEnum.Open
                                    || this.state.values.workflowType === WorkflowTypeEnum.CPC}
                                disabled={this.state.values.bookingType === BookingTypeEnum.Open
                                    || this.state.values.workflowType === WorkflowTypeEnum.CPC}
                            />
                            {this.isCpc() && <Input.Checkbox
                                label={"Flexible Certificates Required?"}
                                value={Boolean(this.state.values.flexibleCertificatesRequired)}
                                onChange={this.onChange("flexibleCertificatesRequired")}
                                toggle
                            />}
                            {workflowTypeSupportsCertificate(this.state.values.workflowType) &&
                                <Input.Checkbox
                                    label="Certificate Required?"
                                    value={Boolean(this.state.values.certificateRequired)}
                                    onChange={this.onChange("certificateRequired")}
                                    toggle
                                />
                            }
                            {supportsOnRoadReport(workflowType, productCategory)  &&
                                <Input.Checkbox
                                    label="On Road Report Required"
                                    value={Boolean(this.state.values.onRoadReportRequired)}
                                    onChange={this.onChange("onRoadReportRequired")}
                                    toggle
                                />
                            }

                            {this.props.isConstruction &&
                            <>
                                <Input.Checkbox
                                    label="Reseller Course"
                                    value={Boolean(this.state.values.resellerCourse)}
                                    onChange={this.onChange("resellerCourse")}
                                    toggle
                                />

                                <EditCourseCategories
                                    label="Course Categories"
                                    categories={this.props.constructionCategories}
                                    values={this.state.values.courseCategories}
                                    onChange={this.onChange("courseCategories")} />
                            </>}

                            <ExtendedDropdownNumber
                                value={this.state.values.productCategory}
                                label="Product Category"
                                showErrors={this.state.showErrors}
                                required
                                dynamicOptions
                                options={ObjectKeys(omit(ProductCategory, ProductCategoryEnum.None))
                                    .filter(k => !workflowType || ProductCategoryMatchesWorkflowType(+k, workflowType))
                                    .map(k => ({ text: ProductCategory[k], value: +k }))}
                                onChange={this.updateProductCategory}
                                search
                            />
                            {this.showCertificateInfo() &&
                            <>
                                <ExtendedDropdownNumber
                                    value={mandatoryCorpCertificate}
                                    label="Mandatory Certificate"
                                    showErrors={this.state.showErrors}
                                    options={ObjectKeys(omit(CorporateCertificateType, CorporateCertificateTypeEnum.None))
                                        .map(k => ({ text: CorporateCertificateType[k], value: +k }))}
                                    required
                                    disabled
                                />
                                <ExtendedDropdownNumber
                                    value={optionalCorpCertificate}
                                    label="Optional Certificate"
                                    showErrors={this.state.showErrors}
                                    options={ObjectKeys(CorporateCertificateType)
                                        .map(k => ({ text: CorporateCertificateType[k], value: +k }))}
                                    required
                                    disabled
                                />
                            </>}
                        </>}
                        <ExtendedDropdownNumber
                            value={this.state.values.deliveryType}
                            label="Delivery Type"
                            showErrors={this.state.showErrors}
                            required
                            options={ObjectKeys(omit(SchemeDeliveryType, SchemeDeliveryTypeEnum.Unknown))
                                .filter(k => (this.state.values.moduleType !== ModuleTypeEnum.Practical && this.state.values.moduleType !== ModuleTypeEnum.Both)
                                    || +k === SchemeDeliveryTypeEnum.Classroom)
                                .map(k => ({ text: SchemeDeliveryType[k], value: +k }))}
                            onChange={this.updateDeliveryType}
                            search
                            dynamicOptions
                        />
                        {!this.props.isPoliceOrCourt && <>
                            {(isWorkflowCorporate(workflowType) || isWorkflowConstruction(workflowType)) &&
                            <>
                                {(deliveryType === SchemeDeliveryTypeEnum.Classroom || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&

                                    <Input.Text
                                        value={this.state.values.internalId}
                                        label="Internal ID (Classroom)"
                                        onChange={this.onChange("internalId")}
                                    />
                                }
                                {(deliveryType === SchemeDeliveryTypeEnum.Digital || deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) &&

                                    <Input.Text
                                        value={this.state.values.internalIdDigital}
                                        label="Internal ID (Digital)"
                                        onChange={this.onChange("internalIdDigital")}
                                    />
                                }
                            </>
                            }
                            {(workflowType === WorkflowTypeEnum.CPC || productCategory === ProductCategoryEnum.OnRoadWithCpc) &&
                                <Input.Text
                                    value={this.state.values.courseApprovalNumber}
                                    label="Course Approval Number"
                                    onChange={this.onChange("courseApprovalNumber")}
                                />
                            }
                        </> }
                        {this.isDors() &&
                            <Input.Number
                                value={this.state.values.dorsId}
                                label="DORS ID"
                                showErrors={this.state.showErrors}
                                validation={dorsId}
                                onChange={this.onChange("dorsId")}
                            />
                        }
                        {this.isDors() && <ExtendedDropdown
                            value={this.state.values.showCarType?.toString() ?? "false"}
                            label="Show car"
                            showErrors={this.state.showErrors}
                            dynamicOptions
                            options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                            required
                            onChange={this.updateShowCarType}
                            search
                        />}

                        {(this.state.values.moduleType === ModuleTypeEnum.Practical ||
                            this.state.values.moduleType === ModuleTypeEnum.Both) &&
                            <>
                                <Input.Number
                                    value={this.state.values.maxNumberOfAttendeesPerPracticalTrainer}
                                    label="Max Number Of Attendees Per Practical Trainer"
                                    showErrors={this.state.showErrors}
                                    validation={[validators.requiredNumber(), positiveNumber()]}
                                    validationOptions={{ sequential: false }}
                                    onChange={this.onChange("maxNumberOfAttendeesPerPracticalTrainer")}
                                />
                                {(this.isCorporateWorkflow() || this.isConstructionWorkflow()) &&
                                    <ExtendedDropdown
                                        value={this.state.values.practicalTrainerAttributeId}
                                        label="Practical Trainer attribute"
                                        showErrors={this.state.showErrors}
                                        dynamicOptions
                                        options={this.props.trainerAttributes}
                                        required
                                        onChange={this.onChange("practicalTrainerAttributeId")}
                                        search
                                    />}
                                {(this.isDors()) &&
                                <>
                                    <ExtendedDropdown
                                        value={this.state.values.manualCarPracticalTrainerAttributeId}
                                        label="Manual Car Practical Trainer attribute"
                                        showErrors={this.state.showErrors}
                                        dynamicOptions
                                        options={this.props.trainerAttributes}
                                        required
                                        onChange={this.onChange("manualCarPracticalTrainerAttributeId")}
                                        search
                                    />
                                    <ExtendedDropdown
                                        value={this.state.values.automaticCarPracticalTrainerAttributeId}
                                        label="Automatic Car Practical Trainer attribute"
                                        showErrors={this.state.showErrors}
                                        dynamicOptions
                                        options={this.props.trainerAttributes}
                                        required
                                        onChange={this.onChange("automaticCarPracticalTrainerAttributeId")}
                                        search
                                    />
                                </>
                                }
                            </>}
                        {
                            this.state.values.showCarType &&
                            <>
                                <Input.Text
                                    value={this.state.values.amendableBookingAppTexts[BookingAppTextKeys.EnglishCarTypeNote] ?? ""}
                                    label="Car Type Note (En)"
                                    showErrors={this.state.showErrors}
                                    required
                                    onChange={this.onBookingAppTextChange(BookingAppTextKeys.EnglishCarTypeNote)}
                                />
                                <Input.Text
                                    value={this.state.values.amendableBookingAppTexts[BookingAppTextKeys.CymraegCarTypeNote] ?? ""}
                                    label="Car Type Note (Cy)"
                                    showErrors={this.state.showErrors}
                                    required
                                    onChange={this.onBookingAppTextChange(BookingAppTextKeys.CymraegCarTypeNote)}
                                />
                            </>
                        }
                        {this.props.isPoliceOrCourt && <ExtendedDropdown
                            value={this.state.values.flexiPayEnabled?.toString() ?? "false"}
                            label="Flexi-Booking Enabled"
                            showErrors={this.state.showErrors}
                            options={yesNoOptions()}
                            onChange={this.updateFlexiPayEnabled}
                            search
                        />}
                        {this.state.values.flexiPayEnabled &&
                            <>
                                <h3>Flexi-Booking Fees</h3>
                                <FeeWithEffectiveDateList
                                    fees={this.state.values.flexiPayFees}
                                    feesProperty="flexiPayFees"
                                    feeLabel="Flexi-Booking"
                                    onChange={this.onChange}
                                />
                            </>
                        }
                        {this.isDors() &&
                        <ExtendedDropdown
                            value={this.state.values.currentDorsScheme?.toString() ?? "false"}
                            label="Current DORS scheme"
                            showErrors={this.state.showErrors}
                            options={yesNoOptions()}
                            onChange={this.updateCurrentDorsScheme}
                            search
                        />}
                        <RebookingFeesForm form={this} />
                        <MuiDateField
                            value={this.state.values.expiryDate}
                            label="Expiry Date"
                            validation={[muiTodayOrfutureDateValidator,
                                (expiryDate) => expiryDateIsAfterOrBeforeExistingEiDateValidator(this.state.values.id, expiryDate)]}
                            onChange={this.onChange("expiryDate")}
                        />
                    </Tab.Pane>
                );
            }
        },
        {
            index: 1, menuItem: "Classroom details", editPath: "/classroomDetails", visible: this.requiresClassroomDetails(), render: () => {
                return (
                    <ClassroomDetails
                        classroomEventTypeDetails={this.state.values.classroomEventTypeDetails}
                        workflowType={this.state.values.workflowType}
                        showErrors={this.state.showErrors}
                        productCategory={this.state.values.productCategory}
                        updateStartTimes={this.updateStartTimes}
                        updateEventTypeParts={this.updateEventTypeParts}
                        onChange={this.onDetailsChange}
                        theoryAndPractical={this.state.values.moduleType === ModuleTypeEnum.Both}
                        durationType={this.state.values.durationType}
                        showInOpenBookingApp={this.state.values.showInOpenBookingApp}
                    />
                );
            }
        },
        {
            index: 2, menuItem: "Digital details", editPath: "/digitalDetails", visible: this.requiresDigitalDetails(), render: () => {
                return (
                    <DigitalDetails
                        digitalEventTypeDetails={this.state.values.digitalEventTypeDetails}
                        workflowType={this.state.values.workflowType}
                        showErrors={this.state.showErrors}
                        productCategory={this.state.values.productCategory}
                        updateEventTypeParts={this.updateEventTypeParts}
                        updateStartTimes={this.updateStartTimes}
                        onChange={this.onDetailsChange}
                        showInOpenBookingApp={this.state.values.showInOpenBookingApp}
                    />
                );
            }
        },
        {
            index: 3, menuItem: "Course Fees", editPath: "/courseFees", visible: this.requiresCourseFees(), render: () => {
                return (
                    <CourseFees
                        courseFeesEventTypeDetails={this.state.values.courseFeesEventTypeDetails }
                        workflowType={this.state.values.workflowType}
                        deliveryType={this.state.values.deliveryType}
                        bookingType={this.state.values.bookingType}
                        showErrors={this.state.showErrors}
                        eventTypeName={this.state.values.name}
                        onChange={this.onDetailsChange}
                    />
                );
            }
        },
        {
            index: 4, menuItem: "Trainer", editPath: "/trainer", render: () => {
                return (
                    <TrainerFeesEditTab
                        values={this.state.values}
                        showErrors={this.state.showErrors}
                        onChange={this.onChange}
                        push={this.props.push}
                        onDropdownPropertyChange={this.onDropdownPropertyChange}
                        onDetailsChange={this.onDetailsChange}
                        updateDisableAutoAllocateIfTrainerNonCompliant={this.updateDisableAutoAllocateIfTrainerNonCompliant}
                    />
                );
            }
        },
        {
            index: 5, menuItem: "Communications", editPath: "/communications", render: () => {
                return (
                    <CommunicationTab
                        model={this.state.values}
                        showErrors={this.state.showErrors}
                        onPropertyChange={this.onChange}
                        onNestedPropertyChange={this.onDetailsChange}
                    />
                );
            }
        },
    ];

    private panesWithCourseFees = [...this.panes.filter(pane => pane.editPath !== "/courseFees"),
        {
            index: 6, menuItem: "Course Fees", editPath: "/courseFees", render: () => {
                return (
                    <CourseFeesTab
                        ddrsCourseFees={this.state.values.ddrsCourseFees}
                        showErrors={this.state.showErrors}
                        onPropertyChange={this.onChange}
                        push={this.props.push}
                        create={false}
                    />
                );
            }
        }
    ];

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tab panes={this.state.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
            </Form>
        );
    }

    public onBookingAppTextChange = (textKey: string) => (value: string, valid: boolean) => {
        const newValue = { ...this.state.values.amendableBookingAppTexts, [textKey]: value };
        this.updateProperty("amendableBookingAppTexts", newValue, valid);
    };

    public updateShowCarType = (value: string) => {
        this.updateProperty("showCarType", value === "true", true);
    };

    public updateFlexiPayEnabled = (value: string) => {
        this.updateBoolean(value, "flexiPayEnabled");
    };

    public updateBoolean = (value: string, key: keyof EventTypeCreateEditModel) => {
        this.updateProperty(key, value === "true", true);
    };

    public updateCurrentDorsScheme = (value: string) => {
        this.updateBoolean(value, "currentDorsScheme");
    };

    public updateDisableAutoAllocateIfTrainerNonCompliant = (value: string) => {
        this.updateBoolean(value, "disableAutoAllocateIfTrainerNonCompliant");
    };

    public onDropdownPropertyChange = (propName: keyof (EventTypeCreateEditModel)) => (event: any, { value }: DropdownProps) =>
        this.onChange(propName)(value as any, true);

    public onChange = (propName: keyof EventTypeCreateEditModel) => (value: any, valid: boolean) => {
        this.updateProperty(propName, value, valid);
        if (propName === "workflowType" && value === WorkflowTypeEnum.DDRS) {
            this.updateProperty("moduleType", ModuleTypeEnum.Theory, true);
        }
    };

    public onDetailsChange = (propName: keyof EventTypeCreateEditModel, termsAndConditionsProperty?: boolean) => (value: any, valid: boolean) => {
        this.updateNestedProperty(propName, value, valid);
        if (termsAndConditionsProperty) {
            this.props.termsAndConditionsTouched();
        }
    };

    public updateModuleType = (value: number, valid: boolean) => {
        if (value === ModuleTypeEnum.Theory || value === ModuleTypeEnum.Practical) {
            if (value === ModuleTypeEnum.Practical) {
                this.updateNestedProperty("classroomEventTypeDetails.maxNumberOfAttendeesPerTrainer", null, true);
                this.updateNestedProperty("digitalEventTypeDetails.maxNumberOfAttendeesPerTrainer", null, true);
                this.updateProperty("theoryTrainerAttributeId", null, true);
                this.updateProperty("theoryComplianceAttributeIds", [], true);
            }

            if (value === ModuleTypeEnum.Theory) {
                this.updateProperty("maxNumberOfAttendeesPerPracticalTrainer", null, true);
                this.updateProperty("practicalTrainerAttributeId", null, true);
                this.updateProperty("manualCarPracticalTrainerAttributeId", null, true);
                this.updateProperty("automaticCarPracticalTrainerAttributeId", null, true);
                this.updateProperty("practicalComplianceAttributeIds", [], true);
            }

            if (this.state.values.durationType === DurationTypeEnum.Combined) {
                this.updateDurationType(DurationTypeEnum.SeparateTheoryPractical, true);
            }
        }
        this.updateProperty("moduleType", value, valid);
    };

    public updateDurationType = (value: number, valid: boolean) => {
        this.updateNestedProperty("classroomEventTypeDetails.theoryEventDurations", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.suggestedStartTimesForTheorySessions", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.practicalEventDurations", null, true);
        this.updateNestedProperty("classroomEventTypeDetails.suggestedStartTimesForPracticalSessions", null, true);
        this.updateProperty("durationType", value, valid);
    };

    public updateBookingType = (value: number, valid: boolean) => {
        if (!value || value === BookingTypeEnum.Closed) {
            this.updateProperty("showInOpenBookingApp", false, true);
        }

        if (value === BookingTypeEnum.Open) {
            this.updateProperty("delegatesRequired", true, true);
        }

        this.updateProperty("bookingType", value, valid);
    };

    public updateWorkflowType = (value: number) => {
        if (this.state.values.productCategory && !ProductCategoryMatchesWorkflowType(this.state.values.productCategory, value)) {
            this.updateProperty("productCategory", null, true);
        }

        if (supportsOnRoadReport(value, this.state.values.productCategory)) {
            this.updateProperty("onRoadReportRequired", true, true);
        }
        else {
            this.updateProperty("onRoadReportRequired", null, true);
        }

        if (value === WorkflowTypeEnum.CPC) {
            this.updateProperty("delegatesRequired", true, true);
        }

        if (value !== WorkflowTypeEnum.CPC) {
            this.updateProperty("flexibleCertificatesRequired", false, true);
        }

        if (workflowTypeSupportsCertificate(value)) {
            this.updateProperty("certificateRequired", true, true);
        }

        this.updateProperty("workflowType", value, true);
    };

    public updateProductCategory = (value: number, valid: boolean) => {
        switch (value) {
            case ProductCategoryEnum.CPC:
                this.updateProperty("mandatoryCorpCertificate", CorporateCertificateTypeEnum.CPC, valid);
                this.updateProperty("optionalCorpCertificate", CorporateCertificateTypeEnum.None, valid);
                break;
            case ProductCategoryEnum.FORS:
                this.updateProperty("mandatoryCorpCertificate", CorporateCertificateTypeEnum.FORS, valid);
                this.updateProperty("optionalCorpCertificate", CorporateCertificateTypeEnum.CPC, valid);
                break;
            default:
                this.updateProperty("mandatoryCorpCertificate", null, valid);
                this.updateProperty("optionalCorpCertificate", null, valid);
                break;
        }

        if (this.state.values.workflowType === WorkflowTypeEnum.BusinessDriver) {
            if (this.state.values.productCategory === ProductCategoryEnum.LicenceAcquisition && value !== ProductCategoryEnum.LicenceAcquisition) {
                this.updateProperty("onRoadReportRequired", true, true);
            }

            if (this.state.values.productCategory !== ProductCategoryEnum.LicenceAcquisition && value === ProductCategoryEnum.LicenceAcquisition) {
                this.updateProperty("onRoadReportRequired", null, true);
            }
        }

        this.updateProperty("productCategory", value, valid);
    };

    public submit = async () => {
        let validationKeys = ObjectKeys(this.state.valid);
        const deliveryType = this.state.values.deliveryType;
        if (deliveryType !== SchemeDeliveryTypeEnum.ClassroomAndDigital) {
            if (deliveryType !== SchemeDeliveryTypeEnum.Classroom) {
                validationKeys = validationKeys.filter(k => k.startsWith("classroomEventTypeDetails") === false);
            }

            if (deliveryType !== SchemeDeliveryTypeEnum.Digital) {
                validationKeys = validationKeys.filter(k => k.startsWith("digitalEventTypeDetails") === false);
            }
        }

        if (validationKeys.some(k =>
            !this.state.valid[k])) {
            this.setState({ showErrors: true });
            toast.warning(!this.props.isPoliceOrCourt
                ? "Product cannot be saved as some fields are invalid"
                : "Scheme cannot be saved as some fields are invalid");
            return;
        }

        if (this.state.values.hasRebookingFees && !this.state.values.rebookingFees.some(fee => fee.feeAfter14Days > 0 && fee.feeCloseToCourseDate > 0)) {
            toast.warning(!this.props.isPoliceOrCourt
                ? "Product cannot be saved as rebooking fees are required"
                : "Scheme cannot be saved as rebooking fees are required");
            return;
        }

        if (this.state.values.competencyReportRequired &&
            isNullOrUndefinedOrEmptyString(this.state.values.competencyReportLink))
        {
            toast.warning("A competency report link is required");
            return;
        }

        if (!this.state.values.flexiPayEnabled || this.flexiPayFeesValid()) {
            await this.handleSubmit();
            toast.success(!this.props.isPoliceOrCourt
                ? "Product successfully edited"
                : "Scheme successfully edited");
        }
    };

    private flexiPayFeesValid = () => {
        if (!this.state.values.flexiPayFees?.length) {
            toast.error("You must include at least one flexi-booking fee.");
            return false;
        }

        const uniqueFeeDates = new Set(this.state.values.flexiPayFees.map(f => f.effectiveDate.toISOString()));
        if (uniqueFeeDates.size < this.state.values.flexiPayFees.length) {
            toast.error("Two or more flexi-booking fees have the same effective date.");
            return false;
        }

        return true;
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));

    public updateStartTimes = (propName: keyof ClassroomEventTypeDetails | keyof DigitalEventTypeDetails,
        startTimes: Dictionary<moment.Duration[]>) => {
        const startTimeValues = Object.values(startTimes);
        const valid = startTimeValues.flatMap(x => x).every(x => x && x.isValid()) &&
        startTimeValues.every(x => x.length === new Set(x.map(y => y?.asMilliseconds())).size);
        this.updateNestedProperty(propName, startTimes, valid);
    };

    public updateEventTypeParts = (propName: keyof ClassroomEventTypeDetails | keyof DigitalEventTypeDetails,
        eventTypeParts: Dictionary<EventTypePart>) => {
        this.updateNestedProperty(propName, eventTypeParts, true);
    };
}

function mapStateToProps(state: AppState & TrainerAttributeDefinitionState & AppCommonState) {
    const open = state.router.pathname.endsWith("edit");
    return {
        model: eventTypeByRouteSelector(state) as EventTypeCreateEditModel | EventTypeDetailModel,
        trainerAttributes: trainerAttributeDefinitionsSelector(state),
        open,
        isCorporate: isCorporateSelector(state),
        isConstruction: isConstructionSelector(state),
        isPoliceOrCourt: isPoliceOrCourtSelector(state),
        isBusinessDriverAdmin: isBusinessDriverAdmin(state),
        isTtcCorporateAdmin: isTtcCorporateAdmin(state),
        basePath: basePathSelector(state),
        editPath: open ? editPathSelector(state) : null,
        feesModalOpen: state.router.pathname.endsWith("feesOpen/edit") ||
            state.router.pathname.endsWith("monitorObserverFeesOpen/edit") ||
            state.router.pathname.endsWith("otherTrainerFeesOpen/edit"),
        constructionCategories: state.appSettings.constructionCategories
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (eventType: EventTypeCreateEditModel, basePath: string) => dispatch(saveEventType(eventType, basePath)),
        dispatchPush: (basePath: string) => dispatch(push(basePath)),
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    const { model, open, isCorporate, isConstruction, isPoliceOrCourt, trainerAttributes, basePath, editPath, termsAndConditionsTouched,
        feesModalOpen, constructionCategories } = propsFromState;
    const { dispatchSave, dispatchPush } = propsFromDispatch;

    return {
        model,
        open,
        isCorporate,
        isConstruction,
        isPoliceOrCourt,
        trainerAttributes: trainerAttributes.map((attr: TrainerAttributeDefinition) => ({ value: attr.id, text: attr.name })),
        isBusinessDriverAdmin: propsFromState.isBusinessDriverAdmin,
        isTtcCorporateAdmin: propsFromState.isTtcCorporateAdmin,
        save: (eventType: EventTypeCreateEditModel) => {
            if (feesModalOpen) {
                return;
            }
            return dispatchSave(eventType, `${basePath}/${model.id}${editPath}`);
        },
        close: () => dispatchPush(`${basePath}/${model.id}${editPath}`),
        push: (path) => propsFromDispatch.dispatchPush(`${propsFromState.basePath}/${model.id}/${path}`),
        termsAndConditionsTouched,
        editPath,
        constructionCategories
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
