import { MultiDayTrainerAvailabilitySelectModel, TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import {
    CarType,
    EventInstance,
    EventInstanceDetailModel,
    EventTrainer,
    GroupEventInstancesModel,
    OtherTrainerTypeCategoryEnum,
    PracticalEventTrainer,
    SameDayEventInstanceModel,
    TrainerType
} from "../../model";
import { TrainerAllocationModel } from "@common/crud/trainer/model";
import { EventInstanceApi } from "@common/crud/eventInstance";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import moment from "moment";
import { BusinessLineType } from "@common/redux-helpers";

export function getTrainerTypeAsString(trainerType: TrainerType) {
    switch (trainerType) {
        case TrainerType.MonitorTrainer:
            return "Monitor";
        case TrainerType.PracticalTrainer:
            return "Practical";
        case TrainerType.OtherTrainer:
            return "Other Trainers";
        default:
            return "Trainer";
    }
}

export function getFlattenedTrainers(
    trainers: {
        id: string; fee: number; sundries: number; carType?: CarType; availableForOtherTrainers?: boolean;
        dateMadeAvailableForOtherTrainers?: moment.Moment; otherTrainerTypeCategory?: OtherTrainerTypeCategoryEnum; }[],
    filteredTrainers: Record<string, TrainerAvailabilitySelectModel[]>): TrainerAvailabilitySelectModel[] {
    const tempResult = Object.values(filteredTrainers)
        .reduce((a, b) => a.concat(b), []);

    const result = tempResult.filter(t => trainers?.some(cst => cst.id === t.id)).map(t => {
        const tr = trainers.find(cst => cst.id === t.id);
        return {
            ...t,
            fee: tr.fee,
            sundries: tr.sundries,
            carType: tr.carType,
            availableForOtherTrainers: tr.availableForOtherTrainers,
            dateMadeAvailableForOtherTrainers: tr.dateMadeAvailableForOtherTrainers,
            otherTrainerTypeCategory: tr.otherTrainerTypeCategory
        };
    });
    return result;
}

export function getAllocationsFromExistingTrainers(trainers: PracticalEventTrainer[]): TrainerAllocationModel[] {
    return trainers?.map(t => ({
        id: t.id,
        fee: t.fee,
        sundries: t.sundries,
        allocateToCourses: [],
        carType: t.carType,
        availableForOtherTrainers: t.availableForOtherTrainers,
        dateMadeAvailableForOtherTrainers: t.dateMadeAvailableForOtherTrainers
    }));
}

export function getLimitMessage(trainerType: TrainerType, isDigital: boolean, isOneToOne?: boolean, businessLineType?: BusinessLineType) {
    if (trainerType === TrainerType.OtherTrainer) {
        return null;
    }

    if (isOneToOne) {
        return { value: 1, message: "This trainer cannot be selected as only 1 trainer can be allocated to a 1:1 course." };
    }

    if ((businessLineType === BusinessLineType.Corporate || businessLineType === BusinessLineType.Construction)
        && (trainerType === TrainerType.TheoryTrainer || trainerType === TrainerType.MonitorTrainer)) {
        return {
            value: 1,
            message: `This trainer cannot be selected as only 1
                ${(trainerType === TrainerType.TheoryTrainer ? "theory trainer" : "monitor")}
                can be allocated to a ${(businessLineType === BusinessLineType.Corporate ? "commercial" : "construction")} course`
        };
    }

    if (isDigital) {
        return { value: 1, message: "This trainer cannot be selected as only 1 theory trainer can be allocated to a digital course." };
    }
    else if (trainerType === TrainerType.TheoryTrainer) {
        return { value: 2, message: "This trainer cannot be selected as only 2 theory trainers can be allocated to a classroom course." };
    } else {
        return null;
    }
}

export function IncreaseLimitIfTrainerIsRcCoverForDigitalCourse(
    selectedTrainer: TrainerAvailabilitySelectModel[],
    newTrainer: TrainerAvailabilitySelectModel,
    publishedDate: moment.Moment,
    startDate: moment.Moment,
    isDigital: boolean) {
    const rcCoverTrainer = selectedTrainer.filter(trainer => trainer.isRCCoverOnDate).length;
    const currentDayStart = moment.utc().startOf("day");
    const isBefore = startDate.startOf("day").isSameOrBefore(currentDayStart);
    if ( isDigital &&
        !!publishedDate &&
        isBefore &&
        selectedTrainer.length <= 1 &&
        (rcCoverTrainer === 1 && !newTrainer?.isRCCoverOnDate || newTrainer?.isRCCoverOnDate))
    {
        return 1;
    }
    return 0;
}

export function getTrainerAllocations(
    selectedTrainers: TrainerAvailabilitySelectModel[],
    selectedEventInstances: SameDayEventInstanceModel[],
    isCorporateOrConstruction: boolean): TrainerAllocationModel[] {

    return selectedTrainers.map(s => {
        return {
            id: s.id,
            fee: s.fee,
            sundries: s.sundries,
            allocateToCourses: selectedEventInstances.filter(e => e.trainerIds.includes(s.id)).map(e => e.id),
            carType: isCorporateOrConstruction ? "Any" : s.carType,
            isFeeNonStandard: s.isFeeNonStandard,
            availableForOtherTrainers: s.availableForOtherTrainers,
            dateMadeAvailableForOtherTrainers: s.dateMadeAvailableForOtherTrainers,
            otherTrainerTypeCategory: s.otherTrainerTypeCategory
        };
    });
}

export function getDrinkDriveTrainerAllocations(
    eventInstanceId: string,
    selectedTrainers: TrainerAvailabilitySelectModel[],
    selectedEventInstances: SameDayEventInstanceModel[]): TrainerAllocationModel[] {

    return selectedTrainers.map(s => {
        return {
            id: s.id,
            name: s.fullName,
            fee: s.fee,
            sundries: s.sundries,
            allocateToCourses: selectedEventInstances.filter(e => e.trainerIds.includes(s.id)).map(e => e.id),
            eventInstanceId,
            dayNumbers: s.allocatedDayNumbers,
            isFeeNonStandard: s.isFeeNonStandard,
            otherTrainerTypeCategory: s.otherTrainerTypeCategory
        };
    });
}

export function getTrainerStatus(trainer: MultiDayTrainerAvailabilitySelectModel, selectedDdrsEventInstances?: GroupEventInstancesModel[], dayNumber?: number) {
    if (selectedDdrsEventInstances && selectedDdrsEventInstances.length > 0 &&
        selectedDdrsEventInstances[dayNumber-1].trainerIds?.some(tid => tid === trainer.id)) {
        return "Allocated";
    }

    if (trainer.dayAvailabilities[dayNumber].some(c => c.isRCCoverOnDate)) {
        return "Booked as RC Cover";
    }

    if (trainer.dayAvailabilities[dayNumber].some(c => c.isRCMonitorOnDate)) {
        return "Booked as RC Monitor";
    }

    if (trainer.dayAvailabilities[dayNumber].some(c => c.isBooked)) {
        return "Booked";
    }

    if (trainer.dayAvailabilities[dayNumber].some(c => c.isProvisionallyBooked)) {
        return "Provisionally booked";
    }

    return trainer.dayAvailabilities[dayNumber].some(c => c.isStandbyTrainerDuringEventInstance) ? "Booked as Standby Trainer" : "Not Booked";
}

export const mapTrainer = (trainer: (EventTrainer | PracticalEventTrainer), type: TrainerType, eventInstance: EventInstance = null,
    withAdditionalFees: boolean = false) => {
    const { id, fee, additionalFeesTotal, sundries, name, otherTrainerTypeCategory } = trainer;
    const trainerObject = { id, sundries, name,
        fee: withAdditionalFees ? fee + additionalFeesTotal : fee,
        carType: type === TrainerType.PracticalTrainer ? (trainer as PracticalEventTrainer).carType : null,
        otherTrainerType: type === TrainerType.OtherTrainer, otherTrainerTypeCategory };
    // eslint-disable-next-line eqeqeq
    if (eventInstance != null) {
        return { ...trainerObject, deliveryDateTime: eventInstance.deliveryDateTime };
    }
    return trainerObject;
};

export function getTrainersIdsFromDetail(detail: EventInstanceDetailModel, trainerType: TrainerType) {
    switch (trainerType) {
        case TrainerType.TheoryTrainer:
            return detail.trainers.map(t => t.id);
        case TrainerType.PracticalTrainer:
            return detail.practicalTrainers.map(p => p.id);
        case TrainerType.MonitorTrainer:
            return detail.monitorTrainers.map(m => m.id);
        case TrainerType.OtherTrainer:
            return detail.observerTrainers.map(o => o.id);
        default: return [];
    }
}

export async function loadGroupEventInstancesDetails(group: EventInstanceGroupModel) {
    const api = new EventInstanceApi();
    const loadGroupEventInstances: EventInstance[] = [];

    for (const eventInstanceGroupItemModel of group.eventInstanceGroupItems) {
        const detail = await api.detail(eventInstanceGroupItemModel.eventInstanceId);

        loadGroupEventInstances.push(detail);
    }

    return loadGroupEventInstances;
}

export function loadCurrentMultiDayEventInstancesGroupDetailsByTrainerType(group: EventInstanceGroupModel,
    groupEventInstances: EventInstance[], trainerType: TrainerType): GroupEventInstancesModel[] {

    let currentDayParts: GroupEventInstancesModel[] = [];

    for (const eventInstanceGroupItemModel of group?.eventInstanceGroupItems || []) {
        const detail = groupEventInstances.find(gei => gei.id === eventInstanceGroupItemModel.eventInstanceId);

        const ddrsResult: GroupEventInstancesModel = {
            dayNumber: eventInstanceGroupItemModel.dayNumber,
            trainerIds: getTrainersIdsFromDetail(detail, trainerType),
            trainerIdsAndOtherTrainerTypeCategories:
                trainerType === TrainerType.OtherTrainer && !!detail.observerTrainers?.length
                    ? detail.observerTrainers.map(t => ({ trainerId: t.id, otherTrainerTypeCategory: t.otherTrainerTypeCategory }))
                    : null
        };
        currentDayParts = currentDayParts.concat(ddrsResult);
    }

    return currentDayParts;
}
