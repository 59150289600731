import * as React from "react";
import { Divider, Grid, List } from "semantic-ui-react";
import { TrainerNamesForDay } from "../../standbyTrainersCalendarModel";
import "./StandbyTrainersStyle.scss";

interface Props {
    trainerNamesForDay: TrainerNamesForDay;
}

export const StandbyTrainers = ({ trainerNamesForDay }: Props) => {
    return (
        trainerNamesForDay &&
        <Grid columns={1} className="grid-container">
            <Grid.Row>
                <Grid.Column>
                    <h3>Standby Trainers</h3>
                    <div>{trainerNamesForDay.date.toDate().toDateString()}</div>
                    {trainerNamesForDay.sessionSelected && (<div>{trainerNamesForDay.sessionSelected}</div>)}
                    <Divider className="standby-divider" />
                    {trainerNamesForDay.sessionSelected ? (
                        <List>
                            {
                                (trainerNamesForDay.trainerNames && trainerNamesForDay.trainerNames.length > 0)
                                    ? (trainerNamesForDay.trainerNames.map((name, index) => (
                                        <List.Item key={`${index}-${name}`}>
                                            {name}
                                        </List.Item>
                                    ))) : (
                                        <List.Item>
                                            No Trainers assigned to this session
                                        </List.Item>
                                    )
                            }
                        </List>
                    ) : (
                        <>
                            <div>Session One</div>
                            <List>
                                {
                                    (trainerNamesForDay.sessionOneTrainerNames && trainerNamesForDay.sessionOneTrainerNames.length > 0)
                                        ? (trainerNamesForDay.sessionOneTrainerNames.map((name, index) => (
                                            <List.Item key={`${index}-${name}`}>
                                                {name}
                                            </List.Item>
                                        ))) : (
                                            <List.Item>
                                                No Trainers assigned
                                            </List.Item>
                                        )
                                }
                            </List>
                            <Divider className="standby-divider" />
                            <div>Session Two</div>
                            <List>
                                {
                                    (trainerNamesForDay.sessionTwoTrainerNames && trainerNamesForDay.sessionTwoTrainerNames.length > 0)
                                        ? (trainerNamesForDay.sessionTwoTrainerNames.map((name, index) => (
                                            <List.Item key={`${index}-${name}`}>
                                                {name}
                                            </List.Item>
                                        ))) : (
                                            <List.Item>
                                                No Trainers assigned
                                            </List.Item>
                                        )
                                }
                            </List>
                            <Divider className="standby-divider" />
                            <div>Session Three</div>
                            <List>
                                {
                                    (trainerNamesForDay.sessionThreeTrainerNames && trainerNamesForDay.sessionThreeTrainerNames.length > 0)
                                        ? (trainerNamesForDay.sessionThreeTrainerNames.map((name, index) => (
                                            <List.Item key={`${index}-${name}`}>
                                                {name}
                                            </List.Item>
                                        ))) : (
                                            <List.Item>
                                                No Trainers assigned
                                            </List.Item>
                                        )
                                }
                            </List>
                            <Divider className="standby-divider" />
                            <div>Session Four</div>
                            <List>
                                {
                                    (trainerNamesForDay.sessionFourTrainerNames && trainerNamesForDay.sessionFourTrainerNames.length > 0)
                                        ? (trainerNamesForDay.sessionFourTrainerNames.map((name, index) => (
                                            <List.Item key={`${index}-${name}`}>
                                                {name}
                                            </List.Item>
                                        ))) : (
                                            <List.Item>
                                                No Trainers assigned
                                            </List.Item>
                                        )
                                }
                            </List>
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
