import * as React from "react";
import { EiSideBar } from "../EiSideBar";
import { useDispatch, useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { Grid, Container, Form, TextArea, TextAreaProps, Button } from "semantic-ui-react";
import { EventInstanceBanner } from "../EventInstanceBanner";
import { EventInstanceHealthAndSafetyModel, EventInstanceHealthAndSafetyValidationModel, initialHealthAndSafetyModel } from "../../model";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { TrainerRole, PoliceAndDdrsAdminRole } from "@common/auth/model";
import { saveHealthAndSafety } from "../../actions";
import moment from "moment";

export const HealthAndSafety: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const isTrainer = useSelector(currentUserIsInRoleSelector(TrainerRole));
    const isEventAdmin = useSelector(currentUserIsInRoleSelector(PoliceAndDdrsAdminRole));
    const isTrainerOrEventAdmin = isTrainer || isEventAdmin;
    const isAtLeastEventInstanceStartDate = eventInstance?.startDate?.isBefore(moment().utc());
    const isAllowedToSaveHealthAndSafety = isTrainerOrEventAdmin && isAtLeastEventInstanceStartDate;

    const [healthAndSafetyFormState, setHealthAndSafetyFormState]
        = React.useState<EventInstanceHealthAndSafetyModel>(eventInstance?.healthAndSafety ?? initialHealthAndSafetyModel);

    const [validationShown, setValidationShown] = React.useState<boolean>(false);
    const [healthAndSafetyFormValidationState, setHealthAndSafetyFormValidationState]
        = React.useState<EventInstanceHealthAndSafetyValidationModel>({});

    React.useEffect(() => {
        setHealthAndSafetyFormState(eventInstance?.healthAndSafety ?? initialHealthAndSafetyModel);
    }, [eventInstance?.healthAndSafety]);

    const dispatch = useDispatch();

    const onSaveHealthAndSafety = React.useCallback(() => {
        const dispatchSaveHealthAndSafety = async () => {
            await dispatch(saveHealthAndSafety(eventInstance.id, healthAndSafetyFormState));
        };
        setHealthAndSafetyFormValidationState({});
        setValidationShown(false);
        dispatchSaveHealthAndSafety();
    }, [dispatch, eventInstance?.id, healthAndSafetyFormState]);

    const onUnlockHealthAndSafety = React.useCallback(() => {
        setHealthAndSafetyFormState({ ...healthAndSafetyFormState, unlockedForAmend: true });
    }, [healthAndSafetyFormState]);

    const onDiscardChangesHealthAndSafety = React.useCallback(() => {
        setHealthAndSafetyFormState(eventInstance?.healthAndSafety);
        setHealthAndSafetyFormValidationState({});
        setValidationShown(false);
    }, [eventInstance?.healthAndSafety]);

    const getToggleValue = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel) =>
        healthAndSafetyFormState[name.toString()]?.result, [healthAndSafetyFormState]);

    const getCommentsValue = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel, extendedModel: boolean) =>
        extendedModel ? healthAndSafetyFormState[name.toString()]?.comments : healthAndSafetyFormState[name.toString()], [healthAndSafetyFormState]);

    const validateRow = React.useCallback((row: keyof EventInstanceHealthAndSafetyModel) => {
        let validationStyle = "";
        const toggleValue = getToggleValue(row);

        if (toggleValue) {
            const commentValue = getCommentsValue(row, true);

            if (!commentValue) {
                validationStyle = "row-invalid";
            }
        }

        return validationStyle;
    }, [getToggleValue, getCommentsValue]);

    const validateRows = React.useCallback((rows: (keyof EventInstanceHealthAndSafetyModel)[]) => {
        let newHealthAndSafetyFormValidationState: EventInstanceHealthAndSafetyValidationModel = {};

        rows.forEach(row => {
            const validationStyle = validateRow(row);
            newHealthAndSafetyFormValidationState = { ...newHealthAndSafetyFormValidationState, [row.toString()]: validationStyle };
        });

        setHealthAndSafetyFormValidationState(newHealthAndSafetyFormValidationState);
        setValidationShown(Object.values(newHealthAndSafetyFormValidationState).some(v => v));

        return newHealthAndSafetyFormValidationState;
    }, [validateRow]);

    const onSubmitHealthAndSafety = React.useCallback(() => {
        const dispatchSaveHealthAndSafety = async () => {
            await dispatch(saveHealthAndSafety(eventInstance.id, { ...healthAndSafetyFormState, submittedOn: moment().utc(), unlockedForAmend: false }));
        };

        const newHealthAndSafetyFormValidationState = validateRows(["evacuationPathClear", "roomFreeOfTripSlipHazards", "roomTemperatureSatisfactory",
            "equipmentFunctional", "equipmentHadValidPatTest", "lightningSatisfactory", "furnitureFitForPurposeAndStable"]);

        if (!Object.values(newHealthAndSafetyFormValidationState).some(v => v)) {
            dispatchSaveHealthAndSafety();
        }
    }, [dispatch, eventInstance?.id, healthAndSafetyFormState, validateRows]);

    const onToggleChange = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel) => {
        return () => setHealthAndSafetyFormState(
            { ...healthAndSafetyFormState,
                [name.toString()]:
                    { ...healthAndSafetyFormState[name.toString()],
                        result: !getToggleValue(name)
                    }
            });
    }, [healthAndSafetyFormState, getToggleValue]);

    const onCommentsChange = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel, extendedModel) => {
        return (_: any, d: TextAreaProps) => extendedModel
            ? setHealthAndSafetyFormState(
                { ...healthAndSafetyFormState,
                    [name.toString()]:
                        { ...healthAndSafetyFormState[name.toString()],
                            comments: d.value.toString()
                        }
                })
            : setHealthAndSafetyFormState(
                { ...healthAndSafetyFormState,
                    [name.toString()]: d.value.toString()
                });
    }, [healthAndSafetyFormState]);

    const getCommentsValidation = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel) =>
        healthAndSafetyFormValidationState[name.toString()], [healthAndSafetyFormValidationState]);

    const toggleAndCommentsRow = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel, label: string) => {
        const toggleValue = getToggleValue(name);
        const commentsValue = getCommentsValue(name, true);
        const commentsValidation = getCommentsValidation(name);

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <h5>{label}</h5>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Checkbox
                        toggle
                        label={toggleValue ? "Fail" : "Pass"}
                        checked={toggleValue}
                        onChange={onToggleChange(name)}
                        disabled={!isAllowedToSaveHealthAndSafety || (!!healthAndSafetyFormState.submittedOn && !healthAndSafetyFormState.unlockedForAmend)}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form>
                        <TextArea
                            placeholder={"Enter comments"}
                            rows={3}
                            onChange={onCommentsChange(name, true)}
                            value={commentsValue}
                            disabled={!isAllowedToSaveHealthAndSafety || (!!healthAndSafetyFormState.submittedOn && !healthAndSafetyFormState.unlockedForAmend)}
                            className={commentsValidation}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    }, [
        getCommentsValue,
        onCommentsChange,
        onToggleChange,
        getToggleValue,
        getCommentsValidation,
        isAllowedToSaveHealthAndSafety,
        healthAndSafetyFormState?.submittedOn,
        healthAndSafetyFormState?.unlockedForAmend
    ]);

    const commentsRow = React.useCallback((name: keyof EventInstanceHealthAndSafetyModel, label: string) => {
        const commentsValue = getCommentsValue(name, false);

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <h5>{label}</h5>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form>
                        <TextArea
                            placeholder={"Enter comments"}
                            rows={3}
                            onChange={onCommentsChange(name, false)}
                            value={commentsValue}
                            disabled={!isAllowedToSaveHealthAndSafety || (!!healthAndSafetyFormState.submittedOn && !healthAndSafetyFormState.unlockedForAmend)}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    }, [getCommentsValue, onCommentsChange, isAllowedToSaveHealthAndSafety, healthAndSafetyFormState?.submittedOn, healthAndSafetyFormState?.unlockedForAmend]);

    const content = () => {
        return (
            <Container>
                <Grid className="event-instance-details">
                    <h1 className="event-instance-title">Health and Safety</h1>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EventInstanceBanner eventInstance={eventInstance} />
                        </Grid.Column>
                    </Grid.Row>
                    {isAllowedToSaveHealthAndSafety && !healthAndSafetyFormState.submittedOn && (<h3>Please complete the below form</h3>)}
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <h3>Standards</h3>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <h3>Result</h3>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <h3>Comments</h3>
                        </Grid.Column>
                    </Grid.Row>
                    {toggleAndCommentsRow("evacuationPathClear", "Is the evacuation path clear?")}
                    {toggleAndCommentsRow("roomFreeOfTripSlipHazards", "Is the room free of trip/slip hazards?")}
                    {toggleAndCommentsRow("roomTemperatureSatisfactory", "Is the room temperature satisfactory?")}
                    {toggleAndCommentsRow("equipmentFunctional", "Is the equipment functional?")}
                    {toggleAndCommentsRow("equipmentHadValidPatTest", "Has the equipment had a valid PAT test?")}
                    {toggleAndCommentsRow("lightningSatisfactory", "Is the lighting satisfactory?")}
                    {toggleAndCommentsRow("furnitureFitForPurposeAndStable", "Is the furniture fit for purpose and stable?")}
                    {commentsRow("anyOtherComments", "Any other comments")}
                    {isAllowedToSaveHealthAndSafety && (
                        <Grid.Row>
                            <Grid.Column>
                                {!healthAndSafetyFormState.submittedOn && (<Button onClick={onSaveHealthAndSafety} floated={"left"}>Save</Button>)}
                                {!!healthAndSafetyFormState.submittedOn && (
                                    healthAndSafetyFormState.unlockedForAmend
                                        ? (<Button floated={"left"} icon="close" color="red" onClick={onDiscardChangesHealthAndSafety}>Discard Changes</Button>)
                                        : (<Button floated={"left"} onClick={onUnlockHealthAndSafety}>Update</Button>)
                                )}
                                <Button
                                    onClick={onSubmitHealthAndSafety}
                                    disabled={!!healthAndSafetyFormState.submittedOn && !healthAndSafetyFormState.unlockedForAmend}
                                    floated={"right"}
                                >
                                    Submit
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {isTrainerOrEventAdmin && !isAtLeastEventInstanceStartDate && (
                        <Grid.Row>
                            <h5 className="red-warning-text">The form can be edited starting from course date</h5>
                        </Grid.Row>
                    )}
                    <h5></h5>
                    {validationShown && (
                        <Grid.Row>
                            <h5 className="red-warning-text">In order to submit the form, please complete the actions in red</h5>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        {!!healthAndSafetyFormState.submittedOn && !healthAndSafetyFormState.unlockedForAmend && (
                            <h5>The form has been submitted on {healthAndSafetyFormState.submittedOn.format("DD/MM/YYYY")}</h5>
                        )}
                    </Grid.Row>
                    {!!healthAndSafetyFormState.submittedOn && isAllowedToSaveHealthAndSafety && (
                        <Grid.Row>
                            {healthAndSafetyFormState.unlockedForAmend
                                ? (<h5>Please resubmit the form to save your updates</h5>)
                                : (<h5>Please click update if you wish to amend the form</h5>)}
                        </Grid.Row>
                    )}
                </Grid>
            </Container>);
    };

    return (<EiSideBar children={content()} />);
};
