import { loadTrainerAvailabilityById, loadVenuesInAvailabilityRange } from "@common/availabilityTrainer/actions";
import { loadTrainerAttributeDefinitions } from "../trainerAttributeDefinition";
import { loadTrainerDetail } from "./actions";
import { AppState } from "./model";
import { trainerSelector } from "./selectors";
import { loadVenues } from "../venue";
import { routes as trainerInvoices } from "@common/trainerInvoices";
import { loadEventTypes } from "../eventType/actions";
import { loadOrganisations } from "../organisation/actions";
import { routes as trainerAttributeDefinitions } from "@common/crud/trainerAttributeDefinition";
import { loadTrainerAttributeDefinitionsWithSearchOptions } from "../trainerAttributeDefinition/actions";

export const TrainerRoutes = {
    TrainerDetails: "/:trainerId",
    Edit: "/edit",
    Trainers: "/trainers",
    Create: "/create",
    MonitoringPlanner: "/monitoring-planner",
    DdrsMonitoringPlanner: "/ddrs-monitoring-planner",
    MonitoringLibrary: "/monitoring-library",
    Availability: "/availability",
    WorkingRadius: "/workingRadius",
    Schedule: "/schedule",
    DdrsSchedule: "/schedule-drink-drive",
    CorpSchedule: "/schedule-corp",
    ConstructionSchedule: "/schedule-construction",
    History: "/history",
    Management: "/management",
    Areas: "/areas",
    CompanyInfo: "/companyInformation",
    Attributes: "/attributes",
    TrainerInvoices: "/invoices",
    TrainerInvoicing: "/invoicing",
    TrainerCpd: "/cpd",
    TrainerAttributeDefinitions: "/trainerAttributeDefinitions",
};

export const detailRoute = () => ({
    [TrainerRoutes.TrainerDetails]: {
        title: (state: AppState) => trainerSelector(state).fullName,
        resolve: [loadTrainerDetail, loadOrganisations],
        [TrainerRoutes.Edit]: {
            title: "Edit",
            resolve: () => loadTrainerAttributeDefinitionsWithSearchOptions({ includeCategories: true })
        },
        [TrainerRoutes.Availability]: {
            title: "Availability",
            resolve: loadTrainerAvailabilityById,
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        },
        [TrainerRoutes.WorkingRadius]: {
            title: "Working Radius",
            resolve: loadVenuesInAvailabilityRange,
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        },
        [TrainerRoutes.Schedule]: {
            title: "Schedule",
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        },
        [TrainerRoutes.DdrsSchedule]: {
            title: "DDRS"
        },
        [TrainerRoutes.CorpSchedule]: {
            title: "Commercial"
        },
        [TrainerRoutes.ConstructionSchedule]: {
            title: "Construction"
        },
        [TrainerRoutes.History]: {
            title: "History",
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        },
        [TrainerRoutes.Areas]: {
            title: "Areas",
            [TrainerRoutes.Edit]: {
                title: "Edit",
                resolve: () => loadTrainerAttributeDefinitionsWithSearchOptions({ includeCategories: true })
            }
        },
        [TrainerRoutes.Management]: {
            title: "Management",
            [TrainerRoutes.Edit]: {
                title: "Edit",
                resolve: () => loadTrainerAttributeDefinitionsWithSearchOptions({ includeCategories: true })
            }
        },
        [TrainerRoutes.CompanyInfo]: {
            title: "Company Information",
            [TrainerRoutes.Edit]: {
                title: "Edit",
                resolve: () => loadTrainerAttributeDefinitionsWithSearchOptions({ includeCategories: true })
            }
        },
        [TrainerRoutes.Attributes]: {
            title: "Attributes",
            resolve: () => loadTrainerAttributeDefinitionsWithSearchOptions({ includeCategories: true }),
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        },
        [TrainerRoutes.TrainerInvoices]: {
            title: "Invoices",
            ...trainerInvoices
        },
        [TrainerRoutes.TrainerCpd]: {
            title: "CPD",
            [TrainerRoutes.Edit]: {
                title: "Edit"
            }
        }
    }
});

export const monitoringRoute = () => ({
    [TrainerRoutes.MonitoringPlanner]: {
        title: "NDORS Monitoring Planner",
        resolve: [loadOrganisations]
    },
    [TrainerRoutes.DdrsMonitoringPlanner]: {
        title: "DDRS Monitoring Planner",
    },
    [TrainerRoutes.MonitoringLibrary]: {
        title: "Monitoring Library"
    }
});

export const trainerInvoicingRoute = () => ({
    [TrainerRoutes.TrainerInvoicing]: {
        title: "Invoicing"
    }
});

export const createTrainerRouteObject ={
    title: "Create Trainer",
    resolve: [loadTrainerAttributeDefinitions, loadOrganisations]
};

export const routes = {
    [TrainerRoutes.Trainers]: {
        resolve: [loadEventTypes, loadOrganisations, loadVenues, loadTrainerAttributeDefinitions],
        title: "Trainers",
        ignoreParentResolve: true,
        ...detailRoute(),
        ...monitoringRoute(),
        ...trainerInvoicingRoute(),
        ...trainerAttributeDefinitions,

        [TrainerRoutes.Create]: createTrainerRouteObject
    }
};
