import * as React from "react";
import { Modal } from "@common/components";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Input, Button, Grid, Table } from "semantic-ui-react";
import { AssignTrainersRow } from "./AssignTrainersRow";
import { MonitorTrainerAvailabilityForDay, RegionalCoordinatorCalendarDay } from "./model";
import { RegionalCoordinatorApi } from "./RegionalCoordinatorApi";
import moment from "moment";
import { Spinner } from "@common/global";

interface AssignRegionalCoordinatorsModalProps {
    date: moment.Moment;
    open: boolean;
    closeModal: () => void;
    currentAssignments: RegionalCoordinatorCalendarDay[];
    updateCurrentAssignments: (update: RegionalCoordinatorCalendarDay[]) => void;
    stopEditing: () => void;
    setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
    allAvailableTrainers: MonitorTrainerAvailabilityForDay[];
    changes: RegionalCoordinatorCalendarDay[];
    setChanges: React.Dispatch<React.SetStateAction<RegionalCoordinatorCalendarDay[]>>;
}

export const AssignRegionalCoordinatorsModal: React.FC<AssignRegionalCoordinatorsModalProps> =
({ date, open, currentAssignments, closeModal, updateCurrentAssignments, stopEditing, setDate, allAvailableTrainers, changes, setChanges }) => {
    const [nameSearch, setNameSearch] = React.useState("");
    const arrangementsForDay = currentAssignments?.find(d => d.date.date() === date.date());
    const [coverIds, setCoverIds] = React.useState(arrangementsForDay?.coverTrainerIds ?? []);
    const [monitorIds, setMonitorIds] = React.useState(arrangementsForDay?.monitorTrainerIds ?? []);
    const [loading, setLoading] = React.useState(false);
    const [bookedRcCoverTrainers, setBookedRcCoverTrainers] = React.useState<string[]>(null);

    React.useEffect(() => {
        const getRcCoverTrainer = async () => {
            setBookedRcCoverTrainers(await new RegionalCoordinatorApi().getRcCoverTrainersBookedForEventInstance(date, coverIds));
        };

        if (open && coverIds.length) {
            getRcCoverTrainer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[coverIds, open]);

    const dateString = date?.toISOString();

    React.useEffect(() => {
        async function initDay() {
            const currentArrangementsForDay = currentAssignments?.find(d => d.date.date() === date.date());
            const newArrangementsForDay = changes?.find(d => d.date.date() === date.date());
            setCoverIds(newArrangementsForDay?.coverTrainerIds ?? currentArrangementsForDay?.coverTrainerIds ?? []);
            setMonitorIds(newArrangementsForDay?.monitorTrainerIds ?? currentArrangementsForDay?.monitorTrainerIds ?? []);
        }
        initDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAssignments, dateString, changes]);

    function handleSearchChange(_: any, { value }: { value: string }) {
        setNameSearch(value);
    }

    function onPreviousDayClicked() {
        addChangesForDate();
        setDate(date.clone().subtract(1, "day"));
    }

    function onNextDayClicked() {
        addChangesForDate();
        setDate(date.clone().add(1, "day"));
    }

    const canGoBack = date.date() > 1;
    const canGoForward = date.date() < date.daysInMonth();
    const IdsAddedToCurrentDay = !coverIds.every(id => arrangementsForDay?.coverTrainerIds?.includes(id)) ||
        !monitorIds.every(id => arrangementsForDay?.monitorTrainerIds?.includes(id));
    const IdsRemovedFromCurrentDay = arrangementsForDay?.coverTrainerIds.some(x => !coverIds.includes(x)) ||
        arrangementsForDay?.monitorTrainerIds.some(x => !monitorIds.includes(x));

    function addChangesForDate() {
        // If something has changed we need to add it to the list of changes.
        if (IdsAddedToCurrentDay || IdsRemovedFromCurrentDay) {
            const newChange: RegionalCoordinatorCalendarDay =
            { monitorTrainerIds: monitorIds, coverTrainerIds: coverIds, monitorSessionsOnDay: -1, date, monitoredEventsOnDay: [] };
            const indexOfCurrentDate = changes.map(x => x.date).indexOf(date);

            if (indexOfCurrentDate !== -1) {
                setChanges(changes.map((x, index) => index === indexOfCurrentDate? newChange : x ));
            }
            else
            {
                setChanges([...changes, newChange]);
            }
        }
    }

    async function saveChanges() {
        setLoading(true);
        let allChanges = changes;
        if (IdsAddedToCurrentDay || IdsRemovedFromCurrentDay) {
            const newChange: RegionalCoordinatorCalendarDay = {
                monitorTrainerIds: monitorIds,
                coverTrainerIds: coverIds,
                monitorSessionsOnDay: -1,
                date,
                monitoredEventsOnDay: []
            };
            const indexOfCurrentDate = changes.map(x => x.date).indexOf(date);
            if (indexOfCurrentDate !== -1) {
                allChanges = changes.map((x, index) => index === indexOfCurrentDate? newChange : x );
            }
            else
            {
                allChanges = ([...allChanges, newChange]);
            }
        }
        if (allChanges.length < 1) {
            setLoading(false);
            closeModal();
            return;
        }
        const api = new RegionalCoordinatorApi();
        const result = await(api.saveChangesToCalendar(date, allChanges));
        updateCurrentAssignments(result);
        setLoading(false);
        stopEditing();
        closeModal();
    }

    const sortByBookedAndThenAvailable = (a: MonitorTrainerAvailabilityForDay, b: MonitorTrainerAvailabilityForDay) => {
        if (a.isBooked !== b.isBooked) {
            return a.isBooked ? 1 : -1;
        }

        if (a.available !== b.available) {
            return a.available ? -1 : 1;
        }

        return 0;
    };

    const availableTrainers = allAvailableTrainers?.length > 0 ? allAvailableTrainers : [];

    const trainers = ((nameSearch !== "" && nameSearch !== null) ?
        availableTrainers.filter(t => t.fullName.toLowerCase().includes(nameSearch.trim().toLowerCase()))
        : availableTrainers).sort((a, b) => sortByBookedAndThenAvailable(a, b));

    return (
        <Modal open={open} size={"large"}>
            <Modal.Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>Select trainers</Grid.Column>
                        <Grid.Column width={7}>{date.format(DateFormat.Long)}</Grid.Column>
                        <Grid.Column width={3}><Button disabled={!canGoBack || loading} onClick={onPreviousDayClicked}>Previous Day</Button></Grid.Column>
                        <Grid.Column width={2}><Button disabled={!canGoForward || loading} onClick={onNextDayClicked}>Next Day</Button></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Header>
            <Modal.Actions>
                <Input placeholder="Enter a trainer name" className="floated-left" value={nameSearch} onChange={handleSearchChange} />
                <Button disabled={loading} content="Close" onClick={closeModal} className="cancel-action" />
                <Button disabled={loading} loading={loading} content="Continue" onClick={saveChanges} />
            </Modal.Actions>
            <Modal.Content>
                <Spinner active={loading}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Trainer</Table.HeaderCell>
                                <Table.HeaderCell>no. courses in same month</Table.HeaderCell>
                                <Table.HeaderCell>no. days in rolling 12 months</Table.HeaderCell>
                                <Table.HeaderCell>Available</Table.HeaderCell>
                                <Table.HeaderCell>Current status</Table.HeaderCell>
                                <Table.HeaderCell>RC Cover</Table.HeaderCell>
                                <Table.HeaderCell>RC Monitor</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <AssignTrainersRow
                                trainers={trainers}
                                monitorIds={monitorIds}
                                setMonitorIds={setMonitorIds}
                                coverIds={coverIds}
                                setCoverIds={setCoverIds}
                                bookedRcCoverTrainers={bookedRcCoverTrainers}
                            />
                        </Table.Body>
                    </Table>
                </Spinner>
            </Modal.Content>
        </Modal>
    );
};
