import { appSettingsSelector } from "@common/appSettings/selectors";
import { CurrentUser, TrainerRole } from "@common/auth/model";
import { ModuleTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventTrainer, HandbackReason, HandbackReasonEnum, PracticalEventTrainer, SUBCONTRACTING_WINDOW_IN_DAYS } from "../../model";
import { basePathSelector } from "../../selectors";
import moment from "moment";
import { EventInstanceApi } from "../../eventInstanceApi";
import { toast } from "@common/toasts";
import { push } from "redux-little-router";
import { setEventInstanceAvailabilityForOtherTrainersByTrainer } from "../../actions";
import { Button, Dropdown, Form, Modal, Popup, TextArea, TextAreaProps } from "semantic-ui-react";
import { Authorize } from "reauthorize";
import { DropdownProps } from "react-day-picker";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

interface Props {
    moduleType: ModuleTypeEnum;
    eventInstanceId: string;
    startDate: moment.Moment;
    trainers?: EventTrainer[];
    practicalTrainers?: PracticalEventTrainer[];
    monitorTrainers?: EventTrainer[];
    currentUser: CurrentUser;
}

const dropdownOptions = [
    {
        key: HandbackReasonEnum.Illness,
        value: HandbackReasonEnum.Illness,
        text: HandbackReason[HandbackReasonEnum.Illness]
    },
    {
        key: HandbackReasonEnum.OtherWorkCommitments,
        value: HandbackReasonEnum.OtherWorkCommitments,
        text: HandbackReason[HandbackReasonEnum.OtherWorkCommitments]
    },
    {
        key: HandbackReasonEnum.Appointment,
        value: HandbackReasonEnum.Appointment,
        text: HandbackReason[HandbackReasonEnum.Appointment]
    },
    {
        key: HandbackReasonEnum.Other,
        value: HandbackReasonEnum.Other,
        text: HandbackReason[HandbackReasonEnum.Other]
    }
];

export const ModuleAvailabilityModalForTrainer = (props: Props) => {
    const { moduleType, eventInstanceId, startDate, trainers, practicalTrainers, monitorTrainers, currentUser } = props;

    const [open, setOpen] = React.useState(false);
    const [handbackReason, setHandbackReason] = React.useState(HandbackReasonEnum.None);
    const [handbackReasonOther, setHandbackReasonOther] = React.useState<string>(null);
    const [showTextField, setShowTextField] = React.useState(false);

    const dispatch = useDispatch();
    const config = useSelector(appSettingsSelector);
    const basePath = useSelector(basePathSelector);

    const openButtonEnabled = moment().utc().isBefore(startDate.clone().add(-10, "days"));
    const theory = moduleType === ModuleTypeEnum.Theory;
    const trainer = getTrainer();
    const availableForOtherTrainers = trainer?.availableForOtherTrainers;
    const moduleTypeText = theory ? "Theory" : "Practical";
    const businessLineType = useSelector(businessLineTypeSelector);
    const allowSubcontracting = React.useMemo(() => {
        switch (businessLineType) {
            case BusinessLineType.PoliceAndCourt: return config.featureSettings.policeAndCourtSubcontracting;
            case BusinessLineType.Corporate: return config.featureSettings.commercialSubcontracting;
            case BusinessLineType.Construction: return config.featureSettings.constructionSubcontracting;
            default: return false;
        }
    }, [
        businessLineType,
        config.featureSettings.policeAndCourtSubcontracting,
        config.featureSettings.commercialSubcontracting,
        config.featureSettings.constructionSubcontracting]);

    React.useEffect(() => {
        if (availableForOtherTrainers) {
            resetHandbackReasons();
            setShowTextField(false);
        }
    }, [availableForOtherTrainers]);

    function getTrainer() {
        if (monitorTrainers?.find(t => t.id === currentUser.id)) {
            return undefined;
        }

        return theory ?
            trainers?.find(t => t.id === currentUser.id) :
            practicalTrainers?.find(t => t.id === currentUser.id);
    }

    async function onContinueClick() {
        if (availableForOtherTrainers) {
            const trainersCoursesOnStartDate = (await new EventInstanceApi().getAll({
                fromDate: startDate,
                toDate: startDate,
                trainerId: currentUser.id,
                workflowTypes: [WorkflowTypeEnum.Any]
            })).eventInstances;

            const trainerStillAllocatedToThisCourse = trainersCoursesOnStartDate.some(
                ei => ei.id === eventInstanceId && (theory ?
                    ei.trainerIds.includes(currentUser.id) :
                    ei.practicalTrainersIds.includes(currentUser.id)));

            if (!trainerStillAllocatedToThisCourse) {
                toast.error("Handback cannot be cancelled as you are no longer allocated to the course");
                dispatch(push(basePath));
                return;
            }
        }

        dispatch(setEventInstanceAvailabilityForOtherTrainersByTrainer(
            eventInstanceId, trainer.id, !availableForOtherTrainers, moduleType, handbackReason, handbackReasonOther));
        closeModal();
    }

    function openModal() {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
        resetHandbackReasons();
        setShowTextField(false);
    }

    function resetHandbackReasons() {
        setHandbackReason(HandbackReasonEnum.None);
        setHandbackReasonOther(null);
    }

    function getModalContentText() {
        let contentText = "";

        if (availableForOtherTrainers) {
            contentText = "Are you sure you wish to cancel this course handback?";
        } else {
            contentText = "Are you sure you wish to hand this course back? Warning - this action cannot be undone." +
                "\n\nNote - Alaska will attempt to swap this course to another trainer.";
            if (allowSubcontracting) {
                contentText += `\n\nIf the swap is completed within ${SUBCONTRACTING_WINDOW_IN_DAYS} days of the ` +
                    "course, the subcontracting process will be initiated.";
            }
            contentText += "\n\nPlease select the reason you are handing this course back.\n";
        }

        return contentText;
    }

    function onDropdownChange(_: any, { value }: DropdownProps) {
        setHandbackReason(value as HandbackReasonEnum);
        if (value === HandbackReasonEnum.Other) {
            setHandbackReasonOther("");
            setShowTextField(true);
        } else {
            setHandbackReasonOther(null);
            setShowTextField(false);
        }
    }

    function onTextAreaInput(_: any, { value }: TextAreaProps) {
        setHandbackReasonOther((value as string).trim());
    }

    if (!trainer) {
        return <></>;
    }

    const enabledHandbackButtonClassName = availableForOtherTrainers ? "undo-action" :"irregular-positive";
    const handBackbuttonClassName = openButtonEnabled ? enabledHandbackButtonClassName : "grey";

    return (
        <>
            <Popup
                disabled={openButtonEnabled}
                content="Handback option not possible as too close to course date. Please contact the Planning Team."
                on="hover"
                pinned
                trigger={
                    <span>
                        <Button
                            className={handBackbuttonClassName}
                            disabled={!openButtonEnabled}
                            onClick={openModal}
                        >
                            {availableForOtherTrainers ? `Cancel ${moduleTypeText} Handback` : `Handback ${moduleTypeText}`}
                        </Button>
                    </span>
                }
            />
            <Modal open={open}>
                <Modal.Header>{`${availableForOtherTrainers ? "Cancel " : ""}${moduleTypeText} Handback`}</Modal.Header>
                <Modal.Content className="multi-line">
                    {getModalContentText()}
                    {!availableForOtherTrainers &&
                        <>
                            <Dropdown selection fluid options={dropdownOptions} onChange={onDropdownChange} className="margin-top-bottom" />
                            {showTextField && <Form><TextArea placeholder="Please confirm the details" onInput={onTextAreaInput} /></Form>}
                        </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} className="cancel-action">
                        Cancel
                    </Button>
                    <Authorize authorize={[TrainerRole]}>
                        <Button
                            onClick={onContinueClick}
                            positive
                            disabled={!availableForOtherTrainers && (!handbackReason || handbackReason === HandbackReasonEnum.Other && !handbackReasonOther)}
                            labelPosition="right"
                            icon="checkmark"
                            content={!availableForOtherTrainers ? "Complete handback" : "Continue"}
                        />
                    </Authorize>
                </Modal.Actions>
            </Modal>
        </>
    );
};
