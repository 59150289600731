import { Dropdown } from "@neworbit/simpleui-input/dist/dropdown/dropdown";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, DropdownItemProps } from "semantic-ui-react";
import { setTrainerCarTypeForEvent } from "../../actions";
import { EventInstanceApi } from "../../eventInstanceApi";

interface CarTypeEditProps {
    trainerId: string;
    eventInstanceId: string;
    carType: string;
    isTrainerSubcontracted: boolean;
};

export const CarTypeEdit: React.FC<CarTypeEditProps> = ({ trainerId, eventInstanceId, carType, isTrainerSubcontracted }) => {

    const [editing, setEditing] = React.useState(false);
    const [dropDownOptions, setDropDownOptions] = React.useState<DropdownItemProps[]>([]);
    const [selectedCarType, setSelectedCarType] = React.useState(carType);
    const [saving, setSaving] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => setSelectedCarType(carType), [carType]);

    React.useEffect(() => {
        async function loadAvailableCarTypes() {
            const manual = { value: "Manual", text: "Manual" };
            const automatic = { value: "Automatic", text: "Automatic" };
            const eventInstanceApi = new EventInstanceApi();
            const result = await eventInstanceApi.getTrainerCarTypesForEvent(eventInstanceId, trainerId);
            let options: DropdownItemProps[] = [];
            if (result.manual) {
                options = [manual];
            }

            if (result.automatic) {
                options = [...options, automatic];
            }

            setDropDownOptions(options);
        }
        if (trainerId && eventInstanceId) {
            loadAvailableCarTypes();
        }
    }, [eventInstanceId, trainerId]);

    function toggleEdit() {
        setEditing(!editing);
    };

    function getEditButtonIcon(isInEditMode: boolean) {
        return isInEditMode ? "check" : "pencil";
    };

    function onCarTypeChange(value: string) {
        setSelectedCarType(value);
    }

    function cancelEdit() {
        setSelectedCarType(carType);
        toggleEdit();
    }

    async function saveChange() {
        setSaving(true);
        await dispatch(setTrainerCarTypeForEvent(eventInstanceId, trainerId, selectedCarType));
        setSaving(false);
        toggleEdit();
    }

    if (carType !== "Manual" && carType !== "Automatic") {
        return null;
    }

    return (
        <tr>
            <th>Car Type:</th>
            <td>
                { editing ? (<Dropdown value={selectedCarType} options={dropDownOptions} onChange={onCarTypeChange} />) : (<><span>{carType}</span></>) }
                <Button
                    icon={getEditButtonIcon(editing)}
                    size="mini"
                    onClick={editing ? saveChange : toggleEdit}
                    disabled={saving || isTrainerSubcontracted}
                    loading={saving}
                />
                { editing && <Button icon="delete" color="red" disabled={saving} size="mini" onClick={cancelEdit} /> }
            </td>
        </tr>);
};
