import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { AutomaticPlacesIndicator } from "./AutomaticPlacesIndicator";
import { useDispatch, useSelector } from "react-redux";
import { reserveMultiPartSeats } from "../../seat/actions";
import { ApplicationState } from "@booking/applicationState";
import { GroupEventInstanceProps } from "@booking/landing/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { MultiPartSeatReservation } from "@booking/seat/model";
import { EventInstanceGroupItemModel } from "@common/crud/eventInstanceGroup/model";

export const GroupEventInstanceMobileComponent: React.FC<GroupEventInstanceProps> = ({ groupSearchResultRow, path, additionalMargin, firstNewElement }) => {
    const [t] = useTranslation("EventInstanceMobile");
    const dispatch = useDispatch();
    const dayParts = groupSearchResultRow.dayParts && groupSearchResultRow.dayParts;
    const includeFullyBookedCourses = useSelector((state: ApplicationState) => state.includeFullyBookedCourses);

    const { priceInPence } = groupSearchResultRow;
    const onReservationRequest = React.useCallback(() => {

        const multiPartRequests = (dayParts.map((dp, index) => {
            return { eventInstanceId: dp.eventInstanceId, includeFullyBookedCourses, dayPart: index+1 };
        }));

        const reservationRequest: MultiPartSeatReservation = { seatReservations: multiPartRequests, groupId: groupSearchResultRow.groupId };
        dispatch(reserveMultiPartSeats(reservationRequest, path));
    }, [dayParts, path, includeFullyBookedCourses, dispatch]);

    const startTimeFormatted = (dayPart: EventInstanceGroupItemModel) => {
        return dayPart?.startTime.format(DateFormat.Time, { trim: false });
    };
    const endTimeFormatted = (dayPart: EventInstanceGroupItemModel) => {

        const startTime = dayPart?.startTime.clone();
        return startTime.add(dayPart?.eventDuration).format(DateFormat.Time, { trim: false });
    };

    const renderDayParts = () => {
        return (<>
            {dayParts.map((dp) =>  (
                <React.Fragment key={dp.dayNumber}>
                    <Grid.Row>
                        {dateString(dp.deliveryDateTime, DateFormat.MidDayOnly)}
                    </Grid.Row>
                    <Grid.Row className="mobile-ddrs-date-time">
                        <div className="mobile-ddrs-date">{dateString(dp.deliveryDateTime, DateFormat.ShortWithSpace)}</div>
                        <div>{startTimeFormatted(dp)} - {endTimeFormatted(dp)}</div>
                    </Grid.Row>
                </React.Fragment>))}
        </>
        );
    };

    let className = "event-instance-row cursor-pointer no-margin";
    if (additionalMargin) {
        className += "event-instance-table-additional-margin";
    }

    return (
        <Grid
            mobile={16}
            ref={firstNewElement}
            key={groupSearchResultRow.id}
            id={additionalMargin ? "scroll-to-this" : undefined}
            onClick={onReservationRequest}
            className={className}
        >
            <Grid.Column mobile={16}>
                {groupSearchResultRow.venueName && (<Grid.Row>{groupSearchResultRow.venueName}</Grid.Row>)}
                {groupSearchResultRow.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
                        <Grid.Row>{groupSearchResultRow.distanceInMiles} {t("MILES")}</Grid.Row>
                }
            </Grid.Column>
            <Grid.Row>
                <Grid.Column mobile={13}>
                    {renderDayParts()}
                    <AutomaticPlacesIndicator automaticSeatsData={groupSearchResultRow.automaticSeatsData} />
                </Grid.Column>
                <Grid.Column mobile={2} className="mobile-book-button ddrs-mobile">
                    <Icon color="black" size="large" name={"angle right"} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Column mobile={16}>
                <Grid.Row> {t("COURSE_LANGUAGE")} {t(`Languages.${groupSearchResultRow.language}`)}
                    <WelshLanguageIndicator language={groupSearchResultRow.language} />
                </Grid.Row>
                {groupSearchResultRow.oneToOne &&
                        <Grid.Row>
                            <img className="menu-icon" src={"/assets/one-to-one.png"} />
                        </Grid.Row>}
                <Grid.Row width={4} className="cost">
                £{priceInPence && `${(priceInPence / 100).toFixed(2)}`}
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
