import * as React from "react";
import { ClaritySettings as ClaritySettingsDictionary } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const ClaritySettings: ConfigurationAreaComponent<ClaritySettingsDictionary> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Clarity"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "CorporateBookingProjectId", label: "Commercial Booking Project ID" }
            ]}
        />
    );
};
