import * as React from "react";
import { TrainerInvoiceApi } from "../trainerInvoiceApi";
import { push, State as RouterState } from "redux-little-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InvoicePreviewComponent } from "./invoicePreviewComponent";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";

export const InvoicePreview: React.FC = () => {
    const dispatch = useDispatch();

    const trainerId = useSelector<RouterState>(state => state.router.params.trainerId) as string;
    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);

    const [submitLoading, setSubmitLoading]=React.useState<boolean>(false);

    const submitInvoice = React.useCallback(async () => {
        setSubmitLoading(true);
        const api = new TrainerInvoiceApi(trainerId);
        const newInvoiceId = await api.submitInvoice();
        setSubmitLoading(false);
        toast.success("Invoice successfully submitted");
        dispatch(push(`${businessLineTypePath}/trainers/${trainerId}/invoices/${newInvoiceId}`));
    }, [businessLineTypePath, dispatch, trainerId]);

    return (
        <>
            <InvoicePreviewComponent
                submitInvoice={submitInvoice}
                trainerId={trainerId}
                submitLoading={submitLoading}
            />
        </>
    );
};

