import * as React from "react";
import { Table } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { EventInstanceRow } from "./EventInstanceRow";
import { pathSelector } from "../selectors";
import { CourseSearchResultRow, GroupCourseSearchResultRow, useScrollToNewElementRef } from "../model";
import { GroupEventInstanceRow } from "@booking/landing/components/GroupEventInstanceRow";

interface EventInstanceListProps{
    eventInstances: CourseSearchResultRow[];
    eventInstanceGroups: GroupCourseSearchResultRow[];
    pageSize: number;
    isMobile: boolean;
}

export const EventInstanceList: React.FC<EventInstanceListProps>  =  ({
    eventInstances,
    eventInstanceGroups,
    pageSize,
    isMobile
}) => {
    const path = useSelector(pathSelector);
    const firstNewElement = useScrollToNewElementRef([eventInstances?.length]);

    const indexWithAdditionalMargin = eventInstances.length - pageSize;

    const isDdrs = eventInstanceGroups?.length > 0;

    return (
        <Table style={{ tableLayout: "fixed" }}>
            <Table.Header />
            <Table.Body>
                {!isDdrs && eventInstances.map((eventInstance, index) => {
                    return (
                        <EventInstanceRow
                            eventInstance={eventInstance}
                            key={eventInstance.id}
                            path={path}
                            additionalMargin={index !== 0 && index === indexWithAdditionalMargin}
                            firstNewElement={index !== 0 && index === indexWithAdditionalMargin ? firstNewElement : undefined}
                            isMobile={isMobile}
                        />
                    );
                }
                )}
                {isDdrs && eventInstanceGroups.map((groupEventInstance, index) =>
                    (<GroupEventInstanceRow
                        groupSearchResultRow={groupEventInstance}
                        key={groupEventInstance.id}
                        path={path}
                        additionalMargin={index !== 0 && index === indexWithAdditionalMargin}
                        firstNewElement={index !== 0 && index === indexWithAdditionalMargin ? firstNewElement : undefined}
                    />)
                )}
            </Table.Body>
        </Table>
    );
};
