import * as React from "react";
import { useSelector } from "react-redux";
import { adminAppEndpointSelector, isUserClientAdvisor } from "@common/crud/common/selectors";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { ApplicationState } from "../applicationState";
import { bookingBasePathSelector } from "../landing/selectors";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { View } from "./ViewEnum";
import { useTranslation } from "react-i18next";
import { Link } from "redux-little-router";
import { Button } from "semantic-ui-react";
import { BookingApi } from "@booking/bookings/bookingApi";
import { SaveBookingDetailsModal, SaveBookingDetailsModalRequired } from "@booking/bookings/components/SaveBookingDetailsModal";

export interface ClientAdvisorHeaderProps {
    message?: string;
    showDetailedInfo: boolean;
    currentView: View;
    noMarginOnLinkButton?: boolean;
}

export const ClientAdvisorHeader: React.FunctionComponent<ClientAdvisorHeaderProps> = ({ message, showDetailedInfo, currentView, noMarginOnLinkButton }) => {
    const [t] = useTranslation("ClientAdvisorHeader");
    const isClientAdvisor = useSelector((state: ApplicationState) => isUserClientAdvisor(state));
    const booking = useSelector((state: ApplicationState) => {
        return state.booking;
    });
    const path = useSelector(bookingBasePathSelector);
    const rebookEventInstance = useSelector((state: ApplicationState) => state.rebookEventInstance);
    const rebooking = !!rebookEventInstance;
    const attendeeName = `${booking.forename} ${booking.surname}`;
    const bookingLink = <Link href={`${path}/ca-redirect`}>{attendeeName}</Link>;

    const adminAppEndpoint = useSelector(adminAppEndpointSelector);
    const [id, setId] = React.useState<string>("");

    React.useEffect(() => {
        async function loadAttendeeId() {
            if (booking.isCancelled || rebooking) {
                const dorsApi = new BookingApi(booking.id);
                const eventInstanceId = rebooking ? booking.originalEventInstanceId : booking.nextCancelledEventInstanceId;
                const attendeeId = await dorsApi.getAttendeeId(booking.id, eventInstanceId, true);
                setId(attendeeId);
            }
        }
        if (isClientAdvisor && booking.id) {
            loadAttendeeId();
        }
    }, [isClientAdvisor, booking.nextCancelledEventInstanceId, booking.id, booking.isCancelled,
        booking.originalEventInstanceId, booking.originalSeatReservationId, rebooking]);

    const goToOriginalBookingLink = React.useCallback(() => {
        const eventInstanceId = booking.isCancelled ? booking.nextCancelledEventInstanceId : booking.originalEventInstanceId;
        location.href = `${adminAppEndpoint}police-and-court-event-management/eventInstances/${eventInstanceId}/attendees/${id}`;
    }, [adminAppEndpoint, booking.nextCancelledEventInstanceId, booking.isCancelled, booking.originalEventInstanceId, id]);

    const goToOfferBookingLink = React.useCallback(() => {
        location.href = booking.eventTypeCategory === EventTypeCategory.Dors
            ? `${adminAppEndpoint}police-and-court-event-management/dorsBookings/${booking.id}`
            : `${adminAppEndpoint}police-and-court-event-management/drinkDriveOffenders/${booking.id}`;
    }, [adminAppEndpoint, booking.id]);

    const [openSaveOffer, setOpenSaveOffer] = React.useState(false);
    const [openSaveOriginal, setOpenSaveOriginal] = React.useState(false);

    function onOfferClicked() {
        const savePossible = SaveBookingDetailsModalRequired(booking);

        if (savePossible) {
            setOpenSaveOffer(true);
            return;
        }

        goToOfferBookingLink();
    };

    function onOrigionalClicked() {
        const savePossible = SaveBookingDetailsModalRequired(booking);

        if (savePossible) {
            setOpenSaveOriginal(true);
            return;
        }

        goToOriginalBookingLink();
    };

    function closeOfferClicked() {
        setOpenSaveOffer(false);
    }

    function closeOriginalClicked() {
        setOpenSaveOriginal(false);
    }

    const nameDisplay = () => {
        if (currentView === View.BookingConfirmation) {
            return bookingLink;
        }

        const btnClassName = `link-button large${noMarginOnLinkButton ? " no-margin" : ""}`;

        if (rebooking || booking.isCancelled === true) {
            return <Button className={btnClassName} onClick={onOrigionalClicked}>{attendeeName}</Button>;
        }

        return <Button className={btnClassName} onClick={onOfferClicked}>{attendeeName}</Button>;
    };

    const eventTypeName = booking.eventTypeName;
    const eventTypeNameCy = booking.eventTypeNameCy ?? booking.eventTypeName;
    const completionDate = booking.expiryDate && booking.expiryDate.format(DateFormat.Short);

    return isClientAdvisor && (
        <>
            <SaveBookingDetailsModal
                open={openSaveOffer}
                onContinue={goToOfferBookingLink}
                close={closeOfferClicked}
            />
            <SaveBookingDetailsModal
                open={openSaveOriginal}
                onContinue={goToOriginalBookingLink}
                close={closeOriginalClicked}
            />
            <h3>
                {message || "You are booking "}
                {nameDisplay()}
                {showDetailedInfo &&
                    t("WELCOME", { completionDate, eventTypeName, eventTypeNameCy })}
            </h3>

            {currentView === View.VenueSelection &&
                <>
                    <p>{t("INTRODUCTION_LINE1")}<br />{t("INTRODUCTION_LINE2")}</p>
                </>}
        </>
    );
};
