import * as React from "react";
import { Link } from "redux-little-router";
import { Button, Table } from "semantic-ui-react";
import { Order, EventInstanceWithOrdersListModel } from "../model";
import { OrderApi } from "../orderApi";
import { Spinner } from "@common/global";
import { EventInstanceOrderRow } from "./EventInstanceOrderRow";
import { getBookingItemState } from "../../eventInstance/helpers";
import { formatPrice } from "../helpers";
import { businessLineTypeSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { useSelector } from "react-redux";

interface EventInstanceRowProps {
    eventInstance: EventInstanceWithOrdersListModel;
    extended: boolean;
    corporateOrganisationId?: string;
    corporateUserId?: string;
    showOrders: (eventInstanceId: string) => void;
}

export const EventInstanceRow: React.FC<EventInstanceRowProps> = ({
    eventInstance,
    extended,
    corporateOrganisationId,
    corporateUserId,
    showOrders,
}) => {
    const [orders, setOrders] = React.useState<Order[]>([]);

    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    React.useEffect(() => {
        const fetchData = async () => {
            const api = new OrderApi();
            const eventInstanceOrders = await api.getAllOrders({
                businessLineType,
                eventInstanceId: eventInstance.id,
                organisationId: corporateOrganisationId,
                userId: corporateUserId
            });
            setOrders(eventInstanceOrders.orders);
        };

        if (extended) {
            fetchData();
        } else {
            setOrders([]);
        }
    }, [businessLineType, eventInstance?.id, extended, corporateOrganisationId, corporateUserId]);

    const showOrdersForEventInstance = React.useCallback(() => {
        showOrders(eventInstance.id);
    }, [eventInstance.id, showOrders]);

    const eventInstanceRow = React.useMemo(() => (
        <Table.Row key={eventInstance.id}>
            <Table.Cell>
                <Link href={`${eventManagementPath}/eventInstances/${eventInstance.id}`}>
                    {eventInstance.venueName}
                </Link>
            </Table.Cell>
            <Table.Cell>{eventInstance.eventTypeName}</Table.Cell>
            <Table.Cell>
                {eventInstance.eventInstanceDeliveryDateTime.format(
                    "ddd DD/MM/YYYY HH:mm"
                )}
            </Table.Cell>
            <Table.Cell>
                {getBookingItemState(eventInstance.cancelled, eventInstance.completed)}
            </Table.Cell>
            <Table.Cell>{eventInstance.attendeeCount}</Table.Cell>
            <Table.Cell>{formatPrice(eventInstance.total ?? 0)}</Table.Cell>
            <Table.Cell>
                <Button
                    id={extended ? "extended" : "not_extended"}
                    icon={extended ? "angle up" : "angle down"}
                    onClick={showOrdersForEventInstance}
                />
            </Table.Cell>
        </Table.Row>
    ), [eventInstance, extended, showOrdersForEventInstance, eventManagementPath]);

    return (
        <>
            {eventInstanceRow}
            {extended && orders.length === 0 && <Spinner active />}
            {extended && orders.length > 0 && (
                <Table.Cell colSpan={7}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Booking ID</Table.HeaderCell>
                                <Table.HeaderCell>Booking Date</Table.HeaderCell>
                                {!corporateOrganisationId && (
                                    <Table.HeaderCell>Organisation</Table.HeaderCell>
                                )}
                                {!corporateUserId && (
                                    <Table.HeaderCell>Booker</Table.HeaderCell>
                                )}
                                <Table.HeaderCell>Number of seats</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {orders.map((order, i) => (
                                <EventInstanceOrderRow
                                    key={`eventInstanceOrder_${i}`}
                                    order={order}
                                    corporateOrganisationId={corporateOrganisationId}
                                    corporateUserId={corporateUserId}
                                    index={i}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            )}
        </>
    );
};
