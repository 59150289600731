import React from "react";
import { Button, Form, Icon, Message, Modal, Segment } from "semantic-ui-react";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { ChangeInBasketInput } from "./ChangeInBasketInput";
import { EventInstanceApi } from "@common/crud/eventInstance";
import { phoneNumberWithSpaces } from "@common/validation";
import { EmailApi } from "@common/email/emailApi";
import { debounce } from "lodash";
import { toast } from "@common/toasts";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { EventInstanceEnquiry } from "../model";

export interface EnquiryCaptureButtonAndModalProps {
    maxSeats: number;
    eventInstanceId: string;
    enquiryData: EventInstanceEnquiry;
}

const checkEmail = async (
    value: string,
    setSearchingEmail: (searchingEmail: boolean) => void,
    setEmailValid: (valid: boolean) => void,
    setEmailError: (error: string) => void
) => {
    if (!value) {
        setEmailValid(false);
    } else {
        const api = new EmailApi();
        const response = await api.checkEmail(value);
        setEmailValid(response.valid);
        setEmailError(response.error);
    }
    setSearchingEmail(false);
};

const deboucedCheckEmail = debounce(checkEmail, 1000);

export const EnquiryCaptureButtonAndModal = (props: EnquiryCaptureButtonAndModalProps) => {
    const { maxSeats, eventInstanceId, enquiryData } = props;

    const [open, setOpen] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);

    const [name, setName] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [searchingEmail, setSearchingEmail] = React.useState(false);
    const [emailValid, setEmailValid] = React.useState(false);
    const [emailError, setEmailError] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phoneValid, setPhoneValid] = React.useState(false);
    const [additionalComments, setAdditionalComments] = React.useState("");
    const [additionalCommentsValid, setAdditionalCommentsValid] = React.useState(false);
    const [seats, setSeats] = React.useState(1);

    const [contextOrganisationId, setContextOrganisationId] = React.useState<string>("");
    const [contextCorporateUserId, setContextCorporateUserId] = React.useState<string>("");

    React.useEffect(() => {
        if (enquiryData?.contextOrganisationId) {
            setContextOrganisationId(enquiryData.contextOrganisationId);
            setCompany(enquiryData.company);
        }

        if (enquiryData?.contextCorporateUserId) {
            setContextCorporateUserId(enquiryData.contextCorporateUserId);
            setEmail(enquiryData.email);
            setEmailValid(!!enquiryData.email);
            setName(enquiryData.name);
            setPhone(enquiryData.phone);
            setPhoneValid(!!enquiryData.phone);
        }
    }, [enquiryData]);

    const showEnquiryCaptureForm = React.useCallback(() => setOpen(true), []);

    const closeEnquiryCaptureForm = React.useCallback(() => {
        setOpen(false);
        setShowErrors(false);
        setSeats(1);
    }, []);

    const cleanEnquiryCaptureForm = React.useCallback(() => {
        setShowErrors(false);
        setName(enquiryData?.name || "");
        setCompany(enquiryData?.company || "");
        setEmail(enquiryData?.email || "");
        setEmailValid(!!enquiryData?.email);
        setEmailError("");
        setPhone(enquiryData?.phone || "");
        setPhoneValid(!!enquiryData?.phone);
        setAdditionalComments("");
        setAdditionalCommentsValid(false);
        setSeats(1);
    }, [enquiryData]);

    const onFullNameChanged = React.useCallback((value: string) => setName(value), []);
    const onCompanyChanged = React.useCallback((value: string) => setCompany(value), []);
    const onEmailChanged = React.useCallback((value: string, valid: boolean) => {
        if (email !== value) {
            setSearchingEmail(true);
            setEmail(value);
            deboucedCheckEmail(value, setSearchingEmail, setEmailValid, setEmailError);
        } else {
            setSearchingEmail(false);
        }

        setEmail(value);
        setEmailValid(valid);
    }, [email]);
    const onPhoneChanged = React.useCallback((value: string, valid: boolean) => {
        setPhone(value);
        setPhoneValid(valid);
    }, []);
    const onAdditionalCommentsChanged = React.useCallback((value: string, valid: boolean) => {
        setAdditionalComments(value);
        setAdditionalCommentsValid(valid);
    }, []);
    const onSeatsChanged = React.useCallback((value: number) => setSeats(seats + value), [seats]);

    const onSubmit = React.useCallback(async () => {
        setShowErrors(true);
        if (name && email && emailValid && (!phone || phoneValid) && (!additionalComments || additionalCommentsValid)) {
            try {
                const api = new EventInstanceApi();
                await api.addEnquiry(eventInstanceId, {
                    name,
                    company,
                    email,
                    phone,
                    additionalComments,
                    seats,
                    contextOrganisationId,
                    contextCorporateUserId,
                });
                closeEnquiryCaptureForm();
                toast.success("Enquiry submitted successfully.");
            }
            catch
            {
                toast.error("Failed to submit enquiry. Please try again later.");
            }
        } else {
            toast.error("Please provide valid data");
        }
    }, [additionalComments, additionalCommentsValid, closeEnquiryCaptureForm, company, contextCorporateUserId,
        contextOrganisationId, email, emailValid, eventInstanceId, name, phone, phoneValid, seats]);

    return (
        <>
            <Button onClick={showEnquiryCaptureForm} className="basket-button">Enquire</Button>
            <Modal size="small" open={open} onClose={closeEnquiryCaptureForm}>
                <Modal.Header>Enquiry</Modal.Header>
                <Modal.Content>
                    <Form>
                        <ExtendedTextInput
                            value={name}
                            label="Full name"
                            required
                            showErrors={showErrors}
                            onChange={onFullNameChanged}
                        />
                        <ExtendedTextInput
                            value={company}
                            label="Company"
                            showErrors={showErrors}
                            onChange={onCompanyChanged}
                        />
                        <ExtendedTextInput
                            value={email}
                            label="Email"
                            required
                            showErrors={showErrors}
                            onChange={onEmailChanged}
                        />
                        {searchingEmail &&
                            <Message as={Segment} className="cancel-action">
                                <Icon loading name='spinner' />
                                Validating email
                            </Message>
                        }
                        {email && !emailValid && emailError &&
                            <div className="ui red pointing above basic label">
                                <p>
                                    {emailError}
                                </p>
                            </div>
                        }
                        <ExtendedTextInput
                            value={phone}
                            label="Contact number"
                            validation={phoneNumberWithSpaces()}
                            showErrors={showErrors}
                            onChange={onPhoneChanged}
                        />
                        <Input.Textarea
                            value={additionalComments}
                            label="Additional comments"
                            validation={[validators.validLength({ max: 8000 })]}
                            onChange={onAdditionalCommentsChanged}
                        />
                        <ChangeInBasketInput
                            value={seats}
                            maxValue={maxSeats}
                            seatsChangedForCourse={onSeatsChanged}
                            wide
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={closeEnquiryCaptureForm}>Cancel</Button>
                    <Button className="cancel-action" onClick={cleanEnquiryCaptureForm}>Clean</Button>
                    <Button onClick={onSubmit}>Enquire now</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
