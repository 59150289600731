import * as React from "react";
import { Table, Button, Icon, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import { dateString, DateFormat } from "@common/crud/common/DateTimeFormats";
import { AutomaticPlacesIndicator } from "./AutomaticPlacesIndicator";
import { AttendeeRole, TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { useDispatch, useSelector } from "react-redux";
import { reserveSeat } from "../../seat/actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ApplicationState } from "../../applicationState";
import { EventInstanceProps } from "@booking/landing/model";
import { ManualPlacesIndicator } from "./ManualPlacesIndicator";
import { bookingSelector, deliveryTypeFilterSelector } from "@booking/landing/selectors";
import { useSetDeliveryTypeFilterInSessionStorage } from "@booking/landing/hooks/useSetDeliveryTypeFilterInSessionStorage";

export const EventInstanceRow: React.FC<EventInstanceProps> = ({ eventInstance, path, additionalMargin, firstNewElement, isMobile }) => {
    const [t] = useTranslation("AllEventInstances");
    const dispatch = useDispatch();
    const courseDate = dateString(eventInstance.startDate, DateFormat.LongNoYear);
    const startTimeFormatted = eventInstance.startTime.format(DateFormat.Time, { trim: false });
    const endTimeFormatted = eventInstance.endTime.format(DateFormat.Time, { trim: false });
    const includeFullyBookedCourses = useSelector((state: ApplicationState) => state.includeFullyBookedCourses);
    const deliveryTypeFilters = useSelector(deliveryTypeFilterSelector);
    const booking = useSelector(bookingSelector);
    const allowAutomatic = !!(eventInstance?.automaticSeatsData && eventInstance.automaticSeatsData.remainingSeats > 0);
    const allowManual = !!(eventInstance?.manualSeatsData && eventInstance.manualSeatsData.remainingSeats > 0);

    const onReservationRequest = React.useCallback(() => {
        dispatch(reserveSeat({ eventInstanceId: eventInstance.id, includeFullyBookedCourses, dayPart: 0, allowAutomatic, allowManual }, path));
        useSetDeliveryTypeFilterInSessionStorage(deliveryTypeFilters);
    }, [dispatch, eventInstance.id, includeFullyBookedCourses, allowAutomatic, allowManual, path, deliveryTypeFilters, booking]);

    return (
        <Table.Row
            id={additionalMargin ? "scroll-to-this" : undefined}
            className={`${additionalMargin ? "event-instance-table-additional-margin " : ""} ${isMobile? "event-instance-row cursor-pointer":""}`}
            onClick={isMobile ? onReservationRequest : null}
        >
            <Table.Cell className="event-details">
                <div ref={firstNewElement}>
                    {courseDate}<br />
                    {startTimeFormatted} {t("TO")} {endTimeFormatted}<br />
                    {t("COURSE_LANGUAGE")} {t(`Languages.${eventInstance.language}`)}
                    <WelshLanguageIndicator language={eventInstance.language} />
                    {eventInstance.oneToOne &&
                            <><br /><img className="menu-icon" src={"/assets/one-to-one.png"} /></>}
                    <Authorize authorize={TtcClientAdvisorRole}>
                        <br />
                        {eventInstance.seatsAvailable} {t("SEATS_AVAILABLE")}
                    </Authorize>
                </div>
                <AutomaticPlacesIndicator automaticSeatsData={eventInstance.automaticSeatsData} />
                <ManualPlacesIndicator manualSeatsData={eventInstance.manualSeatsData} />
                <Authorize authorize={AttendeeRole}>
                    <div className={"car-type-info"}>
                        {allowAutomatic && !allowManual &&  t("AUTO_ONLY")}
                        {allowManual && !allowAutomatic &&  t("MANUAL_ONLY")}
                        {allowManual && allowAutomatic &&  t("MANUAL_AND_AUTO")}
                    </div>
                </Authorize>
            </Table.Cell>
            <Table.Cell>
                <div className="course-distance">
                    {eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital ? null :
                        <>
                            {eventInstance.venueName}<br />
                            {eventInstance.distanceInMiles} {t("MILES")}<br />
                        </>}
                    <span className="cost-cell">
                            £{eventInstance.priceInPence && (eventInstance.priceInPence / 100).toFixed(2)}
                    </span>
                </div>
            </Table.Cell>
            {isMobile?
                <Table.Cell>
                    <Container className="event-instances-button-container"  textAlign={"right"}>
                        <Icon color="black" size="large" name={"angle right"} />
                    </Container>
                </Table.Cell>:
                <Table.Cell className="event-instances-button-container">
                    <Button
                        className="uppercase"
                        onClick={onReservationRequest}
                    >
                        {t("BOOK")}
                    </Button>
                </Table.Cell>}
        </Table.Row>
    );
};
