import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { InternalAppSettings } from "@common/appSettings/model";
import { AttendeeDetailModel, EventTypeCategory } from "../../model";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { AttendeeApi } from "@common/crud/attendee";
import { toast } from "@common/toasts";

export interface RebookButtonProps {
    attendeeAlreadyBookedAndCompleted: boolean;
    offerWithdrawn: boolean;
    internalAppSettings: InternalAppSettings;
    attendee: AttendeeDetailModel;
    dorsAttendanceStatus: DorsAttendanceStatusesEnum;
}

export const RebookButton: React.FC<RebookButtonProps> =
    ({ attendeeAlreadyBookedAndCompleted, offerWithdrawn, internalAppSettings, attendee, dorsAttendanceStatus }) => {
        const [rebookModalOpen, setRebookModalOpen] = React.useState<boolean>(false);

        const checkIfCanRebookAndRedirect = React.useCallback(async () => {
            const api = new AttendeeApi(attendee.eventInstanceId);
            const canRebook = await api.canBeRebooked(attendee.id);
            if (canRebook) {
                window.location.href = `${internalAppSettings.bookingAppEndpoint}bookings/${attendee.correlationId}/rebook`;
            } else {
                toast.error("The attendee is currently processed and cannot be rebooked. Please try again later.");
                setRebookModalOpen(false);
            }
        }, [internalAppSettings.bookingAppEndpoint, attendee.correlationId, attendee.eventInstanceId, attendee.id, setRebookModalOpen]);

        const cancel = React.useCallback(() => {
            setRebookModalOpen(false);
        }, []);

        const openModal = React.useCallback(() => {
            setRebookModalOpen(true);
        }, []);

        const dorsAttendanceStatusNotSet = attendee.eventTypeCategory === EventTypeCategory.Dors && dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet;

        const redirectRebookButton = attendee.eventTypeCategory !== EventTypeCategory.Dors ||
            attendee.eventTypeCategory === EventTypeCategory.Dors && attendeeAlreadyBookedAndCompleted === false && offerWithdrawn === false &&
            !dorsAttendanceStatusNotSet;

        const modalRebookButton = attendee.eventTypeCategory !== EventTypeCategory.Dors ||
            (attendee.eventTypeCategory === EventTypeCategory.Dors && (attendeeAlreadyBookedAndCompleted === true || offerWithdrawn === true ||
            dorsAttendanceStatusNotSet));

        const modalAction = () => (
            <Modal.Actions>
                <Button
                    onClick={cancel}
                    className="cancel-action"
                    content="Cancel"
                />
                <Button
                    onClick={checkIfCanRebookAndRedirect}
                    icon="checkmark"
                    content="Continue"
                />
            </Modal.Actions>);

        return (
            <>
                <Button
                    onClick={redirectRebookButton ? checkIfCanRebookAndRedirect : modalRebookButton ? openModal : null}
                    disabled={redirectRebookButton === false && modalRebookButton === false || attendee.isTransferred}
                >
                    Rebook
                </Button>

                <Modal open={rebookModalOpen}>
                    <Modal.Header>
                        {
                            attendeeAlreadyBookedAndCompleted ?
                                "Attendee's DORS status is attended and completed. Are you sure you want to book them?" :
                                dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet ?
                                    "Attendee's DORS status is unknown. Are you sure you want to book them?" :
                                    "Attendee's DORS status is offer withdrawn. Are you sure you want to book them?"
                        }
                    </Modal.Header>
                    {modalAction()}
                </Modal>
            </>
        );
    };
