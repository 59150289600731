import * as React from "react";
import { MultiSelectCheckbox } from "@common/components";
import { VenueAttribute } from "@common/crud/venue/model";
import { DigitalSpecialRequirementsAttribute,
    ClassroomAssignableAttribute,
    SpecialRequirementsAttribute,
    hearingImpairmentAttributes } from "@common/crud/eventInstance/model";
import { useTranslation } from "react-i18next";
import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";
import { Checkbox, CheckboxProps, Segment, TextArea } from "semantic-ui-react";
import "./SpecialRequirementsCheckboxes.scss";
import { AttendeeRole, EventManagementAdminRoles, TtcClientAdvisorRole, TtcSeniorClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";

interface SpecialRequirementsCheckboxesProps {
    includeOther: boolean;
    specialRequirements: SpecialRequirements;
    translated?: boolean;
    isDigitalCourse: boolean;
    onUpdate: (value: Omit<SpecialRequirements, "hasSpecialRequirements">) => void;
    hasSpecialRequirements: boolean;
}

export const SpecialRequirementsCheckboxes: React.FC<SpecialRequirementsCheckboxesProps> =
    ({ specialRequirements, onUpdate, hasSpecialRequirements, isDigitalCourse, includeOther, translated = true }) => {
        const [venueAttributes, setVenueAttributes] = React.useState<number[]>((specialRequirements && specialRequirements.venueAttributes) || []);
        const [eventAttributes, setEventAttributes] = React.useState<number[]>((specialRequirements && specialRequirements.eventAttributes) || []);
        const [otherRequirements, setOtherRequirements] = React.useState<string>((specialRequirements?.otherRequirements));
        const [interpreterName, setInterpreterName] = React.useState<string>((specialRequirements?.interpreterName) || "");
        const [outsideUkCountryName, setOutsideUkCountryName] = React.useState<string>((specialRequirements?.outsideUkCountryName) || "");
        const [hasHearingImpairment, setHasHearingImpairment] = React.useState<boolean>(specialRequirements?.hasHearingImpairment);
        const [otherHearingRequirements, setOtherHearingRequirements] = React.useState<string>(specialRequirements?.otherHearingRequirements);
        const [t] = useTranslation("SpecialRequirementsCheckboxes");

        const updateOtherRequirements = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) =>
            setOtherRequirements(event.currentTarget.value), []);

        const updateInterpreterName = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) =>
            setInterpreterName(event.currentTarget.value), []);

        const updateOutsideUkName = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) =>
            setOutsideUkCountryName(event.currentTarget.value), []);

        React.useEffect(() => {
            if (!eventAttributes?.includes(ClassroomAssignableAttribute.Translator) &&
                !eventAttributes?.includes(SpecialRequirementsAttribute.OwnInterpreter)) {
                setInterpreterName("");
            }
            if (!eventAttributes?.includes(SpecialRequirementsAttribute.OutsideUkAttendee)) {
                setOutsideUkCountryName("");
            }

            onUpdate({
                venueAttributes, eventAttributes, otherRequirements, interpreterName, outsideUkCountryName, otherHearingRequirements, hasHearingImpairment
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [eventAttributes]);

        React.useEffect(() => {
            if (hasSpecialRequirements) {
                onUpdate({
                    venueAttributes, eventAttributes, otherRequirements, interpreterName, outsideUkCountryName, otherHearingRequirements, hasHearingImpairment
                });
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [venueAttributes, eventAttributes, otherRequirements, interpreterName,
            outsideUkCountryName, otherHearingRequirements, hasHearingImpairment, hasSpecialRequirements]);

        const hasThisVenueAttribute = (attribute: VenueAttribute) => {
            return venueAttributes.indexOf(attribute) > -1;
        };

        const hasThisEventInstanceAssignableAttribute = (attribute: ClassroomAssignableAttribute) => {
            return eventAttributes.indexOf(attribute) > -1;
        };

        function onHearingImpairmentChange(event: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps) {
            setHasHearingImpairment(checked);
            if (!checked) {
                setEventAttributes(eventAttributes.filter(a => !hearingImpairmentAttributes.includes(a)));
                setOtherHearingRequirements("");
            }
        };

        function updateOtherHearingRequirements(event: React.FormEvent<HTMLTextAreaElement>) {
            setOtherHearingRequirements(event.currentTarget.value);
        }

        if (!hasSpecialRequirements) {
            return null;
        }

        return (
            <>
                <div className="field-wrapper select-list-item">
                    <Checkbox
                        label={translated ? t("HEARING_IMPAIRMENT") : "I have a hearing impairment"}
                        checked={hasHearingImpairment === true}
                        onChange={onHearingImpairmentChange}
                    />
                </div>
                {hasHearingImpairment &&
                    <Segment className="no-margin-top no-margin-bottom hearing-requirements">
                        <p><span className="required-asterisk">*</span>{t("HEARING_REQUIREMENTS")}</p>
                        <MultiSelectCheckbox
                            label={translated ? t("BSL") : "British Sign Language (BSL) interpreter"}
                            value={ClassroomAssignableAttribute.BslInterpreter}
                            selectedValues={eventAttributes}
                            onValuesChanged={setEventAttributes}
                        />
                        {!isDigitalCourse &&
                            <MultiSelectCheckbox
                                label={translated ? t("SPEECH_TO_TEXT") : "Speech to text reporter"}
                                value={ClassroomAssignableAttribute.TextToSpeechReporter}
                                selectedValues={eventAttributes}
                                onValuesChanged={setEventAttributes}
                            />}
                        {!isDigitalCourse &&
                            <MultiSelectCheckbox
                                label={translated ? t("HEARING_LOOP") : "I require a hearing loop"}
                                value={ClassroomAssignableAttribute.HearingLoop}
                                selectedValues={eventAttributes}
                                onValuesChanged={setEventAttributes}
                            />}
                        {isDigitalCourse &&
                            <MultiSelectCheckbox
                                label={translated ? t("REQUIRE_SUBTITLES") : "Require Subtitles"}
                                value={SpecialRequirementsAttribute.NeedSubtitles}
                                selectedValues={eventAttributes}
                                onValuesChanged={setEventAttributes}
                            />}
                        <div className="other-requirements">
                            <p>{t("OTHER_HEARING_REQUIREMENTS")}</p>
                            <TextArea
                                className="other-requirements-input"
                                placeholder="Other hearing requirements"
                                rows={2}
                                onChange={updateOtherHearingRequirements}
                                value={otherHearingRequirements}
                            />
                        </div>
                    </Segment>
                }
                {!isDigitalCourse &&
                <>
                    <MultiSelectCheckbox
                        label={translated ? t("BREASTFEEDING") : "Breastfeeding facilities"}
                        value={VenueAttribute.BreastfeedingRoom}
                        selectedValues={venueAttributes}
                        onValuesChanged={setVenueAttributes}
                    />
                    <MultiSelectCheckbox
                        label={translated ? t("PRAYER_ROOM") : "A prayer room"}
                        value={VenueAttribute.AreaAvailableForPrayer}
                        selectedValues={venueAttributes}
                        onValuesChanged={setVenueAttributes}
                    />
                    <MultiSelectCheckbox
                        label={translated ? t("DISABLED_ACCESS") : "Disabled access"}
                        value={VenueAttribute.DisabledAccess}
                        selectedValues={venueAttributes}
                        onValuesChanged={setVenueAttributes}
                    />
                    <MultiSelectCheckbox
                        label={translated ?
                            t("OWN_TRANSLATOR") : "Attendee speaks a different language and will bring their own translator"}
                        value={ClassroomAssignableAttribute.Translator}
                        selectedValues={eventAttributes}
                        onValuesChanged={setEventAttributes}
                    />
                    {eventAttributes?.includes(ClassroomAssignableAttribute.Translator) &&
                        <div className="other-requirements">
                            <p><span className="required-asterisk">*</span>{t("OWN_INTERPRETER")}</p>
                            <TextArea
                                className="other-requirements-input"
                                placeholder="The name of the interpreter"
                                rows={2}
                                onChange={updateInterpreterName}
                                value={interpreterName}
                            />
                        </div>}
                </>
                }
                {isDigitalCourse && <>
                    <Authorize authorize={[TtcClientAdvisorRole, TtcSeniorClientAdvisorRole]}>
                        {hasThisVenueAttribute(VenueAttribute.BreastfeedingRoom) && <MultiSelectCheckbox
                            label={translated ? t("BREASTFEEDING") : "Breastfeeding facilities - invalid for digital courses"}
                            value={VenueAttribute.BreastfeedingRoom}
                            selectedValues={venueAttributes}
                            onValuesChanged={setVenueAttributes}
                        />}
                        {hasThisVenueAttribute(VenueAttribute.AreaAvailableForPrayer) && <MultiSelectCheckbox
                            label={translated ? t("PRAYER_ROOM") : "A prayer room - invalid for digital courses"}
                            value={VenueAttribute.AreaAvailableForPrayer}
                            selectedValues={venueAttributes}
                            onValuesChanged={setVenueAttributes}
                        />}
                        {hasThisVenueAttribute(VenueAttribute.DisabledAccess) && <MultiSelectCheckbox
                            label={translated ? t("DISABLED_ACCESS") : "Disabled access - invalid for digital courses"}
                            value={VenueAttribute.DisabledAccess}
                            selectedValues={venueAttributes}
                            onValuesChanged={setVenueAttributes}
                        />}
                        {hasThisEventInstanceAssignableAttribute(ClassroomAssignableAttribute.TextToSpeechReporter) && <MultiSelectCheckbox
                            label={translated ? t("SPEECH_TO_TEXT") : "Speech to text reporter - invalid for digital courses"}
                            value={ClassroomAssignableAttribute.TextToSpeechReporter}
                            selectedValues={eventAttributes}
                            onValuesChanged={setEventAttributes}
                        />}
                        {hasThisEventInstanceAssignableAttribute(ClassroomAssignableAttribute.Translator) && <MultiSelectCheckbox
                            label={translated ?
                                t("OWN_TRANSLATOR") :
                                "Attendee speaks a different language and will bring their own translator - invalid for digital courses"}
                            value={ClassroomAssignableAttribute.Translator}
                            selectedValues={eventAttributes}
                            onValuesChanged={setEventAttributes}
                        />}
                        {hasThisEventInstanceAssignableAttribute(ClassroomAssignableAttribute.HearingLoop) && <MultiSelectCheckbox
                            label={translated ?
                                t("OWN_TRANSLATOR") : "I require a hearing loop - invalid for digital courses"}
                            value={ClassroomAssignableAttribute.HearingLoop}
                            selectedValues={eventAttributes}
                            onValuesChanged={setEventAttributes}
                        />}
                    </Authorize>
                    <MultiSelectCheckbox
                        label={translated ? t("NEED_TO_BREASTFEED") : "Need to breastfeed"}
                        value={DigitalSpecialRequirementsAttribute.NeedToBreastFeed}
                        selectedValues={eventAttributes}
                        onValuesChanged={setEventAttributes}
                    />
                    <MultiSelectCheckbox
                        label={translated ? t("NEED_PRAYER") : "Need to pray"}
                        value={DigitalSpecialRequirementsAttribute.NeedToPray}
                        selectedValues={eventAttributes}
                        onValuesChanged={setEventAttributes}
                    />
                    <MultiSelectCheckbox
                        label={translated ? t("OWN_TRANSLATOR") : "I speak a different language and will bring my own translator"}
                        value={SpecialRequirementsAttribute.OwnInterpreter}
                        selectedValues={eventAttributes}
                        onValuesChanged={setEventAttributes}
                    />
                    {(eventAttributes?.includes(SpecialRequirementsAttribute.OwnInterpreter) ||
                        eventAttributes?.includes(SpecialRequirementsAttribute.Translator)) &&
                    <div className="other-requirements">
                        <p><span className="required-asterisk">*</span>{t("OWN_INTERPRETER")}</p>
                        <TextArea
                            className="other-requirements-input"
                            placeholder="The name of the interpreter"
                            rows={2}
                            onChange={updateInterpreterName}
                            value={interpreterName}
                        />
                    </div>}
                    <MultiSelectCheckbox
                        label={translated ? t("OUTSIDE_UK") : "Attendee will be joining the course from outside of the UK"}
                        value={SpecialRequirementsAttribute.OutsideUkAttendee}
                        selectedValues={eventAttributes}
                        onValuesChanged={setEventAttributes}
                    />
                    {eventAttributes?.includes(SpecialRequirementsAttribute.OutsideUkAttendee) &&
                    <div className="other-requirements">
                        <p><span className="required-asterisk">*</span>{t("OTHER_COUNTRY")}</p>
                        <TextArea
                            className="other-requirements-input"
                            placeholder="Please advise which country attendee will be joining the course from"
                            rows={1}
                            onChange={updateOutsideUkName}
                            value={outsideUkCountryName}
                        />
                    </div>}
                </>}
                {
                    includeOther &&
                        <Authorize authorize={[AttendeeRole, ...EventManagementAdminRoles]}>
                            <div className="other-requirements">
                                {translated ? t("OTHER_DISABILITY") : "Please confirm details of any other disabilities below"}
                                <TextArea
                                    className="other-requirements-input"
                                    placeholder="Other requirements"
                                    rows={2}
                                    onChange={updateOtherRequirements}
                                    value={otherRequirements}
                                />
                            </div>
                        </Authorize>
                }
            </>
        );
    };
