import { Grid, Form, Button, Divider, InputOnChangeData, DropdownProps } from "semantic-ui-react";
import * as React from "react";
import { useState } from "react";
import { SearchOptions, ShowOrdersBy } from "../model";
import { MuiDateField } from "@common/components/MuiDateField";
import { getDateInputFormat } from "@common/dateFormating";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { constructionOrganisationOptionsSelectorWithAny, corporateOrganisationOptionsSelectorWithAny } from "@common/crud/organisation/selectors";
import { nameof } from "@common/helpers/nameof";
import { muiAnyDateValidator } from "@common/validation/futureDateValidator";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { loadAllOrganisationsForBusineessLineType } from "@common/crud/organisation/actions";

interface OrdersFiltersProps {
    corporateOrganisationId?: string;
    corporateUserId?: string;
    searchOptions: SearchOptions;
    onOptionsChanged: (query: SearchOptions) => void;
}

export const OrdersFilters: React.FC<OrdersFiltersProps> = ({ corporateOrganisationId, corporateUserId, searchOptions, onOptionsChanged }) => {
    const [stateOptions, setStateOptions] = useState<SearchOptions>(searchOptions);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

    const businessLineType = useSelector(businessLineTypeSelector);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!corporateOrganisationId) {
            dispatch(loadAllOrganisationsForBusineessLineType(businessLineType));
        }
    }, [businessLineType, corporateOrganisationId, dispatch]);

    const organisationOptions = useSelector(businessLineType === BusinessLineType.Corporate
        ? corporateOrganisationOptionsSelectorWithAny
        : constructionOrganisationOptionsSelectorWithAny).filter(o => !o.open);

    const dateInputFormat = React.useMemo(() => getDateInputFormat(), []);

    const onSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const options = {
            ...searchOptions,
            ...stateOptions,
        };
        setStateOptions(options);
        onOptionsChanged(options);
    }, [searchOptions, stateOptions, onOptionsChanged]);

    const onInputChanged = React.useCallback((prop: keyof (SearchOptions)) =>
        (_: any, data: InputOnChangeData) => {
            const prevValue = searchOptions[prop];
            if (prevValue !== data.value) {
                const options = { ...stateOptions, [prop]: data.value, filtersCleared: false };
                setStateOptions(options);
            }
        }, [searchOptions, stateOptions]);

    const onDateInputChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any, valid: boolean) => {
            if (!valid || (moment.isMoment(value) && !value.isValid)) {
                return;
            }

            const prevValue = searchOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
            }
        };
    }, [searchOptions, stateOptions]);

    const onMultiDropdownChange = React.useCallback((prop: keyof SearchOptions) => (_: any, { value }: DropdownProps) => {
        const options = { ...stateOptions, [prop]: value };
        if (prop === nameof<SearchOptions>("corporateOrganisationIds") && Array.isArray(value) && value.includes("")) {
            options.corporateOrganisationIds = [];
        }
        setStateOptions(options);
        onOptionsChanged(options);
    }, [onOptionsChanged, stateOptions]);

    const onClearFilters = React.useCallback(() => {
        const clearedSearchOptions = {
            ...ObjectKeys(searchOptions)
                .reduce((prevSearchOptions: SearchOptions, key: keyof SearchOptions) => {
                    const value = searchOptions[key];
                    const defaultValue: string[] | string | boolean =
                        Array.isArray(value) ? [] : typeof value === "string" ? "" : undefined;

                    return {
                        ...prevSearchOptions,
                        [key]: defaultValue
                    };
                }, {}), filtersCleared: true
        };
        clearedSearchOptions.showOrdersBy = ShowOrdersBy.Order;

        setStateOptions(clearedSearchOptions);
        onOptionsChanged(clearedSearchOptions);
    }, [onOptionsChanged, searchOptions]);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable className="full-width">
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"issue-button float-left"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {filtersVisible &&
                <>
                    <form onSubmit={onSubmit}>
                        <Grid className="filter-grid full-width" stackable>
                            <Grid.Row>
                                {!corporateOrganisationId && (
                                    <Grid.Column width={8}>
                                        <Form.Dropdown
                                            label="Organisations"
                                            floating
                                            multiple
                                            selection
                                            placeholder="Organisations"
                                            value={stateOptions.corporateOrganisationIds ?? []}
                                            options={organisationOptions}
                                            onChange={onMultiDropdownChange("corporateOrganisationIds")}
                                            search
                                        />
                                    </Grid.Column>
                                )}
                                <Grid.Column width={
                                    corporateUserId
                                        ? 16
                                        : corporateOrganisationId ? 8 : 4}
                                >
                                    <Form.Input
                                        label="Booking Reference"
                                        placeholder="Booking Reference"
                                        value={stateOptions.bookingReference}
                                        onChange={onInputChanged("bookingReference")}
                                    />
                                </Grid.Column>
                                {!corporateUserId && (
                                    <Grid.Column width={corporateOrganisationId ? 8 : 4}>
                                        <Form.Input
                                            label="Booker Name"
                                            placeholder="Booker Name"
                                            value={stateOptions.bookerName}
                                            onChange={onInputChanged("bookerName")}
                                        />
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <MuiDateField
                                        label="Date from"
                                        placeholder={dateInputFormat}
                                        value={stateOptions?.fromDate}
                                        onChange={onDateInputChanged("fromDate")}
                                        key={"fromDate-" + stateOptions?.fromDate?.toString()}
                                        validation={[muiAnyDateValidator]}
                                        showErrors
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <MuiDateField
                                        label="Date to"
                                        placeholder={dateInputFormat}
                                        value={stateOptions?.toDate}
                                        onChange={onDateInputChanged("toDate")}
                                        key={"toDate-" + stateOptions?.toDate?.toString()}
                                        validation={[muiAnyDateValidator]}
                                        showErrors
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Divider />
                            <Grid.Row>
                                <Grid.Column width={10} />
                                <Grid.Column width={4} >
                                    <a className={"issue-button filters"} onClick={onClearFilters}>Clear filters</a>
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="right">
                                    <Button content="APPLY" type="submit" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                </>
            }
        </>
    );
};
