import * as React from "react";

import { Language, LanguageEnum, LanguageCode } from "../eventInstance/model";

interface FlagIconProps {
    language: LanguageEnum;
}

export const FlagIcon = ({ language }: FlagIconProps) => (
    <img
        className="language-icon"
        src={`/assets/flag-${LanguageCode[language]}.png`}
        alt={`Language: ${Language[language]}`}
    />
);
