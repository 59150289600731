import * as React from "react";
import { connect } from "react-redux";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { Link } from "redux-little-router";
import {
    OrganisationDetailModel,
    AppState,
    ReminderType,
    BusinessLineType,
    Country
} from "../model";
import { dsaAreasSelector, organisationSelector, routeIdSelector } from "../selectors";
import { ContextMenu } from "./ContextMenu";
import { DsaAreaListItem } from "@common/dsa";

export interface DetailProps {
    path: string;
    organisationId: string;
    organisation: OrganisationDetailModel;
    dsaAreas: DsaAreaListItem[];
}

export class DetailInternal extends React.Component<DetailProps> {

    public render() {

        const organisation = this.props.organisation;
        const dsaAreas = this.props.dsaAreas;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{organisation.name}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <ContextMenu businessLineType={organisation.businessLineType} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Grid container stackable className="nomargintop">
                            <h2>Detail</h2>
                            {organisation.businessLineType === BusinessLineType.Court &&
                            <Detail
                                label="Reminder Type"
                                value={ReminderType[organisation.reminderType]}
                                labelWidth={5}
                                valueWidth={11}
                            />}
                            {organisation.businessLineType === BusinessLineType.Police &&
                            <Detail
                                label="Management Information Only"
                                value={organisation.managementInformationOnly ? "Yes" : "No"}
                                labelWidth={5}
                                valueWidth={11}
                            />}
                            {organisation.businessLineType === BusinessLineType.Police && <Detail
                                label="DORS Id"
                                value={`${organisation.dorsId || ""}`}
                                labelWidth={5}
                                valueWidth={11}
                            />}
                            <Detail
                                label="Expiry Date"
                                value={organisation.expiryDate?.isValid() && organisation.expiryDate.format("DD/MM/YYYY") || ""}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <h2>Contact</h2>
                            {organisation.organisationContact?.contacts?.map((contact, index) => (
                                <>
                                    <Detail
                                        label="Name"
                                        value={contact.name || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Email"
                                        value={contact?.email || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Telephone"
                                        value={contact?.telephone || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    {index !== organisation.organisationContact.contacts.length - 1 && (
                                        <Divider hidden className="full-width" />
                                    )}
                                </>
                            ))}
                            <Divider hidden className="full-width" />
                            {organisation.businessLineType === BusinessLineType.Court && <>
                                <h2>Address</h2>
                                <Detail label="Address Line 1" value={organisation.courtOrganisationData.address.addressLine1} labelWidth={5} valueWidth={11} />
                                <Detail label="Address Line 2" value={organisation.courtOrganisationData.address.addressLine2} labelWidth={5} valueWidth={11} />
                                <Detail label="Address Line 3" value={organisation.courtOrganisationData.address.addressLine3} labelWidth={5} valueWidth={11} />
                                <Detail label="Town" value={organisation.courtOrganisationData.address.city} labelWidth={5} valueWidth={11} />
                                <Detail label="Postcode" value={organisation.courtOrganisationData.address.postalCode} labelWidth={5} valueWidth={11} />
                            </>}
                            {organisation.businessLineType === BusinessLineType.Court && <>
                                <Detail label="Country" value={Country[organisation.courtOrganisationData.country]} labelWidth={5} valueWidth={11} />
                                <h2>DSA</h2>
                                <Detail label="DSA Area"
                                    value={dsaAreas[organisation.courtOrganisationData.dsaArea].description}
                                    labelWidth={5}
                                    valueWidth={11} />
                            </>}

                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const DetailWithActions: React.FC<DetailProps> = ({ organisationId, organisation, path, dsaAreas }) => {
    return (
        <div>
            <DetailInternal organisation={organisation} organisationId={organisationId} path={path} dsaAreas={dsaAreas} />
            <Divider />
            <Segment basic clearing vertical>
                <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${path}/edit`} />
            </Segment>
        </div>
    );
};

function mapStateToProps(state: AppState): DetailProps {
    return {
        organisation: organisationSelector(state),
        organisationId: routeIdSelector(state),
        path: state.router.pathname,
        dsaAreas: dsaAreasSelector(state)
    };
}

export const CoreDetail = connect(mapStateToProps)(DetailWithActions);
