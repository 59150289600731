import * as React from "react";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { Button, Dropdown } from "semantic-ui-react";
import { Link } from "redux-little-router";

export const AdminEventManagementActions: React.FC<{ path: string; ddrs: boolean }> = ({ path, ddrs }) => {
    const digitalCalendarLink = ddrs ? `${path}/ddrs-digital-planning` : `${path}/digital-planning`;
    const digitalPlanningOverviewLink = ddrs ? `${path}/ddrs-create-courses` : `${path}/create-courses`;
    const classroomCalendarLink = ddrs ? `${path}/ddrs-bulk-create` : `${path}/bulk-create`;

    return (
        <Authorize authorize={TtcPlannerRole}>
            <Button content="Trainer availability search" as={Link} href={"/police-and-court-event-management/trainer-availability-search"} />
            <Button content="Spreading Overview" as={Link} href={`${path}/spreading-overview`} />
            <Dropdown text="Planning" floating button>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} href={digitalCalendarLink} value="digital" text="Digital Planning" />
                    <Dropdown.Item as={Link} href={classroomCalendarLink} value="classroom" text="Classroom Planning" />
                    {ddrs === false &&
                        <Dropdown.Item as={Link} href={`${path}/team-support-calendar`} value="team-support-calendar" text="Trainer Support Planning" /> }
                    <Dropdown.Item as={Link} href={`${path}/standby-trainer-calendar`} value="standby-trainer-calendar" text="Standby Trainer Planning" />
                </Dropdown.Menu>
            </Dropdown>
            <Button content="Digital Planning Overview" as={Link} href={digitalPlanningOverviewLink} />
            <Button content="Create" as={Link} href={`${path}/create`} />
        </Authorize>
    );
};
