import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import * as React from "react";
import { Icon } from "semantic-ui-react";

export interface FunctionProps {
    label?: string;
    handleClick: (time: Moment) => void;
    time: Moment;
}

interface StartTimePickerState {
    time: Moment;
    label?: string;
}

export class StartTimePicker extends React.Component<FunctionProps, StartTimePickerState> {
    constructor(props: FunctionProps) {
        super(props);
        this.state = {
            time: props.time
        };
    }

    public render() {
        return (
            <div>
                <KeyboardTimePicker label={this.props.label ?? "Add Start Time"}
                    value={this.props.time ?? this.state.time}
                    onChange={this.updateSuggestedTime}
                    format={DateFormat.Time}
                    InputProps={{ disableUnderline: true, className: "ui labeled input" }}
                    mask="__:__"
                    keyboardIcon={<Icon name="time" />}
                    className={"mui-field small"}
                    placeholder={"--:--"}
                />
            </div>
        );
    }

    private updateSuggestedTime = (dateValue: Moment) => {
        this.setState({ time: dateValue });
        this.props.handleClick(dateValue?.isValid()? dateValue: null);
    };
}
