import * as React from "react";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Detail } from "@neworbit/simpleui-detail";

import { TtcVenueAdminRole } from "@common/auth/model";

import { Authorize } from "reauthorize";

import { CoreDetailProps } from "../Detail";
import { Venue, VenueAttributes } from "../../model";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { WorkflowTypeEnum, WorkflowTypes } from "@common/crud/eventType/model";
import { Country, VenueType, VenueTypeEnum, venueTypeNeedsAddress } from "@common/crud/organisation/model";
import { isVenuePoliceAndCourt } from "@common/global/CommonHelpers";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";

const CoreDetails: React.FC<{ venue: Venue; isPoliceOrCourt: boolean; isCorporate: boolean; isConstruction: boolean }> = ({ venue, isPoliceOrCourt, isCorporate,
    isConstruction }) => {
    const venueFee = venue.venueFee ? `£${venue.venueFee.toFixed(2)}` : "";
    const classroom = venue.deliveryType === DeliveryTypeEnum.Onsite;
    return (
        <Grid>
            <Detail label="Name" value={venue.name} labelWidth={5} valueWidth={11} />
            {isCorporate && (
                <>
                    <Detail label="Venue Type" value={VenueType[venue.venueType]} labelWidth={5} valueWidth={11} />
                    {(venue.venueType === VenueTypeEnum.CorporateOrganisationSpecific
                        || venue.venueType === VenueTypeEnum.ConstructionOrganisationSpecific) && (
                        <Detail label="Organisation" value={venue.organisationName} labelWidth={5} valueWidth={11} />
                    )}
                </>
            )}
            {isConstruction && (
                <>
                    <Detail label="Venue Type" value={VenueType[venue.venueType]} labelWidth={5} valueWidth={11} />
                    {(venue.venueType === VenueTypeEnum.ConstructionShared
                        || venue.venueType === VenueTypeEnum.ConstructionOrganisationSpecific) && (
                        <Detail label="Organisation" value={venue.organisationName} labelWidth={5} valueWidth={11} />
                    )}
                </>
            )}
            <Detail label="Contact Name" value={venue.contactName} labelWidth={5} valueWidth={11} />
            <Detail label="Email" value={venue.email} labelWidth={5} valueWidth={11} />
            <Detail label="Telephone Number" value={venue.telephoneNumber} labelWidth={5} valueWidth={11} />
            <Detail label="Attributes" value={venue.attributes?.map(value => VenueAttributes[value]).join(", ")} labelWidth={5} valueWidth={11} />
            <Detail label="Delivery type" value={DeliveryType[venue.deliveryType]} labelWidth={5} valueWidth={11} />
            <Detail label="Workflows" value={venue.workflowTypes?.map(value => WorkflowTypes[value]).join(", ")} labelWidth={5} valueWidth={11} />
            {isVenuePoliceAndCourt(venue) && venue.organisationName &&
                <Detail label="Police Force" value={venue.organisationName} labelWidth={5} valueWidth={11} />
            }
            {venue.workflowTypes?.some(w => w === WorkflowTypeEnum.Dors) &&
                <Detail label="DORS Id" value={venue.dorsId?.toString() || ""} labelWidth={5} valueWidth={11} />
            }
            {venue.dsaAreaName &&
                <Detail label="DSA Area" value={venue.dsaAreaName} labelWidth={5} valueWidth={11} />
            }
            {venue.referredCourtCountry &&
                <Detail label="Country" value={Country[venue.referredCourtCountry]} labelWidth={5} valueWidth={11} />
            }
            <Detail
                label="Expiry Date"
                value={
                    venue.expiryDate?.isValid() && venue.expiryDate.format("DD/MM/YYYY") || ""}
                labelWidth={5}
                valueWidth={11}
            />
            <Detail label="Venue Fee (net)" value={venueFee || "N/A"} labelWidth={5} valueWidth={5} />
            <Detail label="Venue Fee Note" value={venue.feeNote} labelWidth={5} valueWidth={5} />
            {venueTypeNeedsAddress(venue.venueType) && (
                <>
                    <h2>Address</h2>
                    <Detail label="Address Line 1" value={venue.address?.addressLine1} labelWidth={5} valueWidth={11} />
                    <Detail label="Address Line 2" value={venue.address?.addressLine2} labelWidth={5} valueWidth={11} />
                    <Detail label="Address Line 3" value={venue.address?.addressLine3} labelWidth={5} valueWidth={11} />
                    <Detail label="Town" value={venue.address?.city} labelWidth={5} valueWidth={11} />
                    <Detail label="Postcode" value={venue.address?.postalCode} labelWidth={5} valueWidth={11} />
                </>
            )}
            {isPoliceOrCourt ?
                <Detail label={"Booking App Note (En)"} value={venue.noteEn} labelWidth={5} valueWidth={11} />:
                <Detail label={"Booking Note"} value={<HtmlRenderer value={venue.noteEn} /> } labelWidth={5} valueWidth={11} />}
            {isPoliceOrCourt && (
                <Detail label="Booking App Note (Cy)" value={venue.noteCy} labelWidth={5} valueWidth={11} />
            )}

            { classroom && <Detail label="Max Capacity" value={venue.maxCapacity?.toString()} labelWidth={5} valueWidth={11} /> }
        </Grid>
    );
};

const CoreDetailsWithActions: React.FC<CoreDetailProps> = ({ venue, path, isPoliceOrCourt, isCorporate, isConstruction }) => {
    return (
        <div>
            <CoreDetails venue={venue} isPoliceOrCourt={isPoliceOrCourt} isCorporate={isCorporate} isConstruction={isConstruction} />

            <Authorize authorize={TtcVenueAdminRole}>
                <Divider />
                <Segment basic clearing vertical>
                    <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${path}/edit`} />
                </Segment>
            </Authorize>
        </div>
    );
};

export { CoreDetails, CoreDetailsWithActions };
