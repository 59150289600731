/* eslint-disable max-lines */
import * as React from "react";
import { Header, Tab } from "semantic-ui-react";
import { EventTypeCreateEditModel, WorkflowTypeEnum } from "../../model";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { rangeValidator } from "@common/validation";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";

export interface OwnProps {
    model: EventTypeCreateEditModel;
    showErrors?: boolean;
    onPropertyChange(propName: keyof EventTypeCreateEditModel): (value: any, valid: boolean) => void;
    onNestedPropertyChange(propName: string): (value: any, valid: boolean) => void;
}

export const CommunicationTab: React.FC<OwnProps> = ({
    model,
    showErrors,
    onPropertyChange,
    onNestedPropertyChange
}) => {

    const {
        whitespaceHeight,
        bookingConfirmationLetterOpeningEn,
        bookingConfirmationLetterOpeningCy,
        bookingConfirmationLetterClosingEn,
        bookingConfirmationLetterClosingCy,
    } = model.classroomEventTypeDetails || {};

    const {
        digitalWhitespaceHeight,
        digitalBookingConfirmationLetterOpeningEn,
        digitalBookingConfirmationLetterOpeningCy,
        digitalBookingConfirmationLetterClosingEn,
        digitalBookingConfirmationLetterClosingCy,
    } = model.digitalEventTypeDetails || {};

    const isDors = model.workflowType === WorkflowTypeEnum.Dors;
    const isDdrs = model.workflowType === WorkflowTypeEnum.DDRS;
    const isPoliceOrCourt = isDors || isDdrs;

    return (
        <Tab.Pane key="classroom-details">
            {isDdrs && (
                <>
                    <Header dividing>DDRS</Header>
                    <MarkdownEditor
                        value={model.firstInviteLetterOpening}
                        label="First invite Letter Opening"
                        showErrors={showErrors}
                        onChange={onPropertyChange("firstInviteLetterOpening")}
                    />
                    <MarkdownEditor
                        value={model.firstInviteLetterClosing}
                        label="First invite Letter Closing"
                        showErrors={showErrors}
                        onChange={onPropertyChange("firstInviteLetterClosing")}
                    />
                    <MarkdownEditor
                        value={model.reminderToBookLetterOpening}
                        label="Reminder to book Letter Opening"
                        showErrors={showErrors}
                        onChange={onPropertyChange("reminderToBookLetterOpening")}
                    />
                    <MarkdownEditor
                        value={model.reminderToBookLetterClosing}
                        label="Reminder to book Letter Closing"
                        showErrors={showErrors}
                        onChange={onPropertyChange("reminderToBookLetterClosing")}
                    />
                    <MarkdownEditor
                        value={model.completionLetterWordingEn}
                        label="Completion Letter Wording (En)"
                        showErrors={showErrors}
                        onChange={onPropertyChange("completionLetterWordingEn")}
                    />
                    <MarkdownEditor
                        value={model.nonCompletionLetterWordingEn}
                        label="Non-Completion Letter Wording (En)"
                        showErrors={showErrors}
                        onChange={onPropertyChange("nonCompletionLetterWordingEn")}
                    />
                </>
            )}
            {isDors && (
                <>
                    <Header dividing>Classroom</Header>
                    <Input.Number
                        value={whitespaceHeight}
                        label="Whitespace height"
                        validation={rangeValidator(0,5)}
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("classroomEventTypeDetails.whitespaceHeight")}
                    />
                    <MarkdownEditor
                        value={bookingConfirmationLetterOpeningEn}
                        label="Booking Confirmation Letter Opening (English)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("classroomEventTypeDetails.bookingConfirmationLetterOpeningEn")}
                    />
                    <MarkdownEditor
                        value={bookingConfirmationLetterOpeningCy}
                        label="Booking Confirmation Letter Opening (Welsh)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("classroomEventTypeDetails.bookingConfirmationLetterOpeningCy")}
                    />
                    <MarkdownEditor
                        value={bookingConfirmationLetterClosingEn}
                        label="Booking Confirmation Letter Closing (English)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("classroomEventTypeDetails.bookingConfirmationLetterClosingEn")}
                    />
                    <MarkdownEditor
                        value={bookingConfirmationLetterClosingCy}
                        label="Booking Confirmation Letter Closing (Welsh)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("classroomEventTypeDetails.bookingConfirmationLetterClosingCy")}
                    />

                    <Header dividing>Digital</Header>
                    <Input.Number
                        value={digitalWhitespaceHeight}
                        label="Whitespace height"
                        validation={rangeValidator(0,5)}
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("digitalEventTypeDetails.digitalWhitespaceHeight")}
                    />
                    <MarkdownEditor
                        value={digitalBookingConfirmationLetterOpeningEn}
                        label="Booking Confirmation Letter Opening (English)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("digitalEventTypeDetails.digitalBookingConfirmationLetterOpeningEn")}
                    />
                    <MarkdownEditor
                        value={digitalBookingConfirmationLetterOpeningCy}
                        label="Booking Confirmation Letter Opening (Welsh)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("digitalEventTypeDetails.digitalBookingConfirmationLetterOpeningCy")}
                    />
                    <MarkdownEditor
                        value={digitalBookingConfirmationLetterClosingEn}
                        label="Booking Confirmation Letter Closing (English)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("digitalEventTypeDetails.digitalBookingConfirmationLetterClosingEn")}
                    />
                    <MarkdownEditor
                        value={digitalBookingConfirmationLetterClosingCy}
                        label="Booking Confirmation Letter Closing (Welsh)"
                        showErrors={showErrors}
                        onChange={onNestedPropertyChange("digitalEventTypeDetails.digitalBookingConfirmationLetterClosingCy")}
                    />
                </>
            )}

            <h3>Booking App</h3>
            {isPoliceOrCourt && (
                <>
                    <Input.Textarea
                        value={model.noteEn}
                        label={"Scheme Note (English)"}
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={onPropertyChange("noteEn")}
                    />
                    <Input.Textarea
                        value={model.noteCy}
                        label={"Scheme Note (Welsh)"}
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={onPropertyChange("noteCy")}
                    />
                </>
            )}
            {!isPoliceOrCourt && (
                <MarkdownEditor
                    value={model.productNote}
                    label="Product Note"
                    showErrors={showErrors}
                    onChange={onPropertyChange("productNote")}
                />
            )}
            {isPoliceOrCourt && (
                <>
                    <MarkdownEditor
                        value={model.introMessageEnglish}
                        label="Intro Message (English)"
                        showErrors={showErrors}
                        onChange={onPropertyChange("introMessageEnglish")}
                    />
                    <MarkdownEditor
                        value={model.introMessageWelsh}
                        label="Intro Message (Welsh)"
                        showErrors={showErrors}
                        onChange={onPropertyChange("introMessageWelsh")}
                    />
                    <h3>Surveys</h3>
                    <Input.Text
                        value={model.surveyLink}
                        label="Survey 1 Link - English"
                        showErrors={showErrors}
                        onChange={onPropertyChange("surveyLink")}
                    />
                    <Input.Text
                        value={model.surveyLinkCy}
                        label="Survey 1 Link - Welsh"
                        showErrors={showErrors}
                        onChange={onPropertyChange("surveyLinkCy")}
                    />
                    <Input.Text
                        value={model.secondSurveyLink}
                        label="Survey 2 Link - English"
                        showErrors={showErrors}
                        onChange={onPropertyChange("secondSurveyLink")}
                    />
                    <Input.Text
                        value={model.secondSurveyLinkCy}
                        label="Survey 2 Link - Welsh"
                        showErrors={showErrors}
                        onChange={onPropertyChange("secondSurveyLinkCy")}
                    />
                </>
            )}
            {isPoliceOrCourt &&
                (model.deliveryType === SchemeDeliveryTypeEnum.Classroom ||
                 model.deliveryType === SchemeDeliveryTypeEnum.Digital ||
                 model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) && (
                <h3>Booking Information</h3>
            )}
            {(model.deliveryType === SchemeDeliveryTypeEnum.Classroom || model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)
                && isPoliceOrCourt && (
                <>
                    <Input.Text
                        value={model.bookingInformationLinkClassroom}
                        label="Classroom Booking Information Link - English"
                        showErrors={showErrors}
                        onChange={onPropertyChange("bookingInformationLinkClassroom")}
                    />
                    <Input.Text
                        value={model.bookingInformationLinkClassroomCy}
                        label="Classroom Booking Information Link - Welsh"
                        showErrors={showErrors}
                        onChange={onPropertyChange("bookingInformationLinkClassroomCy")}
                    />
                </>
            )}
            {(model.deliveryType === SchemeDeliveryTypeEnum.Digital || model.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital)
                && isPoliceOrCourt && (
                <>
                    <Input.Text
                        value={model.bookingInformationLinkDigital}
                        label="Digital Booking Information Link - English"
                        showErrors={showErrors}
                        onChange={onPropertyChange("bookingInformationLinkDigital")}
                    />
                    <Input.Text
                        value={model.bookingInformationLinkDigitalCy}
                        label="Digital Booking Information Link - Welsh"
                        showErrors={showErrors}
                        onChange={onPropertyChange("bookingInformationLinkDigitalCy")}
                    />
                </>
            )}
            {model.flexiPayEnabled &&
                <>
                    <h3>Flexi-booking</h3>
                    <Input.Textarea
                        value={model.flexiPayInstructionsEn}
                        label="Flexi-booking Instructions (English)"
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={onPropertyChange("flexiPayInstructionsEn")}
                    />
                    <Input.Textarea
                        value={model.flexiPayInstructionsCy}
                        label="Flexi-booking Instructions (Welsh)"
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={onPropertyChange("flexiPayInstructionsCy")}
                    />
                </>
            }
            {(model.workflowType === WorkflowTypeEnum.OnRoad || model.workflowType === WorkflowTypeEnum.Workshop
                || model.workflowType === WorkflowTypeEnum.BusinessDriver) &&
                <>
                    <h3>Competency Report</h3>
                    <Input.Checkbox
                        label="Competency Report Required"
                        value={model.competencyReportRequired}
                        showErrors={showErrors}
                        onChange={onPropertyChange("competencyReportRequired")}
                        toggle
                    />
                    {model.competencyReportRequired &&
                        <>
                            <Input.Text
                                label="Competency Report Link"
                                value={model.competencyReportLink}
                                showErrors={showErrors}
                                onChange={onPropertyChange("competencyReportLink")}
                            />
                        </>
                    }
                </>
            }
        </Tab.Pane>
    );
};
