import * as React from "react";
import { connect } from "react-redux";
import { AsyncDispatch } from "@common/redux-helpers";
import { OrganisationState, selectors as organisationSelectors } from "@common/crud/organisation";
import { ConfirmButton } from "@common/components";
import { AppState, PoliceUserDetailModel } from "../../model";
import * as actions from "../../actions";

interface OwnProps {
    user: PoliceUserDetailModel;
}

interface DispatchProps {
    updateAdb2cAccount: () => void;
}

const Adb2cAccountButtonComponent: React.FC<OwnProps & DispatchProps> = ({ user, updateAdb2cAccount }) => {

    const updateText = user.accountDisabled ? "Enable" : "Disable";

    return (
        <ConfirmButton icon="pencil" onConfirm={updateAdb2cAccount} header={`${updateText} user account`}>
            {updateText}
        </ConfirmButton>
    );
};

const mapStateToProps = (state: AppState & OrganisationState) => {
    return {
        organisationId: organisationSelectors.routeIdSelector(state),
    };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => {
    return {
        updateAdb2cAccount: (id: string, organisationId: string) => dispatch(actions.updateAdb2cAccount(id, organisationId)),
    };
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch, ownProps: OwnProps): OwnProps & DispatchProps => {
    const { organisationId } = propsFromState;
    const { updateAdb2cAccount } = propsFromDispatch;
    const { user } = ownProps;
    return {
        user,
        updateAdb2cAccount: () => updateAdb2cAccount(user.id, organisationId)
    };
};

export const Adb2cAccountButton = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Adb2cAccountButtonComponent);
