import { isWorkflowConstruction, isWorkflowCorporate } from "@common/global/CommonHelpers";
import { loadTrainerAttributeDefinitions } from "../trainerAttributeDefinition/actions";
import { loadEventTypeDetail } from "./actions";
import { AppState } from "./model";
import { eventTypeByRouteSelector, eventTypesByRouteSelector } from "./selectors";

export const EventTypeRoutes = {
    Details: "/:eventTypeId",
    Edit: "/edit",
    EventTypes: "/eventTypes",
    Create: "/create",
    History: "/history",
    ClassroomDetails: "/classroomDetails",
    DigitalDetails: "/digitalDetails",
    Trainer: "/trainer",
    Communications: "/communications",
    CourseFees: "/courseFees",
    FeesOpenCreate: "/feesOpen/create",
    FeesOpenEdit: "/feesOpen/edit",
    MonitorObserverFeesOpenEdit: "/monitorObserverFeesOpen/edit",
    OtherTrainerFeesOpenEdit: "/otherTrainerFeesOpen/edit",
    Files: "/files",
    DelegateDocuments: "/delegateDocuments",
};

const detailRoute = () => ({
    [EventTypeRoutes.Details]: {
        title: (state: AppState) => eventTypeByRouteSelector(state).name,
        resolve: [loadEventTypeDetail, loadTrainerAttributeDefinitions],
        [EventTypeRoutes.Edit]: {
            title: "Edit"
        },
        [EventTypeRoutes.ClassroomDetails]: {
            title: "Classroom Details",
            [EventTypeRoutes.Edit]: {
                title: "Edit"
            }
        },
        [EventTypeRoutes.DigitalDetails]: {
            title: "Digital Details",
            [EventTypeRoutes.Edit]: {
                title: "Edit"
            }
        },
        [EventTypeRoutes.Trainer]: {
            title: "Trainer",
            [EventTypeRoutes.FeesOpenEdit]: {
                title: "Edit"
            },
            [EventTypeRoutes.Edit]: {
                title: "Edit"
            },
            [EventTypeRoutes.MonitorObserverFeesOpenEdit]: {
                title: "Edit"
            },
            [EventTypeRoutes.OtherTrainerFeesOpenEdit]: {
                title: "Edit"
            }
        },
        [EventTypeRoutes.Communications]: {
            title: "Communications",
            [EventTypeRoutes.Edit]: {
                title: "Edit"
            }
        },
        [EventTypeRoutes.CourseFees]: {
            title: "Course Fees",
            [EventTypeRoutes.FeesOpenEdit]: {
                title: "Edit"
            },
            [EventTypeRoutes.Edit]: {
                title: "Edit",
            }

        },
        [EventTypeRoutes.Files]: {
            title: "Files"
        },
        [EventTypeRoutes.DelegateDocuments]: {
            title: "Delegate Documents"
        },
        [EventTypeRoutes.History]: {
            title: (state: AppState) => eventTypesByRouteSelector(state).some(t => isWorkflowCorporate(t) || isWorkflowConstruction(t))
                ? "Product history"
                : "Scheme history",
        }
    }
});

export const routes = {
    [EventTypeRoutes.EventTypes]: {
        title: (state: AppState) => eventTypesByRouteSelector(state).some(t => isWorkflowCorporate(t) || isWorkflowConstruction(t))
            ? "Products"
            : "Schemes",
        ignoreParentResolve: true,
        ...detailRoute(),
        [EventTypeRoutes.Create]: {
            title: (state: AppState) => eventTypesByRouteSelector(state).some(t => isWorkflowCorporate(t) || isWorkflowConstruction(t))
                ? "Create Product"
                : "Create Scheme",
            resolve: loadTrainerAttributeDefinitions,
        },
        [EventTypeRoutes.FeesOpenCreate]: {
            title: (state: AppState) => eventTypesByRouteSelector(state).some(t => isWorkflowCorporate(t) || isWorkflowConstruction(t))
                ? "Create Product"
                : "Create Scheme",
        },
        [EventTypeRoutes.DelegateDocuments]: {
            title: "Delegate Documents",
        }
    }
};
