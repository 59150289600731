import * as React from "react";
import { Fragment, Location } from "redux-little-router";

import { StripeWrapper } from "src/Admin.App/src/payments/components/StripeWrapper";

import { ManualPayment } from "src/Admin.App/src/payments/components/ManualPayment";

import { All } from "./All";
import { Detail as AttendeeDetail } from "./Detail";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { GenesysAuthorisation } from "@common/genesys/components/GenesysAuthorisation";
import { Edit as AttendeeEdit } from "@common/crud/attendee/components/Edit";
import { FlexiPayPayment } from "src/Admin.App/src/payments/FlexiPayPayment";
import { Area } from "@common/crud/eventInstance/model";
import { useSelector } from "react-redux";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { Detail as DelegateDetail } from "../../delegate/components/Detail";
import { Edit as DelegateEdit } from "../../delegate/components/Edit";

const noAttendeeId = (location: Location) => location.params.attendeeId === undefined;

export interface BaseProp {
    area: Area;
}
export const Base: React.FC<BaseProp> = ({ area  }) => {
    const businessLineType = useSelector(businessLineTypeSelector);

    const VaryingDetail = React.useMemo(() =>
        (businessLineType === BusinessLineType.Corporate || businessLineType === BusinessLineType.Construction)
            ? DelegateDetail
            : AttendeeDetail, [businessLineType]);
    const VaryingEdit = React.useMemo(() =>
        (businessLineType === BusinessLineType.Corporate || businessLineType === BusinessLineType.Construction)
            ? DelegateEdit
            : AttendeeEdit, [businessLineType]);

    return (
        <div>
            <Fragment forRoute="/" withConditions={noAttendeeId}>
                <div>
                    <All area={area} />
                </div>
            </Fragment>
            <Fragment forRoute={`/:eventInstanceId/${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`}>
                <StripeWrapper />
            </Fragment>
            <Fragment forRoute={`/:attendeeId/${PaymentPageNames[PaymentPageNamesEnum.ManualPayment]}`}>
                <ManualPayment />
            </Fragment>
            <Fragment forRoute={`/:attendeeId/${PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]}`}>
                <GenesysAuthorisation />
            </Fragment>
            <Fragment forRoute={`/:attendeeId/${PaymentPageNames[PaymentPageNamesEnum.FlexiPayPayment]}`}>
                <FlexiPayPayment />
            </Fragment>
            <Fragment forRoute="/:attendeeId">
                <div>
                    <VaryingDetail />
                    <VaryingEdit />
                </div>
            </Fragment>
        </div>
    );
};
