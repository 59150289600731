import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";
import * as React from "react";
import { OptTrainerModal } from "./OptTrainerModal";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

export interface AttributesTableProps {
    attributes: TrainerAttribute[];
    attributeCategory: string;
    digital: boolean;
    trainerId: string;
    title: string;
    attributeModalHeader?: string;
}

interface ExtendedTrainerAttribute  extends TrainerAttribute {
    id: string;
}

export const ConstructionAttributesTable = ({ attributes, digital, attributeCategory, attributeModalHeader, trainerId, title }: AttributesTableProps) => {
    const [ extendedTrainerAttributes, setExtendedTrainerAttributes] = React.useState<ExtendedTrainerAttribute[]>([]);
    React.useEffect(() => {
        setExtendedTrainerAttributes(attributes?.map((e: TrainerAttribute): ExtendedTrainerAttribute => ({
            id: e.attributeDefinitionId,
            ...e
        })));
    }, [attributes]);

    const columns: TypedTableRowProps<ExtendedTrainerAttribute>[] = [
        {
            header: "Name",
            value: (e) => !digital && e.isOptedOut ? `${e.name} (Opted Out)` : e.name
        },
        {
            header: "Expiry Date",
            value: (e) => e.expiryDate?.format(DateFormat.Short) ?? "No date"
        },
    ];

    return (
        <>
            <h3>{title}</h3>
            <TypedTable values={extendedTrainerAttributes} emptyValuesArrayMessage={`No ${attributeCategory} attributes`} >
                {columns}
            </TypedTable>
            <OptTrainerModal modalHeader={attributeModalHeader ?? title} trainerAttributes={attributes} trainerId={trainerId} isDigital={digital} />
        </>
    );
};
