import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Button, Form, Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import {
    EditProps as SharedEditProps,
    SaveDispatchProps,
    FormState
} from "@neworbit/simpleui-forms";
import { AsyncDispatch } from "@common/redux-helpers";
import { dorsId } from "@common/validation/dorsIdValidator";

import {
    OrganisationEditModel,
    AppState,
    ReminderType,
    BusinessLineType,
    CountryDropDownOptions,
    OrganisationContactDataModel,
    CorporateOrganisationContact
} from "../model";
import { courtsBasePathSelector, dsaAreasSelector, organisationSelector, policeBasePathSelector } from "../selectors";
import { saveOrganisation } from "../actions";

import { EditModal } from "./EditModal";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { DsaAreaListItem } from "@common/dsa";
import { yesNoOptions } from "@common/crud/common/optionsMappers";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { ExtendedEditComponent } from "@common/components/ExtendedEditForm";
import { OrganisationContactRow } from "./OrganisationContactRow";
import { toast } from "react-toastify";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { ExtendedDropdown, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { omit } from "lodash";

export interface EditProps extends SharedEditProps<OrganisationEditModel> {
    open: boolean;
    dsaAreas: DsaAreaListItem[];
}

export interface DispatchProps extends SaveDispatchProps<OrganisationEditModel> {
    close: () => void;
}

interface EditState extends FormState<OrganisationEditModel> {
    contactsValid: Set<string>;
}

export class EditForm extends ExtendedEditComponent<OrganisationEditModel, EditProps, EditState> {

    constructor(props: EditProps & SaveDispatchProps<OrganisationEditModel>) {
        super(props);

        this.addContact = this.addContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
        this.updateContactValue = this.updateContactValue.bind(this);
        this.fixContactsInvalid = this.fixContactsInvalid.bind(this);
        this.submit = this.submit.bind(this);
    }

    public submitting = false;

    public shouldComponentUpdate() {
        return !this.submitting;
    }

    public updateContactValue(contact: CorporateOrganisationContact, index: number, field: string, value: string, valid: boolean) {
        const validField = `${contact}.${index}.${field}`;
        const uniqueValidField = `${contact}.unique`;
        const newContactValid = new Set(this.state.contactsValid);

        if (valid && newContactValid.has(validField)) {
            newContactValid.delete(validField);
        }

        if (!valid && !newContactValid.has(validField)) {
            newContactValid.add(validField);
        }

        const newContacts = this.state.values.organisationContact.contacts.map((existingContact, contactIndex) => contactIndex === index
            ? { ...existingContact, [field]: value }
            : existingContact);
        const nameContactRepresentations = newContacts.map(c => c.name);
        const emailContactRepresentations = newContacts.map(c => c.email);
        const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
            || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

        if (!hasDuplicates && newContactValid.has(uniqueValidField)) {
            newContactValid.delete(uniqueValidField);
        }

        if (hasDuplicates && !newContactValid.has(uniqueValidField)) {
            newContactValid.add(uniqueValidField);
        }

        this.setState({
            values: { ...this.state.values, organisationContact: { ...this.state.values.organisationContact, contacts: newContacts } },
            contactsValid: newContactValid
        });
    }

    public fixContactsInvalid(indexRemoved: number, newContacts: OrganisationContactDataModel[]) {
        const newContactValid = new Set<string>();
        this.state.contactsValid.forEach(invalidField => {
            const parts = invalidField.split(".");

            if (parts[1] !== "unique") {
                if (+parts[1] < indexRemoved) {
                    newContactValid.add(invalidField);
                }

                if (+parts[1] > indexRemoved) {
                    const newInvalidField = `${parts[0]}.${+parts[1] - 1}.${parts[2]}`;
                    newContactValid.add(newInvalidField);
                }
            } else {
                const nameContactRepresentations = newContacts.map(c => c.name);
                const emailContactRepresentations = newContacts.map(c => c.email);
                const hasDuplicates = (new Set(nameContactRepresentations)).size !== nameContactRepresentations.length
                    || (new Set(emailContactRepresentations)).size !== emailContactRepresentations.length ;

                if (hasDuplicates) {
                    newContactValid.add(invalidField);
                }
            }
        });
        this.setState({ contactsValid: newContactValid });
    }

    public removeContact(index: number) {
        const newContacts = this.state.values.organisationContact.contacts.filter((_, contactIndex: number) => contactIndex !== index);
        this.updateNestedProperty("organisationContact.contacts", newContacts, true);
        this.fixContactsInvalid(index, newContacts);
    }

    public addContact(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.updateNestedProperty(
            "organisationContact.contacts",
            [ ...this.state.values.organisationContact?.contacts || [], { name: "", email: "", telephone: "" } ],
            true
        );
    }

    public render() {
        const { values, showErrors } = this.state;

        const reminderTypeOptions = ObjectKeys(omit(ReminderType, ReminderType.None))
            .filter(k => typeof ReminderType[k] === "string")
            .map(k => ({ text: ReminderType[k], value: +k }));

        const dsaAreaOptions = this.props.dsaAreas.map(da => ({ text: da.description, value: da.id }));

        return (
            <Form onSubmit={this.handleSubmit}>
                <ExtendedTextInput
                    value={values.name}
                    label="Name"
                    disabled
                    required
                />
                {values.businessLineType === BusinessLineType.Court && <ExtendedDropdownNumber
                    value={values.reminderType}
                    label="Reminder Type"
                    required
                    showErrors={showErrors}
                    options={reminderTypeOptions}
                    onChange={(value, valid) => this.updateProperty("reminderType", value, valid)}
                />}
                {values.businessLineType === BusinessLineType.Police && <Input.Number
                    value={values.dorsId}
                    label="DORS Id"
                    validation={dorsId}
                    showErrors={showErrors}
                    onChange={(value, valid) => this.updateProperty("dorsId", value, valid)}
                />}
                <MuiDateField
                    value={values.expiryDate}
                    label="Expiry Date"
                    showErrors={showErrors}
                    validation={[muiTodayOrfutureDateValidator]}
                    onChange={(value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid)}
                />
                {values.businessLineType === BusinessLineType.Police &&
                <ExtendedDropdown
                    value={values.flexiPayEnabled?.toString() ?? "false"}
                    label="Flexi-Booking Enabled"
                    showErrors={showErrors}
                    options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                    onChange={(value, valid) => this.updateProperty("flexiPayEnabled", value, valid)}
                />}
                {values.businessLineType === BusinessLineType.Police &&
                <ExtendedDropdown
                    value={values.managementInformationOnly?.toString() ?? "false"}
                    label="Management Information Only"
                    showErrors={showErrors}
                    options={yesNoOptions()}
                    onChange={(value, valid) => this.updateProperty("managementInformationOnly", value, valid)}
                />}
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <h2>Contact</h2>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.addContact} content="Add contact" className="vertical-center" />
                        </Grid.Column>
                    </Grid.Row>
                    {values.organisationContact && values.organisationContact.contacts && values.organisationContact.contacts.map(
                        (contact: OrganisationContactDataModel, index: number) => (
                            <OrganisationContactRow
                                contactType={CorporateOrganisationContact.Primary}
                                key={`contact_${index}`}
                                contact={contact}
                                index={index}
                                showErrors={showErrors}
                                updateContactValue={this.updateContactValue}
                                removeContact={this.removeContact}
                            />
                        )
                    )}
                </Grid>
                {values.businessLineType === BusinessLineType.Court &&  <>
                    <AddressLookup
                        showErrors={showErrors}
                        address={values.courtOrganisationData.address}
                        onChange={(value, valid) => this.updateNestedProperty("courtOrganisationData.address", value, valid)}
                    />
                    <ExtendedDropdownNumber
                        value={values.courtOrganisationData.country}
                        label="Country"
                        required
                        showErrors={showErrors}
                        options={CountryDropDownOptions}
                        onChange={(value, valid) => this.updateNestedProperty("courtOrganisationData.country", value, valid)}
                    />
                </>}
                {values.businessLineType === BusinessLineType.Court &&  <>
                    <h2>DSA</h2>
                    <ExtendedDropdownNumber
                        value={values.courtOrganisationData.dsaArea}
                        label="DSA Area"
                        required
                        showErrors={showErrors}
                        options={dsaAreaOptions}
                        onChange={(value, valid) => this.updateNestedProperty("courtOrganisationData.dsaArea", value, valid)}
                    />
                </>}
            </Form>
        );
    }

    public submit = () => {
        if (this.state.contactsValid && this.state.contactsValid.size !== 0) {
            toast.warning("Organisation cannot be edited as some contacts have duplicate email addresses");
            return;
        }

        this.submitting = true;
        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };
}

function mapStateToProps(state: AppState) {
    return {
        model: organisationSelector(state) as OrganisationEditModel,
        open: state.router.pathname.endsWith("/edit"),
        basePath: state.router.pathname.includes("courts") ? courtsBasePathSelector(state) : policeBasePathSelector(state),
        dsaAreas: dsaAreasSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (organisation: OrganisationEditModel, basePath: string) => dispatch(saveOrganisation(organisation, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        model: propsFromState.model,
        open: propsFromState.open,
        save: (organisation: OrganisationEditModel) => propsFromDispatch.dispatchSave(organisation, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`),
        dsaAreas: propsFromState.dsaAreas
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
