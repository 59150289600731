import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, push } from "redux-little-router";
import { Button, Grid, Form, InputOnChangeData, Checkbox, CheckboxProps, DropdownProps } from "semantic-ui-react";
import { TtcVenueAdminRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { dsaAreaOptionsSelectorWithAny, unexpiredPoliceDorsOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { venueFilterQuerySelector, venuesSelector } from "../selectors";
import { AllItems } from "./AllItems";
import { routerPathnameSelector } from "@common/crud/common/selectors";
import { toRouterQuery } from "@common/model";
import { SearchOptions, constructionVenueTypeOptions } from "../model";
import { DeliveryType } from "@common/crud/common/DeliveryTypeEnum";
import { optionsFromObject } from "@common/crud/common";
import { WorkflowTypeOptions } from "@common/crud/eventType/model";
import { VenueBaseProps } from "./Base";
import { loadVenues } from "../actions";
import { VenueTypeEnum } from "@common/crud/organisation/model";
import { useCurrentUserRoles } from "@common/hooks/useCurrentUserRoles";

export const All = (props: VenueBaseProps) => {
    const { isCorporate, isConstruction, organisationId } = props;

    const isPoliceOrCourt = !isCorporate && !isConstruction;

    const dispatch = useDispatch();

    const path = useSelector(routerPathnameSelector);
    const venues = useSelector(venuesSelector);
    const filterState = useSelector(venueFilterQuerySelector);
    const policeForces = useSelector(unexpiredPoliceDorsOrganisationOptionsSelector);
    const dsaAreasWithAnyOption = useSelector(dsaAreaOptionsSelectorWithAny);

    const { isBusinessDriverAdmin, isCorporateEventAdmin } = useCurrentUserRoles();

    const [timerId, setTimerId] = React.useState<any>();
    const [venueName, setVenueName] = React.useState("");
    const deliveryTypeOptions = optionsFromObject(DeliveryType, "Any");
    const workflowOptions = React.useMemo(() => WorkflowTypeOptions(isCorporate, isBusinessDriverAdmin, isCorporateEventAdmin, isConstruction, true),
        [isBusinessDriverAdmin, isConstruction, isCorporate, isCorporateEventAdmin]);
    const baseWorkflowOptions = React.useMemo(() => workflowOptions.map(w => w.value as number), [workflowOptions]);

    React.useEffect(() => {
        if (venueName !== (filterState?.name || "")) {
            setVenueName(filterState?.name || "");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState?.name]);

    React.useEffect(() => {
        const options = { ...filterState };

        if (!Array.isArray(options.workflows) || !options.workflows.length) {
            options.workflows = baseWorkflowOptions;
        }

        if (organisationId) {
            if (isCorporate) {
                options.venueType = VenueTypeEnum.CorporateOrganisationSpecific;
            }

            if (isConstruction) {
                options.venueType = VenueTypeEnum.ConstructionOrganisationSpecific;
            }

            options.organisationId = organisationId;
        } else {
            if (isCorporate) {
                options.venueType = VenueTypeEnum.CorporateShared;
            } else if (isPoliceOrCourt) {
                options.venueType = VenueTypeEnum.PoliceAndCourt;
            }
        }

        dispatch(loadVenues({ options }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterState), baseWorkflowOptions]);

    function onVenueNameChange(_: any, data: InputOnChangeData) {
        setVenueName(data.value);

        if (timerId) {
            clearTimeout(timerId);
        }
        const id = setTimeout(() => venueNameChanged(data.value), 300);
        setTimerId(id);
    }

    function onToggleChanged(propertyName: keyof SearchOptions) {
        return function onChange(_: any, { checked }: CheckboxProps) {
            const updatedState = {
                ...filterState,
                [propertyName]: checked
            };

            onFilterStateChange(updatedState);
        };
    }

    function onForceIdChange(_: any, data: InputOnChangeData) {
        onFilterStateChange({ ...filterState, forceId: data.value ? +data.value : undefined });
    }

    function onDsaAreaIdChange(_: any, data: InputOnChangeData) {
        onFilterStateChange({ ...filterState, dsaAreaId: data.value ? +data.value : undefined });
    }

    function onVenueTypeChange(_: any, data: InputOnChangeData) {
        onFilterStateChange({ ...filterState, venueType: data.value ? +data.value : undefined });
    }

    function venueNameChanged(changedName: string) {
        onFilterStateChange({ ...filterState, name: changedName });
    }

    function onDeliveryTypeChange(_: any, data: InputOnChangeData) {
        onFilterStateChange({ ...filterState, deliveryType: data.value ? +data.value : undefined });
    }

    function onWorkflowsChange(_: any, data: DropdownProps ) {
        onFilterStateChange({ ...filterState, workflows: data.value as number[] });
    }

    function onFilterStateChange(changedFilterState: SearchOptions) {
        dispatch(push({ pathname: undefined, query: toRouterQuery(changedFilterState) }));
    }

    return (<>
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <Grid.Column width={14}>
                    <h1>Venues</h1>
                </Grid.Column>
                <Authorize authorize={TtcVenueAdminRole}>
                    <Grid.Column width={2} textAlign="right">
                        <Button icon="add" content="Create" as={Link} href={`${path}/create`} />
                    </Grid.Column>
                </Authorize>
            </Grid.Row>
        </Grid>
        <Grid stackable className="filter-grid" width={16}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Name"
                                placeholder="Name"
                                value={venueName}
                                action={<Button icon="search" />}
                                onChange={onVenueNameChange}
                            />
                        </Form.Group>
                        <Form.Dropdown
                            multiple
                            selection
                            label="Workflow"
                            placeholder="Workflow"
                            onChange={onWorkflowsChange}
                            value={filterState.workflows}
                            options={workflowOptions}
                        />
                        {isPoliceOrCourt && (
                            <>
                                <Form.Dropdown
                                    selection
                                    search
                                    label="Police Force"
                                    placeholder="Police Force"
                                    value={filterState.forceId}
                                    options={[
                                        { text: "Any Police Force", value: "" },
                                        ...policeForces
                                    ]}
                                    onChange={onForceIdChange}
                                />
                                <Form.Dropdown
                                    selection
                                    search
                                    label="DSA Area"
                                    placeholder="DSA Area"
                                    value={filterState.dsaAreaId}
                                    options={dsaAreasWithAnyOption.sort((a,b) => a.text.localeCompare(b.text))}
                                    onChange={onDsaAreaIdChange}
                                />
                            </>
                        )}
                        <Form.Dropdown
                            selection
                            key={filterState.deliveryType}
                            placeholder="Delivery Type"
                            options={deliveryTypeOptions}
                            value={filterState.deliveryType}
                            onChange={onDeliveryTypeChange}
                        />
                        {isConstruction && !organisationId && (
                            <Form.Dropdown
                                selection
                                key={filterState.venueType}
                                placeholder="Venue Type"
                                options={constructionVenueTypeOptions}
                                value={filterState.venueType}
                                onChange={onVenueTypeChange}
                            />
                        )}
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row width={16}>
                <Grid.Column width={3}>
                    <Checkbox
                        label={filterState.showExpired ? "Hide Expired" : "Show Expired"}
                        checked={filterState.showExpired}
                        onChange={onToggleChanged("showExpired")}
                        toggle
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={16}>
                    <AllItems venues={venues} path={path} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </>
    );
};
