import * as React from "react";
import {  Button, Modal } from "semantic-ui-react";
import { v4 } from "uuid";
import { TrainerFee } from "../../model";
import { TrainerPayTable } from "./TrainerPayTable";

interface TrainerPayEditModalProps {
currentTrainerFees?: TrainerFee[];
onChange: (value: any, valid: boolean) => void;
push?: (path: string) => void;
onSubmit?: (trainerFees: TrainerFee[]) => void;
noFeesText?: string;
editButtonText?: string;
}

export interface TrainerFeeFormErrors {
    [trainerFeeId: string]: Set<string>;
}

export const TrainerPayEditModal: React.FC<TrainerPayEditModalProps> = (
    { currentTrainerFees, onChange, push, noFeesText, editButtonText, onSubmit }) => {
    const currentTrainerFeesWithId = React.useMemo(() => currentTrainerFees.map(f => ({ ...f, id: v4() })), [currentTrainerFees]);

    const [open, setOpen] = React.useState(false);
    const [newTrainerFees, setNewTrainerFees] = React.useState<TrainerFee[]>([...currentTrainerFeesWithId]);

    // Map with (key = trainer fee id, value = trainer fee before any edits)
    const [trainerFeesBeingEdited, setTrainerFeesBeingEdited] = React.useState<Map<string, TrainerFee>>(new Map());

    const [formErrors, setFormErrors] = React.useState<TrainerFeeFormErrors>({});
    const valid = Object.keys(formErrors).length === 0;

    function onClose() {
        if (push) {
            push("trainer/edit");
        }

        setOpen(false);
        setTrainerFeesBeingEdited(new Map());
    }

    function onSave() {
        onChange(newTrainerFees, true);

        if (onSubmit) {
            onSubmit(newTrainerFees);
        }

        onClose();
    }

    function onOpen() {
        if (push) {
            push("trainer/feesOpen/edit");
        }
        setNewTrainerFees([...currentTrainerFeesWithId]);
        setOpen(true);
    }

    const trainerFeesLengthDecreased = newTrainerFees.length < currentTrainerFees?.length;

    const savingDisabled = !valid || (trainerFeesBeingEdited?.size === 0 && !trainerFeesLengthDecreased);

    return (
        <Modal
            size={"fullscreen"}
            onClose={onClose}
            onOpen={onOpen}
            trigger={<Button content={editButtonText ?? "Edit"} />}
            open={open}
        >
            <Modal.Content>
                <TrainerPayTable trainerFees={currentTrainerFeesWithId} trainerFeesBeingEdited={trainerFeesBeingEdited}
                    setTrainerFeesBeingEdited={setTrainerFeesBeingEdited} newTrainerFees={newTrainerFees} setNewTrainerFees={setNewTrainerFees}
                    push={push} formErrors={formErrors} setFormErrors={setFormErrors} editable noFeesText={noFeesText} />
                <Modal.Actions>
                    <Button
                        content="Close"
                        className="cancel-action"
                        onClick={onClose}
                    />
                    <Button
                        content="Save"
                        positive
                        onClick={onSave}
                        disabled={savingDisabled}
                    />
                </Modal.Actions>
            </Modal.Content>
        </Modal>
    );};
