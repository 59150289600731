import { TypedTable } from "@common/crud/common/TypedTable";
import * as React from "react";
import { TrainerInvoiceBonusLineItem, TrainerInvoiceBonusTypeEnum } from "../model";

export interface InvoiceBonusLineItemsProps {
    bonusLineItems: TrainerInvoiceBonusLineItem[];
    showPrintableFooter?: boolean;
}

export const InvoiceBonusLineItemsTable = ({ bonusLineItems, showPrintableFooter = true }: InvoiceBonusLineItemsProps) => {
    function getTypeTextForBonusLineItem(bonusLineItem: TrainerInvoiceBonusLineItem) {
        if (bonusLineItem.type === TrainerInvoiceBonusTypeEnum.Uplift) {
            return "Course Uplift";
        }

        return `Tier ${bonusLineItem.tierNumber} Bonus (${bonusLineItem.totalCourses} sessions)`;
    }

    return (
        <div className="course-details">
            <h3>Bonuses</h3>
            <TypedTable
                emptyValuesArrayMessage="No bonuses found"
                values={bonusLineItems.filter(b => b.type !== TrainerInvoiceBonusTypeEnum.None)}
                headerClassName="table-header-row"
                footerClassName="table-footer-row"
                showPrintableFooter={showPrintableFooter}
            >
                {
                    [
                        {
                            header: "Date",
                            value: v => v.date.format("MMMM YYYY")
                        },
                        {
                            header: "Bonus Type",
                            value: v => getTypeTextForBonusLineItem(v)
                        },
                        {
                            header: "Fee",
                            value: v => `£${v.fee.toFixed(2)}`
                        }
                    ]
                }
            </TypedTable>
        </div>
    );
};
