import * as React from "react";
import { useElements, useStripe, CardNumberElement } from "@stripe/react-stripe-js";
import { StartPaymentProcessRequest, PaymentResponse, PaymentRequest } from "@common/payments/model";
import { AsyncDispatch } from "@common/redux-helpers";
import { debounce } from "@neworbit/simpleui-utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { push } from "redux-little-router";
import { Button, Grid, Container, Segment, } from "semantic-ui-react";
import { makePayment } from "@common/payments/actions";
import { bookingBasePathSelector } from "../../landing/selectors";
import { ChosenCourse } from "../../global/ChosenCourse";
import { BookingStages } from "../../global/BookingStages";
import { View } from "../../global/ViewEnum";
import { ClientAdvisorHeader } from "../../global/ClientAdvisorHeader";
import { eventInstanceSelector } from "../../eventInstance/selectors";
import { StripeContainer } from "./StripeContainer";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import { useClearBeforeGenesysAuthPage, useReportCallRecordingStatus, useResumeCallRecordingOnExit } from "@common/genesys";
import { usePauseCallRecordingOnEntry } from "@common/genesys/hooks/usePauseCallRecordingOnEntry";
import { Media } from "@common/global/AppMedia";
import { useScrollToTop } from "@common/hooks/useScrollToTop";
import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { useClearAdvancedFilter } from "@booking/landing/hooks/useClearAdvancedFilter";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import {
    useClearDeliveryTypeFilterInSessionStorage
} from "@booking/landing/hooks/useClearDeliveryTypeFilterInSessionStorage";
import { ApplicationState } from "@booking/applicationState";
import { Booking } from "@booking/bookings/models";
import { OrganisationDetailModel } from "@common/crud/organisation/model";
import { loadPoliceOrganisationDetailsForBooking } from "@booking/eventInstance/actions";

interface StateProps {
    booking: Booking;
    organisation: OrganisationDetailModel;
}

interface DispatchProps {
    proceedWithPayment: (paymentRequest: PaymentRequest) => Promise<PaymentResponse>;
    redirect: (url: string) => void;
}

interface OwnProps {
    startPaymentProcess?: StartPaymentProcessRequest;
}

type PaymentReviewProps = WithTranslation & StateProps & DispatchProps & OwnProps;

const PaymentReviewInternal: React.FC<PaymentReviewProps> = (props) => {
    const dispatch = useDispatch();

    const { t, startPaymentProcess, booking, organisation } = props;
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [pauseCallRecordingCheck, setPauseCallRecordingCheck] = React.useState(false);
    const isClientAdvisor = useSelector(isUserClientAdvisor);
    useReportCallRecordingStatus(isClientAdvisor, startPaymentProcess.correlationId, pauseCallRecordingCheck);

    const eventInstance = useSelector(eventInstanceSelector);
    const path = useSelector(bookingBasePathSelector);
    const stripe = useStripe();
    const elements = useElements();

    React.useEffect(() => {
        if (!organisation && booking && booking.forceId) {
            dispatch(loadPoliceOrganisationDetailsForBooking({ forceId: booking.forceId }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking]);

    useScrollToTop();

    const submit = React.useCallback(async (ev: any) => {
        setDisabled(true);
        ev.preventDefault();
        setErrorMessage(null);

        const card = elements.getElement(CardNumberElement);

        const paymentMethodData = await stripe.createPaymentMethod({ type: "card", card });

        if (paymentMethodData.paymentMethod) {
            const paymentRequest: PaymentRequest = {
                paymentMethodId: paymentMethodData.paymentMethod.id,
                paymentIntentId: "",
                amount: startPaymentProcess.amount,
                eventInstanceId: startPaymentProcess.eventInstanceId,
                startPaymentProcessMultiPartModels: startPaymentProcess.startPaymentProcessMultiPartModels,
                orderId: startPaymentProcess.orderId,
                correlationId: startPaymentProcess.correlationId,
                plan: startPaymentProcess.plan,
                waivedRebookingFee: startPaymentProcess.waivedRebookingFee,
                isNewBooking: startPaymentProcess.isNewBooking,
                eventTypeCategory: eventInstance?.workflowType === WorkflowTypeEnum.DDRS ? EventTypeCategory.Ddrs : EventTypeCategory.Dors
            };

            let stripePaymentResponse: PaymentResponse;
            try {
                setPauseCallRecordingCheck(true);
                stripePaymentResponse = await props.proceedWithPayment(paymentRequest);
            }
            finally {
                setPauseCallRecordingCheck(false);
            }

            if (stripePaymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                handleAction(stripePaymentResponse);
            } else if (stripePaymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else {
                handleRedirect(startPaymentProcess.isOutstandingBalancePayment);
            }
        }
        if (paymentMethodData.error) {
            showErrorAndEnable(paymentMethodData.error.message);
        }
    }, [stripe, props.startPaymentProcess, props.proceedWithPayment, eventInstance]);

    useClearBeforeGenesysAuthPage();

    usePauseCallRecordingOnEntry(startPaymentProcess.correlationId);

    // Restart call recording if we leave the page
    useResumeCallRecordingOnExit(startPaymentProcess.correlationId);

    const handleAction = async (response: PaymentResponse) => {

        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
            response.paymentIntentClientSecret
        );

        if (!isNullOrUndefined(errorAction)) {
            showErrorAndEnable(errorAction.message ?? t("PAYMENT_FAILED"));
        } else {
            const paymentRequest: PaymentRequest = {
                paymentMethodId: "",
                paymentIntentId: paymentIntent.id,
                amount: startPaymentProcess.amount,
                eventInstanceId: startPaymentProcess.eventInstanceId,
                startPaymentProcessMultiPartModels: startPaymentProcess.startPaymentProcessMultiPartModels,
                orderId: startPaymentProcess.orderId,
                correlationId: startPaymentProcess.correlationId,
                plan: startPaymentProcess.plan,
                waivedRebookingFee: startPaymentProcess.waivedRebookingFee,
                isNewBooking: startPaymentProcess.isNewBooking
            };

            const stripePaymentResponse = await props.proceedWithPayment(paymentRequest);

            if (stripePaymentResponse.validRequest === false) {
                showErrorAndEnable();
            } else if (stripePaymentResponse.requiresAction) {
                // Use Stripe.js to handle required card action
                await handleAction(stripePaymentResponse);
            } else {
                handleRedirect(startPaymentProcess.isOutstandingBalancePayment);
            }
        }
    };

    const showErrorAndEnable = (currErrorMessage?: string) => {
        setShowError(true);
        setDisabled(false);

        if (currErrorMessage) {
            setErrorMessage(currErrorMessage);
        }
    };

    const handleRedirect = (isOutstandingBalancePayment: boolean) => {
        useClearAdvancedFilter(booking?.id);
        useClearDeliveryTypeFilterInSessionStorage();
        if (isClientAdvisor) {
            props.redirect(`${path}/ca-redirect?outstandingBalancePayment=${isOutstandingBalancePayment}`);
        } else {
            if (!isOutstandingBalancePayment) {
                props.redirect(`${path}/booking-confirmation?finishDateTime=${new Date().toISOString()}`);
            } else {
                props.redirect(`${path}/payment-confirmation?finishDateTime=${new Date().toISOString()}`);
            }
        }
    };

    const getStripeContainer = () => {
        return (
            <StripeContainer
                t={t}
                showError={showError}
                errorMessage={errorMessage}
            />);
    };

    return (
        <>
            <Segment>
                <ClientAdvisorHeader showDetailedInfo={true} currentView={View.PaymentReview} />
                <h3>{t("PAYMENT_DETAILS")}</h3>
                <p>{t("ENTER_CARD_DETAILS")}</p>
            </Segment>
            {/* desktop*/}
            <Media greaterThanOrEqual="tablet">
                <BookingStages activeStage={3} paymentRequired />
                <Grid className="two-section-layout">
                    <Grid.Row>
                        <Grid.Column width={10} className="two-section-layout-left border-top">
                            <Container className="indented">
                                {getStripeContainer()}
                                <span>
                                    <img src="/assets/stripe-logo.png" className="stripe-logo" alt="Powered by Stripe" />
                                </span>
                                <span className="float-right accepted-cards">
                                    <img src="/assets/2_Card_color_horizontal.png" alt="Mastercard Visa" />
                                </span>
                            </Container>
                        </Grid.Column>
                        <Grid.Column width={6} className="two-section-layout-right booking-details-column">
                            <Grid.Row>
                                <ChosenCourse
                                    eventInstance={eventInstance}
                                    fullDetails={true}
                                    showLinks={false}
                                    waivedRebookingFee={startPaymentProcess.waivedRebookingFee}
                                    payAmount={startPaymentProcess.amount}
                                    currentView={View.PaymentReview}
                                    scheduledPaymentAmount={
                                        startPaymentProcess.plan && startPaymentProcess.plan.reduce((sum, x) => sum + x.amount, 0) || undefined
                                    }
                                    organisation={organisation}
                                />
                            </Grid.Row>
                            <Button className="full-width payment-button" disabled={disabled} onClick={submit}>{t("PAY")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Media>

            {/* mobile*/}
            <Media lessThan="tablet">
                <Grid className="mobile-container">
                    <Grid.Row>
                        <Grid.Column width={16} className="booking-details margin-bottom">
                            <Grid.Row>
                                {getStripeContainer()}
                                <ChosenCourse
                                    eventInstance={eventInstance}
                                    fullDetails={true}
                                    showLinks={false}
                                    payAmount={startPaymentProcess.amount}
                                    waivedRebookingFee={startPaymentProcess.waivedRebookingFee}
                                    currentView={View.PaymentReview}
                                    scheduledPaymentAmount={
                                        startPaymentProcess.plan && startPaymentProcess.plan.reduce((sum, x) => sum + x.amount, 0) || undefined
                                    }
                                    organisation={organisation}
                                />
                                <Button className="full-width" disabled={disabled} onClick={submit}>{t("PAY")}</Button>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Media>
        </>
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    const { booking, organisations } = state;
    const organisation = booking?.forceId ? organisations.find(e => e.dorsId === booking.forceId) : undefined;
    return {
        organisation,
        booking,
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch): DispatchProps {
    return {
        // We need to be careful with the wait value here. If the server can respond in a quicker time than wait value
        // then the UI will unlock and the user can press pay again. But this will be blocked by the debounce and
        // as submit appears to abort after the call to proceedWithPayment, the user will see no messages and the pay button will then remain locked.
        proceedWithPayment: debounce((paymentRequest: PaymentRequest) => dispatch(makePayment(paymentRequest)), 500, true),
        redirect: (url) => dispatch(push(url)),
    };
}

export const PaymentReview = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation("PaymentReview")(PaymentReviewInternal));
