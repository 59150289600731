import * as React from "react";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { TrainerAttributeComplianceStatus } from "@common/crud/common/TrainerAttributeComplianceStatus";
import { TypedTable, TypedTableRowProps } from "@common/crud/common/TypedTable";

export interface ComplianceAttributesTableProps {
    attributes: TrainerAttribute[];
    attributeCategory: string;
}

interface ExtendedTrainerAttribute  extends TrainerAttribute {
    id: string;
}

export const ComplianceAttributesTable: React.FC<ComplianceAttributesTableProps> = ({ attributes, attributeCategory }) => {

    const [ extendedTrainerAttributes, setExtendedTrainerAttributes] = React.useState<ExtendedTrainerAttribute[]>([]);
    React.useEffect(() => {
        setExtendedTrainerAttributes(attributes?.map((e: TrainerAttribute): ExtendedTrainerAttribute => ({
            id: e.attributeDefinitionId,
            ...e
        })));
    }, [attributes]);

    const columns: TypedTableRowProps<ExtendedTrainerAttribute>[] = [
        {
            header: "Name",
            value: (e) => e.name
        },
        {
            header: "Status",
            value: (e) => TrainerAttributeComplianceStatus[e.complianceStatus]
        },
    ];

    return (
        <TypedTable values={extendedTrainerAttributes} emptyValuesArrayMessage={`No ${attributeCategory} attributes`} >
            {columns}
        </TypedTable>
    );
};
