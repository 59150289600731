import axios from "axios";
import { toast } from "react-toastify";
import { AdminRecordedActionType, GenesysCliResponse, GenesysOperationResponse, GenesysTokenCreateEditModel } from "./model";
import { GenesysCallState } from "./model";
import * as QueryString from "query-string";

export class GenesysApi {
    private readonly apiUrl = "/api/genesys";

    public async getCallState(correlationid: string): Promise<GenesysCallState> {
        const response = await axios.get(`${this.apiUrl}/${correlationid}/callstate`);
        return response.data as GenesysCallState;
    }

    public async RecordCaAction(token: string, correlationid: string, actionType: AdminRecordedActionType): Promise<GenesysCallState> {
        const query = QueryString.stringify({ actionType, token });
        const response = await axios.post(`${this.apiUrl}/${correlationid}/ca-action?${query}`);
        return response.data as GenesysCallState;
    }

    public async updateToken(token: GenesysTokenCreateEditModel): Promise<GenesysOperationResponse> {
        const response = await axios.put(this.apiUrl, token);
        const model = response.data as GenesysOperationResponse;
        if (!model.success) {
            toast.error(model.errorMessage);
        }
        return model;
    }

    public async resumeCallRecording(correlationId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${correlationId}/resumeRecording`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }

    public async pauseCallRecording(correlationId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${correlationId}/pauseRecording`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }

    public async auditAuthorisationBypass(correlationId: string): Promise<GenesysOperationResponse> {
        const response = await axios.post(`${this.apiUrl}/${correlationId}/auditAuthorisationBypass`);
        const model = response.data as GenesysOperationResponse;
        return model;
    }

    public async getCurrentCli(token: string): Promise<GenesysCliResponse> {
        const response = await axios.get(`${this.apiUrl}/currentCli`, { params: { token } });
        return response.data as GenesysCliResponse;
    }
}
