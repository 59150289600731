/* eslint-disable no-nested-ternary */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, DropdownProps, Form } from "semantic-ui-react";
import { Modal } from "@common/components";
import { cancelCorporateBooking } from "../../actions";
import { OtherCancellationReason } from "@common/crud/eventInstance/model";
import { Input } from "@neworbit/simpleui-input";
import { validators } from "not-valid";
import { add } from "date-fns";
import { Moment } from "moment";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { BusinessLineSupportsHalfRefund, GetRefundFullPeriodForBusinessLine, GetRefundHalfPeriodForBusinessLine,
    OrderRefundTypeEnum } from "@common/crud/order/model";

export interface CancelBookingModalProps {
    attendeeId: string;
    eventInstanceId: string;
    delegateId: string;
    organisationId: string;
    isBookNowPayLaterOrder: boolean;
    eventInstanceDeliveryDateTime: Moment;
}

export const CancelCorporateBookingModal: React.SFC<CancelBookingModalProps> = ({
    attendeeId,
    eventInstanceId,
    delegateId,
    organisationId,
    isBookNowPayLaterOrder,
    eventInstanceDeliveryDateTime }) => {
    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineSupportsHalfRefunds = BusinessLineSupportsHalfRefund(businessLineType);
    const fullPeriodForBussinesLine = GetRefundFullPeriodForBusinessLine(businessLineType);
    const halfPeriodForBussinesLine = GetRefundHalfPeriodForBusinessLine(businessLineType);

    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [cancellationReason, setCancellationReason] = React.useState<string>();
    const [otherReason, setOtherReason] = React.useState<string>();
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const [paymentForCancelledPlacesRequired, setPaymentForCancelledPlacesRequired] = React.useState<OrderRefundTypeEnum>(
        (eventInstanceDeliveryDateTime != null
            && eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: fullPeriodForBussinesLine }))
            ? OrderRefundTypeEnum.Full
            : (businessLineSupportsHalfRefunds && eventInstanceDeliveryDateTime != null
                && eventInstanceDeliveryDateTime.toDate() < add(new Date(), { days: halfPeriodForBussinesLine }))
                ? OrderRefundTypeEnum.Half
                : OrderRefundTypeEnum.None
    );

    const otherCancellationReasonChoosen = cancellationReason === OtherCancellationReason;

    const dispatch = useDispatch();

    const togglePaymentForCancelledPlacesRequired = React.useCallback(() => {
        setPaymentForCancelledPlacesRequired(paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Full
            ? OrderRefundTypeEnum.None
            : OrderRefundTypeEnum.Full);
    }, [paymentForCancelledPlacesRequired]);

    const changePaymentForCancelledPlacesRequired = React.useCallback((_: any, d: DropdownProps) =>
        setPaymentForCancelledPlacesRequired(d.value as number), []);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(
            cancelCorporateBooking(
                eventInstanceId,
                attendeeId,
                delegateId,
                organisationId,
                cancellationReason,
                otherReason,
                paymentForCancelledPlacesRequired));
        setOpen(false);
    }, [dispatch, eventInstanceId, attendeeId, delegateId, organisationId, cancellationReason, otherReason, paymentForCancelledPlacesRequired]);

    const onOpenCancelModalClick = React.useCallback(() => setOpen(true), []);

    const onCancelClick = React.useCallback(() => {
        setOpen(false);
        setOtherReason(null);
        setOtherReasonValid(true);
    }, []);

    const onReasonChange = React.useCallback((_: any, d: DropdownProps) => setCancellationReason(d.value as string), []);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    return (
        <>
            <Button onClick={onOpenCancelModalClick}>Cancel</Button>
            <Modal open={open}>
                <Modal.Header>
                    Cancellation
                </Modal.Header>
                <Modal.Content>
                    <Form error={submitted && !cancellationReason}>
                        <Form.Select
                            options={[
                                { text: "Other", value: OtherCancellationReason },
                                { text: "Delegate/Organisation decision", value: "DelegateOrganisationDecision" },
                                { text: "Did not attend", value: "AttendeeDidNotAttendCourse" },
                            ]}
                            error={submitted && !cancellationReason}
                            value={cancellationReason}
                            label={"What is the reason for cancelling?"}
                            onChange={onReasonChange}
                        />
                        {otherCancellationReasonChoosen && (
                            <Input.Textarea
                                value={otherReason}
                                label="Other Reason"
                                validation={[validators.validLength({ max: 500 })]}
                                onChange={onOtherReasonChanged}
                            />
                        )}
                        {
                            (!businessLineSupportsHalfRefunds && isBookNowPayLaterOrder) && <Checkbox
                                className="refund-checkbox"
                                toggle
                                label="Does the Booker/Organisation need to pay for these cancelled seats?"
                                defaultChecked={paymentForCancelledPlacesRequired === OrderRefundTypeEnum.Full}
                                onChange={togglePaymentForCancelledPlacesRequired}
                            />
                        }
                        {
                            (businessLineSupportsHalfRefunds && isBookNowPayLaterOrder) && <Form.Select
                                options={[
                                    { text: "0%", value: OrderRefundTypeEnum.None },
                                    { text: "50%", value: OrderRefundTypeEnum.Half },
                                    { text: "100%", value: OrderRefundTypeEnum.Full },
                                ]}
                                value={paymentForCancelledPlacesRequired}
                                label={"How much does the Booker/Organisation need to pay for these cancelled seats?"}
                                onChange={changePaymentForCancelledPlacesRequired}
                            />
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelClick} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        disabled={!cancellationReason || (otherCancellationReasonChoosen && !otherReasonValid)}
                        primary
                    >
                        Confirm Cancellation
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
