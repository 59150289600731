import * as React from "react";
import { useSelector } from "react-redux";
import { Area } from "@common/crud/eventInstance/model";
import { trainerScheduleSelector } from "@common/crud/eventInstance/selectors";
import { Link } from "redux-little-router";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";

export interface TrainerMultiDayPartMenuItemProps {
    area: Area;
    workflowType: WorkflowTypeEnum;
    eventInstanceId: string;
    children: string;
}

export const TrainerMultiDayPartMenuItem: React.FC<TrainerMultiDayPartMenuItemProps> = ({ area, workflowType, eventInstanceId, children }) => {
    const basePath = useSelector(trainerScheduleSelector);
    const href = workflowType === WorkflowTypeEnum.DDRS
        ? area === Area.AdminEventManagementTrainerEventInstance
            ? `${basePath}/schedule-drink-drive/${eventInstanceId}/attendees`
            : `${basePath}/drink-drive/${eventInstanceId}/attendees`
        : area === Area.AdminEventManagementTrainerEventInstance
            ? `${basePath}/schedule/${eventInstanceId}/delegates`
            : `${basePath}/eventInstances/${eventInstanceId}/delegates`;

    return <Link href={href} className="button-search-menu-item" activeProps={{ className: "button-search-menu-item current" }}>Day {children}</Link>;

};
