import * as React from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Order } from "../model";
import { changeBooker } from "../actions";
import { CorporateUserApi } from "@common/crud/corporateUser/corporateUserApi";
import { CorporateUserListModel } from "@common/crud/corporateUser/model";
import { Input } from "@neworbit/simpleui-input";

export interface ChangeBookerModalProps {
    order: Order;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChangeBookerModal: React.FC<ChangeBookerModalProps> = ({ order, setRefreshingRecord }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [bookerId, setBookerId] = React.useState<string>(order.bookerId);
    const [corporateUsers, setCorporateUsers] = React.useState<CorporateUserListModel[]>([]);
    const [submitted, setSubmitted] = React.useState(false);

    const dispatch = useDispatch();

    const corporateUserOptions = React.useMemo(() =>
        (order.bookerId && bookerId === order.bookerId &&
            !corporateUsers.some(user => user.id === order.bookerId)
            ? [...corporateUsers,
                {
                    id: order.bookerId,
                    fullName: order.bookerName,
                    email: order.bookerEmail
                }
            ] : corporateUsers).map(booker => ({ text: `${booker.fullName} [${booker.email}]`, value: booker.id }))
    , [corporateUsers, order, bookerId]);

    const onBookerChange = React.useCallback((userId: string) => {
        setBookerId(userId);
    }, []);

    const openCloseAction = React.useCallback(async () => {
        if (!open) {
            setSubmitted(false);
            setBookerId(order.bookerId);
            setOpen(!open);
            const corporateUserApi = new CorporateUserApi();
            setCorporateUsers(await corporateUserApi.getAll({ organisationId: order.organisationId, showExpired: false }));
        } else {
            setOpen(!open);
        }
    }, [open, order]);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(changeBooker(order.id, bookerId));
        setOpen(false);
        setRefreshingRecord(true);
    }, [bookerId, dispatch, order, setRefreshingRecord]);

    return (
        <>
            <Button onClick={openCloseAction} className="link-button">CHANGE BOOKER</Button>
            <Modal open={open}>
                <Modal.Header>
                    Change booker on order
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            Please use the below dropdown to choose the new Booker for this Order
                        </Form.Field>
                        <Input.Dropdown
                            value={bookerId}
                            label="Booker"
                            options={corporateUserOptions}
                            showErrors={submitted}
                            onChange={onBookerChange}
                            dynamicOptions
                            search
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        loading={submitted}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
