import { Input } from "@neworbit/simpleui-input";
import moment from "moment";
import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { MuiDateField } from "@common/components/MuiDateField";
import { DrivingLicenceCountry, DrivingLicenceCountryEnum, VocationalLicenceCategory } from "@common/crud/delegate/model";
import { AttendeeListModel } from "@common/crud/attendee";
import "../UpdateDelegatesTable.scss";
import { ExtendedDropdownMulti, ExtendedDropdownNumber } from "@common/components/ExtendedDropdown";

interface LicenceDetailsProps {
    isEditable: boolean;
    fieldsForMarkup: string[];
    delegate: AttendeeListModel;
    onDrivingLicenceCountryChange: (value: number, valid: boolean) => void;
    isEventInstanceOpen: boolean;
    onDrivingLicenceNumberChange: (value: string, valid: boolean) => void;
    driverLicenceValidation: any;
    driverLicenceNumberKey: string;
    vocationalLicenceCategoryOptions: {
        text: any;
        value: number;
    }[];
    isEventInstanceCpc: boolean;
    isEventInstanceAfv: boolean;
    submitted: boolean;
    onVocationalLicenceCategoriesArrayChange: (value: number[], valid: boolean) => void;
    onDrivingLicenceExactCountryChange: (value: string, valid: boolean) => void;
    onDateOfBirthChange: (value: moment.Moment, valid: boolean) => void;
}

export function LicenceDetails({
    isEditable,
    fieldsForMarkup,
    delegate,
    onDrivingLicenceCountryChange,
    isEventInstanceOpen,
    onDrivingLicenceNumberChange,
    driverLicenceValidation,
    driverLicenceNumberKey,
    vocationalLicenceCategoryOptions,
    onVocationalLicenceCategoriesArrayChange,
    onDrivingLicenceExactCountryChange,
    onDateOfBirthChange,
    isEventInstanceCpc,
    isEventInstanceAfv,
    submitted
}: LicenceDetailsProps) {

    return (
        <Grid className="register-field-grid">
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header">
                    Origin
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === "drivingLicenceCountry") ? "amber-field" : ""}>
                    {isEditable
                        ? <ExtendedDropdownNumber
                            value={delegate.drivingLicenceCountry ?? null}
                            options={Object.keys(DrivingLicenceCountry).filter(k => +k !== 0).map(k => ({ text: DrivingLicenceCountry[k], value: +k }))}
                            onChange={onDrivingLicenceCountryChange}
                            placeholder="Origin"
                            disabled={isEventInstanceOpen && !delegate.organisationId}
                            search
                            showErrors={submitted}
                            withoutLabel
                        />
                        : delegate.drivingLicenceCountry
                            ? <span className="break-all">{DrivingLicenceCountry[delegate.drivingLicenceCountry]}</span>
                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                </Grid.Column>
            </Grid.Row>
            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                <Grid.Row className="purple-header">
                    Licence Number
                </Grid.Row>
            </Grid.Column>
            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                <Grid.Column className={fieldsForMarkup.some(f => f === "drivingLicenceNumber") ? "amber-field" : ""}>
                    {isEditable
                        ? <Input.Text
                            value={delegate.drivingLicenceNumber ?? ""}
                            onChange={onDrivingLicenceNumberChange}
                            placeholder="Ref"
                            validation={driverLicenceValidation}
                            key={driverLicenceNumberKey}
                            disabled={!delegate.drivingLicenceCountry || (isEventInstanceOpen && !delegate.organisationId)}
                            showErrors={submitted}
                        />
                        : delegate.drivingLicenceNumber
                            ? <span className={`${delegate.drivingLicenceCountry !== +DrivingLicenceCountryEnum.GB? "break-all": ""}`}>
                                {delegate.drivingLicenceNumber}</span>
                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                </Grid.Column>
            </Grid.Row>
            {(isEventInstanceCpc && delegate.drivingLicenceCountry === DrivingLicenceCountryEnum.NonGBNI) && (
                <>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header">
                            Country
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column className={fieldsForMarkup.some(f => f === "drivingLicenceExactCountry") ? "amber-field" : ""}>
                            {isEditable
                                ? <Input.Text
                                    value={delegate.drivingLicenceExactCountry ?? ""}
                                    onChange={onDrivingLicenceExactCountryChange}
                                    placeholder={"Country"}
                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                    showErrors={submitted}
                                />
                                : delegate.drivingLicenceExactCountry
                                    ? <span>{delegate.drivingLicenceExactCountry}</span>
                                    : <Icon className="validation-icon" name={"exclamation circle"} />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header">
                            Date of Birth
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column className={fieldsForMarkup.some(f => f === "dateOfBirth") ? "amber-field" : ""}>
                            {isEditable
                                ? <MuiDateField
                                    value={delegate.dateOfBirth}
                                    placeholder="Date of Birth"
                                    onChange={onDateOfBirthChange}
                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                    showErrors={submitted}
                                />
                                : delegate.dateOfBirth
                                    ? <span>
                                        {delegate.dateOfBirth ? delegate.dateOfBirth.format("DD/MM/YYYY") : ""}
                                    </span>
                                    : <Icon className="validation-icon" name={"exclamation circle"} />}
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
            {(isEventInstanceCpc || isEventInstanceAfv) && (
                <>
                    <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                        <Grid.Row className="purple-header">
                            Vocational Licence
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                        <Grid.Column className={fieldsForMarkup.some(f => f === "vocationalLicenceCategories") ? "amber-field" : ""}>
                            {isEditable
                                ? <ExtendedDropdownMulti
                                    value={delegate.vocationalLicenceCategories}
                                    options={vocationalLicenceCategoryOptions}
                                    onChange={onVocationalLicenceCategoriesArrayChange}
                                    dynamicOptions
                                    placeholder="Vocational Licence"
                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                    multiple
                                    search
                                    showErrors={submitted}
                                />
                                : (delegate.vocationalLicenceCategories && Array.isArray(delegate.vocationalLicenceCategories)
                                && delegate.vocationalLicenceCategories.length > 0)
                                    ? <span className="break-all">
                                        {delegate.vocationalLicenceCategories
                                            .filter(c => vocationalLicenceCategoryOptions.some(o => o.value === c))
                                            .map(c => VocationalLicenceCategory[c]).join(", ")}
                                    </span>
                                    : <Icon className="validation-icon" name={"exclamation circle"} />}
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
        </Grid>);
}
