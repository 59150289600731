import * as React from "react";
import { Link, State as RouterState  } from "redux-little-router";
import { Button } from "semantic-ui-react";
import { TrainerInvoiceApi } from "../trainerInvoiceApi";
import { InvoicesTable } from "./InvoicesTable";
import { useSelector } from "react-redux";
import { InvoiceListModel } from "../model";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { getBusinessLineTypePath } from "@common/global/CommonHelpers";

export const Invoices: React.FC = () => {
    const [canSubmitInvoice, setCanSubmitInvoice] = React.useState<boolean>(null);
    const [invoices, setInvoices] = React.useState<InvoiceListModel[]>(null);
    const [dataLoaded, setDataLoaded] = React.useState(false);

    const trainerId = useSelector<RouterState>(state => state.router.params.trainerId) as string;
    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);

    React.useEffect(() => {
        if (!dataLoaded) {
            const getData = async () => {
                const api = new TrainerInvoiceApi(trainerId);

                setCanSubmitInvoice(await api.checkCanSubmitInvoice());
                setInvoices(await api.getInvoices());
                setDataLoaded(true);
            };

            getData();
        }
    }, [canSubmitInvoice, trainerId, dataLoaded]);

    const generateInvoiceDetailLink = React.useCallback((id: string) => {
        return `${businessLineTypePath}/trainers/${trainerId}/invoices/${id}`;
    }, [businessLineTypePath, trainerId]);

    if (!dataLoaded) {
        return <></>;
    }

    return (
        <>
            {
                canSubmitInvoice &&
                <>
                    <br />
                    <Button as={Link} href={`${businessLineTypePath}/trainers/${trainerId}/invoices/preview`}>
                        Preview New Invoice
                    </Button>
                </>
            }
            <InvoicesTable
                invoices={invoices}
                getInvoiceDetailLink={generateInvoiceDetailLink}
            />
        </>
    );
};
