import { toast } from "@common/toasts";
import * as React from "react";
import { Trans } from "react-i18next";

export const ReservationError: React.FC = () => {

    const historyGo = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        // should go back to the landing page.
        window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf("/"));
    }, []);

    React.useEffect(() => window.onpopstate = () => {
        toast.warning("Sorry this course is no longer available, please use the link below to return to the Search page"); history.forward(); },
    []);

    return (
        <Trans i18nKey="ReservationError:COURSE_NOT_AVAILABLE">
            "We're sorry this course is no longer available, please
            <a className="primary-link" href="#" onClick={historyGo}>
                click here
            </a>
                 to view alternative courses
        </Trans>
    );
};
