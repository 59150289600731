import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";
import { isNullOrUndefined, isArrayNullOrEmpty } from "@common/global/CommonHelpers";
import { SpecialRequirementAttendee } from "../../model";

function noSpecialRequirements(specialRequirements: SpecialRequirements) {
    if (isNullOrUndefined(specialRequirements)) {
        return true;
    }

    return isArrayNullOrEmpty(specialRequirements.eventAttributes) &&
        isArrayNullOrEmpty(specialRequirements.venueAttributes) &&
        isNullOrUndefined(specialRequirements.otherRequirements);
}

export function hasNoSpecialRequirements(specialRequirementAttendee: SpecialRequirementAttendee[]) {

    if (isNullOrUndefined(specialRequirementAttendee) || specialRequirementAttendee.length === 0) {
        return true;
    }

    return specialRequirementAttendee
        .every(s => noSpecialRequirements(s.specialRequirements));
}

export function filterEmptySpecialRequirements(specialRequirementAttendees: SpecialRequirementAttendee[]) {
    return specialRequirementAttendees?.length > 0
        && specialRequirementAttendees.filter(s => (
            s?.specialRequirements?.hasHearingImpairment
                || (s?.specialRequirements?.eventAttributes?.length
                || s?.specialRequirements?.venueAttributes?.length
                || s?.specialRequirements?.otherRequirements?.length) > 0)) || [];
}
