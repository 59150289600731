/* eslint-disable react/jsx-no-bind */
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { EventInstanceAttendeeField } from "@common/crud/eventInstance/model";
import { AttendeeFieldValue } from "@common/crud/attendee/model";
import { AttendeeFieldTypeEnum } from "@common/crud/organisation/model";

interface AttendeeFieldProps {
    fieldConfiguration: EventInstanceAttendeeField;
    fieldValues: AttendeeFieldValue[];
    onAttendeeFieldValueChange: (value: any, fieldConfiguration: EventInstanceAttendeeField) => void;
}

const FieldComponent = (fieldConfiguration: EventInstanceAttendeeField, value: any,
    onAttendeeFieldValueChange: (value: any, fieldConfiguration: EventInstanceAttendeeField) => void) => {
    switch (fieldConfiguration.type) {
        case AttendeeFieldTypeEnum.Text:
            const onStringValueChange = (stringValue: string) => onAttendeeFieldValueChange(stringValue, fieldConfiguration);
            return (<Input.Text
                value={value}
                onChange={onStringValueChange}
                label={fieldConfiguration.displayName}
            />);
        case AttendeeFieldTypeEnum.Number:
            const onIntegerValueChange = (numberValue: number) => onAttendeeFieldValueChange(numberValue, fieldConfiguration);
            return (<Input.Number
                value={value}
                onChange={onIntegerValueChange}
                label={fieldConfiguration.displayName}
            />);
        default:
            return undefined;
    }
};

export function DelegateAttendeeField({
    fieldConfiguration,
    fieldValues,
    onAttendeeFieldValueChange
}: AttendeeFieldProps) {
    const value = fieldValues?.find(v => v.name === fieldConfiguration.fieldId)?.value ?? undefined;

    return FieldComponent(fieldConfiguration, value, onAttendeeFieldValueChange);
};

