import { Venue } from "@common/crud/venue";
import { Payload } from "@neworbit/redux-helpers";
import * as actions from "./actiontypes";
import { ApplicationState } from "../applicationState";
import { bookingIdSelector } from "../landing/selectors";
import { loadAndTrack } from "redux-request-loading";
import { Dispatch } from "redux";
import { BookingVenueApi } from "./bookingVenueApi";
import { appSelector, isUserClientAdvisor } from "@common/crud/common/selectors";

export type VenueAction =
    ({ type: typeof actions.LOAD_VENUE_DETAIL_SUCCESS } & Payload<Venue>) |
    ({ type: typeof actions.LOAD_ALL_VENUES_SUCCESS } & Payload<Venue[]>);

const loadVenueDetailSuccess = (venue: Venue) => ({
    payload: venue,
    type: actions.LOAD_VENUE_DETAIL_SUCCESS
});

export const loadVenuesSuccess = (venues: Venue[]) => ({
    payload: venues,
    type: actions.LOAD_ALL_VENUES_SUCCESS
});

export function loadVenueDetail({ venueId }: { venueId: string }) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        if (!venueId) {
            return;
        }

        const venue = getState().venues.filter(c => c.id === venueId)[0];
        const isCa = isUserClientAdvisor(getState());

        if (venue === undefined
            || venue.detailUpdated === undefined
            || venue.detailUpdated.isBefore(venue.listUpdated)) {
            const bookingId = bookingIdSelector(getState());
            const app = appSelector(getState());
            const api = new BookingVenueApi(app, bookingId, !isCa);
            const result = await loadAndTrack(dispatch, actions.LOAD_VENUE_DETAIL, api.detail(venueId));
            dispatch(loadVenueDetailSuccess(result));
        }
    };
}
