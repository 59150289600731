import axios from "axios";
import * as QueryString from "query-string";

export class AttendeeApi {
    private readonly apiUrl: string;

    constructor(bookingId: string) {
        this.apiUrl = `/api/booking/${bookingId}/attendee`;
    }

    public async getAttendeeId(seatId: string, eventInstanceId: string): Promise<string> {
        const options = {
            seatId,
            eventInstanceId
        };

        const query = QueryString.stringify(options);
        const response = await axios.post(`${this.apiUrl}?${query}`);
        return response.data as string;
    }
}
