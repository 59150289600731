import * as React from "react";
import { EventInstance } from "../model";
import { ShowEventInstanceButton } from "./ShowEventInstanceButton";
import { HideEventInstanceButton } from "./HideEventInstanceButton";

interface Props {
    eventInstance: EventInstance;
}

export const ShowHideEventInstanceButton: React.FC<Props> = ({ eventInstance }) => {

    const isHidden = !!eventInstance && !!eventInstance.reasonForHidingEvent;
    if (isHidden) {
        return <ShowEventInstanceButton eventInstance={eventInstance} />;
    }

    return (
        <HideEventInstanceButton
            eventInstance={eventInstance}
        />
    );
};
