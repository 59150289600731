/* eslint-disable eqeqeq */
import * as React from "react";
import { Message, MessageContent } from "semantic-ui-react";

interface ErrorProps {
    errorMessage: string;
}

export const Error: React.FC<ErrorProps> = ({ errorMessage }) => {
    return (
        <Message color={"red"} size={"mini"}>
            <MessageContent>
                {errorMessage != undefined && errorMessage.length > 0 && errorMessage}
            </MessageContent>
        </Message>
    );
};
