import * as actions from "./actionTypes";
import { CurrentUserAction } from "./actions";
import { CurrentUser, initialState } from "./model";

const defaultState: CurrentUser = initialState;

export function currentUser(state: CurrentUser = defaultState, action: CurrentUserAction): CurrentUser {
    switch (action.type) {
        case actions.GET_CURRENT_USER_SUCCESS:
            return action.payload;
        case actions.SESSION_TIMED_OUT:
            return defaultState;
        default:
            return state;
    }
}
