import { eventInstanceSelector } from "@booking/eventInstance/selectors";
import { Modal } from "@common/components";
import { toast } from "@common/toasts";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { EventInstance, EventInstanceApi } from "../..";
import { CustomSmsMessage } from "@common/crud/common";

export const TrainerSmsModal: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const openModal = React.useCallback(() => setModalOpen(true), []);
    const closeModal = React.useCallback(() => setModalOpen(false), []);
    const [selectingTrainers, setSelectingTrainers] = React.useState(true);
    const [selectedTrainers, setSelectedTrainers] = React.useState<string[]>([]);
    const [message, setMessage] = React.useState("");
    const onContinueClicked = React.useCallback(() => setSelectingTrainers(false), []);
    const onBackClicked = React.useCallback(() => setSelectingTrainers(true), []);

    const eventInstance = useSelector(eventInstanceSelector) as EventInstance;

    const theoryTrainers = [...(eventInstance?.trainers ?? [])];
    const practicalTrainers = [...(eventInstance?.practicalTrainers ?? [])];
    const monitorTrainers = [...(eventInstance?.monitorTrainers ?? [])];
    const observerTrainers = [...(eventInstance?.observerTrainers ?? [])];

    const getOnChangeForTrainer = (trainerId: string) => {
        return (value: boolean) => {
            if (value) {
                if (selectedTrainers.includes(trainerId)) {
                    return;
                }
                setSelectedTrainers([...selectedTrainers, trainerId]);
            } else {
                setSelectedTrainers(selectedTrainers.filter(t => t !== trainerId));
            }
        };
    };

    const canContinue = selectedTrainers.length > 0 && !loading;
    const canSend = message.length > 0 && !loading;

    const onSendClicked = React.useCallback(async () => {
        setLoading(true);
        const trainerApi = new EventInstanceApi();
        await trainerApi.sendTrainerSms(eventInstance.id, message, selectedTrainers);
        toast.info("Messages sent");
        setLoading(false);
        closeModal();
    }, [closeModal, eventInstance.id, message, selectedTrainers]);

    const renderContent = React.useCallback(() => {
        return (
            <>
                {theoryTrainers.length > 0 &&
                <>
                    <h4>Theory</h4>
                    {theoryTrainers.filter((t, index, self) => index === self.findIndex(a => a.id === t.id)).map(trainer =>
                        (<Input.Checkbox
                            key={trainer.id}
                            value={selectedTrainers.includes(trainer.id)}
                            label={trainer.name}
                            onChange={getOnChangeForTrainer(trainer.id)}
                        />))
                    }
                </>}
                {practicalTrainers.length > 0 &&
                <>
                    <h4>Practical</h4>
                    {practicalTrainers.filter((t, index, self) => index === self.findIndex(a => a.id === t.id)).map(trainer =>
                        (<Input.Checkbox
                            key={trainer.id}
                            value={selectedTrainers.includes(trainer.id)}
                            label={trainer.name}
                            onChange={getOnChangeForTrainer(trainer.id)}
                        />))
                    }
                </>}
                {monitorTrainers.length > 0 &&
                <>
                    <h4>Monitor</h4>
                    {monitorTrainers.filter((t, index, self) => index === self.findIndex(a => a.id === t.id)).map(trainer =>
                        (<Input.Checkbox
                            key={trainer.id}
                            value={selectedTrainers.includes(trainer.id)}
                            label={trainer.name}
                            onChange={getOnChangeForTrainer(trainer.id)}
                        />))
                    }
                </>}
                {observerTrainers.length > 0 &&
                <>
                    <h4>Other</h4>
                    {observerTrainers.filter((t, index, self) => index === self.findIndex(a => a.id === t.id)).map(trainer =>
                        (<Input.Checkbox
                            key={trainer.id}
                            value={selectedTrainers.includes(trainer.id)}
                            label={trainer.name}
                            onChange={getOnChangeForTrainer(trainer.id)}
                        />))
                    }
                </>}
            </>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theoryTrainers, practicalTrainers, observerTrainers, monitorTrainers]);

    return (
        <>
            <Button content={"Send SMS"} onClick={openModal} />
            <Modal open={modalOpen}>
                <Modal.Header>
                    Send message
                </Modal.Header>
                <Modal.Content>
                    <h3>{selectingTrainers ? "Which trainers would you like to send this SMS to?"
                        : `What SMS would you like to send to the trainer${selectedTrainers.length > 1 ? "s" : ""}?`}</h3>
                    <Form>
                        {selectingTrainers && renderContent()}
                        {!selectingTrainers && <CustomSmsMessage required={true} message={message} setMessage={setMessage} /> }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" disabled={loading} onClick={closeModal} className="cancel-action" />
                    { selectingTrainers &&  <Button content="Continue" disabled={!canContinue} onClick={onContinueClicked} /> }
                    { !selectingTrainers &&
                        <>
                            <Button content="Back" onClick={onBackClicked} disabled={loading} />
                            <Button content="Send" onClick={onSendClicked} loading={loading} disabled={!canSend} />
                        </>
                    }
                </Modal.Actions>
            </Modal>
        </>
    );
};
