import * as React from "react";
import { Container, Divider, Form } from "semantic-ui-react";
import { SubActionModalProps } from "./SubActionModalProps";
import { Input } from "@neworbit/simpleui-input";
import { DigitalCourseCancellationReason, ClassCourseCancellationReason, OtherCancellationReason } from "../../model";
import { optionsFromText } from "@common/crud/common";
import { cancelMultipleEventInstances } from "../../actions";
import { useDispatch } from "react-redux";
import { validators } from "not-valid";

interface Props {
    isOnsiteCourse: boolean;
    selectedEventInstanceIds: string[];
    setDisableContinue: (value: boolean) => void;
}

export const CancelMultipleEventInstances: React.FC<SubActionModalProps & Props> = ({
    setActionComplete,
    triggerDispatchAction,
    isOnsiteCourse,
    selectedEventInstanceIds,
    setDisableContinue }) => {
    const [reasonForCancel, setReasonForCancel] = React.useState<string>();
    const [validCancelReason, setValidCancelReason] = React.useState<boolean>();
    const [otherReason, setOtherReason] = React.useState<string>();
    const [otherReasonValid, setOtherReasonValid] = React.useState<boolean>(true);
    const dispatch = useDispatch();

    const otherCancellationReasonChoosen = reasonForCancel === OtherCancellationReason;

    const onReasonForCancelEventChanged = React.useCallback((value: string, valid: boolean) => {
        if (value) {
            setReasonForCancel(value);
            setValidCancelReason(valid);
            setDisableContinue(false);
        }
        else {
            setDisableContinue(true);
            setValidCancelReason(valid);
        }
    }, [setDisableContinue]);

    const onOtherReasonChanged = React.useCallback((value: string, valid: boolean) => {
        setOtherReason(value);
        setOtherReasonValid(valid);
    }, []);

    React.useEffect(() => {
        if (validCancelReason && (!otherCancellationReasonChoosen || otherReasonValid) && triggerDispatchAction) {
            dispatch(cancelMultipleEventInstances(selectedEventInstanceIds, reasonForCancel, otherReason));
            setActionComplete(true);
        }
    }, [triggerDispatchAction, validCancelReason, reasonForCancel, dispatch, selectedEventInstanceIds, setActionComplete, otherCancellationReasonChoosen,
        otherReasonValid, otherReason]);

    return (
        <Container>
            <Form>
                <Input.Dropdown
                    value={reasonForCancel}
                    label="What is the reason for cancellation?"
                    options={isOnsiteCourse ?
                        optionsFromText(ClassCourseCancellationReason) :
                        optionsFromText(DigitalCourseCancellationReason)
                    }
                    onChange={onReasonForCancelEventChanged}
                />
                {otherCancellationReasonChoosen && (
                    <Input.Textarea
                        value={otherReason}
                        label="Other Reason"
                        validation={[validators.validLength({ max: 500 })]}
                        onChange={onOtherReasonChanged}
                    />
                )}
                <Divider />
            </Form>
        </Container>
    );
};
