import * as React from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { Area } from "@common/crud/eventInstance/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { AdminMultiDayPartMenuItem } from "@common/crud/eventInstance/components/register/AdminMultiDayPartMenuItem";
import {
    TrainerMultiDayPartMenuItem
} from "@common/crud/eventInstance/components/register/TrainerMultiDayPartMenuItem";

export interface GroupEventInstanceDayPartMenuProps {
    area: Area;
    group: EventInstanceGroupModel;
}

export const GroupEventInstanceDayPartMenu: React.FC<GroupEventInstanceDayPartMenuProps> = ({ area, group }) => {
    const app = useSelector(appSelector);

    return (
        <Container>
            <div className="button-search-menu-fit">
                {app === Apps.Admin &&
                    group.eventInstanceGroupItems?.map(gei =>
                        (<AdminMultiDayPartMenuItem
                            key={gei.eventInstanceId}
                            area={area}
                            eventInstanceId={gei.eventInstanceId}
                            children={gei.dayNumber.toString()} />)
                    )
                }
                {app === Apps.Trainer &&
                    group.eventInstanceGroupItems?.map(gei =>
                        (<TrainerMultiDayPartMenuItem
                            key={gei.eventInstanceId}
                            workflowType={group.workflowType}
                            area={area}
                            eventInstanceId={gei.eventInstanceId}
                            children={gei.dayNumber.toString()} />)
                    )
                }
            </div>
        </Container>
    );
};
