import moment from "moment";
import { DorsBooking, DorsBookingListModel } from "@common/crud/dorsBooking";
import { Location } from "redux-little-router";

export enum GenderEnum {
    None = 0,
    Male = 1,
    Female = 2,
}

export const Gender = {
    1: "Male",
    2: "Female",
};

export enum DrCodeEnum {
    None = 0,
    DR10 = 1,
    DR20 = 2,
    DR30 = 3,
    DR31 = 4,
    DR40 = 5,
    DR50 = 6,
    DR60 = 7,
    DR61 = 8,
    DR70 = 9,
    DR80 = 10,
    DR90 = 11
}

export const DrCode = {
    0: "None",
    1: "DR10 Driving or attempting to drive with alcohol concentration above limit",
    2: "DR20 Driving or attempting to drive when unfit through drink",
    3: "DR30 Driving or attempting to drive then refusing to provide a specimen",
    4: "DR31 Driving or attempting to drive then refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity",
    5: "DR40 In charge of a vehicle while alcohol level above limit",
    6: "DR50 In charge of a vehicle while unfit through drink",
    7: "DR60 Failure to provide a specimen for analysis in circumstances other than driving or attempting to drive",
    // eslint-disable-next-line max-len
    8: "DR61 Refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity in circumstances other than driving or attempting to drive",
    9: "DR70 Failing to provide specimen for breath test",
    10: "DR80 Driving or attempting to drive when unfit through drugs",
    11: "DR90 In charge of a vehicle when unfit through drugs",
};

export enum AlcoholReadingEnum {
    None = 0,
    Blood = 1,
    Breath = 2,
    Urine = 3,
}

export const AlcoholReading = {
    0: "None",
    1: "BL - Blood",
    2: "BR - Breath",
    3: "UR - Urine"
};

export enum HighRiskEnum {
    None = 0,
    FailedToSupply = 1,
    HighReading = 2,
    RepeatOffender = 3,
    NotHighRisk = 4
}

export const HightRisk = {
    0: "None",
    1: "F - Failed to Supply",
    2: "H - High Reading",
    3: "R - Repeat Offender",
    4: "Not High Risk"
};

export enum MessageType {
    DrinkDriveOffenderInvite = 13,
    DrinkDriveOffenderBookingReminder = 15
}

export interface DrinkDriveOffenderDetails {
    gender: GenderEnum;
    dateOfBirth: moment.Moment;
    courtId: string;
    courtName?: string;
    sentenceDate: moment.Moment;
    disqualifiedPeriod: number;
    interimDate: moment.Moment;
    reduction: number;
    previousConvictions: number;
    supervisingCourtId: string;
    supervisingCourtName?: string;
    classicCertificateNumber: string;
    drCode: DrCodeEnum;
    alcoholReadingType: AlcoholReadingEnum;
    alcoholReading?: number;
    highRisk: HighRiskEnum;
    transferProvider?: string;
    transferDate?: moment.Moment;
    referredProvider: string;
    caseNumber: string;
    eligibleForConcessions: boolean;
    eventType: string;
}

export interface DrinkDriveOffenderListModel extends DorsBookingListModel {
    readonly drinkDriveOffenderDetails: DrinkDriveOffenderDetails;
}

export interface DrinkDriveOffenderDetailModel extends DorsBooking {
    readonly drinkDriveOffenderDetails: DrinkDriveOffenderDetails;
}

export type DrinkDriveOffender = DrinkDriveOffenderListModel & DrinkDriveOffenderDetailModel & DrinkDriveOffenderEditModel;

export interface DrinkDriveOffenderUnlockAccountModel {
    id: string;
}

export interface DrinkDriveOffenderCreateModel extends DorsBooking {
    drinkDriveOffenderDetails: DrinkDriveOffenderDetails;
}

export interface DrinkDriveOffenderEditModel extends DorsBooking {
    drinkDriveOffenderDetails: DrinkDriveOffenderDetails;
}

export interface DrinkDriveOffenderAttendeeScoresModel {
    scores: Score[];
}

export interface Score {
    key: number; attendeeId: string; name: string; score1?: number; score2?: number; dirty: boolean;
}

export interface DrinkDriveOffenderState {
    readonly drinkDriveOffenders: DrinkDriveOffender[];
}

export interface DrinkDriveInitialBookingDateDetailResponseModel {
    errorMessage?: string;
    httpStatusCode?: number;
    model?: DrinkDriveInitialBookingDateDetailModel;
}

export interface DrinkDriveInitialBookingDateDetailModel {
    readonly id?: string;
    readonly drinkDriveInitialBookingDate?: moment.Moment;
    readonly drinkDriveInitialEventInstance?: string;
}

export type AppState = DrinkDriveOffenderState & { router: Location };

export interface SearchOptions {
    name?: string;
}

export interface DdrsScore {
    score1?: number;
    score2?: number;
}

export const ExternalDdrsProviders = [
    "Ace Driver Safety Limited",
    "Amber Light Training Ltd",
    "Aquarius Action Projects",
    "Aspire Driver Development",
    "Drink Drive North",
    "IAM Driver Retraining Academy Limited",
    "ISM Psychological Services Ltd",
    "LTP Driver Training",
    "Moorgate Training Limited",
    "NECA",
    "Reform Road Safety & Education Ltd",
];

export const AllDdrsProviders = [
    ...ExternalDdrsProviders,
    "TTC"
];

export interface AttachedDocumentListModel {
    readonly id: string;
    readonly fileName: string;
    readonly description: string;
    readonly blobPath: string;
    readonly dateCreated: moment.Moment;
    readonly addedByEmail: string;
    readonly isProcessed: boolean;
    readonly isCompleted: boolean;
}
