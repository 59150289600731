import * as React from "react";
import { AttendeeCarType } from "../../model";

interface DisplayAttendeeCarTypeProps {
    carType: AttendeeCarType;
}

export function DisplayAttendeeCarType({ carType }: DisplayAttendeeCarTypeProps) {
    const carTypeImage = carType === "Special" ? "specially-adapted.png" : `${carType}.png`;

    return <img className={"car-type-img"} src={`/assets/${carTypeImage}`} alt={`Car Type: ${carType}`} />;
}
