import axios from "axios";
import moment from "moment";
import { HistoryRecord } from "@common/history/model";

export class HistoryApi {
    private readonly historyApi: string;

    constructor(correlationId: string) {
        this.historyApi = `/api/history/${correlationId}`;
    }

    public async getHistory(): Promise<HistoryRecord[]> {
        const response = await axios.get(`${this.historyApi}`);
        const model = response.data as HistoryRecord[];
        return model.map(this.parseHistory);
    }

    public parseHistory(historyRecord: HistoryRecord): HistoryRecord {
        return {
            ...historyRecord,
            dateCreated: historyRecord.dateCreated && moment(historyRecord.dateCreated),
            pendingDate: historyRecord.pendingDate && moment(historyRecord.pendingDate),
            completionDate: historyRecord.completionDate && moment(historyRecord.completionDate),
        };
    }
}
