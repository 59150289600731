import * as React from "react";
import { Button, CheckboxProps, DropdownProps, Form, Grid, InputOnChangeData } from "semantic-ui-react";
import { ConstructionProductCategoriesForWorkflowType, ConstructionWorkflowType, CorporateProductCategoriesForWorkflowType,
    CorporateWorkflowTypes, ProductCategoryEnum, WorkflowTypeEnum } from "../model";
import { FilterOptions } from "./All";
import { optionsFromObject } from "@common/crud/common/";
import { isBusinessDriverAdmin, isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";

export interface FilterProps {
    search: (
     options: FilterOptions
    ) => void;
    initialName?: string;
    initialInternalId?: string;
    initialShownInBookingApp?: boolean;
    initialWorkflowType?: WorkflowTypeEnum;
    initialProductCategories?: ProductCategoryEnum[];
    isCorporate: boolean;
    isConstruction: boolean;
}

const defaultFilterValues = {
    name: "",
    internalId: "",
    shownInBookingApp: false,
    workflowType: WorkflowTypeEnum.Any,
    productCategories: [] as ProductCategoryEnum[],
};

const Filters: React.FC<FilterProps> = (props) => {
    const { search, initialName, initialInternalId, initialShownInBookingApp, initialWorkflowType, initialProductCategories, isCorporate,
        isConstruction } = props;

    const hasBusinessDriverAdmin = useSelector(isBusinessDriverAdmin);
    const hasTtcCorporateAdmin = useSelector(isTtcCorporateAdmin);
    const workflowTypeOptions = isCorporate
        ? CorporateWorkflowTypes(hasBusinessDriverAdmin, hasTtcCorporateAdmin)
        : isConstruction
            ? ConstructionWorkflowType
            : {};

    const [nameValue, setNameValue] = React.useState<string>(initialName ?? defaultFilterValues.name);
    const [internalIdValue, setInternalIdValue] = React.useState<string>(initialInternalId ?? defaultFilterValues.internalId);
    const [shownInBookingAppValue, setShownInBookingAppValue] = React.useState<boolean>(initialShownInBookingApp ?? defaultFilterValues.shownInBookingApp);
    const [workflowTypeValue, setWorkflowTypeValue] = React.useState<WorkflowTypeEnum | undefined>(initialWorkflowType ?? defaultFilterValues.workflowType);
    const [productCategoriesValue, setProductCategoriesValue] =
        React.useState<ProductCategoryEnum[]>(initialProductCategories ?? defaultFilterValues.productCategories);

    const productCategoryOptions = isCorporate
        ? CorporateProductCategoriesForWorkflowType(hasBusinessDriverAdmin, hasTtcCorporateAdmin, workflowTypeValue)
        : isConstruction
            ? ConstructionProductCategoriesForWorkflowType(workflowTypeValue)
            : {};

    const [timerId, setTimerId] = React.useState<any>();

    const timeOutMs = 500;

    const onSearch = React.useCallback(({
        name = undefined,
        internalId = undefined,
        workflowType = undefined,
        productCategories = undefined,
        shownInBookingApp = undefined
    }: FilterOptions) => {

        const options = {
            name: name !== undefined ? name : nameValue,
            internalId: internalId !== undefined ? internalId : internalIdValue,
            workflowType: workflowType !== undefined ? workflowType : workflowTypeValue,
            productCategories: productCategories !== undefined ? productCategories : productCategoriesValue,
            shownInBookingApp: shownInBookingApp !== undefined ? shownInBookingApp : shownInBookingAppValue,
        };

        search(options);
    }, [internalIdValue, nameValue, productCategoriesValue, search, shownInBookingAppValue, workflowTypeValue]);

    const onNameChange = React.useCallback((_: any, data: InputOnChangeData) => {
        const name = data.value;
        setNameValue(name);

        if (timerId) {
            clearTimeout(timerId);
        }
        const id = setTimeout(() => onSearch({ name }), timeOutMs);
        setTimerId(id);
    }, [onSearch, timerId]);

    const onInternalIdChange = React.useCallback((_: any, data: InputOnChangeData) => {
        const internalId = data.value;
        setInternalIdValue(internalId);

        if (timerId) {
            clearTimeout(timerId);
        }
        const id = setTimeout(() => onSearch({ internalId }), timeOutMs);
        setTimerId(id);
    }, [onSearch, timerId]);

    const onWorkflowTypeChange = React.useCallback((_: any, data: InputOnChangeData) => {
        const workflowType = +data.value;
        setWorkflowTypeValue(workflowType);
        setProductCategoriesValue([]);

        if (timerId) {
            clearTimeout(timerId);
        }

        onSearch({ workflowType, productCategories: [] });
    }, [onSearch, timerId]);

    const onProductCategoriesChange = React.useCallback((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps)  => {
        let productCategories = data.value as number[];
        productCategories = orderBy(productCategories);
        if (Array.isArray(data.value) && data.value.includes(-1)) {
            productCategories = [];
        }
        setProductCategoriesValue(productCategories);

        if (timerId) {
            clearTimeout(timerId);
        }

        onSearch({ productCategories });
    }, [onSearch, timerId]);

    const onShownInBookingAppChanged = React.useCallback((_: any, data: CheckboxProps) => {
        const shownInBookingApp = data.checked;
        setShownInBookingAppValue(shownInBookingApp);

        if (timerId) {
            clearTimeout(timerId);
        }

        onSearch({ shownInBookingApp });
    }, [onSearch, timerId]);

    function clearFilters()
    {
        setNameValue(defaultFilterValues.name);
        setInternalIdValue(defaultFilterValues.internalId);
        setWorkflowTypeValue(defaultFilterValues.workflowType);
        setProductCategoriesValue(defaultFilterValues.productCategories);
        setShownInBookingAppValue(defaultFilterValues.shownInBookingApp);

        if (timerId) {
            clearTimeout(timerId);
        }

        onSearch(defaultFilterValues);
    }

    return (
        <Grid stackable className="filter-grid" width={16}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form>
                        <Form.Input
                            label={(isCorporate || isConstruction) ? "Product Name" : "Scheme Name"}
                            placeholder={(isCorporate || isConstruction) ? "Product Name" : "Scheme Name"}
                            value={nameValue}
                            action={<Button icon="search" />}
                            onChange={onNameChange}
                        />
                        {(isCorporate || isConstruction) && (
                            <>
                                <Form.Input
                                    label="Internal ID"
                                    placeholder="Internal ID"
                                    value={internalIdValue}
                                    action={<Button icon="search" />}
                                    onChange={onInternalIdChange}
                                />
                                <Form.Dropdown
                                    label="Workflow"
                                    placeholder="Any"
                                    value={workflowTypeValue}
                                    options={optionsFromObject(workflowTypeOptions, "Any", WorkflowTypeEnum.Any)}
                                    onChange={onWorkflowTypeChange}
                                    selection
                                />
                                <Form.Dropdown
                                    label="Product Category"
                                    placeholder="Any"
                                    value={productCategoriesValue}
                                    options={optionsFromObject(productCategoryOptions, "Any", ProductCategoryEnum.Any)}
                                    onChange={onProductCategoriesChange}
                                    selection
                                    multiple
                                />
                            </>
                        )}
                    </Form>
                </Grid.Column>
            </Grid.Row>
            {(isCorporate || isConstruction) && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Checkbox toggle label="Shown in booking app" checked={shownInBookingAppValue} onChange={onShownInBookingAppChanged} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Button className="display-right" content="Clear Filters" onClick={clearFilters} />
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export { Filters };
