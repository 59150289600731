import { EventType } from "@common/crud/eventType";
import { GetEventMaxDigitalAttendees } from "@common/crud/eventType/model";
import { CountryEnum } from "@common/crud/organisation/model";
import { Trainer } from "@common/crud/trainer";
import moment from "moment";
import * as React from "react";
import { Link } from "redux-little-router";
import { Grid, Table } from "semantic-ui-react";
import { EventInstance } from "../..";

interface PlannedEventsOverviewTableRowProps {
    name: string;
    eventType: EventType;
    events: EventInstance[];
    trainer?: Trainer;
    months: moment.Moment[];
}

interface DdrsSchemeEventsSummary {
    courses: number;
    seats: number;
    month?: number;
    englandCourses: number;
    walesCourses: number;
    scotlandCourses: number;
    northernIrelandCourses: number;
}

const createSchemeEventsSummaryForEventType = (month: moment.Moment, events: EventInstance[], eventType: EventType): DdrsSchemeEventsSummary => {

    let coursesOfEventType = 0;
    let seats = 0;
    let englandCourses = 0;
    let walesCourses = 0;
    let scotlandCourses = 0;
    let northernIrelandCourses = 0;

    // This filtering means only the first of any day parts will be counted.
    events.filter(e => e.id === e.dayParts[0]).forEach(e => {
        if (e.startDate?.month() === month.month()) {
            coursesOfEventType++;
            seats += GetEventMaxDigitalAttendees(e.startDate, eventType);

            // eslint-disable-next-line default-case
            switch (e.referredCourtCountry) {
                case CountryEnum.England:
                    englandCourses++;
                    break;
                case CountryEnum.Wales:
                    walesCourses++;
                    break;
                case CountryEnum.Scotland:
                    scotlandCourses++;
                    break;
                case CountryEnum.NIreland:
                    northernIrelandCourses++;
                    break;
            }
        }
    });

    return {
        courses: coursesOfEventType,
        seats,
        month: month.month(),
        englandCourses,
        walesCourses,
        scotlandCourses,
        northernIrelandCourses,
    };
};

const getTotalCoursesAndSeats = (eventType: EventType, events: EventInstance[]): DdrsSchemeEventsSummary => {
    let coursesCount = 0;
    let seatCount = 0;
    let englandCourses = 0;
    let walesCourses = 0;
    let scotlandCourses = 0;
    let northernIrelandCourses = 0;

    events.filter(e => e.id === e.dayParts[0]).forEach(e => {
        coursesCount++;
        seatCount += GetEventMaxDigitalAttendees(e.startDate, eventType);

        // eslint-disable-next-line default-case
        switch (e.referredCourtCountry) {
            case CountryEnum.England:
                englandCourses++;
                break;
            case CountryEnum.Wales:
                walesCourses++;
                break;
            case CountryEnum.Scotland:
                scotlandCourses++;
                break;
            case CountryEnum.NIreland:
                northernIrelandCourses++;
                break;
        }
    });
    return {
        courses: coursesCount,
        seats: seatCount,
        englandCourses,
        walesCourses,
        scotlandCourses,
        northernIrelandCourses,
    };
};

export const DdrsPlannedEventsOverviewTableRow: React.FC<PlannedEventsOverviewTableRowProps> = ({ eventType, events, name, trainer, months }) => {

    const schemeEventsSummaries: DdrsSchemeEventsSummary[] = months.map(m => createSchemeEventsSummaryForEventType(m, events, eventType));

    const totals = getTotalCoursesAndSeats(eventType, events);
    const linkMonth = months?.length > 0 ? months[0] : null;
    const trainerLink = `/police-and-court-event-management/eventInstances/ddrs-digital-planning?month=${linkMonth?.toISOString()}&trainerId=${trainer?.id}`;

    return (
        <Table.Row>
            <Table.Cell>
                { trainer ? <Link href={trainerLink}>{name}</Link> : name}
            </Table.Cell>
            <Table.Cell>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.englandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.walesCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.scotlandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.northernIrelandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.courses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{totals.seats}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
            {schemeEventsSummaries.map(s => (<Table.Cell key={s.month}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.englandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.walesCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.scotlandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.northernIrelandCourses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.courses}</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            <p>{s.seats}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>))}
        </Table.Row>
    );
};
