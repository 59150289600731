import { Payload } from "@neworbit/redux-helpers";
import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { AsyncDispatch } from "@common/redux-helpers";
import { push } from "redux-little-router";
import { TrainerVenuesInAvailabilityRangeModel } from "src/Trainer.App/src/workingRadius";
import { LOAD_VENUES_IN_AVAILABILITY_RANGE_SUCCESS } from "src/Trainer.App/src/workingRadius/actionTypes";
import { TrainerAvailabilityApi } from "./trainerAvailabilityApi";
import { TrainerAvailabilityModel } from "./model";
import { LOAD_AVAILABILITY_TRAINER_SUCCESS, SAVE_AVAILABILITY_TRAINER_SUCCESS, SET_AVAILABILITY_EDIT_MODE_SUCCESS } from "./actionTypes";
import { toast } from "@common/toasts";
import { setCurrentPreferredNoDaysPerMonthOverMonthsSuccess, setCurrentPreferredNoSessionsPerDayOverMonthsSuccess } from "@common/trainerPromise/actions";

export type TrainerAvailabilityAction =
    ({ type: LOAD_AVAILABILITY_TRAINER_SUCCESS } & Payload<TrainerAvailabilityModel>)
    | ({ type: LOAD_VENUES_IN_AVAILABILITY_RANGE_SUCCESS } & Payload<TrainerVenuesInAvailabilityRangeModel>)
    | ({ type: SAVE_AVAILABILITY_TRAINER_SUCCESS } & Payload<TrainerAvailabilityModel>)
    | ({ type: SET_AVAILABILITY_EDIT_MODE_SUCCESS } & Payload<boolean>);

export const loadTrainerAvailabilitySuccess = (payload: TrainerAvailabilityModel): TrainerAvailabilityAction => ({
    payload,
    type: LOAD_AVAILABILITY_TRAINER_SUCCESS
});

export const loadVenuesInAvailabilityRangeSuccess = (payload: TrainerVenuesInAvailabilityRangeModel): TrainerAvailabilityAction => ({
    payload,
    type: LOAD_VENUES_IN_AVAILABILITY_RANGE_SUCCESS
});

export const saveTrainerAvailabilitySuccess = (payload: TrainerAvailabilityModel): TrainerAvailabilityAction => ({
    payload,
    type: SAVE_AVAILABILITY_TRAINER_SUCCESS
});

export const setAvailabilityEditModeSuccess = (payload: boolean): TrainerAvailabilityAction => ({
    payload,
    type: SET_AVAILABILITY_EDIT_MODE_SUCCESS
});

export function loadTrainerAvailability() {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAvailabilityApi();
        const result = await loadAndTrack(
            dispatch,
            "loadTrainerAvailability",
            api.getTrainerAvailability()
        );
        dispatch(loadTrainerAvailabilitySuccess(result));
        dispatch(setCurrentPreferredNoDaysPerMonthOverMonthsSuccess(result.preferredNoDaysPerMonthOverMonths));
        dispatch(setCurrentPreferredNoSessionsPerDayOverMonthsSuccess(result.preferredNoSessionsPerDayOverMonths));
    };
}

export function loadTrainerAvailabilityById({ trainerId }: { trainerId: string }) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAvailabilityApi();
        const result = await loadAndTrack(
            dispatch,
            "loadTrainerAvailabilityByTrainerId",
            api.getTrainerAvailabilityByTrainerId(trainerId)
        );
        dispatch(loadTrainerAvailabilitySuccess(result));
    };
}

export function loadVenuesInAvailabilityRange({ trainerId }: { trainerId: string }) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAvailabilityApi();
        const result = await loadAndTrack(
            dispatch,
            "loadVenuesInAvailabilityRange",
            api.getTrainerVenuesInAvailabilityRangeByTrainerId(trainerId)
        );
        dispatch(loadVenuesInAvailabilityRangeSuccess(result));
    };
}

export function saveTrainerAvailability(availability: TrainerAvailabilityModel, path?: string) {
    return async (dispatch: AsyncDispatch) => {
        const api = new TrainerAvailabilityApi();
        const result = await loadAndTrack(
            dispatch,
            "saveTrainerAvailability",
            api.saveTrainerAvailability(availability)
        );

        toast.info("Save successful");
        dispatch(saveTrainerAvailabilitySuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}`));
        }
    };
}

export function setAvailabilityEditMode(editMode: boolean = false) {
    return async (dispatch: Dispatch) => {
        dispatch(setAvailabilityEditModeSuccess(editMode));
    };
}
