import * as React from "react";
import { useDispatch } from "react-redux";
import { Form, Segment, Button, Grid, Divider } from "semantic-ui-react";
import { AttendeeListModel, RegisterAttendeeModel } from "@common/crud/attendee";
import { submitAttendanceRegister } from "@common/crud/attendee/actions";
import { EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { AttendanceRegisterRow } from "./AttendanceRegisterRow";
import { RegisterStatistics } from "./RegisterStatistics";
import {
    mapAttendanceRegisterAttendees,
    getRegisterStats,
    isCurrentDateTimeBeforeEventInstanceDateTime
} from "./helpers";
import { useRegisterContext } from "./register-context";
import { EventInstance } from "@common/crud/eventInstance";

interface AttendanceRegisterProps {
    eventInstance: EventInstance;
}

export const AttendanceRegister: React.FC<AttendanceRegisterProps> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const { nonCancelledAttendees: attendees, eventInstanceOptions: { eventInstanceId } } = useRegisterContext();
    const disableSubmitAttendanceButton = isCurrentDateTimeBeforeEventInstanceDateTime(eventInstance);

    const [attendeeRegister, setAttendeeRegister] = React.useState<RegisterAttendeeModel[]>([]);

    React.useEffect(() => {
        if (attendees && attendees.length > 0) {
            setAttendeeRegister(mapAttendanceRegisterAttendees(attendees));
        }
    }, [attendees]);

    function handleFormSubmit() {
        // isStageTwo: true
        onSubmitRegister(attendeeRegister, true);
    }

    async function onAttendanceUpdated(id: string, value: boolean) {

        const updatedAttendee = attendeeRegister.find(a => a.id === id);
        const restAttendees = attendeeRegister.filter(a => a.id !== id);
        const updatedAttendeeRegister = [...restAttendees, { ...updatedAttendee, didAttend: value }];
        setAttendeeRegister(updatedAttendeeRegister);
        onSubmitRegister(updatedAttendeeRegister, false);
    }

    function onSubmitRegister(models: RegisterAttendeeModel[], isStageTwo: boolean) {
        dispatch(submitAttendanceRegister(eventInstanceId, models, isStageTwo));
    }

    function getDidAttend(attendeeId: string) {
        return attendeeRegister.find(a => a.id === attendeeId)?.didAttend ?? false;
    }

    const sortedAttendees = attendees.sort((a: AttendeeListModel, b: AttendeeListModel) => a.fullName.localeCompare(b.fullName));

    return (
        <Form onSubmit={handleFormSubmit}>
            <Grid container padded className="attendance-register">
                <Grid.Row only="computer tablet">
                    <Grid.Column className="purple-header" computer={6} tablet={6}>
                        Attendee Name
                    </Grid.Column>
                    <Grid.Column className="purple-header" width={5} only="computer">
                        Driving Licence
                    </Grid.Column>
                    <Grid.Column className="purple-header" computer={5} textAlign="right" tablet={10}>
                        Attended
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row only="mobile">
                    <Grid.Column width="11" className="purple-header">Attendee name</Grid.Column>
                    <Grid.Column width="5" className="purple-header">Attended</Grid.Column>
                </Grid.Row>
                <Divider />
                {sortedAttendees.map(attendee => (
                    <AttendanceRegisterRow
                        key={attendee.id}
                        attendee={attendee}
                        didAttend={getDidAttend(attendee.id)}
                        onAttendanceUpdated={onAttendanceUpdated}
                    />
                ))}

                <RegisterStatistics registerStats={getRegisterStats(attendees)} />
            </Grid>
            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <Segment className="button-segment no-margin-top">
                    <div className="button-container right-align">
                        <Button disabled={disableSubmitAttendanceButton} content="SUBMIT ATTENDANCE" floated="right" />
                    </div>
                </Segment>
            </Authorize>
        </Form>
    );
};
