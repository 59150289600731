import * as React from "react";
import { Tab, Grid } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { CoreDetailProps } from "../Detail";
import { CompanyType, CompanyTypeEnum } from "../../model";
import { AddressDisplay } from "./AddressDisplay";

export const CompanyInformation: React.FC<CoreDetailProps> = ({ trainer }) => {
    const limitedCompanySelected = trainer.companyType === CompanyTypeEnum.LimitedCompany;
    const isVatRegistered = !!trainer.vatNumber;

    return (
        <Tab.Pane>
            <Grid>
                <Detail label="VAT Registered?" value={trainer.vatNumber ? "Yes": "No"} labelWidth={5} valueWidth={11} />
                {isVatRegistered && <Detail label="Vat Number" value={trainer.vatNumber} labelWidth={5} valueWidth={11} />}
                <Detail label="Company Type" value={CompanyType[trainer.companyType]} labelWidth={5} valueWidth={11} />
                {limitedCompanySelected && (
                    <>
                        <Detail label="Company Name" value={trainer.companyName} labelWidth={5} valueWidth={11} />
                        <Detail label="Company Registration Number" value={trainer.companyRegistrationNumber} labelWidth={5} valueWidth={11} />
                        <Detail label="Address" value={AddressDisplay(trainer.companyAddress)} labelWidth={5} valueWidth={11} />
                    </>
                )}
                {trainer.bankAccount &&
                    <Detail.Group header="Bank Account">
                        <Detail label="Account Name" value={trainer.bankAccount.accountName} labelWidth={5} valueWidth={11} />
                        <Detail label="Sort Code" value={trainer.bankAccount.sortCode} labelWidth={5} valueWidth={11} />
                        <Detail label="Account Number" value={trainer.bankAccount.accountNumber} labelWidth={5} valueWidth={11} />
                    </Detail.Group>}
            </Grid>
        </Tab.Pane>
    );
};
