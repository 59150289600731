import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const KnowingTheRulesSection: QuestionSection = {
    id: "6",
    title: "Module 3",
    seqNum: 5,
    subSections: [ {
        id: "6.1",
        text: "Knowing the rules 15 min",
        seqNum: 1,
        criteria: [
            { id: "1", text: "Introduce first part of map" },
            { id: "2", text: "Mixed use of responses from clients, thumbs up/down, write down, wave at screen, " +
                    "question 6 clients write down and trainer gives out answers." },
            { id: "3", text: "Reminder that the Highway Code is available online" }
        ]
    }],
    summaryCriterium: [{ id: "4", text: "Overall Comments", typeEnum: CriteriaEnum.text }]
};
