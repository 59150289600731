import { createSelector } from "reselect";
import { createBasePathSelector } from "@common/redux-helpers/selectors";
import { Attendee, AppState } from "./model";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.attendeeId;
};

export type SelectorState = AppState;

export const attendeesSelector = (state: AppState) => state.attendees;

export const attendeesFromSelector = (state: AppState) => state.attendeesFrom;

export const taskTypeQuerySelector = (state: AppState) => state.router.query?.taskType;

export const attendeeSelector = createSelector(
    attendeesSelector,
    routeIdSelector,
    (attendees: Attendee[], id: string) => {
        if (!id) {
            return null;
        }
        return attendees.filter(c => c.id === id)[0] || {} as any;
    }
);

export const basePathSelector = createBasePathSelector("attendees");
