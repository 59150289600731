import * as React from "react";
import { SettingsGrid, SettingsGridItemProps } from "../SettingsGrid";
import { AdHocTemplatesSettings } from "../../model";
import { AdHocConfigurationAreaComponent } from "../ConfigurationAreaComponent";
import { Grid } from "semantic-ui-react";
import { toast } from "@common/toasts";
import { CreateFieldModal } from "./CreateFieldModal";

export const AdHocTemplates: AdHocConfigurationAreaComponent<AdHocTemplatesSettings> = ({ hierarchy, settings, area, inputType }) => {
    const [items, setItems] = React.useState<SettingsGridItemProps<string>[]>();
    React.useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (settings != null) {
            setItems(Object.keys(settings)?.map((key: string) => {
                const c = settings[key];
                return { name: c.name, label: c.name, isArchivable: true, inputType };
            }));
        } else {
            setItems([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    const onAdd = React.useCallback((newField: string) => {
        if (!newField) {
            toast.warning("Template needs name.");
            return;
        }
        setItems([...items, { name: newField, inputType }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column floated="right">
                        <CreateFieldModal onSave={onAdd} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <SettingsGrid
                area={area}
                hierarchy={hierarchy}
                settings={settings}
                items={items}
            />
        </>
    );
};
