import * as React from "react";
import { Grid } from "semantic-ui-react";
import { v4 } from "uuid";
import moment from "moment";
import { ScheduledPaymentPlanViewModel } from "../../model";
import { ScheduledPaymentRow } from "./ScheduledPaymentRow";
import { ScheduledPaymentResponse } from "./ScheduledPaymentBase";
import { isNullOrUndefined } from "@common/global/CommonHelpers";

export interface ScheduledPaymentPlanProps {
    maxValue: number;
    initialPaymentValue: number;
    eventDate: moment.Moment;
    sendPlan: (planObject: ScheduledPaymentResponse) => void;
    scheduledPaymentPlans: ScheduledPaymentPlanViewModel[];
    setScheduledPaymentPlans: React.Dispatch<React.SetStateAction<ScheduledPaymentPlanViewModel[]>>;
}

export const defaultPaymentPlan = (): ScheduledPaymentPlanViewModel => ({
    id: v4(),
    amount: 0,
    chargeDate: null,
    isAmountDirty: false,
    isDateDirty: false,
    index: 0
});

export const ScheduledPaymentPlan: React.FC<ScheduledPaymentPlanProps> = ({
    eventDate, initialPaymentValue, maxValue, sendPlan, scheduledPaymentPlans, setScheduledPaymentPlans
}) => {
    const [errorMessage, setErrorMessage] = React.useState<string>(null);

    React.useEffect(() => {
        validatePlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledPaymentPlans]);

    const addPaymentPlan = React.useCallback(() => {
        if (scheduledPaymentPlans.length < 5 && scheduledPaymentPlans.every(x => x.chargeDate && x.chargeDate)) {
            setScheduledPaymentPlans([
                ...scheduledPaymentPlans,
                defaultPaymentPlan()
            ]);
        }
    }, [scheduledPaymentPlans, setScheduledPaymentPlans]);

    const removePaymentPlan = React.useCallback((planId: string) => {
        const filteredPlans = scheduledPaymentPlans.filter((item) => item.id !== planId);

        setScheduledPaymentPlans(filteredPlans);
    }, [scheduledPaymentPlans, setScheduledPaymentPlans]);

    const validateStep = (plan: ScheduledPaymentPlanViewModel) => {
        let validationMessage: string = "";

        const duplicates =
            plan?.chargeDate &&
            scheduledPaymentPlans.filter(
                v =>
                    v.id !== plan.id &&
                    !isNullOrUndefined(v.chargeDate) && v.chargeDate.isSame(plan.chargeDate, "days"));

        if (duplicates && duplicates.length > 0) {
            validationMessage += "Cannot have two plans with the same date";
        }

        if (isNullOrUndefined(plan?.chargeDate)) {
            validationMessage += "Date is required.";
        }

        if (plan?.chargeDate?.isValid() && moment.duration(eventDate.diff(plan.chargeDate)).asDays() < 8) {
            validationMessage += `Date have to be at least eight days before event date (${eventDate.format("DD/MM/YYYY")})`;
        }

        if (plan?.chargeDate?.isSameOrBefore(moment(), "days")) {
            validationMessage += "Cannot set plan date to today or in past";
        }

        if (plan?.amount === 0) {
            return "Cannot set plan with value equal to zero";
        }

        return validationMessage;
    };

    const updatePaymentPlan = React.useCallback((plan: ScheduledPaymentPlanViewModel) => {

        plan.validationMessage = validateStep(plan);

        const updatedPlan = scheduledPaymentPlans.map(
            (p, i) => p.id === plan.id
                ? { ...plan, index: i }
                : { ...p, index: i }
        );

        setScheduledPaymentPlans(updatedPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledPaymentPlans]);

    const currentBalance = () => {
        const tempBalance = (maxValue*100) - (initialPaymentValue*100);
        return +(scheduledPaymentPlans.reduce((sum, current) => ((sum) - (current.amount*100)), tempBalance)/100).toFixed(2);
    };

    const validatePlan = () => {
        let isValid: boolean = true;
        const currentBalanceValue = currentBalance();
        const currentBalanceDisplay = currentBalanceValue.toFixed(2);

        setErrorMessage("");

        if (currentBalanceValue > 0) {
            setErrorMessage(`Attendee has to be charged £${currentBalanceDisplay} pounds more.`);
            isValid = false;
        } else if (currentBalanceValue < 0) {
            setErrorMessage(`Attendee cannot be overcharged £(${currentBalanceDisplay}).`);
            isValid = false;
        } else if (scheduledPaymentPlans.some(x => !x.isAmountDirty || !x.isDateDirty)) {
            setErrorMessage("Some of the values are not set. Please correct or remove step.");
            isValid = false;
        } else if (initialPaymentValue === 0) {
            setErrorMessage("Initial payment is necessary to set up scheduled payment plan.");
            isValid = false;
        }

        if (scheduledPaymentPlans.some(x => x.validationMessage !== "")) {
            isValid = false;
        }

        sendPlan({
            isPlanValid: isValid,
            plan: scheduledPaymentPlans
        });
    };

    return (
        <Grid className="grid scheduled-payment-base-grid">
            {scheduledPaymentPlans.map((plan, idx) => (
                <ScheduledPaymentRow
                    key={plan.id}
                    isFirstRow={idx === 0}
                    isLastRow={idx === scheduledPaymentPlans.length - 1}
                    isAddingEnabled={currentBalance() > 0}
                    scheduledPaymentPlan={plan}
                    addPaymentPlan={addPaymentPlan}
                    removePaymentPlan={removePaymentPlan}
                    updatePaymentPlan={updatePaymentPlan}
                />
            ))}

            {errorMessage !== "" &&
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p className="error">{errorMessage}</p>
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
    );
};
