export const MobileMaxWidth = 767;

export const NonMobileMinWidth = 768;

export const MobileLandscapeHeight = 450;

export const TabletMinWidth = 768;

export const TabletMaxWidth = 820;

export const TabletLandscapeWidth = 1180;
