/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Icon, Loader, PaginationProps, Table } from "semantic-ui-react";
import { eventInstancesWithOrdersSelector } from "../selectors";
import { OrderBaseProps } from "./Base";
import { completeProcess, fixEventInstancesWithOrders, loadEventInstancesWithOrders, loadPayment } from "../actions";
import { Basket, BusinessLineSupportsHalfRefund, FeesStatusEnum, GetRefundFullPeriodForBusinessLine, GetRefundHalfPeriodForBusinessLine,
    RebookedAttendee, SearchOptions } from "../model";
import { loadEventTypeForRouteWorkflowTypes } from "@common/crud/eventType/actions";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { TTCPagination } from "@common/components/TTCPageination";
import { OrderApi } from "../orderApi";
import "./RebookSelect.scss";
import { toast } from "react-toastify";
import { RebookSelectSummaryRow } from "./RebookSelectSummaryRow";
import { numberFormatter } from "@common/helpers/number-format";
import { useSetBeforeGenesysAuthPage } from "@common/genesys";
import { OfferedRebookedCourseDetails } from "./OfferedRebookedCourseDetails";
import { RebookEventInstancesFilters } from "./RebookEventInstancesFilters";
import moment from "moment";
import { push } from "redux-little-router";
import { prepareOrdersUrl } from "../helpers";
import { BusinessLineType, businessLineTypeSelector, universalEventManagementPathSelector } from "@common/redux-helpers";

const constantFilters = {
    maxPageSize: 30,
    showCancelled: false,
    showCompleted: false,
    withFreeSeats: true,
};

const flattenBasket = (basket: Basket): RebookedAttendee[] => [...basket?.rebookedEventInstances?.map((ei) => ei.rebookedAttendees
    .map((a) => ({
        id: a.id,
        delegateId: a.delegateId,
        fullName: a.fullName,
        isBookingCanceled: a.isBookingCanceled,
        fromOrderId: a.fromOrderId,
        fromOrderWithBookNowPayLater: a.fromOrderWithBookNowPayLater,
        fromBookingReference: a.fromBookingReference,
        fromInvoiceReference: a.fromInvoiceReference,
        rebookedToEventInstanceId: a.rebookedToEventInstanceId,
        rebookedToSeatId: a.rebookedToSeatId,
        rebookedEventTypeName: a.rebookedEventTypeName,
        rebookedEventInstanceDeliveryDateTime: a.rebookedEventInstanceDeliveryDateTime,
        rebookedEventInstanceDeliveryDateTimeEnd: a.rebookedEventInstanceDeliveryDateTimeEnd,
        rebookedEventInstanceVenueName: a.rebookedEventInstanceVenueName,
        ignored: a.ignored,
        oldPricePerSeatInPence: a.oldPricePerSeatInPence,
        newPricePerSeatExcludingVatInPence: a.newPricePerSeatExcludingVatInPence,
        newPricePerSeatInPence: a.newPricePerSeatInPence,
        newPricePerSeatInPenceWithoutDiscount: a.newPricePerSeatInPenceWithoutDiscount,
        feesDue: a.feesDue,
        feesHalfDue: a.feesHalfDue,
        feesWaived: a.feesWaived,
        feesZeroed: a.feesZeroed,
        noDiscountOnFutureRebooking: a.noDiscountOnFutureRebooking,
        eventInstanceId: ei.id,
        eventTypeName: ei.eventTypeName,
        eventInstanceDeliveryDateTime: ei.eventInstanceDeliveryDateTime,
        eventInstanceDeliveryDateTimeEnd: ei.eventInstanceDeliveryDateTimeEnd,
        summary: false
    })))?.flat() || [], {
    id: "",
    summary: true
}];

export const RebookSelect = (props: OrderBaseProps) => {
    const { corporateOrganisationId, corporateUserId } = props;

    const [basket, setBasket] = React.useState<Basket>(undefined);
    const [attendeesToBeProcessed, setAttendeesToBeProcessed] = React.useState<RebookedAttendee[]>([]);
    const [attendeeCurrentlyProcessing, setAttendeeCurrentlyProcessing] = React.useState<string>(undefined);
    const [searchOptions, setSearchOptions] = React.useState<SearchOptions>(constantFilters);

    const dispatch = useDispatch();

    const businessLineType = useSelector(businessLineTypeSelector);
    const businessLineSupportsHalfRefunds = BusinessLineSupportsHalfRefund(businessLineType);
    const fullPeriodForBussinesLine = GetRefundFullPeriodForBusinessLine(businessLineType);
    const halfPeriodForBussinesLine = GetRefundHalfPeriodForBusinessLine(businessLineType);

    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    useSetBeforeGenesysAuthPage();

    React.useEffect(() => {
        const fetchData = async () => {
            const api = new OrderApi();
            const existingBasket = await api.getBasket(businessLineType, corporateOrganisationId, corporateUserId);
            setBasket(existingBasket);
            const flattenedAttendees = flattenBasket(existingBasket);
            setAttendeesToBeProcessed(flattenedAttendees);
            setAttendeeCurrentlyProcessing(flattenedAttendees[0].id);
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corporateOrganisationId, corporateUserId]);

    const eventInstancesWithOrdersState = useSelector(eventInstancesWithOrdersSelector);

    React.useEffect(() => {
        dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: WorkflowTypeEnum.CPC }));
    }, [dispatch]);

    const totalElements = React.useMemo(() => eventInstancesWithOrdersState.total, [eventInstancesWithOrdersState.total]);

    const totalPages = React.useMemo(() => {
        return totalElements
            ? Math.ceil(totalElements / searchOptions?.maxPageSize)
            : 1;
    }, [searchOptions, totalElements]);

    const onPageChange = React.useCallback((_: any, { activePage }: PaginationProps) => {
        setSearchOptions({ ...searchOptions, page: +activePage - 1 });
    }, [searchOptions]);

    const currentlyProcessedAttendee = React.useMemo(() => {
        return attendeesToBeProcessed.find((a) => a.id === attendeeCurrentlyProcessing);
    }, [attendeeCurrentlyProcessing, attendeesToBeProcessed]);

    const currentlyProcessedAttendeeIndex = React.useMemo(() => {
        return attendeesToBeProcessed.findIndex((a) => a.id === attendeeCurrentlyProcessing);
    }, [attendeeCurrentlyProcessing, attendeesToBeProcessed]);

    const hasPayNowRebookings = React.useMemo(() =>
        attendeesToBeProcessed.some(a => !a.summary && !a.fromOrderWithBookNowPayLater), [attendeesToBeProcessed]);

    const hasBookNowPayLaterRebookings = React.useMemo(() =>
        attendeesToBeProcessed.some(a => !a.summary && a.fromOrderWithBookNowPayLater), [attendeesToBeProcessed]);

    const total = React.useMemo(() => (attendeesToBeProcessed
        .filter(a => a.rebookedToEventInstanceId && !a.summary)
        .reduce((a, b) => {
            const oldPriceToBeRemoved = (b.feesHalfDue && !b.feesWaived)
                ? b.oldPricePerSeatInPence / 2
                : b.oldPricePerSeatInPence;

            return a + ((b.isBookingCanceled || (b.feesDue && !b.feesWaived && !b.feesZeroed))
                ? b.newPricePerSeatInPence
                : ((b.feesDue || b.feesHalfDue) && b.feesZeroed)
                    ? 0
                    : (b.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                        ? 0
                        : b.newPricePerSeatInPence - oldPriceToBeRemoved);
        }, 0) / 100), [attendeesToBeProcessed]);

    const totalBookNowPayLater = React.useMemo(() => (attendeesToBeProcessed
        .filter(a => a.rebookedToEventInstanceId && !a.summary && a.fromOrderWithBookNowPayLater)
        .reduce((a, b) => {
            const oldPriceToBeRemoved = (b.feesHalfDue && !b.feesWaived)
                ? b.oldPricePerSeatInPence / 2
                : b.oldPricePerSeatInPence;

            return a + ((b.isBookingCanceled || (b.feesDue && !b.feesWaived && !b.feesZeroed))
                ? b.newPricePerSeatInPence
                : ((b.feesDue || b.feesHalfDue) && b.feesZeroed)
                    ? 0
                    : (b.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                        ? 0
                        : b.newPricePerSeatInPence - oldPriceToBeRemoved);
        }, 0) / 100), [attendeesToBeProcessed]);

    const totalToBePaid = React.useMemo(() => total - totalBookNowPayLater, [total, totalBookNowPayLater]);

    const totalRefund = React.useMemo(() => (attendeesToBeProcessed
        .filter(a => a.rebookedToEventInstanceId && !a.summary)
        .reduce((a, b) => {
            const oldPriceToBeRemoved = (b.feesHalfDue && !b.feesWaived)
                ? b.oldPricePerSeatInPence / 2
                : b.oldPricePerSeatInPence;

            return a + ((b.isBookingCanceled || (b.feesDue && !b.feesWaived && !b.feesZeroed))
                ? 0
                : ((b.feesDue || b.feesHalfDue) && b.feesZeroed)
                    ? 0
                    : (b.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                        ? oldPriceToBeRemoved - b.newPricePerSeatInPence
                        : 0);
        }, 0) / 100), [attendeesToBeProcessed]);

    const totalBookNowPayLaterRefund = React.useMemo(() => (attendeesToBeProcessed
        .filter(a => a.rebookedToEventInstanceId && !a.summary && a.fromOrderWithBookNowPayLater)
        .reduce((a, b) => {
            const oldPriceToBeRemoved = (b.feesHalfDue && !b.feesWaived)
                ? b.oldPricePerSeatInPence / 2
                : b.oldPricePerSeatInPence;

            return a + ((b.isBookingCanceled || (b.feesDue && !b.feesWaived && !b.feesZeroed))
                ? 0
                : ((b.feesDue || b.feesHalfDue) && b.feesZeroed)
                    ? 0
                    : (b.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                        ? oldPriceToBeRemoved - b.newPricePerSeatInPence
                        : 0);
        }, 0) / 100), [attendeesToBeProcessed]);

    const totalRefundForToBePaid = React.useMemo(() => totalRefund - totalBookNowPayLaterRefund, [totalRefund, totalBookNowPayLaterRefund]);

    const eventInstancesAlreadyInBasket = React.useMemo(() => basket?.rebookedEventInstances?.map(
        (ei) => ei.rebookedAttendees.filter(a => a.rebookedToEventInstanceId).map(
            (a) => a.rebookedToEventInstanceId)).flat() || [], [basket?.rebookedEventInstances]);

    const isWithinFullyDuePeriod = React.useMemo(() => {
        const differenceInDays = currentlyProcessedAttendee?.eventInstanceDeliveryDateTime?.diff(moment(), "days");
        return differenceInDays < fullPeriodForBussinesLine;
    }, [currentlyProcessedAttendee, fullPeriodForBussinesLine]);

    const isWithinHalfDuePeriod = React.useMemo(() => {
        const differenceInDays = currentlyProcessedAttendee?.eventInstanceDeliveryDateTime?.diff(moment(), "days");
        return businessLineSupportsHalfRefunds && differenceInDays >= fullPeriodForBussinesLine && differenceInDays < halfPeriodForBussinesLine;
    }, [currentlyProcessedAttendee, businessLineSupportsHalfRefunds, fullPeriodForBussinesLine, halfPeriodForBussinesLine]);

    React.useEffect(() => {
        if (currentlyProcessedAttendee?.id) {
            dispatch(loadEventInstancesWithOrders({
                options: {
                    ...searchOptions,
                    ...constantFilters,
                    businessLineType,
                    rebookingAttendeeId: currentlyProcessedAttendee.id,
                    rebookingAttendeeFeesFullyDue: currentlyProcessedAttendee.isBookingCanceled
                        || ((isWithinFullyDuePeriod || currentlyProcessedAttendee.feesDue) && !currentlyProcessedAttendee.feesWaived),
                    rebookingAttendeeFeesHalfDue: !currentlyProcessedAttendee.isBookingCanceled
                        && ((isWithinHalfDuePeriod || currentlyProcessedAttendee.feesHalfDue) && !currentlyProcessedAttendee.feesWaived),
                    noCheckForOrdersAttached: true,
                    originalEventInstanceId: currentlyProcessedAttendee.eventInstanceId,
                    ignoreOriginalEventInstanceId: !currentlyProcessedAttendee.isBookingCanceled,
                    ignoreEventInstancesFromDelegateId: currentlyProcessedAttendee.delegateId,
                    includeEventInstanceIds: eventInstancesAlreadyInBasket,
                    discountLost: currentlyProcessedAttendee.noDiscountOnFutureRebooking || isWithinFullyDuePeriod
                }
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, searchOptions, currentlyProcessedAttendee?.id]);

    const goBackToPreviousAttendee = React.useCallback(() => {
        setAttendeeCurrentlyProcessing(attendeesToBeProcessed[currentlyProcessedAttendeeIndex - 1].id);
    }, [attendeesToBeProcessed, currentlyProcessedAttendeeIndex]);

    const goToNextAttendee = React.useCallback(() => {
        setAttendeeCurrentlyProcessing(attendeesToBeProcessed[currentlyProcessedAttendeeIndex + 1].id);
    }, [attendeesToBeProcessed, currentlyProcessedAttendeeIndex]);

    const handleBasketChanges = React.useCallback((changedBasket: Basket) => {
        setBasket(changedBasket);
        const flattenedAttendees = flattenBasket(changedBasket);
        setAttendeesToBeProcessed(flattenedAttendees);
        setAttendeeCurrentlyProcessing(flattenedAttendees[currentlyProcessedAttendeeIndex].id);

        if (changedBasket?.offeredEventInstancesNewCounts?.length > 0) {
            dispatch(fixEventInstancesWithOrders(changedBasket.offeredEventInstancesNewCounts));
        }
    }, [currentlyProcessedAttendeeIndex, dispatch]);

    const handleSelectedEventInstance = React.useCallback(async (eventInstanceId: string) => {
        const api = new OrderApi();
        const newEventInstanceId = currentlyProcessedAttendee.rebookedToEventInstanceId === eventInstanceId ? null : eventInstanceId;
        const changedBasket = await api.rebookSeat(businessLineType, basket.id, currentlyProcessedAttendee.eventInstanceId, currentlyProcessedAttendee.id,
            newEventInstanceId);

        if (!changedBasket?.id) {
            toast.error("Basket no longer exists");
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
            return;
        }

        const eventInstanceIdRebookedToAfterCall = changedBasket?.rebookedEventInstances?.find(ei =>
            ei.id === currentlyProcessedAttendee.eventInstanceId)?.rebookedAttendees.find(a =>
            a.id === currentlyProcessedAttendee.id)?.rebookedToEventInstanceId;
        handleBasketChanges(changedBasket);

        if (newEventInstanceId && eventInstanceIdRebookedToAfterCall !== eventInstanceId) {
            toast.warning("System was not able to book seat for the selected course. Please try again or choose different course.");
        } else if (currentlyProcessedAttendee.rebookedToEventInstanceId !== eventInstanceId) {
            window.scrollTo(0, 0);
            goToNextAttendee();
        }
    }, [handleBasketChanges, currentlyProcessedAttendee, goToNextAttendee, basket, corporateOrganisationId, corporateUserId, dispatch,
        businessLineType, eventManagementPath]);

    const changeFeesStatus = React.useCallback(async (attendeeId: string, feesStatus: number) => {
        const attendee = attendeesToBeProcessed.find(a => a.id === attendeeId);
        const api = new OrderApi();
        const changedBasket = await api.rebookSeat(
            businessLineType,
            basket.id,
            attendee.eventInstanceId,
            attendee.id,
            attendee.rebookedToEventInstanceId,
            feesStatus === FeesStatusEnum.IncreaseOnly,
            feesStatus === FeesStatusEnum.No
        );

        if (!changedBasket?.id) {
            toast.error("Basket no longer exists");
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
            return;
        }

        handleBasketChanges(changedBasket);
    }, [attendeesToBeProcessed, handleBasketChanges, basket, corporateOrganisationId, corporateUserId, dispatch, businessLineType, eventManagementPath]);

    const handleRebooking = React.useCallback(async () => {
        if (totalToBePaid > 0) {
            dispatch(
                loadPayment(
                    businessLineType,
                    basket.id,
                    corporateOrganisationId,
                    corporateUserId,
                    "rebooking"
                )
            );
        } else {
            await dispatch(completeProcess({ businessLineType, basketId: basket.id, amount: 0 }, corporateOrganisationId, corporateUserId));
        }
    }, [basket, corporateOrganisationId, corporateUserId, dispatch, totalToBePaid, businessLineType]);

    const summaryDisabled = React.useMemo(() => attendeesToBeProcessed.filter(a => a.rebookedToEventInstanceId).length === 0, [attendeesToBeProcessed]);

    const attendeeOptions = React.useMemo(() => attendeesToBeProcessed.map((a, i) => ({
        key: `attendeeOption_${i}`,
        text: <>
            {!a.summary && <Icon
                name={a.rebookedToEventInstanceId ? "check circle" : "times circle"}
                className={a.rebookedToEventInstanceId ? "check-circle" : "times-circle"}
            />}
            <span>{a.summary ? "Summary" : `${a.fullName}, ${a.eventTypeName}, ${a.eventInstanceDeliveryDateTime.format("ddd DD/MM/YYYY HH:mm")}`
                + ` - ${a.eventInstanceDeliveryDateTimeEnd?.format("HH:mm")}`}</span>
        </>,
        value: i,
        disabled: a.summary && summaryDisabled
    })), [attendeesToBeProcessed, summaryDisabled]);

    const onCurrentlyProcessedAttendeeChange = React.useCallback((_, { value }) => {
        setAttendeeCurrentlyProcessing(attendeesToBeProcessed[value].id);
    }, [attendeesToBeProcessed]);

    return (
        <div className="rebook-select-page">
            {!basket?.rebookedEventInstances ? (
                <Loader />
            ) : (
                <>
                    <div className="progress">
                        {currentlyProcessedAttendeeIndex !== (attendeesToBeProcessed.length - 1) && (<h4 className="title">You are rebooking</h4>)}
                        <Form.Dropdown
                            floating
                            selection
                            value={currentlyProcessedAttendeeIndex}
                            options={attendeeOptions}
                            onChange={onCurrentlyProcessedAttendeeChange}
                            search
                            className="summary-selector"
                        />
                        <div className="progress_chevrons">
                            <div className="select-course-button">
                                <Button
                                    icon="chevron left"
                                    disabled={currentlyProcessedAttendeeIndex === 0}
                                    onClick={goBackToPreviousAttendee}
                                    className="link-button select-link-button"
                                />
                            </div>
                            <h4 className="seat-number">
                                {currentlyProcessedAttendeeIndex !== (attendeesToBeProcessed.length - 1)
                                    ? `Seat ${currentlyProcessedAttendeeIndex + 1} of ${attendeesToBeProcessed.length - 1}`
                                    : "Summary"}
                            </h4>
                            <div className="select-course-button">
                                <Button
                                    icon="chevron right"
                                    disabled={currentlyProcessedAttendeeIndex === attendeesToBeProcessed.length - 1
                                        || (summaryDisabled && currentlyProcessedAttendeeIndex === attendeesToBeProcessed.length - 2)}
                                    onClick={goToNextAttendee}
                                    className="link-button select-link-button"
                                />
                            </div>
                        </div>
                    </div>
                    {currentlyProcessedAttendee?.id && (
                        <RebookEventInstancesFilters
                            searchOptions={searchOptions}
                            onOptionsChanged={setSearchOptions}
                            corporateOrganisationId={corporateOrganisationId}
                        />
                    )}
                    {currentlyProcessedAttendee?.summary && (
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    {hasBookNowPayLaterRebookings && (
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={16} className="bolden-summary">Book Now Pay Later Rebookings</Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={2}>Attendee</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>Original Course</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>Rebooked Course</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Previous Price</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>New Price</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Fees Due</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Refund Due</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Amount to Pay</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {attendeesToBeProcessed
                                                    .filter(a => !a.summary && a.fromOrderWithBookNowPayLater)
                                                    .map((attendeeToBeProcessed, index) => {
                                                        return (
                                                            <RebookSelectSummaryRow
                                                                key={`rebookSelectSummaryRow_${index}`}
                                                                rebookedAttendee={attendeeToBeProcessed}
                                                                changeFeesStatus={changeFeesStatus}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Table.Body>
                                            <Table.Footer fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={12} className="bolden-summary">Total</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>&pound;{numberFormatter(totalBookNowPayLaterRefund)}</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>&pound;{numberFormatter(totalBookNowPayLater)}</Table.HeaderCell>
                                                </Table.Row>
                                                {businessLineType === BusinessLineType.Corporate && (
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan={16} className="bolden-summary">
                                                            Price includes VAT and DVSA registration fees
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                )}
                                            </Table.Footer>
                                        </Table>
                                    )}
                                    {hasPayNowRebookings && (
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={16} className="bolden-summary">Pay Now Rebookings</Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={2}>Attendee</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>Original Course</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>Rebooked Course</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Previous Price</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>New Price</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Fees Due</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Refund Due</Table.HeaderCell>
                                                    <Table.HeaderCell className="fixed-column-width" colSpan={2}>Amount to Pay</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {attendeesToBeProcessed
                                                    .filter(a => !a.summary && !a.fromOrderWithBookNowPayLater)
                                                    .map((attendeeToBeProcessed, index) => {
                                                        return (
                                                            <RebookSelectSummaryRow
                                                                key={`rebookSelectSummaryRow_${index}`}
                                                                rebookedAttendee={attendeeToBeProcessed}
                                                                changeFeesStatus={changeFeesStatus}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Table.Body>
                                            <Table.Footer fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan={12} className="bolden-summary">Total</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>&pound;{numberFormatter(totalRefundForToBePaid)}</Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={2}>&pound;{numberFormatter(totalToBePaid)}</Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    {businessLineType === BusinessLineType.Corporate && (
                                                        <Table.HeaderCell colSpan={16} className="bolden-summary">
                                                            Price includes VAT and DVSA registration fees
                                                        </Table.HeaderCell>
                                                    )}
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    )}
                                    <div className="rebookActionsPanel">
                                        <Button
                                            content={totalToBePaid > 0 ? "Go To Payment" : "Complete Rebooking"}
                                            onClick={handleRebooking}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                    {currentlyProcessedAttendee?.id && (
                        <>
                            <div className="courses-list">
                                {(eventInstancesWithOrdersState?.eventInstancesWithOrders?.length || 0) === 0 && <div>No courses found</div>}
                                {(eventInstancesWithOrdersState?.eventInstancesWithOrders || []).map((ei, i) => (
                                    <OfferedRebookedCourseDetails
                                        key={`offeredRebookedCourseDetails_${i}`}
                                        offeredCourse={ei}
                                        selectedEventInstanceId={currentlyProcessedAttendee?.rebookedToEventInstanceId}
                                        setSelectedEventInstance={handleSelectedEventInstance}
                                    />
                                ))}
                            </div>
                            {totalPages > 1 && (
                                <Grid stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TTCPagination
                                                activePage={searchOptions?.page + 1 || 1}
                                                totalPages={totalPages}
                                                onPageChange={onPageChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
