import * as React from "react";
import { Fragment, Location } from "redux-little-router";
import { Authorize } from "reauthorize";
import {
    TtcPlannerRole,
    EventManagementAdminRolesAndTrainers,
    PoliceRole,
    MonitorRole, TtcClientAdvisorRole, TtcSeniorClientAdvisorRole, CorporateHomeRole,
    ConstructionHomeRole
} from "@common/auth/model";
import { Base as Attendees } from "../../attendee";
import { Area } from "../model";
import { Detail } from "./Detail";
import { Edit } from "./Edit";
import { Create } from "./Create";
import { EventInstanceHistory } from "./register/EventInstanceHistory";
import { BulkBaseCreate } from "./bulkEventCreation/BulkCreateBase";
import { Communications } from "./details/Communications";
import { SpecialRequirements } from "./details/SpecialRequirements";
import { BookingsHistory } from "@common/crud/bookingsHistory/components/BookingsHistory";
import { TrainerMonitoring } from "@common/crud/eventInstance/components/monitoring/TrainerMonitoring";
import { DigitalPlanningBase } from "./digitalPlanning/DigitalPlanningBase";
import { DigitalPlanningOverview } from "./digitalPlanning/DigitalPlanningOverview";
import { AttendeeRedirect } from "@common/crud/eventInstance/components/details/AttendeeRedirect";
import { HealthAndSafety } from "@common/crud/eventInstance/components/details/HealthAndSafety";
import { All } from "./All";
import { EventInstanceListWithFilters } from "./EventInstanceListWithFilters";
import { DrinkDriveEventInstanceListWithFilters } from "./drinkDrive/DrinkDriveEventInstanceListWithFilters";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { DdrsDigitalPlanningOverview } from "./digitalPlanning/DdrsDigitalPlanningOverview";
import { ConstructionWorkflowTypeArray, CorporateWorkflowTypeArray, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { SpreadingOverview } from "./spreading/SpreadingOverview";
import { Files } from "./files/Files";
import { CorporateEventInstanceListWithFilters } from "./CorporateEventInstanceListWithFilters";
import { EventInstanceTrainerViewFactory } from "./EventInstanceTrainerViewFactory";

const noEventInstanceId = (location: Location) => location.params.eventInstanceId === undefined;
type FragmentFor = "dors" | "corp" | "construction" | "ddrs";
export const CommonBase: React.FC<{ area: Area; fragmentFor: FragmentFor; workflowType?: WorkflowTypeEnum }> = ({ area, fragmentFor, workflowType }) => {

    const dorsFragment = fragmentFor === "dors";
    const bookingHistoryRoles = getBookingHistoryRoles(fragmentFor);
    const authorizeCoursesList = getAuthorizeCoursesList(fragmentFor);

    return (
        <div>
            <Fragment forRoute="/bulk-create">
                <BulkBaseCreate eventTypeCategory={EventTypeCategory.Dors} />
            </Fragment>
            <Fragment forRoute="/ddrs-bulk-create">
                <BulkBaseCreate eventTypeCategory={EventTypeCategory.Ddrs} />
            </Fragment>
            <Fragment forRoute="/digital-planning">
                <DigitalPlanningBase eventTypeCategory={EventTypeCategory.Dors} />
            </Fragment>
            <Fragment forRoute="/ddrs-digital-planning">
                <DigitalPlanningBase eventTypeCategory={EventTypeCategory.Ddrs} />
            </Fragment>
            <Fragment forRoute="/create-courses">
                <DigitalPlanningOverview />
            </Fragment>
            <Fragment forRoute="/ddrs-create-courses">
                <DdrsDigitalPlanningOverview />
            </Fragment>
            <Fragment forRoute="/spreading-overview">
                <Authorize authorize={[TtcPlannerRole]}>
                    <SpreadingOverview />
                </Authorize>
            </Fragment>
            <Fragment forRoute="/" withConditions={noEventInstanceId}>
                <div>
                    <Authorize authorize={authorizeCoursesList}>
                        <All extraSpacing={dorsFragment} area={area}>
                            <EventInstancesListFactory fragmentFor={fragmentFor} area={area} workflowType={workflowType} />
                        </All>
                    </Authorize>
                    <Authorize authorize={[TtcPlannerRole]}>
                        <Create speficicWorkflowType={dorsFragment ? WorkflowTypeEnum.Dors : WorkflowTypeEnum.DDRS} />
                    </Authorize>
                </div>
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/attendees">
                <Attendees area={area} />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/delegates">
                <Attendees area={area} />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/health-and-safety">
                <HealthAndSafety />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/attendee-redirect/:reservedSeatId">
                <AttendeeRedirect />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/trainers">
                <EventInstanceTrainerViewFactory />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/communications">
                <Communications />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/special-requirements">
                <SpecialRequirements />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/history">
                <EventInstanceHistory />
            </Fragment>
            <Authorize authorize={[...EventManagementAdminRolesAndTrainers, MonitorRole]}>
                <Fragment forRoute="/:eventInstanceId/monitoring">
                    <TrainerMonitoring />
                </Fragment>
            </Authorize>
            <Authorize authorize={bookingHistoryRoles}>
                <Fragment forRoute="/:eventInstanceId/bookings-history">
                    <BookingsHistory />
                </Fragment>
            </Authorize>
            <Authorize authorize={TtcClientAdvisorRole}>
                <Fragment forRoute="/:eventInstanceId/files">
                    <Files />
                </Fragment>
            </Authorize>
            <Fragment forRoute="/:eventInstanceId">
                <div>
                    <Detail />
                    <Authorize authorize={[TtcPlannerRole]}>
                        <Edit />
                    </Authorize>
                </div>
            </Fragment>
        </div>
    );
};

function EventInstancesListFactory({ fragmentFor, area, workflowType }: { fragmentFor: FragmentFor; area: Area; workflowType: WorkflowTypeEnum }) {
    const workflowTypes = getWorkflowTypes(fragmentFor, workflowType);

    if (fragmentFor === "dors") {
        return <EventInstanceListWithFilters area={area} workflowTypes={workflowTypes} />;
    }

    if (fragmentFor === "corp" || fragmentFor === "construction") {
        return <CorporateEventInstanceListWithFilters area={area} workflowTypes={workflowTypes} />;
    }

    return <DrinkDriveEventInstanceListWithFilters area={area} />;
}

function getWorkflowTypes(fragmentFor: FragmentFor, workflowType?: WorkflowTypeEnum) {

    if (workflowType) {
        return [workflowType];
    }
    if (fragmentFor === "dors") {
        return [WorkflowTypeEnum.Dors];
    }

    if (fragmentFor === "corp") {
        return CorporateWorkflowTypeArray;
    }

    if (fragmentFor === "construction") {
        return ConstructionWorkflowTypeArray;
    }

    return [WorkflowTypeEnum.DDRS];
};

function getBookingHistoryRoles(fragmentFor: FragmentFor) {

    const baseRoles = [TtcClientAdvisorRole, TtcSeniorClientAdvisorRole];
    if (fragmentFor === "dors") {
        return [...baseRoles, PoliceRole];
    }
    if (fragmentFor === "corp") {
        return [...baseRoles, CorporateHomeRole];
    }

    if (fragmentFor === "construction") {
        return [...baseRoles, ConstructionHomeRole];
    }
    return baseRoles;
};

function getAuthorizeCoursesList(fragmentFor: FragmentFor) {
    if (fragmentFor === "dors") {
        return [...EventManagementAdminRolesAndTrainers, PoliceRole];
    }
    if (fragmentFor === "corp") {
        return [...EventManagementAdminRolesAndTrainers, CorporateHomeRole];
    }

    if (fragmentFor === "construction") {
        return [...EventManagementAdminRolesAndTrainers, ConstructionHomeRole];
    }
    return EventManagementAdminRolesAndTrainers;
};

