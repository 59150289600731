/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Button, Checkbox, CheckboxProps, Confirm, Container, Divider, Form, Icon, Label } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, createBasePathSelector } from "@common/redux-helpers";
import { selectors as venueSelectors, Venue, VenueState } from "@common/crud/venue";
import moment from "moment";
import {
    AllowedWorkflowTypeEnum,
    BookingType,
    BookingTypeEnum,
    BusinessDriverProductCategories,
    CertificateType,
    ConstructionWorkflowTypeArray,
    CorporateCertificateType,
    CorporateWorkflowTypeArray,
    DurationType,
    DurationTypeEnum,
    EventTypePart,
    GetEducationDuration,
    GetEventClassroomBreakEvenPoints,
    GetEventClassroomRegistrationDuration,
    GetEventDigitalBreakEvenPoints,
    GetEventDigitalRegistrationDuration,
    GetEventDuration,
    GetEventMaxAttendeesPerClassroomTrainer,
    GetEventMaxAttendeesPerDigitalTrainer,
    GetEventMaxClassroomAttendees,
    GetEventMaxDigitalAttendees,
    GetEventPracticalDuration,
    GetEventRegistrationEndTime,
    GetEventTheoryDuration,
    GetProductCategories,
    InitialiseEventTypeParts,
    InitialiseGroupItemStartDates,
    ModuleType,
    ModuleTypeEnum,
    PoliceAndCourtWorkflowTypeArray,
    ProductCategory,
    ProductCategoryEnum,
    WorkflowTypeEnum
} from "@common/crud/eventType/model";
import { TrainerAttributeDefinition, TrainerAttributeDefinitionState } from "@common/crud/trainerAttributeDefinition";
import { validators } from "not-valid";
import { Address, optionsFromObject } from "@common/crud/common";
import { LoadingState } from "redux-request-loading";
import { EventType, EventTypeState } from "../../eventType";
import { Trainer, TrainerState } from "../../trainer";
import { createEventInstance } from "../actions";
import { basePathSelector, ddrsBasePathSelector } from "../selectors";
import {
    AppState,
    BookingAvailabilityType,
    BookingAvailabilityTypeEnum,
    EnquiryType,
    EventInstanceCreateModel,
    FinanceDetails,
    Language,
    OrganisationOption,
    UpdateClosedCourseCommsDestinationsModel,
    WorkflowTypeToEventTypeCategory
} from "../model";
import { CreateModal } from "./CreateModal";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { DigitalCourseEdit } from "./DigitalCourseEdit";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { toast } from "@common/toasts";
import { MultiDayEventTypePartEdit } from "@common/crud/eventInstance/components/MultiDayEventTypePartEdit";
import { SingleDayPropsForCreate } from "@common/crud/eventInstance/components/SingleDayPropsForCreate";
import { PoliceContract } from "@common/policeContracts";
import { policeContractsSelector } from "@common/policeContracts/selector";
import { WorkflowType } from "./../../eventType/model";
import { filterAndSortPurchaseOrders, totalDurationEqualsSchemeTotal } from "../helpers";
import { CompanyTypeEnum, OrganisationState, PurchaseOrder, SchemeDeliveryTypeEnum, VenueTypeEnum } from "@common/crud/organisation/model";
import { BankHoliday } from "@common/bankHolidays/model";
import { BankHolidaysApi } from "@common/bankHolidays/bankHolidaysApi";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { constructionOrganisationOptionsSelector, corporateOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { nonExpiredEventTypes } from "@common/helpers/expiredEventTypes";
import { eventTypeIsMultiDay, isBusinessLineTypeCorporateOrConstruction, isBusinessLineTypePoliceOrCourt, isEventTypeWorkflowConstruction,
    isEventTypeWorkflowCorporate, isEventTypeWorkflowPoliceOrCourt, isVenueConstruction, isVenueCorporate, isVenuePoliceAndCourt,
    isWorkflowConstruction, isWorkflowCorporate, supportsOnRoadReport, workflowTypeSupportsCertificate } from "@common/global/CommonHelpers";
import { CorporateQuickAddEntityDictionary, QuickAddEntities, QuickAddEntityDictionary } from "@common/crud/quickAdd/model";
import { CustomerStatusEnum } from "@common/crud/alaskaNudgeTask/model";
import { isBusinessDriverAdmin, isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { AppCommonState } from "@common/appCommonState";
import { OrganisationApi } from "@common/crud/organisation";
import { numberFormatter } from "@common/helpers/number-format";
import { EventInstanceApi } from "../eventInstanceApi";
import { ClosedContactsForm } from "./contacts/ClosedContactsForm";
import { PurchaseOrderDropdown } from "@common/crud/organisation/components/PurchaseOrderDropdown";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { isVenueTypeCorrectForConstruction, isVenueTypeCorrectForCorporate,
    isVenueTypeCorrectForPoliceAndCourt, venueHasCorrectDeliveryType } from "@common/crud/venue/components/helpers";
import { rangeValidator } from "@common/validation";
import { minValidator } from "@common/validation/minValidator";
import { Category } from "@common/appSettings/model";
import { EditCourseCategories } from "@common/components/EditCourseCategories";

export interface CreateProps {
    open: boolean;
    venues: Venue[];
    trainers: Trainer[];
    eventTypes: EventType[];
    trainerAttributeDefinitions: TrainerAttributeDefinition[];
    loading: boolean;
    policeContracts: PoliceContract[];
    quickAddId: string;
    quickAddType?: QuickAddEntities;
    businessLineType: BusinessLineType;
    organisationOptions: OrganisationOption[];
    isBusinessDriverAdminRole: boolean;
    isTtcCorporateAdminRole: boolean;
    workflowTypes: (AllowedWorkflowTypeEnum | WorkflowTypeEnum.None)[];
    constructionCategories: Category[];
}

interface OwnProps {
    speficicWorkflowType?: AllowedWorkflowTypeEnum;
    corporateOrganisationId?: string;
}

export interface DispatchProps extends SaveDispatchProps<EventInstanceCreateModel> {
    close: () => void;
}

interface CreateFormState extends FormState<EventInstanceCreateModel> {
    availableSuggestedTimes: { key: number; text: string; value: number }[];
    availableSuggestedTheoryTimes: { key: number; text: string; value: number }[];
    availableSuggestedPracticalTimes: { key: number; text: string; value: number }[];
    showOther: boolean;
    ddStartTime: number;
    ddPracticalTime: number;
    venueAttributesDisplay: string;
    reducedVenues: Venue[];
    reducedProductCategories: { text: string; value: number }[];
    eventTypeParts: Dictionary<EventTypePart>;
    groupItemStartDates: Dictionary<moment.Moment>;
    showNoContractError: boolean;
    quickAddId: string;
    showDurationError: boolean;
    purchaseOrders: PurchaseOrder[];
    courseFees: FinanceDetails;
}

export class CreateForm extends FormBaseComponent<EventInstanceCreateModel, CreateProps & OwnProps, CreateFormState> {
    constructor(props: CreateProps & SaveDispatchProps<EventInstanceCreateModel> & OwnProps) {
        super(props);
        this.state = this.initialState;
        this.submitIgnoringNoContractWarning = this.submitIgnoringNoContractWarning.bind(this);
        this.practicalStartTimeValidationPredicate = this.practicalStartTimeValidationPredicate.bind(this);
        this.addNewPurchaseOrder = this.addNewPurchaseOrder.bind(this);
    }

    public bankHolidays: BankHoliday[];

    public initialValueState: EventInstanceCreateModel = {
        id: "",
        eventInstanceDeliveryType: DeliveryTypeEnum.Onsite,
        eventTypeId: this.props.eventTypes?.filter(e => this.props.workflowTypes.includes(e.workflowType)).length === 1 ?
            this.props.eventTypes?.filter(e => this.props.workflowTypes.includes(e.workflowType))[0].id
            : "",
        eventTypeName: "",
        eventTypeAbbreviation: "",
        maxNumberOfAttendees: 0,
        maxNumberOfAttendeesPerPracticalTrainer: 0,
        eventDuration: moment.duration(),
        startDate: moment().clone().utc(true).startOf("day"),
        publishDate: null,
        startTime: moment.duration(),
        registrationEndTime: moment.duration(),
        venueId: "",
        venueName: "",
        moduleType: "",
        durationType: undefined,
        oneToOne: false,
        trainerAttributes: [],
        language: 1,
        workflowType: this.props.workflowTypes.length === 1 ? this.props.workflowTypes[0] : 0,
        adminNote: "",
        bookingNoteEn: "",
        bookingNoteCy: "",
        availableInBookingJourney: BookingAvailabilityTypeEnum.Both,
        venueDorsId: 0,
        dsaArea: 0,
        referredCourtCountry: null,
        productCategory: 0,
        corporateOrganisationId: this.props.quickAddType === QuickAddEntities.OpenCourse
            ? this.props.organisationOptions?.find(org => org.open)?.value
            : this.props.corporateOrganisationId,
        eventTypeCategory: this.props.speficicWorkflowType? WorkflowTypeToEventTypeCategory[this.props.speficicWorkflowType] :undefined,
        bookingType: this.props.quickAddType === QuickAddEntities.OpenCourse
            ? BookingTypeEnum.Open
            : this.props.corporateOrganisationId
                ? this.props.organisationOptions?.find(org => org.value === this.props.corporateOrganisationId)?.open
                    ? BookingTypeEnum.Open
                    : BookingTypeEnum.Closed
                : BookingTypeEnum.Closed,
        educationDuration: moment.duration(),
        existingPurchaseOrdersList: [],
        locationDescription: "",
        enquiryType: EnquiryType.NotEnquiry,
        classroomBreakEvenPoint: 0,
        digitalBreakEvenPoint: 0,
        courseCategories: [],
    };

    public initialState: CreateFormState = { values: this.initialValueState,
        quickAddId: "",
        valid: {},
        availableSuggestedTimes: [],
        availableSuggestedTheoryTimes: [],
        availableSuggestedPracticalTimes: [],
        showOther: false,
        ddStartTime: 0,
        ddPracticalTime: 0,
        venueAttributesDisplay: "",
        reducedVenues: this.props.venues,
        reducedProductCategories: this.getProductCategories(this.props.isBusinessDriverAdminRole, this.props.isTtcCorporateAdminRole),
        eventTypeParts: {},
        groupItemStartDates: {},
        showNoContractError: false,
        showDurationError: false,
        purchaseOrders: [],
        courseFees: null
    };

    public componentWillReceiveProps(nextProps: Readonly<CreateProps & OwnProps & SaveDispatchProps<EventInstanceCreateModel>>): void {
        if (this.props.loading) {
            return;
        }

        this.handleOrganisationUpdates(this.props, nextProps);

        if (nextProps.isBusinessDriverAdminRole !== this.props.isBusinessDriverAdminRole ||
            nextProps.isTtcCorporateAdminRole !== this.props.isTtcCorporateAdminRole) {
            this.setState({
                reducedProductCategories: this.getProductCategories(nextProps.isBusinessDriverAdminRole, nextProps.isTtcCorporateAdminRole)
            });
        }
    }

    public componentDidUpdate(_: any, prevState: CreateFormState) {
        if (this.props.quickAddId !== this.state.quickAddId) {
            this.setState({ ...this.initialState, quickAddId: this.props.quickAddId });
        }

        if (this.shouldUpdateCourseFees(prevState)) {
            this.updateCourseFees();
        }

        if (this.shouldUpdateTotalFees(prevState)) {
            this.updateTotalFees();
        }
    }

    private async updateCourseFees() {
        const { corporateOrganisationId, eventTypeId, eventInstanceDeliveryType, startDate } = this.state.values;
        const courseFees = await this.getCourseFees(corporateOrganisationId, eventTypeId, eventInstanceDeliveryType, startDate);
        this.setState({ courseFees });
        this.updateProperty("closedCourseFee", courseFees.closedCourseFee, true);
        this.updateProperty("uploadFee", courseFees.uploadFee, true);
    }

    private updateTotalFees() {
        const { miscellaneousOrganisationFee, closedCourseFee, uploadFee, maxNumberOfAttendees } = this.state.values;
        const safeMiscellaneousOrganisationFee = isNaN(miscellaneousOrganisationFee) ? 0 : miscellaneousOrganisationFee;
        const safeClosedCourseFee = isNaN(closedCourseFee) ? 0 : closedCourseFee;
        const safeUploadFee = isNaN(uploadFee) ? 0 : uploadFee;
        const safeMaxNumberOfAttendees = isNaN(maxNumberOfAttendees) ? 0 : maxNumberOfAttendees;

        const totalFeesInPence = (
            safeMiscellaneousOrganisationFee +
            safeClosedCourseFee +
            (safeUploadFee * safeMaxNumberOfAttendees)
        ) * 100;

        this.updateProperty("totalFeesInPence", totalFeesInPence, true);
    };

    private updatePurchaseOrder = (index: number) => (value: string, valid: boolean) => {
        this.updateExistingPurchaseOrders(index, value, valid);
    };

    private addNewPurchaseOrder() {
        this.updateProperty("existingPurchaseOrdersList", [
            ...this.state.values.existingPurchaseOrdersList || [],
            null
        ], true);
    }

    private handleOrganisationUpdates(currentProps: Readonly<CreateProps & OwnProps & SaveDispatchProps<EventInstanceCreateModel>>,
        nextProps?: Readonly<CreateProps & OwnProps & SaveDispatchProps<EventInstanceCreateModel>>) {
        if (nextProps && nextProps.corporateOrganisationId === currentProps.corporateOrganisationId &&
                nextProps.organisationOptions === currentProps.organisationOptions) {
            return;
        }
        const propsToUse = nextProps ?? currentProps;

        if (isBusinessLineTypeCorporateOrConstruction(propsToUse.businessLineType) &&
            propsToUse.corporateOrganisationId &&
            propsToUse.organisationOptions) {
            const organisation = propsToUse.organisationOptions.find(org =>
                propsToUse.quickAddType === QuickAddEntities.OpenCourse ? org.open : org.value === propsToUse.corporateOrganisationId
            );

            this.updateProperty("corporateOrganisationId", propsToUse.corporateOrganisationId, true);
            this.updateProperty("bookingType", organisation?.open ? BookingTypeEnum.Open : BookingTypeEnum.Closed, true);
            this.updateProperty("delegatesRequired", !!organisation?.open, true);
        }
    }

    public async componentDidMount() {
        if (!this.bankHolidays) {
            this.bankHolidays = await new BankHolidaysApi().get();
        }

        this.handleOrganisationUpdates(this.props);
    }

    public practicalStartTimeValidationPredicate(v: string) {
        if (v !== undefined) {
            const parsedTime: moment.Duration = moment.duration(v);
            const minPossibleStartTime = this.state.values.theoryStartTime.clone().add(this.state.values.theoryDuration);
            return parsedTime > minPossibleStartTime;
        }

        return true;
    };

    public render() {
        const {
            values,
            showErrors,
            ddStartTime,
            availableSuggestedTimes,
            showOther,
            venueAttributesDisplay,
            ddPracticalTime
        } = this.state;
        const eventType = this.props.eventTypes.find(et => et.id === values.eventTypeId);
        const corporateOrganisation = this.props.organisationOptions.find(org => org.value === values.corporateOrganisationId);
        const workflowTypes = this.props.workflowTypes;
        const businessLineType = this.props.businessLineType;
        const allowedSchemes = this.props.eventTypes.filter(e => nonExpiredEventTypes(e.expiryDate) && workflowTypes.includes(e.workflowType));
        const cancelProps = { content: "Cancel", className: "cancel-action" };
        const venue = this.props.venues.find(v => v.id === values.venueId);

        let remainingCourseFees = this.state.values.totalFeesInPence / 100;

        const orgIsBusinessDriver = corporateOrganisation?.companyType?.some(ct => ct === CompanyTypeEnum.BusinessDriver);

        return (
            <Form onSubmit={this.handleSubmit}>
                {isBusinessLineTypePoliceOrCourt(businessLineType) && (
                    <Input.DropdownNumber
                        value={values.language}
                        label="Delivery Language"
                        showErrors={showErrors}
                        required
                        validation={[validators.requiredNumber()]}
                        options={optionsFromObject(Language)}
                        onChange={this.onChange("language")}
                    />
                )}
                {isBusinessLineTypeCorporateOrConstruction(businessLineType) && (
                    <>
                        <Input.Dropdown
                            value={values.corporateOrganisationId}
                            label="Organisation"
                            showErrors={showErrors}
                            required
                            options={this.props.organisationOptions
                                .filter(
                                    o => o.customerStatus === CustomerStatusEnum.Customer &&
                                        (!!(this.props.corporateOrganisationId || this.props.quickAddType === QuickAddEntities.OpenCourse) || !o.open)
                                ).map(opt => ({ key: opt.key, text: opt.text, value: opt.value }))}
                            onChange={this.updateOrganisation}
                            dynamicOptions
                            search
                            disabled={!!(this.props.corporateOrganisationId || this.props.quickAddType === QuickAddEntities.OpenCourse)}
                        />
                        <Input.DropdownNumber
                            label="Product Category"
                            placeholder="Select Product Category"
                            showErrors={showErrors}
                            value={values.productCategory}
                            dynamicOptions
                            options={this.state.reducedProductCategories}
                            onChange={this.updateProductCategory}
                            search
                            required
                        />
                    </>
                )}
                <Input.Dropdown
                    label={isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "Product" : "Scheme"}
                    placeholder={isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "Select Product" : "Select Scheme"}
                    showErrors={showErrors}
                    value={values.eventTypeId}
                    disabled={isBusinessLineTypeCorporateOrConstruction(businessLineType)
                        && (!corporateOrganisation || !values.productCategory)}
                    options={allowedSchemes
                        .filter(
                            c => (!c.expiryDate || c.expiryDate > moment.utc()) &&
                                (!c.productCategory || c.productCategory === values.productCategory) &&
                                (!corporateOrganisation ||
                                    (corporateOrganisation.open && c.bookingType !== BookingTypeEnum.Closed) ||
                                    (!corporateOrganisation.open && c.bookingType !== BookingTypeEnum.Open)) &&
                                (!corporateOrganisation || !corporateOrganisation.open || corporateOrganisation.open)
                        )
                        .map(
                            c => ({ key: c.id, text: c.name, value: c.id })
                        )}
                    onChange={this.updateEventType}
                    dynamicOptions
                    search
                    required
                />
                {businessLineType === BusinessLineType.Construction && (
                    <>
                        {venue?.venueType === VenueTypeEnum.Enquiry && (
                            <Input.Checkbox
                                label="Reseller Course"
                                value={Boolean(this.state.values.resellerCourse)}
                                onChange={this.onChange("resellerCourse")}
                                toggle
                            />
                        )}

                        <EditCourseCategories
                            label="Course Categories"
                            categories={this.props.constructionCategories}
                            values={this.state.values.courseCategories}
                            onChange={this.onChange("courseCategories")} />
                    </>
                )}
                {(!!values.eventTypeId && (isWorkflowCorporate(values.workflowType)) || isWorkflowConstruction(values.workflowType)) &&
                    <div className="margin-bottom">
                        {(eventType?.deliveryType === SchemeDeliveryTypeEnum.Classroom
                            || (eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
                                && values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite)) &&
                            <div>Internal Product ID (Classroom): {eventType.internalId}</div>
                        }
                        {(eventType?.deliveryType === SchemeDeliveryTypeEnum.Digital
                            || (eventType?.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital
                                && values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital)) &&
                            <div>Internal Product ID (Digital): {eventType.internalIdDigital}</div>
                        }
                        {(values.workflowType === WorkflowTypeEnum.CPC || values.productCategory === ProductCategoryEnum.OnRoadWithCpc) && (
                            <div>Course Approval Number: {eventType.courseApprovalNumber}</div>
                        )}
                    </div>
                }
                {workflowTypeSupportsCertificate(values.workflowType) &&
                    <Input.Checkbox
                        label="Certificate Required?"
                        value={Boolean(values.certificateRequired)}
                        onChange={this.onChange("certificateRequired")}
                        toggle
                    />
                }
                {supportsOnRoadReport(eventType?.workflowType, eventType?.productCategory) &&
                    <Input.Checkbox
                        label="On Road Report Required"
                        value={Boolean(this.state.values.onRoadReportRequired)}
                        onChange={this.onChange("onRoadReportRequired")}
                        toggle
                    />
                }
                {isBusinessLineTypeCorporateOrConstruction(businessLineType) && (
                    <>
                        <Input.DropdownNumber
                            label="Booking Type"
                            placeholder="Select Booking Type"
                            showErrors={showErrors}
                            value={values.bookingType}
                            options={
                                ObjectKeys(BookingType).filter(k => (corporateOrganisation?.open ?
                                    +k === BookingTypeEnum.Open : +k === BookingTypeEnum.Closed))
                                    .map(
                                        k => ({ text: BookingType[k], value: +k })
                                    )}
                            readOnly
                            disabled={!!values.bookingType}
                            required
                        />
                        <Input.Checkbox
                            label="Delegates Required?"
                            value={Boolean(values.delegatesRequired)}
                            onChange={this.onChange("delegatesRequired")}
                            toggle
                            readOnly={values.bookingType === BookingTypeEnum.Open
                                || values.workflowType === WorkflowTypeEnum.CPC}
                            disabled={values.bookingType === BookingTypeEnum.Open
                                || values.workflowType === WorkflowTypeEnum.CPC}
                        />
                        {values.flexibleCertificatesRequired && (
                            <Input.DropdownNumber
                                label="Certificate Type"
                                placeholder="Select Certificate Type"
                                showErrors={showErrors}
                                value={values.certificateType}
                                options={
                                    ObjectKeys(CertificateType)
                                        .filter(k => +k !== 0)
                                        .map(
                                            k => ({ text: CertificateType[k], value: +k })
                                        )}
                                onChange={this.updateCertificateType}
                                required
                            />
                        )}
                    </>
                )}
                <Container>
                    {values.maxNumberOfAttendees > 0 &&
                    <Label id="attendees" >
                        Max Attendees Permitted: {values.maxNumberOfAttendees}
                    </Label>
                    }
                    {this.isOnSiteDeliveryType() && GetEventMaxAttendeesPerClassroomTrainer(this.state.values.startDate, eventType) > 0 &&
                    <Label id="attendeespertrainer" >
                        Max Attendees Permitted Per Theory Trainer: {GetEventMaxAttendeesPerClassroomTrainer(this.state.values.startDate, eventType)}
                    </Label>
                    }
                    {this.isDigitalDeliveryType() && GetEventMaxAttendeesPerDigitalTrainer(this.state.values.startDate, eventType) > 0 &&
                    <Label id="attendeespertrainer" >
                        Max Attendees Permitted Per Digital Trainer: {GetEventMaxAttendeesPerDigitalTrainer(this.state.values.startDate, eventType)}
                    </Label>
                    }
                    {this.isOnSiteDeliveryType() && values.maxNumberOfAttendeesPerPracticalTrainer > 0 &&
                    <Label id="attendeesperpracticaltrainer" >
                        Max Attendees Permitted Per Practical Trainer: {values.maxNumberOfAttendeesPerPracticalTrainer}
                    </Label>
                    }
                    {values?.moduleType !== "" &&
                    <Label id="moduleType">
                        Course Type: {values.moduleType}
                    </Label>
                    }
                    {!!values?.durationType &&
                    <Label id="durationType">
                        Duration Type: {DurationType[values.durationType]}
                    </Label>
                    }
                    {values.eventTypeId &&
                    <Label id="workflowType">
                        Workflow Type: {WorkflowType[values.workflowType]}
                    </Label>
                    }
                    {!values.flexibleCertificatesRequired && (
                        <>
                            {!!values.mandatoryCorpCertificate &&
                                <Label id="mandatoryCorpCertificate">
                                    Mandatory Certificate Type: {CorporateCertificateType[values.mandatoryCorpCertificate]}
                                </Label>
                            }
                            {!!values.optionalCorpCertificate  &&
                            <Label id="optionalCorpCertificate">
                                Optional Certificate Type: {CorporateCertificateType[values.optionalCorpCertificate]}
                            </Label>
                            }
                        </>
                    )}
                </Container>
                <Checkbox
                    label="Course is 1:1?"
                    onChange={this.onOneToOneChange}
                    checked={this.state.values.oneToOne}
                />
                <Divider hidden />
                <Input.Dropdown
                    label="Venue"
                    placeholder="Select Venue"
                    showErrors={showErrors}
                    value={values.venueId}
                    disabled={!this.state.values.eventTypeId}
                    options={this.state.reducedVenues.map(c => ({ key: c.id, text: c.name, value: c.id }))}
                    onChange={this.updateVenue}
                    dynamicOptions
                    search
                    required
                />
                {venue?.venueType === VenueTypeEnum.Enquiry  && venue?.deliveryType === DeliveryTypeEnum.Onsite && (
                    <Input.Text
                        label=" Location description"
                        value={values.locationDescription}
                        onChange={this.onChange("locationDescription")}
                        required
                        showErrors={showErrors}
                    />
                )}
                {venueAttributesDisplay &&
                    <Label id="venueattributes">
                        Special Considerations: {venueAttributesDisplay}
                    </Label>
                }
                {venue?.venueType === VenueTypeEnum.DelegateHome && (
                    <AddressLookup
                        address={values.delegateHomeAddress}
                        showErrors={showErrors}
                        onChange={this.updateDelegateHomeAddress}
                        required
                        autofill
                    />
                )}
                {businessLineType === BusinessLineType.Construction
                    && values.bookingType === BookingTypeEnum.Open && venue?.venueType === VenueTypeEnum.Enquiry && (
                    <>
                        {values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite && (
                            <Input.Number
                                value={this.state.values.classroomBreakEvenPoint}
                                label="Classroom Break Even Point"
                                showErrors={this.state.showErrors}
                                required
                                validation={this.state.values.maxNumberOfAttendees > 0 ? rangeValidator(0, this.state.values.maxNumberOfAttendees) : undefined}
                                onChange={this.onChange("classroomBreakEvenPoint")}
                            />
                        )}
                        {values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital  && (
                            <Input.Number
                                value={this.state.values.digitalBreakEvenPoint}
                                label="Digital Break Even Point"
                                showErrors={this.state.showErrors}
                                required
                                validation={this.state.values.maxNumberOfAttendees > 0 ? rangeValidator(0, this.state.values.maxNumberOfAttendees) : undefined}
                                onChange={this.onChange("digitalBreakEvenPoint")}
                            />
                        )}
                    </>
                )}
                {(isBusinessLineTypeCorporateOrConstruction(businessLineType)
                    && values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital) && (
                    <Input.Dropdown
                        value={this.state.values.doesNotUseTtcZoomAccounts?.toString() ?? "false"}
                        label="Organisation specific digital details required"
                        showErrors={this.state.showErrors}
                        dynamicOptions
                        options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]}
                        onChange={this.updateDoesNotUseTtcZoomAccounts}
                        search
                    />
                )}
                <DigitalCourseEdit
                    eventInstance={values}
                    updateProperty={this.onChange}
                    updateDigitalCourseWithoutRegistration={this.updateDigitalCourseWithoutRegistration}
                    fromCreate
                />
                <Divider hidden />
                {isBusinessLineTypePoliceOrCourt(businessLineType) && (
                    <Input.DropdownNumber
                        label="Booking Journey Type"
                        showErrors={showErrors}
                        value={values.availableInBookingJourney}
                        validation={[validators.requiredNumber()]}
                        required
                        disabled={this.state.values.oneToOne}
                        options={optionsFromObject(BookingAvailabilityType)}
                        onChange={this.onChange("availableInBookingJourney")}
                    />
                )}
                {eventTypeIsMultiDay(eventType) && (
                    <Input.Number
                        value={this.state.values.numberOfDeliveryDays}
                        label="Number of Delivery Days"
                        showErrors={this.state.showErrors}
                        required
                        validation={minValidator(2)}
                        onChange={this.updateNumberOfDeliveryDays}
                    />
                )}
                <MuiDateField
                    value={values.startDate}
                    label="Date"
                    required
                    validation={[muiTodayOrfutureDateValidator]}
                    showErrors={showErrors}
                    onChange={this.updateDate}
                />
                {this.bankHolidays?.some(h => h.date.isSame(values.startDate, "day")) &&
                    <div className="ui yellow message">
                        <Icon name="warning sign" size="large" />Warning - date selected is a Bank Holiday
                    </div>}
                {eventTypeIsMultiDay(eventType)
                    ? <MultiDayEventTypePartEdit
                        groupItemStartDates={this.state.values.groupItemStartDates}
                        bankHolidays={this.bankHolidays}
                        firstStartDate={this.state.values.startDate}
                        deliveryType={values.eventInstanceDeliveryType}
                        updateEventTypeParts={this.updateEventTypeParts}
                        eventTypeParts={this.state.values.eventTypeParts}
                        updateStartDates={this.updateAllDDRSGroupStartDates}
                        updateSpecificStartDate={this.updateSpecificGroupStartDate}
                        eventType={eventType}
                    />
                    : <SingleDayPropsForCreate
                        eventDuration={values.eventDuration}
                        educationDuration={values.educationDuration}
                        startTime={values.startTime}
                        ddStartTime={ddStartTime}
                        availableSuggestedTimes={availableSuggestedTimes}
                        showErrors={showErrors}
                        showOther={showOther}
                        updateProperty={this.onChange}
                        eventTypeId={this.state.values.eventTypeId}
                        registrationEndTime={values.registrationEndTime}
                        updateFieldsAffectedByStartTime={this.updateFieldsAffectedByStartTime}
                        theoryAvailableSuggestedTimes={
                            this.getStartTimeForDeliveryTypeOptions(eventType?.classroomEventTypeDetails?.suggestedStartTimesForTheorySessions)}
                        theoryStartTime={values.theoryStartTime}
                        theoryDuration={values.theoryDuration}
                        showTheoryAndPractical={eventType?.moduleType === ModuleTypeEnum.Both}
                        showCombined={eventType?.durationType === DurationTypeEnum.Combined}
                        practicalAvailableSuggestedTimes={
                            this.getStartTimeForDeliveryTypeOptions(eventType?.classroomEventTypeDetails?.suggestedStartTimesForPracticalSessions)}
                        practicalStartTime={values.practicalStartTime}
                        practicalDuration={values.practicalDuration}
                        ddPracticalTime={ddPracticalTime}
                        validationPredicate={this.practicalStartTimeValidationPredicate}
                        hasEducationDuration={this.hasEducationDuration(eventType)} />
                }
                <Divider hidden />
                <Input.Textarea
                    value={this.state.values.adminNote}
                    label="Admin Note"
                    validation={[validators.validLength({ max: 1000 })]}
                    showErrors={this.state.showErrors}
                    onChange={this.onChange("adminNote")}
                />
                {isBusinessLineTypePoliceOrCourt(businessLineType) && (
                    <>
                        <Input.Textarea
                            value={this.state.values.bookingNoteEn}
                            label="Booking Note (EN)"
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("bookingNoteEn")}
                        />
                        <Input.Textarea
                            value={this.state.values.bookingNoteCy}
                            label="Booking Note (CY)"
                            validation={[validators.validLength({ max: 1000 })]}
                            showErrors={this.state.showErrors}
                            onChange={this.onChange("bookingNoteCy")}
                        />
                    </>
                )}
                {(isBusinessLineTypeCorporateOrConstruction(businessLineType)
                    && this.state.values.bookingType === BookingTypeEnum.Closed
                    && values.corporateOrganisationId && values.eventTypeId && values.startDate) && (
                    <>
                        <h3>Finance details</h3>
                        <CurrencyInput
                            label={"Closed Course Organisation Fee"}
                            showErrors={showErrors}
                            value={values.closedCourseFee}
                            onChange={this.onChange("closedCourseFee")}
                        />
                        {(this.state.courseFees?.uploadFee !== null && this.state.courseFees?.uploadFee !== undefined) && (
                            <>
                                <CurrencyInput
                                    label={"Upload Fee Per Delegate"}
                                    showErrors={showErrors}
                                    value={values.uploadFee}
                                    onChange={this.onChange("uploadFee")} />
                                <div className="margin-bottom">
                                    Maximum Total Upload Fee: {`£${numberFormatter(this.getMaximumTotalUploadFee())}`}
                                </div>
                            </>
                        )}
                        <CurrencyInput
                            label={"Misc Org Fee"}
                            showErrors={showErrors}
                            value={values.miscellaneousOrganisationFee}
                            onChange={this.onChange("miscellaneousOrganisationFee")}
                        />
                        <div className="margin-bottom">
                            <div>Maximum Invoice Total:
                                {` £${numberFormatter(this.state.values.totalFeesInPence / 100)}`}
                            </div>
                        </div>
                        {corporateOrganisation?.hasCorporateOrganisationSpecificPurchaseOrderDrawdowns && this.state.purchaseOrders && (
                            <>
                                {this.state.values.existingPurchaseOrdersList?.map((purchaseOrder: PurchaseOrder, index: number) => {
                                    const drawdown = Math.min(purchaseOrder?.valueRemaining ?? 0, remainingCourseFees);
                                    remainingCourseFees -= drawdown;

                                    return (
                                        <div key={purchaseOrder?.id}>
                                            <PurchaseOrderDropdown
                                                existingPurchaseOrder={purchaseOrder}
                                                purchaseOrders={this.state.purchaseOrders.filter(
                                                    po => !this.state.values.existingPurchaseOrdersList.includes(po) ||
                                                    po.number === this.state.values.existingPurchaseOrdersList[index]?.number)}
                                                onChange={this.updatePurchaseOrder(index)}
                                            />
                                            {purchaseOrder?.number && purchaseOrder?.number !== "Not Set" && (
                                                <>
                                                    {purchaseOrder.description && (
                                                        <div className="margin-bottom">Description: {purchaseOrder.description}</div>
                                                    )}
                                                    <div>Current Purchase Order Value: {`£${numberFormatter(purchaseOrder.valueRemaining ?? 0)}`}</div>
                                                    <div className="margin-bottom">
                                                        New Purchase Order Value: {`£${numberFormatter((purchaseOrder.valueRemaining ?? 0) - drawdown)}`}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                                <Button
                                    className="margin-bottom"
                                    content="Add Purchase Order"
                                    onClick={this.addNewPurchaseOrder}
                                    disabled={this.state.purchaseOrders?.length === this.state.values.existingPurchaseOrdersList?.length ||
                                        ((this.state.values.existingPurchaseOrdersList &&
                                            this.state.values.existingPurchaseOrdersList.some(po => !po || po.number === "Not Set")) ||
                                            remainingCourseFees <= 0)}
                                />
                            </>
                        )}
                        <Input.Text
                            label="Purchase Order no"
                            showErrors={showErrors}
                            value={values.purchaseOrderNumbersList?.length > 0 ?
                                values.purchaseOrderNumbersList.filter(num => num && num !== "Not Set").join(", ") :
                                ""}
                            onChange={this.updatePurchaseOrderNumbers}
                            disabled={values.existingPurchaseOrdersList?.some(po => po)}
                        />
                        <Input.Textarea
                            label="Note"
                            showErrors={showErrors}
                            validation={[validators.validLength({ max: 1000 })]}
                            value={values.financeDetailsNote}
                            onChange={this.onChange("financeDetailsNote")}
                        />
                    </>
                )}
                {values.bookingType === BookingTypeEnum.Closed && !isBusinessLineTypePoliceOrCourt(this.props.businessLineType) &&
                    <>
                        <h3>Contacts</h3>
                        <Divider hidden />
                        <ClosedContactsForm
                            primaryContact={corporateOrganisation?.organisationContact}
                            courseContact={corporateOrganisation?.organisationCourseContact}
                            financeContact={corporateOrganisation?.organisationFinanceContact}
                            delegatesContact={corporateOrganisation?.delegatesContact}
                            managersContact={corporateOrganisation?.managersContact}
                            isBusinessDriver={orgIsBusinessDriver}
                            onChange={this.onClosedContactsChange} />
                    </>}
                <Confirm
                    open={this.state.showNoContractError}
                    header={"No Contract Found"}
                    content={"No contract was found for this police force and event type for this date. Are you sure you wish to continue?"}
                    cancelButton={cancelProps}
                    onConfirm={this.submitIgnoringNoContractWarning}
                    onCancel={this.closeNoContractWarningModal}
                />

                <Confirm
                    open={this.state.showDurationError}
                    header={"Total duration differs from scheme"}
                    // eslint-disable-next-line max-len
                    content={isBusinessLineTypeCorporateOrConstruction(this.props.businessLineType)
                        ? "The total duration of courses differs from the total duration defined on the product. Are you sure you wish to continue?"
                        : "The total duration of drink drive courses differs from the total duration defined on the scheme. Are you sure you wish to continue?"}
                    cancelButton={cancelProps}
                    onConfirm={this.submitIgnoringDurationWarning}
                    onCancel={this.closeDurationWarningModal}
                />
            </Form>
        );
    }

    private closeNoContractWarningModal = () => this.setState({ showNoContractError: false });

    private openNoContractWarningModal = () => this.setState({ showNoContractError: true });

    private closeDurationWarningModal = () => this.setState({ showDurationError: false });

    private openDurationWarningModal = () => this.setState({ showDurationError: true });

    private onClosedContactsChange = (contacts: UpdateClosedCourseCommsDestinationsModel) => {
        this.updateProperty("contactsModel", contacts, true);
    };

    private checkForceHasContractForEvent = () => {
        if (this.state.values.workflowType !== WorkflowTypeEnum.Dors) {
            return true;
        }
        const forceId = this.state.values.forceId;
        const schemeId = this.state.values.schemeId;
        const date = this.state.values.startDate;
        return this.props.policeContracts.find(c => c.forceId === forceId && c.schemeId === schemeId && date >= c.startDate && date <= c.endDate) !== undefined;
    };

    public submit = async () => {
        const { valid, values } = this.state;
        const eventType = this.props.eventTypes.find(et => et.id === values.eventTypeId);

        if (ObjectKeys(valid).some(k =>
            !valid[k])) {
            toast.warning("Course cannot be created as some mandatory fields have not been completed");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (values.existingPurchaseOrdersList.some(po => po && po.number !== "Not Set") &&
            this.getRemainingCourseFees() > 0) {
            toast.warning("There is insufficient value remaining on the selected purchase order(s)");
            return;
        }

        if (eventType.moduleType === ModuleTypeEnum.Both) {
            if (!this.validateDuration(values.theoryDuration) )
            {
                toast.warning("Theory event duration has not been set");
                return;
            }
            if (!this.validateDuration(values.practicalDuration) )
            {
                toast.warning("Practical event duration has not been set");
                return;
            }
        }
        else if (!eventTypeIsMultiDay(eventType) && !this.validateDuration(values.eventDuration)) {
            toast.warning("Event duration has not been set");
            return;
        }

        if (!eventTypeIsMultiDay(eventType) && values.workflowType === WorkflowTypeEnum.CPC &&
            !this.validateDuration(values.educationDuration)) {
            toast.warning("Education duration has not been set");
            return;
        }

        if (!this.checkForceHasContractForEvent()) {
            this.openNoContractWarningModal();
            return;
        }

        if (eventTypeIsMultiDay(eventType) &&
            !totalDurationEqualsSchemeTotal(values.eventInstanceDeliveryType, values.eventTypeId, values.eventTypeParts, this.props.eventTypes) ) {
            this.openDurationWarningModal();
            return;
        }

        const venue = this.props.venues.find(v => v.id === values.venueId);
        if (venue?.venueType === VenueTypeEnum.Enquiry && venue?.deliveryType === DeliveryTypeEnum.Onsite) {
            const api = new EventInstanceApi();
            if (await api.validateLocationDescription(values.locationDescription) === false) {
                toast.warning("Location description is not valid");
                this.setState(prevState => ({ ...prevState, showErrors: true }));
                return;
            }
        }

        if (eventTypeIsMultiDay(eventType) && values.eventTypeParts && Object.values(values.eventTypeParts).length > 0) {
            const eventTypePartsValues = Object.values(values.eventTypeParts);
            if (eventTypePartsValues.some(etp =>
                !etp.eventDuration || !etp.eventDuration.isValid() || etp.eventDuration.asMilliseconds() === 0 ||
                !etp.registrationDuration || !etp.registrationDuration.isValid() || etp.registrationDuration.asMilliseconds() === 0)) {
                toast.warning("Event duration and registration duration must be set for all days");
                return;
            }
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private submitIgnoringWarning = (errorField: keyof CreateFormState) => {
        this.setState({ ...this.state,
            [errorField]: false
        });

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private submitIgnoringNoContractWarning=() =>
        this.submitIgnoringWarning("showNoContractError");

    private submitIgnoringDurationWarning=() =>
        this.submitIgnoringWarning("showDurationError");

    private validateDuration = (value: moment.Duration) => {
        return (value?.minutes() > 0 || value?.hours() > 0);
    };

    private updateAllDDRSGroupStartDates = ( value: Dictionary<moment.Moment>, valid: boolean) =>  {
        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        const datesArray = Object.values(value);

        const correctOrder = datesArray.every((_e,index) => index + 1 < datesArray.length? value[index + 1].isBefore(value[index + 2]) : true);

        if (!correctOrder) {
            valid = false;
            toast.warning("Day parts must be in chronological order");
        }

        if (!this.state.values.startDate.isSame(datesArray[0])) {
            this.updateProperty("startDate", datesArray[0], true);
            if (this.isOnSiteDeliveryType()) {
                return this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        datesArray[0],
                        eventType.classroomEventTypeDetails.defaultMultiDayType,
                        this.state.values.eventTypeParts), valid
                );
            }

            if (this.isDigitalDeliveryType()) {
                return this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        datesArray[0],
                        eventType.digitalEventTypeDetails.defaultMultiDayType,
                        this.state.values.eventTypeParts), valid
                );
            }
        }

        return this.updateProperty("groupItemStartDates", value, valid);
    };

    private updateNumberOfDeliveryDays = (value: number, valid: boolean) => {
        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        if (valid && (this.isOnSiteDeliveryType() || this.isDigitalDeliveryType())) {
            const start = this.state.values.startDate;
            const multiDayType = this.isOnSiteDeliveryType()
                ? eventType.classroomEventTypeDetails.defaultMultiDayType
                : eventType.digitalEventTypeDetails.defaultMultiDayType;

            const eventTypeParts = this.handleUpdatedEventTypeParts(this.state.values.eventTypeParts, value);
            this.updateProperty("eventTypeParts", eventTypeParts, valid);
            this.updateProperty(
                "groupItemStartDates",
                InitialiseGroupItemStartDates(
                    start,
                    multiDayType,
                    eventTypeParts || {}
                ),
                valid);
        }

        this.updateProperty("numberOfDeliveryDays", value, valid);
    };

    private updateSpecificGroupStartDate = (day: string, value: moment.Moment, valid: boolean) =>
        this.updateAllDDRSGroupStartDates({ ...this.state.values.groupItemStartDates, [day]: value }, valid );

    private updateDate = async (value: moment.Moment, valid: boolean) => {
        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        const startTime = eventType?.moduleType === ModuleTypeEnum.Both ? this.state.values.theoryStartTime : this.state.values.startTime;

        if (this.isOnSiteDeliveryType()) {
            this.updateProperty("eventDuration", GetEventDuration(value, DeliveryTypeEnum.Onsite, eventType), valid);

            if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(value, DeliveryTypeEnum.Onsite, eventType), valid);
            }

            this.updateProperty("maxNumberOfAttendees", GetEventMaxClassroomAttendees(value, eventType), true);
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(startTime, GetEventClassroomRegistrationDuration(value, eventType)), true);
        }

        if (this.isDigitalDeliveryType()) {
            this.updateProperty("eventDuration", GetEventDuration(value, DeliveryTypeEnum.Digital,  eventType), valid);

            if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(value, DeliveryTypeEnum.Digital, eventType), valid);
            }

            this.updateProperty("maxNumberOfAttendees", GetEventMaxDigitalAttendees(value, eventType), true);
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(startTime ,GetEventDigitalRegistrationDuration(value, eventType)), true);
        }

        if (eventType?.moduleType === ModuleTypeEnum.Both) {
            this.updateProperty("theoryDuration", GetEventTheoryDuration(value, eventType), valid);
            this.updateProperty("practicalDuration", GetEventPracticalDuration(value, eventType), valid);
        }

        this.updateProperty("startDate", value, valid);
        this.updateProperty("classroomBreakEvenPoint", GetEventClassroomBreakEvenPoints(value, eventType), valid);
        this.updateProperty("digitalBreakEvenPoint", GetEventDigitalBreakEvenPoints(value, eventType), valid);

        if (this.state.values.groupItemStartDates && Object.keys(this.state.values.groupItemStartDates).length > 0) {
            if (this.isOnSiteDeliveryType()) {
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        value,
                        eventType.classroomEventTypeDetails.defaultMultiDayType,
                        this.state.values.eventTypeParts), valid
                );
            }

            if (this.isDigitalDeliveryType()) {
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        value,
                        eventType.digitalEventTypeDetails.defaultMultiDayType,
                        this.state.values.eventTypeParts), valid
                );
            }
        }

        if (this.state.values.corporateOrganisationId) {
            const purchaseOrders = filterAndSortPurchaseOrders(
                await new OrganisationApi().getPurchaseOrders(this.state.values.corporateOrganisationId),
                this.state.values.id,
                this.state.values.startDate);
            this.setState({ purchaseOrders });
            if (purchaseOrders.length === 0 || purchaseOrders.find(po => po.number === this.state.values.purchaseOrderNumbersList[0]) === undefined) {
                this.updateExistingPurchaseOrders(0, "Not Set", true);
            }
        }
    };

    private updateDigitalCourseWithoutRegistration = (value: string) => {
        this.updateProperty("digitalCourseWithoutRegistration", value === "No", true);
    };

    private onChange = (prop: keyof EventInstanceCreateModel) => (value: any, valid: boolean) => {
        this.updateProperty(prop, value, valid);
    };

    private updateVenue = (value: string, valid: boolean) => {
        this.updateProperty("venueId", value, valid);

        const venue = this.props.venues.find(v => v.id === value);

        if (venue) {
            this.updateProperty("venueName", venue.name, valid);
            this.updateProperty("forceId", venue.forceId, valid);
            this.updateProperty("dsaArea", venue.dsaAreaId, valid);
            this.updateProperty("referredCourtCountry", venue.referredCourtCountry, valid);

            const venueDorsId = this.state.values?.workflowType === WorkflowTypeEnum.Dors ? venue.dorsId : 0;
            this.updateProperty("venueDorsId", venueDorsId, valid);

            this.updateProperty("eventInstanceDeliveryType", venue.deliveryType, valid);
            this.deliveryTypeChanged(venue.deliveryType);
            this.updateProperty("enquiryType", venue.venueType === VenueTypeEnum.Enquiry ? EnquiryType.Enquiry : EnquiryType.NotEnquiry, valid);

            const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);

            if (eventType) {
                this.updateFieldsDependantOnDeliveryType(eventType, valid);

                if (venue.venueType === VenueTypeEnum.Enquiry) {
                    this.updateProperty("resellerCourse", eventType.resellerCourse, true);
                } else {
                    this.updateProperty("resellerCourse", false, true);
                }

                if (eventType.workflowType === WorkflowTypeEnum.CPC) {
                    this.updateProperty("digitalCourseWithoutRegistration", false, true);
                }

                if (eventType.workflowType === WorkflowTypeEnum.Workshop || eventType.workflowType === WorkflowTypeEnum.OnRoad) {
                    this.updateProperty("digitalCourseWithoutRegistration", true, true);
                }

                if (eventTypeIsMultiDay(eventType)) {
                    if (venue.deliveryType === DeliveryTypeEnum.Onsite) {
                        this.updateProperty("numberOfDeliveryDays", eventType.classroomEventTypeDetails.defaultNumberOfClassroomDeliveryDays, true);
                        this.updateProperty("eventTypeParts", eventType.classroomEventTypeDetails.classroomEventTypeParts, valid);
                    } else {
                        this.updateProperty("numberOfDeliveryDays", eventType.digitalEventTypeDetails.defaultNumberOfDigitalDeliveryDays, true);
                        this.updateProperty("eventTypeParts", eventType.digitalEventTypeDetails.digitalEventTypeParts, valid);
                    }
                }
            } else {
                this.updateProperty("resellerCourse", false, true);
            }
        } else {
            this.updateProperty("resellerCourse", false, true);
        }

        if (venue?.venueType !== VenueTypeEnum.Enquiry || venue?.deliveryType !== DeliveryTypeEnum.Onsite) {
            this.updateProperty("locationDescription", undefined, true);
        }

        if (venue?.venueType !== VenueTypeEnum.DelegateHome) {
            this.updateProperty("delegateHomeAddress", {}, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine1", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine2", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.addressLine3", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.city", undefined, true);
            this.updateNestedProperty("delegateHomeAddress.postalCode", undefined, true);
        }
    };

    private updateDelegateHomeAddress = (address: Address, valid: boolean) => {
        this.updateProperty("delegateHomeAddress", address, valid);
    };

    private updateDoesNotUseTtcZoomAccounts = (value: string) => {
        this.updateProperty("doesNotUseTtcZoomAccounts", value, true);
    };

    private updateOrganisation = async (value: string, valid: boolean) => {
        this.updateProperty("corporateOrganisationId", value, valid);

        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        const isPoliceAndCourt = isEventTypeWorkflowPoliceOrCourt(eventType);
        const isCorporate = isEventTypeWorkflowCorporate(eventType);
        const isConstruction = isEventTypeWorkflowConstruction(eventType);

        const orgCompanyTypes = this.props.organisationOptions.find(org => org.value === value)?.companyType;
        const isBusinessDriverOnly = orgCompanyTypes && orgCompanyTypes.length === 1 && orgCompanyTypes.includes(CompanyTypeEnum.BusinessDriver);
        const hasBusinessDriver = orgCompanyTypes && orgCompanyTypes.includes(CompanyTypeEnum.BusinessDriver);

        if (value) {
            this.setState({
                reducedProductCategories: GetProductCategories(this.props.businessLineType, this.props.isBusinessDriverAdminRole,
                    this.props.isTtcCorporateAdminRole)
                    .filter(k => hasBusinessDriver || !BusinessDriverProductCategories.includes(k))
                    .filter(k => !isBusinessDriverOnly || BusinessDriverProductCategories.includes(k))
                    .filter(k => +k !== 0)
                    .map(k => ({ text: ProductCategory[k], value: +k }))
            });
        }
        this.setState({
            reducedVenues: this.props.venues
                .filter(v => venueHasCorrectDeliveryType(v, eventType) &&
                    (!eventType?.workflowType ||
                        (v.workflowTypes.some(wft => wft === eventType?.workflowType) &&
                            (!isPoliceAndCourt || isVenueTypeCorrectForPoliceAndCourt(v)) &&
                            (!isCorporate || isVenueTypeCorrectForCorporate(v, value)) &&
                            (!isConstruction || isVenueTypeCorrectForConstruction(v, value)))))
        });
        if (value) {
            const corporateOrganisation = this.props.organisationOptions.find(org => org.value === value);

            this.updateProperty("productCategory", null, true);

            if (corporateOrganisation?.open && this.state.values.bookingType === BookingTypeEnum.Closed) {
                this.updateProperty("bookingType", null, true);
            }

            if (corporateOrganisation?.open && eventType?.bookingType === BookingTypeEnum.Closed) {
                this.updateEventType(null, false);
            }

            const purchaseOrders = filterAndSortPurchaseOrders(
                await new OrganisationApi().getPurchaseOrders(corporateOrganisation?.value),
                this.state.values.id,
                this.state.values.startDate);
            this.setState({ purchaseOrders }, () => {
                this.updateExistingPurchaseOrders(0, "Not Set", true);
            });
        }
    };

    private updateEventType = (value: string, valid: boolean, updateRegistrationEnd: boolean = true) => {
        if (value !== "") {
            const eventType = this.props.eventTypes.find(et => et.id === value);
            const venue = this.props.venues.find(v => v.id === this.state.values.venueId);
            const moduleType = ModuleType[eventType?.moduleType];
            const isPoliceAndCourt = isEventTypeWorkflowPoliceOrCourt(eventType);
            const isCorporate = isEventTypeWorkflowCorporate(eventType);
            const isConstruction = isEventTypeWorkflowConstruction(eventType);
            const organisationId = this.state.values.corporateOrganisationId;

            this.setState({
                reducedVenues: this.props.venues
                    .filter(v => venueHasCorrectDeliveryType(v, eventType) &&
                        (!eventType?.workflowType ||
                            (v.workflowTypes.some(wft => wft === eventType?.workflowType) &&
                                (!isPoliceAndCourt || isVenueTypeCorrectForPoliceAndCourt(v)) &&
                                (!isCorporate || isVenueTypeCorrectForCorporate(v, organisationId)) &&
                                (!isConstruction || isVenueTypeCorrectForConstruction(v, organisationId)))))
            });

            if (eventType) {
                this.updateProperty("eventTypeId", value, valid);
                this.updateProperty("eventTypeName", eventType.name, valid);
                this.updateProperty("maxNumberOfAttendeesPerPracticalTrainer", eventType.maxNumberOfAttendeesPerPracticalTrainer, valid);
                this.updateProperty("moduleType", moduleType, valid);
                this.updateProperty("durationType", eventType.durationType, valid);
                this.updateProperty("workflowType", eventType.workflowType, valid);
                this.updateProperty("schemeId", eventType.dorsId, valid);
                this.updateProperty("eventTypeAbbreviation", eventType.abbreviation, valid);
                this.updateProperty("delegatesRequired", eventType.delegatesRequired, valid);
                this.updateProperty("flexibleCertificatesRequired", eventType.flexibleCertificatesRequired, valid);
                this.updateProperty("certificateRequired", eventType.certificateRequired, valid);
                this.updateProperty("courseCategories", eventType.courseCategories, valid);
                this.updateFieldsDependantOnDeliveryType(eventType, valid, updateRegistrationEnd);

                if (venue?.venueType === VenueTypeEnum.Enquiry) {
                    this.updateProperty("resellerCourse", eventType.resellerCourse, valid);
                }

                if (this.isOnSiteDeliveryType(eventType)) {
                    this.updateProperty("numberOfDeliveryDays", eventType.classroomEventTypeDetails.defaultNumberOfClassroomDeliveryDays, true);
                }

                if (this.isDigitalDeliveryType(eventType)) {
                    this.updateProperty("numberOfDeliveryDays", eventType.digitalEventTypeDetails.defaultNumberOfDigitalDeliveryDays, true);
                }

                if (isCorporate || isConstruction) {
                    this.updateProperty("mandatoryCorpCertificate", eventType.mandatoryCorpCertificate, valid);

                    if (eventType.workflowType === WorkflowTypeEnum.CPC) {
                        this.updateProperty("digitalCourseWithoutRegistration", false, true);
                        this.updateProperty("optionalCorpCertificate", eventType.optionalCorpCertificate, valid);
                    }

                    if (eventType.workflowType === WorkflowTypeEnum.Workshop || eventType.workflowType === WorkflowTypeEnum.OnRoad) {
                        this.updateProperty("digitalCourseWithoutRegistration", true, true);
                    }

                    if (supportsOnRoadReport(eventType.workflowType, eventType.productCategory)) {
                        this.updateProperty("onRoadReportRequired", eventType.onRoadReportRequired, true);
                    }
                    else {
                        this.updateProperty("onRoadReportRequired", null, true);
                    }
                }

                if (eventType.moduleType !== ModuleTypeEnum.Both) {
                    this.updateProperty("theoryStartTime", null, true);
                    this.updateProperty("theoryDuration", null, true);
                    this.updateProperty("practicalDuration", null, true);
                    this.updateProperty("practicalStartTime", null, true);
                }

                if (eventType.bookingType !== BookingTypeEnum.Both && eventType.bookingType !== this.state.values.bookingType) {
                    this.updateProperty("bookingType", null, true);
                }

                // sets the event type category for quick add course creation
                if (!this.props.speficicWorkflowType) {
                    this.updateProperty("eventTypeCategory", WorkflowTypeToEventTypeCategory[eventType.workflowType], valid);
                }

                if (isConstruction && (eventType.bookingType === BookingTypeEnum.Open || eventType.bookingType === BookingTypeEnum.Both)) {
                    if (eventType.classroomEventTypeDetails.classroomBreakEvenPoints) {
                        this.updateProperty("classroomBreakEvenPoint", GetEventClassroomBreakEvenPoints(this.state.values.startDate, eventType), valid);
                    }

                    if (eventType.digitalEventTypeDetails.digitalBreakEvenPoints) {
                        this.updateProperty("digitalBreakEvenPoint", GetEventDigitalBreakEvenPoints(this.state.values.startDate, eventType), valid);
                    }
                }
            }
        }
    };

    private updateCertificateType = (value: number, valid: boolean) => {
        this.updateProperty("certificateType", value, valid);
    };

    private updateProductCategory = (value: number, valid: boolean) => {
        this.updateProperty("productCategory", value, valid);

        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);

        if (eventType && eventType.productCategory !== value) {
            this.updateEventType(null, false);
            this.updateProperty("onRoadReportRequired", null, true);
            this.updateProperty("delegatesRequired", null, true);
        }
    };

    private onOneToOneChange = (_: any, { checked }: CheckboxProps) => this.setState(prevState => ({
        ...prevState,

        values: {
            ...prevState.values,
            oneToOne: checked,
            availableInBookingJourney: checked? BookingAvailabilityTypeEnum.Office : prevState.values.availableInBookingJourney
        }
    }));

    private updateFieldsDependantOnDeliveryType(eventType: EventType, valid: boolean, updateRegistrationEnd: boolean = true) {
        this.setStartTimes(eventType);
        const start = this.state.values.startDate;
        const startTime = eventType?.moduleType === ModuleTypeEnum.Both ? this.state.values.theoryStartTime : this.state.values.startTime;
        if (this.isOnSiteDeliveryType(eventType)) {
            if (eventTypeIsMultiDay(eventType) && eventType.workflowType === WorkflowTypeEnum.DDRS) {
                const eventTypeParts = InitialiseEventTypeParts(DeliveryTypeEnum.Onsite);
                this.updateProperty("eventTypeParts", eventTypeParts, valid);
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(start, eventType.classroomEventTypeDetails.defaultMultiDayType, eventTypeParts), valid
                );
            }
            else if (eventTypeIsMultiDay(eventType) && eventType.workflowType !== WorkflowTypeEnum.DDRS) {
                const eventTypeParts = this.handleUpdatedEventTypeParts(
                    this.state.values.eventTypeParts && Object.keys(this.state.values.eventTypeParts).length > 0
                        ? this.state.values.eventTypeParts
                        : eventType.classroomEventTypeDetails.classroomEventTypeParts,
                    this.state.values.numberOfDeliveryDays || eventType.classroomEventTypeDetails.defaultNumberOfClassroomDeliveryDays
                );
                this.updateProperty("eventTypeParts", eventTypeParts, valid);
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        start,
                        eventType.classroomEventTypeDetails.defaultMultiDayType,
                        eventTypeParts || {}
                    ),
                    valid);
            }
            else if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(start, DeliveryTypeEnum.Onsite, eventType), valid);
            }

            this.updateProperty("eventDuration", GetEventDuration(start, DeliveryTypeEnum.Onsite, eventType), valid);

            this.updateProperty("maxNumberOfAttendees", GetEventMaxClassroomAttendees(start, eventType), valid);
            if (updateRegistrationEnd) {
                this.updateProperty("registrationEndTime",
                    GetEventRegistrationEndTime(startTime, GetEventClassroomRegistrationDuration(start, eventType)),valid);
            }

            this.updateProperty("doesNotUseTtcZoomAccounts", undefined, true);
        }

        if (this.isDigitalDeliveryType(eventType)) {
            if (eventTypeIsMultiDay(eventType) && eventType.workflowType === WorkflowTypeEnum.DDRS) {
                const eventTypeParts = InitialiseEventTypeParts(DeliveryTypeEnum.Digital);
                this.updateNestedProperty("eventTypeParts", eventTypeParts, valid);
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(start, eventType.digitalEventTypeDetails.defaultMultiDayType, eventTypeParts), valid);
            }
            else if (eventTypeIsMultiDay(eventType) && eventType.workflowType !== WorkflowTypeEnum.DDRS) {
                const eventTypeParts = this.handleUpdatedEventTypeParts(
                    this.state.values.eventTypeParts && Object.keys(this.state.values.eventTypeParts).length > 0
                        ? this.state.values.eventTypeParts
                        : eventType.digitalEventTypeDetails.digitalEventTypeParts,
                    this.state.values.numberOfDeliveryDays || eventType.digitalEventTypeDetails.defaultNumberOfDigitalDeliveryDays
                );
                this.updateProperty("eventTypeParts", eventTypeParts, valid);
                this.updateProperty(
                    "groupItemStartDates",
                    InitialiseGroupItemStartDates(
                        start,
                        eventType.digitalEventTypeDetails.defaultMultiDayType,
                        eventTypeParts || {}
                    ),
                    valid);
            }
            else if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(start, DeliveryTypeEnum.Digital, eventType), valid);
            }

            this.updateProperty("eventDuration", GetEventDuration(start, DeliveryTypeEnum.Digital, eventType), valid);

            this.updateProperty("maxNumberOfAttendees", GetEventMaxDigitalAttendees(start, eventType), valid);
            if (updateRegistrationEnd) {
                this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(startTime, GetEventDigitalRegistrationDuration(start, eventType)),valid);
            }
            const corporateOrganisation = this.state.values.corporateOrganisationId && this.props.organisationOptions
                .find(org => org.value === this.state.values.corporateOrganisationId);
            if (corporateOrganisation) {
                this.updateProperty("doesNotUseTtcZoomAccounts",
                    corporateOrganisation.hasCorporateOrganisationSpecificDigitalDetails ? "true" : "false", true);
            }
        }

        if (eventType?.moduleType === ModuleTypeEnum.Both) {
            this.updateProperty("theoryDuration", GetEventTheoryDuration(start, eventType), valid);
            this.updateProperty("practicalDuration", GetEventPracticalDuration(start, eventType), valid);
        }
    }

    private getStartTimeForDeliveryTypeOptions(suggestedStartTimes: Dictionary<moment.Duration[]>) {
        // eslint-disable-next-line eqeqeq
        if (suggestedStartTimes == null) {
            return [];
        }
        const uniqueStartTimeInMsSet = Object.values(suggestedStartTimes)
            .reduce((previousSet, currentDurations) => {
                currentDurations.forEach(d => previousSet.add(d.asMilliseconds()));
                return previousSet;
            }, new Set<number>());

        const uniqueStartTimeInMs = Array
            .from(uniqueStartTimeInMsSet)
            .filter(Boolean)
            .sort((msOne: any, msTwo: any) => msOne < msTwo ? -1 : msOne > msTwo ? 1 : 0);

        const newSuggestedStartTimes = uniqueStartTimeInMs.map(milliseconds => ({
            key: milliseconds,
            text: moment.duration(milliseconds).format(DateFormat.Time, { trim: false }),
            value: milliseconds,
        }));

        newSuggestedStartTimes.push({ key: 0, text: "Other", value: 0 });

        return newSuggestedStartTimes;
    }

    private setStartTimeForDeliveryType(suggestedStartTimes: Dictionary<moment.Duration[]>) {

        const newSuggestedStartTimes = this.getStartTimeForDeliveryTypeOptions(suggestedStartTimes);

        if (newSuggestedStartTimes.length > 1) {
            const possibleStart = newSuggestedStartTimes[0];
            this.updateProperty("startTime", moment.duration(possibleStart.value), true);

            const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
            if (eventType && eventType.moduleType === ModuleTypeEnum.Both) {
                this.updateProperty("theoryStartTime", moment.duration(possibleStart.value), true);
            }
            this.setState({
                showOther: false,
                availableSuggestedTimes: newSuggestedStartTimes,
                ddStartTime: possibleStart.value,
            });
        } else {
            this.setState({
                showOther: true,
                availableSuggestedTimes: newSuggestedStartTimes,
                ddStartTime: 0,
            });
        }
    }

    private setPracticalStartTimeForDeliveryType(suggestedStartTimes: Dictionary<moment.Duration[]>) {

        const newSuggestedStartTimes = this.getStartTimeForDeliveryTypeOptions(suggestedStartTimes);

        if (newSuggestedStartTimes.length > 1) {
            const possibleStart = newSuggestedStartTimes[0];
            this.updateProperty("practicalStartTime", moment.duration(possibleStart.value), true);
            this.setState({
                showOther: false,
                availableSuggestedPracticalTimes: newSuggestedStartTimes,
                ddPracticalTime: possibleStart.value,
            });
        } else {
            this.setState({
                showOther: true,
                availableSuggestedPracticalTimes: newSuggestedStartTimes,
                ddPracticalTime: 0,
            });
        }
    }

    private setStartTimes(eventType: EventType) {

        if (!eventTypeIsMultiDay(eventType)) {
            if (this.state.values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital) {
                this.setStartTimeForDeliveryType(eventType.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions);
            } else {
                this.setStartTimeForDeliveryType(eventType.classroomEventTypeDetails.suggestedStartTimesForSessions);
            }

            if (eventType?.moduleType === ModuleTypeEnum.Both) {
                this.setStartTimeForDeliveryType(eventType.classroomEventTypeDetails.suggestedStartTimesForTheorySessions);
                this.setPracticalStartTimeForDeliveryType(eventType.classroomEventTypeDetails.suggestedStartTimesForPracticalSessions);
            }
        }

    }

    private updateFieldsAffectedByStartTime = (newTime: moment.Duration) => {
        const start = this.state.values.startDate;
        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        if (this.isOnSiteDeliveryType()) {
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(newTime, GetEventClassroomRegistrationDuration(start, eventType)), true);
        }

        if (this.isDigitalDeliveryType()) {
            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(newTime, GetEventDigitalRegistrationDuration(start, eventType)), true);
        }
    };

    private deliveryTypeChanged = (eventInstanceType: number) => {
        const eventType = this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        const start = this.state.values.startDate;
        const startTime = eventType?.moduleType === ModuleTypeEnum.Both ? this.state.values.theoryStartTime : this.state.values.startTime;
        if (eventInstanceType !== DeliveryTypeEnum.Digital) {
            this.updateProperty("digitalCourseLink", null, true);
            this.updateProperty("digitalCourseAccount", null, true);
            this.updateProperty("digitalCoursePasswordTrainer", null, true);
            this.updateProperty("digitalCourseLinkTrainer", null, true);
            this.updateProperty("digitalCoursePasswordAttendee", null, true);
            this.updateProperty("digitalCourseMeetingId", null, true);
            this.updateProperty("maxNumberOfAttendees", GetEventMaxClassroomAttendees(start, eventType), true);
            this.updateProperty("eventDuration", GetEventDuration(start, DeliveryTypeEnum.Onsite, eventType), true);

            if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(start, DeliveryTypeEnum.Onsite, eventType), true);
            }

            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(startTime, GetEventClassroomRegistrationDuration(start, eventType)), true);
        } else {
            this.updateProperty("digitalCourseWithoutRegistration", true, true);
            this.updateProperty("maxNumberOfAttendees", GetEventMaxDigitalAttendees(start, eventType), true);
            this.updateProperty("eventDuration", GetEventDuration(start, DeliveryTypeEnum.Digital, eventType), true);

            if (this.hasEducationDuration(eventType)) {
                this.updateProperty("educationDuration", GetEducationDuration(start, DeliveryTypeEnum.Digital, eventType), true);
            };

            this.updateProperty("registrationEndTime", GetEventRegistrationEndTime(startTime, GetEventDigitalRegistrationDuration(start, eventType)), true);
        }
    };

    private isOnSiteDeliveryType(eventType?: EventType): boolean {
        const eventTypeToCheck = eventType || this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        return eventTypeToCheck?.deliveryType === SchemeDeliveryTypeEnum.Classroom || this.state.values.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite;
    }

    private isDigitalDeliveryType(eventType?: EventType): boolean {
        const eventTypeToCheck = eventType || this.props.eventTypes.find(et => et.id === this.state.values.eventTypeId);
        return eventTypeToCheck?.deliveryType === SchemeDeliveryTypeEnum.Digital || this.state.values.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    }

    private isCpcEventType(eventType: EventType): boolean {
        return eventType?.workflowType === WorkflowTypeEnum.CPC;
    }

    private hasEducationDuration(eventType: EventType): boolean {
        return this.isCpcEventType(eventType) || eventType?.productCategory === ProductCategoryEnum.OnRoadWithCpc;
    }

    public updateEventTypeParts = (eventTypeParts: Dictionary<EventTypePart>): any => {
        this.updateProperty("eventTypeParts", eventTypeParts, true);
    };

    private getProductCategories(isBusinessDriverAdminRole: boolean, isTtcCorporateAdminRole: boolean):  { text: string; value: number }[] {
        return GetProductCategories(this.props.businessLineType, isBusinessDriverAdminRole, isTtcCorporateAdminRole)
            .filter(k => +k !== 0)
            .map(k => ({ text: ProductCategory[k], value: +k }));
    }

    public updateExistingPurchaseOrders = (index: number, value: string, valid: boolean) => {
        const selectedPurchaseOrder = this.state.purchaseOrders.find(po => po.number === value);

        const newPurchaseOrderNumbers = [...(this.state.values.purchaseOrderNumbersList || [])];
        newPurchaseOrderNumbers[index] = selectedPurchaseOrder?.number !== "Not Set" ? selectedPurchaseOrder?.number : "";
        this.updateProperty("purchaseOrderNumbersList", newPurchaseOrderNumbers, valid);

        const newExistingPurchaseOrders = [...(this.state.values.existingPurchaseOrdersList || [])];
        newExistingPurchaseOrders[index] = selectedPurchaseOrder;
        this.updateProperty("existingPurchaseOrdersList", newExistingPurchaseOrders, valid);
    };

    public updatePurchaseOrderNumbers = (value: string, valid: boolean) => {
        const purchaseOrderNumbers: string[] = [value];
        this.updateProperty("purchaseOrderNumbersList", purchaseOrderNumbers, valid);
    };

    private handleUpdatedEventTypeParts = (eventTypeParts: { [x: string]: EventTypePart }, numberOfDeliveryDays: number): { [x: string]: EventTypePart } => {
        let newEventTypeParts = { ...eventTypeParts };

        if (Object.keys(newEventTypeParts).length > numberOfDeliveryDays) {
            newEventTypeParts = Object.fromEntries(Object.entries(newEventTypeParts).slice(0, numberOfDeliveryDays));
        } else if (Object.keys(newEventTypeParts).length < numberOfDeliveryDays) {
            newEventTypeParts = Object.fromEntries(
                Array.from({ length: numberOfDeliveryDays }, (_, i) => [
                    (i + 1).toString(),
                    i < Object.values(newEventTypeParts).length
                        ? Object.values(newEventTypeParts)[i]
                        : {
                            suggestedStartTime: undefined,
                            eventDuration: undefined,
                            registrationDuration: undefined,
                        }
                ])
            );
        }

        return newEventTypeParts;
    };

    private async getCourseFees(corporateOrganisationId: string, eventInstanceTypeId: string, eventInstanceDeliveryType: number, startDate: moment.Moment) {
        return new EventInstanceApi().getCourseFees(
            corporateOrganisationId,
            eventInstanceTypeId,
            startDate,
            eventInstanceDeliveryType);
    }

    private shouldUpdateCourseFees = (prevState: CreateFormState): boolean => {
        const { corporateOrganisationId, eventTypeId, startDate, eventInstanceDeliveryType } = this.state.values;
        const { corporateOrganisationId: prevCorporateOrganisationId,
            eventTypeId: prevEventTypeId,
            startDate: prevStartDate,
            eventInstanceDeliveryType: prevEventInstanceDeliveryType
        } = prevState.values;

        return corporateOrganisationId &&
            eventTypeId &&
            startDate &&
            eventInstanceDeliveryType &&
            (
                corporateOrganisationId !== prevCorporateOrganisationId ||
                eventTypeId !== prevEventTypeId ||
                startDate !== prevStartDate ||
                eventInstanceDeliveryType !== prevEventInstanceDeliveryType
            );
    };

    private shouldUpdateTotalFees = (prevState: CreateFormState): boolean => {
        const { miscellaneousOrganisationFee, closedCourseFee, uploadFee, existingPurchaseOrdersList } = this.state.values;
        const {
            miscellaneousOrganisationFee: prevMiscellaneousOrganisationFee,
            closedCourseFee: prevClosedCourseFee,
            uploadFee: prevUploadFee,
            existingPurchaseOrdersList: prevExistingPurchaseOrders
        }= prevState.values;

        const hasChanged = (current: number, previous: number) => (!isNaN(current) || !isNaN(previous)) ? current !== previous : false;

        return (
            hasChanged(closedCourseFee, prevClosedCourseFee) ||
            hasChanged(uploadFee, prevUploadFee) ||
            hasChanged(miscellaneousOrganisationFee, prevMiscellaneousOrganisationFee) ||
            existingPurchaseOrdersList !== prevExistingPurchaseOrders
        );
    };

    private getRemainingCourseFees() {
        let remainingCourseFees = this.state.values.totalFeesInPence / 100;

        this.state.values.existingPurchaseOrdersList?.forEach((purchaseOrder: PurchaseOrder) => {
            const drawdown = Math.min(purchaseOrder?.valueRemaining ?? 0, remainingCourseFees);
            remainingCourseFees -= drawdown;
        });

        return remainingCourseFees;
    }

    private getMaximumTotalUploadFee = (): number => {
        const { uploadFee, maxNumberOfAttendees } = this.state.values;
        const safeUploadFee= isNaN(uploadFee) ? 0 : uploadFee;
        const safeMaxNumberOfAttendees = isNaN(maxNumberOfAttendees) ? 0 : maxNumberOfAttendees;
        return safeUploadFee * safeMaxNumberOfAttendees;
    };
}

function mapStateToProps(
    state: AppState & VenueState & TrainerState & EventTypeState & TrainerAttributeDefinitionState & LoadingState & OrganisationState & AppCommonState
) {
    const pathname = state.router.pathname;
    const isDdrsEventInstance = new RegExp("/drink-drive(/create)?").test(pathname);
    const quickAdd = pathname.includes("quickAdd");
    const quickAddType = quickAdd
        ? pathname.includes(CorporateQuickAddEntityDictionary[QuickAddEntities.OpenCourse])
            ? QuickAddEntities.OpenCourse
            : pathname.includes(CorporateQuickAddEntityDictionary[QuickAddEntities.ClosedCourse])
                ? QuickAddEntities.ClosedCourse
                : pathname.includes(QuickAddEntityDictionary[QuickAddEntities.Course])
                    ? QuickAddEntities.Course
                    : undefined
        : undefined;
    const createForm =pathname.endsWith("/create");
    const open = quickAdd || createForm;

    const businessLineType = businessLineTypeSelector(state);

    const basePart = businessLineType === BusinessLineType.Corporate
        ? "/corporate-event-management"
        : businessLineType === BusinessLineType.Construction
            ? "/construction-event-management"
            : "/police-and-court-event-management";
    const basePath = quickAdd
        ? createBasePathSelector(basePart)(state)
        : isDdrsEventInstance
            ? ddrsBasePathSelector(state)
            : basePathSelector(state);

    let organisationOptions = businessLineType === BusinessLineType.Corporate
        ? corporateOrganisationOptionsSelector(state)
        : constructionOrganisationOptionsSelector(state);
    const isBusinessDriverAdminRole = isBusinessDriverAdmin(state);
    const isTtcCorporateAdminRole = isTtcCorporateAdmin(state);

    if (businessLineType === BusinessLineType.Corporate) {
        if (isBusinessDriverAdminRole === true && isTtcCorporateAdminRole === true) {
            organisationOptions = organisationOptions.filter(org => org.companyType.includes(CompanyTypeEnum.BusinessDriver) ||
                org.companyType.includes(CompanyTypeEnum.TTC) || org.companyType.includes(CompanyTypeEnum.LicenceBureau));
        }
        else if (isTtcCorporateAdminRole === true && isBusinessDriverAdminRole === false) {
            organisationOptions = organisationOptions
                .filter(org => org.companyType.includes(CompanyTypeEnum.TTC) || org.companyType.includes(CompanyTypeEnum.LicenceBureau));
        } else if (isBusinessDriverAdminRole === true && isTtcCorporateAdminRole === false) {
            organisationOptions = organisationOptions.filter(org => org.companyType.includes(CompanyTypeEnum.BusinessDriver));
        } else {
            organisationOptions = [];
        }
    }

    let venues = venueSelectors.unexpiredVenuesSelector(state) || [];

    if (businessLineType === BusinessLineType.Corporate) {
        venues = venues.filter(v => isVenueCorporate(v));
    } else if (businessLineType === BusinessLineType.Construction) {
        venues = venues.filter(v => isVenueConstruction(v));
    } else if (businessLineType === BusinessLineType.PoliceAndCourt) {
        venues = venues.filter(v => isVenuePoliceAndCourt(v));
    }

    const quickAddId = quickAdd?  pathname.substring( pathname.indexOf("quickAdd")):"";
    return {
        venues,
        trainers: state.trainers || [],
        eventTypes: state.eventTypes || [],
        trainerAttributeDefinitions: state.trainerAttributeDefinitions || [],
        open,
        basePath,
        quickAddId,
        quickAddType,
        loading: state.loading.active,
        businessLineType,
        organisationOptions,
        isBusinessDriverAdminRole,
        isTtcCorporateAdminRole,
        policeContracts: policeContractsSelector(state),
        constructionCategories: state.appSettings.constructionCategories
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (eventInstance: EventInstanceCreateModel, basePath: string, businessLineType: BusinessLineType, quickAddType?: QuickAddEntities) => {
            return dispatch(createEventInstance(eventInstance, basePath, businessLineType, quickAddType ));},
        dispatchClose: (basePath: string) => dispatch(push(basePath))

    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch, ownProps: OwnProps): CreateProps & OwnProps & DispatchProps {
    const { venues, trainers, eventTypes, trainerAttributeDefinitions, basePath, open, loading, policeContracts, quickAddId, quickAddType,
        businessLineType, organisationOptions, isBusinessDriverAdminRole, isTtcCorporateAdminRole, constructionCategories } = propsFromState;

    const { dispatchClose, dispatchSave } = propsFromDispatch;
    const { speficicWorkflowType, corporateOrganisationId } = ownProps;

    const workflowTypes = speficicWorkflowType
        ? [speficicWorkflowType]
        : (businessLineType === BusinessLineType.Corporate
            ? CorporateWorkflowTypeArray
            : businessLineType === BusinessLineType.Construction
                ? ConstructionWorkflowTypeArray
                : PoliceAndCourtWorkflowTypeArray);

    return {
        venues,
        trainers,
        eventTypes,
        trainerAttributeDefinitions,
        open,
        save: (eventInstance: EventInstanceCreateModel) => dispatchSave(eventInstance, basePath, businessLineType, quickAddType),
        close: () => dispatchClose(basePath),
        loading,
        policeContracts,
        quickAddId,
        quickAddType,
        businessLineType,
        organisationOptions,
        corporateOrganisationId,
        speficicWorkflowType,
        isBusinessDriverAdminRole,
        isTtcCorporateAdminRole,
        workflowTypes,
        constructionCategories
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
