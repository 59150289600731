import * as React from "react";
import { Tab } from "semantic-ui-react";
import {  DdrsAllCountriesFeeConfig } from "../../model";
import { DDRSFeeViewer } from "../EventTypeFieldPickerHelpers";
import { DisplayValueWithEffectiveDate } from "./DisplayValueWithEffectiveDate";
import { initialFeeDetail } from "./../../model";
import { Country } from "@common/crud/organisation/model";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface CourseFeesDetailsTabProps {
    ddrsCourseFees: DdrsAllCountriesFeeConfig;
}

export const CourseFeesDetailsTab: React.FC<CourseFeesDetailsTabProps> =({ ddrsCourseFees }) => {

    const courseFees = ddrsCourseFees ?? initialFeeDetail;
    return (
        <Tab.Pane>
            <h3>Course Fees</h3>
            {ObjectKeys(courseFees).map(country => (
                <React.Fragment key={country}>
                    <h4>{Country[country]}</h4>
                    <DisplayValueWithEffectiveDate
                        label={""}
                        values={courseFees[country]}
                        valueFormat={DDRSFeeViewer(null, null, +country)}
                    />
                </React.Fragment>))}
        </Tab.Pane>
    );
};
