import * as React from "react";
import { GroupEventInstancesModel, OtherTrainerTypeCategoryEnum, TrainerType } from "../../model";
import { TrainerAllocationModal } from "../trainers/TrainerAllocationModal";
import { EventType, ModuleTypeEnum } from "@common/crud/eventType/model";
import { EventInstance } from "../..";
import { TrainerFeeCalculator } from "../../TrainerFeeCalculator";
import { loadCurrentMultiDayEventInstancesGroupDetailsByTrainerType, mapTrainer } from "@common/crud/eventInstance/components/trainers/helper";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";

import { useSelector } from "react-redux";
export interface OwnProps {
    eventType: EventType;
    eventInstance: EventInstance;
    isDigital: boolean;
    trainerType: TrainerType;
    groupEventInstances?: EventInstance[];
    group?: EventInstanceGroupModel;
};

export const TrainersEdit: React.FC<OwnProps> = ({
    eventType,
    eventInstance,
    isDigital,
    trainerType,
    groupEventInstances,
    group }) => {

    const {
        startDate, startTime, eventTypeName, trainers, practicalTrainers, monitorTrainers, observerTrainers
    } = eventInstance;

    const [courseFee, setCourseFee] = React.useState([0]);

    const organisation = useSelector(policeOrganisationByDorsId(eventInstance?.forceId));
    const isMultiDayCourse = !!eventInstance?.groupId;
    const [multiDayParts, setMultiDayParts] = React.useState<GroupEventInstancesModel[]>();

    const practicalTrainer = trainerType === TrainerType.PracticalTrainer;
    const theoryTrainer = trainerType === TrainerType.TheoryTrainer;
    const theoryAndPractical = eventType.moduleType === ModuleTypeEnum.Both && (practicalTrainer || theoryTrainer);

    // #71830: If the TrainerAllocationModal is populated for a TheoryTrainer we ALSO need to get the fee for a PracticalTrainer
    // in case the user selects "Add to practical"? (onAssignToPracticalChanged).  Then we need to use fee[1] not fee[0] in
    // allocateTrainers below.  It's not ideal, but it was the smallest change I could find without taking everything apart.
    const feeType = !practicalTrainer && !theoryTrainer ? TrainerType.TheoryTrainer : trainerType;

    let start = eventInstance.deliveryDateTime;
    let end = eventInstance.deliveryDateTimeEnd;

    if (theoryAndPractical) {
        if (practicalTrainer) {
            start = eventInstance.practicalDeliveryDateTime;
            end = eventInstance.practicalDeliveryDateTimeEnd;
        }

        if (theoryTrainer) {
            start = eventInstance.theoryDeliveryDateTime;
            end = eventInstance.theoryDeliveryDateTimeEnd;
        }
    };

    React.useEffect(() => {
        if (!isMultiDayCourse && (trainerType === TrainerType.MonitorTrainer || trainerType === TrainerType.OtherTrainer)) {
            if (trainerType === TrainerType.MonitorTrainer) {
                setCourseFee([TrainerFeeCalculator.getMonitorCourseFee(eventInstance, eventType, trainerType)]);
            } else {
                setCourseFee([0]);
            }
        } else {
            setCourseFee([
                TrainerFeeCalculator.getCourseFee(eventInstance, eventType, organisation, group, false),
                TrainerFeeCalculator.getCourseFee(eventInstance, eventType, organisation, group, true)
            ]);
        }
        const groupEventInstancesForAllocation = isMultiDayCourse && group && groupEventInstances ?
            loadCurrentMultiDayEventInstancesGroupDetailsByTrainerType(group, groupEventInstances, trainerType) :
            [];
        setMultiDayParts(groupEventInstancesForAllocation);
    }, [eventInstance, eventType, group, groupEventInstances, isMultiDayCourse, trainerType, practicalTrainer, organisation]);

    function getTrainersInGroup(groupEventInstance: EventInstance, type: TrainerType) {
        switch (type) {
            case TrainerType.MonitorTrainer:
                return groupEventInstance.monitorTrainers?.map((trainer) => mapTrainer(trainer, type, groupEventInstance));
            case TrainerType.PracticalTrainer:
                return groupEventInstance.practicalTrainers?.map((trainer) => mapTrainer(trainer, type, groupEventInstance));
            case TrainerType.OtherTrainer:
                return groupEventInstance.observerTrainers?.map((trainer) => mapTrainer(trainer, type, groupEventInstance,
                    trainer.otherTrainerTypeCategory === OtherTrainerTypeCategoryEnum.Mentor
                    || trainer.otherTrainerTypeCategory === OtherTrainerTypeCategoryEnum.FirstCourseSupport));
            default:
                return groupEventInstance.trainers?.map((trainer) => mapTrainer(trainer, type, groupEventInstance));
        }
    };

    const selectedTrainers = React.useCallback((type: TrainerType) => {
        if (group) {
            let trainersList: any[] = [];
            groupEventInstances?.forEach(gei => {
                trainersList = trainersList.concat(getTrainersInGroup(gei, type));
            });
            return trainersList;
        } else {
            switch (type) {
                case TrainerType.MonitorTrainer:
                    return monitorTrainers?.map(trainer => mapTrainer(trainer, type)) ?? [];
                case TrainerType.PracticalTrainer:
                    return practicalTrainers?.map(trainer => mapTrainer(trainer, type)) ?? [];
                case TrainerType.OtherTrainer:
                    return observerTrainers?.map(trainer => mapTrainer(trainer, type, null,
                        trainer.otherTrainerTypeCategory === OtherTrainerTypeCategoryEnum.Mentor
                        || trainer.otherTrainerTypeCategory === OtherTrainerTypeCategoryEnum.FirstCourseSupport)) ?? [];
                default:
                    return trainers?.map(trainer => mapTrainer(trainer, type)) ?? [];
            }
        }
    }, [group, groupEventInstances, monitorTrainers, observerTrainers, practicalTrainers, trainers]);

    return (
        <TrainerAllocationModal
            isOneToOne={eventInstance.oneToOne}
            isDigital={isDigital}
            eventTypeName={eventTypeName}
            courseFee={courseFee}
            feeType={feeType}
            startDate={startDate}
            startTime={startTime}
            deliveryDateTime={start}
            deliveryDateTimeEnd={end}
            trainers={selectedTrainers(trainerType)}
            eventInstanceId={eventInstance.id}
            trainerType={trainerType}
            theoryAndPractical={theoryAndPractical}
            assignedMonitors={selectedTrainers(TrainerType.MonitorTrainer)}
            monitoringCandidates={[...selectedTrainers(TrainerType.TheoryTrainer), ...selectedTrainers(TrainerType.OtherTrainer)]}
            initialMonitoredTrainersIds={eventInstance.monitoredTrainersIds}
            multiDayParts={multiDayParts}
            group={group}
            moduleType={eventType.moduleType}
            durationType={eventType.durationType}
            practicalTrainers={eventInstance.practicalTrainers ?? []}
            publishedDate={eventInstance.publishDate}
            eventTypeId={eventType.id}
            workflowType={eventInstance.workflowType }
        />
    );
};
