import { BankHolidaysApi } from "@common/bankHolidays/bankHolidaysApi";
import { BankHoliday } from "@common/bankHolidays/model";
import moment from "moment";
import * as React from "react";

export function useBankHolidays() {
    const [bankHolidays, setBankHolidays] = React.useState<BankHoliday[]>([]);

    React.useEffect(() => {
        const getBankHolidays = async () => {
            setBankHolidays(await new BankHolidaysApi().get());
        };
        getBankHolidays();
    }, []);

    return {
        bankHolidays,
        isDayBankHoliday(day: moment.Moment) {
            return bankHolidays?.some(x => x.date?.isSame(day, "day"));
        }
    };
}
