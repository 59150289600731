import { DateFormat } from "@common/crud/common/DateTimeFormats";
import * as React from "react";
import moment from "moment";
import { Authorize } from "reauthorize";
import { DdrsAdmin } from "@common/auth/model";
import { ConfirmButton } from "@common/components/ConfirmButton";
import { AttachedDocumentListModel } from "../../model";

export interface FileEntryProps {
    downloadUrl: string;
    openUrl: string;
    showAllDetails: boolean;
    entry: AttachedDocumentListModel;
    onDeleteFileClick?: (entry: AttachedDocumentListModel) => void;
}

export const FileEntry: React.FC<FileEntryProps> = ({ entry, downloadUrl, showAllDetails, openUrl, onDeleteFileClick }) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(showAllDetails);

    React.useEffect(() => {
        setShowDetails(showAllDetails);
    }, [showAllDetails]);

    const onShowDetails = React.useCallback(() => setShowDetails(!showDetails), [showDetails]);

    const downloadLink = `${downloadUrl}/${entry.id}`;
    const openLink = `${openUrl}/${entry.id}`;

    return (
        <div className="entry">
            <span className="bold cursor-pointer" onClick={onShowDetails}>
                <span className="entry-type">
                    {entry.description ?? entry.fileName}
                </span>
                <span className="creation-details">
                    {moment(entry.dateCreated).format(DateFormat.Time)} by {entry.addedByEmail}
                </span>
            </span>
            {showDetails &&
                <>
                    <div>
                        <span className="message">
                            <a href={openLink} target="_blank" rel="noreferrer">Open</a>
                        </span>
                    </div>
                    <div>
                        <span className="message">
                            <a href={downloadLink}>Download</a>
                        </span>
                    </div>
                    <Authorize authorize={[DdrsAdmin]}>
                        <div>
                            <span className="message">
                                <ConfirmButton
                                    className="link-button anchor-blue file-action"
                                    header={`Delete '${entry.description}'`}
                                    content="Are you sure you want to delete this file?"
                                    onConfirm={() => onDeleteFileClick(entry)}
                                >
                                    Delete
                                </ConfirmButton>
                            </span>
                        </div>
                    </Authorize>
                </>
            }
        </div>
    );
};
