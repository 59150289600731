import moment from "moment";
import { AppState, PendingEventInstance, SearchOptions } from "./model";
import { createSelector } from "reselect";
import { DateFormat } from "../common/DateTimeFormats";
import { organisationIdSelector } from "@common/redux-helpers";
import { corporateOrConstructionBasePathSelector } from "../organisation/selectors";

export const pendingEventInstancesSelector = (state: AppState) => state.pendingEventInstances;
export const pendingEventInstanceIdSelector = (state: AppState) => state.router.params.pendingEventInstanceId;

export const pendingEventInstanceSelector = createSelector(
    pendingEventInstancesSelector,
    pendingEventInstanceIdSelector,
    (pendingEventInstances: PendingEventInstance[], id: string): PendingEventInstance => {
        return pendingEventInstances.find(c => c.id === id) || {} as any;
    }
);

export const pendingEventInstanceSearchOptionsSelector = (state: AppState): SearchOptions => ({
    ...state.router.query,
    corporateOrganisationId: state.router.params.organisationId,
    eventType: state.router.query.eventType?.split(",") ?? [],
    fromDate: state.router.query.fromDate ? moment(state.router.query.fromDate) : undefined,
    toDate: state.router.query.toDate ? moment(state.router.query.toDate) : undefined,
    venueId: state.router.query.venueId?.split(",") ?? [],
    maxPageSize: state.router.query.maxPageSize ? parseInt(state.router.query.maxPageSize, 10) : 100,
});

export const pendingEventInstanceBreadcrumbSelector = createSelector(
    pendingEventInstancesSelector,
    pendingEventInstanceIdSelector,
    (pendingEventInstances: PendingEventInstance[], id: string): string => {
        const pendingEventInstance = pendingEventInstances.find(c => c.id === id);

        return pendingEventInstance ?
            `${pendingEventInstance.venueName || "TBC"} ${pendingEventInstance.startDate?.format(DateFormat.Short) ?? ""}` :
            "Pending Course Details";
    }
);

export const organisationPendingCoursesBasePathSelector = createSelector(
    corporateOrConstructionBasePathSelector,
    organisationIdSelector,
    (basePath, orgId) => `${basePath}/${orgId}/pending-courses`
);
