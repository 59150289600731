export const getCurrencyFormat = (value: number): string => {
    return `£${(value || 0).toFixed(2)} `;
};

export const getStringCurrencyFormat = (value: string): string => {
    let numericValue = Number(value);
    if (isNaN(numericValue)) { numericValue = 0; }
    return getCurrencyFormat(numericValue);
};

export const formatCurrency = (currency: number): string => {
    if (currency === 0) {
        return "£0.00";
    }

    const absValue = Math.abs(currency / 100).toFixed(2);
    return currency < 0 ? `£(${absValue})` : `£${absValue}`;
};
