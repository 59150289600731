import * as React from "react";
import moment from "moment";
import { State as RouterState } from "redux-little-router";
import { useSelector } from "react-redux";

export const GenesysAuthorisationRedirectReturn: React.FC = () => {

    // Genesys returns the token information in the hash rather than the query parameters
    const hash = useSelector((state: RouterState) => state.router.hash);

    let token = "";
    let validUntil: moment.Moment = null;

    // Send token to parent window.
    React.useEffect(() => {
        if (token !== null)
        {
            window.top.postMessage(JSON.stringify({ error: false, token, validUntil }), "*");
        }
    }, [token, validUntil]);

    const getParameterByName = (name: string, hashString: string): string => {
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\#&]" + name + "=([^&#]*)");
        const results = regex.exec(hashString);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    if (hash !== null)
    {
        // This extracts the token
        token = getParameterByName("access_token", hash);
        const validForSecondsString = getParameterByName("expires_in", hash);
        let validForSeconds = Number.parseInt(validForSecondsString, 10);
        if (validForSeconds > 600)
        {
            // We force renewal of the token 10 mins before it is due, this should give us
            // sufficient time to complete the current phone call
            validForSeconds -= 600;
        }
        validUntil = moment().utc().add(validForSeconds, "seconds");
    }

    return (
        <div>
            { token !== null ? <h2>Genesys authorisation successful</h2> : <h2>Error, no token found after Genesys authorisation</h2> }
        </div>
    );
};
