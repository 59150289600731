import axios from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import {
    SearchOptions,
    CorporateUserListModel,
    CorporateUserDetailModel,
    CorporateUserCreateEditModel
} from "./model";
import { BusinessLineType } from "@common/redux-helpers";

export class CorporateUserApi {
    private readonly apiUrl = "/api/corporateUser";

    public async getAll(options?: SearchOptions): Promise<CorporateUserListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get<CorporateUserListModel[]>(`${this.apiUrl}?${query}`);
        return response.data.map(lm => this.parseListModel(lm));
    }

    public async detail(id: string): Promise<CorporateUserDetailModel> {
        const response = await axios.get<CorporateUserDetailModel>(`${this.apiUrl}/${id}`);
        return this.parseDetailModel(response.data);
    }

    public async save(corporateUser: CorporateUserCreateEditModel): Promise<CorporateUserDetailModel> {
        const response = await axios.put<CorporateUserDetailModel>(`${this.apiUrl}/${corporateUser.id}`, corporateUser);
        return this.parseDetailModel(response.data);
    }

    public async create(corporateUser: CorporateUserCreateEditModel): Promise<CorporateUserDetailModel> {
        const response = await axios.post<CorporateUserDetailModel>(this.apiUrl, corporateUser);
        return this.parseDetailModel(response.data);
    }

    public async findByEmail(email: string, businessLineType: BusinessLineType): Promise<CorporateUserDetailModel> {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get<CorporateUserDetailModel>(`${this.apiUrl}/findbyemail?email=${encodedEmail}&businessLineType=${businessLineType}`);
        return response.data;
    }

    public parseListModel(model: CorporateUserListModel): CorporateUserListModel {
        return {
            ...model,
            expiry: model.expiry ? moment(model.expiry) : undefined,
        };
    }

    public parseDetailModel(model: CorporateUserDetailModel): CorporateUserDetailModel {
        return {
            ...model,
            expiry: model.expiry ? moment(model.expiry) : undefined,
        };
    }
}
