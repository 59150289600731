import * as React from "react";
import { Button, Input, Modal } from "semantic-ui-react";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { formatPrice } from "../helpers";
import { useDispatch } from "react-redux";
import { markInvoiceAsPaid } from "../actions";
import { Error } from "@common/components/Error";

export interface ProcessInvoiceModalProps {
    orderId: string;
    eventInstanceId: string;
    selectedEventInstanceTotalIncludingNonRefundedCancellations?: number;
    eventInstanceTotalPayments?: number;
    invoiceReference: string;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProcessInvoiceModal: React.FC<ProcessInvoiceModalProps> = ({
    orderId,
    eventInstanceId,
    selectedEventInstanceTotalIncludingNonRefundedCancellations,
    eventInstanceTotalPayments,
    invoiceReference,
    setRefreshingRecord
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [updatedInvoiceReference, setUpdatedInvoiceReference] = React.useState<string>(invoiceReference);
    const [invoiceReferenceErrorMessage, setInvoiceReferenceErrorMessage] = React.useState<string | undefined>(undefined);

    const dispatch = useDispatch();

    const toggleModal = React.useCallback(() => {
        if (open) {
            setUpdatedInvoiceReference(invoiceReference);
        }
        setOpen(!open);
    }, [invoiceReference, open]);

    const onMarkAsPaid = React.useCallback(() => {
        if (updatedInvoiceReference) {
            dispatch(markInvoiceAsPaid(orderId, eventInstanceId, updatedInvoiceReference));
            setRefreshingRecord(true);
            toggleModal();
        } else {
            setInvoiceReferenceErrorMessage("Please enter PO reference");
        }
    }, [updatedInvoiceReference, dispatch, orderId, eventInstanceId, setRefreshingRecord, toggleModal]);

    const onInputReferenceChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedInvoiceReference(e.target.value);
        if (e.target.value) {
            setInvoiceReferenceErrorMessage("");
        }
    }, []);

    return (
        <>
            <Button onClick={toggleModal}>
                Process Invoice
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    Process Invoice
                </Modal.Header>
                <Modal.Content>
                    <LabelAndValue
                        label="Course Total"
                        value={formatPrice(selectedEventInstanceTotalIncludingNonRefundedCancellations ?? 0)} />
                    <LabelAndValue
                        label="Course Total Payments"
                        value={formatPrice(eventInstanceTotalPayments ?? 0)} />
                    <LabelAndValue
                        label="Course Remaining Balance"
                        value={formatPrice((selectedEventInstanceTotalIncludingNonRefundedCancellations ?? 0) - (eventInstanceTotalPayments  ?? 0))}
                    />
                    <LabelAndValue label="PO Reference" value="" />
                    <Input
                        value={updatedInvoiceReference}
                        type="text"
                        onChange={onInputReferenceChange}
                    />
                    {invoiceReferenceErrorMessage && <Error errorMessage={invoiceReferenceErrorMessage} />}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={toggleModal}
                        className="cancel-action"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onMarkAsPaid}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Mark as paid"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
