import * as React from "react";
import { Icon, Table } from "semantic-ui-react";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";
import { CorporateEventInstanceResultRow, BasketItem, dateRange } from "../model";
import { numberFormatter } from "@common/helpers/number-format";
import "./BasketModalItem.scss";
import { ChangeInBasketInput } from "./ChangeInBasketInput";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export interface BasketModalItemProps {
    basketItem: BasketItem;
    course: CorporateEventInstanceResultRow;
    removeBasketItem: (eventInstanceId: string, seatsDiff: number) => void;
    onChangeBasketItem: (eventInstanceId: string, seats: number) => void;
    basketChangesInProgress: boolean;
    priceIncludesVat: boolean;
}

export const BasketModalItem: React.FC<BasketModalItemProps> = ({ basketItem, course, removeBasketItem, onChangeBasketItem, basketChangesInProgress,
    priceIncludesVat }) => {
    const onRemove = React.useCallback(() => {
        if (!basketChangesInProgress) {
            removeBasketItem(basketItem.eventInstanceId, basketItem.numberOfSeats);
        }
    }, [basketChangesInProgress, basketItem.eventInstanceId, basketItem?.numberOfSeats, removeBasketItem]);

    const onChange = React.useCallback((seats: number) => {
        if (!basketChangesInProgress) {
            onChangeBasketItem(basketItem.eventInstanceId, seats);
        }
    }, [basketChangesInProgress, basketItem.eventInstanceId, onChangeBasketItem]);

    PreventScrollingOnNumberInputsHook();
    return (
        <Table.Row>
            <Table.Cell colSpan={5}>
                <div className="title-column-width basket-item-details">
                    <div>
                        {course?.eventTypeName}
                    </div>
                    <div>
                        {course?.deliveryType === DeliveryTypeEnum.Onsite
                            ? `Delivered in ${course.venueName} ${course.venueTown}`
                            : "Delivered Online"
                        }
                    </div>
                    {course.startTimes.length > 1
                        ? <div>{`${course.startTimes.length} days: ${dateRange(course.startTimes)}`}</div>
                        : <div>{course?.startTimes[0]?.format("ddd, DD MMM HH:mm")} - {course?.endTimes[0]?.format("HH:mm")}</div>
                    }
                </div>
            </Table.Cell>
            <Table.Cell colSpan={5} className="fixed-column-width">
                <ChangeInBasketInput
                    value={basketItem.numberOfSeats}
                    maxValue={course?.seatsAvailable}
                    seatsChangedForCourse={onChange}
                    basketChangesInProgress={basketChangesInProgress}
                />
            </Table.Cell>
            <Table.Cell colSpan={1} className="action-column-width">
                <Icon name="trash" className="delete-icon" onClick={onRemove} />
            </Table.Cell>
            <Table.Cell colSpan={5} className="fixed-column-width">
                &pound;{numberFormatter((priceIncludesVat ? basketItem.totalPriceInPence : basketItem.totalPriceExcludingVatInPence) / 100)}
            </Table.Cell>
        </Table.Row>
    );
};
