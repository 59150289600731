import * as React from "react";
import moment from "moment";
import { EventInstance } from "@common/crud/eventInstance";
import { useSelector } from "react-redux";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

export function useCanRebook(eventInstance: EventInstance) {
    const bookingSettings = useSelector(appSettingsSelector)?.bookingAppSettings;

    const canRebook = React.useMemo(() => {

        if (bookingSettings.alwaysShowRebookButton) {
            return true;
        }

        const isDigitalCourse = eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
        const tresholdInHours = isDigitalCourse ? bookingSettings.hideRebookButtonFromDigitalAttendeeBeforeCourseInHours :
            bookingSettings.hideRebookButtonFromClassroomAttendeeBeforeCourseInHours;
        if (tresholdInHours > 0) {
            const startDateTime = eventInstance && eventInstance.startDate && eventInstance.startDate.clone().add(eventInstance.startTime);
            return startDateTime &&  startDateTime.diff(moment(), "hours") > tresholdInHours;
        }
        const thresholdDate = eventInstance && eventInstance.startDate && eventInstance.startDate.clone().hour(12);
        return thresholdDate && thresholdDate.diff(moment(), "day") > 0;
    }, [eventInstance, bookingSettings]);

    return canRebook;
}
