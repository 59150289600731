import { EventInstanceDetailModel } from "@common/crud/eventInstance";
import moment from "moment";

export interface RegionalCoordinatorCalendarDay {
    date: moment.Moment;
    coverTrainerIds: string[];
    monitorTrainerIds: string[];
    monitorSessionsOnDay: number;
    monitorSessionsOnDaySessionOne?: number;
    monitorSessionsOnDaySessionTwo?: number;
    monitoredEventsOnDay: EventInstanceDetailModel[];
}

export interface RegionalCoordinatorCalendar {
    id: string;
    month: moment.Moment;
    days: RegionalCoordinatorCalendarDay[];
}

export interface MonitorTrainerAvailabilityForDay {
    numberOfCoursesInSameMonth: number;
    averageDaysWorked: number;
    available: boolean;
    isBooked: boolean;
    isProvisionallyBooked: boolean;
    isStandbyTrainerOnAnyOfFirstThreeSessions: boolean;
    id: string;
    fullName: string;
}

export enum RCTrainerType {
    Cover,
    Monitor
}

export interface TrainerNamesForDay {
    date: moment.Moment;
    rcTrainerType: RCTrainerType;
    trainerNames: string[];
}

export interface RegionalCoordinatorCalendarSettings {
    id?: string;
    weekdayRcCoverFee?: number;
    saturdayRcCoverFee?: number;
    sundayRcCoverFee?: number;
}
