import * as React from "react";

import { DorsAttendanceStatusesEnum, DorsAttendanceStatusesKeys } from "../model";

interface Props {
    status?: DorsAttendanceStatusesEnum;
}

export const DorsBookingAttendanceStatus: React.FC<Props> = ({ status }: Props) => {
    return <p>{DorsAttendanceStatusesKeys[status] || "Loading"}</p>;
};
