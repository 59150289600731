import * as React from "react";
import { TableCell, TableRow } from "semantic-ui-react";
import { BookingsHistoryModel } from "@common/crud/bookingsHistory/model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { basePathSelector } from "@common/crud/eventInstance/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { businessLineTypeSelector } from "@common/redux-helpers";
import { isBusinessLineTypeCorporateOrConstruction, isBusinessLineTypePoliceOrCourt, isWorkflowConstruction } from "@common/global/CommonHelpers";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";

export interface BookingsHistoryAttendeeContentProps {
    booking: BookingsHistoryModel;
    isEventInstanceMultiDay: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
    workflowType?: WorkflowTypeEnum;
}

export const BookingsHistoryAttendeeContent: React.FC<BookingsHistoryAttendeeContentProps> = ({ booking, isEventInstanceMultiDay,
    eventInstanceGroupDays, workflowType }) => {
    const basePath = useSelector(basePathSelector);
    const businessLineType = useSelector(businessLineTypeSelector);

    const isConstruction = React.useMemo(() => isWorkflowConstruction(workflowType), [workflowType]);

    const identifierValue = React.useMemo(() => {
        switch (workflowType) {
            case WorkflowTypeEnum.DDRS:
                return booking.caseNumber;
            case WorkflowTypeEnum.BusinessDriver:
                return booking.uin;
            default:
                return booking.drivingLicenceNumber;
        }
    }, [booking, workflowType]);

    const daysValue = React.useMemo(() => eventInstanceGroupDays
        .filter(eigd => booking.eventInstanceIds?.includes(eigd.eventInstanceId))
        .sort((a, b) => a.dayNumber - b.dayNumber)
        .map(eigd => eigd.dayNumber)
        .join(", ")
    , [booking.eventInstanceIds, eventInstanceGroupDays]);

    return (
        <TableRow key={`${booking.attendeeId}-${booking.dateCreated}`}>
            <TableCell>
                <a href={booking.delegateId
                    ? `/corporate-event-management/organisations/${booking.organisationId}`
                        + `/delegates/${booking.delegateId}?attendeeId=${booking.attendeeId}`
                    :`${basePath}/${booking.eventInstanceId}`
                        + `/${isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "delegates" : "attendees"}`
                        + `/${booking.attendeeId}`}>
                    {booking.attendeeName}
                </a>
            </TableCell>
            {!isConstruction && <TableCell>{identifierValue}</TableCell>}
            <TableCell>{booking.bookedBy}</TableCell>
            <TableCell>{booking.dateCreated.format(DateFormat.ShortWithTime)}</TableCell>
            {isEventInstanceMultiDay && (<TableCell>
                {daysValue}
            </TableCell>)}
            <TableCell>
                {booking.status}
                {booking.rebookedTo &&
                    <>
                        <br /><Link href={`${basePath}/${booking.eventInstanceId}`}>{booking.rebookedTo}</Link>
                    </>
                }
            </TableCell>
            {
                isBusinessLineTypePoliceOrCourt(businessLineType) &&
                <TableCell>
                    {booking.offerExpiry?.format(DateFormat.DayMonthYear)}
                </TableCell>
            }
        </TableRow>
    );
};
