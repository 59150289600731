import {
    CriteriaEnum,
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";

export const StayingInControlSection: QuestionSection = {
    id: "8",
    title: "Module 5",
    seqNum: 7,
    subSections: [{
        id: "8.1",
        text: "Staying in control 20 min",
        seqNum: 1,
        criteria: [
            { id: "1", text: "Map re-capped" },
            { id: "2", text: "Identified and addressed the key factors which can pull us away from the task of " +
                    "driving, in particular emotions and distractions" },
            { id: "3", text: "Trainer example shown to clients" },
            { id: "4", text: "Distractions, write down and hold up to screen. Trainer holds up their example." },
            { id: "5", text: "Solutions for managing distractions and technology." }
        ]
    }],
    summaryCriterium: [{ id: "6", text: "", typeEnum: CriteriaEnum.text }]
};
