/* eslint-disable max-lines */
import * as React from "react";
import { CorporatePostmarkTemplates } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";
import { validatePostmarkTemplateId } from "../validation";
import { Form, Grid } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";
import { CorporateWorkflowType, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CorporateRecipientType, RecipientTypeEnum } from "@common/crud/common/RecipientTypeEnum";
import { PostmarkTemplateInfoApi } from "../postmarkTemplateInfoApi";
import { usePreventScroll } from "react-aria";

export const CorporatePostmark: ConfigurationAreaComponent<
    CorporatePostmarkTemplates
> = ({ settings, hierarchy }) => {
    const [filtersVisible, setFiltersVisible] = React.useState<boolean>(true);
    const [workflowType, setWorkflowType] = React.useState<WorkflowTypeEnum>();
    const [deliveryType, setDeliveryType] = React.useState<DeliveryTypeEnum>();
    const [recipientType, setRecipientType] = React.useState<RecipientTypeEnum>();
    const [fieldsMatchingCriteria, setFieldsMatchingCriteria] = React.useState<string[]>();
    const [loadingData, setLoadingData] = React.useState<boolean>(true);

    usePreventScroll({ isDisabled: !loadingData });

    const items = React.useMemo(() => [
        {
            name: "SendFromEmailAlaska",
            label: "Send From Email Address - Alaska",
        },
        {
            name: "OrganisationCourseCreationClassroom",
            label: "CPC Organisation Course Creation - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationCourseCreationDigital",
            label: "CPC Organisation Course Creation - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationCourseReminderClassroom",
            label: "CPC Organisation Course Reminder - classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationCourseReminderDigital",
            label: "CPC Organisation Course Reminder - digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationCourseCancellation",
            label: "CPC Organisation Course cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEi",
            label: "Trainer allocated to EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerAllocatedEiTotal",
            label: "Trainer Allocated EI total",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerEiReminder",
            label: "Trainer reminder for Corp EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerRemovedFromCourses",
            label: "Trainer removed from Corp EI",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadReportRejected",
            label: "On Road Report Rejected",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrgCpcCertificateEmail",
            label: "CPC Organisation Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrgForsCertificateEmail",
            label: "FORS Organisation Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationWorkshopCertificateEmail",
            label: "Workshop Organisation Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrgOnRoadCertificateEmail",
            label: "On Road Organisation Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrgBusinessDriverCertificateEmail",
            label: "Business Driver Organisation Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseBookingDigital",
            label: "Business Driver Organisation Course Booking - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseBookingClassroom",
            label: "Business Driver Organisation Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCertificateEmail",
            label: "CPC Delegate Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateOnRoadCertificateEmail",
            label: " On Road Delegate Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateBusinessDriverCertificateEmail",
            label: "Business Driver Delegate Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateWorkshopCertificateEmail",
            label: "Workshop Delegate Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstanceVenueReminderEmail",
            label: "Course Venue Reminder Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstancesVenueReminderEmail",
            label: "Bulk Course Venue Reminder Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "EventInstanceVenueConfirmationEmail",
            label: "Course Venue Confirmation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OneTimePasscodeEmail",
            label: "One Time Passcode",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCourseBookingClassroom",
            label: "CPC Delegate Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCourseBookingDigital",
            label: "CPC Delegate Course Booking - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCourseReminderClassroom",
            label: "CPC Delegate Closed Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCourseReminderDigital",
            label: "CPC Delegate Closed Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CorporateFreeTypeTemplate",
            label: "Corporation free type template",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateCourseCancellation",
            label: "CPC Delegate course cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "CorporateBookingAppBookerConfirmation",
            label: "Commercial Booking App Booker Confirmation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {

            name: "WorkshopSurvey",
            label: "Workshop Survey",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "FirstCorporateOpenCourseReminder",
            label: "First Commercial Open Course Reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "SecondCorporateOpenCourseReminder",
            label: "Second Commercial Open Course Reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ThirdCorporateOpenCourseReminder",
            label: "Third Commercial Open Course Reminder",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopOrganisationCourseCreationClassroom",
            label: "Workshop Organisation Course Creation - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopOrganisationCourseCreationDigital",
            label: "Workshop Organisation Course Creation - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopOrganisationCourseReminderClassroom",
            label: "Workshop Organisation Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopOrganisationCourseReminderDigital",
            label: "Workshop Organisation Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopOrganisationCourseCancellation",
            label: "Workshop Organisation Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopDelegateCourseBookingClassroom",
            label: "Workshop Delegate Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopDelegateCourseBookingDigital",
            label: "Workshop Delegate Course Booking - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopDelegateCourseReminderClassroom",
            label: "Workshop Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopDelegateCourseReminderDigital",
            label: "Workshop Delegate Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "WorkshopDelegateCourseCancellation",
            label: "Workshop Delegate Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadOrganisationCourseCreationClassroom",
            label: "On Road Organisation Course Creation - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadOrganisationCourseReminderClassroom",
            label: "On Road Organisation Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadOrganisationCourseCancellation",
            label: "On Road Organisation Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadDelegateCourseBookingClassroom",
            label: "On Road Delegate Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadDelegateCourseReminderClassroom",
            label: "On Road Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OnRoadDelegateCourseCancellation",
            label: "On Road Delegate Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "MissingDelegateInformation",
            label: "Missing Delegate Information",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseCreationClassroom",
            label: "Business Driver Organisation Course Creation - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseReminderClassroom",
            label: "Business Driver Organisation Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseCancellation",
            label: "Business Driver Organisation Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverDelegateCourseBookingClassroom",
            label: "Business Driver Delegate Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverDelegateCourseReminderClassroom",
            label: "Business Driver Delegate Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverDelegateCourseCancellation",
            label: "Business Driver Delegate Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseCreationDigital",
            label: "Business Driver Organisation Course Creation - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationCourseReminderDigital",
            label: "Business Driver Organisation Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverDelegateCourseBookingDigital",
            label: "Business Driver Delegate Course Booking - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverDelegateCourseReminderDigital",
            label: "Business Driver Delegate Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "BusinessDriverOrganisationDelegateCancellation",
            label: "Business Driver Organisation Booking Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrderSeatsCancellationBookerEmail",
            label: "Order Seats Cancellation Booker Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerCertificateEmail",
            label: "Manager Certificate Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerCourseReminderClassroom",
            label: "Manager Course Reminder - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerCourseReminderDigital",
            label: "Manager Course Reminder - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerCourseBookingClassroom",
            label: "Manager Course Booking - Classroom",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerCourseBookingDigital",
            label: "Manager Course Booking - Digital",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerDelegateCancellation",
            label: "Manager Course Cancellation",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "TrainerEIAmendmentEmail",
            label: "Trainer EI Amendment Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "OrganisationEIAmendmentEmail",
            label: "Organisation EI Amendment Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "DelegateEIAmendmentEmail",
            label: "Delegate EI Amendment Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        },
        {
            name: "ManagerEIAmendmentEmail",
            label: "Manager EI Amendment Email",
            inputType: InputType.Number,
            validate: validatePostmarkTemplateId,
            withInfoModal: true
        }
    ], []);

    React.useEffect(() => {
        const fetchFields = async () => {
            const api = new PostmarkTemplateInfoApi();
            const fields = await api.fieldsMatchingCriteria("CorporatePostmarkTemplates", workflowType, deliveryType, recipientType);
            setFieldsMatchingCriteria(fields);
            setLoadingData(false);
        };

        setLoadingData(true);
        fetchFields();
    }, [workflowType, deliveryType, recipientType]);

    const filteredItems = React.useMemo(() => {
        if (workflowType || deliveryType || recipientType) {
            if (fieldsMatchingCriteria && fieldsMatchingCriteria.length > 0) {
                return items.filter((item) => fieldsMatchingCriteria.includes(item.name));
            } else {
                return [];
            }
        } else {
            return items;
        }
    }, [deliveryType, fieldsMatchingCriteria, items, recipientType, workflowType]);

    const onWorkflowTypeChange = React.useCallback((_: any, data: any) => {
        setWorkflowType(+data.value);
    }, []);

    const onDeliveryTypeChange = React.useCallback((_: any, data: any) => {
        setDeliveryType(+data.value);
    }, []);

    const onRecipientTypeChange = React.useCallback((_: any, data: any) => {
        setRecipientType(+data.value);
    }, []);

    const clearFilters = React.useCallback(() => {
        setWorkflowType(0);
        setDeliveryType(0);
        setRecipientType(0);
    }, []);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"float-left cursor-pointer"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filtersVisible &&
                <Grid stackable className="filter-grid" width={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Dropdown
                                label="Workflow"
                                placeholder="Any"
                                value={workflowType}
                                options={optionsFromObject(CorporateWorkflowType, "Any", "")}
                                onChange={onWorkflowTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Delivery Type"
                                placeholder="Any"
                                value={deliveryType}
                                options={optionsFromObject(DeliveryType, "Any", "")}
                                onChange={onDeliveryTypeChange}
                                selection
                            />
                            <Form.Dropdown
                                label="Recipient Type"
                                placeholder="Any"
                                value={recipientType}
                                options={optionsFromObject(CorporateRecipientType, "Any", "")}
                                onChange={onRecipientTypeChange}
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} />
                        <Grid.Column width={8}>
                            <Form.Button className="display-right" content="Clear Filters" onClick={clearFilters} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            <SettingsGrid
                area="CorporatePostmarkTemplates"
                hierarchy={hierarchy}
                settings={settings}
                items={filteredItems}
            />
        </>
    );
};
