import { AppSettingsApi } from "@common/appSettings/appSettingsApi";
import { AppSettings } from "@common/appSettings/model";
import { TtcFinanceAdministratorRole } from "@common/auth/model";
import { Spinner } from "@common/global";
import * as React from "react";
import { TrainerInvoicesSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const TierThresholdLabels = {
    Tier2: "Tier 2 Threshold",
    Tier3: "Tier 3 Threshold"
};

export const TrainerInvoices: ConfigurationAreaComponent<TrainerInvoicesSettings> = ({ settings, hierarchy }) => {

    const [currentAppSettings, setCurrentAppSettings] = React.useState<AppSettings>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

        const getCurrentAppSettings = async () => {
            setLoading(true);
            setCurrentAppSettings(await new AppSettingsApi().load());
            setLoading(false);};

        getCurrentAppSettings();
    }, []);

    return (
        <Spinner active={loading}>
            <SettingsGrid
                area="TrainerInvoices"
                hierarchy={hierarchy}
                settings={settings}
                items={[
                    { name: "EarliestInvoiceDate", label: "Earliest Invoice Date (dd/mm/yyyy)" },
                    { name: "EarliestInvoiceDateVersionTwo", label: "Earliest Invoice Date Version 2 (dd/mm/yyyy)" },
                    { name: "Enabled", label: "Enabled" },
                    { name: "SortCode", label: "Sort Code", authorize: TtcFinanceAdministratorRole },
                    { name: "AccountNumber", label: "Account Number", authorize: TtcFinanceAdministratorRole },
                    { name: "Tier2Threshold", label: TierThresholdLabels.Tier2, inputType: InputType.Number },
                    { name: "Tier3Threshold", label: TierThresholdLabels.Tier3, inputType: InputType.Number },
                ]}
                currentAppSettings={currentAppSettings}
            />
        </Spinner>
    );
};
