import { isNullOrUndefined } from "@common/global/CommonHelpers";
import { Moment } from "moment";

export const DateFormat = {
    LongNoYear: "dddd Do MMMM",                  // Thursday 15th August
    MidNoYear: "ddd DD MMM",                     // Thu 15 Aug

    Long: "dddd Do MMMM YYYY",                   // Thursday 15th August 2019
    Mid: "ddd DD MMM 'YY",                       // Thu 15 Aug '19
    Short: "DD/MM/YYYY",                         // 15/08/2019
    ShortWithSpace: "DD MMM YYYY",               // 25 JUL 2022
    ISODateFormat: "YYYY-MM-DD",                 // 2020-01-23
    MidDayOnly: "dddd",                          // Thursday

    LongWithTime: "dddd Do MMMM YYYY HH:mm",     // Thursday 15th August 2019 15:30
    MidWithTime: "ddd DD MMM 'YY HH:mm",         // Thu 15 Aug '19 15:30
    ShortWithTime: "DD/MM/YYYY HH:mm",           // 15/08/2019 15:30
    ShortWithDayAndTime: "ddd DD/MM/YYYY HH:mm", // Thu 15/08/2019 15:30

    DayMonth: "Do MMM",                          // 31st Mar
    DayMonthYear: "Do MMM YYYY",                 // 31st Mar 2020

    MonthYear: "MMMM YYYY",                      // May 2022

    Time: "HH:mm",                               // 15:30
    TimeFormat12HoursFormat: "hh:mm a",          // 3:30 PM
    TimeWithSeconds: "HH:mm:ss",                 // 15:30:11
};

export type Boundary = [number, string];

export function dateString(moment: Moment, defaultFormat: string): string {
    if (isNullOrUndefined(moment) || !moment.isValid()) {
        return "";
    }

    moment.locale(false);    // Ensure the current language will be reflected
    return moment.format(defaultFormat);
}
