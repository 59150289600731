import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { AppState } from "@common/payments/model";
import { PaymentReview } from "./PaymentReview";
import { useStickyState } from "@common/payments/useStickyState";

const StripeWrapper: React.FC = () => {
    let startPaymentProcess = useSelector((state: AppState) => state.startPaymentProcess);
    const localState = useStickyState(startPaymentProcess);

    if (!startPaymentProcess || startPaymentProcess.stripePublishKey === null) {
        startPaymentProcess = localState;
    }

    const stripePromise = loadStripe(startPaymentProcess.stripePublishKey);

    return (
        <Elements stripe={stripePromise}>
            <PaymentReview startPaymentProcess={startPaymentProcess} />
        </Elements>
    );
};

export { StripeWrapper };
