import { toast } from "@common/toasts";
import * as React from "react";
import { getTrainerBackgroundBasedOnAverageDaysWorked } from "@common/crud/eventInstance/helpers";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import { MonitorTrainerAvailabilityForDay } from "./model";

interface AssignTrainersRowProps {
    trainers: MonitorTrainerAvailabilityForDay[];
    coverIds: string[];
    setCoverIds: (ids: string[]) => void;
    monitorIds: string[];
    setMonitorIds: (ids: string[]) => void;
    bookedRcCoverTrainers?: string[];
}

export const AssignTrainersRow: React.FC<AssignTrainersRowProps> =
({ trainers, coverIds, setCoverIds, monitorIds, setMonitorIds, bookedRcCoverTrainers }) => {
    const getBooleanIcon = (value: boolean) => {
        return (<Icon name={value ? "checkmark" : "cancel"} color={value ? "green" : "red"} />);
    };

    const getTrainerStatus = (trainer: MonitorTrainerAvailabilityForDay) => {
        if (coverIds.includes(trainer.id)) {
            return "Booked as RC Cover";
        }

        if (monitorIds.includes(trainer.id)) {
            return "Booked as RC Monitor";
        }

        if (trainer.isBooked) {
            return "Booked";
        }

        if (trainer.isProvisionallyBooked) {
            return "Provisionally booked";
        }

        return trainer.isStandbyTrainerOnAnyOfFirstThreeSessions ? "Booked as Standby Trainer" : "Not Booked";
    };

    const getStatusColour = (trainer: MonitorTrainerAvailabilityForDay) => {
        if (coverIds.includes(trainer.id) || monitorIds.includes(trainer.id)) {
            return "orange";
        }

        return (trainer.isBooked || trainer.isProvisionallyBooked || trainer.isStandbyTrainerOnAnyOfFirstThreeSessions) ? "red" : "green";
    };

    const onDeselectClickedMonitor = (trainerId: string) => () => {
        if (!monitorIds.includes(trainerId)) {
            return;
        }
        setMonitorIds(monitorIds.filter(id => id !== trainerId));
    };

    const onDeselectClickedCover = (trainerId: string) => () => {
        if (coverIds.includes(trainerId) && bookedRcCoverTrainers?.includes(trainerId)) {
            toast.warning("Cannot unselect as trainer is booked as Theory Trainer on Course");
            return;
        }

        if (!coverIds.includes(trainerId)) {
            return;
        }
        setCoverIds(coverIds.filter(id => id !== trainerId));
    };

    const onSelectClickedMonitor = (trainerId: string) => () => {
        if (coverIds.includes(trainerId)) {
            toast.warning("You may not assign a person as a monitor and a cover");
            return;
        }
        setMonitorIds([...monitorIds, trainerId]);
    };

    const onSelectClickedCover = (trainerId: string) => () => {
        if (monitorIds.includes(trainerId)) {
            toast.warning("You may not assign a person as a monitor and a cover");
            return;
        }
        setCoverIds([...coverIds, trainerId]);
    };

    const getSetAsRCCoverButton = (trainer: MonitorTrainerAvailabilityForDay) => {
        if (coverIds.includes(trainer.id)) {
            return (<Button
                content="Selected"
                onClick={onDeselectClickedCover(trainer.id)}
                color="green"
            />);
        } else if (!(trainer.isBooked || trainer.isProvisionallyBooked || trainer.isStandbyTrainerOnAnyOfFirstThreeSessions)) {
            return (<Button
                content="Select"
                onClick={onSelectClickedCover(trainer.id)}
                color="black"
            />);
        }
        return (<></>);
    };

    const getSetAsRCMonitorButton = (trainer: MonitorTrainerAvailabilityForDay) => {
        if (monitorIds.includes(trainer.id)) {
            return (<Button
                content="Selected"
                onClick={onDeselectClickedMonitor(trainer.id)}
                color="green"
            />);
        } else if (!(trainer.isBooked || trainer.isProvisionallyBooked || trainer.isStandbyTrainerOnAnyOfFirstThreeSessions)) {
            return (<Button
                content="Select"
                onClick={onSelectClickedMonitor(trainer.id)}
                color="black"
            />);
        }
        return (<></>);
    };

    return (
        <>
            { trainers.map(trainer => (
                <Table.Row key={trainer.id}>
                    <Table.Cell content={trainer.fullName} />
                    <Table.Cell width={2} content={trainer.numberOfCoursesInSameMonth} />
                    <Table.Cell width={2} className={getTrainerBackgroundBasedOnAverageDaysWorked(trainer.averageDaysWorked)}
                        content={trainer.averageDaysWorked} />
                    <Table.Cell textAlign="center">{getBooleanIcon(trainer.available)}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Label color={getStatusColour(trainer)} content={getTrainerStatus(trainer)} />
                    </Table.Cell>
                    <Table.Cell>
                        {getSetAsRCCoverButton(trainer)}
                    </Table.Cell>
                    <Table.Cell>
                        {getSetAsRCMonitorButton(trainer)}
                    </Table.Cell>
                </Table.Row>
            ))}
        </>
    );
};
