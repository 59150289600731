import * as React from "react";
import { Modal } from "@common/components";
import { Button, Form, Grid } from "semantic-ui-react";
import { TimeInput, timeIsValid } from "@common/global/TimeInput";
import moment from "moment";
import { EventInstanceBreak } from "../../model";
import { v4 } from "uuid";
import { DelegateConfirmTimesModalBreak } from "./DelegateConfirmTimesModalBreak";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { saveEventInstanceTimes } from "../../actions";

export interface DelegateConfirmTimesModalProps {
    showTimesConfirmation: boolean;
    eventInstanceId: string;
    eventInstanceStartTime: moment.Duration;
    eventInstanceEndTime: moment.Duration;
    actualEventInstanceStartTime?: moment.Duration;
    actualEventInstanceEndTime?: moment.Duration;
    eventInstanceBreaks: EventInstanceBreak[];
    forceDelegateConfirmTimes?: boolean;
    confirmForcedDelegateTimeConfirmation?: () => void;
    cancelForcedDelegateTimeConfirmation?: () => void;
}

export const DelegateConfirmTimesModal: React.FC<DelegateConfirmTimesModalProps> =
    ({ showTimesConfirmation, eventInstanceId, eventInstanceStartTime, eventInstanceEndTime, eventInstanceBreaks,
        forceDelegateConfirmTimes, confirmForcedDelegateTimeConfirmation, cancelForcedDelegateTimeConfirmation,
        actualEventInstanceStartTime, actualEventInstanceEndTime }) => {
        const dispatch = useDispatch();

        const [open, setOpen] = React.useState<boolean>(false);
        const [startTime, setStartTime] = React.useState<string>((actualEventInstanceStartTime || eventInstanceStartTime)?.format("HH:mm"));
        const [endTime, setEndTime] = React.useState<string>((actualEventInstanceEndTime || eventInstanceEndTime)?.format("HH:mm"));
        const [breaks, setBreaks] = React.useState<{id: string; startTime: string; endTime: string}[]>(
            eventInstanceBreaks?.map(b => ({ id: v4(), startTime: b.startTime.format("HH:mm"), endTime: b.endTime.format("HH:mm") })) || []);

        React.useEffect(() => setStartTime((actualEventInstanceStartTime || eventInstanceStartTime)?.format("HH:mm")),
            [actualEventInstanceStartTime, eventInstanceStartTime]);
        React.useEffect(() => setEndTime((actualEventInstanceEndTime || eventInstanceEndTime)?.format("HH:mm")),
            [actualEventInstanceEndTime, eventInstanceEndTime]);
        React.useEffect(() =>
            setBreaks(eventInstanceBreaks?.map(b => ({  id: v4(), startTime: b.startTime.format("HH:mm"), endTime: b.endTime.format("HH:mm") })) || []),
        [eventInstanceBreaks]);

        const addNewBreak = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            const updatedBreaks = [...breaks, { id: v4(), startTime: "", endTime: "" }];
            setBreaks(updatedBreaks);
        }, [breaks]);

        const updateEventInstanceBreak = React.useCallback((eventInstanceBreak: {id: string; startTime: string; endTime: string}) => {
            const updatedBreaks = breaks.map(b =>
                b.id === eventInstanceBreak.id && (b.startTime !== eventInstanceBreak.startTime || b.endTime !== eventInstanceBreak.endTime)
                    ? eventInstanceBreak
                    : b
            );
            setBreaks(updatedBreaks);
        }, [breaks]);

        const removeEventInstanceBreak = React.useCallback((eventInstanceBreak: {id: string; startTime: string; endTime: string}) => {
            const updatedBreaks = breaks.filter(b => b.id !== eventInstanceBreak.id);
            setBreaks(updatedBreaks);
        }, [breaks]);

        const onOpenClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            setOpen(true);
        }, []);

        const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setOpen(false);
            if (forceDelegateConfirmTimes && cancelForcedDelegateTimeConfirmation) {
                cancelForcedDelegateTimeConfirmation();
            }

            setStartTime((actualEventInstanceStartTime || eventInstanceStartTime)?.format("HH:mm"));
            setEndTime((actualEventInstanceEndTime || eventInstanceEndTime)?.format("HH:mm"));
            setBreaks(eventInstanceBreaks?.map(b => ({  id: v4(), startTime: b.startTime.format("HH:mm"), endTime: b.endTime.format("HH:mm") })) || []);
        }, [eventInstanceBreaks, eventInstanceEndTime, eventInstanceStartTime, forceDelegateConfirmTimes, cancelForcedDelegateTimeConfirmation,
            actualEventInstanceStartTime, actualEventInstanceEndTime]);

        const onSubmitClick = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (!timeIsValid(startTime) || !timeIsValid(endTime) || breaks.some(b => !timeIsValid(b.startTime) || !timeIsValid(b.endTime))) {
                toast.error("Please provide valid data");
                return;
            }

            const startTimeMoment = moment(startTime, "HH:mm", true);
            const endTimeMoment = moment(endTime, "HH:mm", true);
            const breakMoments = breaks.map(b => ({ startTimeMoment: moment(b.startTime, "HH:mm", true), endTimeMoment: moment(b.endTime, "HH:mm", true) }));

            if (startTimeMoment > endTimeMoment || breakMoments.some(b => b.startTimeMoment > b.endTimeMoment)) {
                toast.error("Start time has to be before end time");
                return;
            }

            if (breakMoments.some(b => b.startTimeMoment < startTimeMoment || b.endTimeMoment > endTimeMoment)) {
                toast.error("Breaks can only happen during event instance");
                return;
            }

            for (let i = 0; i < breakMoments.length; i++) {
                const breakMomentCompared = breakMoments[i];
                const otherBreakMoments = breakMoments.filter((_, index) => index !== i);
                if (otherBreakMoments.some(otherBreakMoment => (breakMomentCompared.startTimeMoment <= otherBreakMoment.endTimeMoment)
                    && (breakMomentCompared.endTimeMoment >= otherBreakMoment.startTimeMoment))) {
                    toast.error("Breaks cannot overlap");
                    return;
                }
            }

            await dispatch(saveEventInstanceTimes(eventInstanceId, startTime, endTime, breaks));

            setOpen(false);
            if (forceDelegateConfirmTimes && confirmForcedDelegateTimeConfirmation) {
                confirmForcedDelegateTimeConfirmation();
            }
        }, [breaks, dispatch, endTime, eventInstanceId, startTime, forceDelegateConfirmTimes, confirmForcedDelegateTimeConfirmation]);

        return (
            <>
                {showTimesConfirmation ? <Button onClick={onOpenClick} className="link-button no-margin-bottom">Record Course Times</Button> : null}
                <Modal open={open || !!forceDelegateConfirmTimes} size="small">
                    <Modal.Header>
                        Please confirm the start and end times of this course. Also enter any breaks taken between these course times.
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Grid>
                                <h2>Course</h2>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <TimeInput
                                            label="Start Time"
                                            value={startTime}
                                            onChange={setStartTime}
                                            required
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <TimeInput
                                            label="End Time"
                                            value={endTime}
                                            onChange={setEndTime}
                                            required
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <h2>Breaks</h2>
                                {breaks.map(eventInstanceBreak => (
                                    <DelegateConfirmTimesModalBreak
                                        key={eventInstanceBreak.id}
                                        eventInstanceBreak={eventInstanceBreak}
                                        updateEventInstanceBreak={updateEventInstanceBreak}
                                        removeEventInstanceBreak={removeEventInstanceBreak}
                                    />
                                ))}
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button onClick={addNewBreak}>
                                            Add new break
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={onCloseClick} negative>
                            Cancel
                        </Button>
                        <Button
                            onClick={onSubmitClick}
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content={forceDelegateConfirmTimes ? "Submit" : "Continue"}
                        />
                    </Modal.Actions>
                </Modal>
            </>
        );
    };
