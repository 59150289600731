import { cloneDeep, includes } from "lodash";
import {
    AssesmentCriteriaModel,
    DelegateOnRoadReport,
    FuelTypeEnum, OnRoadAssessment, OnRoadReport,
    SuggestedComments,
    VehicleTypeEnum,
    assessmentSectionKeys,
    suggestedComments,
    largeVehicleDealingWithJunctionsSuggestions,
    largeVehicleObservationsSuggestions,
    largeVehicleUseOfMirrorsSuggestions,
    OnRoadReportAll,
    GenericFormSectionErrors,
    ProductCategoryWithOnRoadReport,
    ProductCategoriesWithOnRoadReports
} from "./model";
import { ProductCategoryEnum } from "@common/crud/eventType/model";

export function SectionHasErrors<U, T extends U>(sectionErrors: GenericFormSectionErrors<U, T>) {

    if (!sectionErrors) {
        return false;
    }

    return checkForMessageInObject(sectionErrors);
}

function checkForMessageInObject<U, T extends U>(sectionErrors: GenericFormSectionErrors<U, T>) {
    for (const key in sectionErrors) {
        if (sectionErrors[key]?.message) {
            return true;
        }
        else {
            return checkForMessageInObject(sectionErrors?.[key]);
        }
    }

    return false;
}

export function GetAverageScoreFromAssesmentCriteria(onRoadReport: OnRoadReport) {

    if (!onRoadReport) {
        return null;
    }

    let scoreTotal = 0;
    let numberOfSections = 0;

    assessmentSectionKeys.forEach(section => {

        for (const criteriaValue of Object.values(onRoadReport[section])) {
            if (criteriaValue && typeof criteriaValue.rating === "number") {
                scoreTotal += criteriaValue.rating;
                numberOfSections++;
            }
        }});

    if (!numberOfSections) {
        return null;
    }

    return (scoreTotal / numberOfSections).toFixed(2);
}

const defaultAssessmentCriteria: AssesmentCriteriaModel = { rating: null };
export const defaultPreCourseCheck = { completed: false };

export const onRoadDefaultValues: OnRoadReportAll = {
    vehicleDetails: {
        vehicleType: VehicleTypeEnum.None,
        fuelType: FuelTypeEnum.None,
    },

    preCourse: {
        eyeSightCheck: defaultPreCourseCheck,
        vehicleCheck: defaultPreCourseCheck,
        licenceCheck: defaultPreCourseCheck,
        healthIssues: defaultPreCourseCheck,
    },
    concentration: {
        commentaryDrive: defaultAssessmentCriteria,
        explainImpacts: defaultAssessmentCriteria,
    },
    observation: {
        effectiveObservation: defaultAssessmentCriteria,
        useOfMirrors: defaultAssessmentCriteria,
        blindSpotVision: defaultAssessmentCriteria,
        dealingWithJunctions: defaultAssessmentCriteria,
    },
    anticipation: {
        forwardPlanning: defaultAssessmentCriteria,
        decisionMaking: defaultAssessmentCriteria,
        predictingRoadUsers: defaultAssessmentCriteria,
        gearSelection: defaultAssessmentCriteria,
    },
    space: {
        movingFollowingDistance: defaultAssessmentCriteria,
        queueingFollowingDistance: defaultAssessmentCriteria,
        positioning: defaultAssessmentCriteria,
        safetyMargins: defaultAssessmentCriteria
    },

    time: {
        appropriateSpeed: defaultAssessmentCriteria,
        speedCompliance: defaultAssessmentCriteria,
        accelerationSense: defaultAssessmentCriteria,
        ecoDriving: defaultAssessmentCriteria,
    },
    parkingAndManoeuvring: {
        observations: defaultAssessmentCriteria,
        control: defaultAssessmentCriteria,
        accuracy: defaultAssessmentCriteria,
    },
    summaryText: "",
};

export function GetInitialFormValues(existingOnRoadReport: DelegateOnRoadReport | undefined) {

    if (!existingOnRoadReport) {
        return onRoadDefaultValues;
    }

    return existingOnRoadReport.report;
}

export function capitalisedName(fullName: string) {return fullName.split(" ").map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(" ");}

export function GetSuggestedComments(vehicleType: VehicleTypeEnum) {

    if (+vehicleType === VehicleTypeEnum.Car || +vehicleType === VehicleTypeEnum.None) {
        return suggestedComments;
    }

    const largeVehicleComments = cloneDeep(suggestedComments) satisfies SuggestedComments<keyof OnRoadAssessment>;;
    largeVehicleComments.observation.useOfMirrors = largeVehicleUseOfMirrorsSuggestions;
    largeVehicleComments.observation.dealingWithJunctions = largeVehicleDealingWithJunctionsSuggestions;
    largeVehicleComments.parkingAndManoeuvring.observations = largeVehicleObservationsSuggestions;

    return largeVehicleComments;
}

export function ProductCategoryHasOnRoadReport(productCategory: ProductCategoryEnum): productCategory is ProductCategoryWithOnRoadReport {
    return includes(ProductCategoriesWithOnRoadReports, productCategory);
}
