import * as React from "react";
import { Modal } from "@common/components";
import { Button, DropdownProps, Form, Icon, Popup, TextAreaProps } from "semantic-ui-react";
import { AttendeeListModel, ReasonIssueEnum, RegisterAttendeeModel } from "@common/crud/attendee/model";
import { Media } from "@common/global/AppMedia";
import { useRegisterContext } from "./register-context";
import { getReasonIssueOptions, MandatoryReasonIssue } from "./helpers";

export interface RaiseIssueModalProps {
    hasIssues: boolean;
    disabled?: boolean;
    attendee?: AttendeeListModel;
    registerAttendee: RegisterAttendeeModel;
    onAttendeeRegisterUpdated: (registerAttendee: RegisterAttendeeModel) => void;
}

export const RaiseIssueModal: React.FC<RaiseIssueModalProps> = ({ hasIssues, disabled, attendee, registerAttendee, onAttendeeRegisterUpdated }) => {
    const { eventInstanceOptions: { isDigitalEventInstance } } = useRegisterContext();

    const [reasonIssue, setReasonIssue] = React.useState<ReasonIssueEnum>();
    const [issueText, setIssueText] = React.useState<string>("");

    React.useEffect(() => {
        setReasonIssue(registerAttendee.reasonIssue);
        setIssueText(registerAttendee.issue);
    }, [registerAttendee.issue, registerAttendee.reasonIssue]);

    const [showErrors, setShowErrors] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);

    const shouldShowError = React.useCallback((value: string, reason: ReasonIssueEnum) => {
        if (MandatoryReasonIssue.includes(reason) && !value) {
            setShowErrors(true);
            return true;
        } else {
            setShowErrors(false);
            return false;
        }
    }, []);

    const submit = React.useCallback((event: any) => {
        event.preventDefault();
        if (!shouldShowError(issueText, reasonIssue)) {
            onAttendeeRegisterUpdated({
                ...registerAttendee,
                reasonIssue: +reasonIssue,
                issue: issueText
            });
            setOpen(false);
        }
    }, [issueText, onAttendeeRegisterUpdated, reasonIssue, registerAttendee, shouldShowError]);

    const onIssueUpdate = React.useCallback((_: any, { value }: TextAreaProps) => {
        shouldShowError(value as string, reasonIssue);
        setIssueText(value as string);
    }, [reasonIssue, shouldShowError]);

    const onReasonChange = React.useCallback((_e: any, { value }: DropdownProps) => {
        if (showErrors) {
            shouldShowError(issueText, +value);
        }

        setReasonIssue(+value);
    }, [issueText, shouldShowError, showErrors]);

    const openModal = React.useCallback((event: any) => {
        event.preventDefault();
        setOpen(true);
    }, []);

    const closeModal = React.useCallback((event: any) => {
        event.preventDefault();
        // On cancel reset to original values
        onAttendeeRegisterUpdated({
            ...registerAttendee,
            issue: attendee.issue,
            reasonIssue: attendee.reasonIssue
        });
        setShowErrors(false);
        setOpen(false);
    }, [attendee?.issue, attendee?.reasonIssue, onAttendeeRegisterUpdated, registerAttendee]);

    const attendeeName = React.useMemo(() => attendee.fullName, [attendee.fullName]);
    const buttonClass = React.useMemo(() => `issue-button ${hasIssues ? "error" : ""}`, [hasIssues]);
    const reasonIssueOptions = React.useMemo(() => getReasonIssueOptions(registerAttendee.completed, isDigitalEventInstance),
        [isDigitalEventInstance, registerAttendee.completed]);
    const errorMessage = React.useMemo(() => showErrors ? { content: "Issue is mandatory for given reason", pointing: "below" } : null, [showErrors]);
    const actionType = React.useMemo(() => reasonIssue !== ReasonIssueEnum.None || issueText ? "Edit issue" : "Raise issue", [issueText, reasonIssue]);

    return (
        <>
            <Media greaterThanOrEqual="computer">
                <Popup trigger={<Button
                    className={buttonClass}
                    onClick={openModal}
                    disabled={disabled}
                >
                    {actionType}
                    <Icon name="angle right" />
                </Button>} content={`${actionType} on ${attendeeName}`} />
            </Media>
            <Media lessThan="computer">
                <Button
                    className={buttonClass}
                    onClick={openModal}
                    disabled={disabled}
                >
                    {actionType}
                    <Icon name="angle right" />
                </Button>
            </Media>

            <Modal open={open}>
                <Modal.Header>
                    {`${actionType} on ${attendeeName}`}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Dropdown
                            label="Select reason"
                            placeholder="Select reason"
                            fluid
                            selection
                            value={reasonIssue}
                            onChange={onReasonChange}
                            options={reasonIssueOptions}
                        />
                        <Form.TextArea
                            name="issue"
                            placeholder="Issue"
                            value={issueText}
                            fluid
                            error={errorMessage}
                            onChange={onIssueUpdate}
                            label={"Whats the issue?"}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} className="cancel-action">Cancel</Button>
                    <Button
                        positive
                        onClick={submit}
                        labelPosition="right"
                        icon="checkmark"
                        content="Save"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
