import * as React from "react";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { basePathSelector } from "@common/crud/eventInstance/selectors";
import { AppState } from "@common/crud/eventInstance/model";
import { Button, Grid } from "semantic-ui-react";
import { reactStringReplace } from "../stringReplace";
import { HistoryRecord, HistoryLinkItem, LinkedResource, ChangeEventType } from "../model";
import { HistoryObjectModificationComponent } from "./HistoryObjectModificationComponent";
import { PendingNoteDetails } from "./PendingNoteDetails";
import { EmailDetailsViewer } from "./EmailDetailsViewer";
import { SmsDetailsViewer } from "./SmsDetailsViewer";
import { HistoryNote } from "./HistoryNote";
import { Authorize } from "reauthorize";
import { TtcFinanceAdministratorRole } from "@common/auth/model";
import { TransferPaymentModal } from "./TransferPaymentModal";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

export interface HistoryEntryProps {
    entry: HistoryRecord;
    onEditPendingNote: (entry: HistoryRecord) => void;
    showDetails: boolean;
}

export const DdrsHistoryEntryValues: React.FC<HistoryEntryProps> = ({ entry, onEditPendingNote, showDetails }) => {
    const app = useSelector(appSelector);

    const isAdminApp = React.useMemo(() => app === Apps.Admin, [app]);

    const onPreviewClick = React.useCallback(() => {
        const encodedBlobPath = encodeURIComponent(entry.noteAttachmentBlobPath);
        window.open(`/api/note/attached-documents/open/${encodedBlobPath}`);
    }, [entry.noteAttachmentBlobPath]);

    const onDownloadClick = React.useCallback(() => {
        const encodedBlobPath = encodeURIComponent(entry.noteAttachmentBlobPath);
        window.open(`/api/note/attached-documents/download/${encodedBlobPath}`);
    } ,[entry.noteAttachmentBlobPath]);

    const linkedPaymentId = entry.historyLinkItems?.find(h => h.linkedResource === LinkedResource.Payment)?.mainObjectId;
    const showTransferPaymentButton =
        !entry.eventMessage?.includes("Payment skipped") && !entry.eventMessage?.includes("Flexi-booking was added") &&
        entry.eventType === ChangeEventType.Payment && linkedPaymentId !== undefined;

    return (
        <>
            <div className="bold">
                {
                    entry.eventType === ChangeEventType.Note &&
                    <HistoryNote
                        note={entry.eventMessage}
                        correlationId={entry.correlationId}
                        noteId={entry.id}
                        eventType={entry.eventType}
                        fileName={entry.noteAttachmentFileName}
                        taskRelation={TaskRelationEnum.DdrsBooking}
                    />
                }
                <PendingNoteDetails entry={entry} onEditPendingNote={onEditPendingNote} />
            </div>
            {showDetails && <div className="entry">
                {
                    (isAdminApp && entry?.noteAttachmentFileName) && (
                        <div className="message-with-links">
                            <span>Attachment: {entry.noteAttachmentFileName}</span>
                            <br />
                            <Button content="Preview" onClick={onPreviewClick} className="link-button withoutMargin" />
                            <br />
                            <Button content="Download" onClick={onDownloadClick} className="link-button withoutMargin" />
                        </div>
                    )
                }
                {
                    entry?.historyLinkItems?.length > 0 ?
                        <div className="message-with-links">
                            <MessageWithLinks message={entry.eventMessage} historyLinkItems={entry.historyLinkItems} />
                        </div> :
                        <span className="message">
                            {entry.eventMessage}
                            {entry.eventType === ChangeEventType.Comms && <EmailDetailsViewer
                                emailMessageId={entry.properties?.EmailMessageId}
                                emailTemplateName={entry.properties?.EmailTemplateName}
                                emailFrom={entry.properties?.EmailFrom}
                            />}
                            {entry.eventType === ChangeEventType.Comms && <SmsDetailsViewer
                                smsBody={entry.properties?.SmsBody}
                                smsTemplateName={entry.properties?.SmsTemplateName}
                            />}
                            {entry.eventType === ChangeEventType.AlwaysShown && entry.properties?.TransferredToProviderName &&
                                <p>{entry.properties?.TransferredToProviderName}</p>
                            }
                        </span>
                }
                {entry.comparisonResult &&
                    <Grid className="data-modification">
                        <HistoryObjectModificationComponent historyRecord={entry} />
                    </Grid>
                }
                {showTransferPaymentButton &&
                    <Authorize authorize={TtcFinanceAdministratorRole}>
                        <TransferPaymentModal correlationId={entry.correlationId} paymentId={linkedPaymentId} />
                    </Authorize>
                }
            </div>
            }
        </>
    );
};

const MessageWithLinks: React.FC<{ message: string; historyLinkItems: HistoryLinkItem[] }> = ({ message, historyLinkItems }) => {
    const baseUrl = useSelector((state: AppState) => basePathSelector(state));
    let rna: React.ReactNode[] = [message];

    [LinkedResource.NewEventInstance, LinkedResource.OldAttendee,
        LinkedResource.OldEventInstance, LinkedResource.EventInstance, LinkedResource.NewAttendee].forEach(element => {
        rna = prepareReactNode(rna, historyLinkItems, element, baseUrl);
    });

    return (
        <span>
            {rna.map(elem => elem)}
        </span>);
};

const prepareReactNode =
    (message: React.ReactNode[], historyLinkItems: HistoryLinkItem[], elemToReplace: LinkedResource, basePath: string): React.ReactNode[] => {
        if (historyLinkItems.findIndex(x => x.linkedResource === elemToReplace) === -1) {
            return message;
        }

        const historyLinkItem = historyLinkItems.find(x => x.linkedResource === elemToReplace);

        const match = `{${LinkedResource[historyLinkItem.linkedResource]}}`;
        const href = getHrefForLinkType(historyLinkItem.linkedResource, historyLinkItem.mainObjectId, historyLinkItem.secondaryObjectId, basePath);

        return reactStringReplace(message, match, () =>
            <Link key={historyLinkItem.mainObjectId} href={href}>{historyLinkItem.mainObjectName}</Link>);
    };

const getHrefForLinkType = (linkType: LinkedResource, mainObjectId: string, secondaryObjectId: string, basePath: string) => {
    switch (linkType) {
        case LinkedResource.NewEventInstance:
        case LinkedResource.OldEventInstance:
            return `${basePath}/${mainObjectId}`;
        case LinkedResource.NewAttendee:
        case LinkedResource.OldAttendee:
            return `${basePath}/${secondaryObjectId}/attendees/${mainObjectId}`;
        case LinkedResource.EventInstance:
            return `/police-and-court-event-management/eventInstances/${mainObjectId}`;
        default:
            return "#";
    }
};
