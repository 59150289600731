import * as React from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { Modal } from "@common/components";
import { CreateForm, CreateProps, DispatchProps } from "./Create";

export class CreateModal extends React.Component<CreateProps & DispatchProps, {}> {
    private createForm: CreateForm;

    public render() {
        return (
            <Modal size="small" open={this.props.open} onClose={this.props.close}>
                <Dimmer active={this.props.loading}>
                    <Loader active={this.props.loading} />
                </Dimmer>
                <Modal.Header>Create User</Modal.Header>
                <Modal.Content>
                    <CreateForm
                        ref={instance => this.createForm = instance}
                        {...this.props}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close}>Cancel</Button>
                    <Button onClick={this.submit}>Save</Button>
                </Modal.Actions>
            </Modal >
        );
    }

    private submit = () => this.createForm.submit();
}
