import * as React from "react";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { Divider, Grid, Message } from "semantic-ui-react";
import { EventInstanceBanner } from "@common/crud/eventInstance/components/EventInstanceBanner";
import { EiSideBar } from "@common/crud/eventInstance/components/EiSideBar";
import { TrainerMonitoringDisplay } from "@common/crud/eventInstance/components/monitoring/TrainerMonitoringDisplay";
import { currentUserSelector } from "@common/auth";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { MonitorRole } from "@common/auth/model";
import { TrainerMonitoringGroup } from "@common/crud/eventInstance/components/monitoring/TrainerMonitoringGroup";

export const TrainerMonitoring: React.FC = (() => {
    const formNotAvailableMessage = "Form not available until trainer is allocated to be monitored";
    const eventInstance = useSelector(eventInstanceSelector);
    const isAdminApp = useSelector(appSelector) === Apps.Admin;
    const currentUser = useSelector(currentUserSelector);
    const isMonitor = currentUser?.roles?.includes(MonitorRole);
    const currentUserIsMonitorOnCourse = !!eventInstance.monitorTrainers?.find(t => t.id === currentUser.id);
    const currentUserIsMonitoredTrainerOnCourse = eventInstance.monitoredTrainersIds?.includes(currentUser.id);
    const trainersAndObserverTrainers = (eventInstance?.trainers ?? []).concat(eventInstance.observerTrainers ?? []);
    const eventInstanceTrainers = currentUserIsMonitoredTrainerOnCourse
        ? trainersAndObserverTrainers.filter(t => t.id === currentUser.id)
        : trainersAndObserverTrainers;
    const eventInstanceMonitor = eventInstance?.monitorTrainers && eventInstance?.monitorTrainers[0];

    const currentUserCanViewMonitoring = isMonitor || currentUserIsMonitoredTrainerOnCourse || currentUserIsMonitorOnCourse || isAdminApp;

    const content = () => {
        return (
            <Grid>
                <h1 className="event-instance-title">Monitoring</h1>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <EventInstanceBanner eventInstance={eventInstance} />
                    </Grid.Column>
                </Grid.Row>
                {eventInstance?.groupId &&
                    <TrainerMonitoringGroup
                        isAdminApp={isAdminApp}
                        currentUser={currentUser}
                        groupId={eventInstance?.groupId}
                        formNotAvailableMessage={formNotAvailableMessage} />}
                {!eventInstance?.groupId && eventInstanceTrainers?.filter(e => eventInstance?.monitoredTrainersIds.includes(e.id)).map((e, i, arr) => (
                    <React.Fragment key={e.id}>
                        <Grid.Row>
                            <TrainerMonitoringDisplay eventTrainer={e} eventMonitor={eventInstanceMonitor} eventInstance={eventInstance} />
                        </Grid.Row>
                        {(i !== arr.length - 1) && <Divider />}
                    </React.Fragment>
                ))}
                {
                    !eventInstance?.groupId && !eventInstance?.monitoredTrainersIds?.length &&
                    <Grid.Row>
                        <Grid.Column>
                            <Message content={formNotAvailableMessage} info icon={"info circle"} />
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        );
    };

    return <EiSideBar children={currentUserCanViewMonitoring ? content() : <></>} />;
});
