import axios from "axios";
import * as QueryString from "query-string";
import moment from "moment";
import { FilterOptions } from "@booking/eventInstance/model";
import { Venue } from "@common/crud/venue/model";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { GetAllForBookingResponse } from "./model";
import { CourseSearchResultRow, GroupCourseSearchResultRow } from "@booking/landing/model";
import { ParseEventInstanceGroupItem } from "@common/crud/eventInstanceGroup/helpers";

export class CourseSearchApi {

    private readonly apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/booking/${bookingId}/coursesearch` : "/api/coursesearch";
    }

    public async getAllForBooking(searchOptions: FilterOptions): Promise<GetAllForBookingResponse> {
        const parsedQuery = {
            ...searchOptions,
            toDate: searchOptions.toDate ? searchOptions.toDate.format(DateFormat.ISODateFormat) : undefined,
            fromDate: searchOptions.fromDate ? searchOptions.fromDate.format(DateFormat.ISODateFormat) : undefined,
        };
        const response = await axios.get(`${this.apiUrl}/search?${QueryString.stringify(parsedQuery)}`);
        const data = response.data as GetAllForBookingResponse;
        return {
            ...data,
            venues: data.venues.map(this.parseVenue),
            eventInstances: data.eventInstances.map(this.parseEventInstance),
            eventInstanceGroupItems: data.eventInstanceGroupItems.map(CourseSearchApi.parseEventInstanceGroupSearchResultRow)
        };
    }

    private parseVenue<T extends Venue>(model: T): T {
        return {
            ...model,
            expiryDate: model.expiryDate
                ? moment(model.expiryDate)
                : undefined
        };
    }

    private parseEventInstance(model: CourseSearchResultRow): CourseSearchResultRow {
        return {
            ...model,
            startDate: model.startDate && moment(model.startDate).utc().startOf("day"),
            startTime: model.startTime && moment.duration(model.startTime),
            endTime: model.endTime && moment.duration(model.endTime),
            priceInPence: model.priceInPence < 0 ? 0 : model.priceInPence,
        };
    }

    private static parseEventInstanceGroupSearchResultRow(item: GroupCourseSearchResultRow): GroupCourseSearchResultRow {
        return {
            ...item,
            startDate: item.startDate && moment(item.startDate),
            startTime: item.startTime && moment.duration(item.startTime),
            endTime: item.endTime && moment.duration(item.endTime),
            priceInPence: item.priceInPence < 0 ? 0 : item.priceInPence,
            dayParts: item.dayParts.map(ParseEventInstanceGroupItem)
        };
    }
}
