/* eslint-disable no-nested-ternary */
import { Basket, BasketComparisonResult, BasketDifference, OrderPaymentStatus } from "./model";

export const prepareOrdersUrl = (eventManagementPath: string, path: string, corporateOrganisationId?: string, corporateUserId?: string, query?: string,
    enquiryEventInstanceId?: string
) => {
    let baseRoute = eventManagementPath;
    if (corporateOrganisationId) {
        baseRoute = baseRoute + `/organisations/${corporateOrganisationId}`;
    }

    if (corporateUserId) {
        baseRoute = baseRoute + `/users/${corporateUserId}`;
    }

    baseRoute = query
        ? `${baseRoute}/${path}?addition=${query}`
        : `${baseRoute}/${path}`;

    baseRoute = enquiryEventInstanceId
        ? query
            ? `${baseRoute}&enquiryEventInstanceId=${enquiryEventInstanceId}`
            : `${baseRoute}?enquiryEventInstanceId=${enquiryEventInstanceId}`
        : baseRoute;

    return baseRoute;
};

export const compareBaskets = (oldBasket: Basket, newBasket: Basket): BasketComparisonResult => {
    const differences: BasketDifference[] = [];

    if (oldBasket?.items && Object.values(oldBasket?.items).length > 0) {
        for (const itemId in oldBasket.items) {
            if (itemId in newBasket.items) {
                const oldItem = oldBasket.items[itemId];
                const newItem = newBasket.items[itemId];
                if (oldItem.pricePerSeatInPence !== newItem.pricePerSeatInPence || oldBasket.discount !== newBasket.discount) {
                    differences.push({
                        eventInstanceId: oldItem.eventInstanceId,
                        numberOfSeats: oldItem.numberOfSeats,
                        oldPricePerSeatInPence: oldItem.pricePerSeatInPence,
                        newPricePerSeatInPence: newItem.pricePerSeatInPence,
                        oldTotalPriceInPence: oldItem.totalPriceInPence,
                        newTotalPriceInPence: newItem.totalPriceInPence,
                        priceChanged: oldItem.pricePerSeatInPence !== newItem.pricePerSeatInPence
                    });
                }
            }
        }
    }

    if (differences.length > 0) {
        return {
            differences,
            oldTotal: oldBasket.total,
            newTotal: newBasket.total,
            oldDiscount: oldBasket.discount,
            newDiscount: newBasket.discount,
            discountChanged: oldBasket.discount !== newBasket.discount
        };
    } else {
        return undefined;
    }
};

export function formatPrice(price: number) {
    return `£${(price / 100).toFixed(2)}`;
}

export function getPaymentStatusName(status: OrderPaymentStatus) {
    const paymentStatuses = {
        0: "Unknown",
        1: "Outstanding",
        2: "Invoiced",
        3: "Not required",
    };
    return paymentStatuses[status];
}
