import * as React from "react";
import { Container } from "semantic-ui-react";

export const PublishConfirmationText = () => {
    return (
        <Container>
            <h3 className="status-attention">Are you sure?</h3>
            <p>Once published, the Courses will be visible to — and bookable by — customers or staff.</p>
            <p>Also, the Courses will no longer be editable except to modify the Trainers delivering the event.</p>
        </Container>
    );
};
