import React, { useState } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { UpdateDelegatesMatchingDataField } from "./UpdateDelegatesMatchingDataField";
import { DelegateComparisonType, DelegateComparisonTypeEnum, DrivingLicenceCountry, VocationalLicenceCategory } from "@common/crud/delegate/model";
import { Address } from "@common/crud/common";

interface UpdateDelegatesComparisonModalProps {
    open: boolean;
    comparisonFields: { field: string; needsComparison: boolean; attendeeFieldDisplayName?: string }[];
    attendeeManualData: AttendeeListModel;
    attendeeDelegateData: AttendeeListModel;
    delegateComparisonType: DelegateComparisonTypeEnum;
    onCancel: () => void;
    onContinue: (resultAttendee: AttendeeListModel) => void;
}

const getAddressValueRepresentation = (address: Address) => {
    let valueRepresentation = "";
    if (address?.addressLine1) {
        valueRepresentation = address.addressLine1;
    }
    if (address?.addressLine2) {
        if (valueRepresentation !== "") {
            valueRepresentation = valueRepresentation + ", ";
        }
        valueRepresentation = valueRepresentation + address.addressLine2;
    }
    if (address?.addressLine3) {
        if (valueRepresentation !== "") {
            valueRepresentation = valueRepresentation + ", ";
        }
        valueRepresentation = valueRepresentation + address.addressLine3;
    }
    if (address?.city) {
        if (valueRepresentation !== "") {
            valueRepresentation = valueRepresentation + ", ";
        }
        valueRepresentation = valueRepresentation + address.city;
    }
    if (address?.postalCode) {
        if (valueRepresentation !== "") {
            valueRepresentation = valueRepresentation + ", ";
        }
        valueRepresentation = valueRepresentation + address.postalCode;
    }
    return valueRepresentation ? valueRepresentation : "-";
};

export const UpdateDelegatesComparisonModal = (props: UpdateDelegatesComparisonModalProps) => {
    const { open, comparisonFields, attendeeManualData, attendeeDelegateData, delegateComparisonType, onCancel, onContinue } = props;

    const attendeeFieldComparisonFields = React.useMemo(() => comparisonFields.filter(f => f.attendeeFieldDisplayName), [comparisonFields]);

    const [resultAttendee, setResultAttendee] = useState(attendeeDelegateData);

    React.useEffect(() => setResultAttendee(attendeeDelegateData), [attendeeDelegateData]);

    const onCancelClick = React.useCallback(() => onCancel(), [onCancel]);
    const onContinueClick = React.useCallback(() => onContinue(resultAttendee), [onContinue, resultAttendee]);

    const setUpdatedManagers = React.useCallback((managers: { name: string; telephone: string; email: string }[]) => {
        setResultAttendee({ ...resultAttendee, managers });
    }, [resultAttendee]);

    const setUpdateForename = React.useCallback((forename: string) => {
        setResultAttendee({ ...resultAttendee, forename });
    }, [resultAttendee]);

    const setUpdateSurname = React.useCallback((surname: string) => {
        setResultAttendee({ ...resultAttendee, surname });
    }, [resultAttendee]);

    const setUpdateDrivingLicenceCountry = React.useCallback((drivingLicenceCountry: number) => {
        setResultAttendee({ ...resultAttendee, drivingLicenceCountry });
    }, [resultAttendee]);

    const setUpdateVocationalLicenceCategories = React.useCallback((vocationalLicenceCategories: number[]) => {
        setResultAttendee({ ...resultAttendee, vocationalLicenceCategories });
    }, [resultAttendee]);

    const setUpdateDrivingLicenceExactCountry = React.useCallback((drivingLicenceExactCountry: string) => {
        setResultAttendee({ ...resultAttendee, drivingLicenceExactCountry });
    }, [resultAttendee]);

    const setUpdateDrivingLicenceNumber = React.useCallback((drivingLicenceNumber: string) => {
        setResultAttendee({ ...resultAttendee, drivingLicenceNumber });
    }, [resultAttendee]);

    const setUpdateDateOfBirth = React.useCallback((dateOfBirth: moment.Moment) => {
        setResultAttendee({ ...resultAttendee, dateOfBirth });
    }, [resultAttendee]);

    const setUpdateDqcReference = React.useCallback((dqcReference: string) => {
        setResultAttendee({ ...resultAttendee, dqcReference });
    }, [resultAttendee]);

    const setUpdateDqcExpiry = React.useCallback((dqcExpiry: moment.Moment) => {
        setResultAttendee({ ...resultAttendee, dqcExpiry });
    }, [resultAttendee]);

    const setUpdateEmail = React.useCallback((email: string) => {
        setResultAttendee({ ...resultAttendee, email });
    }, [resultAttendee]);

    const setUpdateTelephone = React.useCallback((telephone: string) => {
        setResultAttendee({ ...resultAttendee, telephone });
    }, [resultAttendee]);

    const setUpdateAddress = React.useCallback((address: Address) => {
        setResultAttendee({ ...resultAttendee, address });
    }, [resultAttendee]);

    const setUpdateAttendeeField = React.useCallback((value: any, attendeeFieldName: string) => {
        const attendeeFieldValue = resultAttendee.attendeeFieldValues.find(afv => afv.displayName === attendeeFieldName);
        const newAttendeeFieldValue = { ...attendeeFieldValue, value };
        const attendeeFieldValues = [...resultAttendee.attendeeFieldValues.filter(f => f.displayName !== attendeeFieldName), newAttendeeFieldValue];
        setResultAttendee({ ...resultAttendee, attendeeFieldValues });
    }, [resultAttendee]);

    const getManagerPresentation = React.useCallback((manager: { name: string; telephone: string; email: string }) => {
        return [manager.name, manager.email && `[${manager.email}]`, manager.telephone && `[${manager.telephone}]`]
            .filter(Boolean)
            .join(" ");
    }, []);

    return (
        <Modal size="large" open={open}>
            <Modal.Header>
                Compare Attendee Data
            </Modal.Header>
            <Modal.Content>
                <Grid container stackable className="nomargintop">
                    <Grid.Row>
                        Based on the {DelegateComparisonType[delegateComparisonType]} entered, we detect that there are differences
                        between the data from the existing delegate record and the data you have entered on the register.
                    </Grid.Row>
                    <Grid.Row>
                        Please tick the values that you would like to save on the register.
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4} />
                        <Grid.Column width={6} as="h2">
                            Manual Attendee Data
                        </Grid.Column>
                        <Grid.Column width={6} as="h2">
                            Delegate Attendee Data
                        </Grid.Column>
                    </Grid.Row>
                    {comparisonFields.some(f => f.field === "managers" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Managers"
                            attendeeManualValue={attendeeManualData?.managers}
                            attendeeManualValuePresentation={attendeeManualData?.managers?.map(getManagerPresentation).join(", ")}
                            attendeeDelegateValue={attendeeDelegateData?.managers}
                            attendeeDelegateValuePresentation={attendeeDelegateData?.managers?.map(getManagerPresentation).join(", ")}
                            resultValue={resultAttendee?.managers}
                            onUpdateChanged={setUpdatedManagers}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "forename" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Forename"
                            attendeeManualValue={attendeeManualData?.forename}
                            attendeeDelegateValue={attendeeDelegateData?.forename}
                            resultValue={resultAttendee?.forename}
                            onUpdateChanged={setUpdateForename}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "surname" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Surname"
                            attendeeManualValue={attendeeManualData?.surname}
                            attendeeDelegateValue={attendeeDelegateData?.surname}
                            resultValue={resultAttendee?.surname}
                            onUpdateChanged={setUpdateSurname}
                        />
                    )}
                    {attendeeFieldComparisonFields.length > 0 && attendeeFieldComparisonFields.map(f => (
                        <UpdateDelegatesMatchingDataField
                            key={`attendee_field_${f.attendeeFieldDisplayName}`}
                            label={f.attendeeFieldDisplayName}
                            attendeeManualValue={attendeeManualData?.attendeeFieldValues?.find(af => af.displayName === f.attendeeFieldDisplayName)?.value}
                            attendeeDelegateValue={attendeeDelegateData?.attendeeFieldValues?.find(af => af.displayName === f.attendeeFieldDisplayName)?.value}
                            resultValue={resultAttendee?.attendeeFieldValues?.find(af => af.displayName === f.attendeeFieldDisplayName)?.value}
                            attendeeFieldDisplayName={f.attendeeFieldDisplayName}
                            onUpdateChanged={setUpdateAttendeeField}
                        />
                    ))}
                    {comparisonFields.some(f => f.field === "drivingLicenceCountry" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Driving Licence Country"
                            attendeeManualValue={attendeeManualData?.drivingLicenceCountry}
                            attendeeManualValuePresentation={
                                attendeeManualData?.drivingLicenceCountry
                                    ? DrivingLicenceCountry[attendeeManualData.drivingLicenceCountry]
                                    : ""
                            }
                            attendeeDelegateValue={attendeeDelegateData?.drivingLicenceCountry}
                            attendeeDelegateValuePresentation={
                                attendeeDelegateData?.drivingLicenceCountry
                                    ? DrivingLicenceCountry[attendeeDelegateData.drivingLicenceCountry]
                                    : ""
                            }
                            resultValue={resultAttendee?.drivingLicenceCountry}
                            onUpdateChanged={setUpdateDrivingLicenceCountry}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "drivingLicenceNumber" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Driving Licence Number"
                            attendeeManualValue={attendeeManualData?.drivingLicenceNumber}
                            attendeeDelegateValue={attendeeDelegateData?.drivingLicenceNumber}
                            resultValue={resultAttendee?.drivingLicenceNumber}
                            onUpdateChanged={setUpdateDrivingLicenceNumber}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "drivingLicenceExactCountry" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Issuing Country"
                            attendeeManualValue={attendeeManualData?.drivingLicenceExactCountry}
                            attendeeDelegateValue={attendeeDelegateData?.drivingLicenceExactCountry}
                            resultValue={resultAttendee?.drivingLicenceExactCountry}
                            onUpdateChanged={setUpdateDrivingLicenceExactCountry}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "dateOfBirth" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Date of Birth"
                            attendeeManualValue={attendeeManualData?.dateOfBirth}
                            attendeeManualValuePresentation={attendeeManualData?.dateOfBirth?.format("DD/MM/YYYY")}
                            attendeeDelegateValue={attendeeDelegateData?.dateOfBirth}
                            attendeeDelegateValuePresentation={attendeeDelegateData?.dateOfBirth?.format("DD/MM/YYYY")}
                            resultValue={resultAttendee?.dateOfBirth}
                            onUpdateChanged={setUpdateDateOfBirth}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "vocationalLicenceCategories" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Vocational Licence Categories"
                            attendeeManualValue={attendeeManualData?.vocationalLicenceCategories}
                            attendeeManualValuePresentation={
                                attendeeManualData?.vocationalLicenceCategories?.map(vlc => VocationalLicenceCategory[vlc]).join(", ")
                            }
                            attendeeDelegateValue={attendeeDelegateData?.vocationalLicenceCategories}
                            attendeeDelegateValuePresentation={
                                attendeeDelegateData?.vocationalLicenceCategories?.map(vlc => VocationalLicenceCategory[vlc]).join(", ")
                            }
                            resultValue={resultAttendee?.vocationalLicenceCategories}
                            onUpdateChanged={setUpdateVocationalLicenceCategories}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "dqcReference" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="DQC Reference"
                            attendeeManualValue={attendeeManualData?.dqcReference}
                            attendeeDelegateValue={attendeeDelegateData?.dqcReference}
                            resultValue={resultAttendee?.dqcReference}
                            onUpdateChanged={setUpdateDqcReference}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "dqcExpiry" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="DQC Expiry"
                            attendeeManualValue={attendeeManualData?.dqcExpiry}
                            attendeeManualValuePresentation={attendeeManualData?.dqcExpiry?.format("DD/MM/YYYY")}
                            attendeeDelegateValue={attendeeDelegateData?.dqcExpiry}
                            attendeeDelegateValuePresentation={attendeeDelegateData?.dqcExpiry?.format("DD/MM/YYYY")}
                            resultValue={resultAttendee?.dqcExpiry}
                            onUpdateChanged={setUpdateDqcExpiry}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "email" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Email"
                            attendeeManualValue={attendeeManualData?.email}
                            attendeeDelegateValue={attendeeDelegateData?.email}
                            resultValue={resultAttendee?.email}
                            onUpdateChanged={setUpdateEmail}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "telephone" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Contact number"
                            attendeeManualValue={attendeeManualData?.telephone}
                            attendeeDelegateValue={attendeeDelegateData?.telephone}
                            resultValue={resultAttendee?.telephone}
                            onUpdateChanged={setUpdateTelephone}
                        />
                    )}
                    {comparisonFields.some(f => f.field === "address" && f.needsComparison) && (
                        <UpdateDelegatesMatchingDataField
                            label="Address"
                            attendeeManualValue={attendeeManualData?.address}
                            attendeeManualValuePresentation={getAddressValueRepresentation(attendeeManualData?.address)}
                            attendeeDelegateValue={attendeeDelegateData?.address}
                            attendeeDelegateValuePresentation={getAddressValueRepresentation(attendeeDelegateData?.address)}
                            resultValue={resultAttendee?.address}
                            onUpdateChanged={setUpdateAddress}
                        />
                    )}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancelClick} className="cancel-action">
                    Cancel
                </Button>
                <Button
                    onClick={onContinueClick}
                    positive
                    labelPosition="right"
                    icon="checkmark"
                    content="Continue"
                />
            </Modal.Actions>
        </Modal>
    );
};
