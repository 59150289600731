import { marked } from "marked";
import * as React from "react";
import TurndownService from "turndown";
import { Input } from "@neworbit/simpleui-input";
import { TextareaInputProps } from "@neworbit/simpleui-input/dist/string/textarea-input";
import { Button } from "semantic-ui-react";

import { HtmlRenderer } from "./HtmlRenderer";
import "./MarkdownEditor.scss";
import { sanitize } from "dompurify";

export function MarkdownEditor(props: TextareaInputProps) {
    const turndownService = new TurndownService();
    const [markdownValue, setMarkdownValue] = React.useState(turndownService.turndown(props.value || ""));

    const [previewVisible, setPreviewVisible] = React.useState(false);

    const showPreview = React.useCallback(() => setPreviewVisible(true), []);
    const hidePreview = React.useCallback(() => setPreviewVisible(false), []);

    const onChange = React.useCallback((value, valid) => {
        setMarkdownValue(value);
        props.onChange(marked.parse(sanitize(value), { async: false }) as string, valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(
        () => setMarkdownValue(turndownService.turndown(props.value || "")),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.value?.length > 0]);

    const editor = previewVisible ? <HtmlRenderer value={props.value} /> : <Input.Textarea {...props} label={""} value={markdownValue} onChange={onChange} />;

    return (
        <div className={`field${props.required ? " required" : ""}`}>
            <label>{props.label}</label>
            <div className="editor-options">
                <Button type="button" className={previewVisible ? "basic" : ""} onClick={hidePreview}>Write</Button>
                <Button type="button" className={!previewVisible ? "basic" : ""} onClick={showPreview}>Preview</Button>
            </div>
            {editor}
        </div>
    );
}
