import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import "./Legend.scss";
import { MobileLegend } from "./MobileLegend";
import { DesktopLegend } from "./DesktopLegend";
import { Area } from "@common/crud/eventInstance/model";

interface ResponsiveLegendProps{
    mobile: boolean;
    area: Area;
}

export const ResponsiveLegend: React.FC<ResponsiveLegendProps> = ( { mobile, area } ) => {
    const [showLegend, setShowLegend] = React.useState(false);

    function show() {
        setShowLegend(true);
    }

    function hide() {
        setShowLegend(false);
    }

    const ShowHideButton = () => showLegend ? <Button onClick={hide}>Hide Legend</Button> : <Button onClick={show}>Show Legend</Button>;
    const LegendWithHeader = () => showLegend &&
    <Grid className="ui divided availability-legend mobile"  >
        <Grid.Row>
            <Grid.Column><h3>Legend</h3></Grid.Column>
        </Grid.Row>
        {mobile
            ? <MobileLegend />
            : <DesktopLegend area={area} />}
    </Grid>;

    return (
        <>
            {area === Area.AdminEventManagementDigitalPlanning ?
                <Grid.Row>
                    <Grid.Column>
                        <ShowHideButton />
                    </Grid.Column>
                    <Grid.Column>
                        <LegendWithHeader />
                    </Grid.Column>
                </Grid.Row>
                :<>
                    <ShowHideButton />
                    <LegendWithHeader />
                </>
            }
        </>
    );
};
