import * as React from "react";
import { Link } from "redux-little-router";
import { Button, Table } from "semantic-ui-react";
import { orderBy } from "lodash";
import { Order } from "../model";
import { OrderEventInstanceRow } from "./OrderEventInstanceRow";
import { formatPrice, prepareOrdersUrl } from "../helpers";
import { useSelector } from "react-redux";
import { universalEventManagementPathSelector } from "@common/redux-helpers";
interface OrderRowProps {
    order: Order;
    extended: boolean;
    corporateOrganisationId?: string;
    corporateUserId?: string;
    showEventInstances: (orderId: string) => void;
}

export const OrderRow: React.FC<OrderRowProps> = ({
    order,
    extended,
    corporateOrganisationId,
    corporateUserId,
    showEventInstances,
}) => {
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const showEventInstancesForOrder = React.useCallback(() => {
        showEventInstances(order.id);
    }, [order.id, showEventInstances]);

    const orderLink = React.useMemo(() => prepareOrdersUrl(eventManagementPath, `orders/${order.id}`, corporateOrganisationId, corporateUserId),
        [corporateOrganisationId, corporateUserId, order.id, eventManagementPath]);

    const orderRow = React.useMemo(() => (
        <Table.Row key={order.id}>
            <Table.Cell>
                <Link href={orderLink}>
                    {order.bookingReference}
                </Link>
            </Table.Cell>
            <Table.Cell>{order.bookingDate.format("LL")}</Table.Cell>
            {!corporateOrganisationId && (
                <Table.Cell>
                    <Link
                        href={`${eventManagementPath}/organisations/${order.organisationId}`}
                    >
                        {order.organisationName}
                    </Link>
                </Table.Cell>
            )}
            {!corporateUserId && (
                <Table.Cell>
                    <Link
                        href={`${eventManagementPath}/organisations/${order.organisationId}/users/${order.bookerId}`}
                    >
                        {order.bookerName}
                    </Link>
                </Table.Cell>
            )}
            <Table.Cell>{order.attendeeCount}</Table.Cell>
            <Table.Cell>{formatPrice(order.total ?? 0)}</Table.Cell>
            <Table.Cell>
                <Button
                    id={extended ? "extended" : "not_extended"}
                    icon={extended ? "angle up" : "angle down"}
                    onClick={showEventInstancesForOrder}
                />
            </Table.Cell>
        </Table.Row>
    ), [order, corporateOrganisationId, corporateUserId, extended, showEventInstancesForOrder, orderLink, eventManagementPath]);

    const colSpan = React.useMemo(() => {
        let calculatedColSpan = 7;
        if (corporateOrganisationId) {
            calculatedColSpan = calculatedColSpan - 1;
        }
        if (corporateUserId) {
            calculatedColSpan = calculatedColSpan - 1;
        }
        return calculatedColSpan;
    }, [corporateOrganisationId, corporateUserId]);

    const orderEventInstancesToShow = React.useMemo(() =>  (order?.eventInstances || []).filter(ei => {
        const eiTotal = order?.eventInstanceTotals
            ? order?.eventInstanceTotals[ei.eventInstanceId] ?? 0
            : 0;
        return ei.attendeeCount > 0 || eiTotal > 0;
    }), [order?.eventInstanceTotals, order?.eventInstances]);

    return (
        <>
            {orderRow}
            {extended && order.eventInstances.length > 0 && (
                <Table.Cell colSpan={colSpan}>
                    <Table>
                        <Table.Header className="grey">
                            <Table.Row>
                                <Table.HeaderCell>Venue</Table.HeaderCell>
                                <Table.HeaderCell>Product</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Attendees</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {orderBy(orderEventInstancesToShow, ei => ei.eventInstanceStartDate).map((eventInstance, i) => (
                                <OrderEventInstanceRow
                                    key={`orderEventInstance_${i}`}
                                    order={order}
                                    eventInstance={eventInstance}
                                    orderLink={orderLink}
                                    index={i}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            )}
        </>
    );
};
