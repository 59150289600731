import { createValidator } from "not-valid";
import moment from "moment";

export const futureDateValidator = createValidator<moment.Moment>(
    v => v && v.isValid() ? moment().isBefore(v) : true,
    "Please enter a date after today");

export const muiFutureDateValidator = (value: moment.Moment) => {
    return (value && value.isValid() && !value.isAfter(moment(), "day"))
        ? "Please enter a date after today"
        : undefined;
};

export const todayOrfutureDateValidator = () => {
    return [createValidator<moment.Moment>(
        v => v && v.isValid() ? v.isSameOrAfter(moment(), "day") : true,
        "Please enter today's or future date")];
};

export const muiTodayOrfutureDateValidator = (value: moment.Moment) => {
    return (value && !value.isSameOrAfter(moment(), "day"))
        ? "Please enter today's or future date"
        : undefined;
};

export const muiAnyDateValidator = (value: moment.Moment) => {
    return (!value || value.isValid())
        ? undefined
        : "Please enter a valid date";
};
