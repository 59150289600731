/* eslint-disable max-lines */
/* tslint:disable:no-consecutive-blank-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { DropdownItemProps, Form } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, BusinessLineType, businessLineTypeSelector, createBasePathSelector } from "@common/redux-helpers";
import { phoneNumberWithSpaces } from "@common/validation";
import { optionsFromObject } from "@common/crud/common";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { muiFutureDateValidator } from "@common/validation/futureDateValidator";
import {
    OrganisationState,
    selectors as organisationSelectors,
    Organisation
} from "@common/crud/organisation";
import { LoadingState } from "redux-request-loading";
import { validators } from "not-valid";
import { createVenue } from "../actions";
import { basePathSelector } from "../selectors";
import { VenueCreateEditModel, AppState, VenueAttributes, DorsSiteModel, workflowTypesValidator } from "../model";
import { CreateModal } from "./CreateModal";
import { DeliveryType, DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { CurrencyInput } from "@common/global/CurrencyInput";
import { WorkflowTypeEnum, WorkflowTypeOptions } from "@common/crud/eventType/model";
import { VenueApi } from "../venueApi";
import { getDorsSitesOptions, getDsaAreasOptions } from "./helpers";
import { DsaAreaListItem } from "@common/dsa";
import { toast } from "react-toastify";

import { ConstructionVenueType, CorporateVenueType, Country, PoliceAndCourtVenueType,
    VenueTypeEnum, venueTypeNeedsAddress } from "@common/crud/organisation/model";
import { omit } from "lodash";
import { getBusinessLineTypePath, isHomePath } from "@common/global/CommonHelpers";
import { MuiDateField } from "@common/components/MuiDateField";
import { VenueBaseProps } from "./Base";
import { constructionOrganisationOptionsSelector, corporateOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { AuthState } from "@common/auth";
import { BusinessDriverEventAdmin, TtcCorporateEventAdmin } from "@common/auth/model";
import { MarkdownEditor } from "@common/crud/common/MarkdownEditor";
export interface CreateProps {
    open: boolean;
    isPoliceOrCourt: boolean;
    isCorporate: boolean;
    isConstruction: boolean;
    isBusinessDriverAdmin: boolean;
    isCorporateEventAdmin: boolean;
    organisationId?: string;
    organisationOptions: DropdownItemProps[];
    organisations: Organisation[];
    loading: boolean;
    quickAddId: string;
    dsaAreas: DsaAreaListItem[];
    businessLineType: BusinessLineType;
}

export interface DispatchProps extends SaveDispatchProps<VenueCreateEditModel> {
    close: () => void;
}

interface CreateFormState extends FormState<VenueCreateEditModel> {
    dorsSites: DorsSiteModel[];
    quickAddId: string;
}

export class CreateForm extends FormBaseComponent<VenueCreateEditModel, CreateProps, CreateFormState> {

    constructor(props: CreateProps & SaveDispatchProps<VenueCreateEditModel>) {
        super(props);
        this.state = this.initialState;
    }

    public initialValueState: VenueCreateEditModel ={
        id: "",
        name: "",
        venueType: this.props.isCorporate
            ? (this.props.organisationId
                ? VenueTypeEnum.CorporateOrganisationSpecific
                : VenueTypeEnum.CorporateShared)
            : this.props.isConstruction
                ? (this.props.organisationId
                    ? VenueTypeEnum.ConstructionOrganisationSpecific
                    : VenueTypeEnum.ConstructionShared)
                : VenueTypeEnum.PoliceAndCourt,
        address: {
            postalCode: "",
            city: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: ""
        },
        contactName: "",
        email: "",
        telephoneNumber: "",
        attributes: [],
        dorsId: null,
        organisationId: this.props.organisationId ?? undefined,
        noteEn: "",
        noteCy: "",
        venueFee: null,
        workflowTypes: [],
        dsaAreaId: null,
        maxCapacity: null,
        referredCourtCountry: null
    };

    public initialState: CreateFormState = {
        values: this.initialValueState,
        valid: {},
        dorsSites: [],
        quickAddId: ""
    };

    public async componentDidMount() {
        const venueApi = new VenueApi();
        const sites = await venueApi.getAllDorsSites();
        this.setState({
            ...this.state,
            dorsSites: sites,
        });
    }

    public componentDidUpdate() {
        if (this.props.quickAddId!== this.state.quickAddId) {
            this.setState({ ...this.state, values: this.initialValueState, quickAddId: this.props.quickAddId });

        }
    }

    private updateModelProp(property: keyof VenueCreateEditModel) {
        return (value: any, valid: boolean) => this.updateProperty(property, value, valid);
    }

    private updateVenueType() {
        return (value: any, valid: boolean) => {
            this.updateProperty("venueType", value, valid);

            if (!venueTypeNeedsAddress(+value)) {
                this.updateProperty("deliveryType", DeliveryTypeEnum.Onsite, true);
                this.updateProperty("address", {}, true);
                this.updateNestedProperty("address.addressLine1", undefined, true);
                this.updateNestedProperty("address.addressLine2", undefined, true);
                this.updateNestedProperty("address.addressLine3", undefined, true);
                this.updateNestedProperty("address.city", undefined, true);
                this.updateNestedProperty("address.postalCode", undefined, true);
            }
        };
    }

    private updateExpiryDate() {
        return (value: any, valid: boolean) => this.updateProperty("expiryDate", value && value.isValid() ? value : undefined, valid);
    }

    private updateOrganisationProp() {
        return (value: string, valid: boolean) => this.updateOrganisation(value, valid);
    }

    private updateDsaAreaProp() {
        return (value: number, valid: boolean) => this.updateDsaArea(value, valid);
    }

    private containsWorkflow(workflowValues: WorkflowTypeEnum[],  workflow: WorkflowTypeEnum) {
        return  workflowValues.some(w => w === workflow);
    }

    private containsDors() {
        return this.containsWorkflow(this.state.values.workflowTypes, WorkflowTypeEnum.Dors);
    }

    private containsDdrs() {
        return this.containsWorkflow(this.state.values.workflowTypes, WorkflowTypeEnum.DDRS);
    }

    private updateWorkflowProp() {

        return (value: any, valid: boolean) => {

            if (this.containsWorkflow(value, WorkflowTypeEnum.DDRS) && !this.containsWorkflow(value, WorkflowTypeEnum.Dors)) {
                this.updateOrganisation(null,valid);
                this.updateProperty("dorsId", null, valid);
            }

            if (this.containsWorkflow(value,WorkflowTypeEnum.Dors) && !this.containsWorkflow(value,WorkflowTypeEnum.DDRS)) {
                this.updateDsaArea(null, valid);
                this.updateProperty("referredCourtCountry", null, valid);
            }

            const validDeliveryType = !this.policeOrCourtDigitalWithMultipleWorkflows(this.state.values.deliveryType, value);

            this.updateProperty("deliveryType", this.state.values.deliveryType, valid && validDeliveryType);

            return this.updateProperty("workflowTypes", value, valid && validDeliveryType);};
    }

    private policeOrCourtDigitalWithMultipleWorkflows(deliveryType: DeliveryTypeEnum, workflowTypes: WorkflowTypeEnum[]) {
        return this.props.isPoliceOrCourt && (deliveryType === DeliveryTypeEnum.Digital) && (workflowTypes.length > 1);
    }

    public render() {
        const { values, showErrors, dorsSites } = this.state;
        const { organisations, isPoliceOrCourt, isCorporate, isConstruction, isBusinessDriverAdmin, isCorporateEventAdmin, organisationId,
            organisationOptions } = this.props;

        const dorsSiteOptions = getDorsSitesOptions(dorsSites);
        const dsaAreaOptions = getDsaAreasOptions(this.props.dsaAreas);
        const countryOptions = optionsFromObject(omit(Country,0));
        const venueTypeOptions = isCorporate
            ? optionsFromObject(CorporateVenueType)
            : isConstruction
                ? optionsFromObject(ConstructionVenueType)
                : optionsFromObject(PoliceAndCourtVenueType);
        const workflowTypeOptions = WorkflowTypeOptions(
            isCorporate,
            isBusinessDriverAdmin,
            isCorporateEventAdmin,
            isConstruction,
            false
        );
        const deliveryTypeOptions = values.venueType === VenueTypeEnum.DelegateHome
            ? optionsFromObject(omit(DeliveryType,[DeliveryTypeEnum.Digital]))
            : optionsFromObject(DeliveryType);
        const classroom = this.state.values.deliveryType === DeliveryTypeEnum.Onsite;

        return (
            <Form onSubmit={this.handleSubmit}>

                <Input.Text
                    value={values.name}
                    label="Name"
                    showErrors={this.state.showErrors}
                    required
                    onChange={this.updateModelProp("name")}
                />
                {!isPoliceOrCourt && (
                    <>
                        <Input.DropdownNumber
                            value={values.venueType}
                            label="Venue Type"
                            showErrors={showErrors}
                            validation={[validators.requiredNumber()]}
                            options={venueTypeOptions}
                            onChange={this.updateVenueType()}
                            disabled={!!organisationId}
                        />
                        {(values.venueType === VenueTypeEnum.CorporateOrganisationSpecific
                            || values.venueType === VenueTypeEnum.ConstructionOrganisationSpecific) && (
                            <Input.Dropdown
                                value={values.organisationId}
                                label="Organisation"
                                showErrors={showErrors}
                                required
                                options={organisationOptions}
                                onChange={this.updateModelProp("organisationId")}
                                dynamicOptions
                                search
                                disabled={!!organisationId}
                            />
                        )}
                    </>
                )}
                <Input.Text
                    value={values.contactName}
                    label="Contact Name"
                    showErrors={showErrors}
                    onChange={this.updateModelProp("contactName")}
                />
                <Input.Email
                    value={values.email}
                    label="Email"
                    showErrors={showErrors}
                    onChange={this.updateModelProp("email")}
                />
                <Input.Text
                    value={values.telephoneNumber}
                    label="Telephone Number"
                    showErrors={showErrors}
                    validation={phoneNumberWithSpaces()}
                    onChange={this.updateModelProp("telephoneNumber")}
                />
                {venueTypeNeedsAddress(values.venueType) && (
                    <AddressLookup
                        address={values.address}
                        showErrors={showErrors}
                        onChange={this.updateModelProp("address")}
                        autofill
                    />
                )}
                <Input.DropdownMulti
                    value={values.attributes}
                    label="Attributes"
                    options={optionsFromObject(VenueAttributes)}
                    showErrors={showErrors}
                    onChange={this.updateModelProp("attributes")}
                />
                <Input.DropdownNumber
                    value={values.deliveryType}
                    label="Delivery type"
                    showErrors={showErrors}
                    validation={[validators.requiredNumber()]}
                    options={deliveryTypeOptions}
                    onChange={this.updateDeliveryType}
                    dynamicOptions
                    required
                />
                <Input.DropdownMulti
                    value={values.workflowTypes}
                    label="Workflows"
                    options={workflowTypeOptions}
                    dynamicOptions
                    required
                    showErrors={showErrors}
                    validation={[workflowTypesValidator()]}
                    onChange={this.updateWorkflowProp()}
                />
                {this.containsDors() &&
                    <>
                        <Input.Dropdown
                            label="Police Force"
                            placeholder="Select Police Force"
                            showErrors={showErrors}
                            value={values.organisationId}
                            options={organisations.map(c => ({ key: c.id, text: c.name, value: c.id }))}
                            onChange={this.updateOrganisationProp()}
                            required
                            dynamicOptions
                            search
                        />

                        <Input.DropdownNumber
                            label="Dors Id"
                            placeholder="Select Dors Site"
                            showErrors={showErrors}
                            value={values.dorsId}
                            options={dorsSiteOptions}
                            onChange={this.updateModelProp("dorsId")}
                            required
                            dynamicOptions
                            search
                        />
                    </>
                }
                {this.containsDdrs() &&
                <>
                    <Input.DropdownNumber
                        label="DSA Area"
                        placeholder="Select DSA Area"
                        showErrors={showErrors}
                        value={values.dsaAreaId}
                        options={dsaAreaOptions}
                        onChange={this.updateDsaAreaProp()}
                        required
                        dynamicOptions
                        search
                    />
                    <Input.DropdownNumber
                        label="Country"
                        showErrors={showErrors}
                        options={countryOptions}
                        value={values.referredCourtCountry}
                        disabled
                        required
                    />
                </>
                }
                <MuiDateField
                    value={values.expiryDate}
                    label="Expiry Date"
                    showErrors={showErrors}
                    validation={[muiFutureDateValidator]}
                    onChange={this.updateExpiryDate()}
                />
                <CurrencyInput
                    label="Venue Fee (net)"
                    value={values.venueFee}
                    showErrors={showErrors}
                    onChange={this.updateModelProp("venueFee")}
                />
                <Input.Textarea
                    value={values.feeNote}
                    label="Venue Fee Note"
                    validation={[validators.validLength({ max: 1000 })]}
                    showErrors={showErrors}
                    onChange={this.updateModelProp("feeNote")}
                />
                {isPoliceOrCourt?
                    <Input.Textarea
                        value={values.noteEn}
                        label={"Booking App Note (English)"}
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={this.updateModelProp("noteEn")}
                    />:
                    <MarkdownEditor
                        value={values.noteEn}
                        label="Booking Note"
                        showErrors={showErrors}
                        onChange={this.updateModelProp("noteEn")}
                    />
                }
                {isPoliceOrCourt && (
                    <Input.Textarea
                        value={values.noteCy}
                        label="Booking App Note (Welsh)"
                        validation={[validators.validLength({ max: 1000 })]}
                        showErrors={showErrors}
                        onChange={this.updateModelProp("noteCy")}
                    />
                )}
                {classroom && (
                    <Input.Number
                        value={values.maxCapacity}
                        label="Max Capacity"
                        showErrors={showErrors}
                        onChange={this.updateModelProp("maxCapacity")}
                    />
                )}
            </Form>
        );
    }

    public submit = () => {

        const { deliveryType, workflowTypes } =  this.state.values;

        if (!Array.isArray(workflowTypes) || workflowTypes.length === 0) {
            toast.warning("A venue shold have at least one workflow type.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (!deliveryType || +deliveryType === DeliveryTypeEnum.None) {
            toast.warning("A venue shold have delivery type.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (this.policeOrCourtDigitalWithMultipleWorkflows(deliveryType, workflowTypes)) {
            toast.warning("A digital venue should not have multiple workflow types.");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        if (Object.keys(this.state.valid).some(k => !this.state.valid[k])) {
            toast.error("Please correct any invalid fields");
            this.setState(prevState => ({ ...prevState, showErrors: true }));
            return;
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private updateOrganisation = (value: string, valid: boolean) => {
        const organisation = this.props.organisations.find(v => v.id === value);

        if (organisation) {
            this.updateProperty("organisationId", value, valid);
            this.updateProperty("forceId", organisation.dorsId, valid);
        }
        else {
            this.updateProperty("organisationId", null, !this.containsDors());
            this.updateProperty("forceId", null,  !this.containsDors());
        }
    };

    private updateDsaArea = (value: number, valid: boolean) => {
        const dsaArea = this.props.dsaAreas.find(v => v.id === value);
        const country = dsaArea?.country;

        if (dsaArea) {
            this.updateProperty("dsaAreaId", value, valid);
            this.updateProperty("referredCourtCountry", country, valid);
        }
        else {
            this.updateProperty("dsaAreaId", null, !this.containsDdrs());
            this.updateProperty("referredCourtCountry", null, false);
        }
    };

    private updateDeliveryType = (value: number, valid: boolean) => {
        const validWorkflows = !this.policeOrCourtDigitalWithMultipleWorkflows(value, this.state.values.workflowTypes);
        this.updateProperty("deliveryType", value, valid && validWorkflows);
        this.updateProperty("workflowTypes", this.state.values.workflowTypes, valid && validWorkflows);
    };
}

function mapStateToProps(state: AppState & OrganisationState & LoadingState & AuthState, ownProps: VenueBaseProps) {
    const pathname = state.router.pathname;
    const quickAdd = pathname.includes("quickAdd");
    const createForm =pathname.endsWith("/create");
    const open = quickAdd || createForm;
    const isPoliceOrCourt = ownProps.isPoliceOrCourt;
    const isCorporate = ownProps.isCorporate;
    const isConstruction = ownProps.isConstruction;
    const isBusinessDriverAdmin = state.currentUser?.roles?.includes(BusinessDriverEventAdmin) || false;
    const isCorporateEventAdmin = state.currentUser?.roles?.includes(TtcCorporateEventAdmin) || false;
    const organisationId = ownProps.organisationId;
    const businessLineType = businessLineTypeSelector(state);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);
    const basePath = quickAdd?
        createBasePathSelector(businessLineTypePath)(state):basePathSelector(state);
    const organisationOptions = isCorporate
        ? corporateOrganisationOptionsSelector(state)
        : isConstruction
            ? constructionOrganisationOptionsSelector(state)
            : [];

    const quickAddId = quickAdd?  pathname.substring(pathname.indexOf("quickAdd")):"";

    return {
        open,
        basePath,
        quickAddId,
        organisations: organisationSelectors.unexpiredPoliceDorsOrganisationsSelector(state),
        loading: state.loading.active,
        dsaAreas: organisationSelectors.dsaAreasSelector(state),
        businessLineType,
        isPoliceOrCourt,
        isCorporate,
        isConstruction,
        isBusinessDriverAdmin,
        isCorporateEventAdmin,
        organisationId,
        organisationOptions
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (venue: VenueCreateEditModel, basePath: string, businessLineType: BusinessLineType) => {
            const quickAdd = isHomePath(basePath);
            return dispatch(createVenue(venue, basePath, businessLineType, quickAdd));},
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): CreateProps & DispatchProps {
    const { open, organisations, loading, basePath, quickAddId, dsaAreas, businessLineType, isPoliceOrCourt, isCorporate, isConstruction, organisationOptions,
        organisationId, isBusinessDriverAdmin, isCorporateEventAdmin } = propsFromState;
    const { dispatchClose, dispatchSave } = propsFromDispatch;
    return {
        open,
        organisations,
        loading,
        quickAddId,
        save: (venue: VenueCreateEditModel) => dispatchSave(venue, basePath, businessLineType),
        close: () => dispatchClose(basePath),
        dsaAreas,
        businessLineType,
        isPoliceOrCourt,
        isCorporate,
        isConstruction,
        isBusinessDriverAdmin,
        isCorporateEventAdmin,
        organisationId,
        organisationOptions
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
