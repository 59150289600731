import axios from "axios";
import { RebookSignInProgress } from "./model";
import moment from "moment";

export class RebookSignInApi {
    private readonly apiUrl = "/api/auth";

    public async signInGenerateOtp(email: string, attendanceId: string, postcode: string) {
        const response = await axios.post<RebookSignInProgress>(`${this.apiUrl}/signingenerateotp`,
            { email, attendanceId: parseInt(attendanceId, 10), postcode });
        return { ...response.data, accountLockedUntil: response.data.accountLockedUntil? moment(response.data.accountLockedUntil) : null };
    }

    public async signInValidateOtp(email: string, code: string, attendanceId: string, postcode: string) {
        const response = await axios.post<RebookSignInProgress>(`${this.apiUrl}/signinvalidateotp`, { email, otp: code, attendanceId, postcode, });
        return { ...response.data, accountLockedUntil: response.data.accountLockedUntil? moment(response.data.accountLockedUntil) : null };
    }
}
