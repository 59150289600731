import * as React from "react";
import { Address } from "@common/crud/common/Address";

export const AddressDisplay = (address: Address) =>
    address &&
    <div className="text-wrap">
        {address.addressLine1}<br />
        {address.addressLine2 && <>{address.addressLine2}<br /></>}
        {address.addressLine3 && <>{address.addressLine3}<br /></>}
        {address.city}<br />
        {address.postalCode}
    </div>;
