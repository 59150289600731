import axios from "axios";
import moment from "moment";
import { LookupResponse } from "../common/LookupResponse";
import { AssistanceRequired } from "@common/crud/dorsBooking/model";
import { EditContactDetailsModel, SendMessageOver } from "@common/crud/attendee/model";
import { ResultResponse } from "@common/model";
import {
    AttachedDocumentListModel,
    DrinkDriveInitialBookingDateDetailResponseModel,
    DrinkDriveOffender,
    DrinkDriveOffenderCreateModel,
    DrinkDriveOffenderDetailModel,
    DrinkDriveOffenderDetails,
    DrinkDriveOffenderEditModel,
    DrinkDriveOffenderListModel,
    DrinkDriveOffenderUnlockAccountModel,
} from "@common/crud/drinkDriveOffenders/model";
import { SearchOptions } from "./model";
import * as QueryString from "query-string";

export class DrinkDriveOffenderApi {

    private readonly apiUrl = "/api/drinkDriveOffender";

    public async getAll(options: SearchOptions): Promise<DrinkDriveOffenderListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as DrinkDriveOffenderListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<DrinkDriveOffenderDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as DrinkDriveOffenderDetailModel;
        return this.parseModel(model);
    }

    public async save(drinkDriveOffender: DrinkDriveOffenderEditModel): Promise<DrinkDriveOffenderDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${drinkDriveOffender.id}`, drinkDriveOffender);
        const model = response.data as DrinkDriveOffenderDetailModel;
        return this.parseModel(model);
    }

    public async unlockAccount(unlockAccountModel: DrinkDriveOffenderUnlockAccountModel): Promise<DrinkDriveOffenderDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${unlockAccountModel.id}/unlock`);
        const model = response.data;
        return this.parseModel(model);
    }

    public async create(drinkDriveOffender: DrinkDriveOffenderCreateModel): Promise<DrinkDriveOffenderDetailModel> {

        const response = await axios.post(this.apiUrl, drinkDriveOffender);
        const model = response.data as DrinkDriveOffenderDetailModel;
        return this.parseModel(model);
    }

    public async setAssistanceRequired(correlationId: string, model: AssistanceRequired): Promise<DrinkDriveOffender> {
        const url = `${this.apiUrl}/${correlationId}/setassistancerequired`;
        const response = await axios.put(url, model);
        const data = response.data as DrinkDriveOffender;
        return this.parseModel(data);
    }

    public async setEligibleForConcessions(correlationId: string, eligible: boolean): Promise<DrinkDriveOffenderDetailModel> {
        const url = `${this.apiUrl}/${correlationId}/set-eligible-for-concessions?eligible=${eligible}`;
        const response = await axios.put(url);
        const data = response.data as DrinkDriveOffender;
        return this.parseModel(data);
    }

    public updateContactDetails(bookingId: string, contactDetailsModel: EditContactDetailsModel) {
        return axios.post(`${this.apiUrl}/${bookingId}/updatecontact`, contactDetailsModel);
    }

    public async sendAdHocEmail(bookingId: string, templateName: string, templateId: number): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendadhocmail`, { templateName, templateId });
        return result.data as ResultResponse;
    }

    public async sendAdHocSms(bookingId: string, templateName: string, template: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendadhocsms`, { templateName, template });
        return result.data as ResultResponse;
    }

    public async sendSms(bookingId: string, message: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendsms?message=${message}`);
        return result.data as ResultResponse;
    }

    public async sendComms(bookingId: string, sendMessageOver: SendMessageOver, messageType: number) {
        await axios.post(`${this.apiUrl}/${bookingId}/send-comms`, { sendMessageOver, messageType });
    }

    public async transferProvider(bookingId: string, providerName: string) {
        const response = await axios.post<DrinkDriveOffenderDetailModel>(
            `${this.apiUrl}/${bookingId}/transfer-provider`, { providerName });

        return this.parseModel(response.data);
    }

    public async getAttachedDocumentsCount(bookingId: string) {
        const response = await axios.get<number>(
            `${this.apiUrl}/${bookingId}/attached-documents-count`);

        return response.data;
    }

    public async getAttachedDocuments(bookingId: string) {
        const response = await axios.get<AttachedDocumentListModel[]>(
            `${this.apiUrl}/${bookingId}/attached-documents`);

        return response.data;
    }

    public async getAllDdrsOffenderDocuments() {
        const response = await axios.get<AttachedDocumentListModel[]>(`${this.apiUrl}/referral-documents`);
        return response.data;
    }

    public async uploadAttachedDocument(bookingId: string, description: string, file: File) {
        const formData = new FormData();
        formData.append("description", description);
        formData.append("file", file);

        const response = await axios.post<AttachedDocumentListModel>(
            `${this.apiUrl}/${bookingId}/attach-document`, formData,
            {
                headers: { "Content-Type": "multipart/form-data" }
            });

        return response.data;
    }

    public async deleteAttachedDocument(bookingId: string, documentId: string) {
        await axios.delete(`${this.apiUrl}/${bookingId}/attached-document/${documentId}`);
    }

    public async getDrinkDriveInitialBookingDateDetail(bookingId: string) {
        const response = await axios.get<DrinkDriveInitialBookingDateDetailResponseModel>(`${this.apiUrl}/drinkDriveInitialBookingDateDetail/${bookingId}`);
        const model = response.data as DrinkDriveInitialBookingDateDetailResponseModel;
        return this.parseDrinkDriveBookingInitialBookingDateDetailModel(model);
    }

    public async setDrinkDriveInitialBookingDate(id: string, drinkDriveInitialBookingDate: moment.Moment) {
        const response = await axios.put<DrinkDriveInitialBookingDateDetailResponseModel>(`${this.apiUrl}/setDrinkDriveInitialBookingDate`,
            { id, drinkDriveInitialBookingDate });
        const model = response.data as DrinkDriveInitialBookingDateDetailResponseModel;
        return this.parseDrinkDriveBookingInitialBookingDateDetailModel(model);
    }

    public parseDrinkDriveBookingInitialBookingDateDetailModel(
        model: DrinkDriveInitialBookingDateDetailResponseModel): DrinkDriveInitialBookingDateDetailResponseModel {
        return {
            ...model,
            model: {
                ...model.model,
                drinkDriveInitialBookingDate: model.model.drinkDriveInitialBookingDate && moment(model.model.drinkDriveInitialBookingDate)
            }
        };
    }

    public parseModel(model: DrinkDriveOffenderDetailModel): DrinkDriveOffenderDetailModel {

        const drinkDriveOffenderDetails: DrinkDriveOffenderDetails = model.drinkDriveOffenderDetails && {
            ...model.drinkDriveOffenderDetails,
            dateOfBirth: model.drinkDriveOffenderDetails.dateOfBirth && moment(model.drinkDriveOffenderDetails.dateOfBirth),
            sentenceDate: model.drinkDriveOffenderDetails.sentenceDate && moment(model.drinkDriveOffenderDetails.sentenceDate),
            interimDate: model.drinkDriveOffenderDetails.interimDate && moment(model.drinkDriveOffenderDetails.interimDate),
            transferDate: model.drinkDriveOffenderDetails.transferDate && moment(model.drinkDriveOffenderDetails.transferDate),
        };
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
            drinkDriveOffenderDetails,
            historyRecords: model?.historyRecords?.map(historyRecord => {
                return {
                    ...historyRecord,
                    dateCreated: historyRecord.dateCreated && moment(historyRecord.dateCreated),
                    pendingDate: historyRecord.pendingDate && moment(historyRecord.pendingDate),
                    completionDate: historyRecord.completionDate && moment(historyRecord.completionDate),
                };
            }),
        };
    }

    public parseDorsLookupModel(model: LookupResponse): LookupResponse {
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate)
        };
    }
}
