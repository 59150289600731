import * as React from "react";
import { Checkbox, Grid, List, TextArea } from "semantic-ui-react";
import {
    CriteriaEnum,
    QuestionCriterium
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { Criterium } from "@common/crud/trainer/trainerMonitoringModel";
import { validators } from "not-valid";
import "./CriteriaLabelAndValue.css";

interface CriteriaLabelAndValueProps {
    questionCriterium: QuestionCriterium;
    criterium: Criterium;
    updateValue: (value: Criterium) => void;
    lockedForChange: boolean;
}

export const CriteriaLabelAndValue = (props: CriteriaLabelAndValueProps) => {
    const { updateValue, questionCriterium, criterium, lockedForChange } = props;
    const renderTo = questionCriterium.typeEnum ?? CriteriaEnum.check;
    const updateCheckBox = React.useCallback((_, data) => {
        criterium.unsatisfactory = data.checked;
        updateValue(criterium);
    }, [criterium, updateValue]);

    const updateSummarySubSectionCheckBox = React.useCallback((_, data, id, idsToClear) => {
        criterium.summarySubSection.find(s => s.id === id).unsatisfactory = data.checked;
        if (data.checked && (idsToClear?.length > 0 ?? false)) {
            idsToClear.forEach((idToClear: string) => {
                criterium.summarySubSection.find(s => s.id === idToClear).unsatisfactory = false;
                criterium.summarySubSection.find(s => s.id === idToClear).response = "";
            });
        }
        updateValue(criterium);
    }, [criterium, updateValue]);

    const updateTextArea = React.useCallback((_, data) => {
        criterium.response = data.value;
        updateValue(criterium);
    }, [criterium, updateValue]);

    const itemKey = criterium?.sectionId + "-" + criterium?.subSectionId + "-" + criterium?.id;
    return  (
        <List.Item key={itemKey}>
            {renderTo === CriteriaEnum.check &&
                <Grid.Column width={16}>
                    <Checkbox
                        disabled={lockedForChange}
                        checked={criterium?.unsatisfactory}
                        type="checkbox"
                        label={questionCriterium.text}
                        onClick={updateCheckBox} />
                </Grid.Column> }
            {renderTo === CriteriaEnum.text &&
                <>
                    <Grid.Column width={16}>
                        <span className="label">{questionCriterium.text}</span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <TextArea
                            disabled={lockedForChange}
                            value={criterium?.response}
                            rows={6}
                            onChange={updateTextArea}
                            className="textarea-large"
                            validation={[validators.validLength({ max: 1000 }), validators.requiredString]} />
                    </Grid.Column>
                </>
            }
            {
                renderTo === CriteriaEnum.none &&
                questionCriterium.summarySubSection.length > 0 &&
                    <>
                        <Grid.Column width={16}>
                            <span className="label">{questionCriterium.text}</span>
                        </Grid.Column>
                        {
                            questionCriterium.summarySubSection.map((sub) => (
                                sub.typeEnum === CriteriaEnum.check ?
                                    <Grid.Row key={sub.id} className="question-criterium-summary-sub-section-row">
                                        <Grid.Column width={16}>
                                            <Checkbox
                                                disabled={lockedForChange}
                                                checked={criterium?.summarySubSection?.find(s => s.id === sub.id).unsatisfactory}
                                                type="checkbox"
                                                label={sub.text}
                                                onClick={(_,data) => updateSummarySubSectionCheckBox(_, data, sub.id, sub.idsToClear)} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    : <Grid.Row key={sub.id}>
                                        <Grid.Column width={16}>
                                            <span className="label">{sub.text}</span>
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <TextArea
                                                disabled={lockedForChange}
                                                value={criterium?.response}
                                                rows={6}
                                                onChange={updateTextArea}
                                                className="textarea-large"
                                                validation={[validators.validLength({ max: 1000 }), validators.requiredString]} />
                                        </Grid.Column>
                                    </Grid.Row>
                            ))
                        }
                    </>
            }
        </List.Item>
    );
};
