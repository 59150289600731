import React, { useCallback, useMemo } from "react";
import { Category, SubCategory } from "@common/appSettings/model";
import { Button, ButtonProps, Dropdown, Grid, Label, Modal, Table } from "semantic-ui-react";

interface EditCourseCatagoriesProps {
    label: string;
    categories: Category[];
    values: number[];
    onChange: (values: number[], valid: boolean) => void;
}

interface ItemData {
    category: Category;
    subCategory: SubCategory | undefined;
}

export const EditCourseCategories = ({ label, categories, values, onChange }: EditCourseCatagoriesProps) => {

    const [open, setOpen] = React.useState(false);
    const onClose = useCallback(() => setOpen(false), []);
    const [addCategoryId, setAddCategoryId] = React.useState<number | undefined>(undefined);
    const [addSubCategoryId, setAddSubCategoryId] = React.useState<number>(0);

    const subCategoryOptions = useMemo(() => {
        return [
            { value: -1, text: "-- None --" },
            ...categories.find(c => c.id === addCategoryId)?.subCategories.map(sc => ({  text: sc.title, value: sc.id })) ?? []
        ];
    }, [categories, addCategoryId]);

    const updateAddCategory = useCallback((event: React.SyntheticEvent<HTMLElement>, data: { value: number }) => {
        setAddCategoryId(data.value);
        setAddSubCategoryId(-1);
    }, []);

    const updateSubCategory = useCallback((event: React.SyntheticEvent<HTMLElement>, data: { value: number }) => {
        setAddSubCategoryId(data.value);
    }, []);

    const getCategoryInfo = useCallback((v: number): ItemData => {
        const category = categories.find((c) => c.id === v || c.subCategories.some((sc) => sc.id === v));
        const subCategory = category?.subCategories.find((sc) => sc.id === v);
        return { category , subCategory };
    }, [categories]);

    const items = useMemo<ItemData[]>(() => {
        const info = values?.map(getCategoryInfo) ?? [];
        return info.filter(a =>
            a.category !== undefined &&
            (a.subCategory !== undefined ||
            info.filter(b => a.category === b.category && b.subCategory !== undefined).length === 0))
            .sort((a, b) => a.category.title !== b.category.title
                ? a.category.title.localeCompare(b.category.title)
                : a.subCategory.title.localeCompare(b.subCategory.title));
    }, [getCategoryInfo, values]);

    const deleteRow = useCallback((event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        event.preventDefault();
        const id = data.id as number;
        const item = getCategoryInfo(id);

        if (item.subCategory === undefined) {
            onChange(values.filter(v => v !== item.category.id), true);
        } else {
            const newValues = values.filter(v => v !== item.subCategory.id);
            const onlyTopLevelLeft = newValues.filter(v => v === item.category.id).length === 1;
            onChange(onlyTopLevelLeft ? newValues.filter(v => v !== item.category.id) : newValues, true);
        }
    }, [getCategoryInfo, onChange, values]);

    const addItem = useCallback((event: React.MouseEvent) => {
        setOpen(true);
        event.preventDefault();
    }, []);

    const getRow = (item: ItemData) => {
        return (<Table.Row>
            <Table.Cell width={7}>
                {item.category.title}
            </Table.Cell>
            <Table.Cell width={7}>
                {item.subCategory?.title}
            </Table.Cell>
            <Table.Cell width={2} textAlign="right">
                <Button size="mini" icon="trash" negative={true} id={item.subCategory?.id ?? item.category.id} onClick={deleteRow} />
            </Table.Cell>
        </Table.Row>);
    };

    const addCourseCategory = useCallback(() => {
        let newValues = [...values];
        if (!newValues.includes(addCategoryId)) {
            newValues = [...newValues, addCategoryId];
        }
        if (addSubCategoryId > 0 && !newValues.includes(addSubCategoryId)) {
            newValues = [...newValues, addSubCategoryId];
        }
        onChange(newValues, true);
        onClose();
    }, [addCategoryId, addSubCategoryId, onChange, onClose, values]);

    return (
        <>
            <Modal open={open} onClose={onClose} size="small">
                <Modal.Header>Add Course Category</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Label>Category</Label>
                            <Dropdown
                                placeholder="Select Category"
                                fluid
                                selection
                                options={categories.map(c => ({ key: c.id, text: c.title, value: c.id }))}
                                onChange={updateAddCategory}
                                value={addCategoryId} />
                        </Grid.Row>
                        {addCategoryId &&
                            <Grid.Row>
                                <Label>Sub Category</Label>
                                <Dropdown
                                    fluid
                                    selection
                                    options={subCategoryOptions}
                                    onChange={updateSubCategory}
                                    value={addSubCategoryId} />
                            </Grid.Row>}
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={onClose} />
                    <Button content="Add" primary={true} onClick={addCourseCategory} />
                </Modal.Actions>
            </Modal>
            <div className="field">
                <label>{label}</label>
                <Table compact>
                    {items.map(getRow)}
                    <Table.Row>
                        <Table.Cell colSpan={3} textAlign="right">
                            <Button content="Add" icon="plus" primary={true} onClick={addItem} />
                        </Table.Cell>
                    </Table.Row>
                </Table>
            </div>
        </>
    ) ;
};
