import { SeatAction } from "./actions";
import { CREATE_RESERVATION_SUCCESS } from "./actiontypes";

export function seats(state = {} as any, action: SeatAction) {
    switch (action.type) {
        case CREATE_RESERVATION_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
