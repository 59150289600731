import * as React from "react";
import { CarType, ExtendedEventInstance, OtherTrainerTypeCategory } from "@common/crud/eventInstance/model";
import { DisplayTrainerCarType } from "@common/crud/trainer/components/DisplayTrainerCarType";

const TrainerRoleType = {
    Theory: "Theory",
    Practical: "Practical",
    TheoryAndPractical: "Theory and practical",
    Monitor: "Monitor",
    Observer: "Observer"
};

interface TrainerRoleProps {
    eventInstance:  ExtendedEventInstance;
    trainerId: string;
}

export const TrainerRoleColumn: React.FC<TrainerRoleProps> = ({ eventInstance, trainerId })  => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const displayTrainerRole = React.useCallback(() => GetTrainerRoleType(),[eventInstance]);

    function RoleWithCarType(carType: CarType, role: string) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center" }}>
                <div style={{ paddingBottom: "2px" }}>{role}</div>
                <div>{((carType && carType !== "Any") && <DisplayTrainerCarType height={40} carType={carType} />)}</div>
            </div>
        );
    }

    function GetTrainerRoleType() {
        if (!!eventInstance
            && !!eventInstance.trainerIds?.some(id => id === trainerId)
            && !eventInstance.practicalTrainersIds?.some(id => id === trainerId)) {
            return TrainerRoleType.Theory;
        }

        if (!!eventInstance
            && !!eventInstance.practicalTrainersIds?.some(id => id === trainerId)
            && !eventInstance.trainerIds?.some(id => id === trainerId)) {

            const carType = eventInstance.practicalTrainers.find(trainer => trainer.id === trainerId)?.carType;
            return RoleWithCarType(carType, TrainerRoleType.Practical);
        }

        if (!!eventInstance
            && !!eventInstance.practicalTrainersIds?.some(id => id === trainerId)
            && !!eventInstance.trainerIds?.some(id => id === trainerId)) {

            const carType = eventInstance.practicalTrainers.find(trainer => trainer.id === trainerId)?.carType;
            return RoleWithCarType(carType, TrainerRoleType.TheoryAndPractical);
        }

        if (!!eventInstance && !!eventInstance.monitorTrainersIds?.some(id => id === trainerId)) {
            return TrainerRoleType.Monitor;
        }

        if (!!eventInstance && !!eventInstance.observerTrainersIds?.some(id => id === trainerId)) {
            return OtherTrainerTypeCategory[eventInstance.observerTrainers?.find(ot => ot.id === trainerId)?.otherTrainerTypeCategory]
                ?? TrainerRoleType.Observer;
        }

        return "None";
    }

    return <>{displayTrainerRole()}</>;
};
