import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { sendConfirmation } from "../actions";

export interface SendBookerConfirmationModalProps {
    orderId: string;
    eventInstanceId?: string;
    canSend: boolean;
}

export const SendBookerConfirmationModal: React.FC<SendBookerConfirmationModalProps> = ({ orderId, eventInstanceId, canSend }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);

    const dispatch = useDispatch();

    const openCloseAction = React.useCallback(() => {
        setSubmitted(false);
        setOpen(!open);
    }, [open]);

    const onContinue = React.useCallback(() => {
        setSubmitted(true);
        dispatch(sendConfirmation({
            orderId,
            eventInstanceId,
        }));
        setOpen(false);
    }, [dispatch, eventInstanceId, orderId]);

    return (
        <>
            <Button
                disabled={!canSend}
                onClick={openCloseAction}
            >
                {eventInstanceId ? "SEND COURSE CONFIRMATION EMAIL" : "SEND ORDER CONFIRMATION EMAIL"}
            </Button>
            <Modal open={open}>
                <Modal.Header>
                    {eventInstanceId ? "Send Course Confirmation" : "Send Order Confirmation"}
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        loading={submitted}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
