import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import { Checkbox, Table } from "semantic-ui-react";
import * as React from "react";
import { Moment } from "moment";
import { OptSchemeTrainerAttributeModel } from "@common/crud/trainer/model";
import { Apps } from "@common/model";
import { MuiDateField } from "@common/components/MuiDateField";

interface Props {
    trainerAttribute: TrainerAttribute;
    setOptTrainerAttributes: React.Dispatch<React.SetStateAction<OptSchemeTrainerAttributeModel[]>>;
    optTrainerAttributes: OptSchemeTrainerAttributeModel[];
    app: Apps;
}

export const OptTrainerRow: React.FC<Props> = ({ trainerAttribute, setOptTrainerAttributes, optTrainerAttributes, app }) => {
    const [optToggle, setOptToggle] = React.useState<boolean>(trainerAttribute?.isOptedOut === null ? true : trainerAttribute?.isOptedOut !== true);
    const [lastMonitoredDate, setLastMonitoredDate] = React.useState<Moment>(
        (trainerAttribute.isDigital ? trainerAttribute.lastDigitalMonitoredDate : trainerAttribute.lastMonitoredDate ));
    const [nextMonitoringDue, setNextMonitoringDue] = React.useState<Moment>(
        (trainerAttribute.isDigital ? trainerAttribute.nextDigitalMonitoringDue : trainerAttribute.nextMonitoringDue ));
    const [nextMonitoringSession, setNextMonitoringSession] = React.useState<Moment>(
        (trainerAttribute.isDigital ? trainerAttribute.nextDigitalMonitoringSessionPlanned : trainerAttribute.nextMonitoringSessionPlanned ));

    const handleOptToggle = () => {
        const newOptToggle = !optToggle;
        setOptToggle(newOptToggle);
        if (optTrainerAttributes.some(attribute => attribute.attributeDefinitionId === trainerAttribute.attributeDefinitionId)) {
            setOptTrainerAttributes(prevState => prevState.map(state =>
                state.attributeDefinitionId === trainerAttribute.attributeDefinitionId ?
                    { ...state, isOptedOut: newOptToggle } : state));
        }
        else {
            const newOptSchemeTrainerAttributeObject: OptSchemeTrainerAttributeModel = {
                attributeDefinitionId: trainerAttribute.attributeDefinitionId,
                isOptedOut: newOptToggle,
                lastMonitored: lastMonitoredDate,
                nextMonitoringDue,
                nextMonitoringSession
            };
            setOptTrainerAttributes(prevState => [...prevState, newOptSchemeTrainerAttributeObject]);
        }
    };

    const handleLastMonitoredDate = (newValue: Moment) => {
        setLastMonitoredDate(newValue);
        if (optTrainerAttributes.some(attribute => attribute.attributeDefinitionId === trainerAttribute.attributeDefinitionId)) {
            setOptTrainerAttributes(prevState => prevState.map(state =>
                state.attributeDefinitionId === trainerAttribute.attributeDefinitionId ?
                    { ...state, lastMonitored: newValue } : state));
        }
        else {
            const newOptSchemeTrainerAttributeObject: OptSchemeTrainerAttributeModel = {
                attributeDefinitionId: trainerAttribute.attributeDefinitionId,
                isOptedOut: optToggle,
                lastMonitored: newValue,
                nextMonitoringDue,
                nextMonitoringSession
            };
            setOptTrainerAttributes(prevState => [...prevState, newOptSchemeTrainerAttributeObject]);
        }
    };

    const handleNextMonitoringDue = (newValue: Moment) => {
        setNextMonitoringDue(newValue);
        if (optTrainerAttributes.some(attribute => attribute.attributeDefinitionId === trainerAttribute.attributeDefinitionId)) {
            setOptTrainerAttributes(prevState => prevState.map(state =>
                state.attributeDefinitionId === trainerAttribute.attributeDefinitionId ?
                    { ...state, nextMonitoringDue: newValue } : state));
        }
        else {
            const newOptSchemeTrainerAttributeObject: OptSchemeTrainerAttributeModel = {
                attributeDefinitionId: trainerAttribute.attributeDefinitionId,
                isOptedOut: optToggle,
                lastMonitored: lastMonitoredDate,
                nextMonitoringDue: newValue,
                nextMonitoringSession
            };
            setOptTrainerAttributes(prevState => [...prevState, newOptSchemeTrainerAttributeObject]);
        }
    };

    const handleNextMonitoringSession = (newValue: Moment) => {
        setNextMonitoringSession(newValue);
        if (optTrainerAttributes.some(attribute => attribute.attributeDefinitionId === trainerAttribute.attributeDefinitionId)) {
            setOptTrainerAttributes(prevState => prevState.map(state =>
                state.attributeDefinitionId === trainerAttribute.attributeDefinitionId ?
                    { ...state, nextMonitoringSession: newValue } : state));
        }
        else {
            const newOptSchemeTrainerAttributeObject: OptSchemeTrainerAttributeModel = {
                attributeDefinitionId: trainerAttribute.attributeDefinitionId,
                isOptedOut: optToggle,
                lastMonitored: lastMonitoredDate,
                nextMonitoringDue,
                nextMonitoringSession: newValue
            };
            setOptTrainerAttributes(prevState => [...prevState, newOptSchemeTrainerAttributeObject]);
        }
    };

    return (
        <Table.Row>
            <Table.Cell>
                {trainerAttribute.name}
            </Table.Cell>
            <Table.Cell>
                <Checkbox
                    label={optToggle ? "You are opted in to this scheme" : "You are opted out of this scheme"}
                    checked={optToggle}
                    onChange={handleOptToggle}
                    toggle
                />
            </Table.Cell>
            {
                app === Apps.Admin &&
                <>
                    <Table.Cell>
                        <MuiDateField
                            value={lastMonitoredDate}
                            onChange={(value) => handleLastMonitoredDate(value)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <MuiDateField
                            value={nextMonitoringDue}
                            onChange={(value) => handleNextMonitoringDue(value)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <MuiDateField
                            value={nextMonitoringSession}
                            onChange={(value) => handleNextMonitoringSession(value)}
                        />
                    </Table.Cell>
                </>
            }
        </Table.Row>
    );
};
