import * as React from "react";
import { Authorize } from "reauthorize";
import {
    EventManagementAdminRoles,
    EventManagementAdminRolesAndTrainers
} from "@common/auth/model";
import { EventTrainer, PracticalEventTrainer } from "../../model";
import { row, hrefRow } from "./TrainerDetails";

/**
 * Extract component and export to allow testing.
 * @param props
 * @param props.trainer the trainer to render
 * @returns {Fragment} A row for trainer's contact numbers and whether they've confirmed.
 */

export const TrainerDetailsContactNumbers = ({ trainer }: { trainer: EventTrainer | PracticalEventTrainer }) => (
    <>
        <Authorize authorize={EventManagementAdminRolesAndTrainers}>
            {row("Confirmed:", trainer.confirmed ? "Yes" : "No")}
            {trainer.mobileNumber && hrefRow("Mobile:", trainer.mobileNumber, `tel:${trainer.mobileNumber}`)}
        </Authorize>
        <Authorize authorize={EventManagementAdminRoles}>
            {hrefRow("Home Telephone:", trainer.homeNumber, `tel:${trainer.homeNumber}`)}
        </Authorize>
    </>
);
