import { Venue } from "@common/crud/venue";
import moment from "moment";
import * as actions from "./actiontypes";
import { VenueAction } from "./actions";

export function venues(state = [] as Venue[], action: VenueAction): Venue[] {
    switch (action.type) {
        case actions.LOAD_ALL_VENUES_SUCCESS:
            return action.payload.map(c => ({ ...c, listUpdated: moment() }));

        case actions.LOAD_VENUE_DETAIL_SUCCESS:
            return [
                ...state.filter(v => v.id !== action.payload.id),
                { listUpdated: moment(), ...action.payload, detailUpdated: moment() }
            ];
        default:
            return state;
    }
}
