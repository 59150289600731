import * as React from "react";
import { Container, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { AutomaticPlacesIndicator } from "./AutomaticPlacesIndicator";
import { useDispatch, useSelector } from "react-redux";
import { reserveSeat } from "../../seat/actions";
import { ApplicationState } from "@booking/applicationState";
import { EventInstanceProps } from "@booking/landing/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { Authorize } from "reauthorize";
import { ManualPlacesIndicator } from "./ManualPlacesIndicator";
import { AttendeeRole } from "@common/auth/model";
import { bookingSelector, deliveryTypeFilterSelector } from "@booking/landing/selectors";
import { useSetDeliveryTypeFilterInSessionStorage } from "@booking/landing/hooks/useSetDeliveryTypeFilterInSessionStorage";

export const EventInstanceMobileComponent: React.FC<EventInstanceProps> = ({ eventInstance, path, additionalMargin, firstNewElement }) => {
    const [t] = useTranslation("EventInstanceMobile");
    const dispatch = useDispatch();
    const includeFullyBookedCourses = useSelector((state: ApplicationState) => state.includeFullyBookedCourses);
    const deliveryTypeFilters = useSelector(deliveryTypeFilterSelector);
    const booking = useSelector(bookingSelector);
    const { endTime, startDate, startTime, priceInPence } = eventInstance;
    const startTimeFormatted = startTime.format(DateFormat.Time, { trim: false });
    const endTimeFormatted = t("END_TIME", { endTime: endTime.format(DateFormat.Time, { trim: false }) });

    const onReservationRequest = React.useCallback(() => {
        dispatch(reserveSeat({ eventInstanceId: eventInstance.id, includeFullyBookedCourses, dayPart: 0, allowAutomatic, allowManual }, path));
        useSetDeliveryTypeFilterInSessionStorage(deliveryTypeFilters);
    }, [eventInstance.id, path, includeFullyBookedCourses, dispatch, deliveryTypeFilters, booking]);

    let className = "event-instance-row cursor-pointer ";
    if (additionalMargin) {
        className += "event-instance-table-additional-margin";
    }

    const allowAutomatic = !!(eventInstance?.automaticSeatsData && eventInstance.automaticSeatsData.remainingSeats > 0);
    const allowManual = !!(eventInstance?.manualSeatsData && eventInstance.manualSeatsData.remainingSeats > 0);

    return (
        <Grid.Row
            key={eventInstance.id}
            id={additionalMargin ? "scroll-to-this" : undefined}
            onClick={onReservationRequest}
            verticalAlign="middle"
            className={className}
        >
            <Grid.Column width={10} textAlign={"left"}>
                <div ref={firstNewElement}>
                    <Container>   {dateString(startDate, DateFormat.LongNoYear + " ")}
                    </Container>
                    <Container>
                        {startTimeFormatted}&nbsp;{endTimeFormatted}
                    </Container>
                    {eventInstance.venueName && (  <Container>{eventInstance.venueName}</Container>)}
                    {eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite &&
                        <Container>{eventInstance.distanceInMiles} {t("MILES")}</Container>
                    }
                    <Container> {t("COURSE_LANGUAGE")} {t(`Languages.${eventInstance.language}`)}
                        <WelshLanguageIndicator language={eventInstance.language} />
                    </Container>
                    {eventInstance.oneToOne &&
                        <Container><img className="menu-icon" src={"/assets/one-to-one.png"} /></Container>}
                </div>
                <AutomaticPlacesIndicator automaticSeatsData={eventInstance.automaticSeatsData} />
                <ManualPlacesIndicator manualSeatsData={eventInstance.manualSeatsData} />
                <Authorize authorize={AttendeeRole}>
                    <div className={"car-type-info"}>
                        {allowAutomatic && !allowManual &&  t("AUTO_ONLY")}
                        {allowManual && !allowAutomatic &&  t("MANUAL_ONLY")}
                        {allowManual && allowAutomatic &&  t("MANUAL_AND_AUTO")}
                    </div>
                </Authorize>
            </Grid.Column>
            <Container>
                <Grid.Column width={4} className="cost">
                £{priceInPence && `${(priceInPence / 100).toFixed(2)}`}
                </Grid.Column>
                <Grid.Column width={2} textAlign={"right"} className="mobile-book-button">
                    <Icon color="black" size="large" name={"angle right"} />
                </Grid.Column>
            </Container>
        </Grid.Row>
    );
};
