import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { FieldAndDate } from "@common/crud/common/FieldAndDate";
import { Country, CountryEnum } from "@common/crud/organisation/model";
import { toast } from "@common/toasts";
import moment from "moment";
import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import { CreateFieldPickerProps, DdrsAllCountriesFeeConfig, initialFeeDetail, ValueWithEffectiveDate } from "../model";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

interface DDRSFeesEditAndViewProps {
    ddrsCourseFees: DdrsAllCountriesFeeConfig;
    onChange: (value: DdrsAllCountriesFeeConfig, valid: boolean) => void;
    CreateFieldPicker: (props: CreateFieldPickerProps<any>) => JSX.Element;
    valueView?: (value: any) => (string | JSX.Element);
    showErrors: boolean;
    fieldName: string;
    fieldIsDuration?: boolean;
    horizontalDisplayTitle?: string;
}

export const DDRSFeesEditAndView: React.FC<DDRSFeesEditAndViewProps> =
({ ddrsCourseFees, onChange, showErrors, fieldName, CreateFieldPicker, fieldIsDuration, horizontalDisplayTitle, valueView }) => {
    const [ddrsFees, setDdrsFees] = React.useState<DdrsAllCountriesFeeConfig>(ddrsCourseFees ?? initialFeeDetail);

    function addField(field: DdrsAllCountriesFeeConfig, date: moment.Moment) {

        const allCountries = ObjectKeys(field);

        const nonNullCountries = allCountries.filter(c => {
            const discountObj = field[c][0].value;
            return ObjectKeys(discountObj).some(key => key!=="discountType" && discountObj[key]);});

        const newFees = Object.fromEntries(
            allCountries.map(country => {
                return nonNullCountries.includes(country)?
                    [country, [...ddrsFees[country], { ...field[country][0], effectiveDate: date }]]:  [country, ddrsFees[country]];
            })) as DdrsAllCountriesFeeConfig;

        let errorCountry;
        if (nonNullCountries.some(country => {errorCountry=country;

            return ddrsFees[country].find((d: { effectiveDate: moment.Moment }) => {

                return moment(d.effectiveDate).isSame(date);});})) {
            toast.warning(`You cannot have two instances of ${errorCountry && Country[errorCountry]}
                fees with the effective date, please remove the existing one to continue.`);
            return ;
        }

        setDdrsFees(newFees);
    }
    ;

    function removeFields(country: Exclude<CountryEnum, CountryEnum.None>) {

        return function(date: moment.Moment) {
            return () => {
                event.preventDefault();
                setDdrsFees({ ...ddrsFees, [country]: [...ddrsFees[country].filter((d: { effectiveDate: moment.Moment }) => d.effectiveDate !== date)] });
            };
        };}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => onChange(ddrsFees, true), [ddrsFees]);

    const filteredList = ObjectKeys(ddrsFees).filter(e =>
        ddrsFees[e].length > 0);
    const viewAndDelete =(all: ValueWithEffectiveDate<any>[], country: CountryEnum) => {

        return all?.sort((a, b) => a.effectiveDate.diff(b.effectiveDate)).map((d, i) => (
            <Grid.Row key={i}>
                <Grid.Column width={6}>{fieldName} from {d.effectiveDate.format(DateFormat.DayMonthYear)}</Grid.Column>
                <Grid.Column width={6}>{valueView({ [country]: all })}</Grid.Column>
                <Grid.Column width={4}><Button onClick={removeFields(+country)(d.effectiveDate)} >Remove</Button></Grid.Column>
            </Grid.Row>
        ));

    };

    return (
        <>
            <Grid>
                {horizontalDisplayTitle && <Grid.Row><Grid.Column><h4>{horizontalDisplayTitle}</h4></Grid.Column></Grid.Row>}
                <Grid.Row>
                    <Grid.Column>
                        <FieldAndDate
                            showErrors={showErrors}
                            onChange={addField}
                            fieldName={fieldName}
                            fieldIsDuration={fieldIsDuration}
                            CreateFieldPicker={CreateFieldPicker}
                        />
                    </Grid.Column>
                </Grid.Row>
                {filteredList.map(country =>
                    (<React.Fragment key={country}><h4>{`${Country[country]} DDRS Course Fees`}</h4>
                        {viewAndDelete(ddrsFees[country], +country)}
                    </React.Fragment>)
                )}
            </Grid>
        </>
    );
};

