import * as React from "react";
import { HeaderLogo } from "@common/global/HeaderLogo";
import { Grid, GridColumnProps } from "semantic-ui-react";
import "./CompanyInfoHeader.scss";

export function CompanyInfoHeader() {

    const mobileBreakpoint = 768;
    const isMobile = window.innerWidth < mobileBreakpoint;

    const columnProps: GridColumnProps = isMobile ? {
        width: "8"
    } : {
        width: "5",
        floated: "right"
    };

    return (
        <Grid columns={2} className="company-header">
            <Grid.Column width={isMobile ? 8 : 4 } verticalAlign="middle">
                <HeaderLogo logo="logo-print.png" />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom" {...columnProps}>
                <div className="company-info">
                    <p className="para bold">TTC Group</p>
                    <p className="para">Hadley Park East</p>
                    <p className="para">Telford, TF1 6QJ</p>
                    <p className="para">T: 0330 024 1805</p>
                    <p className="para bold">www.thettcgroup.com</p>
                </div>
            </Grid.Column>
        </Grid>
    );
}
