import { Payload } from "@neworbit/redux-helpers";
import { Dispatch } from "redux";
import { PreferredNoDaysPerMonthInAGivenMonth, PreferredNoSessionsPerDayInAGivenMonth } from "./model";
import {
    SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS,
    SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS,
    SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS,
    SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS
} from "./actionTypes";

export type TrainerPromiseAction =
    ({ type: SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS } & Payload<PreferredNoDaysPerMonthInAGivenMonth[]>)
    | ({ type: SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS } & Payload<PreferredNoSessionsPerDayInAGivenMonth[]>)
    | ({ type: SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS } & Payload<PreferredNoDaysPerMonthInAGivenMonth[]>)
    | ({ type: SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS } & Payload<PreferredNoSessionsPerDayInAGivenMonth[]>);

export const setCurrentPreferredNoDaysPerMonthOverMonthsSuccess = (payload: PreferredNoDaysPerMonthInAGivenMonth[]): TrainerPromiseAction => ({
    payload,
    type: SET_CURRENT_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS
});

export const setCurrentPreferredNoSessionsPerDayOverMonthsSuccess = (payload: PreferredNoSessionsPerDayInAGivenMonth[]): TrainerPromiseAction => ({
    payload,
    type: SET_CURRENT_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS
});

export const setUpdatedPreferredNoDaysPerMonthOverMonthsSuccess = (payload: PreferredNoDaysPerMonthInAGivenMonth[]): TrainerPromiseAction => ({
    payload,
    type: SET_UPDATED_PREFERRED_NO_DAYS_PER_MONTH_OVER_MONTHS_SUCCESS
});

export const setUpdatedPreferredNoSessionsPerDayOverMonthsSuccess = (payload: PreferredNoSessionsPerDayInAGivenMonth[]): TrainerPromiseAction => ({
    payload,
    type: SET_UPDATED_PREFERRED_NO_SESSIONS_PER_DAY_OVER_MONTHS_SUCCESS
});

export function setCurrentPreferredNoDaysPerMonthOverMonths(preferredNoDaysPerMonthOverMonths: PreferredNoDaysPerMonthInAGivenMonth[]) {
    return async (dispatch: Dispatch) => {
        dispatch(setCurrentPreferredNoDaysPerMonthOverMonthsSuccess(preferredNoDaysPerMonthOverMonths));
    };
}

export function setCurrentPreferredNoSessionsPerDayOverMonths(preferredNoSessionsPerDayOverMonths: PreferredNoSessionsPerDayInAGivenMonth[]) {
    return async (dispatch: Dispatch) => {
        dispatch(setCurrentPreferredNoSessionsPerDayOverMonthsSuccess(preferredNoSessionsPerDayOverMonths));
    };
}

export function setUpdatedPreferredNoDaysPerMonthOverMonths(preferredNoDaysPerMonthOverMonths: PreferredNoDaysPerMonthInAGivenMonth[]) {
    return async (dispatch: Dispatch) => {
        dispatch(setUpdatedPreferredNoDaysPerMonthOverMonthsSuccess(preferredNoDaysPerMonthOverMonths));
    };
}

export function setUpdatedPreferredNoSessionsPerDayOverMonths(preferredNoSessionsPerDayOverMonths: PreferredNoSessionsPerDayInAGivenMonth[]) {
    return async (dispatch: Dispatch) => {
        dispatch(setUpdatedPreferredNoSessionsPerDayOverMonthsSuccess(preferredNoSessionsPerDayOverMonths));
    };
}
