import * as React from "react";
import { StripeSettings } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { InputType, SettingsGrid } from "./SettingsGrid";

export const Stripe: ConfigurationAreaComponent<StripeSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="Stripe"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "PublishKey", label: "Publishable Key - DORS" },
                { name: "SecretKey", label: "Secret Key - DORS" },
                { name: "DorsGenesysStripeAccountId", label: "DORS Genesys Stripe Account Identifier" },
                { name: "DdrsPublishKey", label: "Publishable Key - DDRS" },
                { name: "DdrsSecretKey", label: "Secret Key - DDRS" },
                { name: "DdrsGenesysStripeAccountId", label: "DDRS Genesys Stripe Account Identifier" },
                { name: "CorporatePublishKey", label: "Publishable Key - Corporate" },
                { name: "CorporateSecretKey", label: "Secret Key - Corporate" },
                { name: "CorporateGenesysStripeAccountId", label: "Commercial Genesys Stripe Account Identifier" },
                { name: "CorporateAppleVerificationCode", label: "Commercial domain verification code for Apple Pay", inputType: InputType.TextArea },
                { name: "ConstructionPublishKey", label: "Publishable Key - Construction" },
                { name: "ConstructionSecretKey", label: "Secret Key - Construction" },
                { name: "ConstructionGenesysStripeAccountId", label: "Construction Genesys Stripe Account Identifier" },
            ]}
        />
    );
};
