import { AppState } from "./model";
import { orderSelector } from "./selectors";

export const OrderRoutes = {
    Orders: "/orders",
    Rebook: "/rebook",
    SelectRebooking: "/selectRebooking",
    CreateBasket: "/create",
    DetailsAndPayment: "/detailsAndPayment",
    OrderDetails: "/:orderId",
    History: "/history",
    ReviewPayment: "/reviewPayment"
};

export const detailRoute = () => ({
    [OrderRoutes.OrderDetails]: {
        title: (state: AppState) => orderSelector(state)?.bookingReference,
        [OrderRoutes.History]: {
            title: (state: AppState) => `${orderSelector(state)?.bookingReference} Order Journey`
        },
    }
});

export const routes = {
    [OrderRoutes.Orders]: {
        title: "Orders",
        ...detailRoute(),
        [OrderRoutes.Rebook]: {
            title: "Rebook Attendees"
        },
        [OrderRoutes.SelectRebooking]: {
            title: "Choose new courses"
        },
        [OrderRoutes.DetailsAndPayment]: {
            title: "Details and Payment"
        },
        [OrderRoutes.ReviewPayment]: {
            title: "Review Payment"
        }
    }
};
