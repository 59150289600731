import * as React from "react";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { EventInstanceSessionCarTypeData } from "@booking/landing/model";

export function AutomaticPlacesIndicator({ automaticSeatsData }: { automaticSeatsData: EventInstanceSessionCarTypeData }) {
    if (!automaticSeatsData) {
        return null;
    }

    return (
        <Authorize authorize={TtcClientAdvisorRole}>
            <div className="car-type-places">
                {automaticSeatsData.remainingSeats > 0 && <img
                    src={"/assets/automatic.png"}
                    alt={"Automatic Places"}
                />}
            </div>
        </Authorize>
    );
}
