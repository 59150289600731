import * as React from "react";
import { InvoiceDetailModel, InvoiceFormat } from "../model";
import { TrainerInvoiceApi } from "../trainerInvoiceApi";
import { InvoiceLineItemsTable } from "./InvoiceLineItemsTable";
import { useSelector } from "react-redux";
import { State as RouterState } from "redux-little-router";
import { InvoiceTotalsFooter } from "./invoiceTotalsFooter";
import { InvoiceDetailHeader } from "./invoiceDetailHeader";
import { Button, Message } from "semantic-ui-react";
import moment from "moment";
import { ConfirmButton } from "@common/components";
import { toast } from "@common/toasts";
import { currentUserSelector } from "@common/auth";
import { TrainerRole } from "@common/auth/model";
import { InvoiceBonusLineItemsTable } from "./InvoiceBonusLineItemsTable";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { PrintableInvoiceDetail } from "./PrintableInvoiceDetail";

export const InvoiceDetail: React.FC = () => {
    const [invoice, setInvoicePreview] = React.useState<InvoiceDetailModel>(null);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showPrintView, setShowPrintView] = React.useState(false);
    const [showPrintableFooter, setShowPrintableFooter] = React.useState(false);
    const params = useSelector<RouterState>(state => state.router.params) as { id: string; trainerId: string };
    const currentUser = useSelector(currentUserSelector);
    const isTrainer = currentUser && currentUser.roles?.includes(TrainerRole);
    const config = useSelector(appSettingsSelector);
    const trainerInvoiceOldFormatDate = moment(config.trainerInvoicesSettings.earliestInvoiceDateVersionTwo);

    const invoiceFormat = (trainerInvoiceOldFormatDate && invoice?.invoiceDate < trainerInvoiceOldFormatDate)?
        InvoiceFormat.OldUpliftFees: InvoiceFormat.NewCombinedBonuses;

    window.onafterprint = setAndCloseOnAfterPrint;

    function setAndCloseOnAfterPrint() {
        setShowPrintableFooter(false);
        removeEventListener("onafterprint", setAndCloseOnAfterPrint);
    }

    React.useEffect(() => {
        if (!dataLoaded) {
            const getTrainerInvoice = async () => {
                const api = new TrainerInvoiceApi(params.trainerId);
                const result = await api.getInvoice(params.id);

                if (result) {
                    setInvoicePreview(result);
                    setDataLoaded(true);
                }
            };

            getTrainerInvoice();
        }
    }, [dataLoaded, params]);

    if (!dataLoaded) {
        return <></>;
    }

    function printInvoice() {
        setShowPrintableFooter(true);
        window.print();
    }

    async function cancelInvoice() {
        setLoading(true);
        const api = new TrainerInvoiceApi(params.trainerId);
        const result = await api.cancelInvoice(invoice.id);
        if (result.isCancelled) {
            toast.success("Invoice cancelled successfully");
        } else {
            toast.error("There was an error cancelling this invoice");
        }
        setInvoicePreview(result);
        setLoading(false);
    }

    const currentDate = moment().local();
    const beforeFifteenthOfMonth = invoice.invoiceDate?.isValid() && invoice.invoiceDate.month <= currentDate.month && currentDate.day() <= 15;
    const hasBeenProcessed = invoice.dateProcessed.isValid() && invoice.dateProcessed.year() > 1;
    const allowInvoiceCancellation = !isTrainer && !hasBeenProcessed && beforeFifteenthOfMonth && !invoice.isCancelled;

    return (
        <>
            { invoice.isCancelled &&
            <Message icon="warning circle" negative visible content={"This invoice has been cancelled and will not be processed"} /> }
            { !invoice.isCancelled && <Button className="print-button" onClick={printInvoice}>Print Invoice</Button> }
            { allowInvoiceCancellation && <ConfirmButton
                className="print-button cancel-action"
                content="You are about to cancel this invoice."
                header="Are you sure?"
                onConfirm={cancelInvoice}
                loading={loading}
                disabled={loading}
            >
                Cancel Invoice
            </ConfirmButton>}
            <InvoiceDetailHeader invoice={invoice} />
            <InvoiceLineItemsTable showPrintableFooter={showPrintableFooter} lineItems={invoice.invoiceLineItems} vatPercentage={invoice.vatPercentage}
                invoiceFormat={invoiceFormat} />
            {invoiceFormat === InvoiceFormat.NewCombinedBonuses &&
                <>
                    <InvoiceLineItemsTable lineItems={invoice.invoiceSubcontractedToLineItems} vatPercentage={invoice.vatPercentage} subcontractedType="to"
                        invoiceFormat={invoiceFormat} showPrintableFooter={showPrintableFooter} />
                    <InvoiceBonusLineItemsTable showPrintableFooter={showPrintableFooter} bonusLineItems={invoice.invoiceBonusLineItems} />
                </>}

            <InvoiceTotalsFooter invoiceTotal={invoice.invoiceTotal} vatPercentage={invoice.vatPercentage} />

            {invoiceFormat === InvoiceFormat.NewCombinedBonuses &&
            <>
                <InvoiceLineItemsTable showPrintableFooter={showPrintableFooter} lineItems={invoice.invoiceSubcontractedFromLineItems}
                    vatPercentage={invoice.vatPercentage} subcontractedType="from"
                    invoiceFormat={invoiceFormat} />
            </>}
            <PrintableInvoiceDetail invoice={invoice} showPrintView={showPrintView} setShowPrint={setShowPrintView} invoiceFormat={invoiceFormat} />
        </>
    );
};

