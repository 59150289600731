import * as React from "react";
import { TypedTable } from "@common/crud/common/TypedTable";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { InvoiceListModel } from "../model";
import { Link } from "redux-little-router";
import { calculateGrossAmount } from "@common/trainerInvoices";

export interface InvoiceTableProps {
    invoices: InvoiceListModel[];
    getInvoiceDetailLink: (id: string) => string;
}

export const InvoicesTable: React.FC<InvoiceTableProps> = ({ invoices, getInvoiceDetailLink }) => {

    const getDateProcessed= (v: InvoiceListModel) => {
        if (v.isCancelled) {
            return "Cancelled";
        }
        return v.dateProcessed.isValid() ? v.dateProcessed.format(DateFormat.Short) : "Not Processed";
    };

    return (
        <TypedTable emptyValuesArrayMessage="No invoices have been generated for you" values={invoices}>
            {
                [
                    {
                        header: "Invoice",
                        value: v => {
                            return (
                                <Link href={getInvoiceDetailLink(v.id)}>{v.invoiceNumber}</Link>
                            );
                        },
                    },
                    {
                        header: "Invoice Date",
                        value: v => v.invoiceDate.format(DateFormat.Short),
                    },
                    {
                        header: "Invoice Total",
                        value: v => `£${calculateGrossAmount(v.invoiceTotal, v.vatPercentage).toFixed(2)}`,
                    },
                    {
                        header: "Submitted Date",
                        value: v => v.dateSubmitted.format(DateFormat.Short),
                    },
                    {
                        header: "Date Processed",
                        value: v => getDateProcessed(v),
                    }
                ]
            }
        </TypedTable>
    );
};
