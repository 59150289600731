import * as React from "react";
import { Container, Grid, Tab } from "semantic-ui-react";

import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { LabelAndValue } from "@common/crud/common/LabelAndValue";
import { DisplayAddress } from "@common/crud/common/DisplayAddress";
import { useDispatch, useSelector } from "react-redux";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { CoreDetailProps } from "../Detail";
import { DorsBookingApi } from "../..";
import { SupportRequestsLabelWithEdit } from "../SupportRequestsLabelWithEdit";
import { AssistanceRequired } from "@common/crud/dorsBooking/model";
import { setAssistanceRequiredForBooking } from "@common/crud/dorsBooking/actions";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { AttendanceIdLabelWithEdit } from "@common/crud/attendee/components/details/AttendanceIdLabelWithEdit";
import { ReferredSchemeLabelWithEdit as ReferredSchemeLabelWithEdit } from "@common/crud/attendee/components/details/ReferredSchemeLabelWithEdit";
import { ContactDetailsModal } from "@common/crud/attendee/components/details/ContactDetailsModal";
import { DorsBookingAttendanceStatus } from "@common/crud/dorsBooking/components/DorsBookingAttendanceStatus";
import { AdHocCommsModal } from "@common/crud/attendee/components/details/AdHocComms/AdHocCommsModal";
import { BookButton } from "@common/crud/search/components/BookButton";
import { IvrPaymentActions } from "./IvrPaymentActions";
import { AttendeeInfoMessages, AttendeeInfoMessagesModal } from "@common/crud/eventInstance/components/details/AttendeeInfoMessagesModal";
import { LookupResponse } from "@common/crud/common/LookupResponse";

export const DetailsTab: React.FC<CoreDetailProps> = ({ dorsBooking, internalAppSettings, eventTypes }) => {
    const [expiryDate, setExpiryDate] = React.useState<moment.Moment>(dorsBooking.expiryDate);
    const [lookupResponse, setLookupResponse] = React.useState<LookupResponse>(null);
    const dispatch = useDispatch();

    React.useEffect(() => {
        let isSubscribed = true;

        async function syncBookingDataWithDors(bookingId: string) {
            const bookingApi = new DorsBookingApi();
            const response = await bookingApi.lookupDorsData(bookingId);
            if (isSubscribed) {
                setExpiryDate(response.expiryDate);
                setLookupResponse(response);
            }
        }

        if (dorsBooking.id) {
            syncBookingDataWithDors(dorsBooking.id);
        }

        return () => {
            isSubscribed = false;
        };
    }, [dorsBooking?.id]);

    function updateSupportRequests(model: AssistanceRequired) {
        dispatch(setAssistanceRequiredForBooking(dorsBooking.id, model));
    };

    const normalizedAddress = {
        addressLine1: [dorsBooking.buildingName, dorsBooking.buildingNumber, dorsBooking.address1].filter(x => x).join(" "),
        addressLine2: dorsBooking.address2,
        addressLine3: dorsBooking.address3,
        city: [dorsBooking.townCity, dorsBooking.country].filter(x => x).join(", "),
        postalCode: dorsBooking.postcode
    };

    const bookingDisplayAddress = <DisplayAddress address={normalizedAddress} />;

    const issuingPoliceForce = useSelector(policeOrganisationByDorsId(dorsBooking.forceId));

    return (
        <Tab.Pane className="no-border">
            <AttendeeInfoMessagesModal
                bookingId={dorsBooking.id}
                attendee={null}
                eventInstance={null}
                messages={AttendeeInfoMessages}
                lookupResponse={lookupResponse}
            />
            <Grid stackable>
                <Grid.Column width={8}>
                    <Container className="white-panel">
                        <h5>Attendee details</h5>
                        <LabelAndValue label="Name" value={`${dorsBooking.forename} ${dorsBooking.surname}`} />
                        <LabelAndValue label="Address" value={bookingDisplayAddress} />
                        <LabelAndValue label="Telephone" value={dorsBooking.telephone || "None"} />
                        <LabelAndValue label="Email" value={dorsBooking.email || "None"} />
                        <Authorize authorize={TtcClientAdvisorRole}>
                            <ContactDetailsModal
                                dorsBooking={dorsBooking}
                            />
                        </Authorize>
                        <LabelAndValue label="Driver number" value={dorsBooking.driverNumber} />
                        <LabelAndValue label="Ticket Id" value={dorsBooking.ticketId} />
                        <LabelAndValue
                            label="Completion Date"
                            value={expiryDate?.format("DD MMM YYYY") || "Not Set"}
                        />
                        <ReferredSchemeLabelWithEdit
                            booking={dorsBooking}
                            eventTypeId={dorsBooking.eventTypeId}
                            eventTypes={eventTypes} />
                        <LabelAndValue label="Referring Police Force" value={issuingPoliceForce?.name || dorsBooking.forceName || "Non TTC Police Force"} />
                        <LabelAndValue label="DORS status" value={<DorsBookingAttendanceStatus status={lookupResponse?.dorsAttendanceStatus} />} />
                        <AttendanceIdLabelWithEdit
                            attendanceId={dorsBooking.attendanceId}
                            dorsBookingId={dorsBooking.id}
                        />
                    </Container>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Container className="blue-panel">
                        <h5>Course details</h5>
                        <LabelAndValue label="Scheme" value={dorsBooking.eventTypeName} />
                        <LabelAndValue label="Date and Time" value="No course booked" />
                        <Authorize authorize={[TtcClientAdvisorRole]}>
                            <SupportRequestsLabelWithEdit
                                correlationId={dorsBooking.id}
                                updateSupportRequests={updateSupportRequests}
                                taskRelation={TaskRelationEnum.DorsBooking}
                                booking={dorsBooking} />
                            <BookButton
                                disable={dorsBooking.isTransferred}
                                bookingId={dorsBooking.id}
                                bookingAppEndpoint={internalAppSettings.bookingAppEndpoint}
                                isDorsBooking
                            />
                            <IvrPaymentActions
                                bookingId={dorsBooking.id}
                                bookingAppEndpoint={internalAppSettings.bookingAppEndpoint}
                            />
                            <AdHocCommsModal
                                correlationId={dorsBooking.id}
                                attendeeEmail={dorsBooking.email}
                                attendeeNumber={dorsBooking.telephone}
                            />
                        </Authorize>
                    </Container>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
