import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { BookingsHistoryContent } from "@common/crud/bookingsHistory/components/BookingsHistoryContent";
import { eventInstanceGroupByIdSelector, eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { EiSideBar } from "@common/crud/eventInstance/components/EiSideBar";
import { EventInstanceBanner } from "@common/crud/eventInstance/components/EventInstanceBanner";
import { EventInstanceApi } from "@common/crud/eventInstance";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";

export const BookingsHistory: React.FC = () => {
    const eventInstance = useSelector(eventInstanceSelector);
    const eventInstanceGroup = useSelector(eventInstanceGroupByIdSelector(eventInstance?.groupId)) as EventInstanceGroupModel;
    const eventInstanceGroupDays = React.useMemo(() => eventInstanceGroup?.eventInstanceGroupItems?.map(eigi => ({
        dayNumber: eigi.dayNumber,
        deliveryDateTime: eigi.deliveryDateTime,
        eventInstanceId: eigi.eventInstanceId
    })).sort((a, b) => a.dayNumber - b.dayNumber) ?? [], [eventInstanceGroup?.eventInstanceGroupItems]);

    const isEventInstanceMultiDay = React.useMemo(() => !!eventInstance?.groupId, [eventInstance?.groupId]);

    const [bookingHistory, setBookingHistory] = React.useState([]);
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function loadBookingHistory() {
            const api = new EventInstanceApi();
            const result = await api.getEventInstanceBookingHistory(eventInstance.id);
            setBookingHistory(result);
        }

        if (eventInstance?.id) {
            loadBookingHistory();
        }
    },[eventInstance.id, dispatch]);

    const content = () => {
        return (
            <div>
                <h1>Bookings history</h1>
                <EventInstanceBanner eventInstance={eventInstance} />
                <BookingsHistoryContent
                    entries={bookingHistory}
                    isEventInstanceMultiDay={isEventInstanceMultiDay}
                    eventInstanceGroupDays={eventInstanceGroupDays}
                    workflowType={eventInstance.workflowType}
                />
            </div>);
    };

    return <EiSideBar children={content()} />;
};
