import * as React from "react";
import { Modal, ConfirmButton } from "@common/components";
import { EventInstance } from "../model";
import { useDispatch } from "react-redux";
import { updateReasonForHidingEvent } from "../actions";

export interface Props {
    eventInstance: EventInstance;
}

export const ShowEventInstanceButton: React.FC<Props> = ({ eventInstance }) => {
    const dispatch = useDispatch();
    const onShowEventInstanceClick =
        // eslint-disable-next-line react-hooks/exhaustive-deps
        React.useCallback(() => dispatch(updateReasonForHidingEvent(eventInstance.id, null)), [dispatch, updateReasonForHidingEvent, eventInstance]);

    const confirmationText = (
        <Modal.Content>
            <h3 className="status-attention">Are you sure?</h3>
            <p>The Course will be visible to — and bookable by — customers or staff.</p>
        </Modal.Content>
    );
    return <ConfirmButton className="undo-action" content={confirmationText} onConfirm={onShowEventInstanceClick}>Make Course visible</ConfirmButton>;
};
