/* eslint-disable max-lines */
import * as React from "react";
import {  Table } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import { UpdateSpecialRequirementsTableRow } from "./UpdateSpecialRequirementsTableRow";

import "./UpdateSpecialRequirementsTable.scss";

interface UpdateSpecialRequirementsTableProps {
    delegates: AttendeeListModel[];
    newDelegates?: AttendeeListModel[];
    setNewDelegates?: React.Dispatch<React.SetStateAction<AttendeeListModel[]>>;
    isDigitalCourse: boolean;
}

export const UpdateSpecialRequirementsTable: React.FC<UpdateSpecialRequirementsTableProps> =
({ delegates, newDelegates, setNewDelegates, isDigitalCourse }) => {
    const hasExistingDelegates = delegates && delegates.length > 0;
    const hasNewDelegates = newDelegates && newDelegates.length > 0;

    const updateDelegate = React.useCallback((delegate: AttendeeListModel, updatedDelegate: AttendeeListModel) => {
        setNewDelegates(prev => prev.map(d => d.id === delegate.id ? updatedDelegate : d));
    }, [setNewDelegates]);

    return (
        <>
            <Table celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={13}>Special Requirements</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {!hasExistingDelegates &&
                        <Table.Row>
                            <Table.Cell textAlign="center" colSpan={6}>{"No Delegates Currently Saved"}</Table.Cell>
                        </Table.Row>}
                    {(hasExistingDelegates || hasNewDelegates) &&
                        (newDelegates || delegates)?.filter(delegate => !delegate.isBookingCanceled).map((delegate, index) =>
                            (<UpdateSpecialRequirementsTableRow
                                key={`delegate_${delegate?.id || index}`}
                                delegate={delegate}
                                updateDelegate={updateDelegate}
                                isDigitalCourse={isDigitalCourse}
                            />)
                        )
                    }
                </Table.Body>
            </Table>
        </>
    );
};
