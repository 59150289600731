import * as React from "react";
import { EventInstance } from "../..";
import moment from "moment";
import { Table } from "semantic-ui-react";
import { DigitalCalendarSessionRow } from "./DigitalCalendarSessionRow";
import { EventType } from "@common/crud/eventType";
import { AvailabilityType, SessionAvailability } from "@common/availabilityTrainer/model";
import { GetEventMaxDigitalAttendees, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { toast } from "react-toastify";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { GetAllTrainerIdsForEventInstance } from "./DigitalPlanningHelpers";
import { eventOverlapsWithSession, setDeliveryTimesForTrainer } from "../../helpers";
import { durationMap, previousCourseSessionInProgress } from "@common/availabilityTrainer/helpers";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";

interface DrawAvailabilityProps {
    day: Date;
    availability: SessionAvailability[];
    trainerExpiryDate?: moment.Moment;
    existingEvents: EventInstance[];
    digitallyPlannedEvents: EventInstance[];
    digitallyPlannedForOtherCategory: EventInstance[];
    deleteDigitallyPlannedEvent: (eventId: string) => void;
    addDigitallyPlannedEvent: (session: number, date: moment.Moment, oldEventId?: string) => void;
    selectedTrainerId: string;
    selectedEventType: EventType;
    disabled: boolean;
    eventTypeCategory: EventTypeCategory;
    existingAllocationsOnDay: EventInstance[];
    standbyCalendar: StandbyTrainersCalendarDay[];
}

const getNumberOfSeatsOfEventType = (eventType: EventType, events: EventInstance[]) => {
    if (!eventType) {
        return null;
    }

    let numberOfEvents = 0;
    events.forEach(e => {
        if (e.eventTypeId === eventType.id && (e.eventInstanceDeliveryType === undefined || e.eventInstanceDeliveryType === DeliveryTypeEnum.Digital)) {
            numberOfEvents++;
        }
    });
    return numberOfEvents * (events?.length > 0 ? GetEventMaxDigitalAttendees(events[0].startDate, eventType) : 0);
};

export const DrawAvailability: React.FC<DrawAvailabilityProps> = (props) => {
    const { availability, trainerExpiryDate, existingEvents, digitallyPlannedEvents, day, selectedTrainerId, selectedEventType,
        deleteDigitallyPlannedEvent, addDigitallyPlannedEvent, disabled, eventTypeCategory,digitallyPlannedForOtherCategory,
        existingAllocationsOnDay, standbyCalendar } = props;

    const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;

    const startOfTrainerExpiryDate = trainerExpiryDate
        ? trainerExpiryDate.utc().startOf("day")
        : undefined;

    const startOfDay = moment(day).utc().startOf("day");
    const endOfDay = moment(day).utc().endOf("day");

    const trainerExpired = !!startOfTrainerExpiryDate && startOfTrainerExpiryDate <= startOfDay;

    // Events that are on the same day
    const eventsOnDay = [...existingEvents, ...digitallyPlannedEvents, ...digitallyPlannedForOtherCategory].filter(e =>
        e.startDate >= startOfDay && e.startDate <= endOfDay);
    const trainerEvents =
        eventsOnDay.filter(e => e.trainerId === selectedTrainerId ||
            GetAllTrainerIdsForEventInstance(e.id, existingEvents).includes(selectedTrainerId) ||
            GetAllTrainerIdsForEventInstance(e.id, digitallyPlannedForOtherCategory).includes(selectedTrainerId))
            .map(e => setDeliveryTimesForTrainer(e, selectedTrainerId));

    const sessionOneCourses = eventsOnDay.filter(x => eventOverlapsWithSession(x, 1));
    const sessionTwoCourses = eventsOnDay.filter(x => eventOverlapsWithSession(x, 2));
    const sessionThreeCourses = eventsOnDay.filter(x => eventOverlapsWithSession(x, 3));
    const sessionFourCourses = eventsOnDay.filter(x => eventOverlapsWithSession(x, 4));

    const otherDdrsCourseOnDay = eventsOnDay.findIndex(e => e.trainerId === selectedTrainerId && e.workflowType === WorkflowTypeEnum.DDRS) !== -1;

    const sessionOneCourse = trainerEvents.find(x => eventOverlapsWithSession(x, 1));
    const sessionTwoCourse = trainerEvents.find(x => x !== sessionOneCourse && eventOverlapsWithSession(x, 2)) ??
        trainerEvents.find(x => eventOverlapsWithSession(x, 2));
    const sessionThreeCourse = trainerEvents.find(x => x !== sessionTwoCourse && eventOverlapsWithSession(x, 3)) ??
        trainerEvents.find(x => eventOverlapsWithSession(x, 3));
    const sessionFourCourse = trainerEvents.find(x => x !== sessionThreeCourse && eventOverlapsWithSession(x, 4)) ??
        trainerEvents.find(x => eventOverlapsWithSession(x, 4));

    const standbyArrangementsForDay = standbyCalendar?.find(d => d.date.date() === startOfDay.date());
    const sessionOneStandby = standbyArrangementsForDay?.sessionOneStandbyTrainerIds?.length > 0 ?? false;
    const sessionTwoStandby = standbyArrangementsForDay?.sessionTwoStandbyTrainerIds?.length > 0 ?? false;
    const sessionThreeStandby = standbyArrangementsForDay?.sessionThreeStandbyTrainerIds?.length > 0 ?? false;
    const sessionFourStandby = standbyArrangementsForDay?.sessionFourStandbyTrainerIds?.length > 0 ?? false;

    const sessionCourses = [
        sessionOneCourse,
        sessionTwoCourse,
        sessionThreeCourse,
        sessionFourCourse
    ];

    const existingAllocatedCoursesSessionIndexes = existingAllocationsOnDay?.map(e => {
        let index = 0;
        if (eventOverlapsWithSession(e, 1)) {
            index = 1;
        }

        if (eventOverlapsWithSession(e, 2)) {
            index = 2;
        }

        if (eventOverlapsWithSession(e, 3)) {
            index = 3;
        }

        if (eventOverlapsWithSession(e, 4)) {
            index = 4;
        }

        return {
            existingSessionIndex: index,
        };
    });

    const getCssClassName = React.useCallback((sessionIndex: number, positionClass: string, canChange: boolean, fromOtherWorkflow: boolean, standby: boolean) =>
        trainerExpired
            ? "period-trainer-expired"
            : standby
                ? "period-color-session-filled"
                : `${positionClass} ${existingAllocatedCoursesSessionIndexes?.some(eacsi => eacsi.existingSessionIndex === sessionIndex)
                    ? "existing-trainer-allocation"
                    : canChange
                        ? (availability.map(a => a.session).indexOf(sessionIndex) > -1
                            ? "period-color"
                            : "")
                        : fromOtherWorkflow
                            ? "period-color-session-other-wf"
                            : "period-color-session-filled"}`
    ,[availability, existingAllocatedCoursesSessionIndexes, trainerExpired]);

    const getOnClick = React.useCallback((event: EventInstance, sessionIndex: number) => {
        return () => {
            if (disabled) {
                return;
            }
            if (selectedEventType === null || selectedEventType === undefined) {
                // eslint-disable-next-line eqeqeq
                if (event?.id != null) {
                    deleteDigitallyPlannedEvent(event?.id);
                }
                return; // Do nothing if no event type currently selected.
            }
            if (selectedEventType.id === event?.eventTypeId) {
                deleteDigitallyPlannedEvent(event?.id);
                return; // Delete event if session has been double clicked.
            }
            if (ddrs && otherDdrsCourseOnDay) {
                toast.warning("Cannot add course as this trainer has already been assigned to an event on this day");
                return;
            }
            addDigitallyPlannedEvent(sessionIndex, startOfDay, event?.id);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addDigitallyPlannedEvent, deleteDigitallyPlannedEvent, selectedEventType, startOfDay, disabled]);

    const getCanChange = (standby: boolean, course?: EventInstance) => !standby && !trainerExpired && (!course ||
     existingEvents.concat(digitallyPlannedForOtherCategory).findIndex(e => e.id === course?.id) === -1);

    const getFromOtherWorkflowPlanning = (course?: EventInstance) => course &&
     digitallyPlannedForOtherCategory.findIndex(e => e.id === course?.id) !== -1;

    return (
        <div className="font-style">
            <Table className="unstackable">
                <Table.Body className="table-body">
                    <DigitalCalendarSessionRow
                        onClick={getOnClick(sessionOneCourse, 1)}
                        sessionCourse={sessionOneCourse}
                        cssClass={getCssClassName(1, "top", getCanChange(sessionOneStandby, sessionOneCourse), getFromOtherWorkflowPlanning(sessionOneCourse),
                            sessionOneStandby)}
                        numberOfSeats={getNumberOfSeatsOfEventType(selectedEventType, sessionOneCourses)}
                        canChange={getCanChange(sessionOneStandby, sessionOneCourse)}
                        fromOtherWorkflowPlanning={getFromOtherWorkflowPlanning(sessionOneCourse)}
                        classroomAvailabilityOnly={availability.find(a => a.session === 1)?.type === AvailabilityType.Classroom}
                        trainerExpired={trainerExpired}
                        lengthOfCont={durationMap(sessionCourses)[0]}
                        hiddenByContCourse={false}
                        standby={sessionOneStandby}
                    />
                    <DigitalCalendarSessionRow
                        onClick={getOnClick(sessionTwoCourse, 2)}
                        sessionCourse={sessionTwoCourse}
                        cssClass={getCssClassName(2, "middle-top", getCanChange(sessionTwoStandby, sessionTwoCourse),
                            getFromOtherWorkflowPlanning(sessionTwoCourse), sessionTwoStandby)}
                        numberOfSeats={getNumberOfSeatsOfEventType(selectedEventType, sessionTwoCourses)}
                        canChange={getCanChange(sessionTwoStandby, sessionTwoCourse)}
                        fromOtherWorkflowPlanning={getFromOtherWorkflowPlanning(sessionTwoCourse)}
                        classroomAvailabilityOnly={availability.find(a => a.session === 2)?.type === AvailabilityType.Classroom}
                        trainerExpired={trainerExpired}
                        lengthOfCont={durationMap(sessionCourses)[1]}
                        hiddenByContCourse={previousCourseSessionInProgress(2, sessionCourses)}
                        standby={sessionTwoStandby}
                    />
                    <DigitalCalendarSessionRow
                        onClick={getOnClick(sessionThreeCourse, 3)}
                        sessionCourse={sessionThreeCourse}
                        cssClass={getCssClassName(3, "middle-bottom", getCanChange(sessionThreeStandby, sessionThreeCourse),
                            getFromOtherWorkflowPlanning(sessionThreeCourse), sessionThreeStandby)}
                        numberOfSeats={getNumberOfSeatsOfEventType(selectedEventType, sessionThreeCourses)}
                        canChange={getCanChange(sessionThreeStandby, sessionThreeCourse)}
                        fromOtherWorkflowPlanning={getFromOtherWorkflowPlanning(sessionThreeCourse)}
                        classroomAvailabilityOnly={availability.find(a => a.session === 3)?.type === AvailabilityType.Classroom}
                        trainerExpired={trainerExpired}
                        lengthOfCont={durationMap(sessionCourses)[2]}
                        hiddenByContCourse={previousCourseSessionInProgress(3, sessionCourses)}
                        standby={sessionThreeStandby}
                    />
                    <DigitalCalendarSessionRow
                        onClick={getOnClick(sessionFourCourse, 4)}
                        sessionCourse={sessionFourCourse}
                        cssClass={getCssClassName(4, "bottom", getCanChange(sessionFourStandby, sessionFourCourse),
                            getFromOtherWorkflowPlanning(sessionFourCourse), sessionFourStandby)}
                        numberOfSeats={getNumberOfSeatsOfEventType(selectedEventType, sessionFourCourses)}
                        canChange={getCanChange(sessionFourStandby, sessionFourCourse)}
                        fromOtherWorkflowPlanning={getFromOtherWorkflowPlanning(sessionFourCourse)}
                        classroomAvailabilityOnly={availability.find(a => a.session === 4)?.type === AvailabilityType.Classroom}
                        trainerExpired={trainerExpired}
                        lengthOfCont={durationMap(sessionCourses)[3]}
                        hiddenByContCourse={previousCourseSessionInProgress(4, sessionCourses)}
                        standby={sessionFourStandby}
                    />
                </Table.Body>
            </Table>
        </div>
    );
};
