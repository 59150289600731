import { loadEventInstancesForVenue } from "../eventInstance/actions";

export const detailRoutes = {
    "/history": {
        title: "Venue history"
    },
    "/calendar": {
        title: "Venue Calendar",
        resolve: [loadEventInstancesForVenue]
    }
};
