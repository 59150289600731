import * as React from "react";
import { AttendeeRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { Segment } from "semantic-ui-react";
import { ConfirmButton } from "@common/components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resendConfirmationMail } from "../landing/actions";

export const ResendConfirmationButton: React.FunctionComponent = () => {
    const [t] = useTranslation("ResendConfirmationButton");
    const dispatch = useDispatch();

    const resendConfirmationEmail = React.useCallback(() => {
        dispatch(resendConfirmationMail());
    }, [dispatch]);

    return (
        <Authorize authorize={AttendeeRole}>
            <Segment className="confirmation-button">
                <ConfirmButton
                    onConfirm={resendConfirmationEmail}
                    header={t("RESEND_CONFIRMATION_EMAIL")}
                >
                    {t("RESEND_CONFIRMATION_EMAIL")}
                </ConfirmButton>
            </Segment>
        </Authorize>
    );
};
