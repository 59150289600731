import * as React from "react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Link } from "redux-little-router";
import { useSelector } from "react-redux";
import { basePathSelector } from "@common/crud/eventInstance/selectors";
import { AppState } from "@common/crud/eventInstance/model";
import { Button, Grid } from "semantic-ui-react";
import { reactStringReplace } from "../stringReplace";
import { ChangeEventType, ChangeEventTypes, HistoryLinkItem, HistoryRecord, LinkedResource } from "../model";
import { HistoryObjectModificationComponent } from "./HistoryObjectModificationComponent";
import { PendingNoteDetails } from "./PendingNoteDetails";
import { EmailDetailsViewer } from "./EmailDetailsViewer";
import { SmsDetailsViewer } from "./SmsDetailsViewer";
import { HistoryNote } from "./HistoryNote";
import { Authorize } from "reauthorize";
import { TtcFinanceAdministratorRole } from "@common/auth/model";
import { TransferPaymentModal } from "./TransferPaymentModal";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { OrganisationCommsHistoryNote } from "@common/history/components/OrganisationCommsHistoryNote";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

export interface HistoryEntryProps {
    entry: HistoryRecord;
    showAllDetails: boolean;
    onEditPendingNote: (entry: HistoryRecord) => void;
    eventTypeCategory?: EventTypeCategory;
    taskRelation: TaskRelationEnum;
}

export const HistoryEntry: React.FC<HistoryEntryProps> = ({ entry, showAllDetails, onEditPendingNote, eventTypeCategory, taskRelation }) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(showAllDetails);

    const app = useSelector(appSelector);

    const isAdminApp = React.useMemo(() => app === Apps.Admin, [app]);

    React.useEffect(() => {
        setShowDetails(showAllDetails);
    }, [showAllDetails]);

    const onPreviewClick = React.useCallback(() => {
        const encodedBlobPath = encodeURIComponent(entry.noteAttachmentBlobPath);
        window.open(`/api/note/attached-documents/open/${encodedBlobPath}`);
    }, [entry.noteAttachmentBlobPath]);

    const onDownloadClick = React.useCallback(() => {
        const encodedBlobPath = encodeURIComponent(entry.noteAttachmentBlobPath);
        window.open(`/api/note/attached-documents/download/${encodedBlobPath}`);
    } ,[entry.noteAttachmentBlobPath]);

    const onShowDetails = React.useCallback(() => setShowDetails(!showDetails), [showDetails]);

    const linkedPaymentId = entry.historyLinkItems?.find(h => h.linkedResource === LinkedResource.Payment)?.mainObjectId;
    const showTransferPaymentButton =
        !entry.eventMessage?.includes("Payment skipped") && !entry.eventMessage?.includes("Flexi-booking was added") &&
        entry.eventType === ChangeEventType.Payment && linkedPaymentId !== undefined;

    return (
        <div className="entry">
            <span className="bold cursor-pointer" onClick={onShowDetails}>
                <span className="entry-type">
                    {ChangeEventTypes[entry.eventType]}
                </span>
                {
                    entry.eventType !== ChangeEventType.ExpiryDate && entry.eventType !== ChangeEventType.FlexiPayExpiryDate
                    && <span className="creation-details">{entry.dateCreated.format(DateFormat.Time)} by {entry.recordModifiedByEmail}</span>
                }
            </span>
            <span className="bold">
                {
                    ((entry.eventType === ChangeEventType.Note || (isAdminApp && (entry.eventType === ChangeEventType.TrainerNote
                        || entry.eventType === ChangeEventType.TrainerComplaints))) &&
                    <HistoryNote
                        note={entry.eventMessage}
                        correlationId={entry.correlationId}
                        noteId={entry.id}
                        fileName={entry.noteAttachmentFileName}
                        eventType={entry.eventType}
                        taskRelation={taskRelation}
                    />)
                }
                <PendingNoteDetails entry={entry} onEditPendingNote={onEditPendingNote} />
            </span>
            {showDetails && <div>
                {
                    (isAdminApp && entry?.noteAttachmentFileName) && (
                        <div className="message-with-links">
                            <span>Attachment: {entry.noteAttachmentFileName}</span>
                            <br />
                            <Button content="Preview" onClick={onPreviewClick} className="link-button withoutMargin" />
                            <br />
                            <Button content="Download" onClick={onDownloadClick} className="link-button withoutMargin" />
                        </div>
                    )
                }
                {
                    entry?.historyLinkItems?.length > 0 ?
                        <div className="message-with-links">
                            <MessageWithLinks message={entry.eventMessage} historyLinkItems={entry.historyLinkItems} eventTypeCategory={eventTypeCategory} />
                        </div> :
                        <span className="message">
                            {entry.eventType === ChangeEventType.CourseCreated && (
                                <div>{`${entry.properties?.ProductName} course taking place at ${entry.properties?.CourseVenue}` +
                                ` on ${entry.properties?.CourseDateTime} has been created`}</div>
                            )}
                            {entry.eventType === ChangeEventType.Note && entry.isAlertNoteToTTC &&
                                <div>Police User alerted TTC to the below note</div>}
                            {!entry.properties?.MessageSentDateTime && !entry.properties?.CorporateEventInstanceIdRedirect &&
                                !entry.properties?.SeatsBooked && entry.eventMessage}
                            {!!entry.properties?.CorporateEventInstanceIdRedirect &&
                                (<a href={`/corporate-event-management/eventInstances/${entry.properties?.CorporateEventInstanceIdRedirect}`}>
                                    {entry.eventMessage}
                                </a>)}
                            {!!entry.properties?.MessageSentDateTime && <OrganisationCommsHistoryNote historyRecord={entry} />}
                            {entry.eventType === ChangeEventType.Comms && <EmailDetailsViewer
                                emailMessageId={entry.properties?.EmailMessageId}
                                emailTemplateName={entry.properties?.EmailTemplateName}
                                emailFrom={entry.properties?.EmailFrom}
                            />}
                            {entry.eventType === ChangeEventType.Comms && <SmsDetailsViewer
                                smsBody={entry.properties?.SmsBody}
                                smsTemplateName={entry.properties?.SmsTemplateName}
                            />}
                            {entry.eventType === ChangeEventType.AlwaysShown && entry.properties?.TransferredToProviderName &&
                                <p>{entry.properties?.TransferredToProviderName}</p>
                            }
                            {entry.eventType === ChangeEventType.Certificates && entry.properties?.CertificateUrl &&
                                <p><a href={entry.properties?.CertificateUrl} target="_blank" rel="noreferrer">Open</a></p>
                            }
                        </span>
                }
                {entry.comparisonResult &&
                    <Grid className="data-modification">
                        <HistoryObjectModificationComponent historyRecord={entry} />
                    </Grid>
                }
                { showTransferPaymentButton &&
                    <Authorize authorize={TtcFinanceAdministratorRole}>
                        <TransferPaymentModal correlationId={entry.correlationId} paymentId={linkedPaymentId} />
                    </Authorize>
                }
            </div>}
        </div>
    );
};

const MessageWithLinks: React.FC<{ message: string; historyLinkItems: HistoryLinkItem[]; eventTypeCategory?: EventTypeCategory }>
    = ({ message, historyLinkItems, eventTypeCategory }) => {
        const baseUrl = useSelector((state: AppState) => basePathSelector(state));
        let rna: React.ReactNode[] = [message];

        [LinkedResource.NewEventInstance, LinkedResource.OldAttendee,
            LinkedResource.OldEventInstance, LinkedResource.EventInstance, LinkedResource.NewAttendee,
            LinkedResource.Organisation, LinkedResource.CorporateUser, LinkedResource.CorporateEventInstance].forEach(element => {
            rna = prepareReactNode(rna, historyLinkItems, element, baseUrl, eventTypeCategory);
        });

        return (
            <span>
                {rna.map(elem => elem)}
            </span>);
    };

const prepareReactNode =
    (message: React.ReactNode[],
        historyLinkItems: HistoryLinkItem[],
        elemToReplace: LinkedResource,
        basePath: string,
        eventTypeCategory?: EventTypeCategory): React.ReactNode[] => {
        if (historyLinkItems.findIndex(x => x.linkedResource === elemToReplace) === -1) {
            return message;
        }

        const historyLinkItem = historyLinkItems.find(x => x.linkedResource === elemToReplace);

        const match = `{${LinkedResource[historyLinkItem.linkedResource]}}`;
        const href =
            getHrefForLinkType(historyLinkItem.linkedResource, historyLinkItem.mainObjectId, historyLinkItem.secondaryObjectId, basePath, eventTypeCategory);

        return reactStringReplace(message, match, () =>
            <Link key={historyLinkItem.mainObjectId} href={href}>{historyLinkItem.mainObjectName}</Link>);
    };

const getHrefForLinkType
    = (linkType: LinkedResource, mainObjectId: string, secondaryObjectId: string, basePath: string, eventTypeCategory?: EventTypeCategory) => {
        switch (linkType) {
            case LinkedResource.NewEventInstance:
            case LinkedResource.OldEventInstance:
                return `${basePath}/${mainObjectId}`;
            case LinkedResource.NewAttendee:
            case LinkedResource.OldAttendee:
                return `${basePath}/${secondaryObjectId}/attendees/${mainObjectId}`;
            case LinkedResource.CorporateUser:
                return `/corporate-event-management/organisations/${secondaryObjectId}/users/${mainObjectId}`;
            case LinkedResource.EventInstance:
                return (eventTypeCategory === EventTypeCategory.CorporateOrganisation ? "/corporate-event-management/" : "/police-and-court-event-management/")
                    + `eventInstances/${mainObjectId}`;
            case LinkedResource.Organisation:
                return `/corporate-event-management/organisations/${mainObjectId}`;
            case LinkedResource.CorporateEventInstance:
                return `/corporate-event-management/eventInstances/${mainObjectId}`;
            default:
                return "#";
        }
    };
