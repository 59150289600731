/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS = "LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS";
export type LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS = typeof LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS;

export const CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS = "CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS";
export type CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS = typeof CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS;

export const SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS = "SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS";
export type SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS = typeof SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS;

export const DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS = "DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS";
export type DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS = typeof DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS;

export const LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS = "LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS";
export type LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS = typeof LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS;
