import moment from "moment";
import { LocalStateKeys, LocalStateKeysEnum } from "../model";

export function useGetAdvancedFilter(bookingId: string) {
    const json = sessionStorage.getItem(`${LocalStateKeys[LocalStateKeysEnum.AdvancedFilterSearch]}${bookingId}`);
    const filter = json !== null ? JSON.parse(json) : undefined;
    if (filter) {
        const created = moment(filter.created);
        if (moment().diff(created, "minutes") < 20) {
            return { ...filter,
                toDate: filter.toDate ? moment(filter.toDate) : undefined,
                fromDate: filter.fromDate ? moment(filter.fromDate) : undefined
            };
        }
    }
    return undefined;
}
