import { DateFormat } from "@common/crud/common/DateTimeFormats";
import moment from "moment";
import * as React from "react";
import { Divider, Message, Segment } from "semantic-ui-react";
import { EventInstance, EventTrainer, TrainerAvailabilityRoleType } from "../../model";

interface OwnProps {
    eventInstance: EventInstance;
}

function mapTrainerToString(trainer: EventTrainer) {
    let result = trainer.name;
    if (trainer.dateMadeAvailableForOtherTrainers) {
        result +=
            ` on ${trainer.dateMadeAvailableForOtherTrainers.format(DateFormat.Short)} at ${trainer.dateMadeAvailableForOtherTrainers.format(DateFormat.Time)}`;
    }
    return result;
}

export const EventInstanceAvailabilityMessage: React.FC<OwnProps> = ({ eventInstance }) => {
    const trainersWhoMadeCourseAvailable = Array.from(
        new Set((eventInstance.trainers ?? []).concat(eventInstance.practicalTrainers ?? []).filter(t => t.availableForOtherTrainers).map(mapTrainerToString)));
    const madeAvailableByAdmin = eventInstance.availableForOtherTrainers;
    const madeAvailableByTrainers = trainersWhoMadeCourseAvailable.length > 0;
    const availableForOtherTrainers = madeAvailableByAdmin || madeAvailableByTrainers;

    const courseDateHasPassed = moment().utc() > eventInstance?.deliveryDateTime;

    const getMessageContent = () => {
        let content = "";
        if (madeAvailableByAdmin) {
            const adminRoles = eventInstance.adminAvailableRoles.map(r => TrainerAvailabilityRoleType[r]).join(", ");
            content +=
                `This course was made available by admin for the following role${adminRoles.length > 1 ? "s: " : ": "}${adminRoles}`;
        }

        if (madeAvailableByTrainers) {
            if (content) {
                content += "\n";
            }
            content += `This course was made available by the following trainers: ${trainersWhoMadeCourseAvailable.join(", ")}`;
        }

        return content;
    };

    return (<>
        {availableForOtherTrainers && !courseDateHasPassed && !eventInstance.cancelled &&
            <>
                <Message content={getMessageContent()} as={Segment} error className="multi-line" />
                <Divider hidden />
            </>
        }
    </>);
};
