import * as React from "react";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { HistoryRecord } from "../model";
import { HistoryDateEntries } from "./HistoryDateEntries";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { EventInstanceDayDropdown } from "./EventInstanceDayDropdown";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

interface HistoryContentProps {
    entries: HistoryRecord[];
    showDetailsDefault: boolean;
    workflowType?: number;
    groupEventInstance?: EventInstanceGroupModel;
    eventTypeCategory?: EventTypeCategory;
    taskRelation?: TaskRelationEnum;
}

export const HistoryContent: React.FC<HistoryContentProps> =
({ entries, showDetailsDefault, workflowType, groupEventInstance, eventTypeCategory, taskRelation }) => {
    const [showAllDetails, setShowAllDetails] = React.useState<boolean>(showDetailsDefault);
    const [day, setDay] = React.useState<number>(null);

    const groupedHistory = _(entries).orderBy((a) => a.dateCreated, "desc")
        .groupBy(entry => entry.dateCreated.format(DateFormat.Short))
        .map((value, key) => ({ key, values: value.filter(v => {
            if (day !== null) {
                return v.eventInstanceDay === day;
            }
            return v;
        }) }))
        .value();

    const showDetailsText = showAllDetails ? "Hide all details" : "Show all details";

    const setShowAllDetailsClick = React.useCallback(() => setShowAllDetails(!showAllDetails),
        [showAllDetails]);

    return (
        <Grid>
            <Grid.Column>
                <Grid padded="vertically">
                    <Grid.Column width="3">
                        <a className="show-button" onClick={setShowAllDetailsClick}>{showDetailsText}</a>
                    </Grid.Column>
                    {
                        workflowType === WorkflowTypeEnum.DDRS &&
                        <EventInstanceDayDropdown groupEventInstance={groupEventInstance} setDay={setDay} day={day} />
                    }
                </Grid>
                <Grid.Row className="no-paddings">
                    <Grid.Column width={16}>
                        {groupedHistory.map(group => (
                            <HistoryDateEntries
                                workflowType={workflowType}
                                key={group.key}
                                dateEntries={group}
                                daySelected={day}
                                showAllDetails={showAllDetails}
                                eventTypeCategory={eventTypeCategory}
                                taskRelation={taskRelation}
                            />))}
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
