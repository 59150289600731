import { Detail } from "@neworbit/simpleui-detail";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { Button, Divider, Form, Grid } from "semantic-ui-react";
import { v4 } from "uuid";
import { ManagementContactDetails } from "../model";

export interface ManagementContactDetailItemProps {
    title: string;
    managerType: string;
    managers: ManagementContactDetails[];
    allowEditing: boolean;
    addManager: (manager: ManagementContactDetails) => void;
    removeManager: (id: string) => () => void;
}

export const ManagementContactDetailItem: React.FC<ManagementContactDetailItemProps> =
({ title, managerType, managers, allowEditing,  addManager, removeManager }) => {
    const [addingManager, setAddingManager] = React.useState(false);
    const [newManager, setNewManager] = React.useState<ManagementContactDetails>();

    function showForm() {
        setAddingManager(true);
    }

    function hideForm() {
        setNewManager(undefined);
        setAddingManager(false);
    }

    function updateRegionalManagerField(field: keyof ManagementContactDetails) {
        return (value: string) => setNewManager({ ...newManager, [field]: value });
    }

    function onAddManager() {
        addManager({ ...newManager, id: v4() });
        hideForm();
    }

    function getRemoveManager(id: string) {
        return removeManager(id);
    }

    function blockAddManger() {
        return newManager ? Object.values(newManager).includes(undefined) : true;
    }

    return (
        <>
            <Grid container stackable className="nomargintop">
                <h3>{title}</h3>
                {managers.map(r => (
                    <>
                        <Detail label={<b>{`${managerType} Name`}</b>} value={r.name} labelWidth={5} valueWidth={11} />
                        <Detail label={<b>{`${managerType} Email`}</b>}  value={r.email} labelWidth={5} valueWidth={11} />
                        <Detail label={<b>{`${managerType} Number`}</b>} value={r.number} labelWidth={5} valueWidth={11} />
                        { allowEditing && <Button floated="right" negative content="Remove" icon="trash" onClick={getRemoveManager(r.id)} /> }
                        { !allowEditing && <Divider /> }
                    </>
                ))}
            </Grid>
            <div className="margin-top-bottom">
                { allowEditing && !addingManager && <><Button positive content={`Add ${managerType}`} icon="plus" onClick={showForm} /></> }

                { addingManager &&
    <Form>
        <Input.Text placeholder="Name" value={newManager?.name} onChange={updateRegionalManagerField("name")} />
        <Input.Text placeholder="Email" value={newManager?.email} onChange={updateRegionalManagerField("email")} />
        <Input.Text placeholder="Number" value={newManager?.number} onChange={updateRegionalManagerField("number")} />
        <Button className="cancel-action" content="Cancel" icon="cross" onClick={hideForm} />
        <Button positive content="Add" icon="check" onClick={onAddManager} disabled={blockAddManger()} />
    </Form>
                }
            </div></>);
};
