/* eslint-disable eqeqeq */
import * as React from "react";
import { Grid, Radio, RadioProps, Divider } from "semantic-ui-react";
import { EventType } from "@common/crud/eventType";

export interface CreationWizardProps {
    selectedEventType: EventType;
    selectedEventsPerDay: number;
    setSelectedEventsPerDay: (selectedEventsPerDay: number) => void;
}

export const DigitalEventCreationWizard: React.FC<CreationWizardProps> = ({
    selectedEventType, setSelectedEventsPerDay, selectedEventsPerDay
}) => {
    const getKeys = (dict: Dictionary<moment.Duration[]>) => dict != null ? Object.entries(dict).map(
        ([key]) => {
            return Number.parseInt(key, 10);
        }) : [0];

    const maxEventsPerDay = selectedEventType?.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions == null ? null
        : getKeys(selectedEventType?.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions);

    const onEventAmountSelection = React.useCallback((_: any, { value }: RadioProps) => {
        setSelectedEventsPerDay(value as number);
    }, [setSelectedEventsPerDay]);

    const disabledSelection = (
        <>
            <Radio disabled={true} label="1 per day" />
            <Radio disabled={true} label="2 per day" />
        </>
    );

    React.useCallback(() => {
        if (selectedEventType && maxEventsPerDay.length < selectedEventsPerDay) {
            setSelectedEventsPerDay(null);
        }
    }, [maxEventsPerDay?.length, selectedEventType, selectedEventsPerDay, setSelectedEventsPerDay]);

    return (
        <>
            <h3 className="wide-header">Choose course number model</h3>
            <Grid className="no-margin-top" padded="horizontally">
                <Grid.Row className="no-padding-top">
                    {(selectedEventType && maxEventsPerDay == null || maxEventsPerDay?.length === 0) &&
                        <Grid.Column width={16}>
                            <div className={`flex-cell ${selectedEventType == null ? "disabled" : ""}`}>
                                <span className="error-message">Scheme doesn't have configured suggested start times.
                                                    Please update scheme or change to the one that's configured properly</span>
                                <Divider />
                                {disabledSelection}
                            </div>
                        </Grid.Column>
                    }
                    {(selectedEventType === null || selectedEventType === undefined) &&
                        <Grid.Column width={16}>
                            <div className={`flex-cell ${selectedEventType == null ? "disabled" : ""}`}>
                                <span className="error-message">Please select a scheme.</span>
                                <Divider />
                                {disabledSelection}
                            </div>
                        </Grid.Column>
                    }
                    {maxEventsPerDay?.map(key => (
                        <Grid.Column width={4} key={key}>
                            <Radio
                                className="event-radio-button"
                                key={key}
                                checked={selectedEventsPerDay === key}
                                label={key}
                                value={key}
                                onChange={onEventAmountSelection}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid>
        </>
    );
};
