import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import * as actions from "./actionTypes";
import { DsaAreaListItem } from "./model";
import { OrganisationApi } from "@common/crud/organisation";

export type DsaAction =
    ({ type: actions.LOAD_DSA_AREAS_SUCCESS } & Payload<DsaAreaListItem[]>);

export function loadDsaAreas() {
    return async (dispatch: Dispatch) => {
        const result = await loadAndTrack(dispatch, "loadDsaAreas", new OrganisationApi().getDsaAreaList());
        dispatch(loadDsaAreasSuccess(result));
    };
}

export const loadDsaAreasSuccess = (dsaAreas: DsaAreaListItem[]): DsaAction => ({
    payload: dsaAreas,
    type: actions.LOAD_DSA_AREAS_SUCCESS
});
