import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form, DropdownItemProps } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";
import { OrganisationState, selectors as organisationsSelectors } from "@common/crud/organisation";
import { EditModal } from "./EditModal";
import { basePathSelector } from "@common/crud/attendee/selectors";
import { Address, optionsFromObject } from "@common/crud/common";
import { Gender } from "@common/crud/drinkDriveOffenders/model";
import { AppState } from "@common/crud/attendee/model";
import { phoneNumber } from "@common/validation";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { AttendeeEditModel } from "@common/crud/attendee/model";
import { attendeeSelector } from "@common/crud/attendee/selectors";
import { saveAttendee } from "@common/crud/attendee/actions";
import { MuiDateField } from "@common/components/MuiDateField";

export interface EditProps extends SharedEditProps<AttendeeEditModel> {
    open: boolean;
    courts: DropdownItemProps[];
    genders: DropdownItemProps[];
}

export interface DispatchProps extends SaveDispatchProps<AttendeeEditModel> {
    close: () => void;
}

export class EditForm extends EditComponent<AttendeeEditModel, EditProps> {

    public render() {
        const address: Address = {
            addressLine1: this.state.values.address?.addressLine1,
            addressLine2: this.state.values.address?.addressLine2,
            addressLine3: this.state.values.address?.addressLine3,
            postalCode: this.state.values.address?.postalCode,
            city: this.state.values.address?.city
        };

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={this.state.values.forename}
                    label="Forename"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("forename", value, valid)}
                />
                <Input.Text
                    value={this.state.values.surname}
                    label="Surname"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateProperty("surname", value, valid)}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails.dateOfBirth}
                    label="Date Of Birth"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.dateOfBirth", value, valid)}
                />
                <Input.Email
                    value={this.state.values.email}
                    label="Email"
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateProperty("email", value, valid)}
                />
                <Input.Text
                    value={this.state.values.telephone}
                    label="Telephone"
                    validation={phoneNumber()}
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateProperty("telephone", value, valid)}
                />
                <AddressLookup
                    showErrors={this.state.showErrors}
                    address={address}
                    onChange={(value, valid) => this.updateAddress(value, valid)}
                />
                <h2>Case Details</h2>
                <Input.Dropdown
                    label="Court"
                    placeholder="Court"
                    showErrors={this.state.showErrors}
                    required
                    value={this.state.values.drinkDriveOffenderDetails?.courtId}
                    options={this.props.courts}
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.courtId", value, valid)}
                    dynamicOptions
                    search
                />
                <Input.DropdownNumber
                    label="Gender"
                    placeholder="Gender"
                    showErrors={this.state.showErrors}
                    required
                    value={this.state.values.drinkDriveOffenderDetails?.gender}
                    options={this.props.genders}
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.gender", value, valid)}
                    dynamicOptions
                    search
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails?.sentenceDate}
                    label="Sentence Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.sentenceDate", value, valid)}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.disqualifiedPeriod}
                    label="Disqualified Period"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.disqualifiedPeriod", value, valid)}
                />
                <MuiDateField
                    value={this.state.values.drinkDriveOffenderDetails?.interimDate}
                    label="Interim Date"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.interimDate", value, valid)}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.reduction}
                    label="Reduction"
                    showErrors={this.state.showErrors}
                    required
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.reduction", value, valid)}
                />
                <MuiDateField
                    value={this.state.values.offerExpiry}
                    label="Completion Date"
                    required
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateProperty("offerExpiry", value, valid)}
                />
                <Input.Number
                    value={this.state.values.drinkDriveOffenderDetails?.previousConvictions}
                    label="Previous Convictions"
                    required
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.previousConvictions", value, valid)}
                />
                <Input.Dropdown
                    label="Supervising Court"
                    placeholder="Supervising Court"
                    required
                    showErrors={this.state.showErrors}
                    value={this.state.values.drinkDriveOffenderDetails?.supervisingCourtId}
                    options={this.props.courts}
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.supervisingCourtId", value, valid)}
                    dynamicOptions
                    search
                />
                <Input.Text
                    value={this.state.values.drinkDriveOffenderDetails?.classicCertificateNumber}
                    required
                    label="Classic Certificate Number"
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateNestedProperty("drinkDriveOffenderDetails.classicCertificateNumber", value, valid)}
                />
            </Form>
        );
    }

    private updateAddress = (address: Address, valid: boolean) => {
        this.updateNestedProperty("address.addressLine1", address.addressLine1, valid);
        this.updateNestedProperty("address.addressLine2", address.addressLine2, valid);
        this.updateNestedProperty("address.addressLine3", address.addressLine3, valid);
        this.updateNestedProperty("address.city", address.city, valid);
        this.updateNestedProperty("address.postalCode", address.postalCode, valid);
    };

    public submit = () => this.handleSubmit({ preventDefault: (): void => undefined } as any);
}

function mapStateToProps(state: AppState & OrganisationState) {
    const model = attendeeSelector(state) as AttendeeEditModel;
    const attendeeDOBMoment = moment(model?.drinkDriveOffenderDetails?.dateOfBirth);
    const attendeeDOBvalue = attendeeDOBMoment.isValid() ?
        attendeeDOBMoment:moment();

    if (model.drinkDriveOffenderDetails) {
        model.drinkDriveOffenderDetails.dateOfBirth = attendeeDOBvalue;
    }

    return {
        courts: organisationsSelectors.courtOrganisationOptionsSelector(state),
        genders: optionsFromObject(Gender),
        model,
        open: state.router.pathname.endsWith("/edit"),
        basePath: basePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (attendee: AttendeeEditModel, basePath: string) => dispatch(saveAttendee(attendee.eventInstanceId, attendee, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): EditProps & DispatchProps {
    return {
        model: propsFromState.model,
        open: propsFromState.open,
        courts: propsFromState.courts,
        genders: propsFromState.genders,
        save: (attendee: AttendeeEditModel) => propsFromDispatch.dispatchSave(attendee, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`)
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
