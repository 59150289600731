import * as React from "react";
import { EventInstancesWithGroupModel, MonitoredTrainer, Monitoring } from "@common/crud/eventInstanceGroup/model";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { Divider, Grid, Menu, Message, Segment, SemanticWIDTHS } from "semantic-ui-react";
import { TrainerMonitoringDisplay } from "@common/crud/eventInstance/components/monitoring/TrainerMonitoringDisplay";
import "./TrainerMonitoringGroup.css";
import { CurrentUser } from "@common/auth";
import { MonitorRole } from "@common/auth/model";
import { EventInstance } from "@common/crud/eventInstance";

interface MenuEventInstanceGroupItems {
    dayNumber: number;
    eventInstance: EventInstance;
    eventTrainers: MonitoredTrainer[];
    eventMonitor: Monitoring;
}

interface Props {
    groupId: string;
    currentUser: CurrentUser;
    isAdminApp: boolean;
    formNotAvailableMessage: string;
}

export const TrainerMonitoringGroup: React.FC<Props> = ({ groupId, currentUser, isAdminApp, formNotAvailableMessage }) => {
    const [groupEventInstances, setGroupEventInstances] = React.useState<EventInstancesWithGroupModel>();
    const [activeTabIndex, setActiveTabIndex] = React.useState<number>();
    const [groupEventInstanceItems, setGroupEventInstanceItems] = React.useState<MenuEventInstanceGroupItems[]>();

    const currentUserCanViewMonitoringForm = React.useCallback((): boolean => {
        const isMonitor = currentUser?.roles?.includes(MonitorRole);
        const currentUserIsMonitorOnCourse =
            groupEventInstances?.group.eventInstanceGroupItems.some(item => item.monitoring?.monitorTrainerId === currentUser.id);
        const currentUserIsMonitoredTrainerOnCourse =
            groupEventInstances?.group.eventInstanceGroupItems.some(item => item.monitoring?.monitoredTrainers.some(mt => mt.id === currentUser.id));
        return isMonitor || currentUserIsMonitorOnCourse || currentUserIsMonitoredTrainerOnCourse || isAdminApp;
    },[currentUser, groupEventInstances, isAdminApp]);

    React.useEffect(() => {
        async function getEventInstancesForGroup() {
            const api = new EventInstanceGroupApi();
            const eventInstanceGroup =  await api.getEventInstancesByGroupId(groupId);
            setGroupEventInstances(eventInstanceGroup);
            shapeMenuGroupEventInstanceItems(eventInstanceGroup);
            setActiveTabIndex(eventInstanceGroup.group.eventInstanceGroupItems.find(item => item.monitoring)?.dayNumber);
        }

        if (groupId) {
            getEventInstancesForGroup();
        }
    },[groupId]);

    const shapeMenuGroupEventInstanceItems = (eventInstanceGroup: EventInstancesWithGroupModel) => {
        setGroupEventInstanceItems(eventInstanceGroup?.group.eventInstanceGroupItems?.map(gei => {
            const eventInstanceById = eventInstanceGroup?.eventInstances.find(ei => ei.id === gei.eventInstanceId);
            return {
                dayNumber: gei.dayNumber,
                eventInstance: eventInstanceById,
                eventTrainers: gei.monitoring?.monitoredTrainers,
                eventMonitor: gei.monitoring
            };
        }));
    };

    if (!groupId && !groupEventInstances || !currentUserCanViewMonitoringForm()) { return null; }

    return (
        <div className="event-instance-group-monitor-container">
            <Menu fluid widths={groupEventInstanceItems?.length as SemanticWIDTHS} className="event-instance-group-monitor-menu-container">
                {groupEventInstanceItems && groupEventInstanceItems?.map((gei) => (
                    <Menu.Item
                        disabled={!gei.eventMonitor?.monitoredTrainers?.length}
                        key={gei.dayNumber}
                        name={`Day ${gei.dayNumber}`}
                        className={`border-styling ${activeTabIndex === gei.dayNumber ? "active-tab" : "normal-tab"}`}
                        active={activeTabIndex === gei.dayNumber}
                        onClick={() => setActiveTabIndex(gei.dayNumber)}
                    />
                ))}
            </Menu>
            <Segment attached='bottom'>
                {groupEventInstanceItems && groupEventInstanceItems?.map((gei) =>
                    activeTabIndex === gei.dayNumber && gei.eventMonitor && gei.eventTrainers.map((monitoredTrainer, i, monitoredTrainers) => (
                        <React.Fragment key={gei.eventInstance?.id}>
                            <Grid.Row>
                                <TrainerMonitoringDisplay
                                    eventTrainer={monitoredTrainer}
                                    eventMonitor={gei?.eventInstance?.monitorTrainers.find(mt => mt.id === gei.eventMonitor.monitorTrainerId)}
                                    eventInstance={gei?.eventInstance} />
                            </Grid.Row>
                            {(i !== monitoredTrainers.length - 1) && <Divider />}
                        </React.Fragment>
                    ))
                )}
                {
                    groupEventInstanceItems && groupEventInstanceItems?.every((gei) => !gei.eventMonitor?.monitoredTrainers?.length) &&
                    <Grid.Row>
                        <Grid.Column>
                            <Message content={formNotAvailableMessage} info icon={"info circle"} />
                        </Grid.Column>
                    </Grid.Row>
                }
            </Segment>
        </div>
    );
};
