import * as React from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";

export const error = (message: string) =>
    toast(<span><Icon name="ban" /> {message}</span>, { className: "toast-error", progressClassName: "progress" });

export const info = (message: string) =>
    toast(<span><Icon name="info" /> {message}</span>, { className: "toast-info", progressClassName: "progress" });

export const warning = (message: string) =>
    toast(<span><Icon name="warning sign" /> {message}</span>, { className: "toast-warning", progressClassName: "progress" });

export const success = (message: string) =>
    toast(<span><Icon name="checkmark" /> {message}</span>, { className: "toast-success", progressClassName: "progress" });

export const cpcSuccess = (message: string) => toast.success(message,  { className: "toast-success", icon: true, closeButton: false });

export const cpcError = (message: string) => toast.error(message,  { className: "toast-error", icon: true, closeButton: false });

export const cpcWarning = (message: string) => toast.warning(message,  { className: "toast-warning", icon: true, closeButton: false });

export const successPersistent = (message: string) =>
    toast(<span><Icon name="checkmark" /> {message}</span>, { className: "toast-success with-spacing", progressClassName: "progress", autoClose: false });

export const errorPersistent = (message: string) =>
    toast(<span><Icon name="ban" /> {message}</span>, { className: "toast-error with-spacing", progressClassName: "progress", autoClose: false });

export const informationPersistent = (message: string) =>
    toast(<span><Icon name="info" /> {message}</span>, { className: "toast-info with-spacing", progressClassName: "progress", autoClose: false });

export const importantAlertPersistent = (message: string) =>
    toast(<span><Icon name="bell" /> {message}</span>, { className: "toast-error", progressClassName: "progress", autoClose: false });
