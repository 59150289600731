import { LanguageSettings } from "./model";
import { LanguageAction } from "./actions";
import { SET_CURRENT_LANGUAGE } from "./actionTypes";

const defaultState: LanguageSettings = {
    current: "en"
};

export function reducer(state = defaultState, action: LanguageAction): LanguageSettings {
    switch (action.type) {
        case SET_CURRENT_LANGUAGE:
            return { ...state, current: action.payload };
        default:
            return state;
    }
}
