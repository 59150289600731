import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { HtmlLabelProps, SemanticShorthandItem } from "semantic-ui-react";
import { ValidationFunction } from "not-valid";

export interface EventInstanceNotesComponentProps {
    dayNumber?: number;
    label: SemanticShorthandItem<HtmlLabelProps>;
    value: string;
    validation?: ValidationFunction<string>[];
    canChange: boolean;
    onNoteChange: (dayNumber: number, value: string) => void;
}

export const EventInstanceNotesComponent: React.FC<EventInstanceNotesComponentProps> = (props: EventInstanceNotesComponentProps) => {

    const { dayNumber, label, value, onNoteChange, canChange, validation } = props;

    const onChange = React.useCallback((newValue: string) => {
        const updated = newValue ? newValue : undefined;
        onNoteChange(dayNumber, updated);
    }, [dayNumber, onNoteChange]);

    return (
        <>
            <Input.Textarea label={label} value={value} onChange={onChange} disabled={!canChange} validation={validation} />
        </>
    );
};
