import * as React from "react";
import { connect } from "react-redux";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";
import { Detail } from "@neworbit/simpleui-detail";
import { Link } from "redux-little-router";
import {
    OrganisationDetailModel,
    AppState
} from "../model";
import { organisationSelector, routeIdSelector } from "../selectors";
import { ContextMenu } from "./ContextMenu";

export interface ContactsDetailProps {
    path: string;
    organisationId: string;
    organisation: OrganisationDetailModel;
}

export class ContactsDetailInternal extends React.Component<ContactsDetailProps> {
    public render() {
        const organisation = this.props.organisation;
        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={3} as={Grid} padded>
                        <ContextMenu businessLineType={organisation.businessLineType} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Grid container stackable className="nomargintop">
                            <h2>Primary Contact</h2>
                            {organisation.organisationContact?.contacts?.map((contact, index) => (
                                <>
                                    <Detail
                                        label="Name"
                                        value={contact.name || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Email"
                                        value={contact?.email || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Telephone"
                                        value={contact?.telephone || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    {index !== organisation.organisationContact.contacts.length - 1 && (
                                        <Divider hidden className="full-width" />
                                    )}
                                </>
                            ))}
                            <Divider hidden className="full-width" />
                            <h2>Course Contact</h2>
                            {organisation.organisationCourseContact?.contacts?.map((contact, index) => (
                                <>
                                    <Detail
                                        label="Name"
                                        value={contact.name || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Email"
                                        value={contact?.email || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Telephone"
                                        value={contact?.telephone || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    {index !== organisation.organisationCourseContact.contacts.length - 1 && (
                                        <Divider hidden className="full-width" />
                                    )}
                                </>
                            ))}
                            <Divider hidden className="full-width" />
                            <h2>Finance Contact</h2>
                            {organisation.organisationFinanceContact?.contacts?.map((contact, index) => (
                                <>
                                    <Detail
                                        label="Name"
                                        value={contact.name || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Email"
                                        value={contact?.email || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    <Detail
                                        label="Telephone"
                                        value={contact?.telephone || ""}
                                        labelWidth={5}
                                        valueWidth={11}
                                    />
                                    {index !== organisation.organisationFinanceContact.contacts.length - 1 && (
                                        <Divider hidden className="full-width" />
                                    )}
                                </>
                            ))}
                            <Divider hidden className="full-width" />
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const DetailWithActions: React.FC<ContactsDetailProps> = ({ organisationId, organisation, path }) => {
    return (
        <div>
            <ContactsDetailInternal organisation={organisation} organisationId={organisationId} path={path} />
            <Divider />
            <Segment basic clearing vertical>
                <Button icon="pencil" content="Edit" floated="right" as={Link} href={`${path}/edit`} />
            </Segment>
        </div>
    );
};

function mapStateToProps(state: AppState): ContactsDetailProps {
    return {
        organisation: organisationSelector(state),
        organisationId: routeIdSelector(state),
        path: state.router.pathname
    };
}

export const NonPoliceContactsDetail = connect(mapStateToProps)(DetailWithActions);
