import * as React from "react";
import { Grid } from "semantic-ui-react";
import { PoliceNotificationListModel, PoliceNotificationType } from "../model";
import { PoliceNotificationsTimelineContent } from "./PoliceNotificationsTimelineContent";
import { PoliceNotificationsTimelineFilter } from "./PoliceNotificationsTimelineFilter";

const filters = Object.values(PoliceNotificationType).filter(n => +n > 0) as PoliceNotificationType[];

export interface PoliceNotificationsTimelineProps {
    notifications: PoliceNotificationListModel[];
}

export const PoliceNotificationsTimeline = ({ notifications }: PoliceNotificationsTimelineProps) => {
    const [activeFilters, setActiveFilters] = React.useState<PoliceNotificationType[]>(filters);

    const toggleFilter = (filter: PoliceNotificationType) => activeFilters.includes(filter) ?
        setActiveFilters(activeFilters.filter(f => f !== filter)) :
        setActiveFilters([...activeFilters, filter]);

    const allFiltersActive = activeFilters.length === filters.length;

    const selectFilters = () => setActiveFilters(allFiltersActive ? [] : filters);

    return (
        <>
            <Grid.Column width={3}>
                <Grid.Row>
                    Filters by group:
                </Grid.Row>
                {filters.map(notificationType => (
                    <PoliceNotificationsTimelineFilter
                        key={notificationType}
                        notificationType={notificationType}
                        active={activeFilters.includes(notificationType)}
                        onChange={toggleFilter}
                    />))}
                <Grid.Row>
                    <a className="show-button" onClick={selectFilters}>
                        {allFiltersActive ? "Deselect all" : "Select all"}
                    </a>
                </Grid.Row>
            </Grid.Column>
            <Grid.Column width={13}>
                <PoliceNotificationsTimelineContent
                    notifications={notifications.filter(n => activeFilters.includes(n.notificationType))}
                />
            </Grid.Column>
        </>
    );
};
