import * as React from "react";
import { AssistanceRequired, DorsBooking } from "@common/crud/dorsBooking/model";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { useState } from "react";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import "./CaAssistanceRequired.scss";
import { AssistanceRequiredComponent } from "@common/crud/dorsBooking/components/AssistanceRequiredComponent";
import { EventInstance } from "@common/crud/eventInstance";

export interface AssistanceRequiredComponentProps {
    dorsBooking: DorsBooking;
    isOfferBooking?: boolean;
    eventInstance?: EventInstance;
    showRequiresShortCompletionAssistance?: boolean;
    onUpdate: (value: AssistanceRequired) => void;
}

export const CaAssistanceRequiredComponent: React.FC<AssistanceRequiredComponentProps> = (props: AssistanceRequiredComponentProps) => {
    const { dorsBooking, showRequiresShortCompletionAssistance, isOfferBooking, onUpdate } = props;
    const [isTechnicalAssistanceRequired, setIsTechnicalAssistanceRequired] = useState(dorsBooking?.isTechnicalAssistanceRequired);
    const [requiresSupervisorAssistance, setRequiresSupervisorAssistance] = useState(dorsBooking?.requiresSupervisorAssistance);
    const [shortNoticeCompletionRequiresSpace, setShortNoticeCompletionRequiresSpace] = useState(dorsBooking?.shortNoticeCompletionRequiresSpace);
    const [technicalAssistanceComment, setTechnicalAssistanceComment] = useState(dorsBooking?.technicalAssistanceComment);
    const [supervisorAssistanceComment, setSupervisorAssistanceComment] = useState(dorsBooking?.supervisorAssistanceComment);
    const [shortNoticeRequiresSpaceComment, setShortNoticeRequiresSpaceComment] = useState(dorsBooking?.shortNoticeRequiresSpaceComment);

    const selectedEventInstance = useSelector(eventInstanceSelector);
    const eventInstance =  props.eventInstance ?? selectedEventInstance;
    const isDigitalCourse = eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;

    React.useEffect(() => {
        onUpdate({
            isTechnicalAssistanceRequired,
            requiresSupervisorAssistance,
            shortNoticeCompletionRequiresSpace,
            technicalAssistanceComment,
            supervisorAssistanceComment,
            shortNoticeRequiresSpaceComment
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isTechnicalAssistanceRequired,
        requiresSupervisorAssistance,
        shortNoticeCompletionRequiresSpace,
        technicalAssistanceComment,
        supervisorAssistanceComment,
        shortNoticeRequiresSpaceComment
    ]);

    return (
        <div className="assistance-required">
            <Authorize authorize={TtcClientAdvisorRole} >
                <div>
                    {(isDigitalCourse || isOfferBooking) && (
                        <>
                            <AssistanceRequiredComponent
                                onFlagClick={setIsTechnicalAssistanceRequired}
                                flag={isTechnicalAssistanceRequired}
                                onChange={setTechnicalAssistanceComment}
                                comment={technicalAssistanceComment}
                                label="Attendee requires technical support" />
                            <br />
                        </>
                    )}

                    <AssistanceRequiredComponent
                        onFlagClick={setRequiresSupervisorAssistance}
                        flag={requiresSupervisorAssistance}
                        onChange={setSupervisorAssistanceComment}
                        comment={supervisorAssistanceComment}
                        label="Attendee wants to speak to a supervisor" />
                    <br />

                    {showRequiresShortCompletionAssistance && (
                        <>
                            <AssistanceRequiredComponent
                                onFlagClick={setShortNoticeCompletionRequiresSpace}
                                flag={shortNoticeCompletionRequiresSpace}
                                onChange={setShortNoticeRequiresSpaceComment}
                                comment={shortNoticeRequiresSpaceComment}
                                label="Attendees has a short completion date and needs a last minute space" />
                            <br />
                        </>
                    )}
                </div>
            </Authorize>
        </div>
    );
};

CaAssistanceRequiredComponent.defaultProps = {
    showRequiresShortCompletionAssistance: true
};
