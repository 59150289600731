import * as React from "react";
import { Fragment, Location } from "redux-little-router";

import { All } from "./All";
import { useSelector } from "react-redux";
import { organisationIdSelector } from "@common/redux-helpers";
import { Detail } from "./Detail";
import { OrderStripeWrapper } from "./OrderStripeWrapper";
import { CreateBasket } from "./CreateBasket";
import { DetailsAndBilling } from "./DetailsAndBilling";
import { routeCorporateUserIdSelector } from "@common/crud/corporateUser/selectors";
import { Rebook } from "./Rebook";
import { GenesysAuthorisation } from "./GenesysAuthorisation";
import { RebookSelect } from "./RebookSelect";

export interface OrderBaseProps {
    corporateOrganisationId?: string;
    corporateUserId?: string;
}

const noOrderId = (location: Location) => location.params.orderId === undefined;

export const Base = (props: OrderBaseProps) => {
    const organisationId = useSelector(organisationIdSelector);
    const userId = useSelector(routeCorporateUserIdSelector);
    const propsWithIdsFromRoute = {
        ...props,
        corporateOrganisationId:
            props.corporateOrganisationId ?? organisationId,
        corporateUserId:
            props.corporateUserId ?? userId
    };

    return (
        <>
            <Fragment forRoute="/orders/create">
                <CreateBasket />
            </Fragment>
            <Fragment forRoute="/orders/detailsAndPayment">
                <DetailsAndBilling />
            </Fragment>
            <Fragment forRoute={"/orders/rebook"}>
                <Rebook {...propsWithIdsFromRoute} />
            </Fragment>
            <Fragment forRoute={"/orders/selectRebooking"}>
                <RebookSelect {...propsWithIdsFromRoute} />
            </Fragment>
            <Fragment forRoute={"/orders/genesysAuth"}>
                <GenesysAuthorisation />
            </Fragment>
            <Fragment forRoute={"/orders/reviewPayment"}>
                <OrderStripeWrapper />
            </Fragment>
            <Fragment forRoute="/orders" withConditions={noOrderId}>
                <All {...propsWithIdsFromRoute} />
            </Fragment>
            <Fragment forRoute="/orders/:orderId">
                <Detail />
            </Fragment>
        </>
    );
};
