import * as React from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import { LinkedBookingResponse, DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";

export interface BookButtonProps {
    disable: boolean;
    bookingId: string;
    bookingAppEndpoint: string;
    isDorsBooking?: boolean;
}

export const BookButton: React.FC<BookButtonProps> = ({ disable, bookingId, bookingAppEndpoint, isDorsBooking }) => {
    const [differentBookingsOpen, setDifferentBookingsOpen] = React.useState<boolean>(false);
    const [cancelledBookingsOpen, setCancelledBookingsOpen] = React.useState<boolean>(false);
    const [offerWithdrawnOpen, setOfferWithdrawnOpen] = React.useState<boolean>(false);
    const [dorsAttendanceStatus, setDorsAttendanceStatus] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<LinkedBookingResponse>(null);

    const url = `${bookingAppEndpoint}bookings/${bookingId}`;

    const redirect = React.useCallback(() => {
        window.location.href = url;
    }, [url]);

    const modalAction = () => (
        <Modal.Actions>
            <Button
                onClick={cancel}
                className="cancel-action"
                content="Cancel"
            />
            { response?.disableBooking !== true &&
                <Button
                    onClick={redirect}
                    icon="checkmark"
                    content="Continue"
                    loading={response === null}
                />
            }
        </Modal.Actions>);

    const book = React.useCallback(async () => {
        const bookingApi = new DorsBookingApi();

        if (!isDorsBooking) {
            redirect();
        }

        await bookingApi.lookupDorsData(bookingId);
        const result = await bookingApi.getLinkedBookings(bookingId);

        if (result.hasCancelledAttendee) {
            setResponse(result);
            setCancelledBookingsOpen(true);
        } else if (result.hasDifferentBookings) {
            setResponse(result);
            setDifferentBookingsOpen(true);
        }  else if (result.isOfferWithdrawn) {
            setResponse(result);
            setOfferWithdrawnOpen(true);
        } else if (result.dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet && isDorsBooking) {
            setDorsAttendanceStatus(true);
            setResponse(result);
        } else {
            redirect();
        }
    }, [bookingId, isDorsBooking, redirect]);

    const cancel = () => {
        setDifferentBookingsOpen(false);
        setOfferWithdrawnOpen(false);
        setCancelledBookingsOpen(false);
        setDorsAttendanceStatus(false);
    };

    const modalContent = () => {
        const hasContent = differentBookingsOpen || cancelledBookingsOpen;
        const contentLine = differentBookingsOpen ?
            <p>This attendee has another record in the system that has the same ticket ID/Driving licence reference.</p> :
            <p>This attendee has a cancellation record in the system that has the same ticket ID/Driving licence reference.</p>;
        if (hasContent) {
            return (
                <Modal.Content>
                    {contentLine}
                    <p>
                        Please review and ensure you are booking the correct record.
                    </p>
                    { response.disableBooking === true &&
                       <p>
                           This attendee has a duplicate record with the same driving licence and ticket ID.
                       Please ensure you update the original record with the new attendance ID. Completing this
                        action will allow you to book the original record.
                       </p>

                    }

                    <Header size="medium">Found booking details:</Header>
                    <p><b>Name:</b> {response?.name}</p>
                    <p><b>Attendance Id:</b> {response?.attendanceId}</p>
                    <p><b>Issued by police force name:</b> {response?.issuedBy}</p>
                </Modal.Content>
            );
        }
        return <></>;
    };

    const renderModal = () => {
        const openSesame = differentBookingsOpen || cancelledBookingsOpen || offerWithdrawnOpen || dorsAttendanceStatus;
        const modalHeader = differentBookingsOpen || cancelledBookingsOpen ? "Found booking with same ticket id/driving licence id" :
            offerWithdrawnOpen ? "Attendee's DORS status is offer withdrawn. Are you sure you want to book them?" :
                "Attendee's DORS status is unknown. Are you sure you want to book them?";
        return (
            <>
                <Modal open={openSesame}>
                    <Modal.Header>
                        {modalHeader}
                    </Modal.Header>
                    {modalContent()}
                    {modalAction()}
                </Modal>
            </>
        );
    };

    return (
        <>
            <Button onClick={book} disabled={disable}>
                Book
            </Button>

            {renderModal()}
        </>
    );
};
