import * as React from "react";
import { Confirm, Grid, Icon, Form, InputProps, TextAreaProps, CheckboxProps, InputOnChangeData,
    DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { InputType } from "./SettingsGrid";
import { AppSettingInput } from "./AppSettingInput";

interface AppSettingLikeEditProps {
    label: string;
    value: string;
    isCreate: boolean;
    inputType: InputType;
    dropdownNumberOptions?: DropdownItemProps[];
    bringCheckClose?: boolean;
    done: () => void;
    saveSetting: (value: string) => void;
    deleteSetting: () => void;
    validate?: (value: string | string[] | { value: string; text: string }[]) => string[];
}

interface AppSettingLikeEditState {
    value: string;
    dirty: boolean;
    deleting: boolean;
    saving: boolean;
    valid: boolean;
    errors: string[];
}

const cancelButtonProps = { content: "Cancel", className: "cancel-action" };

export class AppSettingLikeEdit extends React.Component<AppSettingLikeEditProps, AppSettingLikeEditState> {
    constructor(props: AppSettingLikeEditProps) {
        super(props);
        this.state = {
            value: props?.value ? String(props.value) : "",
            dirty: false,
            deleting: false,
            saving: false,
            valid: true,
            errors: []
        };
    }

    public render() {
        const { inputType, isCreate, label, dropdownNumberOptions, bringCheckClose } = this.props;
        const { value, saving, deleting, dirty, valid, errors } = this.state;
        return (
            <Grid.Row className="app-setings-like">
                <Grid.Column width={4}>
                    <h4>{label}</h4>
                </Grid.Column>
                <Grid.Column width={bringCheckClose && inputType === InputType.Toggle ? 2 : 10}>
                    <Form onSubmit={this.save}>
                        <AppSettingInput
                            inputType={inputType}
                            value={value}
                            dropdownNumberOptions={dropdownNumberOptions}
                            onChange={this.onChange}
                            onMarkdownChange={this.onMarkdownChange}
                            onToggleChange={this.onToggleChange}
                            onNumberChange={this.onNumberChange}
                            onDropdownNumberChange={this.onDropdownNumberChange}
                        />
                        {errors && errors.length > 0 && <div className="ui red pointing above basic label">{errors.join(", ")}</div>}
                    </Form>
                </Grid.Column>
                <Grid.Column width={2}>
                    {dirty && valid && <Icon link name="check" onClick={this.validatePassword} />}
                    {inputType !== InputType.Toggle && (isCreate
                        ? <Icon link name="cancel" onClick={this.cancel} />
                        : <Icon link name="trash" onClick={this.delete} />)}
                </Grid.Column>
                <Confirm
                    open={deleting}
                    header={`Delete ${label}`}
                    cancelButton={cancelButtonProps}
                    onConfirm={this.confirmDelete}
                    onCancel={this.cancelAction}
                />
                <Confirm
                    open={saving}
                    header={`Save ${label}`}
                    cancelButton={cancelButtonProps}
                    onConfirm={this.save}
                    content={"Are you sure that you want to save a password that contains trailing asterisk characters?"}
                    onCancel={this.cancelAction}
                />
            </Grid.Row>
        );
    }

    private onChange = (event: any, { value }: InputProps | TextAreaProps) => this.setState({ value, dirty: true, valid: !!value });

    private onToggleChange = (_: any, { checked }: CheckboxProps) => this.setState({ value: checked?.toString() ?? "", dirty: true });

    private onNumberChange = (_: any, { value }: InputOnChangeData) => {
        if (value !== "" && !/^(0|[1-9]\d*)$/.test(value)) {
            return;
        }

        this.setState({ value, dirty: true });
    };

    private onDropdownNumberChange = (_: any, { value }: DropdownProps) => this.setState({ value: value.toString(), dirty: true });

    private onMarkdownChange = (value: string) => this.setState({ value, dirty: true });

    private validatePassword = () => {
        const { value } = this.state;
        if (value.endsWith("**********")) {
            this.setState({ saving: true });
        } else {
            this.save();
        }
    };

    private save = () => {
        const { value } = this.state;

        if (this.props.validate) {
            const errors = this.props.validate(this.state.value);
            if (errors && errors.length > 0) {
                this.setState({ ...this.state, errors, saving: false });
                return;
            }
        }

        this.props.saveSetting(value);
        this.setState({ dirty: false, saving: false, errors: [] });
        this.props.done();
    };

    private cancel = () => this.props.done();

    private delete = () => this.setState({ deleting: true });

    private confirmDelete = () => {
        this.props.deleteSetting();
        this.setState({ deleting: false });
    };

    private cancelAction = () => this.setState({ deleting: false, saving: false });
}
