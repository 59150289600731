/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { Input } from "@neworbit/simpleui-input";
import moment from "moment";
import * as React from "react";
import { Button, CheckboxProps, Confirm, Divider, Grid, Icon } from "semantic-ui-react";
import { validators } from "not-valid";
import { AttendeeListModel } from "@common/crud/attendee";
import "./UpdateDelegatesTable.scss";
import { AttendeeIdType, AttendeeIdTypeEnum, AttendeeTitleEnum, CompletionState, ReasonIssue, ReasonIssueEnum } from "@common/crud/attendee/model";
import { debounce } from "lodash";
import { useRegisterContext } from "../register/register-context";
import { EventInstanceApi } from "../../eventInstanceApi";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { constructionOrganisationsSelector } from "@common/crud/organisation/selectors";
import { phoneNumberWithSpaces } from "@common/validation";
import { EventInstanceAttendeeField } from "../../model";
import { EmailApi } from "@common/email/emailApi";
import { Department } from "@common/crud/organisation/model";
import { MxEmailCheckResult } from "@common/crud/email/model";
import { toast } from "@common/toasts";
import { ConstructionDelegateBasicDetails } from "./delegate-register-components/ConstructionDelegateBasicDetails";
import { ConstructionDelegateStateToggle } from "./delegate-register-components/ConstructionDelegateStateToggle";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";
import { eventInstanceSelector } from "../../selectors";
import { ProductCategoryEnum } from "@common/crud/eventType/model";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { Address } from "@common/crud/common/Address";
import { optionsFromObjectIncludingZero } from "@common/crud/common/optionsMappers";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";

interface ConstructionUpdateDelegatesTableRowProps {
    delegate: AttendeeListModel;
    isEditable?: boolean;
    eventInstanceId?: string;
    isStageTwo: boolean;
    isEventInstanceOpen: boolean;
    isEventInstanceMultiDay: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
    resubmissionRequired?: boolean;
    showCompletionError?: boolean;
    canChangeDelegateAmount?: boolean;
    submitted: boolean;
    updateDelegate: (delegate: AttendeeListModel, updatedDelegate: AttendeeListModel, forceImmediateSave?: boolean) => void;
    removeDelegate: (delegate: AttendeeListModel) => void;
    updateFormErrorsOnFieldChange: (delegate: AttendeeListModel, formField: keyof AttendeeListModel, valid: boolean) => void;
    setResubmissionRequired?: () => void;
    fetchingRowDataInProgress: (delegateId: string) => void;
    fetchingRowDataFinished: (delegateId: string) => void;
}

const cancelProps = { content: "Cancel", className: "cancel-action" };
const confirmProps = { content: "Confirm" };

export const ConstructionUpdateDelegatesTableRow: React.FC<ConstructionUpdateDelegatesTableRowProps> =
({ delegate, isEditable, eventInstanceId, updateDelegate, updateFormErrorsOnFieldChange, isStageTwo, canChangeDelegateAmount,
    resubmissionRequired, setResubmissionRequired, showCompletionError, removeDelegate, isEventInstanceOpen,
    fetchingRowDataInProgress, fetchingRowDataFinished, submitted, isEventInstanceMultiDay, eventInstanceGroupDays }) => {
    const [updatingCompletion, setUpdatingCompletion] = React.useState(false);
    const [loadedAttendeeFields, setLoadedAttendeeFields] = React.useState<Record<string, EventInstanceAttendeeField[]>>();
    const [loadedDepartments, setLoadedDepartments] = React.useState<Record<string, Department[]>>();
    const [removingAttendee, setRemovingAttendee] = React.useState<boolean>(false);
    const [emailChecked, setEmailChecked] = React.useState<{value: string; valid: boolean; result: MxEmailCheckResult; exception: boolean}>(undefined);

    const checkEmail = React.useCallback(async (value: string, valid: boolean) => {
        if (valid) {
            try {
                const emailApi = new EmailApi();
                const mxEmailCheckResult = await emailApi.checkEmail(value);
                setEmailChecked({ value, valid, result: mxEmailCheckResult, exception: false });
            } catch {
                setEmailChecked({ value, valid, result: undefined, exception: true });
            }
        } else {
            setEmailChecked({ value, valid, result: undefined, exception: false });
        }
    }, []);

    const checkEmailDebounced = React.useMemo(() => debounce(checkEmail, 1500), [checkEmail]);

    React.useEffect(() => {
        if (emailChecked !== undefined) {
            if (emailChecked.valid) {
                if (!emailChecked.exception) {
                    updateDelegate(delegate, { ...delegate, email: emailChecked.value, mxEmailCheckResult: emailChecked.result });
                    updateFormErrorsOnFieldChange(delegate, "email", emailChecked.value ? emailChecked.result?.valid : true);
                } else {
                    toast.error("An error occurred while checking the email address.");
                }
                fetchingRowDataFinished(`${delegate.id}_emailCallDebounced`);
            } else {
                updateFormErrorsOnFieldChange(delegate, "email", false);
                fetchingRowDataFinished(`${delegate.id}_emailCallDebounced`);
            }
            setEmailChecked(undefined);
        }
    }, [delegate, emailChecked, fetchingRowDataFinished, updateDelegate, updateFormErrorsOnFieldChange]);

    const app = useSelector(appSelector);
    const isAdminApp = app === Apps.Admin;
    const isTrainerApp = app === Apps.Trainer;

    const eventInstance = useSelector(eventInstanceSelector);
    const showScoreColumn = React.useMemo(() => eventInstance?.productCategory === ProductCategoryEnum.StandardAps && isStageTwo,
        [eventInstance?.productCategory, isStageTwo]);
    const isCitb = React.useMemo(() => eventInstance?.productCategory === ProductCategoryEnum.StandardCitb, [eventInstance?.productCategory]);
    const isDigital = React.useMemo(() => eventInstance?.eventInstanceDeliveryType === DeliveryTypeEnum.Digital, [eventInstance]);

    const organisations = useSelector(constructionOrganisationsSelector);

    const { eventInstanceOptions } = useRegisterContext();
    const { hasBeenSubmitted, attendeeFields, organisationId } = eventInstanceOptions;

    React.useEffect(() => {
        setLoadedAttendeeFields(attendeeFields);
    }, [attendeeFields]);

    const addAttendeeFieldsToLoadedFields = React.useCallback((usedOrganisationId: string, fields: EventInstanceAttendeeField[]) => {
        const extendedLoadedAttendeeFields = { ...loadedAttendeeFields };
        extendedLoadedAttendeeFields[usedOrganisationId] = fields;
        setLoadedAttendeeFields(extendedLoadedAttendeeFields);
    }, [loadedAttendeeFields]);

    const addDepartmentsToLoadedDepartments = React.useCallback((usedOrganisationId: string, departments: Department[]) => {
        const extendedLoadedDepartments = { ...loadedDepartments };
        extendedLoadedDepartments[usedOrganisationId] = departments;
        setLoadedDepartments(extendedLoadedDepartments);
    }, [loadedDepartments]);

    const onTitleChange = React.useCallback((value: AttendeeTitleEnum) => {
        updateDelegate(delegate, { ...delegate, title: value });
    }, [delegate, updateDelegate]);

    const onForenameChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "forename", valid);
        updateDelegate(delegate, { ...delegate, forename: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onSurnameChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "surname", valid);
        updateDelegate(delegate, { ...delegate, surname: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDateOfBirthChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "dateOfBirth", valid);
        updateDelegate(delegate, { ...delegate, dateOfBirth: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onNationalInsuranceNumberChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "nationalInsuranceNumber", valid);
        updateDelegate(delegate, { ...delegate, nationalInsuranceNumber: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDaysChange = React.useCallback((value: string[]) => {
        updateFormErrorsOnFieldChange(delegate, "eventInstanceIds", value.length > 0);
        if (delegate.eventInstanceIds !== value) {
            updateDelegate(delegate, { ...delegate, eventInstanceIds: value });
        }
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onOrganisationChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "organisationId", valid);
        if (delegate.organisationId !== value) {
            const organisation = organisations.find(o => o.id === value);
            updateDelegate(delegate, { ...delegate, organisationId: value, organisationName: organisation?.name, attendeeFieldValues: [] });
        }
    }, [delegate, organisations, updateDelegate, updateFormErrorsOnFieldChange]);

    const onEmailChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "email", valid);
        updateDelegate(delegate, { ...delegate, email: value });
        fetchingRowDataInProgress(`${delegate.id}_emailCallDebounced`);
        checkEmailDebounced(value, valid);
    }, [checkEmailDebounced, delegate, fetchingRowDataInProgress, updateDelegate, updateFormErrorsOnFieldChange]);

    const onEmailBlur = React.useCallback(() => {
        checkEmailDebounced.flush();
    }, [checkEmailDebounced]);

    const onTelephoneChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "telephone", valid);
        updateDelegate(delegate, { ...delegate, telephone: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onAddressChange = React.useCallback((value: Address) => {
        updateFormErrorsOnFieldChange(delegate, "address", true);
        updateDelegate(delegate, { ...delegate, address: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onScoreChange = React.useCallback((value: number, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "score", valid);
        updateDelegate(delegate, { ...delegate, score: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onCitbRegistrationNumberChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "citbRegistrationNumber", valid);
        updateDelegate(delegate, { ...delegate, citbRegistrationNumber: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onCitbLevyNumberChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "citbLevyNumber", valid);
        updateDelegate(delegate, { ...delegate, citbLevyNumber: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onIdTypeChange = React.useCallback((value: AttendeeIdTypeEnum) => {
        updateDelegate(delegate, { ...delegate, idType: value });
    }, [delegate, updateDelegate]);

    const onIdLast4DigitsChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "idLast4Digits", valid);
        updateDelegate(delegate, { ...delegate, idLast4Digits: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onAttendeeFieldValueChange = React.useCallback((value: any, fieldConfiguration: EventInstanceAttendeeField) => {
        updateDelegate(delegate,
            { ...delegate,
                attendeeFieldValues: [
                    ...(delegate.attendeeFieldValues || []).filter(afv => afv.name !== fieldConfiguration.fieldId),
                    { name: fieldConfiguration.fieldId, value, displayName: fieldConfiguration.displayName, type: fieldConfiguration.type } ]
            }
        );
    }, [delegate, updateDelegate]);

    const onDepartmentsChange = React.useCallback((value: string[], valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "departments", valid);
        updateDelegate(delegate, { ...delegate, departments: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const handleAttendedChange = React.useCallback((_: any, data: any) => {
        updateDelegate(delegate, { ...delegate, didAttend: data.checked }, true);
    }, [delegate, updateDelegate]);

    const fetchingAddressInProgress = React.useCallback((id: string) => {
        fetchingRowDataInProgress(`${delegate.id}_${id}`);
    }, [delegate, fetchingRowDataInProgress]);

    const fetchingAddressFinished = React.useCallback((id: string) => {
        fetchingRowDataFinished(`${delegate.id}_${id}`);
    }, [delegate, fetchingRowDataFinished]);

    const organisationOptions = React.useMemo(() => {
        return organisations
            .filter(organisation => ((delegate.organisationId && organisation.id === delegate.organisationId) || !organisation.expiryDate
                || organisation.expiryDate > moment.utc()) && organisation.corporateOrganisationData && !organisation.corporateOrganisationData.openCourse)
            .map(organisation => ({ text: organisation.name, value: organisation.id }));
    }, [delegate?.organisationId, organisations]);

    React.useEffect(() => {
        if (!resubmissionRequired) {
            setUpdatingCompletion(false);
        }
    }, [resubmissionRequired]);

    const onUpdateCompletion = React.useCallback(async () => {
        const api = new EventInstanceApi();
        await api.setUpdating(delegate.eventInstanceId, true);
        setUpdatingCompletion(true);
        setResubmissionRequired();
    }, [delegate.eventInstanceId, setResubmissionRequired]);

    const completionState = React.useMemo(() => delegate.didAttend
        ? delegate.isBookingCanceled ? delegate.completed : delegate?.completed
        : CompletionState.NotCompleted
    , [delegate?.completed, delegate?.didAttend, delegate?.isBookingCanceled]);
    const disableRow = React.useMemo(() => hasBeenSubmitted && !updatingCompletion, [hasBeenSubmitted, updatingCompletion]);
    const completionDisabled = React.useMemo(() => !delegate.didAttend || disableRow, [delegate?.didAttend, disableRow]);

    const handleCompletedChange = React.useCallback((_: any, data: CheckboxProps) => {
        updateDelegate(delegate, { ...delegate, completed: +data.value }, !updatingCompletion);
    }, [delegate, updateDelegate, updatingCompletion]);

    const hasIssues = React.useMemo(() => showCompletionError &&
        (delegate.completed === CompletionState.NotCompleted || delegate.didAttend === false) &&
        delegate.reasonIssue === ReasonIssueEnum.None, [delegate?.completed, delegate?.didAttend, delegate?.reasonIssue, showCompletionError]);
    const reasonIssue = React.useMemo(() => delegate.isBookingCanceled ? delegate.reasonIssue : delegate.reasonIssue,
        [delegate?.isBookingCanceled, delegate?.reasonIssue]);
    const issueText = React.useMemo(() => delegate.isBookingCanceled ? delegate.issue : delegate.issue, [delegate?.isBookingCanceled, delegate?.issue]);

    const onAttendeeRegisterUpdated = React.useCallback((updatedDelegate: AttendeeListModel) => {
        updateDelegate(delegate, updatedDelegate,  !updatingCompletion);
    }, [delegate, updateDelegate, updatingCompletion]);

    const onRemoveClick = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(true);
    }, []);

    const confirmRemove = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(false);
        removeDelegate(delegate);
    }, [delegate, removeDelegate]);

    const cancelRemove = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(false);
    }, []);

    const removeAttendeeeIdentification = React.useMemo(() => {
        let delegateIdentification = "";
        if (delegate.forename) {
            delegateIdentification = delegate.forename;
        }

        if (delegate.surname) {
            delegateIdentification = delegateIdentification ? `${delegateIdentification} ${delegate.surname}` : delegate.surname;
        }

        if (delegate.drivingLicenceNumber) {
            delegateIdentification = delegateIdentification ? `${delegateIdentification} (${delegate.drivingLicenceNumber})` : delegate.drivingLicenceNumber;
        }

        return delegateIdentification;
    }, [delegate]);

    const attendeeColumnWidth = React.useMemo(() => {
        if (isEditable) {
            return isCitb ? 6 : 8;
        } else if (isTrainerApp) {
            if (showScoreColumn) {
                return 10;
            } else if (isCitb) {
                return 5;
            }
            else {
                return 12;
            }
        } else {
            if (showScoreColumn) {
                return 5;
            }
            else if (isCitb) {
                return 5;
            }
            else {
                return 7;
            }
        }

    }, [isEditable, isTrainerApp, isCitb, showScoreColumn]);

    const contactDetailsColumnWidth = React.useMemo(() => {
        return isEditable ? (isCitb ? 6 : 8) : (isCitb ? 5 : 5);
    }, [isCitb, isEditable]);

    const canRemoveDelegate = React.useMemo(() =>
        isEditable && !isCitb && ((isAdminApp && canChangeDelegateAmount) || delegate.notSaved),
    [canChangeDelegateAmount, delegate.notSaved, isAdminApp, isEditable, isCitb]);

    return (
        (<>
            <Confirm
                open={removingAttendee}
                header="Remove delegate"
                content={`Are you sure you want to remove delegate ${removeAttendeeeIdentification}?`}
                cancelButton={cancelProps}
                confirmButton={confirmProps}
                onConfirm={confirmRemove}
                onCancel={cancelRemove}
            />
            <Grid.Row className={delegate.isBookingCanceled ? "light-grey full-width-input-form" : "full-width-input-form"}>
                <Grid.Column computer={attendeeColumnWidth} tablet={12} mobile={12}>
                    <ConstructionDelegateBasicDetails
                        isEventInstanceMultiDay={isEventInstanceMultiDay}
                        isEventInstanceOpen={isEventInstanceOpen}
                        organisationId={organisationId}
                        isEditable={isEditable}
                        delegate={delegate}
                        organisationOptions={organisationOptions}
                        onOrganisationChange={onOrganisationChange}
                        onTitleChange={onTitleChange}
                        onForenameChange={onForenameChange}
                        isTrainerApp={isTrainerApp}
                        eventInstanceId={eventInstanceId}
                        onSurnameChange={onSurnameChange}
                        onDateOfBirthChange={onDateOfBirthChange}
                        onNationalInsuranceNumberChange={onNationalInsuranceNumberChange}
                        isStageTwo={isStageTwo}
                        hasIssues={hasIssues}
                        disableRow={disableRow}
                        onAttendeeRegisterUpdated={onAttendeeRegisterUpdated}
                        attendeeFields={loadedAttendeeFields}
                        onAttendeeFieldValueChange={onAttendeeFieldValueChange}
                        addAttendeeFieldsToLoadedFields={addAttendeeFieldsToLoadedFields}
                        departments={loadedDepartments}
                        onDepartmentsChange={onDepartmentsChange}
                        addDepartmentsToLoadedDepartments={addDepartmentsToLoadedDepartments}
                        submitted={submitted}
                        eventInstanceGroupDays={eventInstanceGroupDays}
                        onDaysChange={onDaysChange}
                        canRemoveDelegate={canRemoveDelegate}
                        onRemoveClick={onRemoveClick}
                    />
                </Grid.Column>
                <Grid.Column only="tablet mobile">
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                </Grid.Column>
                {(!isTrainerApp || (isTrainerApp && isCitb)) &&
                    <>
                        <Grid.Column
                            computer={contactDetailsColumnWidth}
                            tablet={12}
                            mobile={12}
                            className="contact-details"
                        >
                            <Grid>
                                <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                    <Grid.Row className="purple-header">
                                        Email
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                    <Grid.Column>
                                        {isEditable
                                            ? <Input.Text
                                                value={delegate.email ?? ""}
                                                onChange={onEmailChange}
                                                onBlur={onEmailBlur}
                                                placeholder={"Email"}
                                                disabled={isEventInstanceOpen && !delegate.organisationId}
                                                validation={[validators.validEmail()]}
                                                showErrors={submitted}
                                            />
                                            : delegate.email
                                                ? <span className="break-all">{delegate.email}</span>
                                                : <Icon className="validation-icon" name={"exclamation circle"} />
                                        }
                                        {delegate.email && delegate.mxEmailCheckResult && !delegate.mxEmailCheckResult.valid && (
                                            <p><Icon className="validation-icon" name={"exclamation circle"} />{delegate.mxEmailCheckResult.error}</p>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                    <Grid.Row className="purple-header">
                                        Contact number
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                    <Grid.Column>
                                        {isEditable
                                            ? <Input.Text
                                                value={delegate.telephone ?? ""}
                                                onChange={onTelephoneChange}
                                                placeholder={"Contact number"}
                                                validation={phoneNumberWithSpaces()}
                                                disabled={isEventInstanceOpen && !delegate.organisationId}
                                                showErrors={submitted}
                                            />
                                            : delegate.telephone
                                                ? <span className="break-all">{delegate.telephone}</span>
                                                : <Icon className="validation-icon" name={"exclamation circle"} />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                {(isCitb && isDigital) && (
                                    <>
                                        <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                            <Grid.Row className="purple-header">
                                                Address
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                            <Grid.Column>
                                                {isEditable
                                                    ? <AddressLookup
                                                        address={delegate.address}
                                                        onChange={onAddressChange}
                                                        disabled={isEventInstanceOpen && !delegate.organisationId}
                                                        required={false}
                                                        showErrors={submitted}
                                                        compact
                                                        fetchingDataInProgress={fetchingAddressInProgress}
                                                        fetchingDataFinished={fetchingAddressFinished}
                                                    />
                                                    : (<>
                                                        {delegate.address?.addressLine1
                                                            && <span className="break-all">{delegate.address.addressLine1}<br /></span>}
                                                        {delegate.address?.addressLine2
                                                            && <span className="break-all">{delegate.address.addressLine2}<br /></span>}
                                                        {delegate.address?.addressLine3
                                                            && <span className="break-all">{delegate.address.addressLine3}<br /></span>}
                                                        {delegate.address?.city
                                                            && <span className="break-all">{delegate.address.city}<br /></span>}
                                                        {delegate.address?.postalCode
                                                            && <span className="break-all">{delegate.address.postalCode}<br /></span>}
                                                    </>)
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                            </Grid>
                        </Grid.Column>
                        <Grid.Column only="tablet mobile" className={"padding-bottom"}>
                            <Grid.Row>
                                <Divider />
                            </Grid.Row>
                        </Grid.Column>
                    </>
                }
                {isCitb &&
                    <Grid.Column
                        computer={4}
                        tablet={12}
                        mobile={12}
                        className="contact-details"
                    >
                        <Grid>
                            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                <Grid.Row className="purple-header">
                                    CITB Details
                                </Grid.Row>
                            </Grid.Column>
                            {isDigital && (
                                <>
                                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                        <Grid.Column>
                                            {isEditable
                                                ? <Input.Text
                                                    value={delegate.citbRegistrationNumber ?? ""}
                                                    onChange={onCitbRegistrationNumberChange}
                                                    placeholder={"CITB Registration Number"}
                                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                                    showErrors={submitted}
                                                />
                                                : delegate.citbRegistrationNumber
                                                    ? <span className="break-all">{delegate.citbRegistrationNumber}</span>
                                                    : <Icon className="validation-icon" name={"exclamation circle"} />
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                        <Grid.Column>
                                            {isEditable
                                                ? <Input.Text
                                                    value={delegate.citbLevyNumber ?? ""}
                                                    onChange={onCitbLevyNumberChange}
                                                    placeholder={"Company Levy Number"}
                                                    disabled={isEventInstanceOpen && !delegate.organisationId}
                                                    showErrors={submitted}
                                                    validation={[validators.validLength({ max: 20 }), validators.validAlphanumeric()]}
                                                />
                                                : delegate.citbLevyNumber
                                                    ? <span className="break-all">{delegate.citbLevyNumber}</span>
                                                    : <Icon className="validation-icon" name={"exclamation circle"} />
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </>
                            )}
                            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                <Grid.Column>
                                    {isEditable
                                        ? <Input.DropdownNumber
                                            value={delegate.idType}
                                            onChange={(value, _) => onIdTypeChange(value)}
                                            placeholder={"ID Type"}
                                            options={optionsFromObjectIncludingZero(AttendeeIdType)}
                                            disabled={isEventInstanceOpen && !delegate.organisationId}
                                            showErrors={submitted} />
                                        : delegate.idType
                                            ? <span className="break-all">{AttendeeIdType[delegate.idType]}</span>
                                            : <Icon className="validation-icon" name={"exclamation circle"} />}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                <Grid.Column>
                                    {isEditable
                                        ? <Input.Text
                                            value={delegate.idLast4Digits ?? ""}
                                            onChange={onIdLast4DigitsChange}
                                            placeholder={"ID Last 4 Digits"}
                                            validation={[validators.validLength({ min: 4, max: 4, message: "Please enter exactly 4 digits" })]}
                                            disabled={isEventInstanceOpen && !delegate.organisationId}
                                            showErrors={submitted}
                                        />
                                        : delegate.idLast4Digits
                                            ? <span className="break-all">{delegate.idLast4Digits}</span>
                                            : <Icon className="validation-icon" name={"exclamation circle"} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            {(isEditable && isCitb && ((isAdminApp && canChangeDelegateAmount) || delegate.notSaved)) && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button type="button" onClick={onRemoveClick} content="- Remove delegate" className="link-button" />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Grid.Column>}
                {!isEditable && showScoreColumn &&
                    <Grid.Column
                        computer={2}
                        tablet={12}
                        mobile={12}
                        className="contact-details"
                    >
                        <Grid>
                            <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                <Grid.Row className="purple-header">
                                    Score
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Row className={"no-padding"}>
                                <Grid.Column>
                                    <Input.Number
                                        value={delegate.score}
                                        disabled={completionDisabled}
                                        onChange={onScoreChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>}
                {!isEditable &&
                    <ConstructionDelegateStateToggle
                        isStageTwo={isStageTwo}
                        showCompletionError={showCompletionError}
                        completionState={completionState}
                        delegate={delegate}
                        completionDisabled={completionDisabled}
                        handleCompletedChange={handleCompletedChange}
                        hasBeenSubmitted={hasBeenSubmitted}
                        updatingCompletion={updatingCompletion}
                        onUpdateCompletion={onUpdateCompletion}
                        handleAttendedChange={handleAttendedChange}
                        isEditable={isEditable}
                        hasIssues={hasIssues}
                        disableRow={disableRow}
                        onAttendeeRegisterUpdated={onAttendeeRegisterUpdated}
                        forcedEventInstanceProcessing={eventInstanceOptions.forcedEventInstanceProcessing}
                        columnWidth={isCitb ? 2 : 4}
                    />}

            </Grid.Row>
            {(!isEditable && reasonIssue !== ReasonIssueEnum.None) && (
                <Grid.Row>
                    <Grid.Column>
                        <Grid.Row className="no-padding">
                            <Grid.Column width="16">
                                <span className="raised-issue-detail"><b>Reason:</b> {ReasonIssue[reasonIssue]}</span>
                            </Grid.Column>
                        </Grid.Row>
                        {issueText &&
                            <Grid.Row className="no-padding">
                                <Grid.Column width="16">
                                    <span className="raised-issue-detail">{issueText}</span>
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </Grid.Column>
                </Grid.Row>
            )}
        </>)
    );
};

