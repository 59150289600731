import React from "react";
import { Controller, FieldError, FieldPath, FieldPathValue, FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form";
import { Input } from "./Input";
interface InputFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>{
    name: TName;
    rules?: Omit<RegisterOptions<FieldValues, Path<FieldValues>>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
    placeholder?: string;
    isRequired?: boolean;
    className?: string;
    additionalOnChange?: (value: FieldPathValue<TFieldValues, TName>) => void;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    error?: FieldError;
    useGenericInput?: boolean;
    icon?: React.ReactElement;
}

export function InputField<T extends FieldValues>({
    name,
    rules,
    placeholder,
    isRequired,
    className,
    additionalOnChange,
    isDisabled,
    isReadOnly,
    error,
    icon
}: InputFieldProps<T>) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={""}
            render={function({ field }) {

                function onChange(e: any) {
                    field.onChange(e);

                    if (additionalOnChange) {
                        additionalOnChange(e);
                    }

                };

                return (
                    <>
                        <Input
                            className={className}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            isReadOnly={isReadOnly}
                            isRequired={isRequired}
                            error={error?.message}
                            icon={icon}
                            {...field}
                            onChange={onChange} />
                    </>);
            }}
        />
    );
}

