import { ExtendedEventInstance, ExtendedEventInstanceGroup } from "@common/crud/eventInstance/model";
import * as React from "react";

export const errorMessageDisplay = (e: ExtendedEventInstanceGroup | ExtendedEventInstance) => {
    return (
        <>
            {e.errorMessageDuringPublish &&
                <>
                    <span>{e.errorMessageDuringPublish}</span>
                    <br />
                </>}
            {"errorMessageDuringUpdateTrainers" in e && e.errorMessageDuringUpdateTrainers &&
                <span>{e.errorMessageDuringUpdateTrainers}</span>}
            {
                e.errorMessageDuringUpdateDorsOpenPlacesCount && <span>{e.errorMessageDuringUpdateDorsOpenPlacesCount}</span>
            }
        </>
    );
};
