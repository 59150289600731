import * as React from "react";
import { DropdownDivider, Form } from "semantic-ui-react";
import moment from "moment";
import { GetDropDownItemFromMoment, GetMomentFromDropDownItem, GetNextTwelveMonthsDropDownOptions } from "./GetNextTwelveMonthsDropDownOptions";
import { useSelector } from "react-redux";
import { digitalPlanningSessionsSelector } from "../../selectors";
import { DdrsDigitalPlanningOverviewFilter } from "./DdrsDigitalPlanningOverview";

export interface FiltersProps {
    disabled: boolean;
    months: moment.Moment[];
    onFilterChange: (filter: DdrsDigitalPlanningOverviewFilter) => void;
    hideCreateCoursesButton?: boolean;
}

export const MultiMonthDropDown: React.FC<FiltersProps> = ({ disabled, onFilterChange, months }) => {

    const onMonthChange = React.useCallback((_: any, { value }) => {
        onFilterChange({ months: value?.map((v: string) => GetMomentFromDropDownItem(v)) });
    }, [onFilterChange]);

    const planningSessions = useSelector(digitalPlanningSessionsSelector);

    const monthOptions = GetNextTwelveMonthsDropDownOptions(planningSessions);
    const values = months?.map(m => GetDropDownItemFromMoment(m));
    return (
        <>
            <Form.Dropdown
                selection
                label={"Choose Months"}
                floating
                disabled={disabled}
                placeholder="Months"
                value={values}
                options={monthOptions}
                onChange={onMonthChange}
                search
                multiple
            />
            <DropdownDivider />
        </>
    );
};
