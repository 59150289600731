import { Area } from "@common/crud/eventInstance/model";
import { Trainer } from "@common/crud/trainer";
import { Spinner } from "@common/global";
import moment from "moment";
import * as React from "react";
import { DayContentProps, DayPicker } from "react-day-picker";
import { AllItems } from "../../AllItems";
import { Button, Container, Divider, Grid } from "semantic-ui-react";
import { AssignRegionalCoordinatorsModal } from "./AssignRegionalCoordinatorsModal";
import { MonitorTrainerAvailabilityForDay, RCTrainerType, RegionalCoordinatorCalendar, RegionalCoordinatorCalendarDay, TrainerNamesForDay } from "./model";
import { RegionalCoordinatorApi } from "./RegionalCoordinatorApi";
import "./RegionalCoordinatorCalendarStyle.scss";
import { RegionCoordinatorLegend } from "./RegionalCoordinatorLegend";
import {
    RegionalCoordinatorTrainers
} from "@common/crud/eventInstance/components/monitoring/RegionalCoordinatorCalendar.tsx/RegionalCoordinatorTrainers";
import { useBankHolidays } from "@common/hooks/useBankHolidays";
import { useWindowDimensions } from "@common/global/UseWindowDimensions";

interface RegionalCoordinatorCalendarProps {
    editing: boolean;
    stopEditing: () => void;
}

export const RCCalendar: React.FC<RegionalCoordinatorCalendarProps> =
({ editing, stopEditing }) => {
    const [month, setMonth] = React.useState(moment().utc().startOf("M"));
    const [selectedDate, setSelectedDate] = React.useState(moment().startOf("M"));
    const [rcCalendar, setRCCalendar] = React.useState<RegionalCoordinatorCalendar>();
    const [allTrainers, setAllTrainers] = React.useState<Trainer[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [trainerNames, setTrainerNames] = React.useState<TrainerNamesForDay>(null);
    const [allAvailableTrainers, setAllAvailableTrainers] = React.useState<MonitorTrainerAvailabilityForDay[]>([]);
    const [changes, setChanges] = React.useState<RegionalCoordinatorCalendarDay[]>([]);
    const { isDayBankHoliday } = useBankHolidays();
    const { isMobileLandscapeHeight, isTabletScreenSize } = useWindowDimensions();

    React.useEffect(() => {
        async function loadData() {
            setLoading(true);
            const api = new RegionalCoordinatorApi();
            setRCCalendar(await api.getTrainerMonitoringForMonth(month));
            setLoading(false);
            setTrainerNames(null);
        }
        loadData();
    }, [month]);

    React.useEffect(() => {
        async function loadData() {
            const api = new RegionalCoordinatorApi();
            setAllTrainers(await api.getAllMonitorTrainers());
        }
        loadData();
    }, []);

    React.useEffect(() => {
        async function loadData() {
            const api = new RegionalCoordinatorApi();
            const result = await api.getMonitorTrainersForDay(selectedDate);
            setAllAvailableTrainers(result);
        }
        if (editing) {
            loadData();
        }

    }, [editing, selectedDate]);

    function getTrainerName(trainerId: string) {
        const name = allTrainers?.find(t => t.id === trainerId)?.fullName ?? "Unknown!";

        if (name.length < 26) {
            return name;
        }

        return `${name.slice(0, 20)}...`;
    }

    function renderDay(props: DayContentProps) {
        const day = props.date;
        const currentDay = day.getDate();
        const dayMoment = moment.utc([day.getFullYear(), day.getMonth(), currentDay]).startOf("day");
        const arrangementsForDay = rcCalendar?.days.find(d => d.date.date() === dayMoment.date());
        const isBankHoliday = isDayBankHoliday(dayMoment);

        function onClick() {
            setSelectedDate(dayMoment);
            if (editing) {
                setModalOpen(true);
            }
        }

        function displayTrainerNames(rcTrainerType: RCTrainerType, ids: string[]) {
            const trainerNamesForTrainerType = rcTrainerType === RCTrainerType.Cover ?
                ids.map(id => getTrainerName(id)) :
                ids.map(id => `${getTrainerName(id)} ${arrangementsForDay?.monitorSessionsOnDay}`);
            setTrainerNames({ rcTrainerType, date: dayMoment, trainerNames: trainerNamesForTrainerType });
        }

        return (
            <div className="cell-style" onClick={onClick}>
                <div className={`date-style ${isBankHoliday ? "bank-holiday" : ""}`}>
                    {isBankHoliday &&
                        <span className="bank-holiday-text">
                            {isTabletScreenSize || isMobileLandscapeHeight ? "BH" : "Bank holiday"}
                        </span>
                    }
                    <span>{currentDay}</span>
                </div>
                {!!arrangementsForDay?.coverTrainerIds?.length &&
                    <Button
                        size="small"
                        className="cover-trainer-button"
                        onClick={() => displayTrainerNames(RCTrainerType.Cover, arrangementsForDay?.coverTrainerIds)}>
                        Cover Trainers
                    </Button>
                }
                {!!arrangementsForDay?.monitorTrainerIds?.length &&
                    <Button
                        size="small"
                        className="monitor-trainer-button"
                        onClick={() => displayTrainerNames(RCTrainerType.Monitor, arrangementsForDay?.monitorTrainerIds)}>
                        Monitor Trainers
                    </Button>
                }
            </div>
        );
    }

    function onMonthChange(date: Date) {
        const calendarDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
        const startOfMonth = calendarDate.clone().startOf("month");
        setMonth(startOfMonth);
    }

    function closeModal() {
        setChanges([]);
        setModalOpen(false);
    }

    function updateCurrentAssignments(update: RegionalCoordinatorCalendarDay[]) {
        setRCCalendar({ ...rcCalendar, days: update });
    }

    const eventsOnDay = rcCalendar?.days?.find(e => e.date.date() === selectedDate.date())?.monitoredEventsOnDay ?? [];

    return (
        <Container>
            <Spinner active={loading}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={14} mobile={10} tablet={12}>
                            <DayPicker
                                className="narrow"
                                selected={[]}
                                components={{ DayContent: renderDay }}
                                month={month.toDate()}
                                onMonthChange={onMonthChange}
                                disableNavigation={editing}
                                key={JSON.stringify(rcCalendar?.days)}
                                weekStartsOn={1}
                            />
                            <AllItems area={Area.RegionalCoordinatorCalendar} eventInstances={eventsOnDay} />
                            <AssignRegionalCoordinatorsModal
                                date={selectedDate}
                                open={modalOpen}
                                closeModal={closeModal}
                                currentAssignments={rcCalendar?.days}
                                key={selectedDate.toISOString()}
                                updateCurrentAssignments={updateCurrentAssignments}
                                stopEditing={stopEditing}
                                setDate={setSelectedDate}
                                allAvailableTrainers={allAvailableTrainers}
                                changes={changes}
                                setChanges={setChanges}
                            />
                        </Grid.Column>
                        <Grid.Column computer={2} mobile={6} tablet={4}>
                            <RegionCoordinatorLegend />
                            <Divider hidden />
                            <RegionalCoordinatorTrainers trainerNamesForDay={trainerNames} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Spinner>
        </Container>
    );
};
