import * as React from "react";
import { CorporateEventInstanceResultRow, dateRange, datesConsecutive, EventInstanceEnquiry } from "../model";
import { Icon } from "semantic-ui-react";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { AddToBasketButton } from "./AddToBasketButton";
import "./offeredCourseDetails.scss";
import { EnquiryType } from "@common/crud/eventInstance/model";
import { EnquiryCaptureButtonAndModal } from "./EnquiryCaptureButtonAndModal";
import { doesPriceIncludeVat } from "@common/crud/organisation/model";
import { useSelector } from "react-redux";
import { businessLineTypeSelector } from "@common/redux-helpers";

export interface OfferedCourseDetailsProps {
    offeredCourse: CorporateEventInstanceResultRow;
    seatsAddedForCourse: (offeredCourseId: string, seats: number, add: boolean) => void;
    basketChangesInProgress: boolean;
    enquiryData: EventInstanceEnquiry;
}

export const OfferedCourseDetails = (props: OfferedCourseDetailsProps) => {
    const { offeredCourse, seatsAddedForCourse, basketChangesInProgress, enquiryData } = props;

    const businessLineType = useSelector(businessLineTypeSelector);

    const [showMore, setShowMore] = React.useState(false);

    const onShowMoreToggle = React.useCallback(() => {
        setShowMore(!showMore);
    }, [showMore]);

    const handleSeatsAddedForCourse = React.useCallback((seats: number) => {
        seatsAddedForCourse(offeredCourse.id, seats, true);
    }, [offeredCourse, seatsAddedForCourse]);

    const formattedPrice = doesPriceIncludeVat(+businessLineType)
        ? `£${(offeredCourse.priceInPence / 100).toFixed(2)}`
        : `£${(offeredCourse.priceInPence / 100).toFixed(2)} + VAT`;

    return (
        <div className="offered-course-details">
            <div className="date-part">
                <div className="center-text"><Icon name="calendar" /></div>
                {offeredCourse.startTimes.length > 1 ?
                    <>
                        <div className="center-text">{`${offeredCourse.startTimes.length} DAYS`}</div>
                        <div className="center-text">{dateRange(offeredCourse.startTimes)}</div>
                        <div className="center-text">{datesConsecutive(offeredCourse.startTimes) ? "consecutive" : "non-consecutive"}</div>
                    </> : <>
                        <div className="center-text">{offeredCourse.startTimes[0]?.format("ddd")?.toUpperCase()}</div>
                        <div className="center-text bigger-font">{offeredCourse.startTimes[0]?.format("DD")}</div>
                        <div className="center-text">{offeredCourse.startTimes[0]?.format("MMM")?.toUpperCase()}</div>
                    </>
                }
            </div>
            <div className="details-part">
                <div className="product-name">{offeredCourse.eventTypeName}</div>
                {offeredCourse.startTimes.length > 1 ?
                    <>
                        <div className="dates-part">
                            <div>
                                <Icon name="time" />{`Day 1: ${offeredCourse.startTimes[0]?.format("DD MMM HH:mm")}`
                                    + `- ${offeredCourse.endTimes[0]?.format("HH:mm")}`}
                                <Icon name={showMore ? "chevron up" : "chevron down"} className="clickable-chevron" onClick={onShowMoreToggle} />
                            </div>
                            {showMore && offeredCourse.startTimes.slice(1).map((startTime, index) => (
                                <div key={`${offeredCourse.id}-date-${index}`}>
                                    <Icon name="time" />{`Day ${index + 2}: ${startTime?.format("DD MMM HH:mm")}`
                                    + `- ${offeredCourse.endTimes[index + 1]?.format("HH:mm")}`}
                                </div>
                            ))}
                        </div>
                    </> : <>
                        <div>
                            <Icon name="time" />{offeredCourse.startTimes[0]?.format("HH:mm")} - {offeredCourse.endTimes[0]?.format("HH:mm")}
                        </div>
                    </>
                }
                <div><Icon name="map pin" />
                    {offeredCourse.enquiryType === EnquiryType.Enquiry
                        ? offeredCourse.locationDescription
                        : offeredCourse.deliveryType === DeliveryTypeEnum.Onsite
                            ? `Delivered in ${offeredCourse.venueName} ${offeredCourse.venueTown}`
                            : "Delivered Online"}
                </div>
                <div>
                    <Icon name="user" />{offeredCourse.enquiryType === EnquiryType.Enquiry
                        ? `max ${offeredCourse.seatsAvailable} ${`place${offeredCourse.seatsAvailable > 1? "s" : ""}`} in total`
                        : `${offeredCourse.seatsAvailable} ${`place${offeredCourse.seatsAvailable > 1? "s" : ""}`} available`
                    }
                </div>
            </div>
            <div className="actions-part">
                <div className="price end-text">{formattedPrice}</div>
                <div className="end-text">
                    {offeredCourse.enquiryType !== EnquiryType.Enquiry && (
                        <AddToBasketButton
                            maxValue={offeredCourse.seatsAvailable}
                            seatsAddedForCourse={handleSeatsAddedForCourse}
                            basketChangesInProgress={basketChangesInProgress}
                        />
                    )}
                    {offeredCourse.enquiryType === EnquiryType.Enquiry &&
                        <EnquiryCaptureButtonAndModal
                            maxSeats={offeredCourse.seatsAvailable}
                            eventInstanceId={offeredCourse.id}
                            enquiryData={enquiryData}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
