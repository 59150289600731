import * as React from "react";
import { getTrainerBackgroundBasedOnAverageDaysWorked } from "@common/crud/eventInstance/helpers";
import { Table, Button, Icon, Label, Popup } from "semantic-ui-react";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { errorMessageDisplay } from "@common/crud/trainer/components/ErrorMessageDisplay";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { IncreaseLimitIfTrainerIsRcCoverForDigitalCourse } from "@common/crud/eventInstance/components/trainers/helper";
import moment from "moment";
import { DateFormat } from "@common/crud/common/DateTimeFormats";

interface OwnProps {
    isDigital: boolean;
    isCorporate: boolean;
    trainer: TrainerAvailabilitySelectModel;
    selectedTrainers: TrainerAvailabilitySelectModel[];
    limit?: { value: number; message: string };
    onSelectTrainer(trainer: TrainerAvailabilitySelectModel): void;
    onDeselectTrainer(trainer: TrainerAvailabilitySelectModel): void;
    theoryAndPractical: boolean;
    publishedDate: moment.Moment;
    startDate: moment.Moment;
}

export const TrainerRow: React.FC<OwnProps> = (
    { isDigital,
        isCorporate,
        trainer,
        selectedTrainers,
        limit,
        onSelectTrainer,
        onDeselectTrainer,
        theoryAndPractical,
        publishedDate,
        startDate }) => {
    const eventInstance = useSelector(eventInstanceSelector);
    const isTrainerSubcontracted = eventInstance.subcontractedTrainers?.some(t => t.id === trainer.id) === true;

    const onSelectClicked = React.useCallback(() => {
        const withinSelectionLimit = () => {
            return limit && selectedTrainers.length < limit.value +
                IncreaseLimitIfTrainerIsRcCoverForDigitalCourse(selectedTrainers, trainer, publishedDate, startDate, isDigital) || !limit;
        };

        if (!withinSelectionLimit()) {
            toast.info(limit.message);
        } else {
            onSelectTrainer(trainer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, onSelectTrainer, selectedTrainers.length, trainer]);

    const onDeselectClicked = React.useCallback(() => {
        onDeselectTrainer(trainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onDeselectTrainer]);

    const getBooleanIcon = (value: boolean) => {
        return (<Icon name={value ? "checkmark" : "cancel"} color={value ? "green" : "red"} />);
    };

    const showAvailabilityTypeSymbol = (t: TrainerAvailabilitySelectModel) => {
        if (!t.available) {
            return <></>;
        }

        if (t.onlyAvailableForDigitalEvents) {
            return <Icon name="wifi" />;
        }

        if (t.onlyAvailableForClassroomEvents) {
            return <Icon name="home" />;
        }
        return <></>;
    };

    const getStatusColour = () => {
        if (trainer.isBooked || trainer.isProvisionallyBooked) {
            return "red";
        } else if (trainer.isBookedOnOtherTimesOnTheSameDay || trainer.isRCMonitorOnDate || trainer.isRCCoverOnDate ||
                   trainer.isStandbyTrainerDuringEventInstance) {
            return "orange";
        }
        return "green";
    };

    const isSelected = () => {
        return selectedTrainers?.some(s => s.id === trainer.id);
    };

    const getSelectionButton = () => {
        if (isSelected()) {
            return (<Button
                content="Selected"
                onClick={onDeselectClicked}
                color="green"
                disabled={isTrainerSubcontracted}
            />);
        } else if (!(trainer.isBooked || trainer.isProvisionallyBooked)) {
            return (<Button
                content="Select"
                onClick={onSelectClicked}
                color="black"
            />);
        }
        return (<></>);
    };

    const getTrainerStatus = () => {
        if (trainer.isRCCoverOnDate) {
            return "Booked as RC Cover";
        }

        if (trainer.isRCMonitorOnDate) {
            return "Booked as RC Monitor";
        }

        if (trainer.isBooked) {
            return "Booked";
        }

        if (trainer.isProvisionallyBooked) {
            return "Provisionally booked";
        }

        return trainer.isStandbyTrainerDuringEventInstance ? "Booked as Standby Trainer" : "Not Booked";
    };

    const getTrainerCarTypes = () => {
        return (
            <div className="car-type-places">
                {trainer.hasAutomaticAttribute && <img
                    src={"/assets/automatic.png"}
                    alt={"Automatic"}
                />}

                {trainer.hasManualAttribute && <img
                    src={"/assets/manual.png"}
                    alt={"Manual"}
                />}

                {!trainer.hasAutomaticAttribute && !trainer.hasManualAttribute && <p>No car types</p> }
            </div>);
    };

    return (
        <Table.Row key={trainer.id}>
            <Table.Cell width={3}>
                <div className="trainer-name-cell">
                    {trainer.fullName}
                    {trainer.unverifiedTrainerAttributes?.length > 0 &&
                        <Popup
                            content={errorMessageDisplay(trainer.unverifiedTrainerAttributes)}
                            trigger={<Icon name={"exclamation triangle"} />}
                        />}
                </div>
            </Table.Cell>
            <Table.Cell>
                {trainer.mobileNumber}
                {trainer.mobileNumber && trainer.homeNumber && <br />}
                {trainer.homeNumber}
            </Table.Cell>
            {!isDigital && <Table.Cell content={Math.round(trainer.distanceInMiles)} />}
            <Table.Cell width={2} content={trainer.allocatedCoursesInMonth} />
            <Table.Cell width={2} className={getTrainerBackgroundBasedOnAverageDaysWorked(trainer.averageDaysWorkedPerMonthInLastYear)}
                content={trainer.averageDaysWorkedPerMonthInLastYear} />
            <Table.Cell textAlign="center">
                {getBooleanIcon(trainer.available)}{showAvailabilityTypeSymbol(trainer)}
                {trainer.available && trainer.availabilityLastUpdated &&
                    <div>{trainer.availabilityLastUpdated.local().format(DateFormat.Short)}</div>}
            </Table.Cell>
            {!isDigital && <Table.Cell textAlign="center">{getBooleanIcon(trainer.venueChosen)}</Table.Cell>}
            <Table.Cell textAlign="center">
                <Label color={getStatusColour()} content={getTrainerStatus()} />
            </Table.Cell>
            {(!isCorporate && theoryAndPractical) &&
                <Table.Cell textAlign="center">
                    {getTrainerCarTypes()}
                </Table.Cell>
            }
            <Table.Cell>
                {getSelectionButton()}
            </Table.Cell>
        </Table.Row>
    );
};
