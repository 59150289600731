/* eslint-disable max-lines */
import { Input } from "@neworbit/simpleui-input";
import moment from "moment";
import * as React from "react";
import { CheckboxProps, Confirm, Divider, Grid, Icon } from "semantic-ui-react";
import { validators } from "not-valid";
import { DelegateComparisonTypeEnum, DrivingLicenceCountryEnum, VocationalLicenceCategoryEnum,
    filteredVocationalLicenceCatogories } from "@common/crud/delegate/model";
import { AttendeeListModel } from "@common/crud/attendee";
import "./UpdateDelegatesTable.scss";
import { licenceValidator } from "@common/validation/drivingLicenceNumber";
import { DelegateApi } from "@common/crud/delegate/delegateApi";
import { BusinessDriverComparableDelegateAttendeeFields, CompletionState, CpcComparableDelegateAttendeeFields, OnRoadComparableDelegateAttendeeFields,
    ReasonIssue, ReasonIssueEnum, WorkshopComparableDelegateAttendeeFields } from "@common/crud/attendee/model";
import { debounce } from "lodash";
import { ValidationResultType } from "not-valid/bin/results";
import { UpdateDelegatesComparisonModal } from "./UpdateDelegatesComparisonModal";
import { useRegisterContext } from "../register/register-context";
import { EventInstanceApi } from "../../eventInstanceApi";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { corporateOrganisationsSelector } from "@common/crud/organisation/selectors";
import { DqcDetails } from "./delegate-register-components/DqcDetails";
import { DelegateBasicDetails } from "./delegate-register-components/DelegateBasicDetails";
import { DelegateStateToggle } from "./delegate-register-components/DelegateStateToggle";
import { LicenceDetails } from "./delegate-register-components/LicenceDetails";
import { phoneNumberWithSpaces } from "@common/validation";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { Address } from "@common/crud/common";
import { EventInstanceAttendeeField } from "../../model";
import { ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { noWhiteSpaceValidator } from "@common/validation/noWhiteSpaceValidator";
import { EmailApi } from "@common/email/emailApi";
import { Department } from "@common/crud/organisation/model";
import { MxEmailCheckResult } from "@common/crud/email/model";
import { toast } from "@common/toasts";
import { uinValidator } from "@common/validation/uin";
import { ManagerDetails } from "./delegate-register-components/ManagerDetails";
import { phoneNumberSpacesValidator } from "@common/validation/phoneNumber";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";

interface UpdateDelegatesTableRowProps {
    delegate: AttendeeListModel;
    isEditable?: boolean;
    eventInstanceId?: string;
    alreadyUsedDrivingLicenceNumbers?: { id: string; drivingLicenceNumber: string }[];
    alreadyUsedUins?: { id: string; uin: string }[];
    isStageTwo: boolean;
    isEventInstanceOpen: boolean;
    isEventInstanceMultiDay: boolean;
    eventInstanceGroupDays: EventInstanceGroupDay[];
    eventInstanceWorkflowType: WorkflowTypeEnum;
    eventInstanceProductCategory: ProductCategoryEnum;
    isEventInstanceAfv: boolean;
    resubmissionRequired?: boolean;
    showCompletionError?: boolean;
    canChangeDelegateAmount?: boolean;
    fieldsForMarkup?: string[];
    submitted: boolean;
    updateDelegate: (delegate: AttendeeListModel, updatedDelegate: AttendeeListModel, forceImmediateSave?: boolean) => void;
    removeDelegate: (delegate: AttendeeListModel) => void;
    updateFormErrorsOnFieldChange: (delegate: AttendeeListModel, formField: keyof AttendeeListModel, valid: boolean) => void;
    setResubmissionRequired?: () => void;
    forceDrivingLicenceNumberValidation: (id: string, drivingLicenceNumber: string) => void;
    forceUinValidation: (id: string, uin: string) => void;
    updateFieldsForMarkup: (delegate: AttendeeListModel, fieldsForMarkup: string[]) => void;
    updateBackendValidationInProgress: (backendValidationInProgress: boolean) => void;
    fetchingRowDataInProgress: (delegateId: string) => void;
    fetchingRowDataFinished: (delegateId: string) => void;
}

async function lookForDelegateData(value: string, delegateComparisonType: DelegateComparisonTypeEnum, eventInstanceId: string, delegate: AttendeeListModel,
    isTrainerApp: boolean, isEventInstanceOpen: boolean, eventInstanceWorkflowType: WorkflowTypeEnum, isEventInstanceAfv: boolean,
    updateDelegate: (delegate: AttendeeListModel, updatedDelegate: AttendeeListModel, forceImmediateSave?: boolean) => void,
    setAttendeeManualData: (delegate: AttendeeListModel) => void,
    setAttendeeDelegateData: (delegate: AttendeeListModel) => void,
    setComparisonFields: (comparisonFields: { field: string; needsComparison: boolean }[]) => void,
    updateFieldsForMarkup: (delegate: AttendeeListModel, fieldsForMarkup: string[]) => void,
    updateBackendValidationInProgress: (backendValidationInProgress: boolean) => void) {

    const isEventInstanceCpc = eventInstanceWorkflowType === WorkflowTypeEnum.CPC;
    const isEventInstanceBusinessDriver = eventInstanceWorkflowType === WorkflowTypeEnum.BusinessDriver;

    if (!value) {
        updateBackendValidationInProgress(false);
        return;
    }

    const delegateApi = new DelegateApi();
    let result: AttendeeListModel;
    try {
        result = await delegateApi.lookForDelegateData(eventInstanceId, value, delegateComparisonType, isEventInstanceOpen ? delegate.organisationId : null);
    } catch {
        updateBackendValidationInProgress(false);
        return;
    }

    if (result?.delegateId) {
        if (delegate.delegateId && delegate.delegateId === result.delegateId) {
            updateBackendValidationInProgress(false);
            return;
        }

        let fieldsNeedComparison: { field: string; attendeeFieldDisplayName?: string; needsComparison: boolean }[] = [];

        const fieldsForComparison = () => {
            switch (eventInstanceWorkflowType) {
                case WorkflowTypeEnum.CPC:
                    return CpcComparableDelegateAttendeeFields;
                case WorkflowTypeEnum.Workshop:
                    return WorkshopComparableDelegateAttendeeFields;
                case WorkflowTypeEnum.OnRoad:
                    return OnRoadComparableDelegateAttendeeFields;
                case WorkflowTypeEnum.BusinessDriver:
                    return BusinessDriverComparableDelegateAttendeeFields;
                default:
                    return [];
            }
        };

        fieldsNeedComparison = fieldsForComparison().map(field => ({
            field,
            needsComparison: delegate[field] && delegate[field]?.toString()?.toLowerCase() !== result[field]?.toString()?.toLowerCase()
        }));

        if (result.attendeeFieldValues && result.attendeeFieldValues.length > 0) {
            fieldsNeedComparison = result.attendeeFieldValues.reduce((acc, afv) => {
                const hasMismatchedValue = delegate.attendeeFieldValues && delegate.attendeeFieldValues
                    .some(dafv => dafv.name === afv.name && dafv.value !== afv.value);
                return hasMismatchedValue ? [
                    ...acc,
                    {
                        field: afv.name,
                        attendeeFieldDisplayName: afv.displayName,
                        needsComparison: true
                    }
                ] : acc;
            }, fieldsNeedComparison);
        }

        if (delegate.attendeeFieldValues && delegate.attendeeFieldValues.length > 0) {
            if (!result.attendeeFieldValues) {
                result = {
                    ...result,
                    attendeeFieldValues: []
                };
            }

            for (const afv of delegate.attendeeFieldValues) {
                if (!result.attendeeFieldValues.some(rafv => rafv.name === afv.name)) {
                    result = {
                        ...result,
                        attendeeFieldValues: [...result.attendeeFieldValues, afv]
                    };
                } else if (result.attendeeFieldValues.some(rafv => rafv.name === afv.name && !rafv.value)) {
                    result = {
                        ...result,
                        attendeeFieldValues: [...result.attendeeFieldValues.filter(rafv => rafv.name !== afv.name), afv]
                    };
                }
            }
        }

        if (isEventInstanceCpc) {
            fieldsNeedComparison = [
                ...fieldsNeedComparison,
                {
                    field: "dqcExpiry",
                    needsComparison: (!!delegate.dqcExpiry && !result.dqcExpiry) ||
                        (!!delegate.dqcExpiry && !!result.dqcExpiry &&
                        delegate.dqcExpiry.utc(true).format("DD/MM/YYYY") !== result.dqcExpiry.utc(true).format("DD/MM/YYYY"))
                },
                {
                    field: "dateOfBirth",
                    needsComparison: (!!delegate.dateOfBirth && !result.dateOfBirth) ||
                    (!!delegate.dateOfBirth && !!result.dateOfBirth &&
                    delegate.dateOfBirth.utc(true).format("DD/MM/YYYY") !== result.dateOfBirth.utc(true).format("DD/MM/YYYY"))
                }
            ];
        }

        if (isEventInstanceCpc || isEventInstanceAfv) {
            fieldsNeedComparison = [
                ...fieldsNeedComparison,
                {
                    field: "vocationalLicenceCategories",
                    needsComparison: (delegate.vocationalLicenceCategories?.length ?? 0) !== 0 &&
                        ((delegate.vocationalLicenceCategories?.length ?? 0) !== (result.vocationalLicenceCategories?.length ?? 0) ||
                        !(delegate.vocationalLicenceCategories?.sort() ?? []).every((e, i) => e === (result.vocationalLicenceCategories?.sort() ?? [])[i]))
                }
            ];
        }

        if (isEventInstanceAfv) {
            fieldsNeedComparison = [
                ...fieldsNeedComparison,
                {
                    field: "address",
                    needsComparison: delegate.address?.addressLine1 !== result.address?.addressLine1
                        || delegate.address?.addressLine2 !== result.address?.addressLine2
                        || delegate.address?.addressLine3 !== result.address?.addressLine3
                        || delegate.address?.city !== result.address?.city
                        || delegate.address?.postalCode !== result.address?.postalCode
                }
            ];
        }

        if (isEventInstanceBusinessDriver) {
            const notEmptyDelegateManagersCount = delegate.managers?.filter(m => m.name || m.telephone || m.email).length || 0;
            const delegateManagersSorted = [...delegate.managers || []].sort((a, b) => (a.email || "").localeCompare(b.email || "")
            || (a.name || "").localeCompare(b.name || ""));
            const resultManagersSorted = [...result.managers || []].sort((a, b) => (a.email || "").localeCompare(b.email || "")
                || (a.name || "").localeCompare(b.name || ""));

            fieldsNeedComparison = [
                ...fieldsNeedComparison,
                {
                    field: "managers",
                    needsComparison: notEmptyDelegateManagersCount !== 0 && (delegateManagersSorted.length !== resultManagersSorted.length ||
                        delegateManagersSorted.some((m, i) => m.name !== resultManagersSorted[i].name || m.telephone !== resultManagersSorted[i].telephone
                            || m.email !== resultManagersSorted[i].email))
                }
            ];
        }

        const fieldsForMarkup = fieldsNeedComparison.filter(f => f.needsComparison).map(f => f.field);
        const needComparison = !isTrainerApp && fieldsNeedComparison.reduce((a, b) => a || b.needsComparison, false);

        if (!needComparison) {
            updateFieldsForMarkup(delegate, fieldsForMarkup);
            updateDelegate(
                delegate,
                {
                    ...delegate,
                    ...result,
                    id: delegate.id,
                    notSaved: delegate.notSaved,
                    managers: delegateComparisonType === DelegateComparisonTypeEnum.Uin
                        ? result.managers?.length > 0
                            ? result.managers
                            : [ { name: "", telephone: "", email: "" }]
                        : []
                }
            );
            updateBackendValidationInProgress(false);
        } else {
            setAttendeeManualData(delegate);
            setAttendeeDelegateData(result);
            setComparisonFields(fieldsNeedComparison);
        }
    } else {
        updateBackendValidationInProgress(false);
    }
}
const lookForDelegateDataDebounced = debounce(lookForDelegateData, 1000);

const cancelProps = { content: "Cancel", className: "cancel-action" };
const confirmProps = { content: "Confirm" };

export const UpdateDelegatesTableRow: React.FC<UpdateDelegatesTableRowProps> =
({ delegate, isEditable, eventInstanceId, alreadyUsedDrivingLicenceNumbers, alreadyUsedUins, updateDelegate,
    updateFormErrorsOnFieldChange, isStageTwo, canChangeDelegateAmount,
    resubmissionRequired, setResubmissionRequired, showCompletionError, removeDelegate, forceDrivingLicenceNumberValidation,
    forceUinValidation, isEventInstanceOpen, updateFieldsForMarkup, fieldsForMarkup, updateBackendValidationInProgress,
    eventInstanceWorkflowType, isEventInstanceAfv, eventInstanceProductCategory, isEventInstanceMultiDay,
    fetchingRowDataInProgress, fetchingRowDataFinished, eventInstanceGroupDays, submitted }) => {
    const [comparisonFields, setComparisonFields] = React.useState<{ field: string; needsComparison: boolean }[]>([]);
    const [attendeeManualData, setAttendeeManualData] = React.useState<AttendeeListModel>(undefined);
    const [attendeeDelegateData, setAttendeeDelegateData] = React.useState<AttendeeListModel>(undefined);
    const [updatingCompletion, setUpdatingCompletion] = React.useState(false);
    const [loadedAttendeeFields, setLoadedAttendeeFields] = React.useState<Record<string, EventInstanceAttendeeField[]>>();
    const [loadedDepartments, setLoadedDepartments] = React.useState<Record<string, Department[]>>();
    const [removingAttendee, setRemovingAttendee] = React.useState<boolean>(false);
    const [emailChecked, setEmailChecked] = React.useState<{value: string; valid: boolean; result: MxEmailCheckResult; exception: boolean}>(undefined);

    const checkEmail = React.useCallback(async (value: string, valid: boolean) => {
        if (valid) {
            try {
                const emailApi = new EmailApi();
                const mxEmailCheckResult = await emailApi.checkEmail(value);
                setEmailChecked({ value, valid, result: mxEmailCheckResult, exception: false });
            } catch {
                setEmailChecked({ value, valid, result: undefined, exception: true });
            }
        } else {
            setEmailChecked({ value, valid, result: undefined, exception: false });
        }
    }, []);

    const checkEmailDebounced = React.useMemo(() => debounce(checkEmail, 1500), [checkEmail]);

    React.useEffect(() => {
        if (emailChecked !== undefined) {
            if (emailChecked.valid) {
                if (!emailChecked.exception) {
                    updateDelegate(delegate, { ...delegate, email: emailChecked.value, mxEmailCheckResult: emailChecked.result });
                    updateFormErrorsOnFieldChange(delegate, "email", emailChecked.value ? emailChecked.result?.valid : true);
                } else {
                    toast.error("An error occurred while checking the email address.");
                }
                fetchingRowDataFinished(`${delegate.id}_emailCallDebounced`);
            } else {
                updateFormErrorsOnFieldChange(delegate, "email", false);
                fetchingRowDataFinished(`${delegate.id}_emailCallDebounced`);
            }
            setEmailChecked(undefined);
        }
    }, [delegate, emailChecked, fetchingRowDataFinished, updateDelegate, updateFormErrorsOnFieldChange]);

    const app = useSelector(appSelector);
    const isAdminApp = app === Apps.Admin;
    const isTrainerApp = app === Apps.Trainer;

    const organisations = useSelector(corporateOrganisationsSelector);

    const { eventInstanceOptions } = useRegisterContext();
    const { hasBeenSubmitted, attendeeFields, organisationId } = eventInstanceOptions;

    const eventInstanceHasCpc = React.useMemo(() =>
        eventInstanceWorkflowType === WorkflowTypeEnum.CPC || eventInstanceProductCategory === ProductCategoryEnum.OnRoadWithCpc,
    [eventInstanceProductCategory, eventInstanceWorkflowType]);

    const delegateComparisonType = React.useMemo(() => eventInstanceWorkflowType === WorkflowTypeEnum.BusinessDriver
        ? DelegateComparisonTypeEnum.Uin
        : DelegateComparisonTypeEnum.DrivingLicenceNumber,
    [eventInstanceWorkflowType]);

    React.useEffect(() => {
        setLoadedAttendeeFields(attendeeFields);
    }, [attendeeFields]);

    const addAttendeeFieldsToLoadedFields = React.useCallback((usedOrganisationId: string, fields: EventInstanceAttendeeField[]) => {
        const extendedLoadedAttendeeFields = { ...loadedAttendeeFields };
        extendedLoadedAttendeeFields[usedOrganisationId] = fields;
        setLoadedAttendeeFields(extendedLoadedAttendeeFields);
    }, [loadedAttendeeFields]);

    const addDepartmentsToLoadedDepartments = React.useCallback((usedOrganisationId: string, departments: Department[]) => {
        const extendedLoadedDepartments = { ...loadedDepartments };
        extendedLoadedDepartments[usedOrganisationId] = departments;
        setLoadedDepartments(extendedLoadedDepartments);
    }, [loadedDepartments]);

    const onComparisonCancel = React.useCallback(() => {
        updateDelegate(delegate, {
            ...delegate,
            drivingLicenceNumber: delegateComparisonType !== DelegateComparisonTypeEnum.DrivingLicenceNumber ? delegate.drivingLicenceNumber : "",
            uin: delegateComparisonType !== DelegateComparisonTypeEnum.Uin ? delegate.uin : "",
        });
        setAttendeeManualData(undefined);
        setAttendeeDelegateData(undefined);
        setComparisonFields([]);
        updateBackendValidationInProgress(false);
    }, [delegate, delegateComparisonType, updateDelegate, updateBackendValidationInProgress]);

    const onComparisonContinue = React.useCallback((resultAttendee: AttendeeListModel) => {
        if (delegateComparisonType === DelegateComparisonTypeEnum.Uin) {
            const uins = alreadyUsedUins.filter(a => a.id !== delegate.id).map(attendee => (attendee.uin));
            const validator = uinValidator(uins);
            const uinValid = validator(resultAttendee.uin);
            updateFormErrorsOnFieldChange(delegate, "uin", uinValid.type === ValidationResultType.Pass);
        }

        const drivingLicenceNumbers = alreadyUsedDrivingLicenceNumbers.filter(a => a.id !== delegate.id).map(attendee => (attendee.drivingLicenceNumber));
        const drivingLicenceNumberValidator = licenceValidator(resultAttendee?.drivingLicenceCountry, resultAttendee.surname, drivingLicenceNumbers);
        const licenceValid = drivingLicenceNumberValidator(resultAttendee.drivingLicenceNumber);
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceNumber", licenceValid.type === ValidationResultType.Pass);

        updateDelegate(
            delegate,
            {
                ...resultAttendee,
                id: attendeeManualData.id,
                notSaved: attendeeManualData.notSaved,
                managers: delegateComparisonType === DelegateComparisonTypeEnum.Uin
                    ? resultAttendee.managers?.length > 0
                        ? resultAttendee.managers
                        : [ { name: "", telephone: "", email: "" }]
                    : []
            }
        );
        setAttendeeManualData(undefined);
        setAttendeeDelegateData(undefined);
        setComparisonFields([]);
        updateBackendValidationInProgress(false);
    }, [attendeeManualData, delegate, delegateComparisonType, updateDelegate, updateBackendValidationInProgress, alreadyUsedDrivingLicenceNumbers,
        alreadyUsedUins, updateFormErrorsOnFieldChange]);

    const onForenameChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "forename", valid);
        updateDelegate(delegate, { ...delegate, forename: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onSurnameChange = React.useCallback((value: string, valid: boolean) => {
        const drivingLicenceNumberValidator = licenceValidator(delegate?.drivingLicenceCountry, value,
            alreadyUsedDrivingLicenceNumbers.map(dln => dln.drivingLicenceNumber));
        const licenceValid = drivingLicenceNumberValidator(delegate.drivingLicenceNumber);
        updateFormErrorsOnFieldChange(delegate, "surname", valid);
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceNumber", licenceValid.type === ValidationResultType.Pass);
        updateDelegate(delegate, { ...delegate, surname: value });
    }, [alreadyUsedDrivingLicenceNumbers, delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDaysChange = React.useCallback((value: string[]) => {
        updateFormErrorsOnFieldChange(delegate, "eventInstanceIds", value.length > 0);
        if (delegate.eventInstanceIds !== value) {
            updateDelegate(delegate, { ...delegate, eventInstanceIds: value });
        }
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onOrganisationChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "organisationId", valid);
        if (delegate.organisationId !== value) {
            const organisation = organisations.find(o => o.id === value);
            updateDelegate(delegate, { ...delegate, organisationId: value, organisationName: organisation?.name, attendeeFieldValues: [] });
        }
    }, [delegate, organisations, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDqcReferenceChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "dqcReference", valid);
        updateDelegate(delegate, { ...delegate, dqcReference: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onEmailChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "email", valid);
        updateDelegate(delegate, { ...delegate, email: value });
        fetchingRowDataInProgress(`${delegate.id}_emailCallDebounced`);
        checkEmailDebounced(value, valid);
    }, [checkEmailDebounced, delegate, fetchingRowDataInProgress, updateDelegate, updateFormErrorsOnFieldChange]);

    const onEmailBlur = React.useCallback(() => {
        checkEmailDebounced.flush();
    }, [checkEmailDebounced]);

    const onTelephoneChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "telephone", valid);
        updateDelegate(delegate, { ...delegate, telephone: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onAddressChange = React.useCallback((value: Address) => {
        updateFormErrorsOnFieldChange(delegate, "address", true);
        updateDelegate(delegate, { ...delegate, address: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onUinChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "uin", valid);
        updateDelegate(delegate, { ...delegate, uin: value });

        updateBackendValidationInProgress(true);
        lookForDelegateDataDebounced(value, DelegateComparisonTypeEnum.Uin, eventInstanceId, delegate, isTrainerApp, isEventInstanceOpen,
            eventInstanceWorkflowType, isEventInstanceAfv, updateDelegate, setAttendeeManualData, setAttendeeDelegateData, setComparisonFields,
            updateFieldsForMarkup, updateBackendValidationInProgress);
        forceUinValidation(delegate.id, value);
    }, [delegate, eventInstanceId, eventInstanceWorkflowType, forceUinValidation, isEventInstanceAfv, isEventInstanceOpen,
        isTrainerApp, updateBackendValidationInProgress, updateDelegate, updateFieldsForMarkup, updateFormErrorsOnFieldChange]);

    const onManagerValueChange = React.useCallback((index: number, field: string, value: string) => {
        const newManagers = delegate.managers.map((existingManager, managerIndex) => managerIndex === index
            ? { ...existingManager, [field]: value }
            : existingManager);

        const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
        const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length;
        const hasInvalidEmails = newManagers.filter(m => m.email).some(m => validators.validEmail()(m.email).type !== ValidationResultType.Pass);
        const hasInvalidTelephones = newManagers.filter(m => m.telephone).some(m => phoneNumberSpacesValidator(m.telephone).type !== ValidationResultType.Pass);

        updateFormErrorsOnFieldChange(delegate, "managers", !hasDuplicates && !hasInvalidEmails && !hasInvalidTelephones);
        updateDelegate(delegate, { ...delegate, managers: newManagers });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onRemoveManager = React.useCallback((index: number) => {
        const newManagers = delegate.managers.filter((_, managerIndex: number) => managerIndex !== index);

        const emailManagerRepresentations = newManagers.filter(c => c.email).map(c => c.email);
        const hasDuplicates = (new Set(emailManagerRepresentations)).size !== emailManagerRepresentations.length;
        const hasInvalidEmails = newManagers.filter(m => m.email).some(m => validators.validEmail()(m.email).type !== ValidationResultType.Pass);

        updateFormErrorsOnFieldChange(delegate, "managers", !hasDuplicates && !hasInvalidEmails);
        updateDelegate(delegate, { ...delegate, managers: newManagers });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onAddManager = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();

        const newManagers = delegate.managers.concat([{ name: "", telephone: "", email: "" }]);
        updateDelegate(delegate, { ...delegate, managers: newManagers });
    }, [delegate, updateDelegate]);

    const onDrivingLicenceNumberChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceNumber", valid);
        updateDelegate(delegate, { ...delegate, drivingLicenceNumber: value });

        if (delegateComparisonType === DelegateComparisonTypeEnum.DrivingLicenceNumber) {
            updateBackendValidationInProgress(true);
            lookForDelegateDataDebounced(value, DelegateComparisonTypeEnum.DrivingLicenceNumber, eventInstanceId, delegate, isTrainerApp, isEventInstanceOpen,
                eventInstanceWorkflowType, isEventInstanceAfv, updateDelegate, setAttendeeManualData, setAttendeeDelegateData, setComparisonFields,
                updateFieldsForMarkup, updateBackendValidationInProgress);
            forceDrivingLicenceNumberValidation(delegate.id, value);
        }
    }, [delegate, eventInstanceId, updateDelegate, updateFormErrorsOnFieldChange, forceDrivingLicenceNumberValidation, isEventInstanceOpen, isTrainerApp,
        updateFieldsForMarkup, updateBackendValidationInProgress, eventInstanceWorkflowType, isEventInstanceAfv, delegateComparisonType]);

    const driverLicenceNumberKey = React.useMemo(() => {
        const filteredAlreadyUsedDrivingLicenceNumbers = alreadyUsedDrivingLicenceNumbers?.filter(dln => dln.id !== delegate.id)
            .map(dln => dln.drivingLicenceNumber) ?? [];
        return `${delegate?.drivingLicenceCountry || "country"}-${delegate?.surname || "surname"
        }-${filteredAlreadyUsedDrivingLicenceNumbers.join("-")}`.replace(/\s+/g, "");
    }, [delegate?.drivingLicenceCountry, delegate?.surname, delegate?.id, alreadyUsedDrivingLicenceNumbers]);

    const driverLicenceValidation = React.useMemo(() => [
        noWhiteSpaceValidator,
        licenceValidator(delegate?.drivingLicenceCountry, delegate?.surname,alreadyUsedDrivingLicenceNumbers?.map(dln => dln.drivingLicenceNumber) ?? [])
    ],
    [delegate?.drivingLicenceCountry, delegate?.surname, alreadyUsedDrivingLicenceNumbers]);

    const onDrivingLicenceCountryChange = React.useCallback((value: number, valid: boolean) => {
        const drivingLicenceNumberValidator = licenceValidator(value, delegate.surname,
            alreadyUsedDrivingLicenceNumbers.map(dln => dln.drivingLicenceNumber));
        const licenceValid = drivingLicenceNumberValidator(delegate.drivingLicenceNumber);
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceCountry", valid);
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceNumber", licenceValid.type === ValidationResultType.Pass);

        const newDelegate = { ...delegate, drivingLicenceCountry: value };
        if (value !== DrivingLicenceCountryEnum.NonGBNI) {
            newDelegate.drivingLicenceExactCountry = undefined;
            newDelegate.dateOfBirth = undefined;
            updateFormErrorsOnFieldChange(delegate, "drivingLicenceExactCountry", true);
            updateFormErrorsOnFieldChange(delegate, "dateOfBirth", true);
        }

        updateDelegate(delegate, newDelegate);
    }, [alreadyUsedDrivingLicenceNumbers, delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDqcExpiryChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "dqcExpiry", valid);
        updateDelegate(delegate, { ...delegate, dqcExpiry: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onVocationalLicenceCategoriesArrayChange = React.useCallback((value: number[], valid: boolean) => {
        const vocationalCategoryValues = value;
        if (delegate?.vocationalLicenceCategories?.includes(VocationalLicenceCategoryEnum.B) &&
            !vocationalCategoryValues?.includes(VocationalLicenceCategoryEnum.B)) {
            vocationalCategoryValues.push(VocationalLicenceCategoryEnum.B);
        }
        updateFormErrorsOnFieldChange(delegate, "vocationalLicenceCategories", valid);
        updateDelegate(delegate, { ...delegate, vocationalLicenceCategories: vocationalCategoryValues });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDrivingLicenceExactCountryChange = React.useCallback((value: string, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "drivingLicenceExactCountry", valid);
        updateDelegate(delegate, { ...delegate, drivingLicenceExactCountry: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onDateOfBirthChange = React.useCallback((value: moment.Moment, valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "dateOfBirth", valid);
        updateDelegate(delegate, { ...delegate, dateOfBirth: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const onAttendeeFieldValueChange = React.useCallback((value: any, fieldConfiguration: EventInstanceAttendeeField) => {
        updateDelegate(delegate,
            { ...delegate,
                attendeeFieldValues: [
                    ...(delegate.attendeeFieldValues || []).filter(afv => afv.name !== fieldConfiguration.fieldId),
                    { name: fieldConfiguration.fieldId, value, displayName: fieldConfiguration.displayName, type: fieldConfiguration.type } ]
            }
        );
    }, [delegate, updateDelegate]);

    const onDepartmentsChange = React.useCallback((value: string[], valid: boolean) => {
        updateFormErrorsOnFieldChange(delegate, "departments", valid);
        updateDelegate(delegate, { ...delegate, departments: value });
    }, [delegate, updateDelegate, updateFormErrorsOnFieldChange]);

    const handleAttendedChange = React.useCallback((_: any, data: any) => {
        updateDelegate(delegate, { ...delegate, didAttend: data.checked }, true);
    }, [delegate, updateDelegate]);

    const organisationOptions = React.useMemo(() => {
        return organisations
            .filter(organisation => ((delegate.organisationId && organisation.id === delegate.organisationId) || !organisation.expiryDate
                || organisation.expiryDate > moment.utc()) && organisation.corporateOrganisationData && !organisation.corporateOrganisationData.openCourse)
            .map(organisation => ({ text: organisation.name, value: organisation.id }));
    }, [delegate?.organisationId, organisations]);

    const vocationalLicenceCategoryOptions = React.useMemo(() => {
        return filteredVocationalLicenceCatogories(delegate.vocationalLicenceCategories, eventInstanceProductCategory);
    }, [delegate, eventInstanceProductCategory]);

    React.useEffect(() => {
        if (!resubmissionRequired) {
            setUpdatingCompletion(false);
        }
    }, [resubmissionRequired]);

    const onUpdateCompletion = React.useCallback(async () => {
        const api = new EventInstanceApi();
        await api.setUpdating(delegate.eventInstanceId, true);
        setUpdatingCompletion(true);
        setResubmissionRequired();
    }, [delegate.eventInstanceId, setResubmissionRequired]);

    const completionState = React.useMemo(() => delegate.didAttend
        ? delegate.isBookingCanceled ? delegate.completed : delegate?.completed
        : CompletionState.NotCompleted
    , [delegate?.completed, delegate?.didAttend, delegate?.isBookingCanceled]);
    const disableRow = React.useMemo(() => hasBeenSubmitted && !updatingCompletion, [hasBeenSubmitted, updatingCompletion]);
    const completionDisabled = React.useMemo(() => !delegate.didAttend || disableRow, [delegate?.didAttend, disableRow]);

    const handleCompletedChange = React.useCallback((_: any, data: CheckboxProps) => {
        updateDelegate(delegate, { ...delegate, completed: +data.value }, !updatingCompletion);
    }, [delegate, updateDelegate, updatingCompletion]);

    const hasIssues = React.useMemo(() => showCompletionError &&
        (delegate.completed === CompletionState.NotCompleted || delegate.didAttend === false) &&
        delegate.reasonIssue === ReasonIssueEnum.None, [delegate?.completed, delegate?.didAttend, delegate?.reasonIssue, showCompletionError]);
    const reasonIssue = React.useMemo(() => delegate.isBookingCanceled ? delegate.reasonIssue : delegate.reasonIssue,
        [delegate?.isBookingCanceled, delegate?.reasonIssue]);
    const issueText = React.useMemo(() => delegate.isBookingCanceled ? delegate.issue : delegate.issue, [delegate?.isBookingCanceled, delegate?.issue]);

    const onAttendeeRegisterUpdated = React.useCallback((updatedDelegate: AttendeeListModel) => {
        updateDelegate(delegate, updatedDelegate,  !updatingCompletion);
    }, [delegate, updateDelegate, updatingCompletion]);

    const onRemoveClick = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(true);
    }, []);

    const confirmRemove = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(false);
        removeDelegate(delegate);
    }, [delegate, removeDelegate]);

    const cancelRemove = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        setRemovingAttendee(false);
    }, []);

    const fetchingAddressInProgress = React.useCallback((id: string) => {
        fetchingRowDataInProgress(`${delegate.id}_${id}`);
    }, [delegate, fetchingRowDataInProgress]);

    const fetchingAddressFinished = React.useCallback((id: string) => {
        fetchingRowDataFinished(`${delegate.id}_${id}`);
    }, [delegate, fetchingRowDataFinished]);

    const attendeeColumnWidth = React.useMemo(() =>
        (eventInstanceHasCpc || delegateComparisonType === DelegateComparisonTypeEnum.Uin)
            ? isTrainerApp
                ? 6
                : 3
            : (isTrainerApp && !isEventInstanceAfv)
                ? 7
                : 4
    , [eventInstanceHasCpc, delegateComparisonType, isTrainerApp, isEventInstanceAfv]);
    const drivingLicenceColumnWidth = React.useMemo(() =>
        (eventInstanceHasCpc || delegateComparisonType === DelegateComparisonTypeEnum.Uin)
            ? isEditable
                ? isTrainerApp
                    ? 6
                    : 5
                : isTrainerApp
                    ? 4
                    : 3
            : isEditable
                ? (isTrainerApp && !isEventInstanceAfv)
                    ? 9
                    : 7
                : (isTrainerApp && !isEventInstanceAfv)
                    ? 6
                    : 4
    ,[eventInstanceHasCpc, delegateComparisonType, isEditable, isTrainerApp, isEventInstanceAfv]);
    const dqcOrUinColumnWidth = React.useMemo(() =>
        isEditable
            ? isTrainerApp
                ? 4
                : delegateComparisonType === DelegateComparisonTypeEnum.Uin
                    ? 4
                    : 3
            : isTrainerApp
                ? 3
                : delegateComparisonType === DelegateComparisonTypeEnum.Uin
                    ? 3
                    : 2
    ,[delegateComparisonType, isEditable, isTrainerApp]);
    const contactColumnWidth = React.useMemo(() =>
        (delegateComparisonType === DelegateComparisonTypeEnum.Uin && !isTrainerApp)
            ? 4
            : 5
    , [delegateComparisonType, isTrainerApp]);

    const removeAttendeeeIdentification = React.useMemo(() => {
        let delegateIdentification = "";
        if (delegate.forename) {
            delegateIdentification = delegate.forename;
        }

        if (delegate.surname) {
            delegateIdentification = delegateIdentification ? `${delegateIdentification} ${delegate.surname}` : delegate.surname;
        }

        if (delegate.uin) {
            delegateIdentification = delegateIdentification ? `${delegateIdentification} (${delegate.uin})` : delegate.uin;
        }

        if (delegate.drivingLicenceNumber) {
            delegateIdentification = delegateIdentification ? `${delegateIdentification} (${delegate.drivingLicenceNumber})` : delegate.drivingLicenceNumber;
        }

        return delegateIdentification;
    }, [delegate]);

    const canRemoveDelegate = React.useMemo(() =>
        isEditable && ((isAdminApp && canChangeDelegateAmount && delegate.correlatedOrderId === null) || delegate.notSaved),
    [canChangeDelegateAmount, delegate.correlatedOrderId, delegate.notSaved, isAdminApp, isEditable]);

    return (
        (<>
            {isEditable &&
            <UpdateDelegatesComparisonModal
                open={!!attendeeDelegateData?.delegateId}
                comparisonFields={comparisonFields}
                attendeeManualData={attendeeManualData}
                attendeeDelegateData={attendeeDelegateData}
                delegateComparisonType={delegateComparisonType}
                onCancel={onComparisonCancel}
                onContinue={onComparisonContinue}
            />}
            <Confirm
                open={removingAttendee}
                header="Remove delegate"
                content={`Are you sure you want to remove delegate ${removeAttendeeeIdentification}?`}
                cancelButton={cancelProps}
                confirmButton={confirmProps}
                onConfirm={confirmRemove}
                onCancel={cancelRemove}
            />
            <Grid.Row className={delegate.isBookingCanceled ? "light-grey full-width-input-form" : "full-width-input-form"}>
                <Grid.Column computer={attendeeColumnWidth} tablet={12} mobile={12}>
                    <DelegateBasicDetails
                        isEventInstanceMultiDay={isEventInstanceMultiDay}
                        isEventInstanceOpen={isEventInstanceOpen}
                        organisationId={organisationId}
                        isEditable={isEditable}
                        delegate={delegate}
                        organisationOptions={organisationOptions}
                        onOrganisationChange={onOrganisationChange}
                        fieldsForMarkup={fieldsForMarkup}
                        onForenameChange={onForenameChange}
                        isTrainerApp={isTrainerApp}
                        eventInstanceId={eventInstanceId}
                        onSurnameChange={onSurnameChange}
                        isStageTwo={isStageTwo}
                        hasIssues={hasIssues}
                        disableRow={disableRow}
                        onAttendeeRegisterUpdated={onAttendeeRegisterUpdated}
                        attendeeFields={loadedAttendeeFields}
                        onAttendeeFieldValueChange={onAttendeeFieldValueChange}
                        addAttendeeFieldsToLoadedFields={addAttendeeFieldsToLoadedFields}
                        departments={loadedDepartments}
                        onDepartmentsChange={onDepartmentsChange}
                        addDepartmentsToLoadedDepartments={addDepartmentsToLoadedDepartments}
                        submitted={submitted}
                        hasUin={delegateComparisonType === DelegateComparisonTypeEnum.Uin}
                        onUinChange={onUinChange}
                        canRemoveDelegate={canRemoveDelegate}
                        onRemoveClick={onRemoveClick}
                        eventInstanceGroupDays={eventInstanceGroupDays}
                        onDaysChange={onDaysChange}
                    />
                </Grid.Column>
                <Grid.Column only="tablet mobile">
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={drivingLicenceColumnWidth} tablet={12} mobile={12}>
                    <LicenceDetails
                        isEditable={isEditable}
                        fieldsForMarkup={fieldsForMarkup}
                        delegate={delegate}
                        onDrivingLicenceCountryChange={onDrivingLicenceCountryChange}
                        isEventInstanceOpen={isEventInstanceOpen}
                        onDrivingLicenceNumberChange={onDrivingLicenceNumberChange}
                        driverLicenceValidation={driverLicenceValidation}
                        driverLicenceNumberKey={driverLicenceNumberKey}
                        vocationalLicenceCategoryOptions={vocationalLicenceCategoryOptions}
                        onVocationalLicenceCategoriesArrayChange={onVocationalLicenceCategoriesArrayChange}
                        onDrivingLicenceExactCountryChange={onDrivingLicenceExactCountryChange}
                        onDateOfBirthChange={onDateOfBirthChange}
                        isEventInstanceCpc={eventInstanceHasCpc}
                        isEventInstanceAfv={isEventInstanceAfv}
                        submitted={submitted}
                    />
                </Grid.Column>
                <Grid.Column only="tablet mobile" className={"padding-bottom"}>
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                </Grid.Column>
                {eventInstanceHasCpc && (
                    <>
                        <Grid.Column computer={dqcOrUinColumnWidth} tablet={12} mobile={12} className="dqc-details">
                            <DqcDetails isEditable={isEditable}
                                fieldsForMarkup={fieldsForMarkup}
                                delegate={delegate}
                                onDqcReferenceChange={onDqcReferenceChange}
                                onDqcExpiryChange={onDqcExpiryChange}
                                isEventInstanceOpen={isEventInstanceOpen}
                                submitted={submitted}
                            />
                        </Grid.Column>
                        <Grid.Column only="tablet mobile" className={"padding-bottom"}>
                            <Grid.Row>
                                <Divider />
                            </Grid.Row>
                        </Grid.Column>
                    </>
                )}
                {(!isTrainerApp || isEventInstanceAfv) &&
                    <>
                        <Grid.Column
                            computer={contactColumnWidth}
                            tablet={12}
                            mobile={12}
                            only={!isEventInstanceAfv ? "computer" : undefined}
                            className="contact-details"
                        >
                            <Grid>
                                {!isTrainerApp && (
                                    <>
                                        <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                            <Grid.Row className="purple-header">
                                                Email
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                            <Grid.Column className={fieldsForMarkup.some(f => f === "email") ? "amber-field" : ""}>
                                                {isEditable
                                                    ? <Input.Text
                                                        value={delegate.email ?? ""}
                                                        onChange={onEmailChange}
                                                        onBlur={onEmailBlur}
                                                        placeholder={"Email"}
                                                        disabled={isEventInstanceOpen && !delegate.organisationId}
                                                        validation={[validators.validEmail()]}
                                                        showErrors={submitted}
                                                    />
                                                    : delegate.email
                                                        ? <span className="break-all">{delegate.email}</span>
                                                        : <Icon className="validation-icon" name={"exclamation circle"} />
                                                }
                                                {delegate.email && delegate.mxEmailCheckResult && !delegate.mxEmailCheckResult.valid && (
                                                    <p><Icon className="validation-icon" name={"exclamation circle"} />{delegate.mxEmailCheckResult.error}</p>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                            <Grid.Row className="purple-header">
                                                Contact number
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                            <Grid.Column className={fieldsForMarkup.some(f => f === "telephone") ? "amber-field" : ""}>
                                                {isEditable
                                                    ? <Input.Text
                                                        value={delegate.telephone ?? ""}
                                                        onChange={onTelephoneChange}
                                                        placeholder={"Contact number"}
                                                        validation={phoneNumberWithSpaces()}
                                                        disabled={isEventInstanceOpen && !delegate.organisationId}
                                                        showErrors={submitted}
                                                    />
                                                    : delegate.telephone
                                                        ? <span className="break-all">{delegate.telephone}</span>
                                                        : <Icon className="validation-icon" name={"exclamation circle"} />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                                {isEventInstanceAfv && (
                                    <>
                                        <Grid.Column only="tablet mobile" className="mobile-column" width={12}>
                                            <Grid.Row className="purple-header">
                                                Address
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Row className={!isEditable ? "no-padding" : "editable-no-padding"}>
                                            <Grid.Column className={fieldsForMarkup.some(f => f === "address") ? "amber-field" : ""}>
                                                {isEditable
                                                    ? <AddressLookup
                                                        address={delegate.address}
                                                        onChange={onAddressChange}
                                                        disabled={isEventInstanceOpen && !delegate.organisationId}
                                                        required={false}
                                                        showErrors={submitted}
                                                        compact
                                                        fetchingDataInProgress={fetchingAddressInProgress}
                                                        fetchingDataFinished={fetchingAddressFinished}
                                                    />
                                                    : (<>
                                                        {delegate.address?.addressLine1
                                                            && <span className="break-all">{delegate.address.addressLine1}<br /></span>}
                                                        {delegate.address?.addressLine2
                                                            && <span className="break-all">{delegate.address.addressLine2}<br /></span>}
                                                        {delegate.address?.addressLine3
                                                            && <span className="break-all">{delegate.address.addressLine3}<br /></span>}
                                                        {delegate.address?.city
                                                            && <span className="break-all">{delegate.address.city}<br /></span>}
                                                        {delegate.address?.postalCode
                                                            && <span className="break-all">{delegate.address.postalCode}<br /></span>}
                                                    </>)
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                            </Grid>
                        </Grid.Column>
                        <Grid.Column only="tablet mobile" className={"padding-bottom"}>
                            <Grid.Row>
                                <Divider />
                            </Grid.Row>
                        </Grid.Column>
                    </>
                }
                {delegateComparisonType === DelegateComparisonTypeEnum.Uin && (
                    <>
                        <Grid.Column computer={dqcOrUinColumnWidth} tablet={12} mobile={12} className="uin-details">
                            <ManagerDetails isEditable={isEditable}
                                fieldsForMarkup={fieldsForMarkup}
                                delegate={delegate}
                                onManagerValueChange={onManagerValueChange}
                                onRemoveManager={onRemoveManager}
                                onAddManager={onAddManager}
                                submitted={submitted}
                            />
                        </Grid.Column>
                        <Grid.Column only="tablet mobile" className={"padding-bottom"}>
                            <Grid.Row>
                                <Divider />
                            </Grid.Row>
                        </Grid.Column>
                    </>
                )}
                {!isEditable &&
                    <DelegateStateToggle
                        isStageTwo={isStageTwo}
                        showCompletionError={showCompletionError}
                        completionState={completionState}
                        delegate={delegate}
                        completionDisabled={completionDisabled}
                        handleCompletedChange={handleCompletedChange}
                        hasBeenSubmitted={hasBeenSubmitted}
                        updatingCompletion={updatingCompletion}
                        onUpdateCompletion={onUpdateCompletion}
                        handleAttendedChange={handleAttendedChange}
                        isEditable={isEditable}
                        hasIssues={hasIssues}
                        disableRow={disableRow}
                        onAttendeeRegisterUpdated={onAttendeeRegisterUpdated}
                        isEventInstanceAfv={isEventInstanceAfv}
                        forcedEventInstanceProcessing={eventInstanceOptions.forcedEventInstanceProcessing}
                    />}

            </Grid.Row>
            {(!isEditable && reasonIssue !== ReasonIssueEnum.None) && (
                <Grid.Row>
                    <Grid.Column>
                        <Grid.Row className="no-padding">
                            <Grid.Column width="16">
                                <span className="raised-issue-detail"><b>Reason:</b> {ReasonIssue[reasonIssue]}</span>
                            </Grid.Column>
                        </Grid.Row>
                        {issueText &&
                            <Grid.Row className="no-padding">
                                <Grid.Column width="16">
                                    <span className="raised-issue-detail">{issueText}</span>
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </Grid.Column>
                </Grid.Row>
            )}
        </>)
    );
};

