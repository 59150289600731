import * as React from "react";
import { Message } from "semantic-ui-react";
import { DigitalPlanningSessionsState } from "../../model";

export interface DigitalPlanningMessageState {
    state: DigitalPlanningSessionsState;
    errorMessage: string;
    courseCreationError: boolean;
}

interface DigitalPlanningEditMessageProps {
    state: DigitalPlanningMessageState;
}

export const DigitalPlanningEditMessage: React.FC<DigitalPlanningEditMessageProps> = ({ state }) => {

    const messageText = state.state === DigitalPlanningSessionsState.Creating
        ? "You cannot edit this planning session as the courses are currently being created."
        : "These courses have already been created, please start a new planning session.";

    const courseCreationErrorMessage =
    <>The last course creation attempt for this plan was unsuccessful, please fix the plan and try again. <strong>Reason: {state.errorMessage}</strong></>;

    const courseCreationErrorMessageVisible = state.courseCreationError === true && state.state < DigitalPlanningSessionsState.Creating;

    return (
        <>
            <Message visible={state.state > DigitalPlanningSessionsState.InProgress} content={messageText} warning icon={"info circle"} />
            { courseCreationErrorMessageVisible && <Message visible content={courseCreationErrorMessage} info icon={"info circle"} />}
        </>
    );
};
