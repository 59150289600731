/* eslint-disable max-lines */
import * as React from "react";
import { Container, Grid, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { eventInstancesSelector } from "../../selectors";
import { loadEventInstances } from "../..";
import moment from "moment";
import { Calendar } from "./Calendar";
import { loadOrganisations } from "@common/crud/organisation";
import { loadVenues, Venue } from "@common/crud/venue";
import { onsiteVenuesSelector } from "@common/crud/venue/selectors";
import { CreationWizard } from "./CreationWizard";
import { loadEventTypes, EventType } from "@common/crud/eventType";
import { eventTypeOptionSelector, allEventTypesSelector } from "@common/crud/eventType/selectors";
import { EventInstanceCreateModel, LanguageEnum, Area, BookingAvailabilityTypeEnum, WorkflowTypeToEventTypeCategory } from "../../model";
import { EventTable } from "./EventTable";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import {
    GetEventClassroomRegistrationDuration, GetEventDigitalRegistrationDuration,
    GetEventDuration, GetEventMaxClassroomAttendees, GetEventMaxDigitalAttendees, GetEventPracticalDuration, GetEventRegistrationEndTime,
    GetEventTheoryDuration, ModuleType, WorkflowTypeEnum, ModuleTypeEnum,
} from "@common/crud/eventType/model";
import { TrainerAttributeDefinition, loadTrainerAttributeDefinitions } from "@common/crud/trainerAttributeDefinition";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { v4 } from "uuid";
import { Filters, FilterProps } from "./Filters";
import { ConfirmButton } from "@common/components";
import { createEventInstances, loadEventInstancesAndGroups } from "../../actions";
import { AllItems } from "../AllItems";
import { toast } from "@common/toasts";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { nonExpiredEventTypes } from "@common/helpers/expiredEventTypes";

export interface WizardSettings {
    eventType: EventType;
    eventsPerDay: number;
}

function CreateEvent(
    eventType: EventType,
    venue: Venue,
    _trainerAttributeDefinitions: TrainerAttributeDefinition[],
    startDate: moment.Moment,
    startTime: moment.Duration,
    practicalStartTime?: moment.Duration,
    dayParts?: string[],
    id?: string,
    duration?: moment.Duration): EventInstanceCreateModel {

    const moduleType = ModuleType[eventType.moduleType];
    const registrationDuration = venue.deliveryType === DeliveryTypeEnum.Digital
        ? GetEventDigitalRegistrationDuration(startDate, eventType) : GetEventClassroomRegistrationDuration(startDate, eventType);

    return {
        venueId: venue.id,
        venueName: venue.name,
        forceId: venue.forceId,
        venueDorsId: venue.dorsId,
        eventInstanceDeliveryType: venue.deliveryType,
        maxNumberOfAttendees: venue.deliveryType === DeliveryTypeEnum.Digital ?
            GetEventMaxDigitalAttendees(startDate, eventType) :
            GetEventMaxClassroomAttendees(startDate, eventType),
        registrationEndTime: GetEventRegistrationEndTime(startTime, registrationDuration),
        eventTypeId: eventType.id,
        eventTypeName: eventType.name,
        maxNumberOfAttendeesPerPracticalTrainer: eventType.maxNumberOfAttendeesPerPracticalTrainer,
        eventDuration: duration ?? GetEventDuration(startDate, venue.deliveryType, eventType),
        moduleType,
        durationType: eventType.durationType,
        oneToOne: false,
        workflowType: eventType.workflowType,
        schemeId: eventType.dorsId,
        eventTypeAbbreviation: eventType.abbreviation,
        startDate,
        startTime,
        language: LanguageEnum.English,
        id: id ?? v4(),
        availableInBookingJourney: BookingAvailabilityTypeEnum.Both,
        dayParts,
        eventTypeCategory: WorkflowTypeToEventTypeCategory[eventType.workflowType],
        theoryStartTime: eventType.moduleType === ModuleTypeEnum.Both ? startTime : undefined,
        theoryDuration: eventType.moduleType === ModuleTypeEnum.Both ? GetEventTheoryDuration(startDate, eventType) : undefined,
        practicalStartTime: eventType.moduleType === ModuleTypeEnum.Both ? practicalStartTime : undefined,
        practicalDuration: eventType.moduleType === ModuleTypeEnum.Both ? GetEventPracticalDuration(startDate, eventType) : undefined,
        flexibleCertificatesRequired: eventType.flexibleCertificatesRequired,
    };
}

const getEventTypeFilter = (eventTypeCategory: EventTypeCategory) => {
    if (eventTypeCategory === EventTypeCategory.Dors) {
        return (e: EventType) => e.workflowType === WorkflowTypeEnum.Dors;
    }

    if (eventTypeCategory === EventTypeCategory.Ddrs) {
        return (e: EventType) => e.workflowType === WorkflowTypeEnum.DDRS;
    }
    return () => true;
};

interface BulkBaseCreateProps {
    eventTypeCategory: EventTypeCategory;
}

const BulkBaseCreate: React.FC<BulkBaseCreateProps> = ({ eventTypeCategory }) => {
    const [filters, setFilters] = React.useState<FilterProps>({} as any);
    const [calendarDate, setCalendarDate] = React.useState<moment.Moment>(moment());
    const [isWizardEnabled, setIsWizardEnabled] = React.useState<boolean>(false);
    const [wizardSettings, setWizardSettings] = React.useState<WizardSettings>();
    const [isWizardCompleted, setIsWizardCompleted] = React.useState<boolean>(false);
    const [plannedEvents, setPlannedEvents] = React.useState<EventInstanceCreateModel[]>([]);
    const [creationMode, setCreationMode] = React.useState<boolean>(false);
    const [scheduleViewDate, setScheduleViewDate] = React.useState<moment.Moment>(null);
    const [venue, setVenue] = React.useState<Venue>(null);

    const eventInstances = useSelector(eventInstancesSelector);

    const eventTypes = useSelector(allEventTypesSelector)?.filter(getEventTypeFilter(eventTypeCategory))?.filter(et => nonExpiredEventTypes(et.expiryDate));
    const eventTypeOptions = useSelector(eventTypeOptionSelector)?.filter(e => eventTypes.findIndex(t => t.id === e.value) !== -1);
    const venues = useSelector(onsiteVenuesSelector);
    const trainerAttributeDefinition = useSelector(trainerAttributeDefinitionsSelector);

    const dispatch = useDispatch();

    const isDdrs = eventTypeCategory === EventTypeCategory.Ddrs;
    const isDors = eventTypeCategory === EventTypeCategory.Dors;

    React.useEffect(() => {
        if (((filters.forceId && isDors) || (filters.dsaAreaId && isDdrs)) && filters.venueId) {
            setIsWizardEnabled(true);
        } else {
            setIsWizardEnabled(false);
        }
    }, [filters, isDdrs, isDors]);

    // eslint-disable-next-line react-hooks/exhaustive-deps, eqeqeq
    React.useEffect(() => setVenue(filters?.venueId != null ? venues.find(x => x.id === filters.venueId) : null), [filters?.venueId]);

    React.useEffect(() =>
        setIsWizardCompleted(wizardSettings?.eventType && (isDdrs || (wizardSettings?.eventsPerDay > 0 ?? false))), [wizardSettings, isDdrs]);

    React.useEffect(() => {
        dispatch(loadEventTypes());
        dispatch(loadOrganisations());
        dispatch(loadVenues({ options: { deliveryType: DeliveryTypeEnum.Onsite } }));
        dispatch(loadTrainerAttributeDefinitions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addNewCourses = React.useCallback((day: moment.Moment) => {

        if (isWizardCompleted) {
            if (venue.expiryDate && day.isSameOrAfter(venue.expiryDate)) {
                toast.error("Cannot add course. The selected venue will have expired on the chosen date.");
                return;
            }

            const isDigitalVenue = venue.deliveryType === DeliveryTypeEnum.Digital;

            let newEvents: EventInstanceCreateModel[] = [];
            if (isDdrs) {
                const dayPartsDictionary = isDigitalVenue ? wizardSettings.eventType.digitalEventTypeDetails?.digitalEventTypeParts
                    : wizardSettings.eventType.classroomEventTypeDetails.classroomEventTypeParts;
                const dayParts = Object.keys(dayPartsDictionary);
                const ids = dayParts.map(() => v4());
                newEvents = dayParts.map((s, index) => CreateEvent(
                    wizardSettings.eventType,
                    venue,
                    trainerAttributeDefinition,
                    day.clone().add((index * 7), "days"),
                    dayPartsDictionary[s].suggestedStartTime,
                    null,
                    ids,
                    ids[index],
                    dayPartsDictionary[s].eventDuration
                ));
            }

            if (isDors) {
                if (venue.deliveryType !== DeliveryTypeEnum.Digital && wizardSettings.eventType.moduleType === ModuleTypeEnum.Both) {
                    const theoryTimes = wizardSettings.eventType.classroomEventTypeDetails.suggestedStartTimesForTheorySessions[wizardSettings.eventsPerDay];
                    const practicalTimes =
                        wizardSettings.eventType.classroomEventTypeDetails.suggestedStartTimesForPracticalSessions[wizardSettings.eventsPerDay];

                    if (!theoryTimes || !practicalTimes || theoryTimes.length !== practicalTimes.length) {
                        toast.error(`Cannot add course for this scheme.
                        Please update a scheme with correct number of start times for ${wizardSettings.eventsPerDay} per day`);
                        return;
                    }

                    for (let i = 0; i < wizardSettings.eventsPerDay; i++) {
                        newEvents.push(CreateEvent(wizardSettings.eventType, venue, trainerAttributeDefinition, day, theoryTimes[i], practicalTimes[i]));
                    }
                } else {
                    const suggestedTimes = venue.deliveryType === DeliveryTypeEnum.Digital ?
                        wizardSettings.eventType.digitalEventTypeDetails.digitalEventSuggestedStartTimesForSessions[wizardSettings.eventsPerDay] :
                        wizardSettings.eventType.classroomEventTypeDetails.suggestedStartTimesForSessions[wizardSettings.eventsPerDay];

                    newEvents = suggestedTimes.map(time =>
                        CreateEvent(wizardSettings.eventType, venue, trainerAttributeDefinition, day, time));
                }
            }
            setPlannedEvents([...plannedEvents, ...newEvents]);
        }
    }, [isWizardCompleted, venue, isDdrs, isDors, plannedEvents, wizardSettings?.eventType, wizardSettings?.eventsPerDay, trainerAttributeDefinition]);

    const onCreationModeButtonClick = React.useCallback(() => {
        if (creationMode === false) {
            setCreationMode(true);
        } else {
            setCreationMode(false);
            setWizardSettings(null);
            setPlannedEvents([]);
        }
    }, [creationMode]);

    const removePlannedCourse = React.useCallback((id: string) => {
        const eventToDelete = plannedEvents.find(e => e.id === id);
        setPlannedEvents([...plannedEvents.filter(e => e.id !== id && (isDors || eventToDelete.dayParts?.includes(e.id) === false))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plannedEvents]);

    const removePlannedEvents = React.useCallback((day: number) => {
        const eventsToDelete = plannedEvents.filter(e => e.startDate.date() === day);
        setPlannedEvents([...plannedEvents.filter(e => e.startDate.date() !== day &&
            (isDors || eventsToDelete.some(d => d.dayParts?.includes(e.id) === false)))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plannedEvents]);

    const onMonthChange = React.useCallback((date: Date) => {
        setCalendarDate(moment(date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarDate]);

    const saveCourses = React.useCallback(() => {
        dispatch(createEventInstances(plannedEvents, true));
        setPlannedEvents([]);
        setCreationMode(false);
        setWizardSettings(null);
    }, [dispatch, plannedEvents]);

    React.useEffect(() => {
        const startOfMonth = calendarDate.clone().startOf("month");
        const endOfMonth = calendarDate.clone().endOf("month");

        const eventInstanceOptions = {
            options: {
                fromDate: startOfMonth,
                toDate: endOfMonth,
                venueId: [filters?.venueId],
                forceId: [filters?.forceId?.toString()],
                deliveryType: DeliveryTypeEnum.Onsite.toString(),
                maxPageSize: 200,
                workflowTypes: isDdrs ? [WorkflowTypeEnum.DDRS] : [WorkflowTypeEnum.Dors]
            }
        };

        dispatch(isDdrs ? loadEventInstancesAndGroups(eventInstanceOptions) : loadEventInstances(eventInstanceOptions));
    }, [dispatch, filters, calendarDate, isDdrs]);

    const onLanguageChange = React.useCallback((id: string, language: LanguageEnum) => {
        const eventToChange = plannedEvents.find(e => e.id === id);
        plannedEvents.filter(x => x.id === id || eventToChange.dayParts?.includes(x.id)).forEach(e => e.language = language);
        setPlannedEvents([...plannedEvents]);
    }, [plannedEvents]);

    const onViewModeDayClick = React.useCallback((date: Date) => {
        // eslint-disable-next-line eqeqeq
        if (date == null) {
            setScheduleViewDate(null);
            return;
        }

        const courseDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).startOf("day");
        setScheduleViewDate(moment(courseDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInstances]);

    const onStartTimeChange = React.useCallback((id: string, startTime: moment.Duration) => {
        plannedEvents.find(x => x.id === id).startTime = startTime;
        setPlannedEvents([...plannedEvents]);
    }, [plannedEvents]);

    const onStartDateChange = React.useCallback((id: string, startDate: moment.Moment) => {
        plannedEvents.find(x => x.id === id).startDate = startDate.clone().startOf("day");
        setPlannedEvents([...plannedEvents]);
    }, [plannedEvents]);

    const creationButtonText = creationMode ? "Cancel creation" : "Create courses";

    return (
        <Container>
            <Grid className="ui form filter-font">
                <Filters eventTypeCategory={eventTypeCategory} disabled={creationMode} onFilterChange={setFilters} />

                <Grid.Row>
                    <Grid.Column width={12}>
                        <Calendar
                            editMode={isWizardCompleted}
                            eventInstances={eventInstances}
                            onMonthChange={onMonthChange}
                            venueSelected={!!filters?.venueId}
                            plannedEventInstances={plannedEvents}
                            addPlannedEvent={addNewCourses}
                            onViewModeDayClick={onViewModeDayClick}
                            removePlannedEvents={removePlannedEvents}
                        />
                        {
                            !isWizardCompleted && <AllItems
                                area={Area.AdminEventManagementCalendar}
                                eventInstances={eventInstances?.filter(x => moment.utc(x.startDate).startOf("day").isSame(scheduleViewDate ?? moment()))}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                {(plannedEvents?.length === 0 ?? true) && <Button
                                    fluid
                                    disabled={!isWizardEnabled}
                                    onClick={onCreationModeButtonClick}>
                                    {creationButtonText}
                                </Button>}
                                {(plannedEvents?.length > 0 &&
                                    <ConfirmButton
                                        fluid={true}
                                        header="Are you sure?"
                                        content="Are you sure you want to cancel? You will lose all current progress."
                                        disabled={!isWizardEnabled}
                                        onConfirm={onCreationModeButtonClick}>
                                        {creationButtonText}
                                    </ConfirmButton>)
                                }
                            </Grid.Row>
                            <Grid.Row>
                                {
                                    creationMode &&
                                    <CreationWizard
                                        isDigitalVenue={venue?.deliveryType === DeliveryTypeEnum.Digital}
                                        blockEventTypeChange={plannedEvents?.length > 0 ?? false}
                                        eventTypesOptions={eventTypeOptions}
                                        eventTypes={eventTypes}
                                        setDemandedCourseSettings={setWizardSettings}
                                        eventTypeCategory={eventTypeCategory}
                                    />
                                }
                                {
                                    plannedEvents?.length > 0 &&
                                    <>
                                        <EventTable
                                            onLanguageChange={onLanguageChange}
                                            deleteRow={removePlannedCourse}
                                            eventInstances={plannedEvents}
                                            onStartTimeChange={onStartTimeChange}
                                            eventTypeCategory={eventTypeCategory}
                                            onStartDateChange={onStartDateChange}
                                        />
                                        <ConfirmButton
                                            fluid={true}
                                            onConfirm={saveCourses}
                                            header="Are you sure"
                                            content={"Are you sure you want to create these courses?"}
                                        >
                                            Create courses
                                        </ConfirmButton>
                                    </>
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export { BulkBaseCreate };
