import * as React from "react";
import { Table } from "semantic-ui-react";
import { CorporateEventInstanceResultRow, BasketDifference } from "../model";
import { numberFormatter } from "@common/helpers/number-format";
import "./BasketModalItem.scss";
import "./RelatedOrganisationChangedModal.scss";

export interface BasketDifferenceItemProps {
    basketDifference: BasketDifference;
    course: CorporateEventInstanceResultRow;
}

export const BasketDifferenceItem: React.FC<BasketDifferenceItemProps> = ({ basketDifference, course }) => {
    return (
        <Table.Row>
            <Table.Cell colSpan={3}>
                <div className="title-column-width basket-item-details">
                    <div>
                        {course?.eventTypeName}
                    </div>
                    <div>
                        {course?.startTimes[0]?.format("ddd, DD MMM HH:mm")} - {course?.endTimes[0]?.format("HH:mm")}
                    </div>
                </div>
            </Table.Cell>
            <Table.Cell colSpan={6}>
                {basketDifference.priceChanged ? (
                    <>
                        <span className="old-value">&pound;{numberFormatter(basketDifference.oldPricePerSeatInPence / 100)}</span>
                        <span> =&gt; </span>
                        <span className="new-value">&pound;{numberFormatter(basketDifference.newPricePerSeatInPence / 100)}</span>
                    </>
                ) : (
                    <span>&pound;{numberFormatter(basketDifference.newPricePerSeatInPence / 100)}</span>
                )}
            </Table.Cell>
            <Table.Cell colSpan={1} className="fixed-column-width">
                <span>{basketDifference.numberOfSeats}</span>
            </Table.Cell>
            <Table.Cell colSpan={6} className="fixed-column-width">
                {basketDifference.priceChanged ? (
                    <>
                        <span className="old-value">&pound;{numberFormatter(basketDifference.oldTotalPriceInPence / 100)}</span>
                        <span> =&gt; </span>
                        <span className="new-value">&pound;{numberFormatter(basketDifference.newTotalPriceInPence / 100)}</span>
                    </>
                ) : (
                    <span>&pound;{numberFormatter(basketDifference.newTotalPriceInPence / 100)}</span>
                )}
            </Table.Cell>
        </Table.Row>
    );
};
