import { AttendeeListModel, CompletionState, RegisterAttendeeModel } from "@common/crud/attendee";
import { MultiDropVehicleSpecificReasons, ReasonIssue, ReasonIssueEnum } from "@common/crud/attendee/model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { EventInstance, EventInstanceAttendeeField } from "../../model";
import moment from "moment";
import { ProductCategoryEnum } from "@common/crud/eventType/model";

export interface RegisterStats {
    totalPlacesBooked: number;
    totalArrived: number;
    totalCompleted: number;
}

export function getRegisterStats(
    attendees: AttendeeListModel[],
    isEventInstanceMultiDay?: boolean,
    eventInstanceId?: string,
    totalPlacesBooked?: number,
    completionRegister?: AttendeeListModel[]): RegisterStats {
    const filteredAttendees = isEventInstanceMultiDay
        ? attendees.filter(a => a.eventInstanceId === eventInstanceId)
        : attendees;

    const totalArrived = filteredAttendees.filter(a => a.didAttend === true).length;

    // Only non cancelled attendees can have their courses completed
    const attendingAttendees = getAttendingAttendees(filteredAttendees);
    const totalCompleted = completionRegister && completionRegister.length > 0
        ? attendingAttendees.filter(a => completionRegister.find(r => r.id === a.id)?.completed === CompletionState.Completed).length
        : filteredAttendees.filter(a => a.completed === CompletionState.Completed).length;

    return {
        totalArrived,
        totalCompleted,
        totalPlacesBooked: totalPlacesBooked ?? filteredAttendees.length
    };
}

export interface FilteredAttendeesData {
    totalPlacesBooked: number;
    noCancelledAttendee: boolean;
    sortedAttendees: AttendeeListModel[];
}

function getAttendingAttendees(attendees: AttendeeListModel[]) {
    return attendees.filter(a => a.isBookingCanceled === false);
}

export function getFilterAttendeesData(
    attendees: AttendeeListModel[],
    eventInstanceStartDateTime: moment.Moment,
    showRemovedAttendees: boolean): FilteredAttendeesData {

    // Attendees cancelled after course started
    const cancelledAttendees = attendees.filter(a =>
        a.isBookingCanceled === true &&
        a.cancellationDate !== null &&
        a.cancellationDate !== undefined &&
        a.cancellationDate?.clone()?.isAfter(eventInstanceStartDateTime));

    // Attendees attending the course
    const attendingAttendees = getAttendingAttendees(attendees);

    const filteredAttendees = showRemovedAttendees
        ? [...attendingAttendees, ...cancelledAttendees]
        : attendingAttendees;

    const totalPlacesBooked = attendingAttendees.length + cancelledAttendees.length;

    const noCancelledAttendee = cancelledAttendees.length === 0;

    const sortedAttendees = filteredAttendees
        .sort((a: AttendeeListModel, b: AttendeeListModel) => a.fullName?.localeCompare(b.fullName));

    return {
        totalPlacesBooked,
        noCancelledAttendee,
        sortedAttendees,
    };
}

export function mapAttendanceRegisterAttendees(attendees: AttendeeListModel[]) {
    // Register only contains attendees whose booking is not cancelled
    const attendingAttendees = getAttendingAttendees(attendees);
    return attendingAttendees.map<RegisterAttendeeModel>(a => ({
        id: a.id,
        didAttend: a.didAttend,
        completed: a.completed,
        registerSubmitted: false,
        issue: a.issue,
        reasonIssue: a.reasonIssue,
        dateModified: a.dateModified,
    }));
}

export function mapCompletionRegisterAttendees(attendees: AttendeeListModel[], registerAttendees?: AttendeeListModel[],
    isEventInstanceMultiDay?: boolean, eventInstanceId?: string) {
    // Register only contains attendees whose booking is not cancelled
    const filteredAttendees = isEventInstanceMultiDay
        ? attendees.filter(a => a.eventInstanceId === eventInstanceId)
        : attendees;
    const attendingAttendees = getAttendingAttendees(filteredAttendees);
    return attendingAttendees.map<AttendeeListModel>(attendee => {

        // When called from discard callback we will revert to original values from attendee
        let register = attendee as AttendeeListModel;
        if (registerAttendees) {
            register = registerAttendees.find(r => r.id === attendee.id);
        }

        return {
            id: attendee.id,
            didAttend: attendee.didAttend,
            completed: attendee.didAttend ? register.completed : CompletionState.NotCompleted,
            registerSubmitted: false,
            issue: register.issue,
            reasonIssue: register.reasonIssue,
            dateModified: attendee.dateModified,
            score: register.score,
        };
    });
}

export interface EventInstanceOptions {
    eventInstanceId: string;
    hasBeenSubmitted: boolean;
    updatesInProgress: boolean;
    isDigitalEventInstance: boolean;
    attendeesRegisterDate?: moment.Moment;
    eventInstanceStartDateTime?: moment.Moment;
    eventInstanceFinished?: boolean;
    registerClosed?: boolean;
    attendeeFields?: Record<string, EventInstanceAttendeeField[]>;
    organisationId?: string;
    isMultiDropVehicle?: boolean;
    forcedEventInstanceProcessing?: boolean;
}

export function getEventInstanceOptions(eventInstance: EventInstance): EventInstanceOptions {

    const hasBeenSubmitted = eventInstance.attendeesRegisterDate !== null;
    const eventInstanceDate = eventInstance.startDate && eventInstance.startDate.clone().startOf("day");
    const eventInstanceStartDateTime = eventInstanceDate && eventInstanceDate.add(eventInstance.startTime);
    const eventInstanceFinished = eventInstance.deliveryDateTimeEnd?.isBefore(moment().utc());
    const isDigitalEventInstance = eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital;
    const registerClosed = (eventInstanceFinished ?? false) && !!eventInstance.registerProcessedDate;
    const attendeeFields = eventInstance.attendeeFields;
    const organisationId = eventInstance.corporateOrganisationId;
    const isMultiDropVehicle = eventInstance.productCategory === ProductCategoryEnum.MultiDropVehicle;
    const forcedEventInstanceProcessing = eventInstance.forceRegisterProcessing;

    return {
        attendeesRegisterDate: eventInstance.attendeesRegisterDate,
        eventInstanceId: eventInstance.id,
        updatesInProgress: eventInstance.updatesInProgress,
        hasBeenSubmitted,
        isDigitalEventInstance,
        eventInstanceStartDateTime,
        eventInstanceFinished,
        registerClosed,
        attendeeFields,
        organisationId,
        isMultiDropVehicle,
        forcedEventInstanceProcessing
    };
}

export const MandatoryReasonIssue = [ReasonIssueEnum.HQReviewRequired, ReasonIssueEnum.OtherTermsAndConditionsBreach, ReasonIssueEnum.TechIssues];

export const getReasonIssueOptions = (completionState?: CompletionState, isDigitalEventInstance?: boolean, isMultiDropVehicle?: boolean) => {
    if (completionState === CompletionState.Completed) {
        return Object.keys(ReasonIssue)
            .filter(k => +k === ReasonIssueEnum.None || +k === ReasonIssueEnum.HQReviewRequired)
            .map(k => ({ text: ReasonIssue[k], value: +k }));
    } else {
        let reasons = Object.keys(ReasonIssue);
        if (isMultiDropVehicle) {
            reasons = reasons.filter(k => +k === ReasonIssueEnum.None || +k === ReasonIssueEnum.HQReviewRequired
                || MultiDropVehicleSpecificReasons.some(r => +k === r));
        } else {
            reasons = reasons.filter(k => MultiDropVehicleSpecificReasons.every(r => +k !== r));
        }

        if (isDigitalEventInstance) {
            return reasons.map(k => ({ text: ReasonIssue[k], value: +k }));
        } else {
            return reasons
                .filter(k => +k !== ReasonIssueEnum.TechIssues)
                .map(k => ({ text: ReasonIssue[k], value: +k }));
        }
    }
};

export const isCurrentDateTimeBeforeEventInstanceDateTime = (eventInstance: EventInstance) =>
    moment.utc().add(60, "minutes").isBefore(eventInstance.deliveryDateTime);
