import { createValidator } from "not-valid";

// Source: https://gist.github.com/danielrbradley/7567269
// eslint-disable-next-line max-len
const regex = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/;
// Current | Prefix | Suffix | DatelessLongNumberPrefix | DatelessShortNumberPrefix | DatelessLongNumberSuffix | DatelessShortNumberSuffix

// export const validVrm = createRegexValidator(regex, "Please enter a valid UK registration number");
export const validVrm = createValidator<string>(value => {
    if (!value) {
        return true;
    }
    const normalised = value.replace(/\s/g, "").toUpperCase();
    return regex.test(normalised);
}, "Please enter a valid UK registration number");

export const vrm = () => {
    return [validVrm];
};
