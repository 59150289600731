/* eslint-disable max-lines */
import { Location } from "redux-little-router";

import { Address } from "../common/Address";
import { EventInstancesWithOrdersState, OrdersState, PaymentState } from "./reducer";
import { HistoryRecord } from "@common/history/model";
import { PaymentType } from "@common/payments/model";
import { BookingTypeEnum } from "src/CorporateBooking.App/src/redux/eventInstance/model";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { ProductCategoryEnum } from "../eventType/model";
import { CustomerStatusEnum } from "../alaskaNudgeTask/model";
import { MxEmailCheckResult } from "../email/model";
import { BusinessLineType } from "@common/redux-helpers";
import { EnquiryType } from "../eventInstance/model";

export enum ShowOrdersBy {
    Order,
    EventInstance
}

export const paymentTypeOptions = [
    { value: PaymentType.Card, text: "Card" },
    { value: PaymentType.FakePayment, text: "Book now pay later" }
];

export type StartOrganisationPaymentResult = "NotCalled" | "Ok" | "SeatsExpired" | "Error" | "EmptyBasket" | "NoLongerValid" | "BasketGone";

export enum GenesysObjectType {
    DorsAttendee = 0,
    Order = 1,
    Basket = 2
}

export enum BookingSourceEnum {
    Unknown = 0,
    Online = 1,
    Offline = 2
}

export const BookingSource = {
    [BookingSourceEnum.Unknown]: "Unknown",
    [BookingSourceEnum.Online]: "Online",
    [BookingSourceEnum.Offline]: "Offline",
};
export interface StartOrganisationPaymentProcessRequest {
    id: string;
    basketId?: string;
    orderId: string;
    amount: number;
    stripePublishKey: string;
}

export interface OrganisationPaymentRequest {
    businessLineType: BusinessLineType;
    basketId: string;
    paymentMethodId?: string;
    paymentIntentId?: string;
    amount: number;
    orderId?: string;
}

export interface StartOrganisationPaymentProcessResult {
    status: StartOrganisationPaymentResult;
    startOrganisationPaymentProcessRequest: StartOrganisationPaymentProcessRequest;
}

export interface StartPaymentProcessModel {
    orderId: string;
    organisationId: string;
    amount: number;
}

export interface OrderListModel {
    readonly id?: string;
    readonly bookingReference?: string;
    readonly bookingDate?: moment.Moment;
    readonly organisationId?: string;
    readonly organisationName?: string;
    readonly bookerId?: string;
    readonly bookerName?: string;
    readonly eventInstances?: EventInstanceDetails[];
    readonly attendeeCount?: number;
    readonly total?: number;
    readonly cancellationDate?: moment.Moment;
    readonly cancellationReason?: number;
    readonly listUpdated?: moment.Moment;
}

export interface EventInstanceWithOrdersListModel {
    readonly id?: string;
    readonly venueName?: string;
    readonly organisationId: string;
    readonly organisationName: string;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly eventInstanceDeliveryDateTime?: moment.Moment;
    readonly eventInstanceDeliveryDateTimeEnd?: moment.Moment;
    readonly startTimes: moment.Moment[];
    readonly endTimes: moment.Moment[];
    readonly eventInstanceDeliveryType?: DeliveryTypeEnum;
    readonly cancelled?: boolean;
    readonly completed?: boolean;
    readonly attendeeCount?: number;
    readonly total?: number;
    readonly seatCount?: number;
    readonly openPlacesCount?: number;
    readonly priceInPence?: number;
    readonly cancellationDate?: moment.Moment;
    readonly cancellationReason?: number;
    readonly listUpdated?: moment.Moment;
}

export interface OrderDetailModel {
    readonly id?: string;
    readonly bookingSource?: BookingSourceEnum;
    readonly bookingReference?: string;
    readonly basketId?: string;
    readonly bookingDate?: moment.Moment;
    readonly organisationId?: string;
    readonly organisationName?: string;
    readonly organisationAddress?: Address;
    readonly organisationBdmId?: string;
    readonly organisationBdmName?: string;
    readonly organisationBdmEmail?: string;
    readonly bookerId?: string;
    readonly bookerForename?: string;
    readonly bookerSurname?: string;
    readonly bookerName?: string;
    readonly bookerEmail?: string;
    readonly bookerPhoneNumber?: string;
    readonly taxReceiptInformation?: TaxReceiptInformation;
    readonly eventInstances?: EventInstanceDetails[];
    readonly eventInstanceTotals?: { [key: string]: number };
    readonly eventInstanceTotalsIncludingNonRefundedCancellations?: { [key: string]: number };
    readonly eventInstanceTotalPayments?: { [key: string]: number };
    readonly eventInstanceTotalPendingRefunds?: { [key: string]: number };
    readonly eventInstancePaymentStatuses?: { [key: string]: OrderPaymentStatus };
    readonly attendeeCount?: number;
    readonly total?: number;
    readonly totalIncludingNonRefundedCancellations?: number;
    readonly totalPayments?: number;
    readonly totalPendingRefunds?: number;
    readonly cancellationDate?: moment.Moment;
    readonly cancellationReason?: number;
    readonly otherReason?: string;
    readonly detailUpdated?: moment.Moment;
    readonly history?: HistoryRecord[];
    readonly refundError?: string;
    readonly bundleDiscountApplied?: boolean;
    readonly bundleDiscountPercentageConfigured?: number;
    readonly bundleDiscountAppliedAtCreation?: boolean;
    readonly bookNowPayLater?: boolean;
    readonly invoiceReference?: string;
    readonly invoiceEmail?: string;
    readonly invoicePhoneNumber?: string;
    readonly invoiceAddress?: Address;
    readonly paymentStatus?: OrderPaymentStatus;
}

export enum OrderPaymentStatus {
    Unknown = 0,
    Outstanding = 1,
    Paid = 2,
    NotRequired = 3
}

export enum OrderRefundTypeEnum {
    None = 0,
    Half = 1,
    Full = 2,
}

export const OrderRefundType = {
    [OrderRefundTypeEnum.None]: "0%",
    [OrderRefundTypeEnum.Half]: "50%",
    [OrderRefundTypeEnum.Full]: "100%",
};

export const GetRefundFullPeriodForBusinessLine = (businessLineType: BusinessLineType) => {
    switch (businessLineType) {
        case BusinessLineType.Corporate:
            return 14;
        case BusinessLineType.Construction:
            return 10;
        default:
            return 0;
    }
};

export const GetRefundHalfPeriodForBusinessLine = (businessLineType: BusinessLineType) => {
    switch (businessLineType) {
        case BusinessLineType.Construction:
            return 20;
        default:
            return 0;
    }
};

export const BusinessLineSupportsHalfRefund = (businessLineType: BusinessLineType) => {
    return businessLineType === BusinessLineType.Construction;
};

export interface CancelFromOrderModel {
    readonly orderId: string;
    readonly reasonId: string;
    readonly eventInstanceId?: string;
    readonly otherReason?: string;
    readonly validationAmount?: number;
    readonly attendeesSelected?: string[];
    readonly cancelAll?: boolean;
    readonly bnplEventInstancesWherePaymentIsStillRequired?: string[];
    readonly bnplEventInstancesWherePaymentIsStillHalfRequired?: string[];
}

export type TaxReceiptType = "None" | "Individual" | "Business";

export interface TaxReceiptInformation {
    nameOnTaxReceipt: string;
    taxReceiptType: TaxReceiptType;
    hasCpcOpenBookNowPayLater?: boolean;
}

export interface EventInstanceDetails {
    readonly eventInstanceId?: string;
    readonly groupCorrelationId: string;
    readonly eventTypeId?: string;
    readonly eventTypeName?: string;
    readonly eventInstanceDeliveryDateTime?: moment.Moment;
    readonly eventInstanceStartDate?: moment.Moment;
    readonly eventInstanceStartTime?: moment.Duration;
    readonly eventInstanceDuration?: moment.Duration;
    readonly otherStartTimes?: OtherStartTime[];
    readonly bookingType?: BookingTypeEnum;
    readonly venueName?: string;
    readonly attendeeCount?: number;
    readonly cancelled?: boolean;
    readonly completed?: boolean;
    readonly cancellationDate?: moment.Moment;
    readonly cancellationReason?: number;
    readonly otherReason?: string;
}

export interface OtherStartTime {
    readonly eventInstanceDeliveryDateTime?: moment.Moment;
    readonly eventInstanceStartDate?: moment.Moment;
    readonly eventInstanceStartTime?: moment.Duration;
    readonly eventInstanceDuration?: moment.Duration;
}

export interface OrderListResponse {
    readonly orders: OrderListModel[];
    readonly total: number;
}

export interface EventInstanceWithOrdersResponse {
    readonly eventInstancesWithOrders: EventInstanceWithOrdersListModel[];
    readonly total: number;
}

export type Order = OrderListModel & OrderDetailModel;

export interface OrderEditModel {
    readonly organisationName?: string;
    readonly organisationBdmId?: string;
    readonly organisationBdmName?: string;
    readonly organisationBdmEmail?: string;
    readonly nameOnTaxReceipt?: string;
    readonly organisationAddress?: Address;
    readonly bookerForename?: string;
    readonly bookerSurname?: string;
    readonly bookerEmail?: string;
    readonly bookerPhoneNumber?: string;
}

export interface OrderEditModelValid {
    readonly organisationName?: boolean;
    readonly organisationBdmId?: boolean;
    readonly organisationBdmName?: boolean;
    readonly organisationBdmEmail?: boolean;
    readonly nameOnTaxReceipt?: boolean;
    readonly organisationAddress?: boolean;
    readonly bookerForename?: boolean;
    readonly bookerSurname?: boolean;
    readonly bookerEmail?: boolean;
    readonly bookerPhoneNumber?: boolean;
}

export enum ReminderType {
    One = 1,
    Two = 2,
    Three = 3
}

export enum RecipientType {
    Booker = 1,
    Delegates = 2
}

export interface OrderRefundResponseModel {
    readonly amount: number;
    readonly success: boolean;
    readonly message: string;
    readonly order: Order;
}

export const OtherCancellationReason = "Other";

export interface CorporateEventTypeResultRow {
    readonly id: string;
    readonly name: string;
    readonly deliveryType: DeliveryTypeEnum;
    readonly productIntro: string;
    readonly productDescription: string;
}

export interface CorporateEventInstanceResultRow {
    readonly id: string;
    readonly startTimes: moment.Moment[];
    readonly endTimes: moment.Moment[];
    readonly priceInPence: number;
    readonly deliveryType: DeliveryTypeEnum;
    readonly seatsAvailable: number;
    readonly eventTypeId: string;
    readonly eventTypeName: string;
    readonly productCategory: ProductCategoryEnum;
    readonly venueName: string;
    readonly venueTown: string;
    readonly locationDescription: string;
    readonly enquiryType: EnquiryType;
    readonly courseCategories: number[];
}

export interface BasketItem {
    eventInstanceId: string;
    numberOfSeats: number;
    pricePerSeatInPence: number;
    pricePerSeatExcludingVatInPence: number;
    totalPriceInPence: number;
    totalPriceExcludingVatInPence: number;
};

export type ChangeBasketStatusResult = {
    corporateBasket?: Basket;
    error?: string;
}

export type RefreshBasketBeforePaymentResult = {
    corporateBasket: Basket;
    error?: string;
}

export type OrderCorporateUser = {
    id?: string;
    forename?: string;
    surname?: string;
    email?: string;
    telephone?: string;
    upcomingNewsAndCoursesNewsletter?: boolean;
    optConstructionDelegatesIntoCourseClosureComms?: boolean;
}

export type OrderCorporateOrganisation = {
    id?: string;
    name?: string;
    taxReceiptType?: TaxReceiptType;
    billingAddress?: Address;
}

export interface OrderTaxReceiptInformation {
    nameOnTaxReceipt: string;
    type: TaxReceiptType;
    hasCpcOpenBookNowPayLater?: boolean;
}

export type Basket = {
    id: string;
    items: Record<string, BasketItem>;
    adminCoursesChosen: boolean;
    total: number;
    totalExcludingVat: number;
    discount: number;
    lockUser?: boolean;
    lockOrganisation?: boolean;
    orderCorporateUser?: OrderCorporateUser;
    orderCorporateOrganisation?: OrderCorporateOrganisation;
    orderTaxReceiptInformation?: OrderTaxReceiptInformation;
    relatedOrganisationId?: string;
    rebookedEventInstances?: {
        id: string;
        eventTypeName: string;
        rebookedAttendees: {
            id: string;
            delegateId?: string;
            fullName?: string;
            isBookingCanceled?: boolean;
            fromOrderId?: string;
            fromOrderWithBookNowPayLater?: boolean;
            fromBookingReference?: string;
            fromInvoiceReference?: string;
            rebookedToEventInstanceId?: string;
            rebookedToSeatId?: string;
            rebookedEventTypeName?: string;
            rebookedEventInstanceDeliveryDateTime?: moment.Moment;
            rebookedEventInstanceDeliveryDateTimeEnd?: moment.Moment;
            rebookedEventInstanceVenueName?: string;
            ignored?: boolean;
            oldPricePerSeatInPence?: number;
            newPricePerSeatExcludingVatInPence?: number;
            newPricePerSeatInPence?: number;
            newPricePerSeatInPenceWithoutDiscount?: number;
            feesDue?: boolean;
            feesHalfDue?: boolean;
            feesWaived?: boolean;
            feesZeroed?: boolean;
            noDiscountOnFutureRebooking?: boolean;
        }[];
        eventInstanceDeliveryDateTime: moment.Moment;
        eventInstanceDeliveryDateTimeEnd: moment.Moment;
    }[];
    offeredEventInstancesNewCounts?: {
        eventInstanceId: string;
        newCount: number;
    }[];
};

export interface BasketDifference {
    eventInstanceId: string;
    numberOfSeats: number;
    oldPricePerSeatInPence: number;
    newPricePerSeatInPence: number;
    oldTotalPriceInPence: number;
    newTotalPriceInPence: number;
    priceChanged: boolean;
}

export interface BasketComparisonResult {
    differences: BasketDifference[];
    oldTotal: number;
    newTotal: number;
    oldDiscount: number;
    newDiscount: number;
    discountChanged: boolean;
}

export interface DetailsAndBillingModel {
    userId: string;
    forename: string;
    surname: string;
    email: string;
    telephone: string;
    customerStatus: CustomerStatusEnum;
    organisationId: string;
    organisation: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postalCode: string;
    nameOnTaxReceipt: string;
    taxReceiptType: TaxReceiptType;
    hasCpcOpenBookNowPayLater?: boolean;
    upcomingNewsAndCoursesNewsletter: boolean;
    invoiceAddressLine1: string;
    invoiceAddressLine2: string;
    invoiceAddressLine3: string;
    invoicePostalCode: string;
    invoiceCity: string;
}

export interface CorporateUsersByEmail {
    users: DetailsAndBillingModel[];
    emailCheckResult: MxEmailCheckResult;
}

export enum DayOfWeekEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export const DayOfWeek = {
    [DayOfWeekEnum.Monday]: "Monday",
    [DayOfWeekEnum.Tuesday]: "Tuesday",
    [DayOfWeekEnum.Wednesday]: "Wednesday",
    [DayOfWeekEnum.Thursday]: "Thursday",
    [DayOfWeekEnum.Friday]: "Friday",
    [DayOfWeekEnum.Saturday]: "Saturday",
    [DayOfWeekEnum.Sunday]: "Sunday",
};

export interface OrderState {
    readonly ordersState: OrdersState;
    readonly eventInstancesWithOrdersState: EventInstancesWithOrdersState;
    readonly orderPaymentsState: PaymentState;
}

export type AppState = OrderState & { router: Location };

export interface SearchOptions {
    businessLineType?: BusinessLineType;
    showOrdersBy?: number;
    organisationId?: string;
    userId?: string;
    justForOrderId?: string;
    justForEventInstanceId?: string;
    eventInstanceId?: string;
    bookingReference?: string;
    bookerName?: string;
    eventTypeIds?: string[];
    fromDate?: moment.Moment;
    toDate?: moment.Moment;
    corporateOrganisationIds?: string[];
    noCheckForOrdersAttached?: boolean;
    originalEventInstanceId?: string;
    ignoreOriginalEventInstanceId?: boolean;
    rebookingAttendeeId?: string;
    rebookingAttendeeFeesFullyDue?: boolean;
    rebookingAttendeeFeesHalfDue?: boolean;
    rebookingAttendeeFeesZeroed?: boolean;
    ignoreEventInstancesFromDelegateId?: string;
    withFreeSeats?: boolean;
    includeEventInstanceIds?: string[];
    discountLost?: boolean;
    showCancelled?: boolean;
    showCompleted?: boolean;
    maxPageSize?: number;
    page?: number;
}

export interface AttendeeSearchOptions {
    orderId?: string;
    eventInstanceId?: string;
    corporateOrganisationId?: string;
    corporateUserId?: string;
    bookingReference?: string;
    bookerName?: string;
}

export interface RebookedAttendee {
    id?: string;
    delegateId?: string;
    fullName?: string;
    isBookingCanceled?: boolean;
    eventInstanceId?: string;
    eventTypeName?: string;
    eventInstanceDeliveryDateTime?: moment.Moment;
    eventInstanceDeliveryDateTimeEnd?: moment.Moment;
    fromOrderId?: string;
    fromOrderWithBookNowPayLater?: boolean;
    fromBookingReference?: string;
    fromInvoiceReference?: string;
    rebookedToEventInstanceId?: string;
    rebookedToSeatId?: string;
    rebookedEventTypeName?: string;
    rebookedEventInstanceVenueName?: string;
    rebookedEventInstanceDeliveryDateTime?: moment.Moment;
    rebookedEventInstanceDeliveryDateTimeEnd?: moment.Moment;
    oldPricePerSeatInPence?: number;
    newPricePerSeatExcludingVatInPence?: number;
    newPricePerSeatInPence?: number;
    newPricePerSeatInPenceWithoutDiscount?: number;
    feesDue?: boolean;
    feesHalfDue?: boolean;
    feesWaived?: boolean;
    feesZeroed?: boolean;
    noDiscountOnFutureRebooking?: boolean;
    summary: boolean;
}

export interface OrganisationAutocompleteOption {
    name: string;
    address: Address;
}

export interface MarkInvoiceAsPaidModel {
    orderId: string;
    eventInstanceId: string;
    invoiceReference: string;
}

export const FeesStatusEnum = {
    Yes: 0,
    No: 1,
    IncreaseOnly: 2,
};

export const FeesStatus = {
    [FeesStatusEnum.Yes]: "Yes",
    [FeesStatusEnum.No]: "No",
    [FeesStatusEnum.IncreaseOnly]: "Increase Only",
};

export const FeesStatusHalf = {
    [FeesStatusEnum.Yes]: "Half",
    [FeesStatusEnum.No]: "No",
    [FeesStatusEnum.IncreaseOnly]: "Increase Only",
};

export interface EventInstanceEnquiry {
    email: string;
    name: string;
    company: string;
    phone: string;
    additionalComments: string;
    seats: number;
    contextOrganisationId?: string;
    contextCorporateUserId?: string;
}

export const dateRange = (startTimes: moment.Moment[], fullMonthNames?: boolean): string => {
    const month = fullMonthNames === true ? "MMMM" : "MMM";
    const day = fullMonthNames === true ? "D" : "DD";
    if (startTimes.length === 1) {
        return (startTimes[0].format(`${day} ${month}`));
    }

    const firstDate = startTimes[0];
    const lastDate = startTimes[startTimes.length - 1];

    return firstDate.month() === lastDate.month()
        ? `${firstDate.format(day)} - ${lastDate.format(`${day} ${month}`)}`
        : `${firstDate.format(`${day} ${month}`)} - ${lastDate.format(`${day} ${month}`)}`;
};

export const datesConsecutive = (dates: moment.Moment[]) => {
    for (let i = 0; i < dates.length - 1; i++) {
        const d = new Date(dates[i].milliseconds() + (1000 * 60 * 60 * 24));
        const o = new Date(dates[i + 1].milliseconds());
        if (d.getDate() !== o.getDate() || d.getMonth() !== o.getMonth()) {
            return false;
        }
    }
    return true;
};
