/* eslint-disable react/jsx-no-bind */
/* tslint:disable:no-consecutive-blank-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form } from "semantic-ui-react";
import { EditComponent, SaveDispatchProps, EditProps as SharedEditProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";

import { TrainerAttributeDefinitionEditModel, AppState } from "../model";
import { trainerAttributeDefinitionSelector, basePathSelector } from "../selectors";
import { saveTrainerAttributeDefinition } from "../actions";

import { EditModal } from "./EditModal";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { EventType, EventTypeState } from "@common/crud/eventType/model";
import { TrainerAttributeType } from "@common/crud/trainerAttributeDefinition";

export interface EditProps extends SharedEditProps<TrainerAttributeDefinitionEditModel> {
    open: boolean;
    eventTypes: EventType[];
}

export interface DispatchProps extends SaveDispatchProps<TrainerAttributeDefinitionEditModel> {
    close: () => void;
}

export class EditForm extends EditComponent<TrainerAttributeDefinitionEditModel, EditProps> {
    public render() {
        const optionsFromEventTypes = this.props.eventTypes?.map(et => ({ text: et.name, value: et.id }));

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={this.state.values.name}
                    label="Name"
                    required
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateProperty("name", value, valid)}
                    readOnly={this.state.values.attributeType === TrainerAttributeType.Continuum}
                />
                <Input.Text
                    value={TrainerAttributeType[this.state.values.attributeType]}
                    label="Attribute type"
                    readOnly
                />
                <Form.Dropdown
                    selection
                    multiple
                    clearable
                    placeholder="Scheme"
                    value={this.state.values.schemes}
                    options={optionsFromEventTypes}
                    disabled
                />
            </Form>
        );
    }

    public submit = () => this.handleSubmit({ preventDefault: (): void => undefined } as any);
}

function mapStateToProps(state: AppState & EventTypeState) {
    return {
        model: trainerAttributeDefinitionSelector(state) as TrainerAttributeDefinitionEditModel,
        open: state.router.pathname.endsWith("/edit"),
        basePath: basePathSelector(state),
        eventTypes: allEventTypesSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (trainerAttributeDefinition: TrainerAttributeDefinitionEditModel, basePath: string) =>
            dispatch(saveTrainerAttributeDefinition(trainerAttributeDefinition, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): EditProps & DispatchProps {
    return {
        model: propsFromState.model,
        open: propsFromState.open,
        save: (trainerAttributeDefinition: TrainerAttributeDefinitionEditModel) =>
            propsFromDispatch.dispatchSave(trainerAttributeDefinition, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(`${propsFromState.basePath}/${propsFromState.model.id}`),
        eventTypes: propsFromState.eventTypes
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditModal);
