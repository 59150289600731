import { EventInstance } from "@common/crud/eventInstance";

export const durationMap = (sessionCourses: EventInstance[]) => sessionCourses.map((course, index, arr) => {

    if (!course) {
        return 0;
    }

    const duration = arr.filter(x => x && x.id === course.id).length;
    const isFirstSessionOfCourse = arr.findIndex(x => x && x.id === course.id) === index;

    return isFirstSessionOfCourse? duration: 0;
});

export const previousCourseSessionInProgress = (sessionNumber: number, sessionCourses: EventInstance[]) =>
    durationMap(sessionCourses).filter((x, index) => index < sessionNumber - 1).some((y, index) => y > sessionNumber - index);
