import * as React from "react";
import { Grid } from "semantic-ui-react";
import { HistoryRecord } from "../model";

export const HistoryObjectModificationComponent: React.SFC<{ historyRecord: HistoryRecord }> = ({ historyRecord }) => {
    return (
        <>
            {historyRecord.comparisonResult && historyRecord.comparisonResult.map((elem, index) =>
                (<Grid.Row key={index}>
                    <Grid.Column width={16}>
                        {(elem.oldValue && elem.oldValue !== "(null)" && elem.oldValue !== "null") ?
                            <p>
                                <span className="emphasized-property"> {elem.changedPropertyName} </span>
                                has been changed from
                                <span className="changed-property-old"> {elem.oldValue} </span>
                                to
                                <span className="changed-property-new"> {elem.newValue || "no value"} </span>
                            </p>
                            :
                            <p>
                                <span className="emphasized-property"> {elem.changedPropertyName} </span>
                                has been set to
                                <span className="changed-property-new"> {elem.newValue || "no value"} </span>
                            </p>}
                    </Grid.Column>
                </Grid.Row>)
            )}
        </>
    );
};
