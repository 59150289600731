/* eslint-disable max-lines */
import {
    SearchOptions,
    Area,
    EventStatus,
    PageSizes,
    Language,
    LanguageEnum,
    AppState,
    YesNoAnyOptions,
    YesNoAnyEnum
} from "@common/crud/eventInstance/model";
import { Grid, Form, DropdownProps, CheckboxProps, Button, SemanticWIDTHS, Divider, Container } from "semantic-ui-react";
import * as React from "react";
import moment from "moment";
import { getDateInputFormat } from "@common/dateFormating";
import { Apps, DayOfWeek, DayPeriod } from "@common/model";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEventTypes } from "@common/crud/eventType";
import { Organisation, loadOrganisations } from "@common/crud/organisation";
import { corporateOrganisationOptionsSelectorForWorkflow, constructionOrganisationOptionsSelector, organisationSelector,
    policeForceOptionsSelector } from "@common/crud/organisation/selectors";
import { loadVenues } from "@common/crud/venue";
import {
    constructionSortedVenueOptionsWithAnySelector,
    corporateSortedVenueOptionsWithAnySelector, sortedVenueOptionsWithAnySelector, venuesSelector,
} from "@common/crud/venue/selectors";
import { GoToEventInstanceById } from "./GoToEventInstanceById";
import {
    constructionEventTypeOptionsForWorkflowSelector,
    corporateEventTypeOptionsForWorkflowSelector,
    eventTypeOptionsSelectorWithAny,
    nDorsEventTypeOptionsSelectorWithAny
} from "@common/crud/eventType/selectors";
import { optionsFromObject, optionsFromObjectIncludingZero, rotateDropdownRight } from "@common/crud/common";
import { DeliveryType } from "@common/crud/common/DeliveryTypeEnum";
import { AdminEventManagementActions } from "@common/crud/eventInstance/components/AdminEventManagementActions";

import { optionsFromObjectWithString } from "@common/crud/common/optionsMappers";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { BusinessLineType as SearchBusinessLineType } from "@common/crud/organisation/model";

import "./Filters.scss";
import { MuiDateField } from "@common/components/MuiDateField";
import { EventInstanceBookingType } from "@common/crud/eventType/model";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import {
    isBusinessLineTypeCorporateOrConstruction,
    isBusinessLineTypePoliceOrCourt,
    isWorkflowConstruction,
    isWorkflowCorporate,
    isWorkflowPoliceOrCourt
} from "@common/global/CommonHelpers";
import { nameof } from "@common/helpers/nameof";
import { Authorize } from "reauthorize";
import { BusinessDriverEventAdmin, ConstructionHomeRole, TtcCorporateEventAdmin } from "@common/auth/model";
import { isTtcCorporateAdmin } from "@common/crud/common/selectors";
import { CompanyTypeEnum } from "@common/crud/organisation/model";
import { loadEventTypeForRouteWorkflowTypes } from "@common/crud/eventType/actions";
import { CorporateAndConstructionAdminEventManagementActions } from "./CorporateAndConstructionAdminEventManagementActions";

interface FilterProps {
    app: Apps;
    area: Area;
    isOnAvailableCoursesView?: boolean;
    searchOptions: SearchOptions;
    onOptionsChanged: (query: SearchOptions) => void;
    sideMenuStyle?: boolean;
    workflowTypes?: WorkflowTypeEnum[];
    hideBusinessDriver?: boolean;
}

interface Filter {
    showInArea: Area[];
    showInBusinessLine: BusinessLineType[];
    filter: JSX.Element;
}

export const Filters: React.FC<FilterProps> = ({
    app,
    area,
    isOnAvailableCoursesView,
    searchOptions,
    onOptionsChanged,
    sideMenuStyle,
    workflowTypes,
    hideBusinessDriver }) =>
{
    const [stateOptions, setStateOptions] = useState<SearchOptions>(searchOptions);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);
    const allVenues = useSelector(venuesSelector);
    const organisation = useSelector(organisationSelector) as Organisation;

    const dispatch = useDispatch();

    const path = useSelector((state: AppState) => state.router.pathname);
    const routeBusinessLineType = useSelector(businessLineTypeSelector);

    const useCorporateStateWorkflowTypeFilters = routeBusinessLineType === BusinessLineType.Corporate
        && (area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementCorporateOrganisation);

    const useConstructionStateWorkflowTypeFilters = routeBusinessLineType === BusinessLineType.Construction
        && (area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementCorporateOrganisation);

    const businessLineType = React.useMemo(() => {
        const containsPoliceAndCourtWorkflowTypes = workflowTypes && workflowTypes.some(wt => isWorkflowPoliceOrCourt(wt));
        const containsCorporateWorkflowTypes = workflowTypes && workflowTypes.some(wt => isWorkflowCorporate(wt));
        const containsConstructionWorkflowTypes = workflowTypes && workflowTypes.some(wt => isWorkflowConstruction(wt));

        if (containsPoliceAndCourtWorkflowTypes && !containsCorporateWorkflowTypes && !containsConstructionWorkflowTypes) {
            return BusinessLineType.PoliceAndCourt;
        }

        if (containsCorporateWorkflowTypes && !containsPoliceAndCourtWorkflowTypes && !containsConstructionWorkflowTypes) {
            return BusinessLineType.Corporate;
        }

        if (containsConstructionWorkflowTypes && !containsPoliceAndCourtWorkflowTypes && !containsCorporateWorkflowTypes) {
            return BusinessLineType.Construction;
        }

        return routeBusinessLineType;
    }, [routeBusinessLineType, workflowTypes]);

    React.useEffect(() => {
        // Create component handles its own data loading
        if (path.endsWith("/create")) {
            return;
        }

        if (searchOptions.stateWorkflowType) {
            dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: searchOptions.stateWorkflowType }));
            dispatch(loadVenues({ options: { workflows: [searchOptions.stateWorkflowType] } }));
        }
        else if (isBusinessLineTypePoliceOrCourt(businessLineType)) {
            dispatch(loadEventTypeForRouteWorkflowTypes({ workflowType: WorkflowTypeEnum.Dors }));
            dispatch(loadVenues({ options: { workflows: [WorkflowTypeEnum.Dors] } }));
        } else {
            dispatch(loadEventTypes());
            dispatch(loadVenues({}));
        }
    }, [businessLineType, dispatch, searchOptions.stateWorkflowType]);

    React.useEffect(() => {
        if (app === Apps.Admin) {
            switch (businessLineType) {
                case BusinessLineType.PoliceAndCourt:
                case BusinessLineType.PoliceAndCourt2:
                    dispatch(loadOrganisations({ options: { businessLineType: [SearchBusinessLineType.Police, SearchBusinessLineType.Court] } }));
                    break;
                case BusinessLineType.Corporate:
                    dispatch(loadOrganisations({ options: { businessLineType: [SearchBusinessLineType.Corporate] } }));
                    break;
                case BusinessLineType.Construction:
                    dispatch(loadOrganisations({ options: { businessLineType: [SearchBusinessLineType.Construction] } }));
                    break;
                default:
                    dispatch(loadOrganisations());
                    break;
            }
        }
    }, [app, businessLineType, dispatch]);

    const isTtcCorporateAdminRole = useSelector(isTtcCorporateAdmin);
    const nDorsEventTypeOptions = useSelector(nDorsEventTypeOptionsSelectorWithAny);
    const cpcEventTypeOptions = useSelector(corporateEventTypeOptionsForWorkflowSelector(searchOptions.stateWorkflowType));
    const constructionEventTypeOptions = useSelector(constructionEventTypeOptionsForWorkflowSelector(searchOptions.stateWorkflowType));
    const allEventTypeOptions = useSelector(eventTypeOptionsSelectorWithAny);
    const policeForceOptions = useSelector(policeForceOptionsSelector);
    const organisationOptions = useSelector(businessLineType === BusinessLineType.Corporate
        ? corporateOrganisationOptionsSelectorForWorkflow(searchOptions.stateWorkflowType)
        : constructionOrganisationOptionsSelector);
    const venueOptions = useSelector(businessLineType === BusinessLineType.Corporate
        ? corporateSortedVenueOptionsWithAnySelector
        : businessLineType === BusinessLineType.Construction
            ? constructionSortedVenueOptionsWithAnySelector
            : sortedVenueOptionsWithAnySelector);
    const dateInputFormat = getDateInputFormat();

    const availableSeatLabel = React.useMemo(() => searchOptions?.hasAvailableSeats ? "available" : "all", [searchOptions]);
    const oneToOneOnlyLabel = React.useMemo(() => searchOptions?.oneToOneOnly ? "yes" : "no", [searchOptions]);
    const warningsLabel = React.useMemo(() => searchOptions?.hasWarnings ? "errors" : "all", [searchOptions]);

    const onSubmit = React.useCallback(() => {
        const options = {
            ...searchOptions,
            ...stateOptions,
            availableForOtherTrainers: isOnAvailableCoursesView ? YesNoAnyEnum.Yes : YesNoAnyEnum.Any
        };
        setStateOptions(options);
        onOptionsChanged(options);
    }, [searchOptions, stateOptions, isOnAvailableCoursesView, onOptionsChanged]);

    const onInputChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            if (moment.isMoment(value) && !value.isValid) {
                return;
            }

            const prevValue = searchOptions[prop];

            if (prevValue !== value) {
                setStateOptions({ ...stateOptions, [prop]: value, filtersCleared: false });
            }
        };
    }, [searchOptions, stateOptions]);

    const onInputChangedWithOptionsChangedCall = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            if (moment.isMoment(value) && !value.isValid) {
                return;
            }

            const prevValue = searchOptions[prop];

            if (prevValue !== value) {
                const newStateOptions = { ...stateOptions };
                if (prop === nameof<SearchOptions>("stateWorkflowType")) {
                    newStateOptions.eventType = [];
                }

                setStateOptions({ ...newStateOptions, [prop]: value, filtersCleared: false });
                onOptionsChanged({ ...newStateOptions, [prop]: value, filtersCleared: false });
            }
        };
    }, [onOptionsChanged, searchOptions, stateOptions]);

    const onPropertyChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            const prevValue = stateOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
                onOptionsChanged(options);
            }
        };
    }, [onOptionsChanged, stateOptions]);

    const onCheckboxChanged = React.useCallback((prop: keyof (SearchOptions)) =>
        (_: any, { checked }: CheckboxProps) => onPropertyChanged(prop)(checked as any), [onPropertyChanged]);

    const onDropdownPropertyChanged = React.useCallback((prop: keyof (SearchOptions)) =>
        (_: any, { value }: DropdownProps) => onPropertyChanged(prop)(value as any), [onPropertyChanged]);

    const onAvailableCoursesCheckboxChanged = React.useCallback((_: any, { checked }: CheckboxProps) => {
        const options: SearchOptions = {
            ...stateOptions,
            availableForOtherTrainers: checked ? YesNoAnyEnum.Yes : YesNoAnyEnum.No,
            onlyIncludeCoursesMadeAvailableByTrainer: checked
        };
        setStateOptions(options);
        onOptionsChanged(options);
    }, [onOptionsChanged, stateOptions]);

    const onMultiDropdownChange = React.useCallback((prop: keyof SearchOptions) => (_: any, { value }: DropdownProps) => {
        const options = { ...stateOptions, [prop]: value };
        // deselect all eventTypes if "Any Product" is selected
        if (prop === nameof<SearchOptions>("eventType") && Array.isArray(value) && value.includes("")) {
            options.eventType = [];
        }
        if (prop === nameof<SearchOptions>("forceId")) {
            const newForceId = value as number[];
            if (newForceId.length > 0) {
                const validVenuesForCurrentForceSelection = allVenues.filter(v => newForceId.some(f => f === v.forceId));
                const newVenueIds = stateOptions.venueId.filter(v => validVenuesForCurrentForceSelection.some(venue => venue.id === v));
                options.venueId = newVenueIds;
            }
        }
        setStateOptions(options);
        onOptionsChanged(options);
    }, [allVenues, onOptionsChanged, stateOptions]);

    const onClearFilters = React.useCallback(() => {
        const clearedSearchOptions = {
            ...Object.keys(searchOptions)
                .reduce((prevSearchOptions: SearchOptions, key: string) => {

                    const defaultValue: string[] | string | moment.Moment =
                        Array.isArray(searchOptions[key]) ? [] :
                            key.toLowerCase() === "fromdate" ? moment().startOf("day")
                                : undefined;

                    return {
                        ...prevSearchOptions,
                        [key]: defaultValue
                    };
                }, {}), filtersCleared: true
        };

        if (useCorporateStateWorkflowTypeFilters || useConstructionStateWorkflowTypeFilters) {
            clearedSearchOptions.stateWorkflowType = searchOptions.stateWorkflowType;
        }

        setStateOptions(clearedSearchOptions);
        onOptionsChanged(clearedSearchOptions);
    }, [onOptionsChanged, searchOptions, useCorporateStateWorkflowTypeFilters, useConstructionStateWorkflowTypeFilters]);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const isTrainerOrTrainerAdmin = React.useMemo(() =>
        !isOnAvailableCoursesView && (app === Apps.Trainer || area === Area.AdminEventManagementTrainerEventInstance), [app, area, isOnAvailableCoursesView]);

    const courseDetailFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.PoliceEventInstancesList,
            Area.TrainerAppSchedule,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                {app !== Apps.Trainer && <Form.Dropdown
                    floating
                    multiple
                    selection
                    placeholder={area === Area.AdminEventManagementTrainerEventInstance ? "Scheme/Product" :
                        isBusinessLineTypeCorporateOrConstruction(businessLineType) ? "Product" : "Scheme"}
                    value={stateOptions.eventType ?? []}
                    options={businessLineType === BusinessLineType.Corporate
                        ? cpcEventTypeOptions
                        : businessLineType === BusinessLineType.Construction
                            ? constructionEventTypeOptions
                            : nDorsEventTypeOptions}
                    className={`event-type-dropdown ${sideMenuStyle ? "side-menu-style" : ""}`}
                    onChange={onMultiDropdownChange("eventType")}
                    search
                />}
                {app === Apps.Trainer && <Form.Dropdown
                    floating
                    multiple
                    selection
                    placeholder="Scheme/Product"
                    value={stateOptions.eventType ?? []}
                    options={allEventTypeOptions}
                    className="event-type-dropdown"
                    onChange={onMultiDropdownChange("eventType")}
                    search
                />}
            </>
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.PoliceEventInstancesList,
            Area.TrainerAppSchedule,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.PoliceAndCourt,
        ],
        filter: (
            <Form.Dropdown
                selection
                key={searchOptions.language}
                placeholder="Language"
                value={searchOptions.language}
                options={optionsFromObject(Language, "Any", LanguageEnum.Any)}
                onChange={onDropdownPropertyChanged("language")}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                selection
                multiple
                placeholder="Course Status"
                value={stateOptions?.eventStatus ?? []}
                options={optionsFromObjectWithString(EventStatus)}
                onChange={onMultiDropdownChange("eventStatus")}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                selection
                key={searchOptions.deliveryType}
                placeholder="Delivery Type"
                options={optionsFromObjectWithString(DeliveryType, "Any")}
                value={searchOptions.deliveryType}
                onChange={onDropdownPropertyChanged("deliveryType")}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <Form.Dropdown
                    selection
                    key={searchOptions.bookingType}
                    placeholder={"Booking Type"}
                    options={optionsFromObjectWithString(EventInstanceBookingType, "Any")}
                    value={searchOptions.bookingType}
                    onChange={onDropdownPropertyChanged("bookingType")}
                    className={`event-type-dropdown ${sideMenuStyle ? "side-menu-style" : ""}`}
                    search
                />
            </>
        )
    },];

    const dateTimeFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.PoliceEventInstancesList,
            Area.TrainerAppSchedule,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <MuiDateField
                label="Date from"
                placeholder={dateInputFormat}
                value={stateOptions?.fromDate}
                onChange={onInputChanged("fromDate")}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.PoliceEventInstancesList,
            Area.TrainerAppSchedule,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <MuiDateField
                label="Date to"
                placeholder={dateInputFormat}
                value={stateOptions?.toDate}
                onChange={onInputChanged("toDate")}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.PoliceEventInstancesList,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                multiple
                selection
                placeholder="Day Period"
                value={stateOptions?.dayPeriod ?? []}
                options={optionsFromObject(DayPeriod)}
                onChange={onMultiDropdownChange("dayPeriod")}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    },
    {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.TrainerAppAvailableCourses
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                multiple
                selection
                width={4}
                placeholder="Days Of Week"
                value={stateOptions?.daysOfWeek ?? []}
                options={rotateDropdownRight(optionsFromObjectIncludingZero(DayOfWeek))}
                onChange={onMultiDropdownChange("daysOfWeek")}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    }];

    const venueFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.PoliceEventInstancesList
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                floating
                selection
                multiple
                placeholder="Venue"
                value={stateOptions?.venueId ?? []}
                options={venueOptions}
                onChange={onMultiDropdownChange("venueId")}
                search
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    };

    const allocationFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                selection
                label="Requires Trainers"
                key={searchOptions?.requiresTrainers}
                placeholder="Requires Trainers"
                value={searchOptions.requiresTrainers}
                options={optionsFromObject(YesNoAnyOptions, "Any", YesNoAnyEnum.Any)}
                onChange={onDropdownPropertyChanged("requiresTrainers")}
                search
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    }, {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                selection
                label="Available to Trainers"
                key={searchOptions.availableForOtherTrainers}
                placeholder="Available to Trainers"
                value={searchOptions.availableForOtherTrainers}
                options={optionsFromObject(YesNoAnyOptions, "Any", YesNoAnyEnum.Any)}
                onChange={onDropdownPropertyChanged("availableForOtherTrainers")}
                search
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    }];

    let areaFilters: Filter[] = [{
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance
        ],
        showInBusinessLine: [
            BusinessLineType.PoliceAndCourt,
        ],
        filter: (
            <Form.Dropdown
                selection
                multiple
                placeholder="Police Force"
                value={stateOptions.forceId ?? []}
                options={policeForceOptions}
                onChange={onMultiDropdownChange("forceId")}
                search
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
            />
        )
    }, {
        showInArea: [
            Area.AdminEventManagementEventInstance
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                floating
                multiple
                selection
                placeholder="Organisations"
                value={searchOptions.corporateOrganisationIds ?? []}
                options={organisationOptions}
                className={`${sideMenuStyle ? "side-menu-style" : ""}`}
                onChange={onMultiDropdownChange("corporateOrganisationIds")}
                search
            />
        )
    }];

    if (app === Apps.Police) {
        courseDetailFilters.push(venueFilter);
    } else {
        areaFilters.push(venueFilter);
        areaFilters = areaFilters.concat(allocationFilters);
    }

    const numberOfResultsFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
            Area.TrainerAppSchedule
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <Form.Dropdown
                selection
                placeholder="Number of Results"
                value={searchOptions?.maxPageSize}
                options={optionsFromObject(PageSizes)}
                onChange={onDropdownPropertyChanged("maxPageSize")}
            />
        )
    };

    const trainerUnconfirmedFilter: Filter = {
        showInArea: [
            Area.TrainerAppSchedule,
            Area.AdminEventManagementTrainerEventInstance
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <label>Unconfirmed?</label>
                <Form.Checkbox
                    toggle
                    checked={searchOptions?.getOnlyUnconfirmedByTrainer}
                    onChange={onCheckboxChanged("getOnlyUnconfirmedByTrainer")}
                />
            </>
        )
    };

    const trainerAvailableCoursesFilter: Filter = {
        showInArea: [
            Area.TrainerAppSchedule
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <label>My Available Courses</label>
                <Form.Checkbox
                    toggle
                    checked={searchOptions?.availableForOtherTrainers === YesNoAnyEnum.Yes}
                    onChange={onAvailableCoursesCheckboxChanged}
                />
            </>
        )
    };

    const seatAvailabilityFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementTrainerEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <label>Seat Availability</label>
                <Form.Checkbox
                    toggle
                    label={availableSeatLabel}
                    checked={searchOptions.hasAvailableSeats}
                    onChange={onCheckboxChanged("hasAvailableSeats")}
                />
            </>
        )
    };

    const warningsFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <label>Show errors</label>
                <Form.Checkbox
                    toggle
                    label={warningsLabel}
                    checked={searchOptions.hasWarnings}
                    onChange={onCheckboxChanged("hasWarnings")}
                />
            </>
        )
    };

    const oneToOneOnlyFilter: Filter = {
        showInArea: [
            Area.AdminEventManagementEventInstance,
            Area.AdminEventManagementCorporateOrganisation,
        ],
        showInBusinessLine: [
            BusinessLineType.Corporate,
            BusinessLineType.PoliceAndCourt,
            BusinessLineType.Construction
        ],
        filter: (
            <>
                <label>1:1 only</label>
                <Form.Checkbox
                    toggle
                    label={oneToOneOnlyLabel}
                    checked={searchOptions.oneToOneOnly}
                    onChange={onCheckboxChanged("oneToOneOnly")}
                />
            </>
        )
    };

    function renderFilterRow(filters: Filter[], withDivider?: boolean) {
        const visibleFilters = filters.filter(f => f.showInArea.includes(area) && (app !== Apps.Admin || f.showInBusinessLine.includes(businessLineType)));

        if (visibleFilters.length === 0) {
            return null;
        }

        const width = Math.floor(16 / visibleFilters.length);
        const totalWidth = width * visibleFilters.length;
        const lastColumnWidth = totalWidth === 16 ? width : (16 - width * visibleFilters.length) + width;
        return (
            <>
                <Grid.Row>
                    {visibleFilters.map((x, i) => (
                        <Grid.Column
                            key={i}
                            width={(i === visibleFilters.length - 1 ? lastColumnWidth : width) as SemanticWIDTHS}
                            verticalAlign="bottom"
                            className={(withDivider && i === visibleFilters.length / 2 ? "border-left" : "")}>
                            {x.filter}
                        </Grid.Column>
                    ))}
                </Grid.Row>

                <Divider />
            </>
        );
    }

    function renderCorporateWorkflowTypeFilters() {
        // If we have an org and it's a Business Driver company, or if the current user doesn't have the TtcCorporateEventAdmin role,
        // don't render the filters as the user can only at most have access to the Business Driver filter
        if ((organisation?.id && (organisation.companyType?.includes(CompanyTypeEnum.BusinessDriver) || false)) || !isTtcCorporateAdminRole) {
            return null;
        }

        return (<Grid container stackable>
            <Grid.Row>
                <Grid.Column>
                    <Container>
                        <div className="button-search-menu">
                            <Authorize authorize={TtcCorporateEventAdmin}>
                                <a
                                    onClick={onCpcSelected}
                                    className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.CPC
                                        ? "button-search-menu-item current"
                                        : ""}`}
                                >
                                    CPC
                                </a>
                                <a
                                    onClick={onWorkshopSelected}
                                    className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.Workshop
                                        ? "button-search-menu-item current"
                                        : ""}`}
                                >
                                    Workshop
                                </a>
                                <a
                                    onClick={onOnRoadSelected}
                                    className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.OnRoad
                                        ? "button-search-menu-item current"
                                        : ""}`}
                                >
                                    On Road
                                </a>
                            </Authorize>
                            {!hideBusinessDriver && (
                                <Authorize authorize={BusinessDriverEventAdmin}>
                                    <a
                                        onClick={onBusinessDriverSelected}
                                        className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.BusinessDriver
                                            ? "button-search-menu-item current"
                                            : ""}`}
                                    >
                                        Business Driver
                                    </a>
                                </Authorize>
                            )}
                        </div>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    const onConstructionStandardSelected = React.useCallback(() =>
        onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.ConstructionStandard),
    [onInputChangedWithOptionsChangedCall]);
    const onCitbSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.CITB),
        [onInputChangedWithOptionsChangedCall]);
    const onApsSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.APS),
        [onInputChangedWithOptionsChangedCall]);

    const onCpcSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.CPC),
        [onInputChangedWithOptionsChangedCall]);
    const onWorkshopSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.Workshop),
        [onInputChangedWithOptionsChangedCall]);
    const onOnRoadSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.OnRoad),
        [onInputChangedWithOptionsChangedCall]);
    const onBusinessDriverSelected = React.useCallback(() => onInputChangedWithOptionsChangedCall("stateWorkflowType")(WorkflowTypeEnum.BusinessDriver),
        [onInputChangedWithOptionsChangedCall]);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";
    return (
        <>
            {useConstructionStateWorkflowTypeFilters && (
                <Grid container stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Container>
                                <div className="button-search-menu">
                                    <Authorize authorize={ConstructionHomeRole}>
                                        <a
                                            onClick={onConstructionStandardSelected}
                                            className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.ConstructionStandard
                                                ? "button-search-menu-item current"
                                                : ""}`}
                                        >
                                            Standard Construction
                                        </a>
                                        <a
                                            onClick={onCitbSelected}
                                            className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.CITB
                                                ? "button-search-menu-item current"
                                                : ""}`}>
                                            CITB
                                        </a>
                                        <a
                                            onClick={onApsSelected}
                                            className={`button-search-menu-item ${searchOptions.stateWorkflowType === WorkflowTypeEnum.APS
                                                ? "button-search-menu-item current"
                                                : ""}`}>
                                            APS
                                        </a>
                                    </Authorize>
                                </div>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
            {useCorporateStateWorkflowTypeFilters && renderCorporateWorkflowTypeFilters()}
            <Grid stackable container>
                <Grid.Row>
                    <Grid.Column width={8} verticalAlign="bottom">
                        {!isOnAvailableCoursesView && (isTrainerOrTrainerAdmin || app === Apps.Admin) &&
                            <GoToEventInstanceById />
                        }
                        <a className={"issue-button float-left"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="right" verticalAlign="top">
                        {app === Apps.Admin && (area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementCorporateOrganisation) &&
                            (isBusinessLineTypeCorporateOrConstruction(businessLineType)
                                ? <CorporateAndConstructionAdminEventManagementActions
                                    eventManagementPath={businessLineType === BusinessLineType.Corporate
                                        ? "corporate-event-management"
                                        : "construction-event-management"}
                                    path={path}
                                />
                                : <AdminEventManagementActions ddrs={false} path={path} />
                            )
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {filtersVisible &&
                <Grid className="filter-grid" container stackable>
                    {renderFilterRow(courseDetailFilters)}
                    {renderFilterRow(dateTimeFilters)}
                    {renderFilterRow(areaFilters, true)}
                    <Grid.Row>
                        {(seatAvailabilityFilter.showInArea.includes(area) &&
                            (app !== Apps.Admin || seatAvailabilityFilter.showInBusinessLine.includes(businessLineType))) && (
                            <Grid.Column width={3}>
                                {seatAvailabilityFilter.filter}
                            </Grid.Column>
                        )}
                        {(trainerUnconfirmedFilter.showInArea.includes(area) &&
                            (app !== Apps.Admin || trainerUnconfirmedFilter.showInBusinessLine.includes(businessLineType))) && (
                            <Grid.Column width={(area === Area.AdminEventManagementTrainerEventInstance ? 3 : 12)}>
                                {trainerUnconfirmedFilter.filter}
                            </Grid.Column>
                        )}
                        {(trainerAvailableCoursesFilter.showInArea.includes(area) &&
                            (app !== Apps.Admin || trainerAvailableCoursesFilter.showInBusinessLine.includes(businessLineType))) && (
                            <Grid.Column width={12}>
                                {trainerAvailableCoursesFilter.filter}
                            </Grid.Column>
                        )}
                        {(oneToOneOnlyFilter.showInArea.includes(area) &&
                            (app !== Apps.Admin || oneToOneOnlyFilter.showInBusinessLine.includes(businessLineType))) && (
                            <Grid.Column width={3}>
                                {oneToOneOnlyFilter.filter}
                            </Grid.Column>
                        )}
                        {(warningsFilter.showInArea.includes(area) &&
                            (app !== Apps.Admin || warningsFilter.showInBusinessLine.includes(businessLineType))) && (
                            <Grid.Column width={3}>
                                {warningsFilter.filter}
                            </Grid.Column>
                        )}
                        <div className="display-right">
                            <Grid.Column width={2} >
                                <a className={"issue-button filters"} onClick={onClearFilters}>Clear filters</a>
                            </Grid.Column>
                            <Grid.Column width={2} textAlign="right">
                                <Button content="APPLY" onClick={onSubmit} />
                            </Grid.Column>
                        </div>
                    </Grid.Row>
                </Grid>
            }

            {(numberOfResultsFilter.showInArea.includes(area) &&
                (app !== Apps.Admin || numberOfResultsFilter.showInBusinessLine.includes(businessLineType))) && (
                <Grid className={(businessLineType !== BusinessLineType.PoliceAndCourt
                    && area === Area.AdminEventManagementEventInstance) ? "slightly-wider" : ""}>
                    <Grid.Row textAlign="right">
                        <Grid.Column width={16}>
                            {numberOfResultsFilter.filter}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    );
};
