import * as React from "react";

import { AzureSearchSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const AzureSearch: ConfigurationAreaComponent<AzureSearchSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="AzureSearch"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "ServiceName", label: "Service Name" },
                { name: "Version", label: "Version" },
                { name: "ApiKey", label: "Api Key" }
            ]}
        />
    );
};
