/* eslint-disable @typescript-eslint/no-redeclare */
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export type LOAD_ORDERS_SUCCESS = typeof LOAD_ORDERS_SUCCESS;

export const LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS = "LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS";
export type LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS = typeof LOAD_EVENT_INSTANCES_WITH_ORDERS_SUCCESS;

export const LOAD_ORDER_DETAIL_SUCCESS = "LOAD_ORDER_DETAIL_SUCCESS";
export type LOAD_ORDER_DETAIL_SUCCESS = typeof LOAD_ORDER_DETAIL_SUCCESS;

export const LOAD_ORDER_PAYMENT_SUCCESS = "LOAD_ORDER_PAYMENT_SUCCESS";
export type LOAD_ORDER_PAYMENT_SUCCESS = typeof LOAD_ORDER_PAYMENT_SUCCESS;

export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export type MAKE_PAYMENT_SUCCESS = typeof MAKE_PAYMENT_SUCCESS;

export const MAKE_PAYMENT_FAILURE = "MAKE_PAYMENT_FAILURE";
export type MAKE_PAYMENT_FAILURE = typeof MAKE_PAYMENT_FAILURE;

export const ORDER_REFUND_SUCCESS = "ORDER_REFUND_SUCCESS";
export type ORDER_REFUND_SUCCESS = typeof ORDER_REFUND_SUCCESS;

export const ORDER_REFUND_FAILURE = "ORDER_REFUND_FAILURE";
export type ORDER_REFUND_FAILURE = typeof ORDER_REFUND_FAILURE;

export const LOAD_HISTORY_SUCCESS = "LOAD_HISTORY_SUCCESS";
export type LOAD_HISTORY_SUCCESS = typeof LOAD_HISTORY_SUCCESS;

export const FIX_EVENT_INSTANCES_WITH_ORDERS = "FIX_EVENT_INSTANCES_WITH_ORDERS";
export type FIX_EVENT_INSTANCES_WITH_ORDERS = typeof FIX_EVENT_INSTANCES_WITH_ORDERS;
