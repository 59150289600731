import { createValidator } from "not-valid";

const min = (limit: number) => {
    // eslint-disable-next-line eqeqeq
    return createValidator<number>(v => v != null && v >= limit, `Value must be at least ${limit}`);
};

export const minValidator = (limit: number) => {
    return [ min(limit) ];
};
