import * as React from "react";
import { EventInstanceCreateModel, LanguageEnum } from "../../model";
import { TypedTable } from "@common/crud/common/TypedTable";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { DeleteButton } from "./DeleteButton";
import { orderBy } from "lodash";
import { LanguageDropdown } from "./LanguageDropdown";
import { StartTimeInput } from "./StartTimeInput";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { StartDateInput } from "./StartDateInput";

export interface EventTableProps {
    deleteRow: (id: string) => void;
    onStartTimeChange: (id: string, time: moment.Duration) => void;
    onStartDateChange: (id: string, time: moment.Moment) => void;
    onLanguageChange: (id: string, language: LanguageEnum) => void;
    eventInstances: EventInstanceCreateModel[];
    eventTypeCategory: EventTypeCategory;
}

export const EventTable: React.FC<EventTableProps> =
({ eventInstances, deleteRow, onLanguageChange, onStartTimeChange, eventTypeCategory, onStartDateChange }) => {
    let ddrsEventsGrouped: EventInstanceCreateModel[] = [];
    const isDdrs = eventTypeCategory === EventTypeCategory.Ddrs;

    if (isDdrs) {
        eventInstances.forEach(eventInstance => {
            if (ddrsEventsGrouped.findIndex(e => e.id === eventInstance.id) !== -1) {
                return;
            }
            const eventsInSameGroup = orderBy(eventInstances.filter(e => eventInstance.dayParts?.includes(e.id)), ["startDate"], "asc");
            ddrsEventsGrouped = [...ddrsEventsGrouped, ...eventsInSameGroup];
        });
    }

    return (
        <TypedTable
            values={isDdrs ? ddrsEventsGrouped : orderBy(eventInstances, ["startDate"], "asc")}
            emptyValuesArrayMessage="No courses on this date or range of dates"
        >
            {
                [
                    {
                        header: "Date",
                        value: e => isDdrs ? <StartDateInput startDate={e.startDate} id={e.id} onStartDateChange={onStartDateChange} />
                            : e.startDate.format(DateFormat.Short)
                    },
                    {
                        header: "Start Time",
                        value: (e) => <StartTimeInput id={e.id} startTime={e.startTime} onStartTimeChange={onStartTimeChange} />
                    },
                    {
                        header: "Language",
                        value: (e) => <LanguageDropdown id={e.id} language={e.language} onLanguageChange={onLanguageChange} />
                    },
                    {
                        header: "",
                        value: (e) => <DeleteButton deleteCourse={deleteRow} id={e.id} />
                    }
                ]
            }
        </TypedTable>
    );
};
