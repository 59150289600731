import * as React from "react";
import { Link } from "redux-little-router";

interface HeaderLogoProps {
    logo?: string;
    redirectOnClick?: boolean;
}

const HeaderLogo: React.SFC<HeaderLogoProps> = ({ logo, redirectOnClick }) => {
    const logoSource = logo ? `/assets/${logo}` : "/assets/logo.png";
    const redirect = redirectOnClick ?? false;

    return (
        <div className="brand-img">
            {
                redirect ?
                    <Link href="/">
                        <img className="logo" src={logoSource} alt="TTC" />
                    </Link> :
                    <img className="logo" src={logoSource} alt="TTC" />
            }
        </div>
    );
};

export { HeaderLogo };
