import * as React from "react";
import { connect } from "react-redux";
import { push, ObjectLiteral } from "redux-little-router";
import { isEqual } from "lodash";
import { Grid, PaginationProps } from "semantic-ui-react";
import { AsyncDispatch } from "@common/redux-helpers";
import { TTCPagination } from "@common/components/TTCPageination";
import { usersSelector, SelectorState } from "../selectors";
import { OrganisationState, selectors as organisationSelectors } from "../../organisation";
import { SearchOptions, PoliceUserListModel } from "../model";
import { AllItems } from "./AllItems";
import { Filters } from "./Filters";

const PAGE_SIZE = 50;

interface OwnProps {
    loadUsers: (options: SearchOptions) => void;
}

interface StateProps {
    users: PoliceUserListModel[];
    searchOptions: SearchOptions;
}

interface DispatchProps {
    setQuery: (query: ObjectLiteral<string>) => void;
    searchOptions: SearchOptions;
}

type UserListWithFiltersProps = OwnProps & StateProps & DispatchProps;

class UserListWithFiltersInternal extends React.Component<UserListWithFiltersProps> {
    public state = {
        page: 1
    };

    public render() {
        const start = (this.state.page - 1) * PAGE_SIZE;
        const numberPages = Math.ceil(this.props.users.length / PAGE_SIZE);
        const searchOptions = {
            ...this.props.searchOptions
        };
        return (
            <>
                <Filters
                    searchOptions={searchOptions}
                    onOptionsChanged={this.onOptionsChanged}
                />
                {numberPages > 1 &&
                    <TTCPagination activePage={this.state.page} totalPages={numberPages} onPageChange={this.onPageChange} />
                }
                <Grid.Row>
                    <Grid.Column width={16}>
                        <AllItems
                            users={this.props.users.slice(start, start + PAGE_SIZE)}
                        />
                    </Grid.Column>
                </Grid.Row>
                {numberPages > 1 &&
                    <TTCPagination activePage={this.state.page} totalPages={numberPages} onPageChange={this.onPageChange} />
                }
            </>
        );
    }

    public componentDidMount() {
        this.props.loadUsers(this.props.searchOptions);
    }

    public componentDidUpdate(prevProps: UserListWithFiltersProps) {
        if (!isEqual(this.props.searchOptions, prevProps.searchOptions)) {
            this.props.loadUsers(this.props.searchOptions);
        }
    }

    public onPageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        this.setState({ page: data.activePage });
    };

    private onOptionsChanged = (options: SearchOptions) => {
        const query = {
            name: options.name || undefined
        };
        this.props.setQuery(query);
        this.setState({ page: 1 });
    };
}

function mapStateToProps(state: SelectorState & OrganisationState) {
    const searchOptions = {
        ...state.router.query
    };
    return {
        users: usersSelector(state),
        searchOptions,
        organisationId: organisationSelectors.routeIdSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        setQuery: (query: ObjectLiteral<string>) => dispatch(push({ pathname: undefined, query }))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch, ownProps: OwnProps): UserListWithFiltersProps {
    const { users, searchOptions } = propsFromState;
    const { setQuery, } = propsFromDispatch;
    const { loadUsers } = ownProps;
    return {
        users,
        searchOptions,
        setQuery,
        loadUsers
    };
}

export const UserListWithFilters = connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserListWithFiltersInternal);
