import { createValidator } from "not-valid";

const integerValidator = () => {
    // eslint-disable-next-line eqeqeq
    return createValidator<number>(v => v != null && Number.isInteger(v), "Value must be an integer");
};

export const isInteger = () => {
    return [ integerValidator() ];
};
