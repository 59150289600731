import * as React from "react";
import { Fragment, Location } from "redux-little-router";
import { Authorize } from "reauthorize";
import { TtcClientAdvisorRole, AttendeeRole } from "@common/auth/model";
import { BookingDetails } from "../../bookings/components/BookingDetails";
import { StripeWrapper } from "../../payments/components/StripeWrapper";
import { RebookStartPage } from "../../bookings/components/RebookStartPage";
import { OutstandingBalancePaymentConfirmation } from "../../payments/components/OutstandingBalancePaymentConfirmation";
import { Dashboard } from "../../bookings/components/Dashboard";
import { Landing } from "./Landing";
import { ClientAdvisorRedirectComponent } from "@booking/payments/components/ClientAdvisorRedirect";
import { ReservationError } from "./ReservationError";
import { DorsUpdateError } from "@booking/landing/components/DorsUpdateError";
import { PaymentPageNames, PaymentPageNamesEnum } from "@common/payments/model";
import { GenesysAuthorisation } from "@common/genesys/components/GenesysAuthorisation";
import { BookingConfirmation } from "@booking/bookings/components/BookingConfirmation";

const noEventInstanceId = (location: Location) => location.params.eventInstanceId === undefined;

export const Base = () => (
    // I did this in order to prevent redirect on refresh the site
    <Authorize authorize={[TtcClientAdvisorRole, AttendeeRole]}>
        <>
            <Fragment forRoute="/rebook">
                <RebookStartPage />
            </Fragment>
            <Fragment forRoute="/booking-confirmation">
                <BookingConfirmation />
            </Fragment>
            <Fragment forRoute="/dashboard">
                <Dashboard />
            </Fragment>
            <Fragment forRoute="/payment-confirmation">
                <OutstandingBalancePaymentConfirmation />
            </Fragment>
            <Fragment forRoute="/ca-redirect">
                <ClientAdvisorRedirectComponent />
            </Fragment>
            <Fragment forRoute="/reservation-error">
                <ReservationError />
            </Fragment>
            <Fragment forRoute="/dors-update-error">
                <DorsUpdateError />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/dors-update-error">
                <DorsUpdateError />
            </Fragment>
            <Fragment forRoute="/" withConditions={noEventInstanceId}>
                <Landing />
            </Fragment>
            <Fragment forRoute={`/:eventInstanceId/${PaymentPageNames[PaymentPageNamesEnum.ReviewPayment]}`}>
                <StripeWrapper />
            </Fragment>
            <Fragment forRoute={`/:eventInstanceId/${PaymentPageNames[PaymentPageNamesEnum.GenesysAuthorisation]}`}>
                <GenesysAuthorisation />
            </Fragment>
            <Fragment forRoute="/:eventInstanceId/booking-details">
                <BookingDetails />
            </Fragment>
        </>
    </Authorize>
);
