import * as React from "react";
import {  Grid } from "semantic-ui-react";
import { TrainerDetailModel } from "@common/crud/trainer/model";
import { ComplianceAttributesTable } from "../ComplianceAttributesTable";

export const ComplianceAttributes = ({ trainer }: {trainer: TrainerDetailModel}) => {

    const complianceAttributes = trainer?.trainerAttributes
        ?.filter((a) => a.isCompliance)
        .sort((a1, a2) => (a1.name > a2.name ? 1 : a2.name > a1.name ? -1 : 0));

    return (
        <Grid.Row>
            <h3>Compliance Attributes</h3>
            <ComplianceAttributesTable
                attributes={complianceAttributes}
                attributeCategory="Compliance"
            />
        </Grid.Row>
    );};
