import * as React from "react";
import { useDispatch } from "react-redux";
import { ConfirmButton } from "@common/components";
import { Trainer } from "../../model";
import { sendSignupInvitation } from "../../actions";

interface OwnProps {
    trainer: Trainer;
}

export const SignupInvitationButton: React.SFC<OwnProps> = ({ trainer }) => {
    const dispatch = useDispatch();

    const sendInvitation = React.useCallback(() => {
        dispatch(sendSignupInvitation(trainer.id));
    }, [dispatch, trainer.id]);

    const notSignedUp = trainer.adB2CId === null; // As opposed to undefined, which just means the user hasn't been loaded yet.
    if (!notSignedUp) {
        return null;
    }

    return (
        <ConfirmButton icon="mail" onConfirm={sendInvitation} header="Resend Signup Email">
            Resend Signup Email
        </ConfirmButton>
    );
};
