import * as React from "react";
import moment from "moment";
import { BookingOrganisationApi } from "@booking/organisation/bookingOrganisationApi";
import { getFlexiPaySessionStorageKey } from "@common/crud/dorsBooking/model";
import { EventTypeApi } from "@common/crud/eventType";
import { PaymentApi } from "@common/payments/paymentApi";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

export function useFlexiPay(
    bookingId: string,
    eventTypeId: string,
    organisation: OrganisationDetailModel,
    forceId: number,
    isRebooking: boolean,
    isCancelled: boolean,
    isCa: boolean) {
    const [eligibleForFlexiPay, setEligibleForFlexiPay] = React.useState<boolean | null>(null);
    const flexiPaySessionStorageKey = getFlexiPaySessionStorageKey(bookingId);
    const [flexiPayChecked, setFlexiPayChecked] = React.useState<boolean | null>(null);
    const [flexiPayFee, setFlexiPayFee] = React.useState<number | null>(null);

    const organisationRef = React.useRef(organisation);

    React.useEffect(() => {
        organisationRef.current = organisation;
    }, [organisation]);

    React.useEffect(() => {
        const initialiseFlexiPayChecked = async () => {

            if (isRebooking || isCancelled) {
                setFlexiPayChecked(false);
                return;
            }

            const sessionStorageItem = sessionStorage.getItem(flexiPaySessionStorageKey);
            if (sessionStorageItem !== null) {
                setFlexiPayChecked(sessionStorageItem === "true");
                return;
            }

            if (!isCa) {
                setFlexiPayChecked(false);
                return;
            }

            const response = await new PaymentApi(bookingId).getIvrPaymentDetails();
            const { flexiPayPurchased, paymentComplete } = response.paymentStatusModel;
            if (!paymentComplete && flexiPayPurchased) {
                setFlexiPayChecked(true);
                return;
            }

            setFlexiPayChecked(false);
        };

        if (flexiPayChecked === null) {
            initialiseFlexiPayChecked();
        }
    }, [isRebooking, isCancelled, flexiPaySessionStorageKey, flexiPayChecked, isCa, bookingId]);

    React.useEffect(() => {
        const initialiseFlexiPayEligibility = async () => {
            if (isRebooking || isCancelled) {
                setEligibleForFlexiPay(false);
                return;
            }

            const [org, scheme] = await Promise.all([
                new BookingOrganisationApi().getOrganisationByForceId(forceId),
                new EventTypeApi().detail(eventTypeId)
            ]);

            const now = moment();
            const orgExistsInAlaska = org.id !== undefined;
            const activeFeeExists = scheme.flexiPayFees.some(f => f.effectiveDate.isSameOrBefore(now));
            const eligible = (org.flexiPayEnabled || !orgExistsInAlaska) && scheme.flexiPayEnabled && activeFeeExists;

            setEligibleForFlexiPay(eligible);
        };

        if (eventTypeId) {
            initialiseFlexiPayEligibility();
        }

    }, [isRebooking, eventTypeId, forceId, isCancelled]);

    React.useEffect(() => sessionStorage.setItem(flexiPaySessionStorageKey, flexiPayChecked?.toString()), [flexiPayChecked, flexiPaySessionStorageKey]);

    React.useEffect(() => {
        const calculateFlexiPayFee = async () => {
            const now = moment();
            let currentFee;
            if (organisation?.chargesOwnFlexiPayFees) {
                currentFee = organisation.flexiPayFees.map(f => [f.value, f.effectiveDate.diff(now)]).reverse().find(f => f[1] <= 0)[0];
                setFlexiPayFee(currentFee);
            } else {
                currentFee = (await new EventTypeApi().detail(eventTypeId))
                    .flexiPayFees.map(f => [f.fee, f.effectiveDate.diff(now)]).reverse().find(f => f[1] <= 0)[0];
                if (organisationRef.current !== organisation) {
                    return;
                }
            }
            setFlexiPayFee(currentFee);
        };

        if (eligibleForFlexiPay) {
            calculateFlexiPayFee();
        }
    }, [eligibleForFlexiPay, eventTypeId, organisation]);

    return { eligibleForFlexiPay, flexiPayFee, flexiPayChecked, setFlexiPayChecked };
}
