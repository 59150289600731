import { DetailGroup } from "@neworbit/simpleui-detail/dist/DetailGroup";
import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

export interface DetailProps {
    label: string | JSX.Element;
    value: string | JSX.Element;
    labelWidth?: SemanticWIDTHS;
    valueWidth?: SemanticWIDTHS;
    labelClassName?: string;
    valueClassName?: string;
}

export interface CustomDetailComponent extends React.FC<DetailProps> {
    Group: typeof DetailGroup;
}

const customDetail: React.FC<DetailProps> = ({ label, value, labelWidth, valueWidth, labelClassName, valueClassName }) => {
    return (
        <Grid.Row>
            <Grid.Column width={labelWidth || 4} as="h4" className={labelClassName}>
                {label}
            </Grid.Column>
            <Grid.Column width={valueWidth || 12} className={valueClassName}>
                {value}
            </Grid.Column>
        </Grid.Row>
    );
};

export const CustomDetail: CustomDetailComponent = Object.assign(
    customDetail,
    { Group: DetailGroup }
);
