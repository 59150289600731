import * as React from "react";
import moment from "moment";
import { Button, Container, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { EventInstance } from "@common/crud/eventInstance";
import { BookingSummaryGridRows } from "../bookings/components/BookingSummaryGridRows";
import { Booking, BookingDetailFormikModel } from "../bookings/models";
import { BookingSpecialRequirementsForm } from "../landing/components/BookingSpecialRequirementsForm";
import { TAndCsConfirmationComponent } from "../bookings/components/TAndCsConfirmationComponent";
import { BookingDetailsTelephoneField } from "../bookings/components/BookingDetailsTelephoneField";
import { BookingDetailsEmailField } from "../bookings/components/BookingDetailsEmailField";
import { BookingDetailsCarTypeFields } from "../bookings/components/BookingDetailsCarTypeFields";
import { ChosenCourse } from "./ChosenCourse";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { View } from "./ViewEnum";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { useFlexiPay } from "./hooks/useFlexiPay";
import { useFormikContext } from "formik";
import { FlexiPayModal } from "./FlexiPayModal";
import { OrganisationDetailModel } from "@common/crud/organisation/model";

interface BookingDetailsMobileProps {
    booking: Booking;
    hasSpecialRequirements: boolean;
    eventInstance: EventInstance;
    isClientAdvisor: boolean;
    flexiPayModalOpen: boolean;
    handleContinueOnClick: () => void;
    setFlexiPayModalOpen: (open: boolean) => void;
    seatDiscount: number;
    specialIsOnlyOption: boolean;
    carFlag: boolean;
    organisation?: OrganisationDetailModel;
    allFieldsValid: boolean;
}

export const BookingDetailsMobile: React.FC<BookingDetailsMobileProps> = (props: BookingDetailsMobileProps) => {
    const [t] = useTranslation("BookingDetails");

    const {
        booking,
        hasSpecialRequirements,
        eventInstance,
        handleContinueOnClick,
        isClientAdvisor,
        flexiPayModalOpen,
        setFlexiPayModalOpen,
        seatDiscount,
        specialIsOnlyOption,
        carFlag,
        organisation,
        allFieldsValid,
    } = props;

    const continueButtonMessage = eventInstance.priceInPence === 0 ? t("COMPLETE_BOOKING") : t("GO_TO_PAYMENT");

    const {
        eligibleForFlexiPay,
        flexiPayFee,
        flexiPayChecked,
        setFlexiPayChecked
    } = useFlexiPay(booking.id, eventInstance.eventTypeId, organisation, booking.forceId, booking.isRebooking, booking.isCancelled, isClientAdvisor);

    const formikProps = useFormikContext<BookingDetailFormikModel>();

    const eiStartsWithin14Days = moment(eventInstance?.deliveryDateTime).isSameOrBefore(moment().add(14, "days"));

    async function handleContinueButtonClick() {
        if (!eiStartsWithin14Days && eligibleForFlexiPay && !flexiPayModalOpen && !isClientAdvisor) {
            setFlexiPayModalOpen(true);
            formikProps.validateForm();
            formikProps.setFieldTouched("specialRequirements", true);
            formikProps.setFieldTouched("telephone", true);
            formikProps.setFieldTouched("email", true);
            formikProps.setFieldTouched("tAndCsConfirmed", true);
            if (carFlag) {
                formikProps.setFieldTouched("carType", true);
            }

            return;
        }

        continueToPayment(flexiPayChecked);
    }

    function continueToPayment(flexiPayPurchased: boolean) {
        setFlexiPayModalOpen(false);
        formikProps.setFieldValue("flexiPayPurchased", flexiPayPurchased);
        formikProps.setFieldValue("flexiPayFee", flexiPayFee);
        handleContinueOnClick();
    }

    function closeFlexiPayModal() {
        setFlexiPayModalOpen(false);
    }

    React.useEffect(() => {
        if (flexiPayModalOpen && !allFieldsValid) {
            setFlexiPayModalOpen(false);
        }

    }, [allFieldsValid, flexiPayModalOpen, setFlexiPayModalOpen]);

    return (
        <Container className="mobile-container">
            <FlexiPayModal
                open={flexiPayModalOpen && allFieldsValid}
                continueToPayment={continueToPayment}
                eventInstance={eventInstance}
                flexiPayFee={flexiPayFee}
                onClose={closeFlexiPayModal}
            />
            <BookingSummaryGridRows booking={booking} showPersonalDetails={false} />
            <ChosenCourse
                eventInstance={eventInstance}
                fullDetails={false}
                showLinks={true}
                currentView={View.BookingDetails}
                eligibleForFlexiPay={eligibleForFlexiPay}
                flexiPayChecked={flexiPayChecked}
                flexiPayFee={flexiPayFee}
                setFlexiPayChecked={setFlexiPayChecked}
                seatDiscount={seatDiscount}
                organisation={organisation}
            />
            <h3 className="sub-heading">{t("CORRECT_CONTACT_DETAILS")}</h3>
            <BookingDetailsTelephoneField bookingId={booking.id} />
            <BookingDetailsEmailField bookingId={booking.id} />
            <BookingDetailsCarTypeFields eventInstance={eventInstance} specialIsOnlyOption={specialIsOnlyOption} carFlag={carFlag} />
            <BookingSpecialRequirementsForm
                hasUserFilterBySpecialRequirements={hasSpecialRequirements}
                isDigitalCourse={eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Digital}
                eventInstance={eventInstance}
            />
            {!isClientAdvisor &&
                <TAndCsConfirmationComponent
                    eventInstance={eventInstance}
                    organisation={organisation}
                />
            }
            {allFieldsValid === false && <Message warning visible content={t("PLEASE_COMPLETE_ALL_SECTIONS")} /> }
            <Button
                className={"continue-button"}
                content={continueButtonMessage}
                onClick={handleContinueButtonClick}
                disabled={allFieldsValid === false || carFlag === null || formikProps.isSubmitting}
            />
            <PrivacyPolicy />
        </Container>
    );
};
