import * as React from "react";

const preventScroll = () => {
    if ((document.activeElement as any).type === "number")
    {
        (document.activeElement as any).blur();
    }
};

const preventScrollingOnNumberInputs = () => {
    document.addEventListener("wheel", preventScroll);
    return () => document.removeEventListener("wheel", preventScroll);
};

export const PreventScrollingOnNumberInputsHook = () => React.useEffect(() => preventScrollingOnNumberInputs(), []);
