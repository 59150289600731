import * as React from "react";
import { Link } from "redux-little-router";
import { Button, Table } from "semantic-ui-react";
import { EventInstanceDetails, Order } from "../model";
import { getBookingItemState } from "../../eventInstance/helpers";
import { formatPrice } from "../helpers";
import { useSelector } from "react-redux";
import { universalEventManagementPathSelector } from "@common/redux-helpers";

interface OrderEventInstanceRowProps {
    order: Order;
    eventInstance: EventInstanceDetails;
    orderLink: string;
    index: number;
}

export const OrderEventInstanceRow: React.FC<OrderEventInstanceRowProps> = ({
    order,
    eventInstance,
    orderLink,
    index,
}) => {
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const eventInstanceTotal = order?.eventInstanceTotals[eventInstance.eventInstanceId] ?? undefined;

    return (
        <Table.Row key={`eventInstanceRow_${index}`} className="grey">
            <Table.Cell>
                <Link href={`${eventManagementPath}/eventInstances/${eventInstance.eventInstanceId}`}>
                    {eventInstance.venueName}
                </Link>
            </Table.Cell>
            <Table.Cell>{eventInstance.eventTypeName}</Table.Cell>
            <Table.Cell>
                {eventInstance.eventInstanceDeliveryDateTime.format(
                    "ddd DD/MM/YYYY HH:mm"
                )}
            </Table.Cell>
            <Table.Cell>
                {getBookingItemState(eventInstance.cancelled, eventInstance.completed)}
            </Table.Cell>
            <Table.Cell>{eventInstance.attendeeCount}</Table.Cell>
            <Table.Cell>{formatPrice(eventInstanceTotal)}</Table.Cell>
            <Table.Cell>
                <Button
                    icon={"angle right"}
                    as={Link}
                    href={`${orderLink}?eventInstanceId=${eventInstance.eventInstanceId}`}
                />

            </Table.Cell>
        </Table.Row>
    );
};
