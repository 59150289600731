/* eslint-disable max-lines */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Segment, Button, Grid, Divider, Checkbox, Popup } from "semantic-ui-react";
import { AttendeeApi, CompletionState } from "@common/crud/attendee";
import { gotoStageOne, loadAttendeesForEventInstance, loadAttendeesFromSuccess, loadAttendeesSuccess, submitRegister } from "@common/crud/attendee/actions";
import { AttendeeListModel, ReasonIssueEnum } from "@common/crud/attendee/model";
import { Authorize } from "reauthorize";
import { ConstructionHomeRole, EventManagementAdminRolesAndTrainers } from "@common/auth/model";
import { RegisterStatistics } from "./RegisterStatistics";
import { EventInstanceUpdateDelegateFormErrors, isNowAfter24WindowEnd } from "../../model";
import { block } from "redux-little-router";
import { EventInstanceApi } from "../..";
import { getRegisterStats, mapCompletionRegisterAttendees } from "./helpers";
import { useRegisterContext } from "./register-context";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { BookingTypeEnum, ProductCategoryEnum } from "@common/crud/eventType/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import "./general.scss";
import { Media } from "@common/global/AppMedia";
import moment from "moment";
import { OnRoadReportStatus } from "../onRoadReports/model";
import { ConfirmButton } from "@common/components";
import { forceRegisterProcessing } from "../../actions";
import { ConstructionUpdateDelegatesTableRow } from "../details/ConstructionUpdateDelegatesTableRow";
import { EventInstanceGroupDay } from "@common/crud/eventInstanceGroup/model";
import { appSettingsSelector } from "@common/appSettings/selectors";

interface ConstructionDelegateCompletionRegisterProps {
    areDelegatesEditable: boolean;
    canChangeDelegateAmount: boolean;
    triggerSubmit: boolean;
    forceDelegateConfirmTimes: () => void;
    eventInstanceDate: moment.Moment;
    eventInstanceGroupDays: EventInstanceGroupDay[];
}

export const ConstructionDelegateCompletionRegister: React.FC<ConstructionDelegateCompletionRegisterProps> = (props) => {
    const { areDelegatesEditable, canChangeDelegateAmount, forceDelegateConfirmTimes, triggerSubmit, eventInstanceGroupDays } = props;
    const dispatch = useDispatch();
    const { attendees, eventInstanceOptions, eventInstance, attendeesFrom } = useRegisterContext();
    const isEventInstanceOpen = eventInstance.bookingType === BookingTypeEnum.Open;
    const isEventInstanceMultiDay = !!eventInstance.groupId;
    const app = useSelector(appSelector);
    const settings = useSelector(appSettingsSelector);
    const allowConstructionOpenBookingManualDelegateAdjustments = settings.featureSettings?.allowConstructionOpenBookingManualDelegateAdjustments;
    const isAdminApp = app === Apps.Admin;
    const isTrainerApp = app === Apps.Trainer;
    const
        {
            eventInstanceId,
            hasBeenSubmitted,
            attendeesRegisterDate,
            updatesInProgress,
            eventInstanceFinished,
            registerClosed,
        } = eventInstanceOptions;

    const [showError, setShowError] = React.useState<boolean>(false);
    const [needsResubmission, setNeedsResubmission] = React.useState(updatesInProgress);
    const setNeedsSubmission = React.useCallback(() => setNeedsResubmission(true), []);

    const [attendeeRegister, setAttendeeRegister] = React.useState<AttendeeListModel[]>(attendees?.sort((a, b) => a.forename.localeCompare(b.forename)) || []);
    const [attendeesRegisterFrom, setAttendeesRegisterFrom] = React.useState<moment.Moment>(attendeesFrom);
    const [changedDataSubmitting, setChangedDataSubmitting] = React.useState<boolean>(false);
    const [registerEditable, setRegisterEditable] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [formErrors, setFormErrors] = React.useState<EventInstanceUpdateDelegateFormErrors>({});
    const [showRemovedAttendees, setShowRemovedAttendees] = React.useState<boolean>(false);
    const [delegateDataFetchingInProgress, setDelegateDataFetchingInProgress] = React.useState<string[]>([]);

    const dataFetchingInProgress = React.useMemo(() => delegateDataFetchingInProgress.length > 0, [delegateDataFetchingInProgress]);
    const onRoadFormsRequiredAndNotSubmitted = eventInstance?.onRoadReportRequired &&
        attendeeRegister.filter(a => a.completed === CompletionState.Completed).some(a => a.onRoadReportStatus !== OnRoadReportStatus.Submitted);
    const isAps = eventInstance.productCategory === ProductCategoryEnum.StandardAps;
    const isCitb = eventInstance.productCategory === ProductCategoryEnum.StandardCitb;

    function onShowRemovedAttendeesChange() {
        setShowRemovedAttendees(prevValue => !prevValue);
    }

    React.useEffect(() => {
        dispatch(loadAttendeesForEventInstance({ eventInstanceId }));
    }, [dispatch, eventInstanceId]);

    React.useEffect(() => {
        if (attendees && !registerEditable) {
            setAttendeeRegister(attendees);
            setAttendeesRegisterFrom(attendeesFrom);
        }
    }, [attendees, attendeesFrom, registerEditable]);

    const [previousTriggerSubmit, setPreviousTriggerSubmit] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (triggerSubmit !== previousTriggerSubmit) {
            if (triggerSubmit) {
                setNeedsResubmission(false);

                const models = mapCompletionRegisterAttendees(attendees, attendeeRegister, isEventInstanceMultiDay, eventInstanceId);

                // complete: true, marks stage 2 complete
                dispatch(submitRegister(eventInstanceId, models, true));
            }

            setPreviousTriggerSubmit(triggerSubmit);
        }
    }, [attendeeRegister, attendees, dispatch, eventInstanceId, isEventInstanceMultiDay, previousTriggerSubmit, triggerSubmit]);

    const totalPlacesBooked = React.useMemo(() => {
        const filteredAttendees = isEventInstanceMultiDay
            ? attendees.filter(a => a.eventInstanceId === eventInstanceId)
            : attendees;
        return filteredAttendees?.length || 0;
    }, [attendees, eventInstanceId, isEventInstanceMultiDay]);
    const noCancelledAttendee = React.useMemo(() => {
        const filteredAttendees = isEventInstanceMultiDay
            ? attendees.filter(a => a.eventInstanceId === eventInstanceId)
            : attendees;
        return !filteredAttendees?.find(a => a.isBookingCanceled);
    }, [attendees, eventInstanceId, isEventInstanceMultiDay]);

    function validateAttendeeRegister(register: AttendeeListModel[]) {
        const models = mapCompletionRegisterAttendees(attendees, register, isEventInstanceMultiDay, eventInstanceId);
        if (models.some(a =>
            a.completed === CompletionState.Unknown ||
            a.completed === CompletionState.NotCompleted && a.reasonIssue === ReasonIssueEnum.None)) {
            return false;
        }

        return true;
    }

    function validate(): boolean {
        const attendeesToBeChecked = isEventInstanceMultiDay
            ? attendeeRegister.filter(a => a.eventInstanceIds?.includes(eventInstanceId) || false)
            : attendeeRegister;

        const isValid = validateAttendeeRegister(attendeesToBeChecked);

        if (!isValid) {
            setShowError(true);
            return isValid;
        }

        setShowError(false);
        return isValid;
    }

    async function handleSubmit() {
        if (!validate()) {
            return;
        }

        if (eventInstance.actualStartTime && eventInstance.actualEndTime) {
            setNeedsResubmission(false);

            const models = mapCompletionRegisterAttendees(attendees, attendeeRegister, isEventInstanceMultiDay, eventInstanceId);

            // complete: true, marks stage 2 resubmit
            dispatch(submitRegister(eventInstanceId, models, true));
        } else {
            forceDelegateConfirmTimes();
        }
    }

    const onForceRegisterProcessing = React.useCallback(async () => {
        dispatch(forceRegisterProcessing(eventInstanceId));
    }, [dispatch, eventInstanceId]);

    const registerMessage = React.useMemo(() => {
        if (attendeesRegisterDate === null) {
            return null;
        }

        if ((isAdminApp || !registerClosed) && needsResubmission) {
            return <p className="red-warning-text">Please resubmit the register to save your updates</p>;
        }

        const isCompleted = !isNowAfter24WindowEnd(attendeesRegisterDate);

        return isCompleted ? "The register has been completed." : "This register has been submitted";
    }, [attendeesRegisterDate, isAdminApp, needsResubmission, registerClosed]);

    function onRedirect() { dispatch(gotoStageOne(eventInstanceId)); }

    if (!location.pathname.endsWith("attendees") && window.location.pathname.endsWith("attendees") && needsResubmission) {
        dispatch(block(() => {
            return `Are you sure you want to leave the register?
            Any changes you made will not have been saved.
            This register will still require re submission before it can be processed.
            Alternatively you can discard your changes.`;
        }));
    }

    const onDiscard = React.useCallback(async () => {
        const api = new EventInstanceApi();
        await api.setUpdating(eventInstanceId, false);
        window.location.reload();
    }, [eventInstanceId]);

    const hasBeenSubmittedAndNoNeedToResubmit = hasBeenSubmitted && !needsResubmission;
    const submitButtonText = hasBeenSubmittedAndNoNeedToResubmit ? "REGISTER SUBMITTED" : "SUBMIT REGISTER";
    const submitDisabled = !eventInstanceFinished || hasBeenSubmittedAndNoNeedToResubmit || onRoadFormsRequiredAndNotSubmitted
        || (!isAdminApp && registerClosed);

    const renderRegisterMessagePara = React.useMemo(() => (
        <div className="register-message-paragraph">
            {!eventInstanceFinished && "Submit action will be enabled once the course has finished"}
            {registerMessage}
            {showError && <p className="error">In order to submit the register, please complete the actions in red.</p>}
        </div>
    ), [eventInstanceFinished, registerMessage, showError]);

    const showRemovedAttendeesLabel = `${showRemovedAttendees ? "Hide" : "Show"} removed attendees`;
    const removedAttendeeElement = (
        <Authorize authorize={[ConstructionHomeRole]}>
            <Checkbox
                toggle
                disabled={noCancelledAttendee}
                label={showRemovedAttendeesLabel}
                checked={showRemovedAttendees}
                onChange={onShowRemovedAttendeesChange}
            />
        </Authorize>
    );

    const attendeesToShow = React.useMemo(() => {
        let filteredAttendees = attendeeRegister ? [...attendeeRegister] : [];

        if (!showRemovedAttendees) {
            filteredAttendees = filteredAttendees.filter(a => !a.isBookingCanceled);
        }

        if (isEventInstanceMultiDay && !registerEditable) {
            filteredAttendees = filteredAttendees.filter(a => a.eventInstanceIds?.includes(eventInstanceId) || a.eventInstanceId === eventInstanceId);
        }

        return filteredAttendees;
    }, [attendeeRegister, eventInstanceId, isEventInstanceMultiDay, registerEditable, showRemovedAttendees]);

    const canAddMoreDelegates = React.useMemo(() =>
        (eventInstance.bookingType === BookingTypeEnum.Closed || (isAdminApp && allowConstructionOpenBookingManualDelegateAdjustments))
            && (attendeeRegister?.filter(a => !a.isBookingCanceled) || []).length < (eventInstance.openPlacesCount || 0),
    [allowConstructionOpenBookingManualDelegateAdjustments, attendeeRegister, eventInstance.bookingType, eventInstance.openPlacesCount, isAdminApp]);

    const onEditRegister = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRegisterEditable(true);
    }, []);

    const onCancelEdit = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAttendeeRegister(attendees);
        setFormErrors({});
        setRegisterEditable(false);
        setSubmitted(false);
    }, [attendees]);

    const onSaveChanges = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setSubmitted(true);

        const emptyNotSavedAttendeeIds = attendeeRegister.filter(a => a.notSaved && !a.forename && !a.surname  && !a.email && !a.telephone).map(a => a.id);

        const formErrorsForCheck = { ...formErrors };
        if (emptyNotSavedAttendeeIds.length > 0) {
            for (const emptyNotSavedAttendeeId of emptyNotSavedAttendeeIds) {
                if (formErrorsForCheck[emptyNotSavedAttendeeId] !== undefined) {
                    delete formErrorsForCheck[emptyNotSavedAttendeeId];
                }
            }
            setFormErrors(formErrorsForCheck);

            const newAttendeeRegister = attendeeRegister.filter(a => !emptyNotSavedAttendeeIds.some(id => id === a.id));
            setAttendeeRegister(newAttendeeRegister);
        }

        if (Object.keys(formErrorsForCheck).length !== 0) {
            toast.error("Please provide valid data");
            return;
        }

        setChangedDataSubmitting(true);

        try {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const result = await attendeeApi.updateDelegateAttendees(attendeeRegister, attendeesRegisterFrom, props.eventInstanceDate);
            dispatch(loadAttendeesFromSuccess(result.attendeesFrom));
            dispatch(loadAttendeesSuccess(result.attendees));
            setFormErrors({});
            setRegisterEditable(false);
            setSubmitted(false);
        } finally {
            setChangedDataSubmitting(false);
        }
    }, [attendeeRegister, attendeesRegisterFrom, dispatch, eventInstanceId, formErrors, props.eventInstanceDate]);

    const updateDelegate = React.useCallback((delegate: AttendeeListModel, updatedDelegate: AttendeeListModel, forceImmediateSave?: boolean) => {
        const updatedAttendeeRegister = attendeeRegister.map(d => d.id === delegate.id ? updatedDelegate : d);
        setAttendeeRegister(updatedAttendeeRegister);

        if (forceImmediateSave) {
            dispatch(submitRegister(eventInstanceId, updatedAttendeeRegister, false));
        }
    }, [attendeeRegister, dispatch, eventInstanceId]);

    const updateFormErrorsOnFieldChange = React.useCallback((delegate: AttendeeListModel, formField: keyof AttendeeListModel, valid: boolean,
        awareFormErrors?: EventInstanceUpdateDelegateFormErrors) => {
        const checkedFormErrors = awareFormErrors || formErrors;

        if (!(delegate.id in checkedFormErrors)) {
            if (!valid) {
                const set = new Set<string>();
                set.add(formField);
                const newFormErrors = { ...checkedFormErrors, [delegate.id]: set };
                setFormErrors(newFormErrors);
                return newFormErrors;
            }
            return checkedFormErrors;
        }

        const newFormErrors = { ...checkedFormErrors };
        if (newFormErrors[delegate.id].has(formField) && valid) {
            newFormErrors[delegate.id].delete(formField);
        } else if (!newFormErrors[delegate.id].has(formField) && !valid) {
            newFormErrors[delegate.id].add(formField);
        }

        if (newFormErrors[delegate.id].size === 0) {
            delete newFormErrors[delegate.id];
        }

        setFormErrors(newFormErrors);
        return newFormErrors;
    }, [formErrors, setFormErrors]);

    const onAddRowClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const newDelegate: AttendeeListModel = {
            id: v4(),
            eventInstanceIds: isEventInstanceMultiDay ? eventInstanceGroupDays.map(eigd => eigd.eventInstanceId) : [],
            notSaved: true
        };
        setAttendeeRegister(prev => [...prev, newDelegate]);
    }, [eventInstanceGroupDays, isEventInstanceMultiDay]);

    const removeDelegate = React.useCallback((delegate: AttendeeListModel) => {
        setAttendeeRegister(attendeeRegister.filter(d => d.id !== delegate.id));
        if (delegate.id in formErrors) {
            const newFormErrors = { ...formErrors };
            delete newFormErrors[delegate.id];
            setFormErrors(newFormErrors);
        }
    }, [attendeeRegister, formErrors]);

    const fetchingRowDataInProgress = React.useCallback((delegateId: string) => {
        setDelegateDataFetchingInProgress([ ...delegateDataFetchingInProgress, delegateId ]);
    }, [delegateDataFetchingInProgress]);

    const fetchingRowDataFinished = React.useCallback((delegateId: string) => {
        setDelegateDataFetchingInProgress(delegateDataFetchingInProgress.filter(af => af !== delegateId));
    }, [delegateDataFetchingInProgress]);

    const canForceProcessing = React.useMemo(() =>
        isAdminApp && !!eventInstance.attendeesRegisterDate && !eventInstance.registerProcessedDate && !eventInstance.forceRegisterProcessing,
    [isAdminApp, eventInstance]);

    const updateDelegatesElement = (
        (!registerEditable && areDelegatesEditable) ?
            (<Button
                type="button"
                onClick={onEditRegister}
                content="UPDATE DELEGATES"
                disabled={!isAdminApp && registerClosed}
                floated="left"
                className="update-delegates-completion-button"
            />)
            : (registerEditable && canChangeDelegateAmount) ?
                (<Button
                    type="button"
                    onClick={onAddRowClick}
                    content='Add new delegate'
                    floated="left"
                    labelPosition='left'
                    className="update-delegates-completion-button"
                    icon='add circle'
                    disabled={!canAddMoreDelegates}
                />)
                : null
    );

    const updateDelegatesElementMobile = (
        (!registerEditable && areDelegatesEditable) ?
            (<Button
                type="button"
                onClick={onEditRegister}
                content="UPDATE"
                disabled={!isAdminApp && registerClosed}
                className="mobile-button"
            />)
            : (registerEditable && canChangeDelegateAmount) ?
                (<Button
                    type="button"
                    onClick={onAddRowClick}
                    content='Add'
                    className="mobile-button"
                    disabled={!canAddMoreDelegates}
                />)
                : null
    );

    const blockAddingDelegatesElement = ((eventInstance.bookingType === BookingTypeEnum.Closed
        || (isAdminApp && allowConstructionOpenBookingManualDelegateAdjustments)) && registerEditable && !canAddMoreDelegates)
        ? (<span className="update-delegates-completion-span">Cannot add delegates over open places count</span>)
        : null;

    const attendeeColumnWidth = React.useMemo(() => {
        if (registerEditable) {
            return isCitb ? 6 : 8;
        } else if (isTrainerApp) {
            if (isAps) {
                return 10;
            } else if (isCitb) {
                return 5;
            } else {
                return 12;
            }
        } else {
            return (isAps || isCitb) ? 5 : 7;
        }
    }, [registerEditable, isTrainerApp, isAps, isCitb]);

    const contactDetailsColumnWidth = React.useMemo(() => {
        if (registerEditable) {
            return isCitb ? 6 : 8;
        } else {
            return (isAps || isCitb) ? 5 : 7;
        }
    }, [registerEditable, isAps, isCitb]);

    const delegateStateColumnWidth = React.useMemo(() => {
        return isAps ? 4 : 2;
    }, [isAps]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container padded className="register-table  detail-list-container">
                    <Grid.Row only="computer">
                        <Grid.Column className="purple-header" width={attendeeColumnWidth}>
                            {isEventInstanceOpen ? "Org & Attendee Name" : "Attendee Name"}
                        </Grid.Column>
                        {(!isTrainerApp || (isTrainerApp && isCitb)) && (
                            <Grid.Column className="purple-header" width={contactDetailsColumnWidth}>
                                Contact Details
                            </Grid.Column>
                        )}
                        {!registerEditable && isAps && (
                            <Grid.Column className="purple-header" width={2}>
                                Scores
                            </Grid.Column>
                        )}
                        {isCitb && (
                            <Grid.Column className="purple-header" width={4}>
                                CITB Details
                            </Grid.Column>
                        )}
                        {!registerEditable && (
                            <Grid.Column className="purple-header" computer={delegateStateColumnWidth} textAlign="right">
                                {isAps ? "Passed" : "Completed"}
                            </Grid.Column>
                        )}
                    </Grid.Row>
                    <Divider className="full-width" />
                    {attendeesToShow.map(attendee => (
                        <React.Fragment key={attendee.id}>
                            <ConstructionUpdateDelegatesTableRow
                                delegate={attendee}
                                isEditable={registerEditable && !attendee.isBookingCanceled}
                                eventInstanceId={eventInstanceId}
                                updateFormErrorsOnFieldChange={updateFormErrorsOnFieldChange}
                                updateDelegate={updateDelegate}
                                isStageTwo={true}
                                resubmissionRequired={needsResubmission}
                                showCompletionError={showError}
                                setResubmissionRequired={setNeedsSubmission}
                                removeDelegate={removeDelegate}
                                isEventInstanceOpen={isEventInstanceOpen}
                                isEventInstanceMultiDay={isEventInstanceMultiDay}
                                eventInstanceGroupDays={eventInstanceGroupDays}
                                canChangeDelegateAmount={canChangeDelegateAmount}
                                fetchingRowDataInProgress={fetchingRowDataInProgress}
                                fetchingRowDataFinished={fetchingRowDataFinished}
                                submitted={submitted}
                            />
                            <Divider className="full-width" />
                        </React.Fragment>
                    ))}
                    <Media lessThan="computer" className="detail-list-container">
                        <br />
                        {updateDelegatesElementMobile}
                        <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                            {registerEditable && (<>
                                <Button
                                    type="submit"
                                    className="mobile-button"
                                    onClick={onSaveChanges}
                                    disabled={changedDataSubmitting || dataFetchingInProgress}
                                    content="SAVE"
                                    floated="right"
                                />
                                <Button type="button" onClick={onCancelEdit} className="cancel-action" content="CANCEL" floated="right" /></>)}
                        </Authorize>
                    </Media>
                    <Media lessThan="computer" className="detail-list-container completion">
                        <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                            {!registerEditable && (<>
                                <Popup disabled={!onRoadFormsRequiredAndNotSubmitted}
                                    trigger={
                                        <div>
                                            <Button
                                                type="submit"
                                                className="mobile-button"
                                                content={submitButtonText}
                                                disabled={submitDisabled}
                                            />
                                        </div>}
                                    content={"On Road Reports must be submitted for all delegates who completed before submitting the register"} />
                                {canForceProcessing && (
                                    <ConfirmButton
                                        icon="check"
                                        content={"You are about to update this register as processed. If you complete this action, the relevant register"
                                            + " processing tasks will be initiated within 30 minutes. If you would like to continue, please click the"
                                            + " OK button. To cancel this process, click CANCEL"}
                                        header="Mark register as processed"
                                        onConfirm={onForceRegisterProcessing}
                                    >
                                        Process Register
                                    </ConfirmButton>
                                )}
                                {(needsResubmission && (isAdminApp || !registerClosed)) &&
                                    <Button className="mobile-button" icon="close" color="red" type="button" onClick={onDiscard} content="DISCARD CHANGES" />}
                                {renderRegisterMessagePara}
                            </>)}
                            <br />
                            <Button
                                className="mobile-button"
                                icon="angle left"
                                color="grey"
                                type="button"
                                onClick={onRedirect}
                                disabled={!isAdminApp && registerClosed}
                                content="UPDATE ARRIVALS"
                            />
                        </Authorize>
                    </Media>
                    <RegisterStatistics
                        showCompletion
                        registerStats={getRegisterStats(attendees, isEventInstanceMultiDay, eventInstanceId, totalPlacesBooked, attendeeRegister)}
                        removedAttendeeElement={removedAttendeeElement}
                        updateDelegatesElement={updateDelegatesElement}
                        blockAddingDelegatesElement={blockAddingDelegatesElement}
                    />
                </Grid>
                <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                    <Media greaterThanOrEqual="computer">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Segment className="button-segment no-margin-top">
                                        <div className="button-container left-align">
                                            <Button
                                                icon="angle left"
                                                color="grey"
                                                type="button"
                                                onClick={onRedirect}
                                                disabled={!isAdminApp && registerClosed}
                                                content="UPDATE ARRIVALS"
                                            />
                                        </div>
                                    </Segment>
                                </Grid.Column>
                                {registerEditable && (
                                    <Grid.Column computer={10} >
                                        <Segment className="button-segment not-mobile">
                                            <Button
                                                type="submit"
                                                onClick={onSaveChanges}
                                                disabled={changedDataSubmitting || dataFetchingInProgress}
                                                content="SAVE CHANGES"
                                                floated="right"
                                            />
                                            <Button type="button" onClick={onCancelEdit} className="cancel-action" content="CANCEL" floated="right" />
                                        </Segment>
                                    </Grid.Column>
                                )}
                                {!registerEditable && (
                                    <Grid.Column computer={10} className="text-right-align">
                                        <Segment className="button-segment no-margin-top">
                                            <div className="button-container left-align right-align-last-element">
                                                {(needsResubmission && (isAdminApp || !registerClosed)) &&
                                                    <Button icon="close" color="red" type="button" onClick={onDiscard} content="DISCARD CHANGES" />}
                                            </div>
                                            <Popup disabled={!onRoadFormsRequiredAndNotSubmitted}
                                                trigger={
                                                    <div className="button-container left-align right-align-last-element">
                                                        <Button
                                                            type="submit"
                                                            content={submitButtonText}
                                                            disabled={submitDisabled}
                                                        />
                                                    </div>}
                                                content={"On Road Reports must be submitted for all delegates who completed before submitting the"
                                                    + " register"} />
                                            {canForceProcessing && (
                                                <div className="button-container left-align right-align-last-element">
                                                    <ConfirmButton
                                                        icon="check"
                                                        content={"You are about to update this register as processed. If you complete this action, the relevant"
                                                            + " register processing tasks will be initiated within 30 minutes. If you would like to continue,"
                                                            + " please click the OK button. To cancel this process, click CANCEL"}
                                                        header="Mark register as processed"

                                                        onConfirm={onForceRegisterProcessing}
                                                    >
                                                        Process Register
                                                    </ConfirmButton>
                                                </div>
                                            )}
                                            {renderRegisterMessagePara}
                                        </Segment>
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                    </Media>
                </Authorize>
            </Form>
        </>
    );
};
