import { DrivingLicenceCountryEnum } from "@common/crud/delegate/model";
import moment from "moment";
import { createValidator } from "not-valid";

const licenceRegEx =
    /^(?=.{16}$)[A-Za-z]{1,5}9{0,4}[0-9](?:[05][1-9]|[16][0-2])(?:[0][1-9]|[12][0-9]|3[01])[0-9](?:99|[A-Za-z][A-Za-z9])(?![IOQYZioqyz01_])\w[A-Za-z]{2}/;

const getSurnameValid = (licence: string, attendeeSurname?: string) => {
    if (!attendeeSurname) {
        return true;
    }

    const licenceSurname = licence?.substring(0, 5).toUpperCase();
    let surname = attendeeSurname.replace(/\W/g, "").toUpperCase();
    surname = surname.substring(0, 3) === "MAC" ? "MC" + surname.substring(3) : surname;
    return licenceSurname === (surname + "99999").substring(0, 5);
};

const getBirthDateValid = (licence: string) => {
    const licenceBirthDate = licence.substring(5, 11);
    const day = licenceBirthDate.substring(3, 5);
    let month = licenceBirthDate.substring(1, 3);
    let monthNumber = parseInt(month, 10);
    if (monthNumber > 50) {
        monthNumber -= 50;
        month = monthNumber.toString().padStart(2, "0");
    }
    const year = licenceBirthDate[0] + licenceBirthDate[5];

    const birthDate = moment(`${day}/${month}/${year}`, "DD/MM/YY");

    if (birthDate.isValid() && birthDate.isSameOrAfter(moment())) {
        birthDate.subtract(100, "years");
    }

    return birthDate && birthDate.isValid() && birthDate.isBefore(moment().add(-17, "years"), "day") && birthDate.isAfter(moment().add(-100, "years"), "day");
};

export const licenceValidator = (drivingLicenceCountry?: DrivingLicenceCountryEnum, attendeeSurname?: string,
    alreadyUsedDrivingLicenceNumbers?: string[]) => {
    return createValidator<string>((v: string) => {
        if (v && alreadyUsedDrivingLicenceNumbers.filter(n => n === v).length > 1) {
            return false;
        }

        if (!v || !drivingLicenceCountry || (drivingLicenceCountry !== DrivingLicenceCountryEnum.GB
            && drivingLicenceCountry !== DrivingLicenceCountryEnum.NI)) {
            return true;
        }

        if (drivingLicenceCountry === DrivingLicenceCountryEnum.NI) {
            return v.length === 8 && !!v.match(/^\d+$/);
        }

        if (drivingLicenceCountry === DrivingLicenceCountryEnum.GB) {
            return licenceRegEx.test(v) && getSurnameValid(v, attendeeSurname) && getBirthDateValid(v);
        }

        return true;
    },
    "Please enter a valid unique driving licence number");
};
