import * as React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { DropdownProps, Form, DropdownItemProps, StrictDropdownProps } from "semantic-ui-react";

import { SemanticFormError } from "./SemanticFormError";

interface SemanticDropdownProps<T> {
    name: Exclude<keyof T, number | symbol>;
    label: string;
    note?: string;
    placeholder: string;
    validate?: (value: string) => string | null;
    options: DropdownItemProps[];
}

export function SemanticDropdown<T extends Dictionary<StrictDropdownProps["value"]>>({
    name,
    label,
    options,
    placeholder,
    validate,
    note
}: SemanticDropdownProps<T>) {

    const { values } = useFormikContext<T>();

    return (
        <>
            <Field name={name} validate={validate}>
                {({
                    field: { onChange, onBlur, ...fieldProps }
                }: FieldProps) => {
                    const wrappedOnChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        onChange({ target: { name, value: data.value } });
                    };
                    const wrappedOnBlur = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        onBlur({ target: { name } });
                    };
                    return (
                        <Form.Select
                            {...fieldProps}
                            onChange={wrappedOnChange}
                            onBlur={wrappedOnBlur}
                            type="text"
                            value={values[name]}
                            options={options}
                            label={label}
                            className={note && "field-with-note"}
                            placeholder={placeholder}
                        />
                    );
                }}
            </Field>
            {note && <p className="field-note">{note}</p>}
            <SemanticFormError name={name} />
        </>
    );
}
