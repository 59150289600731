/* eslint-disable max-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form, Tab, TabProps, DropdownItemProps, CheckboxProps, Checkbox, Icon, Message, Segment } from "semantic-ui-react";
import { FormBaseComponent, SaveDispatchProps, FormState } from "@neworbit/simpleui-forms";
import { debounce } from "@neworbit/simpleui-utils";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch, BusinessLineType, businessLineTypeSelector, createBasePathSelector } from "@common/redux-helpers";
import { TrainerAttributesPicker } from "@common/crud/common/TrainerAttributesPicker";
import { TrainerAttributeDefinition, TrainerAttributeDefinitionState } from "@common/crud/trainerAttributeDefinition";
import { trainerAttributeDefinitionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { TrainerApi } from "../trainerApi";
import { CompanyTypeEnum, CompanyType, ADTrainerUser, TrainerEmploymentType } from "../model";
import { createTrainer } from "../actions";
import { basePathSelector } from "../selectors";
import { TrainerCreateEditModel, AppState } from "../model";
import { CREATE_TRAINER } from "../actiontypes";
import { CreateModal } from "./CreateModal";
import { policeOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { OrganisationState } from "@common/crud/organisation";
import { phoneNumberWithSpaces, vatNumber, sortCode, bankAccountNumber } from "@common/validation";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { optionsFromObject } from "@common/crud/common";
import { AuthState, TtcFinanceAdministratorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { CountryDropDownOptions } from "@common/crud/organisation/model";
import { toast } from "@common/toasts";
import { DuplicateUserMessage } from "./DuplicateUserMessage";
import { getBusinessLineTypePath, isHomePath } from "@common/global/CommonHelpers";
import { currentUserIsInRoleSelector } from "@common/auth/selectors";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";
import { MultiSelectCheckbox } from "@common/components";

export interface CreateProps {
    open: boolean;
    basePath: string;
    trainerAttributeDefinitions: TrainerAttributeDefinition[];
    isFinanceAdministrator: boolean;
    findUser: (email: string) => Promise<ADTrainerUser>;
    isSaving: boolean;
    organisationOptions: DropdownItemProps[];
    quickAddId: string;
    businessLineType: BusinessLineType;
}

export interface DispatchProps extends SaveDispatchProps<TrainerCreateEditModel> {
    close: () => void;
}

interface CreateState extends FormState<TrainerCreateEditModel> {
    duplicateUser: ADTrainerUser;
    removeADB2CConfirmed: boolean;
    activeIndex: string | number;
    isVatRegistered: boolean;
    quickAddId: string;
    searchingEmail: boolean;
}

export class CreateForm extends FormBaseComponent<TrainerCreateEditModel, CreateProps, CreateState> {

    private onChange = (k: keyof TrainerCreateEditModel, trimValue?: boolean) => (value: any, valid: boolean) => {
        this.updateProperty(k, trimValue ? value.trim() : value, valid);
    };

    private onNestedPropertyChange = (k: string) => (value: any, valid: boolean) => {
        this.updateNestedProperty(k, value, valid);
    };

    private onIsVatRegisteredChange = (_: any, { checked }: CheckboxProps) => this.setState(prevState => ({
        ...prevState,
        isVatRegistered: checked,
        values: {
            ...prevState.values,
            vatNumber: "",
        }
    }));

    private onRemoveADB2CConfirm = (_: any, { checked }: CheckboxProps) =>
        this.setState((prevState) =>
            ({ ...this.state,
                values: { ...this.state.values,
                    adB2CAccountForRemovalId: checked ? prevState.duplicateUser.adB2CId : null },
                removeADB2CConfirmed: checked,
                valid: { ...this.state.valid, email: checked }
            }));

    private setBusinessLineTypes = (update: (arr: number[]) => number[]) =>
    {
        const businessLineTypes = update(this.state.values.businessLineTypes);
        this.setState(() =>
            ({ ...this.state,
                values: { ...this.state.values, businessLineTypes },
                valid: { ...this.state.valid,
                    businessLineTypes: businessLineTypes.length > 0 }
            }));
    };

    private panes = [
        {
            menuItem: "Details", render: () => {
                const { values, duplicateUser, searchingEmail, showErrors } = this.state;
                const { basePath } = this.props;
                const limitedCompanySelected = values.companyType === CompanyTypeEnum.LimitedCompany;

                return (<Tab.Pane key="mainPane">
                    <Input.Text
                        value={values.title}
                        label="Title"
                        showErrors={showErrors}
                        onChange={this.onChange("title")}
                    />
                    <Input.Text
                        value={values.forename}
                        label="Forename"
                        showErrors={showErrors}
                        required
                        onChange={this.onChange("forename")}
                    />
                    <Input.Text
                        value={values.surname}
                        label="Surname"
                        showErrors={showErrors}
                        required
                        onChange={this.onChange("surname")}
                    />
                    <Input.DropdownNumber
                        label="Employment Type"
                        showErrors={showErrors}
                        value={values.employmentType}
                        options={optionsFromObject(TrainerEmploymentType)}
                        onChange={this.onChange("employmentType")}
                    />
                    <>
                        <span>Line of Business</span> <span className="required-asterisk">*</span>
                        <MultiSelectCheckbox
                            label="Police and Courts"
                            value={BusinessLineType.PoliceAndCourt}
                            selectedValues={this.state.values.businessLineTypes}
                            onValuesChanged={this.setBusinessLineTypes}
                        />
                        <MultiSelectCheckbox
                            label="Commercial"
                            value={BusinessLineType.Corporate}
                            selectedValues={this.state.values.businessLineTypes}
                            onValuesChanged={this.setBusinessLineTypes}
                        />
                        <MultiSelectCheckbox
                            label="Construction"
                            value={BusinessLineType.Construction}
                            selectedValues={this.state.values.businessLineTypes}
                            onValuesChanged={this.setBusinessLineTypes}
                        />
                        {showErrors && this.state?.valid?.businessLineTypes === false &&
                            <div className="ui red pointing above basic label"><p>This field is required</p></div>
                        }
                    </>
                    <AddressLookup
                        address={values.address}
                        showErrors={showErrors}
                        onChange={this.onChange("address")}
                        autofill
                    />
                    <Authorize authorize={TtcFinanceAdministratorRole} >
                        <h2>Company Information</h2>
                        <Checkbox label="VAT Registered?" onClick={this.onIsVatRegisteredChange} checked={this.state.isVatRegistered} />
                        {this.state.isVatRegistered && (
                            <Input.Text
                                value={values.vatNumber}
                                label="Vat Number"
                                showErrors={showErrors}
                                validation={vatNumber()}
                                onChange={this.onChange("vatNumber")}
                            />
                        )}
                        <Input.DropdownNumber
                            value={values.companyType}
                            label="Company Type"
                            showErrors={showErrors}
                            options={optionsFromObject(CompanyType)}
                            onChange={this.onChange("companyType")}
                            required
                        />
                        {limitedCompanySelected && (
                            <>
                                <Input.Text
                                    value={values.companyName}
                                    label="Company Name"
                                    showErrors={showErrors}
                                    onChange={this.onChange("companyName")}
                                />
                                <Input.Text
                                    value={values.companyRegistrationNumber}
                                    label="Company Registration Number"
                                    showErrors={showErrors}
                                    onChange={this.onChange("companyRegistrationNumber")}
                                />
                                <AddressLookup
                                    address={values.companyAddress}
                                    showErrors={showErrors}
                                    onChange={this.onChange("companyAddress")}
                                    title={"Company Address"}
                                    required={false}
                                    autofill
                                />
                            </>
                        )}
                        <h2>Bank Account</h2>
                        <Input.Text
                            value={values.bankAccount && values.bankAccount.accountName}
                            label="Account Name"
                            showErrors={showErrors}
                            onChange={this.onNestedPropertyChange("bankAccount.accountName")}
                        />
                        <Input.Text
                            value={values.bankAccount && values.bankAccount.sortCode}
                            label="Sort Code"
                            showErrors={showErrors}
                            validation={sortCode()}
                            onChange={this.onNestedPropertyChange("bankAccount.sortCode")}
                        />
                        <Input.Text
                            value={values.bankAccount && values.bankAccount.accountNumber}
                            label="Account Number"
                            showErrors={showErrors}
                            validation={bankAccountNumber()}
                            onChange={this.onNestedPropertyChange("bankAccount.accountNumber")}
                        />
                    </Authorize>
                    <h2>Contact Details</h2>
                    <Input.Email
                        value={values.email}
                        label="Email"
                        showErrors={showErrors}
                        required
                        onChange={this.onEmailChanged}
                    />
                    {searchingEmail &&
                        <Message as={Segment} className="cancel-action">
                            <Icon loading name='spinner' />
                            Searching for existing users with that email...
                        </Message>
                    }
                    {duplicateUser &&
                        <DuplicateUserMessage
                            duplicateUser={duplicateUser}
                            basePath={basePath}
                            removeADB2CConfirmed={this.state.removeADB2CConfirmed}
                            onCheck={this.onRemoveADB2CConfirm} />
                    }
                    <Input.Text
                        value={values.mobileNumber}
                        label="Mobile Number"
                        showErrors={showErrors}
                        validation={phoneNumberWithSpaces()}
                        onChange={this.onChange("mobileNumber")}
                    />
                    <Input.Text
                        value={values.homeNumber}
                        label="Home Number"
                        showErrors={showErrors}
                        validation={phoneNumberWithSpaces()}
                        onChange={this.onChange("homeNumber")}
                    />
                    <h2>Emergency Contact</h2>
                    <Input.Text
                        value={values.emergencyContact && values.emergencyContact.name}
                        label="Name"
                        showErrors={showErrors}
                        onChange={this.onNestedPropertyChange("emergencyContact.name")}
                    />
                    <Input.Text
                        value={values.emergencyContact && values.emergencyContact.phoneNumber}
                        label="Phone Number"
                        validation={phoneNumberWithSpaces()}
                        showErrors={showErrors}
                        onChange={this.onNestedPropertyChange("emergencyContact.phoneNumber")}
                    />
                </Tab.Pane>);
            }
        },
        {
            menuItem: "Attributes", render: () => {
                const { showErrors, values } = this.state;
                const { trainerAttributeDefinitions } = this.props;

                return (
                    <Tab.Pane key="attributesPane">
                        <TrainerAttributesPicker
                            trainerAttributeDefinitions={trainerAttributeDefinitions}
                            trainerAttributes={values.trainerAttributes}
                            showErrors={showErrors}
                            onChange={this.onChange("trainerAttributes")}
                        />
                        <Input.Text
                            value={values.ndorsLicenceNumber}
                            label="NDors Licence Number"
                            showErrors={showErrors}
                            onChange={this.onChange("ndorsLicenceNumber", true)}
                        />
                    </Tab.Pane>
                );
            }
        },
        {
            menuItem: "Areas", render: () => {
                const { values } = this.state;
                return (
                    <Tab.Pane key="areasPane">
                        <Input.DropdownMulti
                            value={values.areaIds}
                            label="NDORS Police Areas"
                            options={this.props.organisationOptions}
                            onChange={this.onChange("areaIds")}
                        />
                        <Input.DropdownMulti
                            value={values.ddrsCountries}
                            label="DDRS Countries"
                            options={CountryDropDownOptions}
                            onChange={this.onChange("ddrsCountries")}
                        />
                    </Tab.Pane>
                );
            }
        },
        {
            index: 3, menuItem: "Management", render: () => {
                return (
                    <Tab.Pane key="managementPane">
                        <Input.Dropdown
                            value={this.state.values.homeArea}
                            label="Home Area"
                            options={this.props.organisationOptions}
                            onChange={this.onChange("homeArea")}
                        />
                    </Tab.Pane>
                );
            }
        }
    ];

    constructor(props: CreateProps & SaveDispatchProps<TrainerCreateEditModel>) {
        super(props);
        this.state = this.initialState;
    }

    public initialValueState: TrainerCreateEditModel = {
        id: "",
        title: "",
        forename: "",
        surname: "",
        employmentType: 1,
        name: "",
        address: {
            postalCode: "",
            city: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: ""
        },
        vatNumber: "",
        companyName: "",
        companyType: undefined,
        companyRegistrationNumber: "",
        companyAddress: {
            postalCode: "",
            city: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: ""
        },
        bankAccount: {
            sortCode: "",
            accountNumber: ""
        },
        email: "",
        mobileNumber: "",
        homeNumber: "",
        emergencyContact: {
            name: "",
            phoneNumber: ""
        },
        trainerAttributes: [],
        ndorsLicenceNumber: "",
        businessLineTypes: [],
    };

    public initialState: CreateState = {
        values: this.initialValueState,
        valid: {
            businessLineTypes: false,
        },
        duplicateUser: undefined,
        removeADB2CConfirmed: false,
        searchingEmail: false,
        activeIndex: 0,
        isVatRegistered: false,
        quickAddId: ""
    };

    public componentDidUpdate() {
        if (this.props.quickAddId !== this.state.quickAddId) {
            this.setState({ ...this.initialState, quickAddId: this.props.quickAddId });
        }
    }

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tab panes={this.panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
            </Form>
        );
    }

    public submit = () => {
        if (this.props.isFinanceAdministrator) {
            if ((this.state.isVatRegistered && !this.state.values.vatNumber) ||
                this.state.values.companyType === CompanyTypeEnum.Unknown ||
                (this.state.values.companyType === CompanyTypeEnum.LimitedCompany && (!this.state.values.companyName || !this.state.values.companyAddress))
            ) {
                this.setState(prevState => ({ ...prevState, activeIndex: 0, loading: false, showErrors: true }));
                toast.error("Please complete the Company Information");
                return;
            }
        }

        if (ObjectKeys(this.state.valid).some(k => !this.state.valid[k])) {
            this.setState(prevState => ({ ...prevState, activeIndex: 0, loading: false, showErrors: true }));
            toast.error("Please correct any invalid fields");
            return;
        }

        this.handleSubmit({ preventDefault: (): void => undefined } as any);
    };

    private onEmailChanged = async (value: string, valid: boolean) => {
        this.updateProperty("email", value, valid);
        if (value && valid) {
            this.setState({ searchingEmail: true, valid: { ...this.state.valid, email: false } });
            const duplicateUser = await this.props.findUser(value);
            this.setState({ searchingEmail: false, duplicateUser, removeADB2CConfirmed: false, valid: { ...this.state.valid, email: !duplicateUser } });
        } else {
            this.setState({ duplicateUser: null, removeADB2CConfirmed: false });
        }
    };

    private handleTabChange = (e: React.MouseEvent, { activeIndex }: TabProps) => this.setState(prevState => ({ ...prevState, activeIndex }));
}

function mapStateToProps(state: AppState & TrainerAttributeDefinitionState & LoadingState & OrganisationState & AuthState) {

    const pathname = state.router.pathname;
    const quickAdd = pathname.includes("quickAdd");
    const createForm = pathname.endsWith("/create");
    const open = quickAdd || createForm;
    const businessLineType = businessLineTypeSelector(state);
    const businessLineTypePath = getBusinessLineTypePath(businessLineType);
    const basePath = quickAdd ? createBasePathSelector(businessLineTypePath)(state) : basePathSelector(state);
    const quickAddId = quickAdd ? pathname.substring(pathname.indexOf("quickAdd")) : "";

    return {
        trainerAttributeDefinitions: trainerAttributeDefinitionsSelector(state),
        isFinanceAdministrator: currentUserIsInRoleSelector(TtcFinanceAdministratorRole)(state),
        open,
        quickAddId,
        basePath,
        isSaving: isRequestActive(state, CREATE_TRAINER),
        organisationOptions: policeOrganisationOptionsSelector(state),
        businessLineType
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (trainer: TrainerCreateEditModel, basePath: string, businessLineType: BusinessLineType) => {
            const quickAdd = isHomePath(basePath);
            return dispatch(createTrainer(trainer, basePath, businessLineType, quickAdd));
        },
        dispatchClose: (basePath: string) => dispatch(push(basePath)),
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): CreateProps & DispatchProps {
    const api = new TrainerApi();
    return {
        quickAddId: propsFromState.quickAddId,
        basePath: propsFromState.basePath,
        open: propsFromState.open,
        trainerAttributeDefinitions: propsFromState.trainerAttributeDefinitions,
        isFinanceAdministrator: propsFromState.isFinanceAdministrator,
        save: (trainer: TrainerCreateEditModel) => propsFromDispatch.dispatchSave(trainer, propsFromState.basePath, propsFromState.businessLineType),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
        findUser: debounce((email: string) => api.findByEmail(email), 300),
        isSaving: propsFromState.isSaving,
        organisationOptions: propsFromState.organisationOptions,
        businessLineType: propsFromState.businessLineType
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
