import * as React from "react";
import { Button, Modal, Form, Radio, CheckboxProps } from "semantic-ui-react";
import { isValidMobileNumber } from "@common/validation/mobileNumber";
import { DrinkDriveOffenderDetailModel, MessageType } from "../../model";
import { SendMessageOver } from "@common/crud/attendee/model";
import { DrinkDriveOffenderApi } from "../../drinkDriveOffenderApi";
import { toast } from "@common/toasts";
import { EventTypeDetailModel } from "@common/crud/eventType/model";
import { DdrsOffenderLetterPrintView } from "@common/ddrsLetters/components/DdrsOffenderLetterPrintView";

export interface FirstInviteLetterModalProps {
    drinkDriveOffender: DrinkDriveOffenderDetailModel;
    eventType: EventTypeDetailModel;
}

export const FirstInviteLetterModal: React.FC<FirstInviteLetterModalProps> = ({ drinkDriveOffender, eventType }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [resendOption, setResendOption] = React.useState<SendMessageOver>(SendMessageOver.Both);
    const [loading, setLoading] = React.useState(false);

    const { email, telephone } = drinkDriveOffender;
    React.useEffect(() => {
        if (email && telephone) {
            setResendOption(SendMessageOver.Both);
        } else {
            setResendOption(email ? SendMessageOver.Email : telephone ? SendMessageOver.SMS : SendMessageOver.Letter);
        }

    }, [email, telephone]);

    function toggleModal() { setOpen(!open); }
    function setOption(e: any, { value }: CheckboxProps) { setResendOption(value as number); }

    async function onContinueClick() {
        setLoading(true);

        if (resendOption === SendMessageOver.Letter) {
            window.print();
        } else {
            await new DrinkDriveOffenderApi().sendComms(drinkDriveOffender.id, resendOption, MessageType.DrinkDriveOffenderInvite);
            toast.info("First invite comm sent");
        }

        setLoading(false);
        setOpen(false);
    }

    const hasValidMobileNumber = isValidMobileNumber(telephone);

    return (
        <>
            <Button disabled={drinkDriveOffender.isTransferred} onClick={toggleModal}>Send Invite</Button>
            {resendOption === SendMessageOver.Letter &&
                <DdrsOffenderLetterPrintView
                    openingWording={eventType?.firstInviteLetterOpening}
                    closingWording={eventType?.firstInviteLetterClosing}
                    drinkDriveOffender={drinkDriveOffender} />}
            <Modal open={open}>
                <Modal.Header>
                    First Invite Comms
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            How do you want to send the first invite comms?
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Both Email and SMS"
                                name="sendMessageMedium"
                                disabled={!hasValidMobileNumber || !email}
                                value={SendMessageOver.Both}
                                checked={resendOption === SendMessageOver.Both}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Email"
                                name="sendMessageMedium"
                                disabled={!email}
                                value={SendMessageOver.Email}
                                checked={resendOption === SendMessageOver.Email}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="SMS"
                                name="sendMessageMedium"
                                disabled={!hasValidMobileNumber}
                                value={SendMessageOver.SMS}
                                checked={resendOption === SendMessageOver.SMS}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Letter (manual print)"
                                name="sendMessageMedium"
                                value={SendMessageOver.Letter}
                                checked={resendOption === SendMessageOver.Letter}
                                onChange={setOption}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="Letter (auto print)"
                                name="radioGroup"
                                value={SendMessageOver.LetterAutomatic}
                                checked={resendOption === SendMessageOver.LetterAutomatic}
                                onChange={setOption}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={toggleModal}
                        className="cancel-action"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinueClick}
                        loading={loading}
                        disabled={resendOption === SendMessageOver.None || loading}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
