import * as React from "react";
import { connect } from "react-redux";
import { Link } from "redux-little-router";
import { Button, Grid } from "semantic-ui-react";
import { AsyncDispatch } from "@common/redux-helpers";
import { PoliceAndDdrsAdminRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { AppCommonState } from "@common/appCommonState";
import { SearchOptions } from "../model";
import { SelectorState } from "../selectors";
import { loadUsers } from "../actions";
import { OrganisationState, selectors as organisationSelectors } from "../../organisation";
import { UserListWithFilters } from "./UserListWithFilters";

interface StateProps {
    path: string;
}

interface DispatchProps {
    loadUsers: (options: SearchOptions) => void;
}

type AllProps = StateProps & DispatchProps;

export class AllInternal extends React.Component<AllProps> {
    public render() {
        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={10}>
                        <h1>Users</h1>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="right">
                        <div className="ui buttons">
                            <Authorize authorize={PoliceAndDdrsAdminRole} >
                                <Button
                                    icon="add"
                                    content="Create"
                                    as={Link}
                                    href={`${this.props.path}/create`}
                                />
                            </Authorize>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <UserListWithFilters loadUsers={this.props.loadUsers} />
            </Grid>
        );
    }
}

function mapStateToProps(state: SelectorState & OrganisationState & AppCommonState) {
    return {
        path: state.router.pathname,
        organisationId: organisationSelectors.routeIdSelector(state),
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        loadUsers: (organisationId: string, options: SearchOptions) => dispatch(loadUsers({ organisationId, options }))
    };
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

function mergeProps(propsFromState: PropsFromState, propsFromDispatch: PropsFromDispatch): AllProps {
    return {
        path: propsFromState.path,
        loadUsers: (options) => propsFromDispatch.loadUsers(propsFromState.organisationId, options),
    };
}

export const All = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AllInternal);
