import axios from "axios";

import { ExperianSearchModel } from "./experianSearchModel";
import { ExperianAddressResponse } from "./experianAddressModel";

const apiUrl = "/api/address-lookup";

export abstract class AddressLookupApi {
    public static async searchForAddress(postcode: string, country: string = "gbr", take: number = 100): Promise<ExperianSearchModel> {
        const response = await axios.get<ExperianSearchModel>(`${apiUrl}/search?query=${postcode}&country=${country}&take=${take}`);
        return response.data;
    }

    public static async getAddressById(id: string, country: string = "gbr"): Promise<ExperianAddressResponse> {
        const response = await axios.get<ExperianAddressResponse>(`${apiUrl}/get?id=${id}&country=${country}`);
        return response.data;
    }
}
