import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { Grid, Tab } from "semantic-ui-react";
import { DetailState, CorporateUserDetailModel } from "../model";
import { basePathSelector, corporateUserSelector } from "../selectors";
import { coreDetailsTab } from "./CoreDetails";

export interface CoreDetailProps {
    corporateUser: CorporateUserDetailModel;
}

export interface DetailProps extends CoreDetailProps {
    path: string;
    basePath: string;
    tab?: string;
}

export interface DispatchProps {
    push: (path: string) => void;
}

const tabs = Object.values([coreDetailsTab]);

class DetailInternal extends React.Component<DetailProps & DispatchProps> {
    constructor(props: DetailProps & DispatchProps) {
        super(props);
    }

    public render() {
        const panes = tabs.map(t => t(this.props));

        const content = () => panes.length === 1
            ? panes[0].render()
            : <Tab panes={panes} />;

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column>
                        <h1>{this.props.corporateUser.fullName}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {content()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state: DetailState): DetailProps {
    return {
        corporateUser: corporateUserSelector(state),
        path: state.router.pathname,
        basePath: basePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        push: (path: string) => dispatch(push(path))
    };
}

export const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailInternal);
