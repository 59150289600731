import {
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { PreCourseSection } from "./PreCourseSection";

/*
Pre course section, removed bullet points:
    Visible on the screen
    Maintained eye contact with screen throughout
    Digital platform open
    PowerPoint ready
    Desktop - no other application / programme/ documents visible
    Suitable room - free from disruption/ adequately lit Meeting Started
*/
export const PreCourseSectionClassroom: QuestionSection = {
    ...PreCourseSection,
    subSections: [
        {
            ...PreCourseSection.subSections[0],
            criteria: [
                { id: "1", text: "Smart business" },
                { id: "2", text: "Wearing ID badge" },
            ]
        },
        {
            id: "1.2",
            text: "Resources and Visual Aids",
            seqNum: 2,
            criteria: PreCourseSection.subSections[2].criteria
        },
        {
            id: "1.3",
            text: "Pre-Course Registers",
            seqNum: 3,
            criteria: PreCourseSection.subSections[3].criteria
        }],
};
