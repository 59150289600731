import * as React from "react";
import { Button, Checkbox, Form, Icon, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { updateNoteToCorrelationId } from "../../notes/actions";
import { toast } from "@common/toasts";
import { Modal } from "@common/components";
import { Input } from "@neworbit/simpleui-input";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { updateOrderNote } from "@common/crud/order/actions";
import { ChangeEventType } from "../model";
import { updateTrainerNote } from "@common/crud/trainer/actions";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";

export interface HistoryNotesModalProps {
    note: string;
    correlationId: string;
    noteId: string;
    taskRelation: TaskRelationEnum;
    eventType: ChangeEventType;
    fileName?: string;
}

export const HistoryNote: React.FC<HistoryNotesModalProps> = (props: HistoryNotesModalProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [noteText, setNoteText] = React.useState(props.note);
    const [removeAttachment, setRemoveAttachment] = React.useState(false);
    const [notModified, setNotModified] = React.useState(true);

    const app = useSelector(appSelector);

    const isAdminApp = React.useMemo(() => app === Apps.Admin, [app]);

    const onNoteChange = React.useCallback((value: string) => {
        setNoteText(value);
        setNotModified((noteText === props.note || noteText === "") && !removeAttachment);
    }, [noteText, props.note, removeAttachment]);

    const onToggleRemoveAttachment = React.useCallback(() => {
        setRemoveAttachment(!removeAttachment);
        setNotModified((noteText === props.note || noteText === "") && removeAttachment);
    }, [noteText, props.note, removeAttachment]);

    const onCancelClick = React.useCallback(() => {
        setNoteText(props.note);
        setRemoveAttachment(false);
        setOpen(false);
    }, [props.note]);

    const dispatch = useDispatch();

    const onSaveClick = React.useCallback(async () => {
        if (noteText) {
            if (props.taskRelation === TaskRelationEnum.Order) {
                await dispatch(updateOrderNote(props.correlationId, props.noteId, noteText, removeAttachment));
            } else if (props.eventType === ChangeEventType.TrainerNote || props.eventType === ChangeEventType.TrainerComplaints) {
                await dispatch(updateTrainerNote(props.correlationId, props.noteId, noteText, removeAttachment));
            } else {
                await dispatch(updateNoteToCorrelationId(props.correlationId, props.noteId, noteText, props.taskRelation, removeAttachment));
            }
            toast.success("Note updated");
            setOpen(false);
        }
        else {
            toast.error("A blank note cannot be saved");
        }
    }, [dispatch, noteText, props.correlationId, props.eventType, props.noteId, props.taskRelation, removeAttachment]);

    const onOpenButtonClick = React.useCallback(() => setOpen(true), []);

    return (<>
        {(isAdminApp && props.fileName) && (
            <Popup content={"Note contains attachment"} trigger={<Icon name="save" className="note-attachment" />} />
        )}
        <a className="update-note" onClick={onOpenButtonClick}>
            <u>Edit</u>
        </a>

        <Modal open={open}>
            <Modal.Header>
                How would you like to edit this note today?
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Input.Textarea
                        label="Note"
                        value={noteText}
                        onChange={onNoteChange}
                    />
                    {(isAdminApp && props.fileName) && (
                        <Checkbox
                            label={"Remove attachment"}
                            onChange={onToggleRemoveAttachment}
                            checked={removeAttachment}

                        />
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancelClick} className="cancel-action">
                    Cancel
                </Button>
                <Button
                    onClick={onSaveClick}
                    disabled={notModified}
                    positive
                    labelPosition="right"
                    icon="checkmark"
                    content="continue"
                />
            </Modal.Actions>
        </Modal>
    </>
    );
};
