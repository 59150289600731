import * as React from "react";
import moment from "moment";
import { Grid, PaginationProps } from "semantic-ui-react";
import { MonitoringHistoryTable } from "./MonitoringHistoryTable";
import { MonitoringHistoryApi } from "../../monitoringHistoryApi";
import { useDispatch, useSelector } from "react-redux";
import { push, State } from "redux-little-router";
import { Filters } from "@common/crud/trainer/components/monitoring/Filters";
import { Spinner } from "@common/global";
import { MonitoringReportHistory } from "../../model";
import { selectedEventInstanceListPath } from "@common/crud/eventInstance/selectors";
import { SortDirection, toRouterQuery } from "@common/model";
import { routerQuerySelector } from "@common/crud/common/selectors";
import { TTCPagination } from "@common/components/TTCPageination";

export const AllMonitoring: React.FC = () => {
    const [monitoringReports, setMonitoringReports] = React.useState<
        MonitoringReportHistory[]
    >([]);
    const [isLoaded, setLoaded] = React.useState<boolean>(false);
    const [pageCount, setPageCount] = React.useState<number>(0);

    const basePath = useSelector(selectedEventInstanceListPath(null));
    const initialSchemeQuery =
        useSelector((state: State) => state.router.query.scheme) ?? "";
    const initialFromDateQuery = useSelector(
        (state: State) => state.router.query.fromDate
    );
    const initialToDateQuery = useSelector(
        (state: State) => state.router.query.toDate
    );
    const initialTrainerId = useSelector(
        (state: State) => state.router.query.trainerId
    );
    const initialDeliveryType =
        useSelector((state: State) => state.router.query.deliveryType) ?? "0";
    const initialSortBy =
        useSelector((state: State) => state.router.query.sortBy) ??
        "courseDate";
    const initialSortDirection =
        useSelector((state: State) => state.router.query.sortDirection) ??
        "asc";
    const initialPage = useSelector((state: State) => state.router.query.page) ?? "1";
    const query = useSelector(routerQuerySelector);

    const dispatch = useDispatch();

    const onSearch = React.useCallback(
        async (
            scheme?: string,
            fromDate?: moment.Moment,
            toDate?: moment.Moment,
            trainerId?: string,
            deliveryType?: string
        ) => {
            dispatch(
                push({
                    pathname: undefined,
                    query: toRouterQuery({
                        scheme,
                        fromDate: fromDate?.toISOString(),
                        toDate: toDate?.toISOString(),
                        trainerId,
                        deliveryType,
                        sortBy: initialSortBy,
                        sortDirection: initialSortDirection,
                        page: initialPage,
                    }),
                })
            );
        },
        [dispatch, initialPage, initialSortBy, initialSortDirection]
    );

    const sortDispatch = React.useCallback(
        (property: keyof MonitoringReportHistory, direction: SortDirection) => {
            const currentQuery = {
                ...query,
                sortBy: property,
                sortDirection: direction,
            };
            dispatch(push({ pathname: undefined, query: currentQuery }));
        },
        [dispatch, query]
    );

    function onPageChange(_: any, { activePage }: PaginationProps) {
        const currentQuery = {
            ...query,
            page: activePage.toString()
        };
        dispatch(push({ pathname: undefined, query: currentQuery }));
    }

    const onClear = React.useCallback(() => {
        dispatch(push({ pathname: undefined, query: undefined }));
    }, [dispatch]);

    const fromDate = React.useMemo(() => initialFromDateQuery ? moment(initialFromDateQuery) : null, [initialFromDateQuery]);
    const toDate = React.useMemo(() => initialToDateQuery ? moment(initialToDateQuery) : null, [initialToDateQuery]);

    React.useEffect(() => {

        const getData = async (
            from: moment.Moment,
            to: moment.Moment,
            scheme?: string,
            trainerId?: string,
            deliveryType?: string,
            sortBy?: string,
            sortDirection?: string,
            page?: string
        ) => {
            const api = new MonitoringHistoryApi();
            const response = await api.getFilteredReportsForMonitor(
                scheme,
                from,
                to,
                trainerId,
                deliveryType,
                sortBy,
                sortDirection,
                page,
            );
            setMonitoringReports(response.reports);
            setPageCount(response.pageCount);
            setLoaded(true);
        };

        getData(
            fromDate,
            toDate,
            initialSchemeQuery,
            initialTrainerId,
            initialDeliveryType,
            initialSortBy,
            initialSortDirection,
            initialPage
        );
    }, [initialDeliveryType, initialSchemeQuery, initialTrainerId, initialSortBy, initialSortDirection, initialPage, fromDate, toDate]);

    const getReportDetailLink = React.useCallback(
        (id: string) => {
            return basePath + `/${id}/monitoring`;
        },
        [basePath]
    );

    return (
        <>
            <Grid>
                <Filters
                    search={onSearch}
                    useMonitorFilters={true}
                    initialFromDate={fromDate}
                    initialToDate={toDate}
                    initialDeliveryType={initialDeliveryType}
                    initialScheme={initialSchemeQuery}
                    initialTrainerId={initialTrainerId}
                    clear={onClear}
                />
                <TTCPagination
                    activePage={initialPage}
                    totalPages={pageCount}
                    onPageChange={onPageChange}
                />
            </Grid>
            <Spinner active={!isLoaded}>
                <MonitoringHistoryTable
                    reports={monitoringReports}
                    getReportDetailLink={getReportDetailLink}
                    initialFromDate={fromDate}
                    initialToDate={toDate}
                    sortDispatch={sortDispatch}
                />
            </Spinner>
        </>
    );
};
