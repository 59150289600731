import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ObjectLiteral, push } from "redux-little-router";
import { Area, Language, SearchOptions, YesNoAnyEnum } from "@common/crud/eventInstance/model";
import { Filters } from "@common/crud/eventInstance/components/Filters";
import { eventInstanceSearchOptionsSelector, eventInstancesSelector, groupedEventInstancesSelector } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "reauthorize";
import { TtcPlannerRole } from "@common/auth/model";
import { StringValues } from "@common/model";
import { AuthorizeProps } from "reauthorize/dist/Authorize";
import { ActionModal } from "./bulkActions/ActionModal";
import { loadEventInstancesAndGroups } from "../actions";
import { AppState } from "../model";
import { compare } from "@common/helpers/compare";
import { appSelector } from "@common/crud/common/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { AllCorporateItems } from "./AllCorporateItems";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

const PAGE_SIZE = 100;

interface CorporateEventInstanceListWithFiltersProps {
    area?: Area;
    workflowTypes?: WorkflowTypeEnum[];
    corporateOrganisationId?: string;
    initialWorkflowType?: WorkflowTypeEnum;
    hideBusinessDriver?: boolean;
}

const parseOptions = (options: SearchOptions) => {
    const parsedOptions: StringValues<SearchOptions> = {
        fromDate: options.fromDate?.format("YYYY-MM-DD") || undefined,
        toDate: options.toDate?.format("YYYY-MM-DD") || undefined,
        hasAvailableSeats: options.hasAvailableSeats?.toString(),
        requiresTrainers: options.requiresTrainers ? YesNoAnyEnum[options.requiresTrainers] : undefined,
        oneToOneOnly: options.oneToOneOnly?.toString(),
        eventType: options.eventType?.join(",") || undefined,
        forceId: options.forceId?.join(",") || undefined,
        venueId: options.venueId?.join(",") || undefined,
        getOnlyUnconfirmedByTrainer: options.getOnlyUnconfirmedByTrainer?.toString(),
        trainerId: options.trainerId,
        eventStatus: options.eventStatus?.join(",") || undefined,
        deliveryType: options.deliveryType,
        availableForOtherTrainers: options.availableForOtherTrainers ? YesNoAnyEnum[options.availableForOtherTrainers] : undefined,
        onlyIncludeCoursesMadeAvailableByTrainer: options.onlyIncludeCoursesMadeAvailableByTrainer?.toString(),
        maxPageSize: options.maxPageSize?.toString(),
        language: Language[options.language],
        daysOfWeek: options?.daysOfWeek?.join(",") || undefined,
        dayPeriod: options?.dayPeriod?.join(",") || undefined,
        filtersCleared: options?.filtersCleared?.toString(),
        hasWarnings: options?.hasWarnings?.toString(),
        corporateOrganisationIds: options?.corporateOrganisationIds?.join(",") || undefined,
        bookingType: options.bookingType,
        stateWorkflowType: options?.stateWorkflowType?.toString()
    };

    return parsedOptions;
};

export const CorporateEventInstanceListWithFilters: React.FC<CorporateEventInstanceListWithFiltersProps> = ({
    area,
    workflowTypes,
    corporateOrganisationId,
    initialWorkflowType,
    hideBusinessDriver,
}) => {
    const [selectedEventInstanceIds, setSelectedEventInstanceIds] = React.useState<string[]>([]);
    const [searchOptions, setSearchOptions] = React.useState<SearchOptions>({});
    const [initialLoad, setInitialLoad] = React.useState(true);

    const dispatch = useDispatch();

    const routerSearchOptions = useSelector((state: AppState) => eventInstanceSearchOptionsSelector(state, initialWorkflowType));
    const eventInstances = useSelector(eventInstancesSelector);
    const eventInstanceGroups = useSelector(groupedEventInstancesSelector);
    const app = useSelector(appSelector);
    const selectedEventInstances = eventInstances?.filter(ei =>
        selectedEventInstanceIds.includes(ei.id));

    React.useEffect(() => {
        // prevent dispatching the initial state so user can back track
        if (initialLoad) {
            setInitialLoad(false);
            return;
        }
        const query = parseOptions(routerSearchOptions);
        setQuery(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const truncatedOptions = {
            ...routerSearchOptions,
            eventType: routerSearchOptions.eventType === undefined ? [] : routerSearchOptions.eventType,
            corporateOrganisationIds: routerSearchOptions.corporateOrganisationIds === undefined ? [] : routerSearchOptions.corporateOrganisationIds
        };
        ObjectKeys(truncatedOptions).forEach(key => truncatedOptions[key] === undefined ? delete truncatedOptions[key] : {});
        const newSearchOptions = {
            ...truncatedOptions,
            workflowTypes: workflowTypes
                ? workflowTypes
                : [WorkflowTypeEnum.Any],
            corporateOrganisationIds: corporateOrganisationId
                ? [corporateOrganisationId]
                : truncatedOptions.corporateOrganisationIds };
        if (!compare(newSearchOptions, searchOptions)) {
            loadInstances(newSearchOptions);
            setSearchOptions(newSearchOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerSearchOptions, corporateOrganisationId]);

    const setQuery = React.useCallback((query: ObjectLiteral<string>) => dispatch(push({ pathname: undefined, query })), [dispatch]);
    const loadInstances = React.useCallback((options: SearchOptions) => dispatch(loadEventInstancesAndGroups({ options })), [dispatch]);

    const pageSize = React.useMemo(() => searchOptions.maxPageSize ? searchOptions.maxPageSize : PAGE_SIZE, [searchOptions]);

    const onEventInstanceSelection = React.useCallback((eventInstanceId: string) => {

        if (selectedEventInstanceIds?.includes(eventInstanceId)) {
            setSelectedEventInstanceIds(selectedEventInstanceIds.filter(x => x !== eventInstanceId));

        } else {
            setSelectedEventInstanceIds([...selectedEventInstanceIds, eventInstanceId]);
        }
    }, [selectedEventInstanceIds]);

    const onEventInstanceGroupSelect = React.useCallback((eventInstanceGroupId: string) => {
        const groupEventInstanceIds = eventInstanceGroups.find(g => g.id === eventInstanceGroupId)?.eventInstanceGroupItems?.map(e => e.eventInstanceId) || [];

        if (groupEventInstanceIds.some(ei => selectedEventInstanceIds.includes(ei))) {
            setSelectedEventInstanceIds(selectedEventInstanceIds.filter(x => !groupEventInstanceIds.includes(x)));
        }
        else {
            setSelectedEventInstanceIds([...selectedEventInstanceIds, ...groupEventInstanceIds]);
        }
    },[eventInstanceGroups, selectedEventInstanceIds]);

    const clearSelection = React.useCallback(() => {
        setSelectedEventInstanceIds([]);
    }, []);

    const onOptionsChanged = React.useCallback((options: SearchOptions) => {
        const query = parseOptions(options);
        clearSelection();
        setQuery(query);
    }, [clearSelection, setQuery]);

    const selectAll = React.useCallback(() => {
        if (selectedEventInstanceIds.length === eventInstances.length) {
            setSelectedEventInstanceIds([]);
        } else {
            if (eventInstances) {
                setSelectedEventInstanceIds(eventInstances?.map(x => x.id));
            }
        }
    }, [selectedEventInstanceIds.length, eventInstances]);

    const isOnsiteCourse =
        eventInstances.some(x => selectedEventInstanceIds.find(c => c === x.id)?.length > 0 &&
            x.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite);
    const someSelectedEventInstanceHasMonitor = selectedEventInstances.some(ei => ei.hasMonitor);
    const someSelectedEventInstanceHasAssessmentDue = selectedEventInstances.some(ei => ei.trainers?.some(t =>
        t.externalAssessmentDue) || ei.practicalTrainers?.some(pt => pt.externalAssessmentDue));

    const selectedEventInstanceGroups = eventInstanceGroups.filter(g =>
        g.eventInstanceGroupItems.some(e => selectedEventInstanceIds.includes(e.eventInstanceId))).map(g => g.id);

    return (
        <div>
            <Filters
                app={app}
                area={area}
                onOptionsChanged={onOptionsChanged}
                searchOptions={routerSearchOptions}
                sideMenuStyle={!!corporateOrganisationId}
                workflowTypes={workflowTypes}
                hideBusinessDriver={hideBusinessDriver}
            />
            <Grid.Row className={area === Area.AdminEventManagementEventInstance ? "slightly-wider" : ""}>
                <Grid.Column width={16}>
                    {eventInstances.length > pageSize &&
                    <p>Showing first {pageSize} courses; use the search box to filter.</p>
                    }
                    <AllCorporateItems
                        selectedEventInstances={selectedEventInstanceIds}
                        eventInstances={eventInstances}
                        onEventInstanceSelect={onEventInstanceSelection}
                        onEventInstanceGroupSelect={onEventInstanceGroupSelect}
                        selectAll={selectAll}
                        area={area}
                        selectedEventInstanceGroups={selectedEventInstanceGroups}
                        eventInstanceGroups={eventInstanceGroups}
                        pageSize={pageSize} />
                </Grid.Column>
            </Grid.Row>
            <AuthorizedGridColumn
                area={area}
                authorize={TtcPlannerRole}
                children={
                    <ActionModal
                        area={area}
                        selectedEventInstanceIds={selectedEventInstanceIds}
                        isOnsiteCourse={isOnsiteCourse}
                        clearSelection={clearSelection}
                        hasMonitor={someSelectedEventInstanceHasMonitor}
                        hasExternalAssessmentDue={someSelectedEventInstanceHasAssessmentDue}
                        searchOptions={searchOptions}
                    />}
            />
        </div>
    );
};

const AuthorizedGridColumn: React.FC<AuthorizeProps & { area: Area }> = ({ area, authorize, children }) => {
    return (
        <Authorize authorize={authorize}>
            <Grid.Row className={area === Area.AdminEventManagementEventInstance ? "slightly-wider" : ""}>
                <Grid.Column width={16}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Authorize>
    );
};
