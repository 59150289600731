import * as React from "react";
import moment from "moment";
import { Input } from "@neworbit/simpleui-input";
import { debounce } from "@neworbit/simpleui-utils";
import { DurationPicker } from "@common/crud/common";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { Grid, Icon, Label } from "semantic-ui-react";
import { EventTypePart, MultiDayTypeEnum } from "@common/crud/eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiTodayOrfutureDateValidator } from "@common/validation/futureDateValidator";
import { BankHoliday } from "@common/bankHolidays/model";

export interface EventTypePartEditProps {
    label: string;
    itemKey: string;
    eventTypePart: EventTypePart;
    updateSuggestedTime: (key: string, value: string, valid: boolean) => void;
    updateSuggestedDuration: (key: string, value: string, valid: boolean) => void;
    updateSuggestedRegistrationDuration: (key: string, value: string, valid: boolean) => void;
    groupItemStartDate?: moment.Moment;
    updateStartDates?:  ( value: Dictionary<moment.Moment>, valid: boolean) => void;
    numberOfDayParts?: number;
    updateSpecificStartDate?: (day: string, value: moment.Moment, valid: boolean) => void;
    firstStartDate?: moment.Moment;
    bankHolidays?: BankHoliday[];
    multiDayType?: MultiDayTypeEnum;
}

export const EventTypePartEdit: React.FC<EventTypePartEditProps>  = ({ label, itemKey, eventTypePart,
    updateSuggestedDuration, updateSuggestedRegistrationDuration, updateSuggestedTime, groupItemStartDate, updateStartDates, updateSpecificStartDate,
    numberOfDayParts, firstStartDate, bankHolidays, multiDayType }) => {

    const updateTime = React.useCallback((value: string, valid: boolean) => {
        updateSuggestedTime(itemKey, value, valid);
    }, [itemKey, updateSuggestedTime]);

    const updateStartDate = React.useCallback((value: moment.Moment, valid: boolean) => {
        if (value) {
            if (itemKey ==="1" && valid) {
                const entries = [];

                for (let i = 1; i<= numberOfDayParts; i++) {
                    const newValue = value.clone();
                    entries.push([i.toString(), newValue.add(i-1, multiDayType === MultiDayTypeEnum.Consecutive ? "days" : "weeks")]);
                }

                updateStartDates(Object.fromEntries(entries), valid);
            }
            else {
                updateSpecificStartDate(itemKey, value, valid);
            }
        }
    }, [itemKey, multiDayType, numberOfDayParts, updateSpecificStartDate, updateStartDates]);

    const updateDuration = React.useCallback((value: moment.Duration, valid: boolean) => {
        updateSuggestedDuration(itemKey, value.format("HH:mm", { trim: false }), valid);
    }, [itemKey, updateSuggestedDuration]);

    const updateRegistrationDuration = React.useCallback((value: moment.Duration, valid: boolean) => {
        updateSuggestedRegistrationDuration(itemKey, value.format("HH:mm", { trim: false }), valid);
    }, [itemKey, updateSuggestedRegistrationDuration]);

    const startTime = React.useMemo(() => eventTypePart.suggestedStartTime, [eventTypePart.suggestedStartTime]);

    const endTimeDisplay = React.useMemo(() => {
        let calculatedEndTime = startTime?.clone().add(eventTypePart.eventDuration);
        if (calculatedEndTime?.asHours() >= 24) {
            calculatedEndTime = calculatedEndTime.subtract(1, "day");
        }

        return calculatedEndTime?.format(DateFormat.Time, { trim: false });
    }, [eventTypePart.eventDuration, startTime]);

    return (
        <Grid container stackable className="nomargintop" key={itemKey}>
            <Grid.Row>
                <Grid.Column  >
                    <h4>{`Day ${itemKey}`}</h4>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} >
                    {groupItemStartDate && updateStartDates &&
                       <MuiDateField
                           value={groupItemStartDate}
                           label="Date"
                           onChange={updateStartDate}
                           required
                           validation={[muiTodayOrfutureDateValidator]}
                       />}
                    <Input.Time
                        value={startTime?.format(DateFormat.Time, { trim: false })}
                        label={label ?? "Set Start Time"}
                        showErrors={false}
                        onChange={debounce(updateTime, 1000)}
                        required
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <DurationPicker label="Set Duration" eventDuration={eventTypePart.eventDuration} showErrors={true}
                        onChange={updateDuration} showHeader={false} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <DurationPicker label="Set Registration Duration" eventDuration={eventTypePart.registrationDuration} showErrors={true}
                        onChange={updateRegistrationDuration} showHeader={false} />
                </Grid.Column>
            </Grid.Row>
            {groupItemStartDate &&
            <Label id="endtime">
                    Event Ends at: {endTimeDisplay}
            </Label>}
            {(bankHolidays?.some(h => h.date.isSame(firstStartDate.clone().add(parseInt(itemKey) * 7, "days"), "day"))
                    || (groupItemStartDate && bankHolidays?.some(h => h.date.isSame(groupItemStartDate, "days"), "day"))) &&
                <Grid.Row>
                    <Grid.Column>
                        <div className="ui yellow message">
                            <Icon name="warning sign" size="large" />Warning - date selected is a Bank Holiday
                        </div>
                    </Grid.Column>
                </Grid.Row>}
        </Grid>
    );
};
