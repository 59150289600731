import { Dispatch } from "redux";
import { loadAndTrack } from "redux-request-loading";
import * as actions from "./actionTypes";
import { DigitalFees, DigitalFeesSaveModel } from "./model";
import { Payload } from "@neworbit/redux-helpers";
import { DigitalFeeApi } from ".";

export type DigitalFeeAction =
    ({ type: actions.LOAD_DIGITAL_FEES_SUCCESS } & Payload<DigitalFees[]>)
    | ({ type: actions.SAVE_DIGITAL_FEES_SUCCESS } & Payload<DigitalFees>)
    | ({ type: actions.TOGGLE_DIFFERENT_RATES_SUCCESS } & Payload<{ forceId: number; enabled: boolean; newDifferentRateId?: string }>);

export const loadDigitalFeesSuccess = (payload: DigitalFees[]): DigitalFeeAction => ({
    payload,
    type: actions.LOAD_DIGITAL_FEES_SUCCESS
});

export const saveDigitalFeesSuccess = (payload: DigitalFees): DigitalFeeAction => ({
    payload,
    type: actions.SAVE_DIGITAL_FEES_SUCCESS
});

export const toggleDifferentRatesSuccess = (payload: { forceId: number; enabled: boolean; newDifferentRateId?: string }): DigitalFeeAction => ({
    payload,
    type: actions.TOGGLE_DIFFERENT_RATES_SUCCESS
});

export function loadAllDigitalFees() {
    return async (dispatch: Dispatch) => {
        const api = new DigitalFeeApi();
        const fees = await loadAndTrack(dispatch, "loadAllDigitalFees", api.getAll());

        dispatch(loadDigitalFeesSuccess(fees));
    };
}

export function toggleDifferentRates(forceId: number, enabled: boolean) {
    return async (dispatch: Dispatch) => {
        const api = new DigitalFeeApi();
        const newDifferentRateId = await loadAndTrack(dispatch, "toggleDifferentRate", api.toggleDifferentRates(forceId, enabled));
        dispatch(toggleDifferentRatesSuccess({ forceId, enabled, newDifferentRateId }));
    };
}

export function saveDigitalFees(model: DigitalFeesSaveModel) {
    return async (dispatch: Dispatch) => {
        const api = new DigitalFeeApi();
        const fees = await loadAndTrack(dispatch, "saveDigitalFees", api.saveDigitalFees(model));

        dispatch(saveDigitalFeesSuccess(fees));
    };
}
