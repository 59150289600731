import {
    QuestionSection
} from "@common/crud/eventInstance/components/monitoring/MonitoringQuestionnaireModel";
import { IntroductionSection } from "./IntroductionSection";

export const IntroductionSectionClassroom: QuestionSection = {
    ...IntroductionSection,
    subSections: [
        {
            ...IntroductionSection.subSections[0],
            criteria: [
                { id: "1", text: "Trainer and clients to introduce themselves." },
                { id: "2", text: "Explain what is going to happen and what is expected of clients.  " },
                { id: "3", text: "Set the right tone for the day. " },
                { id: "4", text: "Encourage clients to relax." }
            ]
        }
    ],
};

