import { toast } from "@common/toasts";
import * as React from "react";
import { Button, Confirm, ModalContentProps, SemanticFLOATS, SemanticICONS } from "semantic-ui-react";

interface ConfirmButtonProps {
    label?: string;
    icon?: SemanticICONS;
    basic?: boolean;
    onConfirm: () => void;
    header?: string;
    content?: React.ReactNode | ModalContentProps;
    disabled?: boolean;
    className?: string;
    fluid?: boolean;
    floated?: SemanticFLOATS;
    loading?: boolean;
    showToast?: boolean;
    toastText?: string;
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {

    const { children, label, icon, basic, header, content, disabled, className, fluid, floated, loading, onConfirm, showToast, toastText, size } = props;

    const [open, setOpen] = React.useState(false);

    function show() {
        setOpen(true);
    }

    function hide() {
        setOpen(false);
    }

    function onConfirmClicked() {
        if (showToast) {
            toast.info(toastText);
        }
        onConfirm();
        hide();
    }

    const cancelButtonProps = { content: "Cancel", className: "cancel-action" };
    return (
        <>
            <Button
                className={className}
                label={label}
                icon={icon}
                basic={basic}
                content={children}
                onClick={show}
                disabled={disabled}
                fluid={fluid}
                floated={floated}
                loading={loading}
            />
            <Confirm
                open={open}
                size={size}
                header={header}
                content={content}
                cancelButton={cancelButtonProps}
                onConfirm={onConfirmClicked}
                onCancel={hide}
            />
        </>
    );
};
