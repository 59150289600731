/* eslint-disable max-lines */
import * as React from "react";
import { Form, Message, Icon, Segment, Radio, Card, Button } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { debounce } from "lodash";
import { OrderApi } from "../orderApi";
import { Basket, BasketComparisonResult, CorporateEventInstanceResultRow, CorporateUsersByEmail, DetailsAndBillingModel,
    OrganisationAutocompleteOption,
    TaxReceiptType, paymentTypeOptions } from "../model";
import { DetailsAndBillingUserSelectModal } from "./DetailsAndBillingUserSelectModal";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-little-router";
import "./DetailsAndBilling.scss";
import { CustomerStatusEnum } from "@common/crud/alaskaNudgeTask/model";
import { Address } from "@common/crud/common";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { numberFormatter } from "@common/helpers/number-format";
import { toast } from "react-toastify";
import { BusinessLineType, businessLineTypeSelector, organisationIdSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { queryAdditionSelector, queryEnquiryEventInstanceIdSelector, routeCorporateUserIdSelector } from "@common/crud/corporateUser/selectors";
import { compareBaskets, prepareOrdersUrl } from "../helpers";
import { phoneNumberWithSpaces } from "@common/validation";
import { ExtendedTextInput } from "@common/components/ExtendedTextInput";
import { phoneNumberSpacesValidator } from "@common/validation/phoneNumber";
import { ValidationResultType } from "not-valid/bin/results";
import { basicRelatedOrganisationOptionsSelectorWithNone, organisationSelector } from "@common/crud/organisation/selectors";
import { loadRelatedOrganisationOptions } from "@common/crud/organisation/actions";
import { ExtendedDropdown } from "@common/components/ExtendedDropdown";
import { bookNowPayLater, loadPayment } from "../actions";
import { useSetBeforeGenesysAuthPage } from "@common/genesys";
import { RelatedOrganisationChangedModal } from "./RelatedOrganisationChangedModal";
import { PaymentType } from "@common/payments/model";
import { DropdownProps } from "react-day-picker";
import { ConfirmButton } from "@common/components";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { DetailsAndBillingCourseSummary } from "./DetailsAndBillingCourseSummary";
import { EmailApi } from "@common/email/emailApi";
import { doesPriceIncludeVat } from "@common/crud/organisation/model";

const checkEmail = async (
    value: string,
    businessLineType: BusinessLineType,
    setSearchingEmail: (searchingEmail: boolean) => void,
    setCorporateUsersByEmail: (corporateUsersByEmail: CorporateUsersByEmail) => void
) => {
    if (!value) {
        setCorporateUsersByEmail(undefined);
    } else {
        const api = new OrderApi();
        const response = await api.checkEmail(value, businessLineType);
        setCorporateUsersByEmail(response);
    }
    setSearchingEmail(false);
};

const checkInvoiceEmail = async (
    value: string,
    setSearchingInvoiceEmail: (searchingInvoiceEmail: boolean) => void,
    setInvoiceEmailError: (accountsInvoiceEmailError: string) => void
) => {
    if (!value) {
        setInvoiceEmailError(undefined);
    } else {
        const api = new EmailApi();
        const response = await api.checkEmail(value);
        setInvoiceEmailError(response.valid ? undefined : response.error);
    }
    setSearchingInvoiceEmail(false);
};

const debouncedCheckEmail = debounce(checkEmail, 2000);
const debouncedCheckInvoiceEmail = debounce(checkInvoiceEmail, 2000);

export const DetailsAndBilling: React.FC = () => {
    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);
    const queryAddition = useSelector(queryAdditionSelector);
    const enquiryEventInstanceId = useSelector(queryEnquiryEventInstanceIdSelector);

    const [basket, setBasket] = React.useState<Basket>(undefined);
    const [basketChangesInProgress, setBasketChangesInProgress] = React.useState<boolean>(false);
    const [basketComparisonResult, setBasketComparisonResult] = React.useState<BasketComparisonResult>(undefined);
    const [coursesFromIds, setCoursesFromIds] = React.useState<CorporateEventInstanceResultRow[]>([]);

    const [relatedOrganisationId, setRelatedOrganisationId] = React.useState<string>(undefined);

    const [showErrors, setShowErrors] = React.useState<boolean>(false);

    const [emailOptionsOpen, setEmailOptionsOpen] = React.useState<boolean>(false);
    const [emailOptions, setEmailOptions] = React.useState<string[]>([]);
    const [email, setEmail] = React.useState<string>("");
    const [emailHandled, setEmailHandled] = React.useState<boolean>(false);
    const [searchingEmail, setSearchingEmail] = React.useState<boolean>(false);
    const [corporateUsersByEmail, setCorporateUsersByEmail] = React.useState<CorporateUsersByEmail>(undefined);

    const [userId, setUserId] = React.useState<string>(undefined);
    const [organisationId, setOrganisationId] = React.useState<string>(undefined);
    const [organisationSearchValue, setOrganisationSearchValue] = React.useState<string>(undefined);
    const [userHasCpcOpenBookNowPayLater, setUserHasCpcOpenBookNowPayLater] = React.useState<boolean>(false);

    const [lockUser, setLockUser] = React.useState<boolean>(false);
    const [lockOrganisation, setLockOrganisation] = React.useState<boolean>(false);

    const [taxReceiptType, setTaxReceiptType] = React.useState<TaxReceiptType>(undefined);

    const [forename, setForename] = React.useState<string>(undefined);
    const [surname, setSurname] = React.useState<string>(undefined);

    const [organisationAutocompleteOptions, setOrganisationAutocompleteOptions] = React.useState<OrganisationAutocompleteOption[]>([]);
    const [organisation, setOrganisation] = React.useState<string>(undefined);

    const [telephone, setTelephone] = React.useState<string>(undefined);
    const [telephoneValid, setTelephoneValid] = React.useState<boolean>(undefined);

    const [address, setAddress] = React.useState<Address>(undefined);
    const [invoiceAddress, setInvoiceAddress] = React.useState<Address>(undefined);

    const [invoicePhoneNo, setInvoicePhoneNo] = React.useState<string>(undefined);
    const [invoicePhoneNoValid, setInvoicePhoneNoValid] = React.useState<boolean>(undefined);

    const [invoiceEmail, setInvoiceEmail] = React.useState<string>(undefined);
    const [searchingInvoiceEmail, setSearchingInvoiceEmail] = React.useState<boolean>(false);
    const [invoiceEmailError, setInvoiceEmailError] = React.useState<string>(undefined);

    const [nameOnTaxReceipt, setNameOnTaxReceipt] = React.useState<string>(undefined);

    const [paymentType, setPaymentType] = React.useState<PaymentType>(businessLineType === BusinessLineType.Corporate
        ? PaymentType.Card
        : PaymentType.FakePayment);

    const [invoiceReference, setInvoiceReference] = React.useState<string>(undefined);
    const [useDifferentAddressForInvoice, setUseDifferentAddressForInvoice] = React.useState<boolean>(false);

    const [upcomingNewsAndCoursesNewsletter, setUpcomingNewsAndCoursesNewsletter] = React.useState<boolean>(false);
    const [optConstructionDelegatesIntoCourseClosureComms, setOptConstructionDelegatesIntoCourseClosureComms] = React.useState<boolean>(false);

    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [autosave, setAutosave] = React.useState<boolean>(false);
    const [goingToPayment, setGoingToPayment] = React.useState<boolean>(false);

    const corporateOrganisationId = useSelector(organisationIdSelector);
    const corporateUserId = useSelector(routeCorporateUserIdSelector);
    const organisationOptions = useSelector(basicRelatedOrganisationOptionsSelectorWithNone);
    const appSettings = useSelector(appSettingsSelector);

    const dispatch = useDispatch();

    const currentOrganisation = useSelector(organisationSelector);
    const hasBookNowPayLater = React.useMemo(() => businessLineType === BusinessLineType.Construction
        || (appSettings?.featureSettings?.offlineBookNowPayLaterEnabled && (userHasCpcOpenBookNowPayLater
            || currentOrganisation?.corporateOrganisationData?.hasCpcOpenBookNowPayLater))
    , [appSettings, businessLineType, currentOrganisation, userHasCpcOpenBookNowPayLater]);

    useSetBeforeGenesysAuthPage();

    React.useEffect(() => {
        if (businessLineType === BusinessLineType.Corporate && organisationOptions.length === 1) {
            dispatch(loadRelatedOrganisationOptions());
        }
    }, [businessLineType, dispatch, organisationOptions.length]);

    React.useEffect(() => {
        if (corporateOrganisationId) {
            const fetchOrganisationUserEmails = async () => {
                const api = new OrderApi();
                const response = await api.getOrganisationUserEmails(corporateOrganisationId);
                setEmailOptions(response);
            };

            fetchOrganisationUserEmails();
        }
    }, [corporateOrganisationId]);

    React.useEffect(() => {
        if (!corporateOrganisationId) {
            const fetchOrganisations = async () => {
                const api = new OrderApi();
                const response = await api.getOrganisationsAutocompleteOptions(businessLineType);
                setOrganisationAutocompleteOptions(response);
            };

            fetchOrganisations();
        }
    }, [businessLineType, corporateOrganisationId]);

    const onAutoSave = React.useCallback(async () => {
        if (isDirty && !goingToPayment) {
            toast.info("Autosaving progress");
            const api = new OrderApi();
            const savedBasket = await api.saveDetailsAndBilling(
                businessLineType,
                basket.id,
                {
                    id: userId,
                    forename,
                    surname,
                    email,
                    telephone: telephoneValid ? telephone : undefined,
                    upcomingNewsAndCoursesNewsletter,
                    optConstructionDelegatesIntoCourseClosureComms
                }, {
                    id: organisationId,
                    name: organisation,
                    taxReceiptType,
                    billingAddress: address
                }, {
                    nameOnTaxReceipt,
                    taxReceiptType,
                    hasCpcOpenBookNowPayLater: userHasCpcOpenBookNowPayLater
                });

            if (!savedBasket?.id) {
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                return;
            }

            setIsDirty(false);
        }
    }, [address, email, forename, isDirty, nameOnTaxReceipt, organisation, organisationId, surname, taxReceiptType, telephone,
        telephoneValid, upcomingNewsAndCoursesNewsletter, userId, goingToPayment, userHasCpcOpenBookNowPayLater, basket, corporateOrganisationId,
        corporateUserId, dispatch, businessLineType, eventManagementPath, optConstructionDelegatesIntoCourseClosureComms]);

    React.useEffect(() => {
        if (autosave && isDirty) {
            onAutoSave();
            setAutosave(false);
        }
    }, [autosave, isDirty, onAutoSave]);

    React.useEffect(() => {
        const autosaveInterval = setInterval(() => setAutosave(true) , 10000);
        return () => {
            setAutosave(false);
            clearInterval(autosaveInterval);
        };
    }, []);

    const handleRelatedOrganisationChange = React.useCallback(async (value: string) => {
        if (basket?.id && relatedOrganisationId !== value) {
            const api = new OrderApi();
            const updatedBasket = await api.changeBasketRelatedOrganisation(businessLineType, basket.id, value);

            if (!updatedBasket?.id) {
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                return;
            }

            const comparisonResult = compareBaskets(basket, updatedBasket);

            const oldTotalSeats = basket.items ? Object.values(basket.items).reduce((total, item) => total + item.numberOfSeats, 0) : 0;
            const newTotalSeats = updatedBasket.items ? Object.values(updatedBasket.items).reduce((total, item) => total + item.numberOfSeats, 0) : 0;
            if (oldTotalSeats !== newTotalSeats) {
                toast.warning("Some seats were lost after reservation updated. Please review basket for further action");
            }

            setBasket(updatedBasket);
            setBasketComparisonResult(comparisonResult);
            setRelatedOrganisationId(updatedBasket?.relatedOrganisationId);
        }
    }, [basket, corporateOrganisationId, corporateUserId, dispatch, relatedOrganisationId, businessLineType, eventManagementPath]);

    React.useEffect(() => {
        const fetchData = async () => {
            const api = new OrderApi();

            const existingBasket = await api.getBasket(businessLineType, corporateOrganisationId, corporateUserId);
            setBasket(existingBasket);
            setRelatedOrganisationId(existingBasket?.relatedOrganisationId);

            if (corporateOrganisationId && existingBasket?.relatedOrganisationId !== corporateOrganisationId) {
                handleRelatedOrganisationChange(corporateOrganisationId);
            }

            if (existingBasket.orderCorporateUser) {
                setUserId(existingBasket.orderCorporateUser.id);
                setForename(existingBasket.orderCorporateUser.forename);
                setSurname(existingBasket.orderCorporateUser.surname);
                setEmail(existingBasket.orderCorporateUser.email);
                setEmailHandled(!!existingBasket.orderCorporateUser.email);
                if (existingBasket.orderCorporateUser.telephone) {
                    setTelephone(existingBasket.orderCorporateUser.telephone);
                    const telephoneValidationResult = phoneNumberSpacesValidator(existingBasket.orderCorporateUser.telephone);
                    setTelephoneValid(telephoneValidationResult.type === ValidationResultType.Pass);
                }

                setUpcomingNewsAndCoursesNewsletter(existingBasket.orderCorporateUser.upcomingNewsAndCoursesNewsletter);
                setOptConstructionDelegatesIntoCourseClosureComms(existingBasket.orderCorporateUser.optConstructionDelegatesIntoCourseClosureComms);
                setLockUser(existingBasket.lockUser);
            }

            if (existingBasket.orderCorporateOrganisation) {
                setOrganisationId(existingBasket.orderCorporateOrganisation.id);
                setOrganisation(existingBasket.orderCorporateOrganisation.name);
                if (existingBasket.orderCorporateOrganisation.taxReceiptType?.toString() === "1") {
                    setTaxReceiptType("Individual");
                    if (existingBasket.orderCorporateUser) {
                        setNameOnTaxReceipt((existingBasket.orderCorporateUser.forename + " " + existingBasket.orderCorporateUser.surname).trim());
                    }
                }
                else if (existingBasket.orderCorporateOrganisation.taxReceiptType?.toString() === "2") {
                    setTaxReceiptType("Business");
                    setNameOnTaxReceipt(existingBasket.orderCorporateOrganisation.name);
                }

                setAddress(existingBasket.orderCorporateOrganisation.billingAddress);
                setLockOrganisation(existingBasket.lockOrganisation);
            }

            if (existingBasket.orderTaxReceiptInformation) {
                if (existingBasket.orderTaxReceiptInformation.nameOnTaxReceipt) {
                    setNameOnTaxReceipt(existingBasket.orderTaxReceiptInformation.nameOnTaxReceipt);
                }
                setUserHasCpcOpenBookNowPayLater(existingBasket.orderTaxReceiptInformation.hasCpcOpenBookNowPayLater || false);
            }
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corporateOrganisationId, corporateUserId]);

    React.useEffect(() => {
        const fetchData = async () => {
            const orderApi = new OrderApi();
            const newCoursesFromIds =
                await orderApi.getBookedCoursesFromIds(businessLineType, Object.values(basket.items).map(x => x.eventInstanceId), relatedOrganisationId);
            setCoursesFromIds(newCoursesFromIds);
        };

        if (basket && basket.items && Object.values(basket.items).length > 0) {
            fetchData();
        }
    }, [basket, relatedOrganisationId, businessLineType]);

    React.useEffect(() => {
        if (basket?.adminCoursesChosen === false) {
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders/create", corporateOrganisationId, corporateUserId)));
        }
    }, [basket?.adminCoursesChosen, corporateOrganisationId, corporateUserId, dispatch, eventManagementPath]);

    React.useEffect(() => {
        if (organisationAutocompleteOptions?.some(o => o.name === organisation)) {
            setTaxReceiptType("Business");
        }
    }, [organisation, organisationAutocompleteOptions]);

    const onUserSelected = React.useCallback((user: DetailsAndBillingModel) => {
        if (user?.userId) {
            setEmailHandled(true);

            setUserId(user.userId);
            setOrganisationId(user.organisationId);
            setUserHasCpcOpenBookNowPayLater(user.hasCpcOpenBookNowPayLater);

            const isIndividualDriver = user.customerStatus === CustomerStatusEnum.IndividualDriver;
            setForename(user.forename);
            setSurname(user.surname);
            setTaxReceiptType(isIndividualDriver
                ? "Individual"
                : "Business");
            setOrganisation(user.organisation);
            if (user.telephone) {
                setTelephone(user.telephone);
                const telephoneValidationResult = phoneNumberSpacesValidator(user.telephone);
                setTelephoneValid(telephoneValidationResult.type === ValidationResultType.Pass);
            }
            setAddress({
                addressLine1: user.addressLine1,
                addressLine2: user.addressLine2,
                addressLine3: user.addressLine3,
                postalCode: user.postalCode,
                city: user.city,
            });
            setInvoiceAddress({
                addressLine1: user.invoiceAddressLine1,
                addressLine2: user.invoiceAddressLine2,
                addressLine3: user.invoiceAddressLine3,
                postalCode: user.invoicePostalCode,
                city: user.invoiceCity,
            });
            setNameOnTaxReceipt(isIndividualDriver
                ? (user.forename+ " " + user.surname).trim()
                : user.organisation);
            setUpcomingNewsAndCoursesNewsletter(user.upcomingNewsAndCoursesNewsletter);
        } else {
            setCorporateUsersByEmail(undefined);
            setEmail("");
        }

        setIsDirty(true);
    }, []);

    const handleCorporateUsersByEmail = React.useCallback((model: CorporateUsersByEmail) => {
        setEmailOptionsOpen(false);
        if (model?.emailCheckResult?.valid === true && (model?.users?.length || 0) === 0) {
            setEmailHandled(true);
        } else if (model?.emailCheckResult?.valid === true && (model?.users?.length || 0) === 1 && emailOptions.some(o => o === model.users[0].email)) {
            onUserSelected(model.users[0]);
        } else {
            setCorporateUsersByEmail(model);
            setIsDirty(true);
        }
    }, [emailOptions, onUserSelected]);

    const onEmailFocused = React.useCallback(() => {
        setEmailOptionsOpen(true);
    }, []);

    const onEmailBlurred = React.useCallback(() => {
        setEmailOptionsOpen(false);
    }, []);

    const onEmailChanged = React.useCallback((event: React.ChangeEvent, value: any) => {
        if (event?.preventDefault) {
            event.preventDefault();
        }
        setEmailOptionsOpen(!corporateUsersByEmail);
        setSearchingEmail(true);
        if (email !== value) {
            setCorporateUsersByEmail(undefined);
            setEmailHandled(false);
            setEmail(value);
            debouncedCheckEmail(value, businessLineType, setSearchingEmail, handleCorporateUsersByEmail);
        } else {
            setSearchingEmail(false);
        }

        setIsDirty(true);
    }, [corporateUsersByEmail, email, businessLineType, handleCorporateUsersByEmail]);

    const onForenameChanged = React.useCallback((value: string) => {
        if (taxReceiptType === "Individual") {
            const nameValue = ((value || "") + " " + (surname ?? "")).trim();
            setNameOnTaxReceipt(nameValue);
        }
        setForename(value);
        setIsDirty(true);
    }, [surname, taxReceiptType]);

    const onSurnameChanged = React.useCallback((value: string) => {
        if (taxReceiptType === "Individual") {
            const nameValue = ((forename || "") + " " + (value ?? "")).trim();
            setNameOnTaxReceipt(nameValue);
        }
        setSurname(value);
        setIsDirty(true);
    }, [forename, taxReceiptType]);

    const onAddressChanged = React.useCallback((value: Address) => {
        if (value.addressLine1 === undefined && value.addressLine2 === undefined && value.addressLine3 === undefined
            && value.postalCode === "" && value.city === undefined) {
            return;
        }
        setAddress(value);
        setIsDirty(true);
    }, [setAddress]);

    const onInvoiceAddressChanged = React.useCallback((value: Address) => {
        if (value.addressLine1 === undefined && value.addressLine2 === undefined && value.addressLine3 === undefined
            && value.postalCode === "" && value.city === undefined) {
            return;
        }
        setInvoiceAddress(value);
        setIsDirty(true);
    }, [setInvoiceAddress]);

    const onInvoiceReferenceChanged = React.useCallback((value: string) => {
        setInvoiceReference(value);
        setIsDirty(true);
    }, []);

    const onInvoicePhoneNoChanged = React.useCallback((value: string, valid: boolean) => {
        setInvoicePhoneNo(value);
        setInvoicePhoneNoValid(valid);
        setIsDirty(true);
    }, []);

    const onInvoiceEmailChanged = React.useCallback((value: string) => {
        setSearchingInvoiceEmail(true);
        if (email !== value) {
            setInvoiceEmail(value);
            debouncedCheckInvoiceEmail(value, setSearchingInvoiceEmail, setInvoiceEmailError);
        } else {
            setSearchingInvoiceEmail(false);
        }

        setIsDirty(true);
    }, [email]);

    const onOrganisationChanged = React.useCallback((event: React.ChangeEvent, value: any) => {
        if (event?.preventDefault) {
            event.preventDefault();
        } else {
            setOrganisationSearchValue(value);
        }

        if (organisationAutocompleteOptions?.some(o => `${o.name} (${o.address?.postalCode})` === value)) {
            // Event firing from text field change, after something was already selected from autocomplete options
            // Should not be handled or will create duplicate organisation with postal code added at the end of organisation name
            return;
        }

        if (taxReceiptType === "Business") {
            setNameOnTaxReceipt(value || "");
        }
        setOrganisation(value);

        const matchingAutocompleteOption = organisationAutocompleteOptions?.find(o => o.name === value);
        if (matchingAutocompleteOption) {
            onAddressChanged(matchingAutocompleteOption.address);
        }

        setIsDirty(true);
    }, [onAddressChanged, organisationAutocompleteOptions, taxReceiptType]);

    const onTelephoneChanged = React.useCallback((value: string, valid: boolean) => {
        setTelephone(value);
        setTelephoneValid(valid);
        setIsDirty(true);
    }, []);

    const onTaxReceiptTypeChanged = React.useCallback((_, { value }) => {
        if (value === "Individual") {
            setOrganisation("");
            let nameValue = (forename || "");
            if (nameValue) {
                nameValue += " ";
            }
            nameValue += (surname || "");
            if (!nameOnTaxReceipt || nameOnTaxReceipt === organisation) {
                setNameOnTaxReceipt(nameValue.trim());
            }
        }

        if (value === "Business") {
            setNameOnTaxReceipt(organisation);
        }

        setTaxReceiptType(value);
        setIsDirty(true);
    }, [forename, nameOnTaxReceipt, organisation, surname]);

    const onPaymentTypeOptionChange = React.useCallback((_: any, option: DropdownProps) => {
        setPaymentType(+option.value);
        setIsDirty(true);
    }, []);

    const onUpcomingNewsAndCoursesNewsletterChanged = React.useCallback((checked: boolean) => {
        setUpcomingNewsAndCoursesNewsletter(checked);
        setIsDirty(true);
    }, [setUpcomingNewsAndCoursesNewsletter]);

    const onOptConstructionDelegatesIntoCourseClosureCommsChanged = React.useCallback((checked: boolean) => {
        setOptConstructionDelegatesIntoCourseClosureComms(checked);
        setIsDirty(true);
    }, [setOptConstructionDelegatesIntoCourseClosureComms]);

    const goBackToCourses = React.useCallback(async () => {
        const orderApi = new OrderApi();

        const corporateBasket = await orderApi.goBackToCourses(
            businessLineType,
            basket.id,
            {
                id: userId,
                forename,
                surname,
                email,
                telephone: telephoneValid ? telephone : undefined,
                upcomingNewsAndCoursesNewsletter,
                optConstructionDelegatesIntoCourseClosureComms
            }, {
                id: organisationId,
                name: organisation,
                taxReceiptType,
                billingAddress: address
            }, {
                nameOnTaxReceipt,
                taxReceiptType,
                hasCpcOpenBookNowPayLater: userHasCpcOpenBookNowPayLater
            });

        if (!corporateBasket?.id) {
            toast.error("Basket no longer exists");
            dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
            return;
        }

        setBasket(corporateBasket);
    }, [address, email, forename, nameOnTaxReceipt, organisation, organisationId, surname, taxReceiptType, telephone,
        upcomingNewsAndCoursesNewsletter, userId, telephoneValid, userHasCpcOpenBookNowPayLater, basket, corporateOrganisationId,
        corporateUserId, dispatch, businessLineType, eventManagementPath, optConstructionDelegatesIntoCourseClosureComms]);

    const goBackToEnquiries = React.useCallback(async () => {
        dispatch(push(`${eventManagementPath}/eventInstances/${enquiryEventInstanceId}/enquiries`));
    }, [dispatch, enquiryEventInstanceId, eventManagementPath]);

    const handleSeatsChangeForCourse = React.useCallback(async (offeredCourseId: string, numberOfSeats: number, add: boolean) => {
        setBasketChangesInProgress(true);
        try {
            const seatsForOfferedCourse = basket?.items
                ? Object.values(basket.items).find(x => x.eventInstanceId === offeredCourseId)?.numberOfSeats ?? 0
                : 0;
            const orderApi = new OrderApi();
            const changedBasket = await orderApi.updateBasketItem(businessLineType, basket.id, offeredCourseId, numberOfSeats, add);

            if (!changedBasket?.id) {
                toast.error("Basket no longer exists");
                dispatch(push(prepareOrdersUrl(eventManagementPath, "orders", corporateOrganisationId, corporateUserId)));
                return;
            }

            setBasket(changedBasket);
            const seatsForChangedBasketOfferedCourse = Object.values(changedBasket.items).find(x => x.eventInstanceId === offeredCourseId)?.numberOfSeats ?? 0;

            if (add
                ? seatsForChangedBasketOfferedCourse === seatsForOfferedCourse + numberOfSeats
                : !Object.values(changedBasket.items).some(x => x.eventInstanceId === offeredCourseId)) {
                const eventInstanceAlreadyInBasket = basket && basket.items && Object.values(basket?.items).some(x => x.eventInstanceId === offeredCourseId);

                toast.success(add
                    ? numberOfSeats > 0
                        ? `${numberOfSeats} place added to basket`
                        : `${Math.abs(numberOfSeats)} place removed from basket`
                    : numberOfSeats === 0
                        ? "Course removed from basket"
                        : eventInstanceAlreadyInBasket
                            ? `Course in basket updated to ${numberOfSeats} places`
                            : `Course added to basket with ${numberOfSeats} places`);
            } else {
                toast.error("Not possible to make that reservation, please reload offered courses");
            }

            const seatsReserved = Object.values(changedBasket.items)?.map(x => x.numberOfSeats)?.reduce((a, b) => a + b, 0) ?? 0;
            if (seatsReserved === 0) {
                goBackToCourses();
            }
        } finally {
            setBasketChangesInProgress(false);
        }
    }, [basket, goBackToCourses, corporateOrganisationId, corporateUserId, dispatch, businessLineType, eventManagementPath]);

    const handleSubmit = React.useCallback(async () => {
        setShowErrors(true);

        if (useDifferentAddressForInvoice && (!invoiceAddress?.addressLine1 || !invoiceAddress?.city || !invoiceAddress?.postalCode)
        ) {
            toast.error("Please fill in all required fields");
            return;
        }

        if (
            !!email &&
            !!taxReceiptType &&
            !!forename &&
            !!surname &&
            (taxReceiptType === "Individual" || !!organisation) &&
            !!address?.addressLine1 &&
            !!address?.city &&
            !!address?.postalCode &&
            !!nameOnTaxReceipt &&
            (!telephone || telephoneValid) &&
            (paymentType !== PaymentType.FakePayment || businessLineType === BusinessLineType.Construction || !!invoiceReference) &&
            (businessLineType !== BusinessLineType.Construction || paymentType !== PaymentType.FakePayment || (!!invoiceEmail && !invoiceEmailError)) &&
            (paymentType !== PaymentType.FakePayment || !invoicePhoneNo || invoicePhoneNoValid)
        ) {
            setGoingToPayment(true);
            try {
                const orderApi = new OrderApi();
                const changeBasketStatusResult =
                    await orderApi.refreshBasketBeforePayment(
                        businessLineType,
                        basket.id,
                        {
                            id: userId,
                            forename,
                            surname,
                            email,
                            telephone: telephoneValid ? telephone : undefined,
                            upcomingNewsAndCoursesNewsletter,
                            optConstructionDelegatesIntoCourseClosureComms,
                        },
                        {
                            id: organisationId,
                            name: organisation,
                            taxReceiptType,
                            billingAddress: address
                        },
                        {
                            nameOnTaxReceipt,
                            taxReceiptType,
                        }
                    );

                if (!changeBasketStatusResult?.corporateBasket?.id) {
                    toast.error("Basket no longer exists");
                    dispatch(
                        push(
                            prepareOrdersUrl(
                                eventManagementPath,
                                "orders",
                                corporateOrganisationId,
                                corporateUserId
                            )
                        )
                    );
                    return;
                }

                if (changeBasketStatusResult.error) {
                    toast.error(changeBasketStatusResult.error);
                }

                setBasket(changeBasketStatusResult.corporateBasket);

                if (
                    changeBasketStatusResult.corporateBasket
                        ?.adminCoursesChosen === false
                ) {
                    dispatch(
                        push(
                            prepareOrdersUrl(
                                eventManagementPath,
                                "orders/createBasket",
                                corporateOrganisationId,
                                corporateUserId
                            )
                        )
                    );
                } else if (!changeBasketStatusResult.error) {
                    if (paymentType === PaymentType.Card) {
                        dispatch(
                            loadPayment(
                                businessLineType,
                                basket.id,
                                corporateOrganisationId,
                                corporateUserId,
                                queryAddition,
                                enquiryEventInstanceId
                            )
                        );
                    } else {
                        dispatch(
                            bookNowPayLater(
                                businessLineType,
                                basket.id,
                                changeBasketStatusResult.corporateBasket.total,
                                invoiceReference,
                                invoiceEmail,
                                invoicePhoneNo,
                                useDifferentAddressForInvoice ? invoiceAddress : undefined,
                                corporateOrganisationId,
                                corporateUserId,
                                queryAddition,
                                enquiryEventInstanceId
                            )
                        );
                    }
                }
            } finally {
                setGoingToPayment(false);
            }
        } else {
            toast.error("Please fill in all required fields");
        }
    }, [useDifferentAddressForInvoice,
        invoiceAddress,
        email,
        taxReceiptType,
        forename,
        surname,
        organisation,
        address,
        nameOnTaxReceipt,
        telephone,
        telephoneValid,
        paymentType,
        invoiceReference,
        invoiceEmail,
        invoiceEmailError,
        invoicePhoneNo,
        invoicePhoneNoValid,
        businessLineType,
        basket,
        userId,
        upcomingNewsAndCoursesNewsletter,
        optConstructionDelegatesIntoCourseClosureComms,
        organisationId,
        dispatch,
        eventManagementPath,
        corporateOrganisationId,
        corporateUserId,
        queryAddition]);

    const detailsAndBillingUserSelectModalOpen = React.useMemo(() =>
        corporateUsersByEmail?.emailCheckResult?.valid === true
        && corporateUsersByEmail?.users?.length > 0
        && !emailHandled
    , [corporateUsersByEmail, emailHandled]);

    const closeRelatedOrganisationChangedModal = React.useCallback(() => setBasketComparisonResult(undefined), []);

    const filterOptions = React.useCallback((options: string[], { inputValue }) => {
        return options.filter(o => o.toLowerCase().includes((inputValue || organisationSearchValue || "").toLowerCase())).slice(0, 10);
    }, [organisationSearchValue]);

    const getEmailOptionLabel = React.useCallback((option: any) => option ? option.toString() : "", []);
    const getEmailOptionSelected = React.useCallback((option: any, value: any) => option === value, []);
    const onChangeEmailInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onEmailChanged(undefined, event.target.value);
    }, [onEmailChanged]);
    const renderEmailInput = React.useCallback((params: any) => <TextField {...params} onChange={onChangeEmailInput} />, [onChangeEmailInput]);

    const organisationOptionValues = React.useMemo(() => organisationAutocompleteOptions.map(x => x.name), [organisationAutocompleteOptions]);
    const getOrganisationOptionLabel = React.useCallback((option: any) => {
        const stringOption = option?.toString() || "";
        const postalCode = organisationAutocompleteOptions.find(x => x.name === stringOption)?.address.postalCode;
        return postalCode ? `${stringOption} (${postalCode})` : stringOption;
    }, [organisationAutocompleteOptions]);
    const getOrganisationOptionSelected = React.useCallback((option: any, value: any) => option === value, []);
    const onChangeOrganisationInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onOrganisationChanged(undefined, event.target.value);
    }, [onOrganisationChanged]);
    const renderOrganisationInput = React.useCallback((params: any) =>
        <TextField {...params} onChange={onChangeOrganisationInput} />, [onChangeOrganisationInput]);

    const individualLabel = React.useMemo(() => businessLineType === BusinessLineType.Corporate ? "Individual Driver" : "Individual", [businessLineType]);

    const priceIncludesVat = React.useMemo(() => doesPriceIncludeVat(+businessLineType), [businessLineType]);

    const vatDifference = React.useMemo(() => (basket?.total || 0) - (basket?.totalExcludingVat || 0), [basket]);

    return (
        <>
            <DetailsAndBillingUserSelectModal
                open={detailsAndBillingUserSelectModalOpen}
                corporateUsersByEmail={corporateUsersByEmail}
                corporateOrganisationId={corporateOrganisationId}
                selectCorporateUser={onUserSelected}
            />
            <RelatedOrganisationChangedModal
                basketComparisonResult={basketComparisonResult}
                close={closeRelatedOrganisationChangedModal}
            />
            <Form>
                {businessLineType === BusinessLineType.Corporate && (
                    <div className="full-width related-organisation">
                        <Card fluid>
                            <Card.Content header="Related Organisation" />
                            <Card.Content>
                                <ExtendedDropdown
                                    value={relatedOrganisationId}
                                    options={organisationOptions}
                                    onChange={handleRelatedOrganisationChange}
                                    dynamicOptions
                                    search
                                    disabled={!!corporateOrganisationId}
                                />
                            </Card.Content>
                        </Card>
                    </div>
                )}
                <div className="detailsAndBillingPage">
                    <Card className="detailsAndBillingForm">
                        <Card.Content header="Your details" />
                        <Card.Content>
                            <div className="field-wrapper">
                                <div className="field">
                                    <label>
                                        Email<span className="required-asterisk"> *</span>
                                    </label>
                                    <div className="ui input">
                                        <Autocomplete
                                            value={email}
                                            open={emailOptionsOpen}
                                            onFocus={onEmailFocused}
                                            onBlur={onEmailBlurred}
                                            fullWidth
                                            freeSolo
                                            autoSelect
                                            options={emailOptions}
                                            getOptionLabel={getEmailOptionLabel}
                                            getOptionSelected={getEmailOptionSelected}
                                            renderInput={renderEmailInput}
                                            onChange={onEmailChanged}
                                            disabled={lockUser}
                                            disableClearable
                                            filterOptions={filterOptions}
                                        />
                                    </div>
                                </div>
                                {(showErrors && !email) &&
                                        <div className="ui red pointing above basic label"><p>This field is required</p></div>}
                            </div>
                            {searchingEmail &&
                                <Message as={Segment} className="cancel-action">
                                    <Icon loading name='spinner' />
                                    Searching for existing users with that email...
                                </Message>
                            }
                            {corporateUsersByEmail?.emailCheckResult?.valid === false && (
                                <Message as={Segment} className="cancel-action">
                                    <Icon name="warning sign" />
                                    {corporateUsersByEmail.emailCheckResult.error}
                                </Message>
                            )}
                            {(corporateUsersByEmail?.emailCheckResult?.valid === true && ((corporateUsersByEmail?.users?.length || 0) === 0)) && (
                                <Message as={Segment} className="cancel-action">
                                    <Icon name="info circle" />
                                    Email not found in the system. Please fill in the details below.
                                </Message>
                            )}
                            <div className="taxReceiptField">
                                <Form.Field>
                                    <label>Customer type:</label>
                                </Form.Field>
                                <div className="taxReceiptChoicesWithErrorRow">
                                    <div className="taxReceiptChoicesRow">
                                        <Form.Field>
                                            <Radio
                                                label={individualLabel}
                                                name="taxReceiptType"
                                                disabled={!emailHandled || lockOrganisation}
                                                value={"Individual"}
                                                checked={taxReceiptType === "Individual"}
                                                onChange={onTaxReceiptTypeChanged}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Radio
                                                label="Company"
                                                name="taxReceiptType"
                                                disabled={!emailHandled || lockOrganisation}
                                                value={"Business"}
                                                checked={taxReceiptType === "Business"}
                                                onChange={onTaxReceiptTypeChanged}
                                            />
                                        </Form.Field>
                                    </div>
                                    {showErrors && taxReceiptType !== "Individual" && taxReceiptType !== "Business"
                                            && <div className="ui red pointing above basic label custom-error"><p>This field is required</p></div>}
                                </div>
                            </div>
                            <div className="nameRow">
                                <ExtendedTextInput
                                    value={forename}
                                    label="Forename"
                                    required
                                    showErrors={showErrors}
                                    onChange={onForenameChanged}
                                    disabled={!emailHandled || lockUser}
                                />
                                <ExtendedTextInput
                                    value={surname}
                                    label="Surname"
                                    required
                                    showErrors={showErrors}
                                    onChange={onSurnameChanged}
                                    disabled={!emailHandled || lockUser}
                                />
                            </div>
                            <div className="companyAndContactRow">
                                <div className="field-wrapper">
                                    <div className="field">
                                        <label>
                                            Organisation{taxReceiptType === "Business" && <span className="required-asterisk"> *</span>}
                                        </label>
                                        <div className={(!emailHandled || taxReceiptType === "Individual" || lockOrganisation)
                                            ? "disabled-opacity ui input"
                                            : "ui input"}
                                        >
                                            <Autocomplete
                                                value={organisation}
                                                key={`${taxReceiptType}-organisation`}
                                                fullWidth
                                                freeSolo
                                                options={organisationOptionValues}
                                                getOptionLabel={getOrganisationOptionLabel}
                                                getOptionSelected={getOrganisationOptionSelected}
                                                renderInput={renderOrganisationInput}
                                                onChange={onOrganisationChanged}
                                                disabled={!emailHandled || taxReceiptType === "Individual" || lockOrganisation}
                                                disableClearable
                                                filterOptions={filterOptions}
                                            />
                                        </div>
                                    </div>
                                    {(showErrors && taxReceiptType === "Business" && !organisation) &&
                                        <div className="ui red pointing above basic label"><p>This field is required</p></div>}
                                </div>
                                <ExtendedTextInput
                                    value={telephone}
                                    label="Telephone"
                                    validation={phoneNumberWithSpaces()}
                                    showErrors={showErrors}
                                    onChange={onTelephoneChanged}
                                    disabled={!emailHandled || lockUser}
                                />
                            </div>
                            <div className="header">Billing address</div>
                            <AddressLookup
                                address={address}
                                required
                                showErrors={showErrors}
                                withoutTitle
                                disabled={!emailHandled}
                                onChange={onAddressChanged}
                            />
                            {hasBookNowPayLater && (
                                <>
                                    <div className="header">Please choose the payment type</div>
                                    <Form.Dropdown
                                        selection
                                        placeholder="Payment type"
                                        disabled={!emailHandled}
                                        value={paymentType}
                                        options={paymentTypeOptions}
                                        onChange={onPaymentTypeOptionChange}
                                    />
                                    {paymentType === PaymentType.FakePayment &&
                                        <>
                                            <ExtendedTextInput
                                                value={invoiceReference}
                                                label="Invoice reference"
                                                disabled={!emailHandled}
                                                onChange={onInvoiceReferenceChanged}
                                                required={businessLineType === BusinessLineType.Corporate}
                                                showErrors={showErrors}
                                            />
                                            {businessLineType === BusinessLineType.Construction && (
                                                <>
                                                    <ExtendedTextInput
                                                        value={invoicePhoneNo}
                                                        label="Accounts payable contact no."
                                                        validation={phoneNumberWithSpaces()}
                                                        showErrors={showErrors}
                                                        onChange={onInvoicePhoneNoChanged}
                                                        disabled={!emailHandled}
                                                    />
                                                    <ExtendedTextInput
                                                        value={invoiceEmail}
                                                        label="Accounts payable email"
                                                        disabled={!emailHandled}
                                                        onChange={onInvoiceEmailChanged}
                                                        required
                                                        showErrors={showErrors}
                                                    />
                                                    {searchingInvoiceEmail &&
                                                        <Message as={Segment} className="cancel-action">
                                                            <Icon loading name='spinner' />
                                                            Validating accounts payable email...
                                                        </Message>
                                                    }
                                                    {!!invoiceEmailError && (
                                                        <Message as={Segment} className="cancel-action">
                                                            <Icon name="warning sign" />
                                                            {invoiceEmailError}
                                                        </Message>
                                                    )}
                                                    <Input.Checkbox
                                                        onChange={setUseDifferentAddressForInvoice}
                                                        value={useDifferentAddressForInvoice}
                                                        label="Use different address for invoice"
                                                        disabled={!emailHandled}
                                                    />
                                                    {useDifferentAddressForInvoice &&
                                                        <>
                                                            <div className="header">Invoice address</div>
                                                            <AddressLookup
                                                                address={invoiceAddress}
                                                                required={useDifferentAddressForInvoice}
                                                                showErrors={showErrors && useDifferentAddressForInvoice}
                                                                withoutTitle
                                                                disabled={!emailHandled}
                                                                onChange={onInvoiceAddressChanged}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            )}
                                        </>
                                    }
                                </>
                            )}
                            <Input.Checkbox
                                onChange={onUpcomingNewsAndCoursesNewsletterChanged}
                                value={upcomingNewsAndCoursesNewsletter}
                                label="Tick this box if the booker would like to hear about our upcoming news and courses"
                                disabled={!emailHandled}
                            />
                            {businessLineType === BusinessLineType.Construction && <Input.Checkbox
                                onChange={onOptConstructionDelegatesIntoCourseClosureCommsChanged}
                                value={optConstructionDelegatesIntoCourseClosureComms}
                                label="Tick this box if the booker would like delegates to receieve certificates"
                            />}
                        </Card.Content>
                    </Card>
                    {(coursesFromIds && coursesFromIds.length > 0) && (
                        <Card className="courseSummary">
                            <Card.Content header="Course summary" />
                            <Card.Content>
                                {basket && basket.items && Object.values(basket.items).map((basketItem, index) => {
                                    const course = coursesFromIds.find(c => c.id === basketItem.eventInstanceId);
                                    return (
                                        <DetailsAndBillingCourseSummary
                                            key={`courseSummary_${index}`}
                                            course={course}
                                            basketItem={basketItem}
                                            index={index}
                                            handleSeatsChangeForCourse={handleSeatsChangeForCourse}
                                            basketChangesInProgress={basketChangesInProgress}
                                            priceIncludesVat={priceIncludesVat}
                                            allowRemovingBasketItem={!enquiryEventInstanceId}
                                        />
                                    );
                                })}
                            </Card.Content>
                            <Card.Content>
                                {basket && basket.items && Object.values(basket.items).map((basketItem, index) => {
                                    const course = coursesFromIds.find(c => c.id === basketItem.eventInstanceId);
                                    return (
                                        <div className="totals" key={`coursePrice_${index}`}>
                                            <div>{course?.eventTypeName}</div>
                                            <div>
                                                &pound;{numberFormatter((priceIncludesVat
                                                    ? basketItem.totalPriceInPence
                                                    : basketItem.totalPriceExcludingVatInPence
                                                ) / 100)}
                                            </div>
                                        </div>
                                    );
                                })}
                                {(basket && basket.discount > 0) && (
                                    <div className="totals">
                                        <div className="marked">Subtotal</div>
                                        <div>&pound;{numberFormatter((basket.total + basket.discount) / 100)}</div>
                                    </div>
                                )}
                                {(basket && basket.discount > 0) && (
                                    <div className="totals">
                                        <div className="marked">Discount</div>
                                        <div>-&pound;{numberFormatter(basket.discount / 100)}</div>
                                    </div>
                                )}
                                {!priceIncludesVat && vatDifference > 0 && (
                                    <div className="totals">
                                        <div className="marked">VAT</div>
                                        <div className="important">&pound;{numberFormatter(vatDifference / 100)}</div>
                                    </div>
                                )}
                                {(basket && basket.total > 0) && (
                                    <div className="totals">
                                        <div className="marked">Total</div>
                                        <div className="important">&pound;{numberFormatter(basket.total / 100)}</div>
                                    </div>
                                )}
                                {businessLineType === BusinessLineType.Corporate && (
                                    <div className="priceComment">Price includes VAT and DVSA registration fees</div>
                                )}
                            </Card.Content>
                        </Card>
                    )}
                </div>
                <div className="buttons">
                    {paymentType === PaymentType.Card ? (
                        <Button floated="right" content="Go to payment" onClick={handleSubmit} />
                    ) : (
                        <ConfirmButton
                            floated="right"
                            header={"You are about to use book now pay later option"}
                            content={"Are you sure?"}
                            onConfirm={handleSubmit}
                        >
                            Confirm booking
                        </ConfirmButton>
                    )}
                    {!enquiryEventInstanceId && (
                        <Button floated="right" onClick={goBackToCourses} className="cancel-action" content="Return to course selection" />
                    )}
                    {enquiryEventInstanceId && (
                        <Button floated="right" onClick={goBackToEnquiries} className="cancel-action" content="Return to enquiries" />
                    )}
                </div>
            </Form>
        </>
    );
};
