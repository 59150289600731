import * as React from "react";
import { AttendeeListModel } from "@common/crud/attendee";
import { Grid } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { CurrentUser, TtcClientAdvisorRole, PoliceRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { IfNotAuthorized } from "@common/auth/components/IfNotAuthorized";
import { CurrentUserContext } from "@common/current-user-context";
import { useRegisterContext } from "./register-context";

interface RegisterAttendeeProps {
    attendee: AttendeeListModel;
    blueName?: boolean;
    raiseIssueModal?: JSX.Element;
}

const RegisterAttendee: React.FC<RegisterAttendeeProps> = (props) => {

    const { path } = useRegisterContext();
    const { attendee, blueName, raiseIssueModal } = props;

    const currentUser: CurrentUser = React.useContext(CurrentUserContext);

    if (currentUser?.forceId && currentUser.roles.includes(PoliceRole) && currentUser.forceId !== attendee.issuingForceId) {
        return (
            <Grid.Column width="11">
                <div>REFERRED BY OTHER ORGANISATION</div>
            </Grid.Column>
        );
    }

    const renderNameLink = (
        <>
            <Authorize authorize={[TtcClientAdvisorRole, PoliceRole]}>
                <Link href={`${path}/${attendee.id}`} className={`attendee-name ${blueName ? "blue-name" : ""}`}>
                    {attendee.fullName}
                </Link>
            </Authorize>
            <IfNotAuthorized authorize={[TtcClientAdvisorRole, PoliceRole]}>
                <span className={`attendee-name ${blueName ? "blue-name" : ""}`}>{attendee.fullName}</span>
            </IfNotAuthorized>
        </>
    );

    return (
        <>
            {/* Display size > mobile */}
            <Grid.Column width="6" only="computer">
                {renderNameLink}
                {raiseIssueModal}
            </Grid.Column>
            <Grid.Column width="5" only="computer">
                {attendee.drivingLicenceNumber}
            </Grid.Column>

            {/* Display size mobile */}
            <Grid.Column width="11" only="tablet mobile">
                {renderNameLink}
                <div>
                    {attendee.drivingLicenceNumber}
                </div>
                {raiseIssueModal}
            </Grid.Column>
        </>
    );
};

export { RegisterAttendee };
