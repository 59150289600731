import * as React from "react";
import { ToastContainer } from "react-toastify";
import { Container } from "semantic-ui-react";
import { AppSettings } from "@common/appSettings/model";
import { ErrorBoundary, Footer, Spinner } from "@common/global";

import { BookingHeader } from "./BookingHeader";

interface AppProps {
    loading: boolean;
    appSettings: AppSettings;
    routeFragments: React.ComponentType;
}

class BookingWrapper extends React.Component<AppProps> {

    public render() {
        const { loading, appSettings } = this.props;
        const RouteFragments = this.props.routeFragments;
        return (
            <div>
                <BookingHeader />
                <ToastContainer autoClose={5000} />
                <div className="global-wrapper">
                    <Container className="main-content">
                        <ErrorBoundary>
                            <Spinner active={loading}>
                                <RouteFragments />
                            </Spinner>
                        </ErrorBoundary>
                    </Container>
                </div>
                <Footer version={appSettings.version} />
            </div>
        );
    }
}

export { BookingWrapper };
