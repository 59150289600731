import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";

import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actiontypes";
import * as models from "./model";
import { trainerAttributeDefinitionSearchOptionsSelector } from "./selectors";
import { TrainerAttributeDefinitionApi } from "./trainerAttributeDefinitionApi";
import { SearchOptions } from "./model";

export type TrainerAttributeDefinitionAction =
      ({ type: actions.LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS } & Payload<models.TrainerAttributeDefinitionListModel[]>)
    | ({ type: actions.CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS } & Payload<models.TrainerAttributeDefinitionDetailModel>)
    | ({ type: actions.LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS } & Payload<models.TrainerAttributeDefinitionDetailModel>)
    | ({ type: actions.SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS } & Payload<models.TrainerAttributeDefinitionDetailModel>)
    | ({ type: actions.DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS } & Payload<string>);

export const loadTrainerAttributeDefinitionsSuccess = (payload: models.TrainerAttributeDefinitionListModel[]): TrainerAttributeDefinitionAction => ({
    payload,
    type: actions.LOAD_TRAINERATTRIBUTEDEFINITIONS_SUCCESS
});

export const createTrainerAttributeDefinitionSuccess = (payload: models.TrainerAttributeDefinitionDetailModel): TrainerAttributeDefinitionAction => ({
    payload,
    type: actions.CREATE_TRAINERATTRIBUTEDEFINITION_SUCCESS
});

export const saveTrainerAttributeDefinitionSuccess = (payload: models.TrainerAttributeDefinitionDetailModel): TrainerAttributeDefinitionAction => ({
    payload,
    type: actions.SAVE_TRAINERATTRIBUTEDEFINITION_SUCCESS
});

export const deleteTrainerAttributeDefinitionSuccess = (id: string): TrainerAttributeDefinitionAction => ({
    payload: id,
    type: actions.DELETE_TRAINERATTRIBUTEDEFINITION_SUCCESS
});

export function createTrainerAttributeDefinition(trainerAttribute: models.TrainerAttributeDefinitionCreateModel, path: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAttributeDefinitionApi();
        const result = await loadAndTrack(dispatch, "createTrainerAttributeDefinition", api.create(trainerAttribute));
        dispatch(createTrainerAttributeDefinitionSuccess(result));
        dispatch(push(`${path}/${result.id}`));
    };
}

export function saveTrainerAttributeDefinition(trainerAttribute: models.TrainerAttributeDefinitionEditModel, path?: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAttributeDefinitionApi();
        const result = await loadAndTrack(dispatch, "saveTrainerAttributeDefinition", api.save(trainerAttribute));

        dispatch(saveTrainerAttributeDefinitionSuccess(result));
        if (path !== undefined) {
            dispatch(push(`${path}/${result.id}`));
        }
    };
}

export function deleteTrainerAttributeDefinition(id: string, path: string) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAttributeDefinitionApi();
        await loadAndTrack(dispatch, "deleteTrainerAttributeDefinition", api.delete(id));
        dispatch(deleteTrainerAttributeDefinitionSuccess(id));
        dispatch(push(`${path}`));
    };
}

export function loadTrainerAttributeDefinitions() {
    return loadTrainerAttributeDefinitionsWithSearchOptions();
}

export function loadTrainerAttributeDefinitionsWithSearchOptions(options: SearchOptions = null) {
    return async (dispatch: Dispatch) => {
        const api = new TrainerAttributeDefinitionApi();
        const result = await loadAndTrack(dispatch, "loadTrainerAttributeDefinitions", api.getAll(options));
        dispatch(loadTrainerAttributeDefinitionsSuccess(result));
    };
}

export function loadTrainerAttributeDefinitionsFromQuery() {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const trainerAttributeDefinitionFilterQuery = trainerAttributeDefinitionSearchOptionsSelector(getState());
        const api = new TrainerAttributeDefinitionApi();
        const result = await loadAndTrack(dispatch, "loadTrainerAttributeDefinitions", api.getAll(trainerAttributeDefinitionFilterQuery));
        dispatch(loadTrainerAttributeDefinitionsSuccess(result));
    };
}

export const loadTrainerAttributeDefinitionDetailSuccess = (trainerAttribute: models.TrainerAttributeDefinitionDetailModel) => ({
    payload: trainerAttribute,
    type: actions.LOAD_TRAINERATTRIBUTEDEFINITION_DETAIL_SUCCESS
});

export function loadTrainerAttributeDefinitionDetail({ trainerAttributeDefinitionId }: { trainerAttributeDefinitionId: string}) {
    return async (dispatch: Dispatch, getState: () => models.AppState) => {
        const trainerAttributeDefinition = getState().trainerAttributeDefinitions.filter(c => c.id === trainerAttributeDefinitionId)[0];

        if (trainerAttributeDefinition === undefined
            || trainerAttributeDefinition.detailUpdated === undefined
            || trainerAttributeDefinition.detailUpdated.isBefore(trainerAttributeDefinition.listUpdated)) {

            const action = trainerAttributeDefinition === undefined ? createTrainerAttributeDefinitionSuccess : loadTrainerAttributeDefinitionDetailSuccess;

            const api = new TrainerAttributeDefinitionApi();
            const result = await loadAndTrack(dispatch, "loadTrainerAttributeDefinitionDetail", api.detail(trainerAttributeDefinitionId));
            dispatch(action(result));
        }
    };
}
