import * as React from "react";
import { Table, List, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { WelshLanguageIndicator } from "@common/crud/eventInstance/components/WelshLanguageIndicator";
import { dateString, DateFormat } from "@common/crud/common/DateTimeFormats";
import { AutomaticPlacesIndicator } from "./AutomaticPlacesIndicator";
import { TtcClientAdvisorRole } from "@common/auth/model";
import { Authorize } from "reauthorize";
import { useDispatch, useSelector } from "react-redux";
import { reserveMultiPartSeats } from "../../seat/actions";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { ApplicationState } from "../../applicationState";
import { GroupEventInstanceProps } from "@booking/landing/model";
import { MultiPartSeatReservation } from "@booking/seat/model";
import { EventInstanceGroupItemModel } from "@common/crud/eventInstanceGroup/model";

export const GroupEventInstanceRow: React.FC<GroupEventInstanceProps> = ({ groupSearchResultRow,  path, additionalMargin, firstNewElement }) => {
    const [t] = useTranslation("AllEventInstances");
    const dispatch = useDispatch();
    const dayParts = groupSearchResultRow.dayParts && groupSearchResultRow.dayParts;
    const groupEventInstance = dayParts[0];
    const includeFullyBookedCourses = useSelector((state: ApplicationState) => state.includeFullyBookedCourses);

    const onReservationRequest = React.useCallback(() => {

        const multiPartRequests = (dayParts.map((dp, index) => {
            return { eventInstanceId: dp.eventInstanceId, includeFullyBookedCourses, dayPart: index+1 };
        }));

        const reservationRequest: MultiPartSeatReservation = { seatReservations: multiPartRequests, groupId: groupSearchResultRow.groupId };
        dispatch(reserveMultiPartSeats(reservationRequest, path));
    }, [dayParts, path, includeFullyBookedCourses, dispatch]);

    const startTimeFormatted = (dayPart: EventInstanceGroupItemModel) => {
        return dayPart?.startTime.format(DateFormat.Time, { trim: false });
    };
    const endTimeFormatted = (dayPart: EventInstanceGroupItemModel) => {
        const startTime = dayPart?.startTime.clone();
        return startTime.add(dayPart?.eventDuration).format(DateFormat.Time, { trim: false });
    };

    const renderDayParts = () => {
        return (
            dayParts.map((dp, index) => {
                return (
                    <List.Item key={index+1}>
                        {/* eslint-disable-next-line max-len */}
                        {dateString(dp.deliveryDateTime, DateFormat.MidDayOnly)}&ensp;{dateString(dp.deliveryDateTime, DateFormat.ShortWithSpace)}&ensp;{startTimeFormatted(dp)}&nbsp;-&nbsp;{endTimeFormatted(dp)}
                    </List.Item>
                );
            })
        );
    };

    function dayPartsDisplay() {

        return (
            <>{renderDayParts()}</>
        );
    }

    return (
        <>
            <Table.Row
                key={groupEventInstance.eventInstanceId}
                id={additionalMargin ? "scroll-to-this" : undefined}
                className={additionalMargin ? "event-instance-table-additional-margin" : undefined}
            >
                <Table.Cell width={8} className="event-details" ref={firstNewElement}>
                    <List>{dayPartsDisplay()}</List>
                    <b>{t("COURSE_LANGUAGE")}</b> {t(`Languages.${groupSearchResultRow.language}`)}
                    <WelshLanguageIndicator language={groupSearchResultRow.language} />
                    {groupSearchResultRow.oneToOne &&
                        <><br /><img className="menu-icon" src={"/assets/one-to-one.png"} /></>}
                    <Authorize authorize={TtcClientAdvisorRole}>
                        <br />
                        {groupSearchResultRow.seatsAvailable} {t("SEATS_AVAILABLE")}
                    </Authorize>
                    <AutomaticPlacesIndicator automaticSeatsData={groupSearchResultRow.automaticSeatsData} />
                </Table.Cell>
                <Table.Cell width={5} verticalAlign="top">
                    <div className="course-distance">
                        {groupSearchResultRow.eventInstanceDeliveryType === DeliveryTypeEnum.Digital ? null :
                            <>
                                {groupSearchResultRow.venueName}<br />
                                {groupSearchResultRow.distanceInMiles} {t("MILES")}<br />
                            </>}
                        <span className="cost-cell">
                            £{groupSearchResultRow.priceInPence && (groupSearchResultRow.priceInPence / 100).toFixed(2)}
                        </span>
                    </div>
                </Table.Cell>
                <Table.Cell width={3} verticalAlign="top" singleLine className="event-instances-button-container">
                    <Button
                        className="uppercase"
                        onClick={onReservationRequest}
                    >
                        {t("BOOK")}
                    </Button>
                </Table.Cell>
            </Table.Row>
        </>
    );
};
