import axios, { AxiosError } from "axios";
import {
    StartPaymentProcessRequest,
    PaymentResponse,
    PaymentRequest,
    BookingPaymentsInfo,
    ScheduledPaymentPlanDetailModel,
    PaymentType,
    CreateStartPaymentProcessRequestResponse,
    AutoPaymentProcessModel,
    AutoPaymentProcessResult,
    IvrPaymentDetail,
    PaymentDetailsForTransfer,
} from "@common/payments/model";

import moment from "moment";

import { StartPaymentProcessModel } from "./model";
import { toast } from "react-toastify";

export class PaymentApi {
    private readonly apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/booking/${bookingId}/payment` : "/api/payment";
    }

    public async createStartPaymentProcessRequest(paymentModel: StartPaymentProcessModel): Promise<CreateStartPaymentProcessRequestResponse> {
        const response = await axios.post(`${this.apiUrl}/start-payment-process`, paymentModel);
        const model = response.data as CreateStartPaymentProcessRequestResponse;
        return model;
    }

    public async makePayment(paymentRequest?: PaymentRequest): Promise<PaymentResponse> {
        try {
            const response = await axios.post(`${this.apiUrl}/charge-stripe`, paymentRequest);
            const model = response.data as PaymentResponse;
            if (!model.validRequest && !model.duplicateRequest && !model.requiresAction) {
                toast.error(model.paymentError
                    ? model.paymentError
                    : "Your payment has failed");
            }
            return model;
        } catch (e) {
            const axiosError = e as AxiosError;
            // If the server returned 500 error, for example if an exception was thrown, then we don't know the
            // state of the payment and so need to tell user to call us
            if (axiosError && axiosError.response && axiosError.response.status && axiosError.response.status === 500) {
                const response: PaymentResponse = {
                    validRequest: false,
                    paymentError: null,
                    paymentAuthorised: false,
                    requiresAction: null,
                    paymentIntentClientSecret: null,
                    duplicateRequest: false,
                    noAvailability: false,
                    paymentAlreadyReceived: false,
                    reservationHasExpired: false,
                    serverError: true
                };
                toast.error("An error has occured during payment. Please call us.");
                return response;
            }
            throw e;
        }
    }

    public async getBookingPaymentsBalance(): Promise<BookingPaymentsInfo> {
        const response = await axios.get(`${this.apiUrl}/payment-details`);
        return response.data as BookingPaymentsInfo;
    }

    // This one is doing exactly the same thing as the upper one but takes data from admin app where we have slightly different endpoints.
    // Probably worth refactoring on bigger task.
    public async getAdminPaymentsBalance(bookingId: string): Promise<BookingPaymentsInfo> {
        const response = await axios.get(`${this.apiUrl}/payment-details/${bookingId}`);
        return response.data as BookingPaymentsInfo;
    }

    public async getScheduledPaymentPlans(): Promise<ScheduledPaymentPlanDetailModel[]> {
        const response = await axios.get(`${this.apiUrl}/scheduled-payment-plans`);
        return response.data.map(this.parseScheduledPaymentModel);
    }

    public async createOutstandingBalancePayment(): Promise<StartPaymentProcessRequest> {
        const response = await axios.post(`${this.apiUrl}/start-outstanding-balance-payment-process`);
        const model = response.data as StartPaymentProcessRequest;
        return model;
    }

    public async addPayment(eventInstanceId: string, attendeeId: string, paymentType: PaymentType, amount: number, flexiPayPurchasedAfterBooking?: boolean) {
        await axios.post(`${this.apiUrl}/add-payment`, { eventInstanceId, attendeeId, paymentType, amount, flexiPayPurchasedAfterBooking });
    }

    public async startAutoPaymentProcessFromBookingApp(request: AutoPaymentProcessModel): Promise<AutoPaymentProcessResult> {
        const response = await axios.post(`${this.apiUrl}/auto-payment-process`, request);
        const model = response.data as AutoPaymentProcessResult;
        return model;

    }

    public async startAutoPaymentProcessFromAdminApp(bookingId: string, request: AutoPaymentProcessModel): Promise<AutoPaymentProcessResult> {
        const response = await axios.post(`${this.apiUrl}/auto-payment-process/${bookingId}`, request);
        const model = response.data as AutoPaymentProcessResult;
        return model;
    }

    public async getIvrPaymentDetails(bookingId?: string): Promise<IvrPaymentDetail> {
        const endpointUrl = `${this.apiUrl}/ivr-payment-details`;
        const url = bookingId ? `${endpointUrl}/${bookingId}` : endpointUrl;
        const response = await axios.get<IvrPaymentDetail>(url);
        return response.data;
    }

    public async getPaymentDetailsForTransfer(correlationId: string, paymentId: string): Promise<PaymentDetailsForTransfer> {
        const response = await axios.get<PaymentDetailsForTransfer>(`${this.apiUrl}/${correlationId}/${paymentId}/get-details-for-transfer`);
        return this.parsePaymentDetailsForTransferModel(response.data);
    }

    public async transferPayment(oldCorrelationId: string, newCorrelationId: string, paymentId: string): Promise<string> {
        const response = await axios.post<string>(`${this.apiUrl}/transfer-payment`, { oldCorrelationId, newCorrelationId, paymentId });
        return response.data;
    }

    public parseScheduledPaymentModel(model: ScheduledPaymentPlanDetailModel): ScheduledPaymentPlanDetailModel {
        return {
            ...model,
            chargeDate: model.chargeDate && moment(model.chargeDate)
        };
    }

    public parsePaymentDetailsForTransferModel(model: PaymentDetailsForTransfer): PaymentDetailsForTransfer {
        return {
            ...model,
            paymentDate: model.paymentDate && moment(model.paymentDate)
        };
    }
}
