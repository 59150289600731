import { toast } from "@common/toasts";
import * as React from "react";
import { Button } from "semantic-ui-react";

export type FileAcceptTypes = "application/pdf" | "image/jpeg" | "image/png";

interface FileUploadProps {
    uploadLabel: string;
    uploadApiPath: string;
    multiple?: boolean;
    accept?: FileAcceptTypes[];
}

export const FileUpload: React.FC<FileUploadProps> = ({ uploadLabel, multiple, uploadApiPath, accept }) => {

    const [files, setFiles] = React.useState<FileList>();
    const [uploading, setUploading] = React.useState(false);

    function onClear() { setFiles(undefined); }

    function onFileUploadChange(e: React.FormEvent<HTMLInputElement>) {
        setFiles(e.currentTarget.files);
    };

    async function onUploadFiles() {
        setUploading(true);

        const formData = new FormData();
        Array.from(files)
            .forEach(file => formData.append("files", file));
        try {
            const response = await fetch(uploadApiPath, {
                method: "POST",
                body: formData,
                credentials: "include"
            });

            if (response.status === 200) {
                toast.success(`File${files.length > 1 ? "s" : ""} successfully uploaded`);
                onClear();
            } else {
                const errorBody = await response.json();
                toast.error(errorBody?.errorMessage);
            }

        } catch {
            toast.error(`Could not upload file${files.length > 1 ? "s" : ""}`);
        } finally {
            setUploading(false);
        }
    }

    const acceptTypes = accept ? accept.join(",") : undefined;

    return files ? (
        <Button.Group>
            <Button loading={uploading} icon="upload" content={"Upload"} onClick={onUploadFiles} />
            <Button disabled={uploading} icon="delete" content="Clear " onClick={onClear} />
        </Button.Group>
    ) : (
        <label htmlFor="file-upload">
            <div className="ui button">
                <i className="upload icon"></i> &nbsp; {uploadLabel}
            </div>
            <input
                id="file-upload"
                type="file"
                className="invisible"
                multiple={multiple}
                accept={acceptTypes}
                onChange={onFileUploadChange} />
        </label>
    );
};
