import * as React from "react";

import { RecaptchaSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const Recaptcha: ConfigurationAreaComponent<RecaptchaSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="RecaptchaSettings"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "RecaptchaSiteKey", label: "Recaptcha Site Key" },
                { name: "RecaptchaSecretKey", label: "Recaptcha Secret Key" },
            ]}
        />
    );
};
