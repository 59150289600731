import axios from "axios";

import moment from "moment";

import { LookupResponse } from "../common/LookupResponse";

import {
    DorsBookingListModel,
    DorsBookingDetailModel,
    LinkedBookingResponse,
    AssistanceRequired,
    DorsBooking,
    UpdateAttendanceIdRequest,
    UpdateAttendanceIdResponse,
    UpdateAttendanceIdWithDifferencesRequest,
    IvrPaymentStatusModel,
    DorsAttendanceStatusesEnum,
    UpdateEventTypeIdAndNameRequest
} from "./model";
import { EditContactDetailsModel } from "@common/crud/attendee/model";
import { ResultResponse } from "@common/model";

export class DorsBookingApi {

    protected readonly apiUrl = "/api/dorsBooking";

    public async getAll(): Promise<DorsBookingListModel[]> {

        const response = await axios.get(this.apiUrl);
        const data = response.data as DorsBookingListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<DorsBookingDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as DorsBookingDetailModel;
        return this.parseModel(model);
    }

    public async detailWithAudit(id: string): Promise<DorsBookingDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}/audit`);
        const model = response.data as DorsBookingDetailModel;
        return this.parseModel(model);
    }

    public async lookupDorsData(bookingId: string): Promise<LookupResponse> {
        const response = await axios.get(`${this.apiUrl}/${bookingId}/lookupDorsData`);
        const model = response.data as LookupResponse;
        return this.parseDorsLookupModel(model);
    }

    public async getLinkedBookings(correlationId: string): Promise<LinkedBookingResponse> {
        const response = await axios.get(`${this.apiUrl}/${correlationId}/getlinkedbookings`);
        return response.data as LinkedBookingResponse;
    }

    public async getIvrPaymentStatus(correlationId: string): Promise<IvrPaymentStatusModel> {
        const response = await axios.get(`${this.apiUrl}/${correlationId}/ivr-payment-status`);
        return response.data as IvrPaymentStatusModel;
    }

    public async setAssistanceRequired(correlationId: string, model: AssistanceRequired): Promise<DorsBooking> {
        const url = `${this.apiUrl}/${correlationId}/setassistancerequired`;
        const response = await axios.put(url, model);
        const data = response.data as DorsBooking;
        return this.parseModel(data);
    }

    public async updateAttendanceId(model: UpdateAttendanceIdWithDifferencesRequest): Promise<boolean> {
        const result = await axios.post(`${this.apiUrl}/updateattendanceid`, model);
        return result.data as boolean;
    }

    public async updateEventTypeIdAndName(model: UpdateEventTypeIdAndNameRequest): Promise<boolean> {
        const result = await axios.post(`${this.apiUrl}/updateeventypeidandname`, model);
        return result.data as boolean;
    }

    public async validateAttendanceIdUpdate(model: UpdateAttendanceIdRequest): Promise<UpdateAttendanceIdResponse> {
        const result = await axios.post(`${this.apiUrl}/validateattendanceidupdate`, model);
        return result.data as UpdateAttendanceIdResponse;
    }

    public updateContactDetails(bookingId: string, contactDetailsModel: EditContactDetailsModel) {
        return axios.post(`${this.apiUrl}/${bookingId}/updatecontact`, contactDetailsModel);
    }

    public async sendAdHocEmail(bookingId: string, templateName: string, templateId: number): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendadhocmail`, { templateName, templateId });
        return result.data as ResultResponse;
    }

    public async sendEmail(bookingId: string, emailSubject: string, customEmailMessage: string) {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendcustomemail`,{ emailSubject,customEmailMessage });
        return result.data as ResultResponse;
    }

    public async sendAdHocSms(bookingId: string, templateName: string, template: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendadhocsms`, { templateName, template });
        return result.data as ResultResponse;
    }

    public async sendSms(bookingId: string, message: string): Promise<ResultResponse> {
        const result = await axios.post(`${this.apiUrl}/${bookingId}/sendsms?message=${message}`);
        return result.data as ResultResponse;
    }

    public async rejoinBooking(correlationId: string): Promise<void> {
        await axios.get(`${this.apiUrl}/${correlationId}/rejoin-booking`);
    }

    public async checkForOlderBookingsWithMatchingDetails(attendanceId: number): Promise<boolean> {
        const response = await axios.get<boolean>(`${this.apiUrl}/checkforolderbookings?attendanceId=${attendanceId}`);
        return response.data;
    }

    public async checkAttendanceIdDorsStatus(attendanceId: number): Promise<DorsAttendanceStatusesEnum> {
        const response = await axios.get<DorsAttendanceStatusesEnum>(`${this.apiUrl}/dorsAttendanceStatus?attendanceId=${attendanceId}`);
        return response.data;
    }

    public async searchForBookingsByAttendanceId(attendanceId: number): Promise<DorsBookingDetailModel[]> {
        const response = await axios.get(`${this.apiUrl}/${attendanceId}/searchByAttendanceId`);
        const model = response.data as DorsBookingDetailModel[];
        return model.map(m => this.parseModel(m));
    }

    public parseModel(model: DorsBookingDetailModel): DorsBookingDetailModel {

        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate),
            historyRecords: model?.historyRecords?.map(historyRecord => {
                return {
                    ...historyRecord,
                    dateCreated: historyRecord.dateCreated && moment(historyRecord.dateCreated),
                    pendingDate: historyRecord.pendingDate && moment(historyRecord.pendingDate),
                    completionDate: historyRecord.completionDate && moment(historyRecord.completionDate),
                };
            }),
            initialBookingDate: model.initialBookingDate && moment(model.initialBookingDate),
        };
    }

    public parseDorsLookupModel(model: LookupResponse): LookupResponse {
        return {
            ...model,
            expiryDate: model.expiryDate && moment(model.expiryDate)
        };
    }
}
