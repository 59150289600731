/* eslint-disable no-nested-ternary */
import * as React from "react";
import { Form, Table } from "semantic-ui-react";
import { FeesStatus, FeesStatusEnum, FeesStatusHalf, RebookedAttendee } from "../model";
import { numberFormatter } from "@common/helpers/number-format";
import { optionsFromObjectIncludingZero } from "@common/crud/common";
import "./BasketModalItem.scss";

export interface RebookSelectSummaryRowProps {
    rebookedAttendee: RebookedAttendee;
    changeFeesStatus: (attendeeId: string, feeStatus: number) => void;
}

export const RebookSelectSummaryRow: React.FC<RebookSelectSummaryRowProps> = ({ rebookedAttendee, changeFeesStatus }) => {
    const changeFeesStatusForAttendee = React.useCallback((_, { value }) => {
        changeFeesStatus(rebookedAttendee.id, value);
    }, [changeFeesStatus, rebookedAttendee.id]);

    const feesStateOptions = React.useMemo(() => {
        const options = optionsFromObjectIncludingZero(rebookedAttendee.feesHalfDue ? FeesStatusHalf : FeesStatus);
        return (rebookedAttendee?.newPricePerSeatInPence - rebookedAttendee?.oldPricePerSeatInPence) <= 0
            ? options.filter(d => d.value !== FeesStatusEnum.IncreaseOnly)
            : options;
    }, [rebookedAttendee?.newPricePerSeatInPence, rebookedAttendee?.oldPricePerSeatInPence, rebookedAttendee?.feesHalfDue]);

    const refundDue = React.useMemo(() => {
        const oldPriceToBeRemoved = (rebookedAttendee.feesHalfDue && !rebookedAttendee.feesWaived)
            ? rebookedAttendee.oldPricePerSeatInPence / 2
            : rebookedAttendee.oldPricePerSeatInPence;

        return ((rebookedAttendee.isBookingCanceled || (rebookedAttendee.feesDue && !rebookedAttendee.feesWaived
            && !rebookedAttendee.feesZeroed))
            ? 0
            : ((rebookedAttendee.feesDue || rebookedAttendee.feesHalfDue) && rebookedAttendee.feesZeroed)
                ? 0
                : (rebookedAttendee.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                    ? oldPriceToBeRemoved - rebookedAttendee.newPricePerSeatInPence
                    : 0);
    }, [rebookedAttendee]);

    const amountToPay = React.useMemo(() => {
        const oldPriceToBeRemoved = (rebookedAttendee.feesHalfDue && !rebookedAttendee.feesWaived)
            ? rebookedAttendee.oldPricePerSeatInPence / 2
            : rebookedAttendee.oldPricePerSeatInPence;

        return ((rebookedAttendee.isBookingCanceled || (rebookedAttendee.feesDue && !rebookedAttendee.feesWaived
            && !rebookedAttendee.feesZeroed))
            ? rebookedAttendee.newPricePerSeatInPence
            : ((rebookedAttendee.feesDue || rebookedAttendee.feesHalfDue) && rebookedAttendee.feesZeroed)
                ? 0
                : (rebookedAttendee.newPricePerSeatInPence - oldPriceToBeRemoved) < 0
                    ? 0
                    : rebookedAttendee.newPricePerSeatInPence - oldPriceToBeRemoved);
    }, [rebookedAttendee]);

    return (
        <Table.Row>
            <Table.Cell className="overflow-wrap-break-word" colSpan={2}>
                {rebookedAttendee?.fullName}
            </Table.Cell>
            <Table.Cell className="overflow-wrap-break-word" colSpan={2}>
                {rebookedAttendee.eventTypeName}, {rebookedAttendee.eventInstanceDeliveryDateTime.format("ddd DD/MM/YYYY HH:mm")}
            </Table.Cell>
            <Table.Cell className="overflow-wrap-break-word" colSpan={2}>
                {rebookedAttendee.rebookedToEventInstanceId ? <>
                    {rebookedAttendee.rebookedEventTypeName}, {rebookedAttendee.rebookedEventInstanceDeliveryDateTime.format("ddd DD/MM/YYYY HH:mm")}
                </> : "N/A"}
            </Table.Cell>
            <Table.Cell colSpan={2}>
                {rebookedAttendee.rebookedToEventInstanceId ? <>
                    &pound;{numberFormatter(rebookedAttendee.oldPricePerSeatInPence / 100)}
                </> : "N/A"}
            </Table.Cell>
            <Table.Cell colSpan={2}>
                &pound;{numberFormatter(rebookedAttendee.newPricePerSeatInPence / 100)}
            </Table.Cell>
            <Table.Cell colSpan={2}>
                {rebookedAttendee.rebookedToEventInstanceId ? <>
                    {rebookedAttendee.isBookingCanceled
                        ? "Yes"
                        : (rebookedAttendee.feesDue || rebookedAttendee.feesHalfDue)
                            ? (<>
                                <Form.Dropdown
                                    floating
                                    selection
                                    value={rebookedAttendee?.feesWaived
                                        ? FeesStatusEnum.IncreaseOnly
                                        : rebookedAttendee?.feesZeroed
                                            ? FeesStatusEnum.No
                                            : FeesStatusEnum.Yes}
                                    options={feesStateOptions}
                                    onChange={changeFeesStatusForAttendee}
                                    search
                                />
                            </>)
                            : "No"}
                </> : "N/A"}
            </Table.Cell>
            <Table.Cell colSpan={2}>
                {rebookedAttendee.rebookedToEventInstanceId ? <>&pound;{numberFormatter(refundDue / 100)}</> : "N/A"}
            </Table.Cell>
            <Table.Cell colSpan={2}>
                {rebookedAttendee.rebookedToEventInstanceId ? <>&pound;{numberFormatter(amountToPay / 100)}</> : "N/A"}
            </Table.Cell>
        </Table.Row>
    );
};
