import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ObjectLiteral, push } from "redux-little-router";
import { Area, Language, SearchOptions, YesNoAnyEnum } from "@common/crud/eventInstance/model";
import { Filters } from "@common/crud/eventInstance/components/Filters";
import { AllItems } from "@common/crud/eventInstance/components/AllItems";
import { eventInstanceSearchOptionsSelector, eventInstancesSelector } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmButton } from "@common/components";
import { Authorize } from "reauthorize";
import { TrainerRole, TtcPlannerRole } from "@common/auth/model";
import { Apps, StringValues } from "@common/model";
import { AnyAction, Dispatch } from "redux";
import { AuthorizeProps } from "reauthorize/dist/Authorize";
import { ActionModal } from "./bulkActions/ActionModal";
import { acceptEventInstances, loadEventInstances } from "../actions";
import { AppState } from "../model";
import { compare } from "@common/helpers/compare";
import { appSelector } from "@common/crud/common/selectors";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { TrainerCourseConfirmationSummary } from "./trainers/TrainerCourseConfirmationSummary";

const PAGE_SIZE = 100;

interface EventInstanceListWithFiltersProps {
    area?: Area;
    workflowTypes?: WorkflowTypeEnum[];
    corporateOrganisationId?: string;
    initialWorkflowType?: WorkflowTypeEnum;
    hideBusinessDriver?: boolean;
}

const parseOptions = (options: SearchOptions) => {
    const parsedOptions: StringValues<SearchOptions> = {
        fromDate: options.fromDate?.format("YYYY-MM-DD") || undefined,
        toDate: options.toDate?.format("YYYY-MM-DD") || undefined,
        hasAvailableSeats: options.hasAvailableSeats?.toString(),
        requiresTrainers: options.requiresTrainers ? YesNoAnyEnum[options.requiresTrainers] : undefined,
        oneToOneOnly: options.oneToOneOnly?.toString(),
        eventType: options.eventType?.join(",") || undefined,
        forceId: options.forceId?.join(",") || undefined,
        venueId: options.venueId?.join(",") || undefined,
        getOnlyUnconfirmedByTrainer: options.getOnlyUnconfirmedByTrainer?.toString(),
        trainerId: options.trainerId,
        eventStatus: options.eventStatus?.join(",") || undefined,
        deliveryType: options.deliveryType,
        availableForOtherTrainers: options.availableForOtherTrainers ? YesNoAnyEnum[options.availableForOtherTrainers] : undefined,
        onlyIncludeCoursesMadeAvailableByTrainer: options.onlyIncludeCoursesMadeAvailableByTrainer?.toString(),
        maxPageSize: options.maxPageSize?.toString(),
        language: Language[options.language],
        daysOfWeek: options?.daysOfWeek?.join(",") || undefined,
        dayPeriod: options?.dayPeriod?.join(",") || undefined,
        filtersCleared: options?.filtersCleared?.toString(),
        hasWarnings: options?.hasWarnings?.toString(),
        corporateOrganisationIds: options?.corporateOrganisationIds?.join(",") || undefined,
        bookingType: options.bookingType,
        stateWorkflowType: options?.stateWorkflowType?.toString()
    };

    return parsedOptions;
};

export const EventInstanceListWithFilters: React.FC<EventInstanceListWithFiltersProps> = ({
    area,
    workflowTypes,
    corporateOrganisationId,
    initialWorkflowType,
    hideBusinessDriver,
}) => {
    const [eventInstanceIds, setEventInstanceIds] = React.useState<string[]>([]);
    const [hasMonitors, setHasMonitors] = React.useState<boolean>(false);
    const [hasExternalAssessmentDue, setHasExternalAssessmentDue] = React.useState<boolean>(false);

    const [searchOptions, setSearchOptions] = React.useState<SearchOptions>({});
    const [initialLoad, setInitialLoad] = React.useState(true);

    const dispatch = useDispatch();

    const routerSearchOptions = useSelector((state: AppState) => eventInstanceSearchOptionsSelector(state, initialWorkflowType));
    const eventInstances = useSelector(eventInstancesSelector);
    const app = useSelector(appSelector);
    const currentUser = useSelector((state: any) => state.currentUser);

    const trainerId = React.useMemo(() => app === Apps.Trainer && currentUser?.id, [app, currentUser?.id]);

    React.useEffect(() => {
        // prevent dispatching the initial state so user can back track
        if (initialLoad) {
            setInitialLoad(false);
            return;
        }
        const query = parseOptions(routerSearchOptions);
        setQuery(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const truncatedOptions = {
            ...routerSearchOptions,
            eventType: routerSearchOptions.eventType === undefined ? [] : routerSearchOptions.eventType,
            corporateOrganisationIds: routerSearchOptions.corporateOrganisationIds === undefined ? [] : routerSearchOptions.corporateOrganisationIds
        };
        Object.keys(truncatedOptions).forEach(key => truncatedOptions[key] === undefined ? delete truncatedOptions[key] : {});
        const newSearchOptions = {
            ...truncatedOptions,
            workflowTypes: workflowTypes
                ? workflowTypes
                : [WorkflowTypeEnum.Any],
            corporateOrganisationIds: corporateOrganisationId
                ? [corporateOrganisationId]
                : truncatedOptions.corporateOrganisationIds };
        if (!compare(newSearchOptions, searchOptions)) {
            loadInstances(newSearchOptions);
            setSearchOptions(newSearchOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerSearchOptions, corporateOrganisationId]);

    const setQuery = React.useCallback((query: ObjectLiteral<string>) => dispatch(push({ pathname: undefined, query })), [dispatch]);
    const loadInstances = React.useCallback((options: SearchOptions) => dispatch(loadEventInstances({ options })), [dispatch]);

    const pageSize = React.useMemo(() => searchOptions.maxPageSize ? searchOptions.maxPageSize : PAGE_SIZE, [searchOptions]);

    const onEventInstanceSelection = React.useCallback((eventInstanceId: string) => {
        const retrieveMonitorState = (newSelectedIds: string[]) => {
            if (eventInstances?.some(ei => newSelectedIds.some(id => id === ei.id) && ei.hasMonitor)) {
                setHasMonitors(true);
            } else {
                setHasMonitors(false);
            }
        };

        const groupInstancesIds: string[] = [];
        const groupId = eventInstances.find(ei => ei.id === eventInstanceId).groupId;
        if (groupId) {
            for (const ei of eventInstances) {
                if (ei.groupId === groupId && ei.id) {
                    groupInstancesIds.push(ei.id);
                }
            }
        }

        if (eventInstanceIds?.includes(eventInstanceId)) {
            const newSelectedIds = eventInstanceIds?.filter(x => x !== eventInstanceId);
            if (groupId) {
                // also remove related group instances
                for (const otherGroupId of groupInstancesIds) {
                    const i = newSelectedIds.indexOf(otherGroupId);
                    if (i !== -1) {
                        newSelectedIds.splice(i, 1);
                    }
                }
            }

            retrieveMonitorState(newSelectedIds);
            setEventInstanceIds(newSelectedIds);

        } else {
            if (groupId) {
                const newSelectedIds = [...eventInstanceIds, ...groupInstancesIds];
                retrieveMonitorState(newSelectedIds);
                setEventInstanceIds(newSelectedIds);
            } else {
                const newSelectedIds = [...eventInstanceIds, eventInstanceId];
                retrieveMonitorState(newSelectedIds);
                setEventInstanceIds(newSelectedIds);
            }
        }

    }, [eventInstanceIds, eventInstances]);

    const clearSelection = React.useCallback(() => {
        setEventInstanceIds([]);
    }, []);

    const onOptionsChanged = React.useCallback((options: SearchOptions) => {
        const query = parseOptions(options);
        clearSelection();
        setQuery(query);
    }, [clearSelection, setQuery]);

    const selectAll = React.useCallback(() => {
        if (eventInstanceIds.length === eventInstances.length) {
            setEventInstanceIds([]);
            setHasMonitors(false);
        } else {
            if (eventInstances) {
                setEventInstanceIds(eventInstances?.map(x => x.id));
                setHasMonitors(!!eventInstances?.find(ei => ei.hasMonitor));
            }
        }
    }, [eventInstanceIds, eventInstances]);

    React.useEffect(() => {
        const selectedEventInstances = eventInstances?.filter(ei => eventInstanceIds.includes(ei.id));
        if (selectedEventInstances.some(ei => ei.trainers?.some(t => t.externalAssessmentDue) || ei.practicalTrainers?.some(pt => pt.externalAssessmentDue))) {
            setHasExternalAssessmentDue(true);
        } else {
            setHasExternalAssessmentDue(false);
        }
    }, [eventInstanceIds, eventInstances]);

    const isOnsiteCourse =
        eventInstances.some(x => eventInstanceIds.find(c => c === x.id)?.length > 0 &&
            x.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite);

    const dispatchWithCleanup = React.useCallback((d: (d: Dispatch<AnyAction>) => Promise<void>): void => {
        dispatch(d);
        clearSelection();
    }, [clearSelection, dispatch]);

    const accept = React.useCallback(() => {
        dispatchWithCleanup(acceptEventInstances(eventInstanceIds));
    }, [dispatchWithCleanup, eventInstanceIds]);
    const isActionDisabled = eventInstanceIds?.length === 0;

    return (
        <>
            <Filters
                app={app}
                area={area}
                onOptionsChanged={onOptionsChanged}
                searchOptions={routerSearchOptions}
                sideMenuStyle={!!corporateOrganisationId}
                workflowTypes={workflowTypes}
                hideBusinessDriver={hideBusinessDriver}
            />
            <Grid.Row>
                <Grid.Column width={16}>
                    {eventInstances.length > pageSize &&
                        <p>Showing first {pageSize} courses; use the search box to filter.</p>
                    }
                    <AllItems
                        selectedEventInstances={eventInstanceIds}
                        eventInstances={eventInstances.slice(0, pageSize)}
                        onEventInstanceSelect={onEventInstanceSelection}
                        selectAll={selectAll}
                        area={area}
                        trainerId={trainerId}
                    />
                </Grid.Column>
            </Grid.Row>
            {app === Apps.Trainer &&
                <AuthorizedGridColumn
                    authorize={TrainerRole}
                    children={(<ConfirmButton
                        header={`You are about to confirm ${eventInstanceIds.length} courses.`}
                        size={"large"}
                        content={

                            <TrainerCourseConfirmationSummary
                                eventInstances={eventInstances.filter(e => eventInstanceIds.includes(e.id))}
                                trainerId={trainerId} />
                        }
                        onConfirm={accept}
                        disabled={isActionDisabled}
                        children={<span>Accept Courses</span>}
                    />)}
                />}
            {(area === Area.AdminEventManagementEventInstance || area === Area.AdminEventManagementTrainerEventInstance
                || area === Area.AdminEventManagementCorporateOrganisation) &&
                <AuthorizedGridColumn
                    authorize={TtcPlannerRole}
                    children={
                        <ActionModal
                            area={area}
                            selectedEventInstanceIds={eventInstanceIds}
                            isOnsiteCourse={isOnsiteCourse}
                            clearSelection={clearSelection}
                            hasMonitor={hasMonitors}
                            hasExternalAssessmentDue={hasExternalAssessmentDue}
                            searchOptions={searchOptions}
                        />}
                />
            }
        </>
    );
};

const AuthorizedGridColumn: React.FC<AuthorizeProps> = ({ authorize, children }) => {
    return (
        <Authorize authorize={authorize}>
            <Grid.Row>
                <Grid.Column width={16}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Authorize>
    );
};
