/* eslint-disable eqeqeq */
import * as React from "react";
import moment from "moment";
import { Detail } from "@neworbit/simpleui-detail";
import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { isNullOrUndefinedOrEmptyString } from "@common/global/CommonHelpers";

export interface DisplaySuggestedStartTimesProps {
    deliveryTypeDescription: string;
    startTimes: Dictionary<moment.Duration[]>;
}

export const DisplaySuggestedStartTimes: React.FC<DisplaySuggestedStartTimesProps> = ({ startTimes, deliveryTypeDescription }) => {
    return (
        <>
            {startTimes != null && startTimes[1] != null ?
                Object.entries(startTimes).map(
                    ([key, values]) => {
                        const sessionLabel = key === "1" ? "Session" : "Sessions";
                        const deliveryType = isNullOrUndefinedOrEmptyString(deliveryTypeDescription) ? "" : `(${deliveryTypeDescription})`;
                        const label = `Start times for ${key} ${sessionLabel} ${deliveryType}`;
                        const times = values.map(time => moment.utc(time.asMilliseconds()).format(DateFormat.TimeFormat12HoursFormat)).join(", ");

                        return (
                            <Detail
                                key={key}
                                label={label}
                                value={times}
                                labelWidth={5}
                                valueWidth={11}
                            />);

                    }) :
                <Detail
                    label={`Start times (${deliveryTypeDescription})`}
                    value="Not set"
                    labelWidth={5}
                    valueWidth={11}
                />
            }
        </>
    );
};
