/* eslint-disable max-lines */
import * as React from "react";
import { Button, Modal, Form, Message, Segment, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "redux-little-router";
import { Order, OrderEditModel, OrderEditModelValid } from "../model";
import { MxEmailCheckResult } from "@common/crud/email/model";
import { EmailApi } from "@common/email/emailApi";
import { ExtendedDropdown } from "@common/components/ExtendedDropdown";
import { OrganisationApi } from "@common/crud/organisation";
import { BusinessDevelopmentManager } from "@common/crud/organisation/model";
import { phoneNumber } from "@common/validation";
import { Input } from "@neworbit/simpleui-input";
import { AddressLookup } from "@common/addressLookup/components/AddressLookup";
import { Address } from "@common/crud/common";
import { toast } from "@common/toasts";
import { updateOrder } from "../actions";
import { debounce } from "lodash";
import { CorporateUserApi } from "@common/crud/corporateUser/corporateUserApi";
import { CorporateUserDetailModel } from "@common/crud/corporateUser/model";
import { businessLineTypeSelector, universalEventManagementPathSelector } from "@common/redux-helpers";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

export interface EditModalProps {
    order: Order;
    setRefreshingRecord: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BaseOrderValidModel: OrderEditModelValid = {
    organisationName: true,
    organisationBdmId: true,
    organisationBdmName: true,
    organisationBdmEmail: true,
    nameOnTaxReceipt: true,
    organisationAddress: true,
    bookerForename: true,
    bookerSurname: true,
    bookerEmail: true,
    bookerPhoneNumber: true
};

export const EditModal: React.FC<EditModalProps> = ({ order, setRefreshingRecord }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [orderData, setOrderData] = React.useState<OrderEditModel>({ ...order, nameOnTaxReceipt: order.taxReceiptInformation?.nameOnTaxReceipt });
    const [orderDataValid, setOrderDataValid] = React.useState<OrderEditModelValid>(BaseOrderValidModel);
    const [businessDevelopmentManagers, setBusinessDevelopmentManagers] = React.useState<BusinessDevelopmentManager[]>([]);
    const [duplicateCheckInProgress, setDuplicateCheckInProgress] = React.useState(false);
    const [duplicateUserEmail, setDuplicateUserEmail] = React.useState<CorporateUserDetailModel>(undefined);
    const [mxEmailCheckResult, setMxEmailCheckResult] = React.useState<MxEmailCheckResult>(undefined);
    const [submitted, setSubmitted] = React.useState(false);

    const businessLineType = useSelector(businessLineTypeSelector);
    const eventManagementPath = useSelector(universalEventManagementPathSelector);

    const dispatch = useDispatch();

    const businessDevelopmentManagersOptions = React.useMemo(() =>
        (orderData.organisationBdmId &&
            !businessDevelopmentManagers.some(bdm => bdm.id === orderData.organisationBdmId)
            ? [...businessDevelopmentManagers,
                {
                    id: orderData.organisationBdmId,
                    name: orderData.organisationBdmName,
                    email: orderData.organisationBdmEmail
                }
            ] : businessDevelopmentManagers).map(bdm => ({ text: `${bdm.name} [${bdm.email}]`, value: bdm.id }))
    , [businessDevelopmentManagers, orderData]);

    const onOrganisationNameChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            organisationName: value
        });

        setOrderDataValid({
            ...orderDataValid,
            organisationName: valid
        });
    }, [orderData, orderDataValid]);

    const onBusinessDevelopmentManagerChange = React.useCallback((businessDevelopmentManagerId: string) => {
        const matchingBusinessDevelopmentManager = businessDevelopmentManagers.find(bdm => bdm.id === businessDevelopmentManagerId);
        setOrderData({
            ...orderData,
            organisationBdmId: businessDevelopmentManagerId,
            organisationBdmName: matchingBusinessDevelopmentManager?.name,
            organisationBdmEmail: matchingBusinessDevelopmentManager?.email
        });

        setOrderDataValid({
            ...orderDataValid,
            organisationBdmId: true,
            organisationBdmName: true,
            organisationBdmEmail: true
        });
    }, [businessDevelopmentManagers, orderData, orderDataValid]);

    const onNameOnTaxReceiptChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            nameOnTaxReceipt: value
        });

        setOrderDataValid({
            ...orderDataValid,
            nameOnTaxReceipt: valid
        });
    }, [orderData, orderDataValid]);

    const onAddressChange = React.useCallback((value: Address, valid: boolean) => {
        setOrderData({
            ...orderData,
            organisationAddress: value
        });

        setOrderDataValid({
            ...orderDataValid,
            organisationAddress: valid
        });
    }, [orderData, orderDataValid]);

    const onBookerForenameChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            bookerForename: value
        });

        setOrderDataValid({
            ...orderDataValid,
            bookerForename: valid
        });
    }, [orderData, orderDataValid]);

    const onBookerSurnameChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            bookerSurname: value
        });

        setOrderDataValid({
            ...orderDataValid,
            bookerSurname: valid
        });
    }, [orderData, orderDataValid]);

    const checkEmail = React.useCallback(async (email: string) => {
        if (!email) {
            setDuplicateUserEmail(undefined);
            setMxEmailCheckResult(undefined);
        } else {
            const corporateUserApi = new CorporateUserApi();
            const emailApi = new EmailApi();
            const [checkEmailResponse, findUserResponse] = await Promise.all([
                emailApi.checkEmail(email),
                corporateUserApi.findByEmail(email, businessLineType)
            ]);
            setDuplicateUserEmail(findUserResponse ? findUserResponse : undefined);
            setMxEmailCheckResult(checkEmailResponse);
        }
        setDuplicateCheckInProgress(false);
    }, []);

    const deboucedCheckEmail = React.useMemo(() => debounce((value: string) => {
        checkEmail(value);
    }, 1000), [checkEmail]);

    const onBookerEmailChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            bookerEmail: value
        });

        setOrderDataValid({
            ...orderDataValid,
            bookerEmail: valid
        });

        setDuplicateCheckInProgress(true);
        deboucedCheckEmail(value);
    }, [deboucedCheckEmail, orderData, orderDataValid]);

    const onBookerPhoneNumberChange = React.useCallback((value: string, valid: boolean) => {
        setOrderData({
            ...orderData,
            bookerPhoneNumber: value
        });

        setOrderDataValid({
            ...orderDataValid,
            bookerPhoneNumber: valid
        });
    }, [orderData, orderDataValid]);

    const openCloseAction = React.useCallback(async () => {
        if (!open) {
            setSubmitted(false);
            setOrderData({ ...order, nameOnTaxReceipt: order.taxReceiptInformation?.nameOnTaxReceipt });
            setOrderDataValid(BaseOrderValidModel);
            setDuplicateCheckInProgress(false);
            setDuplicateUserEmail(undefined);
            setOpen(!open);
            const organisationApi = new OrganisationApi();
            setBusinessDevelopmentManagers(await organisationApi.getAllBusinessDevelopmentManagers());
        } else {
            setOpen(!open);
        }
    }, [open, order]);

    const onContinue = React.useCallback(() => {
        if (mxEmailCheckResult && mxEmailCheckResult.valid === false) {
            toast.warning("Please fix invalid email");
            return;
        }

        setSubmitted(true);

        if (Object.values(orderDataValid).every(value => value)) {
            dispatch(updateOrder(order.id, orderData));
            setOpen(false);
            setRefreshingRecord(true);
        } else {
            const invalidOrderFields = ObjectKeys(orderDataValid).filter(field => !orderDataValid[field]);
            const invalidFieldsWithData = invalidOrderFields.filter(field => orderData[field]);

            const missingRequiredFields = invalidFieldsWithData.length < invalidOrderFields.length;
            const hasInvalidFields = invalidFieldsWithData.length > 0;

            if (missingRequiredFields) {
                toast.warning("Please fill in all required fields");
            }

            if (hasInvalidFields) {
                toast.warning("Please fix invalid fields");
            }

            setSubmitted(false);
        }
    }, [dispatch, mxEmailCheckResult, order.id, orderData, orderDataValid, setRefreshingRecord]);

    return (
        <>
            <Button onClick={openCloseAction}>EDIT</Button>
            <Modal open={open}>
                <Modal.Header>
                    Update Details
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <h5>Organisation details</h5>
                        <Input.Text
                            value={orderData.organisationName}
                            label="Name"
                            required
                            showErrors={submitted}
                            onChange={onOrganisationNameChange}
                        />
                        <ExtendedDropdown
                            value={orderData.organisationBdmId}
                            label="BDM"
                            options={businessDevelopmentManagersOptions}
                            showErrors={submitted}
                            onChange={onBusinessDevelopmentManagerChange}
                            dynamicOptions
                            search
                        />
                        <h5>Order details</h5>
                        <Input.Text
                            value={orderData.nameOnTaxReceipt}
                            label="Name on Tax Receipt"
                            required
                            showErrors={submitted}
                            onChange={onNameOnTaxReceiptChange}
                        />
                        <div className="field-wrapper">
                            <div className="field">
                                <label>Address</label>
                            </div>
                        </div>
                        <AddressLookup
                            address={orderData.organisationAddress}
                            required
                            showErrors={submitted}
                            onChange={onAddressChange}
                            compact
                        />
                        <h5>Booker details</h5>
                        <Input.Text
                            value={orderData.bookerForename}
                            label="Forename"
                            required
                            showErrors={submitted}
                            onChange={onBookerForenameChange}
                        />
                        <Input.Text
                            value={orderData.bookerSurname}
                            label="Surname"
                            required
                            showErrors={submitted}
                            onChange={onBookerSurnameChange}
                        />
                        <Input.Email
                            value={orderData.bookerEmail}
                            label="Email"
                            required
                            showErrors={submitted}
                            onChange={onBookerEmailChange}
                        />
                        {mxEmailCheckResult && !mxEmailCheckResult.valid &&
                            <Message as={Segment} className="cancel-action">
                                <p><Icon className="validation-icon" name={"exclamation circle"} />{mxEmailCheckResult.error}</p>
                            </Message>
                        }
                        {(duplicateUserEmail && duplicateUserEmail.id !== order.bookerId) &&
                            <Message as={Segment} className="cancel-action">
                                <p>User with that email already exists:&nbsp;
                                    <Link href={`${eventManagementPath}/organisations/${duplicateUserEmail.organisationId}`
                                        + `/users/${duplicateUserEmail.id}`}>
                                        {duplicateUserEmail.fullName}
                                    </Link>
                                </p>
                            </Message>
                        }
                        <Input.Text
                            value={orderData.bookerPhoneNumber}
                            label="Phone Number"
                            showErrors={submitted}
                            onChange={onBookerPhoneNumberChange}
                            validation={phoneNumber()}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={openCloseAction}
                        className="cancel-action"
                        disabled={submitted}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinue}
                        loading={submitted}
                        disabled={duplicateCheckInProgress || (duplicateUserEmail?.id && duplicateUserEmail.id !== order.bookerId)}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
