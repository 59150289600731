import * as React from "react";
import { HistoryContent } from "@common/history/components/HistoryContent";
import { useSelector } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { HistoryFilter } from "@common/history/components/HistoryFilter";
import { ChangeEventType } from "@common/history/model";
import { orderSelector } from "../selectors";
import { OrderDetailModel } from "../model";
import { NewOrderNote } from "./NewOrderNote";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";

const expectedFilters = [
    { type: ChangeEventType.Note, display: true },
    { type: ChangeEventType.Payment, display: true },
    { type: ChangeEventType.Booking, display: true },
    { type: ChangeEventType.Comms, display: true },
    { type: ChangeEventType.Audit, display: true },
    { type: ChangeEventType.DataModification, display: true },
];

export function OrderHistory() {
    const order = useSelector(orderSelector) as OrderDetailModel;

    const [filters, setFilters] = React.useState<ChangeEventType[]>([ChangeEventType.Note, ChangeEventType.Payment,
        ChangeEventType.Booking, ChangeEventType.Comms]);

    const [selectFiltersText, setSelectFiltersText] = React.useState<string>("Deselect All");

    React.useEffect(() => {
        setSelectFiltersText(filters.length > 1 ? "Deselect All" : "Select All");
    }, [filters?.length]);

    const addOrRemove = React.useCallback((item: ChangeEventType) => filters.includes(item) ?
        setFilters([...filters.filter((c) => c !== item)]) :
        setFilters([...filters, item]), [filters]);

    const selectFilters = React.useCallback(() => filters.length > 1 ?
        setFilters([ChangeEventType.ExpiryDate]) :
        setFilters([...expectedFilters.map(filter => filter.type)]), [filters]);

    return (
        <div>
            <div>
                <NewOrderNote orderId={order?.id} />
            </div>
            <div>
                <Header size="large">Order booking journey</Header>
                <Grid>
                    <Grid.Column width={3}>
                        <Grid.Row>
                            Filters by group:
                        </Grid.Row>
                        {expectedFilters.map(eventType => eventType.display ?
                            <HistoryFilter
                                key={eventType.type}
                                eventType={eventType.type}
                                value={filters.includes(eventType.type)}
                                onChange={addOrRemove}
                            /> : null)}
                        <Grid.Row>
                            <a className="show-button" onClick={selectFilters}>{selectFiltersText}</a>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <HistoryContent showDetailsDefault={false} entries={order?.history?.filter(
                            record => filters.includes(record.eventType))} taskRelation={TaskRelationEnum.Order} />
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    );
}
