import * as React from "react";
import { Icon, Popup, PopupProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { MobileMaxWidth } from "@common/style/constants";

const WithIcon: React.FC<{ isAttendee: boolean; attendeeTextKey: string; caTextKey: string }> = ({ attendeeTextKey, caTextKey, children, isAttendee }) => {
    const [t] = useTranslation("Filters");

    const props: PopupProps = {
        trigger: <Icon name="info circle" size="big" />,
        content: isAttendee ? t(attendeeTextKey) : t(caTextKey),
        position: "top right"
    };

    // only add this prop on mobile screens
    if (window.screen.width <= MobileMaxWidth) {
        props.openOnTriggerFocus = true;
    }

    return (
        <div className="input-with-tooltip-group search-tooltip">
            <div className="input-with-tooltip">
                {children}
            </div>
            <Popup {...props} />
        </div>
    );
};

export { WithIcon };
