import axios from "axios";
import moment from "moment";
import { InvoicePreviewModel, InvoiceDetailModel, InvoiceListModel, TrainerInvoiceLineItem, TrainerInvoiceBonusLineItem,
    TrainerInvoiceSubcontractedLineItem } from "./model";

export class TrainerInvoiceApi {
    private readonly apiUrl: string;

    constructor(trainerId?: string) {
        this.apiUrl = trainerId ? `/api/trainerInvoice/${trainerId}` : "/api/trainerInvoice";
    }

    public async getInvoices(): Promise<InvoiceListModel[]> {
        const response = await axios.get(`${this.apiUrl}`);
        const data = response.data as InvoiceListModel[];
        return data.map(this.parseListModel);
    }

    public async getInvoicesForProcessing(): Promise<InvoiceListModel[]> {
        const response = await axios.get(`${this.apiUrl}/invoicesForProcessing`);
        const data = response.data as InvoiceListModel[];
        return data.map(this.parseListModel);
    }

    public async getInvoicePreview(): Promise<InvoicePreviewModel> {
        const response = await axios.get(`${this.apiUrl}/preview`);
        const data = response.data as InvoicePreviewModel;
        data.invoiceDate = moment(data.invoiceDate);
        data.invoiceLineItems = data.invoiceLineItems.map(this.parseInvoiceListItemModel);
        data.invoiceBonusLineItems = data.invoiceBonusLineItems.map(this.parseInvoiceBonusLineItemModel);
        data.invoiceSubcontractedToLineItems =
            data.invoiceSubcontractedToLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];
        data.invoiceSubcontractedFromLineItems =
            data.invoiceSubcontractedFromLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];

        return data;
    }

    public async getInvoice(id: string): Promise<InvoiceDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const data = response.data as InvoiceDetailModel;
        data.invoiceDate = moment(data.invoiceDate);
        data.dateProcessed = moment(data.dateProcessed);
        data.invoiceLineItems = data.invoiceLineItems.map(this.parseInvoiceListItemModel);
        data.invoiceBonusLineItems = data.invoiceBonusLineItems.map(this.parseInvoiceBonusLineItemModel);
        data.invoiceSubcontractedToLineItems =
            data.invoiceSubcontractedToLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];
        data.invoiceSubcontractedFromLineItems =
            data.invoiceSubcontractedFromLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];

        return data;
    }

    public async cancelInvoice(id: string): Promise<InvoiceDetailModel> {
        const response = await axios.post(`${this.apiUrl}/${id}/cancel`);
        const data = response.data as InvoiceDetailModel;
        data.invoiceDate = moment(data.invoiceDate);
        data.dateProcessed = moment(data.dateProcessed);
        data.invoiceLineItems = data.invoiceLineItems.map(this.parseInvoiceListItemModel);
        data.invoiceBonusLineItems = data.invoiceBonusLineItems.map(this.parseInvoiceBonusLineItemModel);
        data.invoiceSubcontractedToLineItems =
            data.invoiceSubcontractedToLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];
        data.invoiceSubcontractedFromLineItems =
            data.invoiceSubcontractedFromLineItems.map(this.parseInvoiceListItemModel) as TrainerInvoiceSubcontractedLineItem[];

        return data;
    }

    public async hasPoliceOrCourtAttributes(): Promise<boolean> {
        const response = await axios.get(`${this.apiUrl}/hasPoliceOrCourtAttributes`);
        const data = response.data as boolean;
        return data;
    }

    public async checkCanSubmitInvoice(): Promise<boolean> {
        const response = await axios.get(`${this.apiUrl}/canSubmitInvoice`);
        const data = response.data as boolean;
        return data;
    }

    public async submitInvoice(): Promise<string> {
        const response = await axios.post(`${this.apiUrl}`);
        const data = response.data as string;
        return data;
    }

    private parseListModel(model: InvoiceListModel): InvoiceListModel {
        return {
            ...model,
            invoiceDate: moment(model.invoiceDate),
            dateProcessed: moment(model.dateProcessed),
            dateSubmitted: moment(model.dateSubmitted),
        };
    }

    private parseInvoiceListItemModel(model: TrainerInvoiceLineItem): TrainerInvoiceLineItem {
        return {
            ...model,
            courseDate: moment(model.courseDate),
            courseTime: moment.duration(model.courseTime)
        };
    }

    private parseInvoiceBonusLineItemModel(model: TrainerInvoiceBonusLineItem): TrainerInvoiceBonusLineItem {
        return {
            ...model,
            date: moment(model.date)
        };
    }
}
