import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import moment from "moment";
import { CreateFieldPickerProps } from "../eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { toast } from "react-toastify";

interface FieldPickerProps<T> {
    showErrors: boolean;
    onChange: (value: T, date: moment.Moment, valid: boolean) => void;
    fieldName: string;
    fieldIsDuration?: boolean;
    CreateFieldPicker: (props: CreateFieldPickerProps<any>) => JSX.Element;
}

type FieldType = any

export const FieldAndDate: React.FC<FieldPickerProps<FieldType>> = ({ onChange, showErrors, fieldName,
    fieldIsDuration, CreateFieldPicker }) => {

    const [field, setField] = React.useState<FieldType>();
    const [date, setDate] = React.useState<moment.Moment>();
    const [valid, setValid] = React.useState<boolean>();

    function onAddField() {
        event.preventDefault();
        if ((fieldIsDuration && !field?.isValid()) ||
            (field.hours && field.minutes && field.hours() === 0 && field.minutes() === 0) ||
            !date?.isValid()) {
            toast.warning("Please provide valid data");
            return;
        }

        onChange(field, date, valid);
        setField(null);
    }

    function setFieldState(newValue: any, isValid: boolean)
    {
        setField(newValue);
        setValid(isValid);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    {CreateFieldPicker({ field, setField: setFieldState, showErrors })}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <MuiDateField
                        value={date}
                        onChange={setDate}
                        label={"Effective Date"}
                        showErrors={showErrors}
                        required
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button style={{ marginTop: "9%" }} onClick={onAddField}>Add {fieldName}</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
