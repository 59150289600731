import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useTextField } from "react-aria";
import { mergeClasses } from "src/CorporateBooking.App/src/helpers";
import { ErrorMessage } from "./ErrorMessage";

export type InputColor = "default" | "white" | "brandOffWhite";

export type InputProps = {
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    isRequired?: boolean;
    className?: string;
    isDisabled?: boolean;
    autoComplete?: string;
    color?: InputColor;
    name?: string;
    error?: string;
    onChange?: (value: string) => void;
    isReadOnly?: boolean;
    icon?: React.ReactElement;
    type?: string;
    isPartOfSelectList?: boolean;
    maxLength?: number;
}

export type BaseInputProps =
    InputProps &
    {
        classNames: {
        input: string;
        default: string;
        white: string;
        required: string;
        disabled: string;
        placeholder: string;
        hidden: string;
        asterisk: string;
        icon: string;
    };
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>((
    {   value,
        defaultValue,
        placeholder,
        isRequired,
        className,
        isDisabled = false,
        autoComplete,
        color = "default",
        name,
        error,
        onChange,
        isReadOnly,
        classNames,
        icon,
        type = "text",
        isPartOfSelectList = false,
        maxLength
    }, outerRef) => {
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(outerRef, () => innerRef.current!, []);

    const { inputProps } = useTextField({
        value,
        defaultValue,
        "aria-label": placeholder,
        placeholder,
        isRequired,
        autoComplete,
        onChange,
        isDisabled,
        isReadOnly,
        type,
        maxLength
    }, innerRef);

    const mergedClasses = mergeClasses(
        classNames.input,
        classNames[color],
        isRequired ? classNames.required : undefined,
        (isDisabled || isReadOnly) && !isPartOfSelectList ? classNames.disabled : undefined,
        className);

    return (<div className={mergedClasses}>
        <input className={classNames[color]} ref={innerRef} {...inputProps} name={name} />
        {icon && <div className={classNames.icon}>{icon}</div>}
        <div className={classNames.placeholder}>
            <span className={classNames.hidden}>{placeholder}</span>
            <span className={classNames.asterisk}>*</span>
        </div>
        <ErrorMessage error={error} />
    </div>);
});
