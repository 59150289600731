import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { EventInstanceEditModel, EventInstanceCreateModel } from "../model";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { optionsFromText } from "@common/crud/common";
import { DropdownWithoutOnChangeOnRender } from "@common/components/DropdownWithoutOnChangeOnRender";

export interface DigitalCourseEditProps {
    eventInstance: EventInstanceEditModel | EventInstanceCreateModel;
    fromCreate?: boolean;
    updateProperty: (prop: keyof EventInstanceEditModel | keyof EventInstanceCreateModel) => (value: any, valid: boolean) => void;
    updateDigitalCourseWithoutRegistration: (value: string, valid: boolean) => void;
}

export const DigitalCourseEdit: React.SFC<DigitalCourseEditProps> = (
    {
        eventInstance,
        updateProperty,
        updateDigitalCourseWithoutRegistration,
        fromCreate
    }
) => {
    const {
        eventInstanceDeliveryType,
        digitalCourseLink,
        digitalCourseAccount,
        digitalCoursePasswordTrainer,
        digitalCourseLinkTrainer,
        digitalCoursePasswordAttendee,
        digitalCourseMeetingId,
        digitalCourseWithoutRegistration
    } = eventInstance;
    const requiresRegistration = digitalCourseWithoutRegistration ? "No" : "Yes";

    return (
        <>
            {
                eventInstanceDeliveryType === DeliveryTypeEnum.Digital &&
                <>
                    <DropdownWithoutOnChangeOnRender
                        value={requiresRegistration}
                        label="Registration Required"
                        options={optionsFromText(["Yes", "No"])}
                        onChange={updateDigitalCourseWithoutRegistration}
                    />
                    {!fromCreate && (
                        <>
                            <Input.Text
                                label="Digital course link"
                                value={digitalCourseLink}
                                onChange={updateProperty("digitalCourseLink")}
                            />
                            <Input.Text
                                label="Digital course account"
                                value={digitalCourseAccount}
                                onChange={updateProperty("digitalCourseAccount")}
                            />
                            <Input.Text
                                label="Digital Course Password (Trainer)"
                                value={digitalCoursePasswordTrainer}
                                onChange={updateProperty("digitalCoursePasswordTrainer")}
                            />
                            <Input.Text
                                label="Digital Course Link (Trainer)"
                                value={digitalCourseLinkTrainer}
                                onChange={updateProperty("digitalCourseLinkTrainer")}
                            />
                            <Input.Text
                                label="Digital Course Password (Attendee)"
                                value={digitalCoursePasswordAttendee}
                                onChange={updateProperty("digitalCoursePasswordAttendee")}
                            />
                            <Input.Text
                                label="Digital Course Meeting ID"
                                value={digitalCourseMeetingId}
                                onChange={updateProperty("digitalCourseMeetingId")}
                            />
                        </>
                    )}
                </>
            }
        </>
    );
};
