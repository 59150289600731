import * as React from "react";
import {
    EventInstance,
    OtherTrainerCategoryDropDownOptions,
    OtherTrainerTypeCategory,
    OtherTrainerTypeCategoryEnum
} from "@common/crud/eventInstance/model";
import { Button, Confirm, Dropdown, DropdownProps, Grid } from "semantic-ui-react";
import { setOtherRoleForTrainer } from "@common/crud/eventInstance/actions";
import { useDispatch } from "react-redux";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { toast } from "react-toastify";

interface Props {
    trainerId: string;
    eventInstanceId?: string;
    otherTrainerTypeCategory: OtherTrainerTypeCategoryEnum;
    isFeeNonStandard?: boolean;
    getEditButtonIcon: (editMode: boolean) => string;
    groupId?: string;
    dayNumbers?: number[];
    updateGroupEventInstances?: (eventInstances: EventInstance[]) => void;
}

export const OtherRoleEdit: React.FC<Props> = ({ trainerId, eventInstanceId, otherTrainerTypeCategory, isFeeNonStandard, getEditButtonIcon,
    groupId, dayNumbers, updateGroupEventInstances }) => {
    const [isOtherRoleInEditMode, setIsOtherRoleInEditMode] = React.useState<boolean>(false);
    const [confirmationNeeded, setConfirmationNeeded] = React.useState<boolean>(false);
    const [otherRole, setOtherRole] = React.useState<OtherTrainerTypeCategoryEnum>(otherTrainerTypeCategory);
    const [saving, setSaving] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setOtherRole(otherTrainerTypeCategory);
    },[otherTrainerTypeCategory]);

    const cancelOtherRoleEdit = React.useCallback(() => setIsOtherRoleInEditMode(false), []);
    const toggleEdit = React.useCallback(() => setIsOtherRoleInEditMode(prevState => !prevState), []);

    const changeOtherRole = React.useCallback((_: any, data: DropdownProps) => {
        setOtherRole(data.value as OtherTrainerTypeCategoryEnum);
    }, []);

    const updateOtherRole = React.useCallback(async () => {
        setSaving(true);
        if (groupId && dayNumbers.length) {
            const api = new EventInstanceGroupApi();
            const result = await api.setOtherRoleForGroupTrainer(groupId, dayNumbers, trainerId, otherRole);
            updateGroupEventInstances(result);
            if (result) {
                toast.info("Trainer Other Role Category updated");
            }
        } else {
            await dispatch(setOtherRoleForTrainer(eventInstanceId, trainerId, otherRole));
        }
        setSaving(false);
        toggleEdit();
    }, [dayNumbers, dispatch, eventInstanceId, groupId, otherRole, toggleEdit, trainerId, updateGroupEventInstances]);

    const checkNeedForConfirmation = React.useCallback(() => {
        if (isFeeNonStandard && otherRole === otherTrainerTypeCategory) {
            setConfirmationNeeded(true);
        } else {
            updateOtherRole();
        }
    }, [otherRole, otherTrainerTypeCategory, updateOtherRole, isFeeNonStandard]);

    const confirmUpdate = React.useCallback(() => {
        setConfirmationNeeded(false);
        updateOtherRole();
    }, [updateOtherRole]);
    const cancelUpdate = React.useCallback(() => setConfirmationNeeded(false), []);

    const getOtherRoleEditElements = React.useCallback(() => {
        return (
            <>
                <Confirm
                    open={confirmationNeeded}
                    header="Warning"
                    content="Trainer currently has a non-standard fee. If you save then the trainer fee will be reverted back to the default fee for the role"
                    cancelButton={{ content: "Cancel", className: "cancel-action" }}
                    onConfirm={confirmUpdate}
                    onCancel={cancelUpdate}
                />
                { isOtherRoleInEditMode ?
                    <Dropdown
                        selection
                        value={otherRole}
                        onChange={changeOtherRole}
                        options={OtherTrainerCategoryDropDownOptions}
                        className="other-role-value"
                    /> : <span className="other-role-value">{OtherTrainerTypeCategory[otherRole]}</span> }
                <Button
                    icon={getEditButtonIcon(isOtherRoleInEditMode)}
                    size="mini"
                    disabled={saving}
                    loading={saving}
                    onClick={isOtherRoleInEditMode ? checkNeedForConfirmation : toggleEdit} />
                { isOtherRoleInEditMode &&
                    <Button
                        icon="delete"
                        color="red"
                        size="mini"
                        disabled={saving}
                        onClick={cancelOtherRoleEdit} />}
            </>
        );
    }, [cancelOtherRoleEdit, cancelUpdate, checkNeedForConfirmation, confirmUpdate, confirmationNeeded, getEditButtonIcon, isOtherRoleInEditMode, otherRole,
        saving, toggleEdit, changeOtherRole]);

    if (groupId && dayNumbers.length) {
        return (
            <Grid.Row className="vertical-spacing">
                <Grid.Column width={6} className="horizontal-spacing"><b>Other Role:</b></Grid.Column>
                <Grid.Column width={10}>
                    {getOtherRoleEditElements()}
                </Grid.Column>
            </Grid.Row>
        );
    }

    return (
        <tr>
            <th>Other Role:</th>
            <td>
                {getOtherRoleEditElements()}
            </td>
        </tr>
    );
};
