import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { Duration, Moment } from "moment";
import * as React from "react";
import { MultiDayTimingDetails } from "./MultiDayTimingDetails";
import { TimingDetails } from "./TimingDetails";
import { ProductCategoryEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";

interface DateAndTimeDetailsProps{
    groupDetail: EventInstanceGroupModel;
    workflowType: WorkflowTypeEnum;
    productCategory: ProductCategoryEnum;
    startDate: Moment;
    eventDuration: Duration;
    educationDuration: Duration;
    startTime: Duration;
    registrationEndTime: Duration;
    theoryAndPractical: boolean;
    showCombined: boolean;
    theoryDuration: Duration;
    theoryStartTime: Duration;
    practicalDuration: Duration;
    practicalStartTime: Duration;
}

export const DateAndTimeDetails: React.FC<DateAndTimeDetailsProps> = ( { workflowType, productCategory, startDate, eventDuration, educationDuration, startTime,
    registrationEndTime, groupDetail, theoryAndPractical, showCombined, theoryDuration, theoryStartTime, practicalDuration, practicalStartTime }) => {

    const fieldIsValidAndNonZero = (field: moment.Duration) => field && field.asMilliseconds?.() > 0 ;

    const startDateToString = (sDate: Moment) => sDate && sDate.isValid() ? sDate.format("LL") :  "Not Set";

    const startTimeToString = (sTime: Duration) => fieldIsValidAndNonZero(sTime) ? sTime.format(DateFormat.Time, { trim: false }) : "Not Set";

    const notSetDurationToString = (duration: Duration) => fieldIsValidAndNonZero(duration) ?
        duration.format?.(DateFormat.Time, { trim: false }) : "Not Set";

    return (<><h3 className="event-instance-title">Date/Time details</h3>
        {(groupDetail?.eventInstanceGroupItems?.length || 1) > 1
            ? <MultiDayTimingDetails eiGroupItems={groupDetail.eventInstanceGroupItems} />
            : <TimingDetails
                startDate={startDateToString(startDate)}
                startTime={startTimeToString(startTime)}
                eventDuration={notSetDurationToString(eventDuration)}
                educationDuration={notSetDurationToString(educationDuration)}
                theoryStartTime={theoryAndPractical ? startTimeToString(theoryStartTime) : null}
                theoryDuration={theoryAndPractical ? notSetDurationToString(theoryDuration) : null}
                practicalStartTime={theoryAndPractical ? startTimeToString(practicalStartTime) : null}
                practicalDuration={theoryAndPractical ? notSetDurationToString(practicalDuration) : null}
                registrationEndTime={notSetDurationToString(registrationEndTime)}
                theoryAndPractical={theoryAndPractical}
                showCombined={showCombined}
                hasEducationDuration={workflowType === WorkflowTypeEnum.CPC || productCategory === ProductCategoryEnum.OnRoadWithCpc}
            />
        }
    </>);
};

