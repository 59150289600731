/* eslint-disable max-lines */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Form, InputOnChangeData, DropdownProps, Popup, Checkbox, CheckboxProps } from "semantic-ui-react";
import { optionsFromObject } from "@common/crud/common";
import { ddrsEventTypesSelector, eventTypeWithWorkflowOptionsSelector } from "@common/crud/eventType/selectors";
import { FilterState } from "./trainer-list-filters";
import { DeliveryType } from "@common/crud/common/DeliveryTypeEnum";
import { policeOrganisationOptionsSelector } from "@common/crud/organisation/selectors";
import { push } from "redux-little-router";
import { debounce } from "lodash";
import * as QueryString from "query-string";
import axios from "axios";
import { toRouterQuery } from "@common/model";
import { unexpiredClassroomVenueOptionsSelector } from "@common/crud/venue/selectors";
import { trainerAttributeDefinitionOptionsSelector } from "@common/crud/trainerAttributeDefinition/selectors";
import { ConstructionWorkflowType, CorporateWorkflowType, PoliceAndCourtWorkflowType } from "@common/crud/eventType/model";
import { routerQuerySelector } from "@common/crud/common/selectors";
import { loadTrainersSuccess } from "../actions";
import { LicenseStatus, LicenseStatusEnum, TrainerListModel } from "../model";
import { TrainerApi } from "../trainerApi";
import { currentUserSelector } from "@common/auth";
import { CorporateHomeRole, PoliceAndDdrsAdminRole } from "@common/auth/model";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";

const policeAndDdrsWorkflows = Object.keys(PoliceAndCourtWorkflowType).map(k => +k).filter(x => x > 0);
const corpWorkflows = Object.keys(CorporateWorkflowType).map(k => +k).filter(x => x > 0);

export const TrainerListFilters: React.FC = () => {

    const dispatch = useDispatch();

    const [searchString, setSearchString] = React.useState("");
    const [filtersVisible, setFiltersVisible] = React.useState<boolean>(true);

    const filterQuery = useSelector(routerQuerySelector);
    const user = useSelector(currentUserSelector);
    const businessLineType = useSelector(businessLineTypeSelector);

    const filterState: FilterState = React.useMemo(() => {
        const hasPoliceAndDdrsRole = user.roles.includes(PoliceAndDdrsAdminRole);
        const hasCorpRole = user.roles.includes(CorporateHomeRole);
        let defaultWorkflows: number[] = [];
        if (hasPoliceAndDdrsRole && !hasCorpRole) {
            defaultWorkflows = policeAndDdrsWorkflows;
        } else if (!hasPoliceAndDdrsRole && hasCorpRole) {
            defaultWorkflows = corpWorkflows;
        }

        return {
            searchString: filterQuery.searchString ?? undefined,
            eventType: filterQuery.eventType ?? undefined,
            deliveryType: filterQuery.deliveryType ? Number(filterQuery.deliveryType) : undefined,
            homeArea: filterQuery.homeArea?.split(",")?.filter(Boolean) ?? [],
            optedInVenue: filterQuery.optedInVenue ?? undefined,
            attributes: filterQuery.attributes?.split(",")?.filter(Boolean) ?? [],
            licenseStatus: filterQuery.licenseStatus ? Number(filterQuery.licenseStatus) : LicenseStatusEnum.All,
            showExpired: filterQuery.showExpired === "true",
            workflows: filterQuery.workflows?.split(",").filter(Boolean).map(x => +x) ?? defaultWorkflows
        };
    }, [filterQuery, user]);

    const { eventType, deliveryType, homeArea, optedInVenue, attributes, licenseStatus, showExpired, workflows } = filterState;

    React.useEffect(() => {
        setSearchString(filterState.searchString ?? "");
    }, [filterState.searchString]);

    function onPropertyChanged(propertyName: keyof FilterState) {
        return function onInputChange(_: any, { value }: InputOnChangeData | DropdownProps) {
            const updatedState = {
                ...filterState,
                [propertyName]: value
            };

            if (propertyName === "eventType" && shouldDisableLicenseStatus(updatedState)) {
                updatedState.licenseStatus = LicenseStatusEnum.All;
            };

            if (propertyName === "homeArea" && Array.isArray(value) && value.includes("")) {
                updatedState.homeArea = [];
            }

            onFilterStateChange(updatedState);
        };
    }

    function onToggleChanged(propertyName: keyof FilterState) {
        return function onChange(_: any, { checked }: CheckboxProps) {
            const updatedState = {
                ...filterState,
                [propertyName]: checked
            };

            onFilterStateChange(updatedState);
        };
    }

    const debouncedOnFilterStateChange = React.useCallback(debounce(query =>
        onFilterStateChange({
            ...filterState,
            searchString: query
        }), 300), []
    );

    function onSearchChange(_: any, data: InputOnChangeData) {
        setSearchString(data.value);
        debouncedOnFilterStateChange(data.value);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        const query = QueryString.stringify(filterState);
        const api = new TrainerApi();

        axios.get(`/api/trainer?${query}`, {
            signal: abortController.signal,
        }).then((response) => {
            const data = response.data as TrainerListModel[];
            dispatch(loadTrainersSuccess(data.map(api.parseModel)));
            return;
        }).catch((error) => {
            if (abortController.signal.aborted) {
                // need this to avoid throwing in the UI for aborted calls
            } else {
                throw (error);
            }
        });

        return () => {
            abortController.abort();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState]);

    async function onFilterStateChange(changedFilterState: FilterState) {
        dispatch(push({ pathname: undefined, query: toRouterQuery(changedFilterState) }));
    }

    function onClearFilters() {
        dispatch(push({ pathname: undefined, query: undefined }));
    }

    function onToggleFiltersVisibility() {
        setFiltersVisible(prevValue => !prevValue);
    }

    const ddrsEventTypes = useSelector(ddrsEventTypesSelector);
    const deliveryTypeOptions = optionsFromObject(DeliveryType, "Any");
    const venueOptions = useSelector(unexpiredClassroomVenueOptionsSelector);
    const attributeOptions = useSelector(trainerAttributeDefinitionOptionsSelector);
    const organisationOptions = [{ text: "Any Home Area", value: "" }, ...useSelector(policeOrganisationOptionsSelector)];
    const licenseStatusOptions = optionsFromObject(LicenseStatus, "All", 0);
    const workflowsBase = businessLineType === BusinessLineType.Corporate
        ? CorporateWorkflowType
        : businessLineType === BusinessLineType.Construction
            ? ConstructionWorkflowType
            : PoliceAndCourtWorkflowType;
    const workflowOptions = optionsFromObject(workflowsBase);
    const eventTypeOptions = useSelector(eventTypeWithWorkflowOptionsSelector((workflows && workflows.length > 0)
        ? workflows
        : Object.keys(workflowsBase).map(Number)));

    const shouldDisableLicenseStatus = React.useCallback((newFilterState: FilterState) => {
        return !newFilterState?.eventType || isDdrsEventType(newFilterState?.eventType) || !newFilterState?.deliveryType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterState]);

    const isDdrsEventType = React.useCallback((eventTypeId: string) => {
        const ddrsEventTypeIds = ddrsEventTypes?.map(e => e.id);
        return eventTypeId && ddrsEventTypeIds.includes(eventTypeId);
    }, [ddrsEventTypes]);

    return (
        <>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="right" verticalAlign="bottom">
                        <Button size="large" content="FILTERS" onClick={onToggleFiltersVisibility} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {filtersVisible && (
                <Grid stackable className="filter-grid">
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Input
                                action={<Button icon="search" />}
                                placeholder="Search"
                                value={searchString}
                                onChange={onSearchChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} verticalAlign="bottom">
                            <Form.Dropdown
                                multiple
                                selection
                                label="Course Details"
                                placeholder="Workflow"
                                onChange={onPropertyChanged("workflows")}
                                value={workflows}
                                options={workflowOptions}
                                disabled={!!eventType}
                            />
                        </Grid.Column>
                        <Grid.Column width={5} verticalAlign="bottom">
                            <Form.Dropdown
                                selection
                                placeholder="Scheme"
                                key={eventType}
                                value={eventType}
                                options={eventTypeOptions}
                                className="event-type-dropdown"
                                onChange={onPropertyChanged("eventType")}
                            />
                        </Grid.Column>
                        <Grid.Column width={5} verticalAlign="bottom">
                            <Form.Dropdown
                                selection
                                key={deliveryType}
                                placeholder="Delivery Type"
                                options={deliveryTypeOptions}
                                value={deliveryType}
                                onChange={onPropertyChanged("deliveryType")}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {businessLineType === BusinessLineType.PoliceAndCourt && (
                        <Grid.Row>
                            {!isDdrsEventType(eventType) &&
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <Form.Dropdown
                                        search
                                        selection
                                        multiple
                                        label="Area"
                                        placeholder="Home Area"
                                        options={organisationOptions}
                                        value={homeArea ?? []}
                                        onChange={onPropertyChanged("homeArea")}
                                    />
                                </Grid.Column>
                            }
                            <Grid.Column width={8} verticalAlign="bottom">
                                <Form.Dropdown
                                    label={isDdrsEventType(eventType) ? "Area" : undefined}
                                    search
                                    selection
                                    key={optedInVenue}
                                    placeholder="Opted In Venues"
                                    options={venueOptions}
                                    value={optedInVenue}
                                    onChange={onPropertyChanged("optedInVenue")}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Column width={businessLineType === BusinessLineType.PoliceAndCourt ? 16 : 8} verticalAlign="bottom">
                            <Form.Dropdown
                                label="Skills"
                                placeholder="Attributes"
                                search
                                multiple
                                selection
                                options={attributeOptions}
                                value={attributes}
                                onChange={onPropertyChanged("attributes")}
                            />
                        </Grid.Column>
                        {businessLineType === BusinessLineType.PoliceAndCourt && (
                            <Grid.Column width={8} verticalAlign="bottom">
                                {shouldDisableLicenseStatus(filterState) &&
                                    <Popup content="Please select DORS scheme and delivery type" trigger={
                                        <span>
                                            <Form.Dropdown
                                                label="Licence Status"
                                                selection
                                                options={licenseStatusOptions}
                                                value={licenseStatus}
                                                onChange={onPropertyChanged("licenseStatus")}
                                                disabled={shouldDisableLicenseStatus(filterState)}
                                            />
                                        </span>
                                    }
                                    on="hover" />}
                                {!shouldDisableLicenseStatus(filterState) &&
                                    <Form.Dropdown
                                        label="Licence Status"
                                        selection
                                        options={licenseStatusOptions}
                                        value={licenseStatus}
                                        disabled={shouldDisableLicenseStatus(filterState)}
                                        onChange={onPropertyChanged("licenseStatus")}
                                    />}
                            </Grid.Column>
                        )}
                    </Grid.Row>
                    <Grid.Row width={16}>
                        <Grid.Column width={3}>
                            <Checkbox
                                label={showExpired ? "Hide Expired" : "Show Expired"}
                                checked={showExpired}
                                onChange={onToggleChanged("showExpired")}
                                toggle
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="display-right">
                            <a className={"issue-button filters"} onClick={onClearFilters}>Clear filters</a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    );
};
