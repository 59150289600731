import { StartPaymentProcessRequest, PaymentResponse } from "@common/payments/model";

import * as actions from "./actiontypes";
import { PaymentAction } from "./actions";

const initialState: StartPaymentProcessRequest = {
    id: null,
    orderId: null,
    amount: null,
    correlationId: null,
    relatedObjectId: null,
    startPaymentProcessMultiPartModels: [],
    eventInstanceId: null,
    stripePublishKey: null
};

const stripeInitialState: PaymentResponse = {
    validRequest: null,
    requiresAction: null,
    paymentIntentClientSecret: null,
    paymentAuthorised: null,
    noAvailability: null,
    reservationHasExpired: null,
    paymentAlreadyReceived: null,
    duplicateRequest: null,
};

export function startPaymentProcess(state: StartPaymentProcessRequest = initialState, action: PaymentAction): StartPaymentProcessRequest {
    switch (action.type) {
        case actions.LOAD_PAYMENT_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export function stripePayment(state: PaymentResponse = stripeInitialState, action: PaymentAction): PaymentResponse {
    switch (action.type) {
        case actions.MAKE_PAYMENT_SUCCESS:
            return { ...state, ...action.payload.paymentResponse };
        case actions.MAKE_PAYMENT_FAILURE:
            if (action.payload.paymentResponse && action.payload.paymentResponse.duplicateRequest)
            {
                // We ignore duplicate request messages responses a we don't want to show any message for these
                return state;
            }
            else
            {
                return { ...state, ...action.payload.paymentResponse };
            }
        default:
            return state;
    }
}
