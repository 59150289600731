import axios from "axios";
import { Dictionary } from "lodash";

import { AppSettings } from "./model";

export type AdHocCommsTemplate<T extends string | number = string | number> = {
    text: string;
    value: T;
};

export type EmailTemplate = AdHocCommsTemplate<number>;
export type SmsTemplate = AdHocCommsTemplate<string>

export class AppSettingsApi {
    private apiUrl: string;

    private convertToEmailTemplate = (data: Dictionary<string>): EmailTemplate[] => Object
        .keys(data)
        .map(key => ({
            text: key,
            value: Number(data[key]),
        }));

    private convertToSmsTemplate = (data: Dictionary<string>): SmsTemplate[] => Object
        .keys(data)
        .map(key => ({
            text: key,
            value: data[key],
        }));

    constructor(orgId?: string) {
        this.apiUrl = orgId ? `/api/organisations/${orgId}/appsettings` : "/api/appsettings";
    }

    public async load(relatedOrganisationId?: string): Promise<AppSettings> {
        let url = this.apiUrl;
        if (relatedOrganisationId) {
            url = `${url}?relatedOrganisationId=${relatedOrganisationId}`;
        }
        const response = await axios.get(url);
        return response.data;
    }

    public async getAdHocPostmarkTemplates(): Promise<EmailTemplate[]> {
        const { data } = await axios.get(`${this.apiUrl}/get-adhoc-postmark-templates`);
        return this.convertToEmailTemplate(data);
    }

    public async getAdHocTrainerPostmarkTemplates(): Promise<EmailTemplate[]> {
        const { data } = await (await axios.get<Dictionary<string>>(`${this.apiUrl}/get-adhoc-trainer-postmark-templates`));
        return this.convertToEmailTemplate(data);
    }

    public async getAdHocSmsTemplates(): Promise<SmsTemplate[]> {
        const { data } = await axios.get(`${this.apiUrl}/get-adhoc-sms-templates`);
        return this.convertToSmsTemplate(data);
    }

    public async getAdHocCorpEmailTemplates(): Promise<EmailTemplate[]> {
        const { data } = await axios.get(`${this.apiUrl}/get-adhoc-corp-email-templates`);
        return this.convertToEmailTemplate(data);
    }
}
