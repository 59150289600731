/* tslint:disable:no-consecutive-blank-lines */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { Form } from "semantic-ui-react";
import { FormBaseComponent, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { AsyncDispatch } from "@common/redux-helpers";

import { TrainerAttributeDefinitionCreateModel, AppState } from "../model";
import { basePathSelector } from "../selectors";
import { createTrainerAttributeDefinition } from "../actions";

import { CreateModal } from "./CreateModal";
import { TrainerAttributeType } from "@common/crud/trainerAttributeDefinition";

export interface CreateProps {
    open: boolean;
}

export interface DispatchProps extends SaveDispatchProps<TrainerAttributeDefinitionCreateModel> {
    close: () => void;
}

export class CreateForm extends FormBaseComponent<TrainerAttributeDefinitionCreateModel, CreateProps> {

    constructor(props: CreateProps & SaveDispatchProps<TrainerAttributeDefinitionCreateModel>) {
        super(props);
        this.state = {
            values: {
                id: "",
                name: "",
                attributeType: 0,
            },
            valid: {}
        };
    }

    public render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={this.state.values.name}
                    label="Name"
                    required
                    showErrors={this.state.showErrors}
                    onChange={(value, valid) => this.updateProperty("name", value, valid)}
                />
                <Input.Text
                    value={TrainerAttributeType[this.state.values.attributeType]}
                    label="Attribute type"
                    readOnly
                />
            </Form>
        );
    }

    public submit = () => this.handleSubmit({ preventDefault: (): void => undefined } as any);
}

function mapStateToProps(state: AppState) {
    return {
        open: state.router.pathname.endsWith("/create"),
        basePath: basePathSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        dispatchSave: (trainerAttribute: TrainerAttributeDefinitionCreateModel, basePath: string) =>
            dispatch(createTrainerAttributeDefinition(trainerAttribute, basePath)),
        dispatchClose: (basePath: string) => dispatch(push(basePath))
    };
}

function mergeProps(propsFromState: any, propsFromDispatch: any): CreateProps & DispatchProps {
    return {
        open: propsFromState.open,
        save: (trainerAttribute: TrainerAttributeDefinitionCreateModel) => propsFromDispatch.dispatchSave(trainerAttribute, propsFromState.basePath),
        close: () => propsFromDispatch.dispatchClose(propsFromState.basePath)
    };
}

export const Create = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateModal);
