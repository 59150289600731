import { Grid, Form, DropdownProps, Button, Divider, CheckboxProps } from "semantic-ui-react";
import * as React from "react";
import { useState } from "react";
import { SearchOptions, ShowOrdersBy } from "../model";
import { useDispatch, useSelector } from "react-redux";
import { constructionEventTypeOptionsSelectorWithAny, corporateEventTypeOptionsForWorkflowSelector } from "@common/crud/eventType/selectors";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { MuiDateField } from "@common/components/MuiDateField";
import { getDateInputFormat } from "@common/dateFormating";
import moment from "moment";
import { nameof } from "@common/helpers/nameof";
import { constructionOrganisationOptionsSelectorWithAny, corporateOrganisationOptionsSelectorWithAny } from "@common/crud/organisation/selectors";
import { BusinessLineType, businessLineTypeSelector } from "@common/redux-helpers";
import { loadAllOrganisationsForBusineessLineType } from "@common/crud/organisation/actions";

interface EventInstancesFiltersProps {
    corporateOrganisationId?: string;
    searchOptions: SearchOptions;
    onOptionsChanged: (query: SearchOptions) => void;
    withoutToggles?: boolean;
}

export const EventInstancesFilters: React.FC<EventInstancesFiltersProps> = ({ corporateOrganisationId, searchOptions, withoutToggles, onOptionsChanged }) => {
    const [stateOptions, setStateOptions] = useState<SearchOptions>(searchOptions);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

    const businessLineType = useSelector(businessLineTypeSelector);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!corporateOrganisationId) {
            dispatch(loadAllOrganisationsForBusineessLineType(businessLineType));
        }
    }, [businessLineType, corporateOrganisationId, dispatch]);

    const organisationOptions = useSelector(businessLineType === BusinessLineType.Corporate
        ? corporateOrganisationOptionsSelectorWithAny
        : constructionOrganisationOptionsSelectorWithAny);

    const eventTypeOptions = useSelector(businessLineType === BusinessLineType.Corporate
        ? corporateEventTypeOptionsForWorkflowSelector(WorkflowTypeEnum.CPC)
        : constructionEventTypeOptionsSelectorWithAny);

    const dateInputFormat = React.useMemo(() => getDateInputFormat(), []);

    const onSubmit = React.useCallback(() => {
        const options = {
            ...searchOptions,
            ...stateOptions,
        };
        setStateOptions(options);
        onOptionsChanged(options);
    }, [searchOptions, stateOptions, onOptionsChanged]);

    const onPropertyChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            const prevValue = stateOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
                onOptionsChanged(options);
            }
        };
    }, [onOptionsChanged, stateOptions]);

    const onInputChanged = React.useCallback((prop: keyof (SearchOptions)) => {
        return (value: any) => {
            if (moment.isMoment(value) && !value.isValid) {
                return;
            }

            const prevValue = stateOptions[prop];

            if (prevValue !== value) {
                const options = { ...stateOptions, [prop]: value, filtersCleared: false };
                setStateOptions(options);
            }
        };
    }, [stateOptions]);

    const onMultiDropdownChange = React.useCallback((prop: keyof SearchOptions) => (_: any, { value }: DropdownProps) => {
        const options = { ...stateOptions, [prop]: value };
        // deselect all eventTypes if "Any Product" is selected
        if (prop === nameof<SearchOptions>("eventTypeIds") && Array.isArray(value) && value.includes("")) {
            options.eventTypeIds = [];
        }
        if (prop === nameof<SearchOptions>("corporateOrganisationIds") && Array.isArray(value) && value.includes("")) {
            options.corporateOrganisationIds = [];
        }
        setStateOptions(options);
        onOptionsChanged(options);
    }, [onOptionsChanged, stateOptions]);

    const onCheckboxChanged = React.useCallback((prop: keyof (SearchOptions)) =>
        (_: any, data: CheckboxProps) => onPropertyChanged(prop)(data.checked),[onPropertyChanged]);

    const onClearFilters = React.useCallback(() => {
        const clearedSearchOptions = {
            ...Object.keys(searchOptions)
                .reduce((prevSearchOptions: SearchOptions, key: keyof SearchOptions) => {
                    const defaultValue: string[] | string | boolean =
                        Array.isArray(searchOptions[key]) ? [] : undefined;

                    return {
                        ...prevSearchOptions,
                        [key]: defaultValue
                    };
                }, {}), filtersCleared: true
        };
        clearedSearchOptions.showOrdersBy = ShowOrdersBy.EventInstance;

        setStateOptions(clearedSearchOptions);
        onOptionsChanged(clearedSearchOptions);
    }, [onOptionsChanged, searchOptions]);

    const onToggleFiltersVisibility = React.useCallback(() => {
        setFiltersVisible(prevValue => !prevValue);
    }, []);

    const showFiltersDisplay = filtersVisible ? "Hide Filters" : "Show Filters";
    const showCancelledLabel = React.useMemo(() => stateOptions?.showCancelled ? "yes" : "no", [stateOptions]);
    const showCompletedLabel = React.useMemo(() => stateOptions?.showCompleted ? "yes" : "no", [stateOptions]);

    return (
        <>
            <Grid stackable className="full-width">
                <Grid.Row>
                    <Grid.Column width={16} verticalAlign="bottom">
                        <a className={"issue-button float-left"} onClick={onToggleFiltersVisibility}>{showFiltersDisplay}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {filtersVisible &&
                <>
                    <Grid className="filter-grid full-width" stackable>
                        <Grid.Row>
                            {!corporateOrganisationId && (
                                <Grid.Column width={8}>
                                    <Form.Dropdown
                                        label="Organisations"
                                        floating
                                        multiple
                                        selection
                                        placeholder="Organisations"
                                        value={stateOptions.corporateOrganisationIds ?? []}
                                        options={organisationOptions}
                                        onChange={onMultiDropdownChange("corporateOrganisationIds")}
                                        search
                                    />
                                </Grid.Column>
                            )}
                            <Grid.Column width={corporateOrganisationId ? 16 : 8}>
                                <Form.Dropdown
                                    label="Product"
                                    floating
                                    multiple
                                    selection
                                    placeholder="Product"
                                    value={stateOptions.eventTypeIds ?? []}
                                    options={eventTypeOptions}
                                    onChange={onMultiDropdownChange("eventTypeIds")}
                                    search
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <MuiDateField
                                    label="Date from"
                                    placeholder={dateInputFormat}
                                    value={stateOptions?.fromDate}
                                    onChange={onInputChanged("fromDate")}
                                    key={"fromDate-" + stateOptions?.fromDate?.toString()}
                                    maxDate={stateOptions?.toDate}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <MuiDateField
                                    label="Date to"
                                    placeholder={dateInputFormat}
                                    value={stateOptions?.toDate}
                                    onChange={onInputChanged("toDate")}
                                    key={"toDate-" + stateOptions?.toDate?.toString()}
                                    minDate={stateOptions?.fromDate}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column width={3}>
                                {!withoutToggles && (
                                    <>
                                        <label>Show Cancelled</label>
                                        <Form.Checkbox
                                            toggle
                                            label={showCancelledLabel}
                                            checked={stateOptions.showCancelled}
                                            onChange={onCheckboxChanged("showCancelled")}
                                        />
                                    </>
                                )}
                            </Grid.Column>
                            <Grid.Column width={3}>
                                {!withoutToggles && (
                                    <>
                                        <label>Show Completed</label>
                                        <Form.Checkbox
                                            toggle
                                            label={showCompletedLabel}
                                            checked={stateOptions.showCompleted}
                                            onChange={onCheckboxChanged("showCompleted")}
                                        />
                                    </>
                                )}
                            </Grid.Column>
                            <Grid.Column width={4} />
                            <Grid.Column width={4} >
                                <a className={"issue-button filters"} onClick={onClearFilters}>Clear filters</a>
                            </Grid.Column>
                            <Grid.Column width={2} textAlign="right">
                                <Button content="APPLY" onClick={onSubmit} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            }
        </>
    );
};
