import * as React from "react";
import { useTranslation } from "react-i18next";
import { DateFormat, dateString } from "@common/crud/common/DateTimeFormats";
import { Button, Grid, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Booking } from "@booking/bookings/models";
import { EventInstance } from "@common/crud/eventInstance";
import { push } from "redux-little-router";

interface ChosenCourseBannerProps {
    booking: Booking;
    eventInstance: EventInstance;
}

export const ChosenCourseBanner: React.FC<ChosenCourseBannerProps> = ({ booking, eventInstance }) => {
    const [t] = useTranslation("Landing");
    const dispatch = useDispatch();

    function onChooseAnotherCourse() {
        dispatch(push(`/bookings/${booking.id}`));
    }

    const venueName = eventInstance?.venueName;
    const date = dateString(eventInstance?.startDate, DateFormat.LongNoYear);
    const startTime = eventInstance && eventInstance?.startTime?.format(DateFormat.Time, { trim: false });

    return (
        <Message className="rebook-message">
            <Message.Header>{t("SELECTED_COURSE")}</Message.Header>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <p>
                            You are reserving {venueName} on {date} at {startTime},
                            click button to select a new course
                        </p>
                    </Grid.Column>
                    <Grid.Column width={6} className="right-align">
                        <Button onClick={onChooseAnotherCourse}>
                            Choose another course
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Message>
    );
};
