import { TtcClientAdvisorRole, TtcPlannerRole } from "@common/auth/model";
import * as React from "react";
import { Authorize } from "reauthorize";
import { Attendee } from "../..";

export interface FreeRebookingMessageProps {
    attendee: Attendee;
    onLinkClick: () => void;
}

export const FreeRebookingMessage = ({ attendee, onLinkClick }: FreeRebookingMessageProps) => {
    return (
        <p>
            {`A free rebooking ${attendee.freeRebookingAddedByCa ? "was" : "was not"} added upon
            ${attendee.isBookingCanceled ? " cancellation" : " hiding the course"}. `}
            <Authorize authorize={[TtcClientAdvisorRole, TtcPlannerRole]}>
                <a onClick={onLinkClick} className="cursor-pointer">
                    {`Click here to ${attendee.freeRebookingAddedByCa ? "remove the" : "add a"} free rebooking.`}
                </a>
            </Authorize>
        </p>
    );
};
