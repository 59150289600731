import * as React from "react";
import { Icon, Label, Menu, Tab } from "semantic-ui-react";
import { HistoryVersion } from "@common/history/model";
import { CurrentUserContext } from "@common/current-user-context";
import { PoliceRole } from "@common/auth/model";
import { CoreDetailProps } from "../Detail";
import { DetailsTab } from "./DetailsTab";
import { useDispatch, useSelector } from "react-redux";
import { getAttendeeHistory } from "../../actions";
import { HistoryTab } from "@common/history/components/HistoryTab";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { FileDetailsTab } from "@common/crud/drinkDriveOffenders/components/details/FileDetailsTab";
import { DrinkDriveOffenderApi } from "@common/crud/drinkDriveOffenders";
import { EventTypeCategory } from "../../model";

enum TabNames {
    Details = "Details",
    Files = "Files",
    HistoryV1 = "Old Client Journey",
    HistoryV2 = "Client Journey",
    Notes = "Notes"
}

const CoreDetails: React.FC<CoreDetailProps> = ({ attendee, internalAppSettings, eventTypes }) => {
    const currentUser = React.useContext(CurrentUserContext);
    const dispatch = useDispatch();
    const app = useSelector(appSelector);
    const showDetailsDefault = app === Apps.Admin;
    const [documentsCount, setDocumentsCount] = React.useState<number>(0);

    const getHistory = React.useCallback(() => dispatch(getAttendeeHistory(attendee.id, attendee.correlationId)), [attendee, dispatch]);

    const hasActivePendingNotes = attendee?.attendeeHistoryRecordsV2 &&
        attendee.attendeeHistoryRecordsV2?.some(ahr => ahr.isPendingNote === true && ahr.isPendingNoteCompleted === false);

    React.useEffect(() => {
        async function loadAttachedDocuments() {
            const api = new DrinkDriveOffenderApi();
            const response = await api.getAttachedDocumentsCount(attendee.correlationId);
            setDocumentsCount(response);
        }

        if (attendee?.correlationId && attendee?.eventTypeCategory === EventTypeCategory.Ddrs) {
            loadAttachedDocuments();
        }
    }, [attendee?.correlationId, attendee?.eventTypeCategory]);

    const panes =
        [
            { menuItem: TabNames.Details, render: () => <DetailsTab attendee={attendee} internalAppSettings={internalAppSettings} eventTypes={eventTypes} /> },
            attendee.eventTypeCategory === EventTypeCategory.Ddrs ? {
                menuItem: (
                    <Menu.Item>
                        {TabNames.Files} <Label circular content={documentsCount} />
                    </Menu.Item>
                ),
                render: () => (
                    <FileDetailsTab
                        bookingId={attendee.correlationId}
                        downloadUrl="/api/drinkDriveOffender/download"
                        openUrl="/api/drinkDriveOffender/open"
                    />
                )
            } : null,
            attendee.attendeeHistoryRecords?.length && currentUser.roles.includes(PoliceRole) === false ?
                {
                    menuItem: TabNames.HistoryV1,
                    render: () => (
                        <HistoryTab
                            showDetailsDefault={showDetailsDefault}
                            historyRecords={attendee.attendeeHistoryRecords || []}
                            correlationId={attendee.correlationId}
                            historyVersion={HistoryVersion.V1}
                            relatedTo={TaskRelationEnum.Attendee}
                            eventTypeCategory={attendee.eventTypeCategory}
                        />
                    )
                } : null,
            {
                menuItem: (
                    <Menu.Item onClick={getHistory} key={TabNames.HistoryV2}>
                        {TabNames.HistoryV2} {hasActivePendingNotes && <Icon className="flag alternate vertical icon" color={"red"} />}
                    </Menu.Item>
                ),
                render: () => (
                    <HistoryTab
                        showDetailsDefault={showDetailsDefault}
                        historyRecords={attendee.attendeeHistoryRecordsV2 || []}
                        correlationId={attendee.correlationId}
                        historyVersion={HistoryVersion.V2}
                        relatedTo={TaskRelationEnum.Attendee}
                        eventTypeCategory={attendee.eventTypeCategory}
                    />
                )
            },
        ];

    return (
        <Tab panes={panes.filter(Boolean)} />
    );
};

export { CoreDetails };
