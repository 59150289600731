import { languageSelector } from "@booking/selectors";
import { AppSettings } from "@common/appSettings/model";
import { appSettingsSelector } from "@common/appSettings/selectors";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { EventInstance } from "@common/crud/eventInstance";
import { LanguageSettings } from "@common/language/model";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonProps, Modal } from "semantic-ui-react";
import classes from "./FlexiPayModal.module.scss";
import { IconButton } from "@booking/common/IconButton";
import { mergeClasses } from "src/CorporateBooking.App/src/helpers";
import { Trans } from "react-i18next";
import { getExternalLinksForGivenLanguage } from "@booking/bookings/components/TermsAndFaqLinks";
import { policeOrganisationByDorsId } from "@common/crud/organisation/selectors";
import { eventInstanceSelector } from "@common/crud/eventInstance/selectors";
import { bookingSelector } from "@booking/landing/selectors";
import { useTypeSafeTranslation } from "@booking/i18n/resources/useTypeSafeTranslation";

export interface FlexiPayModalProps {
    open: boolean;
    eventInstance: EventInstance;
    flexiPayFee: number;
    onClose: () => void;
    continueToPayment: (flexiPayPurchased: boolean) => void;
}

export const FlexiPayModal = (props: FlexiPayModalProps) => {
    const { open, eventInstance, flexiPayFee, onClose, continueToPayment } = props;
    const currentLanguage: LanguageSettings = useSelector(languageSelector);
    const appSettings: AppSettings = useSelector(appSettingsSelector);
    const flexiPayExplanation = currentLanguage.current === "cy" ?
        appSettings.bookingAppSettings.flexiPayExplanationCY : appSettings.bookingAppSettings.flexiPayExplanationEN;
    const standardBookingExplanation = currentLanguage.current === "cy" ?
        appSettings.bookingAppSettings.standardBookingExplanationCY : appSettings.bookingAppSettings.standardBookingExplanationEN;

    function onButtonClick(_event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) {
        const flexiPayPurchased = (data.content as string).includes("Choose Flexi-Booking");
        continueToPayment(flexiPayPurchased);
    }

    const standardBookingPriceText = eventInstance?.price && `£${eventInstance.price.toFixed(2)}`;
    const flexiPayTotalPriceText = eventInstance?.price && flexiPayFee && `£${(eventInstance.price + flexiPayFee).toFixed(2)}`;
    const flexiPayFeeText = flexiPayFee && `£${flexiPayFee.toFixed(2)}`;
    const t = useTypeSafeTranslation("FlexiPayModal");

    return (
        <>
            <Modal className={classes.flexiPayModal} open={open} onClose={onClose}>
                <IconButton className={classes.closeButton} icon="close-bold" onPress={onClose} />
                <Modal.Header className={classes.modalHeader}>
                    {t("CHOOSE_BOOKING_TYPE")}
                </Modal.Header>
                <div className={classes.horizontalDividerContainer}>
                    <div className={classes.horizontalDivider} />
                </div>
                <Modal.Content>
                    <div className={classes.bookingTypeCardContainer}>
                        <BookingTypeCard
                            priceHeader={
                                <PriceHeader
                                    title={t("STANDARD_BOOKING")}
                                    feeText={standardBookingPriceText} />
                            }
                            explanation={standardBookingExplanation}
                            chooseBookingTypeActionButton={<Button onClick={onButtonClick} content={t("CHOOSE_STANDARD")} />}
                        />
                        <BookingTypeCard
                            priceHeader={
                                <PriceHeader
                                    title={t("FLEXI_BOOKING")}
                                    feeText={flexiPayTotalPriceText}
                                    priceBreakdown={`(${standardBookingPriceText} + ${flexiPayFeeText} ${t("FLEXI_FEE")})`} />
                            }
                            explanation={flexiPayExplanation}
                            chooseBookingTypeActionButton={<Button onClick={onButtonClick} content={t("CHOOSE_FLEXI")} />}
                            isMostPopular
                        />

                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};

function PriceHeader({ title, feeText, priceBreakdown }: {title: string; feeText: string; priceBreakdown?: string}) {
    return (<div className={classes.priceHeader}>
        <h3>{title}</h3>
        <div className={classes.price}>{feeText}</div>
        {priceBreakdown && <div className={classes.priceBreakdown}>{priceBreakdown}</div>}
    </div>);

}
function BookingTypeCard({ priceHeader, explanation, chooseBookingTypeActionButton, isMostPopular = false  }: {
    priceHeader: React.ReactChild;
    explanation: string;
    isMostPopular?: boolean;
    chooseBookingTypeActionButton: React.ReactChild;
}) {
    const className = mergeClasses(classes.bookingTypeCard, isMostPopular? classes.mostPopular : undefined);
    const booking = useSelector(bookingSelector);
    const eventInstance = useSelector(eventInstanceSelector);
    const organisation = useSelector(policeOrganisationByDorsId(booking.forceId));
    const links = getExternalLinksForGivenLanguage(eventInstance, organisation);
    const t = useTypeSafeTranslation("FlexiPayModal");

    return (<div className={className}>
        {isMostPopular && <div className={classes.mostPopularText}>{t("MOST_POPULAR")}</div>}
        {priceHeader}
        <div className={classes.explanation}>
            <HtmlRenderer value={explanation} />
            <br />
            <div>
                <Trans i18nKey="FlexiPayModal:TERMS_AND_CONDITIONS_TEXT">
                    <a className={classes.tAndCLink} href={links.termsAndConditionsLink} target="_blank" rel="noreferrer">See T&amp;Cs</a>for more information
                </Trans>
            </div>
        </div>
        <div className={classes.bookingTypeButtonContainer}>
            {chooseBookingTypeActionButton}
        </div>
    </div>);
}
