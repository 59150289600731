import { createSelector } from "reselect";

import { TrainerAttributeDefinition, TrainerAttributeType, AppState } from "./model";

import { SearchOptions } from "./model";

export const routeIdSelector = (state: AppState) => {
    return state.router.params.trainerAttributeDefinitionId;
};

export type SelectorState = AppState;

export const trainerAttributeDefinitionsSelector = (state: AppState) => state.trainerAttributeDefinitions;

export const trainerAttributeDefinitionSelector = createSelector(
    trainerAttributeDefinitionsSelector,
    routeIdSelector,
    (trainerAttributeDefinitions: TrainerAttributeDefinition[], id: string) => {
        return trainerAttributeDefinitions.filter(c => c.id === id)[0] || {} as TrainerAttributeDefinition;
    }
);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("trainerAttributeDefinitions") + "trainerAttributeDefinitions".length);

export const trainerAttributeDefinitionSearchOptionsSelector = (state: AppState): SearchOptions => {
    const query = state.router.query;

    const { searchString, eventTypes, attributeType } = query;
    return ({
        searchString: searchString ?? undefined,
        eventTypes: eventTypes?.split(",")?.filter(Boolean) ?? [],
        attributeType: attributeType ? Number(attributeType) : undefined,
    });
};

export const trainerAttributeDefinitionOptionsSelector = createSelector(
    trainerAttributeDefinitionsSelector,
    (attributeDefinitions: TrainerAttributeDefinition[]) => attributeDefinitions.map(a => ({ text: a.name, value: a.id, key: a.id }))
);

export const attributeTypeSelector = () => {
    const attributeTypes = Object.entries(TrainerAttributeType)
        .map(([key, value]) => ({ number: key, word: value }))
        .filter(at => !isNaN(Number(at.number)));
    return [
        { text: "Any Attribute type", value: "" },
        ...attributeTypes.map(at => ({ text: at.word, value: at.number, key: at.number }))
    ];
};
