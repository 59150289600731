import * as React from "react";
import { Grid, Checkbox, Divider } from "semantic-ui-react";
import { EventInstanceDetails } from "../model";

export interface CancelModalEventInstancePaymentRowProps {
    eventInstance: EventInstanceDetails;
    selected: boolean;
    toggleEventInstance: (eventInstanceId: string) => void;
}

export const CancelModalEventInstancePaymentRow: React.FC<CancelModalEventInstancePaymentRowProps> = ({
    eventInstance,
    selected,
    toggleEventInstance }) => {
    const onEventInstanceToggle = React.useCallback(() => {
        toggleEventInstance(eventInstance?.eventInstanceId ?? "");
    }, [eventInstance, toggleEventInstance]);

    return (
        <>
            <Grid.Row className="half-padding">
                <Grid.Column width={7}>
                    {eventInstance.eventTypeName}
                </Grid.Column>
                <Grid.Column width={6}>
                    {eventInstance.eventInstanceDeliveryDateTime?.format("LLLL") ?? ""}
                </Grid.Column>
                <Grid.Column width={3}>
                    <Checkbox checked={selected} onClick={onEventInstanceToggle} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="without-padding">
                <Grid.Column>
                    <Divider />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};
