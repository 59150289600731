import axios from "axios";
import { ExternalLinks } from "./model";

export class SettingsApi {
    private readonly apiUrl: string = "/api/appsettings";

    public async getExternalLinks() {
        const response = await axios.get(`${this.apiUrl}/getexternallinks`);
        return response.data as ExternalLinks;
    }
}
