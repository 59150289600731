import { AppCommonState } from "@common/appCommonState";
import { isUserClientAdvisor } from "@common/crud/common/selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pauseCallRecording } from "../genesysActions";

export function usePauseCallRecordingOnEntry(correlationId: string) {

    const dispatch = useDispatch();

    const isClientAdvisor = useSelector(isUserClientAdvisor);

    const { genesysSettings, settingsLoaded } = useSelector((state: AppCommonState) => {
        return {
            genesysSettings: state.appSettings.genesysSettings,
            settingsLoaded: state.appSettings.loaded
        };
    });

    // Pause call recording for CA when we enter the page
    React.useEffect(() => {
        if (isClientAdvisor && settingsLoaded && genesysSettings.genesysSetupAndEnabled)
        {
            // If we aren't getting a new token but we are a client advisor then pause the call recording
            dispatch(pauseCallRecording(correlationId));
        }
    }, [correlationId, dispatch, genesysSettings.genesysSetupAndEnabled, isClientAdvisor, settingsLoaded]);
}
