import { Spinner } from "@common/global";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { PoliceNotificationCreateEditModel, PoliceNotificationListModel, PoliceNotificationType, PoliceNotificationTypes } from "../model";
import { nonManagementInfoPoliceOrganisationOptionsSelector } from "../../organisation/selectors";
import { PoliceNotificationsApi } from "../policeNotificationsApi";
import { usePoliceNotificationContext } from "../policeNotificationContext";
import { toast } from "@common/toasts";
import { ObjectKeys } from "@common/helpers/typedObjectMethods";

const notificationTypeOptions: DropdownItemProps[] =
    ObjectKeys(PoliceNotificationTypes).filter(key => +key > 0).map(key => ({
        key,
        value: +key,
        text: PoliceNotificationTypes[key]
    }));

interface FormValues {
    organisationId: string;
    notificationType: PoliceNotificationType;
    description: string;
}

const defaultFormValues: FormValues = {
    organisationId: "",
    notificationType: undefined,
    description: ""
};

const FormValuesKeys =
    Object.fromEntries(Object.keys(defaultFormValues).map(key => [key, key])) as { [key in keyof FormValues]: key };

export interface PoliceNotificationModalProps {
    open: boolean;
    notification?: PoliceNotificationListModel;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PoliceNotificationModal = (props: PoliceNotificationModalProps) => {
    const { open, notification, setOpen } = props;
    const organisationOptions = useSelector(nonManagementInfoPoliceOrganisationOptionsSelector);

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

    const { notificationAddedCallback, notificationEditedCallback, notificationDeletedCallback } = usePoliceNotificationContext();

    const initialFormValues: FormValues = notification ?? defaultFormValues;

    function validateFormValues({ organisationId, notificationType, description }: FormValues) {
        const errors: any = {};

        if (!organisationId) {
            errors[FormValuesKeys.organisationId] = "Please choose an organisation";
        }
        if (!notificationType) {
            errors[FormValuesKeys.notificationType] = "Please choose a notification type";
        }
        if (!description) {
            errors[FormValuesKeys.description] = "Please add a description";
        }

        return errors;
    }

    async function onSubmit(formValues: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) {
        const { description, organisationId, notificationType } = formValues;
        const organisationName = organisationOptions.filter(o => o.key === organisationId)[0].text;
        const model: PoliceNotificationCreateEditModel = {
            id: notification?.id,
            description,
            organisationId,
            notificationType,
            organisationName
        };

        let newNotification: PoliceNotificationListModel;
        let formValuesForReset: FormValues;
        if (notification) {
            newNotification = await new PoliceNotificationsApi().updatePoliceNotification(model);
            notificationEditedCallback(newNotification);
            formValuesForReset = formValues;
        } else {
            newNotification = await new PoliceNotificationsApi().createPoliceNotification(model);
            notificationAddedCallback(newNotification);
            formValuesForReset = initialFormValues;
        }

        setSubmitting(false);
        setOpen(false);
        resetForm({ values: formValuesForReset });
    }

    function openDeleteModal() {
        setDeleteModalOpen(true);
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false);
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validate={validateFormValues}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps: FormikProps<FormValues>) => {
                const {
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    resetForm,
                    setSubmitting,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                } = formikProps;

                function onOrganisationChange(_: any, { value }: DropdownProps) {
                    setFieldValue(FormValuesKeys.organisationId, value);
                }

                function onNotificationTypeChange(_: any, { value }: DropdownProps) {
                    setFieldValue(FormValuesKeys.notificationType, +value);
                }

                function onCancelClick() {
                    setOpen(false);
                    resetForm({ values: initialFormValues });
                }

                async function deleteNotification() {
                    setSubmitting(true);

                    await new PoliceNotificationsApi().deletePoliceNotification(notification.id);
                    notificationDeletedCallback(notification);

                    closeDeleteModal();
                    setOpen(false);
                    setSubmitting(false);
                    resetForm({ values: defaultFormValues });
                    toast.success("Notification deleted");
                }

                return (
                    <>
                        <Modal open={deleteModalOpen}>
                            <Modal.Header>Are you sure?</Modal.Header>
                            <Modal.Content>Are you sure you want to delete this notification? Warning - this action cannot be undone.</Modal.Content>
                            <Modal.Actions>
                                <Button content="No" className="cancel-action" onClick={closeDeleteModal} disabled={isSubmitting} loading={isSubmitting} />
                                <Button content="Yes" positive onClick={deleteNotification} disabled={isSubmitting} loading={isSubmitting} />
                            </Modal.Actions>
                        </Modal>
                        <Modal open={open} size="tiny">
                            <Modal.Header>
                                {notification ? "Edit" : "Add new"} Police Notification
                            </Modal.Header>
                            <Modal.Content>
                                <Spinner active={isSubmitting}>
                                    <Form>
                                        <Form.Dropdown
                                            label="Police Organisation"
                                            value={values.organisationId}
                                            options={organisationOptions}
                                            selection
                                            onChange={onOrganisationChange}
                                            onBlur={handleBlur}
                                            id={FormValuesKeys.organisationId}
                                            error={errors.organisationId && touched.organisationId &&
                                                { content: errors.organisationId, pointing: "above" }}
                                        />
                                        <Form.Dropdown
                                            label="Notification Type"
                                            onChange={onNotificationTypeChange}
                                            onBlur={handleBlur}
                                            value={values.notificationType}
                                            selection
                                            options={notificationTypeOptions}
                                            id={FormValuesKeys.notificationType}
                                            error={errors.notificationType && touched.notificationType &&
                                                { content: errors.notificationType, pointing: "above" }}
                                        />
                                        <Form.TextArea
                                            label="Description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            id={FormValuesKeys.description}
                                            error={errors.description && touched.description &&
                                                { content: errors.description, pointing: "above" }}
                                        />
                                    </Form>
                                </Spinner>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    content="Cancel"
                                    className="cancel-action"
                                    onClick={onCancelClick}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                                <Button
                                    content="Save"
                                    positive
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                                {notification &&
                                    <Button
                                        content="Delete"
                                        onClick={openDeleteModal}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        secondary
                                    />}
                            </Modal.Actions>
                        </Modal>
                    </>
                );
            }}
        </Formik>
    );
};
