import * as React from "react";
import { Modal } from "@common/components";
import { useDispatch } from "react-redux";
import { Button, Checkbox } from "semantic-ui-react";
import { refund } from "../../actions";
import { BalanceInfo } from "@common/payments/components/BalanceInfo";
import { WorkflowTypeEnum } from "@common/crud/eventType/model";
import { AlaskaNudgeTaskEnum } from "@common/crud/alaskaNudgeTask";
import { AttendeeApi } from "../../attendeeApi";
import { PreventScrollingOnNumberInputsHook } from "@common/crud/common/PreventScrollingOnNumberInputs";
import { CurrencyInput } from "@common/global/CurrencyInput";

export interface RefundModalProps {
    correlationId: string;
    attendeeId: string;
    eventInstanceId: string;
    totalAmountPaid: number;
    totalAmountDue: number;
    workflowType: number;
}

export const RefundModal: React.FC<RefundModalProps> = ({ correlationId, attendeeId, eventInstanceId, totalAmountDue, totalAmountPaid, workflowType }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [isAmountValid, setIsAmountValid] = React.useState<boolean>(true);
    const [amount, setAmount] = React.useState<number>(0);
    const [doNotRefundOnStripe, setDoNotRefundOnStripe] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function hasActiveNudges() {
            const attendeeApi = new AttendeeApi(eventInstanceId);
            const response = await attendeeApi.hasActiveNudges(attendeeId, AlaskaNudgeTaskEnum.RefundTasks);

            if (response === true && workflowType === WorkflowTypeEnum.Dors) {
                setAmount(49);
            }

            if (response === true && workflowType === WorkflowTypeEnum.DDRS) {
                setAmount(75);
            }
        }

        if (eventInstanceId && attendeeId && workflowType) {
            hasActiveNudges();
        }

    }, [attendeeId, eventInstanceId, workflowType]);

    function onOpenClick() { setOpen(true); }
    function onCloseClick() { setOpen(false); }

    function onInputChange(value: number, valid: boolean) {
        setAmount(value);
        setIsAmountValid(valid);
    }

    function onRefundCheckboxClick() { setDoNotRefundOnStripe(!doNotRefundOnStripe); }

    function onContinueClick() {
        dispatch(refund({ amount: amount * 100, correlationId, eventInstanceId, attendeeId, doNotRefundOnStripe }));
        setOpen(false);
    }

    PreventScrollingOnNumberInputsHook();
    return (
        <>
            <Button className="button-margin" onClick={onOpenClick}>Refund</Button>

            <Modal open={open}>
                <Modal.Header>
                    Refund
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <BalanceInfo paymentsSum={totalAmountPaid} remainingBalance={totalAmountDue} />
                        <p>How much would you like to refund?</p>
                        <CurrencyInput
                            placeholder="Amount"
                            value={amount}
                            required
                            showErrors
                            onChange={onInputChange}
                            nonNegative
                        />
                        <Checkbox checked={doNotRefundOnStripe} onClick={onRefundCheckboxClick} label="Do not refund automatically in Stripe" />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCloseClick} className="cancel-action">
                        Cancel
                    </Button>
                    <Button
                        onClick={onContinueClick}
                        disabled={!isAmountValid}
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content="Continue"
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
