import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import {  Grid, Table, TextArea } from "semantic-ui-react";
import { AttendeeListModel } from "@common/crud/attendee";
import "./UpdateDelegatesTable.scss";
import { DelegateClassroomAssignableAttributes, DelegateDigitalAssignableAttributes, SpecialRequirementsAttribute } from "../../model";
import { SpecialRequirements } from "@common/crud/common/SpecialRequirements";

interface UpdateSpecialRequirementsTableRowProps {
    delegate: AttendeeListModel;
    isDigitalCourse: boolean;
    updateDelegate: (delegate: AttendeeListModel, updatedDelegate: AttendeeListModel) => void;
}

export const UpdateSpecialRequirementsTableRow: React.FC<UpdateSpecialRequirementsTableRowProps> =
({ delegate, updateDelegate, isDigitalCourse }) => {
    const [oldSpecialRequirements, setOldSpecialRequirements] = React.useState<SpecialRequirements>(null);
    const onEventAttributesArrayChange = React.useCallback((value: number[]) => {
        updateDelegate(delegate,
            {
                ...delegate,
                specialRequirements: {
                    ...delegate.specialRequirements,
                    eventAttributes: value,
                }
            });
    }, [delegate, updateDelegate]);

    const updateInterpreterName = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
        updateDelegate(delegate,
            {
                ...delegate,
                specialRequirements: {
                    ...delegate.specialRequirements,
                    interpreterName: event.currentTarget.value,
                }
            });
    }, [delegate, updateDelegate]);

    React.useEffect(() => {
        setOldSpecialRequirements(delegate.specialRequirements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    React.useEffect(() => {
        if (oldSpecialRequirements?.eventAttributes?.includes(SpecialRequirementsAttribute.Translator) &&
            !delegate.specialRequirements.eventAttributes.includes(SpecialRequirementsAttribute.Translator)) {
            updateDelegate(delegate,
                {
                    ...delegate,
                    specialRequirements: {
                        ...delegate.specialRequirements,
                        interpreterName: null,
                    }
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[delegate.specialRequirements.eventAttributes]);

    const updateOutsideUkCountryName = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
        updateDelegate(delegate,
            {
                ...delegate,
                specialRequirements: {
                    ...delegate.specialRequirements,
                    outsideUkCountryName: event.currentTarget.value,
                }
            });
    }, [delegate, updateDelegate]);

    const updateOtherRequirements = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
        updateDelegate(delegate,
            {
                ...delegate,
                specialRequirements: {
                    ...delegate.specialRequirements,
                    otherRequirements: event.currentTarget.value,
                }
            });
    }, [delegate, updateDelegate]);

    const specialRequirementsOptions = React.useMemo(() => isDigitalCourse
        ? Object.keys(DelegateDigitalAssignableAttributes)
            .filter(k => +k !== 0)
            .map(k => ({ text: DelegateDigitalAssignableAttributes[k], value: +k }))
        : Object.keys(DelegateClassroomAssignableAttributes)
            .filter(k => +k !== 0)
            .map(k => ({ text: DelegateClassroomAssignableAttributes[k], value: +k }))
    , [isDigitalCourse]);

    const requireInterpreterName = React.useMemo(() => (delegate.specialRequirements?.eventAttributes?.includes(SpecialRequirementsAttribute.OwnInterpreter) ||
        delegate.specialRequirements?.eventAttributes?.includes(SpecialRequirementsAttribute.Translator)), [delegate.specialRequirements?.eventAttributes]);
    const requireOutsideUkCountryName = React.useMemo(() =>
        delegate.specialRequirements?.eventAttributes?.includes(SpecialRequirementsAttribute.OutsideUkAttendee),
    [delegate.specialRequirements?.eventAttributes]);

    return (
        (<Table.Row key={`edit-${delegate.id}`} className="full-width-input-form">
            <Table.Cell>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            {delegate.fullName}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
            <Table.Cell>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Input.DropdownMulti
                                value={delegate.specialRequirements?.eventAttributes}
                                options={specialRequirementsOptions}
                                onChange={onEventAttributesArrayChange}
                                placeholder="Special Requirements"
                                multiple
                                search
                            />
                            <div className="requirements-div">
                                <TextArea
                                    value={delegate.specialRequirements?.otherRequirements}
                                    onChange={updateOtherRequirements}
                                    className="requirements-text-area"
                                    placeholder="Other requirements"
                                    rows={2}
                                />
                            </div>
                            {requireInterpreterName && (
                                <div className="requirements-div">
                                    <TextArea
                                        value={delegate.specialRequirements?.interpreterName}
                                        onChange={updateInterpreterName}
                                        className="requirements-text-area"
                                        placeholder="The Name of Interpreter"
                                        rows={2}
                                    />
                                </div>
                            )}
                            {requireOutsideUkCountryName && (
                                <div className="requirements-div">
                                    <TextArea
                                        value={delegate.specialRequirements?.outsideUkCountryName}
                                        onChange={updateOutsideUkCountryName}
                                        className="requirements-text-area"
                                        placeholder="Other Country Name"
                                        rows={2}
                                    />
                                </div>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </Table.Row>)
    );
};
