/* tslint:disable:object-literal-key-quotes */
import { loadAttendeesForEventInstance } from "./actions";
import { AppState } from "./model";
import { attendeeSelector } from "./selectors";
import { detailRoutes, corporateDetailRoutes } from "./detailRoutes";
import { loadEventTypes } from "../eventType/actions";
import { loadDdrsEventInstanceDetail, loadEventInstanceDetail } from "@common/crud/eventInstance/actions";
import { delegateSelector } from "../delegate/selectors";
import { EventManagementAdminRoles, TrainerRole } from "@common/auth/model";

const detailRoute = () => ({
    "/:attendeeId": {
        title: (state: AppState) => attendeeSelector(state).fullName,
        resolve: [loadEventTypes, loadDdrsEventInstanceDetail],
        ...detailRoutes
    }
});

export const routes = {
    "/attendees": {
        resolve: loadAttendeesForEventInstance,
        title: "Attendees",
        ignoreParentResolve: true,
        ...detailRoute()
    },
};

const corporateAndConstructionDetailRoute = () => ({
    "/:attendeeId": {
        title: (state: AppState) => {
            const delegate = delegateSelector(state);
            return delegate
                ? `${delegate.forename} ${delegate.surname}`
                : undefined;
        },
        ...corporateDetailRoutes
    }
});

export const constructionRoutes = {
    "/delegates": {
        resolve: loadAttendeesForEventInstance,
        title: "Delegates",
        ignoreParentResolve: true,
        ...corporateAndConstructionDetailRoute()
    }
};

export const corporateRoutes = {
    "/delegates": {
        resolve: loadAttendeesForEventInstance,
        title: "Delegates",
        ignoreParentResolve: true,
        ...corporateAndConstructionDetailRoute()
    },
    "/on-road-reports": {
        title: "On Road Reports",
        resolve: [loadAttendeesForEventInstance, loadEventInstanceDetail],
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
    "/competency-reports": {
        title: "Competency Reports",
        resolve: [loadAttendeesForEventInstance],
        authorize: [...EventManagementAdminRoles, TrainerRole]
    },
};

