/* eslint-disable max-lines */
/* eslint-disable max-len */
import { LanguageEnum } from "@common/crud/eventInstance/model";
import { TranslationModel } from "./en";
// Please note: any fields included in a translation using t("KEY") should be preceeded with a dash. e.g. {{- booking.forename}}
// this prevents i18n from escaping the string; react will escape the string.
// DO NOT USE THE SAME APPROACH ON ANY TRANSLATION BEING PASSED TO TRANS

const Languages = {
    [0]: "Y naill neu’r llall",
    [LanguageEnum.English]: "Saesneg",
    [LanguageEnum.Welsh]: "Cymraeg",
};

export const cy: TranslationModel = {
    Header: {
        LOGIN: "Mewngofnodi",
        LOGOUT: "Allgofnodi",
        HOME: "Hafan",
        LANGUAGE: "English",
        LANGUAGE_CODE: "en",
    },
    BookingStages: {
        STAGE_1: "Dewis eich lleoliad",
        STAGE_2: "Cadarnhau eich manylion",
        STAGE_2_COMPLETE: "Cadarnhewch eich manylion a chwblhewch y taliad",
        STAGE_3: "Talu",
    },
    AllVenues: {
        SEARCH: "Chwilio",
        SEARCH_FOR_ANOTHER_VENUE: "Chwilio am leoliad arall",
        SELECT_VENUE: "Dewis lleoliad",
        ADVANCED_SEARCH: "Chwilio yn fanylach",
        VENUE_INTRO: "Fel arall chwilio gyda chôd post arall neu hidlwyr",
        THERE_ARE_NO_EVENT_INSTANCES: "Nid oes unrhyw ddigwyddiadau sy’n cyfateb i’r meini prawf",
        MILES: "milltiroedd",
        APPLY: "Gosod",
        HAS_WELSH_LANGUAGE_COURSES: "Cyrsiau yn yr iaith Gymraeg ar gael"
    },
    App: {
        PERSONAL_DETAILS: "Manylion Personol",
    },
    BookingDetails: {
        MY_DETAILS: "Fy Manylion",
        ATTENDEE_DETAILS: "Manylion y Mynychwr",
        NAME: "Enw",
        ADDRESS: "Cyfeiriad",
        EMAIL: "Cyfeiriad e-bost",
        TELEPHONE: "Ffôn",
        LICENCE_NUMBER: "Rhif Trwydded",
        YOUR_CONTACT_DETAILS: "Eich manylion cyswllt",
        WELCOME: "Cadarnhewch eich manylion isod cyn symud ymlaen i dalu",
        EDIT_INSTRUCTIONS: "I olygu eich manylion ewch i’r <1>Porth NDORS </1>",
        REQUIRED_FIELD: "Mae angen y maes hwn",
        PHONE_NUMBER_REQUIRED: " Rhowch rif ffôn dilys",
        ACTION_REQUIRED: " Angen gweithredu",
        YOU_MUST_TICK_THE_BOX: " Rhaid i chi dicio'r blwch i gadarnhau eich bod wedi darllen a chytuno i'n telerau ac amodau",
        TELEPHONE_NOTE: " Byddwn ond yn defnyddio'ch rhif i gysylltu â chi ynglŷn â diweddariadau pwysig am eich cwrs.",
        EMAIL_REQUIRED: "Rhowch gyfeiriad e-bost dilys",
        EMAIL_NOTE: " Byddwn ond yn defnyddio'ch cyfeiriad e-bost i anfon negeseuon e-bost ynglŷn â’ch cwrs",
        CONTINUE: "Mynd Ymlaen",
        CLOSE: "Cau",
        GO_TO_PAYMENT: "Ewch i daliad",
        COMPLETE_BOOKING: "Cwblhau archebu'ch cwrs",
        MANUAL_PAYMENT: "Mewnbynnu Taliad” ",
        BOOKING_REFERENCE: "Cyfeirnod Archebu",
        CAR_TYPE: "Math o Gar",
        CAR_TYPE_REQUIRED: "Dewiswch y math o gar sydd ei angen arnoch",
        CAR_TYPE_NOTE: "Mae’r cwrs hwn yn cynnwys sesiwn ymarferol, pa fath o gar sydd ei angen arnoch?",
        SPECIAL_CAR_DETAILS_REQUIRED: "Rhowch fanylion yr addasiadau arbennig ar y car",
        SPECIAL_CAR_DETAILS_MAX_LENGTH: "Rhaid i hyn beidio â bod yn fwy na 150 llythyren",
        SPECIAL_CAR_DETAILS: "Manylion addasiadau arbennig",
        SPECIAL_CAR_DETAILS_NOTE: "Rhowch ddisgrifiad byr i egluro addasiadau arbennig eich car",
        CORRECT_CONTACT_DETAILS: "Gwnewch yn siŵr bod gennym y manylion cyswllt cywir isod",
        PLEASE_COMPLETE_ALL_SECTIONS: "Llenwch bob rhan o'r dudalen hon cyn bwrw ymlaen",
        LANGUAGE_DEPENDENT_FIELD: "{{- welsh}}"
    },
    BookingConfirmation: {
        BOOKING_CONFIRMED: "Archebu wedi'i gadarnhaud",
        BOOKING_REFERENCE: "Cyfeirnod archebu TTC",
        YOUR_PERSONAL_DETAILS: "Eich manylion personol",
        WELCOME: "Isod mae manylion y cwrs <1>{{- eventTypeNameCy}}</1> rydych wedi archebu lle arno.",
        CHANGE_BOOKING: "Diolch am newid eich cwrs.",
        NEW_BOOKING_REFERENCE: "Mae eich archeb newydd wedi’i chadarnhau",
        PRINT: "Argraffu manylion y cwrs",
        CONFIRMATION: "Cadarnhad",
        ORDER_SUMMARY: "Crynodeb o'r Gorchymyn",
        YOUR_COURSE: "Eich cwrs",
        RECOMMEND_ASSISTANCE: "<strong>Rydych chi wedi archebu cwrs Digidol. Bydd hwn yn cael ei gyflwyno gan ddefnyddio Zoom.</strong><br /><br />Rydym yn argymell clicio ar y ddolen er mwyn cwblhau ymarfer o ymuno â'ch cwrs Ymwybyddiaeth Gyrwyr Digidol gyda TTC.",
        ZOOM_ASSISTANCE: "Os ydych yn cael anawsterau wrth gwblhau eich cyfarfod Zoom prawf yna anfonwch e-bost atom yn <1>onlinecoursesupport@ttc-uk.com</1>. Cynhwyswch eich <strong>cyfeirnod archebu TTC</strong>, <strong>enw </strong> a <strong>rhif cyswllt</strong> er mwyn i'n tîm cymorth ar-lein gysylltu eto.",
        LANGUAGE_DEPENDENT_FIELD: "{{- welsh}}",
        TEST_MEETING: "Dechrau cyfarfod prawf",
        COURSE_DETAILS_TITLE: "Manylion y Cwrs",
    },
    FinanceDetails: {
        AMOUNT_PAID: "Swm wedi ei dalu:",
        AMOUNT_DUE: "Swm sy’n ddyledus yn ôl i’r mynychwr:",
        OUTSTANDING_BALANCE: "Balans sy’n ddyledus:",
        SCHEDULED_PAYMENT: "Swm y taliad a drefnwyd:",
        FLEXI_PAY_CONFIRMATION: "Ffi talu hyblyg:",
        PAYMENT_DETAILS_TITLE: "Manylion Talu",
    },
    BookingAssistance: {
        RECOMMEND_ASSISTANCE: "<strong>Rydych wedi archebu lle ar Gwrs Digidol. Cyflwynir hwn gan ddefnyddio Zoom</strong><br />Rydym yn argymell clicio ar y ddolen ganlynol er mwyn cwblhau arbrawf o ymuno â'ch cwrs Ymwybyddiaeth Gyrwyr Digidol gyda TTC.",
        CHOOSE_ASSISTANCE: "Os ydych chi'n cael anawsterau wrth gwblhau eich cyfarfod Zoom arbrofol yna anfonwch e-bost atom yn onlinecoursesupport@ttc-uk.com. Cofiwch gynnwys eich rhif cyfeirnod, enw a rhif cyswllt er mwyn i'n tîm cymorth ar-lein gysylltu â chi wedyn.",
        TEST_MEETING: "Cychwyn cyfarfod arbrofol"
    },
    EventInstanceMobile: {
        TO: "i",
        END_TIME: "tan {{- endTime}}",
        COURSE_LANGUAGE: "Iaith:",
        MILES: "milltiroedd",
        DAY_NUMBER: "Dydd ",
        AUTO_ONLY: "Mae'r cwrs hwn yn addas ar gyfer gyrwyr awtomatig yn unig",
        MANUAL_ONLY: "Mae'r cwrs hwn yn addas ar gyfer gyrwyr â llaw yn unig",
        MANUAL_AND_AUTO: "Mae’r cwrs hwn yn addas ar gyfer gyrwyr â llaw ac awtomatig",
        Languages
    },
    AttendeeHeader: {
        WELCOME: "Croeso {{- booking.forename}}, rydych wedi cael eich cyfeirio ar gyfer {{- eventTypeNameCy}} gyda dyddiad terfynol o {{- completionDate}}",
        INTRODUCTION_LINE1: "Isod mae detholiad o gyrsiau rydym yn eu hargymell yn seiliedig ar eich dyddiad cwblhau.",
        INTRODUCTION_LINE2: "Os nad yw'r rhain yn addas i chi, gallwch chi ddefnyddio'r modd chwilio’n uwch i ddod o hyd i'r cwrs delfrydol i chi.",
    },
    ClientAdvisorHeader: {
        WELCOME: " - Referred for {{- eventTypeNameCy}} with a deadline date of {{- completionDate}}",
        INTRODUCTION_LINE1: "Isod mae detholiad o gyrsiau yn seiliedig ar ddyddiad cwblhau'r cleient.",
        INTRODUCTION_LINE2: "Gellir defnyddio'r modd chwilio’n uwch i hidlo’r cyrsiau a ddangosir isod.",
    },
    Landing: {
        ATTENDEE_LANDING_PAGE: "Croeso i Alaska",
        SELECT_DATE_TIME_AT_YOUR_CHOSEN_VENUE: " Dewiswch ddyddiad ac amser yn y lleoliad o'ch dewis.",
        SEARCH: "Chwilio am leoliad arall",
        BOOKING_NOT_FOUND: " Mae'n ddrwg gennym, ni chanfuwyd unrhyw fanylion i gyfateb i'ch cais",
        CLASSROOM_COURSES: "Cyrsiau ystafell ddosbarth",
        CLASSROOM_COURSES_ONLY: "Cyrsiau Ystafell Ddosbarth",
        CLASSROOM_COURSES_ONLY_EXPLANATION: "Isod mae rhestr o gyrsiau a ddarperir mewn gwahanol leoliadau hyfforddi",
        FILTER_BY_TRAINING_CENTRE: "Hidlo yn ôl canolfan hyfforddi",
        FILTER_BY_VENUE: "Hidlo yn ôl lleoliad",
        FILTER_BY_DISTANCE: "Hidlo yn ôl y pellter teithio a ffefrir (milltiroedd)",
        FILTER_BY_DISTANCE_PLACEHOLDER: "Hidlo yn ôl pellter",
        DIGITAL_COURSES: "Cyrsiau digidol",
        CLASSROOM_COURSES_EXPLANATION: "Darperir mewn sawl Canolfan Hyfforddi ledled Cymru a Lloegr",
        DIGITAL_COURSES_ONLY: "Cyrsiau Digidol",
        DIGITAL_COURSES_ONLY_EXPLANATION: "Isod mae rhestr o gyrsiau a fydd yn cael eu cyflwyno ar-lein gan ddefnyddio Zoom",
        DIGITAL_COURSES_EXPLANATION: "Cyflwynir ar-lein gan ddefnyddio'r darparwr fideo-gynadledda Zoom",
        ANY: "Unrhyw",
        MILES_AWAY: "({{- distanceInMiles}} Milltiroedd i ffwrdd)",
        THERE_ARE_NO_DIGITAL_COURSES: "Mae'n ddrwg gennym, nid oes cyrsiau ar gael yn seiliedig ar feini prawf eich chwiliad",
        THERE_ARE_NO_CLASSROOM_COURSES: "Oherwydd y sefyllfa bresennol ynglŷn â Covid-19, mae pob cwrs ystafell ddosbarth wedi ei ganslo. Cliciwch ar y rhestr cyrsiau digidol ar ochr chwith y sgrin hon i ddod o hyd i gwrs sy'n addas i chi.",
        SELECTED_COURSE: "Cwrs sydd wedi’i ddewis ar hyn o bryd",
        BOOK_THIS_COURSE: "Ewch ymlaen i archebu’r cwrs hwn",
        PENDING_BOOKING_INFORMATION: "Rydych yn cadw lle ar gwrs <1>{{- venueName}}</1> ar <3>{{- date }}</3> am <5>{{- startTime }}</5>, cliciwch y botwm i barhau â'r dewis hwn neu dewiswch gwrs gwahanol o'r rhestr isod",
        WELCOME_TO_PORTAL: "Croeso i borth archebu ar-lein TTC",
        UNABLE_TO_BOOK_ON_COURSE: "Ni allwn gadw lle ar eich cyfer ar gwrs. Ymddiheurwn am unrhyw anghyfleustra.",
        PLEASE_CALL_FOR_ASSISTANCE: "Ffoniwch 0330 024 1805 ac bydd un o'n Cynghorwyr yn hapus i helpu.",
        SESSION_EXPIRED_HEADING: "Mae'n ddrwg gennym ond mae eich sesiwn archebu wedi dod i ben.",
        SESSION_EXPIRED_BODY: "I ailgychwyn archebu eich cwrs gyda TTC cliciwch ar y botwm isod i gael eich trosglwyddo yn ôl i Borth NDORS. Sylwer y bydd angen i chi fewngofnodi i Borth NDORS eto cyn dewis TTC fel y darparwr sydd orau gennych.",
        SESSION_EXPIRED_BUTTON_TEXT: "Borth NDORS"
    },
    IntroMessage: {
        MODAL_HEADER: "Croeso i Borth Archebu TTC",
        CONTINUE: "Parhau",
    },
    Payment: {
        FAIL_AND_RETRY: " Mae'n ddrwg gennym, roedd problem wrth brosesu'ch taliad. Rhowch gynnig arall os gwelwch yn dda",
        SUCCEEDED: " Mae eich taliad wedi'i brosesu",
        TAKE_ME_TO_PAYMENT_VIEW: "Gweld manylion y taliad",
    },
    PaymentReview: {
        PAY: "Talu",
        ENTER_CARD_DETAILS: "Mewnbynnwch fanylion eich cerdyn isod",
        CARD_NUMBER: "Rhif y Cerdyn",
        EXPIRY_DATE: "Dyddiad dod i ben",
        SECURITY_CODE: "Côd Diogelwch",
        PAYMENT_FAILED: "Mae eich taliad wedi methu ",
        PAYMENT_DETAILS: "Manylion eich taliad",
        NO_AVAILABILITY: "Mae'n ddrwg gennym, mae'r holl leoedd ar y cwrs hwnnw bellach wedi'u harchebu, dewiswch ddyddiad arall.",
        RESERVATION_HAS_EXPIRED: "Mae eich archeb ar gyfer y cwrs wedi dod i ben, defnyddiwch y botwm ‘yn ôl’ac ail- ddewiswch y cwrs.",
        PAYMENT_ALREADY_RECEIVED: "Mae’ch taliad eisoes wedi’i dderbyn",
        SERVER_ERROR: "Mae gwall wedi digwydd yn ystod y taliad. Ffoniwch ni."
    },
    TAndCsConfirmationComponent: {
        CONFIRM: " Cadarnhewch eich bod wedi darllen ein <1>Telerau ac Amodau </1>.",
    },
    SpecialRequirementsCheckboxes: {
        HEARING_LOOP: " Rwyf angen system ddolen glyw",
        BSL: " Rwyf angen cyfieithydd Iaith Arwyddion Prydain (BSL)",
        BREASTFEEDING: "Rwyf angen cyfleusterau bwydo ar y fron",
        PRAYER_ROOM: "Rwyf angen ystafell i weddïo ynddi",
        DISABLED_ACCESS: " Rwyf angen mynediad pobl anabl",
        SPEECH_TO_TEXT: "Rwyf angen adroddwr llais i destun",
        OWN_TRANSLATOR: " Rwy'n siarad iaith wahanol a byddaf yn dod â'm cyfieithydd fy hun",
        OWN_INTERPRETER: "Bydd angen i chi ddod â'ch cyfieithydd eich hun, ni fydd TTC yn darparu hyn. Rhowch enw'r cyfieithydd isod.",
        NEED_TO_BREASTFEED: "Bydd angen i mi fwydo ar y fron yn ystod y cwrs",
        NEED_PRAYER: "Bydd angen i mi weddïo yn ystod y cwrs",
        REQUIRE_SUBTITLES: "Mae arnaf angen isdeitlau",
        OUTSIDE_UK: "Byddaf yn ymuno â'r cwrs o'r tu allan i'r DU",
        OTHER_COUNTRY: "Rhowch wybod o ba wlad y byddwch chi'n ymuno â'r cwrs",
        OTHER_DISABILITY: "Cadarnhewch fanylion unrhyw anableddau eraill isod",
        HEARING_IMPAIRMENT: "Mae gennyf nam ar y clyw",
        HEARING_REQUIREMENTS: "Dewiswch yr opsiynau sy’n fwyaf addas ar gyfer eich anghenion",
        OTHER_HEARING_REQUIREMENTS: "Cadarnhewch isod fanylion unrhyw anghenion clyw eraill"
    },

    BookingSpecialRequirementsForm: {
        YOUR_REQUIREMENTS: "Eich anghenion",
        ANY_SPECIAL_REQUIREMENTS_QUESTION: "Oes angen unrhyw ofynion arbennig arnoch wrth fynychu eich cwrs?",
        SELECT_SOME_REQUIREMENTS: " Dewiswch rai gofynion arbennig",
        SELECT_HEARING_REQUIREMENTS: "Dewiswch rai ofynion ynglŷn â nam ar y clyw",
        CONFIRM_INTERPRETER_NAME: "Cadarnhewch enw'r cyfieithydd, os gwelwch yn dda",
        CONFIRM_OTHER_COUNTRY: "Cadarnhewch enw'r wlad os gwelwch yn dda",
        CURRENT_SPECIAL_REQUIREMENTS: "Dewiswyd, yn flaenorol, y gofynion arbennig canlynol. Os nad oes angen y rhain mwyach, diddymwch nhw, neu fel arall dewiswch unrhyw rai sydd eu hangen arnoch bellach.",
        YES: "Oes ",
        NO: "Nac oes ",
        EI_SCHEDULED_WITHIN_72_HOURS: "Yn anffodus, oherwydd dyddiad y cwrs rydych wedi ei ddewis, ni fydd gennym ddigon o amser i adolygu eich gofynion arbennig. Ewch yn ôl i'r dudalen flaenorol a dewiswch gwrs diweddarach, neu fel arall ewch ymlaen i dalu os ydych yn dal eisiau archebu eich cwrs dewisedig",
        OTHER_REQUIREMENTS_LENGTH_EXCEEDED: "Rhaid i ofynion eraill fod yn 200 nod neu lai"
    },
    Filters: {
        SEARCH: "Chwilio",
        SEARCH_FOR_ANOTHER_VENUE: "Chwilio am leoliad arall",
        SELECT_VENUE: "Dewis lleoliad",
        ADVANCED_SEARCH: "Chwilio yn fanylach",
        VENUE_INTRO: "Fel arall chwilio gyda chôd post arall neu hidlwyr",
        POSTCODE: "Côd post",
        POSTCODE_VALIDATION_ERROR: "Rhowch god post/cod allanol dilys y DU",
        THERE_ARE_NO_EVENT_INSTANCES: "Nid oes unrhyw ddigwyddiadau sy’n cyfateb i’r meini prawf",
        MILES: "milltiroedd",
        APPLY: "Gosod",
        HAS_WELSH_LANGUAGE_COURSES: "Cyrsiau yn yr iaith Gymraeg ar gael",
        FROM_DATE: "O ddyddiad",
        TO_DATE: "I ddydiad",
        SPECIAL_REQUIREMENTS: "Anghenion Arbennig",
        LANGUAGE: "Iaith",
        LANGUAGE_ANY: "Unrhyw",
        DISTANCE: "Pellter (Milltiroedd)",
        DAY_OF_THE_WEEK: "Diwrnod yr wythnos",
        MONDAY: "Dydd Llun",
        TUESDAY: "Dydd Mawrth",
        WEDNESDAY: "Dydd Mercher",
        THURSDAY: "Dydd Iau",
        FRIDAY: "Dydd Gwener",
        SATURDAY: "Dydd Sadwrn",
        SUNDAY: "Dydd Sul",
        FROM_DATE_ATTENDEE_TOOLTIP: "Rhowch y dyddiad cynharaf y gallwch fynychu eich cwrs",
        FROM_DATE_CA_TOOLTIP: "Rhowch y dyddiad cynharaf y gall y mynychwr fynychu cwrs",
        TO_DATE_ATTENDEE_TOOLTIP: "Rhowch y dyddiad hwyraf y gallwch fynychu eich cwrs",
        TO_DATE_CA_TOOLTIP: "Rhowch y dyddiad hwyraf y gall y mynychwr fynychu cwrs",
        LANGUAGE_TOOLTIP: "Darperir ein cyrsiau yn y Saesneg neu yn y Gymraeg",
        POSTCODE_ATTENDEE_TOOLTIP: "Rhowch y côd post lle rydych am wneud eich cwrs",
        POSTCODE_CA_TOOLTIP: "Rhowch y côd post lle mae'r mynychwr am wneud ei gwrs/chwrs",
        DISTANCE_ATTENDEE_TOOLTIP: "Dewiswch pa mor bell y byddai'n well gennych deithio i gyrraedd eich cwrs",
        DISTANCE_CA_TOOLTIP: "Dewiswch pa mor bell y byddai'n well gan y mynychwr deithio i gyrraedd ei gwrs/chwrs",
        START_TIMES: "Amseroedd Cychwyn",
        MORNING: "Bore",
        AFTERNOON: "Prynhawn",
        EVENING: "Gyda Nos",
        CANCEL: "Canslo",
        Languages,
    },
    ManualPayment: {
        HOW_MUCH_QUESTION: " Faint hoffech chi ei dalu?",
        BOOK_WITHOUT_PAYING: "Archebu heb dalu",
        GO_TO_PAYMENT: "Ewch i daliad",
        ENTER_CARD_DETAILS: "Mewnbynnwch fanylion eich cerdyn isod",
        PAYMENT_DETAILS: "Manylion eich taliad"
    },
    AllEventInstances: {
        TO: "i",
        BOOK_THIS_COURSE: "Archebu lle ar y cwrs hwn",
        BOOK: "Archebu",
        YOUR_CHOSEN_VENUE: "Lleoliad rydych wedi’i ddewis",
        COURSE_LANGUAGE: "Iaith:",
        SEATS_AVAILABLE: "seddi ar gael",
        AUTO_ONLY: "Mae'r cwrs hwn yn addas ar gyfer gyrwyr awtomatig yn unig",
        MANUAL_ONLY: "Mae'r cwrs hwn yn addas ar gyfer gyrwyr â llaw yn unig",
        MANUAL_AND_AUTO: "Mae’r cwrs hwn yn addas ar gyfer gyrwyr â llaw ac awtomatig",
        LOAD_MORE: "Llwythwch fwy ...",
        MILES: "milltiroedd",
        FLEXI_PAY_USED_DIGITAL: "Bydd y ffi i aildrefnu eich cwrs heddiw yn cael ei hepgor diolch i 'Archebu Hyblyg'.",
        FLEXI_PAY_USED_CLASSROOM: "Bydd y ffi i aildrefnu eich cwrs heddiw yn cael ei hepgor diolch i 'Archebu Hyblyg'. " +
            "Sylwer y bydd angen, efallai, i chi dalu ffi ychwanegol fach os ydych yn dewis mynd i leoliad sydd mewn rhanbarth Heddlu arall.",
        Languages,
        DAY_NUMBER: "Dydd "
    },
    ReservationError: {
        COURSE_NOT_AVAILABLE: "Mae'n ddrwg gennym nad yw'r cwrs hwn ar gael bellach, <1>cliciwch yma</1> i weld cyrsiau eraill"
    },
    ChosenCourse: {
        YOUR_CHOSEN_COURSE: " Y cwrs rydych wedi’i ddewis",
        COURSE_LANGUAGE: "Iaith:",
        VENUE_HEADER: "Lleoliad: ",
        DATE_HEADER: "Dyddiad: ",
        VENUE_NOTES: "Nodiadau lleoliad",
        VENUE_INSTRUCTIONS: "Sylwer y canlynol ar gyfer y lleoliad rydych wedi’i ddewis",
        TERMS_BUTTON: "Telerau ac Amodau",
        REARRANGEMENT_FEE: "Mae'r pris yn cynnwys ffi aildrefnu o £<1>{{fee}}</1>",
        AMOUNT_TO_PAY: "Swm i’w dalu",
        EXPENSIVE_VENUE: "Mae’r pris yn cynnwys £ <1>{{fee}}</1> ychwanegol i fynychu yn y rhanbarth Heddlu hwn",
        SCHEDULED_PAYMENT: "Swm y taliad a drefnwyd: ",
        AMOUNT_DUE: "Swm sy’n ddyledus yn ôl i’r mynychwr: ",
        OUTSTANDING_BALANCE: "Balans sy’n ddyledus: ",
        REARRANGE_FEE: "Swm i’w dalu heddiw i aildrefnu’ch cwrs",
        AMOUNT_PAID: "Swm wedi ei dalu",
        PAYMENT_AMOUNT: "Swm i’w dalu",
        DIGITAL_LINK: "Cyswllt cwrs digidol: ",
        DELIVERED_USING_ZOOM: "Wedi'i gyflwyno gan ddefnyddio Zoom",
        FLEXI_PAY_DESCRIPTION: "Drwy dalu ffi talu hyblyg, bydd mynychwyr yn gymwys i gael un cyfle i ail-archebu am ddim (cyfyngiadau yn berthnasol).",
        FLEXI_PAY_FEE: "Mae'r pris yn cynnwys ffi talu hyblyg o £<1>{{fee}}</1>",
        FLEXI_PAY_CONFIRMATION: "Ffi talu hyblyg: ",
        PAYMENT: "Taliad",
        Languages,
        LANGUAGE_DEPENDENT_FIELD: "{{- welsh}}",
    },
    ChosenVenue: {
        YOUR_CHOSEN_VENUE: " Y lleoliad rydych wedi’i ddewis",
        VENUE: "Lleoliad"
    },
    PrivacyPolicy: {
        READ_PRIVACY_POLICY: "Darllenwch ein gwybodaeth am breifatrwydd",
        PRIVACY_POLICY: "Mae Grŵp TTC yn ymrwymedig i sicrhau bod eich preifatrwydd yn cael ei warchod. Rydym yn cymryd eich preifatrwydd o ddifrif, a byddwn ond yn defnyddio'ch gwybodaeth bersonol i weinyddu'ch cyfrif, ac i ddarparu'r cynhyrchion a'r gwasanaethau yr ydych wedi gofyn amdanynt.\n\n Ni fydd y wybodaeth rydych wedi'i darparu yn cael ei defnyddio at ddibenion marchnata, a dim ond cyfathrebiadau perthnasol i'ch cwrs y byddwch chi'n derbyn oddi wrthym ni.\n\n Dim ond gydag unigolion cymeradwyedig sy'n gysylltiedig â TTC ac sy'n cynorthwyo i ddarparu'r cynnyrch neu'r gwasanaeth yr ydych wedi gofyn amdano y bydd eich gwybodaeth yn cael ei rhannu.\n\n Mae gennych hawl i ofyn am gopi o'r wybodaeth y mae TTC Group yn ei chadw amdanoch chi, mae gennych hefyd yr hawl i'ch gwybodaeth gael ei dileu neu ei chywiro.\n\n Os oes gennych ymholiad mewn perthynas â'ch data, cysylltwch â 0330 024 1805, fel arall, gallwch anfon e-bost <1>yourdata@ttc-uk.com</1> neu ysgrifennu at y Swyddog Diogelu Data, TTC Group, Hadley Park East, Telford TF1 6QJ.\n\n Am ragor o wybodaeth am Bolisi Preifatrwydd TTC Group ewch i <3>https://www.ttc-uk.com/aboutus/#privacy</3>"
    },
    RebookInformation: {
        REBOOKING: "Ail-archebu eich cwrs",
        REBOOKING_INFORMATION_CLASSROOM: "Mae eich archeb cwrs wreiddiol ar gyfer <strong>{{- eventNameCy}}</strong>, yn <strong><3>{{- address}}</3></strong> ar <strong>{{date}}</strong> am <strong>{{time}}</strong>.",
        REBOOKING_INFORMATION_DIGITAL_ATTENDEE: "Roedd y cwrs a archebwyd yn wreiddiol yn gwrs Digidol ar gyfer <strong>{{- eventNameCy}}</strong> ar <strong>{{date}}</strong> am <strong>{{time}}</strong>.",
        REBOOKING_INFORMATION_DIGITAL_CA: "Roeddech wedi archebu lle ar gwrs yn wreiddiol yn <strong>{{- venueName}}</strong> ar gyfer <strong>{{- eventNameCy}}</strong> ar <strong>{{date}}</strong> am <strong>{{time}}</strong>.",
        REBOOKING_FOOTNOTE: "Dewiswch y dyddiad ac amser newydd sydd orau gennych.",
        CANCEL_REBOOKING: "Canslo ail-archebu"
    },
    OutstandingBalancePaymentConfirmation: {
        SUCCESSFUL_PAYMENT: "Mae eich taliad wedi bod yn llwyddiannus.",
        BOOKING_REFERENCE: "Cyfeirnod Archebu",
        YOUR_PERSONAL_DETAILS: "Eich manylion personol",
        WELCOME: " Isod mae manylion y cwrs <1>{{- eventTypeNameCy}}</1> rydych wedi archebu lle arno",
        CHANGE_BOOKING: "Diolch am newid eich cwrs.",
        NEW_BOOKING_REFERENCE: "Mae eich archeb newydd wedi’i chadarnhau - cyfeirnod archebu:",
        PRINT: "Argraffu manylion y cwrs"
    },
    Dashboard: {
        WELCOME_HEADER: "Croeso {{attendeeName}}",
        BOOKING_REFERENCE: "Cyfeirnod archebu TTC",
        WELCOME_TEXT: "rydych wedi cael eich cyfeirio ar gyfer {{eventTypeName}} gyda dyddiad terfynol o {{expiryDate}}",
        CHANGE_BOOKING: "Diolch am newid eich cwrs.",
        NEW_BOOKING_REFERENCE: "Mae eich archeb newydd wedi’i chadarnhau - cyfeirnod archebu:",
        PRINT: "Argraffu manylion y cwrs",
        LANGUAGE_DEPENDENT_FIELD: "{{- welsh}}",
        YOUR_COURSE: "Eich cwrs",
        JOIN_COURSE: "Ymunwch â'r cwrs",
        TEST_MEETING: "Dechrau cyfarfod prawf",
        NEED_TO_REBOOK: "Angen ail-archebu neu newid y dyddiad / lleoliad?",
        PRICE: "Pris",
        SCHEDULED_PAYMENT: "Swm taliad a drefnwyd",
        OUTSTANDING_BALANCE: "Balans sy’n ddyledus",
        PAY_OUTSTANDING_BALANCE: "Talu’r gweddill sy’n ddyledus",
        ZOOM_ASSISTANCE_HEADER: "Prawf cyfarfod Zoom ddim yn gweithio?",
        ZOOM_ASSISTANCE: "Os ydych yn cael anawsterau wrth gwblhau eich cyfarfod Zoom prawf yna anfonwch e-bost atom yn <1>onlinecoursesupport@ttc-uk.com</1>. Cynhwyswch eich <strong>cyfeirnod archebu TTC</strong>, <strong>enw </strong> a <strong>rhif cyswllt</strong> er mwyn i'n tîm cymorth ar-lein gysylltu eto.",
        RECOMMEND_ASSISTANCE: "<strong>Rydych chi wedi archebu cwrs Digidol. Bydd hwn yn cael ei gyflwyno gan ddefnyddio Zoom.</strong><br />Rydym yn argymell clicio ar y ddolen er mwyn cwblhau ymarfer o ymuno â'ch cwrs Ymwybyddiaeth Gyrwyr Digidol gyda TTC.",
        UNABLE_TO_JOIN_ZOOM: "Methu ymuno â'r cwrs. Ni chanfuwyd dolen galwad Zoom.",
        COURSE_DETAILS_TITLE: "Manylion y Cwrs",
        PAYMENT_DETAILS_TITLE: "Manylion Talu",
        ADDITONAL_INFORMATION: "Gwybodaeth Ychwanegol",
        ADDRESS: "Cyfeiriad",
    },
    CourseCoreDetails: {
        LANGUAGE: "Iaith",
        Languages,
        DELIVERED_ONLINE: "Cynhelir ar-lein"
    },
    BookingActions: {
        RESEND_BOOKING_CONFIRMATION: "Ail-anfon cadarnhad archeb",
        PRINT_BOOKING_DETAILS: "Argraffu manylion archeb",
        TERMS_AND_CONDITIONS: "Telerau ac Amodau",
    },
    RebookButton: {
        CANNOT_REBOOK_INFO: "Ni ellir gwneud unrhyw newidiadau i'ch cwrs ar hyn o bryd. ",
        CALLUS: "Ffoniwch 0330 024 1805 ac bydd un o'n Cynghorwyr yn hapus i helpu.",
        REBOOK: "Ail-archebu yma",
        REBOOK_INTRO: "Oes angen newid dyddiad neu leoliad eich cwrs?",
        COURSE_ATTENDED_AND_COMPLETED: "Mae ein cofnodion yn dangos eich bod wedi cwblhau'r cwrs hwn",
        REBOOK_DASHBOARD: "Ail-archebu"
    },
    OutstandingBalanceButton: {
        PAY_OUTSTANDING_BALANCE: "Talu’r gweddill sy’n ddyledus",
        PAY_OUTSTANDING_BALANCE_QUESTION: "Ydych chi am dalu'ch gweddill sy'n ddyledus?"
    },
    ResendConfirmationButton: {
        RESEND_CONFIRMATION_EMAIL: "Ail-anfonwch e-bost gyda chadarnhad archebu"
    },
    PrintedBookingConfirmation: {
        SALUTATION: "Annwyl {{- forename}}",
        COURSE_DETAILS: "Manylion y cwrs",
        ZOOM_DETAILS: "MANYLION ZOOM",
        DIGITAL_COURSE_LINK: "Dolen Cwrs Digidol",
        DIGITAL_COURSE_MEETING_ID: "ID Cyfarfod Cwrs Digidol",
        DIGITAL_COURSE_PASSWORD_ATTENDEE: "Cyfrinair Mynychwr Cwrs Digidol",
        BOOKING_REFERENCE: "Cyfeirnod archebu",
        COURSE_TYPE: "Y cwrs rydych wedi’i ddewis",
        COURSE_DATE: "Dyddiad",
        COURSE_TIME: "Amser cwrs",
        COURSE_LOCATION: "Lleoliad",
        COURSE_LANGUAGE: "Iaith y cwrs",
        AMOUNT_PAID: "Swm wedi ei dalu",
        OUTSTANDING_BALANCE: "Balans sy’n ddyledus",
        VENUE_NOTE: "Nodyn am leoliad",
        BOOKING_NOTE: "Nodyn am archeb",
        TERMS_AND_CONDITIONS: "Telerau ac amodau",
        VALEDICTION: "Cofion,",
        FLEXI_PAY: "Talu hyblyg",
        Languages
    },
    RebookingSignIn: {
        NEW_CODE: "Roedd y côd a ddarparwyd gennych yn gywir ond wedi dod i ben, mae côd newydd wedi'i e-bostio atoch.",
        SUPERSEDED: "Mae'r côd a ddarparwyd gennych wedi'i ddisodli gan e-bost mwy newydd.",
        ACCOUNT_NOT_RECOGNISED: "Ni chafodd yr wybodaeth am y cyfrif a ddarparwyd ei chydnabod.",
        CODE_NOT_RECOGNISED: "Ni chafodd y côd a roddwyd ei gydnabod.",
        ACCOUNT_ALREADY_LOCKED: "Mae eich cyfrif wedi'i gloi ar hyn o bryd.",
        TRY_AGAIN: "Rhowch gynnig eto ar {{- accountLockedUntil}}.",
        EMAIL_REQUEST_LIMIT_REACHED: "Mae eich cyfrif wedi ei gloi dros dro gan eich bod wedi gofyn am ormod o gyfrineiriau un tro.",
        VALIDATION_ATTEMPTS_LIMIT_REACHED: "Mae eich cyfrif wedi'i gloi dros dro gan eich bod wedi rhoi’r cyfrinair un tro yn anghywir gormod o weithiau.",
        EMAIL_INVALID_TEXT: "Rhowch e-bost dilys",
        EMAIL_REQUIRED_TEXT: "Mae angen e-bost",
        BOOKING_REFERENCE_INVALID_TEXT: "Rhowch gyfeirnod archebu dilys",
        BOOKING_REFERENCE_REQUIRED_TEXT: "Mae angen cyfeirnod archebu",
        POSTCODE_INVALID_TEXT: "Rhowch god post dilys",
        POSTCODE_REQUIRED_TEXT: "Mae angen cod pos",
        CODE_INVALID_TEXT: "Rhaid i chi roi'r côd 6 digid o'r e-bost",
        EMAIL_ADDRESS: "Cyfeiriad e-bost",
        BOOKING_REFERENCE: "Cyfeirnod archebu TTC",
        POSTCODE: "Côd post",
        TRANSFERRING: "Trosglwyddo...",
        HEADER_TEXT: "Cyrsiau a gyfeiriwyd gan yr heddlu a'r llys mewngofnodi",
        ASTERISK_LEGEND: "(<1>*</1>) Yn dynodi maes gorfodol.",
        OTP_INITIAL_TEXT: "I barhau, mae angen i chi wirio'ch cyfeiriad e-bost gyda chod pas un-amser.",
        SEND_CODE: "Anfonwch un-amser-god",
        NEW_CUSTOMER: "Cwsmer Newydd? Cofrestrwch yma",
        OTP: "Cyfrinair un-amser",
        RESEND_MESSAGE: "Rhowch y cyfrinair un-amser yr ydym wedi ei e-bostio atoch. I ail-anfon,",
        CLICK_HERE: "cliciwch yma.",
        SUBMITTING: "Wrthi'n cyflwyno…",
        VERIFY: "Gwirio",
        VERIFYING: "Yn gwirio...",
        CHECK_SPAM_FOLDER: "Os and ydych wedi derbyn yr e-bost, gwiriwch eich ffolder sbam/sothach.",
        BOOKING_REF_TOOLTIP: "I ddod o hyd i'ch cyfeirnod archebu TTC, gwiriwch yr e-bost sy'n cadarnhau eich archeb. Mae'n god 8 digid."
    },
    FlexiPayModal: {
        TERMS_AND_CONDITIONS_TEXT: "<0>Gweler T&Cs</0> am ragor o wybodaeth",
        CHOOSE_BOOKING_TYPE: "Dewis eich math o archebu",
        CHOOSE_FLEXI: "Dewis archebu hyblyg",
        CHOOSE_STANDARD: "Dewis archebu safonol",
        FLEXI_BOOKING: "Archebu hyblyg",
        STANDARD_BOOKING: "Archebu safonol",
        FLEXI_FEE: "Ffi archebu hyblyg",
        MOST_POPULAR: "Mwyaf poblogaidd",
    }
};
