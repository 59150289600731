import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import moment from "moment";

interface DurationPickerProps {
    label?: string;
    eventDuration: moment.Duration;
    showErrors: boolean;
    onChange: (value: moment.Duration, valid: boolean) => void;
    freeEntry?: boolean;
    showHours?: boolean;
    showHeader?: boolean;
}

export const DurationPicker: React.FC<DurationPickerProps> = ({ eventDuration, onChange, showErrors, label, showHours, freeEntry, showHeader = true }) => {

    const updateMinutes = React.useCallback((value: string, valid: boolean) => {
        if (value === undefined) {
            return;
        }

        const duration = moment.duration(`${eventDuration ? eventDuration.hours() : "00"}:${value}:00`);

        onChange(duration, valid);
    }, [eventDuration, onChange]);

    const updateMinutesNumber = React.useCallback((value: number, valid: boolean) => {
        if (value === undefined) {
            return;
        }

        const duration = moment.duration(`${eventDuration ? eventDuration.hours() : "00"}:${value}:00`);

        onChange(duration, valid);
    }, [eventDuration, onChange]);

    const updateHours = React.useCallback((value: string, valid: boolean) => {
        if (value === undefined) {
            return;
        }

        const duration = moment.duration(`${value}:${eventDuration ? eventDuration.minutes() : "00"}:00`);

        onChange(duration, valid);
    }, [eventDuration, onChange]);

    const updateHoursNumber = React.useCallback((value: number, valid: boolean) => {
        if (value === undefined) {
            return;
        }

        const duration = moment.duration(`${value}:${eventDuration ? eventDuration.minutes() : "00"}:00`);

        onChange(duration, valid);
    }, [eventDuration, onChange]);

    const labelText = label ?? "";
    return (
        <>
            {showHeader && label && <p>{label}s</p>}
            <Grid>
                { showHours !== false && <Grid.Column width={8}>
                    { !freeEntry && <Input.Dropdown
                        value={eventDuration && eventDuration.hours().toString()}
                        label={`${labelText} [Hours]`}
                        showErrors={showErrors}
                        required
                        options={Array.from(Array(24).keys()).map(item => ({ text: item.toString(), value: item.toString() }))}
                        onChange={updateHours}
                    />
                    }
                    { freeEntry && <Input.Number
                        value={eventDuration && eventDuration.hours()}
                        label={`${labelText} [Hours]`}
                        showErrors={showErrors}
                        required
                        onChange={updateHoursNumber}
                    />
                    }
                </Grid.Column>
                }
                <Grid.Column width={8}>
                    { !freeEntry && <Input.Dropdown
                        value={eventDuration && eventDuration.minutes().toString()}
                        label="[Minutes]"
                        showErrors={showErrors}
                        required
                        options={Array.from(Array(12).keys()).map(item => item * 5).map(item => ({ text: item.toString(), value: item.toString() }))}
                        onChange={updateMinutes}
                    /> }
                    { freeEntry && <Input.Number
                        value={eventDuration && eventDuration.minutes()}
                        label={`${showHours ? "" : labelText} [Minutes]`}
                        showErrors={showErrors}
                        required
                        onChange={updateMinutesNumber}
                    /> }
                </Grid.Column>
            </Grid>
        </>
    );
};
