import { ApplicationsOwnState } from "@common/appCommonState";
import { Location } from "redux-little-router";

export type AppState = ApplicationsOwnState & { router: Location };

export const bookingIdSelector = (state: AppState) => state.router.params.bookingId;

export const pathSelector = (state: AppState) => state.router.pathname;

export const currentAppSelector = (state: AppState) => state.app;
