import * as React from "react";
import { Authorize } from "reauthorize";
import { AttendeeRole } from "@common/auth/model";
import { useTranslation } from "react-i18next";
import classes from "./DashboardRebookButton.module.scss";
import { Booking } from "../bookings/models";
import { DorsAttendanceStatusesEnum } from "@common/crud/dorsBooking/model";
import { Button } from "@booking/common/Button";
import { useSelector } from "react-redux";
import { appSettingsSelector } from "@booking/bookings/selectors";

interface DashboardRebookButtonProps {
    booking: Booking;
    courseAllowsRebooking: boolean;
    isFreshBooking: boolean;
    path: string;
}

export const DashboardRebookButton: React.FC<DashboardRebookButtonProps> = ({ courseAllowsRebooking, path, isFreshBooking, booking }) => {
    const [t] = useTranslation("RebookButton");
    const settings = useSelector(appSettingsSelector);
    const expiredBooking = !(booking && booking.expiryDate && booking.expiryDate.isAfter(Date.now()));
    const bookingStatusAllowsRebooking = booking && (booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.BookingPending
    || booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.Booked ||
    (settings.testSettings?.allowUnknownStatusDorsBookingToRebook && booking.dorsAttendanceStatus === DorsAttendanceStatusesEnum.NotSet));

    const attendedAndCompleted = booking?.dorsAttendanceStatus === DorsAttendanceStatusesEnum.AttendedAndCompleted;
    const canRebook = courseAllowsRebooking && bookingStatusAllowsRebooking;

    let noRebookMessage = "";
    if (!expiredBooking) {
        if (attendedAndCompleted) {
            noRebookMessage = t("COURSE_ATTENDED_AND_COMPLETED");
        } else if (!canRebook) {
            noRebookMessage = t("CANNOT_REBOOK_INFO") + t("CALLUS");
        }
    }

    return (
        <Authorize authorize={AttendeeRole}>
            {!expiredBooking && !isFreshBooking &&
                <div className={classes.container}>
                    <div className={classes.horizontalGridDivider} />
                    <div className={classes.rebookSection}>
                        {!attendedAndCompleted && <p className={classes.rebookIntro}>{t("REBOOK_INTRO")}</p>}
                        {canRebook &&
                            <Button variant={"primary"} className={classes.rebookButton} >
                                <a href={`${path}/rebook`}>
                                    {t("REBOOK_DASHBOARD")}
                                </a>
                            </Button>
                        }
                        {noRebookMessage &&
                            <div className={classes.noRebookMessage}>
                                {noRebookMessage}
                            </div>
                        }
                    </div>
                </div>
            }
        </Authorize>
    );
};
