import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "redux-little-router";
import { Grid, Menu } from "semantic-ui-react";
import { currentUserSelector } from "@common/auth";
import {
    eventInstanceGroupByIdSelector,
    eventInstanceSelector,
    variableBasePathSelector
} from "../selectors";
import { ContextItems } from "./details/ContextItems";

interface EiSideBarProps {
    children: JSX.Element;
}

export const EiSideBar: React.FC<EiSideBarProps> = ({ children }) => {

    const eventInstance = useSelector(eventInstanceSelector);
    const groupDetails = useSelector(eventInstanceGroupByIdSelector(eventInstance?.groupId));
    const basePath = useSelector(variableBasePathSelector);
    const currentUser = useSelector(currentUserSelector);

    // Need to account for rotated tablet.
    const isTabletOrLess = window.screen.width <= 1024;
    return (
        <Grid container stackable className="nomargintop">
            <Grid.Row>
                <Grid.Column width={isTabletOrLess ? 4 : 3} as={Grid} padded>
                    <Grid.Column vertical as={Menu}>
                        <Menu.Item name="EventInstances" as={Link} href={basePath}>
                            All Courses
                        </Menu.Item>
                        <ContextItems groupDetailsByRoute={groupDetails} currentUser={currentUser} eventInstance={eventInstance} basePath={basePath} />
                    </Grid.Column>
                </Grid.Column>
                <Grid.Column width={isTabletOrLess ? 12 : 13} className="ei-details">
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
