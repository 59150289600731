import { DateFormat } from "@common/crud/common/DateTimeFormats";
import * as React from "react";
import { Grid, Table } from "semantic-ui-react";
import { CountryTitle } from "./DdrsCountryTitle";

interface DdrsPlannedEventsOverviewTableHeaderProps {
    months: moment.Moment[];
}

export const DdrsPlannedEventsOverviewTableHeader: React.FC<DdrsPlannedEventsOverviewTableHeaderProps> = ({ months }) => {

    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ minWidth: "100px" }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6} textAlign="center">Months:</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2} textAlign="center">
                                <p>Name</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={12} textAlign="center" style={{ minWidth: "100px" }}>
                                    Total
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <CountryTitle abbreviation="E" toolTip="England Courses" />
                            <CountryTitle abbreviation="W" toolTip="Wales Courses" />
                            <CountryTitle abbreviation="S" toolTip="Scotland Courses" />
                            <CountryTitle abbreviation="NI" toolTip="Northern Ireland Courses" />
                            <CountryTitle abbreviation="All" toolTip="All Courses" />
                            <Grid.Column width={2} textAlign="center">
                                <p>Seats</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>
                {months.map(month => (<Table.HeaderCell key={month.month()}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={12} textAlign="center" style={{ minWidth: "100px" }}>
                                {month.format(DateFormat.MonthYear)}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <CountryTitle abbreviation="E" toolTip="England Courses" />
                            <CountryTitle abbreviation="W" toolTip="Wales Courses" />
                            <CountryTitle abbreviation="S" toolTip="Scotland Courses" />
                            <CountryTitle abbreviation="NI" toolTip="Northern Ireland Courses" />
                            <CountryTitle abbreviation="All" toolTip="All Courses" />
                            <Grid.Column width={2} textAlign="center">
                                <p>Seats</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.HeaderCell>))}
            </Table.Row>
        </Table.Header >
    );
};
