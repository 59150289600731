import * as React from "react";
import { connect } from "react-redux";
import { Link } from "redux-little-router";
import { Button, Grid } from "semantic-ui-react";
import { push } from "redux-little-router";
import { toRouterQuery } from "@common/model";
import { AsyncDispatch } from "@common/redux-helpers";
import { SearchOptions } from "@common/crud/trainerAttributeDefinition/model";
import { Filters } from "@common/crud/trainerAttributeDefinition/components/Filters";
import { TrainerAttributeDefinitionListModel } from "../model";
import { trainerAttributeDefinitionsSelector, SelectorState } from "../selectors";
import { allEventTypesSelector } from "@common/crud/eventType/selectors";
import { EventType, EventTypeState } from "@common/crud/eventType/model";

import { AllItems } from "./AllItems";
import { trainerAttributeDefinitionSearchOptionsSelector, basePathSelector } from "../selectors";

export interface AllProps {
    trainerAttributes: TrainerAttributeDefinitionListModel[];
    basePath: string;
    eventTypes: EventType[];
    routerSearchOptions: SearchOptions;
}

export interface DispatchProps {
    push: (path: string, options: SearchOptions) => void;
}

class AllInternal extends React.Component<AllProps & DispatchProps> {
    public render() {
        const setQuery = (options: SearchOptions) => this.props.push(this.props.basePath, options);

        const onFilterStateChange = (options: SearchOptions) => {
            setQuery(options);
        };

        return (
            <Grid container stackable className="nomargintop">
                <Grid.Row>
                    <Grid.Column width={14}>
                        <h1>Trainer Attribute Definitions</h1>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="right">
                        <Button icon="add" content="Create" as={Link} href={`${this.props.basePath}/create`} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Filters
                            onFilterStateChange={onFilterStateChange}
                            searchOptions={this.props.routerSearchOptions}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <AllItems
                            trainerAttributes={this.props.trainerAttributes}
                            basePath={this.props.basePath}
                            eventTypes={this.props.eventTypes}
                            routerSearchOptions={this.props.routerSearchOptions} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state: SelectorState & EventTypeState): AllProps {
    return {
        basePath: basePathSelector(state),
        trainerAttributes: trainerAttributeDefinitionsSelector(state),
        eventTypes: allEventTypesSelector(state),
        routerSearchOptions: trainerAttributeDefinitionSearchOptionsSelector(state)
    };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
    return {
        push: (path: string, options: SearchOptions) => dispatch(push({ pathname: path, query: toRouterQuery(options) }))
    };
}

export const All = connect(mapStateToProps, mapDispatchToProps)(AllInternal);

