import * as React from "react";
import { Grid, Label, Menu, Popup } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { CurrentUser } from "@common/auth";
import {
    EventManagementAdminRoles,
    EventManagementAdminRolesAndTrainers,
    MonitorRole,
    TrainerRole,
    TtcClientAdvisorRole,
    TtcSeniorClientAdvisorRole,
    TtcTrainerAdminRole
} from "@common/auth/model";

import { EventInstance } from "../..";
import { AttendeesNotDisplayingReason, EnquiryType, shouldAttendeesBeDisplayed, ShouldAttendeesBeDisplayedResult } from "../../model";
import { useSelector } from "react-redux";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { Authorize } from "reauthorize";
import { isEmpty } from "lodash";
import { GetNumberOfAttendeesWithSpecialRequirements, GetNumberOfNotes, isDrinkDriveCourse } from "@common/crud/eventInstance/helpers";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { BookingTypeEnum, WorkflowTypeEnum } from "@common/crud/eventType/model";
import { EventInstanceGroupApi } from "@common/crud/eventInstanceGroup/eventInstanceGroupApi";
import { DeliveryTypeEnum } from "@common/crud/common/DeliveryTypeEnum";
import { BusinessLineType } from "@common/redux-helpers";
import { isBusinessLineTypeCorporateOrConstruction, isBusinessLineTypePoliceOrCourt, isEventInstanceWorkflowConstruction,
    isEventInstanceWorkflowCorporate } from "@common/global/CommonHelpers";

export interface ContextItemsProps {
    basePath: string;
    eventInstance: EventInstance;
    currentUser: CurrentUser;
    groupDetailsByRoute: EventInstanceGroupModel;
}

export const ContextItems: React.FC<ContextItemsProps> = (props) => {
    const { currentUser, eventInstance, groupDetailsByRoute } = props;
    const app = useSelector(appSelector);
    const isDdrs = isDrinkDriveCourse(eventInstance);
    const [enableAttendees, setEnableAttendees] = React.useState<ShouldAttendeesBeDisplayedResult>({ result: false });
    const [allowMonitoring, setAllowMonitoring] = React.useState<boolean>(false);
    const [showMonitoringToUser, setShowMonitoringToUser] = React.useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
    const numberOfAttendeesWithSpecialRequirements = React.useMemo(() => GetNumberOfAttendeesWithSpecialRequirements(eventInstance),[eventInstance]);
    const numberOfNotes = React.useMemo(() => GetNumberOfNotes(eventInstance, app),[eventInstance, app]);
    const businessLineType = isEventInstanceWorkflowCorporate(eventInstance)
        ? BusinessLineType.Corporate
        : isEventInstanceWorkflowConstruction(eventInstance)
            ? BusinessLineType.Construction
            : BusinessLineType.PoliceAndCourt;

    const competencyReportEnabledForAdmin = eventInstance.workflowType !== WorkflowTypeEnum.BusinessDriver
        && eventInstance.competencyReportRequired && app === Apps.Admin;
    const competencyReportEnabledForTrainer = eventInstance.workflowType !== WorkflowTypeEnum.BusinessDriver
        && eventInstance.competencyReportRequired && app === Apps.Trainer;

    React.useEffect(() => {
        async function GetGroupForEventInstance() {
            const api = new EventInstanceGroupApi();
            const eventInstanceGroup = await api.getByEventInstanceId(eventInstance.id);
            setAllowMonitoring(eventInstanceGroup?.eventInstanceGroupItems?.some(item => item.monitoring));
        }

        if (!isEmpty(eventInstance)) {
            const currentUserIsMonitoredTrainerOnCourse = !!eventInstance.monitoredTrainersIds?.find(t => t === currentUser.id);
            const currentUserIsMonitorOnCourse = !!eventInstance.monitorTrainers?.find(t => t.id === currentUser.id);
            const currentUserIsMonitor = !!currentUser.roles.find(r => r === MonitorRole);
            setShowMonitoringToUser(
                !!currentUser.roles.find(r => r === TtcTrainerAdminRole) ||
                currentUserIsMonitorOnCourse ||
                currentUserIsMonitoredTrainerOnCourse ||
                currentUserIsMonitor);
            setEnableAttendees(shouldAttendeesBeDisplayed(currentUser, eventInstance));
            setAllowMonitoring(eventInstance.monitorTrainers?.length > 0 ||
                !!groupDetailsByRoute?.eventInstanceGroupItems?.some(item => item.monitoring));
            setDataLoaded(true);

            if (isEmpty(groupDetailsByRoute) &&
                !eventInstance.monitorTrainers?.length &&
                eventInstance.workflowType === WorkflowTypeEnum.DDRS) {
                GetGroupForEventInstance();
            }
        }
    }, [currentUser, eventInstance, groupDetailsByRoute]);

    if (!dataLoaded) {
        return <></>;
    }

    return (
        <>
            <Menu.Item name="Details" as={Link} href={`${props.basePath}/${props.eventInstance.id}`}>Details</Menu.Item>
            {
                isBusinessLineTypePoliceOrCourt(businessLineType) && enableAttendees.result &&
                <Menu.Item name="Attendees" as={Link} href={`${props.basePath}/${props.eventInstance.id}/attendees`}>Attendees</Menu.Item>
            }
            {
                isBusinessLineTypeCorporateOrConstruction(businessLineType)
                    && enableAttendees.result && eventInstance.delegatesRequired !== false &&
                <Menu.Item name="Delegates" as={Link} href={`${props.basePath}/${props.eventInstance.id}/delegates`}>Delegates</Menu.Item>
            }
            {
                isBusinessLineTypePoliceOrCourt(businessLineType) && !enableAttendees.result &&
                <Popup
                    content={enableAttendees.reason && enableAttendees.reason === AttendeesNotDisplayingReason.Locked ?
                        "Attendees tab is not available as the register has been processed" : "Attendees tab is not available until 1 hour before the course"}
                    trigger={<Menu.Item name="Attendees" disabled>Attendees</Menu.Item>}
                    position='top center'
                />
            }
            {
                isBusinessLineTypeCorporateOrConstruction(businessLineType) && !enableAttendees.result &&
                <Popup
                    content={enableAttendees.reason && enableAttendees.reason === AttendeesNotDisplayingReason.Locked ?
                        "Delegates tab is not available as the register has been processed" : "Delegates tab is not available until 24 hours before the course"}
                    trigger={<Menu.Item name="Delegates" disabled>Delegates</Menu.Item>}
                    position='top center'
                />
            }
            {
                eventInstance.onRoadReportRequired && (app === Apps.Admin || (app === Apps.Trainer && enableAttendees.result)) &&
                <Menu.Item name="On Road Reports" as={Link} href={`${props.basePath}/${props.eventInstance.id}/on-road-reports`}>On Road Reports</Menu.Item>
            }
            {
                eventInstance.onRoadReportRequired && app === Apps.Trainer && !enableAttendees.result &&
                <Popup
                    content={enableAttendees.reason && enableAttendees.reason === AttendeesNotDisplayingReason.Locked ?
                        "On Road Reports tab is not available as the register has been processed" :
                        "On Road Reports tab is not available until 24 hours before the course"}
                    trigger={<Menu.Item name="On Road Reports" disabled>On Road Reports</Menu.Item>}
                    position='top center'
                />
            }
            {
                (competencyReportEnabledForAdmin || (competencyReportEnabledForTrainer && enableAttendees.result)) &&
                <Menu.Item
                    name="Competency Report" as={Link} href={`${props.basePath}/${props.eventInstance.id}/competency-reports`}>
                        Competency Reports
                </Menu.Item>
            }
            {
                competencyReportEnabledForTrainer && !enableAttendees.result &&
                <Popup
                    content={enableAttendees.reason && enableAttendees.reason === AttendeesNotDisplayingReason.Locked ?
                        "Competency Reports tab is not available as the register has been processed" :
                        "Competency Reports tab is not available until 24 hours before the course"}
                    trigger={<Menu.Item name="Competency Reports" disabled>Competency Reports</Menu.Item>}
                    position='top center'
                />
            }
            {
                (isBusinessLineTypePoliceOrCourt(businessLineType)
                    && (app === Apps.Trainer || app === Apps.Admin)
                    && eventInstance.eventInstanceDeliveryType === DeliveryTypeEnum.Onsite) &&
                <Menu.Item name="HealthAndSafety" as={Link} href={`${props.basePath}/${props.eventInstance.id}/health-and-safety`}>Health and Safety</Menu.Item>
            }
            <Menu.Item name="Trainers" as={Link} href={`${props.basePath}/${props.eventInstance.id}/trainers`}>Trainers</Menu.Item>
            <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <Menu.Item name="Communications" as={Link} href={`${props.basePath}/${props.eventInstance.id}/communications`}>
                    <Grid>
                        <Grid.Column width={10} verticalAlign="middle">Notes</Grid.Column>
                        <Grid.Column width={4}>
                            {numberOfNotes > 0 && <Label circular content={numberOfNotes} />}
                        </Grid.Column>
                    </Grid>
                </Menu.Item>
            </Authorize>
            {
                (app === Apps.Trainer || app === Apps.Admin) && isDdrs &&
                <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                    <Menu.Item name="Scores" as={Link} href={`${props.basePath}/${props.eventInstance.id}/scores`}>
                        Scores
                    </Menu.Item>
                </Authorize>
            }
            {
                (app === Apps.Admin) && isDdrs &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="Certificates" as={Link} href={`${props.basePath}/${props.eventInstance.id}/certificates`}>
                        Certificates
                    </Menu.Item>
                </Authorize>
            }
            {
                (app === Apps.Admin) && isBusinessLineTypeCorporateOrConstruction(businessLineType) &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="CourseClosure" as={Link} href={`${props.basePath}/${props.eventInstance.id}/course-closure`}>
                        Course Closure
                    </Menu.Item>
                </Authorize>
            }
            {
                (app === Apps.Admin) && isBusinessLineTypeCorporateOrConstruction(businessLineType)
                    && (eventInstance.bookingType === BookingTypeEnum.Open) &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="Contacts" as={Link} href={`${props.basePath}/${props.eventInstance.id}/contacts`}>
                        Contacts
                    </Menu.Item>
                </Authorize>
            }
            {
                (app === Apps.Admin) && isBusinessLineTypeCorporateOrConstruction(businessLineType)
                    && (eventInstance.bookingType === BookingTypeEnum.Closed) &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="Contacts" as={Link} href={`${props.basePath}/${props.eventInstance.id}/closed-contacts`}>
                        Contacts
                    </Menu.Item>
                </Authorize>
            }
            {eventInstance.delegatesRequired !== false && <Authorize authorize={EventManagementAdminRolesAndTrainers}>
                <Menu.Item name="SpecialRequirements" as={Link} className="mobile-or-desktop-view"
                    href={`${props.basePath}/${props.eventInstance.id}/special-requirements`}>
                    <Grid>
                        <Grid.Column width={11} verticalAlign="middle">Special Requirements</Grid.Column>
                        <Grid.Column width={4} className="not-hidden-on-mobile">
                            {numberOfAttendeesWithSpecialRequirements > 0 && <Label circular content={numberOfAttendeesWithSpecialRequirements} />}
                        </Grid.Column>
                    </Grid>
                </Menu.Item>
                <Menu.Item name="SpecialRequirements" as={Link} className="tablet-view"
                    href={`${props.basePath}/${props.eventInstance.id}/special-requirements`}>
                    <Grid>
                        <Grid.Column width={10} verticalAlign="middle">Spec Reqs</Grid.Column>
                        <Grid.Column width={4}>
                            {numberOfAttendeesWithSpecialRequirements > 0 && <Label circular content={numberOfAttendeesWithSpecialRequirements} />}
                        </Grid.Column>
                    </Grid>
                </Menu.Item>
            </Authorize>}
            {app === Apps.Admin &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="Files" as={Link} href={`${props.basePath}/${props.eventInstance.id}/files`}>
                        Files
                    </Menu.Item>
                </Authorize>
            }
            {app === Apps.Trainer &&
                <Menu.Item name="Files" as={Link} href={`${props.basePath}/${props.eventInstance.id}/files`}>
                    Files
                </Menu.Item>
            }
            {
                app === Apps.Admin &&
                <Menu.Item name="History" as={Link} href={`${props.basePath}/${props.eventInstance.id}/history`}>History</Menu.Item>
            }
            {eventInstance.delegatesRequired !== false && <Authorize authorize={[TtcClientAdvisorRole, TtcSeniorClientAdvisorRole]}>
                <Menu.Item
                    name="Bookings History"
                    as={Link}
                    href={`${props.basePath}/${props.eventInstance.id}/bookings-history`}>
                    Bookings History
                </Menu.Item>
            </Authorize>}
            {eventInstance.bookingType === BookingTypeEnum.Open && (
                <Authorize authorize={[TtcClientAdvisorRole, TtcSeniorClientAdvisorRole]}>
                    <Menu.Item
                        name="Finance"
                        as={Link}
                        href={`${props.basePath}/${props.eventInstance.id}/finance`}>
                        Finance
                    </Menu.Item>
                </Authorize>
            )}
            {eventInstance.bookingType === BookingTypeEnum.Open
            && businessLineType === BusinessLineType.Construction
            && eventInstance.enquiryType !== EnquiryType.NotEnquiry  && (
                <Authorize authorize={[TtcClientAdvisorRole, TtcSeniorClientAdvisorRole]}>
                    <Menu.Item
                        name="Enquiries"
                        as={Link}
                        href={`${props.basePath}/${props.eventInstance.id}/enquiries`}>
                        Enquiries
                    </Menu.Item>
                </Authorize>
            )}
            {allowMonitoring && showMonitoringToUser &&
                <Authorize authorize={[...EventManagementAdminRoles, MonitorRole, TrainerRole]}>
                    <Menu.Item name="Monitoring" as={Link} href={`${props.basePath}/${props.eventInstance.id}/monitoring`}>Monitoring</Menu.Item>
                </Authorize>}
            {app === Apps.Admin && businessLineType === BusinessLineType.Construction &&
                <Authorize authorize={[TtcClientAdvisorRole]}>
                    <Menu.Item name="Delegate Documents" as={Link} href={`${props.basePath}/${props.eventInstance.id}/delegateDocuments`}>
                        Delegate Documents
                    </Menu.Item>
                </Authorize>
            }

        </>
    );
};
