import * as React from "react";
import { TrainerAvailabilitySelectModel } from "@common/availabilityTrainer/model";
import { EventInstanceGroupModel } from "@common/crud/eventInstanceGroup/model";
import { EventInstance } from "@common/crud/eventInstance";

export interface AvailabilityContextOptions {
    eventInstanceGroup?: EventInstanceGroupModel;
    trainerAvailabilities?: Record<string, TrainerAvailabilitySelectModel[]>;
    distinctAvailability: TrainerAvailabilitySelectModel[];
    eventInstancesInGroup?: EventInstance[];
    isMultiDay?: boolean;
}

export const AvailabilityContext = React.createContext<AvailabilityContextOptions>({ distinctAvailability: [], isMultiDay: false });
export const useAvailabilityContext = () => React.useContext(AvailabilityContext);
