import * as React from "react";
import { Modal } from "@common/components";
import { Button } from "semantic-ui-react";
import { Spinner } from "@common/global";
import { TrainerAttribute } from "@common/crud/common/TrainerAttribute";
import moment from "moment";
import { TrainerMonitoringReportCreateEditModel } from "@common/crud/trainer/trainerMonitoringModel";
import { MuiDateField } from "@common/components/MuiDateField";
import { muiFutureDateValidator } from "@common/validation/futureDateValidator";

export interface SubmitIqaTeamFeedbackModalProps {
    iqaOpen: boolean;
    isSubmitting: boolean;
    currentAttribute: TrainerAttribute;
    onNextMonitoringDateUpdate: (value: moment.Moment, valid: boolean) => void;
    onNextDigitalMonitoringDateUpdate: (value: moment.Moment, valid: boolean) => void;
    closeIqaModal: () => void;
    setReportFormDataModel: (model: TrainerMonitoringReportCreateEditModel) => void;
    saveIqaFeedback: () => void;
    isDigitalEventInstanceDeliveryType: boolean;
}
export const SubmitIqaTeamFeedbackModal: React.FC<SubmitIqaTeamFeedbackModalProps> = (props) => {
    const {
        iqaOpen,
        isSubmitting,
        onNextMonitoringDateUpdate,
        onNextDigitalMonitoringDateUpdate,
        currentAttribute,
        closeIqaModal,
        saveIqaFeedback,
        isDigitalEventInstanceDeliveryType
    } = props;

    return (
        <Modal open={iqaOpen}>
            <Modal.Header>Submit IQA Team Feedback</Modal.Header>
            <Modal.Content>
                {isDigitalEventInstanceDeliveryType && <MuiDateField
                    label="Please confirm the recommended date for next monitoring"
                    value={currentAttribute?.nextDigitalMonitoringDue}
                    validation={[muiFutureDateValidator]}
                    onChange={onNextDigitalMonitoringDateUpdate}
                />}
                {!isDigitalEventInstanceDeliveryType && <MuiDateField
                    label="Please confirm the recommended date for next monitoring"
                    value={currentAttribute?.nextMonitoringDue}
                    validation={[muiFutureDateValidator]}
                    onChange={onNextMonitoringDateUpdate}
                />}
            </Modal.Content>
            <Modal.Actions>
                <Spinner active={isSubmitting}>
                    <Button onClick={closeIqaModal} className="cancel-action">Cancel</Button>
                    <Button onClick={saveIqaFeedback} positive>Yes</Button>
                </Spinner>
            </Modal.Actions>
        </Modal>
    );
};

