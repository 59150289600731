import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import * as resources from "./resources";

export function configureI18n() {
    return i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            supportedLngs: ["en", "cy"],
            fallbackLng: "en",
            interpolation: {
                escapeValue: false
            }
        });
}
