import { Button, Modal } from "semantic-ui-react";
import * as React from "react";
import { Error } from "@common/components/Error";
import momentDate from "moment";
import { DrinkDriveOffenderApi } from "@common/crud/drinkDriveOffenders";
import { DrinkDriveInitialBookingDateDetailModel } from "@common/crud/drinkDriveOffenders/model";
import { MuiDateField } from "@common/components/MuiDateField";

interface SetDrinkDriveInitialBookingDateProps {
    externalAttendeeId: string;
}

export const SetDrinkDriveInitialBookingDate: React.FC<SetDrinkDriveInitialBookingDateProps> = ({ externalAttendeeId }) => {

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<momentDate.Moment>();
    const [model, setModel] = React.useState<DrinkDriveInitialBookingDateDetailModel>();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [loadData, setLoadData] = React.useState<boolean>(true);
    const disableModalButton = React.useMemo(() => {
        return model?.drinkDriveInitialEventInstance === null || model?.drinkDriveInitialEventInstance.length === 0;
    },[model?.drinkDriveInitialEventInstance]);

    const disableSaveButton = React.useMemo(() => date > momentDate(), [date]);

    React.useEffect(() => {

        async function getData()  {
            const api = new DrinkDriveOffenderApi();
            const drinkDriveInitialBookingDateDetailResponseModel = await api.getDrinkDriveInitialBookingDateDetail(externalAttendeeId);
            setDate(drinkDriveInitialBookingDateDetailResponseModel.model.drinkDriveInitialBookingDate);
            setModel(drinkDriveInitialBookingDateDetailResponseModel.model);
        }

        if (loadData && externalAttendeeId) {
            setLoadData(false);
            getData();
        }
    },[externalAttendeeId, loadData]);

    function openCloseModal() {
        setOpenModal(!openModal);
        setDate(model.drinkDriveInitialBookingDate);
        setErrorMessage("");
    }

    async function save() {
        if (date < momentDate()) {
            const api = new DrinkDriveOffenderApi();
            await api.setDrinkDriveInitialBookingDate(model.id, date);
            setOpenModal(false);
            setLoadData(true);
        }
    }

    const dateChange = (newDate: momentDate.Moment) => {
        setDate(newDate);
        if (newDate < momentDate()) {
            setErrorMessage("");
        } else {
            setErrorMessage("Date cannot be greater than today.");
        }
    };

    return (
        <>
            <Button disabled={disableModalButton} icon="pencil" content="CHANGE 1ST BOOKING DATE" onClick={openCloseModal} />
            <Modal size="small" open={openModal}>
                <Modal.Header>Update attendee's booking date</Modal.Header>
                <Modal.Content>
                    <h4>Booking Date</h4>
                    <MuiDateField
                        value={model?.drinkDriveInitialBookingDate}
                        onChange={moment => dateChange(moment)}
                        required
                    />
                    { errorMessage.length > 0 && <Error errorMessage={errorMessage} /> }
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={openCloseModal}>Cancel</Button>
                    <Button disabled={disableSaveButton} onClick={save}>Save</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
