import * as React from "react";
import { GoogleTagManagerSettings as GoogleTagManagerSettingsDictionary } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const GoogleTagManagerSettings: ConfigurationAreaComponent<GoogleTagManagerSettingsDictionary> = ({ settings, hierarchy }) => {
    return (
        <>
            <SettingsGrid
                area="GoogleTagManager"
                hierarchy={hierarchy}
                settings={settings}
                items={[
                    { name: "CorporateBookingGtmId", label: "Commercial Booking GTM ID" }
                ]}
            />
            <p className="margin-top status-attention">
                All GTM IDs must already be defined in ApplicationBuilderExtensions scriptHashes, else the inline-script will not be permitted to run.
            </p>
        </>
    );
};
