import * as React from "react";
import "moment-duration-format";
import { Detail } from "@neworbit/simpleui-detail";
import { EventInstance } from "../..";
import { BookingTypeEnum } from "@common/crud/eventType/model";
import { FinanceDetailsModal } from "./FinanceDetailsModal";
import { formatPrice } from "../../../order/helpers";
import { OrganisationApi } from "@common/crud/organisation";
import { PurchaseOrder } from "@common/crud/organisation/model";
import { numberFormatter } from "@common/helpers/number-format";

interface FinanceDetailsProps {
    eventInstance: EventInstance;
}

export const FinanceDetails: React.FC<FinanceDetailsProps> = (props) => {
    const { eventInstance } = props;

    const isOpen = eventInstance.bookingType === BookingTypeEnum.Open;
    const isClosed = eventInstance.bookingType === BookingTypeEnum.Closed;

    const [purchaseOrderDetails, setPurchaseOrderDetails] = React.useState<string>("");

    const getPurchaseOrderNumbersAndUses = React.useCallback(async (purchaseOrdersList: PurchaseOrder[]) => {
        if (!purchaseOrdersList || purchaseOrdersList.length === 0) {
            setPurchaseOrderDetails("");
            return;
        }

        const detailsPromises = purchaseOrdersList.map(async (purchaseOrder) => {
            const details = await new OrganisationApi().getPurchaseOrder(eventInstance.corporateOrganisationId, purchaseOrder.id);
            const feesUsedText = !isNaN(details.eventInstanceUses[eventInstance.id]) ?
                `(-£${numberFormatter(details.eventInstanceUses[eventInstance.id])})` : "";
            return `${purchaseOrder.number} ${feesUsedText}`;
        });

        const details = await Promise.all(detailsPromises);
        setPurchaseOrderDetails(details.join(", "));
    }, [eventInstance.corporateOrganisationId, eventInstance.id]);

    React.useEffect(() => {
        if (eventInstance.existingPurchaseOrdersList) {
            getPurchaseOrderNumbersAndUses(eventInstance.existingPurchaseOrdersList);
        }
    }, [eventInstance.existingPurchaseOrdersList, eventInstance.purchaseOrderNumbersList, getPurchaseOrderNumbersAndUses]);

    return (
        <>
            <h3 className="event-instance-title">Finance details</h3>
            <FinanceDetailsModal eventInstance={eventInstance} />

            {isOpen && (
                <Detail
                    label="Open Course Delegate Fee"
                    value={eventInstance.priceInPence ? formatPrice(eventInstance.priceInPence) : "£0.00"}
                    labelWidth={5}
                    valueWidth={11}
                />
            )}
            {isClosed && (
                <>
                    <Detail
                        label="Purchase Order No"
                        value={purchaseOrderDetails}
                        labelWidth={5}
                        valueWidth={11}
                    />
                    <Detail
                        label="Closed Course Organisation Fee"
                        value={eventInstance.closedCourseFeeInPence ? formatPrice(eventInstance.closedCourseFeeInPence) : "£0.00"}
                        labelWidth={5}
                        valueWidth={11}
                    />
                    {eventInstance.delegatesRequired !== false && (
                        <>
                            <Detail
                                label="Upload fee per delegate"
                                value={eventInstance.uploadFeeInPence ? formatPrice(eventInstance.uploadFeeInPence) : "£0.00"}
                                labelWidth={5}
                                valueWidth={11}
                            />
                            <Detail
                                label="Total Upload Fee"
                                value={eventInstance.cancelled
                                    ? "£0.00"
                                    : (eventInstance.totalUploadFeeInPence || eventInstance.totalUploadFeeInPence === 0)
                                        ? formatPrice(eventInstance.totalUploadFeeInPence)
                                        : "TBC"}
                                labelWidth={5}
                                valueWidth={11}
                            />
                        </>
                    )}
                    <Detail
                        label="Misc Org Fee"
                        value={eventInstance.miscellaneousOrganisationFeeInPence
                            ? formatPrice(eventInstance.miscellaneousOrganisationFeeInPence)
                            : "£0.00"}
                        labelWidth={5}
                        valueWidth={11}
                    />
                    <Detail
                        label="Invoice Total"
                        value={eventInstance.invoiceTotalInPence
                            ? formatPrice(eventInstance.invoiceTotalInPence)
                            : (eventInstance.cancelled || !!eventInstance.certificatesProcessedDate
                                ? "£0.00"
                                : "TBC")}
                        labelWidth={5}
                        valueWidth={11}
                    />
                </>
            )}
            <Detail
                label="Note"
                value={eventInstance.financeDetailsNote}
                labelWidth={5}
                valueWidth={11}
            />
        </>
    );
};
