import * as React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useInterval } from "@common/hooks/useInterval";
import { Container, Header } from "semantic-ui-react";
import { eventInstanceIdSelector } from "@booking/eventInstance/selectors";
import { reservedIdSelector } from "../../selectors";

export const AttendeeRedirect: React.FC = () => {
    const [id, setId] = React.useState<string>("");
    const eventInstanceId = useSelector(eventInstanceIdSelector);
    const seatId = useSelector(reservedIdSelector);

    useInterval(async () => {
        const response = await axios.get(`/api/${eventInstanceId}/attendees/poll-active-attendee/${seatId}`);
        const attendeeId = response.data as string;

        setId(attendeeId);
    }, 2000);

    React.useEffect(() => {
        if (id) {
            location.href = `/police-and-court-event-management/eventInstances/${eventInstanceId}/attendees/${id}`;
        }
    }, [eventInstanceId, id]);

    return (
        <Container>
            <Header content="You are being redirected to the new attendee record" />
        </Container>
    );
};
