import * as React from "react";
import { Tab, Icon, Menu } from "semantic-ui-react";
import { HistoryVersion } from "@common/history/model";
import { CoreDetailProps } from "../Detail";
import { DetailsTab } from "./DetailsTab";
import { HistoryTab } from "@common/history/components/HistoryTab";
import { TaskRelationEnum } from "@common/crud/alaskaNudgeTask/model";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../actions";
import { appSelector } from "@common/crud/common/selectors";
import { Apps } from "@common/model";
import { EventTypeCategory } from "@common/crud/attendee/model";

enum TabNames {
    Details = "Details",
    History = "Client Journey",
}

export const CoreDetails: React.SFC<CoreDetailProps> = (props) => {
    const dispatch = useDispatch();
    const app = useSelector(appSelector);
    const showDetailsDefault = app === Apps.Admin;
    const flagIcon = () => <Icon className="flag alternate vertical icon" color={"red"} />;
    const hasActivePendingNotes = props?.dorsBooking?.historyRecords &&
        props?.dorsBooking?.historyRecords.some(ahr => ahr.isPendingNote === true && ahr.isPendingNoteCompleted === false);
    const getBookingHistory = React.useCallback(() => dispatch(getHistory(props?.dorsBooking?.id)), [props.dorsBooking, dispatch]);

    const panes =
        [
            {
                menuItem: (
                    <Menu.Item
                        onClick={getBookingHistory}>
                        {TabNames.History} {hasActivePendingNotes && flagIcon()}
                    </Menu.Item>),
                render: () =>
                    (<HistoryTab
                        showDetailsDefault={showDetailsDefault}
                        historyRecords={props?.dorsBooking?.historyRecords}
                        correlationId={props.dorsBooking.id}
                        historyVersion={HistoryVersion.V2}
                        relatedTo={TaskRelationEnum.DorsBooking}
                        eventTypeCategory={EventTypeCategory.Dors} />)
            }
        ];

    return (
        <Tab
            panes={[
                { menuItem: TabNames.Details, render: () => <DetailsTab {...props} /> },
                ...panes
            ]}
        />);
};
