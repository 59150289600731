import * as React from "react";
import { Container } from "semantic-ui-react";
import { parse } from "query-string";

class Login extends React.Component {

    public render() {
        const { redirectUrl } = parse(location.search);
        const signInUrl = redirectUrl ? `/account/signin?redirectUrl=${redirectUrl}` : "/account/signin";

        return (
            <Container className="home">
                <h1>Welcome to TTC.</h1>
                <div>
                    Please <a href={signInUrl}>Sign in</a> to continue.
                </div>
            </Container>
        );
    }
}

export { Login };
