import moment, { duration } from "moment";
import { DeliveryTypeEnum } from "../common/DeliveryTypeEnum";
import { LanguageEnum } from "../eventInstance/model";
import { AppState } from "../trainer/model";
import { ModuleTypeEnum, WorkflowTypeEnum } from "../eventType/model";

export interface SearchOptions {
    workflowType?: WorkflowTypeEnum;
    eventTypeId?: string;
    deliveryType?: DeliveryTypeEnum;
    venueId?: string;
    postCode?: string;
    courseType?: ModuleTypeEnum;
    language?: LanguageEnum;
    proposedDeliveryDate?: moment.Moment;
    proposedDeliveryTime?: moment.Moment;
}

export const trainerAvailabilitySearchOptionsSelector = (state: AppState): SearchOptions => {
    const proposedDeliveryTime = state.router.query.proposedDeliveryTime ? duration(state.router.query.proposedDeliveryTime) : undefined;
    const proposedDeliveryTimeAsMoment = proposedDeliveryTime?.isValid() ? moment().startOf("day").utc(true).add(proposedDeliveryTime) : undefined;
    return ({
        ...state.router.query,
        workflowType: state.router.query.workflowType ? +state.router.query.workflowType : undefined,
        eventTypeId: state.router.query.eventTypeId ?? undefined,
        deliveryType: state.router.query.deliveryType ? +state.router.query.deliveryType : undefined,
        venueId: state.router.query.venueId ?? undefined,
        postCode: state.router.query.postCode ?? undefined,
        language: state.router.query.language ? +state.router.query.language : undefined,
        proposedDeliveryDate: state.router.query.proposedDeliveryDate ? moment(state.router.query.proposedDeliveryDate).utc(true) : undefined,
        proposedDeliveryTime: proposedDeliveryTimeAsMoment
    });
};
