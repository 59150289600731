import { ApplicationState } from "../applicationState";
import { createSelector } from "reselect";
import { EventInstance } from "@common/crud/eventInstance";

export const eventInstanceIdSelector = (state: ApplicationState) => state.router.params.eventInstanceId;

export const eventInstanceHasSpecialRequirementsSelector = (state: ApplicationState) => state.router.query.hasSpecialRequirements === "true";

export const eventInstancesSelector = (state: ApplicationState) => state.eventInstances;

export const eventInstanceSelector = createSelector(
    eventInstancesSelector,
    eventInstanceIdSelector,
    (eventInstances: EventInstance[], id: string) => {
        return eventInstances.filter(c => c.id === id)[0] || {} as any;
    }
);
