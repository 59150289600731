import { AvailableDate } from "@common/availabilityTrainer/model";
import { EventTypeCategory } from "@common/crud/attendee/model";
import { EventType } from "@common/crud/eventType";
import { CountryEnum } from "@common/crud/organisation/model";
import { Trainer } from "@common/crud/trainer";
import * as React from "react";
import { Grid } from "semantic-ui-react";
import { EventInstance } from "../..";
import { StandbyTrainersCalendarDay } from "../../standbyTrainersCalendarModel";
import { DdrsCountryInput } from "./DdrsCountryInput";
import { DigitalEventCreationWizard } from "./DigitalEventCreationWizard";
import { EventTypeInput } from "./EventTypeInput";
import { PlannedEventsTable } from "./PlannedEventsTable";
import { QuickPlan } from "./QuickPlan";
import { TotalPlannedEventsTable } from "./TotalPlannedEventsTable";

export interface DigitalPlanningSidebarProps {
    showInputs: any;
    selectedTrainerId: any;
    disableEditing: boolean;
    selectedEventType: EventType;
    selectedTrainer: Trainer;
    eventTypeCategory: EventTypeCategory;
    selectedDdrsCountry: CountryEnum;
    currentTrainer: Trainer;
    selectedEventsPerDay: number;
    trainerAvailability: AvailableDate[];
    trainerExpiryDate?: moment.Moment;
    selectedMonth: moment.Moment;
    existingEvents: EventInstance[];
    digitallyPlannedEvents: EventInstance[];
    standbyCalendar: StandbyTrainersCalendarDay[];
    setSelectedEventType: React.Dispatch<React.SetStateAction<EventType>>;
    setSelectedDdrsCountry: React.Dispatch<React.SetStateAction<CountryEnum>>;
    setSelectedEventsPerDay: React.Dispatch<React.SetStateAction<number>>;
    setDigitallyPlannedEvents: React.Dispatch<React.SetStateAction<EventInstance[]>>;
    getStartTimeForSession: (session: number) => moment.Duration;
}

export const DigitalPlanningSidebar = (props: DigitalPlanningSidebarProps) => {
    const { showInputs, selectedTrainerId, disableEditing, selectedEventType, selectedTrainer, eventTypeCategory, selectedDdrsCountry, currentTrainer,
        selectedEventsPerDay, trainerAvailability, trainerExpiryDate, selectedMonth, existingEvents, digitallyPlannedEvents, standbyCalendar,
        setSelectedEventType, setSelectedDdrsCountry, setSelectedEventsPerDay, setDigitallyPlannedEvents, getStartTimeForSession } = props;

    const ddrs = eventTypeCategory === EventTypeCategory.Ddrs;

    return (
        <Grid.Column width={4} className="digital-planning-sidebar padded-top">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <h3>Planning</h3>
                    </Grid.Column>
                </Grid.Row>
                {showInputs &&
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EventTypeInput
                                disabled={disableEditing}
                                selectedEventType={selectedEventType}
                                setSelectedEventType={setSelectedEventType}
                                selectedTrainer={selectedTrainer}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
                {showInputs && ddrs &&
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <DdrsCountryInput
                                selectedDdrsCountry={selectedDdrsCountry}
                                setSelectedDdrsCountry={setSelectedDdrsCountry}
                                selectedTrainer={currentTrainer}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
                {selectedTrainerId && !ddrs &&
                    <>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <DigitalEventCreationWizard
                                    selectedEventType={selectedEventType}
                                    selectedEventsPerDay={selectedEventsPerDay}
                                    setSelectedEventsPerDay={setSelectedEventsPerDay}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <QuickPlan
                                    trainerAvailability={trainerAvailability}
                                    trainerExpiryDate={trainerExpiryDate}
                                    selectedMonth={selectedMonth}
                                    selectedEventType={selectedEventType}
                                    selectedTrainerId={selectedTrainerId}
                                    selectedEventsPerDay={selectedEventsPerDay}
                                    existingEvents={existingEvents}
                                    digitallyPlannedEvents={digitallyPlannedEvents}
                                    setDigitallyPlannedEvents={setDigitallyPlannedEvents}
                                    getStartTimeForSession={getStartTimeForSession}
                                    standbyCalendar={standbyCalendar}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                }
                {showInputs &&
                    <Grid.Row>
                        <Grid.Column width={16} className="overflow-auto">
                            <PlannedEventsTable
                                plannedEventInstances={digitallyPlannedEvents}
                                eventTypeCategory={eventTypeCategory}
                                title={ddrs ? "Month Summary" : "Summary"}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
                {showInputs && ddrs &&
                    <Grid.Row>
                        <Grid.Column width={16} className="overflow-auto">
                            <TotalPlannedEventsTable
                                plannedEventInstances={digitallyPlannedEvents}
                                month={selectedMonth}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </Grid.Column>
    );
};
