import { DateFormat } from "@common/crud/common/DateTimeFormats";
import { DorsBookingApi } from "@common/crud/dorsBooking";
import { DorsBookingDetailModel } from "@common/crud/dorsBooking/model";
import { formatCurrency } from "@common/formating";
import { PaymentDetailsForTransfer } from "@common/payments/model";
import { PaymentApi } from "@common/payments/paymentApi";
import { toast } from "@common/toasts";
import { Input } from "@neworbit/simpleui-input";
import { createValidator } from "not-valid";
import * as React from "react";
import { Button, Message, Modal } from "semantic-ui-react";

const MaxAttendanceId = 2147483647; // int.MaxValue

interface transferPaymentModalProps {
    paymentId: string;
    correlationId: string;
}

export const TransferPaymentModal: React.FC<transferPaymentModalProps> = ({ paymentId, correlationId }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [payment, setPayment] = React.useState<PaymentDetailsForTransfer>();
    const [verifiedCorrelationId, setVerifiedCorrelationId] = React.useState<string>(null);
    const [verifiedId, setVerifiedId] = React.useState(null);
    const [newId, setNewId] = React.useState<number>(null);
    const [newIdValid, setNewIdValid] = React.useState<boolean>(true);
    const [newAttendeeName, setNewAttendeeName] = React.useState("");
    const [paymentTransferred, setPaymentTransferred] = React.useState(false);
    const [matchingBookings, setMatchingBookings] = React.useState<DorsBookingDetailModel[]>([]);

    function newIdValidator() {
        return createValidator<number>(v => v < MaxAttendanceId, `Maximum allowed id is ${MaxAttendanceId}`);
    };

    function open() {
        setModalOpen(true);
    }

    function close() {
        setModalOpen(false);
    }

    function onIdChange(value: number , valid: boolean) {
        setNewId(value);
        setNewIdValid(valid);
        setVerifiedId(null);
        setVerifiedCorrelationId(null);
        setNewAttendeeName("");
    }

    React.useEffect(() => {
        async function getPaymentDetails() {
            setLoading(true);
            const api = new PaymentApi();
            const result = await api.getPaymentDetailsForTransfer(correlationId, paymentId);
            setPayment(result);
            setLoading(false);
        }
        if (paymentId) {
            getPaymentDetails();
        }
    }, [correlationId, modalOpen, paymentId]);

    async function transferPayment() {
        setLoading(true);
        const api = new PaymentApi();
        const result = await api.transferPayment(correlationId, verifiedCorrelationId, paymentId);
        setLoading(false);
        if (result) {
            toast.error(`Failed to transfer payment, reason: ${result}`);
            return;
        } else {
            toast.success(`Payment successfully transferred to ${newAttendeeName}`);
            setPaymentTransferred(true);
            window.location.reload();
        }
        close();
    }

    async function verifyAttendee() {
        setLoading(true);
        const api = new DorsBookingApi();
        const bookings = await api.searchForBookingsByAttendanceId(newId);
        setMatchingBookings(bookings);
        if (bookings?.length === 1) {
            setNewAttendeeName(`${bookings[0].forename} ${bookings[0].surname}`);
            setVerifiedCorrelationId(bookings[0].id);
            setVerifiedId(newId);
        }

        if (bookings?.length > 1) {
            setNewAttendeeName(null);
        }

        if (bookings?.length === 0) {
            setNewAttendeeName("Attendee not found");
        }
        setLoading(false);
    }

    function setBookingAsVerified(booking: DorsBookingDetailModel) {
        return () => {
            setNewAttendeeName(`${booking.forename} ${booking.surname}`);
            setVerifiedCorrelationId(booking.id);
            setVerifiedId(booking.attendanceId);
            setMatchingBookings([booking]);
        };
    }

    const attendeeHasBeenVerified = verifiedId === newId && newId !== null && verifiedId !== null;
    const transferDisabled = payment?.isSuitableForTransfer !== true || !attendeeHasBeenVerified || loading;

    if (paymentTransferred) {
        return (<></>);
    }

    return (
        <>
            { modalOpen === false && payment?.isSuitableForTransfer === true && <Button icon="exchange" onClick={open} positive content="Transfer Payment" /> }
            <Modal open={modalOpen}>
                <Modal.Header>
                    Payment Transfer
                </Modal.Header>
                <Modal.Content>
                    <p>You will be transferring a payment of <strong>{formatCurrency(payment?.amount)}</strong> which was
                     completed on <strong>{payment?.paymentDate?.format(DateFormat.LongWithTime)}</strong></p>
                    <Input.Number
                        placeholder="Attendance Id"
                        value={newId}
                        onChange={onIdChange}
                        showErrors={!setNewIdValid}
                        validation={[newIdValidator()]}
                    />
                    <Button onClick={verifyAttendee} disabled={!newIdValid}>Verify Attendee</Button>
                    { matchingBookings?.length > 1 &&
                        <>
                            <p>Multiple matching bookings were found, please select the one you wish to use</p>
                            { matchingBookings?.map(b => <Button key={b.id} onClick={setBookingAsVerified(b)} >{b.forename} {b.surname}</Button>)}
                        </>
                    }
                    { newAttendeeName && !attendeeHasBeenVerified && <p>No attendees were found with that attendance id</p>}
                    { newAttendeeName && attendeeHasBeenVerified && !loading && <p>Name of Attendee: <strong>{newAttendeeName}</strong></p> }
                    { payment?.transferIssue &&
                    <Message negative>This payment may not be transferred, Reason: {payment?.transferIssue}</Message> }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} className="cancel-action" content="Cancel" />
                    <Button disabled={transferDisabled || !newIdValid} floated="right" content="Save" onClick={transferPayment} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
