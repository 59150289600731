import * as React from "react";
import moment from "moment";
import { Button, Divider, Icon, Modal, Popup } from "semantic-ui-react";
import { EventTrainer, PracticalEventTrainer, SUBCONTRACTING_WINDOW_IN_DAYS, TrainerType } from "../../model";
import { SwapTrainerAction } from "../bulkActions/SwapTrainerAction";
import { muiPresentOrPastDateValidator } from "@common/validation/pastDateValidator";
import { useSelector } from "react-redux";
import { eventInstanceSelector } from "../../selectors";
import { TrainerRemovalWarningComponent } from "../TrainerRemovalWarningComponent";
import { muiLessThanAYearAgoOrFutureValidator } from "@common/validation/lessThanAYearAgoOrFutureValidator";
import { MuiDateField } from "@common/components/MuiDateField";

export interface SwapTrainerProps {
    trainer: EventTrainer | PracticalEventTrainer;
    trainerType: TrainerType;
}

export const SwapTrainer = ({ trainer, trainerType }: SwapTrainerProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [actionComplete, setActionComplete] = React.useState(false);
    const [triggerDispatchAction, setTriggerDispatchAction] = React.useState(false);
    const [dateAgreed, setDateAgreed] = React.useState(moment().utc().startOf("day"));
    const [isDateAgreedValid, setIsDateAgreedValid] = React.useState(true);
    const [isNewTrainerSelected, setIsNewTrainerSelected] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const eventInstance = useSelector(eventInstanceSelector);

    React.useEffect(() => {
        if (actionComplete) {
            setTriggerDispatchAction(false);
            setActionComplete(false);
            setIsLoading(false);
            setIsModalOpen(false);
        }
    }, [actionComplete]);

    function triggerAction() {
        setIsLoading(true);
        setTriggerDispatchAction(true);
    }

    function onDateAgreedChanged(value: moment.Moment, valid: boolean) {
        if (value) {
            setDateAgreed(value.utc().startOf("day"));
            setIsDateAgreedValid(valid);
        }
    }

    function openModal() {
        setIsModalOpen(true);
    }

    function onCancelClick() {
        setIsModalOpen(false);
        setDateAgreed(moment());
        setIsDateAgreedValid(true);
    }

    function instructionsText() {
        return <p>Choose a trainer to swap this course to.</p>;
    }

    const selectedEventInstanceIds = React.useMemo(() => [eventInstance.id], [eventInstance]);

    const isSwapValid = isNewTrainerSelected && isDateAgreedValid;
    const isDateAgreedWithinSubcontractingWindow = dateAgreed.isSameOrAfter(moment(eventInstance.startDate).subtract(SUBCONTRACTING_WINDOW_IN_DAYS, "days"));
    const eventInstanceStartsWithinSubcontractingWindow = eventInstance.startDate.isSameOrBefore(moment().add(SUBCONTRACTING_WINDOW_IN_DAYS, "days"));
    const isSwappingEnabled = !trainer?.subcontractingProcess?.isActive;

    return (
        <>
            <Popup
                on="hover"
                content="Swap cannot be completed as subcontracting is already taking place"
                trigger={<span><Button content="Swap" onClick={openModal} disabled={!isSwappingEnabled} /></span>}
                disabled={isSwappingEnabled}
            />
            <Modal open={isModalOpen}>
                <Modal.Header>Trainer Swap</Modal.Header>
                <Modal.Content>
                    <TrainerRemovalWarningComponent
                        onTrainerSwapModal={true}
                        hasMonitor={eventInstance.monitorTrainers?.length > 0}
                        hasExternalAssessmentDue={trainer.externalAssessmentDue}
                    />
                    <Divider />
                    <SwapTrainerAction
                        actionComplete={actionComplete}
                        setActionComplete={setActionComplete}
                        triggerDispatchAction={triggerDispatchAction}
                        setTriggerDispatchAction={setTriggerDispatchAction}
                        selectedEventInstanceIds={selectedEventInstanceIds}
                        children={instructionsText}
                        trainer={trainer}
                        setIsNewTrainerSelected={setIsNewTrainerSelected}
                        swapAgreedDate={dateAgreed}
                        startSubcontractingProcess={isDateAgreedWithinSubcontractingWindow}
                        trainerType={trainerType}
                    />
                    <br />
                    {eventInstanceStartsWithinSubcontractingWindow &&
                        <>
                            <p>Confirm the date the swap was agreed.</p>
                            <MuiDateField
                                value={dateAgreed}
                                onChange={onDateAgreedChanged}
                                showErrors
                                validation={[muiPresentOrPastDateValidator, muiLessThanAYearAgoOrFutureValidator]}
                            />
                            {isSwapValid && isDateAgreedWithinSubcontractingWindow &&
                                <>
                                    <br />
                                    <p className="trainer-subcontracting-warning">
                                        <Icon name="warning" />
                                        As this swap was agreed within {`${SUBCONTRACTING_WINDOW_IN_DAYS}`} days of the course,
                                        the removed trainer is subcontracting this course to the new trainer.
                                    </p>
                                </>
                            }
                        </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Cancel"
                        className="cancel-action"
                        onClick={onCancelClick}
                        disabled={isLoading}
                        loading={isLoading}
                    />
                    <Button
                        content="Complete Swap"
                        positive
                        onClick={triggerAction}
                        disabled={!isSwapValid || isLoading}
                        loading={isLoading}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
