import * as React from "react";
import { Detail } from "@neworbit/simpleui-detail";
import { HtmlRenderer } from "@common/crud/common/HtmlRenderer";
import { WorkflowTypeEnum, EventTypeDetailModel } from "../../model";
import { Grid, Header, Tab } from "semantic-ui-react";
import { SchemeDeliveryTypeEnum } from "@common/crud/organisation/model";
import { isEventTypeWorkflowConstruction, isEventTypeWorkflowCorporate } from "@common/global/CommonHelpers";
import { CustomDetail } from "@common/components/CustomDetail";

interface CommunicationDetailsTabProps {
    eventType: EventTypeDetailModel;
}

export const CommunicationDetailsTab: React.FC<CommunicationDetailsTabProps> = ({ eventType }) => {
    const classroomDetails = eventType.classroomEventTypeDetails;
    const digitalDetails = eventType.digitalEventTypeDetails;

    const isDors = eventType.workflowType === WorkflowTypeEnum.Dors;
    const isDdrs = eventType.workflowType === WorkflowTypeEnum.DDRS;
    const isCorporate = isEventTypeWorkflowCorporate(eventType);
    const isConstruction = isEventTypeWorkflowConstruction(eventType);
    const yesNoFromBool = (value: boolean) => value ? "Yes" : "No";

    return (
        <Tab.Pane>
            <Grid>
                {isDdrs && (
                    <>
                        <Grid.Row><Header>DDRS</Header></Grid.Row>
                        <Detail
                            label="First invite Letter Opening"
                            value={<HtmlRenderer value={eventType.firstInviteLetterOpening} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="First invite Letter Closing"
                            value={<HtmlRenderer value={eventType.firstInviteLetterClosing} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Reminder to book Letter Opening"
                            value={<HtmlRenderer value={eventType.reminderToBookLetterOpening} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Reminder to book Letter Closing"
                            value={<HtmlRenderer value={eventType.reminderToBookLetterClosing} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Completion Letter Wording (En)"
                            value={<HtmlRenderer value={eventType.completionLetterWordingEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Non-Completion Letter Wording (En)"
                            value={<HtmlRenderer value={eventType.nonCompletionLetterWordingEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                )}

                {isDors && (
                    <>
                        <Grid.Row><Header>Classroom</Header></Grid.Row>
                        <Detail
                            label="Height between letter sections"
                            value={<>{classroomDetails?.whitespaceHeight}</>}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Booking Confirmation Letter Opening (En)"
                            value={<HtmlRenderer value={classroomDetails?.bookingConfirmationLetterOpeningEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Booking Confirmation Letter Opening (Cy)"
                            value={<HtmlRenderer value={classroomDetails?.bookingConfirmationLetterOpeningCy} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Booking Confirmation Letter Closing (En)"
                            value={<HtmlRenderer value={classroomDetails?.bookingConfirmationLetterClosingEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Booking Confirmation Letter Closing (Cy)"
                            value={<HtmlRenderer value={classroomDetails?.bookingConfirmationLetterClosingCy} />}
                            labelWidth={5}
                            valueWidth={11}
                        />

                        <Grid.Row><Header>Digital</Header></Grid.Row>
                        <Detail
                            label="Height between letter sections"
                            value={<>{digitalDetails?.digitalWhitespaceHeight}</>}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Digital Booking Confirmation Letter Opening (En)"
                            value={<HtmlRenderer value={digitalDetails?.digitalBookingConfirmationLetterOpeningEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Digital Booking Confirmation Letter Opening (Cy)"
                            value={<HtmlRenderer value={digitalDetails?.digitalBookingConfirmationLetterOpeningCy} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Digital Booking Confirmation Letter Closing (En)"
                            value={<HtmlRenderer value={digitalDetails?.digitalBookingConfirmationLetterClosingEn} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Digital Booking Confirmation Letter Closing (Cy)"
                            value={<HtmlRenderer value={digitalDetails?.digitalBookingConfirmationLetterClosingCy} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                )}

                <h3>Booking App</h3>
                {!isCorporate && !isConstruction && (
                    <>
                        <Detail label={"Scheme Note (En)"} value={eventType.noteEn} labelWidth={5} valueWidth={11} />
                        <Detail label={"Scheme Note (Cy)"} value={eventType.noteCy} labelWidth={5} valueWidth={11} />
                    </>
                )}
                {(isCorporate || isConstruction) && (
                    <Detail
                        label={"Product Note"}
                        value={<HtmlRenderer value={eventType.productNote} />}
                        labelWidth={5}
                        valueWidth={11} />
                )}
                {!isCorporate && !isConstruction && (
                    <>
                        <Detail
                            label="Intro Message (En)"
                            value={<HtmlRenderer value={eventType.introMessageEnglish} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Intro Message (Cy)"
                            value={<HtmlRenderer value={eventType.introMessageWelsh} />}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <h3>Surveys</h3>
                        <Detail
                            label="Survey 1 Link - English"
                            value={eventType.surveyLink}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Survey 1 Link - Welsh"
                            value={eventType.surveyLinkCy}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Survey 2 Link - English"
                            value={eventType.secondSurveyLink}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Survey 2 Link - Welsh"
                            value={eventType.secondSurveyLinkCy}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <h3>Booking Information</h3>
                    </>
                )}
                {!isCorporate && !isConstruction &&
                    (eventType.deliveryType === SchemeDeliveryTypeEnum.Classroom || eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) && (
                    <>
                        <Detail
                            label="Classroom Booking Information Link - English"
                            value={eventType.bookingInformationLinkClassroom}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Classroom Booking Information Link - Welsh"
                            value={eventType.bookingInformationLinkClassroomCy}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                )}
                {!isCorporate && !isConstruction &&
                    (eventType.deliveryType === SchemeDeliveryTypeEnum.Digital || eventType.deliveryType === SchemeDeliveryTypeEnum.ClassroomAndDigital) && (
                    <>
                        <Detail
                            label="Digital Booking Information Link - English"
                            value={eventType.bookingInformationLinkDigital}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Digital Booking Information Link - Welsh"
                            value={eventType.bookingInformationLinkDigitalCy}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                )}
                {eventType.flexiPayEnabled &&
                    <>
                        <h3>Flexi-booking</h3>
                        <Detail
                            label="Flexi-booking Instructions (En)"
                            value={eventType.flexiPayInstructionsEn}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        <Detail
                            label="Flexi-booking Instructions (Cy)"
                            value={eventType.flexiPayInstructionsCy}
                            labelWidth={5}
                            valueWidth={11}
                        />
                    </>
                }
                {(eventType.workflowType === WorkflowTypeEnum.OnRoad || eventType.workflowType === WorkflowTypeEnum.Workshop
                    || eventType.workflowType === WorkflowTypeEnum.BusinessDriver) &&
                    <>
                        <h3>Competency Report</h3>
                        <Detail
                            label="Competency Report Required"
                            value={yesNoFromBool(eventType.competencyReportRequired)}
                            labelWidth={5}
                            valueWidth={11}
                        />
                        {eventType.competencyReportRequired &&
                            <CustomDetail
                                label="Competency Report Link"
                                value={eventType.competencyReportLink}
                                labelWidth={5}
                                valueWidth={11}
                                valueClassName="break-all"
                            />
                        }
                    </>
                }
            </Grid >
        </Tab.Pane>
    );
};
