import * as React from "react";

import { BookingApiSettings } from "../model";

import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const BookingApi: ConfigurationAreaComponent<BookingApiSettings> = ({ settings, hierarchy }) => {
    return (
        <SettingsGrid
            area="BookingApi"
            hierarchy={hierarchy}
            settings={settings}
            items={[
                { name: "UserName", label: "User Name" },
                { name: "Password" },
                { name: "AdB2CId", label: "AD B2C ID" }
            ]}
        />
    );
};
