import * as React from "react";
import { ConfirmButton, Modal } from "@common/components";
import { Button, Dimmer } from "semantic-ui-react";

import { EditForm, EditProps, DispatchProps } from "./Edit";

interface EditModalState {
    termsAndConditionsTouched: boolean;
    loading: boolean;
}

export class EditModal extends React.Component<EditProps & DispatchProps, EditModalState> {

    private editForm: EditForm;

    constructor(props: any) {
        super(props);
        this.state = {
            termsAndConditionsTouched: false,
            loading: false
        };
    }

    public render() {
        const confirmationText = (
            <Modal.Content>
                <h3 className="status-attention">Are you sure?</h3>
                <p>If you have changed the Ts and Cs properties all Courses will be updated. This will take some time to complete.</p>
                <p>Please contact support before proceeding.</p>
            </Modal.Content>
        );

        return (
            <Modal size="large" open={this.props.open} onClose={this.props.close}>
                <Modal.Header>{(this.props.isCorporate || this.props.isConstruction)  ? "Edit Product" : "Edit Scheme"}</Modal.Header>
                <Modal.Content>
                    <Dimmer.Dimmable dimmed={this.state.loading}>
                        <Dimmer active={this.state.loading} inverted className="dimmer" />
                        <EditForm
                            open={this.props.open}
                            isCorporate={this.props.isCorporate}
                            isConstruction={this.props.isConstruction}
                            isBusinessDriverAdmin={this.props.isBusinessDriverAdmin}
                            isPoliceOrCourt={this.props.isPoliceOrCourt}
                            isTtcCorporateAdmin={this.props.isTtcCorporateAdmin}
                            close={this.props.close}
                            save={this.props.save}
                            push={this.props.push}
                            model={this.props.model}
                            trainerAttributes={this.props.trainerAttributes}
                            ref={this.setRef}
                            termsAndConditionsTouched={this.setTermsAndConditionsTouched}
                            editPath={this.props.editPath}
                            constructionCategories={this.props.constructionCategories}
                        />
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cancel-action" onClick={this.props.close} disabled={this.state.loading}>Cancel</Button>
                    {this.state.termsAndConditionsTouched ?
                        <ConfirmButton className={this.state.loading ? "ui loading button" : "ui button"}
                            content={confirmationText} onConfirm={this.submit} disabled={this.state.loading}>Save</ConfirmButton> :
                        <Button className={this.state.loading ? "ui loading button" : "ui button"}
                            onClick={this.submit} disabled={this.state.loading}>Save</Button>
                    }
                </Modal.Actions>
            </Modal>
        );
    }

    private setRef = (instance: EditForm) => this.editForm = instance;

    private submit = async () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
            await this.editForm.submit();
            this.setState({ loading: false });
        }
    };

    private setTermsAndConditionsTouched = () => this.setState({ termsAndConditionsTouched: true });
}
