import * as React from "react";
import { HotjarSettings as HotjarSettingsDictionary } from "../model";
import { ConfigurationAreaComponent } from "./ConfigurationAreaComponent";
import { SettingsGrid } from "./SettingsGrid";

export const HotjarSettings: ConfigurationAreaComponent<HotjarSettingsDictionary> = ({ settings, hierarchy }) => {
    return (
        <>
            <SettingsGrid
                area="Hotjar"
                hierarchy={hierarchy}
                settings={settings}
                items={[
                    { name: "CpcSiteId", label: "CPC Booking App Site Id" },
                    { name: "PoliceBookingAppSiteId", label: "Police Booking App Site Id" },
                    { name: "HotjarVersion", label: "Hotjar Version" },
                ]}
            />
        </>
    );
};
