import axios from "axios";
import moment from "moment";
import * as QueryString from "query-string";
import {
    SearchOptions,
    DelegateListModel,
    DelegateDetailModel,
    DelegateCreateEditModel,
    DelegateComparisonTypeEnum
} from "./model";
import { parseModel as parseAttendeeModel } from "../attendee/helpers";
import { AttendeeListModel } from "../attendee";

export class DelegateApi {

    private readonly apiUrl = "/api/delegate";

    public async getAll(options?: SearchOptions): Promise<DelegateListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get<DelegateListModel[]>(`${this.apiUrl}?${query}`);
        return response.data;
    }

    public async detail(organisationId: string, eventInstanceId: string, delegateId: string, attendeeId: string, addAuditEntry: boolean = true):
     Promise<DelegateDetailModel> {
        let url = `${this.apiUrl}/detail?`;
        if (delegateId) {
            url = url + `delegateId=${delegateId}`;
        } else if (attendeeId) {
            url = url + `attendeeId=${attendeeId}`;
        }

        if (organisationId) {
            url = url + `&organisationId=${organisationId}`;
        } else if (eventInstanceId) {
            url = url + `&eventInstanceId=${eventInstanceId}`;
        }
        const response = await axios.get<DelegateDetailModel>(`${url}&addAuditEntry=${addAuditEntry}`);
        return this.parseModel(response.data);
    }

    public async save(delegate: DelegateCreateEditModel): Promise<DelegateDetailModel> {
        const normalizedDelegate = this.normalizeCreateEditModel(delegate);
        const response = await axios.put<DelegateDetailModel>(`${this.apiUrl}/${delegate.id}`, normalizedDelegate);
        return this.parseModel(response.data);
    }

    public async create(delegate: DelegateCreateEditModel): Promise<DelegateDetailModel> {
        const normalizedDelegate = this.normalizeCreateEditModel(delegate);
        const response = await axios.post<DelegateDetailModel>(this.apiUrl, normalizedDelegate);
        return this.parseModel(response.data);
    }

    public async findByEmail(organisationId: string, email: string): Promise<DelegateDetailModel> {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get<DelegateDetailModel>(`${this.apiUrl}/findbyemail?organisationId=${organisationId}&email=${encodedEmail}`);
        return response.data;
    }

    public async findByDrivingLicence(organisationId: string, drivingLicence: string): Promise<DelegateDetailModel> {
        const encodedDrivingLicence = encodeURIComponent(drivingLicence);
        const response = await axios.get<DelegateDetailModel>(
            `${this.apiUrl}/findbydrivinglicence?organisationId=${organisationId}&drivingLicence=${encodedDrivingLicence}`
        );
        return response.data;
    }

    public async findByUin(organisationId: string, uin: string): Promise<DelegateDetailModel> {
        const encodedUin = encodeURIComponent(uin);
        const response = await axios.get<DelegateDetailModel>(`${this.apiUrl}/findbyuin?organisationId=${organisationId}&uin=${encodedUin}`);
        return response.data;
    }

    public async lookForDelegateData(eventInstanceId: string, comparisonValue: string, delegateComparisonType: DelegateComparisonTypeEnum,
        organisationId: string = null): Promise<AttendeeListModel> {
        let url = `${this.apiUrl}/lookForDelegateData/${eventInstanceId}/${delegateComparisonType}/${comparisonValue}`;
        if (organisationId) {
            url = url + `?organisationId=${organisationId}`;
        }

        const response = await axios.get(url);
        const data = response.data as AttendeeListModel;
        return parseAttendeeModel(data);
    }

    public async handleDeletionDate(delegateId: string, organisationId: string, eventInstanceId: string, cancel: boolean): Promise<moment.Moment> {
        const response = await axios.put<moment.Moment>
        (`${this.apiUrl}/${delegateId}/handleDeletionDate?cancel=${cancel}&organisationId=${organisationId}&eventInstanceId=${eventInstanceId}`);
        return response.data ? moment(response.data) : null;
    }

    public parseModel(model: DelegateDetailModel): DelegateDetailModel {
        return {
            ...model,
            dqcExpiry: model.dqcExpiry ? moment(model.dqcExpiry) : undefined,
            deletionDate: model.deletionDate ? moment(model.deletionDate) : undefined,
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated)
            })),
            delegateAttendees: model.delegateAttendees?.map(a => ({
                ...a,
                deliveryDate: moment(a.deliveryDate),
                addedOn: moment(a.addedOn),
                deletionDate: a.deletionDate ? moment(a.deletionDate) : undefined
            })),
            dateOfBirth: model.dateOfBirth ? moment(model.dateOfBirth) : undefined
        };
    }

    public normalizeCreateEditModel(model: DelegateCreateEditModel): DelegateCreateEditModel {
        return {
            ...model,
            forename: model.forename?.trim() || undefined,
            surname: model.surname?.trim() || undefined,
            licenceNumber: model.licenceNumber?.trim().toUpperCase() || undefined,
            drivingLicenceExactCountry: model.drivingLicenceExactCountry?.trim() || undefined,
            dqcReference: model.dqcReference?.trim().toUpperCase() || undefined,
            email: model.email?.trim() || undefined,
            mobileNumber: model.mobileNumber?.trim() || undefined,
        };
    }
}
