import axios from "axios";
import * as QueryString from "query-string";
import moment from "moment";
import { SearchOptions, VenueListModel, VenueDetailModel, VenueCreateEditModel, Venue, DorsSiteModel } from "./model";

export class VenueApi {

    private readonly apiUrl: string;

    constructor(bookingId?: string) {
        this.apiUrl = bookingId ? `/api/dorsBooking/${bookingId}/venue` : "/api/venue";
    }

    public async getAll(options: SearchOptions): Promise<VenueListModel[]> {
        const query = QueryString.stringify(options);
        const response = await axios.get(`${this.apiUrl}?${query}`);
        const data = response.data as VenueListModel[];
        return data.map(this.parseModel);
    }

    public async detail(id: string): Promise<VenueDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as VenueDetailModel;
        return this.parseModel(model);
    }

    public async save(venue: VenueCreateEditModel): Promise<VenueDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${venue.id}`, venue);
        const model = response.data as VenueDetailModel;
        return this.parseModel(model);
    }

    public async create(venue: VenueCreateEditModel): Promise<VenueDetailModel> {

        const response = await axios.post(this.apiUrl, venue);
        const model = response.data as VenueDetailModel;
        return this.parseModel(model);
    }

    public delete(id: string): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public async getAllDorsSites(): Promise<DorsSiteModel[]> {
        const response = await axios.get(`${this.apiUrl}/dors-sites`);
        return response.data as DorsSiteModel[];
    }

    public parseModel<T extends Venue>(model: T): T {
        return {
            ...model,
            expiryDate: model.expiryDate
                ? moment(model.expiryDate)
                : undefined,
            history: model.history?.map(h => ({
                ...h,
                dateCreated: moment(h.dateCreated),
                pendingDate: h.pendingDate && moment(h.pendingDate),
                completionDate: h.completionDate && moment(h.completionDate)
            }))
        };
    }
}
