import * as React from "react";
import { useEffect, useState } from "react";
import { Input } from "@neworbit/simpleui-input";
import { DropdownItemProps, FormInputProps, DropdownProps, Form, Dropdown, Label } from "semantic-ui-react";
import { RichTextEditor } from "./RichTextEditor";

type CustomEmailMessageProps = {
    isCustomEmail: boolean;
    onCustomEmailChange: React.Dispatch<React.SetStateAction<boolean>>;
    onSubjectChange: (value: string) => void;
    onBodyChange: (value: string) => void;
    templateDropdownOptions: DropdownItemProps[];
    onTemplateIdChange: (id: number) => void;
    onIsValidChange: (isValid: boolean) => void;
    onShowErrorsChange: (isValid: boolean) => void;
    /**
     * e.g. onSubmit of parent component.
     */
    showErrors: boolean;
};

export const CustomEmailMessage = ({
    isCustomEmail,
    onCustomEmailChange,
    onSubjectChange,
    onBodyChange,
    templateDropdownOptions,
    onTemplateIdChange,
    onIsValidChange,
    showErrors,
    onShowErrorsChange
}: CustomEmailMessageProps) => {
    const [bodyError, setBodyError] = useState(true);
    const [subjectError, setSubjectError] = useState(true);
    const [dropdownError, setDropdownError] = useState(true);

    useEffect(() => {
        const customError = isCustomEmail && (subjectError || bodyError);
        const templateError = !isCustomEmail && dropdownError;
        const anyError = customError || templateError;

        onIsValidChange(!anyError);
    }, [isCustomEmail, bodyError, subjectError, dropdownError, onIsValidChange, onShowErrorsChange]);

    function subjectChanged(_: any, { value }: FormInputProps) {
        setSubjectError(isCustomEmail && value?.length === 0);
        onSubjectChange(value);
        onShowErrorsChange(false);
    }

    function bodyChanged(value: string) {
        setBodyError(isCustomEmail && value?.length === 0);
        onBodyChange(value);
        onShowErrorsChange(false);
    }

    function dropdownOptionChanged(_: any, { value }: DropdownProps) {
        if (typeof value !== "number") {
            // This won't happen if the data was sanitised at input (in config)
            throw new Error(`Unexpected '${value}' from dropdown.`);
        }

        const option = value;
        setDropdownError(!isCustomEmail && option === 0);
        onTemplateIdChange(option);
        onShowErrorsChange(false);
    }

    function customToggleChanged(toggle: boolean): void {
        onShowErrorsChange(false);
        onCustomEmailChange(toggle);
    }

    return (<>
        <Input.Checkbox
            label="Send a custom email"
            value={isCustomEmail}
            onChange={customToggleChanged} />

        {isCustomEmail ? (
            <>
                <Form.Input
                    required
                    label="Subject"
                    spellCheck="true"
                    onChange={subjectChanged}
                    error={subjectError}
                />
                {(showErrors && subjectError) && <Label basic color="red" content={"This field is required"} pointing="above" />}

                <RichTextEditor onChange={bodyChanged} />
                {(showErrors && bodyError) && <Label basic color="red" content={"This field is required"} pointing="above" />}
            </>)
            :
            <>
                <Dropdown
                    placeholder="Select template"
                    fluid
                    selection
                    options={templateDropdownOptions}
                    onChange={dropdownOptionChanged} />
                {(showErrors && dropdownError) && <Label basic color="red" content="This field is required" pointing="above" />}
            </>
        }
    </>);
};
